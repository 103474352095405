import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";

const SideBarRightAlleFilter = ({
  resetFilterHandler,
  DB_fontFace_FontFamilyName,

  SideBarLeft_AlleFilter_Farbe_Groesse_Stil_OnMouseOverAndOut,
  SideBarLeft_AlleFilter_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut,
  SideBarLeft_AlleFilter_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut,
  SideBarLeft_AlleFilter_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut,
  SideBarLeft_AlleFilter_Farbe_Groesse_Stil_Trigger,
  SideBarLeft_AlleFilter_Farbe_Groesse_Stil_Hover_Trigger,
  SideBarLeft_AlleFilter_HintergrundFarbe_Radius_Stil_Trigger,
  SideBarLeft_AlleFilter_HintergrundFarbe_Radius_Stil_Hover_Trigger,

  DB_fontFace_AlleFilterButtonFontFamily_FontFamilyName,
  DB_fontFace_AlleFilterButtonFontFamily_FontFamilySrc,
  DB_LeftSidebar_MenuItem_AlleFilterButtonFF_FontWeight,
  DB_LeftSidebar_MenuItem_AlleFilterButtonFF_FontColor,
  DB_LeftSidebar_MenuItem_AlleFilterButtonFF_FontSize,
  DB_LeftSidebar_MenuItem_AlleFilterButtonFF_BackgroundColor,
  DB_LeftSidebar_MenuItem_AlleFilterButtonFF_TextAlign,
  DB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderTop,
  DB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderStyleTop,
  DB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderColorTop,
  DB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderBottom,
  DB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderStyleBottom,
  DB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderColorBottom,
  DB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderLeft,
  DB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderStyleLeft,
  DB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderColorLeft,
  DB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderRight,
  DB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderStyleRight,
  DB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderColorRight,
  DB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderTopRightRadius,
  DB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderTopLeftRadius,
  DB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderBottomRightRadius,
  DB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderBottomLeftRadius,
  DB_LeftSidebar_MenuItem_AlleFilterButtonFF_marginLeftRight,
  DB_LeftSidebar_MenuItem_AlleFilterButtonFF_marginTopBottom,
  DB_LeftSidebar_MenuItem_AlleFilterButtonFF_paddingLeftRight,
  DB_LeftSidebar_MenuItem_AlleFilterButtonFF_paddingTopBottom,
  DB_LeftSidebar_MenuItem_AlleFilterButtonFF_letterSpacing,
  DB_LeftSidebar_MenuItem_AlleFilterButtonFF_ShadowHorizontal,
  DB_LeftSidebar_MenuItem_AlleFilterButtonFF_ShadowVertical,
  DB_LeftSidebar_MenuItem_AlleFilterButtonFF_ShadowBlur,
  DB_LeftSidebar_MenuItem_AlleFilterButtonFF_ShadowColor,
  DB_LeftSidebar_MenuItem_AlleFilterButtonFF_TextDecoration,
  DB_LeftSidebar_MenuItem_AlleFilterButtonFF_TextDecorationStyle,

  DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_FontWeight,
  DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_FontColor,
  DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_FontSize,
  DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BackgroundColor,
  DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_TextAlign,
  DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderTop,
  DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderStyleTop,
  DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderColorTop,
  DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderBottom,
  DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderStyleBottom,
  DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderColorBottom,
  DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderLeft,
  DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderStyleLeft,
  DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderColorLeft,
  DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderRight,
  DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderStyleRight,
  DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderColorRight,
  DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderTopRightRadius,
  DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderTopLeftRadius,
  DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderBottomRightRadius,
  DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderBottomLeftRadius,
  DB_LeftSidebar_MenuItem_AlleFilterButtonFF_marginLeftRight_Hover,
  DB_LeftSidebar_MenuItem_AlleFilterButtonFF_marginTopBottom_Hover,
  DB_LeftSidebar_MenuItem_AlleFilterButtonFF_paddingLeftRight_Hover,
  DB_LeftSidebar_MenuItem_AlleFilterButtonFF_paddingTopBottom_Hover,
  DB_LeftSidebar_MenuItem_AlleFilterButtonFF_letterSpacing_Hover,
  DB_LeftSidebar_MenuItem_AlleFilterButtonFF_ShadowHorizontal_Hover,
  DB_LeftSidebar_MenuItem_AlleFilterButtonFF_ShadowVertical_Hover,
  DB_LeftSidebar_MenuItem_AlleFilterButtonFF_ShadowBlur_Hover,
  DB_LeftSidebar_MenuItem_AlleFilterButtonFF_ShadowColor_Hover,
  DB_LeftSidebar_MenuItem_AlleFilterButtonFF_TextDecoration_Hover,
  DB_LeftSidebar_MenuItem_AlleFilterButtonFF_TextDecorationStyle_Hover,
}) => {
  const { t } = useTranslation();

  const useStyles = createUseStyles({
    menuItem_filterZuruecksetzen: {
      fontFamily: `${DB_fontFace_AlleFilterButtonFontFamily_FontFamilyName}`,
      fontWeight: `${DB_LeftSidebar_MenuItem_AlleFilterButtonFF_FontWeight}`,
      color: `${DB_LeftSidebar_MenuItem_AlleFilterButtonFF_FontColor}`,
      fontSize: `${DB_LeftSidebar_MenuItem_AlleFilterButtonFF_FontSize}px`,
      backgroundColor: `${
        SideBarLeft_AlleFilter_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut
          ? "rgba(255,0,0,0.5)"
          : SideBarLeft_AlleFilter_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut
          ? "rgba(255,0,0,0.3)"
          : DB_LeftSidebar_MenuItem_AlleFilterButtonFF_BackgroundColor
      }`,
      textAlign: `${DB_LeftSidebar_MenuItem_AlleFilterButtonFF_TextAlign}`,
      borderTop: `${
        SideBarLeft_AlleFilter_Farbe_Groesse_Stil_OnMouseOverAndOut
          ? "3"
          : SideBarLeft_AlleFilter_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
          ? "3"
          : DB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderTop
      }px
              ${
                SideBarLeft_AlleFilter_Farbe_Groesse_Stil_OnMouseOverAndOut
                  ? "solid"
                  : SideBarLeft_AlleFilter_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
                  ? "dotted"
                  : DB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderStyleTop
              }
              ${
                SideBarLeft_AlleFilter_Farbe_Groesse_Stil_OnMouseOverAndOut
                  ? "red"
                  : SideBarLeft_AlleFilter_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
                  ? "red"
                  : DB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderColorTop
              }`,
      borderBottom: `${
        SideBarLeft_AlleFilter_Farbe_Groesse_Stil_OnMouseOverAndOut
          ? "3"
          : SideBarLeft_AlleFilter_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
          ? "3"
          : DB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderBottom
      }px
                ${
                  SideBarLeft_AlleFilter_Farbe_Groesse_Stil_OnMouseOverAndOut
                    ? "solid"
                    : SideBarLeft_AlleFilter_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
                    ? "dotted"
                    : DB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderStyleBottom
                }
                ${
                  SideBarLeft_AlleFilter_Farbe_Groesse_Stil_OnMouseOverAndOut
                    ? "red"
                    : SideBarLeft_AlleFilter_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
                    ? "red"
                    : DB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderColorBottom
                }`,
      borderLeft: `${
        SideBarLeft_AlleFilter_Farbe_Groesse_Stil_OnMouseOverAndOut
          ? "3"
          : SideBarLeft_AlleFilter_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
          ? "3"
          : DB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderLeft
      }px
                ${
                  SideBarLeft_AlleFilter_Farbe_Groesse_Stil_OnMouseOverAndOut
                    ? "solid"
                    : SideBarLeft_AlleFilter_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
                    ? "dotted"
                    : DB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderStyleLeft
                }
                ${
                  SideBarLeft_AlleFilter_Farbe_Groesse_Stil_OnMouseOverAndOut
                    ? "red"
                    : SideBarLeft_AlleFilter_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
                    ? "red"
                    : DB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderColorLeft
                }`,
      borderRight: `${
        SideBarLeft_AlleFilter_Farbe_Groesse_Stil_OnMouseOverAndOut
          ? "3"
          : SideBarLeft_AlleFilter_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
          ? "3"
          : DB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderRight
      }px
                ${
                  SideBarLeft_AlleFilter_Farbe_Groesse_Stil_OnMouseOverAndOut
                    ? "solid"
                    : SideBarLeft_AlleFilter_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
                    ? "dotted"
                    : DB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderStyleRight
                }
                ${
                  SideBarLeft_AlleFilter_Farbe_Groesse_Stil_OnMouseOverAndOut
                    ? "red"
                    : SideBarLeft_AlleFilter_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
                    ? "red"
                    : DB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderColorRight
                }`,
      borderTopRightRadius: `${DB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderTopRightRadius}px`,
      borderTopLeftRadius: `${DB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderTopLeftRadius}px`,
      borderBottomRightRadius: `${DB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderBottomRightRadius}px`,
      borderBottomLeftRadius: `${DB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderBottomLeftRadius}px`,
      marginLeft: `${DB_LeftSidebar_MenuItem_AlleFilterButtonFF_marginLeftRight}px`,
      marginRight: `${DB_LeftSidebar_MenuItem_AlleFilterButtonFF_marginLeftRight}px`,
      marginTop: `${DB_LeftSidebar_MenuItem_AlleFilterButtonFF_marginTopBottom}px`,
      marginBottom: `${DB_LeftSidebar_MenuItem_AlleFilterButtonFF_marginTopBottom}px`,
      paddingLeft: `${DB_LeftSidebar_MenuItem_AlleFilterButtonFF_paddingLeftRight}px`,
      paddingRight: `${DB_LeftSidebar_MenuItem_AlleFilterButtonFF_paddingLeftRight}px`,
      paddingTop: `${DB_LeftSidebar_MenuItem_AlleFilterButtonFF_paddingTopBottom}px`,
      paddingBottom: `${DB_LeftSidebar_MenuItem_AlleFilterButtonFF_paddingTopBottom}px`,
      letterSpacing: `${DB_LeftSidebar_MenuItem_AlleFilterButtonFF_letterSpacing}px`,
      textShadow: `${DB_LeftSidebar_MenuItem_AlleFilterButtonFF_ShadowHorizontal}px
              ${DB_LeftSidebar_MenuItem_AlleFilterButtonFF_ShadowVertical}px
              ${DB_LeftSidebar_MenuItem_AlleFilterButtonFF_ShadowBlur}px
              ${DB_LeftSidebar_MenuItem_AlleFilterButtonFF_ShadowColor}`,
      textDecoration: `${DB_LeftSidebar_MenuItem_AlleFilterButtonFF_TextDecoration}
              ${DB_LeftSidebar_MenuItem_AlleFilterButtonFF_TextDecorationStyle}`,
      cursor: "pointer",

      "&:hover": {
        fontWeight: `${DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_FontWeight}`,
        color: `${DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_FontColor}`,
        fontSize: `${DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_FontSize}px`,
        backgroundColor: `${DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BackgroundColor}`,
        textAlign: `${DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_TextAlign}`,
        borderTop: `${DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderTop}px
              ${DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderStyleTop}
              ${DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderColorTop}`,
        borderBottom: `${DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderBottom}px
              ${DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderStyleBottom}
              ${DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderColorBottom}`,
        borderLeft: `${DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderLeft}px
              ${DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderStyleLeft}
              ${DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderColorLeft}`,
        borderRight: `${DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderRight}px
              ${DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderStyleRight}
              ${DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderColorRight}`,
        borderTopRightRadius: `${DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderTopRightRadius}px`,
        borderTopLeftRadius: `${DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderTopLeftRadius}px`,
        borderBottomRightRadius: `${DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderBottomRightRadius}px`,
        borderBottomLeftRadius: `${DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderBottomLeftRadius}px`,
        marginLeft: `${DB_LeftSidebar_MenuItem_AlleFilterButtonFF_marginLeftRight_Hover}px`,
        marginRight: `${DB_LeftSidebar_MenuItem_AlleFilterButtonFF_marginLeftRight_Hover}px`,
        marginTop: `${DB_LeftSidebar_MenuItem_AlleFilterButtonFF_marginTopBottom_Hover}px`,
        marginBottom: `${DB_LeftSidebar_MenuItem_AlleFilterButtonFF_marginTopBottom_Hover}px`,
        paddingLeft: `${DB_LeftSidebar_MenuItem_AlleFilterButtonFF_paddingLeftRight_Hover}px`,
        paddingRight: `${DB_LeftSidebar_MenuItem_AlleFilterButtonFF_paddingLeftRight_Hover}px`,
        paddingTop: `${DB_LeftSidebar_MenuItem_AlleFilterButtonFF_paddingTopBottom_Hover}px`,
        paddingBottom: `${DB_LeftSidebar_MenuItem_AlleFilterButtonFF_paddingTopBottom_Hover}px`,
        letterSpacing: `${DB_LeftSidebar_MenuItem_AlleFilterButtonFF_letterSpacing_Hover}px`,
        textShadow: `${DB_LeftSidebar_MenuItem_AlleFilterButtonFF_ShadowHorizontal_Hover}px
              ${DB_LeftSidebar_MenuItem_AlleFilterButtonFF_ShadowVertical_Hover}px
              ${DB_LeftSidebar_MenuItem_AlleFilterButtonFF_ShadowBlur_Hover}px
              ${DB_LeftSidebar_MenuItem_AlleFilterButtonFF_ShadowColor_Hover}`,
        textDecoration: `${DB_LeftSidebar_MenuItem_AlleFilterButtonFF_TextDecoration_Hover}
              ${DB_LeftSidebar_MenuItem_AlleFilterButtonFF_TextDecorationStyle_Hover}`,
      },
    },
  });

  const classes = useStyles();

  return (
    <div
      className={classes.menuItem_filterZuruecksetzen}
      onClick={resetFilterHandler}
    >
      {t("AlleFilter")}
    </div>
  );
};

export default SideBarRightAlleFilter;
