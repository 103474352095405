import { createUseStyles } from "react-jss";

const ProductCardPreisWaehrung = ({
  showProductDetailsHandler,
  productTitle,
  productFixpreis,
  productAktionspreis,
  DB_BodyProductCard_Waehrung,
  DB_fontFace_chooseFontWaehrung_FontFamilyName,
  DB_fontFace_chooseFontWaehrung_FontFamilySrc,
  DB_fontFace_chooseFontWaehrungEuro_FontFamilyName,
  DB_fontFace_chooseFontWaehrungEuro_FontFamilySrc,
  DB_LeftSidebar_MenuItem_ProduktPreis_FontWeight,
  DB_LeftSidebar_MenuItem_ProduktPreis_Hover_FontWeight,
  DB_LeftSidebar_MenuItem_ProduktPreis_FontColor,
  DB_LeftSidebar_MenuItem_ProduktPreis_Hover_FontColor,
  DB_LeftSidebar_MenuItem_ProduktPreis_FontSize,
  DB_LeftSidebar_MenuItem_ProduktPreis_Hover_FontSize,
  SideBarLeft_ProduktPreis_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut,
  SideBarLeft_ProduktPreis_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut,
  DB_LeftSidebar_MenuItem_ProduktPreis_BackgroundColor,
  DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BackgroundColor,
  DB_LeftSidebar_MenuItem_ProduktPreis_TextAlign,
  DB_LeftSidebar_MenuItem_ProduktPreis_Hover_TextAlign,
  SideBarLeft_ProduktPreis_Farbe_Groesse_Stil_OnMouseOverAndOut,
  SideBarLeft_ProduktPreis_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut,
  DB_LeftSidebar_MenuItem_ProduktPreis_BorderTop,
  DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderTop,
  DB_LeftSidebar_MenuItem_ProduktPreis_BorderStyleTop,
  DB_LeftSidebar_MenuItem_ProduktPreis_BorderColorTop,
  DB_LeftSidebar_MenuItem_ProduktPreis_BorderBottom,
  DB_LeftSidebar_MenuItem_ProduktPreis_BorderStyleBottom,
  DB_LeftSidebar_MenuItem_ProduktPreis_BorderColorBottom,
  DB_LeftSidebar_MenuItem_ProduktPreis_BorderLeft,
  DB_LeftSidebar_MenuItem_ProduktPreis_BorderStyleLeft,
  DB_LeftSidebar_MenuItem_ProduktPreis_BorderColorLeft,
  DB_LeftSidebar_MenuItem_ProduktPreis_BorderRight,
  DB_LeftSidebar_MenuItem_ProduktPreis_BorderStyleRight,
  DB_LeftSidebar_MenuItem_ProduktPreis_BorderColorRight,

  DB_LeftSidebar_MenuItem_ProduktPreis_BorderTopRightRadius,
  DB_LeftSidebar_MenuItem_ProduktPreis_BorderTopLeftRadius,
  DB_LeftSidebar_MenuItem_ProduktPreis_BorderBottomRightRadius,
  DB_LeftSidebar_MenuItem_ProduktPreis_BorderBottomLeftRadius,

  DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderTopRightRadius,
  DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderTopLeftRadius,
  DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderBottomRightRadius,
  DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderBottomLeftRadius,

  DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderStyleTop,
  DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderColorTop,
  DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderBottom,
  DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderStyleBottom,

  DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderColorBottom,
  DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderLeft,
  DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderStyleLeft,
  DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderColorLeft,

  DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderRight,
  DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderStyleRight,
  DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderColorRight,

  DB_LeftSidebar_MenuItem_ProduktPreis_marginTopBottom,
  DB_LeftSidebar_MenuItem_ProduktPreis_marginLeftRight,
  DB_LeftSidebar_MenuItem_ProduktPreis_paddingLeftRight,
  DB_LeftSidebar_MenuItem_ProduktPreis_paddingTopBottom,

  DB_LeftSidebar_MenuItem_ProduktPreis_letterSpacing,
  DB_LeftSidebar_MenuItem_ProduktPreis_ShadowHorizontal,
  DB_LeftSidebar_MenuItem_ProduktPreis_ShadowVertical,
  DB_LeftSidebar_MenuItem_ProduktPreis_ShadowBlur,
  DB_LeftSidebar_MenuItem_ProduktPreis_ShadowColor,
  DB_LeftSidebar_MenuItem_ProduktPreis_TextDecoration,
  DB_LeftSidebar_MenuItem_ProduktPreis_TextDecorationStyle,

  DB_LeftSidebar_MenuItem_ProduktPreis_marginTopBottom_Hover,
  DB_LeftSidebar_MenuItem_ProduktPreis_marginLeftRight_Hover,
  DB_LeftSidebar_MenuItem_ProduktPreis_paddingLeftRight_Hover,
  DB_LeftSidebar_MenuItem_ProduktPreis_paddingTopBottom_Hover,

  DB_LeftSidebar_MenuItem_ProduktPreis_letterSpacing_Hover,
  DB_LeftSidebar_MenuItem_ProduktPreis_ShadowHorizontal_Hover,
  DB_LeftSidebar_MenuItem_ProduktPreis_ShadowVertical_Hover,
  DB_LeftSidebar_MenuItem_ProduktPreis_ShadowBlur_Hover,
  DB_LeftSidebar_MenuItem_ProduktPreis_ShadowColor_Hover,
  DB_LeftSidebar_MenuItem_ProduktPreis_TextDecoration_Hover,
  DB_LeftSidebar_MenuItem_ProduktPreis_TextDecorationStyle_Hover,

  DB_LeftSidebar_MenuItem_ProduktWaehrung_FontWeight,
  DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_FontWeight,
  DB_LeftSidebar_MenuItem_ProduktWaehrung_FontColor,
  DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_FontColor,
  DB_LeftSidebar_MenuItem_ProduktWaehrung_FontSize,
  DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_FontSize,
  SideBarLeft_ProduktWaehrung_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut,
  SideBarLeft_ProduktWaehrung_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut,
  DB_LeftSidebar_MenuItem_ProduktWaehrung_BackgroundColor,
  DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BackgroundColor,
  DB_LeftSidebar_MenuItem_ProduktWaehrung_TextAlign,
  DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_TextAlign,
  SideBarLeft_ProduktWaehrung_Farbe_Groesse_Stil_OnMouseOverAndOut,
  SideBarLeft_ProduktWaehrung_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut,
  DB_LeftSidebar_MenuItem_ProduktWaehrung_BorderTop,
  DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderTop,
  DB_LeftSidebar_MenuItem_ProduktWaehrung_BorderStyleTop,
  DB_LeftSidebar_MenuItem_ProduktWaehrung_BorderColorTop,
  DB_LeftSidebar_MenuItem_ProduktWaehrung_BorderBottom,
  DB_LeftSidebar_MenuItem_ProduktWaehrung_BorderStyleBottom,
  DB_LeftSidebar_MenuItem_ProduktWaehrung_BorderColorBottom,
  DB_LeftSidebar_MenuItem_ProduktWaehrung_BorderLeft,
  DB_LeftSidebar_MenuItem_ProduktWaehrung_BorderStyleLeft,
  DB_LeftSidebar_MenuItem_ProduktWaehrung_BorderColorLeft,
  DB_LeftSidebar_MenuItem_ProduktWaehrung_BorderRight,
  DB_LeftSidebar_MenuItem_ProduktWaehrung_BorderStyleRight,
  DB_LeftSidebar_MenuItem_ProduktWaehrung_BorderColorRight,

  DB_LeftSidebar_MenuItem_ProduktWaehrung_BorderTopRightRadius,
  DB_LeftSidebar_MenuItem_ProduktWaehrung_BorderTopLeftRadius,
  DB_LeftSidebar_MenuItem_ProduktWaehrung_BorderBottomRightRadius,
  DB_LeftSidebar_MenuItem_ProduktWaehrung_BorderBottomLeftRadius,

  DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderTopRightRadius,
  DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderTopLeftRadius,
  DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderBottomRightRadius,
  DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderBottomLeftRadius,

  DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderStyleTop,
  DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderColorTop,
  DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderBottom,
  DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderStyleBottom,

  DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderColorBottom,
  DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderLeft,
  DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderStyleLeft,
  DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderColorLeft,

  DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderRight,
  DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderStyleRight,
  DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderColorRight,

  DB_LeftSidebar_MenuItem_ProduktWaehrung_marginTopBottom,
  DB_LeftSidebar_MenuItem_ProduktWaehrung_marginLeftRight,
  DB_LeftSidebar_MenuItem_ProduktWaehrung_paddingLeftRight,
  DB_LeftSidebar_MenuItem_ProduktWaehrung_paddingTopBottom,

  DB_LeftSidebar_MenuItem_ProduktWaehrung_letterSpacing,
  DB_LeftSidebar_MenuItem_ProduktWaehrung_ShadowHorizontal,
  DB_LeftSidebar_MenuItem_ProduktWaehrung_ShadowVertical,
  DB_LeftSidebar_MenuItem_ProduktWaehrung_ShadowBlur,
  DB_LeftSidebar_MenuItem_ProduktWaehrung_ShadowColor,
  DB_LeftSidebar_MenuItem_ProduktWaehrung_TextDecoration,
  DB_LeftSidebar_MenuItem_ProduktWaehrung_TextDecorationStyle,

  DB_LeftSidebar_MenuItem_ProduktWaehrung_marginTopBottom_Hover,
  DB_LeftSidebar_MenuItem_ProduktWaehrung_marginLeftRight_Hover,
  DB_LeftSidebar_MenuItem_ProduktWaehrung_paddingLeftRight_Hover,
  DB_LeftSidebar_MenuItem_ProduktWaehrung_paddingTopBottom_Hover,

  DB_LeftSidebar_MenuItem_ProduktWaehrung_letterSpacing_Hover,
  DB_LeftSidebar_MenuItem_ProduktWaehrung_ShadowHorizontal_Hover,
  DB_LeftSidebar_MenuItem_ProduktWaehrung_ShadowVertical_Hover,
  DB_LeftSidebar_MenuItem_ProduktWaehrung_ShadowBlur_Hover,
  DB_LeftSidebar_MenuItem_ProduktWaehrung_ShadowColor_Hover,
  DB_LeftSidebar_MenuItem_ProduktWaehrung_TextDecoration_Hover,
  DB_LeftSidebar_MenuItem_ProduktWaehrung_TextDecorationStyle_Hover,
}) => {
  const useStyles = createUseStyles({
    ProduktPreisContainer: {
      textAlign: `${DB_LeftSidebar_MenuItem_ProduktPreis_TextAlign}`,
    },

    ProduktPreis: {
      display: "inline-block",
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      fontFamily: `${DB_fontFace_chooseFontWaehrung_FontFamilyName}`,
      fontWeight: `${DB_LeftSidebar_MenuItem_ProduktPreis_FontWeight}`,
      color: `${DB_LeftSidebar_MenuItem_ProduktPreis_FontColor}`,
      fontSize: `${DB_LeftSidebar_MenuItem_ProduktPreis_FontSize}px`,
      backgroundColor: `${
        SideBarLeft_ProduktPreis_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut
          ? "rgba(255,0,0,0.5)"
          : SideBarLeft_ProduktPreis_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut
          ? "rgba(255,0,0,0.3)"
          : DB_LeftSidebar_MenuItem_ProduktPreis_BackgroundColor
      }`,

      borderTop: `${
        SideBarLeft_ProduktPreis_Farbe_Groesse_Stil_OnMouseOverAndOut
          ? "3"
          : SideBarLeft_ProduktPreis_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
          ? "3"
          : DB_LeftSidebar_MenuItem_ProduktPreis_BorderTop
      }px
        ${
          SideBarLeft_ProduktPreis_Farbe_Groesse_Stil_OnMouseOverAndOut
            ? "solid"
            : SideBarLeft_ProduktPreis_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
            ? "dotted"
            : DB_LeftSidebar_MenuItem_ProduktPreis_BorderStyleTop
        }
        ${
          SideBarLeft_ProduktPreis_Farbe_Groesse_Stil_OnMouseOverAndOut
            ? "red"
            : SideBarLeft_ProduktPreis_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
            ? "red"
            : DB_LeftSidebar_MenuItem_ProduktPreis_BorderColorTop
        }`,
      borderBottom: `${
        SideBarLeft_ProduktPreis_Farbe_Groesse_Stil_OnMouseOverAndOut
          ? "3"
          : SideBarLeft_ProduktPreis_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
          ? "3"
          : DB_LeftSidebar_MenuItem_ProduktPreis_BorderBottom
      }px
        ${
          SideBarLeft_ProduktPreis_Farbe_Groesse_Stil_OnMouseOverAndOut
            ? "solid"
            : SideBarLeft_ProduktPreis_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
            ? "dotted"
            : DB_LeftSidebar_MenuItem_ProduktPreis_BorderStyleBottom
        }
        ${
          SideBarLeft_ProduktPreis_Farbe_Groesse_Stil_OnMouseOverAndOut
            ? "red"
            : SideBarLeft_ProduktPreis_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
            ? "red"
            : DB_LeftSidebar_MenuItem_ProduktPreis_BorderColorBottom
        }`,
      borderLeft: `${
        SideBarLeft_ProduktPreis_Farbe_Groesse_Stil_OnMouseOverAndOut
          ? "3"
          : SideBarLeft_ProduktPreis_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
          ? "3"
          : DB_LeftSidebar_MenuItem_ProduktPreis_BorderLeft
      }px
        ${
          SideBarLeft_ProduktPreis_Farbe_Groesse_Stil_OnMouseOverAndOut
            ? "solid"
            : SideBarLeft_ProduktPreis_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
            ? "dotted"
            : DB_LeftSidebar_MenuItem_ProduktPreis_BorderStyleLeft
        }
        ${
          SideBarLeft_ProduktPreis_Farbe_Groesse_Stil_OnMouseOverAndOut
            ? "red"
            : SideBarLeft_ProduktPreis_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
            ? "red"
            : DB_LeftSidebar_MenuItem_ProduktPreis_BorderColorLeft
        }`,
      borderRight: `${
        SideBarLeft_ProduktPreis_Farbe_Groesse_Stil_OnMouseOverAndOut
          ? "3"
          : SideBarLeft_ProduktPreis_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
          ? "3"
          : DB_LeftSidebar_MenuItem_ProduktPreis_BorderRight
      }px
        ${
          SideBarLeft_ProduktPreis_Farbe_Groesse_Stil_OnMouseOverAndOut
            ? "solid"
            : SideBarLeft_ProduktPreis_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
            ? "dotted"
            : DB_LeftSidebar_MenuItem_ProduktPreis_BorderStyleRight
        }
        ${
          SideBarLeft_ProduktPreis_Farbe_Groesse_Stil_OnMouseOverAndOut
            ? "red"
            : SideBarLeft_ProduktPreis_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
            ? "red"
            : DB_LeftSidebar_MenuItem_ProduktPreis_BorderColorRight
        }`,
      borderTopRightRadius: `${DB_LeftSidebar_MenuItem_ProduktPreis_BorderTopRightRadius}px`,
      borderTopLeftRadius: `${DB_LeftSidebar_MenuItem_ProduktPreis_BorderTopLeftRadius}px`,
      borderBottomRightRadius: `${DB_LeftSidebar_MenuItem_ProduktPreis_BorderBottomRightRadius}px`,
      borderBottomLeftRadius: `${DB_LeftSidebar_MenuItem_ProduktPreis_BorderBottomLeftRadius}px`,
      marginLeft:
        `${DB_LeftSidebar_MenuItem_ProduktPreis_marginLeftRight}` < 0
          ? Math.abs(DB_LeftSidebar_MenuItem_ProduktPreis_marginLeftRight)
          : `${0}` + `px`,
      marginRight:
        `${DB_LeftSidebar_MenuItem_ProduktPreis_marginLeftRight}` > 0
          ? Math.abs(DB_LeftSidebar_MenuItem_ProduktPreis_marginLeftRight)
          : `${0}` + `px`,
      marginTop:
        `${DB_LeftSidebar_MenuItem_ProduktPreis_marginTopBottom}` > 0
          ? Math.abs(DB_LeftSidebar_MenuItem_ProduktPreis_marginTopBottom)
          : `${0}` + `px`,
      marginBottom:
        `${DB_LeftSidebar_MenuItem_ProduktPreis_marginTopBottom}` < 0
          ? Math.abs(DB_LeftSidebar_MenuItem_ProduktPreis_marginTopBottom)
          : `${0}` + `px`,
      paddingLeft: `${DB_LeftSidebar_MenuItem_ProduktPreis_paddingLeftRight}px`,
      paddingRight: `${DB_LeftSidebar_MenuItem_ProduktPreis_paddingLeftRight}px`,
      paddingTop: `${DB_LeftSidebar_MenuItem_ProduktPreis_paddingTopBottom}px`,
      paddingBottom: `${DB_LeftSidebar_MenuItem_ProduktPreis_paddingTopBottom}px`,
      letterSpacing: `${DB_LeftSidebar_MenuItem_ProduktPreis_letterSpacing}px`,
      textShadow: `${DB_LeftSidebar_MenuItem_ProduktPreis_ShadowHorizontal}px
        ${DB_LeftSidebar_MenuItem_ProduktPreis_ShadowVertical}px
        ${DB_LeftSidebar_MenuItem_ProduktPreis_ShadowBlur}px
        ${DB_LeftSidebar_MenuItem_ProduktPreis_ShadowColor}`,
      textDecoration: `${DB_LeftSidebar_MenuItem_ProduktPreis_TextDecoration}
        ${DB_LeftSidebar_MenuItem_ProduktPreis_TextDecorationStyle}`,
      cursor: "pointer",

      "&:hover": {
        fontWeight: `${DB_LeftSidebar_MenuItem_ProduktPreis_Hover_FontWeight}`,
        color: `${DB_LeftSidebar_MenuItem_ProduktPreis_Hover_FontColor}`,
        fontSize: `${DB_LeftSidebar_MenuItem_ProduktPreis_Hover_FontSize}px`,
        backgroundColor: `${DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BackgroundColor}`,
        textAlign: `${DB_LeftSidebar_MenuItem_ProduktPreis_Hover_TextAlign}`,
        borderTop: `${DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderTop}px
        ${DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderStyleTop}
        ${DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderColorTop}`,
        borderBottom: `${DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderBottom}px
        ${DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderStyleBottom}
        ${DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderColorBottom}`,
        borderLeft: `${DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderLeft}px
        ${DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderStyleLeft}
        ${DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderColorLeft}`,
        borderRight: `${DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderRight}px
        ${DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderStyleRight}
        ${DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderColorRight}`,
        borderRopRightRadius: `${DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderTopRightRadius}px`,
        borderTopLeftRadius: `${DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderTopLeftRadius}px`,
        borderBottomRightRadius: `${DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderBottomRightRadius}px`,
        borderBottomLeftRadius: `${DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderBottomLeftRadius}px`,

        marginLeft:
          `${DB_LeftSidebar_MenuItem_ProduktPreis_marginLeftRight_Hover}` < 0
            ? Math.abs(
                DB_LeftSidebar_MenuItem_ProduktPreis_marginLeftRight_Hover
              )
            : `${0}` + `px`,
        marginRight:
          `${DB_LeftSidebar_MenuItem_ProduktPreis_marginLeftRight_Hover}` > 0
            ? Math.abs(
                DB_LeftSidebar_MenuItem_ProduktPreis_marginLeftRight_Hover
              )
            : `${0}` + `px`,
        marginTop:
          `${DB_LeftSidebar_MenuItem_ProduktPreis_marginTopBottom_Hover}` > 0
            ? Math.abs(
                DB_LeftSidebar_MenuItem_ProduktPreis_marginTopBottom_Hover
              )
            : `${0}` + `px`,
        marginBottom:
          `${DB_LeftSidebar_MenuItem_ProduktPreis_marginTopBottom_Hover}` < 0
            ? Math.abs(
                DB_LeftSidebar_MenuItem_ProduktPreis_marginTopBottom_Hover
              )
            : `${0}` + `px`,

        paddingLeft: `${DB_LeftSidebar_MenuItem_ProduktPreis_paddingLeftRight_Hover}px`,
        paddingRight: `${DB_LeftSidebar_MenuItem_ProduktPreis_paddingLeftRight_Hover}px`,
        paddingTop: `${DB_LeftSidebar_MenuItem_ProduktPreis_paddingTopBottom_Hover}px`,
        paddingBottom: `${DB_LeftSidebar_MenuItem_ProduktPreis_paddingTopBottom_Hover}px`,
        letterSpacing: `${DB_LeftSidebar_MenuItem_ProduktPreis_letterSpacing_Hover}px`,
        textShadow: `${DB_LeftSidebar_MenuItem_ProduktPreis_ShadowHorizontal_Hover}px
        ${DB_LeftSidebar_MenuItem_ProduktPreis_ShadowVertical_Hover}px
        ${DB_LeftSidebar_MenuItem_ProduktPreis_ShadowBlur_Hover}px
        ${DB_LeftSidebar_MenuItem_ProduktPreis_ShadowColor_Hover}`,
        textDecoration: `${DB_LeftSidebar_MenuItem_ProduktPreis_TextDecoration_Hover}
        ${DB_LeftSidebar_MenuItem_ProduktPreis_TextDecorationStyle_Hover}`,
      },

      ProduktWaehrung: {
        display: "inline-block",
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",

        fontFamily: `${DB_fontFace_chooseFontWaehrungEuro_FontFamilyName}`,
        fontWeight: `${DB_LeftSidebar_MenuItem_ProduktWaehrung_FontWeight}`,
        color: `${DB_LeftSidebar_MenuItem_ProduktWaehrung_FontColor}`,
        fontSize: `${DB_LeftSidebar_MenuItem_ProduktWaehrung_FontSize}px`,
        backgroundColor: `${
          SideBarLeft_ProduktWaehrung_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut
            ? "rgba(255,0,0,0.5)"
            : SideBarLeft_ProduktWaehrung_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut
            ? "rgba(255,0,0,0.3)"
            : DB_LeftSidebar_MenuItem_ProduktWaehrung_BackgroundColor
        }`,
        textAlign: `${DB_LeftSidebar_MenuItem_ProduktWaehrung_TextAlign}`,
        borderTop: `${
          SideBarLeft_ProduktWaehrung_Farbe_Groesse_Stil_OnMouseOverAndOut
            ? "3"
            : SideBarLeft_ProduktWaehrung_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
            ? "3"
            : DB_LeftSidebar_MenuItem_ProduktWaehrung_BorderTop
        }px
          ${
            SideBarLeft_ProduktWaehrung_Farbe_Groesse_Stil_OnMouseOverAndOut
              ? "solid"
              : SideBarLeft_ProduktWaehrung_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
              ? "dotted"
              : DB_LeftSidebar_MenuItem_ProduktWaehrung_BorderStyleTop
          }
          ${
            SideBarLeft_ProduktWaehrung_Farbe_Groesse_Stil_OnMouseOverAndOut
              ? "red"
              : SideBarLeft_ProduktWaehrung_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
              ? "red"
              : DB_LeftSidebar_MenuItem_ProduktWaehrung_BorderColorTop
          }`,
        borderBottom: `${
          SideBarLeft_ProduktWaehrung_Farbe_Groesse_Stil_OnMouseOverAndOut
            ? "3"
            : SideBarLeft_ProduktWaehrung_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
            ? "3"
            : DB_LeftSidebar_MenuItem_ProduktWaehrung_BorderBottom
        }px
          ${
            SideBarLeft_ProduktWaehrung_Farbe_Groesse_Stil_OnMouseOverAndOut
              ? "solid"
              : SideBarLeft_ProduktWaehrung_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
              ? "dotted"
              : DB_LeftSidebar_MenuItem_ProduktWaehrung_BorderStyleBottom
          }
          ${
            SideBarLeft_ProduktWaehrung_Farbe_Groesse_Stil_OnMouseOverAndOut
              ? "red"
              : SideBarLeft_ProduktWaehrung_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
              ? "red"
              : DB_LeftSidebar_MenuItem_ProduktWaehrung_BorderColorBottom
          }`,
        borderLeft: `${
          SideBarLeft_ProduktWaehrung_Farbe_Groesse_Stil_OnMouseOverAndOut
            ? "3"
            : SideBarLeft_ProduktWaehrung_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
            ? "3"
            : DB_LeftSidebar_MenuItem_ProduktWaehrung_BorderLeft
        }px
          ${
            SideBarLeft_ProduktWaehrung_Farbe_Groesse_Stil_OnMouseOverAndOut
              ? "solid"
              : SideBarLeft_ProduktWaehrung_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
              ? "dotted"
              : DB_LeftSidebar_MenuItem_ProduktWaehrung_BorderStyleLeft
          }
          ${
            SideBarLeft_ProduktWaehrung_Farbe_Groesse_Stil_OnMouseOverAndOut
              ? "red"
              : SideBarLeft_ProduktWaehrung_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
              ? "red"
              : DB_LeftSidebar_MenuItem_ProduktWaehrung_BorderColorLeft
          }`,
        borderRight: `${
          SideBarLeft_ProduktWaehrung_Farbe_Groesse_Stil_OnMouseOverAndOut
            ? "3"
            : SideBarLeft_ProduktWaehrung_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
            ? "3"
            : DB_LeftSidebar_MenuItem_ProduktWaehrung_BorderRight
        }px
          ${
            SideBarLeft_ProduktWaehrung_Farbe_Groesse_Stil_OnMouseOverAndOut
              ? "solid"
              : SideBarLeft_ProduktWaehrung_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
              ? "dotted"
              : DB_LeftSidebar_MenuItem_ProduktWaehrung_BorderStyleRight
          }
          ${
            SideBarLeft_ProduktWaehrung_Farbe_Groesse_Stil_OnMouseOverAndOut
              ? "red"
              : SideBarLeft_ProduktWaehrung_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
              ? "red"
              : DB_LeftSidebar_MenuItem_ProduktWaehrung_BorderColorRight
          }`,
        borderTopRightRadius: `${DB_LeftSidebar_MenuItem_ProduktWaehrung_BorderTopRightRadius}px`,
        borderTopLeftRadius: `${DB_LeftSidebar_MenuItem_ProduktWaehrung_BorderTopLeftRadius}px`,
        borderBottomRightRadius: `${DB_LeftSidebar_MenuItem_ProduktWaehrung_BorderBottomRightRadius}px`,
        borderBottomLeftRadius: `${DB_LeftSidebar_MenuItem_ProduktWaehrung_BorderBottomLeftRadius}px`,
        marginLeft:
          `${DB_LeftSidebar_MenuItem_ProduktWaehrung_marginLeftRight}` < 0
            ? Math.abs(DB_LeftSidebar_MenuItem_ProduktWaehrung_marginLeftRight)
            : `${0}` + `px`,
        marginRight:
          `${DB_LeftSidebar_MenuItem_ProduktWaehrung_marginLeftRight}` > 0
            ? Math.abs(DB_LeftSidebar_MenuItem_ProduktWaehrung_marginLeftRight)
            : `${0}` + `px`,
        marginTop:
          `{DB_LeftSidebar_MenuItem_ProduktWaehrung_marginTopBottom}` > 0
            ? Math.abs(DB_LeftSidebar_MenuItem_ProduktWaehrung_marginTopBottom)
            : `${0}` + `px`,
        marginBottom:
          `${DB_LeftSidebar_MenuItem_ProduktWaehrung_marginTopBottom}` < 0
            ? Math.abs(DB_LeftSidebar_MenuItem_ProduktWaehrung_marginTopBottom)
            : `${0}` + `px`,
        paddingLeft: `${DB_LeftSidebar_MenuItem_ProduktWaehrung_paddingLeftRight}px`,
        paddingRight: `${DB_LeftSidebar_MenuItem_ProduktWaehrung_paddingLeftRight}px`,
        paddingTop: `${DB_LeftSidebar_MenuItem_ProduktWaehrung_paddingTopBottom}px`,
        paddingBottom: `${DB_LeftSidebar_MenuItem_ProduktWaehrung_paddingTopBottom}px`,
        letterSpacing: `${DB_LeftSidebar_MenuItem_ProduktWaehrung_letterSpacing}px`,
        textShadow: `${DB_LeftSidebar_MenuItem_ProduktWaehrung_ShadowHorizontal}px
          ${DB_LeftSidebar_MenuItem_ProduktWaehrung_ShadowVertical}px
          ${DB_LeftSidebar_MenuItem_ProduktWaehrung_ShadowBlur}px
          ${DB_LeftSidebar_MenuItem_ProduktWaehrung_ShadowColor}`,
        textDecoration: `${DB_LeftSidebar_MenuItem_ProduktWaehrung_TextDecoration}
          ${DB_LeftSidebar_MenuItem_ProduktWaehrung_TextDecorationStyle}`,
        cursor: "pointer",

        "&:hover": {
          fontWeight: `${DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_FontWeight}`,
          color: `${DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_FontColor}`,
          fontSize: `${DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_FontSize}px`,
          backgroundColor: `${DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BackgroundColor}`,
          textAlign: `${DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_TextAlign}`,
          borderTop: `${DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderTop}px
          ${DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderStyleTop}
          ${DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderColorTop}`,
          borderBottom: `${DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderBottom}px
          ${DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderStyleBottom}
          ${DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderColorBottom}`,
          borderLeft: `${DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderLeft}px
          ${DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderStyleLeft}
          ${DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderColorLeft}`,
          borderRight: `${DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderRight}px
          ${DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderStyleRight}
          ${DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderColorRight}`,
          borderTopRightRadius: `${DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderTopRightRadius}px`,
          borderTopLeftRadius: `${DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderTopLeftRadius}px`,
          borderBottomRighRadius: `${DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderBottomRightRadius}px`,
          borderBottomLeftRadius: `${DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderBottomLeftRadius}px`,

          marginLeft:
            `${DB_LeftSidebar_MenuItem_ProduktWaehrung_marginLeftRight_Hover}` <
            0
              ? Math.abs(
                  DB_LeftSidebar_MenuItem_ProduktWaehrung_marginLeftRight_Hover
                )
              : `${0}` + `px`,
          marginRight:
            `${DB_LeftSidebar_MenuItem_ProduktWaehrung_marginLeftRight_Hover}` >
            0
              ? Math.abs(
                  DB_LeftSidebar_MenuItem_ProduktWaehrung_marginLeftRight_Hover
                )
              : `${0}` + `px`,
          marginTop:
            `${DB_LeftSidebar_MenuItem_ProduktWaehrung_marginTopBottom_Hover}` >
            0
              ? Math.abs(
                  DB_LeftSidebar_MenuItem_ProduktWaehrung_marginTopBottom_Hover
                )
              : `${0}` + `px`,
          marginBottom:
            `${DB_LeftSidebar_MenuItem_ProduktWaehrung_marginTopBottom_Hover}` <
            0
              ? Math.abs(
                  DB_LeftSidebar_MenuItem_ProduktWaehrung_marginTopBottom_Hover
                )
              : `${0}` + `px`,
          paddingLeft: `${DB_LeftSidebar_MenuItem_ProduktWaehrung_paddingLeftRight_Hover}px`,
          paddingRight: `${DB_LeftSidebar_MenuItem_ProduktWaehrung_paddingLeftRight_Hover}px`,
          paddingTop: `${DB_LeftSidebar_MenuItem_ProduktWaehrung_paddingTopBottom_Hover}px`,
          paddingBottom: `${DB_LeftSidebar_MenuItem_ProduktWaehrung_paddingTopBottom_Hover}px`,
          letterSpacing: `${DB_LeftSidebar_MenuItem_ProduktWaehrung_letterSpacing_Hover}px`,
          textShadow: `${DB_LeftSidebar_MenuItem_ProduktWaehrung_ShadowHorizontal_Hover}px
          ${DB_LeftSidebar_MenuItem_ProduktWaehrung_ShadowVertical_Hover}px
          ${DB_LeftSidebar_MenuItem_ProduktWaehrung_ShadowBlur_Hover}px
          ${DB_LeftSidebar_MenuItem_ProduktWaehrung_ShadowColor_Hover}`,
          textDecoration: `${DB_LeftSidebar_MenuItem_ProduktWaehrung_TextDecoration_Hover}
          ${DB_LeftSidebar_MenuItem_ProduktWaehrung_TextDecorationStyle_Hover}`,
        },
      },
    },
  });

  const classes = useStyles();
  return (
    <>
      {productFixpreis ? (
        <div className={classes.ProduktPreisContainer}>
          <div
            className={classes.ProduktPreis}
            onClick={(productIdentifier) =>
              showProductDetailsHandler(productTitle)
            }
          >
            {productFixpreis} {DB_BodyProductCard_Waehrung}
          </div>

          {productAktionspreis ? (
            <div
              className={classes.ProduktWaehrung}
              onClick={(productIdentifier) =>
                showProductDetailsHandler(productTitle)
              }
            >
              {productAktionspreis} {DB_BodyProductCard_Waehrung}
            </div>
          ) : (
            ""
          )}
        </div>
      ) : (
        <></>
      )}
    </>
  );
};
export default ProductCardPreisWaehrung;
