import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";

const ProductFilterTopBarSpecialRight = ({
  paginationStart,
  paginationEnd,
  allProductsStatePagination,
  productBefore,
  productNext,
  columnNumber,
  pageAnzahlCounter,
  DB_fontFace_FontFamilyName,
  columnNumberChange,
  changePreisAlphabeticUswHandler,

  SideBarLeft_SortierungTop_Farbe_Groesse_Stil_OnMouseOverAndOut,
  SideBarLeft_SortierungTop_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut,
  SideBarLeft_SortierungTop_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut,
  SideBarLeft_SortierungTop_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut,

  DB_fontFace_FilterTopBar_FontFamilyName,
  DB_fontFace_FilterTopBar_FontFamilySrc,
  DB_LeftSidebar_MenuItem_SortierungTop_BackgroundColor,
  DB_LeftSidebar_MenuItem_SortierungTop_FontColor,
  DB_LeftSidebar_MenuItem_SortierungTop_BorderTop,
  DB_LeftSidebar_MenuItem_SortierungTop_BorderStyleTop,
  DB_LeftSidebar_MenuItem_SortierungTop_BorderColorTop,
  DB_LeftSidebar_MenuItem_SortierungTop_BorderBottom,
  DB_LeftSidebar_MenuItem_SortierungTop_BorderStyleBottom,
  DB_LeftSidebar_MenuItem_SortierungTop_BorderColorBottom,
  DB_LeftSidebar_MenuItem_SortierungTop_BorderLeft,
  DB_LeftSidebar_MenuItem_SortierungTop_BorderStyleLeft,
  DB_LeftSidebar_MenuItem_SortierungTop_BorderColorLeft,
  DB_LeftSidebar_MenuItem_SortierungTop_BorderRight,
  DB_LeftSidebar_MenuItem_SortierungTop_BorderStyleRight,
  DB_LeftSidebar_MenuItem_SortierungTop_BorderColorRight,
  DB_LeftSidebar_MenuItem_SortierungTop_BorderTopRightRadius,
  DB_LeftSidebar_MenuItem_SortierungTop_BorderTopLeftRadius,
  DB_LeftSidebar_MenuItem_SortierungTop_BorderBottomRightRadius,
  DB_LeftSidebar_MenuItem_SortierungTop_BorderBottomLeftRadius,
  DB_LeftSidebar_MenuItem_SortierungTop_ShadowHorizontal,
  DB_LeftSidebar_MenuItem_SortierungTop_ShadowVertical,
  DB_LeftSidebar_MenuItem_SortierungTop_ShadowBlur,
  DB_LeftSidebar_MenuItem_SortierungTop_ShadowColor,

  DB_LeftSidebar_MenuItem_SortierungTop_Hover_BackgroundColor,
  DB_LeftSidebar_MenuItem_SortierungTop_Hover_FontColor,
  DB_LeftSidebar_MenuItem_SortierungTop_Hover_BorderTop,
  DB_LeftSidebar_MenuItem_SortierungTop_Hover_BorderStyleTop,
  DB_LeftSidebar_MenuItem_SortierungTop_Hover_BorderColorTop,
  DB_LeftSidebar_MenuItem_SortierungTop_Hover_BorderBottom,
  DB_LeftSidebar_MenuItem_SortierungTop_Hover_BorderStyleBottom,
  DB_LeftSidebar_MenuItem_SortierungTop_Hover_BorderColorBottom,
  DB_LeftSidebar_MenuItem_SortierungTop_Hover_BorderLeft,
  DB_LeftSidebar_MenuItem_SortierungTop_Hover_BorderStyleLeft,
  DB_LeftSidebar_MenuItem_SortierungTop_Hover_BorderColorLeft,
  DB_LeftSidebar_MenuItem_SortierungTop_Hover_BorderRight,
  DB_LeftSidebar_MenuItem_SortierungTop_Hover_BorderStyleRight,
  DB_LeftSidebar_MenuItem_SortierungTop_Hover_BorderColorRight,
  DB_LeftSidebar_MenuItem_SortierungTop_Hover_BorderTopRightRadius,
  DB_LeftSidebar_MenuItem_SortierungTop_Hover_BorderTopLeftRadius,
  DB_LeftSidebar_MenuItem_SortierungTop_Hover_BorderBottomRightRadius,
  DB_LeftSidebar_MenuItem_SortierungTop_Hover_BorderBottomLeftRadius,
  DB_LeftSidebar_MenuItem_SortierungTop_Hover_ShadowHorizontal,
  DB_LeftSidebar_MenuItem_SortierungTop_Hover_ShadowVertical,
  DB_LeftSidebar_MenuItem_SortierungTop_Hover_ShadowBlur,
  DB_LeftSidebar_MenuItem_SortierungTop_Hover_ShadowColor,
}) => {
  const { t } = useTranslation();

  const useStyles = createUseStyles({
    selectAufsteigendAbsteigendSpecial: {
      width: "100%",
      marginBottom: "5px",
      fontFamily: `${DB_fontFace_FilterTopBar_FontFamilyName}`,
      backgroundColor: `${DB_LeftSidebar_MenuItem_SortierungTop_BackgroundColor}`,
      color: `${DB_LeftSidebar_MenuItem_SortierungTop_FontColor}`,
      height: "5px",
      border: `1px solid ${DB_LeftSidebar_MenuItem_SortierungTop_FontColor}`,
    },

    OneTwoThreeFour: {
      color: `${DB_LeftSidebar_MenuItem_SortierungTop_FontColor}`,
      "&:hover": {
        color: `${DB_LeftSidebar_MenuItem_SortierungTop_FontColor}`,
      },
    },
    selectTopSubSubBar: {
      border: `1px solid ${DB_LeftSidebar_MenuItem_SortierungTop_FontColor}`,

      "&:active": {
        border: `1px solid ${DB_LeftSidebar_MenuItem_SortierungTop_FontColor}`,
      },
      "&:focus": {
        border: `1px solid ${DB_LeftSidebar_MenuItem_SortierungTop_FontColor}`,
      },
    },
    selectTopSubSubBarOption: {
      fontFamily: `${DB_fontFace_FilterTopBar_FontFamilyName}`,
      background: `${DB_LeftSidebar_MenuItem_SortierungTop_BackgroundColor}`,
      border: `1px solid ${DB_LeftSidebar_MenuItem_SortierungTop_FontColor}`,
    },
    infosPlusFilterButtonsSpecial: {
      padding: "5px 8px 0px 8px",
      fontFamily: `${DB_fontFace_FilterTopBar_FontFamilyName}`,
      color: `${DB_LeftSidebar_MenuItem_SortierungTop_FontColor}`,
      backgroundColor: `${
        SideBarLeft_SortierungTop_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut
          ? "rgba(255,0,0,0.5)"
          : SideBarLeft_SortierungTop_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut
          ? "rgba(255,0,0,0.3)"
          : DB_LeftSidebar_MenuItem_SortierungTop_BackgroundColor
      }`,
      textAlign: "left",

      borderTop: `${
        SideBarLeft_SortierungTop_Farbe_Groesse_Stil_OnMouseOverAndOut
          ? "3"
          : SideBarLeft_SortierungTop_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
          ? "3"
          : DB_LeftSidebar_MenuItem_SortierungTop_BorderTop
      }px
        ${
          SideBarLeft_SortierungTop_Farbe_Groesse_Stil_OnMouseOverAndOut
            ? "solid"
            : SideBarLeft_SortierungTop_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
            ? "dotted"
            : DB_LeftSidebar_MenuItem_SortierungTop_BorderStyleTop
        }
        ${
          SideBarLeft_SortierungTop_Farbe_Groesse_Stil_OnMouseOverAndOut
            ? "red"
            : SideBarLeft_SortierungTop_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
            ? "red"
            : DB_LeftSidebar_MenuItem_SortierungTop_BorderColorTop
        }`,
      borderBottom: `${
        SideBarLeft_SortierungTop_Farbe_Groesse_Stil_OnMouseOverAndOut
          ? "3"
          : SideBarLeft_SortierungTop_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
          ? "3"
          : DB_LeftSidebar_MenuItem_SortierungTop_BorderBottom
      }px
        ${
          SideBarLeft_SortierungTop_Farbe_Groesse_Stil_OnMouseOverAndOut
            ? "solid"
            : SideBarLeft_SortierungTop_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
            ? "dotted"
            : DB_LeftSidebar_MenuItem_SortierungTop_BorderStyleBottom
        }
        ${
          SideBarLeft_SortierungTop_Farbe_Groesse_Stil_OnMouseOverAndOut
            ? "red"
            : SideBarLeft_SortierungTop_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
            ? "red"
            : DB_LeftSidebar_MenuItem_SortierungTop_BorderColorBottom
        }`,
      borderLeft: `${
        SideBarLeft_SortierungTop_Farbe_Groesse_Stil_OnMouseOverAndOut
          ? "3"
          : SideBarLeft_SortierungTop_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
          ? "3"
          : DB_LeftSidebar_MenuItem_SortierungTop_BorderLeft
      }px
        ${
          SideBarLeft_SortierungTop_Farbe_Groesse_Stil_OnMouseOverAndOut
            ? "solid"
            : SideBarLeft_SortierungTop_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
            ? "dotted"
            : DB_LeftSidebar_MenuItem_SortierungTop_BorderStyleLeft
        }
        ${
          SideBarLeft_SortierungTop_Farbe_Groesse_Stil_OnMouseOverAndOut
            ? "red"
            : SideBarLeft_SortierungTop_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
            ? "red"
            : DB_LeftSidebar_MenuItem_SortierungTop_BorderColorLeft
        }`,
      borderRight: `${
        SideBarLeft_SortierungTop_Farbe_Groesse_Stil_OnMouseOverAndOut
          ? "3"
          : SideBarLeft_SortierungTop_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
          ? "3"
          : DB_LeftSidebar_MenuItem_SortierungTop_BorderRight
      }px
        ${
          SideBarLeft_SortierungTop_Farbe_Groesse_Stil_OnMouseOverAndOut
            ? "solid"
            : SideBarLeft_SortierungTop_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
            ? "dotted"
            : DB_LeftSidebar_MenuItem_SortierungTop_BorderStyleRight
        }
        ${
          SideBarLeft_SortierungTop_Farbe_Groesse_Stil_OnMouseOverAndOut
            ? "red"
            : SideBarLeft_SortierungTop_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
            ? "red"
            : DB_LeftSidebar_MenuItem_SortierungTop_BorderColorRight
        }`,
      borderTopRightRadius: `${DB_LeftSidebar_MenuItem_SortierungTop_BorderTopRightRadius}px`,
      borderTopLeftRadius: `${DB_LeftSidebar_MenuItem_SortierungTop_BorderTopLeftRadius}px`,
      borderBottomRightRadius: `${DB_LeftSidebar_MenuItem_SortierungTop_BorderBottomRightRadius}px`,
      borderBottomLeftRadius: `${DB_LeftSidebar_MenuItem_SortierungTop_BorderBottomLeftRadius}px`,
      boxShadow: `${DB_LeftSidebar_MenuItem_SortierungTop_ShadowHorizontal}px
        ${DB_LeftSidebar_MenuItem_SortierungTop_ShadowVertical}px
        ${DB_LeftSidebar_MenuItem_SortierungTop_ShadowBlur}px
        ${DB_LeftSidebar_MenuItem_SortierungTop_ShadowColor}`,
      "&:hover": {
        color: `${DB_LeftSidebar_MenuItem_SortierungTop_Hover_FontColor}`,
        backgroundColor: `${DB_LeftSidebar_MenuItem_SortierungTop_Hover_BackgroundColor}`,
        textAlign: "left",

        borderTop: `${DB_LeftSidebar_MenuItem_SortierungTop_Hover_BorderTop}px
        ${DB_LeftSidebar_MenuItem_SortierungTop_Hover_BorderStyleTop}
        ${DB_LeftSidebar_MenuItem_SortierungTop_Hover_BorderColorTop}`,
        borderBottom: `${DB_LeftSidebar_MenuItem_SortierungTop_Hover_BorderBottom}px
        ${DB_LeftSidebar_MenuItem_SortierungTop_Hover_BorderStyleBottom}
        ${DB_LeftSidebar_MenuItem_SortierungTop_Hover_BorderColorBottom}`,
        borderLeft: `${DB_LeftSidebar_MenuItem_SortierungTop_Hover_BorderLeft}px
        ${DB_LeftSidebar_MenuItem_SortierungTop_Hover_BorderStyleLeft}
        ${DB_LeftSidebar_MenuItem_SortierungTop_Hover_BorderColorLeft}`,
        borderRight: `${DB_LeftSidebar_MenuItem_SortierungTop_Hover_BorderRight}px
        ${DB_LeftSidebar_MenuItem_SortierungTop_Hover_BorderStyleRight}
        ${DB_LeftSidebar_MenuItem_SortierungTop_Hover_BorderColorRight}`,

        borderTopRightRadius: `${DB_LeftSidebar_MenuItem_SortierungTop_Hover_BorderTopRightRadius}px`,
        borderTopLeftRadius: `${DB_LeftSidebar_MenuItem_SortierungTop_Hover_BorderTopLeftRadius}px`,
        borderBottomRightRadius: `${DB_LeftSidebar_MenuItem_SortierungTop_Hover_BorderBottomRightRadius}px`,
        borderBottomLeftRadius: `${DB_LeftSidebar_MenuItem_SortierungTop_Hover_BorderBottomLeftRadius}px`,

        boxShadow: `${DB_LeftSidebar_MenuItem_SortierungTop_Hover_ShadowHorizontal}px
        ${DB_LeftSidebar_MenuItem_SortierungTop_Hover_ShadowVertical}px
        ${DB_LeftSidebar_MenuItem_SortierungTop_Hover_ShadowBlur}px
        ${DB_LeftSidebar_MenuItem_SortierungTop_Hover_ShadowColor}`,
      },
    },
    OneTwoThreeFourInputSpecial: {
      paddingLeft: "25px",
      marginRight: "8px",
      marginBottom: "0px",
    },

    form_check_input_special: {
      "&:checked": {
        backgroundColor: `${DB_LeftSidebar_MenuItem_SortierungTop_BackgroundColor}`,
        borderColor: `${DB_LeftSidebar_MenuItem_SortierungTop_FontColor}`,
      },
    },
    form_check_input: {
      backgroundColor: `${DB_LeftSidebar_MenuItem_SortierungTop_FontColor}`,
    },
  });

  const classes = useStyles();

  return (
    <>
      <div
        className={`col-lg-12 ${classes.infosPlusFilterButtonsSpecial}`}
        style={{ marginBottom: "16px" }}
      >
        <div
          className="anzahlProdukteAnzahlSpecialContainer"
          style={{ display: "block", textAlign: "center", marginBottom: "5px" }}
        >
          {t("AnzahlProdukte")}:{" "}
          <span className="anzahlProdukteAnzahlNumber">
            <strong>{pageAnzahlCounter}</strong>
          </span>
        </div>

        <div
          style={{
            marginBottom: "1px",
            textAlign: "center",
          }}
        >
          <select
            id="inputState"
            className={`form-control-sm ${classes.selectTopSubSubBar} ${classes.selectAufsteigendAbsteigendSpecial}`}
            style={{ fontFamily: DB_fontFace_FontFamilyName }}
            onChange={(e) => changePreisAlphabeticUswHandler(e)}
          >
            <option
              className={classes.selectTopSubSubBarOption}
              value="Neueste"
              defaultValue
            >
              {t("Neueste")}
            </option>
            <option
              value="Älteste"
              className={`${classes.selectTopSubSubBarOption} Aelteste`}
            >
              {t("Aelteste")}
            </option>
            <option
              value="Alphabetisch aufsteigend"
              className={`${classes.selectTopSubSubBarOption} AlphabetischAufsteigend`}
            >
              {t("AlphabetischAufsteigend")}
            </option>
            <option
              value="Alphabetisch absteigend"
              className={`${classes.selectTopSubSubBarOption} AlphabetischAbsteigend`}
            >
              {t("AlphabetischAbsteigend")}
            </option>
            <option
              value="Preis aufsteigend"
              className={`${classes.selectTopSubSubBarOption} PreisAufsteigend`}
            >
              {t("PreisAufsteigend")}
            </option>
            <option
              value="Preis absteigend"
              className={`${classes.selectTopSubSubBarOption} PreisAbsteigend`}
            >
              {t("PreisAbsteigend")}
            </option>
          </select>
        </div>
        <div
          className="zahlenSpaltenAuswaehlenRadioButtons d-sm-none d-md-none d-lg-block"
          style={{ textAlign: "center" }}
        >
          <div
            className={`form-check form-check-inline ${classes.OneTwoThreeFourInputSpecial}`}
            style={{ cursor: "pointer" }}
          >
            <input
              style={{ cursor: "pointer" }}
              className={`form-check-input ${classes.form_check_input_special}`}
              type="radio"
              name="inlineRadioOptionsSpecialRight"
              id="inlineRadio1"
              value="12"
              checked={columnNumber == 12 ? true : false}
              onClick={columnNumberChange}
            />
            <label
              style={{
                cursor: "pointer",
              }}
              className={`form-check-label ${classes.OneTwoThreeFour}`}
              htmlFor="inlineRadio1"
            >
              1
            </label>
          </div>
          <div
            className={`form-check form-check-inline ${classes.OneTwoThreeFourInputSpecial}`}
            style={{ cursor: "pointer" }}
          >
            <input
              style={{ cursor: "pointer" }}
              className={`form-check-input ${classes.form_check_input_special}`}
              type="radio"
              name="inlineRadioOptionsSpecialRight"
              id="inlineRadio2"
              value="6"
              checked={columnNumber == 6 ? true : false}
              onClick={columnNumberChange}
            />
            <label
              style={{
                cursor: "pointer",
              }}
              className={`form-check-label ${classes.OneTwoThreeFour}`}
              htmlFor="inlineRadio2"
            >
              2
            </label>
          </div>
          <div
            className={`form-check form-check-inline ${classes.OneTwoThreeFourInputSpecial}`}
            style={{ cursor: "pointer" }}
          >
            <input
              style={{ cursor: "pointer" }}
              className={`form-check-input ${classes.form_check_input_special}`}
              type="radio"
              name="inlineRadioOptionsSpecialRight"
              id="inlineRadio3"
              value="4"
              checked={columnNumber == 4 ? true : false}
              onClick={columnNumberChange}
            />
            <label
              style={{
                cursor: "pointer",
              }}
              className={`form-check-label ${classes.OneTwoThreeFour}`}
              htmlFor="inlineRadio3"
            >
              3
            </label>
          </div>
          <div
            className={`form-check form-check-inline ${classes.OneTwoThreeFourInputSpecial}`}
            style={{ cursor: "pointer" }}
          >
            <input
              style={{ cursor: "pointer" }}
              className={`form-check-input ${classes.form_check_input_special}`}
              type="radio"
              name="inlineRadioOptionsSpecialRight"
              id="inlineRadio4"
              value="3"
              checked={columnNumber == 3 ? true : false}
              onClick={columnNumberChange}
            />
            <label
              style={{
                cursor: "pointer",
              }}
              className={`form-check-label ${classes.OneTwoThreeFour}`}
              htmlFor="inlineRadio4"
            >
              4
            </label>
          </div>
          {!(
            Math.ceil(
              pageAnzahlCounter / 72 -
                (pageAnzahlCounter / 72 - paginationEnd / 72)
            ) == 1 && Math.ceil(pageAnzahlCounter / 72) == 0
          ) &&
            Math.ceil(pageAnzahlCounter / 72) != 1 && (
              <div style={{ display: "inline-block", marginLeft: "30px" }}>
                {paginationStart > 0 ? (
                  <span
                    onClick={() => productBefore()}
                    style={{
                      display: "inline-block",
                      cursor: "pointer",
                    }}
                  >
                    <span
                      className="OneTwoThreeFour"
                      style={{
                        fontSize: 19,
                        fontWeight: "bold",
                      }}
                    >
                      &#x21E6;
                    </span>
                  </span>
                ) : (
                  <span style={{ marginLeft: "14px" }}>&nbsp;</span>
                )}
                {paginationEnd < pageAnzahlCounter ? (
                  <span
                    onClick={() => productNext()}
                    style={{
                      marginLeft: "15px",
                      display: "inline-block",
                      cursor: "pointer",
                    }}
                  >
                    <span
                      className="OneTwoThreeFour"
                      style={{
                        fontSize: 19,
                        fontWeight: "bold",
                      }}
                    >
                      &#x21E8;
                    </span>
                  </span>
                ) : (
                  <span
                    style={{
                      fontSize: 19,
                      fontWeight: "bold",
                      marginLeft: "14px",
                    }}
                  >
                    &nbsp;
                  </span>
                )}
              </div>
            )}
          {!(
            Math.ceil(
              pageAnzahlCounter / 72 -
                (pageAnzahlCounter / 72 - paginationEnd / 72)
            ) == 1 && Math.ceil(pageAnzahlCounter / 72) == 0
          ) &&
            Math.ceil(pageAnzahlCounter / 72) != 1 &&
            Math.ceil(pageAnzahlCounter / 72) != 1 && (
              <div style={{ display: "inline-block", marginLeft: "10px" }}>
                <span
                  style={{
                    marginLeft: "15px",
                    display: "inline-block",
                    cursor: "pointer",
                  }}
                >
                  <span
                    className="anzahlProdukteAnzahlNumber"
                    style={{
                      fontSize: 15,
                    }}
                  >
                    {Math.ceil(
                      pageAnzahlCounter / 72 -
                        (pageAnzahlCounter / 72 - paginationEnd / 72)
                    ) == 1 && Math.ceil(pageAnzahlCounter / 72) == 0
                      ? ""
                      : Math.ceil(
                          pageAnzahlCounter / 72 -
                            (pageAnzahlCounter / 72 - paginationEnd / 72)
                        ) + " /"}{" "}
                  </span>
                  <span
                    className="anzahlProdukteAnzahlNumber"
                    style={{
                      fontSize: 15,
                    }}
                  >
                    {Math.ceil(
                      pageAnzahlCounter / 72 -
                        (pageAnzahlCounter / 72 - paginationEnd / 72)
                    ) == 1 && Math.ceil(pageAnzahlCounter / 72) == 0
                      ? ""
                      : Math.ceil(pageAnzahlCounter / 72)}
                  </span>
                </span>
              </div>
            )}

          {/* <span className="form-check form-check-inline">
          <input
            className="form_check_input"
            type="radio"
            name="inlineRadioOptions"
            id="inlineRadio6"
            value="2"
            onClick={columnNumberChange}
          />
          <label
            className="form-check-label"
            htmlFor="inlineRadio6"
          >
            6
          </label>
        </span> */}
        </div>
      </div>
    </>
  );
};

export default ProductFilterTopBarSpecialRight;
