import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";

const SideBarLeftAlleProdukteTitel = ({
  resetFilterHandler,

  SideBarLeft_AlleProdukte_Farbe_Groesse_Stil_OnMouseOverAndOut,
  SideBarLeft_AlleProdukte_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut,
  SideBarLeft_AlleProdukte_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut,
  SideBarLeft_AlleProdukte_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut,

  DB_fontFace_alleProdukteAuswahlBB_FontFamilyName,
  DB_fontFace_alleProdukteAuswahlBB_FontFamilySrc,
  DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_FontWeight,
  DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_FontColor,
  DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_FontSize,
  DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_BackgroundColor,
  DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_BorderTop,
  DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_BorderStyleTop,
  DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_BorderColorTop,
  DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_BorderBottom,
  DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_BorderStyleBottom,
  DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_BorderColorBottom,
  DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_BorderLeft,
  DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_BorderStyleLeft,
  DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_BorderColorLeft,
  DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_BorderRight,
  DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_BorderStyleRight,
  DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_BorderColorRight,
  DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_BorderTopRightRadius,
  DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_BorderTopLeftRadius,
  DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_BorderBottomRightRadius,
  DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_BorderBottomLeftRadius,
  DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_marginLeftRight,
  DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_marginTopBottom,
  DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_paddingLeftRight,
  DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_paddingTopBottom,
  DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_letterSpacing,
  DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_ShadowHorizontal,
  DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_ShadowVertical,
  DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_ShadowBlur,
  DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_ShadowColor,
  DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_TextDecoration,
  DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_TextDecorationStyle,
  DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_TextAlign,

  DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_FontWeight,
  DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_FontColor,
  DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_FontSize,
  DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_BackgroundColor,
  DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_TextAlign,
  DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_BorderTop,
  DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_BorderStyleTop,
  DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_BorderColorTop,
  DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_BorderBottom,
  DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_BorderStyleBottom,
  DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_BorderColorBottom,
  DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_BorderLeft,
  DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_BorderStyleLeft,
  DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_BorderColorLeft,
  DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_BorderRight,
  DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_BorderStyleRight,
  DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_BorderColorRight,
  DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_BorderTopRightRadius,
  DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_BorderTopLeftRadius,
  DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_BorderBottomRightRadius,
  DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_BorderBottomLeftRadius,
  DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_marginLeftRight_Hover,
  DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_marginTopBottom_Hover,
  DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_paddingLeftRight_Hover,
  DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_paddingTopBottom_Hover,
  DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_letterSpacing_Hover,
  DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_ShadowHorizontal_Hover,
  DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_ShadowVertical_Hover,
  DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_ShadowBlur_Hover,
  DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_ShadowColor_Hover,
  DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_TextDecoration_Hover,
  DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_TextDecorationStyle_Hover,
}) => {
  const { t, i18n } = useTranslation();

  const useStyles = createUseStyles({
    menuItem_alleProdukteUltraKatZuruecksetzenBB: {
      fontFamily: `${DB_fontFace_alleProdukteAuswahlBB_FontFamilyName}`,
      fontWeight: `${DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_FontWeight}`,
      color: `${DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_FontColor}`,
      fontSize: `${DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_FontSize}px`,
      backgroundColor: `${
        SideBarLeft_AlleProdukte_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut
          ? "rgba(255,0,0,0.5)"
          : SideBarLeft_AlleProdukte_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut
          ? "rgba(255,0,0,0.3)"
          : DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_BackgroundColor
      }`,
      textAlign: `${DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_TextAlign}`,
      borderTop: `${
        SideBarLeft_AlleProdukte_Farbe_Groesse_Stil_OnMouseOverAndOut
          ? "3"
          : SideBarLeft_AlleProdukte_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
          ? "3"
          : DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_BorderTop
      }px
        ${
          SideBarLeft_AlleProdukte_Farbe_Groesse_Stil_OnMouseOverAndOut
            ? "solid"
            : SideBarLeft_AlleProdukte_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
            ? "dotted"
            : DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_BorderStyleTop
        }
        ${
          SideBarLeft_AlleProdukte_Farbe_Groesse_Stil_OnMouseOverAndOut
            ? "red"
            : SideBarLeft_AlleProdukte_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
            ? "red"
            : DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_BorderColorTop
        }`,
      borderBottom: `${
        SideBarLeft_AlleProdukte_Farbe_Groesse_Stil_OnMouseOverAndOut
          ? "3"
          : SideBarLeft_AlleProdukte_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
          ? "3"
          : DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_BorderBottom
      }px
          ${
            SideBarLeft_AlleProdukte_Farbe_Groesse_Stil_OnMouseOverAndOut
              ? "solid"
              : SideBarLeft_AlleProdukte_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
              ? "dotted"
              : DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_BorderStyleBottom
          }
          ${
            SideBarLeft_AlleProdukte_Farbe_Groesse_Stil_OnMouseOverAndOut
              ? "red"
              : SideBarLeft_AlleProdukte_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
              ? "red"
              : DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_BorderColorBottom
          }`,
      borderLeft: `${
        SideBarLeft_AlleProdukte_Farbe_Groesse_Stil_OnMouseOverAndOut
          ? "3"
          : SideBarLeft_AlleProdukte_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
          ? "3"
          : DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_BorderLeft
      }px
          ${
            SideBarLeft_AlleProdukte_Farbe_Groesse_Stil_OnMouseOverAndOut
              ? "solid"
              : SideBarLeft_AlleProdukte_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
              ? "dotted"
              : DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_BorderStyleLeft
          }
          ${
            SideBarLeft_AlleProdukte_Farbe_Groesse_Stil_OnMouseOverAndOut
              ? "red"
              : SideBarLeft_AlleProdukte_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
              ? "red"
              : DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_BorderColorLeft
          }`,
      borderRight: `${
        SideBarLeft_AlleProdukte_Farbe_Groesse_Stil_OnMouseOverAndOut
          ? "3"
          : SideBarLeft_AlleProdukte_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
          ? "3"
          : DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_BorderRight
      }px
          ${
            SideBarLeft_AlleProdukte_Farbe_Groesse_Stil_OnMouseOverAndOut
              ? "solid"
              : SideBarLeft_AlleProdukte_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
              ? "dotted"
              : DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_BorderStyleRight
          }
          ${
            SideBarLeft_AlleProdukte_Farbe_Groesse_Stil_OnMouseOverAndOut
              ? "red"
              : SideBarLeft_AlleProdukte_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
              ? "red"
              : DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_BorderColorRight
          }`,
      borderTopRightRadius: `${DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_BorderTopRightRadius}px`,
      borderTopLeftRadius: `${DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_BorderTopLeftRadius}px`,
      borderBottomRightRadius: `${DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_BorderBottomRightRadius}px`,
      borderBottomLeftRadius: `${DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_BorderBottomLeftRadius}px`,
      marginLeft: `${DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_marginLeftRight}px`,
      marginRight: `${DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_marginLeftRight}px`,
      marginTop: `${DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_marginTopBottom}px`,
      marginBottom: `${DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_marginTopBottom}px`,
      paddingLeft: `${DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_paddingLeftRight}px`,
      paddingRight: `${DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_paddingLeftRight}px`,
      paddingTop: `${DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_paddingTopBottom}px`,
      paddingBottom: `${DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_paddingTopBottom}px`,
      letterSpacing: `${DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_letterSpacing}px`,
      textShadow: `${DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_ShadowHorizontal}px
        ${DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_ShadowVertical}px
        ${DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_ShadowBlur}px
        ${DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_ShadowColor}`,
      textDecoration: `${DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_TextDecoration}
        ${DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_TextDecorationStyle}`,
      cursor: "pointer",
      "&:hover": {
        fontWeight: `${DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_FontWeight}`,
        color: `${DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_FontColor}`,
        fontSize: `${DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_FontSize}px`,
        backgroundColor: `${DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_BackgroundColor}`,
        textAlign: `${DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_TextAlign}`,
        borderTop: `${DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_BorderTop}px
        ${DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_BorderStyleTop}
        ${DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_BorderColorTop}`,
        borderBottom: `${DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_BorderBottom}px
        ${DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_BorderStyleBottom}
        ${DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_BorderColorBottom}`,
        borderLeft: `${DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_BorderLeft}px
        ${DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_BorderStyleLeft}
        ${DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_BorderColorLeft}`,
        borderRight: `${DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_BorderRight}px
        ${DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_BorderStyleRight}
        ${DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_BorderColorRight}`,
        borderTopRightRadius: `${DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_BorderTopRightRadius}px`,
        borderTopLeftRadius: `${DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_BorderTopLeftRadius}px`,
        borderBottomRightRadius: `${DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_BorderBottomRightRadius}px`,
        borderBottomLeftRadius: `${DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_BorderBottomLeftRadius}px`,
        marginLeft: `${DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_marginLeftRight_Hover}px`,
        marginRight: `${DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_marginLeftRight_Hover}px`,
        marginTop: `${DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_marginTopBottom_Hover}px`,
        marginBottom: `${DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_marginTopBottom_Hover}px`,
        paddingLeft: `${DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_paddingLeftRight_Hover}px`,
        paddingRight: `${DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_paddingLeftRight_Hover}px`,
        paddingTop: `${DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_paddingTopBottom_Hover}px`,
        paddingBottom: `${DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_paddingTopBottom_Hover}px`,
        letterSpacing: `${DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_letterSpacing_Hover}px`,
        textShadow: `${DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_ShadowHorizontal_Hover}px
        ${DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_ShadowVertical_Hover}px
        ${DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_ShadowBlur_Hover}px
        ${DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_ShadowColor_Hover}`,
        textDecoration: `${DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_TextDecoration_Hover}
        ${DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_TextDecorationStyle_Hover}`,
      },
    },
  });

  const classes = useStyles();

  return (
    <div
      className={classes.menuItem_alleProdukteUltraKatZuruecksetzenBB}
      onClick={resetFilterHandler}
    >
      {t("AlleProdukte")}
    </div>
  );
};

export default SideBarLeftAlleProdukteTitel;
