import { createUseStyles } from "react-jss";

const SideBarLeftSubSubKategorie = ({
  category,
  DB_fontFace_unterunterkategorieTitel_FontFamilyName,
  DB_fontFace_unterunterkategorieTitel_FontFamilySrc,
  categoryLeftSideHandler,
  DB_LeftSidebar_MenuItem_UnterUnterkategorie_FontWeight,
  DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_FontWeight,
  DB_LeftSidebar_MenuItem_UnterUnterkategorie_FontColor,
  DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_FontColor,
  DB_LeftSidebar_MenuItem_UnterUnterkategorie_FontSize,
  DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_FontSize,
  DB_LeftSidebar_MenuItem_UnterUnterkategorie_BackgroundColor,
  DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_BackgroundColor,
  DB_LeftSidebar_MenuItem_UnterUnterkategorie_TextAlign,
  DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_TextAlign,
  DB_LeftSidebar_MenuItem_UnterUnterkategorie_BorderTop,
  DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_BorderTop,
  DB_LeftSidebar_MenuItem_UnterUnterkategorie_BorderStyleTop,
  DB_LeftSidebar_MenuItem_UnterUnterkategorie_BorderColorTop,
  DB_LeftSidebar_MenuItem_UnterUnterkategorie_BorderBottom,
  DB_LeftSidebar_MenuItem_UnterUnterkategorie_BorderStyleBottom,
  DB_LeftSidebar_MenuItem_UnterUnterkategorie_BorderColorBottom,
  DB_LeftSidebar_MenuItem_UnterUnterkategorie_BorderLeft,
  DB_LeftSidebar_MenuItem_UnterUnterkategorie_BorderStyleLeft,
  DB_LeftSidebar_MenuItem_UnterUnterkategorie_BorderColorLeft,
  DB_LeftSidebar_MenuItem_UnterUnterkategorie_BorderRight,
  DB_LeftSidebar_MenuItem_UnterUnterkategorie_BorderStyleRight,
  DB_LeftSidebar_MenuItem_UnterUnterkategorie_BorderColorRight,

  DB_LeftSidebar_MenuItem_UnterUnterkategorie_BorderTopRightRadius,
  DB_LeftSidebar_MenuItem_UnterUnterkategorie_BorderTopLeftRadius,
  DB_LeftSidebar_MenuItem_UnterUnterkategorie_BorderBottomRightRadius,
  DB_LeftSidebar_MenuItem_UnterUnterkategorie_BorderBottomLeftRadius,

  DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_BorderTopRightRadius,
  DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_BorderTopLeftRadius,
  DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_BorderBottomRightRadius,
  DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_BottomLeftRadius,

  DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_BorderStyleTop,
  DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_BorderColorTop,
  DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_BorderBottom,
  DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_BorderStyleBottom,

  DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_BorderColorBottom,
  DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_BorderLeft,
  DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_BorderStyleLeft,
  DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_BorderColorLeft,

  DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_BorderRight,
  DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_BorderStyleRight,
  DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_BorderColorRight,
  DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_BorderBottomLeftRadius,

  SideBarLeft_UnterUnterkategorie_Farbe_Groesse_Stil_OnMouseOverAndOut,
  SideBarLeft_UnterUnterkategorie_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut,
  SideBarLeft_UnterUnterkategorie_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut,
  SideBarLeft_UnterUnterkategorie_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut,

  DB_LeftSidebar_MenuItem_UnterUnterkategorie_marginTopBottom,
  DB_LeftSidebar_MenuItem_UnterUnterkategorie_marginLeftRight,
  DB_LeftSidebar_MenuItem_UnterUnterkategorie_paddingLeftRight,
  DB_LeftSidebar_MenuItem_UnterUnterkategorie_paddingTopBottom,

  DB_LeftSidebar_MenuItem_UnterUnterkategorie_letterSpacing,
  DB_LeftSidebar_MenuItem_UnterUnterkategorie_ShadowHorizontal,
  DB_LeftSidebar_MenuItem_UnterUnterkategorie_ShadowVertical,
  DB_LeftSidebar_MenuItem_UnterUnterkategorie_ShadowBlur,
  DB_LeftSidebar_MenuItem_UnterUnterkategorie_ShadowColor,
  DB_LeftSidebar_MenuItem_UnterUnterkategorie_TextDecoration,
  DB_LeftSidebar_MenuItem_UnterUnterkategorie_TextDecorationStyle,

  DB_LeftSidebar_MenuItem_UnterUnterkategorie_marginTopBottom_Hover,
  DB_LeftSidebar_MenuItem_UnterUnterkategorie_marginLeftRight_Hover,
  DB_LeftSidebar_MenuItem_UnterUnterkategorie_paddingLeftRight_Hover,
  DB_LeftSidebar_MenuItem_UnterUnterkategorie_paddingTopBottom_Hover,

  DB_LeftSidebar_MenuItem_UnterUnterkategorie_letterSpacing_Hover,
  DB_LeftSidebar_MenuItem_UnterUnterkategorie_ShadowHorizontal_Hover,
  DB_LeftSidebar_MenuItem_UnterUnterkategorie_ShadowVertical_Hover,
  DB_LeftSidebar_MenuItem_UnterUnterkategorie_ShadowBlur_Hover,
  DB_LeftSidebar_MenuItem_UnterUnterkategorie_ShadowColor_Hover,
  DB_LeftSidebar_MenuItem_UnterUnterkategorie_TextDecoration_Hover,
  DB_LeftSidebar_MenuItem_UnterUnterkategorie_TextDecorationStyle_Hover,
}) => {
  const useStyles = createUseStyles({
    menuItem_subunterkategorie: {
      fontSize: `${DB_LeftSidebar_MenuItem_UnterUnterkategorie_FontSize}px`,
      fontWeight: `${DB_LeftSidebar_MenuItem_UnterUnterkategorie_FontWeight}`,
      fontFamily: `${DB_fontFace_unterunterkategorieTitel_FontFamilyName}`,
      backgroundColor: `${
        SideBarLeft_UnterUnterkategorie_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut
          ? "rgba(255,0,0,0.5)"
          : SideBarLeft_UnterUnterkategorie_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut
          ? "rgba(255,0,0,0.3)"
          : DB_LeftSidebar_MenuItem_UnterUnterkategorie_BackgroundColor
      }`,
      color: `${DB_LeftSidebar_MenuItem_UnterUnterkategorie_FontColor}`,
      textAlign: `${DB_LeftSidebar_MenuItem_UnterUnterkategorie_TextAlign}`,
      borderTop: `${
        SideBarLeft_UnterUnterkategorie_Farbe_Groesse_Stil_OnMouseOverAndOut
          ? "3"
          : SideBarLeft_UnterUnterkategorie_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
          ? "3"
          : DB_LeftSidebar_MenuItem_UnterUnterkategorie_BorderTop
      }px
          ${
            SideBarLeft_UnterUnterkategorie_Farbe_Groesse_Stil_OnMouseOverAndOut
              ? "solid"
              : SideBarLeft_UnterUnterkategorie_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
              ? "dotted"
              : DB_LeftSidebar_MenuItem_UnterUnterkategorie_BorderStyleTop
          }
          ${
            SideBarLeft_UnterUnterkategorie_Farbe_Groesse_Stil_OnMouseOverAndOut
              ? "red"
              : SideBarLeft_UnterUnterkategorie_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
              ? "red"
              : DB_LeftSidebar_MenuItem_UnterUnterkategorie_BorderColorTop
          }`,
      borderBottom: `${
        SideBarLeft_UnterUnterkategorie_Farbe_Groesse_Stil_OnMouseOverAndOut
          ? "3"
          : SideBarLeft_UnterUnterkategorie_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
          ? "3"
          : DB_LeftSidebar_MenuItem_UnterUnterkategorie_BorderBottom
      }px
          ${
            SideBarLeft_UnterUnterkategorie_Farbe_Groesse_Stil_OnMouseOverAndOut
              ? "solid"
              : SideBarLeft_UnterUnterkategorie_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
              ? "dotted"
              : DB_LeftSidebar_MenuItem_UnterUnterkategorie_BorderStyleBottom
          }
          ${
            SideBarLeft_UnterUnterkategorie_Farbe_Groesse_Stil_OnMouseOverAndOut
              ? "red"
              : SideBarLeft_UnterUnterkategorie_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
              ? "red"
              : DB_LeftSidebar_MenuItem_UnterUnterkategorie_BorderColorBottom
          }`,
      borderLeft: `${
        SideBarLeft_UnterUnterkategorie_Farbe_Groesse_Stil_OnMouseOverAndOut
          ? "3"
          : SideBarLeft_UnterUnterkategorie_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
          ? "3"
          : DB_LeftSidebar_MenuItem_UnterUnterkategorie_BorderLeft
      }px
          ${
            SideBarLeft_UnterUnterkategorie_Farbe_Groesse_Stil_OnMouseOverAndOut
              ? "solid"
              : SideBarLeft_UnterUnterkategorie_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
              ? "dotted"
              : DB_LeftSidebar_MenuItem_UnterUnterkategorie_BorderStyleLeft
          }
          ${
            SideBarLeft_UnterUnterkategorie_Farbe_Groesse_Stil_OnMouseOverAndOut
              ? "red"
              : SideBarLeft_UnterUnterkategorie_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
              ? "red"
              : DB_LeftSidebar_MenuItem_UnterUnterkategorie_BorderColorLeft
          }`,
      borderRight: `${
        SideBarLeft_UnterUnterkategorie_Farbe_Groesse_Stil_OnMouseOverAndOut
          ? "3"
          : SideBarLeft_UnterUnterkategorie_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
          ? "3"
          : DB_LeftSidebar_MenuItem_UnterUnterkategorie_BorderRight
      }px
          ${
            SideBarLeft_UnterUnterkategorie_Farbe_Groesse_Stil_OnMouseOverAndOut
              ? "solid"
              : SideBarLeft_UnterUnterkategorie_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
              ? "dotted"
              : DB_LeftSidebar_MenuItem_UnterUnterkategorie_BorderStyleRight
          }
          ${
            SideBarLeft_UnterUnterkategorie_Farbe_Groesse_Stil_OnMouseOverAndOut
              ? "red"
              : SideBarLeft_UnterUnterkategorie_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
              ? "red"
              : DB_LeftSidebar_MenuItem_UnterUnterkategorie_BorderColorRight
          }`,
      borderTopRightRadius: `${DB_LeftSidebar_MenuItem_UnterUnterkategorie_BorderTopRightRadius}px`,
      borderTopLeftRadius: `${DB_LeftSidebar_MenuItem_UnterUnterkategorie_BorderTopLeftRadius}px`,
      borderBottomRightRadius: `${DB_LeftSidebar_MenuItem_UnterUnterkategorie_BorderBottomRightRadius}px`,
      borderBottomLeftRadius: `${DB_LeftSidebar_MenuItem_UnterUnterkategorie_BorderBottomLeftRadius}px`,
      marginLeft: `${DB_LeftSidebar_MenuItem_UnterUnterkategorie_marginLeftRight}px`,
      marginRight: `${DB_LeftSidebar_MenuItem_UnterUnterkategorie_marginLeftRight}px`,
      marginTop: `${DB_LeftSidebar_MenuItem_UnterUnterkategorie_marginTopBottom}px`,
      marginBottom: `${DB_LeftSidebar_MenuItem_UnterUnterkategorie_marginTopBottom}px`,
      paddingLeft: `${DB_LeftSidebar_MenuItem_UnterUnterkategorie_paddingLeftRight}px`,
      paddingRight: `${DB_LeftSidebar_MenuItem_UnterUnterkategorie_paddingLeftRight}px`,
      paddingTop: `${DB_LeftSidebar_MenuItem_UnterUnterkategorie_paddingTopBottom}px`,
      paddingBottom: `${DB_LeftSidebar_MenuItem_UnterUnterkategorie_paddingTopBottom}px`,
      letterSpacing: `${DB_LeftSidebar_MenuItem_UnterUnterkategorie_letterSpacing}px`,
      textShadow: `${DB_LeftSidebar_MenuItem_UnterUnterkategorie_ShadowHorizontal}px
          ${DB_LeftSidebar_MenuItem_UnterUnterkategorie_ShadowVertical}px
          ${DB_LeftSidebar_MenuItem_UnterUnterkategorie_ShadowBlur}px
          ${DB_LeftSidebar_MenuItem_UnterUnterkategorie_ShadowColor}`,
      textDecoration: `${DB_LeftSidebar_MenuItem_UnterUnterkategorie_TextDecoration}
          ${DB_LeftSidebar_MenuItem_UnterUnterkategorie_TextDecorationStyle}`,

      "&:hover": {
        fontSize: `${DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_FontSize}px`,
        fontWeight: `${DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_FontWeight}`,
        backgroundColor: `${DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_BackgroundColor}`,
        color: `${DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_FontColor}`,
        textAlign: `${DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_TextAlign}`,
        borderTop: `${DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_BorderTop}px
          ${DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_BorderStyleTop}
          ${DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_BorderColorTop}`,
        borderBottom: `${DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_BorderBottom}px
          ${DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_BorderStyleBottom}
          ${DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_BorderColorBottom}`,
        borderLeft: `${DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_BorderLeft}px
          ${DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_BorderStyleLeft}
          ${DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_BorderColorLeft}`,
        borderRight: `${DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_BorderRight}px
          ${DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_BorderStyleRight}
          ${DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_BorderColorRight}`,
        borderTopRightRadius: `${DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_BorderTopRightRadius}px`,
        borderTopLeftRadius: `${DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_BorderTopLeftRadius}px`,
        borderBottomRightRadius: `${DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_BorderBottomRightRadius}px`,
        borderBottomLeftRadius: `${DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_BorderBottomLeftRadius}px`,

        marginLeft: `${DB_LeftSidebar_MenuItem_UnterUnterkategorie_marginLeftRight_Hover}px`,
        marginRight: `${DB_LeftSidebar_MenuItem_UnterUnterkategorie_marginLeftRight_Hover}px`,
        marginTop: `${DB_LeftSidebar_MenuItem_UnterUnterkategorie_marginTopBottom_Hover}px`,
        marginBottom: `${DB_LeftSidebar_MenuItem_UnterUnterkategorie_marginTopBottom_Hover}px`,
        paddingLeft: `${DB_LeftSidebar_MenuItem_UnterUnterkategorie_paddingLeftRight_Hover}px`,
        paddingRight: `${DB_LeftSidebar_MenuItem_UnterUnterkategorie_paddingLeftRight_Hover}px`,
        paddingTop: `${DB_LeftSidebar_MenuItem_UnterUnterkategorie_paddingTopBottom_Hover}px`,
        paddingBottom: `${DB_LeftSidebar_MenuItem_UnterUnterkategorie_paddingTopBottom_Hover}px`,
        letterSpacing: `${DB_LeftSidebar_MenuItem_UnterUnterkategorie_letterSpacing_Hover}px`,
        textShadow: `${DB_LeftSidebar_MenuItem_UnterUnterkategorie_ShadowHorizontal_Hover}px
          ${DB_LeftSidebar_MenuItem_UnterUnterkategorie_ShadowVertical_Hover}px
          ${DB_LeftSidebar_MenuItem_UnterUnterkategorie_ShadowBlur_Hover}px
          ${DB_LeftSidebar_MenuItem_UnterUnterkategorie_ShadowColor_Hover}`,
        textDecoration: `${DB_LeftSidebar_MenuItem_UnterUnterkategorie_TextDecoration_Hover}
          ${DB_LeftSidebar_MenuItem_UnterUnterkategorie_TextDecorationStyle_Hover}`,
      },
    },
  });

  const classes = useStyles();

  return (
    <div
      className="hauptkategorie"
      style={{ cursor: "pointer" }}
      key={"subSubCategoryOne" + category.subsubkategorie + Math.random()}
      onClick={() =>
        categoryLeftSideHandler(
          `${category.kategorie}`,
          `${category.subkategorie}`,
          `${category.subsubkategorie}`
        )
      }
    >
      <li className={`mb-1 ${classes.menuItem_subunterkategorie}`}>
        {category.subsubkategorie}
      </li>
    </div>
  );
};

export default SideBarLeftSubSubKategorie;
