import { useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import { Button, Form, Modal } from "react-bootstrap";
import axios from "axios";
import { useTranslation } from "react-i18next";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import { ToastContainer, toast, Zoom } from "react-toastify";
import { isAuth, getCookie, getIdOfUser, signout } from "../helpers/helpers";

function SiteBelongsToUserSaveDesign({
  designIdentifier,
  newDesignIdentifier,
  megaUltraSaveDesign,
  identifierChangeFunction,
  oneClickDesignChangerFunction,
  setNewDesignAfterDeletion,
  shopName,
  countImageFunctionPlusOne,
  cancelSaveDesign,
  setLowMemoryHandler,
  lowMemory,
}) {
  const [allDesigns, setAllDesigns] = useState([]);
  const [allStandardDesigns, setAllStandardDesigns] = useState([]);
  const [deleteDesignModal, setDeleteDesignModal] = useState(false);
  const [createDesignModal, setCreateDesignModal] = useState(false);
  const [saveCounter, setSaveCounter] = useState(0);
  const { t, i18n } = useTranslation();

  const megaUltraSaveNewDesign = (event) => {
    megaUltraSaveDesign(event);
    setTimeout(() => {
      setSaveCounter((prev) => prev + 1);
      setCreateDesignModal(false);
    }, 1000);
  };

  const setDeleteDesignModalFunction = () => {
    setDeleteDesignModal(!deleteDesignModal);
  };

  const setCreateDesignModalFunction = () => {
    cancelSaveDesign();
    setCreateDesignModal(!createDesignModal);
  };

  // const handleSelect = (eventKey) => alert(`selected ${eventKey}`);

  const loadStandardDesignFunction = (loadStandardDesign) => {
    oneClickDesignChangerFunction("", "supermegashop", loadStandardDesign);
  };

  useEffect(() => {
    const token = getCookie("token");
    axios({
      method: "post",
      url: `${process.env.REACT_APP_PUBLIC_SERVER_URL}/shop/receive-all-standard-designs`,
      data: { shopName },

      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        setAllStandardDesigns(response.data.All_Standard_Designs);
      })

      .catch((error) => {
        toast.error(`${t("KeineVerbindungZumServer")}`, {
          toastId: "custom-id-yes",
          position: "bottom-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          transition: Zoom,
        });
      });
  }, []);

  useEffect(() => {
    const token = getCookie("token");
    axios({
      method: "post",
      url: `${process.env.REACT_APP_PUBLIC_SERVER_URL}/shop/receive-all-designs`,
      data: { shopName },

      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        setAllDesigns(response.data.All_Designs);
      })

      .catch((error) => {
        toast.error(`${t("KeineVerbindungZumServer")}`, {
          toastId: "custom-id-yes",
          position: "bottom-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          transition: Zoom,
        });
      });
  }, [createDesignModal, deleteDesignModal, saveCounter, newDesignIdentifier]);

  const deleteDesignFunction = () => {
    const token = getCookie("token");
    axios({
      method: "post",
      url: `${process.env.REACT_APP_PUBLIC_SERVER_URL}/shop/delete-the-design`,
      data: { shopName, newDesignIdentifier },

      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        setNewDesignAfterDeletion();
        if (response.status === 200) {
          toast.success(`${t("DesignErfolgreichGeloescht")}`, {
            toastId: "custom-id-yes",
            position: "bottom-center",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            transition: Zoom,
          });
          setTimeout(() => {
            countImageFunctionPlusOne();
            setDeleteDesignModal(false);
          }, 1000);
        } else {
          toast.error(`${t("KeineVerbindungZumServer")}`, {
            toastId: "custom-id-yes",
            position: "bottom-center",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            transition: Zoom,
          });
        }
      })

      .catch((error) => {
        toast.error(`${t("KeineVerbindungZumServer")}`, {
          toastId: "custom-id-yes",
          position: "bottom-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          transition: Zoom,
        });
      });
  };

  return (
    <>
      <style jsx global>
        {`
          .SiteBelongsToUserSaveDesignSave {
            border-radius: 20px;
            background-color: rgba(0, 0, 0, 0.8) !important;
            position: fixed !important;
            width: 900 !important;
            bottom: 54px !important;
            padding-top: 12px;
            padding-bottom: 12px;
            z-index: 1000;
          }

          .dropdown-menu {
            background-color: rgba(0, 0, 0, 0.9) !important;

            border-bottom-left-radius: 20px;
            border-bottom-right-radius: 20px;
            border-bottom-left-radius: 20px;
            border-bottom-right-radius: 20px;
            border-left: 1px solid rgba(255, 255, 255, 0.2);
            border-right: 1px solid rgba(255, 255, 255, 0.2);
          }

          .dropdown-item {
            border-bottom-left-radius: 20px;
            border-bottom-right-radius: 20px;
            border-top-left-radius: 20px;
            border-top-right-radius: 20px;
            border-bottom: 0px !important;
            color: white !important;
            font-family: "Lato";
            src: url("../assets/Lato/Lato-Regular.ttf");
          }

          a.nav-link {
            font-family: "Lato";
            src: url("../assets/Lato/Lato-Regular.ttf");
            color: white;
            font-weight: bold;
          }

          .dropdown-item:hover {
            border-bottom-left-radius: 0px;
            border-bottom-right-radius: 0px;
            border-top-left-radius: 0px;
            border-top-right-radius: 0px;
            color: white !important;
            background-color: rgba(255, 255, 255, 0.1) !important;
            font-weight: normal;
          }

          // FAQ's, AGB's, Über Uns, Kontakt (Container)
          .SiteBelongsToUserNavStatic {
            margin-left: 20px !important;
            background-color: rgba(0, 0, 0, 0.6);
            padding: 0px 15px !important;
            margin-bottom: 0px !important;
            font-size: 16px;
            border-radius: 7px;
            min-height: 20px;
            border-left: 1px solid rgba(0, 0, 0, 0.6);
            border-right: 1px solid rgba(0, 0, 0, 0.6);
            box-shadow: rgba(0, 0, 0, 0.16) 0px 2px 3px,
              rgba(0, 0, 0, 0.23) 0px 2px 3px;
          }

          hr.horizontalRule {
            border: 5px solid rgba(255, 255, 255, 1);
          }

          hr.horizontalRuleSubDelete {
            border: 1px solid rgba(255, 255, 255, 1);
          }

          .identifierClassName {
            width: 300px;
          }

          .SiteBelongsToUserNavStaticDelete {
            margin-left: 10px !important;
            background-color: rgba(0, 0, 0, 0.6);
            height: 38px;
            font-size: 16px;
            border-radius: 7px;
            border-left: 1px solid rgba(0, 0, 0, 0.6);
            border-right: 1px solid rgba(0, 0, 0, 0.6);
            box-shadow: rgba(0, 0, 0, 0.16) 0px 2px 3px,
              rgba(0, 0, 0, 0.23) 0px 2px 3px;
          }

          .SiteBelongsToUserNavStaticDelete > a {
            font-weight: 100;
          }

          .SiteBelongsToUserNavStaticDelete > div {
            border-bottom-left-radius: 0px !important;
            border-bottom-right-radius: 0px !important;
            border-top-left-radius: 10px !important;
            border-top-right-radius: 10px !important;
            border: 0px;
          }

          .SiteBelongsToUserNavStaticStandardDesigns {
            margin-left: 10px !important;
            margin-right: 26px !important;
            background-color: rgba(255, 255, 255, 0.2);
            height: 38px;
            font-size: 16px;
            border-radius: 7px;
            border-left: 1px solid rgba(0, 0, 0, 0.6);
            border-right: 1px solid rgba(0, 0, 0, 0.6);
            box-shadow: rgba(0, 0, 0, 0.16) 0px 2px 3px,
              rgba(0, 0, 0, 0.23) 0px 2px 3px;
          }

          .SiteBelongsToUserNavStaticStandardDesigns > a {
            font-weight: 100;
          }

          .inputFormContactFormTwo {
            display: block;
            width: 100%;
            padding: 0.375rem 0.75rem;
            font-size: 1rem;
            font-weight: 400;
            line-height: 1.5;
            color: #212529;
            background-color: #fff;
            background-clip: padding-box;
            border: 1px solid #ced4da;
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            border-radius: 0.375rem;
            transition: border-color 0.15s ease-in-out,
              box-shadow 0.15s ease-in-out;
          }
        `}
      </style>

      <Container className="px-0 d-lg-block d-none">
        <div className="d-flex justify-content-center">
          <Navbar
            bg="dark"
            variant="dark"
            expand="lg"
            className="SiteBelongsToUserSaveDesignSave d-flex"
          >
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav style={{ marginLeft: "20px" }}>
                <NavDropdown
                  drop="up"
                  title={newDesignIdentifier ? newDesignIdentifier : "choose"}
                  id="basic-nav-dropdown"
                  className="SiteBelongsToUserNavStaticDelete"
                >
                  <div>
                    {allDesigns.map((item) => {
                      return (
                        <div key={"design-" + item.id}>
                          <NavDropdown.Item
                            onClick={() =>
                              oneClickDesignChangerFunction(
                                item.designIdentifier
                              )
                            }
                          >
                            {item.designIdentifier}
                          </NavDropdown.Item>
                          <hr className="horizontalRuleSubDelete" />
                        </div>
                      );
                    })}
                  </div>
                </NavDropdown>
                <div
                  style={{ marginLeft: "10px" }}
                  onClick={(event) => megaUltraSaveDesign(event)}
                >
                  <Button variant="success">{t("Speichern")}</Button>
                </div>
                <div
                  style={{ marginLeft: "10px", marginLeft: "100px" }}
                  onClick={() => setCreateDesignModalFunction()}
                >
                  <Button variant="primary">{t("NeuesDesignErstellen")}</Button>
                </div>
                <NavDropdown
                  drop="up"
                  title={t("DesignLoeschen")}
                  className="SiteBelongsToUserNavStaticDelete"
                  style={{
                    backgroundColor: "#dc3545",
                    marginRight: "0px",
                  }}
                >
                  <hr className="horizontalRuleSubDelete" />
                  <div
                    onClick={setDeleteDesignModalFunction}
                    className="StaticDeleteItem"
                  >
                    <NavDropdown.Item>
                      {t("SindSieSicher")}
                      <br />
                      <strong>{t("JaJetztLoeschen")}</strong>
                    </NavDropdown.Item>
                  </div>
                  <hr className="horizontalRuleSubDelete" />
                </NavDropdown>
                <NavDropdown
                  drop="up"
                  title="Standard Designs"
                  id="basic-nav-dropdown"
                  className="SiteBelongsToUserNavStaticDelete"
                >
                  {/* <hr className="horizontalRuleSubDelete" /> */}
                  <div>
                    {allStandardDesigns.map((standardDesign) => {
                      return (
                        <div key={"standarddesign-" + standardDesign.id}>
                          <NavDropdown.Item
                            onClick={(loadStandardDesign) =>
                              loadStandardDesignFunction(
                                standardDesign.designIdentifier
                              )
                            }
                          >
                            {standardDesign.designIdentifier}
                          </NavDropdown.Item>
                          <hr className="horizontalRuleSubDelete" />
                        </div>
                      );
                    })}
                  </div>
                </NavDropdown>{" "}
                <div
                  style={{
                    marginTop: "2px",
                    marginLeft: "10px",
                    marginRight: "26px",
                  }}
                >
                  <h6
                    className="mb-0 mt-0 OffCanvasTitle"
                    style={{
                      display: "inline-block",
                      backgroundColor: "#0d6efd",
                    }}
                  >
                    {t("WenigRessourcen")}:
                    <input
                      id="specialLeftFiltersVisible"
                      className="form-check-input ms-2"
                      style={{ marginRight: "7px" }}
                      type="checkbox"
                      checked={lowMemory}
                      onChange={(e) => setLowMemoryHandler(e)}
                    />
                  </h6>
                </div>
              </Nav>
            </Navbar.Collapse>
          </Navbar>
        </div>
      </Container>

      <Modal
        show={deleteDesignModal}
        style={{ color: "white", fontFamily: "Lato" }}
      >
        <Modal.Header
          style={{
            backgroundColor: "black",
            padding: "20px",
            marginTop: "10px",
            marginLeft: "20px",
            marginRight: "20px",
          }}
          closeButton
        >
          <Modal.Title>{newDesignIdentifier}</Modal.Title>
        </Modal.Header>

        <Modal.Body
          style={{
            backgroundColor: "black",
            paddingBottom: "0px",
          }}
        >
          <p>
            {t("DasVonIhnenAusgewaehlteDesign")} (
            <strong>{newDesignIdentifier}</strong>) {t("jetztWirklichLoeschen")}
          </p>
        </Modal.Body>

        <Modal.Footer
          style={{
            backgroundColor: "black",
            borderTop: "black",
            paddingTop: "0px",
            marginBottom: "5px",
          }}
        >
          <Button onClick={setDeleteDesignModalFunction} variant="secondary">
            {t("Schliessen")}
          </Button>
          <Button variant="danger" onClick={deleteDesignFunction}>
            {t("JetztLoeschen")}
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={createDesignModal}
        style={{ color: "white", fontFamily: "Lato" }}
      >
        <Modal.Header
          style={{
            backgroundColor: "black",
            borderBottom: "black",
            paddingBottom: "0px",
            marginTop: "10px",
            marginLeft: "20px",
            marginRight: "20px",
          }}
          closeButton
        >
          <Modal.Title>{t("NeuesDesignErstellen")}</Modal.Title>
        </Modal.Header>

        <Modal.Body style={{ backgroundColor: "black", paddingBottom: "0px" }}>
          <p>
            <Form.Group
              className="mb-2 identifierClassName"
              controlId="identifierID"
            >
              <Form.Control
                onChange={(e) => identifierChangeFunction(e)}
                /* value={newDesignIdentifier} */
                type="text"
                className="inputFormContactFormTwo"
                aria-describedby="identifierID"
              />
            </Form.Group>
          </p>
        </Modal.Body>

        <Modal.Footer
          style={{
            backgroundColor: "black",
            borderTop: "black",
            paddingTop: "0px",
            marginBottom: "5px",
          }}
        >
          <Button onClick={setCreateDesignModalFunction} variant="secondary">
            {t("Schliessen")}
          </Button>
          <div
            style={{ marginLeft: "10px", marginRight: "30px" }}
            onClick={(event) => megaUltraSaveNewDesign(event)}
          >
            <Button variant="primary">{t("JetztErstellen")}</Button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default SiteBelongsToUserSaveDesign;
