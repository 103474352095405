// @flow
import React from "react";
import { Button, Row, Col, Form, InputGroup } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Select from "react-select";
const RegisterInfos = ({
  regAdresse,
  doRegAdresseHandler,
  regPLZ,
  doRegPLZHandler,
  regOrt,
  doRegOrtHandler,
  regLand,
  doRegLandHandler,
  adresseValid,
  plzValid,
  ortValid,
  landValid,
  adresseConfirm,
  doDetailsHandler,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <form
        style={{ paddingLeft: "45px", paddingRight: "45px", marginTop: "10px" }}
        action="#"
      >
        <div className="mb-1 mt-3 text-center">
          <label
            style={{ color: "white", fontSize: "16px" }}
            htmlFor="address"
            className="form-label"
          >
            {t("Adresse")}
          </label>
        </div>
        <InputGroup>
          <InputGroup.Text
            className={
              adresseValid
                ? "emailUniIconContainer regInputValid"
                : "emailUniIconContainer regInputInvalid"
            }
          >
            <span id="emailUniIcon">🏠</span>
          </InputGroup.Text>
          <Form.Control
            aria-label="address"
            aria-describedby="emailUniIcon"
            onChange={doRegAdresseHandler}
            value={regAdresse}
            className={
              adresseValid
                ? "regInputValid form-control inputEmailPw"
                : "regInputInvalid form-control inputEmailPw"
            }
            type="text"
            id="address"
            placeholder={t("Adresse")}
          />
        </InputGroup>
        <div className="mb-4" style={{ paddingTop: "2px", paddingLeft: "2px" }}>
          {!adresseValid && (
            <div
              style={{ fontSize: "13px", color: "#eb0b3d", fontWeight: "bold" }}
            >
              {t("AdresseMeldung")}
            </div>
          )}
        </div>

        <Row>
          <Col lg="5">
            <div className="mb-1 mt-2 text-center">
              <label
                style={{ color: "white", fontSize: "16px" }}
                htmlFor="zip"
                className="form-label"
              >
                {t("PLZ")}
              </label>
            </div>
            <InputGroup>
              <InputGroup.Text
                className={
                  plzValid
                    ? "emailUniIconContainer regInputValid"
                    : "emailUniIconContainer regInputInvalid"
                }
              >
                <span id="emailUniIcon">📯</span>
              </InputGroup.Text>
              <Form.Control
                aria-label="zip"
                aria-describedby="emailUniIcon"
                onChange={doRegPLZHandler}
                value={regPLZ}
                className={
                  plzValid
                    ? "regInputValid form-control inputEmailPw"
                    : "regInputInvalid form-control inputEmailPw"
                }
                type="text"
                id="zip"
                placeholder={t("PLZ")}
              />
            </InputGroup>
            <div
              className="mb-3"
              style={{ paddingTop: "2px", paddingLeft: "2px" }}
            >
              {!plzValid && (
                <div
                  style={{
                    fontSize: "13px",
                    color: "#eb0b3d",
                    fontWeight: "bold",
                  }}
                >
                  {t("PLZMeldung")}
                </div>
              )}
            </div>
          </Col>

          <Col lg="7">
            <div className="mb-1 mt-2 text-center">
              <label
                style={{ color: "white", fontSize: "16px" }}
                htmlFor="ort"
                className="form-label"
              >
                {t("Ort")}
              </label>
            </div>
            <InputGroup>
              <InputGroup.Text
                className={
                  ortValid
                    ? "emailUniIconContainer regInputValid"
                    : "emailUniIconContainer regInputInvalid"
                }
              >
                <span id="emailUniIcon">📍</span>
              </InputGroup.Text>
              <Form.Control
                aria-label="ort"
                aria-describedby="emailUniIcon"
                onChange={doRegOrtHandler}
                value={regOrt}
                className={
                  ortValid
                    ? "regInputValid form-control inputEmailPw"
                    : "regInputInvalid form-control inputEmailPw"
                }
                type="text"
                id="ort"
                placeholder={t("Ort")}
              />
            </InputGroup>
            <div
              className="mb-3"
              style={{ paddingTop: "2px", paddingLeft: "2px" }}
            >
              {!ortValid && (
                <div
                  style={{
                    fontSize: "13px",
                    color: "#eb0b3d",
                    fontWeight: "bold",
                  }}
                >
                  {t("OrtMeldung")}
                </div>
              )}
            </div>
          </Col>
        </Row>
        <div className="mb-1 mt-2 text-center">
          <label
            style={{ color: "white", fontSize: "16px" }}
            htmlFor="country"
            className="form-label"
          >
            🌎 {t("Land")}
          </label>
        </div>
        <Select
          className={
            landValid
              ? "react-select landUniIconContainer regInputValid"
              : "react-select landUniIconContainer regInputInvalid"
          }
          classNamePrefix="react-select"
          onChange={doRegLandHandler}
          theme={(theme) => ({
            ...theme,
            borderRadius: 3,
            colors: {
              ...theme.colors,
              color: "white",
              neutral80: "white",
              text: "white",
              primary25: "#635bff",
              primary: "#635bff",
              font: "white",
            },
          })}
          options={[
            { value: "afghanistan", label: "Afghanistan" },
            { value: "aland_islands", label: "Aland Islands" },
            { value: "albania", label: "Albania" },
            { value: "algeria", label: "Algeria" },
            { value: "american_samoa", label: "American Samoa" },
            { value: "andorra", label: "Andorra" },
            { value: "angola", label: "Angola" },
            { value: "anguilla", label: "Anguilla" },
            { value: "antarctica", label: "Antarctica" },
            { value: "antigua_and_barbuda", label: "Antigua and Barbuda" },
            { value: "argentina", label: "Argentina" },
            { value: "armenia", label: "Armenia" },
            { value: "aruba", label: "Aruba" },
            { value: "australia", label: "Australia" },
            { value: "austria", label: "Austria" },
            { value: "azerbaijan", label: "Azerbaijan" },
            { value: "bahamas", label: "Bahamas" },
            { value: "bahrain", label: "Bahrain" },
            { value: "bangladesh", label: "Bangladesh" },
            { value: "barbados", label: "Barbados" },
            { value: "belarus", label: "Belarus" },
            { value: "belgium", label: "Belgium" },
            { value: "belize", label: "Belize" },
            { value: "benin", label: "Benin" },
            { value: "bermuda", label: "Bermuda" },
            { value: "bhutan", label: "Bhutan" },
            { value: "bolivia", label: "Bolivia" },
            { value: "bonaire", label: "Bonaire" },
            {
              value: "bosnia_and_herzegovina",
              label: "Bosnia and Herzegovina",
            },
            { value: "botswana", label: "Botswana" },
            { value: "bouvet_island", label: "Bouvet Island" },
            { value: "brazil", label: "Brazil" },
            { value: "brunei_darussalam", label: "Brunei Darussalam" },
            { value: "bulgaria", label: "Bulgaria" },
            { value: "burkina_faso", label: "Burkina Faso" },
            { value: "burundi", label: "Burundi" },
            { value: "cabo_verde", label: "Cabo Verde" },
            { value: "cambodia", label: "Cambodia" },
            { value: "cameroon", label: "Cameroon" },
            { value: "canada", label: "Canada" },
            { value: "cayman_islands", label: "Cayman Islands" },
            {
              value: "central_african_republic",
              label: "Central African Republic",
            },
            { value: "chad", label: "Chad" },
            { value: "chile", label: "Chile" },
            { value: "china", label: "China" },
            { value: "christmas_island", label: "Christmas Island" },
            { value: "cocos_islands", label: "Cocos Islands" },
            { value: "colombia", label: "Colombia" },
            { value: "comoros", label: "Comoros" },
            { value: "congo", label: "Congo" },
            { value: "congo", label: "Congo" },
            { value: "cook_islands", label: "Cook Islands" },
            { value: "costa_rica", label: "Costa Rica" },
            { value: "cote_d'ivoire", label: "Cote d'Ivoire" },
            { value: "croatia", label: "Croatia" },
            { value: "cuba", label: "Cuba" },
            { value: "curacao", label: "Curacao" },
            { value: "cyprus", label: "Cyprus" },
            { value: "czech_republic", label: "Czech Republic" },
            { value: "denmark", label: "Denmark" },
            { value: "djibouti", label: "Djibouti" },
            { value: "dominica", label: "Dominica" },
            { value: "dominican_republic", label: "Dominican Republic" },
            { value: "ecuador", label: "Ecuador" },
            { value: "egypt", label: "Egypt" },
            { value: "el_salvador", label: "El Salvador" },
            { value: "equatorial_guinea", label: "Equatorial Guinea" },
            { value: "eritrea", label: "Eritrea" },
            { value: "estonia", label: "Estonia" },
            { value: "ethiopia", label: "Ethiopia" },
            { value: "falkland_islands", label: "Falkland Islands" },
            { value: "faroe_islands", label: "Faroe Islands" },
            { value: "fiji", label: "Fiji" },
            { value: "finland", label: "Finland" },
            { value: "france", label: "France" },
            { value: "french_guiana", label: "French Guiana" },
            { value: "french_polynesia", label: "French Polynesia" },
            {
              value: "french_southern_territories",
              label: "French Southern Territories",
            },
            { value: "gabon", label: "Gabon" },
            { value: "gambia", label: "Gambia" },
            { value: "georgia", label: "Georgia" },
            { value: "germany", label: "Germany" },
            { value: "ghana", label: "Ghana" },
            { value: "gibraltar", label: "Gibraltar" },
            { value: "greece", label: "Greece" },
            { value: "greenland", label: "Greenland" },
            { value: "grenada", label: "Grenada" },
            { value: "guadeloupe", label: "Guadeloupe" },
            { value: "guam", label: "Guam" },
            { value: "guatemala", label: "Guatemala" },
            { value: "guernsey", label: "Guernsey" },
            { value: "guinea", label: "Guinea" },
            { value: "guinea-bissau", label: "Guinea-Bissau" },
            { value: "guyana", label: "Guyana" },
            { value: "haiti", label: "Haiti" },
            {
              value: "heard_and_mcdonald_islands",
              label: "Heard and McDonald Islands",
            },
            { value: "holy_see", label: "Holy See" },
            { value: "honduras", label: "Honduras" },
            { value: "hong_kong", label: "Hong Kong" },
            { value: "hungary", label: "Hungary" },
            { value: "iceland", label: "Iceland" },
            { value: "india", label: "India" },
            { value: "indonesia", label: "Indonesia" },
            { value: "iran", label: "Iran" },
            { value: "iraq", label: "Iraq" },
            { value: "ireland", label: "Ireland" },
            { value: "isle_of_man", label: "Isle of Man" },
            { value: "israel", label: "Israel" },
            { value: "italy", label: "Italy" },
            { value: "jamaica", label: "Jamaica" },
            { value: "japan", label: "Japan" },
            { value: "jersey", label: "Jersey" },
            { value: "jordan", label: "Jordan" },
            { value: "kazakhstan", label: "Kazakhstan" },
            { value: "kenya", label: "Kenya" },
            { value: "kiribati", label: "Kiribati" },
            { value: "korea", label: "Korea" },
            { value: "kosovo", label: "Kosovo" },
            { value: "kuwait", label: "Kuwait" },
            { value: "kyrgyzstan", label: "Kyrgyzstan" },
            { value: "lao_peoples", label: "Lao People's" },
            { value: "latvia", label: "Latvia" },
            { value: "lebanon", label: "Lebanon" },
            { value: "lesotho", label: "Lesotho" },
            { value: "liberia", label: "Liberia" },
            { value: "libya", label: "Libya" },
            { value: "liechtenstein", label: "Liechtenstein" },
            { value: "lithuania", label: "Lithuania" },
            { value: "luxembourg", label: "Luxembourg" },
            { value: "macao", label: "Macao" },
            { value: "macedonia", label: "Macedonia" },
            { value: "madagascar", label: "Madagascar" },
            { value: "malawi", label: "Malawi" },
            { value: "malaysia", label: "Malaysia" },
            { value: "maldives", label: "Maldives" },
            { value: "mali", label: "Mali" },
            { value: "malta", label: "Malta" },
            { value: "marshall_islands", label: "Marshall Islands" },
            { value: "martinique", label: "Martinique" },
            { value: "mauritania", label: "Mauritania" },
            { value: "mauritius", label: "Mauritius" },
            { value: "mayotte", label: "Mayotte" },
            { value: "mexico", label: "Mexico" },
            { value: "micronesia", label: "Micronesia" },
            { value: "moldova", label: "Moldova" },
            { value: "monaco", label: "Monaco" },
            { value: "mongolia", label: "Mongolia" },
            { value: "montenegro", label: "Montenegro" },
            { value: "montserrat", label: "Montserrat" },
            { value: "morocco", label: "Morocco" },
            { value: "mozambique", label: "Mozambique" },
            { value: "myanmar", label: "Myanmar" },
            { value: "namibia", label: "Namibia" },
            { value: "nauru", label: "Nauru" },
            { value: "nepal", label: "Nepal" },
            { value: "netherlands", label: "Netherlands" },
            { value: "new_caledonia", label: "New Caledonia" },
            { value: "new_zealand", label: "New Zealand" },
            { value: "nicaragua", label: "Nicaragua" },
            { value: "niger", label: "Niger" },
            { value: "nigeria", label: "Nigeria" },
            { value: "niue", label: "Niue" },
            { value: "norfolk_island", label: "Norfolk Island" },
            {
              value: "northern_mariana_islands",
              label: "Northern Mariana Islands",
            },
            { value: "norway", label: "Norway" },
            { value: "oman", label: "Oman" },
            { value: "pakistan", label: "Pakistan" },
            { value: "palau", label: "Palau" },
            { value: "palestine", label: "Palestine" },
            { value: "panama", label: "Panama" },
            { value: "papua_new_guinea", label: "Papua New Guinea" },
            { value: "paraguay", label: "Paraguay" },
            { value: "peru", label: "Peru" },
            { value: "philippines", label: "Philippines" },
            { value: "pitcairn", label: "Pitcairn" },
            { value: "poland", label: "Poland" },
            { value: "portugal", label: "Portugal" },
            { value: "puerto_rico", label: "Puerto Rico" },
            { value: "qatar", label: "Qatar" },
            { value: "reunion", label: "Reunion" },
            { value: "romania", label: "Romania" },
            { value: "russian_federation", label: "Russian Federation" },
            { value: "rwanda", label: "Rwanda" },
            { value: "saint_barthelemy", label: "Saint Barthelemy" },
            { value: "saint_helena", label: "Saint Helena" },
            { value: "saint_kitts_and_nevis", label: "Saint Kitts and Nevis" },
            { value: "saint_lucia", label: "Saint Lucia" },
            { value: "saint_martin", label: "Saint Martin" },
            {
              value: "saint_pierre_and_miquelon",
              label: "Saint Pierre and Miquelon",
            },
            {
              value: "saint_vincent_and_the_grenadines",
              label: "Saint Vincent and the Grenadines",
            },
            { value: "samoa", label: "Samoa" },
            { value: "san_marino", label: "San Marino" },
            { value: "sao_tome_and_principe", label: "Sao Tome and Principe" },
            { value: "saudi_arabia", label: "Saudi Arabia" },
            { value: "senegal", label: "Senegal" },
            { value: "serbia", label: "Serbia" },
            { value: "seychelles", label: "Seychelles" },
            { value: "sierra_leone", label: "Sierra Leone" },
            { value: "singapore", label: "Singapore" },
            { value: "sint_maarten", label: "Sint Maarten" },
            { value: "slovakia", label: "Slovakia" },
            { value: "slovenia", label: "Slovenia" },
            { value: "solomon_islands", label: "Solomon Islands" },
            { value: "somalia", label: "Somalia" },
            { value: "south_africa", label: "South Africa" },
            { value: "south_georgia", label: "South Georgia" },
            { value: "south_sudan", label: "South Sudan" },
            { value: "spain", label: "Spain" },
            { value: "sri_lanka", label: "Sri Lanka" },
            { value: "sudan", label: "Sudan" },
            { value: "suriname", label: "Suriname" },
            {
              value: "svalbard_and_jan mayen",
              label: "Svalbard and Jan Mayen",
            },
            { value: "swaziland", label: "Swaziland" },
            { value: "sweden", label: "Sweden" },
            { value: "switzerland", label: "Switzerland" },
            { value: "syrian_arab_republic", label: "Syrian Arab Republic" },
            { value: "taiwan", label: "Taiwan" },
            { value: "tajikistan", label: "Tajikistan" },
            { value: "tanzania", label: "Tanzania" },
            { value: "thailand", label: "Thailand" },
            { value: "timor_leste", label: "Timor Leste" },
            { value: "togo", label: "Togo" },
            { value: "tokelau", label: "Tokelau" },
            { value: "tonga", label: "Tonga" },
            { value: "trinidad_and_tobago", label: "Trinidad and Tobago" },
            { value: "tunisia", label: "Tunisia" },
            { value: "turkey", label: "Turkey" },
            { value: "turkmenistan", label: "Turkmenistan" },
            {
              value: "turks_and_caicos islands",
              label: "Turks and Caicos Islands",
            },
            { value: "tuvalu", label: "Tuvalu" },
            { value: "uganda", label: "Uganda" },
            { value: "ukraine", label: "Ukraine" },
            { value: "united_arab_emirates", label: "United Arab Emirates" },
            { value: "united_kingdom", label: "United Kingdom" },
            {
              value: "united_states_of america",
              label: "United States of America (USA)",
            },
            { value: "uruguay", label: "Uruguay" },
            { value: "uzbekistan", label: "Uzbekistan" },
            { value: "vanuatu", label: "Vanuatu" },
            { value: "venezuela", label: "Venezuela" },
            { value: "viet_nam", label: "Viet Nam" },
            { value: "virgin_islands", label: "Virgin Islands" },
            { value: "virgin_islands", label: "Virgin Islands" },
            { value: "wallis_and_futuna", label: "Wallis and Futuna" },
            { value: "western_sahara", label: "Western Sahara" },
            { value: "yemen", label: "Yemen" },
            { value: "zambia", label: "Zambia" },
            { value: "zimbabwe", label: "Zimbabwe" },
          ]}
        ></Select>

        <div className="mb-3" style={{ paddingTop: "2px", paddingLeft: "2px" }}>
          {!landValid && (
            <div
              style={{ fontSize: "13px", color: "#eb0b3d", fontWeight: "bold" }}
            >
              {t("LandMeldung")}
            </div>
          )}
        </div>
        <div className="mt-5 d-flex justify-content-between">
          <div className="mb-3" style={{ display: "inline-block" }}>
            <Button onClick={doDetailsHandler} variant="secondary">
              {t("Zurueck")}
            </Button>
          </div>
          <div className="mb-3" style={{ display: "inline-block" }}>
            <Button onClick={adresseConfirm} variant="primary">
              {t("Weiter")}
            </Button>
          </div>
        </div>
      </form>
    </>
  );
};

export default RegisterInfos;
