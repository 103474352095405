import React from "react";
import { createUseStyles } from "react-jss";
import SideBarLeftAlleProdukteTitel from "./SideBarLeftAlleProdukteTitel";
import SideBarLeftMainKategorie from "./SideBarLeftMainKategorie";
import SideBarLeftSubKategorie from "./SideBarLeftSubKategorie";
import SideBarLeftSubSubKategorie from "./SideBarLeftSubSubKategorie";

const SideBarLeftKategories = ({
  resetFilterHandler,
  ultraCategoriesNew,
  categoryLeftSideHandler,
  DB_fontFace_FontFamilyName,
  userOwnsThisSite,
  SideBarLeft_Unterkategorie_Farbe_Groesse_Stil_OnMouseOverAndOut,
  SideBarLeft_Unterkategorie_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut,
  SideBarLeft_Unterkategorie_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut,
  SideBarLeft_Unterkategorie_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut,
  SideBarLeft_UnterUnterkategorie_Farbe_Groesse_Stil_OnMouseOverAndOut,
  SideBarLeft_UnterUnterkategorie_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut,
  SideBarLeft_UnterUnterkategorie_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut,
  SideBarLeft_UnterUnterkategorie_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut,
  SideBarLeft_Container_Farbe_Groesse_Stil_OnMouseOverAndOut,
  SideBarLeft_Container_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut,
  SideBarLeft_Container_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut,
  SideBarLeft_Container_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut,
  SideBarLeft_AlleProdukte_Farbe_Groesse_Stil_OnMouseOverAndOut,
  SideBarLeft_AlleProdukte_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut,
  SideBarLeft_AlleProdukte_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut,
  SideBarLeft_AlleProdukte_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut,

  DB_fontFace_alleProdukteAuswahlBB_FontFamilyName,
  DB_fontFace_alleProdukteAuswahlBB_FontFamilySrc,
  DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_FontWeight,
  DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_FontColor,
  DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_FontSize,
  DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_BackgroundColor,
  DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_BorderTop,
  DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_BorderStyleTop,
  DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_BorderColorTop,
  DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_BorderBottom,
  DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_BorderStyleBottom,
  DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_BorderColorBottom,
  DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_BorderLeft,
  DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_BorderStyleLeft,
  DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_BorderColorLeft,
  DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_BorderRight,
  DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_BorderStyleRight,
  DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_BorderColorRight,
  DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_BorderTopRightRadius,
  DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_BorderTopLeftRadius,
  DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_BorderBottomRightRadius,
  DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_BorderBottomLeftRadius,
  DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_marginLeftRight,
  DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_marginTopBottom,
  DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_paddingLeftRight,
  DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_paddingTopBottom,
  DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_letterSpacing,
  DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_ShadowHorizontal,
  DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_ShadowVertical,
  DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_ShadowBlur,
  DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_ShadowColor,
  DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_TextDecoration,
  DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_TextDecorationStyle,
  DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_TextAlign,

  DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_FontWeight,
  DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_FontColor,
  DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_FontSize,
  DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_BackgroundColor,
  DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_TextAlign,
  DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_BorderTop,
  DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_BorderStyleTop,
  DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_BorderColorTop,
  DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_BorderBottom,
  DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_BorderStyleBottom,
  DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_BorderColorBottom,
  DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_BorderLeft,
  DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_BorderStyleLeft,
  DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_BorderColorLeft,
  DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_BorderRight,
  DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_BorderStyleRight,
  DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_BorderColorRight,
  DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_BorderTopRightRadius,
  DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_BorderTopLeftRadius,
  DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_BorderBottomRightRadius,
  DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_BorderBottomLeftRadius,
  DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_marginLeftRight_Hover,
  DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_marginTopBottom_Hover,
  DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_paddingLeftRight_Hover,
  DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_paddingTopBottom_Hover,
  DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_letterSpacing_Hover,
  DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_ShadowHorizontal_Hover,
  DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_ShadowVertical_Hover,
  DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_ShadowBlur_Hover,
  DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_ShadowColor_Hover,
  DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_TextDecoration_Hover,
  DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_TextDecorationStyle_Hover,

  DB_LeftSidebar_MenuItem_Hauptkategorie_FontFace,
  DB_LeftSidebar_MenuItem_Hauptkategorie_FontWeight,
  DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_FontWeight,
  DB_LeftSidebar_MenuItem_Hauptkategorie_FontColor,
  DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_FontColor,
  DB_LeftSidebar_MenuItem_Hauptkategorie_FontSize,
  DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_FontSize,
  SideBarLeft_Hauptkategorie_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut,
  SideBarLeft_Hauptkategorie_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut,
  DB_LeftSidebar_MenuItem_Hauptkategorie_BackgroundColor,
  DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_BackgroundColor,
  DB_LeftSidebar_MenuItem_Hauptkategorie_TextAlign,
  DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_TextAlign,
  SideBarLeft_Hauptkategorie_Farbe_Groesse_Stil_OnMouseOverAndOut,
  SideBarLeft_Hauptkategorie_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut,
  DB_LeftSidebar_MenuItem_Hauptkategorie_BorderTop,
  DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_BorderTop,
  DB_LeftSidebar_MenuItem_Hauptkategorie_BorderStyleTop,
  DB_LeftSidebar_MenuItem_Hauptkategorie_BorderColorTop,
  DB_LeftSidebar_MenuItem_Hauptkategorie_BorderBottom,
  DB_LeftSidebar_MenuItem_Hauptkategorie_BorderStyleBottom,
  DB_LeftSidebar_MenuItem_Hauptkategorie_BorderColorBottom,
  DB_LeftSidebar_MenuItem_Hauptkategorie_BorderLeft,
  DB_LeftSidebar_MenuItem_Hauptkategorie_BorderStyleLeft,
  DB_LeftSidebar_MenuItem_Hauptkategorie_BorderColorLeft,
  DB_LeftSidebar_MenuItem_Hauptkategorie_BorderRight,
  DB_LeftSidebar_MenuItem_Hauptkategorie_BorderStyleRight,
  DB_LeftSidebar_MenuItem_Hauptkategorie_BorderColorRight,

  DB_LeftSidebar_MenuItem_Hauptkategorie_BorderTopRightRadius,
  DB_LeftSidebar_MenuItem_Hauptkategorie_BorderTopLeftRadius,
  DB_LeftSidebar_MenuItem_Hauptkategorie_BorderBottomRightRadius,
  DB_LeftSidebar_MenuItem_Hauptkategorie_BorderBottomLeftRadius,

  DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_FontFace,
  DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_BorderTopRightRadius,
  DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_BorderTopLeftRadius,
  DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_BorderBottomRightRadius,
  DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_BorderBottomLeftRadius,

  DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_BorderStyleTop,
  DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_BorderColorTop,
  DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_BorderBottom,
  DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_BorderStyleBottom,

  DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_BorderColorBottom,
  DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_BorderLeft,
  DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_BorderStyleLeft,
  DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_BorderColorLeft,

  DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_BorderRight,
  DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_BorderStyleRight,
  DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_BorderColorRight,

  DB_LeftSidebar_MenuItem_Hauptkategorie_marginTopBottom,
  DB_LeftSidebar_MenuItem_Hauptkategorie_marginLeftRight,
  DB_LeftSidebar_MenuItem_Hauptkategorie_paddingLeftRight,
  DB_LeftSidebar_MenuItem_Hauptkategorie_paddingTopBottom,

  DB_LeftSidebar_MenuItem_Hauptkategorie_letterSpacing,
  DB_LeftSidebar_MenuItem_Hauptkategorie_ShadowHorizontal,
  DB_LeftSidebar_MenuItem_Hauptkategorie_ShadowVertical,
  DB_LeftSidebar_MenuItem_Hauptkategorie_ShadowBlur,
  DB_LeftSidebar_MenuItem_Hauptkategorie_ShadowColor,
  DB_LeftSidebar_MenuItem_Hauptkategorie_TextDecoration,
  DB_LeftSidebar_MenuItem_Hauptkategorie_TextDecorationStyle,

  DB_LeftSidebar_MenuItem_Hauptkategorie_marginTopBottom_Hover,
  DB_LeftSidebar_MenuItem_Hauptkategorie_marginLeftRight_Hover,
  DB_LeftSidebar_MenuItem_Hauptkategorie_paddingLeftRight_Hover,
  DB_LeftSidebar_MenuItem_Hauptkategorie_paddingTopBottom_Hover,

  DB_LeftSidebar_MenuItem_Hauptkategorie_letterSpacing_Hover,
  DB_LeftSidebar_MenuItem_Hauptkategorie_ShadowHorizontal_Hover,
  DB_LeftSidebar_MenuItem_Hauptkategorie_ShadowVertical_Hover,
  DB_LeftSidebar_MenuItem_Hauptkategorie_ShadowBlur_Hover,
  DB_LeftSidebar_MenuItem_Hauptkategorie_ShadowColor_Hover,
  DB_LeftSidebar_MenuItem_Hauptkategorie_TextDecoration_Hover,
  DB_LeftSidebar_MenuItem_Hauptkategorie_TextDecorationStyle_Hover,

  DB_fontFace_unterkategorieTitel_FontFamilyName,
  DB_fontFace_unterkategorieTitel_FontFamilySrc,
  DB_LeftSidebar_MenuItem_Unterkategorie_FontFace,
  DB_LeftSidebar_MenuItem_Unterkategorie_Hover_FontFace,
  DB_LeftSidebar_MenuItem_Unterkategorie_FontWeight,
  DB_LeftSidebar_MenuItem_Unterkategorie_Hover_FontWeight,
  DB_LeftSidebar_MenuItem_Unterkategorie_FontColor,
  DB_LeftSidebar_MenuItem_Unterkategorie_Hover_FontColor,
  DB_LeftSidebar_MenuItem_Unterkategorie_FontSize,
  DB_LeftSidebar_MenuItem_Unterkategorie_Hover_FontSize,
  DB_LeftSidebar_MenuItem_Unterkategorie_BackgroundColor,
  DB_LeftSidebar_MenuItem_Unterkategorie_Hover_BackgroundColor,
  DB_LeftSidebar_MenuItem_Unterkategorie_TextAlign,
  DB_LeftSidebar_MenuItem_Unterkategorie_Hover_TextAlign,
  DB_LeftSidebar_MenuItem_Unterkategorie_BorderTop,
  DB_LeftSidebar_MenuItem_Unterkategorie_Hover_BorderTop,
  DB_LeftSidebar_MenuItem_Unterkategorie_BorderStyleTop,
  DB_LeftSidebar_MenuItem_Unterkategorie_Hover_BorderStyleTop,
  DB_LeftSidebar_MenuItem_Unterkategorie_BorderColorTop,
  DB_LeftSidebar_MenuItem_Unterkategorie_Hover_BorderColorTop,
  DB_LeftSidebar_MenuItem_Unterkategorie_BorderBottom,
  DB_LeftSidebar_MenuItem_Unterkategorie_Hover_BorderBottom,
  DB_LeftSidebar_MenuItem_Unterkategorie_BorderStyleBottom,
  DB_LeftSidebar_MenuItem_Unterkategorie_Hover_BorderStyleBottom,
  DB_LeftSidebar_MenuItem_Unterkategorie_BorderColorBottom,
  DB_LeftSidebar_MenuItem_Unterkategorie_Hover_BorderColorBottom,
  DB_LeftSidebar_MenuItem_Unterkategorie_BorderLeft,
  DB_LeftSidebar_MenuItem_Unterkategorie_Hover_BorderLeft,
  DB_LeftSidebar_MenuItem_Unterkategorie_BorderStyleLeft,
  DB_LeftSidebar_MenuItem_Unterkategorie_Hover_BorderStyleLeft,
  DB_LeftSidebar_MenuItem_Unterkategorie_BorderColorLeft,
  DB_LeftSidebar_MenuItem_Unterkategorie_Hover_BorderColorLeft,
  DB_LeftSidebar_MenuItem_Unterkategorie_BorderRight,
  DB_LeftSidebar_MenuItem_Unterkategorie_Hover_BorderRight,
  DB_LeftSidebar_MenuItem_Unterkategorie_BorderStyleRight,
  DB_LeftSidebar_MenuItem_Unterkategorie_Hover_BorderStyleRight,
  DB_LeftSidebar_MenuItem_Unterkategorie_BorderColorRight,
  DB_LeftSidebar_MenuItem_Unterkategorie_Hover_BorderColorRight,
  DB_LeftSidebar_MenuItem_Unterkategorie_BorderTopRightRadius,
  DB_LeftSidebar_MenuItem_Unterkategorie_Hover_BorderTopRightRadius,
  DB_LeftSidebar_MenuItem_Unterkategorie_BorderTopLeftRadius,
  DB_LeftSidebar_MenuItem_Unterkategorie_Hover_BorderTopLeftRadius,
  DB_LeftSidebar_MenuItem_Unterkategorie_BorderBottomRightRadius,
  DB_LeftSidebar_MenuItem_Unterkategorie_Hover_BorderBottomRightRadius,
  DB_LeftSidebar_MenuItem_Unterkategorie_BorderBottomLeftRadius,
  DB_LeftSidebar_MenuItem_Unterkategorie_Hover_BorderBottomLeftRadius,
  DB_LeftSidebar_MenuItem_Unterkategorie_marginTopBottom,
  DB_LeftSidebar_MenuItem_Unterkategorie_marginLeftRight,
  DB_LeftSidebar_MenuItem_Unterkategorie_paddingLeftRight,
  DB_LeftSidebar_MenuItem_Unterkategorie_paddingTopBottom,
  DB_LeftSidebar_MenuItem_Unterkategorie_letterSpacing,
  DB_LeftSidebar_MenuItem_Unterkategorie_ShadowHorizontal,
  DB_LeftSidebar_MenuItem_Unterkategorie_ShadowVertical,
  DB_LeftSidebar_MenuItem_Unterkategorie_ShadowBlur,
  DB_LeftSidebar_MenuItem_Unterkategorie_ShadowColor,
  DB_LeftSidebar_MenuItem_Unterkategorie_TextDecoration,
  DB_LeftSidebar_MenuItem_Unterkategorie_TextDecorationStyle,
  DB_LeftSidebar_MenuItem_Unterkategorie_marginTopBottom_Hover,
  DB_LeftSidebar_MenuItem_Unterkategorie_marginLeftRight_Hover,
  DB_LeftSidebar_MenuItem_Unterkategorie_paddingLeftRight_Hover,
  DB_LeftSidebar_MenuItem_Unterkategorie_paddingTopBottom_Hover,
  DB_LeftSidebar_MenuItem_Unterkategorie_letterSpacing_Hover,
  DB_LeftSidebar_MenuItem_Unterkategorie_ShadowHorizontal_Hover,
  DB_LeftSidebar_MenuItem_Unterkategorie_ShadowVertical_Hover,
  DB_LeftSidebar_MenuItem_Unterkategorie_ShadowBlur_Hover,
  DB_LeftSidebar_MenuItem_Unterkategorie_ShadowColor_Hover,
  DB_LeftSidebar_MenuItem_Unterkategorie_TextDecoration_Hover,
  DB_LeftSidebar_MenuItem_Unterkategorie_TextDecorationStyle_Hover,

  DB_fontFace_unterunterkategorieTitel_FontFamilyName,
  DB_fontFace_unterunterkategorieTitel_FontFamilySrc,
  DB_LeftSidebar_MenuItem_UnterUnterkategorie_FontFace,
  DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_FontFace,
  DB_LeftSidebar_MenuItem_UnterUnterkategorie_FontWeight,
  DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_FontWeight,
  DB_LeftSidebar_MenuItem_UnterUnterkategorie_FontColor,
  DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_FontColor,
  DB_LeftSidebar_MenuItem_UnterUnterkategorie_FontSize,
  DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_FontSize,
  DB_LeftSidebar_MenuItem_UnterUnterkategorie_BackgroundColor,
  DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_BackgroundColor,
  DB_LeftSidebar_MenuItem_UnterUnterkategorie_TextAlign,
  DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_TextAlign,
  DB_LeftSidebar_MenuItem_UnterUnterkategorie_BorderTop,
  DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_BorderTop,
  DB_LeftSidebar_MenuItem_UnterUnterkategorie_BorderStyleTop,
  DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_BorderStyleTop,
  DB_LeftSidebar_MenuItem_UnterUnterkategorie_BorderColorTop,
  DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_BorderColorTop,
  DB_LeftSidebar_MenuItem_UnterUnterkategorie_BorderBottom,
  DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_BorderBottom,
  DB_LeftSidebar_MenuItem_UnterUnterkategorie_BorderStyleBottom,
  DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_BorderStyleBottom,
  DB_LeftSidebar_MenuItem_UnterUnterkategorie_BorderColorBottom,
  DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_BorderColorBottom,
  DB_LeftSidebar_MenuItem_UnterUnterkategorie_BorderLeft,
  DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_BorderLeft,
  DB_LeftSidebar_MenuItem_UnterUnterkategorie_BorderStyleLeft,
  DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_BorderStyleLeft,
  DB_LeftSidebar_MenuItem_UnterUnterkategorie_BorderColorLeft,
  DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_BorderColorLeft,
  DB_LeftSidebar_MenuItem_UnterUnterkategorie_BorderRight,
  DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_BorderRight,
  DB_LeftSidebar_MenuItem_UnterUnterkategorie_BorderStyleRight,
  DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_BorderStyleRight,
  DB_LeftSidebar_MenuItem_UnterUnterkategorie_BorderColorRight,
  DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_BorderColorRight,
  DB_LeftSidebar_MenuItem_UnterUnterkategorie_BorderTopRightRadius,
  DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_BorderTopRightRadius,
  DB_LeftSidebar_MenuItem_UnterUnterkategorie_BorderTopLeftRadius,
  DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_BorderTopLeftRadius,
  DB_LeftSidebar_MenuItem_UnterUnterkategorie_BorderBottomRightRadius,
  DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_BorderBottomRightRadius,
  DB_LeftSidebar_MenuItem_UnterUnterkategorie_BorderBottomLeftRadius,
  DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_BorderBottomLeftRadius,
  DB_LeftSidebar_MenuItem_UnterUnterkategorie_marginTopBottom,
  DB_LeftSidebar_MenuItem_UnterUnterkategorie_marginLeftRight,
  DB_LeftSidebar_MenuItem_UnterUnterkategorie_paddingLeftRight,
  DB_LeftSidebar_MenuItem_UnterUnterkategorie_paddingTopBottom,
  DB_LeftSidebar_MenuItem_UnterUnterkategorie_letterSpacing,
  DB_LeftSidebar_MenuItem_UnterUnterkategorie_ShadowHorizontal,
  DB_LeftSidebar_MenuItem_UnterUnterkategorie_ShadowVertical,
  DB_LeftSidebar_MenuItem_UnterUnterkategorie_ShadowBlur,
  DB_LeftSidebar_MenuItem_UnterUnterkategorie_ShadowColor,
  DB_LeftSidebar_MenuItem_UnterUnterkategorie_TextDecoration,
  DB_LeftSidebar_MenuItem_UnterUnterkategorie_TextDecorationStyle,
  DB_LeftSidebar_MenuItem_UnterUnterkategorie_marginTopBottom_Hover,
  DB_LeftSidebar_MenuItem_UnterUnterkategorie_marginLeftRight_Hover,
  DB_LeftSidebar_MenuItem_UnterUnterkategorie_paddingLeftRight_Hover,
  DB_LeftSidebar_MenuItem_UnterUnterkategorie_paddingTopBottom_Hover,
  DB_LeftSidebar_MenuItem_UnterUnterkategorie_letterSpacing_Hover,
  DB_LeftSidebar_MenuItem_UnterUnterkategorie_ShadowHorizontal_Hover,
  DB_LeftSidebar_MenuItem_UnterUnterkategorie_ShadowVertical_Hover,
  DB_LeftSidebar_MenuItem_UnterUnterkategorie_ShadowBlur_Hover,
  DB_LeftSidebar_MenuItem_UnterUnterkategorie_ShadowColor_Hover,
  DB_LeftSidebar_MenuItem_UnterUnterkategorie_TextDecoration_Hover,
  DB_LeftSidebar_MenuItem_UnterUnterkategorie_TextDecorationStyle_Hover,

  showConfig_LeftSidebar_MenuMainKategorie_CC_BorderAndColor,
  DB_fontFace_hauptkategorieTitel_FontFamilyName,
  DB_fontFace_hauptkategorieTitel_FontFamilySrc,
  DB_LeftSidebar_MenuContainer_CC__BackgroundColor,

  DB_LeftSidebar_MenuContainer_CC__PaddingTopBottom,
  DB_LeftSidebar_MenuContainer_CC__PaddingLeftRight,
  DB_LeftSidebar_MenuContainer_CC__BorderTop,
  DB_LeftSidebar_MenuContainer_CC__BorderStyleTop,
  DB_LeftSidebar_MenuContainer_CC__BorderColorTop,
  DB_LeftSidebar_MenuContainer_CC__BorderBottom,
  DB_LeftSidebar_MenuContainer_CC__BorderStyleBottom,
  DB_LeftSidebar_MenuContainer_CC__BorderColorBottom,
  DB_LeftSidebar_MenuContainer_CC__BorderLeft,
  DB_LeftSidebar_MenuContainer_CC__BorderStyleLeft,
  DB_LeftSidebar_MenuContainer_CC__BorderColorLeft,
  DB_LeftSidebar_MenuContainer_CC__BorderRight,
  DB_LeftSidebar_MenuContainer_CC__BorderStyleRight,
  DB_LeftSidebar_MenuContainer_CC__BorderColorRight,
  DB_LeftSidebar_MenuItem_MenuContainer_CC__BorderTopRightRadius,
  DB_LeftSidebar_MenuItem_MenuContainer_CC__BorderTopLeftRadius,
  DB_LeftSidebar_MenuItem_MenuContainer_CC__BorderBottomRightRadius,
  DB_LeftSidebar_MenuItem_MenuContainer_CC__BorderBottomLeftRadius,
  DB_LeftSidebar_MenuItem_MenuContainer_CC__paddingLeft,
  DB_LeftSidebar_MenuItem_MenuContainer_CC__paddingRight,
  DB_LeftSidebar_MenuItem_MenuContainer_CC__paddingTop,
  DB_LeftSidebar_MenuItem_MenuContainer_CC__paddingBottom,
  DB_LeftSidebar_MenuItem_MenuContainer_CC__ShadowHorizontal,
  DB_LeftSidebar_MenuItem_MenuContainer_CC__ShadowVertical,
  DB_LeftSidebar_MenuItem_MenuContainer_CC__ShadowBlur,
  DB_LeftSidebar_MenuItem_MenuContainer_CC__ShadowColor,

  DB_LeftSidebar_MenuContainer_CC__Hover_BackgroundColor,
  DB_LeftSidebar_MenuContainer_CC__Hover_PaddingTopBottom,
  DB_LeftSidebar_MenuContainer_CC__Hover_PaddingLeftRight,
  DB_LeftSidebar_MenuContainer_CC__Hover_BorderTop,
  DB_LeftSidebar_MenuContainer_CC__Hover_BorderStyleTop,
  DB_LeftSidebar_MenuContainer_CC__Hover_BorderColorTop,
  DB_LeftSidebar_MenuContainer_CC__Hover_BorderBottom,
  DB_LeftSidebar_MenuContainer_CC__Hover_BorderStyleBottom,
  DB_LeftSidebar_MenuContainer_CC__Hover_BorderColorBottom,
  DB_LeftSidebar_MenuContainer_CC__Hover_BorderLeft,
  DB_LeftSidebar_MenuContainer_CC__Hover_BorderStyleLeft,
  DB_LeftSidebar_MenuContainer_CC__Hover_BorderColorLeft,
  DB_LeftSidebar_MenuContainer_CC__Hover_BorderRight,
  DB_LeftSidebar_MenuContainer_CC__Hover_BorderStyleRight,
  DB_LeftSidebar_MenuContainer_CC__Hover_BorderColorRight,
  DB_LeftSidebar_MenuItem_MenuContainer_CC__Hover_BorderTopRightRadius,
  DB_LeftSidebar_MenuItem_MenuContainer_CC__Hover_BorderTopLeftRadius,
  DB_LeftSidebar_MenuItem_MenuContainer_CC__Hover_BorderBottomRightRadius,
  DB_LeftSidebar_MenuItem_MenuContainer_CC__Hover_BorderBottomLeftRadius,
  DB_LeftSidebar_MenuItem_MenuContainer_CC__Hover_paddingLeft,
  DB_LeftSidebar_MenuItem_MenuContainer_CC__Hover_paddingRight,
  DB_LeftSidebar_MenuItem_MenuContainer_CC__Hover_paddingTop,
  DB_LeftSidebar_MenuItem_MenuContainer_CC__Hover_paddingBottom,
  DB_LeftSidebar_MenuItem_MenuContainer_CC__Hover_ShadowHorizontal,
  DB_LeftSidebar_MenuItem_MenuContainer_CC__Hover_ShadowVertical,
  DB_LeftSidebar_MenuItem_MenuContainer_CC__Hover_ShadowBlur,
  DB_LeftSidebar_MenuItem_MenuContainer_CC__Hover_ShadowColor,
}) => {
  let kategorieTemp;
  let subkategorieTemp;

  const useStyles = createUseStyles({
    leftSideBarStyle: {
      backgroundColor: `${
        SideBarLeft_Container_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut
          ? "rgba(255,0,0,0.5)"
          : SideBarLeft_Container_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut
          ? "rgba(255,0,0,0.3)"
          : DB_LeftSidebar_MenuContainer_CC__BackgroundColor
      }`,
      textAlign: "left",
      paddingTop: `${DB_LeftSidebar_MenuContainer_CC__PaddingTopBottom}px`,
      paddingBottom: `${DB_LeftSidebar_MenuContainer_CC__PaddingTopBottom}px`,
      paddingLeft: `${DB_LeftSidebar_MenuContainer_CC__PaddingLeftRight}px`,
      paddingRight: `${DB_LeftSidebar_MenuContainer_CC__PaddingLeftRight}px`,

      borderTop: `${
        SideBarLeft_Container_Farbe_Groesse_Stil_OnMouseOverAndOut
          ? "3"
          : SideBarLeft_Container_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
          ? "3"
          : DB_LeftSidebar_MenuContainer_CC__BorderTop
      }px
      ${
        SideBarLeft_Container_Farbe_Groesse_Stil_OnMouseOverAndOut
          ? "solid"
          : SideBarLeft_Container_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
          ? "dotted"
          : DB_LeftSidebar_MenuContainer_CC__BorderStyleTop
      }
      ${
        SideBarLeft_Container_Farbe_Groesse_Stil_OnMouseOverAndOut
          ? "red"
          : SideBarLeft_Container_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
          ? "red"
          : DB_LeftSidebar_MenuContainer_CC__BorderColorTop
      }`,
      borderBottom: `${
        SideBarLeft_Container_Farbe_Groesse_Stil_OnMouseOverAndOut
          ? "3"
          : SideBarLeft_Container_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
          ? "3"
          : DB_LeftSidebar_MenuContainer_CC__BorderBottom
      }px
      ${
        SideBarLeft_Container_Farbe_Groesse_Stil_OnMouseOverAndOut
          ? "solid"
          : SideBarLeft_Container_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
          ? "dotted"
          : DB_LeftSidebar_MenuContainer_CC__BorderStyleBottom
      }
      ${
        SideBarLeft_Container_Farbe_Groesse_Stil_OnMouseOverAndOut
          ? "red"
          : SideBarLeft_Container_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
          ? "red"
          : DB_LeftSidebar_MenuContainer_CC__BorderColorBottom
      }`,
      borderLeft: `${
        SideBarLeft_Container_Farbe_Groesse_Stil_OnMouseOverAndOut
          ? "3"
          : SideBarLeft_Container_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
          ? "3"
          : DB_LeftSidebar_MenuContainer_CC__BorderLeft
      }px
      ${
        SideBarLeft_Container_Farbe_Groesse_Stil_OnMouseOverAndOut
          ? "solid"
          : SideBarLeft_Container_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
          ? "dotted"
          : DB_LeftSidebar_MenuContainer_CC__BorderStyleLeft
      }
      ${
        SideBarLeft_Container_Farbe_Groesse_Stil_OnMouseOverAndOut
          ? "red"
          : SideBarLeft_Container_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
          ? "red"
          : DB_LeftSidebar_MenuContainer_CC__BorderColorLeft
      }`,
      borderRight: `${
        SideBarLeft_Container_Farbe_Groesse_Stil_OnMouseOverAndOut
          ? "3"
          : SideBarLeft_Container_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
          ? "3"
          : DB_LeftSidebar_MenuContainer_CC__BorderRight
      }px
      ${
        SideBarLeft_Container_Farbe_Groesse_Stil_OnMouseOverAndOut
          ? "solid"
          : SideBarLeft_Container_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
          ? "dotted"
          : DB_LeftSidebar_MenuContainer_CC__BorderStyleRight
      }
      ${
        SideBarLeft_Container_Farbe_Groesse_Stil_OnMouseOverAndOut
          ? "red"
          : SideBarLeft_Container_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
          ? "red"
          : DB_LeftSidebar_MenuContainer_CC__BorderColorRight
      }`,
      borderTopRightRadius: `${DB_LeftSidebar_MenuItem_MenuContainer_CC__BorderTopRightRadius}px`,
      borderTopLeftRadius: `${DB_LeftSidebar_MenuItem_MenuContainer_CC__BorderTopLeftRadius}px`,
      borderBottomRightRadius: `${DB_LeftSidebar_MenuItem_MenuContainer_CC__BorderBottomRightRadius}px`,
      borderBottomLeftRadius: `${DB_LeftSidebar_MenuItem_MenuContainer_CC__BorderBottomLeftRadius}px`,

      paddingLeft: `${DB_LeftSidebar_MenuItem_MenuContainer_CC__paddingLeft}px`,
      paddingRight: `${DB_LeftSidebar_MenuItem_MenuContainer_CC__paddingRight}px`,
      paddingTop: `${DB_LeftSidebar_MenuItem_MenuContainer_CC__paddingTop}px`,
      paddingBottom: `${DB_LeftSidebar_MenuItem_MenuContainer_CC__paddingBottom}px`,
      boxShadow: `${DB_LeftSidebar_MenuItem_MenuContainer_CC__ShadowHorizontal}px
      ${DB_LeftSidebar_MenuItem_MenuContainer_CC__ShadowVertical}px
      ${DB_LeftSidebar_MenuItem_MenuContainer_CC__ShadowBlur}px
      ${DB_LeftSidebar_MenuItem_MenuContainer_CC__ShadowColor}`,

      "&:hover": {
        backgroundColor: `${DB_LeftSidebar_MenuContainer_CC__Hover_BackgroundColor}`,
        textAlign: "left",
        paddingTop: `${DB_LeftSidebar_MenuContainer_CC__Hover_PaddingTopBottom}px`,
        paddingBottom: `${DB_LeftSidebar_MenuContainer_CC__Hover_PaddingTopBottom}px`,
        paddingLeft: `${DB_LeftSidebar_MenuContainer_CC__Hover_PaddingLeftRight}px`,
        paddingRight: `${DB_LeftSidebar_MenuContainer_CC__Hover_PaddingLeftRight}px`,
        borderTop: `${DB_LeftSidebar_MenuContainer_CC__Hover_BorderTop}px
      ${DB_LeftSidebar_MenuContainer_CC__Hover_BorderStyleTop}
      ${DB_LeftSidebar_MenuContainer_CC__Hover_BorderColorTop}`,
        borderBottom: `${DB_LeftSidebar_MenuContainer_CC__Hover_BorderBottom}px
      ${DB_LeftSidebar_MenuContainer_CC__Hover_BorderStyleBottom}
      ${DB_LeftSidebar_MenuContainer_CC__Hover_BorderColorBottom}`,
        borderLeft: `${DB_LeftSidebar_MenuContainer_CC__Hover_BorderLeft}px
      ${DB_LeftSidebar_MenuContainer_CC__Hover_BorderStyleLeft}
      ${DB_LeftSidebar_MenuContainer_CC__Hover_BorderColorLeft}`,
        borderRight: `${DB_LeftSidebar_MenuContainer_CC__Hover_BorderRight}px
      ${DB_LeftSidebar_MenuContainer_CC__Hover_BorderStyleRight}
      ${DB_LeftSidebar_MenuContainer_CC__Hover_BorderColorRight}`,

        borderTopRightRadius: `${DB_LeftSidebar_MenuItem_MenuContainer_CC__Hover_BorderTopRightRadius}px`,
        borderTopLeftRadius: `${DB_LeftSidebar_MenuItem_MenuContainer_CC__Hover_BorderTopLeftRadius}px`,
        borderBottomRightRadius: `${DB_LeftSidebar_MenuItem_MenuContainer_CC__Hover_BorderBottomRightRadius}px`,
        borderBottomLeftRadius: `${DB_LeftSidebar_MenuItem_MenuContainer_CC__Hover_BorderBottomLeftRadius}px`,
        paddingLeft: `${DB_LeftSidebar_MenuItem_MenuContainer_CC__Hover_paddingLeft}px`,
        paddingRight: `${DB_LeftSidebar_MenuItem_MenuContainer_CC__Hover_paddingRight}px`,
        paddingTop: `${DB_LeftSidebar_MenuItem_MenuContainer_CC__Hover_paddingTop}px`,
        paddingBottom: `${DB_LeftSidebar_MenuItem_MenuContainer_CC__Hover_paddingBottom}px`,
        boxShadow: `${DB_LeftSidebar_MenuItem_MenuContainer_CC__Hover_ShadowHorizontal}px
      ${DB_LeftSidebar_MenuItem_MenuContainer_CC__Hover_ShadowVertical}px
      ${DB_LeftSidebar_MenuItem_MenuContainer_CC__Hover_ShadowBlur}px
      ${DB_LeftSidebar_MenuItem_MenuContainer_CC__Hover_ShadowColor}`,
      },
    },
  });

  const classes = useStyles();

  return (
    <div
      data-bs-spy="scroll"
      data-bs-target="#navbar-example3"
      className={`position-relatve ${classes.leftSideBarStyle}`}
      data-bs-offset="0"
      tabIndex="0"
      style={{ marginBottom: "16px" }}
    >
      <ul className="list-unstyled fw-bolder" id="flexCheckDefault">
        <div className="category">
          <div>
            <SideBarLeftAlleProdukteTitel
              resetFilterHandler={resetFilterHandler}
              DB_fontFace_FontFamilyName={DB_fontFace_FontFamilyName}
              userOwnsThisSite={userOwnsThisSite}
              SideBarLeft_AlleProdukte_Farbe_Groesse_Stil_OnMouseOverAndOut={
                SideBarLeft_AlleProdukte_Farbe_Groesse_Stil_OnMouseOverAndOut
              }
              SideBarLeft_AlleProdukte_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut={
                SideBarLeft_AlleProdukte_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
              }
              SideBarLeft_AlleProdukte_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut={
                SideBarLeft_AlleProdukte_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut
              }
              SideBarLeft_AlleProdukte_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut={
                SideBarLeft_AlleProdukte_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut
              }
              DB_fontFace_alleProdukteAuswahlBB_FontFamilyName={
                DB_fontFace_alleProdukteAuswahlBB_FontFamilyName
              }
              DB_fontFace_alleProdukteAuswahlBB_FontFamilySrc={
                DB_fontFace_alleProdukteAuswahlBB_FontFamilySrc
              }
              DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_FontWeight={
                DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_FontWeight
              }
              DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_FontColor={
                DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_FontColor
              }
              DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_FontSize={
                DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_FontSize
              }
              DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_BackgroundColor={
                DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_BackgroundColor
              }
              DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_BorderTop={
                DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_BorderTop
              }
              DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_BorderStyleTop={
                DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_BorderStyleTop
              }
              DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_BorderColorTop={
                DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_BorderColorTop
              }
              DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_BorderBottom={
                DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_BorderBottom
              }
              DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_BorderStyleBottom={
                DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_BorderStyleBottom
              }
              DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_BorderColorBottom={
                DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_BorderColorBottom
              }
              DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_BorderLeft={
                DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_BorderLeft
              }
              DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_BorderStyleLeft={
                DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_BorderStyleLeft
              }
              DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_BorderColorLeft={
                DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_BorderColorLeft
              }
              DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_BorderRight={
                DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_BorderRight
              }
              DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_BorderStyleRight={
                DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_BorderStyleRight
              }
              DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_BorderColorRight={
                DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_BorderColorRight
              }
              DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_BorderTopRightRadius={
                DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_BorderTopRightRadius
              }
              DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_BorderTopLeftRadius={
                DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_BorderTopLeftRadius
              }
              DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_BorderBottomRightRadius={
                DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_BorderBottomRightRadius
              }
              DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_BorderBottomLeftRadius={
                DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_BorderBottomLeftRadius
              }
              DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_marginLeftRight={
                DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_marginLeftRight
              }
              DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_marginTopBottom={
                DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_marginTopBottom
              }
              DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_paddingLeftRight={
                DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_paddingLeftRight
              }
              DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_paddingTopBottom={
                DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_paddingTopBottom
              }
              DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_letterSpacing={
                DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_letterSpacing
              }
              DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_ShadowHorizontal={
                DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_ShadowHorizontal
              }
              DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_ShadowVertical={
                DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_ShadowVertical
              }
              DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_ShadowBlur={
                DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_ShadowBlur
              }
              DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_ShadowColor={
                DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_ShadowColor
              }
              DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_TextDecoration={
                DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_TextDecoration
              }
              DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_TextDecorationStyle={
                DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_TextDecorationStyle
              }
              DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_TextAlign={
                DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_TextAlign
              }
              DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_FontWeight={
                DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_FontWeight
              }
              DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_FontColor={
                DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_FontColor
              }
              DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_FontSize={
                DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_FontSize
              }
              DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_BackgroundColor={
                DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_BackgroundColor
              }
              DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_TextAlign={
                DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_TextAlign
              }
              DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_BorderTop={
                DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_BorderTop
              }
              DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_BorderStyleTop={
                DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_BorderStyleTop
              }
              DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_BorderColorTop={
                DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_BorderColorTop
              }
              DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_BorderBottom={
                DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_BorderBottom
              }
              DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_BorderStyleBottom={
                DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_BorderStyleBottom
              }
              DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_BorderColorBottom={
                DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_BorderColorBottom
              }
              DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_BorderLeft={
                DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_BorderLeft
              }
              DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_BorderStyleLeft={
                DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_BorderStyleLeft
              }
              DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_BorderColorLeft={
                DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_BorderColorLeft
              }
              DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_BorderRight={
                DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_BorderRight
              }
              DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_BorderStyleRight={
                DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_BorderStyleRight
              }
              DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_BorderColorRight={
                DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_BorderColorRight
              }
              DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_BorderTopRightRadius={
                DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_BorderTopRightRadius
              }
              DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_BorderTopLeftRadius={
                DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_BorderTopLeftRadius
              }
              DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_BorderBottomRightRadius={
                DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_BorderBottomRightRadius
              }
              DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_BorderBottomLeftRadius={
                DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_BorderBottomLeftRadius
              }
              DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_marginLeftRight_Hover={
                DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_marginLeftRight_Hover
              }
              DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_marginTopBottom_Hover={
                DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_marginTopBottom_Hover
              }
              DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_paddingLeftRight_Hover={
                DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_paddingLeftRight_Hover
              }
              DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_paddingTopBottom_Hover={
                DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_paddingTopBottom_Hover
              }
              DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_letterSpacing_Hover={
                DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_letterSpacing_Hover
              }
              DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_ShadowHorizontal_Hover={
                DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_ShadowHorizontal_Hover
              }
              DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_ShadowVertical_Hover={
                DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_ShadowVertical_Hover
              }
              DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_ShadowBlur_Hover={
                DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_ShadowBlur_Hover
              }
              DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_ShadowColor_Hover={
                DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_ShadowColor_Hover
              }
              DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_TextDecoration_Hover={
                DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_TextDecoration_Hover
              }
              DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_TextDecorationStyle_Hover={
                DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_TextDecorationStyle_Hover
              }
            />

            {ultraCategoriesNew &&
              ultraCategoriesNew.map((category, index) => {
                if (category.kategorie && !category.subkategorie) {
                  kategorieTemp = category.kategorie;
                  return (
                    !undefined && (
                      <SideBarLeftMainKategorie
                        key={category.kategorie}
                        mainkey={category + "_" + index}
                        category={category}
                        DB_fontFace_hauptkategorieTitel_FontFamilyName={
                          DB_fontFace_hauptkategorieTitel_FontFamilyName
                        }
                        categoryLeftSideHandler={categoryLeftSideHandler}
                        showConfig_LeftSidebar_MenuMainKategorie_CC_BorderAndColor={
                          showConfig_LeftSidebar_MenuMainKategorie_CC_BorderAndColor
                        }
                        DB_LeftSidebar_MenuItem_Hauptkategorie_FontFace={
                          DB_LeftSidebar_MenuItem_Hauptkategorie_FontFace
                        }
                        DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_FontFace={
                          DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_FontFace
                        }
                        DB_LeftSidebar_MenuItem_Hauptkategorie_FontWeight={
                          DB_LeftSidebar_MenuItem_Hauptkategorie_FontWeight
                        }
                        DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_FontWeight={
                          DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_FontWeight
                        }
                        DB_LeftSidebar_MenuItem_Hauptkategorie_FontColor={
                          DB_LeftSidebar_MenuItem_Hauptkategorie_FontColor
                        }
                        DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_FontColor={
                          DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_FontColor
                        }
                        DB_LeftSidebar_MenuItem_Hauptkategorie_FontSize={
                          DB_LeftSidebar_MenuItem_Hauptkategorie_FontSize
                        }
                        DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_FontSize={
                          DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_FontSize
                        }
                        SideBarLeft_Hauptkategorie_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut={
                          SideBarLeft_Hauptkategorie_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut
                        }
                        SideBarLeft_Hauptkategorie_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut={
                          SideBarLeft_Hauptkategorie_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut
                        }
                        DB_LeftSidebar_MenuItem_Hauptkategorie_BackgroundColor={
                          DB_LeftSidebar_MenuItem_Hauptkategorie_BackgroundColor
                        }
                        DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_BackgroundColor={
                          DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_BackgroundColor
                        }
                        DB_LeftSidebar_MenuItem_Hauptkategorie_TextAlign={
                          DB_LeftSidebar_MenuItem_Hauptkategorie_TextAlign
                        }
                        DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_TextAlign={
                          DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_TextAlign
                        }
                        SideBarLeft_Hauptkategorie_Farbe_Groesse_Stil_OnMouseOverAndOut={
                          SideBarLeft_Hauptkategorie_Farbe_Groesse_Stil_OnMouseOverAndOut
                        }
                        SideBarLeft_Hauptkategorie_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut={
                          SideBarLeft_Hauptkategorie_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
                        }
                        DB_LeftSidebar_MenuItem_Hauptkategorie_BorderTop={
                          DB_LeftSidebar_MenuItem_Hauptkategorie_BorderTop
                        }
                        DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_BorderTop={
                          DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_BorderTop
                        }
                        DB_LeftSidebar_MenuItem_Hauptkategorie_BorderStyleTop={
                          DB_LeftSidebar_MenuItem_Hauptkategorie_BorderStyleTop
                        }
                        DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_BorderStyleTop={
                          DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_BorderStyleTop
                        }
                        DB_LeftSidebar_MenuItem_Hauptkategorie_BorderColorTop={
                          DB_LeftSidebar_MenuItem_Hauptkategorie_BorderColorTop
                        }
                        DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_BorderColorTop={
                          DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_BorderColorTop
                        }
                        DB_LeftSidebar_MenuItem_Hauptkategorie_BorderBottom={
                          DB_LeftSidebar_MenuItem_Hauptkategorie_BorderBottom
                        }
                        DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_BorderBottom={
                          DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_BorderBottom
                        }
                        DB_LeftSidebar_MenuItem_Hauptkategorie_BorderStyleBottom={
                          DB_LeftSidebar_MenuItem_Hauptkategorie_BorderStyleBottom
                        }
                        DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_BorderStyleBottom={
                          DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_BorderStyleBottom
                        }
                        DB_LeftSidebar_MenuItem_Hauptkategorie_BorderColorBottom={
                          DB_LeftSidebar_MenuItem_Hauptkategorie_BorderColorBottom
                        }
                        DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_BorderColorBottom={
                          DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_BorderColorBottom
                        }
                        DB_LeftSidebar_MenuItem_Hauptkategorie_BorderLeft={
                          DB_LeftSidebar_MenuItem_Hauptkategorie_BorderLeft
                        }
                        DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_BorderLeft={
                          DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_BorderLeft
                        }
                        DB_LeftSidebar_MenuItem_Hauptkategorie_BorderStyleLeft={
                          DB_LeftSidebar_MenuItem_Hauptkategorie_BorderStyleLeft
                        }
                        DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_BorderStyleLeft={
                          DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_BorderStyleLeft
                        }
                        DB_LeftSidebar_MenuItem_Hauptkategorie_BorderColorLeft={
                          DB_LeftSidebar_MenuItem_Hauptkategorie_BorderColorLeft
                        }
                        DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_BorderColorLeft={
                          DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_BorderColorLeft
                        }
                        DB_LeftSidebar_MenuItem_Hauptkategorie_BorderRight={
                          DB_LeftSidebar_MenuItem_Hauptkategorie_BorderRight
                        }
                        DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_BorderRight={
                          DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_BorderRight
                        }
                        DB_LeftSidebar_MenuItem_Hauptkategorie_BorderStyleRight={
                          DB_LeftSidebar_MenuItem_Hauptkategorie_BorderStyleRight
                        }
                        DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_BorderStyleRight={
                          DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_BorderStyleRight
                        }
                        DB_LeftSidebar_MenuItem_Hauptkategorie_BorderColorRight={
                          DB_LeftSidebar_MenuItem_Hauptkategorie_BorderColorRight
                        }
                        DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_BorderColorRight={
                          DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_BorderColorRight
                        }
                        DB_LeftSidebar_MenuItem_Hauptkategorie_BorderTopRightRadius={
                          DB_LeftSidebar_MenuItem_Hauptkategorie_BorderTopRightRadius
                        }
                        DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_BorderTopRightRadius={
                          DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_BorderTopRightRadius
                        }
                        DB_LeftSidebar_MenuItem_Hauptkategorie_BorderTopLeftRadius={
                          DB_LeftSidebar_MenuItem_Hauptkategorie_BorderTopLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_BorderTopLeftRadius={
                          DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_BorderTopLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_Hauptkategorie_BorderBottomRightRadius={
                          DB_LeftSidebar_MenuItem_Hauptkategorie_BorderBottomRightRadius
                        }
                        DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_BorderBottomRightRadius={
                          DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_BorderBottomRightRadius
                        }
                        DB_LeftSidebar_MenuItem_Hauptkategorie_BorderBottomLeftRadius={
                          DB_LeftSidebar_MenuItem_Hauptkategorie_BorderBottomLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_BorderBottomLeftRadius={
                          DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_BorderBottomLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_Hauptkategorie_marginTopBottom={
                          DB_LeftSidebar_MenuItem_Hauptkategorie_marginTopBottom
                        }
                        DB_LeftSidebar_MenuItem_Hauptkategorie_marginLeftRight={
                          DB_LeftSidebar_MenuItem_Hauptkategorie_marginLeftRight
                        }
                        DB_LeftSidebar_MenuItem_Hauptkategorie_paddingLeftRight={
                          DB_LeftSidebar_MenuItem_Hauptkategorie_paddingLeftRight
                        }
                        DB_LeftSidebar_MenuItem_Hauptkategorie_paddingTopBottom={
                          DB_LeftSidebar_MenuItem_Hauptkategorie_paddingTopBottom
                        }
                        DB_LeftSidebar_MenuItem_Hauptkategorie_letterSpacing={
                          DB_LeftSidebar_MenuItem_Hauptkategorie_letterSpacing
                        }
                        DB_LeftSidebar_MenuItem_Hauptkategorie_ShadowHorizontal={
                          DB_LeftSidebar_MenuItem_Hauptkategorie_ShadowHorizontal
                        }
                        DB_LeftSidebar_MenuItem_Hauptkategorie_ShadowVertical={
                          DB_LeftSidebar_MenuItem_Hauptkategorie_ShadowVertical
                        }
                        DB_LeftSidebar_MenuItem_Hauptkategorie_ShadowBlur={
                          DB_LeftSidebar_MenuItem_Hauptkategorie_ShadowBlur
                        }
                        DB_LeftSidebar_MenuItem_Hauptkategorie_ShadowColor={
                          DB_LeftSidebar_MenuItem_Hauptkategorie_ShadowColor
                        }
                        DB_LeftSidebar_MenuItem_Hauptkategorie_TextDecoration={
                          DB_LeftSidebar_MenuItem_Hauptkategorie_TextDecoration
                        }
                        DB_LeftSidebar_MenuItem_Hauptkategorie_TextDecorationStyle={
                          DB_LeftSidebar_MenuItem_Hauptkategorie_TextDecorationStyle
                        }
                        DB_LeftSidebar_MenuItem_Hauptkategorie_marginTopBottom_Hover={
                          DB_LeftSidebar_MenuItem_Hauptkategorie_marginTopBottom_Hover
                        }
                        DB_LeftSidebar_MenuItem_Hauptkategorie_marginLeftRight_Hover={
                          DB_LeftSidebar_MenuItem_Hauptkategorie_marginLeftRight_Hover
                        }
                        DB_LeftSidebar_MenuItem_Hauptkategorie_paddingLeftRight_Hover={
                          DB_LeftSidebar_MenuItem_Hauptkategorie_paddingLeftRight_Hover
                        }
                        DB_LeftSidebar_MenuItem_Hauptkategorie_paddingTopBottom_Hover={
                          DB_LeftSidebar_MenuItem_Hauptkategorie_paddingTopBottom_Hover
                        }
                        DB_LeftSidebar_MenuItem_Hauptkategorie_letterSpacing_Hover={
                          DB_LeftSidebar_MenuItem_Hauptkategorie_letterSpacing_Hover
                        }
                        DB_LeftSidebar_MenuItem_Hauptkategorie_ShadowHorizontal_Hover={
                          DB_LeftSidebar_MenuItem_Hauptkategorie_ShadowHorizontal_Hover
                        }
                        DB_LeftSidebar_MenuItem_Hauptkategorie_ShadowVertical_Hover={
                          DB_LeftSidebar_MenuItem_Hauptkategorie_ShadowVertical_Hover
                        }
                        DB_LeftSidebar_MenuItem_Hauptkategorie_ShadowBlur_Hover={
                          DB_LeftSidebar_MenuItem_Hauptkategorie_ShadowBlur_Hover
                        }
                        DB_LeftSidebar_MenuItem_Hauptkategorie_ShadowColor_Hover={
                          DB_LeftSidebar_MenuItem_Hauptkategorie_ShadowColor_Hover
                        }
                        DB_LeftSidebar_MenuItem_Hauptkategorie_TextDecoration_Hover={
                          DB_LeftSidebar_MenuItem_Hauptkategorie_TextDecoration_Hover
                        }
                        DB_LeftSidebar_MenuItem_Hauptkategorie_TextDecorationStyle_Hover={
                          DB_LeftSidebar_MenuItem_Hauptkategorie_TextDecorationStyle_Hover
                        }
                      />
                    )
                  );
                }
                if (
                  category.subkategorie &&
                  (!category.kategorie || !category.subsubkategorie)
                ) {
                  subkategorieTemp = category.subkategorie;
                  return (
                    !undefined && (
                      <SideBarLeftSubKategorie
                        key={category.kategorie + "-" + category.subkategorie}
                        category={category}
                        DB_fontFace_unterkategorieTitel_FontFamilyName={
                          DB_fontFace_unterkategorieTitel_FontFamilyName
                        }
                        DB_fontFace_unterkategorieTitel_FontFamilySrc={
                          DB_fontFace_unterkategorieTitel_FontFamilySrc
                        }
                        categoryLeftSideHandler={categoryLeftSideHandler}
                        DB_LeftSidebar_MenuItem_Unterkategorie_FontWeight={
                          DB_LeftSidebar_MenuItem_Unterkategorie_FontWeight
                        }
                        DB_LeftSidebar_MenuItem_Unterkategorie_Hover_FontWeight={
                          DB_LeftSidebar_MenuItem_Unterkategorie_Hover_FontWeight
                        }
                        DB_LeftSidebar_MenuItem_Unterkategorie_FontColor={
                          DB_LeftSidebar_MenuItem_Unterkategorie_FontColor
                        }
                        DB_LeftSidebar_MenuItem_Unterkategorie_Hover_FontColor={
                          DB_LeftSidebar_MenuItem_Unterkategorie_Hover_FontColor
                        }
                        DB_LeftSidebar_MenuItem_Unterkategorie_FontSize={
                          DB_LeftSidebar_MenuItem_Unterkategorie_FontSize
                        }
                        DB_LeftSidebar_MenuItem_Unterkategorie_Hover_FontSize={
                          DB_LeftSidebar_MenuItem_Unterkategorie_Hover_FontSize
                        }
                        DB_LeftSidebar_MenuItem_Unterkategorie_BackgroundColor={
                          DB_LeftSidebar_MenuItem_Unterkategorie_BackgroundColor
                        }
                        DB_LeftSidebar_MenuItem_Unterkategorie_Hover_BackgroundColor={
                          DB_LeftSidebar_MenuItem_Unterkategorie_Hover_BackgroundColor
                        }
                        DB_LeftSidebar_MenuItem_Unterkategorie_TextAlign={
                          DB_LeftSidebar_MenuItem_Unterkategorie_TextAlign
                        }
                        DB_LeftSidebar_MenuItem_Unterkategorie_Hover_TextAlign={
                          DB_LeftSidebar_MenuItem_Unterkategorie_Hover_TextAlign
                        }
                        DB_LeftSidebar_MenuItem_Unterkategorie_BorderTop={
                          DB_LeftSidebar_MenuItem_Unterkategorie_BorderTop
                        }
                        DB_LeftSidebar_MenuItem_Unterkategorie_Hover_BorderTop={
                          DB_LeftSidebar_MenuItem_Unterkategorie_Hover_BorderTop
                        }
                        DB_LeftSidebar_MenuItem_Unterkategorie_BorderStyleTop={
                          DB_LeftSidebar_MenuItem_Unterkategorie_BorderStyleTop
                        }
                        DB_LeftSidebar_MenuItem_Unterkategorie_Hover_BorderStyleTop={
                          DB_LeftSidebar_MenuItem_Unterkategorie_Hover_BorderStyleTop
                        }
                        DB_LeftSidebar_MenuItem_Unterkategorie_BorderColorTop={
                          DB_LeftSidebar_MenuItem_Unterkategorie_BorderColorTop
                        }
                        DB_LeftSidebar_MenuItem_Unterkategorie_Hover_BorderColorTop={
                          DB_LeftSidebar_MenuItem_Unterkategorie_Hover_BorderColorTop
                        }
                        DB_LeftSidebar_MenuItem_Unterkategorie_BorderBottom={
                          DB_LeftSidebar_MenuItem_Unterkategorie_BorderBottom
                        }
                        DB_LeftSidebar_MenuItem_Unterkategorie_Hover_BorderBottom={
                          DB_LeftSidebar_MenuItem_Unterkategorie_Hover_BorderBottom
                        }
                        DB_LeftSidebar_MenuItem_Unterkategorie_BorderStyleBottom={
                          DB_LeftSidebar_MenuItem_Unterkategorie_BorderStyleBottom
                        }
                        DB_LeftSidebar_MenuItem_Unterkategorie_Hover_BorderStyleBottom={
                          DB_LeftSidebar_MenuItem_Unterkategorie_Hover_BorderStyleBottom
                        }
                        DB_LeftSidebar_MenuItem_Unterkategorie_BorderColorBottom={
                          DB_LeftSidebar_MenuItem_Unterkategorie_BorderColorBottom
                        }
                        DB_LeftSidebar_MenuItem_Unterkategorie_Hover_BorderColorBottom={
                          DB_LeftSidebar_MenuItem_Unterkategorie_Hover_BorderColorBottom
                        }
                        DB_LeftSidebar_MenuItem_Unterkategorie_BorderLeft={
                          DB_LeftSidebar_MenuItem_Unterkategorie_BorderLeft
                        }
                        DB_LeftSidebar_MenuItem_Unterkategorie_Hover_BorderLeft={
                          DB_LeftSidebar_MenuItem_Unterkategorie_Hover_BorderLeft
                        }
                        DB_LeftSidebar_MenuItem_Unterkategorie_BorderStyleLeft={
                          DB_LeftSidebar_MenuItem_Unterkategorie_BorderStyleLeft
                        }
                        DB_LeftSidebar_MenuItem_Unterkategorie_Hover_BorderStyleLeft={
                          DB_LeftSidebar_MenuItem_Unterkategorie_Hover_BorderStyleLeft
                        }
                        DB_LeftSidebar_MenuItem_Unterkategorie_BorderColorLeft={
                          DB_LeftSidebar_MenuItem_Unterkategorie_BorderColorLeft
                        }
                        DB_LeftSidebar_MenuItem_Unterkategorie_Hover_BorderColorLeft={
                          DB_LeftSidebar_MenuItem_Unterkategorie_Hover_BorderColorLeft
                        }
                        DB_LeftSidebar_MenuItem_Unterkategorie_BorderRight={
                          DB_LeftSidebar_MenuItem_Unterkategorie_BorderRight
                        }
                        DB_LeftSidebar_MenuItem_Unterkategorie_Hover_BorderRight={
                          DB_LeftSidebar_MenuItem_Unterkategorie_Hover_BorderRight
                        }
                        DB_LeftSidebar_MenuItem_Unterkategorie_BorderStyleRight={
                          DB_LeftSidebar_MenuItem_Unterkategorie_BorderStyleRight
                        }
                        DB_LeftSidebar_MenuItem_Unterkategorie_Hover_BorderStyleRight={
                          DB_LeftSidebar_MenuItem_Unterkategorie_Hover_BorderStyleRight
                        }
                        DB_LeftSidebar_MenuItem_Unterkategorie_BorderColorRight={
                          DB_LeftSidebar_MenuItem_Unterkategorie_BorderColorRight
                        }
                        DB_LeftSidebar_MenuItem_Unterkategorie_Hover_BorderColorRight={
                          DB_LeftSidebar_MenuItem_Unterkategorie_Hover_BorderColorRight
                        }
                        DB_LeftSidebar_MenuItem_Unterkategorie_BorderTopRightRadius={
                          DB_LeftSidebar_MenuItem_Unterkategorie_BorderTopRightRadius
                        }
                        DB_LeftSidebar_MenuItem_Unterkategorie_Hover_BorderTopRightRadius={
                          DB_LeftSidebar_MenuItem_Unterkategorie_Hover_BorderTopRightRadius
                        }
                        DB_LeftSidebar_MenuItem_Unterkategorie_BorderTopLeftRadius={
                          DB_LeftSidebar_MenuItem_Unterkategorie_BorderTopLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_Unterkategorie_Hover_BorderTopLeftRadius={
                          DB_LeftSidebar_MenuItem_Unterkategorie_Hover_BorderTopLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_Unterkategorie_BorderBottomRightRadius={
                          DB_LeftSidebar_MenuItem_Unterkategorie_BorderBottomRightRadius
                        }
                        DB_LeftSidebar_MenuItem_Unterkategorie_Hover_BorderBottomRightRadius={
                          DB_LeftSidebar_MenuItem_Unterkategorie_Hover_BorderBottomRightRadius
                        }
                        DB_LeftSidebar_MenuItem_Unterkategorie_BorderBottomLeftRadius={
                          DB_LeftSidebar_MenuItem_Unterkategorie_BorderBottomLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_Unterkategorie_Hover_BorderBottomLeftRadius={
                          DB_LeftSidebar_MenuItem_Unterkategorie_Hover_BorderBottomLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_Unterkategorie_marginTopBottom={
                          DB_LeftSidebar_MenuItem_Unterkategorie_marginTopBottom
                        }
                        DB_LeftSidebar_MenuItem_Unterkategorie_marginLeftRight={
                          DB_LeftSidebar_MenuItem_Unterkategorie_marginLeftRight
                        }
                        DB_LeftSidebar_MenuItem_Unterkategorie_paddingLeftRight={
                          DB_LeftSidebar_MenuItem_Unterkategorie_paddingLeftRight
                        }
                        DB_LeftSidebar_MenuItem_Unterkategorie_paddingTopBottom={
                          DB_LeftSidebar_MenuItem_Unterkategorie_paddingTopBottom
                        }
                        DB_LeftSidebar_MenuItem_Unterkategorie_letterSpacing={
                          DB_LeftSidebar_MenuItem_Unterkategorie_letterSpacing
                        }
                        DB_LeftSidebar_MenuItem_Unterkategorie_ShadowHorizontal={
                          DB_LeftSidebar_MenuItem_Unterkategorie_ShadowHorizontal
                        }
                        DB_LeftSidebar_MenuItem_Unterkategorie_ShadowVertical={
                          DB_LeftSidebar_MenuItem_Unterkategorie_ShadowVertical
                        }
                        DB_LeftSidebar_MenuItem_Unterkategorie_ShadowBlur={
                          DB_LeftSidebar_MenuItem_Unterkategorie_ShadowBlur
                        }
                        DB_LeftSidebar_MenuItem_Unterkategorie_ShadowColor={
                          DB_LeftSidebar_MenuItem_Unterkategorie_ShadowColor
                        }
                        DB_LeftSidebar_MenuItem_Unterkategorie_TextDecoration={
                          DB_LeftSidebar_MenuItem_Unterkategorie_TextDecoration
                        }
                        DB_LeftSidebar_MenuItem_Unterkategorie_TextDecorationStyle={
                          DB_LeftSidebar_MenuItem_Unterkategorie_TextDecorationStyle
                        }
                        DB_LeftSidebar_MenuItem_Unterkategorie_marginTopBottom_Hover={
                          DB_LeftSidebar_MenuItem_Unterkategorie_marginTopBottom_Hover
                        }
                        DB_LeftSidebar_MenuItem_Unterkategorie_marginLeftRight_Hover={
                          DB_LeftSidebar_MenuItem_Unterkategorie_marginLeftRight_Hover
                        }
                        DB_LeftSidebar_MenuItem_Unterkategorie_paddingLeftRight_Hover={
                          DB_LeftSidebar_MenuItem_Unterkategorie_paddingLeftRight_Hover
                        }
                        DB_LeftSidebar_MenuItem_Unterkategorie_paddingTopBottom_Hover={
                          DB_LeftSidebar_MenuItem_Unterkategorie_paddingTopBottom_Hover
                        }
                        DB_LeftSidebar_MenuItem_Unterkategorie_letterSpacing_Hover={
                          DB_LeftSidebar_MenuItem_Unterkategorie_letterSpacing_Hover
                        }
                        DB_LeftSidebar_MenuItem_Unterkategorie_ShadowHorizontal_Hover={
                          DB_LeftSidebar_MenuItem_Unterkategorie_ShadowHorizontal_Hover
                        }
                        DB_LeftSidebar_MenuItem_Unterkategorie_ShadowVertical_Hover={
                          DB_LeftSidebar_MenuItem_Unterkategorie_ShadowVertical_Hover
                        }
                        DB_LeftSidebar_MenuItem_Unterkategorie_ShadowBlur_Hover={
                          DB_LeftSidebar_MenuItem_Unterkategorie_ShadowBlur_Hover
                        }
                        DB_LeftSidebar_MenuItem_Unterkategorie_ShadowColor_Hover={
                          DB_LeftSidebar_MenuItem_Unterkategorie_ShadowColor_Hover
                        }
                        DB_LeftSidebar_MenuItem_Unterkategorie_TextDecoration_Hover={
                          DB_LeftSidebar_MenuItem_Unterkategorie_TextDecoration_Hover
                        }
                        DB_LeftSidebar_MenuItem_Unterkategorie_TextDecorationStyle_Hover={
                          DB_LeftSidebar_MenuItem_Unterkategorie_TextDecorationStyle_Hover
                        }
                        SideBarLeft_Unterkategorie_Farbe_Groesse_Stil_OnMouseOverAndOut={
                          SideBarLeft_Unterkategorie_Farbe_Groesse_Stil_OnMouseOverAndOut
                        }
                        SideBarLeft_Unterkategorie_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut={
                          SideBarLeft_Unterkategorie_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
                        }
                        SideBarLeft_Unterkategorie_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut={
                          SideBarLeft_Unterkategorie_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut
                        }
                        SideBarLeft_Unterkategorie_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut={
                          SideBarLeft_Unterkategorie_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut
                        }
                      />
                    )
                  );
                }
                if (
                  category.subsubkategorie &&
                  category.subkategorie &&
                  category.kategorie
                ) {
                  return (
                    !undefined && (
                      <SideBarLeftSubSubKategorie
                        key={
                          category.kategorie +
                          "-" +
                          category.subkategorie +
                          "-" +
                          category.subsubkategorie
                        }
                        category={category}
                        DB_fontFace_unterunterkategorieTitel_FontFamilyName={
                          DB_fontFace_unterunterkategorieTitel_FontFamilyName
                        }
                        DB_fontFace_unterunterkategorieTitel_FontFamilySrc={
                          DB_fontFace_unterunterkategorieTitel_FontFamilySrc
                        }
                        categoryLeftSideHandler={categoryLeftSideHandler}
                        DB_LeftSidebar_MenuItem_UnterUnterkategorie_FontFace={
                          DB_LeftSidebar_MenuItem_UnterUnterkategorie_FontFace
                        }
                        DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_FontFace={
                          DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_FontFace
                        }
                        DB_LeftSidebar_MenuItem_UnterUnterkategorie_FontWeight={
                          DB_LeftSidebar_MenuItem_UnterUnterkategorie_FontWeight
                        }
                        DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_FontWeight={
                          DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_FontWeight
                        }
                        DB_LeftSidebar_MenuItem_UnterUnterkategorie_FontColor={
                          DB_LeftSidebar_MenuItem_UnterUnterkategorie_FontColor
                        }
                        DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_FontColor={
                          DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_FontColor
                        }
                        DB_LeftSidebar_MenuItem_UnterUnterkategorie_FontSize={
                          DB_LeftSidebar_MenuItem_UnterUnterkategorie_FontSize
                        }
                        DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_FontSize={
                          DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_FontSize
                        }
                        DB_LeftSidebar_MenuItem_UnterUnterkategorie_BackgroundColor={
                          DB_LeftSidebar_MenuItem_UnterUnterkategorie_BackgroundColor
                        }
                        DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_BackgroundColor={
                          DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_BackgroundColor
                        }
                        DB_LeftSidebar_MenuItem_UnterUnterkategorie_TextAlign={
                          DB_LeftSidebar_MenuItem_UnterUnterkategorie_TextAlign
                        }
                        DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_TextAlign={
                          DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_TextAlign
                        }
                        DB_LeftSidebar_MenuItem_UnterUnterkategorie_BorderTop={
                          DB_LeftSidebar_MenuItem_UnterUnterkategorie_BorderTop
                        }
                        DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_BorderTop={
                          DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_BorderTop
                        }
                        DB_LeftSidebar_MenuItem_UnterUnterkategorie_BorderStyleTop={
                          DB_LeftSidebar_MenuItem_UnterUnterkategorie_BorderStyleTop
                        }
                        DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_BorderStyleTop={
                          DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_BorderStyleTop
                        }
                        DB_LeftSidebar_MenuItem_UnterUnterkategorie_BorderColorTop={
                          DB_LeftSidebar_MenuItem_UnterUnterkategorie_BorderColorTop
                        }
                        DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_BorderColorTop={
                          DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_BorderColorTop
                        }
                        DB_LeftSidebar_MenuItem_UnterUnterkategorie_BorderBottom={
                          DB_LeftSidebar_MenuItem_UnterUnterkategorie_BorderBottom
                        }
                        DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_BorderBottom={
                          DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_BorderBottom
                        }
                        DB_LeftSidebar_MenuItem_UnterUnterkategorie_BorderStyleBottom={
                          DB_LeftSidebar_MenuItem_UnterUnterkategorie_BorderStyleBottom
                        }
                        DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_BorderStyleBottom={
                          DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_BorderStyleBottom
                        }
                        DB_LeftSidebar_MenuItem_UnterUnterkategorie_BorderColorBottom={
                          DB_LeftSidebar_MenuItem_UnterUnterkategorie_BorderColorBottom
                        }
                        DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_BorderColorBottom={
                          DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_BorderColorBottom
                        }
                        DB_LeftSidebar_MenuItem_UnterUnterkategorie_BorderLeft={
                          DB_LeftSidebar_MenuItem_UnterUnterkategorie_BorderLeft
                        }
                        DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_BorderLeft={
                          DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_BorderLeft
                        }
                        DB_LeftSidebar_MenuItem_UnterUnterkategorie_BorderStyleLeft={
                          DB_LeftSidebar_MenuItem_UnterUnterkategorie_BorderStyleLeft
                        }
                        DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_BorderStyleLeft={
                          DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_BorderStyleLeft
                        }
                        DB_LeftSidebar_MenuItem_UnterUnterkategorie_BorderColorLeft={
                          DB_LeftSidebar_MenuItem_UnterUnterkategorie_BorderColorLeft
                        }
                        DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_BorderColorLeft={
                          DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_BorderColorLeft
                        }
                        DB_LeftSidebar_MenuItem_UnterUnterkategorie_BorderRight={
                          DB_LeftSidebar_MenuItem_UnterUnterkategorie_BorderRight
                        }
                        DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_BorderRight={
                          DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_BorderRight
                        }
                        DB_LeftSidebar_MenuItem_UnterUnterkategorie_BorderStyleRight={
                          DB_LeftSidebar_MenuItem_UnterUnterkategorie_BorderStyleRight
                        }
                        DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_BorderStyleRight={
                          DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_BorderStyleRight
                        }
                        DB_LeftSidebar_MenuItem_UnterUnterkategorie_BorderColorRight={
                          DB_LeftSidebar_MenuItem_UnterUnterkategorie_BorderColorRight
                        }
                        DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_BorderColorRight={
                          DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_BorderColorRight
                        }
                        DB_LeftSidebar_MenuItem_UnterUnterkategorie_BorderTopRightRadius={
                          DB_LeftSidebar_MenuItem_UnterUnterkategorie_BorderTopRightRadius
                        }
                        DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_BorderTopRightRadius={
                          DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_BorderTopRightRadius
                        }
                        DB_LeftSidebar_MenuItem_UnterUnterkategorie_BorderTopLeftRadius={
                          DB_LeftSidebar_MenuItem_UnterUnterkategorie_BorderTopLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_BorderTopLeftRadius={
                          DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_BorderTopLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_UnterUnterkategorie_BorderBottomRightRadius={
                          DB_LeftSidebar_MenuItem_UnterUnterkategorie_BorderBottomRightRadius
                        }
                        DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_BorderBottomRightRadius={
                          DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_BorderBottomRightRadius
                        }
                        DB_LeftSidebar_MenuItem_UnterUnterkategorie_BorderBottomLeftRadius={
                          DB_LeftSidebar_MenuItem_UnterUnterkategorie_BorderBottomLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_BorderBottomLeftRadius={
                          DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_BorderBottomLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_UnterUnterkategorie_marginTopBottom={
                          DB_LeftSidebar_MenuItem_UnterUnterkategorie_marginTopBottom
                        }
                        DB_LeftSidebar_MenuItem_UnterUnterkategorie_marginLeftRight={
                          DB_LeftSidebar_MenuItem_UnterUnterkategorie_marginLeftRight
                        }
                        DB_LeftSidebar_MenuItem_UnterUnterkategorie_paddingLeftRight={
                          DB_LeftSidebar_MenuItem_UnterUnterkategorie_paddingLeftRight
                        }
                        DB_LeftSidebar_MenuItem_UnterUnterkategorie_paddingTopBottom={
                          DB_LeftSidebar_MenuItem_UnterUnterkategorie_paddingTopBottom
                        }
                        DB_LeftSidebar_MenuItem_UnterUnterkategorie_letterSpacing={
                          DB_LeftSidebar_MenuItem_UnterUnterkategorie_letterSpacing
                        }
                        DB_LeftSidebar_MenuItem_UnterUnterkategorie_ShadowHorizontal={
                          DB_LeftSidebar_MenuItem_UnterUnterkategorie_ShadowHorizontal
                        }
                        DB_LeftSidebar_MenuItem_UnterUnterkategorie_ShadowVertical={
                          DB_LeftSidebar_MenuItem_UnterUnterkategorie_ShadowVertical
                        }
                        DB_LeftSidebar_MenuItem_UnterUnterkategorie_ShadowBlur={
                          DB_LeftSidebar_MenuItem_UnterUnterkategorie_ShadowBlur
                        }
                        DB_LeftSidebar_MenuItem_UnterUnterkategorie_ShadowColor={
                          DB_LeftSidebar_MenuItem_UnterUnterkategorie_ShadowColor
                        }
                        DB_LeftSidebar_MenuItem_UnterUnterkategorie_TextDecoration={
                          DB_LeftSidebar_MenuItem_UnterUnterkategorie_TextDecoration
                        }
                        DB_LeftSidebar_MenuItem_UnterUnterkategorie_TextDecorationStyle={
                          DB_LeftSidebar_MenuItem_UnterUnterkategorie_TextDecorationStyle
                        }
                        DB_LeftSidebar_MenuItem_UnterUnterkategorie_marginTopBottom_Hover={
                          DB_LeftSidebar_MenuItem_UnterUnterkategorie_marginTopBottom_Hover
                        }
                        DB_LeftSidebar_MenuItem_UnterUnterkategorie_marginLeftRight_Hover={
                          DB_LeftSidebar_MenuItem_UnterUnterkategorie_marginLeftRight_Hover
                        }
                        DB_LeftSidebar_MenuItem_UnterUnterkategorie_paddingLeftRight_Hover={
                          DB_LeftSidebar_MenuItem_UnterUnterkategorie_paddingLeftRight_Hover
                        }
                        DB_LeftSidebar_MenuItem_UnterUnterkategorie_paddingTopBottom_Hover={
                          DB_LeftSidebar_MenuItem_UnterUnterkategorie_paddingTopBottom_Hover
                        }
                        DB_LeftSidebar_MenuItem_UnterUnterkategorie_letterSpacing_Hover={
                          DB_LeftSidebar_MenuItem_UnterUnterkategorie_letterSpacing_Hover
                        }
                        DB_LeftSidebar_MenuItem_UnterUnterkategorie_ShadowHorizontal_Hover={
                          DB_LeftSidebar_MenuItem_UnterUnterkategorie_ShadowHorizontal_Hover
                        }
                        DB_LeftSidebar_MenuItem_UnterUnterkategorie_ShadowVertical_Hover={
                          DB_LeftSidebar_MenuItem_UnterUnterkategorie_ShadowVertical_Hover
                        }
                        DB_LeftSidebar_MenuItem_UnterUnterkategorie_ShadowBlur_Hover={
                          DB_LeftSidebar_MenuItem_UnterUnterkategorie_ShadowBlur_Hover
                        }
                        DB_LeftSidebar_MenuItem_UnterUnterkategorie_ShadowColor_Hover={
                          DB_LeftSidebar_MenuItem_UnterUnterkategorie_ShadowColor_Hover
                        }
                        DB_LeftSidebar_MenuItem_UnterUnterkategorie_TextDecoration_Hover={
                          DB_LeftSidebar_MenuItem_UnterUnterkategorie_TextDecoration_Hover
                        }
                        DB_LeftSidebar_MenuItem_UnterUnterkategorie_TextDecorationStyle_Hover={
                          DB_LeftSidebar_MenuItem_UnterUnterkategorie_TextDecorationStyle_Hover
                        }
                        SideBarLeft_UnterUnterkategorie_Farbe_Groesse_Stil_OnMouseOverAndOut={
                          SideBarLeft_UnterUnterkategorie_Farbe_Groesse_Stil_OnMouseOverAndOut
                        }
                        SideBarLeft_UnterUnterkategorie_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut={
                          SideBarLeft_UnterUnterkategorie_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
                        }
                        SideBarLeft_UnterUnterkategorie_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut={
                          SideBarLeft_UnterUnterkategorie_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut
                        }
                        SideBarLeft_UnterUnterkategorie_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut={
                          SideBarLeft_UnterUnterkategorie_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut
                        }
                      />
                    )
                  );
                }
              })}
          </div>
        </div>
      </ul>
    </div>
  );
};

export default SideBarLeftKategories;
