import { useState, useEffect } from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { toast, Zoom } from "react-toastify";

const Footer = ({
  shopName,
  countImagePlusTwo,
  toggleSignIn,
  logoActive,
  isAuthState,
  signOutMessage,
  aboutUsShowHandler,
  faqShowHandler,
  faqDetailItem,
  meldenModalHandler,
}) => {
  const { t } = useTranslation();
  const [DB_TopBarLogoImageSrc, setDB_TopBarLogoImageSrc] = useState("");
  const [todaysYear] = useState(new Date().getFullYear());

  useEffect(() => {
    axios({
      method: "post",
      url: `${process.env.REACT_APP_PUBLIC_SERVER_URL}/shop/get-design-logo-image`,
      data: {
        shopName,
      },
    })
      .then((uploadedResult) => {
        if (uploadedResult.status === 201) {
        } else {
          setDB_TopBarLogoImageSrc(
            `${process.env.REACT_APP_PUBLIC_SERVER_URL}/` +
              uploadedResult.data.logo_image +
              "?" +
              new Date().getMilliseconds()
          );
        }
      })
      .catch((err) => {
        toast.error(
          "Keine Verbindung zum Server - Logo konnte nicht vom Server heruntergeladen werden",
          {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            transition: Zoom,
          }
        );
      });
  }, [countImagePlusTwo]);

  return (
    <div
      className="container-fluid"
      style={{
        backgroundColor: "#000000aa",
        paddingTop: "3px",
        paddingBottom: "3px",
      }}
    >
      <div className="container p-0">
        <footer className="d-flex flex-wrap justify-content-between align-items-center">
          <span
            className="col-md-5 mb-0 footer justify-content-end align-items-end"
            style={{ cursor: "default", textDecoration: "none" }}
          >
            &copy; {todaysYear}
            {/*    {aboutUsDetailItem.details.firma && (
              <span> {aboutUsDetailItem.details.firma}</span>
            )}
            {aboutUsDetailItem.details.vorname &&
            aboutUsDetailItem.details.nachname ? (
              <span>
                {" "}
                {aboutUsDetailItem.details.vorname}{" "}
                {aboutUsDetailItem.details.nachname}
              </span>
            ) : (
              <span>{aboutUsDetailItem.privateName}</span>
            )} */}
          </span>

          <span>
            <a
              href="#"
              className="col-md-2 d-flex align-items-center justify-content-center mb-3 mb-md-0 me-md-auto link-dark text-decoration-none"
            >
              {DB_TopBarLogoImageSrc && logoActive ? (
                <img
                  src={DB_TopBarLogoImageSrc}
                  alt="SuperMegaShop.com Logo"
                  style={{ height: "40px" }}
                />
              ) : (
                ""
              )}
            </a>
          </span>
          <ul className="nav col-md-5 justify-content-start">
            <li
              className="nav-item"
              style={{ cursor: "pointer" }}
              onClick={() => aboutUsShowHandler()}
            >
              <span href="#" className="footer px-2">
                {t("UeberUns")}
              </span>
            </li>

            {faqDetailItem && (
              <li
                className="nav-item"
                onClick={() => faqShowHandler()}
                style={{ cursor: "pointer" }}
              >
                <span href="#" className="footer px-2">
                  {t("FAQ")}
                </span>
              </li>
            )}
            <li
              className="nav-item"
              style={{ cursor: "pointer" }}
              onClick={(truefalse) => meldenModalHandler(true)}
            >
              <span href="#" className="footer px-2">
                {t("ShopMelden")}
              </span>
            </li>
            {/*   <li className="nav-item">
              <span href="#" className="footer px-2">
                AGB's
              </span>
            </li>
             <li className="nav-item">
              <span href="#" className="footer px-2">
                Zahlungsarten
              </span>
            </li>
            <li className="nav-item">
              <span href="#" className="footer px-2">
                Sponsoring
              </span>
            </li>
            <li className="nav-item">
              <span href="#" className="footer px-2">
                Prospekt
              </span>
            </li> */}
            {isAuthState ? (
              <li className="nav-item">
                <span
                  className="footer ps-2"
                  style={{ cursor: "pointer" }}
                  onClick={signOutMessage}
                >
                  {t("Abmelden")}
                </span>
              </li>
            ) : (
              <li className="nav-item">
                <span
                  className="footer ps-2"
                  style={{ cursor: "pointer" }}
                  onClick={toggleSignIn}
                >
                  {t("Anmelden")}
                </span>
              </li>
            )}
          </ul>
        </footer>
      </div>
    </div>
  );
};

export default Footer;
