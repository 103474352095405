import { createUseStyles } from "react-jss";

const SideBarRightSocialIconsTitel = ({
  CustomSocialTitel,
  SideBarLeft_AlleSocial_Farbe_Groesse_Stil_OnMouseOverAndOut,
  SideBarLeft_AlleSocial_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut,
  SideBarLeft_AlleSocial_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut,
  SideBarLeft_AlleSocial_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut,

  DB_fontFace_SocialTitelFontFamily_FontFamilyName,
  DB_fontFace_SocialTitelFontFamily_FontFamilySrc,
  DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_FontWeight,
  DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_FontColor,
  DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_FontSize,
  DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BackgroundColor,
  DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_TextAlign,
  DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderTop,
  DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderStyleTop,
  DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderColorTop,
  DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderBottom,
  DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderStyleBottom,
  DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderColorBottom,
  DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderLeft,
  DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderStyleLeft,
  DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderColorLeft,
  DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderRight,
  DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderStyleRight,
  DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderColorRight,

  DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderTopRightRadius,
  DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderTopLeftRadius,
  DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderBottomRightRadius,
  DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderBottomLeftRadius,
  DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_marginLeftRight,
  DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_marginTopBottom,
  DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_paddingLeftRight,
  DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_paddingTopBottom,
  DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_letterSpacing,
  DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowHorizontal,
  DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowVertical,
  DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowBlur,
  DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowColor,
  DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_TextDecoration,
  DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_TextDecorationStyle,

  DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_FontWeight,
  DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_FontColor,
  DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_FontSize,
  DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BackgroundColor,
  DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_TextAlign,
  DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderTop,
  DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderStyleTop,
  DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderColorTop,
  DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderBottom,
  DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderStyleBottom,
  DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderColorBottom,
  DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderLeft,
  DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderStyleLeft,
  DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderColorLeft,
  DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderRight,
  DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderStyleRight,
  DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderColorRight,
  DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderTopRightRadius,
  DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderTopLeftRadius,
  DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderBottomRightRadius,
  DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderBottomLeftRadius,
  DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_marginLeftRight_Hover,
  DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_marginTopBottom_Hover,
  DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_paddingLeftRight_Hover,
  DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_paddingTopBottom_Hover,
  DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_letterSpacing_Hover,
  DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowHorizontal_Hover,
  DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowVertical_Hover,
  DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowBlur_Hover,
  DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowColor_Hover,
  DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_TextDecoration_Hover,
  DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_TextDecorationStyle_Hover,
}) => {
  const useStyles = createUseStyles({
    rightSideTitelUnserMottoNachrichtKaeufer: {
      fontFamily: `${DB_fontFace_SocialTitelFontFamily_FontFamilyName}`,
      fontWeight: `${DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_FontWeight}`,
      color: `${DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_FontColor}`,
      fontSize: `${DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_FontSize}px`,
      backgroundColor: `${
        SideBarLeft_AlleSocial_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut
          ? "rgba(255,0,0,0.5)"
          : SideBarLeft_AlleSocial_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut
          ? "rgba(255,0,0,0.3)"
          : DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BackgroundColor
      }`,
      textAlign: `${DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_TextAlign}`,
      borderTop: `${
        SideBarLeft_AlleSocial_Farbe_Groesse_Stil_OnMouseOverAndOut
          ? "3"
          : SideBarLeft_AlleSocial_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
          ? "3"
          : DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderTop
      }px
            ${
              SideBarLeft_AlleSocial_Farbe_Groesse_Stil_OnMouseOverAndOut
                ? "solid"
                : SideBarLeft_AlleSocial_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
                ? "dotted"
                : DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderStyleTop
            }
            ${
              SideBarLeft_AlleSocial_Farbe_Groesse_Stil_OnMouseOverAndOut
                ? "red"
                : SideBarLeft_AlleSocial_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
                ? "red"
                : DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderColorTop
            }`,
      borderBottom: `${
        SideBarLeft_AlleSocial_Farbe_Groesse_Stil_OnMouseOverAndOut
          ? "3"
          : SideBarLeft_AlleSocial_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
          ? "3"
          : DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderBottom
      }px
              ${
                SideBarLeft_AlleSocial_Farbe_Groesse_Stil_OnMouseOverAndOut
                  ? "solid"
                  : SideBarLeft_AlleSocial_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
                  ? "dotted"
                  : DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderStyleBottom
              }
              ${
                SideBarLeft_AlleSocial_Farbe_Groesse_Stil_OnMouseOverAndOut
                  ? "red"
                  : SideBarLeft_AlleSocial_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
                  ? "red"
                  : DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderColorBottom
              }`,
      borderLeft: `${
        SideBarLeft_AlleSocial_Farbe_Groesse_Stil_OnMouseOverAndOut
          ? "3"
          : SideBarLeft_AlleSocial_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
          ? "3"
          : DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderLeft
      }px
              ${
                SideBarLeft_AlleSocial_Farbe_Groesse_Stil_OnMouseOverAndOut
                  ? "solid"
                  : SideBarLeft_AlleSocial_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
                  ? "dotted"
                  : DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderStyleLeft
              }
              ${
                SideBarLeft_AlleSocial_Farbe_Groesse_Stil_OnMouseOverAndOut
                  ? "red"
                  : SideBarLeft_AlleSocial_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
                  ? "red"
                  : DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderColorLeft
              }`,
      borderRight: `${
        SideBarLeft_AlleSocial_Farbe_Groesse_Stil_OnMouseOverAndOut
          ? "3"
          : SideBarLeft_AlleSocial_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
          ? "3"
          : DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderRight
      }px
              ${
                SideBarLeft_AlleSocial_Farbe_Groesse_Stil_OnMouseOverAndOut
                  ? "solid"
                  : SideBarLeft_AlleSocial_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
                  ? "dotted"
                  : DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderStyleRight
              }
              ${
                SideBarLeft_AlleSocial_Farbe_Groesse_Stil_OnMouseOverAndOut
                  ? "red"
                  : SideBarLeft_AlleSocial_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
                  ? "red"
                  : DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderColorRight
              }`,
      borderTopRightRadius: `${DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderTopRightRadius}px`,
      borderTopLeftRadius: `${DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderTopLeftRadius}px`,
      borderBottomRightRadius: `${DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderBottomRightRadius}px`,
      borderBottomLeftRadius: `${DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderBottomLeftRadius}px`,
      marginLeft: `${DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_marginLeftRight}px`,
      marginRight: `${DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_marginLeftRight}px`,
      marginTop: `${DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_marginTopBottom}px`,
      marginBottom: `${DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_marginTopBottom}px`,
      paddingLeft: `${DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_paddingLeftRight}px`,
      paddingRight: `${DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_paddingLeftRight}px`,
      paddingTop: `${DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_paddingTopBottom}px`,
      paddingBottom: `${DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_paddingTopBottom}px`,
      letterSpacing: `${DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_letterSpacing}px`,
      textShadow: `${DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowHorizontal}px
            ${DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowVertical}px
            ${DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowBlur}px
            ${DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowColor}`,
      textDecoration: `${DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_TextDecoration}
            ${DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_TextDecorationStyle}`,

      "&:hover": {
        fontWeight: `${DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_FontWeight}`,
        color: `${DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_FontColor}`,
        fontSize: `${DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_FontSize}px`,
        backgroundColor: `${DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BackgroundColor}`,
        textAlign: `${DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_TextAlign}`,
        borderTop: `${DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderTop}px
            ${DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderStyleTop}
            ${DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderColorTop}`,
        borderBottom: `${DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderBottom}px
            ${DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderStyleBottom}
            ${DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderColorBottom}`,
        borderLeft: `${DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderLeft}px
            ${DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderStyleLeft}
            ${DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderColorLeft}`,
        borderRight: `${DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderRight}px
            ${DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderStyleRight}
            ${DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderColorRight}`,
        borderTopRightRadius: `${DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderTopRightRadius}px`,
        borderTopLeftRadius: `${DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderTopLeftRadius}px`,
        borderBottomRightRadius: `${DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderBottomRightRadius}px`,
        borderBottomLeftRadius: `${DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderBottomLeftRadius}px`,
        marginLeft: `${DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_marginLeftRight_Hover}px`,
        marginRight: `${DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_marginLeftRight_Hover}px`,
        marginTop: `${DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_marginTopBottom_Hover}px`,
        marginBottom: `${DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_marginTopBottom_Hover}px`,
        paddingLeft: `${DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_paddingLeftRight_Hover}px`,
        paddingRight: `${DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_paddingLeftRight_Hover}px`,
        paddingTop: `${DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_paddingTopBottom_Hover}px`,
        paddingBottom: `${DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_paddingTopBottom_Hover}px`,
        letterSpacing: `${DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_letterSpacing_Hover}px`,
        textShadow: `${DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowHorizontal_Hover}px
            ${DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowVertical_Hover}px
            ${DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowBlur_Hover}px
            ${DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowColor_Hover}`,
        textDecoration: `${DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_TextDecoration_Hover}
            ${DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_TextDecorationStyle_Hover}`,
      },
    },
  });

  const classes = useStyles();
  return (
    <div
      className={classes.rightSideTitelUnserMottoNachrichtKaeufer}
      style={{ marginBottom: "10px", marginTop: "5px" }}
    >
      {CustomSocialTitel}
    </div>
  );
};

export default SideBarRightSocialIconsTitel;
