import { createUseStyles } from "react-jss";

const BodyProductMainTitle = ({
  hideProductDetailsHandler,
  productDetailItemTitel,
  DB_fontFace_chooseFontFamilySuperTitel_FontFamilyName,
  DB_fontFace_chooseFontFamilySuperTitel_FontFamilySrc,
  DB_LeftSidebar_MenuItem_TitelSuperTitel_FontWeight,
  DB_LeftSidebar_MenuItem_TitelSuperTitel_FontColor,
  DB_LeftSidebar_MenuItem_TitelSuperTitel_FontSize,
  DB_LeftSidebar_MenuItem_TitelSuperTitel_BackgroundColor,
  DB_LeftSidebar_MenuItem_TitelSuperTitel_TextAlign,
  DB_LeftSidebar_MenuItem_TitelSuperTitel_BorderTop,
  DB_LeftSidebar_MenuItem_TitelSuperTitel_BorderStyleTop,
  DB_LeftSidebar_MenuItem_TitelSuperTitel_BorderColorTop,
  DB_LeftSidebar_MenuItem_TitelSuperTitel_BorderBottom,
  DB_LeftSidebar_MenuItem_TitelSuperTitel_BorderStyleBottom,
  DB_LeftSidebar_MenuItem_TitelSuperTitel_BorderColorBottom,
  DB_LeftSidebar_MenuItem_TitelSuperTitel_BorderLeft,
  DB_LeftSidebar_MenuItem_TitelSuperTitel_BorderStyleLeft,
  DB_LeftSidebar_MenuItem_TitelSuperTitel_BorderColorLeft,
  DB_LeftSidebar_MenuItem_TitelSuperTitel_BorderRight,
  DB_LeftSidebar_MenuItem_TitelSuperTitel_BorderStyleRight,
  DB_LeftSidebar_MenuItem_TitelSuperTitel_BorderColorRight,
  DB_LeftSidebar_MenuItem_TitelSuperTitel_BorderTopRightRadius,
  DB_LeftSidebar_MenuItem_TitelSuperTitel_BorderTopLeftRadius,
  DB_LeftSidebar_MenuItem_TitelSuperTitel_BorderBottomRightRadius,
  DB_LeftSidebar_MenuItem_TitelSuperTitel_BorderBottomLeftRadius,
  DB_LeftSidebar_MenuItem_TitelSuperTitel_marginLeftRight,
  DB_LeftSidebar_MenuItem_TitelSuperTitel_marginTopBottom,
  DB_LeftSidebar_MenuItem_TitelSuperTitel_paddingLeftRight,
  DB_LeftSidebar_MenuItem_TitelSuperTitel_paddingTopBottom,
  DB_LeftSidebar_MenuItem_TitelSuperTitel_letterSpacing,
  DB_LeftSidebar_MenuItem_TitelSuperTitel_ShadowHorizontal,
  DB_LeftSidebar_MenuItem_TitelSuperTitel_ShadowVertical,
  DB_LeftSidebar_MenuItem_TitelSuperTitel_ShadowBlur,
  DB_LeftSidebar_MenuItem_TitelSuperTitel_ShadowColor,
  DB_LeftSidebar_MenuItem_TitelSuperTitel_TextDecoration,
  DB_LeftSidebar_MenuItem_TitelSuperTitel_TextDecorationStyle,
  SideBarLeft_DetailSuper_Farbe_Groesse_Stil_OnMouseOverAndOut,
  SideBarLeft_DetailSuper_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut,
}) => {
  const useStyles = createUseStyles({
    productDetailItemHeaderSuperTitel: {
      fontFamily: `${DB_fontFace_chooseFontFamilySuperTitel_FontFamilyName}`,
      fontWeight: `${DB_LeftSidebar_MenuItem_TitelSuperTitel_FontWeight}`,
      color: `${DB_LeftSidebar_MenuItem_TitelSuperTitel_FontColor}`,
      fontSize: `${DB_LeftSidebar_MenuItem_TitelSuperTitel_FontSize}px`,
      backgroundColor: `${
        SideBarLeft_DetailSuper_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut
          ? "rgba(255,0,0,0.5)"
          : DB_LeftSidebar_MenuItem_TitelSuperTitel_BackgroundColor
      }`,
      textAlign: `${DB_LeftSidebar_MenuItem_TitelSuperTitel_TextAlign}`,
      borderTop: `${
        SideBarLeft_DetailSuper_Farbe_Groesse_Stil_OnMouseOverAndOut
          ? "3"
          : DB_LeftSidebar_MenuItem_TitelSuperTitel_BorderTop
      }px
        ${
          SideBarLeft_DetailSuper_Farbe_Groesse_Stil_OnMouseOverAndOut
            ? "solid"
            : DB_LeftSidebar_MenuItem_TitelSuperTitel_BorderStyleTop
        }
        ${
          SideBarLeft_DetailSuper_Farbe_Groesse_Stil_OnMouseOverAndOut
            ? "red"
            : DB_LeftSidebar_MenuItem_TitelSuperTitel_BorderColorTop
        }`,
      borderBottom: `${
        SideBarLeft_DetailSuper_Farbe_Groesse_Stil_OnMouseOverAndOut
          ? "3"
          : DB_LeftSidebar_MenuItem_TitelSuperTitel_BorderBottom
      }px
        ${
          SideBarLeft_DetailSuper_Farbe_Groesse_Stil_OnMouseOverAndOut
            ? "solid"
            : DB_LeftSidebar_MenuItem_TitelSuperTitel_BorderStyleBottom
        }
        ${
          SideBarLeft_DetailSuper_Farbe_Groesse_Stil_OnMouseOverAndOut
            ? "red"
            : DB_LeftSidebar_MenuItem_TitelSuperTitel_BorderColorBottom
        }`,
      borderLeft: `${
        SideBarLeft_DetailSuper_Farbe_Groesse_Stil_OnMouseOverAndOut
          ? "3"
          : DB_LeftSidebar_MenuItem_TitelSuperTitel_BorderLeft
      }px
        ${
          SideBarLeft_DetailSuper_Farbe_Groesse_Stil_OnMouseOverAndOut
            ? "solid"
            : DB_LeftSidebar_MenuItem_TitelSuperTitel_BorderStyleLeft
        }
        ${
          SideBarLeft_DetailSuper_Farbe_Groesse_Stil_OnMouseOverAndOut
            ? "red"
            : DB_LeftSidebar_MenuItem_TitelSuperTitel_BorderColorLeft
        }`,
      borderRight: `${
        SideBarLeft_DetailSuper_Farbe_Groesse_Stil_OnMouseOverAndOut
          ? "3"
          : DB_LeftSidebar_MenuItem_TitelSuperTitel_BorderRight
      }px
        ${
          SideBarLeft_DetailSuper_Farbe_Groesse_Stil_OnMouseOverAndOut
            ? "solid"
            : DB_LeftSidebar_MenuItem_TitelSuperTitel_BorderStyleRight
        }
        ${
          SideBarLeft_DetailSuper_Farbe_Groesse_Stil_OnMouseOverAndOut
            ? "red"
            : DB_LeftSidebar_MenuItem_TitelSuperTitel_BorderColorRight
        }`,
      borderTopRightRadius: `${DB_LeftSidebar_MenuItem_TitelSuperTitel_BorderTopRightRadius}px`,
      borderTopLeftRadius: `${DB_LeftSidebar_MenuItem_TitelSuperTitel_BorderTopLeftRadius}px`,
      borderBottomRightRadius: `${DB_LeftSidebar_MenuItem_TitelSuperTitel_BorderBottomRightRadius}px`,
      borderBottomLeftRadius: `${DB_LeftSidebar_MenuItem_TitelSuperTitel_BorderBottomLeftRadius}px`,
      marginLeft: `${DB_LeftSidebar_MenuItem_TitelSuperTitel_marginLeftRight}px`,
      marginRight: `${DB_LeftSidebar_MenuItem_TitelSuperTitel_marginLeftRight}px`,
      marginTop: `${DB_LeftSidebar_MenuItem_TitelSuperTitel_marginTopBottom}px`,
      marginBottom: `${DB_LeftSidebar_MenuItem_TitelSuperTitel_marginTopBottom}px`,
      paddingLeft: `${DB_LeftSidebar_MenuItem_TitelSuperTitel_paddingLeftRight}px`,
      paddingRight: `${DB_LeftSidebar_MenuItem_TitelSuperTitel_paddingLeftRight}px`,
      paddingTop: `${DB_LeftSidebar_MenuItem_TitelSuperTitel_paddingTopBottom}px`,
      paddingBottom: `${DB_LeftSidebar_MenuItem_TitelSuperTitel_paddingTopBottom}px`,
      letterSpacing: `${DB_LeftSidebar_MenuItem_TitelSuperTitel_letterSpacing}px`,
      textShadow: `${DB_LeftSidebar_MenuItem_TitelSuperTitel_ShadowHorizontal}px
        ${DB_LeftSidebar_MenuItem_TitelSuperTitel_ShadowVertical}px
        ${DB_LeftSidebar_MenuItem_TitelSuperTitel_ShadowBlur}px
        ${DB_LeftSidebar_MenuItem_TitelSuperTitel_ShadowColor}`,
      textDecoration: `${DB_LeftSidebar_MenuItem_TitelSuperTitel_TextDecoration}
        ${DB_LeftSidebar_MenuItem_TitelSuperTitel_TextDecorationStyle}`,
      cursor: "pointer",
    },
  });

  const classes = useStyles();
  return (
    <div
      className={classes.productDetailItemHeaderSuperTitel}
      onClick={hideProductDetailsHandler}
    >
      {productDetailItemTitel}
    </div>
  );
};

export default BodyProductMainTitle;
