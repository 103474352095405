import { createUseStyles } from "react-jss";

const SideBarRightMainFilter = ({
  filterBezeichnung,
  DB_fontFace_FilterHauptTitel_FontFamilyName,
  DB_fontFace_FilterHauptTitel_FontFamilySrc,

  SideBarLeft_HauptFiltertitel_Farbe_Groesse_Stil_OnMouseOverAndOut,
  SideBarLeft_HauptFiltertitel_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut,
  SideBarLeft_HauptFiltertitel_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut,
  SideBarLeft_HauptFiltertitel_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut,

  DB_LeftSidebar_MenuItem_FilterHauptTitel_FontWeight,
  DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_FontWeight,
  DB_LeftSidebar_MenuItem_FilterHauptTitel_FontColor,
  DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_FontColor,
  DB_LeftSidebar_MenuItem_FilterHauptTitel_FontSize,
  DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_FontSize,

  DB_LeftSidebar_MenuItem_FilterHauptTitel_BackgroundColor,
  DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BackgroundColor,
  DB_LeftSidebar_MenuItem_FilterHauptTitel_TextAlign,
  DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_TextAlign,

  DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderTop,
  DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderTop,
  DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderStyleTop,
  DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderColorTop,
  DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderBottom,
  DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderStyleBottom,
  DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderColorBottom,
  DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderLeft,
  DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderStyleLeft,
  DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderColorLeft,
  DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderRight,
  DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderStyleRight,
  DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderColorRight,

  DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderTopRightRadius,
  DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderTopLeftRadius,
  DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderBottomRightRadius,
  DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderBottomLeftRadius,

  DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderTopRightRadius,
  DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderTopLeftRadius,
  DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderBottomRightRadius,
  DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderBottomLeftRadius,

  DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderStyleTop,
  DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderColorTop,
  DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderBottom,
  DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderStyleBottom,

  DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderColorBottom,
  DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderLeft,
  DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderStyleLeft,
  DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderColorLeft,

  DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderRight,
  DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderStyleRight,
  DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderColorRight,

  DB_LeftSidebar_MenuItem_FilterHauptTitel_marginTopBottom,
  DB_LeftSidebar_MenuItem_FilterHauptTitel_marginLeftRight,
  DB_LeftSidebar_MenuItem_FilterHauptTitel_paddingLeftRight,
  DB_LeftSidebar_MenuItem_FilterHauptTitel_paddingTopBottom,

  DB_LeftSidebar_MenuItem_FilterHauptTitel_letterSpacing,
  DB_LeftSidebar_MenuItem_FilterHauptTitel_ShadowHorizontal,
  DB_LeftSidebar_MenuItem_FilterHauptTitel_ShadowVertical,
  DB_LeftSidebar_MenuItem_FilterHauptTitel_ShadowBlur,
  DB_LeftSidebar_MenuItem_FilterHauptTitel_ShadowColor,
  DB_LeftSidebar_MenuItem_FilterHauptTitel_TextDecoration,
  DB_LeftSidebar_MenuItem_FilterHauptTitel_TextDecorationStyle,

  DB_LeftSidebar_MenuItem_FilterHauptTitel_marginTopBottom_Hover,
  DB_LeftSidebar_MenuItem_FilterHauptTitel_marginLeftRight_Hover,
  DB_LeftSidebar_MenuItem_FilterHauptTitel_paddingLeftRight_Hover,
  DB_LeftSidebar_MenuItem_FilterHauptTitel_paddingTopBottom_Hover,

  DB_LeftSidebar_MenuItem_FilterHauptTitel_letterSpacing_Hover,
  DB_LeftSidebar_MenuItem_FilterHauptTitel_ShadowHorizontal_Hover,
  DB_LeftSidebar_MenuItem_FilterHauptTitel_ShadowVertical_Hover,
  DB_LeftSidebar_MenuItem_FilterHauptTitel_ShadowBlur_Hover,
  DB_LeftSidebar_MenuItem_FilterHauptTitel_ShadowColor_Hover,
  DB_LeftSidebar_MenuItem_FilterHauptTitel_TextDecoration_Hover,
  DB_LeftSidebar_MenuItem_FilterHauptTitel_TextDecorationStyle_Hover,
}) => {
  const useStyles = createUseStyles({
    menuItem_filterTitel: {
      fontFamily: `${DB_fontFace_FilterHauptTitel_FontFamilyName}`,
      fontWeight: `${DB_LeftSidebar_MenuItem_FilterHauptTitel_FontWeight}`,
      color: `${DB_LeftSidebar_MenuItem_FilterHauptTitel_FontColor}`,
      fontSize: `${DB_LeftSidebar_MenuItem_FilterHauptTitel_FontSize}px`,
      backgroundColor: `${
        SideBarLeft_HauptFiltertitel_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut
          ? "rgba(255,0,0,0.5)"
          : SideBarLeft_HauptFiltertitel_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut
          ? "rgba(255,0,0,0.3)"
          : DB_LeftSidebar_MenuItem_FilterHauptTitel_BackgroundColor
      }`,
      textAlign: `${DB_LeftSidebar_MenuItem_FilterHauptTitel_TextAlign}`,
      borderTop: `${
        SideBarLeft_HauptFiltertitel_Farbe_Groesse_Stil_OnMouseOverAndOut
          ? "3"
          : SideBarLeft_HauptFiltertitel_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
          ? "3"
          : DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderTop
      }px
        ${
          SideBarLeft_HauptFiltertitel_Farbe_Groesse_Stil_OnMouseOverAndOut
            ? "solid"
            : SideBarLeft_HauptFiltertitel_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
            ? "dotted"
            : DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderStyleTop
        }
        ${
          SideBarLeft_HauptFiltertitel_Farbe_Groesse_Stil_OnMouseOverAndOut
            ? "red"
            : SideBarLeft_HauptFiltertitel_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
            ? "red"
            : DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderColorTop
        }`,
      borderBottom: `${
        SideBarLeft_HauptFiltertitel_Farbe_Groesse_Stil_OnMouseOverAndOut
          ? "3"
          : SideBarLeft_HauptFiltertitel_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
          ? "3"
          : DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderBottom
      }px
        ${
          SideBarLeft_HauptFiltertitel_Farbe_Groesse_Stil_OnMouseOverAndOut
            ? "solid"
            : SideBarLeft_HauptFiltertitel_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
            ? "dotted"
            : DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderStyleBottom
        }
        ${
          SideBarLeft_HauptFiltertitel_Farbe_Groesse_Stil_OnMouseOverAndOut
            ? "red"
            : SideBarLeft_HauptFiltertitel_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
            ? "red"
            : DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderColorBottom
        }`,
      borderLeft: `${
        SideBarLeft_HauptFiltertitel_Farbe_Groesse_Stil_OnMouseOverAndOut
          ? "3"
          : SideBarLeft_HauptFiltertitel_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
          ? "3"
          : DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderLeft
      }px
        ${
          SideBarLeft_HauptFiltertitel_Farbe_Groesse_Stil_OnMouseOverAndOut
            ? "solid"
            : SideBarLeft_HauptFiltertitel_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
            ? "dotted"
            : DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderStyleLeft
        }
        ${
          SideBarLeft_HauptFiltertitel_Farbe_Groesse_Stil_OnMouseOverAndOut
            ? "red"
            : SideBarLeft_HauptFiltertitel_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
            ? "red"
            : DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderColorLeft
        }`,
      borderRight: `${
        SideBarLeft_HauptFiltertitel_Farbe_Groesse_Stil_OnMouseOverAndOut
          ? "3"
          : SideBarLeft_HauptFiltertitel_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
          ? "3"
          : DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderRight
      }px
        ${
          SideBarLeft_HauptFiltertitel_Farbe_Groesse_Stil_OnMouseOverAndOut
            ? "solid"
            : SideBarLeft_HauptFiltertitel_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
            ? "dotted"
            : DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderStyleRight
        }
        ${
          SideBarLeft_HauptFiltertitel_Farbe_Groesse_Stil_OnMouseOverAndOut
            ? "red"
            : SideBarLeft_HauptFiltertitel_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
            ? "red"
            : DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderColorRight
        }`,
      borderTopRightRadius: `${DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderTopRightRadius}px`,
      borderTopLeftRadius: `${DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderTopLeftRadius}px`,
      borderBottomRightRadius: `${DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderBottomRightRadius}px`,
      borderBottomLeftRadius: `${DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderBottomLeftRadius}px`,
      marginLeft: `${DB_LeftSidebar_MenuItem_FilterHauptTitel_marginLeftRight}px`,
      marginRight: `${DB_LeftSidebar_MenuItem_FilterHauptTitel_marginLeftRight}px`,
      marginTop: `${DB_LeftSidebar_MenuItem_FilterHauptTitel_marginTopBottom}px`,
      marginBottom: `${DB_LeftSidebar_MenuItem_FilterHauptTitel_marginTopBottom}px`,
      paddingLeft: `${DB_LeftSidebar_MenuItem_FilterHauptTitel_paddingLeftRight}px`,
      paddingRight: `${DB_LeftSidebar_MenuItem_FilterHauptTitel_paddingLeftRight}px`,
      paddingTop: `${DB_LeftSidebar_MenuItem_FilterHauptTitel_paddingTopBottom}px`,
      paddingBottom: `${DB_LeftSidebar_MenuItem_FilterHauptTitel_paddingTopBottom}px`,
      letterSpacing: `${DB_LeftSidebar_MenuItem_FilterHauptTitel_letterSpacing}px`,
      textShadow: `${DB_LeftSidebar_MenuItem_FilterHauptTitel_ShadowHorizontal}px
        ${DB_LeftSidebar_MenuItem_FilterHauptTitel_ShadowVertical}px
        ${DB_LeftSidebar_MenuItem_FilterHauptTitel_ShadowBlur}px
        ${DB_LeftSidebar_MenuItem_FilterHauptTitel_ShadowColor}`,
      textDecoration: `${DB_LeftSidebar_MenuItem_FilterHauptTitel_TextDecoration}
        ${DB_LeftSidebar_MenuItem_FilterHauptTitel_TextDecorationStyle}`,

      "&:hover": {
        fontWeight: `${DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_FontWeight}`,
        color: `${DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_FontColor}`,
        fontSize: `${DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_FontSize}px`,
        backgroundColor: `${DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BackgroundColor}`,
        textAlign: `${DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_TextAlign}`,
        borderTop: `${DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderTop}px
        ${DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderStyleTop}
        ${DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderColorTop}`,
        borderBottom: `${DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderBottom}px
        ${DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderStyleBottom}
        ${DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderColorBottom}`,
        borderLeft: `${DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderLeft}px
        ${DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderStyleLeft}
        ${DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderColorLeft}`,
        borderRight: `${DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderRight}px
        ${DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderStyleRight}
        ${DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderColorRight}`,
        borderTopRightRadius: `${DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderTopRightRadius}px`,
        borderTopLeftRadius: `${DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderTopLeftRadius}px`,
        borderBottomRightRadius: `${DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderBottomRightRadius}px`,
        borderBottomLeftRadius: `${DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderBottomLeftRadius}px`,

        marginLeft: `${DB_LeftSidebar_MenuItem_FilterHauptTitel_marginLeftRight_Hover}px`,
        marginRight: `${DB_LeftSidebar_MenuItem_FilterHauptTitel_marginLeftRight_Hover}px`,
        marginTop: `${DB_LeftSidebar_MenuItem_FilterHauptTitel_marginTopBottom_Hover}px`,
        marginBottom: `${DB_LeftSidebar_MenuItem_FilterHauptTitel_marginTopBottom_Hover}px`,
        paddingLeft: `${DB_LeftSidebar_MenuItem_FilterHauptTitel_paddingLeftRight_Hover}px`,
        paddingRight: `${DB_LeftSidebar_MenuItem_FilterHauptTitel_paddingLeftRight_Hover}px`,
        paddingTop: `${DB_LeftSidebar_MenuItem_FilterHauptTitel_paddingTopBottom_Hover}px`,
        paddingBottom: `${DB_LeftSidebar_MenuItem_FilterHauptTitel_paddingTopBottom_Hover}px`,
        letterSpacing: `${DB_LeftSidebar_MenuItem_FilterHauptTitel_letterSpacing_Hover}px`,
        textShadow: `${DB_LeftSidebar_MenuItem_FilterHauptTitel_ShadowHorizontal_Hover}px
        ${DB_LeftSidebar_MenuItem_FilterHauptTitel_ShadowVertical_Hover}px
        ${DB_LeftSidebar_MenuItem_FilterHauptTitel_ShadowBlur_Hover}px
        ${DB_LeftSidebar_MenuItem_FilterHauptTitel_ShadowColor_Hover}`,
        textDecoration: `${DB_LeftSidebar_MenuItem_FilterHauptTitel_TextDecoration_Hover}
        ${DB_LeftSidebar_MenuItem_FilterHauptTitel_TextDecorationStyle_Hover}`,
      },
    },
  });

  const classes = useStyles();

  return (
    <div className={classes.menuItem_filterTitel}>{filterBezeichnung}</div>
  );
};
export default SideBarRightMainFilter;
