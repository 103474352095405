import React, { useEffect, useState } from "react";
import axios from "axios";
import { Form } from "react-bootstrap";
import { toast, Zoom } from "react-toastify";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import "/node_modules/react-image-gallery/styles/css/image-gallery.css";
import TopBarStaticContainer from "./TopBarStaticContainer";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";

const TopBarStaticBottom = ({
  faqDetailItem,
  aboutUsShowHandler,
  faqShowHandler,
  TopBarIsVisibleTop,
  TopBarIsVisibleBottom,
  logoActive,
  countImagePlusTwo,
  shopName,
  searchValue,
  searchHandler,
  searchPressEnter,
  isAuthState,
  signOutMessage,
  toggleSignIn,
  productsUserId,
  showTopProductsHandler,
  /* handleShow_showConfig_Main, */
  userOwnsThisSite,
  DB_fontFace_FontFamilyName,
  resetFilterHandler,
  SideBarLeft_HauptmenuTopStatic_Farbe_Groesse_Stil_Trigger,
  SideBarLeft_HauptmenuTopStatic_Farbe_Groesse_Stil_Hover_Trigger,
  SideBarLeft_HauptmenuTopStatic_HintergrundFarbe_Radius_Stil_Trigger,
  SideBarLeft_HauptmenuTopStatic_HintergrundFarbe_Radius_Stil_Hover_Trigger,
  SideBarLeft_HauptmenuTopStatic_Farbe_Groesse_Stil_OnMouseOverAndOut,
  SideBarLeft_HauptmenuTopStatic_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut,
  SideBarLeft_HauptmenuTopStatic_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut,
  SideBarLeft_HauptmenuTopStatic_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut,

  DB_fontFace_hauptMenueStatic_FontFamilyName,
  DB_fontFace_hauptMenueStatic_FontFamilySrc,
  DB_LeftSidebar_MenuItem_HauptmenuTop_FontWeight,
  DB_LeftSidebar_MenuItem_HauptmenuTop_FontColor,
  DB_LeftSidebar_MenuItem_HauptmenuTop_FontSize,
  DB_LeftSidebar_MenuItem_HauptmenuTop_BackgroundColor,
  DB_LeftSidebar_MenuItem_HauptmenuTop_TextAlign,
  DB_LeftSidebar_MenuItem_HauptmenuTop_BorderTop,
  DB_LeftSidebar_MenuItem_HauptmenuTop_BorderStyleTop,
  DB_LeftSidebar_MenuItem_HauptmenuTop_BorderColorTop,
  DB_LeftSidebar_MenuItem_HauptmenuTop_BorderBottom,
  DB_LeftSidebar_MenuItem_HauptmenuTop_BorderStyleBottom,
  DB_LeftSidebar_MenuItem_HauptmenuTop_BorderColorBottom,
  DB_LeftSidebar_MenuItem_HauptmenuTop_BorderLeft,
  DB_LeftSidebar_MenuItem_HauptmenuTop_BorderStyleLeft,
  DB_LeftSidebar_MenuItem_HauptmenuTop_BorderColorLeft,
  DB_LeftSidebar_MenuItem_HauptmenuTop_BorderRight,
  DB_LeftSidebar_MenuItem_HauptmenuTop_BorderStyleRight,
  DB_LeftSidebar_MenuItem_HauptmenuTop_BorderColorRight,
  DB_LeftSidebar_MenuItem_HauptmenuTop_BorderTopRightRadius,
  DB_LeftSidebar_MenuItem_HauptmenuTop_BorderTopLeftRadius,
  DB_LeftSidebar_MenuItem_HauptmenuTop_BorderBottomRightRadius,
  DB_LeftSidebar_MenuItem_HauptmenuTop_BorderBottomLeftRadius,
  DB_LeftSidebar_MenuItem_HauptmenuTop_marginLeftRight,
  DB_LeftSidebar_MenuItem_HauptmenuTop_marginTopBottom,
  DB_LeftSidebar_MenuItem_HauptmenuTop_paddingLeftRight,
  DB_LeftSidebar_MenuItem_HauptmenuTop_paddingTopBottom,
  DB_LeftSidebar_MenuItem_HauptmenuTop_letterSpacing,
  DB_LeftSidebar_MenuItem_HauptmenuTop_ShadowHorizontal,
  DB_LeftSidebar_MenuItem_HauptmenuTop_ShadowVertical,
  DB_LeftSidebar_MenuItem_HauptmenuTop_ShadowBlur,
  DB_LeftSidebar_MenuItem_HauptmenuTop_ShadowColor,
  DB_LeftSidebar_MenuItem_HauptmenuTop_TextDecoration,
  DB_LeftSidebar_MenuItem_HauptmenuTop_TextDecorationStyle,

  DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_FontWeight,
  DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_FontColor,
  DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_FontSize,
  DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BackgroundColor,
  DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_TextAlign,
  DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderTop,
  DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderStyleTop,
  DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderColorTop,
  DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderBottom,
  DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderStyleBottom,
  DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderColorBottom,
  DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderLeft,
  DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderStyleLeft,
  DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderColorLeft,
  DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderRight,
  DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderStyleRight,
  DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderColorRight,
  DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderTopRightRadius,
  DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderTopLeftRadius,
  DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderBottomRightRadius,
  DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderBottomLeftRadius,
  DB_LeftSidebar_MenuItem_HauptmenuTop_marginLeftRight_Hover,
  DB_LeftSidebar_MenuItem_HauptmenuTop_marginTopBottom_Hover,
  DB_LeftSidebar_MenuItem_HauptmenuTop_paddingLeftRight_Hover,
  DB_LeftSidebar_MenuItem_HauptmenuTop_paddingTopBottom_Hover,
  DB_LeftSidebar_MenuItem_HauptmenuTop_letterSpacing_Hover,
  DB_LeftSidebar_MenuItem_HauptmenuTop_ShadowHorizontal_Hover,
  DB_LeftSidebar_MenuItem_HauptmenuTop_ShadowVertical_Hover,
  DB_LeftSidebar_MenuItem_HauptmenuTop_ShadowBlur_Hover,
  DB_LeftSidebar_MenuItem_HauptmenuTop_ShadowColor_Hover,
  DB_LeftSidebar_MenuItem_HauptmenuTop_TextDecoration_Hover,
  DB_LeftSidebar_MenuItem_HauptmenuTop_TextDecorationStyle_Hover,

  DB_LeftSidebar_MenuItem_MenuContainer_Logo_DDpaddingLeft,
  DB_LeftSidebar_MenuItem_MenuContainer_Logo_DDpaddingRight,
  DB_LeftSidebar_MenuItem_MenuContainer_Logo_DDpaddingTop,
  DB_LeftSidebar_MenuItem_MenuContainer_Logo_DDpaddingBottom,
  DB_LeftSidebar_MenuItem_MenuContainer_Logo_DDShadowHorizontal,
  DB_LeftSidebar_MenuItem_MenuContainer_Logo_DDShadowVertical,
  DB_LeftSidebar_MenuItem_MenuContainer_Logo_DDShadowBlur,
  DB_LeftSidebar_MenuItem_MenuContainer_Logo_DDShadowColor,
  DB_LeftSidebar_MenuContainer_Logo_DDBackgroundColor,
  DB_LeftSidebar_MenuItem_MenuContainer_Logo_DDHeight,

  SideBarLeft_ContainerTopXYZ_Farbe_Groesse_Stil_OnMouseOverAndOut,
  SideBarLeft_ContainerTopXYZ_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut,
  SideBarLeft_ContainerTopXYZ_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut,
  SideBarLeft_ContainerTopXYZ_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut,

  DB_LeftSidebar_MenuContainerXYZ_BackgroundColor,
  DB_LeftSidebar_MenuContainerXYZ_PaddingTopBottom,
  DB_LeftSidebar_MenuContainerXYZ_PaddingLeftRight,
  DB_LeftSidebar_MenuContainerXYZ_BorderTop,
  DB_LeftSidebar_MenuContainerXYZ_BorderStyleTop,
  DB_LeftSidebar_MenuContainerXYZ_BorderColorTop,
  DB_LeftSidebar_MenuContainerXYZ_BorderBottom,
  DB_LeftSidebar_MenuContainerXYZ_BorderStyleBottom,
  DB_LeftSidebar_MenuContainerXYZ_BorderColorBottom,
  DB_LeftSidebar_MenuContainerXYZ_BorderLeft,
  DB_LeftSidebar_MenuContainerXYZ_BorderStyleLeft,
  DB_LeftSidebar_MenuContainerXYZ_BorderColorLeft,
  DB_LeftSidebar_MenuContainerXYZ_BorderRight,
  DB_LeftSidebar_MenuContainerXYZ_BorderStyleRight,
  DB_LeftSidebar_MenuContainerXYZ_BorderColorRight,
  DB_LeftSidebar_MenuItem_MenuContainerXYZ_BorderTopRightRadius,
  DB_LeftSidebar_MenuItem_MenuContainerXYZ_BorderTopLeftRadius,
  DB_LeftSidebar_MenuItem_MenuContainerXYZ_BorderBottomRightRadius,
  DB_LeftSidebar_MenuItem_MenuContainerXYZ_BorderBottomLeftRadius,
  DB_LeftSidebar_MenuItem_MenuContainerXYZ_paddingLeft,
  DB_LeftSidebar_MenuItem_MenuContainerXYZ_paddingRight,
  DB_LeftSidebar_MenuItem_MenuContainerXYZ_paddingTop,
  DB_LeftSidebar_MenuItem_MenuContainerXYZ_paddingBottom,
  DB_LeftSidebar_MenuItem_MenuContainerXYZ_ShadowHorizontal,
  DB_LeftSidebar_MenuItem_MenuContainerXYZ_ShadowVertical,
  DB_LeftSidebar_MenuItem_MenuContainerXYZ_ShadowBlur,
  DB_LeftSidebar_MenuItem_MenuContainerXYZ_ShadowColor,

  DB_LeftSidebar_MenuContainerXYZ_Hover_BackgroundColor,
  DB_LeftSidebar_MenuContainerXYZ_Hover_PaddingTopBottom,
  DB_LeftSidebar_MenuContainerXYZ_Hover_PaddingLeftRight,
  DB_LeftSidebar_MenuContainerXYZ_Hover_BorderTop,
  DB_LeftSidebar_MenuContainerXYZ_Hover_BorderStyleTop,
  DB_LeftSidebar_MenuContainerXYZ_Hover_BorderColorTop,
  DB_LeftSidebar_MenuContainerXYZ_Hover_BorderBottom,
  DB_LeftSidebar_MenuContainerXYZ_Hover_BorderStyleBottom,
  DB_LeftSidebar_MenuContainerXYZ_Hover_BorderColorBottom,
  DB_LeftSidebar_MenuContainerXYZ_Hover_BorderLeft,
  DB_LeftSidebar_MenuContainerXYZ_Hover_BorderStyleLeft,
  DB_LeftSidebar_MenuContainerXYZ_Hover_BorderColorLeft,
  DB_LeftSidebar_MenuContainerXYZ_Hover_BorderRight,
  DB_LeftSidebar_MenuContainerXYZ_Hover_BorderStyleRight,
  DB_LeftSidebar_MenuContainerXYZ_Hover_BorderColorRight,
  DB_LeftSidebar_MenuItem_MenuContainerXYZ_Hover_BorderTopRightRadius,
  DB_LeftSidebar_MenuItem_MenuContainerXYZ_Hover_BorderTopLeftRadius,
  DB_LeftSidebar_MenuItem_MenuContainerXYZ_Hover_BorderBottomRightRadius,
  DB_LeftSidebar_MenuItem_MenuContainerXYZ_Hover_BorderBottomLeftRadius,
  DB_LeftSidebar_MenuItem_MenuContainerXYZ_Hover_paddingLeft,
  DB_LeftSidebar_MenuItem_MenuContainerXYZ_Hover_paddingRight,
  DB_LeftSidebar_MenuItem_MenuContainerXYZ_Hover_paddingTop,
  DB_LeftSidebar_MenuItem_MenuContainerXYZ_Hover_paddingBottom,
  DB_LeftSidebar_MenuItem_MenuContainerXYZ_Hover_ShadowHorizontal,
  DB_LeftSidebar_MenuItem_MenuContainerXYZ_Hover_ShadowVertical,
  DB_LeftSidebar_MenuItem_MenuContainerXYZ_Hover_ShadowBlur,
  DB_LeftSidebar_MenuItem_MenuContainerXYZ_Hover_ShadowColor,
  meldenModalHandler,
}) => {
  const [DB_TopBarLogoImageSrc, setDB_TopBarLogoImageSrc] = useState("");
  const { t, i18n } = useTranslation();

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (window.innerWidth <= 768) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, []);

  const useStyles = createUseStyles({
    topNavigationNavItemStatic: {
      fontFamily: `${DB_fontFace_hauptMenueStatic_FontFamilyName} `,
      fontWeight: `${DB_LeftSidebar_MenuItem_HauptmenuTop_FontWeight}`,
      color: `${DB_LeftSidebar_MenuItem_HauptmenuTop_FontColor}`,
      fontSize: `${DB_LeftSidebar_MenuItem_HauptmenuTop_FontSize}px`,
      backgroundColor: `${
        SideBarLeft_HauptmenuTopStatic_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut
          ? "rgba(255,0,0,0.5)"
          : SideBarLeft_HauptmenuTopStatic_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut
          ? "rgba(255,0,0,0.3)"
          : DB_LeftSidebar_MenuItem_HauptmenuTop_BackgroundColor
      }`,
      textAlign: `${DB_LeftSidebar_MenuItem_HauptmenuTop_TextAlign}`,
      borderTop: `${
        SideBarLeft_HauptmenuTopStatic_Farbe_Groesse_Stil_OnMouseOverAndOut
          ? "3"
          : SideBarLeft_HauptmenuTopStatic_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
          ? "3"
          : DB_LeftSidebar_MenuItem_HauptmenuTop_BorderTop
      }px ${
        SideBarLeft_HauptmenuTopStatic_Farbe_Groesse_Stil_OnMouseOverAndOut
          ? "solid"
          : SideBarLeft_HauptmenuTopStatic_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
          ? "dotted"
          : DB_LeftSidebar_MenuItem_HauptmenuTop_BorderStyleTop
      }
      ${
        SideBarLeft_HauptmenuTopStatic_Farbe_Groesse_Stil_OnMouseOverAndOut
          ? "red"
          : SideBarLeft_HauptmenuTopStatic_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
          ? "red"
          : DB_LeftSidebar_MenuItem_HauptmenuTop_BorderColorTop
      }`,
      borderBottom: `${
        SideBarLeft_HauptmenuTopStatic_Farbe_Groesse_Stil_OnMouseOverAndOut
          ? "3"
          : SideBarLeft_HauptmenuTopStatic_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
          ? "3"
          : DB_LeftSidebar_MenuItem_HauptmenuTop_BorderBottom
      }px
      ${
        SideBarLeft_HauptmenuTopStatic_Farbe_Groesse_Stil_OnMouseOverAndOut
          ? "solid"
          : SideBarLeft_HauptmenuTopStatic_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
          ? "dotted"
          : DB_LeftSidebar_MenuItem_HauptmenuTop_BorderStyleBottom
      }
      ${
        SideBarLeft_HauptmenuTopStatic_Farbe_Groesse_Stil_OnMouseOverAndOut
          ? "red"
          : SideBarLeft_HauptmenuTopStatic_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
          ? "red"
          : DB_LeftSidebar_MenuItem_HauptmenuTop_BorderColorBottom
      } !important;
    border-left: ${
      SideBarLeft_HauptmenuTopStatic_Farbe_Groesse_Stil_OnMouseOverAndOut
        ? "3"
        : SideBarLeft_HauptmenuTopStatic_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
        ? "3"
        : DB_LeftSidebar_MenuItem_HauptmenuTop_BorderLeft
    }px
      ${
        SideBarLeft_HauptmenuTopStatic_Farbe_Groesse_Stil_OnMouseOverAndOut
          ? "solid"
          : SideBarLeft_HauptmenuTopStatic_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
          ? "dotted"
          : DB_LeftSidebar_MenuItem_HauptmenuTop_BorderStyleLeft
      }
      ${
        SideBarLeft_HauptmenuTopStatic_Farbe_Groesse_Stil_OnMouseOverAndOut
          ? "red"
          : SideBarLeft_HauptmenuTopStatic_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
          ? "red"
          : DB_LeftSidebar_MenuItem_HauptmenuTop_BorderColorLeft
      }`,
      borderRight: `${
        SideBarLeft_HauptmenuTopStatic_Farbe_Groesse_Stil_OnMouseOverAndOut
          ? "3"
          : SideBarLeft_HauptmenuTopStatic_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
          ? "3"
          : DB_LeftSidebar_MenuItem_HauptmenuTop_BorderRight
      }px
      ${
        SideBarLeft_HauptmenuTopStatic_Farbe_Groesse_Stil_OnMouseOverAndOut
          ? "solid"
          : SideBarLeft_HauptmenuTopStatic_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
          ? "dotted"
          : DB_LeftSidebar_MenuItem_HauptmenuTop_BorderStyleRight
      }
      ${
        SideBarLeft_HauptmenuTopStatic_Farbe_Groesse_Stil_OnMouseOverAndOut
          ? "red"
          : SideBarLeft_HauptmenuTopStatic_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
          ? "red"
          : DB_LeftSidebar_MenuItem_HauptmenuTop_BorderColorRight
      }`,
      borderTopRightRadius: `${DB_LeftSidebar_MenuItem_HauptmenuTop_BorderTopRightRadius}px`,
      borderTopLeftRadius: `${DB_LeftSidebar_MenuItem_HauptmenuTop_BorderTopLeftRadius}px`,
      borderBottomRightRadius: `${DB_LeftSidebar_MenuItem_HauptmenuTop_BorderBottomRightRadius}px`,
      borderBottomLeftRadius: `${DB_LeftSidebar_MenuItem_HauptmenuTop_BorderBottomLeftRadius}px`,
      marginLeft: `${DB_LeftSidebar_MenuItem_HauptmenuTop_marginLeftRight}px`,
      marginRight: `${DB_LeftSidebar_MenuItem_HauptmenuTop_marginLeftRight}px`,

      marginBottom: `${DB_LeftSidebar_MenuItem_HauptmenuTop_marginTopBottom}px`,
      paddingLeft: `${DB_LeftSidebar_MenuItem_HauptmenuTop_paddingLeftRight}px`,
      paddingRight: `${DB_LeftSidebar_MenuItem_HauptmenuTop_paddingLeftRight}px`,
      paddingTop: `${DB_LeftSidebar_MenuItem_HauptmenuTop_paddingTopBottom}px`,
      paddingBottom: `${DB_LeftSidebar_MenuItem_HauptmenuTop_paddingTopBottom}px`,
      letterSpacing: `${DB_LeftSidebar_MenuItem_HauptmenuTop_letterSpacing}px`,
      textShadow: `${DB_LeftSidebar_MenuItem_HauptmenuTop_ShadowHorizontal}px
    ${DB_LeftSidebar_MenuItem_HauptmenuTop_ShadowVertical}px
      ${DB_LeftSidebar_MenuItem_HauptmenuTop_ShadowBlur}px
      ${DB_LeftSidebar_MenuItem_HauptmenuTop_ShadowColor}`,
      textDecoration: `${DB_LeftSidebar_MenuItem_HauptmenuTop_TextDecoration}
      ${DB_LeftSidebar_MenuItem_HauptmenuTop_TextDecorationStyle}`,

      "&:hover": {
        fontWeight: `${DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_FontWeight}`,
        color: `${DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_FontColor}`,
        fontSize: `${DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_FontSize}px`,
        backgroundColor: `${DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BackgroundColor}`,
        textAlign: `${DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_TextAlign}`,
        borderTop: `${DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderTop}px
              ${DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderStyleTop}
              ${DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderColorTop}`,
        borderBottom: `${DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderBottom}px
              ${DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderStyleBottom}
              ${DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderColorBottom}`,
        borderLeft: `${DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderLeft}px
              ${DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderStyleLeft}
              ${DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderColorLeft}`,
        borderRight: `${DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderRight}px
              ${DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderStyleRight}
              ${DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderColorRight}`,
        borderTopRightRadius: `${DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderTopRightRadius}px`,
        borderTopLeftRadius: `${DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderTopLeftRadius}px`,
        borderBottomRightRadius: `${DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderBottomRightRadius}px`,
        borderBottomLeftRadius: `${DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderBottomLeftRadius}px`,

        marginLeft: `${DB_LeftSidebar_MenuItem_HauptmenuTop_marginLeftRight_Hover}px`,
        marginRight: `${DB_LeftSidebar_MenuItem_HauptmenuTop_marginLeftRight_Hover}px`,

        marginBottom: `${DB_LeftSidebar_MenuItem_HauptmenuTop_marginTopBottom_Hover}px`,
        paddingLeft: `${DB_LeftSidebar_MenuItem_HauptmenuTop_paddingLeftRight_Hover}px`,
        paddingRight: `${DB_LeftSidebar_MenuItem_HauptmenuTop_paddingLeftRight_Hover}px`,
        paddingTop: `${DB_LeftSidebar_MenuItem_HauptmenuTop_paddingTopBottom_Hover}px`,
        paddingBottom: `${DB_LeftSidebar_MenuItem_HauptmenuTop_paddingTopBottom_Hover}px`,
        letterSpacing: `${DB_LeftSidebar_MenuItem_HauptmenuTop_letterSpacing_Hover}px`,
        textShadow: `${DB_LeftSidebar_MenuItem_HauptmenuTop_ShadowHorizontal_Hover}px
              ${DB_LeftSidebar_MenuItem_HauptmenuTop_ShadowVertical_Hover}px
              ${DB_LeftSidebar_MenuItem_HauptmenuTop_ShadowBlur_Hover}px
              ${DB_LeftSidebar_MenuItem_HauptmenuTop_ShadowColor_Hover}`,
        textDecoration: `${DB_LeftSidebar_MenuItem_HauptmenuTop_TextDecoration_Hover}
              ${DB_LeftSidebar_MenuItem_HauptmenuTop_TextDecorationStyle_Hover}`,
      },
    },
    theLogo: {
      height: `${DB_LeftSidebar_MenuItem_MenuContainer_Logo_DDHeight}px`,
      backgroundColor: `${DB_LeftSidebar_MenuContainer_Logo_DDBackgroundColor}`,
      paddingLeft: `${DB_LeftSidebar_MenuItem_MenuContainer_Logo_DDpaddingLeft}px`,
      paddingRight: `${DB_LeftSidebar_MenuItem_MenuContainer_Logo_DDpaddingRight}px`,
      paddingTop: `${DB_LeftSidebar_MenuItem_MenuContainer_Logo_DDpaddingTop}px`,
      paddingBottom: `${DB_LeftSidebar_MenuItem_MenuContainer_Logo_DDpaddingBottom}px`,
      boxShadow: `${DB_LeftSidebar_MenuItem_MenuContainer_Logo_DDShadowHorizontal}px
      ${DB_LeftSidebar_MenuItem_MenuContainer_Logo_DDShadowVertical}px
      ${DB_LeftSidebar_MenuItem_MenuContainer_Logo_DDShadowBlur}px
      ${DB_LeftSidebar_MenuItem_MenuContainer_Logo_DDShadowColor}`,
      cursor: "pointer",
    },
    topNavigationStatic: {
      position: `relative`,
      display: `flex`,
      minHeight: `50px`,
      overflow: `hidden`,
      flexWrap: `wrap`,
      listStyle: `none`,
      padding: `0`,
      width: `100%`,
    },
    topNavigationNavItemSearchStaticBottom: {
      backgroundColor: `${
        SideBarLeft_HauptmenuTopStatic_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut
          ? "rgba(255,0,0,0.5)"
          : SideBarLeft_HauptmenuTopStatic_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut
          ? "rgba(255,0,0,0.3)"
          : DB_LeftSidebar_MenuItem_HauptmenuTop_BackgroundColor
      }`,
      padding: `0px 0px`,
      fontFamily: `${DB_fontFace_hauptMenueStatic_FontFamilyName}`,
      fontWeight: `${DB_LeftSidebar_MenuItem_HauptmenuTop_FontWeight}`,
      fontSize: `${DB_LeftSidebar_MenuItem_HauptmenuTop_FontSize}px`,
      color: `${DB_LeftSidebar_MenuItem_HauptmenuTop_FontColor}`,
      textAlign: `center`,
      marginLeft: `${DB_LeftSidebar_MenuItem_HauptmenuTop_marginLeftRight}px`,
      marginRight: `${DB_LeftSidebar_MenuItem_HauptmenuTop_marginLeftRight}px`,
      marginBottom: `${DB_LeftSidebar_MenuItem_HauptmenuTop_marginTopBottom}px`,
      paddingLeft: `${DB_LeftSidebar_MenuItem_HauptmenuTop_paddingLeftRight}px`,
      paddingRight: `${DB_LeftSidebar_MenuItem_HauptmenuTop_paddingLeftRight}px`,
      paddingTop: `${DB_LeftSidebar_MenuItem_HauptmenuTop_paddingTopBottom}px`,
      paddingBottom: `${DB_LeftSidebar_MenuItem_HauptmenuTop_paddingTopBottom}px`,
      borderTopRightRadius: `${DB_LeftSidebar_MenuItem_HauptmenuTop_BorderTopRightRadius}px`,
      borderTopLeftRadius: `${DB_LeftSidebar_MenuItem_HauptmenuTop_BorderTopLeftRadius}px`,
      borderBottomRightRadius: `${DB_LeftSidebar_MenuItem_HauptmenuTop_BorderBottomRightRadius}px`,
      borderBottomLeftRadius: `${DB_LeftSidebar_MenuItem_HauptmenuTop_BorderBottomLeftRadius}px`,
      borderTop: `${
        SideBarLeft_HauptmenuTopStatic_Farbe_Groesse_Stil_OnMouseOverAndOut
          ? "3"
          : SideBarLeft_HauptmenuTopStatic_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
          ? "3"
          : DB_LeftSidebar_MenuItem_HauptmenuTop_BorderTop
      }px
        ${
          SideBarLeft_HauptmenuTopStatic_Farbe_Groesse_Stil_OnMouseOverAndOut
            ? "solid"
            : SideBarLeft_HauptmenuTopStatic_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
            ? "dotted"
            : DB_LeftSidebar_MenuItem_HauptmenuTop_BorderStyleTop
        }
        ${
          SideBarLeft_HauptmenuTopStatic_Farbe_Groesse_Stil_OnMouseOverAndOut
            ? "red"
            : SideBarLeft_HauptmenuTopStatic_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
            ? "red"
            : DB_LeftSidebar_MenuItem_HauptmenuTop_BorderColorTop
        }`,
      borderBottom: `${
        SideBarLeft_HauptmenuTopStatic_Farbe_Groesse_Stil_OnMouseOverAndOut
          ? "3"
          : SideBarLeft_HauptmenuTopStatic_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
          ? "3"
          : DB_LeftSidebar_MenuItem_HauptmenuTop_BorderBottom
      }px
        ${
          SideBarLeft_HauptmenuTopStatic_Farbe_Groesse_Stil_OnMouseOverAndOut
            ? "solid"
            : SideBarLeft_HauptmenuTopStatic_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
            ? "dotted"
            : DB_LeftSidebar_MenuItem_HauptmenuTop_BorderStyleBottom
        }
        ${
          SideBarLeft_HauptmenuTopStatic_Farbe_Groesse_Stil_OnMouseOverAndOut
            ? "red"
            : SideBarLeft_HauptmenuTopStatic_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
            ? "red"
            : DB_LeftSidebar_MenuItem_HauptmenuTop_BorderColorBottom
        }`,
      borderLeft: `${
        SideBarLeft_HauptmenuTopStatic_Farbe_Groesse_Stil_OnMouseOverAndOut
          ? "3"
          : SideBarLeft_HauptmenuTopStatic_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
          ? "3"
          : DB_LeftSidebar_MenuItem_HauptmenuTop_BorderLeft
      }px
        ${
          SideBarLeft_HauptmenuTopStatic_Farbe_Groesse_Stil_OnMouseOverAndOut
            ? "solid"
            : SideBarLeft_HauptmenuTopStatic_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
            ? "dotted"
            : DB_LeftSidebar_MenuItem_HauptmenuTop_BorderStyleLeft
        }
        ${
          SideBarLeft_HauptmenuTopStatic_Farbe_Groesse_Stil_OnMouseOverAndOut
            ? "red"
            : SideBarLeft_HauptmenuTopStatic_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
            ? "red"
            : DB_LeftSidebar_MenuItem_HauptmenuTop_BorderColorLeft
        }`,
      borderRight: `${
        SideBarLeft_HauptmenuTopStatic_Farbe_Groesse_Stil_OnMouseOverAndOut
          ? "3"
          : SideBarLeft_HauptmenuTopStatic_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
          ? "3"
          : DB_LeftSidebar_MenuItem_HauptmenuTop_BorderRight
      }px
        ${
          SideBarLeft_HauptmenuTopStatic_Farbe_Groesse_Stil_OnMouseOverAndOut
            ? "solid"
            : SideBarLeft_HauptmenuTopStatic_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
            ? "dotted"
            : DB_LeftSidebar_MenuItem_HauptmenuTop_BorderStyleRight
        }
        ${
          SideBarLeft_HauptmenuTopStatic_Farbe_Groesse_Stil_OnMouseOverAndOut
            ? "red"
            : SideBarLeft_HauptmenuTopStatic_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
            ? "red"
            : DB_LeftSidebar_MenuItem_HauptmenuTop_BorderColorRight
        }`,
      boxShadow: `none`,

      "&:focus": {
        backgroundColor: `${DB_LeftSidebar_MenuItem_HauptmenuTop_BackgroundColor}`,
        color: `${DB_LeftSidebar_MenuItem_HauptmenuTop_FontColor}`,
      },

      "&::placeholder": {
        color: "rgba(255, 255, 255, 0.5)",
        opacity: "1",
      },

      "&::-ms-input-placeholder": {
        /* Internet Explorer 10-11 */
        color: "rgba(255, 255, 255, 0.5)",
      },

      "&::-ms-input-placeholder": {
        /* Microsoft Edge */
        color: "rgba(255, 255, 255, 0.5)",
      },
    },
    topNavigationSearchStatic: {
      position: `relative`,
      display: `flex`,
      overflow: `hidden`,
      flexWrap: `wrap`,
      listStyle: `none`,
      width: `100%`,
    },
    topNavigationNavItemSearchStaticMobile: {
      width: `140px`,
      marginLeft: `20px`,
      marginRight: `20px`,
      backgroundColor: `rgba(0, 0, 0, 0.4)`,
      marginTop: `20px`,
      padding: `3px 0px !important`,
      fontFamily: `${DB_fontFace_FontFamilyName}`,
      fontSize: `16px`,
      textAlign: `center`,
      borderRadius: `7px`,
      borderTop: `1px solid rgba(0, 0, 0, 0.6)`,
      borderBottom: `1px solid rgba(0, 0, 0, 0.6)`,
      borderLeft: `1px solid rgba(0, 0, 0, 0.6)`,
      borderRight: `1px solid rgba(0, 0, 0, 0.6)`,
      boxShadow: `none`,
    },
    topNavigationNavItemSearchStaticBottom: {
      backgroundColor: `${
        SideBarLeft_HauptmenuTopStatic_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut
          ? "rgba(255,0,0,0.5)"
          : SideBarLeft_HauptmenuTopStatic_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut
          ? "rgba(255,0,0,0.3)"
          : DB_LeftSidebar_MenuItem_HauptmenuTop_BackgroundColor
      }`,
      padding: `0px 0px`,
      fontFamily: `${DB_fontFace_hauptMenueStatic_FontFamilyName}`,
      fontWeight: `${DB_LeftSidebar_MenuItem_HauptmenuTop_FontWeight}`,
      fontSize: `${DB_LeftSidebar_MenuItem_HauptmenuTop_FontSize}px`,
      color: `${DB_LeftSidebar_MenuItem_HauptmenuTop_FontColor}`,
      textAlign: `center`,
      marginLeft: `${DB_LeftSidebar_MenuItem_HauptmenuTop_marginLeftRight}px`,
      marginRight: `${DB_LeftSidebar_MenuItem_HauptmenuTop_marginLeftRight}px`,
      marginBottom: `${DB_LeftSidebar_MenuItem_HauptmenuTop_marginTopBottom}px`,
      paddingLeft: `${DB_LeftSidebar_MenuItem_HauptmenuTop_paddingLeftRight}px`,
      paddingRight: `${DB_LeftSidebar_MenuItem_HauptmenuTop_paddingLeftRight}px`,
      paddingTop: `${DB_LeftSidebar_MenuItem_HauptmenuTop_paddingTopBottom}px`,
      paddingBottom: `${DB_LeftSidebar_MenuItem_HauptmenuTop_paddingTopBottom}px`,
      borderTopRightRadius: `${DB_LeftSidebar_MenuItem_HauptmenuTop_BorderTopRightRadius}px`,
      borderTopLeftRadius: `${DB_LeftSidebar_MenuItem_HauptmenuTop_BorderTopLeftRadius}px`,
      borderBottomRightRadius: `${DB_LeftSidebar_MenuItem_HauptmenuTop_BorderBottomRightRadius}px`,
      borderBottomLeftRadius: `${DB_LeftSidebar_MenuItem_HauptmenuTop_BorderBottomLeftRadius}px`,
      borderTop: `${
        SideBarLeft_HauptmenuTopStatic_Farbe_Groesse_Stil_OnMouseOverAndOut
          ? "3"
          : SideBarLeft_HauptmenuTopStatic_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
          ? "3"
          : DB_LeftSidebar_MenuItem_HauptmenuTop_BorderTop
      }px
        ${
          SideBarLeft_HauptmenuTopStatic_Farbe_Groesse_Stil_OnMouseOverAndOut
            ? "solid"
            : SideBarLeft_HauptmenuTopStatic_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
            ? "dotted"
            : DB_LeftSidebar_MenuItem_HauptmenuTop_BorderStyleTop
        }
        ${
          SideBarLeft_HauptmenuTopStatic_Farbe_Groesse_Stil_OnMouseOverAndOut
            ? "red"
            : SideBarLeft_HauptmenuTopStatic_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
            ? "red"
            : DB_LeftSidebar_MenuItem_HauptmenuTop_BorderColorTop
        }`,
      borderBottom: `${
        SideBarLeft_HauptmenuTopStatic_Farbe_Groesse_Stil_OnMouseOverAndOut
          ? "3"
          : SideBarLeft_HauptmenuTopStatic_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
          ? "3"
          : DB_LeftSidebar_MenuItem_HauptmenuTop_BorderBottom
      }px
        ${
          SideBarLeft_HauptmenuTopStatic_Farbe_Groesse_Stil_OnMouseOverAndOut
            ? "solid"
            : SideBarLeft_HauptmenuTopStatic_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
            ? "dotted"
            : DB_LeftSidebar_MenuItem_HauptmenuTop_BorderStyleBottom
        }
        ${
          SideBarLeft_HauptmenuTopStatic_Farbe_Groesse_Stil_OnMouseOverAndOut
            ? "red"
            : SideBarLeft_HauptmenuTopStatic_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
            ? "red"
            : DB_LeftSidebar_MenuItem_HauptmenuTop_BorderColorBottom
        }`,
      borderLeft: `${
        SideBarLeft_HauptmenuTopStatic_Farbe_Groesse_Stil_OnMouseOverAndOut
          ? "3"
          : SideBarLeft_HauptmenuTopStatic_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
          ? "3"
          : DB_LeftSidebar_MenuItem_HauptmenuTop_BorderLeft
      }px
        ${
          SideBarLeft_HauptmenuTopStatic_Farbe_Groesse_Stil_OnMouseOverAndOut
            ? "solid"
            : SideBarLeft_HauptmenuTopStatic_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
            ? "dotted"
            : DB_LeftSidebar_MenuItem_HauptmenuTop_BorderStyleLeft
        }
        ${
          SideBarLeft_HauptmenuTopStatic_Farbe_Groesse_Stil_OnMouseOverAndOut
            ? "red"
            : SideBarLeft_HauptmenuTopStatic_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
            ? "red"
            : DB_LeftSidebar_MenuItem_HauptmenuTop_BorderColorLeft
        }`,
      borderRight: `${
        SideBarLeft_HauptmenuTopStatic_Farbe_Groesse_Stil_OnMouseOverAndOut
          ? "3"
          : SideBarLeft_HauptmenuTopStatic_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
          ? "3"
          : DB_LeftSidebar_MenuItem_HauptmenuTop_BorderRight
      }px
        ${
          SideBarLeft_HauptmenuTopStatic_Farbe_Groesse_Stil_OnMouseOverAndOut
            ? "solid"
            : SideBarLeft_HauptmenuTopStatic_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
            ? "dotted"
            : DB_LeftSidebar_MenuItem_HauptmenuTop_BorderStyleRight
        }
        ${
          SideBarLeft_HauptmenuTopStatic_Farbe_Groesse_Stil_OnMouseOverAndOut
            ? "red"
            : SideBarLeft_HauptmenuTopStatic_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
            ? "red"
            : DB_LeftSidebar_MenuItem_HauptmenuTop_BorderColorRight
        }`,
      boxShadow: `none`,

      "&:focus": {
        backgroundColor: `${DB_LeftSidebar_MenuItem_HauptmenuTop_BackgroundColor}`,
        color: `${DB_LeftSidebar_MenuItem_HauptmenuTop_FontColor}`,
      },

      "&::placeholder": {
        color: "rgba(255, 255, 255, 0.5)",
        opacity: "1",
      },

      "&::-ms-input-placeholder": {
        /* Internet Explorer 10-11 */
        color: "rgba(255, 255, 255, 0.5)",
      },

      "&::-ms-input-placeholder": {
        /* Microsoft Edge */
        color: "rgba(255, 255, 255, 0.5)",
      },
    },
    topNavigationNavItemSearchStaticBottomMobile: {
      width: `140px`,
      marginLeft: `20px`,
      marginRight: `20px`,
      backgroundColor: `rgba(0, 0, 0, 0.4)`,
      marginTop: `20px`,
      padding: `3px 0px !important`,
      fontFamily: `${DB_fontFace_FontFamilyName}`,
      fontSize: `16px`,
      textAlign: `center`,
      borderRadius: `7px`,
      borderTop: `1px solid rgba(0, 0, 0, 0.6)`,
      borderBottom: `1px solid rgba(0, 0, 0, 0.6)`,
      borderLeft: `1px solid rgba(0, 0, 0, 0.6)`,
      borderRight: `1px solid rgba(0, 0, 0, 0.6)`,
      boxShadow: `none`,
    },
  });

  const classes = useStyles();

  useEffect(() => {
    axios({
      method: "post",
      url: `${process.env.REACT_APP_PUBLIC_SERVER_URL}/shop/get-design-logo-image`,
      data: {
        shopName,
      },
    })
      .then((uploadedResult) => {
        if (uploadedResult.status === 201) {
        } else {
          setDB_TopBarLogoImageSrc(
            `${process.env.REACT_APP_PUBLIC_SERVER_URL}/` +
              uploadedResult.data.logo_image +
              "?" +
              new Date().getMilliseconds()
          );
        }
      })
      .catch((err) => {
        toast.error(
          "Keine Verbindung zum Server - Logo konnte nicht vom Server heruntergeladen werden",
          {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            transition: Zoom,
          }
        );
      });
  }, [countImagePlusTwo]);

  return (
    <>
      <style jsx global>
        {`
          .theLogo {
            height: ${DB_LeftSidebar_MenuItem_MenuContainer_Logo_DDHeight}px !important;
            background-color: ${DB_LeftSidebar_MenuContainer_Logo_DDBackgroundColor} !important;
            padding-left: ${DB_LeftSidebar_MenuItem_MenuContainer_Logo_DDpaddingLeft}px !important;
            padding-right: ${DB_LeftSidebar_MenuItem_MenuContainer_Logo_DDpaddingRight}px !important;
            padding-top: ${DB_LeftSidebar_MenuItem_MenuContainer_Logo_DDpaddingTop}px !important;
            padding-bottom: ${DB_LeftSidebar_MenuItem_MenuContainer_Logo_DDpaddingBottom}px !important;
            box-shadow: ${DB_LeftSidebar_MenuItem_MenuContainer_Logo_DDShadowHorizontal}px
              ${DB_LeftSidebar_MenuItem_MenuContainer_Logo_DDShadowVertical}px
              ${DB_LeftSidebar_MenuItem_MenuContainer_Logo_DDShadowBlur}px
              ${DB_LeftSidebar_MenuItem_MenuContainer_Logo_DDShadowColor} !important;
            cursor: pointer;
          }
        `}
      </style>

      <Container
        style={{
          padding: "0px",
          textAlign: "center",
        }}
      >
        {DB_TopBarLogoImageSrc && logoActive && TopBarIsVisibleBottom ? (
          <Navbar.Brand style={{ margin: "0px", padding: "0px" }}>
            <img
              onClick={resetFilterHandler}
              src={DB_TopBarLogoImageSrc}
              alt={shopName + " " + "Logo"}
              className={classes.theLogo}
            />
          </Navbar.Brand>
        ) : (
          <Navbar.Brand
            style={{ margin: "0px", padding: "0px" }}
          ></Navbar.Brand>
        )}
        {/* {userOwnsThisSite && (
          <div
            className="ms-2 inline-block d-lg-block d-none"
            style={{ cursor: "pointer" }}
            onClick={handleShow_showConfig_Main}
          >
            <ChangePropertiesIcon />
          </div>
        )} */}
        {(TopBarIsVisibleTop || TopBarIsVisibleBottom) && (
          <div className="justify-content-end" style={{ width: "100%" }}>
            <Navbar.Toggle
              aria-controls="basic-navbar-nav-go"
              style={{
                position: "absolute",
                right: "10px",
                top: "10px",
              }}
            />
            <Navbar.Collapse id="basic-navbar-nav-go">
              <TopBarStaticContainer
                SideBarLeft_ContainerTopXYZ_Farbe_Groesse_Stil_OnMouseOverAndOut={
                  SideBarLeft_ContainerTopXYZ_Farbe_Groesse_Stil_OnMouseOverAndOut
                }
                SideBarLeft_ContainerTopXYZ_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut={
                  SideBarLeft_ContainerTopXYZ_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
                }
                SideBarLeft_ContainerTopXYZ_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut={
                  SideBarLeft_ContainerTopXYZ_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut
                }
                SideBarLeft_ContainerTopXYZ_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut={
                  SideBarLeft_ContainerTopXYZ_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut
                }
                DB_LeftSidebar_MenuContainerXYZ_BackgroundColor={
                  DB_LeftSidebar_MenuContainerXYZ_BackgroundColor
                }
                DB_LeftSidebar_MenuContainerXYZ_PaddingTopBottom={
                  DB_LeftSidebar_MenuContainerXYZ_PaddingTopBottom
                }
                DB_LeftSidebar_MenuContainerXYZ_PaddingLeftRight={
                  DB_LeftSidebar_MenuContainerXYZ_PaddingLeftRight
                }
                DB_LeftSidebar_MenuContainerXYZ_BorderTop={
                  DB_LeftSidebar_MenuContainerXYZ_BorderTop
                }
                DB_LeftSidebar_MenuContainerXYZ_BorderStyleTop={
                  DB_LeftSidebar_MenuContainerXYZ_BorderStyleTop
                }
                DB_LeftSidebar_MenuContainerXYZ_BorderColorTop={
                  DB_LeftSidebar_MenuContainerXYZ_BorderColorTop
                }
                DB_LeftSidebar_MenuContainerXYZ_BorderBottom={
                  DB_LeftSidebar_MenuContainerXYZ_BorderBottom
                }
                DB_LeftSidebar_MenuContainerXYZ_BorderStyleBottom={
                  DB_LeftSidebar_MenuContainerXYZ_BorderStyleBottom
                }
                DB_LeftSidebar_MenuContainerXYZ_BorderColorBottom={
                  DB_LeftSidebar_MenuContainerXYZ_BorderColorBottom
                }
                DB_LeftSidebar_MenuContainerXYZ_BorderLeft={
                  DB_LeftSidebar_MenuContainerXYZ_BorderLeft
                }
                DB_LeftSidebar_MenuContainerXYZ_BorderStyleLeft={
                  DB_LeftSidebar_MenuContainerXYZ_BorderStyleLeft
                }
                DB_LeftSidebar_MenuContainerXYZ_BorderColorLeft={
                  DB_LeftSidebar_MenuContainerXYZ_BorderColorLeft
                }
                DB_LeftSidebar_MenuContainerXYZ_BorderRight={
                  DB_LeftSidebar_MenuContainerXYZ_BorderRight
                }
                DB_LeftSidebar_MenuContainerXYZ_BorderStyleRight={
                  DB_LeftSidebar_MenuContainerXYZ_BorderStyleRight
                }
                DB_LeftSidebar_MenuContainerXYZ_BorderColorRight={
                  DB_LeftSidebar_MenuContainerXYZ_BorderColorRight
                }
                DB_LeftSidebar_MenuItem_MenuContainerXYZ_BorderTopRightRadius={
                  DB_LeftSidebar_MenuItem_MenuContainerXYZ_BorderTopRightRadius
                }
                DB_LeftSidebar_MenuItem_MenuContainerXYZ_BorderTopLeftRadius={
                  DB_LeftSidebar_MenuItem_MenuContainerXYZ_BorderTopLeftRadius
                }
                DB_LeftSidebar_MenuItem_MenuContainerXYZ_BorderBottomRightRadius={
                  DB_LeftSidebar_MenuItem_MenuContainerXYZ_BorderBottomRightRadius
                }
                DB_LeftSidebar_MenuItem_MenuContainerXYZ_BorderBottomLeftRadius={
                  DB_LeftSidebar_MenuItem_MenuContainerXYZ_BorderBottomLeftRadius
                }
                DB_LeftSidebar_MenuItem_MenuContainerXYZ_paddingLeft={
                  DB_LeftSidebar_MenuItem_MenuContainerXYZ_paddingLeft
                }
                DB_LeftSidebar_MenuItem_MenuContainerXYZ_paddingRight={
                  DB_LeftSidebar_MenuItem_MenuContainerXYZ_paddingRight
                }
                DB_LeftSidebar_MenuItem_MenuContainerXYZ_paddingTop={
                  DB_LeftSidebar_MenuItem_MenuContainerXYZ_paddingTop
                }
                DB_LeftSidebar_MenuItem_MenuContainerXYZ_paddingBottom={
                  DB_LeftSidebar_MenuItem_MenuContainerXYZ_paddingBottom
                }
                DB_LeftSidebar_MenuItem_MenuContainerXYZ_ShadowHorizontal={
                  DB_LeftSidebar_MenuItem_MenuContainerXYZ_ShadowHorizontal
                }
                DB_LeftSidebar_MenuItem_MenuContainerXYZ_ShadowVertical={
                  DB_LeftSidebar_MenuItem_MenuContainerXYZ_ShadowVertical
                }
                DB_LeftSidebar_MenuItem_MenuContainerXYZ_ShadowBlur={
                  DB_LeftSidebar_MenuItem_MenuContainerXYZ_ShadowBlur
                }
                DB_LeftSidebar_MenuItem_MenuContainerXYZ_ShadowColor={
                  DB_LeftSidebar_MenuItem_MenuContainerXYZ_ShadowColor
                }
                DB_LeftSidebar_MenuContainerXYZ_Hover_BackgroundColor={
                  DB_LeftSidebar_MenuContainerXYZ_Hover_BackgroundColor
                }
                DB_LeftSidebar_MenuContainerXYZ_Hover_PaddingTopBottom={
                  DB_LeftSidebar_MenuContainerXYZ_Hover_PaddingTopBottom
                }
                DB_LeftSidebar_MenuContainerXYZ_Hover_PaddingLeftRight={
                  DB_LeftSidebar_MenuContainerXYZ_Hover_PaddingLeftRight
                }
                DB_LeftSidebar_MenuContainerXYZ_Hover_BorderTop={
                  DB_LeftSidebar_MenuContainerXYZ_Hover_BorderTop
                }
                DB_LeftSidebar_MenuContainerXYZ_Hover_BorderStyleTop={
                  DB_LeftSidebar_MenuContainerXYZ_Hover_BorderStyleTop
                }
                DB_LeftSidebar_MenuContainerXYZ_Hover_BorderColorTop={
                  DB_LeftSidebar_MenuContainerXYZ_Hover_BorderColorTop
                }
                DB_LeftSidebar_MenuContainerXYZ_Hover_BorderBottom={
                  DB_LeftSidebar_MenuContainerXYZ_Hover_BorderBottom
                }
                DB_LeftSidebar_MenuContainerXYZ_Hover_BorderStyleBottom={
                  DB_LeftSidebar_MenuContainerXYZ_Hover_BorderStyleBottom
                }
                DB_LeftSidebar_MenuContainerXYZ_Hover_BorderColorBottom={
                  DB_LeftSidebar_MenuContainerXYZ_Hover_BorderColorBottom
                }
                DB_LeftSidebar_MenuContainerXYZ_Hover_BorderLeft={
                  DB_LeftSidebar_MenuContainerXYZ_Hover_BorderLeft
                }
                DB_LeftSidebar_MenuContainerXYZ_Hover_BorderStyleLeft={
                  DB_LeftSidebar_MenuContainerXYZ_Hover_BorderStyleLeft
                }
                DB_LeftSidebar_MenuContainerXYZ_Hover_BorderColorLeft={
                  DB_LeftSidebar_MenuContainerXYZ_Hover_BorderColorLeft
                }
                DB_LeftSidebar_MenuContainerXYZ_Hover_BorderRight={
                  DB_LeftSidebar_MenuContainerXYZ_Hover_BorderRight
                }
                DB_LeftSidebar_MenuContainerXYZ_Hover_BorderStyleRight={
                  DB_LeftSidebar_MenuContainerXYZ_Hover_BorderStyleRight
                }
                DB_LeftSidebar_MenuContainerXYZ_Hover_BorderColorRight={
                  DB_LeftSidebar_MenuContainerXYZ_Hover_BorderColorRight
                }
                DB_LeftSidebar_MenuItem_MenuContainerXYZ_Hover_BorderTopRightRadius={
                  DB_LeftSidebar_MenuItem_MenuContainerXYZ_Hover_BorderTopRightRadius
                }
                DB_LeftSidebar_MenuItem_MenuContainerXYZ_Hover_BorderTopLeftRadius={
                  DB_LeftSidebar_MenuItem_MenuContainerXYZ_Hover_BorderTopLeftRadius
                }
                DB_LeftSidebar_MenuItem_MenuContainerXYZ_Hover_BorderBottomRightRadius={
                  DB_LeftSidebar_MenuItem_MenuContainerXYZ_Hover_BorderBottomRightRadius
                }
                DB_LeftSidebar_MenuItem_MenuContainerXYZ_Hover_BorderBottomLeftRadius={
                  DB_LeftSidebar_MenuItem_MenuContainerXYZ_Hover_BorderBottomLeftRadius
                }
                DB_LeftSidebar_MenuItem_MenuContainerXYZ_Hover_paddingLeft={
                  DB_LeftSidebar_MenuItem_MenuContainerXYZ_Hover_paddingLeft
                }
                DB_LeftSidebar_MenuItem_MenuContainerXYZ_Hover_paddingRight={
                  DB_LeftSidebar_MenuItem_MenuContainerXYZ_Hover_paddingRight
                }
                DB_LeftSidebar_MenuItem_MenuContainerXYZ_Hover_paddingTop={
                  DB_LeftSidebar_MenuItem_MenuContainerXYZ_Hover_paddingTop
                }
                DB_LeftSidebar_MenuItem_MenuContainerXYZ_Hover_paddingBottom={
                  DB_LeftSidebar_MenuItem_MenuContainerXYZ_Hover_paddingBottom
                }
                DB_LeftSidebar_MenuItem_MenuContainerXYZ_Hover_ShadowHorizontal={
                  DB_LeftSidebar_MenuItem_MenuContainerXYZ_Hover_ShadowHorizontal
                }
                DB_LeftSidebar_MenuItem_MenuContainerXYZ_Hover_ShadowVertical={
                  DB_LeftSidebar_MenuItem_MenuContainerXYZ_Hover_ShadowVertical
                }
                DB_LeftSidebar_MenuItem_MenuContainerXYZ_Hover_ShadowBlur={
                  DB_LeftSidebar_MenuItem_MenuContainerXYZ_Hover_ShadowBlur
                }
                DB_LeftSidebar_MenuItem_MenuContainerXYZ_Hover_ShadowColor={
                  DB_LeftSidebar_MenuItem_MenuContainerXYZ_Hover_ShadowColor
                }
              >
                <div
                  style={{
                    marginBottom: "10px",
                    marginLeft: "100px",
                    marginRight: "100px",
                  }}
                >
                  <Nav.Item>
                    <Form.Control
                      className={
                        isMobile
                          ? classes.topNavigationNavItemSearchStaticBottomMobile
                          : classes.topNavigationNavItemSearchStaticBottom
                      }
                      type="text"
                      placeholder={t("Suche")}
                      onChange={(e) => searchHandler(e)}
                      onKeyDown={(e) => searchPressEnter(e)}
                      value={searchValue}
                      autoComplete="nope"
                    />
                  </Nav.Item>
                </div>

                <Nav expand="lg" className="justify-content-end">
                  <Nav.Item>
                    <Nav.Link
                      className={classes.topNavigationNavItemSearchStaticBottom}
                      onClick={() => showTopProductsHandler()}
                    >
                      <span className="topNavigationNavItemText">
                        {t("TopProdukte")}
                      </span>
                    </Nav.Link>
                  </Nav.Item>

                  <Nav.Item>
                    <Nav.Link
                      className={classes.topNavigationNavItemSearchStaticBottom}
                      onClick={() => aboutUsShowHandler()}
                    >
                      <span className="topNavigationNavItemText">
                        {t("UeberUns")}
                      </span>
                    </Nav.Link>
                  </Nav.Item>
                  {faqDetailItem && (
                    <Nav.Item>
                      <Nav.Link
                        className={
                          classes.topNavigationNavItemSearchStaticBottom
                        }
                        onClick={() => faqShowHandler()}
                      >
                        <span className="topNavigationNavItemText">
                          {t("FAQ")}
                        </span>
                      </Nav.Link>
                    </Nav.Item>
                  )}

                  {isAuthState ? (
                    <Nav.Item>
                      <Nav.Link
                        className={
                          classes.topNavigationNavItemSearchStaticBottom
                        }
                        onClick={(truefalse) => meldenModalHandler(true)}
                      >
                        <span className="topNavigationNavItemText">
                          {t("ShopMelden")}
                        </span>
                      </Nav.Link>
                    </Nav.Item>
                  ) : (
                    <>
                      <Nav.Item>
                        <Nav.Link
                          className={
                            classes.topNavigationNavItemSearchStaticBottom
                          }
                          onClick={(truefalse) => meldenModalHandler(true)}
                        >
                          <span className="topNavigationNavItemText">
                            {t("ShopMelden")}
                          </span>
                        </Nav.Link>
                      </Nav.Item>
                    </>
                  )}

                  {isAuthState ? (
                    <Nav.Item>
                      <Nav.Link
                        className={
                          classes.topNavigationNavItemSearchStaticBottom
                        }
                        onClick={signOutMessage}
                      >
                        <span className="topNavigationNavItemText">
                          {t("Abmelden")}
                        </span>
                      </Nav.Link>
                    </Nav.Item>
                  ) : (
                    <Nav.Item>
                      <Nav.Link
                        className={
                          classes.topNavigationNavItemSearchStaticBottom
                        }
                        onClick={toggleSignIn}
                      >
                        <span className="topNavigationNavItemText">
                          {t("Anmelden")}
                        </span>
                      </Nav.Link>
                    </Nav.Item>
                  )}
                </Nav>
              </TopBarStaticContainer>
            </Navbar.Collapse>
          </div>
        )}
      </Container>
    </>
  );
};

export default TopBarStaticBottom;
