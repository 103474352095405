import { createUseStyles } from "react-jss";

const ProductCardContainerTwelfe = ({
  children,
  SideBarLeft_ProduktContainer_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut,
  SideBarLeft_ProduktContainer_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut,
  DB_LeftSidebar_MenuProduktContainer_BackgroundColor,
  DB_LeftSidebar_MenuProduktContainer_PaddingTopBottom,
  DB_LeftSidebar_MenuProduktContainer_PaddingLeftRight,
  SideBarLeft_ProduktContainer_Farbe_Groesse_Stil_OnMouseOverAndOut,
  SideBarLeft_ProduktContainer_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut,
  DB_LeftSidebar_MenuProduktContainer_BorderTop,
  DB_LeftSidebar_MenuProduktContainer_BorderStyleTop,
  DB_LeftSidebar_MenuProduktContainer_BorderColorTop,
  DB_LeftSidebar_MenuProduktContainer_BorderBottom,
  DB_LeftSidebar_MenuProduktContainer_BorderStyleBottom,
  DB_LeftSidebar_MenuProduktContainer_BorderColorBottom,
  DB_LeftSidebar_MenuProduktContainer_BorderLeft,
  DB_LeftSidebar_MenuProduktContainer_BorderStyleLeft,
  DB_LeftSidebar_MenuProduktContainer_BorderColorLeft,
  DB_LeftSidebar_MenuProduktContainer_BorderRight,
  DB_LeftSidebar_MenuProduktContainer_BorderStyleRight,
  DB_LeftSidebar_MenuProduktContainer_BorderColorRight,
  DB_LeftSidebar_MenuItem_MenuProduktContainer_BorderTopRightRadius,
  DB_LeftSidebar_MenuItem_MenuProduktContainer_BorderTopLeftRadius,
  DB_LeftSidebar_MenuItem_MenuProduktContainer_BorderBottomRightRadius,
  DB_LeftSidebar_MenuItem_MenuProduktContainer_BorderBottomLeftRadius,
  DB_LeftSidebar_MenuItem_MenuProduktContainer_paddingLeft,
  DB_LeftSidebar_MenuItem_MenuProduktContainer_paddingRight,
  DB_LeftSidebar_MenuItem_MenuProduktContainer_paddingTop,
  DB_LeftSidebar_MenuItem_MenuProduktContainer_paddingBottom,
  DB_LeftSidebar_MenuItem_MenuProduktContainer_ShadowHorizontal,
  DB_LeftSidebar_MenuItem_MenuProduktContainer_ShadowBlur,
  DB_LeftSidebar_MenuItem_MenuProduktContainer_ShadowColor,
  DB_LeftSidebar_MenuProduktContainer_Hover_BackgroundColor,
  DB_LeftSidebar_MenuProduktContainer_Hover_PaddingTopBottom,
  DB_LeftSidebar_MenuProduktContainer_Hover_PaddingLeftRight,
  DB_LeftSidebar_MenuProduktContainer_Hover_BorderTop,
  DB_LeftSidebar_MenuProduktContainer_Hover_BorderStyleTop,
  DB_LeftSidebar_MenuProduktContainer_Hover_BorderColorTop,
  DB_LeftSidebar_MenuProduktContainer_Hover_BorderBottom,
  DB_LeftSidebar_MenuProduktContainer_Hover_BorderStyleBottom,
  DB_LeftSidebar_MenuProduktContainer_Hover_BorderColorBottom,
  DB_LeftSidebar_MenuProduktContainer_Hover_BorderLeft,
  DB_LeftSidebar_MenuProduktContainer_Hover_BorderStyleLeft,
  DB_LeftSidebar_MenuProduktContainer_Hover_BorderColorLeft,
  DB_LeftSidebar_MenuProduktContainer_Hover_BorderRight,
  DB_LeftSidebar_MenuProduktContainer_Hover_BorderStyleRight,
  DB_LeftSidebar_MenuProduktContainer_Hover_BorderColorRight,
  DB_LeftSidebar_MenuItem_MenuProduktContainer_Hover_BorderTopRightRadius,
  DB_LeftSidebar_MenuItem_MenuProduktContainer_Hover_BorderTopLeftRadius,
  DB_LeftSidebar_MenuItem_MenuProduktContainer_Hover_BorderBottomRightRadius,
  DB_LeftSidebar_MenuItem_MenuProduktContainer_Hover_BorderBottomLeftRadius,
  DB_LeftSidebar_MenuItem_MenuProduktContainer_Hover_paddingLeft,
  DB_LeftSidebar_MenuItem_MenuProduktContainer_Hover_paddingRight,
  DB_LeftSidebar_MenuItem_MenuProduktContainer_Hover_paddingTop,
  DB_LeftSidebar_MenuItem_MenuProduktContainer_Hover_paddingBottom,
  DB_LeftSidebar_MenuItem_MenuProduktContainer_Hover_ShadowVertical,
  DB_LeftSidebar_MenuItem_MenuProduktContainer_ShadowVertical,
  DB_LeftSidebar_MenuItem_MenuProduktContainer_Hover_ShadowHorizontal,
  DB_LeftSidebar_MenuItem_MenuProduktContainer_Hover_ShadowBlur,
  DB_LeftSidebar_MenuItem_MenuProduktContainer_Hover_ShadowColor,
}) => {
  const useStyles = createUseStyles({
    productCardContainer: {
      backgroundColor: `${
        SideBarLeft_ProduktContainer_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut
          ? "rgba(255,0,0,0.5)"
          : SideBarLeft_ProduktContainer_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut
          ? "rgba(255,0,0,0.3)"
          : DB_LeftSidebar_MenuProduktContainer_BackgroundColor
      }`,
      height: "100%",
      paddingTop: `${DB_LeftSidebar_MenuProduktContainer_PaddingTopBottom}px`,
      paddingBottom: `${DB_LeftSidebar_MenuProduktContainer_PaddingTopBottom}px`,
      paddingLeft: `${DB_LeftSidebar_MenuProduktContainer_PaddingLeftRight}px`,
      paddingRight: `${DB_LeftSidebar_MenuProduktContainer_PaddingLeftRight}px`,

      borderTop: `${
        SideBarLeft_ProduktContainer_Farbe_Groesse_Stil_OnMouseOverAndOut
          ? "3"
          : SideBarLeft_ProduktContainer_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
          ? "3"
          : DB_LeftSidebar_MenuProduktContainer_BorderTop
      }px
        ${
          SideBarLeft_ProduktContainer_Farbe_Groesse_Stil_OnMouseOverAndOut
            ? "solid"
            : SideBarLeft_ProduktContainer_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
            ? "dotted"
            : DB_LeftSidebar_MenuProduktContainer_BorderStyleTop
        }
        ${
          SideBarLeft_ProduktContainer_Farbe_Groesse_Stil_OnMouseOverAndOut
            ? "red"
            : SideBarLeft_ProduktContainer_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
            ? "red"
            : DB_LeftSidebar_MenuProduktContainer_BorderColorTop
        }`,
      borderBottom: `${
        SideBarLeft_ProduktContainer_Farbe_Groesse_Stil_OnMouseOverAndOut
          ? "3"
          : SideBarLeft_ProduktContainer_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
          ? "3"
          : DB_LeftSidebar_MenuProduktContainer_BorderBottom
      }px
        ${
          SideBarLeft_ProduktContainer_Farbe_Groesse_Stil_OnMouseOverAndOut
            ? "solid"
            : SideBarLeft_ProduktContainer_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
            ? "dotted"
            : DB_LeftSidebar_MenuProduktContainer_BorderStyleBottom
        }
        ${
          SideBarLeft_ProduktContainer_Farbe_Groesse_Stil_OnMouseOverAndOut
            ? "red"
            : SideBarLeft_ProduktContainer_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
            ? "red"
            : DB_LeftSidebar_MenuProduktContainer_BorderColorBottom
        }`,
      borderLeft: `${
        SideBarLeft_ProduktContainer_Farbe_Groesse_Stil_OnMouseOverAndOut
          ? "3"
          : SideBarLeft_ProduktContainer_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
          ? "3"
          : DB_LeftSidebar_MenuProduktContainer_BorderLeft
      }px
        ${
          SideBarLeft_ProduktContainer_Farbe_Groesse_Stil_OnMouseOverAndOut
            ? "solid"
            : SideBarLeft_ProduktContainer_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
            ? "dotted"
            : DB_LeftSidebar_MenuProduktContainer_BorderStyleLeft
        }
        ${
          SideBarLeft_ProduktContainer_Farbe_Groesse_Stil_OnMouseOverAndOut
            ? "red"
            : SideBarLeft_ProduktContainer_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
            ? "red"
            : DB_LeftSidebar_MenuProduktContainer_BorderColorLeft
        }`,
      borderRight: `${
        SideBarLeft_ProduktContainer_Farbe_Groesse_Stil_OnMouseOverAndOut
          ? "3"
          : SideBarLeft_ProduktContainer_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
          ? "3"
          : DB_LeftSidebar_MenuProduktContainer_BorderRight
      }px
        ${
          SideBarLeft_ProduktContainer_Farbe_Groesse_Stil_OnMouseOverAndOut
            ? "solid"
            : SideBarLeft_ProduktContainer_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
            ? "dotted"
            : DB_LeftSidebar_MenuProduktContainer_BorderStyleRight
        }
        ${
          SideBarLeft_ProduktContainer_Farbe_Groesse_Stil_OnMouseOverAndOut
            ? "red"
            : SideBarLeft_ProduktContainer_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
            ? "red"
            : DB_LeftSidebar_MenuProduktContainer_BorderColorRight
        }`,
      borderTopRightRadius: `${DB_LeftSidebar_MenuItem_MenuProduktContainer_BorderTopRightRadius}px`,
      borderTopLeftRadius: `${DB_LeftSidebar_MenuItem_MenuProduktContainer_BorderTopLeftRadius}px`,
      borderBottomRightRadius: `${DB_LeftSidebar_MenuItem_MenuProduktContainer_BorderBottomRightRadius}px`,
      borderBottomLeftRadius: `${DB_LeftSidebar_MenuItem_MenuProduktContainer_BorderBottomLeftRadius}px`,

      paddingLeft: `${DB_LeftSidebar_MenuItem_MenuProduktContainer_paddingLeft}px`,
      paddingRight: `${DB_LeftSidebar_MenuItem_MenuProduktContainer_paddingRight}px`,
      paddingTop: `${DB_LeftSidebar_MenuItem_MenuProduktContainer_paddingTop}px`,
      paddingBottom: `${DB_LeftSidebar_MenuItem_MenuProduktContainer_paddingBottom}px`,
      boxShadow: `${DB_LeftSidebar_MenuItem_MenuProduktContainer_ShadowHorizontal}px
        ${DB_LeftSidebar_MenuItem_MenuProduktContainer_ShadowVertical}px
        ${DB_LeftSidebar_MenuItem_MenuProduktContainer_ShadowBlur}px
        ${DB_LeftSidebar_MenuItem_MenuProduktContainer_ShadowColor}`,

      "&:hover": {
        backgroundColor: `${DB_LeftSidebar_MenuProduktContainer_Hover_BackgroundColor}`,
        paddingTop: `${DB_LeftSidebar_MenuProduktContainer_Hover_PaddingTopBottom}px`,
        paddingBottom: `${DB_LeftSidebar_MenuProduktContainer_Hover_PaddingTopBottom}px`,
        paddinLeft: `${DB_LeftSidebar_MenuProduktContainer_Hover_PaddingLeftRight}px`,
        paddingRight: `${DB_LeftSidebar_MenuProduktContainer_Hover_PaddingLeftRight}px`,
        borderTop: `${DB_LeftSidebar_MenuProduktContainer_Hover_BorderTop}px
            ${DB_LeftSidebar_MenuProduktContainer_Hover_BorderStyleTop}
            ${DB_LeftSidebar_MenuProduktContainer_Hover_BorderColorTop}`,
        borderBottom: `${DB_LeftSidebar_MenuProduktContainer_Hover_BorderBottom}px
            ${DB_LeftSidebar_MenuProduktContainer_Hover_BorderStyleBottom}
            ${DB_LeftSidebar_MenuProduktContainer_Hover_BorderColorBottom}`,
        borderLeft: `${DB_LeftSidebar_MenuProduktContainer_Hover_BorderLeft}px
            ${DB_LeftSidebar_MenuProduktContainer_Hover_BorderStyleLeft}
            ${DB_LeftSidebar_MenuProduktContainer_Hover_BorderColorLeft}`,
        borderRight: `${DB_LeftSidebar_MenuProduktContainer_Hover_BorderRight}px
            ${DB_LeftSidebar_MenuProduktContainer_Hover_BorderStyleRight}
            ${DB_LeftSidebar_MenuProduktContainer_Hover_BorderColorRight}`,

        borderTopRightRadius: `${DB_LeftSidebar_MenuItem_MenuProduktContainer_Hover_BorderTopRightRadius}px`,
        borderTopLeftRadius: `${DB_LeftSidebar_MenuItem_MenuProduktContainer_Hover_BorderTopLeftRadius}px`,
        borderBottomRightRadius: `${DB_LeftSidebar_MenuItem_MenuProduktContainer_Hover_BorderBottomRightRadius}px`,
        borderBottomLeftRadius: `${DB_LeftSidebar_MenuItem_MenuProduktContainer_Hover_BorderBottomLeftRadius}px`,
        paddingLeft: `${DB_LeftSidebar_MenuItem_MenuProduktContainer_Hover_paddingLeft}px`,
        paddingRight: `${DB_LeftSidebar_MenuItem_MenuProduktContainer_Hover_paddingRight}px`,
        paddingTop: `${DB_LeftSidebar_MenuItem_MenuProduktContainer_Hover_paddingTop}px`,
        paddingBottom: `${DB_LeftSidebar_MenuItem_MenuProduktContainer_Hover_paddingBottom}px`,
        boxShadow: `${DB_LeftSidebar_MenuItem_MenuProduktContainer_Hover_ShadowHorizontal}px
            ${DB_LeftSidebar_MenuItem_MenuProduktContainer_Hover_ShadowVertical}px
            ${DB_LeftSidebar_MenuItem_MenuProduktContainer_Hover_ShadowBlur}px
            ${DB_LeftSidebar_MenuItem_MenuProduktContainer_Hover_ShadowColor}`,
      },
    },
  });

  const classes = useStyles();

  return (
    <div
      key={Math.random()}
      className={`border-0 m-0 p-0 ${classes.productCardContainer}`}
    >
      {children}
    </div>
  );
};

export default ProductCardContainerTwelfe;
