import React, { useEffect, useState, useCallback } from "react";
import axios from "axios";
import { Button, Form, Modal } from "react-bootstrap";
import { toast, Zoom } from "react-toastify";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import "/node_modules/react-image-gallery/styles/css/image-gallery.css";
import TopBarStaticContainer from "./TopBarStaticContainer";
import Select from "react-select";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";

const TopBarStatic = ({
  meldenModalHandler,
  faqDetailItem,
  aboutUsShowHandler,
  faqShowHandler,
  logoActive,
  countImagePlusTwo,
  shopName,
  searchValue,
  searchHandler,
  searchPressEnter,
  isAuthState,
  signOutMessage,
  productsUserId,
  toggleSignIn,
  showTopProductsHandler,
  doMeldenReasonHandler,
  regelverstossMeldenSubmit,
  userOwnsThisSite,
  DB_fontFace_FontFamilyName,
  resetFilterHandler,
  SideBarLeft_HauptmenuTopStatic_Farbe_Groesse_Stil_Trigger,
  SideBarLeft_HauptmenuTopStatic_Farbe_Groesse_Stil_Hover_Trigger,
  SideBarLeft_HauptmenuTopStatic_HintergrundFarbe_Radius_Stil_Trigger,
  SideBarLeft_HauptmenuTopStatic_HintergrundFarbe_Radius_Stil_Hover_Trigger,
  SideBarLeft_HauptmenuTopStatic_Farbe_Groesse_Stil_OnMouseOverAndOut,
  SideBarLeft_HauptmenuTopStatic_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut,
  SideBarLeft_HauptmenuTopStatic_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut,
  SideBarLeft_HauptmenuTopStatic_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut,

  DB_fontFace_hauptMenueStatic_FontFamilyName,
  DB_fontFace_hauptMenueStatic_FontFamilySrc,
  DB_LeftSidebar_MenuItem_HauptmenuTop_FontWeight,
  DB_LeftSidebar_MenuItem_HauptmenuTop_FontColor,
  DB_LeftSidebar_MenuItem_HauptmenuTop_FontSize,
  DB_LeftSidebar_MenuItem_HauptmenuTop_BackgroundColor,
  DB_LeftSidebar_MenuItem_HauptmenuTop_TextAlign,
  DB_LeftSidebar_MenuItem_HauptmenuTop_BorderTop,
  DB_LeftSidebar_MenuItem_HauptmenuTop_BorderStyleTop,
  DB_LeftSidebar_MenuItem_HauptmenuTop_BorderColorTop,
  DB_LeftSidebar_MenuItem_HauptmenuTop_BorderBottom,
  DB_LeftSidebar_MenuItem_HauptmenuTop_BorderStyleBottom,
  DB_LeftSidebar_MenuItem_HauptmenuTop_BorderColorBottom,
  DB_LeftSidebar_MenuItem_HauptmenuTop_BorderLeft,
  DB_LeftSidebar_MenuItem_HauptmenuTop_BorderStyleLeft,
  DB_LeftSidebar_MenuItem_HauptmenuTop_BorderColorLeft,
  DB_LeftSidebar_MenuItem_HauptmenuTop_BorderRight,
  DB_LeftSidebar_MenuItem_HauptmenuTop_BorderStyleRight,
  DB_LeftSidebar_MenuItem_HauptmenuTop_BorderColorRight,
  DB_LeftSidebar_MenuItem_HauptmenuTop_BorderTopRightRadius,
  DB_LeftSidebar_MenuItem_HauptmenuTop_BorderTopLeftRadius,
  DB_LeftSidebar_MenuItem_HauptmenuTop_BorderBottomRightRadius,
  DB_LeftSidebar_MenuItem_HauptmenuTop_BorderBottomLeftRadius,
  DB_LeftSidebar_MenuItem_HauptmenuTop_marginLeftRight,
  DB_LeftSidebar_MenuItem_HauptmenuTop_marginTopBottom,
  DB_LeftSidebar_MenuItem_HauptmenuTop_paddingLeftRight,
  DB_LeftSidebar_MenuItem_HauptmenuTop_paddingTopBottom,
  DB_LeftSidebar_MenuItem_HauptmenuTop_letterSpacing,
  DB_LeftSidebar_MenuItem_HauptmenuTop_ShadowHorizontal,
  DB_LeftSidebar_MenuItem_HauptmenuTop_ShadowVertical,
  DB_LeftSidebar_MenuItem_HauptmenuTop_ShadowBlur,
  DB_LeftSidebar_MenuItem_HauptmenuTop_ShadowColor,
  DB_LeftSidebar_MenuItem_HauptmenuTop_TextDecoration,
  DB_LeftSidebar_MenuItem_HauptmenuTop_TextDecorationStyle,

  DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_FontWeight,
  DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_FontColor,
  DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_FontSize,
  DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BackgroundColor,
  DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_TextAlign,
  DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderTop,
  DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderStyleTop,
  DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderColorTop,
  DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderBottom,
  DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderStyleBottom,
  DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderColorBottom,
  DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderLeft,
  DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderStyleLeft,
  DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderColorLeft,
  DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderRight,
  DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderStyleRight,
  DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderColorRight,
  DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderTopRightRadius,
  DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderTopLeftRadius,
  DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderBottomRightRadius,
  DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderBottomLeftRadius,
  DB_LeftSidebar_MenuItem_HauptmenuTop_marginLeftRight_Hover,
  DB_LeftSidebar_MenuItem_HauptmenuTop_marginTopBottom_Hover,
  DB_LeftSidebar_MenuItem_HauptmenuTop_paddingLeftRight_Hover,
  DB_LeftSidebar_MenuItem_HauptmenuTop_paddingTopBottom_Hover,
  DB_LeftSidebar_MenuItem_HauptmenuTop_letterSpacing_Hover,
  DB_LeftSidebar_MenuItem_HauptmenuTop_ShadowHorizontal_Hover,
  DB_LeftSidebar_MenuItem_HauptmenuTop_ShadowVertical_Hover,
  DB_LeftSidebar_MenuItem_HauptmenuTop_ShadowBlur_Hover,
  DB_LeftSidebar_MenuItem_HauptmenuTop_ShadowColor_Hover,
  DB_LeftSidebar_MenuItem_HauptmenuTop_TextDecoration_Hover,
  DB_LeftSidebar_MenuItem_HauptmenuTop_TextDecorationStyle_Hover,

  DB_LeftSidebar_MenuItem_MenuContainer_Logo_DDpaddingLeft,
  DB_LeftSidebar_MenuItem_MenuContainer_Logo_DDpaddingRight,
  DB_LeftSidebar_MenuItem_MenuContainer_Logo_DDpaddingTop,
  DB_LeftSidebar_MenuItem_MenuContainer_Logo_DDpaddingBottom,
  DB_LeftSidebar_MenuItem_MenuContainer_Logo_DDShadowHorizontal,
  DB_LeftSidebar_MenuItem_MenuContainer_Logo_DDShadowVertical,
  DB_LeftSidebar_MenuItem_MenuContainer_Logo_DDShadowBlur,
  DB_LeftSidebar_MenuItem_MenuContainer_Logo_DDShadowColor,
  DB_LeftSidebar_MenuContainer_Logo_DDBackgroundColor,
  DB_LeftSidebar_MenuItem_MenuContainer_Logo_DDHeight,

  SideBarLeft_ContainerTopXYZ_Farbe_Groesse_Stil_OnMouseOverAndOut,
  SideBarLeft_ContainerTopXYZ_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut,
  SideBarLeft_ContainerTopXYZ_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut,
  SideBarLeft_ContainerTopXYZ_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut,

  DB_LeftSidebar_MenuContainerXYZ_BackgroundColor,
  DB_LeftSidebar_MenuContainerXYZ_PaddingTopBottom,
  DB_LeftSidebar_MenuContainerXYZ_PaddingLeftRight,
  DB_LeftSidebar_MenuContainerXYZ_BorderTop,
  DB_LeftSidebar_MenuContainerXYZ_BorderStyleTop,
  DB_LeftSidebar_MenuContainerXYZ_BorderColorTop,
  DB_LeftSidebar_MenuContainerXYZ_BorderBottom,
  DB_LeftSidebar_MenuContainerXYZ_BorderStyleBottom,
  DB_LeftSidebar_MenuContainerXYZ_BorderColorBottom,
  DB_LeftSidebar_MenuContainerXYZ_BorderLeft,
  DB_LeftSidebar_MenuContainerXYZ_BorderStyleLeft,
  DB_LeftSidebar_MenuContainerXYZ_BorderColorLeft,
  DB_LeftSidebar_MenuContainerXYZ_BorderRight,
  DB_LeftSidebar_MenuContainerXYZ_BorderStyleRight,
  DB_LeftSidebar_MenuContainerXYZ_BorderColorRight,
  DB_LeftSidebar_MenuItem_MenuContainerXYZ_BorderTopRightRadius,
  DB_LeftSidebar_MenuItem_MenuContainerXYZ_BorderTopLeftRadius,
  DB_LeftSidebar_MenuItem_MenuContainerXYZ_BorderBottomRightRadius,
  DB_LeftSidebar_MenuItem_MenuContainerXYZ_BorderBottomLeftRadius,
  DB_LeftSidebar_MenuItem_MenuContainerXYZ_paddingLeft,
  DB_LeftSidebar_MenuItem_MenuContainerXYZ_paddingRight,
  DB_LeftSidebar_MenuItem_MenuContainerXYZ_paddingTop,
  DB_LeftSidebar_MenuItem_MenuContainerXYZ_paddingBottom,
  DB_LeftSidebar_MenuItem_MenuContainerXYZ_ShadowHorizontal,
  DB_LeftSidebar_MenuItem_MenuContainerXYZ_ShadowVertical,
  DB_LeftSidebar_MenuItem_MenuContainerXYZ_ShadowBlur,
  DB_LeftSidebar_MenuItem_MenuContainerXYZ_ShadowColor,

  DB_LeftSidebar_MenuContainerXYZ_Hover_BackgroundColor,
  DB_LeftSidebar_MenuContainerXYZ_Hover_PaddingTopBottom,
  DB_LeftSidebar_MenuContainerXYZ_Hover_PaddingLeftRight,
  DB_LeftSidebar_MenuContainerXYZ_Hover_BorderTop,
  DB_LeftSidebar_MenuContainerXYZ_Hover_BorderStyleTop,
  DB_LeftSidebar_MenuContainerXYZ_Hover_BorderColorTop,
  DB_LeftSidebar_MenuContainerXYZ_Hover_BorderBottom,
  DB_LeftSidebar_MenuContainerXYZ_Hover_BorderStyleBottom,
  DB_LeftSidebar_MenuContainerXYZ_Hover_BorderColorBottom,
  DB_LeftSidebar_MenuContainerXYZ_Hover_BorderLeft,
  DB_LeftSidebar_MenuContainerXYZ_Hover_BorderStyleLeft,
  DB_LeftSidebar_MenuContainerXYZ_Hover_BorderColorLeft,
  DB_LeftSidebar_MenuContainerXYZ_Hover_BorderRight,
  DB_LeftSidebar_MenuContainerXYZ_Hover_BorderStyleRight,
  DB_LeftSidebar_MenuContainerXYZ_Hover_BorderColorRight,
  DB_LeftSidebar_MenuItem_MenuContainerXYZ_Hover_BorderTopRightRadius,
  DB_LeftSidebar_MenuItem_MenuContainerXYZ_Hover_BorderTopLeftRadius,
  DB_LeftSidebar_MenuItem_MenuContainerXYZ_Hover_BorderBottomRightRadius,
  DB_LeftSidebar_MenuItem_MenuContainerXYZ_Hover_BorderBottomLeftRadius,
  DB_LeftSidebar_MenuItem_MenuContainerXYZ_Hover_paddingLeft,
  DB_LeftSidebar_MenuItem_MenuContainerXYZ_Hover_paddingRight,
  DB_LeftSidebar_MenuItem_MenuContainerXYZ_Hover_paddingTop,
  DB_LeftSidebar_MenuItem_MenuContainerXYZ_Hover_paddingBottom,
  DB_LeftSidebar_MenuItem_MenuContainerXYZ_Hover_ShadowHorizontal,
  DB_LeftSidebar_MenuItem_MenuContainerXYZ_Hover_ShadowVertical,
  DB_LeftSidebar_MenuItem_MenuContainerXYZ_Hover_ShadowBlur,
  DB_LeftSidebar_MenuItem_MenuContainerXYZ_Hover_ShadowColor,
}) => {
  const { t, i18n } = useTranslation();
  const [DB_TopBarLogoImageSrc, setDB_TopBarLogoImageSrc] = useState("");
  const [meldenReason, setMeldenReason] = useState("");
  const [benutzerMeldenModal, setBenutzerMeldenModal] = useState(false);
  const [meldeBeschreibung, setMeldeBeschreibung] = useState("");
  const onChangeMeldebeschreibung = (e) => {
    setMeldeBeschreibung(e.target.value);
  };

  useEffect(() => {
    axios({
      method: "post",
      url: `${process.env.REACT_APP_PUBLIC_SERVER_URL}/shop/get-design-logo-image`,
      data: {
        shopName,
      },
    })
      .then((uploadedResult) => {
        if (uploadedResult.status === 201) {
        } else {
          setDB_TopBarLogoImageSrc(
            `${process.env.REACT_APP_PUBLIC_SERVER_URL}` +
              "/" +
              uploadedResult.data.logo_image +
              "?" +
              new Date().getMilliseconds()
          );
        }
      })
      .catch((err) => {
        toast.error(
          "Keine Verbindung zum Server - Logo konnte nicht vom Server heruntergeladen werden",
          {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            transition: Zoom,
          }
        );
      });
  }, [countImagePlusTwo]);

  const useStyles = createUseStyles({
    topNavigationStatic: {
      position: `relative`,
      display: `flex`,
      minHeight: `50px`,
      overflow: `hidden`,
      flexWrap: `wrap`,
      listStyle: `none`,
      padding: `0`,
      width: `100%`,
    },
    topNavigationNavItemSearchStaticSpecial: {
      width: `80%`,
      backgroundColor: `${
        SideBarLeft_HauptmenuTopStatic_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut
          ? "rgba(255,0,0,0.5)"
          : SideBarLeft_HauptmenuTopStatic_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut
          ? "rgba(255,0,0,0.3)"
          : DB_LeftSidebar_MenuItem_HauptmenuTop_BackgroundColor
      }`,
      padding: `0px 0px`,
      fontFamily: `${DB_fontFace_hauptMenueStatic_FontFamilyName}`,
      fontWeight: `${DB_LeftSidebar_MenuItem_HauptmenuTop_FontWeight}`,
      fontSize: `${DB_LeftSidebar_MenuItem_HauptmenuTop_FontSize}px`,
      color: `${DB_LeftSidebar_MenuItem_HauptmenuTop_FontColor}`,
      textAlign: `center`,
      marginLeft: `auto`,
      marginRight: `auto`,
      marginTop: `5px`,
      marginBottom: `0px`,
      paddingLeft: `${DB_LeftSidebar_MenuItem_HauptmenuTop_paddingLeftRight}px`,
      paddingRight: `${DB_LeftSidebar_MenuItem_HauptmenuTop_paddingLeftRight}px`,
      paddingTop: `${DB_LeftSidebar_MenuItem_HauptmenuTop_paddingTopBottom}px`,
      paddingBottom: `${DB_LeftSidebar_MenuItem_HauptmenuTop_paddingTopBottom}px`,
      borderTopRightRadius: `${DB_LeftSidebar_MenuItem_HauptmenuTop_BorderTopRightRadius}px`,
      borderTopLeftRadius: `${DB_LeftSidebar_MenuItem_HauptmenuTop_BorderTopLeftRadius}px`,
      borderBottomRightRadius: `${DB_LeftSidebar_MenuItem_HauptmenuTop_BorderBottomRightRadius}px`,
      borderBottomLeftRadius: `${DB_LeftSidebar_MenuItem_HauptmenuTop_BorderBottomLeftRadius}px`,
      borderTop: `${
        SideBarLeft_HauptmenuTopStatic_Farbe_Groesse_Stil_OnMouseOverAndOut
          ? "3"
          : SideBarLeft_HauptmenuTopStatic_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
          ? "3"
          : DB_LeftSidebar_MenuItem_HauptmenuTop_BorderTop
      }px
        ${
          SideBarLeft_HauptmenuTopStatic_Farbe_Groesse_Stil_OnMouseOverAndOut
            ? "solid"
            : SideBarLeft_HauptmenuTopStatic_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
            ? "dotted"
            : DB_LeftSidebar_MenuItem_HauptmenuTop_BorderStyleTop
        }
        ${
          SideBarLeft_HauptmenuTopStatic_Farbe_Groesse_Stil_OnMouseOverAndOut
            ? "red"
            : SideBarLeft_HauptmenuTopStatic_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
            ? "red"
            : DB_LeftSidebar_MenuItem_HauptmenuTop_BorderColorTop
        }`,
      borderBottom: `${
        SideBarLeft_HauptmenuTopStatic_Farbe_Groesse_Stil_OnMouseOverAndOut
          ? "3"
          : SideBarLeft_HauptmenuTopStatic_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
          ? "3"
          : DB_LeftSidebar_MenuItem_HauptmenuTop_BorderBottom
      }px
        ${
          SideBarLeft_HauptmenuTopStatic_Farbe_Groesse_Stil_OnMouseOverAndOut
            ? "solid"
            : SideBarLeft_HauptmenuTopStatic_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
            ? "dotted"
            : DB_LeftSidebar_MenuItem_HauptmenuTop_BorderStyleBottom
        }
        ${
          SideBarLeft_HauptmenuTopStatic_Farbe_Groesse_Stil_OnMouseOverAndOut
            ? "red"
            : SideBarLeft_HauptmenuTopStatic_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
            ? "red"
            : DB_LeftSidebar_MenuItem_HauptmenuTop_BorderColorBottom
        }`,
      borderLeft: `${
        SideBarLeft_HauptmenuTopStatic_Farbe_Groesse_Stil_OnMouseOverAndOut
          ? "3"
          : SideBarLeft_HauptmenuTopStatic_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
          ? "3"
          : DB_LeftSidebar_MenuItem_HauptmenuTop_BorderLeft
      }px
        ${
          SideBarLeft_HauptmenuTopStatic_Farbe_Groesse_Stil_OnMouseOverAndOut
            ? "solid"
            : SideBarLeft_HauptmenuTopStatic_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
            ? "dotted"
            : DB_LeftSidebar_MenuItem_HauptmenuTop_BorderStyleLeft
        }
        ${
          SideBarLeft_HauptmenuTopStatic_Farbe_Groesse_Stil_OnMouseOverAndOut
            ? "red"
            : SideBarLeft_HauptmenuTopStatic_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
            ? "red"
            : DB_LeftSidebar_MenuItem_HauptmenuTop_BorderColorLeft
        }`,
      borderRight: `${
        SideBarLeft_HauptmenuTopStatic_Farbe_Groesse_Stil_OnMouseOverAndOut
          ? "3"
          : SideBarLeft_HauptmenuTopStatic_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
          ? "3"
          : DB_LeftSidebar_MenuItem_HauptmenuTop_BorderRight
      }px
        ${
          SideBarLeft_HauptmenuTopStatic_Farbe_Groesse_Stil_OnMouseOverAndOut
            ? "solid"
            : SideBarLeft_HauptmenuTopStatic_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
            ? "dotted"
            : DB_LeftSidebar_MenuItem_HauptmenuTop_BorderStyleRight
        }
        ${
          SideBarLeft_HauptmenuTopStatic_Farbe_Groesse_Stil_OnMouseOverAndOut
            ? "red"
            : SideBarLeft_HauptmenuTopStatic_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
            ? "red"
            : DB_LeftSidebar_MenuItem_HauptmenuTop_BorderColorRight
        }`,

      "&:focus": {
        backgroundColor: `${DB_LeftSidebar_MenuItem_HauptmenuTop_BackgroundColor}`,
        color: `${DB_LeftSidebar_MenuItem_HauptmenuTop_FontColor}`,
      },
      boxShadow: `none`,
      "&::placeholder": {
        color: "rgba(255, 255, 255, 0.5)",
        opacity: "1",
      },

      "&::-ms-input-placeholder": {
        /* Internet Explorer 10-11 */
        color: "rgba(255, 255, 255, 0.5)",
      },

      "&::-ms-input-placeholder": {
        /* Microsoft Edge */
        color: "rgba(255, 255, 255, 0.5)",
      },
    },
    topNavigationNavItemStatic: {
      fontFamily: `${DB_fontFace_hauptMenueStatic_FontFamilyName} `,
      fontWeight: `${DB_LeftSidebar_MenuItem_HauptmenuTop_FontWeight}`,
      color: `${DB_LeftSidebar_MenuItem_HauptmenuTop_FontColor}`,
      fontSize: `${DB_LeftSidebar_MenuItem_HauptmenuTop_FontSize}px`,
      backgroundColor: `${
        SideBarLeft_HauptmenuTopStatic_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut
          ? "rgba(255,0,0,0.5)"
          : SideBarLeft_HauptmenuTopStatic_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut
          ? "rgba(255,0,0,0.3)"
          : DB_LeftSidebar_MenuItem_HauptmenuTop_BackgroundColor
      }`,
      textAlign: `${DB_LeftSidebar_MenuItem_HauptmenuTop_TextAlign}`,
      borderTop: `${
        SideBarLeft_HauptmenuTopStatic_Farbe_Groesse_Stil_OnMouseOverAndOut
          ? "3"
          : SideBarLeft_HauptmenuTopStatic_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
          ? "3"
          : DB_LeftSidebar_MenuItem_HauptmenuTop_BorderTop
      }px ${
        SideBarLeft_HauptmenuTopStatic_Farbe_Groesse_Stil_OnMouseOverAndOut
          ? "solid"
          : SideBarLeft_HauptmenuTopStatic_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
          ? "dotted"
          : DB_LeftSidebar_MenuItem_HauptmenuTop_BorderStyleTop
      }
      ${
        SideBarLeft_HauptmenuTopStatic_Farbe_Groesse_Stil_OnMouseOverAndOut
          ? "red"
          : SideBarLeft_HauptmenuTopStatic_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
          ? "red"
          : DB_LeftSidebar_MenuItem_HauptmenuTop_BorderColorTop
      }`,
      borderBottom: `${
        SideBarLeft_HauptmenuTopStatic_Farbe_Groesse_Stil_OnMouseOverAndOut
          ? "3"
          : SideBarLeft_HauptmenuTopStatic_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
          ? "3"
          : DB_LeftSidebar_MenuItem_HauptmenuTop_BorderBottom
      }px
      ${
        SideBarLeft_HauptmenuTopStatic_Farbe_Groesse_Stil_OnMouseOverAndOut
          ? "solid"
          : SideBarLeft_HauptmenuTopStatic_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
          ? "dotted"
          : DB_LeftSidebar_MenuItem_HauptmenuTop_BorderStyleBottom
      }
      ${
        SideBarLeft_HauptmenuTopStatic_Farbe_Groesse_Stil_OnMouseOverAndOut
          ? "red"
          : SideBarLeft_HauptmenuTopStatic_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
          ? "red"
          : DB_LeftSidebar_MenuItem_HauptmenuTop_BorderColorBottom
      } !important;
    border-left: ${
      SideBarLeft_HauptmenuTopStatic_Farbe_Groesse_Stil_OnMouseOverAndOut
        ? "3"
        : SideBarLeft_HauptmenuTopStatic_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
        ? "3"
        : DB_LeftSidebar_MenuItem_HauptmenuTop_BorderLeft
    }px
      ${
        SideBarLeft_HauptmenuTopStatic_Farbe_Groesse_Stil_OnMouseOverAndOut
          ? "solid"
          : SideBarLeft_HauptmenuTopStatic_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
          ? "dotted"
          : DB_LeftSidebar_MenuItem_HauptmenuTop_BorderStyleLeft
      }
      ${
        SideBarLeft_HauptmenuTopStatic_Farbe_Groesse_Stil_OnMouseOverAndOut
          ? "red"
          : SideBarLeft_HauptmenuTopStatic_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
          ? "red"
          : DB_LeftSidebar_MenuItem_HauptmenuTop_BorderColorLeft
      }`,
      borderRight: `${
        SideBarLeft_HauptmenuTopStatic_Farbe_Groesse_Stil_OnMouseOverAndOut
          ? "3"
          : SideBarLeft_HauptmenuTopStatic_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
          ? "3"
          : DB_LeftSidebar_MenuItem_HauptmenuTop_BorderRight
      }px
      ${
        SideBarLeft_HauptmenuTopStatic_Farbe_Groesse_Stil_OnMouseOverAndOut
          ? "solid"
          : SideBarLeft_HauptmenuTopStatic_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
          ? "dotted"
          : DB_LeftSidebar_MenuItem_HauptmenuTop_BorderStyleRight
      }
      ${
        SideBarLeft_HauptmenuTopStatic_Farbe_Groesse_Stil_OnMouseOverAndOut
          ? "red"
          : SideBarLeft_HauptmenuTopStatic_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
          ? "red"
          : DB_LeftSidebar_MenuItem_HauptmenuTop_BorderColorRight
      }`,
      borderTopRightRadius: `${DB_LeftSidebar_MenuItem_HauptmenuTop_BorderTopRightRadius}px`,
      borderTopLeftRadius: `${DB_LeftSidebar_MenuItem_HauptmenuTop_BorderTopLeftRadius}px`,
      borderBottomRightRadius: `${DB_LeftSidebar_MenuItem_HauptmenuTop_BorderBottomRightRadius}px`,
      borderBottomLeftRadius: `${DB_LeftSidebar_MenuItem_HauptmenuTop_BorderBottomLeftRadius}px`,
      marginLeft: `${DB_LeftSidebar_MenuItem_HauptmenuTop_marginLeftRight}px`,
      marginRight: `${DB_LeftSidebar_MenuItem_HauptmenuTop_marginLeftRight}px`,

      marginBottom: `${DB_LeftSidebar_MenuItem_HauptmenuTop_marginTopBottom}px`,
      paddingLeft: `${DB_LeftSidebar_MenuItem_HauptmenuTop_paddingLeftRight}px`,
      paddingRight: `${DB_LeftSidebar_MenuItem_HauptmenuTop_paddingLeftRight}px`,
      paddingTop: `${DB_LeftSidebar_MenuItem_HauptmenuTop_paddingTopBottom}px`,
      paddingBottom: `${DB_LeftSidebar_MenuItem_HauptmenuTop_paddingTopBottom}px`,
      letterSpacing: `${DB_LeftSidebar_MenuItem_HauptmenuTop_letterSpacing}px`,
      textShadow: `${DB_LeftSidebar_MenuItem_HauptmenuTop_ShadowHorizontal}px
    ${DB_LeftSidebar_MenuItem_HauptmenuTop_ShadowVertical}px
      ${DB_LeftSidebar_MenuItem_HauptmenuTop_ShadowBlur}px
      ${DB_LeftSidebar_MenuItem_HauptmenuTop_ShadowColor}`,
      textDecoration: `${DB_LeftSidebar_MenuItem_HauptmenuTop_TextDecoration}
      ${DB_LeftSidebar_MenuItem_HauptmenuTop_TextDecorationStyle}`,

      "&:hover": {
        fontWeight: `${DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_FontWeight}`,
        color: `${DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_FontColor}`,
        fontSize: `${DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_FontSize}px`,
        backgroundColor: `${DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BackgroundColor}`,
        textAlign: `${DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_TextAlign}`,
        borderTop: `${DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderTop}px
              ${DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderStyleTop}
              ${DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderColorTop}`,
        borderBottom: `${DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderBottom}px
              ${DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderStyleBottom}
              ${DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderColorBottom}`,
        borderLeft: `${DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderLeft}px
              ${DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderStyleLeft}
              ${DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderColorLeft}`,
        borderRight: `${DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderRight}px
              ${DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderStyleRight}
              ${DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderColorRight}`,
        borderTopRightRadius: `${DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderTopRightRadius}px`,
        borderTopLeftRadius: `${DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderTopLeftRadius}px`,
        borderBottomRightRadius: `${DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderBottomRightRadius}px`,
        borderBottomLeftRadius: `${DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderBottomLeftRadius}px`,

        marginLeft: `${DB_LeftSidebar_MenuItem_HauptmenuTop_marginLeftRight_Hover}px`,
        marginRight: `${DB_LeftSidebar_MenuItem_HauptmenuTop_marginLeftRight_Hover}px`,

        marginBottom: `${DB_LeftSidebar_MenuItem_HauptmenuTop_marginTopBottom_Hover}px`,
        paddingLeft: `${DB_LeftSidebar_MenuItem_HauptmenuTop_paddingLeftRight_Hover}px`,
        paddingRight: `${DB_LeftSidebar_MenuItem_HauptmenuTop_paddingLeftRight_Hover}px`,
        paddingTop: `${DB_LeftSidebar_MenuItem_HauptmenuTop_paddingTopBottom_Hover}px`,
        paddingBottom: `${DB_LeftSidebar_MenuItem_HauptmenuTop_paddingTopBottom_Hover}px`,
        letterSpacing: `${DB_LeftSidebar_MenuItem_HauptmenuTop_letterSpacing_Hover}px`,
        textShadow: `${DB_LeftSidebar_MenuItem_HauptmenuTop_ShadowHorizontal_Hover}px
              ${DB_LeftSidebar_MenuItem_HauptmenuTop_ShadowVertical_Hover}px
              ${DB_LeftSidebar_MenuItem_HauptmenuTop_ShadowBlur_Hover}px
              ${DB_LeftSidebar_MenuItem_HauptmenuTop_ShadowColor_Hover}`,
        textDecoration: `${DB_LeftSidebar_MenuItem_HauptmenuTop_TextDecoration_Hover}
              ${DB_LeftSidebar_MenuItem_HauptmenuTop_TextDecorationStyle_Hover}`,
      },
    },
    topNavigationNavItemStaticBlock: {
      display: `block`,
      width: `100%`,
      marginTop: `5px`,
    },
    theLogo: {
      height: "100px",
      backgroundColor: "rgba(0,0,0,0)",
      paddingLeft: "0px",
      paddingRight: "0px",
      paddingTop: "0px",
      paddingBottom: "0px",
      boxShadow: "0px 0px 0px rgba(0,0,0,0)",
      cursor: "pointer",
    },
  });

  const classes = useStyles();

  return (
    <>
      <Container
        style={{
          padding: "0px",
          paddingTop: "0px",
          display: "block",
          marginBottom: "12px",
        }}
      >
        <TopBarStaticContainer
          SideBarLeft_ContainerTopXYZ_Farbe_Groesse_Stil_OnMouseOverAndOut={
            SideBarLeft_ContainerTopXYZ_Farbe_Groesse_Stil_OnMouseOverAndOut
          }
          SideBarLeft_ContainerTopXYZ_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut={
            SideBarLeft_ContainerTopXYZ_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
          }
          SideBarLeft_ContainerTopXYZ_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut={
            SideBarLeft_ContainerTopXYZ_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut
          }
          SideBarLeft_ContainerTopXYZ_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut={
            SideBarLeft_ContainerTopXYZ_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut
          }
          DB_LeftSidebar_MenuContainerXYZ_BackgroundColor={
            DB_LeftSidebar_MenuContainerXYZ_BackgroundColor
          }
          DB_LeftSidebar_MenuContainerXYZ_PaddingTopBottom={
            DB_LeftSidebar_MenuContainerXYZ_PaddingTopBottom
          }
          DB_LeftSidebar_MenuContainerXYZ_PaddingLeftRight={
            DB_LeftSidebar_MenuContainerXYZ_PaddingLeftRight
          }
          DB_LeftSidebar_MenuContainerXYZ_BorderTop={
            DB_LeftSidebar_MenuContainerXYZ_BorderTop
          }
          DB_LeftSidebar_MenuContainerXYZ_BorderStyleTop={
            DB_LeftSidebar_MenuContainerXYZ_BorderStyleTop
          }
          DB_LeftSidebar_MenuContainerXYZ_BorderColorTop={
            DB_LeftSidebar_MenuContainerXYZ_BorderColorTop
          }
          DB_LeftSidebar_MenuContainerXYZ_BorderBottom={
            DB_LeftSidebar_MenuContainerXYZ_BorderBottom
          }
          DB_LeftSidebar_MenuContainerXYZ_BorderStyleBottom={
            DB_LeftSidebar_MenuContainerXYZ_BorderStyleBottom
          }
          DB_LeftSidebar_MenuContainerXYZ_BorderColorBottom={
            DB_LeftSidebar_MenuContainerXYZ_BorderColorBottom
          }
          DB_LeftSidebar_MenuContainerXYZ_BorderLeft={
            DB_LeftSidebar_MenuContainerXYZ_BorderLeft
          }
          DB_LeftSidebar_MenuContainerXYZ_BorderStyleLeft={
            DB_LeftSidebar_MenuContainerXYZ_BorderStyleLeft
          }
          DB_LeftSidebar_MenuContainerXYZ_BorderColorLeft={
            DB_LeftSidebar_MenuContainerXYZ_BorderColorLeft
          }
          DB_LeftSidebar_MenuContainerXYZ_BorderRight={
            DB_LeftSidebar_MenuContainerXYZ_BorderRight
          }
          DB_LeftSidebar_MenuContainerXYZ_BorderStyleRight={
            DB_LeftSidebar_MenuContainerXYZ_BorderStyleRight
          }
          DB_LeftSidebar_MenuContainerXYZ_BorderColorRight={
            DB_LeftSidebar_MenuContainerXYZ_BorderColorRight
          }
          DB_LeftSidebar_MenuItem_MenuContainerXYZ_BorderTopRightRadius={
            DB_LeftSidebar_MenuItem_MenuContainerXYZ_BorderTopRightRadius
          }
          DB_LeftSidebar_MenuItem_MenuContainerXYZ_BorderTopLeftRadius={
            DB_LeftSidebar_MenuItem_MenuContainerXYZ_BorderTopLeftRadius
          }
          DB_LeftSidebar_MenuItem_MenuContainerXYZ_BorderBottomRightRadius={
            DB_LeftSidebar_MenuItem_MenuContainerXYZ_BorderBottomRightRadius
          }
          DB_LeftSidebar_MenuItem_MenuContainerXYZ_BorderBottomLeftRadius={
            DB_LeftSidebar_MenuItem_MenuContainerXYZ_BorderBottomLeftRadius
          }
          DB_LeftSidebar_MenuItem_MenuContainerXYZ_paddingLeft={
            DB_LeftSidebar_MenuItem_MenuContainerXYZ_paddingLeft
          }
          DB_LeftSidebar_MenuItem_MenuContainerXYZ_paddingRight={
            DB_LeftSidebar_MenuItem_MenuContainerXYZ_paddingRight
          }
          DB_LeftSidebar_MenuItem_MenuContainerXYZ_paddingTop={
            DB_LeftSidebar_MenuItem_MenuContainerXYZ_paddingTop
          }
          DB_LeftSidebar_MenuItem_MenuContainerXYZ_paddingBottom={
            DB_LeftSidebar_MenuItem_MenuContainerXYZ_paddingBottom
          }
          DB_LeftSidebar_MenuItem_MenuContainerXYZ_ShadowHorizontal={
            DB_LeftSidebar_MenuItem_MenuContainerXYZ_ShadowHorizontal
          }
          DB_LeftSidebar_MenuItem_MenuContainerXYZ_ShadowVertical={
            DB_LeftSidebar_MenuItem_MenuContainerXYZ_ShadowVertical
          }
          DB_LeftSidebar_MenuItem_MenuContainerXYZ_ShadowBlur={
            DB_LeftSidebar_MenuItem_MenuContainerXYZ_ShadowBlur
          }
          DB_LeftSidebar_MenuItem_MenuContainerXYZ_ShadowColor={
            DB_LeftSidebar_MenuItem_MenuContainerXYZ_ShadowColor
          }
          DB_LeftSidebar_MenuContainerXYZ_Hover_BackgroundColor={
            DB_LeftSidebar_MenuContainerXYZ_Hover_BackgroundColor
          }
          DB_LeftSidebar_MenuContainerXYZ_Hover_PaddingTopBottom={
            DB_LeftSidebar_MenuContainerXYZ_Hover_PaddingTopBottom
          }
          DB_LeftSidebar_MenuContainerXYZ_Hover_PaddingLeftRight={
            DB_LeftSidebar_MenuContainerXYZ_Hover_PaddingLeftRight
          }
          DB_LeftSidebar_MenuContainerXYZ_Hover_BorderTop={
            DB_LeftSidebar_MenuContainerXYZ_Hover_BorderTop
          }
          DB_LeftSidebar_MenuContainerXYZ_Hover_BorderStyleTop={
            DB_LeftSidebar_MenuContainerXYZ_Hover_BorderStyleTop
          }
          DB_LeftSidebar_MenuContainerXYZ_Hover_BorderColorTop={
            DB_LeftSidebar_MenuContainerXYZ_Hover_BorderColorTop
          }
          DB_LeftSidebar_MenuContainerXYZ_Hover_BorderBottom={
            DB_LeftSidebar_MenuContainerXYZ_Hover_BorderBottom
          }
          DB_LeftSidebar_MenuContainerXYZ_Hover_BorderStyleBottom={
            DB_LeftSidebar_MenuContainerXYZ_Hover_BorderStyleBottom
          }
          DB_LeftSidebar_MenuContainerXYZ_Hover_BorderColorBottom={
            DB_LeftSidebar_MenuContainerXYZ_Hover_BorderColorBottom
          }
          DB_LeftSidebar_MenuContainerXYZ_Hover_BorderLeft={
            DB_LeftSidebar_MenuContainerXYZ_Hover_BorderLeft
          }
          DB_LeftSidebar_MenuContainerXYZ_Hover_BorderStyleLeft={
            DB_LeftSidebar_MenuContainerXYZ_Hover_BorderStyleLeft
          }
          DB_LeftSidebar_MenuContainerXYZ_Hover_BorderColorLeft={
            DB_LeftSidebar_MenuContainerXYZ_Hover_BorderColorLeft
          }
          DB_LeftSidebar_MenuContainerXYZ_Hover_BorderRight={
            DB_LeftSidebar_MenuContainerXYZ_Hover_BorderRight
          }
          DB_LeftSidebar_MenuContainerXYZ_Hover_BorderStyleRight={
            DB_LeftSidebar_MenuContainerXYZ_Hover_BorderStyleRight
          }
          DB_LeftSidebar_MenuContainerXYZ_Hover_BorderColorRight={
            DB_LeftSidebar_MenuContainerXYZ_Hover_BorderColorRight
          }
          DB_LeftSidebar_MenuItem_MenuContainerXYZ_Hover_BorderTopRightRadius={
            DB_LeftSidebar_MenuItem_MenuContainerXYZ_Hover_BorderTopRightRadius
          }
          DB_LeftSidebar_MenuItem_MenuContainerXYZ_Hover_BorderTopLeftRadius={
            DB_LeftSidebar_MenuItem_MenuContainerXYZ_Hover_BorderTopLeftRadius
          }
          DB_LeftSidebar_MenuItem_MenuContainerXYZ_Hover_BorderBottomRightRadius={
            DB_LeftSidebar_MenuItem_MenuContainerXYZ_Hover_BorderBottomRightRadius
          }
          DB_LeftSidebar_MenuItem_MenuContainerXYZ_Hover_BorderBottomLeftRadius={
            DB_LeftSidebar_MenuItem_MenuContainerXYZ_Hover_BorderBottomLeftRadius
          }
          DB_LeftSidebar_MenuItem_MenuContainerXYZ_Hover_paddingLeft={
            DB_LeftSidebar_MenuItem_MenuContainerXYZ_Hover_paddingLeft
          }
          DB_LeftSidebar_MenuItem_MenuContainerXYZ_Hover_paddingRight={
            DB_LeftSidebar_MenuItem_MenuContainerXYZ_Hover_paddingRight
          }
          DB_LeftSidebar_MenuItem_MenuContainerXYZ_Hover_paddingTop={
            DB_LeftSidebar_MenuItem_MenuContainerXYZ_Hover_paddingTop
          }
          DB_LeftSidebar_MenuItem_MenuContainerXYZ_Hover_paddingBottom={
            DB_LeftSidebar_MenuItem_MenuContainerXYZ_Hover_paddingBottom
          }
          DB_LeftSidebar_MenuItem_MenuContainerXYZ_Hover_ShadowHorizontal={
            DB_LeftSidebar_MenuItem_MenuContainerXYZ_Hover_ShadowHorizontal
          }
          DB_LeftSidebar_MenuItem_MenuContainerXYZ_Hover_ShadowVertical={
            DB_LeftSidebar_MenuItem_MenuContainerXYZ_Hover_ShadowVertical
          }
          DB_LeftSidebar_MenuItem_MenuContainerXYZ_Hover_ShadowBlur={
            DB_LeftSidebar_MenuItem_MenuContainerXYZ_Hover_ShadowBlur
          }
          DB_LeftSidebar_MenuItem_MenuContainerXYZ_Hover_ShadowColor={
            DB_LeftSidebar_MenuItem_MenuContainerXYZ_Hover_ShadowColor
          }
        >
          {DB_TopBarLogoImageSrc && logoActive && (
            <Navbar.Brand
              style={{
                margin: "0px",
                padding: "0px",
                display: "block",
                textAlign: "center",
              }}
            >
              <img
                onClick={resetFilterHandler}
                src={DB_TopBarLogoImageSrc}
                alt="Logo"
                className={classes.theLogo}
              />
            </Navbar.Brand>
          )}

          <Nav expand="lg" className={`mt-1 ${classes.topNavigationStatic}`}>
            <Nav.Item>
              <Form.Control
                className={classes.topNavigationNavItemSearchStaticSpecial}
                type="text"
                placeholder={t("Suche")}
                onChange={(e) => searchHandler(e)}
                onKeyDown={(e) => searchPressEnter(e)}
                value={searchValue}
                autoComplete="nope"
              />
            </Nav.Item>
            <Nav.Item className={classes.topNavigationNavItemStaticBlock}>
              <Nav.Link
                className={classes.topNavigationNavItemStatic}
                onClick={() => showTopProductsHandler()}
              >
                <span className="topNavigationNavItemText">
                  {t("TopProdukte")}
                </span>
              </Nav.Link>
            </Nav.Item>
            <Nav.Item className={classes.topNavigationNavItemStaticBlock}>
              <Nav.Link
                className={classes.topNavigationNavItemStatic}
                onClick={() => aboutUsShowHandler()}
              >
                <span className="topNavigationNavItemText">
                  {t("UeberUns")}
                </span>
              </Nav.Link>
            </Nav.Item>
            {faqDetailItem && (
              <Nav.Item className={classes.topNavigationNavItemStaticBlock}>
                <Nav.Link
                  className={classes.topNavigationNavItemStatic}
                  onClick={() => faqShowHandler()}
                >
                  <span className="topNavigationNavItemText">{t("FAQ")}</span>
                </Nav.Link>
              </Nav.Item>
            )}

            {isAuthState ? (
              <Nav.Item className={classes.topNavigationNavItemStaticBlock}>
                <Nav.Link
                  className={classes.topNavigationNavItemStatic}
                  onClick={(truefalse) => meldenModalHandler(true)}
                >
                  <span className="topNavigationNavItemText">
                    {t("ShopMelden")}
                  </span>
                </Nav.Link>
              </Nav.Item>
            ) : (
              <Nav.Item className={classes.topNavigationNavItemStaticBlock}>
                <Nav.Link
                  className={classes.topNavigationNavItemStatic}
                  onClick={(truefalse) => meldenModalHandler(true)}
                >
                  <span className="topNavigationNavItemText">
                    {t("ShopMelden")}
                  </span>
                </Nav.Link>
              </Nav.Item>
            )}
            {isAuthState ? (
              <Nav.Item className={classes.topNavigationNavItemStaticBlock}>
                <Nav.Link
                  className={classes.topNavigationNavItemStatic}
                  onClick={signOutMessage}
                >
                  <span className="topNavigationNavItemText">
                    {t("Abmelden")}
                  </span>
                </Nav.Link>
              </Nav.Item>
            ) : (
              <Nav.Item className={classes.topNavigationNavItemStaticBlock}>
                <Nav.Link
                  className={classes.topNavigationNavItemStatic}
                  onClick={toggleSignIn}
                >
                  <span className="topNavigationNavItemText">
                    {t("Anmelden")}
                  </span>
                </Nav.Link>
              </Nav.Item>
            )}
          </Nav>
        </TopBarStaticContainer>
      </Container>
      <Modal
        show={benutzerMeldenModal}
        backdrop={true}
        className={"loggingModal"}
      >
        <Modal.Header>
          <div className="loginRegisterHeader">
            <span>
              {DB_TopBarLogoImageSrc && logoActive ? (
                <img
                  src={DB_TopBarLogoImageSrc}
                  alt="SuperMegaShop.com Logo"
                  height="65"
                />
              ) : (
                ""
              )}
            </span>
          </div>
        </Modal.Header>
        <Modal.Body className="p-0 loginRegister loginModal">
          <form className="ps-4 pe-4" action="#">
            <div className="mb-3 text-center">
              <h1
                style={{ color: "white", marginTop: "25px", fontSize: "24px" }}
              >
                {t("BenutzerMelden")}
              </h1>
              <div
                className="form-label"
                style={{
                  color: "white",
                  marginTop: "15px",
                  marginBottom: "10px",
                  fontSize: "16px",
                }}
              >
                {t("RegelverstossMelden")}
              </div>
              <div style={{ marginTop: "20px" }}>
                <Select
                  className="react-select landUniIconContainer"
                  classNamePrefix="react-select"
                  onChange={(e) => doMeldenReasonHandler(e)}
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 3,
                    colors: {
                      ...theme.colors,
                      color: "white",
                      neutral80: "white",
                      text: "white",
                      primary25: "#635bff",
                      primary: "#635bff",
                      font: "white",
                    },
                  })}
                  options={[
                    { value: "beleidigung", label: `${t("Beleidigung")}` },
                    { value: "spam", label: `${t("Spam")}` },
                    { value: "produkte", label: `${t("IllegaleProdukte")}` },
                    { value: "bilder", label: `${t("AnstoessigeBilder")}` },
                    { value: "rassismus", label: `${t("Rassismus")}` },
                    { value: "sexismus", label: `${t("Sexismus")}` },
                    { value: "politik", label: `${t("Politik")}` },
                    { value: "religion", label: `${t("Religion")}` },
                  ]}
                ></Select>
              </div>
              <div
                className="form-label"
                style={{
                  color: "white",
                  marginTop: "15px",
                  marginBottom: "10px",
                  fontSize: "16px",
                }}
              >
                {t("BeschreibungRegelverstoss")}
              </div>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Control
                  style={{ color: "white", height: "100px" }}
                  placeholder="Meldungsbeschreibung"
                  as="textarea"
                  rows={3}
                  onChange={(e) => {
                    onChangeMeldebeschreibung(e);
                  }}
                />
              </Form.Group>
              <div
                style={{
                  color: "white",
                  marginTop: "25px",
                  marginBottom: "20px",
                  fontSize: "16px",
                }}
              >
                {t("WeiterMassnahmen")}
              </div>
            </div>

            <div className="mb-1 text-center">
              <div className="mb-3 text-center">
                <Button
                  variant="danger"
                  onClick={() => regelverstossMeldenSubmit()}
                >
                  {t("BenutzerMelden")}
                </Button>
              </div>
            </div>
          </form>
        </Modal.Body>

        <Button
          className="buttonModalClose"
          onClick={() => meldenModalHandler(false)}
        >
          {t("Schliessen")}
        </Button>
      </Modal>
    </>
  );
};

export default TopBarStatic;
