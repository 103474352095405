import { useState } from "react";
import Container from "react-bootstrap/Container";
import { Button } from "react-bootstrap";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import { useTranslation } from "react-i18next";

function SiteBelongsToUserNavFAQusw({
  showSaveOptions,
  megaUltraShowSaveOptions,
  handleShow_showConfig_Main,
  setSideBarLeft_Container_Farbe_Groesse_Stil_Handler,
  setSideBarLeft_Container_Farbe_Groesse_Stil_Hover_Handler,
  setSideBarLeft_Container_HintergrundFarbe_Radius_Stil_Handler,
  setSideBarLeft_Container_HintergrundFarbe_Radius_Stil_Hover_Handler,
  setSideBarLeft_AlleProdukte_Farbe_Groesse_Stil_Handler,
  setSideBarLeft_AlleProdukte_Farbe_Groesse_Stil_Hover_Handler,
  setSideBarLeft_AlleProdukte_HintergrundFarbe_Radius_Stil_Handler,
  setSideBarLeft_AlleProdukte_HintergrundFarbe_Radius_Stil_Hover_Handler,
  setSideBarLeft_Hauptkategorie_Farbe_Groesse_Stil_Handler,
  setSideBarLeft_Hauptkategorie_Farbe_Groesse_Stil_Hover_Handler,
  setSideBarLeft_Hauptkategorie_HintergrundFarbe_Radius_Stil_Handler,
  setSideBarLeft_Hauptkategorie_HintergrundFarbe_Radius_Stil_Hover_Handler,
  setSideBarLeft_Unterkategorie_Farbe_Groesse_Stil_Handler,
  setSideBarLeft_Unterkategorie_Farbe_Groesse_Stil_Hover_Handler,
  setSideBarLeft_Unterkategorie_HintergrundFarbe_Radius_Stil_Handler,
  setSideBarLeft_Unterkategorie_HintergrundFarbe_Radius_Stil_Hover_Handler,
  setSideBarLeft_UnterUnterkategorie_Farbe_Groesse_Stil_Handler,
  setSideBarLeft_UnterUnterkategorie_Farbe_Groesse_Stil_Hover_Handler,
  setSideBarLeft_UnterUnterkategorie_HintergrundFarbe_Radius_Stil_Handler,
  setSideBarLeft_UnterUnterkategorie_HintergrundFarbe_Radius_Stil_Hover_Handler,
  setSideBarLeft_Container_Farbe_Groesse_Stil_OnMouseOverAndOut_Handler,
  setSideBarLeft_Container_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut_Handler,
  setSideBarLeft_Container_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut_Handler,
  setSideBarLeft_Container_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut_Handler,
  setSideBarLeft_AlleProdukte_Farbe_Groesse_Stil_OnMouseOverAndOut_Handler,
  setSideBarLeft_AlleProdukte_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut_Handler,
  setSideBarLeft_AlleProdukte_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut_Handler,
  setSideBarLeft_AlleProdukte_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut_Handler,
  setSideBarLeft_Hauptkategorie_Farbe_Groesse_Stil_OnMouseOverAndOut_Handler,
  setSideBarLeft_Hauptkategorie_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut_Handler,
  setSideBarLeft_Hauptkategorie_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut_Handler,
  setSideBarLeft_Hauptkategorie_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut_Handler,
  setSideBarLeft_Unterkategorie_Farbe_Groesse_Stil_OnMouseOverAndOut_Handler,
  setSideBarLeft_Unterkategorie_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut_Handler,
  setSideBarLeft_Unterkategorie_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut_Handler,
  setSideBarLeft_Unterkategorie_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut_Handler,
  setSideBarLeft_UnterUnterkategorie_Farbe_Groesse_Stil_OnMouseOverAndOut_Handler,
  setSideBarLeft_UnterUnterkategorie_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut_Handler,
  setSideBarLeft_UnterUnterkategorie_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut_Handler,
  setSideBarLeft_UnterUnterkategorie_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut_Handler,

  setSideBarLeft_BreadCrumbs_Farbe_Groesse_Stil_OnMouseOverAndOut_Handler,
  setSideBarLeft_BreadCrumbs_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut_Handler,
  setSideBarLeft_BreadCrumbs_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut_Handler,
  setSideBarLeft_BreadCrumbs_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut_Handler,
  setSideBarLeft_BreadCrumbs_Farbe_Groesse_Stil_Handler,
  setSideBarLeft_BreadCrumbs_Farbe_Groesse_Stil_Hover_Handler,
  setSideBarLeft_BreadCrumbs_HintergrundFarbe_Radius_Stil_Handler,
  setSideBarLeft_BreadCrumbs_HintergrundFarbe_Radius_Stil_Hover_Handler,

  setSideBarLeft_HauptTitel_Farbe_Groesse_Stil_OnMouseOverAndOut_Handler,
  setSideBarLeft_HauptTitel_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut_Handler,
  setSideBarLeft_HauptTitel_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut_Handler,
  setSideBarLeft_HauptTitel_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut_Handler,
  setSideBarLeft_HauptTitel_Farbe_Groesse_Stil_Handler,
  setSideBarLeft_HauptTitel_Farbe_Groesse_Stil_Hover_Handler,
  setSideBarLeft_HauptTitel_HintergrundFarbe_Radius_Stil_Handler,
  setSideBarLeft_HauptTitel_HintergrundFarbe_Radius_Stil_Hover_Handler,

  setSideBarLeft_UnterTitel_Farbe_Groesse_Stil_OnMouseOverAndOut_Handler,
  setSideBarLeft_UnterTitel_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut_Handler,
  setSideBarLeft_UnterTitel_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut_Handler,
  setSideBarLeft_UnterTitel_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut_Handler,
  setSideBarLeft_UnterTitel_Farbe_Groesse_Stil_Handler,
  setSideBarLeft_UnterTitel_Farbe_Groesse_Stil_Hover_Handler,
  setSideBarLeft_UnterTitel_HintergrundFarbe_Radius_Stil_Handler,
  setSideBarLeft_UnterTitel_HintergrundFarbe_Radius_Stil_Hover_Handler,

  setSideBarLeft_ProduktPreis_Farbe_Groesse_Stil_OnMouseOverAndOut_Handler,
  setSideBarLeft_ProduktPreis_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut_Handler,
  setSideBarLeft_ProduktPreis_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut_Handler,
  setSideBarLeft_ProduktPreis_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut_Handler,
  setSideBarLeft_ProduktPreis_Farbe_Groesse_Stil_Handler,
  setSideBarLeft_ProduktPreis_Farbe_Groesse_Stil_Hover_Handler,
  setSideBarLeft_ProduktPreis_HintergrundFarbe_Radius_Stil_Handler,
  setSideBarLeft_ProduktPreis_HintergrundFarbe_Radius_Stil_Hover_Handler,

  setSideBarLeft_ProduktWaehrung_Farbe_Groesse_Stil_OnMouseOverAndOut_Handler,
  setSideBarLeft_ProduktWaehrung_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut_Handler,
  setSideBarLeft_ProduktWaehrung_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut_Handler,
  setSideBarLeft_ProduktWaehrung_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut_Handler,
  setSideBarLeft_ProduktWaehrung_Farbe_Groesse_Stil_Handler,
  setSideBarLeft_ProduktWaehrung_Farbe_Groesse_Stil_Hover_Handler,
  setSideBarLeft_ProduktWaehrung_HintergrundFarbe_Radius_Stil_Handler,
  setSideBarLeft_ProduktWaehrung_HintergrundFarbe_Radius_Stil_Hover_Handler,

  setSideBarLeft_ProduktContainer_Farbe_Groesse_Stil_Handler,
  setSideBarLeft_ProduktContainer_Farbe_Groesse_Stil_Hover_Handler,
  setSideBarLeft_ProduktContainer_HintergrundFarbe_Radius_Stil_Handler,
  setSideBarLeft_ProduktContainer_HintergrundFarbe_Radius_Stil_Hover_Handler,
  setSideBarLeft_ProduktContainer_Farbe_Groesse_Stil_OnMouseOverAndOut_Handler,
  setSideBarLeft_ProduktContainer_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut_Handler,
  setSideBarLeft_ProduktContainer_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut_Handler,
  setSideBarLeft_ProduktContainer_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut_Handler,

  setSideBarLeft_HauptmenuTop_Farbe_Groesse_Stil_OnMouseOverAndOut_Handler,
  setSideBarLeft_HauptmenuTop_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut_Handler,
  setSideBarLeft_HauptmenuTop_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut_Handler,
  setSideBarLeft_HauptmenuTop_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut_Handler,
  setSideBarLeft_HauptmenuTop_Farbe_Groesse_Stil_Handler,
  setSideBarLeft_HauptmenuTop_Farbe_Groesse_Stil_Hover_Handler,
  setSideBarLeft_HauptmenuTop_HintergrundFarbe_Radius_Stil_Handler,
  setSideBarLeft_HauptmenuTop_HintergrundFarbe_Radius_Stil_Hover_Handler,

  setSideBarLeft_ContainerTop_Farbe_Groesse_Stil_Handler,
  setSideBarLeft_ContainerTop_Farbe_Groesse_Stil_Hover_Handler,
  setSideBarLeft_ContainerTop_HintergrundFarbe_Radius_Stil_Handler,
  setSideBarLeft_ContainerTop_HintergrundFarbe_Radius_Stil_Hover_Handler,
  setSideBarLeft_ContainerTop_Farbe_Groesse_Stil_OnMouseOverAndOut_Handler,
  setSideBarLeft_ContainerTop_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut_Handler,
  setSideBarLeft_ContainerTop_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut_Handler,
  setSideBarLeft_ContainerTop_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut_Handler,

  setSideBarLeft_SortierungTop_Farbe_Groesse_Stil_Handler,
  setSideBarLeft_SortierungTop_Farbe_Groesse_Stil_Hover_Handler,
  setSideBarLeft_SortierungTop_HintergrundFarbe_Radius_Stil_Handler,
  setSideBarLeft_SortierungTop_HintergrundFarbe_Radius_Stil_Hover_Handler,
  setSideBarLeft_SortierungTop_Farbe_Groesse_Stil_OnMouseOverAndOut_Handler,
  setSideBarLeft_SortierungTop_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut_Handler,
  setSideBarLeft_SortierungTop_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut_Handler,
  setSideBarLeft_SortierungTop_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut_Handler,

  setSideBarLeft_HauptmenuTopStatic_Farbe_Groesse_Stil_OnMouseOverAndOut_Handler,
  setSideBarLeft_HauptmenuTopStatic_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut_Handler,
  setSideBarLeft_HauptmenuTopStatic_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut_Handler,
  setSideBarLeft_HauptmenuTopStatic_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut_Handler,
  setSideBarLeft_HauptmenuTopStatic_Farbe_Groesse_Stil_Handler,
  setSideBarLeft_HauptmenuTopStatic_Farbe_Groesse_Stil_Hover_Handler,
  setSideBarLeft_HauptmenuTopStatic_HintergrundFarbe_Radius_Stil_Handler,
  setSideBarLeft_HauptmenuTopStatic_HintergrundFarbe_Radius_Stil_Hover_Handler,

  setSideBarLeft_ContainerFilter_Farbe_Groesse_Stil_Handler,
  setSideBarLeft_ContainerFilter_Farbe_Groesse_Stil_Hover_Handler,
  setSideBarLeft_ContainerFilter_HintergrundFarbe_Radius_Stil_Handler,
  setSideBarLeft_ContainerFilter_HintergrundFarbe_Radius_Stil_Hover_Handler,
  setSideBarLeft_AlleFilter_Farbe_Groesse_Stil_Handler,
  setSideBarLeft_AlleFilter_Farbe_Groesse_Stil_Hover_Handler,
  setSideBarLeft_AlleFilter_HintergrundFarbe_Radius_Stil_Handler,
  setSideBarLeft_AlleFilter_HintergrundFarbe_Radius_Stil_Hover_Handler,
  setSideBarLeft_FilterItem_Farbe_Groesse_Stil_Handler,
  setSideBarLeft_FilterItem_Farbe_Groesse_Stil_Hover_Handler,
  setSideBarLeft_FilterItem_HintergrundFarbe_Radius_Stil_Handler,
  setSideBarLeft_FilterItem_HintergrundFarbe_Radius_Stil_Hover_Handler,
  setSideBarLeft_HauptFiltertitel_Farbe_Groesse_Stil_Handler,
  setSideBarLeft_HauptFiltertitel_Farbe_Groesse_Stil_Hover_Handler,
  setSideBarLeft_HauptFiltertitel_HintergrundFarbe_Radius_Stil_Handler,
  setSideBarLeft_HauptFiltertitel_HintergrundFarbe_Radius_Stil_Hover_Handler,
  setSideBarLeft_ContainerFilter_Farbe_Groesse_Stil_OnMouseOverAndOut_Handler,
  setSideBarLeft_ContainerFilter_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut_Handler,
  setSideBarLeft_ContainerFilter_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut_Handler,
  setSideBarLeft_ContainerFilter_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut_Handler,
  setSideBarLeft_AlleFilter_Farbe_Groesse_Stil_OnMouseOverAndOut_Handler,
  setSideBarLeft_AlleFilter_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut_Handler,
  setSideBarLeft_AlleFilter_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut_Handler,
  setSideBarLeft_AlleFilter_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut_Handler,
  setSideBarLeft_FilterItem_Farbe_Groesse_Stil_OnMouseOverAndOut_Handler,
  setSideBarLeft_FilterItem_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut_Handler,
  setSideBarLeft_FilterItem_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut_Handler,
  setSideBarLeft_FilterItem_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut_Handler,
  setSideBarLeft_HauptFiltertitel_Farbe_Groesse_Stil_OnMouseOverAndOut_Handler,
  setSideBarLeft_HauptFiltertitel_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut_Handler,
  setSideBarLeft_HauptFiltertitel_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut_Handler,
  setSideBarLeft_HauptFiltertitel_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut_Handler,

  setSideBarLeft_ContainerSocial_Farbe_Groesse_Stil_Handler,
  setSideBarLeft_ContainerSocial_Farbe_Groesse_Stil_Hover_Handler,
  setSideBarLeft_ContainerSocial_HintergrundFarbe_Radius_Stil_Handler,
  setSideBarLeft_ContainerSocial_HintergrundFarbe_Radius_Stil_Hover_Handler,
  setSideBarLeft_AlleSocial_Farbe_Groesse_Stil_Handler,
  setSideBarLeft_AlleSocial_Farbe_Groesse_Stil_Hover_Handler,
  setSideBarLeft_AlleSocial_HintergrundFarbe_Radius_Stil_Handler,
  setSideBarLeft_AlleSocial_HintergrundFarbe_Radius_Stil_Hover_Handler,
  setSideBarLeft_SocialItem_Farbe_Groesse_Stil_Handler,
  setSideBarLeft_SocialItem_Farbe_Groesse_Stil_Hover_Handler,
  setSideBarLeft_SocialItem_HintergrundFarbe_Radius_Stil_Handler,
  setSideBarLeft_SocialItem_HintergrundFarbe_Radius_Stil_Hover_Handler,

  setSideBarLeft_ContainerSocial_Farbe_Groesse_Stil_OnMouseOverAndOut_Handler,
  setSideBarLeft_ContainerSocial_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut_Handler,
  setSideBarLeft_ContainerSocial_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut_Handler,
  setSideBarLeft_ContainerSocial_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut_Handler,
  setSideBarLeft_AlleSocial_Farbe_Groesse_Stil_OnMouseOverAndOut_Handler,
  setSideBarLeft_AlleSocial_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut_Handler,
  setSideBarLeft_AlleSocial_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut_Handler,
  setSideBarLeft_AlleSocial_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut_Handler,
  setSideBarLeft_SocialItem_Farbe_Groesse_Stil_OnMouseOverAndOut_Handler,
  setSideBarLeft_SocialItem_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut_Handler,
  setSideBarLeft_SocialItem_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut_Handler,
  setSideBarLeft_SocialItem_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut_Handler,

  setSideBarLeft_ContainerDetail_Farbe_Groesse_Stil_OnMouseOverAndOut_Handler,
  setSideBarLeft_ContainerDetail_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut_Handler,
  setSideBarLeft_AlleDetail_Farbe_Groesse_Stil_OnMouseOverAndOut_Handler,
  setSideBarLeft_AlleDetail_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut_Handler,
  setSideBarLeft_DetailItem_Farbe_Groesse_Stil_OnMouseOverAndOut_Handler,
  setSideBarLeft_DetailItem_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut_Handler,
  setSideBarLeft_DetailSuper_Farbe_Groesse_Stil_OnMouseOverAndOut_Handler,
  setSideBarLeft_DetailSuper_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut_Handler,

  setSideBarLeft_ContainerDetail_Farbe_Groesse_Stil_Handler,
  setSideBarLeft_ContainerDetail_HintergrundFarbe_Radius_Stil_Handler,
  setSideBarLeft_AlleDetail_Farbe_Groesse_Stil_Handler,
  setSideBarLeft_AlleDetail_HintergrundFarbe_Radius_Stil_Handler,
  setSideBarLeft_DetailItem_Farbe_Groesse_Stil_Handler,
  setSideBarLeft_DetailItem_HintergrundFarbe_Radius_Stil_Handler,
  setSideBarLeft_DetailSuper_Farbe_Groesse_Stil_Handler,
  setSideBarLeft_DetailSuper_HintergrundFarbe_Radius_Stil_Handler,

  setSideBarLeft_TitelKontaktFormularTitel_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut_Handler,
  setSideBarLeft_TitelKontaktFormularTitel_Farbe_Groesse_Stil_OnMouseOverAndOut_Handler,
  setSideBarLeft_TitelKontaktFormularTitel_HintergrundFarbe_Radius_Stil_Handler,
  setSideBarLeft_TitelKontaktFormularTitel_Farbe_Groesse_Stil_Handler,

  setSideBarLeft_FormInputDetail_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut_Handler,
  setSideBarLeft_FormInputDetail_Farbe_Groesse_Stil_OnMouseOverAndOut_Handler,
  setSideBarLeft_FormInputDetail_HintergrundFarbe_Radius_Stil_Handler,
  setSideBarLeft_FormInputDetail_Farbe_Groesse_Stil_Handler,

  setSideBarLeft_Container_HintergrundFarbe_Radius_Stil_Logo_Handler,

  setSideBarLeft_ContainerTopStatic_HintergrundFarbe_Radius_Stil_Handler,
  setSideBarStatic_ContainerTop_HintergrundFarbe_Radius_Stil_Hover_Handler,
  setSideBarStatic_ContainerTop_Farbe_Groesse_Stil_Handler,
  setSideBarStatic_ContainerTop_Farbe_Groesse_Stil_Hover_Handler,
  setSideBarLeft_ContainerTopXYZ_Farbe_Groesse_Stil_OnMouseOverAndOut_Handler,
  setSideBarLeft_ContainerTopXYZ_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut_Handler,
  setSideBarLeft_ContainerTopXYZ_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut_Handler,
  setSideBarLeft_ContainerTopXYZ_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut_Handler,
}) {
  const handleSelect = (eventKey) => alert(`selected ${eventKey}`);
  const { t, i18n } = useTranslation();
  return (
    <>
      <style jsx global>
        {`
          .SiteBelongsToUserNavFAQ {
            border-top-left-radius: ${showSaveOptions
              ? "0px"
              : "20px"} !important;
            border-top-right-radius: ${showSaveOptions
              ? "0px"
              : "20px"} !important;
            background-color: rgba(0, 0, 0, 0.8) !important;
            position: fixed !important;
            bottom: 0px !important;
            width: 180px !important;
            z-index: 9999 !important;
          }

          .dropdown-menu {
            background-color: rgba(0, 0, 0, 0.9) !important;
            margin-top: 7px !important;
            border-top-left-radius: 0px;
            border-top-right-radius: 0px;
            border-bottom-left-radius: 20px;
            border-bottom-right-radius: 20px;
            border-left: 1px solid rgba(255, 255, 255, 0.2);
            border-right: 1px solid rgba(255, 255, 255, 0.2);
            border-bottom: 1px solid rgba(255, 255, 255, 0.2);
          }

          .dropdown-item {
            color: white !important;
            font-family: "Lato";
            src: url("../assets/Lato/Lato-Regular.ttf");
          }

          a.nav-link {
            font-family: "Lato";
            src: url("../assets/Lato/Lato-Regular.ttf");
            color: white;
            font-weight: bold;
          }

          .dropdown-item:hover {
            color: white !important;
            background-color: rgba(255, 255, 255, 0.1) !important;
            font-weight: normal;
          }

          // FAQ's, AGB's, Über Uns, Kontakt (Container)
          .SiteBelongsToUserNavStaticSpeichern {
            background-color: rgba(0, 0, 0, 0.6);
            padding: 0px 15px !important;
            margin-top: 0px !important;
            font-size: 16px;
            text-align: center;
            border-radius: 7px;
            min-height: 20px;
            border-top: 1px solid rgba(0, 0, 0, 0.6);
            border-bottom: 1px solid rgba(0, 0, 0, 0.6);
            border-left: 1px solid rgba(0, 0, 0, 0.6);
            border-right: 1px solid rgba(0, 0, 0, 0.6);
            box-shadow: rgba(0, 0, 0, 0.16) 0px 2px 3px,
              rgba(0, 0, 0, 0.23) 0px 2px 3px;
          }

          hr.horizontalRule {
            border: 2px solid rgba(255, 255, 255, 1) !important;
          }

          hr.horizontalRuleSub {
            border: 1px solid rgba(255, 255, 255, 1) !important;
          }
        `}
      </style>

      <Container className="px-0 d-lg-block d-none UserNavFAQ">
        <div className="container d-flex justify-content-center">
          <Navbar
            bg="dark"
            variant="dark"
            expand="lg"
            className="SiteBelongsToUserNavFAQ text-center"
          >
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="m-auto">
                {/*    <NavDropdown.Item
                  className="SiteBelongsToUserNavStatic grundeinstellungen"
                  onClick={() => handleShow_showConfig_Main()}
                >
                  <strong>Grundeinstellungen</strong>
                </NavDropdown.Item> */}
                <div onClick={() => megaUltraShowSaveOptions()}>
                  <Button variant="success">{t("Speicherauswahl")}</Button>
                </div>
              </Nav>
            </Navbar.Collapse>
          </Navbar>
        </div>
      </Container>
    </>
  );
}

export default SiteBelongsToUserNavFAQusw;
