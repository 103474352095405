import { createUseStyles } from "react-jss";

const SideBarLeftSubKategorie = ({
  category,
  DB_fontFace_unterkategorieTitel_FontFamilyName,
  DB_fontFace_unterkategorieTitel_FontFamilySrc,
  categoryLeftSideHandler,
  DB_LeftSidebar_MenuItem_Unterkategorie_FontWeight,
  DB_LeftSidebar_MenuItem_Unterkategorie_Hover_FontWeight,
  DB_LeftSidebar_MenuItem_Unterkategorie_FontColor,
  DB_LeftSidebar_MenuItem_Unterkategorie_Hover_FontColor,
  DB_LeftSidebar_MenuItem_Unterkategorie_FontSize,
  DB_LeftSidebar_MenuItem_Unterkategorie_Hover_FontSize,
  DB_LeftSidebar_MenuItem_Unterkategorie_BackgroundColor,
  DB_LeftSidebar_MenuItem_Unterkategorie_Hover_BackgroundColor,
  DB_LeftSidebar_MenuItem_Unterkategorie_TextAlign,
  DB_LeftSidebar_MenuItem_Unterkategorie_Hover_TextAlign,
  DB_LeftSidebar_MenuItem_Unterkategorie_BorderTop,
  DB_LeftSidebar_MenuItem_Unterkategorie_Hover_BorderTop,
  DB_LeftSidebar_MenuItem_Unterkategorie_BorderStyleTop,
  DB_LeftSidebar_MenuItem_Unterkategorie_BorderColorTop,
  DB_LeftSidebar_MenuItem_Unterkategorie_BorderBottom,
  DB_LeftSidebar_MenuItem_Unterkategorie_BorderStyleBottom,
  DB_LeftSidebar_MenuItem_Unterkategorie_BorderColorBottom,
  DB_LeftSidebar_MenuItem_Unterkategorie_BorderLeft,
  DB_LeftSidebar_MenuItem_Unterkategorie_BorderStyleLeft,
  DB_LeftSidebar_MenuItem_Unterkategorie_BorderColorLeft,
  DB_LeftSidebar_MenuItem_Unterkategorie_BorderRight,
  DB_LeftSidebar_MenuItem_Unterkategorie_BorderStyleRight,
  DB_LeftSidebar_MenuItem_Unterkategorie_BorderColorRight,

  DB_LeftSidebar_MenuItem_Unterkategorie_BorderTopRightRadius,
  DB_LeftSidebar_MenuItem_Unterkategorie_BorderTopLeftRadius,
  DB_LeftSidebar_MenuItem_Unterkategorie_BorderBottomRightRadius,
  DB_LeftSidebar_MenuItem_Unterkategorie_BorderBottomLeftRadius,

  DB_LeftSidebar_MenuItem_Unterkategorie_Hover_BorderTopRightRadius,
  DB_LeftSidebar_MenuItem_Unterkategorie_Hover_BorderTopLeftRadius,
  DB_LeftSidebar_MenuItem_Unterkategorie_Hover_BorderBottomRightRadius,
  DB_LeftSidebar_MenuItem_Unterkategorie_Hover_BorderBottomLeftRadius,

  DB_LeftSidebar_MenuItem_Unterkategorie_Hover_BorderStyleTop,
  DB_LeftSidebar_MenuItem_Unterkategorie_Hover_BorderColorTop,
  DB_LeftSidebar_MenuItem_Unterkategorie_Hover_BorderBottom,
  DB_LeftSidebar_MenuItem_Unterkategorie_Hover_BorderStyleBottom,

  DB_LeftSidebar_MenuItem_Unterkategorie_Hover_BorderColorBottom,
  DB_LeftSidebar_MenuItem_Unterkategorie_Hover_BorderLeft,
  DB_LeftSidebar_MenuItem_Unterkategorie_Hover_BorderStyleLeft,
  DB_LeftSidebar_MenuItem_Unterkategorie_Hover_BorderColorLeft,

  DB_LeftSidebar_MenuItem_Unterkategorie_Hover_BorderRight,
  DB_LeftSidebar_MenuItem_Unterkategorie_Hover_BorderStyleRight,
  DB_LeftSidebar_MenuItem_Unterkategorie_Hover_BorderColorRight,

  SideBarLeft_Unterkategorie_Farbe_Groesse_Stil_OnMouseOverAndOut,
  SideBarLeft_Unterkategorie_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut,
  SideBarLeft_Unterkategorie_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut,
  SideBarLeft_Unterkategorie_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut,

  DB_LeftSidebar_MenuItem_Unterkategorie_marginTopBottom,
  DB_LeftSidebar_MenuItem_Unterkategorie_marginLeftRight,
  DB_LeftSidebar_MenuItem_Unterkategorie_paddingLeftRight,
  DB_LeftSidebar_MenuItem_Unterkategorie_paddingTopBottom,

  DB_LeftSidebar_MenuItem_Unterkategorie_letterSpacing,
  DB_LeftSidebar_MenuItem_Unterkategorie_ShadowHorizontal,
  DB_LeftSidebar_MenuItem_Unterkategorie_ShadowVertical,
  DB_LeftSidebar_MenuItem_Unterkategorie_ShadowBlur,
  DB_LeftSidebar_MenuItem_Unterkategorie_ShadowColor,
  DB_LeftSidebar_MenuItem_Unterkategorie_TextDecoration,
  DB_LeftSidebar_MenuItem_Unterkategorie_TextDecorationStyle,

  DB_LeftSidebar_MenuItem_Unterkategorie_marginTopBottom_Hover,
  DB_LeftSidebar_MenuItem_Unterkategorie_marginLeftRight_Hover,
  DB_LeftSidebar_MenuItem_Unterkategorie_paddingLeftRight_Hover,
  DB_LeftSidebar_MenuItem_Unterkategorie_paddingTopBottom_Hover,

  DB_LeftSidebar_MenuItem_Unterkategorie_letterSpacing_Hover,
  DB_LeftSidebar_MenuItem_Unterkategorie_ShadowHorizontal_Hover,
  DB_LeftSidebar_MenuItem_Unterkategorie_ShadowVertical_Hover,
  DB_LeftSidebar_MenuItem_Unterkategorie_ShadowBlur_Hover,
  DB_LeftSidebar_MenuItem_Unterkategorie_ShadowColor_Hover,
  DB_LeftSidebar_MenuItem_Unterkategorie_TextDecoration_Hover,
  DB_LeftSidebar_MenuItem_Unterkategorie_TextDecorationStyle_Hover,
}) => {
  const useStyles = createUseStyles({
    menuItem_unterkategorie: {
      fontFamily: `${DB_fontFace_unterkategorieTitel_FontFamilyName}`,
      fontSize: `${DB_LeftSidebar_MenuItem_Unterkategorie_FontSize}px`,
      fontWeight: `${DB_LeftSidebar_MenuItem_Unterkategorie_FontWeight}`,
      color: `${DB_LeftSidebar_MenuItem_Unterkategorie_FontColor}`,
      backgroundColor: `${
        SideBarLeft_Unterkategorie_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut
          ? "rgba(255, 0, 0, 0.5)"
          : SideBarLeft_Unterkategorie_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut
          ? "rgba(255, 0, 0, 0.3)"
          : DB_LeftSidebar_MenuItem_Unterkategorie_BackgroundColor
      }`,
      textAlign: `${DB_LeftSidebar_MenuItem_Unterkategorie_TextAlign}`,
      borderTop: `${
        SideBarLeft_Unterkategorie_Farbe_Groesse_Stil_OnMouseOverAndOut
          ? "3"
          : SideBarLeft_Unterkategorie_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
          ? "3"
          : DB_LeftSidebar_MenuItem_Unterkategorie_BorderTop
      }px
        ${
          SideBarLeft_Unterkategorie_Farbe_Groesse_Stil_OnMouseOverAndOut
            ? "solid"
            : SideBarLeft_Unterkategorie_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
            ? "dotted"
            : DB_LeftSidebar_MenuItem_Unterkategorie_BorderStyleTop
        }
        ${
          SideBarLeft_Unterkategorie_Farbe_Groesse_Stil_OnMouseOverAndOut
            ? "red"
            : SideBarLeft_Unterkategorie_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
            ? "red"
            : DB_LeftSidebar_MenuItem_Unterkategorie_BorderColorTop
        }`,
      borderBottom: `${
        SideBarLeft_Unterkategorie_Farbe_Groesse_Stil_OnMouseOverAndOut
          ? "3"
          : SideBarLeft_Unterkategorie_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
          ? "3"
          : DB_LeftSidebar_MenuItem_Unterkategorie_BorderBottom
      }px
        ${
          SideBarLeft_Unterkategorie_Farbe_Groesse_Stil_OnMouseOverAndOut
            ? "solid"
            : SideBarLeft_Unterkategorie_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
            ? "dotted"
            : DB_LeftSidebar_MenuItem_Unterkategorie_BorderStyleBottom
        }
        ${
          SideBarLeft_Unterkategorie_Farbe_Groesse_Stil_OnMouseOverAndOut
            ? "red"
            : SideBarLeft_Unterkategorie_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
            ? "red"
            : DB_LeftSidebar_MenuItem_Unterkategorie_BorderColorBottom
        }`,
      borderLeft: `${
        SideBarLeft_Unterkategorie_Farbe_Groesse_Stil_OnMouseOverAndOut
          ? "3"
          : SideBarLeft_Unterkategorie_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
          ? "3"
          : DB_LeftSidebar_MenuItem_Unterkategorie_BorderLeft
      }px
        ${
          SideBarLeft_Unterkategorie_Farbe_Groesse_Stil_OnMouseOverAndOut
            ? "solid"
            : SideBarLeft_Unterkategorie_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
            ? "dotted"
            : DB_LeftSidebar_MenuItem_Unterkategorie_BorderStyleLeft
        }
        ${
          SideBarLeft_Unterkategorie_Farbe_Groesse_Stil_OnMouseOverAndOut
            ? "red"
            : SideBarLeft_Unterkategorie_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
            ? "red"
            : DB_LeftSidebar_MenuItem_Unterkategorie_BorderColorLeft
        }`,
      borderRight: `${
        SideBarLeft_Unterkategorie_Farbe_Groesse_Stil_OnMouseOverAndOut
          ? "3"
          : SideBarLeft_Unterkategorie_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
          ? "3"
          : DB_LeftSidebar_MenuItem_Unterkategorie_BorderRight
      }px
        ${
          SideBarLeft_Unterkategorie_Farbe_Groesse_Stil_OnMouseOverAndOut
            ? "solid"
            : SideBarLeft_Unterkategorie_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
            ? "dotted"
            : DB_LeftSidebar_MenuItem_Unterkategorie_BorderStyleRight
        }
        ${
          SideBarLeft_Unterkategorie_Farbe_Groesse_Stil_OnMouseOverAndOut
            ? "red"
            : SideBarLeft_Unterkategorie_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
            ? "red"
            : DB_LeftSidebar_MenuItem_Unterkategorie_BorderColorRight
        }`,
      borderTopRightRadius: `${DB_LeftSidebar_MenuItem_Unterkategorie_BorderTopRightRadius}px`,
      borderTopLeftRadius: `${DB_LeftSidebar_MenuItem_Unterkategorie_BorderTopLeftRadius}px`,
      borderBottomRightRadius: `${DB_LeftSidebar_MenuItem_Unterkategorie_BorderBottomRightRadius}px`,
      borderBottomLeftRadius: `${DB_LeftSidebar_MenuItem_Unterkategorie_BorderBottomLeftRadius}px`,
      marginLeft: `${DB_LeftSidebar_MenuItem_Unterkategorie_marginLeftRight}px`,
      marginRight: `${DB_LeftSidebar_MenuItem_Unterkategorie_marginLeftRight}px`,
      marginTop: `${DB_LeftSidebar_MenuItem_Unterkategorie_marginTopBottom}px`,
      marginBottom: `${DB_LeftSidebar_MenuItem_Unterkategorie_marginTopBottom}px`,
      paddingLeft: `${DB_LeftSidebar_MenuItem_Unterkategorie_paddingLeftRight}px`,
      paddingRight: `${DB_LeftSidebar_MenuItem_Unterkategorie_paddingLeftRight}px`,
      paddingTop: `${DB_LeftSidebar_MenuItem_Unterkategorie_paddingTopBottom}px`,
      paddingBottom: `${DB_LeftSidebar_MenuItem_Unterkategorie_paddingTopBottom}px`,
      letterSpacing: `${DB_LeftSidebar_MenuItem_Unterkategorie_letterSpacing}px`,
      textShadow: `${DB_LeftSidebar_MenuItem_Unterkategorie_ShadowHorizontal}px
        ${DB_LeftSidebar_MenuItem_Unterkategorie_ShadowVertical}px
        ${DB_LeftSidebar_MenuItem_Unterkategorie_ShadowBlur}px
        ${DB_LeftSidebar_MenuItem_Unterkategorie_ShadowColor}`,
      textDecoration: `${DB_LeftSidebar_MenuItem_Unterkategorie_TextDecoration}
        ${DB_LeftSidebar_MenuItem_Unterkategorie_TextDecorationStyle}`,
      "&:hover": {
        fontSize: `${DB_LeftSidebar_MenuItem_Unterkategorie_Hover_FontSize}px`,
        fontWeight: `${DB_LeftSidebar_MenuItem_Unterkategorie_Hover_FontWeight}`,
        color: `${DB_LeftSidebar_MenuItem_Unterkategorie_Hover_FontColor}`,
        backgroundColor: `${DB_LeftSidebar_MenuItem_Unterkategorie_Hover_BackgroundColor}`,
        textAlign: `${DB_LeftSidebar_MenuItem_Unterkategorie_Hover_TextAlign}`,
        borderTop: `${DB_LeftSidebar_MenuItem_Unterkategorie_Hover_BorderTop}px
        ${DB_LeftSidebar_MenuItem_Unterkategorie_Hover_BorderStyleTop}
        ${DB_LeftSidebar_MenuItem_Unterkategorie_Hover_BorderColorTop}`,
        borderbottom: `${DB_LeftSidebar_MenuItem_Unterkategorie_Hover_BorderBottom}px
        ${DB_LeftSidebar_MenuItem_Unterkategorie_Hover_BorderStyleBottom}
        ${DB_LeftSidebar_MenuItem_Unterkategorie_Hover_BorderColorBottom}`,
        borderLeft: `${DB_LeftSidebar_MenuItem_Unterkategorie_Hover_BorderLeft}px
        ${DB_LeftSidebar_MenuItem_Unterkategorie_Hover_BorderStyleLeft}
        ${DB_LeftSidebar_MenuItem_Unterkategorie_Hover_BorderColorLeft}`,
        borderRight: `${DB_LeftSidebar_MenuItem_Unterkategorie_Hover_BorderRight}px
        ${DB_LeftSidebar_MenuItem_Unterkategorie_Hover_BorderStyleRight}
        ${DB_LeftSidebar_MenuItem_Unterkategorie_Hover_BorderColorRight}`,
        borderTopRightRadius: `${DB_LeftSidebar_MenuItem_Unterkategorie_Hover_BorderTopRightRadius}px`,
        borderTopLeftRadius: `${DB_LeftSidebar_MenuItem_Unterkategorie_Hover_BorderTopLeftRadius}px`,
        borderBottomRightRadius: `${DB_LeftSidebar_MenuItem_Unterkategorie_Hover_BorderBottomRightRadius}px`,
        borderBottomLeftRadius: `${DB_LeftSidebar_MenuItem_Unterkategorie_Hover_BorderBottomLeftRadius}px`,
        marginLeft: `${DB_LeftSidebar_MenuItem_Unterkategorie_marginLeftRight_Hover}px`,
        marginRight: `${DB_LeftSidebar_MenuItem_Unterkategorie_marginLeftRight_Hover}px`,
        marginTop: `${DB_LeftSidebar_MenuItem_Unterkategorie_marginTopBottom_Hover}px`,
        marginBottom: `${DB_LeftSidebar_MenuItem_Unterkategorie_marginTopBottom_Hover}px`,
        paddingLeft: `${DB_LeftSidebar_MenuItem_Unterkategorie_paddingLeftRight_Hover}px`,
        paddingRight: `${DB_LeftSidebar_MenuItem_Unterkategorie_paddingLeftRight_Hover}px`,
        paddingTop: `${DB_LeftSidebar_MenuItem_Unterkategorie_paddingTopBottom_Hover}px`,
        paddingBottom: `${DB_LeftSidebar_MenuItem_Unterkategorie_paddingTopBottom_Hover}px`,
        letterSpacing: `${DB_LeftSidebar_MenuItem_Unterkategorie_letterSpacing_Hover}px`,
        textShadow: `${DB_LeftSidebar_MenuItem_Unterkategorie_ShadowHorizontal_Hover}px
        ${DB_LeftSidebar_MenuItem_Unterkategorie_ShadowVertical_Hover}px
        ${DB_LeftSidebar_MenuItem_Unterkategorie_ShadowBlur_Hover}px
        ${DB_LeftSidebar_MenuItem_Unterkategorie_ShadowColor_Hover}`,
        textDecoration: `${DB_LeftSidebar_MenuItem_Unterkategorie_TextDecoration_Hover}
        ${DB_LeftSidebar_MenuItem_Unterkategorie_TextDecorationStyle_Hover}`,
      },
    },
  });

  const classes = useStyles();

  return (
    <div
      className="hauptkategorie"
      style={{ cursor: "pointer" }}
      key={"subCategoryOne" + category.subkategorie}
      onClick={() =>
        categoryLeftSideHandler(
          `${category.kategorie}`,
          `${category.subkategorie}`
        )
      }
    >
      <li className={classes.menuItem_unterkategorie}>
        {category.subkategorie}
      </li>
    </div>
  );
};

export default SideBarLeftSubKategorie;
