import { useState, useEffect } from "react";
import {
  Offcanvas,
  Container,
  Form,
  OverlayTrigger,
  Tooltip,
  Button,
} from "react-bootstrap";
import { RgbaColorPicker } from "react-colorful";
import ChangePropertiesIcon from "./ChangePropertiesIcon";
import { createUseStyles } from "react-jss";

const SideBarLeftMainKategorie = ({
  mainkey,
  trace,
  category,
  DB_fontFace_hauptkategorieTitel_FontFamilyName,
  categoryLeftSideHandler,
  DB_LeftSidebar_MenuItem_Hauptkategorie_FontWeight,
  DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_FontWeight,
  DB_LeftSidebar_MenuItem_Hauptkategorie_FontColor,
  DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_FontColor,
  DB_LeftSidebar_MenuItem_Hauptkategorie_FontSize,
  DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_FontSize,
  SideBarLeft_Hauptkategorie_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut,
  SideBarLeft_Hauptkategorie_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut,
  DB_LeftSidebar_MenuItem_Hauptkategorie_BackgroundColor,
  DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_BackgroundColor,
  DB_LeftSidebar_MenuItem_Hauptkategorie_TextAlign,
  DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_TextAlign,
  SideBarLeft_Hauptkategorie_Farbe_Groesse_Stil_OnMouseOverAndOut,
  SideBarLeft_Hauptkategorie_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut,
  DB_LeftSidebar_MenuItem_Hauptkategorie_BorderTop,
  DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_BorderTop,
  DB_LeftSidebar_MenuItem_Hauptkategorie_BorderStyleTop,
  DB_LeftSidebar_MenuItem_Hauptkategorie_BorderColorTop,
  DB_LeftSidebar_MenuItem_Hauptkategorie_BorderBottom,
  DB_LeftSidebar_MenuItem_Hauptkategorie_BorderStyleBottom,
  DB_LeftSidebar_MenuItem_Hauptkategorie_BorderColorBottom,
  DB_LeftSidebar_MenuItem_Hauptkategorie_BorderLeft,
  DB_LeftSidebar_MenuItem_Hauptkategorie_BorderStyleLeft,
  DB_LeftSidebar_MenuItem_Hauptkategorie_BorderColorLeft,
  DB_LeftSidebar_MenuItem_Hauptkategorie_BorderRight,
  DB_LeftSidebar_MenuItem_Hauptkategorie_BorderStyleRight,
  DB_LeftSidebar_MenuItem_Hauptkategorie_BorderColorRight,

  DB_LeftSidebar_MenuItem_Hauptkategorie_BorderTopRightRadius,
  DB_LeftSidebar_MenuItem_Hauptkategorie_BorderTopLeftRadius,
  DB_LeftSidebar_MenuItem_Hauptkategorie_BorderBottomRightRadius,
  DB_LeftSidebar_MenuItem_Hauptkategorie_BorderBottomLeftRadius,

  DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_BorderTopRightRadius,
  DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_BorderTopLeftRadius,
  DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_BorderBottomRightRadius,
  DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_BorderBottomLeftRadius,

  DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_BorderStyleTop,
  DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_BorderColorTop,
  DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_BorderBottom,
  DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_BorderStyleBottom,

  DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_BorderColorBottom,
  DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_BorderLeft,
  DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_BorderStyleLeft,
  DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_BorderColorLeft,

  DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_BorderRight,
  DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_BorderStyleRight,
  DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_BorderColorRight,

  DB_LeftSidebar_MenuItem_Hauptkategorie_marginTopBottom,
  DB_LeftSidebar_MenuItem_Hauptkategorie_marginLeftRight,
  DB_LeftSidebar_MenuItem_Hauptkategorie_paddingLeftRight,
  DB_LeftSidebar_MenuItem_Hauptkategorie_paddingTopBottom,

  DB_LeftSidebar_MenuItem_Hauptkategorie_letterSpacing,
  DB_LeftSidebar_MenuItem_Hauptkategorie_ShadowHorizontal,
  DB_LeftSidebar_MenuItem_Hauptkategorie_ShadowVertical,
  DB_LeftSidebar_MenuItem_Hauptkategorie_ShadowBlur,
  DB_LeftSidebar_MenuItem_Hauptkategorie_ShadowColor,
  DB_LeftSidebar_MenuItem_Hauptkategorie_TextDecoration,
  DB_LeftSidebar_MenuItem_Hauptkategorie_TextDecorationStyle,

  DB_LeftSidebar_MenuItem_Hauptkategorie_marginTopBottom_Hover,
  DB_LeftSidebar_MenuItem_Hauptkategorie_marginLeftRight_Hover,
  DB_LeftSidebar_MenuItem_Hauptkategorie_paddingLeftRight_Hover,
  DB_LeftSidebar_MenuItem_Hauptkategorie_paddingTopBottom_Hover,

  DB_LeftSidebar_MenuItem_Hauptkategorie_letterSpacing_Hover,
  DB_LeftSidebar_MenuItem_Hauptkategorie_ShadowHorizontal_Hover,
  DB_LeftSidebar_MenuItem_Hauptkategorie_ShadowVertical_Hover,
  DB_LeftSidebar_MenuItem_Hauptkategorie_ShadowBlur_Hover,
  DB_LeftSidebar_MenuItem_Hauptkategorie_ShadowColor_Hover,
  DB_LeftSidebar_MenuItem_Hauptkategorie_TextDecoration_Hover,
  DB_LeftSidebar_MenuItem_Hauptkategorie_TextDecorationStyle_Hover,
}) => {
  // DB_LeftSidebar - Hauptkategorie Hover

  const useStyles = createUseStyles({
    menuItem_hauptkategorie: {
      fontFamily: `${DB_fontFace_hauptkategorieTitel_FontFamilyName}`,
      fontWeight: `${DB_LeftSidebar_MenuItem_Hauptkategorie_FontWeight}`,
      color: `${DB_LeftSidebar_MenuItem_Hauptkategorie_FontColor}`,
      fontSize: `${DB_LeftSidebar_MenuItem_Hauptkategorie_FontSize}px`,
      backgroundColor: `${
        SideBarLeft_Hauptkategorie_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut
          ? "rgba(255,0,0,0.5)"
          : SideBarLeft_Hauptkategorie_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut
          ? "rgba(255,0,0,0.3)"
          : DB_LeftSidebar_MenuItem_Hauptkategorie_BackgroundColor
      }`,
      textAlign: `${DB_LeftSidebar_MenuItem_Hauptkategorie_TextAlign}`,
      borderTop: `${
        SideBarLeft_Hauptkategorie_Farbe_Groesse_Stil_OnMouseOverAndOut
          ? "3"
          : SideBarLeft_Hauptkategorie_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
          ? "3"
          : DB_LeftSidebar_MenuItem_Hauptkategorie_BorderTop
      }px
        ${
          SideBarLeft_Hauptkategorie_Farbe_Groesse_Stil_OnMouseOverAndOut
            ? "solid"
            : SideBarLeft_Hauptkategorie_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
            ? "dotted"
            : DB_LeftSidebar_MenuItem_Hauptkategorie_BorderStyleTop
        }
        ${
          SideBarLeft_Hauptkategorie_Farbe_Groesse_Stil_OnMouseOverAndOut
            ? "red"
            : SideBarLeft_Hauptkategorie_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
            ? "red"
            : DB_LeftSidebar_MenuItem_Hauptkategorie_BorderColorTop
        }`,
      borderBottom: `${
        SideBarLeft_Hauptkategorie_Farbe_Groesse_Stil_OnMouseOverAndOut
          ? "3"
          : SideBarLeft_Hauptkategorie_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
          ? "3"
          : DB_LeftSidebar_MenuItem_Hauptkategorie_BorderBottom
      }px
        ${
          SideBarLeft_Hauptkategorie_Farbe_Groesse_Stil_OnMouseOverAndOut
            ? "solid"
            : SideBarLeft_Hauptkategorie_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
            ? "dotted"
            : DB_LeftSidebar_MenuItem_Hauptkategorie_BorderStyleBottom
        }
        ${
          SideBarLeft_Hauptkategorie_Farbe_Groesse_Stil_OnMouseOverAndOut
            ? "red"
            : SideBarLeft_Hauptkategorie_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
            ? "red"
            : DB_LeftSidebar_MenuItem_Hauptkategorie_BorderColorBottom
        }`,
      borderLeft: `${
        SideBarLeft_Hauptkategorie_Farbe_Groesse_Stil_OnMouseOverAndOut
          ? "3"
          : SideBarLeft_Hauptkategorie_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
          ? "3"
          : DB_LeftSidebar_MenuItem_Hauptkategorie_BorderLeft
      }px
        ${
          SideBarLeft_Hauptkategorie_Farbe_Groesse_Stil_OnMouseOverAndOut
            ? "solid"
            : SideBarLeft_Hauptkategorie_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
            ? "dotted"
            : DB_LeftSidebar_MenuItem_Hauptkategorie_BorderStyleLeft
        }
        ${
          SideBarLeft_Hauptkategorie_Farbe_Groesse_Stil_OnMouseOverAndOut
            ? "red"
            : SideBarLeft_Hauptkategorie_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
            ? "red"
            : DB_LeftSidebar_MenuItem_Hauptkategorie_BorderColorLeft
        }`,
      borderRight: `${
        SideBarLeft_Hauptkategorie_Farbe_Groesse_Stil_OnMouseOverAndOut
          ? "3"
          : SideBarLeft_Hauptkategorie_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
          ? "3"
          : DB_LeftSidebar_MenuItem_Hauptkategorie_BorderRight
      }px
        ${
          SideBarLeft_Hauptkategorie_Farbe_Groesse_Stil_OnMouseOverAndOut
            ? "solid"
            : SideBarLeft_Hauptkategorie_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
            ? "dotted"
            : DB_LeftSidebar_MenuItem_Hauptkategorie_BorderStyleRight
        }
        ${
          SideBarLeft_Hauptkategorie_Farbe_Groesse_Stil_OnMouseOverAndOut
            ? "red"
            : SideBarLeft_Hauptkategorie_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
            ? "red"
            : DB_LeftSidebar_MenuItem_Hauptkategorie_BorderColorRight
        }`,
      borderTopRightRadius: `${DB_LeftSidebar_MenuItem_Hauptkategorie_BorderTopRightRadius}px`,
      borderTopLeftRadius: `${DB_LeftSidebar_MenuItem_Hauptkategorie_BorderTopLeftRadius}px`,
      borderBottomRightRadius: `${DB_LeftSidebar_MenuItem_Hauptkategorie_BorderBottomRightRadius}px`,
      borderBottomLeftRadius: `${DB_LeftSidebar_MenuItem_Hauptkategorie_BorderBottomLeftRadius}px`,
      marginLeft: `${DB_LeftSidebar_MenuItem_Hauptkategorie_marginLeftRight}px`,
      marginRight: `${DB_LeftSidebar_MenuItem_Hauptkategorie_marginLeftRight}px`,
      marginTop: `${DB_LeftSidebar_MenuItem_Hauptkategorie_marginTopBottom}px`,
      marginBottom: `${DB_LeftSidebar_MenuItem_Hauptkategorie_marginTopBottom}px`,
      paddingLeft: `${DB_LeftSidebar_MenuItem_Hauptkategorie_paddingLeftRight}px`,
      paddingRight: `${DB_LeftSidebar_MenuItem_Hauptkategorie_paddingLeftRight}px`,
      paddingTop: `${DB_LeftSidebar_MenuItem_Hauptkategorie_paddingTopBottom}px`,
      paddingBottom: `${DB_LeftSidebar_MenuItem_Hauptkategorie_paddingTopBottom}px`,
      letterSpacing: `${DB_LeftSidebar_MenuItem_Hauptkategorie_letterSpacing}px`,
      textShadow: `${DB_LeftSidebar_MenuItem_Hauptkategorie_ShadowHorizontal}px
        ${DB_LeftSidebar_MenuItem_Hauptkategorie_ShadowVertical}px
        ${DB_LeftSidebar_MenuItem_Hauptkategorie_ShadowBlur}px
        ${DB_LeftSidebar_MenuItem_Hauptkategorie_ShadowColor}`,
      textDecoration: `${DB_LeftSidebar_MenuItem_Hauptkategorie_TextDecoration}
        ${DB_LeftSidebar_MenuItem_Hauptkategorie_TextDecorationStyle}`,

      "&:hover": {
        fontWeight: `${DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_FontWeight}`,
        color: `${DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_FontColor}`,
        fontSize: `${DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_FontSize}px`,
        backgroundColor: `${DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_BackgroundColor}`,
        textAlign: `${DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_TextAlign}`,
        borderTop: `${DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_BorderTop}px
        ${DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_BorderStyleTop}
        ${DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_BorderColorTop}`,
        borderBottom: `${DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_BorderBottom}px
        ${DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_BorderStyleBottom}
        ${DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_BorderColorBottom}`,
        borderLeft: `${DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_BorderLeft}px
        ${DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_BorderStyleLeft}
        ${DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_BorderColorLeft}`,
        borderRight: `${DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_BorderRight}px
        ${DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_BorderStyleRight}
        ${DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_BorderColorRight}`,
        borderTopRightRadius: `${DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_BorderTopRightRadius}px`,
        borderTopLeftRadius: `${DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_BorderTopLeftRadius}px`,
        borderBottomRightRadius: `${DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_BorderBottomRightRadius}px`,
        borderBottomLeftRadius: `${DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_BorderBottomLeftRadius}px`,

        marginLeft: `${DB_LeftSidebar_MenuItem_Hauptkategorie_marginLeftRight_Hover}px`,
        marginRight: `${DB_LeftSidebar_MenuItem_Hauptkategorie_marginLeftRight_Hover}px`,
        marginTop: `${DB_LeftSidebar_MenuItem_Hauptkategorie_marginTopBottom_Hover}px`,
        marginBottom: `${DB_LeftSidebar_MenuItem_Hauptkategorie_marginTopBottom_Hover}px`,
        paddingLeft: `${DB_LeftSidebar_MenuItem_Hauptkategorie_paddingLeftRight_Hover}px`,
        paddingRight: `${DB_LeftSidebar_MenuItem_Hauptkategorie_paddingLeftRight_Hover}px`,
        paddingTop: `${DB_LeftSidebar_MenuItem_Hauptkategorie_paddingTopBottom_Hover}px`,
        paddingBottom: `${DB_LeftSidebar_MenuItem_Hauptkategorie_paddingTopBottom_Hover}px`,
        letterSpacing: `${DB_LeftSidebar_MenuItem_Hauptkategorie_letterSpacing_Hover}px`,
        textShadow: `${DB_LeftSidebar_MenuItem_Hauptkategorie_ShadowHorizontal_Hover}px
        ${DB_LeftSidebar_MenuItem_Hauptkategorie_ShadowVertical_Hover}px
        ${DB_LeftSidebar_MenuItem_Hauptkategorie_ShadowBlur_Hover}px
        ${DB_LeftSidebar_MenuItem_Hauptkategorie_ShadowColor_Hover}`,
        textDecoration: `${DB_LeftSidebar_MenuItem_Hauptkategorie_TextDecoration_Hover}
        ${DB_LeftSidebar_MenuItem_Hauptkategorie_TextDecorationStyle_Hover}`,
      },
    },
  });

  const classes = useStyles();

  return (
    <div
      className="hauptkategorie"
      style={{ cursor: "pointer" }}
      key={"CategoryOne" + category.kategorie + Math.random()}
      onClick={() => categoryLeftSideHandler(`${category.kategorie}`)}
    >
      <li className={`mb-1 ${classes.menuItem_hauptkategorie}`}>
        {category.kategorie}
      </li>
    </div>
  );
};

export default SideBarLeftMainKategorie;
