// @flow
import React, { useState } from "react";

import axios from "axios";
import { Button, InputGroup, Form } from "react-bootstrap";

import { useTranslation } from "react-i18next";
import { toast, Zoom } from "react-toastify";
import { authenticate, isAuth, getCookie } from "../helpers/helpers";
import "react-toastify/dist/ReactToastify.min.css";
const Login = (props) => {
  const { t } = useTranslation();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loginCounter, setLoginCounter] = useState(0);
  const [showPassword, setShowPassword] = useState(false);

  const setEmailHandler = (e) => {
    setEmail(e.target.value);
  };

  const setPasswordHandler = (e) => {
    setPassword(e.target.value);
  };

  const clickSubmit = (event) => {
    event.preventDefault();

    axios({
      method: "POST",
      url: `${process.env.REACT_APP_PUBLIC_SERVER_URL}/auth/signin`,
      data: { email, password },
    })
      .then((response) => {
        if (response.status === 200) {
          props.anmeldungErfolgreich();
          toast.success(`${t("AnmeldungErfolgreich")}`, {
            toastId: "custom-id-yes",
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            transition: Zoom,
          });

          authenticate(response, () => {});
        } else if (response.status === 201) {
          toast.error(`${t("AnmeldungFehlgeschlagen")}`, {
            toastId: "custom-id-yes",
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            transition: Zoom,
          });
        }
      })
      .catch((error) => {
        if (loginCounter < 3) {
          toast.error(`${t("AnmeldungFehlgeschlagen")}`, {
            toastId: "custom-id-yes",
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            transition: Zoom,
          });
        } else {
          toast.error(`${t("MehrfachFalschEingegeben")}`, {
            toastId: "custom-id-yes",
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            transition: Zoom,
          });
        }

        setLoginCounter((prev) => prev + 1);
      });
  };

  const showPasswordHandler = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="p-0 m-0">
      <div className="text-center" style={{ height: "40px" }}></div>
      <form style={{ paddingLeft: "45px", paddingRight: "45px" }} action="#">
        <div className="mb-1 text-center">
          <label
            style={{ color: "white", fontSize: "16px" }}
            htmlFor="emailaddress"
            className="form-label"
          >
            {t("Email")}
          </label>
        </div>
        <InputGroup className="mb-4">
          <InputGroup.Text className="emailUniIconContainer">
            <span id="emailUniIcon">✉️</span>
          </InputGroup.Text>
          <Form.Control
            aria-label="email"
            aria-describedby="emailUniIcon"
            onChange={setEmailHandler}
            value={email}
            className="form-control inputEmailPw"
            type="email"
            id="emailaddress"
            placeholder={t("Email")}
          />
        </InputGroup>

        <div className="mb-1 text-center">
          <label
            style={{ color: "white", fontSize: "16px" }}
            htmlFor="emailaddress"
            className="form-label"
          >
            {t("Passwort")}
          </label>
        </div>
        <InputGroup className="mb-4">
          <InputGroup.Text className="emailUniIconContainer">
            <span
              id="passwordUniIcon"
              style={{ cursor: "pointer" }}
              onClick={showPasswordHandler}
            >
              🔒
            </span>
          </InputGroup.Text>

          <Form.Control
            aria-label="password"
            aria-describedby="passwordUniIcon"
            onChange={setPasswordHandler}
            value={password}
            className="form-control inputEmailPw"
            type={showPassword ? "text" : "password"}
            required=""
            id="password"
            placeholder={t("Passwort")}
          />
        </InputGroup>

        <div className="mb-1 text-center">
          {loginCounter < 4 ? (
            <div className="mb-4 mt-4 text-center">
              <Button onClick={clickSubmit} variant="primary" type="submit">
                {t("Anmelden")}
              </Button>
            </div>
          ) : (
            <div className="mb-4 mt-2 text-center">
              <Button variant="secondary" type="submit" disabled="disabled">
                {t("Anmelden")}
              </Button>
            </div>
          )}
        </div>
      </form>

      {/* <div className="text-center">
                Noch kein Benutzerkonto: &nbsp;
                <span
                    onClick={props.registrierenAktiv}
                    className="text-primary"
                    style={{ fontWeight: 'bold', cursor: 'pointer' }}>
                    Jetzt registrieren
                </span>
            </div>

            <div className="text-center mt-1">
                <span onClick={props.passwortVergessenAktiv} className="text-muted" style={{ cursor: 'pointer' }}>
                    <small style={{ fontSize: '0.8rem' }}>{t('Passwort vergessen?')}</small>
                </span>
          
          </div> */}
    </div>
  );
};

export default Login;
