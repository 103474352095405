// @flow
import React, { useState } from "react";
import axios from "axios";
import { Button, InputGroup, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { toast, Zoom } from "react-toastify";

const RegisterEmailPW = ({
  AGBsConfirmHandler,
  confirmAGB,
  confirmAGBTest,
  regEmail,
  doRegEmailHandler,
  regPasswort,
  doRegPasswortHandler,
  regPasswortRepeat,
  doRegPasswortRepeatHandler,
  eMailPWconfirm,
  eMailValid,
  passwortValid,
  passwortRepValid,
  passwortNotDuplicated,
}) => {
  const { t } = useTranslation();

  const [showPassword, setShowPassword] = useState(false);

  const showPasswordHandler = () => {
    setShowPassword(!showPassword);
  };

  return (
    <>
      <form
        style={{ paddingLeft: "45px", paddingRight: "45px", marginTop: "10px" }}
        action="#"
      >
        <div className="mb-1 mt-3 text-center">
          <label
            style={{ color: "white", fontSize: "16px" }}
            htmlFor="emailaddress"
            className="form-label"
          >
            {t("Email")}
          </label>
        </div>
        <InputGroup>
          <InputGroup.Text
            className={
              eMailValid
                ? "emailUniIconContainer regInputValid"
                : "emailUniIconContainer regInputInvalid"
            }
          >
            <span id="emailUniIcon">✉️</span>
          </InputGroup.Text>
          <Form.Control
            aria-label="email"
            aria-describedby="emailUniIcon"
            onChange={doRegEmailHandler}
            value={regEmail}
            className={
              eMailValid
                ? "regInputValid form-control inputEmailPw"
                : "regInputInvalid form-control inputEmailPw"
            }
            type="email"
            id="emailaddress"
            placeholder={t("Email")}
          />
        </InputGroup>

        <div className="mb-4" style={{ paddingTop: "2px", paddingLeft: "2px" }}>
          {!eMailValid && (
            <div
              style={{ fontSize: "13px", color: "#eb0b3d", fontWeight: "bold" }}
            >
              {t("EMailMeldung")}
            </div>
          )}
        </div>

        <div className="mb-1 text-center">
          <label
            style={{ color: "white", fontSize: "16px" }}
            htmlFor="emailaddress"
            className="form-label"
          >
            {t("Passwort")}
          </label>
        </div>
        <InputGroup>
          <InputGroup.Text
            className={
              passwortValid
                ? "emailUniIconContainer regInputValid"
                : "emailUniIconContainer regInputInvalid"
            }
          >
            <span
              id="passwordUniIcon"
              style={{ cursor: "pointer" }}
              onClick={showPasswordHandler}
            >
              🔒
            </span>
          </InputGroup.Text>
          <Form.Control
            aria-label="password"
            aria-describedby="passwordUniIcon"
            onChange={doRegPasswortHandler}
            value={regPasswort}
            className={
              passwortValid
                ? "regInputValid form-control inputEmailPw"
                : "regInputInvalid form-control inputEmailPw"
            }
            type={showPassword ? "text" : "password"}
            required=""
            id="password"
            placeholder={t("Passwort")}
          />
        </InputGroup>
        <div className="mb-4" style={{ paddingTop: "2px", paddingLeft: "2px" }}>
          {!passwortValid && (
            <div
              style={{ fontSize: "13px", color: "#eb0b3d", fontWeight: "bold" }}
            >
              {t("PasswortMeldung")}
            </div>
          )}
        </div>
        <div className="mb-1 text-center">
          <label
            style={{ color: "white", fontSize: "16px" }}
            htmlFor="emailaddress"
            className="form-label"
          >
            {t("PasswortWiederholen")}
          </label>
        </div>

        <InputGroup>
          <InputGroup.Text
            className={
              passwortRepValid && passwortNotDuplicated
                ? "emailUniIconContainer regInputValid"
                : "emailUniIconContainer regInputInvalid"
            }
          >
            <span
              id="passwordUniIcon"
              style={{ cursor: "pointer" }}
              onClick={showPasswordHandler}
            >
              🔏
            </span>
          </InputGroup.Text>

          <Form.Control
            aria-label="password"
            aria-describedby="passwordUniIcon"
            onChange={doRegPasswortRepeatHandler}
            value={regPasswortRepeat}
            className={
              passwortRepValid && passwortNotDuplicated
                ? "regInputValid form-control inputEmailPw"
                : "regInputInvalid form-control inputEmailPw"
            }
            type={showPassword ? "text" : "password"}
            required=""
            id="password"
            placeholder={t("Passwort")}
          />
        </InputGroup>
        <div className="mb-4" style={{ paddingTop: "2px", paddingLeft: "2px" }}>
          {!passwortRepValid && (
            <div
              style={{ fontSize: "13px", color: "#eb0b3d", fontWeight: "bold" }}
            >
              {t("PasswortWiederholenMeldung")}
            </div>
          )}

          {!passwortNotDuplicated && (
            <div
              style={{ fontSize: "13px", color: "#eb0b3d", fontWeight: "bold" }}
            >
              {t("PasswortIdentischMeldung")}
            </div>
          )}
        </div>
        <Form.Check
          style={{
            display: "inline-block",
            marginRight: "10px",
            float: "left",
          }}
          onChange={AGBsConfirmHandler}
          defaultChecked={confirmAGB}
          type="checkbox"
          id="abg"
        />
        <label
          for="abg"
          style={{
            color: "white",
            marginTop: "7px",
            display: "block",
            overflow: "unset",
          }}
        >
          <a
            href={`${process.env.REACT_APP_PUBLIC_SERVER_URL}/impressum`}
            target="_blank"
            style={{ textDecoration: "underline" }}
          >
            {t("AGBs")}
          </a>{" "}
          {t("AGBAkzeptieren")}
        </label>

        <div className="mb-3" style={{ paddingTop: "2px", paddingLeft: "2px" }}>
          {confirmAGBTest && (
            <div
              style={{ fontSize: "13px", color: "#eb0b3d", fontWeight: "bold" }}
            >
              {t("AGBMeldung")}
            </div>
          )}
        </div>
        <div className="mt-5 d-flex justify-content-end">
          <div className="mb-4" style={{ display: "inline-block" }}>
            <Button variant="primary" onClick={eMailPWconfirm}>
              {t("Weiter")}
            </Button>
          </div>
        </div>
      </form>
    </>
  );
};

export default RegisterEmailPW;
