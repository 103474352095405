import { createUseStyles } from "react-jss";

const SideBarRightFilterItem = ({
  filterItemBezeichnung,
  filterZustand,
  filterProduktsHandler,
  isCheck,
  SideBarLeft_FilterItem_Farbe_Groesse_Stil_OnMouseOverAndOut,
  SideBarLeft_FilterItem_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut,
  SideBarLeft_FilterItem_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut,
  SideBarLeft_FilterItem_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut,
  DB_fontFace_FilterItemFontFamily_FontFamilyName,
  DB_fontFace_FilterItemFontFamily_FontFamilySrc,
  DB_LeftSidebar_MenuItem_FilterItem_FontWeight,
  DB_LeftSidebar_MenuItem_FilterItem_Hover_FontWeight,
  DB_LeftSidebar_MenuItem_FilterItem_FontColor,
  DB_LeftSidebar_MenuItem_FilterItem_Hover_FontColor,
  DB_LeftSidebar_MenuItem_FilterItem_FontSize,
  DB_LeftSidebar_MenuItem_FilterItem_Hover_FontSize,
  DB_LeftSidebar_MenuItem_FilterItem_BackgroundColor,
  DB_LeftSidebar_MenuItem_FilterItem_Hover_BackgroundColor,
  DB_LeftSidebar_MenuItem_FilterItem_TextAlign,
  DB_LeftSidebar_MenuItem_FilterItem_Hover_TextAlign,
  DB_LeftSidebar_MenuItem_FilterItem_BorderTop,
  DB_LeftSidebar_MenuItem_FilterItem_BorderStyleTop,
  DB_LeftSidebar_MenuItem_FilterItem_BorderColorTop,
  DB_LeftSidebar_MenuItem_FilterItem_BorderBottom,
  DB_LeftSidebar_MenuItem_FilterItem_BorderStyleBottom,
  DB_LeftSidebar_MenuItem_FilterItem_BorderColorBottom,
  DB_LeftSidebar_MenuItem_FilterItem_BorderLeft,
  DB_LeftSidebar_MenuItem_FilterItem_BorderStyleLeft,
  DB_LeftSidebar_MenuItem_FilterItem_BorderColorLeft,
  DB_LeftSidebar_MenuItem_FilterItem_BorderRight,
  DB_LeftSidebar_MenuItem_FilterItem_BorderStyleRight,
  DB_LeftSidebar_MenuItem_FilterItem_BorderColorRight,

  DB_LeftSidebar_MenuItem_FilterItem_BorderTopRightRadius,
  DB_LeftSidebar_MenuItem_FilterItem_BorderTopLeftRadius,
  DB_LeftSidebar_MenuItem_FilterItem_BorderBottomRightRadius,
  DB_LeftSidebar_MenuItem_FilterItem_BorderBottomLeftRadius,

  DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderTopRightRadius,
  DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderTopLeftRadius,
  DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderBottomRightRadius,
  DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderBottomLeftRadius,

  DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderTop,
  DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderStyleTop,
  DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderColorTop,

  DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderBottom,
  DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderStyleBottom,
  DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderColorBottom,

  DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderLeft,
  DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderStyleLeft,
  DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderColorLeft,

  DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderRight,
  DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderStyleRight,
  DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderColorRight,

  DB_LeftSidebar_MenuItem_FilterItem_marginTopBottom,
  DB_LeftSidebar_MenuItem_FilterItem_marginLeftRight,
  DB_LeftSidebar_MenuItem_FilterItem_paddingLeftRight,
  DB_LeftSidebar_MenuItem_FilterItem_paddingTopBottom,

  DB_LeftSidebar_MenuItem_FilterItem_letterSpacing,
  DB_LeftSidebar_MenuItem_FilterItem_ShadowHorizontal,
  DB_LeftSidebar_MenuItem_FilterItem_ShadowVertical,
  DB_LeftSidebar_MenuItem_FilterItem_ShadowBlur,
  DB_LeftSidebar_MenuItem_FilterItem_ShadowColor,
  DB_LeftSidebar_MenuItem_FilterItem_TextDecoration,
  DB_LeftSidebar_MenuItem_FilterItem_TextDecorationStyle,

  DB_LeftSidebar_MenuItem_FilterItem_marginTopBottom_Hover,
  DB_LeftSidebar_MenuItem_FilterItem_marginLeftRight_Hover,
  DB_LeftSidebar_MenuItem_FilterItem_paddingLeftRight_Hover,
  DB_LeftSidebar_MenuItem_FilterItem_paddingTopBottom_Hover,

  DB_LeftSidebar_MenuItem_FilterItem_letterSpacing_Hover,
  DB_LeftSidebar_MenuItem_FilterItem_ShadowHorizontal_Hover,
  DB_LeftSidebar_MenuItem_FilterItem_ShadowVertical_Hover,
  DB_LeftSidebar_MenuItem_FilterItem_ShadowBlur_Hover,
  DB_LeftSidebar_MenuItem_FilterItem_ShadowColor_Hover,
  DB_LeftSidebar_MenuItem_FilterItem_TextDecoration_Hover,
  DB_LeftSidebar_MenuItem_FilterItem_TextDecorationStyle_Hover,
}) => {
  const useStyles = createUseStyles({
    filterItem: {
      fontFamily: `${DB_fontFace_FilterItemFontFamily_FontFamilyName}`,
      fontWeight: `${DB_LeftSidebar_MenuItem_FilterItem_FontWeight}`,
      color: `${DB_LeftSidebar_MenuItem_FilterItem_FontColor}`,
      fontSize: `${DB_LeftSidebar_MenuItem_FilterItem_FontSize}px`,
      backgroundColor: `${
        SideBarLeft_FilterItem_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut
          ? "rgba(255,0,0,0.5)"
          : SideBarLeft_FilterItem_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut
          ? "rgba(255,0,0,0.3)"
          : DB_LeftSidebar_MenuItem_FilterItem_BackgroundColor
      }`,
      textAlign: `${DB_LeftSidebar_MenuItem_FilterItem_TextAlign}`,
      borderTop: `${
        SideBarLeft_FilterItem_Farbe_Groesse_Stil_OnMouseOverAndOut
          ? "3"
          : SideBarLeft_FilterItem_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
          ? "3"
          : DB_LeftSidebar_MenuItem_FilterItem_BorderTop
      }px
          ${
            SideBarLeft_FilterItem_Farbe_Groesse_Stil_OnMouseOverAndOut
              ? "solid"
              : SideBarLeft_FilterItem_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
              ? "dotted"
              : DB_LeftSidebar_MenuItem_FilterItem_BorderStyleTop
          }
          ${
            SideBarLeft_FilterItem_Farbe_Groesse_Stil_OnMouseOverAndOut
              ? "red"
              : SideBarLeft_FilterItem_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
              ? "red"
              : DB_LeftSidebar_MenuItem_FilterItem_BorderColorTop
          }`,
      borderBottom: `${
        SideBarLeft_FilterItem_Farbe_Groesse_Stil_OnMouseOverAndOut
          ? "3"
          : SideBarLeft_FilterItem_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
          ? "3"
          : DB_LeftSidebar_MenuItem_FilterItem_BorderBottom
      }px
          ${
            SideBarLeft_FilterItem_Farbe_Groesse_Stil_OnMouseOverAndOut
              ? "solid"
              : SideBarLeft_FilterItem_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
              ? "dotted"
              : DB_LeftSidebar_MenuItem_FilterItem_BorderStyleBottom
          }
          ${
            SideBarLeft_FilterItem_Farbe_Groesse_Stil_OnMouseOverAndOut
              ? "red"
              : SideBarLeft_FilterItem_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
              ? "red"
              : DB_LeftSidebar_MenuItem_FilterItem_BorderColorBottom
          }`,
      borderLeft: `${
        SideBarLeft_FilterItem_Farbe_Groesse_Stil_OnMouseOverAndOut
          ? "3"
          : SideBarLeft_FilterItem_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
          ? "3"
          : DB_LeftSidebar_MenuItem_FilterItem_BorderLeft
      }px
          ${
            SideBarLeft_FilterItem_Farbe_Groesse_Stil_OnMouseOverAndOut
              ? "solid"
              : SideBarLeft_FilterItem_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
              ? "dotted"
              : DB_LeftSidebar_MenuItem_FilterItem_BorderStyleLeft
          }
          ${
            SideBarLeft_FilterItem_Farbe_Groesse_Stil_OnMouseOverAndOut
              ? "red"
              : SideBarLeft_FilterItem_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
              ? "red"
              : DB_LeftSidebar_MenuItem_FilterItem_BorderColorLeft
          }`,
      borderRight: `${
        SideBarLeft_FilterItem_Farbe_Groesse_Stil_OnMouseOverAndOut
          ? "3"
          : SideBarLeft_FilterItem_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
          ? "3"
          : DB_LeftSidebar_MenuItem_FilterItem_BorderRight
      }px
          ${
            SideBarLeft_FilterItem_Farbe_Groesse_Stil_OnMouseOverAndOut
              ? "solid"
              : SideBarLeft_FilterItem_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
              ? "dotted"
              : DB_LeftSidebar_MenuItem_FilterItem_BorderStyleRight
          }
          ${
            SideBarLeft_FilterItem_Farbe_Groesse_Stil_OnMouseOverAndOut
              ? "red"
              : SideBarLeft_FilterItem_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
              ? "red"
              : DB_LeftSidebar_MenuItem_FilterItem_BorderColorRight
          }`,
      borderTopRightRadius: `${DB_LeftSidebar_MenuItem_FilterItem_BorderTopRightRadius}px`,
      borderTopLeftRadius: `${DB_LeftSidebar_MenuItem_FilterItem_BorderTopLeftRadius}px`,
      borderBottomRightRadius: `${DB_LeftSidebar_MenuItem_FilterItem_BorderBottomRightRadius}px`,
      borderBottomLeftRadius: `${DB_LeftSidebar_MenuItem_FilterItem_BorderBottomLeftRadius}px`,
      marginLeft: `${DB_LeftSidebar_MenuItem_FilterItem_marginLeftRight}px`,
      marginRight: `${DB_LeftSidebar_MenuItem_FilterItem_marginLeftRight}px`,
      marginTop: `${DB_LeftSidebar_MenuItem_FilterItem_marginTopBottom}px`,
      marginBottom: `${DB_LeftSidebar_MenuItem_FilterItem_marginTopBottom}px`,
      paddingLeft: `${DB_LeftSidebar_MenuItem_FilterItem_paddingLeftRight}px`,
      paddingRight: `${DB_LeftSidebar_MenuItem_FilterItem_paddingLeftRight}px`,
      paddingTop: `${DB_LeftSidebar_MenuItem_FilterItem_paddingTopBottom}px`,
      paddingBottom: `${DB_LeftSidebar_MenuItem_FilterItem_paddingTopBottom}px`,
      letterSpacing: `${DB_LeftSidebar_MenuItem_FilterItem_letterSpacing}px`,
      textShadow: `${DB_LeftSidebar_MenuItem_FilterItem_ShadowHorizontal}px
          ${DB_LeftSidebar_MenuItem_FilterItem_ShadowVertical}px
          ${DB_LeftSidebar_MenuItem_FilterItem_ShadowBlur}px
          ${DB_LeftSidebar_MenuItem_FilterItem_ShadowColor}`,
      textDecoration: `${DB_LeftSidebar_MenuItem_FilterItem_TextDecoration}
          ${DB_LeftSidebar_MenuItem_FilterItem_TextDecorationStyle}`,
      "&:hover": {
        fontWeight: `${DB_LeftSidebar_MenuItem_FilterItem_Hover_FontWeight}`,
        color: `${DB_LeftSidebar_MenuItem_FilterItem_Hover_FontColor}`,
        fontSize: `${DB_LeftSidebar_MenuItem_FilterItem_Hover_FontSize}px`,
        backgroundColor: `${DB_LeftSidebar_MenuItem_FilterItem_Hover_BackgroundColor}`,
        textAlign: `${DB_LeftSidebar_MenuItem_FilterItem_Hover_TextAlign}`,
        borderTop: `${DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderTop}px
          ${DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderStyleTop}
          ${DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderColorTop}`,
        borderBottom: `${DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderBottom}px
          ${DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderStyleBottom}
          ${DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderColorBottom}`,
        borderLeft: `${DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderLeft}px
          ${DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderStyleLeft}
          ${DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderColorLeft}`,
        borderRight: `${DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderRight}px
          ${DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderStyleRight}
          ${DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderColorRight}`,
        borderTopRightRadius: `${DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderTopRightRadius}px`,
        borderTopLeftRadius: `${DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderTopLeftRadius}px`,
        borderBottomRightRadius: `${DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderBottomRightRadius}px`,
        borderBottomLeftRadius: `${DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderBottomLeftRadius}px`,

        marginLeft: `${DB_LeftSidebar_MenuItem_FilterItem_marginLeftRight_Hover}px`,
        marginRight: `${DB_LeftSidebar_MenuItem_FilterItem_marginLeftRight_Hover}px`,
        marginTop: `${DB_LeftSidebar_MenuItem_FilterItem_marginTopBottom_Hover}px`,
        marginBottom: `${DB_LeftSidebar_MenuItem_FilterItem_marginTopBottom_Hover}px`,
        paddingLeft: `${DB_LeftSidebar_MenuItem_FilterItem_paddingLeftRight_Hover}px`,
        paddingRight: `${DB_LeftSidebar_MenuItem_FilterItem_paddingLeftRight_Hover}px`,
        paddingTop: `${DB_LeftSidebar_MenuItem_FilterItem_paddingTopBottom_Hover}px`,
        paddingBottom: `${DB_LeftSidebar_MenuItem_FilterItem_paddingTopBottom_Hover}px`,
        letterSpacing: `${DB_LeftSidebar_MenuItem_FilterItem_letterSpacing_Hover}px`,
        textShadow: `${DB_LeftSidebar_MenuItem_FilterItem_ShadowHorizontal_Hover}px
          ${DB_LeftSidebar_MenuItem_FilterItem_ShadowVertical_Hover}px
          ${DB_LeftSidebar_MenuItem_FilterItem_ShadowBlur_Hover}px
          ${DB_LeftSidebar_MenuItem_FilterItem_ShadowColor_Hover}`,
        textDecoration: `${DB_LeftSidebar_MenuItem_FilterItem_TextDecoration_Hover}
          ${DB_LeftSidebar_MenuItem_FilterItem_TextDecorationStyle_Hover}`,
      },
      formCheckLabels: {
        display: "block",
        position: "relative",
        overflow: "hidden",
        cursor: "pointer",
      },
    },
  });

  const classes = useStyles();

  return (
    <div
      className={`form-check ${classes.filterItem}`}
      key={`${filterZustand}`}
    >
      <input
        className={`form-check-input ${filterItemBezeichnung}`}
        type="checkbox"
        value={filterZustand}
        id={filterZustand}
        onChange={filterProduktsHandler}
        checked={isCheck.find((element) => {
          if (filterItemBezeichnung == "zustand") {
            if (element.zustand == filterZustand) {
              return true;
            } else {
              return false;
            }
          } else if (filterItemBezeichnung == "material") {
            if (element.material == filterZustand) {
              return true;
            } else {
              return false;
            }
          } else if (filterItemBezeichnung == "marke") {
            if (element.marke == filterZustand) {
              return true;
            } else {
              return false;
            }
          } else if (filterItemBezeichnung == "groesse") {
            if (element.groesse == filterZustand) {
              return true;
            } else {
              return false;
            }
          } else if (filterItemBezeichnung == "farbe") {
            if (element.farbe == filterZustand) {
              return true;
            } else {
              return false;
            }
          }
        })}
      />
      <label className={classes.formCheckLabels} htmlFor={filterZustand}>
        {filterZustand}
      </label>
    </div>
  );
};
export default SideBarRightFilterItem;
