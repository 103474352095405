import React, { useEffect, useState, useCallback } from "react";
import axios from "axios";
import { Row, Col, Button, Form, Modal, InputGroup } from "react-bootstrap";
import { ToastContainer, toast, Zoom } from "react-toastify";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Offcanvas from "react-bootstrap/Offcanvas";
import Accordion from "react-bootstrap/Accordion";
import { RgbaColorPicker } from "react-colorful";
import ImageGallery from "react-image-gallery";
import "/node_modules/react-image-gallery/styles/css/image-gallery.css";
import Login from "../components/Login";
import Register from "../components/Register";
import ForgetPassword from "../components/ForgetPassword";
import { isAuth, getCookie, getIdOfUser, signout } from "../helpers/helpers";
import HauptMenueTop from "../components/HauptMenueTop";
import OneTwoThreeFourFilter from "../components/OneTwoThreeFourFilter";
import BodyAllProductCards from "../components/BodyAllProductCards";
import BodyProductDetail from "../components/BodyProductDetail";
import AboutUs from "../components/AboutUs";
import FAQ from "../components/FAQ";
import SideBarLeftKategories from "../components/SideBarLeftKategories";
import SideBarRightFiltersOffCanvas from "../components/SideBarRightFiltersOffCanvas";
import SideBarRightFiltersMain from "../components/SideBarRightFiltersMain";
import SideBarRightSocialIcons from "../components/SideBarRightSocialIcons";
import SideBarRightMessageToBuyer from "../components/SideBarRightMessageToBuyer";
import TopBarStatic from "../components/TopBarStatic";
import TopBarStaticSpecialLeftRight from "../components/TopBarStaticSpecialLeftRight";
import UploadAndDisplayImage from "../components/UploadAndDisplayImage";
import UploadAndDisplayLogoImage from "../components/UploadAndDisplayLogoImage";
import SiteBelongsToUserNav from "../components/SiteBelongsToUserNav";
import SiteBelongsToUserSaveDesign from "../components/SiteBelongsToUserSaveDesign";
import SideBarRightCookieMessage from "../components/SideBarRightCookieMessage";
import ProductFilterTopBar from "../components/ProductFilterTopBar";
import ProductFilterTopBarSpecial from "../components/ProductFilterTopBarSpecial";
import HauptMenueTopSpecial from "../components/HauptMenueTopSpecial";
import ProductFilterTopBarBottom from "../components/ProductFilterTopBarBottom";
import ProductFilterTopBarSpecialRight from "../components/ProductFilterTopBarSpecialRight";
import Footer from "../components/Footer";
import SiteBelongsToUserNavFAQusw from "../components/SiteBelongsToUserNavFAQusw";
import { useTranslation, i18n } from "react-i18next";
import Select from "react-select";
import TopBarStaticBottom from "../components/TopBarStaticBottom";
import MarqueeBox from "../components/MarqueeBox";
import { useSearchParams } from "react-router-dom";
import jwt from "jsonwebtoken";
import "../styles/stylesLogin.css";

function MyShop() {
  const shopName = "swissair";
  const [allProducts, setAllProducts] = useState([]);
  const [allFrontpageProducts, setAllFrontpageProducts] = useState([]);
  const [ultraCategoriesNew, setUltraCategoriesNew] = useState([]);
  const [allFrontpageProductsState, setAllFrontpageProductsState] = useState(
    []
  );
  const [allProductsState, setAllProductsState] = useState([]);
  const [allProductsStatePagination, setAllProductsStatePagination] = useState(
    []
  );
  const [ultraFiltersState, setUltraFiltersState] = useState();
  const [allProductsStateMenuLeft, setAllProductsStateMenuLeft] =
    useState(allProducts);
  const [allProductsStateMenuRight, setAllProductsStateMenuRight] =
    useState(allProducts);
  const [searchParams, setSearchParams] = useSearchParams();
  const [pageAnzahlCounter, setPageAnzahlCounter] = useState(0);
  const [updateDetailProduct, setUpdateDetailProduct] = useState(0);

  let allFilters = 0;
  let allProductsTemp = [];
  let allFrontpageProductsTemp = [];
  let successData = false;
  let productsUserId = "";
  let language = "";
  let ultraCategoriesNewServer = [];
  let ultraFiltersServer = [];

  const { t, i18n } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [benutzerMeldenModal, setBenutzerMeldenModal] = useState(false);
  const [meldenReason, setMeldenReason] = useState("");
  const [meldeBeschreibung, setMeldeBeschreibung] = useState("");
  const [isAuthState, setIsAuthState] = useState(false);

  const [traceCounter, setTraceCounter] = useState(0);

  const [showSaveOptions, setShowSaveOptions] = useState(false);
  const [designIdentifier, setDesignIdentifier] = useState("");
  const [newDesignIdentifier, setNewDesignIdentifier] = useState("");

  const [countImagePlusOne, setCountImagePlusOne] = useState(0);
  const [countImagePlusTwo, setCountImagePlusTwo] = useState(0);

  const anzahlPaginationCounter = 72;
  const pageAnzahlProductsPrimary = 72;
  const [paginationStart, setPaginationStart] = useState(0);
  const [paginationEnd, setPaginationEnd] = useState(72);

  const [menuClicked, setMenuClicked] = useState(false);
  const [categoryObject, setCategoryObject] = useState({
    kategorie: "",
    unterkategorie: "",
    unterunterkategorie: "",
  });

  const actiTokenActive = searchParams.get("token");
  const pwChangeActive = searchParams.get("resettoken");

  useEffect(() => {
    const loadEverythingFunction = (async) => {
      axios({
        method: "post",
        url: `${process.env.REACT_APP_PUBLIC_SERVER_URL}/shop/receive-frontpage-products`,
        data: { shopName: shopName },
      })
        .then((response) => {
          if (response.status === 200) {
            successData = true;
          } else {
            successData = false;
          }

          if (successData) {
            allFrontpageProductsTemp = response.data.data;
            setAllFrontpageProductsState(allFrontpageProductsTemp);
            setAllFrontpageProducts(allFrontpageProductsTemp);
            !actiTokenActive &&
              !pwChangeActive &&
              !searchParams.get("s") &&
              !(
                searchParams.get("cat") ||
                (searchParams.get("cat") && searchParams.get("sub")) ||
                (searchParams.get("cat") &&
                  searchParams.get("sub") &&
                  searchParams.get("subsub"))
              ) &&
              setSearchParams({ t: 1 });
            setAllProductsStatePagination(allFrontpageProductsTemp);
            setPageAnzahlCounter(allFrontpageProductsTemp.length);
          } else if (response.status === 201) {
            toast.error("Abonnement abgelaufen", {
              toastId: "custom-id-yes",
              position: "bottom-center",
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
              transition: Zoom,
            });
          } else if (response.status === 202) {
            /*    res.writeHead(301, { location: "https://home.supermegashop.com" });
        res.end(); */
          } else {
            /*  res.writeHead(301, { location: "https://home.supermegashop.com" });
        res.end(); */
          }
        })
        .catch((err) => {
          toast.error("No Connection To Server", {
            toastId: "custom-id-yes",
            position: "bottom-center",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            transition: Zoom,
          });
        });

      axios({
        method: "post",
        url: `${process.env.REACT_APP_PUBLIC_SERVER_URL}/shop/receive-all-products`,
        data: { shopName: shopName },
      })
        .then((response) => {
          if (response.status === 200) {
            successData = true;
          } else {
            successData = false;
          }

          if (successData) {
            allProductsTemp = response.data.data;

            setAllProductsStateMenuLeft(allProductsTemp);
            setAllProductsStateMenuRight(allProductsTemp);

            setAllProducts(allProductsTemp);

            ultraCategoriesNewServer = JSON.parse(
              response.data.categoryFilters.ultracategoriesSorted
            );

            setUltraCategoriesNew(ultraCategoriesNewServer);

            ultraFiltersServer = JSON.parse(
              response.data.categoryFilters.ultrafilters
            );

            setUltraFiltersState(ultraFiltersServer);

            productsUserId = response.data.userId;

            language = response.data.language;
          } else if (response.status === 201) {
            toast.error("Subscription deactivated", {
              toastId: "custom-id-yes",
              position: "bottom-center",
              autoClose: 15000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
              transition: Zoom,
            });
          }
        })
        .catch((err) => {
          toast.error("No Connection To Server", {
            toastId: "custom-id-yes",
            position: "bottom-center",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            transition: Zoom,
          });
        });

      if (successData) {
        setAllProducts(allProductsTemp);
      } else {
        /*   res.writeHead(301, { location: "https://home.supermegashop.com" });
    res.end(); */
      }
    };

    loadEverythingFunction();
  }, []);

  const onChangeMeldebeschreibung = (e) => {
    setMeldeBeschreibung(e.target.value);
  };

  const meldenModalHandler = (myBool) => {
    isAuth() ? setBenutzerMeldenModal(myBool) : setSignInModal(true);
  };

  const doMeldenReasonHandler = (e) => {
    setMeldenReason(e.label);
  };

  const regelverstossMeldenSubmit = () => {
    if (!isAuth()) {
    } else {
      const token = getCookie("token");
      axios({
        method: "post",
        url: `${process.env.REACT_APP_PUBLIC_SERVER_URL}/chat/regelverstoss-melden`,
        data: {
          selectedUser: { id: productsUserId },
          meldenReason,
          meldeBeschreibung,
          quelle: "Shop",
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((response) => {
          toast.success(`${t("ErfolgreichGemeldet")}`, {
            toastId: "custom-id-yes",
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            transition: Zoom,
          });
          setBenutzerMeldenModal(false);
        })
        .catch((err) => {
          toast.error(`${t("KeineVerbindungZumServer")}`, {
            toastId: "custom-id-yes",
            position: "top-center",
            autoClose: 7000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            transition: Zoom,
          });
        });
    }
  };

  const showTopProductsHandler = () => {
    setSearchParams({ t: 1 });
    /*  setMenuClicked(true);
    setProductDetailShow(false);
    setAboutUsShow(false);
    setFaqShow(false);
    setAllProductsState(
      allFrontpageProducts.slice(0, pageAnzahlProductsPrimary)
    );
    setPaginationStart(0);
    setPaginationEnd(72);
    setPageAnzahlCounter(allFrontpageProducts.length);
    setAllProductsStateMenuLeft(allFrontpageProducts); */

    setMenuClicked(true);
    setProductDetailShow(false);
    setAboutUsShow(false);
    setFaqShow(false);
  };

  const traceHandler = () => {
    setTraceCounter(Math.floor(Math.random() * 10000000000));
  };

  const setNewDesignAfterDeletion = () => {
    setNewDesignIdentifier("");
  };

  const identifierChangeFunction = (e) => {
    setNewDesignIdentifier(e.target.value);
  };

  const megaUltraShowSaveOptions = () => {
    setShowSaveOptions(!showSaveOptions);
  };

  const countImageFunctionPlusOne = () => {
    setCountImagePlusOne((prev) => prev + 1);
  };

  const countImageFunctionPlusTwo = () => {
    setCountImagePlusTwo((prev) => prev + 1);
  };

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (window.innerWidth <= 768) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, []);

  const token = getCookie("token");

  const cancelSaveDesign = () => {
    setDesignIdentifier("");
    setNewDesignIdentifier("");
  };

  const megaUltraSaveDesign = (event) => {
    event.preventDefault();
    if (!userOwnsThisSite) {
      toast.error("Sie können nur Ihre eigene Seite ändern", {
        toastId: "custom-id-yes",
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        transition: Zoom,
      });
    } else {
      let newDesignIdentifierTemp = newDesignIdentifier;
      newDesignIdentifierTemp = newDesignIdentifierTemp
        .replace(/[^a-z0-9äöü]/gi, " ")
        .trim();
      newDesignIdentifierTemp = newDesignIdentifierTemp
        .replace(/\s+/g, "-")
        .trim();
      newDesignIdentifierTemp = newDesignIdentifierTemp
        .replace(/[^a-z0-9äöü]/gi, "-")
        .trim();

      setDesignIdentifier(newDesignIdentifierTemp);
      const design_top_navigation = {
        designIdentifier: newDesignIdentifier,
        shopName: shopName,
        logo_activated: logoActive,
        backgroundImage_activated: backgroundImageActive,
        logo_hintergrundfarbe:
          DB_LeftSidebar_MenuContainer_Logo_DDBackgroundColor.toString(),
        logo_hintergrundfarbe_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuContainer_Logo_DDBackgroundColorNotEncoded
        ),
        logo_schattenfarbe:
          DB_LeftSidebar_MenuItem_MenuContainer_Logo_DDShadowColor,
        logo_schattenfarbe_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_MenuContainer_Logo_DDShadowColorNotEncoded
        ),
        logo_groesse: DB_LeftSidebar_MenuItem_MenuContainer_Logo_DDHeight,
        logo_schattenposition_horizontal:
          DB_LeftSidebar_MenuItem_MenuContainer_Logo_DDShadowHorizontal,
        logo_schattenposition_vertikal:
          DB_LeftSidebar_MenuItem_MenuContainer_Logo_DDShadowVertical,
        logo_schattenrauschen:
          DB_LeftSidebar_MenuItem_MenuContainer_Logo_DDShadowBlur,
        logo_innerer_abstand_links:
          DB_LeftSidebar_MenuItem_MenuContainer_Logo_DDpaddingLeft,
        logo_innerer_abstand_rechts:
          DB_LeftSidebar_MenuItem_MenuContainer_Logo_DDpaddingRight,
        logo_innerer_abstand_oben:
          DB_LeftSidebar_MenuItem_MenuContainer_Logo_DDpaddingTop,
        logo_innerer_abstand_unten:
          DB_LeftSidebar_MenuItem_MenuContainer_Logo_DDpaddingBottom,

        top_navigation_container_backgroundcolor:
          DB_LeftSidebar_MenuContainerAA_BackgroundColor,
        top_navigation_container_backgroundcolor_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuContainerAA_BackgroundColorNotEncoded
        ),
        top_navigation_container_schattenfarbe:
          DB_LeftSidebar_MenuItem_MenuContainerAA_ShadowColor,
        top_navigation_container_schattenfarbe_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_MenuContainerAA_ShadowColorNotEncoded
        ),
        top_navigation_container_schattenposition_horizontal:
          DB_LeftSidebar_MenuItem_MenuContainerAA_ShadowHorizontal,
        top_navigation_container_schattenposition_vertikal:
          DB_LeftSidebar_MenuItem_MenuContainerAA_ShadowVertical,
        top_navigation_container_schattenrauschen:
          DB_LeftSidebar_MenuItem_MenuContainerAA_ShadowBlur,
        top_navigation_container_navigationsverteilung:
          DB_LeftSidebar_MenuItem_MenuContainerAA_Verteilung,
        top_navigation_container_innerer_abstand_links:
          DB_LeftSidebar_MenuItem_MenuContainerAA_paddingLeft,
        top_navigation_container_innerer_abstand_rechts:
          DB_LeftSidebar_MenuItem_MenuContainerAA_paddingRight,
        top_navigation_container_innerer_abstand_oben:
          DB_LeftSidebar_MenuItem_MenuContainerAA_paddingTop,
        top_navigation_container_innerer_abstand_unten:
          DB_LeftSidebar_MenuItem_MenuContainerAA_paddingBottom,

        top_navigation_container_hover_backgroundcolor:
          DB_LeftSidebar_MenuContainerAA_Hover_BackgroundColor,
        top_navigation_container_hover_backgroundcolor_not_encoded:
          JSON.stringify(
            DB_LeftSidebar_MenuContainerAA_Hover_BackgroundColorNotEncoded
          ),
        top_navigation_container_hover_schattenfarbe:
          DB_LeftSidebar_MenuItem_MenuContainerAA_Hover_ShadowColor,
        top_navigation_container_hover_schattenfarbe_not_encoded:
          JSON.stringify(
            DB_LeftSidebar_MenuItem_MenuContainerAA_Hover_ShadowColorNotEncoded
          ),
        top_navigation_container_hover_schattenposition_horizontal:
          DB_LeftSidebar_MenuItem_MenuContainerAA_Hover_ShadowHorizontal,
        top_navigation_container_hover_schattenposition_vertikal:
          DB_LeftSidebar_MenuItem_MenuContainerAA_Hover_ShadowVertical,
        top_navigation_container_hover_schattenrauschen:
          DB_LeftSidebar_MenuItem_MenuContainerAA_Hover_ShadowBlur,
        top_navigation_container_hover_innerer_abstand_links:
          DB_LeftSidebar_MenuItem_MenuContainerAA_Hover_paddingLeft,
        top_navigation_container_hover_innerer_abstand_rechts:
          DB_LeftSidebar_MenuItem_MenuContainerAA_Hover_paddingRight,
        top_navigation_container_hover_innerer_abstand_oben:
          DB_LeftSidebar_MenuItem_MenuContainerAA_Hover_paddingTop,
        top_navigation_container_hover_innerer_abstand_unten:
          DB_LeftSidebar_MenuItem_MenuContainerAA_Hover_paddingBottom,

        top_navigation_container_border_color_left:
          DB_LeftSidebar_MenuContainerAA_BorderColorLeft,
        top_navigation_container_border_color_left_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuContainerAA_BorderColorLeftNotEncoded
        ),
        top_navigation_container_border_color_right:
          DB_LeftSidebar_MenuContainerAA_BorderColorRight,
        top_navigation_container_border_color_right_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuContainerAA_BorderColorRightNotEncoded
        ),
        top_navigation_container_border_color_top:
          DB_LeftSidebar_MenuContainerAA_BorderColorTop,
        top_navigation_container_border_color_top_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuContainerAA_BorderColorTopNotEncoded
        ),
        top_navigation_container_border_color_bottom:
          DB_LeftSidebar_MenuContainerAA_BorderColorBottom,
        top_navigation_container_border_color_bottom_not_encoded:
          JSON.stringify(
            DB_LeftSidebar_MenuContainerAA_BorderColorBottomNotEncoded
          ),

        top_navigation_container_border_pixel_left:
          DB_LeftSidebar_MenuContainerAA_BorderLeft,
        top_navigation_container_border_pixel_right:
          DB_LeftSidebar_MenuContainerAA_BorderRight,
        top_navigation_container_border_pixel_top:
          DB_LeftSidebar_MenuContainerAA_BorderTop,
        top_navigation_container_border_pixel_bottom:
          DB_LeftSidebar_MenuContainerAA_BorderBottom,

        top_navigation_container_border_radius_top_left:
          DB_LeftSidebar_MenuItem_MenuContainerAA_BorderTopLeftRadius,
        top_navigation_container_border_radius_top_right:
          DB_LeftSidebar_MenuItem_MenuContainerAA_BorderTopRightRadius,
        top_navigation_container_border_radius_bottom_left:
          DB_LeftSidebar_MenuItem_MenuContainerAA_BorderBottomLeftRadius,
        top_navigation_container_border_radius_bottom_right:
          DB_LeftSidebar_MenuItem_MenuContainerAA_BorderBottomRightRadius,

        top_navigation_container_border_style_left:
          DB_LeftSidebar_MenuContainerAA_BorderStyleLeft,
        top_navigation_container_border_style_right:
          DB_LeftSidebar_MenuContainerAA_BorderStyleRight,
        top_navigation_container_border_style_top:
          DB_LeftSidebar_MenuContainerAA_BorderStyleTop,
        top_navigation_container_border_style_bottom:
          DB_LeftSidebar_MenuContainerAA_BorderStyleBottom,

        top_navigation_container_hover_border_color_left:
          DB_LeftSidebar_MenuContainerAA_Hover_BorderColorLeft,
        top_navigation_container_hover_border_color_left_not_encoded:
          JSON.stringify(
            DB_LeftSidebar_MenuContainerAA_Hover_BorderColorLeftNotEncoded
          ),
        top_navigation_container_hover_border_color_right:
          DB_LeftSidebar_MenuContainerAA_Hover_BorderColorRight,
        top_navigation_container_hover_border_color_right_not_encoded:
          JSON.stringify(
            DB_LeftSidebar_MenuContainerAA_Hover_BorderColorRightNotEncoded
          ),
        top_navigation_container_hover_border_color_top:
          DB_LeftSidebar_MenuContainerAA_Hover_BorderColorTop,
        top_navigation_container_hover_border_color_top_not_encoded:
          JSON.stringify(
            DB_LeftSidebar_MenuContainerAA_Hover_BorderColorTopNotEncoded
          ),
        top_navigation_container_hover_border_color_bottom:
          DB_LeftSidebar_MenuContainerAA_Hover_BorderColorBottom,
        top_navigation_container_hover_border_color_bottom_not_encoded:
          JSON.stringify(
            DB_LeftSidebar_MenuContainerAA_Hover_BorderColorBottomNotEncoded
          ),

        top_navigation_container_hover_border_pixel_left:
          DB_LeftSidebar_MenuContainerAA_Hover_BorderLeft,
        top_navigation_container_hover_border_pixel_right:
          DB_LeftSidebar_MenuContainerAA_Hover_BorderRight,
        top_navigation_container_hover_border_pixel_top:
          DB_LeftSidebar_MenuContainerAA_Hover_BorderTop,
        top_navigation_container_hover_border_pixel_bottom:
          DB_LeftSidebar_MenuContainerAA_Hover_BorderBottom,

        top_navigation_container_hover_border_radius_top_left:
          DB_LeftSidebar_MenuItem_MenuContainerAA_Hover_BorderTopLeftRadius,
        top_navigation_container_hover_border_radius_top_right:
          DB_LeftSidebar_MenuItem_MenuContainerAA_Hover_BorderTopRightRadius,
        top_navigation_container_hover_border_radius_bottom_left:
          DB_LeftSidebar_MenuItem_MenuContainerAA_Hover_BorderBottomLeftRadius,
        top_navigation_container_hover_border_radius_bottom_right:
          DB_LeftSidebar_MenuItem_MenuContainerAA_Hover_BorderBottomRightRadius,

        top_navigation_container_hover_border_style_left:
          DB_LeftSidebar_MenuContainerAA_Hover_BorderStyleLeft,
        top_navigation_container_hover_border_style_right:
          DB_LeftSidebar_MenuContainerAA_Hover_BorderStyleRight,
        top_navigation_container_hover_border_style_top:
          DB_LeftSidebar_MenuContainerAA_Hover_BorderStyleTop,
        top_navigation_container_hover_border_style_bottom:
          DB_LeftSidebar_MenuContainerAA_Hover_BorderStyleBottom,

        /// XXXYYYZZZ START

        static_nav_container_backgroundcolor:
          DB_LeftSidebar_MenuContainerXYZ_BackgroundColor,
        static_nav_container_backgroundcolor_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuContainerXYZ_BackgroundColorNotEncoded
        ),
        static_nav_container_schattenfarbe:
          DB_LeftSidebar_MenuItem_MenuContainerXYZ_ShadowColor,
        static_nav_container_schattenfarbe_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_MenuContainerXYZ_ShadowColorNotEncoded
        ),
        static_nav_container_schattenposition_horizontal:
          DB_LeftSidebar_MenuItem_MenuContainerXYZ_ShadowHorizontal,
        static_nav_container_schattenposition_vertikal:
          DB_LeftSidebar_MenuItem_MenuContainerXYZ_ShadowVertical,
        static_nav_container_schattenrauschen:
          DB_LeftSidebar_MenuItem_MenuContainerXYZ_ShadowBlur,
        static_nav_container_innerer_abstand_links:
          DB_LeftSidebar_MenuItem_MenuContainerXYZ_paddingLeft,
        static_nav_container_innerer_abstand_rechts:
          DB_LeftSidebar_MenuItem_MenuContainerXYZ_paddingRight,
        static_nav_container_innerer_abstand_oben:
          DB_LeftSidebar_MenuItem_MenuContainerXYZ_paddingTop,
        static_nav_container_innerer_abstand_unten:
          DB_LeftSidebar_MenuItem_MenuContainerXYZ_paddingBottom,

        static_nav_container_hover_backgroundcolor:
          DB_LeftSidebar_MenuContainerXYZ_Hover_BackgroundColor,
        static_nav_container_hover_backgroundcolor_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuContainerXYZ_Hover_BackgroundColorNotEncoded
        ),
        static_nav_container_hover_schattenfarbe:
          DB_LeftSidebar_MenuItem_MenuContainerXYZ_Hover_ShadowColor,
        static_nav_container_hover_schattenfarbe_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_MenuContainerXYZ_Hover_ShadowColorNotEncoded
        ),
        static_nav_container_hover_schattenposition_horizontal:
          DB_LeftSidebar_MenuItem_MenuContainerXYZ_Hover_ShadowHorizontal,
        static_nav_container_hover_schattenposition_vertikal:
          DB_LeftSidebar_MenuItem_MenuContainerXYZ_Hover_ShadowVertical,
        static_nav_container_hover_schattenrauschen:
          DB_LeftSidebar_MenuItem_MenuContainerXYZ_Hover_ShadowBlur,
        static_nav_container_hover_innerer_abstand_links:
          DB_LeftSidebar_MenuItem_MenuContainerXYZ_Hover_paddingLeft,
        static_nav_container_hover_innerer_abstand_rechts:
          DB_LeftSidebar_MenuItem_MenuContainerXYZ_Hover_paddingRight,
        static_nav_container_hover_innerer_abstand_oben:
          DB_LeftSidebar_MenuItem_MenuContainerXYZ_Hover_paddingTop,
        static_nav_container_hover_innerer_abstand_unten:
          DB_LeftSidebar_MenuItem_MenuContainerXYZ_Hover_paddingBottom,

        static_nav_container_border_color_left:
          DB_LeftSidebar_MenuContainerXYZ_BorderColorLeft,
        static_nav_container_border_color_left_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuContainerXYZ_BorderColorLeftNotEncoded
        ),
        static_nav_container_border_color_right:
          DB_LeftSidebar_MenuContainerXYZ_BorderColorRight,
        static_nav_container_border_color_right_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuContainerXYZ_BorderColorRightNotEncoded
        ),
        static_nav_container_border_color_top:
          DB_LeftSidebar_MenuContainerXYZ_BorderColorTop,
        static_nav_container_border_color_top_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuContainerXYZ_BorderColorTopNotEncoded
        ),
        static_nav_container_border_color_bottom:
          DB_LeftSidebar_MenuContainerXYZ_BorderColorBottom,
        static_nav_container_border_color_bottom_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuContainerXYZ_BorderColorBottomNotEncoded
        ),

        static_nav_container_border_pixel_left:
          DB_LeftSidebar_MenuContainerXYZ_BorderLeft,
        static_nav_container_border_pixel_right:
          DB_LeftSidebar_MenuContainerXYZ_BorderRight,
        static_nav_container_border_pixel_top:
          DB_LeftSidebar_MenuContainerXYZ_BorderTop,
        static_nav_container_border_pixel_bottom:
          DB_LeftSidebar_MenuContainerXYZ_BorderBottom,

        static_nav_container_border_radius_top_left:
          DB_LeftSidebar_MenuItem_MenuContainerXYZ_BorderTopLeftRadius,
        static_nav_container_border_radius_top_right:
          DB_LeftSidebar_MenuItem_MenuContainerXYZ_BorderTopRightRadius,
        static_nav_container_border_radius_bottom_left:
          DB_LeftSidebar_MenuItem_MenuContainerXYZ_BorderBottomLeftRadius,
        static_nav_container_border_radius_bottom_right:
          DB_LeftSidebar_MenuItem_MenuContainerXYZ_BorderBottomRightRadius,

        static_nav_container_border_style_left:
          DB_LeftSidebar_MenuContainerXYZ_BorderStyleLeft,
        static_nav_container_border_style_right:
          DB_LeftSidebar_MenuContainerXYZ_BorderStyleRight,
        static_nav_container_border_style_top:
          DB_LeftSidebar_MenuContainerXYZ_BorderStyleTop,
        static_nav_container_border_style_bottom:
          DB_LeftSidebar_MenuContainerXYZ_BorderStyleBottom,

        static_nav_container_hover_border_color_left:
          DB_LeftSidebar_MenuContainerXYZ_Hover_BorderColorLeft,
        static_nav_container_hover_border_color_left_not_encoded:
          JSON.stringify(
            DB_LeftSidebar_MenuContainerXYZ_Hover_BorderColorLeftNotEncoded
          ),
        static_nav_container_hover_border_color_right:
          DB_LeftSidebar_MenuContainerXYZ_Hover_BorderColorRight,
        static_nav_container_hover_border_color_right_not_encoded:
          JSON.stringify(
            DB_LeftSidebar_MenuContainerXYZ_Hover_BorderColorRightNotEncoded
          ),
        static_nav_container_hover_border_color_top:
          DB_LeftSidebar_MenuContainerXYZ_Hover_BorderColorTop,
        static_nav_container_hover_border_color_top_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuContainerXYZ_Hover_BorderColorTopNotEncoded
        ),
        static_nav_container_hover_border_color_bottom:
          DB_LeftSidebar_MenuContainerXYZ_Hover_BorderColorBottom,
        static_nav_container_hover_border_color_bottom_not_encoded:
          JSON.stringify(
            DB_LeftSidebar_MenuContainerXYZ_Hover_BorderColorBottomNotEncoded
          ),

        static_nav_container_hover_border_pixel_left:
          DB_LeftSidebar_MenuContainerXYZ_Hover_BorderLeft,
        static_nav_container_hover_border_pixel_right:
          DB_LeftSidebar_MenuContainerXYZ_Hover_BorderRight,
        static_nav_container_hover_border_pixel_top:
          DB_LeftSidebar_MenuContainerXYZ_Hover_BorderTop,
        static_nav_container_hover_border_pixel_bottom:
          DB_LeftSidebar_MenuContainerXYZ_Hover_BorderBottom,

        static_nav_container_hover_border_radius_top_left:
          DB_LeftSidebar_MenuItem_MenuContainerXYZ_Hover_BorderTopLeftRadius,
        static_nav_container_hover_border_radius_top_right:
          DB_LeftSidebar_MenuItem_MenuContainerXYZ_Hover_BorderTopRightRadius,
        static_nav_container_hover_border_radius_bottom_left:
          DB_LeftSidebar_MenuItem_MenuContainerXYZ_Hover_BorderBottomLeftRadius,
        static_nav_container_hover_border_radius_bottom_right:
          DB_LeftSidebar_MenuItem_MenuContainerXYZ_Hover_BorderBottomRightRadius,

        static_nav_container_hover_border_style_left:
          DB_LeftSidebar_MenuContainerXYZ_Hover_BorderStyleLeft,
        static_nav_container_hover_border_style_right:
          DB_LeftSidebar_MenuContainerXYZ_Hover_BorderStyleRight,
        static_nav_container_hover_border_style_top:
          DB_LeftSidebar_MenuContainerXYZ_Hover_BorderStyleTop,
        static_nav_container_hover_border_style_bottom:
          DB_LeftSidebar_MenuContainerXYZ_Hover_BorderStyleBottom,
        /// XXXYYYZZZ END

        top_navigation_hauptkategorien_schriftfarbe:
          DB_LeftSidebar_MenuItem_HauptmenuTopAA_FontColor,
        top_navigation_hauptkategorien_schriftfarbe_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_HauptmenuTopAA_FontColorNotEncoded
        ),
        top_navigation_hauptkategorien_hintergrundfarbe:
          DB_LeftSidebar_MenuItem_HauptmenuTopAA_BackgroundColor,
        top_navigation_hauptkategorien_hintergrundfarbe_not_encoded:
          JSON.stringify(
            DB_LeftSidebar_MenuItem_HauptmenuTopAA_BackgroundColorNotEncoded
          ),
        top_navigation_hauptkategorien_schattenfarbe:
          DB_LeftSidebar_MenuItem_HauptmenuTopAA_ShadowColor,
        top_navigation_hauptkategorien_schattenfarbe_not_encoded:
          JSON.stringify(
            DB_LeftSidebar_MenuItem_HauptmenuTopAA_ShadowColorNotEncoded
          ),
        top_navigation_hauptkategorien_schattenposition_horizontal:
          DB_LeftSidebar_MenuItem_HauptmenuTopAA_ShadowHorizontal,
        top_navigation_hauptkategorien_schattenposition_vertikal:
          DB_LeftSidebar_MenuItem_HauptmenuTopAA_ShadowVertical,
        top_navigation_hauptkategorien_schattenrauschen:
          DB_LeftSidebar_MenuItem_HauptmenuTopAA_ShadowBlur,
        top_navigation_hauptkategorien_schriftgroesse:
          DB_LeftSidebar_MenuItem_HauptmenuTopAA_FontSize,
        top_navigation_hauptkategorien_schriftgewicht:
          DB_LeftSidebar_MenuItem_HauptmenuTopAA_FontWeight,
        top_navigation_hauptkategorien_buchstabenabstand:
          DB_LeftSidebar_MenuItem_HauptmenuTopAA_letterSpacing,
        top_navigation_hauptkategorien_text_dekoration:
          DB_LeftSidebar_MenuItem_HauptmenuTopAA_TextDecoration,
        top_navigation_hauptkategorien_text_dekoration_stil:
          DB_LeftSidebar_MenuItem_HauptmenuTopAA_TextDecorationStyle,
        top_navigation_hauptkategorien_aussen_abstand_links_rechts:
          DB_LeftSidebar_MenuItem_HauptmenuTopAA_marginLeftRight,
        top_navigation_hauptkategorien_aussen_abstand_oben_unten:
          DB_LeftSidebar_MenuItem_HauptmenuTopAA_marginTopBottom,
        top_navigation_hauptkategorien_innerer_abstand_links_rechts:
          DB_LeftSidebar_MenuItem_HauptmenuTopAA_paddingLeftRight,
        top_navigation_hauptkategorien_innerer_abstand_oben_unten:
          DB_LeftSidebar_MenuItem_HauptmenuTopAA_paddingTopBottom,
        top_navigation_hauptkategorien_textausrichtung:
          DB_LeftSidebar_MenuItem_HauptmenuTopAA_TextAlign,
        top_navigation_hauptkategorien_fontfamily:
          DB_fontFace_hauptMenueTopAA_FontFamilyName,
        top_navigation_hauptkategorien_fontfamilysrc:
          DB_fontFace_hauptMenueTopAA_FontFamilySrc,

        top_nav_hauptkategorien_hover_schriftfarbe:
          DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_FontColor,
        top_nav_hauptkategorien_hover_schriftfarbe_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_FontColorNotEncoded
        ),
        top_nav_hauptkategorien_hover_hintergrundfarbe:
          DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BackgroundColor,
        top_nav_hauptkategorien_hover_hintergrundfarbe_not_encoded:
          JSON.stringify(
            DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BackgroundColorNotEncoded
          ),
        top_nav_hauptkategorien_hover_schattenfarbe:
          DB_LeftSidebar_MenuItem_HauptmenuTopAA_ShadowColor_Hover,
        top_nav_hauptkategorien_hover_schattenfarbe_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_HauptmenuTopAA_ShadowColorNotEncoded_Hover
        ),
        top_nav_hauptkategorien_hover_schattenposition_horizontal:
          DB_LeftSidebar_MenuItem_HauptmenuTopAA_ShadowHorizontal_Hover,
        top_nav_hauptkategorien_hover_schattenposition_vertikal:
          DB_LeftSidebar_MenuItem_HauptmenuTopAA_ShadowVertical_Hover,
        top_nav_hauptkategorien_hover_schattenrauschen:
          DB_LeftSidebar_MenuItem_HauptmenuTopAA_ShadowBlur_Hover,
        top_nav_hauptkategorien_hover_schriftgroesse:
          DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_FontSize,
        top_nav_hauptkategorien_hover_schriftgewicht:
          DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_FontWeight,
        top_nav_hauptkategorien_hover_buchstabenabstand:
          DB_LeftSidebar_MenuItem_HauptmenuTopAA_letterSpacing_Hover,
        top_nav_hauptkategorien_hover_text_dekoration:
          DB_LeftSidebar_MenuItem_HauptmenuTopAA_TextDecoration_Hover,
        top_nav_hauptkategorien_hover_text_dekoration_stil:
          DB_LeftSidebar_MenuItem_HauptmenuTopAA_TextDecorationStyle_Hover,
        top_nav_hauptkategorien_hover_aussen_abstand_links_rechts:
          DB_LeftSidebar_MenuItem_HauptmenuTopAA_marginLeftRight_Hover,
        top_nav_hauptkategorien_hover_aussen_abstand_oben_unten:
          DB_LeftSidebar_MenuItem_HauptmenuTopAA_marginTopBottom_Hover,
        top_nav_hauptkategorien_hover_innerer_abstand_links_rechts:
          DB_LeftSidebar_MenuItem_HauptmenuTopAA_paddingLeftRight_Hover,
        top_nav_hauptkategorien_hover_innerer_abstand_oben_unten:
          DB_LeftSidebar_MenuItem_HauptmenuTopAA_paddingTopBottom_Hover,
        top_nav_hauptkategorien_hover_textausrichtung:
          DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_TextAlign,

        top_nav_hauptkategorien_border_color_left:
          DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderColorLeft,
        top_nav_hauptkategorien_border_color_left_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderColorLeftNotEncoded
        ),

        top_nav_hauptkategorien_border_color_right:
          DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderColorRight,
        top_nav_hauptkategorien_border_color_right_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderColorRightNotEncoded
        ),

        top_nav_hauptkategorien_border_color_top:
          DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderColorTop,
        top_nav_hauptkategorien_border_color_top_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderColorTopNotEncoded
        ),

        top_nav_hauptkategorien_border_color_bottom:
          DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderColorBottom,
        top_nav_hauptkategorien_border_color_bottom_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderColorBottomNotEncoded
        ),

        top_nav_hauptkategorien_border_pixel_left:
          DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderLeft,

        top_nav_hauptkategorien_border_pixel_right:
          DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderRight,
        top_nav_hauptkategorien_border_pixel_top:
          DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderTop,
        top_nav_hauptkategorien_border_pixel_bottom:
          DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderBottom,

        top_nav_hauptkategorien_border_radius_top_left:
          DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderTopLeftRadius,
        top_nav_hauptkategorien_border_radius_top_right:
          DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderTopRightRadius,
        top_nav_hauptkategorien_border_radius_bottom_left:
          DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderBottomLeftRadius,
        top_nav_hauptkategorien_border_radius_bottom_right:
          DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderBottomRightRadius,

        top_nav_hauptkategorien_border_style_left:
          DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderStyleLeft,
        top_nav_hauptkategorien_border_style_right:
          DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderStyleRight,
        top_nav_hauptkategorien_border_style_top:
          DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderStyleTop,
        top_nav_hauptkategorien_border_style_bottom:
          DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderStyleBottom,

        top_nav_hauptkategorien_border_hover_color_left:
          DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderColorLeft,
        top_nav_hauptkategorien_border_hover_color_left_not_encoded:
          JSON.stringify(
            DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderColorLeftNotEncoded
          ),

        top_nav_hauptkategorien_border_hover_color_right:
          DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderColorRight,
        top_nav_hauptkategorien_border_hover_color_right_not_encoded:
          JSON.stringify(
            DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderColorRightNotEncoded
          ),

        top_nav_hauptkategorien_border_hover_color_top:
          DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderColorTop,
        top_nav_hauptkategorien_border_hover_color_top_not_encoded:
          JSON.stringify(
            DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderColorTopNotEncoded
          ),

        top_nav_hauptkategorien_border_hover_color_bottom:
          DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderColorBottom,
        top_nav_hauptkategorien_border_hover_color_bottom_not_encoded:
          JSON.stringify(
            DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderColorBottomNotEncoded
          ),

        top_nav_hauptkategorien_border_hover_pixel_left:
          DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderLeft,
        top_nav_hauptkategorien_border_hover_pixel_right:
          DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderRight,
        top_nav_hauptkategorien_border_hover_pixel_top:
          DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderTop,
        top_nav_hauptkategorien_border_hover_pixel_bottom:
          DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderBottom,

        top_nav_hauptkategorien_border_hover_radius_top_left:
          DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderTopLeftRadius,
        top_nav_hauptkategorien_border_hover_radius_top_right:
          DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderTopRightRadius,
        top_nav_hauptkategorien_border_hover_radius_bottom_left:
          DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderBottomLeftRadius,
        top_nav_hauptkategorien_border_hover_radius_bottom_right:
          DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderBottomRightRadius,

        top_nav_hauptkategorien_border_hover_style_left:
          DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderStyleLeft,
        top_nav_hauptkategorien_border_hover_style_right:
          DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderStyleRight,
        top_nav_hauptkategorien_border_hover_style_top:
          DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderStyleTop,
        top_nav_hauptkategorien_border_hover_style_bottom:
          DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderStyleBottom,

        top_navigation_static_schriftfarbe:
          DB_LeftSidebar_MenuItem_HauptmenuTop_FontColor,
        top_navigation_static_schriftfarbe_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_HauptmenuTop_FontColorNotEncoded
        ),
        top_navigation_static_hintergrundfarbe:
          DB_LeftSidebar_MenuItem_HauptmenuTop_BackgroundColor,
        top_navigation_static_hintergrundfarbe_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_HauptmenuTop_BackgroundColorNotEncoded
        ),
        top_navigation_static_schattenfarbe:
          DB_LeftSidebar_MenuItem_HauptmenuTop_ShadowColor,
        top_navigation_static_schattenfarbe_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_HauptmenuTop_ShadowColorNotEncoded
        ),
        top_navigation_static_schattenposition_horizontal:
          DB_LeftSidebar_MenuItem_HauptmenuTop_ShadowHorizontal,
        top_navigation_static_schattenposition_vertikal:
          DB_LeftSidebar_MenuItem_HauptmenuTop_ShadowVertical,
        top_navigation_static_schattenrauschen:
          DB_LeftSidebar_MenuItem_HauptmenuTop_ShadowBlur,
        top_navigation_static_schriftgroesse:
          DB_LeftSidebar_MenuItem_HauptmenuTop_FontSize,
        top_navigation_static_schriftgewicht:
          DB_LeftSidebar_MenuItem_HauptmenuTop_FontWeight,
        top_navigation_static_buchstabenabstand:
          DB_LeftSidebar_MenuItem_HauptmenuTop_letterSpacing,
        top_navigation_static_text_dekoration:
          DB_LeftSidebar_MenuItem_HauptmenuTop_TextDecoration,
        top_navigation_static_text_dekoration_stil:
          DB_LeftSidebar_MenuItem_HauptmenuTop_TextDecorationStyle,
        top_navigation_static_aussen_abstand_links_rechts:
          DB_LeftSidebar_MenuItem_HauptmenuTop_marginLeftRight,
        top_navigation_static_aussen_abstand_oben_unten:
          DB_LeftSidebar_MenuItem_HauptmenuTop_marginTopBottom,
        top_navigation_static_innerer_abstand_links_rechts:
          DB_LeftSidebar_MenuItem_HauptmenuTop_paddingLeftRight,
        top_navigation_static_innerer_abstand_oben_unten:
          DB_LeftSidebar_MenuItem_HauptmenuTop_paddingTopBottom,
        top_navigation_static_textausrichtung:
          DB_LeftSidebar_MenuItem_HauptmenuTop_TextAlign,
        top_navigation_static_fontfamilyname:
          DB_fontFace_hauptMenueStatic_FontFamilyName,
        top_navigation_static_fontfamilysrc:
          DB_fontFace_hauptMenueStatic_FontFamilySrc,

        top_navigation_static_hover_schriftfarbe:
          DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_FontColor,
        top_navigation_static_hover_schriftfarbe_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_FontColorNotEncoded
        ),
        top_navigation_static_hover_hintergrundfarbe:
          DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BackgroundColor,
        top_navigation_static_hover_hintergrundfarbe_not_encoded:
          JSON.stringify(
            DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BackgroundColorNotEncoded
          ),
        top_navigation_static_hover_schattenfarbe:
          DB_LeftSidebar_MenuItem_HauptmenuTop_ShadowColor_Hover,
        top_navigation_static_hover_schattenfarbe_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_HauptmenuTop_ShadowColorNotEncoded_Hover
        ),
        top_navigation_static_hover_schattenposition_horizontal:
          DB_LeftSidebar_MenuItem_HauptmenuTop_ShadowHorizontal_Hover,
        top_navigation_static_hover_schattenposition_vertikal:
          DB_LeftSidebar_MenuItem_HauptmenuTop_ShadowVertical_Hover,
        top_navigation_static_hover_schattenrauschen:
          DB_LeftSidebar_MenuItem_HauptmenuTop_ShadowBlur_Hover,
        top_navigation_static_hover_schriftgroesse:
          DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_FontSize,
        top_navigation_static_hover_schriftgewicht:
          DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_FontWeight,
        top_navigation_static_hover_buchstabenabstand:
          DB_LeftSidebar_MenuItem_HauptmenuTop_letterSpacing_Hover,
        top_navigation_static_hover_text_dekoration:
          DB_LeftSidebar_MenuItem_HauptmenuTop_TextDecoration_Hover,
        top_navigation_static_hover_text_dekoration_stil:
          DB_LeftSidebar_MenuItem_HauptmenuTop_TextDecorationStyle_Hover,
        top_navigation_static_hover_aussen_abstand_links_rechts:
          DB_LeftSidebar_MenuItem_HauptmenuTop_marginLeftRight_Hover,
        top_navigation_static_hover_aussen_abstand_oben_unten:
          DB_LeftSidebar_MenuItem_HauptmenuTop_marginTopBottom_Hover,
        top_navigation_static_hover_innerer_abstand_links_rechts:
          DB_LeftSidebar_MenuItem_HauptmenuTop_paddingLeftRight_Hover,
        top_navigation_static_hover_innerer_abstand_oben_unten:
          DB_LeftSidebar_MenuItem_HauptmenuTop_paddingTopBottom_Hover,
        top_navigation_static_hover_textausrichtung:
          DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_TextAlign,

        top_nav_static_border_color_left:
          DB_LeftSidebar_MenuItem_HauptmenuTop_BorderColorLeft,
        top_nav_static_border_color_left_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_HauptmenuTop_BorderColorLeftNotEncoded
        ),

        top_nav_static_border_color_right:
          DB_LeftSidebar_MenuItem_HauptmenuTop_BorderColorRight,
        top_nav_static_border_color_right_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_HauptmenuTop_BorderColorRightNotEncoded
        ),

        top_nav_static_border_color_top:
          DB_LeftSidebar_MenuItem_HauptmenuTop_BorderColorTop,
        top_nav_static_border_color_top_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_HauptmenuTop_BorderColorTopNotEncoded
        ),

        top_nav_static_border_color_bottom:
          DB_LeftSidebar_MenuItem_HauptmenuTop_BorderColorBottom,
        top_nav_static_border_color_bottom_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_HauptmenuTop_BorderColorBottomNotEncoded
        ),

        top_nav_static_border_pixel_left:
          DB_LeftSidebar_MenuItem_HauptmenuTop_BorderLeft,

        top_nav_static_border_pixel_right:
          DB_LeftSidebar_MenuItem_HauptmenuTop_BorderRight,
        top_nav_static_border_pixel_top:
          DB_LeftSidebar_MenuItem_HauptmenuTop_BorderTop,
        top_nav_static_border_pixel_bottom:
          DB_LeftSidebar_MenuItem_HauptmenuTop_BorderBottom,

        top_nav_static_border_radius_top_left:
          DB_LeftSidebar_MenuItem_HauptmenuTop_BorderTopLeftRadius,
        top_nav_static_border_radius_top_right:
          DB_LeftSidebar_MenuItem_HauptmenuTop_BorderTopRightRadius,
        top_nav_static_border_radius_bottom_left:
          DB_LeftSidebar_MenuItem_HauptmenuTop_BorderBottomLeftRadius,
        top_nav_static_border_radius_bottom_right:
          DB_LeftSidebar_MenuItem_HauptmenuTop_BorderBottomRightRadius,

        top_nav_static_border_style_left:
          DB_LeftSidebar_MenuItem_HauptmenuTop_BorderStyleLeft,
        top_nav_static_border_style_right:
          DB_LeftSidebar_MenuItem_HauptmenuTop_BorderStyleRight,
        top_nav_static_border_style_top:
          DB_LeftSidebar_MenuItem_HauptmenuTop_BorderStyleTop,
        top_nav_static_border_style_bottom:
          DB_LeftSidebar_MenuItem_HauptmenuTop_BorderStyleBottom,

        top_nav_static_border_hover_color_left:
          DB_LeftSidebar_MenuItem_HauptmenuTop_BorderColorLeft,
        top_nav_static_border_hover_color_left_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_HauptmenuTop_BorderColorLeftNotEncoded
        ),

        top_nav_static_border_hover_color_right:
          DB_LeftSidebar_MenuItem_HauptmenuTop_BorderColorRight,
        top_nav_static_border_hover_color_right_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_HauptmenuTop_BorderColorRightNotEncoded
        ),

        top_nav_static_border_hover_color_top:
          DB_LeftSidebar_MenuItem_HauptmenuTop_BorderColorTop,
        top_nav_static_border_hover_color_top_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_HauptmenuTop_BorderColorTopNotEncoded
        ),

        top_nav_static_border_hover_color_bottom:
          DB_LeftSidebar_MenuItem_HauptmenuTop_BorderColorBottom,
        top_nav_static_border_hover_color_bottom_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_HauptmenuTop_BorderColorBottomNotEncoded
        ),

        top_nav_static_border_hover_pixel_left:
          DB_LeftSidebar_MenuItem_HauptmenuTop_BorderLeft,

        top_nav_static_border_hover_pixel_right:
          DB_LeftSidebar_MenuItem_HauptmenuTop_BorderRight,
        top_nav_static_border_hover_pixel_top:
          DB_LeftSidebar_MenuItem_HauptmenuTop_BorderTop,
        top_nav_static_border_hover_pixel_bottom:
          DB_LeftSidebar_MenuItem_HauptmenuTop_BorderBottom,

        top_nav_static_border_hover_radius_top_left:
          DB_LeftSidebar_MenuItem_HauptmenuTop_BorderTopLeftRadius,
        top_nav_static_border_hover_radius_top_right:
          DB_LeftSidebar_MenuItem_HauptmenuTop_BorderTopRightRadius,
        top_nav_static_border_hover_radius_bottom_left:
          DB_LeftSidebar_MenuItem_HauptmenuTop_BorderBottomLeftRadius,
        top_nav_static_border_hover_radius_bottom_right:
          DB_LeftSidebar_MenuItem_HauptmenuTop_BorderBottomRightRadius,

        top_nav_static_border_hover_style_left:
          DB_LeftSidebar_MenuItem_HauptmenuTop_BorderStyleLeft,
        top_nav_static_border_hover_style_right:
          DB_LeftSidebar_MenuItem_HauptmenuTop_BorderStyleRight,
        top_nav_static_border_hover_style_top:
          DB_LeftSidebar_MenuItem_HauptmenuTop_BorderStyleTop,
        top_nav_static_border_hover_style_bottom:
          DB_LeftSidebar_MenuItem_HauptmenuTop_BorderStyleBottom,
      };

      const design_kategorien_leftside_nav = {
        left_nav_hauptkategorien_hintergrundfarbe:
          DB_LeftSidebar_MenuContainer_CC__BackgroundColor,

        left_nav_hauptkategorien_hintergrundfarbe_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuContainer_CC__BackgroundColorNotEncoded
        ),
        left_nav_hauptkategorien_schattenfarbe:
          DB_LeftSidebar_MenuItem_MenuContainer_CC__ShadowColor,
        left_nav_hauptkategorien_schattenfarbe_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_MenuContainer_CC__ShadowColorNotEncoded
        ),
        left_nav_hauptkategorien_schattenposition_horizontal:
          DB_LeftSidebar_MenuItem_MenuContainer_CC__ShadowHorizontal,
        left_nav_hauptkategorien_schattenposition_vertikal:
          DB_LeftSidebar_MenuItem_MenuContainer_CC__ShadowVertical,
        left_nav_hauptkategorien_schattenrauschen:
          DB_LeftSidebar_MenuItem_MenuContainer_CC__ShadowBlur,
        left_nav_hauptkategorien_innerer_abstand_links:
          DB_LeftSidebar_MenuItem_MenuContainer_CC__paddingLeft,
        left_nav_hauptkategorien_innerer_abstand_rechts:
          DB_LeftSidebar_MenuItem_MenuContainer_CC__paddingRight,
        left_nav_hauptkategorien_innerer_abstand_oben:
          DB_LeftSidebar_MenuItem_MenuContainer_CC__paddingTop,
        left_nav_hauptkategorien_innerer_abstand_unten:
          DB_LeftSidebar_MenuItem_MenuContainer_CC__paddingBottom,

        left_nav_hauptkategorien_hover_hintergrundfarbe:
          DB_LeftSidebar_MenuContainer_CC__Hover_BackgroundColor,

        left_nav_hauptkategorien_hintergrundfarbe_hover_not_encoded:
          JSON.stringify(
            DB_LeftSidebar_MenuContainer_CC__Hover_BackgroundColorNotEncoded
          ),
        left_nav_hauptkategorien_hover_schattenfarbe:
          DB_LeftSidebar_MenuItem_MenuContainer_CC__Hover_ShadowColor,
        left_nav_hauptkategorien_hover_schattenfarbe_not_encoded:
          JSON.stringify(
            DB_LeftSidebar_MenuItem_MenuContainer_CC__Hover_ShadowColorNotEncoded
          ),
        left_nav_hauptkategorien_hover_schattenposition_horizontal:
          DB_LeftSidebar_MenuItem_MenuContainer_CC__Hover_ShadowHorizontal,
        left_nav_hauptkategorien_hover_schattenposition_vertikal:
          DB_LeftSidebar_MenuItem_MenuContainer_CC__Hover_ShadowVertical,
        left_nav_hauptkategorien_hover_schattenrauschen:
          DB_LeftSidebar_MenuItem_MenuContainer_CC__Hover_ShadowBlur,
        left_nav_hauptkategorien_hover_innerer_abstand_links:
          DB_LeftSidebar_MenuItem_MenuContainer_CC__Hover_paddingLeft,
        left_nav_hauptkategorien_hover_innerer_abstand_rechts:
          DB_LeftSidebar_MenuItem_MenuContainer_CC__Hover_paddingRight,
        left_nav_hauptkategorien_hover_innerer_abstand_oben:
          DB_LeftSidebar_MenuItem_MenuContainer_CC__Hover_paddingTop,
        left_nav_hauptkategorien_hover_innerer_abstand_unten:
          DB_LeftSidebar_MenuItem_MenuContainer_CC__Hover_paddingBottom,

        left_nav_hauptkategorien_border_color_left:
          DB_LeftSidebar_MenuContainer_CC__BorderColorLeft,
        left_nav_hauptkategorien_border_color_left_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuContainer_CC__BorderColorLeftNotEncoded
        ),

        left_nav_hauptkategorien_border_color_right:
          DB_LeftSidebar_MenuContainer_CC__BorderColorRight,
        left_nav_hauptkategorien_border_color_right_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuContainer_CC__BorderColorRightNotEncoded
        ),

        left_nav_hauptkategorien_border_color_top:
          DB_LeftSidebar_MenuContainer_CC__BorderColorTop,
        left_nav_hauptkategorien_border_color_top_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuContainer_CC__BorderColorTopNotEncoded
        ),

        left_nav_hauptkategorien_border_color_bottom:
          DB_LeftSidebar_MenuContainer_CC__BorderColorBottom,
        left_nav_hauptkategorien_border_color_bottom_not_encoded:
          JSON.stringify(
            DB_LeftSidebar_MenuContainer_CC__BorderColorBottomNotEncoded
          ),

        left_nav_hauptkategorien_border_pixel_left:
          DB_LeftSidebar_MenuContainer_CC__BorderLeft,
        left_nav_hauptkategorien_border_pixel_right:
          DB_LeftSidebar_MenuContainer_CC__BorderRight,
        left_nav_hauptkategorien_border_pixel_top:
          DB_LeftSidebar_MenuContainer_CC__BorderTop,
        left_nav_hauptkategorien_border_pixel_bottom:
          DB_LeftSidebar_MenuContainer_CC__BorderBottom,

        left_nav_hauptkategorien_border_radius_top_left:
          DB_LeftSidebar_MenuItem_MenuContainer_CC__BorderTopLeftRadius,
        left_nav_hauptkategorien_border_radius_top_right:
          DB_LeftSidebar_MenuItem_MenuContainer_CC__BorderTopRightRadius,
        left_nav_hauptkategorien_border_radius_bottom_left:
          DB_LeftSidebar_MenuItem_MenuContainer_CC__BorderBottomLeftRadius,
        left_nav_hauptkategorien_border_radius_bottom_right:
          DB_LeftSidebar_MenuItem_MenuContainer_CC__BorderBottomRightRadius,

        left_nav_hauptkategorien_border_style_left:
          DB_LeftSidebar_MenuContainer_CC__BorderStyleLeft,
        left_nav_hauptkategorien_border_style_right:
          DB_LeftSidebar_MenuContainer_CC__BorderStyleRight,
        left_nav_hauptkategorien_border_style_top:
          DB_LeftSidebar_MenuContainer_CC__BorderStyleTop,
        left_nav_hauptkategorien_border_style_bottom:
          DB_LeftSidebar_MenuContainer_CC__BorderStyleBottom,

        left_nav_hauptkategorien_hover_border_color_left:
          DB_LeftSidebar_MenuContainer_CC__Hover_BorderColorLeft,
        left_nav_hauptkategorien_hover_border_color_left_not_encoded:
          JSON.stringify(
            DB_LeftSidebar_MenuContainer_CC__Hover_BorderColorLeftNotEncoded
          ),

        left_nav_hauptkategorien_hover_border_color_right:
          DB_LeftSidebar_MenuContainer_CC__Hover_BorderColorRight,
        left_nav_hauptkategorien_hover_border_color_right_not_encoded:
          JSON.stringify(
            DB_LeftSidebar_MenuContainer_CC__Hover_BorderColorRightNotEncoded
          ),

        left_nav_hauptkategorien_hover_border_color_top:
          DB_LeftSidebar_MenuContainer_CC__Hover_BorderColorTop,
        left_nav_hauptkategorien_hover_border_color_top_not_encoded:
          JSON.stringify(
            DB_LeftSidebar_MenuContainer_CC__Hover_BorderColorTopNotEncoded
          ),

        left_nav_hauptkategorien_hover_border_color_bottom:
          DB_LeftSidebar_MenuContainer_CC__Hover_BorderColorBottom,
        left_nav_hauptkategorien_hover_border_color_bottom_not_encoded:
          JSON.stringify(
            DB_LeftSidebar_MenuContainer_CC__Hover_BorderColorBottomNotEncoded
          ),

        left_nav_hauptkategorien_hover_border_pixel_left:
          DB_LeftSidebar_MenuContainer_CC__Hover_BorderLeft,
        left_nav_hauptkategorien_hover_border_pixel_right:
          DB_LeftSidebar_MenuContainer_CC__Hover_BorderRight,
        left_nav_hauptkategorien_hover_border_pixel_top:
          DB_LeftSidebar_MenuContainer_CC__Hover_BorderTop,
        left_nav_hauptkategorien_hover_border_pixel_bottom:
          DB_LeftSidebar_MenuContainer_CC__Hover_BorderBottom,

        left_nav_hauptkategorien_hover_border_radius_top_left:
          DB_LeftSidebar_MenuItem_MenuContainer_CC__Hover_BorderTopLeftRadius,
        left_nav_hauptkategorien_hover_border_radius_top_right:
          DB_LeftSidebar_MenuItem_MenuContainer_CC__Hover_BorderTopRightRadius,
        left_nav_hauptkategorien_hover_border_radius_bottom_left:
          DB_LeftSidebar_MenuItem_MenuContainer_CC__Hover_BorderBottomLeftRadius,
        left_nav_hauptkategorien_hover_border_radius_bottom_right:
          DB_LeftSidebar_MenuItem_MenuContainer_CC__Hover_BorderBottomRightRadius,

        left_nav_hauptkategorien_hover_border_style_left:
          DB_LeftSidebar_MenuContainer_CC__Hover_BorderStyleLeft,
        left_nav_hauptkategorien_hover_border_style_right:
          DB_LeftSidebar_MenuContainer_CC__Hover_BorderStyleRight,
        left_nav_hauptkategorien_hover_border_style_top:
          DB_LeftSidebar_MenuContainer_CC__Hover_BorderStyleTop,
        left_nav_hauptkategorien_hover_border_style_bottom:
          DB_LeftSidebar_MenuContainer_CC__Hover_BorderStyleBottom,

        left_nav_hauptkat_alleProd_schriftfarbe:
          DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_FontColor,
        left_nav_hauptkat_alleProd_schriftfarbe_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_FontColorNotEncoded
        ),
        left_nav_hauptkat_alleProd_hintergrundfarbe:
          DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_BackgroundColor,
        left_nav_hauptkat_alleProd_hintergrundfarbe_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_BackgroundColorNotEncoded
        ),
        left_nav_hauptkat_alleProd_schattenfarbe:
          DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_ShadowColor,
        left_nav_hauptkat_alleProd_schattenfarbe_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_ShadowColorNotEncoded
        ),
        left_nav_hauptkat_alleProd_schattenposition_horizontal:
          DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_ShadowHorizontal,
        left_nav_hauptkat_alleProd_schattenposition_vertikal:
          DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_ShadowVertical,
        left_nav_hauptkat_alleProd_schattenrauschen:
          DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_ShadowBlur,
        left_nav_hauptkat_alleProd_schriftgroesse:
          DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_FontSize,
        left_nav_hauptkat_alleProd_schriftgewicht:
          DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_FontWeight,
        left_nav_hauptkat_alleProd_buchstabenabstand:
          DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_letterSpacing,
        left_nav_hauptkat_alleProd_text_dekoration:
          DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_TextDecoration,
        left_nav_hauptkat_alleProd_text_dekoration_stil:
          DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_TextDecorationStyle,
        left_nav_hauptkat_alleProd_aussen_abstand_links_rechts:
          DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_marginLeftRight,
        left_nav_hauptkat_alleProd_aussen_abstand_oben_unten:
          DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_marginTopBottom,
        left_nav_hauptkat_alleProd_innerer_abstand_links_rechts:
          DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_paddingLeftRight,
        left_nav_hauptkat_alleProd_innerer_abstand_oben_unten:
          DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_paddingTopBottom,
        left_nav_hauptkat_alleProd_textausrichtung:
          DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_TextAlign,
        left_nav_hauptkat_alleProd_fontfamilyname:
          DB_fontFace_alleProdukteAuswahlBB_FontFamilyName,
        left_nav_hauptkat_alleProd_fontfamilysrc:
          DB_fontFace_alleProdukteAuswahlBB_FontFamilySrc,

        left_nav_hauptkat_alleProd_hov_schriftfarbe:
          DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_FontColor,

        left_nav_hauptkat_alleProd_hov_schriftfarbe_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_FontColorNotEncoded
        ),
        left_nav_hauptkat_alleProd_hov_hintergrundfarbe:
          DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_BackgroundColor,

        left_nav_hauptkat_alleProd_hov_hintergrundfarbe_not_encoded:
          JSON.stringify(
            DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_BackgroundColorNotEncoded
          ),
        left_nav_hauptkat_alleProd_hov_schattenfarbe:
          DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_ShadowColor_Hover,

        left_nav_hauptkat_alleProd_hov_schattenfarbe_not_encoded:
          JSON.stringify(
            DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_ShadowColorNotEncoded_Hover
          ),
        left_nav_hauptkat_alleProd_hov_schattenposition_horizontal:
          DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_ShadowHorizontal_Hover,

        left_nav_hauptkat_alleProd_hov_schattenposition_vertikal:
          DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_ShadowVertical_Hover,

        left_nav_hauptkat_alleProd_hov_schattenrauschen:
          DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_ShadowBlur_Hover,

        left_nav_hauptkat_alleProd_hov_schriftgroesse:
          DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_FontSize,

        left_nav_hauptkat_alleProd_hov_schriftgewicht:
          DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_FontWeight,

        left_nav_hauptkat_alleProd_hov_buchstabenabstand:
          DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_letterSpacing_Hover,

        left_nav_hauptkat_alleProd_hov_text_dekoration:
          DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_TextDecoration_Hover,

        left_nav_hauptkat_alleProd_hov_text_dekoration_stil:
          DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_TextDecorationStyle_Hover,

        left_nav_hauptkat_alleProd_hov_aussen_abstand_links_rechts:
          DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_marginLeftRight_Hover,

        left_nav_hauptkat_alleProd_hov_aussen_abstand_oben_unten:
          DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_marginTopBottom_Hover,

        left_nav_hauptkat_alleProd_hov_innerer_abstand_links_rechts:
          DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_paddingLeftRight_Hover,

        left_nav_hauptkat_alleProd_hov_innerer_abstand_oben_unten:
          DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_paddingTopBottom_Hover,

        left_nav_hauptkat_alleProd_hov_textausrichtung:
          DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_TextAlign,

        left_nav_hauptkat_alleProd_border_color_left:
          DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_BorderColorLeft,
        left_nav_hauptkat_alleProd_border_color_left_not_encoded:
          JSON.stringify(
            DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_BorderColorLeftNotEncoded
          ),

        left_nav_hauptkat_alleProd_border_color_right:
          DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_BorderColorRight,
        left_nav_hauptkat_alleProd_border_color_right_not_encoded:
          JSON.stringify(
            DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_BorderColorRightNotEncoded
          ),

        left_nav_hauptkat_alleProd_border_color_top:
          DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_BorderColorTop,
        left_nav_hauptkat_alleProd_border_color_top_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_BorderColorTopNotEncoded
        ),

        left_nav_hauptkat_alleProd_border_color_bottom:
          DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_BorderColorBottom,
        left_nav_hauptkat_alleProd_border_color_bottom_not_encoded:
          JSON.stringify(
            DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_BorderColorBottomNotEncoded
          ),

        left_nav_hauptkat_alleProd_border_pixel_left:
          DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_BorderLeft,
        left_nav_hauptkat_alleProd_border_pixel_right:
          DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_BorderRight,
        left_nav_hauptkat_alleProd_border_pixel_top:
          DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_BorderTop,
        left_nav_hauptkat_alleProd_border_pixel_bottom:
          DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_BorderBottom,

        left_nav_hauptkat_alleProd_border_radius_top_left:
          DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_BorderTopLeftRadius,
        left_nav_hauptkat_alleProd_border_radius_top_right:
          DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_BorderTopRightRadius,
        left_nav_hauptkat_alleProd_border_radius_bottom_left:
          DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_BorderBottomLeftRadius,
        left_nav_hauptkat_alleProd_border_radius_bottom_right:
          DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_BorderBottomRightRadius,

        left_nav_hauptkat_alleProd_border_style_left:
          DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_BorderStyleLeft,
        left_nav_hauptkat_alleProd_border_style_right:
          DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_BorderStyleRight,
        left_nav_hauptkat_alleProd_border_style_top:
          DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_BorderStyleTop,
        left_nav_hauptkat_alleProd_border_style_bottom:
          DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_BorderStyleBottom,

        left_nav_hauptkat_alleProd_hov_border_color_left:
          DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_BorderColorLeft,

        left_nav_hauptkat_alleProd_hov_border_color_left_not_encoded:
          JSON.stringify(
            DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_BorderColorLeftNotEncoded
          ),

        left_nav_hauptkat_alleProd_hov_border_color_right:
          DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_BorderColorRight,
        left_nav_hauptkat_alleProd_hov_border_color_right_not_encoded:
          JSON.stringify(
            DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_BorderColorRightNotEncoded
          ),

        left_nav_hauptkat_alleProd_hov_border_color_top:
          DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_BorderColorTop,
        left_nav_hauptkat_alleProd_hov_border_color_top_not_encoded:
          JSON.stringify(
            DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_BorderColorTopNotEncoded
          ),

        left_nav_hauptkat_alleProd_hov_border_color_bottom:
          DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_BorderColorBottom,
        left_nav_hauptkat_alleProd_hov_border_color_bottom_not_encoded:
          JSON.stringify(
            DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_BorderColorBottomNotEncoded
          ),

        left_nav_hauptkat_alleProd_hov_border_pixel_left:
          DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_BorderLeft,

        left_nav_hauptkat_alleProd_hov_border_pixel_right:
          DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_BorderRight,

        left_nav_hauptkat_alleProd_hov_border_pixel_top:
          DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_BorderTop,

        left_nav_hauptkat_alleProd_hov_border_pixel_bottom:
          DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_BorderBottom,

        left_nav_hauptkat_alleProd_hov_border_radius_top_left:
          DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_BorderTopLeftRadius,

        left_nav_hauptkat_alleProd_hov_border_radius_top_right:
          DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_BorderTopRightRadius,

        left_nav_hauptkat_alleProd_hov_border_radius_bottom_left:
          DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_BorderBottomLeftRadius,

        left_nav_hauptkat_alleProd_hov_border_radius_bottom_right:
          DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_BorderBottomRightRadius,

        left_nav_hauptkat_alleProd_hov_border_style_left:
          DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_BorderStyleLeft,

        left_nav_hauptkat_alleProd_hov_border_style_right:
          DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_BorderStyleRight,

        left_nav_hauptkat_alleProd_hov_border_style_top:
          DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_BorderStyleTop,

        left_nav_hauptkat_alleProd_hov_border_style_bottom:
          DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_BorderStyleBottom,
      };

      const design_kategorien_leftside_categories = {
        left_hauptkat_schriftfarbe:
          DB_LeftSidebar_MenuItem_Hauptkategorie_FontColor,
        left_hauptkat_schriftfarbe_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_Hauptkategorie_FontColorNotEncoded
        ),
        left_hauptkat_hintergrundfarbe:
          DB_LeftSidebar_MenuItem_Hauptkategorie_BackgroundColor,
        left_hauptkat_hintergrundfarbe_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_Hauptkategorie_BackgroundColorNotEncoded
        ),
        left_hauptkat_schattenfarbe:
          DB_LeftSidebar_MenuItem_Hauptkategorie_ShadowColor,
        left_hauptkat_schattenfarbe_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_Hauptkategorie_ShadowColorNotEncoded
        ),
        left_hauptkat_schattenposition_horizontal:
          DB_LeftSidebar_MenuItem_Hauptkategorie_ShadowHorizontal,
        left_hauptkat_schattenposition_vertikal:
          DB_LeftSidebar_MenuItem_Hauptkategorie_ShadowVertical,
        left_hauptkat_schattenrauschen:
          DB_LeftSidebar_MenuItem_Hauptkategorie_ShadowBlur,
        left_hauptkat_schriftgroesse:
          DB_LeftSidebar_MenuItem_Hauptkategorie_FontSize,
        left_hauptkat_schriftgewicht:
          DB_LeftSidebar_MenuItem_Hauptkategorie_FontWeight,
        left_hauptkat_buchstabenabstand:
          DB_LeftSidebar_MenuItem_Hauptkategorie_letterSpacing,
        left_hauptkat_text_dekoration:
          DB_LeftSidebar_MenuItem_Hauptkategorie_TextDecoration,
        left_hauptkat_text_dekoration_stil:
          DB_LeftSidebar_MenuItem_Hauptkategorie_TextDecorationStyle,
        left_hauptkat_aussen_abstand_links_rechts:
          DB_LeftSidebar_MenuItem_Hauptkategorie_marginLeftRight,
        left_hauptkat_aussen_abstand_oben_unten:
          DB_LeftSidebar_MenuItem_Hauptkategorie_marginTopBottom,
        left_hauptkat_innerer_abstand_links_rechts:
          DB_LeftSidebar_MenuItem_Hauptkategorie_paddingLeftRight,
        left_hauptkat_innerer_abstand_oben_unten:
          DB_LeftSidebar_MenuItem_Hauptkategorie_paddingTopBottom,
        left_hauptkat_textausrichtung:
          DB_LeftSidebar_MenuItem_Hauptkategorie_TextAlign,
        left_hauptkat_fontfamily:
          DB_fontFace_hauptkategorieTitel_FontFamilyName,
        left_hauptkat_fontfamilysrc:
          DB_fontFace_hauptkategorieTitel_FontFamilySrc,

        left_hauptkat_hov_schriftfarbe:
          DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_FontColor,
        left_hauptkat_hov_schriftfarbe_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_FontColorNotEncoded
        ),
        left_hauptkat_hov_hintergrundfarbe:
          DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_BackgroundColor,
        left_hauptkat_hov_hintergrundfarbe_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_BackgroundColorNotEncoded
        ),
        left_hauptkat_hov_schattenfarbe:
          DB_LeftSidebar_MenuItem_Hauptkategorie_ShadowColor_Hover,
        left_hauptkat_hov_schattenfarbe_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_Hauptkategorie_ShadowColorNotEncoded_Hover
        ),
        left_hauptkat_hov_schattenposition_horizontal:
          DB_LeftSidebar_MenuItem_Hauptkategorie_ShadowHorizontal_Hover,
        left_hauptkat_hov_schattenposition_vertikal:
          DB_LeftSidebar_MenuItem_Hauptkategorie_ShadowVertical_Hover,
        left_hauptkat_hov_schattenrauschen:
          DB_LeftSidebar_MenuItem_Hauptkategorie_ShadowBlur_Hover,
        left_hauptkat_hov_schriftgroesse:
          DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_FontSize,
        left_hauptkat_hov_schriftgewicht:
          DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_FontWeight,
        left_hauptkat_hov_buchstabenabstand:
          DB_LeftSidebar_MenuItem_Hauptkategorie_letterSpacing_Hover,
        left_hauptkat_hov_text_dekoration:
          DB_LeftSidebar_MenuItem_Hauptkategorie_TextDecoration_Hover,
        left_hauptkat_hov_text_dekoration_stil:
          DB_LeftSidebar_MenuItem_Hauptkategorie_TextDecorationStyle_Hover,
        left_hauptkat_hov_aussen_abstand_links_rechts:
          DB_LeftSidebar_MenuItem_Hauptkategorie_marginLeftRight_Hover,
        left_hauptkat_hov_aussen_abstand_oben_unten:
          DB_LeftSidebar_MenuItem_Hauptkategorie_marginTopBottom_Hover,
        left_hauptkat_hov_innerer_abstand_links_rechts:
          DB_LeftSidebar_MenuItem_Hauptkategorie_paddingLeftRight_Hover,
        left_hauptkat_hov_innerer_abstand_oben_unten:
          DB_LeftSidebar_MenuItem_Hauptkategorie_paddingTopBottom_Hover,
        left_hauptkat_hov_textausrichtung:
          DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_TextAlign,

        left_hauptkat_border_color_left:
          DB_LeftSidebar_MenuItem_Hauptkategorie_BorderColorLeft,
        left_hauptkat_border_color_left_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_Hauptkategorie_BorderColorLeftNotEncoded
        ),
        left_hauptkat_border_color_right:
          DB_LeftSidebar_MenuItem_Hauptkategorie_BorderColorRight,
        left_hauptkat_border_color_right_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_Hauptkategorie_BorderColorRightNotEncoded
        ),
        left_hauptkat_border_color_top:
          DB_LeftSidebar_MenuItem_Hauptkategorie_BorderColorTop,
        left_hauptkat_border_color_top_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_Hauptkategorie_BorderColorTopNotEncoded
        ),
        left_hauptkat_border_color_bottom:
          DB_LeftSidebar_MenuItem_Hauptkategorie_BorderColorBottom,
        left_hauptkat_border_color_bottom_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_Hauptkategorie_BorderColorBottomNotEncoded
        ),
        left_hauptkat_border_pixel_left:
          DB_LeftSidebar_MenuItem_Hauptkategorie_BorderLeft,
        left_hauptkat_border_pixel_right:
          DB_LeftSidebar_MenuItem_Hauptkategorie_BorderRight,
        left_hauptkat_border_pixel_top:
          DB_LeftSidebar_MenuItem_Hauptkategorie_BorderTop,
        left_hauptkat_border_pixel_bottom:
          DB_LeftSidebar_MenuItem_Hauptkategorie_BorderBottom,
        left_hauptkat_border_radius_top_left:
          DB_LeftSidebar_MenuItem_Hauptkategorie_BorderTopLeftRadius,
        left_hauptkat_border_radius_top_right:
          DB_LeftSidebar_MenuItem_Hauptkategorie_BorderTopRightRadius,
        left_hauptkat_border_radius_bottom_left:
          DB_LeftSidebar_MenuItem_Hauptkategorie_BorderBottomLeftRadius,
        left_hauptkat_border_radius_bottom_right:
          DB_LeftSidebar_MenuItem_Hauptkategorie_BorderBottomRightRadius,
        left_hauptkat_border_style_left:
          DB_LeftSidebar_MenuItem_Hauptkategorie_BorderStyleLeft,
        left_hauptkat_border_style_right:
          DB_LeftSidebar_MenuItem_Hauptkategorie_BorderStyleRight,
        left_hauptkat_border_style_top:
          DB_LeftSidebar_MenuItem_Hauptkategorie_BorderStyleTop,
        left_hauptkat_border_style_bottom:
          DB_LeftSidebar_MenuItem_Hauptkategorie_BorderStyleBottom,

        left_hauptkat_border_hov_color_left:
          DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_BorderColorLeft,
        left_hauptkat_border_hov_color_left_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_BorderColorLeftNotEncoded
        ),
        left_hauptkat_border_hov_color_right:
          DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_BorderColorRight,
        left_hauptkat_border_hov_color_right_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_BorderColorRightNotEncoded
        ),
        left_hauptkat_border_hov_color_top:
          DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_BorderColorTop,
        left_hauptkat_border_hov_color_top_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_BorderColorTopNotEncoded
        ),
        left_hauptkat_border_hov_color_bottom:
          DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_BorderColorBottom,
        left_hauptkat_border_hov_color_bottom_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_BorderColorBottomNotEncoded
        ),
        left_hauptkat_border_hov_pixel_left:
          DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_BorderLeft,
        left_hauptkat_border_hov_pixel_right:
          DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_BorderRight,
        left_hauptkat_border_hov_pixel_top:
          DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_BorderTop,
        left_hauptkat_border_hov_pixel_bottom:
          DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_BorderBottom,
        left_hauptkat_border_hov_radius_top_left:
          DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_BorderTopLeftRadius,
        left_hauptkat_border_hov_radius_top_right:
          DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_BorderTopRightRadius,
        left_hauptkat_border_hov_radius_bottom_left:
          DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_BorderBottomLeftRadius,
        left_hauptkat_border_hov_radius_bottom_right:
          DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_BorderBottomRightRadius,
        left_hauptkat_border_hov_style_left:
          DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_BorderStyleLeft,
        left_hauptkat_border_hov_style_right:
          DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_BorderStyleRight,
        left_hauptkat_border_hov_style_top:
          DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_BorderStyleTop,
        left_hauptkat_border_hov_style_bottom:
          DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_BorderStyleBottom,

        left_unterkat_schriftfarbe:
          DB_LeftSidebar_MenuItem_Unterkategorie_FontColor,
        left_unterkat_schriftfarbe_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_Unterkategorie_FontColorNotEncoded
        ),
        left_unterkat_hintergrundfarbe:
          DB_LeftSidebar_MenuItem_Unterkategorie_BackgroundColor,
        left_unterkat_hintergrundfarbe_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_Unterkategorie_BackgroundColorNotEncoded
        ),
        left_unterkat_schattenfarbe:
          DB_LeftSidebar_MenuItem_Unterkategorie_ShadowColor,
        left_unterkat_schattenfarbe_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_Unterkategorie_ShadowColorNotEncoded
        ),
        left_unterkat_schattenposition_horizontal:
          DB_LeftSidebar_MenuItem_Unterkategorie_ShadowHorizontal,
        left_unterkat_schattenposition_vertikal:
          DB_LeftSidebar_MenuItem_Unterkategorie_ShadowVertical,
        left_unterkat_schattenrauschen:
          DB_LeftSidebar_MenuItem_Unterkategorie_ShadowBlur,
        left_unterkat_schriftgroesse:
          DB_LeftSidebar_MenuItem_Unterkategorie_FontSize,
        left_unterkat_schriftgewicht:
          DB_LeftSidebar_MenuItem_Unterkategorie_FontWeight,
        left_unterkat_buchstabenabstand:
          DB_LeftSidebar_MenuItem_Unterkategorie_letterSpacing,
        left_unterkat_text_dekoration:
          DB_LeftSidebar_MenuItem_Unterkategorie_TextDecoration,
        left_unterkat_text_dekoration_stil:
          DB_LeftSidebar_MenuItem_Unterkategorie_TextDecorationStyle,
        left_unterkat_aussen_abstand_links_rechts:
          DB_LeftSidebar_MenuItem_Unterkategorie_marginLeftRight,
        left_unterkat_aussen_abstand_oben_unten:
          DB_LeftSidebar_MenuItem_Unterkategorie_marginTopBottom,
        left_unterkat_innerer_abstand_links_rechts:
          DB_LeftSidebar_MenuItem_Unterkategorie_paddingLeftRight,
        left_unterkat_innerer_abstand_oben_unten:
          DB_LeftSidebar_MenuItem_Unterkategorie_paddingTopBottom,
        left_unterkat_textausrichtung:
          DB_LeftSidebar_MenuItem_Unterkategorie_TextAlign,
        left_unterkat_fontfamily:
          DB_fontFace_unterkategorieTitel_FontFamilyName,
        left_unterkat_fontfamilysrc:
          DB_fontFace_unterkategorieTitel_FontFamilySrc,

        left_unterkat_hov_schriftfarbe:
          DB_LeftSidebar_MenuItem_Unterkategorie_Hover_FontColor,
        left_unterkat_hov_schriftfarbe_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_Unterkategorie_Hover_FontColorNotEncoded
        ),
        left_unterkat_hov_hintergrundfarbe:
          DB_LeftSidebar_MenuItem_Unterkategorie_Hover_BackgroundColor,
        left_unterkat_hov_hintergrundfarbe_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_Unterkategorie_Hover_BackgroundColorNotEncoded
        ),
        left_unterkat_hov_schattenfarbe:
          DB_LeftSidebar_MenuItem_Unterkategorie_ShadowColor_Hover,
        left_unterkat_hov_schattenfarbe_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_Unterkategorie_ShadowColorNotEncoded_Hover
        ),
        left_unterkat_hov_schattenposition_horizontal:
          DB_LeftSidebar_MenuItem_Unterkategorie_ShadowHorizontal_Hover,
        left_unterkat_hov_schattenposition_vertikal:
          DB_LeftSidebar_MenuItem_Unterkategorie_ShadowVertical_Hover,
        left_unterkat_hov_schattenrauschen:
          DB_LeftSidebar_MenuItem_Unterkategorie_ShadowBlur_Hover,
        left_unterkat_hov_schriftgroesse:
          DB_LeftSidebar_MenuItem_Unterkategorie_Hover_FontSize,
        left_unterkat_hov_schriftgewicht:
          DB_LeftSidebar_MenuItem_Unterkategorie_Hover_FontWeight,
        left_unterkat_hov_buchstabenabstand:
          DB_LeftSidebar_MenuItem_Unterkategorie_letterSpacing_Hover,
        left_unterkat_hov_text_dekoration:
          DB_LeftSidebar_MenuItem_Unterkategorie_TextDecoration_Hover,
        left_unterkat_hov_text_dekoration_stil:
          DB_LeftSidebar_MenuItem_Unterkategorie_TextDecorationStyle_Hover,
        left_unterkat_hov_aussen_abstand_links_rechts:
          DB_LeftSidebar_MenuItem_Unterkategorie_marginLeftRight_Hover,
        left_unterkat_hov_aussen_abstand_oben_unten:
          DB_LeftSidebar_MenuItem_Unterkategorie_marginTopBottom_Hover,
        left_unterkat_hov_innerer_abstand_links_rechts:
          DB_LeftSidebar_MenuItem_Unterkategorie_paddingLeftRight_Hover,
        left_unterkat_hov_innerer_abstand_oben_unten:
          DB_LeftSidebar_MenuItem_Unterkategorie_paddingTopBottom_Hover,
        left_unterkat_hov_textausrichtung:
          DB_LeftSidebar_MenuItem_Unterkategorie_Hover_TextAlign,

        left_unterkat_border_color_left:
          DB_LeftSidebar_MenuItem_Unterkategorie_BorderColorLeft,
        left_unterkat_border_color_left_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_Unterkategorie_BorderColorLeftNotEncoded
        ),
        left_unterkat_border_color_right:
          DB_LeftSidebar_MenuItem_Unterkategorie_BorderColorRight,
        left_unterkat_border_color_right_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_Unterkategorie_BorderColorRightNotEncoded
        ),
        left_unterkat_border_color_top:
          DB_LeftSidebar_MenuItem_Unterkategorie_BorderColorTop,
        left_unterkat_border_color_top_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_Unterkategorie_BorderColorTopNotEncoded
        ),
        left_unterkat_border_color_bottom:
          DB_LeftSidebar_MenuItem_Unterkategorie_BorderColorBottom,
        left_unterkat_border_color_bottom_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_Unterkategorie_BorderColorBottomNotEncoded
        ),
        left_unterkat_border_pixel_left:
          DB_LeftSidebar_MenuItem_Unterkategorie_BorderLeft,
        left_unterkat_border_pixel_right:
          DB_LeftSidebar_MenuItem_Unterkategorie_BorderRight,
        left_unterkat_border_pixel_top:
          DB_LeftSidebar_MenuItem_Unterkategorie_BorderTop,
        left_unterkat_border_pixel_bottom:
          DB_LeftSidebar_MenuItem_Unterkategorie_BorderBottom,
        left_unterkat_border_radius_top_left:
          DB_LeftSidebar_MenuItem_Unterkategorie_BorderTopLeftRadius,
        left_unterkat_border_radius_top_right:
          DB_LeftSidebar_MenuItem_Unterkategorie_BorderTopRightRadius,
        left_unterkat_border_radius_bottom_left:
          DB_LeftSidebar_MenuItem_Unterkategorie_BorderBottomLeftRadius,
        left_unterkat_border_radius_bottom_right:
          DB_LeftSidebar_MenuItem_Unterkategorie_BorderBottomRightRadius,
        left_unterkat_border_style_left:
          DB_LeftSidebar_MenuItem_Unterkategorie_BorderStyleLeft,
        left_unterkat_border_style_right:
          DB_LeftSidebar_MenuItem_Unterkategorie_BorderStyleRight,
        left_unterkat_border_style_top:
          DB_LeftSidebar_MenuItem_Unterkategorie_BorderStyleTop,
        left_unterkat_border_style_bottom:
          DB_LeftSidebar_MenuItem_Unterkategorie_BorderStyleBottom,

        left_unterkat_border_hov_color_left:
          DB_LeftSidebar_MenuItem_Unterkategorie_Hover_BorderColorLeft,
        left_unterkat_border_hov_color_left_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_Unterkategorie_Hover_BorderColorLeftNotEncoded
        ),
        left_unterkat_border_hov_color_right:
          DB_LeftSidebar_MenuItem_Unterkategorie_Hover_BorderColorRight,
        left_unterkat_border_hov_color_right_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_Unterkategorie_Hover_BorderColorRightNotEncoded
        ),
        left_unterkat_border_hov_color_top:
          DB_LeftSidebar_MenuItem_Unterkategorie_Hover_BorderColorTop,
        left_unterkat_border_hov_color_top_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_Unterkategorie_Hover_BorderColorTopNotEncoded
        ),
        left_unterkat_border_hov_color_bottom:
          DB_LeftSidebar_MenuItem_Unterkategorie_Hover_BorderColorBottom,
        left_unterkat_border_hov_color_bottom_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_Unterkategorie_Hover_BorderColorBottomNotEncoded
        ),
        left_unterkat_border_hov_pixel_left:
          DB_LeftSidebar_MenuItem_Unterkategorie_Hover_BorderLeft,
        left_unterkat_border_hov_pixel_right:
          DB_LeftSidebar_MenuItem_Unterkategorie_Hover_BorderRight,
        left_unterkat_border_hov_pixel_top:
          DB_LeftSidebar_MenuItem_Unterkategorie_Hover_BorderTop,
        left_unterkat_border_hov_pixel_bottom:
          DB_LeftSidebar_MenuItem_Unterkategorie_Hover_BorderBottom,
        left_unterkat_border_hov_radius_top_left:
          DB_LeftSidebar_MenuItem_Unterkategorie_Hover_BorderTopLeftRadius,
        left_unterkat_border_hov_radius_top_right:
          DB_LeftSidebar_MenuItem_Unterkategorie_Hover_BorderTopRightRadius,
        left_unterkat_border_hov_radius_bottom_left:
          DB_LeftSidebar_MenuItem_Unterkategorie_Hover_BorderBottomLeftRadius,
        left_unterkat_border_hov_radius_bottom_right:
          DB_LeftSidebar_MenuItem_Unterkategorie_Hover_BorderBottomRightRadius,
        left_unterkat_border_hov_style_left:
          DB_LeftSidebar_MenuItem_Unterkategorie_Hover_BorderStyleLeft,
        left_unterkat_border_hov_style_right:
          DB_LeftSidebar_MenuItem_Unterkategorie_Hover_BorderStyleRight,
        left_unterkat_border_hov_style_top:
          DB_LeftSidebar_MenuItem_Unterkategorie_Hover_BorderStyleTop,
        left_unterkat_border_hov_style_bottom:
          DB_LeftSidebar_MenuItem_Unterkategorie_Hover_BorderStyleBottom,

        left_subunterkat_schriftfarbe:
          DB_LeftSidebar_MenuItem_UnterUnterkategorie_FontColor,
        left_subunterkat_schriftfarbe_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_UnterUnterkategorie_FontColorNotEncoded
        ),
        left_subunterkat_hintergrundfarbe:
          DB_LeftSidebar_MenuItem_UnterUnterkategorie_BackgroundColor,
        left_subunterkat_hintergrundfarbe_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_UnterUnterkategorie_BackgroundColorNotEncoded
        ),
        left_subunterkat_schattenfarbe:
          DB_LeftSidebar_MenuItem_UnterUnterkategorie_ShadowColor,
        left_subunterkat_schattenfarbe_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_UnterUnterkategorie_ShadowColorNotEncoded
        ),
        left_subunterkat_schattenposition_horizontal:
          DB_LeftSidebar_MenuItem_UnterUnterkategorie_ShadowHorizontal,
        left_subunterkat_schattenposition_vertikal:
          DB_LeftSidebar_MenuItem_UnterUnterkategorie_ShadowVertical,
        left_subunterkat_schattenrauschen:
          DB_LeftSidebar_MenuItem_UnterUnterkategorie_ShadowBlur,
        left_subunterkat_schriftgroesse:
          DB_LeftSidebar_MenuItem_UnterUnterkategorie_FontSize,
        left_subunterkat_schriftgewicht:
          DB_LeftSidebar_MenuItem_UnterUnterkategorie_FontWeight,
        left_subunterkat_buchstabenabstand:
          DB_LeftSidebar_MenuItem_UnterUnterkategorie_letterSpacing,
        left_subunterkat_text_dekoration:
          DB_LeftSidebar_MenuItem_UnterUnterkategorie_TextDecoration,
        left_subunterkat_text_dekoration_stil:
          DB_LeftSidebar_MenuItem_UnterUnterkategorie_TextDecorationStyle,
        left_subunterkat_aussen_abstand_links_rechts:
          DB_LeftSidebar_MenuItem_UnterUnterkategorie_marginLeftRight,
        left_subunterkat_aussen_abstand_oben_unten:
          DB_LeftSidebar_MenuItem_UnterUnterkategorie_marginTopBottom,
        left_subunterkat_innerer_abstand_links_rechts:
          DB_LeftSidebar_MenuItem_UnterUnterkategorie_paddingLeftRight,
        left_subunterkat_innerer_abstand_oben_unten:
          DB_LeftSidebar_MenuItem_UnterUnterkategorie_paddingTopBottom,
        left_subunterkat_textausrichtung:
          DB_LeftSidebar_MenuItem_UnterUnterkategorie_TextAlign,
        left_subunterkat_fontfamily:
          DB_fontFace_unterunterkategorieTitel_FontFamilyName,
        left_subunterkat_fontfamilysrc:
          DB_fontFace_unterunterkategorieTitel_FontFamilySrc,

        left_subunterkat_hov_schriftfarbe:
          DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_FontColor,
        left_subunterkat_hov_schriftfarbe_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_FontColorNotEncoded
        ),
        left_subunterkat_hov_hintergrundfarbe:
          DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_BackgroundColor,
        left_subunterkat_hov_hintergrundfarbe_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_BackgroundColorNotEncoded
        ),
        left_subunterkat_hov_schattenfarbe:
          DB_LeftSidebar_MenuItem_UnterUnterkategorie_ShadowColor_Hover,
        left_subunterkat_hov_schattenfarbe_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_UnterUnterkategorie_ShadowColorNotEncoded_Hover
        ),
        left_subunterkat_hov_schattenposition_horizontal:
          DB_LeftSidebar_MenuItem_UnterUnterkategorie_ShadowHorizontal_Hover,
        left_subunterkat_hov_schattenposition_vertikal:
          DB_LeftSidebar_MenuItem_UnterUnterkategorie_ShadowVertical_Hover,
        left_subunterkat_hov_schattenrauschen:
          DB_LeftSidebar_MenuItem_UnterUnterkategorie_ShadowBlur_Hover,
        left_subunterkat_hov_schriftgroesse:
          DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_FontSize,
        left_subunterkat_hov_schriftgewicht:
          DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_FontWeight,
        left_subunterkat_hov_buchstabenabstand:
          DB_LeftSidebar_MenuItem_UnterUnterkategorie_letterSpacing_Hover,
        left_subunterkat_hov_text_dekoration:
          DB_LeftSidebar_MenuItem_UnterUnterkategorie_TextDecoration_Hover,
        left_subunterkat_hov_text_dekoration_stil:
          DB_LeftSidebar_MenuItem_UnterUnterkategorie_TextDecorationStyle_Hover,
        left_subunterkat_hov_aussen_abstand_links_rechts:
          DB_LeftSidebar_MenuItem_UnterUnterkategorie_marginLeftRight_Hover,
        left_subunterkat_hov_aussen_abstand_oben_unten:
          DB_LeftSidebar_MenuItem_UnterUnterkategorie_marginTopBottom_Hover,
        left_subunterkat_hov_innerer_abstand_links_rechts:
          DB_LeftSidebar_MenuItem_UnterUnterkategorie_paddingLeftRight_Hover,
        left_subunterkat_hov_innerer_abstand_oben_unten:
          DB_LeftSidebar_MenuItem_UnterUnterkategorie_paddingTopBottom_Hover,
        left_subunterkat_hov_textausrichtung:
          DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_TextAlign,

        left_subunterkat_border_color_left:
          DB_LeftSidebar_MenuItem_UnterUnterkategorie_BorderColorLeft,
        left_subunterkat_border_color_left_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_UnterUnterkategorie_BorderColorLeftNotEncoded
        ),
        left_subunterkat_border_color_right:
          DB_LeftSidebar_MenuItem_UnterUnterkategorie_BorderColorRight,
        left_subunterkat_border_color_right_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_UnterUnterkategorie_BorderColorRightNotEncoded
        ),
        left_subunterkat_border_color_top:
          DB_LeftSidebar_MenuItem_UnterUnterkategorie_BorderColorTop,
        left_subunterkat_border_color_top_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_UnterUnterkategorie_BorderColorTopNotEncoded
        ),
        left_subunterkat_border_color_bottom:
          DB_LeftSidebar_MenuItem_UnterUnterkategorie_BorderColorBottom,
        left_subunterkat_border_color_bottom_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_UnterUnterkategorie_BorderColorBottomNotEncoded
        ),
        left_subunterkat_border_pixel_left:
          DB_LeftSidebar_MenuItem_UnterUnterkategorie_BorderLeft,
        left_subunterkat_border_pixel_right:
          DB_LeftSidebar_MenuItem_UnterUnterkategorie_BorderRight,
        left_subunterkat_border_pixel_top:
          DB_LeftSidebar_MenuItem_UnterUnterkategorie_BorderTop,
        left_subunterkat_border_pixel_bottom:
          DB_LeftSidebar_MenuItem_UnterUnterkategorie_BorderBottom,
        left_subunterkat_border_radius_top_left:
          DB_LeftSidebar_MenuItem_UnterUnterkategorie_BorderTopLeftRadius,
        left_subunterkat_border_radius_top_right:
          DB_LeftSidebar_MenuItem_UnterUnterkategorie_BorderTopRightRadius,
        left_subunterkat_border_radius_bottom_left:
          DB_LeftSidebar_MenuItem_UnterUnterkategorie_BorderBottomLeftRadius,
        left_subunterkat_border_radius_bottom_right:
          DB_LeftSidebar_MenuItem_UnterUnterkategorie_BorderBottomRightRadius,
        left_subunterkat_border_style_left:
          DB_LeftSidebar_MenuItem_UnterUnterkategorie_BorderStyleLeft,
        left_subunterkat_border_style_right:
          DB_LeftSidebar_MenuItem_UnterUnterkategorie_BorderStyleRight,
        left_subunterkat_border_style_top:
          DB_LeftSidebar_MenuItem_UnterUnterkategorie_BorderStyleTop,
        left_subunterkat_border_style_bottom:
          DB_LeftSidebar_MenuItem_UnterUnterkategorie_BorderStyleBottom,

        left_subunterkat_border_hov_color_left:
          DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_BorderColorLeft,
        left_subunterkat_border_hov_color_left_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_BorderColorLeftNotEncoded
        ),
        left_subunterkat_border_hov_color_right:
          DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_BorderColorRight,
        left_subunterkat_border_hov_color_right_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_BorderColorRightNotEncoded
        ),
        left_subunterkat_border_hov_color_top:
          DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_BorderColorTop,
        left_subunterkat_border_hov_color_top_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_BorderColorTopNotEncoded
        ),
        left_subunterkat_border_hov_color_bottom:
          DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_BorderColorBottom,
        left_subunterkat_border_hov_color_bottom_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_BorderColorBottomNotEncoded
        ),
        left_subunterkat_border_hov_pixel_left:
          DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_BorderLeft,
        left_subunterkat_border_hov_pixel_right:
          DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_BorderRight,
        left_subunterkat_border_hov_pixel_top:
          DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_BorderTop,
        left_subunterkat_border_hov_pixel_bottom:
          DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_BorderBottom,
        left_subunterkat_border_hov_radius_top_left:
          DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_BorderTopLeftRadius,
        left_subunterkat_border_hov_radius_top_right:
          DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_BorderTopRightRadius,
        left_subunterkat_border_hov_radius_bottom_left:
          DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_BorderBottomLeftRadius,
        left_subunterkat_border_hov_radius_bottom_right:
          DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_BorderBottomRightRadius,
        left_subunterkat_border_hov_style_left:
          DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_BorderStyleLeft,
        left_subunterkat_border_hov_style_right:
          DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_BorderStyleRight,
        left_subunterkat_border_hov_style_top:
          DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_BorderStyleTop,
        left_subunterkat_border_hov_style_bottom:
          DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_BorderStyleBottom,
      };

      const design_rightside_social = {
        right_social_hintergrundfarbe:
          DB_LeftSidebar_MenuContainer_Social_DDBackgroundColor,
        right_social_hintergrundfarbe_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuContainer_Social_DDBackgroundColorNotEncoded
        ),
        right_social_schattenfarbe:
          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDShadowColor,
        right_social_schattenfarbe_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDShadowColorNotEncoded
        ),
        right_social_schattenposition_horizontal:
          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDShadowHorizontal,
        right_social_schattenposition_vertikal:
          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDShadowVertical,
        right_social_schattenrauschen:
          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDShadowBlur,
        right_social_innerer_abstand_links:
          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDpaddingLeft,
        right_social_innerer_abstand_rechts:
          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDpaddingRight,
        right_social_innerer_abstand_oben:
          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDpaddingTop,
        right_social_innerer_abstand_unten:
          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDpaddingBottom,

        right_social_hover_hintergrundfarbe:
          DB_LeftSidebar_MenuContainer_Social_DDHover_BackgroundColor,
        right_social_hover_hintergrundfarbe_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuContainer_Social_DDHover_BackgroundColorNotEncoded
        ),
        right_social_hover_schattenfarbe:
          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_ShadowColor,
        right_social_hover_schattenfarbe_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_ShadowColorNotEncoded
        ),
        right_social_hover_schattenposition_horizontal:
          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_ShadowHorizontal,
        right_social_hover_schattenposition_vertikal:
          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_ShadowVertical,
        right_social_hover_schattenrauschen:
          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_ShadowBlur,
        right_social_hover_innerer_abstand_links:
          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_paddingLeft,
        right_social_hover_innerer_abstand_rechts:
          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_paddingRight,
        right_social_hover_innerer_abstand_oben:
          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_paddingTop,
        right_social_hover_innerer_abstand_unten:
          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_paddingBottom,

        right_social_border_color_left:
          DB_LeftSidebar_MenuContainer_Social_DDBorderColorLeft,
        right_social_border_color_left_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuContainer_Social_DDBorderColorLeftNotEncoded
        ),
        right_social_border_color_right:
          DB_LeftSidebar_MenuContainer_Social_DDBorderColorRight,
        right_social_border_color_right_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuContainer_Social_DDBorderColorRightNotEncoded
        ),
        right_social_border_color_top:
          DB_LeftSidebar_MenuContainer_Social_DDBorderColorTop,
        right_social_border_color_top_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuContainer_Social_DDBorderColorTopNotEncoded
        ),
        right_social_border_color_bottom:
          DB_LeftSidebar_MenuContainer_Social_DDBorderColorBottom,
        right_social_border_color_bottom_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuContainer_Social_DDBorderColorBottomNotEncoded
        ),
        right_social_border_pixel_left:
          DB_LeftSidebar_MenuContainer_Social_DDBorderLeft,
        right_social_border_pixel_right:
          DB_LeftSidebar_MenuContainer_Social_DDBorderRight,
        right_social_border_pixel_top:
          DB_LeftSidebar_MenuContainer_Social_DDBorderTop,
        right_social_border_pixel_bottom:
          DB_LeftSidebar_MenuContainer_Social_DDBorderBottom,
        right_social_border_radius_top_left:
          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDBorderTopLeftRadius,
        right_social_border_radius_top_right:
          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDBorderTopRightRadius,
        right_social_border_radius_bottom_left:
          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDBorderBottomLeftRadius,
        right_social_border_radius_bottom_right:
          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDBorderBottomRightRadius,
        right_social_border_style_left:
          DB_LeftSidebar_MenuContainer_Social_DDBorderStyleLeft,
        right_social_border_style_right:
          DB_LeftSidebar_MenuContainer_Social_DDBorderStyleRight,
        right_social_border_style_top:
          DB_LeftSidebar_MenuContainer_Social_DDBorderStyleTop,
        right_social_border_style_bottom:
          DB_LeftSidebar_MenuContainer_Social_DDBorderStyleBottom,

        right_social_border_hov_color_left:
          DB_LeftSidebar_MenuContainer_Social_DDHover_BorderColorLeft,
        right_social_border_hov_color_left_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuContainer_Social_DDHover_BorderColorLeftNotEncoded
        ),
        right_social_border_hov_color_right:
          DB_LeftSidebar_MenuContainer_Social_DDHover_BorderColorRight,
        right_social_border_hov_color_right_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuContainer_Social_DDHover_BorderColorRightNotEncoded
        ),
        right_social_border_hov_color_top:
          DB_LeftSidebar_MenuContainer_Social_DDHover_BorderColorTop,
        right_social_border_hov_color_top_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuContainer_Social_DDHover_BorderColorTopNotEncoded
        ),
        right_social_border_hov_color_bottom:
          DB_LeftSidebar_MenuContainer_Social_DDHover_BorderColorBottom,
        right_social_border_hov_color_bottom_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuContainer_Social_DDHover_BorderColorBottomNotEncoded
        ),
        right_social_border_hov_pixel_left:
          DB_LeftSidebar_MenuContainer_Social_DDHover_BorderLeft,
        right_social_border_hov_pixel_right:
          DB_LeftSidebar_MenuContainer_Social_DDHover_BorderRight,
        right_social_border_hov_pixel_top:
          DB_LeftSidebar_MenuContainer_Social_DDHover_BorderTop,
        right_social_border_hov_pixel_bottom:
          DB_LeftSidebar_MenuContainer_Social_DDHover_BorderBottom,
        right_social_border_hov_radius_top_left:
          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_BorderTopLeftRadius,
        right_social_border_hov_radius_top_right:
          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_BorderTopRightRadius,
        right_social_border_hov_radius_bottom_left:
          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_BorderBottomLeftRadius,
        right_social_border_hov_radius_bottom_right:
          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_BorderBottomRightRadius,
        right_social_border_hov_style_left:
          DB_LeftSidebar_MenuContainer_Social_DDHover_BorderStyleLeft,
        right_social_border_hov_style_right:
          DB_LeftSidebar_MenuContainer_Social_DDHover_BorderStyleRight,
        right_social_border_hov_style_top:
          DB_LeftSidebar_MenuContainer_Social_DDHover_BorderStyleTop,
        right_social_border_hov_style_bottom:
          DB_LeftSidebar_MenuContainer_Social_DDHover_BorderStyleBottom,

        right_socialTitle_schriftfarbe:
          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_FontColor,
        right_socialTitle_schriftfarbe_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_FontColorNotEncoded
        ),
        right_socialTitle_hintergrundfarbe:
          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BackgroundColor,
        right_socialTitle_hintergrundfarbe_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BackgroundColorNotEncoded
        ),
        right_socialTitle_schattenfarbe:
          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowColor,
        right_socialTitle_schattenfarbe_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowColorNotEncoded
        ),
        right_socialTitle_schattenposition_horizontal:
          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowHorizontal,
        right_socialTitle_schattenposition_vertikal:
          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowVertical,
        right_socialTitle_schattenrauschen:
          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowBlur,
        right_socialTitle_schriftgroesse:
          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_FontSize,
        right_socialTitle_schriftgewicht:
          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_FontWeight,
        right_socialTitle_buchstabenabstand:
          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_letterSpacing,
        right_socialTitle_text_dekoration:
          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_TextDecoration,
        right_socialTitle_text_dekoration_stil:
          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_TextDecorationStyle,
        right_socialTitle_aussen_abstand_links_rechts:
          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_marginLeftRight,
        right_socialTitle_aussen_abstand_oben_unten:
          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_marginTopBottom,
        right_socialTitle_innerer_abstand_links_rechts:
          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_paddingLeftRight,
        right_socialTitle_innerer_abstand_oben_unten:
          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_paddingTopBottom,
        right_socialTitle_textausrichtung:
          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_TextAlign,
        right_socialTitle_fontfamily:
          DB_fontFace_SocialTitelFontFamily_FontFamilyName,
        right_socialTitle_fontfamilysrc:
          DB_fontFace_SocialTitelFontFamily_FontFamilySrc,

        right_socialTitle_hov_schriftfarbe:
          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_FontColor,
        right_socialTitle_hov_schriftfarbe_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_FontColorNotEncoded
        ),
        right_socialTitle_hov_hintergrundfarbe:
          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BackgroundColor,
        right_socialTitle_hov_hintergrundfarbe_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BackgroundColorNotEncoded
        ),
        right_socialTitle_hov_schattenfarbe:
          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowColor_Hover,
        right_socialTitle_hov_schattenfarbe_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowColorNotEncoded_Hover
        ),
        right_socialTitle_hov_schattenposition_horizontal:
          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowHorizontal_Hover,
        right_socialTitle_hov_schattenposition_vertikal:
          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowVertical_Hover,
        right_socialTitle_hov_schattenrauschen:
          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowBlur_Hover,
        right_socialTitle_hov_schriftgroesse:
          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_FontSize,
        right_socialTitle_hov_schriftgewicht:
          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_FontWeight,
        right_socialTitle_hov_buchstabenabstand:
          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_letterSpacing_Hover,
        right_socialTitle_hov_text_dekoration:
          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_TextDecoration_Hover,
        right_socialTitle_hov_text_dekoration_stil:
          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_TextDecorationStyle_Hover,
        right_socialTitle_hov_aussen_abstand_links_rechts:
          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_marginLeftRight_Hover,
        right_socialTitle_hov_aussen_abstand_oben_unten:
          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_marginTopBottom_Hover,
        right_socialTitle_hov_innerer_abstand_links_rechts:
          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_paddingLeftRight_Hover,
        right_socialTitle_hov_innerer_abstand_oben_unten:
          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_paddingTopBottom_Hover,
        right_socialTitle_hov_textausrichtung:
          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_TextAlign,

        right_socialTitle_border_color_left:
          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderColorLeft,
        right_socialTitle_border_color_left_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderColorLeftNotEncoded
        ),
        right_socialTitle_border_color_right:
          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderColorRight,
        right_socialTitle_border_color_right_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderColorRightNotEncoded
        ),
        right_socialTitle_border_color_top:
          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderColorTop,
        right_socialTitle_border_color_top_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderColorTopNotEncoded
        ),
        right_socialTitle_border_color_bottom:
          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderColorBottom,
        right_socialTitle_border_color_bottom_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderColorBottomNotEncoded
        ),
        right_socialTitle_border_pixel_left:
          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderLeft,
        right_socialTitle_border_pixel_right:
          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderRight,
        right_socialTitle_border_pixel_top:
          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderTop,
        right_socialTitle_border_pixel_bottom:
          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderBottom,
        right_socialTitle_border_radius_top_left:
          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderTopLeftRadius,
        right_socialTitle_border_radius_top_right:
          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderTopRightRadius,
        right_socialTitle_border_radius_bottom_left:
          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderBottomLeftRadius,
        right_socialTitle_border_radius_bottom_right:
          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderBottomRightRadius,
        right_socialTitle_border_style_left:
          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderStyleLeft,
        right_socialTitle_border_style_right:
          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderStyleRight,
        right_socialTitle_border_style_top:
          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderStyleTop,
        right_socialTitle_border_style_bottom:
          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderStyleBottom,

        right_socialTitle_border_hov_color_left:
          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderColorLeft,
        right_socialTitle_border_hov_color_left_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderColorLeftNotEncoded
        ),
        right_socialTitle_border_hov_color_right:
          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderColorRight,
        right_socialTitle_border_hov_color_right_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderColorRightNotEncoded
        ),
        right_socialTitle_border_hov_color_top:
          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderColorTop,
        right_socialTitle_border_hov_color_top_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderColorTopNotEncoded
        ),
        right_socialTitle_border_hov_color_bottom:
          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderColorBottom,
        right_socialTitle_border_hov_color_bottom_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderColorBottomNotEncoded
        ),
        right_socialTitle_border_hov_pixel_left:
          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderLeft,
        right_socialTitle_border_hov_pixel_right:
          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderRight,
        right_socialTitle_border_hov_pixel_top:
          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderTop,
        right_socialTitle_border_hov_pixel_bottom:
          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderBottom,
        right_socialTitle_border_hov_radius_top_left:
          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderTopLeftRadius,
        right_socialTitle_border_hov_radius_top_right:
          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderTopRightRadius,
        right_socialTitle_border_hov_radius_bottom_left:
          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderBottomLeftRadius,
        right_socialTitle_border_hov_radius_bottom_right:
          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderBottomRightRadius,
        right_socialTitle_border_hov_style_left:
          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderStyleLeft,
        right_socialTitle_border_hov_style_right:
          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderStyleRight,
        right_socialTitle_border_hov_style_top:
          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderStyleTop,
        right_socialTitle_border_hov_style_bottom:
          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderStyleBottom,

        right_socialItem_schriftfarbe:
          DB_LeftSidebar_MenuItem_SocialText_FontColor,
        right_socialItem_schriftfarbe_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_SocialText_FontColorNotEncoded
        ),
        right_socialItem_hintergrundfarbe:
          DB_LeftSidebar_MenuItem_SocialText_BackgroundColor,
        right_socialItem_hintergrundfarbe_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_SocialText_BackgroundColorNotEncoded
        ),
        right_socialItem_schattenfarbe:
          DB_LeftSidebar_MenuItem_SocialText_ShadowColor,
        right_socialItem_schattenfarbe_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_SocialText_ShadowColorNotEncoded
        ),
        right_socialItem_schattenposition_horizontal:
          DB_LeftSidebar_MenuItem_SocialText_ShadowHorizontal,
        right_socialItem_schattenposition_vertikal:
          DB_LeftSidebar_MenuItem_SocialText_ShadowVertical,
        right_socialItem_schattenrauschen:
          DB_LeftSidebar_MenuItem_SocialText_ShadowBlur,
        right_socialItem_schriftgroesse:
          DB_LeftSidebar_MenuItem_SocialText_FontSize,
        right_socialItem_schriftgewicht:
          DB_LeftSidebar_MenuItem_SocialText_FontWeight,
        right_socialItem_buchstabenabstand:
          DB_LeftSidebar_MenuItem_SocialText_letterSpacing,
        right_socialItem_text_dekoration:
          DB_LeftSidebar_MenuItem_SocialText_TextDecoration,
        right_socialItem_text_dekoration_stil:
          DB_LeftSidebar_MenuItem_SocialText_TextDecorationStyle,
        right_socialItem_aussen_abstand_links_rechts:
          DB_LeftSidebar_MenuItem_SocialText_marginLeftRight,
        right_socialItem_aussen_abstand_oben_unten:
          DB_LeftSidebar_MenuItem_SocialText_marginTopBottom,
        right_socialItem_innerer_abstand_links_rechts:
          DB_LeftSidebar_MenuItem_SocialText_paddingLeftRight,
        right_socialItem_innerer_abstand_oben_unten:
          DB_LeftSidebar_MenuItem_SocialText_paddingTopBottom,
        right_socialItem_textausrichtung:
          DB_LeftSidebar_MenuItem_SocialText_TextAlign,
        right_socialItem_fontfamily:
          DB_fontFace_SocialTextFontFamily_FontFamilyName,
        right_socialItem_fontfamilysrc:
          DB_fontFace_SocialTextFontFamily_FontFamilySrc,

        right_socialItem_hov_schriftfarbe:
          DB_LeftSidebar_MenuItem_SocialText_Hover_FontColor,
        right_socialItem_hov_schriftfarbe_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_SocialText_Hover_FontColorNotEncoded
        ),
        right_socialItem_hov_hintergrundfarbe:
          DB_LeftSidebar_MenuItem_SocialText_Hover_BackgroundColor,
        right_socialItem_hov_hintergrundfarbe_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_SocialText_Hover_BackgroundColorNotEncoded
        ),
        right_socialItem_hov_schattenfarbe:
          DB_LeftSidebar_MenuItem_SocialText_ShadowColor_Hover,
        right_socialItem_hov_schattenfarbe_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_SocialText_ShadowColorNotEncoded_Hover
        ),
        right_socialItem_hov_schattenposition_horizontal:
          DB_LeftSidebar_MenuItem_SocialText_ShadowHorizontal_Hover,
        right_socialItem_hov_schattenposition_vertikal:
          DB_LeftSidebar_MenuItem_SocialText_ShadowVertical_Hover,
        right_socialItem_hov_schattenrauschen:
          DB_LeftSidebar_MenuItem_SocialText_ShadowBlur_Hover,
        right_socialItem_hov_schriftgroesse:
          DB_LeftSidebar_MenuItem_SocialText_Hover_FontSize,
        right_socialItem_hov_schriftgewicht:
          DB_LeftSidebar_MenuItem_SocialText_Hover_FontWeight,
        right_socialItem_hov_buchstabenabstand:
          DB_LeftSidebar_MenuItem_SocialText_letterSpacing_Hover,
        right_socialItem_hov_text_dekoration:
          DB_LeftSidebar_MenuItem_SocialText_TextDecoration_Hover,
        right_socialItem_hov_text_dekoration_stil:
          DB_LeftSidebar_MenuItem_SocialText_TextDecorationStyle_Hover,
        right_socialItem_hov_aussen_abstand_links_rechts:
          DB_LeftSidebar_MenuItem_SocialText_marginLeftRight_Hover,
        right_socialItem_hov_aussen_abstand_oben_unten:
          DB_LeftSidebar_MenuItem_SocialText_marginTopBottom_Hover,
        right_socialItem_hov_innerer_abstand_links_rechts:
          DB_LeftSidebar_MenuItem_SocialText_paddingLeftRight_Hover,
        right_socialItem_hov_innerer_abstand_oben_unten:
          DB_LeftSidebar_MenuItem_SocialText_paddingTopBottom_Hover,
        right_socialItem_hov_textausrichtung:
          DB_LeftSidebar_MenuItem_SocialText_Hover_TextAlign,

        right_socialItem_border_color_left:
          DB_LeftSidebar_MenuItem_SocialText_BorderColorLeft,
        right_socialItem_border_color_left_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_SocialText_BorderColorLeftNotEncoded
        ),
        right_socialItem_border_color_right:
          DB_LeftSidebar_MenuItem_SocialText_BorderColorRight,
        right_socialItem_border_color_right_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_SocialText_BorderColorRightNotEncoded
        ),
        right_socialItem_border_color_top:
          DB_LeftSidebar_MenuItem_SocialText_BorderColorTop,
        right_socialItem_border_color_top_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_SocialText_BorderColorTopNotEncoded
        ),
        right_socialItem_border_color_bottom:
          DB_LeftSidebar_MenuItem_SocialText_BorderColorBottom,
        right_socialItem_border_color_bottom_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_SocialText_BorderColorBottomNotEncoded
        ),
        right_socialItem_border_pixel_left:
          DB_LeftSidebar_MenuItem_SocialText_BorderLeft,
        right_socialItem_border_pixel_right:
          DB_LeftSidebar_MenuItem_SocialText_BorderRight,
        right_socialItem_border_pixel_top:
          DB_LeftSidebar_MenuItem_SocialText_BorderTop,
        right_socialItem_border_pixel_bottom:
          DB_LeftSidebar_MenuItem_SocialText_BorderBottom,
        right_socialItem_border_radius_top_left:
          DB_LeftSidebar_MenuItem_SocialText_BorderTopLeftRadius,
        right_socialItem_border_radius_top_right:
          DB_LeftSidebar_MenuItem_SocialText_BorderTopRightRadius,
        right_socialItem_border_radius_bottom_left:
          DB_LeftSidebar_MenuItem_SocialText_BorderBottomLeftRadius,
        right_socialItem_border_radius_bottom_right:
          DB_LeftSidebar_MenuItem_SocialText_BorderBottomRightRadius,
        right_socialItem_border_style_left:
          DB_LeftSidebar_MenuItem_SocialText_BorderStyleLeft,
        right_socialItem_border_style_right:
          DB_LeftSidebar_MenuItem_SocialText_BorderStyleRight,
        right_socialItem_border_style_top:
          DB_LeftSidebar_MenuItem_SocialText_BorderStyleTop,
        right_socialItem_border_style_bottom:
          DB_LeftSidebar_MenuItem_SocialText_BorderStyleBottom,

        right_socialItem_border_hov_color_left:
          DB_LeftSidebar_MenuItem_SocialText_Hover_BorderColorLeft,
        right_socialItem_border_hov_color_left_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_SocialText_Hover_BorderColorLeftNotEncoded
        ),
        right_socialItem_border_hov_color_right:
          DB_LeftSidebar_MenuItem_SocialText_Hover_BorderColorRight,
        right_socialItem_border_hov_color_right_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_SocialText_Hover_BorderColorRightNotEncoded
        ),
        right_socialItem_border_hov_color_top:
          DB_LeftSidebar_MenuItem_SocialText_Hover_BorderColorTop,
        right_socialItem_border_hov_color_top_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_SocialText_Hover_BorderColorTopNotEncoded
        ),
        right_socialItem_border_hov_color_bottom:
          DB_LeftSidebar_MenuItem_SocialText_Hover_BorderColorBottom,
        right_socialItem_border_hov_color_bottom_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_SocialText_Hover_BorderColorBottomNotEncoded
        ),
        right_socialItem_border_hov_pixel_left:
          DB_LeftSidebar_MenuItem_SocialText_Hover_BorderLeft,
        right_socialItem_border_hov_pixel_right:
          DB_LeftSidebar_MenuItem_SocialText_Hover_BorderRight,
        right_socialItem_border_hov_pixel_top:
          DB_LeftSidebar_MenuItem_SocialText_Hover_BorderTop,
        right_socialItem_border_hov_pixel_bottom:
          DB_LeftSidebar_MenuItem_SocialText_Hover_BorderBottom,
        right_socialItem_border_hov_radius_top_left:
          DB_LeftSidebar_MenuItem_SocialText_Hover_BorderTopLeftRadius,
        right_socialItem_border_hov_radius_top_right:
          DB_LeftSidebar_MenuItem_SocialText_Hover_BorderTopRightRadius,
        right_socialItem_border_hov_radius_bottom_left:
          DB_LeftSidebar_MenuItem_SocialText_Hover_BorderBottomLeftRadius,
        right_socialItem_border_hov_radius_bottom_right:
          DB_LeftSidebar_MenuItem_SocialText_Hover_BorderBottomRightRadius,
        right_socialItem_border_hov_style_left:
          DB_LeftSidebar_MenuItem_SocialText_Hover_BorderStyleLeft,
        right_socialItem_border_hov_style_right:
          DB_LeftSidebar_MenuItem_SocialText_Hover_BorderStyleRight,
        right_socialItem_border_hov_style_top:
          DB_LeftSidebar_MenuItem_SocialText_Hover_BorderStyleTop,
        right_socialItem_border_hov_style_bottom:
          DB_LeftSidebar_MenuItem_SocialText_Hover_BorderStyleBottom,
      };

      const design_rightside_filter = {
        right_filter_hintergrundfarbe:
          DB_LeftSidebar_MenuContainerFilterGG_BackgroundColor,
        right_filter_hintergrundfarbe_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuContainerFilterGG_BackgroundColorNotEncoded
        ),
        right_filter_schattenfarbe:
          DB_LeftSidebar_MenuItem_MenuContainerFilterGG_ShadowColor,
        right_filter_schattenfarbe_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_MenuContainerFilterGG_ShadowColorNotEncoded
        ),
        right_filter_schattenposition_horizontal:
          DB_LeftSidebar_MenuItem_MenuContainerFilterGG_ShadowHorizontal,
        right_filter_schattenposition_vertikal:
          DB_LeftSidebar_MenuItem_MenuContainerFilterGG_ShadowVertical,
        right_filter_schattenrauschen:
          DB_LeftSidebar_MenuItem_MenuContainerFilterGG_ShadowBlur,
        right_filter_innerer_abstand_links:
          DB_LeftSidebar_MenuItem_MenuContainerFilterGG_paddingLeft,
        right_filter_innerer_abstand_rechts:
          DB_LeftSidebar_MenuItem_MenuContainerFilterGG_paddingRight,
        right_filter_innerer_abstand_oben:
          DB_LeftSidebar_MenuItem_MenuContainerFilterGG_paddingTop,
        right_filter_innerer_abstand_unten:
          DB_LeftSidebar_MenuItem_MenuContainerFilterGG_paddingBottom,

        right_filter_hover_hintergrundfarbe:
          DB_LeftSidebar_MenuContainerFilterGG_Hover_BackgroundColor,
        right_filter_hover_hintergrundfarbe_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuContainerFilterGG_Hover_BackgroundColorNotEncoded
        ),
        right_filter_hover_schattenfarbe:
          DB_LeftSidebar_MenuItem_MenuContainerFilterGG_Hover_ShadowColor,
        right_filter_hover_schattenfarbe_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_MenuContainerFilterGG_Hover_ShadowColorNotEncoded
        ),
        right_filter_hover_schattenposition_horizontal:
          DB_LeftSidebar_MenuItem_MenuContainerFilterGG_Hover_ShadowHorizontal,
        right_filter_hover_schattenposition_vertikal:
          DB_LeftSidebar_MenuItem_MenuContainerFilterGG_Hover_ShadowVertical,
        right_filter_hover_schattenrauschen:
          DB_LeftSidebar_MenuItem_MenuContainerFilterGG_Hover_ShadowBlur,
        right_filter_hover_innerer_abstand_links:
          DB_LeftSidebar_MenuItem_MenuContainerFilterGG_Hover_paddingLeft,
        right_filter_hover_innerer_abstand_rechts:
          DB_LeftSidebar_MenuItem_MenuContainerFilterGG_Hover_paddingRight,
        right_filter_hover_innerer_abstand_oben:
          DB_LeftSidebar_MenuItem_MenuContainerFilterGG_Hover_paddingTop,
        right_filter_hover_innerer_abstand_unten:
          DB_LeftSidebar_MenuItem_MenuContainerFilterGG_Hover_paddingBottom,

        right_filter_border_color_left:
          DB_LeftSidebar_MenuContainerFilterGG_BorderColorLeft,
        right_filter_border_color_left_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuContainerFilterGG_BorderColorLeftNotEncoded
        ),
        right_filter_border_color_right:
          DB_LeftSidebar_MenuContainerFilterGG_BorderColorRight,
        right_filter_border_color_right_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuContainerFilterGG_BorderColorRightNotEncoded
        ),
        right_filter_border_color_top:
          DB_LeftSidebar_MenuContainerFilterGG_BorderColorTop,
        right_filter_border_color_top_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuContainerFilterGG_BorderColorTopNotEncoded
        ),
        right_filter_border_color_bottom:
          DB_LeftSidebar_MenuContainerFilterGG_BorderColorBottom,
        right_filter_border_color_bottom_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuContainerFilterGG_BorderColorBottomNotEncoded
        ),
        right_filter_border_pixel_left:
          DB_LeftSidebar_MenuContainerFilterGG_BorderLeft,
        right_filter_border_pixel_right:
          DB_LeftSidebar_MenuContainerFilterGG_BorderRight,
        right_filter_border_pixel_top:
          DB_LeftSidebar_MenuContainerFilterGG_BorderTop,
        right_filter_border_pixel_bottom:
          DB_LeftSidebar_MenuContainerFilterGG_BorderBottom,
        right_filter_border_radius_top_left:
          DB_LeftSidebar_MenuItem_MenuContainerFilterGG_BorderTopLeftRadius,
        right_filter_border_radius_top_right:
          DB_LeftSidebar_MenuItem_MenuContainerFilterGG_BorderTopRightRadius,
        right_filter_border_radius_bottom_left:
          DB_LeftSidebar_MenuItem_MenuContainerFilterGG_BorderBottomLeftRadius,
        right_filter_border_radius_bottom_right:
          DB_LeftSidebar_MenuItem_MenuContainerFilterGG_BorderBottomRightRadius,
        right_filter_border_style_left:
          DB_LeftSidebar_MenuContainerFilterGG_BorderStyleLeft,
        right_filter_border_style_right:
          DB_LeftSidebar_MenuContainerFilterGG_BorderStyleRight,
        right_filter_border_style_top:
          DB_LeftSidebar_MenuContainerFilterGG_BorderStyleTop,
        right_filter_border_style_bottom:
          DB_LeftSidebar_MenuContainerFilterGG_BorderStyleBottom,

        right_filter_border_hov_color_left:
          DB_LeftSidebar_MenuContainerFilterGG_Hover_BorderColorLeft,
        right_filter_border_hov_color_left_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuContainerFilterGG_Hover_BorderColorLeftNotEncoded
        ),
        right_filter_border_hov_color_right:
          DB_LeftSidebar_MenuContainerFilterGG_Hover_BorderColorRight,
        right_filter_border_hov_color_right_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuContainerFilterGG_Hover_BorderColorRightNotEncoded
        ),
        right_filter_border_hov_color_top:
          DB_LeftSidebar_MenuContainerFilterGG_Hover_BorderColorTop,
        right_filter_border_hov_color_top_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuContainerFilterGG_Hover_BorderColorTopNotEncoded
        ),
        right_filter_border_hov_color_bottom:
          DB_LeftSidebar_MenuContainerFilterGG_Hover_BorderColorBottom,
        right_filter_border_hov_color_bottom_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuContainerFilterGG_Hover_BorderColorBottomNotEncoded
        ),
        right_filter_border_hov_pixel_left:
          DB_LeftSidebar_MenuContainerFilterGG_Hover_BorderLeft,
        right_filter_border_hov_pixel_right:
          DB_LeftSidebar_MenuContainerFilterGG_Hover_BorderRight,
        right_filter_border_hov_pixel_top:
          DB_LeftSidebar_MenuContainerFilterGG_Hover_BorderTop,
        right_filter_border_hov_pixel_bottom:
          DB_LeftSidebar_MenuContainerFilterGG_Hover_BorderBottom,
        right_filter_border_hov_radius_top_left:
          DB_LeftSidebar_MenuItem_MenuContainerFilterGG_Hover_BorderTopLeftRadius,
        right_filter_border_hov_radius_top_right:
          DB_LeftSidebar_MenuItem_MenuContainerFilterGG_Hover_BorderTopRightRadius,
        right_filter_border_hov_radius_bottom_left:
          DB_LeftSidebar_MenuItem_MenuContainerFilterGG_Hover_BorderBottomLeftRadius,
        right_filter_border_hov_radius_bottom_right:
          DB_LeftSidebar_MenuItem_MenuContainerFilterGG_Hover_BorderBottomRightRadius,
        right_filter_border_hov_style_left:
          DB_LeftSidebar_MenuContainerFilterGG_Hover_BorderStyleLeft,
        right_filter_border_hov_style_right:
          DB_LeftSidebar_MenuContainerFilterGG_Hover_BorderStyleRight,
        right_filter_border_hov_style_top:
          DB_LeftSidebar_MenuContainerFilterGG_Hover_BorderStyleTop,
        right_filter_border_hov_style_bottom:
          DB_LeftSidebar_MenuContainerFilterGG_Hover_BorderStyleBottom,

        right_nav_allFilter_schriftfarbe:
          DB_LeftSidebar_MenuItem_AlleFilterButtonFF_FontColor,
        right_nav_allFilter_schriftfarbe_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_AlleFilterButtonFF_FontColorNotEncoded
        ),
        right_nav_allFilter_hintergrundfarbe:
          DB_LeftSidebar_MenuItem_AlleFilterButtonFF_BackgroundColor,
        right_nav_allFilter_hintergrundfarbe_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_AlleFilterButtonFF_BackgroundColorNotEncoded
        ),
        right_nav_allFilter_schattenfarbe:
          DB_LeftSidebar_MenuItem_AlleFilterButtonFF_ShadowColor,
        right_nav_allFilter_schattenfarbe_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_AlleFilterButtonFF_ShadowColorNotEncoded
        ),
        right_nav_allFilter_schattenposition_horizontal:
          DB_LeftSidebar_MenuItem_AlleFilterButtonFF_ShadowHorizontal,
        right_nav_allFilter_schattenposition_vertikal:
          DB_LeftSidebar_MenuItem_AlleFilterButtonFF_ShadowVertical,
        right_nav_allFilter_schattenrauschen:
          DB_LeftSidebar_MenuItem_AlleFilterButtonFF_ShadowBlur,
        right_nav_allFilter_schriftgroesse:
          DB_LeftSidebar_MenuItem_AlleFilterButtonFF_FontSize,
        right_nav_allFilter_schriftgewicht:
          DB_LeftSidebar_MenuItem_AlleFilterButtonFF_FontWeight,
        right_nav_allFilter_buchstabenabstand:
          DB_LeftSidebar_MenuItem_AlleFilterButtonFF_letterSpacing,
        right_nav_allFilter_text_dekoration:
          DB_LeftSidebar_MenuItem_AlleFilterButtonFF_TextDecoration,
        right_nav_allFilter_text_dekoration_stil:
          DB_LeftSidebar_MenuItem_AlleFilterButtonFF_TextDecorationStyle,
        right_nav_allFilter_aussen_abstand_links_rechts:
          DB_LeftSidebar_MenuItem_AlleFilterButtonFF_marginLeftRight,
        right_nav_allFilter_aussen_abstand_oben_unten:
          DB_LeftSidebar_MenuItem_AlleFilterButtonFF_marginTopBottom,
        right_nav_allFilter_innerer_abstand_links_rechts:
          DB_LeftSidebar_MenuItem_AlleFilterButtonFF_paddingLeftRight,
        right_nav_allFilter_innerer_abstand_oben_unten:
          DB_LeftSidebar_MenuItem_AlleFilterButtonFF_paddingTopBottom,
        right_nav_allFilter_textausrichtung:
          DB_LeftSidebar_MenuItem_AlleFilterButtonFF_TextAlign,
        right_nav_allFilter_fontfamilyname:
          DB_fontFace_AlleFilterButtonFontFamily_FontFamilyName,
        right_nav_allFilter_fontfamilysrc:
          DB_fontFace_AlleFilterButtonFontFamily_FontFamilySrc,

        right_nav_allFilter_hov_schriftfarbe:
          DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_FontColor,

        right_nav_allFilter_hov_schriftfarbe_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_FontColorNotEncoded
        ),
        right_nav_allFilter_hov_hintergrundfarbe:
          DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BackgroundColor,

        right_nav_allFilter_hov_hintergrundfarbe_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BackgroundColorNotEncoded
        ),
        right_nav_allFilter_hov_schattenfarbe:
          DB_LeftSidebar_MenuItem_AlleFilterButtonFF_ShadowColor_Hover,

        right_nav_allFilter_hov_schattenfarbe_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_AlleFilterButtonFF_ShadowColorNotEncoded_Hover
        ),
        right_nav_allFilter_hov_schattenposition_horizontal:
          DB_LeftSidebar_MenuItem_AlleFilterButtonFF_ShadowHorizontal_Hover,

        right_nav_allFilter_hov_schattenposition_vertikal:
          DB_LeftSidebar_MenuItem_AlleFilterButtonFF_ShadowVertical_Hover,

        right_nav_allFilter_hov_schattenrauschen:
          DB_LeftSidebar_MenuItem_AlleFilterButtonFF_ShadowBlur_Hover,

        right_nav_allFilter_hov_schriftgroesse:
          DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_FontSize,

        right_nav_allFilter_hov_schriftgewicht:
          DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_FontWeight,

        right_nav_allFilter_hov_buchstabenabstand:
          DB_LeftSidebar_MenuItem_AlleFilterButtonFF_letterSpacing_Hover,

        right_nav_allFilter_hov_text_dekoration:
          DB_LeftSidebar_MenuItem_AlleFilterButtonFF_TextDecoration_Hover,

        right_nav_allFilter_hov_text_dekoration_stil:
          DB_LeftSidebar_MenuItem_AlleFilterButtonFF_TextDecorationStyle_Hover,

        right_nav_allFilter_hov_aussen_abstand_links_rechts:
          DB_LeftSidebar_MenuItem_AlleFilterButtonFF_marginLeftRight_Hover,

        right_nav_allFilter_hov_aussen_abstand_oben_unten:
          DB_LeftSidebar_MenuItem_AlleFilterButtonFF_marginTopBottom_Hover,

        right_nav_allFilter_hov_innerer_abstand_links_rechts:
          DB_LeftSidebar_MenuItem_AlleFilterButtonFF_paddingLeftRight_Hover,

        right_nav_allFilter_hov_innerer_abstand_oben_unten:
          DB_LeftSidebar_MenuItem_AlleFilterButtonFF_paddingTopBottom_Hover,

        right_nav_allFilter_hov_textausrichtung:
          DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_TextAlign,

        right_nav_allFilter_border_color_left:
          DB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderColorLeft,
        right_nav_allFilter_border_color_left_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderColorLeftNotEncoded
        ),

        right_nav_allFilter_border_color_right:
          DB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderColorRight,
        right_nav_allFilter_border_color_right_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderColorRightNotEncoded
        ),

        right_nav_allFilter_border_color_top:
          DB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderColorTop,
        right_nav_allFilter_border_color_top_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderColorTopNotEncoded
        ),

        right_nav_allFilter_border_color_bottom:
          DB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderColorBottom,
        right_nav_allFilter_border_color_bottom_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderColorBottomNotEncoded
        ),

        right_nav_allFilter_border_pixel_left:
          DB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderLeft,
        right_nav_allFilter_border_pixel_right:
          DB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderRight,
        right_nav_allFilter_border_pixel_top:
          DB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderTop,
        right_nav_allFilter_border_pixel_bottom:
          DB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderBottom,

        right_nav_allFilter_border_radius_top_left:
          DB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderTopLeftRadius,
        right_nav_allFilter_border_radius_top_right:
          DB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderTopRightRadius,
        right_nav_allFilter_border_radius_bottom_left:
          DB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderBottomLeftRadius,
        right_nav_allFilter_border_radius_bottom_right:
          DB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderBottomRightRadius,

        right_nav_allFilter_border_style_left:
          DB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderStyleLeft,
        right_nav_allFilter_border_style_right:
          DB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderStyleRight,
        right_nav_allFilter_border_style_top:
          DB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderStyleTop,
        right_nav_allFilter_border_style_bottom:
          DB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderStyleBottom,

        right_nav_allFilter_hov_border_color_left:
          DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderColorLeft,

        right_nav_allFilter_hov_border_color_left_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderColorLeftNotEncoded
        ),

        right_nav_allFilter_hov_border_color_right:
          DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderColorRight,
        right_nav_allFilter_hov_border_color_right_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderColorRightNotEncoded
        ),

        right_nav_allFilter_hov_border_color_top:
          DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderColorTop,
        right_nav_allFilter_hov_border_color_top_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderColorTopNotEncoded
        ),

        right_nav_allFilter_hov_border_color_bottom:
          DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderColorBottom,
        right_nav_allFilter_hov_border_color_bottom_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderColorBottomNotEncoded
        ),

        right_nav_allFilter_hov_border_pixel_left:
          DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderLeft,

        right_nav_allFilter_hov_border_pixel_right:
          DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderRight,

        right_nav_allFilter_hov_border_pixel_top:
          DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderTop,

        right_nav_allFilter_hov_border_pixel_bottom:
          DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderBottom,

        right_nav_allFilter_hov_border_radius_top_left:
          DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderTopLeftRadius,

        right_nav_allFilter_hov_border_radius_top_right:
          DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderTopRightRadius,

        right_nav_allFilter_hov_border_radius_bottom_left:
          DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderBottomLeftRadius,

        right_nav_allFilter_hov_border_radius_bottom_right:
          DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderBottomRightRadius,

        right_nav_allFilter_hov_border_style_left:
          DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderStyleLeft,

        right_nav_allFilter_hov_border_style_right:
          DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderStyleRight,

        right_nav_allFilter_hov_border_style_top:
          DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderStyleTop,

        right_nav_allFilter_hov_border_style_bottom:
          DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderStyleBottom,
      };

      const design_rightside_filter_title_item = {
        right_filtTitle_schriftfarbe:
          DB_LeftSidebar_MenuItem_FilterHauptTitel_FontColor,
        right_filtTitle_schriftfarbe_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_FilterHauptTitel_FontColorNotEncoded
        ),
        right_filtTitle_hintergrundfarbe:
          DB_LeftSidebar_MenuItem_FilterHauptTitel_BackgroundColor,
        right_filtTitle_hintergrundfarbe_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_FilterHauptTitel_BackgroundColorNotEncoded
        ),
        right_filtTitle_schattenfarbe:
          DB_LeftSidebar_MenuItem_FilterHauptTitel_ShadowColor,
        right_filtTitle_schattenfarbe_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_FilterHauptTitel_ShadowColorNotEncoded
        ),
        right_filtTitle_schattenposition_horizontal:
          DB_LeftSidebar_MenuItem_FilterHauptTitel_ShadowHorizontal,
        right_filtTitle_schattenposition_vertikal:
          DB_LeftSidebar_MenuItem_FilterHauptTitel_ShadowVertical,
        right_filtTitle_schattenrauschen:
          DB_LeftSidebar_MenuItem_FilterHauptTitel_ShadowBlur,
        right_filtTitle_schriftgroesse:
          DB_LeftSidebar_MenuItem_FilterHauptTitel_FontSize,
        right_filtTitle_schriftgewicht:
          DB_LeftSidebar_MenuItem_FilterHauptTitel_FontWeight,
        right_filtTitle_buchstabenabstand:
          DB_LeftSidebar_MenuItem_FilterHauptTitel_letterSpacing,
        right_filtTitle_text_dekoration:
          DB_LeftSidebar_MenuItem_FilterHauptTitel_TextDecoration,
        right_filtTitle_text_dekoration_stil:
          DB_LeftSidebar_MenuItem_FilterHauptTitel_TextDecorationStyle,
        right_filtTitle_aussen_abstand_links_rechts:
          DB_LeftSidebar_MenuItem_FilterHauptTitel_marginLeftRight,
        right_filtTitle_aussen_abstand_oben_unten:
          DB_LeftSidebar_MenuItem_FilterHauptTitel_marginTopBottom,
        right_filtTitle_innerer_abstand_links_rechts:
          DB_LeftSidebar_MenuItem_FilterHauptTitel_paddingLeftRight,
        right_filtTitle_innerer_abstand_oben_unten:
          DB_LeftSidebar_MenuItem_FilterHauptTitel_paddingTopBottom,
        right_filtTitle_textausrichtung:
          DB_LeftSidebar_MenuItem_FilterHauptTitel_TextAlign,
        right_filtTitle_fontfamily: DB_fontFace_FilterHauptTitel_FontFamilyName,
        right_filtTitle_fontfamilysrc:
          DB_fontFace_FilterHauptTitel_FontFamilySrc,

        right_filtTitle_hov_schriftfarbe:
          DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_FontColor,
        right_filtTitle_hov_schriftfarbe_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_FontColorNotEncoded
        ),
        right_filtTitle_hov_hintergrundfarbe:
          DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BackgroundColor,
        right_filtTitle_hov_hintergrundfarbe_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BackgroundColorNotEncoded
        ),
        right_filtTitle_hov_schattenfarbe:
          DB_LeftSidebar_MenuItem_FilterHauptTitel_ShadowColor_Hover,
        right_filtTitle_hov_schattenfarbe_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_FilterHauptTitel_ShadowColorNotEncoded_Hover
        ),
        right_filtTitle_hov_schattenposition_horizontal:
          DB_LeftSidebar_MenuItem_FilterHauptTitel_ShadowHorizontal_Hover,
        right_filtTitle_hov_schattenposition_vertikal:
          DB_LeftSidebar_MenuItem_FilterHauptTitel_ShadowVertical_Hover,
        right_filtTitle_hov_schattenrauschen:
          DB_LeftSidebar_MenuItem_FilterHauptTitel_ShadowBlur_Hover,
        right_filtTitle_hov_schriftgroesse:
          DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_FontSize,
        right_filtTitle_hov_schriftgewicht:
          DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_FontWeight,
        right_filtTitle_hov_buchstabenabstand:
          DB_LeftSidebar_MenuItem_FilterHauptTitel_letterSpacing_Hover,
        right_filtTitle_hov_text_dekoration:
          DB_LeftSidebar_MenuItem_FilterHauptTitel_TextDecoration_Hover,
        right_filtTitle_hov_text_dekoration_stil:
          DB_LeftSidebar_MenuItem_FilterHauptTitel_TextDecorationStyle_Hover,
        right_filtTitle_hov_aussen_abstand_links_rechts:
          DB_LeftSidebar_MenuItem_FilterHauptTitel_marginLeftRight_Hover,
        right_filtTitle_hov_aussen_abstand_oben_unten:
          DB_LeftSidebar_MenuItem_FilterHauptTitel_marginTopBottom_Hover,
        right_filtTitle_hov_innerer_abstand_links_rechts:
          DB_LeftSidebar_MenuItem_FilterHauptTitel_paddingLeftRight_Hover,
        right_filtTitle_hov_innerer_abstand_oben_unten:
          DB_LeftSidebar_MenuItem_FilterHauptTitel_paddingTopBottom_Hover,
        right_filtTitle_hov_textausrichtung:
          DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_TextAlign,

        right_filtTitle_border_color_left:
          DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderColorLeft,
        right_filtTitle_border_color_left_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderColorLeftNotEncoded
        ),
        right_filtTitle_border_color_right:
          DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderColorRight,
        right_filtTitle_border_color_right_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderColorRightNotEncoded
        ),
        right_filtTitle_border_color_top:
          DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderColorTop,
        right_filtTitle_border_color_top_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderColorTopNotEncoded
        ),
        right_filtTitle_border_color_bottom:
          DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderColorBottom,
        right_filtTitle_border_color_bottom_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderColorBottomNotEncoded
        ),
        right_filtTitle_border_pixel_left:
          DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderLeft,
        right_filtTitle_border_pixel_right:
          DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderRight,
        right_filtTitle_border_pixel_top:
          DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderTop,
        right_filtTitle_border_pixel_bottom:
          DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderBottom,
        right_filtTitle_border_radius_top_left:
          DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderTopLeftRadius,
        right_filtTitle_border_radius_top_right:
          DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderTopRightRadius,
        right_filtTitle_border_radius_bottom_left:
          DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderBottomLeftRadius,
        right_filtTitle_border_radius_bottom_right:
          DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderBottomRightRadius,
        right_filtTitle_border_style_left:
          DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderStyleLeft,
        right_filtTitle_border_style_right:
          DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderStyleRight,
        right_filtTitle_border_style_top:
          DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderStyleTop,
        right_filtTitle_border_style_bottom:
          DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderStyleBottom,

        right_filtTitle_border_hov_color_left:
          DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderColorLeft,
        right_filtTitle_border_hov_color_left_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderColorLeftNotEncoded
        ),
        right_filtTitle_border_hov_color_right:
          DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderColorRight,
        right_filtTitle_border_hov_color_right_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderColorRightNotEncoded
        ),
        right_filtTitle_border_hov_color_top:
          DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderColorTop,
        right_filtTitle_border_hov_color_top_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderColorTopNotEncoded
        ),
        right_filtTitle_border_hov_color_bottom:
          DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderColorBottom,
        right_filtTitle_border_hov_color_bottom_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderColorBottomNotEncoded
        ),
        right_filtTitle_border_hov_pixel_left:
          DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderLeft,
        right_filtTitle_border_hov_pixel_right:
          DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderRight,
        right_filtTitle_border_hov_pixel_top:
          DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderTop,
        right_filtTitle_border_hov_pixel_bottom:
          DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderBottom,
        right_filtTitle_border_hov_radius_top_left:
          DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderTopLeftRadius,
        right_filtTitle_border_hov_radius_top_right:
          DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderTopRightRadius,
        right_filtTitle_border_hov_radius_bottom_left:
          DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderBottomLeftRadius,
        right_filtTitle_border_hov_radius_bottom_right:
          DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderBottomRightRadius,
        right_filtTitle_border_hov_style_left:
          DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderStyleLeft,
        right_filtTitle_border_hov_style_right:
          DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderStyleRight,
        right_filtTitle_border_hov_style_top:
          DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderStyleTop,
        right_filtTitle_border_hov_style_bottom:
          DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderStyleBottom,

        right_filtItem_schriftfarbe:
          DB_LeftSidebar_MenuItem_FilterItem_FontColor,
        right_filtItem_schriftfarbe_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_FilterItem_FontColorNotEncoded
        ),
        right_filtItem_hintergrundfarbe:
          DB_LeftSidebar_MenuItem_FilterItem_BackgroundColor,
        right_filtItem_hintergrundfarbe_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_FilterItem_BackgroundColorNotEncoded
        ),
        right_filtItem_schattenfarbe:
          DB_LeftSidebar_MenuItem_FilterItem_ShadowColor,
        right_filtItem_schattenfarbe_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_FilterItem_ShadowColorNotEncoded
        ),
        right_filtItem_schattenposition_horizontal:
          DB_LeftSidebar_MenuItem_FilterItem_ShadowHorizontal,
        right_filtItem_schattenposition_vertikal:
          DB_LeftSidebar_MenuItem_FilterItem_ShadowVertical,
        right_filtItem_schattenrauschen:
          DB_LeftSidebar_MenuItem_FilterItem_ShadowBlur,
        right_filtItem_schriftgroesse:
          DB_LeftSidebar_MenuItem_FilterItem_FontSize,
        right_filtItem_schriftgewicht:
          DB_LeftSidebar_MenuItem_FilterItem_FontWeight,
        right_filtItem_buchstabenabstand:
          DB_LeftSidebar_MenuItem_FilterItem_letterSpacing,
        right_filtItem_text_dekoration:
          DB_LeftSidebar_MenuItem_FilterItem_TextDecoration,
        right_filtItem_text_dekoration_stil:
          DB_LeftSidebar_MenuItem_FilterItem_TextDecorationStyle,
        right_filtItem_aussen_abstand_links_rechts:
          DB_LeftSidebar_MenuItem_FilterItem_marginLeftRight,
        right_filtItem_aussen_abstand_oben_unten:
          DB_LeftSidebar_MenuItem_FilterItem_marginTopBottom,
        right_filtItem_innerer_abstand_links_rechts:
          DB_LeftSidebar_MenuItem_FilterItem_paddingLeftRight,
        right_filtItem_innerer_abstand_oben_unten:
          DB_LeftSidebar_MenuItem_FilterItem_paddingTopBottom,
        right_filtItem_textausrichtung:
          DB_LeftSidebar_MenuItem_FilterItem_TextAlign,
        right_filtItem_fontfamily:
          DB_fontFace_FilterItemFontFamily_FontFamilyName,
        right_filtItem_fontfamilysrc:
          DB_fontFace_FilterItemFontFamily_FontFamilySrc,

        right_filtItem_hov_schriftfarbe:
          DB_LeftSidebar_MenuItem_FilterItem_Hover_FontColor,
        right_filtItem_hov_schriftfarbe_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_FilterItem_Hover_FontColorNotEncoded
        ),
        right_filtItem_hov_hintergrundfarbe:
          DB_LeftSidebar_MenuItem_FilterItem_Hover_BackgroundColor,
        right_filtItem_hov_hintergrundfarbe_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_FilterItem_Hover_BackgroundColorNotEncoded
        ),
        right_filtItem_hov_schattenfarbe:
          DB_LeftSidebar_MenuItem_FilterItem_ShadowColor_Hover,
        right_filtItem_hov_schattenfarbe_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_FilterItem_ShadowColorNotEncoded_Hover
        ),
        right_filtItem_hov_schattenposition_horizontal:
          DB_LeftSidebar_MenuItem_FilterItem_ShadowHorizontal_Hover,
        right_filtItem_hov_schattenposition_vertikal:
          DB_LeftSidebar_MenuItem_FilterItem_ShadowVertical_Hover,
        right_filtItem_hov_schattenrauschen:
          DB_LeftSidebar_MenuItem_FilterItem_ShadowBlur_Hover,
        right_filtItem_hov_schriftgroesse:
          DB_LeftSidebar_MenuItem_FilterItem_Hover_FontSize,
        right_filtItem_hov_schriftgewicht:
          DB_LeftSidebar_MenuItem_FilterItem_Hover_FontWeight,
        right_filtItem_hov_buchstabenabstand:
          DB_LeftSidebar_MenuItem_FilterItem_letterSpacing_Hover,
        right_filtItem_hov_text_dekoration:
          DB_LeftSidebar_MenuItem_FilterItem_TextDecoration_Hover,
        right_filtItem_hov_text_dekoration_stil:
          DB_LeftSidebar_MenuItem_FilterItem_TextDecorationStyle_Hover,
        right_filtItem_hov_aussen_abstand_links_rechts:
          DB_LeftSidebar_MenuItem_FilterItem_marginLeftRight_Hover,
        right_filtItem_hov_aussen_abstand_oben_unten:
          DB_LeftSidebar_MenuItem_FilterItem_marginTopBottom_Hover,
        right_filtItem_hov_innerer_abstand_links_rechts:
          DB_LeftSidebar_MenuItem_FilterItem_paddingLeftRight_Hover,
        right_filtItem_hov_innerer_abstand_oben_unten:
          DB_LeftSidebar_MenuItem_FilterItem_paddingTopBottom_Hover,
        right_filtItem_hov_textausrichtung:
          DB_LeftSidebar_MenuItem_FilterItem_Hover_TextAlign,

        right_filtItem_border_color_left:
          DB_LeftSidebar_MenuItem_FilterItem_BorderColorLeft,
        right_filtItem_border_color_left_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_FilterItem_BorderColorLeftNotEncoded
        ),
        right_filtItem_border_color_right:
          DB_LeftSidebar_MenuItem_FilterItem_BorderColorRight,
        right_filtItem_border_color_right_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_FilterItem_BorderColorRightNotEncoded
        ),
        right_filtItem_border_color_top:
          DB_LeftSidebar_MenuItem_FilterItem_BorderColorTop,
        right_filtItem_border_color_top_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_FilterItem_BorderColorTopNotEncoded
        ),
        right_filtItem_border_color_bottom:
          DB_LeftSidebar_MenuItem_FilterItem_BorderColorBottom,
        right_filtItem_border_color_bottom_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_FilterItem_BorderColorBottomNotEncoded
        ),
        right_filtItem_border_pixel_left:
          DB_LeftSidebar_MenuItem_FilterItem_BorderLeft,
        right_filtItem_border_pixel_right:
          DB_LeftSidebar_MenuItem_FilterItem_BorderRight,
        right_filtItem_border_pixel_top:
          DB_LeftSidebar_MenuItem_FilterItem_BorderTop,
        right_filtItem_border_pixel_bottom:
          DB_LeftSidebar_MenuItem_FilterItem_BorderBottom,
        right_filtItem_border_radius_top_left:
          DB_LeftSidebar_MenuItem_FilterItem_BorderTopLeftRadius,
        right_filtItem_border_radius_top_right:
          DB_LeftSidebar_MenuItem_FilterItem_BorderTopRightRadius,
        right_filtItem_border_radius_bottom_left:
          DB_LeftSidebar_MenuItem_FilterItem_BorderBottomLeftRadius,
        right_filtItem_border_radius_bottom_right:
          DB_LeftSidebar_MenuItem_FilterItem_BorderBottomRightRadius,
        right_filtItem_border_style_left:
          DB_LeftSidebar_MenuItem_FilterItem_BorderStyleLeft,
        right_filtItem_border_style_right:
          DB_LeftSidebar_MenuItem_FilterItem_BorderStyleRight,
        right_filtItem_border_style_top:
          DB_LeftSidebar_MenuItem_FilterItem_BorderStyleTop,
        right_filtItem_border_style_bottom:
          DB_LeftSidebar_MenuItem_FilterItem_BorderStyleBottom,

        right_filtItem_border_hov_color_left:
          DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderColorLeft,
        right_filtItem_border_hov_color_left_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderColorLeftNotEncoded
        ),
        right_filtItem_border_hov_color_right:
          DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderColorRight,
        right_filtItem_border_hov_color_right_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderColorRightNotEncoded
        ),
        right_filtItem_border_hov_color_top:
          DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderColorTop,
        right_filtItem_border_hov_color_top_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderColorTopNotEncoded
        ),
        right_filtItem_border_hov_color_bottom:
          DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderColorBottom,
        right_filtItem_border_hov_color_bottom_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderColorBottomNotEncoded
        ),
        right_filtItem_border_hov_pixel_left:
          DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderLeft,
        right_filtItem_border_hov_pixel_right:
          DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderRight,
        right_filtItem_border_hov_pixel_top:
          DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderTop,
        right_filtItem_border_hov_pixel_bottom:
          DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderBottom,
        right_filtItem_border_hov_radius_top_left:
          DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderTopLeftRadius,
        right_filtItem_border_hov_radius_top_right:
          DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderTopRightRadius,
        right_filtItem_border_hov_radius_bottom_left:
          DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderBottomLeftRadius,
        right_filtItem_border_hov_radius_bottom_right:
          DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderBottomRightRadius,
        right_filtItem_border_hov_style_left:
          DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderStyleLeft,
        right_filtItem_border_hov_style_right:
          DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderStyleRight,
        right_filtItem_border_hov_style_top:
          DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderStyleTop,
        right_filtItem_border_hov_style_bottom:
          DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderStyleBottom,
      };

      const design_sortierung = {
        waehrung: DB_Waehrung_Global,
        sortierung_schriftfarbe:
          DB_LeftSidebar_MenuItem_SortierungTop_FontColor,
        sortierung_schriftfarbe_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_SortierungTop_FontColorNotEncoded
        ),
        sortierung_hintergrundfarbe:
          DB_LeftSidebar_MenuItem_SortierungTop_BackgroundColor,
        sortierung_hintergrundfarbe_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_SortierungTop_BackgroundColorNotEncoded
        ),
        sortierung_schattenfarbe:
          DB_LeftSidebar_MenuItem_SortierungTop_ShadowColor,
        sortierung_schattenfarbe_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_SortierungTop_ShadowColorNotEncoded
        ),
        sortierung_schattenposition_horizontal:
          DB_LeftSidebar_MenuItem_SortierungTop_ShadowHorizontal,
        sortierung_schattenposition_vertikal:
          DB_LeftSidebar_MenuItem_SortierungTop_ShadowVertical,
        sortierung_schattenrauschen:
          DB_LeftSidebar_MenuItem_SortierungTop_ShadowBlur,
        sortierung_fontfamilyname: DB_fontFace_FilterTopBar_FontFamilyName,
        sortierung_fontfamilysrc: DB_fontFace_FilterTopBar_FontFamilySrc,

        sortierung_hov_schriftfarbe:
          DB_LeftSidebar_MenuItem_SortierungTop_Hover_FontColor,
        sortierung_hov_schriftfarbe_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_SortierungTop_Hover_FontColorNotEncoded
        ),

        sortierung_hov_hintergrundfarbe:
          DB_LeftSidebar_MenuItem_SortierungTop_Hover_BackgroundColor,
        sortierung_hov_hintergrundfarbe_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_SortierungTop_Hover_BackgroundColorNotEncoded
        ),
        sortierung_hov_schattenfarbe:
          DB_LeftSidebar_MenuItem_SortierungTop_Hover_ShadowColor,
        sortierung_hov_schattenfarbe_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_SortierungTop_Hover_ShadowColorNotEncoded
        ),
        sortierung_hov_schattenposition_horizontal:
          DB_LeftSidebar_MenuItem_SortierungTop_Hover_ShadowHorizontal,
        sortierung_hov_schattenposition_vertikal:
          DB_LeftSidebar_MenuItem_SortierungTop_Hover_ShadowVertical,
        sortierung_hov_schattenrauschen:
          DB_LeftSidebar_MenuItem_SortierungTop_Hover_ShadowBlur,

        sortierung_border_color_left:
          DB_LeftSidebar_MenuItem_SortierungTop_BorderColorLeft,
        sortierung_border_color_left_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_SortierungTop_BorderColorLeftNotEncoded
        ),
        sortierung_border_color_right:
          DB_LeftSidebar_MenuItem_SortierungTop_BorderColorRight,
        sortierung_border_color_right_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_SortierungTop_BorderColorRightNotEncoded
        ),
        sortierung_border_color_top:
          DB_LeftSidebar_MenuItem_SortierungTop_BorderColorTop,
        sortierung_border_color_top_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_SortierungTop_BorderColorTopNotEncoded
        ),
        sortierung_border_color_bottom:
          DB_LeftSidebar_MenuItem_SortierungTop_BorderColorBottom,
        sortierung_border_color_bottom_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_SortierungTop_BorderColorBottomNotEncoded
        ),
        sortierung_border_pixel_left:
          DB_LeftSidebar_MenuItem_SortierungTop_BorderLeft,
        sortierung_border_pixel_right:
          DB_LeftSidebar_MenuItem_SortierungTop_BorderRight,
        sortierung_border_pixel_top:
          DB_LeftSidebar_MenuItem_SortierungTop_BorderTop,
        sortierung_border_pixel_bottom:
          DB_LeftSidebar_MenuItem_SortierungTop_BorderBottom,
        sortierung_border_radius_top_left:
          DB_LeftSidebar_MenuItem_SortierungTop_BorderTopLeftRadius,
        sortierung_border_radius_top_right:
          DB_LeftSidebar_MenuItem_SortierungTop_BorderTopRightRadius,
        sortierung_border_radius_bottom_left:
          DB_LeftSidebar_MenuItem_SortierungTop_BorderBottomLeftRadius,
        sortierung_border_radius_bottom_right:
          DB_LeftSidebar_MenuItem_SortierungTop_BorderBottomRightRadius,
        sortierung_border_style_left:
          DB_LeftSidebar_MenuItem_SortierungTop_BorderStyleLeft,
        sortierung_border_style_right:
          DB_LeftSidebar_MenuItem_SortierungTop_BorderStyleRight,
        sortierung_border_style_top:
          DB_LeftSidebar_MenuItem_SortierungTop_BorderStyleTop,
        sortierung_border_style_bottom:
          DB_LeftSidebar_MenuItem_SortierungTop_BorderStyleBottom,

        sortierung_hov_border_color_left:
          DB_LeftSidebar_MenuItem_SortierungTop_Hover_BorderColorLeft,
        sortierung_hov_border_color_left_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_SortierungTop_Hover_BorderColorLeftNotEncoded
        ),
        sortierung_hov_border_color_right:
          DB_LeftSidebar_MenuItem_SortierungTop_Hover_BorderColorRight,
        sortierung_hov_border_color_right_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_SortierungTop_Hover_BorderColorRightNotEncoded
        ),
        sortierung_hov_border_color_top:
          DB_LeftSidebar_MenuItem_SortierungTop_Hover_BorderColorTop,
        sortierung_hov_border_color_top_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_SortierungTop_Hover_BorderColorTopNotEncoded
        ),
        sortierung_hov_border_color_bottom:
          DB_LeftSidebar_MenuItem_SortierungTop_Hover_BorderColorBottom,
        sortierung_hov_border_color_bottom_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_SortierungTop_Hover_BorderColorBottomNotEncoded
        ),
        sortierung_hov_border_pixel_left:
          DB_LeftSidebar_MenuItem_SortierungTop_Hover_BorderLeft,
        sortierung_hov_border_pixel_right:
          DB_LeftSidebar_MenuItem_SortierungTop_Hover_BorderRight,
        sortierung_hov_border_pixel_top:
          DB_LeftSidebar_MenuItem_SortierungTop_Hover_BorderTop,
        sortierung_hov_border_pixel_bottom:
          DB_LeftSidebar_MenuItem_SortierungTop_Hover_BorderBottom,
        sortierung_hov_border_radius_top_left:
          DB_LeftSidebar_MenuItem_SortierungTop_Hover_BorderTopLeftRadius,
        sortierung_hov_border_radius_top_right:
          DB_LeftSidebar_MenuItem_SortierungTop_Hover_BorderTopRightRadius,
        sortierung_hov_border_radius_bottom_left:
          DB_LeftSidebar_MenuItem_SortierungTop_Hover_BorderBottomLeftRadius,
        sortierung_hov_border_radius_bottom_right:
          DB_LeftSidebar_MenuItem_SortierungTop_Hover_BorderBottomRightRadius,
        sortierung_hov_border_style_left:
          DB_LeftSidebar_MenuItem_SortierungTop_Hover_BorderStyleLeft,
        sortierung_hov_border_style_right:
          DB_LeftSidebar_MenuItem_SortierungTop_Hover_BorderStyleRight,
        sortierung_hov_border_style_top:
          DB_LeftSidebar_MenuItem_SortierungTop_Hover_BorderStyleTop,
        sortierung_hov_border_style_bottom:
          DB_LeftSidebar_MenuItem_SortierungTop_Hover_BorderStyleBottom,

        main_backgroundcolor_active: backgroundColorActive.toString(),
        main_backgroundcolor_encoded: DB_BackgroundColorOverlayForAllBody,
        main_backgroundcolor_not_encoded: JSON.stringify(
          DB_BackgroundColorOverlayForAllBodyNotEncoded
        ),
        main_order_menu_content: DB_OrderOfBodyElements,

        design_columnNumber: columnNumber,

        design_MarqueeIsVisibleTop: MarqueeIsVisibleTop,
        design_topMainCategoriesVisible: topMainCategoriesVisible,
        design_bottomMainCategoriesVisible: bottomMainCategoriesVisible,
        design_leftMainCategoriesVisible: leftMainCategoriesVisible,
        design_rightMainCategoriesVisible: rightMainCategoriesVisible,

        design_TopBarIsVisibleTop: TopBarIsVisibleTop,
        design_TopBarIsVisibleBottom: TopBarIsVisibleBottom,
        design_TopBarIsVisibleLeft: TopBarIsVisibleLeft,
        design_TopBarIsVisibleRight: TopBarIsVisibleRight,

        design_TopBarFilterProductOne: TopBarFilterProductOne,
        design_TopBarFilterProductTwo: TopBarFilterProductTwo,
        design_TopBarFilterProductThree: TopBarFilterProductThree,
        design_TopBarFilterProductFour: TopBarFilterProductFour,

        design_ProductFilterIsVisibleTop: ProductFilterIsVisibleTop,
        design_ProductFilterIsVisibleBottom: ProductFilterIsVisibleBottom,
        design_ProductFilterIsVisibleLeft: ProductFilterIsVisibleLeft,
        design_ProductFilterIsVisibleRight: ProductFilterIsVisibleRight,

        design_leftCategoriesVisible: leftCategoriesVisible,
        design_specialRightCategoriesVisible: specialRightCategoriesVisible,
        design_specialLeftFiltersVisible: specialLeftFiltersVisible,
        design_rightFiltersVisible: rightFiltersVisible,
        design_specialLeftSocialsVisible: specialLeftSocialsVisible,
        design_rightSocialsVisible: rightSocialsVisible,
      };

      const design_productcard_breadcrumbs = {
        card_crumbs_hintergrundfarbe:
          DB_LeftSidebar_MenuProduktContainer_BackgroundColor,
        card_crumbs_hintergrundfarbe_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuProduktContainer_BackgroundColorNotEncoded
        ),
        card_crumbs_image_hintergrundfarbe:
          DB_LeftSidebar_MenuProduktImageContainer_BackgroundColor,
        card_crumbs_image_hintergrundfarbe_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuProduktImageContainer_BackgroundColorNotEncoded
        ),
        card_crumbs_schattenfarbe:
          DB_LeftSidebar_MenuItem_MenuProduktContainer_ShadowColor,
        card_crumbs_schattenfarbe_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_MenuProduktContainer_ShadowColorNotEncoded
        ),
        card_crumbs_schattenposition_horizontal:
          DB_LeftSidebar_MenuItem_MenuProduktContainer_ShadowHorizontal,
        card_crumbs_schattenposition_vertikal:
          DB_LeftSidebar_MenuItem_MenuProduktContainer_ShadowVertical,
        card_crumbs_schattenrauschen:
          DB_LeftSidebar_MenuItem_MenuProduktContainer_ShadowBlur,
        card_crumbs_innerer_abstand_links:
          DB_LeftSidebar_MenuItem_MenuProduktContainer_paddingLeft,
        card_crumbs_innerer_abstand_rechts:
          DB_LeftSidebar_MenuItem_MenuProduktContainer_paddingRight,
        card_crumbs_innerer_abstand_oben:
          DB_LeftSidebar_MenuItem_MenuProduktContainer_paddingTop,
        card_crumbs_innerer_abstand_unten:
          DB_LeftSidebar_MenuItem_MenuProduktContainer_paddingBottom,

        card_crumbs_hover_hintergrundfarbe:
          DB_LeftSidebar_MenuProduktContainer_Hover_BackgroundColor,
        card_crumbs_hover_hintergrundfarbe_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuProduktContainer_Hover_BackgroundColorNotEncoded
        ),
        card_crumbs_image_hover_hintergrundfarbe:
          DB_LeftSidebar_MenuProduktImageContainer_Hover_BackgroundColor,
        card_crumbs_image_hover_hintergrundfarbe_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuProduktImageContainer_Hover_BackgroundColorNotEncoded
        ),
        card_crumbs_hover_schattenfarbe:
          DB_LeftSidebar_MenuItem_MenuProduktContainer_Hover_ShadowColor,
        card_crumbs_hover_schattenfarbe_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_MenuProduktContainer_Hover_ShadowColorNotEncoded
        ),
        card_crumbs_hover_schattenposition_horizontal:
          DB_LeftSidebar_MenuItem_MenuProduktContainer_Hover_ShadowHorizontal,
        card_crumbs_hover_schattenposition_vertikal:
          DB_LeftSidebar_MenuItem_MenuProduktContainer_Hover_ShadowVertical,
        card_crumbs_hover_schattenrauschen:
          DB_LeftSidebar_MenuItem_MenuProduktContainer_Hover_ShadowBlur,
        card_crumbs_hover_innerer_abstand_links:
          DB_LeftSidebar_MenuItem_MenuProduktContainer_Hover_paddingLeft,
        card_crumbs_hover_innerer_abstand_rechts:
          DB_LeftSidebar_MenuItem_MenuProduktContainer_Hover_paddingRight,
        card_crumbs_hover_innerer_abstand_oben:
          DB_LeftSidebar_MenuItem_MenuProduktContainer_Hover_paddingTop,
        card_crumbs_hover_innerer_abstand_unten:
          DB_LeftSidebar_MenuItem_MenuProduktContainer_Hover_paddingBottom,

        card_crumbs_border_color_left:
          DB_LeftSidebar_MenuProduktContainer_BorderColorLeft,
        card_crumbs_border_color_left_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuProduktContainer_BorderColorLeftNotEncoded
        ),
        card_crumbs_border_color_right:
          DB_LeftSidebar_MenuProduktContainer_BorderColorRight,
        card_crumbs_border_color_right_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuProduktContainer_BorderColorRightNotEncoded
        ),
        card_crumbs_border_color_top:
          DB_LeftSidebar_MenuProduktContainer_BorderColorTop,
        card_crumbs_border_color_top_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuProduktContainer_BorderColorTopNotEncoded
        ),
        card_crumbs_border_color_bottom:
          DB_LeftSidebar_MenuProduktContainer_BorderColorBottom,
        card_crumbs_border_color_bottom_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuProduktContainer_BorderColorBottomNotEncoded
        ),
        card_crumbs_border_pixel_left:
          DB_LeftSidebar_MenuProduktContainer_BorderLeft,
        card_crumbs_border_pixel_right:
          DB_LeftSidebar_MenuProduktContainer_BorderRight,
        card_crumbs_border_pixel_top:
          DB_LeftSidebar_MenuProduktContainer_BorderTop,
        card_crumbs_border_pixel_bottom:
          DB_LeftSidebar_MenuProduktContainer_BorderBottom,
        card_crumbs_border_radius_top_left:
          DB_LeftSidebar_MenuItem_MenuProduktContainer_BorderTopLeftRadius,
        card_crumbs_border_radius_top_right:
          DB_LeftSidebar_MenuItem_MenuProduktContainer_BorderTopRightRadius,
        card_crumbs_border_radius_bottom_left:
          DB_LeftSidebar_MenuItem_MenuProduktContainer_BorderBottomLeftRadius,
        card_crumbs_border_radius_bottom_right:
          DB_LeftSidebar_MenuItem_MenuProduktContainer_BorderBottomRightRadius,
        card_crumbs_border_style_left:
          DB_LeftSidebar_MenuProduktContainer_BorderStyleLeft,
        card_crumbs_border_style_right:
          DB_LeftSidebar_MenuProduktContainer_BorderStyleRight,
        card_crumbs_border_style_top:
          DB_LeftSidebar_MenuProduktContainer_BorderStyleTop,
        card_crumbs_border_style_bottom:
          DB_LeftSidebar_MenuProduktContainer_BorderStyleBottom,

        card_crumbs_border_hov_color_left:
          DB_LeftSidebar_MenuProduktContainer_Hover_BorderColorLeft,
        card_crumbs_border_hov_color_left_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuProduktContainer_Hover_BorderColorLeftNotEncoded
        ),
        card_crumbs_border_hov_color_right:
          DB_LeftSidebar_MenuProduktContainer_Hover_BorderColorRight,
        card_crumbs_border_hov_color_right_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuProduktContainer_Hover_BorderColorRightNotEncoded
        ),
        card_crumbs_border_hov_color_top:
          DB_LeftSidebar_MenuProduktContainer_Hover_BorderColorTop,
        card_crumbs_border_hov_color_top_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuProduktContainer_Hover_BorderColorTopNotEncoded
        ),
        card_crumbs_border_hov_color_bottom:
          DB_LeftSidebar_MenuProduktContainer_Hover_BorderColorBottom,
        card_crumbs_border_hov_color_bottom_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuProduktContainer_Hover_BorderColorBottomNotEncoded
        ),
        card_crumbs_border_hov_pixel_left:
          DB_LeftSidebar_MenuProduktContainer_Hover_BorderLeft,
        card_crumbs_border_hov_pixel_right:
          DB_LeftSidebar_MenuProduktContainer_Hover_BorderRight,
        card_crumbs_border_hov_pixel_top:
          DB_LeftSidebar_MenuProduktContainer_Hover_BorderTop,
        card_crumbs_border_hov_pixel_bottom:
          DB_LeftSidebar_MenuProduktContainer_Hover_BorderBottom,
        card_crumbs_border_hov_radius_top_left:
          DB_LeftSidebar_MenuItem_MenuProduktContainer_Hover_BorderTopLeftRadius,
        card_crumbs_border_hov_radius_top_right:
          DB_LeftSidebar_MenuItem_MenuProduktContainer_Hover_BorderTopRightRadius,
        card_crumbs_border_hov_radius_bottom_left:
          DB_LeftSidebar_MenuItem_MenuProduktContainer_Hover_BorderBottomLeftRadius,
        card_crumbs_border_hov_radius_bottom_right:
          DB_LeftSidebar_MenuItem_MenuProduktContainer_Hover_BorderBottomRightRadius,
        card_crumbs_border_hov_style_left:
          DB_LeftSidebar_MenuProduktContainer_Hover_BorderStyleLeft,
        card_crumbs_border_hov_style_right:
          DB_LeftSidebar_MenuProduktContainer_Hover_BorderStyleRight,
        card_crumbs_border_hov_style_top:
          DB_LeftSidebar_MenuProduktContainer_Hover_BorderStyleTop,
        card_crumbs_border_hov_style_bottom:
          DB_LeftSidebar_MenuProduktContainer_Hover_BorderStyleBottom,

        card_crumbs_bread_schriftfarbe:
          DB_LeftSidebar_MenuItem_BreadCrumbs_FontColor,
        card_crumbs_bread_schriftfarbe_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_BreadCrumbs_FontColorNotEncoded
        ),
        card_crumbs_bread_hintergrundfarbe:
          DB_LeftSidebar_MenuItem_BreadCrumbs_BackgroundColor,
        card_crumbs_bread_hintergrundfarbe_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_BreadCrumbs_BackgroundColorNotEncoded
        ),
        card_crumbs_bread_schattenfarbe:
          DB_LeftSidebar_MenuItem_BreadCrumbs_ShadowColor,
        card_crumbs_bread_schattenfarbe_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_BreadCrumbs_ShadowColorNotEncoded
        ),
        card_crumbs_bread_schattenposition_horizontal:
          DB_LeftSidebar_MenuItem_BreadCrumbs_ShadowHorizontal,
        card_crumbs_bread_schattenposition_vertikal:
          DB_LeftSidebar_MenuItem_BreadCrumbs_ShadowVertical,
        card_crumbs_bread_schattenrauschen:
          DB_LeftSidebar_MenuItem_BreadCrumbs_ShadowBlur,
        card_crumbs_bread_schriftgroesse:
          DB_LeftSidebar_MenuItem_BreadCrumbs_FontSize,
        card_crumbs_bread_schriftgewicht:
          DB_LeftSidebar_MenuItem_BreadCrumbs_FontWeight,
        card_crumbs_bread_buchstabenabstand:
          DB_LeftSidebar_MenuItem_BreadCrumbs_letterSpacing,
        card_crumbs_bread_text_dekoration:
          DB_LeftSidebar_MenuItem_BreadCrumbs_TextDecoration,
        card_crumbs_bread_text_dekoration_stil:
          DB_LeftSidebar_MenuItem_BreadCrumbs_TextDecorationStyle,
        card_crumbs_bread_aussen_abstand_links_rechts:
          DB_LeftSidebar_MenuItem_BreadCrumbs_marginLeftRight,
        card_crumbs_bread_aussen_abstand_oben_unten:
          DB_LeftSidebar_MenuItem_BreadCrumbs_marginTopBottom,
        card_crumbs_bread_innerer_abstand_links_rechts:
          DB_LeftSidebar_MenuItem_BreadCrumbs_paddingLeftRight,
        card_crumbs_bread_innerer_abstand_oben_unten:
          DB_LeftSidebar_MenuItem_BreadCrumbs_paddingTopBottom,
        card_crumbs_bread_textausrichtung:
          DB_LeftSidebar_MenuItem_BreadCrumbs_TextAlign,
        card_crumbs_bread_fontfamily:
          DB_fontFace_chooseFontBreadcrumbs_FontFamilyName,
        card_crumbs_bread_fontfamilysrc:
          DB_fontFace_chooseFontBreadcrumbs_FontFamilySrc,

        card_crumbs_bread_hov_schriftfarbe:
          DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_FontColor,
        card_crumbs_bread_hov_schriftfarbe_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_FontColorNotEncoded
        ),
        card_crumbs_bread_hov_hintergrundfarbe:
          DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BackgroundColor,
        card_crumbs_bread_hov_hintergrundfarbe_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BackgroundColorNotEncoded
        ),
        card_crumbs_bread_hov_schattenfarbe:
          DB_LeftSidebar_MenuItem_BreadCrumbs_ShadowColor_Hover,
        card_crumbs_bread_hov_schattenfarbe_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_BreadCrumbs_ShadowColorNotEncoded_Hover
        ),
        card_crumbs_bread_hov_schattenposition_horizontal:
          DB_LeftSidebar_MenuItem_BreadCrumbs_ShadowHorizontal_Hover,
        card_crumbs_bread_hov_schattenposition_vertikal:
          DB_LeftSidebar_MenuItem_BreadCrumbs_ShadowVertical_Hover,
        card_crumbs_bread_hov_schattenrauschen:
          DB_LeftSidebar_MenuItem_BreadCrumbs_ShadowBlur_Hover,
        card_crumbs_bread_hov_schriftgroesse:
          DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_FontSize,
        card_crumbs_bread_hov_schriftgewicht:
          DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_FontWeight,
        card_crumbs_bread_hov_buchstabenabstand:
          DB_LeftSidebar_MenuItem_BreadCrumbs_letterSpacing_Hover,
        card_crumbs_bread_hov_text_dekoration:
          DB_LeftSidebar_MenuItem_BreadCrumbs_TextDecoration_Hover,
        card_crumbs_bread_hov_text_dekoration_stil:
          DB_LeftSidebar_MenuItem_BreadCrumbs_TextDecorationStyle_Hover,
        card_crumbs_bread_hov_aussen_abstand_links_rechts:
          DB_LeftSidebar_MenuItem_BreadCrumbs_marginLeftRight_Hover,
        card_crumbs_bread_hov_aussen_abstand_oben_unten:
          DB_LeftSidebar_MenuItem_BreadCrumbs_marginTopBottom_Hover,
        card_crumbs_bread_hov_innerer_abstand_links_rechts:
          DB_LeftSidebar_MenuItem_BreadCrumbs_paddingLeftRight_Hover,
        card_crumbs_bread_hov_innerer_abstand_oben_unten:
          DB_LeftSidebar_MenuItem_BreadCrumbs_paddingTopBottom_Hover,
        card_crumbs_bread_hov_textausrichtung:
          DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_TextAlign,

        card_crumbs_bread_border_color_left:
          DB_LeftSidebar_MenuItem_BreadCrumbs_BorderColorLeft,
        card_crumbs_bread_border_color_left_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_BreadCrumbs_BorderColorLeftNotEncoded
        ),
        card_crumbs_bread_border_color_right:
          DB_LeftSidebar_MenuItem_BreadCrumbs_BorderColorRight,
        card_crumbs_bread_border_color_right_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_BreadCrumbs_BorderColorRightNotEncoded
        ),
        card_crumbs_bread_border_color_top:
          DB_LeftSidebar_MenuItem_BreadCrumbs_BorderColorTop,
        card_crumbs_bread_border_color_top_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_BreadCrumbs_BorderColorTopNotEncoded
        ),
        card_crumbs_bread_border_color_bottom:
          DB_LeftSidebar_MenuItem_BreadCrumbs_BorderColorBottom,
        card_crumbs_bread_border_color_bottom_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_BreadCrumbs_BorderColorBottomNotEncoded
        ),
        card_crumbs_bread_border_pixel_left:
          DB_LeftSidebar_MenuItem_BreadCrumbs_BorderLeft,
        card_crumbs_bread_border_pixel_right:
          DB_LeftSidebar_MenuItem_BreadCrumbs_BorderRight,
        card_crumbs_bread_border_pixel_top:
          DB_LeftSidebar_MenuItem_BreadCrumbs_BorderTop,
        card_crumbs_bread_border_pixel_bottom:
          DB_LeftSidebar_MenuItem_BreadCrumbs_BorderBottom,
        card_crumbs_bread_border_radius_top_left:
          DB_LeftSidebar_MenuItem_BreadCrumbs_BorderTopLeftRadius,
        card_crumbs_bread_border_radius_top_right:
          DB_LeftSidebar_MenuItem_BreadCrumbs_BorderTopRightRadius,
        card_crumbs_bread_border_radius_bottom_left:
          DB_LeftSidebar_MenuItem_BreadCrumbs_BorderBottomLeftRadius,
        card_crumbs_bread_border_radius_bottom_right:
          DB_LeftSidebar_MenuItem_BreadCrumbs_BorderBottomRightRadius,
        card_crumbs_bread_border_style_left:
          DB_LeftSidebar_MenuItem_BreadCrumbs_BorderStyleLeft,
        card_crumbs_bread_border_style_right:
          DB_LeftSidebar_MenuItem_BreadCrumbs_BorderStyleRight,
        card_crumbs_bread_border_style_top:
          DB_LeftSidebar_MenuItem_BreadCrumbs_BorderStyleTop,
        card_crumbs_bread_border_style_bottom:
          DB_LeftSidebar_MenuItem_BreadCrumbs_BorderStyleBottom,

        card_crumbs_bread_border_hov_color_left:
          DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderColorLeft,
        card_crumbs_bread_border_hov_color_left_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderColorLeftNotEncoded
        ),
        card_crumbs_bread_border_hov_color_right:
          DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderColorRight,
        card_crumbs_bread_border_hov_color_right_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderColorRightNotEncoded
        ),
        card_crumbs_bread_border_hov_color_top:
          DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderColorTop,
        card_crumbs_bread_border_hov_color_top_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderColorTopNotEncoded
        ),
        card_crumbs_bread_border_hov_color_bottom:
          DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderColorBottom,
        card_crumbs_bread_border_hov_color_bottom_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderColorBottomNotEncoded
        ),
        card_crumbs_bread_border_hov_pixel_left:
          DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderLeft,
        card_crumbs_bread_border_hov_pixel_right:
          DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderRight,
        card_crumbs_bread_border_hov_pixel_top:
          DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderTop,
        card_crumbs_bread_border_hov_pixel_bottom:
          DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderBottom,
        card_crumbs_bread_border_hov_radius_top_left:
          DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderTopLeftRadius,
        card_crumbs_bread_border_hov_radius_top_right:
          DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderTopRightRadius,
        card_crumbs_bread_border_hov_radius_bottom_left:
          DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderBottomLeftRadius,
        card_crumbs_bread_border_hov_radius_bottom_right:
          DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderBottomRightRadius,
        card_crumbs_bread_border_hov_style_left:
          DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderStyleLeft,
        card_crumbs_bread_border_hov_style_right:
          DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderStyleRight,
        card_crumbs_bread_border_hov_style_top:
          DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderStyleTop,
        card_crumbs_bread_border_hov_style_bottom:
          DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderStyleBottom,

        card_main_title_schriftfarbe:
          DB_LeftSidebar_MenuItem_HauptTitel_FontColor,
        card_main_title_schriftfarbe_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_HauptTitel_FontColorNotEncoded
        ),
        card_main_title_hintergrundfarbe:
          DB_LeftSidebar_MenuItem_HauptTitel_BackgroundColor,
        card_main_title_hintergrundfarbe_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_HauptTitel_BackgroundColorNotEncoded
        ),
        card_main_title_schattenfarbe:
          DB_LeftSidebar_MenuItem_HauptTitel_ShadowColor,
        card_main_title_schattenfarbe_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_HauptTitel_ShadowColorNotEncoded
        ),
        card_main_title_schattenposition_horizontal:
          DB_LeftSidebar_MenuItem_HauptTitel_ShadowHorizontal,
        card_main_title_schattenposition_vertikal:
          DB_LeftSidebar_MenuItem_HauptTitel_ShadowVertical,
        card_main_title_schattenrauschen:
          DB_LeftSidebar_MenuItem_HauptTitel_ShadowBlur,
        card_main_title_schriftgroesse:
          DB_LeftSidebar_MenuItem_HauptTitel_FontSize,
        card_main_title_schriftgewicht:
          DB_LeftSidebar_MenuItem_HauptTitel_FontWeight,
        card_main_title_buchstabenabstand:
          DB_LeftSidebar_MenuItem_HauptTitel_letterSpacing,
        card_main_title_text_dekoration:
          DB_LeftSidebar_MenuItem_HauptTitel_TextDecoration,
        card_main_title_text_dekoration_stil:
          DB_LeftSidebar_MenuItem_HauptTitel_TextDecorationStyle,
        card_main_title_aussen_abstand_links_rechts:
          DB_LeftSidebar_MenuItem_HauptTitel_marginLeftRight,
        card_main_title_aussen_abstand_oben_unten:
          DB_LeftSidebar_MenuItem_HauptTitel_marginTopBottom,
        card_main_title_innerer_abstand_links_rechts:
          DB_LeftSidebar_MenuItem_HauptTitel_paddingLeftRight,
        card_main_title_innerer_abstand_oben_unten:
          DB_LeftSidebar_MenuItem_HauptTitel_paddingTopBottom,
        card_main_title_textausrichtung:
          DB_LeftSidebar_MenuItem_HauptTitel_TextAlign,
        card_main_title_fontfamily:
          DB_fontFace_chooseFontFamilyProduct_FontFamilyName,
        card_main_title_fontfamilysrc:
          DB_fontFace_chooseFontFamilyProduct_FontFamilySrc,

        card_main_title_hov_schriftfarbe:
          DB_LeftSidebar_MenuItem_HauptTitel_Hover_FontColor,
        card_main_title_hov_schriftfarbe_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_HauptTitel_Hover_FontColorNotEncoded
        ),
        card_main_title_hov_hintergrundfarbe:
          DB_LeftSidebar_MenuItem_HauptTitel_Hover_BackgroundColor,
        card_main_title_hov_hintergrundfarbe_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_HauptTitel_Hover_BackgroundColorNotEncoded
        ),
        card_main_title_hov_schattenfarbe:
          DB_LeftSidebar_MenuItem_HauptTitel_ShadowColor_Hover,
        card_main_title_hov_schattenfarbe_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_HauptTitel_ShadowColorNotEncoded_Hover
        ),
        card_main_title_hov_schattenposition_horizontal:
          DB_LeftSidebar_MenuItem_HauptTitel_ShadowHorizontal_Hover,
        card_main_title_hov_schattenposition_vertikal:
          DB_LeftSidebar_MenuItem_HauptTitel_ShadowVertical_Hover,
        card_main_title_hov_schattenrauschen:
          DB_LeftSidebar_MenuItem_HauptTitel_ShadowBlur_Hover,
        card_main_title_hov_schriftgroesse:
          DB_LeftSidebar_MenuItem_HauptTitel_Hover_FontSize,
        card_main_title_hov_schriftgewicht:
          DB_LeftSidebar_MenuItem_HauptTitel_Hover_FontWeight,
        card_main_title_hov_buchstabenabstand:
          DB_LeftSidebar_MenuItem_HauptTitel_letterSpacing_Hover,
        card_main_title_hov_text_dekoration:
          DB_LeftSidebar_MenuItem_HauptTitel_TextDecoration_Hover,
        card_main_title_hov_text_dekoration_stil:
          DB_LeftSidebar_MenuItem_HauptTitel_TextDecorationStyle_Hover,
        card_main_title_hov_aussen_abstand_links_rechts:
          DB_LeftSidebar_MenuItem_HauptTitel_marginLeftRight_Hover,
        card_main_title_hov_aussen_abstand_oben_unten:
          DB_LeftSidebar_MenuItem_HauptTitel_marginTopBottom_Hover,
        card_main_title_hov_innerer_abstand_links_rechts:
          DB_LeftSidebar_MenuItem_HauptTitel_paddingLeftRight_Hover,
        card_main_title_hov_innerer_abstand_oben_unten:
          DB_LeftSidebar_MenuItem_HauptTitel_paddingTopBottom_Hover,
        card_main_title_hov_textausrichtung:
          DB_LeftSidebar_MenuItem_HauptTitel_Hover_TextAlign,

        card_main_title_border_color_left:
          DB_LeftSidebar_MenuItem_HauptTitel_BorderColorLeft,
        card_main_title_border_color_left_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_HauptTitel_BorderColorLeftNotEncoded
        ),
        card_main_title_border_color_right:
          DB_LeftSidebar_MenuItem_HauptTitel_BorderColorRight,
        card_main_title_border_color_right_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_HauptTitel_BorderColorRightNotEncoded
        ),
        card_main_title_border_color_top:
          DB_LeftSidebar_MenuItem_HauptTitel_BorderColorTop,
        card_main_title_border_color_top_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_HauptTitel_BorderColorTopNotEncoded
        ),
        card_main_title_border_color_bottom:
          DB_LeftSidebar_MenuItem_HauptTitel_BorderColorBottom,
        card_main_title_border_color_bottom_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_HauptTitel_BorderColorBottomNotEncoded
        ),
        card_main_title_border_pixel_left:
          DB_LeftSidebar_MenuItem_HauptTitel_BorderLeft,
        card_main_title_border_pixel_right:
          DB_LeftSidebar_MenuItem_HauptTitel_BorderRight,
        card_main_title_border_pixel_top:
          DB_LeftSidebar_MenuItem_HauptTitel_BorderTop,
        card_main_title_border_pixel_bottom:
          DB_LeftSidebar_MenuItem_HauptTitel_BorderBottom,
        card_main_title_border_radius_top_left:
          DB_LeftSidebar_MenuItem_HauptTitel_BorderTopLeftRadius,
        card_main_title_border_radius_top_right:
          DB_LeftSidebar_MenuItem_HauptTitel_BorderTopRightRadius,
        card_main_title_border_radius_bottom_left:
          DB_LeftSidebar_MenuItem_HauptTitel_BorderBottomLeftRadius,
        card_main_title_border_radius_bottom_right:
          DB_LeftSidebar_MenuItem_HauptTitel_BorderBottomRightRadius,
        card_main_title_border_style_left:
          DB_LeftSidebar_MenuItem_HauptTitel_BorderStyleLeft,
        card_main_title_border_style_right:
          DB_LeftSidebar_MenuItem_HauptTitel_BorderStyleRight,
        card_main_title_border_style_top:
          DB_LeftSidebar_MenuItem_HauptTitel_BorderStyleTop,
        card_main_title_border_style_bottom:
          DB_LeftSidebar_MenuItem_HauptTitel_BorderStyleBottom,

        card_main_title_border_hov_color_left:
          DB_LeftSidebar_MenuItem_HauptTitel_Hover_BorderColorLeft,
        card_main_title_border_hov_color_left_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_HauptTitel_Hover_BorderColorLeftNotEncoded
        ),
        card_main_title_border_hov_color_right:
          DB_LeftSidebar_MenuItem_HauptTitel_Hover_BorderColorRight,
        card_main_title_border_hov_color_right_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_HauptTitel_Hover_BorderColorRightNotEncoded
        ),
        card_main_title_border_hov_color_top:
          DB_LeftSidebar_MenuItem_HauptTitel_Hover_BorderColorTop,
        card_main_title_border_hov_color_top_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_HauptTitel_Hover_BorderColorTopNotEncoded
        ),
        card_main_title_border_hov_color_bottom:
          DB_LeftSidebar_MenuItem_HauptTitel_Hover_BorderColorBottom,
        card_main_title_border_hov_color_bottom_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_HauptTitel_Hover_BorderColorBottomNotEncoded
        ),
        card_main_title_border_hov_pixel_left:
          DB_LeftSidebar_MenuItem_HauptTitel_Hover_BorderLeft,
        card_main_title_border_hov_pixel_right:
          DB_LeftSidebar_MenuItem_HauptTitel_Hover_BorderRight,
        card_main_title_border_hov_pixel_top:
          DB_LeftSidebar_MenuItem_HauptTitel_Hover_BorderTop,
        card_main_title_border_hov_pixel_bottom:
          DB_LeftSidebar_MenuItem_HauptTitel_Hover_BorderBottom,
        card_main_title_border_hov_radius_top_left:
          DB_LeftSidebar_MenuItem_HauptTitel_Hover_BorderTopLeftRadius,
        card_main_title_border_hov_radius_top_right:
          DB_LeftSidebar_MenuItem_HauptTitel_Hover_BorderTopRightRadius,
        card_main_title_border_hov_radius_bottom_left:
          DB_LeftSidebar_MenuItem_HauptTitel_Hover_BorderBottomLeftRadius,
        card_main_title_border_hov_radius_bottom_right:
          DB_LeftSidebar_MenuItem_HauptTitel_Hover_BorderBottomRightRadius,
        card_main_title_border_hov_style_left:
          DB_LeftSidebar_MenuItem_HauptTitel_Hover_BorderStyleLeft,
        card_main_title_border_hov_style_right:
          DB_LeftSidebar_MenuItem_HauptTitel_Hover_BorderStyleRight,
        card_main_title_border_hov_style_top:
          DB_LeftSidebar_MenuItem_HauptTitel_Hover_BorderStyleTop,
        card_main_title_border_hov_style_bottom:
          DB_LeftSidebar_MenuItem_HauptTitel_Hover_BorderStyleBottom,
      };

      const design_productcard_untertitel = {
        card_untertitel_schriftfarbe:
          DB_LeftSidebar_MenuItem_UnterTitel_FontColor,
        card_untertitel_schriftfarbe_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_UnterTitel_FontColorNotEncoded
        ),
        card_untertitel_hintergrundfarbe:
          DB_LeftSidebar_MenuItem_UnterTitel_BackgroundColor,
        card_untertitel_hintergrundfarbe_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_UnterTitel_BackgroundColorNotEncoded
        ),
        card_untertitel_schattenfarbe:
          DB_LeftSidebar_MenuItem_UnterTitel_ShadowColor,
        card_untertitel_schattenfarbe_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_UnterTitel_ShadowColorNotEncoded
        ),
        card_untertitel_schattenposition_horizontal:
          DB_LeftSidebar_MenuItem_UnterTitel_ShadowHorizontal,
        card_untertitel_schattenposition_vertikal:
          DB_LeftSidebar_MenuItem_UnterTitel_ShadowVertical,
        card_untertitel_schattenrauschen:
          DB_LeftSidebar_MenuItem_UnterTitel_ShadowBlur,
        card_untertitel_schriftgroesse:
          DB_LeftSidebar_MenuItem_UnterTitel_FontSize,
        card_untertitel_schriftgewicht:
          DB_LeftSidebar_MenuItem_UnterTitel_FontWeight,
        card_untertitel_buchstabenabstand:
          DB_LeftSidebar_MenuItem_UnterTitel_letterSpacing,
        card_untertitel_text_dekoration:
          DB_LeftSidebar_MenuItem_UnterTitel_TextDecoration,
        card_untertitel_text_dekoration_stil:
          DB_LeftSidebar_MenuItem_UnterTitel_TextDecorationStyle,
        card_untertitel_aussen_abstand_links_rechts:
          DB_LeftSidebar_MenuItem_UnterTitel_marginLeftRight,
        card_untertitel_aussen_abstand_oben_unten:
          DB_LeftSidebar_MenuItem_UnterTitel_marginTopBottom,
        card_untertitel_innerer_abstand_links_rechts:
          DB_LeftSidebar_MenuItem_UnterTitel_paddingLeftRight,
        card_untertitel_innerer_abstand_oben_unten:
          DB_LeftSidebar_MenuItem_UnterTitel_paddingTopBottom,
        card_untertitel_textausrichtung:
          DB_LeftSidebar_MenuItem_UnterTitel_TextAlign,
        card_untertitel_fontfamily:
          DB_fontFace_chooseFontFamilyProductUntertitel_FontFamilyName,
        card_untertitel_fontfamilysrc:
          DB_fontFace_chooseFontFamilyProductUntertitel_FontFamilySrc,

        card_untertitel_hov_schriftfarbe:
          DB_LeftSidebar_MenuItem_UnterTitel_Hover_FontColor,
        card_untertitel_hov_schriftfarbe_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_UnterTitel_Hover_FontColorNotEncoded
        ),
        card_untertitel_hov_hintergrundfarbe:
          DB_LeftSidebar_MenuItem_UnterTitel_Hover_BackgroundColor,
        card_untertitel_hov_hintergrundfarbe_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_UnterTitel_Hover_BackgroundColorNotEncoded
        ),
        card_untertitel_hov_schattenfarbe:
          DB_LeftSidebar_MenuItem_UnterTitel_ShadowColor_Hover,
        card_untertitel_hov_schattenfarbe_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_UnterTitel_ShadowColorNotEncoded_Hover
        ),
        card_untertitel_hov_schattenposition_horizontal:
          DB_LeftSidebar_MenuItem_UnterTitel_ShadowHorizontal_Hover,
        card_untertitel_hov_schattenposition_vertikal:
          DB_LeftSidebar_MenuItem_UnterTitel_ShadowVertical_Hover,
        card_untertitel_hov_schattenrauschen:
          DB_LeftSidebar_MenuItem_UnterTitel_ShadowBlur_Hover,
        card_untertitel_hov_schriftgroesse:
          DB_LeftSidebar_MenuItem_UnterTitel_Hover_FontSize,
        card_untertitel_hov_schriftgewicht:
          DB_LeftSidebar_MenuItem_UnterTitel_Hover_FontWeight,
        card_untertitel_hov_buchstabenabstand:
          DB_LeftSidebar_MenuItem_UnterTitel_letterSpacing_Hover,
        card_untertitel_hov_text_dekoration:
          DB_LeftSidebar_MenuItem_UnterTitel_TextDecoration_Hover,
        card_untertitel_hov_text_dekoration_stil:
          DB_LeftSidebar_MenuItem_UnterTitel_TextDecorationStyle_Hover,
        card_untertitel_hov_aussen_abstand_links_rechts:
          DB_LeftSidebar_MenuItem_UnterTitel_marginLeftRight_Hover,
        card_untertitel_hov_aussen_abstand_oben_unten:
          DB_LeftSidebar_MenuItem_UnterTitel_marginTopBottom_Hover,
        card_untertitel_hov_innerer_abstand_links_rechts:
          DB_LeftSidebar_MenuItem_UnterTitel_paddingLeftRight_Hover,
        card_untertitel_hov_innerer_abstand_oben_unten:
          DB_LeftSidebar_MenuItem_UnterTitel_paddingTopBottom_Hover,
        card_untertitel_hov_textausrichtung:
          DB_LeftSidebar_MenuItem_UnterTitel_Hover_TextAlign,

        card_untertitel_border_color_left:
          DB_LeftSidebar_MenuItem_UnterTitel_BorderColorLeft,
        card_untertitel_border_color_left_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_UnterTitel_BorderColorLeftNotEncoded
        ),
        card_untertitel_border_color_right:
          DB_LeftSidebar_MenuItem_UnterTitel_BorderColorRight,
        card_untertitel_border_color_right_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_UnterTitel_BorderColorRightNotEncoded
        ),
        card_untertitel_border_color_top:
          DB_LeftSidebar_MenuItem_UnterTitel_BorderColorTop,
        card_untertitel_border_color_top_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_UnterTitel_BorderColorTopNotEncoded
        ),
        card_untertitel_border_color_bottom:
          DB_LeftSidebar_MenuItem_UnterTitel_BorderColorBottom,
        card_untertitel_border_color_bottom_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_UnterTitel_BorderColorBottomNotEncoded
        ),
        card_untertitel_border_pixel_left:
          DB_LeftSidebar_MenuItem_UnterTitel_BorderLeft,
        card_untertitel_border_pixel_right:
          DB_LeftSidebar_MenuItem_UnterTitel_BorderRight,
        card_untertitel_border_pixel_top:
          DB_LeftSidebar_MenuItem_UnterTitel_BorderTop,
        card_untertitel_border_pixel_bottom:
          DB_LeftSidebar_MenuItem_UnterTitel_BorderBottom,
        card_untertitel_border_radius_top_left:
          DB_LeftSidebar_MenuItem_UnterTitel_BorderTopLeftRadius,
        card_untertitel_border_radius_top_right:
          DB_LeftSidebar_MenuItem_UnterTitel_BorderTopRightRadius,
        card_untertitel_border_radius_bottom_left:
          DB_LeftSidebar_MenuItem_UnterTitel_BorderBottomLeftRadius,
        card_untertitel_border_radius_bottom_right:
          DB_LeftSidebar_MenuItem_UnterTitel_BorderBottomRightRadius,
        card_untertitel_border_style_left:
          DB_LeftSidebar_MenuItem_UnterTitel_BorderStyleLeft,
        card_untertitel_border_style_right:
          DB_LeftSidebar_MenuItem_UnterTitel_BorderStyleRight,
        card_untertitel_border_style_top:
          DB_LeftSidebar_MenuItem_UnterTitel_BorderStyleTop,
        card_untertitel_border_style_bottom:
          DB_LeftSidebar_MenuItem_UnterTitel_BorderStyleBottom,

        card_untertitel_border_hov_color_left:
          DB_LeftSidebar_MenuItem_UnterTitel_Hover_BorderColorLeft,
        card_untertitel_border_hov_color_left_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_UnterTitel_Hover_BorderColorLeftNotEncoded
        ),
        card_untertitel_border_hov_color_right:
          DB_LeftSidebar_MenuItem_UnterTitel_Hover_BorderColorRight,
        card_untertitel_border_hov_color_right_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_UnterTitel_Hover_BorderColorRightNotEncoded
        ),
        card_untertitel_border_hov_color_top:
          DB_LeftSidebar_MenuItem_UnterTitel_Hover_BorderColorTop,
        card_untertitel_border_hov_color_top_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_UnterTitel_Hover_BorderColorTopNotEncoded
        ),
        card_untertitel_border_hov_color_bottom:
          DB_LeftSidebar_MenuItem_UnterTitel_Hover_BorderColorBottom,
        card_untertitel_border_hov_color_bottom_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_UnterTitel_Hover_BorderColorBottomNotEncoded
        ),
        card_untertitel_border_hov_pixel_left:
          DB_LeftSidebar_MenuItem_UnterTitel_Hover_BorderLeft,
        card_untertitel_border_hov_pixel_right:
          DB_LeftSidebar_MenuItem_UnterTitel_Hover_BorderRight,
        card_untertitel_border_hov_pixel_top:
          DB_LeftSidebar_MenuItem_UnterTitel_Hover_BorderTop,
        card_untertitel_border_hov_pixel_bottom:
          DB_LeftSidebar_MenuItem_UnterTitel_Hover_BorderBottom,
        card_untertitel_border_hov_radius_top_left:
          DB_LeftSidebar_MenuItem_UnterTitel_Hover_BorderTopLeftRadius,
        card_untertitel_border_hov_radius_top_right:
          DB_LeftSidebar_MenuItem_UnterTitel_Hover_BorderTopRightRadius,
        card_untertitel_border_hov_radius_bottom_left:
          DB_LeftSidebar_MenuItem_UnterTitel_Hover_BorderBottomLeftRadius,
        card_untertitel_border_hov_radius_bottom_right:
          DB_LeftSidebar_MenuItem_UnterTitel_Hover_BorderBottomRightRadius,
        card_untertitel_border_hov_style_left:
          DB_LeftSidebar_MenuItem_UnterTitel_Hover_BorderStyleLeft,
        card_untertitel_border_hov_style_right:
          DB_LeftSidebar_MenuItem_UnterTitel_Hover_BorderStyleRight,
        card_untertitel_border_hov_style_top:
          DB_LeftSidebar_MenuItem_UnterTitel_Hover_BorderStyleTop,
        card_untertitel_border_hov_style_bottom:
          DB_LeftSidebar_MenuItem_UnterTitel_Hover_BorderStyleBottom,

        card_price_schriftfarbe: DB_LeftSidebar_MenuItem_ProduktPreis_FontColor,
        card_price_schriftfarbe_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_ProduktPreis_FontColorNotEncoded
        ),
        card_price_hintergrundfarbe:
          DB_LeftSidebar_MenuItem_ProduktPreis_BackgroundColor,
        card_price_hintergrundfarbe_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_ProduktPreis_BackgroundColorNotEncoded
        ),
        card_price_schattenfarbe:
          DB_LeftSidebar_MenuItem_ProduktPreis_ShadowColor,
        card_price_schattenfarbe_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_ProduktPreis_ShadowColorNotEncoded
        ),
        card_price_schattenposition_horizontal:
          DB_LeftSidebar_MenuItem_ProduktPreis_ShadowHorizontal,
        card_price_schattenposition_vertikal:
          DB_LeftSidebar_MenuItem_ProduktPreis_ShadowVertical,
        card_price_schattenrauschen:
          DB_LeftSidebar_MenuItem_ProduktPreis_ShadowBlur,
        card_price_schriftgroesse:
          DB_LeftSidebar_MenuItem_ProduktPreis_FontSize,
        card_price_schriftgewicht:
          DB_LeftSidebar_MenuItem_ProduktPreis_FontWeight,
        card_price_buchstabenabstand:
          DB_LeftSidebar_MenuItem_ProduktPreis_letterSpacing,
        card_price_text_dekoration:
          DB_LeftSidebar_MenuItem_ProduktPreis_TextDecoration,
        card_price_text_dekoration_stil:
          DB_LeftSidebar_MenuItem_ProduktPreis_TextDecorationStyle,
        card_price_aussen_abstand_links_rechts:
          DB_LeftSidebar_MenuItem_ProduktPreis_marginLeftRight,
        card_price_aussen_abstand_oben_unten:
          DB_LeftSidebar_MenuItem_ProduktPreis_marginTopBottom,
        card_price_innerer_abstand_links_rechts:
          DB_LeftSidebar_MenuItem_ProduktPreis_paddingLeftRight,
        card_price_innerer_abstand_oben_unten:
          DB_LeftSidebar_MenuItem_ProduktPreis_paddingTopBottom,
        card_price_textausrichtung:
          DB_LeftSidebar_MenuItem_ProduktPreis_TextAlign,
        card_price_fontfamily: DB_fontFace_chooseFontWaehrung_FontFamilyName,
        card_price_fontfamilysrc: DB_fontFace_chooseFontWaehrung_FontFamilySrc,

        card_price_hov_schriftfarbe:
          DB_LeftSidebar_MenuItem_ProduktPreis_Hover_FontColor,
        card_price_hov_schriftfarbe_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_ProduktPreis_Hover_FontColorNotEncoded
        ),
        card_price_hov_hintergrundfarbe:
          DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BackgroundColor,
        card_price_hov_hintergrundfarbe_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BackgroundColorNotEncoded
        ),
        card_price_hov_schattenfarbe:
          DB_LeftSidebar_MenuItem_ProduktPreis_ShadowColor_Hover,
        card_price_hov_schattenfarbe_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_ProduktPreis_ShadowColorNotEncoded_Hover
        ),
        card_price_hov_schattenposition_horizontal:
          DB_LeftSidebar_MenuItem_ProduktPreis_ShadowHorizontal_Hover,
        card_price_hov_schattenposition_vertikal:
          DB_LeftSidebar_MenuItem_ProduktPreis_ShadowVertical_Hover,
        card_price_hov_schattenrauschen:
          DB_LeftSidebar_MenuItem_ProduktPreis_ShadowBlur_Hover,
        card_price_hov_schriftgroesse:
          DB_LeftSidebar_MenuItem_ProduktPreis_Hover_FontSize,
        card_price_hov_schriftgewicht:
          DB_LeftSidebar_MenuItem_ProduktPreis_Hover_FontWeight,
        card_price_hov_buchstabenabstand:
          DB_LeftSidebar_MenuItem_ProduktPreis_letterSpacing_Hover,
        card_price_hov_text_dekoration:
          DB_LeftSidebar_MenuItem_ProduktPreis_TextDecoration_Hover,
        card_price_hov_text_dekoration_stil:
          DB_LeftSidebar_MenuItem_ProduktPreis_TextDecorationStyle_Hover,
        card_price_hov_aussen_abstand_links_rechts:
          DB_LeftSidebar_MenuItem_ProduktPreis_marginLeftRight_Hover,
        card_price_hov_aussen_abstand_oben_unten:
          DB_LeftSidebar_MenuItem_ProduktPreis_marginTopBottom_Hover,
        card_price_hov_innerer_abstand_links_rechts:
          DB_LeftSidebar_MenuItem_ProduktPreis_paddingLeftRight_Hover,
        card_price_hov_innerer_abstand_oben_unten:
          DB_LeftSidebar_MenuItem_ProduktPreis_paddingTopBottom_Hover,
        card_price_hov_textausrichtung:
          DB_LeftSidebar_MenuItem_ProduktPreis_Hover_TextAlign,

        card_price_border_color_left:
          DB_LeftSidebar_MenuItem_ProduktPreis_BorderColorLeft,
        card_price_border_color_left_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_ProduktPreis_BorderColorLeftNotEncoded
        ),
        card_price_border_color_right:
          DB_LeftSidebar_MenuItem_ProduktPreis_BorderColorRight,
        card_price_border_color_right_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_ProduktPreis_BorderColorRightNotEncoded
        ),
        card_price_border_color_top:
          DB_LeftSidebar_MenuItem_ProduktPreis_BorderColorTop,
        card_price_border_color_top_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_ProduktPreis_BorderColorTopNotEncoded
        ),
        card_price_border_color_bottom:
          DB_LeftSidebar_MenuItem_ProduktPreis_BorderColorBottom,
        card_price_border_color_bottom_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_ProduktPreis_BorderColorBottomNotEncoded
        ),
        card_price_border_pixel_left:
          DB_LeftSidebar_MenuItem_ProduktPreis_BorderLeft,
        card_price_border_pixel_right:
          DB_LeftSidebar_MenuItem_ProduktPreis_BorderRight,
        card_price_border_pixel_top:
          DB_LeftSidebar_MenuItem_ProduktPreis_BorderTop,
        card_price_border_pixel_bottom:
          DB_LeftSidebar_MenuItem_ProduktPreis_BorderBottom,
        card_price_border_radius_top_left:
          DB_LeftSidebar_MenuItem_ProduktPreis_BorderTopLeftRadius,
        card_price_border_radius_top_right:
          DB_LeftSidebar_MenuItem_ProduktPreis_BorderTopRightRadius,
        card_price_border_radius_bottom_left:
          DB_LeftSidebar_MenuItem_ProduktPreis_BorderBottomLeftRadius,
        card_price_border_radius_bottom_right:
          DB_LeftSidebar_MenuItem_ProduktPreis_BorderBottomRightRadius,
        card_price_border_style_left:
          DB_LeftSidebar_MenuItem_ProduktPreis_BorderStyleLeft,
        card_price_border_style_right:
          DB_LeftSidebar_MenuItem_ProduktPreis_BorderStyleRight,
        card_price_border_style_top:
          DB_LeftSidebar_MenuItem_ProduktPreis_BorderStyleTop,
        card_price_border_style_bottom:
          DB_LeftSidebar_MenuItem_ProduktPreis_BorderStyleBottom,

        card_price_border_hov_color_left:
          DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderColorLeft,
        card_price_border_hov_color_left_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderColorLeftNotEncoded
        ),
        card_price_border_hov_color_right:
          DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderColorRight,
        card_price_border_hov_color_right_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderColorRightNotEncoded
        ),
        card_price_border_hov_color_top:
          DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderColorTop,
        card_price_border_hov_color_top_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderColorTopNotEncoded
        ),
        card_price_border_hov_color_bottom:
          DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderColorBottom,
        card_price_border_hov_color_bottom_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderColorBottomNotEncoded
        ),
        card_price_border_hov_pixel_left:
          DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderLeft,
        card_price_border_hov_pixel_right:
          DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderRight,
        card_price_border_hov_pixel_top:
          DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderTop,
        card_price_border_hov_pixel_bottom:
          DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderBottom,
        card_price_border_hov_radius_top_left:
          DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderTopLeftRadius,
        card_price_border_hov_radius_top_right:
          DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderTopRightRadius,
        card_price_border_hov_radius_bottom_left:
          DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderBottomLeftRadius,
        card_price_border_hov_radius_bottom_right:
          DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderBottomRightRadius,
        card_price_border_hov_style_left:
          DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderStyleLeft,
        card_price_border_hov_style_right:
          DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderStyleRight,
        card_price_border_hov_style_top:
          DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderStyleTop,
        card_price_border_hov_style_bottom:
          DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderStyleBottom,

        card_currency_schriftfarbe:
          DB_LeftSidebar_MenuItem_ProduktWaehrung_FontColor,
        card_currency_schriftfarbe_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_ProduktWaehrung_FontColorNotEncoded
        ),
        card_currency_hintergrundfarbe:
          DB_LeftSidebar_MenuItem_ProduktWaehrung_BackgroundColor,
        card_currency_hintergrundfarbe_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_ProduktWaehrung_BackgroundColorNotEncoded
        ),
        card_currency_schattenfarbe:
          DB_LeftSidebar_MenuItem_ProduktWaehrung_ShadowColor,
        card_currency_schattenfarbe_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_ProduktWaehrung_ShadowColorNotEncoded
        ),
        card_currency_schattenposition_horizontal:
          DB_LeftSidebar_MenuItem_ProduktWaehrung_ShadowHorizontal,
        card_currency_schattenposition_vertikal:
          DB_LeftSidebar_MenuItem_ProduktWaehrung_ShadowVertical,
        card_currency_schattenrauschen:
          DB_LeftSidebar_MenuItem_ProduktWaehrung_ShadowBlur,
        card_currency_schriftgroesse:
          DB_LeftSidebar_MenuItem_ProduktWaehrung_FontSize,
        card_currency_schriftgewicht:
          DB_LeftSidebar_MenuItem_ProduktWaehrung_FontWeight,
        card_currency_buchstabenabstand:
          DB_LeftSidebar_MenuItem_ProduktWaehrung_letterSpacing,
        card_currency_text_dekoration:
          DB_LeftSidebar_MenuItem_ProduktWaehrung_TextDecoration,
        card_currency_text_dekoration_stil:
          DB_LeftSidebar_MenuItem_ProduktWaehrung_TextDecorationStyle,
        card_currency_aussen_abstand_links_rechts:
          DB_LeftSidebar_MenuItem_ProduktWaehrung_marginLeftRight,
        card_currency_aussen_abstand_oben_unten:
          DB_LeftSidebar_MenuItem_ProduktWaehrung_marginTopBottom,
        card_currency_innerer_abstand_links_rechts:
          DB_LeftSidebar_MenuItem_ProduktWaehrung_paddingLeftRight,
        card_currency_innerer_abstand_oben_unten:
          DB_LeftSidebar_MenuItem_ProduktWaehrung_paddingTopBottom,
        card_currency_textausrichtung:
          DB_LeftSidebar_MenuItem_ProduktWaehrung_TextAlign,
        card_currency_fontfamily:
          DB_fontFace_chooseFontWaehrungEuro_FontFamilyName,
        card_currency_fontfamilysrc:
          DB_fontFace_chooseFontWaehrungEuro_FontFamilySrc,

        card_currency_hov_schriftfarbe:
          DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_FontColor,
        card_currency_hov_schriftfarbe_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_FontColorNotEncoded
        ),
        card_currency_hov_hintergrundfarbe:
          DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BackgroundColor,
        card_currency_hov_hintergrundfarbe_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BackgroundColorNotEncoded
        ),
        card_currency_hov_schattenfarbe:
          DB_LeftSidebar_MenuItem_ProduktWaehrung_ShadowColor_Hover,
        card_currency_hov_schattenfarbe_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_ProduktWaehrung_ShadowColorNotEncoded_Hover
        ),
        card_currency_hov_schattenposition_horizontal:
          DB_LeftSidebar_MenuItem_ProduktWaehrung_ShadowHorizontal_Hover,
        card_currency_hov_schattenposition_vertikal:
          DB_LeftSidebar_MenuItem_ProduktWaehrung_ShadowVertical_Hover,
        card_currency_hov_schattenrauschen:
          DB_LeftSidebar_MenuItem_ProduktWaehrung_ShadowBlur_Hover,
        card_currency_hov_schriftgroesse:
          DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_FontSize,
        card_currency_hov_schriftgewicht:
          DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_FontWeight,
        card_currency_hov_buchstabenabstand:
          DB_LeftSidebar_MenuItem_ProduktWaehrung_letterSpacing_Hover,
        card_currency_hov_text_dekoration:
          DB_LeftSidebar_MenuItem_ProduktWaehrung_TextDecoration_Hover,
        card_currency_hov_text_dekoration_stil:
          DB_LeftSidebar_MenuItem_ProduktWaehrung_TextDecorationStyle_Hover,
        card_currency_hov_aussen_abstand_links_rechts:
          DB_LeftSidebar_MenuItem_ProduktWaehrung_marginLeftRight_Hover,
        card_currency_hov_aussen_abstand_oben_unten:
          DB_LeftSidebar_MenuItem_ProduktWaehrung_marginTopBottom_Hover,
        card_currency_hov_innerer_abstand_links_rechts:
          DB_LeftSidebar_MenuItem_ProduktWaehrung_paddingLeftRight_Hover,
        card_currency_hov_innerer_abstand_oben_unten:
          DB_LeftSidebar_MenuItem_ProduktWaehrung_paddingTopBottom_Hover,
        card_currency_hov_textausrichtung:
          DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_TextAlign,

        card_currency_border_color_left:
          DB_LeftSidebar_MenuItem_ProduktWaehrung_BorderColorLeft,
        card_currency_border_color_left_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_ProduktWaehrung_BorderColorLeftNotEncoded
        ),
        card_currency_border_color_right:
          DB_LeftSidebar_MenuItem_ProduktWaehrung_BorderColorRight,
        card_currency_border_color_right_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_ProduktWaehrung_BorderColorRightNotEncoded
        ),
        card_currency_border_color_top:
          DB_LeftSidebar_MenuItem_ProduktWaehrung_BorderColorTop,
        card_currency_border_color_top_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_ProduktWaehrung_BorderColorTopNotEncoded
        ),
        card_currency_border_color_bottom:
          DB_LeftSidebar_MenuItem_ProduktWaehrung_BorderColorBottom,
        card_currency_border_color_bottom_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_ProduktWaehrung_BorderColorBottomNotEncoded
        ),
        card_currency_border_pixel_left:
          DB_LeftSidebar_MenuItem_ProduktWaehrung_BorderLeft,
        card_currency_border_pixel_right:
          DB_LeftSidebar_MenuItem_ProduktWaehrung_BorderRight,
        card_currency_border_pixel_top:
          DB_LeftSidebar_MenuItem_ProduktWaehrung_BorderTop,
        card_currency_border_pixel_bottom:
          DB_LeftSidebar_MenuItem_ProduktWaehrung_BorderBottom,
        card_currency_border_radius_top_left:
          DB_LeftSidebar_MenuItem_ProduktWaehrung_BorderTopLeftRadius,
        card_currency_border_radius_top_right:
          DB_LeftSidebar_MenuItem_ProduktWaehrung_BorderTopRightRadius,
        card_currency_border_radius_bottom_left:
          DB_LeftSidebar_MenuItem_ProduktWaehrung_BorderBottomLeftRadius,
        card_currency_border_radius_bottom_right:
          DB_LeftSidebar_MenuItem_ProduktWaehrung_BorderBottomRightRadius,
        card_currency_border_style_left:
          DB_LeftSidebar_MenuItem_ProduktWaehrung_BorderStyleLeft,
        card_currency_border_style_right:
          DB_LeftSidebar_MenuItem_ProduktWaehrung_BorderStyleRight,
        card_currency_border_style_top:
          DB_LeftSidebar_MenuItem_ProduktWaehrung_BorderStyleTop,
        card_currency_border_style_bottom:
          DB_LeftSidebar_MenuItem_ProduktWaehrung_BorderStyleBottom,

        card_currency_border_hov_color_left:
          DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderColorLeft,
        card_currency_border_hov_color_left_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderColorLeftNotEncoded
        ),
        card_currency_border_hov_color_right:
          DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderColorRight,
        card_currency_border_hov_color_right_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderColorRightNotEncoded
        ),
        card_currency_border_hov_color_top:
          DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderColorTop,
        card_currency_border_hov_color_top_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderColorTopNotEncoded
        ),
        card_currency_border_hov_color_bottom:
          DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderColorBottom,
        card_currency_border_hov_color_bottom_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderColorBottomNotEncoded
        ),
        card_currency_border_hov_pixel_left:
          DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderLeft,
        card_currency_border_hov_pixel_right:
          DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderRight,
        card_currency_border_hov_pixel_top:
          DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderTop,
        card_currency_border_hov_pixel_bottom:
          DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderBottom,
        card_currency_border_hov_radius_top_left:
          DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderTopLeftRadius,
        card_currency_border_hov_radius_top_right:
          DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderTopRightRadius,
        card_currency_border_hov_radius_bottom_left:
          DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderBottomLeftRadius,
        card_currency_border_hov_radius_bottom_right:
          DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderBottomRightRadius,
        card_currency_border_hov_style_left:
          DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderStyleLeft,
        card_currency_border_hov_style_right:
          DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderStyleRight,
        card_currency_border_hov_style_top:
          DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderStyleTop,
        card_currency_border_hov_style_bottom:
          DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderStyleBottom,
      };

      const design_product_detail = {
        detail_haupttitel_schriftfarbe:
          DB_LeftSidebar_MenuItem_TitelSuperTitel_FontColor,
        detail_haupttitel_schriftfarbe_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_TitelSuperTitel_FontColorNotEncoded
        ),
        detail_haupttitel_hintergrundfarbe:
          DB_LeftSidebar_MenuItem_TitelSuperTitel_BackgroundColor,
        detail_haupttitel_hintergrundfarbe_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_TitelSuperTitel_BackgroundColorNotEncoded
        ),
        detail_haupttitel_schattenfarbe:
          DB_LeftSidebar_MenuItem_TitelSuperTitel_ShadowColor,
        detail_haupttitel_schattenfarbe_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_TitelSuperTitel_ShadowColorNotEncoded
        ),
        detail_haupttitel_schattenposition_horizontal:
          DB_LeftSidebar_MenuItem_TitelSuperTitel_ShadowHorizontal,
        detail_haupttitel_schattenposition_vertikal:
          DB_LeftSidebar_MenuItem_TitelSuperTitel_ShadowVertical,
        detail_haupttitel_schattenrauschen:
          DB_LeftSidebar_MenuItem_TitelSuperTitel_ShadowBlur,
        detail_haupttitel_schriftgroesse:
          DB_LeftSidebar_MenuItem_TitelSuperTitel_FontSize,
        detail_haupttitel_schriftgewicht:
          DB_LeftSidebar_MenuItem_TitelSuperTitel_FontWeight,
        detail_haupttitel_buchstabenabstand:
          DB_LeftSidebar_MenuItem_TitelSuperTitel_letterSpacing,
        detail_haupttitel_text_dekoration:
          DB_LeftSidebar_MenuItem_TitelSuperTitel_TextDecoration,
        detail_haupttitel_text_dekoration_stil:
          DB_LeftSidebar_MenuItem_TitelSuperTitel_TextDecorationStyle,
        detail_haupttitel_aussen_abstand_links_rechts:
          DB_LeftSidebar_MenuItem_TitelSuperTitel_marginLeftRight,
        detail_haupttitel_aussen_abstand_oben_unten:
          DB_LeftSidebar_MenuItem_TitelSuperTitel_marginTopBottom,
        detail_haupttitel_innerer_abstand_links_rechts:
          DB_LeftSidebar_MenuItem_TitelSuperTitel_paddingLeftRight,
        detail_haupttitel_innerer_abstand_oben_unten:
          DB_LeftSidebar_MenuItem_TitelSuperTitel_paddingTopBottom,
        detail_haupttitel_textausrichtung:
          DB_LeftSidebar_MenuItem_TitelSuperTitel_TextAlign,
        detail_haupttitel_fontfamily:
          DB_fontFace_chooseFontFamilySuperTitel_FontFamilyName,
        detail_haupttitel_fontfamilysrc:
          DB_fontFace_chooseFontFamilySuperTitel_FontFamilySrc,

        detail_haupttitel_border_color_left:
          DB_LeftSidebar_MenuItem_TitelSuperTitel_BorderColorLeft,
        detail_haupttitel_border_color_left_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_TitelSuperTitel_BorderColorLeftNotEncoded
        ),
        detail_haupttitel_border_color_right:
          DB_LeftSidebar_MenuItem_TitelSuperTitel_BorderColorRight,
        detail_haupttitel_border_color_right_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_TitelSuperTitel_BorderColorRightNotEncoded
        ),
        detail_haupttitel_border_color_top:
          DB_LeftSidebar_MenuItem_TitelSuperTitel_BorderColorTop,
        detail_haupttitel_border_color_top_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_TitelSuperTitel_BorderColorTopNotEncoded
        ),
        detail_haupttitel_border_color_bottom:
          DB_LeftSidebar_MenuItem_TitelSuperTitel_BorderColorBottom,
        detail_haupttitel_border_color_bottom_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_TitelSuperTitel_BorderColorBottomNotEncoded
        ),
        detail_haupttitel_border_pixel_left:
          DB_LeftSidebar_MenuItem_TitelSuperTitel_BorderLeft,
        detail_haupttitel_border_pixel_right:
          DB_LeftSidebar_MenuItem_TitelSuperTitel_BorderRight,
        detail_haupttitel_border_pixel_top:
          DB_LeftSidebar_MenuItem_TitelSuperTitel_BorderTop,
        detail_haupttitel_border_pixel_bottom:
          DB_LeftSidebar_MenuItem_TitelSuperTitel_BorderBottom,
        detail_haupttitel_border_radius_top_left:
          DB_LeftSidebar_MenuItem_TitelSuperTitel_BorderTopLeftRadius,
        detail_haupttitel_border_radius_top_right:
          DB_LeftSidebar_MenuItem_TitelSuperTitel_BorderTopRightRadius,
        detail_haupttitel_border_radius_bottom_left:
          DB_LeftSidebar_MenuItem_TitelSuperTitel_BorderBottomLeftRadius,
        detail_haupttitel_border_radius_bottom_right:
          DB_LeftSidebar_MenuItem_TitelSuperTitel_BorderBottomRightRadius,
        detail_haupttitel_border_style_left:
          DB_LeftSidebar_MenuItem_TitelSuperTitel_BorderStyleLeft,
        detail_haupttitel_border_style_right:
          DB_LeftSidebar_MenuItem_TitelSuperTitel_BorderStyleRight,
        detail_haupttitel_border_style_top:
          DB_LeftSidebar_MenuItem_TitelSuperTitel_BorderStyleTop,
        detail_haupttitel_border_style_bottom:
          DB_LeftSidebar_MenuItem_TitelSuperTitel_BorderStyleBottom,

        detail_propTitel_schriftfarbe:
          DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_FontColor,
        detail_propTitel_schriftfarbe_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_FontColorNotEncoded
        ),
        detail_propTitel_hintergrundfarbe:
          DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BackgroundColor,
        detail_propTitel_hintergrundfarbe_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BackgroundColorNotEncoded
        ),
        detail_propTitel_schattenfarbe:
          DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_ShadowColor,
        detail_propTitel_schattenfarbe_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_ShadowColorNotEncoded
        ),
        detail_propTitel_schattenposition_horizontal:
          DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_ShadowHorizontal,
        detail_propTitel_schattenposition_vertikal:
          DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_ShadowVertical,
        detail_propTitel_schattenrauschen:
          DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_ShadowBlur,
        detail_propTitel_schriftgroesse:
          DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_FontSize,
        detail_propTitel_schriftgewicht:
          DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_FontWeight,
        detail_propTitel_buchstabenabstand:
          DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_letterSpacing,
        detail_propTitel_text_dekoration:
          DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_TextDecoration,
        detail_propTitel_text_dekoration_stil:
          DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_TextDecorationStyle,
        detail_propTitel_aussen_abstand_links_rechts:
          DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_marginLeftRight,
        detail_propTitel_aussen_abstand_oben_unten:
          DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_marginTopBottom,
        detail_propTitel_innerer_abstand_links_rechts:
          DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_paddingLeftRight,
        detail_propTitel_innerer_abstand_oben_unten:
          DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_paddingTopBottom,
        detail_propTitel_textausrichtung:
          DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_TextAlign,
        detail_propTitel_fontfamily:
          DB_fontFace_chooseFontHaupttitelFontFamily_FontFamilyName,
        detail_propTitel_fontfamilysrc:
          DB_fontFace_chooseFontHaupttitelFontFamily_FontFamilySrc,

        detail_propTitel_border_color_left:
          DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderColorLeft,
        detail_propTitel_border_color_left_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderColorLeftNotEncoded
        ),
        detail_propTitel_border_color_right:
          DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderColorRight,
        detail_propTitel_border_color_right_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderColorRightNotEncoded
        ),
        detail_propTitel_border_color_top:
          DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderColorTop,
        detail_propTitel_border_color_top_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderColorTopNotEncoded
        ),
        detail_propTitel_border_color_bottom:
          DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderColorBottom,
        detail_propTitel_border_color_bottom_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderColorBottomNotEncoded
        ),
        detail_propTitel_border_pixel_left:
          DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderLeft,
        detail_propTitel_border_pixel_right:
          DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderRight,
        detail_propTitel_border_pixel_top:
          DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderTop,
        detail_propTitel_border_pixel_bottom:
          DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderBottom,
        detail_propTitel_border_radius_top_left:
          DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderTopLeftRadius,
        detail_propTitel_border_radius_top_right:
          DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderTopRightRadius,
        detail_propTitel_border_radius_bottom_left:
          DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderBottomLeftRadius,
        detail_propTitel_border_radius_bottom_right:
          DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderBottomRightRadius,
        detail_propTitel_border_style_left:
          DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderStyleLeft,
        detail_propTitel_border_style_right:
          DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderStyleRight,
        detail_propTitel_border_style_top:
          DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderStyleTop,
        detail_propTitel_border_style_bottom:
          DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderStyleBottom,

        detail_propValues_schriftfarbe:
          DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_FontColor,
        detail_propValues_schriftfarbe_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_FontColorNotEncoded
        ),
        detail_propValues_hintergrundfarbe:
          DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BackgroundColor,
        detail_propValues_hintergrundfarbe_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BackgroundColorNotEncoded
        ),
        detail_propValues_schattenfarbe:
          DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_ShadowColor,
        detail_propValues_schattenfarbe_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_ShadowColorNotEncoded
        ),
        detail_propValues_schattenposition_horizontal:
          DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_ShadowHorizontal,
        detail_propValues_schattenposition_vertikal:
          DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_ShadowVertical,
        detail_propValues_schattenrauschen:
          DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_ShadowBlur,
        detail_propValues_schriftgroesse:
          DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_FontSize,
        detail_propValues_schriftgewicht:
          DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_FontWeight,
        detail_propValues_buchstabenabstand:
          DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_letterSpacing,
        detail_propValues_text_dekoration:
          DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_TextDecoration,
        detail_propValues_text_dekoration_stil:
          DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_TextDecorationStyle,
        detail_propValues_aussen_abstand_links_rechts:
          DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_marginLeftRight,
        detail_propValues_aussen_abstand_oben_unten:
          DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_marginTopBottom,
        detail_propValues_innerer_abstand_links_rechts:
          DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_paddingLeftRight,
        detail_propValues_innerer_abstand_oben_unten:
          DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_paddingTopBottom,
        detail_propValues_textausrichtung:
          DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_TextAlign,
        detail_propValues_fontfamily:
          DB_fontFace_chooseFontBeschreibungFontFamily_FontFamilyName,
        detail_propValues_fontfamilysrc:
          DB_fontFace_chooseFontBeschreibungFontFamily_FontFamilySrc,

        detail_propValues_border_color_left:
          DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderColorLeft,
        detail_propValues_border_color_left_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderColorLeftNotEncoded
        ),
        detail_propValues_border_color_right:
          DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderColorRight,
        detail_propValues_border_color_right_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderColorRightNotEncoded
        ),
        detail_propValues_border_color_top:
          DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderColorTop,
        detail_propValues_border_color_top_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderColorTopNotEncoded
        ),
        detail_propValues_border_color_bottom:
          DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderColorBottom,
        detail_propValues_border_color_bottom_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderColorBottomNotEncoded
        ),
        detail_propValues_border_pixel_left:
          DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderLeft,
        detail_propValues_border_pixel_right:
          DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderRight,
        detail_propValues_border_pixel_top:
          DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderTop,
        detail_propValues_border_pixel_bottom:
          DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderBottom,
        detail_propValues_border_radius_top_left:
          DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderTopLeftRadius,
        detail_propValues_border_radius_top_right:
          DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderTopRightRadius,
        detail_propValues_border_radius_bottom_left:
          DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderBottomLeftRadius,
        detail_propValues_border_radius_bottom_right:
          DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderBottomRightRadius,
        detail_propValues_border_style_left:
          DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderStyleLeft,
        detail_propValues_border_style_right:
          DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderStyleRight,
        detail_propValues_border_style_top:
          DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderStyleTop,
        detail_propValues_border_style_bottom:
          DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderStyleBottom,

        detail_formTitle_schriftfarbe:
          DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_FontColor,
        detail_formTitle_schriftfarbe_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_FontColorNotEncoded
        ),
        detail_formTitle_hintergrundfarbe:
          DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_BackgroundColor,
        detail_formTitle_hintergrundfarbe_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_BackgroundColorNotEncoded
        ),
        detail_formTitle_schattenfarbe:
          DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_ShadowColor,
        detail_formTitle_schattenfarbe_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_ShadowColorNotEncoded
        ),
        detail_formTitle_schattenposition_horizontal:
          DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_ShadowHorizontal,
        detail_formTitle_schattenposition_vertikal:
          DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_ShadowVertical,
        detail_formTitle_schattenrauschen:
          DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_ShadowBlur,
        detail_formTitle_schriftgroesse:
          DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_FontSize,
        detail_formTitle_schriftgewicht:
          DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_FontWeight,
        detail_formTitle_buchstabenabstand:
          DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_letterSpacing,
        detail_formTitle_text_dekoration:
          DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_TextDecoration,
        detail_formTitle_text_dekoration_stil:
          DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_TextDecorationStyle,
        detail_formTitle_aussen_abstand_links_rechts:
          DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_marginLeftRight,
        detail_formTitle_aussen_abstand_oben_unten:
          DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_marginTopBottom,
        detail_formTitle_innerer_abstand_links_rechts:
          DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_paddingLeftRight,
        detail_formTitle_innerer_abstand_oben_unten:
          DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_paddingTopBottom,
        detail_formTitle_textausrichtung:
          DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_TextAlign,
        detail_formTitle_fontfamily:
          DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_FontFamilyName,
        detail_formTitle_fontfamilysrc:
          DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_FontFamilySrc,

        detail_formTitle_border_color_left:
          DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_BorderColorLeft,
        detail_formTitle_border_color_left_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_BorderColorLeftNotEncoded
        ),
        detail_formTitle_border_color_right:
          DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_BorderColorRight,
        detail_formTitle_border_color_right_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_BorderColorRightNotEncoded
        ),
        detail_formTitle_border_color_top:
          DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_BorderColorTop,
        detail_formTitle_border_color_top_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_BorderColorTopNotEncoded
        ),
        detail_formTitle_border_color_bottom:
          DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_BorderColorBottom,
        detail_formTitle_border_color_bottom_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_BorderColorBottomNotEncoded
        ),
        detail_formTitle_border_pixel_left:
          DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_BorderLeft,
        detail_formTitle_border_pixel_right:
          DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_BorderRight,
        detail_formTitle_border_pixel_top:
          DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_BorderTop,
        detail_formTitle_border_pixel_bottom:
          DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_BorderBottom,
        detail_formTitle_border_radius_top_left:
          DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_BorderTopLeftRadius,
        detail_formTitle_border_radius_top_right:
          DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_BorderTopRightRadius,
        detail_formTitle_border_radius_bottom_left:
          DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_BorderBottomLeftRadius,
        detail_formTitle_border_radius_bottom_right:
          DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_BorderBottomRightRadius,
        detail_formTitle_border_style_left:
          DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_BorderStyleLeft,
        detail_formTitle_border_style_right:
          DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_BorderStyleRight,
        detail_formTitle_border_style_top:
          DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_BorderStyleTop,
        detail_formTitle_border_style_bottom:
          DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_BorderStyleBottom,

        detail_formValue_schriftfarbe:
          DB_LeftSidebar_MenuItem_TitelKontaktFormular_FontColor,
        detail_formValue_schriftfarbe_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_TitelKontaktFormular_FontColorNotEncoded
        ),
        detail_formValue_hintergrundfarbe:
          DB_LeftSidebar_MenuItem_TitelKontaktFormular_BackgroundColor,
        detail_formValue_hintergrundfarbe_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_TitelKontaktFormular_BackgroundColorNotEncoded
        ),
        detail_formValue_schattenfarbe:
          DB_LeftSidebar_MenuItem_TitelKontaktFormular_ShadowColor,
        detail_formValue_schattenfarbe_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_TitelKontaktFormular_ShadowColorNotEncoded
        ),
        detail_formValue_schattenposition_horizontal:
          DB_LeftSidebar_MenuItem_TitelKontaktFormular_ShadowHorizontal,
        detail_formValue_schattenposition_vertikal:
          DB_LeftSidebar_MenuItem_TitelKontaktFormular_ShadowVertical,
        detail_formValue_schattenrauschen:
          DB_LeftSidebar_MenuItem_TitelKontaktFormular_ShadowBlur,
        detail_formValue_schriftgroesse:
          DB_LeftSidebar_MenuItem_TitelKontaktFormular_FontSize,
        detail_formValue_schriftgewicht:
          DB_LeftSidebar_MenuItem_TitelKontaktFormular_FontWeight,
        detail_formValue_buchstabenabstand:
          DB_LeftSidebar_MenuItem_TitelKontaktFormular_letterSpacing,
        detail_formValue_text_dekoration:
          DB_LeftSidebar_MenuItem_TitelKontaktFormular_TextDecoration,
        detail_formValue_text_dekoration_stil:
          DB_LeftSidebar_MenuItem_TitelKontaktFormular_TextDecorationStyle,
        detail_formValue_aussen_abstand_links_rechts:
          DB_LeftSidebar_MenuItem_TitelKontaktFormular_marginLeftRight,
        detail_formValue_aussen_abstand_oben_unten:
          DB_LeftSidebar_MenuItem_TitelKontaktFormular_marginTopBottom,
        detail_formValue_innerer_abstand_links_rechts:
          DB_LeftSidebar_MenuItem_TitelKontaktFormular_paddingLeftRight,
        detail_formValue_innerer_abstand_oben_unten:
          DB_LeftSidebar_MenuItem_TitelKontaktFormular_paddingTopBottom,
        detail_formValue_textausrichtung:
          DB_LeftSidebar_MenuItem_TitelKontaktFormular_TextAlign,
        detail_formValue_fontfamily:
          DB_fontFace_chooseFontKontaktFormularFontFamily_FontFamilyName,
        detail_formValue_fontfamilysrc:
          DB_fontFace_chooseFontKontaktFormularFontFamily_FontFamilySrc,

        detail_formValue_border_color_left:
          DB_LeftSidebar_MenuItem_TitelKontaktFormular_BorderColorLeft,
        detail_formValue_border_color_left_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_TitelKontaktFormular_BorderColorLeftNotEncoded
        ),
        detail_formValue_border_color_right:
          DB_LeftSidebar_MenuItem_TitelKontaktFormular_BorderColorRight,
        detail_formValue_border_color_right_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_TitelKontaktFormular_BorderColorRightNotEncoded
        ),
        detail_formValue_border_color_top:
          DB_LeftSidebar_MenuItem_TitelKontaktFormular_BorderColorTop,
        detail_formValue_border_color_top_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_TitelKontaktFormular_BorderColorTopNotEncoded
        ),
        detail_formValue_border_color_bottom:
          DB_LeftSidebar_MenuItem_TitelKontaktFormular_BorderColorBottom,
        detail_formValue_border_color_bottom_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_TitelKontaktFormular_BorderColorBottomNotEncoded
        ),
        detail_formValue_border_pixel_left:
          DB_LeftSidebar_MenuItem_TitelKontaktFormular_BorderLeft,
        detail_formValue_border_pixel_right:
          DB_LeftSidebar_MenuItem_TitelKontaktFormular_BorderRight,
        detail_formValue_border_pixel_top:
          DB_LeftSidebar_MenuItem_TitelKontaktFormular_BorderTop,
        detail_formValue_border_pixel_bottom:
          DB_LeftSidebar_MenuItem_TitelKontaktFormular_BorderBottom,
        detail_formValue_border_radius_top_left:
          DB_LeftSidebar_MenuItem_TitelKontaktFormular_BorderTopLeftRadius,
        detail_formValue_border_radius_top_right:
          DB_LeftSidebar_MenuItem_TitelKontaktFormular_BorderTopRightRadius,
        detail_formValue_border_radius_bottom_left:
          DB_LeftSidebar_MenuItem_TitelKontaktFormular_BorderBottomLeftRadius,
        detail_formValue_border_radius_bottom_right:
          DB_LeftSidebar_MenuItem_TitelKontaktFormular_BorderBottomRightRadius,
        detail_formValue_border_style_left:
          DB_LeftSidebar_MenuItem_TitelKontaktFormular_BorderStyleLeft,
        detail_formValue_border_style_right:
          DB_LeftSidebar_MenuItem_TitelKontaktFormular_BorderStyleRight,
        detail_formValue_border_style_top:
          DB_LeftSidebar_MenuItem_TitelKontaktFormular_BorderStyleTop,
        detail_formValue_border_style_bottom:
          DB_LeftSidebar_MenuItem_TitelKontaktFormular_BorderStyleBottom,

        detail_formInput_schriftfarbe:
          DB_LeftSidebar_MenuItem_TitelFormInputDetail_FontColor,
        detail_formInput_schriftfarbe_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_TitelFormInputDetail_FontColorNotEncoded
        ),
        detail_formInput_hintergrundfarbe:
          DB_LeftSidebar_MenuItem_TitelFormInputDetail_BackgroundColor,
        detail_formInput_hintergrundfarbe_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_TitelFormInputDetail_BackgroundColorNotEncoded
        ),
        detail_formInput_schattenfarbe:
          DB_LeftSidebar_MenuItem_TitelFormInputDetail_ShadowColor,
        detail_formInput_schattenfarbe_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_TitelFormInputDetail_ShadowColorNotEncoded
        ),
        detail_formInput_schattenposition_horizontal:
          DB_LeftSidebar_MenuItem_TitelFormInputDetail_ShadowHorizontal,
        detail_formInput_schattenposition_vertikal:
          DB_LeftSidebar_MenuItem_TitelFormInputDetail_ShadowVertical,
        detail_formInput_schattenrauschen:
          DB_LeftSidebar_MenuItem_TitelFormInputDetail_ShadowBlur,
        detail_formInput_schriftgroesse:
          DB_LeftSidebar_MenuItem_TitelFormInputDetail_FontSize,
        detail_formInput_schriftgewicht:
          DB_LeftSidebar_MenuItem_TitelFormInputDetail_FontWeight,
        detail_formInput_buchstabenabstand:
          DB_LeftSidebar_MenuItem_TitelFormInputDetail_letterSpacing,
        detail_formInput_text_dekoration:
          DB_LeftSidebar_MenuItem_TitelFormInputDetail_TextDecoration,
        detail_formInput_text_dekoration_stil:
          DB_LeftSidebar_MenuItem_TitelFormInputDetail_TextDecorationStyle,
        detail_formInput_aussen_abstand_links_rechts:
          DB_LeftSidebar_MenuItem_TitelFormInputDetail_marginLeftRight,
        detail_formInput_aussen_abstand_oben_unten:
          DB_LeftSidebar_MenuItem_TitelFormInputDetail_marginTopBottom,
        detail_formInput_innerer_abstand_links_rechts:
          DB_LeftSidebar_MenuItem_TitelFormInputDetail_paddingLeftRight,
        detail_formInput_innerer_abstand_oben_unten:
          DB_LeftSidebar_MenuItem_TitelFormInputDetail_paddingTopBottom,
        detail_formInput_textausrichtung:
          DB_LeftSidebar_MenuItem_TitelFormInputDetail_TextAlign,
        detail_formInput_fontfamily:
          DB_fontFace_chooseFontFormLabelsFontFamily_FontFamilyName,
        detail_formInput_fontfamilysrc:
          DB_fontFace_chooseFontFormLabelsFontFamily_FontFamilySrc,

        detail_formInput_border_color_left:
          DB_LeftSidebar_MenuItem_TitelFormInputDetail_BorderColorLeft,
        detail_formInput_border_color_left_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_TitelFormInputDetail_BorderColorLeftNotEncoded
        ),
        detail_formInput_border_color_right:
          DB_LeftSidebar_MenuItem_TitelFormInputDetail_BorderColorRight,
        detail_formInput_border_color_right_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_TitelFormInputDetail_BorderColorRightNotEncoded
        ),
        detail_formInput_border_color_top:
          DB_LeftSidebar_MenuItem_TitelFormInputDetail_BorderColorTop,
        detail_formInput_border_color_top_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_TitelFormInputDetail_BorderColorTopNotEncoded
        ),
        detail_formInput_border_color_bottom:
          DB_LeftSidebar_MenuItem_TitelFormInputDetail_BorderColorBottom,
        detail_formInput_border_color_bottom_not_encoded: JSON.stringify(
          DB_LeftSidebar_MenuItem_TitelFormInputDetail_BorderColorBottomNotEncoded
        ),
        detail_formInput_border_pixel_left:
          DB_LeftSidebar_MenuItem_TitelFormInputDetail_BorderLeft,
        detail_formInput_border_pixel_right:
          DB_LeftSidebar_MenuItem_TitelFormInputDetail_BorderRight,
        detail_formInput_border_pixel_top:
          DB_LeftSidebar_MenuItem_TitelFormInputDetail_BorderTop,
        detail_formInput_border_pixel_bottom:
          DB_LeftSidebar_MenuItem_TitelFormInputDetail_BorderBottom,
        detail_formInput_border_radius_top_left:
          DB_LeftSidebar_MenuItem_TitelFormInputDetail_BorderTopLeftRadius,
        detail_formInput_border_radius_top_right:
          DB_LeftSidebar_MenuItem_TitelFormInputDetail_BorderTopRightRadius,
        detail_formInput_border_radius_bottom_left:
          DB_LeftSidebar_MenuItem_TitelFormInputDetail_BorderBottomLeftRadius,
        detail_formInput_border_radius_bottom_right:
          DB_LeftSidebar_MenuItem_TitelFormInputDetail_BorderBottomRightRadius,
        detail_formInput_border_style_left:
          DB_LeftSidebar_MenuItem_TitelFormInputDetail_BorderStyleLeft,
        detail_formInput_border_style_right:
          DB_LeftSidebar_MenuItem_TitelFormInputDetail_BorderStyleRight,
        detail_formInput_border_style_top:
          DB_LeftSidebar_MenuItem_TitelFormInputDetail_BorderStyleTop,
        detail_formInput_border_style_bottom:
          DB_LeftSidebar_MenuItem_TitelFormInputDetail_BorderStyleBottom,
      };

      ////////////zzzzzzzzzzzzzzzzzzzzzzzzzzzzzz

      axios({
        method: "post",
        url: `${process.env.REACT_APP_PUBLIC_SERVER_URL}/shop/ultra-save-design`,
        data: {
          design_top_navigation,
          design_kategorien_leftside_nav,
          design_kategorien_leftside_categories,
          design_rightside_social,
          design_rightside_filter,
          design_rightside_filter_title_item,
          design_sortierung,
          design_productcard_breadcrumbs,
          design_productcard_untertitel,
          design_product_detail,
        },

        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((response) => {
          if (response.status === 200) {
            toast.success(`${t("DesignErfolgreichGespeichert")}`, {
              toastId: "custom-id-yes",
              position: "bottom-center",
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
              transition: Zoom,
            });
          } else if (response.status === 201) {
            toast.error(`${t("KeineVerbindungZumServer")}`, {
              toastId: "custom-id-yes",
              position: "bottom-center",
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
              transition: Zoom,
            });
          }
        })
        .catch((error) => {
          toast.error(`${t("KeineVerbindungZumServer")}`, {
            toastId: "custom-id-yes",
            position: "bottom-center",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            transition: Zoom,
          });
        });
    }
  };

  const oneClickDesignChangerFunction = (
    e,
    standardDesignShopName,
    standardDesignIdentifier
  ) => {
    /*   if (standardDesignShopName === "supermegashop") {
      setDesignIdentifier(standardDesignIdentifier);
    } else {
      setDesignIdentifier(e);
    }

    if (standardDesignShopName === "supermegashop") {
      shopName = "supermegashop";
    } else {
      shopName = myRouter.query.shopName;
    } */
    // xyz
  };

  useEffect(() => {
    axios({
      method: "post",
      url: `${process.env.REACT_APP_PUBLIC_SERVER_URL}/shop/ultra-receive-design`,
      data: { shopName, designIdentifier },
    })
      .then((response) => {
        if (response.data.Design_Top_Navigation) {
          setNewDesignIdentifier(
            response.data.Design_Top_Navigation.designIdentifier
          );
        }

        const Design_Top_Navigation = {
          ...response.data.Design_Top_Navigation,
          ...response.data.Design_Top_Navigation_Two,
          ...response.data.Design_Top_Navigation_Three,
          ...response.data.Design_Top_Navigation_Four,
        };
        const Design_Left_Navigation = {
          ...response.data.Design_Left_Navigation,
          ...response.data.Design_Left_Navigation_Two,
        };
        const Design_Left_Categories = {
          ...response.data.Design_Left_Categories,
          ...response.data.Design_Left_Categories_Two,
          ...response.data.Design_Left_Categories_Three,
          ...response.data.Design_Left_Categories_Four,
          ...response.data.Design_Left_Categories_Five,
        };
        const Design_Right_Social = {
          ...response.data.Design_Right_Social,
          ...response.data.Design_Right_Social_Two,
          ...response.data.Design_Right_Social_Three,
          ...response.data.Design_Right_Social_Four,
        };
        const Design_Right_Filter = {
          ...response.data.Design_Right_Filter,
          ...response.data.Design_Right_Filter_Two,
          ...response.data.Design_Right_Filter_Three,
        };
        const Design_Right_FilterTitleItem = {
          ...response.data.Design_Right_Filter_Title_Item,
          ...response.data.Design_Right_Filter_Title_Item_Two,
          ...response.data.Design_Right_Filter_Title_Item_Three,
        };
        const Design_Sortierung = {
          ...response.data.Design_Sortierung,
          ...response.data.Design_Sortierung_Two,
        };
        const Design_Card_Crumbs = {
          ...response.data.Design_Card_Crumb,
          ...response.data.Design_Card_Crumb_Two,
          ...response.data.Design_Card_Crumb_Three,
          ...response.data.Design_Card_Crumb_Four,
        };
        const Design_Card_Subtitle = {
          ...response.data.Design_Card_Subtitle_Price,
          ...response.data.Design_Card_Subtitle_Price_Two,
          ...response.data.Design_Card_Subtitle_Price_Three,
          ...response.data.Design_Card_Subtitle_Price_Four,
          ...response.data.Design_Card_Subtitle_Price_Five,
        };
        const Design_Product_Detail = {
          ...response.data.Design_Product_Detail,
          ...response.data.Design_Product_Detail_Two,
          ...response.data.Design_Product_Detail_Three,
          ...response.data.Design_Product_Detail_Four,
          ...response.data.Design_Product_Detail_Five,
        };

        setLogoActive(
          Design_Top_Navigation !== undefined &&
            Design_Top_Navigation.logo_activated !== undefined
            ? Design_Top_Navigation.logo_activated
            : false
        );

        setBackgroundImageActive(
          Design_Top_Navigation !== undefined &&
            Design_Top_Navigation.backgroundImage_activated !== undefined
            ? Design_Top_Navigation.backgroundImage_activated
            : false
        );

        setDB_LeftSidebar_MenuContainer_Logo_DDBackgroundColor(
          Design_Top_Navigation !== undefined &&
            Design_Top_Navigation.logo_hintergrundfarbe !== undefined
            ? Design_Top_Navigation.logo_hintergrundfarbe
            : "rgba(0,0,0,0)"
        );

        setDB_LeftSidebar_MenuContainer_Logo_DDBackgroundColorNotEncoded(
          Design_Top_Navigation !== undefined &&
            Design_Top_Navigation.logo_hintergrundfarbe_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Top_Navigation.logo_hintergrundfarbe_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0 }
        );

        setDB_LeftSidebar_MenuItem_MenuContainer_Logo_DDShadowColor(
          Design_Top_Navigation !== undefined &&
            Design_Top_Navigation.logo_schattenfarbe !== undefined
            ? Design_Top_Navigation.logo_schattenfarbe
            : "rgba(0,0,0,0)"
        );
        setDB_LeftSidebar_MenuItem_MenuContainer_Logo_DDShadowColorNotEncoded(
          Design_Top_Navigation !== undefined &&
            Design_Top_Navigation.logo_schattenfarbe_not_encoded !== undefined
            ? JSON.parse(Design_Top_Navigation.logo_schattenfarbe_not_encoded)
            : { r: 0, g: 0, b: 0, a: 0 }
        );
        setDB_LeftSidebar_MenuItem_MenuContainer_Logo_DDHeight(
          Design_Top_Navigation !== undefined &&
            Design_Top_Navigation.logo_groesse !== undefined
            ? Design_Top_Navigation.logo_groesse
            : "70"
        );
        setDB_LeftSidebar_MenuItem_MenuContainer_Logo_DDShadowHorizontal(
          Design_Top_Navigation !== undefined &&
            Design_Top_Navigation.logo_schattenposition_horizontal !== undefined
            ? Design_Top_Navigation.logo_schattenposition_horizontal
            : "0"
        );
        setDB_LeftSidebar_MenuItem_MenuContainer_Logo_DDShadowVertical(
          Design_Top_Navigation !== undefined &&
            Design_Top_Navigation.logo_schattenposition_vertikal !== undefined
            ? Design_Top_Navigation.logo_schattenposition_vertikal
            : "0"
        );
        setDB_LeftSidebar_MenuItem_MenuContainer_Logo_DDShadowBlur(
          Design_Top_Navigation !== undefined &&
            Design_Top_Navigation.logo_schattenrauschen !== undefined
            ? Design_Top_Navigation.logo_schattenrauschen
            : "0"
        );
        setDB_LeftSidebar_MenuItem_MenuContainer_Logo_DDpaddingLeft(
          Design_Top_Navigation !== undefined &&
            Design_Top_Navigation.logo_innerer_abstand_links !== undefined
            ? Design_Top_Navigation.logo_innerer_abstand_links
            : "0"
        );
        setDB_LeftSidebar_MenuItem_MenuContainer_Logo_DDpaddingRight(
          Design_Top_Navigation !== undefined &&
            Design_Top_Navigation.logo_innerer_abstand_rechts !== undefined
            ? Design_Top_Navigation.logo_innerer_abstand_rechts
            : "0"
        );
        setDB_LeftSidebar_MenuItem_MenuContainer_Logo_DDpaddingTop(
          Design_Top_Navigation !== undefined &&
            Design_Top_Navigation.logo_innerer_abstand_oben !== undefined
            ? Design_Top_Navigation.logo_innerer_abstand_oben
            : "0"
        );
        setDB_LeftSidebar_MenuItem_MenuContainer_Logo_DDpaddingBottom(
          Design_Top_Navigation !== undefined &&
            Design_Top_Navigation.logo_innerer_abstand_unten !== undefined
            ? Design_Top_Navigation.logo_innerer_abstand_unten
            : "0"
        );

        setDB_LeftSidebar_MenuContainerAA_BackgroundColor(
          Design_Top_Navigation !== undefined &&
            Design_Top_Navigation.top_navigation_container_backgroundcolor !==
              undefined
            ? Design_Top_Navigation.top_navigation_container_backgroundcolor
            : "rgba(0,0,0,0.3)"
        );
        setDB_LeftSidebar_MenuContainerAA_BackgroundColorNotEncoded(
          Design_Top_Navigation !== undefined &&
            Design_Top_Navigation.top_navigation_container_backgroundcolor_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Top_Navigation.top_navigation_container_backgroundcolor_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0.3 }
        );

        setDB_LeftSidebar_MenuItem_MenuContainerAA_ShadowColor(
          Design_Top_Navigation !== undefined &&
            Design_Top_Navigation.top_navigation_container_schattenfarbe !==
              undefined
            ? Design_Top_Navigation.top_navigation_container_schattenfarbe
            : "rgba(0,0,0,0.5)"
        );
        setDB_LeftSidebar_MenuItem_MenuContainerAA_ShadowColorNotEncoded(
          Design_Top_Navigation !== undefined &&
            Design_Top_Navigation.top_navigation_container_schattenfarbe_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Top_Navigation.top_navigation_container_schattenfarbe_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0.5 }
        );
        setDB_LeftSidebar_MenuItem_MenuContainerAA_ShadowHorizontal(
          Design_Top_Navigation !== undefined &&
            Design_Top_Navigation.top_navigation_container_schattenposition_horizontal !==
              undefined
            ? Design_Top_Navigation.top_navigation_container_schattenposition_horizontal
            : "3"
        );
        setDB_LeftSidebar_MenuItem_MenuContainerAA_ShadowVertical(
          Design_Top_Navigation !== undefined &&
            Design_Top_Navigation.top_navigation_container_schattenposition_vertikal !==
              undefined
            ? Design_Top_Navigation.top_navigation_container_schattenposition_vertikal
            : "3"
        );
        setDB_LeftSidebar_MenuItem_MenuContainerAA_ShadowBlur(
          Design_Top_Navigation !== undefined &&
            Design_Top_Navigation.top_navigation_container_schattenrauschen !==
              undefined
            ? Design_Top_Navigation.top_navigation_container_schattenrauschen
            : "6"
        );
        setDB_LeftSidebar_MenuItem_MenuContainerAA_Verteilung(
          Design_Top_Navigation !== undefined &&
            Design_Top_Navigation.top_navigation_container_navigationsverteilung !==
              undefined
            ? Design_Top_Navigation.top_navigation_container_navigationsverteilung
            : "4"
        );
        setDB_LeftSidebar_MenuItem_MenuContainerAA_paddingLeft(
          Design_Top_Navigation !== undefined &&
            Design_Top_Navigation.top_navigation_container_innerer_abstand_links !==
              undefined
            ? Design_Top_Navigation.top_navigation_container_innerer_abstand_links
            : "0"
        );
        setDB_LeftSidebar_MenuItem_MenuContainerAA_paddingRight(
          Design_Top_Navigation !== undefined &&
            Design_Top_Navigation.top_navigation_container_innerer_abstand_rechts !==
              undefined
            ? Design_Top_Navigation.top_navigation_container_innerer_abstand_rechts
            : "0"
        );
        setDB_LeftSidebar_MenuItem_MenuContainerAA_paddingTop(
          Design_Top_Navigation !== undefined &&
            Design_Top_Navigation.top_navigation_container_innerer_abstand_oben !==
              undefined
            ? Design_Top_Navigation.top_navigation_container_innerer_abstand_oben
            : "7"
        );
        setDB_LeftSidebar_MenuItem_MenuContainerAA_paddingBottom(
          Design_Top_Navigation !== undefined &&
            Design_Top_Navigation.top_navigation_container_innerer_abstand_unten !==
              undefined
            ? Design_Top_Navigation.top_navigation_container_innerer_abstand_unten
            : "7"
        );
        setDB_LeftSidebar_MenuContainerAA_Hover_BackgroundColor(
          Design_Top_Navigation !== undefined &&
            Design_Top_Navigation.top_navigation_container_hover_backgroundcolor !==
              undefined
            ? Design_Top_Navigation.top_navigation_container_hover_backgroundcolor
            : "rgba(0,0,0,0.3)"
        );
        setDB_LeftSidebar_MenuContainerAA_Hover_BackgroundColorNotEncoded(
          Design_Top_Navigation !== undefined &&
            Design_Top_Navigation.top_navigation_container_hover_backgroundcolor_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Top_Navigation.top_navigation_container_hover_backgroundcolor_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0.3 }
        );
        setDB_LeftSidebar_MenuItem_MenuContainerAA_Hover_ShadowColor(
          Design_Top_Navigation !== undefined &&
            Design_Top_Navigation.top_navigation_container_hover_schattenfarbe !==
              undefined
            ? Design_Top_Navigation.top_navigation_container_hover_schattenfarbe
            : "rgba(0,0,0,0.5)"
        );
        setDB_LeftSidebar_MenuItem_MenuContainerAA_Hover_ShadowColorNotEncoded(
          Design_Top_Navigation !== undefined &&
            Design_Top_Navigation.top_navigation_container_hover_schattenfarbe_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Top_Navigation.top_navigation_container_hover_schattenfarbe_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0.5 }
        );
        setDB_LeftSidebar_MenuItem_MenuContainerAA_Hover_ShadowHorizontal(
          Design_Top_Navigation !== undefined &&
            Design_Top_Navigation.top_navigation_container_hover_schattenposition_horizontal !==
              undefined
            ? Design_Top_Navigation.top_navigation_container_hover_schattenposition_horizontal
            : "3"
        );
        setDB_LeftSidebar_MenuItem_MenuContainerAA_Hover_ShadowVertical(
          Design_Top_Navigation !== undefined &&
            Design_Top_Navigation.top_navigation_container_hover_schattenposition_vertikal !==
              undefined
            ? Design_Top_Navigation.top_navigation_container_hover_schattenposition_vertikal
            : "3"
        );
        setDB_LeftSidebar_MenuItem_MenuContainerAA_Hover_ShadowBlur(
          Design_Top_Navigation !== undefined &&
            Design_Top_Navigation.top_navigation_container_hover_schattenrauschen !==
              undefined
            ? Design_Top_Navigation.top_navigation_container_hover_schattenrauschen
            : "2"
        );
        setDB_LeftSidebar_MenuItem_MenuContainerAA_Hover_paddingLeft(
          Design_Top_Navigation !== undefined &&
            Design_Top_Navigation.top_navigation_container_hover_innerer_abstand_links !==
              undefined
            ? Design_Top_Navigation.top_navigation_container_hover_innerer_abstand_links
            : "0"
        );
        setDB_LeftSidebar_MenuItem_MenuContainerAA_Hover_paddingRight(
          Design_Top_Navigation !== undefined &&
            Design_Top_Navigation.top_navigation_container_hover_innerer_abstand_rechts !==
              undefined
            ? Design_Top_Navigation.top_navigation_container_hover_innerer_abstand_rechts
            : "0"
        );
        setDB_LeftSidebar_MenuItem_MenuContainerAA_Hover_paddingTop(
          Design_Top_Navigation !== undefined &&
            Design_Top_Navigation.top_navigation_container_hover_innerer_abstand_oben !==
              undefined
            ? Design_Top_Navigation.top_navigation_container_hover_innerer_abstand_oben
            : "7"
        );
        setDB_LeftSidebar_MenuItem_MenuContainerAA_Hover_paddingBottom(
          Design_Top_Navigation !== undefined &&
            Design_Top_Navigation.top_navigation_container_hover_innerer_abstand_unten !==
              undefined
            ? Design_Top_Navigation.top_navigation_container_hover_innerer_abstand_unten
            : "7"
        );

        setDB_LeftSidebar_MenuContainerAA_BorderColorLeft(
          Design_Top_Navigation !== undefined &&
            Design_Top_Navigation.top_navigation_container_border_color_left !==
              undefined
            ? Design_Top_Navigation.top_navigation_container_border_color_left
            : "rgba(0,0,0,0.3)"
        );
        setDB_LeftSidebar_MenuContainerAA_BorderColorLeftNotEncoded(
          Design_Top_Navigation !== undefined &&
            Design_Top_Navigation.top_navigation_container_border_color_left_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Top_Navigation.top_navigation_container_border_color_left_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0.3 }
        );
        setDB_LeftSidebar_MenuContainerAA_BorderColorRight(
          Design_Top_Navigation !== undefined &&
            Design_Top_Navigation.top_navigation_container_border_color_right !==
              undefined
            ? Design_Top_Navigation.top_navigation_container_border_color_right
            : "rgba(0,0,150,0.3)"
        );
        setDB_LeftSidebar_MenuContainerAA_BorderColorRightNotEncoded(
          Design_Top_Navigation !== undefined &&
            Design_Top_Navigation.top_navigation_container_border_color_right_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Top_Navigation.top_navigation_container_border_color_right_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0.3 }
        );
        setDB_LeftSidebar_MenuContainerAA_BorderColorTop(
          Design_Top_Navigation !== undefined &&
            Design_Top_Navigation.top_navigation_container_border_color_top !==
              undefined
            ? Design_Top_Navigation.top_navigation_container_border_color_top
            : "rgba(0,0,0,0.3)"
        );
        setDB_LeftSidebar_MenuContainerAA_BorderColorTopNotEncoded(
          Design_Top_Navigation !== undefined &&
            Design_Top_Navigation.top_navigation_container_border_color_top_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Top_Navigation.top_navigation_container_border_color_top_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0.3 }
        );
        setDB_LeftSidebar_MenuContainerAA_BorderColorBottom(
          Design_Top_Navigation !== undefined &&
            Design_Top_Navigation.top_navigation_container_border_color_bottom !==
              undefined
            ? Design_Top_Navigation.top_navigation_container_border_color_bottom
            : "rgba(0,0,0,0.3)"
        );
        setDB_LeftSidebar_MenuContainerAA_BorderColorBottomNotEncoded(
          Design_Top_Navigation !== undefined &&
            Design_Top_Navigation.top_navigation_container_border_color_bottom_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Top_Navigation.top_navigation_container_border_color_bottom_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0.3 }
        );

        setDB_LeftSidebar_MenuContainerAA_BorderLeft(
          Design_Top_Navigation !== undefined &&
            Design_Top_Navigation.top_navigation_container_border_pixel_left !==
              undefined
            ? Design_Top_Navigation.top_navigation_container_border_pixel_left
            : "1"
        );
        setDB_LeftSidebar_MenuContainerAA_BorderRight(
          Design_Top_Navigation !== undefined &&
            Design_Top_Navigation.top_navigation_container_border_pixel_right !==
              undefined
            ? Design_Top_Navigation.top_navigation_container_border_pixel_right
            : "1"
        );
        setDB_LeftSidebar_MenuContainerAA_BorderTop(
          Design_Top_Navigation !== undefined &&
            Design_Top_Navigation.top_navigation_container_border_pixel_top !==
              undefined
            ? Design_Top_Navigation.top_navigation_container_border_pixel_top
            : "1"
        );
        setDB_LeftSidebar_MenuContainerAA_BorderBottom(
          Design_Top_Navigation !== undefined &&
            Design_Top_Navigation.top_navigation_container_border_pixel_bottom !==
              undefined
            ? Design_Top_Navigation.top_navigation_container_border_pixel_bottom
            : "1"
        );

        setDB_LeftSidebar_MenuItem_MenuContainerAA_BorderTopLeftRadius(
          Design_Top_Navigation !== undefined &&
            Design_Top_Navigation.top_navigation_container_border_radius_top_left !==
              undefined
            ? Design_Top_Navigation.top_navigation_container_border_radius_top_left
            : "7"
        );
        setDB_LeftSidebar_MenuItem_MenuContainerAA_BorderTopRightRadius(
          Design_Top_Navigation !== undefined &&
            Design_Top_Navigation.top_navigation_container_border_radius_top_right !==
              undefined
            ? Design_Top_Navigation.top_navigation_container_border_radius_top_right
            : "7"
        );
        setDB_LeftSidebar_MenuItem_MenuContainerAA_BorderBottomLeftRadius(
          Design_Top_Navigation !== undefined &&
            Design_Top_Navigation.top_navigation_container_border_radius_bottom_left !==
              undefined
            ? Design_Top_Navigation.top_navigation_container_border_radius_bottom_left
            : "7"
        );
        setDB_LeftSidebar_MenuItem_MenuContainerAA_BorderBottomRightRadius(
          Design_Top_Navigation !== undefined &&
            Design_Top_Navigation.top_navigation_container_border_radius_bottom_right !==
              undefined
            ? Design_Top_Navigation.top_navigation_container_border_radius_bottom_right
            : "7"
        );

        setDB_LeftSidebar_MenuContainerAA_BorderStyleLeft(
          Design_Top_Navigation !== undefined &&
            Design_Top_Navigation.top_navigation_container_border_style_left !==
              undefined
            ? Design_Top_Navigation.top_navigation_container_border_style_left
            : "solid"
        );
        setDB_LeftSidebar_MenuContainerAA_BorderStyleRight(
          Design_Top_Navigation !== undefined &&
            Design_Top_Navigation.top_navigation_container_border_style_right !==
              undefined
            ? Design_Top_Navigation.top_navigation_container_border_style_right
            : "solid"
        );
        setDB_LeftSidebar_MenuContainerAA_BorderStyleTop(
          Design_Top_Navigation !== undefined &&
            Design_Top_Navigation.top_navigation_container_border_style_top !==
              undefined
            ? Design_Top_Navigation.top_navigation_container_border_style_top
            : "solid"
        );
        setDB_LeftSidebar_MenuContainerAA_BorderStyleBottom(
          Design_Top_Navigation !== undefined &&
            Design_Top_Navigation.top_navigation_container_border_style_bottom !==
              undefined
            ? Design_Top_Navigation.top_navigation_container_border_style_bottom
            : "solid"
        );

        setDB_LeftSidebar_MenuContainerAA_Hover_BorderLeft(
          Design_Top_Navigation !== undefined &&
            Design_Top_Navigation.top_navigation_container_hover_border_pixel_left !==
              undefined
            ? Design_Top_Navigation.top_navigation_container_hover_border_pixel_left
            : "1"
        );
        setDB_LeftSidebar_MenuContainerAA_Hover_BorderRight(
          Design_Top_Navigation !== undefined &&
            Design_Top_Navigation.top_navigation_container_hover_border_pixel_right !==
              undefined
            ? Design_Top_Navigation.top_navigation_container_hover_border_pixel_right
            : "1"
        );
        setDB_LeftSidebar_MenuContainerAA_Hover_BorderTop(
          Design_Top_Navigation !== undefined &&
            Design_Top_Navigation.top_navigation_container_hover_border_pixel_top !==
              undefined
            ? Design_Top_Navigation.top_navigation_container_hover_border_pixel_top
            : "1"
        );
        setDB_LeftSidebar_MenuContainerAA_Hover_BorderBottom(
          Design_Top_Navigation !== undefined &&
            Design_Top_Navigation.top_navigation_container_hover_border_pixel_bottom !==
              undefined
            ? Design_Top_Navigation.top_navigation_container_hover_border_pixel_bottom
            : "1"
        );

        setDB_LeftSidebar_MenuContainerAA_Hover_BorderColorLeft(
          Design_Top_Navigation !== undefined &&
            Design_Top_Navigation.top_navigation_container_hover_border_color_left !==
              undefined
            ? Design_Top_Navigation.top_navigation_container_hover_border_color_left
            : "rgba(0,0,0,0.4)"
        );
        setDB_LeftSidebar_MenuContainerAA_Hover_BorderColorLeftNotEncoded(
          Design_Top_Navigation !== undefined &&
            Design_Top_Navigation.top_navigation_container_hover_border_color_left_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Top_Navigation.top_navigation_container_hover_border_color_left_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0.4 }
        );
        setDB_LeftSidebar_MenuContainerAA_Hover_BorderColorRight(
          Design_Top_Navigation !== undefined &&
            Design_Top_Navigation.top_navigation_container_hover_border_color_right !==
              undefined
            ? Design_Top_Navigation.top_navigation_container_hover_border_color_right
            : "rgba(0,0,0,0.4)"
        );
        setDB_LeftSidebar_MenuContainerAA_Hover_BorderColorRightNotEncoded(
          Design_Top_Navigation !== undefined &&
            Design_Top_Navigation.top_navigation_container_hover_border_color_right_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Top_Navigation.top_navigation_container_hover_border_color_right_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0.4 }
        );
        setDB_LeftSidebar_MenuContainerAA_Hover_BorderColorTop(
          Design_Top_Navigation !== undefined &&
            Design_Top_Navigation.top_navigation_container_hover_border_color_top !==
              undefined
            ? Design_Top_Navigation.top_navigation_container_hover_border_color_top
            : "rgba(0,0,0,0.4)"
        );
        setDB_LeftSidebar_MenuContainerAA_Hover_BorderColorTopNotEncoded(
          Design_Top_Navigation !== undefined &&
            Design_Top_Navigation.top_navigation_container_hover_border_color_top_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Top_Navigation.top_navigation_container_hover_border_color_top_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0.4 }
        );
        setDB_LeftSidebar_MenuContainerAA_Hover_BorderColorBottom(
          Design_Top_Navigation !== undefined &&
            Design_Top_Navigation.top_navigation_container_hover_border_color_bottom !==
              undefined
            ? Design_Top_Navigation.top_navigation_container_hover_border_color_bottom
            : "rgba(0,0,0,0.4)"
        );
        setDB_LeftSidebar_MenuContainerAA_Hover_BorderColorBottomNotEncoded(
          Design_Top_Navigation !== undefined &&
            Design_Top_Navigation.top_navigation_container_hover_border_color_bottom_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Top_Navigation.top_navigation_container_hover_border_color_bottom_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0.4 }
        );

        setDB_LeftSidebar_MenuItem_MenuContainerAA_Hover_BorderTopLeftRadius(
          Design_Top_Navigation !== undefined &&
            Design_Top_Navigation.top_navigation_container_hover_border_radius_top_left !==
              undefined
            ? Design_Top_Navigation.top_navigation_container_hover_border_radius_top_left
            : "7"
        );
        setDB_LeftSidebar_MenuItem_MenuContainerAA_Hover_BorderTopRightRadius(
          Design_Top_Navigation !== undefined &&
            Design_Top_Navigation.top_navigation_container_hover_border_radius_top_right !==
              undefined
            ? Design_Top_Navigation.top_navigation_container_hover_border_radius_top_right
            : "7"
        );
        setDB_LeftSidebar_MenuItem_MenuContainerAA_Hover_BorderBottomLeftRadius(
          Design_Top_Navigation !== undefined &&
            Design_Top_Navigation.top_navigation_container_hover_border_radius_bottom_left !==
              undefined
            ? Design_Top_Navigation.top_navigation_container_hover_border_radius_bottom_left
            : "7"
        );
        setDB_LeftSidebar_MenuItem_MenuContainerAA_Hover_BorderBottomRightRadius(
          Design_Top_Navigation !== undefined &&
            Design_Top_Navigation.top_navigation_container_hover_border_radius_bottom_right !==
              undefined
            ? Design_Top_Navigation.top_navigation_container_hover_border_radius_bottom_right
            : "7"
        );

        setDB_LeftSidebar_MenuContainerAA_Hover_BorderStyleLeft(
          Design_Top_Navigation !== undefined &&
            Design_Top_Navigation.top_navigation_container_hover_border_style_left !==
              undefined
            ? Design_Top_Navigation.top_navigation_container_hover_border_style_left
            : "solid"
        );
        setDB_LeftSidebar_MenuContainerAA_Hover_BorderStyleRight(
          Design_Top_Navigation !== undefined &&
            Design_Top_Navigation.top_navigation_container_hover_border_style_right !==
              undefined
            ? Design_Top_Navigation.top_navigation_container_hover_border_style_right
            : "solid"
        );
        setDB_LeftSidebar_MenuContainerAA_Hover_BorderStyleTop(
          Design_Top_Navigation !== undefined &&
            Design_Top_Navigation.top_navigation_container_hover_border_style_top !==
              undefined
            ? Design_Top_Navigation.top_navigation_container_hover_border_style_top
            : "solid"
        );
        setDB_LeftSidebar_MenuContainerAA_Hover_BorderStyleBottom(
          Design_Top_Navigation !== undefined &&
            Design_Top_Navigation.top_navigation_container_hover_border_style_bottom !==
              undefined
            ? Design_Top_Navigation.top_navigation_container_hover_border_style_bottom
            : "solid"
        );

        /// XXXYYYZZZ START

        setColumnNumber(
          Design_Sortierung !== undefined &&
            Design_Sortierung.design_columnNumber !== undefined
            ? Design_Sortierung.design_columnNumber
            : "4"
        );

        setMarqueeIsVisibleTop(
          Design_Sortierung !== undefined &&
            Design_Sortierung.design_MarqueeIsVisibleTop !== undefined
            ? Boolean(parseInt(Design_Sortierung.design_MarqueeIsVisibleTop))
            : true
        );
        setTopMainCategoriesVisible(
          Design_Sortierung !== undefined &&
            Design_Sortierung.design_topMainCategoriesVisible !== undefined
            ? Boolean(
                parseInt(Design_Sortierung.design_topMainCategoriesVisible)
              )
            : true
        );
        setBottomMainCategoriesVisible(
          Design_Sortierung !== undefined &&
            Design_Sortierung.design_bottomMainCategoriesVisible !== undefined
            ? Boolean(
                parseInt(Design_Sortierung.design_bottomMainCategoriesVisible)
              )
            : false
        );
        setLeftMainCategoriesVisible(
          Design_Sortierung !== undefined &&
            Design_Sortierung.design_leftMainCategoriesVisible !== undefined
            ? Boolean(
                parseInt(Design_Sortierung.design_leftMainCategoriesVisible)
              )
            : false
        );
        setRightMainCategoriesVisible(
          Design_Sortierung !== undefined &&
            Design_Sortierung.design_rightMainCategoriesVisible !== undefined
            ? Boolean(
                parseInt(Design_Sortierung.design_rightMainCategoriesVisible)
              )
            : false
        );

        setTopBarIsVisibleTop(
          Design_Sortierung !== undefined &&
            Design_Sortierung.design_TopBarIsVisibleTop !== undefined
            ? Boolean(parseInt(Design_Sortierung.design_TopBarIsVisibleTop))
            : true
        );
        setTopBarIsVisibleBottom(
          Design_Sortierung !== undefined &&
            Design_Sortierung.design_TopBarIsVisibleBottom !== undefined
            ? Boolean(parseInt(Design_Sortierung.design_TopBarIsVisibleBottom))
            : false
        );
        setTopBarIsVisibleLeft(
          Design_Sortierung !== undefined &&
            Design_Sortierung.design_TopBarIsVisibleLeft !== undefined
            ? Boolean(parseInt(Design_Sortierung.design_TopBarIsVisibleLeft))
            : false
        );
        setTopBarIsVisibleRight(
          Design_Sortierung !== undefined &&
            Design_Sortierung.design_TopBarIsVisibleRight !== undefined
            ? Boolean(parseInt(Design_Sortierung.design_TopBarIsVisibleRight))
            : false
        );

        setTopBarFilterProductOne(
          Design_Sortierung !== undefined &&
            Design_Sortierung.design_TopBarFilterProductOne !== undefined
            ? Boolean(parseInt(Design_Sortierung.design_TopBarFilterProductOne))
            : false
        );
        setTopBarFilterProductTwo(
          Design_Sortierung !== undefined &&
            Design_Sortierung.design_TopBarFilterProductTwo !== undefined
            ? Boolean(parseInt(Design_Sortierung.design_TopBarFilterProductTwo))
            : false
        );
        setTopBarFilterProductThree(
          Design_Sortierung !== undefined &&
            Design_Sortierung.design_TopBarFilterProductThree !== undefined
            ? Boolean(
                parseInt(Design_Sortierung.design_TopBarFilterProductThree)
              )
            : true
        );
        setTopBarFilterProductFour(
          Design_Sortierung !== undefined &&
            Design_Sortierung.design_TopBarFilterProductFour !== undefined
            ? Boolean(
                parseInt(Design_Sortierung.design_TopBarFilterProductFour)
              )
            : false
        );

        setProductFilterIsVisibleTop(
          Design_Sortierung !== undefined &&
            Design_Sortierung.design_ProductFilterIsVisibleTop !== undefined
            ? Boolean(
                parseInt(Design_Sortierung.design_ProductFilterIsVisibleTop)
              )
            : true
        );
        setProductFilterIsVisibleBottom(
          Design_Sortierung !== undefined &&
            Design_Sortierung.design_ProductFilterIsVisibleBottom !== undefined
            ? Boolean(
                parseInt(Design_Sortierung.design_ProductFilterIsVisibleBottom)
              )
            : false
        );
        setProductFilterIsVisibleLeft(
          Design_Sortierung !== undefined &&
            Design_Sortierung.design_ProductFilterIsVisibleLeft !== undefined
            ? Boolean(
                parseInt(Design_Sortierung.design_ProductFilterIsVisibleLeft)
              )
            : false
        );
        setProductFilterIsVisibleRight(
          Design_Sortierung !== undefined &&
            Design_Sortierung.design_ProductFilterIsVisibleRight !== undefined
            ? Boolean(
                parseInt(Design_Sortierung.design_ProductFilterIsVisibleRight)
              )
            : false
        );

        setLeftCategoriesVisible(
          Design_Sortierung !== undefined &&
            Design_Sortierung.design_leftCategoriesVisible !== undefined
            ? Boolean(parseInt(Design_Sortierung.design_leftCategoriesVisible))
            : true
        );
        setSpecialRightCategoriesVisible(
          Design_Sortierung !== undefined &&
            Design_Sortierung.design_specialRightCategoriesVisible !== undefined
            ? Boolean(
                parseInt(Design_Sortierung.design_specialRightCategoriesVisible)
              )
            : false
        );
        setSpecialLeftFiltersVisible(
          Design_Sortierung !== undefined &&
            Design_Sortierung.design_specialLeftFiltersVisible !== undefined
            ? Boolean(
                parseInt(Design_Sortierung.design_specialLeftFiltersVisible)
              )
            : false
        );
        setRightFiltersVisible(
          Design_Sortierung !== undefined &&
            Design_Sortierung.design_rightFiltersVisible !== undefined
            ? Boolean(parseInt(Design_Sortierung.design_rightFiltersVisible))
            : true
        );
        setSpecialLeftSocialsVisible(
          Design_Sortierung !== undefined &&
            Design_Sortierung.design_specialLeftSocialsVisible !== undefined
            ? Boolean(
                parseInt(Design_Sortierung.design_specialLeftSocialsVisible)
              )
            : false
        );
        setRightSocialsVisible(
          Design_Sortierung !== undefined &&
            Design_Sortierung.design_rightSocialsVisible !== undefined
            ? Boolean(parseInt(Design_Sortierung.design_rightSocialsVisible))
            : true
        );

        setDB_LeftSidebar_MenuContainerXYZ_BackgroundColor(
          Design_Sortierung !== undefined &&
            Design_Sortierung.static_nav_container_backgroundcolor !== undefined
            ? Design_Sortierung.static_nav_container_backgroundcolor
            : "rgba(0,0,0,0.3)"
        );
        setDB_LeftSidebar_MenuContainerXYZ_BackgroundColorNotEncoded(
          Design_Sortierung !== undefined &&
            Design_Sortierung.static_nav_container_backgroundcolor_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Sortierung.static_nav_container_backgroundcolor_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0.3 }
        );

        setDB_LeftSidebar_MenuItem_MenuContainerXYZ_ShadowColor(
          Design_Sortierung !== undefined &&
            Design_Sortierung.static_nav_container_schattenfarbe !== undefined
            ? Design_Sortierung.static_nav_container_schattenfarbe
            : "rgba(0,0,0,0.5)"
        );
        setDB_LeftSidebar_MenuItem_MenuContainerXYZ_ShadowColorNotEncoded(
          Design_Sortierung !== undefined &&
            Design_Sortierung.static_nav_container_schattenfarbe_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Sortierung.static_nav_container_schattenfarbe_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0.5 }
        );
        setDB_LeftSidebar_MenuItem_MenuContainerXYZ_ShadowHorizontal(
          Design_Sortierung !== undefined &&
            Design_Sortierung.static_nav_container_schattenposition_horizontal !==
              undefined
            ? Design_Sortierung.static_nav_container_schattenposition_horizontal
            : "3"
        );
        setDB_LeftSidebar_MenuItem_MenuContainerXYZ_ShadowVertical(
          Design_Sortierung !== undefined &&
            Design_Sortierung.static_nav_container_schattenposition_vertikal !==
              undefined
            ? Design_Sortierung.static_nav_container_schattenposition_vertikal
            : "3"
        );
        setDB_LeftSidebar_MenuItem_MenuContainerXYZ_ShadowBlur(
          Design_Sortierung !== undefined &&
            Design_Sortierung.static_nav_container_schattenrauschen !==
              undefined
            ? Design_Sortierung.static_nav_container_schattenrauschen
            : "6"
        );

        setDB_LeftSidebar_MenuItem_MenuContainerXYZ_paddingLeft(
          Design_Sortierung !== undefined &&
            Design_Sortierung.static_nav_container_innerer_abstand_links !==
              undefined
            ? Design_Sortierung.static_nav_container_innerer_abstand_links
            : "0"
        );
        setDB_LeftSidebar_MenuItem_MenuContainerXYZ_paddingRight(
          Design_Sortierung !== undefined &&
            Design_Sortierung.static_nav_container_innerer_abstand_rechts !==
              undefined
            ? Design_Sortierung.static_nav_container_innerer_abstand_rechts
            : "0"
        );
        setDB_LeftSidebar_MenuItem_MenuContainerXYZ_paddingTop(
          Design_Sortierung !== undefined &&
            Design_Sortierung.static_nav_container_innerer_abstand_oben !==
              undefined
            ? Design_Sortierung.static_nav_container_innerer_abstand_oben
            : "7"
        );
        setDB_LeftSidebar_MenuItem_MenuContainerXYZ_paddingBottom(
          Design_Sortierung !== undefined &&
            Design_Sortierung.static_nav_container_innerer_abstand_unten !==
              undefined
            ? Design_Sortierung.static_nav_container_innerer_abstand_unten
            : "7"
        );
        setDB_LeftSidebar_MenuContainerXYZ_Hover_BackgroundColor(
          Design_Sortierung !== undefined &&
            Design_Sortierung.static_nav_container_hover_backgroundcolor !==
              undefined
            ? Design_Sortierung.static_nav_container_hover_backgroundcolor
            : "rgba(0,0,0,0.3)"
        );
        setDB_LeftSidebar_MenuContainerXYZ_Hover_BackgroundColorNotEncoded(
          Design_Sortierung !== undefined &&
            Design_Sortierung.static_nav_container_hover_backgroundcolor_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Sortierung.static_nav_container_hover_backgroundcolor_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0.3 }
        );
        setDB_LeftSidebar_MenuItem_MenuContainerXYZ_Hover_ShadowColor(
          Design_Sortierung !== undefined &&
            Design_Sortierung.static_nav_container_hover_schattenfarbe !==
              undefined
            ? Design_Sortierung.static_nav_container_hover_schattenfarbe
            : "rgba(0,0,0,0.5)"
        );
        setDB_LeftSidebar_MenuItem_MenuContainerXYZ_Hover_ShadowColorNotEncoded(
          Design_Sortierung !== undefined &&
            Design_Sortierung.static_nav_container_hover_schattenfarbe_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Sortierung.static_nav_container_hover_schattenfarbe_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0.5 }
        );
        setDB_LeftSidebar_MenuItem_MenuContainerXYZ_Hover_ShadowHorizontal(
          Design_Sortierung !== undefined &&
            Design_Sortierung.static_nav_container_hover_schattenposition_horizontal !==
              undefined
            ? Design_Sortierung.static_nav_container_hover_schattenposition_horizontal
            : "3"
        );
        setDB_LeftSidebar_MenuItem_MenuContainerXYZ_Hover_ShadowVertical(
          Design_Sortierung !== undefined &&
            Design_Sortierung.static_nav_container_hover_schattenposition_vertikal !==
              undefined
            ? Design_Sortierung.static_nav_container_hover_schattenposition_vertikal
            : "3"
        );
        setDB_LeftSidebar_MenuItem_MenuContainerXYZ_Hover_ShadowBlur(
          Design_Sortierung !== undefined &&
            Design_Sortierung.static_nav_container_hover_schattenrauschen !==
              undefined
            ? Design_Sortierung.static_nav_container_hover_schattenrauschen
            : "2"
        );
        setDB_LeftSidebar_MenuItem_MenuContainerXYZ_Hover_paddingLeft(
          Design_Sortierung !== undefined &&
            Design_Sortierung.static_nav_container_hover_innerer_abstand_links !==
              undefined
            ? Design_Sortierung.static_nav_container_hover_innerer_abstand_links
            : "0"
        );
        setDB_LeftSidebar_MenuItem_MenuContainerXYZ_Hover_paddingRight(
          Design_Sortierung !== undefined &&
            Design_Sortierung.static_nav_container_hover_innerer_abstand_rechts !==
              undefined
            ? Design_Sortierung.static_nav_container_hover_innerer_abstand_rechts
            : "0"
        );
        setDB_LeftSidebar_MenuItem_MenuContainerXYZ_Hover_paddingTop(
          Design_Sortierung !== undefined &&
            Design_Sortierung.static_nav_container_hover_innerer_abstand_oben !==
              undefined
            ? Design_Sortierung.static_nav_container_hover_innerer_abstand_oben
            : "7"
        );
        setDB_LeftSidebar_MenuItem_MenuContainerXYZ_Hover_paddingBottom(
          Design_Sortierung !== undefined &&
            Design_Sortierung.static_nav_container_hover_innerer_abstand_unten !==
              undefined
            ? Design_Sortierung.static_nav_container_hover_innerer_abstand_unten
            : "7"
        );

        setDB_LeftSidebar_MenuContainerXYZ_BorderColorLeft(
          Design_Sortierung !== undefined &&
            Design_Sortierung.static_nav_container_border_color_left !==
              undefined
            ? Design_Sortierung.static_nav_container_border_color_left
            : "rgba(0,0,0,0.3)"
        );
        setDB_LeftSidebar_MenuContainerXYZ_BorderColorLeftNotEncoded(
          Design_Sortierung !== undefined &&
            Design_Sortierung.static_nav_container_border_color_left_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Sortierung.static_nav_container_border_color_left_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0.3 }
        );
        setDB_LeftSidebar_MenuContainerXYZ_BorderColorRight(
          Design_Sortierung !== undefined &&
            Design_Sortierung.static_nav_container_border_color_right !==
              undefined
            ? Design_Sortierung.static_nav_container_border_color_right
            : "rgba(0,0,150,0.3)"
        );
        setDB_LeftSidebar_MenuContainerXYZ_BorderColorRightNotEncoded(
          Design_Sortierung !== undefined &&
            Design_Sortierung.static_nav_container_border_color_right_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Sortierung.static_nav_container_border_color_right_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0.3 }
        );
        setDB_LeftSidebar_MenuContainerXYZ_BorderColorTop(
          Design_Sortierung !== undefined &&
            Design_Sortierung.static_nav_container_border_color_top !==
              undefined
            ? Design_Sortierung.static_nav_container_border_color_top
            : "rgba(0,0,0,0.3)"
        );
        setDB_LeftSidebar_MenuContainerXYZ_BorderColorTopNotEncoded(
          Design_Sortierung !== undefined &&
            Design_Sortierung.static_nav_container_border_color_top_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Sortierung.static_nav_container_border_color_top_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0.3 }
        );
        setDB_LeftSidebar_MenuContainerXYZ_BorderColorBottom(
          Design_Sortierung !== undefined &&
            Design_Sortierung.static_nav_container_border_color_bottom !==
              undefined
            ? Design_Sortierung.static_nav_container_border_color_bottom
            : "rgba(0,0,0,0.3)"
        );
        setDB_LeftSidebar_MenuContainerXYZ_BorderColorBottomNotEncoded(
          Design_Sortierung !== undefined &&
            Design_Sortierung.static_nav_container_border_color_bottom_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Sortierung.static_nav_container_border_color_bottom_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0.3 }
        );

        setDB_LeftSidebar_MenuContainerXYZ_BorderLeft(
          Design_Sortierung !== undefined &&
            Design_Sortierung.static_nav_container_border_pixel_left !==
              undefined
            ? Design_Sortierung.static_nav_container_border_pixel_left
            : "1"
        );
        setDB_LeftSidebar_MenuContainerXYZ_BorderRight(
          Design_Sortierung !== undefined &&
            Design_Sortierung.static_nav_container_border_pixel_right !==
              undefined
            ? Design_Sortierung.static_nav_container_border_pixel_right
            : "1"
        );
        setDB_LeftSidebar_MenuContainerXYZ_BorderTop(
          Design_Sortierung !== undefined &&
            Design_Sortierung.static_nav_container_border_pixel_top !==
              undefined
            ? Design_Sortierung.static_nav_container_border_pixel_top
            : "1"
        );
        setDB_LeftSidebar_MenuContainerXYZ_BorderBottom(
          Design_Sortierung !== undefined &&
            Design_Sortierung.static_nav_container_border_pixel_bottom !==
              undefined
            ? Design_Sortierung.static_nav_container_border_pixel_bottom
            : "1"
        );

        setDB_LeftSidebar_MenuItem_MenuContainerXYZ_BorderTopLeftRadius(
          Design_Sortierung !== undefined &&
            Design_Sortierung.static_nav_container_border_radius_top_left !==
              undefined
            ? Design_Sortierung.static_nav_container_border_radius_top_left
            : "7"
        );
        setDB_LeftSidebar_MenuItem_MenuContainerXYZ_BorderTopRightRadius(
          Design_Sortierung !== undefined &&
            Design_Sortierung.static_nav_container_border_radius_top_right !==
              undefined
            ? Design_Sortierung.static_nav_container_border_radius_top_right
            : "7"
        );
        setDB_LeftSidebar_MenuItem_MenuContainerXYZ_BorderBottomLeftRadius(
          Design_Sortierung !== undefined &&
            Design_Sortierung.static_nav_container_border_radius_bottom_left !==
              undefined
            ? Design_Sortierung.static_nav_container_border_radius_bottom_left
            : "7"
        );
        setDB_LeftSidebar_MenuItem_MenuContainerXYZ_BorderBottomRightRadius(
          Design_Sortierung !== undefined &&
            Design_Sortierung.static_nav_container_border_radius_bottom_right !==
              undefined
            ? Design_Sortierung.static_nav_container_border_radius_bottom_right
            : "7"
        );

        setDB_LeftSidebar_MenuContainerXYZ_BorderStyleLeft(
          Design_Sortierung !== undefined &&
            Design_Sortierung.static_nav_container_border_style_left !==
              undefined
            ? Design_Sortierung.static_nav_container_border_style_left
            : "solid"
        );
        setDB_LeftSidebar_MenuContainerXYZ_BorderStyleRight(
          Design_Sortierung !== undefined &&
            Design_Sortierung.static_nav_container_border_style_right !==
              undefined
            ? Design_Sortierung.static_nav_container_border_style_right
            : "solid"
        );
        setDB_LeftSidebar_MenuContainerXYZ_BorderStyleTop(
          Design_Sortierung !== undefined &&
            Design_Sortierung.static_nav_container_border_style_top !==
              undefined
            ? Design_Sortierung.static_nav_container_border_style_top
            : "solid"
        );
        setDB_LeftSidebar_MenuContainerXYZ_BorderStyleBottom(
          Design_Sortierung !== undefined &&
            Design_Sortierung.static_nav_container_border_style_bottom !==
              undefined
            ? Design_Sortierung.static_nav_container_border_style_bottom
            : "solid"
        );

        setDB_LeftSidebar_MenuContainerXYZ_Hover_BorderLeft(
          Design_Sortierung !== undefined &&
            Design_Sortierung.static_nav_container_hover_border_pixel_left !==
              undefined
            ? Design_Sortierung.static_nav_container_hover_border_pixel_left
            : "1"
        );
        setDB_LeftSidebar_MenuContainerXYZ_Hover_BorderRight(
          Design_Sortierung !== undefined &&
            Design_Sortierung.static_nav_container_hover_border_pixel_right !==
              undefined
            ? Design_Sortierung.static_nav_container_hover_border_pixel_right
            : "1"
        );
        setDB_LeftSidebar_MenuContainerXYZ_Hover_BorderTop(
          Design_Sortierung !== undefined &&
            Design_Sortierung.static_nav_container_hover_border_pixel_top !==
              undefined
            ? Design_Sortierung.static_nav_container_hover_border_pixel_top
            : "1"
        );
        setDB_LeftSidebar_MenuContainerXYZ_Hover_BorderBottom(
          Design_Sortierung !== undefined &&
            Design_Sortierung.static_nav_container_hover_border_pixel_bottom !==
              undefined
            ? Design_Sortierung.static_nav_container_hover_border_pixel_bottom
            : "1"
        );

        setDB_LeftSidebar_MenuContainerXYZ_Hover_BorderColorLeft(
          Design_Sortierung !== undefined &&
            Design_Sortierung.static_nav_container_hover_border_color_left !==
              undefined
            ? Design_Sortierung.static_nav_container_hover_border_color_left
            : "rgba(0,0,0,0.4)"
        );
        setDB_LeftSidebar_MenuContainerXYZ_Hover_BorderColorLeftNotEncoded(
          Design_Sortierung !== undefined &&
            Design_Sortierung.static_nav_container_hover_border_color_left_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Sortierung.static_nav_container_hover_border_color_left_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0.4 }
        );
        setDB_LeftSidebar_MenuContainerXYZ_Hover_BorderColorRight(
          Design_Sortierung !== undefined &&
            Design_Sortierung.static_nav_container_hover_border_color_right !==
              undefined
            ? Design_Sortierung.static_nav_container_hover_border_color_right
            : "rgba(0,0,0,0.4)"
        );
        setDB_LeftSidebar_MenuContainerXYZ_Hover_BorderColorRightNotEncoded(
          Design_Sortierung !== undefined &&
            Design_Sortierung.static_nav_container_hover_border_color_right_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Sortierung.static_nav_container_hover_border_color_right_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0.4 }
        );
        setDB_LeftSidebar_MenuContainerXYZ_Hover_BorderColorTop(
          Design_Sortierung !== undefined &&
            Design_Sortierung.static_nav_container_hover_border_color_top !==
              undefined
            ? Design_Sortierung.static_nav_container_hover_border_color_top
            : "rgba(0,0,0,0.4)"
        );
        setDB_LeftSidebar_MenuContainerXYZ_Hover_BorderColorTopNotEncoded(
          Design_Sortierung !== undefined &&
            Design_Sortierung.static_nav_container_hover_border_color_top_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Sortierung.static_nav_container_hover_border_color_top_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0.4 }
        );
        setDB_LeftSidebar_MenuContainerXYZ_Hover_BorderColorBottom(
          Design_Sortierung !== undefined &&
            Design_Sortierung.static_nav_container_hover_border_color_bottom !==
              undefined
            ? Design_Sortierung.static_nav_container_hover_border_color_bottom
            : "rgba(0,0,0,0.4)"
        );
        setDB_LeftSidebar_MenuContainerXYZ_Hover_BorderColorBottomNotEncoded(
          Design_Sortierung !== undefined &&
            Design_Sortierung.static_nav_container_hover_border_color_bottom_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Sortierung.static_nav_container_hover_border_color_bottom_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0.4 }
        );

        setDB_LeftSidebar_MenuItem_MenuContainerXYZ_Hover_BorderTopLeftRadius(
          Design_Sortierung !== undefined &&
            Design_Sortierung.static_nav_container_hover_border_radius_top_left !==
              undefined
            ? Design_Sortierung.static_nav_container_hover_border_radius_top_left
            : "7"
        );
        setDB_LeftSidebar_MenuItem_MenuContainerXYZ_Hover_BorderTopRightRadius(
          Design_Sortierung !== undefined &&
            Design_Sortierung.static_nav_container_hover_border_radius_top_right !==
              undefined
            ? Design_Sortierung.static_nav_container_hover_border_radius_top_right
            : "7"
        );
        setDB_LeftSidebar_MenuItem_MenuContainerXYZ_Hover_BorderBottomLeftRadius(
          Design_Sortierung !== undefined &&
            Design_Sortierung.static_nav_container_hover_border_radius_bottom_left !==
              undefined
            ? Design_Sortierung.static_nav_container_hover_border_radius_bottom_left
            : "7"
        );
        setDB_LeftSidebar_MenuItem_MenuContainerXYZ_Hover_BorderBottomRightRadius(
          Design_Sortierung !== undefined &&
            Design_Sortierung.static_nav_container_hover_border_radius_bottom_right !==
              undefined
            ? Design_Sortierung.static_nav_container_hover_border_radius_bottom_right
            : "7"
        );

        setDB_LeftSidebar_MenuContainerXYZ_Hover_BorderStyleLeft(
          Design_Sortierung !== undefined &&
            Design_Sortierung.static_nav_container_hover_border_style_left !==
              undefined
            ? Design_Sortierung.static_nav_container_hover_border_style_left
            : "solid"
        );
        setDB_LeftSidebar_MenuContainerXYZ_Hover_BorderStyleRight(
          Design_Sortierung !== undefined &&
            Design_Sortierung.static_nav_container_hover_border_style_right !==
              undefined
            ? Design_Sortierung.static_nav_container_hover_border_style_right
            : "solid"
        );
        setDB_LeftSidebar_MenuContainerXYZ_Hover_BorderStyleTop(
          Design_Sortierung !== undefined &&
            Design_Sortierung.static_nav_container_hover_border_style_top !==
              undefined
            ? Design_Sortierung.static_nav_container_hover_border_style_top
            : "solid"
        );
        setDB_LeftSidebar_MenuContainerXYZ_Hover_BorderStyleBottom(
          Design_Sortierung !== undefined &&
            Design_Sortierung.static_nav_container_hover_border_style_bottom !==
              undefined
            ? Design_Sortierung.static_nav_container_hover_border_style_bottom
            : "solid"
        );
        /// XXXYYYZZZ END

        setDB_LeftSidebar_MenuItem_HauptmenuTopAA_FontColor(
          Design_Top_Navigation !== undefined &&
            Design_Top_Navigation.top_navigation_hauptkategorien_schriftfarbe !==
              undefined
            ? Design_Top_Navigation.top_navigation_hauptkategorien_schriftfarbe
            : "rgba(255,255,255,1)"
        );
        setDB_LeftSidebar_MenuItem_HauptmenuTopAA_FontColorNotEncoded(
          Design_Top_Navigation !== undefined &&
            Design_Top_Navigation.top_navigation_hauptkategorien_schriftfarbe_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Top_Navigation.top_navigation_hauptkategorien_schriftfarbe_not_encoded
              )
            : { r: 255, g: 255, b: 255, a: 1 }
        );
        setDB_LeftSidebar_MenuItem_HauptmenuTopAA_BackgroundColor(
          Design_Top_Navigation !== undefined &&
            Design_Top_Navigation.top_navigation_hauptkategorien_hintergrundfarbe !==
              undefined
            ? Design_Top_Navigation.top_navigation_hauptkategorien_hintergrundfarbe
            : "rgba(0,0,0,0.3)"
        );
        setDB_LeftSidebar_MenuItem_HauptmenuTopAA_BackgroundColorNotEncoded(
          Design_Top_Navigation !== undefined &&
            Design_Top_Navigation.top_navigation_hauptkategorien_hintergrundfarbe_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Top_Navigation.top_navigation_hauptkategorien_hintergrundfarbe_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0.3 }
        );
        setDB_LeftSidebar_MenuItem_HauptmenuTopAA_ShadowColor(
          Design_Top_Navigation !== undefined &&
            Design_Top_Navigation.top_navigation_hauptkategorien_schattenfarbe !==
              undefined
            ? Design_Top_Navigation.top_navigation_hauptkategorien_schattenfarbe
            : "rgba(0,0,0,1)"
        );
        setDB_LeftSidebar_MenuItem_HauptmenuTopAA_ShadowColorNotEncoded(
          Design_Top_Navigation !== undefined &&
            Design_Top_Navigation.top_navigation_hauptkategorien_schattenfarbe_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Top_Navigation.top_navigation_hauptkategorien_schattenfarbe_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 1 }
        );
        setDB_LeftSidebar_MenuItem_HauptmenuTopAA_ShadowHorizontal(
          Design_Top_Navigation !== undefined &&
            Design_Top_Navigation.top_navigation_hauptkategorien_schattenposition_horizontal !==
              undefined
            ? Design_Top_Navigation.top_navigation_hauptkategorien_schattenposition_horizontal
            : "2"
        );
        setDB_LeftSidebar_MenuItem_HauptmenuTopAA_ShadowVertical(
          Design_Top_Navigation !== undefined &&
            Design_Top_Navigation.top_navigation_hauptkategorien_schattenposition_vertikal !==
              undefined
            ? Design_Top_Navigation.top_navigation_hauptkategorien_schattenposition_vertikal
            : "2"
        );
        setDB_LeftSidebar_MenuItem_HauptmenuTopAA_ShadowBlur(
          Design_Top_Navigation !== undefined &&
            Design_Top_Navigation.top_navigation_hauptkategorien_schattenrauschen !==
              undefined
            ? Design_Top_Navigation.top_navigation_hauptkategorien_schattenrauschen
            : "4"
        );
        setDB_LeftSidebar_MenuItem_HauptmenuTopAA_FontSize(
          Design_Top_Navigation !== undefined &&
            Design_Top_Navigation.top_navigation_hauptkategorien_schriftgroesse !==
              undefined
            ? Design_Top_Navigation.top_navigation_hauptkategorien_schriftgroesse
            : "20"
        );
        setDB_LeftSidebar_MenuItem_HauptmenuTopAA_FontWeight(
          Design_Top_Navigation !== undefined &&
            Design_Top_Navigation.top_navigation_hauptkategorien_schriftgewicht !==
              undefined
            ? Design_Top_Navigation.top_navigation_hauptkategorien_schriftgewicht
            : "1000"
        );
        setDB_LeftSidebar_MenuItem_HauptmenuTopAA_letterSpacing(
          Design_Top_Navigation !== undefined &&
            Design_Top_Navigation.top_navigation_hauptkategorien_buchstabenabstand !==
              undefined
            ? Design_Top_Navigation.top_navigation_hauptkategorien_buchstabenabstand
            : "2"
        );
        setDB_LeftSidebar_MenuItem_HauptmenuTopAA_TextDecoration(
          Design_Top_Navigation !== undefined &&
            Design_Top_Navigation.top_navigation_hauptkategorien_text_dekoration !==
              undefined
            ? Design_Top_Navigation.top_navigation_hauptkategorien_text_dekoration
            : "none"
        );
        setDB_LeftSidebar_MenuItem_HauptmenuTopAA_TextDecorationStyle(
          Design_Top_Navigation !== undefined &&
            Design_Top_Navigation.top_navigation_hauptkategorien_text_dekoration_stil !==
              undefined
            ? Design_Top_Navigation.top_navigation_hauptkategorien_text_dekoration_stil
            : "none"
        );
        setDB_LeftSidebar_MenuItem_HauptmenuTopAA_marginLeftRight(
          Design_Top_Navigation !== undefined &&
            Design_Top_Navigation.top_navigation_hauptkategorien_aussen_abstand_links_rechts !==
              undefined
            ? Design_Top_Navigation.top_navigation_hauptkategorien_aussen_abstand_links_rechts
            : "10"
        );
        setDB_LeftSidebar_MenuItem_HauptmenuTopAA_marginTopBottom(
          Design_Top_Navigation !== undefined &&
            Design_Top_Navigation.top_navigation_hauptkategorien_aussen_abstand_oben_unten !==
              undefined
            ? Design_Top_Navigation.top_navigation_hauptkategorien_aussen_abstand_oben_unten
            : "0"
        );
        setDB_LeftSidebar_MenuItem_HauptmenuTopAA_paddingLeftRight(
          Design_Top_Navigation !== undefined &&
            Design_Top_Navigation.top_navigation_hauptkategorien_innerer_abstand_links_rechts !==
              undefined
            ? Design_Top_Navigation.top_navigation_hauptkategorien_innerer_abstand_links_rechts
            : "0"
        );
        setDB_LeftSidebar_MenuItem_HauptmenuTopAA_paddingTopBottom(
          Design_Top_Navigation !== undefined &&
            Design_Top_Navigation.top_navigation_hauptkategorien_innerer_abstand_oben_unten !==
              undefined
            ? Design_Top_Navigation.top_navigation_hauptkategorien_innerer_abstand_oben_unten
            : "4"
        );
        setDB_LeftSidebar_MenuItem_HauptmenuTopAA_TextAlign(
          Design_Top_Navigation !== undefined &&
            Design_Top_Navigation.top_navigation_hauptkategorien_textausrichtung !==
              undefined
            ? Design_Top_Navigation.top_navigation_hauptkategorien_textausrichtung
            : "center"
        );

        setDB_fontFace_hauptMenueTopAA_FontFamilyName(
          Design_Top_Navigation !== undefined &&
            Design_Top_Navigation.top_navigation_hauptkategorien_fontfamily !==
              undefined
            ? Design_Top_Navigation.top_navigation_hauptkategorien_fontfamily
            : "Lato"
        );

        setDB_fontFace_hauptMenueTopAA_FontFamilySrc(
          Design_Top_Navigation !== undefined &&
            Design_Top_Navigation.top_navigation_hauptkategorien_fontfamilysrc !==
              undefined
            ? Design_Top_Navigation.top_navigation_hauptkategorien_fontfamilysrc
            : "/assets/Lato/Lato-Regular.ttf"
        );

        setDB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_FontColor(
          Design_Top_Navigation !== undefined &&
            Design_Top_Navigation.top_nav_hauptkategorien_hover_schriftfarbe !==
              undefined
            ? Design_Top_Navigation.top_nav_hauptkategorien_hover_schriftfarbe
            : "rgba(255,255,255,1)"
        );
        setDB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_FontColorNotEncoded(
          Design_Top_Navigation !== undefined &&
            Design_Top_Navigation.top_nav_hauptkategorien_hover_schriftfarbe_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Top_Navigation.top_nav_hauptkategorien_hover_schriftfarbe_not_encoded
              )
            : { r: 255, g: 255, b: 255, a: 1 }
        );
        setDB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BackgroundColor(
          Design_Top_Navigation !== undefined &&
            Design_Top_Navigation.top_nav_hauptkategorien_hover_hintergrundfarbe !==
              undefined
            ? Design_Top_Navigation.top_nav_hauptkategorien_hover_hintergrundfarbe
            : "rgba(0,0,0,0.4)"
        );

        setDB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BackgroundColorNotEncoded(
          Design_Top_Navigation !== undefined &&
            Design_Top_Navigation.top_nav_hauptkategorien_hover_hintergrundfarbe_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Top_Navigation.top_nav_hauptkategorien_hover_hintergrundfarbe_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0.4 }
        );
        setDB_LeftSidebar_MenuItem_HauptmenuTopAA_ShadowColor_Hover(
          Design_Top_Navigation !== undefined &&
            Design_Top_Navigation.top_nav_hauptkategorien_hover_schattenfarbe !==
              undefined
            ? Design_Top_Navigation.top_nav_hauptkategorien_hover_schattenfarbe
            : "rgba(0,0,0,1)"
        );
        setDB_LeftSidebar_MenuItem_HauptmenuTopAA_ShadowColorNotEncoded_Hover(
          Design_Top_Navigation !== undefined &&
            Design_Top_Navigation.top_nav_hauptkategorien_hover_schattenfarbe_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Top_Navigation.top_nav_hauptkategorien_hover_schattenfarbe_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 1 }
        );
        setDB_LeftSidebar_MenuItem_HauptmenuTopAA_ShadowHorizontal_Hover(
          Design_Top_Navigation !== undefined &&
            Design_Top_Navigation.top_nav_hauptkategorien_hover_schattenposition_horizontal !==
              undefined
            ? Design_Top_Navigation.top_nav_hauptkategorien_hover_schattenposition_horizontal
            : "2"
        );
        setDB_LeftSidebar_MenuItem_HauptmenuTopAA_ShadowVertical_Hover(
          Design_Top_Navigation !== undefined &&
            Design_Top_Navigation.top_nav_hauptkategorien_hover_schattenposition_vertikal !==
              undefined
            ? Design_Top_Navigation.top_nav_hauptkategorien_hover_schattenposition_vertikal
            : "2"
        );
        setDB_LeftSidebar_MenuItem_HauptmenuTopAA_ShadowBlur_Hover(
          Design_Top_Navigation !== undefined &&
            Design_Top_Navigation.top_nav_hauptkategorien_hover_schattenrauschen !==
              undefined
            ? Design_Top_Navigation.top_nav_hauptkategorien_hover_schattenrauschen
            : "3"
        );
        setDB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_FontSize(
          Design_Top_Navigation !== undefined &&
            Design_Top_Navigation.top_nav_hauptkategorien_hover_schriftgroesse !==
              undefined
            ? Design_Top_Navigation.top_nav_hauptkategorien_hover_schriftgroesse
            : "20"
        );
        setDB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_FontWeight(
          Design_Top_Navigation !== undefined &&
            Design_Top_Navigation.top_nav_hauptkategorien_hover_schriftgewicht !==
              undefined
            ? Design_Top_Navigation.top_nav_hauptkategorien_hover_schriftgewicht
            : "1000"
        );
        setDB_LeftSidebar_MenuItem_HauptmenuTopAA_letterSpacing_Hover(
          Design_Top_Navigation !== undefined &&
            Design_Top_Navigation.top_nav_hauptkategorien_hover_buchstabenabstand !==
              undefined
            ? Design_Top_Navigation.top_nav_hauptkategorien_hover_buchstabenabstand
            : "2"
        );
        setDB_LeftSidebar_MenuItem_HauptmenuTopAA_TextDecoration_Hover(
          Design_Top_Navigation !== undefined &&
            Design_Top_Navigation.top_nav_hauptkategorien_hover_text_dekoration !==
              undefined
            ? Design_Top_Navigation.top_nav_hauptkategorien_hover_text_dekoration
            : "none"
        );
        setDB_LeftSidebar_MenuItem_HauptmenuTopAA_TextDecorationStyle_Hover(
          Design_Top_Navigation !== undefined &&
            Design_Top_Navigation.top_nav_hauptkategorien_hover_text_dekoration_stil !==
              undefined
            ? Design_Top_Navigation.top_nav_hauptkategorien_hover_text_dekoration_stil
            : "none"
        );
        setDB_LeftSidebar_MenuItem_HauptmenuTopAA_marginLeftRight_Hover(
          Design_Top_Navigation !== undefined &&
            Design_Top_Navigation.top_nav_hauptkategorien_hover_aussen_abstand_links_rechts !==
              undefined
            ? Design_Top_Navigation.top_nav_hauptkategorien_hover_aussen_abstand_links_rechts
            : "10"
        );
        setDB_LeftSidebar_MenuItem_HauptmenuTopAA_marginTopBottom_Hover(
          Design_Top_Navigation !== undefined &&
            Design_Top_Navigation.top_nav_hauptkategorien_hover_aussen_abstand_oben_unten !==
              undefined
            ? Design_Top_Navigation.top_nav_hauptkategorien_hover_aussen_abstand_oben_unten
            : "0"
        );
        setDB_LeftSidebar_MenuItem_HauptmenuTopAA_paddingLeftRight_Hover(
          Design_Top_Navigation !== undefined &&
            Design_Top_Navigation.top_nav_hauptkategorien_hover_innerer_abstand_links_rechts !==
              undefined
            ? Design_Top_Navigation.top_nav_hauptkategorien_hover_innerer_abstand_links_rechts
            : "0"
        );
        setDB_LeftSidebar_MenuItem_HauptmenuTopAA_paddingTopBottom_Hover(
          Design_Top_Navigation !== undefined &&
            Design_Top_Navigation.top_nav_hauptkategorien_hover_innerer_abstand_oben_unten !==
              undefined
            ? Design_Top_Navigation.top_nav_hauptkategorien_hover_innerer_abstand_oben_unten
            : "4"
        );
        setDB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_TextAlign(
          Design_Top_Navigation !== undefined &&
            Design_Top_Navigation.top_nav_hauptkategorien_hover_textausrichtung !==
              undefined
            ? Design_Top_Navigation.top_nav_hauptkategorien_hover_textausrichtung
            : "center"
        );

        setDB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderColorLeft(
          Design_Top_Navigation !== undefined &&
            Design_Top_Navigation.top_nav_hauptkategorien_border_color_left !==
              undefined
            ? Design_Top_Navigation.top_nav_hauptkategorien_border_color_left
            : "rgba(0,0,0,0.2)"
        );
        setDB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderColorLeftNotEncoded(
          Design_Top_Navigation !== undefined &&
            Design_Top_Navigation.top_nav_hauptkategorien_border_color_left_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Top_Navigation.top_nav_hauptkategorien_border_color_left_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0.2 }
        );

        setDB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderColorRight(
          Design_Top_Navigation !== undefined &&
            Design_Top_Navigation.top_nav_hauptkategorien_border_color_right !==
              undefined
            ? Design_Top_Navigation.top_nav_hauptkategorien_border_color_right
            : "rgba(0,0,0,0.2)"
        );

        setDB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderColorRightNotEncoded(
          Design_Top_Navigation !== undefined &&
            Design_Top_Navigation.top_nav_hauptkategorien_border_color_right_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Top_Navigation.top_nav_hauptkategorien_border_color_right_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0 }
        );

        setDB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderColorTop(
          Design_Top_Navigation !== undefined &&
            Design_Top_Navigation.top_nav_hauptkategorien_border_color_top !==
              undefined
            ? Design_Top_Navigation.top_nav_hauptkategorien_border_color_top
            : "rgba(0,0,0,0.2)"
        );

        setDB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderColorTopNotEncoded(
          Design_Top_Navigation !== undefined &&
            Design_Top_Navigation.top_nav_hauptkategorien_border_color_top_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Top_Navigation.top_nav_hauptkategorien_border_color_top_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0.2 }
        );

        setDB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderColorBottom(
          Design_Top_Navigation !== undefined &&
            Design_Top_Navigation.top_nav_hauptkategorien_border_color_bottom !==
              undefined
            ? Design_Top_Navigation.top_nav_hauptkategorien_border_color_bottom
            : "rgba(0,0,0,0.2)"
        );

        setDB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderColorBottomNotEncoded(
          Design_Top_Navigation !== undefined &&
            Design_Top_Navigation.top_nav_hauptkategorien_border_color_bottom_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Top_Navigation.top_nav_hauptkategorien_border_color_bottom_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0.2 }
        );

        setDB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderLeft(
          Design_Top_Navigation !== undefined &&
            Design_Top_Navigation.top_nav_hauptkategorien_border_pixel_left !==
              undefined
            ? Design_Top_Navigation.top_nav_hauptkategorien_border_pixel_left
            : "1"
        );

        setDB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderRight(
          Design_Top_Navigation !== undefined &&
            Design_Top_Navigation.top_nav_hauptkategorien_border_pixel_right !==
              undefined
            ? Design_Top_Navigation.top_nav_hauptkategorien_border_pixel_right
            : "1"
        );

        setDB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderTop(
          Design_Top_Navigation !== undefined &&
            Design_Top_Navigation.top_nav_hauptkategorien_border_pixel_top !==
              undefined
            ? Design_Top_Navigation.top_nav_hauptkategorien_border_pixel_top
            : "1"
        );

        setDB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderBottom(
          Design_Top_Navigation !== undefined &&
            Design_Top_Navigation.top_nav_hauptkategorien_border_pixel_bottom !==
              undefined
            ? Design_Top_Navigation.top_nav_hauptkategorien_border_pixel_bottom
            : "1"
        );

        setDB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderTopLeftRadius(
          Design_Top_Navigation !== undefined &&
            Design_Top_Navigation.top_nav_hauptkategorien_border_radius_top_left !==
              undefined
            ? Design_Top_Navigation.top_nav_hauptkategorien_border_radius_top_left
            : "7"
        );

        setDB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderTopRightRadius(
          Design_Top_Navigation !== undefined &&
            Design_Top_Navigation.top_nav_hauptkategorien_border_radius_top_right !==
              undefined
            ? Design_Top_Navigation.top_nav_hauptkategorien_border_radius_top_right
            : "7"
        );

        setDB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderBottomLeftRadius(
          Design_Top_Navigation !== undefined &&
            Design_Top_Navigation.top_nav_hauptkategorien_border_radius_bottom_left !==
              undefined
            ? Design_Top_Navigation.top_nav_hauptkategorien_border_radius_bottom_left
            : "7"
        );

        setDB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderBottomRightRadius(
          Design_Top_Navigation !== undefined &&
            Design_Top_Navigation.top_nav_hauptkategorien_border_radius_bottom_right !==
              undefined
            ? Design_Top_Navigation.top_nav_hauptkategorien_border_radius_bottom_right
            : "7"
        );

        setDB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderStyleLeft(
          Design_Top_Navigation !== undefined &&
            Design_Top_Navigation.top_nav_hauptkategorien_border_style_left !==
              undefined
            ? Design_Top_Navigation.top_nav_hauptkategorien_border_style_left
            : "solid"
        );

        setDB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderStyleRight(
          Design_Top_Navigation !== undefined &&
            Design_Top_Navigation.top_nav_hauptkategorien_border_style_right !==
              undefined
            ? Design_Top_Navigation.top_nav_hauptkategorien_border_style_right
            : "solid"
        );

        setDB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderStyleTop(
          Design_Top_Navigation !== undefined &&
            Design_Top_Navigation.top_nav_hauptkategorien_border_style_top !==
              undefined
            ? Design_Top_Navigation.top_nav_hauptkategorien_border_style_top
            : "solid"
        );

        setDB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderStyleBottom(
          Design_Top_Navigation !== undefined &&
            Design_Top_Navigation.top_nav_hauptkategorien_border_style_bottom !==
              undefined
            ? Design_Top_Navigation.top_nav_hauptkategorien_border_style_bottom
            : "solid"
        );

        setDB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderColorLeft(
          Design_Top_Navigation !== undefined &&
            Design_Top_Navigation.top_nav_hauptkategorien_border_hover_color_left !==
              undefined
            ? Design_Top_Navigation.top_nav_hauptkategorien_border_hover_color_left
            : "rgba(0,0,0,0.3)"
        );
        setDB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderColorLeftNotEncoded(
          Design_Top_Navigation !== undefined &&
            Design_Top_Navigation.top_nav_hauptkategorien_border_hover_color_left_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Top_Navigation.top_nav_hauptkategorien_border_hover_color_left_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0.3 }
        );

        setDB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderColorRight(
          Design_Top_Navigation !== undefined &&
            Design_Top_Navigation.top_nav_hauptkategorien_border_hover_color_right !==
              undefined
            ? Design_Top_Navigation.top_nav_hauptkategorien_border_hover_color_right
            : "rgba(0,0,0,0.3)"
        );
        setDB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderColorRightNotEncoded(
          Design_Top_Navigation !== undefined &&
            Design_Top_Navigation.top_nav_hauptkategorien_border_hover_color_right_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Top_Navigation.top_nav_hauptkategorien_border_hover_color_right_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0.3 }
        );

        setDB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderColorTop(
          Design_Top_Navigation !== undefined &&
            Design_Top_Navigation.top_nav_hauptkategorien_border_hover_color_top !==
              undefined
            ? Design_Top_Navigation.top_nav_hauptkategorien_border_hover_color_top
            : "rgba(0,0,0,0.3)"
        );
        setDB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderColorTopNotEncoded(
          Design_Top_Navigation !== undefined &&
            Design_Top_Navigation.top_nav_hauptkategorien_border_hover_color_top_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Top_Navigation.top_nav_hauptkategorien_border_hover_color_top_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0.3 }
        );

        setDB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderColorBottom(
          Design_Top_Navigation !== undefined &&
            Design_Top_Navigation.top_nav_hauptkategorien_border_hover_color_bottom !==
              undefined
            ? Design_Top_Navigation.top_nav_hauptkategorien_border_hover_color_bottom
            : "rgba(0,0,0,0.3)"
        );
        setDB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderColorBottomNotEncoded(
          Design_Top_Navigation !== undefined &&
            Design_Top_Navigation.top_nav_hauptkategorien_border_hover_color_bottom_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Top_Navigation.top_nav_hauptkategorien_border_hover_color_bottom_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0.3 }
        );

        setDB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderLeft(
          Design_Top_Navigation !== undefined &&
            Design_Top_Navigation.top_nav_hauptkategorien_border_hover_pixel_left !==
              undefined
            ? Design_Top_Navigation.top_nav_hauptkategorien_border_hover_pixel_left
            : "1"
        );

        setDB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderRight(
          Design_Top_Navigation !== undefined &&
            Design_Top_Navigation.top_nav_hauptkategorien_border_hover_pixel_right !==
              undefined
            ? Design_Top_Navigation.top_nav_hauptkategorien_border_hover_pixel_right
            : "1"
        );

        setDB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderTop(
          Design_Top_Navigation !== undefined &&
            Design_Top_Navigation.top_nav_hauptkategorien_border_hover_pixel_top !==
              undefined
            ? Design_Top_Navigation.top_nav_hauptkategorien_border_hover_pixel_top
            : "1"
        );

        setDB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderBottom(
          Design_Top_Navigation !== undefined &&
            Design_Top_Navigation.top_nav_hauptkategorien_border_hover_pixel_bottom !==
              undefined
            ? Design_Top_Navigation.top_nav_hauptkategorien_border_hover_pixel_bottom
            : "1"
        );

        setDB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderTopLeftRadius(
          Design_Top_Navigation !== undefined &&
            Design_Top_Navigation.top_nav_hauptkategorien_border_hover_radius_top_left !==
              undefined
            ? Design_Top_Navigation.top_nav_hauptkategorien_border_hover_radius_top_left
            : "7"
        );

        setDB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderTopRightRadius(
          Design_Top_Navigation !== undefined &&
            Design_Top_Navigation.top_nav_hauptkategorien_border_hover_radius_top_right !==
              undefined
            ? Design_Top_Navigation.top_nav_hauptkategorien_border_hover_radius_top_right
            : "7"
        );

        setDB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderBottomLeftRadius(
          Design_Top_Navigation !== undefined &&
            Design_Top_Navigation.top_nav_hauptkategorien_border_hover_radius_bottom_left !==
              undefined
            ? Design_Top_Navigation.top_nav_hauptkategorien_border_hover_radius_bottom_left
            : "7"
        );

        setDB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderBottomRightRadius(
          Design_Top_Navigation !== undefined &&
            Design_Top_Navigation.top_nav_hauptkategorien_border_hover_radius_bottom_right !==
              undefined
            ? Design_Top_Navigation.top_nav_hauptkategorien_border_hover_radius_bottom_right
            : "7"
        );

        setDB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderStyleLeft(
          Design_Top_Navigation !== undefined &&
            Design_Top_Navigation.top_nav_hauptkategorien_border_hover_style_left !==
              undefined
            ? Design_Top_Navigation.top_nav_hauptkategorien_border_hover_style_left
            : "solid"
        );

        setDB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderStyleRight(
          Design_Top_Navigation !== undefined &&
            Design_Top_Navigation.top_nav_hauptkategorien_border_hover_style_right !==
              undefined
            ? Design_Top_Navigation.top_nav_hauptkategorien_border_hover_style_right
            : "solid"
        );

        setDB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderStyleTop(
          Design_Top_Navigation !== undefined &&
            Design_Top_Navigation.top_nav_hauptkategorien_border_hover_style_top !==
              undefined
            ? Design_Top_Navigation.top_nav_hauptkategorien_border_hover_style_top
            : "solid"
        );

        setDB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderStyleBottom(
          Design_Top_Navigation !== undefined &&
            Design_Top_Navigation.top_nav_hauptkategorien_border_hover_style_bottom !==
              undefined
            ? Design_Top_Navigation.top_nav_hauptkategorien_border_hover_style_bottom
            : "solid"
        );

        setDB_LeftSidebar_MenuItem_HauptmenuTop_FontColor(
          Design_Top_Navigation !== undefined &&
            Design_Top_Navigation.top_navigation_static_schriftfarbe !==
              undefined
            ? Design_Top_Navigation.top_navigation_static_schriftfarbe
            : "rgba(255,255,255,1)"
        );
        setDB_LeftSidebar_MenuItem_HauptmenuTop_FontColorNotEncoded(
          Design_Top_Navigation !== undefined &&
            Design_Top_Navigation.top_navigation_static_schriftfarbe_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Top_Navigation.top_navigation_static_schriftfarbe_not_encoded
              )
            : { r: 255, g: 255, b: 255, a: 1 }
        );

        setDB_LeftSidebar_MenuItem_HauptmenuTop_BackgroundColor(
          Design_Top_Navigation !== undefined &&
            Design_Top_Navigation.top_navigation_static_hintergrundfarbe !==
              undefined
            ? Design_Top_Navigation.top_navigation_static_hintergrundfarbe
            : "rgba(0,0,0,0.3)"
        );
        setDB_LeftSidebar_MenuItem_HauptmenuTop_BackgroundColorNotEncoded(
          Design_Top_Navigation !== undefined &&
            Design_Top_Navigation.top_navigation_static_hintergrundfarbe_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Top_Navigation.top_navigation_static_hintergrundfarbe_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0.3 }
        );

        setDB_LeftSidebar_MenuItem_HauptmenuTop_ShadowColor(
          Design_Top_Navigation !== undefined &&
            Design_Top_Navigation.top_navigation_static_schattenfarbe !==
              undefined
            ? Design_Top_Navigation.top_navigation_static_schattenfarbe
            : "rgba(0,0,0,0.3)"
        );
        setDB_LeftSidebar_MenuItem_HauptmenuTop_ShadowColorNotEncoded(
          Design_Top_Navigation !== undefined &&
            Design_Top_Navigation.top_navigation_static_schattenfarbe_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Top_Navigation.top_navigation_static_schattenfarbe_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0.3 }
        );

        setDB_LeftSidebar_MenuItem_HauptmenuTop_ShadowHorizontal(
          Design_Top_Navigation !== undefined &&
            Design_Top_Navigation.top_navigation_static_schattenposition_horizontal !==
              undefined
            ? Design_Top_Navigation.top_navigation_static_schattenposition_horizontal
            : "2"
        );

        setDB_LeftSidebar_MenuItem_HauptmenuTop_ShadowVertical(
          Design_Top_Navigation !== undefined &&
            Design_Top_Navigation.top_navigation_static_schattenposition_vertikal !==
              undefined
            ? Design_Top_Navigation.top_navigation_static_schattenposition_vertikal
            : "2"
        );

        setDB_LeftSidebar_MenuItem_HauptmenuTop_ShadowBlur(
          Design_Top_Navigation !== undefined &&
            Design_Top_Navigation.top_navigation_static_schattenrauschen !==
              undefined
            ? Design_Top_Navigation.top_navigation_static_schattenrauschen
            : "2"
        );

        setDB_LeftSidebar_MenuItem_HauptmenuTop_FontSize(
          Design_Top_Navigation !== undefined &&
            Design_Top_Navigation.top_navigation_static_schriftgroesse !==
              undefined
            ? Design_Top_Navigation.top_navigation_static_schriftgroesse
            : "18"
        );

        setDB_LeftSidebar_MenuItem_HauptmenuTop_FontWeight(
          Design_Top_Navigation !== undefined &&
            Design_Top_Navigation.top_navigation_static_schriftgewicht !==
              undefined
            ? Design_Top_Navigation.top_navigation_static_schriftgewicht
            : "100"
        );

        setDB_LeftSidebar_MenuItem_HauptmenuTop_letterSpacing(
          Design_Top_Navigation !== undefined &&
            Design_Top_Navigation.top_navigation_static_buchstabenabstand !==
              undefined
            ? Design_Top_Navigation.top_navigation_static_buchstabenabstand
            : "0"
        );

        setDB_LeftSidebar_MenuItem_HauptmenuTop_TextDecoration(
          Design_Top_Navigation !== undefined &&
            Design_Top_Navigation.top_navigation_static_text_dekoration !==
              undefined
            ? Design_Top_Navigation.top_navigation_static_text_dekoration
            : "underline"
        );

        setDB_LeftSidebar_MenuItem_HauptmenuTop_TextDecorationStyle(
          Design_Top_Navigation !== undefined &&
            Design_Top_Navigation.top_navigation_static_text_dekoration_stil !==
              undefined
            ? Design_Top_Navigation.top_navigation_static_text_dekoration_stil
            : "solid"
        );

        setDB_LeftSidebar_MenuItem_HauptmenuTop_marginLeftRight(
          Design_Top_Navigation !== undefined &&
            Design_Top_Navigation.top_navigation_static_aussen_abstand_links_rechts !==
              undefined
            ? Design_Top_Navigation.top_navigation_static_aussen_abstand_links_rechts
            : "4"
        );

        setDB_LeftSidebar_MenuItem_HauptmenuTop_marginTopBottom(
          Design_Top_Navigation !== undefined &&
            Design_Top_Navigation.top_navigation_static_aussen_abstand_oben_unten !==
              undefined
            ? Design_Top_Navigation.top_navigation_static_aussen_abstand_oben_unten
            : "0"
        );

        setDB_LeftSidebar_MenuItem_HauptmenuTop_paddingLeftRight(
          Design_Top_Navigation !== undefined &&
            Design_Top_Navigation.top_navigation_static_innerer_abstand_links_rechts !==
              undefined
            ? Design_Top_Navigation.top_navigation_static_innerer_abstand_links_rechts
            : "15"
        );

        setDB_LeftSidebar_MenuItem_HauptmenuTop_paddingTopBottom(
          Design_Top_Navigation !== undefined &&
            Design_Top_Navigation.top_navigation_static_innerer_abstand_oben_unten !==
              undefined
            ? Design_Top_Navigation.top_navigation_static_innerer_abstand_oben_unten
            : "3"
        );

        setDB_LeftSidebar_MenuItem_HauptmenuTop_TextAlign(
          Design_Top_Navigation !== undefined &&
            Design_Top_Navigation.top_navigation_static_textausrichtung !==
              undefined
            ? Design_Top_Navigation.top_navigation_static_textausrichtung
            : "center"
        );

        setDB_fontFace_hauptMenueStatic_FontFamilyName(
          Design_Top_Navigation !== undefined &&
            Design_Top_Navigation.top_navigation_static_fontfamilyname !==
              undefined
            ? Design_Top_Navigation.top_navigation_static_fontfamilyname
            : "Lato"
        );

        setDB_fontFace_hauptMenueStatic_FontFamilySrc(
          Design_Top_Navigation !== undefined &&
            Design_Top_Navigation.top_navigation_static_fontfamilysrc !==
              undefined
            ? Design_Top_Navigation.top_navigation_static_fontfamilysrc
            : "/assets/Lato/Lato-Regular.ttf"
        );

        setDB_LeftSidebar_MenuItem_HauptmenuTop_Hover_FontColor(
          Design_Top_Navigation !== undefined &&
            Design_Top_Navigation.top_navigation_static_hover_schriftfarbe !==
              undefined
            ? Design_Top_Navigation.top_navigation_static_hover_schriftfarbe
            : "rgba(255,255,255,1)"
        );
        setDB_LeftSidebar_MenuItem_HauptmenuTop_Hover_FontColorNotEncoded(
          Design_Top_Navigation !== undefined &&
            Design_Top_Navigation.top_navigation_static_hover_schriftfarbe_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Top_Navigation.top_navigation_static_hover_schriftfarbe_not_encoded
              )
            : { r: 255, g: 255, b: 255, a: 1 }
        );

        setDB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BackgroundColor(
          Design_Top_Navigation !== undefined &&
            Design_Top_Navigation.top_navigation_static_hover_hintergrundfarbe !==
              undefined
            ? Design_Top_Navigation.top_navigation_static_hover_hintergrundfarbe
            : "rgba(0,0,0,0.4)"
        );
        setDB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BackgroundColorNotEncoded(
          Design_Top_Navigation !== undefined &&
            Design_Top_Navigation.top_navigation_static_hover_hintergrundfarbe_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Top_Navigation.top_navigation_static_hover_hintergrundfarbe_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0.4 }
        );

        setDB_LeftSidebar_MenuItem_HauptmenuTop_ShadowColor_Hover(
          Design_Top_Navigation !== undefined &&
            Design_Top_Navigation.top_navigation_static_hover_schattenfarbe !==
              undefined
            ? Design_Top_Navigation.top_navigation_static_hover_schattenfarbe
            : "rgba(0,0,0,0.3)"
        );
        setDB_LeftSidebar_MenuItem_HauptmenuTop_ShadowColorNotEncoded_Hover(
          Design_Top_Navigation !== undefined &&
            Design_Top_Navigation.top_navigation_static_hover_schattenfarbe_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Top_Navigation.top_navigation_static_hover_schattenfarbe_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0.3 }
        );

        setDB_LeftSidebar_MenuItem_HauptmenuTop_ShadowHorizontal_Hover(
          Design_Top_Navigation !== undefined &&
            Design_Top_Navigation.top_navigation_static_hover_schattenposition_horizontal !==
              undefined
            ? Design_Top_Navigation.top_navigation_static_hover_schattenposition_horizontal
            : "3"
        );

        setDB_LeftSidebar_MenuItem_HauptmenuTop_ShadowVertical_Hover(
          Design_Top_Navigation !== undefined &&
            Design_Top_Navigation.top_navigation_static_hover_schattenposition_vertikal !==
              undefined
            ? Design_Top_Navigation.top_navigation_static_hover_schattenposition_vertikal
            : "3"
        );

        setDB_LeftSidebar_MenuItem_HauptmenuTop_ShadowBlur_Hover(
          Design_Top_Navigation !== undefined &&
            Design_Top_Navigation.top_navigation_static_hover_schattenrauschen !==
              undefined
            ? Design_Top_Navigation.top_navigation_static_hover_schattenrauschen
            : "2"
        );

        setDB_LeftSidebar_MenuItem_HauptmenuTop_Hover_FontSize(
          Design_Top_Navigation !== undefined &&
            Design_Top_Navigation.top_navigation_static_hover_schriftgroesse !==
              undefined
            ? Design_Top_Navigation.top_navigation_static_hover_schriftgroesse
            : "18"
        );

        setDB_LeftSidebar_MenuItem_HauptmenuTop_Hover_FontWeight(
          Design_Top_Navigation !== undefined &&
            Design_Top_Navigation.top_navigation_static_hover_schriftgewicht !==
              undefined
            ? Design_Top_Navigation.top_navigation_static_hover_schriftgewicht
            : "1000"
        );

        setDB_LeftSidebar_MenuItem_HauptmenuTop_letterSpacing_Hover(
          Design_Top_Navigation !== undefined &&
            Design_Top_Navigation.top_navigation_static_hover_buchstabenabstand !==
              undefined
            ? Design_Top_Navigation.top_navigation_static_hover_buchstabenabstand
            : "0"
        );

        setDB_LeftSidebar_MenuItem_HauptmenuTop_TextDecoration_Hover(
          Design_Top_Navigation !== undefined &&
            Design_Top_Navigation.top_navigation_static_hover_text_dekoration !==
              undefined
            ? Design_Top_Navigation.top_navigation_static_hover_text_dekoration
            : "underline"
        );

        setDB_LeftSidebar_MenuItem_HauptmenuTop_TextDecorationStyle_Hover(
          Design_Top_Navigation !== undefined &&
            Design_Top_Navigation.top_navigation_static_hover_text_dekoration_stil !==
              undefined
            ? Design_Top_Navigation.top_navigation_static_hover_text_dekoration_stil
            : "solid"
        );

        setDB_LeftSidebar_MenuItem_HauptmenuTop_marginLeftRight_Hover(
          Design_Top_Navigation !== undefined &&
            Design_Top_Navigation.top_navigation_static_hover_aussen_abstand_links_rechts !==
              undefined
            ? Design_Top_Navigation.top_navigation_static_hover_aussen_abstand_links_rechts
            : "4"
        );

        setDB_LeftSidebar_MenuItem_HauptmenuTop_marginTopBottom_Hover(
          Design_Top_Navigation !== undefined &&
            Design_Top_Navigation.top_navigation_static_hover_aussen_abstand_oben_unten !==
              undefined
            ? Design_Top_Navigation.top_navigation_static_hover_aussen_abstand_oben_unten
            : "0"
        );

        setDB_LeftSidebar_MenuItem_HauptmenuTop_paddingLeftRight_Hover(
          Design_Top_Navigation !== undefined &&
            Design_Top_Navigation.top_navigation_static_hover_innerer_abstand_links_rechts !==
              undefined
            ? Design_Top_Navigation.top_navigation_static_hover_innerer_abstand_links_rechts
            : "15"
        );

        setDB_LeftSidebar_MenuItem_HauptmenuTop_paddingTopBottom_Hover(
          Design_Top_Navigation !== undefined &&
            Design_Top_Navigation.top_navigation_static_hover_innerer_abstand_oben_unten !==
              undefined
            ? Design_Top_Navigation.top_navigation_static_hover_innerer_abstand_oben_unten
            : "3"
        );

        setDB_LeftSidebar_MenuItem_HauptmenuTop_Hover_TextAlign(
          Design_Top_Navigation !== undefined &&
            Design_Top_Navigation.top_navigation_static_hover_textausrichtung !==
              undefined
            ? Design_Top_Navigation.top_navigation_static_hover_textausrichtung
            : "center"
        );

        setDB_LeftSidebar_MenuItem_HauptmenuTop_BorderColorLeft(
          Design_Top_Navigation !== undefined &&
            Design_Top_Navigation.top_nav_static_border_color_left !== undefined
            ? Design_Top_Navigation.top_nav_static_border_color_left
            : "rgba(0,0,0,0.3)"
        );
        setDB_LeftSidebar_MenuItem_HauptmenuTop_BorderColorLeftNotEncoded(
          Design_Top_Navigation !== undefined &&
            Design_Top_Navigation.top_nav_static_border_color_left_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Top_Navigation.top_nav_static_border_color_left_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0.3 }
        );

        setDB_LeftSidebar_MenuItem_HauptmenuTop_BorderColorRight(
          Design_Top_Navigation !== undefined &&
            Design_Top_Navigation.top_nav_static_border_color_right !==
              undefined
            ? Design_Top_Navigation.top_nav_static_border_color_right
            : "rgba(0,0,0,0.3)"
        );
        setDB_LeftSidebar_MenuItem_HauptmenuTop_BorderColorRightNotEncoded(
          Design_Top_Navigation !== undefined &&
            Design_Top_Navigation.top_nav_static_border_color_right_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Top_Navigation.top_nav_static_border_color_right_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0.3 }
        );

        setDB_LeftSidebar_MenuItem_HauptmenuTop_BorderColorTop(
          Design_Top_Navigation !== undefined &&
            Design_Top_Navigation.top_nav_static_border_color_top !== undefined
            ? Design_Top_Navigation.top_nav_static_border_color_top
            : "rgba(0,0,0,0.3)"
        );
        setDB_LeftSidebar_MenuItem_HauptmenuTop_BorderColorTopNotEncoded(
          Design_Top_Navigation !== undefined &&
            Design_Top_Navigation.top_nav_static_border_color_top_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Top_Navigation.top_nav_static_border_color_top_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0.3 }
        );

        setDB_LeftSidebar_MenuItem_HauptmenuTop_BorderColorBottom(
          Design_Top_Navigation !== undefined &&
            Design_Top_Navigation.top_nav_static_border_color_bottom !==
              undefined
            ? Design_Top_Navigation.top_nav_static_border_color_bottom
            : "rgba(0,0,0,0.3)"
        );
        setDB_LeftSidebar_MenuItem_HauptmenuTop_BorderColorBottomNotEncoded(
          Design_Top_Navigation !== undefined &&
            Design_Top_Navigation.top_nav_static_border_color_bottom_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Top_Navigation.top_nav_static_border_color_bottom_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0.3 }
        );

        setDB_LeftSidebar_MenuItem_HauptmenuTop_BorderLeft(
          Design_Top_Navigation !== undefined &&
            Design_Top_Navigation.top_nav_static_border_pixel_left !== undefined
            ? Design_Top_Navigation.top_nav_static_border_pixel_left
            : "1"
        );

        setDB_LeftSidebar_MenuItem_HauptmenuTop_BorderRight(
          Design_Top_Navigation !== undefined &&
            Design_Top_Navigation.top_nav_static_border_pixel_right !==
              undefined
            ? Design_Top_Navigation.top_nav_static_border_pixel_right
            : "1"
        );

        setDB_LeftSidebar_MenuItem_HauptmenuTop_BorderTop(
          Design_Top_Navigation !== undefined &&
            Design_Top_Navigation.top_nav_static_border_pixel_top !== undefined
            ? Design_Top_Navigation.top_nav_static_border_pixel_top
            : "1"
        );

        setDB_LeftSidebar_MenuItem_HauptmenuTop_BorderBottom(
          Design_Top_Navigation !== undefined &&
            Design_Top_Navigation.top_nav_static_border_pixel_bottom !==
              undefined
            ? Design_Top_Navigation.top_nav_static_border_pixel_bottom
            : "1"
        );

        setDB_LeftSidebar_MenuItem_HauptmenuTop_BorderTopLeftRadius(
          Design_Top_Navigation !== undefined &&
            Design_Top_Navigation.top_nav_static_border_radius_top_left !==
              undefined
            ? Design_Top_Navigation.top_nav_static_border_radius_top_left
            : "5"
        );

        setDB_LeftSidebar_MenuItem_HauptmenuTop_BorderTopRightRadius(
          Design_Top_Navigation !== undefined &&
            Design_Top_Navigation.top_nav_static_border_radius_top_right !==
              undefined
            ? Design_Top_Navigation.top_nav_static_border_radius_top_right
            : "5"
        );

        setDB_LeftSidebar_MenuItem_HauptmenuTop_BorderBottomLeftRadius(
          Design_Top_Navigation !== undefined &&
            Design_Top_Navigation.top_nav_static_border_radius_bottom_left !==
              undefined
            ? Design_Top_Navigation.top_nav_static_border_radius_bottom_left
            : "5"
        );

        setDB_LeftSidebar_MenuItem_HauptmenuTop_BorderBottomRightRadius(
          Design_Top_Navigation !== undefined &&
            Design_Top_Navigation.top_nav_static_border_radius_bottom_right !==
              undefined
            ? Design_Top_Navigation.top_nav_static_border_radius_bottom_right
            : "5"
        );

        setDB_LeftSidebar_MenuItem_HauptmenuTop_BorderStyleLeft(
          Design_Top_Navigation !== undefined &&
            Design_Top_Navigation.top_nav_static_border_style_left !== undefined
            ? Design_Top_Navigation.top_nav_static_border_style_left
            : "solid"
        );

        setDB_LeftSidebar_MenuItem_HauptmenuTop_BorderStyleRight(
          Design_Top_Navigation !== undefined &&
            Design_Top_Navigation.top_nav_static_border_style_right !==
              undefined
            ? Design_Top_Navigation.top_nav_static_border_style_right
            : "solid"
        );

        setDB_LeftSidebar_MenuItem_HauptmenuTop_BorderStyleTop(
          Design_Top_Navigation !== undefined &&
            Design_Top_Navigation.top_nav_static_border_style_top !== undefined
            ? Design_Top_Navigation.top_nav_static_border_style_top
            : "solid"
        );

        setDB_LeftSidebar_MenuItem_HauptmenuTop_BorderStyleBottom(
          Design_Top_Navigation !== undefined &&
            Design_Top_Navigation.top_nav_static_border_style_bottom !==
              undefined
            ? Design_Top_Navigation.top_nav_static_border_style_bottom
            : "solid"
        );

        setDB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderColorLeft(
          Design_Top_Navigation !== undefined &&
            Design_Top_Navigation.top_nav_static_border_hover_color_left !==
              undefined
            ? Design_Top_Navigation.top_nav_static_border_hover_color_left
            : "rgba(0,0,0,0.4)"
        );

        setDB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderColorLeftNotEncoded(
          Design_Top_Navigation !== undefined &&
            Design_Top_Navigation.top_nav_static_border_hover_color_left_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Top_Navigation.top_nav_static_border_hover_color_left_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0.4 }
        );

        setDB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderColorRight(
          Design_Top_Navigation !== undefined &&
            Design_Top_Navigation.top_nav_static_border_hover_color_right !==
              undefined
            ? Design_Top_Navigation.top_nav_static_border_hover_color_right
            : "rgba(0,0,0,0.4)"
        );
        setDB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderColorRightNotEncoded(
          Design_Top_Navigation !== undefined &&
            Design_Top_Navigation.top_nav_static_border_hover_color_right_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Top_Navigation.top_nav_static_border_hover_color_right_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0.4 }
        );

        setDB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderColorTop(
          Design_Top_Navigation !== undefined &&
            Design_Top_Navigation.top_nav_static_border_hover_color_top !==
              undefined
            ? Design_Top_Navigation.top_nav_static_border_hover_color_top
            : "rgba(0,0,0,0.4)"
        );
        setDB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderColorTopNotEncoded(
          Design_Top_Navigation !== undefined &&
            Design_Top_Navigation.top_nav_static_border_hover_color_top_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Top_Navigation.top_nav_static_border_hover_color_top_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0.4 }
        );

        setDB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderColorBottom(
          Design_Top_Navigation !== undefined &&
            Design_Top_Navigation.top_nav_static_border_hover_color_bottom !==
              undefined
            ? Design_Top_Navigation.top_nav_static_border_hover_color_bottom
            : "rgba(0,0,0,0.4)"
        );
        setDB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderColorBottomNotEncoded(
          Design_Top_Navigation !== undefined &&
            Design_Top_Navigation.top_nav_static_border_hover_color_bottom_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Top_Navigation.top_nav_static_border_hover_color_bottom_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0.4 }
        );

        setDB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderLeft(
          Design_Top_Navigation !== undefined &&
            Design_Top_Navigation.top_nav_static_border_hover_pixel_left !==
              undefined
            ? Design_Top_Navigation.top_nav_static_border_hover_pixel_left
            : "1"
        );

        setDB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderRight(
          Design_Top_Navigation !== undefined &&
            Design_Top_Navigation.top_nav_static_border_hover_pixel_right !==
              undefined
            ? Design_Top_Navigation.top_nav_static_border_hover_pixel_right
            : "1"
        );

        setDB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderTop(
          Design_Top_Navigation !== undefined &&
            Design_Top_Navigation.top_nav_static_border_hover_pixel_top !==
              undefined
            ? Design_Top_Navigation.top_nav_static_border_hover_pixel_top
            : "1"
        );

        setDB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderBottom(
          Design_Top_Navigation !== undefined &&
            Design_Top_Navigation.top_nav_static_border_hover_pixel_bottom !==
              undefined
            ? Design_Top_Navigation.top_nav_static_border_hover_pixel_bottom
            : "1"
        );

        setDB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderTopLeftRadius(
          Design_Top_Navigation !== undefined &&
            Design_Top_Navigation.top_nav_static_border_hover_radius_top_left !==
              undefined
            ? Design_Top_Navigation.top_nav_static_border_hover_radius_top_left
            : "5"
        );

        setDB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderTopRightRadius(
          Design_Top_Navigation !== undefined &&
            Design_Top_Navigation.top_nav_static_border_hover_radius_top_right !==
              undefined
            ? Design_Top_Navigation.top_nav_static_border_hover_radius_top_right
            : "5"
        );

        setDB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderBottomLeftRadius(
          Design_Top_Navigation !== undefined &&
            Design_Top_Navigation.top_nav_static_border_hover_radius_bottom_left !==
              undefined
            ? Design_Top_Navigation.top_nav_static_border_hover_radius_bottom_left
            : "5"
        );

        setDB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderBottomRightRadius(
          Design_Top_Navigation !== undefined &&
            Design_Top_Navigation.top_nav_static_border_hover_radius_bottom_right !==
              undefined
            ? Design_Top_Navigation.top_nav_static_border_hover_radius_bottom_right
            : "5"
        );

        setDB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderStyleLeft(
          Design_Top_Navigation !== undefined &&
            Design_Top_Navigation.top_nav_static_border_hover_style_left !==
              undefined
            ? Design_Top_Navigation.top_nav_static_border_hover_style_left
            : "solid"
        );

        setDB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderStyleRight(
          Design_Top_Navigation !== undefined &&
            Design_Top_Navigation.top_nav_static_border_hover_style_right !==
              undefined
            ? Design_Top_Navigation.top_nav_static_border_hover_style_right
            : "solid"
        );

        setDB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderStyleTop(
          Design_Top_Navigation !== undefined &&
            Design_Top_Navigation.top_nav_static_border_hover_style_top !==
              undefined
            ? Design_Top_Navigation.top_nav_static_border_hover_style_top
            : "solid"
        );

        setDB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderStyleBottom(
          Design_Top_Navigation !== undefined &&
            Design_Top_Navigation.top_nav_static_border_hover_style_bottom !==
              undefined
            ? Design_Top_Navigation.top_nav_static_border_hover_style_bottom
            : "solid"
        );

        setDB_LeftSidebar_MenuContainer_CC__BackgroundColor(
          Design_Left_Navigation !== undefined &&
            Design_Left_Navigation.left_nav_hauptkategorien_hintergrundfarbe !==
              undefined
            ? Design_Left_Navigation.left_nav_hauptkategorien_hintergrundfarbe
            : "rgba(0,0,0,0.3)"
        );

        setDB_LeftSidebar_MenuContainer_CC__BackgroundColorNotEncoded(
          Design_Left_Navigation !== undefined &&
            Design_Left_Navigation.left_nav_hauptkategorien_hintergrundfarbe_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Left_Navigation.left_nav_hauptkategorien_hintergrundfarbe_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0.3 }
        );

        setDB_LeftSidebar_MenuItem_MenuContainer_CC__ShadowColor(
          Design_Left_Navigation !== undefined &&
            Design_Left_Navigation.left_nav_hauptkategorien_schattenfarbe !==
              undefined
            ? Design_Left_Navigation.left_nav_hauptkategorien_schattenfarbe
            : "rgba(0,0,0,0.4)"
        );
        setDB_LeftSidebar_MenuItem_MenuContainer_CC__ShadowColorNotEncoded(
          Design_Left_Navigation !== undefined &&
            Design_Left_Navigation.left_nav_hauptkategorien_schattenfarbe_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Left_Navigation.left_nav_hauptkategorien_schattenfarbe_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0.4 }
        );

        setDB_LeftSidebar_MenuItem_MenuContainer_CC__ShadowHorizontal(
          Design_Left_Navigation !== undefined &&
            Design_Left_Navigation.left_nav_hauptkategorien_schattenposition_horizontal !==
              undefined
            ? Design_Left_Navigation.left_nav_hauptkategorien_schattenposition_horizontal
            : "4"
        );

        setDB_LeftSidebar_MenuItem_MenuContainer_CC__ShadowVertical(
          Design_Left_Navigation !== undefined &&
            Design_Left_Navigation.left_nav_hauptkategorien_schattenposition_vertikal !==
              undefined
            ? Design_Left_Navigation.left_nav_hauptkategorien_schattenposition_vertikal
            : "4"
        );

        setDB_LeftSidebar_MenuItem_MenuContainer_CC__ShadowBlur(
          Design_Left_Navigation !== undefined &&
            Design_Left_Navigation.left_nav_hauptkategorien_schattenrauschen !==
              undefined
            ? Design_Left_Navigation.left_nav_hauptkategorien_schattenrauschen
            : "5"
        );

        setDB_LeftSidebar_MenuItem_MenuContainer_CC__paddingLeft(
          Design_Left_Navigation !== undefined &&
            Design_Left_Navigation.left_nav_hauptkategorien_innerer_abstand_links !==
              undefined
            ? Design_Left_Navigation.left_nav_hauptkategorien_innerer_abstand_links
            : "0"
        );

        setDB_LeftSidebar_MenuItem_MenuContainer_CC__paddingRight(
          Design_Left_Navigation !== undefined &&
            Design_Left_Navigation.left_nav_hauptkategorien_innerer_abstand_rechts !==
              undefined
            ? Design_Left_Navigation.left_nav_hauptkategorien_innerer_abstand_rechts
            : "0"
        );

        setDB_LeftSidebar_MenuItem_MenuContainer_CC__paddingTop(
          Design_Left_Navigation !== undefined &&
            Design_Left_Navigation.left_nav_hauptkategorien_innerer_abstand_oben !==
              undefined
            ? Design_Left_Navigation.left_nav_hauptkategorien_innerer_abstand_oben
            : "5"
        );
        setDB_LeftSidebar_MenuItem_MenuContainer_CC__paddingBottom(
          Design_Left_Navigation !== undefined &&
            Design_Left_Navigation.left_nav_hauptkategorien_innerer_abstand_unten !==
              undefined
            ? Design_Left_Navigation.left_nav_hauptkategorien_innerer_abstand_unten
            : "5"
        );

        setDB_LeftSidebar_MenuContainer_CC__Hover_BackgroundColor(
          Design_Left_Navigation !== undefined &&
            Design_Left_Navigation.left_nav_hauptkategorien_hover_hintergrundfarbe !==
              undefined
            ? Design_Left_Navigation.left_nav_hauptkategorien_hover_hintergrundfarbe
            : "rgba(0,0,0,0.3)"
        );
        setDB_LeftSidebar_MenuContainer_CC__Hover_BackgroundColorNotEncoded(
          Design_Left_Navigation !== undefined &&
            Design_Left_Navigation.left_nav_hauptkategorien_hintergrundfarbe_hover_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Left_Navigation.left_nav_hauptkategorien_hintergrundfarbe_hover_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0.3 }
        );

        setDB_LeftSidebar_MenuItem_MenuContainer_CC__Hover_ShadowColor(
          Design_Left_Navigation !== undefined &&
            Design_Left_Navigation.left_nav_hauptkategorien_hover_schattenfarbe !==
              undefined
            ? Design_Left_Navigation.left_nav_hauptkategorien_hover_schattenfarbe
            : "rgba(0,0,0,0.5)"
        );
        setDB_LeftSidebar_MenuItem_MenuContainer_CC__Hover_ShadowColorNotEncoded(
          Design_Left_Navigation !== undefined &&
            Design_Left_Navigation.left_nav_hauptkategorien_hover_schattenfarbe_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Left_Navigation.left_nav_hauptkategorien_hover_schattenfarbe_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0.5 }
        );

        setDB_LeftSidebar_MenuItem_MenuContainer_CC__Hover_ShadowHorizontal(
          Design_Left_Navigation !== undefined &&
            Design_Left_Navigation.left_nav_hauptkategorien_hover_schattenposition_horizontal !==
              undefined
            ? Design_Left_Navigation.left_nav_hauptkategorien_hover_schattenposition_horizontal
            : "4"
        );
        setDB_LeftSidebar_MenuItem_MenuContainer_CC__Hover_ShadowVertical(
          Design_Left_Navigation !== undefined &&
            Design_Left_Navigation.left_nav_hauptkategorien_hover_schattenposition_vertikal !==
              undefined
            ? Design_Left_Navigation.left_nav_hauptkategorien_hover_schattenposition_vertikal
            : "4"
        );
        setDB_LeftSidebar_MenuItem_MenuContainer_CC__Hover_ShadowBlur(
          Design_Left_Navigation !== undefined &&
            Design_Left_Navigation.left_nav_hauptkategorien_hover_schattenrauschen !==
              undefined
            ? Design_Left_Navigation.left_nav_hauptkategorien_hover_schattenrauschen
            : "6"
        );

        setDB_LeftSidebar_MenuItem_MenuContainer_CC__Hover_paddingLeft(
          Design_Left_Navigation !== undefined &&
            Design_Left_Navigation.left_nav_hauptkategorien_hover_innerer_abstand_links !==
              undefined
            ? Design_Left_Navigation.left_nav_hauptkategorien_hover_innerer_abstand_links
            : "0"
        );
        setDB_LeftSidebar_MenuItem_MenuContainer_CC__Hover_paddingRight(
          Design_Left_Navigation !== undefined &&
            Design_Left_Navigation.left_nav_hauptkategorien_hover_innerer_abstand_rechts !==
              undefined
            ? Design_Left_Navigation.left_nav_hauptkategorien_hover_innerer_abstand_rechts
            : "0"
        );
        setDB_LeftSidebar_MenuItem_MenuContainer_CC__Hover_paddingTop(
          Design_Left_Navigation !== undefined &&
            Design_Left_Navigation.left_nav_hauptkategorien_hover_innerer_abstand_oben !==
              undefined
            ? Design_Left_Navigation.left_nav_hauptkategorien_hover_innerer_abstand_oben
            : "5"
        );
        setDB_LeftSidebar_MenuItem_MenuContainer_CC__Hover_paddingBottom(
          Design_Left_Navigation !== undefined &&
            Design_Left_Navigation.left_nav_hauptkategorien_hover_innerer_abstand_unten !==
              undefined
            ? Design_Left_Navigation.left_nav_hauptkategorien_hover_innerer_abstand_unten
            : "5"
        );

        setDB_LeftSidebar_MenuContainer_CC__BorderColorLeft(
          Design_Left_Navigation !== undefined &&
            Design_Left_Navigation.left_nav_hauptkategorien_border_color_left !==
              undefined
            ? Design_Left_Navigation.left_nav_hauptkategorien_border_color_left
            : "rgba(0,0,0,0.3)"
        );
        setDB_LeftSidebar_MenuContainer_CC__BorderColorLeftNotEncoded(
          Design_Left_Navigation !== undefined &&
            Design_Left_Navigation.left_nav_hauptkategorien_border_color_left_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Left_Navigation.left_nav_hauptkategorien_border_color_left_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0.3 }
        );
        setDB_LeftSidebar_MenuContainer_CC__BorderColorRight(
          Design_Left_Navigation !== undefined &&
            Design_Left_Navigation.left_nav_hauptkategorien_border_color_right !==
              undefined
            ? Design_Left_Navigation.left_nav_hauptkategorien_border_color_right
            : "rgba(0,0,0,0.3)"
        );
        setDB_LeftSidebar_MenuContainer_CC__BorderColorRightNotEncoded(
          Design_Left_Navigation !== undefined &&
            Design_Left_Navigation.left_nav_hauptkategorien_border_color_right_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Left_Navigation.left_nav_hauptkategorien_border_color_right_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0.3 }
        );
        setDB_LeftSidebar_MenuContainer_CC__BorderColorTop(
          Design_Left_Navigation !== undefined &&
            Design_Left_Navigation.left_nav_hauptkategorien_border_color_top !==
              undefined
            ? Design_Left_Navigation.left_nav_hauptkategorien_border_color_top
            : "rgba(0,0,0,0.3)"
        );
        setDB_LeftSidebar_MenuContainer_CC__BorderColorTopNotEncoded(
          Design_Left_Navigation !== undefined &&
            Design_Left_Navigation.left_nav_hauptkategorien_border_color_top_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Left_Navigation.left_nav_hauptkategorien_border_color_top_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0.3 }
        );
        setDB_LeftSidebar_MenuContainer_CC__BorderColorBottom(
          Design_Left_Navigation !== undefined &&
            Design_Left_Navigation.left_nav_hauptkategorien_border_color_bottom !==
              undefined
            ? Design_Left_Navigation.left_nav_hauptkategorien_border_color_bottom
            : "rgba(0,0,0,0.3)"
        );
        setDB_LeftSidebar_MenuContainer_CC__BorderColorBottomNotEncoded(
          Design_Left_Navigation !== undefined &&
            Design_Left_Navigation.left_nav_hauptkategorien_border_color_bottom_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Left_Navigation.left_nav_hauptkategorien_border_color_bottom_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0.3 }
        );

        setDB_LeftSidebar_MenuContainer_CC__BorderLeft(
          Design_Left_Navigation !== undefined &&
            Design_Left_Navigation.left_nav_hauptkategorien_border_pixel_left !==
              undefined
            ? Design_Left_Navigation.left_nav_hauptkategorien_border_pixel_left
            : "1"
        );
        setDB_LeftSidebar_MenuContainer_CC__BorderRight(
          Design_Left_Navigation !== undefined &&
            Design_Left_Navigation.left_nav_hauptkategorien_border_pixel_right !==
              undefined
            ? Design_Left_Navigation.left_nav_hauptkategorien_border_pixel_right
            : "1"
        );
        setDB_LeftSidebar_MenuContainer_CC__BorderTop(
          Design_Left_Navigation !== undefined &&
            Design_Left_Navigation.left_nav_hauptkategorien_border_pixel_top !==
              undefined
            ? Design_Left_Navigation.left_nav_hauptkategorien_border_pixel_top
            : "1"
        );
        setDB_LeftSidebar_MenuContainer_CC__BorderBottom(
          Design_Left_Navigation !== undefined &&
            Design_Left_Navigation.left_nav_hauptkategorien_border_pixel_bottom !==
              undefined
            ? Design_Left_Navigation.left_nav_hauptkategorien_border_pixel_bottom
            : "1"
        );

        setDB_LeftSidebar_MenuItem_MenuContainer_CC__BorderTopLeftRadius(
          Design_Left_Navigation !== undefined &&
            Design_Left_Navigation.left_nav_hauptkategorien_border_radius_top_left !==
              undefined
            ? Design_Left_Navigation.left_nav_hauptkategorien_border_radius_top_left
            : "7"
        );
        setDB_LeftSidebar_MenuItem_MenuContainer_CC__BorderTopRightRadius(
          Design_Left_Navigation !== undefined &&
            Design_Left_Navigation.left_nav_hauptkategorien_border_radius_top_right !==
              undefined
            ? Design_Left_Navigation.left_nav_hauptkategorien_border_radius_top_right
            : "7"
        );
        setDB_LeftSidebar_MenuItem_MenuContainer_CC__BorderBottomLeftRadius(
          Design_Left_Navigation !== undefined &&
            Design_Left_Navigation.left_nav_hauptkategorien_border_radius_bottom_left !==
              undefined
            ? Design_Left_Navigation.left_nav_hauptkategorien_border_radius_bottom_left
            : "7"
        );
        setDB_LeftSidebar_MenuItem_MenuContainer_CC__BorderBottomRightRadius(
          Design_Left_Navigation !== undefined &&
            Design_Left_Navigation.left_nav_hauptkategorien_border_radius_bottom_right !==
              undefined
            ? Design_Left_Navigation.left_nav_hauptkategorien_border_radius_bottom_right
            : "7"
        );

        setDB_LeftSidebar_MenuContainer_CC__BorderStyleLeft(
          Design_Left_Navigation !== undefined &&
            Design_Left_Navigation.left_nav_hauptkategorien_border_style_left !==
              undefined
            ? Design_Left_Navigation.left_nav_hauptkategorien_border_style_left
            : "solid"
        );
        setDB_LeftSidebar_MenuContainer_CC__BorderStyleRight(
          Design_Left_Navigation !== undefined &&
            Design_Left_Navigation.left_nav_hauptkategorien_border_style_right !==
              undefined
            ? Design_Left_Navigation.left_nav_hauptkategorien_border_style_right
            : "solid"
        );
        setDB_LeftSidebar_MenuContainer_CC__BorderStyleTop(
          Design_Left_Navigation !== undefined &&
            Design_Left_Navigation.left_nav_hauptkategorien_border_style_top !==
              undefined
            ? Design_Left_Navigation.left_nav_hauptkategorien_border_style_top
            : "solid"
        );
        setDB_LeftSidebar_MenuContainer_CC__BorderStyleBottom(
          Design_Left_Navigation !== undefined &&
            Design_Left_Navigation.left_nav_hauptkategorien_border_style_bottom !==
              undefined
            ? Design_Left_Navigation.left_nav_hauptkategorien_border_style_bottom
            : "solid"
        );

        setDB_LeftSidebar_MenuContainer_CC__Hover_BorderColorLeft(
          Design_Left_Navigation !== undefined &&
            Design_Left_Navigation.left_nav_hauptkategorien_hover_border_color_left !==
              undefined
            ? Design_Left_Navigation.left_nav_hauptkategorien_hover_border_color_left
            : "rgba(0,0,0,0.3)"
        );
        setDB_LeftSidebar_MenuContainer_CC__Hover_BorderColorLeftNotEncoded(
          Design_Left_Navigation !== undefined &&
            Design_Left_Navigation.left_nav_hauptkategorien_hover_border_color_left_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Left_Navigation.left_nav_hauptkategorien_hover_border_color_left_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0.3 }
        );
        setDB_LeftSidebar_MenuContainer_CC__Hover_BorderColorRight(
          Design_Left_Navigation !== undefined &&
            Design_Left_Navigation.left_nav_hauptkategorien_hover_border_color_right !==
              undefined
            ? Design_Left_Navigation.left_nav_hauptkategorien_hover_border_color_right
            : "rgba(0,0,0,0.3)"
        );
        setDB_LeftSidebar_MenuContainer_CC__Hover_BorderColorRightNotEncoded(
          Design_Left_Navigation !== undefined &&
            Design_Left_Navigation.left_nav_hauptkategorien_hover_border_color_right_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Left_Navigation.left_nav_hauptkategorien_hover_border_color_right_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0.3 }
        );
        setDB_LeftSidebar_MenuContainer_CC__Hover_BorderColorTop(
          Design_Left_Navigation !== undefined &&
            Design_Left_Navigation.left_nav_hauptkategorien_hover_border_color_top !==
              undefined
            ? Design_Left_Navigation.left_nav_hauptkategorien_hover_border_color_top
            : "rgba(0,0,0,0.3)"
        );
        setDB_LeftSidebar_MenuContainer_CC__Hover_BorderColorTopNotEncoded(
          Design_Left_Navigation !== undefined &&
            Design_Left_Navigation.left_nav_hauptkategorien_hover_border_color_top_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Left_Navigation.left_nav_hauptkategorien_hover_border_color_top_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0.3 }
        );
        setDB_LeftSidebar_MenuContainer_CC__Hover_BorderColorBottom(
          Design_Left_Navigation !== undefined &&
            Design_Left_Navigation.left_nav_hauptkategorien_hover_border_color_bottom !==
              undefined
            ? Design_Left_Navigation.left_nav_hauptkategorien_hover_border_color_bottom
            : "rgba(0,0,0,0.3)"
        );
        setDB_LeftSidebar_MenuContainer_CC__Hover_BorderColorBottomNotEncoded(
          Design_Left_Navigation !== undefined &&
            Design_Left_Navigation.left_nav_hauptkategorien_hover_border_color_bottom_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Left_Navigation.left_nav_hauptkategorien_hover_border_color_bottom_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0.3 }
        );

        setDB_LeftSidebar_MenuContainer_CC__Hover_BorderLeft(
          Design_Left_Navigation !== undefined &&
            Design_Left_Navigation.left_nav_hauptkategorien_hover_border_pixel_left !==
              undefined
            ? Design_Left_Navigation.left_nav_hauptkategorien_hover_border_pixel_left
            : "1"
        );

        setDB_LeftSidebar_MenuContainer_CC__Hover_BorderRight(
          Design_Left_Navigation !== undefined &&
            Design_Left_Navigation.left_nav_hauptkategorien_hover_border_pixel_right !==
              undefined
            ? Design_Left_Navigation.left_nav_hauptkategorien_hover_border_pixel_right
            : "1"
        );

        setDB_LeftSidebar_MenuContainer_CC__Hover_BorderTop(
          Design_Left_Navigation !== undefined &&
            Design_Left_Navigation.left_nav_hauptkategorien_hover_border_pixel_top !==
              undefined
            ? Design_Left_Navigation.left_nav_hauptkategorien_hover_border_pixel_top
            : "1"
        );

        setDB_LeftSidebar_MenuContainer_CC__Hover_BorderBottom(
          Design_Left_Navigation !== undefined &&
            Design_Left_Navigation.left_nav_hauptkategorien_hover_border_pixel_bottom !==
              undefined
            ? Design_Left_Navigation.left_nav_hauptkategorien_hover_border_pixel_bottom
            : "1"
        );

        setDB_LeftSidebar_MenuItem_MenuContainer_CC__Hover_BorderTopLeftRadius(
          Design_Left_Navigation !== undefined &&
            Design_Left_Navigation.left_nav_hauptkategorien_hover_border_radius_top_left !==
              undefined
            ? Design_Left_Navigation.left_nav_hauptkategorien_hover_border_radius_top_left
            : "7"
        );

        setDB_LeftSidebar_MenuItem_MenuContainer_CC__Hover_BorderTopRightRadius(
          Design_Left_Navigation !== undefined &&
            Design_Left_Navigation.left_nav_hauptkategorien_hover_border_radius_top_right !==
              undefined
            ? Design_Left_Navigation.left_nav_hauptkategorien_hover_border_radius_top_right
            : "7"
        );

        setDB_LeftSidebar_MenuItem_MenuContainer_CC__Hover_BorderBottomLeftRadius(
          Design_Left_Navigation !== undefined &&
            Design_Left_Navigation.left_nav_hauptkategorien_hover_border_radius_bottom_left !==
              undefined
            ? Design_Left_Navigation.left_nav_hauptkategorien_hover_border_radius_bottom_left
            : "7"
        );

        setDB_LeftSidebar_MenuItem_MenuContainer_CC__Hover_BorderBottomRightRadius(
          Design_Left_Navigation !== undefined &&
            Design_Left_Navigation.left_nav_hauptkategorien_hover_border_radius_bottom_right !==
              undefined
            ? Design_Left_Navigation.left_nav_hauptkategorien_hover_border_radius_bottom_right
            : "7"
        );

        setDB_LeftSidebar_MenuContainer_CC__Hover_BorderStyleLeft(
          Design_Left_Navigation !== undefined &&
            Design_Left_Navigation.left_nav_hauptkategorien_hover_border_style_left !==
              undefined
            ? Design_Left_Navigation.left_nav_hauptkategorien_hover_border_style_left
            : "solid"
        );

        setDB_LeftSidebar_MenuContainer_CC__Hover_BorderStyleRight(
          Design_Left_Navigation !== undefined &&
            Design_Left_Navigation.left_nav_hauptkategorien_hover_border_style_right !==
              undefined
            ? Design_Left_Navigation.left_nav_hauptkategorien_hover_border_style_right
            : "solid"
        );

        setDB_LeftSidebar_MenuContainer_CC__Hover_BorderStyleTop(
          Design_Left_Navigation !== undefined &&
            Design_Left_Navigation.left_nav_hauptkategorien_hover_border_style_top !==
              undefined
            ? Design_Left_Navigation.left_nav_hauptkategorien_hover_border_style_top
            : "solid"
        );

        setDB_LeftSidebar_MenuContainer_CC__Hover_BorderStyleBottom(
          Design_Left_Navigation !== undefined &&
            Design_Left_Navigation.left_nav_hauptkategorien_hover_border_style_bottom !==
              undefined
            ? Design_Left_Navigation.left_nav_hauptkategorien_hover_border_style_bottom
            : "solid"
        );

        setDB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_FontColor(
          Design_Left_Navigation !== undefined &&
            Design_Left_Navigation.left_nav_hauptkat_alleProd_schriftfarbe !==
              undefined
            ? Design_Left_Navigation.left_nav_hauptkat_alleProd_schriftfarbe
            : "rgba(255,255,255,1)"
        );

        setDB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_FontColorNotEncoded(
          Design_Left_Navigation !== undefined &&
            Design_Left_Navigation.left_nav_hauptkat_alleProd_schriftfarbe_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Left_Navigation.left_nav_hauptkat_alleProd_schriftfarbe_not_encoded
              )
            : { r: 255, g: 255, b: 255, a: 1 }
        );

        setDB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_BackgroundColor(
          Design_Left_Navigation !== undefined &&
            Design_Left_Navigation.left_nav_hauptkat_alleProd_hintergrundfarbe !==
              undefined
            ? Design_Left_Navigation.left_nav_hauptkat_alleProd_hintergrundfarbe
            : "rgba(0,0,0,0.4)"
        );
        setDB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_BackgroundColorNotEncoded(
          Design_Left_Navigation !== undefined &&
            Design_Left_Navigation.left_nav_hauptkat_alleProd_hintergrundfarbe_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Left_Navigation.left_nav_hauptkat_alleProd_hintergrundfarbe_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0.4 }
        );

        setDB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_ShadowColor(
          Design_Left_Navigation !== undefined &&
            Design_Left_Navigation.left_nav_hauptkat_alleProd_schattenfarbe !==
              undefined
            ? Design_Left_Navigation.left_nav_hauptkat_alleProd_schattenfarbe
            : "rgba(0,0,0,1)"
        );

        setDB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_ShadowColorNotEncoded(
          Design_Left_Navigation !== undefined &&
            Design_Left_Navigation.left_nav_hauptkat_alleProd_schattenfarbe_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Left_Navigation.left_nav_hauptkat_alleProd_schattenfarbe_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 1 }
        );

        setDB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_ShadowHorizontal(
          Design_Left_Navigation !== undefined &&
            Design_Left_Navigation.left_nav_hauptkat_alleProd_schattenposition_horizontal !==
              undefined
            ? Design_Left_Navigation.left_nav_hauptkat_alleProd_schattenposition_horizontal
            : "1"
        );

        setDB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_ShadowVertical(
          Design_Left_Navigation !== undefined &&
            Design_Left_Navigation.left_nav_hauptkat_alleProd_schattenposition_vertikal !==
              undefined
            ? Design_Left_Navigation.left_nav_hauptkat_alleProd_schattenposition_vertikal
            : "1"
        );

        setDB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_ShadowBlur(
          Design_Left_Navigation !== undefined &&
            Design_Left_Navigation.left_nav_hauptkat_alleProd_schattenrauschen !==
              undefined
            ? Design_Left_Navigation.left_nav_hauptkat_alleProd_schattenrauschen
            : "3"
        );

        setDB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_FontSize(
          Design_Left_Navigation !== undefined &&
            Design_Left_Navigation.left_nav_hauptkat_alleProd_schriftgroesse !==
              undefined
            ? Design_Left_Navigation.left_nav_hauptkat_alleProd_schriftgroesse
            : "19"
        );

        setDB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_FontWeight(
          Design_Left_Navigation !== undefined &&
            Design_Left_Navigation.left_nav_hauptkat_alleProd_schriftgewicht !==
              undefined
            ? Design_Left_Navigation.left_nav_hauptkat_alleProd_schriftgewicht
            : "100"
        );

        setDB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_letterSpacing(
          Design_Left_Navigation !== undefined &&
            Design_Left_Navigation.left_nav_hauptkat_alleProd_buchstabenabstand !==
              undefined
            ? Design_Left_Navigation.left_nav_hauptkat_alleProd_buchstabenabstand
            : "0"
        );

        setDB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_TextDecoration(
          Design_Left_Navigation !== undefined &&
            Design_Left_Navigation.left_nav_hauptkat_alleProd_text_dekoration !==
              undefined
            ? Design_Left_Navigation.left_nav_hauptkat_alleProd_text_dekoration
            : "none"
        );

        setDB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_TextDecorationStyle(
          Design_Left_Navigation !== undefined &&
            Design_Left_Navigation.left_nav_hauptkat_alleProd_text_dekoration_stil !==
              undefined
            ? Design_Left_Navigation.left_nav_hauptkat_alleProd_text_dekoration_stil
            : "none"
        );

        setDB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_marginLeftRight(
          Design_Left_Navigation !== undefined &&
            Design_Left_Navigation.left_nav_hauptkat_alleProd_aussen_abstand_links_rechts !==
              undefined
            ? Design_Left_Navigation.left_nav_hauptkat_alleProd_aussen_abstand_links_rechts
            : "0"
        );

        setDB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_marginTopBottom(
          Design_Left_Navigation !== undefined &&
            Design_Left_Navigation.left_nav_hauptkat_alleProd_aussen_abstand_oben_unten !==
              undefined
            ? Design_Left_Navigation.left_nav_hauptkat_alleProd_aussen_abstand_oben_unten
            : "0"
        );

        setDB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_paddingLeftRight(
          Design_Left_Navigation !== undefined &&
            Design_Left_Navigation.left_nav_hauptkat_alleProd_innerer_abstand_links_rechts !==
              undefined
            ? Design_Left_Navigation.left_nav_hauptkat_alleProd_innerer_abstand_links_rechts
            : "0"
        );

        setDB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_paddingTopBottom(
          Design_Left_Navigation !== undefined &&
            Design_Left_Navigation.left_nav_hauptkat_alleProd_innerer_abstand_oben_unten !==
              undefined
            ? Design_Left_Navigation.left_nav_hauptkat_alleProd_innerer_abstand_oben_unten
            : "0"
        );

        setDB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_TextAlign(
          Design_Left_Navigation !== undefined &&
            Design_Left_Navigation.left_nav_hauptkat_alleProd_textausrichtung !==
              undefined
            ? Design_Left_Navigation.left_nav_hauptkat_alleProd_textausrichtung
            : "center"
        );

        setDB_fontFace_alleProdukteAuswahlBB_FontFamilyName(
          Design_Left_Navigation !== undefined &&
            Design_Left_Navigation.left_nav_hauptkat_alleProd_fontfamilyname !==
              undefined
            ? Design_Left_Navigation.left_nav_hauptkat_alleProd_fontfamilyname
            : "Lato"
        );

        setDB_fontFace_alleProdukteAuswahlBB_FontFamilySrc(
          Design_Left_Navigation !== undefined &&
            Design_Left_Navigation.left_nav_hauptkat_alleProd_fontfamilysrc !==
              undefined
            ? Design_Left_Navigation.left_nav_hauptkat_alleProd_fontfamilysrc
            : "/assets/Lato/Lato-Regular.ttf"
        );

        setDB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_FontColor(
          Design_Left_Navigation !== undefined &&
            Design_Left_Navigation.left_nav_hauptkat_alleProd_hov_schriftfarbe !==
              undefined
            ? Design_Left_Navigation.left_nav_hauptkat_alleProd_hov_schriftfarbe
            : "rgba(255,255,255,1)"
        );

        setDB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_FontColorNotEncoded(
          Design_Left_Navigation !== undefined &&
            Design_Left_Navigation.left_nav_hauptkat_alleProd_hov_schriftfarbe_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Left_Navigation.left_nav_hauptkat_alleProd_hov_schriftfarbe_not_encoded
              )
            : { r: 255, g: 255, b: 255, a: 1 }
        );

        setDB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_BackgroundColor(
          Design_Left_Navigation !== undefined &&
            Design_Left_Navigation.left_nav_hauptkat_alleProd_hov_hintergrundfarbe !==
              undefined
            ? Design_Left_Navigation.left_nav_hauptkat_alleProd_hov_hintergrundfarbe
            : "rgba(0,0,0,0.5)"
        );
        setDB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_BackgroundColorNotEncoded(
          Design_Left_Navigation !== undefined &&
            Design_Left_Navigation.left_nav_hauptkat_alleProd_hov_hintergrundfarbe_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Left_Navigation.left_nav_hauptkat_alleProd_hov_hintergrundfarbe_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0.5 }
        );

        setDB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_ShadowColor_Hover(
          Design_Left_Navigation !== undefined &&
            Design_Left_Navigation.left_nav_hauptkat_alleProd_hov_schattenfarbe !==
              undefined
            ? Design_Left_Navigation.left_nav_hauptkat_alleProd_hov_schattenfarbe
            : "rgba(0,0,0,1)"
        );
        setDB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_ShadowColorNotEncoded_Hover(
          Design_Left_Navigation !== undefined &&
            Design_Left_Navigation.left_nav_hauptkat_alleProd_hov_schattenfarbe_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Left_Navigation.left_nav_hauptkat_alleProd_hov_schattenfarbe_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 1 }
        );

        setDB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_ShadowHorizontal_Hover(
          Design_Left_Navigation !== undefined &&
            Design_Left_Navigation.left_nav_hauptkat_alleProd_hov_schattenposition_horizontal !==
              undefined
            ? Design_Left_Navigation.left_nav_hauptkat_alleProd_hov_schattenposition_horizontal
            : "1"
        );

        setDB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_ShadowVertical_Hover(
          Design_Left_Navigation !== undefined &&
            Design_Left_Navigation.left_nav_hauptkat_alleProd_hov_schattenposition_vertikal !==
              undefined
            ? Design_Left_Navigation.left_nav_hauptkat_alleProd_hov_schattenposition_vertikal
            : "1"
        );

        setDB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_ShadowBlur_Hover(
          Design_Left_Navigation !== undefined &&
            Design_Left_Navigation.left_nav_hauptkat_alleProd_hov_schattenrauschen !==
              undefined
            ? Design_Left_Navigation.left_nav_hauptkat_alleProd_hov_schattenrauschen
            : "3"
        );

        setDB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_FontSize(
          Design_Left_Navigation !== undefined &&
            Design_Left_Navigation.left_nav_hauptkat_alleProd_hov_schriftgroesse !==
              undefined
            ? Design_Left_Navigation.left_nav_hauptkat_alleProd_hov_schriftgroesse
            : "19"
        );

        setDB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_FontWeight(
          Design_Left_Navigation !== undefined &&
            Design_Left_Navigation.left_nav_hauptkat_alleProd_hov_schriftgewicht !==
              undefined
            ? Design_Left_Navigation.left_nav_hauptkat_alleProd_hov_schriftgewicht
            : "100"
        );

        setDB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_letterSpacing_Hover(
          Design_Left_Navigation !== undefined &&
            Design_Left_Navigation.left_nav_hauptkat_alleProd_hov_buchstabenabstand !==
              undefined
            ? Design_Left_Navigation.left_nav_hauptkat_alleProd_hov_buchstabenabstand
            : "0"
        );

        setDB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_TextDecoration_Hover(
          Design_Left_Navigation !== undefined &&
            Design_Left_Navigation.left_nav_hauptkat_alleProd_hov_text_dekoration !==
              undefined
            ? Design_Left_Navigation.left_nav_hauptkat_alleProd_hov_text_dekoration
            : "none"
        );

        setDB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_TextDecorationStyle_Hover(
          Design_Left_Navigation !== undefined &&
            Design_Left_Navigation.left_nav_hauptkat_alleProd_hov_text_dekoration_stil !==
              undefined
            ? Design_Left_Navigation.left_nav_hauptkat_alleProd_hov_text_dekoration_stil
            : "none"
        );

        setDB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_marginLeftRight_Hover(
          Design_Left_Navigation !== undefined &&
            Design_Left_Navigation.left_nav_hauptkat_alleProd_hov_aussen_abstand_links_rechts !==
              undefined
            ? Design_Left_Navigation.left_nav_hauptkat_alleProd_hov_aussen_abstand_links_rechts
            : "0"
        );

        setDB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_marginTopBottom_Hover(
          Design_Left_Navigation !== undefined &&
            Design_Left_Navigation.left_nav_hauptkat_alleProd_hov_aussen_abstand_oben_unten !==
              undefined
            ? Design_Left_Navigation.left_nav_hauptkat_alleProd_hov_aussen_abstand_oben_unten
            : "0"
        );

        setDB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_paddingLeftRight_Hover(
          Design_Left_Navigation !== undefined &&
            Design_Left_Navigation.left_nav_hauptkat_alleProd_hov_innerer_abstand_links_rechts !==
              undefined
            ? Design_Left_Navigation.left_nav_hauptkat_alleProd_hov_innerer_abstand_links_rechts
            : "0"
        );

        setDB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_paddingTopBottom_Hover(
          Design_Left_Navigation !== undefined &&
            Design_Left_Navigation.left_nav_hauptkat_alleProd_hov_innerer_abstand_oben_unten !==
              undefined
            ? Design_Left_Navigation.left_nav_hauptkat_alleProd_hov_innerer_abstand_oben_unten
            : "0"
        );

        setDB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_TextAlign(
          Design_Left_Navigation !== undefined &&
            Design_Left_Navigation.left_nav_hauptkat_alleProd_hov_textausrichtung !==
              undefined
            ? Design_Left_Navigation.left_nav_hauptkat_alleProd_hov_textausrichtung
            : "center"
        );

        setDB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_BorderColorLeft(
          Design_Left_Navigation !== undefined &&
            Design_Left_Navigation.left_nav_hauptkat_alleProd_border_color_left !==
              undefined
            ? Design_Left_Navigation.left_nav_hauptkat_alleProd_border_color_left
            : "rgba(0,0,0,0)"
        );
        setDB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_BorderColorLeftNotEncoded(
          Design_Left_Navigation !== undefined &&
            Design_Left_Navigation.left_nav_hauptkat_alleProd_border_color_left_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Left_Navigation.left_nav_hauptkat_alleProd_border_color_left_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0 }
        );
        setDB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_BorderColorRight(
          Design_Left_Navigation !== undefined &&
            Design_Left_Navigation.left_nav_hauptkat_alleProd_border_color_right !==
              undefined
            ? Design_Left_Navigation.left_nav_hauptkat_alleProd_border_color_right
            : "rgba(0,0,0,0)"
        );
        setDB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_BorderColorRightNotEncoded(
          Design_Left_Navigation !== undefined &&
            Design_Left_Navigation.left_nav_hauptkat_alleProd_border_color_right_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Left_Navigation.left_nav_hauptkat_alleProd_border_color_right_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0 }
        );
        setDB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_BorderColorTop(
          Design_Left_Navigation !== undefined &&
            Design_Left_Navigation.left_nav_hauptkat_alleProd_border_color_top !==
              undefined
            ? Design_Left_Navigation.left_nav_hauptkat_alleProd_border_color_top
            : "rgba(255,255,255,1)"
        );
        setDB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_BorderColorTopNotEncoded(
          Design_Left_Navigation !== undefined &&
            Design_Left_Navigation.left_nav_hauptkat_alleProd_border_color_top_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Left_Navigation.left_nav_hauptkat_alleProd_border_color_top_not_encoded
              )
            : { r: 255, g: 255, b: 255, a: 1 }
        );
        setDB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_BorderColorBottom(
          Design_Left_Navigation !== undefined &&
            Design_Left_Navigation.left_nav_hauptkat_alleProd_border_color_bottom !==
              undefined
            ? Design_Left_Navigation.left_nav_hauptkat_alleProd_border_color_bottom
            : "rgba(255,255,255,1)"
        );
        setDB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_BorderColorBottomNotEncoded(
          Design_Left_Navigation !== undefined &&
            Design_Left_Navigation.left_nav_hauptkat_alleProd_border_color_bottom_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Left_Navigation.left_nav_hauptkat_alleProd_border_color_bottom_not_encoded
              )
            : { r: 255, g: 255, b: 255, a: 1 }
        );

        setDB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_BorderLeft(
          Design_Left_Navigation !== undefined &&
            Design_Left_Navigation.left_nav_hauptkat_alleProd_border_pixel_left !==
              undefined
            ? Design_Left_Navigation.left_nav_hauptkat_alleProd_border_pixel_left
            : "0"
        );
        setDB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_BorderRight(
          Design_Left_Navigation !== undefined &&
            Design_Left_Navigation.left_nav_hauptkat_alleProd_border_pixel_right !==
              undefined
            ? Design_Left_Navigation.left_nav_hauptkat_alleProd_border_pixel_right
            : "0"
        );
        setDB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_BorderTop(
          Design_Left_Navigation !== undefined &&
            Design_Left_Navigation.left_nav_hauptkat_alleProd_border_pixel_top !==
              undefined
            ? Design_Left_Navigation.left_nav_hauptkat_alleProd_border_pixel_top
            : "2"
        );
        setDB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_BorderBottom(
          Design_Left_Navigation !== undefined &&
            Design_Left_Navigation.left_nav_hauptkat_alleProd_border_pixel_bottom !==
              undefined
            ? Design_Left_Navigation.left_nav_hauptkat_alleProd_border_pixel_bottom
            : "2"
        );

        setDB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_BorderTopLeftRadius(
          Design_Left_Navigation !== undefined &&
            Design_Left_Navigation.left_nav_hauptkat_alleProd_border_radius_top_left !==
              undefined
            ? Design_Left_Navigation.left_nav_hauptkat_alleProd_border_radius_top_left
            : "0"
        );
        setDB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_BorderTopRightRadius(
          Design_Left_Navigation !== undefined &&
            Design_Left_Navigation.left_nav_hauptkat_alleProd_border_radius_top_right !==
              undefined
            ? Design_Left_Navigation.left_nav_hauptkat_alleProd_border_radius_top_right
            : "0"
        );
        setDB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_BorderBottomLeftRadius(
          Design_Left_Navigation !== undefined &&
            Design_Left_Navigation.left_nav_hauptkat_alleProd_border_radius_bottom_left !==
              undefined
            ? Design_Left_Navigation.left_nav_hauptkat_alleProd_border_radius_bottom_left
            : "0"
        );
        setDB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_BorderBottomRightRadius(
          Design_Left_Navigation !== undefined &&
            Design_Left_Navigation.left_nav_hauptkat_alleProd_border_radius_bottom_right !==
              undefined
            ? Design_Left_Navigation.left_nav_hauptkat_alleProd_border_radius_bottom_right
            : "0"
        );

        setDB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_BorderStyleLeft(
          Design_Left_Navigation !== undefined &&
            Design_Left_Navigation.left_nav_hauptkat_alleProd_border_style_left !==
              undefined
            ? Design_Left_Navigation.left_nav_hauptkat_alleProd_border_style_left
            : "solid"
        );
        setDB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_BorderStyleRight(
          Design_Left_Navigation !== undefined &&
            Design_Left_Navigation.left_nav_hauptkat_alleProd_border_style_right !==
              undefined
            ? Design_Left_Navigation.left_nav_hauptkat_alleProd_border_style_right
            : "solid"
        );
        setDB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_BorderStyleTop(
          Design_Left_Navigation !== undefined &&
            Design_Left_Navigation.left_nav_hauptkat_alleProd_border_style_top !==
              undefined
            ? Design_Left_Navigation.left_nav_hauptkat_alleProd_border_style_top
            : "solid"
        );
        setDB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_BorderStyleBottom(
          Design_Left_Navigation !== undefined &&
            Design_Left_Navigation.left_nav_hauptkat_alleProd_border_style_bottom !==
              undefined
            ? Design_Left_Navigation.left_nav_hauptkat_alleProd_border_style_bottom
            : "solid"
        );

        setDB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_BorderColorLeft(
          Design_Left_Navigation !== undefined &&
            Design_Left_Navigation.left_nav_hauptkat_alleProd_hov_border_color_left !==
              undefined
            ? Design_Left_Navigation.left_nav_hauptkat_alleProd_hov_border_color_left
            : "rgba(255,255,255,1)"
        );
        setDB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_BorderColorLeftNotEncoded(
          Design_Left_Navigation !== undefined &&
            Design_Left_Navigation.left_nav_hauptkat_alleProd_hov_border_color_left_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Left_Navigation.left_nav_hauptkat_alleProd_hov_border_color_left_not_encoded
              )
            : { r: 255, g: 255, b: 255, a: 1 }
        );
        setDB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_BorderColorRight(
          Design_Left_Navigation !== undefined &&
            Design_Left_Navigation.left_nav_hauptkat_alleProd_hov_border_color_right !==
              undefined
            ? Design_Left_Navigation.left_nav_hauptkat_alleProd_hov_border_color_right
            : "rgba(255,255,255,1)"
        );
        setDB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_BorderColorRightNotEncoded(
          Design_Left_Navigation !== undefined &&
            Design_Left_Navigation.left_nav_hauptkat_alleProd_hov_border_color_right_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Left_Navigation.left_nav_hauptkat_alleProd_hov_border_color_right_not_encoded
              )
            : { r: 255, g: 255, b: 255, a: 1 }
        );
        setDB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_BorderColorTop(
          Design_Left_Navigation !== undefined &&
            Design_Left_Navigation.left_nav_hauptkat_alleProd_hov_border_color_top !==
              undefined
            ? Design_Left_Navigation.left_nav_hauptkat_alleProd_hov_border_color_top
            : "rgba(255,255,255,1)"
        );
        setDB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_BorderColorTopNotEncoded(
          Design_Left_Navigation !== undefined &&
            Design_Left_Navigation.left_nav_hauptkat_alleProd_hov_border_color_top_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Left_Navigation.left_nav_hauptkat_alleProd_hov_border_color_top_not_encoded
              )
            : { r: 255, g: 255, b: 255, a: 1 }
        );
        setDB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_BorderColorBottom(
          Design_Left_Navigation !== undefined &&
            Design_Left_Navigation.left_nav_hauptkat_alleProd_hov_border_color_bottom !==
              undefined
            ? Design_Left_Navigation.left_nav_hauptkat_alleProd_hov_border_color_bottom
            : "rgba(255,255,255,1)"
        );
        setDB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_BorderColorBottomNotEncoded(
          Design_Left_Navigation !== undefined &&
            Design_Left_Navigation.left_nav_hauptkat_alleProd_hov_border_color_bottom_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Left_Navigation.left_nav_hauptkat_alleProd_hov_border_color_bottom_not_encoded
              )
            : { r: 255, g: 255, b: 255, a: 1 }
        );

        setDB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_BorderLeft(
          Design_Left_Navigation !== undefined &&
            Design_Left_Navigation.left_nav_hauptkat_alleProd_hov_border_pixel_left !==
              undefined
            ? Design_Left_Navigation.left_nav_hauptkat_alleProd_hov_border_pixel_left
            : "0"
        );
        setDB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_BorderRight(
          Design_Left_Navigation !== undefined &&
            Design_Left_Navigation.left_nav_hauptkat_alleProd_hov_border_pixel_right !==
              undefined
            ? Design_Left_Navigation.left_nav_hauptkat_alleProd_hov_border_pixel_right
            : "0"
        );
        setDB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_BorderTop(
          Design_Left_Navigation !== undefined &&
            Design_Left_Navigation.left_nav_hauptkat_alleProd_hov_border_pixel_top !==
              undefined
            ? Design_Left_Navigation.left_nav_hauptkat_alleProd_hov_border_pixel_top
            : "2"
        );
        setDB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_BorderBottom(
          Design_Left_Navigation !== undefined &&
            Design_Left_Navigation.left_nav_hauptkat_alleProd_hov_border_pixel_bottom !==
              undefined
            ? Design_Left_Navigation.left_nav_hauptkat_alleProd_hov_border_pixel_bottom
            : "2"
        );

        setDB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_BorderTopLeftRadius(
          Design_Left_Navigation !== undefined &&
            Design_Left_Navigation.left_nav_hauptkat_alleProd_hov_border_radius_top_left !==
              undefined
            ? Design_Left_Navigation.left_nav_hauptkat_alleProd_hov_border_radius_top_left
            : "0"
        );
        setDB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_BorderTopRightRadius(
          Design_Left_Navigation !== undefined &&
            Design_Left_Navigation.left_nav_hauptkat_alleProd_hov_border_radius_top_right !==
              undefined
            ? Design_Left_Navigation.left_nav_hauptkat_alleProd_hov_border_radius_top_right
            : "0"
        );
        setDB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_BorderBottomLeftRadius(
          Design_Left_Navigation !== undefined &&
            Design_Left_Navigation.left_nav_hauptkat_alleProd_hov_border_radius_bottom_left !==
              undefined
            ? Design_Left_Navigation.left_nav_hauptkat_alleProd_hov_border_radius_bottom_left
            : "0"
        );
        setDB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_BorderBottomRightRadius(
          Design_Left_Navigation !== undefined &&
            Design_Left_Navigation.left_nav_hauptkat_alleProd_hov_border_radius_bottom_right !==
              undefined
            ? Design_Left_Navigation.left_nav_hauptkat_alleProd_hov_border_radius_bottom_right
            : "0"
        );

        setDB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_BorderStyleLeft(
          Design_Left_Navigation !== undefined &&
            Design_Left_Navigation.left_nav_hauptkat_alleProd_hov_border_style_left !==
              undefined
            ? Design_Left_Navigation.left_nav_hauptkat_alleProd_hov_border_style_left
            : "solid"
        );
        setDB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_BorderStyleRight(
          Design_Left_Navigation !== undefined &&
            Design_Left_Navigation.left_nav_hauptkat_alleProd_hov_border_style_right !==
              undefined
            ? Design_Left_Navigation.left_nav_hauptkat_alleProd_hov_border_style_right
            : "solid"
        );
        setDB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_BorderStyleTop(
          Design_Left_Navigation !== undefined &&
            Design_Left_Navigation.left_nav_hauptkat_alleProd_hov_border_style_top !==
              undefined
            ? Design_Left_Navigation.left_nav_hauptkat_alleProd_hov_border_style_top
            : "solid"
        );
        setDB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_BorderStyleBottom(
          Design_Left_Navigation !== undefined &&
            Design_Left_Navigation.left_nav_hauptkat_alleProd_hov_border_style_bottom !==
              undefined
            ? Design_Left_Navigation.left_nav_hauptkat_alleProd_hov_border_style_bottom
            : "solid"
        );

        setDB_LeftSidebar_MenuItem_Hauptkategorie_FontColor(
          Design_Left_Categories !== undefined &&
            Design_Left_Categories.left_hauptkat_schriftfarbe !== undefined
            ? Design_Left_Categories.left_hauptkat_schriftfarbe
            : "rgba(255,255,255,1)"
        );
        setDB_LeftSidebar_MenuItem_Hauptkategorie_FontColorNotEncoded(
          Design_Left_Categories !== undefined &&
            Design_Left_Categories.left_hauptkat_schriftfarbe_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Left_Categories.left_hauptkat_schriftfarbe_not_encoded
              )
            : { r: 255, g: 255, b: 255, a: 1 }
        );
        setDB_LeftSidebar_MenuItem_Hauptkategorie_BackgroundColor(
          Design_Left_Categories !== undefined &&
            Design_Left_Categories.left_hauptkat_hintergrundfarbe !== undefined
            ? Design_Left_Categories.left_hauptkat_hintergrundfarbe
            : "rgba(0,0,0,0.15)"
        );
        setDB_LeftSidebar_MenuItem_Hauptkategorie_BackgroundColorNotEncoded(
          Design_Left_Categories !== undefined &&
            Design_Left_Categories.left_hauptkat_hintergrundfarbe_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Left_Categories.left_hauptkat_hintergrundfarbe_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0.15 }
        );
        setDB_LeftSidebar_MenuItem_Hauptkategorie_ShadowColor(
          Design_Left_Categories !== undefined &&
            Design_Left_Categories.left_hauptkat_schattenfarbe !== undefined
            ? Design_Left_Categories.left_hauptkat_schattenfarbe
            : "rgba(0,0,0,0.3)"
        );
        setDB_LeftSidebar_MenuItem_Hauptkategorie_ShadowColorNotEncoded(
          Design_Left_Categories !== undefined &&
            Design_Left_Categories.left_hauptkat_schattenfarbe_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Left_Categories.left_hauptkat_schattenfarbe_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 1 }
        );
        setDB_LeftSidebar_MenuItem_Hauptkategorie_ShadowHorizontal(
          Design_Left_Categories !== undefined &&
            Design_Left_Categories.left_hauptkat_schattenposition_horizontal !==
              undefined
            ? Design_Left_Categories.left_hauptkat_schattenposition_horizontal
            : "2"
        );
        setDB_LeftSidebar_MenuItem_Hauptkategorie_ShadowVertical(
          Design_Left_Categories !== undefined &&
            Design_Left_Categories.left_hauptkat_schattenposition_vertikal !==
              undefined
            ? Design_Left_Categories.left_hauptkat_schattenposition_vertikal
            : "2"
        );
        setDB_LeftSidebar_MenuItem_Hauptkategorie_ShadowBlur(
          Design_Left_Categories !== undefined &&
            Design_Left_Categories.left_hauptkat_schattenrauschen !== undefined
            ? Design_Left_Categories.left_hauptkat_schattenrauschen
            : "3"
        );
        setDB_LeftSidebar_MenuItem_Hauptkategorie_FontSize(
          Design_Left_Categories !== undefined &&
            Design_Left_Categories.left_hauptkat_schriftgroesse !== undefined
            ? Design_Left_Categories.left_hauptkat_schriftgroesse
            : "18"
        );
        setDB_LeftSidebar_MenuItem_Hauptkategorie_FontWeight(
          Design_Left_Categories !== undefined &&
            Design_Left_Categories.left_hauptkat_schriftgewicht !== undefined
            ? Design_Left_Categories.left_hauptkat_schriftgewicht
            : "300"
        );
        setDB_LeftSidebar_MenuItem_Hauptkategorie_letterSpacing(
          Design_Left_Categories !== undefined &&
            Design_Left_Categories.left_hauptkat_buchstabenabstand !== undefined
            ? Design_Left_Categories.left_hauptkat_buchstabenabstand
            : "1"
        );
        setDB_LeftSidebar_MenuItem_Hauptkategorie_TextDecoration(
          Design_Left_Categories !== undefined &&
            Design_Left_Categories.left_hauptkat_text_dekoration !== undefined
            ? Design_Left_Categories.left_hauptkat_text_dekoration
            : "none"
        );
        setDB_LeftSidebar_MenuItem_Hauptkategorie_TextDecorationStyle(
          Design_Left_Categories !== undefined &&
            Design_Left_Categories.left_hauptkat_text_dekoration_stil !==
              undefined
            ? Design_Left_Categories.left_hauptkat_text_dekoration_stil
            : "none"
        );
        setDB_LeftSidebar_MenuItem_Hauptkategorie_marginLeftRight(
          Design_Left_Categories !== undefined &&
            Design_Left_Categories.left_hauptkat_aussen_abstand_links_rechts !==
              undefined
            ? Design_Left_Categories.left_hauptkat_aussen_abstand_links_rechts
            : "15"
        );
        setDB_LeftSidebar_MenuItem_Hauptkategorie_marginTopBottom(
          Design_Left_Categories !== undefined &&
            Design_Left_Categories.left_hauptkat_aussen_abstand_oben_unten !==
              undefined
            ? Design_Left_Categories.left_hauptkat_aussen_abstand_oben_unten
            : "5"
        );
        setDB_LeftSidebar_MenuItem_Hauptkategorie_paddingLeftRight(
          Design_Left_Categories !== undefined &&
            Design_Left_Categories.left_hauptkat_innerer_abstand_links_rechts !==
              undefined
            ? Design_Left_Categories.left_hauptkat_innerer_abstand_links_rechts
            : "0"
        );
        setDB_LeftSidebar_MenuItem_Hauptkategorie_paddingTopBottom(
          Design_Left_Categories !== undefined &&
            Design_Left_Categories.left_hauptkat_innerer_abstand_oben_unten !==
              undefined
            ? Design_Left_Categories.left_hauptkat_innerer_abstand_oben_unten
            : "2"
        );
        setDB_LeftSidebar_MenuItem_Hauptkategorie_TextAlign(
          Design_Left_Categories !== undefined &&
            Design_Left_Categories.left_hauptkat_textausrichtung !== undefined
            ? Design_Left_Categories.left_hauptkat_textausrichtung
            : "center"
        );

        setDB_fontFace_hauptkategorieTitel_FontFamilyName(
          Design_Left_Categories !== undefined &&
            Design_Left_Categories.left_hauptkat_fontfamily !== undefined
            ? Design_Left_Categories.left_hauptkat_fontfamily
            : "Lato"
        );

        setDB_fontFace_hauptkategorieTitel_FontFamilySrc(
          Design_Left_Categories !== undefined &&
            Design_Left_Categories.left_hauptkat_fontfamilysrc !== undefined
            ? Design_Left_Categories.left_hauptkat_fontfamilysrc
            : "/assets/Lato/Lato-Regular.ttf"
        );

        setDB_LeftSidebar_MenuItem_Hauptkategorie_Hover_FontColor(
          Design_Left_Categories !== undefined &&
            Design_Left_Categories.left_hauptkat_hov_schriftfarbe !== undefined
            ? Design_Left_Categories.left_hauptkat_hov_schriftfarbe
            : "rgba(255,255,255,1)"
        );
        setDB_LeftSidebar_MenuItem_Hauptkategorie_Hover_FontColorNotEncoded(
          Design_Left_Categories !== undefined &&
            Design_Left_Categories.left_hauptkat_hov_schriftfarbe_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Left_Categories.left_hauptkat_hov_schriftfarbe_not_encoded
              )
            : { r: 255, g: 255, b: 255, a: 1 }
        );
        setDB_LeftSidebar_MenuItem_Hauptkategorie_Hover_BackgroundColor(
          Design_Left_Categories !== undefined &&
            Design_Left_Categories.left_hauptkat_hov_hintergrundfarbe !==
              undefined
            ? Design_Left_Categories.left_hauptkat_hov_hintergrundfarbe
            : "rgba(0,0,0,0.3)"
        );
        setDB_LeftSidebar_MenuItem_Hauptkategorie_Hover_BackgroundColorNotEncoded(
          Design_Left_Categories !== undefined &&
            Design_Left_Categories.left_hauptkat_hov_hintergrundfarbe_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Left_Categories.left_hauptkat_hov_hintergrundfarbe_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0.3 }
        );
        setDB_LeftSidebar_MenuItem_Hauptkategorie_ShadowColor_Hover(
          Design_Left_Categories !== undefined &&
            Design_Left_Categories.left_hauptkat_hov_schattenfarbe !== undefined
            ? Design_Left_Categories.left_hauptkat_hov_schattenfarbe
            : "rgba(0,0,0,1)"
        );
        setDB_LeftSidebar_MenuItem_Hauptkategorie_ShadowColorNotEncoded_Hover(
          Design_Left_Categories !== undefined &&
            Design_Left_Categories.left_hauptkat_hov_schattenfarbe_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Left_Categories.left_hauptkat_hov_schattenfarbe_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 1 }
        );
        setDB_LeftSidebar_MenuItem_Hauptkategorie_ShadowHorizontal_Hover(
          Design_Left_Categories !== undefined &&
            Design_Left_Categories.left_hauptkat_hov_schattenposition_horizontal !==
              undefined
            ? Design_Left_Categories.left_hauptkat_hov_schattenposition_horizontal
            : "3"
        );
        setDB_LeftSidebar_MenuItem_Hauptkategorie_ShadowVertical_Hover(
          Design_Left_Categories !== undefined &&
            Design_Left_Categories.left_hauptkat_hov_schattenposition_vertikal !==
              undefined
            ? Design_Left_Categories.left_hauptkat_hov_schattenposition_vertikal
            : "3"
        );
        setDB_LeftSidebar_MenuItem_Hauptkategorie_ShadowBlur_Hover(
          Design_Left_Categories !== undefined &&
            Design_Left_Categories.left_hauptkat_hov_schattenrauschen !==
              undefined
            ? Design_Left_Categories.left_hauptkat_hov_schattenrauschen
            : "5"
        );
        setDB_LeftSidebar_MenuItem_Hauptkategorie_Hover_FontSize(
          Design_Left_Categories !== undefined &&
            Design_Left_Categories.left_hauptkat_hov_schriftgroesse !==
              undefined
            ? Design_Left_Categories.left_hauptkat_hov_schriftgroesse
            : "18"
        );
        setDB_LeftSidebar_MenuItem_Hauptkategorie_Hover_FontWeight(
          Design_Left_Categories !== undefined &&
            Design_Left_Categories.left_hauptkat_hov_schriftgewicht !==
              undefined
            ? Design_Left_Categories.left_hauptkat_hov_schriftgewicht
            : "300"
        );
        setDB_LeftSidebar_MenuItem_Hauptkategorie_letterSpacing_Hover(
          Design_Left_Categories !== undefined &&
            Design_Left_Categories.left_hauptkat_hov_buchstabenabstand !==
              undefined
            ? Design_Left_Categories.left_hauptkat_hov_buchstabenabstand
            : "1"
        );

        setDB_LeftSidebar_MenuItem_Hauptkategorie_TextDecoration_Hover(
          Design_Left_Categories !== undefined &&
            Design_Left_Categories.left_hauptkat_hov_text_dekoration !==
              undefined
            ? Design_Left_Categories.left_hauptkat_hov_text_dekoration
            : "none"
        );
        setDB_LeftSidebar_MenuItem_Hauptkategorie_TextDecorationStyle_Hover(
          Design_Left_Categories !== undefined &&
            Design_Left_Categories.left_hauptkat_hov_text_dekoration_stil !==
              undefined
            ? Design_Left_Categories.left_hauptkat_hov_text_dekoration_stil
            : "none"
        );
        setDB_LeftSidebar_MenuItem_Hauptkategorie_marginLeftRight_Hover(
          Design_Left_Categories !== undefined &&
            Design_Left_Categories.left_hauptkat_hov_aussen_abstand_links_rechts !==
              undefined
            ? Design_Left_Categories.left_hauptkat_hov_aussen_abstand_links_rechts
            : "15"
        );
        setDB_LeftSidebar_MenuItem_Hauptkategorie_marginTopBottom_Hover(
          Design_Left_Categories !== undefined &&
            Design_Left_Categories.left_hauptkat_hov_aussen_abstand_oben_unten !==
              undefined
            ? Design_Left_Categories.left_hauptkat_hov_aussen_abstand_oben_unten
            : "5"
        );
        setDB_LeftSidebar_MenuItem_Hauptkategorie_paddingLeftRight_Hover(
          Design_Left_Categories !== undefined &&
            Design_Left_Categories.left_hauptkat_hov_innerer_abstand_links_rechts !==
              undefined
            ? Design_Left_Categories.left_hauptkat_hov_innerer_abstand_links_rechts
            : "0"
        );
        setDB_LeftSidebar_MenuItem_Hauptkategorie_paddingTopBottom_Hover(
          Design_Left_Categories !== undefined &&
            Design_Left_Categories.left_hauptkat_hov_innerer_abstand_oben_unten !==
              undefined
            ? Design_Left_Categories.left_hauptkat_hov_innerer_abstand_oben_unten
            : "2"
        );
        setDB_LeftSidebar_MenuItem_Hauptkategorie_Hover_TextAlign(
          Design_Left_Categories !== undefined &&
            Design_Left_Categories.left_hauptkat_hov_textausrichtung !==
              undefined
            ? Design_Left_Categories.left_hauptkat_hov_textausrichtung
            : "center"
        );

        setDB_LeftSidebar_MenuItem_Hauptkategorie_BorderColorLeft(
          Design_Left_Categories !== undefined &&
            Design_Left_Categories.left_hauptkat_border_color_left !== undefined
            ? Design_Left_Categories.left_hauptkat_border_color_left
            : "rgba(255,255,255,0.8)"
        );
        setDB_LeftSidebar_MenuItem_Hauptkategorie_BorderColorLeftNotEncoded(
          Design_Left_Categories !== undefined &&
            Design_Left_Categories.left_hauptkat_border_color_left_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Left_Categories.left_hauptkat_border_color_left_not_encoded
              )
            : { r: 255, g: 255, b: 255, a: 0.8 }
        );
        setDB_LeftSidebar_MenuItem_Hauptkategorie_BorderColorRight(
          Design_Left_Categories !== undefined &&
            Design_Left_Categories.left_hauptkat_border_color_right !==
              undefined
            ? Design_Left_Categories.left_hauptkat_border_color_right
            : "rgba(255,255,255,0.8)"
        );
        setDB_LeftSidebar_MenuItem_Hauptkategorie_BorderColorRightNotEncoded(
          Design_Left_Categories !== undefined &&
            Design_Left_Categories.left_hauptkat_border_color_right_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Left_Categories.left_hauptkat_border_color_right_not_encoded
              )
            : { r: 255, g: 255, b: 255, a: 0.8 }
        );
        setDB_LeftSidebar_MenuItem_Hauptkategorie_BorderColorTop(
          Design_Left_Categories !== undefined &&
            Design_Left_Categories.left_hauptkat_border_color_top !== undefined
            ? Design_Left_Categories.left_hauptkat_border_color_top
            : "rgba(255,255,255,0.8)"
        );
        setDB_LeftSidebar_MenuItem_Hauptkategorie_BorderColorTopNotEncoded(
          Design_Left_Categories !== undefined &&
            Design_Left_Categories.left_hauptkat_border_color_top_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Left_Categories.left_hauptkat_border_color_top_not_encoded
              )
            : { r: 255, g: 255, b: 255, a: 0.8 }
        );
        setDB_LeftSidebar_MenuItem_Hauptkategorie_BorderColorBottom(
          Design_Left_Categories !== undefined &&
            Design_Left_Categories.left_hauptkat_border_color_bottom !==
              undefined
            ? Design_Left_Categories.left_hauptkat_border_color_bottom
            : "rgba(255,255,255,0.8)"
        );
        setDB_LeftSidebar_MenuItem_Hauptkategorie_BorderColorBottomNotEncoded(
          Design_Left_Categories !== undefined &&
            Design_Left_Categories.left_hauptkat_border_color_bottom_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Left_Categories.left_hauptkat_border_color_bottom_not_encoded
              )
            : { r: 255, g: 255, b: 255, a: 0.8 }
        );

        setDB_LeftSidebar_MenuItem_Hauptkategorie_BorderLeft(
          Design_Left_Categories !== undefined &&
            Design_Left_Categories.left_hauptkat_border_pixel_left !== undefined
            ? Design_Left_Categories.left_hauptkat_border_pixel_left
            : "1"
        );
        setDB_LeftSidebar_MenuItem_Hauptkategorie_BorderRight(
          Design_Left_Categories !== undefined &&
            Design_Left_Categories.left_hauptkat_border_pixel_right !==
              undefined
            ? Design_Left_Categories.left_hauptkat_border_pixel_right
            : "1"
        );
        setDB_LeftSidebar_MenuItem_Hauptkategorie_BorderTop(
          Design_Left_Categories !== undefined &&
            Design_Left_Categories.left_hauptkat_border_pixel_top !== undefined
            ? Design_Left_Categories.left_hauptkat_border_pixel_top
            : "1"
        );
        setDB_LeftSidebar_MenuItem_Hauptkategorie_BorderBottom(
          Design_Left_Categories !== undefined &&
            Design_Left_Categories.left_hauptkat_border_pixel_bottom !==
              undefined
            ? Design_Left_Categories.left_hauptkat_border_pixel_bottom
            : "1"
        );

        setDB_LeftSidebar_MenuItem_Hauptkategorie_BorderTopLeftRadius(
          Design_Left_Categories !== undefined &&
            Design_Left_Categories.left_hauptkat_border_radius_top_left !==
              undefined
            ? Design_Left_Categories.left_hauptkat_border_radius_top_left
            : "7"
        );
        setDB_LeftSidebar_MenuItem_Hauptkategorie_BorderTopRightRadius(
          Design_Left_Categories !== undefined &&
            Design_Left_Categories.left_hauptkat_border_radius_top_right !==
              undefined
            ? Design_Left_Categories.left_hauptkat_border_radius_top_right
            : "7"
        );
        setDB_LeftSidebar_MenuItem_Hauptkategorie_BorderBottomLeftRadius(
          Design_Left_Categories !== undefined &&
            Design_Left_Categories.left_hauptkat_border_radius_bottom_left !==
              undefined
            ? Design_Left_Categories.left_hauptkat_border_radius_bottom_left
            : "7"
        );
        setDB_LeftSidebar_MenuItem_Hauptkategorie_BorderBottomRightRadius(
          Design_Left_Categories !== undefined &&
            Design_Left_Categories.left_hauptkat_border_radius_bottom_right !==
              undefined
            ? Design_Left_Categories.left_hauptkat_border_radius_bottom_right
            : "7"
        );

        setDB_LeftSidebar_MenuItem_Hauptkategorie_BorderStyleLeft(
          Design_Left_Categories !== undefined &&
            Design_Left_Categories.left_hauptkat_border_style_left !== undefined
            ? Design_Left_Categories.left_hauptkat_border_style_left
            : "solid"
        );
        setDB_LeftSidebar_MenuItem_Hauptkategorie_BorderStyleRight(
          Design_Left_Categories !== undefined &&
            Design_Left_Categories.left_hauptkat_border_style_right !==
              undefined
            ? Design_Left_Categories.left_hauptkat_border_style_right
            : "solid"
        );
        setDB_LeftSidebar_MenuItem_Hauptkategorie_BorderStyleTop(
          Design_Left_Categories !== undefined &&
            Design_Left_Categories.left_hauptkat_border_style_top !== undefined
            ? Design_Left_Categories.left_hauptkat_border_style_top
            : "solid"
        );
        setDB_LeftSidebar_MenuItem_Hauptkategorie_BorderStyleBottom(
          Design_Left_Categories !== undefined &&
            Design_Left_Categories.left_hauptkat_border_style_bottom !==
              undefined
            ? Design_Left_Categories.left_hauptkat_border_style_bottom
            : "solid"
        );

        setDB_LeftSidebar_MenuItem_Hauptkategorie_Hover_BorderColorLeft(
          Design_Left_Categories !== undefined &&
            Design_Left_Categories.left_hauptkat_border_hov_color_left !==
              undefined
            ? Design_Left_Categories.left_hauptkat_border_hov_color_left
            : "rgba(255,255,255,1)"
        );
        setDB_LeftSidebar_MenuItem_Hauptkategorie_Hover_BorderColorLeftNotEncoded(
          Design_Left_Categories !== undefined &&
            Design_Left_Categories.left_hauptkat_border_hov_color_left_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Left_Categories.left_hauptkat_border_hov_color_left_not_encoded
              )
            : { r: 255, g: 255, b: 255, a: 1 }
        );
        setDB_LeftSidebar_MenuItem_Hauptkategorie_Hover_BorderColorRight(
          Design_Left_Categories !== undefined &&
            Design_Left_Categories.left_hauptkat_border_hov_color_right !==
              undefined
            ? Design_Left_Categories.left_hauptkat_border_hov_color_right
            : "rgba(255,255,255,1)"
        );
        setDB_LeftSidebar_MenuItem_Hauptkategorie_Hover_BorderColorRightNotEncoded(
          Design_Left_Categories !== undefined &&
            Design_Left_Categories.left_hauptkat_border_hov_color_right_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Left_Categories.left_hauptkat_border_hov_color_right_not_encoded
              )
            : { r: 255, g: 255, b: 255, a: 1 }
        );
        setDB_LeftSidebar_MenuItem_Hauptkategorie_Hover_BorderColorTop(
          Design_Left_Categories !== undefined &&
            Design_Left_Categories.left_hauptkat_border_hov_color_top !==
              undefined
            ? Design_Left_Categories.left_hauptkat_border_hov_color_top
            : "rgba(255,255,255,1)"
        );
        setDB_LeftSidebar_MenuItem_Hauptkategorie_Hover_BorderColorTopNotEncoded(
          Design_Left_Categories !== undefined &&
            Design_Left_Categories.left_hauptkat_border_hov_color_top_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Left_Categories.left_hauptkat_border_hov_color_top_not_encoded
              )
            : { r: 255, g: 255, b: 255, a: 1 }
        );
        setDB_LeftSidebar_MenuItem_Hauptkategorie_Hover_BorderColorBottom(
          Design_Left_Categories !== undefined &&
            Design_Left_Categories.left_hauptkat_border_hov_color_bottom !==
              undefined
            ? Design_Left_Categories.left_hauptkat_border_hov_color_bottom
            : "rgba(255,255,255,1)"
        );
        setDB_LeftSidebar_MenuItem_Hauptkategorie_Hover_BorderColorBottomNotEncoded(
          Design_Left_Categories !== undefined &&
            Design_Left_Categories.left_hauptkat_border_hov_color_bottom_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Left_Categories.left_hauptkat_border_hov_color_bottom_not_encoded
              )
            : { r: 255, g: 255, b: 255, a: 1 }
        );

        setDB_LeftSidebar_MenuItem_Hauptkategorie_Hover_BorderLeft(
          Design_Left_Categories !== undefined &&
            Design_Left_Categories.left_hauptkat_border_hov_pixel_left !==
              undefined
            ? Design_Left_Categories.left_hauptkat_border_hov_pixel_left
            : "1"
        );
        setDB_LeftSidebar_MenuItem_Hauptkategorie_Hover_BorderRight(
          Design_Left_Categories !== undefined &&
            Design_Left_Categories.left_hauptkat_border_hov_pixel_right !==
              undefined
            ? Design_Left_Categories.left_hauptkat_border_hov_pixel_right
            : "1"
        );
        setDB_LeftSidebar_MenuItem_Hauptkategorie_Hover_BorderTop(
          Design_Left_Categories !== undefined &&
            Design_Left_Categories.left_hauptkat_border_hov_pixel_top !==
              undefined
            ? Design_Left_Categories.left_hauptkat_border_hov_pixel_top
            : "1"
        );
        setDB_LeftSidebar_MenuItem_Hauptkategorie_Hover_BorderBottom(
          Design_Left_Categories !== undefined &&
            Design_Left_Categories.left_hauptkat_border_hov_pixel_bottom !==
              undefined
            ? Design_Left_Categories.left_hauptkat_border_hov_pixel_bottom
            : "1"
        );

        setDB_LeftSidebar_MenuItem_Hauptkategorie_Hover_BorderTopLeftRadius(
          Design_Left_Categories !== undefined &&
            Design_Left_Categories.left_hauptkat_border_hov_radius_top_left !==
              undefined
            ? Design_Left_Categories.left_hauptkat_border_hov_radius_top_left
            : "7"
        );
        setDB_LeftSidebar_MenuItem_Hauptkategorie_Hover_BorderTopRightRadius(
          Design_Left_Categories !== undefined &&
            Design_Left_Categories.left_hauptkat_border_hov_radius_top_right !==
              undefined
            ? Design_Left_Categories.left_hauptkat_border_hov_radius_top_right
            : "7"
        );
        setDB_LeftSidebar_MenuItem_Hauptkategorie_Hover_BorderBottomLeftRadius(
          Design_Left_Categories !== undefined &&
            Design_Left_Categories.left_hauptkat_border_hov_radius_bottom_left !==
              undefined
            ? Design_Left_Categories.left_hauptkat_border_hov_radius_bottom_left
            : "7"
        );
        setDB_LeftSidebar_MenuItem_Hauptkategorie_Hover_BorderBottomRightRadius(
          Design_Left_Categories !== undefined &&
            Design_Left_Categories.left_hauptkat_border_hov_radius_bottom_right !==
              undefined
            ? Design_Left_Categories.left_hauptkat_border_hov_radius_bottom_right
            : "7"
        );

        setDB_LeftSidebar_MenuItem_Hauptkategorie_Hover_BorderStyleLeft(
          Design_Left_Categories !== undefined &&
            Design_Left_Categories.left_hauptkat_border_hov_style_left !==
              undefined
            ? Design_Left_Categories.left_hauptkat_border_hov_style_left
            : "solid"
        );
        setDB_LeftSidebar_MenuItem_Hauptkategorie_Hover_BorderStyleRight(
          Design_Left_Categories !== undefined &&
            Design_Left_Categories.left_hauptkat_border_hov_style_right !==
              undefined
            ? Design_Left_Categories.left_hauptkat_border_hov_style_right
            : "solid"
        );
        setDB_LeftSidebar_MenuItem_Hauptkategorie_Hover_BorderStyleTop(
          Design_Left_Categories !== undefined &&
            Design_Left_Categories.left_hauptkat_border_hov_style_top !==
              undefined
            ? Design_Left_Categories.left_hauptkat_border_hov_style_top
            : "solid"
        );
        setDB_LeftSidebar_MenuItem_Hauptkategorie_Hover_BorderStyleBottom(
          Design_Left_Categories !== undefined &&
            Design_Left_Categories.left_hauptkat_border_hov_style_bottom !==
              undefined
            ? Design_Left_Categories.left_hauptkat_border_hov_style_bottom
            : "solid"
        );

        setDB_LeftSidebar_MenuItem_Unterkategorie_FontColor(
          Design_Left_Categories !== undefined &&
            Design_Left_Categories.left_unterkat_schriftfarbe !== undefined
            ? Design_Left_Categories.left_unterkat_schriftfarbe
            : "rgba(255,255,255,1)"
        );
        setDB_LeftSidebar_MenuItem_Unterkategorie_FontColorNotEncoded(
          Design_Left_Categories !== undefined &&
            Design_Left_Categories.left_unterkat_schriftfarbe_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Left_Categories.left_unterkat_schriftfarbe_not_encoded
              )
            : { r: 255, g: 255, b: 255, a: 1 }
        );
        setDB_LeftSidebar_MenuItem_Unterkategorie_BackgroundColor(
          Design_Left_Categories !== undefined &&
            Design_Left_Categories.left_unterkat_hintergrundfarbe !== undefined
            ? Design_Left_Categories.left_unterkat_hintergrundfarbe
            : "rgba(255,255,255,0.3)"
        );
        setDB_LeftSidebar_MenuItem_Unterkategorie_BackgroundColorNotEncoded(
          Design_Left_Categories !== undefined &&
            Design_Left_Categories.left_unterkat_hintergrundfarbe_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Left_Categories.left_unterkat_hintergrundfarbe_not_encoded
              )
            : { r: 255, g: 255, b: 255, a: 0.3 }
        );
        setDB_LeftSidebar_MenuItem_Unterkategorie_ShadowColor(
          Design_Left_Categories !== undefined &&
            Design_Left_Categories.left_unterkat_schattenfarbe !== undefined
            ? Design_Left_Categories.left_unterkat_schattenfarbe
            : "rgba(0,0,0,0.3)"
        );
        setDB_LeftSidebar_MenuItem_Unterkategorie_ShadowColorNotEncoded(
          Design_Left_Categories !== undefined &&
            Design_Left_Categories.left_unterkat_schattenfarbe_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Left_Categories.left_unterkat_schattenfarbe_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0.3 }
        );
        setDB_LeftSidebar_MenuItem_Unterkategorie_ShadowHorizontal(
          Design_Left_Categories !== undefined &&
            Design_Left_Categories.left_unterkat_schattenposition_horizontal !==
              undefined
            ? Design_Left_Categories.left_unterkat_schattenposition_horizontal
            : "2"
        );
        setDB_LeftSidebar_MenuItem_Unterkategorie_ShadowVertical(
          Design_Left_Categories !== undefined &&
            Design_Left_Categories.left_unterkat_schattenposition_vertikal !==
              undefined
            ? Design_Left_Categories.left_unterkat_schattenposition_vertikal
            : "2"
        );
        setDB_LeftSidebar_MenuItem_Unterkategorie_ShadowBlur(
          Design_Left_Categories !== undefined &&
            Design_Left_Categories.left_unterkat_schattenrauschen !== undefined
            ? Design_Left_Categories.left_unterkat_schattenrauschen
            : "3"
        );
        setDB_LeftSidebar_MenuItem_Unterkategorie_FontSize(
          Design_Left_Categories !== undefined &&
            Design_Left_Categories.left_unterkat_schriftgroesse !== undefined
            ? Design_Left_Categories.left_unterkat_schriftgroesse
            : "16"
        );
        setDB_LeftSidebar_MenuItem_Unterkategorie_FontWeight(
          Design_Left_Categories !== undefined &&
            Design_Left_Categories.left_unterkat_schriftgewicht !== undefined
            ? Design_Left_Categories.left_unterkat_schriftgewicht
            : "300"
        );
        setDB_LeftSidebar_MenuItem_Unterkategorie_letterSpacing(
          Design_Left_Categories !== undefined &&
            Design_Left_Categories.left_unterkat_buchstabenabstand !== undefined
            ? Design_Left_Categories.left_unterkat_buchstabenabstand
            : "1"
        );
        setDB_LeftSidebar_MenuItem_Unterkategorie_TextDecoration(
          Design_Left_Categories !== undefined &&
            Design_Left_Categories.left_unterkat_text_dekoration !== undefined
            ? Design_Left_Categories.left_unterkat_text_dekoration
            : "none"
        );
        setDB_LeftSidebar_MenuItem_Unterkategorie_TextDecorationStyle(
          Design_Left_Categories !== undefined &&
            Design_Left_Categories.left_unterkat_text_dekoration_stil !==
              undefined
            ? Design_Left_Categories.left_unterkat_text_dekoration_stil
            : "none"
        );
        setDB_LeftSidebar_MenuItem_Unterkategorie_marginLeftRight(
          Design_Left_Categories !== undefined &&
            Design_Left_Categories.left_unterkat_aussen_abstand_links_rechts !==
              undefined
            ? Design_Left_Categories.left_unterkat_aussen_abstand_links_rechts
            : "30"
        );
        setDB_LeftSidebar_MenuItem_Unterkategorie_marginTopBottom(
          Design_Left_Categories !== undefined &&
            Design_Left_Categories.left_unterkat_aussen_abstand_oben_unten !==
              undefined
            ? Design_Left_Categories.left_unterkat_aussen_abstand_oben_unten
            : "0"
        );
        setDB_LeftSidebar_MenuItem_Unterkategorie_paddingLeftRight(
          Design_Left_Categories !== undefined &&
            Design_Left_Categories.left_unterkat_innerer_abstand_links_rechts !==
              undefined
            ? Design_Left_Categories.left_unterkat_innerer_abstand_links_rechts
            : "0"
        );
        setDB_LeftSidebar_MenuItem_Unterkategorie_paddingTopBottom(
          Design_Left_Categories !== undefined &&
            Design_Left_Categories.left_unterkat_innerer_abstand_oben_unten !==
              undefined
            ? Design_Left_Categories.left_unterkat_innerer_abstand_oben_unten
            : "0"
        );
        setDB_LeftSidebar_MenuItem_Unterkategorie_TextAlign(
          Design_Left_Categories !== undefined &&
            Design_Left_Categories.left_unterkat_textausrichtung !== undefined
            ? Design_Left_Categories.left_unterkat_textausrichtung
            : "center"
        );

        setDB_fontFace_unterkategorieTitel_FontFamilyName(
          Design_Left_Categories !== undefined &&
            Design_Left_Categories.left_unterkat_fontfamily !== undefined
            ? Design_Left_Categories.left_unterkat_fontfamily
            : "Lato"
        );

        setDB_fontFace_unterkategorieTitel_FontFamilySrc(
          Design_Left_Categories !== undefined &&
            Design_Left_Categories.left_unterkat_fontfamilysrc !== undefined
            ? Design_Left_Categories.left_unterkat_fontfamilysrc
            : "/assets/Lato/Lato-Regular.ttf"
        );

        setDB_LeftSidebar_MenuItem_Unterkategorie_Hover_FontColor(
          Design_Left_Categories !== undefined &&
            Design_Left_Categories.left_unterkat_hov_schriftfarbe !== undefined
            ? Design_Left_Categories.left_unterkat_hov_schriftfarbe
            : "rgba(255,255,255,1)"
        );
        setDB_LeftSidebar_MenuItem_Unterkategorie_Hover_FontColorNotEncoded(
          Design_Left_Categories !== undefined &&
            Design_Left_Categories.left_unterkat_hov_schriftfarbe_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Left_Categories.left_unterkat_hov_schriftfarbe_not_encoded
              )
            : { r: 255, g: 255, b: 255, a: 1 }
        );
        setDB_LeftSidebar_MenuItem_Unterkategorie_Hover_BackgroundColor(
          Design_Left_Categories !== undefined &&
            Design_Left_Categories.left_unterkat_hov_hintergrundfarbe !==
              undefined
            ? Design_Left_Categories.left_unterkat_hov_hintergrundfarbe
            : "rgba(255,255,255,0.4)"
        );
        setDB_LeftSidebar_MenuItem_Unterkategorie_Hover_BackgroundColorNotEncoded(
          Design_Left_Categories !== undefined &&
            Design_Left_Categories.left_unterkat_hov_hintergrundfarbe_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Left_Categories.left_unterkat_hov_hintergrundfarbe_not_encoded
              )
            : { r: 255, g: 255, b: 255, a: 0.4 }
        );
        setDB_LeftSidebar_MenuItem_Unterkategorie_ShadowColor_Hover(
          Design_Left_Categories !== undefined &&
            Design_Left_Categories.left_unterkat_hov_schattenfarbe !== undefined
            ? Design_Left_Categories.left_unterkat_hov_schattenfarbe
            : "rgba(0,0,0,0.3)"
        );
        setDB_LeftSidebar_MenuItem_Unterkategorie_ShadowColorNotEncoded_Hover(
          Design_Left_Categories !== undefined &&
            Design_Left_Categories.left_unterkat_hov_schattenfarbe_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Left_Categories.left_unterkat_hov_schattenfarbe_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0.3 }
        );
        setDB_LeftSidebar_MenuItem_Unterkategorie_ShadowHorizontal_Hover(
          Design_Left_Categories !== undefined &&
            Design_Left_Categories.left_unterkat_hov_schattenposition_horizontal !==
              undefined
            ? Design_Left_Categories.left_unterkat_hov_schattenposition_horizontal
            : "3"
        );
        setDB_LeftSidebar_MenuItem_Unterkategorie_ShadowVertical_Hover(
          Design_Left_Categories !== undefined &&
            Design_Left_Categories.left_unterkat_hov_schattenposition_vertikal !==
              undefined
            ? Design_Left_Categories.left_unterkat_hov_schattenposition_vertikal
            : "3"
        );
        setDB_LeftSidebar_MenuItem_Unterkategorie_ShadowBlur_Hover(
          Design_Left_Categories !== undefined &&
            Design_Left_Categories.left_unterkat_hov_schattenrauschen !==
              undefined
            ? Design_Left_Categories.left_unterkat_hov_schattenrauschen
            : "5"
        );
        setDB_LeftSidebar_MenuItem_Unterkategorie_Hover_FontSize(
          Design_Left_Categories !== undefined &&
            Design_Left_Categories.left_unterkat_hov_schriftgroesse !==
              undefined
            ? Design_Left_Categories.left_unterkat_hov_schriftgroesse
            : "16"
        );
        setDB_LeftSidebar_MenuItem_Unterkategorie_Hover_FontWeight(
          Design_Left_Categories !== undefined &&
            Design_Left_Categories.left_unterkat_hov_schriftgewicht !==
              undefined
            ? Design_Left_Categories.left_unterkat_hov_schriftgewicht
            : "300"
        );
        setDB_LeftSidebar_MenuItem_Unterkategorie_letterSpacing_Hover(
          Design_Left_Categories !== undefined &&
            Design_Left_Categories.left_unterkat_hov_buchstabenabstand !==
              undefined
            ? Design_Left_Categories.left_unterkat_hov_buchstabenabstand
            : "1"
        );
        setDB_LeftSidebar_MenuItem_Unterkategorie_TextDecoration_Hover(
          Design_Left_Categories !== undefined &&
            Design_Left_Categories.left_unterkat_hov_text_dekoration !==
              undefined
            ? Design_Left_Categories.left_unterkat_hov_text_dekoration
            : "none"
        );
        setDB_LeftSidebar_MenuItem_Unterkategorie_TextDecorationStyle_Hover(
          Design_Left_Categories !== undefined &&
            Design_Left_Categories.left_unterkat_hov_text_dekoration_stil !==
              undefined
            ? Design_Left_Categories.left_unterkat_hov_text_dekoration_stil
            : "none"
        );
        setDB_LeftSidebar_MenuItem_Unterkategorie_marginLeftRight_Hover(
          Design_Left_Categories !== undefined &&
            Design_Left_Categories.left_unterkat_hov_aussen_abstand_links_rechts !==
              undefined
            ? Design_Left_Categories.left_unterkat_hov_aussen_abstand_links_rechts
            : "30"
        );
        setDB_LeftSidebar_MenuItem_Unterkategorie_marginTopBottom_Hover(
          Design_Left_Categories !== undefined &&
            Design_Left_Categories.left_unterkat_hov_aussen_abstand_oben_unten !==
              undefined
            ? Design_Left_Categories.left_unterkat_hov_aussen_abstand_oben_unten
            : "0"
        );
        setDB_LeftSidebar_MenuItem_Unterkategorie_paddingLeftRight_Hover(
          Design_Left_Categories !== undefined &&
            Design_Left_Categories.left_unterkat_hov_innerer_abstand_links_rechts !==
              undefined
            ? Design_Left_Categories.left_unterkat_hov_innerer_abstand_links_rechts
            : "0"
        );
        setDB_LeftSidebar_MenuItem_Unterkategorie_paddingTopBottom_Hover(
          Design_Left_Categories !== undefined &&
            Design_Left_Categories.left_unterkat_hov_innerer_abstand_oben_unten !==
              undefined
            ? Design_Left_Categories.left_unterkat_hov_innerer_abstand_oben_unten
            : "0"
        );

        setDB_LeftSidebar_MenuItem_Unterkategorie_TextAlign(
          Design_Left_Categories !== undefined &&
            Design_Left_Categories.left_unterkat_hov_textausrichtung !==
              undefined
            ? Design_Left_Categories.left_unterkat_hov_textausrichtung
            : "center"
        );

        setDB_LeftSidebar_MenuItem_Unterkategorie_BorderColorLeft(
          Design_Left_Categories !== undefined &&
            Design_Left_Categories.left_unterkat_border_color_left !== undefined
            ? Design_Left_Categories.left_unterkat_border_color_left
            : "rgba(0,0,0,0.4)"
        );
        setDB_LeftSidebar_MenuItem_Unterkategorie_BorderColorLeftNotEncoded(
          Design_Left_Categories !== undefined &&
            Design_Left_Categories.left_unterkat_border_color_left_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Left_Categories.left_unterkat_border_color_left_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0.4 }
        );
        setDB_LeftSidebar_MenuItem_Unterkategorie_BorderColorRight(
          Design_Left_Categories !== undefined &&
            Design_Left_Categories.left_unterkat_border_color_right !==
              undefined
            ? Design_Left_Categories.left_unterkat_border_color_right
            : "rgba(0,0,0,0.4)"
        );
        setDB_LeftSidebar_MenuItem_Unterkategorie_BorderColorRightNotEncoded(
          Design_Left_Categories !== undefined &&
            Design_Left_Categories.left_unterkat_border_color_right_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Left_Categories.left_unterkat_border_color_right_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0.4 }
        );
        setDB_LeftSidebar_MenuItem_Unterkategorie_BorderColorTop(
          Design_Left_Categories !== undefined &&
            Design_Left_Categories.left_unterkat_border_color_top !== undefined
            ? Design_Left_Categories.left_unterkat_border_color_top
            : "rgba(0,0,0,0.4)"
        );
        setDB_LeftSidebar_MenuItem_Unterkategorie_BorderColorTopNotEncoded(
          Design_Left_Categories !== undefined &&
            Design_Left_Categories.left_unterkat_border_color_top_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Left_Categories.left_unterkat_border_color_top_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0.4 }
        );
        setDB_LeftSidebar_MenuItem_Unterkategorie_BorderColorBottom(
          Design_Left_Categories !== undefined &&
            Design_Left_Categories.left_unterkat_border_color_bottom !==
              undefined
            ? Design_Left_Categories.left_unterkat_border_color_bottom
            : "rgba(0,0,0,0.4)"
        );
        setDB_LeftSidebar_MenuItem_Unterkategorie_BorderColorBottomNotEncoded(
          Design_Left_Categories !== undefined &&
            Design_Left_Categories.left_unterkat_border_color_bottom_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Left_Categories.left_unterkat_border_color_bottom_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0.4 }
        );

        setDB_LeftSidebar_MenuItem_Unterkategorie_BorderLeft(
          Design_Left_Categories !== undefined &&
            Design_Left_Categories.left_unterkat_border_pixel_left !== undefined
            ? Design_Left_Categories.left_unterkat_border_pixel_left
            : "1"
        );
        setDB_LeftSidebar_MenuItem_Unterkategorie_BorderRight(
          Design_Left_Categories !== undefined &&
            Design_Left_Categories.left_unterkat_border_pixel_right !==
              undefined
            ? Design_Left_Categories.left_unterkat_border_pixel_right
            : "1"
        );
        setDB_LeftSidebar_MenuItem_Unterkategorie_BorderTop(
          Design_Left_Categories !== undefined &&
            Design_Left_Categories.left_unterkat_border_pixel_top !== undefined
            ? Design_Left_Categories.left_unterkat_border_pixel_top
            : "1"
        );
        setDB_LeftSidebar_MenuItem_Unterkategorie_BorderBottom(
          Design_Left_Categories !== undefined &&
            Design_Left_Categories.left_unterkat_border_pixel_bottom !==
              undefined
            ? Design_Left_Categories.left_unterkat_border_pixel_bottom
            : "1"
        );

        setDB_LeftSidebar_MenuItem_Unterkategorie_BorderTopLeftRadius(
          Design_Left_Categories !== undefined &&
            Design_Left_Categories.left_unterkat_border_radius_top_left !==
              undefined
            ? Design_Left_Categories.left_unterkat_border_radius_top_left
            : "15"
        );
        setDB_LeftSidebar_MenuItem_Unterkategorie_BorderTopRightRadius(
          Design_Left_Categories !== undefined &&
            Design_Left_Categories.left_unterkat_border_radius_top_right !==
              undefined
            ? Design_Left_Categories.left_unterkat_border_radius_top_right
            : "15"
        );
        setDB_LeftSidebar_MenuItem_Unterkategorie_BorderBottomLeftRadius(
          Design_Left_Categories !== undefined &&
            Design_Left_Categories.left_unterkat_border_radius_bottom_left !==
              undefined
            ? Design_Left_Categories.left_unterkat_border_radius_bottom_left
            : "15"
        );
        setDB_LeftSidebar_MenuItem_Unterkategorie_BorderBottomRightRadius(
          Design_Left_Categories !== undefined &&
            Design_Left_Categories.left_unterkat_border_radius_bottom_right !==
              undefined
            ? Design_Left_Categories.left_unterkat_border_radius_bottom_right
            : "15"
        );

        setDB_LeftSidebar_MenuItem_Unterkategorie_BorderStyleLeft(
          Design_Left_Categories !== undefined &&
            Design_Left_Categories.left_unterkat_border_style_left !== undefined
            ? Design_Left_Categories.left_unterkat_border_style_left
            : "solid"
        );
        setDB_LeftSidebar_MenuItem_Unterkategorie_BorderStyleRight(
          Design_Left_Categories !== undefined &&
            Design_Left_Categories.left_unterkat_border_style_right !==
              undefined
            ? Design_Left_Categories.left_unterkat_border_style_right
            : "solid"
        );
        setDB_LeftSidebar_MenuItem_Unterkategorie_BorderStyleTop(
          Design_Left_Categories !== undefined &&
            Design_Left_Categories.left_unterkat_border_style_top !== undefined
            ? Design_Left_Categories.left_unterkat_border_style_top
            : "solid"
        );
        setDB_LeftSidebar_MenuItem_Unterkategorie_BorderStyleBottom(
          Design_Left_Categories !== undefined &&
            Design_Left_Categories.left_unterkat_border_style_bottom !==
              undefined
            ? Design_Left_Categories.left_unterkat_border_style_bottom
            : "solid"
        );

        setDB_LeftSidebar_MenuItem_Unterkategorie_Hover_BorderColorLeft(
          Design_Left_Categories !== undefined &&
            Design_Left_Categories.left_unterkat_border_hov_color_left !==
              undefined
            ? Design_Left_Categories.left_unterkat_border_hov_color_left
            : "rgba(0,0,0,0.5)"
        );
        setDB_LeftSidebar_MenuItem_Unterkategorie_Hover_BorderColorLeftNotEncoded(
          Design_Left_Categories !== undefined &&
            Design_Left_Categories.left_unterkat_border_hov_color_left_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Left_Categories.left_unterkat_border_hov_color_left_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0.5 }
        );
        setDB_LeftSidebar_MenuItem_Unterkategorie_Hover_BorderColorRight(
          Design_Left_Categories !== undefined &&
            Design_Left_Categories.left_unterkat_border_hov_color_right !==
              undefined
            ? Design_Left_Categories.left_unterkat_border_hov_color_right
            : "rgba(0,0,0,0.5)"
        );
        setDB_LeftSidebar_MenuItem_Unterkategorie_Hover_BorderColorRightNotEncoded(
          Design_Left_Categories !== undefined &&
            Design_Left_Categories.left_unterkat_border_hov_color_right_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Left_Categories.left_unterkat_border_hov_color_right_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0.5 }
        );
        setDB_LeftSidebar_MenuItem_Unterkategorie_Hover_BorderColorTop(
          Design_Left_Categories !== undefined &&
            Design_Left_Categories.left_unterkat_border_hov_color_top !==
              undefined
            ? Design_Left_Categories.left_unterkat_border_hov_color_top
            : "rgba(0,0,0,0.5)"
        );
        setDB_LeftSidebar_MenuItem_Unterkategorie_Hover_BorderColorTopNotEncoded(
          Design_Left_Categories !== undefined &&
            Design_Left_Categories.left_unterkat_border_hov_color_top_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Left_Categories.left_unterkat_border_hov_color_top_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0.5 }
        );
        setDB_LeftSidebar_MenuItem_Unterkategorie_Hover_BorderColorBottom(
          Design_Left_Categories !== undefined &&
            Design_Left_Categories.left_unterkat_border_hov_color_bottom !==
              undefined
            ? Design_Left_Categories.left_unterkat_border_hov_color_bottom
            : "rgba(0,0,0,0.5)"
        );
        setDB_LeftSidebar_MenuItem_Unterkategorie_Hover_BorderColorBottomNotEncoded(
          Design_Left_Categories !== undefined &&
            Design_Left_Categories.left_unterkat_border_hov_color_bottom_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Left_Categories.left_unterkat_border_hov_color_bottom_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0.5 }
        );

        setDB_LeftSidebar_MenuItem_Unterkategorie_Hover_BorderLeft(
          Design_Left_Categories !== undefined &&
            Design_Left_Categories.left_unterkat_border_hov_pixel_left !==
              undefined
            ? Design_Left_Categories.left_unterkat_border_hov_pixel_left
            : "1"
        );
        setDB_LeftSidebar_MenuItem_Unterkategorie_Hover_BorderRight(
          Design_Left_Categories !== undefined &&
            Design_Left_Categories.left_unterkat_border_hov_pixel_right !==
              undefined
            ? Design_Left_Categories.left_unterkat_border_hov_pixel_right
            : "1"
        );
        setDB_LeftSidebar_MenuItem_Unterkategorie_Hover_BorderTop(
          Design_Left_Categories !== undefined &&
            Design_Left_Categories.left_unterkat_border_hov_pixel_top !==
              undefined
            ? Design_Left_Categories.left_unterkat_border_hov_pixel_top
            : "1"
        );
        setDB_LeftSidebar_MenuItem_Unterkategorie_Hover_BorderBottom(
          Design_Left_Categories !== undefined &&
            Design_Left_Categories.left_unterkat_border_hov_pixel_bottom !==
              undefined
            ? Design_Left_Categories.left_unterkat_border_hov_pixel_bottom
            : "1"
        );

        setDB_LeftSidebar_MenuItem_Unterkategorie_Hover_BorderTopLeftRadius(
          Design_Left_Categories !== undefined &&
            Design_Left_Categories.left_unterkat_border_hov_radius_top_left !==
              undefined
            ? Design_Left_Categories.left_unterkat_border_hov_radius_top_left
            : "15"
        );
        setDB_LeftSidebar_MenuItem_Unterkategorie_Hover_BorderTopRightRadius(
          Design_Left_Categories !== undefined &&
            Design_Left_Categories.left_unterkat_border_hov_radius_top_right !==
              undefined
            ? Design_Left_Categories.left_unterkat_border_hov_radius_top_right
            : "15"
        );
        setDB_LeftSidebar_MenuItem_Unterkategorie_Hover_BorderBottomLeftRadius(
          Design_Left_Categories !== undefined &&
            Design_Left_Categories.left_unterkat_border_hov_radius_bottom_left !==
              undefined
            ? Design_Left_Categories.left_unterkat_border_hov_radius_bottom_left
            : "15"
        );
        setDB_LeftSidebar_MenuItem_Unterkategorie_Hover_BorderBottomRightRadius(
          Design_Left_Categories !== undefined &&
            Design_Left_Categories.left_unterkat_border_hov_radius_bottom_right !==
              undefined
            ? Design_Left_Categories.left_unterkat_border_hov_radius_bottom_right
            : "15"
        );

        setDB_LeftSidebar_MenuItem_Unterkategorie_Hover_BorderStyleLeft(
          Design_Left_Categories !== undefined &&
            Design_Left_Categories.left_unterkat_border_hov_style_left !==
              undefined
            ? Design_Left_Categories.left_unterkat_border_hov_style_left
            : "solid"
        );
        setDB_LeftSidebar_MenuItem_Unterkategorie_Hover_BorderStyleRight(
          Design_Left_Categories !== undefined &&
            Design_Left_Categories.left_unterkat_border_hov_style_right !==
              undefined
            ? Design_Left_Categories.left_unterkat_border_hov_style_right
            : "solid"
        );
        setDB_LeftSidebar_MenuItem_Unterkategorie_Hover_BorderStyleTop(
          Design_Left_Categories !== undefined &&
            Design_Left_Categories.left_unterkat_border_hov_style_top !==
              undefined
            ? Design_Left_Categories.left_unterkat_border_hov_style_top
            : "solid"
        );
        setDB_LeftSidebar_MenuItem_Unterkategorie_Hover_BorderStyleBottom(
          Design_Left_Categories !== undefined &&
            Design_Left_Categories.left_unterkat_border_hov_style_bottom !==
              undefined
            ? Design_Left_Categories.left_unterkat_border_hov_style_bottom
            : "solid"
        );

        setDB_LeftSidebar_MenuItem_UnterUnterkategorie_FontColor(
          Design_Left_Categories !== undefined &&
            Design_Left_Categories.left_subunterkat_schriftfarbe !== undefined
            ? Design_Left_Categories.left_subunterkat_schriftfarbe
            : "rgba(255,255,255,1)"
        );
        setDB_LeftSidebar_MenuItem_UnterUnterkategorie_FontColorNotEncoded(
          Design_Left_Categories !== undefined &&
            Design_Left_Categories.left_subunterkat_schriftfarbe_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Left_Categories.left_subunterkat_schriftfarbe_not_encoded
              )
            : { r: 255, g: 255, b: 255, a: 1 }
        );
        setDB_LeftSidebar_MenuItem_UnterUnterkategorie_BackgroundColor(
          Design_Left_Categories !== undefined &&
            Design_Left_Categories.left_subunterkat_hintergrundfarbe !==
              undefined
            ? Design_Left_Categories.left_subunterkat_hintergrundfarbe
            : "rgba(0,0,0,0)"
        );
        setDB_LeftSidebar_MenuItem_UnterUnterkategorie_BackgroundColorNotEncoded(
          Design_Left_Categories !== undefined &&
            Design_Left_Categories.left_subunterkat_hintergrundfarbe_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Left_Categories.left_subunterkat_hintergrundfarbe_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0 }
        );
        setDB_LeftSidebar_MenuItem_UnterUnterkategorie_ShadowColor(
          Design_Left_Categories !== undefined &&
            Design_Left_Categories.left_subunterkat_schattenfarbe !== undefined
            ? Design_Left_Categories.left_subunterkat_schattenfarbe
            : "rgba(0,0,0,1)"
        );
        setDB_LeftSidebar_MenuItem_UnterUnterkategorie_ShadowColorNotEncoded(
          Design_Left_Categories !== undefined &&
            Design_Left_Categories.left_subunterkat_schattenfarbe_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Left_Categories.left_subunterkat_schattenfarbe_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0.5 }
        );
        setDB_LeftSidebar_MenuItem_UnterUnterkategorie_ShadowHorizontal(
          Design_Left_Categories !== undefined &&
            Design_Left_Categories.left_subunterkat_schattenposition_horizontal !==
              undefined
            ? Design_Left_Categories.left_subunterkat_schattenposition_horizontal
            : "2"
        );
        setDB_LeftSidebar_MenuItem_UnterUnterkategorie_ShadowVertical(
          Design_Left_Categories !== undefined &&
            Design_Left_Categories.left_subunterkat_schattenposition_vertikal !==
              undefined
            ? Design_Left_Categories.left_subunterkat_schattenposition_vertikal
            : "2"
        );
        setDB_LeftSidebar_MenuItem_UnterUnterkategorie_ShadowBlur(
          Design_Left_Categories !== undefined &&
            Design_Left_Categories.left_subunterkat_schattenrauschen !==
              undefined
            ? Design_Left_Categories.left_subunterkat_schattenrauschen
            : "3"
        );
        setDB_LeftSidebar_MenuItem_UnterUnterkategorie_FontSize(
          Design_Left_Categories !== undefined &&
            Design_Left_Categories.left_subunterkat_schriftgroesse !== undefined
            ? Design_Left_Categories.left_subunterkat_schriftgroesse
            : "15"
        );
        setDB_LeftSidebar_MenuItem_UnterUnterkategorie_FontWeight(
          Design_Left_Categories !== undefined &&
            Design_Left_Categories.left_subunterkat_schriftgewicht !== undefined
            ? Design_Left_Categories.left_subunterkat_schriftgewicht
            : "300"
        );
        setDB_LeftSidebar_MenuItem_UnterUnterkategorie_letterSpacing(
          Design_Left_Categories !== undefined &&
            Design_Left_Categories.left_subunterkat_buchstabenabstand !==
              undefined
            ? Design_Left_Categories.left_subunterkat_buchstabenabstand
            : "1"
        );
        setDB_LeftSidebar_MenuItem_UnterUnterkategorie_TextDecoration(
          Design_Left_Categories !== undefined &&
            Design_Left_Categories.left_subunterkat_text_dekoration !==
              undefined
            ? Design_Left_Categories.left_subunterkat_text_dekoration
            : "none"
        );
        setDB_LeftSidebar_MenuItem_UnterUnterkategorie_TextDecorationStyle(
          Design_Left_Categories !== undefined &&
            Design_Left_Categories.left_subunterkat_text_dekoration_stil !==
              undefined
            ? Design_Left_Categories.left_subunterkat_text_dekoration_stil
            : "none"
        );
        setDB_LeftSidebar_MenuItem_UnterUnterkategorie_marginLeftRight(
          Design_Left_Categories !== undefined &&
            Design_Left_Categories.left_subunterkat_aussen_abstand_links_rechts !==
              undefined
            ? Design_Left_Categories.left_subunterkat_aussen_abstand_links_rechts
            : "0"
        );
        setDB_LeftSidebar_MenuItem_UnterUnterkategorie_marginTopBottom(
          Design_Left_Categories !== undefined &&
            Design_Left_Categories.left_subunterkat_aussen_abstand_oben_unten !==
              undefined
            ? Design_Left_Categories.left_subunterkat_aussen_abstand_oben_unten
            : "2"
        );
        setDB_LeftSidebar_MenuItem_UnterUnterkategorie_paddingLeftRight(
          Design_Left_Categories !== undefined &&
            Design_Left_Categories.left_subunterkat_innerer_abstand_links_rechts !==
              undefined
            ? Design_Left_Categories.left_subunterkat_innerer_abstand_links_rechts
            : "0"
        );
        setDB_LeftSidebar_MenuItem_UnterUnterkategorie_paddingTopBottom(
          Design_Left_Categories !== undefined &&
            Design_Left_Categories.left_subunterkat_innerer_abstand_oben_unten !==
              undefined
            ? Design_Left_Categories.left_subunterkat_innerer_abstand_oben_unten
            : "0"
        );
        setDB_LeftSidebar_MenuItem_UnterUnterkategorie_TextAlign(
          Design_Left_Categories !== undefined &&
            Design_Left_Categories.left_subunterkat_textausrichtung !==
              undefined
            ? Design_Left_Categories.left_subunterkat_textausrichtung
            : "center"
        );

        setDB_fontFace_unterunterkategorieTitel_FontFamilyName(
          Design_Left_Categories !== undefined &&
            Design_Left_Categories.left_subunterkat_fontfamily !== undefined
            ? Design_Left_Categories.left_subunterkat_fontfamily
            : "Lato"
        );

        setDB_fontFace_unterunterkategorieTitel_FontFamilySrc(
          Design_Left_Categories !== undefined &&
            Design_Left_Categories.left_subunterkat_fontfamilysrc !== undefined
            ? Design_Left_Categories.left_subunterkat_fontfamilysrc
            : "/assets/Lato/Lato-Regular.ttf"
        );

        setDB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_FontColor(
          Design_Left_Categories !== undefined &&
            Design_Left_Categories.left_subunterkat_hov_schriftfarbe !==
              undefined
            ? Design_Left_Categories.left_subunterkat_hov_schriftfarbe
            : "rgba(255,255,255,1)"
        );
        setDB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_FontColorNotEncoded(
          Design_Left_Categories !== undefined &&
            Design_Left_Categories.left_subunterkat_hov_schriftfarbe_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Left_Categories.left_subunterkat_hov_schriftfarbe_not_encoded
              )
            : { r: 255, g: 255, b: 255, a: 1 }
        );
        setDB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_BackgroundColor(
          Design_Left_Categories !== undefined &&
            Design_Left_Categories.left_subunterkat_hov_hintergrundfarbe !==
              undefined
            ? Design_Left_Categories.left_subunterkat_hov_hintergrundfarbe
            : "rgba(0,0,0,0)"
        );
        setDB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_BackgroundColorNotEncoded(
          Design_Left_Categories !== undefined &&
            Design_Left_Categories.left_subunterkat_hov_hintergrundfarbe_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Left_Categories.left_subunterkat_hov_hintergrundfarbe_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0 }
        );
        setDB_LeftSidebar_MenuItem_UnterUnterkategorie_ShadowColor_Hover(
          Design_Left_Categories !== undefined &&
            Design_Left_Categories.left_subunterkat_hov_schattenfarbe !==
              undefined
            ? Design_Left_Categories.left_subunterkat_hov_schattenfarbe
            : "rgba(0,0,0,1)"
        );
        setDB_LeftSidebar_MenuItem_UnterUnterkategorie_ShadowColorNotEncoded_Hover(
          Design_Left_Categories !== undefined &&
            Design_Left_Categories.left_subunterkat_hov_schattenfarbe_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Left_Categories.left_subunterkat_hov_schattenfarbe_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 1 }
        );
        setDB_LeftSidebar_MenuItem_UnterUnterkategorie_ShadowHorizontal_Hover(
          Design_Left_Categories !== undefined &&
            Design_Left_Categories.left_subunterkat_hov_schattenposition_horizontal !==
              undefined
            ? Design_Left_Categories.left_subunterkat_hov_schattenposition_horizontal
            : "2"
        );
        setDB_LeftSidebar_MenuItem_UnterUnterkategorie_ShadowVertical_Hover(
          Design_Left_Categories !== undefined &&
            Design_Left_Categories.left_subunterkat_hov_schattenposition_vertikal !==
              undefined
            ? Design_Left_Categories.left_subunterkat_hov_schattenposition_vertikal
            : "2"
        );
        setDB_LeftSidebar_MenuItem_UnterUnterkategorie_ShadowBlur_Hover(
          Design_Left_Categories !== undefined &&
            Design_Left_Categories.left_subunterkat_hov_schattenrauschen !==
              undefined
            ? Design_Left_Categories.left_subunterkat_hov_schattenrauschen
            : "3"
        );
        setDB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_FontSize(
          Design_Left_Categories !== undefined &&
            Design_Left_Categories.left_subunterkat_hov_schriftgroesse !==
              undefined
            ? Design_Left_Categories.left_subunterkat_hov_schriftgroesse
            : "15"
        );
        setDB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_FontWeight(
          Design_Left_Categories !== undefined &&
            Design_Left_Categories.left_subunterkat_hov_schriftgewicht !==
              undefined
            ? Design_Left_Categories.left_subunterkat_hov_schriftgewicht
            : "300"
        );
        setDB_LeftSidebar_MenuItem_UnterUnterkategorie_letterSpacing_Hover(
          Design_Left_Categories !== undefined &&
            Design_Left_Categories.left_subunterkat_hov_buchstabenabstand !==
              undefined
            ? Design_Left_Categories.left_subunterkat_hov_buchstabenabstand
            : "1"
        );
        setDB_LeftSidebar_MenuItem_UnterUnterkategorie_TextDecoration_Hover(
          Design_Left_Categories !== undefined &&
            Design_Left_Categories.left_subunterkat_hov_text_dekoration !==
              undefined
            ? Design_Left_Categories.left_subunterkat_hov_text_dekoration
            : "none"
        );
        setDB_LeftSidebar_MenuItem_UnterUnterkategorie_TextDecorationStyle_Hover(
          Design_Left_Categories !== undefined &&
            Design_Left_Categories.left_subunterkat_hov_text_dekoration_stil !==
              undefined
            ? Design_Left_Categories.left_subunterkat_hov_text_dekoration_stil
            : "none"
        );
        setDB_LeftSidebar_MenuItem_UnterUnterkategorie_marginLeftRight_Hover(
          Design_Left_Categories !== undefined &&
            Design_Left_Categories.left_subunterkat_hov_aussen_abstand_links_rechts !==
              undefined
            ? Design_Left_Categories.left_subunterkat_hov_aussen_abstand_links_rechts
            : "0"
        );
        setDB_LeftSidebar_MenuItem_UnterUnterkategorie_marginTopBottom_Hover(
          Design_Left_Categories !== undefined &&
            Design_Left_Categories.left_subunterkat_hov_aussen_abstand_oben_unten !==
              undefined
            ? Design_Left_Categories.left_subunterkat_hov_aussen_abstand_oben_unten
            : "2"
        );
        setDB_LeftSidebar_MenuItem_UnterUnterkategorie_paddingLeftRight_Hover(
          Design_Left_Categories !== undefined &&
            Design_Left_Categories.left_subunterkat_hov_innerer_abstand_links_rechts !==
              undefined
            ? Design_Left_Categories.left_subunterkat_hov_innerer_abstand_links_rechts
            : "0"
        );
        setDB_LeftSidebar_MenuItem_UnterUnterkategorie_paddingTopBottom_Hover(
          Design_Left_Categories !== undefined &&
            Design_Left_Categories.left_subunterkat_hov_innerer_abstand_oben_unten !==
              undefined
            ? Design_Left_Categories.left_subunterkat_hov_innerer_abstand_oben_unten
            : "0"
        );
        setDB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_TextAlign(
          Design_Left_Categories !== undefined &&
            Design_Left_Categories.left_subunterkat_hov_textausrichtung !==
              undefined
            ? Design_Left_Categories.left_subunterkat_hov_textausrichtung
            : "center"
        );

        setDB_LeftSidebar_MenuItem_UnterUnterkategorie_BorderColorLeft(
          Design_Left_Categories !== undefined &&
            Design_Left_Categories.left_subunterkat_border_color_left !==
              undefined
            ? Design_Left_Categories.left_subunterkat_border_color_left
            : "rgba(0,0,0,0)"
        );
        setDB_LeftSidebar_MenuItem_UnterUnterkategorie_BorderColorLeftNotEncoded(
          Design_Left_Categories !== undefined &&
            Design_Left_Categories.left_subunterkat_border_color_left_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Left_Categories.left_subunterkat_border_color_left_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0 }
        );
        setDB_LeftSidebar_MenuItem_UnterUnterkategorie_BorderColorRight(
          Design_Left_Categories !== undefined &&
            Design_Left_Categories.left_subunterkat_border_color_right !==
              undefined
            ? Design_Left_Categories.left_subunterkat_border_color_right
            : "rgba(0,0,0,0)"
        );
        setDB_LeftSidebar_MenuItem_UnterUnterkategorie_BorderColorRightNotEncoded(
          Design_Left_Categories !== undefined &&
            Design_Left_Categories.left_subunterkat_border_color_right_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Left_Categories.left_subunterkat_border_color_right_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0 }
        );
        setDB_LeftSidebar_MenuItem_UnterUnterkategorie_BorderColorTop(
          Design_Left_Categories !== undefined &&
            Design_Left_Categories.left_subunterkat_border_color_top !==
              undefined
            ? Design_Left_Categories.left_subunterkat_border_color_top
            : "rgba(0,0,0,0)"
        );
        setDB_LeftSidebar_MenuItem_UnterUnterkategorie_BorderColorTopNotEncoded(
          Design_Left_Categories !== undefined &&
            Design_Left_Categories.left_subunterkat_border_color_top_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Left_Categories.left_subunterkat_border_color_top_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0 }
        );
        setDB_LeftSidebar_MenuItem_UnterUnterkategorie_BorderColorBottom(
          Design_Left_Categories !== undefined &&
            Design_Left_Categories.left_subunterkat_border_color_bottom !==
              undefined
            ? Design_Left_Categories.left_subunterkat_border_color_bottom
            : "rgba(0,0,0,0)"
        );
        setDB_LeftSidebar_MenuItem_UnterUnterkategorie_BorderColorBottomNotEncoded(
          Design_Left_Categories !== undefined &&
            Design_Left_Categories.left_subunterkat_border_color_bottom_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Left_Categories.left_subunterkat_border_color_bottom_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0 }
        );

        setDB_LeftSidebar_MenuItem_UnterUnterkategorie_BorderLeft(
          Design_Left_Categories !== undefined &&
            Design_Left_Categories.left_subunterkat_border_pixel_left !==
              undefined
            ? Design_Left_Categories.left_subunterkat_border_pixel_left
            : "0"
        );
        setDB_LeftSidebar_MenuItem_UnterUnterkategorie_BorderRight(
          Design_Left_Categories !== undefined &&
            Design_Left_Categories.left_subunterkat_border_pixel_right !==
              undefined
            ? Design_Left_Categories.left_subunterkat_border_pixel_right
            : "0"
        );
        setDB_LeftSidebar_MenuItem_UnterUnterkategorie_BorderTop(
          Design_Left_Categories !== undefined &&
            Design_Left_Categories.left_subunterkat_border_pixel_top !==
              undefined
            ? Design_Left_Categories.left_subunterkat_border_pixel_top
            : "0"
        );
        setDB_LeftSidebar_MenuItem_UnterUnterkategorie_BorderBottom(
          Design_Left_Categories !== undefined &&
            Design_Left_Categories.left_subunterkat_border_pixel_bottom !==
              undefined
            ? Design_Left_Categories.left_subunterkat_border_pixel_bottom
            : "0"
        );

        setDB_LeftSidebar_MenuItem_UnterUnterkategorie_BorderTopLeftRadius(
          Design_Left_Categories !== undefined &&
            Design_Left_Categories.left_subunterkat_border_radius_top_left !==
              undefined
            ? Design_Left_Categories.left_subunterkat_border_radius_top_left
            : "7"
        );
        setDB_LeftSidebar_MenuItem_UnterUnterkategorie_BorderTopRightRadius(
          Design_Left_Categories !== undefined &&
            Design_Left_Categories.left_subunterkat_border_radius_top_right !==
              undefined
            ? Design_Left_Categories.left_subunterkat_border_radius_top_right
            : "7"
        );
        setDB_LeftSidebar_MenuItem_UnterUnterkategorie_BorderBottomLeftRadius(
          Design_Left_Categories !== undefined &&
            Design_Left_Categories.left_subunterkat_border_radius_bottom_left !==
              undefined
            ? Design_Left_Categories.left_subunterkat_border_radius_bottom_left
            : "7"
        );
        setDB_LeftSidebar_MenuItem_UnterUnterkategorie_BorderBottomRightRadius(
          Design_Left_Categories !== undefined &&
            Design_Left_Categories.left_subunterkat_border_radius_bottom_right !==
              undefined
            ? Design_Left_Categories.left_subunterkat_border_radius_bottom_right
            : "7"
        );

        setDB_LeftSidebar_MenuItem_UnterUnterkategorie_BorderStyleLeft(
          Design_Left_Categories !== undefined &&
            Design_Left_Categories.left_subunterkat_border_style_left !==
              undefined
            ? Design_Left_Categories.left_subunterkat_border_style_left
            : "solid"
        );
        setDB_LeftSidebar_MenuItem_UnterUnterkategorie_BorderStyleRight(
          Design_Left_Categories !== undefined &&
            Design_Left_Categories.left_subunterkat_border_style_right !==
              undefined
            ? Design_Left_Categories.left_subunterkat_border_style_right
            : "solid"
        );
        setDB_LeftSidebar_MenuItem_UnterUnterkategorie_BorderStyleTop(
          Design_Left_Categories !== undefined &&
            Design_Left_Categories.left_subunterkat_border_style_top !==
              undefined
            ? Design_Left_Categories.left_subunterkat_border_style_top
            : "solid"
        );
        setDB_LeftSidebar_MenuItem_UnterUnterkategorie_BorderStyleBottom(
          Design_Left_Categories !== undefined &&
            Design_Left_Categories.left_subunterkat_border_style_bottom !==
              undefined
            ? Design_Left_Categories.left_subunterkat_border_style_bottom
            : "solid"
        );

        setDB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_BorderColorLeft(
          Design_Left_Categories !== undefined &&
            Design_Left_Categories.left_subunterkat_border_hov_color_left !==
              undefined
            ? Design_Left_Categories.left_subunterkat_border_hov_color_left
            : "rgba(0,0,0,0)"
        );
        setDB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_BorderColorLeftNotEncoded(
          Design_Left_Categories !== undefined &&
            Design_Left_Categories.left_subunterkat_border_hov_color_left_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Left_Categories.left_subunterkat_border_hov_color_left_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0 }
        );
        setDB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_BorderColorRight(
          Design_Left_Categories !== undefined &&
            Design_Left_Categories.left_subunterkat_border_hov_color_right !==
              undefined
            ? Design_Left_Categories.left_subunterkat_border_hov_color_right
            : "rgba(0,0,0,0)"
        );
        setDB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_BorderColorRightNotEncoded(
          Design_Left_Categories !== undefined &&
            Design_Left_Categories.left_subunterkat_border_hov_color_right_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Left_Categories.left_subunterkat_border_hov_color_right_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0 }
        );
        setDB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_BorderColorTop(
          Design_Left_Categories !== undefined &&
            Design_Left_Categories.left_subunterkat_border_hov_color_top !==
              undefined
            ? Design_Left_Categories.left_subunterkat_border_hov_color_top
            : "rgba(0,0,0,0)"
        );
        setDB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_BorderColorTopNotEncoded(
          Design_Left_Categories !== undefined &&
            Design_Left_Categories.left_subunterkat_border_hov_color_top_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Left_Categories.left_subunterkat_border_hov_color_top_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0 }
        );
        setDB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_BorderColorBottom(
          Design_Left_Categories !== undefined &&
            Design_Left_Categories.left_subunterkat_border_hov_color_bottom !==
              undefined
            ? Design_Left_Categories.left_subunterkat_border_hov_color_bottom
            : "rgba(rgba(0,0,0,0))"
        );
        setDB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_BorderColorBottomNotEncoded(
          Design_Left_Categories !== undefined &&
            Design_Left_Categories.left_subunterkat_border_hov_color_bottom_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Left_Categories.left_subunterkat_border_hov_color_bottom_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0 }
        );

        setDB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_BorderLeft(
          Design_Left_Categories !== undefined &&
            Design_Left_Categories.left_subunterkat_border_hov_pixel_left !==
              undefined
            ? Design_Left_Categories.left_subunterkat_border_hov_pixel_left
            : "0"
        );
        setDB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_BorderRight(
          Design_Left_Categories !== undefined &&
            Design_Left_Categories.left_subunterkat_border_hov_pixel_right !==
              undefined
            ? Design_Left_Categories.left_subunterkat_border_hov_pixel_right
            : "0"
        );
        setDB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_BorderTop(
          Design_Left_Categories !== undefined &&
            Design_Left_Categories.left_subunterkat_border_hov_pixel_top !==
              undefined
            ? Design_Left_Categories.left_subunterkat_border_hov_pixel_top
            : "0"
        );
        setDB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_BorderBottom(
          Design_Left_Categories !== undefined &&
            Design_Left_Categories.left_subunterkat_border_hov_pixel_bottom !==
              undefined
            ? Design_Left_Categories.left_subunterkat_border_hov_pixel_bottom
            : "0"
        );

        setDB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_BorderTopLeftRadius(
          Design_Left_Categories !== undefined &&
            Design_Left_Categories.left_subunterkat_border_hov_radius_top_left !==
              undefined
            ? Design_Left_Categories.left_subunterkat_border_hov_radius_top_left
            : "7"
        );
        setDB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_BorderTopRightRadius(
          Design_Left_Categories !== undefined &&
            Design_Left_Categories.left_subunterkat_border_hov_radius_top_right !==
              undefined
            ? Design_Left_Categories.left_subunterkat_border_hov_radius_top_right
            : "7"
        );
        setDB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_BorderBottomLeftRadius(
          Design_Left_Categories !== undefined &&
            Design_Left_Categories.left_subunterkat_border_hov_radius_bottom_left !==
              undefined
            ? Design_Left_Categories.left_subunterkat_border_hov_radius_bottom_left
            : "7"
        );
        setDB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_BorderBottomRightRadius(
          Design_Left_Categories !== undefined &&
            Design_Left_Categories.left_subunterkat_border_hov_radius_bottom_right !==
              undefined
            ? Design_Left_Categories.left_subunterkat_border_hov_radius_bottom_right
            : "7"
        );

        setDB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_BorderStyleLeft(
          Design_Left_Categories !== undefined &&
            Design_Left_Categories.left_subunterkat_border_hov_style_left !==
              undefined
            ? Design_Left_Categories.left_subunterkat_border_hov_style_left
            : "solid"
        );
        setDB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_BorderStyleRight(
          Design_Left_Categories !== undefined &&
            Design_Left_Categories.left_subunterkat_border_hov_style_right !==
              undefined
            ? Design_Left_Categories.left_subunterkat_border_hov_style_right
            : "solid"
        );
        setDB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_BorderStyleTop(
          Design_Left_Categories !== undefined &&
            Design_Left_Categories.left_subunterkat_border_hov_style_top !==
              undefined
            ? Design_Left_Categories.left_subunterkat_border_hov_style_top
            : "solid"
        );
        setDB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_BorderStyleBottom(
          Design_Left_Categories !== undefined &&
            Design_Left_Categories.left_subunterkat_border_hov_style_bottom !==
              undefined
            ? Design_Left_Categories.left_subunterkat_border_hov_style_bottom
            : "solid"
        );
        ///////////////////jjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjj
        setDB_LeftSidebar_MenuContainer_Social_DDBackgroundColor(
          Design_Right_Social !== undefined &&
            Design_Right_Social.right_social_hintergrundfarbe !== undefined
            ? Design_Right_Social.right_social_hintergrundfarbe
            : "rgba(0,0,0,0.3)"
        );

        setDB_LeftSidebar_MenuContainer_Social_DDBackgroundColorNotEncoded(
          Design_Right_Social !== undefined &&
            Design_Right_Social.right_social_hintergrundfarbe_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Right_Social.right_social_hintergrundfarbe_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0.3 }
        );

        setDB_LeftSidebar_MenuItem_MenuContainer_Social_DDShadowColor(
          Design_Right_Social !== undefined &&
            Design_Right_Social.right_social_schattenfarbe !== undefined
            ? Design_Right_Social.right_social_schattenfarbe
            : "rgba(0,0,0,0.4)"
        );
        setDB_LeftSidebar_MenuItem_MenuContainer_Social_DDShadowColorNotEncoded(
          Design_Right_Social !== undefined &&
            Design_Right_Social.right_social_schattenfarbe_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Right_Social.right_social_schattenfarbe_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0.4 }
        );

        setDB_LeftSidebar_MenuItem_MenuContainer_Social_DDShadowHorizontal(
          Design_Right_Social !== undefined &&
            Design_Right_Social.right_social_schattenposition_horizontal !==
              undefined
            ? Design_Right_Social.right_social_schattenposition_horizontal
            : "-4"
        );

        setDB_LeftSidebar_MenuItem_MenuContainer_Social_DDShadowVertical(
          Design_Right_Social !== undefined &&
            Design_Right_Social.right_social_schattenposition_vertikal !==
              undefined
            ? Design_Right_Social.right_social_schattenposition_vertikal
            : "4"
        );

        setDB_LeftSidebar_MenuItem_MenuContainer_Social_DDShadowBlur(
          Design_Right_Social !== undefined &&
            Design_Right_Social.right_social_schattenrauschen !== undefined
            ? Design_Right_Social.right_social_schattenrauschen
            : "5"
        );

        setDB_LeftSidebar_MenuItem_MenuContainer_Social_DDpaddingLeft(
          Design_Right_Social !== undefined &&
            Design_Right_Social.right_social_innerer_abstand_links !== undefined
            ? Design_Right_Social.right_social_innerer_abstand_links
            : "0"
        );

        setDB_LeftSidebar_MenuItem_MenuContainer_Social_DDpaddingRight(
          Design_Right_Social !== undefined &&
            Design_Right_Social.right_social_innerer_abstand_rechts !==
              undefined
            ? Design_Right_Social.right_social_innerer_abstand_rechts
            : "0"
        );

        setDB_LeftSidebar_MenuItem_MenuContainer_Social_DDpaddingTop(
          Design_Right_Social !== undefined &&
            Design_Right_Social.right_social_innerer_abstand_oben !== undefined
            ? Design_Right_Social.right_social_innerer_abstand_oben
            : "5"
        );
        setDB_LeftSidebar_MenuItem_MenuContainer_Social_DDpaddingBottom(
          Design_Right_Social !== undefined &&
            Design_Right_Social.right_social_innerer_abstand_unten !== undefined
            ? Design_Right_Social.right_social_innerer_abstand_unten
            : "5"
        );

        setDB_LeftSidebar_MenuContainer_Social_DDHover_BackgroundColor(
          Design_Right_Social !== undefined &&
            Design_Right_Social.right_social_hover_hintergrundfarbe !==
              undefined
            ? Design_Right_Social.right_social_hover_hintergrundfarbe
            : "rgba(0,0,0,0.3)"
        );

        setDB_LeftSidebar_MenuContainer_Social_DDHover_BackgroundColorNotEncoded(
          Design_Right_Social !== undefined &&
            Design_Right_Social.right_social_hover_hintergrundfarbe_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Right_Social.right_social_hover_hintergrundfarbe_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0.3 }
        );

        setDB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_ShadowColor(
          Design_Right_Social !== undefined &&
            Design_Right_Social.right_social_hover_schattenfarbe !== undefined
            ? Design_Right_Social.right_social_hover_schattenfarbe
            : "rgba(0,0,0,0.5)"
        );
        setDB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_ShadowColorNotEncoded(
          Design_Right_Social !== undefined &&
            Design_Right_Social.right_social_hover_schattenfarbe_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Right_Social.right_social_hover_schattenfarbe_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0.5 }
        );

        setDB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_ShadowHorizontal(
          Design_Right_Social !== undefined &&
            Design_Right_Social.right_social_hover_schattenposition_horizontal !==
              undefined
            ? Design_Right_Social.right_social_hover_schattenposition_horizontal
            : "-4"
        );

        setDB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_ShadowVertical(
          Design_Right_Social !== undefined &&
            Design_Right_Social.right_social_hover_schattenposition_vertikal !==
              undefined
            ? Design_Right_Social.right_social_hover_schattenposition_vertikal
            : "4"
        );

        setDB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_ShadowBlur(
          Design_Right_Social !== undefined &&
            Design_Right_Social.right_social_hover_schattenrauschen !==
              undefined
            ? Design_Right_Social.right_social_hover_schattenrauschen
            : "6"
        );

        setDB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_paddingLeft(
          Design_Right_Social !== undefined &&
            Design_Right_Social.right_social_hover_innerer_abstand_links !==
              undefined
            ? Design_Right_Social.right_social_hover_innerer_abstand_links
            : "0"
        );

        setDB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_paddingRight(
          Design_Right_Social !== undefined &&
            Design_Right_Social.right_social_hover_innerer_abstand_rechts !==
              undefined
            ? Design_Right_Social.right_social_hover_innerer_abstand_rechts
            : "0"
        );

        setDB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_paddingTop(
          Design_Right_Social !== undefined &&
            Design_Right_Social.right_social_hover_innerer_abstand_oben !==
              undefined
            ? Design_Right_Social.right_social_hover_innerer_abstand_oben
            : "5"
        );
        setDB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_paddingBottom(
          Design_Right_Social !== undefined &&
            Design_Right_Social.right_social_hover_innerer_abstand_unten !==
              undefined
            ? Design_Right_Social.right_social_hover_innerer_abstand_unten
            : "5"
        );

        setDB_LeftSidebar_MenuContainer_Social_DDBorderColorLeft(
          Design_Right_Social !== undefined &&
            Design_Right_Social.right_social_border_color_left !== undefined
            ? Design_Right_Social.right_social_border_color_left
            : "rgba(0,0,0,0.3)"
        );
        setDB_LeftSidebar_MenuContainer_Social_DDBorderColorLeftNotEncoded(
          Design_Right_Social !== undefined &&
            Design_Right_Social.right_social_border_color_left_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Right_Social.right_social_border_color_left_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0.3 }
        );
        setDB_LeftSidebar_MenuContainer_Social_DDBorderColorRight(
          Design_Right_Social !== undefined &&
            Design_Right_Social.right_social_border_color_right !== undefined
            ? Design_Right_Social.right_social_border_color_right
            : "rgba(0,0,0,0.3)"
        );
        setDB_LeftSidebar_MenuContainer_Social_DDBorderColorRightNotEncoded(
          Design_Right_Social !== undefined &&
            Design_Right_Social.right_social_border_color_right_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Right_Social.right_social_border_color_right_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0.3 }
        );
        setDB_LeftSidebar_MenuContainer_Social_DDBorderColorTop(
          Design_Right_Social !== undefined &&
            Design_Right_Social.right_social_border_color_top !== undefined
            ? Design_Right_Social.right_social_border_color_top
            : "rgba(0,0,0,0.3)"
        );
        setDB_LeftSidebar_MenuContainer_Social_DDBorderColorTopNotEncoded(
          Design_Right_Social !== undefined &&
            Design_Right_Social.right_social_border_color_top_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Right_Social.right_social_border_color_top_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0.3 }
        );
        setDB_LeftSidebar_MenuContainer_Social_DDBorderColorBottom(
          Design_Right_Social !== undefined &&
            Design_Right_Social.right_social_border_color_bottom !== undefined
            ? Design_Right_Social.right_social_border_color_bottom
            : "rgba(0,0,0,0.3)"
        );
        setDB_LeftSidebar_MenuContainer_Social_DDBorderColorBottomNotEncoded(
          Design_Right_Social !== undefined &&
            Design_Right_Social.right_social_border_color_bottom_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Right_Social.right_social_border_color_bottom_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0.3 }
        );

        setDB_LeftSidebar_MenuContainer_Social_DDBorderLeft(
          Design_Right_Social !== undefined &&
            Design_Right_Social.right_social_border_pixel_left !== undefined
            ? Design_Right_Social.right_social_border_pixel_left
            : "1"
        );
        setDB_LeftSidebar_MenuContainer_Social_DDBorderRight(
          Design_Right_Social !== undefined &&
            Design_Right_Social.right_social_border_pixel_right !== undefined
            ? Design_Right_Social.right_social_border_pixel_right
            : "1"
        );
        setDB_LeftSidebar_MenuContainer_Social_DDBorderTop(
          Design_Right_Social !== undefined &&
            Design_Right_Social.right_social_border_pixel_top !== undefined
            ? Design_Right_Social.right_social_border_pixel_top
            : "1"
        );
        setDB_LeftSidebar_MenuContainer_Social_DDBorderBottom(
          Design_Right_Social !== undefined &&
            Design_Right_Social.right_social_border_pixel_bottom !== undefined
            ? Design_Right_Social.right_social_border_pixel_bottom
            : "1"
        );

        setDB_LeftSidebar_MenuItem_MenuContainer_Social_DDBorderTopLeftRadius(
          Design_Right_Social !== undefined &&
            Design_Right_Social.right_social_border_radius_top_left !==
              undefined
            ? Design_Right_Social.right_social_border_radius_top_left
            : "7"
        );
        setDB_LeftSidebar_MenuItem_MenuContainer_Social_DDBorderTopRightRadius(
          Design_Right_Social !== undefined &&
            Design_Right_Social.right_social_border_radius_top_right !==
              undefined
            ? Design_Right_Social.right_social_border_radius_top_right
            : "7"
        );
        setDB_LeftSidebar_MenuItem_MenuContainer_Social_DDBorderBottomLeftRadius(
          Design_Right_Social !== undefined &&
            Design_Right_Social.right_social_border_radius_bottom_left !==
              undefined
            ? Design_Right_Social.right_social_border_radius_bottom_left
            : "7"
        );
        setDB_LeftSidebar_MenuItem_MenuContainer_Social_DDBorderBottomRightRadius(
          Design_Right_Social !== undefined &&
            Design_Right_Social.right_social_border_radius_bottom_right !==
              undefined
            ? Design_Right_Social.right_social_border_radius_bottom_right
            : "7"
        );

        setDB_LeftSidebar_MenuContainer_Social_DDBorderStyleLeft(
          Design_Right_Social !== undefined &&
            Design_Right_Social.right_social_border_style_left !== undefined
            ? Design_Right_Social.right_social_border_style_left
            : "solid"
        );
        setDB_LeftSidebar_MenuContainer_Social_DDBorderStyleRight(
          Design_Right_Social !== undefined &&
            Design_Right_Social.right_social_border_style_right !== undefined
            ? Design_Right_Social.right_social_border_style_right
            : "solid"
        );
        setDB_LeftSidebar_MenuContainer_Social_DDBorderStyleTop(
          Design_Right_Social !== undefined &&
            Design_Right_Social.right_social_border_style_top !== undefined
            ? Design_Right_Social.right_social_border_style_top
            : "solid"
        );
        setDB_LeftSidebar_MenuContainer_Social_DDBorderStyleBottom(
          Design_Right_Social !== undefined &&
            Design_Right_Social.right_social_border_style_bottom !== undefined
            ? Design_Right_Social.right_social_border_style_bottom
            : "solid"
        );

        setDB_LeftSidebar_MenuContainer_Social_DDHover_BorderColorLeft(
          Design_Right_Social !== undefined &&
            Design_Right_Social.right_social_border_hov_color_left !== undefined
            ? Design_Right_Social.right_social_border_hov_color_left
            : "rgba(0,0,0,0.3)"
        );
        setDB_LeftSidebar_MenuContainer_Social_DDHover_BorderColorLeftNotEncoded(
          Design_Right_Social !== undefined &&
            Design_Right_Social.right_social_border_hov_color_left_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Right_Social.right_social_border_hov_color_left_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0.3 }
        );
        setDB_LeftSidebar_MenuContainer_Social_DDHover_BorderColorRight(
          Design_Right_Social !== undefined &&
            Design_Right_Social.right_social_border_hov_color_right !==
              undefined
            ? Design_Right_Social.right_social_border_hov_color_right
            : "rgba(0,0,0,0.3)"
        );
        setDB_LeftSidebar_MenuContainer_Social_DDHover_BorderColorRightNotEncoded(
          Design_Right_Social !== undefined &&
            Design_Right_Social.right_social_border_hov_color_right_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Right_Social.right_social_border_hov_color_right_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0.3 }
        );
        setDB_LeftSidebar_MenuContainer_Social_DDHover_BorderColorTop(
          Design_Right_Social !== undefined &&
            Design_Right_Social.right_social_border_hov_color_top !== undefined
            ? Design_Right_Social.right_social_border_hov_color_top
            : "rgba(0,0,0,0.3)"
        );
        setDB_LeftSidebar_MenuContainer_Social_DDHover_BorderColorTopNotEncoded(
          Design_Right_Social !== undefined &&
            Design_Right_Social.right_social_border_hov_color_top_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Right_Social.right_social_border_hov_color_top_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0.3 }
        );
        setDB_LeftSidebar_MenuContainer_Social_DDHover_BorderColorBottom(
          Design_Right_Social !== undefined &&
            Design_Right_Social.right_social_border_hov_color_bottom !==
              undefined
            ? Design_Right_Social.right_social_border_hov_color_bottom
            : "rgba(0,0,0,0.3)"
        );
        setDB_LeftSidebar_MenuContainer_Social_DDHover_BorderColorBottomNotEncoded(
          Design_Right_Social !== undefined &&
            Design_Right_Social.right_social_border_hov_color_bottom_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Right_Social.right_social_border_hov_color_bottom_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0.3 }
        );

        setDB_LeftSidebar_MenuContainer_Social_DDHover_BorderLeft(
          Design_Right_Social !== undefined &&
            Design_Right_Social.right_social_border_hov_pixel_left !== undefined
            ? Design_Right_Social.right_social_border_hov_pixel_left
            : "1"
        );
        setDB_LeftSidebar_MenuContainer_Social_DDHover_BorderRight(
          Design_Right_Social !== undefined &&
            Design_Right_Social.right_social_border_hov_pixel_right !==
              undefined
            ? Design_Right_Social.right_social_border_hov_pixel_right
            : "1"
        );
        setDB_LeftSidebar_MenuContainer_Social_DDHover_BorderTop(
          Design_Right_Social !== undefined &&
            Design_Right_Social.right_social_border_hov_pixel_top !== undefined
            ? Design_Right_Social.right_social_border_hov_pixel_top
            : "1"
        );
        setDB_LeftSidebar_MenuContainer_Social_DDHover_BorderBottom(
          Design_Right_Social !== undefined &&
            Design_Right_Social.right_social_border_hov_pixel_bottom !==
              undefined
            ? Design_Right_Social.right_social_border_hov_pixel_bottom
            : "1"
        );

        setDB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_BorderTopLeftRadius(
          Design_Right_Social !== undefined &&
            Design_Right_Social.right_social_border_hov_radius_top_left !==
              undefined
            ? Design_Right_Social.right_social_border_hov_radius_top_left
            : "7"
        );
        setDB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_BorderTopRightRadius(
          Design_Right_Social !== undefined &&
            Design_Right_Social.right_social_border_hov_radius_top_right !==
              undefined
            ? Design_Right_Social.right_social_border_hov_radius_top_right
            : "7"
        );
        setDB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_BorderBottomLeftRadius(
          Design_Right_Social !== undefined &&
            Design_Right_Social.right_social_border_hov_radius_bottom_left !==
              undefined
            ? Design_Right_Social.right_social_border_hov_radius_bottom_left
            : "7"
        );
        setDB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_BorderBottomRightRadius(
          Design_Right_Social !== undefined &&
            Design_Right_Social.right_social_border_hov_radius_bottom_right !==
              undefined
            ? Design_Right_Social.right_social_border_hov_radius_bottom_right
            : "7"
        );

        setDB_LeftSidebar_MenuContainer_Social_DDHover_BorderStyleLeft(
          Design_Right_Social !== undefined &&
            Design_Right_Social.right_social_border_hov_style_left !== undefined
            ? Design_Right_Social.right_social_border_hov_style_left
            : "solid"
        );
        setDB_LeftSidebar_MenuContainer_Social_DDHover_BorderStyleRight(
          Design_Right_Social !== undefined &&
            Design_Right_Social.right_social_border_hov_style_right !==
              undefined
            ? Design_Right_Social.right_social_border_hov_style_right
            : "solid"
        );
        setDB_LeftSidebar_MenuContainer_Social_DDHover_BorderStyleTop(
          Design_Right_Social !== undefined &&
            Design_Right_Social.right_social_border_hov_style_top !== undefined
            ? Design_Right_Social.right_social_border_hov_style_top
            : "solid"
        );
        setDB_LeftSidebar_MenuContainer_Social_DDHover_BorderStyleBottom(
          Design_Right_Social !== undefined &&
            Design_Right_Social.right_social_border_hov_style_bottom !==
              undefined
            ? Design_Right_Social.right_social_border_hov_style_bottom
            : "solid"
        );

        setDB_LeftSidebar_MenuItem_TitelSocialTitle_EE_FontColor(
          Design_Right_Social !== undefined &&
            Design_Right_Social.right_socialTitle_schriftfarbe !== undefined
            ? Design_Right_Social.right_socialTitle_schriftfarbe
            : "rgba(255,255,255,1)"
        );
        setDB_LeftSidebar_MenuItem_TitelSocialTitle_EE_FontColorNotEncoded(
          Design_Right_Social !== undefined &&
            Design_Right_Social.right_socialTitle_schriftfarbe_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Right_Social.right_socialTitle_schriftfarbe_not_encoded
              )
            : { r: 255, g: 255, b: 255, a: 1 }
        );
        setDB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BackgroundColor(
          Design_Right_Social !== undefined &&
            Design_Right_Social.right_socialTitle_hintergrundfarbe !== undefined
            ? Design_Right_Social.right_socialTitle_hintergrundfarbe
            : "rgba(0,0,0,0.4)"
        );
        setDB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BackgroundColorNotEncoded(
          Design_Right_Social !== undefined &&
            Design_Right_Social.right_socialTitle_hintergrundfarbe_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Right_Social.right_socialTitle_hintergrundfarbe_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0.4 }
        );
        setDB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowColor(
          Design_Right_Social !== undefined &&
            Design_Right_Social.right_socialTitle_schattenfarbe !== undefined
            ? Design_Right_Social.right_socialTitle_schattenfarbe
            : "rgba(0,0,0,1)"
        );
        setDB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowColorNotEncoded(
          Design_Right_Social !== undefined &&
            Design_Right_Social.right_socialTitle_schattenfarbe_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Right_Social.right_socialTitle_schattenfarbe_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 1 }
        );
        setDB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowHorizontal(
          Design_Right_Social !== undefined &&
            Design_Right_Social.right_socialTitle_schattenposition_horizontal !==
              undefined
            ? Design_Right_Social.right_socialTitle_schattenposition_horizontal
            : "1"
        );
        setDB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowVertical(
          Design_Right_Social !== undefined &&
            Design_Right_Social.right_socialTitle_schattenposition_vertikal !==
              undefined
            ? Design_Right_Social.right_socialTitle_schattenposition_vertikal
            : "1"
        );
        setDB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowBlur(
          Design_Right_Social !== undefined &&
            Design_Right_Social.right_socialTitle_schattenrauschen !== undefined
            ? Design_Right_Social.right_socialTitle_schattenrauschen
            : "3"
        );
        setDB_LeftSidebar_MenuItem_TitelSocialTitle_EE_FontSize(
          Design_Right_Social !== undefined &&
            Design_Right_Social.right_socialTitle_schriftgroesse !== undefined
            ? Design_Right_Social.right_socialTitle_schriftgroesse
            : "19"
        );
        setDB_LeftSidebar_MenuItem_TitelSocialTitle_EE_FontWeight(
          Design_Right_Social !== undefined &&
            Design_Right_Social.right_socialTitle_schriftgewicht !== undefined
            ? Design_Right_Social.right_socialTitle_schriftgewicht
            : "300"
        );
        setDB_LeftSidebar_MenuItem_TitelSocialTitle_EE_letterSpacing(
          Design_Right_Social !== undefined &&
            Design_Right_Social.right_socialTitle_buchstabenabstand !==
              undefined
            ? Design_Right_Social.right_socialTitle_buchstabenabstand
            : "0"
        );
        setDB_LeftSidebar_MenuItem_TitelSocialTitle_EE_TextDecoration(
          Design_Right_Social !== undefined &&
            Design_Right_Social.right_socialTitle_text_dekoration !== undefined
            ? Design_Right_Social.right_socialTitle_text_dekoration
            : "none"
        );
        setDB_LeftSidebar_MenuItem_TitelSocialTitle_EE_TextDecorationStyle(
          Design_Right_Social !== undefined &&
            Design_Right_Social.right_socialTitle_text_dekoration_stil !==
              undefined
            ? Design_Right_Social.right_socialTitle_text_dekoration_stil
            : "none"
        );
        setDB_LeftSidebar_MenuItem_TitelSocialTitle_EE_marginLeftRight(
          Design_Right_Social !== undefined &&
            Design_Right_Social.right_socialTitle_aussen_abstand_links_rechts !==
              undefined
            ? Design_Right_Social.right_socialTitle_aussen_abstand_links_rechts
            : "0"
        );
        setDB_LeftSidebar_MenuItem_TitelSocialTitle_EE_marginTopBottom(
          Design_Right_Social !== undefined &&
            Design_Right_Social.right_socialTitle_aussen_abstand_oben_unten !==
              undefined
            ? Design_Right_Social.right_socialTitle_aussen_abstand_oben_unten
            : "0"
        );
        setDB_LeftSidebar_MenuItem_TitelSocialTitle_EE_paddingLeftRight(
          Design_Right_Social !== undefined &&
            Design_Right_Social.right_socialTitle_innerer_abstand_links_rechts !==
              undefined
            ? Design_Right_Social.right_socialTitle_innerer_abstand_links_rechts
            : "0"
        );
        setDB_LeftSidebar_MenuItem_TitelSocialTitle_EE_paddingTopBottom(
          Design_Right_Social !== undefined &&
            Design_Right_Social.right_socialTitle_innerer_abstand_oben_unten !==
              undefined
            ? Design_Right_Social.right_socialTitle_innerer_abstand_oben_unten
            : "0"
        );
        setDB_LeftSidebar_MenuItem_TitelSocialTitle_EE_TextAlign(
          Design_Right_Social !== undefined &&
            Design_Right_Social.right_socialTitle_textausrichtung !== undefined
            ? Design_Right_Social.right_socialTitle_textausrichtung
            : "center"
        );

        setDB_fontFace_SocialTitelFontFamily_FontFamilyName(
          Design_Right_Social !== undefined &&
            Design_Right_Social.right_socialTitle_fontfamily !== undefined
            ? Design_Right_Social.right_socialTitle_fontfamily
            : "Lato"
        );

        setDB_fontFace_SocialTitelFontFamily_FontFamilySrc(
          Design_Right_Social !== undefined &&
            Design_Right_Social.right_socialTitle_fontfamilysrc !== undefined
            ? Design_Right_Social.right_socialTitle_fontfamilysrc
            : "/assets/Lato/Lato-Regular.ttf"
        );

        setDB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_FontColor(
          Design_Right_Social !== undefined &&
            Design_Right_Social.right_socialTitle_hov_schriftfarbe !== undefined
            ? Design_Right_Social.right_socialTitle_hov_schriftfarbe
            : "rgba(255,255,255,1)"
        );
        setDB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_FontColorNotEncoded(
          Design_Right_Social !== undefined &&
            Design_Right_Social.right_socialTitle_hov_schriftfarbe_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Right_Social.right_socialTitle_hov_schriftfarbe_not_encoded
              )
            : { r: 255, g: 255, b: 255, a: 1 }
        );
        setDB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BackgroundColor(
          Design_Right_Social !== undefined &&
            Design_Right_Social.right_socialTitle_hov_hintergrundfarbe !==
              undefined
            ? Design_Right_Social.right_socialTitle_hov_hintergrundfarbe
            : "rgba(0,0,0,0.4)"
        );
        setDB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BackgroundColorNotEncoded(
          Design_Right_Social !== undefined &&
            Design_Right_Social.right_socialTitle_hov_hintergrundfarbe_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Right_Social.right_socialTitle_hov_hintergrundfarbe_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0.4 }
        );
        setDB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowColor_Hover(
          Design_Right_Social !== undefined &&
            Design_Right_Social.right_socialTitle_hov_schattenfarbe !==
              undefined
            ? Design_Right_Social.right_socialTitle_hov_schattenfarbe
            : "rgba(0,0,0,1)"
        );
        setDB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowColorNotEncoded_Hover(
          Design_Right_Social !== undefined &&
            Design_Right_Social.right_socialTitle_hov_schattenfarbe_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Right_Social.right_socialTitle_hov_schattenfarbe_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 1 }
        );
        setDB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowHorizontal_Hover(
          Design_Right_Social !== undefined &&
            Design_Right_Social.right_socialTitle_hov_schattenposition_horizontal !==
              undefined
            ? Design_Right_Social.right_socialTitle_hov_schattenposition_horizontal
            : "1"
        );
        setDB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowVertical_Hover(
          Design_Right_Social !== undefined &&
            Design_Right_Social.right_socialTitle_hov_schattenposition_vertikal !==
              undefined
            ? Design_Right_Social.right_socialTitle_hov_schattenposition_vertikal
            : "1"
        );
        setDB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowBlur_Hover(
          Design_Right_Social !== undefined &&
            Design_Right_Social.right_socialTitle_hov_schattenrauschen !==
              undefined
            ? Design_Right_Social.right_socialTitle_hov_schattenrauschen
            : "3"
        );
        setDB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_FontSize(
          Design_Right_Social !== undefined &&
            Design_Right_Social.right_socialTitle_hov_schriftgroesse !==
              undefined
            ? Design_Right_Social.right_socialTitle_hov_schriftgroesse
            : "19"
        );
        setDB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_FontWeight(
          Design_Right_Social !== undefined &&
            Design_Right_Social.right_socialTitle_hov_schriftgewicht !==
              undefined
            ? Design_Right_Social.right_socialTitle_hov_schriftgewicht
            : "300"
        );
        setDB_LeftSidebar_MenuItem_TitelSocialTitle_EE_letterSpacing_Hover(
          Design_Right_Social !== undefined &&
            Design_Right_Social.right_socialTitle_hov_buchstabenabstand !==
              undefined
            ? Design_Right_Social.right_socialTitle_hov_buchstabenabstand
            : "0"
        );
        setDB_LeftSidebar_MenuItem_TitelSocialTitle_EE_TextDecoration_Hover(
          Design_Right_Social !== undefined &&
            Design_Right_Social.right_socialTitle_hov_text_dekoration !==
              undefined
            ? Design_Right_Social.right_socialTitle_hov_text_dekoration
            : "none"
        );
        setDB_LeftSidebar_MenuItem_TitelSocialTitle_EE_TextDecorationStyle_Hover(
          Design_Right_Social !== undefined &&
            Design_Right_Social.right_socialTitle_hov_text_dekoration_stil !==
              undefined
            ? Design_Right_Social.right_socialTitle_hov_text_dekoration_stil
            : "none"
        );
        setDB_LeftSidebar_MenuItem_TitelSocialTitle_EE_marginLeftRight_Hover(
          Design_Right_Social !== undefined &&
            Design_Right_Social.right_socialTitle_hov_aussen_abstand_links_rechts !==
              undefined
            ? Design_Right_Social.right_socialTitle_hov_aussen_abstand_links_rechts
            : "0"
        );
        setDB_LeftSidebar_MenuItem_TitelSocialTitle_EE_marginTopBottom_Hover(
          Design_Right_Social !== undefined &&
            Design_Right_Social.right_socialTitle_hov_aussen_abstand_oben_unten !==
              undefined
            ? Design_Right_Social.right_socialTitle_hov_aussen_abstand_oben_unten
            : "0"
        );
        setDB_LeftSidebar_MenuItem_TitelSocialTitle_EE_paddingLeftRight_Hover(
          Design_Right_Social !== undefined &&
            Design_Right_Social.right_socialTitle_hov_innerer_abstand_links_rechts !==
              undefined
            ? Design_Right_Social.right_socialTitle_hov_innerer_abstand_links_rechts
            : "0"
        );
        setDB_LeftSidebar_MenuItem_TitelSocialTitle_EE_paddingTopBottom_Hover(
          Design_Right_Social !== undefined &&
            Design_Right_Social.right_socialTitle_hov_innerer_abstand_oben_unten !==
              undefined
            ? Design_Right_Social.right_socialTitle_hov_innerer_abstand_oben_unten
            : "0"
        );
        setDB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_TextAlign(
          Design_Right_Social !== undefined &&
            Design_Right_Social.right_socialTitle_hov_textausrichtung !==
              undefined
            ? Design_Right_Social.right_socialTitle_hov_textausrichtung
            : "center"
        );

        setDB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderColorLeft(
          Design_Right_Social !== undefined &&
            Design_Right_Social.right_socialTitle_border_color_left !==
              undefined
            ? Design_Right_Social.right_socialTitle_border_color_left
            : "rgba(0,0,0,0)"
        );
        setDB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderColorLeftNotEncoded(
          Design_Right_Social !== undefined &&
            Design_Right_Social.right_socialTitle_border_color_left_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Right_Social.right_socialTitle_border_color_left_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0 }
        );
        setDB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderColorRight(
          Design_Right_Social !== undefined &&
            Design_Right_Social.right_socialTitle_border_color_right !==
              undefined
            ? Design_Right_Social.right_socialTitle_border_color_right
            : "rgba(0,0,0,0)"
        );
        setDB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderColorRightNotEncoded(
          Design_Right_Social !== undefined &&
            Design_Right_Social.right_socialTitle_border_color_right_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Right_Social.right_socialTitle_border_color_right_not_encoded
              )
            : { r: 255, g: 255, b: 255, a: 1 }
        );
        setDB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderColorTop(
          Design_Right_Social !== undefined &&
            Design_Right_Social.right_socialTitle_border_color_top !== undefined
            ? Design_Right_Social.right_socialTitle_border_color_top
            : "rgba(255,255,255,1)"
        );
        setDB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderColorTopNotEncoded(
          Design_Right_Social !== undefined &&
            Design_Right_Social.right_socialTitle_border_color_top_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Right_Social.right_socialTitle_border_color_top_not_encoded
              )
            : { r: 255, g: 255, b: 255, a: 1 }
        );
        setDB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderColorBottom(
          Design_Right_Social !== undefined &&
            Design_Right_Social.right_socialTitle_border_color_bottom !==
              undefined
            ? Design_Right_Social.right_socialTitle_border_color_bottom
            : "rgba(255,255,255,1)"
        );
        setDB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderColorBottomNotEncoded(
          Design_Right_Social !== undefined &&
            Design_Right_Social.right_socialTitle_border_color_bottom_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Right_Social.right_socialTitle_border_color_bottom_not_encoded
              )
            : { r: 255, g: 255, b: 255, a: 1 }
        );

        setDB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderLeft(
          Design_Right_Social !== undefined &&
            Design_Right_Social.right_socialTitle_border_pixel_left !==
              undefined
            ? Design_Right_Social.right_socialTitle_border_pixel_left
            : "0"
        );
        setDB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderRight(
          Design_Right_Social !== undefined &&
            Design_Right_Social.right_socialTitle_border_pixel_right !==
              undefined
            ? Design_Right_Social.right_socialTitle_border_pixel_right
            : "0"
        );
        setDB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderTop(
          Design_Right_Social !== undefined &&
            Design_Right_Social.right_socialTitle_border_pixel_top !== undefined
            ? Design_Right_Social.right_socialTitle_border_pixel_top
            : "2"
        );
        setDB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderBottom(
          Design_Right_Social !== undefined &&
            Design_Right_Social.right_socialTitle_border_pixel_bottom !==
              undefined
            ? Design_Right_Social.right_socialTitle_border_pixel_bottom
            : "2"
        );

        setDB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderTopLeftRadius(
          Design_Right_Social !== undefined &&
            Design_Right_Social.right_socialTitle_border_radius_top_left !==
              undefined
            ? Design_Right_Social.right_socialTitle_border_radius_top_left
            : "0"
        );
        setDB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderTopRightRadius(
          Design_Right_Social !== undefined &&
            Design_Right_Social.right_socialTitle_border_radius_top_right !==
              undefined
            ? Design_Right_Social.right_socialTitle_border_radius_top_right
            : "0"
        );
        setDB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderBottomLeftRadius(
          Design_Right_Social !== undefined &&
            Design_Right_Social.right_socialTitle_border_radius_bottom_left !==
              undefined
            ? Design_Right_Social.right_socialTitle_border_radius_bottom_left
            : "0"
        );
        setDB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderBottomRightRadius(
          Design_Right_Social !== undefined &&
            Design_Right_Social.right_socialTitle_border_radius_bottom_right !==
              undefined
            ? Design_Right_Social.right_socialTitle_border_radius_bottom_right
            : "0"
        );

        setDB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderStyleLeft(
          Design_Right_Social !== undefined &&
            Design_Right_Social.right_socialTitle_border_style_left !==
              undefined
            ? Design_Right_Social.right_socialTitle_border_style_left
            : "solid"
        );
        setDB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderStyleRight(
          Design_Right_Social !== undefined &&
            Design_Right_Social.right_socialTitle_border_style_right !==
              undefined
            ? Design_Right_Social.right_socialTitle_border_style_right
            : "solid"
        );
        setDB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderStyleTop(
          Design_Right_Social !== undefined &&
            Design_Right_Social.right_socialTitle_border_style_top !== undefined
            ? Design_Right_Social.right_socialTitle_border_style_top
            : "solid"
        );
        setDB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderStyleBottom(
          Design_Right_Social !== undefined &&
            Design_Right_Social.right_socialTitle_border_style_bottom !==
              undefined
            ? Design_Right_Social.right_socialTitle_border_style_bottom
            : "solid"
        );

        setDB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderColorLeft(
          Design_Right_Social !== undefined &&
            Design_Right_Social.right_socialTitle_border_hov_color_left !==
              undefined
            ? Design_Right_Social.right_socialTitle_border_hov_color_left
            : "rgba(0,0,0,0)"
        );
        setDB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderColorLeftNotEncoded(
          Design_Right_Social !== undefined &&
            Design_Right_Social.right_socialTitle_border_hov_color_left_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Right_Social.right_socialTitle_border_hov_color_left_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0 }
        );
        setDB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderColorRight(
          Design_Right_Social !== undefined &&
            Design_Right_Social.right_socialTitle_border_hov_color_right !==
              undefined
            ? Design_Right_Social.right_socialTitle_border_hov_color_right
            : "rgba(0,0,0,0)"
        );
        setDB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderColorRightNotEncoded(
          Design_Right_Social !== undefined &&
            Design_Right_Social.right_socialTitle_border_hov_color_right_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Right_Social.right_socialTitle_border_hov_color_right_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0 }
        );
        setDB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderColorTop(
          Design_Right_Social !== undefined &&
            Design_Right_Social.right_socialTitle_border_hov_color_top !==
              undefined
            ? Design_Right_Social.right_socialTitle_border_hov_color_top
            : "rgba(255,255,255,1)"
        );
        setDB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderColorTopNotEncoded(
          Design_Right_Social !== undefined &&
            Design_Right_Social.right_socialTitle_border_hov_color_top_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Right_Social.right_socialTitle_border_hov_color_top_not_encoded
              )
            : { r: 255, g: 255, b: 255, a: 1 }
        );
        setDB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderColorBottom(
          Design_Right_Social !== undefined &&
            Design_Right_Social.right_socialTitle_border_hov_color_bottom !==
              undefined
            ? Design_Right_Social.right_socialTitle_border_hov_color_bottom
            : "rgba(255,255,255,1)"
        );
        setDB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderColorBottomNotEncoded(
          Design_Right_Social !== undefined &&
            Design_Right_Social.right_socialTitle_border_hov_color_bottom_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Right_Social.right_socialTitle_border_hov_color_bottom_not_encoded
              )
            : { r: 255, g: 255, b: 255, a: 1 }
        );

        setDB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderLeft(
          Design_Right_Social !== undefined &&
            Design_Right_Social.right_socialTitle_border_hov_pixel_left !==
              undefined
            ? Design_Right_Social.right_socialTitle_border_hov_pixel_left
            : "0"
        );
        setDB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderRight(
          Design_Right_Social !== undefined &&
            Design_Right_Social.right_socialTitle_border_hov_pixel_right !==
              undefined
            ? Design_Right_Social.right_socialTitle_border_hov_pixel_right
            : "0"
        );
        setDB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderTop(
          Design_Right_Social !== undefined &&
            Design_Right_Social.right_socialTitle_border_hov_pixel_top !==
              undefined
            ? Design_Right_Social.right_socialTitle_border_hov_pixel_top
            : "2"
        );
        setDB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderBottom(
          Design_Right_Social !== undefined &&
            Design_Right_Social.right_socialTitle_border_hov_pixel_bottom !==
              undefined
            ? Design_Right_Social.right_socialTitle_border_hov_pixel_bottom
            : "2"
        );

        setDB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderTopLeftRadius(
          Design_Right_Social !== undefined &&
            Design_Right_Social.right_socialTitle_border_hov_radius_top_left !==
              undefined
            ? Design_Right_Social.right_socialTitle_border_hov_radius_top_left
            : "0"
        );
        setDB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderTopRightRadius(
          Design_Right_Social !== undefined &&
            Design_Right_Social.right_socialTitle_border_hov_radius_top_right !==
              undefined
            ? Design_Right_Social.right_socialTitle_border_hov_radius_top_right
            : "0"
        );
        setDB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderBottomLeftRadius(
          Design_Right_Social !== undefined &&
            Design_Right_Social.right_socialTitle_border_hov_radius_bottom_left !==
              undefined
            ? Design_Right_Social.right_socialTitle_border_hov_radius_bottom_left
            : "0"
        );
        setDB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderBottomRightRadius(
          Design_Right_Social !== undefined &&
            Design_Right_Social.right_socialTitle_border_hov_radius_bottom_right !==
              undefined
            ? Design_Right_Social.right_socialTitle_border_hov_radius_bottom_right
            : "0"
        );

        setDB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderStyleLeft(
          Design_Right_Social !== undefined &&
            Design_Right_Social.right_socialTitle_border_hov_style_left !==
              undefined
            ? Design_Right_Social.right_socialTitle_border_hov_style_left
            : "solid"
        );
        setDB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderStyleRight(
          Design_Right_Social !== undefined &&
            Design_Right_Social.right_socialTitle_border_hov_style_right !==
              undefined
            ? Design_Right_Social.right_socialTitle_border_hov_style_right
            : "solid"
        );
        setDB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderStyleTop(
          Design_Right_Social !== undefined &&
            Design_Right_Social.right_socialTitle_border_hov_style_top !==
              undefined
            ? Design_Right_Social.right_socialTitle_border_hov_style_top
            : "solid"
        );
        setDB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderStyleBottom(
          Design_Right_Social !== undefined &&
            Design_Right_Social.right_socialTitle_border_hov_style_bottom !==
              undefined
            ? Design_Right_Social.right_socialTitle_border_hov_style_bottom
            : "solid"
        );

        /////////////////////////////7777777777777777777777777777777777777777

        setDB_LeftSidebar_MenuItem_SocialText_FontColor(
          Design_Right_Social !== undefined &&
            Design_Right_Social.right_socialItem_schriftfarbe !== undefined
            ? Design_Right_Social.right_socialItem_schriftfarbe
            : "rgba(255,255,255,1)"
        );
        setDB_LeftSidebar_MenuItem_SocialText_FontColorNotEncoded(
          Design_Right_Social !== undefined &&
            Design_Right_Social.right_socialItem_schriftfarbe_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Right_Social.right_socialItem_schriftfarbe_not_encoded
              )
            : { r: 255, g: 255, b: 255, a: 1 }
        );
        setDB_LeftSidebar_MenuItem_SocialText_BackgroundColor(
          Design_Right_Social !== undefined &&
            Design_Right_Social.right_socialItem_hintergrundfarbe !== undefined
            ? Design_Right_Social.right_socialItem_hintergrundfarbe
            : "rgba(0,0,0,0)"
        );
        setDB_LeftSidebar_MenuItem_SocialText_BackgroundColorNotEncoded(
          Design_Right_Social !== undefined &&
            Design_Right_Social.right_socialItem_hintergrundfarbe_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Right_Social.right_socialItem_hintergrundfarbe_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0 }
        );
        setDB_LeftSidebar_MenuItem_SocialText_ShadowColor(
          Design_Right_Social !== undefined &&
            Design_Right_Social.right_socialItem_schattenfarbe !== undefined
            ? Design_Right_Social.right_socialItem_schattenfarbe
            : "rgba(0,0,0,0)"
        );
        setDB_LeftSidebar_MenuItem_SocialText_ShadowColorNotEncoded(
          Design_Right_Social !== undefined &&
            Design_Right_Social.right_socialItem_schattenfarbe_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Right_Social.right_socialItem_schattenfarbe_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0 }
        );
        setDB_LeftSidebar_MenuItem_SocialText_ShadowHorizontal(
          Design_Right_Social !== undefined &&
            Design_Right_Social.right_socialItem_schattenposition_horizontal !==
              undefined
            ? Design_Right_Social.right_socialItem_schattenposition_horizontal
            : "0"
        );
        setDB_LeftSidebar_MenuItem_SocialText_ShadowVertical(
          Design_Right_Social !== undefined &&
            Design_Right_Social.right_socialItem_schattenposition_vertikal !==
              undefined
            ? Design_Right_Social.right_socialItem_schattenposition_vertikal
            : "0"
        );
        setDB_LeftSidebar_MenuItem_SocialText_ShadowBlur(
          Design_Right_Social !== undefined &&
            Design_Right_Social.right_socialItem_schattenrauschen !== undefined
            ? Design_Right_Social.right_socialItem_schattenrauschen
            : "0"
        );
        setDB_LeftSidebar_MenuItem_SocialText_FontSize(
          Design_Right_Social !== undefined &&
            Design_Right_Social.right_socialItem_schriftgroesse !== undefined
            ? Design_Right_Social.right_socialItem_schriftgroesse
            : "15"
        );
        setDB_LeftSidebar_MenuItem_SocialText_FontWeight(
          Design_Right_Social !== undefined &&
            Design_Right_Social.right_socialItem_schriftgewicht !== undefined
            ? Design_Right_Social.right_socialItem_schriftgewicht
            : "100"
        );
        setDB_LeftSidebar_MenuItem_SocialText_letterSpacing(
          Design_Right_Social !== undefined &&
            Design_Right_Social.right_socialItem_buchstabenabstand !== undefined
            ? Design_Right_Social.right_socialItem_buchstabenabstand
            : "0"
        );
        setDB_LeftSidebar_MenuItem_SocialText_TextDecoration(
          Design_Right_Social !== undefined &&
            Design_Right_Social.right_socialItem_text_dekoration !== undefined
            ? Design_Right_Social.right_socialItem_text_dekoration
            : "none"
        );
        setDB_LeftSidebar_MenuItem_SocialText_TextDecorationStyle(
          Design_Right_Social !== undefined &&
            Design_Right_Social.right_socialItem_text_dekoration_stil !==
              undefined
            ? Design_Right_Social.right_socialItem_text_dekoration_stil
            : "none"
        );
        setDB_LeftSidebar_MenuItem_SocialText_marginLeftRight(
          Design_Right_Social !== undefined &&
            Design_Right_Social.right_socialItem_aussen_abstand_links_rechts !==
              undefined
            ? Design_Right_Social.right_socialItem_aussen_abstand_links_rechts
            : "6"
        );
        setDB_LeftSidebar_MenuItem_SocialText_marginTopBottom(
          Design_Right_Social !== undefined &&
            Design_Right_Social.right_socialItem_aussen_abstand_oben_unten !==
              undefined
            ? Design_Right_Social.right_socialItem_aussen_abstand_oben_unten
            : "4"
        );
        setDB_LeftSidebar_MenuItem_SocialText_paddingLeftRight(
          Design_Right_Social !== undefined &&
            Design_Right_Social.right_socialItem_innerer_abstand_links_rechts !==
              undefined
            ? Design_Right_Social.right_socialItem_innerer_abstand_links_rechts
            : "0"
        );
        setDB_LeftSidebar_MenuItem_SocialText_paddingTopBottom(
          Design_Right_Social !== undefined &&
            Design_Right_Social.right_socialItem_innerer_abstand_oben_unten !==
              undefined
            ? Design_Right_Social.right_socialItem_innerer_abstand_oben_unten
            : "0"
        );
        setDB_LeftSidebar_MenuItem_SocialText_TextAlign(
          Design_Right_Social !== undefined &&
            Design_Right_Social.right_socialItem_textausrichtung !== undefined
            ? Design_Right_Social.right_socialItem_textausrichtung
            : "center"
        );

        setDB_fontFace_SocialTitelFontFamily_FontFamilyName(
          Design_Right_Social !== undefined &&
            Design_Right_Social.right_socialItem_fontfamily !== undefined
            ? Design_Right_Social.right_socialItem_fontfamily
            : "Lato"
        );

        setDB_fontFace_SocialTitelFontFamily_FontFamilySrc(
          Design_Right_Social !== undefined &&
            Design_Right_Social.right_socialItem_fontfamilysrc !== undefined
            ? Design_Right_Social.right_socialItem_fontfamilysrc
            : "/assets/Lato/Lato-Regular.ttf"
        );

        setDB_LeftSidebar_MenuItem_SocialText_Hover_FontColor(
          Design_Right_Social !== undefined &&
            Design_Right_Social.right_socialItem_hov_schriftfarbe !== undefined
            ? Design_Right_Social.right_socialItem_hov_schriftfarbe
            : "rgba(255,255,255,1)"
        );
        setDB_LeftSidebar_MenuItem_SocialText_Hover_FontColorNotEncoded(
          Design_Right_Social !== undefined &&
            Design_Right_Social.right_socialItem_hov_schriftfarbe_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Right_Social.right_socialItem_hov_schriftfarbe_not_encoded
              )
            : { r: 255, g: 255, b: 255, a: 1 }
        );
        setDB_LeftSidebar_MenuItem_SocialText_Hover_BackgroundColor(
          Design_Right_Social !== undefined &&
            Design_Right_Social.right_socialItem_hov_hintergrundfarbe !==
              undefined
            ? Design_Right_Social.right_socialItem_hov_hintergrundfarbe
            : "rgba(0,0,0,0)"
        );
        setDB_LeftSidebar_MenuItem_SocialText_Hover_BackgroundColorNotEncoded(
          Design_Right_Social !== undefined &&
            Design_Right_Social.right_socialItem_hov_hintergrundfarbe_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Right_Social.right_socialItem_hov_hintergrundfarbe_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0 }
        );
        setDB_LeftSidebar_MenuItem_SocialText_ShadowColor_Hover(
          Design_Right_Social !== undefined &&
            Design_Right_Social.right_socialItem_hov_schattenfarbe !== undefined
            ? Design_Right_Social.right_socialItem_hov_schattenfarbe
            : "rgba(0,0,0,0)"
        );
        setDB_LeftSidebar_MenuItem_SocialText_ShadowColorNotEncoded_Hover(
          Design_Right_Social !== undefined &&
            Design_Right_Social.right_socialItem_hov_schattenfarbe_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Right_Social.right_socialItem_hov_schattenfarbe_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0 }
        );
        setDB_LeftSidebar_MenuItem_SocialText_ShadowHorizontal_Hover(
          Design_Right_Social !== undefined &&
            Design_Right_Social.right_socialItem_hov_schattenposition_horizontal !==
              undefined
            ? Design_Right_Social.right_socialItem_hov_schattenposition_horizontal
            : "0"
        );
        setDB_LeftSidebar_MenuItem_SocialText_ShadowVertical_Hover(
          Design_Right_Social !== undefined &&
            Design_Right_Social.right_socialItem_hov_schattenposition_vertikal !==
              undefined
            ? Design_Right_Social.right_socialItem_hov_schattenposition_vertikal
            : "0"
        );
        setDB_LeftSidebar_MenuItem_SocialText_ShadowBlur_Hover(
          Design_Right_Social !== undefined &&
            Design_Right_Social.right_socialItem_hov_schattenrauschen !==
              undefined
            ? Design_Right_Social.right_socialItem_hov_schattenrauschen
            : "0"
        );
        setDB_LeftSidebar_MenuItem_SocialText_Hover_FontSize(
          Design_Right_Social !== undefined &&
            Design_Right_Social.right_socialItem_hov_schriftgroesse !==
              undefined
            ? Design_Right_Social.right_socialItem_hov_schriftgroesse
            : "15"
        );
        setDB_LeftSidebar_MenuItem_SocialText_Hover_FontWeight(
          Design_Right_Social !== undefined &&
            Design_Right_Social.right_socialItem_hov_schriftgewicht !==
              undefined
            ? Design_Right_Social.right_socialItem_hov_schriftgewicht
            : "100"
        );
        setDB_LeftSidebar_MenuItem_SocialText_letterSpacing_Hover(
          Design_Right_Social !== undefined &&
            Design_Right_Social.right_socialItem_hov_buchstabenabstand !==
              undefined
            ? Design_Right_Social.right_socialItem_hov_buchstabenabstand
            : "0"
        );
        setDB_LeftSidebar_MenuItem_SocialText_TextDecoration_Hover(
          Design_Right_Social !== undefined &&
            Design_Right_Social.right_socialItem_hov_text_dekoration !==
              undefined
            ? Design_Right_Social.right_socialItem_hov_text_dekoration
            : "none"
        );
        setDB_LeftSidebar_MenuItem_SocialText_TextDecorationStyle_Hover(
          Design_Right_Social !== undefined &&
            Design_Right_Social.right_socialItem_hov_text_dekoration_stil !==
              undefined
            ? Design_Right_Social.right_socialItem_hov_text_dekoration_stil
            : "none"
        );
        setDB_LeftSidebar_MenuItem_SocialText_marginLeftRight_Hover(
          Design_Right_Social !== undefined &&
            Design_Right_Social.right_socialItem_hov_aussen_abstand_links_rechts !==
              undefined
            ? Design_Right_Social.right_socialItem_hov_aussen_abstand_links_rechts
            : "6"
        );
        setDB_LeftSidebar_MenuItem_SocialText_marginTopBottom_Hover(
          Design_Right_Social !== undefined &&
            Design_Right_Social.right_socialItem_hov_aussen_abstand_oben_unten !==
              undefined
            ? Design_Right_Social.right_socialItem_hov_aussen_abstand_oben_unten
            : "4"
        );
        setDB_LeftSidebar_MenuItem_SocialText_paddingLeftRight_Hover(
          Design_Right_Social !== undefined &&
            Design_Right_Social.right_socialItem_hov_innerer_abstand_links_rechts !==
              undefined
            ? Design_Right_Social.right_socialItem_hov_innerer_abstand_links_rechts
            : "0"
        );
        setDB_LeftSidebar_MenuItem_SocialText_paddingTopBottom_Hover(
          Design_Right_Social !== undefined &&
            Design_Right_Social.right_socialItem_hov_innerer_abstand_oben_unten !==
              undefined
            ? Design_Right_Social.right_socialItem_hov_innerer_abstand_oben_unten
            : "0"
        );
        setDB_LeftSidebar_MenuItem_SocialText_Hover_TextAlign(
          Design_Right_Social !== undefined &&
            Design_Right_Social.right_socialItem_hov_textausrichtung !==
              undefined
            ? Design_Right_Social.right_socialItem_hov_textausrichtung
            : "center"
        );

        setDB_LeftSidebar_MenuItem_SocialText_BorderColorLeft(
          Design_Right_Social !== undefined &&
            Design_Right_Social.right_socialItem_border_color_left !== undefined
            ? Design_Right_Social.right_socialItem_border_color_left
            : "rgba(0,0,0,0)"
        );
        setDB_LeftSidebar_MenuItem_SocialText_BorderColorLeftNotEncoded(
          Design_Right_Social !== undefined &&
            Design_Right_Social.right_socialItem_border_color_left_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Right_Social.right_socialItem_border_color_left_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0 }
        );
        setDB_LeftSidebar_MenuItem_SocialText_BorderColorRight(
          Design_Right_Social !== undefined &&
            Design_Right_Social.right_socialItem_border_color_right !==
              undefined
            ? Design_Right_Social.right_socialItem_border_color_right
            : "rgba(0,0,0,0)"
        );
        setDB_LeftSidebar_MenuItem_SocialText_BorderColorRightNotEncoded(
          Design_Right_Social !== undefined &&
            Design_Right_Social.right_socialItem_border_color_right_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Right_Social.right_socialItem_border_color_right_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0 }
        );
        setDB_LeftSidebar_MenuItem_SocialText_BorderColorTop(
          Design_Right_Social !== undefined &&
            Design_Right_Social.right_socialItem_border_color_top !== undefined
            ? Design_Right_Social.right_socialItem_border_color_top
            : "rgba(0,0,0,0)"
        );
        setDB_LeftSidebar_MenuItem_SocialText_BorderColorTopNotEncoded(
          Design_Right_Social !== undefined &&
            Design_Right_Social.right_socialItem_border_color_top_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Right_Social.right_socialItem_border_color_top_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0 }
        );
        setDB_LeftSidebar_MenuItem_SocialText_BorderColorBottom(
          Design_Right_Social !== undefined &&
            Design_Right_Social.right_socialItem_border_color_bottom !==
              undefined
            ? Design_Right_Social.right_socialItem_border_color_bottom
            : "rgba(0,0,0,0)"
        );
        setDB_LeftSidebar_MenuItem_SocialText_BorderColorBottomNotEncoded(
          Design_Right_Social !== undefined &&
            Design_Right_Social.right_socialItem_border_color_bottom_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Right_Social.right_socialItem_border_color_bottom_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0 }
        );

        setDB_LeftSidebar_MenuItem_SocialText_BorderLeft(
          Design_Right_Social !== undefined &&
            Design_Right_Social.right_socialItem_border_pixel_left !== undefined
            ? Design_Right_Social.right_socialItem_border_pixel_left
            : "0"
        );
        setDB_LeftSidebar_MenuItem_SocialText_BorderRight(
          Design_Right_Social !== undefined &&
            Design_Right_Social.right_socialItem_border_pixel_right !==
              undefined
            ? Design_Right_Social.right_socialItem_border_pixel_right
            : "0"
        );
        setDB_LeftSidebar_MenuItem_SocialText_BorderTop(
          Design_Right_Social !== undefined &&
            Design_Right_Social.right_socialItem_border_pixel_top !== undefined
            ? Design_Right_Social.right_socialItem_border_pixel_top
            : "0"
        );
        setDB_LeftSidebar_MenuItem_SocialText_BorderBottom(
          Design_Right_Social !== undefined &&
            Design_Right_Social.right_socialItem_border_pixel_bottom !==
              undefined
            ? Design_Right_Social.right_socialItem_border_pixel_bottom
            : "0"
        );

        setDB_LeftSidebar_MenuItem_SocialText_BorderTopLeftRadius(
          Design_Right_Social !== undefined &&
            Design_Right_Social.right_socialItem_border_radius_top_left !==
              undefined
            ? Design_Right_Social.right_socialItem_border_radius_top_left
            : "0"
        );
        setDB_LeftSidebar_MenuItem_SocialText_BorderTopRightRadius(
          Design_Right_Social !== undefined &&
            Design_Right_Social.right_socialItem_border_radius_top_right !==
              undefined
            ? Design_Right_Social.right_socialItem_border_radius_top_right
            : "0"
        );
        setDB_LeftSidebar_MenuItem_SocialText_BorderBottomLeftRadius(
          Design_Right_Social !== undefined &&
            Design_Right_Social.right_socialItem_border_radius_bottom_left !==
              undefined
            ? Design_Right_Social.right_socialItem_border_radius_bottom_left
            : "0"
        );
        setDB_LeftSidebar_MenuItem_SocialText_BorderBottomRightRadius(
          Design_Right_Social !== undefined &&
            Design_Right_Social.right_socialItem_border_radius_bottom_right !==
              undefined
            ? Design_Right_Social.right_socialItem_border_radius_bottom_right
            : "0"
        );

        setDB_LeftSidebar_MenuItem_SocialText_BorderStyleLeft(
          Design_Right_Social !== undefined &&
            Design_Right_Social.right_socialItem_border_style_left !== undefined
            ? Design_Right_Social.right_socialItem_border_style_left
            : "solid"
        );
        setDB_LeftSidebar_MenuItem_SocialText_BorderStyleRight(
          Design_Right_Social !== undefined &&
            Design_Right_Social.right_socialItem_border_style_right !==
              undefined
            ? Design_Right_Social.right_socialItem_border_style_right
            : "solid"
        );
        setDB_LeftSidebar_MenuItem_SocialText_BorderStyleTop(
          Design_Right_Social !== undefined &&
            Design_Right_Social.right_socialItem_border_style_top !== undefined
            ? Design_Right_Social.right_socialItem_border_style_top
            : "solid"
        );
        setDB_LeftSidebar_MenuItem_SocialText_BorderStyleBottom(
          Design_Right_Social !== undefined &&
            Design_Right_Social.right_socialItem_border_style_bottom !==
              undefined
            ? Design_Right_Social.right_socialItem_border_style_bottom
            : "solid"
        );

        setDB_LeftSidebar_MenuItem_SocialText_Hover_BorderColorLeft(
          Design_Right_Social !== undefined &&
            Design_Right_Social.right_socialItem_border_hov_color_left !==
              undefined
            ? Design_Right_Social.right_socialItem_border_hov_color_left
            : "rgba(0,0,0,0)"
        );
        setDB_LeftSidebar_MenuItem_SocialText_Hover_BorderColorLeftNotEncoded(
          Design_Right_Social !== undefined &&
            Design_Right_Social.right_socialItem_border_hov_color_left_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Right_Social.right_socialItem_border_hov_color_left_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0 }
        );
        setDB_LeftSidebar_MenuItem_SocialText_Hover_BorderColorRight(
          Design_Right_Social !== undefined &&
            Design_Right_Social.right_socialItem_border_hov_color_right !==
              undefined
            ? Design_Right_Social.right_socialItem_border_hov_color_right
            : "rgba(0,0,0,0)"
        );
        setDB_LeftSidebar_MenuItem_SocialText_Hover_BorderColorRightNotEncoded(
          Design_Right_Social !== undefined &&
            Design_Right_Social.right_socialItem_border_hov_color_right_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Right_Social.right_socialItem_border_hov_color_right_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0 }
        );
        setDB_LeftSidebar_MenuItem_SocialText_Hover_BorderColorTop(
          Design_Right_Social !== undefined &&
            Design_Right_Social.right_socialItem_border_hov_color_top !==
              undefined
            ? Design_Right_Social.right_socialItem_border_hov_color_top
            : "rgba(0,0,0,0)"
        );
        setDB_LeftSidebar_MenuItem_SocialText_Hover_BorderColorTopNotEncoded(
          Design_Right_Social !== undefined &&
            Design_Right_Social.right_socialItem_border_hov_color_top_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Right_Social.right_socialItem_border_hov_color_top_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0 }
        );
        setDB_LeftSidebar_MenuItem_SocialText_Hover_BorderColorBottom(
          Design_Right_Social !== undefined &&
            Design_Right_Social.right_socialItem_border_hov_color_bottom !==
              undefined
            ? Design_Right_Social.right_socialItem_border_hov_color_bottom
            : "rgba(0,0,0,0)"
        );
        setDB_LeftSidebar_MenuItem_SocialText_Hover_BorderColorBottomNotEncoded(
          Design_Right_Social !== undefined &&
            Design_Right_Social.right_socialItem_border_hov_color_bottom_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Right_Social.right_socialItem_border_hov_color_bottom_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0 }
        );

        setDB_LeftSidebar_MenuItem_SocialText_Hover_BorderLeft(
          Design_Right_Social !== undefined &&
            Design_Right_Social.right_socialItem_border_hov_pixel_left !==
              undefined
            ? Design_Right_Social.right_socialItem_border_hov_pixel_left
            : "0"
        );
        setDB_LeftSidebar_MenuItem_SocialText_Hover_BorderRight(
          Design_Right_Social !== undefined &&
            Design_Right_Social.right_socialItem_border_hov_pixel_right !==
              undefined
            ? Design_Right_Social.right_socialItem_border_hov_pixel_right
            : "0"
        );
        setDB_LeftSidebar_MenuItem_SocialText_Hover_BorderTop(
          Design_Right_Social !== undefined &&
            Design_Right_Social.right_socialItem_border_hov_pixel_top !==
              undefined
            ? Design_Right_Social.right_socialItem_border_hov_pixel_top
            : "0"
        );
        setDB_LeftSidebar_MenuItem_SocialText_Hover_BorderBottom(
          Design_Right_Social !== undefined &&
            Design_Right_Social.right_socialItem_border_hov_pixel_bottom !==
              undefined
            ? Design_Right_Social.right_socialItem_border_hov_pixel_bottom
            : "0"
        );

        setDB_LeftSidebar_MenuItem_SocialText_Hover_BorderTopLeftRadius(
          Design_Right_Social !== undefined &&
            Design_Right_Social.right_socialItem_border_hov_radius_top_left !==
              undefined
            ? Design_Right_Social.right_socialItem_border_hov_radius_top_left
            : "0"
        );
        setDB_LeftSidebar_MenuItem_SocialText_Hover_BorderTopRightRadius(
          Design_Right_Social !== undefined &&
            Design_Right_Social.right_socialItem_border_hov_radius_top_right !==
              undefined
            ? Design_Right_Social.right_socialItem_border_hov_radius_top_right
            : "0"
        );
        setDB_LeftSidebar_MenuItem_SocialText_Hover_BorderBottomLeftRadius(
          Design_Right_Social !== undefined &&
            Design_Right_Social.right_socialItem_border_hov_radius_bottom_left !==
              undefined
            ? Design_Right_Social.right_socialItem_border_hov_radius_bottom_left
            : "0"
        );
        setDB_LeftSidebar_MenuItem_SocialText_Hover_BorderBottomRightRadius(
          Design_Right_Social !== undefined &&
            Design_Right_Social.right_socialItem_border_hov_radius_bottom_right !==
              undefined
            ? Design_Right_Social.right_socialItem_border_hov_radius_bottom_right
            : "0"
        );

        setDB_LeftSidebar_MenuItem_SocialText_Hover_BorderStyleLeft(
          Design_Right_Social !== undefined &&
            Design_Right_Social.right_socialItem_border_hov_style_left !==
              undefined
            ? Design_Right_Social.right_socialItem_border_hov_style_left
            : "solid"
        );
        setDB_LeftSidebar_MenuItem_SocialText_Hover_BorderStyleRight(
          Design_Right_Social !== undefined &&
            Design_Right_Social.right_socialItem_border_hov_style_right !==
              undefined
            ? Design_Right_Social.right_socialItem_border_hov_style_right
            : "solid"
        );
        setDB_LeftSidebar_MenuItem_SocialText_Hover_BorderStyleTop(
          Design_Right_Social !== undefined &&
            Design_Right_Social.right_socialItem_border_hov_style_top !==
              undefined
            ? Design_Right_Social.right_socialItem_border_hov_style_top
            : "solid"
        );
        setDB_LeftSidebar_MenuItem_SocialText_Hover_BorderStyleBottom(
          Design_Right_Social !== undefined &&
            Design_Right_Social.right_socialItem_border_hov_style_bottom !==
              undefined
            ? Design_Right_Social.right_socialItem_border_hov_style_bottom
            : "solid"
        );
        setDB_LeftSidebar_MenuContainerFilterGG_BackgroundColor(
          Design_Right_Filter !== undefined &&
            Design_Right_Filter.right_filter_hintergrundfarbe !== undefined
            ? Design_Right_Filter.right_filter_hintergrundfarbe
            : "rgba(0,0,0,0.3)"
        );

        setDB_LeftSidebar_MenuContainerFilterGG_BackgroundColorNotEncoded(
          Design_Right_Filter !== undefined &&
            Design_Right_Filter.right_filter_hintergrundfarbe_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Right_Filter.right_filter_hintergrundfarbe_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0.3 }
        );

        setDB_LeftSidebar_MenuItem_MenuContainerFilterGG_ShadowColor(
          Design_Right_Filter !== undefined &&
            Design_Right_Filter.right_filter_schattenfarbe !== undefined
            ? Design_Right_Filter.right_filter_schattenfarbe
            : "rgba(0,0,0,0.4)"
        );
        setDB_LeftSidebar_MenuItem_MenuContainerFilterGG_ShadowColorNotEncoded(
          Design_Right_Filter !== undefined &&
            Design_Right_Filter.right_filter_schattenfarbe_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Right_Filter.right_filter_schattenfarbe_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0.4 }
        );

        setDB_LeftSidebar_MenuItem_MenuContainerFilterGG_ShadowHorizontal(
          Design_Right_Filter !== undefined &&
            Design_Right_Filter.right_filter_schattenposition_horizontal !==
              undefined
            ? Design_Right_Filter.right_filter_schattenposition_horizontal
            : "-4"
        );

        setDB_LeftSidebar_MenuItem_MenuContainerFilterGG_ShadowVertical(
          Design_Right_Filter !== undefined &&
            Design_Right_Filter.right_filter_schattenposition_vertikal !==
              undefined
            ? Design_Right_Filter.right_filter_schattenposition_vertikal
            : "4"
        );

        setDB_LeftSidebar_MenuItem_MenuContainerFilterGG_ShadowBlur(
          Design_Right_Filter !== undefined &&
            Design_Right_Filter.right_filter_schattenrauschen !== undefined
            ? Design_Right_Filter.right_filter_schattenrauschen
            : "5"
        );

        setDB_LeftSidebar_MenuItem_MenuContainerFilterGG_paddingLeft(
          Design_Right_Filter !== undefined &&
            Design_Right_Filter.right_filter_innerer_abstand_links !== undefined
            ? Design_Right_Filter.right_filter_innerer_abstand_links
            : "0"
        );

        setDB_LeftSidebar_MenuItem_MenuContainerFilterGG_paddingRight(
          Design_Right_Filter !== undefined &&
            Design_Right_Filter.right_filter_innerer_abstand_rechts !==
              undefined
            ? Design_Right_Filter.right_filter_innerer_abstand_rechts
            : "0"
        );

        setDB_LeftSidebar_MenuItem_MenuContainerFilterGG_paddingTop(
          Design_Right_Filter !== undefined &&
            Design_Right_Filter.right_filter_innerer_abstand_oben !== undefined
            ? Design_Right_Filter.right_filter_innerer_abstand_oben
            : "5"
        );
        setDB_LeftSidebar_MenuItem_MenuContainerFilterGG_paddingBottom(
          Design_Right_Filter !== undefined &&
            Design_Right_Filter.right_filter_innerer_abstand_unten !== undefined
            ? Design_Right_Filter.right_filter_innerer_abstand_unten
            : "5"
        );

        setDB_LeftSidebar_MenuContainerFilterGG_Hover_BackgroundColor(
          Design_Right_Filter !== undefined &&
            Design_Right_Filter.right_filter_hover_hintergrundfarbe !==
              undefined
            ? Design_Right_Filter.right_filter_hover_hintergrundfarbe
            : "rgba(0,0,0,0.3)"
        );

        setDB_LeftSidebar_MenuContainerFilterGG_Hover_BackgroundColorNotEncoded(
          Design_Right_Filter !== undefined &&
            Design_Right_Filter.right_filter_hover_hintergrundfarbe_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Right_Filter.right_filter_hover_hintergrundfarbe_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0.3 }
        );

        setDB_LeftSidebar_MenuItem_MenuContainerFilterGG_Hover_ShadowColor(
          Design_Right_Filter !== undefined &&
            Design_Right_Filter.right_filter_hover_schattenfarbe !== undefined
            ? Design_Right_Filter.right_filter_hover_schattenfarbe
            : "rgba(0,0,0,0.5)"
        );
        setDB_LeftSidebar_MenuItem_MenuContainerFilterGG_Hover_ShadowColorNotEncoded(
          Design_Right_Filter !== undefined &&
            Design_Right_Filter.right_filter_hover_schattenfarbe_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Right_Filter.right_filter_hover_schattenfarbe_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0.5 }
        );

        setDB_LeftSidebar_MenuItem_MenuContainerFilterGG_Hover_ShadowHorizontal(
          Design_Right_Filter !== undefined &&
            Design_Right_Filter.right_filter_hover_schattenposition_horizontal !==
              undefined
            ? Design_Right_Filter.right_filter_hover_schattenposition_horizontal
            : "-4"
        );

        setDB_LeftSidebar_MenuItem_MenuContainerFilterGG_Hover_ShadowVertical(
          Design_Right_Filter !== undefined &&
            Design_Right_Filter.right_filter_hover_schattenposition_vertikal !==
              undefined
            ? Design_Right_Filter.right_filter_hover_schattenposition_vertikal
            : "4"
        );

        setDB_LeftSidebar_MenuItem_MenuContainerFilterGG_Hover_ShadowBlur(
          Design_Right_Filter !== undefined &&
            Design_Right_Filter.right_filter_hover_schattenrauschen !==
              undefined
            ? Design_Right_Filter.right_filter_hover_schattenrauschen
            : "6"
        );

        setDB_LeftSidebar_MenuItem_MenuContainerFilterGG_Hover_paddingLeft(
          Design_Right_Filter !== undefined &&
            Design_Right_Filter.right_filter_hover_innerer_abstand_links !==
              undefined
            ? Design_Right_Filter.right_filter_hover_innerer_abstand_links
            : "0"
        );

        setDB_LeftSidebar_MenuItem_MenuContainerFilterGG_Hover_paddingRight(
          Design_Right_Filter !== undefined &&
            Design_Right_Filter.right_filter_hover_innerer_abstand_rechts !==
              undefined
            ? Design_Right_Filter.right_filter_hover_innerer_abstand_rechts
            : "0"
        );

        setDB_LeftSidebar_MenuItem_MenuContainerFilterGG_Hover_paddingTop(
          Design_Right_Filter !== undefined &&
            Design_Right_Filter.right_filter_hover_innerer_abstand_oben !==
              undefined
            ? Design_Right_Filter.right_filter_hover_innerer_abstand_oben
            : "5"
        );
        setDB_LeftSidebar_MenuItem_MenuContainerFilterGG_Hover_paddingBottom(
          Design_Right_Filter !== undefined &&
            Design_Right_Filter.right_filter_hover_innerer_abstand_unten !==
              undefined
            ? Design_Right_Filter.right_filter_hover_innerer_abstand_unten
            : "5"
        );

        setDB_LeftSidebar_MenuContainerFilterGG_BorderColorLeft(
          Design_Right_Filter !== undefined &&
            Design_Right_Filter.right_filter_border_color_left !== undefined
            ? Design_Right_Filter.right_filter_border_color_left
            : "rgba(0,0,0,0.3)"
        );
        setDB_LeftSidebar_MenuContainerFilterGG_BorderColorLeftNotEncoded(
          Design_Right_Filter !== undefined &&
            Design_Right_Filter.right_filter_border_color_left_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Right_Filter.right_filter_border_color_left_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0.3 }
        );
        setDB_LeftSidebar_MenuContainerFilterGG_BorderColorRight(
          Design_Right_Filter !== undefined &&
            Design_Right_Filter.right_filter_border_color_right !== undefined
            ? Design_Right_Filter.right_filter_border_color_right
            : "rgba(0,0,0,0.3)"
        );
        setDB_LeftSidebar_MenuContainerFilterGG_BorderColorRightNotEncoded(
          Design_Right_Filter !== undefined &&
            Design_Right_Filter.right_filter_border_color_right_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Right_Filter.right_filter_border_color_right_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0.3 }
        );
        setDB_LeftSidebar_MenuContainerFilterGG_BorderColorTop(
          Design_Right_Filter !== undefined &&
            Design_Right_Filter.right_filter_border_color_top !== undefined
            ? Design_Right_Filter.right_filter_border_color_top
            : "rgba(0,0,0,0.3)"
        );
        setDB_LeftSidebar_MenuContainerFilterGG_BorderColorTopNotEncoded(
          Design_Right_Filter !== undefined &&
            Design_Right_Filter.right_filter_border_color_top_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Right_Filter.right_filter_border_color_top_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0.3 }
        );
        setDB_LeftSidebar_MenuContainerFilterGG_BorderColorBottom(
          Design_Right_Filter !== undefined &&
            Design_Right_Filter.right_filter_border_color_bottom !== undefined
            ? Design_Right_Filter.right_filter_border_color_bottom
            : "rgba(0,0,0,0.3)"
        );
        setDB_LeftSidebar_MenuContainerFilterGG_BorderColorBottomNotEncoded(
          Design_Right_Filter !== undefined &&
            Design_Right_Filter.right_filter_border_color_bottom_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Right_Filter.right_filter_border_color_bottom_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0.3 }
        );

        setDB_LeftSidebar_MenuContainerFilterGG_BorderLeft(
          Design_Right_Filter !== undefined &&
            Design_Right_Filter.right_filter_border_pixel_left !== undefined
            ? Design_Right_Filter.right_filter_border_pixel_left
            : "1"
        );
        setDB_LeftSidebar_MenuContainerFilterGG_BorderRight(
          Design_Right_Filter !== undefined &&
            Design_Right_Filter.right_filter_border_pixel_right !== undefined
            ? Design_Right_Filter.right_filter_border_pixel_right
            : "1"
        );
        setDB_LeftSidebar_MenuContainerFilterGG_BorderTop(
          Design_Right_Filter !== undefined &&
            Design_Right_Filter.right_filter_border_pixel_top !== undefined
            ? Design_Right_Filter.right_filter_border_pixel_top
            : "1"
        );
        setDB_LeftSidebar_MenuContainerFilterGG_BorderBottom(
          Design_Right_Filter !== undefined &&
            Design_Right_Filter.right_filter_border_pixel_bottom !== undefined
            ? Design_Right_Filter.right_filter_border_pixel_bottom
            : "1"
        );

        setDB_LeftSidebar_MenuItem_MenuContainerFilterGG_BorderTopLeftRadius(
          Design_Right_Filter !== undefined &&
            Design_Right_Filter.right_filter_border_radius_top_left !==
              undefined
            ? Design_Right_Filter.right_filter_border_radius_top_left
            : "7"
        );
        setDB_LeftSidebar_MenuItem_MenuContainerFilterGG_BorderTopRightRadius(
          Design_Right_Filter !== undefined &&
            Design_Right_Filter.right_filter_border_radius_top_right !==
              undefined
            ? Design_Right_Filter.right_filter_border_radius_top_right
            : "7"
        );
        setDB_LeftSidebar_MenuItem_MenuContainerFilterGG_BorderBottomLeftRadius(
          Design_Right_Filter !== undefined &&
            Design_Right_Filter.right_filter_border_radius_bottom_left !==
              undefined
            ? Design_Right_Filter.right_filter_border_radius_bottom_left
            : "7"
        );
        setDB_LeftSidebar_MenuItem_MenuContainerFilterGG_BorderBottomRightRadius(
          Design_Right_Filter !== undefined &&
            Design_Right_Filter.right_filter_border_radius_bottom_right !==
              undefined
            ? Design_Right_Filter.right_filter_border_radius_bottom_right
            : "7"
        );

        setDB_LeftSidebar_MenuContainerFilterGG_BorderStyleLeft(
          Design_Right_Filter !== undefined &&
            Design_Right_Filter.right_filter_border_style_left !== undefined
            ? Design_Right_Filter.right_filter_border_style_left
            : "solid"
        );
        setDB_LeftSidebar_MenuContainerFilterGG_BorderStyleRight(
          Design_Right_Filter !== undefined &&
            Design_Right_Filter.right_filter_border_style_right !== undefined
            ? Design_Right_Filter.right_filter_border_style_right
            : "solid"
        );
        setDB_LeftSidebar_MenuContainerFilterGG_BorderStyleTop(
          Design_Right_Filter !== undefined &&
            Design_Right_Filter.right_filter_border_style_top !== undefined
            ? Design_Right_Filter.right_filter_border_style_top
            : "solid"
        );
        setDB_LeftSidebar_MenuContainerFilterGG_BorderStyleBottom(
          Design_Right_Filter !== undefined &&
            Design_Right_Filter.right_filter_border_style_bottom !== undefined
            ? Design_Right_Filter.right_filter_border_style_bottom
            : "solid"
        );

        setDB_LeftSidebar_MenuContainerFilterGG_Hover_BorderColorLeft(
          Design_Right_Filter !== undefined &&
            Design_Right_Filter.right_filter_border_hov_color_left !== undefined
            ? Design_Right_Filter.right_filter_border_hov_color_left
            : "rgba(0,0,150,0.3)"
        );
        setDB_LeftSidebar_MenuContainerFilterGG_Hover_BorderColorLeftNotEncoded(
          Design_Right_Filter !== undefined &&
            Design_Right_Filter.right_filter_border_hov_color_left_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Right_Filter.right_filter_border_hov_color_left_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0.3 }
        );
        setDB_LeftSidebar_MenuContainerFilterGG_Hover_BorderColorRight(
          Design_Right_Filter !== undefined &&
            Design_Right_Filter.right_filter_border_hov_color_right !==
              undefined
            ? Design_Right_Filter.right_filter_border_hov_color_right
            : "rgba(0,0,0,0.3)"
        );
        setDB_LeftSidebar_MenuContainerFilterGG_Hover_BorderColorRightNotEncoded(
          Design_Right_Filter !== undefined &&
            Design_Right_Filter.right_filter_border_hov_color_right_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Right_Filter.right_filter_border_hov_color_right_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0.3 }
        );
        setDB_LeftSidebar_MenuContainerFilterGG_Hover_BorderColorTop(
          Design_Right_Filter !== undefined &&
            Design_Right_Filter.right_filter_border_hov_color_top !== undefined
            ? Design_Right_Filter.right_filter_border_hov_color_top
            : "rgba(0,0,150,0.3)"
        );
        setDB_LeftSidebar_MenuContainerFilterGG_Hover_BorderColorTopNotEncoded(
          Design_Right_Filter !== undefined &&
            Design_Right_Filter.right_filter_border_hov_color_top_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Right_Filter.right_filter_border_hov_color_top_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0.3 }
        );
        setDB_LeftSidebar_MenuContainerFilterGG_Hover_BorderColorBottom(
          Design_Right_Filter !== undefined &&
            Design_Right_Filter.right_filter_border_hov_color_bottom !==
              undefined
            ? Design_Right_Filter.right_filter_border_hov_color_bottom
            : "rgba(0,0,0,0.3)"
        );
        setDB_LeftSidebar_MenuContainerFilterGG_Hover_BorderColorBottomNotEncoded(
          Design_Right_Filter !== undefined &&
            Design_Right_Filter.right_filter_border_hov_color_bottom_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Right_Filter.right_filter_border_hov_color_bottom_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0.3 }
        );

        setDB_LeftSidebar_MenuContainerFilterGG_Hover_BorderLeft(
          Design_Right_Filter !== undefined &&
            Design_Right_Filter.right_filter_border_hov_pixel_left !== undefined
            ? Design_Right_Filter.right_filter_border_hov_pixel_left
            : "1"
        );
        setDB_LeftSidebar_MenuContainerFilterGG_Hover_BorderRight(
          Design_Right_Filter !== undefined &&
            Design_Right_Filter.right_filter_border_hov_pixel_right !==
              undefined
            ? Design_Right_Filter.right_filter_border_hov_pixel_right
            : "1"
        );
        setDB_LeftSidebar_MenuContainerFilterGG_Hover_BorderTop(
          Design_Right_Filter !== undefined &&
            Design_Right_Filter.right_filter_border_hov_pixel_top !== undefined
            ? Design_Right_Filter.right_filter_border_hov_pixel_top
            : "1"
        );
        setDB_LeftSidebar_MenuContainerFilterGG_Hover_BorderBottom(
          Design_Right_Filter !== undefined &&
            Design_Right_Filter.right_filter_border_hov_pixel_bottom !==
              undefined
            ? Design_Right_Filter.right_filter_border_hov_pixel_bottom
            : "1"
        );

        setDB_LeftSidebar_MenuItem_MenuContainerFilterGG_Hover_BorderTopLeftRadius(
          Design_Right_Filter !== undefined &&
            Design_Right_Filter.right_filter_border_hov_radius_top_left !==
              undefined
            ? Design_Right_Filter.right_filter_border_hov_radius_top_left
            : "7"
        );
        setDB_LeftSidebar_MenuItem_MenuContainerFilterGG_Hover_BorderTopRightRadius(
          Design_Right_Filter !== undefined &&
            Design_Right_Filter.right_filter_border_hov_radius_top_right !==
              undefined
            ? Design_Right_Filter.right_filter_border_hov_radius_top_right
            : "7"
        );
        setDB_LeftSidebar_MenuItem_MenuContainerFilterGG_Hover_BorderBottomLeftRadius(
          Design_Right_Filter !== undefined &&
            Design_Right_Filter.right_filter_border_hov_radius_bottom_left !==
              undefined
            ? Design_Right_Filter.right_filter_border_hov_radius_bottom_left
            : "7"
        );
        setDB_LeftSidebar_MenuItem_MenuContainerFilterGG_Hover_BorderBottomRightRadius(
          Design_Right_Filter !== undefined &&
            Design_Right_Filter.right_filter_border_hov_radius_bottom_right !==
              undefined
            ? Design_Right_Filter.right_filter_border_hov_radius_bottom_right
            : "7"
        );

        setDB_LeftSidebar_MenuContainerFilterGG_Hover_BorderStyleLeft(
          Design_Right_Filter !== undefined &&
            Design_Right_Filter.right_filter_border_hov_style_left !== undefined
            ? Design_Right_Filter.right_filter_border_hov_style_left
            : "solid"
        );
        setDB_LeftSidebar_MenuContainerFilterGG_Hover_BorderStyleRight(
          Design_Right_Filter !== undefined &&
            Design_Right_Filter.right_filter_border_hov_style_right !==
              undefined
            ? Design_Right_Filter.right_filter_border_hov_style_right
            : "solid"
        );
        setDB_LeftSidebar_MenuContainerFilterGG_Hover_BorderStyleTop(
          Design_Right_Filter !== undefined &&
            Design_Right_Filter.right_filter_border_hov_style_top !== undefined
            ? Design_Right_Filter.right_filter_border_hov_style_top
            : "solid"
        );
        setDB_LeftSidebar_MenuContainerFilterGG_Hover_BorderStyleBottom(
          Design_Right_Filter !== undefined &&
            Design_Right_Filter.right_filter_border_hov_style_bottom !==
              undefined
            ? Design_Right_Filter.right_filter_border_hov_style_bottom
            : "solid"
        );

        setDB_LeftSidebar_MenuItem_AlleFilterButtonFF_FontColor(
          Design_Right_Filter !== undefined &&
            Design_Right_Filter.right_nav_allFilter_schriftfarbe !== undefined
            ? Design_Right_Filter.right_nav_allFilter_schriftfarbe
            : "rgba(255,255,255,1)"
        );

        setDB_LeftSidebar_MenuItem_AlleFilterButtonFF_FontColorNotEncoded(
          Design_Right_Filter !== undefined &&
            Design_Right_Filter.right_nav_allFilter_schriftfarbe_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Right_Filter.right_nav_allFilter_schriftfarbe_not_encoded
              )
            : { r: 255, g: 255, b: 255, a: 1 }
        );

        setDB_LeftSidebar_MenuItem_AlleFilterButtonFF_BackgroundColor(
          Design_Right_Filter !== undefined &&
            Design_Right_Filter.right_nav_allFilter_hintergrundfarbe !==
              undefined
            ? Design_Right_Filter.right_nav_allFilter_hintergrundfarbe
            : "rgba(0,0,0,0.4)"
        );
        setDB_LeftSidebar_MenuItem_AlleFilterButtonFF_BackgroundColorNotEncoded(
          Design_Right_Filter !== undefined &&
            Design_Right_Filter.right_nav_allFilter_hintergrundfarbe_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Right_Filter.right_nav_allFilter_hintergrundfarbe_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0.4 }
        );

        setDB_LeftSidebar_MenuItem_AlleFilterButtonFF_ShadowColor(
          Design_Right_Filter !== undefined &&
            Design_Right_Filter.right_nav_allFilter_schattenfarbe !== undefined
            ? Design_Right_Filter.right_nav_allFilter_schattenfarbe
            : "rgba(0,0,0,1)"
        );

        setDB_LeftSidebar_MenuItem_AlleFilterButtonFF_ShadowColorNotEncoded(
          Design_Right_Filter !== undefined &&
            Design_Right_Filter.right_nav_allFilter_schattenfarbe_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Right_Filter.right_nav_allFilter_schattenfarbe_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 1 }
        );

        setDB_LeftSidebar_MenuItem_AlleFilterButtonFF_ShadowHorizontal(
          Design_Right_Filter !== undefined &&
            Design_Right_Filter.right_nav_allFilter_schattenposition_horizontal !==
              undefined
            ? Design_Right_Filter.right_nav_allFilter_schattenposition_horizontal
            : "1"
        );

        setDB_LeftSidebar_MenuItem_AlleFilterButtonFF_ShadowVertical(
          Design_Right_Filter !== undefined &&
            Design_Right_Filter.right_nav_allFilter_schattenposition_vertikal !==
              undefined
            ? Design_Right_Filter.right_nav_allFilter_schattenposition_vertikal
            : "1"
        );

        setDB_LeftSidebar_MenuItem_AlleFilterButtonFF_ShadowBlur(
          Design_Right_Filter !== undefined &&
            Design_Right_Filter.right_nav_allFilter_schattenrauschen !==
              undefined
            ? Design_Right_Filter.right_nav_allFilter_schattenrauschen
            : "3"
        );

        setDB_LeftSidebar_MenuItem_AlleFilterButtonFF_FontSize(
          Design_Right_Filter !== undefined &&
            Design_Right_Filter.right_nav_allFilter_schriftgroesse !== undefined
            ? Design_Right_Filter.right_nav_allFilter_schriftgroesse
            : "19"
        );

        setDB_LeftSidebar_MenuItem_AlleFilterButtonFF_FontWeight(
          Design_Right_Filter !== undefined &&
            Design_Right_Filter.right_nav_allFilter_schriftgewicht !== undefined
            ? Design_Right_Filter.right_nav_allFilter_schriftgewicht
            : "100"
        );

        setDB_LeftSidebar_MenuItem_AlleFilterButtonFF_letterSpacing(
          Design_Right_Filter !== undefined &&
            Design_Right_Filter.right_nav_allFilter_buchstabenabstand !==
              undefined
            ? Design_Right_Filter.right_nav_allFilter_buchstabenabstand
            : "0"
        );

        setDB_LeftSidebar_MenuItem_AlleFilterButtonFF_TextDecoration(
          Design_Right_Filter !== undefined &&
            Design_Right_Filter.right_nav_allFilter_text_dekoration !==
              undefined
            ? Design_Right_Filter.right_nav_allFilter_text_dekoration
            : "none"
        );

        setDB_LeftSidebar_MenuItem_AlleFilterButtonFF_TextDecorationStyle(
          Design_Right_Filter !== undefined &&
            Design_Right_Filter.right_nav_allFilter_text_dekoration_stil !==
              undefined
            ? Design_Right_Filter.right_nav_allFilter_text_dekoration_stil
            : "none"
        );

        setDB_LeftSidebar_MenuItem_AlleFilterButtonFF_marginLeftRight(
          Design_Right_Filter !== undefined &&
            Design_Right_Filter.right_nav_allFilter_aussen_abstand_links_rechts !==
              undefined
            ? Design_Right_Filter.right_nav_allFilter_aussen_abstand_links_rechts
            : "0"
        );

        setDB_LeftSidebar_MenuItem_AlleFilterButtonFF_marginTopBottom(
          Design_Right_Filter !== undefined &&
            Design_Right_Filter.top_navigation_static_aussen_abstand_oben_unten !==
              undefined
            ? Design_Right_Filter.top_navigation_static_aussen_abstand_oben_unten
            : "0"
        );

        setDB_LeftSidebar_MenuItem_AlleFilterButtonFF_paddingLeftRight(
          Design_Right_Filter !== undefined &&
            Design_Right_Filter.right_nav_allFilter_innerer_abstand_links_rechts !==
              undefined
            ? Design_Right_Filter.right_nav_allFilter_innerer_abstand_links_rechts
            : "0"
        );

        setDB_LeftSidebar_MenuItem_AlleFilterButtonFF_paddingTopBottom(
          Design_Right_Filter !== undefined &&
            Design_Right_Filter.right_nav_allFilter_innerer_abstand_oben_unten !==
              undefined
            ? Design_Right_Filter.right_nav_allFilter_innerer_abstand_oben_unten
            : "0"
        );

        setDB_LeftSidebar_MenuItem_AlleFilterButtonFF_TextAlign(
          Design_Right_Filter !== undefined &&
            Design_Right_Filter.right_nav_allFilter_textausrichtung !==
              undefined
            ? Design_Right_Filter.right_nav_allFilter_textausrichtung
            : "center"
        );

        setDB_fontFace_AlleFilterButtonFontFamily_FontFamilyName(
          Design_Right_Filter !== undefined &&
            Design_Right_Filter.right_nav_allFilter_fontfamilyname !== undefined
            ? Design_Right_Filter.right_nav_allFilter_fontfamilyname
            : "Lato"
        );

        setDB_fontFace_AlleFilterButtonFontFamily_FontFamilySrc(
          Design_Right_Filter !== undefined &&
            Design_Right_Filter.right_nav_allFilter_fontfamilysrc !== undefined
            ? Design_Right_Filter.right_nav_allFilter_fontfamilysrc
            : "/assets/Lato/Lato-Regular.ttf"
        );

        setDB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_FontColor(
          Design_Right_Filter !== undefined &&
            Design_Right_Filter.right_nav_allFilter_hov_schriftfarbe !==
              undefined
            ? Design_Right_Filter.right_nav_allFilter_hov_schriftfarbe
            : "rgba(255,255,255,1)"
        );

        setDB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_FontColorNotEncoded(
          Design_Right_Filter !== undefined &&
            Design_Right_Filter.right_nav_allFilter_hov_schriftfarbe_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Right_Filter.right_nav_allFilter_hov_schriftfarbe_not_encoded
              )
            : { r: 255, g: 255, b: 255, a: 1 }
        );

        setDB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BackgroundColor(
          Design_Right_Filter !== undefined &&
            Design_Right_Filter.right_nav_allFilter_hov_hintergrundfarbe !==
              undefined
            ? Design_Right_Filter.right_nav_allFilter_hov_hintergrundfarbe
            : "rgba(0,0,0,0.5)"
        );
        setDB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BackgroundColorNotEncoded(
          Design_Right_Filter !== undefined &&
            Design_Right_Filter.right_nav_allFilter_hov_hintergrundfarbe_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Right_Filter.right_nav_allFilter_hov_hintergrundfarbe_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0.5 }
        );

        setDB_LeftSidebar_MenuItem_AlleFilterButtonFF_ShadowColor_Hover(
          Design_Right_Filter !== undefined &&
            Design_Right_Filter.right_nav_allFilter_hov_schattenfarbe !==
              undefined
            ? Design_Right_Filter.right_nav_allFilter_hov_schattenfarbe
            : "rgba(0,0,0,1)"
        );
        setDB_LeftSidebar_MenuItem_AlleFilterButtonFF_ShadowColorNotEncoded_Hover(
          Design_Right_Filter !== undefined &&
            Design_Right_Filter.right_nav_allFilter_hov_schattenfarbe_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Right_Filter.right_nav_allFilter_hov_schattenfarbe_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 1 }
        );

        setDB_LeftSidebar_MenuItem_AlleFilterButtonFF_ShadowHorizontal_Hover(
          Design_Right_Filter !== undefined &&
            Design_Right_Filter.right_nav_allFilter_hov_schattenposition_horizontal !==
              undefined
            ? Design_Right_Filter.right_nav_allFilter_hov_schattenposition_horizontal
            : "1"
        );

        setDB_LeftSidebar_MenuItem_AlleFilterButtonFF_ShadowVertical_Hover(
          Design_Right_Filter !== undefined &&
            Design_Right_Filter.right_nav_allFilter_hov_schattenposition_vertikal !==
              undefined
            ? Design_Right_Filter.right_nav_allFilter_hov_schattenposition_vertikal
            : "1"
        );

        setDB_LeftSidebar_MenuItem_AlleFilterButtonFF_ShadowBlur_Hover(
          Design_Right_Filter !== undefined &&
            Design_Right_Filter.right_nav_allFilter_hov_schattenrauschen !==
              undefined
            ? Design_Right_Filter.right_nav_allFilter_hov_schattenrauschen
            : "3"
        );

        setDB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_FontSize(
          Design_Right_Filter !== undefined &&
            Design_Right_Filter.right_nav_allFilter_hov_schriftgroesse !==
              undefined
            ? Design_Right_Filter.right_nav_allFilter_hov_schriftgroesse
            : "19"
        );

        setDB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_FontWeight(
          Design_Right_Filter !== undefined &&
            Design_Right_Filter.right_nav_allFilter_hov_schriftgewicht !==
              undefined
            ? Design_Right_Filter.right_nav_allFilter_hov_schriftgewicht
            : "100"
        );

        setDB_LeftSidebar_MenuItem_AlleFilterButtonFF_letterSpacing_Hover(
          Design_Right_Filter !== undefined &&
            Design_Right_Filter.right_nav_allFilter_hov_buchstabenabstand !==
              undefined
            ? Design_Right_Filter.right_nav_allFilter_hov_buchstabenabstand
            : "0"
        );

        setDB_LeftSidebar_MenuItem_AlleFilterButtonFF_TextDecoration_Hover(
          Design_Right_Filter !== undefined &&
            Design_Right_Filter.right_nav_allFilter_hov_text_dekoration !==
              undefined
            ? Design_Right_Filter.right_nav_allFilter_hov_text_dekoration
            : "none"
        );

        setDB_LeftSidebar_MenuItem_AlleFilterButtonFF_TextDecorationStyle_Hover(
          Design_Right_Filter !== undefined &&
            Design_Right_Filter.right_nav_allFilter_hov_text_dekoration_stil !==
              undefined
            ? Design_Right_Filter.right_nav_allFilter_hov_text_dekoration_stil
            : "none"
        );

        setDB_LeftSidebar_MenuItem_AlleFilterButtonFF_marginLeftRight_Hover(
          Design_Right_Filter !== undefined &&
            Design_Right_Filter.right_nav_allFilter_hov_aussen_abstand_links_rechts !==
              undefined
            ? Design_Right_Filter.right_nav_allFilter_hov_aussen_abstand_links_rechts
            : "0"
        );

        setDB_LeftSidebar_MenuItem_AlleFilterButtonFF_marginTopBottom_Hover(
          Design_Right_Filter !== undefined &&
            Design_Right_Filter.right_nav_allFilter_hov_aussen_abstand_oben_unten !==
              undefined
            ? Design_Right_Filter.right_nav_allFilter_hov_aussen_abstand_oben_unten
            : "0"
        );

        setDB_LeftSidebar_MenuItem_AlleFilterButtonFF_paddingLeftRight_Hover(
          Design_Right_Filter !== undefined &&
            Design_Right_Filter.right_nav_allFilter_hov_innerer_abstand_links_rechts !==
              undefined
            ? Design_Right_Filter.right_nav_allFilter_hov_innerer_abstand_links_rechts
            : "0"
        );

        setDB_LeftSidebar_MenuItem_AlleFilterButtonFF_paddingTopBottom_Hover(
          Design_Right_Filter !== undefined &&
            Design_Right_Filter.right_nav_allFilter_hov_innerer_abstand_oben_unten !==
              undefined
            ? Design_Right_Filter.right_nav_allFilter_hov_innerer_abstand_oben_unten
            : "0"
        );

        setDB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_TextAlign(
          Design_Right_Filter !== undefined &&
            Design_Right_Filter.right_nav_allFilter_hov_textausrichtung !==
              undefined
            ? Design_Right_Filter.right_nav_allFilter_hov_textausrichtung
            : "center"
        );

        setDB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderColorLeft(
          Design_Right_Filter !== undefined &&
            Design_Right_Filter.right_nav_allFilter_border_color_left !==
              undefined
            ? Design_Right_Filter.right_nav_allFilter_border_color_left
            : "rgba(0,0,0,0)"
        );
        setDB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderColorLeftNotEncoded(
          Design_Right_Filter !== undefined &&
            Design_Right_Filter.right_nav_allFilter_border_color_left_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Right_Filter.right_nav_allFilter_border_color_left_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0 }
        );
        setDB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderColorRight(
          Design_Right_Filter !== undefined &&
            Design_Right_Filter.right_nav_allFilter_border_color_right !==
              undefined
            ? Design_Right_Filter.right_nav_allFilter_border_color_right
            : "rgba(0,0,0,0)"
        );
        setDB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderColorRightNotEncoded(
          Design_Right_Filter !== undefined &&
            Design_Right_Filter.right_nav_allFilter_border_color_right_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Right_Filter.right_nav_allFilter_border_color_right_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0 }
        );
        setDB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderColorTop(
          Design_Right_Filter !== undefined &&
            Design_Right_Filter.right_nav_allFilter_border_color_top !==
              undefined
            ? Design_Right_Filter.right_nav_allFilter_border_color_top
            : "rgba(255,255,255,1)"
        );
        setDB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderColorTopNotEncoded(
          Design_Right_Filter !== undefined &&
            Design_Right_Filter.right_nav_allFilter_border_color_top_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Right_Filter.right_nav_allFilter_border_color_top_not_encoded
              )
            : { r: 255, g: 255, b: 255, a: 1 }
        );
        setDB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderColorBottom(
          Design_Right_Filter !== undefined &&
            Design_Right_Filter.right_nav_allFilter_border_color_bottom !==
              undefined
            ? Design_Right_Filter.right_nav_allFilter_border_color_bottom
            : "rgba(255,255,255,1)"
        );
        setDB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderColorBottomNotEncoded(
          Design_Right_Filter !== undefined &&
            Design_Right_Filter.right_nav_allFilter_border_color_bottom_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Right_Filter.right_nav_allFilter_border_color_bottom_not_encoded
              )
            : { r: 255, g: 255, b: 255, a: 1 }
        );

        setDB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderLeft(
          Design_Right_Filter !== undefined &&
            Design_Right_Filter.right_nav_allFilter_border_pixel_left !==
              undefined
            ? Design_Right_Filter.right_nav_allFilter_border_pixel_left
            : "0"
        );
        setDB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderRight(
          Design_Right_Filter !== undefined &&
            Design_Right_Filter.right_nav_allFilter_border_pixel_right !==
              undefined
            ? Design_Right_Filter.right_nav_allFilter_border_pixel_right
            : "0"
        );
        setDB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderTop(
          Design_Right_Filter !== undefined &&
            Design_Right_Filter.right_nav_allFilter_border_pixel_top !==
              undefined
            ? Design_Right_Filter.right_nav_allFilter_border_pixel_top
            : "2"
        );
        setDB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderBottom(
          Design_Right_Filter !== undefined &&
            Design_Right_Filter.right_nav_allFilter_border_pixel_bottom !==
              undefined
            ? Design_Right_Filter.right_nav_allFilter_border_pixel_bottom
            : "2"
        );

        setDB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderTopLeftRadius(
          Design_Right_Filter !== undefined &&
            Design_Right_Filter.right_nav_allFilter_border_radius_top_left !==
              undefined
            ? Design_Right_Filter.right_nav_allFilter_border_radius_top_left
            : "0"
        );
        setDB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderTopRightRadius(
          Design_Right_Filter !== undefined &&
            Design_Right_Filter.right_nav_allFilter_border_radius_top_right !==
              undefined
            ? Design_Right_Filter.right_nav_allFilter_border_radius_top_right
            : "0"
        );
        setDB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderBottomLeftRadius(
          Design_Right_Filter !== undefined &&
            Design_Right_Filter.right_nav_allFilter_border_radius_bottom_left !==
              undefined
            ? Design_Right_Filter.right_nav_allFilter_border_radius_bottom_left
            : "0"
        );
        setDB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderBottomRightRadius(
          Design_Right_Filter !== undefined &&
            Design_Right_Filter.right_nav_allFilter_border_radius_bottom_right !==
              undefined
            ? Design_Right_Filter.right_nav_allFilter_border_radius_bottom_right
            : "0"
        );

        setDB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderStyleLeft(
          Design_Right_Filter !== undefined &&
            Design_Right_Filter.right_nav_allFilter_border_style_left !==
              undefined
            ? Design_Right_Filter.right_nav_allFilter_border_style_left
            : "solid"
        );
        setDB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderStyleRight(
          Design_Right_Filter !== undefined &&
            Design_Right_Filter.right_nav_allFilter_border_style_right !==
              undefined
            ? Design_Right_Filter.right_nav_allFilter_border_style_right
            : "solid"
        );
        setDB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderStyleTop(
          Design_Right_Filter !== undefined &&
            Design_Right_Filter.right_nav_allFilter_border_style_top !==
              undefined
            ? Design_Right_Filter.right_nav_allFilter_border_style_top
            : "solid"
        );
        setDB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderStyleBottom(
          Design_Right_Filter !== undefined &&
            Design_Right_Filter.right_nav_allFilter_border_style_bottom !==
              undefined
            ? Design_Right_Filter.right_nav_allFilter_border_style_bottom
            : "solid"
        );

        setDB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderColorLeft(
          Design_Right_Filter !== undefined &&
            Design_Right_Filter.right_nav_allFilter_hov_border_color_left !==
              undefined
            ? Design_Right_Filter.right_nav_allFilter_hov_border_color_left
            : "rgba(0,0,0,0)"
        );
        setDB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderColorLeftNotEncoded(
          Design_Right_Filter !== undefined &&
            Design_Right_Filter.right_nav_allFilter_hov_border_color_left_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Right_Filter.right_nav_allFilter_hov_border_color_left_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0 }
        );
        setDB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderColorRight(
          Design_Right_Filter !== undefined &&
            Design_Right_Filter.right_nav_allFilter_hov_border_color_right !==
              undefined
            ? Design_Right_Filter.right_nav_allFilter_hov_border_color_right
            : "rgba(0,0,0,0)"
        );
        setDB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderColorRightNotEncoded(
          Design_Right_Filter !== undefined &&
            Design_Right_Filter.right_nav_allFilter_hov_border_color_right_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Right_Filter.right_nav_allFilter_hov_border_color_right_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0 }
        );
        setDB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderColorTop(
          Design_Right_Filter !== undefined &&
            Design_Right_Filter.right_nav_allFilter_hov_border_color_top !==
              undefined
            ? Design_Right_Filter.right_nav_allFilter_hov_border_color_top
            : "rgba(255,255,255,1)"
        );
        setDB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderColorTopNotEncoded(
          Design_Right_Filter !== undefined &&
            Design_Right_Filter.right_nav_allFilter_hov_border_color_top_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Right_Filter.right_nav_allFilter_hov_border_color_top_not_encoded
              )
            : { r: 255, g: 255, b: 255, a: 1 }
        );
        setDB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderColorBottom(
          Design_Right_Filter !== undefined &&
            Design_Right_Filter.right_nav_allFilter_hov_border_color_bottom !==
              undefined
            ? Design_Right_Filter.right_nav_allFilter_hov_border_color_bottom
            : "rgba(255,255,255,1)"
        );
        setDB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderColorBottomNotEncoded(
          Design_Right_Filter !== undefined &&
            Design_Right_Filter.right_nav_allFilter_hov_border_color_bottom_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Right_Filter.right_nav_allFilter_hov_border_color_bottom_not_encoded
              )
            : { r: 255, g: 255, b: 255, a: 1 }
        );

        setDB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderLeft(
          Design_Right_Filter !== undefined &&
            Design_Right_Filter.right_nav_allFilter_hov_border_pixel_left !==
              undefined
            ? Design_Right_Filter.right_nav_allFilter_hov_border_pixel_left
            : "0"
        );
        setDB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderRight(
          Design_Right_Filter !== undefined &&
            Design_Right_Filter.right_nav_allFilter_hov_border_pixel_right !==
              undefined
            ? Design_Right_Filter.right_nav_allFilter_hov_border_pixel_right
            : "0"
        );
        setDB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderTop(
          Design_Right_Filter !== undefined &&
            Design_Right_Filter.right_nav_allFilter_hov_border_pixel_top !==
              undefined
            ? Design_Right_Filter.right_nav_allFilter_hov_border_pixel_top
            : "2"
        );
        setDB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderBottom(
          Design_Right_Filter !== undefined &&
            Design_Right_Filter.right_nav_allFilter_hov_border_pixel_bottom !==
              undefined
            ? Design_Right_Filter.right_nav_allFilter_hov_border_pixel_bottom
            : "2"
        );

        setDB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderTopLeftRadius(
          Design_Right_Filter !== undefined &&
            Design_Right_Filter.right_nav_allFilter_hov_border_radius_top_left !==
              undefined
            ? Design_Right_Filter.right_nav_allFilter_hov_border_radius_top_left
            : "0"
        );
        setDB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderTopRightRadius(
          Design_Right_Filter !== undefined &&
            Design_Right_Filter.right_nav_allFilter_hov_border_radius_top_right !==
              undefined
            ? Design_Right_Filter.right_nav_allFilter_hov_border_radius_top_right
            : "0"
        );
        setDB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderBottomLeftRadius(
          Design_Right_Filter !== undefined &&
            Design_Right_Filter.right_nav_allFilter_hov_border_radius_bottom_left !==
              undefined
            ? Design_Right_Filter.right_nav_allFilter_hov_border_radius_bottom_left
            : "0"
        );
        setDB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderBottomRightRadius(
          Design_Right_Filter !== undefined &&
            Design_Right_Filter.right_nav_allFilter_hov_border_radius_bottom_right !==
              undefined
            ? Design_Right_Filter.right_nav_allFilter_hov_border_radius_bottom_right
            : "0"
        );

        setDB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderStyleLeft(
          Design_Right_Filter !== undefined &&
            Design_Right_Filter.right_nav_allFilter_hov_border_style_left !==
              undefined
            ? Design_Right_Filter.right_nav_allFilter_hov_border_style_left
            : "solid"
        );
        setDB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderStyleRight(
          Design_Right_Filter !== undefined &&
            Design_Right_Filter.right_nav_allFilter_hov_border_style_right !==
              undefined
            ? Design_Right_Filter.right_nav_allFilter_hov_border_style_right
            : "solid"
        );
        setDB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderStyleTop(
          Design_Right_Filter !== undefined &&
            Design_Right_Filter.right_nav_allFilter_hov_border_style_top !==
              undefined
            ? Design_Right_Filter.right_nav_allFilter_hov_border_style_top
            : "solid"
        );
        setDB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderStyleBottom(
          Design_Right_Filter !== undefined &&
            Design_Right_Filter.right_nav_allFilter_hov_border_style_bottom !==
              undefined
            ? Design_Right_Filter.right_nav_allFilter_hov_border_style_bottom
            : "solid"
        );
        setDB_LeftSidebar_MenuItem_FilterHauptTitel_FontColor(
          Design_Right_FilterTitleItem !== undefined &&
            Design_Right_FilterTitleItem.right_filtTitle_schriftfarbe !==
              undefined
            ? Design_Right_FilterTitleItem.right_filtTitle_schriftfarbe
            : "rgba(255,255,255,1)"
        );
        setDB_LeftSidebar_MenuItem_FilterHauptTitel_FontColorNotEncoded(
          Design_Right_FilterTitleItem !== undefined &&
            Design_Right_FilterTitleItem.right_filtTitle_schriftfarbe_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Right_FilterTitleItem.right_filtTitle_schriftfarbe_not_encoded
              )
            : { r: 255, g: 255, b: 255, a: 1 }
        );
        setDB_LeftSidebar_MenuItem_FilterHauptTitel_BackgroundColor(
          Design_Right_FilterTitleItem !== undefined &&
            Design_Right_FilterTitleItem.right_filtTitle_hintergrundfarbe !==
              undefined
            ? Design_Right_FilterTitleItem.right_filtTitle_hintergrundfarbe
            : "rgba(0,0,0, 0.3)"
        );
        setDB_LeftSidebar_MenuItem_FilterHauptTitel_BackgroundColorNotEncoded(
          Design_Right_FilterTitleItem !== undefined &&
            Design_Right_FilterTitleItem.right_filtTitle_hintergrundfarbe_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Right_FilterTitleItem.right_filtTitle_hintergrundfarbe_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0.3 }
        );
        setDB_LeftSidebar_MenuItem_FilterHauptTitel_ShadowColor(
          Design_Right_FilterTitleItem !== undefined &&
            Design_Right_FilterTitleItem.right_filtTitle_schattenfarbe !==
              undefined
            ? Design_Right_FilterTitleItem.right_filtTitle_schattenfarbe
            : "rgba(0,0,0,0.3)"
        );
        setDB_LeftSidebar_MenuItem_FilterHauptTitel_ShadowColorNotEncoded(
          Design_Right_FilterTitleItem !== undefined &&
            Design_Right_FilterTitleItem.right_filtTitle_schattenfarbe_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Right_FilterTitleItem.right_filtTitle_schattenfarbe_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0.3 }
        );
        setDB_LeftSidebar_MenuItem_FilterHauptTitel_ShadowHorizontal(
          Design_Right_FilterTitleItem !== undefined &&
            Design_Right_FilterTitleItem.right_filtTitle_schattenposition_horizontal !==
              undefined
            ? Design_Right_FilterTitleItem.right_filtTitle_schattenposition_horizontal
            : "2"
        );
        setDB_LeftSidebar_MenuItem_FilterHauptTitel_ShadowVertical(
          Design_Right_FilterTitleItem !== undefined &&
            Design_Right_FilterTitleItem.right_filtTitle_schattenposition_vertikal !==
              undefined
            ? Design_Right_FilterTitleItem.right_filtTitle_schattenposition_vertikal
            : "2"
        );
        setDB_LeftSidebar_MenuItem_FilterHauptTitel_ShadowBlur(
          Design_Right_FilterTitleItem !== undefined &&
            Design_Right_FilterTitleItem.right_filtTitle_schattenrauschen !==
              undefined
            ? Design_Right_FilterTitleItem.right_filtTitle_schattenrauschen
            : "3"
        );
        setDB_LeftSidebar_MenuItem_FilterHauptTitel_FontSize(
          Design_Right_FilterTitleItem !== undefined &&
            Design_Right_FilterTitleItem.right_filtTitle_schriftgroesse !==
              undefined
            ? Design_Right_FilterTitleItem.right_filtTitle_schriftgroesse
            : "16"
        );
        setDB_LeftSidebar_MenuItem_FilterHauptTitel_FontWeight(
          Design_Right_FilterTitleItem !== undefined &&
            Design_Right_FilterTitleItem.right_filtTitle_schriftgewicht !==
              undefined
            ? Design_Right_FilterTitleItem.right_filtTitle_schriftgewicht
            : "100"
        );
        setDB_LeftSidebar_MenuItem_FilterHauptTitel_letterSpacing(
          Design_Right_FilterTitleItem !== undefined &&
            Design_Right_FilterTitleItem.right_filtTitle_buchstabenabstand !==
              undefined
            ? Design_Right_FilterTitleItem.right_filtTitle_buchstabenabstand
            : "1"
        );
        setDB_LeftSidebar_MenuItem_FilterHauptTitel_TextDecoration(
          Design_Right_FilterTitleItem !== undefined &&
            Design_Right_FilterTitleItem.right_filtTitle_text_dekoration !==
              undefined
            ? Design_Right_FilterTitleItem.right_filtTitle_text_dekoration
            : "none"
        );
        setDB_LeftSidebar_MenuItem_FilterHauptTitel_TextDecorationStyle(
          Design_Right_FilterTitleItem !== undefined &&
            Design_Right_FilterTitleItem.right_filtTitle_text_dekoration_stil !==
              undefined
            ? Design_Right_FilterTitleItem.right_filtTitle_text_dekoration_stil
            : "none"
        );
        setDB_LeftSidebar_MenuItem_FilterHauptTitel_marginLeftRight(
          Design_Right_FilterTitleItem !== undefined &&
            Design_Right_FilterTitleItem.right_filtTitle_aussen_abstand_links_rechts !==
              undefined
            ? Design_Right_FilterTitleItem.right_filtTitle_aussen_abstand_links_rechts
            : "10"
        );
        setDB_LeftSidebar_MenuItem_FilterHauptTitel_marginTopBottom(
          Design_Right_FilterTitleItem !== undefined &&
            Design_Right_FilterTitleItem.right_filtTitle_aussen_abstand_oben_unten !==
              undefined
            ? Design_Right_FilterTitleItem.right_filtTitle_aussen_abstand_oben_unten
            : "7"
        );
        setDB_LeftSidebar_MenuItem_FilterHauptTitel_paddingLeftRight(
          Design_Right_FilterTitleItem !== undefined &&
            Design_Right_FilterTitleItem.right_filtTitle_innerer_abstand_links_rechts !==
              undefined
            ? Design_Right_FilterTitleItem.right_filtTitle_innerer_abstand_links_rechts
            : "0"
        );
        setDB_LeftSidebar_MenuItem_FilterHauptTitel_paddingTopBottom(
          Design_Right_FilterTitleItem !== undefined &&
            Design_Right_FilterTitleItem.right_filtTitle_innerer_abstand_oben_unten !==
              undefined
            ? Design_Right_FilterTitleItem.right_filtTitle_innerer_abstand_oben_unten
            : "0"
        );
        setDB_LeftSidebar_MenuItem_FilterHauptTitel_TextAlign(
          Design_Right_FilterTitleItem !== undefined &&
            Design_Right_FilterTitleItem.right_filtTitle_textausrichtung !==
              undefined
            ? Design_Right_FilterTitleItem.right_filtTitle_textausrichtung
            : "center"
        );

        setDB_fontFace_FilterHauptTitel_FontFamilyName(
          Design_Right_FilterTitleItem !== undefined &&
            Design_Right_FilterTitleItem.right_filtTitle_fontfamily !==
              undefined
            ? Design_Right_FilterTitleItem.right_filtTitle_fontfamily
            : "Lato"
        );

        setDB_fontFace_FilterHauptTitel_FontFamilySrc(
          Design_Right_FilterTitleItem !== undefined &&
            Design_Right_FilterTitleItem.right_filtTitle_fontfamilysrc !==
              undefined
            ? Design_Right_FilterTitleItem.right_filtTitle_fontfamilysrc
            : "/assets/Lato/Lato-Regular.ttf"
        );

        setDB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_FontColor(
          Design_Right_FilterTitleItem !== undefined &&
            Design_Right_FilterTitleItem.right_filtTitle_hov_schriftfarbe !==
              undefined
            ? Design_Right_FilterTitleItem.right_filtTitle_hov_schriftfarbe
            : "rgba(255,255,255,1)"
        );
        setDB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_FontColorNotEncoded(
          Design_Right_FilterTitleItem !== undefined &&
            Design_Right_FilterTitleItem.right_filtTitle_hov_schriftfarbe_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Right_FilterTitleItem.right_filtTitle_hov_schriftfarbe_not_encoded
              )
            : { r: 255, g: 255, b: 255, a: 1 }
        );
        setDB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BackgroundColor(
          Design_Right_FilterTitleItem !== undefined &&
            Design_Right_FilterTitleItem.right_filtTitle_hov_hintergrundfarbe !==
              undefined
            ? Design_Right_FilterTitleItem.right_filtTitle_hov_hintergrundfarbe
            : "rgba(0,0,0,0.3)"
        );
        setDB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BackgroundColorNotEncoded(
          Design_Right_FilterTitleItem !== undefined &&
            Design_Right_FilterTitleItem.right_filtTitle_hov_hintergrundfarbe_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Right_FilterTitleItem.right_filtTitle_hov_hintergrundfarbe_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0.3 }
        );
        setDB_LeftSidebar_MenuItem_FilterHauptTitel_ShadowColor_Hover(
          Design_Right_FilterTitleItem !== undefined &&
            Design_Right_FilterTitleItem.right_filtTitle_hov_schattenfarbe !==
              undefined
            ? Design_Right_FilterTitleItem.right_filtTitle_hov_schattenfarbe
            : "rgba(0,0,0,0.3)"
        );
        setDB_LeftSidebar_MenuItem_FilterHauptTitel_ShadowColorNotEncoded_Hover(
          Design_Right_FilterTitleItem !== undefined &&
            Design_Right_FilterTitleItem.right_filtTitle_hov_schattenfarbe_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Right_FilterTitleItem.right_filtTitle_hov_schattenfarbe_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0.3 }
        );
        setDB_LeftSidebar_MenuItem_FilterHauptTitel_ShadowHorizontal_Hover(
          Design_Right_FilterTitleItem !== undefined &&
            Design_Right_FilterTitleItem.right_filtTitle_hov_schattenposition_horizontal !==
              undefined
            ? Design_Right_FilterTitleItem.right_filtTitle_hov_schattenposition_horizontal
            : "2"
        );
        setDB_LeftSidebar_MenuItem_FilterHauptTitel_ShadowVertical_Hover(
          Design_Right_FilterTitleItem !== undefined &&
            Design_Right_FilterTitleItem.right_filtTitle_hov_schattenposition_vertikal !==
              undefined
            ? Design_Right_FilterTitleItem.right_filtTitle_hov_schattenposition_vertikal
            : "2"
        );
        setDB_LeftSidebar_MenuItem_FilterHauptTitel_ShadowBlur_Hover(
          Design_Right_FilterTitleItem !== undefined &&
            Design_Right_FilterTitleItem.right_filtTitle_hov_schattenrauschen !==
              undefined
            ? Design_Right_FilterTitleItem.right_filtTitle_hov_schattenrauschen
            : "3"
        );
        setDB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_FontSize(
          Design_Right_FilterTitleItem !== undefined &&
            Design_Right_FilterTitleItem.right_filtTitle_hov_schriftgroesse !==
              undefined
            ? Design_Right_FilterTitleItem.right_filtTitle_hov_schriftgroesse
            : "16"
        );
        setDB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_FontWeight(
          Design_Right_FilterTitleItem !== undefined &&
            Design_Right_FilterTitleItem.right_filtTitle_hov_schriftgewicht !==
              undefined
            ? Design_Right_FilterTitleItem.right_filtTitle_hov_schriftgewicht
            : "100"
        );
        setDB_LeftSidebar_MenuItem_FilterHauptTitel_letterSpacing_Hover(
          Design_Right_FilterTitleItem !== undefined &&
            Design_Right_FilterTitleItem.right_filtTitle_hov_buchstabenabstand !==
              undefined
            ? Design_Right_FilterTitleItem.right_filtTitle_hov_buchstabenabstand
            : "1"
        );
        setDB_LeftSidebar_MenuItem_FilterHauptTitel_TextDecoration_Hover(
          Design_Right_FilterTitleItem !== undefined &&
            Design_Right_FilterTitleItem.right_filtTitle_hov_text_dekoration !==
              undefined
            ? Design_Right_FilterTitleItem.right_filtTitle_hov_text_dekoration
            : "none"
        );
        setDB_LeftSidebar_MenuItem_FilterHauptTitel_TextDecorationStyle_Hover(
          Design_Right_FilterTitleItem !== undefined &&
            Design_Right_FilterTitleItem.right_filtTitle_hov_text_dekoration_stil !==
              undefined
            ? Design_Right_FilterTitleItem.right_filtTitle_hov_text_dekoration_stil
            : "none"
        );
        setDB_LeftSidebar_MenuItem_FilterHauptTitel_marginLeftRight_Hover(
          Design_Right_FilterTitleItem !== undefined &&
            Design_Right_FilterTitleItem.right_filtTitle_hov_aussen_abstand_links_rechts !==
              undefined
            ? Design_Right_FilterTitleItem.right_filtTitle_hov_aussen_abstand_links_rechts
            : "10"
        );
        setDB_LeftSidebar_MenuItem_FilterHauptTitel_marginTopBottom_Hover(
          Design_Right_FilterTitleItem !== undefined &&
            Design_Right_FilterTitleItem.right_filtTitle_hov_aussen_abstand_oben_unten !==
              undefined
            ? Design_Right_FilterTitleItem.right_filtTitle_hov_aussen_abstand_oben_unten
            : "7"
        );
        setDB_LeftSidebar_MenuItem_FilterHauptTitel_paddingLeftRight_Hover(
          Design_Right_FilterTitleItem !== undefined &&
            Design_Right_FilterTitleItem.right_filtTitle_hov_innerer_abstand_links_rechts !==
              undefined
            ? Design_Right_FilterTitleItem.right_filtTitle_hov_innerer_abstand_links_rechts
            : "0"
        );
        setDB_LeftSidebar_MenuItem_FilterHauptTitel_paddingTopBottom_Hover(
          Design_Right_FilterTitleItem !== undefined &&
            Design_Right_FilterTitleItem.right_filtTitle_hov_innerer_abstand_oben_unten !==
              undefined
            ? Design_Right_FilterTitleItem.right_filtTitle_hov_innerer_abstand_oben_unten
            : "0"
        );
        setDB_LeftSidebar_MenuItem_FilterHauptTitel_letterSpacing_Hover(
          Design_Right_FilterTitleItem !== undefined &&
            Design_Right_FilterTitleItem.right_filtTitle_hov_buchstabenabstand !==
              undefined
            ? Design_Right_FilterTitleItem.right_filtTitle_hov_buchstabenabstand
            : "1"
        );

        setDB_LeftSidebar_MenuItem_FilterHauptTitel_BorderColorLeft(
          Design_Right_FilterTitleItem !== undefined &&
            Design_Right_FilterTitleItem.right_filtTitle_border_color_left !==
              undefined
            ? Design_Right_FilterTitleItem.right_filtTitle_border_color_left
            : "rgba(0,0,0,0.4)"
        );
        setDB_LeftSidebar_MenuItem_FilterHauptTitel_BorderColorLeftNotEncoded(
          Design_Right_FilterTitleItem !== undefined &&
            Design_Right_FilterTitleItem.right_filtTitle_border_color_left_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Right_FilterTitleItem.right_filtTitle_border_color_left_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0.4 }
        );
        setDB_LeftSidebar_MenuItem_FilterHauptTitel_BorderColorRight(
          Design_Right_FilterTitleItem !== undefined &&
            Design_Right_FilterTitleItem.right_filtTitle_border_color_right !==
              undefined
            ? Design_Right_FilterTitleItem.right_filtTitle_border_color_right
            : "rgba(0,0,0,0.4)"
        );
        setDB_LeftSidebar_MenuItem_FilterHauptTitel_BorderColorRightNotEncoded(
          Design_Right_FilterTitleItem !== undefined &&
            Design_Right_FilterTitleItem.right_filtTitle_border_color_right_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Right_FilterTitleItem.right_filtTitle_border_color_right_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0.4 }
        );
        setDB_LeftSidebar_MenuItem_FilterHauptTitel_BorderColorTop(
          Design_Right_FilterTitleItem !== undefined &&
            Design_Right_FilterTitleItem.right_filtTitle_border_color_top !==
              undefined
            ? Design_Right_FilterTitleItem.right_filtTitle_border_color_top
            : "rgba(0,0,0,0.4)"
        );
        setDB_LeftSidebar_MenuItem_FilterHauptTitel_BorderColorTopNotEncoded(
          Design_Right_FilterTitleItem !== undefined &&
            Design_Right_FilterTitleItem.right_filtTitle_border_color_top_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Right_FilterTitleItem.right_filtTitle_border_color_top_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0.4 }
        );
        setDB_LeftSidebar_MenuItem_FilterHauptTitel_BorderColorBottom(
          Design_Right_FilterTitleItem !== undefined &&
            Design_Right_FilterTitleItem.right_filtTitle_border_color_bottom !==
              undefined
            ? Design_Right_FilterTitleItem.right_filtTitle_border_color_bottom
            : "rgba(0,0,0,0.4)"
        );
        setDB_LeftSidebar_MenuItem_FilterHauptTitel_BorderColorBottomNotEncoded(
          Design_Right_FilterTitleItem !== undefined &&
            Design_Right_FilterTitleItem.right_filtTitle_border_color_bottom_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Right_FilterTitleItem.right_filtTitle_border_color_bottom_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0.4 }
        );

        setDB_LeftSidebar_MenuItem_FilterHauptTitel_BorderLeft(
          Design_Right_FilterTitleItem !== undefined &&
            Design_Right_FilterTitleItem.right_filtTitle_border_pixel_left !==
              undefined
            ? Design_Right_FilterTitleItem.right_filtTitle_border_pixel_left
            : "1"
        );
        setDB_LeftSidebar_MenuItem_FilterHauptTitel_BorderRight(
          Design_Right_FilterTitleItem !== undefined &&
            Design_Right_FilterTitleItem.right_filtTitle_border_pixel_right !==
              undefined
            ? Design_Right_FilterTitleItem.right_filtTitle_border_pixel_right
            : "1"
        );
        setDB_LeftSidebar_MenuItem_FilterHauptTitel_BorderTop(
          Design_Right_FilterTitleItem !== undefined &&
            Design_Right_FilterTitleItem.right_filtTitle_border_pixel_top !==
              undefined
            ? Design_Right_FilterTitleItem.right_filtTitle_border_pixel_top
            : "1"
        );
        setDB_LeftSidebar_MenuItem_FilterHauptTitel_BorderBottom(
          Design_Right_FilterTitleItem !== undefined &&
            Design_Right_FilterTitleItem.right_filtTitle_border_pixel_bottom !==
              undefined
            ? Design_Right_FilterTitleItem.right_filtTitle_border_pixel_bottom
            : "1"
        );

        setDB_LeftSidebar_MenuItem_FilterHauptTitel_BorderTopLeftRadius(
          Design_Right_FilterTitleItem !== undefined &&
            Design_Right_FilterTitleItem.right_filtTitle_border_radius_top_left !==
              undefined
            ? Design_Right_FilterTitleItem.right_filtTitle_border_radius_top_left
            : "7"
        );
        setDB_LeftSidebar_MenuItem_FilterHauptTitel_BorderTopRightRadius(
          Design_Right_FilterTitleItem !== undefined &&
            Design_Right_FilterTitleItem.right_filtTitle_border_radius_top_right !==
              undefined
            ? Design_Right_FilterTitleItem.right_filtTitle_border_radius_top_right
            : "7"
        );
        setDB_LeftSidebar_MenuItem_FilterHauptTitel_BorderBottomLeftRadius(
          Design_Right_FilterTitleItem !== undefined &&
            Design_Right_FilterTitleItem.right_filtTitle_border_radius_bottom_left !==
              undefined
            ? Design_Right_FilterTitleItem.right_filtTitle_border_radius_bottom_left
            : "7"
        );
        setDB_LeftSidebar_MenuItem_FilterHauptTitel_BorderBottomRightRadius(
          Design_Right_FilterTitleItem !== undefined &&
            Design_Right_FilterTitleItem.right_filtTitle_border_radius_bottom_right !==
              undefined
            ? Design_Right_FilterTitleItem.right_filtTitle_border_radius_bottom_right
            : "7"
        );

        setDB_LeftSidebar_MenuItem_FilterHauptTitel_BorderStyleLeft(
          Design_Right_FilterTitleItem !== undefined &&
            Design_Right_FilterTitleItem.right_filtTitle_border_style_left !==
              undefined
            ? Design_Right_FilterTitleItem.right_filtTitle_border_style_left
            : "solid"
        );
        setDB_LeftSidebar_MenuItem_FilterHauptTitel_BorderStyleRight(
          Design_Right_FilterTitleItem !== undefined &&
            Design_Right_FilterTitleItem.right_filtTitle_border_style_right !==
              undefined
            ? Design_Right_FilterTitleItem.right_filtTitle_border_style_right
            : "solid"
        );
        setDB_LeftSidebar_MenuItem_FilterHauptTitel_BorderStyleTop(
          Design_Right_FilterTitleItem !== undefined &&
            Design_Right_FilterTitleItem.right_filtTitle_border_style_top !==
              undefined
            ? Design_Right_FilterTitleItem.right_filtTitle_border_style_top
            : "solid"
        );
        setDB_LeftSidebar_MenuItem_FilterHauptTitel_BorderStyleBottom(
          Design_Right_FilterTitleItem !== undefined &&
            Design_Right_FilterTitleItem.right_filtTitle_border_style_bottom !==
              undefined
            ? Design_Right_FilterTitleItem.right_filtTitle_border_style_bottom
            : "solid"
        );

        setDB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderColorLeft(
          Design_Right_FilterTitleItem !== undefined &&
            Design_Right_FilterTitleItem.right_filtTitle_border_hov_color_left !==
              undefined
            ? Design_Right_FilterTitleItem.right_filtTitle_border_hov_color_left
            : "rgba(0,0,0,0.4)"
        );
        setDB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderColorLeftNotEncoded(
          Design_Right_FilterTitleItem !== undefined &&
            Design_Right_FilterTitleItem.right_filtTitle_border_hov_color_left_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Right_FilterTitleItem.right_filtTitle_border_hov_color_left_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0.4 }
        );
        setDB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderColorRight(
          Design_Right_FilterTitleItem !== undefined &&
            Design_Right_FilterTitleItem.right_filtTitle_border_hov_color_right !==
              undefined
            ? Design_Right_FilterTitleItem.right_filtTitle_border_hov_color_right
            : "rgba(0,0,0,0.4)"
        );
        setDB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderColorRightNotEncoded(
          Design_Right_FilterTitleItem !== undefined &&
            Design_Right_FilterTitleItem.right_filtTitle_border_hov_color_right_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Right_FilterTitleItem.right_filtTitle_border_hov_color_right_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0.4 }
        );
        setDB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderColorTop(
          Design_Right_FilterTitleItem !== undefined &&
            Design_Right_FilterTitleItem.right_filtTitle_border_hov_color_top !==
              undefined
            ? Design_Right_FilterTitleItem.right_filtTitle_border_hov_color_top
            : "rgba(0,0,0,0.4)"
        );
        setDB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderColorTopNotEncoded(
          Design_Right_FilterTitleItem !== undefined &&
            Design_Right_FilterTitleItem.right_filtTitle_border_hov_color_top_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Right_FilterTitleItem.right_filtTitle_border_hov_color_top_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0.4 }
        );
        setDB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderColorBottom(
          Design_Right_FilterTitleItem !== undefined &&
            Design_Right_FilterTitleItem.right_filtTitle_border_hov_color_bottom !==
              undefined
            ? Design_Right_FilterTitleItem.right_filtTitle_border_hov_color_bottom
            : "rgba(0,0,0,0.4)"
        );
        setDB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderColorBottomNotEncoded(
          Design_Right_FilterTitleItem !== undefined &&
            Design_Right_FilterTitleItem.right_filtTitle_border_hov_color_bottom_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Right_FilterTitleItem.right_filtTitle_border_hov_color_bottom_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0.4 }
        );

        setDB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderLeft(
          Design_Right_FilterTitleItem !== undefined &&
            Design_Right_FilterTitleItem.right_filtTitle_border_hov_pixel_left !==
              undefined
            ? Design_Right_FilterTitleItem.right_filtTitle_border_hov_pixel_left
            : "1"
        );
        setDB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderRight(
          Design_Right_FilterTitleItem !== undefined &&
            Design_Right_FilterTitleItem.right_filtTitle_border_hov_pixel_right !==
              undefined
            ? Design_Right_FilterTitleItem.right_filtTitle_border_hov_pixel_right
            : "1"
        );
        setDB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderTop(
          Design_Right_FilterTitleItem !== undefined &&
            Design_Right_FilterTitleItem.right_filtTitle_border_hov_pixel_top !==
              undefined
            ? Design_Right_FilterTitleItem.right_filtTitle_border_hov_pixel_top
            : "1"
        );
        setDB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderBottom(
          Design_Right_FilterTitleItem !== undefined &&
            Design_Right_FilterTitleItem.right_filtTitle_border_hov_pixel_bottom !==
              undefined
            ? Design_Right_FilterTitleItem.right_filtTitle_border_hov_pixel_bottom
            : "1"
        );

        setDB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderTopLeftRadius(
          Design_Right_FilterTitleItem !== undefined &&
            Design_Right_FilterTitleItem.right_filtTitle_border_hov_radius_top_left !==
              undefined
            ? Design_Right_FilterTitleItem.right_filtTitle_border_hov_radius_top_left
            : "7"
        );
        setDB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderTopRightRadius(
          Design_Right_FilterTitleItem !== undefined &&
            Design_Right_FilterTitleItem.right_filtTitle_border_hov_radius_top_right !==
              undefined
            ? Design_Right_FilterTitleItem.right_filtTitle_border_hov_radius_top_right
            : "7"
        );
        setDB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderBottomLeftRadius(
          Design_Right_FilterTitleItem !== undefined &&
            Design_Right_FilterTitleItem.right_filtTitle_border_hov_radius_bottom_left !==
              undefined
            ? Design_Right_FilterTitleItem.right_filtTitle_border_hov_radius_bottom_left
            : "7"
        );
        setDB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderBottomRightRadius(
          Design_Right_FilterTitleItem !== undefined &&
            Design_Right_FilterTitleItem.right_filtTitle_border_hov_radius_bottom_right !==
              undefined
            ? Design_Right_FilterTitleItem.right_filtTitle_border_hov_radius_bottom_right
            : "7"
        );

        setDB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderStyleLeft(
          Design_Right_FilterTitleItem !== undefined &&
            Design_Right_FilterTitleItem.right_filtTitle_border_hov_style_left !==
              undefined
            ? Design_Right_FilterTitleItem.right_filtTitle_border_hov_style_left
            : "solid"
        );
        setDB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderStyleRight(
          Design_Right_FilterTitleItem !== undefined &&
            Design_Right_FilterTitleItem.right_filtTitle_border_hov_style_right !==
              undefined
            ? Design_Right_FilterTitleItem.right_filtTitle_border_hov_style_right
            : "solid"
        );
        setDB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderStyleTop(
          Design_Right_FilterTitleItem !== undefined &&
            Design_Right_FilterTitleItem.right_filtTitle_border_hov_style_top !==
              undefined
            ? Design_Right_FilterTitleItem.right_filtTitle_border_hov_style_top
            : "solid"
        );
        setDB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderStyleBottom(
          Design_Right_FilterTitleItem !== undefined &&
            Design_Right_FilterTitleItem.right_filtTitle_border_hov_style_bottom !==
              undefined
            ? Design_Right_FilterTitleItem.right_filtTitle_border_hov_style_bottom
            : "solid"
        );

        setDB_LeftSidebar_MenuItem_FilterItem_FontColor(
          Design_Right_FilterTitleItem !== undefined &&
            Design_Right_FilterTitleItem.right_filtItem_schriftfarbe !==
              undefined
            ? Design_Right_FilterTitleItem.right_filtItem_schriftfarbe
            : "rgba(255,255,255,1)"
        );
        setDB_LeftSidebar_MenuItem_FilterItem_FontColorNotEncoded(
          Design_Right_FilterTitleItem !== undefined &&
            Design_Right_FilterTitleItem.right_filtItem_schriftfarbe_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Right_FilterTitleItem.right_filtItem_schriftfarbe_not_encoded
              )
            : { r: 255, g: 255, b: 255, a: 1 }
        );
        setDB_LeftSidebar_MenuItem_FilterItem_BackgroundColor(
          Design_Right_FilterTitleItem !== undefined &&
            Design_Right_FilterTitleItem.right_filtItem_hintergrundfarbe !==
              undefined
            ? Design_Right_FilterTitleItem.right_filtItem_hintergrundfarbe
            : "rgba(0,0,0,0)"
        );
        setDB_LeftSidebar_MenuItem_FilterItem_BackgroundColorNotEncoded(
          Design_Right_FilterTitleItem !== undefined &&
            Design_Right_FilterTitleItem.right_filtItem_hintergrundfarbe_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Right_FilterTitleItem.right_filtItem_hintergrundfarbe_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0 }
        );
        setDB_LeftSidebar_MenuItem_FilterItem_ShadowColor(
          Design_Right_FilterTitleItem !== undefined &&
            Design_Right_FilterTitleItem.right_filtItem_schattenfarbe !==
              undefined
            ? Design_Right_FilterTitleItem.right_filtItem_schattenfarbe
            : "rgba(0,0,0,1)"
        );
        setDB_LeftSidebar_MenuItem_FilterItem_ShadowColorNotEncoded(
          Design_Right_FilterTitleItem !== undefined &&
            Design_Right_FilterTitleItem.right_filtItem_schattenfarbe_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Right_FilterTitleItem.right_filtItem_schattenfarbe_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 1 }
        );
        setDB_LeftSidebar_MenuItem_FilterItem_ShadowHorizontal(
          Design_Right_FilterTitleItem !== undefined &&
            Design_Right_FilterTitleItem.right_filtItem_schattenposition_horizontal !==
              undefined
            ? Design_Right_FilterTitleItem.right_filtItem_schattenposition_horizontal
            : "2"
        );
        setDB_LeftSidebar_MenuItem_FilterItem_ShadowVertical(
          Design_Right_FilterTitleItem !== undefined &&
            Design_Right_FilterTitleItem.right_filtItem_schattenposition_vertikal !==
              undefined
            ? Design_Right_FilterTitleItem.right_filtItem_schattenposition_vertikal
            : "2"
        );
        setDB_LeftSidebar_MenuItem_FilterItem_ShadowBlur(
          Design_Right_FilterTitleItem !== undefined &&
            Design_Right_FilterTitleItem.right_filtItem_schattenrauschen !==
              undefined
            ? Design_Right_FilterTitleItem.right_filtItem_schattenrauschen
            : "3"
        );
        setDB_LeftSidebar_MenuItem_FilterItem_FontSize(
          Design_Right_FilterTitleItem !== undefined &&
            Design_Right_FilterTitleItem.right_filtItem_schriftgroesse !==
              undefined
            ? Design_Right_FilterTitleItem.right_filtItem_schriftgroesse
            : "15"
        );
        setDB_LeftSidebar_MenuItem_FilterItem_FontWeight(
          Design_Right_FilterTitleItem !== undefined &&
            Design_Right_FilterTitleItem.right_filtItem_schriftgewicht !==
              undefined
            ? Design_Right_FilterTitleItem.right_filtItem_schriftgewicht
            : "100"
        );
        setDB_LeftSidebar_MenuItem_FilterItem_letterSpacing(
          Design_Right_FilterTitleItem !== undefined &&
            Design_Right_FilterTitleItem.right_filtItem_buchstabenabstand !==
              undefined
            ? Design_Right_FilterTitleItem.right_filtItem_buchstabenabstand
            : "0"
        );
        setDB_LeftSidebar_MenuItem_FilterItem_TextDecoration(
          Design_Right_FilterTitleItem !== undefined &&
            Design_Right_FilterTitleItem.right_filtItem_text_dekoration !==
              undefined
            ? Design_Right_FilterTitleItem.right_filtItem_text_dekoration
            : "none"
        );
        setDB_LeftSidebar_MenuItem_FilterItem_TextDecorationStyle(
          Design_Right_FilterTitleItem !== undefined &&
            Design_Right_FilterTitleItem.right_filtItem_text_dekoration_stil !==
              undefined
            ? Design_Right_FilterTitleItem.right_filtItem_text_dekoration_stil
            : "none"
        );
        setDB_LeftSidebar_MenuItem_FilterItem_marginLeftRight(
          Design_Right_FilterTitleItem !== undefined &&
            Design_Right_FilterTitleItem.right_filtItem_aussen_abstand_links_rechts !==
              undefined
            ? Design_Right_FilterTitleItem.right_filtItem_aussen_abstand_links_rechts
            : "35"
        );
        setDB_LeftSidebar_MenuItem_FilterItem_marginTopBottom(
          Design_Right_FilterTitleItem !== undefined &&
            Design_Right_FilterTitleItem.right_filtItem_aussen_abstand_oben_unten !==
              undefined
            ? Design_Right_FilterTitleItem.right_filtItem_aussen_abstand_oben_unten
            : "0"
        );
        setDB_LeftSidebar_MenuItem_FilterItem_paddingLeftRight(
          Design_Right_FilterTitleItem !== undefined &&
            Design_Right_FilterTitleItem.right_filtItem_innerer_abstand_links_rechts !==
              undefined
            ? Design_Right_FilterTitleItem.right_filtItem_innerer_abstand_links_rechts
            : "0"
        );
        setDB_LeftSidebar_MenuItem_FilterItem_paddingTopBottom(
          Design_Right_FilterTitleItem !== undefined &&
            Design_Right_FilterTitleItem.right_filtItem_innerer_abstand_oben_unten !==
              undefined
            ? Design_Right_FilterTitleItem.right_filtItem_innerer_abstand_oben_unten
            : "0"
        );
        setDB_LeftSidebar_MenuItem_FilterItem_TextAlign(
          Design_Right_FilterTitleItem !== undefined &&
            Design_Right_FilterTitleItem.right_filtItem_textausrichtung !==
              undefined
            ? Design_Right_FilterTitleItem.right_filtItem_textausrichtung
            : "left"
        );

        setDB_fontFace_FilterItemFontFamily_FontFamilyName(
          Design_Right_FilterTitleItem !== undefined &&
            Design_Right_FilterTitleItem.right_filtItem_fontfamily !== undefined
            ? Design_Right_FilterTitleItem.right_filtItem_fontfamily
            : "Lato"
        );

        setDB_fontFace_FilterItemFontFamily_FontFamilySrc(
          Design_Right_FilterTitleItem !== undefined &&
            Design_Right_FilterTitleItem.right_filtItem_fontfamilysrc !==
              undefined
            ? Design_Right_FilterTitleItem.right_filtItem_fontfamilysrc
            : "/assets/Lato/Lato-Regular.ttf"
        );

        setDB_LeftSidebar_MenuItem_FilterItem_Hover_FontColor(
          Design_Right_FilterTitleItem !== undefined &&
            Design_Right_FilterTitleItem.right_filtItem_hov_schriftfarbe !==
              undefined
            ? Design_Right_FilterTitleItem.right_filtItem_hov_schriftfarbe
            : "rgba(255,255,255,1)"
        );
        setDB_LeftSidebar_MenuItem_FilterItem_Hover_FontColorNotEncoded(
          Design_Right_FilterTitleItem !== undefined &&
            Design_Right_FilterTitleItem.right_filtItem_hov_schriftfarbe_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Right_FilterTitleItem.right_filtItem_hov_schriftfarbe_not_encoded
              )
            : { r: 255, g: 255, b: 255, a: 1 }
        );
        setDB_LeftSidebar_MenuItem_FilterItem_Hover_BackgroundColor(
          Design_Right_FilterTitleItem !== undefined &&
            Design_Right_FilterTitleItem.right_filtItem_hov_hintergrundfarbe !==
              undefined
            ? Design_Right_FilterTitleItem.right_filtItem_hov_hintergrundfarbe
            : "rgba(0,0,0,0)"
        );
        setDB_LeftSidebar_MenuItem_FilterItem_Hover_BackgroundColorNotEncoded(
          Design_Right_FilterTitleItem !== undefined &&
            Design_Right_FilterTitleItem.right_filtItem_hov_hintergrundfarbe_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Right_FilterTitleItem.right_filtItem_hov_hintergrundfarbe_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0 }
        );
        setDB_LeftSidebar_MenuItem_FilterItem_ShadowColor_Hover(
          Design_Right_FilterTitleItem !== undefined &&
            Design_Right_FilterTitleItem.right_filtItem_hov_schattenfarbe !==
              undefined
            ? Design_Right_FilterTitleItem.right_filtItem_hov_schattenfarbe
            : "rgba(0,0,0,1)"
        );
        setDB_LeftSidebar_MenuItem_FilterItem_ShadowColorNotEncoded_Hover(
          Design_Right_FilterTitleItem !== undefined &&
            Design_Right_FilterTitleItem.right_filtItem_hov_schattenfarbe_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Right_FilterTitleItem.right_filtItem_hov_schattenfarbe_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 1 }
        );
        setDB_LeftSidebar_MenuItem_FilterItem_ShadowHorizontal_Hover(
          Design_Right_FilterTitleItem !== undefined &&
            Design_Right_FilterTitleItem.right_filtItem_hov_schattenposition_horizontal !==
              undefined
            ? Design_Right_FilterTitleItem.right_filtItem_hov_schattenposition_horizontal
            : "2"
        );
        setDB_LeftSidebar_MenuItem_FilterItem_ShadowVertical_Hover(
          Design_Right_FilterTitleItem !== undefined &&
            Design_Right_FilterTitleItem.right_filtItem_hov_schattenposition_vertikal !==
              undefined
            ? Design_Right_FilterTitleItem.right_filtItem_hov_schattenposition_vertikal
            : "2"
        );
        setDB_LeftSidebar_MenuItem_FilterItem_ShadowBlur_Hover(
          Design_Right_FilterTitleItem !== undefined &&
            Design_Right_FilterTitleItem.right_filtItem_hov_schattenrauschen !==
              undefined
            ? Design_Right_FilterTitleItem.right_filtItem_hov_schattenrauschen
            : "3"
        );
        setDB_LeftSidebar_MenuItem_FilterItem_Hover_FontSize(
          Design_Right_FilterTitleItem !== undefined &&
            Design_Right_FilterTitleItem.right_filtItem_hov_schriftgroesse !==
              undefined
            ? Design_Right_FilterTitleItem.right_filtItem_hov_schriftgroesse
            : "15"
        );
        setDB_LeftSidebar_MenuItem_FilterItem_Hover_FontWeight(
          Design_Right_FilterTitleItem !== undefined &&
            Design_Right_FilterTitleItem.right_filtItem_hov_schriftgewicht !==
              undefined
            ? Design_Right_FilterTitleItem.right_filtItem_hov_schriftgewicht
            : "100"
        );
        setDB_LeftSidebar_MenuItem_FilterItem_letterSpacing_Hover(
          Design_Right_FilterTitleItem !== undefined &&
            Design_Right_FilterTitleItem.right_filtItem_hov_buchstabenabstand !==
              undefined
            ? Design_Right_FilterTitleItem.right_filtItem_hov_buchstabenabstand
            : "0"
        );
        setDB_LeftSidebar_MenuItem_FilterItem_TextDecoration_Hover(
          Design_Right_FilterTitleItem !== undefined &&
            Design_Right_FilterTitleItem.right_filtItem_hov_text_dekoration !==
              undefined
            ? Design_Right_FilterTitleItem.right_filtItem_hov_text_dekoration
            : "none"
        );
        setDB_LeftSidebar_MenuItem_FilterItem_TextDecorationStyle_Hover(
          Design_Right_FilterTitleItem !== undefined &&
            Design_Right_FilterTitleItem.right_filtItem_hov_text_dekoration_stil !==
              undefined
            ? Design_Right_FilterTitleItem.right_filtItem_hov_text_dekoration_stil
            : "none"
        );
        setDB_LeftSidebar_MenuItem_FilterItem_marginLeftRight_Hover(
          Design_Right_FilterTitleItem !== undefined &&
            Design_Right_FilterTitleItem.right_filtItem_hov_aussen_abstand_links_rechts !==
              undefined
            ? Design_Right_FilterTitleItem.right_filtItem_hov_aussen_abstand_links_rechts
            : "35"
        );
        setDB_LeftSidebar_MenuItem_FilterItem_marginTopBottom_Hover(
          Design_Right_FilterTitleItem !== undefined &&
            Design_Right_FilterTitleItem.right_filtItem_hov_aussen_abstand_oben_unten !==
              undefined
            ? Design_Right_FilterTitleItem.right_filtItem_hov_aussen_abstand_oben_unten
            : "0"
        );
        setDB_LeftSidebar_MenuItem_FilterItem_paddingLeftRight_Hover(
          Design_Right_FilterTitleItem !== undefined &&
            Design_Right_FilterTitleItem.right_filtItem_hov_innerer_abstand_links_rechts !==
              undefined
            ? Design_Right_FilterTitleItem.right_filtItem_hov_innerer_abstand_links_rechts
            : "0"
        );
        setDB_LeftSidebar_MenuItem_FilterItem_paddingTopBottom_Hover(
          Design_Right_FilterTitleItem !== undefined &&
            Design_Right_FilterTitleItem.right_filtItem_hov_innerer_abstand_oben_unten !==
              undefined
            ? Design_Right_FilterTitleItem.right_filtItem_hov_innerer_abstand_oben_unten
            : "0"
        );
        setDB_LeftSidebar_MenuItem_FilterItem_Hover_TextAlign(
          Design_Right_FilterTitleItem !== undefined &&
            Design_Right_FilterTitleItem.right_filtItem_hov_textausrichtung !==
              undefined
            ? Design_Right_FilterTitleItem.right_filtItem_hov_textausrichtung
            : "left"
        );

        setDB_LeftSidebar_MenuItem_FilterItem_BorderColorLeft(
          Design_Right_FilterTitleItem !== undefined &&
            Design_Right_FilterTitleItem.right_filtItem_border_color_left !==
              undefined
            ? Design_Right_FilterTitleItem.right_filtItem_border_color_left
            : "rgba(0,0,0,0)"
        );
        setDB_LeftSidebar_MenuItem_FilterItem_BorderColorLeftNotEncoded(
          Design_Right_FilterTitleItem !== undefined &&
            Design_Right_FilterTitleItem.right_filtItem_border_color_left_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Right_FilterTitleItem.right_filtItem_border_color_left_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0 }
        );
        setDB_LeftSidebar_MenuItem_FilterItem_BorderColorRight(
          Design_Right_FilterTitleItem !== undefined &&
            Design_Right_FilterTitleItem.right_filtItem_border_color_right !==
              undefined
            ? Design_Right_FilterTitleItem.right_filtItem_border_color_right
            : "rgba(0,0,0,0)"
        );
        setDB_LeftSidebar_MenuItem_FilterItem_BorderColorRightNotEncoded(
          Design_Right_FilterTitleItem !== undefined &&
            Design_Right_FilterTitleItem.right_filtItem_border_color_right_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Right_FilterTitleItem.right_filtItem_border_color_right_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0 }
        );
        setDB_LeftSidebar_MenuItem_FilterItem_BorderColorTop(
          Design_Right_FilterTitleItem !== undefined &&
            Design_Right_FilterTitleItem.right_filtItem_border_color_top !==
              undefined
            ? Design_Right_FilterTitleItem.right_filtItem_border_color_top
            : "rgba(0,0,0,0)"
        );
        setDB_LeftSidebar_MenuItem_FilterItem_BorderColorTopNotEncoded(
          Design_Right_FilterTitleItem !== undefined &&
            Design_Right_FilterTitleItem.right_filtItem_border_color_top_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Right_FilterTitleItem.right_filtItem_border_color_top_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0 }
        );
        setDB_LeftSidebar_MenuItem_FilterItem_BorderColorBottom(
          Design_Right_FilterTitleItem !== undefined &&
            Design_Right_FilterTitleItem.right_filtItem_border_color_bottom !==
              undefined
            ? Design_Right_FilterTitleItem.right_filtItem_border_color_bottom
            : "rgba(0,0,0,0)"
        );
        setDB_LeftSidebar_MenuItem_FilterItem_BorderColorBottomNotEncoded(
          Design_Right_FilterTitleItem !== undefined &&
            Design_Right_FilterTitleItem.right_filtItem_border_color_bottom_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Right_FilterTitleItem.right_filtItem_border_color_bottom_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0 }
        );

        setDB_LeftSidebar_MenuItem_FilterItem_BorderLeft(
          Design_Right_FilterTitleItem !== undefined &&
            Design_Right_FilterTitleItem.right_filtItem_border_pixel_left !==
              undefined
            ? Design_Right_FilterTitleItem.right_filtItem_border_pixel_left
            : "0"
        );
        setDB_LeftSidebar_MenuItem_FilterItem_BorderRight(
          Design_Right_FilterTitleItem !== undefined &&
            Design_Right_FilterTitleItem.right_filtItem_border_pixel_right !==
              undefined
            ? Design_Right_FilterTitleItem.right_filtItem_border_pixel_right
            : "0"
        );
        setDB_LeftSidebar_MenuItem_FilterItem_BorderTop(
          Design_Right_FilterTitleItem !== undefined &&
            Design_Right_FilterTitleItem.right_filtItem_border_pixel_top !==
              undefined
            ? Design_Right_FilterTitleItem.right_filtItem_border_pixel_top
            : "0"
        );
        setDB_LeftSidebar_MenuItem_FilterItem_BorderBottom(
          Design_Right_FilterTitleItem !== undefined &&
            Design_Right_FilterTitleItem.right_filtItem_border_pixel_bottom !==
              undefined
            ? Design_Right_FilterTitleItem.right_filtItem_border_pixel_bottom
            : "0"
        );

        setDB_LeftSidebar_MenuItem_FilterItem_BorderTopLeftRadius(
          Design_Right_FilterTitleItem !== undefined &&
            Design_Right_FilterTitleItem.right_filtItem_border_radius_top_left !==
              undefined
            ? Design_Right_FilterTitleItem.right_filtItem_border_radius_top_left
            : "0"
        );
        setDB_LeftSidebar_MenuItem_FilterItem_BorderTopRightRadius(
          Design_Right_FilterTitleItem !== undefined &&
            Design_Right_FilterTitleItem.right_filtItem_border_radius_top_right !==
              undefined
            ? Design_Right_FilterTitleItem.right_filtItem_border_radius_top_right
            : "0"
        );
        setDB_LeftSidebar_MenuItem_FilterItem_BorderBottomLeftRadius(
          Design_Right_FilterTitleItem !== undefined &&
            Design_Right_FilterTitleItem.right_filtItem_border_radius_bottom_left !==
              undefined
            ? Design_Right_FilterTitleItem.right_filtItem_border_radius_bottom_left
            : "0"
        );
        setDB_LeftSidebar_MenuItem_FilterItem_BorderBottomRightRadius(
          Design_Right_FilterTitleItem !== undefined &&
            Design_Right_FilterTitleItem.right_filtItem_border_radius_bottom_right !==
              undefined
            ? Design_Right_FilterTitleItem.right_filtItem_border_radius_bottom_right
            : "0"
        );

        setDB_LeftSidebar_MenuItem_FilterItem_BorderStyleLeft(
          Design_Right_FilterTitleItem !== undefined &&
            Design_Right_FilterTitleItem.right_filtItem_border_style_left !==
              undefined
            ? Design_Right_FilterTitleItem.right_filtItem_border_style_left
            : "solid"
        );
        setDB_LeftSidebar_MenuItem_FilterItem_BorderStyleRight(
          Design_Right_FilterTitleItem !== undefined &&
            Design_Right_FilterTitleItem.right_filtItem_border_style_right !==
              undefined
            ? Design_Right_FilterTitleItem.right_filtItem_border_style_right
            : "solid"
        );
        setDB_LeftSidebar_MenuItem_FilterItem_BorderStyleTop(
          Design_Right_FilterTitleItem !== undefined &&
            Design_Right_FilterTitleItem.right_filtItem_border_style_top !==
              undefined
            ? Design_Right_FilterTitleItem.right_filtItem_border_style_top
            : "solid"
        );
        setDB_LeftSidebar_MenuItem_FilterItem_BorderStyleBottom(
          Design_Right_FilterTitleItem !== undefined &&
            Design_Right_FilterTitleItem.right_filtItem_border_style_bottom !==
              undefined
            ? Design_Right_FilterTitleItem.right_filtItem_border_style_bottom
            : "solid"
        );

        setDB_LeftSidebar_MenuItem_FilterItem_Hover_BorderColorLeft(
          Design_Right_FilterTitleItem !== undefined &&
            Design_Right_FilterTitleItem.right_filtItem_border_hov_color_left !==
              undefined
            ? Design_Right_FilterTitleItem.right_filtItem_border_hov_color_left
            : "rgba(0,0,0,0)"
        );
        setDB_LeftSidebar_MenuItem_FilterItem_Hover_BorderColorLeftNotEncoded(
          Design_Right_FilterTitleItem !== undefined &&
            Design_Right_FilterTitleItem.right_filtItem_border_hov_color_left_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Right_FilterTitleItem.right_filtItem_border_hov_color_left_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0 }
        );
        setDB_LeftSidebar_MenuItem_FilterItem_Hover_BorderColorRight(
          Design_Right_FilterTitleItem !== undefined &&
            Design_Right_FilterTitleItem.right_filtItem_border_hov_color_right !==
              undefined
            ? Design_Right_FilterTitleItem.right_filtItem_border_hov_color_right
            : "rgba(0,0,0,0)"
        );
        setDB_LeftSidebar_MenuItem_FilterItem_Hover_BorderColorRightNotEncoded(
          Design_Right_FilterTitleItem !== undefined &&
            Design_Right_FilterTitleItem.right_filtItem_border_hov_color_right_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Right_FilterTitleItem.right_filtItem_border_hov_color_right_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0 }
        );
        setDB_LeftSidebar_MenuItem_FilterItem_Hover_BorderColorTop(
          Design_Right_FilterTitleItem !== undefined &&
            Design_Right_FilterTitleItem.right_filtItem_border_hov_color_top !==
              undefined
            ? Design_Right_FilterTitleItem.right_filtItem_border_hov_color_top
            : "rgba(0,0,0,0)"
        );
        setDB_LeftSidebar_MenuItem_FilterItem_Hover_BorderColorTopNotEncoded(
          Design_Right_FilterTitleItem !== undefined &&
            Design_Right_FilterTitleItem.right_filtItem_border_hov_color_top_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Right_FilterTitleItem.right_filtItem_border_hov_color_top_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0 }
        );
        setDB_LeftSidebar_MenuItem_FilterItem_Hover_BorderColorBottom(
          Design_Right_FilterTitleItem !== undefined &&
            Design_Right_FilterTitleItem.right_filtItem_border_hov_color_bottom !==
              undefined
            ? Design_Right_FilterTitleItem.right_filtItem_border_hov_color_bottom
            : "rgba(0,0,0,0)"
        );
        setDB_LeftSidebar_MenuItem_FilterItem_Hover_BorderColorBottomNotEncoded(
          Design_Right_FilterTitleItem !== undefined &&
            Design_Right_FilterTitleItem.right_filtItem_border_hov_color_bottom_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Right_FilterTitleItem.right_filtItem_border_hov_color_bottom_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0 }
        );

        setDB_LeftSidebar_MenuItem_FilterItem_Hover_BorderLeft(
          Design_Right_FilterTitleItem !== undefined &&
            Design_Right_FilterTitleItem.right_filtItem_border_hov_pixel_left !==
              undefined
            ? Design_Right_FilterTitleItem.right_filtItem_border_hov_pixel_left
            : "0"
        );
        setDB_LeftSidebar_MenuItem_FilterItem_Hover_BorderRight(
          Design_Right_FilterTitleItem !== undefined &&
            Design_Right_FilterTitleItem.right_filtItem_border_hov_pixel_right !==
              undefined
            ? Design_Right_FilterTitleItem.right_filtItem_border_hov_pixel_right
            : "0"
        );
        setDB_LeftSidebar_MenuItem_FilterItem_Hover_BorderTop(
          Design_Right_FilterTitleItem !== undefined &&
            Design_Right_FilterTitleItem.right_filtItem_border_hov_pixel_top !==
              undefined
            ? Design_Right_FilterTitleItem.right_filtItem_border_hov_pixel_top
            : "0"
        );
        setDB_LeftSidebar_MenuItem_FilterItem_Hover_BorderBottom(
          Design_Right_FilterTitleItem !== undefined &&
            Design_Right_FilterTitleItem.right_filtItem_border_hov_pixel_bottom !==
              undefined
            ? Design_Right_FilterTitleItem.right_filtItem_border_hov_pixel_bottom
            : "0"
        );

        setDB_LeftSidebar_MenuItem_FilterItem_Hover_BorderTopLeftRadius(
          Design_Right_FilterTitleItem !== undefined &&
            Design_Right_FilterTitleItem.right_filtItem_border_hov_radius_top_left !==
              undefined
            ? Design_Right_FilterTitleItem.right_filtItem_border_hov_radius_top_left
            : "7"
        );
        setDB_LeftSidebar_MenuItem_FilterItem_Hover_BorderTopRightRadius(
          Design_Right_FilterTitleItem !== undefined &&
            Design_Right_FilterTitleItem.right_filtItem_border_hov_radius_top_right !==
              undefined
            ? Design_Right_FilterTitleItem.right_filtItem_border_hov_radius_top_right
            : "7"
        );
        setDB_LeftSidebar_MenuItem_FilterItem_Hover_BorderBottomLeftRadius(
          Design_Right_FilterTitleItem !== undefined &&
            Design_Right_FilterTitleItem.right_filtItem_border_hov_radius_bottom_left !==
              undefined
            ? Design_Right_FilterTitleItem.right_filtItem_border_hov_radius_bottom_left
            : "7"
        );
        setDB_LeftSidebar_MenuItem_FilterItem_Hover_BorderBottomRightRadius(
          Design_Right_FilterTitleItem !== undefined &&
            Design_Right_FilterTitleItem.right_filtItem_border_hov_radius_bottom_right !==
              undefined
            ? Design_Right_FilterTitleItem.right_filtItem_border_hov_radius_bottom_right
            : "7"
        );

        setDB_LeftSidebar_MenuItem_FilterItem_Hover_BorderStyleLeft(
          Design_Right_FilterTitleItem !== undefined &&
            Design_Right_FilterTitleItem.right_filtItem_border_hov_style_left !==
              undefined
            ? Design_Right_FilterTitleItem.right_filtItem_border_hov_style_left
            : "solid"
        );
        setDB_LeftSidebar_MenuItem_FilterItem_Hover_BorderStyleRight(
          Design_Right_FilterTitleItem !== undefined &&
            Design_Right_FilterTitleItem.right_filtItem_border_hov_style_right !==
              undefined
            ? Design_Right_FilterTitleItem.right_filtItem_border_hov_style_right
            : "solid"
        );
        setDB_LeftSidebar_MenuItem_FilterItem_Hover_BorderStyleTop(
          Design_Right_FilterTitleItem !== undefined &&
            Design_Right_FilterTitleItem.right_filtItem_border_hov_style_top !==
              undefined
            ? Design_Right_FilterTitleItem.right_filtItem_border_hov_style_top
            : "solid"
        );
        setDB_LeftSidebar_MenuItem_FilterItem_Hover_BorderStyleBottom(
          Design_Right_FilterTitleItem !== undefined &&
            Design_Right_FilterTitleItem.right_filtItem_border_hov_style_bottom !==
              undefined
            ? Design_Right_FilterTitleItem.right_filtItem_border_hov_style_bottom
            : "solid"
        );

        setDB_Waehrung_Global(
          Design_Sortierung !== undefined &&
            Design_Sortierung.waehrung !== undefined
            ? Design_Sortierung.waehrung
            : "€"
        );

        setDB_LeftSidebar_MenuItem_SortierungTop_FontColor(
          Design_Sortierung !== undefined &&
            Design_Sortierung.sortierung_schriftfarbe !== undefined
            ? Design_Sortierung.sortierung_schriftfarbe
            : "rgba(255,255,255,1)"
        );
        setDB_LeftSidebar_MenuItem_SortierungTop_FontColorNotEncoded(
          Design_Sortierung !== undefined &&
            Design_Sortierung.sortierung_schriftfarbe_not_encoded !== undefined
            ? JSON.parse(Design_Sortierung.sortierung_schriftfarbe_not_encoded)
            : { r: 255, g: 255, b: 255, a: 1 }
        );
        setDB_LeftSidebar_MenuItem_SortierungTop_BackgroundColor(
          Design_Sortierung !== undefined &&
            Design_Sortierung.sortierung_hintergrundfarbe !== undefined
            ? Design_Sortierung.sortierung_hintergrundfarbe
            : "rgba(0,0,0,0.3)"
        );
        setDB_LeftSidebar_MenuItem_SortierungTop_BackgroundColorNotEncoded(
          Design_Sortierung !== undefined &&
            Design_Sortierung.sortierung_hintergrundfarbe_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Sortierung.sortierung_hintergrundfarbe_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0.3 }
        );
        setDB_LeftSidebar_MenuItem_SortierungTop_ShadowColor(
          Design_Sortierung !== undefined &&
            Design_Sortierung.sortierung_schattenfarbe !== undefined
            ? Design_Sortierung.sortierung_schattenfarbe
            : "rgba(0,0,0,0.4)"
        );
        setDB_LeftSidebar_MenuItem_SortierungTop_ShadowColorNotEncoded(
          Design_Sortierung !== undefined &&
            Design_Sortierung.sortierung_schattenfarbe_not_encoded !== undefined
            ? JSON.parse(Design_Sortierung.sortierung_schattenfarbe_not_encoded)
            : { r: 0, g: 0, b: 0, a: 0.4 }
        );
        setDB_LeftSidebar_MenuItem_SortierungTop_ShadowHorizontal(
          Design_Sortierung !== undefined &&
            Design_Sortierung.sortierung_schattenposition_horizontal !==
              undefined
            ? Design_Sortierung.sortierung_schattenposition_horizontal
            : "0"
        );
        setDB_LeftSidebar_MenuItem_SortierungTop_ShadowVertical(
          Design_Sortierung !== undefined &&
            Design_Sortierung.sortierung_schattenposition_vertikal !== undefined
            ? Design_Sortierung.sortierung_schattenposition_vertikal
            : "4"
        );
        setDB_LeftSidebar_MenuItem_SortierungTop_ShadowBlur(
          Design_Sortierung !== undefined &&
            Design_Sortierung.sortierung_schattenrauschen !== undefined
            ? Design_Sortierung.sortierung_schattenrauschen
            : "5"
        );

        setDB_fontFace_FilterTopBar_FontFamilyName(
          Design_Sortierung !== undefined &&
            Design_Sortierung.sortierung_fontfamilyname !== undefined
            ? Design_Sortierung.sortierung_fontfamilyname
            : "Lato"
        );

        setDB_fontFace_FilterTopBar_FontFamilySrc(
          Design_Sortierung !== undefined &&
            Design_Sortierung.sortierung_fontfamilysrc !== undefined
            ? Design_Sortierung.sortierung_fontfamilysrc
            : "/assets/Lato/Lato-Regular.ttf"
        );

        setDB_LeftSidebar_MenuItem_SortierungTop_Hover_FontColor(
          Design_Sortierung !== undefined &&
            Design_Sortierung.sortierung_hov_schriftfarbe !== undefined
            ? Design_Sortierung.sortierung_hov_schriftfarbe
            : "rgba(255,255,255,1)"
        );
        setDB_LeftSidebar_MenuItem_SortierungTop_Hover_FontColorNotEncoded(
          Design_Sortierung !== undefined &&
            Design_Sortierung.sortierung_hov_schriftfarbe_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Sortierung.sortierung_hov_schriftfarbe_not_encoded
              )
            : { r: 255, g: 255, b: 255, a: 1 }
        );
        setDB_LeftSidebar_MenuItem_SortierungTop_Hover_BackgroundColor(
          Design_Sortierung !== undefined &&
            Design_Sortierung.sortierung_hov_hintergrundfarbe !== undefined
            ? Design_Sortierung.sortierung_hov_hintergrundfarbe
            : "rgba(0,0,0,0.3)"
        );
        setDB_LeftSidebar_MenuItem_SortierungTop_Hover_BackgroundColorNotEncoded(
          Design_Sortierung !== undefined &&
            Design_Sortierung.sortierung_hov_hintergrundfarbe_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Sortierung.sortierung_hov_hintergrundfarbe_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0.3 }
        );
        setDB_LeftSidebar_MenuItem_SortierungTop_Hover_ShadowColor(
          Design_Sortierung !== undefined &&
            Design_Sortierung.sortierung_hov_schattenfarbe !== undefined
            ? Design_Sortierung.sortierung_hov_schattenfarbe
            : "rgba(0,0,0,0.5)"
        );
        setDB_LeftSidebar_MenuItem_SortierungTop_Hover_ShadowColorNotEncoded(
          Design_Sortierung !== undefined &&
            Design_Sortierung.sortierung_hov_schattenfarbe_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Sortierung.sortierung_hov_schattenfarbe_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0.5 }
        );
        setDB_LeftSidebar_MenuItem_SortierungTop_Hover_ShadowHorizontal(
          Design_Sortierung !== undefined &&
            Design_Sortierung.sortierung_hov_schattenposition_horizontal !==
              undefined
            ? Design_Sortierung.sortierung_hov_schattenposition_horizontal
            : "0"
        );
        setDB_LeftSidebar_MenuItem_SortierungTop_Hover_ShadowVertical(
          Design_Sortierung !== undefined &&
            Design_Sortierung.sortierung_hov_schattenposition_vertikal !==
              undefined
            ? Design_Sortierung.sortierung_hov_schattenposition_vertikal
            : "4"
        );
        setDB_LeftSidebar_MenuItem_SortierungTop_Hover_ShadowBlur(
          Design_Sortierung !== undefined &&
            Design_Sortierung.sortierung_hov_schattenrauschen !== undefined
            ? Design_Sortierung.sortierung_hov_schattenrauschen
            : "6"
        );

        setDB_LeftSidebar_MenuItem_SortierungTop_BorderColorLeft(
          Design_Sortierung !== undefined &&
            Design_Sortierung.sortierung_border_color_left !== undefined
            ? Design_Sortierung.sortierung_border_color_left
            : "rgba(0,0,0,0.3)"
        );
        setDB_LeftSidebar_MenuItem_SortierungTop_BorderColorLeftNotEncoded(
          Design_Sortierung !== undefined &&
            Design_Sortierung.sortierung_border_color_left_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Sortierung.sortierung_border_color_left_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0.3 }
        );
        setDB_LeftSidebar_MenuItem_SortierungTop_BorderColorRight(
          Design_Sortierung !== undefined &&
            Design_Sortierung.sortierung_border_color_right !== undefined
            ? Design_Sortierung.sortierung_border_color_right
            : "rgba(0,0,0,0.3)"
        );
        setDB_LeftSidebar_MenuItem_SortierungTop_BorderColorRightNotEncoded(
          Design_Sortierung !== undefined &&
            Design_Sortierung.sortierung_border_color_right_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Sortierung.sortierung_border_color_right_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0.3 }
        );
        setDB_LeftSidebar_MenuItem_SortierungTop_BorderColorTop(
          Design_Sortierung !== undefined &&
            Design_Sortierung.sortierung_border_color_top !== undefined
            ? Design_Sortierung.sortierung_border_color_top
            : "rgba(0,0,0,0.3)"
        );
        setDB_LeftSidebar_MenuItem_SortierungTop_BorderColorTopNotEncoded(
          Design_Sortierung !== undefined &&
            Design_Sortierung.sortierung_border_color_top_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Sortierung.sortierung_border_color_top_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0.3 }
        );
        setDB_LeftSidebar_MenuItem_SortierungTop_BorderColorBottom(
          Design_Sortierung !== undefined &&
            Design_Sortierung.sortierung_border_color_bottom !== undefined
            ? Design_Sortierung.sortierung_border_color_bottom
            : "rgba(0,0,0,0.3)"
        );
        setDB_LeftSidebar_MenuItem_SortierungTop_BorderColorBottomNotEncoded(
          Design_Sortierung !== undefined &&
            Design_Sortierung.sortierung_border_color_bottom_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Sortierung.sortierung_border_color_bottom_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0.3 }
        );

        setDB_LeftSidebar_MenuItem_SortierungTop_BorderLeft(
          Design_Sortierung !== undefined &&
            Design_Sortierung.sortierung_border_pixel_left !== undefined
            ? Design_Sortierung.sortierung_border_pixel_left
            : "1"
        );
        setDB_LeftSidebar_MenuItem_SortierungTop_BorderRight(
          Design_Sortierung !== undefined &&
            Design_Sortierung.sortierung_border_pixel_right !== undefined
            ? Design_Sortierung.sortierung_border_pixel_right
            : "1"
        );
        setDB_LeftSidebar_MenuItem_SortierungTop_BorderTop(
          Design_Sortierung !== undefined &&
            Design_Sortierung.sortierung_border_pixel_top !== undefined
            ? Design_Sortierung.sortierung_border_pixel_top
            : "1"
        );
        setDB_LeftSidebar_MenuItem_SortierungTop_BorderBottom(
          Design_Sortierung !== undefined &&
            Design_Sortierung.sortierung_border_pixel_bottom !== undefined
            ? Design_Sortierung.sortierung_border_pixel_bottom
            : "1"
        );

        setDB_LeftSidebar_MenuItem_SortierungTop_BorderTopLeftRadius(
          Design_Sortierung !== undefined &&
            Design_Sortierung.sortierung_border_radius_top_left !== undefined
            ? Design_Sortierung.sortierung_border_radius_top_left
            : "7"
        );
        setDB_LeftSidebar_MenuItem_SortierungTop_BorderTopRightRadius(
          Design_Sortierung !== undefined &&
            Design_Sortierung.sortierung_border_radius_top_right !== undefined
            ? Design_Sortierung.sortierung_border_radius_top_right
            : "7"
        );
        setDB_LeftSidebar_MenuItem_SortierungTop_BorderBottomLeftRadius(
          Design_Sortierung !== undefined &&
            Design_Sortierung.sortierung_border_radius_bottom_left !== undefined
            ? Design_Sortierung.sortierung_border_radius_bottom_left
            : "7"
        );
        setDB_LeftSidebar_MenuItem_SortierungTop_BorderBottomRightRadius(
          Design_Sortierung !== undefined &&
            Design_Sortierung.sortierung_border_radius_bottom_right !==
              undefined
            ? Design_Sortierung.sortierung_border_radius_bottom_right
            : "7"
        );

        setDB_LeftSidebar_MenuItem_SortierungTop_BorderStyleLeft(
          Design_Sortierung !== undefined &&
            Design_Sortierung.sortierung_border_style_left !== undefined
            ? Design_Sortierung.sortierung_border_style_left
            : "solid"
        );
        setDB_LeftSidebar_MenuItem_SortierungTop_BorderStyleRight(
          Design_Sortierung !== undefined &&
            Design_Sortierung.sortierung_border_style_right !== undefined
            ? Design_Sortierung.sortierung_border_style_right
            : "solid"
        );
        setDB_LeftSidebar_MenuItem_SortierungTop_BorderStyleTop(
          Design_Sortierung !== undefined &&
            Design_Sortierung.sortierung_border_style_top !== undefined
            ? Design_Sortierung.sortierung_border_style_top
            : "solid"
        );
        setDB_LeftSidebar_MenuItem_SortierungTop_BorderStyleBottom(
          Design_Sortierung !== undefined &&
            Design_Sortierung.sortierung_border_style_bottom !== undefined
            ? Design_Sortierung.sortierung_border_style_bottom
            : "solid"
        );

        setDB_LeftSidebar_MenuItem_SortierungTop_Hover_BorderColorLeft(
          Design_Sortierung !== undefined &&
            Design_Sortierung.sortierung_hov_border_color_left !== undefined
            ? Design_Sortierung.sortierung_hov_border_color_left
            : "rgba(0,0,0,0.3)"
        );
        setDB_LeftSidebar_MenuItem_SortierungTop_Hover_BorderColorLeftNotEncoded(
          Design_Sortierung !== undefined &&
            Design_Sortierung.sortierung_hov_border_color_left_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Sortierung.sortierung_hov_border_color_left_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0.3 }
        );
        setDB_LeftSidebar_MenuItem_SortierungTop_Hover_BorderColorRight(
          Design_Sortierung !== undefined &&
            Design_Sortierung.sortierung_hov_border_color_right !== undefined
            ? Design_Sortierung.sortierung_hov_border_color_right
            : "rgba(0,0,0,0.3)"
        );
        setDB_LeftSidebar_MenuItem_SortierungTop_Hover_BorderColorRightNotEncoded(
          Design_Sortierung !== undefined &&
            Design_Sortierung.sortierung_hov_border_color_right_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Sortierung.sortierung_hov_border_color_right_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0.3 }
        );
        setDB_LeftSidebar_MenuItem_SortierungTop_Hover_BorderColorTop(
          Design_Sortierung !== undefined &&
            Design_Sortierung.sortierung_hov_border_color_top !== undefined
            ? Design_Sortierung.sortierung_hov_border_color_top
            : "rgba(0,0,0,0.3)"
        );
        setDB_LeftSidebar_MenuItem_SortierungTop_Hover_BorderColorTopNotEncoded(
          Design_Sortierung !== undefined &&
            Design_Sortierung.sortierung_hov_border_color_top_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Sortierung.sortierung_hov_border_color_top_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0.3 }
        );
        setDB_LeftSidebar_MenuItem_SortierungTop_Hover_BorderColorBottom(
          Design_Sortierung !== undefined &&
            Design_Sortierung.sortierung_hov_border_color_bottom !== undefined
            ? Design_Sortierung.sortierung_hov_border_color_bottom
            : "rgba(0,0,0,0.3)"
        );
        setDB_LeftSidebar_MenuItem_SortierungTop_Hover_BorderColorBottomNotEncoded(
          Design_Sortierung !== undefined &&
            Design_Sortierung.sortierung_hov_border_color_bottom_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Sortierung.sortierung_hov_border_color_bottom_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0.3 }
        );

        setDB_LeftSidebar_MenuItem_SortierungTop_Hover_BorderLeft(
          Design_Sortierung !== undefined &&
            Design_Sortierung.sortierung_hov_border_pixel_left !== undefined
            ? Design_Sortierung.sortierung_hov_border_pixel_left
            : "1"
        );
        setDB_LeftSidebar_MenuItem_SortierungTop_Hover_BorderRight(
          Design_Sortierung !== undefined &&
            Design_Sortierung.sortierung_hov_border_pixel_right !== undefined
            ? Design_Sortierung.sortierung_hov_border_pixel_right
            : "1"
        );
        setDB_LeftSidebar_MenuItem_SortierungTop_Hover_BorderTop(
          Design_Sortierung !== undefined &&
            Design_Sortierung.sortierung_hov_border_pixel_top !== undefined
            ? Design_Sortierung.sortierung_hov_border_pixel_top
            : "1"
        );
        setDB_LeftSidebar_MenuItem_SortierungTop_Hover_BorderBottom(
          Design_Sortierung !== undefined &&
            Design_Sortierung.sortierung_hov_border_pixel_bottom !== undefined
            ? Design_Sortierung.sortierung_hov_border_pixel_bottom
            : "1"
        );

        setDB_LeftSidebar_MenuItem_SortierungTop_Hover_BorderTopLeftRadius(
          Design_Sortierung !== undefined &&
            Design_Sortierung.sortierung_hov_border_radius_top_left !==
              undefined
            ? Design_Sortierung.sortierung_hov_border_radius_top_left
            : "7"
        );
        setDB_LeftSidebar_MenuItem_SortierungTop_Hover_BorderTopRightRadius(
          Design_Sortierung !== undefined &&
            Design_Sortierung.sortierung_hov_border_radius_top_right !==
              undefined
            ? Design_Sortierung.sortierung_hov_border_radius_top_right
            : "7"
        );
        setDB_LeftSidebar_MenuItem_SortierungTop_Hover_BorderBottomLeftRadius(
          Design_Sortierung !== undefined &&
            Design_Sortierung.sortierung_hov_border_radius_bottom_left !==
              undefined
            ? Design_Sortierung.sortierung_hov_border_radius_bottom_left
            : "7"
        );
        setDB_LeftSidebar_MenuItem_SortierungTop_Hover_BorderBottomRightRadius(
          Design_Sortierung !== undefined &&
            Design_Sortierung.sortierung_hov_border_radius_bottom_right !==
              undefined
            ? Design_Sortierung.sortierung_hov_border_radius_bottom_right
            : "7"
        );

        setDB_LeftSidebar_MenuItem_SortierungTop_Hover_BorderStyleLeft(
          Design_Sortierung !== undefined &&
            Design_Sortierung.sortierung_hov_border_style_left !== undefined
            ? Design_Sortierung.sortierung_hov_border_style_left
            : "solid"
        );
        setDB_LeftSidebar_MenuItem_SortierungTop_Hover_BorderStyleRight(
          Design_Sortierung !== undefined &&
            Design_Sortierung.sortierung_hov_border_style_right !== undefined
            ? Design_Sortierung.sortierung_hov_border_style_right
            : "solid"
        );
        setDB_LeftSidebar_MenuItem_SortierungTop_Hover_BorderStyleTop(
          Design_Sortierung !== undefined &&
            Design_Sortierung.sortierung_hov_border_style_top !== undefined
            ? Design_Sortierung.sortierung_hov_border_style_top
            : "solid"
        );
        setDB_LeftSidebar_MenuItem_SortierungTop_Hover_BorderStyleBottom(
          Design_Sortierung !== undefined &&
            Design_Sortierung.sortierung_hov_border_style_bottom !== undefined
            ? Design_Sortierung.sortierung_hov_border_style_bottom
            : "solid"
        );

        setDB_BackgroundColorOverlayForAllBody(
          Design_Sortierung !== undefined &&
            Design_Sortierung.main_backgroundcolor_encoded !== undefined
            ? Design_Sortierung.main_backgroundcolor_encoded
            : "rgba(0,0,0,0)"
        );

        setDB_BackgroundColorOverlayForAllBodyNotEncoded(
          Design_Sortierung !== undefined &&
            Design_Sortierung.main_backgroundcolor_not_encoded !== undefined
            ? JSON.parse(Design_Sortierung.main_backgroundcolor_not_encoded)
            : { r: 0, g: 0, b: 0, a: 0 }
        );

        setBackgroundColorActive(
          Design_Sortierung !== undefined &&
            Design_Sortierung.main_backgroundcolor_active !== undefined
            ? JSON.parse(Design_Sortierung.main_backgroundcolor_active)
            : false
        );

        setDB_OrderOfBodyElements(
          Design_Sortierung !== undefined &&
            Design_Sortierung.main_order_menu_content !== undefined
            ? Design_Sortierung.main_order_menu_content
            : "012"
        );

        setDB_LeftSidebar_MenuProduktContainer_BackgroundColor(
          Design_Card_Crumbs !== undefined &&
            Design_Card_Crumbs.card_crumbs_hintergrundfarbe !== undefined
            ? Design_Card_Crumbs.card_crumbs_hintergrundfarbe
            : "rgba(0,0,0,0.3)"
        );

        setDB_LeftSidebar_MenuProduktContainer_BackgroundColorNotEncoded(
          Design_Card_Crumbs !== undefined &&
            Design_Card_Crumbs.card_crumbs_hintergrundfarbe_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Card_Crumbs.card_crumbs_hintergrundfarbe_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0.3 }
        );

        setDB_LeftSidebar_MenuProduktImageContainer_BackgroundColor(
          Design_Card_Crumbs !== undefined &&
            Design_Card_Crumbs.card_crumbs_image_hintergrundfarbe !== undefined
            ? Design_Card_Crumbs.card_crumbs_image_hintergrundfarbe
            : "rgba(0,0,0,0.7)"
        );

        setDB_LeftSidebar_MenuProduktImageContainer_BackgroundColorNotEncoded(
          Design_Card_Crumbs !== undefined &&
            Design_Card_Crumbs.card_crumbs_image_hintergrundfarbe_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Card_Crumbs.card_crumbs_image_hintergrundfarbe_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0.7 }
        );

        setDB_LeftSidebar_MenuItem_MenuProduktContainer_ShadowColor(
          Design_Card_Crumbs !== undefined &&
            Design_Card_Crumbs.card_crumbs_schattenfarbe !== undefined
            ? Design_Card_Crumbs.card_crumbs_schattenfarbe
            : "rgba(0,0,0,0.4)"
        );
        setDB_LeftSidebar_MenuItem_MenuProduktContainer_ShadowColorNotEncoded(
          Design_Card_Crumbs !== undefined &&
            Design_Card_Crumbs.card_crumbs_schattenfarbe_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Card_Crumbs.card_crumbs_schattenfarbe_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0.4 }
        );

        setDB_LeftSidebar_MenuItem_MenuProduktContainer_ShadowHorizontal(
          Design_Card_Crumbs !== undefined &&
            Design_Card_Crumbs.card_crumbs_schattenposition_horizontal !==
              undefined
            ? Design_Card_Crumbs.card_crumbs_schattenposition_horizontal
            : "4"
        );

        setDB_LeftSidebar_MenuItem_MenuProduktContainer_ShadowVertical(
          Design_Card_Crumbs !== undefined &&
            Design_Card_Crumbs.card_crumbs_schattenposition_vertikal !==
              undefined
            ? Design_Card_Crumbs.card_crumbs_schattenposition_vertikal
            : "4"
        );

        setDB_LeftSidebar_MenuItem_MenuProduktContainer_ShadowBlur(
          Design_Card_Crumbs !== undefined &&
            Design_Card_Crumbs.card_crumbs_schattenrauschen !== undefined
            ? Design_Card_Crumbs.card_crumbs_schattenrauschen
            : "5"
        );

        setDB_LeftSidebar_MenuItem_MenuProduktContainer_paddingLeft(
          Design_Card_Crumbs !== undefined &&
            Design_Card_Crumbs.card_crumbs_innerer_abstand_links !== undefined
            ? Design_Card_Crumbs.card_crumbs_innerer_abstand_links
            : "0"
        );

        setDB_LeftSidebar_MenuItem_MenuProduktContainer_paddingRight(
          Design_Card_Crumbs !== undefined &&
            Design_Card_Crumbs.card_crumbs_innerer_abstand_rechts !== undefined
            ? Design_Card_Crumbs.card_crumbs_innerer_abstand_rechts
            : "0"
        );

        setDB_LeftSidebar_MenuItem_MenuProduktContainer_paddingTop(
          Design_Card_Crumbs !== undefined &&
            Design_Card_Crumbs.card_crumbs_innerer_abstand_oben !== undefined
            ? Design_Card_Crumbs.card_crumbs_innerer_abstand_oben
            : "0"
        );
        setDB_LeftSidebar_MenuItem_MenuProduktContainer_paddingBottom(
          Design_Card_Crumbs !== undefined &&
            Design_Card_Crumbs.card_crumbs_innerer_abstand_unten !== undefined
            ? Design_Card_Crumbs.card_crumbs_innerer_abstand_unten
            : "0"
        );

        setDB_LeftSidebar_MenuProduktContainer_Hover_BackgroundColor(
          Design_Card_Crumbs !== undefined &&
            Design_Card_Crumbs.card_crumbs_hover_hintergrundfarbe !== undefined
            ? Design_Card_Crumbs.card_crumbs_hover_hintergrundfarbe
            : "rgba(0,0,0,0.4)"
        );

        setDB_LeftSidebar_MenuProduktContainer_Hover_BackgroundColorNotEncoded(
          Design_Card_Crumbs !== undefined &&
            Design_Card_Crumbs.card_crumbs_hover_hintergrundfarbe_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Card_Crumbs.card_crumbs_hover_hintergrundfarbe_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0.4 }
        );

        setDB_LeftSidebar_MenuProduktImageContainer_Hover_BackgroundColor(
          Design_Card_Crumbs !== undefined &&
            Design_Card_Crumbs.card_crumbs_image_hover_hintergrundfarbe !==
              undefined
            ? Design_Card_Crumbs.card_crumbs_image_hover_hintergrundfarbe
            : "rgba(0,0,0,0.7)"
        );

        setDB_LeftSidebar_MenuProduktImageContainer_Hover_BackgroundColorNotEncoded(
          Design_Card_Crumbs !== undefined &&
            Design_Card_Crumbs.card_crumbs_image_hover_hintergrundfarbe_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Card_Crumbs.card_crumbs_image_hover_hintergrundfarbe_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0.7 }
        );

        setDB_LeftSidebar_MenuItem_MenuProduktContainer_Hover_ShadowColor(
          Design_Card_Crumbs !== undefined &&
            Design_Card_Crumbs.card_crumbs_hover_schattenfarbe !== undefined
            ? Design_Card_Crumbs.card_crumbs_hover_schattenfarbe
            : "rgba(0,0,0,0.5)"
        );
        setDB_LeftSidebar_MenuItem_MenuProduktContainer_Hover_ShadowColorNotEncoded(
          Design_Card_Crumbs !== undefined &&
            Design_Card_Crumbs.card_crumbs_hover_schattenfarbe_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Card_Crumbs.card_crumbs_hover_schattenfarbe_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0.5 }
        );

        setDB_LeftSidebar_MenuItem_MenuProduktContainer_Hover_ShadowHorizontal(
          Design_Card_Crumbs !== undefined &&
            Design_Card_Crumbs.card_crumbs_hover_schattenposition_horizontal !==
              undefined
            ? Design_Card_Crumbs.card_crumbs_hover_schattenposition_horizontal
            : "4"
        );

        setDB_LeftSidebar_MenuItem_MenuProduktContainer_Hover_ShadowVertical(
          Design_Card_Crumbs !== undefined &&
            Design_Card_Crumbs.card_crumbs_hover_schattenposition_vertikal !==
              undefined
            ? Design_Card_Crumbs.card_crumbs_hover_schattenposition_vertikal
            : "4"
        );

        setDB_LeftSidebar_MenuItem_MenuProduktContainer_Hover_ShadowBlur(
          Design_Card_Crumbs !== undefined &&
            Design_Card_Crumbs.card_crumbs_hover_schattenrauschen !== undefined
            ? Design_Card_Crumbs.card_crumbs_hover_schattenrauschen
            : "6"
        );

        setDB_LeftSidebar_MenuItem_MenuProduktContainer_Hover_paddingLeft(
          Design_Card_Crumbs !== undefined &&
            Design_Card_Crumbs.card_crumbs_hover_innerer_abstand_links !==
              undefined
            ? Design_Card_Crumbs.card_crumbs_hover_innerer_abstand_links
            : "0"
        );

        setDB_LeftSidebar_MenuItem_MenuProduktContainer_Hover_paddingRight(
          Design_Card_Crumbs !== undefined &&
            Design_Card_Crumbs.card_crumbs_hover_innerer_abstand_rechts !==
              undefined
            ? Design_Card_Crumbs.card_crumbs_hover_innerer_abstand_rechts
            : "0"
        );

        setDB_LeftSidebar_MenuItem_MenuProduktContainer_Hover_paddingTop(
          Design_Card_Crumbs !== undefined &&
            Design_Card_Crumbs.card_crumbs_hover_innerer_abstand_oben !==
              undefined
            ? Design_Card_Crumbs.card_crumbs_hover_innerer_abstand_oben
            : "0"
        );
        setDB_LeftSidebar_MenuItem_MenuProduktContainer_Hover_paddingBottom(
          Design_Card_Crumbs !== undefined &&
            Design_Card_Crumbs.card_crumbs_hover_innerer_abstand_unten !==
              undefined
            ? Design_Card_Crumbs.card_crumbs_hover_innerer_abstand_unten
            : "0"
        );

        setDB_LeftSidebar_MenuProduktContainer_BorderColorLeft(
          Design_Card_Crumbs !== undefined &&
            Design_Card_Crumbs.card_crumbs_border_color_left !== undefined
            ? Design_Card_Crumbs.card_crumbs_border_color_left
            : "rgba(0,0,0,0.3)"
        );
        setDB_LeftSidebar_MenuProduktContainer_BorderColorLeftNotEncoded(
          Design_Card_Crumbs !== undefined &&
            Design_Card_Crumbs.card_crumbs_border_color_left_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Card_Crumbs.card_crumbs_border_color_left_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0.3 }
        );
        setDB_LeftSidebar_MenuProduktContainer_BorderColorRight(
          Design_Card_Crumbs !== undefined &&
            Design_Card_Crumbs.card_crumbs_border_color_right !== undefined
            ? Design_Card_Crumbs.card_crumbs_border_color_right
            : "rgba(0,0,0,0.3)"
        );
        setDB_LeftSidebar_MenuProduktContainer_BorderColorRightNotEncoded(
          Design_Card_Crumbs !== undefined &&
            Design_Card_Crumbs.card_crumbs_border_color_right_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Card_Crumbs.card_crumbs_border_color_right_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0.3 }
        );
        setDB_LeftSidebar_MenuProduktContainer_BorderColorTop(
          Design_Card_Crumbs !== undefined &&
            Design_Card_Crumbs.card_crumbs_border_color_top !== undefined
            ? Design_Card_Crumbs.card_crumbs_border_color_top
            : "rgba(0,0,0,0.3)"
        );
        setDB_LeftSidebar_MenuProduktContainer_BorderColorTopNotEncoded(
          Design_Card_Crumbs !== undefined &&
            Design_Card_Crumbs.card_crumbs_border_color_top_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Card_Crumbs.card_crumbs_border_color_top_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0.3 }
        );
        setDB_LeftSidebar_MenuProduktContainer_BorderColorBottom(
          Design_Card_Crumbs !== undefined &&
            Design_Card_Crumbs.card_crumbs_border_color_bottom !== undefined
            ? Design_Card_Crumbs.card_crumbs_border_color_bottom
            : "rgba(0,0,0,0.3)"
        );
        setDB_LeftSidebar_MenuProduktContainer_BorderColorBottomNotEncoded(
          Design_Card_Crumbs !== undefined &&
            Design_Card_Crumbs.card_crumbs_border_color_bottom_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Card_Crumbs.card_crumbs_border_color_bottom_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0.3 }
        );

        setDB_LeftSidebar_MenuProduktContainer_BorderLeft(
          Design_Card_Crumbs !== undefined &&
            Design_Card_Crumbs.card_crumbs_border_pixel_left !== undefined
            ? Design_Card_Crumbs.card_crumbs_border_pixel_left
            : "1"
        );
        setDB_LeftSidebar_MenuProduktContainer_BorderRight(
          Design_Card_Crumbs !== undefined &&
            Design_Card_Crumbs.card_crumbs_border_pixel_right !== undefined
            ? Design_Card_Crumbs.card_crumbs_border_pixel_right
            : "1"
        );
        setDB_LeftSidebar_MenuProduktContainer_BorderTop(
          Design_Card_Crumbs !== undefined &&
            Design_Card_Crumbs.card_crumbs_border_pixel_top !== undefined
            ? Design_Card_Crumbs.card_crumbs_border_pixel_top
            : "1"
        );
        setDB_LeftSidebar_MenuProduktContainer_BorderBottom(
          Design_Card_Crumbs !== undefined &&
            Design_Card_Crumbs.card_crumbs_border_pixel_bottom !== undefined
            ? Design_Card_Crumbs.card_crumbs_border_pixel_bottom
            : "1"
        );

        setDB_LeftSidebar_MenuItem_MenuProduktContainer_BorderTopLeftRadius(
          Design_Card_Crumbs !== undefined &&
            Design_Card_Crumbs.card_crumbs_border_radius_top_left !== undefined
            ? Design_Card_Crumbs.card_crumbs_border_radius_top_left
            : "7"
        );
        setDB_LeftSidebar_MenuItem_MenuProduktContainer_BorderTopRightRadius(
          Design_Card_Crumbs !== undefined &&
            Design_Card_Crumbs.card_crumbs_border_radius_top_right !== undefined
            ? Design_Card_Crumbs.card_crumbs_border_radius_top_right
            : "7"
        );
        setDB_LeftSidebar_MenuItem_MenuProduktContainer_BorderBottomLeftRadius(
          Design_Card_Crumbs !== undefined &&
            Design_Card_Crumbs.card_crumbs_border_radius_bottom_left !==
              undefined
            ? Design_Card_Crumbs.card_crumbs_border_radius_bottom_left
            : "7"
        );
        setDB_LeftSidebar_MenuItem_MenuProduktContainer_BorderBottomRightRadius(
          Design_Card_Crumbs !== undefined &&
            Design_Card_Crumbs.card_crumbs_border_radius_bottom_right !==
              undefined
            ? Design_Card_Crumbs.card_crumbs_border_radius_bottom_right
            : "7"
        );

        setDB_LeftSidebar_MenuProduktContainer_BorderStyleLeft(
          Design_Card_Crumbs !== undefined &&
            Design_Card_Crumbs.card_crumbs_border_style_left !== undefined
            ? Design_Card_Crumbs.card_crumbs_border_style_left
            : "solid"
        );
        setDB_LeftSidebar_MenuProduktContainer_BorderStyleRight(
          Design_Card_Crumbs !== undefined &&
            Design_Card_Crumbs.card_crumbs_border_style_right !== undefined
            ? Design_Card_Crumbs.card_crumbs_border_style_right
            : "solid"
        );
        setDB_LeftSidebar_MenuProduktContainer_BorderStyleTop(
          Design_Card_Crumbs !== undefined &&
            Design_Card_Crumbs.card_crumbs_border_style_top !== undefined
            ? Design_Card_Crumbs.card_crumbs_border_style_top
            : "solid"
        );
        setDB_LeftSidebar_MenuProduktContainer_BorderStyleBottom(
          Design_Card_Crumbs !== undefined &&
            Design_Card_Crumbs.card_crumbs_border_style_bottom !== undefined
            ? Design_Card_Crumbs.card_crumbs_border_style_bottom
            : "solid"
        );

        setDB_LeftSidebar_MenuProduktContainer_Hover_BorderColorLeft(
          Design_Card_Crumbs !== undefined &&
            Design_Card_Crumbs.card_crumbs_border_hov_color_left !== undefined
            ? Design_Card_Crumbs.card_crumbs_border_hov_color_left
            : "rgba(0,0,0,0.3)"
        );
        setDB_LeftSidebar_MenuProduktContainer_Hover_BorderColorLeftNotEncoded(
          Design_Card_Crumbs !== undefined &&
            Design_Card_Crumbs.card_crumbs_border_hov_color_left_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Card_Crumbs.card_crumbs_border_hov_color_left_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0.3 }
        );
        setDB_LeftSidebar_MenuProduktContainer_Hover_BorderColorRight(
          Design_Card_Crumbs !== undefined &&
            Design_Card_Crumbs.card_crumbs_border_hov_color_right !== undefined
            ? Design_Card_Crumbs.card_crumbs_border_hov_color_right
            : "rgba(0,0,0,0.3)"
        );
        setDB_LeftSidebar_MenuProduktContainer_Hover_BorderColorRightNotEncoded(
          Design_Card_Crumbs !== undefined &&
            Design_Card_Crumbs.card_crumbs_border_hov_color_right_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Card_Crumbs.card_crumbs_border_hov_color_right_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0.3 }
        );
        setDB_LeftSidebar_MenuProduktContainer_Hover_BorderColorTop(
          Design_Card_Crumbs !== undefined &&
            Design_Card_Crumbs.card_crumbs_border_hov_color_top !== undefined
            ? Design_Card_Crumbs.card_crumbs_border_hov_color_top
            : "rgba(0,0,0,0.3)"
        );
        setDB_LeftSidebar_MenuProduktContainer_Hover_BorderColorTopNotEncoded(
          Design_Card_Crumbs !== undefined &&
            Design_Card_Crumbs.card_crumbs_border_hov_color_top_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Card_Crumbs.card_crumbs_border_hov_color_top_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0.3 }
        );
        setDB_LeftSidebar_MenuProduktContainer_Hover_BorderColorBottom(
          Design_Card_Crumbs !== undefined &&
            Design_Card_Crumbs.card_crumbs_border_hov_color_bottom !== undefined
            ? Design_Card_Crumbs.card_crumbs_border_hov_color_bottom
            : "rgba(0,0,0,0.3)"
        );
        setDB_LeftSidebar_MenuProduktContainer_Hover_BorderColorBottomNotEncoded(
          Design_Card_Crumbs !== undefined &&
            Design_Card_Crumbs.card_crumbs_border_hov_color_bottom_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Card_Crumbs.card_crumbs_border_hov_color_bottom_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0.3 }
        );

        setDB_LeftSidebar_MenuProduktContainer_Hover_BorderLeft(
          Design_Card_Crumbs !== undefined &&
            Design_Card_Crumbs.card_crumbs_border_hov_pixel_left !== undefined
            ? Design_Card_Crumbs.card_crumbs_border_hov_pixel_left
            : "1"
        );
        setDB_LeftSidebar_MenuProduktContainer_Hover_BorderRight(
          Design_Card_Crumbs !== undefined &&
            Design_Card_Crumbs.card_crumbs_border_hov_pixel_right !== undefined
            ? Design_Card_Crumbs.card_crumbs_border_hov_pixel_right
            : "1"
        );
        setDB_LeftSidebar_MenuProduktContainer_Hover_BorderTop(
          Design_Card_Crumbs !== undefined &&
            Design_Card_Crumbs.card_crumbs_border_hov_pixel_top !== undefined
            ? Design_Card_Crumbs.card_crumbs_border_hov_pixel_top
            : "1"
        );
        setDB_LeftSidebar_MenuProduktContainer_Hover_BorderBottom(
          Design_Card_Crumbs !== undefined &&
            Design_Card_Crumbs.card_crumbs_border_hov_pixel_bottom !== undefined
            ? Design_Card_Crumbs.card_crumbs_border_hov_pixel_bottom
            : "1"
        );

        setDB_LeftSidebar_MenuItem_MenuProduktContainer_Hover_BorderTopLeftRadius(
          Design_Card_Crumbs !== undefined &&
            Design_Card_Crumbs.card_crumbs_border_hov_radius_top_left !==
              undefined
            ? Design_Card_Crumbs.card_crumbs_border_hov_radius_top_left
            : "7"
        );
        setDB_LeftSidebar_MenuItem_MenuProduktContainer_Hover_BorderTopRightRadius(
          Design_Card_Crumbs !== undefined &&
            Design_Card_Crumbs.card_crumbs_border_hov_radius_top_right !==
              undefined
            ? Design_Card_Crumbs.card_crumbs_border_hov_radius_top_right
            : "7"
        );
        setDB_LeftSidebar_MenuItem_MenuProduktContainer_Hover_BorderBottomLeftRadius(
          Design_Card_Crumbs !== undefined &&
            Design_Card_Crumbs.card_crumbs_border_hov_radius_bottom_left !==
              undefined
            ? Design_Card_Crumbs.card_crumbs_border_hov_radius_bottom_left
            : "7"
        );
        setDB_LeftSidebar_MenuItem_MenuProduktContainer_Hover_BorderBottomRightRadius(
          Design_Card_Crumbs !== undefined &&
            Design_Card_Crumbs.card_crumbs_border_hov_radius_bottom_right !==
              undefined
            ? Design_Card_Crumbs.card_crumbs_border_hov_radius_bottom_right
            : "7"
        );

        setDB_LeftSidebar_MenuProduktContainer_Hover_BorderStyleLeft(
          Design_Card_Crumbs !== undefined &&
            Design_Card_Crumbs.card_crumbs_border_hov_style_left !== undefined
            ? Design_Card_Crumbs.card_crumbs_border_hov_style_left
            : "solid"
        );
        setDB_LeftSidebar_MenuProduktContainer_Hover_BorderStyleRight(
          Design_Card_Crumbs !== undefined &&
            Design_Card_Crumbs.card_crumbs_border_hov_style_right !== undefined
            ? Design_Card_Crumbs.card_crumbs_border_hov_style_right
            : "solid"
        );
        setDB_LeftSidebar_MenuProduktContainer_Hover_BorderStyleTop(
          Design_Card_Crumbs !== undefined &&
            Design_Card_Crumbs.card_crumbs_border_hov_style_top !== undefined
            ? Design_Card_Crumbs.card_crumbs_border_hov_style_top
            : "solid"
        );
        setDB_LeftSidebar_MenuProduktContainer_Hover_BorderStyleBottom(
          Design_Card_Crumbs !== undefined &&
            Design_Card_Crumbs.card_crumbs_border_hov_style_bottom !== undefined
            ? Design_Card_Crumbs.card_crumbs_border_hov_style_bottom
            : "solid"
        );

        setDB_LeftSidebar_MenuItem_BreadCrumbs_FontColor(
          Design_Card_Crumbs !== undefined &&
            Design_Card_Crumbs.card_crumbs_bread_schriftfarbe !== undefined
            ? Design_Card_Crumbs.card_crumbs_bread_schriftfarbe
            : "rgba(255,255,255,1)"
        );
        setDB_LeftSidebar_MenuItem_BreadCrumbs_FontColorNotEncoded(
          Design_Card_Crumbs !== undefined &&
            Design_Card_Crumbs.card_crumbs_bread_schriftfarbe_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Card_Crumbs.card_crumbs_bread_schriftfarbe_not_encoded
              )
            : { r: 255, g: 255, b: 255, a: 1 }
        );
        setDB_LeftSidebar_MenuItem_BreadCrumbs_BackgroundColor(
          Design_Card_Crumbs !== undefined &&
            Design_Card_Crumbs.card_crumbs_bread_hintergrundfarbe !== undefined
            ? Design_Card_Crumbs.card_crumbs_bread_hintergrundfarbe
            : "rgba(0,0,0,0.15)"
        );
        setDB_LeftSidebar_MenuItem_BreadCrumbs_BackgroundColorNotEncoded(
          Design_Card_Crumbs !== undefined &&
            Design_Card_Crumbs.card_crumbs_bread_hintergrundfarbe_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Card_Crumbs.card_crumbs_bread_hintergrundfarbe_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0.15 }
        );
        setDB_LeftSidebar_MenuItem_BreadCrumbs_ShadowColor(
          Design_Card_Crumbs !== undefined &&
            Design_Card_Crumbs.card_crumbs_bread_schattenfarbe !== undefined
            ? Design_Card_Crumbs.card_crumbs_bread_schattenfarbe
            : "rgba(0,0,0,0)"
        );
        setDB_LeftSidebar_MenuItem_BreadCrumbs_ShadowColorNotEncoded(
          Design_Card_Crumbs !== undefined &&
            Design_Card_Crumbs.card_crumbs_bread_schattenfarbe_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Card_Crumbs.card_crumbs_bread_schattenfarbe_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0 }
        );
        setDB_LeftSidebar_MenuItem_BreadCrumbs_ShadowHorizontal(
          Design_Card_Crumbs !== undefined &&
            Design_Card_Crumbs.card_crumbs_bread_schattenposition_horizontal !==
              undefined
            ? Design_Card_Crumbs.card_crumbs_bread_schattenposition_horizontal
            : "0"
        );
        setDB_LeftSidebar_MenuItem_BreadCrumbs_ShadowVertical(
          Design_Card_Crumbs !== undefined &&
            Design_Card_Crumbs.card_crumbs_bread_schattenposition_vertikal !==
              undefined
            ? Design_Card_Crumbs.card_crumbs_bread_schattenposition_vertikal
            : "0"
        );
        setDB_LeftSidebar_MenuItem_BreadCrumbs_ShadowBlur(
          Design_Card_Crumbs !== undefined &&
            Design_Card_Crumbs.card_crumbs_bread_schattenrauschen !== undefined
            ? Design_Card_Crumbs.card_crumbs_bread_schattenrauschen
            : "0"
        );
        setDB_LeftSidebar_MenuItem_BreadCrumbs_FontSize(
          Design_Card_Crumbs !== undefined &&
            Design_Card_Crumbs.card_crumbs_bread_schriftgroesse !== undefined
            ? Design_Card_Crumbs.card_crumbs_bread_schriftgroesse
            : "14"
        );
        setDB_LeftSidebar_MenuItem_BreadCrumbs_FontWeight(
          Design_Card_Crumbs !== undefined &&
            Design_Card_Crumbs.card_crumbs_bread_schriftgewicht !== undefined
            ? Design_Card_Crumbs.card_crumbs_bread_schriftgewicht
            : "100"
        );
        setDB_LeftSidebar_MenuItem_BreadCrumbs_letterSpacing(
          Design_Card_Crumbs !== undefined &&
            Design_Card_Crumbs.card_crumbs_bread_buchstabenabstand !== undefined
            ? Design_Card_Crumbs.card_crumbs_bread_buchstabenabstand
            : "0"
        );
        setDB_LeftSidebar_MenuItem_BreadCrumbs_TextDecoration(
          Design_Card_Crumbs !== undefined &&
            Design_Card_Crumbs.card_crumbs_bread_text_dekoration !== undefined
            ? Design_Card_Crumbs.card_crumbs_bread_text_dekoration
            : "none"
        );
        setDB_LeftSidebar_MenuItem_BreadCrumbs_TextDecorationStyle(
          Design_Card_Crumbs !== undefined &&
            Design_Card_Crumbs.card_crumbs_bread_text_dekoration_stil !==
              undefined
            ? Design_Card_Crumbs.card_crumbs_bread_text_dekoration_stil
            : "none"
        );
        setDB_LeftSidebar_MenuItem_BreadCrumbs_marginLeftRight(
          Design_Card_Crumbs !== undefined &&
            Design_Card_Crumbs.card_crumbs_bread_aussen_abstand_links_rechts !==
              undefined
            ? Design_Card_Crumbs.card_crumbs_bread_aussen_abstand_links_rechts
            : "4"
        );
        setDB_LeftSidebar_MenuItem_BreadCrumbs_marginTopBottom(
          Design_Card_Crumbs !== undefined &&
            Design_Card_Crumbs.card_crumbs_bread_aussen_abstand_oben_unten !==
              undefined
            ? Design_Card_Crumbs.card_crumbs_bread_aussen_abstand_oben_unten
            : "4"
        );
        setDB_LeftSidebar_MenuItem_BreadCrumbs_paddingLeftRight(
          Design_Card_Crumbs !== undefined &&
            Design_Card_Crumbs.card_crumbs_bread_innerer_abstand_links_rechts !==
              undefined
            ? Design_Card_Crumbs.card_crumbs_bread_innerer_abstand_links_rechts
            : "7"
        );
        setDB_LeftSidebar_MenuItem_BreadCrumbs_paddingTopBottom(
          Design_Card_Crumbs !== undefined &&
            Design_Card_Crumbs.card_crumbs_bread_innerer_abstand_oben_unten !==
              undefined
            ? Design_Card_Crumbs.card_crumbs_bread_innerer_abstand_oben_unten
            : "0"
        );
        setDB_LeftSidebar_MenuItem_BreadCrumbs_TextAlign(
          Design_Card_Crumbs !== undefined &&
            Design_Card_Crumbs.card_crumbs_bread_textausrichtung !== undefined
            ? Design_Card_Crumbs.card_crumbs_bread_textausrichtung
            : "center"
        );

        setDB_fontFace_SocialTitelFontFamily_FontFamilyName(
          Design_Card_Crumbs !== undefined &&
            Design_Card_Crumbs.card_crumbs_bread_fontfamily !== undefined
            ? Design_Card_Crumbs.card_crumbs_bread_fontfamily
            : "Lato"
        );

        setDB_fontFace_SocialTitelFontFamily_FontFamilySrc(
          Design_Card_Crumbs !== undefined &&
            Design_Card_Crumbs.card_crumbs_bread_fontfamilysrc !== undefined
            ? Design_Card_Crumbs.card_crumbs_bread_fontfamilysrc
            : "/assets/Lato/Lato-Regular.ttf"
        );

        setDB_LeftSidebar_MenuItem_BreadCrumbs_Hover_FontColor(
          Design_Card_Crumbs !== undefined &&
            Design_Card_Crumbs.card_crumbs_bread_hov_schriftfarbe !== undefined
            ? Design_Card_Crumbs.card_crumbs_bread_hov_schriftfarbe
            : "rgba(255,255,255,1)"
        );
        setDB_LeftSidebar_MenuItem_BreadCrumbs_Hover_FontColorNotEncoded(
          Design_Card_Crumbs !== undefined &&
            Design_Card_Crumbs.card_crumbs_bread_hov_schriftfarbe_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Card_Crumbs.card_crumbs_bread_hov_schriftfarbe_not_encoded
              )
            : { r: 255, g: 255, b: 255, a: 1 }
        );
        setDB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BackgroundColor(
          Design_Card_Crumbs !== undefined &&
            Design_Card_Crumbs.card_crumbs_bread_hov_hintergrundfarbe !==
              undefined
            ? Design_Card_Crumbs.card_crumbs_bread_hov_hintergrundfarbe
            : "rgba(0,0,0,0.3)"
        );
        setDB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BackgroundColorNotEncoded(
          Design_Card_Crumbs !== undefined &&
            Design_Card_Crumbs.card_crumbs_bread_hov_hintergrundfarbe_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Card_Crumbs.card_crumbs_bread_hov_hintergrundfarbe_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0.3 }
        );
        setDB_LeftSidebar_MenuItem_BreadCrumbs_ShadowColor_Hover(
          Design_Card_Crumbs !== undefined &&
            Design_Card_Crumbs.card_crumbs_bread_hov_schattenfarbe !== undefined
            ? Design_Card_Crumbs.card_crumbs_bread_hov_schattenfarbe
            : "rgba(0,0,0,0)"
        );
        setDB_LeftSidebar_MenuItem_BreadCrumbs_ShadowColorNotEncoded_Hover(
          Design_Card_Crumbs !== undefined &&
            Design_Card_Crumbs.card_crumbs_bread_hov_schattenfarbe_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Card_Crumbs.card_crumbs_bread_hov_schattenfarbe_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0 }
        );
        setDB_LeftSidebar_MenuItem_BreadCrumbs_ShadowHorizontal_Hover(
          Design_Card_Crumbs !== undefined &&
            Design_Card_Crumbs.card_crumbs_bread_hov_schattenposition_horizontal !==
              undefined
            ? Design_Card_Crumbs.card_crumbs_bread_hov_schattenposition_horizontal
            : "0"
        );
        setDB_LeftSidebar_MenuItem_BreadCrumbs_ShadowVertical_Hover(
          Design_Card_Crumbs !== undefined &&
            Design_Card_Crumbs.card_crumbs_bread_hov_schattenposition_vertikal !==
              undefined
            ? Design_Card_Crumbs.card_crumbs_bread_hov_schattenposition_vertikal
            : "0"
        );
        setDB_LeftSidebar_MenuItem_BreadCrumbs_ShadowBlur_Hover(
          Design_Card_Crumbs !== undefined &&
            Design_Card_Crumbs.card_crumbs_bread_hov_schattenrauschen !==
              undefined
            ? Design_Card_Crumbs.card_crumbs_bread_hov_schattenrauschen
            : "0"
        );
        setDB_LeftSidebar_MenuItem_BreadCrumbs_Hover_FontSize(
          Design_Card_Crumbs !== undefined &&
            Design_Card_Crumbs.card_crumbs_bread_hov_schriftgroesse !==
              undefined
            ? Design_Card_Crumbs.card_crumbs_bread_hov_schriftgroesse
            : "14"
        );
        setDB_LeftSidebar_MenuItem_BreadCrumbs_Hover_FontWeight(
          Design_Card_Crumbs !== undefined &&
            Design_Card_Crumbs.card_crumbs_bread_hov_schriftgewicht !==
              undefined
            ? Design_Card_Crumbs.card_crumbs_bread_hov_schriftgewicht
            : "100"
        );
        setDB_LeftSidebar_MenuItem_BreadCrumbs_letterSpacing_Hover(
          Design_Card_Crumbs !== undefined &&
            Design_Card_Crumbs.card_crumbs_bread_hov_buchstabenabstand !==
              undefined
            ? Design_Card_Crumbs.card_crumbs_bread_hov_buchstabenabstand
            : "0"
        );
        setDB_LeftSidebar_MenuItem_BreadCrumbs_TextDecoration_Hover(
          Design_Card_Crumbs !== undefined &&
            Design_Card_Crumbs.card_crumbs_bread_hov_text_dekoration !==
              undefined
            ? Design_Card_Crumbs.card_crumbs_bread_hov_text_dekoration
            : "none"
        );
        setDB_LeftSidebar_MenuItem_BreadCrumbs_TextDecorationStyle_Hover(
          Design_Card_Crumbs !== undefined &&
            Design_Card_Crumbs.card_crumbs_bread_hov_text_dekoration_stil !==
              undefined
            ? Design_Card_Crumbs.card_crumbs_bread_hov_text_dekoration_stil
            : "none"
        );
        setDB_LeftSidebar_MenuItem_BreadCrumbs_marginLeftRight_Hover(
          Design_Card_Crumbs !== undefined &&
            Design_Card_Crumbs.card_crumbs_bread_hov_aussen_abstand_links_rechts !==
              undefined
            ? Design_Card_Crumbs.card_crumbs_bread_hov_aussen_abstand_links_rechts
            : "4"
        );
        setDB_LeftSidebar_MenuItem_BreadCrumbs_marginTopBottom_Hover(
          Design_Card_Crumbs !== undefined &&
            Design_Card_Crumbs.card_crumbs_bread_hov_aussen_abstand_oben_unten !==
              undefined
            ? Design_Card_Crumbs.card_crumbs_bread_hov_aussen_abstand_oben_unten
            : "4"
        );
        setDB_LeftSidebar_MenuItem_BreadCrumbs_paddingLeftRight_Hover(
          Design_Card_Crumbs !== undefined &&
            Design_Card_Crumbs.card_crumbs_bread_hov_innerer_abstand_links_rechts !==
              undefined
            ? Design_Card_Crumbs.card_crumbs_bread_hov_innerer_abstand_links_rechts
            : "7"
        );
        setDB_LeftSidebar_MenuItem_BreadCrumbs_paddingTopBottom_Hover(
          Design_Card_Crumbs !== undefined &&
            Design_Card_Crumbs.card_crumbs_bread_hov_innerer_abstand_oben_unten !==
              undefined
            ? Design_Card_Crumbs.card_crumbs_bread_hov_innerer_abstand_oben_unten
            : "0"
        );
        setDB_LeftSidebar_MenuItem_BreadCrumbs_Hover_TextAlign(
          Design_Card_Crumbs !== undefined &&
            Design_Card_Crumbs.card_crumbs_bread_hov_textausrichtung !==
              undefined
            ? Design_Card_Crumbs.card_crumbs_bread_hov_textausrichtung
            : "center"
        );

        setDB_LeftSidebar_MenuItem_BreadCrumbs_BorderColorLeft(
          Design_Card_Crumbs !== undefined &&
            Design_Card_Crumbs.card_crumbs_bread_border_color_left !== undefined
            ? Design_Card_Crumbs.card_crumbs_bread_border_color_left
            : "rgba(0,0,0,0.3)"
        );
        setDB_LeftSidebar_MenuItem_BreadCrumbs_BorderColorLeftNotEncoded(
          Design_Card_Crumbs !== undefined &&
            Design_Card_Crumbs.card_crumbs_bread_border_color_left_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Card_Crumbs.card_crumbs_bread_border_color_left_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0.3 }
        );
        setDB_LeftSidebar_MenuItem_BreadCrumbs_BorderColorRight(
          Design_Card_Crumbs !== undefined &&
            Design_Card_Crumbs.card_crumbs_bread_border_color_right !==
              undefined
            ? Design_Card_Crumbs.card_crumbs_bread_border_color_right
            : "rgba(0,0,0,0.3)"
        );
        setDB_LeftSidebar_MenuItem_BreadCrumbs_BorderColorRightNotEncoded(
          Design_Card_Crumbs !== undefined &&
            Design_Card_Crumbs.card_crumbs_bread_border_color_right_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Card_Crumbs.card_crumbs_bread_border_color_right_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0.3 }
        );
        setDB_LeftSidebar_MenuItem_BreadCrumbs_BorderColorTop(
          Design_Card_Crumbs !== undefined &&
            Design_Card_Crumbs.card_crumbs_bread_border_color_top !== undefined
            ? Design_Card_Crumbs.card_crumbs_bread_border_color_top
            : "rgba(0,0,0,0.3)"
        );
        setDB_LeftSidebar_MenuItem_BreadCrumbs_BorderColorTopNotEncoded(
          Design_Card_Crumbs !== undefined &&
            Design_Card_Crumbs.card_crumbs_bread_border_color_top_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Card_Crumbs.card_crumbs_bread_border_color_top_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0.3 }
        );
        setDB_LeftSidebar_MenuItem_BreadCrumbs_BorderColorBottom(
          Design_Card_Crumbs !== undefined &&
            Design_Card_Crumbs.card_crumbs_bread_border_color_bottom !==
              undefined
            ? Design_Card_Crumbs.card_crumbs_bread_border_color_bottom
            : "rgba(0,0,0,0.3)"
        );
        setDB_LeftSidebar_MenuItem_BreadCrumbs_BorderColorBottomNotEncoded(
          Design_Card_Crumbs !== undefined &&
            Design_Card_Crumbs.card_crumbs_bread_border_color_bottom_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Card_Crumbs.card_crumbs_bread_border_color_bottom_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0.3 }
        );

        setDB_LeftSidebar_MenuItem_BreadCrumbs_BorderLeft(
          Design_Card_Crumbs !== undefined &&
            Design_Card_Crumbs.card_crumbs_bread_border_pixel_left !== undefined
            ? Design_Card_Crumbs.card_crumbs_bread_border_pixel_left
            : "1"
        );
        setDB_LeftSidebar_MenuItem_BreadCrumbs_BorderRight(
          Design_Card_Crumbs !== undefined &&
            Design_Card_Crumbs.card_crumbs_bread_border_pixel_right !==
              undefined
            ? Design_Card_Crumbs.card_crumbs_bread_border_pixel_right
            : "1"
        );
        setDB_LeftSidebar_MenuItem_BreadCrumbs_BorderTop(
          Design_Card_Crumbs !== undefined &&
            Design_Card_Crumbs.card_crumbs_bread_border_pixel_top !== undefined
            ? Design_Card_Crumbs.card_crumbs_bread_border_pixel_top
            : "1"
        );
        setDB_LeftSidebar_MenuItem_BreadCrumbs_BorderBottom(
          Design_Card_Crumbs !== undefined &&
            Design_Card_Crumbs.card_crumbs_bread_border_pixel_bottom !==
              undefined
            ? Design_Card_Crumbs.card_crumbs_bread_border_pixel_bottom
            : "1"
        );

        setDB_LeftSidebar_MenuItem_BreadCrumbs_BorderTopLeftRadius(
          Design_Card_Crumbs !== undefined &&
            Design_Card_Crumbs.card_crumbs_bread_border_radius_top_left !==
              undefined
            ? Design_Card_Crumbs.card_crumbs_bread_border_radius_top_left
            : "5"
        );
        setDB_LeftSidebar_MenuItem_BreadCrumbs_BorderTopRightRadius(
          Design_Card_Crumbs !== undefined &&
            Design_Card_Crumbs.card_crumbs_bread_border_radius_top_right !==
              undefined
            ? Design_Card_Crumbs.card_crumbs_bread_border_radius_top_right
            : "5"
        );
        setDB_LeftSidebar_MenuItem_BreadCrumbs_BorderBottomLeftRadius(
          Design_Card_Crumbs !== undefined &&
            Design_Card_Crumbs.card_crumbs_bread_border_radius_bottom_left !==
              undefined
            ? Design_Card_Crumbs.card_crumbs_bread_border_radius_bottom_left
            : "5"
        );
        setDB_LeftSidebar_MenuItem_BreadCrumbs_BorderBottomRightRadius(
          Design_Card_Crumbs !== undefined &&
            Design_Card_Crumbs.card_crumbs_bread_border_radius_bottom_right !==
              undefined
            ? Design_Card_Crumbs.card_crumbs_bread_border_radius_bottom_right
            : "5"
        );

        setDB_LeftSidebar_MenuItem_BreadCrumbs_BorderStyleLeft(
          Design_Card_Crumbs !== undefined &&
            Design_Card_Crumbs.card_crumbs_bread_border_style_left !== undefined
            ? Design_Card_Crumbs.card_crumbs_bread_border_style_left
            : "solid"
        );
        setDB_LeftSidebar_MenuItem_BreadCrumbs_BorderStyleRight(
          Design_Card_Crumbs !== undefined &&
            Design_Card_Crumbs.card_crumbs_bread_border_style_right !==
              undefined
            ? Design_Card_Crumbs.card_crumbs_bread_border_style_right
            : "solid"
        );
        setDB_LeftSidebar_MenuItem_BreadCrumbs_BorderStyleTop(
          Design_Card_Crumbs !== undefined &&
            Design_Card_Crumbs.card_crumbs_bread_border_style_top !== undefined
            ? Design_Card_Crumbs.card_crumbs_bread_border_style_top
            : "solid"
        );
        setDB_LeftSidebar_MenuItem_BreadCrumbs_BorderStyleBottom(
          Design_Card_Crumbs !== undefined &&
            Design_Card_Crumbs.card_crumbs_bread_border_style_bottom !==
              undefined
            ? Design_Card_Crumbs.card_crumbs_bread_border_style_bottom
            : "solid"
        );

        setDB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderColorLeft(
          Design_Card_Crumbs !== undefined &&
            Design_Card_Crumbs.card_crumbs_bread_border_hov_color_left !==
              undefined
            ? Design_Card_Crumbs.card_crumbs_bread_border_hov_color_left
            : "rgba(0,0,0,0.3)"
        );
        setDB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderColorLeftNotEncoded(
          Design_Card_Crumbs !== undefined &&
            Design_Card_Crumbs.card_crumbs_bread_border_hov_color_left_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Card_Crumbs.card_crumbs_bread_border_hov_color_left_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0.3 }
        );
        setDB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderColorRight(
          Design_Card_Crumbs !== undefined &&
            Design_Card_Crumbs.card_crumbs_bread_border_hov_color_right !==
              undefined
            ? Design_Card_Crumbs.card_crumbs_bread_border_hov_color_right
            : "rgba(0,0,0,0.3)"
        );
        setDB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderColorRightNotEncoded(
          Design_Card_Crumbs !== undefined &&
            Design_Card_Crumbs.card_crumbs_bread_border_hov_color_right_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Card_Crumbs.card_crumbs_bread_border_hov_color_right_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0.3 }
        );
        setDB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderColorTop(
          Design_Card_Crumbs !== undefined &&
            Design_Card_Crumbs.card_crumbs_bread_border_hov_color_top !==
              undefined
            ? Design_Card_Crumbs.card_crumbs_bread_border_hov_color_top
            : "rgba(0,0,0,0.3)"
        );
        setDB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderColorTopNotEncoded(
          Design_Card_Crumbs !== undefined &&
            Design_Card_Crumbs.card_crumbs_bread_border_hov_color_top_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Card_Crumbs.card_crumbs_bread_border_hov_color_top_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0.3 }
        );
        setDB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderColorBottom(
          Design_Card_Crumbs !== undefined &&
            Design_Card_Crumbs.card_crumbs_bread_border_hov_color_bottom !==
              undefined
            ? Design_Card_Crumbs.card_crumbs_bread_border_hov_color_bottom
            : "rgba(0,0,0,0.3)"
        );
        setDB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderColorBottomNotEncoded(
          Design_Card_Crumbs !== undefined &&
            Design_Card_Crumbs.card_crumbs_bread_border_hov_color_bottom_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Card_Crumbs.card_crumbs_bread_border_hov_color_bottom_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0.3 }
        );

        setDB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderLeft(
          Design_Card_Crumbs !== undefined &&
            Design_Card_Crumbs.card_crumbs_bread_border_hov_pixel_left !==
              undefined
            ? Design_Card_Crumbs.card_crumbs_bread_border_hov_pixel_left
            : "1"
        );
        setDB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderRight(
          Design_Card_Crumbs !== undefined &&
            Design_Card_Crumbs.card_crumbs_bread_border_hov_pixel_right !==
              undefined
            ? Design_Card_Crumbs.card_crumbs_bread_border_hov_pixel_right
            : "1"
        );
        setDB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderTop(
          Design_Card_Crumbs !== undefined &&
            Design_Card_Crumbs.card_crumbs_bread_border_hov_pixel_top !==
              undefined
            ? Design_Card_Crumbs.card_crumbs_bread_border_hov_pixel_top
            : "1"
        );
        setDB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderBottom(
          Design_Card_Crumbs !== undefined &&
            Design_Card_Crumbs.card_crumbs_bread_border_hov_pixel_bottom !==
              undefined
            ? Design_Card_Crumbs.card_crumbs_bread_border_hov_pixel_bottom
            : "1"
        );

        setDB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderTopLeftRadius(
          Design_Card_Crumbs !== undefined &&
            Design_Card_Crumbs.card_crumbs_bread_border_hov_radius_top_left !==
              undefined
            ? Design_Card_Crumbs.card_crumbs_bread_border_hov_radius_top_left
            : "5"
        );
        setDB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderTopRightRadius(
          Design_Card_Crumbs !== undefined &&
            Design_Card_Crumbs.card_crumbs_bread_border_hov_radius_top_right !==
              undefined
            ? Design_Card_Crumbs.card_crumbs_bread_border_hov_radius_top_right
            : "5"
        );
        setDB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderBottomLeftRadius(
          Design_Card_Crumbs !== undefined &&
            Design_Card_Crumbs.card_crumbs_bread_border_hov_radius_bottom_left !==
              undefined
            ? Design_Card_Crumbs.card_crumbs_bread_border_hov_radius_bottom_left
            : "5"
        );
        setDB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderBottomRightRadius(
          Design_Card_Crumbs !== undefined &&
            Design_Card_Crumbs.card_crumbs_bread_border_hov_radius_bottom_right !==
              undefined
            ? Design_Card_Crumbs.card_crumbs_bread_border_hov_radius_bottom_right
            : "5"
        );

        setDB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderStyleLeft(
          Design_Card_Crumbs !== undefined &&
            Design_Card_Crumbs.card_crumbs_bread_border_hov_style_left !==
              undefined
            ? Design_Card_Crumbs.card_crumbs_bread_border_hov_style_left
            : "solid"
        );
        setDB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderStyleRight(
          Design_Card_Crumbs !== undefined &&
            Design_Card_Crumbs.card_crumbs_bread_border_hov_style_right !==
              undefined
            ? Design_Card_Crumbs.card_crumbs_bread_border_hov_style_right
            : "solid"
        );
        setDB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderStyleTop(
          Design_Card_Crumbs !== undefined &&
            Design_Card_Crumbs.card_crumbs_bread_border_hov_style_top !==
              undefined
            ? Design_Card_Crumbs.card_crumbs_bread_border_hov_style_top
            : "solid"
        );
        setDB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderStyleBottom(
          Design_Card_Crumbs !== undefined &&
            Design_Card_Crumbs.card_crumbs_bread_border_hov_style_bottom !==
              undefined
            ? Design_Card_Crumbs.card_crumbs_bread_border_hov_style_bottom
            : "solid"
        );

        setDB_LeftSidebar_MenuItem_HauptTitel_FontColor(
          Design_Card_Crumbs !== undefined &&
            Design_Card_Crumbs.card_main_title_schriftfarbe !== undefined
            ? Design_Card_Crumbs.card_main_title_schriftfarbe
            : "rgba(255,255,255,1)"
        );
        setDB_LeftSidebar_MenuItem_HauptTitel_FontColorNotEncoded(
          Design_Card_Crumbs !== undefined &&
            Design_Card_Crumbs.card_main_title_schriftfarbe_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Card_Crumbs.card_main_title_schriftfarbe_not_encoded
              )
            : { r: 255, g: 255, b: 255, a: 1 }
        );
        setDB_LeftSidebar_MenuItem_HauptTitel_BackgroundColor(
          Design_Card_Crumbs !== undefined &&
            Design_Card_Crumbs.card_main_title_hintergrundfarbe !== undefined
            ? Design_Card_Crumbs.card_main_title_hintergrundfarbe
            : "rgba(0,0,0,0)"
        );
        setDB_LeftSidebar_MenuItem_HauptTitel_BackgroundColorNotEncoded(
          Design_Card_Crumbs !== undefined &&
            Design_Card_Crumbs.card_main_title_hintergrundfarbe_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Card_Crumbs.card_main_title_hintergrundfarbe_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0 }
        );
        setDB_LeftSidebar_MenuItem_HauptTitel_ShadowColor(
          Design_Card_Crumbs !== undefined &&
            Design_Card_Crumbs.card_main_title_schattenfarbe !== undefined
            ? Design_Card_Crumbs.card_main_title_schattenfarbe
            : "rgba(0,0,0,1)"
        );
        setDB_LeftSidebar_MenuItem_HauptTitel_ShadowColorNotEncoded(
          Design_Card_Crumbs !== undefined &&
            Design_Card_Crumbs.card_main_title_schattenfarbe_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Card_Crumbs.card_main_title_schattenfarbe_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 1 }
        );
        setDB_LeftSidebar_MenuItem_HauptTitel_ShadowHorizontal(
          Design_Card_Crumbs !== undefined &&
            Design_Card_Crumbs.card_main_title_schattenposition_horizontal !==
              undefined
            ? Design_Card_Crumbs.card_main_title_schattenposition_horizontal
            : "2"
        );
        setDB_LeftSidebar_MenuItem_HauptTitel_ShadowVertical(
          Design_Card_Crumbs !== undefined &&
            Design_Card_Crumbs.card_main_title_schattenposition_vertikal !==
              undefined
            ? Design_Card_Crumbs.card_main_title_schattenposition_vertikal
            : "2"
        );
        setDB_LeftSidebar_MenuItem_HauptTitel_ShadowBlur(
          Design_Card_Crumbs !== undefined &&
            Design_Card_Crumbs.card_main_title_schattenrauschen !== undefined
            ? Design_Card_Crumbs.card_main_title_schattenrauschen
            : "3"
        );
        setDB_LeftSidebar_MenuItem_HauptTitel_FontSize(
          Design_Card_Crumbs !== undefined &&
            Design_Card_Crumbs.card_main_title_schriftgroesse !== undefined
            ? Design_Card_Crumbs.card_main_title_schriftgroesse
            : "20"
        );
        setDB_LeftSidebar_MenuItem_HauptTitel_FontWeight(
          Design_Card_Crumbs !== undefined &&
            Design_Card_Crumbs.card_main_title_schriftgewicht !== undefined
            ? Design_Card_Crumbs.card_main_title_schriftgewicht
            : "100"
        );
        setDB_LeftSidebar_MenuItem_HauptTitel_letterSpacing(
          Design_Card_Crumbs !== undefined &&
            Design_Card_Crumbs.card_main_title_buchstabenabstand !== undefined
            ? Design_Card_Crumbs.card_main_title_buchstabenabstand
            : "0"
        );
        setDB_LeftSidebar_MenuItem_HauptTitel_TextDecoration(
          Design_Card_Crumbs !== undefined &&
            Design_Card_Crumbs.card_main_title_text_dekoration !== undefined
            ? Design_Card_Crumbs.card_main_title_text_dekoration
            : "none"
        );
        setDB_LeftSidebar_MenuItem_HauptTitel_TextDecorationStyle(
          Design_Card_Crumbs !== undefined &&
            Design_Card_Crumbs.card_main_title_text_dekoration_stil !==
              undefined
            ? Design_Card_Crumbs.card_main_title_text_dekoration_stil
            : "none"
        );
        setDB_LeftSidebar_MenuItem_HauptTitel_marginLeftRight(
          Design_Card_Crumbs !== undefined &&
            Design_Card_Crumbs.card_main_title_aussen_abstand_links_rechts !==
              undefined
            ? Design_Card_Crumbs.card_main_title_aussen_abstand_links_rechts
            : "7"
        );
        setDB_LeftSidebar_MenuItem_HauptTitel_marginTopBottom(
          Design_Card_Crumbs !== undefined &&
            Design_Card_Crumbs.card_main_title_aussen_abstand_oben_unten !==
              undefined
            ? Design_Card_Crumbs.card_main_title_aussen_abstand_oben_unten
            : "-10"
        );
        setDB_LeftSidebar_MenuItem_HauptTitel_paddingLeftRight(
          Design_Card_Crumbs !== undefined &&
            Design_Card_Crumbs.card_main_title_innerer_abstand_links_rechts !==
              undefined
            ? Design_Card_Crumbs.card_main_title_innerer_abstand_links_rechts
            : "0"
        );
        setDB_LeftSidebar_MenuItem_HauptTitel_paddingTopBottom(
          Design_Card_Crumbs !== undefined &&
            Design_Card_Crumbs.card_main_title_innerer_abstand_oben_unten !==
              undefined
            ? Design_Card_Crumbs.card_main_title_innerer_abstand_oben_unten
            : "0"
        );
        setDB_LeftSidebar_MenuItem_HauptTitel_TextAlign(
          Design_Card_Crumbs !== undefined &&
            Design_Card_Crumbs.card_main_title_textausrichtung !== undefined
            ? Design_Card_Crumbs.card_main_title_textausrichtung
            : "left"
        );

        setDB_fontFace_chooseFontFamilyProduct_FontFamilyName(
          Design_Card_Crumbs !== undefined &&
            Design_Card_Crumbs.card_main_title_fontfamily !== undefined
            ? Design_Card_Crumbs.card_main_title_fontfamily
            : "Lato"
        );

        setDB_fontFace_chooseFontFamilyProduct_FontFamilySrc(
          Design_Card_Crumbs !== undefined &&
            Design_Card_Crumbs.card_main_title_fontfamilysrc !== undefined
            ? Design_Card_Crumbs.card_main_title_fontfamilysrc
            : "/assets/Lato/Lato-Regular.ttf"
        );

        setDB_LeftSidebar_MenuItem_HauptTitel_Hover_FontColor(
          Design_Card_Crumbs !== undefined &&
            Design_Card_Crumbs.card_main_title_hov_schriftfarbe !== undefined
            ? Design_Card_Crumbs.card_main_title_hov_schriftfarbe
            : "rgba(255,255,255,1)"
        );
        setDB_LeftSidebar_MenuItem_HauptTitel_Hover_FontColorNotEncoded(
          Design_Card_Crumbs !== undefined &&
            Design_Card_Crumbs.card_main_title_hov_schriftfarbe_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Card_Crumbs.card_main_title_hov_schriftfarbe_not_encoded
              )
            : { r: 255, g: 255, b: 255, a: 1 }
        );
        setDB_LeftSidebar_MenuItem_HauptTitel_Hover_BackgroundColor(
          Design_Card_Crumbs !== undefined &&
            Design_Card_Crumbs.card_main_title_hov_hintergrundfarbe !==
              undefined
            ? Design_Card_Crumbs.card_main_title_hov_hintergrundfarbe
            : "rgba(0,0,0,0)"
        );
        setDB_LeftSidebar_MenuItem_HauptTitel_Hover_BackgroundColorNotEncoded(
          Design_Card_Crumbs !== undefined &&
            Design_Card_Crumbs.card_main_title_hov_hintergrundfarbe_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Card_Crumbs.card_main_title_hov_hintergrundfarbe_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0 }
        );
        setDB_LeftSidebar_MenuItem_HauptTitel_ShadowColor_Hover(
          Design_Card_Crumbs !== undefined &&
            Design_Card_Crumbs.card_main_title_hov_schattenfarbe !== undefined
            ? Design_Card_Crumbs.card_main_title_hov_schattenfarbe
            : "rgba(0,0,0,1)"
        );
        setDB_LeftSidebar_MenuItem_HauptTitel_ShadowColorNotEncoded_Hover(
          Design_Card_Crumbs !== undefined &&
            Design_Card_Crumbs.card_main_title_hov_schattenfarbe_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Card_Crumbs.card_main_title_hov_schattenfarbe_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 1 }
        );
        setDB_LeftSidebar_MenuItem_HauptTitel_ShadowHorizontal_Hover(
          Design_Card_Crumbs !== undefined &&
            Design_Card_Crumbs.card_main_title_hov_schattenposition_horizontal !==
              undefined
            ? Design_Card_Crumbs.card_main_title_hov_schattenposition_horizontal
            : "2"
        );
        setDB_LeftSidebar_MenuItem_HauptTitel_ShadowVertical_Hover(
          Design_Card_Crumbs !== undefined &&
            Design_Card_Crumbs.card_main_title_hov_schattenposition_vertikal !==
              undefined
            ? Design_Card_Crumbs.card_main_title_hov_schattenposition_vertikal
            : "2"
        );
        setDB_LeftSidebar_MenuItem_HauptTitel_ShadowBlur_Hover(
          Design_Card_Crumbs !== undefined &&
            Design_Card_Crumbs.card_main_title_hov_schattenrauschen !==
              undefined
            ? Design_Card_Crumbs.card_main_title_hov_schattenrauschen
            : "3"
        );
        setDB_LeftSidebar_MenuItem_HauptTitel_Hover_FontSize(
          Design_Card_Crumbs !== undefined &&
            Design_Card_Crumbs.card_main_title_hov_schriftgroesse !== undefined
            ? Design_Card_Crumbs.card_main_title_hov_schriftgroesse
            : "20"
        );
        setDB_LeftSidebar_MenuItem_HauptTitel_Hover_FontWeight(
          Design_Card_Crumbs !== undefined &&
            Design_Card_Crumbs.card_main_title_hov_schriftgewicht !== undefined
            ? Design_Card_Crumbs.card_main_title_hov_schriftgewicht
            : "100"
        );
        setDB_LeftSidebar_MenuItem_HauptTitel_letterSpacing_Hover(
          Design_Card_Crumbs !== undefined &&
            Design_Card_Crumbs.card_main_title_hov_buchstabenabstand !==
              undefined
            ? Design_Card_Crumbs.card_main_title_hov_buchstabenabstand
            : "0"
        );
        setDB_LeftSidebar_MenuItem_HauptTitel_TextDecoration_Hover(
          Design_Card_Crumbs !== undefined &&
            Design_Card_Crumbs.card_main_title_hov_text_dekoration !== undefined
            ? Design_Card_Crumbs.card_main_title_hov_text_dekoration
            : "none"
        );
        setDB_LeftSidebar_MenuItem_HauptTitel_TextDecorationStyle_Hover(
          Design_Card_Crumbs !== undefined &&
            Design_Card_Crumbs.card_main_title_hov_text_dekoration_stil !==
              undefined
            ? Design_Card_Crumbs.card_main_title_hov_text_dekoration_stil
            : "none"
        );
        setDB_LeftSidebar_MenuItem_HauptTitel_marginLeftRight_Hover(
          Design_Card_Crumbs !== undefined &&
            Design_Card_Crumbs.card_main_title_hov_aussen_abstand_links_rechts !==
              undefined
            ? Design_Card_Crumbs.card_main_title_hov_aussen_abstand_links_rechts
            : "7"
        );
        setDB_LeftSidebar_MenuItem_HauptTitel_marginTopBottom_Hover(
          Design_Card_Crumbs !== undefined &&
            Design_Card_Crumbs.card_main_title_hov_aussen_abstand_oben_unten !==
              undefined
            ? Design_Card_Crumbs.card_main_title_hov_aussen_abstand_oben_unten
            : "-10"
        );
        setDB_LeftSidebar_MenuItem_HauptTitel_paddingLeftRight_Hover(
          Design_Card_Crumbs !== undefined &&
            Design_Card_Crumbs.card_main_title_hov_innerer_abstand_links_rechts !==
              undefined
            ? Design_Card_Crumbs.card_main_title_hov_innerer_abstand_links_rechts
            : "0"
        );
        setDB_LeftSidebar_MenuItem_HauptTitel_paddingTopBottom_Hover(
          Design_Card_Crumbs !== undefined &&
            Design_Card_Crumbs.card_main_title_hov_innerer_abstand_oben_unten !==
              undefined
            ? Design_Card_Crumbs.card_main_title_hov_innerer_abstand_oben_unten
            : "0"
        );
        setDB_LeftSidebar_MenuItem_HauptTitel_Hover_TextAlign(
          Design_Card_Crumbs !== undefined &&
            Design_Card_Crumbs.card_main_title_hov_textausrichtung !== undefined
            ? Design_Card_Crumbs.card_main_title_hov_textausrichtung
            : "left"
        );

        setDB_LeftSidebar_MenuItem_HauptTitel_BorderColorLeft(
          Design_Card_Crumbs !== undefined &&
            Design_Card_Crumbs.card_main_title_border_color_left !== undefined
            ? Design_Card_Crumbs.card_main_title_border_color_left
            : "rgba(0,0,0,0)"
        );
        setDB_LeftSidebar_MenuItem_HauptTitel_BorderColorLeftNotEncoded(
          Design_Card_Crumbs !== undefined &&
            Design_Card_Crumbs.card_main_title_border_color_left_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Card_Crumbs.card_main_title_border_color_left_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0 }
        );
        setDB_LeftSidebar_MenuItem_HauptTitel_BorderColorRight(
          Design_Card_Crumbs !== undefined &&
            Design_Card_Crumbs.card_main_title_border_color_right !== undefined
            ? Design_Card_Crumbs.card_main_title_border_color_right
            : "rgba(0,0,0,0)"
        );
        setDB_LeftSidebar_MenuItem_HauptTitel_BorderColorRightNotEncoded(
          Design_Card_Crumbs !== undefined &&
            Design_Card_Crumbs.card_main_title_border_color_right_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Card_Crumbs.card_main_title_border_color_right_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0 }
        );
        setDB_LeftSidebar_MenuItem_HauptTitel_BorderColorTop(
          Design_Card_Crumbs !== undefined &&
            Design_Card_Crumbs.card_main_title_border_color_top !== undefined
            ? Design_Card_Crumbs.card_main_title_border_color_top
            : "rgba(0,0,0,0)"
        );
        setDB_LeftSidebar_MenuItem_HauptTitel_BorderColorTopNotEncoded(
          Design_Card_Crumbs !== undefined &&
            Design_Card_Crumbs.card_main_title_border_color_top_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Card_Crumbs.card_main_title_border_color_top_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0 }
        );
        setDB_LeftSidebar_MenuItem_HauptTitel_BorderColorBottom(
          Design_Card_Crumbs !== undefined &&
            Design_Card_Crumbs.card_main_title_border_color_bottom !== undefined
            ? Design_Card_Crumbs.card_main_title_border_color_bottom
            : "rgba(0,0,0,0)"
        );
        setDB_LeftSidebar_MenuItem_HauptTitel_BorderColorBottomNotEncoded(
          Design_Card_Crumbs !== undefined &&
            Design_Card_Crumbs.card_main_title_border_color_bottom_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Card_Crumbs.card_main_title_border_color_bottom_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0 }
        );

        setDB_LeftSidebar_MenuItem_HauptTitel_BorderLeft(
          Design_Card_Crumbs !== undefined &&
            Design_Card_Crumbs.card_main_title_border_pixel_left !== undefined
            ? Design_Card_Crumbs.card_main_title_border_pixel_left
            : "0"
        );
        setDB_LeftSidebar_MenuItem_HauptTitel_BorderRight(
          Design_Card_Crumbs !== undefined &&
            Design_Card_Crumbs.card_main_title_border_pixel_right !== undefined
            ? Design_Card_Crumbs.card_main_title_border_pixel_right
            : "0"
        );
        setDB_LeftSidebar_MenuItem_HauptTitel_BorderTop(
          Design_Card_Crumbs !== undefined &&
            Design_Card_Crumbs.card_main_title_border_pixel_top !== undefined
            ? Design_Card_Crumbs.card_main_title_border_pixel_top
            : "0"
        );
        setDB_LeftSidebar_MenuItem_HauptTitel_BorderBottom(
          Design_Card_Crumbs !== undefined &&
            Design_Card_Crumbs.card_main_title_border_pixel_bottom !== undefined
            ? Design_Card_Crumbs.card_main_title_border_pixel_bottom
            : "0"
        );

        setDB_LeftSidebar_MenuItem_HauptTitel_BorderTopLeftRadius(
          Design_Card_Crumbs !== undefined &&
            Design_Card_Crumbs.card_main_title_border_radius_top_left !==
              undefined
            ? Design_Card_Crumbs.card_main_title_border_radius_top_left
            : "0"
        );
        setDB_LeftSidebar_MenuItem_HauptTitel_BorderTopRightRadius(
          Design_Card_Crumbs !== undefined &&
            Design_Card_Crumbs.card_main_title_border_radius_top_right !==
              undefined
            ? Design_Card_Crumbs.card_main_title_border_radius_top_right
            : "0"
        );
        setDB_LeftSidebar_MenuItem_HauptTitel_BorderBottomLeftRadius(
          Design_Card_Crumbs !== undefined &&
            Design_Card_Crumbs.card_main_title_border_radius_bottom_left !==
              undefined
            ? Design_Card_Crumbs.card_main_title_border_radius_bottom_left
            : "0"
        );
        setDB_LeftSidebar_MenuItem_HauptTitel_BorderBottomRightRadius(
          Design_Card_Crumbs !== undefined &&
            Design_Card_Crumbs.card_main_title_border_radius_bottom_right !==
              undefined
            ? Design_Card_Crumbs.card_main_title_border_radius_bottom_right
            : "0"
        );

        setDB_LeftSidebar_MenuItem_HauptTitel_BorderStyleLeft(
          Design_Card_Crumbs !== undefined &&
            Design_Card_Crumbs.card_main_title_border_style_left !== undefined
            ? Design_Card_Crumbs.card_main_title_border_style_left
            : "solid"
        );
        setDB_LeftSidebar_MenuItem_HauptTitel_BorderStyleRight(
          Design_Card_Crumbs !== undefined &&
            Design_Card_Crumbs.card_main_title_border_style_right !== undefined
            ? Design_Card_Crumbs.card_main_title_border_style_right
            : "solid"
        );
        setDB_LeftSidebar_MenuItem_HauptTitel_BorderStyleTop(
          Design_Card_Crumbs !== undefined &&
            Design_Card_Crumbs.card_main_title_border_style_top !== undefined
            ? Design_Card_Crumbs.card_main_title_border_style_top
            : "solid"
        );
        setDB_LeftSidebar_MenuItem_HauptTitel_BorderStyleBottom(
          Design_Card_Crumbs !== undefined &&
            Design_Card_Crumbs.card_main_title_border_style_bottom !== undefined
            ? Design_Card_Crumbs.card_main_title_border_style_bottom
            : "solid"
        );

        setDB_LeftSidebar_MenuItem_HauptTitel_Hover_BorderColorLeft(
          Design_Card_Crumbs !== undefined &&
            Design_Card_Crumbs.card_main_title_border_hov_color_left !==
              undefined
            ? Design_Card_Crumbs.card_main_title_border_hov_color_left
            : "rgba(0,0,0,0)"
        );
        setDB_LeftSidebar_MenuItem_HauptTitel_Hover_BorderColorLeftNotEncoded(
          Design_Card_Crumbs !== undefined &&
            Design_Card_Crumbs.card_main_title_border_hov_color_left_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Card_Crumbs.card_main_title_border_hov_color_left_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0 }
        );
        setDB_LeftSidebar_MenuItem_HauptTitel_Hover_BorderColorRight(
          Design_Card_Crumbs !== undefined &&
            Design_Card_Crumbs.card_main_title_border_hov_color_right !==
              undefined
            ? Design_Card_Crumbs.card_main_title_border_hov_color_right
            : "rgba(0,0,0,0)"
        );
        setDB_LeftSidebar_MenuItem_HauptTitel_Hover_BorderColorRightNotEncoded(
          Design_Card_Crumbs !== undefined &&
            Design_Card_Crumbs.card_main_title_border_hov_color_right_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Card_Crumbs.card_main_title_border_hov_color_right_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0 }
        );
        setDB_LeftSidebar_MenuItem_HauptTitel_Hover_BorderColorTop(
          Design_Card_Crumbs !== undefined &&
            Design_Card_Crumbs.card_main_title_border_hov_color_top !==
              undefined
            ? Design_Card_Crumbs.card_main_title_border_hov_color_top
            : "rgba(0,0,0,0)"
        );
        setDB_LeftSidebar_MenuItem_HauptTitel_Hover_BorderColorTopNotEncoded(
          Design_Card_Crumbs !== undefined &&
            Design_Card_Crumbs.card_main_title_border_hov_color_top_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Card_Crumbs.card_main_title_border_hov_color_top_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0 }
        );
        setDB_LeftSidebar_MenuItem_HauptTitel_Hover_BorderColorBottom(
          Design_Card_Crumbs !== undefined &&
            Design_Card_Crumbs.card_main_title_border_hov_color_bottom !==
              undefined
            ? Design_Card_Crumbs.card_main_title_border_hov_color_bottom
            : "rgba(0,0,0,0)"
        );
        setDB_LeftSidebar_MenuItem_HauptTitel_Hover_BorderColorBottomNotEncoded(
          Design_Card_Crumbs !== undefined &&
            Design_Card_Crumbs.card_main_title_border_hov_color_bottom_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Card_Crumbs.card_main_title_border_hov_color_bottom_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0 }
        );

        setDB_LeftSidebar_MenuItem_HauptTitel_Hover_BorderLeft(
          Design_Card_Crumbs !== undefined &&
            Design_Card_Crumbs.card_main_title_border_hov_pixel_left !==
              undefined
            ? Design_Card_Crumbs.card_main_title_border_hov_pixel_left
            : "0"
        );
        setDB_LeftSidebar_MenuItem_HauptTitel_Hover_BorderRight(
          Design_Card_Crumbs !== undefined &&
            Design_Card_Crumbs.card_main_title_border_hov_pixel_right !==
              undefined
            ? Design_Card_Crumbs.card_main_title_border_hov_pixel_right
            : "0"
        );
        setDB_LeftSidebar_MenuItem_HauptTitel_Hover_BorderTop(
          Design_Card_Crumbs !== undefined &&
            Design_Card_Crumbs.card_main_title_border_hov_pixel_top !==
              undefined
            ? Design_Card_Crumbs.card_main_title_border_hov_pixel_top
            : "0"
        );
        setDB_LeftSidebar_MenuItem_HauptTitel_Hover_BorderBottom(
          Design_Card_Crumbs !== undefined &&
            Design_Card_Crumbs.card_main_title_border_hov_pixel_bottom !==
              undefined
            ? Design_Card_Crumbs.card_main_title_border_hov_pixel_bottom
            : "0"
        );

        setDB_LeftSidebar_MenuItem_HauptTitel_Hover_BorderTopLeftRadius(
          Design_Card_Crumbs !== undefined &&
            Design_Card_Crumbs.card_main_title_border_hov_radius_top_left !==
              undefined
            ? Design_Card_Crumbs.card_main_title_border_hov_radius_top_left
            : "0"
        );
        setDB_LeftSidebar_MenuItem_HauptTitel_Hover_BorderTopRightRadius(
          Design_Card_Crumbs !== undefined &&
            Design_Card_Crumbs.card_main_title_border_hov_radius_top_right !==
              undefined
            ? Design_Card_Crumbs.card_main_title_border_hov_radius_top_right
            : "0"
        );
        setDB_LeftSidebar_MenuItem_HauptTitel_Hover_BorderBottomLeftRadius(
          Design_Card_Crumbs !== undefined &&
            Design_Card_Crumbs.card_main_title_border_hov_radius_bottom_left !==
              undefined
            ? Design_Card_Crumbs.card_main_title_border_hov_radius_bottom_left
            : "0"
        );
        setDB_LeftSidebar_MenuItem_HauptTitel_Hover_BorderBottomRightRadius(
          Design_Card_Crumbs !== undefined &&
            Design_Card_Crumbs.card_main_title_border_hov_radius_bottom_right !==
              undefined
            ? Design_Card_Crumbs.card_main_title_border_hov_radius_bottom_right
            : "0"
        );

        setDB_LeftSidebar_MenuItem_HauptTitel_Hover_BorderStyleLeft(
          Design_Card_Crumbs !== undefined &&
            Design_Card_Crumbs.card_main_title_border_hov_style_left !==
              undefined
            ? Design_Card_Crumbs.card_main_title_border_hov_style_left
            : "solid"
        );
        setDB_LeftSidebar_MenuItem_HauptTitel_Hover_BorderStyleRight(
          Design_Card_Crumbs !== undefined &&
            Design_Card_Crumbs.card_main_title_border_hov_style_right !==
              undefined
            ? Design_Card_Crumbs.card_main_title_border_hov_style_right
            : "solid"
        );
        setDB_LeftSidebar_MenuItem_HauptTitel_Hover_BorderStyleTop(
          Design_Card_Crumbs !== undefined &&
            Design_Card_Crumbs.card_main_title_border_hov_style_top !==
              undefined
            ? Design_Card_Crumbs.card_main_title_border_hov_style_top
            : "solid"
        );
        setDB_LeftSidebar_MenuItem_HauptTitel_Hover_BorderStyleBottom(
          Design_Card_Crumbs !== undefined &&
            Design_Card_Crumbs.card_main_title_border_hov_style_bottom !==
              undefined
            ? Design_Card_Crumbs.card_main_title_border_hov_style_bottom
            : "solid"
        );

        setDB_LeftSidebar_MenuItem_UnterTitel_FontColor(
          Design_Card_Subtitle !== undefined &&
            Design_Card_Subtitle.card_untertitel_schriftfarbe !== undefined
            ? Design_Card_Subtitle.card_untertitel_schriftfarbe
            : "rgba(255,255,255,1)"
        );
        setDB_LeftSidebar_MenuItem_UnterTitel_FontColorNotEncoded(
          Design_Card_Subtitle !== undefined &&
            Design_Card_Subtitle.card_untertitel_schriftfarbe_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Card_Subtitle.card_untertitel_schriftfarbe_not_encoded
              )
            : { r: 255, g: 255, b: 255, a: 1 }
        );
        setDB_LeftSidebar_MenuItem_UnterTitel_BackgroundColor(
          Design_Card_Subtitle !== undefined &&
            Design_Card_Subtitle.card_untertitel_hintergrundfarbe !== undefined
            ? Design_Card_Subtitle.card_untertitel_hintergrundfarbe
            : "rgba(0,0,0,0)"
        );
        setDB_LeftSidebar_MenuItem_UnterTitel_BackgroundColorNotEncoded(
          Design_Card_Subtitle !== undefined &&
            Design_Card_Subtitle.card_untertitel_hintergrundfarbe_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Card_Subtitle.card_untertitel_hintergrundfarbe_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0 }
        );
        setDB_LeftSidebar_MenuItem_UnterTitel_ShadowColor(
          Design_Card_Subtitle !== undefined &&
            Design_Card_Subtitle.card_untertitel_schattenfarbe !== undefined
            ? Design_Card_Subtitle.card_untertitel_schattenfarbe
            : "rgba(0,0,0,1)"
        );
        setDB_LeftSidebar_MenuItem_UnterTitel_ShadowColorNotEncoded(
          Design_Card_Subtitle !== undefined &&
            Design_Card_Subtitle.card_untertitel_schattenfarbe_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Card_Subtitle.card_untertitel_schattenfarbe_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 1 }
        );
        setDB_LeftSidebar_MenuItem_UnterTitel_ShadowHorizontal(
          Design_Card_Subtitle !== undefined &&
            Design_Card_Subtitle.card_untertitel_schattenposition_horizontal !==
              undefined
            ? Design_Card_Subtitle.card_untertitel_schattenposition_horizontal
            : "2"
        );
        setDB_LeftSidebar_MenuItem_UnterTitel_ShadowVertical(
          Design_Card_Subtitle !== undefined &&
            Design_Card_Subtitle.card_untertitel_schattenposition_vertikal !==
              undefined
            ? Design_Card_Subtitle.card_untertitel_schattenposition_vertikal
            : "2"
        );
        setDB_LeftSidebar_MenuItem_UnterTitel_ShadowBlur(
          Design_Card_Subtitle !== undefined &&
            Design_Card_Subtitle.card_untertitel_schattenrauschen !== undefined
            ? Design_Card_Subtitle.card_untertitel_schattenrauschen
            : "3"
        );
        setDB_LeftSidebar_MenuItem_UnterTitel_FontSize(
          Design_Card_Subtitle !== undefined &&
            Design_Card_Subtitle.card_untertitel_schriftgroesse !== undefined
            ? Design_Card_Subtitle.card_untertitel_schriftgroesse
            : "16"
        );
        setDB_LeftSidebar_MenuItem_UnterTitel_FontWeight(
          Design_Card_Subtitle !== undefined &&
            Design_Card_Subtitle.card_untertitel_schriftgewicht !== undefined
            ? Design_Card_Subtitle.card_untertitel_schriftgewicht
            : "100"
        );
        setDB_LeftSidebar_MenuItem_UnterTitel_letterSpacing(
          Design_Card_Subtitle !== undefined &&
            Design_Card_Subtitle.card_untertitel_buchstabenabstand !== undefined
            ? Design_Card_Subtitle.card_untertitel_buchstabenabstand
            : "0"
        );
        setDB_LeftSidebar_MenuItem_UnterTitel_TextDecoration(
          Design_Card_Subtitle !== undefined &&
            Design_Card_Subtitle.card_untertitel_text_dekoration !== undefined
            ? Design_Card_Subtitle.card_untertitel_text_dekoration
            : "none"
        );
        setDB_LeftSidebar_MenuItem_UnterTitel_TextDecorationStyle(
          Design_Card_Subtitle !== undefined &&
            Design_Card_Subtitle.card_untertitel_text_dekoration_stil !==
              undefined
            ? Design_Card_Subtitle.card_untertitel_text_dekoration_stil
            : "none"
        );
        setDB_LeftSidebar_MenuItem_UnterTitel_marginLeftRight(
          Design_Card_Subtitle !== undefined &&
            Design_Card_Subtitle.card_untertitel_aussen_abstand_links_rechts !==
              undefined
            ? Design_Card_Subtitle.card_untertitel_aussen_abstand_links_rechts
            : "7"
        );
        setDB_LeftSidebar_MenuItem_UnterTitel_marginTopBottom(
          Design_Card_Subtitle !== undefined &&
            Design_Card_Subtitle.card_untertitel_aussen_abstand_oben_unten !==
              undefined
            ? Design_Card_Subtitle.card_untertitel_aussen_abstand_oben_unten
            : "7"
        );
        setDB_LeftSidebar_MenuItem_UnterTitel_paddingLeftRight(
          Design_Card_Subtitle !== undefined &&
            Design_Card_Subtitle.card_untertitel_innerer_abstand_links_rechts !==
              undefined
            ? Design_Card_Subtitle.card_untertitel_innerer_abstand_links_rechts
            : "0"
        );
        setDB_LeftSidebar_MenuItem_UnterTitel_paddingTopBottom(
          Design_Card_Subtitle !== undefined &&
            Design_Card_Subtitle.card_untertitel_innerer_abstand_oben_unten !==
              undefined
            ? Design_Card_Subtitle.card_untertitel_innerer_abstand_oben_unten
            : "0"
        );
        setDB_LeftSidebar_MenuItem_UnterTitel_TextAlign(
          Design_Card_Subtitle !== undefined &&
            Design_Card_Subtitle.card_untertitel_textausrichtung !== undefined
            ? Design_Card_Subtitle.card_untertitel_textausrichtung
            : "left"
        );

        setDB_fontFace_chooseFontFamilyProductUntertitel_FontFamilyName(
          Design_Card_Subtitle !== undefined &&
            Design_Card_Subtitle.card_untertitel_fontfamily !== undefined
            ? Design_Card_Subtitle.card_untertitel_fontfamily
            : "Lato"
        );

        setDB_fontFace_chooseFontFamilyProductUntertitel_FontFamilySrc(
          Design_Card_Subtitle !== undefined &&
            Design_Card_Subtitle.card_untertitel_fontfamilysrc !== undefined
            ? Design_Card_Subtitle.card_untertitel_fontfamilysrc
            : "/assets/Lato/Lato-Regular.ttf"
        );

        setDB_LeftSidebar_MenuItem_UnterTitel_Hover_FontColor(
          Design_Card_Subtitle !== undefined &&
            Design_Card_Subtitle.card_untertitel_hov_schriftfarbe !== undefined
            ? Design_Card_Subtitle.card_untertitel_hov_schriftfarbe
            : "rgba(255,255,255,1)"
        );
        setDB_LeftSidebar_MenuItem_UnterTitel_Hover_FontColorNotEncoded(
          Design_Card_Subtitle !== undefined &&
            Design_Card_Subtitle.card_untertitel_hov_schriftfarbe_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Card_Subtitle.card_untertitel_hov_schriftfarbe_not_encoded
              )
            : { r: 255, g: 255, b: 255, a: 1 }
        );
        setDB_LeftSidebar_MenuItem_UnterTitel_Hover_BackgroundColor(
          Design_Card_Subtitle !== undefined &&
            Design_Card_Subtitle.card_untertitel_hov_hintergrundfarbe !==
              undefined
            ? Design_Card_Subtitle.card_untertitel_hov_hintergrundfarbe
            : "rgba(0,0,0,0)"
        );
        setDB_LeftSidebar_MenuItem_UnterTitel_Hover_BackgroundColorNotEncoded(
          Design_Card_Subtitle !== undefined &&
            Design_Card_Subtitle.card_untertitel_hov_hintergrundfarbe_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Card_Subtitle.card_untertitel_hov_hintergrundfarbe_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0 }
        );
        setDB_LeftSidebar_MenuItem_UnterTitel_ShadowColor_Hover(
          Design_Card_Subtitle !== undefined &&
            Design_Card_Subtitle.card_untertitel_hov_schattenfarbe !== undefined
            ? Design_Card_Subtitle.card_untertitel_hov_schattenfarbe
            : "rgba(0,0,0,1)"
        );
        setDB_LeftSidebar_MenuItem_UnterTitel_ShadowColorNotEncoded_Hover(
          Design_Card_Subtitle !== undefined &&
            Design_Card_Subtitle.card_untertitel_hov_schattenfarbe_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Card_Subtitle.card_untertitel_hov_schattenfarbe_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 1 }
        );
        setDB_LeftSidebar_MenuItem_UnterTitel_ShadowHorizontal_Hover(
          Design_Card_Subtitle !== undefined &&
            Design_Card_Subtitle.card_untertitel_hov_schattenposition_horizontal !==
              undefined
            ? Design_Card_Subtitle.card_untertitel_hov_schattenposition_horizontal
            : "2"
        );
        setDB_LeftSidebar_MenuItem_UnterTitel_ShadowVertical_Hover(
          Design_Card_Subtitle !== undefined &&
            Design_Card_Subtitle.card_untertitel_hov_schattenposition_vertikal !==
              undefined
            ? Design_Card_Subtitle.card_untertitel_hov_schattenposition_vertikal
            : "2"
        );
        setDB_LeftSidebar_MenuItem_UnterTitel_ShadowBlur_Hover(
          Design_Card_Subtitle !== undefined &&
            Design_Card_Subtitle.card_untertitel_hov_schattenrauschen !==
              undefined
            ? Design_Card_Subtitle.card_untertitel_hov_schattenrauschen
            : "3"
        );
        setDB_LeftSidebar_MenuItem_UnterTitel_Hover_FontSize(
          Design_Card_Subtitle !== undefined &&
            Design_Card_Subtitle.card_untertitel_hov_schriftgroesse !==
              undefined
            ? Design_Card_Subtitle.card_untertitel_hov_schriftgroesse
            : "16"
        );
        setDB_LeftSidebar_MenuItem_UnterTitel_Hover_FontWeight(
          Design_Card_Subtitle !== undefined &&
            Design_Card_Subtitle.card_untertitel_hov_schriftgewicht !==
              undefined
            ? Design_Card_Subtitle.card_untertitel_hov_schriftgewicht
            : "100"
        );
        setDB_LeftSidebar_MenuItem_UnterTitel_letterSpacing_Hover(
          Design_Card_Subtitle !== undefined &&
            Design_Card_Subtitle.card_untertitel_hov_buchstabenabstand !==
              undefined
            ? Design_Card_Subtitle.card_untertitel_hov_buchstabenabstand
            : "0"
        );
        setDB_LeftSidebar_MenuItem_UnterTitel_TextDecoration_Hover(
          Design_Card_Subtitle !== undefined &&
            Design_Card_Subtitle.card_untertitel_hov_text_dekoration !==
              undefined
            ? Design_Card_Subtitle.card_untertitel_hov_text_dekoration
            : "none"
        );
        setDB_LeftSidebar_MenuItem_UnterTitel_TextDecorationStyle_Hover(
          Design_Card_Subtitle !== undefined &&
            Design_Card_Subtitle.card_untertitel_hov_text_dekoration_stil !==
              undefined
            ? Design_Card_Subtitle.card_untertitel_hov_text_dekoration_stil
            : "none"
        );
        setDB_LeftSidebar_MenuItem_UnterTitel_marginLeftRight_Hover(
          Design_Card_Subtitle !== undefined &&
            Design_Card_Subtitle.card_untertitel_hov_aussen_abstand_links_rechts !==
              undefined
            ? Design_Card_Subtitle.card_untertitel_hov_aussen_abstand_links_rechts
            : "7"
        );
        setDB_LeftSidebar_MenuItem_UnterTitel_marginTopBottom_Hover(
          Design_Card_Subtitle !== undefined &&
            Design_Card_Subtitle.card_untertitel_hov_aussen_abstand_oben_unten !==
              undefined
            ? Design_Card_Subtitle.card_untertitel_hov_aussen_abstand_oben_unten
            : "7"
        );
        setDB_LeftSidebar_MenuItem_UnterTitel_paddingLeftRight_Hover(
          Design_Card_Subtitle !== undefined &&
            Design_Card_Subtitle.card_untertitel_hov_innerer_abstand_links_rechts !==
              undefined
            ? Design_Card_Subtitle.card_untertitel_hov_innerer_abstand_links_rechts
            : "0"
        );
        setDB_LeftSidebar_MenuItem_UnterTitel_paddingTopBottom_Hover(
          Design_Card_Subtitle !== undefined &&
            Design_Card_Subtitle.card_untertitel_hov_innerer_abstand_oben_unten !==
              undefined
            ? Design_Card_Subtitle.card_untertitel_hov_innerer_abstand_oben_unten
            : "0"
        );
        setDB_LeftSidebar_MenuItem_UnterTitel_Hover_TextAlign(
          Design_Card_Subtitle !== undefined &&
            Design_Card_Subtitle.card_untertitel_hov_textausrichtung !==
              undefined
            ? Design_Card_Subtitle.card_untertitel_hov_textausrichtung
            : "left"
        );

        setDB_LeftSidebar_MenuItem_UnterTitel_BorderColorLeft(
          Design_Card_Subtitle !== undefined &&
            Design_Card_Subtitle.card_untertitel_border_color_left !== undefined
            ? Design_Card_Subtitle.card_untertitel_border_color_left
            : "rgba(0,0,0,0)"
        );
        setDB_LeftSidebar_MenuItem_UnterTitel_BorderColorLeftNotEncoded(
          Design_Card_Subtitle !== undefined &&
            Design_Card_Subtitle.card_untertitel_border_color_left_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Card_Subtitle.card_untertitel_border_color_left_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0 }
        );
        setDB_LeftSidebar_MenuItem_UnterTitel_BorderColorRight(
          Design_Card_Subtitle !== undefined &&
            Design_Card_Subtitle.card_untertitel_border_color_right !==
              undefined
            ? Design_Card_Subtitle.card_untertitel_border_color_right
            : "rgba(0,0,0,0)"
        );
        setDB_LeftSidebar_MenuItem_UnterTitel_BorderColorRightNotEncoded(
          Design_Card_Subtitle !== undefined &&
            Design_Card_Subtitle.card_untertitel_border_color_right_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Card_Subtitle.card_untertitel_border_color_right_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0 }
        );
        setDB_LeftSidebar_MenuItem_UnterTitel_BorderColorTop(
          Design_Card_Subtitle !== undefined &&
            Design_Card_Subtitle.card_untertitel_border_color_top !== undefined
            ? Design_Card_Subtitle.card_untertitel_border_color_top
            : "rgba(0,0,0,0)"
        );
        setDB_LeftSidebar_MenuItem_UnterTitel_BorderColorTopNotEncoded(
          Design_Card_Subtitle !== undefined &&
            Design_Card_Subtitle.card_untertitel_border_color_top_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Card_Subtitle.card_untertitel_border_color_top_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0 }
        );
        setDB_LeftSidebar_MenuItem_UnterTitel_BorderColorBottom(
          Design_Card_Subtitle !== undefined &&
            Design_Card_Subtitle.card_untertitel_border_color_bottom !==
              undefined
            ? Design_Card_Subtitle.card_untertitel_border_color_bottom
            : "rgba(0,0,0,0)"
        );
        setDB_LeftSidebar_MenuItem_UnterTitel_BorderColorBottomNotEncoded(
          Design_Card_Subtitle !== undefined &&
            Design_Card_Subtitle.card_untertitel_border_color_bottom_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Card_Subtitle.card_untertitel_border_color_bottom_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0 }
        );

        setDB_LeftSidebar_MenuItem_UnterTitel_BorderLeft(
          Design_Card_Subtitle !== undefined &&
            Design_Card_Subtitle.card_untertitel_border_pixel_left !== undefined
            ? Design_Card_Subtitle.card_untertitel_border_pixel_left
            : "0"
        );
        setDB_LeftSidebar_MenuItem_UnterTitel_BorderRight(
          Design_Card_Subtitle !== undefined &&
            Design_Card_Subtitle.card_untertitel_border_pixel_right !==
              undefined
            ? Design_Card_Subtitle.card_untertitel_border_pixel_right
            : "0"
        );
        setDB_LeftSidebar_MenuItem_UnterTitel_BorderTop(
          Design_Card_Subtitle !== undefined &&
            Design_Card_Subtitle.card_untertitel_border_pixel_top !== undefined
            ? Design_Card_Subtitle.card_untertitel_border_pixel_top
            : "0"
        );
        setDB_LeftSidebar_MenuItem_UnterTitel_BorderBottom(
          Design_Card_Subtitle !== undefined &&
            Design_Card_Subtitle.card_untertitel_border_pixel_bottom !==
              undefined
            ? Design_Card_Subtitle.card_untertitel_border_pixel_bottom
            : "0"
        );

        setDB_LeftSidebar_MenuItem_UnterTitel_BorderTopLeftRadius(
          Design_Card_Subtitle !== undefined &&
            Design_Card_Subtitle.card_untertitel_border_radius_top_left !==
              undefined
            ? Design_Card_Subtitle.card_untertitel_border_radius_top_left
            : "0"
        );
        setDB_LeftSidebar_MenuItem_UnterTitel_BorderTopRightRadius(
          Design_Card_Subtitle !== undefined &&
            Design_Card_Subtitle.card_untertitel_border_radius_top_right !==
              undefined
            ? Design_Card_Subtitle.card_untertitel_border_radius_top_right
            : "0"
        );
        setDB_LeftSidebar_MenuItem_UnterTitel_BorderBottomLeftRadius(
          Design_Card_Subtitle !== undefined &&
            Design_Card_Subtitle.card_untertitel_border_radius_bottom_left !==
              undefined
            ? Design_Card_Subtitle.card_untertitel_border_radius_bottom_left
            : "0"
        );
        setDB_LeftSidebar_MenuItem_UnterTitel_BorderBottomRightRadius(
          Design_Card_Subtitle !== undefined &&
            Design_Card_Subtitle.card_untertitel_border_radius_bottom_right !==
              undefined
            ? Design_Card_Subtitle.card_untertitel_border_radius_bottom_right
            : "0"
        );

        setDB_LeftSidebar_MenuItem_UnterTitel_BorderStyleLeft(
          Design_Card_Subtitle !== undefined &&
            Design_Card_Subtitle.card_untertitel_border_style_left !== undefined
            ? Design_Card_Subtitle.card_untertitel_border_style_left
            : "solid"
        );
        setDB_LeftSidebar_MenuItem_UnterTitel_BorderStyleRight(
          Design_Card_Subtitle !== undefined &&
            Design_Card_Subtitle.card_untertitel_border_style_right !==
              undefined
            ? Design_Card_Subtitle.card_untertitel_border_style_right
            : "solid"
        );
        setDB_LeftSidebar_MenuItem_UnterTitel_BorderStyleTop(
          Design_Card_Subtitle !== undefined &&
            Design_Card_Subtitle.card_untertitel_border_style_top !== undefined
            ? Design_Card_Subtitle.card_untertitel_border_style_top
            : "solid"
        );
        setDB_LeftSidebar_MenuItem_UnterTitel_BorderStyleBottom(
          Design_Card_Subtitle !== undefined &&
            Design_Card_Subtitle.card_untertitel_border_style_bottom !==
              undefined
            ? Design_Card_Subtitle.card_untertitel_border_style_bottom
            : "solid"
        );

        setDB_LeftSidebar_MenuItem_UnterTitel_Hover_BorderColorLeft(
          Design_Card_Subtitle !== undefined &&
            Design_Card_Subtitle.card_untertitel_border_hov_color_left !==
              undefined
            ? Design_Card_Subtitle.card_untertitel_border_hov_color_left
            : "rgba(0,0,0,0)"
        );
        setDB_LeftSidebar_MenuItem_UnterTitel_Hover_BorderColorLeftNotEncoded(
          Design_Card_Subtitle !== undefined &&
            Design_Card_Subtitle.card_untertitel_border_hov_color_left_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Card_Subtitle.card_untertitel_border_hov_color_left_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0 }
        );
        setDB_LeftSidebar_MenuItem_UnterTitel_Hover_BorderColorRight(
          Design_Card_Subtitle !== undefined &&
            Design_Card_Subtitle.card_untertitel_border_hov_color_right !==
              undefined
            ? Design_Card_Subtitle.card_untertitel_border_hov_color_right
            : "rgba(0,0,0,0)"
        );
        setDB_LeftSidebar_MenuItem_UnterTitel_Hover_BorderColorRightNotEncoded(
          Design_Card_Subtitle !== undefined &&
            Design_Card_Subtitle.card_untertitel_border_hov_color_right_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Card_Subtitle.card_untertitel_border_hov_color_right_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0 }
        );
        setDB_LeftSidebar_MenuItem_UnterTitel_Hover_BorderColorTop(
          Design_Card_Subtitle !== undefined &&
            Design_Card_Subtitle.card_untertitel_border_hov_color_top !==
              undefined
            ? Design_Card_Subtitle.card_untertitel_border_hov_color_top
            : "rgba(0,0,0,0)"
        );
        setDB_LeftSidebar_MenuItem_UnterTitel_Hover_BorderColorTopNotEncoded(
          Design_Card_Subtitle !== undefined &&
            Design_Card_Subtitle.card_untertitel_border_hov_color_top_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Card_Subtitle.card_untertitel_border_hov_color_top_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0 }
        );
        setDB_LeftSidebar_MenuItem_UnterTitel_Hover_BorderColorBottom(
          Design_Card_Subtitle !== undefined &&
            Design_Card_Subtitle.card_untertitel_border_hov_color_bottom !==
              undefined
            ? Design_Card_Subtitle.card_untertitel_border_hov_color_bottom
            : "rgba(0,0,0,0)"
        );
        setDB_LeftSidebar_MenuItem_UnterTitel_Hover_BorderColorBottomNotEncoded(
          Design_Card_Subtitle !== undefined &&
            Design_Card_Subtitle.card_untertitel_border_hov_color_bottom_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Card_Subtitle.card_untertitel_border_hov_color_bottom_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0 }
        );

        setDB_LeftSidebar_MenuItem_UnterTitel_Hover_BorderLeft(
          Design_Card_Subtitle !== undefined &&
            Design_Card_Subtitle.card_untertitel_border_hov_pixel_left !==
              undefined
            ? Design_Card_Subtitle.card_untertitel_border_hov_pixel_left
            : "0"
        );
        setDB_LeftSidebar_MenuItem_UnterTitel_Hover_BorderRight(
          Design_Card_Subtitle !== undefined &&
            Design_Card_Subtitle.card_untertitel_border_hov_pixel_right !==
              undefined
            ? Design_Card_Subtitle.card_untertitel_border_hov_pixel_right
            : "0"
        );
        setDB_LeftSidebar_MenuItem_UnterTitel_Hover_BorderTop(
          Design_Card_Subtitle !== undefined &&
            Design_Card_Subtitle.card_untertitel_border_hov_pixel_top !==
              undefined
            ? Design_Card_Subtitle.card_untertitel_border_hov_pixel_top
            : "0"
        );
        setDB_LeftSidebar_MenuItem_UnterTitel_Hover_BorderBottom(
          Design_Card_Subtitle !== undefined &&
            Design_Card_Subtitle.card_untertitel_border_hov_pixel_bottom !==
              undefined
            ? Design_Card_Subtitle.card_untertitel_border_hov_pixel_bottom
            : "0"
        );

        setDB_LeftSidebar_MenuItem_UnterTitel_Hover_BorderTopLeftRadius(
          Design_Card_Subtitle !== undefined &&
            Design_Card_Subtitle.card_untertitel_border_hov_radius_top_left !==
              undefined
            ? Design_Card_Subtitle.card_untertitel_border_hov_radius_top_left
            : "0"
        );
        setDB_LeftSidebar_MenuItem_UnterTitel_Hover_BorderTopRightRadius(
          Design_Card_Subtitle !== undefined &&
            Design_Card_Subtitle.card_untertitel_border_hov_radius_top_right !==
              undefined
            ? Design_Card_Subtitle.card_untertitel_border_hov_radius_top_right
            : "0"
        );
        setDB_LeftSidebar_MenuItem_UnterTitel_Hover_BorderBottomLeftRadius(
          Design_Card_Subtitle !== undefined &&
            Design_Card_Subtitle.card_untertitel_border_hov_radius_bottom_left !==
              undefined
            ? Design_Card_Subtitle.card_untertitel_border_hov_radius_bottom_left
            : "0"
        );
        setDB_LeftSidebar_MenuItem_UnterTitel_Hover_BorderBottomRightRadius(
          Design_Card_Subtitle !== undefined &&
            Design_Card_Subtitle.card_untertitel_border_hov_radius_bottom_right !==
              undefined
            ? Design_Card_Subtitle.card_untertitel_border_hov_radius_bottom_right
            : "0"
        );

        setDB_LeftSidebar_MenuItem_UnterTitel_Hover_BorderStyleLeft(
          Design_Card_Subtitle !== undefined &&
            Design_Card_Subtitle.card_untertitel_border_hov_style_left !==
              undefined
            ? Design_Card_Subtitle.card_untertitel_border_hov_style_left
            : "solid"
        );
        setDB_LeftSidebar_MenuItem_UnterTitel_Hover_BorderStyleRight(
          Design_Card_Subtitle !== undefined &&
            Design_Card_Subtitle.card_untertitel_border_hov_style_right !==
              undefined
            ? Design_Card_Subtitle.card_untertitel_border_hov_style_right
            : "solid"
        );
        setDB_LeftSidebar_MenuItem_UnterTitel_Hover_BorderStyleTop(
          Design_Card_Subtitle !== undefined &&
            Design_Card_Subtitle.card_untertitel_border_hov_style_top !==
              undefined
            ? Design_Card_Subtitle.card_untertitel_border_hov_style_top
            : "solid"
        );
        setDB_LeftSidebar_MenuItem_UnterTitel_Hover_BorderStyleBottom(
          Design_Card_Subtitle !== undefined &&
            Design_Card_Subtitle.card_untertitel_border_hov_style_bottom !==
              undefined
            ? Design_Card_Subtitle.card_untertitel_border_hov_style_bottom
            : "solid"
        );

        setDB_LeftSidebar_MenuItem_ProduktPreis_FontColor(
          Design_Card_Subtitle !== undefined &&
            Design_Card_Subtitle.card_price_schriftfarbe !== undefined
            ? Design_Card_Subtitle.card_price_schriftfarbe
            : "rgba(0,255,0,1)"
        );
        setDB_LeftSidebar_MenuItem_ProduktPreis_FontColorNotEncoded(
          Design_Card_Subtitle !== undefined &&
            Design_Card_Subtitle.card_price_schriftfarbe_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Card_Subtitle.card_price_schriftfarbe_not_encoded
              )
            : { r: 0, g: 255, b: 0, a: 1 }
        );
        setDB_LeftSidebar_MenuItem_ProduktPreis_BackgroundColor(
          Design_Card_Subtitle !== undefined &&
            Design_Card_Subtitle.card_price_hintergrundfarbe !== undefined
            ? Design_Card_Subtitle.card_price_hintergrundfarbe
            : "rgba(0,0,0,0)"
        );
        setDB_LeftSidebar_MenuItem_ProduktPreis_BackgroundColorNotEncoded(
          Design_Card_Subtitle !== undefined &&
            Design_Card_Subtitle.card_price_hintergrundfarbe_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Card_Subtitle.card_price_hintergrundfarbe_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0 }
        );
        setDB_LeftSidebar_MenuItem_ProduktPreis_ShadowColor(
          Design_Card_Subtitle !== undefined &&
            Design_Card_Subtitle.card_price_schattenfarbe !== undefined
            ? Design_Card_Subtitle.card_price_schattenfarbe
            : "rgba(0,0,0,1)"
        );
        setDB_LeftSidebar_MenuItem_ProduktPreis_ShadowColorNotEncoded(
          Design_Card_Subtitle !== undefined &&
            Design_Card_Subtitle.card_price_schattenfarbe_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Card_Subtitle.card_price_schattenfarbe_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 1 }
        );
        setDB_LeftSidebar_MenuItem_ProduktPreis_ShadowHorizontal(
          Design_Card_Subtitle !== undefined &&
            Design_Card_Subtitle.card_price_schattenposition_horizontal !==
              undefined
            ? Design_Card_Subtitle.card_price_schattenposition_horizontal
            : "2"
        );
        setDB_LeftSidebar_MenuItem_ProduktPreis_ShadowVertical(
          Design_Card_Subtitle !== undefined &&
            Design_Card_Subtitle.card_price_schattenposition_vertikal !==
              undefined
            ? Design_Card_Subtitle.card_price_schattenposition_vertikal
            : "2"
        );
        setDB_LeftSidebar_MenuItem_ProduktPreis_ShadowBlur(
          Design_Card_Subtitle !== undefined &&
            Design_Card_Subtitle.card_price_schattenrauschen !== undefined
            ? Design_Card_Subtitle.card_price_schattenrauschen
            : "3"
        );
        setDB_LeftSidebar_MenuItem_ProduktPreis_FontSize(
          Design_Card_Subtitle !== undefined &&
            Design_Card_Subtitle.card_price_schriftgroesse !== undefined
            ? Design_Card_Subtitle.card_price_schriftgroesse
            : "22"
        );
        setDB_LeftSidebar_MenuItem_ProduktPreis_FontWeight(
          Design_Card_Subtitle !== undefined &&
            Design_Card_Subtitle.card_price_schriftgewicht !== undefined
            ? Design_Card_Subtitle.card_price_schriftgewicht
            : "100"
        );
        setDB_LeftSidebar_MenuItem_ProduktPreis_letterSpacing(
          Design_Card_Subtitle !== undefined &&
            Design_Card_Subtitle.card_price_buchstabenabstand !== undefined
            ? Design_Card_Subtitle.card_price_buchstabenabstand
            : "0"
        );
        setDB_LeftSidebar_MenuItem_ProduktPreis_TextDecoration(
          Design_Card_Subtitle !== undefined &&
            Design_Card_Subtitle.card_price_text_dekoration !== undefined
            ? Design_Card_Subtitle.card_price_text_dekoration
            : "none"
        );
        setDB_LeftSidebar_MenuItem_ProduktPreis_TextDecorationStyle(
          Design_Card_Subtitle !== undefined &&
            Design_Card_Subtitle.card_price_text_dekoration_stil !== undefined
            ? Design_Card_Subtitle.card_price_text_dekoration_stil
            : "none"
        );
        setDB_LeftSidebar_MenuItem_ProduktPreis_marginLeftRight(
          Design_Card_Subtitle !== undefined &&
            Design_Card_Subtitle.card_price_aussen_abstand_links_rechts !==
              undefined
            ? Design_Card_Subtitle.card_price_aussen_abstand_links_rechts
            : "7"
        );

        setDB_LeftSidebar_MenuItem_ProduktPreis_marginTopBottom(
          Design_Card_Subtitle !== undefined &&
            Design_Card_Subtitle.card_price_aussen_abstand_oben_unten !==
              undefined
            ? Design_Card_Subtitle.card_price_aussen_abstand_oben_unten
            : "-5"
        );
        setDB_LeftSidebar_MenuItem_ProduktPreis_paddingLeftRight(
          Design_Card_Subtitle !== undefined &&
            Design_Card_Subtitle.card_price_innerer_abstand_links_rechts !==
              undefined
            ? Design_Card_Subtitle.card_price_innerer_abstand_links_rechts
            : "0"
        );
        setDB_LeftSidebar_MenuItem_ProduktPreis_paddingTopBottom(
          Design_Card_Subtitle !== undefined &&
            Design_Card_Subtitle.card_price_innerer_abstand_oben_unten !==
              undefined
            ? Design_Card_Subtitle.card_price_innerer_abstand_oben_unten
            : "0"
        );
        setDB_LeftSidebar_MenuItem_ProduktPreis_TextAlign(
          Design_Card_Subtitle !== undefined &&
            Design_Card_Subtitle.card_price_textausrichtung !== undefined
            ? Design_Card_Subtitle.card_price_textausrichtung
            : "left"
        );

        setDB_fontFace_chooseFontWaehrung_FontFamilyName(
          Design_Card_Subtitle !== undefined &&
            Design_Card_Subtitle.card_price_fontfamily !== undefined
            ? Design_Card_Subtitle.card_price_fontfamily
            : "Lato"
        );

        setDB_fontFace_chooseFontWaehrung_FontFamilySrc(
          Design_Card_Subtitle !== undefined &&
            Design_Card_Subtitle.card_price_fontfamilysrc !== undefined
            ? Design_Card_Subtitle.card_price_fontfamilysrc
            : "/assets/Lato/Lato-Regular.ttf"
        );

        setDB_LeftSidebar_MenuItem_ProduktPreis_Hover_FontColor(
          Design_Card_Subtitle !== undefined &&
            Design_Card_Subtitle.card_price_hov_schriftfarbe !== undefined
            ? Design_Card_Subtitle.card_price_hov_schriftfarbe
            : "rgba(0,255,0,1)"
        );
        setDB_LeftSidebar_MenuItem_ProduktPreis_Hover_FontColorNotEncoded(
          Design_Card_Subtitle !== undefined &&
            Design_Card_Subtitle.card_price_hov_schriftfarbe_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Card_Subtitle.card_price_hov_schriftfarbe_not_encoded
              )
            : { r: 0, g: 255, b: 0, a: 1 }
        );
        setDB_LeftSidebar_MenuItem_ProduktPreis_Hover_BackgroundColor(
          Design_Card_Subtitle !== undefined &&
            Design_Card_Subtitle.card_price_hov_hintergrundfarbe !== undefined
            ? Design_Card_Subtitle.card_price_hov_hintergrundfarbe
            : "rgba(0,0,0,0)"
        );
        setDB_LeftSidebar_MenuItem_ProduktPreis_Hover_BackgroundColorNotEncoded(
          Design_Card_Subtitle !== undefined &&
            Design_Card_Subtitle.card_price_hov_hintergrundfarbe_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Card_Subtitle.card_price_hov_hintergrundfarbe_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0 }
        );
        setDB_LeftSidebar_MenuItem_ProduktPreis_ShadowColor_Hover(
          Design_Card_Subtitle !== undefined &&
            Design_Card_Subtitle.card_price_hov_schattenfarbe !== undefined
            ? Design_Card_Subtitle.card_price_hov_schattenfarbe
            : "rgba(0,0,0,1)"
        );
        setDB_LeftSidebar_MenuItem_ProduktPreis_ShadowColorNotEncoded_Hover(
          Design_Card_Subtitle !== undefined &&
            Design_Card_Subtitle.card_price_hov_schattenfarbe_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Card_Subtitle.card_price_hov_schattenfarbe_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 1 }
        );
        setDB_LeftSidebar_MenuItem_ProduktPreis_ShadowHorizontal_Hover(
          Design_Card_Subtitle !== undefined &&
            Design_Card_Subtitle.card_price_hov_schattenposition_horizontal !==
              undefined
            ? Design_Card_Subtitle.card_price_hov_schattenposition_horizontal
            : "2"
        );
        setDB_LeftSidebar_MenuItem_ProduktPreis_ShadowVertical_Hover(
          Design_Card_Subtitle !== undefined &&
            Design_Card_Subtitle.card_price_hov_schattenposition_vertikal !==
              undefined
            ? Design_Card_Subtitle.card_price_hov_schattenposition_vertikal
            : "2"
        );
        setDB_LeftSidebar_MenuItem_ProduktPreis_ShadowBlur_Hover(
          Design_Card_Subtitle !== undefined &&
            Design_Card_Subtitle.card_price_hov_schattenrauschen !== undefined
            ? Design_Card_Subtitle.card_price_hov_schattenrauschen
            : "3"
        );
        setDB_LeftSidebar_MenuItem_ProduktPreis_Hover_FontSize(
          Design_Card_Subtitle !== undefined &&
            Design_Card_Subtitle.card_price_hov_schriftgroesse !== undefined
            ? Design_Card_Subtitle.card_price_hov_schriftgroesse
            : "22"
        );
        setDB_LeftSidebar_MenuItem_ProduktPreis_Hover_FontWeight(
          Design_Card_Subtitle !== undefined &&
            Design_Card_Subtitle.card_price_hov_schriftgewicht !== undefined
            ? Design_Card_Subtitle.card_price_hov_schriftgewicht
            : "100"
        );
        setDB_LeftSidebar_MenuItem_ProduktPreis_letterSpacing_Hover(
          Design_Card_Subtitle !== undefined &&
            Design_Card_Subtitle.card_price_hov_buchstabenabstand !== undefined
            ? Design_Card_Subtitle.card_price_hov_buchstabenabstand
            : "0"
        );
        setDB_LeftSidebar_MenuItem_ProduktPreis_TextDecoration_Hover(
          Design_Card_Subtitle !== undefined &&
            Design_Card_Subtitle.card_price_hov_text_dekoration !== undefined
            ? Design_Card_Subtitle.card_price_hov_text_dekoration
            : "none"
        );
        setDB_LeftSidebar_MenuItem_ProduktPreis_TextDecorationStyle_Hover(
          Design_Card_Subtitle !== undefined &&
            Design_Card_Subtitle.card_price_hov_text_dekoration_stil !==
              undefined
            ? Design_Card_Subtitle.card_price_hov_text_dekoration_stil
            : "none"
        );
        setDB_LeftSidebar_MenuItem_ProduktPreis_marginLeftRight_Hover(
          Design_Card_Subtitle !== undefined &&
            Design_Card_Subtitle.card_price_hov_aussen_abstand_links_rechts !==
              undefined
            ? Design_Card_Subtitle.card_price_hov_aussen_abstand_links_rechts
            : "7"
        );
        setDB_LeftSidebar_MenuItem_ProduktPreis_marginTopBottom_Hover(
          Design_Card_Subtitle !== undefined &&
            Design_Card_Subtitle.card_price_hov_aussen_abstand_oben_unten !==
              undefined
            ? Design_Card_Subtitle.card_price_hov_aussen_abstand_oben_unten
            : "-5"
        );
        setDB_LeftSidebar_MenuItem_ProduktPreis_paddingLeftRight_Hover(
          Design_Card_Subtitle !== undefined &&
            Design_Card_Subtitle.card_price_hov_innerer_abstand_links_rechts !==
              undefined
            ? Design_Card_Subtitle.card_price_hov_innerer_abstand_links_rechts
            : "0"
        );
        setDB_LeftSidebar_MenuItem_ProduktPreis_paddingTopBottom_Hover(
          Design_Card_Subtitle !== undefined &&
            Design_Card_Subtitle.card_price_hov_innerer_abstand_oben_unten !==
              undefined
            ? Design_Card_Subtitle.card_price_hov_innerer_abstand_oben_unten
            : "0"
        );
        setDB_LeftSidebar_MenuItem_ProduktPreis_Hover_TextAlign(
          Design_Card_Subtitle !== undefined &&
            Design_Card_Subtitle.card_price_hov_textausrichtung !== undefined
            ? Design_Card_Subtitle.card_price_hov_textausrichtung
            : "left"
        );

        setDB_LeftSidebar_MenuItem_ProduktPreis_BorderColorLeft(
          Design_Card_Subtitle !== undefined &&
            Design_Card_Subtitle.card_price_border_color_left !== undefined
            ? Design_Card_Subtitle.card_price_border_color_left
            : "rgba(0,0,0,0)"
        );
        setDB_LeftSidebar_MenuItem_ProduktPreis_BorderColorLeftNotEncoded(
          Design_Card_Subtitle !== undefined &&
            Design_Card_Subtitle.card_price_border_color_left_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Card_Subtitle.card_price_border_color_left_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0 }
        );
        setDB_LeftSidebar_MenuItem_ProduktPreis_BorderColorRight(
          Design_Card_Subtitle !== undefined &&
            Design_Card_Subtitle.card_price_border_color_right !== undefined
            ? Design_Card_Subtitle.card_price_border_color_right
            : "rgba(0,0,0,0)"
        );
        setDB_LeftSidebar_MenuItem_ProduktPreis_BorderColorRightNotEncoded(
          Design_Card_Subtitle !== undefined &&
            Design_Card_Subtitle.card_price_border_color_right_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Card_Subtitle.card_price_border_color_right_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0 }
        );
        setDB_LeftSidebar_MenuItem_ProduktPreis_BorderColorTop(
          Design_Card_Subtitle !== undefined &&
            Design_Card_Subtitle.card_price_border_color_top !== undefined
            ? Design_Card_Subtitle.card_price_border_color_top
            : "rgba(0,0,0,0)"
        );
        setDB_LeftSidebar_MenuItem_ProduktPreis_BorderColorTopNotEncoded(
          Design_Card_Subtitle !== undefined &&
            Design_Card_Subtitle.card_price_border_color_top_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Card_Subtitle.card_price_border_color_top_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0 }
        );
        setDB_LeftSidebar_MenuItem_ProduktPreis_BorderColorBottom(
          Design_Card_Subtitle !== undefined &&
            Design_Card_Subtitle.card_price_border_color_bottom !== undefined
            ? Design_Card_Subtitle.card_price_border_color_bottom
            : "rgba(0,0,0,0)"
        );
        setDB_LeftSidebar_MenuItem_ProduktPreis_BorderColorBottomNotEncoded(
          Design_Card_Subtitle !== undefined &&
            Design_Card_Subtitle.card_price_border_color_bottom_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Card_Subtitle.card_price_border_color_bottom_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0 }
        );

        setDB_LeftSidebar_MenuItem_ProduktPreis_BorderLeft(
          Design_Card_Subtitle !== undefined &&
            Design_Card_Subtitle.card_price_border_pixel_left !== undefined
            ? Design_Card_Subtitle.card_price_border_pixel_left
            : "0"
        );
        setDB_LeftSidebar_MenuItem_ProduktPreis_BorderRight(
          Design_Card_Subtitle !== undefined &&
            Design_Card_Subtitle.card_price_border_pixel_right !== undefined
            ? Design_Card_Subtitle.card_price_border_pixel_right
            : "0"
        );
        setDB_LeftSidebar_MenuItem_ProduktPreis_BorderTop(
          Design_Card_Subtitle !== undefined &&
            Design_Card_Subtitle.card_price_border_pixel_top !== undefined
            ? Design_Card_Subtitle.card_price_border_pixel_top
            : "0"
        );
        setDB_LeftSidebar_MenuItem_ProduktPreis_BorderBottom(
          Design_Card_Subtitle !== undefined &&
            Design_Card_Subtitle.card_price_border_pixel_bottom !== undefined
            ? Design_Card_Subtitle.card_price_border_pixel_bottom
            : "0"
        );

        setDB_LeftSidebar_MenuItem_ProduktPreis_BorderTopLeftRadius(
          Design_Card_Subtitle !== undefined &&
            Design_Card_Subtitle.card_price_border_radius_top_left !== undefined
            ? Design_Card_Subtitle.card_price_border_radius_top_left
            : "0"
        );
        setDB_LeftSidebar_MenuItem_ProduktPreis_BorderTopRightRadius(
          Design_Card_Subtitle !== undefined &&
            Design_Card_Subtitle.card_price_border_radius_top_right !==
              undefined
            ? Design_Card_Subtitle.card_price_border_radius_top_right
            : "0"
        );
        setDB_LeftSidebar_MenuItem_ProduktPreis_BorderBottomLeftRadius(
          Design_Card_Subtitle !== undefined &&
            Design_Card_Subtitle.card_price_border_radius_bottom_left !==
              undefined
            ? Design_Card_Subtitle.card_price_border_radius_bottom_left
            : "0"
        );
        setDB_LeftSidebar_MenuItem_ProduktPreis_BorderBottomRightRadius(
          Design_Card_Subtitle !== undefined &&
            Design_Card_Subtitle.card_price_border_radius_bottom_right !==
              undefined
            ? Design_Card_Subtitle.card_price_border_radius_bottom_right
            : "0"
        );

        setDB_LeftSidebar_MenuItem_ProduktPreis_BorderStyleLeft(
          Design_Card_Subtitle !== undefined &&
            Design_Card_Subtitle.card_price_border_style_left !== undefined
            ? Design_Card_Subtitle.card_price_border_style_left
            : "solid"
        );
        setDB_LeftSidebar_MenuItem_ProduktPreis_BorderStyleRight(
          Design_Card_Subtitle !== undefined &&
            Design_Card_Subtitle.card_price_border_style_right !== undefined
            ? Design_Card_Subtitle.card_price_border_style_right
            : "solid"
        );
        setDB_LeftSidebar_MenuItem_ProduktPreis_BorderStyleTop(
          Design_Card_Subtitle !== undefined &&
            Design_Card_Subtitle.card_price_border_style_top !== undefined
            ? Design_Card_Subtitle.card_price_border_style_top
            : "solid"
        );
        setDB_LeftSidebar_MenuItem_ProduktPreis_BorderStyleBottom(
          Design_Card_Subtitle !== undefined &&
            Design_Card_Subtitle.card_price_border_style_bottom !== undefined
            ? Design_Card_Subtitle.card_price_border_style_bottom
            : "solid"
        );

        setDB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderColorLeft(
          Design_Card_Subtitle !== undefined &&
            Design_Card_Subtitle.card_price_border_hov_color_left !== undefined
            ? Design_Card_Subtitle.card_price_border_hov_color_left
            : "rgba(0,0,0,0)"
        );
        setDB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderColorLeftNotEncoded(
          Design_Card_Subtitle !== undefined &&
            Design_Card_Subtitle.card_price_border_hov_color_left_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Card_Subtitle.card_price_border_hov_color_left_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0 }
        );
        setDB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderColorRight(
          Design_Card_Subtitle !== undefined &&
            Design_Card_Subtitle.card_price_border_hov_color_right !== undefined
            ? Design_Card_Subtitle.card_price_border_hov_color_right
            : "rgba(0,0,0,0)"
        );
        setDB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderColorRightNotEncoded(
          Design_Card_Subtitle !== undefined &&
            Design_Card_Subtitle.card_price_border_hov_color_right_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Card_Subtitle.card_price_border_hov_color_right_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0 }
        );
        setDB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderColorTop(
          Design_Card_Subtitle !== undefined &&
            Design_Card_Subtitle.card_price_border_hov_color_top !== undefined
            ? Design_Card_Subtitle.card_price_border_hov_color_top
            : "rgba(0,0,0,0)"
        );
        setDB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderColorTopNotEncoded(
          Design_Card_Subtitle !== undefined &&
            Design_Card_Subtitle.card_price_border_hov_color_top_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Card_Subtitle.card_price_border_hov_color_top_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0 }
        );
        setDB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderColorBottom(
          Design_Card_Subtitle !== undefined &&
            Design_Card_Subtitle.card_price_border_hov_color_bottom !==
              undefined
            ? Design_Card_Subtitle.card_price_border_hov_color_bottom
            : "rgba(0,0,0,0)"
        );
        setDB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderColorBottomNotEncoded(
          Design_Card_Subtitle !== undefined &&
            Design_Card_Subtitle.card_price_border_hov_color_bottom_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Card_Subtitle.card_price_border_hov_color_bottom_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0 }
        );

        setDB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderLeft(
          Design_Card_Subtitle !== undefined &&
            Design_Card_Subtitle.card_price_border_hov_pixel_left !== undefined
            ? Design_Card_Subtitle.card_price_border_hov_pixel_left
            : "0"
        );
        setDB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderRight(
          Design_Card_Subtitle !== undefined &&
            Design_Card_Subtitle.card_price_border_hov_pixel_right !== undefined
            ? Design_Card_Subtitle.card_price_border_hov_pixel_right
            : "0"
        );
        setDB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderTop(
          Design_Card_Subtitle !== undefined &&
            Design_Card_Subtitle.card_price_border_hov_pixel_top !== undefined
            ? Design_Card_Subtitle.card_price_border_hov_pixel_top
            : "0"
        );
        setDB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderBottom(
          Design_Card_Subtitle !== undefined &&
            Design_Card_Subtitle.card_price_border_hov_pixel_bottom !==
              undefined
            ? Design_Card_Subtitle.card_price_border_hov_pixel_bottom
            : "0"
        );

        setDB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderTopLeftRadius(
          Design_Card_Subtitle !== undefined &&
            Design_Card_Subtitle.card_price_border_hov_radius_top_left !==
              undefined
            ? Design_Card_Subtitle.card_price_border_hov_radius_top_left
            : "0"
        );
        setDB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderTopRightRadius(
          Design_Card_Subtitle !== undefined &&
            Design_Card_Subtitle.card_price_border_hov_radius_top_right !==
              undefined
            ? Design_Card_Subtitle.card_price_border_hov_radius_top_right
            : "0"
        );
        setDB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderBottomLeftRadius(
          Design_Card_Subtitle !== undefined &&
            Design_Card_Subtitle.card_price_border_hov_radius_bottom_left !==
              undefined
            ? Design_Card_Subtitle.card_price_border_hov_radius_bottom_left
            : "0"
        );
        setDB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderBottomRightRadius(
          Design_Card_Subtitle !== undefined &&
            Design_Card_Subtitle.card_price_border_hov_radius_bottom_right !==
              undefined
            ? Design_Card_Subtitle.card_price_border_hov_radius_bottom_right
            : "0"
        );

        setDB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderStyleLeft(
          Design_Card_Subtitle !== undefined &&
            Design_Card_Subtitle.card_price_border_hov_style_left !== undefined
            ? Design_Card_Subtitle.card_price_border_hov_style_left
            : "solid"
        );
        setDB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderStyleRight(
          Design_Card_Subtitle !== undefined &&
            Design_Card_Subtitle.card_price_border_hov_style_right !== undefined
            ? Design_Card_Subtitle.card_price_border_hov_style_right
            : "solid"
        );
        setDB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderStyleTop(
          Design_Card_Subtitle !== undefined &&
            Design_Card_Subtitle.card_price_border_hov_style_top !== undefined
            ? Design_Card_Subtitle.card_price_border_hov_style_top
            : "solid"
        );
        setDB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderStyleBottom(
          Design_Card_Subtitle !== undefined &&
            Design_Card_Subtitle.card_price_border_hov_style_bottom !==
              undefined
            ? Design_Card_Subtitle.card_price_border_hov_style_bottom
            : "solid"
        );

        setDB_LeftSidebar_MenuItem_ProduktWaehrung_FontColor(
          Design_Card_Subtitle !== undefined &&
            Design_Card_Subtitle.card_currency_schriftfarbe !== undefined
            ? Design_Card_Subtitle.card_currency_schriftfarbe
            : "rgba(255,0,0,1)"
        );
        setDB_LeftSidebar_MenuItem_ProduktWaehrung_FontColorNotEncoded(
          Design_Card_Subtitle !== undefined &&
            Design_Card_Subtitle.card_currency_schriftfarbe_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Card_Subtitle.card_currency_schriftfarbe_not_encoded
              )
            : { r: 255, g: 0, b: 0, a: 1 }
        );
        setDB_LeftSidebar_MenuItem_ProduktWaehrung_BackgroundColor(
          Design_Card_Subtitle !== undefined &&
            Design_Card_Subtitle.card_currency_hintergrundfarbe !== undefined
            ? Design_Card_Subtitle.card_currency_hintergrundfarbe
            : "rgba(0,0,0,0)"
        );
        setDB_LeftSidebar_MenuItem_ProduktWaehrung_BackgroundColorNotEncoded(
          Design_Card_Subtitle !== undefined &&
            Design_Card_Subtitle.card_currency_hintergrundfarbe_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Card_Subtitle.card_currency_hintergrundfarbe_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0 }
        );
        setDB_LeftSidebar_MenuItem_ProduktWaehrung_ShadowColor(
          Design_Card_Subtitle !== undefined &&
            Design_Card_Subtitle.card_currency_schattenfarbe !== undefined
            ? Design_Card_Subtitle.card_currency_schattenfarbe
            : "rgba(0,0,0,0)"
        );
        setDB_LeftSidebar_MenuItem_ProduktWaehrung_ShadowColorNotEncoded(
          Design_Card_Subtitle !== undefined &&
            Design_Card_Subtitle.card_currency_schattenfarbe_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Card_Subtitle.card_currency_schattenfarbe_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0 }
        );
        setDB_LeftSidebar_MenuItem_ProduktWaehrung_ShadowHorizontal(
          Design_Card_Subtitle !== undefined &&
            Design_Card_Subtitle.card_currency_schattenposition_horizontal !==
              undefined
            ? Design_Card_Subtitle.card_currency_schattenposition_horizontal
            : "2"
        );
        setDB_LeftSidebar_MenuItem_ProduktWaehrung_ShadowVertical(
          Design_Card_Subtitle !== undefined &&
            Design_Card_Subtitle.card_currency_schattenposition_vertikal !==
              undefined
            ? Design_Card_Subtitle.card_currency_schattenposition_vertikal
            : "2"
        );
        setDB_LeftSidebar_MenuItem_ProduktWaehrung_ShadowBlur(
          Design_Card_Subtitle !== undefined &&
            Design_Card_Subtitle.card_currency_schattenrauschen !== undefined
            ? Design_Card_Subtitle.card_currency_schattenrauschen
            : "3"
        );
        setDB_LeftSidebar_MenuItem_ProduktWaehrung_FontSize(
          Design_Card_Subtitle !== undefined &&
            Design_Card_Subtitle.card_currency_schriftgroesse !== undefined
            ? Design_Card_Subtitle.card_currency_schriftgroesse
            : "13"
        );
        setDB_LeftSidebar_MenuItem_ProduktWaehrung_FontWeight(
          Design_Card_Subtitle !== undefined &&
            Design_Card_Subtitle.card_currency_schriftgewicht !== undefined
            ? Design_Card_Subtitle.card_currency_schriftgewicht
            : "100"
        );
        setDB_LeftSidebar_MenuItem_ProduktWaehrung_letterSpacing(
          Design_Card_Subtitle !== undefined &&
            Design_Card_Subtitle.card_currency_buchstabenabstand !== undefined
            ? Design_Card_Subtitle.card_currency_buchstabenabstand
            : "0"
        );
        setDB_LeftSidebar_MenuItem_ProduktWaehrung_TextDecoration(
          Design_Card_Subtitle !== undefined &&
            Design_Card_Subtitle.card_currency_text_dekoration !== undefined
            ? Design_Card_Subtitle.card_currency_text_dekoration
            : "line-through"
        );
        setDB_LeftSidebar_MenuItem_ProduktWaehrung_TextDecorationStyle(
          Design_Card_Subtitle !== undefined &&
            Design_Card_Subtitle.card_currency_text_dekoration_stil !==
              undefined
            ? Design_Card_Subtitle.card_currency_text_dekoration_stil
            : "solid"
        );
        setDB_LeftSidebar_MenuItem_ProduktWaehrung_marginLeftRight(
          Design_Card_Subtitle !== undefined &&
            Design_Card_Subtitle.card_currency_aussen_abstand_links_rechts !==
              undefined
            ? Design_Card_Subtitle.card_currency_aussen_abstand_links_rechts
            : "0"
        );
        setDB_LeftSidebar_MenuItem_ProduktWaehrung_marginTopBottom(
          Design_Card_Subtitle !== undefined &&
            Design_Card_Subtitle.card_currency_aussen_abstand_oben_unten !==
              undefined
            ? Design_Card_Subtitle.card_currency_aussen_abstand_oben_unten
            : "-6"
        );
        setDB_LeftSidebar_MenuItem_ProduktWaehrung_paddingLeftRight(
          Design_Card_Subtitle !== undefined &&
            Design_Card_Subtitle.card_currency_innerer_abstand_links_rechts !==
              undefined
            ? Design_Card_Subtitle.card_currency_innerer_abstand_links_rechts
            : "0"
        );
        setDB_LeftSidebar_MenuItem_ProduktWaehrung_paddingTopBottom(
          Design_Card_Subtitle !== undefined &&
            Design_Card_Subtitle.card_currency_innerer_abstand_oben_unten !==
              undefined
            ? Design_Card_Subtitle.card_currency_innerer_abstand_oben_unten
            : "14"
        );
        setDB_LeftSidebar_MenuItem_ProduktWaehrung_TextAlign(
          Design_Card_Subtitle !== undefined &&
            Design_Card_Subtitle.card_currency_textausrichtung !== undefined
            ? Design_Card_Subtitle.card_currency_textausrichtung
            : "left"
        );

        setDB_fontFace_chooseFontWaehrungEuro_FontFamilyName(
          Design_Card_Subtitle !== undefined &&
            Design_Card_Subtitle.card_currency_fontfamily !== undefined
            ? Design_Card_Subtitle.card_currency_fontfamily
            : "Lato"
        );

        setDB_fontFace_chooseFontWaehrungEuro_FontFamilySrc(
          Design_Card_Subtitle !== undefined &&
            Design_Card_Subtitle.card_currency_fontfamilysrc !== undefined
            ? Design_Card_Subtitle.card_currency_fontfamilysrc
            : "/assets/Lato/Lato-Regular.ttf"
        );

        setDB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_FontColor(
          Design_Card_Subtitle !== undefined &&
            Design_Card_Subtitle.card_currency_hov_schriftfarbe !== undefined
            ? Design_Card_Subtitle.card_currency_hov_schriftfarbe
            : "rgba(255,0,0,1)"
        );
        setDB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_FontColorNotEncoded(
          Design_Card_Subtitle !== undefined &&
            Design_Card_Subtitle.card_currency_hov_schriftfarbe_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Card_Subtitle.card_currency_hov_schriftfarbe_not_encoded
              )
            : { r: 255, g: 0, b: 0, a: 1 }
        );
        setDB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BackgroundColor(
          Design_Card_Subtitle !== undefined &&
            Design_Card_Subtitle.card_currency_hov_hintergrundfarbe !==
              undefined
            ? Design_Card_Subtitle.card_currency_hov_hintergrundfarbe
            : "rgba(0,0,0,0)"
        );
        setDB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BackgroundColorNotEncoded(
          Design_Card_Subtitle !== undefined &&
            Design_Card_Subtitle.card_currency_hov_hintergrundfarbe_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Card_Subtitle.card_currency_hov_hintergrundfarbe_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0 }
        );
        setDB_LeftSidebar_MenuItem_ProduktWaehrung_ShadowColor_Hover(
          Design_Card_Subtitle !== undefined &&
            Design_Card_Subtitle.card_currency_hov_schattenfarbe !== undefined
            ? Design_Card_Subtitle.card_currency_hov_schattenfarbe
            : "rgba(0,0,0,0)"
        );
        setDB_LeftSidebar_MenuItem_ProduktWaehrung_ShadowColorNotEncoded_Hover(
          Design_Card_Subtitle !== undefined &&
            Design_Card_Subtitle.card_currency_hov_schattenfarbe_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Card_Subtitle.card_currency_hov_schattenfarbe_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0 }
        );
        setDB_LeftSidebar_MenuItem_ProduktWaehrung_ShadowHorizontal_Hover(
          Design_Card_Subtitle !== undefined &&
            Design_Card_Subtitle.card_currency_hov_schattenposition_horizontal !==
              undefined
            ? Design_Card_Subtitle.card_currency_hov_schattenposition_horizontal
            : "0"
        );
        setDB_LeftSidebar_MenuItem_ProduktWaehrung_ShadowVertical_Hover(
          Design_Card_Subtitle !== undefined &&
            Design_Card_Subtitle.card_currency_hov_schattenposition_vertikal !==
              undefined
            ? Design_Card_Subtitle.card_currency_hov_schattenposition_vertikal
            : "0"
        );
        setDB_LeftSidebar_MenuItem_ProduktWaehrung_ShadowBlur_Hover(
          Design_Card_Subtitle !== undefined &&
            Design_Card_Subtitle.card_currency_hov_schattenrauschen !==
              undefined
            ? Design_Card_Subtitle.card_currency_hov_schattenrauschen
            : "0"
        );
        setDB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_FontSize(
          Design_Card_Subtitle !== undefined &&
            Design_Card_Subtitle.card_currency_hov_schriftgroesse !== undefined
            ? Design_Card_Subtitle.card_currency_hov_schriftgroesse
            : "13"
        );
        setDB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_FontWeight(
          Design_Card_Subtitle !== undefined &&
            Design_Card_Subtitle.card_currency_hov_schriftgewicht !== undefined
            ? Design_Card_Subtitle.card_currency_hov_schriftgewicht
            : "100"
        );
        setDB_LeftSidebar_MenuItem_ProduktWaehrung_letterSpacing_Hover(
          Design_Card_Subtitle !== undefined &&
            Design_Card_Subtitle.card_currency_hov_buchstabenabstand !==
              undefined
            ? Design_Card_Subtitle.card_currency_hov_buchstabenabstand
            : "0"
        );
        setDB_LeftSidebar_MenuItem_ProduktWaehrung_TextDecoration_Hover(
          Design_Card_Subtitle !== undefined &&
            Design_Card_Subtitle.card_currency_hov_text_dekoration !== undefined
            ? Design_Card_Subtitle.card_currency_hov_text_dekoration
            : "line-through"
        );
        setDB_LeftSidebar_MenuItem_ProduktWaehrung_TextDecorationStyle_Hover(
          Design_Card_Subtitle !== undefined &&
            Design_Card_Subtitle.card_currency_hov_text_dekoration_stil !==
              undefined
            ? Design_Card_Subtitle.card_currency_hov_text_dekoration_stil
            : "solid"
        );
        setDB_LeftSidebar_MenuItem_ProduktWaehrung_marginLeftRight_Hover(
          Design_Card_Subtitle !== undefined &&
            Design_Card_Subtitle.card_currency_hov_aussen_abstand_links_rechts !==
              undefined
            ? Design_Card_Subtitle.card_currency_hov_aussen_abstand_links_rechts
            : "0"
        );
        setDB_LeftSidebar_MenuItem_ProduktWaehrung_marginTopBottom_Hover(
          Design_Card_Subtitle !== undefined &&
            Design_Card_Subtitle.card_currency_hov_aussen_abstand_oben_unten !==
              undefined
            ? Design_Card_Subtitle.card_currency_hov_aussen_abstand_oben_unten
            : "-6"
        );
        setDB_LeftSidebar_MenuItem_ProduktWaehrung_paddingLeftRight_Hover(
          Design_Card_Subtitle !== undefined &&
            Design_Card_Subtitle.card_currency_hov_innerer_abstand_links_rechts !==
              undefined
            ? Design_Card_Subtitle.card_currency_hov_innerer_abstand_links_rechts
            : "0"
        );
        setDB_LeftSidebar_MenuItem_ProduktWaehrung_paddingTopBottom_Hover(
          Design_Card_Subtitle !== undefined &&
            Design_Card_Subtitle.card_currency_hov_innerer_abstand_oben_unten !==
              undefined
            ? Design_Card_Subtitle.card_currency_hov_innerer_abstand_oben_unten
            : "14"
        );
        setDB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_TextAlign(
          Design_Card_Subtitle !== undefined &&
            Design_Card_Subtitle.card_currency_hov_textausrichtung !== undefined
            ? Design_Card_Subtitle.card_currency_hov_textausrichtung
            : "left"
        );

        setDB_LeftSidebar_MenuItem_ProduktWaehrung_BorderColorLeft(
          Design_Card_Subtitle !== undefined &&
            Design_Card_Subtitle.card_currency_border_color_left !== undefined
            ? Design_Card_Subtitle.card_currency_border_color_left
            : "rgba(0,0,0,0)"
        );
        setDB_LeftSidebar_MenuItem_ProduktWaehrung_BorderColorLeftNotEncoded(
          Design_Card_Subtitle !== undefined &&
            Design_Card_Subtitle.card_currency_border_color_left_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Card_Subtitle.card_currency_border_color_left_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0 }
        );
        setDB_LeftSidebar_MenuItem_ProduktWaehrung_BorderColorRight(
          Design_Card_Subtitle !== undefined &&
            Design_Card_Subtitle.card_currency_border_color_right !== undefined
            ? Design_Card_Subtitle.card_currency_border_color_right
            : "rgba(0,0,0,0)"
        );
        setDB_LeftSidebar_MenuItem_ProduktWaehrung_BorderColorRightNotEncoded(
          Design_Card_Subtitle !== undefined &&
            Design_Card_Subtitle.card_currency_border_color_right_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Card_Subtitle.card_currency_border_color_right_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0 }
        );
        setDB_LeftSidebar_MenuItem_ProduktWaehrung_BorderColorTop(
          Design_Card_Subtitle !== undefined &&
            Design_Card_Subtitle.card_currency_border_color_top !== undefined
            ? Design_Card_Subtitle.card_currency_border_color_top
            : "rgba(0,0,0,0)"
        );
        setDB_LeftSidebar_MenuItem_ProduktWaehrung_BorderColorTopNotEncoded(
          Design_Card_Subtitle !== undefined &&
            Design_Card_Subtitle.card_currency_border_color_top_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Card_Subtitle.card_currency_border_color_top_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0 }
        );
        setDB_LeftSidebar_MenuItem_ProduktWaehrung_BorderColorBottom(
          Design_Card_Subtitle !== undefined &&
            Design_Card_Subtitle.card_currency_border_color_bottom !== undefined
            ? Design_Card_Subtitle.card_currency_border_color_bottom
            : "rgba(0,0,0,0)"
        );
        setDB_LeftSidebar_MenuItem_ProduktWaehrung_BorderColorBottomNotEncoded(
          Design_Card_Subtitle !== undefined &&
            Design_Card_Subtitle.card_currency_border_color_bottom_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Card_Subtitle.card_currency_border_color_bottom_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0 }
        );

        setDB_LeftSidebar_MenuItem_ProduktWaehrung_BorderLeft(
          Design_Card_Subtitle !== undefined &&
            Design_Card_Subtitle.card_currency_border_pixel_left !== undefined
            ? Design_Card_Subtitle.card_currency_border_pixel_left
            : "0"
        );
        setDB_LeftSidebar_MenuItem_ProduktWaehrung_BorderRight(
          Design_Card_Subtitle !== undefined &&
            Design_Card_Subtitle.card_currency_border_pixel_right !== undefined
            ? Design_Card_Subtitle.card_currency_border_pixel_right
            : "0"
        );
        setDB_LeftSidebar_MenuItem_ProduktWaehrung_BorderTop(
          Design_Card_Subtitle !== undefined &&
            Design_Card_Subtitle.card_currency_border_pixel_top !== undefined
            ? Design_Card_Subtitle.card_currency_border_pixel_top
            : "0"
        );
        setDB_LeftSidebar_MenuItem_ProduktWaehrung_BorderBottom(
          Design_Card_Subtitle !== undefined &&
            Design_Card_Subtitle.card_currency_border_pixel_bottom !== undefined
            ? Design_Card_Subtitle.card_currency_border_pixel_bottom
            : "0"
        );

        setDB_LeftSidebar_MenuItem_ProduktWaehrung_BorderTopLeftRadius(
          Design_Card_Subtitle !== undefined &&
            Design_Card_Subtitle.card_currency_border_radius_top_left !==
              undefined
            ? Design_Card_Subtitle.card_currency_border_radius_top_left
            : "0"
        );
        setDB_LeftSidebar_MenuItem_ProduktWaehrung_BorderTopRightRadius(
          Design_Card_Subtitle !== undefined &&
            Design_Card_Subtitle.card_currency_border_radius_top_right !==
              undefined
            ? Design_Card_Subtitle.card_currency_border_radius_top_right
            : "0"
        );
        setDB_LeftSidebar_MenuItem_ProduktWaehrung_BorderBottomLeftRadius(
          Design_Card_Subtitle !== undefined &&
            Design_Card_Subtitle.card_currency_border_radius_bottom_left !==
              undefined
            ? Design_Card_Subtitle.card_currency_border_radius_bottom_left
            : "0"
        );
        setDB_LeftSidebar_MenuItem_ProduktWaehrung_BorderBottomRightRadius(
          Design_Card_Subtitle !== undefined &&
            Design_Card_Subtitle.card_currency_border_radius_bottom_right !==
              undefined
            ? Design_Card_Subtitle.card_currency_border_radius_bottom_right
            : "0"
        );

        setDB_LeftSidebar_MenuItem_ProduktWaehrung_BorderStyleLeft(
          Design_Card_Subtitle !== undefined &&
            Design_Card_Subtitle.card_currency_border_style_left !== undefined
            ? Design_Card_Subtitle.card_currency_border_style_left
            : "solid"
        );
        setDB_LeftSidebar_MenuItem_ProduktWaehrung_BorderStyleRight(
          Design_Card_Subtitle !== undefined &&
            Design_Card_Subtitle.card_currency_border_style_right !== undefined
            ? Design_Card_Subtitle.card_currency_border_style_right
            : "solid"
        );
        setDB_LeftSidebar_MenuItem_ProduktWaehrung_BorderStyleTop(
          Design_Card_Subtitle !== undefined &&
            Design_Card_Subtitle.card_currency_border_style_top !== undefined
            ? Design_Card_Subtitle.card_currency_border_style_top
            : "solid"
        );
        setDB_LeftSidebar_MenuItem_ProduktWaehrung_BorderStyleBottom(
          Design_Card_Subtitle !== undefined &&
            Design_Card_Subtitle.card_currency_border_style_bottom !== undefined
            ? Design_Card_Subtitle.card_currency_border_style_bottom
            : "solid"
        );

        setDB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderColorLeft(
          Design_Card_Subtitle !== undefined &&
            Design_Card_Subtitle.card_currency_border_hov_color_left !==
              undefined
            ? Design_Card_Subtitle.card_currency_border_hov_color_left
            : "rgba(0,0,0,0)"
        );
        setDB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderColorLeftNotEncoded(
          Design_Card_Subtitle !== undefined &&
            Design_Card_Subtitle.card_currency_border_hov_color_left_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Card_Subtitle.card_currency_border_hov_color_left_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0 }
        );
        setDB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderColorRight(
          Design_Card_Subtitle !== undefined &&
            Design_Card_Subtitle.card_currency_border_hov_color_right !==
              undefined
            ? Design_Card_Subtitle.card_currency_border_hov_color_right
            : "rgba(0,0,0,0)"
        );
        setDB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderColorRightNotEncoded(
          Design_Card_Subtitle !== undefined &&
            Design_Card_Subtitle.card_currency_border_hov_color_right_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Card_Subtitle.card_currency_border_hov_color_right_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0 }
        );
        setDB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderColorTop(
          Design_Card_Subtitle !== undefined &&
            Design_Card_Subtitle.card_currency_border_hov_color_top !==
              undefined
            ? Design_Card_Subtitle.card_currency_border_hov_color_top
            : "rgba(0,0,0,0)"
        );
        setDB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderColorTopNotEncoded(
          Design_Card_Subtitle !== undefined &&
            Design_Card_Subtitle.card_currency_border_hov_color_top_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Card_Subtitle.card_currency_border_hov_color_top_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0 }
        );
        setDB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderColorBottom(
          Design_Card_Subtitle !== undefined &&
            Design_Card_Subtitle.card_currency_border_hov_color_bottom !==
              undefined
            ? Design_Card_Subtitle.card_currency_border_hov_color_bottom
            : "rgba(0,0,0,0)"
        );
        setDB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderColorBottomNotEncoded(
          Design_Card_Subtitle !== undefined &&
            Design_Card_Subtitle.card_currency_border_hov_color_bottom_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Card_Subtitle.card_currency_border_hov_color_bottom_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0 }
        );

        setDB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderLeft(
          Design_Card_Subtitle !== undefined &&
            Design_Card_Subtitle.card_currency_border_hov_pixel_left !==
              undefined
            ? Design_Card_Subtitle.card_currency_border_hov_pixel_left
            : "0"
        );
        setDB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderRight(
          Design_Card_Subtitle !== undefined &&
            Design_Card_Subtitle.card_currency_border_hov_pixel_right !==
              undefined
            ? Design_Card_Subtitle.card_currency_border_hov_pixel_right
            : "0"
        );
        setDB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderTop(
          Design_Card_Subtitle !== undefined &&
            Design_Card_Subtitle.card_currency_border_hov_pixel_top !==
              undefined
            ? Design_Card_Subtitle.card_currency_border_hov_pixel_top
            : "0"
        );
        setDB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderBottom(
          Design_Card_Subtitle !== undefined &&
            Design_Card_Subtitle.card_currency_border_hov_pixel_bottom !==
              undefined
            ? Design_Card_Subtitle.card_currency_border_hov_pixel_bottom
            : "0"
        );

        setDB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderTopLeftRadius(
          Design_Card_Subtitle !== undefined &&
            Design_Card_Subtitle.card_currency_border_hov_radius_top_left !==
              undefined
            ? Design_Card_Subtitle.card_currency_border_hov_radius_top_left
            : "0"
        );
        setDB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderTopRightRadius(
          Design_Card_Subtitle !== undefined &&
            Design_Card_Subtitle.card_currency_border_hov_radius_top_right !==
              undefined
            ? Design_Card_Subtitle.card_currency_border_hov_radius_top_right
            : "0"
        );
        setDB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderBottomLeftRadius(
          Design_Card_Subtitle !== undefined &&
            Design_Card_Subtitle.card_currency_border_hov_radius_bottom_left !==
              undefined
            ? Design_Card_Subtitle.card_currency_border_hov_radius_bottom_left
            : "0"
        );
        setDB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderBottomRightRadius(
          Design_Card_Subtitle !== undefined &&
            Design_Card_Subtitle.card_currency_border_hov_radius_bottom_right !==
              undefined
            ? Design_Card_Subtitle.card_currency_border_hov_radius_bottom_right
            : "0"
        );

        setDB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderStyleLeft(
          Design_Card_Subtitle !== undefined &&
            Design_Card_Subtitle.card_currency_border_hov_style_left !==
              undefined
            ? Design_Card_Subtitle.card_currency_border_hov_style_left
            : "solid"
        );
        setDB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderStyleRight(
          Design_Card_Subtitle !== undefined &&
            Design_Card_Subtitle.card_currency_border_hov_style_right !==
              undefined
            ? Design_Card_Subtitle.card_currency_border_hov_style_right
            : "solid"
        );
        setDB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderStyleTop(
          Design_Card_Subtitle !== undefined &&
            Design_Card_Subtitle.card_currency_border_hov_style_top !==
              undefined
            ? Design_Card_Subtitle.card_currency_border_hov_style_top
            : "solid"
        );
        setDB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderStyleBottom(
          Design_Card_Subtitle !== undefined &&
            Design_Card_Subtitle.card_currency_border_hov_style_bottom !==
              undefined
            ? Design_Card_Subtitle.card_currency_border_hov_style_bottom
            : "solid"
        );

        setDB_LeftSidebar_MenuItem_TitelSuperTitel_FontColor(
          Design_Product_Detail !== undefined &&
            Design_Product_Detail.detail_haupttitel_schriftfarbe !== undefined
            ? Design_Product_Detail.detail_haupttitel_schriftfarbe
            : "rgba(255,255,255,1)"
        );
        setDB_LeftSidebar_MenuItem_TitelSuperTitel_FontColorNotEncoded(
          Design_Product_Detail !== undefined &&
            Design_Product_Detail.detail_haupttitel_schriftfarbe_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Product_Detail.detail_haupttitel_schriftfarbe_not_encoded
              )
            : { r: 255, g: 255, b: 255, a: 1 }
        );
        setDB_LeftSidebar_MenuItem_TitelSuperTitel_BackgroundColor(
          Design_Product_Detail !== undefined &&
            Design_Product_Detail.detail_haupttitel_hintergrundfarbe !==
              undefined
            ? Design_Product_Detail.detail_haupttitel_hintergrundfarbe
            : "rgba(0,0,0,0.4)"
        );
        setDB_LeftSidebar_MenuItem_TitelSuperTitel_BackgroundColorNotEncoded(
          Design_Product_Detail !== undefined &&
            Design_Product_Detail.detail_haupttitel_hintergrundfarbe_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Product_Detail.detail_haupttitel_hintergrundfarbe_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0.4 }
        );
        setDB_LeftSidebar_MenuItem_TitelSuperTitel_ShadowColor(
          Design_Product_Detail !== undefined &&
            Design_Product_Detail.detail_haupttitel_schattenfarbe !== undefined
            ? Design_Product_Detail.detail_haupttitel_schattenfarbe
            : "rgba(0,0,70,0.3)"
        );
        setDB_LeftSidebar_MenuItem_TitelSuperTitel_ShadowColorNotEncoded(
          Design_Product_Detail !== undefined &&
            Design_Product_Detail.detail_haupttitel_schattenfarbe_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Product_Detail.detail_haupttitel_schattenfarbe_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 1 }
        );
        setDB_LeftSidebar_MenuItem_TitelSuperTitel_ShadowHorizontal(
          Design_Product_Detail !== undefined &&
            Design_Product_Detail.detail_haupttitel_schattenposition_horizontal !==
              undefined
            ? Design_Product_Detail.detail_haupttitel_schattenposition_horizontal
            : "2"
        );
        setDB_LeftSidebar_MenuItem_TitelSuperTitel_ShadowVertical(
          Design_Product_Detail !== undefined &&
            Design_Product_Detail.detail_haupttitel_schattenposition_vertikal !==
              undefined
            ? Design_Product_Detail.detail_haupttitel_schattenposition_vertikal
            : "2"
        );
        setDB_LeftSidebar_MenuItem_TitelSuperTitel_ShadowBlur(
          Design_Product_Detail !== undefined &&
            Design_Product_Detail.detail_haupttitel_schattenrauschen !==
              undefined
            ? Design_Product_Detail.detail_haupttitel_schattenrauschen
            : "4"
        );
        setDB_LeftSidebar_MenuItem_TitelSuperTitel_FontSize(
          Design_Product_Detail !== undefined &&
            Design_Product_Detail.detail_haupttitel_schriftgroesse !== undefined
            ? Design_Product_Detail.detail_haupttitel_schriftgroesse
            : "20"
        );
        setDB_LeftSidebar_MenuItem_TitelSuperTitel_FontWeight(
          Design_Product_Detail !== undefined &&
            Design_Product_Detail.detail_haupttitel_schriftgewicht !== undefined
            ? Design_Product_Detail.detail_haupttitel_schriftgewicht
            : "100"
        );
        setDB_LeftSidebar_MenuItem_TitelSuperTitel_letterSpacing(
          Design_Product_Detail !== undefined &&
            Design_Product_Detail.detail_haupttitel_buchstabenabstand !==
              undefined
            ? Design_Product_Detail.detail_haupttitel_buchstabenabstand
            : "1"
        );
        setDB_LeftSidebar_MenuItem_TitelSuperTitel_TextDecoration(
          Design_Product_Detail !== undefined &&
            Design_Product_Detail.detail_haupttitel_text_dekoration !==
              undefined
            ? Design_Product_Detail.detail_haupttitel_text_dekoration
            : "none"
        );
        setDB_LeftSidebar_MenuItem_TitelSuperTitel_TextDecorationStyle(
          Design_Product_Detail !== undefined &&
            Design_Product_Detail.detail_haupttitel_text_dekoration_stil !==
              undefined
            ? Design_Product_Detail.detail_haupttitel_text_dekoration_stil
            : "none"
        );
        setDB_LeftSidebar_MenuItem_TitelSuperTitel_marginLeftRight(
          Design_Product_Detail !== undefined &&
            Design_Product_Detail.detail_haupttitel_aussen_abstand_links_rechts !==
              undefined
            ? Design_Product_Detail.detail_haupttitel_aussen_abstand_links_rechts
            : "0"
        );
        setDB_LeftSidebar_MenuItem_TitelSuperTitel_marginTopBottom(
          Design_Product_Detail !== undefined &&
            Design_Product_Detail.detail_haupttitel_aussen_abstand_oben_unten !==
              undefined
            ? Design_Product_Detail.detail_haupttitel_aussen_abstand_oben_unten
            : "0"
        );
        setDB_LeftSidebar_MenuItem_TitelSuperTitel_paddingLeftRight(
          Design_Product_Detail !== undefined &&
            Design_Product_Detail.detail_haupttitel_innerer_abstand_links_rechts !==
              undefined
            ? Design_Product_Detail.detail_haupttitel_innerer_abstand_links_rechts
            : "0"
        );
        setDB_LeftSidebar_MenuItem_TitelSuperTitel_paddingTopBottom(
          Design_Product_Detail !== undefined &&
            Design_Product_Detail.detail_haupttitel_innerer_abstand_oben_unten !==
              undefined
            ? Design_Product_Detail.detail_haupttitel_innerer_abstand_oben_unten
            : "2"
        );
        setDB_LeftSidebar_MenuItem_TitelSuperTitel_TextAlign(
          Design_Product_Detail !== undefined &&
            Design_Product_Detail.detail_haupttitel_textausrichtung !==
              undefined
            ? Design_Product_Detail.detail_haupttitel_textausrichtung
            : "center"
        );

        setDB_fontFace_chooseFontFamilySuperTitel_FontFamilyName(
          Design_Product_Detail !== undefined &&
            Design_Product_Detail.detail_haupttitel_fontfamily !== undefined
            ? Design_Product_Detail.detail_haupttitel_fontfamily
            : "Lato"
        );

        setDB_fontFace_chooseFontFamilySuperTitel_FontFamilySrc(
          Design_Product_Detail !== undefined &&
            Design_Product_Detail.detail_haupttitel_fontfamilysrc !== undefined
            ? Design_Product_Detail.detail_haupttitel_fontfamilysrc
            : "/assets/Lato/Lato-Regular.ttf"
        );

        setDB_LeftSidebar_MenuItem_TitelSuperTitel_BorderColorLeft(
          Design_Product_Detail !== undefined &&
            Design_Product_Detail.detail_haupttitel_border_color_left !==
              undefined
            ? Design_Product_Detail.detail_haupttitel_border_color_left
            : "rgba(0,0,0,0.3)"
        );
        setDB_LeftSidebar_MenuItem_TitelSuperTitel_BorderColorLeftNotEncoded(
          Design_Product_Detail !== undefined &&
            Design_Product_Detail.detail_haupttitel_border_color_left_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Product_Detail.detail_haupttitel_border_color_left_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0.3 }
        );
        setDB_LeftSidebar_MenuItem_TitelSuperTitel_BorderColorRight(
          Design_Product_Detail !== undefined &&
            Design_Product_Detail.detail_haupttitel_border_color_right !==
              undefined
            ? Design_Product_Detail.detail_haupttitel_border_color_right
            : "rgba(0,0,0,0.3)"
        );
        setDB_LeftSidebar_MenuItem_TitelSuperTitel_BorderColorRightNotEncoded(
          Design_Product_Detail !== undefined &&
            Design_Product_Detail.detail_haupttitel_border_color_right_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Product_Detail.detail_haupttitel_border_color_right_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0.3 }
        );
        setDB_LeftSidebar_MenuItem_TitelSuperTitel_BorderColorTop(
          Design_Product_Detail !== undefined &&
            Design_Product_Detail.detail_haupttitel_border_color_top !==
              undefined
            ? Design_Product_Detail.detail_haupttitel_border_color_top
            : "rgba(0,0,0,0.3)"
        );
        setDB_LeftSidebar_MenuItem_TitelSuperTitel_BorderColorTopNotEncoded(
          Design_Product_Detail !== undefined &&
            Design_Product_Detail.detail_haupttitel_border_color_top_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Product_Detail.detail_haupttitel_border_color_top_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0.3 }
        );
        setDB_LeftSidebar_MenuItem_TitelSuperTitel_BorderColorBottom(
          Design_Product_Detail !== undefined &&
            Design_Product_Detail.detail_haupttitel_border_color_bottom !==
              undefined
            ? Design_Product_Detail.detail_haupttitel_border_color_bottom
            : "rgba(0,0,0,0.3)"
        );
        setDB_LeftSidebar_MenuItem_TitelSuperTitel_BorderColorBottomNotEncoded(
          Design_Product_Detail !== undefined &&
            Design_Product_Detail.detail_haupttitel_border_color_bottom_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Product_Detail.detail_haupttitel_border_color_bottom_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0.3 }
        );

        setDB_LeftSidebar_MenuItem_TitelSuperTitel_BorderLeft(
          Design_Product_Detail !== undefined &&
            Design_Product_Detail.detail_haupttitel_border_pixel_left !==
              undefined
            ? Design_Product_Detail.detail_haupttitel_border_pixel_left
            : "1"
        );
        setDB_LeftSidebar_MenuItem_TitelSuperTitel_BorderRight(
          Design_Product_Detail !== undefined &&
            Design_Product_Detail.detail_haupttitel_border_pixel_right !==
              undefined
            ? Design_Product_Detail.detail_haupttitel_border_pixel_right
            : "1"
        );
        setDB_LeftSidebar_MenuItem_TitelSuperTitel_BorderTop(
          Design_Product_Detail !== undefined &&
            Design_Product_Detail.detail_haupttitel_border_pixel_top !==
              undefined
            ? Design_Product_Detail.detail_haupttitel_border_pixel_top
            : "1"
        );
        setDB_LeftSidebar_MenuItem_TitelSuperTitel_BorderBottom(
          Design_Product_Detail !== undefined &&
            Design_Product_Detail.detail_haupttitel_border_pixel_bottom !==
              undefined
            ? Design_Product_Detail.detail_haupttitel_border_pixel_bottom
            : "1"
        );

        setDB_LeftSidebar_MenuItem_TitelSuperTitel_BorderTopLeftRadius(
          Design_Product_Detail !== undefined &&
            Design_Product_Detail.detail_haupttitel_border_radius_top_left !==
              undefined
            ? Design_Product_Detail.detail_haupttitel_border_radius_top_left
            : "7"
        );
        setDB_LeftSidebar_MenuItem_TitelSuperTitel_BorderTopRightRadius(
          Design_Product_Detail !== undefined &&
            Design_Product_Detail.detail_haupttitel_border_radius_top_right !==
              undefined
            ? Design_Product_Detail.detail_haupttitel_border_radius_top_right
            : "7"
        );
        setDB_LeftSidebar_MenuItem_TitelSuperTitel_BorderBottomLeftRadius(
          Design_Product_Detail !== undefined &&
            Design_Product_Detail.detail_haupttitel_border_radius_bottom_left !==
              undefined
            ? Design_Product_Detail.detail_haupttitel_border_radius_bottom_left
            : "0"
        );
        setDB_LeftSidebar_MenuItem_TitelSuperTitel_BorderBottomRightRadius(
          Design_Product_Detail !== undefined &&
            Design_Product_Detail.detail_haupttitel_border_radius_bottom_right !==
              undefined
            ? Design_Product_Detail.detail_haupttitel_border_radius_bottom_right
            : "0"
        );

        setDB_LeftSidebar_MenuItem_TitelSuperTitel_BorderStyleLeft(
          Design_Product_Detail !== undefined &&
            Design_Product_Detail.detail_haupttitel_border_style_left !==
              undefined
            ? Design_Product_Detail.detail_haupttitel_border_style_left
            : "solid"
        );
        setDB_LeftSidebar_MenuItem_TitelSuperTitel_BorderStyleRight(
          Design_Product_Detail !== undefined &&
            Design_Product_Detail.detail_haupttitel_border_style_right !==
              undefined
            ? Design_Product_Detail.detail_haupttitel_border_style_right
            : "solid"
        );
        setDB_LeftSidebar_MenuItem_TitelSuperTitel_BorderStyleTop(
          Design_Product_Detail !== undefined &&
            Design_Product_Detail.detail_haupttitel_border_style_top !==
              undefined
            ? Design_Product_Detail.detail_haupttitel_border_style_top
            : "solid"
        );
        setDB_LeftSidebar_MenuItem_TitelSuperTitel_BorderStyleBottom(
          Design_Product_Detail !== undefined &&
            Design_Product_Detail.detail_haupttitel_border_style_bottom !==
              undefined
            ? Design_Product_Detail.detail_haupttitel_border_style_bottom
            : "solid"
        );

        setDB_LeftSidebar_MenuItem_TitelDetailHauptTitel_FontColor(
          Design_Product_Detail !== undefined &&
            Design_Product_Detail.detail_propTitel_schriftfarbe !== undefined
            ? Design_Product_Detail.detail_propTitel_schriftfarbe
            : "rgba(255,255,255,1)"
        );
        setDB_LeftSidebar_MenuItem_TitelDetailHauptTitel_FontColorNotEncoded(
          Design_Product_Detail !== undefined &&
            Design_Product_Detail.detail_propTitel_schriftfarbe_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Product_Detail.detail_propTitel_schriftfarbe_not_encoded
              )
            : { r: 255, g: 255, b: 255, a: 1 }
        );
        setDB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BackgroundColor(
          Design_Product_Detail !== undefined &&
            Design_Product_Detail.detail_propTitel_hintergrundfarbe !==
              undefined
            ? Design_Product_Detail.detail_propTitel_hintergrundfarbe
            : "rgba(0,0,0,0.4)"
        );
        setDB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BackgroundColorNotEncoded(
          Design_Product_Detail !== undefined &&
            Design_Product_Detail.detail_propTitel_hintergrundfarbe_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Product_Detail.detail_propTitel_hintergrundfarbe_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0.4 }
        );
        setDB_LeftSidebar_MenuItem_TitelDetailHauptTitel_ShadowColor(
          Design_Product_Detail !== undefined &&
            Design_Product_Detail.detail_propTitel_schattenfarbe !== undefined
            ? Design_Product_Detail.detail_propTitel_schattenfarbe
            : "rgba(0,0,0,1)"
        );
        setDB_LeftSidebar_MenuItem_TitelDetailHauptTitel_ShadowColorNotEncoded(
          Design_Product_Detail !== undefined &&
            Design_Product_Detail.detail_propTitel_schattenfarbe_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Product_Detail.detail_propTitel_schattenfarbe_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 1 }
        );
        setDB_LeftSidebar_MenuItem_TitelDetailHauptTitel_ShadowHorizontal(
          Design_Product_Detail !== undefined &&
            Design_Product_Detail.detail_propTitel_schattenposition_horizontal !==
              undefined
            ? Design_Product_Detail.detail_propTitel_schattenposition_horizontal
            : "2"
        );
        setDB_LeftSidebar_MenuItem_TitelDetailHauptTitel_ShadowVertical(
          Design_Product_Detail !== undefined &&
            Design_Product_Detail.detail_propTitel_schattenposition_vertikal !==
              undefined
            ? Design_Product_Detail.detail_propTitel_schattenposition_vertikal
            : "2"
        );
        setDB_LeftSidebar_MenuItem_TitelDetailHauptTitel_ShadowBlur(
          Design_Product_Detail !== undefined &&
            Design_Product_Detail.detail_propTitel_schattenrauschen !==
              undefined
            ? Design_Product_Detail.detail_propTitel_schattenrauschen
            : "3"
        );
        setDB_LeftSidebar_MenuItem_TitelDetailHauptTitel_FontSize(
          Design_Product_Detail !== undefined &&
            Design_Product_Detail.detail_propTitel_schriftgroesse !== undefined
            ? Design_Product_Detail.detail_propTitel_schriftgroesse
            : "18"
        );
        setDB_LeftSidebar_MenuItem_TitelDetailHauptTitel_FontWeight(
          Design_Product_Detail !== undefined &&
            Design_Product_Detail.detail_propTitel_schriftgewicht !== undefined
            ? Design_Product_Detail.detail_propTitel_schriftgewicht
            : "100"
        );
        setDB_LeftSidebar_MenuItem_TitelDetailHauptTitel_letterSpacing(
          Design_Product_Detail !== undefined &&
            Design_Product_Detail.detail_propTitel_buchstabenabstand !==
              undefined
            ? Design_Product_Detail.detail_propTitel_buchstabenabstand
            : "0"
        );
        setDB_LeftSidebar_MenuItem_TitelDetailHauptTitel_TextDecoration(
          Design_Product_Detail !== undefined &&
            Design_Product_Detail.detail_propTitel_text_dekoration !== undefined
            ? Design_Product_Detail.detail_propTitel_text_dekoration
            : "none"
        );
        setDB_LeftSidebar_MenuItem_TitelDetailHauptTitel_TextDecorationStyle(
          Design_Product_Detail !== undefined &&
            Design_Product_Detail.detail_propTitel_text_dekoration_stil !==
              undefined
            ? Design_Product_Detail.detail_propTitel_text_dekoration_stil
            : "none"
        );
        setDB_LeftSidebar_MenuItem_TitelDetailHauptTitel_marginLeftRight(
          Design_Product_Detail !== undefined &&
            Design_Product_Detail.detail_propTitel_aussen_abstand_links_rechts !==
              undefined
            ? Design_Product_Detail.detail_propTitel_aussen_abstand_links_rechts
            : "25"
        );
        setDB_LeftSidebar_MenuItem_TitelDetailHauptTitel_marginTopBottom(
          Design_Product_Detail !== undefined &&
            Design_Product_Detail.detail_propTitel_aussen_abstand_oben_unten !==
              undefined
            ? Design_Product_Detail.detail_propTitel_aussen_abstand_oben_unten
            : "0"
        );
        setDB_LeftSidebar_MenuItem_TitelDetailHauptTitel_paddingLeftRight(
          Design_Product_Detail !== undefined &&
            Design_Product_Detail.detail_propTitel_innerer_abstand_links_rechts !==
              undefined
            ? Design_Product_Detail.detail_propTitel_innerer_abstand_links_rechts
            : "0"
        );
        setDB_LeftSidebar_MenuItem_TitelDetailHauptTitel_paddingTopBottom(
          Design_Product_Detail !== undefined &&
            Design_Product_Detail.detail_propTitel_innerer_abstand_oben_unten !==
              undefined
            ? Design_Product_Detail.detail_propTitel_innerer_abstand_oben_unten
            : "2"
        );
        setDB_LeftSidebar_MenuItem_TitelDetailHauptTitel_TextAlign(
          Design_Product_Detail !== undefined &&
            Design_Product_Detail.detail_propTitel_textausrichtung !== undefined
            ? Design_Product_Detail.detail_propTitel_textausrichtung
            : "center"
        );

        setDB_fontFace_chooseFontHaupttitelFontFamily_FontFamilyName(
          Design_Product_Detail !== undefined &&
            Design_Product_Detail.detail_propTitel_fontfamily !== undefined
            ? Design_Product_Detail.detail_propTitel_fontfamily
            : "Lato"
        );

        setDB_fontFace_chooseFontHaupttitelFontFamily_FontFamilySrc(
          Design_Product_Detail !== undefined &&
            Design_Product_Detail.detail_propTitel_fontfamilysrc !== undefined
            ? Design_Product_Detail.detail_propTitel_fontfamilysrc
            : "/assets/Lato/Lato-Regular.ttf"
        );

        setDB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderColorLeft(
          Design_Product_Detail !== undefined &&
            Design_Product_Detail.detail_propTitel_border_color_left !==
              undefined
            ? Design_Product_Detail.detail_propTitel_border_color_left
            : "rgba(0,0,0,0.3)"
        );
        setDB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderColorLeftNotEncoded(
          Design_Product_Detail !== undefined &&
            Design_Product_Detail.detail_propTitel_border_color_left_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Product_Detail.detail_propTitel_border_color_left_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0.3 }
        );
        setDB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderColorRight(
          Design_Product_Detail !== undefined &&
            Design_Product_Detail.detail_propTitel_border_color_right !==
              undefined
            ? Design_Product_Detail.detail_propTitel_border_color_right
            : "rgba(0,0,0,0.3)"
        );
        setDB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderColorRightNotEncoded(
          Design_Product_Detail !== undefined &&
            Design_Product_Detail.detail_propTitel_border_color_right_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Product_Detail.detail_propTitel_border_color_right_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0.3 }
        );
        setDB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderColorTop(
          Design_Product_Detail !== undefined &&
            Design_Product_Detail.detail_propTitel_border_color_top !==
              undefined
            ? Design_Product_Detail.detail_propTitel_border_color_top
            : "rgba(0,0,0,0.3)"
        );
        setDB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderColorTopNotEncoded(
          Design_Product_Detail !== undefined &&
            Design_Product_Detail.detail_propTitel_border_color_top_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Product_Detail.detail_propTitel_border_color_top_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0.3 }
        );
        setDB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderColorBottom(
          Design_Product_Detail !== undefined &&
            Design_Product_Detail.detail_propTitel_border_color_bottom !==
              undefined
            ? Design_Product_Detail.detail_propTitel_border_color_bottom
            : "rgba(0,0,0,0.3)"
        );
        setDB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderColorBottomNotEncoded(
          Design_Product_Detail !== undefined &&
            Design_Product_Detail.detail_propTitel_border_color_bottom_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Product_Detail.detail_propTitel_border_color_bottom_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0.3 }
        );

        setDB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderLeft(
          Design_Product_Detail !== undefined &&
            Design_Product_Detail.detail_propTitel_border_pixel_left !==
              undefined
            ? Design_Product_Detail.detail_propTitel_border_pixel_left
            : "1"
        );
        setDB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderRight(
          Design_Product_Detail !== undefined &&
            Design_Product_Detail.detail_propTitel_border_pixel_right !==
              undefined
            ? Design_Product_Detail.detail_propTitel_border_pixel_right
            : "1"
        );
        setDB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderTop(
          Design_Product_Detail !== undefined &&
            Design_Product_Detail.detail_propTitel_border_pixel_top !==
              undefined
            ? Design_Product_Detail.detail_propTitel_border_pixel_top
            : "1"
        );
        setDB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderBottom(
          Design_Product_Detail !== undefined &&
            Design_Product_Detail.detail_propTitel_border_pixel_bottom !==
              undefined
            ? Design_Product_Detail.detail_propTitel_border_pixel_bottom
            : "1"
        );

        setDB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderTopLeftRadius(
          Design_Product_Detail !== undefined &&
            Design_Product_Detail.detail_propTitel_border_radius_top_left !==
              undefined
            ? Design_Product_Detail.detail_propTitel_border_radius_top_left
            : "7"
        );
        setDB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderTopRightRadius(
          Design_Product_Detail !== undefined &&
            Design_Product_Detail.detail_propTitel_border_radius_top_right !==
              undefined
            ? Design_Product_Detail.detail_propTitel_border_radius_top_right
            : "7"
        );
        setDB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderBottomLeftRadius(
          Design_Product_Detail !== undefined &&
            Design_Product_Detail.detail_propTitel_border_radius_bottom_left !==
              undefined
            ? Design_Product_Detail.detail_propTitel_border_radius_bottom_left
            : "0"
        );
        setDB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderBottomRightRadius(
          Design_Product_Detail !== undefined &&
            Design_Product_Detail.detail_propTitel_border_radius_bottom_right !==
              undefined
            ? Design_Product_Detail.detail_propTitel_border_radius_bottom_right
            : "0"
        );

        setDB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderStyleLeft(
          Design_Product_Detail !== undefined &&
            Design_Product_Detail.detail_propTitel_border_style_left !==
              undefined
            ? Design_Product_Detail.detail_propTitel_border_style_left
            : "solid"
        );
        setDB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderStyleRight(
          Design_Product_Detail !== undefined &&
            Design_Product_Detail.detail_propTitel_border_style_right !==
              undefined
            ? Design_Product_Detail.detail_propTitel_border_style_right
            : "solid"
        );
        setDB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderStyleTop(
          Design_Product_Detail !== undefined &&
            Design_Product_Detail.detail_propTitel_border_style_top !==
              undefined
            ? Design_Product_Detail.detail_propTitel_border_style_top
            : "solid"
        );
        setDB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderStyleBottom(
          Design_Product_Detail !== undefined &&
            Design_Product_Detail.detail_propTitel_border_style_bottom !==
              undefined
            ? Design_Product_Detail.detail_propTitel_border_style_bottom
            : "solid"
        );

        setDB_LeftSidebar_MenuItem_TitelDetailBeschreibung_FontColor(
          Design_Product_Detail !== undefined &&
            Design_Product_Detail.detail_propValues_schriftfarbe !== undefined
            ? Design_Product_Detail.detail_propValues_schriftfarbe
            : "rgba(255,255,255,1)"
        );
        setDB_LeftSidebar_MenuItem_TitelDetailBeschreibung_FontColorNotEncoded(
          Design_Product_Detail !== undefined &&
            Design_Product_Detail.detail_propValues_schriftfarbe_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Product_Detail.detail_propValues_schriftfarbe_not_encoded
              )
            : { r: 255, g: 255, b: 255, a: 1 }
        );
        setDB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BackgroundColor(
          Design_Product_Detail !== undefined &&
            Design_Product_Detail.detail_propValues_hintergrundfarbe !==
              undefined
            ? Design_Product_Detail.detail_propValues_hintergrundfarbe
            : "rgba(0,0,0,0.2)"
        );
        setDB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BackgroundColorNotEncoded(
          Design_Product_Detail !== undefined &&
            Design_Product_Detail.detail_propValues_hintergrundfarbe_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Product_Detail.detail_propValues_hintergrundfarbe_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0.2 }
        );
        setDB_LeftSidebar_MenuItem_TitelDetailBeschreibung_ShadowColor(
          Design_Product_Detail !== undefined &&
            Design_Product_Detail.detail_propValues_schattenfarbe !== undefined
            ? Design_Product_Detail.detail_propValues_schattenfarbe
            : "rgba(0,0,0,1)"
        );
        setDB_LeftSidebar_MenuItem_TitelDetailBeschreibung_ShadowColorNotEncoded(
          Design_Product_Detail !== undefined &&
            Design_Product_Detail.detail_propValues_schattenfarbe_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Product_Detail.detail_propValues_schattenfarbe_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 1 }
        );
        setDB_LeftSidebar_MenuItem_TitelDetailBeschreibung_ShadowHorizontal(
          Design_Product_Detail !== undefined &&
            Design_Product_Detail.detail_propValues_schattenposition_horizontal !==
              undefined
            ? Design_Product_Detail.detail_propValues_schattenposition_horizontal
            : "2"
        );
        setDB_LeftSidebar_MenuItem_TitelDetailBeschreibung_ShadowVertical(
          Design_Product_Detail !== undefined &&
            Design_Product_Detail.detail_propValues_schattenposition_vertikal !==
              undefined
            ? Design_Product_Detail.detail_propValues_schattenposition_vertikal
            : "2"
        );
        setDB_LeftSidebar_MenuItem_TitelDetailBeschreibung_ShadowBlur(
          Design_Product_Detail !== undefined &&
            Design_Product_Detail.detail_propValues_schattenrauschen !==
              undefined
            ? Design_Product_Detail.detail_propValues_schattenrauschen
            : "3"
        );
        setDB_LeftSidebar_MenuItem_TitelDetailBeschreibung_FontSize(
          Design_Product_Detail !== undefined &&
            Design_Product_Detail.detail_propValues_schriftgroesse !== undefined
            ? Design_Product_Detail.detail_propValues_schriftgroesse
            : "16"
        );
        setDB_LeftSidebar_MenuItem_TitelDetailBeschreibung_FontWeight(
          Design_Product_Detail !== undefined &&
            Design_Product_Detail.detail_propValues_schriftgewicht !== undefined
            ? Design_Product_Detail.detail_propValues_schriftgewicht
            : "100"
        );
        setDB_LeftSidebar_MenuItem_TitelDetailBeschreibung_letterSpacing(
          Design_Product_Detail !== undefined &&
            Design_Product_Detail.detail_propValues_buchstabenabstand !==
              undefined
            ? Design_Product_Detail.detail_propValues_buchstabenabstand
            : "0"
        );
        setDB_LeftSidebar_MenuItem_TitelDetailBeschreibung_TextDecoration(
          Design_Product_Detail !== undefined &&
            Design_Product_Detail.detail_propValues_text_dekoration !==
              undefined
            ? Design_Product_Detail.detail_propValues_text_dekoration
            : "none"
        );
        setDB_LeftSidebar_MenuItem_TitelDetailBeschreibung_TextDecorationStyle(
          Design_Product_Detail !== undefined &&
            Design_Product_Detail.detail_propValues_text_dekoration_stil !==
              undefined
            ? Design_Product_Detail.detail_propValues_text_dekoration_stil
            : "none"
        );
        setDB_LeftSidebar_MenuItem_TitelDetailBeschreibung_marginLeftRight(
          Design_Product_Detail !== undefined &&
            Design_Product_Detail.detail_propValues_aussen_abstand_links_rechts !==
              undefined
            ? Design_Product_Detail.detail_propValues_aussen_abstand_links_rechts
            : "25"
        );

        setDB_LeftSidebar_MenuItem_TitelDetailBeschreibung_marginTopBottom(
          Design_Product_Detail !== undefined &&
            Design_Product_Detail.detail_propValues_aussen_abstand_oben_unten !==
              undefined
            ? Design_Product_Detail.detail_propValues_aussen_abstand_oben_unten
            : "0"
        );
        setDB_LeftSidebar_MenuItem_TitelDetailBeschreibung_paddingLeftRight(
          Design_Product_Detail !== undefined &&
            Design_Product_Detail.detail_propValues_innerer_abstand_links_rechts !==
              undefined
            ? Design_Product_Detail.detail_propValues_innerer_abstand_links_rechts
            : "12"
        );
        setDB_LeftSidebar_MenuItem_TitelDetailBeschreibung_paddingTopBottom(
          Design_Product_Detail !== undefined &&
            Design_Product_Detail.detail_propValues_innerer_abstand_oben_unten !==
              undefined
            ? Design_Product_Detail.detail_propValues_innerer_abstand_oben_unten
            : "8"
        );
        setDB_LeftSidebar_MenuItem_TitelDetailBeschreibung_TextAlign(
          Design_Product_Detail !== undefined &&
            Design_Product_Detail.detail_propValues_textausrichtung !==
              undefined
            ? Design_Product_Detail.detail_propValues_textausrichtung
            : "left"
        );

        setDB_fontFace_chooseFontBeschreibungFontFamily_FontFamilyName(
          Design_Product_Detail !== undefined &&
            Design_Product_Detail.detail_propValues_fontfamily !== undefined
            ? Design_Product_Detail.detail_propValues_fontfamily
            : "Lato"
        );

        setDB_fontFace_chooseFontBeschreibungFontFamily_FontFamilySrc(
          Design_Product_Detail !== undefined &&
            Design_Product_Detail.detail_propValues_fontfamilysrc !== undefined
            ? Design_Product_Detail.detail_propValues_fontfamilysrc
            : "/assets/Lato/Lato-Regular.ttf"
        );

        setDB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderColorLeft(
          Design_Product_Detail !== undefined &&
            Design_Product_Detail.detail_propValues_border_color_left !==
              undefined
            ? Design_Product_Detail.detail_propValues_border_color_left
            : "rgba(0,0,0,0.3)"
        );
        setDB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderColorLeftNotEncoded(
          Design_Product_Detail !== undefined &&
            Design_Product_Detail.detail_propValues_border_color_left_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Product_Detail.detail_propValues_border_color_left_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0.3 }
        );
        setDB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderColorRight(
          Design_Product_Detail !== undefined &&
            Design_Product_Detail.detail_propValues_border_color_right !==
              undefined
            ? Design_Product_Detail.detail_propValues_border_color_right
            : "rgba(0,0,0,0.3)"
        );
        setDB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderColorRightNotEncoded(
          Design_Product_Detail !== undefined &&
            Design_Product_Detail.detail_propValues_border_color_right_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Product_Detail.detail_propValues_border_color_right_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0.3 }
        );
        setDB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderColorTop(
          Design_Product_Detail !== undefined &&
            Design_Product_Detail.detail_propValues_border_color_top !==
              undefined
            ? Design_Product_Detail.detail_propValues_border_color_top
            : "rgba(0,0,0,0.3)"
        );
        setDB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderColorTopNotEncoded(
          Design_Product_Detail !== undefined &&
            Design_Product_Detail.detail_propValues_border_color_top_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Product_Detail.detail_propValues_border_color_top_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0.3 }
        );
        setDB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderColorBottom(
          Design_Product_Detail !== undefined &&
            Design_Product_Detail.detail_propValues_border_color_bottom !==
              undefined
            ? Design_Product_Detail.detail_propValues_border_color_bottom
            : "rgba(0,0,0,0.3)"
        );
        setDB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderColorBottomNotEncoded(
          Design_Product_Detail !== undefined &&
            Design_Product_Detail.detail_propValues_border_color_bottom_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Product_Detail.detail_propValues_border_color_bottom_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0.3 }
        );

        setDB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderLeft(
          Design_Product_Detail !== undefined &&
            Design_Product_Detail.detail_propValues_border_pixel_left !==
              undefined
            ? Design_Product_Detail.detail_propValues_border_pixel_left
            : "1"
        );
        setDB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderRight(
          Design_Product_Detail !== undefined &&
            Design_Product_Detail.detail_propValues_border_pixel_right !==
              undefined
            ? Design_Product_Detail.detail_propValues_border_pixel_right
            : "1"
        );
        setDB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderTop(
          Design_Product_Detail !== undefined &&
            Design_Product_Detail.detail_propValues_border_pixel_top !==
              undefined
            ? Design_Product_Detail.detail_propValues_border_pixel_top
            : "0"
        );
        setDB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderBottom(
          Design_Product_Detail !== undefined &&
            Design_Product_Detail.detail_propValues_border_pixel_bottom !==
              undefined
            ? Design_Product_Detail.detail_propValues_border_pixel_bottom
            : "1"
        );

        setDB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderTopLeftRadius(
          Design_Product_Detail !== undefined &&
            Design_Product_Detail.detail_propValues_border_radius_top_left !==
              undefined
            ? Design_Product_Detail.detail_propValues_border_radius_top_left
            : "0"
        );
        setDB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderTopRightRadius(
          Design_Product_Detail !== undefined &&
            Design_Product_Detail.detail_propValues_border_radius_top_right !==
              undefined
            ? Design_Product_Detail.detail_propValues_border_radius_top_right
            : "0"
        );
        setDB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderBottomLeftRadius(
          Design_Product_Detail !== undefined &&
            Design_Product_Detail.detail_propValues_border_radius_bottom_left !==
              undefined
            ? Design_Product_Detail.detail_propValues_border_radius_bottom_left
            : "7"
        );
        setDB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderBottomRightRadius(
          Design_Product_Detail !== undefined &&
            Design_Product_Detail.detail_propValues_border_radius_bottom_right !==
              undefined
            ? Design_Product_Detail.detail_propValues_border_radius_bottom_right
            : "7"
        );

        setDB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderStyleLeft(
          Design_Product_Detail !== undefined &&
            Design_Product_Detail.detail_propValues_border_style_left !==
              undefined
            ? Design_Product_Detail.detail_propValues_border_style_left
            : "solid"
        );
        setDB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderStyleRight(
          Design_Product_Detail !== undefined &&
            Design_Product_Detail.detail_propValues_border_style_right !==
              undefined
            ? Design_Product_Detail.detail_propValues_border_style_right
            : "solid"
        );
        setDB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderStyleTop(
          Design_Product_Detail !== undefined &&
            Design_Product_Detail.detail_propValues_border_style_top !==
              undefined
            ? Design_Product_Detail.detail_propValues_border_style_top
            : "solid"
        );
        setDB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderStyleBottom(
          Design_Product_Detail !== undefined &&
            Design_Product_Detail.detail_propValues_border_style_bottom !==
              undefined
            ? Design_Product_Detail.detail_propValues_border_style_bottom
            : "solid"
        );

        setDB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_FontColor(
          Design_Product_Detail !== undefined &&
            Design_Product_Detail.detail_formTitle_schriftfarbe !== undefined
            ? Design_Product_Detail.detail_formTitle_schriftfarbe
            : "rgba(255,255,255,1)"
        );
        setDB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_FontColorNotEncoded(
          Design_Product_Detail !== undefined &&
            Design_Product_Detail.detail_formTitle_schriftfarbe_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Product_Detail.detail_formTitle_schriftfarbe_not_encoded
              )
            : { r: 255, g: 255, b: 255, a: 1 }
        );
        setDB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_BackgroundColor(
          Design_Product_Detail !== undefined &&
            Design_Product_Detail.detail_formTitle_hintergrundfarbe !==
              undefined
            ? Design_Product_Detail.detail_formTitle_hintergrundfarbe
            : "rgba(0,0,0,0.4)"
        );
        setDB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_BackgroundColorNotEncoded(
          Design_Product_Detail !== undefined &&
            Design_Product_Detail.detail_formTitle_hintergrundfarbe_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Product_Detail.detail_formTitle_hintergrundfarbe_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0.3 }
        );
        setDB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_ShadowColor(
          Design_Product_Detail !== undefined &&
            Design_Product_Detail.detail_formTitle_schattenfarbe !== undefined
            ? Design_Product_Detail.detail_formTitle_schattenfarbe
            : "rgba(0,0,0,1)"
        );
        setDB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_ShadowColorNotEncoded(
          Design_Product_Detail !== undefined &&
            Design_Product_Detail.detail_formTitle_schattenfarbe_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Product_Detail.detail_formTitle_schattenfarbe_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 1 }
        );
        setDB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_ShadowHorizontal(
          Design_Product_Detail !== undefined &&
            Design_Product_Detail.detail_formTitle_schattenposition_horizontal !==
              undefined
            ? Design_Product_Detail.detail_formTitle_schattenposition_horizontal
            : "2"
        );
        setDB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_ShadowVertical(
          Design_Product_Detail !== undefined &&
            Design_Product_Detail.detail_formTitle_schattenposition_vertikal !==
              undefined
            ? Design_Product_Detail.detail_formTitle_schattenposition_vertikal
            : "2"
        );
        setDB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_ShadowBlur(
          Design_Product_Detail !== undefined &&
            Design_Product_Detail.detail_formTitle_schattenrauschen !==
              undefined
            ? Design_Product_Detail.detail_formTitle_schattenrauschen
            : "3"
        );
        setDB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_FontSize(
          Design_Product_Detail !== undefined &&
            Design_Product_Detail.detail_formTitle_schriftgroesse !== undefined
            ? Design_Product_Detail.detail_formTitle_schriftgroesse
            : "19"
        );
        setDB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_FontWeight(
          Design_Product_Detail !== undefined &&
            Design_Product_Detail.detail_formTitle_schriftgewicht !== undefined
            ? Design_Product_Detail.detail_formTitle_schriftgewicht
            : "100"
        );
        setDB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_letterSpacing(
          Design_Product_Detail !== undefined &&
            Design_Product_Detail.detail_formTitle_buchstabenabstand !==
              undefined
            ? Design_Product_Detail.detail_formTitle_buchstabenabstand
            : "0"
        );
        setDB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_TextDecoration(
          Design_Product_Detail !== undefined &&
            Design_Product_Detail.detail_formTitle_text_dekoration !== undefined
            ? Design_Product_Detail.detail_formTitle_text_dekoration
            : "none"
        );
        setDB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_TextDecorationStyle(
          Design_Product_Detail !== undefined &&
            Design_Product_Detail.detail_formTitle_text_dekoration_stil !==
              undefined
            ? Design_Product_Detail.detail_formTitle_text_dekoration_stil
            : "none"
        );
        setDB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_marginLeftRight(
          Design_Product_Detail !== undefined &&
            Design_Product_Detail.detail_formTitle_aussen_abstand_links_rechts !==
              undefined
            ? Design_Product_Detail.detail_formTitle_aussen_abstand_links_rechts
            : "0"
        );
        setDB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_marginTopBottom(
          Design_Product_Detail !== undefined &&
            Design_Product_Detail.detail_formTitle_aussen_abstand_oben_unten !==
              undefined
            ? Design_Product_Detail.detail_formTitle_aussen_abstand_oben_unten
            : "0"
        );

        setDB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_paddingLeftRight(
          Design_Product_Detail !== undefined &&
            Design_Product_Detail.detail_formTitle_innerer_abstand_links_rechts !==
              undefined
            ? Design_Product_Detail.detail_formTitle_innerer_abstand_links_rechts
            : "0"
        );
        setDB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_paddingTopBottom(
          Design_Product_Detail !== undefined &&
            Design_Product_Detail.detail_formTitle_innerer_abstand_oben_unten !==
              undefined
            ? Design_Product_Detail.detail_formTitle_innerer_abstand_oben_unten
            : "0"
        );
        setDB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_TextAlign(
          Design_Product_Detail !== undefined &&
            Design_Product_Detail.detail_formTitle_textausrichtung !== undefined
            ? Design_Product_Detail.detail_formTitle_textausrichtung
            : "center"
        );

        setDB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_FontFamilyName(
          Design_Product_Detail !== undefined &&
            Design_Product_Detail.detail_formTitle_fontfamily !== undefined
            ? Design_Product_Detail.detail_formTitle_fontfamily
            : "Lato"
        );

        setDB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_FontFamilySrc(
          Design_Product_Detail !== undefined &&
            Design_Product_Detail.detail_formTitle_fontfamilysrc !== undefined
            ? Design_Product_Detail.detail_formTitle_fontfamilysrc
            : "/assets/Lato/Lato-Regular.ttf"
        );

        setDB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_BorderColorLeft(
          Design_Product_Detail !== undefined &&
            Design_Product_Detail.detail_formTitle_border_color_left !==
              undefined
            ? Design_Product_Detail.detail_formTitle_border_color_left
            : "rgba(0,0,0,0.3)"
        );
        setDB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_BorderColorLeftNotEncoded(
          Design_Product_Detail !== undefined &&
            Design_Product_Detail.detail_formTitle_border_color_left_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Product_Detail.detail_formTitle_border_color_left_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0.3 }
        );
        setDB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_BorderColorRight(
          Design_Product_Detail !== undefined &&
            Design_Product_Detail.detail_formTitle_border_color_right !==
              undefined
            ? Design_Product_Detail.detail_formTitle_border_color_right
            : "rgba(0,0,0,0.3)"
        );
        setDB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_BorderColorRightNotEncoded(
          Design_Product_Detail !== undefined &&
            Design_Product_Detail.detail_formTitle_border_color_right_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Product_Detail.detail_formTitle_border_color_right_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0.3 }
        );
        setDB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_BorderColorTop(
          Design_Product_Detail !== undefined &&
            Design_Product_Detail.detail_formTitle_border_color_top !==
              undefined
            ? Design_Product_Detail.detail_formTitle_border_color_top
            : "rgba(0,0,0,0.3)"
        );
        setDB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_BorderColorTopNotEncoded(
          Design_Product_Detail !== undefined &&
            Design_Product_Detail.detail_formTitle_border_color_top_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Product_Detail.detail_formTitle_border_color_top_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0.3 }
        );
        setDB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_BorderColorBottom(
          Design_Product_Detail !== undefined &&
            Design_Product_Detail.detail_formTitle_border_color_bottom !==
              undefined
            ? Design_Product_Detail.detail_formTitle_border_color_bottom
            : "rgba(0,0,0,0.3)"
        );
        setDB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_BorderColorBottomNotEncoded(
          Design_Product_Detail !== undefined &&
            Design_Product_Detail.detail_formTitle_border_color_bottom_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Product_Detail.detail_formTitle_border_color_bottom_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0.3 }
        );

        setDB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_BorderLeft(
          Design_Product_Detail !== undefined &&
            Design_Product_Detail.detail_formTitle_border_pixel_left !==
              undefined
            ? Design_Product_Detail.detail_formTitle_border_pixel_left
            : "1"
        );
        setDB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_BorderRight(
          Design_Product_Detail !== undefined &&
            Design_Product_Detail.detail_formTitle_border_pixel_right !==
              undefined
            ? Design_Product_Detail.detail_formTitle_border_pixel_right
            : "1"
        );
        setDB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_BorderTop(
          Design_Product_Detail !== undefined &&
            Design_Product_Detail.detail_formTitle_border_pixel_top !==
              undefined
            ? Design_Product_Detail.detail_formTitle_border_pixel_top
            : "1"
        );
        setDB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_BorderBottom(
          Design_Product_Detail !== undefined &&
            Design_Product_Detail.detail_formTitle_border_pixel_bottom !==
              undefined
            ? Design_Product_Detail.detail_formTitle_border_pixel_bottom
            : "1"
        );

        setDB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_BorderTopLeftRadius(
          Design_Product_Detail !== undefined &&
            Design_Product_Detail.detail_formTitle_border_radius_top_left !==
              undefined
            ? Design_Product_Detail.detail_formTitle_border_radius_top_left
            : "7"
        );
        setDB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_BorderTopRightRadius(
          Design_Product_Detail !== undefined &&
            Design_Product_Detail.detail_formTitle_border_radius_top_right !==
              undefined
            ? Design_Product_Detail.detail_formTitle_border_radius_top_right
            : "7"
        );
        setDB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_BorderBottomLeftRadius(
          Design_Product_Detail !== undefined &&
            Design_Product_Detail.detail_formTitle_border_radius_bottom_left !==
              undefined
            ? Design_Product_Detail.detail_formTitle_border_radius_bottom_left
            : "7"
        );
        setDB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_BorderBottomRightRadius(
          Design_Product_Detail !== undefined &&
            Design_Product_Detail.detail_formTitle_border_radius_bottom_right !==
              undefined
            ? Design_Product_Detail.detail_formTitle_border_radius_bottom_right
            : "7"
        );

        setDB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_BorderStyleLeft(
          Design_Product_Detail !== undefined &&
            Design_Product_Detail.detail_formTitle_border_style_left !==
              undefined
            ? Design_Product_Detail.detail_formTitle_border_style_left
            : "solid"
        );
        setDB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_BorderStyleRight(
          Design_Product_Detail !== undefined &&
            Design_Product_Detail.detail_formTitle_border_style_right !==
              undefined
            ? Design_Product_Detail.detail_formTitle_border_style_right
            : "solid"
        );
        setDB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_BorderStyleTop(
          Design_Product_Detail !== undefined &&
            Design_Product_Detail.detail_formTitle_border_style_top !==
              undefined
            ? Design_Product_Detail.detail_formTitle_border_style_top
            : "solid"
        );
        setDB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_BorderStyleBottom(
          Design_Product_Detail !== undefined &&
            Design_Product_Detail.detail_formTitle_border_style_bottom !==
              undefined
            ? Design_Product_Detail.detail_formTitle_border_style_bottom
            : "solid"
        );

        setDB_LeftSidebar_MenuItem_TitelKontaktFormular_FontColor(
          Design_Product_Detail !== undefined &&
            Design_Product_Detail.detail_formValue_schriftfarbe !== undefined
            ? Design_Product_Detail.detail_formValue_schriftfarbe
            : "rgba(255,255,255,1)"
        );
        setDB_LeftSidebar_MenuItem_TitelKontaktFormular_FontColorNotEncoded(
          Design_Product_Detail !== undefined &&
            Design_Product_Detail.detail_formValue_schriftfarbe_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Product_Detail.detail_formValue_schriftfarbe_not_encoded
              )
            : { r: 255, g: 255, b: 255, a: 1 }
        );
        setDB_LeftSidebar_MenuItem_TitelKontaktFormular_BackgroundColor(
          Design_Product_Detail !== undefined &&
            Design_Product_Detail.detail_formValue_hintergrundfarbe !==
              undefined
            ? Design_Product_Detail.detail_formValue_hintergrundfarbe
            : "rgba(0,0,0,0.2)"
        );
        setDB_LeftSidebar_MenuItem_TitelKontaktFormular_BackgroundColorNotEncoded(
          Design_Product_Detail !== undefined &&
            Design_Product_Detail.detail_formValue_hintergrundfarbe_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Product_Detail.detail_formValue_hintergrundfarbe_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0.2 }
        );
        setDB_LeftSidebar_MenuItem_TitelKontaktFormular_ShadowColor(
          Design_Product_Detail !== undefined &&
            Design_Product_Detail.detail_formValue_schattenfarbe !== undefined
            ? Design_Product_Detail.detail_formValue_schattenfarbe
            : "rgba(0,0,0,1)"
        );
        setDB_LeftSidebar_MenuItem_TitelKontaktFormular_ShadowColorNotEncoded(
          Design_Product_Detail !== undefined &&
            Design_Product_Detail.detail_formValue_schattenfarbe_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Product_Detail.detail_formValue_schattenfarbe_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 1 }
        );
        setDB_LeftSidebar_MenuItem_TitelKontaktFormular_ShadowHorizontal(
          Design_Product_Detail !== undefined &&
            Design_Product_Detail.detail_formValue_schattenposition_horizontal !==
              undefined
            ? Design_Product_Detail.detail_formValue_schattenposition_horizontal
            : "2"
        );
        setDB_LeftSidebar_MenuItem_TitelKontaktFormular_ShadowVertical(
          Design_Product_Detail !== undefined &&
            Design_Product_Detail.detail_formValue_schattenposition_vertikal !==
              undefined
            ? Design_Product_Detail.detail_formValue_schattenposition_vertikal
            : "2"
        );
        setDB_LeftSidebar_MenuItem_TitelKontaktFormular_ShadowBlur(
          Design_Product_Detail !== undefined &&
            Design_Product_Detail.detail_formValue_schattenrauschen !==
              undefined
            ? Design_Product_Detail.detail_formValue_schattenrauschen
            : "3"
        );
        setDB_LeftSidebar_MenuItem_TitelKontaktFormular_FontSize(
          Design_Product_Detail !== undefined &&
            Design_Product_Detail.detail_formValue_schriftgroesse !== undefined
            ? Design_Product_Detail.detail_formValue_schriftgroesse
            : "16"
        );
        setDB_LeftSidebar_MenuItem_TitelKontaktFormular_FontWeight(
          Design_Product_Detail !== undefined &&
            Design_Product_Detail.detail_formValue_schriftgewicht !== undefined
            ? Design_Product_Detail.detail_formValue_schriftgewicht
            : "100"
        );
        setDB_LeftSidebar_MenuItem_TitelKontaktFormular_letterSpacing(
          Design_Product_Detail !== undefined &&
            Design_Product_Detail.detail_formValue_buchstabenabstand !==
              undefined
            ? Design_Product_Detail.detail_formValue_buchstabenabstand
            : "0"
        );
        setDB_LeftSidebar_MenuItem_TitelKontaktFormular_TextDecoration(
          Design_Product_Detail !== undefined &&
            Design_Product_Detail.detail_formValue_text_dekoration !== undefined
            ? Design_Product_Detail.detail_formValue_text_dekoration
            : "none"
        );
        setDB_LeftSidebar_MenuItem_TitelKontaktFormular_TextDecorationStyle(
          Design_Product_Detail !== undefined &&
            Design_Product_Detail.detail_formValue_text_dekoration_stil !==
              undefined
            ? Design_Product_Detail.detail_formValue_text_dekoration_stil
            : "none"
        );
        setDB_LeftSidebar_MenuItem_TitelKontaktFormular_marginLeftRight(
          Design_Product_Detail !== undefined &&
            Design_Product_Detail.detail_formValue_aussen_abstand_links_rechts !==
              undefined
            ? Design_Product_Detail.detail_formValue_aussen_abstand_links_rechts
            : "25"
        );
        setDB_LeftSidebar_MenuItem_TitelKontaktFormular_marginTopBottom(
          Design_Product_Detail !== undefined &&
            Design_Product_Detail.detail_formValue_aussen_abstand_oben_unten !==
              undefined
            ? Design_Product_Detail.detail_formValue_aussen_abstand_oben_unten
            : "0"
        );
        setDB_LeftSidebar_MenuItem_TitelKontaktFormular_paddingLeftRight(
          Design_Product_Detail !== undefined &&
            Design_Product_Detail.detail_formValue_innerer_abstand_links_rechts !==
              undefined
            ? Design_Product_Detail.detail_formValue_innerer_abstand_links_rechts
            : "10"
        );
        setDB_LeftSidebar_MenuItem_TitelKontaktFormular_paddingTopBottom(
          Design_Product_Detail !== undefined &&
            Design_Product_Detail.detail_formValue_innerer_abstand_oben_unten !==
              undefined
            ? Design_Product_Detail.detail_formValue_innerer_abstand_oben_unten
            : "10"
        );
        setDB_LeftSidebar_MenuItem_TitelKontaktFormular_TextAlign(
          Design_Product_Detail !== undefined &&
            Design_Product_Detail.detail_formValue_textausrichtung !== undefined
            ? Design_Product_Detail.detail_formValue_textausrichtung
            : "left"
        );

        setDB_fontFace_chooseFontKontaktFormularFontFamily_FontFamilyName(
          Design_Product_Detail !== undefined &&
            Design_Product_Detail.detail_formValue_fontfamily !== undefined
            ? Design_Product_Detail.detail_formValue_fontfamily
            : "Lato"
        );

        setDB_fontFace_chooseFontKontaktFormularFontFamily_FontFamilySrc(
          Design_Product_Detail !== undefined &&
            Design_Product_Detail.detail_formValue_fontfamily !== undefined
            ? Design_Product_Detail.detail_formValue_fontfamilysrc
            : "/assets/Lato/Lato-Regular.ttf"
        );

        setDB_LeftSidebar_MenuItem_TitelKontaktFormular_BorderColorLeft(
          Design_Product_Detail !== undefined &&
            Design_Product_Detail.detail_formValue_border_color_left !==
              undefined
            ? Design_Product_Detail.detail_formValue_border_color_left
            : "rgba(0,0,0,0.3)"
        );
        setDB_LeftSidebar_MenuItem_TitelKontaktFormular_BorderColorLeftNotEncoded(
          Design_Product_Detail !== undefined &&
            Design_Product_Detail.detail_formValue_border_color_left_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Product_Detail.detail_formValue_border_color_left_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0.3 }
        );
        setDB_LeftSidebar_MenuItem_TitelKontaktFormular_BorderColorRight(
          Design_Product_Detail !== undefined &&
            Design_Product_Detail.detail_formValue_border_color_right !==
              undefined
            ? Design_Product_Detail.detail_formValue_border_color_right
            : "rgba(0,0,0,0.3)"
        );
        setDB_LeftSidebar_MenuItem_TitelKontaktFormular_BorderColorRightNotEncoded(
          Design_Product_Detail !== undefined &&
            Design_Product_Detail.detail_formValue_border_color_right_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Product_Detail.detail_formValue_border_color_right_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0.3 }
        );
        setDB_LeftSidebar_MenuItem_TitelKontaktFormular_BorderColorTop(
          Design_Product_Detail !== undefined &&
            Design_Product_Detail.detail_formValue_border_color_top !==
              undefined
            ? Design_Product_Detail.detail_formValue_border_color_top
            : "rgba(0,0,0,0.3)"
        );
        setDB_LeftSidebar_MenuItem_TitelKontaktFormular_BorderColorTopNotEncoded(
          Design_Product_Detail !== undefined &&
            Design_Product_Detail.detail_formValue_border_color_top_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Product_Detail.detail_formValue_border_color_top_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0.3 }
        );
        setDB_LeftSidebar_MenuItem_TitelKontaktFormular_BorderColorBottom(
          Design_Product_Detail !== undefined &&
            Design_Product_Detail.detail_formValue_border_color_bottom !==
              undefined
            ? Design_Product_Detail.detail_formValue_border_color_bottom
            : "rgba(0,0,0,0.3)"
        );
        setDB_LeftSidebar_MenuItem_TitelKontaktFormular_BorderColorBottomNotEncoded(
          Design_Product_Detail !== undefined &&
            Design_Product_Detail.detail_formValue_border_color_bottom_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Product_Detail.detail_formValue_border_color_bottom_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0.3 }
        );

        setDB_LeftSidebar_MenuItem_TitelKontaktFormular_BorderLeft(
          Design_Product_Detail !== undefined &&
            Design_Product_Detail.detail_formValue_border_pixel_left !==
              undefined
            ? Design_Product_Detail.detail_formValue_border_pixel_left
            : "1"
        );
        setDB_LeftSidebar_MenuItem_TitelKontaktFormular_BorderRight(
          Design_Product_Detail !== undefined &&
            Design_Product_Detail.detail_formValue_border_pixel_right !==
              undefined
            ? Design_Product_Detail.detail_formValue_border_pixel_right
            : "1"
        );
        setDB_LeftSidebar_MenuItem_TitelKontaktFormular_BorderTop(
          Design_Product_Detail !== undefined &&
            Design_Product_Detail.detail_formValue_border_pixel_top !==
              undefined
            ? Design_Product_Detail.detail_formValue_border_pixel_top
            : "1"
        );
        setDB_LeftSidebar_MenuItem_TitelKontaktFormular_BorderBottom(
          Design_Product_Detail !== undefined &&
            Design_Product_Detail.detail_formValue_border_pixel_bottom !==
              undefined
            ? Design_Product_Detail.detail_formValue_border_pixel_bottom
            : "1"
        );

        setDB_LeftSidebar_MenuItem_TitelKontaktFormular_BorderTopLeftRadius(
          Design_Product_Detail !== undefined &&
            Design_Product_Detail.detail_formValue_border_radius_top_left !==
              undefined
            ? Design_Product_Detail.detail_formValue_border_radius_top_left
            : "0"
        );
        setDB_LeftSidebar_MenuItem_TitelKontaktFormular_BorderTopRightRadius(
          Design_Product_Detail !== undefined &&
            Design_Product_Detail.detail_formValue_border_radius_top_right !==
              undefined
            ? Design_Product_Detail.detail_formValue_border_radius_top_right
            : "0"
        );
        setDB_LeftSidebar_MenuItem_TitelKontaktFormular_BorderBottomLeftRadius(
          Design_Product_Detail !== undefined &&
            Design_Product_Detail.detail_formValue_border_radius_bottom_left !==
              undefined
            ? Design_Product_Detail.detail_formValue_border_radius_bottom_left
            : "7"
        );
        setDB_LeftSidebar_MenuItem_TitelKontaktFormular_BorderBottomRightRadius(
          Design_Product_Detail !== undefined &&
            Design_Product_Detail.detail_formValue_border_radius_bottom_right !==
              undefined
            ? Design_Product_Detail.detail_formValue_border_radius_bottom_right
            : "7"
        );

        setDB_LeftSidebar_MenuItem_TitelKontaktFormular_BorderStyleLeft(
          Design_Product_Detail !== undefined &&
            Design_Product_Detail.detail_formValue_border_style_left !==
              undefined
            ? Design_Product_Detail.detail_formValue_border_style_left
            : "solid"
        );
        setDB_LeftSidebar_MenuItem_TitelKontaktFormular_BorderStyleRight(
          Design_Product_Detail !== undefined &&
            Design_Product_Detail.detail_formValue_border_style_right !==
              undefined
            ? Design_Product_Detail.detail_formValue_border_style_right
            : "solid"
        );
        setDB_LeftSidebar_MenuItem_TitelKontaktFormular_BorderStyleTop(
          Design_Product_Detail !== undefined &&
            Design_Product_Detail.detail_formValue_border_style_top !==
              undefined
            ? Design_Product_Detail.detail_formValue_border_style_top
            : "solid"
        );
        setDB_LeftSidebar_MenuItem_TitelKontaktFormular_BorderStyleBottom(
          Design_Product_Detail !== undefined &&
            Design_Product_Detail.detail_formValue_border_style_bottom !==
              undefined
            ? Design_Product_Detail.detail_formValue_border_style_bottom
            : "solid"
        );

        setDB_LeftSidebar_MenuItem_TitelFormInputDetail_FontColor(
          Design_Product_Detail !== undefined &&
            Design_Product_Detail.detail_formInput_schriftfarbe !== undefined
            ? Design_Product_Detail.detail_formInput_schriftfarbe
            : "rgba(255,255,255,1)"
        );
        setDB_LeftSidebar_MenuItem_TitelFormInputDetail_FontColorNotEncoded(
          Design_Product_Detail !== undefined &&
            Design_Product_Detail.detail_formInput_schriftfarbe_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Product_Detail.detail_formInput_schriftfarbe_not_encoded
              )
            : { r: 255, g: 255, b: 255, a: 1 }
        );
        setDB_LeftSidebar_MenuItem_TitelFormInputDetail_BackgroundColor(
          Design_Product_Detail !== undefined &&
            Design_Product_Detail.detail_formInput_hintergrundfarbe !==
              undefined
            ? Design_Product_Detail.detail_formInput_hintergrundfarbe
            : "rgba(0,0,0,0.3)"
        );
        setDB_LeftSidebar_MenuItem_TitelFormInputDetail_BackgroundColorNotEncoded(
          Design_Product_Detail !== undefined &&
            Design_Product_Detail.detail_formInput_hintergrundfarbe_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Product_Detail.detail_formInput_hintergrundfarbe_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0.3 }
        );
        setDB_LeftSidebar_MenuItem_TitelFormInputDetail_ShadowColor(
          Design_Product_Detail !== undefined &&
            Design_Product_Detail.detail_formInput_schattenfarbe !== undefined
            ? Design_Product_Detail.detail_formInput_schattenfarbe
            : "rgba(0,0,0,1)"
        );
        setDB_LeftSidebar_MenuItem_TitelFormInputDetail_ShadowColorNotEncoded(
          Design_Product_Detail !== undefined &&
            Design_Product_Detail.detail_formInput_schattenfarbe_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Product_Detail.detail_formInput_schattenfarbe_not_encoded
              )
            : { r: 0, g: 0, b: 1, a: 1 }
        );
        setDB_LeftSidebar_MenuItem_TitelFormInputDetail_ShadowHorizontal(
          Design_Product_Detail !== undefined &&
            Design_Product_Detail.detail_formInput_schattenposition_horizontal !==
              undefined
            ? Design_Product_Detail.detail_formInput_schattenposition_horizontal
            : "2"
        );
        setDB_LeftSidebar_MenuItem_TitelFormInputDetail_ShadowVertical(
          Design_Product_Detail !== undefined &&
            Design_Product_Detail.detail_formInput_schattenposition_vertikal !==
              undefined
            ? Design_Product_Detail.detail_formInput_schattenposition_vertikal
            : "2"
        );
        setDB_LeftSidebar_MenuItem_TitelFormInputDetail_ShadowBlur(
          Design_Product_Detail !== undefined &&
            Design_Product_Detail.detail_formInput_schattenrauschen !==
              undefined
            ? Design_Product_Detail.detail_formInput_schattenrauschen
            : "3"
        );
        setDB_LeftSidebar_MenuItem_TitelFormInputDetail_FontSize(
          Design_Product_Detail !== undefined &&
            Design_Product_Detail.detail_formInput_schriftgroesse !== undefined
            ? Design_Product_Detail.detail_formInput_schriftgroesse
            : "15"
        );
        setDB_LeftSidebar_MenuItem_TitelFormInputDetail_FontWeight(
          Design_Product_Detail !== undefined &&
            Design_Product_Detail.detail_formInput_schriftgewicht !== undefined
            ? Design_Product_Detail.detail_formInput_schriftgewicht
            : "100"
        );
        setDB_LeftSidebar_MenuItem_TitelFormInputDetail_letterSpacing(
          Design_Product_Detail !== undefined &&
            Design_Product_Detail.detail_formInput_buchstabenabstand !==
              undefined
            ? Design_Product_Detail.detail_formInput_buchstabenabstand
            : "0"
        );
        setDB_LeftSidebar_MenuItem_TitelFormInputDetail_TextDecoration(
          Design_Product_Detail !== undefined &&
            Design_Product_Detail.detail_formInput_text_dekoration !== undefined
            ? Design_Product_Detail.detail_formInput_text_dekoration
            : "none"
        );
        setDB_LeftSidebar_MenuItem_TitelFormInputDetail_TextDecorationStyle(
          Design_Product_Detail !== undefined &&
            Design_Product_Detail.detail_formInput_text_dekoration_stil !==
              undefined
            ? Design_Product_Detail.detail_formInput_text_dekoration_stil
            : "none"
        );

        setDB_LeftSidebar_MenuItem_TitelFormInputDetail_marginLeftRight(
          Design_Product_Detail !== undefined &&
            Design_Product_Detail.detail_formInput_aussen_abstand_links_rechts !==
              undefined
            ? Design_Product_Detail.detail_formInput_aussen_abstand_links_rechts
            : "0"
        );
        setDB_LeftSidebar_MenuItem_TitelFormInputDetail_marginTopBottom(
          Design_Product_Detail !== undefined &&
            Design_Product_Detail.detail_formInput_aussen_abstand_oben_unten !==
              undefined
            ? Design_Product_Detail.detail_formInput_aussen_abstand_oben_unten
            : "3"
        );
        setDB_LeftSidebar_MenuItem_TitelFormInputDetail_paddingLeftRight(
          Design_Product_Detail !== undefined &&
            Design_Product_Detail.detail_formInput_innerer_abstand_links_rechts !==
              undefined
            ? Design_Product_Detail.detail_formInput_innerer_abstand_links_rechts
            : "0"
        );
        setDB_LeftSidebar_MenuItem_TitelFormInputDetail_paddingTopBottom(
          Design_Product_Detail !== undefined &&
            Design_Product_Detail.detail_formInput_innerer_abstand_oben_unten !==
              undefined
            ? Design_Product_Detail.detail_formInput_innerer_abstand_oben_unten
            : "0"
        );
        setDB_LeftSidebar_MenuItem_TitelFormInputDetail_TextAlign(
          Design_Product_Detail !== undefined &&
            Design_Product_Detail.detail_formInput_textausrichtung !== undefined
            ? Design_Product_Detail.detail_formInput_textausrichtung
            : "center"
        );

        setDB_fontFace_chooseFontFormLabelsFontFamily_FontFamilyName(
          Design_Product_Detail !== undefined &&
            Design_Product_Detail.detail_formInput_fontfamily !== undefined
            ? Design_Product_Detail.detail_formInput_fontfamily
            : "Lato"
        );

        setDB_fontFace_chooseFontFormLabelsFontFamily_FontFamilySrc(
          Design_Product_Detail !== undefined &&
            Design_Product_Detail.detail_formInput_fontfamilysrc !== undefined
            ? Design_Product_Detail.detail_formInput_fontfamilysrc
            : "/assets/Lato/Lato-Regular.ttf"
        );

        setDB_LeftSidebar_MenuItem_TitelFormInputDetail_BorderColorLeft(
          Design_Product_Detail !== undefined &&
            Design_Product_Detail.detail_formInput_border_color_left !==
              undefined
            ? Design_Product_Detail.detail_formInput_border_color_left
            : "rgba(0,0,0,0.3)"
        );
        setDB_LeftSidebar_MenuItem_TitelFormInputDetail_BorderColorLeftNotEncoded(
          Design_Product_Detail !== undefined &&
            Design_Product_Detail.detail_formInput_border_color_left_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Product_Detail.detail_formInput_border_color_left_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0.3 }
        );
        setDB_LeftSidebar_MenuItem_TitelFormInputDetail_BorderColorRight(
          Design_Product_Detail !== undefined &&
            Design_Product_Detail.detail_formInput_border_color_right !==
              undefined
            ? Design_Product_Detail.detail_formInput_border_color_right
            : "rgba(0,0,0,0.3)"
        );
        setDB_LeftSidebar_MenuItem_TitelFormInputDetail_BorderColorRightNotEncoded(
          Design_Product_Detail !== undefined &&
            Design_Product_Detail.detail_formInput_border_color_right_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Product_Detail.detail_formInput_border_color_right_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0.3 }
        );
        setDB_LeftSidebar_MenuItem_TitelFormInputDetail_BorderColorTop(
          Design_Product_Detail !== undefined &&
            Design_Product_Detail.detail_formInput_border_color_top !==
              undefined
            ? Design_Product_Detail.detail_formInput_border_color_top
            : "rgba(0,0,0,0.3)"
        );
        setDB_LeftSidebar_MenuItem_TitelFormInputDetail_BorderColorTopNotEncoded(
          Design_Product_Detail !== undefined &&
            Design_Product_Detail.detail_formInput_border_color_top_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Product_Detail.detail_formInput_border_color_top_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0.3 }
        );
        setDB_LeftSidebar_MenuItem_TitelFormInputDetail_BorderColorBottom(
          Design_Product_Detail !== undefined &&
            Design_Product_Detail.detail_formInput_border_color_bottom !==
              undefined
            ? Design_Product_Detail.detail_formInput_border_color_bottom
            : "rgba(0,0,0,0.3)"
        );
        setDB_LeftSidebar_MenuItem_TitelFormInputDetail_BorderColorBottomNotEncoded(
          Design_Product_Detail !== undefined &&
            Design_Product_Detail.detail_formInput_border_color_bottom_not_encoded !==
              undefined
            ? JSON.parse(
                Design_Product_Detail.detail_formInput_border_color_bottom_not_encoded
              )
            : { r: 0, g: 0, b: 0, a: 0.3 }
        );

        setDB_LeftSidebar_MenuItem_TitelFormInputDetail_BorderLeft(
          Design_Product_Detail !== undefined &&
            Design_Product_Detail.detail_formInput_border_pixel_left !==
              undefined
            ? Design_Product_Detail.detail_formInput_border_pixel_left
            : "1"
        );
        setDB_LeftSidebar_MenuItem_TitelFormInputDetail_BorderRight(
          Design_Product_Detail !== undefined &&
            Design_Product_Detail.detail_formInput_border_pixel_right !==
              undefined
            ? Design_Product_Detail.detail_formInput_border_pixel_right
            : "1"
        );
        setDB_LeftSidebar_MenuItem_TitelFormInputDetail_BorderTop(
          Design_Product_Detail !== undefined &&
            Design_Product_Detail.detail_formInput_border_pixel_top !==
              undefined
            ? Design_Product_Detail.detail_formInput_border_pixel_top
            : "1"
        );
        setDB_LeftSidebar_MenuItem_TitelFormInputDetail_BorderBottom(
          Design_Product_Detail !== undefined &&
            Design_Product_Detail.detail_formInput_border_pixel_bottom !==
              undefined
            ? Design_Product_Detail.detail_formInput_border_pixel_bottom
            : "1"
        );

        setDB_LeftSidebar_MenuItem_TitelFormInputDetail_BorderTopLeftRadius(
          Design_Product_Detail !== undefined &&
            Design_Product_Detail.detail_formInput_border_radius_top_left !==
              undefined
            ? Design_Product_Detail.detail_formInput_border_radius_top_left
            : "7"
        );
        setDB_LeftSidebar_MenuItem_TitelFormInputDetail_BorderTopRightRadius(
          Design_Product_Detail !== undefined &&
            Design_Product_Detail.detail_formInput_border_radius_top_right !==
              undefined
            ? Design_Product_Detail.detail_formInput_border_radius_top_right
            : "7"
        );
        setDB_LeftSidebar_MenuItem_TitelFormInputDetail_BorderBottomLeftRadius(
          Design_Product_Detail !== undefined &&
            Design_Product_Detail.detail_formInput_border_radius_bottom_left !==
              undefined
            ? Design_Product_Detail.detail_formInput_border_radius_bottom_left
            : "7"
        );
        setDB_LeftSidebar_MenuItem_TitelFormInputDetail_BorderBottomRightRadius(
          Design_Product_Detail !== undefined &&
            Design_Product_Detail.detail_formInput_border_radius_bottom_right !==
              undefined
            ? Design_Product_Detail.detail_formInput_border_radius_bottom_right
            : "7"
        );

        setDB_LeftSidebar_MenuItem_TitelFormInputDetail_BorderStyleLeft(
          Design_Product_Detail !== undefined &&
            Design_Product_Detail.detail_formInput_border_style_left !==
              undefined
            ? Design_Product_Detail.detail_formInput_border_style_left
            : "solid"
        );
        setDB_LeftSidebar_MenuItem_TitelFormInputDetail_BorderStyleRight(
          Design_Product_Detail !== undefined &&
            Design_Product_Detail.detail_formInput_border_style_right !==
              undefined
            ? Design_Product_Detail.detail_formInput_border_style_right
            : "solid"
        );
        setDB_LeftSidebar_MenuItem_TitelFormInputDetail_BorderStyleTop(
          Design_Product_Detail !== undefined &&
            Design_Product_Detail.detail_formInput_border_style_top !==
              undefined
            ? Design_Product_Detail.detail_formInput_border_style_top
            : "solid"
        );
        setDB_LeftSidebar_MenuItem_TitelFormInputDetail_BorderStyleBottom(
          Design_Product_Detail !== undefined &&
            Design_Product_Detail.detail_formInput_border_style_bottom !==
              undefined
            ? Design_Product_Detail.detail_formInput_border_style_bottom
            : "solid"
        );
      })
      .then(() => {
        axios({
          method: "post",
          url: `${process.env.REACT_APP_PUBLIC_SERVER_URL}/shop/get-active-design-image`,
          data: {
            shopName: shopName,
            newDesignIdentifier: newDesignIdentifier,
            designIdentifier: designIdentifier,
          },
        })
          .then((uploadedResult) => {
            if (uploadedResult.status === 201) {
              setDB_BackgroundImageForAllBody("/images/sms_standard_bg.jpg");
            } else {
              if (uploadedResult.data.bg_image) {
                setDB_BackgroundImageForAllBody(
                  `${process.env.REACT_APP_PUBLIC_SERVER_URL}` +
                    "/" +
                    uploadedResult.data.bg_image +
                    "?" +
                    new Date().getMilliseconds()
                );
              } else {
                setDB_BackgroundImageForAllBody("/images/sms_standard_bg.jpg");
              }
            }
          })
          .catch((err) => {
            toast.error(
              "Keine Verbindung zum Server - Bilder konnten nicht vom Server heruntergeladen werden",
              {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
                transition: Zoom,
              }
            );
          });
        setIsLoading(false);
      })

      .catch((error) => {
        toast.error(`${t("ErrorUnbekannterFehler")}`, {
          toastId: "custom-id-yes",
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          transition: Zoom,
        });
      });
  }, [designIdentifier, countImagePlusOne]);

  const [productDetailItem, setProductDetailItem] = useState();
  const [aboutUsDetailItem, setAboutUsDetailItem] = useState({});
  const [faqDetailItem, setFaqUsDetailItem] = useState({});
  const [images, setImages] = useState([]);
  const [aboutUsImages, setAboutUsImages] = useState([]);

  /* const [paginateOffset, setPaginateOffset] = useState(3); */
  const allProductsHelperStart = allProducts;
  const [allCategories, setAllCategories] = useState([]);
  const [show, setShow] = useState(false);
  const [columnNumber, setColumnNumber] = useState(4);

  const [isCheck, setIsCheck] = useState([]);

  const [searchValue, setSearchValue] = useState("");

  const [productDetailShow, setProductDetailShow] = useState(false);
  const [aboutUsShow, setAboutUsShow] = useState(false);
  const [faqShow, setFaqShow] = useState(false);

  const [signInModal, setSignInModal] = useState(false);
  const [doLoginOn, setDoLoginOn] = useState(true);
  const [doRegisterOn, setDoRegisterOn] = useState(false);
  const [doForgotPassword, setDoForgotPassword] = useState(false);

  const [showConfig_Main, setShowConfig_Main] = useState(false);
  const [stateUserId, setStateUserId] = useState(getIdOfUser());
  const [userOwnsThisSite, setUserOwnsThisSite] = useState(false);

  const [DB_OrderOfBodyElements, setDB_OrderOfBodyElements] = useState("012");
  const [backgroundColorActive, setBackgroundColorActive] = useState(false);

  const [DB_BackgroundImageForAllBody, setDB_BackgroundImageForAllBody] =
    useState("/images/pexels-steve-johnson-12564252.jpg");

  const [DB_fontFace_FontFamilyName, setDB_fontFace_FontFamilyName] =
    useState("Lato");
  const [DB_fontFace_FontFamilySrc, setDB_fontFace_FontFamilySrc] = useState(
    "/assets/Lato/Lato-Regular.ttf"
  );
  const [DB_MainBody_FontColor, setDB_MainBody_FontColor] = useState(
    "rgba(255,255,255,1)"
  );

  const [pageLoadedNew, setPageLoadedNew] = useState(0);

  useEffect(() => {
    setPageLoadedNew((prev) => prev + 1);
    setAllProductsState(
      allProductsStatePagination.slice(paginationStart, paginationEnd)
    );
  }, []);
  useEffect(() => {
    categoryObject.kategorie ||
    (categoryObject.kategorie && categoryObject.unterkategorie) ||
    (categoryObject.kategorie &&
      categoryObject.unterkategorie &&
      categoryObject.unterunterkategorie)
      ? setSearchParams({
          cat: categoryObject.kategorie ? categoryObject.kategorie : "",
          sub: categoryObject.unterkategorie
            ? categoryObject.unterkategorie
            : "",
          subsub: categoryObject.unterunterkategorie
            ? categoryObject.unterunterkategorie
            : "",
          b: paginationStart,
          e: paginationEnd,
        })
      : !searchParams.get("s") &&
        setSearchParams({
          cat: "",
          sub: "",
          subsub: "",
          b: paginationStart,
          e: paginationEnd,
        });

    window.scrollTo(0, 0);
  }, [paginationStart, paginationEnd, categoryObject]);

  const productBefore = () => {
    if (paginationStart > 0) {
      setPaginationStart((prev) => prev - 72);
      setPaginationEnd((prev) => prev - 72);
    }
  };

  const productNext = () => {
    if (paginationEnd < pageAnzahlCounter) {
      setPaginationStart((prev) => prev + 72);
      setPaginationEnd((prev) => prev + 72);
      console.log("paginationEnd < ", paginationEnd);
      console.log("paginationEnd < pageAnzahlCounter", pageAnzahlCounter);
    }
  };

  const anordnungStandardWiederherstellen = () => {
    setDB_OrderOfBodyElements("012");
    setColumnNumber("4");
    setTopMainCategoriesVisible(true);
    setBottomMainCategoriesVisible(false);
    setLeftMainCategoriesVisible(false);
    setRightMainCategoriesVisible(false);

    setTopBarIsVisibleTop(true);
    setTopBarIsVisibleBottom(false);
    setTopBarIsVisibleLeft(false);
    setTopBarIsVisibleRight(false);

    setTopBarFilterProductOne(false);
    setTopBarFilterProductTwo(false);
    setTopBarFilterProductThree(true);
    setTopBarFilterProductFour(false);

    setProductFilterIsVisibleTop(true);
    setProductFilterIsVisibleBottom(false);
    setProductFilterIsVisibleLeft(false);
    setProductFilterIsVisibleRight(false);

    setLeftCategoriesVisible(true);
    setSpecialRightCategoriesVisible(false);
    setSpecialLeftFiltersVisible(false);
    setRightFiltersVisible(true);
    setSpecialLeftSocialsVisible(false);
    setRightSocialsVisible(true);

    setMarqueeIsVisibleTop(false);
  };

  const [
    DB_fontFace_chooseFontWaehrungEuro_FontFamilyName,
    setDB_fontFace_chooseFontWaehrungEuro_FontFamilyName,
  ] = useState("Lato");
  const [
    DB_fontFace_chooseFontWaehrungEuro_FontFamilySrc,
    setDB_fontFace_chooseFontWaehrungEuro_FontFamilySrc,
  ] = useState("/assets/Lato/Lato-Regular.ttf");

  const [
    DB_fontFace_chooseFontWaehrung_FontFamilyName,
    setDB_fontFace_chooseFontWaehrung_FontFamilyName,
  ] = useState("Lato");
  const [
    DB_fontFace_chooseFontWaehrung_FontFamilySrc,
    setDB_fontFace_chooseFontWaehrung_FontFamilySrc,
  ] = useState("/assets/Lato/Lato-Regular.ttf");

  const [
    DB_fontFace_chooseFontBreadcrumbs_FontFamilyName,
    setDB_fontFace_chooseFontBreadcrumbs_FontFamilyName,
  ] = useState("Lato");
  const [
    DB_fontFace_chooseFontBreadcrumbs_FontFamilySrc,
    setDB_fontFace_chooseFontBreadcrumbs_FontFamilySrc,
  ] = useState("/assets/Lato/Lato-Regular.ttf");

  const [
    DB_fontFace_chooseFontFamilySuperTitel_FontFamilyName,
    setDB_fontFace_chooseFontFamilySuperTitel_FontFamilyName,
  ] = useState("Lato");
  const [
    DB_fontFace_chooseFontFamilySuperTitel_FontFamilySrc,
    setDB_fontFace_chooseFontFamilySuperTitel_FontFamilySrc,
  ] = useState("/assets/Lato/Lato-Regular.ttf");

  const [
    DB_fontFace_chooseFontBeschreibungFontFamily_FontFamilyName,
    setDB_fontFace_chooseFontBeschreibungFontFamily_FontFamilyName,
  ] = useState("Lato");
  const [
    DB_fontFace_chooseFontBeschreibungFontFamily_FontFamilySrc,
    setDB_fontFace_chooseFontBeschreibungFontFamily_FontFamilySrc,
  ] = useState("/assets/Lato/Lato-Regular.ttf");

  const [
    DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_FontFamilyName,
    setDB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_FontFamilyName,
  ] = useState("Lato");
  const [
    DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_FontFamilySrc,
    setDB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_FontFamilySrc,
  ] = useState("/assets/Lato/Lato-Regular.ttf");

  const [
    DB_fontFace_chooseFontFormLabelsFontFamily_FontFamilyName,
    setDB_fontFace_chooseFontFormLabelsFontFamily_FontFamilyName,
  ] = useState("Lato");
  const [
    DB_fontFace_chooseFontFormLabelsFontFamily_FontFamilySrc,
    setDB_fontFace_chooseFontFormLabelsFontFamily_FontFamilySrc,
  ] = useState("/assets/Lato/Lato-Regular.ttf");

  const [
    DB_fontFace_chooseFontHaupttitelFontFamily_FontFamilyName,
    setDB_fontFace_chooseFontHaupttitelFontFamily_FontFamilyName,
  ] = useState("Lato");
  const [
    DB_fontFace_chooseFontHaupttitelFontFamily_FontFamilySrc,
    setDB_fontFace_chooseFontHaupttitelFontFamily_FontFamilySrc,
  ] = useState("/assets/Lato/Lato-Regular.ttf");

  const [
    DB_fontFace_chooseFontKontaktFormularFontFamily_FontFamilyName,
    setDB_fontFace_chooseFontKontaktFormularFontFamily_FontFamilyName,
  ] = useState("Lato");
  const [
    DB_fontFace_chooseFontKontaktFormularFontFamily_FontFamilySrc,
    setDB_fontFace_chooseFontKontaktFormularFontFamily_FontFamilySrc,
  ] = useState("/assets/Lato/Lato-Regular.ttf");

  const [
    DB_fontFace_chooseFontFamilyProduct_FontFamilyName,
    setDB_fontFace_chooseFontFamilyProduct_FontFamilyName,
  ] = useState("Lato");
  const [
    DB_fontFace_chooseFontFamilyProduct_FontFamilySrc,
    setDB_fontFace_chooseFontFamilyProduct_FontFamilySrc,
  ] = useState("/assets/Lato/Lato-Regular.ttf");

  const [
    DB_fontFace_chooseFontFamilyProductUntertitel_FontFamilyName,
    setDB_fontFace_chooseFontFamilyProductUntertitel_FontFamilyName,
  ] = useState("Lato");
  const [
    DB_fontFace_chooseFontFamilyProductUntertitel_FontFamilySrc,
    setDB_fontFace_chooseFontFamilyProductUntertitel_FontFamilySrc,
  ] = useState("/assets/Lato/Lato-Regular.ttf");

  const [
    DB_BackgroundColorOverlayForAllBody,
    setDB_BackgroundColorOverlayForAllBody,
  ] = useState("rgba(0, 0, 0, 0)");

  const [
    DB_BackgroundColorOverlayForAllBodyNotEncoded,
    setDB_BackgroundColorOverlayForAllBodyNotEncoded,
  ] = useState("rgba(255, 255, 255, 1)");

  const [DB_Waehrung_Global, setDB_Waehrung_Global] = useState("€");

  const waehrungDefinierenHandler = (e) => {
    setDB_Waehrung_Global(e.target.value);
  };

  useEffect(() => {
    setIsAuthState(isAuth() ? true : false);
  }, []);

  const [
    SideBarLeft_Container_Farbe_Groesse_Stil_Trigger,
    setSideBarLeft_Container_Farbe_Groesse_Stil_Trigger,
  ] = useState(0);

  const setSideBarLeft_Container_Farbe_Groesse_Stil_Handler = () => {
    setSideBarLeft_Container_Farbe_Groesse_Stil_Trigger((prev) => prev + 1);
  };

  const [
    SideBarLeft_Container_Farbe_Groesse_Stil_Hover_Trigger,
    setSideBarLeft_Container_Farbe_Groesse_Stil_Hover_Trigger,
  ] = useState(0);

  const setSideBarLeft_Container_Farbe_Groesse_Stil_Hover_Handler = () => {
    setSideBarLeft_Container_Farbe_Groesse_Stil_Hover_Trigger(
      (prev) => prev + 1
    );
  };

  const [
    SideBarLeft_ContainerTop_Farbe_Groesse_Stil_OnMouseOverAndOut,
    setSideBarLeft_ContainerTop_Farbe_Groesse_Stil_OnMouseOverAndOut,
  ] = useState(false);

  const setSideBarLeft_ContainerTop_Farbe_Groesse_Stil_OnMouseOverAndOut_Handler =
    (truefalse) => {
      setSideBarLeft_ContainerTop_Farbe_Groesse_Stil_OnMouseOverAndOut(
        truefalse
      );
    };

  const [
    SideBarLeft_Container_Farbe_Groesse_Stil_OnMouseOverAndOut,
    setSideBarLeft_Container_Farbe_Groesse_Stil_OnMouseOverAndOut,
  ] = useState(false);

  const setSideBarLeft_Container_Farbe_Groesse_Stil_OnMouseOverAndOut_Handler =
    (truefalse) => {
      setSideBarLeft_Container_Farbe_Groesse_Stil_OnMouseOverAndOut(truefalse);
    };

  const [
    SideBarLeft_Container_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut,
    setSideBarLeft_Container_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut,
  ] = useState(false);

  const setSideBarLeft_Container_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut_Handler =
    (truefalse) => {
      setSideBarLeft_Container_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut(
        truefalse
      );
    };

  const [
    SideBarLeft_Container_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut,
    setSideBarLeft_Container_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut,
  ] = useState(false);

  const setSideBarLeft_Container_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut_Handler =
    (truefalse) => {
      setSideBarLeft_Container_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut(
        truefalse
      );
    };

  const [
    SideBarLeft_Container_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut,
    setSideBarLeft_Container_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut,
  ] = useState(false);

  const setSideBarLeft_Container_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut_Handler =
    (truefalse) => {
      setSideBarLeft_Container_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut(
        truefalse
      );
    };

  const [
    SideBarLeft_ContainerFilter_Farbe_Groesse_Stil_OnMouseOverAndOut,
    setSideBarLeft_ContainerFilter_Farbe_Groesse_Stil_OnMouseOverAndOut,
  ] = useState(false);

  const setSideBarLeft_ContainerFilter_Farbe_Groesse_Stil_OnMouseOverAndOut_Handler =
    (truefalse) => {
      setSideBarLeft_ContainerFilter_Farbe_Groesse_Stil_OnMouseOverAndOut(
        truefalse
      );
    };

  const [
    SideBarLeft_ContainerFilter_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut,
    setSideBarLeft_ContainerFilter_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut,
  ] = useState(false);

  const setSideBarLeft_ContainerFilter_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut_Handler =
    (truefalse) => {
      setSideBarLeft_ContainerFilter_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut(
        truefalse
      );
    };

  const [
    SideBarLeft_ContainerFilter_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut,
    setSideBarLeft_ContainerFilter_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut,
  ] = useState(false);

  const setSideBarLeft_ContainerFilter_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut_Handler =
    (truefalse) => {
      setSideBarLeft_ContainerFilter_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut(
        truefalse
      );
    };

  const [
    SideBarLeft_ContainerFilter_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut,
    setSideBarLeft_ContainerFilter_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut,
  ] = useState(false);

  const setSideBarLeft_ContainerFilter_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut_Handler =
    (truefalse) => {
      setSideBarLeft_ContainerFilter_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut(
        truefalse
      );
    };

  const [
    SideBarLeft_ContainerSocial_Farbe_Groesse_Stil_OnMouseOverAndOut,
    setSideBarLeft_ContainerSocial_Farbe_Groesse_Stil_OnMouseOverAndOut,
  ] = useState(false);

  const setSideBarLeft_ContainerSocial_Farbe_Groesse_Stil_OnMouseOverAndOut_Handler =
    (truefalse) => {
      setSideBarLeft_ContainerSocial_Farbe_Groesse_Stil_OnMouseOverAndOut(
        truefalse
      );
    };

  const [
    SideBarLeft_ContainerSocial_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut,
    setSideBarLeft_ContainerSocial_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut,
  ] = useState(false);

  const setSideBarLeft_ContainerSocial_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut_Handler =
    (truefalse) => {
      setSideBarLeft_ContainerSocial_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut(
        truefalse
      );
    };

  const [
    SideBarLeft_ContainerSocial_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut,
    setSideBarLeft_ContainerSocial_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut,
  ] = useState(false);

  const setSideBarLeft_ContainerSocial_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut_Handler =
    (truefalse) => {
      setSideBarLeft_ContainerSocial_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut(
        truefalse
      );
    };

  const [
    SideBarLeft_ContainerSocial_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut,
    setSideBarLeft_ContainerSocial_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut,
  ] = useState(false);

  const setSideBarLeft_ContainerSocial_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut_Handler =
    (truefalse) => {
      setSideBarLeft_ContainerSocial_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut(
        truefalse
      );
    };

  const [
    SideBarLeft_ContainerDetail_Farbe_Groesse_Stil_OnMouseOverAndOut,
    setSideBarLeft_ContainerDetail_Farbe_Groesse_Stil_OnMouseOverAndOut,
  ] = useState(false);

  const setSideBarLeft_ContainerDetail_Farbe_Groesse_Stil_OnMouseOverAndOut_Handler =
    (truefalse) => {
      setSideBarLeft_ContainerDetail_Farbe_Groesse_Stil_OnMouseOverAndOut(
        truefalse
      );
    };

  const [
    SideBarLeft_ContainerDetail_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut,
    setSideBarLeft_ContainerDetail_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut,
  ] = useState(false);

  const setSideBarLeft_ContainerDetail_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut_Handler =
    (truefalse) => {
      setSideBarLeft_ContainerDetail_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut(
        truefalse
      );
    };

  const [
    SideBarLeft_ContainerTopAA_Farbe_Groesse_Stil_Trigger,
    setSideBarLeft_ContainerTopAA_Farbe_Groesse_Stil_Trigger,
  ] = useState(0);

  const [
    SideBarLeft_ContainerTopXYZ_Farbe_Groesse_Stil_Trigger,
    setSideBarLeft_ContainerTopXYZ_Farbe_Groesse_Stil_Trigger,
  ] = useState(0);

  const setSideBarStatic_ContainerTop_Farbe_Groesse_Stil_Handler = () => {
    setSideBarLeft_ContainerTopXYZ_Farbe_Groesse_Stil_Trigger(
      (prev) => prev + 1
    );
  };

  const setSideBarLeft_ContainerTop_Farbe_Groesse_Stil_Handler = () => {
    setSideBarLeft_ContainerTopAA_Farbe_Groesse_Stil_Trigger(
      (prev) => prev + 1
    );
  };

  const [
    SideBarLeft_ContainerTopAA_Farbe_Groesse_Stil_Hover_Trigger,
    setSideBarLeft_ContainerTopAA_Farbe_Groesse_Stil_Hover_Trigger,
  ] = useState(0);

  const setSideBarLeft_ContainerTop_Farbe_Groesse_Stil_Hover_Handler = () => {
    setSideBarLeft_ContainerTopAA_Farbe_Groesse_Stil_Hover_Trigger(
      (prev) => prev + 1
    );
  };

  const [
    SideBarLeft_ContainerTopXYZ_Farbe_Groesse_Stil_Hover_Trigger,
    setSideBarLeft_ContainerTopXYZ_Farbe_Groesse_Stil_Hover_Trigger,
  ] = useState(0);

  const setSideBarStatic_ContainerTop_Farbe_Groesse_Stil_Hover_Handler = () => {
    setSideBarLeft_ContainerTopXYZ_Farbe_Groesse_Stil_Hover_Trigger(
      (prev) => prev + 1
    );
  };

  const [
    SideBarLeft_ContainerTopXYZ_Farbe_Groesse_Stil_OnMouseOverAndOut,
    setSideBarLeft_ContainerTopXYZ_Farbe_Groesse_Stil_OnMouseOverAndOut,
  ] = useState(false);

  const setSideBarLeft_ContainerTopXYZ_Farbe_Groesse_Stil_OnMouseOverAndOut_Handler =
    (truefalse) => {
      setSideBarLeft_ContainerTopXYZ_Farbe_Groesse_Stil_OnMouseOverAndOut(
        truefalse
      );
    };

  const [
    SideBarLeft_ContainerTopXYZ_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut,
    setSideBarLeft_ContainerTopXYZ_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut,
  ] = useState(false);

  const setSideBarLeft_ContainerTopXYZ_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut_Handler =
    (truefalse) => {
      setSideBarLeft_ContainerTopXYZ_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut(
        truefalse
      );
    };

  const [
    SideBarLeft_ContainerTopXYZ_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut,
    setSideBarLeft_ContainerTopXYZ_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut,
  ] = useState(false);

  const setSideBarLeft_ContainerTopXYZ_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut_Handler =
    (truefalse) => {
      setSideBarLeft_ContainerTopXYZ_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut(
        truefalse
      );
    };

  const [
    SideBarLeft_ContainerTopXYZ_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut,
    setSideBarLeft_ContainerTopXYZ_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut,
  ] = useState(false);

  const setSideBarLeft_ContainerTopXYZ_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut_Handler =
    (truefalse) => {
      setSideBarLeft_ContainerTopXYZ_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut(
        truefalse
      );
    };

  const [
    SideBarLeft_ContainerTop_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut,
    setSideBarLeft_ContainerTop_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut,
  ] = useState(false);

  const setSideBarLeft_ContainerTop_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut_Handler =
    (truefalse) => {
      setSideBarLeft_ContainerTop_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut(
        truefalse
      );
    };

  const [
    SideBarLeft_ContainerTop_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut,
    setSideBarLeft_ContainerTop_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut,
  ] = useState(false);

  const setSideBarLeft_ContainerTop_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut_Handler =
    (truefalse) => {
      setSideBarLeft_ContainerTop_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut(
        truefalse
      );
    };

  const [
    SideBarLeft_ContainerTop_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut,
    setSideBarLeft_ContainerTop_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut,
  ] = useState(false);

  const setSideBarLeft_ContainerTop_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut_Handler =
    (truefalse) => {
      setSideBarLeft_ContainerTop_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut(
        truefalse
      );
    };

  const [
    SideBarLeft_SortierungTop_Farbe_Groesse_Stil_Trigger,
    setSideBarLeft_SortierungTop_Farbe_Groesse_Stil_Trigger,
  ] = useState(0);

  const setSideBarLeft_SortierungTop_Farbe_Groesse_Stil_Handler = () => {
    setSideBarLeft_SortierungTop_Farbe_Groesse_Stil_Trigger((prev) => prev + 1);
  };

  const [
    SideBarLeft_SortierungTop_Farbe_Groesse_Stil_Hover_Trigger,
    setSideBarLeft_SortierungTop_Farbe_Groesse_Stil_Hover_Trigger,
  ] = useState(0);

  const setSideBarLeft_SortierungTop_Farbe_Groesse_Stil_Hover_Handler = () => {
    setSideBarLeft_SortierungTop_Farbe_Groesse_Stil_Hover_Trigger(
      (prev) => prev + 1
    );
  };

  const [
    SideBarLeft_SortierungTop_Farbe_Groesse_Stil_OnMouseOverAndOut,
    setSideBarLeft_SortierungTop_Farbe_Groesse_Stil_OnMouseOverAndOut,
  ] = useState(false);

  const setSideBarLeft_SortierungTop_Farbe_Groesse_Stil_OnMouseOverAndOut_Handler =
    (truefalse) => {
      setSideBarLeft_SortierungTop_Farbe_Groesse_Stil_OnMouseOverAndOut(
        truefalse
      );
    };

  const [
    SideBarLeft_SortierungTop_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut,
    setSideBarLeft_SortierungTop_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut,
  ] = useState(false);

  const setSideBarLeft_SortierungTop_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut_Handler =
    (truefalse) => {
      setSideBarLeft_SortierungTop_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut(
        truefalse
      );
    };

  const [
    SideBarLeft_SortierungTop_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut,
    setSideBarLeft_SortierungTop_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut,
  ] = useState(false);

  const setSideBarLeft_SortierungTop_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut_Handler =
    (truefalse) => {
      setSideBarLeft_SortierungTop_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut(
        truefalse
      );
    };

  const [
    SideBarLeft_SortierungTop_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut,
    setSideBarLeft_SortierungTop_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut,
  ] = useState(false);

  const setSideBarLeft_SortierungTop_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut_Handler =
    (truefalse) => {
      setSideBarLeft_SortierungTop_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut(
        truefalse
      );
    };

  const [
    SideBarLeft_ProduktContainer_Farbe_Groesse_Stil_Trigger,
    setSideBarLeft_ProduktContainer_Farbe_Groesse_Stil_Trigger,
  ] = useState(0);

  const setSideBarLeft_ProduktContainer_Farbe_Groesse_Stil_Handler = () => {
    setSideBarLeft_ProduktContainer_Farbe_Groesse_Stil_Trigger(
      (prev) => prev + 1
    );
  };

  const [
    SideBarLeft_ProduktContainer_Farbe_Groesse_Stil_Hover_Trigger,
    setSideBarLeft_ProduktContainer_Farbe_Groesse_Stil_Hover_Trigger,
  ] = useState(0);

  const setSideBarLeft_ProduktContainer_Farbe_Groesse_Stil_Hover_Handler =
    () => {
      setSideBarLeft_ProduktContainer_Farbe_Groesse_Stil_Hover_Trigger(
        (prev) => prev + 1
      );
    };

  const [
    SideBarLeft_ProduktContainer_Farbe_Groesse_Stil_OnMouseOverAndOut,
    setSideBarLeft_ProduktContainer_Farbe_Groesse_Stil_OnMouseOverAndOut,
  ] = useState(false);

  const setSideBarLeft_ProduktContainer_Farbe_Groesse_Stil_OnMouseOverAndOut_Handler =
    (truefalse) => {
      setSideBarLeft_ProduktContainer_Farbe_Groesse_Stil_OnMouseOverAndOut(
        truefalse
      );
    };

  const [
    SideBarLeft_ProduktContainer_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut,
    setSideBarLeft_ProduktContainer_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut,
  ] = useState(false);

  const setSideBarLeft_ProduktContainer_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut_Handler =
    (truefalse) => {
      setSideBarLeft_ProduktContainer_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut(
        truefalse
      );
    };

  const [
    SideBarLeft_ProduktContainer_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut,
    setSideBarLeft_ProduktContainer_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut,
  ] = useState(false);

  const setSideBarLeft_ProduktContainer_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut_Handler =
    (truefalse) => {
      setSideBarLeft_ProduktContainer_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut(
        truefalse
      );
    };

  const [
    SideBarLeft_ProduktContainer_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut,
    setSideBarLeft_ProduktContainer_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut,
  ] = useState(false);

  const setSideBarLeft_ProduktContainer_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut_Handler =
    (truefalse) => {
      setSideBarLeft_ProduktContainer_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut(
        truefalse
      );
    };

  const [
    SideBarLeft_AlleProdukte_Farbe_Groesse_Stil_OnMouseOverAndOut,
    setSideBarLeft_AlleProdukte_Farbe_Groesse_Stil_OnMouseOverAndOut,
  ] = useState(false);

  const setSideBarLeft_AlleProdukte_Farbe_Groesse_Stil_OnMouseOverAndOut_Handler =
    (truefalse) => {
      setSideBarLeft_AlleProdukte_Farbe_Groesse_Stil_OnMouseOverAndOut(
        truefalse
      );
    };

  const [
    SideBarLeft_AlleProdukte_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut,
    setSideBarLeft_AlleProdukte_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut,
  ] = useState(false);

  const setSideBarLeft_AlleProdukte_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut_Handler =
    (truefalse) => {
      setSideBarLeft_AlleProdukte_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut(
        truefalse
      );
    };

  const [
    SideBarLeft_AlleProdukte_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut,
    setSideBarLeft_AlleProdukte_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut,
  ] = useState(false);

  const setSideBarLeft_AlleProdukte_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut_Handler =
    (truefalse) => {
      setSideBarLeft_AlleProdukte_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut(
        truefalse
      );
    };

  const [
    SideBarLeft_AlleProdukte_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut,
    setSideBarLeft_AlleProdukte_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut,
  ] = useState(false);

  const setSideBarLeft_AlleProdukte_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut_Handler =
    (truefalse) => {
      setSideBarLeft_AlleProdukte_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut(
        truefalse
      );
    };

  const [
    SideBarLeft_AlleFilter_Farbe_Groesse_Stil_OnMouseOverAndOut,
    setSideBarLeft_AlleFilter_Farbe_Groesse_Stil_OnMouseOverAndOut,
  ] = useState(false);

  const setSideBarLeft_AlleFilter_Farbe_Groesse_Stil_OnMouseOverAndOut_Handler =
    (truefalse) => {
      setSideBarLeft_AlleFilter_Farbe_Groesse_Stil_OnMouseOverAndOut(truefalse);
    };

  const [
    SideBarLeft_AlleFilter_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut,
    setSideBarLeft_AlleFilter_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut,
  ] = useState(false);

  const setSideBarLeft_AlleFilter_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut_Handler =
    (truefalse) => {
      setSideBarLeft_AlleFilter_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut(
        truefalse
      );
    };

  const [
    SideBarLeft_AlleFilter_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut,
    setSideBarLeft_AlleFilter_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut,
  ] = useState(false);

  const setSideBarLeft_AlleFilter_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut_Handler =
    (truefalse) => {
      setSideBarLeft_AlleFilter_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut(
        truefalse
      );
    };

  const [
    SideBarLeft_AlleFilter_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut,
    setSideBarLeft_AlleFilter_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut,
  ] = useState(false);

  const setSideBarLeft_AlleFilter_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut_Handler =
    (truefalse) => {
      setSideBarLeft_AlleFilter_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut(
        truefalse
      );
    };

  const [
    SideBarLeft_AlleSocial_Farbe_Groesse_Stil_OnMouseOverAndOut,
    setSideBarLeft_AlleSocial_Farbe_Groesse_Stil_OnMouseOverAndOut,
  ] = useState(false);

  const setSideBarLeft_AlleSocial_Farbe_Groesse_Stil_OnMouseOverAndOut_Handler =
    (truefalse) => {
      setSideBarLeft_AlleSocial_Farbe_Groesse_Stil_OnMouseOverAndOut(truefalse);
    };

  const [
    SideBarLeft_AlleSocial_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut,
    setSideBarLeft_AlleSocial_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut,
  ] = useState(false);

  const setSideBarLeft_AlleSocial_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut_Handler =
    (truefalse) => {
      setSideBarLeft_AlleSocial_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut(
        truefalse
      );
    };

  const [
    SideBarLeft_AlleSocial_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut,
    setSideBarLeft_AlleSocial_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut,
  ] = useState(false);

  const setSideBarLeft_AlleSocial_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut_Handler =
    (truefalse) => {
      setSideBarLeft_AlleSocial_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut(
        truefalse
      );
    };

  const [
    SideBarLeft_AlleSocial_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut,
    setSideBarLeft_AlleSocial_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut,
  ] = useState(false);

  const setSideBarLeft_AlleSocial_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut_Handler =
    (truefalse) => {
      setSideBarLeft_AlleSocial_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut(
        truefalse
      );
    };

  const [
    SideBarLeft_AlleDetail_Farbe_Groesse_Stil_OnMouseOverAndOut,
    setSideBarLeft_AlleDetail_Farbe_Groesse_Stil_OnMouseOverAndOut,
  ] = useState(false);

  const setSideBarLeft_AlleDetail_Farbe_Groesse_Stil_OnMouseOverAndOut_Handler =
    (truefalse) => {
      setSideBarLeft_AlleDetail_Farbe_Groesse_Stil_OnMouseOverAndOut(truefalse);
    };

  const [
    SideBarLeft_AlleDetail_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut,
    setSideBarLeft_AlleDetail_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut,
  ] = useState(false);

  const setSideBarLeft_AlleDetail_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut_Handler =
    (truefalse) => {
      setSideBarLeft_AlleDetail_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut(
        truefalse
      );
    };

  const [
    SideBarLeft_Hauptkategorie_Farbe_Groesse_Stil_OnMouseOverAndOut,
    setSideBarLeft_Hauptkategorie_Farbe_Groesse_Stil_OnMouseOverAndOut,
  ] = useState(false);

  const setSideBarLeft_Hauptkategorie_Farbe_Groesse_Stil_OnMouseOverAndOut_Handler =
    (truefalse) => {
      setSideBarLeft_Hauptkategorie_Farbe_Groesse_Stil_OnMouseOverAndOut(
        truefalse
      );
    };

  const [
    SideBarLeft_Hauptkategorie_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut,
    setSideBarLeft_Hauptkategorie_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut,
  ] = useState(false);

  const setSideBarLeft_Hauptkategorie_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut_Handler =
    (truefalse) => {
      setSideBarLeft_Hauptkategorie_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut(
        truefalse
      );
    };

  const [
    SideBarLeft_Hauptkategorie_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut,
    setSideBarLeft_Hauptkategorie_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut,
  ] = useState(false);

  const setSideBarLeft_Hauptkategorie_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut_Handler =
    (truefalse) => {
      setSideBarLeft_Hauptkategorie_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut(
        truefalse
      );
    };

  const [
    SideBarLeft_Hauptkategorie_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut,
    setSideBarLeft_Hauptkategorie_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut,
  ] = useState(false);

  const setSideBarLeft_Hauptkategorie_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut_Handler =
    (truefalse) => {
      setSideBarLeft_Hauptkategorie_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut(
        truefalse
      );
    };

  const [
    SideBarLeft_HauptmenuTop_Farbe_Groesse_Stil_OnMouseOverAndOut,
    setSideBarLeft_HauptmenuTop_Farbe_Groesse_Stil_OnMouseOverAndOut,
  ] = useState(false);

  const setSideBarLeft_HauptmenuTop_Farbe_Groesse_Stil_OnMouseOverAndOut_Handler =
    (truefalse) => {
      setSideBarLeft_HauptmenuTop_Farbe_Groesse_Stil_OnMouseOverAndOut(
        truefalse
      );
    };

  const [
    SideBarLeft_HauptmenuTop_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut,
    setSideBarLeft_HauptmenuTop_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut,
  ] = useState(false);

  const setSideBarLeft_HauptmenuTop_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut_Handler =
    (truefalse) => {
      setSideBarLeft_HauptmenuTop_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut(
        truefalse
      );
    };

  const [
    SideBarLeft_HauptmenuTop_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut,
    setSideBarLeft_HauptmenuTop_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut,
  ] = useState(false);

  const setSideBarLeft_HauptmenuTop_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut_Handler =
    (truefalse) => {
      setSideBarLeft_HauptmenuTop_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut(
        truefalse
      );
    };

  const [
    SideBarLeft_HauptmenuTop_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut,
    setSideBarLeft_HauptmenuTop_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut,
  ] = useState(false);

  const setSideBarLeft_HauptmenuTop_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut_Handler =
    (truefalse) => {
      setSideBarLeft_HauptmenuTop_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut(
        truefalse
      );
    };

  const [
    SideBarLeft_HauptmenuTopStatic_Farbe_Groesse_Stil_OnMouseOverAndOut,
    setSideBarLeft_HauptmenuTopStatic_Farbe_Groesse_Stil_OnMouseOverAndOut,
  ] = useState(false);

  const setSideBarLeft_HauptmenuTopStatic_Farbe_Groesse_Stil_OnMouseOverAndOut_Handler =
    (truefalse) => {
      setSideBarLeft_HauptmenuTopStatic_Farbe_Groesse_Stil_OnMouseOverAndOut(
        truefalse
      );
    };

  const [
    SideBarLeft_HauptmenuTopStatic_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut,
    setSideBarLeft_HauptmenuTopStatic_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut,
  ] = useState(false);

  const setSideBarLeft_HauptmenuTopStatic_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut_Handler =
    (truefalse) => {
      setSideBarLeft_HauptmenuTopStatic_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut(
        truefalse
      );
    };

  const [
    SideBarLeft_HauptmenuTopStatic_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut,
    setSideBarLeft_HauptmenuTopStatic_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut,
  ] = useState(false);

  const setSideBarLeft_HauptmenuTopStatic_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut_Handler =
    (truefalse) => {
      setSideBarLeft_HauptmenuTopStatic_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut(
        truefalse
      );
    };

  const [
    SideBarLeft_HauptmenuTopStatic_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut,
    setSideBarLeft_HauptmenuTopStatic_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut,
  ] = useState(false);

  const setSideBarLeft_HauptmenuTopStatic_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut_Handler =
    (truefalse) => {
      setSideBarLeft_HauptmenuTopStatic_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut(
        truefalse
      );
    };

  const [
    SideBarLeft_Unterkategorie_Farbe_Groesse_Stil_OnMouseOverAndOut,
    setSideBarLeft_Unterkategorie_Farbe_Groesse_Stil_OnMouseOverAndOut,
  ] = useState(false);

  const setSideBarLeft_Unterkategorie_Farbe_Groesse_Stil_OnMouseOverAndOut_Handler =
    (truefalse) => {
      setSideBarLeft_Unterkategorie_Farbe_Groesse_Stil_OnMouseOverAndOut(
        truefalse
      );
    };

  const [
    SideBarLeft_Unterkategorie_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut,
    setSideBarLeft_Unterkategorie_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut,
  ] = useState(false);

  const setSideBarLeft_Unterkategorie_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut_Handler =
    (truefalse) => {
      setSideBarLeft_Unterkategorie_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut(
        truefalse
      );
    };

  const [
    SideBarLeft_Unterkategorie_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut,
    setSideBarLeft_Unterkategorie_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut,
  ] = useState(false);

  const setSideBarLeft_Unterkategorie_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut_Handler =
    (truefalse) => {
      setSideBarLeft_Unterkategorie_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut(
        truefalse
      );
    };

  const [
    SideBarLeft_Unterkategorie_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut,
    setSideBarLeft_Unterkategorie_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut,
  ] = useState(false);

  const setSideBarLeft_Unterkategorie_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut_Handler =
    (truefalse) => {
      setSideBarLeft_Unterkategorie_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut(
        truefalse
      );
    };

  const [
    SideBarLeft_FilterItem_Farbe_Groesse_Stil_OnMouseOverAndOut,
    setSideBarLeft_FilterItem_Farbe_Groesse_Stil_OnMouseOverAndOut,
  ] = useState(false);

  const setSideBarLeft_FilterItem_Farbe_Groesse_Stil_OnMouseOverAndOut_Handler =
    (truefalse) => {
      setSideBarLeft_FilterItem_Farbe_Groesse_Stil_OnMouseOverAndOut(truefalse);
    };

  const [
    SideBarLeft_FilterItem_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut,
    setSideBarLeft_FilterItem_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut,
  ] = useState(false);

  const setSideBarLeft_FilterItem_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut_Handler =
    (truefalse) => {
      setSideBarLeft_FilterItem_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut(
        truefalse
      );
    };

  const [
    SideBarLeft_FilterItem_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut,
    setSideBarLeft_FilterItem_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut,
  ] = useState(false);

  const setSideBarLeft_FilterItem_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut_Handler =
    (truefalse) => {
      setSideBarLeft_FilterItem_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut(
        truefalse
      );
    };

  const [
    SideBarLeft_FilterItem_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut,
    setSideBarLeft_FilterItem_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut,
  ] = useState(false);

  const setSideBarLeft_FilterItem_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut_Handler =
    (truefalse) => {
      setSideBarLeft_FilterItem_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut(
        truefalse
      );
    };

  const [
    SideBarLeft_SocialItem_Farbe_Groesse_Stil_OnMouseOverAndOut,
    setSideBarLeft_SocialItem_Farbe_Groesse_Stil_OnMouseOverAndOut,
  ] = useState(false);

  const setSideBarLeft_SocialItem_Farbe_Groesse_Stil_OnMouseOverAndOut_Handler =
    (truefalse) => {
      setSideBarLeft_SocialItem_Farbe_Groesse_Stil_OnMouseOverAndOut(truefalse);
    };

  const [
    SideBarLeft_SocialItem_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut,
    setSideBarLeft_SocialItem_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut,
  ] = useState(false);

  const setSideBarLeft_SocialItem_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut_Handler =
    (truefalse) => {
      setSideBarLeft_SocialItem_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut(
        truefalse
      );
    };

  const [
    SideBarLeft_SocialItem_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut,
    setSideBarLeft_SocialItem_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut,
  ] = useState(false);

  const setSideBarLeft_SocialItem_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut_Handler =
    (truefalse) => {
      setSideBarLeft_SocialItem_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut(
        truefalse
      );
    };

  const [
    SideBarLeft_SocialItem_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut,
    setSideBarLeft_SocialItem_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut,
  ] = useState(false);

  const setSideBarLeft_SocialItem_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut_Handler =
    (truefalse) => {
      setSideBarLeft_SocialItem_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut(
        truefalse
      );
    };

  const [
    SideBarLeft_DetailItem_Farbe_Groesse_Stil_OnMouseOverAndOut,
    setSideBarLeft_DetailItem_Farbe_Groesse_Stil_OnMouseOverAndOut,
  ] = useState(false);

  const setSideBarLeft_DetailItem_Farbe_Groesse_Stil_OnMouseOverAndOut_Handler =
    (truefalse) => {
      setSideBarLeft_DetailItem_Farbe_Groesse_Stil_OnMouseOverAndOut(truefalse);
    };

  const [
    SideBarLeft_DetailItem_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut,
    setSideBarLeft_DetailItem_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut,
  ] = useState(false);

  const setSideBarLeft_DetailItem_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut_Handler =
    (truefalse) => {
      setSideBarLeft_DetailItem_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut(
        truefalse
      );
    };

  const [
    SideBarLeft_DetailSuper_Farbe_Groesse_Stil_OnMouseOverAndOut,
    setSideBarLeft_DetailSuper_Farbe_Groesse_Stil_OnMouseOverAndOut,
  ] = useState(false);

  const setSideBarLeft_DetailSuper_Farbe_Groesse_Stil_OnMouseOverAndOut_Handler =
    (truefalse) => {
      setSideBarLeft_DetailSuper_Farbe_Groesse_Stil_OnMouseOverAndOut(
        truefalse
      );
    };

  const [
    SideBarLeft_DetailSuper_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut,
    setSideBarLeft_DetailSuper_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut,
  ] = useState(false);

  const setSideBarLeft_DetailSuper_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut_Handler =
    (truefalse) => {
      setSideBarLeft_DetailSuper_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut(
        truefalse
      );
    };

  const [
    SideBarLeft_FormInputDetail_Farbe_Groesse_Stil_OnMouseOverAndOut,
    setSideBarLeft_FormInputDetail_Farbe_Groesse_Stil_OnMouseOverAndOut,
  ] = useState(false);

  const setSideBarLeft_FormInputDetail_Farbe_Groesse_Stil_OnMouseOverAndOut_Handler =
    (truefalse) => {
      setSideBarLeft_FormInputDetail_Farbe_Groesse_Stil_OnMouseOverAndOut(
        truefalse
      );
    };

  const [
    SideBarLeft_FormInputDetail_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut,
    setSideBarLeft_FormInputDetail_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut,
  ] = useState(false);

  const setSideBarLeft_FormInputDetail_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut_Handler =
    (truefalse) => {
      setSideBarLeft_FormInputDetail_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut(
        truefalse
      );
    };

  const [
    SideBarLeft_TitelKontaktFormularTitel_Farbe_Groesse_Stil_OnMouseOverAndOut,
    setSideBarLeft_TitelKontaktFormularTitel_Farbe_Groesse_Stil_OnMouseOverAndOut,
  ] = useState(false);

  const setSideBarLeft_TitelKontaktFormularTitel_Farbe_Groesse_Stil_OnMouseOverAndOut_Handler =
    (truefalse) => {
      setSideBarLeft_TitelKontaktFormularTitel_Farbe_Groesse_Stil_OnMouseOverAndOut(
        truefalse
      );
    };

  const [
    SideBarLeft_TitelKontaktFormularTitel_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut,
    setSideBarLeft_TitelKontaktFormularTitel_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut,
  ] = useState(false);

  const setSideBarLeft_TitelKontaktFormularTitel_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut_Handler =
    (truefalse) => {
      setSideBarLeft_TitelKontaktFormularTitel_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut(
        truefalse
      );
    };

  const [
    SideBarLeft_HauptFiltertitel_Farbe_Groesse_Stil_OnMouseOverAndOut,
    setSideBarLeft_HauptFiltertitel_Farbe_Groesse_Stil_OnMouseOverAndOut,
  ] = useState(false);

  const setSideBarLeft_HauptFiltertitel_Farbe_Groesse_Stil_OnMouseOverAndOut_Handler =
    (truefalse) => {
      setSideBarLeft_HauptFiltertitel_Farbe_Groesse_Stil_OnMouseOverAndOut(
        truefalse
      );
    };

  const [
    SideBarLeft_HauptFiltertitel_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut,
    setSideBarLeft_HauptFiltertitel_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut,
  ] = useState(false);

  const setSideBarLeft_HauptFiltertitel_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut_Handler =
    (truefalse) => {
      setSideBarLeft_HauptFiltertitel_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut(
        truefalse
      );
    };

  const [
    SideBarLeft_HauptFiltertitel_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut,
    setSideBarLeft_HauptFiltertitel_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut,
  ] = useState(false);

  const setSideBarLeft_HauptFiltertitel_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut_Handler =
    (truefalse) => {
      setSideBarLeft_HauptFiltertitel_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut(
        truefalse
      );
    };

  const [
    SideBarLeft_HauptFiltertitel_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut,
    setSideBarLeft_HauptFiltertitel_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut,
  ] = useState(false);

  const setSideBarLeft_HauptFiltertitel_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut_Handler =
    (truefalse) => {
      setSideBarLeft_HauptFiltertitel_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut(
        truefalse
      );
    };

  const [
    SideBarLeft_UnterUnterkategorie_Farbe_Groesse_Stil_OnMouseOverAndOut,
    setSideBarLeft_UnterUnterkategorie_Farbe_Groesse_Stil_OnMouseOverAndOut,
  ] = useState(false);

  const setSideBarLeft_UnterUnterkategorie_Farbe_Groesse_Stil_OnMouseOverAndOut_Handler =
    (truefalse) => {
      setSideBarLeft_UnterUnterkategorie_Farbe_Groesse_Stil_OnMouseOverAndOut(
        truefalse
      );
    };

  const [
    SideBarLeft_UnterUnterkategorie_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut,
    setSideBarLeft_UnterUnterkategorie_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut,
  ] = useState(false);

  const setSideBarLeft_UnterUnterkategorie_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut_Handler =
    (truefalse) => {
      setSideBarLeft_UnterUnterkategorie_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut(
        truefalse
      );
    };

  const [
    SideBarLeft_UnterUnterkategorie_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut,
    setSideBarLeft_UnterUnterkategorie_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut,
  ] = useState(false);

  const setSideBarLeft_UnterUnterkategorie_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut_Handler =
    (truefalse) => {
      setSideBarLeft_UnterUnterkategorie_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut(
        truefalse
      );
    };

  const [
    SideBarLeft_UnterUnterkategorie_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut,
    setSideBarLeft_UnterUnterkategorie_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut,
  ] = useState(false);

  const setSideBarLeft_UnterUnterkategorie_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut_Handler =
    (truefalse) => {
      setSideBarLeft_UnterUnterkategorie_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut(
        truefalse
      );
    };

  const [
    SideBarLeft_Container_HintergrundFarbe_Radius_Stil_Trigger,
    setSideBarLeft_Container_HintergrundFarbe_Radius_Stil_Trigger,
  ] = useState(0);

  const setSideBarLeft_Container_HintergrundFarbe_Radius_Stil_Handler = () => {
    setSideBarLeft_Container_HintergrundFarbe_Radius_Stil_Trigger(
      (prev) => prev + 1
    );
  };

  const [
    SideBarLeft_Container_HintergrundFarbe_Radius_Stil_Hover_Trigger,
    setSideBarLeft_Container_HintergrundFarbe_Radius_Stil_Hover_Trigger,
  ] = useState(0);

  const setSideBarLeft_Container_HintergrundFarbe_Radius_Stil_Hover_Handler =
    () => {
      setSideBarLeft_Container_HintergrundFarbe_Radius_Stil_Hover_Trigger(
        (prev) => prev + 1
      );
    };

  const [
    SideBarLeft_ContainerTopAA_HintergrundFarbe_Radius_Stil_Trigger,
    setSideBarLeft_ContainerTopAA_HintergrundFarbe_Radius_Stil_Trigger,
  ] = useState(0);

  const setSideBarLeft_ContainerTop_HintergrundFarbe_Radius_Stil_Handler =
    () => {
      setSideBarLeft_ContainerTopAA_HintergrundFarbe_Radius_Stil_Trigger(
        (prev) => prev + 1
      );
    };

  const setSideBarLeft_ContainerTopStatic_HintergrundFarbe_Radius_Stil_Handler =
    () => {
      setSideBarLeft_ContainerTopXYZ_HintergrundFarbe_Radius_Stil_Trigger(
        (prev) => prev + 1
      );
    };

  const [
    SideBarLeft_ContainerTopAA_HintergrundFarbe_Radius_Stil_Hover_Trigger,
    setSideBarLeft_ContainerTopAA_HintergrundFarbe_Radius_Stil_Hover_Trigger,
  ] = useState(0);

  const setSideBarLeft_ContainerTop_HintergrundFarbe_Radius_Stil_Hover_Handler =
    () => {
      setSideBarLeft_ContainerTopAA_HintergrundFarbe_Radius_Stil_Hover_Trigger(
        (prev) => prev + 1
      );
    };

  /// XXXYYYZZZ START
  const [
    SideBarLeft_ContainerTopXYZ_HintergrundFarbe_Radius_Stil_Trigger,
    setSideBarLeft_ContainerTopXYZ_HintergrundFarbe_Radius_Stil_Trigger,
  ] = useState(0);

  const setSideBarStatic_ContainerTop_HintergrundFarbe_Radius_Stil_Handler =
    () => {
      setSideBarLeft_ContainerTopXYZ_HintergrundFarbe_Radius_Stil_Trigger(
        (prev) => prev + 1
      );
    };

  const [
    SideBarLeft_ContainerTopXYZ_HintergrundFarbe_Radius_Stil_Hover_Trigger,
    setSideBarLeft_ContainerTopXYZ_HintergrundFarbe_Radius_Stil_Hover_Trigger,
  ] = useState(0);

  const setSideBarStatic_ContainerTop_HintergrundFarbe_Radius_Stil_Hover_Handler =
    () => {
      setSideBarLeft_ContainerTopXYZ_HintergrundFarbe_Radius_Stil_Hover_Trigger(
        (prev) => prev + 1
      );
    };

  /// XXXYYYZZZ END

  const [
    SideBarLeft_SortierungTop_HintergrundFarbe_Radius_Stil_Trigger,
    setSideBarLeft_SortierungTop_HintergrundFarbe_Radius_Stil_Trigger,
  ] = useState(0);

  const setSideBarLeft_SortierungTop_HintergrundFarbe_Radius_Stil_Handler =
    () => {
      setSideBarLeft_SortierungTop_HintergrundFarbe_Radius_Stil_Trigger(
        (prev) => prev + 1
      );
    };

  const [
    SideBarLeft_SortierungTop_HintergrundFarbe_Radius_Stil_Hover_Trigger,
    setSideBarLeft_SortierungTop_HintergrundFarbe_Radius_Stil_Hover_Trigger,
  ] = useState(0);

  const setSideBarLeft_SortierungTop_HintergrundFarbe_Radius_Stil_Hover_Handler =
    () => {
      setSideBarLeft_SortierungTop_HintergrundFarbe_Radius_Stil_Hover_Trigger(
        (prev) => prev + 1
      );
    };

  const [
    SideBarLeft_ProduktContainer_HintergrundFarbe_Radius_Stil_Trigger,
    setSideBarLeft_ProduktContainer_HintergrundFarbe_Radius_Stil_Trigger,
  ] = useState(0);

  const setSideBarLeft_ProduktContainer_HintergrundFarbe_Radius_Stil_Handler =
    () => {
      setSideBarLeft_ProduktContainer_HintergrundFarbe_Radius_Stil_Trigger(
        (prev) => prev + 1
      );
    };

  const [
    SideBarLeft_ProduktContainer_HintergrundFarbe_Radius_Stil_Hover_Trigger,
    setSideBarLeft_ProduktContainer_HintergrundFarbe_Radius_Stil_Hover_Trigger,
  ] = useState(0);

  const setSideBarLeft_ProduktContainer_HintergrundFarbe_Radius_Stil_Hover_Handler =
    () => {
      setSideBarLeft_ProduktContainer_HintergrundFarbe_Radius_Stil_Hover_Trigger(
        (prev) => prev + 1
      );
    };

  const [
    SideBarLeft_AlleProdukte_Farbe_Groesse_Stil_Trigger,
    setSideBarLeft_AlleProdukte_Farbe_Groesse_Stil_Trigger,
  ] = useState(0);

  const setSideBarLeft_AlleProdukte_Farbe_Groesse_Stil_Handler = () => {
    setSideBarLeft_AlleProdukte_Farbe_Groesse_Stil_Trigger((prev) => prev + 1);
  };

  const [
    SideBarLeft_AlleProdukte_Farbe_Groesse_Stil_Hover_Trigger,
    setSideBarLeft_AlleProdukte_Farbe_Groesse_Stil_Hover_Trigger,
  ] = useState(0);

  const setSideBarLeft_AlleProdukte_Farbe_Groesse_Stil_Hover_Handler = () => {
    setSideBarLeft_AlleProdukte_Farbe_Groesse_Stil_Hover_Trigger(
      (prev) => prev + 1
    );
  };

  const [
    SideBarLeft_AlleProdukte_HintergrundFarbe_Radius_Stil_Trigger,
    setSideBarLeft_AlleProdukte_HintergrundFarbe_Radius_Stil_Trigger,
  ] = useState(0);

  const setSideBarLeft_AlleProdukte_HintergrundFarbe_Radius_Stil_Handler =
    () => {
      setSideBarLeft_AlleProdukte_HintergrundFarbe_Radius_Stil_Trigger(
        (prev) => prev + 1
      );
    };

  const [
    SideBarLeft_AlleProdukte_HintergrundFarbe_Radius_Stil_Hover_Trigger,
    setSideBarLeft_AlleProdukte_HintergrundFarbe_Radius_Stil_Hover_Trigger,
  ] = useState(0);

  const setSideBarLeft_AlleProdukte_HintergrundFarbe_Radius_Stil_Hover_Handler =
    () => {
      setSideBarLeft_AlleProdukte_HintergrundFarbe_Radius_Stil_Hover_Trigger(
        (prev) => prev + 1
      );
    };

  const [
    SideBarLeft_Hauptkategorie_Farbe_Groesse_Stil_Trigger,
    setSideBarLeft_Hauptkategorie_Farbe_Groesse_Stil_Trigger,
  ] = useState(0);

  const setSideBarLeft_Hauptkategorie_Farbe_Groesse_Stil_Handler = () => {
    setSideBarLeft_Hauptkategorie_Farbe_Groesse_Stil_Trigger(
      (prev) => prev + 1
    );
  };

  const [
    SideBarLeft_Hauptkategorie_Farbe_Groesse_Stil_Hover_Trigger,
    setSideBarLeft_Hauptkategorie_Farbe_Groesse_Stil_Hover_Trigger,
  ] = useState(0);

  const setSideBarLeft_Hauptkategorie_Farbe_Groesse_Stil_Hover_Handler = () => {
    setSideBarLeft_Hauptkategorie_Farbe_Groesse_Stil_Hover_Trigger(
      (prev) => prev + 1
    );
  };

  const [
    SideBarLeft_Hauptkategorie_HintergrundFarbe_Radius_Stil_Trigger,
    setSideBarLeft_Hauptkategorie_HintergrundFarbe_Radius_Stil_Trigger,
  ] = useState(0);

  const setSideBarLeft_Hauptkategorie_HintergrundFarbe_Radius_Stil_Handler =
    () => {
      setSideBarLeft_Hauptkategorie_HintergrundFarbe_Radius_Stil_Trigger(
        (prev) => prev + 1
      );
    };

  const [
    SideBarLeft_Hauptkategorie_HintergrundFarbe_Radius_Stil_Hover_Trigger,
    setSideBarLeft_Hauptkategorie_HintergrundFarbe_Radius_Stil_Hover_Trigger,
  ] = useState(0);

  const setSideBarLeft_Hauptkategorie_HintergrundFarbe_Radius_Stil_Hover_Handler =
    () => {
      setSideBarLeft_Hauptkategorie_HintergrundFarbe_Radius_Stil_Hover_Trigger(
        (prev) => prev + 1
      );
    };

  const [
    SideBarLeft_AlleFilter_Farbe_Groesse_Stil_Trigger,
    setSideBarLeft_AlleFilter_Farbe_Groesse_Stil_Trigger,
  ] = useState(0);

  const setSideBarLeft_AlleFilter_Farbe_Groesse_Stil_Handler = () => {
    setSideBarLeft_AlleFilter_Farbe_Groesse_Stil_Trigger((prev) => prev + 1);
  };

  const [
    SideBarLeft_AlleFilter_Farbe_Groesse_Stil_Hover_Trigger,
    setSideBarLeft_AlleFilter_Farbe_Groesse_Stil_Hover_Trigger,
  ] = useState(0);

  const setSideBarLeft_AlleFilter_Farbe_Groesse_Stil_Hover_Handler = () => {
    setSideBarLeft_AlleFilter_Farbe_Groesse_Stil_Hover_Trigger(
      (prev) => prev + 1
    );
  };

  const [
    SideBarLeft_AlleFilter_HintergrundFarbe_Radius_Stil_Trigger,
    setSideBarLeft_AlleFilter_HintergrundFarbe_Radius_Stil_Trigger,
  ] = useState(0);

  const setSideBarLeft_AlleFilter_HintergrundFarbe_Radius_Stil_Handler = () => {
    setSideBarLeft_AlleFilter_HintergrundFarbe_Radius_Stil_Trigger(
      (prev) => prev + 1
    );
  };

  const [
    SideBarLeft_AlleFilter_HintergrundFarbe_Radius_Stil_Hover_Trigger,
    setSideBarLeft_AlleFilter_HintergrundFarbe_Radius_Stil_Hover_Trigger,
  ] = useState(0);

  const setSideBarLeft_AlleFilter_HintergrundFarbe_Radius_Stil_Hover_Handler =
    () => {
      setSideBarLeft_AlleFilter_HintergrundFarbe_Radius_Stil_Hover_Trigger(
        (prev) => prev + 1
      );
    };

  const [
    SideBarLeft_AlleSocial_Farbe_Groesse_Stil_Trigger,
    setSideBarLeft_AlleSocial_Farbe_Groesse_Stil_Trigger,
  ] = useState(0);

  const setSideBarLeft_AlleSocial_Farbe_Groesse_Stil_Handler = () => {
    setSideBarLeft_AlleSocial_Farbe_Groesse_Stil_Trigger((prev) => prev + 1);
  };

  const [
    SideBarLeft_AlleSocial_Farbe_Groesse_Stil_Hover_Trigger,
    setSideBarLeft_AlleSocial_Farbe_Groesse_Stil_Hover_Trigger,
  ] = useState(0);

  const setSideBarLeft_AlleSocial_Farbe_Groesse_Stil_Hover_Handler = () => {
    setSideBarLeft_AlleSocial_Farbe_Groesse_Stil_Hover_Trigger(
      (prev) => prev + 1
    );
  };

  const [
    SideBarLeft_AlleSocial_HintergrundFarbe_Radius_Stil_Trigger,
    setSideBarLeft_AlleSocial_HintergrundFarbe_Radius_Stil_Trigger,
  ] = useState(0);

  const setSideBarLeft_AlleSocial_HintergrundFarbe_Radius_Stil_Handler = () => {
    setSideBarLeft_AlleSocial_HintergrundFarbe_Radius_Stil_Trigger(
      (prev) => prev + 1
    );
  };

  const [
    SideBarLeft_AlleSocial_HintergrundFarbe_Radius_Stil_Hover_Trigger,
    setSideBarLeft_AlleSocial_HintergrundFarbe_Radius_Stil_Hover_Trigger,
  ] = useState(0);

  const setSideBarLeft_AlleSocial_HintergrundFarbe_Radius_Stil_Hover_Handler =
    () => {
      setSideBarLeft_AlleSocial_HintergrundFarbe_Radius_Stil_Hover_Trigger(
        (prev) => prev + 1
      );
    };

  const [
    SideBarLeft_AlleDetail_Farbe_Groesse_Stil_Trigger,
    setSideBarLeft_AlleDetail_Farbe_Groesse_Stil_Trigger,
  ] = useState(0);

  const setSideBarLeft_AlleDetail_Farbe_Groesse_Stil_Handler = () => {
    setSideBarLeft_AlleDetail_Farbe_Groesse_Stil_Trigger((prev) => prev + 1);
  };

  const [
    SideBarLeft_AlleDetail_HintergrundFarbe_Radius_Stil_Trigger,
    setSideBarLeft_AlleDetail_HintergrundFarbe_Radius_Stil_Trigger,
  ] = useState(0);

  const setSideBarLeft_AlleDetail_HintergrundFarbe_Radius_Stil_Handler = () => {
    setSideBarLeft_AlleDetail_HintergrundFarbe_Radius_Stil_Trigger(
      (prev) => prev + 1
    );
  };

  const [
    SideBarLeft_HauptFiltertitel_Farbe_Groesse_Stil_Trigger,
    setSideBarLeft_HauptFiltertitel_Farbe_Groesse_Stil_Trigger,
  ] = useState(0);

  const setSideBarLeft_HauptFiltertitel_Farbe_Groesse_Stil_Handler = () => {
    setSideBarLeft_HauptFiltertitel_Farbe_Groesse_Stil_Trigger(
      (prev) => prev + 1
    );
  };

  const [
    SideBarLeft_HauptFiltertitel_Farbe_Groesse_Stil_Hover_Trigger,
    setSideBarLeft_HauptFiltertitel_Farbe_Groesse_Stil_Hover_Trigger,
  ] = useState(0);

  const setSideBarLeft_HauptFiltertitel_Farbe_Groesse_Stil_Hover_Handler =
    () => {
      setSideBarLeft_HauptFiltertitel_Farbe_Groesse_Stil_Hover_Trigger(
        (prev) => prev + 1
      );
    };

  const [
    SideBarLeft_HauptFiltertitel_HintergrundFarbe_Radius_Stil_Trigger,
    setSideBarLeft_HauptFiltertitel_HintergrundFarbe_Radius_Stil_Trigger,
  ] = useState(0);

  const setSideBarLeft_HauptFiltertitel_HintergrundFarbe_Radius_Stil_Handler =
    () => {
      setSideBarLeft_HauptFiltertitel_HintergrundFarbe_Radius_Stil_Trigger(
        (prev) => prev + 1
      );
    };

  const [
    SideBarLeft_HauptFiltertitel_HintergrundFarbe_Radius_Stil_Hover_Trigger,
    setSideBarLeft_HauptFiltertitel_HintergrundFarbe_Radius_Stil_Hover_Trigger,
  ] = useState(0);

  const setSideBarLeft_HauptFiltertitel_HintergrundFarbe_Radius_Stil_Hover_Handler =
    () => {
      setSideBarLeft_HauptFiltertitel_HintergrundFarbe_Radius_Stil_Hover_Trigger(
        (prev) => prev + 1
      );
    };

  const [
    SideBarLeft_FilterItem_Farbe_Groesse_Stil_Trigger,
    setSideBarLeft_FilterItem_Farbe_Groesse_Stil_Trigger,
  ] = useState(0);

  const setSideBarLeft_FilterItem_Farbe_Groesse_Stil_Handler = () => {
    setSideBarLeft_FilterItem_Farbe_Groesse_Stil_Trigger((prev) => prev + 1);
  };

  const [
    SideBarLeft_FilterItem_Farbe_Groesse_Stil_Hover_Trigger,
    setSideBarLeft_FilterItem_Farbe_Groesse_Stil_Hover_Trigger,
  ] = useState(0);

  const setSideBarLeft_FilterItem_Farbe_Groesse_Stil_Hover_Handler = () => {
    setSideBarLeft_FilterItem_Farbe_Groesse_Stil_Hover_Trigger(
      (prev) => prev + 1
    );
  };

  const [
    SideBarLeft_FilterItem_HintergrundFarbe_Radius_Stil_Trigger,
    setSideBarLeft_FilterItem_HintergrundFarbe_Radius_Stil_Trigger,
  ] = useState(0);

  const setSideBarLeft_FilterItem_HintergrundFarbe_Radius_Stil_Handler = () => {
    setSideBarLeft_FilterItem_HintergrundFarbe_Radius_Stil_Trigger(
      (prev) => prev + 1
    );
  };

  const [
    SideBarLeft_FilterItem_HintergrundFarbe_Radius_Stil_Hover_Trigger,
    setSideBarLeft_FilterItem_HintergrundFarbe_Radius_Stil_Hover_Trigger,
  ] = useState(0);

  const setSideBarLeft_FilterItem_HintergrundFarbe_Radius_Stil_Hover_Handler =
    () => {
      setSideBarLeft_FilterItem_HintergrundFarbe_Radius_Stil_Hover_Trigger(
        (prev) => prev + 1
      );
    };

  const [
    SideBarLeft_SocialItem_Farbe_Groesse_Stil_Trigger,
    setSideBarLeft_SocialItem_Farbe_Groesse_Stil_Trigger,
  ] = useState(0);

  const setSideBarLeft_SocialItem_Farbe_Groesse_Stil_Handler = () => {
    setSideBarLeft_SocialItem_Farbe_Groesse_Stil_Trigger((prev) => prev + 1);
  };

  const [
    SideBarLeft_SocialItem_Farbe_Groesse_Stil_Hover_Trigger,
    setSideBarLeft_SocialItem_Farbe_Groesse_Stil_Hover_Trigger,
  ] = useState(0);

  const [
    SideBarLeft_SocialItem_HintergrundFarbe_Radius_Stil_Trigger,
    setSideBarLeft_SocialItem_HintergrundFarbe_Radius_Stil_Trigger,
  ] = useState(0);

  const [
    SideBarLeft_SocialItem_HintergrundFarbe_Radius_Stil_Hover_Trigger,
    setSideBarLeft_SocialItem_HintergrundFarbe_Radius_Stil_Hover_Trigger,
  ] = useState(0);

  const [
    SideBarLeft_DetailItem_Farbe_Groesse_Stil_Trigger,
    setSideBarLeft_DetailItem_Farbe_Groesse_Stil_Trigger,
  ] = useState(0);

  const setSideBarLeft_DetailItem_Farbe_Groesse_Stil_Handler = () => {
    setSideBarLeft_DetailItem_Farbe_Groesse_Stil_Trigger((prev) => prev + 1);
  };

  const [
    SideBarLeft_DetailItem_HintergrundFarbe_Radius_Stil_Trigger,
    setSideBarLeft_DetailItem_HintergrundFarbe_Radius_Stil_Trigger,
  ] = useState(0);

  const setSideBarLeft_DetailItem_HintergrundFarbe_Radius_Stil_Handler = () => {
    setSideBarLeft_DetailItem_HintergrundFarbe_Radius_Stil_Trigger(
      (prev) => prev + 1
    );
  };

  const [
    SideBarLeft_DetailSuper_Farbe_Groesse_Stil_Trigger,
    setSideBarLeft_DetailSuper_Farbe_Groesse_Stil_Trigger,
  ] = useState(0);

  const setSideBarLeft_DetailSuper_Farbe_Groesse_Stil_Handler = () => {
    setSideBarLeft_DetailSuper_Farbe_Groesse_Stil_Trigger((prev) => prev + 1);
  };

  const [
    SideBarLeft_DetailSuper_HintergrundFarbe_Radius_Stil_Trigger,
    setSideBarLeft_DetailSuper_HintergrundFarbe_Radius_Stil_Trigger,
  ] = useState(0);

  const setSideBarLeft_DetailSuper_HintergrundFarbe_Radius_Stil_Handler =
    () => {
      setSideBarLeft_DetailSuper_HintergrundFarbe_Radius_Stil_Trigger(
        (prev) => prev + 1
      );
    };

  const [
    SideBarLeft_TitelKontaktFormularTitel_Farbe_Groesse_Stil_Trigger,
    setSideBarLeft_TitelKontaktFormularTitel_Farbe_Groesse_Stil_Trigger,
  ] = useState(0);

  const setSideBarLeft_TitelKontaktFormularTitel_Farbe_Groesse_Stil_Handler =
    () => {
      setSideBarLeft_TitelKontaktFormularTitel_Farbe_Groesse_Stil_Trigger(
        (prev) => prev + 1
      );
    };

  const [
    SideBarLeft_TitelKontaktFormularTitel_HintergrundFarbe_Radius_Stil_Trigger,
    setSideBarLeft_TitelKontaktFormularTitel_HintergrundFarbe_Radius_Stil_Trigger,
  ] = useState(0);

  const setSideBarLeft_TitelKontaktFormularTitel_HintergrundFarbe_Radius_Stil_Handler =
    () => {
      setSideBarLeft_TitelKontaktFormularTitel_HintergrundFarbe_Radius_Stil_Trigger(
        (prev) => prev + 1
      );
    };

  const [
    SideBarLeft_FormInputDetail_Farbe_Groesse_Stil_Trigger,
    setSideBarLeft_FormInputDetail_Farbe_Groesse_Stil_Trigger,
  ] = useState(0);

  const setSideBarLeft_FormInputDetail_Farbe_Groesse_Stil_Handler = () => {
    setSideBarLeft_FormInputDetail_Farbe_Groesse_Stil_Trigger(
      (prev) => prev + 1
    );
  };

  const [
    SideBarLeft_FormInputDetail_HintergrundFarbe_Radius_Stil_Trigger,
    setSideBarLeft_FormInputDetail_HintergrundFarbe_Radius_Stil_Trigger,
  ] = useState(0);

  const setSideBarLeft_FormInputDetail_HintergrundFarbe_Radius_Stil_Handler =
    () => {
      setSideBarLeft_FormInputDetail_HintergrundFarbe_Radius_Stil_Trigger(
        (prev) => prev + 1
      );
    };

  const [
    SideBarLeft_ContainerFilter_Farbe_Groesse_Stil_Trigger,
    setSideBarLeft_ContainerFilter_Farbe_Groesse_Stil_Trigger,
  ] = useState(0);

  const setSideBarLeft_ContainerFilter_Farbe_Groesse_Stil_Handler = () => {
    setSideBarLeft_ContainerFilter_Farbe_Groesse_Stil_Trigger(
      (prev) => prev + 1
    );
  };

  const [
    SideBarLeft_ContainerFilter_Farbe_Groesse_Stil_Hover_Trigger,
    setSideBarLeft_ContainerFilter_Farbe_Groesse_Stil_Hover_Trigger,
  ] = useState(0);

  const setSideBarLeft_ContainerFilter_Farbe_Groesse_Stil_Hover_Handler =
    () => {
      setSideBarLeft_ContainerFilter_Farbe_Groesse_Stil_Hover_Trigger(
        (prev) => prev + 1
      );
    };

  const [
    SideBarLeft_ContainerFilter_HintergrundFarbe_Radius_Stil_Trigger,
    setSideBarLeft_ContainerFilter_HintergrundFarbe_Radius_Stil_Trigger,
  ] = useState(0);

  const setSideBarLeft_ContainerFilter_HintergrundFarbe_Radius_Stil_Handler =
    () => {
      setSideBarLeft_ContainerFilter_HintergrundFarbe_Radius_Stil_Trigger(
        (prev) => prev + 1
      );
    };

  const [
    SideBarLeft_ContainerFilter_HintergrundFarbe_Radius_Stil_Hover_Trigger,
    setSideBarLeft_ContainerFilter_HintergrundFarbe_Radius_Stil_Hover_Trigger,
  ] = useState(0);

  const setSideBarLeft_ContainerFilter_HintergrundFarbe_Radius_Stil_Hover_Handler =
    () => {
      setSideBarLeft_ContainerFilter_HintergrundFarbe_Radius_Stil_Hover_Trigger(
        (prev) => prev + 1
      );
    };

  const [
    SideBarLeft_ContainerSocial_Farbe_Groesse_Stil_Trigger,
    setSideBarLeft_ContainerSocial_Farbe_Groesse_Stil_Trigger,
  ] = useState(0);

  const setSideBarLeft_ContainerSocial_Farbe_Groesse_Stil_Handler = () => {
    setSideBarLeft_ContainerSocial_Farbe_Groesse_Stil_Trigger(
      (prev) => prev + 1
    );
  };

  const [
    SideBarLeft_ContainerSocial_Farbe_Groesse_Stil_Hover_Trigger,
    setSideBarLeft_ContainerSocial_Farbe_Groesse_Stil_Hover_Trigger,
  ] = useState(0);

  const setSideBarLeft_ContainerSocial_Farbe_Groesse_Stil_Hover_Handler =
    () => {
      setSideBarLeft_ContainerSocial_Farbe_Groesse_Stil_Hover_Trigger(
        (prev) => prev + 1
      );
    };

  const [
    SideBarLeft_ContainerSocial_HintergrundFarbe_Radius_Stil_Trigger,
    setSideBarLeft_ContainerSocial_HintergrundFarbe_Radius_Stil_Trigger,
  ] = useState(0);

  const setSideBarLeft_ContainerSocial_HintergrundFarbe_Radius_Stil_Handler =
    () => {
      setSideBarLeft_ContainerSocial_HintergrundFarbe_Radius_Stil_Trigger(
        (prev) => prev + 1
      );
    };

  const [
    SideBarLeft_ContainerSocial_HintergrundFarbe_Radius_Stil_Hover_Trigger,
    setSideBarLeft_ContainerSocial_HintergrundFarbe_Radius_Stil_Hover_Trigger,
  ] = useState(0);

  const setSideBarLeft_ContainerSocial_HintergrundFarbe_Radius_Stil_Hover_Handler =
    () => {
      setSideBarLeft_ContainerSocial_HintergrundFarbe_Radius_Stil_Hover_Trigger(
        (prev) => prev + 1
      );
    };

  const [
    SideBarLeft_ContainerDetail_Farbe_Groesse_Stil_Trigger,
    setSideBarLeft_ContainerDetail_Farbe_Groesse_Stil_Trigger,
  ] = useState(0);

  const setSideBarLeft_ContainerDetail_Farbe_Groesse_Stil_Handler = () => {
    setSideBarLeft_ContainerDetail_Farbe_Groesse_Stil_Trigger(
      (prev) => prev + 1
    );
  };

  const [
    SideBarLeft_ContainerDetail_HintergrundFarbe_Radius_Stil_Trigger,
    setSideBarLeft_ContainerDetail_HintergrundFarbe_Radius_Stil_Trigger,
  ] = useState(0);

  const setSideBarLeft_ContainerDetail_HintergrundFarbe_Radius_Stil_Handler =
    () => {
      setSideBarLeft_ContainerDetail_HintergrundFarbe_Radius_Stil_Trigger(
        (prev) => prev + 1
      );
    };

  const [
    SideBarLeft_HauptmenuTopAA_Farbe_Groesse_Stil_Trigger,
    setSideBarLeft_HauptmenuTopAA_Farbe_Groesse_Stil_Trigger,
  ] = useState(0);

  const setSideBarLeft_HauptmenuTop_Farbe_Groesse_Stil_Handler = () => {
    setSideBarLeft_HauptmenuTopAA_Farbe_Groesse_Stil_Trigger(
      (prev) => prev + 1
    );
  };

  const [
    SideBarLeft_HauptmenuTopAA_Farbe_Groesse_Stil_Hover_Trigger,
    setSideBarLeft_HauptmenuTopAA_Farbe_Groesse_Stil_Hover_Trigger,
  ] = useState(0);

  const setSideBarLeft_HauptmenuTop_Farbe_Groesse_Stil_Hover_Handler = () => {
    setSideBarLeft_HauptmenuTopAA_Farbe_Groesse_Stil_Hover_Trigger(
      (prev) => prev + 1
    );
  };

  const [
    SideBarLeft_HauptmenuTopAA_HintergrundFarbe_Radius_Stil_Trigger,
    setSideBarLeft_HauptmenuTopAA_HintergrundFarbe_Radius_Stil_Trigger,
  ] = useState(0);

  const setSideBarLeft_HauptmenuTop_HintergrundFarbe_Radius_Stil_Handler =
    () => {
      setSideBarLeft_HauptmenuTopAA_HintergrundFarbe_Radius_Stil_Trigger(
        (prev) => prev + 1
      );
    };

  const [
    SideBarLeft_HauptmenuTopAA_HintergrundFarbe_Radius_Stil_Hover_Trigger,
    setSideBarLeft_HauptmenuTopAA_HintergrundFarbe_Radius_Stil_Hover_Trigger,
  ] = useState(0);

  const setSideBarLeft_HauptmenuTop_HintergrundFarbe_Radius_Stil_Hover_Handler =
    () => {
      setSideBarLeft_HauptmenuTopAA_HintergrundFarbe_Radius_Stil_Hover_Trigger(
        (prev) => prev + 1
      );
    };

  const [
    SideBarLeft_HauptmenuTopStatic_Farbe_Groesse_Stil_Trigger,
    setSideBarLeft_HauptmenuTopStatic_Farbe_Groesse_Stil_Trigger,
  ] = useState(0);

  const setSideBarLeft_HauptmenuTopStatic_Farbe_Groesse_Stil_Handler = () => {
    setSideBarLeft_HauptmenuTopStatic_Farbe_Groesse_Stil_Trigger(
      (prev) => prev + 1
    );
  };

  const [
    SideBarLeft_HauptmenuTopStatic_Farbe_Groesse_Stil_Hover_Trigger,
    setSideBarLeft_HauptmenuTopStatic_Farbe_Groesse_Stil_Hover_Trigger,
  ] = useState(0);

  const setSideBarLeft_HauptmenuTopStatic_Farbe_Groesse_Stil_Hover_Handler =
    () => {
      setSideBarLeft_HauptmenuTopStatic_Farbe_Groesse_Stil_Hover_Trigger(
        (prev) => prev + 1
      );
    };

  const [
    SideBarLeft_HauptmenuTopStatic_HintergrundFarbe_Radius_Stil_Trigger,
    setSideBarLeft_HauptmenuTopStatic_HintergrundFarbe_Radius_Stil_Trigger,
  ] = useState(0);

  const setSideBarLeft_HauptmenuTopStatic_HintergrundFarbe_Radius_Stil_Handler =
    () => {
      setSideBarLeft_HauptmenuTopStatic_HintergrundFarbe_Radius_Stil_Trigger(
        (prev) => prev + 1
      );
    };

  const [
    SideBarLeft_HauptmenuTopStatic_HintergrundFarbe_Radius_Stil_Hover_Trigger,
    setSideBarLeft_HauptmenuTopStatic_HintergrundFarbe_Radius_Stil_Hover_Trigger,
  ] = useState(0);

  const setSideBarLeft_HauptmenuTopStatic_HintergrundFarbe_Radius_Stil_Hover_Handler =
    () => {
      setSideBarLeft_HauptmenuTopStatic_HintergrundFarbe_Radius_Stil_Hover_Trigger(
        (prev) => prev + 1
      );
    };

  const [
    SideBarLeft_Unterkategorie_Farbe_Groesse_Stil_Trigger,
    setSideBarLeft_Unterkategorie_Farbe_Groesse_Stil_Trigger,
  ] = useState(0);

  const setSideBarLeft_Unterkategorie_Farbe_Groesse_Stil_Handler = () => {
    setSideBarLeft_Unterkategorie_Farbe_Groesse_Stil_Trigger(
      (prev) => prev + 1
    );
  };

  const [
    SideBarLeft_Unterkategorie_Farbe_Groesse_Stil_Hover_Trigger,
    setSideBarLeft_Unterkategorie_Farbe_Groesse_Stil_Hover_Trigger,
  ] = useState(0);

  const setSideBarLeft_Unterkategorie_Farbe_Groesse_Stil_Hover_Handler = () => {
    setSideBarLeft_Unterkategorie_Farbe_Groesse_Stil_Hover_Trigger(
      (prev) => prev + 1
    );
  };

  const [
    SideBarLeft_Unterkategorie_HintergrundFarbe_Radius_Stil_Trigger,
    setSideBarLeft_Unterkategorie_HintergrundFarbe_Radius_Stil_Trigger,
  ] = useState(0);

  const setSideBarLeft_Unterkategorie_HintergrundFarbe_Radius_Stil_Handler =
    () => {
      setSideBarLeft_Unterkategorie_HintergrundFarbe_Radius_Stil_Trigger(
        (prev) => prev + 1
      );
    };

  const [
    SideBarLeft_Unterkategorie_HintergrundFarbe_Radius_Stil_Hover_Trigger,
    setSideBarLeft_Unterkategorie_HintergrundFarbe_Radius_Stil_Hover_Trigger,
  ] = useState(0);

  const setSideBarLeft_Unterkategorie_HintergrundFarbe_Radius_Stil_Hover_Handler =
    () => {
      setSideBarLeft_Unterkategorie_HintergrundFarbe_Radius_Stil_Hover_Trigger(
        (prev) => prev + 1
      );
    };

  const [
    SideBarLeft_UnterUnterkategorie_Farbe_Groesse_Stil_Trigger,
    setSideBarLeft_UnterUnterkategorie_Farbe_Groesse_Stil_Trigger,
  ] = useState(0);

  const setSideBarLeft_UnterUnterkategorie_Farbe_Groesse_Stil_Handler = () => {
    setSideBarLeft_UnterUnterkategorie_Farbe_Groesse_Stil_Trigger(
      (prev) => prev + 1
    );
  };

  const [
    SideBarLeft_UnterUnterkategorie_Farbe_Groesse_Stil_Hover_Trigger,
    setSideBarLeft_UnterUnterkategorie_Farbe_Groesse_Stil_Hover_Trigger,
  ] = useState(0);

  const setSideBarLeft_UnterUnterkategorie_Farbe_Groesse_Stil_Hover_Handler =
    () => {
      setSideBarLeft_UnterUnterkategorie_Farbe_Groesse_Stil_Hover_Trigger(
        (prev) => prev + 1
      );
    };

  const [
    SideBarLeft_UnterUnterkategorie_HintergrundFarbe_Radius_Stil_Trigger,
    setSideBarLeft_UnterUnterkategorie_HintergrundFarbe_Radius_Stil_Trigger,
  ] = useState(0);

  const [
    SideBarLeft_UnterUnterkategorie_HintergrundFarbe_Radius_Stil_Hover_Trigger,
    setSideBarLeft_UnterUnterkategorie_HintergrundFarbe_Radius_Stil_Hover_Trigger,
  ] = useState(0);

  const setSideBarLeft_UnterUnterkategorie_HintergrundFarbe_Radius_Stil_Handler =
    () => {
      setSideBarLeft_UnterUnterkategorie_HintergrundFarbe_Radius_Stil_Trigger(
        (prev) => prev + 1
      );
    };

  const setSideBarLeft_UnterUnterkategorie_HintergrundFarbe_Radius_Stil_Hover_Handler =
    () => {
      setSideBarLeft_UnterUnterkategorie_HintergrundFarbe_Radius_Stil_Hover_Trigger(
        (prev) => prev + 1
      );
    };

  const [
    SideBarLeft_HauptTitel_Farbe_Groesse_Stil_OnMouseOverAndOut,
    setSideBarLeft_HauptTitel_Farbe_Groesse_Stil_OnMouseOverAndOut,
  ] = useState(false);

  const setSideBarLeft_HauptTitel_Farbe_Groesse_Stil_OnMouseOverAndOut_Handler =
    (truefalse) => {
      setSideBarLeft_HauptTitel_Farbe_Groesse_Stil_OnMouseOverAndOut(truefalse);
    };

  const [
    SideBarLeft_HauptTitel_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut,
    setSideBarLeft_HauptTitel_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut,
  ] = useState(false);

  const setSideBarLeft_HauptTitel_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut_Handler =
    (truefalse) => {
      setSideBarLeft_HauptTitel_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut(
        truefalse
      );
    };

  const [
    SideBarLeft_HauptTitel_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut,
    setSideBarLeft_HauptTitel_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut,
  ] = useState(false);

  const setSideBarLeft_HauptTitel_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut_Handler =
    (truefalse) => {
      setSideBarLeft_HauptTitel_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut(
        truefalse
      );
    };

  const [
    SideBarLeft_HauptTitel_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut,
    setSideBarLeft_HauptTitel_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut,
  ] = useState(false);

  const setSideBarLeft_HauptTitel_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut_Handler =
    (truefalse) => {
      setSideBarLeft_HauptTitel_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut(
        truefalse
      );
    };

  const [
    SideBarLeft_HauptTitel_Farbe_Groesse_Stil_Trigger,
    setSideBarLeft_HauptTitel_Farbe_Groesse_Stil_Trigger,
  ] = useState(0);

  const setSideBarLeft_HauptTitel_Farbe_Groesse_Stil_Handler = () => {
    setSideBarLeft_HauptTitel_Farbe_Groesse_Stil_Trigger((prev) => prev + 1);
  };

  const [
    SideBarLeft_HauptTitel_Farbe_Groesse_Stil_Hover_Trigger,
    setSideBarLeft_HauptTitel_Farbe_Groesse_Stil_Hover_Trigger,
  ] = useState(0);

  const setSideBarLeft_HauptTitel_Farbe_Groesse_Stil_Hover_Handler = () => {
    setSideBarLeft_HauptTitel_Farbe_Groesse_Stil_Hover_Trigger(
      (prev) => prev + 1
    );
  };

  const [
    SideBarLeft_HauptTitel_HintergrundFarbe_Radius_Stil_Trigger,
    setSideBarLeft_HauptTitel_HintergrundFarbe_Radius_Stil_Trigger,
  ] = useState(0);

  const setSideBarLeft_HauptTitel_HintergrundFarbe_Radius_Stil_Handler = () => {
    setSideBarLeft_HauptTitel_HintergrundFarbe_Radius_Stil_Trigger(
      (prev) => prev + 1
    );
  };

  const [
    SideBarLeft_HauptTitel_HintergrundFarbe_Radius_Stil_Hover_Trigger,
    setSideBarLeft_HauptTitel_HintergrundFarbe_Radius_Stil_Hover_Trigger,
  ] = useState(0);

  const setSideBarLeft_HauptTitel_HintergrundFarbe_Radius_Stil_Hover_Handler =
    () => {
      setSideBarLeft_HauptTitel_HintergrundFarbe_Radius_Stil_Hover_Trigger(
        (prev) => prev + 1
      );
    };

  /////////////////////////////// HauptTitel //////////////////////////////////////////////////

  const [
    DB_LeftSidebar_MenuItem_HauptTitel_Hover_FontSize,
    setDB_LeftSidebar_MenuItem_HauptTitel_Hover_FontSize,
  ] = useState(20);
  const DB_LeftSidebar_MenuItem_HauptTitel_Hover_FontFace =
    DB_fontFace_FontFamilyName;
  const [
    DB_LeftSidebar_MenuItem_HauptTitel_Hover_FontWeight,
    setDB_LeftSidebar_MenuItem_HauptTitel_Hover_FontWeight,
  ] = useState(400);
  const [
    DB_LeftSidebar_MenuItem_HauptTitel_Hover_FontColor,
    setDB_LeftSidebar_MenuItem_HauptTitel_Hover_FontColor,
  ] = useState("rgba(255,255,255,1)");

  const [
    DB_LeftSidebar_MenuItem_HauptTitel_Hover_FontColorNotEncoded,
    setDB_LeftSidebar_MenuItem_HauptTitel_Hover_FontColorNotEncoded,
  ] = useState("rgba(0,0,0,0)");

  const [
    DB_LeftSidebar_MenuItem_HauptTitel_Hover_BackgroundColor,
    setDB_LeftSidebar_MenuItem_HauptTitel_Hover_BackgroundColor,
  ] = useState("rgba(0,0,0,0)");
  const [
    DB_LeftSidebar_MenuItem_HauptTitel_Hover_BackgroundColorNotEncoded,
    setDB_LeftSidebar_MenuItem_HauptTitel_Hover_BackgroundColorNotEncoded,
  ] = useState("rgba(0,0,0,0)");

  const [
    DB_LeftSidebar_MenuItem_HauptTitel_Hover_TextAlign,
    setDB_LeftSidebar_MenuItem_HauptTitel_Hover_TextAlign,
  ] = useState("left");

  /////////////////////////////////
  const [
    DB_LeftSidebar_MenuItem_HauptTitel_Hover_BorderTopRightRadius,
    setDB_LeftSidebar_MenuItem_HauptTitel_Hover_BorderTopRightRadius,
  ] = useState(0);
  const [
    DB_LeftSidebar_MenuItem_HauptTitel_Hover_BorderTopLeftRadius,
    setDB_LeftSidebar_MenuItem_HauptTitel_Hover_BorderTopLeftRadius,
  ] = useState(0);
  const [
    DB_LeftSidebar_MenuItem_HauptTitel_Hover_BorderBottomRightRadius,
    setDB_LeftSidebar_MenuItem_HauptTitel_Hover_BorderBottomRightRadius,
  ] = useState(0);
  const [
    DB_LeftSidebar_MenuItem_HauptTitel_Hover_BorderBottomLeftRadius,
    setDB_LeftSidebar_MenuItem_HauptTitel_Hover_BorderBottomLeftRadius,
  ] = useState(0);

  const [
    DB_LeftSidebar_MenuItem_HauptTitel_Hover_BorderColorTop,
    setDB_LeftSidebar_MenuItem_HauptTitel_Hover_BorderColorTop,
  ] = useState("rgba(255,255,255,1)");
  const [
    DB_LeftSidebar_MenuItem_HauptTitel_Hover_BorderColorBottom,
    setDB_LeftSidebar_MenuItem_HauptTitel_Hover_BorderColorBottom,
  ] = useState("rgba(255,255,255,1)");
  const [
    DB_LeftSidebar_MenuItem_HauptTitel_Hover_BorderColorLeft,
    setDB_LeftSidebar_MenuItem_HauptTitel_Hover_BorderColorLeft,
  ] = useState("rgba(255,255,255,1)");
  const [
    DB_LeftSidebar_MenuItem_HauptTitel_Hover_BorderColorRight,
    setDB_LeftSidebar_MenuItem_HauptTitel_Hover_BorderColorRight,
  ] = useState("rgba(255,255,255,1)");

  const [
    DB_LeftSidebar_MenuItem_HauptTitel_Hover_BorderColorTopNotEncoded,
    setDB_LeftSidebar_MenuItem_HauptTitel_Hover_BorderColorTopNotEncoded,
  ] = useState("rgba(255,255,255,1)");
  const [
    DB_LeftSidebar_MenuItem_HauptTitel_Hover_BorderColorBottomNotEncoded,
    setDB_LeftSidebar_MenuItem_HauptTitel_Hover_BorderColorBottomNotEncoded,
  ] = useState("rgba(255,255,255,1)");
  const [
    DB_LeftSidebar_MenuItem_HauptTitel_Hover_BorderColorLeftNotEncoded,
    setDB_LeftSidebar_MenuItem_HauptTitel_Hover_BorderColorLeftNotEncoded,
  ] = useState("rgba(255,255,255,1)");
  const [
    DB_LeftSidebar_MenuItem_HauptTitel_Hover_BorderColorRightNotEncoded,
    setDB_LeftSidebar_MenuItem_HauptTitel_Hover_BorderColorRightNotEncoded,
  ] = useState("rgba(255,255,255,1)");

  const [
    DB_LeftSidebar_MenuItem_HauptTitel_Hover_BorderTop,
    setDB_LeftSidebar_MenuItem_HauptTitel_Hover_BorderTop,
  ] = useState(0);
  const [
    DB_LeftSidebar_MenuItem_HauptTitel_Hover_BorderBottom,
    setDB_LeftSidebar_MenuItem_HauptTitel_Hover_BorderBottom,
  ] = useState(0);
  const [
    DB_LeftSidebar_MenuItem_HauptTitel_Hover_BorderLeft,
    setDB_LeftSidebar_MenuItem_HauptTitel_Hover_BorderLeft,
  ] = useState(0);
  const [
    DB_LeftSidebar_MenuItem_HauptTitel_Hover_BorderRight,
    setDB_LeftSidebar_MenuItem_HauptTitel_Hover_BorderRight,
  ] = useState(0);

  const [
    DB_LeftSidebar_MenuItem_HauptTitel_Hover_BorderStyleTop,
    setDB_LeftSidebar_MenuItem_HauptTitel_Hover_BorderStyleTop,
  ] = useState("solid");
  const [
    DB_LeftSidebar_MenuItem_HauptTitel_Hover_BorderStyleBottom,
    setDB_LeftSidebar_MenuItem_HauptTitel_Hover_BorderStyleBottom,
  ] = useState("solid");
  const [
    DB_LeftSidebar_MenuItem_HauptTitel_Hover_BorderStyleLeft,
    setDB_LeftSidebar_MenuItem_HauptTitel_Hover_BorderStyleLeft,
  ] = useState("solid");
  const [
    DB_LeftSidebar_MenuItem_HauptTitel_Hover_BorderStyleRight,
    setDB_LeftSidebar_MenuItem_HauptTitel_Hover_BorderStyleRight,
  ] = useState("solid");

  const DB_LeftSidebar_MenuItem_HauptTitel_Hover_HoverBackgroundColor =
    "rgba(255,255,255,0)";
  const DB_LeftSidebar_MenuItem_HauptTitel_Hover_HoverFontColor =
    "rgba(255,255,255,1)";

  const [
    showConfig_LeftSidebar_MenuMainTitel_Hover,
    setShowConfig_LeftSidebar_MenuMainTitel_Hover,
  ] = useState(false);

  const [
    showConfig_LeftSidebar_MenuMainTitel_Hover_BorderAndColor,
    setShowConfig_LeftSidebar_MenuMainTitel_Hover_BorderAndColor,
  ] = useState(false);

  // DB_LeftSidebar - HauptTitel Normal .-----------------.---------------------.----------------------
  const [
    DB_LeftSidebar_MenuItem_HauptTitel_FontSize,
    setDB_LeftSidebar_MenuItem_HauptTitel_FontSize,
  ] = useState(20);
  const DB_LeftSidebar_MenuItem_HauptTitel_FontFace =
    DB_fontFace_FontFamilyName;
  const [
    DB_LeftSidebar_MenuItem_HauptTitel_FontWeight,
    setDB_LeftSidebar_MenuItem_HauptTitel_FontWeight,
  ] = useState(400);
  const [
    DB_LeftSidebar_MenuItem_HauptTitel_FontColor,
    setDB_LeftSidebar_MenuItem_HauptTitel_FontColor,
  ] = useState("rgba(255,255,255,1)");

  const [
    DB_LeftSidebar_MenuItem_HauptTitel_FontColorNotEncoded,
    setDB_LeftSidebar_MenuItem_HauptTitel_FontColorNotEncoded,
  ] = useState("rgba(0,0,0,0)");

  const [
    DB_LeftSidebar_MenuItem_HauptTitel_BackgroundColor,
    setDB_LeftSidebar_MenuItem_HauptTitel_BackgroundColor,
  ] = useState("rgba(0,0,0,0)");
  const [
    DB_LeftSidebar_MenuItem_HauptTitel_BackgroundColorNotEncoded,
    setDB_LeftSidebar_MenuItem_HauptTitel_BackgroundColorNotEncoded,
  ] = useState("rgba(0,0,0,0)");

  const [
    DB_LeftSidebar_MenuItem_HauptTitel_TextAlign,
    setDB_LeftSidebar_MenuItem_HauptTitel_TextAlign,
  ] = useState("left");

  /////////////////////////////////
  const [
    DB_LeftSidebar_MenuItem_HauptTitel_BorderTopRightRadius,
    setDB_LeftSidebar_MenuItem_HauptTitel_BorderTopRightRadius,
  ] = useState(0);
  const [
    DB_LeftSidebar_MenuItem_HauptTitel_BorderTopLeftRadius,
    setDB_LeftSidebar_MenuItem_HauptTitel_BorderTopLeftRadius,
  ] = useState(0);
  const [
    DB_LeftSidebar_MenuItem_HauptTitel_BorderBottomRightRadius,
    setDB_LeftSidebar_MenuItem_HauptTitel_BorderBottomRightRadius,
  ] = useState(0);
  const [
    DB_LeftSidebar_MenuItem_HauptTitel_BorderBottomLeftRadius,
    setDB_LeftSidebar_MenuItem_HauptTitel_BorderBottomLeftRadius,
  ] = useState(0);

  const [
    DB_LeftSidebar_MenuItem_HauptTitel_BorderColorTop,
    setDB_LeftSidebar_MenuItem_HauptTitel_BorderColorTop,
  ] = useState("rgba(255,255,255,1)");
  const [
    DB_LeftSidebar_MenuItem_HauptTitel_BorderColorBottom,
    setDB_LeftSidebar_MenuItem_HauptTitel_BorderColorBottom,
  ] = useState("rgba(255,255,255,1)");
  const [
    DB_LeftSidebar_MenuItem_HauptTitel_BorderColorLeft,
    setDB_LeftSidebar_MenuItem_HauptTitel_BorderColorLeft,
  ] = useState("rgba(255,255,255,1)");
  const [
    DB_LeftSidebar_MenuItem_HauptTitel_BorderColorRight,
    setDB_LeftSidebar_MenuItem_HauptTitel_BorderColorRight,
  ] = useState("rgba(255,255,255,1)");

  const [
    DB_LeftSidebar_MenuItem_HauptTitel_BorderColorTopNotEncoded,
    setDB_LeftSidebar_MenuItem_HauptTitel_BorderColorTopNotEncoded,
  ] = useState("rgba(255,255,255,1)");
  const [
    DB_LeftSidebar_MenuItem_HauptTitel_BorderColorBottomNotEncoded,
    setDB_LeftSidebar_MenuItem_HauptTitel_BorderColorBottomNotEncoded,
  ] = useState("rgba(255,255,255,1)");
  const [
    DB_LeftSidebar_MenuItem_HauptTitel_BorderColorLeftNotEncoded,
    setDB_LeftSidebar_MenuItem_HauptTitel_BorderColorLeftNotEncoded,
  ] = useState("rgba(255,255,255,1)");
  const [
    DB_LeftSidebar_MenuItem_HauptTitel_BorderColorRightNotEncoded,
    setDB_LeftSidebar_MenuItem_HauptTitel_BorderColorRightNotEncoded,
  ] = useState("rgba(255,255,255,1)");

  const [
    DB_LeftSidebar_MenuItem_HauptTitel_BorderTop,
    setDB_LeftSidebar_MenuItem_HauptTitel_BorderTop,
  ] = useState(0);
  const [
    DB_LeftSidebar_MenuItem_HauptTitel_BorderBottom,
    setDB_LeftSidebar_MenuItem_HauptTitel_BorderBottom,
  ] = useState(0);
  const [
    DB_LeftSidebar_MenuItem_HauptTitel_BorderLeft,
    setDB_LeftSidebar_MenuItem_HauptTitel_BorderLeft,
  ] = useState(0);
  const [
    DB_LeftSidebar_MenuItem_HauptTitel_BorderRight,
    setDB_LeftSidebar_MenuItem_HauptTitel_BorderRight,
  ] = useState(0);

  const [
    DB_LeftSidebar_MenuItem_HauptTitel_BorderStyleTop,
    setDB_LeftSidebar_MenuItem_HauptTitel_BorderStyleTop,
  ] = useState("solid");
  const [
    DB_LeftSidebar_MenuItem_HauptTitel_BorderStyleBottom,
    setDB_LeftSidebar_MenuItem_HauptTitel_BorderStyleBottom,
  ] = useState("solid");
  const [
    DB_LeftSidebar_MenuItem_HauptTitel_BorderStyleLeft,
    setDB_LeftSidebar_MenuItem_HauptTitel_BorderStyleLeft,
  ] = useState("solid");
  const [
    DB_LeftSidebar_MenuItem_HauptTitel_BorderStyleRight,
    setDB_LeftSidebar_MenuItem_HauptTitel_BorderStyleRight,
  ] = useState("solid");

  const DB_LeftSidebar_MenuItem_HauptTitel_HoverBackgroundColor =
    "rgba(255,255,255,0)";
  const DB_LeftSidebar_MenuItem_HauptTitel_HoverFontColor =
    "rgba(255,255,255,1)";

  const [
    DB_LeftSidebar_MenuItem_HauptTitel_marginTopBottom,
    setDB_LeftSidebar_MenuItem_HauptTitel_marginTopBottom,
  ] = useState("0");
  const [
    DB_LeftSidebar_MenuItem_HauptTitel_marginLeftRight,
    setDB_LeftSidebar_MenuItem_HauptTitel_marginLeftRight,
  ] = useState("5");

  const [
    DB_LeftSidebar_MenuItem_HauptTitel_paddingLeftRight,
    setDB_LeftSidebar_MenuItem_HauptTitel_paddingLeftRight,
  ] = useState("5");

  const [
    DB_LeftSidebar_MenuItem_HauptTitel_paddingTopBottom,
    setDB_LeftSidebar_MenuItem_HauptTitel_paddingTopBottom,
  ] = useState("0");

  const [
    DB_LeftSidebar_MenuItem_HauptTitel_letterSpacing,
    setDB_LeftSidebar_MenuItem_HauptTitel_letterSpacing,
  ] = useState("0");

  const [
    DB_LeftSidebar_MenuItem_HauptTitel_marginTopBottom_Hover,
    setDB_LeftSidebar_MenuItem_HauptTitel_marginTopBottom_Hover,
  ] = useState("0");
  const [
    DB_LeftSidebar_MenuItem_HauptTitel_marginLeftRight_Hover,
    setDB_LeftSidebar_MenuItem_HauptTitel_marginLeftRight_Hover,
  ] = useState("5");

  const [
    DB_LeftSidebar_MenuItem_HauptTitel_paddingLeftRight_Hover,
    setDB_LeftSidebar_MenuItem_HauptTitel_paddingLeftRight_Hover,
  ] = useState("5");

  const [
    DB_LeftSidebar_MenuItem_HauptTitel_paddingTopBottom_Hover,
    setDB_LeftSidebar_MenuItem_HauptTitel_paddingTopBottom_Hover,
  ] = useState("0");

  const [
    DB_LeftSidebar_MenuItem_HauptTitel_letterSpacing_Hover,
    setDB_LeftSidebar_MenuItem_HauptTitel_letterSpacing_Hover,
  ] = useState("0");

  const [
    DB_LeftSidebar_MenuItem_HauptTitel_ShadowHorizontal,
    setDB_LeftSidebar_MenuItem_HauptTitel_ShadowHorizontal,
  ] = useState(1);
  const [
    DB_LeftSidebar_MenuItem_HauptTitel_ShadowVertical,
    setDB_LeftSidebar_MenuItem_HauptTitel_ShadowVertical,
  ] = useState(1);
  const [
    DB_LeftSidebar_MenuItem_HauptTitel_ShadowBlur,
    setDB_LeftSidebar_MenuItem_HauptTitel_ShadowBlur,
  ] = useState(5);
  const [
    DB_LeftSidebar_MenuItem_HauptTitel_ShadowColor,
    setDB_LeftSidebar_MenuItem_HauptTitel_ShadowColor,
  ] = useState("rgba(255,255,255,1)");

  const [
    DB_LeftSidebar_MenuItem_HauptTitel_ShadowColorNotEncoded,
    setDB_LeftSidebar_MenuItem_HauptTitel_ShadowColorNotEncoded,
  ] = useState("rgba(255,255,255,1)");

  const [
    DB_LeftSidebar_MenuItem_HauptTitel_TextDecoration,
    setDB_LeftSidebar_MenuItem_HauptTitel_TextDecoration,
  ] = useState("normal");

  const [
    DB_LeftSidebar_MenuItem_HauptTitel_TextDecorationStyle,
    setDB_LeftSidebar_MenuItem_HauptTitel_TextDecorationStyle,
  ] = useState("");

  const [
    DB_LeftSidebar_MenuItem_HauptTitel_ShadowHorizontal_Hover,
    setDB_LeftSidebar_MenuItem_HauptTitel_ShadowHorizontal_Hover,
  ] = useState(1);
  const [
    DB_LeftSidebar_MenuItem_HauptTitel_ShadowVertical_Hover,
    setDB_LeftSidebar_MenuItem_HauptTitel_ShadowVertical_Hover,
  ] = useState(1);
  const [
    DB_LeftSidebar_MenuItem_HauptTitel_ShadowBlur_Hover,
    setDB_LeftSidebar_MenuItem_HauptTitel_ShadowBlur_Hover,
  ] = useState(5);
  const [
    DB_LeftSidebar_MenuItem_HauptTitel_ShadowColor_Hover,
    setDB_LeftSidebar_MenuItem_HauptTitel_ShadowColor_Hover,
  ] = useState("rgba(255,255,255,1)");
  const [
    DB_LeftSidebar_MenuItem_HauptTitel_TextDecoration_Hover,
    setDB_LeftSidebar_MenuItem_HauptTitel_TextDecoration_Hover,
  ] = useState("none");
  const [
    DB_LeftSidebar_MenuItem_HauptTitel_TextDecorationStyle_Hover,
    setDB_LeftSidebar_MenuItem_HauptTitel_TextDecorationStyle_Hover,
  ] = useState("");

  const [
    DB_LeftSidebar_MenuItem_HauptTitel_ShadowColorNotEncoded_Hover,
    setDB_LeftSidebar_MenuItem_HauptTitel_ShadowColorNotEncoded_Hover,
  ] = useState("rgba(255,255,255,1)");

  const [
    showConfig_LeftSidebar_MenuMainUnterTitel,
    setShowConfig_LeftSidebar_MenuMainUnterTitel,
  ] = useState(false);

  /////////////////////////////// UnterTitel //////////////////////////////////////////////////

  /////////////////////////////// UnterTitel //////////////////////////////////////////////////

  const [
    DB_LeftSidebar_MenuItem_UnterTitel_Hover_FontSize,
    setDB_LeftSidebar_MenuItem_UnterTitel_Hover_FontSize,
  ] = useState(14);
  const DB_LeftSidebar_MenuItem_UnterTitel_Hover_FontFace =
    DB_fontFace_FontFamilyName;
  const [
    DB_LeftSidebar_MenuItem_UnterTitel_Hover_FontWeight,
    setDB_LeftSidebar_MenuItem_UnterTitel_Hover_FontWeight,
  ] = useState(400);
  const [
    DB_LeftSidebar_MenuItem_UnterTitel_Hover_FontColor,
    setDB_LeftSidebar_MenuItem_UnterTitel_Hover_FontColor,
  ] = useState("rgba(255,255,255,1)");

  const [
    DB_LeftSidebar_MenuItem_UnterTitel_Hover_FontColorNotEncoded,
    setDB_LeftSidebar_MenuItem_UnterTitel_Hover_FontColorNotEncoded,
  ] = useState("rgba(0,0,0,0)");

  const [
    DB_LeftSidebar_MenuItem_UnterTitel_Hover_BackgroundColor,
    setDB_LeftSidebar_MenuItem_UnterTitel_Hover_BackgroundColor,
  ] = useState("rgba(0,0,0,0)");
  const [
    DB_LeftSidebar_MenuItem_UnterTitel_Hover_BackgroundColorNotEncoded,
    setDB_LeftSidebar_MenuItem_UnterTitel_Hover_BackgroundColorNotEncoded,
  ] = useState("rgba(0,0,0,0)");

  const [
    DB_LeftSidebar_MenuItem_UnterTitel_Hover_TextAlign,
    setDB_LeftSidebar_MenuItem_UnterTitel_Hover_TextAlign,
  ] = useState("left");

  /////////////////////////////////
  const [
    DB_LeftSidebar_MenuItem_UnterTitel_Hover_BorderTopRightRadius,
    setDB_LeftSidebar_MenuItem_UnterTitel_Hover_BorderTopRightRadius,
  ] = useState(0);
  const [
    DB_LeftSidebar_MenuItem_UnterTitel_Hover_BorderTopLeftRadius,
    setDB_LeftSidebar_MenuItem_UnterTitel_Hover_BorderTopLeftRadius,
  ] = useState(0);
  const [
    DB_LeftSidebar_MenuItem_UnterTitel_Hover_BorderBottomRightRadius,
    setDB_LeftSidebar_MenuItem_UnterTitel_Hover_BorderBottomRightRadius,
  ] = useState(0);
  const [
    DB_LeftSidebar_MenuItem_UnterTitel_Hover_BorderBottomLeftRadius,
    setDB_LeftSidebar_MenuItem_UnterTitel_Hover_BorderBottomLeftRadius,
  ] = useState(0);

  const [
    DB_LeftSidebar_MenuItem_UnterTitel_Hover_BorderColorTop,
    setDB_LeftSidebar_MenuItem_UnterTitel_Hover_BorderColorTop,
  ] = useState("rgba(255,255,255,1)");
  const [
    DB_LeftSidebar_MenuItem_UnterTitel_Hover_BorderColorBottom,
    setDB_LeftSidebar_MenuItem_UnterTitel_Hover_BorderColorBottom,
  ] = useState("rgba(255,255,255,1)");
  const [
    DB_LeftSidebar_MenuItem_UnterTitel_Hover_BorderColorLeft,
    setDB_LeftSidebar_MenuItem_UnterTitel_Hover_BorderColorLeft,
  ] = useState("rgba(255,255,255,1)");
  const [
    DB_LeftSidebar_MenuItem_UnterTitel_Hover_BorderColorRight,
    setDB_LeftSidebar_MenuItem_UnterTitel_Hover_BorderColorRight,
  ] = useState("rgba(255,255,255,1)");

  const [
    DB_LeftSidebar_MenuItem_UnterTitel_Hover_BorderColorTopNotEncoded,
    setDB_LeftSidebar_MenuItem_UnterTitel_Hover_BorderColorTopNotEncoded,
  ] = useState("rgba(255,255,255,1)");
  const [
    DB_LeftSidebar_MenuItem_UnterTitel_Hover_BorderColorBottomNotEncoded,
    setDB_LeftSidebar_MenuItem_UnterTitel_Hover_BorderColorBottomNotEncoded,
  ] = useState("rgba(255,255,255,1)");
  const [
    DB_LeftSidebar_MenuItem_UnterTitel_Hover_BorderColorLeftNotEncoded,
    setDB_LeftSidebar_MenuItem_UnterTitel_Hover_BorderColorLeftNotEncoded,
  ] = useState("rgba(255,255,255,1)");
  const [
    DB_LeftSidebar_MenuItem_UnterTitel_Hover_BorderColorRightNotEncoded,
    setDB_LeftSidebar_MenuItem_UnterTitel_Hover_BorderColorRightNotEncoded,
  ] = useState("rgba(255,255,255,1)");

  const [
    DB_LeftSidebar_MenuItem_UnterTitel_Hover_BorderTop,
    setDB_LeftSidebar_MenuItem_UnterTitel_Hover_BorderTop,
  ] = useState(0);
  const [
    DB_LeftSidebar_MenuItem_UnterTitel_Hover_BorderBottom,
    setDB_LeftSidebar_MenuItem_UnterTitel_Hover_BorderBottom,
  ] = useState(0);
  const [
    DB_LeftSidebar_MenuItem_UnterTitel_Hover_BorderLeft,
    setDB_LeftSidebar_MenuItem_UnterTitel_Hover_BorderLeft,
  ] = useState(0);
  const [
    DB_LeftSidebar_MenuItem_UnterTitel_Hover_BorderRight,
    setDB_LeftSidebar_MenuItem_UnterTitel_Hover_BorderRight,
  ] = useState(0);

  const [
    DB_LeftSidebar_MenuItem_UnterTitel_Hover_BorderStyleTop,
    setDB_LeftSidebar_MenuItem_UnterTitel_Hover_BorderStyleTop,
  ] = useState("solid");
  const [
    DB_LeftSidebar_MenuItem_UnterTitel_Hover_BorderStyleBottom,
    setDB_LeftSidebar_MenuItem_UnterTitel_Hover_BorderStyleBottom,
  ] = useState("solid");
  const [
    DB_LeftSidebar_MenuItem_UnterTitel_Hover_BorderStyleLeft,
    setDB_LeftSidebar_MenuItem_UnterTitel_Hover_BorderStyleLeft,
  ] = useState("solid");
  const [
    DB_LeftSidebar_MenuItem_UnterTitel_Hover_BorderStyleRight,
    setDB_LeftSidebar_MenuItem_UnterTitel_Hover_BorderStyleRight,
  ] = useState("solid");

  const DB_LeftSidebar_MenuItem_UnterTitel_Hover_HoverBackgroundColor =
    "rgba(255,255,255,0)";
  const DB_LeftSidebar_MenuItem_UnterTitel_Hover_HoverFontColor =
    "rgba(255,255,255,1)";

  // DB_LeftSidebar - UnterTitel Normal .-----------------.---------------------.----------------------
  const [
    DB_LeftSidebar_MenuItem_UnterTitel_FontSize,
    setDB_LeftSidebar_MenuItem_UnterTitel_FontSize,
  ] = useState(14);
  const DB_LeftSidebar_MenuItem_UnterTitel_FontFace =
    DB_fontFace_FontFamilyName;
  const [
    DB_LeftSidebar_MenuItem_UnterTitel_FontWeight,
    setDB_LeftSidebar_MenuItem_UnterTitel_FontWeight,
  ] = useState(400);
  const [
    DB_LeftSidebar_MenuItem_UnterTitel_FontColor,
    setDB_LeftSidebar_MenuItem_UnterTitel_FontColor,
  ] = useState("rgba(255,255,255,1)");

  const [
    DB_LeftSidebar_MenuItem_UnterTitel_FontColorNotEncoded,
    setDB_LeftSidebar_MenuItem_UnterTitel_FontColorNotEncoded,
  ] = useState("rgba(0,0,0,0)");

  const [
    DB_LeftSidebar_MenuItem_UnterTitel_BackgroundColor,
    setDB_LeftSidebar_MenuItem_UnterTitel_BackgroundColor,
  ] = useState("rgba(0,0,0,0)");
  const [
    DB_LeftSidebar_MenuItem_UnterTitel_BackgroundColorNotEncoded,
    setDB_LeftSidebar_MenuItem_UnterTitel_BackgroundColorNotEncoded,
  ] = useState("rgba(0,0,0,0)");

  const [
    DB_LeftSidebar_MenuItem_UnterTitel_TextAlign,
    setDB_LeftSidebar_MenuItem_UnterTitel_TextAlign,
  ] = useState("left");

  /////////////////////////////////
  const [
    DB_LeftSidebar_MenuItem_UnterTitel_BorderTopRightRadius,
    setDB_LeftSidebar_MenuItem_UnterTitel_BorderTopRightRadius,
  ] = useState(0);
  const [
    DB_LeftSidebar_MenuItem_UnterTitel_BorderTopLeftRadius,
    setDB_LeftSidebar_MenuItem_UnterTitel_BorderTopLeftRadius,
  ] = useState(0);
  const [
    DB_LeftSidebar_MenuItem_UnterTitel_BorderBottomRightRadius,
    setDB_LeftSidebar_MenuItem_UnterTitel_BorderBottomRightRadius,
  ] = useState(0);
  const [
    DB_LeftSidebar_MenuItem_UnterTitel_BorderBottomLeftRadius,
    setDB_LeftSidebar_MenuItem_UnterTitel_BorderBottomLeftRadius,
  ] = useState(0);

  const [
    DB_LeftSidebar_MenuItem_UnterTitel_BorderColorTop,
    setDB_LeftSidebar_MenuItem_UnterTitel_BorderColorTop,
  ] = useState("rgba(255,255,255,1)");
  const [
    DB_LeftSidebar_MenuItem_UnterTitel_BorderColorBottom,
    setDB_LeftSidebar_MenuItem_UnterTitel_BorderColorBottom,
  ] = useState("rgba(255,255,255,1)");
  const [
    DB_LeftSidebar_MenuItem_UnterTitel_BorderColorLeft,
    setDB_LeftSidebar_MenuItem_UnterTitel_BorderColorLeft,
  ] = useState("rgba(255,255,255,1)");
  const [
    DB_LeftSidebar_MenuItem_UnterTitel_BorderColorRight,
    setDB_LeftSidebar_MenuItem_UnterTitel_BorderColorRight,
  ] = useState("rgba(255,255,255,1)");

  const [
    DB_LeftSidebar_MenuItem_UnterTitel_BorderColorTopNotEncoded,
    setDB_LeftSidebar_MenuItem_UnterTitel_BorderColorTopNotEncoded,
  ] = useState("rgba(255,255,255,1)");
  const [
    DB_LeftSidebar_MenuItem_UnterTitel_BorderColorBottomNotEncoded,
    setDB_LeftSidebar_MenuItem_UnterTitel_BorderColorBottomNotEncoded,
  ] = useState("rgba(255,255,255,1)");
  const [
    DB_LeftSidebar_MenuItem_UnterTitel_BorderColorLeftNotEncoded,
    setDB_LeftSidebar_MenuItem_UnterTitel_BorderColorLeftNotEncoded,
  ] = useState("rgba(255,255,255,1)");
  const [
    DB_LeftSidebar_MenuItem_UnterTitel_BorderColorRightNotEncoded,
    setDB_LeftSidebar_MenuItem_UnterTitel_BorderColorRightNotEncoded,
  ] = useState("rgba(255,255,255,1)");

  const [
    DB_LeftSidebar_MenuItem_UnterTitel_BorderTop,
    setDB_LeftSidebar_MenuItem_UnterTitel_BorderTop,
  ] = useState(0);
  const [
    DB_LeftSidebar_MenuItem_UnterTitel_BorderBottom,
    setDB_LeftSidebar_MenuItem_UnterTitel_BorderBottom,
  ] = useState(0);
  const [
    DB_LeftSidebar_MenuItem_UnterTitel_BorderLeft,
    setDB_LeftSidebar_MenuItem_UnterTitel_BorderLeft,
  ] = useState(0);
  const [
    DB_LeftSidebar_MenuItem_UnterTitel_BorderRight,
    setDB_LeftSidebar_MenuItem_UnterTitel_BorderRight,
  ] = useState(0);

  const [
    DB_LeftSidebar_MenuItem_UnterTitel_BorderStyleTop,
    setDB_LeftSidebar_MenuItem_UnterTitel_BorderStyleTop,
  ] = useState("solid");
  const [
    DB_LeftSidebar_MenuItem_UnterTitel_BorderStyleBottom,
    setDB_LeftSidebar_MenuItem_UnterTitel_BorderStyleBottom,
  ] = useState("solid");
  const [
    DB_LeftSidebar_MenuItem_UnterTitel_BorderStyleLeft,
    setDB_LeftSidebar_MenuItem_UnterTitel_BorderStyleLeft,
  ] = useState("solid");
  const [
    DB_LeftSidebar_MenuItem_UnterTitel_BorderStyleRight,
    setDB_LeftSidebar_MenuItem_UnterTitel_BorderStyleRight,
  ] = useState("solid");

  const DB_LeftSidebar_MenuItem_UnterTitel_HoverBackgroundColor =
    "rgba(255,255,255,0)";
  const DB_LeftSidebar_MenuItem_UnterTitel_HoverFontColor =
    "rgba(255,255,255,1)";

  const [
    DB_LeftSidebar_MenuItem_UnterTitel_marginTopBottom,
    setDB_LeftSidebar_MenuItem_UnterTitel_marginTopBottom,
  ] = useState("0");
  const [
    DB_LeftSidebar_MenuItem_UnterTitel_marginLeftRight,
    setDB_LeftSidebar_MenuItem_UnterTitel_marginLeftRight,
  ] = useState("5");

  const [
    DB_LeftSidebar_MenuItem_UnterTitel_paddingLeftRight,
    setDB_LeftSidebar_MenuItem_UnterTitel_paddingLeftRight,
  ] = useState("5");

  const [
    DB_LeftSidebar_MenuItem_UnterTitel_paddingTopBottom,
    setDB_LeftSidebar_MenuItem_UnterTitel_paddingTopBottom,
  ] = useState("0");

  const [
    DB_LeftSidebar_MenuItem_UnterTitel_letterSpacing,
    setDB_LeftSidebar_MenuItem_UnterTitel_letterSpacing,
  ] = useState("0");

  const [
    DB_LeftSidebar_MenuItem_UnterTitel_marginTopBottom_Hover,
    setDB_LeftSidebar_MenuItem_UnterTitel_marginTopBottom_Hover,
  ] = useState("0");
  const [
    DB_LeftSidebar_MenuItem_UnterTitel_marginLeftRight_Hover,
    setDB_LeftSidebar_MenuItem_UnterTitel_marginLeftRight_Hover,
  ] = useState("5");

  const [
    DB_LeftSidebar_MenuItem_UnterTitel_paddingLeftRight_Hover,
    setDB_LeftSidebar_MenuItem_UnterTitel_paddingLeftRight_Hover,
  ] = useState("5");

  const [
    DB_LeftSidebar_MenuItem_UnterTitel_paddingTopBottom_Hover,
    setDB_LeftSidebar_MenuItem_UnterTitel_paddingTopBottom_Hover,
  ] = useState("0");

  const [
    DB_LeftSidebar_MenuItem_UnterTitel_letterSpacing_Hover,
    setDB_LeftSidebar_MenuItem_UnterTitel_letterSpacing_Hover,
  ] = useState("0");

  const [
    DB_LeftSidebar_MenuItem_UnterTitel_ShadowHorizontal,
    setDB_LeftSidebar_MenuItem_UnterTitel_ShadowHorizontal,
  ] = useState(1);
  const [
    DB_LeftSidebar_MenuItem_UnterTitel_ShadowVertical,
    setDB_LeftSidebar_MenuItem_UnterTitel_ShadowVertical,
  ] = useState(1);
  const [
    DB_LeftSidebar_MenuItem_UnterTitel_ShadowBlur,
    setDB_LeftSidebar_MenuItem_UnterTitel_ShadowBlur,
  ] = useState(5);
  const [
    DB_LeftSidebar_MenuItem_UnterTitel_ShadowColor,
    setDB_LeftSidebar_MenuItem_UnterTitel_ShadowColor,
  ] = useState("rgba(255,255,255,1)");

  const [
    DB_LeftSidebar_MenuItem_UnterTitel_ShadowColorNotEncoded,
    setDB_LeftSidebar_MenuItem_UnterTitel_ShadowColorNotEncoded,
  ] = useState("rgba(255,255,255,1)");

  const [
    DB_LeftSidebar_MenuItem_UnterTitel_TextDecoration,
    setDB_LeftSidebar_MenuItem_UnterTitel_TextDecoration,
  ] = useState("normal");

  const [
    DB_LeftSidebar_MenuItem_UnterTitel_TextDecorationStyle,
    setDB_LeftSidebar_MenuItem_UnterTitel_TextDecorationStyle,
  ] = useState("");

  const [
    DB_LeftSidebar_MenuItem_UnterTitel_ShadowHorizontal_Hover,
    setDB_LeftSidebar_MenuItem_UnterTitel_ShadowHorizontal_Hover,
  ] = useState(1);
  const [
    DB_LeftSidebar_MenuItem_UnterTitel_ShadowVertical_Hover,
    setDB_LeftSidebar_MenuItem_UnterTitel_ShadowVertical_Hover,
  ] = useState(1);
  const [
    DB_LeftSidebar_MenuItem_UnterTitel_ShadowBlur_Hover,
    setDB_LeftSidebar_MenuItem_UnterTitel_ShadowBlur_Hover,
  ] = useState(5);
  const [
    DB_LeftSidebar_MenuItem_UnterTitel_ShadowColor_Hover,
    setDB_LeftSidebar_MenuItem_UnterTitel_ShadowColor_Hover,
  ] = useState("rgba(255,255,255,1)");
  const [
    DB_LeftSidebar_MenuItem_UnterTitel_TextDecoration_Hover,
    setDB_LeftSidebar_MenuItem_UnterTitel_TextDecoration_Hover,
  ] = useState("none");
  const [
    DB_LeftSidebar_MenuItem_UnterTitel_TextDecorationStyle_Hover,
    setDB_LeftSidebar_MenuItem_UnterTitel_TextDecorationStyle_Hover,
  ] = useState("");

  const [
    DB_LeftSidebar_MenuItem_UnterTitel_ShadowColorNotEncoded_Hover,
    setDB_LeftSidebar_MenuItem_UnterTitel_ShadowColorNotEncoded_Hover,
  ] = useState("rgba(255,255,255,1)");

  const [
    showConfig_LeftSidebar_MenuMainTitel,
    setShowConfig_LeftSidebar_MenuMainTitel,
  ] = useState(false);

  /////////////////////////////// Untertitel //////////////////////////////////////////////////

  /////////////////////////////// ProduktPreis //////////////////////////////////////////////////

  const [
    DB_LeftSidebar_MenuItem_ProduktPreis_Hover_FontSize,
    setDB_LeftSidebar_MenuItem_ProduktPreis_Hover_FontSize,
  ] = useState(22);
  const DB_LeftSidebar_MenuItem_ProduktPreis_Hover_FontFace =
    DB_fontFace_FontFamilyName;
  const [
    DB_LeftSidebar_MenuItem_ProduktPreis_Hover_FontWeight,
    setDB_LeftSidebar_MenuItem_ProduktPreis_Hover_FontWeight,
  ] = useState(400);
  const [
    DB_LeftSidebar_MenuItem_ProduktPreis_Hover_FontColor,
    setDB_LeftSidebar_MenuItem_ProduktPreis_Hover_FontColor,
  ] = useState("rgba(255,255,255,1)");

  const [
    DB_LeftSidebar_MenuItem_ProduktPreis_Hover_FontColorNotEncoded,
    setDB_LeftSidebar_MenuItem_ProduktPreis_Hover_FontColorNotEncoded,
  ] = useState("rgba(0,0,0,0)");

  const [
    DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BackgroundColor,
    setDB_LeftSidebar_MenuItem_ProduktPreis_Hover_BackgroundColor,
  ] = useState("rgba(0,0,0,0)");
  const [
    DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BackgroundColorNotEncoded,
    setDB_LeftSidebar_MenuItem_ProduktPreis_Hover_BackgroundColorNotEncoded,
  ] = useState("rgba(0,0,0,0)");

  const [
    DB_LeftSidebar_MenuItem_ProduktPreis_Hover_TextAlign,
    setDB_LeftSidebar_MenuItem_ProduktPreis_Hover_TextAlign,
  ] = useState("left");

  /////////////////////////////////
  const [
    DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderTopRightRadius,
    setDB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderTopRightRadius,
  ] = useState(0);
  const [
    DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderTopLeftRadius,
    setDB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderTopLeftRadius,
  ] = useState(0);
  const [
    DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderBottomRightRadius,
    setDB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderBottomRightRadius,
  ] = useState(0);
  const [
    DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderBottomLeftRadius,
    setDB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderBottomLeftRadius,
  ] = useState(0);

  const [
    DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderColorTop,
    setDB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderColorTop,
  ] = useState("rgba(255,255,255,1)");
  const [
    DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderColorBottom,
    setDB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderColorBottom,
  ] = useState("rgba(255,255,255,1)");
  const [
    DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderColorLeft,
    setDB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderColorLeft,
  ] = useState("rgba(255,255,255,1)");
  const [
    DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderColorRight,
    setDB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderColorRight,
  ] = useState("rgba(255,255,255,1)");

  const [
    DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderColorTopNotEncoded,
    setDB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderColorTopNotEncoded,
  ] = useState("rgba(255,255,255,1)");
  const [
    DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderColorBottomNotEncoded,
    setDB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderColorBottomNotEncoded,
  ] = useState("rgba(255,255,255,1)");
  const [
    DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderColorLeftNotEncoded,
    setDB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderColorLeftNotEncoded,
  ] = useState("rgba(255,255,255,1)");
  const [
    DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderColorRightNotEncoded,
    setDB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderColorRightNotEncoded,
  ] = useState("rgba(255,255,255,1)");

  const [
    DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderTop,
    setDB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderTop,
  ] = useState(0);
  const [
    DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderBottom,
    setDB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderBottom,
  ] = useState(0);
  const [
    DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderLeft,
    setDB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderLeft,
  ] = useState(0);
  const [
    DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderRight,
    setDB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderRight,
  ] = useState(0);

  const [
    DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderStyleTop,
    setDB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderStyleTop,
  ] = useState("solid");
  const [
    DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderStyleBottom,
    setDB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderStyleBottom,
  ] = useState("solid");
  const [
    DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderStyleLeft,
    setDB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderStyleLeft,
  ] = useState("solid");
  const [
    DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderStyleRight,
    setDB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderStyleRight,
  ] = useState("solid");

  const DB_LeftSidebar_MenuItem_ProduktPreis_Hover_HoverBackgroundColor =
    "rgba(255,255,255,0)";
  const DB_LeftSidebar_MenuItem_ProduktPreis_Hover_HoverFontColor =
    "rgba(255,255,255,1)";

  // DB_LeftSidebar - ProduktPreis Normal .-----------------.---------------------.----------------------
  const [
    DB_LeftSidebar_MenuItem_ProduktPreis_FontSize,
    setDB_LeftSidebar_MenuItem_ProduktPreis_FontSize,
  ] = useState(22);
  const DB_LeftSidebar_MenuItem_ProduktPreis_FontFace =
    DB_fontFace_FontFamilyName;
  const [
    DB_LeftSidebar_MenuItem_ProduktPreis_FontWeight,
    setDB_LeftSidebar_MenuItem_ProduktPreis_FontWeight,
  ] = useState(400);
  const [
    DB_LeftSidebar_MenuItem_ProduktPreis_FontColor,
    setDB_LeftSidebar_MenuItem_ProduktPreis_FontColor,
  ] = useState("rgba(255,255,255,1)");

  const [
    DB_LeftSidebar_MenuItem_ProduktPreis_FontColorNotEncoded,
    setDB_LeftSidebar_MenuItem_ProduktPreis_FontColorNotEncoded,
  ] = useState("rgba(0,0,0,0)");

  const [
    DB_LeftSidebar_MenuItem_ProduktPreis_BackgroundColor,
    setDB_LeftSidebar_MenuItem_ProduktPreis_BackgroundColor,
  ] = useState("rgba(0,0,0,0)");
  const [
    DB_LeftSidebar_MenuItem_ProduktPreis_BackgroundColorNotEncoded,
    setDB_LeftSidebar_MenuItem_ProduktPreis_BackgroundColorNotEncoded,
  ] = useState("rgba(0,0,0,0)");

  const [
    DB_LeftSidebar_MenuItem_ProduktPreis_TextAlign,
    setDB_LeftSidebar_MenuItem_ProduktPreis_TextAlign,
  ] = useState("left");

  /////////////////////////////////
  const [
    DB_LeftSidebar_MenuItem_ProduktPreis_BorderTopRightRadius,
    setDB_LeftSidebar_MenuItem_ProduktPreis_BorderTopRightRadius,
  ] = useState(0);
  const [
    DB_LeftSidebar_MenuItem_ProduktPreis_BorderTopLeftRadius,
    setDB_LeftSidebar_MenuItem_ProduktPreis_BorderTopLeftRadius,
  ] = useState(0);
  const [
    DB_LeftSidebar_MenuItem_ProduktPreis_BorderBottomRightRadius,
    setDB_LeftSidebar_MenuItem_ProduktPreis_BorderBottomRightRadius,
  ] = useState(0);
  const [
    DB_LeftSidebar_MenuItem_ProduktPreis_BorderBottomLeftRadius,
    setDB_LeftSidebar_MenuItem_ProduktPreis_BorderBottomLeftRadius,
  ] = useState(0);

  const [
    DB_LeftSidebar_MenuItem_ProduktPreis_BorderColorTop,
    setDB_LeftSidebar_MenuItem_ProduktPreis_BorderColorTop,
  ] = useState("rgba(255,255,255,1)");
  const [
    DB_LeftSidebar_MenuItem_ProduktPreis_BorderColorBottom,
    setDB_LeftSidebar_MenuItem_ProduktPreis_BorderColorBottom,
  ] = useState("rgba(255,255,255,1)");
  const [
    DB_LeftSidebar_MenuItem_ProduktPreis_BorderColorLeft,
    setDB_LeftSidebar_MenuItem_ProduktPreis_BorderColorLeft,
  ] = useState("rgba(255,255,255,1)");
  const [
    DB_LeftSidebar_MenuItem_ProduktPreis_BorderColorRight,
    setDB_LeftSidebar_MenuItem_ProduktPreis_BorderColorRight,
  ] = useState("rgba(255,255,255,1)");

  const [
    DB_LeftSidebar_MenuItem_ProduktPreis_BorderColorTopNotEncoded,
    setDB_LeftSidebar_MenuItem_ProduktPreis_BorderColorTopNotEncoded,
  ] = useState("rgba(255,255,255,1)");
  const [
    DB_LeftSidebar_MenuItem_ProduktPreis_BorderColorBottomNotEncoded,
    setDB_LeftSidebar_MenuItem_ProduktPreis_BorderColorBottomNotEncoded,
  ] = useState("rgba(255,255,255,1)");
  const [
    DB_LeftSidebar_MenuItem_ProduktPreis_BorderColorLeftNotEncoded,
    setDB_LeftSidebar_MenuItem_ProduktPreis_BorderColorLeftNotEncoded,
  ] = useState("rgba(255,255,255,1)");
  const [
    DB_LeftSidebar_MenuItem_ProduktPreis_BorderColorRightNotEncoded,
    setDB_LeftSidebar_MenuItem_ProduktPreis_BorderColorRightNotEncoded,
  ] = useState("rgba(255,255,255,1)");

  const [
    DB_LeftSidebar_MenuItem_ProduktPreis_BorderTop,
    setDB_LeftSidebar_MenuItem_ProduktPreis_BorderTop,
  ] = useState(0);
  const [
    DB_LeftSidebar_MenuItem_ProduktPreis_BorderBottom,
    setDB_LeftSidebar_MenuItem_ProduktPreis_BorderBottom,
  ] = useState(0);
  const [
    DB_LeftSidebar_MenuItem_ProduktPreis_BorderLeft,
    setDB_LeftSidebar_MenuItem_ProduktPreis_BorderLeft,
  ] = useState(0);
  const [
    DB_LeftSidebar_MenuItem_ProduktPreis_BorderRight,
    setDB_LeftSidebar_MenuItem_ProduktPreis_BorderRight,
  ] = useState(0);

  const [
    DB_LeftSidebar_MenuItem_ProduktPreis_BorderStyleTop,
    setDB_LeftSidebar_MenuItem_ProduktPreis_BorderStyleTop,
  ] = useState("solid");
  const [
    DB_LeftSidebar_MenuItem_ProduktPreis_BorderStyleBottom,
    setDB_LeftSidebar_MenuItem_ProduktPreis_BorderStyleBottom,
  ] = useState("solid");
  const [
    DB_LeftSidebar_MenuItem_ProduktPreis_BorderStyleLeft,
    setDB_LeftSidebar_MenuItem_ProduktPreis_BorderStyleLeft,
  ] = useState("solid");
  const [
    DB_LeftSidebar_MenuItem_ProduktPreis_BorderStyleRight,
    setDB_LeftSidebar_MenuItem_ProduktPreis_BorderStyleRight,
  ] = useState("solid");

  const DB_LeftSidebar_MenuItem_ProduktPreis_HoverBackgroundColor =
    "rgba(255,255,255,0)";
  const DB_LeftSidebar_MenuItem_ProduktPreis_HoverFontColor =
    "rgba(255,255,255,1)";

  const [
    DB_LeftSidebar_MenuItem_ProduktPreis_marginTopBottom,
    setDB_LeftSidebar_MenuItem_ProduktPreis_marginTopBottom,
  ] = useState("0");
  const [
    DB_LeftSidebar_MenuItem_ProduktPreis_marginLeftRight,
    setDB_LeftSidebar_MenuItem_ProduktPreis_marginLeftRight,
  ] = useState("4");

  const [
    DB_LeftSidebar_MenuItem_ProduktPreis_paddingLeftRight,
    setDB_LeftSidebar_MenuItem_ProduktPreis_paddingLeftRight,
  ] = useState("3");

  const [
    DB_LeftSidebar_MenuItem_ProduktPreis_paddingTopBottom,
    setDB_LeftSidebar_MenuItem_ProduktPreis_paddingTopBottom,
  ] = useState("0");

  const [
    DB_LeftSidebar_MenuItem_ProduktPreis_letterSpacing,
    setDB_LeftSidebar_MenuItem_ProduktPreis_letterSpacing,
  ] = useState("0");

  const [
    DB_LeftSidebar_MenuItem_ProduktPreis_marginTopBottom_Hover,
    setDB_LeftSidebar_MenuItem_ProduktPreis_marginTopBottom_Hover,
  ] = useState("0");
  const [
    DB_LeftSidebar_MenuItem_ProduktPreis_marginLeftRight_Hover,
    setDB_LeftSidebar_MenuItem_ProduktPreis_marginLeftRight_Hover,
  ] = useState("4");

  const [
    DB_LeftSidebar_MenuItem_ProduktPreis_paddingLeftRight_Hover,
    setDB_LeftSidebar_MenuItem_ProduktPreis_paddingLeftRight_Hover,
  ] = useState("3");

  const [
    DB_LeftSidebar_MenuItem_ProduktPreis_paddingTopBottom_Hover,
    setDB_LeftSidebar_MenuItem_ProduktPreis_paddingTopBottom_Hover,
  ] = useState("0");

  const [
    DB_LeftSidebar_MenuItem_ProduktPreis_letterSpacing_Hover,
    setDB_LeftSidebar_MenuItem_ProduktPreis_letterSpacing_Hover,
  ] = useState("0");

  const [
    DB_LeftSidebar_MenuItem_ProduktPreis_ShadowHorizontal,
    setDB_LeftSidebar_MenuItem_ProduktPreis_ShadowHorizontal,
  ] = useState(1);
  const [
    DB_LeftSidebar_MenuItem_ProduktPreis_ShadowVertical,
    setDB_LeftSidebar_MenuItem_ProduktPreis_ShadowVertical,
  ] = useState(1);
  const [
    DB_LeftSidebar_MenuItem_ProduktPreis_ShadowBlur,
    setDB_LeftSidebar_MenuItem_ProduktPreis_ShadowBlur,
  ] = useState(5);
  const [
    DB_LeftSidebar_MenuItem_ProduktPreis_ShadowColor,
    setDB_LeftSidebar_MenuItem_ProduktPreis_ShadowColor,
  ] = useState("rgba(255,255,255,1)");

  const [
    DB_LeftSidebar_MenuItem_ProduktPreis_ShadowColorNotEncoded,
    setDB_LeftSidebar_MenuItem_ProduktPreis_ShadowColorNotEncoded,
  ] = useState("rgba(255,255,255,1)");

  const [
    DB_LeftSidebar_MenuItem_ProduktPreis_TextDecoration,
    setDB_LeftSidebar_MenuItem_ProduktPreis_TextDecoration,
  ] = useState("normal");

  const [
    DB_LeftSidebar_MenuItem_ProduktPreis_TextDecorationStyle,
    setDB_LeftSidebar_MenuItem_ProduktPreis_TextDecorationStyle,
  ] = useState("");

  const [
    DB_LeftSidebar_MenuItem_ProduktPreis_ShadowHorizontal_Hover,
    setDB_LeftSidebar_MenuItem_ProduktPreis_ShadowHorizontal_Hover,
  ] = useState(1);
  const [
    DB_LeftSidebar_MenuItem_ProduktPreis_ShadowVertical_Hover,
    setDB_LeftSidebar_MenuItem_ProduktPreis_ShadowVertical_Hover,
  ] = useState(1);
  const [
    DB_LeftSidebar_MenuItem_ProduktPreis_ShadowBlur_Hover,
    setDB_LeftSidebar_MenuItem_ProduktPreis_ShadowBlur_Hover,
  ] = useState(5);
  const [
    DB_LeftSidebar_MenuItem_ProduktPreis_ShadowColor_Hover,
    setDB_LeftSidebar_MenuItem_ProduktPreis_ShadowColor_Hover,
  ] = useState("rgba(255,255,255,1)");
  const [
    DB_LeftSidebar_MenuItem_ProduktPreis_TextDecoration_Hover,
    setDB_LeftSidebar_MenuItem_ProduktPreis_TextDecoration_Hover,
  ] = useState("none");
  const [
    DB_LeftSidebar_MenuItem_ProduktPreis_TextDecorationStyle_Hover,
    setDB_LeftSidebar_MenuItem_ProduktPreis_TextDecorationStyle_Hover,
  ] = useState("");

  const [
    DB_LeftSidebar_MenuItem_ProduktPreis_ShadowColorNotEncoded_Hover,
    setDB_LeftSidebar_MenuItem_ProduktPreis_ShadowColorNotEncoded_Hover,
  ] = useState("rgba(255,255,255,1)");

  const [
    showConfig_LeftSidebar_Menu_ProduktPreis,
    setShowConfig_LeftSidebar_Menu_ProduktPreis,
  ] = useState(false);

  const [
    showConfig_ProductCard_ProduktPreis_BorderAndColor,
    setShowConfig_ProductCard_ProduktPreis_BorderAndColor,
  ] = useState(false);

  const [
    showConfig_ProductCard_ProduktPreis_Hover_BorderAndColor,
    setShowConfig_ProductCard_ProduktPreis_Hover_BorderAndColor,
  ] = useState(false);

  const [
    showConfig_ProductCard_ProduktPreis,
    setShowConfig_ProductCard_ProduktPreis,
  ] = useState(false);

  const [
    showConfig_ProductCard_ProduktPreis_Hover,
    setShowConfig_ProductCard_ProduktPreis_Hover,
  ] = useState(false);

  /////////////////////////////// ProduktPreis //////////////////////////////////////////////////

  /////////////////////////////// ProduktWaehrung //////////////////////////////////////////////////

  /////////////////////////////// ProduktWaehrung //////////////////////////////////////////////////

  const [
    DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_FontSize,
    setDB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_FontSize,
  ] = useState(16);
  const DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_FontFace =
    DB_fontFace_FontFamilyName;
  const [
    DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_FontWeight,
    setDB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_FontWeight,
  ] = useState(400);
  const [
    DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_FontColor,
    setDB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_FontColor,
  ] = useState("rgba(255,255,255,1)");

  const [
    DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_FontColorNotEncoded,
    setDB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_FontColorNotEncoded,
  ] = useState("rgba(0,0,0,0)");

  const [
    DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BackgroundColor,
    setDB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BackgroundColor,
  ] = useState("rgba(0,0,0,0)");
  const [
    DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BackgroundColorNotEncoded,
    setDB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BackgroundColorNotEncoded,
  ] = useState("rgba(0,0,0,0)");

  const [
    DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_TextAlign,
    setDB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_TextAlign,
  ] = useState("left");

  /////////////////////////////////
  const [
    DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderTopRightRadius,
    setDB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderTopRightRadius,
  ] = useState(0);
  const [
    DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderTopLeftRadius,
    setDB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderTopLeftRadius,
  ] = useState(0);
  const [
    DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderBottomRightRadius,
    setDB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderBottomRightRadius,
  ] = useState(0);
  const [
    DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderBottomLeftRadius,
    setDB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderBottomLeftRadius,
  ] = useState(0);

  const [
    DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderColorTop,
    setDB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderColorTop,
  ] = useState("rgba(255,255,255,1)");
  const [
    DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderColorBottom,
    setDB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderColorBottom,
  ] = useState("rgba(255,255,255,1)");
  const [
    DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderColorLeft,
    setDB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderColorLeft,
  ] = useState("rgba(255,255,255,1)");
  const [
    DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderColorRight,
    setDB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderColorRight,
  ] = useState("rgba(255,255,255,1)");

  const [
    DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderColorTopNotEncoded,
    setDB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderColorTopNotEncoded,
  ] = useState("rgba(255,255,255,1)");
  const [
    DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderColorBottomNotEncoded,
    setDB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderColorBottomNotEncoded,
  ] = useState("rgba(255,255,255,1)");
  const [
    DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderColorLeftNotEncoded,
    setDB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderColorLeftNotEncoded,
  ] = useState("rgba(255,255,255,1)");
  const [
    DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderColorRightNotEncoded,
    setDB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderColorRightNotEncoded,
  ] = useState("rgba(255,255,255,1)");

  const [
    DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderTop,
    setDB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderTop,
  ] = useState(0);
  const [
    DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderBottom,
    setDB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderBottom,
  ] = useState(0);
  const [
    DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderLeft,
    setDB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderLeft,
  ] = useState(0);
  const [
    DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderRight,
    setDB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderRight,
  ] = useState(0);

  const [
    DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderStyleTop,
    setDB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderStyleTop,
  ] = useState("solid");
  const [
    DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderStyleBottom,
    setDB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderStyleBottom,
  ] = useState("solid");
  const [
    DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderStyleLeft,
    setDB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderStyleLeft,
  ] = useState("solid");
  const [
    DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderStyleRight,
    setDB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderStyleRight,
  ] = useState("solid");

  const DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_HoverBackgroundColor =
    "rgba(255,255,255,0)";
  const DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_HoverFontColor =
    "rgba(255,255,255,1)";

  // DB_LeftSidebar - ProduktWaehrung Normal .-----------------.---------------------.----------------------
  const [
    DB_LeftSidebar_MenuItem_ProduktWaehrung_FontSize,
    setDB_LeftSidebar_MenuItem_ProduktWaehrung_FontSize,
  ] = useState(16);
  const DB_LeftSidebar_MenuItem_ProduktWaehrung_FontFace =
    DB_fontFace_FontFamilyName;
  const [
    DB_LeftSidebar_MenuItem_ProduktWaehrung_FontWeight,
    setDB_LeftSidebar_MenuItem_ProduktWaehrung_FontWeight,
  ] = useState(400);
  const [
    DB_LeftSidebar_MenuItem_ProduktWaehrung_FontColor,
    setDB_LeftSidebar_MenuItem_ProduktWaehrung_FontColor,
  ] = useState("rgba(255,255,255,1)");

  const [
    DB_LeftSidebar_MenuItem_ProduktWaehrung_FontColorNotEncoded,
    setDB_LeftSidebar_MenuItem_ProduktWaehrung_FontColorNotEncoded,
  ] = useState("rgba(0,0,0,0)");

  const [
    DB_LeftSidebar_MenuItem_ProduktWaehrung_BackgroundColor,
    setDB_LeftSidebar_MenuItem_ProduktWaehrung_BackgroundColor,
  ] = useState("rgba(0,0,0,0)");
  const [
    DB_LeftSidebar_MenuItem_ProduktWaehrung_BackgroundColorNotEncoded,
    setDB_LeftSidebar_MenuItem_ProduktWaehrung_BackgroundColorNotEncoded,
  ] = useState("rgba(0,0,0,0)");

  const [
    DB_LeftSidebar_MenuItem_ProduktWaehrung_TextAlign,
    setDB_LeftSidebar_MenuItem_ProduktWaehrung_TextAlign,
  ] = useState("left");

  /////////////////////////////////
  const [
    DB_LeftSidebar_MenuItem_ProduktWaehrung_BorderTopRightRadius,
    setDB_LeftSidebar_MenuItem_ProduktWaehrung_BorderTopRightRadius,
  ] = useState(0);
  const [
    DB_LeftSidebar_MenuItem_ProduktWaehrung_BorderTopLeftRadius,
    setDB_LeftSidebar_MenuItem_ProduktWaehrung_BorderTopLeftRadius,
  ] = useState(0);
  const [
    DB_LeftSidebar_MenuItem_ProduktWaehrung_BorderBottomRightRadius,
    setDB_LeftSidebar_MenuItem_ProduktWaehrung_BorderBottomRightRadius,
  ] = useState(0);
  const [
    DB_LeftSidebar_MenuItem_ProduktWaehrung_BorderBottomLeftRadius,
    setDB_LeftSidebar_MenuItem_ProduktWaehrung_BorderBottomLeftRadius,
  ] = useState(0);

  const [
    DB_LeftSidebar_MenuItem_ProduktWaehrung_BorderColorTop,
    setDB_LeftSidebar_MenuItem_ProduktWaehrung_BorderColorTop,
  ] = useState("rgba(255,255,255,1)");
  const [
    DB_LeftSidebar_MenuItem_ProduktWaehrung_BorderColorBottom,
    setDB_LeftSidebar_MenuItem_ProduktWaehrung_BorderColorBottom,
  ] = useState("rgba(255,255,255,1)");
  const [
    DB_LeftSidebar_MenuItem_ProduktWaehrung_BorderColorLeft,
    setDB_LeftSidebar_MenuItem_ProduktWaehrung_BorderColorLeft,
  ] = useState("rgba(255,255,255,1)");
  const [
    DB_LeftSidebar_MenuItem_ProduktWaehrung_BorderColorRight,
    setDB_LeftSidebar_MenuItem_ProduktWaehrung_BorderColorRight,
  ] = useState("rgba(255,255,255,1)");

  const [
    DB_LeftSidebar_MenuItem_ProduktWaehrung_BorderColorTopNotEncoded,
    setDB_LeftSidebar_MenuItem_ProduktWaehrung_BorderColorTopNotEncoded,
  ] = useState("rgba(255,255,255,1)");
  const [
    DB_LeftSidebar_MenuItem_ProduktWaehrung_BorderColorBottomNotEncoded,
    setDB_LeftSidebar_MenuItem_ProduktWaehrung_BorderColorBottomNotEncoded,
  ] = useState("rgba(255,255,255,1)");
  const [
    DB_LeftSidebar_MenuItem_ProduktWaehrung_BorderColorLeftNotEncoded,
    setDB_LeftSidebar_MenuItem_ProduktWaehrung_BorderColorLeftNotEncoded,
  ] = useState("rgba(255,255,255,1)");
  const [
    DB_LeftSidebar_MenuItem_ProduktWaehrung_BorderColorRightNotEncoded,
    setDB_LeftSidebar_MenuItem_ProduktWaehrung_BorderColorRightNotEncoded,
  ] = useState("rgba(255,255,255,1)");

  const [
    DB_LeftSidebar_MenuItem_ProduktWaehrung_BorderTop,
    setDB_LeftSidebar_MenuItem_ProduktWaehrung_BorderTop,
  ] = useState(0);
  const [
    DB_LeftSidebar_MenuItem_ProduktWaehrung_BorderBottom,
    setDB_LeftSidebar_MenuItem_ProduktWaehrung_BorderBottom,
  ] = useState(0);
  const [
    DB_LeftSidebar_MenuItem_ProduktWaehrung_BorderLeft,
    setDB_LeftSidebar_MenuItem_ProduktWaehrung_BorderLeft,
  ] = useState(0);
  const [
    DB_LeftSidebar_MenuItem_ProduktWaehrung_BorderRight,
    setDB_LeftSidebar_MenuItem_ProduktWaehrung_BorderRight,
  ] = useState(0);

  const [
    DB_LeftSidebar_MenuItem_ProduktWaehrung_BorderStyleTop,
    setDB_LeftSidebar_MenuItem_ProduktWaehrung_BorderStyleTop,
  ] = useState("solid");
  const [
    DB_LeftSidebar_MenuItem_ProduktWaehrung_BorderStyleBottom,
    setDB_LeftSidebar_MenuItem_ProduktWaehrung_BorderStyleBottom,
  ] = useState("solid");
  const [
    DB_LeftSidebar_MenuItem_ProduktWaehrung_BorderStyleLeft,
    setDB_LeftSidebar_MenuItem_ProduktWaehrung_BorderStyleLeft,
  ] = useState("solid");
  const [
    DB_LeftSidebar_MenuItem_ProduktWaehrung_BorderStyleRight,
    setDB_LeftSidebar_MenuItem_ProduktWaehrung_BorderStyleRight,
  ] = useState("solid");

  const DB_LeftSidebar_MenuItem_ProduktWaehrung_HoverBackgroundColor =
    "rgba(255,255,255,0)";
  const DB_LeftSidebar_MenuItem_ProduktWaehrung_HoverFontColor =
    "rgba(255,255,255,1)";

  const [
    DB_LeftSidebar_MenuItem_ProduktWaehrung_marginTopBottom,
    setDB_LeftSidebar_MenuItem_ProduktWaehrung_marginTopBottom,
  ] = useState("0");
  const [
    DB_LeftSidebar_MenuItem_ProduktWaehrung_marginLeftRight,
    setDB_LeftSidebar_MenuItem_ProduktWaehrung_marginLeftRight,
  ] = useState("-10");

  const [
    DB_LeftSidebar_MenuItem_ProduktWaehrung_paddingLeftRight,
    setDB_LeftSidebar_MenuItem_ProduktWaehrung_paddingLeftRight,
  ] = useState("5");

  const [
    DB_LeftSidebar_MenuItem_ProduktWaehrung_paddingTopBottom,
    setDB_LeftSidebar_MenuItem_ProduktWaehrung_paddingTopBottom,
  ] = useState("0");

  const [
    DB_LeftSidebar_MenuItem_ProduktWaehrung_letterSpacing,
    setDB_LeftSidebar_MenuItem_ProduktWaehrung_letterSpacing,
  ] = useState("0");

  const [
    DB_LeftSidebar_MenuItem_ProduktWaehrung_marginTopBottom_Hover,
    setDB_LeftSidebar_MenuItem_ProduktWaehrung_marginTopBottom_Hover,
  ] = useState("0");
  const [
    DB_LeftSidebar_MenuItem_ProduktWaehrung_marginLeftRight_Hover,
    setDB_LeftSidebar_MenuItem_ProduktWaehrung_marginLeftRight_Hover,
  ] = useState("0");

  const [
    DB_LeftSidebar_MenuItem_ProduktWaehrung_paddingLeftRight_Hover,
    setDB_LeftSidebar_MenuItem_ProduktWaehrung_paddingLeftRight_Hover,
  ] = useState("5");

  const [
    DB_LeftSidebar_MenuItem_ProduktWaehrung_paddingTopBottom_Hover,
    setDB_LeftSidebar_MenuItem_ProduktWaehrung_paddingTopBottom_Hover,
  ] = useState("0");

  const [
    DB_LeftSidebar_MenuItem_ProduktWaehrung_letterSpacing_Hover,
    setDB_LeftSidebar_MenuItem_ProduktWaehrung_letterSpacing_Hover,
  ] = useState("0");

  const [
    DB_LeftSidebar_MenuItem_ProduktWaehrung_ShadowHorizontal,
    setDB_LeftSidebar_MenuItem_ProduktWaehrung_ShadowHorizontal,
  ] = useState(1);
  const [
    DB_LeftSidebar_MenuItem_ProduktWaehrung_ShadowVertical,
    setDB_LeftSidebar_MenuItem_ProduktWaehrung_ShadowVertical,
  ] = useState(1);
  const [
    DB_LeftSidebar_MenuItem_ProduktWaehrung_ShadowBlur,
    setDB_LeftSidebar_MenuItem_ProduktWaehrung_ShadowBlur,
  ] = useState(5);
  const [
    DB_LeftSidebar_MenuItem_ProduktWaehrung_ShadowColor,
    setDB_LeftSidebar_MenuItem_ProduktWaehrung_ShadowColor,
  ] = useState("rgba(255,255,255,1)");

  const [
    DB_LeftSidebar_MenuItem_ProduktWaehrung_ShadowColorNotEncoded,
    setDB_LeftSidebar_MenuItem_ProduktWaehrung_ShadowColorNotEncoded,
  ] = useState("rgba(255,255,255,1)");

  const [
    DB_LeftSidebar_MenuItem_ProduktWaehrung_TextDecoration,
    setDB_LeftSidebar_MenuItem_ProduktWaehrung_TextDecoration,
  ] = useState("normal");

  const [
    DB_LeftSidebar_MenuItem_ProduktWaehrung_TextDecorationStyle,
    setDB_LeftSidebar_MenuItem_ProduktWaehrung_TextDecorationStyle,
  ] = useState("");

  const [
    DB_LeftSidebar_MenuItem_ProduktWaehrung_ShadowHorizontal_Hover,
    setDB_LeftSidebar_MenuItem_ProduktWaehrung_ShadowHorizontal_Hover,
  ] = useState(1);
  const [
    DB_LeftSidebar_MenuItem_ProduktWaehrung_ShadowVertical_Hover,
    setDB_LeftSidebar_MenuItem_ProduktWaehrung_ShadowVertical_Hover,
  ] = useState(1);
  const [
    DB_LeftSidebar_MenuItem_ProduktWaehrung_ShadowBlur_Hover,
    setDB_LeftSidebar_MenuItem_ProduktWaehrung_ShadowBlur_Hover,
  ] = useState(5);
  const [
    DB_LeftSidebar_MenuItem_ProduktWaehrung_ShadowColor_Hover,
    setDB_LeftSidebar_MenuItem_ProduktWaehrung_ShadowColor_Hover,
  ] = useState("rgba(255,255,255,1)");
  const [
    DB_LeftSidebar_MenuItem_ProduktWaehrung_TextDecoration_Hover,
    setDB_LeftSidebar_MenuItem_ProduktWaehrung_TextDecoration_Hover,
  ] = useState("none");
  const [
    DB_LeftSidebar_MenuItem_ProduktWaehrung_TextDecorationStyle_Hover,
    setDB_LeftSidebar_MenuItem_ProduktWaehrung_TextDecorationStyle_Hover,
  ] = useState("");

  const [
    DB_LeftSidebar_MenuItem_ProduktWaehrung_ShadowColorNotEncoded_Hover,
    setDB_LeftSidebar_MenuItem_ProduktWaehrung_ShadowColorNotEncoded_Hover,
  ] = useState("rgba(255,255,255,1)");

  /////////////////////////////// ProduktWaehrung //////////////////////////////////////////////////

  /////////////////////////////// BreadCrumbs //////////////////////////////////////////////////

  /////////////////////////////// BreadCrumbs //////////////////////////////////////////////////

  const [
    DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_FontSize,
    setDB_LeftSidebar_MenuItem_BreadCrumbs_Hover_FontSize,
  ] = useState(14);
  const DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_FontFace =
    DB_fontFace_FontFamilyName;
  const [
    DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_FontWeight,
    setDB_LeftSidebar_MenuItem_BreadCrumbs_Hover_FontWeight,
  ] = useState(400);
  const [
    DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_FontColor,
    setDB_LeftSidebar_MenuItem_BreadCrumbs_Hover_FontColor,
  ] = useState("rgba(255,255,255,1)");

  const [
    DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_FontColorNotEncoded,
    setDB_LeftSidebar_MenuItem_BreadCrumbs_Hover_FontColorNotEncoded,
  ] = useState("rgba(0,0,0,0)");

  const [
    DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BackgroundColor,
    setDB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BackgroundColor,
  ] = useState("rgba(0,0,0,0)");
  const [
    DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BackgroundColorNotEncoded,
    setDB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BackgroundColorNotEncoded,
  ] = useState("rgba(0,0,0,0)");

  const [
    DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_TextAlign,
    setDB_LeftSidebar_MenuItem_BreadCrumbs_Hover_TextAlign,
  ] = useState("left");

  /////////////////////////////////
  const [
    DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderTopRightRadius,
    setDB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderTopRightRadius,
  ] = useState(0);
  const [
    DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderTopLeftRadius,
    setDB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderTopLeftRadius,
  ] = useState(0);
  const [
    DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderBottomRightRadius,
    setDB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderBottomRightRadius,
  ] = useState(0);
  const [
    DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderBottomLeftRadius,
    setDB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderBottomLeftRadius,
  ] = useState(0);

  const [
    DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderColorTop,
    setDB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderColorTop,
  ] = useState("rgba(255,255,255,1)");
  const [
    DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderColorBottom,
    setDB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderColorBottom,
  ] = useState("rgba(255,255,255,1)");
  const [
    DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderColorLeft,
    setDB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderColorLeft,
  ] = useState("rgba(255,255,255,1)");
  const [
    DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderColorRight,
    setDB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderColorRight,
  ] = useState("rgba(255,255,255,1)");

  const [
    DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderColorTopNotEncoded,
    setDB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderColorTopNotEncoded,
  ] = useState("rgba(255,255,255,1)");
  const [
    DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderColorBottomNotEncoded,
    setDB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderColorBottomNotEncoded,
  ] = useState("rgba(255,255,255,1)");
  const [
    DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderColorLeftNotEncoded,
    setDB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderColorLeftNotEncoded,
  ] = useState("rgba(255,255,255,1)");
  const [
    DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderColorRightNotEncoded,
    setDB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderColorRightNotEncoded,
  ] = useState("rgba(255,255,255,1)");

  const [
    DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderTop,
    setDB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderTop,
  ] = useState(0);
  const [
    DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderBottom,
    setDB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderBottom,
  ] = useState(0);
  const [
    DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderLeft,
    setDB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderLeft,
  ] = useState(1);
  const [
    DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderRight,
    setDB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderRight,
  ] = useState(1);

  const [
    DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderStyleTop,
    setDB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderStyleTop,
  ] = useState("solid");
  const [
    DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderStyleBottom,
    setDB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderStyleBottom,
  ] = useState("solid");
  const [
    DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderStyleLeft,
    setDB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderStyleLeft,
  ] = useState("solid");
  const [
    DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderStyleRight,
    setDB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderStyleRight,
  ] = useState("solid");

  const DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_HoverBackgroundColor =
    "rgba(255,255,255,0)";
  const DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_HoverFontColor =
    "rgba(255,255,255,1)";

  // DB_LeftSidebar - BreadCrumbs Normal .-----------------.---------------------.----------------------
  const [
    DB_LeftSidebar_MenuItem_BreadCrumbs_FontSize,
    setDB_LeftSidebar_MenuItem_BreadCrumbs_FontSize,
  ] = useState(14);
  const DB_LeftSidebar_MenuItem_BreadCrumbs_FontFace =
    DB_fontFace_FontFamilyName;
  const [
    DB_LeftSidebar_MenuItem_BreadCrumbs_FontWeight,
    setDB_LeftSidebar_MenuItem_BreadCrumbs_FontWeight,
  ] = useState(400);
  const [
    DB_LeftSidebar_MenuItem_BreadCrumbs_FontColor,
    setDB_LeftSidebar_MenuItem_BreadCrumbs_FontColor,
  ] = useState("rgba(255,255,255,1)");

  const [
    DB_LeftSidebar_MenuItem_BreadCrumbs_FontColorNotEncoded,
    setDB_LeftSidebar_MenuItem_BreadCrumbs_FontColorNotEncoded,
  ] = useState("rgba(0,0,0,0)");

  const [
    DB_LeftSidebar_MenuItem_BreadCrumbs_BackgroundColor,
    setDB_LeftSidebar_MenuItem_BreadCrumbs_BackgroundColor,
  ] = useState("rgba(0,0,0,0)");
  const [
    DB_LeftSidebar_MenuItem_BreadCrumbs_BackgroundColorNotEncoded,
    setDB_LeftSidebar_MenuItem_BreadCrumbs_BackgroundColorNotEncoded,
  ] = useState("rgba(0,0,0,0)");

  const [
    DB_LeftSidebar_MenuItem_BreadCrumbs_TextAlign,
    setDB_LeftSidebar_MenuItem_BreadCrumbs_TextAlign,
  ] = useState("left");

  /////////////////////////////////
  const [
    DB_LeftSidebar_MenuItem_BreadCrumbs_BorderTopRightRadius,
    setDB_LeftSidebar_MenuItem_BreadCrumbs_BorderTopRightRadius,
  ] = useState(0);
  const [
    DB_LeftSidebar_MenuItem_BreadCrumbs_BorderTopLeftRadius,
    setDB_LeftSidebar_MenuItem_BreadCrumbs_BorderTopLeftRadius,
  ] = useState(0);
  const [
    DB_LeftSidebar_MenuItem_BreadCrumbs_BorderBottomRightRadius,
    setDB_LeftSidebar_MenuItem_BreadCrumbs_BorderBottomRightRadius,
  ] = useState(0);
  const [
    DB_LeftSidebar_MenuItem_BreadCrumbs_BorderBottomLeftRadius,
    setDB_LeftSidebar_MenuItem_BreadCrumbs_BorderBottomLeftRadius,
  ] = useState(0);

  const [
    DB_LeftSidebar_MenuItem_BreadCrumbs_BorderColorTop,
    setDB_LeftSidebar_MenuItem_BreadCrumbs_BorderColorTop,
  ] = useState("rgba(255,255,255,1)");
  const [
    DB_LeftSidebar_MenuItem_BreadCrumbs_BorderColorBottom,
    setDB_LeftSidebar_MenuItem_BreadCrumbs_BorderColorBottom,
  ] = useState("rgba(255,255,255,1)");
  const [
    DB_LeftSidebar_MenuItem_BreadCrumbs_BorderColorLeft,
    setDB_LeftSidebar_MenuItem_BreadCrumbs_BorderColorLeft,
  ] = useState("rgba(255,255,255,1)");
  const [
    DB_LeftSidebar_MenuItem_BreadCrumbs_BorderColorRight,
    setDB_LeftSidebar_MenuItem_BreadCrumbs_BorderColorRight,
  ] = useState("rgba(255,255,255,1)");

  const [
    DB_LeftSidebar_MenuItem_BreadCrumbs_BorderColorTopNotEncoded,
    setDB_LeftSidebar_MenuItem_BreadCrumbs_BorderColorTopNotEncoded,
  ] = useState("rgba(255,255,255,1)");
  const [
    DB_LeftSidebar_MenuItem_BreadCrumbs_BorderColorBottomNotEncoded,
    setDB_LeftSidebar_MenuItem_BreadCrumbs_BorderColorBottomNotEncoded,
  ] = useState("rgba(255,255,255,1)");
  const [
    DB_LeftSidebar_MenuItem_BreadCrumbs_BorderColorLeftNotEncoded,
    setDB_LeftSidebar_MenuItem_BreadCrumbs_BorderColorLeftNotEncoded,
  ] = useState("rgba(255,255,255,1)");
  const [
    DB_LeftSidebar_MenuItem_BreadCrumbs_BorderColorRightNotEncoded,
    setDB_LeftSidebar_MenuItem_BreadCrumbs_BorderColorRightNotEncoded,
  ] = useState("rgba(255,255,255,1)");

  const [
    DB_LeftSidebar_MenuItem_BreadCrumbs_BorderTop,
    setDB_LeftSidebar_MenuItem_BreadCrumbs_BorderTop,
  ] = useState(0);
  const [
    DB_LeftSidebar_MenuItem_BreadCrumbs_BorderBottom,
    setDB_LeftSidebar_MenuItem_BreadCrumbs_BorderBottom,
  ] = useState(0);
  const [
    DB_LeftSidebar_MenuItem_BreadCrumbs_BorderLeft,
    setDB_LeftSidebar_MenuItem_BreadCrumbs_BorderLeft,
  ] = useState(1);
  const [
    DB_LeftSidebar_MenuItem_BreadCrumbs_BorderRight,
    setDB_LeftSidebar_MenuItem_BreadCrumbs_BorderRight,
  ] = useState(1);

  const [
    DB_LeftSidebar_MenuItem_BreadCrumbs_BorderStyleTop,
    setDB_LeftSidebar_MenuItem_BreadCrumbs_BorderStyleTop,
  ] = useState("solid");
  const [
    DB_LeftSidebar_MenuItem_BreadCrumbs_BorderStyleBottom,
    setDB_LeftSidebar_MenuItem_BreadCrumbs_BorderStyleBottom,
  ] = useState("solid");
  const [
    DB_LeftSidebar_MenuItem_BreadCrumbs_BorderStyleLeft,
    setDB_LeftSidebar_MenuItem_BreadCrumbs_BorderStyleLeft,
  ] = useState("solid");
  const [
    DB_LeftSidebar_MenuItem_BreadCrumbs_BorderStyleRight,
    setDB_LeftSidebar_MenuItem_BreadCrumbs_BorderStyleRight,
  ] = useState("solid");

  const DB_LeftSidebar_MenuItem_BreadCrumbs_HoverBackgroundColor =
    "rgba(255,255,255,0)";
  const DB_LeftSidebar_MenuItem_BreadCrumbs_HoverFontColor =
    "rgba(255,255,255,1)";

  const [
    DB_LeftSidebar_MenuItem_BreadCrumbs_marginTopBottom,
    setDB_LeftSidebar_MenuItem_BreadCrumbs_marginTopBottom,
  ] = useState("0");
  const [
    DB_LeftSidebar_MenuItem_BreadCrumbs_marginLeftRight,
    setDB_LeftSidebar_MenuItem_BreadCrumbs_marginLeftRight,
  ] = useState("5");

  const [
    DB_LeftSidebar_MenuItem_BreadCrumbs_paddingLeftRight,
    setDB_LeftSidebar_MenuItem_BreadCrumbs_paddingLeftRight,
  ] = useState("5");

  const [
    DB_LeftSidebar_MenuItem_BreadCrumbs_paddingTopBottom,
    setDB_LeftSidebar_MenuItem_BreadCrumbs_paddingTopBottom,
  ] = useState("0");

  const [
    DB_LeftSidebar_MenuItem_BreadCrumbs_letterSpacing,
    setDB_LeftSidebar_MenuItem_BreadCrumbs_letterSpacing,
  ] = useState("0");

  const [
    DB_LeftSidebar_MenuItem_BreadCrumbs_marginTopBottom_Hover,
    setDB_LeftSidebar_MenuItem_BreadCrumbs_marginTopBottom_Hover,
  ] = useState("0");
  const [
    DB_LeftSidebar_MenuItem_BreadCrumbs_marginLeftRight_Hover,
    setDB_LeftSidebar_MenuItem_BreadCrumbs_marginLeftRight_Hover,
  ] = useState("5");

  const [
    DB_LeftSidebar_MenuItem_BreadCrumbs_paddingLeftRight_Hover,
    setDB_LeftSidebar_MenuItem_BreadCrumbs_paddingLeftRight_Hover,
  ] = useState("5");

  const [
    DB_LeftSidebar_MenuItem_BreadCrumbs_paddingTopBottom_Hover,
    setDB_LeftSidebar_MenuItem_BreadCrumbs_paddingTopBottom_Hover,
  ] = useState("0");

  const [
    DB_LeftSidebar_MenuItem_BreadCrumbs_letterSpacing_Hover,
    setDB_LeftSidebar_MenuItem_BreadCrumbs_letterSpacing_Hover,
  ] = useState("0");

  const [
    DB_LeftSidebar_MenuItem_BreadCrumbs_ShadowHorizontal,
    setDB_LeftSidebar_MenuItem_BreadCrumbs_ShadowHorizontal,
  ] = useState(1);
  const [
    DB_LeftSidebar_MenuItem_BreadCrumbs_ShadowVertical,
    setDB_LeftSidebar_MenuItem_BreadCrumbs_ShadowVertical,
  ] = useState(1);
  const [
    DB_LeftSidebar_MenuItem_BreadCrumbs_ShadowBlur,
    setDB_LeftSidebar_MenuItem_BreadCrumbs_ShadowBlur,
  ] = useState(5);
  const [
    DB_LeftSidebar_MenuItem_BreadCrumbs_ShadowColor,
    setDB_LeftSidebar_MenuItem_BreadCrumbs_ShadowColor,
  ] = useState("rgba(255,255,255,1)");

  const [
    DB_LeftSidebar_MenuItem_BreadCrumbs_ShadowColorNotEncoded,
    setDB_LeftSidebar_MenuItem_BreadCrumbs_ShadowColorNotEncoded,
  ] = useState("rgba(255,255,255,1)");

  const [
    DB_LeftSidebar_MenuItem_BreadCrumbs_TextDecoration,
    setDB_LeftSidebar_MenuItem_BreadCrumbs_TextDecoration,
  ] = useState("normal");

  const [
    DB_LeftSidebar_MenuItem_BreadCrumbs_TextDecorationStyle,
    setDB_LeftSidebar_MenuItem_BreadCrumbs_TextDecorationStyle,
  ] = useState("");

  const [
    DB_LeftSidebar_MenuItem_BreadCrumbs_ShadowHorizontal_Hover,
    setDB_LeftSidebar_MenuItem_BreadCrumbs_ShadowHorizontal_Hover,
  ] = useState(1);
  const [
    DB_LeftSidebar_MenuItem_BreadCrumbs_ShadowVertical_Hover,
    setDB_LeftSidebar_MenuItem_BreadCrumbs_ShadowVertical_Hover,
  ] = useState(1);
  const [
    DB_LeftSidebar_MenuItem_BreadCrumbs_ShadowBlur_Hover,
    setDB_LeftSidebar_MenuItem_BreadCrumbs_ShadowBlur_Hover,
  ] = useState(5);
  const [
    DB_LeftSidebar_MenuItem_BreadCrumbs_ShadowColor_Hover,
    setDB_LeftSidebar_MenuItem_BreadCrumbs_ShadowColor_Hover,
  ] = useState("rgba(255,255,255,1)");
  const [
    DB_LeftSidebar_MenuItem_BreadCrumbs_TextDecoration_Hover,
    setDB_LeftSidebar_MenuItem_BreadCrumbs_TextDecoration_Hover,
  ] = useState("none");
  const [
    DB_LeftSidebar_MenuItem_BreadCrumbs_TextDecorationStyle_Hover,
    setDB_LeftSidebar_MenuItem_BreadCrumbs_TextDecorationStyle_Hover,
  ] = useState("");

  const [
    DB_LeftSidebar_MenuItem_BreadCrumbs_ShadowColorNotEncoded_Hover,
    setDB_LeftSidebar_MenuItem_BreadCrumbs_ShadowColorNotEncoded_Hover,
  ] = useState("rgba(255,255,255,1)");

  const [
    showConfig_ProductCard_BreadCrumbs_BorderAndColor,
    setShowConfig_ProductCard_BreadCrumbs_BorderAndColor,
  ] = useState(false);

  const [
    showConfig_ProductCard_BreadCrumbs_Hover_BorderAndColor,
    setShowConfig_ProductCard_BreadCrumbs_Hover_BorderAndColor,
  ] = useState(false);

  const [
    showConfig_ProductCard_BreadCrumbs,
    setShowConfig_ProductCard_BreadCrumbs,
  ] = useState(false);

  const [
    showConfig_ProductCard_BreadCrumbs_Hover,
    setShowConfig_ProductCard_BreadCrumbs_Hover,
  ] = useState(false);

  /////////////////////////////// BreadCrumbs //////////////////////////////////////////////////

  useEffect(() => {
    if (productsUserId === getIdOfUser() && isAuthState === true) {
      setUserOwnsThisSite(true);
    } else {
      setUserOwnsThisSite(false);
    }
  }, [isAuthState]);

  const anmeldungErfolgreich = () => {
    /* setTimeout(() => {
      setStateUserId(getIdOfUser());
    }, 2000); */
    setSignInModal(!signInModal);
    setIsAuthState(true);
  };

  const registrierenAktiv = () => {
    setDoRegisterOn(true);
    setDoForgotPassword(false);
    setDoLoginOn(false);
  };

  const anmeldenAktiv = () => {
    setDoLoginOn(true);
    setDoForgotPassword(false);
    setDoRegisterOn(false);
  };

  const passwortVergessenAktiv = () => {
    setDoForgotPassword(true);
    setDoLoginOn(false);
    setDoRegisterOn(false);
  };

  const toggleSignIn = () => {
    setSignInModal(!signInModal);
  };

  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
  };

  const handleClose_showConfig_Main = () => setShowConfig_Main(false);
  const handleShow_showConfig_Main = () => setShowConfig_Main(true);

  const columnNumberChange = (event) => {
    setProductDetailShow(false);
    setAboutUsShow(false);
    setFaqShow(false);
    setColumnNumber(event.target.value);
  };

  let allProductsFilteredTemp = allProductsStateMenuLeft;

  const filterProduktsHandler = (event) => {
    setMenuClicked(true);
    // setSearchParams({});
    setProductDetailShow(false);
    setAboutUsShow(false);
    setFaqShow(false);
    const { value, checked } = event.target;

    if (event.target.className.indexOf("farbe") !== -1) {
      setIsCheck([...isCheck, { farbe: value }]);
      if (!checked) {
        setIsCheck(isCheck.filter((item) => item.farbe !== value));
      }
    } else if (event.target.className.indexOf("garantie") !== -1) {
      setIsCheck([...isCheck, { garantie: value }]);
      if (!checked) {
        setIsCheck(isCheck.filter((item) => item.garantie !== value));
      }
    } else if (event.target.className.indexOf("groesse") !== -1) {
      setIsCheck([...isCheck, { groesse: value }]);
      if (!checked) {
        setIsCheck(isCheck.filter((item) => item.groesse !== value));
      }
    } else if (event.target.className.indexOf("marke") !== -1) {
      setIsCheck([...isCheck, { marke: value }]);
      if (!checked) {
        setIsCheck(isCheck.filter((item) => item.marke !== value));
      }
    } else if (event.target.className.indexOf("material") !== -1) {
      setIsCheck([...isCheck, { material: value }]);
      if (!checked) {
        setIsCheck(isCheck.filter((item) => item.material !== value));
      }
    } else if (event.target.className.indexOf("zustand") !== -1) {
      setIsCheck([...isCheck, { zustand: value }]);
      if (!checked) {
        setIsCheck(isCheck.filter((item) => item.zustand !== value));
      }
    }
  };

  const [showProductCounterOne, setShowProductCounterOne] = useState(0);

  useEffect(() => {
    setShowProductCounterOne((prev) => prev + 1);
    if (showProductCounterOne > 0) {
      console.log("isCheck", isCheck);
      console.log("allProducts", allProducts);
      const s = searchParams.get("s");
      if (s != null && s != undefined && s != "") {
      } else {
        setProductDetailShow(false);
      }

      setAboutUsShow(false);
      setFaqShow(false);
      let filterArray = isCheck;
      let filteredArray = allProducts.filter(function (array_el) {
        return (
          filterArray.filter(function (anotherOne_el) {
            return (
              (anotherOne_el.farbe && anotherOne_el.farbe !== array_el.farbe) ||
              (anotherOne_el.garantie &&
                anotherOne_el.garantie !== array_el.garantie) ||
              (anotherOne_el.groesse &&
                anotherOne_el.groesse !== array_el.groesse) ||
              (anotherOne_el.marke && anotherOne_el.marke !== array_el.marke) ||
              (anotherOne_el.material &&
                anotherOne_el.material !== array_el.material) ||
              (anotherOne_el.zustand &&
                anotherOne_el.zustand !== array_el.zustand)
            );
          }).length == 0
        );
      });

      console.log("filteredArray", filteredArray);

      isCheck &&
      isCheck[0] &&
      isCheck[0].filters == "none" &&
      isCheck.length == 1
        ? setAllProductsState((prev) => prev)
        : setAllProductsState(
            filteredArray.slice(0, pageAnzahlProductsPrimary)
          );
      /* setAllProductsStateMenuLeft(filteredArray); */
      /* setPageAnzahlCounter(filteredArray.length); jjj */
      setPaginationStart(0);
      setPaginationEnd(72);
      isCheck &&
      isCheck[0] &&
      isCheck[0].filters == "none" &&
      isCheck.length == 1
        ? setPageAnzahlCounter((prev) => prev)
        : setPageAnzahlCounter(filteredArray.length);

      isCheck &&
      isCheck[0] &&
      isCheck[0].filters == "none" &&
      isCheck.length == 1
        ? setAllProductsStatePagination((prev) => prev)
        : setAllProductsStatePagination(filteredArray);
    }
  }, [isCheck]);

  useEffect(() => {
    const s = searchParams.get("s");
    if (s != null && s != undefined && s != "") {
    } else {
      setProductDetailShow(false);
    }
    setAboutUsShow(false);
    setFaqShow(false);
    let filterArray = isCheck;
    let filteredArray = allProductsStateMenuLeft.filter(function (array_el) {
      return (
        filterArray.filter(function (anotherOne_el) {
          return (
            (anotherOne_el.farbe && anotherOne_el.farbe !== array_el.farbe) ||
            (anotherOne_el.garantie &&
              anotherOne_el.garantie !== array_el.garantie) ||
            (anotherOne_el.groesse &&
              anotherOne_el.groesse !== array_el.groesse) ||
            (anotherOne_el.marke && anotherOne_el.marke !== array_el.marke) ||
            (anotherOne_el.material &&
              anotherOne_el.material !== array_el.material) ||
            (anotherOne_el.zustand &&
              anotherOne_el.zustand !== array_el.zustand)
          );
        }).length == 0
      );
    });

    let filters = [];
    filteredArray.forEach((element, key) => {
      filters.push({
        zustand: element.zustand,
      });

      filters.push({
        garantie: element.garantie,
      });

      filters.push({
        marke: element.marke,
      });

      filters.push({
        material: element.material,
      });

      filters.push({
        farbe: element.farbe,
      });

      filters.push({
        groesse: element.groesse,
      });
    });

    let allFilters = filters;

    let subFiltersTemp = [];
    let filtersTemp = [];
    let ultraFiltersTemp = [];

    allFilters.map((singleFilter) => {
      subFiltersTemp = [];
      if (singleFilter.zustand && !filtersTemp.includes(singleFilter.zustand)) {
        ultraFiltersTemp.push({ zustand: singleFilter.zustand });
      } else if (
        ultraFiltersTemp.garantie &&
        !filtersTemp.includes(singleFilter.garantie)
      ) {
        ultraFiltersTemp.push({ garantie: singleFilter.garantie });
      } else if (
        singleFilter.marke &&
        !filtersTemp.includes(singleFilter.marke)
      ) {
        ultraFiltersTemp.push({ marke: singleFilter.marke });
      } else if (
        singleFilter.material &&
        !filtersTemp.includes(singleFilter.material)
      ) {
        ultraFiltersTemp.push({ material: singleFilter.material });
      } else if (
        singleFilter.farbe &&
        !filtersTemp.includes(singleFilter.farbe)
      ) {
        ultraFiltersTemp.push({ farbe: singleFilter.farbe });
      } else if (
        singleFilter.groesse &&
        !filtersTemp.includes(singleFilter.groesse)
      ) {
        ultraFiltersTemp.push({ groesse: singleFilter.groesse });
      }
      /* filtersTemp.push(singleFilter.filter); */
    });

    filtersTemp = [];

    ultraFiltersTemp.sort(function (a, b) {
      return (Object.keys(a)[0] > Object.keys(b)[0]) - 0.5;
    });

    ultraFiltersTemp = ultraFiltersTemp.filter(
      (value, index, self) =>
        index ===
        self.findIndex(
          (t) =>
            t.groesse === value.groesse &&
            t.farbe === value.farbe &&
            t.garantie === value.garantie &&
            t.zustand === value.zustand &&
            t.marke === value.marke &&
            t.material === value.material
        )
    );

    isCheck.length !== 0
      ? setUltraFiltersState(ultraFiltersTemp)
      : setUltraFiltersState(ultraFiltersTemp); // ultraFilters
  }, [isCheck, allProductsStateMenuLeft]);

  const resetFilterHandler = () => {
    setSearchParams({
      cat: "",
      sub: "",
      subsub: "",
      b: 0,
      e: 72,
    });
    setMenuClicked(true);
    setCategoryObject({
      kategorie: "",
      unterkategorie: "",
      unterunterkategorie: "",
    });
    setAboutUsShow(false);
    setFaqShow(false);
    setProductDetailShow(false);
    setUltraFiltersState([]);
    setAllProductsState(allProducts.slice(0, pageAnzahlProductsPrimary));
    setAllProductsStateMenuLeft(allProducts);
    setIsCheck([]);
    setPaginationStart(0);
    setPaginationEnd(72);
    setAllProductsStatePagination(allProducts);
    setPageAnzahlCounter(allProducts.length);
  };

  const categoryLeftSideHandler = (
    mainkategorie,
    subkategorie,
    subsubkategorie
  ) => {
    setMenuClicked(true);
    setPaginationStart(0);
    setPaginationEnd(72);

    setProductDetailShow(false);
    setAboutUsShow(false);
    setFaqShow(false);
    setIsCheck([{ filters: "none" }]);

    if (
      mainkategorie &&
      subkategorie === undefined &&
      subsubkategorie === undefined
    ) {
      /*  setSearchParams({
        cat: mainkategorie,
        sub: "",
        subsub: "",
        b: paginationStart,
        e: paginationEnd,
      }); */
      setCategoryObject({
        kategorie: mainkategorie,
        unterkategorie: "",
        unterunterkategorie: "",
      });
    } else if (mainkategorie && subkategorie && subsubkategorie === undefined) {
      /* setSearchParams({
        cat: mainkategorie,
        sub: subkategorie,
        subsub: "",
        b: paginationStart,
        e: paginationEnd,
      }); */
      setCategoryObject({
        kategorie: mainkategorie,
        unterkategorie: subkategorie,
        unterunterkategorie: "",
      });
    } else if (mainkategorie && subkategorie && subsubkategorie) {
      /* setSearchParams({
        cat: mainkategorie,
        sub: subkategorie,
        subsub: subsubkategorie,
        b: paginationStart,
        e: paginationEnd,
      }); */
      setCategoryObject({
        kategorie: mainkategorie,
        unterkategorie: subkategorie,
        unterunterkategorie: subsubkategorie,
      });
    }
  };

  const changePreisAlphabeticUswHandler = (event) => {
    setProductDetailShow(false);
    setAboutUsShow(false);
    setFaqShow(false);
    const preisAufAbUsw = allProductsStateMenuLeft;
    let newPreisAufAb = [];
    if (event.target.value === "Preis aufsteigend") {
      newPreisAufAb = preisAufAbUsw.sort(
        (a, b) => parseFloat(a.fixpreis) - parseFloat(b.fixpreis)
      );
      const reversedArray = [];
      for (let i = newPreisAufAb.length - 1; i >= 0; i--) {
        const valueAtIndex = newPreisAufAb[i];
        reversedArray.push(valueAtIndex);
      }
      setPaginationStart(0);
      setPaginationEnd(72);
      setAllProductsState(reversedArray.slice(0, 72));
      setAllProductsStateMenuLeft(reversedArray);
      setPageAnzahlCounter(reversedArray.length);
      setAllProductsStatePagination(reversedArray);
    } else if (event.target.value === "Preis absteigend") {
      newPreisAufAb = allProductsStateMenuLeft.sort(
        (a, b) => parseFloat(a.fixpreis) - parseFloat(b.fixpreis)
      );
      const reversedArray = [];
      for (let i = newPreisAufAb.length - 1; i >= 0; i--) {
        const valueAtIndex = newPreisAufAb[i];
        reversedArray.push(valueAtIndex);
      }
      setPaginationStart(0);
      setPaginationEnd(72);
      setAllProductsState(reversedArray.slice(0, 72));
      setAllProductsStateMenuLeft(reversedArray);
      setPageAnzahlCounter(reversedArray.length);
      setAllProductsStatePagination(reversedArray);
    } else if (event.target.value === "Älteste") {
      newPreisAufAb = allProductsStateMenuLeft.sort(
        (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
      );
      let normalArray = [];
      for (let i = 0; i < newPreisAufAb.length; i++) {
        const valueAtIndex = newPreisAufAb[i];
        normalArray.push(valueAtIndex);
      }
      setPaginationStart(0);
      setPaginationEnd(72);
      setAllProductsState(normalArray.slice(0, 72));
      setAllProductsStateMenuLeft(normalArray);
      setPageAnzahlCounter(normalArray.length);
      setAllProductsStatePagination(normalArray);
    } else if (event.target.value === "Neueste") {
      newPreisAufAb = allProductsStateMenuLeft.sort(
        (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
      );
      const reversedArray = [];
      for (let i = newPreisAufAb.length - 1; i >= 0; i--) {
        const valueAtIndex = newPreisAufAb[i];
        reversedArray.push(valueAtIndex);
      }
      setPaginationStart(0);
      setPaginationEnd(72);
      setAllProductsState(reversedArray.slice(0, 72));
      setAllProductsStateMenuLeft(reversedArray);
      setPageAnzahlCounter(reversedArray.length);
      setAllProductsStatePagination(reversedArray);
    } else if (event.target.value === "Alphabetisch absteigend") {
      newPreisAufAb = allProductsStateMenuLeft.sort((a, b) =>
        a.titel.toLowerCase() > b.titel.toLowerCase()
          ? 1
          : b.titel.toLowerCase() > a.titel.toLowerCase()
          ? -1
          : 0
      );
      const reversedArray = [];
      for (let i = newPreisAufAb.length - 1; i >= 0; i--) {
        const valueAtIndex = newPreisAufAb[i];
        reversedArray.push(valueAtIndex);
      }
      setPaginationStart(0);
      setPaginationEnd(72);
      setAllProductsState(reversedArray.slice(0, 72));
      setAllProductsStateMenuLeft(reversedArray);
      setPageAnzahlCounter(reversedArray.length);
      setAllProductsStatePagination(reversedArray);
    } else if (event.target.value === "Alphabetisch aufsteigend") {
      newPreisAufAb = allProductsStateMenuLeft.sort((a, b) =>
        a.titel.toLowerCase() > b.titel.toLowerCase()
          ? 1
          : b.titel.toLowerCase() > a.titel.toLowerCase()
          ? -1
          : 0
      );
      let normalArray = [];
      for (let i = 0; i < newPreisAufAb.length; i++) {
        const valueAtIndex = newPreisAufAb[i];
        normalArray.push(valueAtIndex);
      }
      setPaginationStart(0);
      setPaginationEnd(72);
      setAllProductsState(newPreisAufAb.slice(0, 72));
      setAllProductsStateMenuLeft(newPreisAufAb);
      setPageAnzahlCounter(newPreisAufAb.length);
      setAllProductsStatePagination(newPreisAufAb);
    }

    if (
      categoryObject.kategorie == "" &&
      categoryObject.unterkategorie == "" &&
      categoryObject.unterunterkategorie == ""
    ) {
      setCategoryObject({
        kategorie: "",
        unterkategorie: "",
        unterunterkategorie: "",
      });
    }
  };

  const searchHandler = (e) => {
    setMenuClicked(true);
    setProductDetailShow(false);
    setAboutUsShow(false);
    setFaqShow(false);
    setSearchValue(e.target.value);
    const searchValue = e.target.value.toLowerCase();

    const allProductsFiltered = allProducts.filter(
      (product) =>
        (product.titel && product.titel.toLowerCase().includes(searchValue)) ||
        (product.untertitel &&
          product.untertitel.toLowerCase().includes(searchValue)) ||
        (product.kategorie &&
          product.kategorie.toLowerCase().includes(searchValue)) ||
        (product.unterkategorie &&
          product.unterkategorie.toLowerCase().includes(searchValue)) ||
        (product.unterunterkategorie &&
          product.unterunterkategorie.toLowerCase().includes(searchValue))
    );

    /* setAllProductsState(allProductsFiltered);
    setAllProductsStateMenuLeft(allProductsFiltered);
    setPageAnzahlCounter(allProductsFiltered.length); */
    setPaginationStart(0);
    setPaginationEnd(72);
    setAllProductsState(allProductsFiltered.slice(0, 72));
    setAllProductsStateMenuLeft(allProductsFiltered);
    setPageAnzahlCounter(allProductsFiltered.length);
    setAllProductsStatePagination(allProductsFiltered);
  };

  const searchPressEnter = (event) => {
    if (event.key === "Enter") {
      setSearchParams({});
      setSearchValue("");
      setProductDetailShow(false);
      setAboutUsShow(false);
      setFaqShow(false);
      setMenuClicked(true);
    }
  };

  const hideProductDetailsHandler = () => {
    setProductDetailShow(false);
    setAboutUsShow(false);
    setFaqShow(false);
  };

  useEffect(() => {
    const s = searchParams.get("s");

    s
      ? axios({
          method: "post",
          url: `${process.env.REACT_APP_PUBLIC_SERVER_URL}/shop/receive-product-images`,
          data: {
            shopName: shopName,
            chosenProductTitle: s,
          },
          /*  headers: {
        Authorization: `Bearer ${token}`,
      }, */
        })
          .then((response) => {
            setMenuClicked(true);
            const imageidentifier = response.data.data;
            const imagesTemp = [];
            imageidentifier.forEach((element) => {
              imagesTemp.push({
                ordernumber: element.ordernumber,
                original: `${process.env.REACT_APP_PUBLIC_SERVER_URL}/${element.imageidentifier}`,
                thumbnail: `${process.env.REACT_APP_PUBLIC_SERVER_URL}/${element.imageidentifier}`,
              });
            });

            setProductDetailItem(response.data.product);
            setImages(
              imagesTemp.sort(function (a, b) {
                return a.ordernumber - b.ordernumber;
              })
            );

            setAboutUsShow(false);
            setFaqShow(false);
            setProductDetailShow(true);
          })
          .catch((err) => {
            toast.error(`${t("KeineVerbindungZumServer")}`, {
              toastId: "custom-id-yes",
              position: "bottom-center",
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
              transition: Zoom,
            });
          })
      : setProductDetailShow(false);

    const t = searchParams.get("t");
    const begin = searchParams.get("b");
    const end = searchParams.get("e");
    const cat = searchParams.get("cat");
    const sub = searchParams.get("sub");
    const subsub = searchParams.get("subsub");
    let allProductsFiltered;

    if (!begin && !end && !s) {
      setPaginationStart(0);
      setPaginationEnd(72);
    } else if (begin && end && !s) {
      setPaginationStart(parseInt(searchParams.get("b")));
      setPaginationEnd(parseInt(searchParams.get("e")));
    }

    if (!s) {
      if (cat && !sub && !subsub) {
        pageLoadedNew < 3 &&
          setCategoryObject({
            kategorie: cat,
            unterkategorie: "",
            unterunterkategorie: "",
          });
        allProductsFiltered = allProducts.filter(
          (product) => product.kategorie === cat
        );
        setAllProductsState(
          allProductsFiltered.slice(0, pageAnzahlProductsPrimary)
        );
        setAllProductsStateMenuLeft(allProductsFiltered);
        setPageAnzahlCounter(allProductsFiltered.length);
        if (begin && end) {
          setAllProductsState(allProductsFiltered.slice(begin, end));
        } else {
          setAllProductsStatePagination(allProductsFiltered);
        }
      } else if (cat && sub && !subsub) {
        pageLoadedNew < 3 &&
          setCategoryObject({
            kategorie: cat,
            unterkategorie: sub,
            unterunterkategorie: "",
          });
        allProductsFiltered = allProducts.filter(
          (product) =>
            product.kategorie === cat && product.unterkategorie === sub
        );
        setAllProductsState(
          allProductsFiltered.slice(0, pageAnzahlProductsPrimary)
        );
        setAllProductsStateMenuLeft(allProductsFiltered);
        setPageAnzahlCounter(allProductsFiltered.length);
        if (begin && end) {
          setAllProductsState(allProductsFiltered.slice(begin, end));
        } else {
          setAllProductsStatePagination(allProductsFiltered);
        }
      } else if (cat && sub && subsub) {
        pageLoadedNew < 3 &&
          setCategoryObject({
            kategorie: cat,
            unterkategorie: sub,
            unterunterkategorie: subsub,
          });
        allProductsFiltered = allProducts.filter(
          (product) =>
            product.kategorie === cat &&
            product.unterkategorie === sub &&
            product.unterunterkategorie === subsub
        );
        setAllProductsState(
          allProductsFiltered.slice(0, pageAnzahlProductsPrimary)
        );
        setAllProductsStateMenuLeft(allProductsFiltered);
        setPageAnzahlCounter(allProductsFiltered.length);

        if (begin && end) {
          setAllProductsState(allProductsFiltered.slice(begin, end));
        } else {
          setAllProductsStatePagination(allProductsFiltered);
        }
      } else if (t) {
        setAllProductsState(allFrontpageProducts.slice(0, 72));
        setPageAnzahlCounter(allFrontpageProducts.length);
      } else {
        pageLoadedNew < 3 &&
          setCategoryObject({
            kategorie: "",
            unterkategorie: "",
            unterunterkategorie: "",
          });
        allProductsFiltered = allProducts;
        setAllProductsState(
          allProductsFiltered.slice(0, pageAnzahlProductsPrimary)
        );
        setAllProductsStateMenuLeft(allProductsFiltered);
        setPageAnzahlCounter(allProductsFiltered.length);

        if (begin && end) {
          setAllProductsState(allProductsFiltered.slice(begin, end));
        } else {
          setAllProductsStatePagination(allProductsFiltered);
        }
      }
    }

    setPageLoadedNew(3);
  }, [searchParams, allProducts, pageLoadedNew]);

  const showProductDetailsHandler = (productIdentifier) => {
    /* const s = searchParams.get("s"); */
    setSearchParams({ s: productIdentifier });

    /*  menuClicked || s == null
      ? axios({
          method: "post",
          url: `${process.env.REACT_APP_PUBLIC_SERVER_URL}/shop/receive-product-images`,
          data: {
            shopName: shopName,
            chosenProductTitle: productIdentifier,
          },
        })
          .then((response) => {
            const imageidentifier = response.data.data;
            const imagesTemp = [];
            imageidentifier.forEach((element) => {
              imagesTemp.push({
                ordernumber: element.ordernumber,
                original: `${process.env.REACT_APP_PUBLIC_SERVER_URL}/${element.imageidentifier}`,
                thumbnail: `${process.env.REACT_APP_PUBLIC_SERVER_URL}/${element.imageidentifier}`,
              });
            });

            setProductDetailItem(response.data.product);
            setImages(
              imagesTemp.sort(function (a, b) {
                return a.ordernumber - b.ordernumber;
              })
            );
            setAboutUsShow(false);
            setFaqShow(false);
            setProductDetailShow(true);
         
          })
          .catch((err) => {
            toast.error(`${t("KeineVerbindungZumServer")}`, {
              toastId: "custom-id-yes",
              position: "bottom-center",
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
              transition: Zoom,
            });
          })
      : axios({
          method: "post",
          url: `${process.env.REACT_APP_PUBLIC_SERVER_URL}/shop/receive-product-images`,
          data: {
            shopName: shopName,
            chosenProductTitle: s,
          },
  
        })
          .then((response) => {
            const imageidentifier = response.data.data;
            const imagesTemp = [];
            imageidentifier.forEach((element) => {
              imagesTemp.push({
                ordernumber: element.ordernumber,
                original: `${process.env.REACT_APP_PUBLIC_SERVER_URL}/${element.imageidentifier}`,
                thumbnail: `${process.env.REACT_APP_PUBLIC_SERVER_URL}/${element.imageidentifier}`,
              });
            });

            setProductDetailItem(response.data.product);
            setImages(
              imagesTemp.sort(function (a, b) {
                return a.ordernumber - b.ordernumber;
              })
            );
            setAboutUsShow(false);
            setFaqShow(false);
            setProductDetailShow(true);
           
          })
          .catch((err) => {
            toast.error(`${t("KeineVerbindungZumServer")}`, {
              toastId: "custom-id-yes",
              position: "bottom-center",
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
              transition: Zoom,
            });
          }); */
  };

  const aboutUsShowHandler = () => {
    setMenuClicked(true);

    axios({
      method: "post",
      url: `${process.env.REACT_APP_PUBLIC_SERVER_URL}/shop/receive-about-us-images`,
      data: {
        shopName: shopName,
      },
      /*  headers: {
        Authorization: `Bearer ${token}`,
      }, */
    })
      .then((response) => {
        const imageidentifier = response.data.data;
        const imagesTemp = [];
        imageidentifier.forEach((element) => {
          imagesTemp.push({
            ordernumber: element.ordernumber,
            original: `${process.env.REACT_APP_PUBLIC_SERVER_URL}/${element.imageidentifier}`,
            thumbnail: `${process.env.REACT_APP_PUBLIC_SERVER_URL}/${element.imageidentifier}`,
          });
        });

        setAboutUsDetailItem({
          details: response.data.publicProfile,
          privateName: response.data.privateName,
          privateEmail: response.data.privateEmail,
          privateAdress: response.data.privateAdress,
          privatePLZ: response.data.privatePLZ,
          privateOrt: response.data.privateOrt,
          privateLand: response.data.privateLand,
          privateJoinOn: response.data.privateJoinOn,
        });
        setAboutUsImages(
          imagesTemp.sort(function (a, b) {
            return a.ordernumber - b.ordernumber;
          })
        );
        setAboutUsShow(true);
        setFaqShow(false);
      })
      .catch((err) => {
        toast.error(`${t("KeineVerbindungZumServer")}`, {
          toastId: "custom-id-yes",
          position: "bottom-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          transition: Zoom,
        });
      });
  };

  const faqShowHandler = () => {
    setMenuClicked(true);

    axios({
      method: "post",
      url: `${process.env.REACT_APP_PUBLIC_SERVER_URL}/shop/receive-shop-faqs`,
      data: {
        shopName: shopName,
      },
      /*  headers: {
        Authorization: `Bearer ${token}`,
      }, */
    })
      .then((response) => {
        setFaqUsDetailItem(response.data.data);
        setFaqShow(true);
        setAboutUsShow(false);
      })
      .catch((err) => {
        toast.error(`${t("KeineVerbindungZumServer")}`, {
          toastId: "custom-id-yes",
          position: "bottom-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          transition: Zoom,
        });
      });
  };

  useEffect(() => {
    /*  const { s } = myRouter.query; */
    axios({
      method: "post",
      url: `${process.env.REACT_APP_PUBLIC_SERVER_URL}/shop/receive-shop-faqs`,
      data: {
        shopName: shopName,
      },
      /*  headers: {
          Authorization: `Bearer ${token}`,
        }, */
    })
      .then((response) => {
        setFaqUsDetailItem(response.data.data);
      })
      .catch((err) => {
        toast.error(`${t("KeineVerbindungZumServer")}`, {
          toastId: "custom-id-yes",
          position: "bottom-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          transition: Zoom,
        });
      });
  }, []);

  const signOutMessage = () => {
    signout();
    setIsAuthState(false);
    toast.info("Erfolgreich abgemeldet", {
      toastId: "custom-id-yes",
      position: "bottom-center",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
      transition: Zoom,
    });
  };

  const [topBarVisible, setTopBarVisible] = useState(true);
  const [topMainCategoriesVisible, setTopMainCategoriesVisible] =
    useState(true);
  const [bottomMainCategoriesVisible, setBottomMainCategoriesVisible] =
    useState(false);
  const [leftMainCategoriesVisible, setLeftMainCategoriesVisible] =
    useState(false);
  const [rightMainCategoriesVisible, setRightMainCategoriesVisible] =
    useState(false);
  const [leftCategoriesVisible, setLeftCategoriesVisible] = useState(true);
  const [rightFiltersVisible, setRightFiltersVisible] = useState(true);
  const [rightSocialsVisible, setRightSocialsVisible] = useState(true);
  const [productTopFilterVisible, setProductTopFilterVisible] = useState(true);
  const [specialRightCategoriesVisible, setSpecialRightCategoriesVisible] =
    useState(false);
  const [specialLeftFiltersVisible, setSpecialLeftFiltersVisible] =
    useState(false);
  const [specialLeftSocialsVisible, setSpecialLeftSocialsVisible] =
    useState(false);

  const [TopBarIsVisibleTop, setTopBarIsVisibleTop] = useState(true);
  const [MarqueeIsVisibleTop, setMarqueeIsVisibleTop] = useState(false);
  const [TopBarIsVisibleBottom, setTopBarIsVisibleBottom] = useState(false);
  const [TopBarIsVisibleLeft, setTopBarIsVisibleLeft] = useState(false);
  const [TopBarIsVisibleRight, setTopBarIsVisibleRight] = useState(false);

  const [TopBarFilterProductOne, setTopBarFilterProductOne] = useState(false);
  const [TopBarFilterProductTwo, setTopBarFilterProductTwo] = useState(false);
  const [TopBarFilterProductThree, setTopBarFilterProductThree] =
    useState(true);
  const [TopBarFilterProductFour, setTopBarFilterProductFour] = useState(false);

  const checkIfTopBarFilterCounter = (e) => {
    if (e.target.value == "TopBarFilterProductOne") {
      setColumnNumber("12");
      setTopBarFilterProductOne(true);
      setTopBarFilterProductTwo(false);
      setTopBarFilterProductThree(false);
      setTopBarFilterProductFour(false);
    } else if (e.target.value == "TopBarFilterProductTwo") {
      setColumnNumber("6");
      setTopBarFilterProductOne(false);
      setTopBarFilterProductTwo(true);
      setTopBarFilterProductThree(false);
      setTopBarFilterProductFour(false);
    } else if (e.target.value == "TopBarFilterProductThree") {
      setColumnNumber("4");
      setTopBarFilterProductOne(false);
      setTopBarFilterProductTwo(false);
      setTopBarFilterProductThree(true);
      setTopBarFilterProductFour(false);
    } else if (e.target.value == "TopBarFilterProductFour") {
      setColumnNumber("3");
      setTopBarFilterProductOne(false);
      setTopBarFilterProductTwo(false);
      setTopBarFilterProductThree(false);
      setTopBarFilterProductFour(true);
    } else {
      setColumnNumber("4");
      setTopBarFilterProductOne(false);
      setTopBarFilterProductTwo(false);
      setTopBarFilterProductThree(true);
      setTopBarFilterProductFour(false);
    }
  };

  const checkIfMarqueeIsVisible = (e) => {
    if (e.target.value === "MarqueeIsVisibleTop") {
      setMarqueeIsVisibleTop(e.target.checked);
    }
  };

  const checkIfTopBarIsVisible = (e) => {
    if (e.target.value === "TopBarIsVisibleTop") {
      setTopBarIsVisibleTop(e.target.checked);
    } else if (e.target.value === "TopBarIsVisibleBottom") {
      setTopBarIsVisibleBottom(e.target.checked);
    } else if (e.target.value === "TopBarIsVisibleLeft") {
      setTopBarIsVisibleLeft(e.target.checked);
    } else if (e.target.value === "TopBarIsVisibleRight") {
      setTopBarIsVisibleRight(e.target.checked);
    } else {
    }
  };

  const [ProductFilterIsVisibleTop, setProductFilterIsVisibleTop] =
    useState(true);
  const [ProductFilterIsVisibleBottom, setProductFilterIsVisibleBottom] =
    useState(false);
  const [ProductFilterIsVisibleLeft, setProductFilterIsVisibleLeft] =
    useState(false);
  const [ProductFilterIsVisibleRight, setProductFilterIsVisibleRight] =
    useState(false);

  const checkIfTopProductFilterIsVisible = (e) => {
    if (e.target.value === "ProductFilterIsVisibleTop") {
      setProductFilterIsVisibleTop(e.target.checked);
    } else if (e.target.value === "ProductFilterIsVisibleBottom") {
      setProductFilterIsVisibleBottom(e.target.checked);
    } else if (e.target.value === "ProductFilterIsVisibleLeft") {
      setProductFilterIsVisibleLeft(e.target.checked);
    } else if (e.target.value === "ProductFilterIsVisibleRight") {
      setProductFilterIsVisibleRight(e.target.checked);
    } else {
      setProductFilterIsVisibleTop(true);
    }
  };

  const checkIfTopMainCategoriesIsVisible = (e) => {
    setTopMainCategoriesVisible(e.target.checked);
  };

  const checkIfBottomMainCategoriesIsVisible = (e) => {
    setBottomMainCategoriesVisible(e.target.checked);
  };

  const checkIfLeftMainCategoriesIsVisible = (e) => {
    setLeftMainCategoriesVisible(e.target.checked);
  };

  const checkIfRightMainCategoriesIsVisible = (e) => {
    setRightMainCategoriesVisible(e.target.checked);
  };

  const checkIfLeftCategoriesIsVisible = (e) => {
    setLeftCategoriesVisible(e.target.checked);
  };

  const checkIfSpecialRightCategoriesIsVisible = (e) => {
    setSpecialRightCategoriesVisible(e.target.checked);
  };

  const checkIfSpecialLeftFiltersIsVisible = (e) => {
    setSpecialLeftFiltersVisible(e.target.checked);
  };

  const checkIfRightFiltersIsVisible = (e) => {
    setRightFiltersVisible(e.target.checked);
  };

  const checkIfSpecialLeftSocialsIsVisible = (e) => {
    setSpecialLeftSocialsVisible(e.target.checked);
  };

  const checkIfRightSocialsIsVisible = (e) => {
    setRightSocialsVisible(e.target.checked);
  };

  const checkIfBackgroundColorActive = (e) => {
    setBackgroundColorActive(e.target.checked);
  };

  const [logoActive, setLogoActive] = useState(false);

  const checkIfLogoActive = (e) => {
    setLogoActive(e.target.checked);
  };

  const [backgroundImageActive, setBackgroundImageActive] = useState(false);

  const checkIfBackgroundImageActive = (e) => {
    setBackgroundImageActive(e.target.checked);
  };

  const chooseOrderOfBody = (e) => {
    const chosenOrder = e.target.value;

    if (chosenOrder === "012") {
      setDB_OrderOfBodyElements("012");
    } else if (chosenOrder === "021") {
      setDB_OrderOfBodyElements("021");
    } else if (chosenOrder === "102") {
      setDB_OrderOfBodyElements("102");
    } else if (chosenOrder === "120") {
      setDB_OrderOfBodyElements("120");
    } else if (chosenOrder === "201") {
      setDB_OrderOfBodyElements("201");
    } else if (chosenOrder === "210") {
      setDB_OrderOfBodyElements("210");
    } else {
      setDB_OrderOfBodyElements("012");
    }
  };

  const setNewBackgroundImage = (e) => {
    /* setDB_BackgroundImageForAllBody */
  };

  const setNewLogoImage = (e) => {};

  const [DB_HauptContainerBackground, setDB_HauptContainerBackground] =
    useState("");

  /*background-image: url("/images/pexels-steve-johnson-12564252.jpg"); */
  /*background-image: url("/images/pexels-steve-johnson-13978499.jpg"); */
  /* background-image: url("/images/pexels-steve-johnson-13845237.jpg"); */

  // DB_LeftSidebar - Container:Hover
  const [
    DB_LeftSidebar_MenuProduktContainer_Hover_BackgroundColor,
    setDB_LeftSidebar_MenuProduktContainer_Hover_BackgroundColor,
  ] = useState("rgba(0,0,0,0.3)");

  const [
    DB_LeftSidebar_MenuProduktContainer_Hover_BackgroundColorNotEncoded,
    setDB_LeftSidebar_MenuProduktContainer_Hover_BackgroundColorNotEncoded,
  ] = useState("rgba(0,0,0,0.3)");

  const [
    DB_LeftSidebar_MenuProduktImageContainer_Hover_BackgroundColor,
    setDB_LeftSidebar_MenuProduktImageContainer_Hover_BackgroundColor,
  ] = useState("rgba(0,0,0,0.7)");

  const [
    DB_LeftSidebar_MenuProduktImageContainer_Hover_BackgroundColorNotEncoded,
    setDB_LeftSidebar_MenuProduktImageContainer_Hover_BackgroundColorNotEncoded,
  ] = useState("rgba(0,0,0,0.7)");

  const [
    DB_LeftSidebar_MenuProduktContainer_Hover_PaddingTopBottom,
    setDB_LeftSidebar_MenuProduktContainer_Hover_PaddingTopBottom,
  ] = useState(10);

  const [
    DB_LeftSidebar_MenuProduktContainer_Hover_PaddingLeftRight,
    setDB_LeftSidebar_MenuProduktContainer_Hover_PaddingLeftRight,
  ] = useState(10);

  // DB_LeftSidebar - Container
  const [
    DB_LeftSidebar_MenuProduktContainer_BackgroundColor,
    setDB_LeftSidebar_MenuProduktContainer_BackgroundColor,
  ] = useState("rgba(0,0,0,0.3)");

  const [
    DB_LeftSidebar_MenuProduktContainer_BackgroundColorNotEncoded,
    setDB_LeftSidebar_MenuProduktContainer_BackgroundColorNotEncoded,
  ] = useState("rgba(0,0,0,0.3)");

  const [
    DB_LeftSidebar_MenuProduktImageContainer_BackgroundColor,
    setDB_LeftSidebar_MenuProduktImageContainer_BackgroundColor,
  ] = useState("rgba(0,0,0,0.7)");

  const [
    DB_LeftSidebar_MenuProduktImageContainer_BackgroundColorNotEncoded,
    setDB_LeftSidebar_MenuProduktImageContainer_BackgroundColorNotEncoded,
  ] = useState("rgba(0,0,0,0.7)");

  const [
    DB_LeftSidebar_MenuProduktContainer_PaddingTopBottom,
    setDB_LeftSidebar_MenuProduktContainer_PaddingTopBottom,
  ] = useState(10);

  const [
    DB_LeftSidebar_MenuProduktContainer_PaddingLeftRight,
    setDB_LeftSidebar_MenuProduktContainer_PaddingLeftRight,
  ] = useState(10);

  const [
    DB_LeftSidebar_MenuItem_MenuProduktContainer_BorderTopRightRadius,
    setDB_LeftSidebar_MenuItem_MenuProduktContainer_BorderTopRightRadius,
  ] = useState(20);
  const [
    DB_LeftSidebar_MenuItem_MenuProduktContainer_BorderTopLeftRadius,
    setDB_LeftSidebar_MenuItem_MenuProduktContainer_BorderTopLeftRadius,
  ] = useState(20);
  const [
    DB_LeftSidebar_MenuItem_MenuProduktContainer_BorderBottomRightRadius,
    setDB_LeftSidebar_MenuItem_MenuProduktContainer_BorderBottomRightRadius,
  ] = useState(20);
  const [
    DB_LeftSidebar_MenuItem_MenuProduktContainer_BorderBottomLeftRadius,
    setDB_LeftSidebar_MenuItem_MenuProduktContainer_BorderBottomLeftRadius,
  ] = useState(20);

  const [
    DB_LeftSidebar_MenuItem_MenuProduktContainer_Hover_BorderTopRightRadius,
    setDB_LeftSidebar_MenuItem_MenuProduktContainer_Hover_BorderTopRightRadius,
  ] = useState(20);
  const [
    DB_LeftSidebar_MenuItem_MenuProduktContainer_Hover_BorderTopLeftRadius,
    setDB_LeftSidebar_MenuItem_MenuProduktContainer_Hover_BorderTopLeftRadius,
  ] = useState(20);
  const [
    DB_LeftSidebar_MenuItem_MenuProduktContainer_Hover_BorderBottomRightRadius,
    setDB_LeftSidebar_MenuItem_MenuProduktContainer_Hover_BorderBottomRightRadius,
  ] = useState(20);
  const [
    DB_LeftSidebar_MenuItem_MenuProduktContainer_Hover_BorderBottomLeftRadius,
    setDB_LeftSidebar_MenuItem_MenuProduktContainer_Hover_BorderBottomLeftRadius,
  ] = useState(20);

  const [
    DB_LeftSidebar_MenuItem_MenuProduktContainer_BorderColorTop,
    setDB_LeftSidebar_MenuItem_MenuProduktContainer_BorderColorTop,
  ] = useState("rgba(255,255,255,1)");
  const [
    DB_LeftSidebar_MenuItem_MenuProduktContainer_BorderColorBottom,
    setDB_LeftSidebar_MenuItem_MenuProduktContainer_BorderColorBottom,
  ] = useState("rgba(255,255,255,1)");
  const [
    DB_LeftSidebar_MenuItem_MenuProduktContainer_BorderColorLeft,
    setDB_LeftSidebar_MenuItem_MenuProduktContainer_BorderColorLeft,
  ] = useState("rgba(255,255,255,1)");
  const [
    DB_LeftSidebar_MenuItem_MenuProduktContainer_BorderColorRight,
    setDB_LeftSidebar_MenuItem_MenuProduktContainer_BorderColorRight,
  ] = useState("rgba(255,255,255,1)");

  const [
    DB_LeftSidebar_MenuItem_MenuProduktContainer_BorderColorTopNotEncoded,
    setDB_LeftSidebar_MenuItem_MenuProduktContainer_BorderColorTopNotEncoded,
  ] = useState("rgba(255,255,255,1)");
  const [
    DB_LeftSidebar_MenuItem_MenuProduktContainer_BorderColorBottomNotEncoded,
    setDB_LeftSidebar_MenuItem_MenuProduktContainer_BorderColorBottomNotEncoded,
  ] = useState("rgba(255,255,255,1)");
  const [
    DB_LeftSidebar_MenuItem_MenuProduktContainer_BorderColorLeftNotEncoded,
    setDB_LeftSidebar_MenuItem_MenuProduktContainer_BorderColorLeftNotEncoded,
  ] = useState("rgba(255,255,255,1)");
  const [
    DB_LeftSidebar_MenuItem_MenuProduktContainer_BorderColorRightNotEncoded,
    setDB_LeftSidebar_MenuItem_MenuProduktContainer_BorderColorRightNotEncoded,
  ] = useState("rgba(255,255,255,1)");

  const [
    DB_LeftSidebar_MenuProduktContainer_Hover_BorderColorTop,
    setDB_LeftSidebar_MenuProduktContainer_Hover_BorderColorTop,
  ] = useState("rgba(0,0,0,0.3)");
  const [
    DB_LeftSidebar_MenuProduktContainer_Hover_BorderColorBottom,
    setDB_LeftSidebar_MenuProduktContainer_Hover_BorderColorBottom,
  ] = useState("rgba(0,0,0,0.3)");
  const [
    DB_LeftSidebar_MenuProduktContainer_Hover_BorderColorLeft,
    setDB_LeftSidebar_MenuProduktContainer_Hover_BorderColorLeft,
  ] = useState("rgba(0,0,0,0.3)");
  const [
    DB_LeftSidebar_MenuProduktContainer_Hover_BorderColorRight,
    setDB_LeftSidebar_MenuProduktContainer_Hover_BorderColorRight,
  ] = useState("rgba(0,0,0,0.3)");

  const [
    DB_LeftSidebar_MenuProduktContainer_Hover_BorderColorTopNotEncoded,
    setDB_LeftSidebar_MenuProduktContainer_Hover_BorderColorTopNotEncoded,
  ] = useState("rgba(0,0,0,0.3)");
  const [
    DB_LeftSidebar_MenuProduktContainer_Hover_BorderColorBottomNotEncoded,
    setDB_LeftSidebar_MenuProduktContainer_Hover_BorderColorBottomNotEncoded,
  ] = useState("rgba(0,0,0,0.3)");
  const [
    DB_LeftSidebar_MenuProduktContainer_Hover_BorderColorLeftNotEncoded,
    setDB_LeftSidebar_MenuProduktContainer_Hover_BorderColorLeftNotEncoded,
  ] = useState("rgba(0,0,0,0.3)");
  const [
    DB_LeftSidebar_MenuProduktContainer_Hover_BorderColorRightNotEncoded,
    setDB_LeftSidebar_MenuProduktContainer_Hover_BorderColorRightNotEncoded,
  ] = useState("rgba(0,0,0,0.3)");

  const [
    DB_LeftSidebar_MenuProduktContainer_Hover_BorderTop,
    setDB_LeftSidebar_MenuProduktContainer_Hover_BorderTop,
  ] = useState(1);
  const [
    DB_LeftSidebar_MenuProduktContainer_Hover_BorderBottom,
    setDB_LeftSidebar_MenuProduktContainer_Hover_BorderBottom,
  ] = useState(1);
  const [
    DB_LeftSidebar_MenuProduktContainer_Hover_BorderLeft,
    setDB_LeftSidebar_MenuProduktContainer_Hover_BorderLeft,
  ] = useState(1);
  const [
    DB_LeftSidebar_MenuProduktContainer_Hover_BorderRight,
    setDB_LeftSidebar_MenuProduktContainer_Hover_BorderRight,
  ] = useState(1);

  const [
    DB_LeftSidebar_MenuProduktContainer_Hover_BorderStyleTop,
    setDB_LeftSidebar_MenuProduktContainer_Hover_BorderStyleTop,
  ] = useState("solid");
  const [
    DB_LeftSidebar_MenuProduktContainer_Hover_BorderStyleBottom,
    setDB_LeftSidebar_MenuProduktContainer_Hover_BorderStyleBottom,
  ] = useState("solid");
  const [
    DB_LeftSidebar_MenuProduktContainer_Hover_BorderStyleLeft,
    setDB_LeftSidebar_MenuProduktContainer_Hover_BorderStyleLeft,
  ] = useState("solid");
  const [
    DB_LeftSidebar_MenuProduktContainer_Hover_BorderStyleRight,
    setDB_LeftSidebar_MenuProduktContainer_Hover_BorderStyleRight,
  ] = useState("solid");

  const [
    DB_LeftSidebar_MenuProduktContainer_BorderTop,
    setDB_LeftSidebar_MenuProduktContainer_BorderTop,
  ] = useState(1);
  const [
    DB_LeftSidebar_MenuProduktContainer_BorderBottom,
    setDB_LeftSidebar_MenuProduktContainer_BorderBottom,
  ] = useState(1);
  const [
    DB_LeftSidebar_MenuProduktContainer_BorderLeft,
    setDB_LeftSidebar_MenuProduktContainer_BorderLeft,
  ] = useState(1);
  const [
    DB_LeftSidebar_MenuProduktContainer_BorderRight,
    setDB_LeftSidebar_MenuProduktContainer_BorderRight,
  ] = useState(1);

  const [
    DB_LeftSidebar_MenuProduktContainer_BorderStyleTop,
    setDB_LeftSidebar_MenuProduktContainer_BorderStyleTop,
  ] = useState("solid");
  const [
    DB_LeftSidebar_MenuProduktContainer_BorderStyleBottom,
    setDB_LeftSidebar_MenuProduktContainer_BorderStyleBottom,
  ] = useState("solid");
  const [
    DB_LeftSidebar_MenuProduktContainer_BorderStyleLeft,
    setDB_LeftSidebar_MenuProduktContainer_BorderStyleLeft,
  ] = useState("solid");
  const [
    DB_LeftSidebar_MenuProduktContainer_BorderStyleRight,
    setDB_LeftSidebar_MenuProduktContainer_BorderStyleRight,
  ] = useState("solid");

  const [
    DB_LeftSidebar_MenuProduktContainer_BorderColorTop,
    setDB_LeftSidebar_MenuProduktContainer_BorderColorTop,
  ] = useState("rgba(0,0,0,0.3)");
  const [
    DB_LeftSidebar_MenuProduktContainer_BorderColorBottom,
    setDB_LeftSidebar_MenuProduktContainer_BorderColorBottom,
  ] = useState("rgba(0,0,0,0.3)");
  const [
    DB_LeftSidebar_MenuProduktContainer_BorderColorLeft,
    setDB_LeftSidebar_MenuProduktContainer_BorderColorLeft,
  ] = useState("rgba(0,0,0,0.3)");
  const [
    DB_LeftSidebar_MenuProduktContainer_BorderColorRight,
    setDB_LeftSidebar_MenuProduktContainer_BorderColorRight,
  ] = useState("rgba(0,0,0,0.3)");

  const [
    DB_LeftSidebar_MenuProduktContainer_BorderColorTopNotEncoded,
    setDB_LeftSidebar_MenuProduktContainer_BorderColorTopNotEncoded,
  ] = useState("rgba(0,0,0,0.3)");
  const [
    DB_LeftSidebar_MenuProduktContainer_BorderColorBottomNotEncoded,
    setDB_LeftSidebar_MenuProduktContainer_BorderColorBottomNotEncoded,
  ] = useState("rgba(0,0,0,0.3)");
  const [
    DB_LeftSidebar_MenuProduktContainer_BorderColorLeftNotEncoded,
    setDB_LeftSidebar_MenuProduktContainer_BorderColorLeftNotEncoded,
  ] = useState("rgba(0,0,0,0.3)");
  const [
    DB_LeftSidebar_MenuProduktContainer_BorderColorRightNotEncoded,
    setDB_LeftSidebar_MenuProduktContainer_BorderColorRightNotEncoded,
  ] = useState("rgba(0,0,0,0.3)");

  const [
    DB_LeftSidebar_MenuItem_MenuProduktContainer_ShadowHorizontal,
    setDB_LeftSidebar_MenuItem_MenuProduktContainer_ShadowHorizontal,
  ] = useState(1);
  const [
    DB_LeftSidebar_MenuItem_MenuProduktContainer_ShadowVertical,
    setDB_LeftSidebar_MenuItem_MenuProduktContainer_ShadowVertical,
  ] = useState(1);
  const [
    DB_LeftSidebar_MenuItem_MenuProduktContainer_ShadowBlur,
    setDB_LeftSidebar_MenuItem_MenuProduktContainer_ShadowBlur,
  ] = useState(5);
  const [
    DB_LeftSidebar_MenuItem_MenuProduktContainer_ShadowColor,
    setDB_LeftSidebar_MenuItem_MenuProduktContainer_ShadowColor,
  ] = useState("rgba(255,255,255,1)");

  const [
    DB_LeftSidebar_MenuItem_MenuProduktContainer_ShadowColorNotEncoded,
    setDB_LeftSidebar_MenuItem_MenuProduktContainer_ShadowColorNotEncoded,
  ] = useState("rgba(255,255,255,1)");

  const [
    DB_LeftSidebar_MenuItem_MenuProduktContainer_Hover_ShadowHorizontal,
    setDB_LeftSidebar_MenuItem_MenuProduktContainer_Hover_ShadowHorizontal,
  ] = useState(1);
  const [
    DB_LeftSidebar_MenuItem_MenuProduktContainer_Hover_ShadowVertical,
    setDB_LeftSidebar_MenuItem_MenuProduktContainer_Hover_ShadowVertical,
  ] = useState(1);
  const [
    DB_LeftSidebar_MenuItem_MenuProduktContainer_Hover_ShadowBlur,
    setDB_LeftSidebar_MenuItem_MenuProduktContainer_Hover_ShadowBlur,
  ] = useState(5);
  const [
    DB_LeftSidebar_MenuItem_MenuProduktContainer_Hover_ShadowColor,
    setDB_LeftSidebar_MenuItem_MenuProduktContainer_Hover_ShadowColor,
  ] = useState("rgba(255,255,255,1)");

  const [
    DB_LeftSidebar_MenuItem_MenuProduktContainer_Hover_ShadowColorNotEncoded,
    setDB_LeftSidebar_MenuItem_MenuProduktContainer_Hover_ShadowColorNotEncoded,
  ] = useState("rgba(255,255,255,1)");

  const [
    DB_LeftSidebar_MenuItem_MenuProduktContainer_paddingLeft,
    setDB_LeftSidebar_MenuItem_MenuProduktContainer_paddingLeft,
  ] = useState("0");

  const [
    DB_LeftSidebar_MenuItem_MenuProduktContainer_paddingTop,
    setDB_LeftSidebar_MenuItem_MenuProduktContainer_paddingTop,
  ] = useState("0");

  const [
    DB_LeftSidebar_MenuItem_MenuProduktContainer_paddingRight,
    setDB_LeftSidebar_MenuItem_MenuProduktContainer_paddingRight,
  ] = useState("0");

  const [
    DB_LeftSidebar_MenuItem_MenuProduktContainer_paddingBottom,
    setDB_LeftSidebar_MenuItem_MenuProduktContainer_paddingBottom,
  ] = useState("0");

  const [
    DB_LeftSidebar_MenuItem_MenuProduktContainer_Hover_paddingLeft,
    setDB_LeftSidebar_MenuItem_MenuProduktContainer_Hover_paddingLeft,
  ] = useState("0");

  const [
    DB_LeftSidebar_MenuItem_MenuProduktContainer_Hover_paddingTop,
    setDB_LeftSidebar_MenuItem_MenuProduktContainer_Hover_paddingTop,
  ] = useState("0");

  const [
    DB_LeftSidebar_MenuItem_MenuProduktContainer_Hover_paddingRight,
    setDB_LeftSidebar_MenuItem_MenuProduktContainer_Hover_paddingRight,
  ] = useState("0");

  const [
    DB_LeftSidebar_MenuItem_MenuProduktContainer_Hover_paddingBottom,
    setDB_LeftSidebar_MenuItem_MenuProduktContainer_Hover_paddingBottom,
  ] = useState("0");

  const [
    DB_LeftSidebar_MenuItem_TitelSuperTitel_marginTopBottom,
    setDB_LeftSidebar_MenuItem_TitelSuperTitel_marginTopBottom,
  ] = useState("5");
  const [
    DB_LeftSidebar_MenuItem_TitelSuperTitel_marginLeftRight,
    setDB_LeftSidebar_MenuItem_TitelSuperTitel_marginLeftRight,
  ] = useState("5");

  const [
    DB_LeftSidebar_MenuItem_TitelSuperTitel_paddingLeftRight,
    setDB_LeftSidebar_MenuItem_TitelSuperTitel_paddingLeftRight,
  ] = useState("5");

  const [
    DB_LeftSidebar_MenuItem_TitelSuperTitel_paddingTopBottom,
    setDB_LeftSidebar_MenuItem_TitelSuperTitel_paddingTopBottom,
  ] = useState("5");

  const [
    DB_LeftSidebar_MenuItem_TitelSuperTitel_letterSpacing,
    setDB_LeftSidebar_MenuItem_TitelSuperTitel_letterSpacing,
  ] = useState("0");

  const [
    DB_LeftSidebar_MenuItem_TitelSuperTitel_ShadowHorizontal,
    setDB_LeftSidebar_MenuItem_TitelSuperTitel_ShadowHorizontal,
  ] = useState(1);
  const [
    DB_LeftSidebar_MenuItem_TitelSuperTitel_ShadowVertical,
    setDB_LeftSidebar_MenuItem_TitelSuperTitel_ShadowVertical,
  ] = useState(1);
  const [
    DB_LeftSidebar_MenuItem_TitelSuperTitel_ShadowBlur,
    setDB_LeftSidebar_MenuItem_TitelSuperTitel_ShadowBlur,
  ] = useState(5);
  const [
    DB_LeftSidebar_MenuItem_TitelSuperTitel_ShadowColor,
    setDB_LeftSidebar_MenuItem_TitelSuperTitel_ShadowColor,
  ] = useState("rgba(255,255,255,1)");

  const [
    DB_LeftSidebar_MenuItem_TitelSuperTitel_ShadowColorNotEncoded,
    setDB_LeftSidebar_MenuItem_TitelSuperTitel_ShadowColorNotEncoded,
  ] = useState("rgba(255,255,255,1)");

  const [
    DB_LeftSidebar_MenuItem_TitelSuperTitel_TextDecoration,
    setDB_LeftSidebar_MenuItem_TitelSuperTitel_TextDecoration,
  ] = useState("normal");

  const [
    DB_LeftSidebar_MenuItem_TitelSuperTitel_TextDecorationStyle,
    setDB_LeftSidebar_MenuItem_TitelSuperTitel_TextDecorationStyle,
  ] = useState("");

  // DB_LeftSidebar - TitelSuperTitel Normal .-----------------.---------------------.----------------------
  const [
    DB_LeftSidebar_MenuItem_TitelSuperTitel_FontSize,
    setDB_LeftSidebar_MenuItem_TitelSuperTitel_FontSize,
  ] = useState(16);
  const DB_LeftSidebar_MenuItem_TitelSuperTitel_FontFace =
    DB_fontFace_FontFamilyName;
  const [
    DB_LeftSidebar_MenuItem_TitelSuperTitel_FontWeight,
    setDB_LeftSidebar_MenuItem_TitelSuperTitel_FontWeight,
  ] = useState(400);
  const [
    DB_LeftSidebar_MenuItem_TitelSuperTitel_FontColor,
    setDB_LeftSidebar_MenuItem_TitelSuperTitel_FontColor,
  ] = useState("rgba(255,255,255,1)");

  const [
    DB_LeftSidebar_MenuItem_TitelSuperTitel_FontColorNotEncoded,
    setDB_LeftSidebar_MenuItem_TitelSuperTitel_FontColorNotEncoded,
  ] = useState("rgba(0,0,0,0.3)");

  const [
    DB_LeftSidebar_MenuItem_TitelSuperTitel_BackgroundColor,
    setDB_LeftSidebar_MenuItem_TitelSuperTitel_BackgroundColor,
  ] = useState("rgba(0,0,0,0.2)");
  const [
    DB_LeftSidebar_MenuItem_TitelSuperTitel_BackgroundColorNotEncoded,
    setDB_LeftSidebar_MenuItem_TitelSuperTitel_BackgroundColorNotEncoded,
  ] = useState("rgba(0,0,0,0.2)");

  const [
    DB_LeftSidebar_MenuItem_TitelSuperTitel_TextAlign,
    setDB_LeftSidebar_MenuItem_TitelSuperTitel_TextAlign,
  ] = useState("center");

  /////////////////////////////////
  const [
    DB_LeftSidebar_MenuItem_TitelSuperTitel_BorderTopRightRadius,
    setDB_LeftSidebar_MenuItem_TitelSuperTitel_BorderTopRightRadius,
  ] = useState(5);
  const [
    DB_LeftSidebar_MenuItem_TitelSuperTitel_BorderTopLeftRadius,
    setDB_LeftSidebar_MenuItem_TitelSuperTitel_BorderTopLeftRadius,
  ] = useState(5);
  const [
    DB_LeftSidebar_MenuItem_TitelSuperTitel_BorderBottomRightRadius,
    setDB_LeftSidebar_MenuItem_TitelSuperTitel_BorderBottomRightRadius,
  ] = useState(5);
  const [
    DB_LeftSidebar_MenuItem_TitelSuperTitel_BorderBottomLeftRadius,
    setDB_LeftSidebar_MenuItem_TitelSuperTitel_BorderBottomLeftRadius,
  ] = useState(5);

  const [
    DB_LeftSidebar_MenuItem_TitelSuperTitel_BorderColorTop,
    setDB_LeftSidebar_MenuItem_TitelSuperTitel_BorderColorTop,
  ] = useState("rgba(0,0,0,0.3)");
  const [
    DB_LeftSidebar_MenuItem_TitelSuperTitel_BorderColorBottom,
    setDB_LeftSidebar_MenuItem_TitelSuperTitel_BorderColorBottom,
  ] = useState("rgba(0,0,0,0.3)");
  const [
    DB_LeftSidebar_MenuItem_TitelSuperTitel_BorderColorLeft,
    setDB_LeftSidebar_MenuItem_TitelSuperTitel_BorderColorLeft,
  ] = useState("rgba(0,0,0,0.3)");
  const [
    DB_LeftSidebar_MenuItem_TitelSuperTitel_BorderColorRight,
    setDB_LeftSidebar_MenuItem_TitelSuperTitel_BorderColorRight,
  ] = useState("rgba(0,0,0,0.3)");
  const [
    DB_LeftSidebar_MenuItem_TitelSuperTitel_BorderColorTopNotEncoded,
    setDB_LeftSidebar_MenuItem_TitelSuperTitel_BorderColorTopNotEncoded,
  ] = useState("rgba(0,0,0,0.3)");
  const [
    DB_LeftSidebar_MenuItem_TitelSuperTitel_BorderColorBottomNotEncoded,
    setDB_LeftSidebar_MenuItem_TitelSuperTitel_BorderColorBottomNotEncoded,
  ] = useState("rgba(0,0,0,0.3)");
  const [
    DB_LeftSidebar_MenuItem_TitelSuperTitel_BorderColorLeftNotEncoded,
    setDB_LeftSidebar_MenuItem_TitelSuperTitel_BorderColorLeftNotEncoded,
  ] = useState("rgba(0,0,0,0.3)");
  const [
    DB_LeftSidebar_MenuItem_TitelSuperTitel_BorderColorRightNotEncoded,
    setDB_LeftSidebar_MenuItem_TitelSuperTitel_BorderColorRightNotEncoded,
  ] = useState("rgba(0,0,0,0.3)");

  const [
    DB_LeftSidebar_MenuItem_TitelSuperTitel_BorderTop,
    setDB_LeftSidebar_MenuItem_TitelSuperTitel_BorderTop,
  ] = useState(1);
  const [
    DB_LeftSidebar_MenuItem_TitelSuperTitel_BorderBottom,
    setDB_LeftSidebar_MenuItem_TitelSuperTitel_BorderBottom,
  ] = useState(1);
  const [
    DB_LeftSidebar_MenuItem_TitelSuperTitel_BorderLeft,
    setDB_LeftSidebar_MenuItem_TitelSuperTitel_BorderLeft,
  ] = useState(1);
  const [
    DB_LeftSidebar_MenuItem_TitelSuperTitel_BorderRight,
    setDB_LeftSidebar_MenuItem_TitelSuperTitel_BorderRight,
  ] = useState(1);

  const [
    DB_LeftSidebar_MenuItem_TitelSuperTitel_BorderStyleTop,
    setDB_LeftSidebar_MenuItem_TitelSuperTitel_BorderStyleTop,
  ] = useState("solid");
  const [
    DB_LeftSidebar_MenuItem_TitelSuperTitel_BorderStyleBottom,
    setDB_LeftSidebar_MenuItem_TitelSuperTitel_BorderStyleBottom,
  ] = useState("solid");
  const [
    DB_LeftSidebar_MenuItem_TitelSuperTitel_BorderStyleLeft,
    setDB_LeftSidebar_MenuItem_TitelSuperTitel_BorderStyleLeft,
  ] = useState("solid");
  const [
    DB_LeftSidebar_MenuItem_TitelSuperTitel_BorderStyleRight,
    setDB_LeftSidebar_MenuItem_TitelSuperTitel_BorderStyleRight,
  ] = useState("solid");

  const [
    DB_LeftSidebar_MenuItem_TitelFormInputDetail_marginTopBottom,
    setDB_LeftSidebar_MenuItem_TitelFormInputDetail_marginTopBottom,
  ] = useState("5");
  const [
    DB_LeftSidebar_MenuItem_TitelFormInputDetail_marginLeftRight,
    setDB_LeftSidebar_MenuItem_TitelFormInputDetail_marginLeftRight,
  ] = useState("5");

  const [
    DB_LeftSidebar_MenuItem_TitelFormInputDetail_paddingLeftRight,
    setDB_LeftSidebar_MenuItem_TitelFormInputDetail_paddingLeftRight,
  ] = useState("5");

  const [
    DB_LeftSidebar_MenuItem_TitelFormInputDetail_paddingTopBottom,
    setDB_LeftSidebar_MenuItem_TitelFormInputDetail_paddingTopBottom,
  ] = useState("5");

  const [
    DB_LeftSidebar_MenuItem_TitelFormInputDetail_letterSpacing,
    setDB_LeftSidebar_MenuItem_TitelFormInputDetail_letterSpacing,
  ] = useState("0");

  const [
    DB_LeftSidebar_MenuItem_TitelFormInputDetail_ShadowHorizontal,
    setDB_LeftSidebar_MenuItem_TitelFormInputDetail_ShadowHorizontal,
  ] = useState(1);
  const [
    DB_LeftSidebar_MenuItem_TitelFormInputDetail_ShadowVertical,
    setDB_LeftSidebar_MenuItem_TitelFormInputDetail_ShadowVertical,
  ] = useState(1);
  const [
    DB_LeftSidebar_MenuItem_TitelFormInputDetail_ShadowBlur,
    setDB_LeftSidebar_MenuItem_TitelFormInputDetail_ShadowBlur,
  ] = useState(5);
  const [
    DB_LeftSidebar_MenuItem_TitelFormInputDetail_ShadowColor,
    setDB_LeftSidebar_MenuItem_TitelFormInputDetail_ShadowColor,
  ] = useState("rgba(255,255,255,1)");

  const [
    DB_LeftSidebar_MenuItem_TitelFormInputDetail_ShadowColorNotEncoded,
    setDB_LeftSidebar_MenuItem_TitelFormInputDetail_ShadowColorNotEncoded,
  ] = useState("rgba(255,255,255,1)");

  const [
    DB_LeftSidebar_MenuItem_TitelFormInputDetail_TextDecoration,
    setDB_LeftSidebar_MenuItem_TitelFormInputDetail_TextDecoration,
  ] = useState("normal");

  const [
    DB_LeftSidebar_MenuItem_TitelFormInputDetail_TextDecorationStyle,
    setDB_LeftSidebar_MenuItem_TitelFormInputDetail_TextDecorationStyle,
  ] = useState("");

  // DB_LeftSidebar - TitelFormInputDetail Normal .-----------------.---------------------.----------------------
  const [
    DB_LeftSidebar_MenuItem_TitelFormInputDetail_FontSize,
    setDB_LeftSidebar_MenuItem_TitelFormInputDetail_FontSize,
  ] = useState(16);
  const DB_LeftSidebar_MenuItem_TitelFormInputDetail_FontFace =
    DB_fontFace_FontFamilyName;
  const [
    DB_LeftSidebar_MenuItem_TitelFormInputDetail_FontWeight,
    setDB_LeftSidebar_MenuItem_TitelFormInputDetail_FontWeight,
  ] = useState(400);
  const [
    DB_LeftSidebar_MenuItem_TitelFormInputDetail_FontColor,
    setDB_LeftSidebar_MenuItem_TitelFormInputDetail_FontColor,
  ] = useState("rgba(255,255,255,1)");

  const [
    DB_LeftSidebar_MenuItem_TitelFormInputDetail_FontColorNotEncoded,
    setDB_LeftSidebar_MenuItem_TitelFormInputDetail_FontColorNotEncoded,
  ] = useState("rgba(0,0,0,0.3)");

  const [
    DB_LeftSidebar_MenuItem_TitelFormInputDetail_BackgroundColor,
    setDB_LeftSidebar_MenuItem_TitelFormInputDetail_BackgroundColor,
  ] = useState("rgba(0,0,0,0.2)");
  const [
    DB_LeftSidebar_MenuItem_TitelFormInputDetail_BackgroundColorNotEncoded,
    setDB_LeftSidebar_MenuItem_TitelFormInputDetail_BackgroundColorNotEncoded,
  ] = useState("rgba(0,0,0,0.2)");

  const [
    DB_LeftSidebar_MenuItem_TitelFormInputDetail_TextAlign,
    setDB_LeftSidebar_MenuItem_TitelFormInputDetail_TextAlign,
  ] = useState("center");

  /////////////////////////////////
  const [
    DB_LeftSidebar_MenuItem_TitelFormInputDetail_BorderTopRightRadius,
    setDB_LeftSidebar_MenuItem_TitelFormInputDetail_BorderTopRightRadius,
  ] = useState(5);
  const [
    DB_LeftSidebar_MenuItem_TitelFormInputDetail_BorderTopLeftRadius,
    setDB_LeftSidebar_MenuItem_TitelFormInputDetail_BorderTopLeftRadius,
  ] = useState(5);
  const [
    DB_LeftSidebar_MenuItem_TitelFormInputDetail_BorderBottomRightRadius,
    setDB_LeftSidebar_MenuItem_TitelFormInputDetail_BorderBottomRightRadius,
  ] = useState(5);
  const [
    DB_LeftSidebar_MenuItem_TitelFormInputDetail_BorderBottomLeftRadius,
    setDB_LeftSidebar_MenuItem_TitelFormInputDetail_BorderBottomLeftRadius,
  ] = useState(5);

  const [
    DB_LeftSidebar_MenuItem_TitelFormInputDetail_BorderColorTop,
    setDB_LeftSidebar_MenuItem_TitelFormInputDetail_BorderColorTop,
  ] = useState("rgba(0,0,0,0.3)");
  const [
    DB_LeftSidebar_MenuItem_TitelFormInputDetail_BorderColorBottom,
    setDB_LeftSidebar_MenuItem_TitelFormInputDetail_BorderColorBottom,
  ] = useState("rgba(0,0,0,0.3)");
  const [
    DB_LeftSidebar_MenuItem_TitelFormInputDetail_BorderColorLeft,
    setDB_LeftSidebar_MenuItem_TitelFormInputDetail_BorderColorLeft,
  ] = useState("rgba(0,0,0,0.3)");
  const [
    DB_LeftSidebar_MenuItem_TitelFormInputDetail_BorderColorRight,
    setDB_LeftSidebar_MenuItem_TitelFormInputDetail_BorderColorRight,
  ] = useState("rgba(0,0,0,0.3)");
  const [
    DB_LeftSidebar_MenuItem_TitelFormInputDetail_BorderColorTopNotEncoded,
    setDB_LeftSidebar_MenuItem_TitelFormInputDetail_BorderColorTopNotEncoded,
  ] = useState("rgba(0,0,0,0.3)");
  const [
    DB_LeftSidebar_MenuItem_TitelFormInputDetail_BorderColorBottomNotEncoded,
    setDB_LeftSidebar_MenuItem_TitelFormInputDetail_BorderColorBottomNotEncoded,
  ] = useState("rgba(0,0,0,0.3)");
  const [
    DB_LeftSidebar_MenuItem_TitelFormInputDetail_BorderColorLeftNotEncoded,
    setDB_LeftSidebar_MenuItem_TitelFormInputDetail_BorderColorLeftNotEncoded,
  ] = useState("rgba(0,0,0,0.3)");
  const [
    DB_LeftSidebar_MenuItem_TitelFormInputDetail_BorderColorRightNotEncoded,
    setDB_LeftSidebar_MenuItem_TitelFormInputDetail_BorderColorRightNotEncoded,
  ] = useState("rgba(0,0,0,0.3)");

  const [
    DB_LeftSidebar_MenuItem_TitelFormInputDetail_BorderTop,
    setDB_LeftSidebar_MenuItem_TitelFormInputDetail_BorderTop,
  ] = useState(1);
  const [
    DB_LeftSidebar_MenuItem_TitelFormInputDetail_BorderBottom,
    setDB_LeftSidebar_MenuItem_TitelFormInputDetail_BorderBottom,
  ] = useState(1);
  const [
    DB_LeftSidebar_MenuItem_TitelFormInputDetail_BorderLeft,
    setDB_LeftSidebar_MenuItem_TitelFormInputDetail_BorderLeft,
  ] = useState(1);
  const [
    DB_LeftSidebar_MenuItem_TitelFormInputDetail_BorderRight,
    setDB_LeftSidebar_MenuItem_TitelFormInputDetail_BorderRight,
  ] = useState(1);

  const [
    DB_LeftSidebar_MenuItem_TitelFormInputDetail_BorderStyleTop,
    setDB_LeftSidebar_MenuItem_TitelFormInputDetail_BorderStyleTop,
  ] = useState("solid");
  const [
    DB_LeftSidebar_MenuItem_TitelFormInputDetail_BorderStyleBottom,
    setDB_LeftSidebar_MenuItem_TitelFormInputDetail_BorderStyleBottom,
  ] = useState("solid");
  const [
    DB_LeftSidebar_MenuItem_TitelFormInputDetail_BorderStyleLeft,
    setDB_LeftSidebar_MenuItem_TitelFormInputDetail_BorderStyleLeft,
  ] = useState("solid");
  const [
    DB_LeftSidebar_MenuItem_TitelFormInputDetail_BorderStyleRight,
    setDB_LeftSidebar_MenuItem_TitelFormInputDetail_BorderStyleRight,
  ] = useState("solid");

  const [
    DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_marginTopBottom,
    setDB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_marginTopBottom,
  ] = useState("5");
  const [
    DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_marginLeftRight,
    setDB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_marginLeftRight,
  ] = useState("0");

  const [
    DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_paddingLeftRight,
    setDB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_paddingLeftRight,
  ] = useState("5");

  const [
    DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_paddingTopBottom,
    setDB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_paddingTopBottom,
  ] = useState("5");

  const [
    DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_letterSpacing,
    setDB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_letterSpacing,
  ] = useState("0");

  const [
    DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_ShadowHorizontal,
    setDB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_ShadowHorizontal,
  ] = useState(1);
  const [
    DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_ShadowVertical,
    setDB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_ShadowVertical,
  ] = useState(1);
  const [
    DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_ShadowBlur,
    setDB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_ShadowBlur,
  ] = useState(5);
  const [
    DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_ShadowColor,
    setDB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_ShadowColor,
  ] = useState("rgba(255,255,255,1)");

  const [
    DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_ShadowColorNotEncoded,
    setDB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_ShadowColorNotEncoded,
  ] = useState("rgba(255,255,255,1)");

  const [
    DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_TextDecoration,
    setDB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_TextDecoration,
  ] = useState("normal");

  const [
    DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_TextDecorationStyle,
    setDB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_TextDecorationStyle,
  ] = useState("");

  // DB_LeftSidebar - TitelKontaktFormularTitel Normal .-----------------.---------------------.----------------------
  const [
    DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_FontSize,
    setDB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_FontSize,
  ] = useState(16);
  const DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_FontFace =
    DB_fontFace_FontFamilyName;
  const [
    DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_FontWeight,
    setDB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_FontWeight,
  ] = useState(400);
  const [
    DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_FontColor,
    setDB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_FontColor,
  ] = useState("rgba(255,255,255,1)");

  const [
    DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_FontColorNotEncoded,
    setDB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_FontColorNotEncoded,
  ] = useState("rgba(0,0,0,0.3)");

  const [
    DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_BackgroundColor,
    setDB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_BackgroundColor,
  ] = useState("rgba(0,0,0,0.2)");
  const [
    DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_BackgroundColorNotEncoded,
    setDB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_BackgroundColorNotEncoded,
  ] = useState("rgba(0,0,0,0.2)");

  const [
    DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_TextAlign,
    setDB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_TextAlign,
  ] = useState("center");

  /////////////////////////////////
  const [
    DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_BorderTopRightRadius,
    setDB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_BorderTopRightRadius,
  ] = useState(5);
  const [
    DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_BorderTopLeftRadius,
    setDB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_BorderTopLeftRadius,
  ] = useState(5);
  const [
    DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_BorderBottomRightRadius,
    setDB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_BorderBottomRightRadius,
  ] = useState(5);
  const [
    DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_BorderBottomLeftRadius,
    setDB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_BorderBottomLeftRadius,
  ] = useState(5);

  const [
    DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_BorderColorTop,
    setDB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_BorderColorTop,
  ] = useState("rgba(0,0,0,0.3)");
  const [
    DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_BorderColorBottom,
    setDB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_BorderColorBottom,
  ] = useState("rgba(0,0,0,0.3)");
  const [
    DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_BorderColorLeft,
    setDB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_BorderColorLeft,
  ] = useState("rgba(0,0,0,0.3)");
  const [
    DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_BorderColorRight,
    setDB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_BorderColorRight,
  ] = useState("rgba(0,0,0,0.3)");
  const [
    DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_BorderColorTopNotEncoded,
    setDB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_BorderColorTopNotEncoded,
  ] = useState("rgba(0,0,0,0.3)");
  const [
    DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_BorderColorBottomNotEncoded,
    setDB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_BorderColorBottomNotEncoded,
  ] = useState("rgba(0,0,0,0.3)");
  const [
    DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_BorderColorLeftNotEncoded,
    setDB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_BorderColorLeftNotEncoded,
  ] = useState("rgba(0,0,0,0.3)");
  const [
    DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_BorderColorRightNotEncoded,
    setDB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_BorderColorRightNotEncoded,
  ] = useState("rgba(0,0,0,0.3)");

  const [
    DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_BorderTop,
    setDB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_BorderTop,
  ] = useState(1);
  const [
    DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_BorderBottom,
    setDB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_BorderBottom,
  ] = useState(1);
  const [
    DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_BorderLeft,
    setDB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_BorderLeft,
  ] = useState(1);
  const [
    DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_BorderRight,
    setDB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_BorderRight,
  ] = useState(1);

  const [
    DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_BorderStyleTop,
    setDB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_BorderStyleTop,
  ] = useState("solid");
  const [
    DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_BorderStyleBottom,
    setDB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_BorderStyleBottom,
  ] = useState("solid");
  const [
    DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_BorderStyleLeft,
    setDB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_BorderStyleLeft,
  ] = useState("solid");
  const [
    DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_BorderStyleRight,
    setDB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_BorderStyleRight,
  ] = useState("solid");

  const [
    DB_LeftSidebar_MenuItem_TitelKontaktFormular_marginTopBottom,
    setDB_LeftSidebar_MenuItem_TitelKontaktFormular_marginTopBottom,
  ] = useState("5");
  const [
    DB_LeftSidebar_MenuItem_TitelKontaktFormular_marginLeftRight,
    setDB_LeftSidebar_MenuItem_TitelKontaktFormular_marginLeftRight,
  ] = useState("5");

  const [
    DB_LeftSidebar_MenuItem_TitelKontaktFormular_paddingLeftRight,
    setDB_LeftSidebar_MenuItem_TitelKontaktFormular_paddingLeftRight,
  ] = useState("5");

  const [
    DB_LeftSidebar_MenuItem_TitelKontaktFormular_paddingTopBottom,
    setDB_LeftSidebar_MenuItem_TitelKontaktFormular_paddingTopBottom,
  ] = useState("5");

  const [
    DB_LeftSidebar_MenuItem_TitelKontaktFormular_letterSpacing,
    setDB_LeftSidebar_MenuItem_TitelKontaktFormular_letterSpacing,
  ] = useState("0");

  const [
    DB_LeftSidebar_MenuItem_TitelKontaktFormular_ShadowHorizontal,
    setDB_LeftSidebar_MenuItem_TitelKontaktFormular_ShadowHorizontal,
  ] = useState(1);
  const [
    DB_LeftSidebar_MenuItem_TitelKontaktFormular_ShadowVertical,
    setDB_LeftSidebar_MenuItem_TitelKontaktFormular_ShadowVertical,
  ] = useState(1);
  const [
    DB_LeftSidebar_MenuItem_TitelKontaktFormular_ShadowBlur,
    setDB_LeftSidebar_MenuItem_TitelKontaktFormular_ShadowBlur,
  ] = useState(5);
  const [
    DB_LeftSidebar_MenuItem_TitelKontaktFormular_ShadowColor,
    setDB_LeftSidebar_MenuItem_TitelKontaktFormular_ShadowColor,
  ] = useState("rgba(255,255,255,1)");

  const [
    DB_LeftSidebar_MenuItem_TitelKontaktFormular_ShadowColorNotEncoded,
    setDB_LeftSidebar_MenuItem_TitelKontaktFormular_ShadowColorNotEncoded,
  ] = useState("rgba(255,255,255,1)");

  const [
    DB_LeftSidebar_MenuItem_TitelKontaktFormular_TextDecoration,
    setDB_LeftSidebar_MenuItem_TitelKontaktFormular_TextDecoration,
  ] = useState("normal");

  const [
    DB_LeftSidebar_MenuItem_TitelKontaktFormular_TextDecorationStyle,
    setDB_LeftSidebar_MenuItem_TitelKontaktFormular_TextDecorationStyle,
  ] = useState("");

  // DB_LeftSidebar - TitelKontaktFormular Normal .-----------------.---------------------.----------------------
  const [
    DB_LeftSidebar_MenuItem_TitelKontaktFormular_FontSize,
    setDB_LeftSidebar_MenuItem_TitelKontaktFormular_FontSize,
  ] = useState(16);
  const DB_LeftSidebar_MenuItem_TitelKontaktFormular_FontFace =
    DB_fontFace_FontFamilyName;
  const [
    DB_LeftSidebar_MenuItem_TitelKontaktFormular_FontWeight,
    setDB_LeftSidebar_MenuItem_TitelKontaktFormular_FontWeight,
  ] = useState(400);
  const [
    DB_LeftSidebar_MenuItem_TitelKontaktFormular_FontColor,
    setDB_LeftSidebar_MenuItem_TitelKontaktFormular_FontColor,
  ] = useState("rgba(255,255,255,1)");

  const [
    DB_LeftSidebar_MenuItem_TitelKontaktFormular_FontColorNotEncoded,
    setDB_LeftSidebar_MenuItem_TitelKontaktFormular_FontColorNotEncoded,
  ] = useState("rgba(0,0,0,0.3)");

  const [
    DB_LeftSidebar_MenuItem_TitelKontaktFormular_BackgroundColor,
    setDB_LeftSidebar_MenuItem_TitelKontaktFormular_BackgroundColor,
  ] = useState("rgba(0,0,0,0.2)");
  const [
    DB_LeftSidebar_MenuItem_TitelKontaktFormular_BackgroundColorNotEncoded,
    setDB_LeftSidebar_MenuItem_TitelKontaktFormular_BackgroundColorNotEncoded,
  ] = useState("rgba(0,0,0,0.2)");

  const [
    DB_LeftSidebar_MenuItem_TitelKontaktFormular_TextAlign,
    setDB_LeftSidebar_MenuItem_TitelKontaktFormular_TextAlign,
  ] = useState("center");

  /////////////////////////////////
  const [
    DB_LeftSidebar_MenuItem_TitelKontaktFormular_BorderTopRightRadius,
    setDB_LeftSidebar_MenuItem_TitelKontaktFormular_BorderTopRightRadius,
  ] = useState(5);
  const [
    DB_LeftSidebar_MenuItem_TitelKontaktFormular_BorderTopLeftRadius,
    setDB_LeftSidebar_MenuItem_TitelKontaktFormular_BorderTopLeftRadius,
  ] = useState(5);
  const [
    DB_LeftSidebar_MenuItem_TitelKontaktFormular_BorderBottomRightRadius,
    setDB_LeftSidebar_MenuItem_TitelKontaktFormular_BorderBottomRightRadius,
  ] = useState(5);
  const [
    DB_LeftSidebar_MenuItem_TitelKontaktFormular_BorderBottomLeftRadius,
    setDB_LeftSidebar_MenuItem_TitelKontaktFormular_BorderBottomLeftRadius,
  ] = useState(5);

  const [
    DB_LeftSidebar_MenuItem_TitelKontaktFormular_BorderColorTop,
    setDB_LeftSidebar_MenuItem_TitelKontaktFormular_BorderColorTop,
  ] = useState("rgba(0,0,0,0.3)");
  const [
    DB_LeftSidebar_MenuItem_TitelKontaktFormular_BorderColorBottom,
    setDB_LeftSidebar_MenuItem_TitelKontaktFormular_BorderColorBottom,
  ] = useState("rgba(0,0,0,0.3)");
  const [
    DB_LeftSidebar_MenuItem_TitelKontaktFormular_BorderColorLeft,
    setDB_LeftSidebar_MenuItem_TitelKontaktFormular_BorderColorLeft,
  ] = useState("rgba(0,0,0,0.3)");
  const [
    DB_LeftSidebar_MenuItem_TitelKontaktFormular_BorderColorRight,
    setDB_LeftSidebar_MenuItem_TitelKontaktFormular_BorderColorRight,
  ] = useState("rgba(0,0,0,0.3)");
  const [
    DB_LeftSidebar_MenuItem_TitelKontaktFormular_BorderColorTopNotEncoded,
    setDB_LeftSidebar_MenuItem_TitelKontaktFormular_BorderColorTopNotEncoded,
  ] = useState("rgba(0,0,0,0.3)");
  const [
    DB_LeftSidebar_MenuItem_TitelKontaktFormular_BorderColorBottomNotEncoded,
    setDB_LeftSidebar_MenuItem_TitelKontaktFormular_BorderColorBottomNotEncoded,
  ] = useState("rgba(0,0,0,0.3)");
  const [
    DB_LeftSidebar_MenuItem_TitelKontaktFormular_BorderColorLeftNotEncoded,
    setDB_LeftSidebar_MenuItem_TitelKontaktFormular_BorderColorLeftNotEncoded,
  ] = useState("rgba(0,0,0,0.3)");
  const [
    DB_LeftSidebar_MenuItem_TitelKontaktFormular_BorderColorRightNotEncoded,
    setDB_LeftSidebar_MenuItem_TitelKontaktFormular_BorderColorRightNotEncoded,
  ] = useState("rgba(0,0,0,0.3)");

  const [
    DB_LeftSidebar_MenuItem_TitelKontaktFormular_BorderTop,
    setDB_LeftSidebar_MenuItem_TitelKontaktFormular_BorderTop,
  ] = useState(1);
  const [
    DB_LeftSidebar_MenuItem_TitelKontaktFormular_BorderBottom,
    setDB_LeftSidebar_MenuItem_TitelKontaktFormular_BorderBottom,
  ] = useState(1);
  const [
    DB_LeftSidebar_MenuItem_TitelKontaktFormular_BorderLeft,
    setDB_LeftSidebar_MenuItem_TitelKontaktFormular_BorderLeft,
  ] = useState(1);
  const [
    DB_LeftSidebar_MenuItem_TitelKontaktFormular_BorderRight,
    setDB_LeftSidebar_MenuItem_TitelKontaktFormular_BorderRight,
  ] = useState(1);

  const [
    DB_LeftSidebar_MenuItem_TitelKontaktFormular_BorderStyleTop,
    setDB_LeftSidebar_MenuItem_TitelKontaktFormular_BorderStyleTop,
  ] = useState("solid");
  const [
    DB_LeftSidebar_MenuItem_TitelKontaktFormular_BorderStyleBottom,
    setDB_LeftSidebar_MenuItem_TitelKontaktFormular_BorderStyleBottom,
  ] = useState("solid");
  const [
    DB_LeftSidebar_MenuItem_TitelKontaktFormular_BorderStyleLeft,
    setDB_LeftSidebar_MenuItem_TitelKontaktFormular_BorderStyleLeft,
  ] = useState("solid");
  const [
    DB_LeftSidebar_MenuItem_TitelKontaktFormular_BorderStyleRight,
    setDB_LeftSidebar_MenuItem_TitelKontaktFormular_BorderStyleRight,
  ] = useState("solid");

  const [
    DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_marginTopBottom,
    setDB_LeftSidebar_MenuItem_TitelDetailBeschreibung_marginTopBottom,
  ] = useState("5");
  const [
    DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_marginLeftRight,
    setDB_LeftSidebar_MenuItem_TitelDetailBeschreibung_marginLeftRight,
  ] = useState("5");

  const [
    DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_paddingLeftRight,
    setDB_LeftSidebar_MenuItem_TitelDetailBeschreibung_paddingLeftRight,
  ] = useState("5");

  const [
    DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_paddingTopBottom,
    setDB_LeftSidebar_MenuItem_TitelDetailBeschreibung_paddingTopBottom,
  ] = useState("5");

  const [
    DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_letterSpacing,
    setDB_LeftSidebar_MenuItem_TitelDetailBeschreibung_letterSpacing,
  ] = useState("0");

  const [
    DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_ShadowHorizontal,
    setDB_LeftSidebar_MenuItem_TitelDetailBeschreibung_ShadowHorizontal,
  ] = useState(1);
  const [
    DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_ShadowVertical,
    setDB_LeftSidebar_MenuItem_TitelDetailBeschreibung_ShadowVertical,
  ] = useState(1);
  const [
    DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_ShadowBlur,
    setDB_LeftSidebar_MenuItem_TitelDetailBeschreibung_ShadowBlur,
  ] = useState(5);
  const [
    DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_ShadowColor,
    setDB_LeftSidebar_MenuItem_TitelDetailBeschreibung_ShadowColor,
  ] = useState("rgba(255,255,255,1)");

  const [
    DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_ShadowColorNotEncoded,
    setDB_LeftSidebar_MenuItem_TitelDetailBeschreibung_ShadowColorNotEncoded,
  ] = useState("rgba(255,255,255,1)");

  const [
    DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_TextDecoration,
    setDB_LeftSidebar_MenuItem_TitelDetailBeschreibung_TextDecoration,
  ] = useState("normal");

  const [
    DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_TextDecorationStyle,
    setDB_LeftSidebar_MenuItem_TitelDetailBeschreibung_TextDecorationStyle,
  ] = useState("");

  // DB_LeftSidebar - TitelDetailBeschreibung Normal .-----------------.---------------------.----------------------
  const [
    DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_FontSize,
    setDB_LeftSidebar_MenuItem_TitelDetailBeschreibung_FontSize,
  ] = useState(16);
  const DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_FontFace =
    DB_fontFace_FontFamilyName;
  const [
    DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_FontWeight,
    setDB_LeftSidebar_MenuItem_TitelDetailBeschreibung_FontWeight,
  ] = useState(400);
  const [
    DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_FontColor,
    setDB_LeftSidebar_MenuItem_TitelDetailBeschreibung_FontColor,
  ] = useState("rgba(255,255,255,1)");

  const [
    DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_FontColorNotEncoded,
    setDB_LeftSidebar_MenuItem_TitelDetailBeschreibung_FontColorNotEncoded,
  ] = useState("rgba(0,0,0,0.3)");

  const [
    DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BackgroundColor,
    setDB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BackgroundColor,
  ] = useState("rgba(0,0,0,0.2)");
  const [
    DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BackgroundColorNotEncoded,
    setDB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BackgroundColorNotEncoded,
  ] = useState("rgba(0,0,0,0.2)");

  const [
    DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_TextAlign,
    setDB_LeftSidebar_MenuItem_TitelDetailBeschreibung_TextAlign,
  ] = useState("center");

  /////////////////////////////////
  const [
    DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderTopRightRadius,
    setDB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderTopRightRadius,
  ] = useState(5);
  const [
    DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderTopLeftRadius,
    setDB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderTopLeftRadius,
  ] = useState(5);
  const [
    DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderBottomRightRadius,
    setDB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderBottomRightRadius,
  ] = useState(5);
  const [
    DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderBottomLeftRadius,
    setDB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderBottomLeftRadius,
  ] = useState(5);

  const [
    DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderColorTop,
    setDB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderColorTop,
  ] = useState("rgba(0,0,0,0.3)");
  const [
    DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderColorBottom,
    setDB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderColorBottom,
  ] = useState("rgba(0,0,0,0.3)");
  const [
    DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderColorLeft,
    setDB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderColorLeft,
  ] = useState("rgba(0,0,0,0.3)");
  const [
    DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderColorRight,
    setDB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderColorRight,
  ] = useState("rgba(0,0,0,0.3)");
  const [
    DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderColorTopNotEncoded,
    setDB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderColorTopNotEncoded,
  ] = useState("rgba(0,0,0,0.3)");
  const [
    DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderColorBottomNotEncoded,
    setDB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderColorBottomNotEncoded,
  ] = useState("rgba(0,0,0,0.3)");
  const [
    DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderColorLeftNotEncoded,
    setDB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderColorLeftNotEncoded,
  ] = useState("rgba(0,0,0,0.3)");
  const [
    DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderColorRightNotEncoded,
    setDB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderColorRightNotEncoded,
  ] = useState("rgba(0,0,0,0.3)");

  const [
    DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderTop,
    setDB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderTop,
  ] = useState(1);
  const [
    DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderBottom,
    setDB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderBottom,
  ] = useState(1);
  const [
    DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderLeft,
    setDB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderLeft,
  ] = useState(1);
  const [
    DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderRight,
    setDB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderRight,
  ] = useState(1);

  const [
    DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderStyleTop,
    setDB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderStyleTop,
  ] = useState("solid");
  const [
    DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderStyleBottom,
    setDB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderStyleBottom,
  ] = useState("solid");
  const [
    DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderStyleLeft,
    setDB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderStyleLeft,
  ] = useState("solid");
  const [
    DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderStyleRight,
    setDB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderStyleRight,
  ] = useState("solid");

  const [
    DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_marginTopBottom,
    setDB_LeftSidebar_MenuItem_TitelDetailHauptTitel_marginTopBottom,
  ] = useState("5");
  const [
    DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_marginLeftRight,
    setDB_LeftSidebar_MenuItem_TitelDetailHauptTitel_marginLeftRight,
  ] = useState("5");

  const [
    DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_paddingLeftRight,
    setDB_LeftSidebar_MenuItem_TitelDetailHauptTitel_paddingLeftRight,
  ] = useState("5");

  const [
    DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_paddingTopBottom,
    setDB_LeftSidebar_MenuItem_TitelDetailHauptTitel_paddingTopBottom,
  ] = useState("5");

  const [
    DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_letterSpacing,
    setDB_LeftSidebar_MenuItem_TitelDetailHauptTitel_letterSpacing,
  ] = useState("0");

  const [
    DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_ShadowHorizontal,
    setDB_LeftSidebar_MenuItem_TitelDetailHauptTitel_ShadowHorizontal,
  ] = useState(1);
  const [
    DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_ShadowVertical,
    setDB_LeftSidebar_MenuItem_TitelDetailHauptTitel_ShadowVertical,
  ] = useState(1);
  const [
    DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_ShadowBlur,
    setDB_LeftSidebar_MenuItem_TitelDetailHauptTitel_ShadowBlur,
  ] = useState(5);
  const [
    DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_ShadowColor,
    setDB_LeftSidebar_MenuItem_TitelDetailHauptTitel_ShadowColor,
  ] = useState("rgba(255,255,255,1)");

  const [
    DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_ShadowColorNotEncoded,
    setDB_LeftSidebar_MenuItem_TitelDetailHauptTitel_ShadowColorNotEncoded,
  ] = useState("rgba(255,255,255,1)");

  const [
    DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_TextDecoration,
    setDB_LeftSidebar_MenuItem_TitelDetailHauptTitel_TextDecoration,
  ] = useState("normal");

  const [
    DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_TextDecorationStyle,
    setDB_LeftSidebar_MenuItem_TitelDetailHauptTitel_TextDecorationStyle,
  ] = useState("");

  // DB_LeftSidebar - TitelDetailHauptTitel Normal .-----------------.---------------------.----------------------
  const [
    DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_FontSize,
    setDB_LeftSidebar_MenuItem_TitelDetailHauptTitel_FontSize,
  ] = useState(16);
  const DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_FontFace =
    DB_fontFace_FontFamilyName;
  const [
    DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_FontWeight,
    setDB_LeftSidebar_MenuItem_TitelDetailHauptTitel_FontWeight,
  ] = useState(400);
  const [
    DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_FontColor,
    setDB_LeftSidebar_MenuItem_TitelDetailHauptTitel_FontColor,
  ] = useState("rgba(255,255,255,1)");

  const [
    DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_FontColorNotEncoded,
    setDB_LeftSidebar_MenuItem_TitelDetailHauptTitel_FontColorNotEncoded,
  ] = useState("rgba(0,0,0,0.3)");

  const [
    DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BackgroundColor,
    setDB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BackgroundColor,
  ] = useState("rgba(0,0,0,0.2)");
  const [
    DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BackgroundColorNotEncoded,
    setDB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BackgroundColorNotEncoded,
  ] = useState("rgba(0,0,0,0.2)");

  const [
    DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_TextAlign,
    setDB_LeftSidebar_MenuItem_TitelDetailHauptTitel_TextAlign,
  ] = useState("center");

  /////////////////////////////////
  const [
    DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderTopRightRadius,
    setDB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderTopRightRadius,
  ] = useState(5);
  const [
    DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderTopLeftRadius,
    setDB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderTopLeftRadius,
  ] = useState(5);
  const [
    DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderBottomRightRadius,
    setDB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderBottomRightRadius,
  ] = useState(5);
  const [
    DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderBottomLeftRadius,
    setDB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderBottomLeftRadius,
  ] = useState(5);

  const [
    DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderColorTop,
    setDB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderColorTop,
  ] = useState("rgba(0,0,0,0.3)");
  const [
    DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderColorBottom,
    setDB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderColorBottom,
  ] = useState("rgba(0,0,0,0.3)");
  const [
    DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderColorLeft,
    setDB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderColorLeft,
  ] = useState("rgba(0,0,0,0.3)");
  const [
    DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderColorRight,
    setDB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderColorRight,
  ] = useState("rgba(0,0,0,0.3)");
  const [
    DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderColorTopNotEncoded,
    setDB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderColorTopNotEncoded,
  ] = useState("rgba(0,0,0,0.3)");
  const [
    DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderColorBottomNotEncoded,
    setDB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderColorBottomNotEncoded,
  ] = useState("rgba(0,0,0,0.3)");
  const [
    DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderColorLeftNotEncoded,
    setDB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderColorLeftNotEncoded,
  ] = useState("rgba(0,0,0,0.3)");
  const [
    DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderColorRightNotEncoded,
    setDB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderColorRightNotEncoded,
  ] = useState("rgba(0,0,0,0.3)");

  const [
    DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderTop,
    setDB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderTop,
  ] = useState(1);
  const [
    DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderBottom,
    setDB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderBottom,
  ] = useState(1);
  const [
    DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderLeft,
    setDB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderLeft,
  ] = useState(1);
  const [
    DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderRight,
    setDB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderRight,
  ] = useState(1);

  const [
    DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderStyleTop,
    setDB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderStyleTop,
  ] = useState("solid");
  const [
    DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderStyleBottom,
    setDB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderStyleBottom,
  ] = useState("solid");
  const [
    DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderStyleLeft,
    setDB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderStyleLeft,
  ] = useState("solid");
  const [
    DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderStyleRight,
    setDB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderStyleRight,
  ] = useState("solid");

  const [
    DB_fontFace_hauptMenueStatic_FontFamilyName,
    setDB_fontFace_hauptMenueStatic_FontFamilyName,
  ] = useState("Lato");
  const [
    DB_fontFace_hauptMenueStatic_FontFamilySrc,
    setDB_fontFace_hauptMenueStatic_FontFamilySrc,
  ] = useState("/assets/Lato/Lato-Regular.ttf");

  const [
    DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_FontSize,
    setDB_LeftSidebar_MenuItem_HauptmenuTop_Hover_FontSize,
  ] = useState(18);
  const DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_FontFace =
    DB_fontFace_FontFamilyName;
  const [
    DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_FontWeight,
    setDB_LeftSidebar_MenuItem_HauptmenuTop_Hover_FontWeight,
  ] = useState(400);
  const [
    DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_FontColor,
    setDB_LeftSidebar_MenuItem_HauptmenuTop_Hover_FontColor,
  ] = useState("rgba(255,255,255,1)");

  const [
    DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_FontColorNotEncoded,
    setDB_LeftSidebar_MenuItem_HauptmenuTop_Hover_FontColorNotEncoded,
  ] = useState("rgba(0,0,0,0.3)");

  const [
    DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BackgroundColor,
    setDB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BackgroundColor,
  ] = useState("rgba(0,0,0,0.3)");
  const [
    DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BackgroundColorNotEncoded,
    setDB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BackgroundColorNotEncoded,
  ] = useState("rgba(0,0,0,0.3)");

  const [
    DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_TextAlign,
    setDB_LeftSidebar_MenuItem_HauptmenuTop_Hover_TextAlign,
  ] = useState("center");

  /////////////////////////////////
  const [
    DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderTopRightRadius,
    setDB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderTopRightRadius,
  ] = useState(0);
  const [
    DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderTopLeftRadius,
    setDB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderTopLeftRadius,
  ] = useState(0);
  const [
    DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderBottomRightRadius,
    setDB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderBottomRightRadius,
  ] = useState(0);
  const [
    DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderBottomLeftRadius,
    setDB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderBottomLeftRadius,
  ] = useState(0);

  const [
    DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderColorTop,
    setDB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderColorTop,
  ] = useState("rgba(255,255,255,1)");
  const [
    DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderColorBottom,
    setDB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderColorBottom,
  ] = useState("rgba(255,255,255,1)");
  const [
    DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderColorLeft,
    setDB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderColorLeft,
  ] = useState("rgba(255,255,255,1)");
  const [
    DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderColorRight,
    setDB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderColorRight,
  ] = useState("rgba(255,255,255,1)");

  const [
    DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderColorTopNotEncoded,
    setDB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderColorTopNotEncoded,
  ] = useState("rgba(255,255,255,1)");
  const [
    DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderColorBottomNotEncoded,
    setDB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderColorBottomNotEncoded,
  ] = useState("rgba(255,255,255,1)");
  const [
    DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderColorLeftNotEncoded,
    setDB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderColorLeftNotEncoded,
  ] = useState("rgba(255,255,255,1)");
  const [
    DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderColorRightNotEncoded,
    setDB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderColorRightNotEncoded,
  ] = useState("rgba(255,255,255,1)");

  const [
    DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderTop,
    setDB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderTop,
  ] = useState(1);
  const [
    DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderBottom,
    setDB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderBottom,
  ] = useState(1);
  const [
    DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderLeft,
    setDB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderLeft,
  ] = useState(0);
  const [
    DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderRight,
    setDB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderRight,
  ] = useState(0);

  const [
    DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderStyleTop,
    setDB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderStyleTop,
  ] = useState("solid");
  const [
    DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderStyleBottom,
    setDB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderStyleBottom,
  ] = useState("solid");
  const [
    DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderStyleLeft,
    setDB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderStyleLeft,
  ] = useState("solid");
  const [
    DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderStyleRight,
    setDB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderStyleRight,
  ] = useState("solid");

  const DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_HoverBackgroundColor =
    "rgba(255,255,255,0.3)";
  const DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_HoverFontColor =
    "rgba(255,255,255,1)";
  // DB_LeftSidebar - HauptTopStaticY Normal .-----------------.---------------------.----------------------
  const [
    DB_LeftSidebar_MenuItem_HauptmenuTop_FontSize,
    setDB_LeftSidebar_MenuItem_HauptmenuTop_FontSize,
  ] = useState(18);
  const DB_LeftSidebar_MenuItem_HauptmenuTop_FontFace =
    DB_fontFace_FontFamilyName;
  const [
    DB_LeftSidebar_MenuItem_HauptmenuTop_FontWeight,
    setDB_LeftSidebar_MenuItem_HauptmenuTop_FontWeight,
  ] = useState(400);
  const [
    DB_LeftSidebar_MenuItem_HauptmenuTop_FontColor,
    setDB_LeftSidebar_MenuItem_HauptmenuTop_FontColor,
  ] = useState("rgba(255,255,255,1)");

  const [
    DB_LeftSidebar_MenuItem_HauptmenuTop_FontColorNotEncoded,
    setDB_LeftSidebar_MenuItem_HauptmenuTop_FontColorNotEncoded,
  ] = useState("rgba(0,0,0,0.3)");

  const [
    DB_LeftSidebar_MenuItem_HauptmenuTop_BackgroundColor,
    setDB_LeftSidebar_MenuItem_HauptmenuTop_BackgroundColor,
  ] = useState("rgba(0,0,0,0.3)");
  const [
    DB_LeftSidebar_MenuItem_HauptmenuTop_BackgroundColorNotEncoded,
    setDB_LeftSidebar_MenuItem_HauptmenuTop_BackgroundColorNotEncoded,
  ] = useState("rgba(0,0,0,0.3)");

  const [
    DB_LeftSidebar_MenuItem_HauptmenuTop_TextAlign,
    setDB_LeftSidebar_MenuItem_HauptmenuTop_TextAlign,
  ] = useState("center");

  /////////////////////////////////
  const [
    DB_LeftSidebar_MenuItem_HauptmenuTop_BorderTopRightRadius,
    setDB_LeftSidebar_MenuItem_HauptmenuTop_BorderTopRightRadius,
  ] = useState(0);
  const [
    DB_LeftSidebar_MenuItem_HauptmenuTop_BorderTopLeftRadius,
    setDB_LeftSidebar_MenuItem_HauptmenuTop_BorderTopLeftRadius,
  ] = useState(0);
  const [
    DB_LeftSidebar_MenuItem_HauptmenuTop_BorderBottomRightRadius,
    setDB_LeftSidebar_MenuItem_HauptmenuTop_BorderBottomRightRadius,
  ] = useState(0);
  const [
    DB_LeftSidebar_MenuItem_HauptmenuTop_BorderBottomLeftRadius,
    setDB_LeftSidebar_MenuItem_HauptmenuTop_BorderBottomLeftRadius,
  ] = useState(0);

  const [
    DB_LeftSidebar_MenuItem_HauptmenuTop_BorderColorTop,
    setDB_LeftSidebar_MenuItem_HauptmenuTop_BorderColorTop,
  ] = useState("rgba(255,255,255,1)");
  const [
    DB_LeftSidebar_MenuItem_HauptmenuTop_BorderColorBottom,
    setDB_LeftSidebar_MenuItem_HauptmenuTop_BorderColorBottom,
  ] = useState("rgba(255,255,255,1)");
  const [
    DB_LeftSidebar_MenuItem_HauptmenuTop_BorderColorLeft,
    setDB_LeftSidebar_MenuItem_HauptmenuTop_BorderColorLeft,
  ] = useState("rgba(255,255,255,1)");
  const [
    DB_LeftSidebar_MenuItem_HauptmenuTop_BorderColorRight,
    setDB_LeftSidebar_MenuItem_HauptmenuTop_BorderColorRight,
  ] = useState("rgba(255,255,255,1)");

  const [
    DB_LeftSidebar_MenuItem_HauptmenuTop_BorderColorTopNotEncoded,
    setDB_LeftSidebar_MenuItem_HauptmenuTop_BorderColorTopNotEncoded,
  ] = useState("rgba(255,255,255,1)");
  const [
    DB_LeftSidebar_MenuItem_HauptmenuTop_BorderColorBottomNotEncoded,
    setDB_LeftSidebar_MenuItem_HauptmenuTop_BorderColorBottomNotEncoded,
  ] = useState("rgba(255,255,255,1)");
  const [
    DB_LeftSidebar_MenuItem_HauptmenuTop_BorderColorLeftNotEncoded,
    setDB_LeftSidebar_MenuItem_HauptmenuTop_BorderColorLeftNotEncoded,
  ] = useState("rgba(255,255,255,1)");
  const [
    DB_LeftSidebar_MenuItem_HauptmenuTop_BorderColorRightNotEncoded,
    setDB_LeftSidebar_MenuItem_HauptmenuTop_BorderColorRightNotEncoded,
  ] = useState("rgba(255,255,255,1)");

  const [
    DB_LeftSidebar_MenuItem_HauptmenuTop_BorderTop,
    setDB_LeftSidebar_MenuItem_HauptmenuTop_BorderTop,
  ] = useState(1);
  const [
    DB_LeftSidebar_MenuItem_HauptmenuTop_BorderBottom,
    setDB_LeftSidebar_MenuItem_HauptmenuTop_BorderBottom,
  ] = useState(1);
  const [
    DB_LeftSidebar_MenuItem_HauptmenuTop_BorderLeft,
    setDB_LeftSidebar_MenuItem_HauptmenuTop_BorderLeft,
  ] = useState(0);
  const [
    DB_LeftSidebar_MenuItem_HauptmenuTop_BorderRight,
    setDB_LeftSidebar_MenuItem_HauptmenuTop_BorderRight,
  ] = useState(0);

  const [
    DB_LeftSidebar_MenuItem_HauptmenuTop_BorderStyleTop,
    setDB_LeftSidebar_MenuItem_HauptmenuTop_BorderStyleTop,
  ] = useState("solid");
  const [
    DB_LeftSidebar_MenuItem_HauptmenuTop_BorderStyleBottom,
    setDB_LeftSidebar_MenuItem_HauptmenuTop_BorderStyleBottom,
  ] = useState("solid");
  const [
    DB_LeftSidebar_MenuItem_HauptmenuTop_BorderStyleLeft,
    setDB_LeftSidebar_MenuItem_HauptmenuTop_BorderStyleLeft,
  ] = useState("solid");
  const [
    DB_LeftSidebar_MenuItem_HauptmenuTop_BorderStyleRight,
    setDB_LeftSidebar_MenuItem_HauptmenuTop_BorderStyleRight,
  ] = useState("solid");

  const DB_LeftSidebar_MenuItem_HauptmenuTop_HoverBackgroundColor =
    "rgba(255,255,255,0.3)";
  const DB_LeftSidebar_MenuItem_HauptmenuTop_HoverFontColor =
    "rgba(255,255,255,1)";

  const [
    DB_LeftSidebar_MenuItem_HauptmenuTop_marginTopBottom,
    setDB_LeftSidebar_MenuItem_HauptmenuTop_marginTopBottom,
  ] = useState("5");
  const [
    DB_LeftSidebar_MenuItem_HauptmenuTop_marginLeftRight,
    setDB_LeftSidebar_MenuItem_HauptmenuTop_marginLeftRight,
  ] = useState("5");

  const [
    DB_LeftSidebar_MenuItem_HauptmenuTop_paddingLeftRight,
    setDB_LeftSidebar_MenuItem_HauptmenuTop_paddingLeftRight,
  ] = useState("5");

  const [
    DB_LeftSidebar_MenuItem_HauptmenuTop_paddingTopBottom,
    setDB_LeftSidebar_MenuItem_HauptmenuTop_paddingTopBottom,
  ] = useState("5");

  const [
    DB_LeftSidebar_MenuItem_HauptmenuTop_letterSpacing,
    setDB_LeftSidebar_MenuItem_HauptmenuTop_letterSpacing,
  ] = useState("0");

  const [
    DB_LeftSidebar_MenuItem_HauptmenuTop_marginTopBottom_Hover,
    setDB_LeftSidebar_MenuItem_HauptmenuTop_marginTopBottom_Hover,
  ] = useState("5");
  const [
    DB_LeftSidebar_MenuItem_HauptmenuTop_marginLeftRight_Hover,
    setDB_LeftSidebar_MenuItem_HauptmenuTop_marginLeftRight_Hover,
  ] = useState("5");

  const [
    DB_LeftSidebar_MenuItem_HauptmenuTop_paddingLeftRight_Hover,
    setDB_LeftSidebar_MenuItem_HauptmenuTop_paddingLeftRight_Hover,
  ] = useState("5");

  const [
    DB_LeftSidebar_MenuItem_HauptmenuTop_paddingTopBottom_Hover,
    setDB_LeftSidebar_MenuItem_HauptmenuTop_paddingTopBottom_Hover,
  ] = useState("5");

  const [
    DB_LeftSidebar_MenuItem_HauptmenuTop_letterSpacing_Hover,
    setDB_LeftSidebar_MenuItem_HauptmenuTop_letterSpacing_Hover,
  ] = useState("0");

  const [
    DB_LeftSidebar_MenuItem_HauptmenuTop_ShadowHorizontal,
    setDB_LeftSidebar_MenuItem_HauptmenuTop_ShadowHorizontal,
  ] = useState(1);
  const [
    DB_LeftSidebar_MenuItem_HauptmenuTop_ShadowVertical,
    setDB_LeftSidebar_MenuItem_HauptmenuTop_ShadowVertical,
  ] = useState(1);
  const [
    DB_LeftSidebar_MenuItem_HauptmenuTop_ShadowBlur,
    setDB_LeftSidebar_MenuItem_HauptmenuTop_ShadowBlur,
  ] = useState(5);
  const [
    DB_LeftSidebar_MenuItem_HauptmenuTop_ShadowColor,
    setDB_LeftSidebar_MenuItem_HauptmenuTop_ShadowColor,
  ] = useState("rgba(255,255,255,1)");

  const [
    DB_LeftSidebar_MenuItem_HauptmenuTop_ShadowColorNotEncoded,
    setDB_LeftSidebar_MenuItem_HauptmenuTop_ShadowColorNotEncoded,
  ] = useState("rgba(255,255,255,1)");

  const [
    DB_LeftSidebar_MenuItem_HauptmenuTop_TextDecoration,
    setDB_LeftSidebar_MenuItem_HauptmenuTop_TextDecoration,
  ] = useState("normal");

  const [
    DB_LeftSidebar_MenuItem_HauptmenuTop_TextDecorationStyle,
    setDB_LeftSidebar_MenuItem_HauptmenuTop_TextDecorationStyle,
  ] = useState("");

  const [
    DB_LeftSidebar_MenuItem_HauptmenuTop_ShadowHorizontal_Hover,
    setDB_LeftSidebar_MenuItem_HauptmenuTop_ShadowHorizontal_Hover,
  ] = useState(1);
  const [
    DB_LeftSidebar_MenuItem_HauptmenuTop_ShadowVertical_Hover,
    setDB_LeftSidebar_MenuItem_HauptmenuTop_ShadowVertical_Hover,
  ] = useState(1);
  const [
    DB_LeftSidebar_MenuItem_HauptmenuTop_ShadowBlur_Hover,
    setDB_LeftSidebar_MenuItem_HauptmenuTop_ShadowBlur_Hover,
  ] = useState(5);
  const [
    DB_LeftSidebar_MenuItem_HauptmenuTop_ShadowColor_Hover,
    setDB_LeftSidebar_MenuItem_HauptmenuTop_ShadowColor_Hover,
  ] = useState("rgba(255,255,255,1)");
  const [
    DB_LeftSidebar_MenuItem_HauptmenuTop_TextDecoration_Hover,
    setDB_LeftSidebar_MenuItem_HauptmenuTop_TextDecoration_Hover,
  ] = useState("none");
  const [
    DB_LeftSidebar_MenuItem_HauptmenuTop_TextDecorationStyle_Hover,
    setDB_LeftSidebar_MenuItem_HauptmenuTop_TextDecorationStyle_Hover,
  ] = useState("");

  const [
    DB_LeftSidebar_MenuItem_HauptmenuTop_ShadowColorNotEncoded_Hover,
    setDB_LeftSidebar_MenuItem_HauptmenuTop_ShadowColorNotEncoded_Hover,
  ] = useState("rgba(255,255,255,1)");

  const [
    DB_fontFace_hauptMenueTopAA_FontFamilyName,
    setDB_fontFace_hauptMenueTopAA_FontFamilyName,
  ] = useState("Lato");
  const [
    DB_fontFace_hauptMenueTopAA_FontFamilySrc,
    setDB_fontFace_hauptMenueTopAA_FontFamilySrc,
  ] = useState("/assets/Lato/Lato-Regular.ttf");

  const [
    DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_FontSize,
    setDB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_FontSize,
  ] = useState(18);
  const DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_FontFace =
    DB_fontFace_FontFamilyName;
  const [
    DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_FontWeight,
    setDB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_FontWeight,
  ] = useState(400);
  const [
    DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_FontColor,
    setDB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_FontColor,
  ] = useState("rgba(255,255,255,1)");

  const [
    DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_FontColorNotEncoded,
    setDB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_FontColorNotEncoded,
  ] = useState("rgba(0,0,0,0.3)");

  const [
    DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BackgroundColor,
    setDB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BackgroundColor,
  ] = useState("rgba(0,0,0,0.3)");
  const [
    DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BackgroundColorNotEncoded,
    setDB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BackgroundColorNotEncoded,
  ] = useState("rgba(0,0,0,0.3)");

  const [
    DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_TextAlign,
    setDB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_TextAlign,
  ] = useState("center");

  /////////////////////////////////
  const [
    DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderTopRightRadius,
    setDB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderTopRightRadius,
  ] = useState(0);
  const [
    DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderTopLeftRadius,
    setDB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderTopLeftRadius,
  ] = useState(0);
  const [
    DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderBottomRightRadius,
    setDB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderBottomRightRadius,
  ] = useState(0);
  const [
    DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderBottomLeftRadius,
    setDB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderBottomLeftRadius,
  ] = useState(0);

  const [
    DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderColorTop,
    setDB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderColorTop,
  ] = useState("rgba(255,255,255,1)");
  const [
    DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderColorBottom,
    setDB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderColorBottom,
  ] = useState("rgba(255,255,255,1)");
  const [
    DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderColorLeft,
    setDB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderColorLeft,
  ] = useState("rgba(255,255,255,1)");
  const [
    DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderColorRight,
    setDB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderColorRight,
  ] = useState("rgba(255,255,255,1)");

  const [
    DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderColorTopNotEncoded,
    setDB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderColorTopNotEncoded,
  ] = useState("rgba(255,255,255,1)");
  const [
    DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderColorBottomNotEncoded,
    setDB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderColorBottomNotEncoded,
  ] = useState("rgba(255,255,255,1)");
  const [
    DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderColorLeftNotEncoded,
    setDB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderColorLeftNotEncoded,
  ] = useState("rgba(255,255,255,1)");
  const [
    DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderColorRightNotEncoded,
    setDB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderColorRightNotEncoded,
  ] = useState("rgba(255,255,255,1)");

  const [
    DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderTop,
    setDB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderTop,
  ] = useState(1);
  const [
    DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderBottom,
    setDB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderBottom,
  ] = useState(1);
  const [
    DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderLeft,
    setDB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderLeft,
  ] = useState(0);
  const [
    DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderRight,
    setDB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderRight,
  ] = useState(0);

  const [
    DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderStyleTop,
    setDB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderStyleTop,
  ] = useState("solid");
  const [
    DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderStyleBottom,
    setDB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderStyleBottom,
  ] = useState("solid");
  const [
    DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderStyleLeft,
    setDB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderStyleLeft,
  ] = useState("solid");
  const [
    DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderStyleRight,
    setDB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderStyleRight,
  ] = useState("solid");

  const DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_HoverBackgroundColor =
    "rgba(255,255,255,0.3)";
  const DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_HoverFontColor =
    "rgba(255,255,255,1)";

  // DB_LeftSidebar - Hauptkategorie Normal .-----------------.---------------------.----------------------
  const [
    DB_LeftSidebar_MenuItem_HauptmenuTopAA_FontSize,
    setDB_LeftSidebar_MenuItem_HauptmenuTopAA_FontSize,
  ] = useState(18);
  const DB_LeftSidebar_MenuItem_HauptmenuTopAA_FontFace =
    DB_fontFace_FontFamilyName;
  const [
    DB_LeftSidebar_MenuItem_HauptmenuTopAA_FontWeight,
    setDB_LeftSidebar_MenuItem_HauptmenuTopAA_FontWeight,
  ] = useState(400);
  const [
    DB_LeftSidebar_MenuItem_HauptmenuTopAA_FontColor,
    setDB_LeftSidebar_MenuItem_HauptmenuTopAA_FontColor,
  ] = useState("rgba(255,255,255,1)");

  const [
    DB_LeftSidebar_MenuItem_HauptmenuTopAA_FontColorNotEncoded,
    setDB_LeftSidebar_MenuItem_HauptmenuTopAA_FontColorNotEncoded,
  ] = useState("rgba(0,0,0,0.3)");

  const [
    DB_LeftSidebar_MenuItem_HauptmenuTopAA_BackgroundColor,
    setDB_LeftSidebar_MenuItem_HauptmenuTopAA_BackgroundColor,
  ] = useState("rgba(0,0,0,0.3)");
  const [
    DB_LeftSidebar_MenuItem_HauptmenuTopAA_BackgroundColorNotEncoded,
    setDB_LeftSidebar_MenuItem_HauptmenuTopAA_BackgroundColorNotEncoded,
  ] = useState("rgba(0,0,0,0.3)");

  const [
    DB_LeftSidebar_MenuItem_HauptmenuTopAA_TextAlign,
    setDB_LeftSidebar_MenuItem_HauptmenuTopAA_TextAlign,
  ] = useState("center");

  /////////////////////////////////
  const [
    DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderTopRightRadius,
    setDB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderTopRightRadius,
  ] = useState(0);
  const [
    DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderTopLeftRadius,
    setDB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderTopLeftRadius,
  ] = useState(0);
  const [
    DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderBottomRightRadius,
    setDB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderBottomRightRadius,
  ] = useState(0);
  const [
    DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderBottomLeftRadius,
    setDB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderBottomLeftRadius,
  ] = useState(0);

  const [
    DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderColorTop,
    setDB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderColorTop,
  ] = useState("rgba(255,255,255,1)");
  const [
    DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderColorBottom,
    setDB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderColorBottom,
  ] = useState("rgba(255,255,255,1)");
  const [
    DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderColorLeft,
    setDB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderColorLeft,
  ] = useState("rgba(255,255,255,1)");
  const [
    DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderColorRight,
    setDB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderColorRight,
  ] = useState("rgba(255,255,255,1)");

  const [
    DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderColorTopNotEncoded,
    setDB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderColorTopNotEncoded,
  ] = useState("rgba(255,255,255,1)");
  const [
    DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderColorBottomNotEncoded,
    setDB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderColorBottomNotEncoded,
  ] = useState("rgba(255,255,255,1)");
  const [
    DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderColorLeftNotEncoded,
    setDB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderColorLeftNotEncoded,
  ] = useState("rgba(255,255,255,1)");
  const [
    DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderColorRightNotEncoded,
    setDB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderColorRightNotEncoded,
  ] = useState("rgba(255,255,255,1)");

  const [
    DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderTop,
    setDB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderTop,
  ] = useState(1);
  const [
    DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderBottom,
    setDB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderBottom,
  ] = useState(1);
  const [
    DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderLeft,
    setDB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderLeft,
  ] = useState(0);
  const [
    DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderRight,
    setDB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderRight,
  ] = useState(0);

  const [
    DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderStyleTop,
    setDB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderStyleTop,
  ] = useState("solid");
  const [
    DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderStyleBottom,
    setDB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderStyleBottom,
  ] = useState("solid");
  const [
    DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderStyleLeft,
    setDB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderStyleLeft,
  ] = useState("solid");
  const [
    DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderStyleRight,
    setDB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderStyleRight,
  ] = useState("solid");

  const DB_LeftSidebar_MenuItem_HauptmenuTopAA_HoverBackgroundColor =
    "rgba(255,255,255,0.3)";
  const DB_LeftSidebar_MenuItem_HauptmenuTopAA_HoverFontColor =
    "rgba(255,255,255,1)";

  const [
    DB_LeftSidebar_MenuItem_HauptmenuTopAA_marginTopBottom,
    setDB_LeftSidebar_MenuItem_HauptmenuTopAA_marginTopBottom,
  ] = useState("5");
  const [
    DB_LeftSidebar_MenuItem_HauptmenuTopAA_marginLeftRight,
    setDB_LeftSidebar_MenuItem_HauptmenuTopAA_marginLeftRight,
  ] = useState("5");

  const [
    DB_LeftSidebar_MenuItem_HauptmenuTopAA_paddingLeftRight,
    setDB_LeftSidebar_MenuItem_HauptmenuTopAA_paddingLeftRight,
  ] = useState("5");

  const [
    DB_LeftSidebar_MenuItem_HauptmenuTopAA_paddingTopBottom,
    setDB_LeftSidebar_MenuItem_HauptmenuTopAA_paddingTopBottom,
  ] = useState("5");

  const [
    DB_LeftSidebar_MenuItem_HauptmenuTopAA_letterSpacing,
    setDB_LeftSidebar_MenuItem_HauptmenuTopAA_letterSpacing,
  ] = useState("0");

  const [
    DB_LeftSidebar_MenuItem_HauptmenuTopAA_marginTopBottom_Hover,
    setDB_LeftSidebar_MenuItem_HauptmenuTopAA_marginTopBottom_Hover,
  ] = useState("5");
  const [
    DB_LeftSidebar_MenuItem_HauptmenuTopAA_marginLeftRight_Hover,
    setDB_LeftSidebar_MenuItem_HauptmenuTopAA_marginLeftRight_Hover,
  ] = useState("5");

  const [
    DB_LeftSidebar_MenuItem_HauptmenuTopAA_paddingLeftRight_Hover,
    setDB_LeftSidebar_MenuItem_HauptmenuTopAA_paddingLeftRight_Hover,
  ] = useState("5");

  const [
    DB_LeftSidebar_MenuItem_HauptmenuTopAA_paddingTopBottom_Hover,
    setDB_LeftSidebar_MenuItem_HauptmenuTopAA_paddingTopBottom_Hover,
  ] = useState("5");

  const [
    DB_LeftSidebar_MenuItem_HauptmenuTopAA_letterSpacing_Hover,
    setDB_LeftSidebar_MenuItem_HauptmenuTopAA_letterSpacing_Hover,
  ] = useState("0");

  const [
    DB_LeftSidebar_MenuItem_HauptmenuTopAA_ShadowHorizontal,
    setDB_LeftSidebar_MenuItem_HauptmenuTopAA_ShadowHorizontal,
  ] = useState(1);
  const [
    DB_LeftSidebar_MenuItem_HauptmenuTopAA_ShadowVertical,
    setDB_LeftSidebar_MenuItem_HauptmenuTopAA_ShadowVertical,
  ] = useState(1);
  const [
    DB_LeftSidebar_MenuItem_HauptmenuTopAA_ShadowBlur,
    setDB_LeftSidebar_MenuItem_HauptmenuTopAA_ShadowBlur,
  ] = useState(5);
  const [
    DB_LeftSidebar_MenuItem_HauptmenuTopAA_ShadowColor,
    setDB_LeftSidebar_MenuItem_HauptmenuTopAA_ShadowColor,
  ] = useState("rgba(255,255,255,1)");

  const [
    DB_LeftSidebar_MenuItem_HauptmenuTopAA_ShadowColorNotEncoded,
    setDB_LeftSidebar_MenuItem_HauptmenuTopAA_ShadowColorNotEncoded,
  ] = useState("rgba(255,255,255,1)");

  const [
    DB_LeftSidebar_MenuItem_HauptmenuTopAA_TextDecoration,
    setDB_LeftSidebar_MenuItem_HauptmenuTopAA_TextDecoration,
  ] = useState("normal");

  const [
    DB_LeftSidebar_MenuItem_HauptmenuTopAA_TextDecorationStyle,
    setDB_LeftSidebar_MenuItem_HauptmenuTopAA_TextDecorationStyle,
  ] = useState("");

  const [
    DB_LeftSidebar_MenuItem_HauptmenuTopAA_ShadowHorizontal_Hover,
    setDB_LeftSidebar_MenuItem_HauptmenuTopAA_ShadowHorizontal_Hover,
  ] = useState(1);
  const [
    DB_LeftSidebar_MenuItem_HauptmenuTopAA_ShadowVertical_Hover,
    setDB_LeftSidebar_MenuItem_HauptmenuTopAA_ShadowVertical_Hover,
  ] = useState(1);
  const [
    DB_LeftSidebar_MenuItem_HauptmenuTopAA_ShadowBlur_Hover,
    setDB_LeftSidebar_MenuItem_HauptmenuTopAA_ShadowBlur_Hover,
  ] = useState(5);
  const [
    DB_LeftSidebar_MenuItem_HauptmenuTopAA_ShadowColor_Hover,
    setDB_LeftSidebar_MenuItem_HauptmenuTopAA_ShadowColor_Hover,
  ] = useState("rgba(255,255,255,1)");
  const [
    DB_LeftSidebar_MenuItem_HauptmenuTopAA_TextDecoration_Hover,
    setDB_LeftSidebar_MenuItem_HauptmenuTopAA_TextDecoration_Hover,
  ] = useState("none");
  const [
    DB_LeftSidebar_MenuItem_HauptmenuTopAA_TextDecorationStyle_Hover,
    setDB_LeftSidebar_MenuItem_HauptmenuTopAA_TextDecorationStyle_Hover,
  ] = useState("");

  const [
    DB_LeftSidebar_MenuItem_HauptmenuTopAA_ShadowColorNotEncoded_Hover,
    setDB_LeftSidebar_MenuItem_HauptmenuTopAA_ShadowColorNotEncoded_Hover,
  ] = useState("rgba(255,255,255,1)");

  const [
    DB_LeftSidebar_MenuContainerAA_Hover_BackgroundColor,
    setDB_LeftSidebar_MenuContainerAA_Hover_BackgroundColor,
  ] = useState("rgba(0,0,0,0.3)");

  const [
    DB_LeftSidebar_MenuContainerAA_Hover_BackgroundColorNotEncoded,
    setDB_LeftSidebar_MenuContainerAA_Hover_BackgroundColorNotEncoded,
  ] = useState("rgba(0,0,0,0.3)");

  const [
    DB_LeftSidebar_MenuContainerAA_Hover_PaddingTopBottom,
    setDB_LeftSidebar_MenuContainerAA_Hover_PaddingTopBottom,
  ] = useState(10);

  const [
    DB_LeftSidebar_MenuContainerAA_Hover_PaddingLeftRight,
    setDB_LeftSidebar_MenuContainerAA_Hover_PaddingLeftRight,
  ] = useState(10);

  /// XXXYYYZZZ START

  const [
    DB_LeftSidebar_MenuContainerXYZ_Hover_BackgroundColor,
    setDB_LeftSidebar_MenuContainerXYZ_Hover_BackgroundColor,
  ] = useState("rgba(0,0,0,0.3)");

  const [
    DB_LeftSidebar_MenuContainerXYZ_Hover_BackgroundColorNotEncoded,
    setDB_LeftSidebar_MenuContainerXYZ_Hover_BackgroundColorNotEncoded,
  ] = useState("rgba(0,0,0,0.3)");

  const [
    DB_LeftSidebar_MenuContainerXYZ_Hover_PaddingTopBottom,
    setDB_LeftSidebar_MenuContainerXYZ_Hover_PaddingTopBottom,
  ] = useState(10);

  const [
    DB_LeftSidebar_MenuContainerXYZ_Hover_PaddingLeftRight,
    setDB_LeftSidebar_MenuContainerXYZ_Hover_PaddingLeftRight,
  ] = useState(10);

  /// XXXYYYZZZ END

  const [
    DB_LeftSidebar_MenuItem_MenuContainerAA_ShadowHorizontal,
    setDB_LeftSidebar_MenuItem_MenuContainerAA_ShadowHorizontal,
  ] = useState(1);
  const [
    DB_LeftSidebar_MenuItem_MenuContainerAA_ShadowVertical,
    setDB_LeftSidebar_MenuItem_MenuContainerAA_ShadowVertical,
  ] = useState(1);
  const [
    DB_LeftSidebar_MenuItem_MenuContainerAA_ShadowBlur,
    setDB_LeftSidebar_MenuItem_MenuContainerAA_ShadowBlur,
  ] = useState(5);
  const [
    DB_LeftSidebar_MenuItem_MenuContainerAA_Verteilung,
    setDB_LeftSidebar_MenuItem_MenuContainerAA_Verteilung,
  ] = useState(5);
  const [
    DB_LeftSidebar_MenuItem_MenuContainerAA_ShadowColor,
    setDB_LeftSidebar_MenuItem_MenuContainerAA_ShadowColor,
  ] = useState("rgba(255,255,255,1)");

  const [
    DB_LeftSidebar_MenuItem_MenuContainerAA_ShadowColorNotEncoded,
    setDB_LeftSidebar_MenuItem_MenuContainerAA_ShadowColorNotEncoded,
  ] = useState("rgba(255,255,255,1)");

  const [
    DB_LeftSidebar_MenuItem_MenuContainerAA_Hover_ShadowHorizontal,
    setDB_LeftSidebar_MenuItem_MenuContainerAA_Hover_ShadowHorizontal,
  ] = useState(1);
  const [
    DB_LeftSidebar_MenuItem_MenuContainerAA_Hover_ShadowVertical,
    setDB_LeftSidebar_MenuItem_MenuContainerAA_Hover_ShadowVertical,
  ] = useState(1);
  const [
    DB_LeftSidebar_MenuItem_MenuContainerAA_Hover_ShadowBlur,
    setDB_LeftSidebar_MenuItem_MenuContainerAA_Hover_ShadowBlur,
  ] = useState(5);
  const [
    DB_LeftSidebar_MenuItem_MenuContainerAA_Hover_ShadowColor,
    setDB_LeftSidebar_MenuItem_MenuContainerAA_Hover_ShadowColor,
  ] = useState("rgba(255,255,255,1)");

  const [
    DB_LeftSidebar_MenuItem_MenuContainerAA_Hover_ShadowColorNotEncoded,
    setDB_LeftSidebar_MenuItem_MenuContainerAA_Hover_ShadowColorNotEncoded,
  ] = useState("rgba(255,255,255,1)");

  // DB_LeftSidebar - Container
  const [
    DB_LeftSidebar_MenuContainerAA_BackgroundColor,
    setDB_LeftSidebar_MenuContainerAA_BackgroundColor,
  ] = useState("rgba(0,0,0,0.3)");

  const [
    DB_LeftSidebar_MenuContainerAA_BackgroundColorNotEncoded,
    setDB_LeftSidebar_MenuContainerAA_BackgroundColorNotEncoded,
  ] = useState("rgba(0,0,0,0.3)");

  const [
    DB_LeftSidebar_MenuContainerAA_PaddingTopBottom,
    setDB_LeftSidebar_MenuContainerAA_PaddingTopBottom,
  ] = useState(10);

  const [
    DB_LeftSidebar_MenuContainerAA_PaddingLeftRight,
    setDB_LeftSidebar_MenuContainerAA_PaddingLeftRight,
  ] = useState(10);

  const [
    DB_LeftSidebar_MenuItem_MenuContainerAA_BorderTopRightRadius,
    setDB_LeftSidebar_MenuItem_MenuContainerAA_BorderTopRightRadius,
  ] = useState(20);
  const [
    DB_LeftSidebar_MenuItem_MenuContainerAA_BorderTopLeftRadius,
    setDB_LeftSidebar_MenuItem_MenuContainerAA_BorderTopLeftRadius,
  ] = useState(20);
  const [
    DB_LeftSidebar_MenuItem_MenuContainerAA_BorderBottomRightRadius,
    setDB_LeftSidebar_MenuItem_MenuContainerAA_BorderBottomRightRadius,
  ] = useState(20);
  const [
    DB_LeftSidebar_MenuItem_MenuContainerAA_BorderBottomLeftRadius,
    setDB_LeftSidebar_MenuItem_MenuContainerAA_BorderBottomLeftRadius,
  ] = useState(20);

  const [
    DB_LeftSidebar_MenuItem_MenuContainerAA_Hover_BorderTopRightRadius,
    setDB_LeftSidebar_MenuItem_MenuContainerAA_Hover_BorderTopRightRadius,
  ] = useState(20);
  const [
    DB_LeftSidebar_MenuItem_MenuContainerAA_Hover_BorderTopLeftRadius,
    setDB_LeftSidebar_MenuItem_MenuContainerAA_Hover_BorderTopLeftRadius,
  ] = useState(20);
  const [
    DB_LeftSidebar_MenuItem_MenuContainerAA_Hover_BorderBottomRightRadius,
    setDB_LeftSidebar_MenuItem_MenuContainerAA_Hover_BorderBottomRightRadius,
  ] = useState(20);
  const [
    DB_LeftSidebar_MenuItem_MenuContainerAA_Hover_BorderBottomLeftRadius,
    setDB_LeftSidebar_MenuItem_MenuContainerAA_Hover_BorderBottomLeftRadius,
  ] = useState(20);

  const [
    DB_LeftSidebar_MenuItem_MenuContainerAA_BorderColorTop,
    setDB_LeftSidebar_MenuItem_MenuContainerAA_BorderColorTop,
  ] = useState("rgba(255,255,255,1)");
  const [
    DB_LeftSidebar_MenuItem_MenuContainerAA_BorderColorBottom,
    setDB_LeftSidebar_MenuItem_MenuContainerAA_BorderColorBottom,
  ] = useState("rgba(255,255,255,1)");
  const [
    DB_LeftSidebar_MenuItem_MenuContainerAA_BorderColorLeft,
    setDB_LeftSidebar_MenuItem_MenuContainerAA_BorderColorLeft,
  ] = useState("rgba(255,255,255,1)");
  const [
    DB_LeftSidebar_MenuItem_MenuContainerAA_BorderColorRight,
    setDB_LeftSidebar_MenuItem_MenuContainerAA_BorderColorRight,
  ] = useState("rgba(255,255,255,1)");

  const [
    DB_LeftSidebar_MenuItem_MenuContainerAA_BorderColorTopNotEncoded,
    setDB_LeftSidebar_MenuItem_MenuContainerAA_BorderColorTopNotEncoded,
  ] = useState("rgba(255,255,255,1)");
  const [
    DB_LeftSidebar_MenuItem_MenuContainerAA_BorderColorBottomNotEncoded,
    setDB_LeftSidebar_MenuItem_MenuContainerAA_BorderColorBottomNotEncoded,
  ] = useState("rgba(255,255,255,1)");
  const [
    DB_LeftSidebar_MenuItem_MenuContainerAA_BorderColorLeftNotEncoded,
    setDB_LeftSidebar_MenuItem_MenuContainerAA_BorderColorLeftNotEncoded,
  ] = useState("rgba(255,255,255,1)");
  const [
    DB_LeftSidebar_MenuItem_MenuContainerAA_BorderColorRightNotEncoded,
    setDB_LeftSidebar_MenuItem_MenuContainerAA_BorderColorRightNotEncoded,
  ] = useState("rgba(255,255,255,1)");

  const [
    DB_LeftSidebar_MenuContainerAA_Hover_BorderColorTop,
    setDB_LeftSidebar_MenuContainerAA_Hover_BorderColorTop,
  ] = useState("rgba(0,0,0,0.3)");
  const [
    DB_LeftSidebar_MenuContainerAA_Hover_BorderColorBottom,
    setDB_LeftSidebar_MenuContainerAA_Hover_BorderColorBottom,
  ] = useState("rgba(0,0,0,0.3)");
  const [
    DB_LeftSidebar_MenuContainerAA_Hover_BorderColorLeft,
    setDB_LeftSidebar_MenuContainerAA_Hover_BorderColorLeft,
  ] = useState("rgba(0,0,0,0.3)");
  const [
    DB_LeftSidebar_MenuContainerAA_Hover_BorderColorRight,
    setDB_LeftSidebar_MenuContainerAA_Hover_BorderColorRight,
  ] = useState("rgba(0,0,0,0.3)");

  const [
    DB_LeftSidebar_MenuContainerAA_Hover_BorderColorTopNotEncoded,
    setDB_LeftSidebar_MenuContainerAA_Hover_BorderColorTopNotEncoded,
  ] = useState("rgba(0,0,0,0.3)");
  const [
    DB_LeftSidebar_MenuContainerAA_Hover_BorderColorBottomNotEncoded,
    setDB_LeftSidebar_MenuContainerAA_Hover_BorderColorBottomNotEncoded,
  ] = useState("rgba(0,0,0,0.3)");
  const [
    DB_LeftSidebar_MenuContainerAA_Hover_BorderColorLeftNotEncoded,
    setDB_LeftSidebar_MenuContainerAA_Hover_BorderColorLeftNotEncoded,
  ] = useState("rgba(0,0,0,0.3)");
  const [
    DB_LeftSidebar_MenuContainerAA_Hover_BorderColorRightNotEncoded,
    setDB_LeftSidebar_MenuContainerAA_Hover_BorderColorRightNotEncoded,
  ] = useState("rgba(0,0,0,0.3)");

  const [
    DB_LeftSidebar_MenuContainerAA_Hover_BorderTop,
    setDB_LeftSidebar_MenuContainerAA_Hover_BorderTop,
  ] = useState(1);
  const [
    DB_LeftSidebar_MenuContainerAA_Hover_BorderBottom,
    setDB_LeftSidebar_MenuContainerAA_Hover_BorderBottom,
  ] = useState(1);
  const [
    DB_LeftSidebar_MenuContainerAA_Hover_BorderLeft,
    setDB_LeftSidebar_MenuContainerAA_Hover_BorderLeft,
  ] = useState(1);
  const [
    DB_LeftSidebar_MenuContainerAA_Hover_BorderRight,
    setDB_LeftSidebar_MenuContainerAA_Hover_BorderRight,
  ] = useState(1);

  const [
    DB_LeftSidebar_MenuContainerAA_Hover_BorderStyleTop,
    setDB_LeftSidebar_MenuContainerAA_Hover_BorderStyleTop,
  ] = useState("solid");
  const [
    DB_LeftSidebar_MenuContainerAA_Hover_BorderStyleBottom,
    setDB_LeftSidebar_MenuContainerAA_Hover_BorderStyleBottom,
  ] = useState("solid");
  const [
    DB_LeftSidebar_MenuContainerAA_Hover_BorderStyleLeft,
    setDB_LeftSidebar_MenuContainerAA_Hover_BorderStyleLeft,
  ] = useState("solid");
  const [
    DB_LeftSidebar_MenuContainerAA_Hover_BorderStyleRight,
    setDB_LeftSidebar_MenuContainerAA_Hover_BorderStyleRight,
  ] = useState("solid");

  const [
    DB_LeftSidebar_MenuContainerAA_BorderTop,
    setDB_LeftSidebar_MenuContainerAA_BorderTop,
  ] = useState(1);
  const [
    DB_LeftSidebar_MenuContainerAA_BorderBottom,
    setDB_LeftSidebar_MenuContainerAA_BorderBottom,
  ] = useState(1);
  const [
    DB_LeftSidebar_MenuContainerAA_BorderLeft,
    setDB_LeftSidebar_MenuContainerAA_BorderLeft,
  ] = useState(1);
  const [
    DB_LeftSidebar_MenuContainerAA_BorderRight,
    setDB_LeftSidebar_MenuContainerAA_BorderRight,
  ] = useState(1);

  const [
    DB_LeftSidebar_MenuContainerAA_BorderStyleTop,
    setDB_LeftSidebar_MenuContainerAA_BorderStyleTop,
  ] = useState("solid");
  const [
    DB_LeftSidebar_MenuContainerAA_BorderStyleBottom,
    setDB_LeftSidebar_MenuContainerAA_BorderStyleBottom,
  ] = useState("solid");
  const [
    DB_LeftSidebar_MenuContainerAA_BorderStyleLeft,
    setDB_LeftSidebar_MenuContainerAA_BorderStyleLeft,
  ] = useState("solid");
  const [
    DB_LeftSidebar_MenuContainerAA_BorderStyleRight,
    setDB_LeftSidebar_MenuContainerAA_BorderStyleRight,
  ] = useState("solid");

  const [
    DB_LeftSidebar_MenuContainerAA_BorderColorTop,
    setDB_LeftSidebar_MenuContainerAA_BorderColorTop,
  ] = useState("rgba(0,0,0,0.3)");
  const [
    DB_LeftSidebar_MenuContainerAA_BorderColorBottom,
    setDB_LeftSidebar_MenuContainerAA_BorderColorBottom,
  ] = useState("rgba(0,0,0,0.3)");
  const [
    DB_LeftSidebar_MenuContainerAA_BorderColorLeft,
    setDB_LeftSidebar_MenuContainerAA_BorderColorLeft,
  ] = useState("rgba(0,0,0,0.3)");
  const [
    DB_LeftSidebar_MenuContainerAA_BorderColorRight,
    setDB_LeftSidebar_MenuContainerAA_BorderColorRight,
  ] = useState("rgba(0,0,0,0.3)");

  const [
    DB_LeftSidebar_MenuContainerAA_BorderColorTopNotEncoded,
    setDB_LeftSidebar_MenuContainerAA_BorderColorTopNotEncoded,
  ] = useState("rgba(0,0,0,0.3)");
  const [
    DB_LeftSidebar_MenuContainerAA_BorderColorBottomNotEncoded,
    setDB_LeftSidebar_MenuContainerAA_BorderColorBottomNotEncoded,
  ] = useState("rgba(0,0,0,0.3)");
  const [
    DB_LeftSidebar_MenuContainerAA_BorderColorLeftNotEncoded,
    setDB_LeftSidebar_MenuContainerAA_BorderColorLeftNotEncoded,
  ] = useState("rgba(0,0,0,0.3)");
  const [
    DB_LeftSidebar_MenuContainerAA_BorderColorRightNotEncoded,
    setDB_LeftSidebar_MenuContainerAA_BorderColorRightNotEncoded,
  ] = useState("rgba(0,0,0,0.3)");

  const [
    DB_LeftSidebar_MenuItem_MenuContainerAA_paddingLeft,
    setDB_LeftSidebar_MenuItem_MenuContainerAA_paddingLeft,
  ] = useState("5");

  const [
    DB_LeftSidebar_MenuItem_MenuContainerAA_paddingTop,
    setDB_LeftSidebar_MenuItem_MenuContainerAA_paddingTop,
  ] = useState("5");

  const [
    DB_LeftSidebar_MenuItem_MenuContainerAA_paddingRight,
    setDB_LeftSidebar_MenuItem_MenuContainerAA_paddingRight,
  ] = useState("5");

  const [
    DB_LeftSidebar_MenuItem_MenuContainerAA_paddingBottom,
    setDB_LeftSidebar_MenuItem_MenuContainerAA_paddingBottom,
  ] = useState("5");

  const [
    DB_LeftSidebar_MenuItem_MenuContainerAA_Hover_paddingLeft,
    setDB_LeftSidebar_MenuItem_MenuContainerAA_Hover_paddingLeft,
  ] = useState("5");

  const [
    DB_LeftSidebar_MenuItem_MenuContainerAA_Hover_paddingTop,
    setDB_LeftSidebar_MenuItem_MenuContainerAA_Hover_paddingTop,
  ] = useState("5");

  const [
    DB_LeftSidebar_MenuItem_MenuContainerAA_Hover_paddingRight,
    setDB_LeftSidebar_MenuItem_MenuContainerAA_Hover_paddingRight,
  ] = useState("5");

  const [
    DB_LeftSidebar_MenuItem_MenuContainerAA_Hover_paddingBottom,
    setDB_LeftSidebar_MenuItem_MenuContainerAA_Hover_paddingBottom,
  ] = useState("5");

  const [
    DB_LeftSidebar_MenuItem_MenuContainerXYZ_ShadowHorizontal,
    setDB_LeftSidebar_MenuItem_MenuContainerXYZ_ShadowHorizontal,
  ] = useState(1);
  const [
    DB_LeftSidebar_MenuItem_MenuContainerXYZ_ShadowVertical,
    setDB_LeftSidebar_MenuItem_MenuContainerXYZ_ShadowVertical,
  ] = useState(1);
  const [
    DB_LeftSidebar_MenuItem_MenuContainerXYZ_ShadowBlur,
    setDB_LeftSidebar_MenuItem_MenuContainerXYZ_ShadowBlur,
  ] = useState(5);

  const [
    DB_LeftSidebar_MenuItem_MenuContainerXYZ_ShadowColor,
    setDB_LeftSidebar_MenuItem_MenuContainerXYZ_ShadowColor,
  ] = useState("rgba(255,255,255,1)");

  const [
    DB_LeftSidebar_MenuItem_MenuContainerXYZ_ShadowColorNotEncoded,
    setDB_LeftSidebar_MenuItem_MenuContainerXYZ_ShadowColorNotEncoded,
  ] = useState("rgba(255,255,255,1)");

  const [
    DB_LeftSidebar_MenuItem_MenuContainerXYZ_Hover_ShadowHorizontal,
    setDB_LeftSidebar_MenuItem_MenuContainerXYZ_Hover_ShadowHorizontal,
  ] = useState(1);
  const [
    DB_LeftSidebar_MenuItem_MenuContainerXYZ_Hover_ShadowVertical,
    setDB_LeftSidebar_MenuItem_MenuContainerXYZ_Hover_ShadowVertical,
  ] = useState(1);
  const [
    DB_LeftSidebar_MenuItem_MenuContainerXYZ_Hover_ShadowBlur,
    setDB_LeftSidebar_MenuItem_MenuContainerXYZ_Hover_ShadowBlur,
  ] = useState(5);
  const [
    DB_LeftSidebar_MenuItem_MenuContainerXYZ_Hover_ShadowColor,
    setDB_LeftSidebar_MenuItem_MenuContainerXYZ_Hover_ShadowColor,
  ] = useState("rgba(255,255,255,1)");

  const [
    DB_LeftSidebar_MenuItem_MenuContainerXYZ_Hover_ShadowColorNotEncoded,
    setDB_LeftSidebar_MenuItem_MenuContainerXYZ_Hover_ShadowColorNotEncoded,
  ] = useState("rgba(255,255,255,1)");

  // DB_LeftSidebar - Container
  const [
    DB_LeftSidebar_MenuContainerXYZ_BackgroundColor,
    setDB_LeftSidebar_MenuContainerXYZ_BackgroundColor,
  ] = useState("rgba(0,0,0,0.3)");

  const [
    DB_LeftSidebar_MenuContainerXYZ_BackgroundColorNotEncoded,
    setDB_LeftSidebar_MenuContainerXYZ_BackgroundColorNotEncoded,
  ] = useState("rgba(0,0,0,0.3)");

  const [
    DB_LeftSidebar_MenuContainerXYZ_PaddingTopBottom,
    setDB_LeftSidebar_MenuContainerXYZ_PaddingTopBottom,
  ] = useState(10);

  const [
    DB_LeftSidebar_MenuContainerXYZ_PaddingLeftRight,
    setDB_LeftSidebar_MenuContainerXYZ_PaddingLeftRight,
  ] = useState(10);

  const [
    showConfig_LeftSidebar_MenuContainerXYZ,
    setShowConfig_LeftSidebar_MenuContainerXYZ,
  ] = useState(false);

  const [
    showConfig_LeftSidebar_MenuContainerXYZInner,
    setShowConfig_LeftSidebar_MenuContainerXYZInner,
  ] = useState(false);

  const [
    showConfig_LeftSidebar_MenuContainerXYZInnerHover,
    setShowConfig_LeftSidebar_MenuContainerXYZInnerHover,
  ] = useState(false);

  const [
    DB_LeftSidebar_MenuItem_MenuContainerXYZ_BorderTopRightRadius,
    setDB_LeftSidebar_MenuItem_MenuContainerXYZ_BorderTopRightRadius,
  ] = useState(20);
  const [
    DB_LeftSidebar_MenuItem_MenuContainerXYZ_BorderTopLeftRadius,
    setDB_LeftSidebar_MenuItem_MenuContainerXYZ_BorderTopLeftRadius,
  ] = useState(20);
  const [
    DB_LeftSidebar_MenuItem_MenuContainerXYZ_BorderBottomRightRadius,
    setDB_LeftSidebar_MenuItem_MenuContainerXYZ_BorderBottomRightRadius,
  ] = useState(20);
  const [
    DB_LeftSidebar_MenuItem_MenuContainerXYZ_BorderBottomLeftRadius,
    setDB_LeftSidebar_MenuItem_MenuContainerXYZ_BorderBottomLeftRadius,
  ] = useState(20);

  const [
    DB_LeftSidebar_MenuItem_MenuContainerXYZ_Hover_BorderTopRightRadius,
    setDB_LeftSidebar_MenuItem_MenuContainerXYZ_Hover_BorderTopRightRadius,
  ] = useState(20);
  const [
    DB_LeftSidebar_MenuItem_MenuContainerXYZ_Hover_BorderTopLeftRadius,
    setDB_LeftSidebar_MenuItem_MenuContainerXYZ_Hover_BorderTopLeftRadius,
  ] = useState(20);
  const [
    DB_LeftSidebar_MenuItem_MenuContainerXYZ_Hover_BorderBottomRightRadius,
    setDB_LeftSidebar_MenuItem_MenuContainerXYZ_Hover_BorderBottomRightRadius,
  ] = useState(20);
  const [
    DB_LeftSidebar_MenuItem_MenuContainerXYZ_Hover_BorderBottomLeftRadius,
    setDB_LeftSidebar_MenuItem_MenuContainerXYZ_Hover_BorderBottomLeftRadius,
  ] = useState(20);

  const [
    DB_LeftSidebar_MenuItem_MenuContainerXYZ_BorderColorTop,
    setDB_LeftSidebar_MenuItem_MenuContainerXYZ_BorderColorTop,
  ] = useState("rgba(255,255,255,1)");
  const [
    DB_LeftSidebar_MenuItem_MenuContainerXYZ_BorderColorBottom,
    setDB_LeftSidebar_MenuItem_MenuContainerXYZ_BorderColorBottom,
  ] = useState("rgba(255,255,255,1)");
  const [
    DB_LeftSidebar_MenuItem_MenuContainerXYZ_BorderColorLeft,
    setDB_LeftSidebar_MenuItem_MenuContainerXYZ_BorderColorLeft,
  ] = useState("rgba(255,255,255,1)");
  const [
    DB_LeftSidebar_MenuItem_MenuContainerXYZ_BorderColorRight,
    setDB_LeftSidebar_MenuItem_MenuContainerXYZ_BorderColorRight,
  ] = useState("rgba(255,255,255,1)");

  const [
    DB_LeftSidebar_MenuItem_MenuContainerXYZ_BorderColorTopNotEncoded,
    setDB_LeftSidebar_MenuItem_MenuContainerXYZ_BorderColorTopNotEncoded,
  ] = useState("rgba(255,255,255,1)");
  const [
    DB_LeftSidebar_MenuItem_MenuContainerXYZ_BorderColorBottomNotEncoded,
    setDB_LeftSidebar_MenuItem_MenuContainerXYZ_BorderColorBottomNotEncoded,
  ] = useState("rgba(255,255,255,1)");
  const [
    DB_LeftSidebar_MenuItem_MenuContainerXYZ_BorderColorLeftNotEncoded,
    setDB_LeftSidebar_MenuItem_MenuContainerXYZ_BorderColorLeftNotEncoded,
  ] = useState("rgba(255,255,255,1)");
  const [
    DB_LeftSidebar_MenuItem_MenuContainerXYZ_BorderColorRightNotEncoded,
    setDB_LeftSidebar_MenuItem_MenuContainerXYZ_BorderColorRightNotEncoded,
  ] = useState("rgba(255,255,255,1)");

  const [
    DB_LeftSidebar_MenuContainerXYZ_Hover_BorderColorTop,
    setDB_LeftSidebar_MenuContainerXYZ_Hover_BorderColorTop,
  ] = useState("rgba(0,0,0,0.3)");
  const [
    DB_LeftSidebar_MenuContainerXYZ_Hover_BorderColorBottom,
    setDB_LeftSidebar_MenuContainerXYZ_Hover_BorderColorBottom,
  ] = useState("rgba(0,0,0,0.3)");
  const [
    DB_LeftSidebar_MenuContainerXYZ_Hover_BorderColorLeft,
    setDB_LeftSidebar_MenuContainerXYZ_Hover_BorderColorLeft,
  ] = useState("rgba(0,0,0,0.3)");
  const [
    DB_LeftSidebar_MenuContainerXYZ_Hover_BorderColorRight,
    setDB_LeftSidebar_MenuContainerXYZ_Hover_BorderColorRight,
  ] = useState("rgba(0,0,0,0.3)");

  const [
    DB_LeftSidebar_MenuContainerXYZ_Hover_BorderColorTopNotEncoded,
    setDB_LeftSidebar_MenuContainerXYZ_Hover_BorderColorTopNotEncoded,
  ] = useState("rgba(0,0,0,0.3)");
  const [
    DB_LeftSidebar_MenuContainerXYZ_Hover_BorderColorBottomNotEncoded,
    setDB_LeftSidebar_MenuContainerXYZ_Hover_BorderColorBottomNotEncoded,
  ] = useState("rgba(0,0,0,0.3)");
  const [
    DB_LeftSidebar_MenuContainerXYZ_Hover_BorderColorLeftNotEncoded,
    setDB_LeftSidebar_MenuContainerXYZ_Hover_BorderColorLeftNotEncoded,
  ] = useState("rgba(0,0,0,0.3)");
  const [
    DB_LeftSidebar_MenuContainerXYZ_Hover_BorderColorRightNotEncoded,
    setDB_LeftSidebar_MenuContainerXYZ_Hover_BorderColorRightNotEncoded,
  ] = useState("rgba(0,0,0,0.3)");

  const [
    DB_LeftSidebar_MenuContainerXYZ_Hover_BorderTop,
    setDB_LeftSidebar_MenuContainerXYZ_Hover_BorderTop,
  ] = useState(1);
  const [
    DB_LeftSidebar_MenuContainerXYZ_Hover_BorderBottom,
    setDB_LeftSidebar_MenuContainerXYZ_Hover_BorderBottom,
  ] = useState(1);
  const [
    DB_LeftSidebar_MenuContainerXYZ_Hover_BorderLeft,
    setDB_LeftSidebar_MenuContainerXYZ_Hover_BorderLeft,
  ] = useState(1);
  const [
    DB_LeftSidebar_MenuContainerXYZ_Hover_BorderRight,
    setDB_LeftSidebar_MenuContainerXYZ_Hover_BorderRight,
  ] = useState(1);

  const [
    DB_LeftSidebar_MenuContainerXYZ_Hover_BorderStyleTop,
    setDB_LeftSidebar_MenuContainerXYZ_Hover_BorderStyleTop,
  ] = useState("solid");
  const [
    DB_LeftSidebar_MenuContainerXYZ_Hover_BorderStyleBottom,
    setDB_LeftSidebar_MenuContainerXYZ_Hover_BorderStyleBottom,
  ] = useState("solid");
  const [
    DB_LeftSidebar_MenuContainerXYZ_Hover_BorderStyleLeft,
    setDB_LeftSidebar_MenuContainerXYZ_Hover_BorderStyleLeft,
  ] = useState("solid");
  const [
    DB_LeftSidebar_MenuContainerXYZ_Hover_BorderStyleRight,
    setDB_LeftSidebar_MenuContainerXYZ_Hover_BorderStyleRight,
  ] = useState("solid");

  const [
    DB_LeftSidebar_MenuContainerXYZ_BorderTop,
    setDB_LeftSidebar_MenuContainerXYZ_BorderTop,
  ] = useState(1);
  const [
    DB_LeftSidebar_MenuContainerXYZ_BorderBottom,
    setDB_LeftSidebar_MenuContainerXYZ_BorderBottom,
  ] = useState(1);
  const [
    DB_LeftSidebar_MenuContainerXYZ_BorderLeft,
    setDB_LeftSidebar_MenuContainerXYZ_BorderLeft,
  ] = useState(1);
  const [
    DB_LeftSidebar_MenuContainerXYZ_BorderRight,
    setDB_LeftSidebar_MenuContainerXYZ_BorderRight,
  ] = useState(1);

  const [
    DB_LeftSidebar_MenuContainerXYZ_BorderStyleTop,
    setDB_LeftSidebar_MenuContainerXYZ_BorderStyleTop,
  ] = useState("solid");
  const [
    DB_LeftSidebar_MenuContainerXYZ_BorderStyleBottom,
    setDB_LeftSidebar_MenuContainerXYZ_BorderStyleBottom,
  ] = useState("solid");
  const [
    DB_LeftSidebar_MenuContainerXYZ_BorderStyleLeft,
    setDB_LeftSidebar_MenuContainerXYZ_BorderStyleLeft,
  ] = useState("solid");
  const [
    DB_LeftSidebar_MenuContainerXYZ_BorderStyleRight,
    setDB_LeftSidebar_MenuContainerXYZ_BorderStyleRight,
  ] = useState("solid");

  const [
    DB_LeftSidebar_MenuContainerXYZ_BorderColorTop,
    setDB_LeftSidebar_MenuContainerXYZ_BorderColorTop,
  ] = useState("rgba(0,0,0,0.3)");
  const [
    DB_LeftSidebar_MenuContainerXYZ_BorderColorBottom,
    setDB_LeftSidebar_MenuContainerXYZ_BorderColorBottom,
  ] = useState("rgba(0,0,0,0.3)");
  const [
    DB_LeftSidebar_MenuContainerXYZ_BorderColorLeft,
    setDB_LeftSidebar_MenuContainerXYZ_BorderColorLeft,
  ] = useState("rgba(0,0,0,0.3)");
  const [
    DB_LeftSidebar_MenuContainerXYZ_BorderColorRight,
    setDB_LeftSidebar_MenuContainerXYZ_BorderColorRight,
  ] = useState("rgba(0,0,0,0.3)");

  const [
    DB_LeftSidebar_MenuContainerXYZ_BorderColorTopNotEncoded,
    setDB_LeftSidebar_MenuContainerXYZ_BorderColorTopNotEncoded,
  ] = useState("rgba(0,0,0,0.3)");
  const [
    DB_LeftSidebar_MenuContainerXYZ_BorderColorBottomNotEncoded,
    setDB_LeftSidebar_MenuContainerXYZ_BorderColorBottomNotEncoded,
  ] = useState("rgba(0,0,0,0.3)");
  const [
    DB_LeftSidebar_MenuContainerXYZ_BorderColorLeftNotEncoded,
    setDB_LeftSidebar_MenuContainerXYZ_BorderColorLeftNotEncoded,
  ] = useState("rgba(0,0,0,0.3)");
  const [
    DB_LeftSidebar_MenuContainerXYZ_BorderColorRightNotEncoded,
    setDB_LeftSidebar_MenuContainerXYZ_BorderColorRightNotEncoded,
  ] = useState("rgba(0,0,0,0.3)");

  const [
    DB_LeftSidebar_MenuItem_MenuContainerXYZ_paddingLeft,
    setDB_LeftSidebar_MenuItem_MenuContainerXYZ_paddingLeft,
  ] = useState("5");

  const [
    DB_LeftSidebar_MenuItem_MenuContainerXYZ_paddingTop,
    setDB_LeftSidebar_MenuItem_MenuContainerXYZ_paddingTop,
  ] = useState("5");

  const [
    DB_LeftSidebar_MenuItem_MenuContainerXYZ_paddingRight,
    setDB_LeftSidebar_MenuItem_MenuContainerXYZ_paddingRight,
  ] = useState("5");

  const [
    DB_LeftSidebar_MenuItem_MenuContainerXYZ_paddingBottom,
    setDB_LeftSidebar_MenuItem_MenuContainerXYZ_paddingBottom,
  ] = useState("5");

  const [
    DB_LeftSidebar_MenuItem_MenuContainerXYZ_Hover_paddingLeft,
    setDB_LeftSidebar_MenuItem_MenuContainerXYZ_Hover_paddingLeft,
  ] = useState("5");

  const [
    DB_LeftSidebar_MenuItem_MenuContainerXYZ_Hover_paddingTop,
    setDB_LeftSidebar_MenuItem_MenuContainerXYZ_Hover_paddingTop,
  ] = useState("5");

  const [
    DB_LeftSidebar_MenuItem_MenuContainerXYZ_Hover_paddingRight,
    setDB_LeftSidebar_MenuItem_MenuContainerXYZ_Hover_paddingRight,
  ] = useState("5");

  const [
    DB_LeftSidebar_MenuItem_MenuContainerXYZ_Hover_paddingBottom,
    setDB_LeftSidebar_MenuItem_MenuContainerXYZ_Hover_paddingBottom,
  ] = useState("5");

  const [
    DB_fontFace_alleProdukteAuswahlBB_FontFamilyName,
    setDB_fontFace_alleProdukteAuswahlBB_FontFamilyName,
  ] = useState("Lato");
  const [
    DB_fontFace_alleProdukteAuswahlBB_FontFamilySrc,
    setDB_fontFace_alleProdukteAuswahlBB_FontFamilySrc,
  ] = useState("/assets/Lato/Lato-Regular.ttf");

  const [
    DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_FontSize,
    setDB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_FontSize,
  ] = useState(16);
  const DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_FontFace =
    DB_fontFace_FontFamilyName;
  const [
    DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_FontWeight,
    setDB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_FontWeight,
  ] = useState(400);
  const [
    DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_FontColor,
    setDB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_FontColor,
  ] = useState("rgba(255,255,255,1)");

  const [
    DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_FontColorNotEncoded,
    setDB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_FontColorNotEncoded,
  ] = useState("rgba(0,0,0,0.3)");

  const [
    DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_BackgroundColor,
    setDB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_BackgroundColor,
  ] = useState("rgba(0,0,0,0.3)");
  const [
    DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_BackgroundColorNotEncoded,
    setDB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_BackgroundColorNotEncoded,
  ] = useState("rgba(0,0,0,0.3)");

  const [
    DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_TextAlign,
    setDB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_TextAlign,
  ] = useState("center");

  const [
    DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_marginTopBottom,
    setDB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_marginTopBottom,
  ] = useState("5");
  const [
    DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_marginLeftRight,
    setDB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_marginLeftRight,
  ] = useState("5");

  const [
    DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_paddingLeftRight,
    setDB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_paddingLeftRight,
  ] = useState("5");

  const [
    DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_paddingTopBottom,
    setDB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_paddingTopBottom,
  ] = useState("5");

  const [
    DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_letterSpacing,
    setDB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_letterSpacing,
  ] = useState("0");

  const [
    DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_marginTopBottom_Hover,
    setDB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_marginTopBottom_Hover,
  ] = useState("5");
  const [
    DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_marginLeftRight_Hover,
    setDB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_marginLeftRight_Hover,
  ] = useState("5");

  const [
    DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_paddingLeftRight_Hover,
    setDB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_paddingLeftRight_Hover,
  ] = useState("5");

  const [
    DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_paddingTopBottom_Hover,
    setDB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_paddingTopBottom_Hover,
  ] = useState("5");

  const [
    DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_letterSpacing_Hover,
    setDB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_letterSpacing_Hover,
  ] = useState("0");

  const [
    DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_ShadowHorizontal,
    setDB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_ShadowHorizontal,
  ] = useState(1);
  const [
    DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_ShadowVertical,
    setDB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_ShadowVertical,
  ] = useState(1);
  const [
    DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_ShadowBlur,
    setDB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_ShadowBlur,
  ] = useState(5);
  const [
    DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_ShadowColor,
    setDB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_ShadowColor,
  ] = useState("rgba(255,255,255,1)");

  const [
    DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_ShadowColorNotEncoded,
    setDB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_ShadowColorNotEncoded,
  ] = useState("rgba(255,255,255,1)");

  const [
    DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_TextDecoration,
    setDB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_TextDecoration,
  ] = useState("normal");

  const [
    DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_TextDecorationStyle,
    setDB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_TextDecorationStyle,
  ] = useState("");

  const [
    DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_ShadowHorizontal_Hover,
    setDB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_ShadowHorizontal_Hover,
  ] = useState(1);
  const [
    DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_ShadowVertical_Hover,
    setDB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_ShadowVertical_Hover,
  ] = useState(1);
  const [
    DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_ShadowBlur_Hover,
    setDB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_ShadowBlur_Hover,
  ] = useState(5);
  const [
    DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_ShadowColor_Hover,
    setDB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_ShadowColor_Hover,
  ] = useState("rgba(255,255,255,1)");
  const [
    DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_TextDecoration_Hover,
    setDB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_TextDecoration_Hover,
  ] = useState("none");
  const [
    DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_TextDecorationStyle_Hover,
    setDB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_TextDecorationStyle_Hover,
  ] = useState("");

  const [
    DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_ShadowColorNotEncoded_Hover,
    setDB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_ShadowColorNotEncoded_Hover,
  ] = useState("rgba(255,255,255,1)");

  /////////////////////////////////
  const [
    DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_BorderTopRightRadius,
    setDB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_BorderTopRightRadius,
  ] = useState(5);
  const [
    DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_BorderTopLeftRadius,
    setDB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_BorderTopLeftRadius,
  ] = useState(5);
  const [
    DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_BorderBottomRightRadius,
    setDB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_BorderBottomRightRadius,
  ] = useState(5);
  const [
    DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_BorderBottomLeftRadius,
    setDB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_BorderBottomLeftRadius,
  ] = useState(5);

  const [
    DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_BorderColorTop,
    setDB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_BorderColorTop,
  ] = useState("rgba(0,0,0,0.1)");
  const [
    DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_BorderColorBottom,
    setDB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_BorderColorBottom,
  ] = useState("rgba(0,0,0,0.1)");
  const [
    DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_BorderColorLeft,
    setDB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_BorderColorLeft,
  ] = useState("rgba(0,0,0,0.1)");
  const [
    DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_BorderColorRight,
    setDB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_BorderColorRight,
  ] = useState("rgba(0,0,0,0.1)");

  const [
    DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_BorderColorTopNotEncoded,
    setDB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_BorderColorTopNotEncoded,
  ] = useState("rgba(0,0,0,0.1)");
  const [
    DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_BorderColorBottomNotEncoded,
    setDB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_BorderColorBottomNotEncoded,
  ] = useState("rgba(0,0,0,0.1)");
  const [
    DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_BorderColorLeftNotEncoded,
    setDB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_BorderColorLeftNotEncoded,
  ] = useState("rgba(0,0,0,0.1)");
  const [
    DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_BorderColorRightNotEncoded,
    setDB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_BorderColorRightNotEncoded,
  ] = useState("rgba(0,0,0,0.1)");

  const [
    DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_BorderTop,
    setDB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_BorderTop,
  ] = useState(1);
  const [
    DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_BorderBottom,
    setDB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_BorderBottom,
  ] = useState(1);
  const [
    DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_BorderLeft,
    setDB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_BorderLeft,
  ] = useState(1);
  const [
    DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_BorderRight,
    setDB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_BorderRight,
  ] = useState(1);

  const [
    DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_BorderStyleTop,
    setDB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_BorderStyleTop,
  ] = useState("solid");
  const [
    DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_BorderStyleBottom,
    setDB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_BorderStyleBottom,
  ] = useState("solid");
  const [
    DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_BorderStyleLeft,
    setDB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_BorderStyleLeft,
  ] = useState("solid");
  const [
    DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_BorderStyleRight,
    setDB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_BorderStyleRight,
  ] = useState("solid");

  // DB_LeftSidebar - TitelUltraKatZuruecksetzenBB Normal .-----------------.---------------------.----------------------
  const [
    DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_FontSize,
    setDB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_FontSize,
  ] = useState(16);
  const DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_FontFace =
    DB_fontFace_FontFamilyName;
  const [
    DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_FontWeight,
    setDB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_FontWeight,
  ] = useState(400);
  const [
    DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_FontColor,
    setDB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_FontColor,
  ] = useState("rgba(255,255,255,1)");

  const [
    DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_FontColorNotEncoded,
    setDB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_FontColorNotEncoded,
  ] = useState("rgba(0,0,0,0.3)");

  const [
    DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_BackgroundColor,
    setDB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_BackgroundColor,
  ] = useState("rgba(0,0,0,0.2)");
  const [
    DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_BackgroundColorNotEncoded,
    setDB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_BackgroundColorNotEncoded,
  ] = useState("rgba(0,0,0,0.2)");

  const [
    DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_TextAlign,
    setDB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_TextAlign,
  ] = useState("center");

  /////////////////////////////////
  const [
    DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_BorderTopRightRadius,
    setDB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_BorderTopRightRadius,
  ] = useState(5);
  const [
    DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_BorderTopLeftRadius,
    setDB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_BorderTopLeftRadius,
  ] = useState(5);
  const [
    DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_BorderBottomRightRadius,
    setDB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_BorderBottomRightRadius,
  ] = useState(5);
  const [
    DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_BorderBottomLeftRadius,
    setDB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_BorderBottomLeftRadius,
  ] = useState(5);

  const [
    DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_BorderColorTop,
    setDB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_BorderColorTop,
  ] = useState("rgba(0,0,0,0.3)");
  const [
    DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_BorderColorBottom,
    setDB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_BorderColorBottom,
  ] = useState("rgba(0,0,0,0.3)");
  const [
    DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_BorderColorLeft,
    setDB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_BorderColorLeft,
  ] = useState("rgba(0,0,0,0.3)");
  const [
    DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_BorderColorRight,
    setDB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_BorderColorRight,
  ] = useState("rgba(0,0,0,0.3)");
  const [
    DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_BorderColorTopNotEncoded,
    setDB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_BorderColorTopNotEncoded,
  ] = useState("rgba(0,0,0,0.3)");
  const [
    DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_BorderColorBottomNotEncoded,
    setDB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_BorderColorBottomNotEncoded,
  ] = useState("rgba(0,0,0,0.3)");
  const [
    DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_BorderColorLeftNotEncoded,
    setDB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_BorderColorLeftNotEncoded,
  ] = useState("rgba(0,0,0,0.3)");
  const [
    DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_BorderColorRightNotEncoded,
    setDB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_BorderColorRightNotEncoded,
  ] = useState("rgba(0,0,0,0.3)");

  const [
    DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_BorderTop,
    setDB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_BorderTop,
  ] = useState(1);
  const [
    DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_BorderBottom,
    setDB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_BorderBottom,
  ] = useState(1);
  const [
    DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_BorderLeft,
    setDB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_BorderLeft,
  ] = useState(1);
  const [
    DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_BorderRight,
    setDB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_BorderRight,
  ] = useState(1);

  const [
    DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_BorderStyleTop,
    setDB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_BorderStyleTop,
  ] = useState("solid");
  const [
    DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_BorderStyleBottom,
    setDB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_BorderStyleBottom,
  ] = useState("solid");
  const [
    DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_BorderStyleLeft,
    setDB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_BorderStyleLeft,
  ] = useState("solid");
  const [
    DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_BorderStyleRight,
    setDB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_BorderStyleRight,
  ] = useState("solid");

  // DB_LeftSidebar - Container:Hover
  const [
    DB_LeftSidebar_MenuContainer_CC__Hover_BackgroundColor,
    setDB_LeftSidebar_MenuContainer_CC__Hover_BackgroundColor,
  ] = useState("rgba(0,0,0,0.3)");

  const [
    DB_LeftSidebar_MenuContainer_CC__Hover_BackgroundColorNotEncoded,
    setDB_LeftSidebar_MenuContainer_CC__Hover_BackgroundColorNotEncoded,
  ] = useState("rgba(0,0,0,0.3)");

  const [
    DB_LeftSidebar_MenuContainer_CC__Hover_PaddingTopBottom,
    setDB_LeftSidebar_MenuContainer_CC__Hover_PaddingTopBottom,
  ] = useState(10);

  const [
    DB_LeftSidebar_MenuContainer_CC__Hover_PaddingLeftRight,
    setDB_LeftSidebar_MenuContainer_CC__Hover_PaddingLeftRight,
  ] = useState(10);

  // DB_LeftSidebar - Container
  const [
    DB_LeftSidebar_MenuContainer_CC__BackgroundColor,
    setDB_LeftSidebar_MenuContainer_CC__BackgroundColor,
  ] = useState("rgba(0,0,0,0.3)");

  const [
    DB_LeftSidebar_MenuContainer_CC__BackgroundColorNotEncoded,
    setDB_LeftSidebar_MenuContainer_CC__BackgroundColorNotEncoded,
  ] = useState("rgba(0,0,0,0.3)");

  const [
    DB_LeftSidebar_MenuContainer_CC__PaddingTopBottom,
    setDB_LeftSidebar_MenuContainer_CC__PaddingTopBottom,
  ] = useState(10);

  const [
    DB_LeftSidebar_MenuContainer_CC__PaddingLeftRight,
    setDB_LeftSidebar_MenuContainer_CC__PaddingLeftRight,
  ] = useState(10);

  const [
    showConfig_LeftSidebar_MenuContainer_CC_,
    setShowConfig_LeftSidebar_MenuContainer_CC_,
  ] = useState(false);

  const [
    showConfig_LeftSidebar_MenuContainer_CC_Inner,
    setShowConfig_LeftSidebar_MenuContainer_CC_Inner,
  ] = useState(false);

  const [
    DB_LeftSidebar_MenuItem_MenuContainer_CC__BorderTopRightRadius,
    setDB_LeftSidebar_MenuItem_MenuContainer_CC__BorderTopRightRadius,
  ] = useState(20);
  const [
    DB_LeftSidebar_MenuItem_MenuContainer_CC__BorderTopLeftRadius,
    setDB_LeftSidebar_MenuItem_MenuContainer_CC__BorderTopLeftRadius,
  ] = useState(20);
  const [
    DB_LeftSidebar_MenuItem_MenuContainer_CC__BorderBottomRightRadius,
    setDB_LeftSidebar_MenuItem_MenuContainer_CC__BorderBottomRightRadius,
  ] = useState(20);
  const [
    DB_LeftSidebar_MenuItem_MenuContainer_CC__BorderBottomLeftRadius,
    setDB_LeftSidebar_MenuItem_MenuContainer_CC__BorderBottomLeftRadius,
  ] = useState(20);

  const [
    DB_LeftSidebar_MenuItem_MenuContainer_CC__Hover_BorderTopRightRadius,
    setDB_LeftSidebar_MenuItem_MenuContainer_CC__Hover_BorderTopRightRadius,
  ] = useState(20);
  const [
    DB_LeftSidebar_MenuItem_MenuContainer_CC__Hover_BorderTopLeftRadius,
    setDB_LeftSidebar_MenuItem_MenuContainer_CC__Hover_BorderTopLeftRadius,
  ] = useState(20);
  const [
    DB_LeftSidebar_MenuItem_MenuContainer_CC__Hover_BorderBottomRightRadius,
    setDB_LeftSidebar_MenuItem_MenuContainer_CC__Hover_BorderBottomRightRadius,
  ] = useState(20);
  const [
    DB_LeftSidebar_MenuItem_MenuContainer_CC__Hover_BorderBottomLeftRadius,
    setDB_LeftSidebar_MenuItem_MenuContainer_CC__Hover_BorderBottomLeftRadius,
  ] = useState(20);

  const [
    DB_LeftSidebar_MenuItem_MenuContainer_CC__BorderColorTop,
    setDB_LeftSidebar_MenuItem_MenuContainer_CC__BorderColorTop,
  ] = useState("rgba(255,255,255,1)");
  const [
    DB_LeftSidebar_MenuItem_MenuContainer_CC__BorderColorBottom,
    setDB_LeftSidebar_MenuItem_MenuContainer_CC__BorderColorBottom,
  ] = useState("rgba(255,255,255,1)");
  const [
    DB_LeftSidebar_MenuItem_MenuContainer_CC__BorderColorLeft,
    setDB_LeftSidebar_MenuItem_MenuContainer_CC__BorderColorLeft,
  ] = useState("rgba(255,255,255,1)");
  const [
    DB_LeftSidebar_MenuItem_MenuContainer_CC__BorderColorRight,
    setDB_LeftSidebar_MenuItem_MenuContainer_CC__BorderColorRight,
  ] = useState("rgba(255,255,255,1)");

  const [
    DB_LeftSidebar_MenuItem_MenuContainer_CC__BorderColorTopNotEncoded,
    setDB_LeftSidebar_MenuItem_MenuContainer_CC__BorderColorTopNotEncoded,
  ] = useState("rgba(255,255,255,1)");
  const [
    DB_LeftSidebar_MenuItem_MenuContainer_CC__BorderColorBottomNotEncoded,
    setDB_LeftSidebar_MenuItem_MenuContainer_CC__BorderColorBottomNotEncoded,
  ] = useState("rgba(255,255,255,1)");
  const [
    DB_LeftSidebar_MenuItem_MenuContainer_CC__BorderColorLeftNotEncoded,
    setDB_LeftSidebar_MenuItem_MenuContainer_CC__BorderColorLeftNotEncoded,
  ] = useState("rgba(255,255,255,1)");
  const [
    DB_LeftSidebar_MenuItem_MenuContainer_CC__BorderColorRightNotEncoded,
    setDB_LeftSidebar_MenuItem_MenuContainer_CC__BorderColorRightNotEncoded,
  ] = useState("rgba(255,255,255,1)");

  const [
    DB_LeftSidebar_MenuContainer_CC__Hover_BorderColorTop,
    setDB_LeftSidebar_MenuContainer_CC__Hover_BorderColorTop,
  ] = useState("rgba(0,0,0,0.3)");
  const [
    DB_LeftSidebar_MenuContainer_CC__Hover_BorderColorBottom,
    setDB_LeftSidebar_MenuContainer_CC__Hover_BorderColorBottom,
  ] = useState("rgba(0,0,0,0.3)");
  const [
    DB_LeftSidebar_MenuContainer_CC__Hover_BorderColorLeft,
    setDB_LeftSidebar_MenuContainer_CC__Hover_BorderColorLeft,
  ] = useState("rgba(0,0,0,0.3)");
  const [
    DB_LeftSidebar_MenuContainer_CC__Hover_BorderColorRight,
    setDB_LeftSidebar_MenuContainer_CC__Hover_BorderColorRight,
  ] = useState("rgba(0,0,0,0.3)");

  const [
    DB_LeftSidebar_MenuContainer_CC__Hover_BorderColorTopNotEncoded,
    setDB_LeftSidebar_MenuContainer_CC__Hover_BorderColorTopNotEncoded,
  ] = useState("rgba(0,0,0,0.3)");
  const [
    DB_LeftSidebar_MenuContainer_CC__Hover_BorderColorBottomNotEncoded,
    setDB_LeftSidebar_MenuContainer_CC__Hover_BorderColorBottomNotEncoded,
  ] = useState("rgba(0,0,0,0.3)");
  const [
    DB_LeftSidebar_MenuContainer_CC__Hover_BorderColorLeftNotEncoded,
    setDB_LeftSidebar_MenuContainer_CC__Hover_BorderColorLeftNotEncoded,
  ] = useState("rgba(0,0,0,0.3)");
  const [
    DB_LeftSidebar_MenuContainer_CC__Hover_BorderColorRightNotEncoded,
    setDB_LeftSidebar_MenuContainer_CC__Hover_BorderColorRightNotEncoded,
  ] = useState("rgba(0,0,0,0.3)");

  const [
    DB_LeftSidebar_MenuContainer_CC__Hover_BorderTop,
    setDB_LeftSidebar_MenuContainer_CC__Hover_BorderTop,
  ] = useState(1);
  const [
    DB_LeftSidebar_MenuContainer_CC__Hover_BorderBottom,
    setDB_LeftSidebar_MenuContainer_CC__Hover_BorderBottom,
  ] = useState(1);
  const [
    DB_LeftSidebar_MenuContainer_CC__Hover_BorderLeft,
    setDB_LeftSidebar_MenuContainer_CC__Hover_BorderLeft,
  ] = useState(1);
  const [
    DB_LeftSidebar_MenuContainer_CC__Hover_BorderRight,
    setDB_LeftSidebar_MenuContainer_CC__Hover_BorderRight,
  ] = useState(1);

  const [
    DB_LeftSidebar_MenuContainer_CC__Hover_BorderStyleTop,
    setDB_LeftSidebar_MenuContainer_CC__Hover_BorderStyleTop,
  ] = useState("solid");
  const [
    DB_LeftSidebar_MenuContainer_CC__Hover_BorderStyleBottom,
    setDB_LeftSidebar_MenuContainer_CC__Hover_BorderStyleBottom,
  ] = useState("solid");
  const [
    DB_LeftSidebar_MenuContainer_CC__Hover_BorderStyleLeft,
    setDB_LeftSidebar_MenuContainer_CC__Hover_BorderStyleLeft,
  ] = useState("solid");
  const [
    DB_LeftSidebar_MenuContainer_CC__Hover_BorderStyleRight,
    setDB_LeftSidebar_MenuContainer_CC__Hover_BorderStyleRight,
  ] = useState("solid");

  const [
    DB_LeftSidebar_MenuContainer_CC__BorderTop,
    setDB_LeftSidebar_MenuContainer_CC__BorderTop,
  ] = useState(1);
  const [
    DB_LeftSidebar_MenuContainer_CC__BorderBottom,
    setDB_LeftSidebar_MenuContainer_CC__BorderBottom,
  ] = useState(1);
  const [
    DB_LeftSidebar_MenuContainer_CC__BorderLeft,
    setDB_LeftSidebar_MenuContainer_CC__BorderLeft,
  ] = useState(1);
  const [
    DB_LeftSidebar_MenuContainer_CC__BorderRight,
    setDB_LeftSidebar_MenuContainer_CC__BorderRight,
  ] = useState(1);

  const [
    DB_LeftSidebar_MenuContainer_CC__BorderStyleTop,
    setDB_LeftSidebar_MenuContainer_CC__BorderStyleTop,
  ] = useState("solid");
  const [
    DB_LeftSidebar_MenuContainer_CC__BorderStyleBottom,
    setDB_LeftSidebar_MenuContainer_CC__BorderStyleBottom,
  ] = useState("solid");
  const [
    DB_LeftSidebar_MenuContainer_CC__BorderStyleLeft,
    setDB_LeftSidebar_MenuContainer_CC__BorderStyleLeft,
  ] = useState("solid");
  const [
    DB_LeftSidebar_MenuContainer_CC__BorderStyleRight,
    setDB_LeftSidebar_MenuContainer_CC__BorderStyleRight,
  ] = useState("solid");

  const [
    DB_LeftSidebar_MenuContainer_CC__BorderColorTop,
    setDB_LeftSidebar_MenuContainer_CC__BorderColorTop,
  ] = useState("rgba(0,0,0,0.3)");
  const [
    DB_LeftSidebar_MenuContainer_CC__BorderColorBottom,
    setDB_LeftSidebar_MenuContainer_CC__BorderColorBottom,
  ] = useState("rgba(0,0,0,0.3)");
  const [
    DB_LeftSidebar_MenuContainer_CC__BorderColorLeft,
    setDB_LeftSidebar_MenuContainer_CC__BorderColorLeft,
  ] = useState("rgba(0,0,0,0.3)");
  const [
    DB_LeftSidebar_MenuContainer_CC__BorderColorRight,
    setDB_LeftSidebar_MenuContainer_CC__BorderColorRight,
  ] = useState("rgba(0,0,0,0.3)");

  const [
    DB_LeftSidebar_MenuContainer_CC__BorderColorTopNotEncoded,
    setDB_LeftSidebar_MenuContainer_CC__BorderColorTopNotEncoded,
  ] = useState("rgba(0,0,0,0.3)");
  const [
    DB_LeftSidebar_MenuContainer_CC__BorderColorBottomNotEncoded,
    setDB_LeftSidebar_MenuContainer_CC__BorderColorBottomNotEncoded,
  ] = useState("rgba(0,0,0,0.3)");
  const [
    DB_LeftSidebar_MenuContainer_CC__BorderColorLeftNotEncoded,
    setDB_LeftSidebar_MenuContainer_CC__BorderColorLeftNotEncoded,
  ] = useState("rgba(0,0,0,0.3)");
  const [
    DB_LeftSidebar_MenuContainer_CC__BorderColorRightNotEncoded,
    setDB_LeftSidebar_MenuContainer_CC__BorderColorRightNotEncoded,
  ] = useState("rgba(0,0,0,0.3)");

  const [
    DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_FontSize,
    setDB_LeftSidebar_MenuItem_Hauptkategorie_Hover_FontSize,
  ] = useState(18);
  const DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_FontFace =
    DB_fontFace_FontFamilyName;
  const [
    DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_FontWeight,
    setDB_LeftSidebar_MenuItem_Hauptkategorie_Hover_FontWeight,
  ] = useState(400);
  const [
    DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_FontColor,
    setDB_LeftSidebar_MenuItem_Hauptkategorie_Hover_FontColor,
  ] = useState("rgba(255,255,255,1)");

  const [
    DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_FontColorNotEncoded,
    setDB_LeftSidebar_MenuItem_Hauptkategorie_Hover_FontColorNotEncoded,
  ] = useState("rgba(0,0,0,0.3)");

  const [
    DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_BackgroundColor,
    setDB_LeftSidebar_MenuItem_Hauptkategorie_Hover_BackgroundColor,
  ] = useState("rgba(0,0,0,0.3)");
  const [
    DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_BackgroundColorNotEncoded,
    setDB_LeftSidebar_MenuItem_Hauptkategorie_Hover_BackgroundColorNotEncoded,
  ] = useState("rgba(0,0,0,0.3)");

  const [
    DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_TextAlign,
    setDB_LeftSidebar_MenuItem_Hauptkategorie_Hover_TextAlign,
  ] = useState("center");

  /////////////////////////////////
  const [
    DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_BorderTopRightRadius,
    setDB_LeftSidebar_MenuItem_Hauptkategorie_Hover_BorderTopRightRadius,
  ] = useState(0);
  const [
    DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_BorderTopLeftRadius,
    setDB_LeftSidebar_MenuItem_Hauptkategorie_Hover_BorderTopLeftRadius,
  ] = useState(0);
  const [
    DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_BorderBottomRightRadius,
    setDB_LeftSidebar_MenuItem_Hauptkategorie_Hover_BorderBottomRightRadius,
  ] = useState(0);
  const [
    DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_BorderBottomLeftRadius,
    setDB_LeftSidebar_MenuItem_Hauptkategorie_Hover_BorderBottomLeftRadius,
  ] = useState(0);

  const [
    DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_BorderColorTop,
    setDB_LeftSidebar_MenuItem_Hauptkategorie_Hover_BorderColorTop,
  ] = useState("rgba(255,255,255,1)");
  const [
    DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_BorderColorBottom,
    setDB_LeftSidebar_MenuItem_Hauptkategorie_Hover_BorderColorBottom,
  ] = useState("rgba(255,255,255,1)");
  const [
    DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_BorderColorLeft,
    setDB_LeftSidebar_MenuItem_Hauptkategorie_Hover_BorderColorLeft,
  ] = useState("rgba(255,255,255,1)");
  const [
    DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_BorderColorRight,
    setDB_LeftSidebar_MenuItem_Hauptkategorie_Hover_BorderColorRight,
  ] = useState("rgba(255,255,255,1)");

  const [
    DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_BorderColorTopNotEncoded,
    setDB_LeftSidebar_MenuItem_Hauptkategorie_Hover_BorderColorTopNotEncoded,
  ] = useState("rgba(255,255,255,1)");
  const [
    DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_BorderColorBottomNotEncoded,
    setDB_LeftSidebar_MenuItem_Hauptkategorie_Hover_BorderColorBottomNotEncoded,
  ] = useState("rgba(255,255,255,1)");
  const [
    DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_BorderColorLeftNotEncoded,
    setDB_LeftSidebar_MenuItem_Hauptkategorie_Hover_BorderColorLeftNotEncoded,
  ] = useState("rgba(255,255,255,1)");
  const [
    DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_BorderColorRightNotEncoded,
    setDB_LeftSidebar_MenuItem_Hauptkategorie_Hover_BorderColorRightNotEncoded,
  ] = useState("rgba(255,255,255,1)");

  const [
    DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_BorderTop,
    setDB_LeftSidebar_MenuItem_Hauptkategorie_Hover_BorderTop,
  ] = useState(1);
  const [
    DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_BorderBottom,
    setDB_LeftSidebar_MenuItem_Hauptkategorie_Hover_BorderBottom,
  ] = useState(1);
  const [
    DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_BorderLeft,
    setDB_LeftSidebar_MenuItem_Hauptkategorie_Hover_BorderLeft,
  ] = useState(0);
  const [
    DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_BorderRight,
    setDB_LeftSidebar_MenuItem_Hauptkategorie_Hover_BorderRight,
  ] = useState(0);

  const [
    DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_BorderStyleTop,
    setDB_LeftSidebar_MenuItem_Hauptkategorie_Hover_BorderStyleTop,
  ] = useState("solid");
  const [
    DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_BorderStyleBottom,
    setDB_LeftSidebar_MenuItem_Hauptkategorie_Hover_BorderStyleBottom,
  ] = useState("solid");
  const [
    DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_BorderStyleLeft,
    setDB_LeftSidebar_MenuItem_Hauptkategorie_Hover_BorderStyleLeft,
  ] = useState("solid");
  const [
    DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_BorderStyleRight,
    setDB_LeftSidebar_MenuItem_Hauptkategorie_Hover_BorderStyleRight,
  ] = useState("solid");

  const DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_HoverBackgroundColor =
    "rgba(255,255,255,0.3)";
  const DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_HoverFontColor =
    "rgba(255,255,255,1)";

  // DB_LeftSidebar - Hauptkategorie Normal .-----------------.---------------------.----------------------
  const [
    DB_LeftSidebar_MenuItem_Hauptkategorie_FontSize,
    setDB_LeftSidebar_MenuItem_Hauptkategorie_FontSize,
  ] = useState(18);
  const DB_LeftSidebar_MenuItem_Hauptkategorie_FontFace =
    DB_fontFace_FontFamilyName;
  const [
    DB_LeftSidebar_MenuItem_Hauptkategorie_FontWeight,
    setDB_LeftSidebar_MenuItem_Hauptkategorie_FontWeight,
  ] = useState(400);
  const [
    DB_LeftSidebar_MenuItem_Hauptkategorie_FontColor,
    setDB_LeftSidebar_MenuItem_Hauptkategorie_FontColor,
  ] = useState("rgba(255,255,255,1)");

  const [
    DB_LeftSidebar_MenuItem_Hauptkategorie_FontColorNotEncoded,
    setDB_LeftSidebar_MenuItem_Hauptkategorie_FontColorNotEncoded,
  ] = useState("rgba(0,0,0,0.3)");

  const [
    DB_LeftSidebar_MenuItem_Hauptkategorie_BackgroundColor,
    setDB_LeftSidebar_MenuItem_Hauptkategorie_BackgroundColor,
  ] = useState("rgba(0,0,0,0.3)");
  const [
    DB_LeftSidebar_MenuItem_Hauptkategorie_BackgroundColorNotEncoded,
    setDB_LeftSidebar_MenuItem_Hauptkategorie_BackgroundColorNotEncoded,
  ] = useState("rgba(0,0,0,0.3)");

  const [
    DB_LeftSidebar_MenuItem_Hauptkategorie_TextAlign,
    setDB_LeftSidebar_MenuItem_Hauptkategorie_TextAlign,
  ] = useState("center");

  /////////////////////////////////
  const [
    DB_LeftSidebar_MenuItem_Hauptkategorie_BorderTopRightRadius,
    setDB_LeftSidebar_MenuItem_Hauptkategorie_BorderTopRightRadius,
  ] = useState(0);
  const [
    DB_LeftSidebar_MenuItem_Hauptkategorie_BorderTopLeftRadius,
    setDB_LeftSidebar_MenuItem_Hauptkategorie_BorderTopLeftRadius,
  ] = useState(0);
  const [
    DB_LeftSidebar_MenuItem_Hauptkategorie_BorderBottomRightRadius,
    setDB_LeftSidebar_MenuItem_Hauptkategorie_BorderBottomRightRadius,
  ] = useState(0);
  const [
    DB_LeftSidebar_MenuItem_Hauptkategorie_BorderBottomLeftRadius,
    setDB_LeftSidebar_MenuItem_Hauptkategorie_BorderBottomLeftRadius,
  ] = useState(0);

  const [
    DB_LeftSidebar_MenuItem_Hauptkategorie_BorderColorTop,
    setDB_LeftSidebar_MenuItem_Hauptkategorie_BorderColorTop,
  ] = useState("rgba(255,255,255,1)");
  const [
    DB_LeftSidebar_MenuItem_Hauptkategorie_BorderColorBottom,
    setDB_LeftSidebar_MenuItem_Hauptkategorie_BorderColorBottom,
  ] = useState("rgba(255,255,255,1)");
  const [
    DB_LeftSidebar_MenuItem_Hauptkategorie_BorderColorLeft,
    setDB_LeftSidebar_MenuItem_Hauptkategorie_BorderColorLeft,
  ] = useState("rgba(255,255,255,1)");
  const [
    DB_LeftSidebar_MenuItem_Hauptkategorie_BorderColorRight,
    setDB_LeftSidebar_MenuItem_Hauptkategorie_BorderColorRight,
  ] = useState("rgba(255,255,255,1)");

  const [
    DB_LeftSidebar_MenuItem_Hauptkategorie_BorderColorTopNotEncoded,
    setDB_LeftSidebar_MenuItem_Hauptkategorie_BorderColorTopNotEncoded,
  ] = useState("rgba(255,255,255,1)");
  const [
    DB_LeftSidebar_MenuItem_Hauptkategorie_BorderColorBottomNotEncoded,
    setDB_LeftSidebar_MenuItem_Hauptkategorie_BorderColorBottomNotEncoded,
  ] = useState("rgba(255,255,255,1)");
  const [
    DB_LeftSidebar_MenuItem_Hauptkategorie_BorderColorLeftNotEncoded,
    setDB_LeftSidebar_MenuItem_Hauptkategorie_BorderColorLeftNotEncoded,
  ] = useState("rgba(255,255,255,1)");
  const [
    DB_LeftSidebar_MenuItem_Hauptkategorie_BorderColorRightNotEncoded,
    setDB_LeftSidebar_MenuItem_Hauptkategorie_BorderColorRightNotEncoded,
  ] = useState("rgba(255,255,255,1)");

  const [
    DB_LeftSidebar_MenuItem_Hauptkategorie_BorderTop,
    setDB_LeftSidebar_MenuItem_Hauptkategorie_BorderTop,
  ] = useState(1);
  const [
    DB_LeftSidebar_MenuItem_Hauptkategorie_BorderBottom,
    setDB_LeftSidebar_MenuItem_Hauptkategorie_BorderBottom,
  ] = useState(1);
  const [
    DB_LeftSidebar_MenuItem_Hauptkategorie_BorderLeft,
    setDB_LeftSidebar_MenuItem_Hauptkategorie_BorderLeft,
  ] = useState(0);
  const [
    DB_LeftSidebar_MenuItem_Hauptkategorie_BorderRight,
    setDB_LeftSidebar_MenuItem_Hauptkategorie_BorderRight,
  ] = useState(0);

  const [
    DB_LeftSidebar_MenuItem_Hauptkategorie_BorderStyleTop,
    setDB_LeftSidebar_MenuItem_Hauptkategorie_BorderStyleTop,
  ] = useState("solid");
  const [
    DB_LeftSidebar_MenuItem_Hauptkategorie_BorderStyleBottom,
    setDB_LeftSidebar_MenuItem_Hauptkategorie_BorderStyleBottom,
  ] = useState("solid");
  const [
    DB_LeftSidebar_MenuItem_Hauptkategorie_BorderStyleLeft,
    setDB_LeftSidebar_MenuItem_Hauptkategorie_BorderStyleLeft,
  ] = useState("solid");
  const [
    DB_LeftSidebar_MenuItem_Hauptkategorie_BorderStyleRight,
    setDB_LeftSidebar_MenuItem_Hauptkategorie_BorderStyleRight,
  ] = useState("solid");

  const DB_LeftSidebar_MenuItem_Hauptkategorie_HoverBackgroundColor =
    "rgba(255,255,255,0.3)";
  const DB_LeftSidebar_MenuItem_Hauptkategorie_HoverFontColor =
    "rgba(255,255,255,1)";

  // DB_LeftSidebar - Unterkategorie Hover ----------------..------------------.--------------
  const [
    DB_LeftSidebar_MenuItem_Unterkategorie_Hover_FontSize,
    setDB_LeftSidebar_MenuItem_Unterkategorie_Hover_FontSize,
  ] = useState(16);
  const DB_LeftSidebar_MenuItem_Unterkategorie_Hover_FontFace =
    DB_fontFace_FontFamilyName;
  const [
    DB_LeftSidebar_MenuItem_Unterkategorie_Hover_FontWeight,
    setDB_LeftSidebar_MenuItem_Unterkategorie_Hover_FontWeight,
  ] = useState(400);
  const [
    DB_LeftSidebar_MenuItem_Unterkategorie_Hover_FontColor,
    setDB_LeftSidebar_MenuItem_Unterkategorie_Hover_FontColor,
  ] = useState("rgba(255,255,255,1)");

  const [
    DB_LeftSidebar_MenuItem_Unterkategorie_Hover_FontColorNotEncoded,
    setDB_LeftSidebar_MenuItem_Unterkategorie_Hover_FontColorNotEncoded,
  ] = useState("rgba(0,0,0,0.3)");

  const [
    DB_LeftSidebar_MenuItem_Unterkategorie_Hover_BackgroundColor,
    setDB_LeftSidebar_MenuItem_Unterkategorie_Hover_BackgroundColor,
  ] = useState("rgba(255,255,255,0.1)");
  const [
    DB_LeftSidebar_MenuItem_Unterkategorie_Hover_BackgroundColorNotEncoded,
    setDB_LeftSidebar_MenuItem_Unterkategorie_Hover_BackgroundColorNotEncoded,
  ] = useState("rgba(255,255,255,0.1)");

  const [
    DB_LeftSidebar_MenuItem_Unterkategorie_Hover_TextAlign,
    setDB_LeftSidebar_MenuItem_Unterkategorie_Hover_TextAlign,
  ] = useState("center");

  /////////////////////////////////
  const [
    DB_LeftSidebar_MenuItem_Unterkategorie_Hover_BorderTopRightRadius,
    setDB_LeftSidebar_MenuItem_Unterkategorie_Hover_BorderTopRightRadius,
  ] = useState(5);
  const [
    DB_LeftSidebar_MenuItem_Unterkategorie_Hover_BorderTopLeftRadius,
    setDB_LeftSidebar_MenuItem_Unterkategorie_Hover_BorderTopLeftRadius,
  ] = useState(5);
  const [
    DB_LeftSidebar_MenuItem_Unterkategorie_Hover_BorderBottomRightRadius,
    setDB_LeftSidebar_MenuItem_Unterkategorie_Hover_BorderBottomRightRadius,
  ] = useState(5);
  const [
    DB_LeftSidebar_MenuItem_Unterkategorie_Hover_BorderBottomLeftRadius,
    setDB_LeftSidebar_MenuItem_Unterkategorie_Hover_BorderBottomLeftRadius,
  ] = useState(5);

  const [
    DB_LeftSidebar_MenuItem_Unterkategorie_Hover_BorderColorTop,
    setDB_LeftSidebar_MenuItem_Unterkategorie_Hover_BorderColorTop,
  ] = useState("rgba(255,255,255,1)");
  const [
    DB_LeftSidebar_MenuItem_Unterkategorie_Hover_BorderColorBottom,
    setDB_LeftSidebar_MenuItem_Unterkategorie_Hover_BorderColorBottom,
  ] = useState("rgba(255,255,255,1)");
  const [
    DB_LeftSidebar_MenuItem_Unterkategorie_Hover_BorderColorLeft,
    setDB_LeftSidebar_MenuItem_Unterkategorie_Hover_BorderColorLeft,
  ] = useState("rgba(255,255,255,1)");
  const [
    DB_LeftSidebar_MenuItem_Unterkategorie_Hover_BorderColorRight,
    setDB_LeftSidebar_MenuItem_Unterkategorie_Hover_BorderColorRight,
  ] = useState("rgba(255,255,255,1)");

  const [
    DB_LeftSidebar_MenuItem_Unterkategorie_Hover_BorderColorTopNotEncoded,
    setDB_LeftSidebar_MenuItem_Unterkategorie_Hover_BorderColorTopNotEncoded,
  ] = useState("rgba(255,255,255,1)");
  const [
    DB_LeftSidebar_MenuItem_Unterkategorie_Hover_BorderColorBottomNotEncoded,
    setDB_LeftSidebar_MenuItem_Unterkategorie_Hover_BorderColorBottomNotEncoded,
  ] = useState("rgba(255,255,255,1)");
  const [
    DB_LeftSidebar_MenuItem_Unterkategorie_Hover_BorderColorLeftNotEncoded,
    setDB_LeftSidebar_MenuItem_Unterkategorie_Hover_BorderColorLeftNotEncoded,
  ] = useState("rgba(255,255,255,1)");
  const [
    DB_LeftSidebar_MenuItem_Unterkategorie_Hover_BorderColorRightNotEncoded,
    setDB_LeftSidebar_MenuItem_Unterkategorie_Hover_BorderColorRightNotEncoded,
  ] = useState("rgba(255,255,255,1)");

  const [
    DB_LeftSidebar_MenuItem_Unterkategorie_Hover_BorderTop,
    setDB_LeftSidebar_MenuItem_Unterkategorie_Hover_BorderTop,
  ] = useState(0);
  const [
    DB_LeftSidebar_MenuItem_Unterkategorie_Hover_BorderBottom,
    setDB_LeftSidebar_MenuItem_Unterkategorie_Hover_BorderBottom,
  ] = useState(0);
  const [
    DB_LeftSidebar_MenuItem_Unterkategorie_Hover_BorderLeft,
    setDB_LeftSidebar_MenuItem_Unterkategorie_Hover_BorderLeft,
  ] = useState(0);
  const [
    DB_LeftSidebar_MenuItem_Unterkategorie_Hover_BorderRight,
    setDB_LeftSidebar_MenuItem_Unterkategorie_Hover_BorderRight,
  ] = useState(0);

  const [
    DB_LeftSidebar_MenuItem_Unterkategorie_Hover_BorderStyleTop,
    setDB_LeftSidebar_MenuItem_Unterkategorie_Hover_BorderStyleTop,
  ] = useState("solid");
  const [
    DB_LeftSidebar_MenuItem_Unterkategorie_Hover_BorderStyleBottom,
    setDB_LeftSidebar_MenuItem_Unterkategorie_Hover_BorderStyleBottom,
  ] = useState("solid");
  const [
    DB_LeftSidebar_MenuItem_Unterkategorie_Hover_BorderStyleLeft,
    setDB_LeftSidebar_MenuItem_Unterkategorie_Hover_BorderStyleLeft,
  ] = useState("solid");
  const [
    DB_LeftSidebar_MenuItem_Unterkategorie_Hover_BorderStyleRight,
    setDB_LeftSidebar_MenuItem_Unterkategorie_Hover_BorderStyleRight,
  ] = useState("solid");

  // DB_LeftSidebar - Unterkategorie Normal ----------------..------------------.--------------
  const [
    DB_LeftSidebar_MenuItem_Unterkategorie_FontSize,
    setDB_LeftSidebar_MenuItem_Unterkategorie_FontSize,
  ] = useState(16);
  const DB_LeftSidebar_MenuItem_Unterkategorie_FontFace =
    DB_fontFace_FontFamilyName;
  const [
    DB_LeftSidebar_MenuItem_Unterkategorie_FontWeight,
    setDB_LeftSidebar_MenuItem_Unterkategorie_FontWeight,
  ] = useState(400);
  const [
    DB_LeftSidebar_MenuItem_Unterkategorie_FontColor,
    setDB_LeftSidebar_MenuItem_Unterkategorie_FontColor,
  ] = useState("rgba(255,255,255,1)");

  const [
    DB_LeftSidebar_MenuItem_Unterkategorie_FontColorNotEncoded,
    setDB_LeftSidebar_MenuItem_Unterkategorie_FontColorNotEncoded,
  ] = useState("rgba(0,0,0,0.3)");

  const [
    DB_LeftSidebar_MenuItem_Unterkategorie_BackgroundColor,
    setDB_LeftSidebar_MenuItem_Unterkategorie_BackgroundColor,
  ] = useState("rgba(255,255,255,0.1)");
  const [
    DB_LeftSidebar_MenuItem_Unterkategorie_BackgroundColorNotEncoded,
    setDB_LeftSidebar_MenuItem_Unterkategorie_BackgroundColorNotEncoded,
  ] = useState("rgba(255,255,255,0.1)");

  const [
    DB_LeftSidebar_MenuItem_Unterkategorie_TextAlign,
    setDB_LeftSidebar_MenuItem_Unterkategorie_TextAlign,
  ] = useState("center");

  /////////////////////////////////

  const [
    DB_LeftSidebar_MenuItem_Unterkategorie_BorderTopRightRadius,
    setDB_LeftSidebar_MenuItem_Unterkategorie_BorderTopRightRadius,
  ] = useState(5);
  const [
    DB_LeftSidebar_MenuItem_Unterkategorie_BorderTopLeftRadius,
    setDB_LeftSidebar_MenuItem_Unterkategorie_BorderTopLeftRadius,
  ] = useState(5);
  const [
    DB_LeftSidebar_MenuItem_Unterkategorie_BorderBottomRightRadius,
    setDB_LeftSidebar_MenuItem_Unterkategorie_BorderBottomRightRadius,
  ] = useState(5);
  const [
    DB_LeftSidebar_MenuItem_Unterkategorie_BorderBottomLeftRadius,
    setDB_LeftSidebar_MenuItem_Unterkategorie_BorderBottomLeftRadius,
  ] = useState(5);

  const [
    DB_LeftSidebar_MenuItem_Unterkategorie_BorderColorTop,
    setDB_LeftSidebar_MenuItem_Unterkategorie_BorderColorTop,
  ] = useState("rgba(255,255,255,1)");
  const [
    DB_LeftSidebar_MenuItem_Unterkategorie_BorderColorBottom,
    setDB_LeftSidebar_MenuItem_Unterkategorie_BorderColorBottom,
  ] = useState("rgba(255,255,255,1)");
  const [
    DB_LeftSidebar_MenuItem_Unterkategorie_BorderColorLeft,
    setDB_LeftSidebar_MenuItem_Unterkategorie_BorderColorLeft,
  ] = useState("rgba(255,255,255,1)");
  const [
    DB_LeftSidebar_MenuItem_Unterkategorie_BorderColorRight,
    setDB_LeftSidebar_MenuItem_Unterkategorie_BorderColorRight,
  ] = useState("rgba(255,255,255,1)");

  const [
    DB_LeftSidebar_MenuItem_Unterkategorie_BorderColorTopNotEncoded,
    setDB_LeftSidebar_MenuItem_Unterkategorie_BorderColorTopNotEncoded,
  ] = useState("rgba(255,255,255,1)");
  const [
    DB_LeftSidebar_MenuItem_Unterkategorie_BorderColorBottomNotEncoded,
    setDB_LeftSidebar_MenuItem_Unterkategorie_BorderColorBottomNotEncoded,
  ] = useState("rgba(255,255,255,1)");
  const [
    DB_LeftSidebar_MenuItem_Unterkategorie_BorderColorLeftNotEncoded,
    setDB_LeftSidebar_MenuItem_Unterkategorie_BorderColorLeftNotEncoded,
  ] = useState("rgba(255,255,255,1)");
  const [
    DB_LeftSidebar_MenuItem_Unterkategorie_BorderColorRightNotEncoded,
    setDB_LeftSidebar_MenuItem_Unterkategorie_BorderColorRightNotEncoded,
  ] = useState("rgba(255,255,255,1)");

  const [
    DB_LeftSidebar_MenuItem_Unterkategorie_BorderTop,
    setDB_LeftSidebar_MenuItem_Unterkategorie_BorderTop,
  ] = useState(0);
  const [
    DB_LeftSidebar_MenuItem_Unterkategorie_BorderBottom,
    setDB_LeftSidebar_MenuItem_Unterkategorie_BorderBottom,
  ] = useState(0);
  const [
    DB_LeftSidebar_MenuItem_Unterkategorie_BorderLeft,
    setDB_LeftSidebar_MenuItem_Unterkategorie_BorderLeft,
  ] = useState(0);
  const [
    DB_LeftSidebar_MenuItem_Unterkategorie_BorderRight,
    setDB_LeftSidebar_MenuItem_Unterkategorie_BorderRight,
  ] = useState(0);

  const [
    DB_LeftSidebar_MenuItem_Unterkategorie_BorderStyleTop,
    setDB_LeftSidebar_MenuItem_Unterkategorie_BorderStyleTop,
  ] = useState("solid");
  const [
    DB_LeftSidebar_MenuItem_Unterkategorie_BorderStyleBottom,
    setDB_LeftSidebar_MenuItem_Unterkategorie_BorderStyleBottom,
  ] = useState("solid");
  const [
    DB_LeftSidebar_MenuItem_Unterkategorie_BorderStyleLeft,
    setDB_LeftSidebar_MenuItem_Unterkategorie_BorderStyleLeft,
  ] = useState("solid");
  const [
    DB_LeftSidebar_MenuItem_Unterkategorie_BorderStyleRight,
    setDB_LeftSidebar_MenuItem_Unterkategorie_BorderStyleRight,
  ] = useState("solid");

  const DB_LeftSidebar_MenuItem_Unterkategorie_HoverBackgroundColor =
    "rgba(255,255,255,0.3)";
  const DB_LeftSidebar_MenuItem_Unterkategorie_HoverFontColor =
    "rgba(255,255,255,1)";

  const [
    DB_LeftSidebar_MenuItem_Unterkategorie_marginTopBottom,
    setDB_LeftSidebar_MenuItem_Unterkategorie_marginTopBottom,
  ] = useState("5");
  const [
    DB_LeftSidebar_MenuItem_Unterkategorie_marginLeftRight,
    setDB_LeftSidebar_MenuItem_Unterkategorie_marginLeftRight,
  ] = useState("5");

  const [
    DB_LeftSidebar_MenuItem_Unterkategorie_paddingLeftRight,
    setDB_LeftSidebar_MenuItem_Unterkategorie_paddingLeftRight,
  ] = useState("5");

  const [
    DB_LeftSidebar_MenuItem_Unterkategorie_paddingTopBottom,
    setDB_LeftSidebar_MenuItem_Unterkategorie_paddingTopBottom,
  ] = useState("5");

  const [
    DB_LeftSidebar_MenuItem_MenuContainer_CC__paddingLeft,
    setDB_LeftSidebar_MenuItem_MenuContainer_CC__paddingLeft,
  ] = useState("5");

  const [
    DB_LeftSidebar_MenuItem_MenuContainer_CC__paddingTop,
    setDB_LeftSidebar_MenuItem_MenuContainer_CC__paddingTop,
  ] = useState("5");

  const [
    DB_LeftSidebar_MenuItem_MenuContainer_CC__paddingRight,
    setDB_LeftSidebar_MenuItem_MenuContainer_CC__paddingRight,
  ] = useState("5");

  const [
    DB_LeftSidebar_MenuItem_MenuContainer_CC__paddingBottom,
    setDB_LeftSidebar_MenuItem_MenuContainer_CC__paddingBottom,
  ] = useState("5");

  const [
    DB_LeftSidebar_MenuItem_MenuContainer_CC__Hover_paddingLeft,
    setDB_LeftSidebar_MenuItem_MenuContainer_CC__Hover_paddingLeft,
  ] = useState("5");

  const [
    DB_LeftSidebar_MenuItem_MenuContainer_CC__Hover_paddingTop,
    setDB_LeftSidebar_MenuItem_MenuContainer_CC__Hover_paddingTop,
  ] = useState("5");

  const [
    DB_LeftSidebar_MenuItem_MenuContainer_CC__Hover_paddingRight,
    setDB_LeftSidebar_MenuItem_MenuContainer_CC__Hover_paddingRight,
  ] = useState("5");

  const [
    DB_LeftSidebar_MenuItem_MenuContainer_CC__Hover_paddingBottom,
    setDB_LeftSidebar_MenuItem_MenuContainer_CC__Hover_paddingBottom,
  ] = useState("5");

  const [
    DB_LeftSidebar_MenuItem_Unterkategorie_letterSpacing,
    setDB_LeftSidebar_MenuItem_Unterkategorie_letterSpacing,
  ] = useState("0");

  const [
    DB_LeftSidebar_MenuItem_Unterkategorie_marginTopBottom_Hover,
    setDB_LeftSidebar_MenuItem_Unterkategorie_marginTopBottom_Hover,
  ] = useState("5");
  const [
    DB_LeftSidebar_MenuItem_Unterkategorie_marginLeftRight_Hover,
    setDB_LeftSidebar_MenuItem_Unterkategorie_marginLeftRight_Hover,
  ] = useState("5");

  const [
    DB_LeftSidebar_MenuItem_Unterkategorie_paddingLeftRight_Hover,
    setDB_LeftSidebar_MenuItem_Unterkategorie_paddingLeftRight_Hover,
  ] = useState("5");

  const [
    DB_LeftSidebar_MenuItem_Unterkategorie_paddingTopBottom_Hover,
    setDB_LeftSidebar_MenuItem_Unterkategorie_paddingTopBottom_Hover,
  ] = useState("5");

  const [
    DB_LeftSidebar_MenuItem_Unterkategorie_letterSpacing_Hover,
    setDB_LeftSidebar_MenuItem_Unterkategorie_letterSpacing_Hover,
  ] = useState("0");

  const [
    DB_LeftSidebar_MenuItem_Unterkategorie_ShadowHorizontal,
    setDB_LeftSidebar_MenuItem_Unterkategorie_ShadowHorizontal,
  ] = useState(1);
  const [
    DB_LeftSidebar_MenuItem_Unterkategorie_ShadowVertical,
    setDB_LeftSidebar_MenuItem_Unterkategorie_ShadowVertical,
  ] = useState(1);
  const [
    DB_LeftSidebar_MenuItem_Unterkategorie_ShadowBlur,
    setDB_LeftSidebar_MenuItem_Unterkategorie_ShadowBlur,
  ] = useState(5);
  const [
    DB_LeftSidebar_MenuItem_Unterkategorie_ShadowColor,
    setDB_LeftSidebar_MenuItem_Unterkategorie_ShadowColor,
  ] = useState("rgba(255,255,255,1)");

  const [
    DB_LeftSidebar_MenuItem_Unterkategorie_ShadowColorNotEncoded,
    setDB_LeftSidebar_MenuItem_Unterkategorie_ShadowColorNotEncoded,
  ] = useState("rgba(255,255,255,1)");

  const [
    DB_LeftSidebar_MenuItem_MenuContainer_CC__ShadowHorizontal,
    setDB_LeftSidebar_MenuItem_MenuContainer_CC__ShadowHorizontal,
  ] = useState(1);
  const [
    DB_LeftSidebar_MenuItem_MenuContainer_CC__ShadowVertical,
    setDB_LeftSidebar_MenuItem_MenuContainer_CC__ShadowVertical,
  ] = useState(1);
  const [
    DB_LeftSidebar_MenuItem_MenuContainer_CC__ShadowBlur,
    setDB_LeftSidebar_MenuItem_MenuContainer_CC__ShadowBlur,
  ] = useState(5);
  const [
    DB_LeftSidebar_MenuItem_MenuContainer_CC__ShadowColor,
    setDB_LeftSidebar_MenuItem_MenuContainer_CC__ShadowColor,
  ] = useState("rgba(255,255,255,1)");

  const [
    DB_LeftSidebar_MenuItem_MenuContainer_CC__ShadowColorNotEncoded,
    setDB_LeftSidebar_MenuItem_MenuContainer_CC__ShadowColorNotEncoded,
  ] = useState("rgba(255,255,255,1)");

  const [
    DB_LeftSidebar_MenuItem_MenuContainer_CC__Hover_ShadowHorizontal,
    setDB_LeftSidebar_MenuItem_MenuContainer_CC__Hover_ShadowHorizontal,
  ] = useState(1);
  const [
    DB_LeftSidebar_MenuItem_MenuContainer_CC__Hover_ShadowVertical,
    setDB_LeftSidebar_MenuItem_MenuContainer_CC__Hover_ShadowVertical,
  ] = useState(1);
  const [
    DB_LeftSidebar_MenuItem_MenuContainer_CC__Hover_ShadowBlur,
    setDB_LeftSidebar_MenuItem_MenuContainer_CC__Hover_ShadowBlur,
  ] = useState(5);
  const [
    DB_LeftSidebar_MenuItem_MenuContainer_CC__Hover_ShadowColor,
    setDB_LeftSidebar_MenuItem_MenuContainer_CC__Hover_ShadowColor,
  ] = useState("rgba(255,255,255,1)");

  const [
    DB_LeftSidebar_MenuItem_MenuContainer_CC__Hover_ShadowColorNotEncoded,
    setDB_LeftSidebar_MenuItem_MenuContainer_CC__Hover_ShadowColorNotEncoded,
  ] = useState("rgba(255,255,255,1)");

  const [
    DB_LeftSidebar_MenuItem_Unterkategorie_TextDecoration,
    setDB_LeftSidebar_MenuItem_Unterkategorie_TextDecoration,
  ] = useState("normal");

  const [
    DB_LeftSidebar_MenuItem_Unterkategorie_TextDecorationStyle,
    setDB_LeftSidebar_MenuItem_Unterkategorie_TextDecorationStyle,
  ] = useState("");

  const [
    DB_LeftSidebar_MenuItem_Unterkategorie_ShadowHorizontal_Hover,
    setDB_LeftSidebar_MenuItem_Unterkategorie_ShadowHorizontal_Hover,
  ] = useState(1);
  const [
    DB_LeftSidebar_MenuItem_Unterkategorie_ShadowVertical_Hover,
    setDB_LeftSidebar_MenuItem_Unterkategorie_ShadowVertical_Hover,
  ] = useState(1);
  const [
    DB_LeftSidebar_MenuItem_Unterkategorie_ShadowBlur_Hover,
    setDB_LeftSidebar_MenuItem_Unterkategorie_ShadowBlur_Hover,
  ] = useState(5);
  const [
    DB_LeftSidebar_MenuItem_Unterkategorie_ShadowColor_Hover,
    setDB_LeftSidebar_MenuItem_Unterkategorie_ShadowColor_Hover,
  ] = useState("rgba(255,255,255,1)");
  const [
    DB_LeftSidebar_MenuItem_Unterkategorie_TextDecoration_Hover,
    setDB_LeftSidebar_MenuItem_Unterkategorie_TextDecoration_Hover,
  ] = useState("none");
  const [
    DB_LeftSidebar_MenuItem_Unterkategorie_TextDecorationStyle_Hover,
    setDB_LeftSidebar_MenuItem_Unterkategorie_TextDecorationStyle_Hover,
  ] = useState("");

  const [
    DB_LeftSidebar_MenuItem_Unterkategorie_ShadowColorNotEncoded_Hover,
    setDB_LeftSidebar_MenuItem_Unterkategorie_ShadowColorNotEncoded_Hover,
  ] = useState("rgba(255,255,255,1)");

  const [
    DB_LeftSidebar_MenuItem_Hauptkategorie_marginTopBottom,
    setDB_LeftSidebar_MenuItem_Hauptkategorie_marginTopBottom,
  ] = useState("5");
  const [
    DB_LeftSidebar_MenuItem_Hauptkategorie_marginLeftRight,
    setDB_LeftSidebar_MenuItem_Hauptkategorie_marginLeftRight,
  ] = useState("5");

  const [
    DB_LeftSidebar_MenuItem_Hauptkategorie_paddingLeftRight,
    setDB_LeftSidebar_MenuItem_Hauptkategorie_paddingLeftRight,
  ] = useState("5");

  const [
    DB_LeftSidebar_MenuItem_Hauptkategorie_paddingTopBottom,
    setDB_LeftSidebar_MenuItem_Hauptkategorie_paddingTopBottom,
  ] = useState("5");

  const [
    DB_LeftSidebar_MenuItem_Hauptkategorie_letterSpacing,
    setDB_LeftSidebar_MenuItem_Hauptkategorie_letterSpacing,
  ] = useState("0");

  const [
    DB_LeftSidebar_MenuItem_Hauptkategorie_marginTopBottom_Hover,
    setDB_LeftSidebar_MenuItem_Hauptkategorie_marginTopBottom_Hover,
  ] = useState("5");
  const [
    DB_LeftSidebar_MenuItem_Hauptkategorie_marginLeftRight_Hover,
    setDB_LeftSidebar_MenuItem_Hauptkategorie_marginLeftRight_Hover,
  ] = useState("5");

  const [
    DB_LeftSidebar_MenuItem_Hauptkategorie_paddingLeftRight_Hover,
    setDB_LeftSidebar_MenuItem_Hauptkategorie_paddingLeftRight_Hover,
  ] = useState("5");

  const [
    DB_LeftSidebar_MenuItem_Hauptkategorie_paddingTopBottom_Hover,
    setDB_LeftSidebar_MenuItem_Hauptkategorie_paddingTopBottom_Hover,
  ] = useState("5");

  const [
    DB_LeftSidebar_MenuItem_Hauptkategorie_letterSpacing_Hover,
    setDB_LeftSidebar_MenuItem_Hauptkategorie_letterSpacing_Hover,
  ] = useState("0");

  const [
    DB_LeftSidebar_MenuItem_Hauptkategorie_ShadowHorizontal,
    setDB_LeftSidebar_MenuItem_Hauptkategorie_ShadowHorizontal,
  ] = useState(1);
  const [
    DB_LeftSidebar_MenuItem_Hauptkategorie_ShadowVertical,
    setDB_LeftSidebar_MenuItem_Hauptkategorie_ShadowVertical,
  ] = useState(1);
  const [
    DB_LeftSidebar_MenuItem_Hauptkategorie_ShadowBlur,
    setDB_LeftSidebar_MenuItem_Hauptkategorie_ShadowBlur,
  ] = useState(5);
  const [
    DB_LeftSidebar_MenuItem_Hauptkategorie_ShadowColor,
    setDB_LeftSidebar_MenuItem_Hauptkategorie_ShadowColor,
  ] = useState("rgba(255,255,255,1)");

  const [
    DB_LeftSidebar_MenuItem_Hauptkategorie_ShadowColorNotEncoded,
    setDB_LeftSidebar_MenuItem_Hauptkategorie_ShadowColorNotEncoded,
  ] = useState("rgba(255,255,255,1)");

  const [
    DB_LeftSidebar_MenuItem_Hauptkategorie_TextDecoration,
    setDB_LeftSidebar_MenuItem_Hauptkategorie_TextDecoration,
  ] = useState("normal");

  const [
    DB_LeftSidebar_MenuItem_Hauptkategorie_TextDecorationStyle,
    setDB_LeftSidebar_MenuItem_Hauptkategorie_TextDecorationStyle,
  ] = useState("");

  const [
    DB_LeftSidebar_MenuItem_Hauptkategorie_ShadowHorizontal_Hover,
    setDB_LeftSidebar_MenuItem_Hauptkategorie_ShadowHorizontal_Hover,
  ] = useState(1);
  const [
    DB_LeftSidebar_MenuItem_Hauptkategorie_ShadowVertical_Hover,
    setDB_LeftSidebar_MenuItem_Hauptkategorie_ShadowVertical_Hover,
  ] = useState(1);
  const [
    DB_LeftSidebar_MenuItem_Hauptkategorie_ShadowBlur_Hover,
    setDB_LeftSidebar_MenuItem_Hauptkategorie_ShadowBlur_Hover,
  ] = useState(5);
  const [
    DB_LeftSidebar_MenuItem_Hauptkategorie_ShadowColor_Hover,
    setDB_LeftSidebar_MenuItem_Hauptkategorie_ShadowColor_Hover,
  ] = useState("rgba(255,255,255,1)");
  const [
    DB_LeftSidebar_MenuItem_Hauptkategorie_TextDecoration_Hover,
    setDB_LeftSidebar_MenuItem_Hauptkategorie_TextDecoration_Hover,
  ] = useState("none");
  const [
    DB_LeftSidebar_MenuItem_Hauptkategorie_TextDecorationStyle_Hover,
    setDB_LeftSidebar_MenuItem_Hauptkategorie_TextDecorationStyle_Hover,
  ] = useState("");

  const [
    DB_LeftSidebar_MenuItem_Hauptkategorie_ShadowColorNotEncoded_Hover,
    setDB_LeftSidebar_MenuItem_Hauptkategorie_ShadowColorNotEncoded_Hover,
  ] = useState("rgba(255,255,255,1)");

  // DB_LeftSidebar - UnterUnterkategorie Hover
  const [
    DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_FontSize,
    setDB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_FontSize,
  ] = useState(14);
  const DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_FontFace =
    DB_fontFace_FontFamilyName;
  const [
    DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_FontWeight,
    setDB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_FontWeight,
  ] = useState(400);
  const [
    DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_FontColor,
    setDB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_FontColor,
  ] = useState("rgba(255,255,255,1)");

  const [
    DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_FontColorNotEncoded,
    setDB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_FontColorNotEncoded,
  ] = useState("rgba(0,0,0,0)");

  const [
    DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_BackgroundColor,
    setDB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_BackgroundColor,
  ] = useState("rgba(0,0,0,0)");
  const [
    DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_BackgroundColorNotEncoded,
    setDB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_BackgroundColorNotEncoded,
  ] = useState("rgba(0,0,0,0)");

  const [
    DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_TextAlign,
    setDB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_TextAlign,
  ] = useState("center");

  /////////////////////////////////
  const [
    DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_BorderTopRightRadius,
    setDB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_BorderTopRightRadius,
  ] = useState(0);
  const [
    DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_BorderTopLeftRadius,
    setDB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_BorderTopLeftRadius,
  ] = useState(0);
  const [
    DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_BorderBottomRightRadius,
    setDB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_BorderBottomRightRadius,
  ] = useState(0);
  const [
    DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_BorderBottomLeftRadius,
    setDB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_BorderBottomLeftRadius,
  ] = useState(0);

  const [
    DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_BorderColorTop,
    setDB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_BorderColorTop,
  ] = useState("rgba(255,255,255,1)");
  const [
    DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_BorderColorBottom,
    setDB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_BorderColorBottom,
  ] = useState("rgba(255,255,255,1)");
  const [
    DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_BorderColorLeft,
    setDB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_BorderColorLeft,
  ] = useState("rgba(255,255,255,1)");
  const [
    DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_BorderColorRight,
    setDB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_BorderColorRight,
  ] = useState("rgba(255,255,255,1)");

  const [
    DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_BorderColorTopNotEncoded,
    setDB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_BorderColorTopNotEncoded,
  ] = useState("rgba(255,255,255,1)");
  const [
    DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_BorderColorBottomNotEncoded,
    setDB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_BorderColorBottomNotEncoded,
  ] = useState("rgba(255,255,255,1)");
  const [
    DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_BorderColorLeftNotEncoded,
    setDB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_BorderColorLeftNotEncoded,
  ] = useState("rgba(255,255,255,1)");
  const [
    DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_BorderColorRightNotEncoded,
    setDB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_BorderColorRightNotEncoded,
  ] = useState("rgba(255,255,255,1)");

  const [
    DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_BorderTop,
    setDB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_BorderTop,
  ] = useState(0);
  const [
    DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_BorderBottom,
    setDB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_BorderBottom,
  ] = useState(0);
  const [
    DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_BorderLeft,
    setDB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_BorderLeft,
  ] = useState(0);
  const [
    DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_BorderRight,
    setDB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_BorderRight,
  ] = useState(0);

  const [
    DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_BorderStyleTop,
    setDB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_BorderStyleTop,
  ] = useState("solid");
  const [
    DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_BorderStyleBottom,
    setDB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_BorderStyleBottom,
  ] = useState("solid");
  const [
    DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_BorderStyleLeft,
    setDB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_BorderStyleLeft,
  ] = useState("solid");
  const [
    DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_BorderStyleRight,
    setDB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_BorderStyleRight,
  ] = useState("solid");

  const [
    DB_LeftSidebar_MenuItem_UnterUnterkategorie_marginTopBottom,
    setDB_LeftSidebar_MenuItem_UnterUnterkategorie_marginTopBottom,
  ] = useState("5");
  const [
    DB_LeftSidebar_MenuItem_UnterUnterkategorie_marginLeftRight,
    setDB_LeftSidebar_MenuItem_UnterUnterkategorie_marginLeftRight,
  ] = useState("5");

  const [
    DB_LeftSidebar_MenuItem_UnterUnterkategorie_paddingLeftRight,
    setDB_LeftSidebar_MenuItem_UnterUnterkategorie_paddingLeftRight,
  ] = useState("5");

  const [
    DB_LeftSidebar_MenuItem_UnterUnterkategorie_paddingTopBottom,
    setDB_LeftSidebar_MenuItem_UnterUnterkategorie_paddingTopBottom,
  ] = useState("5");

  const [
    DB_LeftSidebar_MenuItem_UnterUnterkategorie_letterSpacing,
    setDB_LeftSidebar_MenuItem_UnterUnterkategorie_letterSpacing,
  ] = useState("0");

  const [
    DB_LeftSidebar_MenuItem_UnterUnterkategorie_marginTopBottom_Hover,
    setDB_LeftSidebar_MenuItem_UnterUnterkategorie_marginTopBottom_Hover,
  ] = useState("5");
  const [
    DB_LeftSidebar_MenuItem_UnterUnterkategorie_marginLeftRight_Hover,
    setDB_LeftSidebar_MenuItem_UnterUnterkategorie_marginLeftRight_Hover,
  ] = useState("5");

  const [
    DB_LeftSidebar_MenuItem_UnterUnterkategorie_paddingLeftRight_Hover,
    setDB_LeftSidebar_MenuItem_UnterUnterkategorie_paddingLeftRight_Hover,
  ] = useState("5");

  const [
    DB_LeftSidebar_MenuItem_UnterUnterkategorie_paddingTopBottom_Hover,
    setDB_LeftSidebar_MenuItem_UnterUnterkategorie_paddingTopBottom_Hover,
  ] = useState("5");

  const [
    DB_LeftSidebar_MenuItem_UnterUnterkategorie_letterSpacing_Hover,
    setDB_LeftSidebar_MenuItem_UnterUnterkategorie_letterSpacing_Hover,
  ] = useState("0");

  const [
    DB_LeftSidebar_MenuItem_UnterUnterkategorie_ShadowHorizontal,
    setDB_LeftSidebar_MenuItem_UnterUnterkategorie_ShadowHorizontal,
  ] = useState(1);
  const [
    DB_LeftSidebar_MenuItem_UnterUnterkategorie_ShadowVertical,
    setDB_LeftSidebar_MenuItem_UnterUnterkategorie_ShadowVertical,
  ] = useState(1);
  const [
    DB_LeftSidebar_MenuItem_UnterUnterkategorie_ShadowBlur,
    setDB_LeftSidebar_MenuItem_UnterUnterkategorie_ShadowBlur,
  ] = useState(5);
  const [
    DB_LeftSidebar_MenuItem_UnterUnterkategorie_ShadowColor,
    setDB_LeftSidebar_MenuItem_UnterUnterkategorie_ShadowColor,
  ] = useState("rgba(255,255,255,1)");

  const [
    DB_LeftSidebar_MenuItem_UnterUnterkategorie_ShadowColorNotEncoded,
    setDB_LeftSidebar_MenuItem_UnterUnterkategorie_ShadowColorNotEncoded,
  ] = useState("rgba(255,255,255,1)");

  const [
    DB_LeftSidebar_MenuItem_UnterUnterkategorie_TextDecoration,
    setDB_LeftSidebar_MenuItem_UnterUnterkategorie_TextDecoration,
  ] = useState("normal");

  const [
    DB_LeftSidebar_MenuItem_UnterUnterkategorie_TextDecorationStyle,
    setDB_LeftSidebar_MenuItem_UnterUnterkategorie_TextDecorationStyle,
  ] = useState("");

  const [
    DB_LeftSidebar_MenuItem_UnterUnterkategorie_ShadowHorizontal_Hover,
    setDB_LeftSidebar_MenuItem_UnterUnterkategorie_ShadowHorizontal_Hover,
  ] = useState(1);
  const [
    DB_LeftSidebar_MenuItem_UnterUnterkategorie_ShadowVertical_Hover,
    setDB_LeftSidebar_MenuItem_UnterUnterkategorie_ShadowVertical_Hover,
  ] = useState(1);
  const [
    DB_LeftSidebar_MenuItem_UnterUnterkategorie_ShadowBlur_Hover,
    setDB_LeftSidebar_MenuItem_UnterUnterkategorie_ShadowBlur_Hover,
  ] = useState(5);
  const [
    DB_LeftSidebar_MenuItem_UnterUnterkategorie_ShadowColor_Hover,
    setDB_LeftSidebar_MenuItem_UnterUnterkategorie_ShadowColor_Hover,
  ] = useState("rgba(255,255,255,1)");
  const [
    DB_LeftSidebar_MenuItem_UnterUnterkategorie_TextDecoration_Hover,
    setDB_LeftSidebar_MenuItem_UnterUnterkategorie_TextDecoration_Hover,
  ] = useState("none");
  const [
    DB_LeftSidebar_MenuItem_UnterUnterkategorie_TextDecorationStyle_Hover,
    setDB_LeftSidebar_MenuItem_UnterUnterkategorie_TextDecorationStyle_Hover,
  ] = useState("");

  const [
    DB_LeftSidebar_MenuItem_UnterUnterkategorie_ShadowColorNotEncoded_Hover,
    setDB_LeftSidebar_MenuItem_UnterUnterkategorie_ShadowColorNotEncoded_Hover,
  ] = useState("rgba(255,255,255,1)");

  // DB_LeftSidebar - UnterUnterkategorie Normal
  const [
    DB_LeftSidebar_MenuItem_UnterUnterkategorie_FontSize,
    setDB_LeftSidebar_MenuItem_UnterUnterkategorie_FontSize,
  ] = useState(14);
  const DB_LeftSidebar_MenuItem_UnterUnterkategorie_FontFace =
    DB_fontFace_FontFamilyName;
  const [
    DB_LeftSidebar_MenuItem_UnterUnterkategorie_FontWeight,
    setDB_LeftSidebar_MenuItem_UnterUnterkategorie_FontWeight,
  ] = useState(400);
  const [
    DB_LeftSidebar_MenuItem_UnterUnterkategorie_FontColor,
    setDB_LeftSidebar_MenuItem_UnterUnterkategorie_FontColor,
  ] = useState("rgba(255,255,255,1)");

  const [
    DB_LeftSidebar_MenuItem_UnterUnterkategorie_FontColorNotEncoded,
    setDB_LeftSidebar_MenuItem_UnterUnterkategorie_FontColorNotEncoded,
  ] = useState("rgba(0,0,0,0)");

  const [
    DB_LeftSidebar_MenuItem_UnterUnterkategorie_BackgroundColor,
    setDB_LeftSidebar_MenuItem_UnterUnterkategorie_BackgroundColor,
  ] = useState("rgba(0,0,0,0)");
  const [
    DB_LeftSidebar_MenuItem_UnterUnterkategorie_BackgroundColorNotEncoded,
    setDB_LeftSidebar_MenuItem_UnterUnterkategorie_BackgroundColorNotEncoded,
  ] = useState("rgba(0,0,0,0)");

  const [
    DB_LeftSidebar_MenuItem_UnterUnterkategorie_TextAlign,
    setDB_LeftSidebar_MenuItem_UnterUnterkategorie_TextAlign,
  ] = useState("center");

  /////////////////////////////////
  const [
    DB_LeftSidebar_MenuItem_UnterUnterkategorie_BorderTopRightRadius,
    setDB_LeftSidebar_MenuItem_UnterUnterkategorie_BorderTopRightRadius,
  ] = useState(0);
  const [
    DB_LeftSidebar_MenuItem_UnterUnterkategorie_BorderTopLeftRadius,
    setDB_LeftSidebar_MenuItem_UnterUnterkategorie_BorderTopLeftRadius,
  ] = useState(0);
  const [
    DB_LeftSidebar_MenuItem_UnterUnterkategorie_BorderBottomRightRadius,
    setDB_LeftSidebar_MenuItem_UnterUnterkategorie_BorderBottomRightRadius,
  ] = useState(0);
  const [
    DB_LeftSidebar_MenuItem_UnterUnterkategorie_BorderBottomLeftRadius,
    setDB_LeftSidebar_MenuItem_UnterUnterkategorie_BorderBottomLeftRadius,
  ] = useState(0);

  const [
    DB_LeftSidebar_MenuItem_UnterUnterkategorie_BorderColorTop,
    setDB_LeftSidebar_MenuItem_UnterUnterkategorie_BorderColorTop,
  ] = useState("rgba(255,255,255,1)");
  const [
    DB_LeftSidebar_MenuItem_UnterUnterkategorie_BorderColorBottom,
    setDB_LeftSidebar_MenuItem_UnterUnterkategorie_BorderColorBottom,
  ] = useState("rgba(255,255,255,1)");
  const [
    DB_LeftSidebar_MenuItem_UnterUnterkategorie_BorderColorLeft,
    setDB_LeftSidebar_MenuItem_UnterUnterkategorie_BorderColorLeft,
  ] = useState("rgba(255,255,255,1)");
  const [
    DB_LeftSidebar_MenuItem_UnterUnterkategorie_BorderColorRight,
    setDB_LeftSidebar_MenuItem_UnterUnterkategorie_BorderColorRight,
  ] = useState("rgba(255,255,255,1)");

  const [
    DB_LeftSidebar_MenuItem_UnterUnterkategorie_BorderColorTopNotEncoded,
    setDB_LeftSidebar_MenuItem_UnterUnterkategorie_BorderColorTopNotEncoded,
  ] = useState("rgba(255,255,255,1)");
  const [
    DB_LeftSidebar_MenuItem_UnterUnterkategorie_BorderColorBottomNotEncoded,
    setDB_LeftSidebar_MenuItem_UnterUnterkategorie_BorderColorBottomNotEncoded,
  ] = useState("rgba(255,255,255,1)");
  const [
    DB_LeftSidebar_MenuItem_UnterUnterkategorie_BorderColorLeftNotEncoded,
    setDB_LeftSidebar_MenuItem_UnterUnterkategorie_BorderColorLeftNotEncoded,
  ] = useState("rgba(255,255,255,1)");
  const [
    DB_LeftSidebar_MenuItem_UnterUnterkategorie_BorderColorRightNotEncoded,
    setDB_LeftSidebar_MenuItem_UnterUnterkategorie_BorderColorRightNotEncoded,
  ] = useState("rgba(255,255,255,1)");

  const [
    DB_LeftSidebar_MenuItem_UnterUnterkategorie_BorderTop,
    setDB_LeftSidebar_MenuItem_UnterUnterkategorie_BorderTop,
  ] = useState(0);
  const [
    DB_LeftSidebar_MenuItem_UnterUnterkategorie_BorderBottom,
    setDB_LeftSidebar_MenuItem_UnterUnterkategorie_BorderBottom,
  ] = useState(0);
  const [
    DB_LeftSidebar_MenuItem_UnterUnterkategorie_BorderLeft,
    setDB_LeftSidebar_MenuItem_UnterUnterkategorie_BorderLeft,
  ] = useState(0);
  const [
    DB_LeftSidebar_MenuItem_UnterUnterkategorie_BorderRight,
    setDB_LeftSidebar_MenuItem_UnterUnterkategorie_BorderRight,
  ] = useState(0);

  const [
    DB_LeftSidebar_MenuItem_UnterUnterkategorie_BorderStyleTop,
    setDB_LeftSidebar_MenuItem_UnterUnterkategorie_BorderStyleTop,
  ] = useState("solid");
  const [
    DB_LeftSidebar_MenuItem_UnterUnterkategorie_BorderStyleBottom,
    setDB_LeftSidebar_MenuItem_UnterUnterkategorie_BorderStyleBottom,
  ] = useState("solid");
  const [
    DB_LeftSidebar_MenuItem_UnterUnterkategorie_BorderStyleLeft,
    setDB_LeftSidebar_MenuItem_UnterUnterkategorie_BorderStyleLeft,
  ] = useState("solid");
  const [
    DB_LeftSidebar_MenuItem_UnterUnterkategorie_BorderStyleRight,
    setDB_LeftSidebar_MenuItem_UnterUnterkategorie_BorderStyleRight,
  ] = useState("solid");

  const [
    showConfig_LeftSidebar_MenuMainUnterUnterkategorie,
    setShowConfig_LeftSidebar_MenuMainUnterUnterkategorie,
  ] = useState(false);

  const [
    showConfig_LeftSidebar_MenuMainUnterUnterkategorie_BorderAndColor,
    setShowConfig_LeftSidebar_MenuMainUnterUnterkategorie_BorderAndColor,
  ] = useState(false);

  const [
    DB_fontFace_hauptkategorieTitel_FontFamilyName,
    setDB_fontFace_hauptkategorieTitel_FontFamilyName,
  ] = useState("Lato");
  const [
    DB_fontFace_hauptkategorieTitel_FontFamilySrc,
    setDB_fontFace_hauptkategorieTitel_FontFamilySrc,
  ] = useState("/assets/Lato/Lato-Regular.ttf");

  const [
    DB_fontFace_unterkategorieTitel_FontFamilyName,
    setDB_fontFace_unterkategorieTitel_FontFamilyName,
  ] = useState("Lato");
  const [
    DB_fontFace_unterkategorieTitel_FontFamilySrc,
    setDB_fontFace_unterkategorieTitel_FontFamilySrc,
  ] = useState("/assets/Lato/Lato-Regular.ttf");

  const [
    DB_fontFace_unterunterkategorieTitel_FontFamilyName,
    setDB_fontFace_unterunterkategorieTitel_FontFamilyName,
  ] = useState("Lato");
  const [
    DB_fontFace_unterunterkategorieTitel_FontFamilySrc,
    setDB_fontFace_unterunterkategorieTitel_FontFamilySrc,
  ] = useState("/assets/Lato/Lato-Regular.ttf");

  const [
    DB_fontFace_SocialTitelFontFamily_FontFamilyName,
    setDB_fontFace_SocialTitelFontFamily_FontFamilyName,
  ] = useState("Lato");
  const [
    DB_fontFace_SocialTitelFontFamily_FontFamilySrc,
    setDB_fontFace_SocialTitelFontFamily_FontFamilySrc,
  ] = useState("/assets/Lato/Lato-Regular.ttf");

  const [
    DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_FontSize,
    setDB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_FontSize,
  ] = useState(16);
  const DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_FontFace =
    DB_fontFace_FontFamilyName;
  const [
    DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_FontWeight,
    setDB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_FontWeight,
  ] = useState(400);
  const [
    DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_FontColor,
    setDB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_FontColor,
  ] = useState("rgba(255,255,255,1)");

  const [
    DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_FontColorNotEncoded,
    setDB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_FontColorNotEncoded,
  ] = useState("rgba(0,0,0,0.3)");

  const [
    DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BackgroundColor,
    setDB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BackgroundColor,
  ] = useState("rgba(0,0,0,0.3)");
  const [
    DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BackgroundColorNotEncoded,
    setDB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BackgroundColorNotEncoded,
  ] = useState("rgba(0,0,0,0.3)");

  const [
    DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_TextAlign,
    setDB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_TextAlign,
  ] = useState("center");

  const [
    DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_marginTopBottom,
    setDB_LeftSidebar_MenuItem_TitelSocialTitle_EE_marginTopBottom,
  ] = useState("5");
  const [
    DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_marginLeftRight,
    setDB_LeftSidebar_MenuItem_TitelSocialTitle_EE_marginLeftRight,
  ] = useState("5");

  const [
    DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_paddingLeftRight,
    setDB_LeftSidebar_MenuItem_TitelSocialTitle_EE_paddingLeftRight,
  ] = useState("5");

  const [
    DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_paddingTopBottom,
    setDB_LeftSidebar_MenuItem_TitelSocialTitle_EE_paddingTopBottom,
  ] = useState("5");

  const [
    DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_letterSpacing,
    setDB_LeftSidebar_MenuItem_TitelSocialTitle_EE_letterSpacing,
  ] = useState("0");

  const [
    DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_marginTopBottom_Hover,
    setDB_LeftSidebar_MenuItem_TitelSocialTitle_EE_marginTopBottom_Hover,
  ] = useState("5");
  const [
    DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_marginLeftRight_Hover,
    setDB_LeftSidebar_MenuItem_TitelSocialTitle_EE_marginLeftRight_Hover,
  ] = useState("5");

  const [
    DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_paddingLeftRight_Hover,
    setDB_LeftSidebar_MenuItem_TitelSocialTitle_EE_paddingLeftRight_Hover,
  ] = useState("5");

  const [
    DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_paddingTopBottom_Hover,
    setDB_LeftSidebar_MenuItem_TitelSocialTitle_EE_paddingTopBottom_Hover,
  ] = useState("5");

  const [
    DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_letterSpacing_Hover,
    setDB_LeftSidebar_MenuItem_TitelSocialTitle_EE_letterSpacing_Hover,
  ] = useState("0");

  const [
    DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowHorizontal,
    setDB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowHorizontal,
  ] = useState(1);
  const [
    DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowVertical,
    setDB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowVertical,
  ] = useState(1);
  const [
    DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowBlur,
    setDB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowBlur,
  ] = useState(5);
  const [
    DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowColor,
    setDB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowColor,
  ] = useState("rgba(255,255,255,1)");

  const [
    DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowColorNotEncoded,
    setDB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowColorNotEncoded,
  ] = useState("rgba(255,255,255,1)");

  const [
    DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_TextDecoration,
    setDB_LeftSidebar_MenuItem_TitelSocialTitle_EE_TextDecoration,
  ] = useState("normal");

  const [
    DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_TextDecorationStyle,
    setDB_LeftSidebar_MenuItem_TitelSocialTitle_EE_TextDecorationStyle,
  ] = useState("");

  const [
    DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowHorizontal_Hover,
    setDB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowHorizontal_Hover,
  ] = useState(1);
  const [
    DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowVertical_Hover,
    setDB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowVertical_Hover,
  ] = useState(1);
  const [
    DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowBlur_Hover,
    setDB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowBlur_Hover,
  ] = useState(5);
  const [
    DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowColor_Hover,
    setDB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowColor_Hover,
  ] = useState("rgba(255,255,255,1)");
  const [
    DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_TextDecoration_Hover,
    setDB_LeftSidebar_MenuItem_TitelSocialTitle_EE_TextDecoration_Hover,
  ] = useState("none");
  const [
    DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_TextDecorationStyle_Hover,
    setDB_LeftSidebar_MenuItem_TitelSocialTitle_EE_TextDecorationStyle_Hover,
  ] = useState("");

  const [
    DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowColorNotEncoded_Hover,
    setDB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowColorNotEncoded_Hover,
  ] = useState("rgba(255,255,255,1)");

  /////////////////////////////////
  const [
    DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderTopRightRadius,
    setDB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderTopRightRadius,
  ] = useState(5);
  const [
    DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderTopLeftRadius,
    setDB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderTopLeftRadius,
  ] = useState(5);
  const [
    DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderBottomRightRadius,
    setDB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderBottomRightRadius,
  ] = useState(5);
  const [
    DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderBottomLeftRadius,
    setDB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderBottomLeftRadius,
  ] = useState(5);

  const [
    DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderColorTop,
    setDB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderColorTop,
  ] = useState("rgba(0,0,0,0.1)");
  const [
    DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderColorBottom,
    setDB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderColorBottom,
  ] = useState("rgba(0,0,0,0.1)");
  const [
    DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderColorLeft,
    setDB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderColorLeft,
  ] = useState("rgba(0,0,0,0.1)");
  const [
    DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderColorRight,
    setDB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderColorRight,
  ] = useState("rgba(0,0,0,0.1)");

  const [
    DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderColorTopNotEncoded,
    setDB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderColorTopNotEncoded,
  ] = useState("rgba(0,0,0,0.1)");
  const [
    DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderColorBottomNotEncoded,
    setDB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderColorBottomNotEncoded,
  ] = useState("rgba(0,0,0,0.1)");
  const [
    DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderColorLeftNotEncoded,
    setDB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderColorLeftNotEncoded,
  ] = useState("rgba(0,0,0,0.1)");
  const [
    DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderColorRightNotEncoded,
    setDB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderColorRightNotEncoded,
  ] = useState("rgba(0,0,0,0.1)");

  const [
    DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderTop,
    setDB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderTop,
  ] = useState(1);
  const [
    DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderBottom,
    setDB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderBottom,
  ] = useState(1);
  const [
    DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderLeft,
    setDB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderLeft,
  ] = useState(1);
  const [
    DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderRight,
    setDB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderRight,
  ] = useState(1);

  const [
    DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderStyleTop,
    setDB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderStyleTop,
  ] = useState("solid");
  const [
    DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderStyleBottom,
    setDB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderStyleBottom,
  ] = useState("solid");
  const [
    DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderStyleLeft,
    setDB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderStyleLeft,
  ] = useState("solid");
  const [
    DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderStyleRight,
    setDB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderStyleRight,
  ] = useState("solid");

  // DB_LeftSidebar - TitelSocialTitle_EE Normal .-----------------.---------------------.----------------------
  const [
    DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_FontSize,
    setDB_LeftSidebar_MenuItem_TitelSocialTitle_EE_FontSize,
  ] = useState(16);
  const DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_FontFace =
    DB_fontFace_FontFamilyName;
  const [
    DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_FontWeight,
    setDB_LeftSidebar_MenuItem_TitelSocialTitle_EE_FontWeight,
  ] = useState(400);
  const [
    DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_FontColor,
    setDB_LeftSidebar_MenuItem_TitelSocialTitle_EE_FontColor,
  ] = useState("rgba(255,255,255,1)");

  const [
    DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_FontColorNotEncoded,
    setDB_LeftSidebar_MenuItem_TitelSocialTitle_EE_FontColorNotEncoded,
  ] = useState({ r: 0, g: 0, b: 0, a: 0.15 });

  const [
    DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BackgroundColor,
    setDB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BackgroundColor,
  ] = useState("rgba(0,0,0,0.2)");
  const [
    DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BackgroundColorNotEncoded,
    setDB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BackgroundColorNotEncoded,
  ] = useState({ r: 0, g: 0, b: 0, a: 0.15 });

  const [
    DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_TextAlign,
    setDB_LeftSidebar_MenuItem_TitelSocialTitle_EE_TextAlign,
  ] = useState("center");

  /////////////////////////////////
  const [
    DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderTopRightRadius,
    setDB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderTopRightRadius,
  ] = useState(5);
  const [
    DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderTopLeftRadius,
    setDB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderTopLeftRadius,
  ] = useState(5);
  const [
    DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderBottomRightRadius,
    setDB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderBottomRightRadius,
  ] = useState(5);
  const [
    DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderBottomLeftRadius,
    setDB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderBottomLeftRadius,
  ] = useState(5);

  const [
    DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderColorTop,
    setDB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderColorTop,
  ] = useState("rgba(0,0,0,0.3)");
  const [
    DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderColorBottom,
    setDB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderColorBottom,
  ] = useState("rgba(0,0,0,0.3)");
  const [
    DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderColorLeft,
    setDB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderColorLeft,
  ] = useState("rgba(0,0,0,0.3)");
  const [
    DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderColorRight,
    setDB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderColorRight,
  ] = useState("rgba(0,0,0,0.3)");
  const [
    DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderColorTopNotEncoded,
    setDB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderColorTopNotEncoded,
  ] = useState("rgba(0,0,0,0.3)");
  const [
    DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderColorBottomNotEncoded,
    setDB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderColorBottomNotEncoded,
  ] = useState("rgba(0,0,0,0.3)");
  const [
    DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderColorLeftNotEncoded,
    setDB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderColorLeftNotEncoded,
  ] = useState("rgba(0,0,0,0.3)");
  const [
    DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderColorRightNotEncoded,
    setDB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderColorRightNotEncoded,
  ] = useState("rgba(0,0,0,0.3)");

  const [
    DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderTop,
    setDB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderTop,
  ] = useState(1);
  const [
    DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderBottom,
    setDB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderBottom,
  ] = useState(1);
  const [
    DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderLeft,
    setDB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderLeft,
  ] = useState(1);
  const [
    DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderRight,
    setDB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderRight,
  ] = useState(1);

  const [
    DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderStyleTop,
    setDB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderStyleTop,
  ] = useState("solid");
  const [
    DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderStyleBottom,
    setDB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderStyleBottom,
  ] = useState("solid");
  const [
    DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderStyleLeft,
    setDB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderStyleLeft,
  ] = useState("solid");
  const [
    DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderStyleRight,
    setDB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderStyleRight,
  ] = useState("solid");

  const [
    DB_LeftSidebar_MenuItem_SocialOne_DD__marginTopBottom,
    setDB_LeftSidebar_MenuItem_SocialOne_DD__marginTopBottom,
  ] = useState("5");
  const [
    DB_LeftSidebar_MenuItem_SocialOne_DD__marginLeftRight,
    setDB_LeftSidebar_MenuItem_SocialOne_DD__marginLeftRight,
  ] = useState("30");

  const [
    DB_LeftSidebar_MenuItem_SocialOne_DD__paddingLeftRight,
    setDB_LeftSidebar_MenuItem_SocialOne_DD__paddingLeftRight,
  ] = useState("0");

  const [
    DB_LeftSidebar_MenuItem_SocialOne_DD__paddingTopBottom,
    setDB_LeftSidebar_MenuItem_SocialOne_DD__paddingTopBottom,
  ] = useState("0");

  const [
    DB_LeftSidebar_MenuItem_SocialOne_DD__letterSpacing,
    setDB_LeftSidebar_MenuItem_SocialOne_DD__letterSpacing,
  ] = useState("0");

  const [
    DB_LeftSidebar_MenuItem_SocialOne_DD__marginTopBottom_Hover,
    setDB_LeftSidebar_MenuItem_SocialOne_DD__marginTopBottom_Hover,
  ] = useState("5");
  const [
    DB_LeftSidebar_MenuItem_SocialOne_DD__marginLeftRight_Hover,
    setDB_LeftSidebar_MenuItem_SocialOne_DD__marginLeftRight_Hover,
  ] = useState("30");

  const [
    DB_LeftSidebar_MenuItem_SocialOne_DD__paddingLeftRight_Hover,
    setDB_LeftSidebar_MenuItem_SocialOne_DD__paddingLeftRight_Hover,
  ] = useState("0");

  const [
    DB_LeftSidebar_MenuItem_SocialOne_DD__paddingTopBottom_Hover,
    setDB_LeftSidebar_MenuItem_SocialOne_DD__paddingTopBottom_Hover,
  ] = useState("0");

  const [
    DB_LeftSidebar_MenuItem_SocialOne_DD__letterSpacing_Hover,
    setDB_LeftSidebar_MenuItem_SocialOne_DD__letterSpacing_Hover,
  ] = useState("0");

  const [
    DB_LeftSidebar_MenuItem_SocialOne_DD__ShadowHorizontal,
    setDB_LeftSidebar_MenuItem_SocialOne_DD__ShadowHorizontal,
  ] = useState(1);
  const [
    DB_LeftSidebar_MenuItem_SocialOne_DD__ShadowVertical,
    setDB_LeftSidebar_MenuItem_SocialOne_DD__ShadowVertical,
  ] = useState(1);
  const [
    DB_LeftSidebar_MenuItem_SocialOne_DD__ShadowBlur,
    setDB_LeftSidebar_MenuItem_SocialOne_DD__ShadowBlur,
  ] = useState(5);
  const [
    DB_LeftSidebar_MenuItem_SocialOne_DD__ShadowColor,
    setDB_LeftSidebar_MenuItem_SocialOne_DD__ShadowColor,
  ] = useState("rgba(255,255,255,1)");

  const [
    DB_LeftSidebar_MenuItem_SocialOne_DD__ShadowColorNotEncoded,
    setDB_LeftSidebar_MenuItem_SocialOne_DD__ShadowColorNotEncoded,
  ] = useState("rgba(255,255,255,1)");

  const [
    DB_LeftSidebar_MenuItem_SocialOne_DD__TextDecoration,
    setDB_LeftSidebar_MenuItem_SocialOne_DD__TextDecoration,
  ] = useState("normal");

  const [
    DB_LeftSidebar_MenuItem_SocialOne_DD__TextDecorationStyle,
    setDB_LeftSidebar_MenuItem_SocialOne_DD__TextDecorationStyle,
  ] = useState("");

  const [
    DB_LeftSidebar_MenuItem_SocialOne_DD__ShadowHorizontal_Hover,
    setDB_LeftSidebar_MenuItem_SocialOne_DD__ShadowHorizontal_Hover,
  ] = useState(1);
  const [
    DB_LeftSidebar_MenuItem_SocialOne_DD__ShadowVertical_Hover,
    setDB_LeftSidebar_MenuItem_SocialOne_DD__ShadowVertical_Hover,
  ] = useState(1);
  const [
    DB_LeftSidebar_MenuItem_SocialOne_DD__ShadowBlur_Hover,
    setDB_LeftSidebar_MenuItem_SocialOne_DD__ShadowBlur_Hover,
  ] = useState(5);
  const [
    DB_LeftSidebar_MenuItem_SocialOne_DD__ShadowColor_Hover,
    setDB_LeftSidebar_MenuItem_SocialOne_DD__ShadowColor_Hover,
  ] = useState("rgba(255,255,255,1)");
  const [
    DB_LeftSidebar_MenuItem_SocialOne_DD__TextDecoration_Hover,
    setDB_LeftSidebar_MenuItem_SocialOne_DD__TextDecoration_Hover,
  ] = useState("none");
  const [
    DB_LeftSidebar_MenuItem_SocialOne_DD__TextDecorationStyle_Hover,
    setDB_LeftSidebar_MenuItem_SocialOne_DD__TextDecorationStyle_Hover,
  ] = useState("");

  const [
    DB_LeftSidebar_MenuItem_SocialOne_DD__ShadowColorNotEncoded_Hover,
    setDB_LeftSidebar_MenuItem_SocialOne_DD__ShadowColorNotEncoded_Hover,
  ] = useState("rgba(255,255,255,1)");

  const [
    DB_LeftSidebar_MenuItem_SocialOne_DD__Hover_FontSize,
    setDB_LeftSidebar_MenuItem_SocialOne_DD__Hover_FontSize,
  ] = useState(15);
  const DB_LeftSidebar_MenuItem_SocialOne_DD__Hover_FontFace =
    DB_fontFace_FontFamilyName;
  const [
    DB_LeftSidebar_MenuItem_SocialOne_DD__Hover_FontWeight,
    setDB_LeftSidebar_MenuItem_SocialOne_DD__Hover_FontWeight,
  ] = useState(400);
  const [
    DB_LeftSidebar_MenuItem_SocialOne_DD__Hover_FontColor,
    setDB_LeftSidebar_MenuItem_SocialOne_DD__Hover_FontColor,
  ] = useState("rgba(255,255,255,1)");

  const [
    DB_LeftSidebar_MenuItem_SocialOne_DD__Hover_FontColorNotEncoded,
    setDB_LeftSidebar_MenuItem_SocialOne_DD__Hover_FontColorNotEncoded,
  ] = useState("rgba(0,0,0,0.3)");

  const [
    DB_LeftSidebar_MenuItem_SocialOne_DD__Hover_BackgroundColor,
    setDB_LeftSidebar_MenuItem_SocialOne_DD__Hover_BackgroundColor,
  ] = useState("rgba(0,0,0,0.3)");
  const [
    DB_LeftSidebar_MenuItem_SocialOne_DD__Hover_BackgroundColorNotEncoded,
    setDB_LeftSidebar_MenuItem_SocialOne_DD__Hover_BackgroundColorNotEncoded,
  ] = useState("rgba(0,0,0,0.3)");

  const [
    DB_LeftSidebar_MenuItem_SocialOne_DD__Hover_TextAlign,
    setDB_LeftSidebar_MenuItem_SocialOne_DD__Hover_TextAlign,
  ] = useState("center");

  /////////////////////////////////
  const [
    DB_LeftSidebar_MenuItem_SocialOne_DD__Hover_BorderTopRightRadius,
    setDB_LeftSidebar_MenuItem_SocialOne_DD__Hover_BorderTopRightRadius,
  ] = useState(15);
  const [
    DB_LeftSidebar_MenuItem_SocialOne_DD__Hover_BorderTopLeftRadius,
    setDB_LeftSidebar_MenuItem_SocialOne_DD__Hover_BorderTopLeftRadius,
  ] = useState(15);
  const [
    DB_LeftSidebar_MenuItem_SocialOne_DD__Hover_BorderBottomRightRadius,
    setDB_LeftSidebar_MenuItem_SocialOne_DD__Hover_BorderBottomRightRadius,
  ] = useState(15);
  const [
    DB_LeftSidebar_MenuItem_SocialOne_DD__Hover_BorderBottomLeftRadius,
    setDB_LeftSidebar_MenuItem_SocialOne_DD__Hover_BorderBottomLeftRadius,
  ] = useState(15);

  const [
    DB_LeftSidebar_MenuItem_SocialOne_DD__Hover_BorderColorTop,
    setDB_LeftSidebar_MenuItem_SocialOne_DD__Hover_BorderColorTop,
  ] = useState("rgba(255,255,255,1)");
  const [
    DB_LeftSidebar_MenuItem_SocialOne_DD__Hover_BorderColorBottom,
    setDB_LeftSidebar_MenuItem_SocialOne_DD__Hover_BorderColorBottom,
  ] = useState("rgba(255,255,255,1)");
  const [
    DB_LeftSidebar_MenuItem_SocialOne_DD__Hover_BorderColorLeft,
    setDB_LeftSidebar_MenuItem_SocialOne_DD__Hover_BorderColorLeft,
  ] = useState("rgba(255,255,255,1)");
  const [
    DB_LeftSidebar_MenuItem_SocialOne_DD__Hover_BorderColorRight,
    setDB_LeftSidebar_MenuItem_SocialOne_DD__Hover_BorderColorRight,
  ] = useState("rgba(255,255,255,1)");

  const [
    DB_LeftSidebar_MenuItem_SocialOne_DD__Hover_BorderColorTopNotEncoded,
    setDB_LeftSidebar_MenuItem_SocialOne_DD__Hover_BorderColorTopNotEncoded,
  ] = useState("rgba(255,255,255,1)");
  const [
    DB_LeftSidebar_MenuItem_SocialOne_DD__Hover_BorderColorBottomNotEncoded,
    setDB_LeftSidebar_MenuItem_SocialOne_DD__Hover_BorderColorBottomNotEncoded,
  ] = useState("rgba(255,255,255,1)");
  const [
    DB_LeftSidebar_MenuItem_SocialOne_DD__Hover_BorderColorLeftNotEncoded,
    setDB_LeftSidebar_MenuItem_SocialOne_DD__Hover_BorderColorLeftNotEncoded,
  ] = useState("rgba(255,255,255,1)");
  const [
    DB_LeftSidebar_MenuItem_SocialOne_DD__Hover_BorderColorRightNotEncoded,
    setDB_LeftSidebar_MenuItem_SocialOne_DD__Hover_BorderColorRightNotEncoded,
  ] = useState("rgba(255,255,255,1)");

  const [
    DB_LeftSidebar_MenuItem_SocialOne_DD__Hover_BorderTop,
    setDB_LeftSidebar_MenuItem_SocialOne_DD__Hover_BorderTop,
  ] = useState(0);
  const [
    DB_LeftSidebar_MenuItem_SocialOne_DD__Hover_BorderBottom,
    setDB_LeftSidebar_MenuItem_SocialOne_DD__Hover_BorderBottom,
  ] = useState(0);
  const [
    DB_LeftSidebar_MenuItem_SocialOne_DD__Hover_BorderLeft,
    setDB_LeftSidebar_MenuItem_SocialOne_DD__Hover_BorderLeft,
  ] = useState(0);
  const [
    DB_LeftSidebar_MenuItem_SocialOne_DD__Hover_BorderRight,
    setDB_LeftSidebar_MenuItem_SocialOne_DD__Hover_BorderRight,
  ] = useState(0);

  const [
    DB_LeftSidebar_MenuItem_SocialOne_DD__Hover_BorderStyleTop,
    setDB_LeftSidebar_MenuItem_SocialOne_DD__Hover_BorderStyleTop,
  ] = useState("solid");
  const [
    DB_LeftSidebar_MenuItem_SocialOne_DD__Hover_BorderStyleBottom,
    setDB_LeftSidebar_MenuItem_SocialOne_DD__Hover_BorderStyleBottom,
  ] = useState("solid");
  const [
    DB_LeftSidebar_MenuItem_SocialOne_DD__Hover_BorderStyleLeft,
    setDB_LeftSidebar_MenuItem_SocialOne_DD__Hover_BorderStyleLeft,
  ] = useState("solid");
  const [
    DB_LeftSidebar_MenuItem_SocialOne_DD__Hover_BorderStyleRight,
    setDB_LeftSidebar_MenuItem_SocialOne_DD__Hover_BorderStyleRight,
  ] = useState("solid");

  const DB_LeftSidebar_MenuItem_SocialOne_DD__Hover_HoverBackgroundColor =
    "rgba(255,255,255,0.3)";
  const DB_LeftSidebar_MenuItem_SocialOne_DD__Hover_HoverFontColor =
    "rgba(255,255,255,1)";

  // DB_LeftSidebar - SocialOne_DD_ Normal .-----------------.---------------------.----------------------
  const [
    DB_LeftSidebar_MenuItem_SocialOne_DD__FontSize,
    setDB_LeftSidebar_MenuItem_SocialOne_DD__FontSize,
  ] = useState(15);
  const DB_LeftSidebar_MenuItem_SocialOne_DD__FontFace =
    DB_fontFace_FontFamilyName;
  const [
    DB_LeftSidebar_MenuItem_SocialOne_DD__FontWeight,
    setDB_LeftSidebar_MenuItem_SocialOne_DD__FontWeight,
  ] = useState(400);
  const [
    DB_LeftSidebar_MenuItem_SocialOne_DD__FontColor,
    setDB_LeftSidebar_MenuItem_SocialOne_DD__FontColor,
  ] = useState("rgba(255,255,255,1)");

  const [
    DB_LeftSidebar_MenuItem_SocialOne_DD__FontColorNotEncoded,
    setDB_LeftSidebar_MenuItem_SocialOne_DD__FontColorNotEncoded,
  ] = useState("rgba(0,0,0,0.3)");

  const [
    DB_LeftSidebar_MenuItem_SocialOne_DD__BackgroundColor,
    setDB_LeftSidebar_MenuItem_SocialOne_DD__BackgroundColor,
  ] = useState("rgba(0,0,0,0.3)");
  const [
    DB_LeftSidebar_MenuItem_SocialOne_DD__BackgroundColorNotEncoded,
    setDB_LeftSidebar_MenuItem_SocialOne_DD__BackgroundColorNotEncoded,
  ] = useState("rgba(0,0,0,0.3)");

  const [
    DB_LeftSidebar_MenuItem_SocialOne_DD__TextAlign,
    setDB_LeftSidebar_MenuItem_SocialOne_DD__TextAlign,
  ] = useState("center");

  /////////////////////////////////
  const [
    DB_LeftSidebar_MenuItem_SocialOne_DD__BorderTopRightRadius,
    setDB_LeftSidebar_MenuItem_SocialOne_DD__BorderTopRightRadius,
  ] = useState(15);
  const [
    DB_LeftSidebar_MenuItem_SocialOne_DD__BorderTopLeftRadius,
    setDB_LeftSidebar_MenuItem_SocialOne_DD__BorderTopLeftRadius,
  ] = useState(15);
  const [
    DB_LeftSidebar_MenuItem_SocialOne_DD__BorderBottomRightRadius,
    setDB_LeftSidebar_MenuItem_SocialOne_DD__BorderBottomRightRadius,
  ] = useState(15);
  const [
    DB_LeftSidebar_MenuItem_SocialOne_DD__BorderBottomLeftRadius,
    setDB_LeftSidebar_MenuItem_SocialOne_DD__BorderBottomLeftRadius,
  ] = useState(15);

  const [
    DB_LeftSidebar_MenuItem_SocialOne_DD__BorderColorTop,
    setDB_LeftSidebar_MenuItem_SocialOne_DD__BorderColorTop,
  ] = useState("rgba(255,255,255,1)");
  const [
    DB_LeftSidebar_MenuItem_SocialOne_DD__BorderColorBottom,
    setDB_LeftSidebar_MenuItem_SocialOne_DD__BorderColorBottom,
  ] = useState("rgba(255,255,255,1)");
  const [
    DB_LeftSidebar_MenuItem_SocialOne_DD__BorderColorLeft,
    setDB_LeftSidebar_MenuItem_SocialOne_DD__BorderColorLeft,
  ] = useState("rgba(255,255,255,1)");
  const [
    DB_LeftSidebar_MenuItem_SocialOne_DD__BorderColorRight,
    setDB_LeftSidebar_MenuItem_SocialOne_DD__BorderColorRight,
  ] = useState("rgba(255,255,255,1)");

  const [
    DB_LeftSidebar_MenuItem_SocialOne_DD__BorderColorTopNotEncoded,
    setDB_LeftSidebar_MenuItem_SocialOne_DD__BorderColorTopNotEncoded,
  ] = useState("rgba(255,255,255,1)");
  const [
    DB_LeftSidebar_MenuItem_SocialOne_DD__BorderColorBottomNotEncoded,
    setDB_LeftSidebar_MenuItem_SocialOne_DD__BorderColorBottomNotEncoded,
  ] = useState("rgba(255,255,255,1)");
  const [
    DB_LeftSidebar_MenuItem_SocialOne_DD__BorderColorLeftNotEncoded,
    setDB_LeftSidebar_MenuItem_SocialOne_DD__BorderColorLeftNotEncoded,
  ] = useState("rgba(255,255,255,1)");
  const [
    DB_LeftSidebar_MenuItem_SocialOne_DD__BorderColorRightNotEncoded,
    setDB_LeftSidebar_MenuItem_SocialOne_DD__BorderColorRightNotEncoded,
  ] = useState("rgba(255,255,255,1)");

  const [
    DB_LeftSidebar_MenuItem_SocialOne_DD__BorderTop,
    setDB_LeftSidebar_MenuItem_SocialOne_DD__BorderTop,
  ] = useState(0);
  const [
    DB_LeftSidebar_MenuItem_SocialOne_DD__BorderBottom,
    setDB_LeftSidebar_MenuItem_SocialOne_DD__BorderBottom,
  ] = useState(0);
  const [
    DB_LeftSidebar_MenuItem_SocialOne_DD__BorderLeft,
    setDB_LeftSidebar_MenuItem_SocialOne_DD__BorderLeft,
  ] = useState(0);
  const [
    DB_LeftSidebar_MenuItem_SocialOne_DD__BorderRight,
    setDB_LeftSidebar_MenuItem_SocialOne_DD__BorderRight,
  ] = useState(0);

  const [
    DB_LeftSidebar_MenuItem_SocialOne_DD__BorderStyleTop,
    setDB_LeftSidebar_MenuItem_SocialOne_DD__BorderStyleTop,
  ] = useState("solid");
  const [
    DB_LeftSidebar_MenuItem_SocialOne_DD__BorderStyleBottom,
    setDB_LeftSidebar_MenuItem_SocialOne_DD__BorderStyleBottom,
  ] = useState("solid");
  const [
    DB_LeftSidebar_MenuItem_SocialOne_DD__BorderStyleLeft,
    setDB_LeftSidebar_MenuItem_SocialOne_DD__BorderStyleLeft,
  ] = useState("solid");
  const [
    DB_LeftSidebar_MenuItem_SocialOne_DD__BorderStyleRight,
    setDB_LeftSidebar_MenuItem_SocialOne_DD__BorderStyleRight,
  ] = useState("solid");

  const DB_LeftSidebar_MenuItem_SocialOne_DD__HoverBackgroundColor =
    "rgba(255,255,255,0.3)";
  const DB_LeftSidebar_MenuItem_SocialOne_DD__HoverFontColor =
    "rgba(255,255,255,1)";

  const [
    DB_LeftSidebar_MenuContainer_Social_DDHover_PaddingTopBottom,
    setDB_LeftSidebar_MenuContainer_Social_DDHover_PaddingTopBottom,
  ] = useState(10);

  const [
    DB_LeftSidebar_MenuContainer_Social_DDHover_PaddingLeftRight,
    setDB_LeftSidebar_MenuContainer_Social_DDHover_PaddingLeftRight,
  ] = useState(10);

  const [
    DB_LeftSidebar_MenuContainer_Social_DDHover_BackgroundColor,
    setDB_LeftSidebar_MenuContainer_Social_DDHover_BackgroundColor,
  ] = useState("rgba(0,0,0,0.3)");

  const [
    DB_LeftSidebar_MenuContainer_Social_DDHover_BackgroundColorNotEncoded,
    setDB_LeftSidebar_MenuContainer_Social_DDHover_BackgroundColorNotEncoded,
  ] = useState("rgba(0,0,0,0.3)");

  const [
    DB_LeftSidebar_MenuItem_MenuContainer_Social_DDShadowHorizontal,
    setDB_LeftSidebar_MenuItem_MenuContainer_Social_DDShadowHorizontal,
  ] = useState(0);
  const [
    DB_LeftSidebar_MenuItem_MenuContainer_Social_DDShadowVertical,
    setDB_LeftSidebar_MenuItem_MenuContainer_Social_DDShadowVertical,
  ] = useState(0);
  const [
    DB_LeftSidebar_MenuItem_MenuContainer_Social_DDShadowBlur,
    setDB_LeftSidebar_MenuItem_MenuContainer_Social_DDShadowBlur,
  ] = useState(5);
  const [
    DB_LeftSidebar_MenuItem_MenuContainer_Social_DDShadowColor,
    setDB_LeftSidebar_MenuItem_MenuContainer_Social_DDShadowColor,
  ] = useState("rgba(255,255,255,1)");

  const [
    DB_LeftSidebar_MenuItem_MenuContainer_Social_DDShadowColorNotEncoded,
    setDB_LeftSidebar_MenuItem_MenuContainer_Social_DDShadowColorNotEncoded,
  ] = useState("rgba(255,255,255,1)");

  const [
    DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_ShadowHorizontal,
    setDB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_ShadowHorizontal,
  ] = useState(0);
  const [
    DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_ShadowVertical,
    setDB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_ShadowVertical,
  ] = useState(0);
  const [
    DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_ShadowBlur,
    setDB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_ShadowBlur,
  ] = useState(5);
  const [
    DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_ShadowColor,
    setDB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_ShadowColor,
  ] = useState("rgba(255,255,255,1)");

  const [
    DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_ShadowColorNotEncoded,
    setDB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_ShadowColorNotEncoded,
  ] = useState("rgba(255,255,255,1)");
  const [
    DB_LeftSidebar_MenuItem_MenuContainer_Social_DDpaddingLeft,
    setDB_LeftSidebar_MenuItem_MenuContainer_Social_DDpaddingLeft,
  ] = useState("0");

  const [
    DB_LeftSidebar_MenuItem_MenuContainer_Social_DDpaddingTop,
    setDB_LeftSidebar_MenuItem_MenuContainer_Social_DDpaddingTop,
  ] = useState("0");

  const [
    DB_LeftSidebar_MenuItem_MenuContainer_Social_DDpaddingRight,
    setDB_LeftSidebar_MenuItem_MenuContainer_Social_DDpaddingRight,
  ] = useState("0");

  const [
    DB_LeftSidebar_MenuItem_MenuContainer_Social_DDpaddingBottom,
    setDB_LeftSidebar_MenuItem_MenuContainer_Social_DDpaddingBottom,
  ] = useState("0");

  const [
    DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_paddingLeft,
    setDB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_paddingLeft,
  ] = useState("0");

  const [
    DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_paddingTop,
    setDB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_paddingTop,
  ] = useState("0");

  const [
    DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_paddingRight,
    setDB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_paddingRight,
  ] = useState("0");

  const [
    DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_paddingBottom,
    setDB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_paddingBottom,
  ] = useState("0");

  const [
    DB_LeftSidebar_MenuContainer_Social_DDBackgroundColor,
    setDB_LeftSidebar_MenuContainer_Social_DDBackgroundColor,
  ] = useState("rgba(0,0,0,0.3)");

  const [
    DB_LeftSidebar_MenuContainer_Social_DDBackgroundColorNotEncoded,
    setDB_LeftSidebar_MenuContainer_Social_DDBackgroundColorNotEncoded,
  ] = useState("rgba(0,0,0,0.3)");

  const [
    DB_LeftSidebar_MenuContainer_Social_DDPaddingTopBottom,
    setDB_LeftSidebar_MenuContainer_Social_DDPaddingTopBottom,
  ] = useState(10);

  const [
    DB_LeftSidebar_MenuContainer_Social_DDPaddingLeftRight,
    setDB_LeftSidebar_MenuContainer_Social_DDPaddingLeftRight,
  ] = useState(10);

  const [
    DB_LeftSidebar_MenuItem_MenuContainer_Social_DDBorderTopRightRadius,
    setDB_LeftSidebar_MenuItem_MenuContainer_Social_DDBorderTopRightRadius,
  ] = useState(20);
  const [
    DB_LeftSidebar_MenuItem_MenuContainer_Social_DDBorderTopLeftRadius,
    setDB_LeftSidebar_MenuItem_MenuContainer_Social_DDBorderTopLeftRadius,
  ] = useState(20);
  const [
    DB_LeftSidebar_MenuItem_MenuContainer_Social_DDBorderBottomRightRadius,
    setDB_LeftSidebar_MenuItem_MenuContainer_Social_DDBorderBottomRightRadius,
  ] = useState(20);
  const [
    DB_LeftSidebar_MenuItem_MenuContainer_Social_DDBorderBottomLeftRadius,
    setDB_LeftSidebar_MenuItem_MenuContainer_Social_DDBorderBottomLeftRadius,
  ] = useState(20);

  const [
    DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_BorderTopRightRadius,
    setDB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_BorderTopRightRadius,
  ] = useState(20);
  const [
    DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_BorderTopLeftRadius,
    setDB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_BorderTopLeftRadius,
  ] = useState(20);
  const [
    DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_BorderBottomRightRadius,
    setDB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_BorderBottomRightRadius,
  ] = useState(20);
  const [
    DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_BorderBottomLeftRadius,
    setDB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_BorderBottomLeftRadius,
  ] = useState(20);

  const [
    DB_LeftSidebar_MenuItem_MenuContainer_Social_DDBorderColorTop,
    setDB_LeftSidebar_MenuItem_MenuContainer_Social_DDBorderColorTop,
  ] = useState("rgba(255,255,255,1)");
  const [
    DB_LeftSidebar_MenuItem_MenuContainer_Social_DDBorderColorBottom,
    setDB_LeftSidebar_MenuItem_MenuContainer_Social_DDBorderColorBottom,
  ] = useState("rgba(255,255,255,1)");
  const [
    DB_LeftSidebar_MenuItem_MenuContainer_Social_DDBorderColorLeft,
    setDB_LeftSidebar_MenuItem_MenuContainer_Social_DDBorderColorLeft,
  ] = useState("rgba(255,255,255,1)");
  const [
    DB_LeftSidebar_MenuItem_MenuContainer_Social_DDBorderColorRight,
    setDB_LeftSidebar_MenuItem_MenuContainer_Social_DDBorderColorRight,
  ] = useState("rgba(255,255,255,1)");

  const [
    DB_LeftSidebar_MenuItem_MenuContainer_Social_DDBorderColorTopNotEncoded,
    setDB_LeftSidebar_MenuItem_MenuContainer_Social_DDBorderColorTopNotEncoded,
  ] = useState("rgba(255,255,255,1)");
  const [
    DB_LeftSidebar_MenuItem_MenuContainer_Social_DDBorderColorBottomNotEncoded,
    setDB_LeftSidebar_MenuItem_MenuContainer_Social_DDBorderColorBottomNotEncoded,
  ] = useState("rgba(255,255,255,1)");
  const [
    DB_LeftSidebar_MenuItem_MenuContainer_Social_DDBorderColorLeftNotEncoded,
    setDB_LeftSidebar_MenuItem_MenuContainer_Social_DDBorderColorLeftNotEncoded,
  ] = useState("rgba(255,255,255,1)");
  const [
    DB_LeftSidebar_MenuItem_MenuContainer_Social_DDBorderColorRightNotEncoded,
    setDB_LeftSidebar_MenuItem_MenuContainer_Social_DDBorderColorRightNotEncoded,
  ] = useState("rgba(255,255,255,1)");

  const [
    DB_LeftSidebar_MenuContainer_Social_DDHover_BorderColorTop,
    setDB_LeftSidebar_MenuContainer_Social_DDHover_BorderColorTop,
  ] = useState("rgba(0,0,0,0.3)");
  const [
    DB_LeftSidebar_MenuContainer_Social_DDHover_BorderColorBottom,
    setDB_LeftSidebar_MenuContainer_Social_DDHover_BorderColorBottom,
  ] = useState("rgba(0,0,0,0.3)");
  const [
    DB_LeftSidebar_MenuContainer_Social_DDHover_BorderColorLeft,
    setDB_LeftSidebar_MenuContainer_Social_DDHover_BorderColorLeft,
  ] = useState("rgba(0,0,0,0.3)");
  const [
    DB_LeftSidebar_MenuContainer_Social_DDHover_BorderColorRight,
    setDB_LeftSidebar_MenuContainer_Social_DDHover_BorderColorRight,
  ] = useState("rgba(0,0,0,0.3)");

  const [
    DB_LeftSidebar_MenuContainer_Social_DDHover_BorderColorTopNotEncoded,
    setDB_LeftSidebar_MenuContainer_Social_DDHover_BorderColorTopNotEncoded,
  ] = useState("rgba(0,0,0,0.3)");
  const [
    DB_LeftSidebar_MenuContainer_Social_DDHover_BorderColorBottomNotEncoded,
    setDB_LeftSidebar_MenuContainer_Social_DDHover_BorderColorBottomNotEncoded,
  ] = useState("rgba(0,0,0,0.3)");
  const [
    DB_LeftSidebar_MenuContainer_Social_DDHover_BorderColorLeftNotEncoded,
    setDB_LeftSidebar_MenuContainer_Social_DDHover_BorderColorLeftNotEncoded,
  ] = useState("rgba(0,0,0,0.3)");
  const [
    DB_LeftSidebar_MenuContainer_Social_DDHover_BorderColorRightNotEncoded,
    setDB_LeftSidebar_MenuContainer_Social_DDHover_BorderColorRightNotEncoded,
  ] = useState("rgba(0,0,0,0.3)");

  const [
    DB_LeftSidebar_MenuContainer_Social_DDHover_BorderTop,
    setDB_LeftSidebar_MenuContainer_Social_DDHover_BorderTop,
  ] = useState(1);
  const [
    DB_LeftSidebar_MenuContainer_Social_DDHover_BorderBottom,
    setDB_LeftSidebar_MenuContainer_Social_DDHover_BorderBottom,
  ] = useState(1);
  const [
    DB_LeftSidebar_MenuContainer_Social_DDHover_BorderLeft,
    setDB_LeftSidebar_MenuContainer_Social_DDHover_BorderLeft,
  ] = useState(1);
  const [
    DB_LeftSidebar_MenuContainer_Social_DDHover_BorderRight,
    setDB_LeftSidebar_MenuContainer_Social_DDHover_BorderRight,
  ] = useState(1);

  const [
    DB_LeftSidebar_MenuContainer_Social_DDHover_BorderStyleTop,
    setDB_LeftSidebar_MenuContainer_Social_DDHover_BorderStyleTop,
  ] = useState("solid");
  const [
    DB_LeftSidebar_MenuContainer_Social_DDHover_BorderStyleBottom,
    setDB_LeftSidebar_MenuContainer_Social_DDHover_BorderStyleBottom,
  ] = useState("solid");
  const [
    DB_LeftSidebar_MenuContainer_Social_DDHover_BorderStyleLeft,
    setDB_LeftSidebar_MenuContainer_Social_DDHover_BorderStyleLeft,
  ] = useState("solid");
  const [
    DB_LeftSidebar_MenuContainer_Social_DDHover_BorderStyleRight,
    setDB_LeftSidebar_MenuContainer_Social_DDHover_BorderStyleRight,
  ] = useState("solid");

  const [
    DB_LeftSidebar_MenuContainer_Social_DDBorderTop,
    setDB_LeftSidebar_MenuContainer_Social_DDBorderTop,
  ] = useState(1);
  const [
    DB_LeftSidebar_MenuContainer_Social_DDBorderBottom,
    setDB_LeftSidebar_MenuContainer_Social_DDBorderBottom,
  ] = useState(1);
  const [
    DB_LeftSidebar_MenuContainer_Social_DDBorderLeft,
    setDB_LeftSidebar_MenuContainer_Social_DDBorderLeft,
  ] = useState(1);
  const [
    DB_LeftSidebar_MenuContainer_Social_DDBorderRight,
    setDB_LeftSidebar_MenuContainer_Social_DDBorderRight,
  ] = useState(1);

  const [
    DB_LeftSidebar_MenuContainer_Social_DDBorderStyleTop,
    setDB_LeftSidebar_MenuContainer_Social_DDBorderStyleTop,
  ] = useState("solid");
  const [
    DB_LeftSidebar_MenuContainer_Social_DDBorderStyleBottom,
    setDB_LeftSidebar_MenuContainer_Social_DDBorderStyleBottom,
  ] = useState("solid");
  const [
    DB_LeftSidebar_MenuContainer_Social_DDBorderStyleLeft,
    setDB_LeftSidebar_MenuContainer_Social_DDBorderStyleLeft,
  ] = useState("solid");
  const [
    DB_LeftSidebar_MenuContainer_Social_DDBorderStyleRight,
    setDB_LeftSidebar_MenuContainer_Social_DDBorderStyleRight,
  ] = useState("solid");

  const [
    DB_LeftSidebar_MenuContainer_Social_DDBorderColorTop,
    setDB_LeftSidebar_MenuContainer_Social_DDBorderColorTop,
  ] = useState("rgba(0,0,0,0.3)");
  const [
    DB_LeftSidebar_MenuContainer_Social_DDBorderColorBottom,
    setDB_LeftSidebar_MenuContainer_Social_DDBorderColorBottom,
  ] = useState("rgba(0,0,0,0.3)");
  const [
    DB_LeftSidebar_MenuContainer_Social_DDBorderColorLeft,
    setDB_LeftSidebar_MenuContainer_Social_DDBorderColorLeft,
  ] = useState("rgba(0,0,0,0.3)");
  const [
    DB_LeftSidebar_MenuContainer_Social_DDBorderColorRight,
    setDB_LeftSidebar_MenuContainer_Social_DDBorderColorRight,
  ] = useState("rgba(0,0,0,0.3)");

  const [
    DB_LeftSidebar_MenuContainer_Social_DDBorderColorTopNotEncoded,
    setDB_LeftSidebar_MenuContainer_Social_DDBorderColorTopNotEncoded,
  ] = useState("rgba(0,0,0,0.3)");
  const [
    DB_LeftSidebar_MenuContainer_Social_DDBorderColorBottomNotEncoded,
    setDB_LeftSidebar_MenuContainer_Social_DDBorderColorBottomNotEncoded,
  ] = useState("rgba(0,0,0,0.3)");
  const [
    DB_LeftSidebar_MenuContainer_Social_DDBorderColorLeftNotEncoded,
    setDB_LeftSidebar_MenuContainer_Social_DDBorderColorLeftNotEncoded,
  ] = useState("rgba(0,0,0,0.3)");
  const [
    DB_LeftSidebar_MenuContainer_Social_DDBorderColorRightNotEncoded,
    setDB_LeftSidebar_MenuContainer_Social_DDBorderColorRightNotEncoded,
  ] = useState("rgba(0,0,0,0.3)");

  const [
    DB_LeftSidebar_MenuItem_SocialItem_marginTopBottom,
    setDB_LeftSidebar_MenuItem_SocialItem_marginTopBottom,
  ] = useState("0");
  const [
    DB_LeftSidebar_MenuItem_SocialItem_marginLeftRight,
    setDB_LeftSidebar_MenuItem_SocialItem_marginLeftRight,
  ] = useState("0");

  const [
    DB_LeftSidebar_MenuItem_SocialItem_paddingLeftRight,
    setDB_LeftSidebar_MenuItem_SocialItem_paddingLeftRight,
  ] = useState("30");

  const [
    DB_LeftSidebar_MenuItem_SocialItem_paddingTopBottom,
    setDB_LeftSidebar_MenuItem_SocialItem_paddingTopBottom,
  ] = useState("0");

  const [
    DB_LeftSidebar_MenuItem_SocialItem_letterSpacing,
    setDB_LeftSidebar_MenuItem_SocialItem_letterSpacing,
  ] = useState("0");

  const [
    DB_LeftSidebar_MenuItem_SocialItem_marginTopBottom_Hover,
    setDB_LeftSidebar_MenuItem_SocialItem_marginTopBottom_Hover,
  ] = useState("0");
  const [
    DB_LeftSidebar_MenuItem_SocialItem_marginLeftRight_Hover,
    setDB_LeftSidebar_MenuItem_SocialItem_marginLeftRight_Hover,
  ] = useState("0");

  const [
    DB_LeftSidebar_MenuItem_SocialItem_paddingLeftRight_Hover,
    setDB_LeftSidebar_MenuItem_SocialItem_paddingLeftRight_Hover,
  ] = useState("30");

  const [
    DB_LeftSidebar_MenuItem_SocialItem_paddingTopBottom_Hover,
    setDB_LeftSidebar_MenuItem_SocialItem_paddingTopBottom_Hover,
  ] = useState("0");

  const [
    DB_LeftSidebar_MenuItem_SocialItem_letterSpacing_Hover,
    setDB_LeftSidebar_MenuItem_SocialItem_letterSpacing_Hover,
  ] = useState("0");

  const [
    DB_LeftSidebar_MenuItem_SocialItem_ShadowHorizontal,
    setDB_LeftSidebar_MenuItem_SocialItem_ShadowHorizontal,
  ] = useState(0);
  const [
    DB_LeftSidebar_MenuItem_SocialItem_ShadowVertical,
    setDB_LeftSidebar_MenuItem_SocialItem_ShadowVertical,
  ] = useState(0);
  const [
    DB_LeftSidebar_MenuItem_SocialItem_ShadowBlur,
    setDB_LeftSidebar_MenuItem_SocialItem_ShadowBlur,
  ] = useState(5);
  const [
    DB_LeftSidebar_MenuItem_SocialItem_ShadowColor,
    setDB_LeftSidebar_MenuItem_SocialItem_ShadowColor,
  ] = useState("rgba(255,255,255,1)");

  const [
    DB_LeftSidebar_MenuItem_SocialItem_ShadowColorNotEncoded,
    setDB_LeftSidebar_MenuItem_SocialItem_ShadowColorNotEncoded,
  ] = useState("rgba(255,255,255,1)");

  const [
    DB_LeftSidebar_MenuItem_SocialItem_TextDecoration,
    setDB_LeftSidebar_MenuItem_SocialItem_TextDecoration,
  ] = useState("normal");

  const [
    DB_LeftSidebar_MenuItem_SocialItem_TextDecorationStyle,
    setDB_LeftSidebar_MenuItem_SocialItem_TextDecorationStyle,
  ] = useState("");

  const [
    DB_LeftSidebar_MenuItem_SocialItem_ShadowHorizontal_Hover,
    setDB_LeftSidebar_MenuItem_SocialItem_ShadowHorizontal_Hover,
  ] = useState(1);
  const [
    DB_LeftSidebar_MenuItem_SocialItem_ShadowVertical_Hover,
    setDB_LeftSidebar_MenuItem_SocialItem_ShadowVertical_Hover,
  ] = useState(1);
  const [
    DB_LeftSidebar_MenuItem_SocialItem_ShadowBlur_Hover,
    setDB_LeftSidebar_MenuItem_SocialItem_ShadowBlur_Hover,
  ] = useState(5);
  const [
    DB_LeftSidebar_MenuItem_SocialItem_ShadowColor_Hover,
    setDB_LeftSidebar_MenuItem_SocialItem_ShadowColor_Hover,
  ] = useState("rgba(255,255,255,1)");
  const [
    DB_LeftSidebar_MenuItem_SocialItem_TextDecoration_Hover,
    setDB_LeftSidebar_MenuItem_SocialItem_TextDecoration_Hover,
  ] = useState("none");
  const [
    DB_LeftSidebar_MenuItem_SocialItem_TextDecorationStyle_Hover,
    setDB_LeftSidebar_MenuItem_SocialItem_TextDecorationStyle_Hover,
  ] = useState("");

  const [
    DB_LeftSidebar_MenuItem_SocialItem_ShadowColorNotEncoded_Hover,
    setDB_LeftSidebar_MenuItem_SocialItem_ShadowColorNotEncoded_Hover,
  ] = useState("rgba(255,255,255,1)");

  const [
    DB_LeftSidebar_MenuItem_SocialItem_Hover_FontSize,
    setDB_LeftSidebar_MenuItem_SocialItem_Hover_FontSize,
  ] = useState(14);
  const DB_LeftSidebar_MenuItem_SocialItem_Hover_FontFace =
    DB_fontFace_FontFamilyName;
  const [
    DB_LeftSidebar_MenuItem_SocialItem_Hover_FontWeight,
    setDB_LeftSidebar_MenuItem_SocialItem_Hover_FontWeight,
  ] = useState(400);
  const [
    DB_LeftSidebar_MenuItem_SocialItem_Hover_FontColor,
    setDB_LeftSidebar_MenuItem_SocialItem_Hover_FontColor,
  ] = useState("rgba(255,255,255,1)");

  const [
    DB_LeftSidebar_MenuItem_SocialItem_Hover_FontColorNotEncoded,
    setDB_LeftSidebar_MenuItem_SocialItem_Hover_FontColorNotEncoded,
  ] = useState("rgba(0,0,0,0)");

  const [
    DB_LeftSidebar_MenuItem_SocialItem_Hover_BackgroundColor,
    setDB_LeftSidebar_MenuItem_SocialItem_Hover_BackgroundColor,
  ] = useState("rgba(0,0,0,0)");
  const [
    DB_LeftSidebar_MenuItem_SocialItem_Hover_BackgroundColorNotEncoded,
    setDB_LeftSidebar_MenuItem_SocialItem_Hover_BackgroundColorNotEncoded,
  ] = useState("rgba(0,0,0,0)");

  const [
    DB_LeftSidebar_MenuItem_SocialItem_Hover_TextAlign,
    setDB_LeftSidebar_MenuItem_SocialItem_Hover_TextAlign,
  ] = useState("left");

  /////////////////////////////////
  const [
    DB_LeftSidebar_MenuItem_SocialItem_Hover_BorderTopRightRadius,
    setDB_LeftSidebar_MenuItem_SocialItem_Hover_BorderTopRightRadius,
  ] = useState(0);
  const [
    DB_LeftSidebar_MenuItem_SocialItem_Hover_BorderTopLeftRadius,
    setDB_LeftSidebar_MenuItem_SocialItem_Hover_BorderTopLeftRadius,
  ] = useState(0);
  const [
    DB_LeftSidebar_MenuItem_SocialItem_Hover_BorderBottomRightRadius,
    setDB_LeftSidebar_MenuItem_SocialItem_Hover_BorderBottomRightRadius,
  ] = useState(0);
  const [
    DB_LeftSidebar_MenuItem_SocialItem_Hover_BorderBottomLeftRadius,
    setDB_LeftSidebar_MenuItem_SocialItem_Hover_BorderBottomLeftRadius,
  ] = useState(0);

  const [
    DB_LeftSidebar_MenuItem_SocialItem_Hover_BorderColorTop,
    setDB_LeftSidebar_MenuItem_SocialItem_Hover_BorderColorTop,
  ] = useState("rgba(255,255,255,1)");
  const [
    DB_LeftSidebar_MenuItem_SocialItem_Hover_BorderColorBottom,
    setDB_LeftSidebar_MenuItem_SocialItem_Hover_BorderColorBottom,
  ] = useState("rgba(255,255,255,1)");
  const [
    DB_LeftSidebar_MenuItem_SocialItem_Hover_BorderColorLeft,
    setDB_LeftSidebar_MenuItem_SocialItem_Hover_BorderColorLeft,
  ] = useState("rgba(255,255,255,1)");
  const [
    DB_LeftSidebar_MenuItem_SocialItem_Hover_BorderColorRight,
    setDB_LeftSidebar_MenuItem_SocialItem_Hover_BorderColorRight,
  ] = useState("rgba(255,255,255,1)");

  const [
    DB_LeftSidebar_MenuItem_SocialItem_Hover_BorderColorTopNotEncoded,
    setDB_LeftSidebar_MenuItem_SocialItem_Hover_BorderColorTopNotEncoded,
  ] = useState("rgba(255,255,255,1)");
  const [
    DB_LeftSidebar_MenuItem_SocialItem_Hover_BorderColorBottomNotEncoded,
    setDB_LeftSidebar_MenuItem_SocialItem_Hover_BorderColorBottomNotEncoded,
  ] = useState("rgba(255,255,255,1)");
  const [
    DB_LeftSidebar_MenuItem_SocialItem_Hover_BorderColorLeftNotEncoded,
    setDB_LeftSidebar_MenuItem_SocialItem_Hover_BorderColorLeftNotEncoded,
  ] = useState("rgba(255,255,255,1)");
  const [
    DB_LeftSidebar_MenuItem_SocialItem_Hover_BorderColorRightNotEncoded,
    setDB_LeftSidebar_MenuItem_SocialItem_Hover_BorderColorRightNotEncoded,
  ] = useState("rgba(255,255,255,1)");

  const [
    DB_LeftSidebar_MenuItem_SocialItem_Hover_BorderTop,
    setDB_LeftSidebar_MenuItem_SocialItem_Hover_BorderTop,
  ] = useState(0);
  const [
    DB_LeftSidebar_MenuItem_SocialItem_Hover_BorderBottom,
    setDB_LeftSidebar_MenuItem_SocialItem_Hover_BorderBottom,
  ] = useState(0);
  const [
    DB_LeftSidebar_MenuItem_SocialItem_Hover_BorderLeft,
    setDB_LeftSidebar_MenuItem_SocialItem_Hover_BorderLeft,
  ] = useState(0);
  const [
    DB_LeftSidebar_MenuItem_SocialItem_Hover_BorderRight,
    setDB_LeftSidebar_MenuItem_SocialItem_Hover_BorderRight,
  ] = useState(0);

  const [
    DB_LeftSidebar_MenuItem_SocialItem_Hover_BorderStyleTop,
    setDB_LeftSidebar_MenuItem_SocialItem_Hover_BorderStyleTop,
  ] = useState("solid");
  const [
    DB_LeftSidebar_MenuItem_SocialItem_Hover_BorderStyleBottom,
    setDB_LeftSidebar_MenuItem_SocialItem_Hover_BorderStyleBottom,
  ] = useState("solid");
  const [
    DB_LeftSidebar_MenuItem_SocialItem_Hover_BorderStyleLeft,
    setDB_LeftSidebar_MenuItem_SocialItem_Hover_BorderStyleLeft,
  ] = useState("solid");
  const [
    DB_LeftSidebar_MenuItem_SocialItem_Hover_BorderStyleRight,
    setDB_LeftSidebar_MenuItem_SocialItem_Hover_BorderStyleRight,
  ] = useState("solid");

  const DB_LeftSidebar_MenuItem_SocialItem_Hover_HoverBackgroundColor =
    "rgba(255,255,255,0.3)";
  const DB_LeftSidebar_MenuItem_SocialItem_Hover_HoverFontColor =
    "rgba(255,255,255,1)";

  // DB_LeftSidebar - SocialItem Normal .-----------------.---------------------.----------------------
  const [
    DB_LeftSidebar_MenuItem_SocialItem_FontSize,
    setDB_LeftSidebar_MenuItem_SocialItem_FontSize,
  ] = useState(14);
  const DB_LeftSidebar_MenuItem_SocialItem_FontFace =
    DB_fontFace_FontFamilyName;
  const [
    DB_LeftSidebar_MenuItem_SocialItem_FontWeight,
    setDB_LeftSidebar_MenuItem_SocialItem_FontWeight,
  ] = useState(400);
  const [
    DB_LeftSidebar_MenuItem_SocialItem_FontColor,
    setDB_LeftSidebar_MenuItem_SocialItem_FontColor,
  ] = useState("rgba(255,255,255,1)");

  const [
    DB_LeftSidebar_MenuItem_SocialItem_FontColorNotEncoded,
    setDB_LeftSidebar_MenuItem_SocialItem_FontColorNotEncoded,
  ] = useState("rgba(0,0,0,0)");

  const [
    DB_LeftSidebar_MenuItem_SocialItem_BackgroundColor,
    setDB_LeftSidebar_MenuItem_SocialItem_BackgroundColor,
  ] = useState("rgba(0,0,0,0)");
  const [
    DB_LeftSidebar_MenuItem_SocialItem_BackgroundColorNotEncoded,
    setDB_LeftSidebar_MenuItem_SocialItem_BackgroundColorNotEncoded,
  ] = useState("rgba(0,0,0,0)");

  const [
    DB_LeftSidebar_MenuItem_SocialItem_TextAlign,
    setDB_LeftSidebar_MenuItem_SocialItem_TextAlign,
  ] = useState("left");

  /////////////////////////////////
  const [
    DB_LeftSidebar_MenuItem_SocialItem_BorderTopRightRadius,
    setDB_LeftSidebar_MenuItem_SocialItem_BorderTopRightRadius,
  ] = useState(0);
  const [
    DB_LeftSidebar_MenuItem_SocialItem_BorderTopLeftRadius,
    setDB_LeftSidebar_MenuItem_SocialItem_BorderTopLeftRadius,
  ] = useState(0);
  const [
    DB_LeftSidebar_MenuItem_SocialItem_BorderBottomRightRadius,
    setDB_LeftSidebar_MenuItem_SocialItem_BorderBottomRightRadius,
  ] = useState(0);
  const [
    DB_LeftSidebar_MenuItem_SocialItem_BorderBottomLeftRadius,
    setDB_LeftSidebar_MenuItem_SocialItem_BorderBottomLeftRadius,
  ] = useState(0);

  const [
    DB_LeftSidebar_MenuItem_SocialItem_BorderColorTop,
    setDB_LeftSidebar_MenuItem_SocialItem_BorderColorTop,
  ] = useState("rgba(255,255,255,1)");
  const [
    DB_LeftSidebar_MenuItem_SocialItem_BorderColorBottom,
    setDB_LeftSidebar_MenuItem_SocialItem_BorderColorBottom,
  ] = useState("rgba(255,255,255,1)");
  const [
    DB_LeftSidebar_MenuItem_SocialItem_BorderColorLeft,
    setDB_LeftSidebar_MenuItem_SocialItem_BorderColorLeft,
  ] = useState("rgba(255,255,255,1)");
  const [
    DB_LeftSidebar_MenuItem_SocialItem_BorderColorRight,
    setDB_LeftSidebar_MenuItem_SocialItem_BorderColorRight,
  ] = useState("rgba(255,255,255,1)");

  const [
    DB_LeftSidebar_MenuItem_SocialItem_BorderColorTopNotEncoded,
    setDB_LeftSidebar_MenuItem_SocialItem_BorderColorTopNotEncoded,
  ] = useState("rgba(255,255,255,1)");
  const [
    DB_LeftSidebar_MenuItem_SocialItem_BorderColorBottomNotEncoded,
    setDB_LeftSidebar_MenuItem_SocialItem_BorderColorBottomNotEncoded,
  ] = useState("rgba(255,255,255,1)");
  const [
    DB_LeftSidebar_MenuItem_SocialItem_BorderColorLeftNotEncoded,
    setDB_LeftSidebar_MenuItem_SocialItem_BorderColorLeftNotEncoded,
  ] = useState("rgba(255,255,255,1)");
  const [
    DB_LeftSidebar_MenuItem_SocialItem_BorderColorRightNotEncoded,
    setDB_LeftSidebar_MenuItem_SocialItem_BorderColorRightNotEncoded,
  ] = useState("rgba(255,255,255,1)");

  const [
    DB_LeftSidebar_MenuItem_SocialItem_BorderTop,
    setDB_LeftSidebar_MenuItem_SocialItem_BorderTop,
  ] = useState(0);
  const [
    DB_LeftSidebar_MenuItem_SocialItem_BorderBottom,
    setDB_LeftSidebar_MenuItem_SocialItem_BorderBottom,
  ] = useState(0);
  const [
    DB_LeftSidebar_MenuItem_SocialItem_BorderLeft,
    setDB_LeftSidebar_MenuItem_SocialItem_BorderLeft,
  ] = useState(0);
  const [
    DB_LeftSidebar_MenuItem_SocialItem_BorderRight,
    setDB_LeftSidebar_MenuItem_SocialItem_BorderRight,
  ] = useState(0);

  const [
    DB_LeftSidebar_MenuItem_SocialItem_BorderStyleTop,
    setDB_LeftSidebar_MenuItem_SocialItem_BorderStyleTop,
  ] = useState("solid");
  const [
    DB_LeftSidebar_MenuItem_SocialItem_BorderStyleBottom,
    setDB_LeftSidebar_MenuItem_SocialItem_BorderStyleBottom,
  ] = useState("solid");
  const [
    DB_LeftSidebar_MenuItem_SocialItem_BorderStyleLeft,
    setDB_LeftSidebar_MenuItem_SocialItem_BorderStyleLeft,
  ] = useState("solid");
  const [
    DB_LeftSidebar_MenuItem_SocialItem_BorderStyleRight,
    setDB_LeftSidebar_MenuItem_SocialItem_BorderStyleRight,
  ] = useState("solid");

  const DB_LeftSidebar_MenuItem_SocialItem_HoverBackgroundColor =
    "rgba(255,255,255,0.3)";
  const DB_LeftSidebar_MenuItem_SocialItem_HoverFontColor =
    "rgba(255,255,255,1)";

  ////////////////////////////////////// SOCIAL TEXT ///////////////////////////////
  const [
    DB_fontFace_SocialTextFontFamily_FontFamilyName,
    setDB_fontFace_SocialTextFontFamily_FontFamilyName,
  ] = useState("Lato");
  const [
    DB_fontFace_SocialTextFontFamily_FontFamilySrc,
    setDB_fontFace_SocialTextFontFamily_FontFamilySrc,
  ] = useState("/assets/Lato/Lato-Regular.ttf");

  const [
    DB_LeftSidebar_MenuItem_SocialText_Hover_FontSize,
    setDB_LeftSidebar_MenuItem_SocialText_Hover_FontSize,
  ] = useState(16);
  const DB_LeftSidebar_MenuItem_SocialText_Hover_FontFace =
    DB_fontFace_FontFamilyName;
  const [
    DB_LeftSidebar_MenuItem_SocialText_Hover_FontWeight,
    setDB_LeftSidebar_MenuItem_SocialText_Hover_FontWeight,
  ] = useState(400);
  const [
    DB_LeftSidebar_MenuItem_SocialText_Hover_FontColor,
    setDB_LeftSidebar_MenuItem_SocialText_Hover_FontColor,
  ] = useState("rgba(255,255,255,1)");

  const [
    DB_LeftSidebar_MenuItem_SocialText_Hover_FontColorNotEncoded,
    setDB_LeftSidebar_MenuItem_SocialText_Hover_FontColorNotEncoded,
  ] = useState("rgba(0,0,0,0.3)");

  const [
    DB_LeftSidebar_MenuItem_SocialText_Hover_BackgroundColor,
    setDB_LeftSidebar_MenuItem_SocialText_Hover_BackgroundColor,
  ] = useState("rgba(0,0,0,0.3)");
  const [
    DB_LeftSidebar_MenuItem_SocialText_Hover_BackgroundColorNotEncoded,
    setDB_LeftSidebar_MenuItem_SocialText_Hover_BackgroundColorNotEncoded,
  ] = useState("rgba(0,0,0,0.3)");

  const [
    DB_LeftSidebar_MenuItem_SocialText_Hover_TextAlign,
    setDB_LeftSidebar_MenuItem_SocialText_Hover_TextAlign,
  ] = useState("center");

  const [
    DB_LeftSidebar_MenuItem_SocialText_marginTopBottom,
    setDB_LeftSidebar_MenuItem_SocialText_marginTopBottom,
  ] = useState("5");
  const [
    DB_LeftSidebar_MenuItem_SocialText_marginLeftRight,
    setDB_LeftSidebar_MenuItem_SocialText_marginLeftRight,
  ] = useState("5");

  const [
    DB_LeftSidebar_MenuItem_SocialText_paddingLeftRight,
    setDB_LeftSidebar_MenuItem_SocialText_paddingLeftRight,
  ] = useState("5");

  const [
    DB_LeftSidebar_MenuItem_SocialText_paddingTopBottom,
    setDB_LeftSidebar_MenuItem_SocialText_paddingTopBottom,
  ] = useState("5");

  const [
    DB_LeftSidebar_MenuItem_SocialText_letterSpacing,
    setDB_LeftSidebar_MenuItem_SocialText_letterSpacing,
  ] = useState("0");

  const [
    DB_LeftSidebar_MenuItem_SocialText_marginTopBottom_Hover,
    setDB_LeftSidebar_MenuItem_SocialText_marginTopBottom_Hover,
  ] = useState("5");
  const [
    DB_LeftSidebar_MenuItem_SocialText_marginLeftRight_Hover,
    setDB_LeftSidebar_MenuItem_SocialText_marginLeftRight_Hover,
  ] = useState("5");

  const [
    DB_LeftSidebar_MenuItem_SocialText_paddingLeftRight_Hover,
    setDB_LeftSidebar_MenuItem_SocialText_paddingLeftRight_Hover,
  ] = useState("5");

  const [
    DB_LeftSidebar_MenuItem_SocialText_paddingTopBottom_Hover,
    setDB_LeftSidebar_MenuItem_SocialText_paddingTopBottom_Hover,
  ] = useState("5");

  const [
    DB_LeftSidebar_MenuItem_SocialText_letterSpacing_Hover,
    setDB_LeftSidebar_MenuItem_SocialText_letterSpacing_Hover,
  ] = useState("0");

  const [
    DB_LeftSidebar_MenuItem_SocialText_ShadowHorizontal,
    setDB_LeftSidebar_MenuItem_SocialText_ShadowHorizontal,
  ] = useState(1);
  const [
    DB_LeftSidebar_MenuItem_SocialText_ShadowVertical,
    setDB_LeftSidebar_MenuItem_SocialText_ShadowVertical,
  ] = useState(1);
  const [
    DB_LeftSidebar_MenuItem_SocialText_ShadowBlur,
    setDB_LeftSidebar_MenuItem_SocialText_ShadowBlur,
  ] = useState(5);
  const [
    DB_LeftSidebar_MenuItem_SocialText_ShadowColor,
    setDB_LeftSidebar_MenuItem_SocialText_ShadowColor,
  ] = useState("rgba(255,255,255,1)");

  const [
    DB_LeftSidebar_MenuItem_SocialText_ShadowColorNotEncoded,
    setDB_LeftSidebar_MenuItem_SocialText_ShadowColorNotEncoded,
  ] = useState("rgba(255,255,255,1)");

  const [
    DB_LeftSidebar_MenuItem_SocialText_TextDecoration,
    setDB_LeftSidebar_MenuItem_SocialText_TextDecoration,
  ] = useState("normal");

  const [
    DB_LeftSidebar_MenuItem_SocialText_TextDecorationStyle,
    setDB_LeftSidebar_MenuItem_SocialText_TextDecorationStyle,
  ] = useState("");

  const [
    DB_LeftSidebar_MenuItem_SocialText_ShadowHorizontal_Hover,
    setDB_LeftSidebar_MenuItem_SocialText_ShadowHorizontal_Hover,
  ] = useState(1);
  const [
    DB_LeftSidebar_MenuItem_SocialText_ShadowVertical_Hover,
    setDB_LeftSidebar_MenuItem_SocialText_ShadowVertical_Hover,
  ] = useState(1);
  const [
    DB_LeftSidebar_MenuItem_SocialText_ShadowBlur_Hover,
    setDB_LeftSidebar_MenuItem_SocialText_ShadowBlur_Hover,
  ] = useState(5);
  const [
    DB_LeftSidebar_MenuItem_SocialText_ShadowColor_Hover,
    setDB_LeftSidebar_MenuItem_SocialText_ShadowColor_Hover,
  ] = useState("rgba(255,255,255,1)");
  const [
    DB_LeftSidebar_MenuItem_SocialText_TextDecoration_Hover,
    setDB_LeftSidebar_MenuItem_SocialText_TextDecoration_Hover,
  ] = useState("none");
  const [
    DB_LeftSidebar_MenuItem_SocialText_TextDecorationStyle_Hover,
    setDB_LeftSidebar_MenuItem_SocialText_TextDecorationStyle_Hover,
  ] = useState("");

  const [
    DB_LeftSidebar_MenuItem_SocialText_ShadowColorNotEncoded_Hover,
    setDB_LeftSidebar_MenuItem_SocialText_ShadowColorNotEncoded_Hover,
  ] = useState("rgba(255,255,255,1)");

  /////////////////////////////////
  const [
    DB_LeftSidebar_MenuItem_SocialText_Hover_BorderTopRightRadius,
    setDB_LeftSidebar_MenuItem_SocialText_Hover_BorderTopRightRadius,
  ] = useState(5);
  const [
    DB_LeftSidebar_MenuItem_SocialText_Hover_BorderTopLeftRadius,
    setDB_LeftSidebar_MenuItem_SocialText_Hover_BorderTopLeftRadius,
  ] = useState(5);
  const [
    DB_LeftSidebar_MenuItem_SocialText_Hover_BorderBottomRightRadius,
    setDB_LeftSidebar_MenuItem_SocialText_Hover_BorderBottomRightRadius,
  ] = useState(5);
  const [
    DB_LeftSidebar_MenuItem_SocialText_Hover_BorderBottomLeftRadius,
    setDB_LeftSidebar_MenuItem_SocialText_Hover_BorderBottomLeftRadius,
  ] = useState(5);

  const [
    DB_LeftSidebar_MenuItem_SocialText_Hover_BorderColorTop,
    setDB_LeftSidebar_MenuItem_SocialText_Hover_BorderColorTop,
  ] = useState("rgba(0,0,0,0.1)");
  const [
    DB_LeftSidebar_MenuItem_SocialText_Hover_BorderColorBottom,
    setDB_LeftSidebar_MenuItem_SocialText_Hover_BorderColorBottom,
  ] = useState("rgba(0,0,0,0.1)");
  const [
    DB_LeftSidebar_MenuItem_SocialText_Hover_BorderColorLeft,
    setDB_LeftSidebar_MenuItem_SocialText_Hover_BorderColorLeft,
  ] = useState("rgba(0,0,0,0.1)");
  const [
    DB_LeftSidebar_MenuItem_SocialText_Hover_BorderColorRight,
    setDB_LeftSidebar_MenuItem_SocialText_Hover_BorderColorRight,
  ] = useState("rgba(0,0,0,0.1)");

  const [
    DB_LeftSidebar_MenuItem_SocialText_Hover_BorderColorTopNotEncoded,
    setDB_LeftSidebar_MenuItem_SocialText_Hover_BorderColorTopNotEncoded,
  ] = useState("rgba(0,0,0,0.1)");
  const [
    DB_LeftSidebar_MenuItem_SocialText_Hover_BorderColorBottomNotEncoded,
    setDB_LeftSidebar_MenuItem_SocialText_Hover_BorderColorBottomNotEncoded,
  ] = useState("rgba(0,0,0,0.1)");
  const [
    DB_LeftSidebar_MenuItem_SocialText_Hover_BorderColorLeftNotEncoded,
    setDB_LeftSidebar_MenuItem_SocialText_Hover_BorderColorLeftNotEncoded,
  ] = useState("rgba(0,0,0,0.1)");
  const [
    DB_LeftSidebar_MenuItem_SocialText_Hover_BorderColorRightNotEncoded,
    setDB_LeftSidebar_MenuItem_SocialText_Hover_BorderColorRightNotEncoded,
  ] = useState("rgba(0,0,0,0.1)");

  const [
    DB_LeftSidebar_MenuItem_SocialText_Hover_BorderTop,
    setDB_LeftSidebar_MenuItem_SocialText_Hover_BorderTop,
  ] = useState(1);
  const [
    DB_LeftSidebar_MenuItem_SocialText_Hover_BorderBottom,
    setDB_LeftSidebar_MenuItem_SocialText_Hover_BorderBottom,
  ] = useState(1);
  const [
    DB_LeftSidebar_MenuItem_SocialText_Hover_BorderLeft,
    setDB_LeftSidebar_MenuItem_SocialText_Hover_BorderLeft,
  ] = useState(1);
  const [
    DB_LeftSidebar_MenuItem_SocialText_Hover_BorderRight,
    setDB_LeftSidebar_MenuItem_SocialText_Hover_BorderRight,
  ] = useState(1);

  const [
    DB_LeftSidebar_MenuItem_SocialText_Hover_BorderStyleTop,
    setDB_LeftSidebar_MenuItem_SocialText_Hover_BorderStyleTop,
  ] = useState("solid");
  const [
    DB_LeftSidebar_MenuItem_SocialText_Hover_BorderStyleBottom,
    setDB_LeftSidebar_MenuItem_SocialText_Hover_BorderStyleBottom,
  ] = useState("solid");
  const [
    DB_LeftSidebar_MenuItem_SocialText_Hover_BorderStyleLeft,
    setDB_LeftSidebar_MenuItem_SocialText_Hover_BorderStyleLeft,
  ] = useState("solid");
  const [
    DB_LeftSidebar_MenuItem_SocialText_Hover_BorderStyleRight,
    setDB_LeftSidebar_MenuItem_SocialText_Hover_BorderStyleRight,
  ] = useState("solid");

  // DB_LeftSidebar - SocialText Normal .-----------------.---------------------.----------------------
  const [
    DB_LeftSidebar_MenuItem_SocialText_FontSize,
    setDB_LeftSidebar_MenuItem_SocialText_FontSize,
  ] = useState(16);
  const DB_LeftSidebar_MenuItem_SocialText_FontFace =
    DB_fontFace_FontFamilyName;
  const [
    DB_LeftSidebar_MenuItem_SocialText_FontWeight,
    setDB_LeftSidebar_MenuItem_SocialText_FontWeight,
  ] = useState(400);
  const [
    DB_LeftSidebar_MenuItem_SocialText_FontColor,
    setDB_LeftSidebar_MenuItem_SocialText_FontColor,
  ] = useState("rgba(255,255,255,1)");

  const [
    DB_LeftSidebar_MenuItem_SocialText_FontColorNotEncoded,
    setDB_LeftSidebar_MenuItem_SocialText_FontColorNotEncoded,
  ] = useState("rgba(0,0,0,0.3)");

  const [
    DB_LeftSidebar_MenuItem_SocialText_BackgroundColor,
    setDB_LeftSidebar_MenuItem_SocialText_BackgroundColor,
  ] = useState("rgba(0,0,0,0.2)");
  const [
    DB_LeftSidebar_MenuItem_SocialText_BackgroundColorNotEncoded,
    setDB_LeftSidebar_MenuItem_SocialText_BackgroundColorNotEncoded,
  ] = useState("rgba(0,0,0,0.2)");

  const [
    DB_LeftSidebar_MenuItem_SocialText_TextAlign,
    setDB_LeftSidebar_MenuItem_SocialText_TextAlign,
  ] = useState("center");

  /////////////////////////////////
  const [
    DB_LeftSidebar_MenuItem_SocialText_BorderTopRightRadius,
    setDB_LeftSidebar_MenuItem_SocialText_BorderTopRightRadius,
  ] = useState(5);
  const [
    DB_LeftSidebar_MenuItem_SocialText_BorderTopLeftRadius,
    setDB_LeftSidebar_MenuItem_SocialText_BorderTopLeftRadius,
  ] = useState(5);
  const [
    DB_LeftSidebar_MenuItem_SocialText_BorderBottomRightRadius,
    setDB_LeftSidebar_MenuItem_SocialText_BorderBottomRightRadius,
  ] = useState(5);
  const [
    DB_LeftSidebar_MenuItem_SocialText_BorderBottomLeftRadius,
    setDB_LeftSidebar_MenuItem_SocialText_BorderBottomLeftRadius,
  ] = useState(5);

  const [
    DB_LeftSidebar_MenuItem_SocialText_BorderColorTop,
    setDB_LeftSidebar_MenuItem_SocialText_BorderColorTop,
  ] = useState("rgba(0,0,0,0.3)");
  const [
    DB_LeftSidebar_MenuItem_SocialText_BorderColorBottom,
    setDB_LeftSidebar_MenuItem_SocialText_BorderColorBottom,
  ] = useState("rgba(0,0,0,0.3)");
  const [
    DB_LeftSidebar_MenuItem_SocialText_BorderColorLeft,
    setDB_LeftSidebar_MenuItem_SocialText_BorderColorLeft,
  ] = useState("rgba(0,0,0,0.3)");
  const [
    DB_LeftSidebar_MenuItem_SocialText_BorderColorRight,
    setDB_LeftSidebar_MenuItem_SocialText_BorderColorRight,
  ] = useState("rgba(0,0,0,0.3)");
  const [
    DB_LeftSidebar_MenuItem_SocialText_BorderColorTopNotEncoded,
    setDB_LeftSidebar_MenuItem_SocialText_BorderColorTopNotEncoded,
  ] = useState("rgba(0,0,0,0.3)");
  const [
    DB_LeftSidebar_MenuItem_SocialText_BorderColorBottomNotEncoded,
    setDB_LeftSidebar_MenuItem_SocialText_BorderColorBottomNotEncoded,
  ] = useState("rgba(0,0,0,0.3)");
  const [
    DB_LeftSidebar_MenuItem_SocialText_BorderColorLeftNotEncoded,
    setDB_LeftSidebar_MenuItem_SocialText_BorderColorLeftNotEncoded,
  ] = useState("rgba(0,0,0,0.3)");
  const [
    DB_LeftSidebar_MenuItem_SocialText_BorderColorRightNotEncoded,
    setDB_LeftSidebar_MenuItem_SocialText_BorderColorRightNotEncoded,
  ] = useState("rgba(0,0,0,0.3)");

  const [
    DB_LeftSidebar_MenuItem_SocialText_BorderTop,
    setDB_LeftSidebar_MenuItem_SocialText_BorderTop,
  ] = useState(1);
  const [
    DB_LeftSidebar_MenuItem_SocialText_BorderBottom,
    setDB_LeftSidebar_MenuItem_SocialText_BorderBottom,
  ] = useState(1);
  const [
    DB_LeftSidebar_MenuItem_SocialText_BorderLeft,
    setDB_LeftSidebar_MenuItem_SocialText_BorderLeft,
  ] = useState(1);
  const [
    DB_LeftSidebar_MenuItem_SocialText_BorderRight,
    setDB_LeftSidebar_MenuItem_SocialText_BorderRight,
  ] = useState(1);

  const [
    DB_LeftSidebar_MenuItem_SocialText_BorderStyleTop,
    setDB_LeftSidebar_MenuItem_SocialText_BorderStyleTop,
  ] = useState("solid");
  const [
    DB_LeftSidebar_MenuItem_SocialText_BorderStyleBottom,
    setDB_LeftSidebar_MenuItem_SocialText_BorderStyleBottom,
  ] = useState("solid");
  const [
    DB_LeftSidebar_MenuItem_SocialText_BorderStyleLeft,
    setDB_LeftSidebar_MenuItem_SocialText_BorderStyleLeft,
  ] = useState("solid");
  const [
    DB_LeftSidebar_MenuItem_SocialText_BorderStyleRight,
    setDB_LeftSidebar_MenuItem_SocialText_BorderStyleRight,
  ] = useState("solid");

  //////////////////// Alle Filter Button //////////////////////////
  const [
    DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_FontSize,
    setDB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_FontSize,
  ] = useState(16);
  const DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_FontFace =
    DB_fontFace_FontFamilyName;
  const [
    DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_FontWeight,
    setDB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_FontWeight,
  ] = useState(400);
  const [
    DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_FontColor,
    setDB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_FontColor,
  ] = useState("rgba(255,255,255,1)");

  const [
    DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_FontColorNotEncoded,
    setDB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_FontColorNotEncoded,
  ] = useState("rgba(0,0,0,0.3)");

  const [
    DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BackgroundColor,
    setDB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BackgroundColor,
  ] = useState("rgba(0,0,0,0.3)");
  const [
    DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BackgroundColorNotEncoded,
    setDB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BackgroundColorNotEncoded,
  ] = useState("rgba(0,0,0,0.3)");

  const [
    DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_TextAlign,
    setDB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_TextAlign,
  ] = useState("center");

  const [
    DB_LeftSidebar_MenuItem_AlleFilterButtonFF_marginTopBottom,
    setDB_LeftSidebar_MenuItem_AlleFilterButtonFF_marginTopBottom,
  ] = useState("5");
  const [
    DB_LeftSidebar_MenuItem_AlleFilterButtonFF_marginLeftRight,
    setDB_LeftSidebar_MenuItem_AlleFilterButtonFF_marginLeftRight,
  ] = useState("5");

  const [
    DB_LeftSidebar_MenuItem_AlleFilterButtonFF_paddingLeftRight,
    setDB_LeftSidebar_MenuItem_AlleFilterButtonFF_paddingLeftRight,
  ] = useState("5");

  const [
    DB_LeftSidebar_MenuItem_AlleFilterButtonFF_paddingTopBottom,
    setDB_LeftSidebar_MenuItem_AlleFilterButtonFF_paddingTopBottom,
  ] = useState("5");

  const [
    DB_LeftSidebar_MenuItem_AlleFilterButtonFF_letterSpacing,
    setDB_LeftSidebar_MenuItem_AlleFilterButtonFF_letterSpacing,
  ] = useState("0");

  const [
    DB_LeftSidebar_MenuItem_AlleFilterButtonFF_marginTopBottom_Hover,
    setDB_LeftSidebar_MenuItem_AlleFilterButtonFF_marginTopBottom_Hover,
  ] = useState("5");
  const [
    DB_LeftSidebar_MenuItem_AlleFilterButtonFF_marginLeftRight_Hover,
    setDB_LeftSidebar_MenuItem_AlleFilterButtonFF_marginLeftRight_Hover,
  ] = useState("5");

  const [
    DB_LeftSidebar_MenuItem_AlleFilterButtonFF_paddingLeftRight_Hover,
    setDB_LeftSidebar_MenuItem_AlleFilterButtonFF_paddingLeftRight_Hover,
  ] = useState("5");

  const [
    DB_LeftSidebar_MenuItem_AlleFilterButtonFF_paddingTopBottom_Hover,
    setDB_LeftSidebar_MenuItem_AlleFilterButtonFF_paddingTopBottom_Hover,
  ] = useState("5");

  const [
    DB_LeftSidebar_MenuItem_AlleFilterButtonFF_letterSpacing_Hover,
    setDB_LeftSidebar_MenuItem_AlleFilterButtonFF_letterSpacing_Hover,
  ] = useState("0");

  const [
    DB_LeftSidebar_MenuItem_AlleFilterButtonFF_ShadowHorizontal,
    setDB_LeftSidebar_MenuItem_AlleFilterButtonFF_ShadowHorizontal,
  ] = useState(1);
  const [
    DB_LeftSidebar_MenuItem_AlleFilterButtonFF_ShadowVertical,
    setDB_LeftSidebar_MenuItem_AlleFilterButtonFF_ShadowVertical,
  ] = useState(1);
  const [
    DB_LeftSidebar_MenuItem_AlleFilterButtonFF_ShadowBlur,
    setDB_LeftSidebar_MenuItem_AlleFilterButtonFF_ShadowBlur,
  ] = useState(5);
  const [
    DB_LeftSidebar_MenuItem_AlleFilterButtonFF_ShadowColor,
    setDB_LeftSidebar_MenuItem_AlleFilterButtonFF_ShadowColor,
  ] = useState("rgba(255,255,255,1)");

  const [
    DB_LeftSidebar_MenuItem_AlleFilterButtonFF_ShadowColorNotEncoded,
    setDB_LeftSidebar_MenuItem_AlleFilterButtonFF_ShadowColorNotEncoded,
  ] = useState("rgba(255,255,255,1)");

  const [
    DB_LeftSidebar_MenuItem_AlleFilterButtonFF_TextDecoration,
    setDB_LeftSidebar_MenuItem_AlleFilterButtonFF_TextDecoration,
  ] = useState("normal");

  const [
    DB_LeftSidebar_MenuItem_AlleFilterButtonFF_TextDecorationStyle,
    setDB_LeftSidebar_MenuItem_AlleFilterButtonFF_TextDecorationStyle,
  ] = useState("");

  const [
    DB_LeftSidebar_MenuItem_AlleFilterButtonFF_ShadowHorizontal_Hover,
    setDB_LeftSidebar_MenuItem_AlleFilterButtonFF_ShadowHorizontal_Hover,
  ] = useState(1);
  const [
    DB_LeftSidebar_MenuItem_AlleFilterButtonFF_ShadowVertical_Hover,
    setDB_LeftSidebar_MenuItem_AlleFilterButtonFF_ShadowVertical_Hover,
  ] = useState(1);
  const [
    DB_LeftSidebar_MenuItem_AlleFilterButtonFF_ShadowBlur_Hover,
    setDB_LeftSidebar_MenuItem_AlleFilterButtonFF_ShadowBlur_Hover,
  ] = useState(5);
  const [
    DB_LeftSidebar_MenuItem_AlleFilterButtonFF_ShadowColor_Hover,
    setDB_LeftSidebar_MenuItem_AlleFilterButtonFF_ShadowColor_Hover,
  ] = useState("rgba(255,255,255,1)");
  const [
    DB_LeftSidebar_MenuItem_AlleFilterButtonFF_TextDecoration_Hover,
    setDB_LeftSidebar_MenuItem_AlleFilterButtonFF_TextDecoration_Hover,
  ] = useState("none");
  const [
    DB_LeftSidebar_MenuItem_AlleFilterButtonFF_TextDecorationStyle_Hover,
    setDB_LeftSidebar_MenuItem_AlleFilterButtonFF_TextDecorationStyle_Hover,
  ] = useState("");

  const [
    DB_LeftSidebar_MenuItem_AlleFilterButtonFF_ShadowColorNotEncoded_Hover,
    setDB_LeftSidebar_MenuItem_AlleFilterButtonFF_ShadowColorNotEncoded_Hover,
  ] = useState("rgba(255,255,255,1)");

  /////////////////////////////////
  const [
    DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderTopRightRadius,
    setDB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderTopRightRadius,
  ] = useState(5);
  const [
    DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderTopLeftRadius,
    setDB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderTopLeftRadius,
  ] = useState(5);
  const [
    DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderBottomRightRadius,
    setDB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderBottomRightRadius,
  ] = useState(5);
  const [
    DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderBottomLeftRadius,
    setDB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderBottomLeftRadius,
  ] = useState(5);

  const [
    DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderColorTop,
    setDB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderColorTop,
  ] = useState("rgba(0,0,0,0.1)");
  const [
    DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderColorBottom,
    setDB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderColorBottom,
  ] = useState("rgba(0,0,0,0.1)");
  const [
    DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderColorLeft,
    setDB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderColorLeft,
  ] = useState("rgba(0,0,0,0.1)");
  const [
    DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderColorRight,
    setDB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderColorRight,
  ] = useState("rgba(0,0,0,0.1)");

  const [
    DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderColorTopNotEncoded,
    setDB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderColorTopNotEncoded,
  ] = useState("rgba(0,0,0,0.1)");
  const [
    DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderColorBottomNotEncoded,
    setDB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderColorBottomNotEncoded,
  ] = useState("rgba(0,0,0,0.1)");
  const [
    DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderColorLeftNotEncoded,
    setDB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderColorLeftNotEncoded,
  ] = useState("rgba(0,0,0,0.1)");
  const [
    DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderColorRightNotEncoded,
    setDB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderColorRightNotEncoded,
  ] = useState("rgba(0,0,0,0.1)");

  const [
    DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderTop,
    setDB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderTop,
  ] = useState(1);
  const [
    DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderBottom,
    setDB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderBottom,
  ] = useState(1);
  const [
    DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderLeft,
    setDB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderLeft,
  ] = useState(1);
  const [
    DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderRight,
    setDB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderRight,
  ] = useState(1);

  const [
    DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderStyleTop,
    setDB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderStyleTop,
  ] = useState("solid");
  const [
    DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderStyleBottom,
    setDB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderStyleBottom,
  ] = useState("solid");
  const [
    DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderStyleLeft,
    setDB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderStyleLeft,
  ] = useState("solid");
  const [
    DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderStyleRight,
    setDB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderStyleRight,
  ] = useState("solid");

  // DB_LeftSidebar - AlleFilterButtonFF Normal .-----------------.---------------------.----------------------
  const [
    DB_LeftSidebar_MenuItem_AlleFilterButtonFF_FontSize,
    setDB_LeftSidebar_MenuItem_AlleFilterButtonFF_FontSize,
  ] = useState(16);
  const DB_LeftSidebar_MenuItem_AlleFilterButtonFF_FontFace =
    DB_fontFace_FontFamilyName;
  const [
    DB_LeftSidebar_MenuItem_AlleFilterButtonFF_FontWeight,
    setDB_LeftSidebar_MenuItem_AlleFilterButtonFF_FontWeight,
  ] = useState(400);
  const [
    DB_LeftSidebar_MenuItem_AlleFilterButtonFF_FontColor,
    setDB_LeftSidebar_MenuItem_AlleFilterButtonFF_FontColor,
  ] = useState("rgba(255,255,255,1)");

  const [
    DB_LeftSidebar_MenuItem_AlleFilterButtonFF_FontColorNotEncoded,
    setDB_LeftSidebar_MenuItem_AlleFilterButtonFF_FontColorNotEncoded,
  ] = useState("rgba(0,0,0,0.3)");

  const [
    DB_LeftSidebar_MenuItem_AlleFilterButtonFF_BackgroundColor,
    setDB_LeftSidebar_MenuItem_AlleFilterButtonFF_BackgroundColor,
  ] = useState("rgba(0,0,0,0.2)");
  const [
    DB_LeftSidebar_MenuItem_AlleFilterButtonFF_BackgroundColorNotEncoded,
    setDB_LeftSidebar_MenuItem_AlleFilterButtonFF_BackgroundColorNotEncoded,
  ] = useState("rgba(0,0,0,0.2)");

  const [
    DB_LeftSidebar_MenuItem_AlleFilterButtonFF_TextAlign,
    setDB_LeftSidebar_MenuItem_AlleFilterButtonFF_TextAlign,
  ] = useState("center");

  /////////////////////////////////
  const [
    DB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderTopRightRadius,
    setDB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderTopRightRadius,
  ] = useState(5);
  const [
    DB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderTopLeftRadius,
    setDB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderTopLeftRadius,
  ] = useState(5);
  const [
    DB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderBottomRightRadius,
    setDB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderBottomRightRadius,
  ] = useState(5);
  const [
    DB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderBottomLeftRadius,
    setDB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderBottomLeftRadius,
  ] = useState(5);

  const [
    DB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderColorTop,
    setDB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderColorTop,
  ] = useState("rgba(0,0,0,0.3)");
  const [
    DB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderColorBottom,
    setDB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderColorBottom,
  ] = useState("rgba(0,0,0,0.3)");
  const [
    DB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderColorLeft,
    setDB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderColorLeft,
  ] = useState("rgba(0,0,0,0.3)");
  const [
    DB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderColorRight,
    setDB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderColorRight,
  ] = useState("rgba(0,0,0,0.3)");
  const [
    DB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderColorTopNotEncoded,
    setDB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderColorTopNotEncoded,
  ] = useState("rgba(0,0,0,0.3)");
  const [
    DB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderColorBottomNotEncoded,
    setDB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderColorBottomNotEncoded,
  ] = useState("rgba(0,0,0,0.3)");
  const [
    DB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderColorLeftNotEncoded,
    setDB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderColorLeftNotEncoded,
  ] = useState("rgba(0,0,0,0.3)");
  const [
    DB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderColorRightNotEncoded,
    setDB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderColorRightNotEncoded,
  ] = useState("rgba(0,0,0,0.3)");

  const [
    DB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderTop,
    setDB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderTop,
  ] = useState(1);
  const [
    DB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderBottom,
    setDB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderBottom,
  ] = useState(1);
  const [
    DB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderLeft,
    setDB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderLeft,
  ] = useState(1);
  const [
    DB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderRight,
    setDB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderRight,
  ] = useState(1);

  const [
    DB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderStyleTop,
    setDB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderStyleTop,
  ] = useState("solid");
  const [
    DB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderStyleBottom,
    setDB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderStyleBottom,
  ] = useState("solid");
  const [
    DB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderStyleLeft,
    setDB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderStyleLeft,
  ] = useState("solid");
  const [
    DB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderStyleRight,
    setDB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderStyleRight,
  ] = useState("solid");

  const [
    DB_fontFace_AlleFilterButtonFontFamily_FontFamilyName,
    setDB_fontFace_AlleFilterButtonFontFamily_FontFamilyName,
  ] = useState("Lato");
  const [
    DB_fontFace_AlleFilterButtonFontFamily_FontFamilySrc,
    setDB_fontFace_AlleFilterButtonFontFamily_FontFamilySrc,
  ] = useState("/assets/Lato/Lato-Regular.ttf");

  /////////////////////////// FILTER HAUPTTITEL ////////////////////////////////
  const [
    DB_fontFace_FilterHauptTitel_FontFamilyName,
    setDB_fontFace_FilterHauptTitel_FontFamilyName,
  ] = useState("Lato");
  const [
    DB_fontFace_FilterHauptTitel_FontFamilySrc,
    setDB_fontFace_FilterHauptTitel_FontFamilySrc,
  ] = useState("/assets/Lato/Lato-Regular.ttf");

  const [
    DB_fontFace_FilterItemFontFamily_FontFamilyName,
    setDB_fontFace_FilterItemFontFamily_FontFamilyName,
  ] = useState("Lato");
  const [
    DB_fontFace_FilterItemFontFamily_FontFamilySrc,
    setDB_fontFace_FilterItemFontFamily_FontFamilySrc,
  ] = useState("/assets/Lato/Lato-Regular.ttf");

  const [
    DB_LeftSidebar_MenuItem_FilterHauptTitel_marginTopBottom,
    setDB_LeftSidebar_MenuItem_FilterHauptTitel_marginTopBottom,
  ] = useState("5");
  const [
    DB_LeftSidebar_MenuItem_FilterHauptTitel_marginLeftRight,
    setDB_LeftSidebar_MenuItem_FilterHauptTitel_marginLeftRight,
  ] = useState("30");

  const [
    DB_LeftSidebar_MenuItem_FilterHauptTitel_paddingLeftRight,
    setDB_LeftSidebar_MenuItem_FilterHauptTitel_paddingLeftRight,
  ] = useState("0");

  const [
    DB_LeftSidebar_MenuItem_FilterHauptTitel_paddingTopBottom,
    setDB_LeftSidebar_MenuItem_FilterHauptTitel_paddingTopBottom,
  ] = useState("0");

  const [
    DB_LeftSidebar_MenuItem_FilterHauptTitel_letterSpacing,
    setDB_LeftSidebar_MenuItem_FilterHauptTitel_letterSpacing,
  ] = useState("0");

  const [
    DB_LeftSidebar_MenuItem_FilterHauptTitel_marginTopBottom_Hover,
    setDB_LeftSidebar_MenuItem_FilterHauptTitel_marginTopBottom_Hover,
  ] = useState("5");
  const [
    DB_LeftSidebar_MenuItem_FilterHauptTitel_marginLeftRight_Hover,
    setDB_LeftSidebar_MenuItem_FilterHauptTitel_marginLeftRight_Hover,
  ] = useState("30");

  const [
    DB_LeftSidebar_MenuItem_FilterHauptTitel_paddingLeftRight_Hover,
    setDB_LeftSidebar_MenuItem_FilterHauptTitel_paddingLeftRight_Hover,
  ] = useState("0");

  const [
    DB_LeftSidebar_MenuItem_FilterHauptTitel_paddingTopBottom_Hover,
    setDB_LeftSidebar_MenuItem_FilterHauptTitel_paddingTopBottom_Hover,
  ] = useState("0");

  const [
    DB_LeftSidebar_MenuItem_FilterHauptTitel_letterSpacing_Hover,
    setDB_LeftSidebar_MenuItem_FilterHauptTitel_letterSpacing_Hover,
  ] = useState("0");

  const [
    DB_LeftSidebar_MenuItem_FilterHauptTitel_ShadowHorizontal,
    setDB_LeftSidebar_MenuItem_FilterHauptTitel_ShadowHorizontal,
  ] = useState(1);
  const [
    DB_LeftSidebar_MenuItem_FilterHauptTitel_ShadowVertical,
    setDB_LeftSidebar_MenuItem_FilterHauptTitel_ShadowVertical,
  ] = useState(1);
  const [
    DB_LeftSidebar_MenuItem_FilterHauptTitel_ShadowBlur,
    setDB_LeftSidebar_MenuItem_FilterHauptTitel_ShadowBlur,
  ] = useState(5);
  const [
    DB_LeftSidebar_MenuItem_FilterHauptTitel_ShadowColor,
    setDB_LeftSidebar_MenuItem_FilterHauptTitel_ShadowColor,
  ] = useState("rgba(255,255,255,1)");

  const [
    DB_LeftSidebar_MenuItem_FilterHauptTitel_ShadowColorNotEncoded,
    setDB_LeftSidebar_MenuItem_FilterHauptTitel_ShadowColorNotEncoded,
  ] = useState("rgba(255,255,255,1)");

  const [
    DB_LeftSidebar_MenuItem_FilterHauptTitel_TextDecoration,
    setDB_LeftSidebar_MenuItem_FilterHauptTitel_TextDecoration,
  ] = useState("normal");

  const [
    DB_LeftSidebar_MenuItem_FilterHauptTitel_TextDecorationStyle,
    setDB_LeftSidebar_MenuItem_FilterHauptTitel_TextDecorationStyle,
  ] = useState("");

  const [
    DB_LeftSidebar_MenuItem_FilterHauptTitel_ShadowHorizontal_Hover,
    setDB_LeftSidebar_MenuItem_FilterHauptTitel_ShadowHorizontal_Hover,
  ] = useState(1);
  const [
    DB_LeftSidebar_MenuItem_FilterHauptTitel_ShadowVertical_Hover,
    setDB_LeftSidebar_MenuItem_FilterHauptTitel_ShadowVertical_Hover,
  ] = useState(1);
  const [
    DB_LeftSidebar_MenuItem_FilterHauptTitel_ShadowBlur_Hover,
    setDB_LeftSidebar_MenuItem_FilterHauptTitel_ShadowBlur_Hover,
  ] = useState(5);
  const [
    DB_LeftSidebar_MenuItem_FilterHauptTitel_ShadowColor_Hover,
    setDB_LeftSidebar_MenuItem_FilterHauptTitel_ShadowColor_Hover,
  ] = useState("rgba(255,255,255,1)");
  const [
    DB_LeftSidebar_MenuItem_FilterHauptTitel_TextDecoration_Hover,
    setDB_LeftSidebar_MenuItem_FilterHauptTitel_TextDecoration_Hover,
  ] = useState("none");
  const [
    DB_LeftSidebar_MenuItem_FilterHauptTitel_TextDecorationStyle_Hover,
    setDB_LeftSidebar_MenuItem_FilterHauptTitel_TextDecorationStyle_Hover,
  ] = useState("");

  const [
    DB_LeftSidebar_MenuItem_FilterHauptTitel_ShadowColorNotEncoded_Hover,
    setDB_LeftSidebar_MenuItem_FilterHauptTitel_ShadowColorNotEncoded_Hover,
  ] = useState("rgba(255,255,255,1)");

  const [
    DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_FontSize,
    setDB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_FontSize,
  ] = useState(15);
  const DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_FontFace =
    DB_fontFace_FontFamilyName;
  const [
    DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_FontWeight,
    setDB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_FontWeight,
  ] = useState(400);
  const [
    DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_FontColor,
    setDB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_FontColor,
  ] = useState("rgba(255,255,255,1)");

  const [
    DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_FontColorNotEncoded,
    setDB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_FontColorNotEncoded,
  ] = useState("rgba(0,0,0,0.3)");

  const [
    DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BackgroundColor,
    setDB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BackgroundColor,
  ] = useState("rgba(0,0,0,0.3)");
  const [
    DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BackgroundColorNotEncoded,
    setDB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BackgroundColorNotEncoded,
  ] = useState("rgba(0,0,0,0.3)");

  const [
    DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_TextAlign,
    setDB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_TextAlign,
  ] = useState("center");

  /////////////////////////////////
  const [
    DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderTopRightRadius,
    setDB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderTopRightRadius,
  ] = useState(15);
  const [
    DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderTopLeftRadius,
    setDB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderTopLeftRadius,
  ] = useState(15);
  const [
    DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderBottomRightRadius,
    setDB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderBottomRightRadius,
  ] = useState(15);
  const [
    DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderBottomLeftRadius,
    setDB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderBottomLeftRadius,
  ] = useState(15);

  const [
    DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderColorTop,
    setDB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderColorTop,
  ] = useState("rgba(255,255,255,1)");
  const [
    DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderColorBottom,
    setDB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderColorBottom,
  ] = useState("rgba(255,255,255,1)");
  const [
    DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderColorLeft,
    setDB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderColorLeft,
  ] = useState("rgba(255,255,255,1)");
  const [
    DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderColorRight,
    setDB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderColorRight,
  ] = useState("rgba(255,255,255,1)");

  const [
    DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderColorTopNotEncoded,
    setDB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderColorTopNotEncoded,
  ] = useState("rgba(255,255,255,1)");
  const [
    DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderColorBottomNotEncoded,
    setDB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderColorBottomNotEncoded,
  ] = useState("rgba(255,255,255,1)");
  const [
    DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderColorLeftNotEncoded,
    setDB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderColorLeftNotEncoded,
  ] = useState("rgba(255,255,255,1)");
  const [
    DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderColorRightNotEncoded,
    setDB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderColorRightNotEncoded,
  ] = useState("rgba(255,255,255,1)");

  const [
    DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderTop,
    setDB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderTop,
  ] = useState(0);
  const [
    DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderBottom,
    setDB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderBottom,
  ] = useState(0);
  const [
    DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderLeft,
    setDB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderLeft,
  ] = useState(0);
  const [
    DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderRight,
    setDB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderRight,
  ] = useState(0);

  const [
    DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderStyleTop,
    setDB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderStyleTop,
  ] = useState("solid");
  const [
    DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderStyleBottom,
    setDB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderStyleBottom,
  ] = useState("solid");
  const [
    DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderStyleLeft,
    setDB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderStyleLeft,
  ] = useState("solid");
  const [
    DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderStyleRight,
    setDB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderStyleRight,
  ] = useState("solid");

  const DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_HoverBackgroundColor =
    "rgba(255,255,255,0.3)";
  const DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_HoverFontColor =
    "rgba(255,255,255,1)";

  // DB_LeftSidebar - FilterHauptTitel Normal .-----------------.---------------------.----------------------
  const [
    DB_LeftSidebar_MenuItem_FilterHauptTitel_FontSize,
    setDB_LeftSidebar_MenuItem_FilterHauptTitel_FontSize,
  ] = useState(15);
  const DB_LeftSidebar_MenuItem_FilterHauptTitel_FontFace =
    DB_fontFace_FontFamilyName;
  const [
    DB_LeftSidebar_MenuItem_FilterHauptTitel_FontWeight,
    setDB_LeftSidebar_MenuItem_FilterHauptTitel_FontWeight,
  ] = useState(400);
  const [
    DB_LeftSidebar_MenuItem_FilterHauptTitel_FontColor,
    setDB_LeftSidebar_MenuItem_FilterHauptTitel_FontColor,
  ] = useState("rgba(255,255,255,1)");

  const [
    DB_LeftSidebar_MenuItem_FilterHauptTitel_FontColorNotEncoded,
    setDB_LeftSidebar_MenuItem_FilterHauptTitel_FontColorNotEncoded,
  ] = useState("rgba(0,0,0,0.3)");

  const [
    DB_LeftSidebar_MenuItem_FilterHauptTitel_BackgroundColor,
    setDB_LeftSidebar_MenuItem_FilterHauptTitel_BackgroundColor,
  ] = useState("rgba(0,0,0,0.3)");
  const [
    DB_LeftSidebar_MenuItem_FilterHauptTitel_BackgroundColorNotEncoded,
    setDB_LeftSidebar_MenuItem_FilterHauptTitel_BackgroundColorNotEncoded,
  ] = useState("rgba(0,0,0,0.3)");

  const [
    DB_LeftSidebar_MenuItem_FilterHauptTitel_TextAlign,
    setDB_LeftSidebar_MenuItem_FilterHauptTitel_TextAlign,
  ] = useState("center");

  /////////////////////////////////
  const [
    DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderTopRightRadius,
    setDB_LeftSidebar_MenuItem_FilterHauptTitel_BorderTopRightRadius,
  ] = useState(15);
  const [
    DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderTopLeftRadius,
    setDB_LeftSidebar_MenuItem_FilterHauptTitel_BorderTopLeftRadius,
  ] = useState(15);
  const [
    DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderBottomRightRadius,
    setDB_LeftSidebar_MenuItem_FilterHauptTitel_BorderBottomRightRadius,
  ] = useState(15);
  const [
    DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderBottomLeftRadius,
    setDB_LeftSidebar_MenuItem_FilterHauptTitel_BorderBottomLeftRadius,
  ] = useState(15);

  const [
    DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderColorTop,
    setDB_LeftSidebar_MenuItem_FilterHauptTitel_BorderColorTop,
  ] = useState("rgba(255,255,255,1)");
  const [
    DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderColorBottom,
    setDB_LeftSidebar_MenuItem_FilterHauptTitel_BorderColorBottom,
  ] = useState("rgba(255,255,255,1)");
  const [
    DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderColorLeft,
    setDB_LeftSidebar_MenuItem_FilterHauptTitel_BorderColorLeft,
  ] = useState("rgba(255,255,255,1)");
  const [
    DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderColorRight,
    setDB_LeftSidebar_MenuItem_FilterHauptTitel_BorderColorRight,
  ] = useState("rgba(255,255,255,1)");

  const [
    DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderColorTopNotEncoded,
    setDB_LeftSidebar_MenuItem_FilterHauptTitel_BorderColorTopNotEncoded,
  ] = useState("rgba(255,255,255,1)");
  const [
    DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderColorBottomNotEncoded,
    setDB_LeftSidebar_MenuItem_FilterHauptTitel_BorderColorBottomNotEncoded,
  ] = useState("rgba(255,255,255,1)");
  const [
    DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderColorLeftNotEncoded,
    setDB_LeftSidebar_MenuItem_FilterHauptTitel_BorderColorLeftNotEncoded,
  ] = useState("rgba(255,255,255,1)");
  const [
    DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderColorRightNotEncoded,
    setDB_LeftSidebar_MenuItem_FilterHauptTitel_BorderColorRightNotEncoded,
  ] = useState("rgba(255,255,255,1)");

  const [
    DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderTop,
    setDB_LeftSidebar_MenuItem_FilterHauptTitel_BorderTop,
  ] = useState(0);
  const [
    DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderBottom,
    setDB_LeftSidebar_MenuItem_FilterHauptTitel_BorderBottom,
  ] = useState(0);
  const [
    DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderLeft,
    setDB_LeftSidebar_MenuItem_FilterHauptTitel_BorderLeft,
  ] = useState(0);
  const [
    DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderRight,
    setDB_LeftSidebar_MenuItem_FilterHauptTitel_BorderRight,
  ] = useState(0);

  const [
    DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderStyleTop,
    setDB_LeftSidebar_MenuItem_FilterHauptTitel_BorderStyleTop,
  ] = useState("solid");
  const [
    DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderStyleBottom,
    setDB_LeftSidebar_MenuItem_FilterHauptTitel_BorderStyleBottom,
  ] = useState("solid");
  const [
    DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderStyleLeft,
    setDB_LeftSidebar_MenuItem_FilterHauptTitel_BorderStyleLeft,
  ] = useState("solid");
  const [
    DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderStyleRight,
    setDB_LeftSidebar_MenuItem_FilterHauptTitel_BorderStyleRight,
  ] = useState("solid");

  const DB_LeftSidebar_MenuItem_FilterHauptTitel_HoverBackgroundColor =
    "rgba(255,255,255,0.3)";
  const DB_LeftSidebar_MenuItem_FilterHauptTitel_HoverFontColor =
    "rgba(255,255,255,1)";

  const [
    DB_LeftSidebar_MenuContainerFilterGG_Hover_PaddingTopBottom,
    setDB_LeftSidebar_MenuContainerFilterGG_Hover_PaddingTopBottom,
  ] = useState(10);

  const [
    DB_LeftSidebar_MenuContainerFilterGG_Hover_PaddingLeftRight,
    setDB_LeftSidebar_MenuContainerFilterGG_Hover_PaddingLeftRight,
  ] = useState(10);

  const [
    DB_LeftSidebar_MenuContainerFilterGG_Hover_BackgroundColor,
    setDB_LeftSidebar_MenuContainerFilterGG_Hover_BackgroundColor,
  ] = useState("rgba(0,0,0,0.3)");

  const [
    DB_LeftSidebar_MenuContainerFilterGG_Hover_BackgroundColorNotEncoded,
    setDB_LeftSidebar_MenuContainerFilterGG_Hover_BackgroundColorNotEncoded,
  ] = useState("rgba(0,0,0,0.3)");

  const [
    DB_LeftSidebar_MenuItem_MenuContainerFilterGG_ShadowHorizontal,
    setDB_LeftSidebar_MenuItem_MenuContainerFilterGG_ShadowHorizontal,
  ] = useState(0);
  const [
    DB_LeftSidebar_MenuItem_MenuContainerFilterGG_ShadowVertical,
    setDB_LeftSidebar_MenuItem_MenuContainerFilterGG_ShadowVertical,
  ] = useState(0);
  const [
    DB_LeftSidebar_MenuItem_MenuContainerFilterGG_ShadowBlur,
    setDB_LeftSidebar_MenuItem_MenuContainerFilterGG_ShadowBlur,
  ] = useState(5);
  const [
    DB_LeftSidebar_MenuItem_MenuContainerFilterGG_ShadowColor,
    setDB_LeftSidebar_MenuItem_MenuContainerFilterGG_ShadowColor,
  ] = useState("rgba(255,255,255,1)");

  const [
    DB_LeftSidebar_MenuItem_MenuContainerFilterGG_ShadowColorNotEncoded,
    setDB_LeftSidebar_MenuItem_MenuContainerFilterGG_ShadowColorNotEncoded,
  ] = useState("rgba(255,255,255,1)");

  const [
    DB_LeftSidebar_MenuItem_MenuContainerFilterGG_Hover_ShadowHorizontal,
    setDB_LeftSidebar_MenuItem_MenuContainerFilterGG_Hover_ShadowHorizontal,
  ] = useState(0);
  const [
    DB_LeftSidebar_MenuItem_MenuContainerFilterGG_Hover_ShadowVertical,
    setDB_LeftSidebar_MenuItem_MenuContainerFilterGG_Hover_ShadowVertical,
  ] = useState(0);
  const [
    DB_LeftSidebar_MenuItem_MenuContainerFilterGG_Hover_ShadowBlur,
    setDB_LeftSidebar_MenuItem_MenuContainerFilterGG_Hover_ShadowBlur,
  ] = useState(5);
  const [
    DB_LeftSidebar_MenuItem_MenuContainerFilterGG_Hover_ShadowColor,
    setDB_LeftSidebar_MenuItem_MenuContainerFilterGG_Hover_ShadowColor,
  ] = useState("rgba(255,255,255,1)");

  const [
    DB_LeftSidebar_MenuItem_MenuContainerFilterGG_Hover_ShadowColorNotEncoded,
    setDB_LeftSidebar_MenuItem_MenuContainerFilterGG_Hover_ShadowColorNotEncoded,
  ] = useState("rgba(255,255,255,1)");
  const [
    DB_LeftSidebar_MenuItem_MenuContainerFilterGG_paddingLeft,
    setDB_LeftSidebar_MenuItem_MenuContainerFilterGG_paddingLeft,
  ] = useState("0");

  const [
    DB_LeftSidebar_MenuItem_MenuContainerFilterGG_paddingTop,
    setDB_LeftSidebar_MenuItem_MenuContainerFilterGG_paddingTop,
  ] = useState("0");

  const [
    DB_LeftSidebar_MenuItem_MenuContainerFilterGG_paddingRight,
    setDB_LeftSidebar_MenuItem_MenuContainerFilterGG_paddingRight,
  ] = useState("0");

  const [
    DB_LeftSidebar_MenuItem_MenuContainerFilterGG_paddingBottom,
    setDB_LeftSidebar_MenuItem_MenuContainerFilterGG_paddingBottom,
  ] = useState("0");

  const [
    DB_LeftSidebar_MenuItem_MenuContainerFilterGG_Hover_paddingLeft,
    setDB_LeftSidebar_MenuItem_MenuContainerFilterGG_Hover_paddingLeft,
  ] = useState("0");

  const [
    DB_LeftSidebar_MenuItem_MenuContainerFilterGG_Hover_paddingTop,
    setDB_LeftSidebar_MenuItem_MenuContainerFilterGG_Hover_paddingTop,
  ] = useState("0");

  const [
    DB_LeftSidebar_MenuItem_MenuContainerFilterGG_Hover_paddingRight,
    setDB_LeftSidebar_MenuItem_MenuContainerFilterGG_Hover_paddingRight,
  ] = useState("0");

  const [
    DB_LeftSidebar_MenuItem_MenuContainerFilterGG_Hover_paddingBottom,
    setDB_LeftSidebar_MenuItem_MenuContainerFilterGG_Hover_paddingBottom,
  ] = useState("0");

  const [
    DB_LeftSidebar_MenuContainerFilterGG_BackgroundColor,
    setDB_LeftSidebar_MenuContainerFilterGG_BackgroundColor,
  ] = useState("rgba(0,0,0,0.3)");

  const [
    DB_LeftSidebar_MenuContainerFilterGG_BackgroundColorNotEncoded,
    setDB_LeftSidebar_MenuContainerFilterGG_BackgroundColorNotEncoded,
  ] = useState("rgba(0,0,0,0.3)");

  const [
    DB_LeftSidebar_MenuContainerFilterGG_PaddingTopBottom,
    setDB_LeftSidebar_MenuContainerFilterGG_PaddingTopBottom,
  ] = useState(10);

  const [
    DB_LeftSidebar_MenuContainerFilterGG_PaddingLeftRight,
    setDB_LeftSidebar_MenuContainerFilterGG_PaddingLeftRight,
  ] = useState(10);

  const [
    DB_LeftSidebar_MenuItem_MenuContainerFilterGG_BorderTopRightRadius,
    setDB_LeftSidebar_MenuItem_MenuContainerFilterGG_BorderTopRightRadius,
  ] = useState(20);
  const [
    DB_LeftSidebar_MenuItem_MenuContainerFilterGG_BorderTopLeftRadius,
    setDB_LeftSidebar_MenuItem_MenuContainerFilterGG_BorderTopLeftRadius,
  ] = useState(20);
  const [
    DB_LeftSidebar_MenuItem_MenuContainerFilterGG_BorderBottomRightRadius,
    setDB_LeftSidebar_MenuItem_MenuContainerFilterGG_BorderBottomRightRadius,
  ] = useState(20);
  const [
    DB_LeftSidebar_MenuItem_MenuContainerFilterGG_BorderBottomLeftRadius,
    setDB_LeftSidebar_MenuItem_MenuContainerFilterGG_BorderBottomLeftRadius,
  ] = useState(20);

  const [
    DB_LeftSidebar_MenuItem_MenuContainerFilterGG_Hover_BorderTopRightRadius,
    setDB_LeftSidebar_MenuItem_MenuContainerFilterGG_Hover_BorderTopRightRadius,
  ] = useState(20);
  const [
    DB_LeftSidebar_MenuItem_MenuContainerFilterGG_Hover_BorderTopLeftRadius,
    setDB_LeftSidebar_MenuItem_MenuContainerFilterGG_Hover_BorderTopLeftRadius,
  ] = useState(20);
  const [
    DB_LeftSidebar_MenuItem_MenuContainerFilterGG_Hover_BorderBottomRightRadius,
    setDB_LeftSidebar_MenuItem_MenuContainerFilterGG_Hover_BorderBottomRightRadius,
  ] = useState(20);
  const [
    DB_LeftSidebar_MenuItem_MenuContainerFilterGG_Hover_BorderBottomLeftRadius,
    setDB_LeftSidebar_MenuItem_MenuContainerFilterGG_Hover_BorderBottomLeftRadius,
  ] = useState(20);

  const [
    DB_LeftSidebar_MenuItem_MenuContainerFilterGG_BorderColorTop,
    setDB_LeftSidebar_MenuItem_MenuContainerFilterGG_BorderColorTop,
  ] = useState("rgba(255,255,255,1)");
  const [
    DB_LeftSidebar_MenuItem_MenuContainerFilterGG_BorderColorBottom,
    setDB_LeftSidebar_MenuItem_MenuContainerFilterGG_BorderColorBottom,
  ] = useState("rgba(255,255,255,1)");
  const [
    DB_LeftSidebar_MenuItem_MenuContainerFilterGG_BorderColorLeft,
    setDB_LeftSidebar_MenuItem_MenuContainerFilterGG_BorderColorLeft,
  ] = useState("rgba(255,255,255,1)");
  const [
    DB_LeftSidebar_MenuItem_MenuContainerFilterGG_BorderColorRight,
    setDB_LeftSidebar_MenuItem_MenuContainerFilterGG_BorderColorRight,
  ] = useState("rgba(255,255,255,1)");

  const [
    DB_LeftSidebar_MenuItem_MenuContainerFilterGG_BorderColorTopNotEncoded,
    setDB_LeftSidebar_MenuItem_MenuContainerFilterGG_BorderColorTopNotEncoded,
  ] = useState("rgba(255,255,255,1)");
  const [
    DB_LeftSidebar_MenuItem_MenuContainerFilterGG_BorderColorBottomNotEncoded,
    setDB_LeftSidebar_MenuItem_MenuContainerFilterGG_BorderColorBottomNotEncoded,
  ] = useState("rgba(255,255,255,1)");
  const [
    DB_LeftSidebar_MenuItem_MenuContainerFilterGG_BorderColorLeftNotEncoded,
    setDB_LeftSidebar_MenuItem_MenuContainerFilterGG_BorderColorLeftNotEncoded,
  ] = useState("rgba(255,255,255,1)");
  const [
    DB_LeftSidebar_MenuItem_MenuContainerFilterGG_BorderColorRightNotEncoded,
    setDB_LeftSidebar_MenuItem_MenuContainerFilterGG_BorderColorRightNotEncoded,
  ] = useState("rgba(255,255,255,1)");

  const [
    DB_LeftSidebar_MenuContainerFilterGG_Hover_BorderColorTop,
    setDB_LeftSidebar_MenuContainerFilterGG_Hover_BorderColorTop,
  ] = useState("rgba(0,0,0,0.3)");
  const [
    DB_LeftSidebar_MenuContainerFilterGG_Hover_BorderColorBottom,
    setDB_LeftSidebar_MenuContainerFilterGG_Hover_BorderColorBottom,
  ] = useState("rgba(0,0,0,0.3)");
  const [
    DB_LeftSidebar_MenuContainerFilterGG_Hover_BorderColorLeft,
    setDB_LeftSidebar_MenuContainerFilterGG_Hover_BorderColorLeft,
  ] = useState("rgba(0,0,0,0.3)");
  const [
    DB_LeftSidebar_MenuContainerFilterGG_Hover_BorderColorRight,
    setDB_LeftSidebar_MenuContainerFilterGG_Hover_BorderColorRight,
  ] = useState("rgba(0,0,0,0.3)");

  const [
    DB_LeftSidebar_MenuContainerFilterGG_Hover_BorderColorTopNotEncoded,
    setDB_LeftSidebar_MenuContainerFilterGG_Hover_BorderColorTopNotEncoded,
  ] = useState("rgba(0,0,0,0.3)");
  const [
    DB_LeftSidebar_MenuContainerFilterGG_Hover_BorderColorBottomNotEncoded,
    setDB_LeftSidebar_MenuContainerFilterGG_Hover_BorderColorBottomNotEncoded,
  ] = useState("rgba(0,0,0,0.3)");
  const [
    DB_LeftSidebar_MenuContainerFilterGG_Hover_BorderColorLeftNotEncoded,
    setDB_LeftSidebar_MenuContainerFilterGG_Hover_BorderColorLeftNotEncoded,
  ] = useState("rgba(0,0,0,0.3)");
  const [
    DB_LeftSidebar_MenuContainerFilterGG_Hover_BorderColorRightNotEncoded,
    setDB_LeftSidebar_MenuContainerFilterGG_Hover_BorderColorRightNotEncoded,
  ] = useState("rgba(0,0,0,0.3)");

  const [
    DB_LeftSidebar_MenuContainerFilterGG_Hover_BorderTop,
    setDB_LeftSidebar_MenuContainerFilterGG_Hover_BorderTop,
  ] = useState(1);
  const [
    DB_LeftSidebar_MenuContainerFilterGG_Hover_BorderBottom,
    setDB_LeftSidebar_MenuContainerFilterGG_Hover_BorderBottom,
  ] = useState(1);
  const [
    DB_LeftSidebar_MenuContainerFilterGG_Hover_BorderLeft,
    setDB_LeftSidebar_MenuContainerFilterGG_Hover_BorderLeft,
  ] = useState(1);
  const [
    DB_LeftSidebar_MenuContainerFilterGG_Hover_BorderRight,
    setDB_LeftSidebar_MenuContainerFilterGG_Hover_BorderRight,
  ] = useState(1);

  const [
    DB_LeftSidebar_MenuContainerFilterGG_Hover_BorderStyleTop,
    setDB_LeftSidebar_MenuContainerFilterGG_Hover_BorderStyleTop,
  ] = useState("solid");
  const [
    DB_LeftSidebar_MenuContainerFilterGG_Hover_BorderStyleBottom,
    setDB_LeftSidebar_MenuContainerFilterGG_Hover_BorderStyleBottom,
  ] = useState("solid");
  const [
    DB_LeftSidebar_MenuContainerFilterGG_Hover_BorderStyleLeft,
    setDB_LeftSidebar_MenuContainerFilterGG_Hover_BorderStyleLeft,
  ] = useState("solid");
  const [
    DB_LeftSidebar_MenuContainerFilterGG_Hover_BorderStyleRight,
    setDB_LeftSidebar_MenuContainerFilterGG_Hover_BorderStyleRight,
  ] = useState("solid");

  const [
    DB_LeftSidebar_MenuContainerFilterGG_BorderTop,
    setDB_LeftSidebar_MenuContainerFilterGG_BorderTop,
  ] = useState(1);
  const [
    DB_LeftSidebar_MenuContainerFilterGG_BorderBottom,
    setDB_LeftSidebar_MenuContainerFilterGG_BorderBottom,
  ] = useState(1);
  const [
    DB_LeftSidebar_MenuContainerFilterGG_BorderLeft,
    setDB_LeftSidebar_MenuContainerFilterGG_BorderLeft,
  ] = useState(1);
  const [
    DB_LeftSidebar_MenuContainerFilterGG_BorderRight,
    setDB_LeftSidebar_MenuContainerFilterGG_BorderRight,
  ] = useState(1);

  const [
    DB_LeftSidebar_MenuContainerFilterGG_BorderStyleTop,
    setDB_LeftSidebar_MenuContainerFilterGG_BorderStyleTop,
  ] = useState("solid");
  const [
    DB_LeftSidebar_MenuContainerFilterGG_BorderStyleBottom,
    setDB_LeftSidebar_MenuContainerFilterGG_BorderStyleBottom,
  ] = useState("solid");
  const [
    DB_LeftSidebar_MenuContainerFilterGG_BorderStyleLeft,
    setDB_LeftSidebar_MenuContainerFilterGG_BorderStyleLeft,
  ] = useState("solid");
  const [
    DB_LeftSidebar_MenuContainerFilterGG_BorderStyleRight,
    setDB_LeftSidebar_MenuContainerFilterGG_BorderStyleRight,
  ] = useState("solid");

  const [
    DB_LeftSidebar_MenuContainerFilterGG_BorderColorTop,
    setDB_LeftSidebar_MenuContainerFilterGG_BorderColorTop,
  ] = useState("rgba(0,0,0,0.3)");
  const [
    DB_LeftSidebar_MenuContainerFilterGG_BorderColorBottom,
    setDB_LeftSidebar_MenuContainerFilterGG_BorderColorBottom,
  ] = useState("rgba(0,0,0,0.3)");
  const [
    DB_LeftSidebar_MenuContainerFilterGG_BorderColorLeft,
    setDB_LeftSidebar_MenuContainerFilterGG_BorderColorLeft,
  ] = useState("rgba(0,0,0,0.3)");
  const [
    DB_LeftSidebar_MenuContainerFilterGG_BorderColorRight,
    setDB_LeftSidebar_MenuContainerFilterGG_BorderColorRight,
  ] = useState("rgba(0,0,0,0.3)");

  const [
    DB_LeftSidebar_MenuContainerFilterGG_BorderColorTopNotEncoded,
    setDB_LeftSidebar_MenuContainerFilterGG_BorderColorTopNotEncoded,
  ] = useState("rgba(0,0,0,0.3)");
  const [
    DB_LeftSidebar_MenuContainerFilterGG_BorderColorBottomNotEncoded,
    setDB_LeftSidebar_MenuContainerFilterGG_BorderColorBottomNotEncoded,
  ] = useState("rgba(0,0,0,0.3)");
  const [
    DB_LeftSidebar_MenuContainerFilterGG_BorderColorLeftNotEncoded,
    setDB_LeftSidebar_MenuContainerFilterGG_BorderColorLeftNotEncoded,
  ] = useState("rgba(0,0,0,0.3)");
  const [
    DB_LeftSidebar_MenuContainerFilterGG_BorderColorRightNotEncoded,
    setDB_LeftSidebar_MenuContainerFilterGG_BorderColorRightNotEncoded,
  ] = useState("rgba(0,0,0,0.3)");

  const [
    DB_LeftSidebar_MenuItem_FilterItem_marginTopBottom,
    setDB_LeftSidebar_MenuItem_FilterItem_marginTopBottom,
  ] = useState("0");
  const [
    DB_LeftSidebar_MenuItem_FilterItem_marginLeftRight,
    setDB_LeftSidebar_MenuItem_FilterItem_marginLeftRight,
  ] = useState("0");

  const [
    DB_LeftSidebar_MenuItem_FilterItem_paddingLeftRight,
    setDB_LeftSidebar_MenuItem_FilterItem_paddingLeftRight,
  ] = useState("30");

  const [
    DB_LeftSidebar_MenuItem_FilterItem_paddingTopBottom,
    setDB_LeftSidebar_MenuItem_FilterItem_paddingTopBottom,
  ] = useState("0");

  const [
    DB_LeftSidebar_MenuItem_FilterItem_letterSpacing,
    setDB_LeftSidebar_MenuItem_FilterItem_letterSpacing,
  ] = useState("0");

  const [
    DB_LeftSidebar_MenuItem_FilterItem_marginTopBottom_Hover,
    setDB_LeftSidebar_MenuItem_FilterItem_marginTopBottom_Hover,
  ] = useState("0");
  const [
    DB_LeftSidebar_MenuItem_FilterItem_marginLeftRight_Hover,
    setDB_LeftSidebar_MenuItem_FilterItem_marginLeftRight_Hover,
  ] = useState("0");

  const [
    DB_LeftSidebar_MenuItem_FilterItem_paddingLeftRight_Hover,
    setDB_LeftSidebar_MenuItem_FilterItem_paddingLeftRight_Hover,
  ] = useState("30");

  const [
    DB_LeftSidebar_MenuItem_FilterItem_paddingTopBottom_Hover,
    setDB_LeftSidebar_MenuItem_FilterItem_paddingTopBottom_Hover,
  ] = useState("0");

  const [
    DB_LeftSidebar_MenuItem_FilterItem_letterSpacing_Hover,
    setDB_LeftSidebar_MenuItem_FilterItem_letterSpacing_Hover,
  ] = useState("0");

  const [
    DB_LeftSidebar_MenuItem_FilterItem_ShadowHorizontal,
    setDB_LeftSidebar_MenuItem_FilterItem_ShadowHorizontal,
  ] = useState(0);
  const [
    DB_LeftSidebar_MenuItem_FilterItem_ShadowVertical,
    setDB_LeftSidebar_MenuItem_FilterItem_ShadowVertical,
  ] = useState(0);
  const [
    DB_LeftSidebar_MenuItem_FilterItem_ShadowBlur,
    setDB_LeftSidebar_MenuItem_FilterItem_ShadowBlur,
  ] = useState(5);
  const [
    DB_LeftSidebar_MenuItem_FilterItem_ShadowColor,
    setDB_LeftSidebar_MenuItem_FilterItem_ShadowColor,
  ] = useState("rgba(255,255,255,1)");

  const [
    DB_LeftSidebar_MenuItem_FilterItem_ShadowColorNotEncoded,
    setDB_LeftSidebar_MenuItem_FilterItem_ShadowColorNotEncoded,
  ] = useState("rgba(255,255,255,1)");

  const [
    DB_LeftSidebar_MenuItem_FilterItem_TextDecoration,
    setDB_LeftSidebar_MenuItem_FilterItem_TextDecoration,
  ] = useState("normal");

  const [
    DB_LeftSidebar_MenuItem_FilterItem_TextDecorationStyle,
    setDB_LeftSidebar_MenuItem_FilterItem_TextDecorationStyle,
  ] = useState("");

  const [
    DB_LeftSidebar_MenuItem_FilterItem_ShadowHorizontal_Hover,
    setDB_LeftSidebar_MenuItem_FilterItem_ShadowHorizontal_Hover,
  ] = useState(1);
  const [
    DB_LeftSidebar_MenuItem_FilterItem_ShadowVertical_Hover,
    setDB_LeftSidebar_MenuItem_FilterItem_ShadowVertical_Hover,
  ] = useState(1);
  const [
    DB_LeftSidebar_MenuItem_FilterItem_ShadowBlur_Hover,
    setDB_LeftSidebar_MenuItem_FilterItem_ShadowBlur_Hover,
  ] = useState(5);
  const [
    DB_LeftSidebar_MenuItem_FilterItem_ShadowColor_Hover,
    setDB_LeftSidebar_MenuItem_FilterItem_ShadowColor_Hover,
  ] = useState("rgba(255,255,255,1)");
  const [
    DB_LeftSidebar_MenuItem_FilterItem_TextDecoration_Hover,
    setDB_LeftSidebar_MenuItem_FilterItem_TextDecoration_Hover,
  ] = useState("none");
  const [
    DB_LeftSidebar_MenuItem_FilterItem_TextDecorationStyle_Hover,
    setDB_LeftSidebar_MenuItem_FilterItem_TextDecorationStyle_Hover,
  ] = useState("");

  const [
    DB_LeftSidebar_MenuItem_FilterItem_ShadowColorNotEncoded_Hover,
    setDB_LeftSidebar_MenuItem_FilterItem_ShadowColorNotEncoded_Hover,
  ] = useState("rgba(255,255,255,1)");

  const [
    DB_LeftSidebar_MenuItem_FilterItem_Hover_FontSize,
    setDB_LeftSidebar_MenuItem_FilterItem_Hover_FontSize,
  ] = useState(14);
  const DB_LeftSidebar_MenuItem_FilterItem_Hover_FontFace =
    DB_fontFace_FontFamilyName;
  const [
    DB_LeftSidebar_MenuItem_FilterItem_Hover_FontWeight,
    setDB_LeftSidebar_MenuItem_FilterItem_Hover_FontWeight,
  ] = useState(400);
  const [
    DB_LeftSidebar_MenuItem_FilterItem_Hover_FontColor,
    setDB_LeftSidebar_MenuItem_FilterItem_Hover_FontColor,
  ] = useState("rgba(255,255,255,1)");

  const [
    DB_LeftSidebar_MenuItem_FilterItem_Hover_FontColorNotEncoded,
    setDB_LeftSidebar_MenuItem_FilterItem_Hover_FontColorNotEncoded,
  ] = useState("rgba(0,0,0,0)");

  const [
    DB_LeftSidebar_MenuItem_FilterItem_Hover_BackgroundColor,
    setDB_LeftSidebar_MenuItem_FilterItem_Hover_BackgroundColor,
  ] = useState("rgba(0,0,0,0)");
  const [
    DB_LeftSidebar_MenuItem_FilterItem_Hover_BackgroundColorNotEncoded,
    setDB_LeftSidebar_MenuItem_FilterItem_Hover_BackgroundColorNotEncoded,
  ] = useState("rgba(0,0,0,0)");

  const [
    DB_LeftSidebar_MenuItem_FilterItem_Hover_TextAlign,
    setDB_LeftSidebar_MenuItem_FilterItem_Hover_TextAlign,
  ] = useState("left");

  /////////////////////////////////
  const [
    DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderTopRightRadius,
    setDB_LeftSidebar_MenuItem_FilterItem_Hover_BorderTopRightRadius,
  ] = useState(0);
  const [
    DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderTopLeftRadius,
    setDB_LeftSidebar_MenuItem_FilterItem_Hover_BorderTopLeftRadius,
  ] = useState(0);
  const [
    DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderBottomRightRadius,
    setDB_LeftSidebar_MenuItem_FilterItem_Hover_BorderBottomRightRadius,
  ] = useState(0);
  const [
    DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderBottomLeftRadius,
    setDB_LeftSidebar_MenuItem_FilterItem_Hover_BorderBottomLeftRadius,
  ] = useState(0);

  const [
    DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderColorTop,
    setDB_LeftSidebar_MenuItem_FilterItem_Hover_BorderColorTop,
  ] = useState("rgba(255,255,255,1)");
  const [
    DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderColorBottom,
    setDB_LeftSidebar_MenuItem_FilterItem_Hover_BorderColorBottom,
  ] = useState("rgba(255,255,255,1)");
  const [
    DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderColorLeft,
    setDB_LeftSidebar_MenuItem_FilterItem_Hover_BorderColorLeft,
  ] = useState("rgba(255,255,255,1)");
  const [
    DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderColorRight,
    setDB_LeftSidebar_MenuItem_FilterItem_Hover_BorderColorRight,
  ] = useState("rgba(255,255,255,1)");

  const [
    DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderColorTopNotEncoded,
    setDB_LeftSidebar_MenuItem_FilterItem_Hover_BorderColorTopNotEncoded,
  ] = useState("rgba(255,255,255,1)");
  const [
    DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderColorBottomNotEncoded,
    setDB_LeftSidebar_MenuItem_FilterItem_Hover_BorderColorBottomNotEncoded,
  ] = useState("rgba(255,255,255,1)");
  const [
    DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderColorLeftNotEncoded,
    setDB_LeftSidebar_MenuItem_FilterItem_Hover_BorderColorLeftNotEncoded,
  ] = useState("rgba(255,255,255,1)");
  const [
    DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderColorRightNotEncoded,
    setDB_LeftSidebar_MenuItem_FilterItem_Hover_BorderColorRightNotEncoded,
  ] = useState("rgba(255,255,255,1)");

  const [
    DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderTop,
    setDB_LeftSidebar_MenuItem_FilterItem_Hover_BorderTop,
  ] = useState(0);
  const [
    DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderBottom,
    setDB_LeftSidebar_MenuItem_FilterItem_Hover_BorderBottom,
  ] = useState(0);
  const [
    DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderLeft,
    setDB_LeftSidebar_MenuItem_FilterItem_Hover_BorderLeft,
  ] = useState(0);
  const [
    DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderRight,
    setDB_LeftSidebar_MenuItem_FilterItem_Hover_BorderRight,
  ] = useState(0);

  const [
    DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderStyleTop,
    setDB_LeftSidebar_MenuItem_FilterItem_Hover_BorderStyleTop,
  ] = useState("solid");
  const [
    DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderStyleBottom,
    setDB_LeftSidebar_MenuItem_FilterItem_Hover_BorderStyleBottom,
  ] = useState("solid");
  const [
    DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderStyleLeft,
    setDB_LeftSidebar_MenuItem_FilterItem_Hover_BorderStyleLeft,
  ] = useState("solid");
  const [
    DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderStyleRight,
    setDB_LeftSidebar_MenuItem_FilterItem_Hover_BorderStyleRight,
  ] = useState("solid");

  const DB_LeftSidebar_MenuItem_FilterItem_Hover_HoverBackgroundColor =
    "rgba(255,255,255,0.3)";
  const DB_LeftSidebar_MenuItem_FilterItem_Hover_HoverFontColor =
    "rgba(255,255,255,1)";

  // DB_LeftSidebar - FilterItem Normal .-----------------.---------------------.----------------------
  const [
    DB_LeftSidebar_MenuItem_FilterItem_FontSize,
    setDB_LeftSidebar_MenuItem_FilterItem_FontSize,
  ] = useState(14);
  const DB_LeftSidebar_MenuItem_FilterItem_FontFace =
    DB_fontFace_FontFamilyName;
  const [
    DB_LeftSidebar_MenuItem_FilterItem_FontWeight,
    setDB_LeftSidebar_MenuItem_FilterItem_FontWeight,
  ] = useState(400);
  const [
    DB_LeftSidebar_MenuItem_FilterItem_FontColor,
    setDB_LeftSidebar_MenuItem_FilterItem_FontColor,
  ] = useState("rgba(255,255,255,1)");

  const [
    DB_LeftSidebar_MenuItem_FilterItem_FontColorNotEncoded,
    setDB_LeftSidebar_MenuItem_FilterItem_FontColorNotEncoded,
  ] = useState("rgba(0,0,0,0)");

  const [
    DB_LeftSidebar_MenuItem_FilterItem_BackgroundColor,
    setDB_LeftSidebar_MenuItem_FilterItem_BackgroundColor,
  ] = useState("rgba(0,0,0,0)");
  const [
    DB_LeftSidebar_MenuItem_FilterItem_BackgroundColorNotEncoded,
    setDB_LeftSidebar_MenuItem_FilterItem_BackgroundColorNotEncoded,
  ] = useState("rgba(0,0,0,0)");

  const [
    DB_LeftSidebar_MenuItem_FilterItem_TextAlign,
    setDB_LeftSidebar_MenuItem_FilterItem_TextAlign,
  ] = useState("left");

  /////////////////////////////////
  const [
    DB_LeftSidebar_MenuItem_FilterItem_BorderTopRightRadius,
    setDB_LeftSidebar_MenuItem_FilterItem_BorderTopRightRadius,
  ] = useState(0);
  const [
    DB_LeftSidebar_MenuItem_FilterItem_BorderTopLeftRadius,
    setDB_LeftSidebar_MenuItem_FilterItem_BorderTopLeftRadius,
  ] = useState(0);
  const [
    DB_LeftSidebar_MenuItem_FilterItem_BorderBottomRightRadius,
    setDB_LeftSidebar_MenuItem_FilterItem_BorderBottomRightRadius,
  ] = useState(0);
  const [
    DB_LeftSidebar_MenuItem_FilterItem_BorderBottomLeftRadius,
    setDB_LeftSidebar_MenuItem_FilterItem_BorderBottomLeftRadius,
  ] = useState(0);

  const [
    DB_LeftSidebar_MenuItem_FilterItem_BorderColorTop,
    setDB_LeftSidebar_MenuItem_FilterItem_BorderColorTop,
  ] = useState("rgba(255,255,255,1)");
  const [
    DB_LeftSidebar_MenuItem_FilterItem_BorderColorBottom,
    setDB_LeftSidebar_MenuItem_FilterItem_BorderColorBottom,
  ] = useState("rgba(255,255,255,1)");
  const [
    DB_LeftSidebar_MenuItem_FilterItem_BorderColorLeft,
    setDB_LeftSidebar_MenuItem_FilterItem_BorderColorLeft,
  ] = useState("rgba(255,255,255,1)");
  const [
    DB_LeftSidebar_MenuItem_FilterItem_BorderColorRight,
    setDB_LeftSidebar_MenuItem_FilterItem_BorderColorRight,
  ] = useState("rgba(255,255,255,1)");

  const [
    DB_LeftSidebar_MenuItem_FilterItem_BorderColorTopNotEncoded,
    setDB_LeftSidebar_MenuItem_FilterItem_BorderColorTopNotEncoded,
  ] = useState("rgba(255,255,255,1)");
  const [
    DB_LeftSidebar_MenuItem_FilterItem_BorderColorBottomNotEncoded,
    setDB_LeftSidebar_MenuItem_FilterItem_BorderColorBottomNotEncoded,
  ] = useState("rgba(255,255,255,1)");
  const [
    DB_LeftSidebar_MenuItem_FilterItem_BorderColorLeftNotEncoded,
    setDB_LeftSidebar_MenuItem_FilterItem_BorderColorLeftNotEncoded,
  ] = useState("rgba(255,255,255,1)");
  const [
    DB_LeftSidebar_MenuItem_FilterItem_BorderColorRightNotEncoded,
    setDB_LeftSidebar_MenuItem_FilterItem_BorderColorRightNotEncoded,
  ] = useState("rgba(255,255,255,1)");

  const [
    DB_LeftSidebar_MenuItem_FilterItem_BorderTop,
    setDB_LeftSidebar_MenuItem_FilterItem_BorderTop,
  ] = useState(0);
  const [
    DB_LeftSidebar_MenuItem_FilterItem_BorderBottom,
    setDB_LeftSidebar_MenuItem_FilterItem_BorderBottom,
  ] = useState(0);
  const [
    DB_LeftSidebar_MenuItem_FilterItem_BorderLeft,
    setDB_LeftSidebar_MenuItem_FilterItem_BorderLeft,
  ] = useState(0);
  const [
    DB_LeftSidebar_MenuItem_FilterItem_BorderRight,
    setDB_LeftSidebar_MenuItem_FilterItem_BorderRight,
  ] = useState(0);

  const [
    DB_LeftSidebar_MenuItem_FilterItem_BorderStyleTop,
    setDB_LeftSidebar_MenuItem_FilterItem_BorderStyleTop,
  ] = useState("solid");
  const [
    DB_LeftSidebar_MenuItem_FilterItem_BorderStyleBottom,
    setDB_LeftSidebar_MenuItem_FilterItem_BorderStyleBottom,
  ] = useState("solid");
  const [
    DB_LeftSidebar_MenuItem_FilterItem_BorderStyleLeft,
    setDB_LeftSidebar_MenuItem_FilterItem_BorderStyleLeft,
  ] = useState("solid");
  const [
    DB_LeftSidebar_MenuItem_FilterItem_BorderStyleRight,
    setDB_LeftSidebar_MenuItem_FilterItem_BorderStyleRight,
  ] = useState("solid");

  const DB_LeftSidebar_MenuItem_FilterItem_HoverBackgroundColor =
    "rgba(255,255,255,0.3)";
  const DB_LeftSidebar_MenuItem_FilterItem_HoverFontColor =
    "rgba(255,255,255,1)";

  ////////////////// Filter Top Bar Anzahl Produkte Neueste Älteste 1,2,3,4 ////////////////////
  const [
    DB_fontFace_FilterTopBar_FontFamilyName,
    setDB_fontFace_FilterTopBar_FontFamilyName,
  ] = useState("Lato");
  const [
    DB_fontFace_FilterTopBar_FontFamilySrc,
    setDB_fontFace_FilterTopBar_FontFamilySrc,
  ] = useState("/assets/Lato/Lato-Regular.ttf");

  const DB_NeuesteAlphabetischSelect_BackgroundColor = "rgba(0,0,0,0.3)";
  const DB_NeuesteAlphabetischSelect_FontFamily = "Lato";
  const DB_NeuesteAlphabetischSelect_FontColor = "rgba(255,255,255,1)";
  const DB_NeuesteUswAnzahlProdukte_FontColor = "white";

  const [
    DB_LeftSidebar_MenuItem_SortierungTop_FontSize,
    setDB_LeftSidebar_MenuItem_SortierungTop_FontSize,
  ] = useState(18);
  const DB_LeftSidebar_MenuItem_SortierungTop_FontFace =
    DB_fontFace_FontFamilyName;
  const [
    DB_LeftSidebar_MenuItem_SortierungTop_FontWeight,
    setDB_LeftSidebar_MenuItem_SortierungTop_FontWeight,
  ] = useState(400);
  const [
    DB_LeftSidebar_MenuItem_SortierungTop_FontColor,
    setDB_LeftSidebar_MenuItem_SortierungTop_FontColor,
  ] = useState("rgba(255,255,255,1)");

  const [
    DB_LeftSidebar_MenuItem_SortierungTop_FontColorNotEncoded,
    setDB_LeftSidebar_MenuItem_SortierungTop_FontColorNotEncoded,
  ] = useState("rgba(0,0,0,0.3)");

  const [
    DB_LeftSidebar_MenuItem_SortierungTop_FontColor_Hover,
    setDB_LeftSidebar_MenuItem_SortierungTop_FontColor_Hover,
  ] = useState("rgba(255,255,255,1)");

  const [
    DB_LeftSidebar_MenuItem_SortierungTop_FontColorNotEncoded_Hover,
    setDB_LeftSidebar_MenuItem_SortierungTop_FontColorNotEncoded_Hover,
  ] = useState("rgba(0,0,0,0.3)");

  const [
    DB_LeftSidebar_MenuItem_SortierungTop_TextAlign,
    setDB_LeftSidebar_MenuItem_SortierungTop_TextAlign,
  ] = useState("center");

  const DB_LeftSidebar_MenuItem_SortierungTop_HoverBackgroundColor =
    "rgba(255,255,255,0.3)";
  const DB_LeftSidebar_MenuItem_SortierungTop_HoverFontColor =
    "rgba(255,255,255,1)";

  const [
    DB_LeftSidebar_MenuItem_SortierungTop_Hover_FontSize,
    setDB_LeftSidebar_MenuItem_SortierungTop_Hover_FontSize,
  ] = useState(18);
  const DB_LeftSidebar_MenuItem_SortierungTop_Hover_FontFace =
    DB_fontFace_FontFamilyName;
  const [
    DB_LeftSidebar_MenuItem_SortierungTop_Hover_FontWeight,
    setDB_LeftSidebar_MenuItem_SortierungTop_Hover_FontWeight,
  ] = useState(400);
  const [
    DB_LeftSidebar_MenuItem_SortierungTop_Hover_FontColor,
    setDB_LeftSidebar_MenuItem_SortierungTop_Hover_FontColor,
  ] = useState("rgba(255,255,255,1)");

  const [
    DB_LeftSidebar_MenuItem_SortierungTop_Hover_FontColorNotEncoded,
    setDB_LeftSidebar_MenuItem_SortierungTop_Hover_FontColorNotEncoded,
  ] = useState("rgba(0,0,0,0.3)");

  const [
    DB_LeftSidebar_MenuItem_SortierungTop_Hover_BackgroundColor,
    setDB_LeftSidebar_MenuItem_SortierungTop_Hover_BackgroundColor,
  ] = useState("rgba(0,0,0,0.3)");
  const [
    DB_LeftSidebar_MenuItem_SortierungTop_Hover_BackgroundColorNotEncoded,
    setDB_LeftSidebar_MenuItem_SortierungTop_Hover_BackgroundColorNotEncoded,
  ] = useState("rgba(0,0,0,0.3)");

  const [
    DB_LeftSidebar_MenuItem_SortierungTop_Hover_TextAlign,
    setDB_LeftSidebar_MenuItem_SortierungTop_Hover_TextAlign,
  ] = useState("center");

  /////////////////////////////////

  const [
    DB_LeftSidebar_MenuItem_SortierungTop_Hover_BorderColorTop,
    setDB_LeftSidebar_MenuItem_SortierungTop_Hover_BorderColorTop,
  ] = useState("rgba(255,255,255,1)");
  const [
    DB_LeftSidebar_MenuItem_SortierungTop_Hover_BorderColorBottom,
    setDB_LeftSidebar_MenuItem_SortierungTop_Hover_BorderColorBottom,
  ] = useState("rgba(255,255,255,1)");
  const [
    DB_LeftSidebar_MenuItem_SortierungTop_Hover_BorderColorLeft,
    setDB_LeftSidebar_MenuItem_SortierungTop_Hover_BorderColorLeft,
  ] = useState("rgba(255,255,255,1)");
  const [
    DB_LeftSidebar_MenuItem_SortierungTop_Hover_BorderColorRight,
    setDB_LeftSidebar_MenuItem_SortierungTop_Hover_BorderColorRight,
  ] = useState("rgba(255,255,255,1)");

  const [
    DB_LeftSidebar_MenuItem_SortierungTop_Hover_BorderColorTopNotEncoded,
    setDB_LeftSidebar_MenuItem_SortierungTop_Hover_BorderColorTopNotEncoded,
  ] = useState("rgba(255,255,255,1)");
  const [
    DB_LeftSidebar_MenuItem_SortierungTop_Hover_BorderColorBottomNotEncoded,
    setDB_LeftSidebar_MenuItem_SortierungTop_Hover_BorderColorBottomNotEncoded,
  ] = useState("rgba(255,255,255,1)");
  const [
    DB_LeftSidebar_MenuItem_SortierungTop_Hover_BorderColorLeftNotEncoded,
    setDB_LeftSidebar_MenuItem_SortierungTop_Hover_BorderColorLeftNotEncoded,
  ] = useState("rgba(255,255,255,1)");
  const [
    DB_LeftSidebar_MenuItem_SortierungTop_Hover_BorderColorRightNotEncoded,
    setDB_LeftSidebar_MenuItem_SortierungTop_Hover_BorderColorRightNotEncoded,
  ] = useState("rgba(255,255,255,1)");

  const [
    DB_LeftSidebar_MenuItem_SortierungTop_Hover_BorderTop,
    setDB_LeftSidebar_MenuItem_SortierungTop_Hover_BorderTop,
  ] = useState(1);
  const [
    DB_LeftSidebar_MenuItem_SortierungTop_Hover_BorderBottom,
    setDB_LeftSidebar_MenuItem_SortierungTop_Hover_BorderBottom,
  ] = useState(1);
  const [
    DB_LeftSidebar_MenuItem_SortierungTop_Hover_BorderLeft,
    setDB_LeftSidebar_MenuItem_SortierungTop_Hover_BorderLeft,
  ] = useState(0);
  const [
    DB_LeftSidebar_MenuItem_SortierungTop_Hover_BorderRight,
    setDB_LeftSidebar_MenuItem_SortierungTop_Hover_BorderRight,
  ] = useState(0);

  const [
    DB_LeftSidebar_MenuItem_SortierungTop_Hover_BorderStyleTop,
    setDB_LeftSidebar_MenuItem_SortierungTop_Hover_BorderStyleTop,
  ] = useState("solid");
  const [
    DB_LeftSidebar_MenuItem_SortierungTop_Hover_BorderStyleBottom,
    setDB_LeftSidebar_MenuItem_SortierungTop_Hover_BorderStyleBottom,
  ] = useState("solid");
  const [
    DB_LeftSidebar_MenuItem_SortierungTop_Hover_BorderStyleLeft,
    setDB_LeftSidebar_MenuItem_SortierungTop_Hover_BorderStyleLeft,
  ] = useState("solid");
  const [
    DB_LeftSidebar_MenuItem_SortierungTop_Hover_BorderStyleRight,
    setDB_LeftSidebar_MenuItem_SortierungTop_Hover_BorderStyleRight,
  ] = useState("solid");

  const DB_LeftSidebar_MenuItem_SortierungTop_Hover_HoverBackgroundColor =
    "rgba(255,255,255,0.3)";
  const DB_LeftSidebar_MenuItem_SortierungTop_Hover_HoverFontColor =
    "rgba(255,255,255,1)";

  const [
    DB_LeftSidebar_MenuItem_SortierungTop_BackgroundColor,
    setDB_LeftSidebar_MenuItem_SortierungTop_BackgroundColor,
  ] = useState("rgba(0,0,0,0.3)");

  const [
    DB_LeftSidebar_MenuItem_SortierungTop_BackgroundColorNotEncoded,
    setDB_LeftSidebar_MenuItem_SortierungTop_BackgroundColorNotEncoded,
  ] = useState("rgba(0,0,0,0.3)");

  const [
    DB_LeftSidebar_MenuItem_SortierungTop_BorderTopRightRadius,
    setDB_LeftSidebar_MenuItem_SortierungTop_BorderTopRightRadius,
  ] = useState(20);
  const [
    DB_LeftSidebar_MenuItem_SortierungTop_BorderTopLeftRadius,
    setDB_LeftSidebar_MenuItem_SortierungTop_BorderTopLeftRadius,
  ] = useState(20);
  const [
    DB_LeftSidebar_MenuItem_SortierungTop_BorderBottomRightRadius,
    setDB_LeftSidebar_MenuItem_SortierungTop_BorderBottomRightRadius,
  ] = useState(20);
  const [
    DB_LeftSidebar_MenuItem_SortierungTop_BorderBottomLeftRadius,
    setDB_LeftSidebar_MenuItem_SortierungTop_BorderBottomLeftRadius,
  ] = useState(20);

  const [
    DB_LeftSidebar_MenuItem_SortierungTop_Hover_BorderTopRightRadius,
    setDB_LeftSidebar_MenuItem_SortierungTop_Hover_BorderTopRightRadius,
  ] = useState(20);
  const [
    DB_LeftSidebar_MenuItem_SortierungTop_Hover_BorderTopLeftRadius,
    setDB_LeftSidebar_MenuItem_SortierungTop_Hover_BorderTopLeftRadius,
  ] = useState(20);
  const [
    DB_LeftSidebar_MenuItem_SortierungTop_Hover_BorderBottomRightRadius,
    setDB_LeftSidebar_MenuItem_SortierungTop_Hover_BorderBottomRightRadius,
  ] = useState(20);
  const [
    DB_LeftSidebar_MenuItem_SortierungTop_Hover_BorderBottomLeftRadius,
    setDB_LeftSidebar_MenuItem_SortierungTop_Hover_BorderBottomLeftRadius,
  ] = useState(20);

  const [
    DB_LeftSidebar_MenuItem_SortierungTop_BorderTop,
    setDB_LeftSidebar_MenuItem_SortierungTop_BorderTop,
  ] = useState(1);
  const [
    DB_LeftSidebar_MenuItem_SortierungTop_BorderBottom,
    setDB_LeftSidebar_MenuItem_SortierungTop_BorderBottom,
  ] = useState(1);
  const [
    DB_LeftSidebar_MenuItem_SortierungTop_BorderLeft,
    setDB_LeftSidebar_MenuItem_SortierungTop_BorderLeft,
  ] = useState(1);
  const [
    DB_LeftSidebar_MenuItem_SortierungTop_BorderRight,
    setDB_LeftSidebar_MenuItem_SortierungTop_BorderRight,
  ] = useState(1);

  const [
    DB_LeftSidebar_MenuItem_SortierungTop_BorderStyleTop,
    setDB_LeftSidebar_MenuItem_SortierungTop_BorderStyleTop,
  ] = useState("solid");
  const [
    DB_LeftSidebar_MenuItem_SortierungTop_BorderStyleBottom,
    setDB_LeftSidebar_MenuItem_SortierungTop_BorderStyleBottom,
  ] = useState("solid");
  const [
    DB_LeftSidebar_MenuItem_SortierungTop_BorderStyleLeft,
    setDB_LeftSidebar_MenuItem_SortierungTop_BorderStyleLeft,
  ] = useState("solid");
  const [
    DB_LeftSidebar_MenuItem_SortierungTop_BorderStyleRight,
    setDB_LeftSidebar_MenuItem_SortierungTop_BorderStyleRight,
  ] = useState("solid");

  const [
    DB_LeftSidebar_MenuItem_SortierungTop_BorderColorTop,
    setDB_LeftSidebar_MenuItem_SortierungTop_BorderColorTop,
  ] = useState("rgba(0,0,0,0.3)");
  const [
    DB_LeftSidebar_MenuItem_SortierungTop_BorderColorBottom,
    setDB_LeftSidebar_MenuItem_SortierungTop_BorderColorBottom,
  ] = useState("rgba(0,0,0,0.3)");
  const [
    DB_LeftSidebar_MenuItem_SortierungTop_BorderColorLeft,
    setDB_LeftSidebar_MenuItem_SortierungTop_BorderColorLeft,
  ] = useState("rgba(0,0,0,0.3)");
  const [
    DB_LeftSidebar_MenuItem_SortierungTop_BorderColorRight,
    setDB_LeftSidebar_MenuItem_SortierungTop_BorderColorRight,
  ] = useState("rgba(0,0,0,0.3)");

  const [
    DB_LeftSidebar_MenuItem_SortierungTop_BorderColorTopNotEncoded,
    setDB_LeftSidebar_MenuItem_SortierungTop_BorderColorTopNotEncoded,
  ] = useState("rgba(0,0,0,0.3)");
  const [
    DB_LeftSidebar_MenuItem_SortierungTop_BorderColorBottomNotEncoded,
    setDB_LeftSidebar_MenuItem_SortierungTop_BorderColorBottomNotEncoded,
  ] = useState("rgba(0,0,0,0.3)");
  const [
    DB_LeftSidebar_MenuItem_SortierungTop_BorderColorLeftNotEncoded,
    setDB_LeftSidebar_MenuItem_SortierungTop_BorderColorLeftNotEncoded,
  ] = useState("rgba(0,0,0,0.3)");
  const [
    DB_LeftSidebar_MenuItem_SortierungTop_BorderColorRightNotEncoded,
    setDB_LeftSidebar_MenuItem_SortierungTop_BorderColorRightNotEncoded,
  ] = useState("rgba(0,0,0,0.3)");

  const [
    DB_LeftSidebar_MenuItem_SortierungTop_Hover_ShadowHorizontal,
    setDB_LeftSidebar_MenuItem_SortierungTop_Hover_ShadowHorizontal,
  ] = useState(1);
  const [
    DB_LeftSidebar_MenuItem_SortierungTop_Hover_ShadowVertical,
    setDB_LeftSidebar_MenuItem_SortierungTop_Hover_ShadowVertical,
  ] = useState(1);
  const [
    DB_LeftSidebar_MenuItem_SortierungTop_Hover_ShadowBlur,
    setDB_LeftSidebar_MenuItem_SortierungTop_Hover_ShadowBlur,
  ] = useState(5);
  const [
    DB_LeftSidebar_MenuItem_SortierungTop_Hover_ShadowColor,
    setDB_LeftSidebar_MenuItem_SortierungTop_Hover_ShadowColor,
  ] = useState("rgba(255,255,255,1)");

  const [
    DB_LeftSidebar_MenuItem_SortierungTop_Hover_ShadowColorNotEncoded,
    setDB_LeftSidebar_MenuItem_SortierungTop_Hover_ShadowColorNotEncoded,
  ] = useState("rgba(255,255,255,1)");

  const [
    DB_LeftSidebar_MenuItem_SortierungTop_marginTopBottom,
    setDB_LeftSidebar_MenuItem_SortierungTop_marginTopBottom,
  ] = useState("5");
  const [
    DB_LeftSidebar_MenuItem_SortierungTop_marginLeftRight,
    setDB_LeftSidebar_MenuItem_SortierungTop_marginLeftRight,
  ] = useState("5");

  const [
    DB_LeftSidebar_MenuItem_SortierungTop_letterSpacing,
    setDB_LeftSidebar_MenuItem_SortierungTop_letterSpacing,
  ] = useState("0");

  const [
    DB_LeftSidebar_MenuItem_SortierungTop_marginTopBottom_Hover,
    setDB_LeftSidebar_MenuItem_SortierungTop_marginTopBottom_Hover,
  ] = useState("5");
  const [
    DB_LeftSidebar_MenuItem_SortierungTop_marginLeftRight_Hover,
    setDB_LeftSidebar_MenuItem_SortierungTop_marginLeftRight_Hover,
  ] = useState("5");

  const [
    DB_LeftSidebar_MenuItem_SortierungTop_letterSpacing_Hover,
    setDB_LeftSidebar_MenuItem_SortierungTop_letterSpacing_Hover,
  ] = useState("0");

  const [
    DB_LeftSidebar_MenuItem_SortierungTop_ShadowHorizontal,
    setDB_LeftSidebar_MenuItem_SortierungTop_ShadowHorizontal,
  ] = useState(1);
  const [
    DB_LeftSidebar_MenuItem_SortierungTop_ShadowVertical,
    setDB_LeftSidebar_MenuItem_SortierungTop_ShadowVertical,
  ] = useState(1);
  const [
    DB_LeftSidebar_MenuItem_SortierungTop_ShadowBlur,
    setDB_LeftSidebar_MenuItem_SortierungTop_ShadowBlur,
  ] = useState(5);
  const [
    DB_LeftSidebar_MenuItem_SortierungTop_ShadowColor,
    setDB_LeftSidebar_MenuItem_SortierungTop_ShadowColor,
  ] = useState("rgba(255,255,255,1)");

  const [
    DB_LeftSidebar_MenuItem_SortierungTop_ShadowColorNotEncoded,
    setDB_LeftSidebar_MenuItem_SortierungTop_ShadowColorNotEncoded,
  ] = useState("rgba(255,255,255,1)");

  const [
    DB_LeftSidebar_MenuItem_SortierungTop_TextDecoration,
    setDB_LeftSidebar_MenuItem_SortierungTop_TextDecoration,
  ] = useState("normal");

  const [
    DB_LeftSidebar_MenuItem_SortierungTop_TextDecorationStyle,
    setDB_LeftSidebar_MenuItem_SortierungTop_TextDecorationStyle,
  ] = useState("");

  const [
    DB_LeftSidebar_MenuItem_SortierungTop_ShadowHorizontal_Hover,
    setDB_LeftSidebar_MenuItem_SortierungTop_ShadowHorizontal_Hover,
  ] = useState(1);
  const [
    DB_LeftSidebar_MenuItem_SortierungTop_ShadowVertical_Hover,
    setDB_LeftSidebar_MenuItem_SortierungTop_ShadowVertical_Hover,
  ] = useState(1);
  const [
    DB_LeftSidebar_MenuItem_SortierungTop_ShadowBlur_Hover,
    setDB_LeftSidebar_MenuItem_SortierungTop_ShadowBlur_Hover,
  ] = useState(5);
  const [
    DB_LeftSidebar_MenuItem_SortierungTop_ShadowColor_Hover,
    setDB_LeftSidebar_MenuItem_SortierungTop_ShadowColor_Hover,
  ] = useState("rgba(255,255,255,1)");
  const [
    DB_LeftSidebar_MenuItem_SortierungTop_TextDecoration_Hover,
    setDB_LeftSidebar_MenuItem_SortierungTop_TextDecoration_Hover,
  ] = useState("none");
  const [
    DB_LeftSidebar_MenuItem_SortierungTop_TextDecorationStyle_Hover,
    setDB_LeftSidebar_MenuItem_SortierungTop_TextDecorationStyle_Hover,
  ] = useState("");

  const [
    DB_LeftSidebar_MenuItem_SortierungTop_ShadowColorNotEncoded_Hover,
    setDB_LeftSidebar_MenuItem_SortierungTop_ShadowColorNotEncoded_Hover,
  ] = useState("rgba(255,255,255,1)");

  const [
    DB_LeftSidebar_MenuItem_MenuContainer_Logo_DDpaddingLeft,
    setDB_LeftSidebar_MenuItem_MenuContainer_Logo_DDpaddingLeft,
  ] = useState("0");

  const [
    DB_LeftSidebar_MenuItem_MenuContainer_Logo_DDpaddingTop,
    setDB_LeftSidebar_MenuItem_MenuContainer_Logo_DDpaddingTop,
  ] = useState("0");

  const [
    DB_LeftSidebar_MenuItem_MenuContainer_Logo_DDpaddingRight,
    setDB_LeftSidebar_MenuItem_MenuContainer_Logo_DDpaddingRight,
  ] = useState("0");

  const [
    DB_LeftSidebar_MenuItem_MenuContainer_Logo_DDpaddingBottom,
    setDB_LeftSidebar_MenuItem_MenuContainer_Logo_DDpaddingBottom,
  ] = useState("0");

  const [
    DB_LeftSidebar_MenuItem_MenuContainer_Logo_DDHeight,
    setDB_LeftSidebar_MenuItem_MenuContainer_Logo_DDHeight,
  ] = useState(50);

  const [
    DB_LeftSidebar_MenuContainer_Logo_DDBackgroundColor,
    setDB_LeftSidebar_MenuContainer_Logo_DDBackgroundColor,
  ] = useState("rgba(0,0,0,0.3)");

  const [
    DB_LeftSidebar_MenuContainer_Logo_DDBackgroundColorNotEncoded,
    setDB_LeftSidebar_MenuContainer_Logo_DDBackgroundColorNotEncoded,
  ] = useState("rgba(0,0,0,0.3)");

  const [
    DB_LeftSidebar_MenuItem_MenuContainer_Logo_DDShadowHorizontal,
    setDB_LeftSidebar_MenuItem_MenuContainer_Logo_DDShadowHorizontal,
  ] = useState(0);
  const [
    DB_LeftSidebar_MenuItem_MenuContainer_Logo_DDShadowVertical,
    setDB_LeftSidebar_MenuItem_MenuContainer_Logo_DDShadowVertical,
  ] = useState(0);
  const [
    DB_LeftSidebar_MenuItem_MenuContainer_Logo_DDShadowBlur,
    setDB_LeftSidebar_MenuItem_MenuContainer_Logo_DDShadowBlur,
  ] = useState(5);
  const [
    DB_LeftSidebar_MenuItem_MenuContainer_Logo_DDShadowColor,
    setDB_LeftSidebar_MenuItem_MenuContainer_Logo_DDShadowColor,
  ] = useState("rgba(255,255,255,1)");

  const [
    DB_LeftSidebar_MenuItem_MenuContainer_Logo_DDShadowColorNotEncoded,
    setDB_LeftSidebar_MenuItem_MenuContainer_Logo_DDShadowColorNotEncoded,
  ] = useState("rgba(255,255,255,1)");

  const [
    SideBarLeft_Container_HintergrundFarbe_Radius_Stil_Logo_Trigger,
    setSideBarLeft_Container_HintergrundFarbe_Radius_Stil_Logo_Trigger,
  ] = useState(0);

  const setSideBarLeft_Container_HintergrundFarbe_Radius_Stil_Logo_Handler =
    () => {
      setSideBarLeft_Container_HintergrundFarbe_Radius_Stil_Logo_Trigger(
        (prev) => prev + 1
      );
    };

  const [shopBoxes, setShopBoxes] = useState({});

  useEffect(() => {
    axios({
      method: "post",
      url: `${process.env.REACT_APP_PUBLIC_SERVER_URL}/shop/get-shop-boxes`,
      data: { shopName },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        if (response.data.data !== null) {
          setShopBoxes(response.data.data);
        } else {
          setShopBoxes({});
        }
      })
      .catch((err) => {
        toast.error(`${t("KeineVerbindungZumServer")}`, {
          toastId: "custom-id-yes",
          position: "bottom-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          transition: Zoom,
        });
      });
  }, []);

  useEffect(() => {
    if (i18n.language == language) {
      i18n.changeLanguage(language);
    } else {
      i18n.changeLanguage("en");
    }
  }, []);

  /*   const clientSideLanguageChange = (newLocale) => {
    if (i18n.language == language) {
      i18n.changeLanguage(language);
    } else {
      i18n.changeLanguage("en");
    }
  }; */

  const [DB_TopBarLogoImageSrc, setDB_TopBarLogoImageSrc] = useState("");
  useEffect(() => {
    axios({
      method: "post",
      url: `${process.env.REACT_APP_PUBLIC_SERVER_URL}/shop/get-design-logo-image`,
      data: {
        shopName,
      },
    })
      .then((uploadedResult) => {
        if (uploadedResult.status === 201) {
        } else {
          setDB_TopBarLogoImageSrc(
            `${process.env.REACT_APP_PUBLIC_SERVER_URL}/` +
              uploadedResult.data.logo_image +
              "?" +
              new Date().getMilliseconds()
          );
        }
      })
      .catch((err) => {
        toast.error(
          "Keine Verbindung zum Server - Logo konnte nicht vom Server heruntergeladen werden",
          {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            transition: Zoom,
          }
        );
      });
  }, []);

  const [values, setValues] = useState({
    name: "",
    password: "",
    token: "",
    show: true,
  });

  let urlToken = searchParams.get("token");
  const [urlTokenBool, setUrlTokenBool] = useState(
    searchParams.get("token") ? true : false
  );

  useEffect(() => {
    if (urlToken) {
      let jwtObject = jwt.decode(urlToken);
      const name = jwtObject && jwtObject.name && jwtObject.name;
      const password = jwtObject && jwtObject.password && jwtObject.password;

      if (urlToken && name && password) {
        setValues({ ...values, name, password, activationToken: urlToken });
      }
    }
  }, [urlToken, values]);

  const { activationToken } = values;

  const clickSubmit = (event) => {
    if (activationToken !== null) {
      event.preventDefault();
      axios({
        method: "POST",
        url: `${process.env.REACT_APP_PUBLIC_SERVER_URL}/auth/account-activation`,
        data: { token: searchParams.get("token") },
      })
        .then((response) => {
          setValues({
            ...values,
            show: false,
          });

          if (response.status === 200) {
            toast.success(`${t("AktivierungErfolgreich")}`, {
              toastId: "custom-id-yes",
              position: "top-center",
              autoClose: 6000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
              transition: Zoom,
            });
            setSearchParams({ t: 1 });
          } else if (response.status === 201) {
            toast.error(`${t("LinkAbgelaufen")}`, {
              toastId: "custom-id-yes",
              position: "top-center",
              autoClose: 7000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
              transition: Zoom,
            });
            setSearchParams({ t: 1 });
          } else if (response.status === 202) {
            toast.error(`${t("BenutzerExistiertBereits")}`, {
              toastId: "custom-id-yes",
              position: "top-center",
              autoClose: 7000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
              transition: Zoom,
            });
            setSearchParams({ t: 1 });
          } else {
            toast.error(`${t("KeineVerbindungZumServer")}`, {
              position: "top-center",
              autoClose: 7000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
              transition: Zoom,
            });
            setSearchParams({ t: 1 });
          }
        })
        .catch((error) => {
          toast.error(`${t("KeineVerbindungZumServer")}`, {
            toastId: "custom-id-yes",
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            transition: Zoom,
          });
          setSearchParams({ t: 1 });
        });
    }
  };

  const [newPassword, setNewPassword] = useState("");
  const handlePWChange = (event) => {
    setNewPassword(event.target.value);
  };

  const [showPassword, setShowPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const showPasswordHandler = () => {
    setShowPassword(!showPassword);
  };
  const showNewPasswordHandler = () => {
    setShowNewPassword(!showNewPassword);
  };

  const submitNewPassword = (event) => {
    event.preventDefault();
    const resettokenurl = searchParams.get("resettoken");

    if (newPassword.length < 6) {
      toast.error(`${t("EmailZuKlein")}`, {
        position: "top-center",
        autoClose: 4000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        transition: Zoom,
      });
    } else {
      axios({
        method: "PUT",
        url: `${process.env.REACT_APP_PUBLIC_SERVER_URL}/auth/reset-password`,
        data: { newPassword, resetPasswordLink: resettokenurl },
      })
        .then((response) => {
          setValues({
            ...values,
            show: false,
          });

          if (response.status === 200) {
            toast.success(`${t("PasswortGeaendert")}`, {
              toastId: "custom-id-yes",
              position: "top-center",
              autoClose: 6000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
              transition: Zoom,
            });
          } else if (response.status === 201) {
            toast.error(`${t("PasswortNicheGeaendert")}`, {
              toastId: "custom-id-yes",
              position: "top-center",
              autoClose: 7000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
              transition: Zoom,
            });
          } else if (response.status === 202) {
            toast.error(`${t("Linkabgelaufen")}`, {
              toastId: "custom-id-yes",
              position: "top-center",
              autoClose: 7000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
              transition: Zoom,
            });
          } else if (response.status === 204) {
            toast.error(`${t("PasswortLinkAbgelaufen")}`, {
              toastId: "custom-id-yes",
              position: "top-center",
              autoClose: 7000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
              transition: Zoom,
            });
          } else {
            toast.error(`${t("KeineVerbindungZumServer")}`, {
              position: "top-center",
              autoClose: 7000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
              transition: Zoom,
            });
          }
        })
        .catch((error) => {
          /* console.log('error', error); */
          toast.error(`${t("KeineVerbindungZumServer")}`, {
            toastId: "custom-id-yes",
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            transition: Zoom,
          });
        });
    }
    setSearchValue("");
    setSearchParams({ t: 1 });
  };

  return isLoading ? (
    <div>&nbsp;</div>
  ) : (
    <>
      {urlTokenBool && (
        <Modal show={true} backdrop={true} className="loggingModal">
          <Modal.Header>
            <div className="loginRegisterHeader">
              <Navbar.Brand style={{ margin: "0px", padding: "0px" }}>
                {DB_TopBarLogoImageSrc && logoActive ? (
                  <img height="65" src={DB_TopBarLogoImageSrc} alt="Logo" />
                ) : (
                  <img
                    id="theLogoSpin"
                    src="/images/logo180_PS2.gif"
                    alt="SuperMegaShop.com Logo"
                    height="65"
                  />
                )}
              </Navbar.Brand>
            </div>
          </Modal.Header>

          <Modal.Body className="p-0 loginRegister loginModal">
            <div onClick={clickSubmit}>
              <div
                className="loginNavIconActiveActivate"
                style={{ width: "100%", marginBottom: "0px" }}
              >
                <span className="singleIcon">🤙</span>
                <span className="singleIconText">{t("Activate")}</span>
              </div>
            </div>
          </Modal.Body>
          <Button
            className="buttonModalCloseActivation"
            style={{ marginTop: "0px" }}
            onClick={() => setUrlTokenBool(false)}
          >
            {t("Schliessen")}
          </Button>
        </Modal>
      )}

      {searchParams.get("resettoken") && (
        <Modal
          show={true}
          backdrop={true}
          className="loggingModal"
          style={{ backgroundColor: "rgba(0,0,0,0.6)" }}
        >
          <Modal.Header>
            <div className="loginRegisterHeader">
              <Navbar.Brand style={{ margin: "0px", padding: "0px" }}>
                {DB_TopBarLogoImageSrc && logoActive ? (
                  <img height="65" src={DB_TopBarLogoImageSrc} alt="Logo" />
                ) : (
                  <img
                    id="theLogoSpin"
                    src="/images/logo180_PS2.gif"
                    alt="SuperMegaShop.com Logo"
                    height="65"
                  />
                )}
              </Navbar.Brand>
            </div>
          </Modal.Header>

          <Modal.Body className="p-0 loginRegister loginModal text-center">
            <h5 className="mb-2" style={{ color: "white" }}>
              {t("BitteNeuesPasswort")}:
            </h5>
            <div className="text-center">
              <InputGroup
                className="mb-3 mt-3"
                style={{ width: "270px", margin: "0 auto" }}
              >
                <InputGroup.Text className="emailUniIconContainer">
                  <span
                    id="passwordUniIcon"
                    style={{ cursor: "pointer" }}
                    onClick={showNewPasswordHandler}
                  >
                    🔒
                  </span>
                </InputGroup.Text>

                <Form.Control
                  style={{ color: "white" }}
                  aria-label="newpassword"
                  aria-describedby="newpasswordUniIcon"
                  onChange={handlePWChange}
                  value={newPassword}
                  required={true}
                  id="newpassword"
                  placeholder="Passwort"
                />
              </InputGroup>
            </div>
            <button
              className="btn btn-primary mt-2 mb-3"
              style={{ fontWeight: "bold" }}
              onClick={submitNewPassword}
            >
              {t("SavePasswort")}
            </button>
          </Modal.Body>
        </Modal>
      )}
      {/* <div style={{ color: "white" }}>
        <button onClick={(newLocale) => clientSideLanguageChange("de")}>
          DE
        </button>

        <h1>{language}</h1>
      </div> */}
      <style jsx global>
        {`
          /* @font-face {
            font-family: ${DB_fontFace_FontFamilyName};
            src: local(${DB_fontFace_FontFamilyName}),
              url(${DB_fontFace_FontFamilySrc}) format("truetype");
            font-display: swap;
          } */

          body {
            -webkit-touch-callout: none; /* iOS Safari */
            -webkit-user-select: none; /* Safari */
             -khtml-user-select: none; /* Konqueror HTML */
               -moz-user-select: none; /* Old versions of Firefox */
                -ms-user-select: none; /* Internet Explorer/Edge */
                    user-select: none; /* Non-prefixed version, currently
                                          supported by Chrome, Edge, Opera and Firefox */
            font-family: ${DB_fontFace_FontFamilyName}, Arial, sans-serif;
            background: url(${
              !backgroundColorActive &&
              backgroundImageActive &&
              DB_BackgroundImageForAllBody
            })
              ${DB_BackgroundColorOverlayForAllBody} !important;
            background-color: ${
              backgroundColorActive
                ? DB_BackgroundColorOverlayForAllBody
                : "rgba(0,0,0,0)"
            }

            background-position: center !important;
            background-repeat: no-repeat !important;
            background-size: cover !important;
            background-attachment: fixed !important;
            background-blend-mode: multiply !important;
          }
          
          section {
            min-height: 1200px !important;
          }
          

          .footer {
            font-family: ${DB_fontFace_FontFamilyName}, Arial, sans-serif !important;
            font-size: 16px !important;
            color: white !important;
          }

          .navbarStaticMobile {
            padding-top: ${isMobile && 50}px !important;
          }

          .navbar-toggler {
            margin-left: 15px !important;
            padding: 5px !important;
            background-color: rgba(255,255,255,0.4) !important;
            
          }

          .navbar-toggler:focus {
            box-shadow: 0 0 0 rgba(0,0,0,0) !important;
       
          }

         

          .offcanvas-backdrop.show {
            opacity: 0;
          }

          label,
          .colorAndSize {
            text-decoration: none;
       

          }

          label,
          small,
          li,
          h1,
          h2,
          h3,
          h4,
          h5,
          h6,
          .form-select {
            text-decoration: none;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 1; /* number of lines to show */
            line-clamp: 1;
            -webkit-box-orient: vertical;
          }

          .OffCanvasTitle {
            font-family: "Lato";
            display: inline-block;
            color: white !important;
            background-color: rgba(0, 0, 0, 1);
            padding-top: 7px;
            padding-bottom: 7px;
            padding-left: 7px;
            padding-right: 7px;
            border-radius: 5px;
            margin-top: 20px;
          }
          .wholeBodyContainerBackgroundColor {
            padding: 0px !important;
            background-color: ${DB_HauptContainerBackground} !important;
          }

          .offcanvas-body {
            padding: 0px;
          }

          .react-colorful {
            width: 281px !important;
          }

          a {
            color: white;
            text-decoration: underline;
          }

          a:hover {
            font-weight: bold;
          }

          .form-check-input:checked {
            background-color: rgba(0, 0, 0, 0.5);
            border-color: rgba(0, 0, 0, 0.5);
            box-shadow: none;
          }

          .form-check-input:active,
          .form-check {
            box-shadow: none;
            border-color: rgba(0, 0, 0, 0.5);
          }

          option {
            background-color: rgba(0, 0, 0, 0.5);
          }

          hr {
            margin: 0px;
          }

          /* <--- Anzahl Produkte - Neueste, Älteste, Alphabetisch absteigend/aufsteigend, Preis absteigend/aufsteigend */

          @media screen and (max-width: 1024px) {
            .infosPlusFilterButtons {
              display: none !important;
            }
          }

          /* ---> Anzahl Produkte - Neueste, Älteste, Alphabetisch absteigend/aufsteigend, Preis absteigend/aufsteigend */

          /* <--- Login Modal */
          .modal-content {
            background-color: rgba(0, 0, 0, 0.8);
          }

          .form-control,
          .btn {
            font-family: ${DB_fontFace_FontFamilyName};
          }
          /* ---> Login Modal (Styling) */

          // Soziale Medien anzeigen (Icons: Facebook, Instagram, Twitter, LinkedIn)
          .rightSideSocialIcons {
            background-color: rgba(0, 0, 0, 0.3);
            text-align: left;
            padding: 10px;
            border-radius: 20px;
            border: 1px solid rgba(0, 0, 0, 0.3);
            box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px,
              rgba(0, 0, 0, 0.23) 0px 3px 6px;
          }

          // Soziale Medien, Nachricht an Käufer-/in, Unser Motto, Benutzerdefinierter Titel (Titel)
          .rightSideTitelUnserMottoNachrichtKaeufer {
            font-weight: bold;
            color: white;
            font-family: ${DB_fontFace_FontFamilyName};
            font-size: 15px;
            text-align: center;
            background-color: rgba(255, 255, 255, 0.1);
            border-radius: 5px;
            margin-top: 5px;
            margin-bottom: 5px;
            margin-top: 10px;
          }

          // Soziale Medien, Nachricht an Käufer-/in, Unser Motto, Benutzerdefinierter Titel (Text)
          p.rightSideTitelUnserMottoNachrichtKaeuferText {
            font-family: ${DB_fontFace_FontFamilyName};
            color: white;
          }

          /* .jumbotron {
            color: rgba(255, 255, 255, 1);
            font-family: ${DB_fontFace_FontFamilyName};
            padding: 20px;
            background-color: rgba(0, 0, 0, 0.2);
            border-radius: 10px;
            background: linear-gradient(to top, #00000022, #00000044),
              url(${DB_BackgroundImageForAllBody});
            border: 1px solid rgba(0, 0, 0, 0.3);
            box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px,
              rgba(0, 0, 0, 0.23) 0px 3px 6px;
          }
          */

          .configBarBottomStyle {
            background-color: rgba(0, 0, 0, 0.8) !important;
            color: white;
          }
        `}
      </style>

      {(isMobile || true) && (
        <Navbar expand="lg" className="m-0 p-0 navbarStaticMobile">
          <TopBarStatic
            meldenModalHandler={meldenModalHandler}
            faqDetailItem={faqDetailItem}
            aboutUsShowHandler={aboutUsShowHandler}
            faqShowHandler={faqShowHandler}
            TopBarIsVisibleTop={TopBarIsVisibleTop}
            logoActive={logoActive}
            countImagePlusTwo={countImagePlusTwo}
            shopName={shopName}
            resetFilterHandler={resetFilterHandler}
            searchValue={searchValue}
            searchHandler={searchHandler}
            searchPressEnter={searchPressEnter}
            isAuthState={isAuthState}
            signOutMessage={signOutMessage}
            productsUserId={productsUserId}
            showTopProductsHandler={showTopProductsHandler}
            toggleSignIn={toggleSignIn}
            /* handleShow_showConfig_Main={handleShow_showConfig_Main} */
            userOwnsThisSite={userOwnsThisSite}
            DB_fontFace_FontFamilyName={DB_fontFace_FontFamilyName}
            SideBarLeft_HauptmenuTopStatic_Farbe_Groesse_Stil_Trigger={
              SideBarLeft_HauptmenuTopStatic_Farbe_Groesse_Stil_Trigger
            }
            SideBarLeft_HauptmenuTopStatic_Farbe_Groesse_Stil_Hover_Trigger={
              SideBarLeft_HauptmenuTopStatic_Farbe_Groesse_Stil_Hover_Trigger
            }
            SideBarLeft_HauptmenuTopStatic_HintergrundFarbe_Radius_Stil_Trigger={
              SideBarLeft_HauptmenuTopStatic_HintergrundFarbe_Radius_Stil_Trigger
            }
            SideBarLeft_HauptmenuTopStatic_HintergrundFarbe_Radius_Stil_Hover_Trigger={
              SideBarLeft_HauptmenuTopStatic_HintergrundFarbe_Radius_Stil_Hover_Trigger
            }
            SideBarLeft_HauptmenuTopStatic_Farbe_Groesse_Stil_OnMouseOverAndOut={
              SideBarLeft_HauptmenuTopStatic_Farbe_Groesse_Stil_OnMouseOverAndOut
            }
            SideBarLeft_HauptmenuTopStatic_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut={
              SideBarLeft_HauptmenuTopStatic_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
            }
            SideBarLeft_HauptmenuTopStatic_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut={
              SideBarLeft_HauptmenuTopStatic_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut
            }
            SideBarLeft_HauptmenuTopStatic_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut={
              SideBarLeft_HauptmenuTopStatic_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut
            }
            DB_fontFace_hauptMenueStatic_FontFamilyName={
              DB_fontFace_hauptMenueStatic_FontFamilyName
            }
            DB_fontFace_hauptMenueStatic_FontFamilySrc={
              DB_fontFace_hauptMenueStatic_FontFamilySrc
            }
            DB_LeftSidebar_MenuItem_HauptmenuTop_FontWeight={
              DB_LeftSidebar_MenuItem_HauptmenuTop_FontWeight
            }
            DB_LeftSidebar_MenuItem_HauptmenuTop_FontColor={
              DB_LeftSidebar_MenuItem_HauptmenuTop_FontColor
            }
            DB_LeftSidebar_MenuItem_HauptmenuTop_FontSize={
              DB_LeftSidebar_MenuItem_HauptmenuTop_FontSize
            }
            DB_LeftSidebar_MenuItem_HauptmenuTop_BackgroundColor={
              DB_LeftSidebar_MenuItem_HauptmenuTop_BackgroundColor
            }
            DB_LeftSidebar_MenuItem_HauptmenuTop_TextAlign={
              DB_LeftSidebar_MenuItem_HauptmenuTop_TextAlign
            }
            DB_LeftSidebar_MenuItem_HauptmenuTop_BorderTop={
              DB_LeftSidebar_MenuItem_HauptmenuTop_BorderTop
            }
            DB_LeftSidebar_MenuItem_HauptmenuTop_BorderStyleTop={
              DB_LeftSidebar_MenuItem_HauptmenuTop_BorderStyleTop
            }
            DB_LeftSidebar_MenuItem_HauptmenuTop_BorderColorTop={
              DB_LeftSidebar_MenuItem_HauptmenuTop_BorderColorTop
            }
            DB_LeftSidebar_MenuItem_HauptmenuTop_BorderBottom={
              DB_LeftSidebar_MenuItem_HauptmenuTop_BorderBottom
            }
            DB_LeftSidebar_MenuItem_HauptmenuTop_BorderStyleBottom={
              DB_LeftSidebar_MenuItem_HauptmenuTop_BorderStyleBottom
            }
            DB_LeftSidebar_MenuItem_HauptmenuTop_BorderColorBottom={
              DB_LeftSidebar_MenuItem_HauptmenuTop_BorderColorBottom
            }
            DB_LeftSidebar_MenuItem_HauptmenuTop_BorderLeft={
              DB_LeftSidebar_MenuItem_HauptmenuTop_BorderLeft
            }
            DB_LeftSidebar_MenuItem_HauptmenuTop_BorderStyleLeft={
              DB_LeftSidebar_MenuItem_HauptmenuTop_BorderStyleLeft
            }
            DB_LeftSidebar_MenuItem_HauptmenuTop_BorderColorLeft={
              DB_LeftSidebar_MenuItem_HauptmenuTop_BorderColorLeft
            }
            DB_LeftSidebar_MenuItem_HauptmenuTop_BorderRight={
              DB_LeftSidebar_MenuItem_HauptmenuTop_BorderRight
            }
            DB_LeftSidebar_MenuItem_HauptmenuTop_BorderStyleRight={
              DB_LeftSidebar_MenuItem_HauptmenuTop_BorderStyleRight
            }
            DB_LeftSidebar_MenuItem_HauptmenuTop_BorderColorRight={
              DB_LeftSidebar_MenuItem_HauptmenuTop_BorderColorRight
            }
            DB_LeftSidebar_MenuItem_HauptmenuTop_BorderTopRightRadius={
              DB_LeftSidebar_MenuItem_HauptmenuTop_BorderTopRightRadius
            }
            DB_LeftSidebar_MenuItem_HauptmenuTop_BorderTopLeftRadius={
              DB_LeftSidebar_MenuItem_HauptmenuTop_BorderTopLeftRadius
            }
            DB_LeftSidebar_MenuItem_HauptmenuTop_BorderBottomRightRadius={
              DB_LeftSidebar_MenuItem_HauptmenuTop_BorderBottomRightRadius
            }
            DB_LeftSidebar_MenuItem_HauptmenuTop_BorderBottomLeftRadius={
              DB_LeftSidebar_MenuItem_HauptmenuTop_BorderBottomLeftRadius
            }
            DB_LeftSidebar_MenuItem_HauptmenuTop_marginLeftRight={
              DB_LeftSidebar_MenuItem_HauptmenuTop_marginLeftRight
            }
            DB_LeftSidebar_MenuItem_HauptmenuTop_marginTopBottom={
              DB_LeftSidebar_MenuItem_HauptmenuTop_marginTopBottom
            }
            DB_LeftSidebar_MenuItem_HauptmenuTop_paddingLeftRight={
              DB_LeftSidebar_MenuItem_HauptmenuTop_paddingLeftRight
            }
            DB_LeftSidebar_MenuItem_HauptmenuTop_paddingTopBottom={
              DB_LeftSidebar_MenuItem_HauptmenuTop_paddingTopBottom
            }
            DB_LeftSidebar_MenuItem_HauptmenuTop_letterSpacing={
              DB_LeftSidebar_MenuItem_HauptmenuTop_letterSpacing
            }
            DB_LeftSidebar_MenuItem_HauptmenuTop_ShadowHorizontal={
              DB_LeftSidebar_MenuItem_HauptmenuTop_ShadowHorizontal
            }
            DB_LeftSidebar_MenuItem_HauptmenuTop_ShadowVertical={
              DB_LeftSidebar_MenuItem_HauptmenuTop_ShadowVertical
            }
            DB_LeftSidebar_MenuItem_HauptmenuTop_ShadowBlur={
              DB_LeftSidebar_MenuItem_HauptmenuTop_ShadowBlur
            }
            DB_LeftSidebar_MenuItem_HauptmenuTop_ShadowColor={
              DB_LeftSidebar_MenuItem_HauptmenuTop_ShadowColor
            }
            DB_LeftSidebar_MenuItem_HauptmenuTop_TextDecoration={
              DB_LeftSidebar_MenuItem_HauptmenuTop_TextDecoration
            }
            DB_LeftSidebar_MenuItem_HauptmenuTop_TextDecorationStyle={
              DB_LeftSidebar_MenuItem_HauptmenuTop_TextDecorationStyle
            }
            DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_FontWeight={
              DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_FontWeight
            }
            DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_FontColor={
              DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_FontColor
            }
            DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_FontSize={
              DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_FontSize
            }
            DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BackgroundColor={
              DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BackgroundColor
            }
            DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_TextAlign={
              DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_TextAlign
            }
            DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderTop={
              DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderTop
            }
            DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderStyleTop={
              DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderStyleTop
            }
            DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderColorTop={
              DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderColorTop
            }
            DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderBottom={
              DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderBottom
            }
            DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderStyleBottom={
              DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderStyleBottom
            }
            DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderColorBottom={
              DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderColorBottom
            }
            DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderLeft={
              DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderLeft
            }
            DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderStyleLeft={
              DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderStyleLeft
            }
            DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderColorLeft={
              DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderColorLeft
            }
            DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderRight={
              DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderRight
            }
            DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderStyleRight={
              DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderStyleRight
            }
            DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderColorRight={
              DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderColorRight
            }
            DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderTopRightRadius={
              DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderTopRightRadius
            }
            DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderTopLeftRadius={
              DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderTopLeftRadius
            }
            DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderBottomRightRadius={
              DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderBottomRightRadius
            }
            DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderBottomLeftRadius={
              DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderBottomLeftRadius
            }
            DB_LeftSidebar_MenuItem_HauptmenuTop_marginLeftRight_Hover={
              DB_LeftSidebar_MenuItem_HauptmenuTop_marginLeftRight_Hover
            }
            DB_LeftSidebar_MenuItem_HauptmenuTop_marginTopBottom_Hover={
              DB_LeftSidebar_MenuItem_HauptmenuTop_marginTopBottom_Hover
            }
            DB_LeftSidebar_MenuItem_HauptmenuTop_paddingLeftRight_Hover={
              DB_LeftSidebar_MenuItem_HauptmenuTop_paddingLeftRight_Hover
            }
            DB_LeftSidebar_MenuItem_HauptmenuTop_paddingTopBottom_Hover={
              DB_LeftSidebar_MenuItem_HauptmenuTop_paddingTopBottom_Hover
            }
            DB_LeftSidebar_MenuItem_HauptmenuTop_letterSpacing_Hover={
              DB_LeftSidebar_MenuItem_HauptmenuTop_letterSpacing_Hover
            }
            DB_LeftSidebar_MenuItem_HauptmenuTop_ShadowHorizontal_Hover={
              DB_LeftSidebar_MenuItem_HauptmenuTop_ShadowHorizontal_Hover
            }
            DB_LeftSidebar_MenuItem_HauptmenuTop_ShadowVertical_Hover={
              DB_LeftSidebar_MenuItem_HauptmenuTop_ShadowVertical_Hover
            }
            DB_LeftSidebar_MenuItem_HauptmenuTop_ShadowBlur_Hover={
              DB_LeftSidebar_MenuItem_HauptmenuTop_ShadowBlur_Hover
            }
            DB_LeftSidebar_MenuItem_HauptmenuTop_ShadowColor_Hover={
              DB_LeftSidebar_MenuItem_HauptmenuTop_ShadowColor_Hover
            }
            DB_LeftSidebar_MenuItem_HauptmenuTop_TextDecoration_Hover={
              DB_LeftSidebar_MenuItem_HauptmenuTop_TextDecoration_Hover
            }
            DB_LeftSidebar_MenuItem_HauptmenuTop_TextDecorationStyle_Hover={
              DB_LeftSidebar_MenuItem_HauptmenuTop_TextDecorationStyle_Hover
            }
            DB_LeftSidebar_MenuItem_MenuContainer_Logo_DDpaddingLeft={
              DB_LeftSidebar_MenuItem_MenuContainer_Logo_DDpaddingLeft
            }
            DB_LeftSidebar_MenuItem_MenuContainer_Logo_DDpaddingRight={
              DB_LeftSidebar_MenuItem_MenuContainer_Logo_DDpaddingRight
            }
            DB_LeftSidebar_MenuItem_MenuContainer_Logo_DDpaddingTop={
              DB_LeftSidebar_MenuItem_MenuContainer_Logo_DDpaddingTop
            }
            DB_LeftSidebar_MenuItem_MenuContainer_Logo_DDpaddingBottom={
              DB_LeftSidebar_MenuItem_MenuContainer_Logo_DDpaddingBottom
            }
            DB_LeftSidebar_MenuItem_MenuContainer_Logo_DDShadowHorizontal={
              DB_LeftSidebar_MenuItem_MenuContainer_Logo_DDShadowHorizontal
            }
            DB_LeftSidebar_MenuItem_MenuContainer_Logo_DDShadowVertical={
              DB_LeftSidebar_MenuItem_MenuContainer_Logo_DDShadowVertical
            }
            DB_LeftSidebar_MenuItem_MenuContainer_Logo_DDHeight={
              DB_LeftSidebar_MenuItem_MenuContainer_Logo_DDHeight
            }
            DB_LeftSidebar_MenuItem_MenuContainer_Logo_DDShadowBlur={
              DB_LeftSidebar_MenuItem_MenuContainer_Logo_DDShadowBlur
            }
            DB_LeftSidebar_MenuItem_MenuContainer_Logo_DDShadowColor={
              DB_LeftSidebar_MenuItem_MenuContainer_Logo_DDShadowColor
            }
            DB_LeftSidebar_MenuContainer_Logo_DDBackgroundColor={
              DB_LeftSidebar_MenuContainer_Logo_DDBackgroundColor
            }
            SideBarLeft_ContainerTopXYZ_Farbe_Groesse_Stil_OnMouseOverAndOut={
              SideBarLeft_ContainerTopXYZ_Farbe_Groesse_Stil_OnMouseOverAndOut
            }
            SideBarLeft_ContainerTopXYZ_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut={
              SideBarLeft_ContainerTopXYZ_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
            }
            SideBarLeft_ContainerTopXYZ_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut={
              SideBarLeft_ContainerTopXYZ_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut
            }
            SideBarLeft_ContainerTopXYZ_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut={
              SideBarLeft_ContainerTopXYZ_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut
            }
            DB_LeftSidebar_MenuContainerXYZ_BackgroundColor={
              DB_LeftSidebar_MenuContainerXYZ_BackgroundColor
            }
            DB_LeftSidebar_MenuContainerXYZ_PaddingTopBottom={
              DB_LeftSidebar_MenuContainerXYZ_PaddingTopBottom
            }
            DB_LeftSidebar_MenuContainerXYZ_PaddingLeftRight={
              DB_LeftSidebar_MenuContainerXYZ_PaddingLeftRight
            }
            DB_LeftSidebar_MenuContainerXYZ_BorderTop={
              DB_LeftSidebar_MenuContainerXYZ_BorderTop
            }
            DB_LeftSidebar_MenuContainerXYZ_BorderStyleTop={
              DB_LeftSidebar_MenuContainerXYZ_BorderStyleTop
            }
            DB_LeftSidebar_MenuContainerXYZ_BorderColorTop={
              DB_LeftSidebar_MenuContainerXYZ_BorderColorTop
            }
            DB_LeftSidebar_MenuContainerXYZ_BorderBottom={
              DB_LeftSidebar_MenuContainerXYZ_BorderBottom
            }
            DB_LeftSidebar_MenuContainerXYZ_BorderStyleBottom={
              DB_LeftSidebar_MenuContainerXYZ_BorderStyleBottom
            }
            DB_LeftSidebar_MenuContainerXYZ_BorderColorBottom={
              DB_LeftSidebar_MenuContainerXYZ_BorderColorBottom
            }
            DB_LeftSidebar_MenuContainerXYZ_BorderLeft={
              DB_LeftSidebar_MenuContainerXYZ_BorderLeft
            }
            DB_LeftSidebar_MenuContainerXYZ_BorderStyleLeft={
              DB_LeftSidebar_MenuContainerXYZ_BorderStyleLeft
            }
            DB_LeftSidebar_MenuContainerXYZ_BorderColorLeft={
              DB_LeftSidebar_MenuContainerXYZ_BorderColorLeft
            }
            DB_LeftSidebar_MenuContainerXYZ_BorderRight={
              DB_LeftSidebar_MenuContainerXYZ_BorderRight
            }
            DB_LeftSidebar_MenuContainerXYZ_BorderStyleRight={
              DB_LeftSidebar_MenuContainerXYZ_BorderStyleRight
            }
            DB_LeftSidebar_MenuContainerXYZ_BorderColorRight={
              DB_LeftSidebar_MenuContainerXYZ_BorderColorRight
            }
            DB_LeftSidebar_MenuItem_MenuContainerXYZ_BorderTopRightRadius={
              DB_LeftSidebar_MenuItem_MenuContainerXYZ_BorderTopRightRadius
            }
            DB_LeftSidebar_MenuItem_MenuContainerXYZ_BorderTopLeftRadius={
              DB_LeftSidebar_MenuItem_MenuContainerXYZ_BorderTopLeftRadius
            }
            DB_LeftSidebar_MenuItem_MenuContainerXYZ_BorderBottomRightRadius={
              DB_LeftSidebar_MenuItem_MenuContainerXYZ_BorderBottomRightRadius
            }
            DB_LeftSidebar_MenuItem_MenuContainerXYZ_BorderBottomLeftRadius={
              DB_LeftSidebar_MenuItem_MenuContainerXYZ_BorderBottomLeftRadius
            }
            DB_LeftSidebar_MenuItem_MenuContainerXYZ_paddingLeft={
              DB_LeftSidebar_MenuItem_MenuContainerXYZ_paddingLeft
            }
            DB_LeftSidebar_MenuItem_MenuContainerXYZ_paddingRight={
              DB_LeftSidebar_MenuItem_MenuContainerXYZ_paddingRight
            }
            DB_LeftSidebar_MenuItem_MenuContainerXYZ_paddingTop={
              DB_LeftSidebar_MenuItem_MenuContainerXYZ_paddingTop
            }
            DB_LeftSidebar_MenuItem_MenuContainerXYZ_paddingBottom={
              DB_LeftSidebar_MenuItem_MenuContainerXYZ_paddingBottom
            }
            DB_LeftSidebar_MenuItem_MenuContainerXYZ_ShadowHorizontal={
              DB_LeftSidebar_MenuItem_MenuContainerXYZ_ShadowHorizontal
            }
            DB_LeftSidebar_MenuItem_MenuContainerXYZ_ShadowVertical={
              DB_LeftSidebar_MenuItem_MenuContainerXYZ_ShadowVertical
            }
            DB_LeftSidebar_MenuItem_MenuContainerXYZ_ShadowBlur={
              DB_LeftSidebar_MenuItem_MenuContainerXYZ_ShadowBlur
            }
            DB_LeftSidebar_MenuItem_MenuContainerXYZ_ShadowColor={
              DB_LeftSidebar_MenuItem_MenuContainerXYZ_ShadowColor
            }
            DB_LeftSidebar_MenuContainerXYZ_Hover_BackgroundColor={
              DB_LeftSidebar_MenuContainerXYZ_Hover_BackgroundColor
            }
            DB_LeftSidebar_MenuContainerXYZ_Hover_PaddingTopBottom={
              DB_LeftSidebar_MenuContainerXYZ_Hover_PaddingTopBottom
            }
            DB_LeftSidebar_MenuContainerXYZ_Hover_PaddingLeftRight={
              DB_LeftSidebar_MenuContainerXYZ_Hover_PaddingLeftRight
            }
            DB_LeftSidebar_MenuContainerXYZ_Hover_BorderTop={
              DB_LeftSidebar_MenuContainerXYZ_Hover_BorderTop
            }
            DB_LeftSidebar_MenuContainerXYZ_Hover_BorderStyleTop={
              DB_LeftSidebar_MenuContainerXYZ_Hover_BorderStyleTop
            }
            DB_LeftSidebar_MenuContainerXYZ_Hover_BorderColorTop={
              DB_LeftSidebar_MenuContainerXYZ_Hover_BorderColorTop
            }
            DB_LeftSidebar_MenuContainerXYZ_Hover_BorderBottom={
              DB_LeftSidebar_MenuContainerXYZ_Hover_BorderBottom
            }
            DB_LeftSidebar_MenuContainerXYZ_Hover_BorderStyleBottom={
              DB_LeftSidebar_MenuContainerXYZ_Hover_BorderStyleBottom
            }
            DB_LeftSidebar_MenuContainerXYZ_Hover_BorderColorBottom={
              DB_LeftSidebar_MenuContainerXYZ_Hover_BorderColorBottom
            }
            DB_LeftSidebar_MenuContainerXYZ_Hover_BorderLeft={
              DB_LeftSidebar_MenuContainerXYZ_Hover_BorderLeft
            }
            DB_LeftSidebar_MenuContainerXYZ_Hover_BorderStyleLeft={
              DB_LeftSidebar_MenuContainerXYZ_Hover_BorderStyleLeft
            }
            DB_LeftSidebar_MenuContainerXYZ_Hover_BorderColorLeft={
              DB_LeftSidebar_MenuContainerXYZ_Hover_BorderColorLeft
            }
            DB_LeftSidebar_MenuContainerXYZ_Hover_BorderRight={
              DB_LeftSidebar_MenuContainerXYZ_Hover_BorderRight
            }
            DB_LeftSidebar_MenuContainerXYZ_Hover_BorderStyleRight={
              DB_LeftSidebar_MenuContainerXYZ_Hover_BorderStyleRight
            }
            DB_LeftSidebar_MenuContainerXYZ_Hover_BorderColorRight={
              DB_LeftSidebar_MenuContainerXYZ_Hover_BorderColorRight
            }
            DB_LeftSidebar_MenuItem_MenuContainerXYZ_Hover_BorderTopRightRadius={
              DB_LeftSidebar_MenuItem_MenuContainerXYZ_Hover_BorderTopRightRadius
            }
            DB_LeftSidebar_MenuItem_MenuContainerXYZ_Hover_BorderTopLeftRadius={
              DB_LeftSidebar_MenuItem_MenuContainerXYZ_Hover_BorderTopLeftRadius
            }
            DB_LeftSidebar_MenuItem_MenuContainerXYZ_Hover_BorderBottomRightRadius={
              DB_LeftSidebar_MenuItem_MenuContainerXYZ_Hover_BorderBottomRightRadius
            }
            DB_LeftSidebar_MenuItem_MenuContainerXYZ_Hover_BorderBottomLeftRadius={
              DB_LeftSidebar_MenuItem_MenuContainerXYZ_Hover_BorderBottomLeftRadius
            }
            DB_LeftSidebar_MenuItem_MenuContainerXYZ_Hover_paddingLeft={
              DB_LeftSidebar_MenuItem_MenuContainerXYZ_Hover_paddingLeft
            }
            DB_LeftSidebar_MenuItem_MenuContainerXYZ_Hover_paddingRight={
              DB_LeftSidebar_MenuItem_MenuContainerXYZ_Hover_paddingRight
            }
            DB_LeftSidebar_MenuItem_MenuContainerXYZ_Hover_paddingTop={
              DB_LeftSidebar_MenuItem_MenuContainerXYZ_Hover_paddingTop
            }
            DB_LeftSidebar_MenuItem_MenuContainerXYZ_Hover_paddingBottom={
              DB_LeftSidebar_MenuItem_MenuContainerXYZ_Hover_paddingBottom
            }
            DB_LeftSidebar_MenuItem_MenuContainerXYZ_Hover_ShadowHorizontal={
              DB_LeftSidebar_MenuItem_MenuContainerXYZ_Hover_ShadowHorizontal
            }
            DB_LeftSidebar_MenuItem_MenuContainerXYZ_Hover_ShadowVertical={
              DB_LeftSidebar_MenuItem_MenuContainerXYZ_Hover_ShadowVertical
            }
            DB_LeftSidebar_MenuItem_MenuContainerXYZ_Hover_ShadowBlur={
              DB_LeftSidebar_MenuItem_MenuContainerXYZ_Hover_ShadowBlur
            }
            DB_LeftSidebar_MenuItem_MenuContainerXYZ_Hover_ShadowColor={
              DB_LeftSidebar_MenuItem_MenuContainerXYZ_Hover_ShadowColor
            }
          />
        </Navbar>
      )}
      {(topMainCategoriesVisible || isMobile) && (
        <Navbar expand="lg">
          <HauptMenueTop
            ultraCategoriesNew={ultraCategoriesNew}
            categoryLeftSideHandler={(e) => {
              categoryLeftSideHandler(e);
            }}
            DB_LeftSidebar_MenuItem_MenuContainerAA_Verteilung={
              DB_LeftSidebar_MenuItem_MenuContainerAA_Verteilung
            }
            SideBarLeft_HauptmenuTopAA_Farbe_Groesse_Stil_OnMouseOverAndOut={
              SideBarLeft_HauptmenuTop_Farbe_Groesse_Stil_OnMouseOverAndOut
            }
            SideBarLeft_HauptmenuTopAA_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut={
              SideBarLeft_HauptmenuTop_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
            }
            SideBarLeft_HauptmenuTopAA_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut={
              SideBarLeft_HauptmenuTop_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut
            }
            SideBarLeft_HauptmenuTopAA_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut={
              SideBarLeft_HauptmenuTop_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut
            }
            SideBarLeft_ContainerTopAA_Farbe_Groesse_Stil_OnMouseOverAndOut={
              SideBarLeft_ContainerTop_Farbe_Groesse_Stil_OnMouseOverAndOut
            }
            SideBarLeft_ContainerTopAA_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut={
              SideBarLeft_ContainerTop_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
            }
            SideBarLeft_ContainerTopAA_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut={
              SideBarLeft_ContainerTop_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut
            }
            SideBarLeft_ContainerTopAA_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut={
              SideBarLeft_ContainerTop_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut
            }
            DB_fontFace_hauptMenueTopAA_FontFamilyName={
              DB_fontFace_hauptMenueTopAA_FontFamilyName
            }
            DB_LeftSidebar_MenuContainerAA_BackgroundColor={
              DB_LeftSidebar_MenuContainerAA_BackgroundColor
            }
            DB_LeftSidebar_MenuContainerAA_PaddingTopBottom={
              DB_LeftSidebar_MenuContainerAA_PaddingTopBottom
            }
            DB_LeftSidebar_MenuContainerAA_PaddingLeftRight={
              DB_LeftSidebar_MenuContainerAA_PaddingLeftRight
            }
            DB_LeftSidebar_MenuContainerAA_BorderTop={
              DB_LeftSidebar_MenuContainerAA_BorderTop
            }
            DB_LeftSidebar_MenuContainerAA_BorderStyleTop={
              DB_LeftSidebar_MenuContainerAA_BorderStyleTop
            }
            DB_LeftSidebar_MenuContainerAA_BorderColorTop={
              DB_LeftSidebar_MenuContainerAA_BorderColorTop
            }
            DB_LeftSidebar_MenuContainerAA_BorderBottom={
              DB_LeftSidebar_MenuContainerAA_BorderBottom
            }
            DB_LeftSidebar_MenuContainerAA_BorderStyleBottom={
              DB_LeftSidebar_MenuContainerAA_BorderStyleBottom
            }
            DB_LeftSidebar_MenuContainerAA_BorderColorBottom={
              DB_LeftSidebar_MenuContainerAA_BorderColorBottom
            }
            DB_LeftSidebar_MenuContainerAA_BorderLeft={
              DB_LeftSidebar_MenuContainerAA_BorderLeft
            }
            DB_LeftSidebar_MenuContainerAA_BorderStyleLeft={
              DB_LeftSidebar_MenuContainerAA_BorderStyleLeft
            }
            DB_LeftSidebar_MenuContainerAA_BorderColorLeft={
              DB_LeftSidebar_MenuContainerAA_BorderColorLeft
            }
            DB_LeftSidebar_MenuContainerAA_BorderRight={
              DB_LeftSidebar_MenuContainerAA_BorderRight
            }
            DB_LeftSidebar_MenuContainerAA_BorderStyleRight={
              DB_LeftSidebar_MenuContainerAA_BorderStyleRight
            }
            DB_LeftSidebar_MenuContainerAA_BorderColorRight={
              DB_LeftSidebar_MenuContainerAA_BorderColorRight
            }
            DB_LeftSidebar_MenuItem_MenuContainerAA_BorderTopRightRadius={
              DB_LeftSidebar_MenuItem_MenuContainerAA_BorderTopRightRadius
            }
            DB_LeftSidebar_MenuItem_MenuContainerAA_BorderTopLeftRadius={
              DB_LeftSidebar_MenuItem_MenuContainerAA_BorderTopLeftRadius
            }
            DB_LeftSidebar_MenuItem_MenuContainerAA_BorderBottomRightRadius={
              DB_LeftSidebar_MenuItem_MenuContainerAA_BorderBottomRightRadius
            }
            DB_LeftSidebar_MenuItem_MenuContainerAA_BorderBottomLeftRadius={
              DB_LeftSidebar_MenuItem_MenuContainerAA_BorderBottomLeftRadius
            }
            DB_LeftSidebar_MenuItem_MenuContainerAA_paddingLeft={
              DB_LeftSidebar_MenuItem_MenuContainerAA_paddingLeft
            }
            DB_LeftSidebar_MenuItem_MenuContainerAA_paddingRight={
              DB_LeftSidebar_MenuItem_MenuContainerAA_paddingRight
            }
            DB_LeftSidebar_MenuItem_MenuContainerAA_paddingTop={
              DB_LeftSidebar_MenuItem_MenuContainerAA_paddingTop
            }
            DB_LeftSidebar_MenuItem_MenuContainerAA_paddingBottom={
              DB_LeftSidebar_MenuItem_MenuContainerAA_paddingBottom
            }
            DB_LeftSidebar_MenuItem_MenuContainerAA_ShadowHorizontal={
              DB_LeftSidebar_MenuItem_MenuContainerAA_ShadowHorizontal
            }
            DB_LeftSidebar_MenuItem_MenuContainerAA_ShadowVertical={
              DB_LeftSidebar_MenuItem_MenuContainerAA_ShadowVertical
            }
            DB_LeftSidebar_MenuItem_MenuContainerAA_ShadowBlur={
              DB_LeftSidebar_MenuItem_MenuContainerAA_ShadowBlur
            }
            DB_LeftSidebar_MenuItem_MenuContainerAA_ShadowColor={
              DB_LeftSidebar_MenuItem_MenuContainerAA_ShadowColor
            }
            DB_LeftSidebar_MenuContainerAA_Hover_BackgroundColor={
              DB_LeftSidebar_MenuContainerAA_Hover_BackgroundColor
            }
            DB_LeftSidebar_MenuContainerAA_Hover_PaddingTopBottom={
              DB_LeftSidebar_MenuContainerAA_Hover_PaddingTopBottom
            }
            DB_LeftSidebar_MenuContainerAA_Hover_PaddingLeftRight={
              DB_LeftSidebar_MenuContainerAA_Hover_PaddingLeftRight
            }
            DB_LeftSidebar_MenuContainerAA_Hover_BorderTop={
              DB_LeftSidebar_MenuContainerAA_Hover_BorderTop
            }
            DB_LeftSidebar_MenuContainerAA_Hover_BorderStyleTop={
              DB_LeftSidebar_MenuContainerAA_Hover_BorderStyleTop
            }
            DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderStyleTop={
              DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderStyleTop
            }
            DB_LeftSidebar_MenuContainerAA_Hover_BorderColorTop={
              DB_LeftSidebar_MenuContainerAA_Hover_BorderColorTop
            }
            DB_LeftSidebar_MenuContainerAA_Hover_BorderBottom={
              DB_LeftSidebar_MenuContainerAA_Hover_BorderBottom
            }
            DB_LeftSidebar_MenuContainerAA_Hover_BorderStyleBottom={
              DB_LeftSidebar_MenuContainerAA_Hover_BorderStyleBottom
            }
            DB_LeftSidebar_MenuContainerAA_Hover_BorderColorBottom={
              DB_LeftSidebar_MenuContainerAA_Hover_BorderColorBottom
            }
            DB_LeftSidebar_MenuContainerAA_Hover_BorderLeft={
              DB_LeftSidebar_MenuContainerAA_Hover_BorderLeft
            }
            DB_LeftSidebar_MenuContainerAA_Hover_BorderStyleLeft={
              DB_LeftSidebar_MenuContainerAA_Hover_BorderStyleLeft
            }
            DB_LeftSidebar_MenuContainerAA_Hover_BorderColorLeft={
              DB_LeftSidebar_MenuContainerAA_Hover_BorderColorLeft
            }
            DB_LeftSidebar_MenuContainerAA_Hover_BorderRight={
              DB_LeftSidebar_MenuContainerAA_Hover_BorderRight
            }
            DB_LeftSidebar_MenuContainerAA_Hover_BorderStyleRight={
              DB_LeftSidebar_MenuContainerAA_Hover_BorderStyleRight
            }
            DB_LeftSidebar_MenuContainerAA_Hover_BorderColorRight={
              DB_LeftSidebar_MenuContainerAA_Hover_BorderColorRight
            }
            DB_LeftSidebar_MenuItem_MenuContainerAA_Hover_BorderTopRightRadius={
              DB_LeftSidebar_MenuItem_MenuContainerAA_Hover_BorderTopRightRadius
            }
            DB_LeftSidebar_MenuItem_MenuContainerAA_Hover_BorderTopLeftRadius={
              DB_LeftSidebar_MenuItem_MenuContainerAA_Hover_BorderTopLeftRadius
            }
            DB_LeftSidebar_MenuItem_MenuContainerAA_Hover_BorderBottomRightRadius={
              DB_LeftSidebar_MenuItem_MenuContainerAA_Hover_BorderBottomRightRadius
            }
            DB_LeftSidebar_MenuItem_MenuContainerAA_Hover_BorderBottomLeftRadius={
              DB_LeftSidebar_MenuItem_MenuContainerAA_Hover_BorderBottomLeftRadius
            }
            DB_LeftSidebar_MenuItem_MenuContainerAA_Hover_paddingLeft={
              DB_LeftSidebar_MenuItem_MenuContainerAA_Hover_paddingLeft
            }
            DB_LeftSidebar_MenuItem_MenuContainerAA_Hover_paddingRight={
              DB_LeftSidebar_MenuItem_MenuContainerAA_Hover_paddingRight
            }
            DB_LeftSidebar_MenuItem_MenuContainerAA_Hover_paddingTop={
              DB_LeftSidebar_MenuItem_MenuContainerAA_Hover_paddingTop
            }
            DB_LeftSidebar_MenuItem_MenuContainerAA_Hover_paddingBottom={
              DB_LeftSidebar_MenuItem_MenuContainerAA_Hover_paddingBottom
            }
            DB_LeftSidebar_MenuItem_MenuContainerAA_Hover_ShadowHorizontal={
              DB_LeftSidebar_MenuItem_MenuContainerAA_Hover_ShadowHorizontal
            }
            DB_LeftSidebar_MenuItem_MenuContainerAA_Hover_ShadowVertical={
              DB_LeftSidebar_MenuItem_MenuContainerAA_Hover_ShadowVertical
            }
            DB_LeftSidebar_MenuItem_MenuContainerAA_Hover_ShadowBlur={
              DB_LeftSidebar_MenuItem_MenuContainerAA_Hover_ShadowBlur
            }
            DB_LeftSidebar_MenuItem_MenuContainerAA_Hover_ShadowColor={
              DB_LeftSidebar_MenuItem_MenuContainerAA_Hover_ShadowColor
            }
            DB_LeftSidebar_MenuItem_HauptmenuTopAA_FontWeight={
              DB_LeftSidebar_MenuItem_HauptmenuTopAA_FontWeight
            }
            DB_LeftSidebar_MenuItem_HauptmenuTopAA_FontColor={
              DB_LeftSidebar_MenuItem_HauptmenuTopAA_FontColor
            }
            DB_LeftSidebar_MenuItem_HauptmenuTopAA_FontSize={
              DB_LeftSidebar_MenuItem_HauptmenuTopAA_FontSize
            }
            DB_LeftSidebar_MenuItem_HauptmenuTopAA_BackgroundColor={
              DB_LeftSidebar_MenuItem_HauptmenuTopAA_BackgroundColor
            }
            DB_LeftSidebar_MenuItem_HauptmenuTopAA_TextAlign={
              DB_LeftSidebar_MenuItem_HauptmenuTopAA_TextAlign
            }
            DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderTop={
              DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderTop
            }
            DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderStyleTop={
              DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderStyleTop
            }
            DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderColorTop={
              DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderColorTop
            }
            DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderBottom={
              DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderBottom
            }
            DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderBottom={
              DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderBottom
            }
            DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderStyleBottom={
              DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderStyleBottom
            }
            DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderColorBottom={
              DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderColorBottom
            }
            DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderLeft={
              DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderLeft
            }
            DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderStyleLeft={
              DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderStyleLeft
            }
            DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderColorLeft={
              DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderColorLeft
            }
            DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderRight={
              DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderRight
            }
            DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderStyleRight={
              DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderStyleRight
            }
            DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderColorRight={
              DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderColorRight
            }
            DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderTopRightRadius={
              DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderTopRightRadius
            }
            DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderTopLeftRadius={
              DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderTopLeftRadius
            }
            DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderBottomRightRadius={
              DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderBottomRightRadius
            }
            DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderBottomLeftRadius={
              DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderBottomLeftRadius
            }
            DB_LeftSidebar_MenuItem_HauptmenuTopAA_marginLeftRight={
              DB_LeftSidebar_MenuItem_HauptmenuTopAA_marginLeftRight
            }
            DB_LeftSidebar_MenuItem_HauptmenuTopAA_marginTopBottom={
              DB_LeftSidebar_MenuItem_HauptmenuTopAA_marginTopBottom
            }
            DB_LeftSidebar_MenuItem_HauptmenuTopAA_paddingLeftRight={
              DB_LeftSidebar_MenuItem_HauptmenuTopAA_paddingLeftRight
            }
            DB_LeftSidebar_MenuItem_HauptmenuTopAA_paddingTopBottom={
              DB_LeftSidebar_MenuItem_HauptmenuTopAA_paddingTopBottom
            }
            DB_LeftSidebar_MenuItem_HauptmenuTopAA_letterSpacing={
              DB_LeftSidebar_MenuItem_HauptmenuTopAA_letterSpacing
            }
            DB_LeftSidebar_MenuItem_HauptmenuTopAA_ShadowHorizontal={
              DB_LeftSidebar_MenuItem_HauptmenuTopAA_ShadowHorizontal
            }
            DB_LeftSidebar_MenuItem_HauptmenuTopAA_ShadowVertical={
              DB_LeftSidebar_MenuItem_HauptmenuTopAA_ShadowVertical
            }
            DB_LeftSidebar_MenuItem_HauptmenuTopAA_ShadowBlur={
              DB_LeftSidebar_MenuItem_HauptmenuTopAA_ShadowBlur
            }
            DB_LeftSidebar_MenuItem_HauptmenuTopAA_ShadowColor={
              DB_LeftSidebar_MenuItem_HauptmenuTopAA_ShadowColor
            }
            DB_LeftSidebar_MenuItem_HauptmenuTopAA_TextDecoration={
              DB_LeftSidebar_MenuItem_HauptmenuTopAA_TextDecoration
            }
            DB_LeftSidebar_MenuItem_HauptmenuTopAA_TextDecorationStyle={
              DB_LeftSidebar_MenuItem_HauptmenuTopAA_TextDecorationStyle
            }
            DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_FontWeight={
              DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_FontWeight
            }
            DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_FontColor={
              DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_FontColor
            }
            DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_FontSize={
              DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_FontSize
            }
            DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BackgroundColor={
              DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BackgroundColor
            }
            DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_TextAlign={
              DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_TextAlign
            }
            DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderTop={
              DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderTop
            }
            DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderColorTop={
              DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderColorTop
            }
            DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderColorBottom={
              DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderColorBottom
            }
            DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderStyleBottom={
              DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderStyleBottom
            }
            DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderLeft={
              DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderLeft
            }
            DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderStyleLeft={
              DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderStyleLeft
            }
            DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderColorLeft={
              DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderColorLeft
            }
            DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderColorRight={
              DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderColorRight
            }
            DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderRight={
              DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderRight
            }
            DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderStyleRight={
              DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderStyleRight
            }
            DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderTopRightRadius={
              DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderTopRightRadius
            }
            DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderTopLeftRadius={
              DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderTopLeftRadius
            }
            DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderBottomRightRadius={
              DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderBottomRightRadius
            }
            DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderBottomLeftRadius={
              DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderBottomLeftRadius
            }
            DB_LeftSidebar_MenuItem_HauptmenuTopAA_marginLeftRight_Hover={
              DB_LeftSidebar_MenuItem_HauptmenuTopAA_marginLeftRight_Hover
            }
            DB_LeftSidebar_MenuItem_HauptmenuTopAA_marginTopBottom_Hover={
              DB_LeftSidebar_MenuItem_HauptmenuTopAA_marginTopBottom_Hover
            }
            DB_LeftSidebar_MenuItem_HauptmenuTopAA_paddingLeftRight_Hover={
              DB_LeftSidebar_MenuItem_HauptmenuTopAA_paddingLeftRight_Hover
            }
            DB_LeftSidebar_MenuItem_HauptmenuTopAA_paddingTopBottom_Hover={
              DB_LeftSidebar_MenuItem_HauptmenuTopAA_paddingTopBottom_Hover
            }
            DB_LeftSidebar_MenuItem_HauptmenuTopAA_letterSpacing_Hover={
              DB_LeftSidebar_MenuItem_HauptmenuTopAA_letterSpacing_Hover
            }
            DB_LeftSidebar_MenuItem_HauptmenuTopAA_ShadowHorizontal_Hover={
              DB_LeftSidebar_MenuItem_HauptmenuTopAA_ShadowHorizontal_Hover
            }
            DB_LeftSidebar_MenuItem_HauptmenuTopAA_ShadowVertical_Hover={
              DB_LeftSidebar_MenuItem_HauptmenuTopAA_ShadowVertical_Hover
            }
            DB_LeftSidebar_MenuItem_HauptmenuTopAA_ShadowBlur_Hover={
              DB_LeftSidebar_MenuItem_HauptmenuTopAA_ShadowBlur_Hover
            }
            DB_LeftSidebar_MenuItem_HauptmenuTopAA_ShadowColor_Hover={
              DB_LeftSidebar_MenuItem_HauptmenuTopAA_ShadowColor_Hover
            }
            DB_LeftSidebar_MenuItem_HauptmenuTopAA_TextDecoration_Hover={
              DB_LeftSidebar_MenuItem_HauptmenuTopAA_TextDecoration_Hover
            }
            DB_LeftSidebar_MenuItem_HauptmenuTopAA_TextDecorationStyle_Hover={
              DB_LeftSidebar_MenuItem_HauptmenuTopAA_TextDecorationStyle_Hover
            }
          />
        </Navbar>
      )}
      {MarqueeIsVisibleTop && !isMobile && (
        <Navbar expand="lg">
          <MarqueeBox
            allFrontpageProductsState={allFrontpageProductsState}
            showProductDetailsHandler={showProductDetailsHandler}
            SideBarLeft_HauptmenuTopAA_Farbe_Groesse_Stil_OnMouseOverAndOut={
              SideBarLeft_HauptmenuTop_Farbe_Groesse_Stil_OnMouseOverAndOut
            }
            SideBarLeft_HauptmenuTopAA_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut={
              SideBarLeft_HauptmenuTop_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
            }
            SideBarLeft_HauptmenuTopAA_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut={
              SideBarLeft_HauptmenuTop_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut
            }
            SideBarLeft_HauptmenuTopAA_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut={
              SideBarLeft_HauptmenuTop_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut
            }
            SideBarLeft_ContainerTopAA_Farbe_Groesse_Stil_OnMouseOverAndOut={
              SideBarLeft_ContainerTop_Farbe_Groesse_Stil_OnMouseOverAndOut
            }
            SideBarLeft_ContainerTopAA_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut={
              SideBarLeft_ContainerTop_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
            }
            DB_LeftSidebar_MenuContainerAA_BackgroundColor={
              DB_LeftSidebar_MenuContainerAA_BackgroundColor
            }
            DB_LeftSidebar_MenuContainerAA_PaddingTopBottom={
              DB_LeftSidebar_MenuContainerAA_PaddingTopBottom
            }
            DB_LeftSidebar_MenuContainerAA_PaddingLeftRight={
              DB_LeftSidebar_MenuContainerAA_PaddingLeftRight
            }
            DB_LeftSidebar_MenuContainerAA_BorderTop={
              DB_LeftSidebar_MenuContainerAA_BorderTop
            }
            DB_LeftSidebar_MenuContainerAA_BorderStyleTop={
              DB_LeftSidebar_MenuContainerAA_BorderStyleTop
            }
            DB_LeftSidebar_MenuContainerAA_BorderColorTop={
              DB_LeftSidebar_MenuContainerAA_BorderColorTop
            }
            DB_LeftSidebar_MenuContainerAA_BorderBottom={
              DB_LeftSidebar_MenuContainerAA_BorderBottom
            }
            DB_LeftSidebar_MenuContainerAA_BorderStyleBottom={
              DB_LeftSidebar_MenuContainerAA_BorderStyleBottom
            }
            DB_LeftSidebar_MenuContainerAA_BorderColorBottom={
              DB_LeftSidebar_MenuContainerAA_BorderColorBottom
            }
            DB_LeftSidebar_MenuContainerAA_BorderLeft={
              DB_LeftSidebar_MenuContainerAA_BorderLeft
            }
            DB_LeftSidebar_MenuContainerAA_BorderStyleLeft={
              DB_LeftSidebar_MenuContainerAA_BorderStyleLeft
            }
            DB_LeftSidebar_MenuContainerAA_BorderColorLeft={
              DB_LeftSidebar_MenuContainerAA_BorderColorLeft
            }
            DB_LeftSidebar_MenuContainerAA_BorderRight={
              DB_LeftSidebar_MenuContainerAA_BorderRight
            }
            DB_LeftSidebar_MenuContainerAA_BorderStyleRight={
              DB_LeftSidebar_MenuContainerAA_BorderStyleRight
            }
            DB_LeftSidebar_MenuContainerAA_BorderColorRight={
              DB_LeftSidebar_MenuContainerAA_BorderColorRight
            }
            DB_LeftSidebar_MenuItem_MenuContainerAA_BorderTopRightRadius={
              DB_LeftSidebar_MenuItem_MenuContainerAA_BorderTopRightRadius
            }
            DB_LeftSidebar_MenuItem_MenuContainerAA_BorderTopLeftRadius={
              DB_LeftSidebar_MenuItem_MenuContainerAA_BorderTopLeftRadius
            }
            DB_LeftSidebar_MenuItem_MenuContainerAA_BorderBottomRightRadius={
              DB_LeftSidebar_MenuItem_MenuContainerAA_BorderBottomRightRadius
            }
            DB_LeftSidebar_MenuItem_MenuContainerAA_BorderBottomLeftRadius={
              DB_LeftSidebar_MenuItem_MenuContainerAA_BorderBottomLeftRadius
            }
            DB_LeftSidebar_MenuItem_MenuContainerAA_paddingLeft={
              DB_LeftSidebar_MenuItem_MenuContainerAA_paddingLeft
            }
            DB_LeftSidebar_MenuItem_MenuContainerAA_paddingRight={
              DB_LeftSidebar_MenuItem_MenuContainerAA_paddingRight
            }
            DB_LeftSidebar_MenuItem_MenuContainerAA_paddingTop={
              DB_LeftSidebar_MenuItem_MenuContainerAA_paddingTop
            }
            DB_LeftSidebar_MenuItem_MenuContainerAA_paddingBottom={
              DB_LeftSidebar_MenuItem_MenuContainerAA_paddingBottom
            }
            DB_LeftSidebar_MenuItem_MenuContainerAA_ShadowHorizontal={
              DB_LeftSidebar_MenuItem_MenuContainerAA_ShadowHorizontal
            }
            DB_LeftSidebar_MenuItem_MenuContainerAA_ShadowVertical={
              DB_LeftSidebar_MenuItem_MenuContainerAA_ShadowVertical
            }
            DB_LeftSidebar_MenuItem_MenuContainerAA_ShadowBlur={
              DB_LeftSidebar_MenuItem_MenuContainerAA_ShadowBlur
            }
            DB_LeftSidebar_MenuItem_MenuContainerAA_ShadowColor={
              DB_LeftSidebar_MenuItem_MenuContainerAA_ShadowColor
            }
            DB_LeftSidebar_MenuContainerAA_Hover_BackgroundColor={
              DB_LeftSidebar_MenuContainerAA_Hover_BackgroundColor
            }
            DB_LeftSidebar_MenuContainerAA_Hover_PaddingTopBottom={
              DB_LeftSidebar_MenuContainerAA_Hover_PaddingTopBottom
            }
            DB_LeftSidebar_MenuContainerAA_Hover_PaddingLeftRight={
              DB_LeftSidebar_MenuContainerAA_Hover_PaddingLeftRight
            }
            DB_LeftSidebar_MenuContainerAA_Hover_BorderTop={
              DB_LeftSidebar_MenuContainerAA_Hover_BorderTop
            }
            DB_LeftSidebar_MenuContainerAA_Hover_BorderStyleTop={
              DB_LeftSidebar_MenuContainerAA_Hover_BorderStyleTop
            }
            DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderStyleTop={
              DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderStyleTop
            }
            DB_LeftSidebar_MenuContainerAA_Hover_BorderColorTop={
              DB_LeftSidebar_MenuContainerAA_Hover_BorderColorTop
            }
            DB_LeftSidebar_MenuContainerAA_Hover_BorderBottom={
              DB_LeftSidebar_MenuContainerAA_Hover_BorderBottom
            }
            DB_LeftSidebar_MenuContainerAA_Hover_BorderStyleBottom={
              DB_LeftSidebar_MenuContainerAA_Hover_BorderStyleBottom
            }
            DB_LeftSidebar_MenuContainerAA_Hover_BorderColorBottom={
              DB_LeftSidebar_MenuContainerAA_Hover_BorderColorBottom
            }
            DB_LeftSidebar_MenuContainerAA_Hover_BorderLeft={
              DB_LeftSidebar_MenuContainerAA_Hover_BorderLeft
            }
            DB_LeftSidebar_MenuContainerAA_Hover_BorderStyleLeft={
              DB_LeftSidebar_MenuContainerAA_Hover_BorderStyleLeft
            }
            DB_LeftSidebar_MenuContainerAA_Hover_BorderColorLeft={
              DB_LeftSidebar_MenuContainerAA_Hover_BorderColorLeft
            }
            DB_LeftSidebar_MenuContainerAA_Hover_BorderRight={
              DB_LeftSidebar_MenuContainerAA_Hover_BorderRight
            }
            DB_LeftSidebar_MenuContainerAA_Hover_BorderStyleRight={
              DB_LeftSidebar_MenuContainerAA_Hover_BorderStyleRight
            }
            DB_LeftSidebar_MenuContainerAA_Hover_BorderColorRight={
              DB_LeftSidebar_MenuContainerAA_Hover_BorderColorRight
            }
            DB_LeftSidebar_MenuItem_MenuContainerAA_Hover_BorderTopRightRadius={
              DB_LeftSidebar_MenuItem_MenuContainerAA_Hover_BorderTopRightRadius
            }
            DB_LeftSidebar_MenuItem_MenuContainerAA_Hover_BorderTopLeftRadius={
              DB_LeftSidebar_MenuItem_MenuContainerAA_Hover_BorderTopLeftRadius
            }
            DB_LeftSidebar_MenuItem_MenuContainerAA_Hover_BorderBottomRightRadius={
              DB_LeftSidebar_MenuItem_MenuContainerAA_Hover_BorderBottomRightRadius
            }
            DB_LeftSidebar_MenuItem_MenuContainerAA_Hover_BorderBottomLeftRadius={
              DB_LeftSidebar_MenuItem_MenuContainerAA_Hover_BorderBottomLeftRadius
            }
            DB_LeftSidebar_MenuItem_MenuContainerAA_Hover_paddingLeft={
              DB_LeftSidebar_MenuItem_MenuContainerAA_Hover_paddingLeft
            }
            DB_LeftSidebar_MenuItem_MenuContainerAA_Hover_paddingRight={
              DB_LeftSidebar_MenuItem_MenuContainerAA_Hover_paddingRight
            }
            DB_LeftSidebar_MenuItem_MenuContainerAA_Hover_paddingTop={
              DB_LeftSidebar_MenuItem_MenuContainerAA_Hover_paddingTop
            }
            DB_LeftSidebar_MenuItem_MenuContainerAA_Hover_paddingBottom={
              DB_LeftSidebar_MenuItem_MenuContainerAA_Hover_paddingBottom
            }
            DB_LeftSidebar_MenuItem_MenuContainerAA_Hover_ShadowHorizontal={
              DB_LeftSidebar_MenuItem_MenuContainerAA_Hover_ShadowHorizontal
            }
            DB_LeftSidebar_MenuItem_MenuContainerAA_Hover_ShadowVertical={
              DB_LeftSidebar_MenuItem_MenuContainerAA_Hover_ShadowVertical
            }
            DB_LeftSidebar_MenuItem_MenuContainerAA_Hover_ShadowBlur={
              DB_LeftSidebar_MenuItem_MenuContainerAA_Hover_ShadowBlur
            }
            DB_LeftSidebar_MenuItem_MenuContainerAA_Hover_ShadowColor={
              DB_LeftSidebar_MenuItem_MenuContainerAA_Hover_ShadowColor
            }
            DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderTop={
              DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderTop
            }
            DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderStyleTop={
              DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderStyleTop
            }
            DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderColorTop={
              DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderColorTop
            }
            DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderBottom={
              DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderBottom
            }
            DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderBottom={
              DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderBottom
            }
            DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderStyleBottom={
              DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderStyleBottom
            }
            DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderColorBottom={
              DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderColorBottom
            }
            DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderLeft={
              DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderLeft
            }
            DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderStyleLeft={
              DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderStyleLeft
            }
            DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderColorLeft={
              DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderColorLeft
            }
            DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderRight={
              DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderRight
            }
            DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderStyleRight={
              DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderStyleRight
            }
            DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderColorRight={
              DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderColorRight
            }
            DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderTopRightRadius={
              DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderTopRightRadius
            }
            DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderTopLeftRadius={
              DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderTopLeftRadius
            }
            DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderBottomRightRadius={
              DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderBottomRightRadius
            }
            DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderBottomLeftRadius={
              DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderBottomLeftRadius
            }
            DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderTop={
              DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderTop
            }
            DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderColorTop={
              DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderColorTop
            }
            DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderColorBottom={
              DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderColorBottom
            }
            DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderStyleBottom={
              DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderStyleBottom
            }
            DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderLeft={
              DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderLeft
            }
            DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderStyleLeft={
              DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderStyleLeft
            }
            DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderColorLeft={
              DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderColorLeft
            }
            DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderColorRight={
              DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderColorRight
            }
            DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderRight={
              DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderRight
            }
            DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderStyleRight={
              DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderStyleRight
            }
            DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderTopRightRadius={
              DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderTopRightRadius
            }
            DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderTopLeftRadius={
              DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderTopLeftRadius
            }
            DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderBottomRightRadius={
              DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderBottomRightRadius
            }
            DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderBottomLeftRadius={
              DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderBottomLeftRadius
            }
          />
        </Navbar>
      )}
      {/* <Container className="px-0">
        <section
          style={{
            backgroundColor: "rgba(0,0,0,0.2)",
            borderRadius: "10px",
          }}
        >
          <p className="text-muted py-2 mb-2 text-center">
            <small style={{ color: "rgba(255,255,255,1)" }}>
              Test Test Test moving Text
            </small>
          </p>
        </section>{" "}
      </Container> */}
      <Container className="text-center d-block d-md-none mb-0 mt-2">
        <Button className="topNavigationNavItemStatic" onClick={handleShow}>
          Filter anzeigen
        </Button>
      </Container>
      <section className="main" style={{ paddingBottom: "40px" }}>
        <Container className="container wholeBodyContainerBackgroundColor">
          {/*  <Row style={{ paddingTop: "10px" }}>
            <Col lg={12}>
              <div className="jumbotron">
                <h2>Hello, world!</h2>
                <p className="lead">
                  This is a simple hero unit, a simple jumbotron-style component
                  for calling extra attention to featured content or
                  information.
                </p>
              </div>
            </Col>
          </Row> */}

          <Row>
            {!leftCategoriesVisible &&
            !specialLeftFiltersVisible &&
            !specialLeftSocialsVisible &&
            !TopBarIsVisibleLeft &&
            !ProductFilterIsVisibleLeft &&
            !leftMainCategoriesVisible ? (
              ""
            ) : (
              <Col
                lg={
                  leftCategoriesVisible ||
                  specialLeftFiltersVisible ||
                  specialLeftSocialsVisible ||
                  TopBarIsVisibleLeft ||
                  ProductFilterIsVisibleLeft ||
                  leftMainCategoriesVisible
                    ? 2
                    : !leftCategoriesVisible &&
                      !specialLeftFiltersVisible &&
                      !specialLeftSocialsVisible &&
                      !TopBarIsVisibleLeft &&
                      !ProductFilterIsVisibleLeft &&
                      !leftMainCategoriesVisible
                    ? 0
                    : 12
                }
                className={`
              ${
                DB_OrderOfBodyElements === "012"
                  ? "order-lg-0 d-lg-block d-none mt-3 pe-0"
                  : DB_OrderOfBodyElements === "021"
                  ? "order-lg-0 d-lg-block d-none mt-3"
                  : DB_OrderOfBodyElements === "102"
                  ? "order-lg-1 d-lg-block d-none mt-3 ps-0"
                  : DB_OrderOfBodyElements === "120"
                  ? "order-lg-2 d-lg-block d-none mt-3"
                  : DB_OrderOfBodyElements === "201"
                  ? "order-lg-1 d-lg-block d-none mt-3 pe-0"
                  : DB_OrderOfBodyElements === "210"
                  ? "order-lg-2 d-lg-block d-none mt-3 ps-0"
                  : "order-lg-0 d-lg-block d-none mt-3 pe-0"
              }`}
              >
                {leftMainCategoriesVisible && !isMobile && (
                  <Navbar expand="lg">
                    <HauptMenueTopSpecial
                      ultraCategoriesNew={ultraCategoriesNew}
                      categoryLeftSideHandler={(e) => {
                        categoryLeftSideHandler(e);
                      }}
                      DB_fontFace_FontFamilyName={DB_fontFace_FontFamilyName}
                      SideBarLeft_HauptmenuTopAA_Farbe_Groesse_Stil_OnMouseOverAndOut={
                        SideBarLeft_HauptmenuTop_Farbe_Groesse_Stil_OnMouseOverAndOut
                      }
                      SideBarLeft_HauptmenuTopAA_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut={
                        SideBarLeft_HauptmenuTop_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
                      }
                      SideBarLeft_HauptmenuTopAA_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut={
                        SideBarLeft_HauptmenuTop_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut
                      }
                      SideBarLeft_HauptmenuTopAA_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut={
                        SideBarLeft_HauptmenuTop_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut
                      }
                      SideBarLeft_ContainerTopAA_Farbe_Groesse_Stil_OnMouseOverAndOut={
                        SideBarLeft_ContainerTop_Farbe_Groesse_Stil_OnMouseOverAndOut
                      }
                      SideBarLeft_ContainerTopAA_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut={
                        SideBarLeft_ContainerTop_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
                      }
                      SideBarLeft_ContainerTopAA_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut={
                        SideBarLeft_ContainerTop_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut
                      }
                      SideBarLeft_ContainerTopAA_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut={
                        SideBarLeft_ContainerTop_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut
                      }
                      DB_fontFace_hauptMenueTopAA_FontFamilyName={
                        DB_fontFace_hauptMenueTopAA_FontFamilyName
                      }
                      DB_fontFace_hauptMenueTopAA_FontFamilySrc={
                        DB_fontFace_hauptMenueTopAA_FontFamilySrc
                      }
                      DB_LeftSidebar_MenuContainerAA_BackgroundColor={
                        DB_LeftSidebar_MenuContainerAA_BackgroundColor
                      }
                      DB_LeftSidebar_MenuContainerAA_PaddingTopBottom={
                        DB_LeftSidebar_MenuContainerAA_PaddingTopBottom
                      }
                      DB_LeftSidebar_MenuContainerAA_PaddingLeftRight={
                        DB_LeftSidebar_MenuContainerAA_PaddingLeftRight
                      }
                      DB_LeftSidebar_MenuContainerAA_BorderTop={
                        DB_LeftSidebar_MenuContainerAA_BorderTop
                      }
                      DB_LeftSidebar_MenuContainerAA_BorderStyleTop={
                        DB_LeftSidebar_MenuContainerAA_BorderStyleTop
                      }
                      DB_LeftSidebar_MenuContainerAA_BorderColorTop={
                        DB_LeftSidebar_MenuContainerAA_BorderColorTop
                      }
                      DB_LeftSidebar_MenuContainerAA_BorderBottom={
                        DB_LeftSidebar_MenuContainerAA_BorderBottom
                      }
                      DB_LeftSidebar_MenuContainerAA_BorderStyleBottom={
                        DB_LeftSidebar_MenuContainerAA_BorderStyleBottom
                      }
                      DB_LeftSidebar_MenuContainerAA_BorderColorBottom={
                        DB_LeftSidebar_MenuContainerAA_BorderColorBottom
                      }
                      DB_LeftSidebar_MenuContainerAA_BorderLeft={
                        DB_LeftSidebar_MenuContainerAA_BorderLeft
                      }
                      DB_LeftSidebar_MenuContainerAA_BorderStyleLeft={
                        DB_LeftSidebar_MenuContainerAA_BorderStyleLeft
                      }
                      DB_LeftSidebar_MenuContainerAA_BorderColorLeft={
                        DB_LeftSidebar_MenuContainerAA_BorderColorLeft
                      }
                      DB_LeftSidebar_MenuContainerAA_BorderRight={
                        DB_LeftSidebar_MenuContainerAA_BorderRight
                      }
                      DB_LeftSidebar_MenuContainerAA_BorderStyleRight={
                        DB_LeftSidebar_MenuContainerAA_BorderStyleRight
                      }
                      DB_LeftSidebar_MenuContainerAA_BorderColorRight={
                        DB_LeftSidebar_MenuContainerAA_BorderColorRight
                      }
                      DB_LeftSidebar_MenuItem_MenuContainerAA_BorderTopRightRadius={
                        DB_LeftSidebar_MenuItem_MenuContainerAA_BorderTopRightRadius
                      }
                      DB_LeftSidebar_MenuItem_MenuContainerAA_BorderTopLeftRadius={
                        DB_LeftSidebar_MenuItem_MenuContainerAA_BorderTopLeftRadius
                      }
                      DB_LeftSidebar_MenuItem_MenuContainerAA_BorderBottomRightRadius={
                        DB_LeftSidebar_MenuItem_MenuContainerAA_BorderBottomRightRadius
                      }
                      DB_LeftSidebar_MenuItem_MenuContainerAA_BorderBottomLeftRadius={
                        DB_LeftSidebar_MenuItem_MenuContainerAA_BorderBottomLeftRadius
                      }
                      DB_LeftSidebar_MenuItem_MenuContainerAA_paddingLeft={
                        DB_LeftSidebar_MenuItem_MenuContainerAA_paddingLeft
                      }
                      DB_LeftSidebar_MenuItem_MenuContainerAA_paddingRight={
                        DB_LeftSidebar_MenuItem_MenuContainerAA_paddingRight
                      }
                      DB_LeftSidebar_MenuItem_MenuContainerAA_paddingTop={
                        DB_LeftSidebar_MenuItem_MenuContainerAA_paddingTop
                      }
                      DB_LeftSidebar_MenuItem_MenuContainerAA_paddingBottom={
                        DB_LeftSidebar_MenuItem_MenuContainerAA_paddingBottom
                      }
                      DB_LeftSidebar_MenuItem_MenuContainerAA_ShadowHorizontal={
                        DB_LeftSidebar_MenuItem_MenuContainerAA_ShadowHorizontal
                      }
                      DB_LeftSidebar_MenuItem_MenuContainerAA_ShadowVertical={
                        DB_LeftSidebar_MenuItem_MenuContainerAA_ShadowVertical
                      }
                      DB_LeftSidebar_MenuItem_MenuContainerAA_ShadowBlur={
                        DB_LeftSidebar_MenuItem_MenuContainerAA_ShadowBlur
                      }
                      DB_LeftSidebar_MenuItem_MenuContainerAA_ShadowColor={
                        DB_LeftSidebar_MenuItem_MenuContainerAA_ShadowColor
                      }
                      DB_LeftSidebar_MenuContainerAA_Hover_BackgroundColor={
                        DB_LeftSidebar_MenuContainerAA_Hover_BackgroundColor
                      }
                      DB_LeftSidebar_MenuContainerAA_Hover_PaddingTopBottom={
                        DB_LeftSidebar_MenuContainerAA_Hover_PaddingTopBottom
                      }
                      DB_LeftSidebar_MenuContainerAA_Hover_PaddingLeftRight={
                        DB_LeftSidebar_MenuContainerAA_Hover_PaddingLeftRight
                      }
                      DB_LeftSidebar_MenuContainerAA_Hover_BorderTop={
                        DB_LeftSidebar_MenuContainerAA_Hover_BorderTop
                      }
                      DB_LeftSidebar_MenuContainerAA_Hover_BorderStyleTop={
                        DB_LeftSidebar_MenuContainerAA_Hover_BorderStyleTop
                      }
                      DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderStyleTop={
                        DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderStyleTop
                      }
                      DB_LeftSidebar_MenuContainerAA_Hover_BorderColorTop={
                        DB_LeftSidebar_MenuContainerAA_Hover_BorderColorTop
                      }
                      DB_LeftSidebar_MenuContainerAA_Hover_BorderBottom={
                        DB_LeftSidebar_MenuContainerAA_Hover_BorderBottom
                      }
                      DB_LeftSidebar_MenuContainerAA_Hover_BorderStyleBottom={
                        DB_LeftSidebar_MenuContainerAA_Hover_BorderStyleBottom
                      }
                      DB_LeftSidebar_MenuContainerAA_Hover_BorderColorBottom={
                        DB_LeftSidebar_MenuContainerAA_Hover_BorderColorBottom
                      }
                      DB_LeftSidebar_MenuContainerAA_Hover_BorderLeft={
                        DB_LeftSidebar_MenuContainerAA_Hover_BorderLeft
                      }
                      DB_LeftSidebar_MenuContainerAA_Hover_BorderStyleLeft={
                        DB_LeftSidebar_MenuContainerAA_Hover_BorderStyleLeft
                      }
                      DB_LeftSidebar_MenuContainerAA_Hover_BorderColorLeft={
                        DB_LeftSidebar_MenuContainerAA_Hover_BorderColorLeft
                      }
                      DB_LeftSidebar_MenuContainerAA_Hover_BorderRight={
                        DB_LeftSidebar_MenuContainerAA_Hover_BorderRight
                      }
                      DB_LeftSidebar_MenuContainerAA_Hover_BorderStyleRight={
                        DB_LeftSidebar_MenuContainerAA_Hover_BorderStyleRight
                      }
                      DB_LeftSidebar_MenuContainerAA_Hover_BorderColorRight={
                        DB_LeftSidebar_MenuContainerAA_Hover_BorderColorRight
                      }
                      DB_LeftSidebar_MenuItem_MenuContainerAA_Hover_BorderTopRightRadius={
                        DB_LeftSidebar_MenuItem_MenuContainerAA_Hover_BorderTopRightRadius
                      }
                      DB_LeftSidebar_MenuItem_MenuContainerAA_Hover_BorderTopLeftRadius={
                        DB_LeftSidebar_MenuItem_MenuContainerAA_Hover_BorderTopLeftRadius
                      }
                      DB_LeftSidebar_MenuItem_MenuContainerAA_Hover_BorderBottomRightRadius={
                        DB_LeftSidebar_MenuItem_MenuContainerAA_Hover_BorderBottomRightRadius
                      }
                      DB_LeftSidebar_MenuItem_MenuContainerAA_Hover_BorderBottomLeftRadius={
                        DB_LeftSidebar_MenuItem_MenuContainerAA_Hover_BorderBottomLeftRadius
                      }
                      DB_LeftSidebar_MenuItem_MenuContainerAA_Hover_paddingLeft={
                        DB_LeftSidebar_MenuItem_MenuContainerAA_Hover_paddingLeft
                      }
                      DB_LeftSidebar_MenuItem_MenuContainerAA_Hover_paddingRight={
                        DB_LeftSidebar_MenuItem_MenuContainerAA_Hover_paddingRight
                      }
                      DB_LeftSidebar_MenuItem_MenuContainerAA_Hover_paddingTop={
                        DB_LeftSidebar_MenuItem_MenuContainerAA_Hover_paddingTop
                      }
                      DB_LeftSidebar_MenuItem_MenuContainerAA_Hover_paddingBottom={
                        DB_LeftSidebar_MenuItem_MenuContainerAA_Hover_paddingBottom
                      }
                      DB_LeftSidebar_MenuItem_MenuContainerAA_Hover_ShadowHorizontal={
                        DB_LeftSidebar_MenuItem_MenuContainerAA_Hover_ShadowHorizontal
                      }
                      DB_LeftSidebar_MenuItem_MenuContainerAA_Hover_ShadowVertical={
                        DB_LeftSidebar_MenuItem_MenuContainerAA_Hover_ShadowVertical
                      }
                      DB_LeftSidebar_MenuItem_MenuContainerAA_Hover_ShadowBlur={
                        DB_LeftSidebar_MenuItem_MenuContainerAA_Hover_ShadowBlur
                      }
                      DB_LeftSidebar_MenuItem_MenuContainerAA_Hover_ShadowColor={
                        DB_LeftSidebar_MenuItem_MenuContainerAA_Hover_ShadowColor
                      }
                      DB_LeftSidebar_MenuItem_HauptmenuTopAA_FontWeight={
                        DB_LeftSidebar_MenuItem_HauptmenuTopAA_FontWeight
                      }
                      DB_LeftSidebar_MenuItem_HauptmenuTopAA_FontColor={
                        DB_LeftSidebar_MenuItem_HauptmenuTopAA_FontColor
                      }
                      DB_LeftSidebar_MenuItem_HauptmenuTopAA_FontSize={
                        DB_LeftSidebar_MenuItem_HauptmenuTopAA_FontSize
                      }
                      DB_LeftSidebar_MenuItem_HauptmenuTopAA_BackgroundColor={
                        DB_LeftSidebar_MenuItem_HauptmenuTopAA_BackgroundColor
                      }
                      DB_LeftSidebar_MenuItem_HauptmenuTopAA_TextAlign={
                        DB_LeftSidebar_MenuItem_HauptmenuTopAA_TextAlign
                      }
                      DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderTop={
                        DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderTop
                      }
                      DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderStyleTop={
                        DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderStyleTop
                      }
                      DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderColorTop={
                        DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderColorTop
                      }
                      DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderBottom={
                        DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderBottom
                      }
                      DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderBottom={
                        DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderBottom
                      }
                      DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderStyleBottom={
                        DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderStyleBottom
                      }
                      DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderColorBottom={
                        DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderColorBottom
                      }
                      DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderLeft={
                        DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderLeft
                      }
                      DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderStyleLeft={
                        DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderStyleLeft
                      }
                      DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderColorLeft={
                        DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderColorLeft
                      }
                      DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderRight={
                        DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderRight
                      }
                      DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderStyleRight={
                        DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderStyleRight
                      }
                      DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderColorRight={
                        DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderColorRight
                      }
                      DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderTopRightRadius={
                        DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderTopRightRadius
                      }
                      DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderTopLeftRadius={
                        DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderTopLeftRadius
                      }
                      DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderBottomRightRadius={
                        DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderBottomRightRadius
                      }
                      DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderBottomLeftRadius={
                        DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderBottomLeftRadius
                      }
                      DB_LeftSidebar_MenuItem_HauptmenuTopAA_marginLeftRight={
                        DB_LeftSidebar_MenuItem_HauptmenuTopAA_marginLeftRight
                      }
                      DB_LeftSidebar_MenuItem_HauptmenuTopAA_marginTopBottom={
                        DB_LeftSidebar_MenuItem_HauptmenuTopAA_marginTopBottom
                      }
                      DB_LeftSidebar_MenuItem_HauptmenuTopAA_paddingLeftRight={
                        DB_LeftSidebar_MenuItem_HauptmenuTopAA_paddingLeftRight
                      }
                      DB_LeftSidebar_MenuItem_HauptmenuTopAA_paddingTopBottom={
                        DB_LeftSidebar_MenuItem_HauptmenuTopAA_paddingTopBottom
                      }
                      DB_LeftSidebar_MenuItem_HauptmenuTopAA_letterSpacing={
                        DB_LeftSidebar_MenuItem_HauptmenuTopAA_letterSpacing
                      }
                      DB_LeftSidebar_MenuItem_HauptmenuTopAA_ShadowHorizontal={
                        DB_LeftSidebar_MenuItem_HauptmenuTopAA_ShadowHorizontal
                      }
                      DB_LeftSidebar_MenuItem_HauptmenuTopAA_ShadowVertical={
                        DB_LeftSidebar_MenuItem_HauptmenuTopAA_ShadowVertical
                      }
                      DB_LeftSidebar_MenuItem_HauptmenuTopAA_ShadowBlur={
                        DB_LeftSidebar_MenuItem_HauptmenuTopAA_ShadowBlur
                      }
                      DB_LeftSidebar_MenuItem_HauptmenuTopAA_ShadowColor={
                        DB_LeftSidebar_MenuItem_HauptmenuTopAA_ShadowColor
                      }
                      DB_LeftSidebar_MenuItem_HauptmenuTopAA_TextDecoration={
                        DB_LeftSidebar_MenuItem_HauptmenuTopAA_TextDecoration
                      }
                      DB_LeftSidebar_MenuItem_HauptmenuTopAA_TextDecorationStyle={
                        DB_LeftSidebar_MenuItem_HauptmenuTopAA_TextDecorationStyle
                      }
                      DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_FontWeight={
                        DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_FontWeight
                      }
                      DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_FontColor={
                        DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_FontColor
                      }
                      DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_FontSize={
                        DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_FontSize
                      }
                      DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BackgroundColor={
                        DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BackgroundColor
                      }
                      DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_TextAlign={
                        DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_TextAlign
                      }
                      DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderTop={
                        DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderTop
                      }
                      DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderColorTop={
                        DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderColorTop
                      }
                      DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderColorBottom={
                        DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderColorBottom
                      }
                      DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderStyleBottom={
                        DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderStyleBottom
                      }
                      DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderLeft={
                        DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderLeft
                      }
                      DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderStyleLeft={
                        DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderStyleLeft
                      }
                      DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderColorLeft={
                        DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderColorLeft
                      }
                      DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderColorRight={
                        DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderColorRight
                      }
                      DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderRight={
                        DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderRight
                      }
                      DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderStyleRight={
                        DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderStyleRight
                      }
                      DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderTopRightRadius={
                        DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderTopRightRadius
                      }
                      DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderTopLeftRadius={
                        DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderTopLeftRadius
                      }
                      DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderBottomRightRadius={
                        DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderBottomRightRadius
                      }
                      DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderBottomLeftRadius={
                        DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderBottomLeftRadius
                      }
                      DB_LeftSidebar_MenuItem_HauptmenuTopAA_marginLeftRight_Hover={
                        DB_LeftSidebar_MenuItem_HauptmenuTopAA_marginLeftRight_Hover
                      }
                      DB_LeftSidebar_MenuItem_HauptmenuTopAA_marginTopBottom_Hover={
                        DB_LeftSidebar_MenuItem_HauptmenuTopAA_marginTopBottom_Hover
                      }
                      DB_LeftSidebar_MenuItem_HauptmenuTopAA_paddingLeftRight_Hover={
                        DB_LeftSidebar_MenuItem_HauptmenuTopAA_paddingLeftRight_Hover
                      }
                      DB_LeftSidebar_MenuItem_HauptmenuTopAA_paddingTopBottom_Hover={
                        DB_LeftSidebar_MenuItem_HauptmenuTopAA_paddingTopBottom_Hover
                      }
                      DB_LeftSidebar_MenuItem_HauptmenuTopAA_letterSpacing_Hover={
                        DB_LeftSidebar_MenuItem_HauptmenuTopAA_letterSpacing_Hover
                      }
                      DB_LeftSidebar_MenuItem_HauptmenuTopAA_ShadowHorizontal_Hover={
                        DB_LeftSidebar_MenuItem_HauptmenuTopAA_ShadowHorizontal_Hover
                      }
                      DB_LeftSidebar_MenuItem_HauptmenuTopAA_ShadowVertical_Hover={
                        DB_LeftSidebar_MenuItem_HauptmenuTopAA_ShadowVertical_Hover
                      }
                      DB_LeftSidebar_MenuItem_HauptmenuTopAA_ShadowBlur_Hover={
                        DB_LeftSidebar_MenuItem_HauptmenuTopAA_ShadowBlur_Hover
                      }
                      DB_LeftSidebar_MenuItem_HauptmenuTopAA_ShadowColor_Hover={
                        DB_LeftSidebar_MenuItem_HauptmenuTopAA_ShadowColor_Hover
                      }
                      DB_LeftSidebar_MenuItem_HauptmenuTopAA_TextDecoration_Hover={
                        DB_LeftSidebar_MenuItem_HauptmenuTopAA_TextDecoration_Hover
                      }
                      DB_LeftSidebar_MenuItem_HauptmenuTopAA_TextDecorationStyle_Hover={
                        DB_LeftSidebar_MenuItem_HauptmenuTopAA_TextDecorationStyle_Hover
                      }
                    />
                  </Navbar>
                )}
                {ProductFilterIsVisibleLeft && (
                  <ProductFilterTopBarSpecial
                    paginationStart={paginationStart}
                    paginationEnd={paginationEnd}
                    allProductsStatePagination={allProductsStatePagination}
                    productBefore={productBefore}
                    productNext={productNext}
                    columnNumber={columnNumber}
                    pageAnzahlCounter={pageAnzahlCounter}
                    DB_fontFace_FontFamilyName={DB_fontFace_FontFamilyName}
                    columnNumberChange={columnNumberChange}
                    changePreisAlphabeticUswHandler={
                      changePreisAlphabeticUswHandler
                    }
                    SideBarLeft_SortierungTop_Farbe_Groesse_Stil_OnMouseOverAndOut={
                      SideBarLeft_SortierungTop_Farbe_Groesse_Stil_OnMouseOverAndOut
                    }
                    SideBarLeft_SortierungTop_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut={
                      SideBarLeft_SortierungTop_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
                    }
                    SideBarLeft_SortierungTop_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut={
                      SideBarLeft_SortierungTop_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut
                    }
                    SideBarLeft_SortierungTop_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut={
                      SideBarLeft_SortierungTop_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut
                    }
                    DB_fontFace_FilterTopBar_FontFamilyName={
                      DB_fontFace_FilterTopBar_FontFamilyName
                    }
                    DB_fontFace_FilterTopBar_FontFamilySrc={
                      DB_fontFace_FilterTopBar_FontFamilySrc
                    }
                    DB_LeftSidebar_MenuItem_SortierungTop_BackgroundColor={
                      DB_LeftSidebar_MenuItem_SortierungTop_BackgroundColor
                    }
                    DB_LeftSidebar_MenuItem_SortierungTop_FontColor={
                      DB_LeftSidebar_MenuItem_SortierungTop_FontColor
                    }
                    DB_LeftSidebar_MenuItem_SortierungTop_BorderTop={
                      DB_LeftSidebar_MenuItem_SortierungTop_BorderTop
                    }
                    DB_LeftSidebar_MenuItem_SortierungTop_BorderStyleTop={
                      DB_LeftSidebar_MenuItem_SortierungTop_BorderStyleTop
                    }
                    DB_LeftSidebar_MenuItem_SortierungTop_BorderColorTop={
                      DB_LeftSidebar_MenuItem_SortierungTop_BorderColorTop
                    }
                    DB_LeftSidebar_MenuItem_SortierungTop_BorderBottom={
                      DB_LeftSidebar_MenuItem_SortierungTop_BorderBottom
                    }
                    DB_LeftSidebar_MenuItem_SortierungTop_BorderStyleBottom={
                      DB_LeftSidebar_MenuItem_SortierungTop_BorderStyleBottom
                    }
                    DB_LeftSidebar_MenuItem_SortierungTop_BorderColorBottom={
                      DB_LeftSidebar_MenuItem_SortierungTop_BorderColorBottom
                    }
                    DB_LeftSidebar_MenuItem_SortierungTop_BorderLeft={
                      DB_LeftSidebar_MenuItem_SortierungTop_BorderLeft
                    }
                    DB_LeftSidebar_MenuItem_SortierungTop_BorderStyleLeft={
                      DB_LeftSidebar_MenuItem_SortierungTop_BorderStyleLeft
                    }
                    DB_LeftSidebar_MenuItem_SortierungTop_BorderColorLeft={
                      DB_LeftSidebar_MenuItem_SortierungTop_BorderColorLeft
                    }
                    DB_LeftSidebar_MenuItem_SortierungTop_BorderRight={
                      DB_LeftSidebar_MenuItem_SortierungTop_BorderRight
                    }
                    DB_LeftSidebar_MenuItem_SortierungTop_BorderStyleRight={
                      DB_LeftSidebar_MenuItem_SortierungTop_BorderStyleRight
                    }
                    DB_LeftSidebar_MenuItem_SortierungTop_BorderColorRight={
                      DB_LeftSidebar_MenuItem_SortierungTop_BorderColorRight
                    }
                    DB_LeftSidebar_MenuItem_SortierungTop_BorderTopRightRadius={
                      DB_LeftSidebar_MenuItem_SortierungTop_BorderTopRightRadius
                    }
                    DB_LeftSidebar_MenuItem_SortierungTop_BorderTopLeftRadius={
                      DB_LeftSidebar_MenuItem_SortierungTop_BorderTopLeftRadius
                    }
                    DB_LeftSidebar_MenuItem_SortierungTop_BorderBottomRightRadius={
                      DB_LeftSidebar_MenuItem_SortierungTop_BorderBottomRightRadius
                    }
                    DB_LeftSidebar_MenuItem_SortierungTop_BorderBottomLeftRadius={
                      DB_LeftSidebar_MenuItem_SortierungTop_BorderBottomLeftRadius
                    }
                    DB_LeftSidebar_MenuItem_SortierungTop_ShadowHorizontal={
                      DB_LeftSidebar_MenuItem_SortierungTop_ShadowHorizontal
                    }
                    DB_LeftSidebar_MenuItem_SortierungTop_ShadowVertical={
                      DB_LeftSidebar_MenuItem_SortierungTop_ShadowVertical
                    }
                    DB_LeftSidebar_MenuItem_SortierungTop_ShadowBlur={
                      DB_LeftSidebar_MenuItem_SortierungTop_ShadowBlur
                    }
                    DB_LeftSidebar_MenuItem_SortierungTop_ShadowColor={
                      DB_LeftSidebar_MenuItem_SortierungTop_ShadowColor
                    }
                    DB_LeftSidebar_MenuItem_SortierungTop_Hover_BorderTop={
                      DB_LeftSidebar_MenuItem_SortierungTop_Hover_BorderTop
                    }
                    DB_LeftSidebar_MenuItem_SortierungTop_Hover_BorderStyleTop={
                      DB_LeftSidebar_MenuItem_SortierungTop_Hover_BorderStyleTop
                    }
                    DB_LeftSidebar_MenuItem_SortierungTop_Hover_BorderColorTop={
                      DB_LeftSidebar_MenuItem_SortierungTop_Hover_BorderColorTop
                    }
                    DB_LeftSidebar_MenuItem_SortierungTop_Hover_BorderBottom={
                      DB_LeftSidebar_MenuItem_SortierungTop_Hover_BorderBottom
                    }
                    DB_LeftSidebar_MenuItem_SortierungTop_Hover_BorderStyleBottom={
                      DB_LeftSidebar_MenuItem_SortierungTop_Hover_BorderStyleBottom
                    }
                    DB_LeftSidebar_MenuItem_SortierungTop_Hover_BorderColorBottom={
                      DB_LeftSidebar_MenuItem_SortierungTop_Hover_BorderColorBottom
                    }
                    DB_LeftSidebar_MenuItem_SortierungTop_Hover_BorderLeft={
                      DB_LeftSidebar_MenuItem_SortierungTop_Hover_BorderLeft
                    }
                    DB_LeftSidebar_MenuItem_SortierungTop_Hover_BorderStyleLeft={
                      DB_LeftSidebar_MenuItem_SortierungTop_Hover_BorderStyleLeft
                    }
                    DB_LeftSidebar_MenuItem_SortierungTop_Hover_BorderColorLeft={
                      DB_LeftSidebar_MenuItem_SortierungTop_Hover_BorderColorLeft
                    }
                    DB_LeftSidebar_MenuItem_SortierungTop_Hover_BorderRight={
                      DB_LeftSidebar_MenuItem_SortierungTop_Hover_BorderRight
                    }
                    DB_LeftSidebar_MenuItem_SortierungTop_Hover_BorderStyleRight={
                      DB_LeftSidebar_MenuItem_SortierungTop_Hover_BorderStyleRight
                    }
                    DB_LeftSidebar_MenuItem_SortierungTop_Hover_BorderColorRight={
                      DB_LeftSidebar_MenuItem_SortierungTop_Hover_BorderColorRight
                    }
                    DB_LeftSidebar_MenuItem_SortierungTop_Hover_BorderTopRightRadius={
                      DB_LeftSidebar_MenuItem_SortierungTop_Hover_BorderTopRightRadius
                    }
                    DB_LeftSidebar_MenuItem_SortierungTop_Hover_BorderTopLeftRadius={
                      DB_LeftSidebar_MenuItem_SortierungTop_Hover_BorderTopLeftRadius
                    }
                    DB_LeftSidebar_MenuItem_SortierungTop_Hover_BorderBottomRightRadius={
                      DB_LeftSidebar_MenuItem_SortierungTop_Hover_BorderBottomRightRadius
                    }
                    DB_LeftSidebar_MenuItem_SortierungTop_Hover_BorderBottomLeftRadius={
                      DB_LeftSidebar_MenuItem_SortierungTop_Hover_BorderBottomLeftRadius
                    }
                    DB_LeftSidebar_MenuItem_SortierungTop_Hover_ShadowHorizontal={
                      DB_LeftSidebar_MenuItem_SortierungTop_Hover_ShadowHorizontal
                    }
                    DB_LeftSidebar_MenuItem_SortierungTop_Hover_ShadowVertical={
                      DB_LeftSidebar_MenuItem_SortierungTop_Hover_ShadowVertical
                    }
                    DB_LeftSidebar_MenuItem_SortierungTop_Hover_ShadowBlur={
                      DB_LeftSidebar_MenuItem_SortierungTop_Hover_ShadowBlur
                    }
                    DB_LeftSidebar_MenuItem_SortierungTop_Hover_ShadowColor={
                      DB_LeftSidebar_MenuItem_SortierungTop_Hover_ShadowColor
                    }
                    DB_LeftSidebar_MenuItem_SortierungTop_Hover_FontColor={
                      DB_LeftSidebar_MenuItem_SortierungTop_Hover_FontColor
                    }
                    DB_LeftSidebar_MenuItem_SortierungTop_Hover_BackgroundColor={
                      DB_LeftSidebar_MenuItem_SortierungTop_Hover_BackgroundColor
                    }
                  />
                )}

                {TopBarIsVisibleLeft && !isMobile && (
                  <>
                    <Navbar
                      expand="lg"
                      className="p-0"
                      style={{
                        marginLeft: "auto",
                        marginRight: "auto",
                      }}
                    >
                      <TopBarStaticSpecialLeftRight
                        meldenModalHandler={meldenModalHandler}
                        faqDetailItem={faqDetailItem}
                        aboutUsShowHandler={aboutUsShowHandler}
                        faqShowHandler={faqShowHandler}
                        logoActive={logoActive}
                        countImagePlusTwo={countImagePlusTwo}
                        shopName={shopName}
                        resetFilterHandler={resetFilterHandler}
                        searchValue={searchValue}
                        searchHandler={searchHandler}
                        searchPressEnter={searchPressEnter}
                        isAuthState={isAuthState}
                        signOutMessage={signOutMessage}
                        productsUserId={productsUserId}
                        showTopProductsHandler={showTopProductsHandler}
                        toggleSignIn={toggleSignIn}
                        /* handleShow_showConfig_Main={handleShow_showConfig_Main} */
                        userOwnsThisSite={userOwnsThisSite}
                        DB_fontFace_FontFamilyName={DB_fontFace_FontFamilyName}
                        SideBarLeft_HauptmenuTopStatic_Farbe_Groesse_Stil_Trigger={
                          SideBarLeft_HauptmenuTopStatic_Farbe_Groesse_Stil_Trigger
                        }
                        SideBarLeft_HauptmenuTopStatic_Farbe_Groesse_Stil_Hover_Trigger={
                          SideBarLeft_HauptmenuTopStatic_Farbe_Groesse_Stil_Hover_Trigger
                        }
                        SideBarLeft_HauptmenuTopStatic_HintergrundFarbe_Radius_Stil_Trigger={
                          SideBarLeft_HauptmenuTopStatic_HintergrundFarbe_Radius_Stil_Trigger
                        }
                        SideBarLeft_HauptmenuTopStatic_HintergrundFarbe_Radius_Stil_Hover_Trigger={
                          SideBarLeft_HauptmenuTopStatic_HintergrundFarbe_Radius_Stil_Hover_Trigger
                        }
                        SideBarLeft_HauptmenuTopStatic_Farbe_Groesse_Stil_OnMouseOverAndOut={
                          SideBarLeft_HauptmenuTopStatic_Farbe_Groesse_Stil_OnMouseOverAndOut
                        }
                        SideBarLeft_HauptmenuTopStatic_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut={
                          SideBarLeft_HauptmenuTopStatic_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
                        }
                        SideBarLeft_HauptmenuTopStatic_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut={
                          SideBarLeft_HauptmenuTopStatic_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut
                        }
                        SideBarLeft_HauptmenuTopStatic_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut={
                          SideBarLeft_HauptmenuTopStatic_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut
                        }
                        DB_fontFace_hauptMenueStatic_FontFamilyName={
                          DB_fontFace_hauptMenueStatic_FontFamilyName
                        }
                        DB_fontFace_hauptMenueStatic_FontFamilySrc={
                          DB_fontFace_hauptMenueStatic_FontFamilySrc
                        }
                        DB_LeftSidebar_MenuItem_HauptmenuTop_FontWeight={
                          DB_LeftSidebar_MenuItem_HauptmenuTop_FontWeight
                        }
                        DB_LeftSidebar_MenuItem_HauptmenuTop_FontColor={
                          DB_LeftSidebar_MenuItem_HauptmenuTop_FontColor
                        }
                        DB_LeftSidebar_MenuItem_HauptmenuTop_FontSize={
                          DB_LeftSidebar_MenuItem_HauptmenuTop_FontSize
                        }
                        DB_LeftSidebar_MenuItem_HauptmenuTop_BackgroundColor={
                          DB_LeftSidebar_MenuItem_HauptmenuTop_BackgroundColor
                        }
                        DB_LeftSidebar_MenuItem_HauptmenuTop_TextAlign={
                          DB_LeftSidebar_MenuItem_HauptmenuTop_TextAlign
                        }
                        DB_LeftSidebar_MenuItem_HauptmenuTop_BorderTop={
                          DB_LeftSidebar_MenuItem_HauptmenuTop_BorderTop
                        }
                        DB_LeftSidebar_MenuItem_HauptmenuTop_BorderStyleTop={
                          DB_LeftSidebar_MenuItem_HauptmenuTop_BorderStyleTop
                        }
                        DB_LeftSidebar_MenuItem_HauptmenuTop_BorderColorTop={
                          DB_LeftSidebar_MenuItem_HauptmenuTop_BorderColorTop
                        }
                        DB_LeftSidebar_MenuItem_HauptmenuTop_BorderBottom={
                          DB_LeftSidebar_MenuItem_HauptmenuTop_BorderBottom
                        }
                        DB_LeftSidebar_MenuItem_HauptmenuTop_BorderStyleBottom={
                          DB_LeftSidebar_MenuItem_HauptmenuTop_BorderStyleBottom
                        }
                        DB_LeftSidebar_MenuItem_HauptmenuTop_BorderColorBottom={
                          DB_LeftSidebar_MenuItem_HauptmenuTop_BorderColorBottom
                        }
                        DB_LeftSidebar_MenuItem_HauptmenuTop_BorderLeft={
                          DB_LeftSidebar_MenuItem_HauptmenuTop_BorderLeft
                        }
                        DB_LeftSidebar_MenuItem_HauptmenuTop_BorderStyleLeft={
                          DB_LeftSidebar_MenuItem_HauptmenuTop_BorderStyleLeft
                        }
                        DB_LeftSidebar_MenuItem_HauptmenuTop_BorderColorLeft={
                          DB_LeftSidebar_MenuItem_HauptmenuTop_BorderColorLeft
                        }
                        DB_LeftSidebar_MenuItem_HauptmenuTop_BorderRight={
                          DB_LeftSidebar_MenuItem_HauptmenuTop_BorderRight
                        }
                        DB_LeftSidebar_MenuItem_HauptmenuTop_BorderStyleRight={
                          DB_LeftSidebar_MenuItem_HauptmenuTop_BorderStyleRight
                        }
                        DB_LeftSidebar_MenuItem_HauptmenuTop_BorderColorRight={
                          DB_LeftSidebar_MenuItem_HauptmenuTop_BorderColorRight
                        }
                        DB_LeftSidebar_MenuItem_HauptmenuTop_BorderTopRightRadius={
                          DB_LeftSidebar_MenuItem_HauptmenuTop_BorderTopRightRadius
                        }
                        DB_LeftSidebar_MenuItem_HauptmenuTop_BorderTopLeftRadius={
                          DB_LeftSidebar_MenuItem_HauptmenuTop_BorderTopLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_HauptmenuTop_BorderBottomRightRadius={
                          DB_LeftSidebar_MenuItem_HauptmenuTop_BorderBottomRightRadius
                        }
                        DB_LeftSidebar_MenuItem_HauptmenuTop_BorderBottomLeftRadius={
                          DB_LeftSidebar_MenuItem_HauptmenuTop_BorderBottomLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_HauptmenuTop_marginLeftRight={
                          DB_LeftSidebar_MenuItem_HauptmenuTop_marginLeftRight
                        }
                        DB_LeftSidebar_MenuItem_HauptmenuTop_marginTopBottom={
                          DB_LeftSidebar_MenuItem_HauptmenuTop_marginTopBottom
                        }
                        DB_LeftSidebar_MenuItem_HauptmenuTop_paddingLeftRight={
                          DB_LeftSidebar_MenuItem_HauptmenuTop_paddingLeftRight
                        }
                        DB_LeftSidebar_MenuItem_HauptmenuTop_paddingTopBottom={
                          DB_LeftSidebar_MenuItem_HauptmenuTop_paddingTopBottom
                        }
                        DB_LeftSidebar_MenuItem_HauptmenuTop_letterSpacing={
                          DB_LeftSidebar_MenuItem_HauptmenuTop_letterSpacing
                        }
                        DB_LeftSidebar_MenuItem_HauptmenuTop_ShadowHorizontal={
                          DB_LeftSidebar_MenuItem_HauptmenuTop_ShadowHorizontal
                        }
                        DB_LeftSidebar_MenuItem_HauptmenuTop_ShadowVertical={
                          DB_LeftSidebar_MenuItem_HauptmenuTop_ShadowVertical
                        }
                        DB_LeftSidebar_MenuItem_HauptmenuTop_ShadowBlur={
                          DB_LeftSidebar_MenuItem_HauptmenuTop_ShadowBlur
                        }
                        DB_LeftSidebar_MenuItem_HauptmenuTop_ShadowColor={
                          DB_LeftSidebar_MenuItem_HauptmenuTop_ShadowColor
                        }
                        DB_LeftSidebar_MenuItem_HauptmenuTop_TextDecoration={
                          DB_LeftSidebar_MenuItem_HauptmenuTop_TextDecoration
                        }
                        DB_LeftSidebar_MenuItem_HauptmenuTop_TextDecorationStyle={
                          DB_LeftSidebar_MenuItem_HauptmenuTop_TextDecorationStyle
                        }
                        DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_FontWeight={
                          DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_FontWeight
                        }
                        DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_FontColor={
                          DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_FontColor
                        }
                        DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_FontSize={
                          DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_FontSize
                        }
                        DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BackgroundColor={
                          DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BackgroundColor
                        }
                        DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_TextAlign={
                          DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_TextAlign
                        }
                        DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderTop={
                          DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderTop
                        }
                        DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderStyleTop={
                          DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderStyleTop
                        }
                        DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderColorTop={
                          DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderColorTop
                        }
                        DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderBottom={
                          DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderBottom
                        }
                        DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderStyleBottom={
                          DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderStyleBottom
                        }
                        DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderColorBottom={
                          DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderColorBottom
                        }
                        DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderLeft={
                          DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderLeft
                        }
                        DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderStyleLeft={
                          DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderStyleLeft
                        }
                        DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderColorLeft={
                          DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderColorLeft
                        }
                        DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderRight={
                          DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderRight
                        }
                        DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderStyleRight={
                          DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderStyleRight
                        }
                        DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderColorRight={
                          DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderColorRight
                        }
                        DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderTopRightRadius={
                          DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderTopRightRadius
                        }
                        DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderTopLeftRadius={
                          DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderTopLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderBottomRightRadius={
                          DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderBottomRightRadius
                        }
                        DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderBottomLeftRadius={
                          DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderBottomLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_HauptmenuTop_marginLeftRight_Hover={
                          DB_LeftSidebar_MenuItem_HauptmenuTop_marginLeftRight_Hover
                        }
                        DB_LeftSidebar_MenuItem_HauptmenuTop_marginTopBottom_Hover={
                          DB_LeftSidebar_MenuItem_HauptmenuTop_marginTopBottom_Hover
                        }
                        DB_LeftSidebar_MenuItem_HauptmenuTop_paddingLeftRight_Hover={
                          DB_LeftSidebar_MenuItem_HauptmenuTop_paddingLeftRight_Hover
                        }
                        DB_LeftSidebar_MenuItem_HauptmenuTop_paddingTopBottom_Hover={
                          DB_LeftSidebar_MenuItem_HauptmenuTop_paddingTopBottom_Hover
                        }
                        DB_LeftSidebar_MenuItem_HauptmenuTop_letterSpacing_Hover={
                          DB_LeftSidebar_MenuItem_HauptmenuTop_letterSpacing_Hover
                        }
                        DB_LeftSidebar_MenuItem_HauptmenuTop_ShadowHorizontal_Hover={
                          DB_LeftSidebar_MenuItem_HauptmenuTop_ShadowHorizontal_Hover
                        }
                        DB_LeftSidebar_MenuItem_HauptmenuTop_ShadowVertical_Hover={
                          DB_LeftSidebar_MenuItem_HauptmenuTop_ShadowVertical_Hover
                        }
                        DB_LeftSidebar_MenuItem_HauptmenuTop_ShadowBlur_Hover={
                          DB_LeftSidebar_MenuItem_HauptmenuTop_ShadowBlur_Hover
                        }
                        DB_LeftSidebar_MenuItem_HauptmenuTop_ShadowColor_Hover={
                          DB_LeftSidebar_MenuItem_HauptmenuTop_ShadowColor_Hover
                        }
                        DB_LeftSidebar_MenuItem_HauptmenuTop_TextDecoration_Hover={
                          DB_LeftSidebar_MenuItem_HauptmenuTop_TextDecoration_Hover
                        }
                        DB_LeftSidebar_MenuItem_HauptmenuTop_TextDecorationStyle_Hover={
                          DB_LeftSidebar_MenuItem_HauptmenuTop_TextDecorationStyle_Hover
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Logo_DDpaddingLeft={
                          DB_LeftSidebar_MenuItem_MenuContainer_Logo_DDpaddingLeft
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Logo_DDpaddingRight={
                          DB_LeftSidebar_MenuItem_MenuContainer_Logo_DDpaddingRight
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Logo_DDpaddingTop={
                          DB_LeftSidebar_MenuItem_MenuContainer_Logo_DDpaddingTop
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Logo_DDpaddingBottom={
                          DB_LeftSidebar_MenuItem_MenuContainer_Logo_DDpaddingBottom
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Logo_DDShadowHorizontal={
                          DB_LeftSidebar_MenuItem_MenuContainer_Logo_DDShadowHorizontal
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Logo_DDShadowVertical={
                          DB_LeftSidebar_MenuItem_MenuContainer_Logo_DDShadowVertical
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Logo_DDHeight={
                          DB_LeftSidebar_MenuItem_MenuContainer_Logo_DDHeight
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Logo_DDShadowBlur={
                          DB_LeftSidebar_MenuItem_MenuContainer_Logo_DDShadowBlur
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Logo_DDShadowColor={
                          DB_LeftSidebar_MenuItem_MenuContainer_Logo_DDShadowColor
                        }
                        DB_LeftSidebar_MenuContainer_Logo_DDBackgroundColor={
                          DB_LeftSidebar_MenuContainer_Logo_DDBackgroundColor
                        }
                        SideBarLeft_ContainerTopXYZ_Farbe_Groesse_Stil_OnMouseOverAndOut={
                          SideBarLeft_ContainerTopXYZ_Farbe_Groesse_Stil_OnMouseOverAndOut
                        }
                        SideBarLeft_ContainerTopXYZ_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut={
                          SideBarLeft_ContainerTopXYZ_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
                        }
                        SideBarLeft_ContainerTopXYZ_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut={
                          SideBarLeft_ContainerTopXYZ_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut
                        }
                        SideBarLeft_ContainerTopXYZ_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut={
                          SideBarLeft_ContainerTopXYZ_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut
                        }
                        DB_LeftSidebar_MenuContainerXYZ_BackgroundColor={
                          DB_LeftSidebar_MenuContainerXYZ_BackgroundColor
                        }
                        DB_LeftSidebar_MenuContainerXYZ_PaddingTopBottom={
                          DB_LeftSidebar_MenuContainerXYZ_PaddingTopBottom
                        }
                        DB_LeftSidebar_MenuContainerXYZ_PaddingLeftRight={
                          DB_LeftSidebar_MenuContainerXYZ_PaddingLeftRight
                        }
                        DB_LeftSidebar_MenuContainerXYZ_BorderTop={
                          DB_LeftSidebar_MenuContainerXYZ_BorderTop
                        }
                        DB_LeftSidebar_MenuContainerXYZ_BorderStyleTop={
                          DB_LeftSidebar_MenuContainerXYZ_BorderStyleTop
                        }
                        DB_LeftSidebar_MenuContainerXYZ_BorderColorTop={
                          DB_LeftSidebar_MenuContainerXYZ_BorderColorTop
                        }
                        DB_LeftSidebar_MenuContainerXYZ_BorderBottom={
                          DB_LeftSidebar_MenuContainerXYZ_BorderBottom
                        }
                        DB_LeftSidebar_MenuContainerXYZ_BorderStyleBottom={
                          DB_LeftSidebar_MenuContainerXYZ_BorderStyleBottom
                        }
                        DB_LeftSidebar_MenuContainerXYZ_BorderColorBottom={
                          DB_LeftSidebar_MenuContainerXYZ_BorderColorBottom
                        }
                        DB_LeftSidebar_MenuContainerXYZ_BorderLeft={
                          DB_LeftSidebar_MenuContainerXYZ_BorderLeft
                        }
                        DB_LeftSidebar_MenuContainerXYZ_BorderStyleLeft={
                          DB_LeftSidebar_MenuContainerXYZ_BorderStyleLeft
                        }
                        DB_LeftSidebar_MenuContainerXYZ_BorderColorLeft={
                          DB_LeftSidebar_MenuContainerXYZ_BorderColorLeft
                        }
                        DB_LeftSidebar_MenuContainerXYZ_BorderRight={
                          DB_LeftSidebar_MenuContainerXYZ_BorderRight
                        }
                        DB_LeftSidebar_MenuContainerXYZ_BorderStyleRight={
                          DB_LeftSidebar_MenuContainerXYZ_BorderStyleRight
                        }
                        DB_LeftSidebar_MenuContainerXYZ_BorderColorRight={
                          DB_LeftSidebar_MenuContainerXYZ_BorderColorRight
                        }
                        DB_LeftSidebar_MenuItem_MenuContainerXYZ_BorderTopRightRadius={
                          DB_LeftSidebar_MenuItem_MenuContainerXYZ_BorderTopRightRadius
                        }
                        DB_LeftSidebar_MenuItem_MenuContainerXYZ_BorderTopLeftRadius={
                          DB_LeftSidebar_MenuItem_MenuContainerXYZ_BorderTopLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_MenuContainerXYZ_BorderBottomRightRadius={
                          DB_LeftSidebar_MenuItem_MenuContainerXYZ_BorderBottomRightRadius
                        }
                        DB_LeftSidebar_MenuItem_MenuContainerXYZ_BorderBottomLeftRadius={
                          DB_LeftSidebar_MenuItem_MenuContainerXYZ_BorderBottomLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_MenuContainerXYZ_paddingLeft={
                          DB_LeftSidebar_MenuItem_MenuContainerXYZ_paddingLeft
                        }
                        DB_LeftSidebar_MenuItem_MenuContainerXYZ_paddingRight={
                          DB_LeftSidebar_MenuItem_MenuContainerXYZ_paddingRight
                        }
                        DB_LeftSidebar_MenuItem_MenuContainerXYZ_paddingTop={
                          DB_LeftSidebar_MenuItem_MenuContainerXYZ_paddingTop
                        }
                        DB_LeftSidebar_MenuItem_MenuContainerXYZ_paddingBottom={
                          DB_LeftSidebar_MenuItem_MenuContainerXYZ_paddingBottom
                        }
                        DB_LeftSidebar_MenuItem_MenuContainerXYZ_ShadowHorizontal={
                          DB_LeftSidebar_MenuItem_MenuContainerXYZ_ShadowHorizontal
                        }
                        DB_LeftSidebar_MenuItem_MenuContainerXYZ_ShadowVertical={
                          DB_LeftSidebar_MenuItem_MenuContainerXYZ_ShadowVertical
                        }
                        DB_LeftSidebar_MenuItem_MenuContainerXYZ_ShadowBlur={
                          DB_LeftSidebar_MenuItem_MenuContainerXYZ_ShadowBlur
                        }
                        DB_LeftSidebar_MenuItem_MenuContainerXYZ_ShadowColor={
                          DB_LeftSidebar_MenuItem_MenuContainerXYZ_ShadowColor
                        }
                        DB_LeftSidebar_MenuContainerXYZ_Hover_BackgroundColor={
                          DB_LeftSidebar_MenuContainerXYZ_Hover_BackgroundColor
                        }
                        DB_LeftSidebar_MenuContainerXYZ_Hover_PaddingTopBottom={
                          DB_LeftSidebar_MenuContainerXYZ_Hover_PaddingTopBottom
                        }
                        DB_LeftSidebar_MenuContainerXYZ_Hover_PaddingLeftRight={
                          DB_LeftSidebar_MenuContainerXYZ_Hover_PaddingLeftRight
                        }
                        DB_LeftSidebar_MenuContainerXYZ_Hover_BorderTop={
                          DB_LeftSidebar_MenuContainerXYZ_Hover_BorderTop
                        }
                        DB_LeftSidebar_MenuContainerXYZ_Hover_BorderStyleTop={
                          DB_LeftSidebar_MenuContainerXYZ_Hover_BorderStyleTop
                        }
                        DB_LeftSidebar_MenuContainerXYZ_Hover_BorderColorTop={
                          DB_LeftSidebar_MenuContainerXYZ_Hover_BorderColorTop
                        }
                        DB_LeftSidebar_MenuContainerXYZ_Hover_BorderBottom={
                          DB_LeftSidebar_MenuContainerXYZ_Hover_BorderBottom
                        }
                        DB_LeftSidebar_MenuContainerXYZ_Hover_BorderStyleBottom={
                          DB_LeftSidebar_MenuContainerXYZ_Hover_BorderStyleBottom
                        }
                        DB_LeftSidebar_MenuContainerXYZ_Hover_BorderColorBottom={
                          DB_LeftSidebar_MenuContainerXYZ_Hover_BorderColorBottom
                        }
                        DB_LeftSidebar_MenuContainerXYZ_Hover_BorderLeft={
                          DB_LeftSidebar_MenuContainerXYZ_Hover_BorderLeft
                        }
                        DB_LeftSidebar_MenuContainerXYZ_Hover_BorderStyleLeft={
                          DB_LeftSidebar_MenuContainerXYZ_Hover_BorderStyleLeft
                        }
                        DB_LeftSidebar_MenuContainerXYZ_Hover_BorderColorLeft={
                          DB_LeftSidebar_MenuContainerXYZ_Hover_BorderColorLeft
                        }
                        DB_LeftSidebar_MenuContainerXYZ_Hover_BorderRight={
                          DB_LeftSidebar_MenuContainerXYZ_Hover_BorderRight
                        }
                        DB_LeftSidebar_MenuContainerXYZ_Hover_BorderStyleRight={
                          DB_LeftSidebar_MenuContainerXYZ_Hover_BorderStyleRight
                        }
                        DB_LeftSidebar_MenuContainerXYZ_Hover_BorderColorRight={
                          DB_LeftSidebar_MenuContainerXYZ_Hover_BorderColorRight
                        }
                        DB_LeftSidebar_MenuItem_MenuContainerXYZ_Hover_BorderTopRightRadius={
                          DB_LeftSidebar_MenuItem_MenuContainerXYZ_Hover_BorderTopRightRadius
                        }
                        DB_LeftSidebar_MenuItem_MenuContainerXYZ_Hover_BorderTopLeftRadius={
                          DB_LeftSidebar_MenuItem_MenuContainerXYZ_Hover_BorderTopLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_MenuContainerXYZ_Hover_BorderBottomRightRadius={
                          DB_LeftSidebar_MenuItem_MenuContainerXYZ_Hover_BorderBottomRightRadius
                        }
                        DB_LeftSidebar_MenuItem_MenuContainerXYZ_Hover_BorderBottomLeftRadius={
                          DB_LeftSidebar_MenuItem_MenuContainerXYZ_Hover_BorderBottomLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_MenuContainerXYZ_Hover_paddingLeft={
                          DB_LeftSidebar_MenuItem_MenuContainerXYZ_Hover_paddingLeft
                        }
                        DB_LeftSidebar_MenuItem_MenuContainerXYZ_Hover_paddingRight={
                          DB_LeftSidebar_MenuItem_MenuContainerXYZ_Hover_paddingRight
                        }
                        DB_LeftSidebar_MenuItem_MenuContainerXYZ_Hover_paddingTop={
                          DB_LeftSidebar_MenuItem_MenuContainerXYZ_Hover_paddingTop
                        }
                        DB_LeftSidebar_MenuItem_MenuContainerXYZ_Hover_paddingBottom={
                          DB_LeftSidebar_MenuItem_MenuContainerXYZ_Hover_paddingBottom
                        }
                        DB_LeftSidebar_MenuItem_MenuContainerXYZ_Hover_ShadowHorizontal={
                          DB_LeftSidebar_MenuItem_MenuContainerXYZ_Hover_ShadowHorizontal
                        }
                        DB_LeftSidebar_MenuItem_MenuContainerXYZ_Hover_ShadowVertical={
                          DB_LeftSidebar_MenuItem_MenuContainerXYZ_Hover_ShadowVertical
                        }
                        DB_LeftSidebar_MenuItem_MenuContainerXYZ_Hover_ShadowBlur={
                          DB_LeftSidebar_MenuItem_MenuContainerXYZ_Hover_ShadowBlur
                        }
                        DB_LeftSidebar_MenuItem_MenuContainerXYZ_Hover_ShadowColor={
                          DB_LeftSidebar_MenuItem_MenuContainerXYZ_Hover_ShadowColor
                        }
                      />
                    </Navbar>
                  </>
                )}
                {leftCategoriesVisible && (
                  <SideBarLeftKategories
                    trace={traceCounter}
                    resetFilterHandler={resetFilterHandler}
                    ultraCategoriesNew={ultraCategoriesNew}
                    categoryLeftSideHandler={categoryLeftSideHandler}
                    SideBarLeft_Container_Farbe_Groesse_Stil_OnMouseOverAndOut={
                      SideBarLeft_Container_Farbe_Groesse_Stil_OnMouseOverAndOut
                    }
                    SideBarLeft_Container_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut={
                      SideBarLeft_Container_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
                    }
                    SideBarLeft_Container_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut={
                      SideBarLeft_Container_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut
                    }
                    SideBarLeft_Container_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut={
                      SideBarLeft_Container_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut
                    }
                    SideBarLeft_AlleProdukte_Farbe_Groesse_Stil_OnMouseOverAndOut={
                      SideBarLeft_AlleProdukte_Farbe_Groesse_Stil_OnMouseOverAndOut
                    }
                    SideBarLeft_AlleProdukte_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut={
                      SideBarLeft_AlleProdukte_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
                    }
                    SideBarLeft_AlleProdukte_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut={
                      SideBarLeft_AlleProdukte_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut
                    }
                    SideBarLeft_AlleProdukte_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut={
                      SideBarLeft_AlleProdukte_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut
                    }
                    SideBarLeft_Hauptkategorie_Farbe_Groesse_Stil_OnMouseOverAndOut={
                      SideBarLeft_Hauptkategorie_Farbe_Groesse_Stil_OnMouseOverAndOut
                    }
                    SideBarLeft_Hauptkategorie_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut={
                      SideBarLeft_Hauptkategorie_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
                    }
                    SideBarLeft_Hauptkategorie_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut={
                      SideBarLeft_Hauptkategorie_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut
                    }
                    SideBarLeft_Hauptkategorie_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut={
                      SideBarLeft_Hauptkategorie_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut
                    }
                    SideBarLeft_Unterkategorie_Farbe_Groesse_Stil_OnMouseOverAndOut={
                      SideBarLeft_Unterkategorie_Farbe_Groesse_Stil_OnMouseOverAndOut
                    }
                    SideBarLeft_Unterkategorie_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut={
                      SideBarLeft_Unterkategorie_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
                    }
                    SideBarLeft_Unterkategorie_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut={
                      SideBarLeft_Unterkategorie_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut
                    }
                    SideBarLeft_Unterkategorie_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut={
                      SideBarLeft_Unterkategorie_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut
                    }
                    SideBarLeft_UnterUnterkategorie_Farbe_Groesse_Stil_OnMouseOverAndOut={
                      SideBarLeft_UnterUnterkategorie_Farbe_Groesse_Stil_OnMouseOverAndOut
                    }
                    SideBarLeft_UnterUnterkategorie_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut={
                      SideBarLeft_UnterUnterkategorie_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
                    }
                    SideBarLeft_UnterUnterkategorie_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut={
                      SideBarLeft_UnterUnterkategorie_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut
                    }
                    SideBarLeft_UnterUnterkategorie_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut={
                      SideBarLeft_UnterUnterkategorie_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut
                    }
                    DB_fontFace_alleProdukteAuswahlBB_FontFamilyName={
                      DB_fontFace_alleProdukteAuswahlBB_FontFamilyName
                    }
                    DB_fontFace_alleProdukteAuswahlBB_FontFamilySrc={
                      DB_fontFace_alleProdukteAuswahlBB_FontFamilySrc
                    }
                    DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_FontWeight={
                      DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_FontWeight
                    }
                    DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_FontColor={
                      DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_FontColor
                    }
                    DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_FontSize={
                      DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_FontSize
                    }
                    DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_BackgroundColor={
                      DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_BackgroundColor
                    }
                    DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_BorderTop={
                      DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_BorderTop
                    }
                    DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_BorderStyleTop={
                      DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_BorderStyleTop
                    }
                    DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_BorderColorTop={
                      DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_BorderColorTop
                    }
                    DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_BorderBottom={
                      DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_BorderBottom
                    }
                    DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_BorderStyleBottom={
                      DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_BorderStyleBottom
                    }
                    DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_BorderColorBottom={
                      DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_BorderColorBottom
                    }
                    DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_BorderLeft={
                      DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_BorderLeft
                    }
                    DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_BorderStyleLeft={
                      DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_BorderStyleLeft
                    }
                    DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_BorderColorLeft={
                      DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_BorderColorLeft
                    }
                    DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_BorderRight={
                      DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_BorderRight
                    }
                    DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_BorderStyleRight={
                      DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_BorderStyleRight
                    }
                    DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_BorderColorRight={
                      DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_BorderColorRight
                    }
                    DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_BorderTopRightRadius={
                      DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_BorderTopRightRadius
                    }
                    DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_BorderTopLeftRadius={
                      DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_BorderTopLeftRadius
                    }
                    DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_BorderBottomRightRadius={
                      DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_BorderBottomRightRadius
                    }
                    DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_BorderBottomLeftRadius={
                      DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_BorderBottomLeftRadius
                    }
                    DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_marginLeftRight={
                      DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_marginLeftRight
                    }
                    DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_marginTopBottom={
                      DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_marginTopBottom
                    }
                    DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_paddingLeftRight={
                      DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_paddingLeftRight
                    }
                    DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_paddingTopBottom={
                      DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_paddingTopBottom
                    }
                    DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_letterSpacing={
                      DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_letterSpacing
                    }
                    DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_ShadowHorizontal={
                      DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_ShadowHorizontal
                    }
                    DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_ShadowVertical={
                      DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_ShadowVertical
                    }
                    DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_ShadowBlur={
                      DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_ShadowBlur
                    }
                    DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_ShadowColor={
                      DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_ShadowColor
                    }
                    DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_TextDecoration={
                      DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_TextDecoration
                    }
                    DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_TextDecorationStyle={
                      DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_TextDecorationStyle
                    }
                    DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_TextAlign={
                      DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_TextAlign
                    }
                    DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_FontWeight={
                      DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_FontWeight
                    }
                    DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_FontColor={
                      DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_FontColor
                    }
                    DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_FontSize={
                      DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_FontSize
                    }
                    DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_BackgroundColor={
                      DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_BackgroundColor
                    }
                    DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_TextAlign={
                      DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_TextAlign
                    }
                    DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_BorderTop={
                      DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_BorderTop
                    }
                    DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_BorderStyleTop={
                      DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_BorderStyleTop
                    }
                    DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_BorderColorTop={
                      DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_BorderColorTop
                    }
                    DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_BorderBottom={
                      DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_BorderBottom
                    }
                    DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_BorderStyleBottom={
                      DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_BorderStyleBottom
                    }
                    DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_BorderColorBottom={
                      DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_BorderColorBottom
                    }
                    DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_BorderLeft={
                      DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_BorderLeft
                    }
                    DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_BorderStyleLeft={
                      DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_BorderStyleLeft
                    }
                    DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_BorderColorLeft={
                      DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_BorderColorLeft
                    }
                    DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_BorderRight={
                      DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_BorderRight
                    }
                    DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_BorderStyleRight={
                      DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_BorderStyleRight
                    }
                    DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_BorderColorRight={
                      DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_BorderColorRight
                    }
                    DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_BorderTopRightRadius={
                      DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_BorderTopRightRadius
                    }
                    DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_BorderTopLeftRadius={
                      DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_BorderTopLeftRadius
                    }
                    DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_BorderBottomRightRadius={
                      DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_BorderBottomRightRadius
                    }
                    DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_BorderBottomLeftRadius={
                      DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_BorderBottomLeftRadius
                    }
                    DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_marginLeftRight_Hover={
                      DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_marginLeftRight_Hover
                    }
                    DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_marginTopBottom_Hover={
                      DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_marginTopBottom_Hover
                    }
                    DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_paddingLeftRight_Hover={
                      DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_paddingLeftRight_Hover
                    }
                    DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_paddingTopBottom_Hover={
                      DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_paddingTopBottom_Hover
                    }
                    DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_letterSpacing_Hover={
                      DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_letterSpacing_Hover
                    }
                    DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_ShadowHorizontal_Hover={
                      DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_ShadowHorizontal_Hover
                    }
                    DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_ShadowVertical_Hover={
                      DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_ShadowVertical_Hover
                    }
                    DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_ShadowBlur_Hover={
                      DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_ShadowBlur_Hover
                    }
                    DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_ShadowColor_Hover={
                      DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_ShadowColor_Hover
                    }
                    DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_TextDecoration_Hover={
                      DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_TextDecoration_Hover
                    }
                    DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_TextDecorationStyle_Hover={
                      DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_TextDecorationStyle_Hover
                    }
                    DB_fontFace_hauptkategorieTitel_FontFamilyName={
                      DB_fontFace_hauptkategorieTitel_FontFamilyName
                    }
                    DB_LeftSidebar_MenuItem_Hauptkategorie_FontFace={
                      DB_LeftSidebar_MenuItem_Hauptkategorie_FontFace
                    }
                    DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_FontFace={
                      DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_FontFace
                    }
                    DB_LeftSidebar_MenuItem_Hauptkategorie_FontWeight={
                      DB_LeftSidebar_MenuItem_Hauptkategorie_FontWeight
                    }
                    DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_FontWeight={
                      DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_FontWeight
                    }
                    DB_LeftSidebar_MenuItem_Hauptkategorie_FontColor={
                      DB_LeftSidebar_MenuItem_Hauptkategorie_FontColor
                    }
                    DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_FontColor={
                      DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_FontColor
                    }
                    DB_LeftSidebar_MenuItem_Hauptkategorie_FontSize={
                      DB_LeftSidebar_MenuItem_Hauptkategorie_FontSize
                    }
                    DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_FontSize={
                      DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_FontSize
                    }
                    SideBarLeft_Hauptkategorie_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut={
                      SideBarLeft_Hauptkategorie_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut
                    }
                    DB_LeftSidebar_MenuItem_Hauptkategorie_BackgroundColor={
                      DB_LeftSidebar_MenuItem_Hauptkategorie_BackgroundColor
                    }
                    DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_BackgroundColor={
                      DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_BackgroundColor
                    }
                    DB_LeftSidebar_MenuItem_Hauptkategorie_TextAlign={
                      DB_LeftSidebar_MenuItem_Hauptkategorie_TextAlign
                    }
                    DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_TextAlign={
                      DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_TextAlign
                    }
                    SideBarLeft_Hauptkategorie_Farbe_Groesse_Stil_OnMouseOverAndOut={
                      SideBarLeft_Hauptkategorie_Farbe_Groesse_Stil_OnMouseOverAndOut
                    }
                    SideBarLeft_Hauptkategorie_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut={
                      SideBarLeft_Hauptkategorie_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
                    }
                    DB_LeftSidebar_MenuItem_Hauptkategorie_BorderTop={
                      DB_LeftSidebar_MenuItem_Hauptkategorie_BorderTop
                    }
                    DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_BorderTop={
                      DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_BorderTop
                    }
                    DB_LeftSidebar_MenuItem_Hauptkategorie_BorderStyleTop={
                      DB_LeftSidebar_MenuItem_Hauptkategorie_BorderStyleTop
                    }
                    DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_BorderStyleTop={
                      DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_BorderStyleTop
                    }
                    DB_LeftSidebar_MenuItem_Hauptkategorie_BorderColorTop={
                      DB_LeftSidebar_MenuItem_Hauptkategorie_BorderColorTop
                    }
                    DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_BorderColorTop={
                      DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_BorderColorTop
                    }
                    DB_LeftSidebar_MenuItem_Hauptkategorie_BorderBottom={
                      DB_LeftSidebar_MenuItem_Hauptkategorie_BorderBottom
                    }
                    DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_BorderBottom={
                      DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_BorderBottom
                    }
                    DB_LeftSidebar_MenuItem_Hauptkategorie_BorderStyleBottom={
                      DB_LeftSidebar_MenuItem_Hauptkategorie_BorderStyleBottom
                    }
                    DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_BorderStyleBottom={
                      DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_BorderStyleBottom
                    }
                    DB_LeftSidebar_MenuItem_Hauptkategorie_BorderColorBottom={
                      DB_LeftSidebar_MenuItem_Hauptkategorie_BorderColorBottom
                    }
                    DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_BorderColorBottom={
                      DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_BorderColorBottom
                    }
                    DB_LeftSidebar_MenuItem_Hauptkategorie_BorderLeft={
                      DB_LeftSidebar_MenuItem_Hauptkategorie_BorderLeft
                    }
                    DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_BorderLeft={
                      DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_BorderLeft
                    }
                    DB_LeftSidebar_MenuItem_Hauptkategorie_BorderStyleLeft={
                      DB_LeftSidebar_MenuItem_Hauptkategorie_BorderStyleLeft
                    }
                    DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_BorderStyleLeft={
                      DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_BorderStyleLeft
                    }
                    DB_LeftSidebar_MenuItem_Hauptkategorie_BorderColorLeft={
                      DB_LeftSidebar_MenuItem_Hauptkategorie_BorderColorLeft
                    }
                    DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_BorderColorLeft={
                      DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_BorderColorLeft
                    }
                    DB_LeftSidebar_MenuItem_Hauptkategorie_BorderRight={
                      DB_LeftSidebar_MenuItem_Hauptkategorie_BorderRight
                    }
                    DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_BorderRight={
                      DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_BorderRight
                    }
                    DB_LeftSidebar_MenuItem_Hauptkategorie_BorderStyleRight={
                      DB_LeftSidebar_MenuItem_Hauptkategorie_BorderStyleRight
                    }
                    DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_BorderStyleRight={
                      DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_BorderStyleRight
                    }
                    DB_LeftSidebar_MenuItem_Hauptkategorie_BorderColorRight={
                      DB_LeftSidebar_MenuItem_Hauptkategorie_BorderColorRight
                    }
                    DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_BorderColorRight={
                      DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_BorderColorRight
                    }
                    DB_LeftSidebar_MenuItem_Hauptkategorie_BorderTopRightRadius={
                      DB_LeftSidebar_MenuItem_Hauptkategorie_BorderTopRightRadius
                    }
                    DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_BorderTopRightRadius={
                      DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_BorderTopRightRadius
                    }
                    DB_LeftSidebar_MenuItem_Hauptkategorie_BorderTopLeftRadius={
                      DB_LeftSidebar_MenuItem_Hauptkategorie_BorderTopLeftRadius
                    }
                    DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_BorderTopLeftRadius={
                      DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_BorderTopLeftRadius
                    }
                    DB_LeftSidebar_MenuItem_Hauptkategorie_BorderBottomRightRadius={
                      DB_LeftSidebar_MenuItem_Hauptkategorie_BorderBottomRightRadius
                    }
                    DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_BorderBottomRightRadius={
                      DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_BorderBottomRightRadius
                    }
                    DB_LeftSidebar_MenuItem_Hauptkategorie_BorderBottomLeftRadius={
                      DB_LeftSidebar_MenuItem_Hauptkategorie_BorderBottomLeftRadius
                    }
                    DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_BorderBottomLeftRadius={
                      DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_BorderBottomLeftRadius
                    }
                    DB_fontFace_hauptkategorieTitel_FontFamilyName={
                      DB_fontFace_hauptkategorieTitel_FontFamilyName
                    }
                    DB_fontFace_hauptkategorieTitel_FontFamilySrc={
                      DB_fontFace_hauptkategorieTitel_FontFamilySrc
                    }
                    DB_LeftSidebar_MenuItem_Hauptkategorie_marginTopBottom={
                      DB_LeftSidebar_MenuItem_Hauptkategorie_marginTopBottom
                    }
                    DB_LeftSidebar_MenuItem_Hauptkategorie_marginLeftRight={
                      DB_LeftSidebar_MenuItem_Hauptkategorie_marginLeftRight
                    }
                    DB_LeftSidebar_MenuItem_Hauptkategorie_paddingLeftRight={
                      DB_LeftSidebar_MenuItem_Hauptkategorie_paddingLeftRight
                    }
                    DB_LeftSidebar_MenuItem_Hauptkategorie_paddingTopBottom={
                      DB_LeftSidebar_MenuItem_Hauptkategorie_paddingTopBottom
                    }
                    DB_LeftSidebar_MenuItem_Hauptkategorie_letterSpacing={
                      DB_LeftSidebar_MenuItem_Hauptkategorie_letterSpacing
                    }
                    DB_LeftSidebar_MenuItem_Hauptkategorie_ShadowHorizontal={
                      DB_LeftSidebar_MenuItem_Hauptkategorie_ShadowHorizontal
                    }
                    DB_LeftSidebar_MenuItem_Hauptkategorie_ShadowVertical={
                      DB_LeftSidebar_MenuItem_Hauptkategorie_ShadowVertical
                    }
                    DB_LeftSidebar_MenuItem_Hauptkategorie_ShadowBlur={
                      DB_LeftSidebar_MenuItem_Hauptkategorie_ShadowBlur
                    }
                    DB_LeftSidebar_MenuItem_Hauptkategorie_ShadowColor={
                      DB_LeftSidebar_MenuItem_Hauptkategorie_ShadowColor
                    }
                    DB_LeftSidebar_MenuItem_Hauptkategorie_TextDecoration={
                      DB_LeftSidebar_MenuItem_Hauptkategorie_TextDecoration
                    }
                    DB_LeftSidebar_MenuItem_Hauptkategorie_TextDecorationStyle={
                      DB_LeftSidebar_MenuItem_Hauptkategorie_TextDecorationStyle
                    }
                    DB_LeftSidebar_MenuItem_Hauptkategorie_marginTopBottom_Hover={
                      DB_LeftSidebar_MenuItem_Hauptkategorie_marginTopBottom_Hover
                    }
                    DB_LeftSidebar_MenuItem_Hauptkategorie_marginLeftRight_Hover={
                      DB_LeftSidebar_MenuItem_Hauptkategorie_marginLeftRight_Hover
                    }
                    DB_LeftSidebar_MenuItem_Hauptkategorie_paddingLeftRight_Hover={
                      DB_LeftSidebar_MenuItem_Hauptkategorie_paddingLeftRight_Hover
                    }
                    DB_LeftSidebar_MenuItem_Hauptkategorie_paddingTopBottom_Hover={
                      DB_LeftSidebar_MenuItem_Hauptkategorie_paddingTopBottom_Hover
                    }
                    DB_LeftSidebar_MenuItem_Hauptkategorie_letterSpacing_Hover={
                      DB_LeftSidebar_MenuItem_Hauptkategorie_letterSpacing_Hover
                    }
                    DB_LeftSidebar_MenuItem_Hauptkategorie_ShadowHorizontal_Hover={
                      DB_LeftSidebar_MenuItem_Hauptkategorie_ShadowHorizontal_Hover
                    }
                    DB_LeftSidebar_MenuItem_Hauptkategorie_ShadowVertical_Hover={
                      DB_LeftSidebar_MenuItem_Hauptkategorie_ShadowVertical_Hover
                    }
                    DB_LeftSidebar_MenuItem_Hauptkategorie_ShadowBlur_Hover={
                      DB_LeftSidebar_MenuItem_Hauptkategorie_ShadowBlur_Hover
                    }
                    DB_LeftSidebar_MenuItem_Hauptkategorie_ShadowColor_Hover={
                      DB_LeftSidebar_MenuItem_Hauptkategorie_ShadowColor_Hover
                    }
                    DB_LeftSidebar_MenuItem_Hauptkategorie_TextDecoration_Hover={
                      DB_LeftSidebar_MenuItem_Hauptkategorie_TextDecoration_Hover
                    }
                    DB_LeftSidebar_MenuItem_Hauptkategorie_TextDecorationStyle_Hover={
                      DB_LeftSidebar_MenuItem_Hauptkategorie_TextDecorationStyle_Hover
                    }
                    DB_fontFace_unterkategorieTitel_FontFamilyName={
                      DB_fontFace_unterkategorieTitel_FontFamilyName
                    }
                    DB_fontFace_unterkategorieTitel_FontFamilySrc={
                      DB_fontFace_unterkategorieTitel_FontFamilySrc
                    }
                    DB_LeftSidebar_MenuItem_Unterkategorie_FontFace={
                      DB_LeftSidebar_MenuItem_Unterkategorie_FontFace
                    }
                    DB_LeftSidebar_MenuItem_Unterkategorie_Hover_FontFace={
                      DB_LeftSidebar_MenuItem_Unterkategorie_Hover_FontFace
                    }
                    DB_LeftSidebar_MenuItem_Unterkategorie_FontWeight={
                      DB_LeftSidebar_MenuItem_Unterkategorie_FontWeight
                    }
                    DB_LeftSidebar_MenuItem_Unterkategorie_Hover_FontWeight={
                      DB_LeftSidebar_MenuItem_Unterkategorie_Hover_FontWeight
                    }
                    DB_LeftSidebar_MenuItem_Unterkategorie_FontColor={
                      DB_LeftSidebar_MenuItem_Unterkategorie_FontColor
                    }
                    DB_LeftSidebar_MenuItem_Unterkategorie_Hover_FontColor={
                      DB_LeftSidebar_MenuItem_Unterkategorie_Hover_FontColor
                    }
                    DB_LeftSidebar_MenuItem_Unterkategorie_FontSize={
                      DB_LeftSidebar_MenuItem_Unterkategorie_FontSize
                    }
                    DB_LeftSidebar_MenuItem_Unterkategorie_Hover_FontSize={
                      DB_LeftSidebar_MenuItem_Unterkategorie_Hover_FontSize
                    }
                    DB_LeftSidebar_MenuItem_Unterkategorie_BackgroundColor={
                      DB_LeftSidebar_MenuItem_Unterkategorie_BackgroundColor
                    }
                    DB_LeftSidebar_MenuItem_Unterkategorie_Hover_BackgroundColor={
                      DB_LeftSidebar_MenuItem_Unterkategorie_Hover_BackgroundColor
                    }
                    DB_LeftSidebar_MenuItem_Unterkategorie_TextAlign={
                      DB_LeftSidebar_MenuItem_Unterkategorie_TextAlign
                    }
                    DB_LeftSidebar_MenuItem_Unterkategorie_Hover_TextAlign={
                      DB_LeftSidebar_MenuItem_Unterkategorie_Hover_TextAlign
                    }
                    DB_LeftSidebar_MenuItem_Unterkategorie_BorderTop={
                      DB_LeftSidebar_MenuItem_Unterkategorie_BorderTop
                    }
                    DB_LeftSidebar_MenuItem_Unterkategorie_Hover_BorderTop={
                      DB_LeftSidebar_MenuItem_Unterkategorie_Hover_BorderTop
                    }
                    DB_LeftSidebar_MenuItem_Unterkategorie_BorderStyleTop={
                      DB_LeftSidebar_MenuItem_Unterkategorie_BorderStyleTop
                    }
                    DB_LeftSidebar_MenuItem_Unterkategorie_Hover_BorderStyleTop={
                      DB_LeftSidebar_MenuItem_Unterkategorie_Hover_BorderStyleTop
                    }
                    DB_LeftSidebar_MenuItem_Unterkategorie_BorderColorTop={
                      DB_LeftSidebar_MenuItem_Unterkategorie_BorderColorTop
                    }
                    DB_LeftSidebar_MenuItem_Unterkategorie_Hover_BorderColorTop={
                      DB_LeftSidebar_MenuItem_Unterkategorie_Hover_BorderColorTop
                    }
                    DB_LeftSidebar_MenuItem_Unterkategorie_BorderBottom={
                      DB_LeftSidebar_MenuItem_Unterkategorie_BorderBottom
                    }
                    DB_LeftSidebar_MenuItem_Unterkategorie_Hover_BorderBottom={
                      DB_LeftSidebar_MenuItem_Unterkategorie_Hover_BorderBottom
                    }
                    DB_LeftSidebar_MenuItem_Unterkategorie_BorderStyleBottom={
                      DB_LeftSidebar_MenuItem_Unterkategorie_BorderStyleBottom
                    }
                    DB_LeftSidebar_MenuItem_Unterkategorie_Hover_BorderStyleBottom={
                      DB_LeftSidebar_MenuItem_Unterkategorie_Hover_BorderStyleBottom
                    }
                    DB_LeftSidebar_MenuItem_Unterkategorie_BorderColorBottom={
                      DB_LeftSidebar_MenuItem_Unterkategorie_BorderColorBottom
                    }
                    DB_LeftSidebar_MenuItem_Unterkategorie_Hover_BorderColorBottom={
                      DB_LeftSidebar_MenuItem_Unterkategorie_Hover_BorderColorBottom
                    }
                    DB_LeftSidebar_MenuItem_Unterkategorie_BorderLeft={
                      DB_LeftSidebar_MenuItem_Unterkategorie_BorderLeft
                    }
                    DB_LeftSidebar_MenuItem_Unterkategorie_Hover_BorderLeft={
                      DB_LeftSidebar_MenuItem_Unterkategorie_Hover_BorderLeft
                    }
                    DB_LeftSidebar_MenuItem_Unterkategorie_BorderStyleLeft={
                      DB_LeftSidebar_MenuItem_Unterkategorie_BorderStyleLeft
                    }
                    DB_LeftSidebar_MenuItem_Unterkategorie_Hover_BorderStyleLeft={
                      DB_LeftSidebar_MenuItem_Unterkategorie_Hover_BorderStyleLeft
                    }
                    DB_LeftSidebar_MenuItem_Unterkategorie_BorderColorLeft={
                      DB_LeftSidebar_MenuItem_Unterkategorie_BorderColorLeft
                    }
                    DB_LeftSidebar_MenuItem_Unterkategorie_Hover_BorderColorLeft={
                      DB_LeftSidebar_MenuItem_Unterkategorie_Hover_BorderColorLeft
                    }
                    DB_LeftSidebar_MenuItem_Unterkategorie_BorderRight={
                      DB_LeftSidebar_MenuItem_Unterkategorie_BorderRight
                    }
                    DB_LeftSidebar_MenuItem_Unterkategorie_Hover_BorderRight={
                      DB_LeftSidebar_MenuItem_Unterkategorie_Hover_BorderRight
                    }
                    DB_LeftSidebar_MenuItem_Unterkategorie_BorderStyleRight={
                      DB_LeftSidebar_MenuItem_Unterkategorie_BorderStyleRight
                    }
                    DB_LeftSidebar_MenuItem_Unterkategorie_Hover_BorderStyleRight={
                      DB_LeftSidebar_MenuItem_Unterkategorie_Hover_BorderStyleRight
                    }
                    DB_LeftSidebar_MenuItem_Unterkategorie_BorderColorRight={
                      DB_LeftSidebar_MenuItem_Unterkategorie_BorderColorRight
                    }
                    DB_LeftSidebar_MenuItem_Unterkategorie_Hover_BorderColorRight={
                      DB_LeftSidebar_MenuItem_Unterkategorie_Hover_BorderColorRight
                    }
                    DB_LeftSidebar_MenuItem_Unterkategorie_BorderTopRightRadius={
                      DB_LeftSidebar_MenuItem_Unterkategorie_BorderTopRightRadius
                    }
                    DB_LeftSidebar_MenuItem_Unterkategorie_Hover_BorderTopRightRadius={
                      DB_LeftSidebar_MenuItem_Unterkategorie_Hover_BorderTopRightRadius
                    }
                    DB_LeftSidebar_MenuItem_Unterkategorie_BorderTopLeftRadius={
                      DB_LeftSidebar_MenuItem_Unterkategorie_BorderTopLeftRadius
                    }
                    DB_LeftSidebar_MenuItem_Unterkategorie_Hover_BorderTopLeftRadius={
                      DB_LeftSidebar_MenuItem_Unterkategorie_Hover_BorderTopLeftRadius
                    }
                    DB_LeftSidebar_MenuItem_Unterkategorie_BorderBottomRightRadius={
                      DB_LeftSidebar_MenuItem_Unterkategorie_BorderBottomRightRadius
                    }
                    DB_LeftSidebar_MenuItem_Unterkategorie_Hover_BorderBottomRightRadius={
                      DB_LeftSidebar_MenuItem_Unterkategorie_Hover_BorderBottomRightRadius
                    }
                    DB_LeftSidebar_MenuItem_Unterkategorie_BorderBottomLeftRadius={
                      DB_LeftSidebar_MenuItem_Unterkategorie_BorderBottomLeftRadius
                    }
                    DB_LeftSidebar_MenuItem_Unterkategorie_Hover_BorderBottomLeftRadius={
                      DB_LeftSidebar_MenuItem_Unterkategorie_Hover_BorderBottomLeftRadius
                    }
                    DB_LeftSidebar_MenuItem_Unterkategorie_marginTopBottom={
                      DB_LeftSidebar_MenuItem_Unterkategorie_marginTopBottom
                    }
                    DB_LeftSidebar_MenuItem_Unterkategorie_marginLeftRight={
                      DB_LeftSidebar_MenuItem_Unterkategorie_marginLeftRight
                    }
                    DB_LeftSidebar_MenuItem_Unterkategorie_paddingLeftRight={
                      DB_LeftSidebar_MenuItem_Unterkategorie_paddingLeftRight
                    }
                    DB_LeftSidebar_MenuItem_Unterkategorie_paddingTopBottom={
                      DB_LeftSidebar_MenuItem_Unterkategorie_paddingTopBottom
                    }
                    DB_LeftSidebar_MenuItem_Unterkategorie_letterSpacing={
                      DB_LeftSidebar_MenuItem_Unterkategorie_letterSpacing
                    }
                    DB_LeftSidebar_MenuItem_Unterkategorie_ShadowHorizontal={
                      DB_LeftSidebar_MenuItem_Unterkategorie_ShadowHorizontal
                    }
                    DB_LeftSidebar_MenuItem_Unterkategorie_ShadowVertical={
                      DB_LeftSidebar_MenuItem_Unterkategorie_ShadowVertical
                    }
                    DB_LeftSidebar_MenuItem_Unterkategorie_ShadowBlur={
                      DB_LeftSidebar_MenuItem_Unterkategorie_ShadowBlur
                    }
                    DB_LeftSidebar_MenuItem_Unterkategorie_ShadowColor={
                      DB_LeftSidebar_MenuItem_Unterkategorie_ShadowColor
                    }
                    DB_LeftSidebar_MenuItem_Unterkategorie_TextDecoration={
                      DB_LeftSidebar_MenuItem_Unterkategorie_TextDecoration
                    }
                    DB_LeftSidebar_MenuItem_Unterkategorie_TextDecorationStyle={
                      DB_LeftSidebar_MenuItem_Unterkategorie_TextDecorationStyle
                    }
                    DB_LeftSidebar_MenuItem_Unterkategorie_marginTopBottom_Hover={
                      DB_LeftSidebar_MenuItem_Unterkategorie_marginTopBottom_Hover
                    }
                    DB_LeftSidebar_MenuItem_Unterkategorie_marginLeftRight_Hover={
                      DB_LeftSidebar_MenuItem_Unterkategorie_marginLeftRight_Hover
                    }
                    DB_LeftSidebar_MenuItem_Unterkategorie_paddingLeftRight_Hover={
                      DB_LeftSidebar_MenuItem_Unterkategorie_paddingLeftRight_Hover
                    }
                    DB_LeftSidebar_MenuItem_Unterkategorie_paddingTopBottom_Hover={
                      DB_LeftSidebar_MenuItem_Unterkategorie_paddingTopBottom_Hover
                    }
                    DB_LeftSidebar_MenuItem_Unterkategorie_letterSpacing_Hover={
                      DB_LeftSidebar_MenuItem_Unterkategorie_letterSpacing_Hover
                    }
                    DB_LeftSidebar_MenuItem_Unterkategorie_ShadowHorizontal_Hover={
                      DB_LeftSidebar_MenuItem_Unterkategorie_ShadowHorizontal_Hover
                    }
                    DB_LeftSidebar_MenuItem_Unterkategorie_ShadowVertical_Hover={
                      DB_LeftSidebar_MenuItem_Unterkategorie_ShadowVertical_Hover
                    }
                    DB_LeftSidebar_MenuItem_Unterkategorie_ShadowBlur_Hover={
                      DB_LeftSidebar_MenuItem_Unterkategorie_ShadowBlur_Hover
                    }
                    DB_LeftSidebar_MenuItem_Unterkategorie_ShadowColor_Hover={
                      DB_LeftSidebar_MenuItem_Unterkategorie_ShadowColor_Hover
                    }
                    DB_LeftSidebar_MenuItem_Unterkategorie_TextDecoration_Hover={
                      DB_LeftSidebar_MenuItem_Unterkategorie_TextDecoration_Hover
                    }
                    DB_LeftSidebar_MenuItem_Unterkategorie_TextDecorationStyle_Hover={
                      DB_LeftSidebar_MenuItem_Unterkategorie_TextDecorationStyle_Hover
                    }
                    SideBarLeft_Unterkategorie_Farbe_Groesse_Stil_Trigger={
                      SideBarLeft_Unterkategorie_Farbe_Groesse_Stil_Trigger
                    }
                    SideBarLeft_Unterkategorie_Farbe_Groesse_Stil_Hover_Trigger={
                      SideBarLeft_Unterkategorie_Farbe_Groesse_Stil_Hover_Trigger
                    }
                    SideBarLeft_Unterkategorie_HintergrundFarbe_Radius_Stil_Trigger={
                      SideBarLeft_Unterkategorie_HintergrundFarbe_Radius_Stil_Trigger
                    }
                    SideBarLeft_Unterkategorie_HintergrundFarbe_Radius_Stil_Hover_Trigger={
                      SideBarLeft_Unterkategorie_HintergrundFarbe_Radius_Stil_Hover_Trigger
                    }
                    SideBarLeft_Unterkategorie_Farbe_Groesse_Stil_OnMouseOverAndOut={
                      SideBarLeft_Unterkategorie_Farbe_Groesse_Stil_OnMouseOverAndOut
                    }
                    SideBarLeft_Unterkategorie_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut={
                      SideBarLeft_Unterkategorie_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
                    }
                    SideBarLeft_Unterkategorie_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut={
                      SideBarLeft_Unterkategorie_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut
                    }
                    SideBarLeft_Unterkategorie_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut={
                      SideBarLeft_Unterkategorie_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut
                    }
                    DB_fontFace_unterunterkategorieTitel_FontFamilyName={
                      DB_fontFace_unterunterkategorieTitel_FontFamilyName
                    }
                    DB_fontFace_unterunterkategorieTitel_FontFamilySrc={
                      DB_fontFace_unterunterkategorieTitel_FontFamilySrc
                    }
                    DB_LeftSidebar_MenuItem_UnterUnterkategorie_FontFace={
                      DB_LeftSidebar_MenuItem_UnterUnterkategorie_FontFace
                    }
                    DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_FontFace={
                      DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_FontFace
                    }
                    DB_LeftSidebar_MenuItem_UnterUnterkategorie_FontWeight={
                      DB_LeftSidebar_MenuItem_UnterUnterkategorie_FontWeight
                    }
                    DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_FontWeight={
                      DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_FontWeight
                    }
                    DB_LeftSidebar_MenuItem_UnterUnterkategorie_FontColor={
                      DB_LeftSidebar_MenuItem_UnterUnterkategorie_FontColor
                    }
                    DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_FontColor={
                      DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_FontColor
                    }
                    DB_LeftSidebar_MenuItem_UnterUnterkategorie_FontSize={
                      DB_LeftSidebar_MenuItem_UnterUnterkategorie_FontSize
                    }
                    DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_FontSize={
                      DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_FontSize
                    }
                    DB_LeftSidebar_MenuItem_UnterUnterkategorie_BackgroundColor={
                      DB_LeftSidebar_MenuItem_UnterUnterkategorie_BackgroundColor
                    }
                    DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_BackgroundColor={
                      DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_BackgroundColor
                    }
                    DB_LeftSidebar_MenuItem_UnterUnterkategorie_TextAlign={
                      DB_LeftSidebar_MenuItem_UnterUnterkategorie_TextAlign
                    }
                    DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_TextAlign={
                      DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_TextAlign
                    }
                    DB_LeftSidebar_MenuItem_UnterUnterkategorie_BorderTop={
                      DB_LeftSidebar_MenuItem_UnterUnterkategorie_BorderTop
                    }
                    DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_BorderTop={
                      DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_BorderTop
                    }
                    DB_LeftSidebar_MenuItem_UnterUnterkategorie_BorderStyleTop={
                      DB_LeftSidebar_MenuItem_UnterUnterkategorie_BorderStyleTop
                    }
                    DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_BorderStyleTop={
                      DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_BorderStyleTop
                    }
                    DB_LeftSidebar_MenuItem_UnterUnterkategorie_BorderColorTop={
                      DB_LeftSidebar_MenuItem_UnterUnterkategorie_BorderColorTop
                    }
                    DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_BorderColorTop={
                      DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_BorderColorTop
                    }
                    DB_LeftSidebar_MenuItem_UnterUnterkategorie_BorderBottom={
                      DB_LeftSidebar_MenuItem_UnterUnterkategorie_BorderBottom
                    }
                    DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_BorderBottom={
                      DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_BorderBottom
                    }
                    DB_LeftSidebar_MenuItem_UnterUnterkategorie_BorderStyleBottom={
                      DB_LeftSidebar_MenuItem_UnterUnterkategorie_BorderStyleBottom
                    }
                    DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_BorderStyleBottom={
                      DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_BorderStyleBottom
                    }
                    DB_LeftSidebar_MenuItem_UnterUnterkategorie_BorderColorBottom={
                      DB_LeftSidebar_MenuItem_UnterUnterkategorie_BorderColorBottom
                    }
                    DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_BorderColorBottom={
                      DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_BorderColorBottom
                    }
                    DB_LeftSidebar_MenuItem_UnterUnterkategorie_BorderLeft={
                      DB_LeftSidebar_MenuItem_UnterUnterkategorie_BorderLeft
                    }
                    DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_BorderLeft={
                      DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_BorderLeft
                    }
                    DB_LeftSidebar_MenuItem_UnterUnterkategorie_BorderStyleLeft={
                      DB_LeftSidebar_MenuItem_UnterUnterkategorie_BorderStyleLeft
                    }
                    DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_BorderStyleLeft={
                      DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_BorderStyleLeft
                    }
                    DB_LeftSidebar_MenuItem_UnterUnterkategorie_BorderColorLeft={
                      DB_LeftSidebar_MenuItem_UnterUnterkategorie_BorderColorLeft
                    }
                    DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_BorderColorLeft={
                      DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_BorderColorLeft
                    }
                    DB_LeftSidebar_MenuItem_UnterUnterkategorie_BorderRight={
                      DB_LeftSidebar_MenuItem_UnterUnterkategorie_BorderRight
                    }
                    DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_BorderRight={
                      DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_BorderRight
                    }
                    DB_LeftSidebar_MenuItem_UnterUnterkategorie_BorderStyleRight={
                      DB_LeftSidebar_MenuItem_UnterUnterkategorie_BorderStyleRight
                    }
                    DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_BorderStyleRight={
                      DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_BorderStyleRight
                    }
                    DB_LeftSidebar_MenuItem_UnterUnterkategorie_BorderColorRight={
                      DB_LeftSidebar_MenuItem_UnterUnterkategorie_BorderColorRight
                    }
                    DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_BorderColorRight={
                      DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_BorderColorRight
                    }
                    DB_LeftSidebar_MenuItem_UnterUnterkategorie_BorderTopRightRadius={
                      DB_LeftSidebar_MenuItem_UnterUnterkategorie_BorderTopRightRadius
                    }
                    DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_BorderTopRightRadius={
                      DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_BorderTopRightRadius
                    }
                    DB_LeftSidebar_MenuItem_UnterUnterkategorie_BorderTopLeftRadius={
                      DB_LeftSidebar_MenuItem_UnterUnterkategorie_BorderTopLeftRadius
                    }
                    DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_BorderTopLeftRadius={
                      DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_BorderTopLeftRadius
                    }
                    DB_LeftSidebar_MenuItem_UnterUnterkategorie_BorderBottomRightRadius={
                      DB_LeftSidebar_MenuItem_UnterUnterkategorie_BorderBottomRightRadius
                    }
                    DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_BorderBottomRightRadius={
                      DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_BorderBottomRightRadius
                    }
                    DB_LeftSidebar_MenuItem_UnterUnterkategorie_BorderBottomLeftRadius={
                      DB_LeftSidebar_MenuItem_UnterUnterkategorie_BorderBottomLeftRadius
                    }
                    DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_BorderBottomLeftRadius={
                      DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_BorderBottomLeftRadius
                    }
                    DB_LeftSidebar_MenuItem_UnterUnterkategorie_marginTopBottom={
                      DB_LeftSidebar_MenuItem_UnterUnterkategorie_marginTopBottom
                    }
                    DB_LeftSidebar_MenuItem_UnterUnterkategorie_marginLeftRight={
                      DB_LeftSidebar_MenuItem_UnterUnterkategorie_marginLeftRight
                    }
                    DB_LeftSidebar_MenuItem_UnterUnterkategorie_paddingLeftRight={
                      DB_LeftSidebar_MenuItem_UnterUnterkategorie_paddingLeftRight
                    }
                    DB_LeftSidebar_MenuItem_UnterUnterkategorie_paddingTopBottom={
                      DB_LeftSidebar_MenuItem_UnterUnterkategorie_paddingTopBottom
                    }
                    DB_LeftSidebar_MenuItem_UnterUnterkategorie_letterSpacing={
                      DB_LeftSidebar_MenuItem_UnterUnterkategorie_letterSpacing
                    }
                    DB_LeftSidebar_MenuItem_UnterUnterkategorie_ShadowHorizontal={
                      DB_LeftSidebar_MenuItem_UnterUnterkategorie_ShadowHorizontal
                    }
                    DB_LeftSidebar_MenuItem_UnterUnterkategorie_ShadowVertical={
                      DB_LeftSidebar_MenuItem_UnterUnterkategorie_ShadowVertical
                    }
                    DB_LeftSidebar_MenuItem_UnterUnterkategorie_ShadowBlur={
                      DB_LeftSidebar_MenuItem_UnterUnterkategorie_ShadowBlur
                    }
                    DB_LeftSidebar_MenuItem_UnterUnterkategorie_ShadowColor={
                      DB_LeftSidebar_MenuItem_UnterUnterkategorie_ShadowColor
                    }
                    DB_LeftSidebar_MenuItem_UnterUnterkategorie_TextDecoration={
                      DB_LeftSidebar_MenuItem_UnterUnterkategorie_TextDecoration
                    }
                    DB_LeftSidebar_MenuItem_UnterUnterkategorie_TextDecorationStyle={
                      DB_LeftSidebar_MenuItem_UnterUnterkategorie_TextDecorationStyle
                    }
                    DB_LeftSidebar_MenuItem_UnterUnterkategorie_marginTopBottom_Hover={
                      DB_LeftSidebar_MenuItem_UnterUnterkategorie_marginTopBottom_Hover
                    }
                    DB_LeftSidebar_MenuItem_UnterUnterkategorie_marginLeftRight_Hover={
                      DB_LeftSidebar_MenuItem_UnterUnterkategorie_marginLeftRight_Hover
                    }
                    DB_LeftSidebar_MenuItem_UnterUnterkategorie_paddingLeftRight_Hover={
                      DB_LeftSidebar_MenuItem_UnterUnterkategorie_paddingLeftRight_Hover
                    }
                    DB_LeftSidebar_MenuItem_UnterUnterkategorie_paddingTopBottom_Hover={
                      DB_LeftSidebar_MenuItem_UnterUnterkategorie_paddingTopBottom_Hover
                    }
                    DB_LeftSidebar_MenuItem_UnterUnterkategorie_letterSpacing_Hover={
                      DB_LeftSidebar_MenuItem_UnterUnterkategorie_letterSpacing_Hover
                    }
                    DB_LeftSidebar_MenuItem_UnterUnterkategorie_ShadowHorizontal_Hover={
                      DB_LeftSidebar_MenuItem_UnterUnterkategorie_ShadowHorizontal_Hover
                    }
                    DB_LeftSidebar_MenuItem_UnterUnterkategorie_ShadowVertical_Hover={
                      DB_LeftSidebar_MenuItem_UnterUnterkategorie_ShadowVertical_Hover
                    }
                    DB_LeftSidebar_MenuItem_UnterUnterkategorie_ShadowBlur_Hover={
                      DB_LeftSidebar_MenuItem_UnterUnterkategorie_ShadowBlur_Hover
                    }
                    DB_LeftSidebar_MenuItem_UnterUnterkategorie_ShadowColor_Hover={
                      DB_LeftSidebar_MenuItem_UnterUnterkategorie_ShadowColor_Hover
                    }
                    DB_LeftSidebar_MenuItem_UnterUnterkategorie_TextDecoration_Hover={
                      DB_LeftSidebar_MenuItem_UnterUnterkategorie_TextDecoration_Hover
                    }
                    DB_LeftSidebar_MenuItem_UnterUnterkategorie_TextDecorationStyle_Hover={
                      DB_LeftSidebar_MenuItem_UnterUnterkategorie_TextDecorationStyle_Hover
                    }
                    SideBarLeft_UnterUnterkategorie_Farbe_Groesse_Stil_Trigger={
                      SideBarLeft_UnterUnterkategorie_Farbe_Groesse_Stil_Trigger
                    }
                    SideBarLeft_UnterUnterkategorie_Farbe_Groesse_Stil_Hover_Trigger={
                      SideBarLeft_UnterUnterkategorie_Farbe_Groesse_Stil_Hover_Trigger
                    }
                    SideBarLeft_UnterUnterkategorie_HintergrundFarbe_Radius_Stil_Trigger={
                      SideBarLeft_UnterUnterkategorie_HintergrundFarbe_Radius_Stil_Trigger
                    }
                    SideBarLeft_UnterUnterkategorie_HintergrundFarbe_Radius_Stil_Hover_Trigger={
                      SideBarLeft_UnterUnterkategorie_HintergrundFarbe_Radius_Stil_Hover_Trigger
                    }
                    SideBarLeft_UnterUnterkategorie_Farbe_Groesse_Stil_OnMouseOverAndOut={
                      SideBarLeft_UnterUnterkategorie_Farbe_Groesse_Stil_OnMouseOverAndOut
                    }
                    SideBarLeft_UnterUnterkategorie_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut={
                      SideBarLeft_UnterUnterkategorie_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
                    }
                    SideBarLeft_UnterUnterkategorie_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut={
                      SideBarLeft_UnterUnterkategorie_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut
                    }
                    SideBarLeft_UnterUnterkategorie_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut={
                      SideBarLeft_UnterUnterkategorie_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut
                    }
                    DB_LeftSidebar_MenuContainer_CC__BackgroundColor={
                      DB_LeftSidebar_MenuContainer_CC__BackgroundColor
                    }
                    DB_LeftSidebar_MenuContainer_CC__PaddingTopBottom={
                      DB_LeftSidebar_MenuContainer_CC__PaddingTopBottom
                    }
                    DB_LeftSidebar_MenuContainer_CC__PaddingLeftRight={
                      DB_LeftSidebar_MenuContainer_CC__PaddingLeftRight
                    }
                    DB_LeftSidebar_MenuContainer_CC__BorderTop={
                      DB_LeftSidebar_MenuContainer_CC__BorderTop
                    }
                    DB_LeftSidebar_MenuContainer_CC__BorderStyleTop={
                      DB_LeftSidebar_MenuContainer_CC__BorderStyleTop
                    }
                    DB_LeftSidebar_MenuContainer_CC__BorderColorTop={
                      DB_LeftSidebar_MenuContainer_CC__BorderColorTop
                    }
                    DB_LeftSidebar_MenuContainer_CC__BorderBottom={
                      DB_LeftSidebar_MenuContainer_CC__BorderBottom
                    }
                    DB_LeftSidebar_MenuContainer_CC__BorderStyleBottom={
                      DB_LeftSidebar_MenuContainer_CC__BorderStyleBottom
                    }
                    DB_LeftSidebar_MenuContainer_CC__BorderColorBottom={
                      DB_LeftSidebar_MenuContainer_CC__BorderColorBottom
                    }
                    DB_LeftSidebar_MenuContainer_CC__BorderLeft={
                      DB_LeftSidebar_MenuContainer_CC__BorderLeft
                    }
                    DB_LeftSidebar_MenuContainer_CC__BorderStyleLeft={
                      DB_LeftSidebar_MenuContainer_CC__BorderStyleLeft
                    }
                    DB_LeftSidebar_MenuContainer_CC__BorderColorLeft={
                      DB_LeftSidebar_MenuContainer_CC__BorderColorLeft
                    }
                    DB_LeftSidebar_MenuContainer_CC__BorderRight={
                      DB_LeftSidebar_MenuContainer_CC__BorderRight
                    }
                    DB_LeftSidebar_MenuContainer_CC__BorderStyleRight={
                      DB_LeftSidebar_MenuContainer_CC__BorderStyleRight
                    }
                    DB_LeftSidebar_MenuContainer_CC__BorderColorRight={
                      DB_LeftSidebar_MenuContainer_CC__BorderColorRight
                    }
                    DB_LeftSidebar_MenuItem_MenuContainer_CC__BorderTopRightRadius={
                      DB_LeftSidebar_MenuItem_MenuContainer_CC__BorderTopRightRadius
                    }
                    DB_LeftSidebar_MenuItem_MenuContainer_CC__BorderTopLeftRadius={
                      DB_LeftSidebar_MenuItem_MenuContainer_CC__BorderTopLeftRadius
                    }
                    DB_LeftSidebar_MenuItem_MenuContainer_CC__BorderBottomRightRadius={
                      DB_LeftSidebar_MenuItem_MenuContainer_CC__BorderBottomRightRadius
                    }
                    DB_LeftSidebar_MenuItem_MenuContainer_CC__BorderBottomLeftRadius={
                      DB_LeftSidebar_MenuItem_MenuContainer_CC__BorderBottomLeftRadius
                    }
                    DB_LeftSidebar_MenuItem_MenuContainer_CC__paddingLeft={
                      DB_LeftSidebar_MenuItem_MenuContainer_CC__paddingLeft
                    }
                    DB_LeftSidebar_MenuItem_MenuContainer_CC__paddingRight={
                      DB_LeftSidebar_MenuItem_MenuContainer_CC__paddingRight
                    }
                    DB_LeftSidebar_MenuItem_MenuContainer_CC__paddingTop={
                      DB_LeftSidebar_MenuItem_MenuContainer_CC__paddingTop
                    }
                    DB_LeftSidebar_MenuItem_MenuContainer_CC__paddingBottom={
                      DB_LeftSidebar_MenuItem_MenuContainer_CC__paddingBottom
                    }
                    DB_LeftSidebar_MenuItem_MenuContainer_CC__ShadowHorizontal={
                      DB_LeftSidebar_MenuItem_MenuContainer_CC__ShadowHorizontal
                    }
                    DB_LeftSidebar_MenuItem_MenuContainer_CC__ShadowVertical={
                      DB_LeftSidebar_MenuItem_MenuContainer_CC__ShadowVertical
                    }
                    DB_LeftSidebar_MenuItem_MenuContainer_CC__ShadowBlur={
                      DB_LeftSidebar_MenuItem_MenuContainer_CC__ShadowBlur
                    }
                    DB_LeftSidebar_MenuItem_MenuContainer_CC__ShadowColor={
                      DB_LeftSidebar_MenuItem_MenuContainer_CC__ShadowColor
                    }
                    DB_LeftSidebar_MenuContainer_CC__Hover_BackgroundColor={
                      DB_LeftSidebar_MenuContainer_CC__Hover_BackgroundColor
                    }
                    DB_LeftSidebar_MenuContainer_CC__Hover_PaddingTopBottom={
                      DB_LeftSidebar_MenuContainer_CC__Hover_PaddingTopBottom
                    }
                    DB_LeftSidebar_MenuContainer_CC__Hover_PaddingLeftRight={
                      DB_LeftSidebar_MenuContainer_CC__Hover_PaddingLeftRight
                    }
                    DB_LeftSidebar_MenuContainer_CC__Hover_BorderTop={
                      DB_LeftSidebar_MenuContainer_CC__Hover_BorderTop
                    }
                    DB_LeftSidebar_MenuContainer_CC__Hover_BorderStyleTop={
                      DB_LeftSidebar_MenuContainer_CC__Hover_BorderStyleTop
                    }
                    DB_LeftSidebar_MenuContainer_CC__Hover_BorderColorTop={
                      DB_LeftSidebar_MenuContainer_CC__Hover_BorderColorTop
                    }
                    DB_LeftSidebar_MenuContainer_CC__Hover_BorderBottom={
                      DB_LeftSidebar_MenuContainer_CC__Hover_BorderBottom
                    }
                    DB_LeftSidebar_MenuContainer_CC__Hover_BorderStyleBottom={
                      DB_LeftSidebar_MenuContainer_CC__Hover_BorderStyleBottom
                    }
                    DB_LeftSidebar_MenuContainer_CC__Hover_BorderColorBottom={
                      DB_LeftSidebar_MenuContainer_CC__Hover_BorderColorBottom
                    }
                    DB_LeftSidebar_MenuContainer_CC__Hover_BorderLeft={
                      DB_LeftSidebar_MenuContainer_CC__Hover_BorderLeft
                    }
                    DB_LeftSidebar_MenuContainer_CC__Hover_BorderStyleLeft={
                      DB_LeftSidebar_MenuContainer_CC__Hover_BorderStyleLeft
                    }
                    DB_LeftSidebar_MenuContainer_CC__Hover_BorderColorLeft={
                      DB_LeftSidebar_MenuContainer_CC__Hover_BorderColorLeft
                    }
                    DB_LeftSidebar_MenuContainer_CC__Hover_BorderRight={
                      DB_LeftSidebar_MenuContainer_CC__Hover_BorderRight
                    }
                    DB_LeftSidebar_MenuContainer_CC__Hover_BorderStyleRight={
                      DB_LeftSidebar_MenuContainer_CC__Hover_BorderStyleRight
                    }
                    DB_LeftSidebar_MenuContainer_CC__Hover_BorderColorRight={
                      DB_LeftSidebar_MenuContainer_CC__Hover_BorderColorRight
                    }
                    DB_LeftSidebar_MenuItem_MenuContainer_CC__Hover_BorderTopRightRadius={
                      DB_LeftSidebar_MenuItem_MenuContainer_CC__Hover_BorderTopRightRadius
                    }
                    DB_LeftSidebar_MenuItem_MenuContainer_CC__Hover_BorderTopLeftRadius={
                      DB_LeftSidebar_MenuItem_MenuContainer_CC__Hover_BorderTopLeftRadius
                    }
                    DB_LeftSidebar_MenuItem_MenuContainer_CC__Hover_BorderBottomRightRadius={
                      DB_LeftSidebar_MenuItem_MenuContainer_CC__Hover_BorderBottomRightRadius
                    }
                    DB_LeftSidebar_MenuItem_MenuContainer_CC__Hover_BorderBottomLeftRadius={
                      DB_LeftSidebar_MenuItem_MenuContainer_CC__Hover_BorderBottomLeftRadius
                    }
                    DB_LeftSidebar_MenuItem_MenuContainer_CC__Hover_paddingLeft={
                      DB_LeftSidebar_MenuItem_MenuContainer_CC__Hover_paddingLeft
                    }
                    DB_LeftSidebar_MenuItem_MenuContainer_CC__Hover_paddingRight={
                      DB_LeftSidebar_MenuItem_MenuContainer_CC__Hover_paddingRight
                    }
                    DB_LeftSidebar_MenuItem_MenuContainer_CC__Hover_paddingTop={
                      DB_LeftSidebar_MenuItem_MenuContainer_CC__Hover_paddingTop
                    }
                    DB_LeftSidebar_MenuItem_MenuContainer_CC__Hover_paddingBottom={
                      DB_LeftSidebar_MenuItem_MenuContainer_CC__Hover_paddingBottom
                    }
                    DB_LeftSidebar_MenuItem_MenuContainer_CC__Hover_ShadowHorizontal={
                      DB_LeftSidebar_MenuItem_MenuContainer_CC__Hover_ShadowHorizontal
                    }
                    DB_LeftSidebar_MenuItem_MenuContainer_CC__Hover_ShadowVertical={
                      DB_LeftSidebar_MenuItem_MenuContainer_CC__Hover_ShadowVertical
                    }
                    DB_LeftSidebar_MenuItem_MenuContainer_CC__Hover_ShadowBlur={
                      DB_LeftSidebar_MenuItem_MenuContainer_CC__Hover_ShadowBlur
                    }
                    DB_LeftSidebar_MenuItem_MenuContainer_CC__Hover_ShadowColor={
                      DB_LeftSidebar_MenuItem_MenuContainer_CC__Hover_ShadowColor
                    }
                  />
                )}
                {specialLeftFiltersVisible && (
                  <SideBarRightFiltersMain
                    resetFilterHandler={resetFilterHandler}
                    ultraFiltersState={ultraFiltersState}
                    filterProduktsHandler={filterProduktsHandler}
                    isCheck={isCheck}
                    DB_fontFace_FontFamilyName={DB_fontFace_FontFamilyName}
                    SideBarLeft_ContainerFilter_Farbe_Groesse_Stil_Trigger={
                      SideBarLeft_ContainerFilter_Farbe_Groesse_Stil_Trigger
                    }
                    SideBarLeft_ContainerFilter_Farbe_Groesse_Stil_Hover_Trigger={
                      SideBarLeft_ContainerFilter_Farbe_Groesse_Stil_Hover_Trigger
                    }
                    SideBarLeft_ContainerFilter_HintergrundFarbe_Radius_Stil_Trigger={
                      SideBarLeft_ContainerFilter_HintergrundFarbe_Radius_Stil_Trigger
                    }
                    SideBarLeft_ContainerFilter_HintergrundFarbe_Radius_Stil_Hover_Trigger={
                      SideBarLeft_ContainerFilter_HintergrundFarbe_Radius_Stil_Hover_Trigger
                    }
                    SideBarLeft_AlleFilter_Farbe_Groesse_Stil_Trigger={
                      SideBarLeft_AlleFilter_Farbe_Groesse_Stil_Trigger
                    }
                    SideBarLeft_AlleFilter_Farbe_Groesse_Stil_Hover_Trigger={
                      SideBarLeft_AlleFilter_Farbe_Groesse_Stil_Hover_Trigger
                    }
                    SideBarLeft_AlleFilter_HintergrundFarbe_Radius_Stil_Trigger={
                      SideBarLeft_AlleFilter_HintergrundFarbe_Radius_Stil_Trigger
                    }
                    SideBarLeft_AlleFilter_HintergrundFarbe_Radius_Stil_Hover_Trigger={
                      SideBarLeft_AlleFilter_HintergrundFarbe_Radius_Stil_Hover_Trigger
                    }
                    SideBarLeft_HauptFiltertitel_Farbe_Groesse_Stil_Trigger={
                      SideBarLeft_HauptFiltertitel_Farbe_Groesse_Stil_Trigger
                    }
                    SideBarLeft_HauptFiltertitel_Farbe_Groesse_Stil_Hover_Trigger={
                      SideBarLeft_HauptFiltertitel_Farbe_Groesse_Stil_Hover_Trigger
                    }
                    SideBarLeft_HauptFiltertitel_HintergrundFarbe_Radius_Stil_Trigger={
                      SideBarLeft_HauptFiltertitel_HintergrundFarbe_Radius_Stil_Trigger
                    }
                    SideBarLeft_HauptFiltertitel_HintergrundFarbe_Radius_Stil_Hover_Trigger={
                      SideBarLeft_HauptFiltertitel_HintergrundFarbe_Radius_Stil_Hover_Trigger
                    }
                    SideBarLeft_FilterItem_Farbe_Groesse_Stil_Trigger={
                      SideBarLeft_FilterItem_Farbe_Groesse_Stil_Trigger
                    }
                    SideBarLeft_FilterItem_Farbe_Groesse_Stil_Hover_Trigger={
                      SideBarLeft_FilterItem_Farbe_Groesse_Stil_Hover_Trigger
                    }
                    SideBarLeft_FilterItem_HintergrundFarbe_Radius_Stil_Trigger={
                      SideBarLeft_FilterItem_HintergrundFarbe_Radius_Stil_Trigger
                    }
                    SideBarLeft_FilterItem_HintergrundFarbe_Radius_Stil_Hover_Trigger={
                      SideBarLeft_FilterItem_HintergrundFarbe_Radius_Stil_Hover_Trigger
                    }
                    SideBarLeft_ContainerFilter_Farbe_Groesse_Stil_OnMouseOverAndOut={
                      SideBarLeft_ContainerFilter_Farbe_Groesse_Stil_OnMouseOverAndOut
                    }
                    SideBarLeft_ContainerFilter_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut={
                      SideBarLeft_ContainerFilter_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
                    }
                    SideBarLeft_ContainerFilter_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut={
                      SideBarLeft_ContainerFilter_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut
                    }
                    SideBarLeft_ContainerFilter_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut={
                      SideBarLeft_ContainerFilter_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut
                    }
                    SideBarLeft_AlleFilter_Farbe_Groesse_Stil_OnMouseOverAndOut={
                      SideBarLeft_AlleFilter_Farbe_Groesse_Stil_OnMouseOverAndOut
                    }
                    SideBarLeft_AlleFilter_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut={
                      SideBarLeft_AlleFilter_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
                    }
                    SideBarLeft_AlleFilter_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut={
                      SideBarLeft_AlleFilter_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut
                    }
                    SideBarLeft_AlleFilter_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut={
                      SideBarLeft_AlleFilter_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut
                    }
                    SideBarLeft_HauptFiltertitel_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut={
                      SideBarLeft_HauptFiltertitel_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
                    }
                    SideBarLeft_HauptFiltertitel_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut={
                      SideBarLeft_HauptFiltertitel_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut
                    }
                    SideBarLeft_HauptFiltertitel_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut={
                      SideBarLeft_HauptFiltertitel_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut
                    }
                    SideBarLeft_FilterItem_Farbe_Groesse_Stil_OnMouseOverAndOut={
                      SideBarLeft_FilterItem_Farbe_Groesse_Stil_OnMouseOverAndOut
                    }
                    SideBarLeft_FilterItem_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut={
                      SideBarLeft_FilterItem_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
                    }
                    SideBarLeft_FilterItem_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut={
                      SideBarLeft_FilterItem_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut
                    }
                    SideBarLeft_FilterItem_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut={
                      SideBarLeft_FilterItem_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut
                    }
                    DB_fontFace_AlleFilterButtonFontFamily_FontFamilyName={
                      DB_fontFace_AlleFilterButtonFontFamily_FontFamilyName
                    }
                    DB_fontFace_AlleFilterButtonFontFamily_FontFamilySrc={
                      DB_fontFace_AlleFilterButtonFontFamily_FontFamilySrc
                    }
                    DB_LeftSidebar_MenuItem_AlleFilterButtonFF_FontWeight={
                      DB_LeftSidebar_MenuItem_AlleFilterButtonFF_FontWeight
                    }
                    DB_LeftSidebar_MenuItem_AlleFilterButtonFF_FontColor={
                      DB_LeftSidebar_MenuItem_AlleFilterButtonFF_FontColor
                    }
                    DB_LeftSidebar_MenuItem_AlleFilterButtonFF_FontSize={
                      DB_LeftSidebar_MenuItem_AlleFilterButtonFF_FontSize
                    }
                    DB_LeftSidebar_MenuItem_AlleFilterButtonFF_BackgroundColor={
                      DB_LeftSidebar_MenuItem_AlleFilterButtonFF_BackgroundColor
                    }
                    DB_LeftSidebar_MenuItem_AlleFilterButtonFF_TextAlign={
                      DB_LeftSidebar_MenuItem_AlleFilterButtonFF_TextAlign
                    }
                    DB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderTop={
                      DB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderTop
                    }
                    DB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderStyleTop={
                      DB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderStyleTop
                    }
                    DB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderColorTop={
                      DB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderColorTop
                    }
                    DB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderBottom={
                      DB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderBottom
                    }
                    DB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderStyleBottom={
                      DB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderStyleBottom
                    }
                    DB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderColorBottom={
                      DB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderColorBottom
                    }
                    DB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderLeft={
                      DB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderLeft
                    }
                    DB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderStyleLeft={
                      DB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderStyleLeft
                    }
                    DB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderColorLeft={
                      DB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderColorLeft
                    }
                    DB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderRight={
                      DB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderRight
                    }
                    DB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderStyleRight={
                      DB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderStyleRight
                    }
                    DB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderColorRight={
                      DB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderColorRight
                    }
                    DB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderTopRightRadius={
                      DB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderTopRightRadius
                    }
                    DB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderTopLeftRadius={
                      DB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderTopLeftRadius
                    }
                    DB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderBottomRightRadius={
                      DB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderBottomRightRadius
                    }
                    DB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderBottomLeftRadius={
                      DB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderBottomLeftRadius
                    }
                    DB_LeftSidebar_MenuItem_AlleFilterButtonFF_marginLeftRight={
                      DB_LeftSidebar_MenuItem_AlleFilterButtonFF_marginLeftRight
                    }
                    DB_LeftSidebar_MenuItem_AlleFilterButtonFF_marginTopBottom={
                      DB_LeftSidebar_MenuItem_AlleFilterButtonFF_marginTopBottom
                    }
                    DB_LeftSidebar_MenuItem_AlleFilterButtonFF_paddingLeftRight={
                      DB_LeftSidebar_MenuItem_AlleFilterButtonFF_paddingLeftRight
                    }
                    DB_LeftSidebar_MenuItem_AlleFilterButtonFF_paddingTopBottom={
                      DB_LeftSidebar_MenuItem_AlleFilterButtonFF_paddingTopBottom
                    }
                    DB_LeftSidebar_MenuItem_AlleFilterButtonFF_letterSpacing={
                      DB_LeftSidebar_MenuItem_AlleFilterButtonFF_letterSpacing
                    }
                    DB_LeftSidebar_MenuItem_AlleFilterButtonFF_ShadowHorizontal={
                      DB_LeftSidebar_MenuItem_AlleFilterButtonFF_ShadowHorizontal
                    }
                    DB_LeftSidebar_MenuItem_AlleFilterButtonFF_ShadowVertical={
                      DB_LeftSidebar_MenuItem_AlleFilterButtonFF_ShadowVertical
                    }
                    DB_LeftSidebar_MenuItem_AlleFilterButtonFF_ShadowBlur={
                      DB_LeftSidebar_MenuItem_AlleFilterButtonFF_ShadowBlur
                    }
                    DB_LeftSidebar_MenuItem_AlleFilterButtonFF_ShadowColor={
                      DB_LeftSidebar_MenuItem_AlleFilterButtonFF_ShadowColor
                    }
                    DB_LeftSidebar_MenuItem_AlleFilterButtonFF_TextDecoration={
                      DB_LeftSidebar_MenuItem_AlleFilterButtonFF_TextDecoration
                    }
                    DB_LeftSidebar_MenuItem_AlleFilterButtonFF_TextDecorationStyle={
                      DB_LeftSidebar_MenuItem_AlleFilterButtonFF_TextDecorationStyle
                    }
                    DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_FontWeight={
                      DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_FontWeight
                    }
                    DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_FontColor={
                      DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_FontColor
                    }
                    DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_FontSize={
                      DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_FontSize
                    }
                    DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BackgroundColor={
                      DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BackgroundColor
                    }
                    DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_TextAlign={
                      DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_TextAlign
                    }
                    DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderTop={
                      DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderTop
                    }
                    DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderStyleTop={
                      DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderStyleTop
                    }
                    DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderColorTop={
                      DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderColorTop
                    }
                    DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderBottom={
                      DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderBottom
                    }
                    DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderStyleBottom={
                      DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderStyleBottom
                    }
                    DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderColorBottom={
                      DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderColorBottom
                    }
                    DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderLeft={
                      DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderLeft
                    }
                    DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderStyleLeft={
                      DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderStyleLeft
                    }
                    DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderColorLeft={
                      DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderColorLeft
                    }
                    DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderRight={
                      DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderRight
                    }
                    DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderStyleRight={
                      DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderStyleRight
                    }
                    DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderColorRight={
                      DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderColorRight
                    }
                    DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderTopRightRadius={
                      DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderTopRightRadius
                    }
                    DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderTopLeftRadius={
                      DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderTopLeftRadius
                    }
                    DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderBottomRightRadius={
                      DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderBottomRightRadius
                    }
                    DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderBottomLeftRadius={
                      DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderBottomLeftRadius
                    }
                    DB_LeftSidebar_MenuItem_AlleFilterButtonFF_marginLeftRight_Hover={
                      DB_LeftSidebar_MenuItem_AlleFilterButtonFF_marginLeftRight_Hover
                    }
                    DB_LeftSidebar_MenuItem_AlleFilterButtonFF_marginTopBottom_Hover={
                      DB_LeftSidebar_MenuItem_AlleFilterButtonFF_marginTopBottom_Hover
                    }
                    DB_LeftSidebar_MenuItem_AlleFilterButtonFF_paddingLeftRight_Hover={
                      DB_LeftSidebar_MenuItem_AlleFilterButtonFF_paddingLeftRight_Hover
                    }
                    DB_LeftSidebar_MenuItem_AlleFilterButtonFF_paddingTopBottom_Hover={
                      DB_LeftSidebar_MenuItem_AlleFilterButtonFF_paddingTopBottom_Hover
                    }
                    DB_LeftSidebar_MenuItem_AlleFilterButtonFF_letterSpacing_Hover={
                      DB_LeftSidebar_MenuItem_AlleFilterButtonFF_letterSpacing_Hover
                    }
                    DB_LeftSidebar_MenuItem_AlleFilterButtonFF_ShadowHorizontal_Hover={
                      DB_LeftSidebar_MenuItem_AlleFilterButtonFF_ShadowHorizontal_Hover
                    }
                    DB_LeftSidebar_MenuItem_AlleFilterButtonFF_ShadowVertical_Hover={
                      DB_LeftSidebar_MenuItem_AlleFilterButtonFF_ShadowVertical_Hover
                    }
                    DB_LeftSidebar_MenuItem_AlleFilterButtonFF_ShadowBlur_Hover={
                      DB_LeftSidebar_MenuItem_AlleFilterButtonFF_ShadowBlur_Hover
                    }
                    DB_LeftSidebar_MenuItem_AlleFilterButtonFF_ShadowColor_Hover={
                      DB_LeftSidebar_MenuItem_AlleFilterButtonFF_ShadowColor_Hover
                    }
                    DB_LeftSidebar_MenuItem_AlleFilterButtonFF_TextDecoration_Hover={
                      DB_LeftSidebar_MenuItem_AlleFilterButtonFF_TextDecoration_Hover
                    }
                    DB_LeftSidebar_MenuItem_AlleFilterButtonFF_TextDecorationStyle_Hover={
                      DB_LeftSidebar_MenuItem_AlleFilterButtonFF_TextDecorationStyle_Hover
                    }
                    DB_fontFace_FilterHauptTitel_FontFamilyName={
                      DB_fontFace_FilterHauptTitel_FontFamilyName
                    }
                    DB_fontFace_FilterHauptTitel_FontFamilySrc={
                      DB_fontFace_FilterHauptTitel_FontFamilySrc
                    }
                    DB_LeftSidebar_MenuItem_FilterHauptTitel_FontFace={
                      DB_LeftSidebar_MenuItem_FilterHauptTitel_FontFace
                    }
                    DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_FontFace={
                      DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_FontFace
                    }
                    DB_LeftSidebar_MenuItem_FilterHauptTitel_FontWeight={
                      DB_LeftSidebar_MenuItem_FilterHauptTitel_FontWeight
                    }
                    DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_FontWeight={
                      DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_FontWeight
                    }
                    DB_LeftSidebar_MenuItem_FilterHauptTitel_FontColor={
                      DB_LeftSidebar_MenuItem_FilterHauptTitel_FontColor
                    }
                    DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_FontColor={
                      DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_FontColor
                    }
                    DB_LeftSidebar_MenuItem_FilterHauptTitel_FontSize={
                      DB_LeftSidebar_MenuItem_FilterHauptTitel_FontSize
                    }
                    DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_FontSize={
                      DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_FontSize
                    }
                    SideBarLeft_HauptFiltertitel_Farbe_Groesse_Stil_OnMouseOverAndOut={
                      SideBarLeft_HauptFiltertitel_Farbe_Groesse_Stil_OnMouseOverAndOut
                    }
                    SideBarLeft_HauptFiltertitel_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut={
                      SideBarLeft_HauptFiltertitel_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut
                    }
                    SideBarLeft_HauptFiltertitel_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut={
                      SideBarLeft_HauptFiltertitel_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut
                    }
                    DB_LeftSidebar_MenuItem_FilterHauptTitel_BackgroundColor={
                      DB_LeftSidebar_MenuItem_FilterHauptTitel_BackgroundColor
                    }
                    DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BackgroundColor={
                      DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BackgroundColor
                    }
                    DB_LeftSidebar_MenuItem_FilterHauptTitel_TextAlign={
                      DB_LeftSidebar_MenuItem_FilterHauptTitel_TextAlign
                    }
                    DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_TextAlign={
                      DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_TextAlign
                    }
                    DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderTop={
                      DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderTop
                    }
                    DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderTop={
                      DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderTop
                    }
                    DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderStyleTop={
                      DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderStyleTop
                    }
                    DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderStyleTop={
                      DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderStyleTop
                    }
                    DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderColorTop={
                      DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderColorTop
                    }
                    DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderColorTop={
                      DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderColorTop
                    }
                    DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderBottom={
                      DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderBottom
                    }
                    DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderBottom={
                      DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderBottom
                    }
                    DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderStyleBottom={
                      DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderStyleBottom
                    }
                    DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderStyleBottom={
                      DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderStyleBottom
                    }
                    DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderColorBottom={
                      DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderColorBottom
                    }
                    DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderColorBottom={
                      DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderColorBottom
                    }
                    DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderLeft={
                      DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderLeft
                    }
                    DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderLeft={
                      DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderLeft
                    }
                    DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderStyleLeft={
                      DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderStyleLeft
                    }
                    DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderStyleLeft={
                      DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderStyleLeft
                    }
                    DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderColorLeft={
                      DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderColorLeft
                    }
                    DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderColorLeft={
                      DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderColorLeft
                    }
                    DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderRight={
                      DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderRight
                    }
                    DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderRight={
                      DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderRight
                    }
                    DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderStyleRight={
                      DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderStyleRight
                    }
                    DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderStyleRight={
                      DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderStyleRight
                    }
                    DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderColorRight={
                      DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderColorRight
                    }
                    DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderColorRight={
                      DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderColorRight
                    }
                    DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderTopRightRadius={
                      DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderTopRightRadius
                    }
                    DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderTopRightRadius={
                      DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderTopRightRadius
                    }
                    DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderTopLeftRadius={
                      DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderTopLeftRadius
                    }
                    DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderTopLeftRadius={
                      DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderTopLeftRadius
                    }
                    DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderBottomRightRadius={
                      DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderBottomRightRadius
                    }
                    DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderBottomRightRadius={
                      DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderBottomRightRadius
                    }
                    DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderBottomLeftRadius={
                      DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderBottomLeftRadius
                    }
                    DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderBottomLeftRadius={
                      DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderBottomLeftRadius
                    }
                    /* handleShow_showConfig_LeftSidebar_MenuMainUltraFilterHauptTitel={
                  handleShow_showConfig_LeftSidebar_MenuMainUltraFilterHauptTitel
                }
                handleShow_showConfig_LeftSidebar_MenuMainUltraFilterHauptTitel_BorderAndColor={
                  handleShow_showConfig_LeftSidebar_MenuMainUltraFilterHauptTitel_BorderAndColor
                }
                handleShow_showConfig_LeftSidebar_MenuMainUltraFilterHauptTitel_Hover={
                  handleShow_showConfig_LeftSidebar_MenuMainUltraFilterHauptTitel_Hover
                }
                handleShow_showConfig_LeftSidebar_MenuMainUltraFilterHauptTitel_Hover_BorderAndColor={
                  handleShow_showConfig_LeftSidebar_MenuMainUltraFilterHauptTitel_Hover_BorderAndColor
                } */
                    DB_LeftSidebar_MenuItem_FilterHauptTitel_marginTopBottom={
                      DB_LeftSidebar_MenuItem_FilterHauptTitel_marginTopBottom
                    }
                    DB_LeftSidebar_MenuItem_FilterHauptTitel_marginLeftRight={
                      DB_LeftSidebar_MenuItem_FilterHauptTitel_marginLeftRight
                    }
                    DB_LeftSidebar_MenuItem_FilterHauptTitel_paddingLeftRight={
                      DB_LeftSidebar_MenuItem_FilterHauptTitel_paddingLeftRight
                    }
                    DB_LeftSidebar_MenuItem_FilterHauptTitel_paddingTopBottom={
                      DB_LeftSidebar_MenuItem_FilterHauptTitel_paddingTopBottom
                    }
                    DB_LeftSidebar_MenuItem_FilterHauptTitel_letterSpacing={
                      DB_LeftSidebar_MenuItem_FilterHauptTitel_letterSpacing
                    }
                    DB_LeftSidebar_MenuItem_FilterHauptTitel_ShadowHorizontal={
                      DB_LeftSidebar_MenuItem_FilterHauptTitel_ShadowHorizontal
                    }
                    DB_LeftSidebar_MenuItem_FilterHauptTitel_ShadowVertical={
                      DB_LeftSidebar_MenuItem_FilterHauptTitel_ShadowVertical
                    }
                    DB_LeftSidebar_MenuItem_FilterHauptTitel_ShadowBlur={
                      DB_LeftSidebar_MenuItem_FilterHauptTitel_ShadowBlur
                    }
                    DB_LeftSidebar_MenuItem_FilterHauptTitel_ShadowColor={
                      DB_LeftSidebar_MenuItem_FilterHauptTitel_ShadowColor
                    }
                    DB_LeftSidebar_MenuItem_FilterHauptTitel_TextDecoration={
                      DB_LeftSidebar_MenuItem_FilterHauptTitel_TextDecoration
                    }
                    DB_LeftSidebar_MenuItem_FilterHauptTitel_TextDecorationStyle={
                      DB_LeftSidebar_MenuItem_FilterHauptTitel_TextDecorationStyle
                    }
                    DB_LeftSidebar_MenuItem_FilterHauptTitel_marginTopBottom_Hover={
                      DB_LeftSidebar_MenuItem_FilterHauptTitel_marginTopBottom_Hover
                    }
                    DB_LeftSidebar_MenuItem_FilterHauptTitel_marginLeftRight_Hover={
                      DB_LeftSidebar_MenuItem_FilterHauptTitel_marginLeftRight_Hover
                    }
                    DB_LeftSidebar_MenuItem_FilterHauptTitel_paddingLeftRight_Hover={
                      DB_LeftSidebar_MenuItem_FilterHauptTitel_paddingLeftRight_Hover
                    }
                    DB_LeftSidebar_MenuItem_FilterHauptTitel_paddingTopBottom_Hover={
                      DB_LeftSidebar_MenuItem_FilterHauptTitel_paddingTopBottom_Hover
                    }
                    DB_LeftSidebar_MenuItem_FilterHauptTitel_letterSpacing_Hover={
                      DB_LeftSidebar_MenuItem_FilterHauptTitel_letterSpacing_Hover
                    }
                    DB_LeftSidebar_MenuItem_FilterHauptTitel_ShadowHorizontal_Hover={
                      DB_LeftSidebar_MenuItem_FilterHauptTitel_ShadowHorizontal_Hover
                    }
                    DB_LeftSidebar_MenuItem_FilterHauptTitel_ShadowVertical_Hover={
                      DB_LeftSidebar_MenuItem_FilterHauptTitel_ShadowVertical_Hover
                    }
                    DB_LeftSidebar_MenuItem_FilterHauptTitel_ShadowBlur_Hover={
                      DB_LeftSidebar_MenuItem_FilterHauptTitel_ShadowBlur_Hover
                    }
                    DB_LeftSidebar_MenuItem_FilterHauptTitel_ShadowColor_Hover={
                      DB_LeftSidebar_MenuItem_FilterHauptTitel_ShadowColor_Hover
                    }
                    DB_LeftSidebar_MenuItem_FilterHauptTitel_TextDecoration_Hover={
                      DB_LeftSidebar_MenuItem_FilterHauptTitel_TextDecoration_Hover
                    }
                    DB_LeftSidebar_MenuItem_FilterHauptTitel_TextDecorationStyle_Hover={
                      DB_LeftSidebar_MenuItem_FilterHauptTitel_TextDecorationStyle_Hover
                    }
                    DB_LeftSidebar_MenuContainerFilterGG_BackgroundColor={
                      DB_LeftSidebar_MenuContainerFilterGG_BackgroundColor
                    }
                    DB_LeftSidebar_MenuContainerFilterGG_BorderTop={
                      DB_LeftSidebar_MenuContainerFilterGG_BorderTop
                    }
                    DB_LeftSidebar_MenuContainerFilterGG_BorderStyleTop={
                      DB_LeftSidebar_MenuContainerFilterGG_BorderStyleTop
                    }
                    DB_LeftSidebar_MenuContainerFilterGG_BorderColorTop={
                      DB_LeftSidebar_MenuContainerFilterGG_BorderColorTop
                    }
                    DB_LeftSidebar_MenuContainerFilterGG_BorderBottom={
                      DB_LeftSidebar_MenuContainerFilterGG_BorderBottom
                    }
                    DB_LeftSidebar_MenuContainerFilterGG_BorderStyleBottom={
                      DB_LeftSidebar_MenuContainerFilterGG_BorderStyleBottom
                    }
                    DB_LeftSidebar_MenuContainerFilterGG_BorderColorBottom={
                      DB_LeftSidebar_MenuContainerFilterGG_BorderColorBottom
                    }
                    DB_LeftSidebar_MenuContainerFilterGG_BorderLeft={
                      DB_LeftSidebar_MenuContainerFilterGG_BorderLeft
                    }
                    DB_LeftSidebar_MenuContainerFilterGG_BorderStyleLeft={
                      DB_LeftSidebar_MenuContainerFilterGG_BorderStyleLeft
                    }
                    DB_LeftSidebar_MenuContainerFilterGG_BorderColorLeft={
                      DB_LeftSidebar_MenuContainerFilterGG_BorderColorLeft
                    }
                    DB_LeftSidebar_MenuContainerFilterGG_BorderRight={
                      DB_LeftSidebar_MenuContainerFilterGG_BorderRight
                    }
                    DB_LeftSidebar_MenuContainerFilterGG_BorderStyleRight={
                      DB_LeftSidebar_MenuContainerFilterGG_BorderStyleRight
                    }
                    DB_LeftSidebar_MenuContainerFilterGG_BorderColorRight={
                      DB_LeftSidebar_MenuContainerFilterGG_BorderColorRight
                    }
                    DB_LeftSidebar_MenuItem_MenuContainerFilterGG_BorderTopRightRadius={
                      DB_LeftSidebar_MenuItem_MenuContainerFilterGG_BorderTopRightRadius
                    }
                    DB_LeftSidebar_MenuItem_MenuContainerFilterGG_BorderTopLeftRadius={
                      DB_LeftSidebar_MenuItem_MenuContainerFilterGG_BorderTopLeftRadius
                    }
                    DB_LeftSidebar_MenuItem_MenuContainerFilterGG_BorderBottomRightRadius={
                      DB_LeftSidebar_MenuItem_MenuContainerFilterGG_BorderBottomRightRadius
                    }
                    DB_LeftSidebar_MenuItem_MenuContainerFilterGG_BorderBottomLeftRadius={
                      DB_LeftSidebar_MenuItem_MenuContainerFilterGG_BorderBottomLeftRadius
                    }
                    DB_LeftSidebar_MenuItem_MenuContainerFilterGG_paddingLeft={
                      DB_LeftSidebar_MenuItem_MenuContainerFilterGG_paddingLeft
                    }
                    DB_LeftSidebar_MenuItem_MenuContainerFilterGG_paddingRight={
                      DB_LeftSidebar_MenuItem_MenuContainerFilterGG_paddingRight
                    }
                    DB_LeftSidebar_MenuItem_MenuContainerFilterGG_paddingTop={
                      DB_LeftSidebar_MenuItem_MenuContainerFilterGG_paddingTop
                    }
                    DB_LeftSidebar_MenuItem_MenuContainerFilterGG_paddingBottom={
                      DB_LeftSidebar_MenuItem_MenuContainerFilterGG_paddingBottom
                    }
                    DB_LeftSidebar_MenuItem_MenuContainerFilterGG_ShadowHorizontal={
                      DB_LeftSidebar_MenuItem_MenuContainerFilterGG_ShadowHorizontal
                    }
                    DB_LeftSidebar_MenuItem_MenuContainerFilterGG_ShadowVertical={
                      DB_LeftSidebar_MenuItem_MenuContainerFilterGG_ShadowVertical
                    }
                    DB_LeftSidebar_MenuItem_MenuContainerFilterGG_ShadowBlur={
                      DB_LeftSidebar_MenuItem_MenuContainerFilterGG_ShadowBlur
                    }
                    DB_LeftSidebar_MenuItem_MenuContainerFilterGG_ShadowColor={
                      DB_LeftSidebar_MenuItem_MenuContainerFilterGG_ShadowColor
                    }
                    DB_LeftSidebar_MenuContainerFilterGG_Hover_BackgroundColor={
                      DB_LeftSidebar_MenuContainerFilterGG_Hover_BackgroundColor
                    }
                    DB_LeftSidebar_MenuContainerFilterGG_Hover_BorderTop={
                      DB_LeftSidebar_MenuContainerFilterGG_Hover_BorderTop
                    }
                    DB_LeftSidebar_MenuContainerFilterGG_Hover_BorderStyleTop={
                      DB_LeftSidebar_MenuContainerFilterGG_Hover_BorderStyleTop
                    }
                    DB_LeftSidebar_MenuContainerFilterGG_Hover_BorderColorTop={
                      DB_LeftSidebar_MenuContainerFilterGG_Hover_BorderColorTop
                    }
                    DB_LeftSidebar_MenuContainerFilterGG_Hover_BorderBottom={
                      DB_LeftSidebar_MenuContainerFilterGG_Hover_BorderBottom
                    }
                    DB_LeftSidebar_MenuContainerFilterGG_Hover_BorderStyleBottom={
                      DB_LeftSidebar_MenuContainerFilterGG_Hover_BorderStyleBottom
                    }
                    DB_LeftSidebar_MenuContainerFilterGG_Hover_BorderColorBottom={
                      DB_LeftSidebar_MenuContainerFilterGG_Hover_BorderColorBottom
                    }
                    DB_LeftSidebar_MenuContainerFilterGG_Hover_BorderLeft={
                      DB_LeftSidebar_MenuContainerFilterGG_Hover_BorderLeft
                    }
                    DB_LeftSidebar_MenuContainerFilterGG_Hover_BorderStyleLeft={
                      DB_LeftSidebar_MenuContainerFilterGG_Hover_BorderStyleLeft
                    }
                    DB_LeftSidebar_MenuContainerFilterGG_Hover_BorderColorLeft={
                      DB_LeftSidebar_MenuContainerFilterGG_Hover_BorderColorLeft
                    }
                    DB_LeftSidebar_MenuContainerFilterGG_Hover_BorderRight={
                      DB_LeftSidebar_MenuContainerFilterGG_Hover_BorderRight
                    }
                    DB_LeftSidebar_MenuContainerFilterGG_Hover_BorderStyleRight={
                      DB_LeftSidebar_MenuContainerFilterGG_Hover_BorderStyleRight
                    }
                    DB_LeftSidebar_MenuContainerFilterGG_Hover_BorderColorRight={
                      DB_LeftSidebar_MenuContainerFilterGG_Hover_BorderColorRight
                    }
                    DB_LeftSidebar_MenuItem_MenuContainerFilterGG_Hover_BorderTopRightRadius={
                      DB_LeftSidebar_MenuItem_MenuContainerFilterGG_Hover_BorderTopRightRadius
                    }
                    DB_LeftSidebar_MenuItem_MenuContainerFilterGG_Hover_BorderTopLeftRadius={
                      DB_LeftSidebar_MenuItem_MenuContainerFilterGG_Hover_BorderTopLeftRadius
                    }
                    DB_LeftSidebar_MenuItem_MenuContainerFilterGG_Hover_BorderBottomRightRadius={
                      DB_LeftSidebar_MenuItem_MenuContainerFilterGG_Hover_BorderBottomRightRadius
                    }
                    DB_LeftSidebar_MenuItem_MenuContainerFilterGG_Hover_BorderBottomLeftRadius={
                      DB_LeftSidebar_MenuItem_MenuContainerFilterGG_Hover_BorderBottomLeftRadius
                    }
                    DB_LeftSidebar_MenuItem_MenuContainerFilterGG_Hover_paddingLeft={
                      DB_LeftSidebar_MenuItem_MenuContainerFilterGG_Hover_paddingLeft
                    }
                    DB_LeftSidebar_MenuItem_MenuContainerFilterGG_Hover_paddingRight={
                      DB_LeftSidebar_MenuItem_MenuContainerFilterGG_Hover_paddingRight
                    }
                    DB_LeftSidebar_MenuItem_MenuContainerFilterGG_Hover_paddingTop={
                      DB_LeftSidebar_MenuItem_MenuContainerFilterGG_Hover_paddingTop
                    }
                    DB_LeftSidebar_MenuItem_MenuContainerFilterGG_Hover_paddingBottom={
                      DB_LeftSidebar_MenuItem_MenuContainerFilterGG_Hover_paddingBottom
                    }
                    DB_LeftSidebar_MenuItem_MenuContainerFilterGG_Hover_ShadowHorizontal={
                      DB_LeftSidebar_MenuItem_MenuContainerFilterGG_Hover_ShadowHorizontal
                    }
                    DB_LeftSidebar_MenuItem_MenuContainerFilterGG_Hover_ShadowVertical={
                      DB_LeftSidebar_MenuItem_MenuContainerFilterGG_Hover_ShadowVertical
                    }
                    DB_LeftSidebar_MenuItem_MenuContainerFilterGG_Hover_ShadowBlur={
                      DB_LeftSidebar_MenuItem_MenuContainerFilterGG_Hover_ShadowBlur
                    }
                    DB_LeftSidebar_MenuItem_MenuContainerFilterGG_Hover_ShadowColor={
                      DB_LeftSidebar_MenuItem_MenuContainerFilterGG_Hover_ShadowColor
                    }
                    DB_fontFace_FilterItemFontFamily_FontFamilyName={
                      DB_fontFace_FilterItemFontFamily_FontFamilyName
                    }
                    DB_fontFace_FilterItemFontFamily_FontFamilySrc={
                      DB_fontFace_FilterItemFontFamily_FontFamilySrc
                    }
                    DB_LeftSidebar_MenuItem_FilterItem_FontWeight={
                      DB_LeftSidebar_MenuItem_FilterItem_FontWeight
                    }
                    DB_LeftSidebar_MenuItem_FilterItem_Hover_FontWeight={
                      DB_LeftSidebar_MenuItem_FilterItem_Hover_FontWeight
                    }
                    DB_LeftSidebar_MenuItem_FilterItem_FontColor={
                      DB_LeftSidebar_MenuItem_FilterItem_FontColor
                    }
                    DB_LeftSidebar_MenuItem_FilterItem_Hover_FontColor={
                      DB_LeftSidebar_MenuItem_FilterItem_Hover_FontColor
                    }
                    DB_LeftSidebar_MenuItem_FilterItem_FontSize={
                      DB_LeftSidebar_MenuItem_FilterItem_FontSize
                    }
                    DB_LeftSidebar_MenuItem_FilterItem_Hover_FontSize={
                      DB_LeftSidebar_MenuItem_FilterItem_Hover_FontSize
                    }
                    SideBarLeft_FilterItem_Farbe_Groesse_Stil_OnMouseOverAndOut={
                      SideBarLeft_FilterItem_Farbe_Groesse_Stil_OnMouseOverAndOut
                    }
                    SideBarLeft_FilterItem_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut={
                      SideBarLeft_FilterItem_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
                    }
                    SideBarLeft_FilterItem_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut={
                      SideBarLeft_FilterItem_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut
                    }
                    SideBarLeft_FilterItem_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut={
                      SideBarLeft_FilterItem_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut
                    }
                    DB_LeftSidebar_MenuItem_FilterItem_BackgroundColor={
                      DB_LeftSidebar_MenuItem_FilterItem_BackgroundColor
                    }
                    DB_LeftSidebar_MenuItem_FilterItem_Hover_BackgroundColor={
                      DB_LeftSidebar_MenuItem_FilterItem_Hover_BackgroundColor
                    }
                    DB_LeftSidebar_MenuItem_FilterItem_TextAlign={
                      DB_LeftSidebar_MenuItem_FilterItem_TextAlign
                    }
                    DB_LeftSidebar_MenuItem_FilterItem_Hover_TextAlign={
                      DB_LeftSidebar_MenuItem_FilterItem_Hover_TextAlign
                    }
                    DB_LeftSidebar_MenuItem_FilterItem_BorderTop={
                      DB_LeftSidebar_MenuItem_FilterItem_BorderTop
                    }
                    DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderTop={
                      DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderTop
                    }
                    DB_LeftSidebar_MenuItem_FilterItem_BorderStyleTop={
                      DB_LeftSidebar_MenuItem_FilterItem_BorderStyleTop
                    }
                    DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderStyleTop={
                      DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderStyleTop
                    }
                    DB_LeftSidebar_MenuItem_FilterItem_BorderColorTop={
                      DB_LeftSidebar_MenuItem_FilterItem_BorderColorTop
                    }
                    DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderColorTop={
                      DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderColorTop
                    }
                    DB_LeftSidebar_MenuItem_FilterItem_BorderBottom={
                      DB_LeftSidebar_MenuItem_FilterItem_BorderBottom
                    }
                    DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderBottom={
                      DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderBottom
                    }
                    DB_LeftSidebar_MenuItem_FilterItem_BorderStyleBottom={
                      DB_LeftSidebar_MenuItem_FilterItem_BorderStyleBottom
                    }
                    DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderStyleBottom={
                      DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderStyleBottom
                    }
                    DB_LeftSidebar_MenuItem_FilterItem_BorderColorBottom={
                      DB_LeftSidebar_MenuItem_FilterItem_BorderColorBottom
                    }
                    DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderColorBottom={
                      DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderColorBottom
                    }
                    DB_LeftSidebar_MenuItem_FilterItem_BorderLeft={
                      DB_LeftSidebar_MenuItem_FilterItem_BorderLeft
                    }
                    DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderLeft={
                      DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderLeft
                    }
                    DB_LeftSidebar_MenuItem_FilterItem_BorderStyleLeft={
                      DB_LeftSidebar_MenuItem_FilterItem_BorderStyleLeft
                    }
                    DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderStyleLeft={
                      DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderStyleLeft
                    }
                    DB_LeftSidebar_MenuItem_FilterItem_BorderColorLeft={
                      DB_LeftSidebar_MenuItem_FilterItem_BorderColorLeft
                    }
                    DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderColorLeft={
                      DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderColorLeft
                    }
                    DB_LeftSidebar_MenuItem_FilterItem_BorderRight={
                      DB_LeftSidebar_MenuItem_FilterItem_BorderRight
                    }
                    DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderRight={
                      DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderRight
                    }
                    DB_LeftSidebar_MenuItem_FilterItem_BorderStyleRight={
                      DB_LeftSidebar_MenuItem_FilterItem_BorderStyleRight
                    }
                    DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderStyleRight={
                      DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderStyleRight
                    }
                    DB_LeftSidebar_MenuItem_FilterItem_BorderColorRight={
                      DB_LeftSidebar_MenuItem_FilterItem_BorderColorRight
                    }
                    DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderColorRight={
                      DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderColorRight
                    }
                    DB_LeftSidebar_MenuItem_FilterItem_BorderTopRightRadius={
                      DB_LeftSidebar_MenuItem_FilterItem_BorderTopRightRadius
                    }
                    DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderTopRightRadius={
                      DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderTopRightRadius
                    }
                    DB_LeftSidebar_MenuItem_FilterItem_BorderTopLeftRadius={
                      DB_LeftSidebar_MenuItem_FilterItem_BorderTopLeftRadius
                    }
                    DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderTopLeftRadius={
                      DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderTopLeftRadius
                    }
                    DB_LeftSidebar_MenuItem_FilterItem_BorderBottomRightRadius={
                      DB_LeftSidebar_MenuItem_FilterItem_BorderBottomRightRadius
                    }
                    DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderBottomRightRadius={
                      DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderBottomRightRadius
                    }
                    DB_LeftSidebar_MenuItem_FilterItem_BorderBottomLeftRadius={
                      DB_LeftSidebar_MenuItem_FilterItem_BorderBottomLeftRadius
                    }
                    DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderBottomLeftRadius={
                      DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderBottomLeftRadius
                    }
                    DB_LeftSidebar_MenuItem_FilterItem_marginTopBottom={
                      DB_LeftSidebar_MenuItem_FilterItem_marginTopBottom
                    }
                    DB_LeftSidebar_MenuItem_FilterItem_marginLeftRight={
                      DB_LeftSidebar_MenuItem_FilterItem_marginLeftRight
                    }
                    DB_LeftSidebar_MenuItem_FilterItem_paddingLeftRight={
                      DB_LeftSidebar_MenuItem_FilterItem_paddingLeftRight
                    }
                    DB_LeftSidebar_MenuItem_FilterItem_paddingTopBottom={
                      DB_LeftSidebar_MenuItem_FilterItem_paddingTopBottom
                    }
                    DB_LeftSidebar_MenuItem_FilterItem_letterSpacing={
                      DB_LeftSidebar_MenuItem_FilterItem_letterSpacing
                    }
                    DB_LeftSidebar_MenuItem_FilterItem_ShadowHorizontal={
                      DB_LeftSidebar_MenuItem_FilterItem_ShadowHorizontal
                    }
                    DB_LeftSidebar_MenuItem_FilterItem_ShadowVertical={
                      DB_LeftSidebar_MenuItem_FilterItem_ShadowVertical
                    }
                    DB_LeftSidebar_MenuItem_FilterItem_ShadowBlur={
                      DB_LeftSidebar_MenuItem_FilterItem_ShadowBlur
                    }
                    DB_LeftSidebar_MenuItem_FilterItem_ShadowColor={
                      DB_LeftSidebar_MenuItem_FilterItem_ShadowColor
                    }
                    DB_LeftSidebar_MenuItem_FilterItem_TextDecoration={
                      DB_LeftSidebar_MenuItem_FilterItem_TextDecoration
                    }
                    DB_LeftSidebar_MenuItem_FilterItem_TextDecorationStyle={
                      DB_LeftSidebar_MenuItem_FilterItem_TextDecorationStyle
                    }
                    DB_LeftSidebar_MenuItem_FilterItem_marginTopBottom_Hover={
                      DB_LeftSidebar_MenuItem_FilterItem_marginTopBottom_Hover
                    }
                    DB_LeftSidebar_MenuItem_FilterItem_marginLeftRight_Hover={
                      DB_LeftSidebar_MenuItem_FilterItem_marginLeftRight_Hover
                    }
                    DB_LeftSidebar_MenuItem_FilterItem_paddingLeftRight_Hover={
                      DB_LeftSidebar_MenuItem_FilterItem_paddingLeftRight_Hover
                    }
                    DB_LeftSidebar_MenuItem_FilterItem_paddingTopBottom_Hover={
                      DB_LeftSidebar_MenuItem_FilterItem_paddingTopBottom_Hover
                    }
                    DB_LeftSidebar_MenuItem_FilterItem_letterSpacing_Hover={
                      DB_LeftSidebar_MenuItem_FilterItem_letterSpacing_Hover
                    }
                    DB_LeftSidebar_MenuItem_FilterItem_ShadowHorizontal_Hover={
                      DB_LeftSidebar_MenuItem_FilterItem_ShadowHorizontal_Hover
                    }
                    DB_LeftSidebar_MenuItem_FilterItem_ShadowVertical_Hover={
                      DB_LeftSidebar_MenuItem_FilterItem_ShadowVertical_Hover
                    }
                    DB_LeftSidebar_MenuItem_FilterItem_ShadowBlur_Hover={
                      DB_LeftSidebar_MenuItem_FilterItem_ShadowBlur_Hover
                    }
                    DB_LeftSidebar_MenuItem_FilterItem_ShadowColor_Hover={
                      DB_LeftSidebar_MenuItem_FilterItem_ShadowColor_Hover
                    }
                    DB_LeftSidebar_MenuItem_FilterItem_TextDecoration_Hover={
                      DB_LeftSidebar_MenuItem_FilterItem_TextDecoration_Hover
                    }
                    DB_LeftSidebar_MenuItem_FilterItem_TextDecorationStyle_Hover={
                      DB_LeftSidebar_MenuItem_FilterItem_TextDecorationStyle_Hover
                    }
                  />
                )}
                {specialLeftSocialsVisible && (
                  <>
                    {(shopBoxes.boxOneTitel || shopBoxes.boxOneContent) && (
                      <SideBarRightMessageToBuyer
                        boxTitel={shopBoxes.boxOneTitel}
                        boxContent={shopBoxes.boxOneContent}
                        SideBarLeft_ContainerSocial_Farbe_Groesse_Stil_OnMouseOverAndOut={
                          SideBarLeft_ContainerSocial_Farbe_Groesse_Stil_OnMouseOverAndOut
                        }
                        SideBarLeft_ContainerSocial_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut={
                          SideBarLeft_ContainerSocial_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
                        }
                        SideBarLeft_ContainerSocial_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut={
                          SideBarLeft_ContainerSocial_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut
                        }
                        SideBarLeft_ContainerSocial_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut={
                          SideBarLeft_ContainerSocial_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut
                        }
                        SideBarLeft_AlleSocial_Farbe_Groesse_Stil_OnMouseOverAndOut={
                          SideBarLeft_AlleSocial_Farbe_Groesse_Stil_OnMouseOverAndOut
                        }
                        SideBarLeft_AlleSocial_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut={
                          SideBarLeft_AlleSocial_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
                        }
                        SideBarLeft_AlleSocial_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut={
                          SideBarLeft_AlleSocial_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut
                        }
                        SideBarLeft_AlleSocial_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut={
                          SideBarLeft_AlleSocial_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut
                        }
                        SideBarLeft_SocialItem_Farbe_Groesse_Stil_OnMouseOverAndOut={
                          SideBarLeft_SocialItem_Farbe_Groesse_Stil_OnMouseOverAndOut
                        }
                        SideBarLeft_SocialItem_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut={
                          SideBarLeft_SocialItem_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
                        }
                        SideBarLeft_SocialItem_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut={
                          SideBarLeft_SocialItem_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut
                        }
                        SideBarLeft_SocialItem_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut={
                          SideBarLeft_SocialItem_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut
                        }
                        SideBarLeft_ContainerSocial_Farbe_Groesse_Stil_Trigger={
                          SideBarLeft_ContainerSocial_Farbe_Groesse_Stil_Trigger
                        }
                        SideBarLeft_ContainerSocial_Farbe_Groesse_Stil_Hover_Trigger={
                          SideBarLeft_ContainerSocial_Farbe_Groesse_Stil_Hover_Trigger
                        }
                        SideBarLeft_ContainerSocial_HintergrundFarbe_Radius_Stil_Trigger={
                          SideBarLeft_ContainerSocial_HintergrundFarbe_Radius_Stil_Trigger
                        }
                        SideBarLeft_ContainerSocial_HintergrundFarbe_Radius_Stil_Hover_Trigger={
                          SideBarLeft_ContainerSocial_HintergrundFarbe_Radius_Stil_Hover_Trigger
                        }
                        SideBarLeft_AlleSocial_Farbe_Groesse_Stil_Trigger={
                          SideBarLeft_AlleSocial_Farbe_Groesse_Stil_Trigger
                        }
                        SideBarLeft_AlleSocial_Farbe_Groesse_Stil_Hover_Trigger={
                          SideBarLeft_AlleSocial_Farbe_Groesse_Stil_Hover_Trigger
                        }
                        SideBarLeft_AlleSocial_HintergrundFarbe_Radius_Stil_Trigger={
                          SideBarLeft_AlleSocial_HintergrundFarbe_Radius_Stil_Trigger
                        }
                        SideBarLeft_AlleSocial_HintergrundFarbe_Radius_Stil_Hover_Trigger={
                          SideBarLeft_AlleSocial_HintergrundFarbe_Radius_Stil_Hover_Trigger
                        }
                        SideBarLeft_SocialItem_Farbe_Groesse_Stil_Trigger={
                          SideBarLeft_SocialItem_Farbe_Groesse_Stil_Trigger
                        }
                        SideBarLeft_SocialItem_Farbe_Groesse_Stil_Hover_Trigger={
                          SideBarLeft_SocialItem_Farbe_Groesse_Stil_Hover_Trigger
                        }
                        SideBarLeft_SocialItem_HintergrundFarbe_Radius_Stil_Trigger={
                          SideBarLeft_SocialItem_HintergrundFarbe_Radius_Stil_Trigger
                        }
                        SideBarLeft_SocialItem_HintergrundFarbe_Radius_Stil_Hover_Trigger={
                          SideBarLeft_SocialItem_HintergrundFarbe_Radius_Stil_Hover_Trigger
                        }
                        DB_fontFace_FontFamilyName={DB_fontFace_FontFamilyName}
                        DB_LeftSidebar_MenuContainer_Social_DDBackgroundColor={
                          DB_LeftSidebar_MenuContainer_Social_DDBackgroundColor
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDBorderTop={
                          DB_LeftSidebar_MenuContainer_Social_DDBorderTop
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDBorderStyleTop={
                          DB_LeftSidebar_MenuContainer_Social_DDBorderStyleTop
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDBorderColorTop={
                          DB_LeftSidebar_MenuContainer_Social_DDBorderColorTop
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDBorderBottom={
                          DB_LeftSidebar_MenuContainer_Social_DDBorderBottom
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDBorderStyleBottom={
                          DB_LeftSidebar_MenuContainer_Social_DDBorderStyleBottom
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDBorderColorBottom={
                          DB_LeftSidebar_MenuContainer_Social_DDBorderColorBottom
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDBorderLeft={
                          DB_LeftSidebar_MenuContainer_Social_DDBorderLeft
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDBorderStyleLeft={
                          DB_LeftSidebar_MenuContainer_Social_DDBorderStyleLeft
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDBorderColorLeft={
                          DB_LeftSidebar_MenuContainer_Social_DDBorderColorLeft
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDBorderRight={
                          DB_LeftSidebar_MenuContainer_Social_DDBorderRight
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDBorderStyleRight={
                          DB_LeftSidebar_MenuContainer_Social_DDBorderStyleRight
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDBorderColorRight={
                          DB_LeftSidebar_MenuContainer_Social_DDBorderColorRight
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDBorderTopRightRadius={
                          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDBorderTopRightRadius
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDBorderTopLeftRadius={
                          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDBorderTopLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDBorderBottomRightRadius={
                          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDBorderBottomRightRadius
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDBorderBottomLeftRadius={
                          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDBorderBottomLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDpaddingLeft={
                          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDpaddingLeft
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDpaddingRight={
                          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDpaddingRight
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDpaddingTop={
                          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDpaddingTop
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDpaddingBottom={
                          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDpaddingBottom
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDShadowHorizontal={
                          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDShadowHorizontal
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDShadowVertical={
                          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDShadowVertical
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDShadowBlur={
                          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDShadowBlur
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDShadowColor={
                          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDShadowColor
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDHover_BackgroundColor={
                          DB_LeftSidebar_MenuContainer_Social_DDHover_BackgroundColor
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDHover_BorderTop={
                          DB_LeftSidebar_MenuContainer_Social_DDHover_BorderTop
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDHover_BorderStyleTop={
                          DB_LeftSidebar_MenuContainer_Social_DDHover_BorderStyleTop
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDHover_BorderColorTop={
                          DB_LeftSidebar_MenuContainer_Social_DDHover_BorderColorTop
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDHover_BorderBottom={
                          DB_LeftSidebar_MenuContainer_Social_DDHover_BorderBottom
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDHover_BorderStyleBottom={
                          DB_LeftSidebar_MenuContainer_Social_DDHover_BorderStyleBottom
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDHover_BorderColorBottom={
                          DB_LeftSidebar_MenuContainer_Social_DDHover_BorderColorBottom
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDHover_BorderLeft={
                          DB_LeftSidebar_MenuContainer_Social_DDHover_BorderLeft
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDHover_BorderStyleLeft={
                          DB_LeftSidebar_MenuContainer_Social_DDHover_BorderStyleLeft
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDHover_BorderColorLeft={
                          DB_LeftSidebar_MenuContainer_Social_DDHover_BorderColorLeft
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDHover_BorderRight={
                          DB_LeftSidebar_MenuContainer_Social_DDHover_BorderRight
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDHover_BorderStyleRight={
                          DB_LeftSidebar_MenuContainer_Social_DDHover_BorderStyleRight
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDHover_BorderColorRight={
                          DB_LeftSidebar_MenuContainer_Social_DDHover_BorderColorRight
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_BorderTopRightRadius={
                          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_BorderTopRightRadius
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_BorderTopLeftRadius={
                          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_BorderTopLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_BorderBottomRightRadius={
                          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_BorderBottomRightRadius
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_BorderBottomLeftRadius={
                          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_BorderBottomLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_paddingLeft={
                          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_paddingLeft
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_paddingRight={
                          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_paddingRight
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_paddingTop={
                          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_paddingTop
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_paddingBottom={
                          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_paddingBottom
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_ShadowHorizontal={
                          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_ShadowHorizontal
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_ShadowVertical={
                          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_ShadowVertical
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_ShadowBlur={
                          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_ShadowBlur
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_ShadowColor={
                          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_ShadowColor
                        }
                        DB_fontFace_SocialTitelFontFamily_FontFamilyName={
                          DB_fontFace_SocialTitelFontFamily_FontFamilyName
                        }
                        DB_fontFace_SocialTitelFontFamily_FontFamilySrc={
                          DB_fontFace_SocialTitelFontFamily_FontFamilySrc
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_FontWeight={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_FontWeight
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_FontColor={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_FontColor
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_FontSize={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_FontSize
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BackgroundColor={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BackgroundColor
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_TextAlign={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_TextAlign
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderTop={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderTop
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderStyleTop={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderStyleTop
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderColorTop={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderColorTop
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderBottom={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderBottom
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderStyleBottom={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderStyleBottom
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderColorBottom={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderColorBottom
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderLeft={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderLeft
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderStyleLeft={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderStyleLeft
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderColorLeft={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderColorLeft
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderRight={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderRight
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderStyleRight={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderStyleRight
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderColorRight={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderColorRight
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderTopRightRadius={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderTopRightRadius
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderTopLeftRadius={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderTopLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderBottomRightRadius={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderBottomRightRadius
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderBottomLeftRadius={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderBottomLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_marginLeftRight={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_marginLeftRight
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_marginTopBottom={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_marginTopBottom
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_paddingLeftRight={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_paddingLeftRight
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_paddingTopBottom={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_paddingTopBottom
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_letterSpacing={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_letterSpacing
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowHorizontal={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowHorizontal
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowVertical={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowVertical
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowBlur={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowBlur
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowColor={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowColor
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_TextDecoration={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_TextDecoration
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_TextDecorationStyle={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_TextDecorationStyle
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_FontWeight={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_FontWeight
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_FontColor={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_FontColor
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_FontSize={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_FontSize
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BackgroundColor={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BackgroundColor
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_TextAlign={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_TextAlign
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderTop={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderTop
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderStyleTop={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderStyleTop
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderColorTop={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderColorTop
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderBottom={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderBottom
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderStyleBottom={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderStyleBottom
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderColorBottom={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderColorBottom
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderLeft={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderLeft
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderStyleLeft={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderStyleLeft
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderColorLeft={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderColorLeft
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderRight={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderRight
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderStyleRight={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderStyleRight
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderColorRight={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderColorRight
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderTopRightRadius={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderTopRightRadius
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderTopLeftRadius={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderTopLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderBottomRightRadius={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderBottomRightRadius
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderBottomLeftRadius={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderBottomLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_marginLeftRight_Hover={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_marginLeftRight_Hover
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_marginTopBottom_Hover={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_marginTopBottom_Hover
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_paddingLeftRight_Hover={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_paddingLeftRight_Hover
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_paddingTopBottom_Hover={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_paddingTopBottom_Hover
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_letterSpacing_Hover={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_letterSpacing_Hover
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowHorizontal_Hover={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowHorizontal_Hover
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowVertical_Hover={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowVertical_Hover
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowBlur_Hover={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowBlur_Hover
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowColor_Hover={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowColor_Hover
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_TextDecoration_Hover={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_TextDecoration_Hover
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_TextDecorationStyle_Hover={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_TextDecorationStyle_Hover
                        }
                        DB_fontFace_SocialTextFontFamily_FontFamilyName={
                          DB_fontFace_SocialTextFontFamily_FontFamilyName
                        }
                        DB_fontFace_SocialTextFontFamily_FontFamilySrc={
                          DB_fontFace_SocialTextFontFamily_FontFamilySrc
                        }
                        DB_LeftSidebar_MenuItem_SocialText_FontWeight={
                          DB_LeftSidebar_MenuItem_SocialText_FontWeight
                        }
                        DB_LeftSidebar_MenuItem_SocialText_FontColor={
                          DB_LeftSidebar_MenuItem_SocialText_FontColor
                        }
                        DB_LeftSidebar_MenuItem_SocialText_FontSize={
                          DB_LeftSidebar_MenuItem_SocialText_FontSize
                        }
                        DB_LeftSidebar_MenuItem_SocialText_BackgroundColor={
                          DB_LeftSidebar_MenuItem_SocialText_BackgroundColor
                        }
                        DB_LeftSidebar_MenuItem_SocialText_BorderTop={
                          DB_LeftSidebar_MenuItem_SocialText_BorderTop
                        }
                        DB_LeftSidebar_MenuItem_SocialText_BorderStyleTop={
                          DB_LeftSidebar_MenuItem_SocialText_BorderStyleTop
                        }
                        DB_LeftSidebar_MenuItem_SocialText_BorderColorTop={
                          DB_LeftSidebar_MenuItem_SocialText_BorderColorTop
                        }
                        DB_LeftSidebar_MenuItem_SocialText_BorderBottom={
                          DB_LeftSidebar_MenuItem_SocialText_BorderBottom
                        }
                        DB_LeftSidebar_MenuItem_SocialText_BorderStyleBottom={
                          DB_LeftSidebar_MenuItem_SocialText_BorderStyleBottom
                        }
                        DB_LeftSidebar_MenuItem_SocialText_BorderColorBottom={
                          DB_LeftSidebar_MenuItem_SocialText_BorderColorBottom
                        }
                        DB_LeftSidebar_MenuItem_SocialText_BorderLeft={
                          DB_LeftSidebar_MenuItem_SocialText_BorderLeft
                        }
                        DB_LeftSidebar_MenuItem_SocialText_BorderStyleLeft={
                          DB_LeftSidebar_MenuItem_SocialText_BorderStyleLeft
                        }
                        DB_LeftSidebar_MenuItem_SocialText_BorderColorLeft={
                          DB_LeftSidebar_MenuItem_SocialText_BorderColorLeft
                        }
                        DB_LeftSidebar_MenuItem_SocialText_BorderRight={
                          DB_LeftSidebar_MenuItem_SocialText_BorderRight
                        }
                        DB_LeftSidebar_MenuItem_SocialText_BorderStyleRight={
                          DB_LeftSidebar_MenuItem_SocialText_BorderStyleRight
                        }
                        DB_LeftSidebar_MenuItem_SocialText_BorderColorRight={
                          DB_LeftSidebar_MenuItem_SocialText_BorderColorRight
                        }
                        DB_LeftSidebar_MenuItem_SocialText_BorderTopRightRadius={
                          DB_LeftSidebar_MenuItem_SocialText_BorderTopRightRadius
                        }
                        DB_LeftSidebar_MenuItem_SocialText_BorderTopLeftRadius={
                          DB_LeftSidebar_MenuItem_SocialText_BorderTopLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_SocialText_BorderBottomRightRadius={
                          DB_LeftSidebar_MenuItem_SocialText_BorderBottomRightRadius
                        }
                        DB_LeftSidebar_MenuItem_SocialText_BorderBottomLeftRadius={
                          DB_LeftSidebar_MenuItem_SocialText_BorderBottomLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_SocialText_marginLeftRight={
                          DB_LeftSidebar_MenuItem_SocialText_marginLeftRight
                        }
                        DB_LeftSidebar_MenuItem_SocialText_marginTopBottom={
                          DB_LeftSidebar_MenuItem_SocialText_marginTopBottom
                        }
                        DB_LeftSidebar_MenuItem_SocialText_paddingLeftRight={
                          DB_LeftSidebar_MenuItem_SocialText_paddingLeftRight
                        }
                        DB_LeftSidebar_MenuItem_SocialText_paddingTopBottom={
                          DB_LeftSidebar_MenuItem_SocialText_paddingTopBottom
                        }
                        DB_LeftSidebar_MenuItem_SocialText_letterSpacing={
                          DB_LeftSidebar_MenuItem_SocialText_letterSpacing
                        }
                        DB_LeftSidebar_MenuItem_SocialText_ShadowHorizontal={
                          DB_LeftSidebar_MenuItem_SocialText_ShadowHorizontal
                        }
                        DB_LeftSidebar_MenuItem_SocialText_ShadowVertical={
                          DB_LeftSidebar_MenuItem_SocialText_ShadowVertical
                        }
                        DB_LeftSidebar_MenuItem_SocialText_ShadowBlur={
                          DB_LeftSidebar_MenuItem_SocialText_ShadowBlur
                        }
                        DB_LeftSidebar_MenuItem_SocialText_ShadowColor={
                          DB_LeftSidebar_MenuItem_SocialText_ShadowColor
                        }
                        DB_LeftSidebar_MenuItem_SocialText_TextAlign={
                          DB_LeftSidebar_MenuItem_SocialText_TextAlign
                        }
                        DB_LeftSidebar_MenuItem_SocialText_TextDecoration={
                          DB_LeftSidebar_MenuItem_SocialText_TextDecoration
                        }
                        DB_LeftSidebar_MenuItem_SocialText_TextDecorationStyle={
                          DB_LeftSidebar_MenuItem_SocialText_TextDecorationStyle
                        }
                        DB_LeftSidebar_MenuItem_SocialText_Hover_FontWeight={
                          DB_LeftSidebar_MenuItem_SocialText_Hover_FontWeight
                        }
                        DB_LeftSidebar_MenuItem_SocialText_Hover_FontColor={
                          DB_LeftSidebar_MenuItem_SocialText_Hover_FontColor
                        }
                        DB_LeftSidebar_MenuItem_SocialText_Hover_FontSize={
                          DB_LeftSidebar_MenuItem_SocialText_Hover_FontSize
                        }
                        DB_LeftSidebar_MenuItem_SocialText_Hover_BackgroundColor={
                          DB_LeftSidebar_MenuItem_SocialText_Hover_BackgroundColor
                        }
                        DB_LeftSidebar_MenuItem_SocialText_Hover_TextAlign={
                          DB_LeftSidebar_MenuItem_SocialText_Hover_TextAlign
                        }
                        DB_LeftSidebar_MenuItem_SocialText_Hover_BorderTop={
                          DB_LeftSidebar_MenuItem_SocialText_Hover_BorderTop
                        }
                        DB_LeftSidebar_MenuItem_SocialText_Hover_BorderStyleTop={
                          DB_LeftSidebar_MenuItem_SocialText_Hover_BorderStyleTop
                        }
                        DB_LeftSidebar_MenuItem_SocialText_Hover_BorderColorTop={
                          DB_LeftSidebar_MenuItem_SocialText_Hover_BorderColorTop
                        }
                        DB_LeftSidebar_MenuItem_SocialText_Hover_BorderBottom={
                          DB_LeftSidebar_MenuItem_SocialText_Hover_BorderBottom
                        }
                        DB_LeftSidebar_MenuItem_SocialText_Hover_BorderStyleBottom={
                          DB_LeftSidebar_MenuItem_SocialText_Hover_BorderStyleBottom
                        }
                        DB_LeftSidebar_MenuItem_SocialText_Hover_BorderColorBottom={
                          DB_LeftSidebar_MenuItem_SocialText_Hover_BorderColorBottom
                        }
                        DB_LeftSidebar_MenuItem_SocialText_Hover_BorderLeft={
                          DB_LeftSidebar_MenuItem_SocialText_Hover_BorderLeft
                        }
                        DB_LeftSidebar_MenuItem_SocialText_Hover_BorderStyleLeft={
                          DB_LeftSidebar_MenuItem_SocialText_Hover_BorderStyleLeft
                        }
                        DB_LeftSidebar_MenuItem_SocialText_Hover_BorderColorLeft={
                          DB_LeftSidebar_MenuItem_SocialText_Hover_BorderColorLeft
                        }
                        DB_LeftSidebar_MenuItem_SocialText_Hover_BorderRight={
                          DB_LeftSidebar_MenuItem_SocialText_Hover_BorderRight
                        }
                        DB_LeftSidebar_MenuItem_SocialText_Hover_BorderStyleRight={
                          DB_LeftSidebar_MenuItem_SocialText_Hover_BorderStyleRight
                        }
                        DB_LeftSidebar_MenuItem_SocialText_Hover_BorderColorRight={
                          DB_LeftSidebar_MenuItem_SocialText_Hover_BorderColorRight
                        }
                        DB_LeftSidebar_MenuItem_SocialText_Hover_BorderTopRightRadius={
                          DB_LeftSidebar_MenuItem_SocialText_Hover_BorderTopRightRadius
                        }
                        DB_LeftSidebar_MenuItem_SocialText_Hover_BorderTopLeftRadius={
                          DB_LeftSidebar_MenuItem_SocialText_Hover_BorderTopLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_SocialText_Hover_BorderBottomRightRadius={
                          DB_LeftSidebar_MenuItem_SocialText_Hover_BorderBottomRightRadius
                        }
                        DB_LeftSidebar_MenuItem_SocialText_Hover_BorderBottomLeftRadius={
                          DB_LeftSidebar_MenuItem_SocialText_Hover_BorderBottomLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_SocialText_marginLeftRight_Hover={
                          DB_LeftSidebar_MenuItem_SocialText_marginLeftRight_Hover
                        }
                        DB_LeftSidebar_MenuItem_SocialText_marginTopBottom_Hover={
                          DB_LeftSidebar_MenuItem_SocialText_marginTopBottom_Hover
                        }
                        DB_LeftSidebar_MenuItem_SocialText_paddingLeftRight_Hover={
                          DB_LeftSidebar_MenuItem_SocialText_paddingLeftRight_Hover
                        }
                        DB_LeftSidebar_MenuItem_SocialText_paddingTopBottom_Hover={
                          DB_LeftSidebar_MenuItem_SocialText_paddingTopBottom_Hover
                        }
                        DB_LeftSidebar_MenuItem_SocialText_letterSpacing_Hover={
                          DB_LeftSidebar_MenuItem_SocialText_letterSpacing_Hover
                        }
                        DB_LeftSidebar_MenuItem_SocialText_ShadowHorizontal_Hover={
                          DB_LeftSidebar_MenuItem_SocialText_ShadowHorizontal_Hover
                        }
                        DB_LeftSidebar_MenuItem_SocialText_ShadowVertical_Hover={
                          DB_LeftSidebar_MenuItem_SocialText_ShadowVertical_Hover
                        }
                        DB_LeftSidebar_MenuItem_SocialText_ShadowBlur_Hover={
                          DB_LeftSidebar_MenuItem_SocialText_ShadowBlur_Hover
                        }
                        DB_LeftSidebar_MenuItem_SocialText_ShadowColor_Hover={
                          DB_LeftSidebar_MenuItem_SocialText_ShadowColor_Hover
                        }
                        DB_LeftSidebar_MenuItem_SocialText_TextDecoration_Hover={
                          DB_LeftSidebar_MenuItem_SocialText_TextDecoration_Hover
                        }
                        DB_LeftSidebar_MenuItem_SocialText_TextDecorationStyle_Hover={
                          DB_LeftSidebar_MenuItem_SocialText_TextDecorationStyle_Hover
                        }
                      />
                    )}
                    {(shopBoxes.boxTwoTitel || shopBoxes.boxTwoContent) && (
                      <SideBarRightMessageToBuyer
                        boxTitel={shopBoxes.boxTwoTitel}
                        boxContent={shopBoxes.boxTwoContent}
                        SideBarLeft_ContainerSocial_Farbe_Groesse_Stil_OnMouseOverAndOut={
                          SideBarLeft_ContainerSocial_Farbe_Groesse_Stil_OnMouseOverAndOut
                        }
                        SideBarLeft_ContainerSocial_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut={
                          SideBarLeft_ContainerSocial_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
                        }
                        SideBarLeft_ContainerSocial_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut={
                          SideBarLeft_ContainerSocial_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut
                        }
                        SideBarLeft_ContainerSocial_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut={
                          SideBarLeft_ContainerSocial_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut
                        }
                        SideBarLeft_AlleSocial_Farbe_Groesse_Stil_OnMouseOverAndOut={
                          SideBarLeft_AlleSocial_Farbe_Groesse_Stil_OnMouseOverAndOut
                        }
                        SideBarLeft_AlleSocial_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut={
                          SideBarLeft_AlleSocial_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
                        }
                        SideBarLeft_AlleSocial_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut={
                          SideBarLeft_AlleSocial_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut
                        }
                        SideBarLeft_AlleSocial_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut={
                          SideBarLeft_AlleSocial_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut
                        }
                        SideBarLeft_SocialItem_Farbe_Groesse_Stil_OnMouseOverAndOut={
                          SideBarLeft_SocialItem_Farbe_Groesse_Stil_OnMouseOverAndOut
                        }
                        SideBarLeft_SocialItem_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut={
                          SideBarLeft_SocialItem_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
                        }
                        SideBarLeft_SocialItem_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut={
                          SideBarLeft_SocialItem_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut
                        }
                        SideBarLeft_SocialItem_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut={
                          SideBarLeft_SocialItem_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut
                        }
                        SideBarLeft_ContainerSocial_Farbe_Groesse_Stil_Trigger={
                          SideBarLeft_ContainerSocial_Farbe_Groesse_Stil_Trigger
                        }
                        SideBarLeft_ContainerSocial_Farbe_Groesse_Stil_Hover_Trigger={
                          SideBarLeft_ContainerSocial_Farbe_Groesse_Stil_Hover_Trigger
                        }
                        SideBarLeft_ContainerSocial_HintergrundFarbe_Radius_Stil_Trigger={
                          SideBarLeft_ContainerSocial_HintergrundFarbe_Radius_Stil_Trigger
                        }
                        SideBarLeft_ContainerSocial_HintergrundFarbe_Radius_Stil_Hover_Trigger={
                          SideBarLeft_ContainerSocial_HintergrundFarbe_Radius_Stil_Hover_Trigger
                        }
                        SideBarLeft_AlleSocial_Farbe_Groesse_Stil_Trigger={
                          SideBarLeft_AlleSocial_Farbe_Groesse_Stil_Trigger
                        }
                        SideBarLeft_AlleSocial_Farbe_Groesse_Stil_Hover_Trigger={
                          SideBarLeft_AlleSocial_Farbe_Groesse_Stil_Hover_Trigger
                        }
                        SideBarLeft_AlleSocial_HintergrundFarbe_Radius_Stil_Trigger={
                          SideBarLeft_AlleSocial_HintergrundFarbe_Radius_Stil_Trigger
                        }
                        SideBarLeft_AlleSocial_HintergrundFarbe_Radius_Stil_Hover_Trigger={
                          SideBarLeft_AlleSocial_HintergrundFarbe_Radius_Stil_Hover_Trigger
                        }
                        SideBarLeft_SocialItem_Farbe_Groesse_Stil_Trigger={
                          SideBarLeft_SocialItem_Farbe_Groesse_Stil_Trigger
                        }
                        SideBarLeft_SocialItem_Farbe_Groesse_Stil_Hover_Trigger={
                          SideBarLeft_SocialItem_Farbe_Groesse_Stil_Hover_Trigger
                        }
                        SideBarLeft_SocialItem_HintergrundFarbe_Radius_Stil_Trigger={
                          SideBarLeft_SocialItem_HintergrundFarbe_Radius_Stil_Trigger
                        }
                        SideBarLeft_SocialItem_HintergrundFarbe_Radius_Stil_Hover_Trigger={
                          SideBarLeft_SocialItem_HintergrundFarbe_Radius_Stil_Hover_Trigger
                        }
                        DB_fontFace_FontFamilyName={DB_fontFace_FontFamilyName}
                        DB_LeftSidebar_MenuContainer_Social_DDBackgroundColor={
                          DB_LeftSidebar_MenuContainer_Social_DDBackgroundColor
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDBorderTop={
                          DB_LeftSidebar_MenuContainer_Social_DDBorderTop
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDBorderStyleTop={
                          DB_LeftSidebar_MenuContainer_Social_DDBorderStyleTop
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDBorderColorTop={
                          DB_LeftSidebar_MenuContainer_Social_DDBorderColorTop
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDBorderBottom={
                          DB_LeftSidebar_MenuContainer_Social_DDBorderBottom
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDBorderStyleBottom={
                          DB_LeftSidebar_MenuContainer_Social_DDBorderStyleBottom
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDBorderColorBottom={
                          DB_LeftSidebar_MenuContainer_Social_DDBorderColorBottom
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDBorderLeft={
                          DB_LeftSidebar_MenuContainer_Social_DDBorderLeft
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDBorderStyleLeft={
                          DB_LeftSidebar_MenuContainer_Social_DDBorderStyleLeft
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDBorderColorLeft={
                          DB_LeftSidebar_MenuContainer_Social_DDBorderColorLeft
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDBorderRight={
                          DB_LeftSidebar_MenuContainer_Social_DDBorderRight
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDBorderStyleRight={
                          DB_LeftSidebar_MenuContainer_Social_DDBorderStyleRight
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDBorderColorRight={
                          DB_LeftSidebar_MenuContainer_Social_DDBorderColorRight
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDBorderTopRightRadius={
                          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDBorderTopRightRadius
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDBorderTopLeftRadius={
                          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDBorderTopLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDBorderBottomRightRadius={
                          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDBorderBottomRightRadius
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDBorderBottomLeftRadius={
                          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDBorderBottomLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDpaddingLeft={
                          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDpaddingLeft
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDpaddingRight={
                          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDpaddingRight
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDpaddingTop={
                          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDpaddingTop
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDpaddingBottom={
                          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDpaddingBottom
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDShadowHorizontal={
                          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDShadowHorizontal
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDShadowVertical={
                          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDShadowVertical
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDShadowBlur={
                          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDShadowBlur
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDShadowColor={
                          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDShadowColor
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDHover_BackgroundColor={
                          DB_LeftSidebar_MenuContainer_Social_DDHover_BackgroundColor
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDHover_BorderTop={
                          DB_LeftSidebar_MenuContainer_Social_DDHover_BorderTop
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDHover_BorderStyleTop={
                          DB_LeftSidebar_MenuContainer_Social_DDHover_BorderStyleTop
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDHover_BorderColorTop={
                          DB_LeftSidebar_MenuContainer_Social_DDHover_BorderColorTop
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDHover_BorderBottom={
                          DB_LeftSidebar_MenuContainer_Social_DDHover_BorderBottom
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDHover_BorderStyleBottom={
                          DB_LeftSidebar_MenuContainer_Social_DDHover_BorderStyleBottom
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDHover_BorderColorBottom={
                          DB_LeftSidebar_MenuContainer_Social_DDHover_BorderColorBottom
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDHover_BorderLeft={
                          DB_LeftSidebar_MenuContainer_Social_DDHover_BorderLeft
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDHover_BorderStyleLeft={
                          DB_LeftSidebar_MenuContainer_Social_DDHover_BorderStyleLeft
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDHover_BorderColorLeft={
                          DB_LeftSidebar_MenuContainer_Social_DDHover_BorderColorLeft
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDHover_BorderRight={
                          DB_LeftSidebar_MenuContainer_Social_DDHover_BorderRight
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDHover_BorderStyleRight={
                          DB_LeftSidebar_MenuContainer_Social_DDHover_BorderStyleRight
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDHover_BorderColorRight={
                          DB_LeftSidebar_MenuContainer_Social_DDHover_BorderColorRight
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_BorderTopRightRadius={
                          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_BorderTopRightRadius
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_BorderTopLeftRadius={
                          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_BorderTopLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_BorderBottomRightRadius={
                          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_BorderBottomRightRadius
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_BorderBottomLeftRadius={
                          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_BorderBottomLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_paddingLeft={
                          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_paddingLeft
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_paddingRight={
                          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_paddingRight
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_paddingTop={
                          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_paddingTop
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_paddingBottom={
                          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_paddingBottom
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_ShadowHorizontal={
                          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_ShadowHorizontal
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_ShadowVertical={
                          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_ShadowVertical
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_ShadowBlur={
                          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_ShadowBlur
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_ShadowColor={
                          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_ShadowColor
                        }
                        DB_fontFace_SocialTitelFontFamily_FontFamilyName={
                          DB_fontFace_SocialTitelFontFamily_FontFamilyName
                        }
                        DB_fontFace_SocialTitelFontFamily_FontFamilySrc={
                          DB_fontFace_SocialTitelFontFamily_FontFamilySrc
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_FontWeight={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_FontWeight
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_FontColor={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_FontColor
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_FontSize={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_FontSize
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BackgroundColor={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BackgroundColor
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_TextAlign={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_TextAlign
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderTop={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderTop
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderStyleTop={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderStyleTop
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderColorTop={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderColorTop
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderBottom={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderBottom
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderStyleBottom={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderStyleBottom
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderColorBottom={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderColorBottom
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderLeft={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderLeft
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderStyleLeft={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderStyleLeft
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderColorLeft={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderColorLeft
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderRight={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderRight
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderStyleRight={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderStyleRight
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderColorRight={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderColorRight
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderTopRightRadius={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderTopRightRadius
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderTopLeftRadius={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderTopLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderBottomRightRadius={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderBottomRightRadius
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderBottomLeftRadius={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderBottomLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_marginLeftRight={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_marginLeftRight
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_marginTopBottom={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_marginTopBottom
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_paddingLeftRight={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_paddingLeftRight
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_paddingTopBottom={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_paddingTopBottom
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_letterSpacing={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_letterSpacing
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowHorizontal={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowHorizontal
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowVertical={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowVertical
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowBlur={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowBlur
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowColor={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowColor
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_TextDecoration={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_TextDecoration
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_TextDecorationStyle={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_TextDecorationStyle
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_FontWeight={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_FontWeight
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_FontColor={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_FontColor
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_FontSize={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_FontSize
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BackgroundColor={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BackgroundColor
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_TextAlign={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_TextAlign
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderTop={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderTop
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderStyleTop={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderStyleTop
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderColorTop={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderColorTop
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderBottom={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderBottom
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderStyleBottom={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderStyleBottom
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderColorBottom={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderColorBottom
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderLeft={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderLeft
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderStyleLeft={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderStyleLeft
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderColorLeft={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderColorLeft
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderRight={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderRight
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderStyleRight={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderStyleRight
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderColorRight={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderColorRight
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderTopRightRadius={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderTopRightRadius
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderTopLeftRadius={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderTopLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderBottomRightRadius={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderBottomRightRadius
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderBottomLeftRadius={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderBottomLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_marginLeftRight_Hover={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_marginLeftRight_Hover
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_marginTopBottom_Hover={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_marginTopBottom_Hover
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_paddingLeftRight_Hover={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_paddingLeftRight_Hover
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_paddingTopBottom_Hover={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_paddingTopBottom_Hover
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_letterSpacing_Hover={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_letterSpacing_Hover
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowHorizontal_Hover={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowHorizontal_Hover
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowVertical_Hover={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowVertical_Hover
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowBlur_Hover={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowBlur_Hover
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowColor_Hover={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowColor_Hover
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_TextDecoration_Hover={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_TextDecoration_Hover
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_TextDecorationStyle_Hover={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_TextDecorationStyle_Hover
                        }
                        DB_fontFace_SocialTextFontFamily_FontFamilyName={
                          DB_fontFace_SocialTextFontFamily_FontFamilyName
                        }
                        DB_fontFace_SocialTextFontFamily_FontFamilySrc={
                          DB_fontFace_SocialTextFontFamily_FontFamilySrc
                        }
                        DB_LeftSidebar_MenuItem_SocialText_FontWeight={
                          DB_LeftSidebar_MenuItem_SocialText_FontWeight
                        }
                        DB_LeftSidebar_MenuItem_SocialText_FontColor={
                          DB_LeftSidebar_MenuItem_SocialText_FontColor
                        }
                        DB_LeftSidebar_MenuItem_SocialText_FontSize={
                          DB_LeftSidebar_MenuItem_SocialText_FontSize
                        }
                        DB_LeftSidebar_MenuItem_SocialText_BackgroundColor={
                          DB_LeftSidebar_MenuItem_SocialText_BackgroundColor
                        }
                        DB_LeftSidebar_MenuItem_SocialText_BorderTop={
                          DB_LeftSidebar_MenuItem_SocialText_BorderTop
                        }
                        DB_LeftSidebar_MenuItem_SocialText_BorderStyleTop={
                          DB_LeftSidebar_MenuItem_SocialText_BorderStyleTop
                        }
                        DB_LeftSidebar_MenuItem_SocialText_BorderColorTop={
                          DB_LeftSidebar_MenuItem_SocialText_BorderColorTop
                        }
                        DB_LeftSidebar_MenuItem_SocialText_BorderBottom={
                          DB_LeftSidebar_MenuItem_SocialText_BorderBottom
                        }
                        DB_LeftSidebar_MenuItem_SocialText_BorderStyleBottom={
                          DB_LeftSidebar_MenuItem_SocialText_BorderStyleBottom
                        }
                        DB_LeftSidebar_MenuItem_SocialText_BorderColorBottom={
                          DB_LeftSidebar_MenuItem_SocialText_BorderColorBottom
                        }
                        DB_LeftSidebar_MenuItem_SocialText_BorderLeft={
                          DB_LeftSidebar_MenuItem_SocialText_BorderLeft
                        }
                        DB_LeftSidebar_MenuItem_SocialText_BorderStyleLeft={
                          DB_LeftSidebar_MenuItem_SocialText_BorderStyleLeft
                        }
                        DB_LeftSidebar_MenuItem_SocialText_BorderColorLeft={
                          DB_LeftSidebar_MenuItem_SocialText_BorderColorLeft
                        }
                        DB_LeftSidebar_MenuItem_SocialText_BorderRight={
                          DB_LeftSidebar_MenuItem_SocialText_BorderRight
                        }
                        DB_LeftSidebar_MenuItem_SocialText_BorderStyleRight={
                          DB_LeftSidebar_MenuItem_SocialText_BorderStyleRight
                        }
                        DB_LeftSidebar_MenuItem_SocialText_BorderColorRight={
                          DB_LeftSidebar_MenuItem_SocialText_BorderColorRight
                        }
                        DB_LeftSidebar_MenuItem_SocialText_BorderTopRightRadius={
                          DB_LeftSidebar_MenuItem_SocialText_BorderTopRightRadius
                        }
                        DB_LeftSidebar_MenuItem_SocialText_BorderTopLeftRadius={
                          DB_LeftSidebar_MenuItem_SocialText_BorderTopLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_SocialText_BorderBottomRightRadius={
                          DB_LeftSidebar_MenuItem_SocialText_BorderBottomRightRadius
                        }
                        DB_LeftSidebar_MenuItem_SocialText_BorderBottomLeftRadius={
                          DB_LeftSidebar_MenuItem_SocialText_BorderBottomLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_SocialText_marginLeftRight={
                          DB_LeftSidebar_MenuItem_SocialText_marginLeftRight
                        }
                        DB_LeftSidebar_MenuItem_SocialText_marginTopBottom={
                          DB_LeftSidebar_MenuItem_SocialText_marginTopBottom
                        }
                        DB_LeftSidebar_MenuItem_SocialText_paddingLeftRight={
                          DB_LeftSidebar_MenuItem_SocialText_paddingLeftRight
                        }
                        DB_LeftSidebar_MenuItem_SocialText_paddingTopBottom={
                          DB_LeftSidebar_MenuItem_SocialText_paddingTopBottom
                        }
                        DB_LeftSidebar_MenuItem_SocialText_letterSpacing={
                          DB_LeftSidebar_MenuItem_SocialText_letterSpacing
                        }
                        DB_LeftSidebar_MenuItem_SocialText_ShadowHorizontal={
                          DB_LeftSidebar_MenuItem_SocialText_ShadowHorizontal
                        }
                        DB_LeftSidebar_MenuItem_SocialText_ShadowVertical={
                          DB_LeftSidebar_MenuItem_SocialText_ShadowVertical
                        }
                        DB_LeftSidebar_MenuItem_SocialText_ShadowBlur={
                          DB_LeftSidebar_MenuItem_SocialText_ShadowBlur
                        }
                        DB_LeftSidebar_MenuItem_SocialText_ShadowColor={
                          DB_LeftSidebar_MenuItem_SocialText_ShadowColor
                        }
                        DB_LeftSidebar_MenuItem_SocialText_TextAlign={
                          DB_LeftSidebar_MenuItem_SocialText_TextAlign
                        }
                        DB_LeftSidebar_MenuItem_SocialText_TextDecoration={
                          DB_LeftSidebar_MenuItem_SocialText_TextDecoration
                        }
                        DB_LeftSidebar_MenuItem_SocialText_TextDecorationStyle={
                          DB_LeftSidebar_MenuItem_SocialText_TextDecorationStyle
                        }
                        DB_LeftSidebar_MenuItem_SocialText_Hover_FontWeight={
                          DB_LeftSidebar_MenuItem_SocialText_Hover_FontWeight
                        }
                        DB_LeftSidebar_MenuItem_SocialText_Hover_FontColor={
                          DB_LeftSidebar_MenuItem_SocialText_Hover_FontColor
                        }
                        DB_LeftSidebar_MenuItem_SocialText_Hover_FontSize={
                          DB_LeftSidebar_MenuItem_SocialText_Hover_FontSize
                        }
                        DB_LeftSidebar_MenuItem_SocialText_Hover_BackgroundColor={
                          DB_LeftSidebar_MenuItem_SocialText_Hover_BackgroundColor
                        }
                        DB_LeftSidebar_MenuItem_SocialText_Hover_TextAlign={
                          DB_LeftSidebar_MenuItem_SocialText_Hover_TextAlign
                        }
                        DB_LeftSidebar_MenuItem_SocialText_Hover_BorderTop={
                          DB_LeftSidebar_MenuItem_SocialText_Hover_BorderTop
                        }
                        DB_LeftSidebar_MenuItem_SocialText_Hover_BorderStyleTop={
                          DB_LeftSidebar_MenuItem_SocialText_Hover_BorderStyleTop
                        }
                        DB_LeftSidebar_MenuItem_SocialText_Hover_BorderColorTop={
                          DB_LeftSidebar_MenuItem_SocialText_Hover_BorderColorTop
                        }
                        DB_LeftSidebar_MenuItem_SocialText_Hover_BorderBottom={
                          DB_LeftSidebar_MenuItem_SocialText_Hover_BorderBottom
                        }
                        DB_LeftSidebar_MenuItem_SocialText_Hover_BorderStyleBottom={
                          DB_LeftSidebar_MenuItem_SocialText_Hover_BorderStyleBottom
                        }
                        DB_LeftSidebar_MenuItem_SocialText_Hover_BorderColorBottom={
                          DB_LeftSidebar_MenuItem_SocialText_Hover_BorderColorBottom
                        }
                        DB_LeftSidebar_MenuItem_SocialText_Hover_BorderLeft={
                          DB_LeftSidebar_MenuItem_SocialText_Hover_BorderLeft
                        }
                        DB_LeftSidebar_MenuItem_SocialText_Hover_BorderStyleLeft={
                          DB_LeftSidebar_MenuItem_SocialText_Hover_BorderStyleLeft
                        }
                        DB_LeftSidebar_MenuItem_SocialText_Hover_BorderColorLeft={
                          DB_LeftSidebar_MenuItem_SocialText_Hover_BorderColorLeft
                        }
                        DB_LeftSidebar_MenuItem_SocialText_Hover_BorderRight={
                          DB_LeftSidebar_MenuItem_SocialText_Hover_BorderRight
                        }
                        DB_LeftSidebar_MenuItem_SocialText_Hover_BorderStyleRight={
                          DB_LeftSidebar_MenuItem_SocialText_Hover_BorderStyleRight
                        }
                        DB_LeftSidebar_MenuItem_SocialText_Hover_BorderColorRight={
                          DB_LeftSidebar_MenuItem_SocialText_Hover_BorderColorRight
                        }
                        DB_LeftSidebar_MenuItem_SocialText_Hover_BorderTopRightRadius={
                          DB_LeftSidebar_MenuItem_SocialText_Hover_BorderTopRightRadius
                        }
                        DB_LeftSidebar_MenuItem_SocialText_Hover_BorderTopLeftRadius={
                          DB_LeftSidebar_MenuItem_SocialText_Hover_BorderTopLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_SocialText_Hover_BorderBottomRightRadius={
                          DB_LeftSidebar_MenuItem_SocialText_Hover_BorderBottomRightRadius
                        }
                        DB_LeftSidebar_MenuItem_SocialText_Hover_BorderBottomLeftRadius={
                          DB_LeftSidebar_MenuItem_SocialText_Hover_BorderBottomLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_SocialText_marginLeftRight_Hover={
                          DB_LeftSidebar_MenuItem_SocialText_marginLeftRight_Hover
                        }
                        DB_LeftSidebar_MenuItem_SocialText_marginTopBottom_Hover={
                          DB_LeftSidebar_MenuItem_SocialText_marginTopBottom_Hover
                        }
                        DB_LeftSidebar_MenuItem_SocialText_paddingLeftRight_Hover={
                          DB_LeftSidebar_MenuItem_SocialText_paddingLeftRight_Hover
                        }
                        DB_LeftSidebar_MenuItem_SocialText_paddingTopBottom_Hover={
                          DB_LeftSidebar_MenuItem_SocialText_paddingTopBottom_Hover
                        }
                        DB_LeftSidebar_MenuItem_SocialText_letterSpacing_Hover={
                          DB_LeftSidebar_MenuItem_SocialText_letterSpacing_Hover
                        }
                        DB_LeftSidebar_MenuItem_SocialText_ShadowHorizontal_Hover={
                          DB_LeftSidebar_MenuItem_SocialText_ShadowHorizontal_Hover
                        }
                        DB_LeftSidebar_MenuItem_SocialText_ShadowVertical_Hover={
                          DB_LeftSidebar_MenuItem_SocialText_ShadowVertical_Hover
                        }
                        DB_LeftSidebar_MenuItem_SocialText_ShadowBlur_Hover={
                          DB_LeftSidebar_MenuItem_SocialText_ShadowBlur_Hover
                        }
                        DB_LeftSidebar_MenuItem_SocialText_ShadowColor_Hover={
                          DB_LeftSidebar_MenuItem_SocialText_ShadowColor_Hover
                        }
                        DB_LeftSidebar_MenuItem_SocialText_TextDecoration_Hover={
                          DB_LeftSidebar_MenuItem_SocialText_TextDecoration_Hover
                        }
                        DB_LeftSidebar_MenuItem_SocialText_TextDecorationStyle_Hover={
                          DB_LeftSidebar_MenuItem_SocialText_TextDecorationStyle_Hover
                        }
                      />
                    )}
                    {(shopBoxes.boxThreeTitel || shopBoxes.boxThreeContent) && (
                      <SideBarRightMessageToBuyer
                        boxTitel={shopBoxes.boxThreeTitel}
                        boxContent={shopBoxes.boxThreeContent}
                        SideBarLeft_ContainerSocial_Farbe_Groesse_Stil_OnMouseOverAndOut={
                          SideBarLeft_ContainerSocial_Farbe_Groesse_Stil_OnMouseOverAndOut
                        }
                        SideBarLeft_ContainerSocial_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut={
                          SideBarLeft_ContainerSocial_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
                        }
                        SideBarLeft_ContainerSocial_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut={
                          SideBarLeft_ContainerSocial_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut
                        }
                        SideBarLeft_ContainerSocial_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut={
                          SideBarLeft_ContainerSocial_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut
                        }
                        SideBarLeft_AlleSocial_Farbe_Groesse_Stil_OnMouseOverAndOut={
                          SideBarLeft_AlleSocial_Farbe_Groesse_Stil_OnMouseOverAndOut
                        }
                        SideBarLeft_AlleSocial_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut={
                          SideBarLeft_AlleSocial_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
                        }
                        SideBarLeft_AlleSocial_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut={
                          SideBarLeft_AlleSocial_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut
                        }
                        SideBarLeft_AlleSocial_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut={
                          SideBarLeft_AlleSocial_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut
                        }
                        SideBarLeft_SocialItem_Farbe_Groesse_Stil_OnMouseOverAndOut={
                          SideBarLeft_SocialItem_Farbe_Groesse_Stil_OnMouseOverAndOut
                        }
                        SideBarLeft_SocialItem_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut={
                          SideBarLeft_SocialItem_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
                        }
                        SideBarLeft_SocialItem_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut={
                          SideBarLeft_SocialItem_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut
                        }
                        SideBarLeft_SocialItem_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut={
                          SideBarLeft_SocialItem_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut
                        }
                        SideBarLeft_ContainerSocial_Farbe_Groesse_Stil_Trigger={
                          SideBarLeft_ContainerSocial_Farbe_Groesse_Stil_Trigger
                        }
                        SideBarLeft_ContainerSocial_Farbe_Groesse_Stil_Hover_Trigger={
                          SideBarLeft_ContainerSocial_Farbe_Groesse_Stil_Hover_Trigger
                        }
                        SideBarLeft_ContainerSocial_HintergrundFarbe_Radius_Stil_Trigger={
                          SideBarLeft_ContainerSocial_HintergrundFarbe_Radius_Stil_Trigger
                        }
                        SideBarLeft_ContainerSocial_HintergrundFarbe_Radius_Stil_Hover_Trigger={
                          SideBarLeft_ContainerSocial_HintergrundFarbe_Radius_Stil_Hover_Trigger
                        }
                        SideBarLeft_AlleSocial_Farbe_Groesse_Stil_Trigger={
                          SideBarLeft_AlleSocial_Farbe_Groesse_Stil_Trigger
                        }
                        SideBarLeft_AlleSocial_Farbe_Groesse_Stil_Hover_Trigger={
                          SideBarLeft_AlleSocial_Farbe_Groesse_Stil_Hover_Trigger
                        }
                        SideBarLeft_AlleSocial_HintergrundFarbe_Radius_Stil_Trigger={
                          SideBarLeft_AlleSocial_HintergrundFarbe_Radius_Stil_Trigger
                        }
                        SideBarLeft_AlleSocial_HintergrundFarbe_Radius_Stil_Hover_Trigger={
                          SideBarLeft_AlleSocial_HintergrundFarbe_Radius_Stil_Hover_Trigger
                        }
                        SideBarLeft_SocialItem_Farbe_Groesse_Stil_Trigger={
                          SideBarLeft_SocialItem_Farbe_Groesse_Stil_Trigger
                        }
                        SideBarLeft_SocialItem_Farbe_Groesse_Stil_Hover_Trigger={
                          SideBarLeft_SocialItem_Farbe_Groesse_Stil_Hover_Trigger
                        }
                        SideBarLeft_SocialItem_HintergrundFarbe_Radius_Stil_Trigger={
                          SideBarLeft_SocialItem_HintergrundFarbe_Radius_Stil_Trigger
                        }
                        SideBarLeft_SocialItem_HintergrundFarbe_Radius_Stil_Hover_Trigger={
                          SideBarLeft_SocialItem_HintergrundFarbe_Radius_Stil_Hover_Trigger
                        }
                        DB_fontFace_FontFamilyName={DB_fontFace_FontFamilyName}
                        DB_LeftSidebar_MenuContainer_Social_DDBackgroundColor={
                          DB_LeftSidebar_MenuContainer_Social_DDBackgroundColor
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDBorderTop={
                          DB_LeftSidebar_MenuContainer_Social_DDBorderTop
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDBorderStyleTop={
                          DB_LeftSidebar_MenuContainer_Social_DDBorderStyleTop
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDBorderColorTop={
                          DB_LeftSidebar_MenuContainer_Social_DDBorderColorTop
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDBorderBottom={
                          DB_LeftSidebar_MenuContainer_Social_DDBorderBottom
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDBorderStyleBottom={
                          DB_LeftSidebar_MenuContainer_Social_DDBorderStyleBottom
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDBorderColorBottom={
                          DB_LeftSidebar_MenuContainer_Social_DDBorderColorBottom
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDBorderLeft={
                          DB_LeftSidebar_MenuContainer_Social_DDBorderLeft
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDBorderStyleLeft={
                          DB_LeftSidebar_MenuContainer_Social_DDBorderStyleLeft
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDBorderColorLeft={
                          DB_LeftSidebar_MenuContainer_Social_DDBorderColorLeft
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDBorderRight={
                          DB_LeftSidebar_MenuContainer_Social_DDBorderRight
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDBorderStyleRight={
                          DB_LeftSidebar_MenuContainer_Social_DDBorderStyleRight
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDBorderColorRight={
                          DB_LeftSidebar_MenuContainer_Social_DDBorderColorRight
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDBorderTopRightRadius={
                          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDBorderTopRightRadius
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDBorderTopLeftRadius={
                          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDBorderTopLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDBorderBottomRightRadius={
                          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDBorderBottomRightRadius
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDBorderBottomLeftRadius={
                          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDBorderBottomLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDpaddingLeft={
                          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDpaddingLeft
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDpaddingRight={
                          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDpaddingRight
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDpaddingTop={
                          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDpaddingTop
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDpaddingBottom={
                          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDpaddingBottom
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDShadowHorizontal={
                          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDShadowHorizontal
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDShadowVertical={
                          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDShadowVertical
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDShadowBlur={
                          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDShadowBlur
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDShadowColor={
                          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDShadowColor
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDHover_BackgroundColor={
                          DB_LeftSidebar_MenuContainer_Social_DDHover_BackgroundColor
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDHover_BorderTop={
                          DB_LeftSidebar_MenuContainer_Social_DDHover_BorderTop
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDHover_BorderStyleTop={
                          DB_LeftSidebar_MenuContainer_Social_DDHover_BorderStyleTop
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDHover_BorderColorTop={
                          DB_LeftSidebar_MenuContainer_Social_DDHover_BorderColorTop
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDHover_BorderBottom={
                          DB_LeftSidebar_MenuContainer_Social_DDHover_BorderBottom
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDHover_BorderStyleBottom={
                          DB_LeftSidebar_MenuContainer_Social_DDHover_BorderStyleBottom
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDHover_BorderColorBottom={
                          DB_LeftSidebar_MenuContainer_Social_DDHover_BorderColorBottom
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDHover_BorderLeft={
                          DB_LeftSidebar_MenuContainer_Social_DDHover_BorderLeft
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDHover_BorderStyleLeft={
                          DB_LeftSidebar_MenuContainer_Social_DDHover_BorderStyleLeft
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDHover_BorderColorLeft={
                          DB_LeftSidebar_MenuContainer_Social_DDHover_BorderColorLeft
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDHover_BorderRight={
                          DB_LeftSidebar_MenuContainer_Social_DDHover_BorderRight
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDHover_BorderStyleRight={
                          DB_LeftSidebar_MenuContainer_Social_DDHover_BorderStyleRight
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDHover_BorderColorRight={
                          DB_LeftSidebar_MenuContainer_Social_DDHover_BorderColorRight
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_BorderTopRightRadius={
                          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_BorderTopRightRadius
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_BorderTopLeftRadius={
                          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_BorderTopLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_BorderBottomRightRadius={
                          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_BorderBottomRightRadius
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_BorderBottomLeftRadius={
                          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_BorderBottomLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_paddingLeft={
                          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_paddingLeft
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_paddingRight={
                          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_paddingRight
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_paddingTop={
                          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_paddingTop
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_paddingBottom={
                          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_paddingBottom
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_ShadowHorizontal={
                          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_ShadowHorizontal
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_ShadowVertical={
                          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_ShadowVertical
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_ShadowBlur={
                          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_ShadowBlur
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_ShadowColor={
                          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_ShadowColor
                        }
                        DB_fontFace_SocialTitelFontFamily_FontFamilyName={
                          DB_fontFace_SocialTitelFontFamily_FontFamilyName
                        }
                        DB_fontFace_SocialTitelFontFamily_FontFamilySrc={
                          DB_fontFace_SocialTitelFontFamily_FontFamilySrc
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_FontWeight={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_FontWeight
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_FontColor={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_FontColor
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_FontSize={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_FontSize
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BackgroundColor={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BackgroundColor
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_TextAlign={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_TextAlign
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderTop={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderTop
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderStyleTop={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderStyleTop
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderColorTop={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderColorTop
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderBottom={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderBottom
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderStyleBottom={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderStyleBottom
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderColorBottom={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderColorBottom
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderLeft={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderLeft
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderStyleLeft={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderStyleLeft
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderColorLeft={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderColorLeft
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderRight={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderRight
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderStyleRight={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderStyleRight
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderColorRight={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderColorRight
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderTopRightRadius={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderTopRightRadius
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderTopLeftRadius={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderTopLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderBottomRightRadius={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderBottomRightRadius
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderBottomLeftRadius={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderBottomLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_marginLeftRight={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_marginLeftRight
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_marginTopBottom={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_marginTopBottom
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_paddingLeftRight={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_paddingLeftRight
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_paddingTopBottom={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_paddingTopBottom
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_letterSpacing={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_letterSpacing
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowHorizontal={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowHorizontal
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowVertical={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowVertical
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowBlur={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowBlur
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowColor={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowColor
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_TextDecoration={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_TextDecoration
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_TextDecorationStyle={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_TextDecorationStyle
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_FontWeight={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_FontWeight
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_FontColor={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_FontColor
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_FontSize={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_FontSize
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BackgroundColor={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BackgroundColor
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_TextAlign={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_TextAlign
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderTop={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderTop
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderStyleTop={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderStyleTop
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderColorTop={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderColorTop
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderBottom={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderBottom
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderStyleBottom={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderStyleBottom
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderColorBottom={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderColorBottom
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderLeft={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderLeft
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderStyleLeft={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderStyleLeft
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderColorLeft={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderColorLeft
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderRight={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderRight
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderStyleRight={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderStyleRight
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderColorRight={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderColorRight
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderTopRightRadius={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderTopRightRadius
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderTopLeftRadius={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderTopLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderBottomRightRadius={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderBottomRightRadius
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderBottomLeftRadius={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderBottomLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_marginLeftRight_Hover={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_marginLeftRight_Hover
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_marginTopBottom_Hover={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_marginTopBottom_Hover
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_paddingLeftRight_Hover={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_paddingLeftRight_Hover
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_paddingTopBottom_Hover={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_paddingTopBottom_Hover
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_letterSpacing_Hover={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_letterSpacing_Hover
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowHorizontal_Hover={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowHorizontal_Hover
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowVertical_Hover={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowVertical_Hover
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowBlur_Hover={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowBlur_Hover
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowColor_Hover={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowColor_Hover
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_TextDecoration_Hover={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_TextDecoration_Hover
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_TextDecorationStyle_Hover={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_TextDecorationStyle_Hover
                        }
                        DB_fontFace_SocialTextFontFamily_FontFamilyName={
                          DB_fontFace_SocialTextFontFamily_FontFamilyName
                        }
                        DB_fontFace_SocialTextFontFamily_FontFamilySrc={
                          DB_fontFace_SocialTextFontFamily_FontFamilySrc
                        }
                        DB_LeftSidebar_MenuItem_SocialText_FontWeight={
                          DB_LeftSidebar_MenuItem_SocialText_FontWeight
                        }
                        DB_LeftSidebar_MenuItem_SocialText_FontColor={
                          DB_LeftSidebar_MenuItem_SocialText_FontColor
                        }
                        DB_LeftSidebar_MenuItem_SocialText_FontSize={
                          DB_LeftSidebar_MenuItem_SocialText_FontSize
                        }
                        DB_LeftSidebar_MenuItem_SocialText_BackgroundColor={
                          DB_LeftSidebar_MenuItem_SocialText_BackgroundColor
                        }
                        DB_LeftSidebar_MenuItem_SocialText_BorderTop={
                          DB_LeftSidebar_MenuItem_SocialText_BorderTop
                        }
                        DB_LeftSidebar_MenuItem_SocialText_BorderStyleTop={
                          DB_LeftSidebar_MenuItem_SocialText_BorderStyleTop
                        }
                        DB_LeftSidebar_MenuItem_SocialText_BorderColorTop={
                          DB_LeftSidebar_MenuItem_SocialText_BorderColorTop
                        }
                        DB_LeftSidebar_MenuItem_SocialText_BorderBottom={
                          DB_LeftSidebar_MenuItem_SocialText_BorderBottom
                        }
                        DB_LeftSidebar_MenuItem_SocialText_BorderStyleBottom={
                          DB_LeftSidebar_MenuItem_SocialText_BorderStyleBottom
                        }
                        DB_LeftSidebar_MenuItem_SocialText_BorderColorBottom={
                          DB_LeftSidebar_MenuItem_SocialText_BorderColorBottom
                        }
                        DB_LeftSidebar_MenuItem_SocialText_BorderLeft={
                          DB_LeftSidebar_MenuItem_SocialText_BorderLeft
                        }
                        DB_LeftSidebar_MenuItem_SocialText_BorderStyleLeft={
                          DB_LeftSidebar_MenuItem_SocialText_BorderStyleLeft
                        }
                        DB_LeftSidebar_MenuItem_SocialText_BorderColorLeft={
                          DB_LeftSidebar_MenuItem_SocialText_BorderColorLeft
                        }
                        DB_LeftSidebar_MenuItem_SocialText_BorderRight={
                          DB_LeftSidebar_MenuItem_SocialText_BorderRight
                        }
                        DB_LeftSidebar_MenuItem_SocialText_BorderStyleRight={
                          DB_LeftSidebar_MenuItem_SocialText_BorderStyleRight
                        }
                        DB_LeftSidebar_MenuItem_SocialText_BorderColorRight={
                          DB_LeftSidebar_MenuItem_SocialText_BorderColorRight
                        }
                        DB_LeftSidebar_MenuItem_SocialText_BorderTopRightRadius={
                          DB_LeftSidebar_MenuItem_SocialText_BorderTopRightRadius
                        }
                        DB_LeftSidebar_MenuItem_SocialText_BorderTopLeftRadius={
                          DB_LeftSidebar_MenuItem_SocialText_BorderTopLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_SocialText_BorderBottomRightRadius={
                          DB_LeftSidebar_MenuItem_SocialText_BorderBottomRightRadius
                        }
                        DB_LeftSidebar_MenuItem_SocialText_BorderBottomLeftRadius={
                          DB_LeftSidebar_MenuItem_SocialText_BorderBottomLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_SocialText_marginLeftRight={
                          DB_LeftSidebar_MenuItem_SocialText_marginLeftRight
                        }
                        DB_LeftSidebar_MenuItem_SocialText_marginTopBottom={
                          DB_LeftSidebar_MenuItem_SocialText_marginTopBottom
                        }
                        DB_LeftSidebar_MenuItem_SocialText_paddingLeftRight={
                          DB_LeftSidebar_MenuItem_SocialText_paddingLeftRight
                        }
                        DB_LeftSidebar_MenuItem_SocialText_paddingTopBottom={
                          DB_LeftSidebar_MenuItem_SocialText_paddingTopBottom
                        }
                        DB_LeftSidebar_MenuItem_SocialText_letterSpacing={
                          DB_LeftSidebar_MenuItem_SocialText_letterSpacing
                        }
                        DB_LeftSidebar_MenuItem_SocialText_ShadowHorizontal={
                          DB_LeftSidebar_MenuItem_SocialText_ShadowHorizontal
                        }
                        DB_LeftSidebar_MenuItem_SocialText_ShadowVertical={
                          DB_LeftSidebar_MenuItem_SocialText_ShadowVertical
                        }
                        DB_LeftSidebar_MenuItem_SocialText_ShadowBlur={
                          DB_LeftSidebar_MenuItem_SocialText_ShadowBlur
                        }
                        DB_LeftSidebar_MenuItem_SocialText_ShadowColor={
                          DB_LeftSidebar_MenuItem_SocialText_ShadowColor
                        }
                        DB_LeftSidebar_MenuItem_SocialText_TextAlign={
                          DB_LeftSidebar_MenuItem_SocialText_TextAlign
                        }
                        DB_LeftSidebar_MenuItem_SocialText_TextDecoration={
                          DB_LeftSidebar_MenuItem_SocialText_TextDecoration
                        }
                        DB_LeftSidebar_MenuItem_SocialText_TextDecorationStyle={
                          DB_LeftSidebar_MenuItem_SocialText_TextDecorationStyle
                        }
                        DB_LeftSidebar_MenuItem_SocialText_Hover_FontWeight={
                          DB_LeftSidebar_MenuItem_SocialText_Hover_FontWeight
                        }
                        DB_LeftSidebar_MenuItem_SocialText_Hover_FontColor={
                          DB_LeftSidebar_MenuItem_SocialText_Hover_FontColor
                        }
                        DB_LeftSidebar_MenuItem_SocialText_Hover_FontSize={
                          DB_LeftSidebar_MenuItem_SocialText_Hover_FontSize
                        }
                        DB_LeftSidebar_MenuItem_SocialText_Hover_BackgroundColor={
                          DB_LeftSidebar_MenuItem_SocialText_Hover_BackgroundColor
                        }
                        DB_LeftSidebar_MenuItem_SocialText_Hover_TextAlign={
                          DB_LeftSidebar_MenuItem_SocialText_Hover_TextAlign
                        }
                        DB_LeftSidebar_MenuItem_SocialText_Hover_BorderTop={
                          DB_LeftSidebar_MenuItem_SocialText_Hover_BorderTop
                        }
                        DB_LeftSidebar_MenuItem_SocialText_Hover_BorderStyleTop={
                          DB_LeftSidebar_MenuItem_SocialText_Hover_BorderStyleTop
                        }
                        DB_LeftSidebar_MenuItem_SocialText_Hover_BorderColorTop={
                          DB_LeftSidebar_MenuItem_SocialText_Hover_BorderColorTop
                        }
                        DB_LeftSidebar_MenuItem_SocialText_Hover_BorderBottom={
                          DB_LeftSidebar_MenuItem_SocialText_Hover_BorderBottom
                        }
                        DB_LeftSidebar_MenuItem_SocialText_Hover_BorderStyleBottom={
                          DB_LeftSidebar_MenuItem_SocialText_Hover_BorderStyleBottom
                        }
                        DB_LeftSidebar_MenuItem_SocialText_Hover_BorderColorBottom={
                          DB_LeftSidebar_MenuItem_SocialText_Hover_BorderColorBottom
                        }
                        DB_LeftSidebar_MenuItem_SocialText_Hover_BorderLeft={
                          DB_LeftSidebar_MenuItem_SocialText_Hover_BorderLeft
                        }
                        DB_LeftSidebar_MenuItem_SocialText_Hover_BorderStyleLeft={
                          DB_LeftSidebar_MenuItem_SocialText_Hover_BorderStyleLeft
                        }
                        DB_LeftSidebar_MenuItem_SocialText_Hover_BorderColorLeft={
                          DB_LeftSidebar_MenuItem_SocialText_Hover_BorderColorLeft
                        }
                        DB_LeftSidebar_MenuItem_SocialText_Hover_BorderRight={
                          DB_LeftSidebar_MenuItem_SocialText_Hover_BorderRight
                        }
                        DB_LeftSidebar_MenuItem_SocialText_Hover_BorderStyleRight={
                          DB_LeftSidebar_MenuItem_SocialText_Hover_BorderStyleRight
                        }
                        DB_LeftSidebar_MenuItem_SocialText_Hover_BorderColorRight={
                          DB_LeftSidebar_MenuItem_SocialText_Hover_BorderColorRight
                        }
                        DB_LeftSidebar_MenuItem_SocialText_Hover_BorderTopRightRadius={
                          DB_LeftSidebar_MenuItem_SocialText_Hover_BorderTopRightRadius
                        }
                        DB_LeftSidebar_MenuItem_SocialText_Hover_BorderTopLeftRadius={
                          DB_LeftSidebar_MenuItem_SocialText_Hover_BorderTopLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_SocialText_Hover_BorderBottomRightRadius={
                          DB_LeftSidebar_MenuItem_SocialText_Hover_BorderBottomRightRadius
                        }
                        DB_LeftSidebar_MenuItem_SocialText_Hover_BorderBottomLeftRadius={
                          DB_LeftSidebar_MenuItem_SocialText_Hover_BorderBottomLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_SocialText_marginLeftRight_Hover={
                          DB_LeftSidebar_MenuItem_SocialText_marginLeftRight_Hover
                        }
                        DB_LeftSidebar_MenuItem_SocialText_marginTopBottom_Hover={
                          DB_LeftSidebar_MenuItem_SocialText_marginTopBottom_Hover
                        }
                        DB_LeftSidebar_MenuItem_SocialText_paddingLeftRight_Hover={
                          DB_LeftSidebar_MenuItem_SocialText_paddingLeftRight_Hover
                        }
                        DB_LeftSidebar_MenuItem_SocialText_paddingTopBottom_Hover={
                          DB_LeftSidebar_MenuItem_SocialText_paddingTopBottom_Hover
                        }
                        DB_LeftSidebar_MenuItem_SocialText_letterSpacing_Hover={
                          DB_LeftSidebar_MenuItem_SocialText_letterSpacing_Hover
                        }
                        DB_LeftSidebar_MenuItem_SocialText_ShadowHorizontal_Hover={
                          DB_LeftSidebar_MenuItem_SocialText_ShadowHorizontal_Hover
                        }
                        DB_LeftSidebar_MenuItem_SocialText_ShadowVertical_Hover={
                          DB_LeftSidebar_MenuItem_SocialText_ShadowVertical_Hover
                        }
                        DB_LeftSidebar_MenuItem_SocialText_ShadowBlur_Hover={
                          DB_LeftSidebar_MenuItem_SocialText_ShadowBlur_Hover
                        }
                        DB_LeftSidebar_MenuItem_SocialText_ShadowColor_Hover={
                          DB_LeftSidebar_MenuItem_SocialText_ShadowColor_Hover
                        }
                        DB_LeftSidebar_MenuItem_SocialText_TextDecoration_Hover={
                          DB_LeftSidebar_MenuItem_SocialText_TextDecoration_Hover
                        }
                        DB_LeftSidebar_MenuItem_SocialText_TextDecorationStyle_Hover={
                          DB_LeftSidebar_MenuItem_SocialText_TextDecorationStyle_Hover
                        }
                      />
                    )}
                    {(shopBoxes.boxFourTitel || shopBoxes.boxFourContent) && (
                      <SideBarRightMessageToBuyer
                        boxTitel={shopBoxes.boxFourTitel}
                        boxContent={shopBoxes.boxFourContent}
                        SideBarLeft_ContainerSocial_Farbe_Groesse_Stil_OnMouseOverAndOut={
                          SideBarLeft_ContainerSocial_Farbe_Groesse_Stil_OnMouseOverAndOut
                        }
                        SideBarLeft_ContainerSocial_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut={
                          SideBarLeft_ContainerSocial_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
                        }
                        SideBarLeft_ContainerSocial_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut={
                          SideBarLeft_ContainerSocial_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut
                        }
                        SideBarLeft_ContainerSocial_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut={
                          SideBarLeft_ContainerSocial_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut
                        }
                        SideBarLeft_AlleSocial_Farbe_Groesse_Stil_OnMouseOverAndOut={
                          SideBarLeft_AlleSocial_Farbe_Groesse_Stil_OnMouseOverAndOut
                        }
                        SideBarLeft_AlleSocial_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut={
                          SideBarLeft_AlleSocial_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
                        }
                        SideBarLeft_AlleSocial_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut={
                          SideBarLeft_AlleSocial_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut
                        }
                        SideBarLeft_AlleSocial_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut={
                          SideBarLeft_AlleSocial_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut
                        }
                        SideBarLeft_SocialItem_Farbe_Groesse_Stil_OnMouseOverAndOut={
                          SideBarLeft_SocialItem_Farbe_Groesse_Stil_OnMouseOverAndOut
                        }
                        SideBarLeft_SocialItem_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut={
                          SideBarLeft_SocialItem_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
                        }
                        SideBarLeft_SocialItem_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut={
                          SideBarLeft_SocialItem_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut
                        }
                        SideBarLeft_SocialItem_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut={
                          SideBarLeft_SocialItem_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut
                        }
                        SideBarLeft_ContainerSocial_Farbe_Groesse_Stil_Trigger={
                          SideBarLeft_ContainerSocial_Farbe_Groesse_Stil_Trigger
                        }
                        SideBarLeft_ContainerSocial_Farbe_Groesse_Stil_Hover_Trigger={
                          SideBarLeft_ContainerSocial_Farbe_Groesse_Stil_Hover_Trigger
                        }
                        SideBarLeft_ContainerSocial_HintergrundFarbe_Radius_Stil_Trigger={
                          SideBarLeft_ContainerSocial_HintergrundFarbe_Radius_Stil_Trigger
                        }
                        SideBarLeft_ContainerSocial_HintergrundFarbe_Radius_Stil_Hover_Trigger={
                          SideBarLeft_ContainerSocial_HintergrundFarbe_Radius_Stil_Hover_Trigger
                        }
                        SideBarLeft_AlleSocial_Farbe_Groesse_Stil_Trigger={
                          SideBarLeft_AlleSocial_Farbe_Groesse_Stil_Trigger
                        }
                        SideBarLeft_AlleSocial_Farbe_Groesse_Stil_Hover_Trigger={
                          SideBarLeft_AlleSocial_Farbe_Groesse_Stil_Hover_Trigger
                        }
                        SideBarLeft_AlleSocial_HintergrundFarbe_Radius_Stil_Trigger={
                          SideBarLeft_AlleSocial_HintergrundFarbe_Radius_Stil_Trigger
                        }
                        SideBarLeft_AlleSocial_HintergrundFarbe_Radius_Stil_Hover_Trigger={
                          SideBarLeft_AlleSocial_HintergrundFarbe_Radius_Stil_Hover_Trigger
                        }
                        SideBarLeft_SocialItem_Farbe_Groesse_Stil_Trigger={
                          SideBarLeft_SocialItem_Farbe_Groesse_Stil_Trigger
                        }
                        SideBarLeft_SocialItem_Farbe_Groesse_Stil_Hover_Trigger={
                          SideBarLeft_SocialItem_Farbe_Groesse_Stil_Hover_Trigger
                        }
                        SideBarLeft_SocialItem_HintergrundFarbe_Radius_Stil_Trigger={
                          SideBarLeft_SocialItem_HintergrundFarbe_Radius_Stil_Trigger
                        }
                        SideBarLeft_SocialItem_HintergrundFarbe_Radius_Stil_Hover_Trigger={
                          SideBarLeft_SocialItem_HintergrundFarbe_Radius_Stil_Hover_Trigger
                        }
                        DB_fontFace_FontFamilyName={DB_fontFace_FontFamilyName}
                        DB_LeftSidebar_MenuContainer_Social_DDBackgroundColor={
                          DB_LeftSidebar_MenuContainer_Social_DDBackgroundColor
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDBorderTop={
                          DB_LeftSidebar_MenuContainer_Social_DDBorderTop
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDBorderStyleTop={
                          DB_LeftSidebar_MenuContainer_Social_DDBorderStyleTop
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDBorderColorTop={
                          DB_LeftSidebar_MenuContainer_Social_DDBorderColorTop
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDBorderBottom={
                          DB_LeftSidebar_MenuContainer_Social_DDBorderBottom
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDBorderStyleBottom={
                          DB_LeftSidebar_MenuContainer_Social_DDBorderStyleBottom
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDBorderColorBottom={
                          DB_LeftSidebar_MenuContainer_Social_DDBorderColorBottom
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDBorderLeft={
                          DB_LeftSidebar_MenuContainer_Social_DDBorderLeft
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDBorderStyleLeft={
                          DB_LeftSidebar_MenuContainer_Social_DDBorderStyleLeft
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDBorderColorLeft={
                          DB_LeftSidebar_MenuContainer_Social_DDBorderColorLeft
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDBorderRight={
                          DB_LeftSidebar_MenuContainer_Social_DDBorderRight
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDBorderStyleRight={
                          DB_LeftSidebar_MenuContainer_Social_DDBorderStyleRight
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDBorderColorRight={
                          DB_LeftSidebar_MenuContainer_Social_DDBorderColorRight
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDBorderTopRightRadius={
                          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDBorderTopRightRadius
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDBorderTopLeftRadius={
                          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDBorderTopLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDBorderBottomRightRadius={
                          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDBorderBottomRightRadius
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDBorderBottomLeftRadius={
                          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDBorderBottomLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDpaddingLeft={
                          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDpaddingLeft
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDpaddingRight={
                          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDpaddingRight
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDpaddingTop={
                          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDpaddingTop
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDpaddingBottom={
                          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDpaddingBottom
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDShadowHorizontal={
                          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDShadowHorizontal
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDShadowVertical={
                          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDShadowVertical
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDShadowBlur={
                          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDShadowBlur
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDShadowColor={
                          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDShadowColor
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDHover_BackgroundColor={
                          DB_LeftSidebar_MenuContainer_Social_DDHover_BackgroundColor
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDHover_BorderTop={
                          DB_LeftSidebar_MenuContainer_Social_DDHover_BorderTop
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDHover_BorderStyleTop={
                          DB_LeftSidebar_MenuContainer_Social_DDHover_BorderStyleTop
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDHover_BorderColorTop={
                          DB_LeftSidebar_MenuContainer_Social_DDHover_BorderColorTop
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDHover_BorderBottom={
                          DB_LeftSidebar_MenuContainer_Social_DDHover_BorderBottom
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDHover_BorderStyleBottom={
                          DB_LeftSidebar_MenuContainer_Social_DDHover_BorderStyleBottom
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDHover_BorderColorBottom={
                          DB_LeftSidebar_MenuContainer_Social_DDHover_BorderColorBottom
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDHover_BorderLeft={
                          DB_LeftSidebar_MenuContainer_Social_DDHover_BorderLeft
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDHover_BorderStyleLeft={
                          DB_LeftSidebar_MenuContainer_Social_DDHover_BorderStyleLeft
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDHover_BorderColorLeft={
                          DB_LeftSidebar_MenuContainer_Social_DDHover_BorderColorLeft
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDHover_BorderRight={
                          DB_LeftSidebar_MenuContainer_Social_DDHover_BorderRight
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDHover_BorderStyleRight={
                          DB_LeftSidebar_MenuContainer_Social_DDHover_BorderStyleRight
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDHover_BorderColorRight={
                          DB_LeftSidebar_MenuContainer_Social_DDHover_BorderColorRight
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_BorderTopRightRadius={
                          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_BorderTopRightRadius
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_BorderTopLeftRadius={
                          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_BorderTopLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_BorderBottomRightRadius={
                          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_BorderBottomRightRadius
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_BorderBottomLeftRadius={
                          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_BorderBottomLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_paddingLeft={
                          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_paddingLeft
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_paddingRight={
                          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_paddingRight
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_paddingTop={
                          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_paddingTop
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_paddingBottom={
                          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_paddingBottom
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_ShadowHorizontal={
                          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_ShadowHorizontal
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_ShadowVertical={
                          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_ShadowVertical
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_ShadowBlur={
                          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_ShadowBlur
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_ShadowColor={
                          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_ShadowColor
                        }
                        DB_fontFace_SocialTitelFontFamily_FontFamilyName={
                          DB_fontFace_SocialTitelFontFamily_FontFamilyName
                        }
                        DB_fontFace_SocialTitelFontFamily_FontFamilySrc={
                          DB_fontFace_SocialTitelFontFamily_FontFamilySrc
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_FontWeight={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_FontWeight
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_FontColor={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_FontColor
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_FontSize={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_FontSize
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BackgroundColor={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BackgroundColor
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_TextAlign={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_TextAlign
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderTop={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderTop
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderStyleTop={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderStyleTop
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderColorTop={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderColorTop
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderBottom={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderBottom
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderStyleBottom={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderStyleBottom
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderColorBottom={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderColorBottom
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderLeft={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderLeft
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderStyleLeft={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderStyleLeft
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderColorLeft={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderColorLeft
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderRight={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderRight
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderStyleRight={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderStyleRight
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderColorRight={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderColorRight
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderTopRightRadius={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderTopRightRadius
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderTopLeftRadius={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderTopLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderBottomRightRadius={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderBottomRightRadius
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderBottomLeftRadius={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderBottomLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_marginLeftRight={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_marginLeftRight
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_marginTopBottom={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_marginTopBottom
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_paddingLeftRight={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_paddingLeftRight
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_paddingTopBottom={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_paddingTopBottom
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_letterSpacing={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_letterSpacing
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowHorizontal={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowHorizontal
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowVertical={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowVertical
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowBlur={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowBlur
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowColor={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowColor
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_TextDecoration={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_TextDecoration
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_TextDecorationStyle={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_TextDecorationStyle
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_FontWeight={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_FontWeight
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_FontColor={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_FontColor
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_FontSize={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_FontSize
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BackgroundColor={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BackgroundColor
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_TextAlign={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_TextAlign
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderTop={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderTop
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderStyleTop={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderStyleTop
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderColorTop={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderColorTop
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderBottom={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderBottom
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderStyleBottom={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderStyleBottom
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderColorBottom={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderColorBottom
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderLeft={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderLeft
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderStyleLeft={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderStyleLeft
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderColorLeft={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderColorLeft
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderRight={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderRight
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderStyleRight={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderStyleRight
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderColorRight={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderColorRight
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderTopRightRadius={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderTopRightRadius
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderTopLeftRadius={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderTopLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderBottomRightRadius={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderBottomRightRadius
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderBottomLeftRadius={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderBottomLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_marginLeftRight_Hover={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_marginLeftRight_Hover
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_marginTopBottom_Hover={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_marginTopBottom_Hover
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_paddingLeftRight_Hover={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_paddingLeftRight_Hover
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_paddingTopBottom_Hover={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_paddingTopBottom_Hover
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_letterSpacing_Hover={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_letterSpacing_Hover
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowHorizontal_Hover={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowHorizontal_Hover
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowVertical_Hover={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowVertical_Hover
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowBlur_Hover={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowBlur_Hover
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowColor_Hover={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowColor_Hover
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_TextDecoration_Hover={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_TextDecoration_Hover
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_TextDecorationStyle_Hover={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_TextDecorationStyle_Hover
                        }
                        DB_fontFace_SocialTextFontFamily_FontFamilyName={
                          DB_fontFace_SocialTextFontFamily_FontFamilyName
                        }
                        DB_fontFace_SocialTextFontFamily_FontFamilySrc={
                          DB_fontFace_SocialTextFontFamily_FontFamilySrc
                        }
                        DB_LeftSidebar_MenuItem_SocialText_FontWeight={
                          DB_LeftSidebar_MenuItem_SocialText_FontWeight
                        }
                        DB_LeftSidebar_MenuItem_SocialText_FontColor={
                          DB_LeftSidebar_MenuItem_SocialText_FontColor
                        }
                        DB_LeftSidebar_MenuItem_SocialText_FontSize={
                          DB_LeftSidebar_MenuItem_SocialText_FontSize
                        }
                        DB_LeftSidebar_MenuItem_SocialText_BackgroundColor={
                          DB_LeftSidebar_MenuItem_SocialText_BackgroundColor
                        }
                        DB_LeftSidebar_MenuItem_SocialText_BorderTop={
                          DB_LeftSidebar_MenuItem_SocialText_BorderTop
                        }
                        DB_LeftSidebar_MenuItem_SocialText_BorderStyleTop={
                          DB_LeftSidebar_MenuItem_SocialText_BorderStyleTop
                        }
                        DB_LeftSidebar_MenuItem_SocialText_BorderColorTop={
                          DB_LeftSidebar_MenuItem_SocialText_BorderColorTop
                        }
                        DB_LeftSidebar_MenuItem_SocialText_BorderBottom={
                          DB_LeftSidebar_MenuItem_SocialText_BorderBottom
                        }
                        DB_LeftSidebar_MenuItem_SocialText_BorderStyleBottom={
                          DB_LeftSidebar_MenuItem_SocialText_BorderStyleBottom
                        }
                        DB_LeftSidebar_MenuItem_SocialText_BorderColorBottom={
                          DB_LeftSidebar_MenuItem_SocialText_BorderColorBottom
                        }
                        DB_LeftSidebar_MenuItem_SocialText_BorderLeft={
                          DB_LeftSidebar_MenuItem_SocialText_BorderLeft
                        }
                        DB_LeftSidebar_MenuItem_SocialText_BorderStyleLeft={
                          DB_LeftSidebar_MenuItem_SocialText_BorderStyleLeft
                        }
                        DB_LeftSidebar_MenuItem_SocialText_BorderColorLeft={
                          DB_LeftSidebar_MenuItem_SocialText_BorderColorLeft
                        }
                        DB_LeftSidebar_MenuItem_SocialText_BorderRight={
                          DB_LeftSidebar_MenuItem_SocialText_BorderRight
                        }
                        DB_LeftSidebar_MenuItem_SocialText_BorderStyleRight={
                          DB_LeftSidebar_MenuItem_SocialText_BorderStyleRight
                        }
                        DB_LeftSidebar_MenuItem_SocialText_BorderColorRight={
                          DB_LeftSidebar_MenuItem_SocialText_BorderColorRight
                        }
                        DB_LeftSidebar_MenuItem_SocialText_BorderTopRightRadius={
                          DB_LeftSidebar_MenuItem_SocialText_BorderTopRightRadius
                        }
                        DB_LeftSidebar_MenuItem_SocialText_BorderTopLeftRadius={
                          DB_LeftSidebar_MenuItem_SocialText_BorderTopLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_SocialText_BorderBottomRightRadius={
                          DB_LeftSidebar_MenuItem_SocialText_BorderBottomRightRadius
                        }
                        DB_LeftSidebar_MenuItem_SocialText_BorderBottomLeftRadius={
                          DB_LeftSidebar_MenuItem_SocialText_BorderBottomLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_SocialText_marginLeftRight={
                          DB_LeftSidebar_MenuItem_SocialText_marginLeftRight
                        }
                        DB_LeftSidebar_MenuItem_SocialText_marginTopBottom={
                          DB_LeftSidebar_MenuItem_SocialText_marginTopBottom
                        }
                        DB_LeftSidebar_MenuItem_SocialText_paddingLeftRight={
                          DB_LeftSidebar_MenuItem_SocialText_paddingLeftRight
                        }
                        DB_LeftSidebar_MenuItem_SocialText_paddingTopBottom={
                          DB_LeftSidebar_MenuItem_SocialText_paddingTopBottom
                        }
                        DB_LeftSidebar_MenuItem_SocialText_letterSpacing={
                          DB_LeftSidebar_MenuItem_SocialText_letterSpacing
                        }
                        DB_LeftSidebar_MenuItem_SocialText_ShadowHorizontal={
                          DB_LeftSidebar_MenuItem_SocialText_ShadowHorizontal
                        }
                        DB_LeftSidebar_MenuItem_SocialText_ShadowVertical={
                          DB_LeftSidebar_MenuItem_SocialText_ShadowVertical
                        }
                        DB_LeftSidebar_MenuItem_SocialText_ShadowBlur={
                          DB_LeftSidebar_MenuItem_SocialText_ShadowBlur
                        }
                        DB_LeftSidebar_MenuItem_SocialText_ShadowColor={
                          DB_LeftSidebar_MenuItem_SocialText_ShadowColor
                        }
                        DB_LeftSidebar_MenuItem_SocialText_TextAlign={
                          DB_LeftSidebar_MenuItem_SocialText_TextAlign
                        }
                        DB_LeftSidebar_MenuItem_SocialText_TextDecoration={
                          DB_LeftSidebar_MenuItem_SocialText_TextDecoration
                        }
                        DB_LeftSidebar_MenuItem_SocialText_TextDecorationStyle={
                          DB_LeftSidebar_MenuItem_SocialText_TextDecorationStyle
                        }
                        DB_LeftSidebar_MenuItem_SocialText_Hover_FontWeight={
                          DB_LeftSidebar_MenuItem_SocialText_Hover_FontWeight
                        }
                        DB_LeftSidebar_MenuItem_SocialText_Hover_FontColor={
                          DB_LeftSidebar_MenuItem_SocialText_Hover_FontColor
                        }
                        DB_LeftSidebar_MenuItem_SocialText_Hover_FontSize={
                          DB_LeftSidebar_MenuItem_SocialText_Hover_FontSize
                        }
                        DB_LeftSidebar_MenuItem_SocialText_Hover_BackgroundColor={
                          DB_LeftSidebar_MenuItem_SocialText_Hover_BackgroundColor
                        }
                        DB_LeftSidebar_MenuItem_SocialText_Hover_TextAlign={
                          DB_LeftSidebar_MenuItem_SocialText_Hover_TextAlign
                        }
                        DB_LeftSidebar_MenuItem_SocialText_Hover_BorderTop={
                          DB_LeftSidebar_MenuItem_SocialText_Hover_BorderTop
                        }
                        DB_LeftSidebar_MenuItem_SocialText_Hover_BorderStyleTop={
                          DB_LeftSidebar_MenuItem_SocialText_Hover_BorderStyleTop
                        }
                        DB_LeftSidebar_MenuItem_SocialText_Hover_BorderColorTop={
                          DB_LeftSidebar_MenuItem_SocialText_Hover_BorderColorTop
                        }
                        DB_LeftSidebar_MenuItem_SocialText_Hover_BorderBottom={
                          DB_LeftSidebar_MenuItem_SocialText_Hover_BorderBottom
                        }
                        DB_LeftSidebar_MenuItem_SocialText_Hover_BorderStyleBottom={
                          DB_LeftSidebar_MenuItem_SocialText_Hover_BorderStyleBottom
                        }
                        DB_LeftSidebar_MenuItem_SocialText_Hover_BorderColorBottom={
                          DB_LeftSidebar_MenuItem_SocialText_Hover_BorderColorBottom
                        }
                        DB_LeftSidebar_MenuItem_SocialText_Hover_BorderLeft={
                          DB_LeftSidebar_MenuItem_SocialText_Hover_BorderLeft
                        }
                        DB_LeftSidebar_MenuItem_SocialText_Hover_BorderStyleLeft={
                          DB_LeftSidebar_MenuItem_SocialText_Hover_BorderStyleLeft
                        }
                        DB_LeftSidebar_MenuItem_SocialText_Hover_BorderColorLeft={
                          DB_LeftSidebar_MenuItem_SocialText_Hover_BorderColorLeft
                        }
                        DB_LeftSidebar_MenuItem_SocialText_Hover_BorderRight={
                          DB_LeftSidebar_MenuItem_SocialText_Hover_BorderRight
                        }
                        DB_LeftSidebar_MenuItem_SocialText_Hover_BorderStyleRight={
                          DB_LeftSidebar_MenuItem_SocialText_Hover_BorderStyleRight
                        }
                        DB_LeftSidebar_MenuItem_SocialText_Hover_BorderColorRight={
                          DB_LeftSidebar_MenuItem_SocialText_Hover_BorderColorRight
                        }
                        DB_LeftSidebar_MenuItem_SocialText_Hover_BorderTopRightRadius={
                          DB_LeftSidebar_MenuItem_SocialText_Hover_BorderTopRightRadius
                        }
                        DB_LeftSidebar_MenuItem_SocialText_Hover_BorderTopLeftRadius={
                          DB_LeftSidebar_MenuItem_SocialText_Hover_BorderTopLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_SocialText_Hover_BorderBottomRightRadius={
                          DB_LeftSidebar_MenuItem_SocialText_Hover_BorderBottomRightRadius
                        }
                        DB_LeftSidebar_MenuItem_SocialText_Hover_BorderBottomLeftRadius={
                          DB_LeftSidebar_MenuItem_SocialText_Hover_BorderBottomLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_SocialText_marginLeftRight_Hover={
                          DB_LeftSidebar_MenuItem_SocialText_marginLeftRight_Hover
                        }
                        DB_LeftSidebar_MenuItem_SocialText_marginTopBottom_Hover={
                          DB_LeftSidebar_MenuItem_SocialText_marginTopBottom_Hover
                        }
                        DB_LeftSidebar_MenuItem_SocialText_paddingLeftRight_Hover={
                          DB_LeftSidebar_MenuItem_SocialText_paddingLeftRight_Hover
                        }
                        DB_LeftSidebar_MenuItem_SocialText_paddingTopBottom_Hover={
                          DB_LeftSidebar_MenuItem_SocialText_paddingTopBottom_Hover
                        }
                        DB_LeftSidebar_MenuItem_SocialText_letterSpacing_Hover={
                          DB_LeftSidebar_MenuItem_SocialText_letterSpacing_Hover
                        }
                        DB_LeftSidebar_MenuItem_SocialText_ShadowHorizontal_Hover={
                          DB_LeftSidebar_MenuItem_SocialText_ShadowHorizontal_Hover
                        }
                        DB_LeftSidebar_MenuItem_SocialText_ShadowVertical_Hover={
                          DB_LeftSidebar_MenuItem_SocialText_ShadowVertical_Hover
                        }
                        DB_LeftSidebar_MenuItem_SocialText_ShadowBlur_Hover={
                          DB_LeftSidebar_MenuItem_SocialText_ShadowBlur_Hover
                        }
                        DB_LeftSidebar_MenuItem_SocialText_ShadowColor_Hover={
                          DB_LeftSidebar_MenuItem_SocialText_ShadowColor_Hover
                        }
                        DB_LeftSidebar_MenuItem_SocialText_TextDecoration_Hover={
                          DB_LeftSidebar_MenuItem_SocialText_TextDecoration_Hover
                        }
                        DB_LeftSidebar_MenuItem_SocialText_TextDecorationStyle_Hover={
                          DB_LeftSidebar_MenuItem_SocialText_TextDecorationStyle_Hover
                        }
                      />
                    )}
                    <SideBarRightSocialIcons
                      SideBarLeft_ContainerSocial_Farbe_Groesse_Stil_OnMouseOverAndOut={
                        SideBarLeft_ContainerSocial_Farbe_Groesse_Stil_OnMouseOverAndOut
                      }
                      SideBarLeft_ContainerSocial_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut={
                        SideBarLeft_ContainerSocial_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
                      }
                      SideBarLeft_ContainerSocial_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut={
                        SideBarLeft_ContainerSocial_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut
                      }
                      SideBarLeft_ContainerSocial_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut={
                        SideBarLeft_ContainerSocial_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut
                      }
                      SideBarLeft_AlleSocial_Farbe_Groesse_Stil_OnMouseOverAndOut={
                        SideBarLeft_AlleSocial_Farbe_Groesse_Stil_OnMouseOverAndOut
                      }
                      SideBarLeft_AlleSocial_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut={
                        SideBarLeft_AlleSocial_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
                      }
                      SideBarLeft_AlleSocial_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut={
                        SideBarLeft_AlleSocial_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut
                      }
                      SideBarLeft_AlleSocial_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut={
                        SideBarLeft_AlleSocial_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut
                      }
                      SideBarLeft_SocialItem_Farbe_Groesse_Stil_OnMouseOverAndOut={
                        SideBarLeft_SocialItem_Farbe_Groesse_Stil_OnMouseOverAndOut
                      }
                      SideBarLeft_SocialItem_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut={
                        SideBarLeft_SocialItem_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
                      }
                      SideBarLeft_SocialItem_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut={
                        SideBarLeft_SocialItem_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut
                      }
                      SideBarLeft_SocialItem_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut={
                        SideBarLeft_SocialItem_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut
                      }
                      DB_fontFace_FontFamilyName={DB_fontFace_FontFamilyName}
                      DB_LeftSidebar_MenuContainer_Social_DDBackgroundColor={
                        DB_LeftSidebar_MenuContainer_Social_DDBackgroundColor
                      }
                      DB_LeftSidebar_MenuContainer_Social_DDBorderTop={
                        DB_LeftSidebar_MenuContainer_Social_DDBorderTop
                      }
                      DB_LeftSidebar_MenuContainer_Social_DDBorderStyleTop={
                        DB_LeftSidebar_MenuContainer_Social_DDBorderStyleTop
                      }
                      DB_LeftSidebar_MenuContainer_Social_DDBorderColorTop={
                        DB_LeftSidebar_MenuContainer_Social_DDBorderColorTop
                      }
                      DB_LeftSidebar_MenuContainer_Social_DDBorderBottom={
                        DB_LeftSidebar_MenuContainer_Social_DDBorderBottom
                      }
                      DB_LeftSidebar_MenuContainer_Social_DDBorderStyleBottom={
                        DB_LeftSidebar_MenuContainer_Social_DDBorderStyleBottom
                      }
                      DB_LeftSidebar_MenuContainer_Social_DDBorderColorBottom={
                        DB_LeftSidebar_MenuContainer_Social_DDBorderColorBottom
                      }
                      DB_LeftSidebar_MenuContainer_Social_DDBorderLeft={
                        DB_LeftSidebar_MenuContainer_Social_DDBorderLeft
                      }
                      DB_LeftSidebar_MenuContainer_Social_DDBorderStyleLeft={
                        DB_LeftSidebar_MenuContainer_Social_DDBorderStyleLeft
                      }
                      DB_LeftSidebar_MenuContainer_Social_DDBorderColorLeft={
                        DB_LeftSidebar_MenuContainer_Social_DDBorderColorLeft
                      }
                      DB_LeftSidebar_MenuContainer_Social_DDBorderRight={
                        DB_LeftSidebar_MenuContainer_Social_DDBorderRight
                      }
                      DB_LeftSidebar_MenuContainer_Social_DDBorderStyleRight={
                        DB_LeftSidebar_MenuContainer_Social_DDBorderStyleRight
                      }
                      DB_LeftSidebar_MenuContainer_Social_DDBorderColorRight={
                        DB_LeftSidebar_MenuContainer_Social_DDBorderColorRight
                      }
                      DB_LeftSidebar_MenuItem_MenuContainer_Social_DDBorderTopRightRadius={
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDBorderTopRightRadius
                      }
                      DB_LeftSidebar_MenuItem_MenuContainer_Social_DDBorderTopLeftRadius={
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDBorderTopLeftRadius
                      }
                      DB_LeftSidebar_MenuItem_MenuContainer_Social_DDBorderBottomRightRadius={
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDBorderBottomRightRadius
                      }
                      DB_LeftSidebar_MenuItem_MenuContainer_Social_DDBorderBottomLeftRadius={
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDBorderBottomLeftRadius
                      }
                      DB_LeftSidebar_MenuItem_MenuContainer_Social_DDpaddingLeft={
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDpaddingLeft
                      }
                      DB_LeftSidebar_MenuItem_MenuContainer_Social_DDpaddingRight={
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDpaddingRight
                      }
                      DB_LeftSidebar_MenuItem_MenuContainer_Social_DDpaddingTop={
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDpaddingTop
                      }
                      DB_LeftSidebar_MenuItem_MenuContainer_Social_DDpaddingBottom={
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDpaddingBottom
                      }
                      DB_LeftSidebar_MenuItem_MenuContainer_Social_DDShadowHorizontal={
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDShadowHorizontal
                      }
                      DB_LeftSidebar_MenuItem_MenuContainer_Social_DDShadowVertical={
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDShadowVertical
                      }
                      DB_LeftSidebar_MenuItem_MenuContainer_Social_DDShadowBlur={
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDShadowBlur
                      }
                      DB_LeftSidebar_MenuItem_MenuContainer_Social_DDShadowColor={
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDShadowColor
                      }
                      DB_LeftSidebar_MenuContainer_Social_DDHover_BackgroundColor={
                        DB_LeftSidebar_MenuContainer_Social_DDHover_BackgroundColor
                      }
                      DB_LeftSidebar_MenuContainer_Social_DDHover_BorderTop={
                        DB_LeftSidebar_MenuContainer_Social_DDHover_BorderTop
                      }
                      DB_LeftSidebar_MenuContainer_Social_DDHover_BorderStyleTop={
                        DB_LeftSidebar_MenuContainer_Social_DDHover_BorderStyleTop
                      }
                      DB_LeftSidebar_MenuContainer_Social_DDHover_BorderColorTop={
                        DB_LeftSidebar_MenuContainer_Social_DDHover_BorderColorTop
                      }
                      DB_LeftSidebar_MenuContainer_Social_DDHover_BorderBottom={
                        DB_LeftSidebar_MenuContainer_Social_DDHover_BorderBottom
                      }
                      DB_LeftSidebar_MenuContainer_Social_DDHover_BorderStyleBottom={
                        DB_LeftSidebar_MenuContainer_Social_DDHover_BorderStyleBottom
                      }
                      DB_LeftSidebar_MenuContainer_Social_DDHover_BorderColorBottom={
                        DB_LeftSidebar_MenuContainer_Social_DDHover_BorderColorBottom
                      }
                      DB_LeftSidebar_MenuContainer_Social_DDHover_BorderLeft={
                        DB_LeftSidebar_MenuContainer_Social_DDHover_BorderLeft
                      }
                      DB_LeftSidebar_MenuContainer_Social_DDHover_BorderStyleLeft={
                        DB_LeftSidebar_MenuContainer_Social_DDHover_BorderStyleLeft
                      }
                      DB_LeftSidebar_MenuContainer_Social_DDHover_BorderColorLeft={
                        DB_LeftSidebar_MenuContainer_Social_DDHover_BorderColorLeft
                      }
                      DB_LeftSidebar_MenuContainer_Social_DDHover_BorderRight={
                        DB_LeftSidebar_MenuContainer_Social_DDHover_BorderRight
                      }
                      DB_LeftSidebar_MenuContainer_Social_DDHover_BorderStyleRight={
                        DB_LeftSidebar_MenuContainer_Social_DDHover_BorderStyleRight
                      }
                      DB_LeftSidebar_MenuContainer_Social_DDHover_BorderColorRight={
                        DB_LeftSidebar_MenuContainer_Social_DDHover_BorderColorRight
                      }
                      DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_BorderTopRightRadius={
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_BorderTopRightRadius
                      }
                      DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_BorderTopLeftRadius={
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_BorderTopLeftRadius
                      }
                      DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_BorderBottomRightRadius={
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_BorderBottomRightRadius
                      }
                      DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_BorderBottomLeftRadius={
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_BorderBottomLeftRadius
                      }
                      DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_paddingLeft={
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_paddingLeft
                      }
                      DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_paddingRight={
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_paddingRight
                      }
                      DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_paddingTop={
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_paddingTop
                      }
                      DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_paddingBottom={
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_paddingBottom
                      }
                      DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_ShadowHorizontal={
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_ShadowHorizontal
                      }
                      DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_ShadowVertical={
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_ShadowVertical
                      }
                      DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_ShadowBlur={
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_ShadowBlur
                      }
                      DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_ShadowColor={
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_ShadowColor
                      }
                      DB_fontFace_SocialTitelFontFamily_FontFamilyName={
                        DB_fontFace_SocialTitelFontFamily_FontFamilyName
                      }
                      DB_fontFace_SocialTitelFontFamily_FontFamilySrc={
                        DB_fontFace_SocialTitelFontFamily_FontFamilySrc
                      }
                      DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_FontWeight={
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_FontWeight
                      }
                      DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_FontColor={
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_FontColor
                      }
                      DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_FontSize={
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_FontSize
                      }
                      DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BackgroundColor={
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BackgroundColor
                      }
                      DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_TextAlign={
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_TextAlign
                      }
                      DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderTop={
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderTop
                      }
                      DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderStyleTop={
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderStyleTop
                      }
                      DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderColorTop={
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderColorTop
                      }
                      DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderBottom={
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderBottom
                      }
                      DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderStyleBottom={
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderStyleBottom
                      }
                      DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderColorBottom={
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderColorBottom
                      }
                      DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderLeft={
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderLeft
                      }
                      DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderStyleLeft={
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderStyleLeft
                      }
                      DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderColorLeft={
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderColorLeft
                      }
                      DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderRight={
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderRight
                      }
                      DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderStyleRight={
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderStyleRight
                      }
                      DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderColorRight={
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderColorRight
                      }
                      DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderTopRightRadius={
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderTopRightRadius
                      }
                      DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderTopLeftRadius={
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderTopLeftRadius
                      }
                      DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderBottomRightRadius={
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderBottomRightRadius
                      }
                      DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderBottomLeftRadius={
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderBottomLeftRadius
                      }
                      DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_marginLeftRight={
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_marginLeftRight
                      }
                      DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_marginTopBottom={
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_marginTopBottom
                      }
                      DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_paddingLeftRight={
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_paddingLeftRight
                      }
                      DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_paddingTopBottom={
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_paddingTopBottom
                      }
                      DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_letterSpacing={
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_letterSpacing
                      }
                      DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowHorizontal={
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowHorizontal
                      }
                      DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowVertical={
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowVertical
                      }
                      DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowBlur={
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowBlur
                      }
                      DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowColor={
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowColor
                      }
                      DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_TextDecoration={
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_TextDecoration
                      }
                      DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_TextDecorationStyle={
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_TextDecorationStyle
                      }
                      DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_FontWeight={
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_FontWeight
                      }
                      DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_FontColor={
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_FontColor
                      }
                      DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_FontSize={
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_FontSize
                      }
                      DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BackgroundColor={
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BackgroundColor
                      }
                      DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_TextAlign={
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_TextAlign
                      }
                      DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderTop={
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderTop
                      }
                      DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderStyleTop={
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderStyleTop
                      }
                      DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderColorTop={
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderColorTop
                      }
                      DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderBottom={
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderBottom
                      }
                      DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderStyleBottom={
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderStyleBottom
                      }
                      DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderColorBottom={
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderColorBottom
                      }
                      DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderLeft={
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderLeft
                      }
                      DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderStyleLeft={
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderStyleLeft
                      }
                      DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderColorLeft={
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderColorLeft
                      }
                      DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderRight={
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderRight
                      }
                      DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderStyleRight={
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderStyleRight
                      }
                      DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderColorRight={
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderColorRight
                      }
                      DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderTopRightRadius={
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderTopRightRadius
                      }
                      DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderTopLeftRadius={
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderTopLeftRadius
                      }
                      DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderBottomRightRadius={
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderBottomRightRadius
                      }
                      DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderBottomLeftRadius={
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderBottomLeftRadius
                      }
                      DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_marginLeftRight_Hover={
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_marginLeftRight_Hover
                      }
                      DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_marginTopBottom_Hover={
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_marginTopBottom_Hover
                      }
                      DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_paddingLeftRight_Hover={
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_paddingLeftRight_Hover
                      }
                      DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_paddingTopBottom_Hover={
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_paddingTopBottom_Hover
                      }
                      DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_letterSpacing_Hover={
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_letterSpacing_Hover
                      }
                      DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowHorizontal_Hover={
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowHorizontal_Hover
                      }
                      DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowVertical_Hover={
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowVertical_Hover
                      }
                      DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowBlur_Hover={
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowBlur_Hover
                      }
                      DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowColor_Hover={
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowColor_Hover
                      }
                      DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_TextDecoration_Hover={
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_TextDecoration_Hover
                      }
                      DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_TextDecorationStyle_Hover={
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_TextDecorationStyle_Hover
                      }
                      DB_fontFace_SocialTextFontFamily_FontFamilyName={
                        DB_fontFace_SocialTextFontFamily_FontFamilyName
                      }
                      DB_fontFace_SocialTextFontFamily_FontFamilySrc={
                        DB_fontFace_SocialTextFontFamily_FontFamilySrc
                      }
                      DB_LeftSidebar_MenuItem_SocialText_FontWeight={
                        DB_LeftSidebar_MenuItem_SocialText_FontWeight
                      }
                      DB_LeftSidebar_MenuItem_SocialText_FontColor={
                        DB_LeftSidebar_MenuItem_SocialText_FontColor
                      }
                      DB_LeftSidebar_MenuItem_SocialText_FontSize={
                        DB_LeftSidebar_MenuItem_SocialText_FontSize
                      }
                      DB_LeftSidebar_MenuItem_SocialText_BackgroundColor={
                        DB_LeftSidebar_MenuItem_SocialText_BackgroundColor
                      }
                      DB_LeftSidebar_MenuItem_SocialText_BorderTop={
                        DB_LeftSidebar_MenuItem_SocialText_BorderTop
                      }
                      DB_LeftSidebar_MenuItem_SocialText_BorderStyleTop={
                        DB_LeftSidebar_MenuItem_SocialText_BorderStyleTop
                      }
                      DB_LeftSidebar_MenuItem_SocialText_BorderColorTop={
                        DB_LeftSidebar_MenuItem_SocialText_BorderColorTop
                      }
                      DB_LeftSidebar_MenuItem_SocialText_BorderBottom={
                        DB_LeftSidebar_MenuItem_SocialText_BorderBottom
                      }
                      DB_LeftSidebar_MenuItem_SocialText_BorderStyleBottom={
                        DB_LeftSidebar_MenuItem_SocialText_BorderStyleBottom
                      }
                      DB_LeftSidebar_MenuItem_SocialText_BorderColorBottom={
                        DB_LeftSidebar_MenuItem_SocialText_BorderColorBottom
                      }
                      DB_LeftSidebar_MenuItem_SocialText_BorderLeft={
                        DB_LeftSidebar_MenuItem_SocialText_BorderLeft
                      }
                      DB_LeftSidebar_MenuItem_SocialText_BorderStyleLeft={
                        DB_LeftSidebar_MenuItem_SocialText_BorderStyleLeft
                      }
                      DB_LeftSidebar_MenuItem_SocialText_BorderColorLeft={
                        DB_LeftSidebar_MenuItem_SocialText_BorderColorLeft
                      }
                      DB_LeftSidebar_MenuItem_SocialText_BorderRight={
                        DB_LeftSidebar_MenuItem_SocialText_BorderRight
                      }
                      DB_LeftSidebar_MenuItem_SocialText_BorderStyleRight={
                        DB_LeftSidebar_MenuItem_SocialText_BorderStyleRight
                      }
                      DB_LeftSidebar_MenuItem_SocialText_BorderColorRight={
                        DB_LeftSidebar_MenuItem_SocialText_BorderColorRight
                      }
                      DB_LeftSidebar_MenuItem_SocialText_BorderTopRightRadius={
                        DB_LeftSidebar_MenuItem_SocialText_BorderTopRightRadius
                      }
                      DB_LeftSidebar_MenuItem_SocialText_BorderTopLeftRadius={
                        DB_LeftSidebar_MenuItem_SocialText_BorderTopLeftRadius
                      }
                      DB_LeftSidebar_MenuItem_SocialText_BorderBottomRightRadius={
                        DB_LeftSidebar_MenuItem_SocialText_BorderBottomRightRadius
                      }
                      DB_LeftSidebar_MenuItem_SocialText_BorderBottomLeftRadius={
                        DB_LeftSidebar_MenuItem_SocialText_BorderBottomLeftRadius
                      }
                      DB_LeftSidebar_MenuItem_SocialText_marginLeftRight={
                        DB_LeftSidebar_MenuItem_SocialText_marginLeftRight
                      }
                      DB_LeftSidebar_MenuItem_SocialText_marginTopBottom={
                        DB_LeftSidebar_MenuItem_SocialText_marginTopBottom
                      }
                      DB_LeftSidebar_MenuItem_SocialText_paddingLeftRight={
                        DB_LeftSidebar_MenuItem_SocialText_paddingLeftRight
                      }
                      DB_LeftSidebar_MenuItem_SocialText_paddingTopBottom={
                        DB_LeftSidebar_MenuItem_SocialText_paddingTopBottom
                      }
                      DB_LeftSidebar_MenuItem_SocialText_letterSpacing={
                        DB_LeftSidebar_MenuItem_SocialText_letterSpacing
                      }
                      DB_LeftSidebar_MenuItem_SocialText_ShadowHorizontal={
                        DB_LeftSidebar_MenuItem_SocialText_ShadowHorizontal
                      }
                      DB_LeftSidebar_MenuItem_SocialText_ShadowVertical={
                        DB_LeftSidebar_MenuItem_SocialText_ShadowVertical
                      }
                      DB_LeftSidebar_MenuItem_SocialText_ShadowBlur={
                        DB_LeftSidebar_MenuItem_SocialText_ShadowBlur
                      }
                      DB_LeftSidebar_MenuItem_SocialText_ShadowColor={
                        DB_LeftSidebar_MenuItem_SocialText_ShadowColor
                      }
                      DB_LeftSidebar_MenuItem_SocialText_TextAlign={
                        DB_LeftSidebar_MenuItem_SocialText_TextAlign
                      }
                      DB_LeftSidebar_MenuItem_SocialText_TextDecoration={
                        DB_LeftSidebar_MenuItem_SocialText_TextDecoration
                      }
                      DB_LeftSidebar_MenuItem_SocialText_TextDecorationStyle={
                        DB_LeftSidebar_MenuItem_SocialText_TextDecorationStyle
                      }
                      DB_LeftSidebar_MenuItem_SocialText_Hover_FontWeight={
                        DB_LeftSidebar_MenuItem_SocialText_Hover_FontWeight
                      }
                      DB_LeftSidebar_MenuItem_SocialText_Hover_FontColor={
                        DB_LeftSidebar_MenuItem_SocialText_Hover_FontColor
                      }
                      DB_LeftSidebar_MenuItem_SocialText_Hover_FontSize={
                        DB_LeftSidebar_MenuItem_SocialText_Hover_FontSize
                      }
                      DB_LeftSidebar_MenuItem_SocialText_Hover_BackgroundColor={
                        DB_LeftSidebar_MenuItem_SocialText_Hover_BackgroundColor
                      }
                      DB_LeftSidebar_MenuItem_SocialText_Hover_TextAlign={
                        DB_LeftSidebar_MenuItem_SocialText_Hover_TextAlign
                      }
                      DB_LeftSidebar_MenuItem_SocialText_Hover_BorderTop={
                        DB_LeftSidebar_MenuItem_SocialText_Hover_BorderTop
                      }
                      DB_LeftSidebar_MenuItem_SocialText_Hover_BorderStyleTop={
                        DB_LeftSidebar_MenuItem_SocialText_Hover_BorderStyleTop
                      }
                      DB_LeftSidebar_MenuItem_SocialText_Hover_BorderColorTop={
                        DB_LeftSidebar_MenuItem_SocialText_Hover_BorderColorTop
                      }
                      DB_LeftSidebar_MenuItem_SocialText_Hover_BorderBottom={
                        DB_LeftSidebar_MenuItem_SocialText_Hover_BorderBottom
                      }
                      DB_LeftSidebar_MenuItem_SocialText_Hover_BorderStyleBottom={
                        DB_LeftSidebar_MenuItem_SocialText_Hover_BorderStyleBottom
                      }
                      DB_LeftSidebar_MenuItem_SocialText_Hover_BorderColorBottom={
                        DB_LeftSidebar_MenuItem_SocialText_Hover_BorderColorBottom
                      }
                      DB_LeftSidebar_MenuItem_SocialText_Hover_BorderLeft={
                        DB_LeftSidebar_MenuItem_SocialText_Hover_BorderLeft
                      }
                      DB_LeftSidebar_MenuItem_SocialText_Hover_BorderStyleLeft={
                        DB_LeftSidebar_MenuItem_SocialText_Hover_BorderStyleLeft
                      }
                      DB_LeftSidebar_MenuItem_SocialText_Hover_BorderColorLeft={
                        DB_LeftSidebar_MenuItem_SocialText_Hover_BorderColorLeft
                      }
                      DB_LeftSidebar_MenuItem_SocialText_Hover_BorderRight={
                        DB_LeftSidebar_MenuItem_SocialText_Hover_BorderRight
                      }
                      DB_LeftSidebar_MenuItem_SocialText_Hover_BorderStyleRight={
                        DB_LeftSidebar_MenuItem_SocialText_Hover_BorderStyleRight
                      }
                      DB_LeftSidebar_MenuItem_SocialText_Hover_BorderColorRight={
                        DB_LeftSidebar_MenuItem_SocialText_Hover_BorderColorRight
                      }
                      DB_LeftSidebar_MenuItem_SocialText_Hover_BorderTopRightRadius={
                        DB_LeftSidebar_MenuItem_SocialText_Hover_BorderTopRightRadius
                      }
                      DB_LeftSidebar_MenuItem_SocialText_Hover_BorderTopLeftRadius={
                        DB_LeftSidebar_MenuItem_SocialText_Hover_BorderTopLeftRadius
                      }
                      DB_LeftSidebar_MenuItem_SocialText_Hover_BorderBottomRightRadius={
                        DB_LeftSidebar_MenuItem_SocialText_Hover_BorderBottomRightRadius
                      }
                      DB_LeftSidebar_MenuItem_SocialText_Hover_BorderBottomLeftRadius={
                        DB_LeftSidebar_MenuItem_SocialText_Hover_BorderBottomLeftRadius
                      }
                      DB_LeftSidebar_MenuItem_SocialText_marginLeftRight_Hover={
                        DB_LeftSidebar_MenuItem_SocialText_marginLeftRight_Hover
                      }
                      DB_LeftSidebar_MenuItem_SocialText_marginTopBottom_Hover={
                        DB_LeftSidebar_MenuItem_SocialText_marginTopBottom_Hover
                      }
                      DB_LeftSidebar_MenuItem_SocialText_paddingLeftRight_Hover={
                        DB_LeftSidebar_MenuItem_SocialText_paddingLeftRight_Hover
                      }
                      DB_LeftSidebar_MenuItem_SocialText_paddingTopBottom_Hover={
                        DB_LeftSidebar_MenuItem_SocialText_paddingTopBottom_Hover
                      }
                      DB_LeftSidebar_MenuItem_SocialText_letterSpacing_Hover={
                        DB_LeftSidebar_MenuItem_SocialText_letterSpacing_Hover
                      }
                      DB_LeftSidebar_MenuItem_SocialText_ShadowHorizontal_Hover={
                        DB_LeftSidebar_MenuItem_SocialText_ShadowHorizontal_Hover
                      }
                      DB_LeftSidebar_MenuItem_SocialText_ShadowVertical_Hover={
                        DB_LeftSidebar_MenuItem_SocialText_ShadowVertical_Hover
                      }
                      DB_LeftSidebar_MenuItem_SocialText_ShadowBlur_Hover={
                        DB_LeftSidebar_MenuItem_SocialText_ShadowBlur_Hover
                      }
                      DB_LeftSidebar_MenuItem_SocialText_ShadowColor_Hover={
                        DB_LeftSidebar_MenuItem_SocialText_ShadowColor_Hover
                      }
                      DB_LeftSidebar_MenuItem_SocialText_TextDecoration_Hover={
                        DB_LeftSidebar_MenuItem_SocialText_TextDecoration_Hover
                      }
                      DB_LeftSidebar_MenuItem_SocialText_TextDecorationStyle_Hover={
                        DB_LeftSidebar_MenuItem_SocialText_TextDecorationStyle_Hover
                      }
                    />

                    {/*  <SideBarRightCookieMessage SideBarLeft_ContainerSocial_Farbe_Groesse_Stil_OnMouseOverAndOut={
                          SideBarLeft_ContainerSocial_Farbe_Groesse_Stil_OnMouseOverAndOut
                        }
                        SideBarLeft_ContainerSocial_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut={
                          SideBarLeft_ContainerSocial_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
                        }
                        SideBarLeft_ContainerSocial_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut={
                          SideBarLeft_ContainerSocial_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut
                        }
                        SideBarLeft_ContainerSocial_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut={
                          SideBarLeft_ContainerSocial_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut
                        }
                        SideBarLeft_AlleSocial_Farbe_Groesse_Stil_OnMouseOverAndOut={
                          SideBarLeft_AlleSocial_Farbe_Groesse_Stil_OnMouseOverAndOut
                        }
                        SideBarLeft_AlleSocial_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut={
                          SideBarLeft_AlleSocial_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
                        }
                        SideBarLeft_AlleSocial_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut={
                          SideBarLeft_AlleSocial_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut
                        }
                        SideBarLeft_AlleSocial_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut={
                          SideBarLeft_AlleSocial_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut
                        }
                        SideBarLeft_SocialItem_Farbe_Groesse_Stil_OnMouseOverAndOut={
                          SideBarLeft_SocialItem_Farbe_Groesse_Stil_OnMouseOverAndOut
                        }
                        SideBarLeft_SocialItem_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut={
                          SideBarLeft_SocialItem_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
                        }
                        SideBarLeft_SocialItem_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut={
                          SideBarLeft_SocialItem_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut
                        }
                        SideBarLeft_SocialItem_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut={
                          SideBarLeft_SocialItem_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut
                        }
                        SideBarLeft_ContainerSocial_Farbe_Groesse_Stil_Trigger={
                          SideBarLeft_ContainerSocial_Farbe_Groesse_Stil_Trigger
                        }
                        SideBarLeft_ContainerSocial_Farbe_Groesse_Stil_Hover_Trigger={
                          SideBarLeft_ContainerSocial_Farbe_Groesse_Stil_Hover_Trigger
                        }
                        SideBarLeft_ContainerSocial_HintergrundFarbe_Radius_Stil_Trigger={
                          SideBarLeft_ContainerSocial_HintergrundFarbe_Radius_Stil_Trigger
                        }
                        SideBarLeft_ContainerSocial_HintergrundFarbe_Radius_Stil_Hover_Trigger={
                          SideBarLeft_ContainerSocial_HintergrundFarbe_Radius_Stil_Hover_Trigger
                        }
                        SideBarLeft_AlleSocial_Farbe_Groesse_Stil_Trigger={
                          SideBarLeft_AlleSocial_Farbe_Groesse_Stil_Trigger
                        }
                        SideBarLeft_AlleSocial_Farbe_Groesse_Stil_Hover_Trigger={
                          SideBarLeft_AlleSocial_Farbe_Groesse_Stil_Hover_Trigger
                        }
                        SideBarLeft_AlleSocial_HintergrundFarbe_Radius_Stil_Trigger={
                          SideBarLeft_AlleSocial_HintergrundFarbe_Radius_Stil_Trigger
                        }
                        SideBarLeft_AlleSocial_HintergrundFarbe_Radius_Stil_Hover_Trigger={
                          SideBarLeft_AlleSocial_HintergrundFarbe_Radius_Stil_Hover_Trigger
                        }
                        SideBarLeft_SocialItem_Farbe_Groesse_Stil_Trigger={
                          SideBarLeft_SocialItem_Farbe_Groesse_Stil_Trigger
                        }
                        SideBarLeft_SocialItem_Farbe_Groesse_Stil_Hover_Trigger={
                          SideBarLeft_SocialItem_Farbe_Groesse_Stil_Hover_Trigger
                        }
                        SideBarLeft_SocialItem_HintergrundFarbe_Radius_Stil_Trigger={
                          SideBarLeft_SocialItem_HintergrundFarbe_Radius_Stil_Trigger
                        }
                        SideBarLeft_SocialItem_HintergrundFarbe_Radius_Stil_Hover_Trigger={
                          SideBarLeft_SocialItem_HintergrundFarbe_Radius_Stil_Hover_Trigger
                        }
                        DB_fontFace_FontFamilyName={DB_fontFace_FontFamilyName}
                        DB_LeftSidebar_MenuContainer_Social_DDBackgroundColor={
                          DB_LeftSidebar_MenuContainer_Social_DDBackgroundColor
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDBorderTop={
                          DB_LeftSidebar_MenuContainer_Social_DDBorderTop
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDBorderStyleTop={
                          DB_LeftSidebar_MenuContainer_Social_DDBorderStyleTop
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDBorderColorTop={
                          DB_LeftSidebar_MenuContainer_Social_DDBorderColorTop
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDBorderBottom={
                          DB_LeftSidebar_MenuContainer_Social_DDBorderBottom
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDBorderStyleBottom={
                          DB_LeftSidebar_MenuContainer_Social_DDBorderStyleBottom
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDBorderColorBottom={
                          DB_LeftSidebar_MenuContainer_Social_DDBorderColorBottom
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDBorderLeft={
                          DB_LeftSidebar_MenuContainer_Social_DDBorderLeft
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDBorderStyleLeft={
                          DB_LeftSidebar_MenuContainer_Social_DDBorderStyleLeft
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDBorderColorLeft={
                          DB_LeftSidebar_MenuContainer_Social_DDBorderColorLeft
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDBorderRight={
                          DB_LeftSidebar_MenuContainer_Social_DDBorderRight
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDBorderStyleRight={
                          DB_LeftSidebar_MenuContainer_Social_DDBorderStyleRight
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDBorderColorRight={
                          DB_LeftSidebar_MenuContainer_Social_DDBorderColorRight
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDBorderTopRightRadius={
                          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDBorderTopRightRadius
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDBorderTopLeftRadius={
                          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDBorderTopLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDBorderBottomRightRadius={
                          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDBorderBottomRightRadius
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDBorderBottomLeftRadius={
                          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDBorderBottomLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDpaddingLeft={
                          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDpaddingLeft
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDpaddingRight={
                          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDpaddingRight
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDpaddingTop={
                          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDpaddingTop
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDpaddingBottom={
                          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDpaddingBottom
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDShadowHorizontal={
                          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDShadowHorizontal
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDShadowVertical={
                          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDShadowVertical
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDShadowBlur={
                          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDShadowBlur
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDShadowColor={
                          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDShadowColor
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDHover_BackgroundColor={
                          DB_LeftSidebar_MenuContainer_Social_DDHover_BackgroundColor
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDHover_BorderTop={
                          DB_LeftSidebar_MenuContainer_Social_DDHover_BorderTop
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDHover_BorderStyleTop={
                          DB_LeftSidebar_MenuContainer_Social_DDHover_BorderStyleTop
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDHover_BorderColorTop={
                          DB_LeftSidebar_MenuContainer_Social_DDHover_BorderColorTop
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDHover_BorderBottom={
                          DB_LeftSidebar_MenuContainer_Social_DDHover_BorderBottom
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDHover_BorderStyleBottom={
                          DB_LeftSidebar_MenuContainer_Social_DDHover_BorderStyleBottom
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDHover_BorderColorBottom={
                          DB_LeftSidebar_MenuContainer_Social_DDHover_BorderColorBottom
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDHover_BorderLeft={
                          DB_LeftSidebar_MenuContainer_Social_DDHover_BorderLeft
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDHover_BorderStyleLeft={
                          DB_LeftSidebar_MenuContainer_Social_DDHover_BorderStyleLeft
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDHover_BorderColorLeft={
                          DB_LeftSidebar_MenuContainer_Social_DDHover_BorderColorLeft
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDHover_BorderRight={
                          DB_LeftSidebar_MenuContainer_Social_DDHover_BorderRight
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDHover_BorderStyleRight={
                          DB_LeftSidebar_MenuContainer_Social_DDHover_BorderStyleRight
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDHover_BorderColorRight={
                          DB_LeftSidebar_MenuContainer_Social_DDHover_BorderColorRight
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_BorderTopRightRadius={
                          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_BorderTopRightRadius
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_BorderTopLeftRadius={
                          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_BorderTopLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_BorderBottomRightRadius={
                          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_BorderBottomRightRadius
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_BorderBottomLeftRadius={
                          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_BorderBottomLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_paddingLeft={
                          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_paddingLeft
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_paddingRight={
                          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_paddingRight
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_paddingTop={
                          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_paddingTop
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_paddingBottom={
                          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_paddingBottom
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_ShadowHorizontal={
                          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_ShadowHorizontal
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_ShadowVertical={
                          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_ShadowVertical
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_ShadowBlur={
                          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_ShadowBlur
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_ShadowColor={
                          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_ShadowColor
                        }
                        DB_fontFace_SocialTitelFontFamily_FontFamilyName={
                          DB_fontFace_SocialTitelFontFamily_FontFamilyName
                        }
                        DB_fontFace_SocialTitelFontFamily_FontFamilySrc={
                          DB_fontFace_SocialTitelFontFamily_FontFamilySrc
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_FontWeight={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_FontWeight
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_FontColor={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_FontColor
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_FontSize={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_FontSize
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BackgroundColor={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BackgroundColor
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_TextAlign={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_TextAlign
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderTop={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderTop
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderStyleTop={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderStyleTop
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderColorTop={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderColorTop
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderBottom={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderBottom
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderStyleBottom={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderStyleBottom
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderColorBottom={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderColorBottom
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderLeft={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderLeft
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderStyleLeft={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderStyleLeft
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderColorLeft={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderColorLeft
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderRight={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderRight
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderStyleRight={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderStyleRight
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderColorRight={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderColorRight
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderTopRightRadius={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderTopRightRadius
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderTopLeftRadius={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderTopLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderBottomRightRadius={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderBottomRightRadius
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderBottomLeftRadius={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderBottomLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_marginLeftRight={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_marginLeftRight
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_marginTopBottom={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_marginTopBottom
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_paddingLeftRight={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_paddingLeftRight
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_paddingTopBottom={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_paddingTopBottom
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_letterSpacing={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_letterSpacing
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowHorizontal={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowHorizontal
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowVertical={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowVertical
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowBlur={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowBlur
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowColor={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowColor
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_TextDecoration={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_TextDecoration
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_TextDecorationStyle={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_TextDecorationStyle
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_FontWeight={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_FontWeight
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_FontColor={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_FontColor
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_FontSize={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_FontSize
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BackgroundColor={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BackgroundColor
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_TextAlign={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_TextAlign
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderTop={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderTop
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderStyleTop={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderStyleTop
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderColorTop={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderColorTop
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderBottom={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderBottom
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderStyleBottom={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderStyleBottom
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderColorBottom={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderColorBottom
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderLeft={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderLeft
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderStyleLeft={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderStyleLeft
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderColorLeft={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderColorLeft
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderRight={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderRight
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderStyleRight={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderStyleRight
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderColorRight={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderColorRight
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderTopRightRadius={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderTopRightRadius
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderTopLeftRadius={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderTopLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderBottomRightRadius={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderBottomRightRadius
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderBottomLeftRadius={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderBottomLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_marginLeftRight_Hover={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_marginLeftRight_Hover
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_marginTopBottom_Hover={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_marginTopBottom_Hover
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_paddingLeftRight_Hover={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_paddingLeftRight_Hover
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_paddingTopBottom_Hover={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_paddingTopBottom_Hover
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_letterSpacing_Hover={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_letterSpacing_Hover
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowHorizontal_Hover={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowHorizontal_Hover
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowVertical_Hover={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowVertical_Hover
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowBlur_Hover={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowBlur_Hover
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowColor_Hover={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowColor_Hover
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_TextDecoration_Hover={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_TextDecoration_Hover
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_TextDecorationStyle_Hover={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_TextDecorationStyle_Hover
                        }
                        DB_fontFace_SocialTextFontFamily_FontFamilyName={
                          DB_fontFace_SocialTextFontFamily_FontFamilyName
                        }
                        DB_fontFace_SocialTextFontFamily_FontFamilySrc={
                          DB_fontFace_SocialTextFontFamily_FontFamilySrc
                        }
                        DB_LeftSidebar_MenuItem_SocialText_FontWeight={
                          DB_LeftSidebar_MenuItem_SocialText_FontWeight
                        }
                        DB_LeftSidebar_MenuItem_SocialText_FontColor={
                          DB_LeftSidebar_MenuItem_SocialText_FontColor
                        }
                        DB_LeftSidebar_MenuItem_SocialText_FontSize={
                          DB_LeftSidebar_MenuItem_SocialText_FontSize
                        }
                        DB_LeftSidebar_MenuItem_SocialText_BackgroundColor={
                          DB_LeftSidebar_MenuItem_SocialText_BackgroundColor
                        }
                        DB_LeftSidebar_MenuItem_SocialText_BorderTop={
                          DB_LeftSidebar_MenuItem_SocialText_BorderTop
                        }
                        DB_LeftSidebar_MenuItem_SocialText_BorderStyleTop={
                          DB_LeftSidebar_MenuItem_SocialText_BorderStyleTop
                        }
                        DB_LeftSidebar_MenuItem_SocialText_BorderColorTop={
                          DB_LeftSidebar_MenuItem_SocialText_BorderColorTop
                        }
                        DB_LeftSidebar_MenuItem_SocialText_BorderBottom={
                          DB_LeftSidebar_MenuItem_SocialText_BorderBottom
                        }
                        DB_LeftSidebar_MenuItem_SocialText_BorderStyleBottom={
                          DB_LeftSidebar_MenuItem_SocialText_BorderStyleBottom
                        }
                        DB_LeftSidebar_MenuItem_SocialText_BorderColorBottom={
                          DB_LeftSidebar_MenuItem_SocialText_BorderColorBottom
                        }
                        DB_LeftSidebar_MenuItem_SocialText_BorderLeft={
                          DB_LeftSidebar_MenuItem_SocialText_BorderLeft
                        }
                        DB_LeftSidebar_MenuItem_SocialText_BorderStyleLeft={
                          DB_LeftSidebar_MenuItem_SocialText_BorderStyleLeft
                        }
                        DB_LeftSidebar_MenuItem_SocialText_BorderColorLeft={
                          DB_LeftSidebar_MenuItem_SocialText_BorderColorLeft
                        }
                        DB_LeftSidebar_MenuItem_SocialText_BorderRight={
                          DB_LeftSidebar_MenuItem_SocialText_BorderRight
                        }
                        DB_LeftSidebar_MenuItem_SocialText_BorderStyleRight={
                          DB_LeftSidebar_MenuItem_SocialText_BorderStyleRight
                        }
                        DB_LeftSidebar_MenuItem_SocialText_BorderColorRight={
                          DB_LeftSidebar_MenuItem_SocialText_BorderColorRight
                        }
                        DB_LeftSidebar_MenuItem_SocialText_BorderTopRightRadius={
                          DB_LeftSidebar_MenuItem_SocialText_BorderTopRightRadius
                        }
                        DB_LeftSidebar_MenuItem_SocialText_BorderTopLeftRadius={
                          DB_LeftSidebar_MenuItem_SocialText_BorderTopLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_SocialText_BorderBottomRightRadius={
                          DB_LeftSidebar_MenuItem_SocialText_BorderBottomRightRadius
                        }
                        DB_LeftSidebar_MenuItem_SocialText_BorderBottomLeftRadius={
                          DB_LeftSidebar_MenuItem_SocialText_BorderBottomLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_SocialText_marginLeftRight={
                          DB_LeftSidebar_MenuItem_SocialText_marginLeftRight
                        }
                        DB_LeftSidebar_MenuItem_SocialText_marginTopBottom={
                          DB_LeftSidebar_MenuItem_SocialText_marginTopBottom
                        }
                        DB_LeftSidebar_MenuItem_SocialText_paddingLeftRight={
                          DB_LeftSidebar_MenuItem_SocialText_paddingLeftRight
                        }
                        DB_LeftSidebar_MenuItem_SocialText_paddingTopBottom={
                          DB_LeftSidebar_MenuItem_SocialText_paddingTopBottom
                        }
                        DB_LeftSidebar_MenuItem_SocialText_letterSpacing={
                          DB_LeftSidebar_MenuItem_SocialText_letterSpacing
                        }
                        DB_LeftSidebar_MenuItem_SocialText_ShadowHorizontal={
                          DB_LeftSidebar_MenuItem_SocialText_ShadowHorizontal
                        }
                        DB_LeftSidebar_MenuItem_SocialText_ShadowVertical={
                          DB_LeftSidebar_MenuItem_SocialText_ShadowVertical
                        }
                        DB_LeftSidebar_MenuItem_SocialText_ShadowBlur={
                          DB_LeftSidebar_MenuItem_SocialText_ShadowBlur
                        }
                        DB_LeftSidebar_MenuItem_SocialText_ShadowColor={
                          DB_LeftSidebar_MenuItem_SocialText_ShadowColor
                        }
                        DB_LeftSidebar_MenuItem_SocialText_TextAlign={
                          DB_LeftSidebar_MenuItem_SocialText_TextAlign
                        }
                        DB_LeftSidebar_MenuItem_SocialText_TextDecoration={
                          DB_LeftSidebar_MenuItem_SocialText_TextDecoration
                        }
                        DB_LeftSidebar_MenuItem_SocialText_TextDecorationStyle={
                          DB_LeftSidebar_MenuItem_SocialText_TextDecorationStyle
                        }
                        DB_LeftSidebar_MenuItem_SocialText_Hover_FontWeight={
                          DB_LeftSidebar_MenuItem_SocialText_Hover_FontWeight
                        }
                        DB_LeftSidebar_MenuItem_SocialText_Hover_FontColor={
                          DB_LeftSidebar_MenuItem_SocialText_Hover_FontColor
                        }
                        DB_LeftSidebar_MenuItem_SocialText_Hover_FontSize={
                          DB_LeftSidebar_MenuItem_SocialText_Hover_FontSize
                        }
                        DB_LeftSidebar_MenuItem_SocialText_Hover_BackgroundColor={
                          DB_LeftSidebar_MenuItem_SocialText_Hover_BackgroundColor
                        }
                        DB_LeftSidebar_MenuItem_SocialText_Hover_TextAlign={
                          DB_LeftSidebar_MenuItem_SocialText_Hover_TextAlign
                        }
                        DB_LeftSidebar_MenuItem_SocialText_Hover_BorderTop={
                          DB_LeftSidebar_MenuItem_SocialText_Hover_BorderTop
                        }
                        DB_LeftSidebar_MenuItem_SocialText_Hover_BorderStyleTop={
                          DB_LeftSidebar_MenuItem_SocialText_Hover_BorderStyleTop
                        }
                        DB_LeftSidebar_MenuItem_SocialText_Hover_BorderColorTop={
                          DB_LeftSidebar_MenuItem_SocialText_Hover_BorderColorTop
                        }
                        DB_LeftSidebar_MenuItem_SocialText_Hover_BorderBottom={
                          DB_LeftSidebar_MenuItem_SocialText_Hover_BorderBottom
                        }
                        DB_LeftSidebar_MenuItem_SocialText_Hover_BorderStyleBottom={
                          DB_LeftSidebar_MenuItem_SocialText_Hover_BorderStyleBottom
                        }
                        DB_LeftSidebar_MenuItem_SocialText_Hover_BorderColorBottom={
                          DB_LeftSidebar_MenuItem_SocialText_Hover_BorderColorBottom
                        }
                        DB_LeftSidebar_MenuItem_SocialText_Hover_BorderLeft={
                          DB_LeftSidebar_MenuItem_SocialText_Hover_BorderLeft
                        }
                        DB_LeftSidebar_MenuItem_SocialText_Hover_BorderStyleLeft={
                          DB_LeftSidebar_MenuItem_SocialText_Hover_BorderStyleLeft
                        }
                        DB_LeftSidebar_MenuItem_SocialText_Hover_BorderColorLeft={
                          DB_LeftSidebar_MenuItem_SocialText_Hover_BorderColorLeft
                        }
                        DB_LeftSidebar_MenuItem_SocialText_Hover_BorderRight={
                          DB_LeftSidebar_MenuItem_SocialText_Hover_BorderRight
                        }
                        DB_LeftSidebar_MenuItem_SocialText_Hover_BorderStyleRight={
                          DB_LeftSidebar_MenuItem_SocialText_Hover_BorderStyleRight
                        }
                        DB_LeftSidebar_MenuItem_SocialText_Hover_BorderColorRight={
                          DB_LeftSidebar_MenuItem_SocialText_Hover_BorderColorRight
                        }
                        DB_LeftSidebar_MenuItem_SocialText_Hover_BorderTopRightRadius={
                          DB_LeftSidebar_MenuItem_SocialText_Hover_BorderTopRightRadius
                        }
                        DB_LeftSidebar_MenuItem_SocialText_Hover_BorderTopLeftRadius={
                          DB_LeftSidebar_MenuItem_SocialText_Hover_BorderTopLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_SocialText_Hover_BorderBottomRightRadius={
                          DB_LeftSidebar_MenuItem_SocialText_Hover_BorderBottomRightRadius
                        }
                        DB_LeftSidebar_MenuItem_SocialText_Hover_BorderBottomLeftRadius={
                          DB_LeftSidebar_MenuItem_SocialText_Hover_BorderBottomLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_SocialText_marginLeftRight_Hover={
                          DB_LeftSidebar_MenuItem_SocialText_marginLeftRight_Hover
                        }
                        DB_LeftSidebar_MenuItem_SocialText_marginTopBottom_Hover={
                          DB_LeftSidebar_MenuItem_SocialText_marginTopBottom_Hover
                        }
                        DB_LeftSidebar_MenuItem_SocialText_paddingLeftRight_Hover={
                          DB_LeftSidebar_MenuItem_SocialText_paddingLeftRight_Hover
                        }
                        DB_LeftSidebar_MenuItem_SocialText_paddingTopBottom_Hover={
                          DB_LeftSidebar_MenuItem_SocialText_paddingTopBottom_Hover
                        }
                        DB_LeftSidebar_MenuItem_SocialText_letterSpacing_Hover={
                          DB_LeftSidebar_MenuItem_SocialText_letterSpacing_Hover
                        }
                        DB_LeftSidebar_MenuItem_SocialText_ShadowHorizontal_Hover={
                          DB_LeftSidebar_MenuItem_SocialText_ShadowHorizontal_Hover
                        }
                        DB_LeftSidebar_MenuItem_SocialText_ShadowVertical_Hover={
                          DB_LeftSidebar_MenuItem_SocialText_ShadowVertical_Hover
                        }
                        DB_LeftSidebar_MenuItem_SocialText_ShadowBlur_Hover={
                          DB_LeftSidebar_MenuItem_SocialText_ShadowBlur_Hover
                        }
                        DB_LeftSidebar_MenuItem_SocialText_ShadowColor_Hover={
                          DB_LeftSidebar_MenuItem_SocialText_ShadowColor_Hover
                        }
                        DB_LeftSidebar_MenuItem_SocialText_TextDecoration_Hover={
                          DB_LeftSidebar_MenuItem_SocialText_TextDecoration_Hover
                        }
                        DB_LeftSidebar_MenuItem_SocialText_TextDecorationStyle_Hover={
                          DB_LeftSidebar_MenuItem_SocialText_TextDecorationStyle_Hover
                        } /> */}
                  </>
                )}
              </Col>
            )}

            <Col
              lg={
                (leftCategoriesVisible ||
                  specialLeftFiltersVisible ||
                  ProductFilterIsVisibleLeft ||
                  specialLeftSocialsVisible ||
                  TopBarIsVisibleLeft ||
                  leftMainCategoriesVisible) &&
                (specialRightCategoriesVisible ||
                  rightFiltersVisible ||
                  ProductFilterIsVisibleRight ||
                  rightSocialsVisible ||
                  TopBarIsVisibleRight ||
                  rightMainCategoriesVisible)
                  ? 8
                  : (leftCategoriesVisible ||
                      specialLeftFiltersVisible ||
                      ProductFilterIsVisibleLeft ||
                      specialLeftSocialsVisible ||
                      TopBarIsVisibleLeft ||
                      leftMainCategoriesVisible) &&
                    !specialRightCategoriesVisible &&
                    !rightFiltersVisible &&
                    !ProductFilterIsVisibleRight &&
                    !rightSocialsVisible &&
                    !TopBarIsVisibleRight &&
                    !rightMainCategoriesVisible
                  ? 10
                  : !leftCategoriesVisible &&
                    !specialLeftFiltersVisible &&
                    !ProductFilterIsVisibleLeft &&
                    !specialLeftSocialsVisible &&
                    !TopBarIsVisibleLeft &&
                    !leftMainCategoriesVisible &&
                    (specialRightCategoriesVisible ||
                      rightFiltersVisible ||
                      ProductFilterIsVisibleRight ||
                      rightSocialsVisible ||
                      TopBarIsVisibleRight ||
                      rightMainCategoriesVisible)
                  ? 10
                  : 12
              }
              className={`
              ${
                DB_OrderOfBodyElements === "012"
                  ? "order-lg-1 order-lg-1 col-12 pb-4"
                  : DB_OrderOfBodyElements === "021"
                  ? "order-lg-2 order-lg-1 col-12 pb-4"
                  : DB_OrderOfBodyElements === "102"
                  ? "order-lg-0  col-12 pb-4"
                  : DB_OrderOfBodyElements === "120"
                  ? "order-lg-0  col-12 pb-4"
                  : DB_OrderOfBodyElements === "201"
                  ? "order-lg-2  col-12 pb-4"
                  : DB_OrderOfBodyElements === "210"
                  ? "order-lg-1  col-12 pb-4"
                  : "order-lg-1  col-12 pb-4"
              }`}
            >
              <div className="container-fluid">
                {ProductFilterIsVisibleTop && !isMobile && (
                  <ProductFilterTopBar
                    paginationStart={paginationStart}
                    paginationEnd={paginationEnd}
                    allProductsStatePagination={allProductsStatePagination}
                    productBefore={productBefore}
                    productNext={productNext}
                    columnNumber={columnNumber}
                    pageAnzahlCounter={pageAnzahlCounter}
                    DB_fontFace_FontFamilyName={DB_fontFace_FontFamilyName}
                    columnNumberChange={columnNumberChange}
                    changePreisAlphabeticUswHandler={
                      changePreisAlphabeticUswHandler
                    }
                    SideBarLeft_SortierungTop_Farbe_Groesse_Stil_OnMouseOverAndOut={
                      SideBarLeft_SortierungTop_Farbe_Groesse_Stil_OnMouseOverAndOut
                    }
                    SideBarLeft_SortierungTop_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut={
                      SideBarLeft_SortierungTop_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
                    }
                    SideBarLeft_SortierungTop_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut={
                      SideBarLeft_SortierungTop_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut
                    }
                    SideBarLeft_SortierungTop_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut={
                      SideBarLeft_SortierungTop_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut
                    }
                    DB_fontFace_FilterTopBar_FontFamilyName={
                      DB_fontFace_FilterTopBar_FontFamilyName
                    }
                    DB_fontFace_FilterTopBar_FontFamilySrc={
                      DB_fontFace_FilterTopBar_FontFamilySrc
                    }
                    DB_LeftSidebar_MenuItem_SortierungTop_BackgroundColor={
                      DB_LeftSidebar_MenuItem_SortierungTop_BackgroundColor
                    }
                    DB_LeftSidebar_MenuItem_SortierungTop_FontColor={
                      DB_LeftSidebar_MenuItem_SortierungTop_FontColor
                    }
                    DB_LeftSidebar_MenuItem_SortierungTop_BorderTop={
                      DB_LeftSidebar_MenuItem_SortierungTop_BorderTop
                    }
                    DB_LeftSidebar_MenuItem_SortierungTop_BorderStyleTop={
                      DB_LeftSidebar_MenuItem_SortierungTop_BorderStyleTop
                    }
                    DB_LeftSidebar_MenuItem_SortierungTop_BorderColorTop={
                      DB_LeftSidebar_MenuItem_SortierungTop_BorderColorTop
                    }
                    DB_LeftSidebar_MenuItem_SortierungTop_BorderBottom={
                      DB_LeftSidebar_MenuItem_SortierungTop_BorderBottom
                    }
                    DB_LeftSidebar_MenuItem_SortierungTop_BorderStyleBottom={
                      DB_LeftSidebar_MenuItem_SortierungTop_BorderStyleBottom
                    }
                    DB_LeftSidebar_MenuItem_SortierungTop_BorderColorBottom={
                      DB_LeftSidebar_MenuItem_SortierungTop_BorderColorBottom
                    }
                    DB_LeftSidebar_MenuItem_SortierungTop_BorderLeft={
                      DB_LeftSidebar_MenuItem_SortierungTop_BorderLeft
                    }
                    DB_LeftSidebar_MenuItem_SortierungTop_BorderStyleLeft={
                      DB_LeftSidebar_MenuItem_SortierungTop_BorderStyleLeft
                    }
                    DB_LeftSidebar_MenuItem_SortierungTop_BorderColorLeft={
                      DB_LeftSidebar_MenuItem_SortierungTop_BorderColorLeft
                    }
                    DB_LeftSidebar_MenuItem_SortierungTop_BorderRight={
                      DB_LeftSidebar_MenuItem_SortierungTop_BorderRight
                    }
                    DB_LeftSidebar_MenuItem_SortierungTop_BorderStyleRight={
                      DB_LeftSidebar_MenuItem_SortierungTop_BorderStyleRight
                    }
                    DB_LeftSidebar_MenuItem_SortierungTop_BorderColorRight={
                      DB_LeftSidebar_MenuItem_SortierungTop_BorderColorRight
                    }
                    DB_LeftSidebar_MenuItem_SortierungTop_BorderTopRightRadius={
                      DB_LeftSidebar_MenuItem_SortierungTop_BorderTopRightRadius
                    }
                    DB_LeftSidebar_MenuItem_SortierungTop_BorderTopLeftRadius={
                      DB_LeftSidebar_MenuItem_SortierungTop_BorderTopLeftRadius
                    }
                    DB_LeftSidebar_MenuItem_SortierungTop_BorderBottomRightRadius={
                      DB_LeftSidebar_MenuItem_SortierungTop_BorderBottomRightRadius
                    }
                    DB_LeftSidebar_MenuItem_SortierungTop_BorderBottomLeftRadius={
                      DB_LeftSidebar_MenuItem_SortierungTop_BorderBottomLeftRadius
                    }
                    DB_LeftSidebar_MenuItem_SortierungTop_ShadowHorizontal={
                      DB_LeftSidebar_MenuItem_SortierungTop_ShadowHorizontal
                    }
                    DB_LeftSidebar_MenuItem_SortierungTop_ShadowVertical={
                      DB_LeftSidebar_MenuItem_SortierungTop_ShadowVertical
                    }
                    DB_LeftSidebar_MenuItem_SortierungTop_ShadowBlur={
                      DB_LeftSidebar_MenuItem_SortierungTop_ShadowBlur
                    }
                    DB_LeftSidebar_MenuItem_SortierungTop_ShadowColor={
                      DB_LeftSidebar_MenuItem_SortierungTop_ShadowColor
                    }
                    DB_LeftSidebar_MenuItem_SortierungTop_Hover_BorderTop={
                      DB_LeftSidebar_MenuItem_SortierungTop_Hover_BorderTop
                    }
                    DB_LeftSidebar_MenuItem_SortierungTop_Hover_BorderStyleTop={
                      DB_LeftSidebar_MenuItem_SortierungTop_Hover_BorderStyleTop
                    }
                    DB_LeftSidebar_MenuItem_SortierungTop_Hover_BorderColorTop={
                      DB_LeftSidebar_MenuItem_SortierungTop_Hover_BorderColorTop
                    }
                    DB_LeftSidebar_MenuItem_SortierungTop_Hover_BorderBottom={
                      DB_LeftSidebar_MenuItem_SortierungTop_Hover_BorderBottom
                    }
                    DB_LeftSidebar_MenuItem_SortierungTop_Hover_BorderStyleBottom={
                      DB_LeftSidebar_MenuItem_SortierungTop_Hover_BorderStyleBottom
                    }
                    DB_LeftSidebar_MenuItem_SortierungTop_Hover_BorderColorBottom={
                      DB_LeftSidebar_MenuItem_SortierungTop_Hover_BorderColorBottom
                    }
                    DB_LeftSidebar_MenuItem_SortierungTop_Hover_BorderLeft={
                      DB_LeftSidebar_MenuItem_SortierungTop_Hover_BorderLeft
                    }
                    DB_LeftSidebar_MenuItem_SortierungTop_Hover_BorderStyleLeft={
                      DB_LeftSidebar_MenuItem_SortierungTop_Hover_BorderStyleLeft
                    }
                    DB_LeftSidebar_MenuItem_SortierungTop_Hover_BorderColorLeft={
                      DB_LeftSidebar_MenuItem_SortierungTop_Hover_BorderColorLeft
                    }
                    DB_LeftSidebar_MenuItem_SortierungTop_Hover_BorderRight={
                      DB_LeftSidebar_MenuItem_SortierungTop_Hover_BorderRight
                    }
                    DB_LeftSidebar_MenuItem_SortierungTop_Hover_BorderStyleRight={
                      DB_LeftSidebar_MenuItem_SortierungTop_Hover_BorderStyleRight
                    }
                    DB_LeftSidebar_MenuItem_SortierungTop_Hover_BorderColorRight={
                      DB_LeftSidebar_MenuItem_SortierungTop_Hover_BorderColorRight
                    }
                    DB_LeftSidebar_MenuItem_SortierungTop_Hover_BorderTopRightRadius={
                      DB_LeftSidebar_MenuItem_SortierungTop_Hover_BorderTopRightRadius
                    }
                    DB_LeftSidebar_MenuItem_SortierungTop_Hover_BorderTopLeftRadius={
                      DB_LeftSidebar_MenuItem_SortierungTop_Hover_BorderTopLeftRadius
                    }
                    DB_LeftSidebar_MenuItem_SortierungTop_Hover_BorderBottomRightRadius={
                      DB_LeftSidebar_MenuItem_SortierungTop_Hover_BorderBottomRightRadius
                    }
                    DB_LeftSidebar_MenuItem_SortierungTop_Hover_BorderBottomLeftRadius={
                      DB_LeftSidebar_MenuItem_SortierungTop_Hover_BorderBottomLeftRadius
                    }
                    DB_LeftSidebar_MenuItem_SortierungTop_Hover_ShadowHorizontal={
                      DB_LeftSidebar_MenuItem_SortierungTop_Hover_ShadowHorizontal
                    }
                    DB_LeftSidebar_MenuItem_SortierungTop_Hover_ShadowVertical={
                      DB_LeftSidebar_MenuItem_SortierungTop_Hover_ShadowVertical
                    }
                    DB_LeftSidebar_MenuItem_SortierungTop_Hover_ShadowBlur={
                      DB_LeftSidebar_MenuItem_SortierungTop_Hover_ShadowBlur
                    }
                    DB_LeftSidebar_MenuItem_SortierungTop_Hover_ShadowColor={
                      DB_LeftSidebar_MenuItem_SortierungTop_Hover_ShadowColor
                    }
                    DB_LeftSidebar_MenuItem_SortierungTop_Hover_FontColor={
                      DB_LeftSidebar_MenuItem_SortierungTop_Hover_FontColor
                    }
                    DB_LeftSidebar_MenuItem_SortierungTop_Hover_BackgroundColor={
                      DB_LeftSidebar_MenuItem_SortierungTop_Hover_BackgroundColor
                    }
                  />
                )}

                <Row>
                  {!productDetailShow && !aboutUsShow && !faqShow ? (
                    <>
                      <BodyAllProductCards
                        key={Math.random()}
                        allProductsState={allProductsState}
                        categoryLeftSideHandler={categoryLeftSideHandler}
                        showProductDetailsHandler={showProductDetailsHandler}
                        columnNumber={columnNumber}
                        DB_Waehrung_Global={DB_Waehrung_Global}
                        DB_fontFace_chooseFontBreadcrumbs_FontFamilyName={
                          DB_fontFace_chooseFontBreadcrumbs_FontFamilyName
                        }
                        DB_fontFace_chooseFontBreadcrumbs_FontFamilySrc={
                          DB_fontFace_chooseFontBreadcrumbs_FontFamilySrc
                        }
                        DB_fontFace_chooseFontWaehrung_FontFamilyName={
                          DB_fontFace_chooseFontWaehrung_FontFamilyName
                        }
                        DB_fontFace_chooseFontWaehrung_FontFamilySrc={
                          DB_fontFace_chooseFontWaehrung_FontFamilySrc
                        }
                        DB_fontFace_chooseFontWaehrungEuro_FontFamilyName={
                          DB_fontFace_chooseFontWaehrungEuro_FontFamilyName
                        }
                        DB_fontFace_chooseFontWaehrungEuro_FontFamilySrc={
                          DB_fontFace_chooseFontWaehrungEuro_FontFamilySrc
                        }
                        DB_fontFace_FontFamilyName={DB_fontFace_FontFamilyName}
                        DB_fontFace_chooseFontFamilyProduct_FontFamilyName={
                          DB_fontFace_chooseFontFamilyProduct_FontFamilyName
                        }
                        SideBarLeft_HauptTitel_Farbe_Groesse_Stil_OnMouseOverAndOut={
                          SideBarLeft_HauptTitel_Farbe_Groesse_Stil_OnMouseOverAndOut
                        }
                        SideBarLeft_HauptTitel_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut={
                          SideBarLeft_HauptTitel_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
                        }
                        SideBarLeft_HauptTitel_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut={
                          SideBarLeft_HauptTitel_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut
                        }
                        SideBarLeft_HauptTitel_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut={
                          SideBarLeft_HauptTitel_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut
                        }
                        DB_LeftSidebar_MenuItem_HauptTitel_FontFace={
                          DB_LeftSidebar_MenuItem_HauptTitel_FontFace
                        }
                        DB_LeftSidebar_MenuItem_HauptTitel_FontWeight={
                          DB_LeftSidebar_MenuItem_HauptTitel_FontWeight
                        }
                        DB_LeftSidebar_MenuItem_HauptTitel_Hover_FontWeight={
                          DB_LeftSidebar_MenuItem_HauptTitel_Hover_FontWeight
                        }
                        DB_LeftSidebar_MenuItem_HauptTitel_FontColor={
                          DB_LeftSidebar_MenuItem_HauptTitel_FontColor
                        }
                        DB_LeftSidebar_MenuItem_HauptTitel_Hover_FontColor={
                          DB_LeftSidebar_MenuItem_HauptTitel_Hover_FontColor
                        }
                        DB_LeftSidebar_MenuItem_HauptTitel_FontSize={
                          DB_LeftSidebar_MenuItem_HauptTitel_FontSize
                        }
                        DB_LeftSidebar_MenuItem_HauptTitel_Hover_FontSize={
                          DB_LeftSidebar_MenuItem_HauptTitel_Hover_FontSize
                        }
                        DB_LeftSidebar_MenuItem_HauptTitel_BackgroundColor={
                          DB_LeftSidebar_MenuItem_HauptTitel_BackgroundColor
                        }
                        DB_LeftSidebar_MenuItem_HauptTitel_Hover_BackgroundColor={
                          DB_LeftSidebar_MenuItem_HauptTitel_Hover_BackgroundColor
                        }
                        DB_LeftSidebar_MenuItem_HauptTitel_TextAlign={
                          DB_LeftSidebar_MenuItem_HauptTitel_TextAlign
                        }
                        DB_LeftSidebar_MenuItem_HauptTitel_Hover_TextAlign={
                          DB_LeftSidebar_MenuItem_HauptTitel_Hover_TextAlign
                        }
                        DB_LeftSidebar_MenuItem_HauptTitel_BorderTop={
                          DB_LeftSidebar_MenuItem_HauptTitel_BorderTop
                        }
                        DB_LeftSidebar_MenuItem_HauptTitel_Hover_BorderTop={
                          DB_LeftSidebar_MenuItem_HauptTitel_Hover_BorderTop
                        }
                        DB_LeftSidebar_MenuItem_HauptTitel_BorderStyleTop={
                          DB_LeftSidebar_MenuItem_HauptTitel_BorderStyleTop
                        }
                        DB_LeftSidebar_MenuItem_HauptTitel_Hover_BorderStyleTop={
                          DB_LeftSidebar_MenuItem_HauptTitel_Hover_BorderStyleTop
                        }
                        DB_LeftSidebar_MenuItem_HauptTitel_BorderColorTop={
                          DB_LeftSidebar_MenuItem_HauptTitel_BorderColorTop
                        }
                        DB_LeftSidebar_MenuItem_HauptTitel_Hover_BorderColorTop={
                          DB_LeftSidebar_MenuItem_HauptTitel_Hover_BorderColorTop
                        }
                        DB_LeftSidebar_MenuItem_HauptTitel_BorderBottom={
                          DB_LeftSidebar_MenuItem_HauptTitel_BorderBottom
                        }
                        DB_LeftSidebar_MenuItem_HauptTitel_Hover_BorderBottom={
                          DB_LeftSidebar_MenuItem_HauptTitel_Hover_BorderBottom
                        }
                        DB_LeftSidebar_MenuItem_HauptTitel_BorderStyleBottom={
                          DB_LeftSidebar_MenuItem_HauptTitel_BorderStyleBottom
                        }
                        DB_LeftSidebar_MenuItem_HauptTitel_Hover_BorderStyleBottom={
                          DB_LeftSidebar_MenuItem_HauptTitel_Hover_BorderStyleBottom
                        }
                        DB_LeftSidebar_MenuItem_HauptTitel_BorderColorBottom={
                          DB_LeftSidebar_MenuItem_HauptTitel_BorderColorBottom
                        }
                        DB_LeftSidebar_MenuItem_HauptTitel_Hover_BorderColorBottom={
                          DB_LeftSidebar_MenuItem_HauptTitel_Hover_BorderColorBottom
                        }
                        DB_LeftSidebar_MenuItem_HauptTitel_BorderLeft={
                          DB_LeftSidebar_MenuItem_HauptTitel_BorderLeft
                        }
                        DB_LeftSidebar_MenuItem_HauptTitel_Hover_BorderLeft={
                          DB_LeftSidebar_MenuItem_HauptTitel_Hover_BorderLeft
                        }
                        DB_LeftSidebar_MenuItem_HauptTitel_BorderStyleLeft={
                          DB_LeftSidebar_MenuItem_HauptTitel_BorderStyleLeft
                        }
                        DB_LeftSidebar_MenuItem_HauptTitel_Hover_BorderStyleLeft={
                          DB_LeftSidebar_MenuItem_HauptTitel_Hover_BorderStyleLeft
                        }
                        DB_LeftSidebar_MenuItem_HauptTitel_BorderColorLeft={
                          DB_LeftSidebar_MenuItem_HauptTitel_BorderColorLeft
                        }
                        DB_LeftSidebar_MenuItem_HauptTitel_Hover_BorderColorLeft={
                          DB_LeftSidebar_MenuItem_HauptTitel_Hover_BorderColorLeft
                        }
                        DB_LeftSidebar_MenuItem_HauptTitel_BorderRight={
                          DB_LeftSidebar_MenuItem_HauptTitel_BorderRight
                        }
                        DB_LeftSidebar_MenuItem_HauptTitel_Hover_BorderRight={
                          DB_LeftSidebar_MenuItem_HauptTitel_Hover_BorderRight
                        }
                        DB_LeftSidebar_MenuItem_HauptTitel_BorderStyleRight={
                          DB_LeftSidebar_MenuItem_HauptTitel_BorderStyleRight
                        }
                        DB_LeftSidebar_MenuItem_HauptTitel_Hover_BorderStyleRight={
                          DB_LeftSidebar_MenuItem_HauptTitel_Hover_BorderStyleRight
                        }
                        DB_LeftSidebar_MenuItem_HauptTitel_BorderColorRight={
                          DB_LeftSidebar_MenuItem_HauptTitel_BorderColorRight
                        }
                        DB_LeftSidebar_MenuItem_HauptTitel_Hover_BorderColorRight={
                          DB_LeftSidebar_MenuItem_HauptTitel_Hover_BorderColorRight
                        }
                        DB_LeftSidebar_MenuItem_HauptTitel_BorderTopRightRadius={
                          DB_LeftSidebar_MenuItem_HauptTitel_BorderTopRightRadius
                        }
                        DB_LeftSidebar_MenuItem_HauptTitel_Hover_BorderTopRightRadius={
                          DB_LeftSidebar_MenuItem_HauptTitel_Hover_BorderTopRightRadius
                        }
                        DB_LeftSidebar_MenuItem_HauptTitel_BorderTopLeftRadius={
                          DB_LeftSidebar_MenuItem_HauptTitel_BorderTopLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_HauptTitel_Hover_BorderTopLeftRadius={
                          DB_LeftSidebar_MenuItem_HauptTitel_Hover_BorderTopLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_HauptTitel_BorderBottomRightRadius={
                          DB_LeftSidebar_MenuItem_HauptTitel_BorderBottomRightRadius
                        }
                        DB_LeftSidebar_MenuItem_HauptTitel_Hover_BorderBottomRightRadius={
                          DB_LeftSidebar_MenuItem_HauptTitel_Hover_BorderBottomRightRadius
                        }
                        DB_LeftSidebar_MenuItem_HauptTitel_BorderBottomLeftRadius={
                          DB_LeftSidebar_MenuItem_HauptTitel_BorderBottomLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_HauptTitel_Hover_BorderBottomLeftRadius={
                          DB_LeftSidebar_MenuItem_HauptTitel_Hover_BorderBottomLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_HauptTitel_marginTopBottom={
                          DB_LeftSidebar_MenuItem_HauptTitel_marginTopBottom
                        }
                        DB_LeftSidebar_MenuItem_HauptTitel_marginLeftRight={
                          DB_LeftSidebar_MenuItem_HauptTitel_marginLeftRight
                        }
                        DB_LeftSidebar_MenuItem_HauptTitel_paddingLeftRight={
                          DB_LeftSidebar_MenuItem_HauptTitel_paddingLeftRight
                        }
                        DB_LeftSidebar_MenuItem_HauptTitel_paddingTopBottom={
                          DB_LeftSidebar_MenuItem_HauptTitel_paddingTopBottom
                        }
                        DB_LeftSidebar_MenuItem_HauptTitel_letterSpacing={
                          DB_LeftSidebar_MenuItem_HauptTitel_letterSpacing
                        }
                        DB_LeftSidebar_MenuItem_HauptTitel_ShadowHorizontal={
                          DB_LeftSidebar_MenuItem_HauptTitel_ShadowHorizontal
                        }
                        DB_LeftSidebar_MenuItem_HauptTitel_ShadowVertical={
                          DB_LeftSidebar_MenuItem_HauptTitel_ShadowVertical
                        }
                        DB_LeftSidebar_MenuItem_HauptTitel_ShadowBlur={
                          DB_LeftSidebar_MenuItem_HauptTitel_ShadowBlur
                        }
                        DB_LeftSidebar_MenuItem_HauptTitel_ShadowColor={
                          DB_LeftSidebar_MenuItem_HauptTitel_ShadowColor
                        }
                        DB_LeftSidebar_MenuItem_HauptTitel_TextDecoration={
                          DB_LeftSidebar_MenuItem_HauptTitel_TextDecoration
                        }
                        DB_LeftSidebar_MenuItem_HauptTitel_TextDecorationStyle={
                          DB_LeftSidebar_MenuItem_HauptTitel_TextDecorationStyle
                        }
                        DB_LeftSidebar_MenuItem_HauptTitel_marginTopBottom_Hover={
                          DB_LeftSidebar_MenuItem_HauptTitel_marginTopBottom_Hover
                        }
                        DB_LeftSidebar_MenuItem_HauptTitel_marginLeftRight_Hover={
                          DB_LeftSidebar_MenuItem_HauptTitel_marginLeftRight_Hover
                        }
                        DB_LeftSidebar_MenuItem_HauptTitel_paddingLeftRight_Hover={
                          DB_LeftSidebar_MenuItem_HauptTitel_paddingLeftRight_Hover
                        }
                        DB_LeftSidebar_MenuItem_HauptTitel_paddingTopBottom_Hover={
                          DB_LeftSidebar_MenuItem_HauptTitel_paddingTopBottom_Hover
                        }
                        DB_LeftSidebar_MenuItem_HauptTitel_letterSpacing_Hover={
                          DB_LeftSidebar_MenuItem_HauptTitel_letterSpacing_Hover
                        }
                        DB_LeftSidebar_MenuItem_HauptTitel_ShadowHorizontal_Hover={
                          DB_LeftSidebar_MenuItem_HauptTitel_ShadowHorizontal_Hover
                        }
                        DB_LeftSidebar_MenuItem_HauptTitel_ShadowVertical_Hover={
                          DB_LeftSidebar_MenuItem_HauptTitel_ShadowVertical_Hover
                        }
                        DB_LeftSidebar_MenuItem_HauptTitel_ShadowBlur_Hover={
                          DB_LeftSidebar_MenuItem_HauptTitel_ShadowBlur_Hover
                        }
                        DB_LeftSidebar_MenuItem_HauptTitel_ShadowColor_Hover={
                          DB_LeftSidebar_MenuItem_HauptTitel_ShadowColor_Hover
                        }
                        DB_LeftSidebar_MenuItem_HauptTitel_TextDecoration_Hover={
                          DB_LeftSidebar_MenuItem_HauptTitel_TextDecoration_Hover
                        }
                        DB_LeftSidebar_MenuItem_HauptTitel_TextDecorationStyle_Hover={
                          DB_LeftSidebar_MenuItem_HauptTitel_TextDecorationStyle_Hover
                        }
                        DB_LeftSidebar_MenuItem_UnterTitel_FontFace={
                          DB_LeftSidebar_MenuItem_UnterTitel_FontFace
                        }
                        DB_LeftSidebar_MenuItem_UnterTitel_Hover_FontFace={
                          DB_LeftSidebar_MenuItem_UnterTitel_Hover_FontFace
                        }
                        DB_LeftSidebar_MenuItem_UnterTitel_FontWeight={
                          DB_LeftSidebar_MenuItem_UnterTitel_FontWeight
                        }
                        DB_LeftSidebar_MenuItem_UnterTitel_Hover_FontWeight={
                          DB_LeftSidebar_MenuItem_UnterTitel_Hover_FontWeight
                        }
                        DB_LeftSidebar_MenuItem_UnterTitel_FontColor={
                          DB_LeftSidebar_MenuItem_UnterTitel_FontColor
                        }
                        DB_LeftSidebar_MenuItem_UnterTitel_Hover_FontColor={
                          DB_LeftSidebar_MenuItem_UnterTitel_Hover_FontColor
                        }
                        DB_LeftSidebar_MenuItem_UnterTitel_FontSize={
                          DB_LeftSidebar_MenuItem_UnterTitel_FontSize
                        }
                        DB_LeftSidebar_MenuItem_UnterTitel_Hover_FontSize={
                          DB_LeftSidebar_MenuItem_UnterTitel_Hover_FontSize
                        }
                        DB_LeftSidebar_MenuItem_UnterTitel_BackgroundColor={
                          DB_LeftSidebar_MenuItem_UnterTitel_BackgroundColor
                        }
                        DB_LeftSidebar_MenuItem_UnterTitel_Hover_BackgroundColor={
                          DB_LeftSidebar_MenuItem_UnterTitel_Hover_BackgroundColor
                        }
                        DB_LeftSidebar_MenuItem_UnterTitel_TextAlign={
                          DB_LeftSidebar_MenuItem_UnterTitel_TextAlign
                        }
                        DB_LeftSidebar_MenuItem_UnterTitel_Hover_TextAlign={
                          DB_LeftSidebar_MenuItem_UnterTitel_Hover_TextAlign
                        }
                        DB_LeftSidebar_MenuItem_UnterTitel_BorderTop={
                          DB_LeftSidebar_MenuItem_UnterTitel_BorderTop
                        }
                        DB_LeftSidebar_MenuItem_UnterTitel_Hover_BorderTop={
                          DB_LeftSidebar_MenuItem_UnterTitel_Hover_BorderTop
                        }
                        DB_LeftSidebar_MenuItem_UnterTitel_BorderStyleTop={
                          DB_LeftSidebar_MenuItem_UnterTitel_BorderStyleTop
                        }
                        DB_LeftSidebar_MenuItem_UnterTitel_Hover_BorderStyleTop={
                          DB_LeftSidebar_MenuItem_UnterTitel_Hover_BorderStyleTop
                        }
                        DB_LeftSidebar_MenuItem_UnterTitel_BorderColorTop={
                          DB_LeftSidebar_MenuItem_UnterTitel_BorderColorTop
                        }
                        DB_LeftSidebar_MenuItem_UnterTitel_Hover_BorderColorTop={
                          DB_LeftSidebar_MenuItem_UnterTitel_Hover_BorderColorTop
                        }
                        DB_LeftSidebar_MenuItem_UnterTitel_BorderBottom={
                          DB_LeftSidebar_MenuItem_UnterTitel_BorderBottom
                        }
                        DB_LeftSidebar_MenuItem_UnterTitel_Hover_BorderBottom={
                          DB_LeftSidebar_MenuItem_UnterTitel_Hover_BorderBottom
                        }
                        DB_LeftSidebar_MenuItem_UnterTitel_BorderStyleBottom={
                          DB_LeftSidebar_MenuItem_UnterTitel_BorderStyleBottom
                        }
                        DB_LeftSidebar_MenuItem_UnterTitel_Hover_BorderStyleBottom={
                          DB_LeftSidebar_MenuItem_UnterTitel_Hover_BorderStyleBottom
                        }
                        DB_LeftSidebar_MenuItem_UnterTitel_BorderColorBottom={
                          DB_LeftSidebar_MenuItem_UnterTitel_BorderColorBottom
                        }
                        DB_LeftSidebar_MenuItem_UnterTitel_Hover_BorderColorBottom={
                          DB_LeftSidebar_MenuItem_UnterTitel_Hover_BorderColorBottom
                        }
                        DB_LeftSidebar_MenuItem_UnterTitel_BorderLeft={
                          DB_LeftSidebar_MenuItem_UnterTitel_BorderLeft
                        }
                        DB_LeftSidebar_MenuItem_UnterTitel_Hover_BorderLeft={
                          DB_LeftSidebar_MenuItem_UnterTitel_Hover_BorderLeft
                        }
                        DB_LeftSidebar_MenuItem_UnterTitel_BorderStyleLeft={
                          DB_LeftSidebar_MenuItem_UnterTitel_BorderStyleLeft
                        }
                        DB_LeftSidebar_MenuItem_UnterTitel_Hover_BorderStyleLeft={
                          DB_LeftSidebar_MenuItem_UnterTitel_Hover_BorderStyleLeft
                        }
                        DB_LeftSidebar_MenuItem_UnterTitel_BorderColorLeft={
                          DB_LeftSidebar_MenuItem_UnterTitel_BorderColorLeft
                        }
                        DB_LeftSidebar_MenuItem_UnterTitel_Hover_BorderColorLeft={
                          DB_LeftSidebar_MenuItem_UnterTitel_Hover_BorderColorLeft
                        }
                        DB_LeftSidebar_MenuItem_UnterTitel_BorderRight={
                          DB_LeftSidebar_MenuItem_UnterTitel_BorderRight
                        }
                        DB_LeftSidebar_MenuItem_UnterTitel_Hover_BorderRight={
                          DB_LeftSidebar_MenuItem_UnterTitel_Hover_BorderRight
                        }
                        DB_LeftSidebar_MenuItem_UnterTitel_BorderStyleRight={
                          DB_LeftSidebar_MenuItem_UnterTitel_BorderStyleRight
                        }
                        DB_LeftSidebar_MenuItem_UnterTitel_Hover_BorderStyleRight={
                          DB_LeftSidebar_MenuItem_UnterTitel_Hover_BorderStyleRight
                        }
                        DB_LeftSidebar_MenuItem_UnterTitel_BorderColorRight={
                          DB_LeftSidebar_MenuItem_UnterTitel_BorderColorRight
                        }
                        DB_LeftSidebar_MenuItem_UnterTitel_Hover_BorderColorRight={
                          DB_LeftSidebar_MenuItem_UnterTitel_Hover_BorderColorRight
                        }
                        DB_LeftSidebar_MenuItem_UnterTitel_BorderTopRightRadius={
                          DB_LeftSidebar_MenuItem_UnterTitel_BorderTopRightRadius
                        }
                        DB_LeftSidebar_MenuItem_UnterTitel_Hover_BorderTopRightRadius={
                          DB_LeftSidebar_MenuItem_UnterTitel_Hover_BorderTopRightRadius
                        }
                        DB_LeftSidebar_MenuItem_UnterTitel_BorderTopLeftRadius={
                          DB_LeftSidebar_MenuItem_UnterTitel_BorderTopLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_UnterTitel_Hover_BorderTopLeftRadius={
                          DB_LeftSidebar_MenuItem_UnterTitel_Hover_BorderTopLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_UnterTitel_BorderBottomRightRadius={
                          DB_LeftSidebar_MenuItem_UnterTitel_BorderBottomRightRadius
                        }
                        DB_LeftSidebar_MenuItem_UnterTitel_Hover_BorderBottomRightRadius={
                          DB_LeftSidebar_MenuItem_UnterTitel_Hover_BorderBottomRightRadius
                        }
                        DB_LeftSidebar_MenuItem_UnterTitel_BorderBottomLeftRadius={
                          DB_LeftSidebar_MenuItem_UnterTitel_BorderBottomLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_UnterTitel_Hover_BorderBottomLeftRadius={
                          DB_LeftSidebar_MenuItem_UnterTitel_Hover_BorderBottomLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_UnterTitel_marginTopBottom={
                          DB_LeftSidebar_MenuItem_UnterTitel_marginTopBottom
                        }
                        DB_LeftSidebar_MenuItem_UnterTitel_marginLeftRight={
                          DB_LeftSidebar_MenuItem_UnterTitel_marginLeftRight
                        }
                        DB_LeftSidebar_MenuItem_UnterTitel_paddingLeftRight={
                          DB_LeftSidebar_MenuItem_UnterTitel_paddingLeftRight
                        }
                        DB_LeftSidebar_MenuItem_UnterTitel_paddingTopBottom={
                          DB_LeftSidebar_MenuItem_UnterTitel_paddingTopBottom
                        }
                        DB_LeftSidebar_MenuItem_UnterTitel_letterSpacing={
                          DB_LeftSidebar_MenuItem_UnterTitel_letterSpacing
                        }
                        DB_LeftSidebar_MenuItem_UnterTitel_ShadowHorizontal={
                          DB_LeftSidebar_MenuItem_UnterTitel_ShadowHorizontal
                        }
                        DB_LeftSidebar_MenuItem_UnterTitel_ShadowVertical={
                          DB_LeftSidebar_MenuItem_UnterTitel_ShadowVertical
                        }
                        DB_LeftSidebar_MenuItem_UnterTitel_ShadowBlur={
                          DB_LeftSidebar_MenuItem_UnterTitel_ShadowBlur
                        }
                        DB_LeftSidebar_MenuItem_UnterTitel_ShadowColor={
                          DB_LeftSidebar_MenuItem_UnterTitel_ShadowColor
                        }
                        DB_LeftSidebar_MenuItem_UnterTitel_TextDecoration={
                          DB_LeftSidebar_MenuItem_UnterTitel_TextDecoration
                        }
                        DB_LeftSidebar_MenuItem_UnterTitel_TextDecorationStyle={
                          DB_LeftSidebar_MenuItem_UnterTitel_TextDecorationStyle
                        }
                        DB_LeftSidebar_MenuItem_UnterTitel_marginTopBottom_Hover={
                          DB_LeftSidebar_MenuItem_UnterTitel_marginTopBottom_Hover
                        }
                        DB_LeftSidebar_MenuItem_UnterTitel_marginLeftRight_Hover={
                          DB_LeftSidebar_MenuItem_UnterTitel_marginLeftRight_Hover
                        }
                        DB_LeftSidebar_MenuItem_UnterTitel_paddingLeftRight_Hover={
                          DB_LeftSidebar_MenuItem_UnterTitel_paddingLeftRight_Hover
                        }
                        DB_LeftSidebar_MenuItem_UnterTitel_paddingTopBottom_Hover={
                          DB_LeftSidebar_MenuItem_UnterTitel_paddingTopBottom_Hover
                        }
                        DB_LeftSidebar_MenuItem_UnterTitel_letterSpacing_Hover={
                          DB_LeftSidebar_MenuItem_UnterTitel_letterSpacing_Hover
                        }
                        DB_LeftSidebar_MenuItem_UnterTitel_ShadowHorizontal_Hover={
                          DB_LeftSidebar_MenuItem_UnterTitel_ShadowHorizontal_Hover
                        }
                        DB_LeftSidebar_MenuItem_UnterTitel_ShadowVertical_Hover={
                          DB_LeftSidebar_MenuItem_UnterTitel_ShadowVertical_Hover
                        }
                        DB_LeftSidebar_MenuItem_UnterTitel_ShadowBlur_Hover={
                          DB_LeftSidebar_MenuItem_UnterTitel_ShadowBlur_Hover
                        }
                        DB_LeftSidebar_MenuItem_UnterTitel_ShadowColor_Hover={
                          DB_LeftSidebar_MenuItem_UnterTitel_ShadowColor_Hover
                        }
                        DB_LeftSidebar_MenuItem_UnterTitel_TextDecoration_Hover={
                          DB_LeftSidebar_MenuItem_UnterTitel_TextDecoration_Hover
                        }
                        DB_LeftSidebar_MenuItem_UnterTitel_TextDecorationStyle_Hover={
                          DB_LeftSidebar_MenuItem_UnterTitel_TextDecorationStyle_Hover
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_FontWeight={
                          DB_LeftSidebar_MenuItem_ProduktPreis_FontWeight
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_Hover_FontWeight={
                          DB_LeftSidebar_MenuItem_ProduktPreis_Hover_FontWeight
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_FontColor={
                          DB_LeftSidebar_MenuItem_ProduktPreis_FontColor
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_Hover_FontColor={
                          DB_LeftSidebar_MenuItem_ProduktPreis_Hover_FontColor
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_FontSize={
                          DB_LeftSidebar_MenuItem_ProduktPreis_FontSize
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_Hover_FontSize={
                          DB_LeftSidebar_MenuItem_ProduktPreis_Hover_FontSize
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_BackgroundColor={
                          DB_LeftSidebar_MenuItem_ProduktPreis_BackgroundColor
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BackgroundColor={
                          DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BackgroundColor
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_TextAlign={
                          DB_LeftSidebar_MenuItem_ProduktPreis_TextAlign
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_Hover_TextAlign={
                          DB_LeftSidebar_MenuItem_ProduktPreis_Hover_TextAlign
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_BorderTop={
                          DB_LeftSidebar_MenuItem_ProduktPreis_BorderTop
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderTop={
                          DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderTop
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_BorderStyleTop={
                          DB_LeftSidebar_MenuItem_ProduktPreis_BorderStyleTop
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_BorderColorTop={
                          DB_LeftSidebar_MenuItem_ProduktPreis_BorderColorTop
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_BorderBottom={
                          DB_LeftSidebar_MenuItem_ProduktPreis_BorderBottom
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_BorderStyleBottom={
                          DB_LeftSidebar_MenuItem_ProduktPreis_BorderStyleBottom
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_BorderColorBottom={
                          DB_LeftSidebar_MenuItem_ProduktPreis_BorderColorBottom
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_BorderLeft={
                          DB_LeftSidebar_MenuItem_ProduktPreis_BorderLeft
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_BorderStyleLeft={
                          DB_LeftSidebar_MenuItem_ProduktPreis_BorderStyleLeft
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_BorderColorLeft={
                          DB_LeftSidebar_MenuItem_ProduktPreis_BorderColorLeft
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_BorderRight={
                          DB_LeftSidebar_MenuItem_ProduktPreis_BorderRight
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_BorderStyleRight={
                          DB_LeftSidebar_MenuItem_ProduktPreis_BorderStyleRight
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_BorderColorRight={
                          DB_LeftSidebar_MenuItem_ProduktPreis_BorderColorRight
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_BorderTopRightRadius={
                          DB_LeftSidebar_MenuItem_ProduktPreis_BorderTopRightRadius
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_BorderTopLeftRadius={
                          DB_LeftSidebar_MenuItem_ProduktPreis_BorderTopLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_BorderBottomRightRadius={
                          DB_LeftSidebar_MenuItem_ProduktPreis_BorderBottomRightRadius
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_BorderBottomLeftRadius={
                          DB_LeftSidebar_MenuItem_ProduktPreis_BorderBottomLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderTopRightRadius={
                          DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderTopRightRadius
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderTopLeftRadius={
                          DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderTopLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderBottomRightRadius={
                          DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderBottomRightRadius
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderBottomLeftRadius={
                          DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderBottomLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderStyleTop={
                          DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderStyleTop
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderColorTop={
                          DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderColorTop
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderBottom={
                          DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderBottom
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderStyleBottom={
                          DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderStyleBottom
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderColorBottom={
                          DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderColorBottom
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderLeft={
                          DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderLeft
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderStyleLeft={
                          DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderStyleLeft
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderColorLeft={
                          DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderColorLeft
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderRight={
                          DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderRight
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderStyleRight={
                          DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderStyleRight
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderColorRight={
                          DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderColorRight
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_marginTopBottom={
                          DB_LeftSidebar_MenuItem_ProduktPreis_marginTopBottom
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_marginLeftRight={
                          DB_LeftSidebar_MenuItem_ProduktPreis_marginLeftRight
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_paddingLeftRight={
                          DB_LeftSidebar_MenuItem_ProduktPreis_paddingLeftRight
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_paddingTopBottom={
                          DB_LeftSidebar_MenuItem_ProduktPreis_paddingTopBottom
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_letterSpacing={
                          DB_LeftSidebar_MenuItem_ProduktPreis_letterSpacing
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_ShadowHorizontal={
                          DB_LeftSidebar_MenuItem_ProduktPreis_ShadowHorizontal
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_ShadowVertical={
                          DB_LeftSidebar_MenuItem_ProduktPreis_ShadowVertical
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_ShadowBlur={
                          DB_LeftSidebar_MenuItem_ProduktPreis_ShadowBlur
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_ShadowColor={
                          DB_LeftSidebar_MenuItem_ProduktPreis_ShadowColor
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_TextDecoration={
                          DB_LeftSidebar_MenuItem_ProduktPreis_TextDecoration
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_TextDecorationStyle={
                          DB_LeftSidebar_MenuItem_ProduktPreis_TextDecorationStyle
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_marginTopBottom_Hover={
                          DB_LeftSidebar_MenuItem_ProduktPreis_marginTopBottom_Hover
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_marginLeftRight_Hover={
                          DB_LeftSidebar_MenuItem_ProduktPreis_marginLeftRight_Hover
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_paddingLeftRight_Hover={
                          DB_LeftSidebar_MenuItem_ProduktPreis_paddingLeftRight_Hover
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_paddingTopBottom_Hover={
                          DB_LeftSidebar_MenuItem_ProduktPreis_paddingTopBottom_Hover
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_letterSpacing_Hover={
                          DB_LeftSidebar_MenuItem_ProduktPreis_letterSpacing_Hover
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_ShadowHorizontal_Hover={
                          DB_LeftSidebar_MenuItem_ProduktPreis_ShadowHorizontal_Hover
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_ShadowVertical_Hover={
                          DB_LeftSidebar_MenuItem_ProduktPreis_ShadowVertical_Hover
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_ShadowBlur_Hover={
                          DB_LeftSidebar_MenuItem_ProduktPreis_ShadowBlur_Hover
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_ShadowColor_Hover={
                          DB_LeftSidebar_MenuItem_ProduktPreis_ShadowColor_Hover
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_TextDecoration_Hover={
                          DB_LeftSidebar_MenuItem_ProduktPreis_TextDecoration_Hover
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_TextDecorationStyle_Hover={
                          DB_LeftSidebar_MenuItem_ProduktPreis_TextDecorationStyle_Hover
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_FontWeight={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_FontWeight
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_FontWeight={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_FontWeight
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_FontColor={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_FontColor
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_FontColor={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_FontColor
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_FontSize={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_FontSize
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_FontSize={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_FontSize
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_BackgroundColor={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_BackgroundColor
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BackgroundColor={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BackgroundColor
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_TextAlign={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_TextAlign
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_TextAlign={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_TextAlign
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_BorderTop={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_BorderTop
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderTop={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderTop
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_BorderStyleTop={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_BorderStyleTop
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_BorderColorTop={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_BorderColorTop
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_BorderBottom={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_BorderBottom
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_BorderStyleBottom={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_BorderStyleBottom
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_BorderColorBottom={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_BorderColorBottom
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_BorderLeft={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_BorderLeft
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_BorderStyleLeft={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_BorderStyleLeft
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_BorderColorLeft={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_BorderColorLeft
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_BorderRight={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_BorderRight
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_BorderStyleRight={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_BorderStyleRight
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_BorderColorRight={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_BorderColorRight
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_BorderTopRightRadius={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_BorderTopRightRadius
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_BorderTopLeftRadius={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_BorderTopLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_BorderBottomRightRadius={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_BorderBottomRightRadius
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_BorderBottomLeftRadius={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_BorderBottomLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderTopRightRadius={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderTopRightRadius
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderTopLeftRadius={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderTopLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderBottomRightRadius={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderBottomRightRadius
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderBottomLeftRadius={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderBottomLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderStyleTop={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderStyleTop
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderColorTop={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderColorTop
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderBottom={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderBottom
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderStyleBottom={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderStyleBottom
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderColorBottom={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderColorBottom
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderLeft={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderLeft
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderStyleLeft={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderStyleLeft
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderColorLeft={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderColorLeft
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderRight={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderRight
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderStyleRight={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderStyleRight
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderColorRight={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderColorRight
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_marginTopBottom={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_marginTopBottom
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_marginLeftRight={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_marginLeftRight
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_paddingLeftRight={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_paddingLeftRight
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_paddingTopBottom={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_paddingTopBottom
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_letterSpacing={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_letterSpacing
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_ShadowHorizontal={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_ShadowHorizontal
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_ShadowVertical={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_ShadowVertical
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_ShadowBlur={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_ShadowBlur
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_ShadowColor={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_ShadowColor
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_TextDecoration={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_TextDecoration
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_TextDecorationStyle={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_TextDecorationStyle
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_marginTopBottom_Hover={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_marginTopBottom_Hover
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_marginLeftRight_Hover={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_marginLeftRight_Hover
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_paddingLeftRight_Hover={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_paddingLeftRight_Hover
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_paddingTopBottom_Hover={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_paddingTopBottom_Hover
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_letterSpacing_Hover={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_letterSpacing_Hover
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_ShadowHorizontal_Hover={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_ShadowHorizontal_Hover
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_ShadowVertical_Hover={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_ShadowVertical_Hover
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_ShadowBlur_Hover={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_ShadowBlur_Hover
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_ShadowColor_Hover={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_ShadowColor_Hover
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_TextDecoration_Hover={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_TextDecoration_Hover
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_TextDecorationStyle_Hover={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_TextDecorationStyle_Hover
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_BackgroundColor={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_BackgroundColor
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BackgroundColor={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BackgroundColor
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_TextAlign={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_TextAlign
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_TextAlign={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_TextAlign
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_BorderTop={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_BorderTop
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderTop={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderTop
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_BorderStyleTop={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_BorderStyleTop
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_BorderColorTop={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_BorderColorTop
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_BorderBottom={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_BorderBottom
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_BorderStyleBottom={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_BorderStyleBottom
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_BorderColorBottom={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_BorderColorBottom
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_BorderLeft={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_BorderLeft
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_BorderStyleLeft={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_BorderStyleLeft
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_BorderColorLeft={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_BorderColorLeft
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_BorderRight={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_BorderRight
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_BorderStyleRight={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_BorderStyleRight
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_BorderColorRight={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_BorderColorRight
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_BorderTopRightRadius={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_BorderTopRightRadius
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_BorderTopLeftRadius={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_BorderTopLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_BorderBottomRightRadius={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_BorderBottomRightRadius
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_BorderBottomLeftRadius={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_BorderBottomLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderTopRightRadius={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderTopRightRadius
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderTopLeftRadius={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderTopLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderBottomRightRadius={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderBottomRightRadius
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderBottomLeftRadius={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderBottomLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderStyleTop={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderStyleTop
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderColorTop={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderColorTop
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderBottom={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderBottom
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderStyleBottom={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderStyleBottom
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderColorBottom={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderColorBottom
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderLeft={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderLeft
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderStyleLeft={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderStyleLeft
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderColorLeft={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderColorLeft
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderRight={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderRight
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderStyleRight={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderStyleRight
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderColorRight={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderColorRight
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_marginTopBottom={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_marginTopBottom
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_marginLeftRight={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_marginLeftRight
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_paddingLeftRight={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_paddingLeftRight
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_paddingTopBottom={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_paddingTopBottom
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_letterSpacing={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_letterSpacing
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_ShadowHorizontal={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_ShadowHorizontal
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_ShadowVertical={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_ShadowVertical
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_ShadowBlur={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_ShadowBlur
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_ShadowColor={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_ShadowColor
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_FontWeight={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_FontWeight
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_FontWeight={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_FontWeight
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_FontColor={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_FontColor
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_FontColor={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_FontColor
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_FontSize={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_FontSize
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_FontSize={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_FontSize
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_TextDecoration={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_TextDecoration
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_TextDecorationStyle={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_TextDecorationStyle
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_marginTopBottom_Hover={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_marginTopBottom_Hover
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_marginLeftRight_Hover={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_marginLeftRight_Hover
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_paddingLeftRight_Hover={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_paddingLeftRight_Hover
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_paddingTopBottom_Hover={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_paddingTopBottom_Hover
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_letterSpacing_Hover={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_letterSpacing_Hover
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_ShadowHorizontal_Hover={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_ShadowHorizontal_Hover
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_ShadowVertical_Hover={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_ShadowVertical_Hover
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_ShadowBlur_Hover={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_ShadowBlur_Hover
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_ShadowColor_Hover={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_ShadowColor_Hover
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_TextDecoration_Hover={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_TextDecoration_Hover
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_TextDecorationStyle_Hover={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_TextDecorationStyle_Hover
                        }
                        SideBarLeft_ProduktContainer_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut={
                          SideBarLeft_ProduktContainer_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut
                        }
                        SideBarLeft_ProduktContainer_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut={
                          SideBarLeft_ProduktContainer_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut
                        }
                        DB_LeftSidebar_MenuProduktContainer_BackgroundColor={
                          DB_LeftSidebar_MenuProduktContainer_BackgroundColor
                        }
                        DB_LeftSidebar_MenuProduktImageContainer_BackgroundColor={
                          DB_LeftSidebar_MenuProduktImageContainer_BackgroundColor
                        }
                        DB_LeftSidebar_MenuProduktContainer_PaddingTopBottom={
                          DB_LeftSidebar_MenuProduktContainer_PaddingTopBottom
                        }
                        DB_LeftSidebar_MenuProduktContainer_PaddingLeftRight={
                          DB_LeftSidebar_MenuProduktContainer_PaddingLeftRight
                        }
                        SideBarLeft_ProduktContainer_Farbe_Groesse_Stil_OnMouseOverAndOut={
                          SideBarLeft_ProduktContainer_Farbe_Groesse_Stil_OnMouseOverAndOut
                        }
                        SideBarLeft_ProduktContainer_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut={
                          SideBarLeft_ProduktContainer_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
                        }
                        DB_LeftSidebar_MenuProduktContainer_BorderTop={
                          DB_LeftSidebar_MenuProduktContainer_BorderTop
                        }
                        DB_LeftSidebar_MenuProduktContainer_BorderStyleTop={
                          DB_LeftSidebar_MenuProduktContainer_BorderStyleTop
                        }
                        DB_LeftSidebar_MenuProduktContainer_BorderColorTop={
                          DB_LeftSidebar_MenuProduktContainer_BorderColorTop
                        }
                        DB_LeftSidebar_MenuProduktContainer_BorderBottom={
                          DB_LeftSidebar_MenuProduktContainer_BorderBottom
                        }
                        DB_LeftSidebar_MenuProduktContainer_BorderStyleBottom={
                          DB_LeftSidebar_MenuProduktContainer_BorderStyleBottom
                        }
                        DB_LeftSidebar_MenuProduktContainer_BorderColorBottom={
                          DB_LeftSidebar_MenuProduktContainer_BorderColorBottom
                        }
                        DB_LeftSidebar_MenuProduktContainer_BorderLeft={
                          DB_LeftSidebar_MenuProduktContainer_BorderLeft
                        }
                        DB_LeftSidebar_MenuProduktContainer_BorderStyleLeft={
                          DB_LeftSidebar_MenuProduktContainer_BorderStyleLeft
                        }
                        DB_LeftSidebar_MenuProduktContainer_BorderColorLeft={
                          DB_LeftSidebar_MenuProduktContainer_BorderColorLeft
                        }
                        DB_LeftSidebar_MenuProduktContainer_BorderRight={
                          DB_LeftSidebar_MenuProduktContainer_BorderRight
                        }
                        DB_LeftSidebar_MenuProduktContainer_BorderStyleRight={
                          DB_LeftSidebar_MenuProduktContainer_BorderStyleRight
                        }
                        DB_LeftSidebar_MenuProduktContainer_BorderColorRight={
                          DB_LeftSidebar_MenuProduktContainer_BorderColorRight
                        }
                        DB_LeftSidebar_MenuItem_MenuProduktContainer_BorderTopRightRadius={
                          DB_LeftSidebar_MenuItem_MenuProduktContainer_BorderTopRightRadius
                        }
                        DB_LeftSidebar_MenuItem_MenuProduktContainer_BorderTopLeftRadius={
                          DB_LeftSidebar_MenuItem_MenuProduktContainer_BorderTopLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_MenuProduktContainer_BorderBottomRightRadius={
                          DB_LeftSidebar_MenuItem_MenuProduktContainer_BorderBottomRightRadius
                        }
                        DB_LeftSidebar_MenuItem_MenuProduktContainer_BorderBottomLeftRadius={
                          DB_LeftSidebar_MenuItem_MenuProduktContainer_BorderBottomLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_MenuProduktContainer_paddingLeft={
                          DB_LeftSidebar_MenuItem_MenuProduktContainer_paddingLeft
                        }
                        DB_LeftSidebar_MenuItem_MenuProduktContainer_paddingRight={
                          DB_LeftSidebar_MenuItem_MenuProduktContainer_paddingRight
                        }
                        DB_LeftSidebar_MenuItem_MenuProduktContainer_paddingTop={
                          DB_LeftSidebar_MenuItem_MenuProduktContainer_paddingTop
                        }
                        DB_LeftSidebar_MenuItem_MenuProduktContainer_paddingBottom={
                          DB_LeftSidebar_MenuItem_MenuProduktContainer_paddingBottom
                        }
                        DB_LeftSidebar_MenuItem_MenuProduktContainer_ShadowVertical={
                          DB_LeftSidebar_MenuItem_MenuProduktContainer_ShadowVertical
                        }
                        DB_LeftSidebar_MenuItem_MenuProduktContainer_ShadowHorizontal={
                          DB_LeftSidebar_MenuItem_MenuProduktContainer_ShadowHorizontal
                        }
                        DB_LeftSidebar_MenuItem_MenuProduktContainer_ShadowBlur={
                          DB_LeftSidebar_MenuItem_MenuProduktContainer_ShadowBlur
                        }
                        DB_LeftSidebar_MenuItem_MenuProduktContainer_ShadowColor={
                          DB_LeftSidebar_MenuItem_MenuProduktContainer_ShadowColor
                        }
                        DB_LeftSidebar_MenuProduktContainer_Hover_BackgroundColor={
                          DB_LeftSidebar_MenuProduktContainer_Hover_BackgroundColor
                        }
                        DB_LeftSidebar_MenuProduktImageContainer_Hover_BackgroundColor={
                          DB_LeftSidebar_MenuProduktImageContainer_Hover_BackgroundColor
                        }
                        DB_LeftSidebar_MenuProduktContainer_Hover_PaddingTopBottom={
                          DB_LeftSidebar_MenuProduktContainer_Hover_PaddingTopBottom
                        }
                        DB_LeftSidebar_MenuProduktContainer_Hover_PaddingLeftRight={
                          DB_LeftSidebar_MenuProduktContainer_Hover_PaddingLeftRight
                        }
                        DB_LeftSidebar_MenuProduktContainer_Hover_BorderTop={
                          DB_LeftSidebar_MenuProduktContainer_Hover_BorderTop
                        }
                        DB_LeftSidebar_MenuProduktContainer_Hover_BorderStyleTop={
                          DB_LeftSidebar_MenuProduktContainer_Hover_BorderStyleTop
                        }
                        DB_LeftSidebar_MenuProduktContainer_Hover_BorderColorTop={
                          DB_LeftSidebar_MenuProduktContainer_Hover_BorderColorTop
                        }
                        DB_LeftSidebar_MenuProduktContainer_Hover_BorderBottom={
                          DB_LeftSidebar_MenuProduktContainer_Hover_BorderBottom
                        }
                        DB_LeftSidebar_MenuProduktContainer_Hover_BorderStyleBottom={
                          DB_LeftSidebar_MenuProduktContainer_Hover_BorderStyleBottom
                        }
                        DB_LeftSidebar_MenuProduktContainer_Hover_BorderColorBottom={
                          DB_LeftSidebar_MenuProduktContainer_Hover_BorderColorBottom
                        }
                        DB_LeftSidebar_MenuProduktContainer_Hover_BorderLeft={
                          DB_LeftSidebar_MenuProduktContainer_Hover_BorderLeft
                        }
                        DB_LeftSidebar_MenuProduktContainer_Hover_BorderStyleLeft={
                          DB_LeftSidebar_MenuProduktContainer_Hover_BorderStyleLeft
                        }
                        DB_LeftSidebar_MenuProduktContainer_Hover_BorderColorLeft={
                          DB_LeftSidebar_MenuProduktContainer_Hover_BorderColorLeft
                        }
                        DB_LeftSidebar_MenuProduktContainer_Hover_BorderRight={
                          DB_LeftSidebar_MenuProduktContainer_Hover_BorderRight
                        }
                        DB_LeftSidebar_MenuProduktContainer_Hover_BorderStyleRight={
                          DB_LeftSidebar_MenuProduktContainer_Hover_BorderStyleRight
                        }
                        DB_LeftSidebar_MenuProduktContainer_Hover_BorderColorRight={
                          DB_LeftSidebar_MenuProduktContainer_Hover_BorderColorRight
                        }
                        DB_LeftSidebar_MenuItem_MenuProduktContainer_Hover_BorderTopRightRadius={
                          DB_LeftSidebar_MenuItem_MenuProduktContainer_Hover_BorderTopRightRadius
                        }
                        DB_LeftSidebar_MenuItem_MenuProduktContainer_Hover_BorderTopLeftRadius={
                          DB_LeftSidebar_MenuItem_MenuProduktContainer_Hover_BorderTopLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_MenuProduktContainer_Hover_BorderBottomRightRadius={
                          DB_LeftSidebar_MenuItem_MenuProduktContainer_Hover_BorderBottomRightRadius
                        }
                        DB_LeftSidebar_MenuItem_MenuProduktContainer_Hover_BorderBottomLeftRadius={
                          DB_LeftSidebar_MenuItem_MenuProduktContainer_Hover_BorderBottomLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_MenuProduktContainer_Hover_paddingLeft={
                          DB_LeftSidebar_MenuItem_MenuProduktContainer_Hover_paddingLeft
                        }
                        DB_LeftSidebar_MenuItem_MenuProduktContainer_Hover_paddingRight={
                          DB_LeftSidebar_MenuItem_MenuProduktContainer_Hover_paddingRight
                        }
                        DB_LeftSidebar_MenuItem_MenuProduktContainer_Hover_paddingTop={
                          DB_LeftSidebar_MenuItem_MenuProduktContainer_Hover_paddingTop
                        }
                        DB_LeftSidebar_MenuItem_MenuProduktContainer_Hover_paddingBottom={
                          DB_LeftSidebar_MenuItem_MenuProduktContainer_Hover_paddingBottom
                        }
                        DB_LeftSidebar_MenuItem_MenuProduktContainer_Hover_ShadowVertical={
                          DB_LeftSidebar_MenuItem_MenuProduktContainer_Hover_ShadowVertical
                        }
                        DB_LeftSidebar_MenuItem_MenuProduktContainer_Hover_ShadowHorizontal={
                          DB_LeftSidebar_MenuItem_MenuProduktContainer_Hover_ShadowHorizontal
                        }
                        DB_LeftSidebar_MenuItem_MenuProduktContainer_Hover_ShadowBlur={
                          DB_LeftSidebar_MenuItem_MenuProduktContainer_Hover_ShadowBlur
                        }
                        DB_LeftSidebar_MenuItem_MenuProduktContainer_Hover_ShadowColor={
                          DB_LeftSidebar_MenuItem_MenuProduktContainer_Hover_ShadowColor
                        }
                      />

                      <Row>
                        <Col lg={12} style={{ marginTop: "50px" }}>
                          {paginationStart > 0 && (
                            <h1
                              style={{
                                color: "white",
                                cursor: "pointer",
                                display: "inline-block",
                                userSelect: "none",
                                paddingRight: "50px",
                                float: "left",
                              }}
                              onClick={() => productBefore()}
                            >
                              ⬅️
                            </h1>
                          )}
                          {paginationEnd < pageAnzahlCounter && (
                            <h1
                              style={{
                                color: "white",
                                cursor: "pointer",
                                display: "inline-block",
                                userSelect: "none",
                                paddingLeft: "50px",
                                float: "right",
                              }}
                              onClick={() => productNext()}
                            >
                              ➡️
                            </h1>
                          )}
                        </Col>
                      </Row>
                      {ProductFilterIsVisibleBottom && (
                        <ProductFilterTopBarBottom
                          className="p-0"
                          style={{
                            width: "95%",
                            marginLeft: "auto",
                            marginRight: "auto",
                          }}
                          paginationStart={paginationStart}
                          paginationEnd={paginationEnd}
                          allProductsStatePagination={
                            allProductsStatePagination
                          }
                          productBefore={productBefore}
                          productNext={productNext}
                          columnNumber={columnNumber}
                          pageAnzahlCounter={pageAnzahlCounter}
                          DB_fontFace_FontFamilyName={
                            DB_fontFace_FontFamilyName
                          }
                          columnNumberChange={columnNumberChange}
                          changePreisAlphabeticUswHandler={
                            changePreisAlphabeticUswHandler
                          }
                          SideBarLeft_SortierungTop_Farbe_Groesse_Stil_OnMouseOverAndOut={
                            SideBarLeft_SortierungTop_Farbe_Groesse_Stil_OnMouseOverAndOut
                          }
                          SideBarLeft_SortierungTop_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut={
                            SideBarLeft_SortierungTop_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
                          }
                          SideBarLeft_SortierungTop_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut={
                            SideBarLeft_SortierungTop_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut
                          }
                          SideBarLeft_SortierungTop_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut={
                            SideBarLeft_SortierungTop_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut
                          }
                          DB_fontFace_FilterTopBar_FontFamilyName={
                            DB_fontFace_FilterTopBar_FontFamilyName
                          }
                          DB_fontFace_FilterTopBar_FontFamilySrc={
                            DB_fontFace_FilterTopBar_FontFamilySrc
                          }
                          DB_LeftSidebar_MenuItem_SortierungTop_BackgroundColor={
                            DB_LeftSidebar_MenuItem_SortierungTop_BackgroundColor
                          }
                          DB_LeftSidebar_MenuItem_SortierungTop_FontColor={
                            DB_LeftSidebar_MenuItem_SortierungTop_FontColor
                          }
                          DB_LeftSidebar_MenuItem_SortierungTop_BorderTop={
                            DB_LeftSidebar_MenuItem_SortierungTop_BorderTop
                          }
                          DB_LeftSidebar_MenuItem_SortierungTop_BorderStyleTop={
                            DB_LeftSidebar_MenuItem_SortierungTop_BorderStyleTop
                          }
                          DB_LeftSidebar_MenuItem_SortierungTop_BorderColorTop={
                            DB_LeftSidebar_MenuItem_SortierungTop_BorderColorTop
                          }
                          DB_LeftSidebar_MenuItem_SortierungTop_BorderBottom={
                            DB_LeftSidebar_MenuItem_SortierungTop_BorderBottom
                          }
                          DB_LeftSidebar_MenuItem_SortierungTop_BorderStyleBottom={
                            DB_LeftSidebar_MenuItem_SortierungTop_BorderStyleBottom
                          }
                          DB_LeftSidebar_MenuItem_SortierungTop_BorderColorBottom={
                            DB_LeftSidebar_MenuItem_SortierungTop_BorderColorBottom
                          }
                          DB_LeftSidebar_MenuItem_SortierungTop_BorderLeft={
                            DB_LeftSidebar_MenuItem_SortierungTop_BorderLeft
                          }
                          DB_LeftSidebar_MenuItem_SortierungTop_BorderStyleLeft={
                            DB_LeftSidebar_MenuItem_SortierungTop_BorderStyleLeft
                          }
                          DB_LeftSidebar_MenuItem_SortierungTop_BorderColorLeft={
                            DB_LeftSidebar_MenuItem_SortierungTop_BorderColorLeft
                          }
                          DB_LeftSidebar_MenuItem_SortierungTop_BorderRight={
                            DB_LeftSidebar_MenuItem_SortierungTop_BorderRight
                          }
                          DB_LeftSidebar_MenuItem_SortierungTop_BorderStyleRight={
                            DB_LeftSidebar_MenuItem_SortierungTop_BorderStyleRight
                          }
                          DB_LeftSidebar_MenuItem_SortierungTop_BorderColorRight={
                            DB_LeftSidebar_MenuItem_SortierungTop_BorderColorRight
                          }
                          DB_LeftSidebar_MenuItem_SortierungTop_BorderTopRightRadius={
                            DB_LeftSidebar_MenuItem_SortierungTop_BorderTopRightRadius
                          }
                          DB_LeftSidebar_MenuItem_SortierungTop_BorderTopLeftRadius={
                            DB_LeftSidebar_MenuItem_SortierungTop_BorderTopLeftRadius
                          }
                          DB_LeftSidebar_MenuItem_SortierungTop_BorderBottomRightRadius={
                            DB_LeftSidebar_MenuItem_SortierungTop_BorderBottomRightRadius
                          }
                          DB_LeftSidebar_MenuItem_SortierungTop_BorderBottomLeftRadius={
                            DB_LeftSidebar_MenuItem_SortierungTop_BorderBottomLeftRadius
                          }
                          DB_LeftSidebar_MenuItem_SortierungTop_ShadowHorizontal={
                            DB_LeftSidebar_MenuItem_SortierungTop_ShadowHorizontal
                          }
                          DB_LeftSidebar_MenuItem_SortierungTop_ShadowVertical={
                            DB_LeftSidebar_MenuItem_SortierungTop_ShadowVertical
                          }
                          DB_LeftSidebar_MenuItem_SortierungTop_ShadowBlur={
                            DB_LeftSidebar_MenuItem_SortierungTop_ShadowBlur
                          }
                          DB_LeftSidebar_MenuItem_SortierungTop_ShadowColor={
                            DB_LeftSidebar_MenuItem_SortierungTop_ShadowColor
                          }
                          DB_LeftSidebar_MenuItem_SortierungTop_Hover_BorderTop={
                            DB_LeftSidebar_MenuItem_SortierungTop_Hover_BorderTop
                          }
                          DB_LeftSidebar_MenuItem_SortierungTop_Hover_BorderStyleTop={
                            DB_LeftSidebar_MenuItem_SortierungTop_Hover_BorderStyleTop
                          }
                          DB_LeftSidebar_MenuItem_SortierungTop_Hover_BorderColorTop={
                            DB_LeftSidebar_MenuItem_SortierungTop_Hover_BorderColorTop
                          }
                          DB_LeftSidebar_MenuItem_SortierungTop_Hover_BorderBottom={
                            DB_LeftSidebar_MenuItem_SortierungTop_Hover_BorderBottom
                          }
                          DB_LeftSidebar_MenuItem_SortierungTop_Hover_BorderStyleBottom={
                            DB_LeftSidebar_MenuItem_SortierungTop_Hover_BorderStyleBottom
                          }
                          DB_LeftSidebar_MenuItem_SortierungTop_Hover_BorderColorBottom={
                            DB_LeftSidebar_MenuItem_SortierungTop_Hover_BorderColorBottom
                          }
                          DB_LeftSidebar_MenuItem_SortierungTop_Hover_BorderLeft={
                            DB_LeftSidebar_MenuItem_SortierungTop_Hover_BorderLeft
                          }
                          DB_LeftSidebar_MenuItem_SortierungTop_Hover_BorderStyleLeft={
                            DB_LeftSidebar_MenuItem_SortierungTop_Hover_BorderStyleLeft
                          }
                          DB_LeftSidebar_MenuItem_SortierungTop_Hover_BorderColorLeft={
                            DB_LeftSidebar_MenuItem_SortierungTop_Hover_BorderColorLeft
                          }
                          DB_LeftSidebar_MenuItem_SortierungTop_Hover_BorderRight={
                            DB_LeftSidebar_MenuItem_SortierungTop_Hover_BorderRight
                          }
                          DB_LeftSidebar_MenuItem_SortierungTop_Hover_BorderStyleRight={
                            DB_LeftSidebar_MenuItem_SortierungTop_Hover_BorderStyleRight
                          }
                          DB_LeftSidebar_MenuItem_SortierungTop_Hover_BorderColorRight={
                            DB_LeftSidebar_MenuItem_SortierungTop_Hover_BorderColorRight
                          }
                          DB_LeftSidebar_MenuItem_SortierungTop_Hover_BorderTopRightRadius={
                            DB_LeftSidebar_MenuItem_SortierungTop_Hover_BorderTopRightRadius
                          }
                          DB_LeftSidebar_MenuItem_SortierungTop_Hover_BorderTopLeftRadius={
                            DB_LeftSidebar_MenuItem_SortierungTop_Hover_BorderTopLeftRadius
                          }
                          DB_LeftSidebar_MenuItem_SortierungTop_Hover_BorderBottomRightRadius={
                            DB_LeftSidebar_MenuItem_SortierungTop_Hover_BorderBottomRightRadius
                          }
                          DB_LeftSidebar_MenuItem_SortierungTop_Hover_BorderBottomLeftRadius={
                            DB_LeftSidebar_MenuItem_SortierungTop_Hover_BorderBottomLeftRadius
                          }
                          DB_LeftSidebar_MenuItem_SortierungTop_Hover_ShadowHorizontal={
                            DB_LeftSidebar_MenuItem_SortierungTop_Hover_ShadowHorizontal
                          }
                          DB_LeftSidebar_MenuItem_SortierungTop_Hover_ShadowVertical={
                            DB_LeftSidebar_MenuItem_SortierungTop_Hover_ShadowVertical
                          }
                          DB_LeftSidebar_MenuItem_SortierungTop_Hover_ShadowBlur={
                            DB_LeftSidebar_MenuItem_SortierungTop_Hover_ShadowBlur
                          }
                          DB_LeftSidebar_MenuItem_SortierungTop_Hover_ShadowColor={
                            DB_LeftSidebar_MenuItem_SortierungTop_Hover_ShadowColor
                          }
                          DB_LeftSidebar_MenuItem_SortierungTop_Hover_FontColor={
                            DB_LeftSidebar_MenuItem_SortierungTop_Hover_FontColor
                          }
                          DB_LeftSidebar_MenuItem_SortierungTop_Hover_BackgroundColor={
                            DB_LeftSidebar_MenuItem_SortierungTop_Hover_BackgroundColor
                          }
                        />
                      )}

                      {bottomMainCategoriesVisible && !isMobile && (
                        <Navbar expand="lg">
                          <HauptMenueTop
                            ultraCategoriesNew={ultraCategoriesNew}
                            categoryLeftSideHandler={(e) => {
                              categoryLeftSideHandler(e);
                            }}
                            DB_fontFace_FontFamilyName={
                              DB_fontFace_FontFamilyName
                            }
                            DB_LeftSidebar_MenuItem_MenuContainerAA_Verteilung={
                              DB_LeftSidebar_MenuItem_MenuContainerAA_Verteilung
                            }
                            SideBarLeft_HauptmenuTopAA_Farbe_Groesse_Stil_OnMouseOverAndOut={
                              SideBarLeft_HauptmenuTop_Farbe_Groesse_Stil_OnMouseOverAndOut
                            }
                            SideBarLeft_HauptmenuTopAA_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut={
                              SideBarLeft_HauptmenuTop_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
                            }
                            SideBarLeft_HauptmenuTopAA_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut={
                              SideBarLeft_HauptmenuTop_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut
                            }
                            SideBarLeft_HauptmenuTopAA_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut={
                              SideBarLeft_HauptmenuTop_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut
                            }
                            SideBarLeft_ContainerTopAA_Farbe_Groesse_Stil_OnMouseOverAndOut={
                              SideBarLeft_ContainerTop_Farbe_Groesse_Stil_OnMouseOverAndOut
                            }
                            SideBarLeft_ContainerTopAA_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut={
                              SideBarLeft_ContainerTop_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
                            }
                            SideBarLeft_ContainerTopAA_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut={
                              SideBarLeft_ContainerTop_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut
                            }
                            SideBarLeft_ContainerTopAA_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut={
                              SideBarLeft_ContainerTop_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut
                            }
                            DB_fontFace_hauptMenueTopAA_FontFamilyName={
                              DB_fontFace_hauptMenueTopAA_FontFamilyName
                            }
                            DB_fontFace_hauptMenueTopAA_FontFamilySrc={
                              DB_fontFace_hauptMenueTopAA_FontFamilySrc
                            }
                            DB_LeftSidebar_MenuContainerAA_BackgroundColor={
                              DB_LeftSidebar_MenuContainerAA_BackgroundColor
                            }
                            DB_LeftSidebar_MenuContainerAA_PaddingTopBottom={
                              DB_LeftSidebar_MenuContainerAA_PaddingTopBottom
                            }
                            DB_LeftSidebar_MenuContainerAA_PaddingLeftRight={
                              DB_LeftSidebar_MenuContainerAA_PaddingLeftRight
                            }
                            DB_LeftSidebar_MenuContainerAA_BorderTop={
                              DB_LeftSidebar_MenuContainerAA_BorderTop
                            }
                            DB_LeftSidebar_MenuContainerAA_BorderStyleTop={
                              DB_LeftSidebar_MenuContainerAA_BorderStyleTop
                            }
                            DB_LeftSidebar_MenuContainerAA_BorderColorTop={
                              DB_LeftSidebar_MenuContainerAA_BorderColorTop
                            }
                            DB_LeftSidebar_MenuContainerAA_BorderBottom={
                              DB_LeftSidebar_MenuContainerAA_BorderBottom
                            }
                            DB_LeftSidebar_MenuContainerAA_BorderStyleBottom={
                              DB_LeftSidebar_MenuContainerAA_BorderStyleBottom
                            }
                            DB_LeftSidebar_MenuContainerAA_BorderColorBottom={
                              DB_LeftSidebar_MenuContainerAA_BorderColorBottom
                            }
                            DB_LeftSidebar_MenuContainerAA_BorderLeft={
                              DB_LeftSidebar_MenuContainerAA_BorderLeft
                            }
                            DB_LeftSidebar_MenuContainerAA_BorderStyleLeft={
                              DB_LeftSidebar_MenuContainerAA_BorderStyleLeft
                            }
                            DB_LeftSidebar_MenuContainerAA_BorderColorLeft={
                              DB_LeftSidebar_MenuContainerAA_BorderColorLeft
                            }
                            DB_LeftSidebar_MenuContainerAA_BorderRight={
                              DB_LeftSidebar_MenuContainerAA_BorderRight
                            }
                            DB_LeftSidebar_MenuContainerAA_BorderStyleRight={
                              DB_LeftSidebar_MenuContainerAA_BorderStyleRight
                            }
                            DB_LeftSidebar_MenuContainerAA_BorderColorRight={
                              DB_LeftSidebar_MenuContainerAA_BorderColorRight
                            }
                            DB_LeftSidebar_MenuItem_MenuContainerAA_BorderTopRightRadius={
                              DB_LeftSidebar_MenuItem_MenuContainerAA_BorderTopRightRadius
                            }
                            DB_LeftSidebar_MenuItem_MenuContainerAA_BorderTopLeftRadius={
                              DB_LeftSidebar_MenuItem_MenuContainerAA_BorderTopLeftRadius
                            }
                            DB_LeftSidebar_MenuItem_MenuContainerAA_BorderBottomRightRadius={
                              DB_LeftSidebar_MenuItem_MenuContainerAA_BorderBottomRightRadius
                            }
                            DB_LeftSidebar_MenuItem_MenuContainerAA_BorderBottomLeftRadius={
                              DB_LeftSidebar_MenuItem_MenuContainerAA_BorderBottomLeftRadius
                            }
                            DB_LeftSidebar_MenuItem_MenuContainerAA_paddingLeft={
                              DB_LeftSidebar_MenuItem_MenuContainerAA_paddingLeft
                            }
                            DB_LeftSidebar_MenuItem_MenuContainerAA_paddingRight={
                              DB_LeftSidebar_MenuItem_MenuContainerAA_paddingRight
                            }
                            DB_LeftSidebar_MenuItem_MenuContainerAA_paddingTop={
                              DB_LeftSidebar_MenuItem_MenuContainerAA_paddingTop
                            }
                            DB_LeftSidebar_MenuItem_MenuContainerAA_paddingBottom={
                              DB_LeftSidebar_MenuItem_MenuContainerAA_paddingBottom
                            }
                            DB_LeftSidebar_MenuItem_MenuContainerAA_ShadowHorizontal={
                              DB_LeftSidebar_MenuItem_MenuContainerAA_ShadowHorizontal
                            }
                            DB_LeftSidebar_MenuItem_MenuContainerAA_ShadowVertical={
                              DB_LeftSidebar_MenuItem_MenuContainerAA_ShadowVertical
                            }
                            DB_LeftSidebar_MenuItem_MenuContainerAA_ShadowBlur={
                              DB_LeftSidebar_MenuItem_MenuContainerAA_ShadowBlur
                            }
                            DB_LeftSidebar_MenuItem_MenuContainerAA_ShadowColor={
                              DB_LeftSidebar_MenuItem_MenuContainerAA_ShadowColor
                            }
                            DB_LeftSidebar_MenuContainerAA_Hover_BackgroundColor={
                              DB_LeftSidebar_MenuContainerAA_Hover_BackgroundColor
                            }
                            DB_LeftSidebar_MenuContainerAA_Hover_PaddingTopBottom={
                              DB_LeftSidebar_MenuContainerAA_Hover_PaddingTopBottom
                            }
                            DB_LeftSidebar_MenuContainerAA_Hover_PaddingLeftRight={
                              DB_LeftSidebar_MenuContainerAA_Hover_PaddingLeftRight
                            }
                            DB_LeftSidebar_MenuContainerAA_Hover_BorderTop={
                              DB_LeftSidebar_MenuContainerAA_Hover_BorderTop
                            }
                            DB_LeftSidebar_MenuContainerAA_Hover_BorderStyleTop={
                              DB_LeftSidebar_MenuContainerAA_Hover_BorderStyleTop
                            }
                            DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderStyleTop={
                              DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderStyleTop
                            }
                            DB_LeftSidebar_MenuContainerAA_Hover_BorderColorTop={
                              DB_LeftSidebar_MenuContainerAA_Hover_BorderColorTop
                            }
                            DB_LeftSidebar_MenuContainerAA_Hover_BorderBottom={
                              DB_LeftSidebar_MenuContainerAA_Hover_BorderBottom
                            }
                            DB_LeftSidebar_MenuContainerAA_Hover_BorderStyleBottom={
                              DB_LeftSidebar_MenuContainerAA_Hover_BorderStyleBottom
                            }
                            DB_LeftSidebar_MenuContainerAA_Hover_BorderColorBottom={
                              DB_LeftSidebar_MenuContainerAA_Hover_BorderColorBottom
                            }
                            DB_LeftSidebar_MenuContainerAA_Hover_BorderLeft={
                              DB_LeftSidebar_MenuContainerAA_Hover_BorderLeft
                            }
                            DB_LeftSidebar_MenuContainerAA_Hover_BorderStyleLeft={
                              DB_LeftSidebar_MenuContainerAA_Hover_BorderStyleLeft
                            }
                            DB_LeftSidebar_MenuContainerAA_Hover_BorderColorLeft={
                              DB_LeftSidebar_MenuContainerAA_Hover_BorderColorLeft
                            }
                            DB_LeftSidebar_MenuContainerAA_Hover_BorderRight={
                              DB_LeftSidebar_MenuContainerAA_Hover_BorderRight
                            }
                            DB_LeftSidebar_MenuContainerAA_Hover_BorderStyleRight={
                              DB_LeftSidebar_MenuContainerAA_Hover_BorderStyleRight
                            }
                            DB_LeftSidebar_MenuContainerAA_Hover_BorderColorRight={
                              DB_LeftSidebar_MenuContainerAA_Hover_BorderColorRight
                            }
                            DB_LeftSidebar_MenuItem_MenuContainerAA_Hover_BorderTopRightRadius={
                              DB_LeftSidebar_MenuItem_MenuContainerAA_Hover_BorderTopRightRadius
                            }
                            DB_LeftSidebar_MenuItem_MenuContainerAA_Hover_BorderTopLeftRadius={
                              DB_LeftSidebar_MenuItem_MenuContainerAA_Hover_BorderTopLeftRadius
                            }
                            DB_LeftSidebar_MenuItem_MenuContainerAA_Hover_BorderBottomRightRadius={
                              DB_LeftSidebar_MenuItem_MenuContainerAA_Hover_BorderBottomRightRadius
                            }
                            DB_LeftSidebar_MenuItem_MenuContainerAA_Hover_BorderBottomLeftRadius={
                              DB_LeftSidebar_MenuItem_MenuContainerAA_Hover_BorderBottomLeftRadius
                            }
                            DB_LeftSidebar_MenuItem_MenuContainerAA_Hover_paddingLeft={
                              DB_LeftSidebar_MenuItem_MenuContainerAA_Hover_paddingLeft
                            }
                            DB_LeftSidebar_MenuItem_MenuContainerAA_Hover_paddingRight={
                              DB_LeftSidebar_MenuItem_MenuContainerAA_Hover_paddingRight
                            }
                            DB_LeftSidebar_MenuItem_MenuContainerAA_Hover_paddingTop={
                              DB_LeftSidebar_MenuItem_MenuContainerAA_Hover_paddingTop
                            }
                            DB_LeftSidebar_MenuItem_MenuContainerAA_Hover_paddingBottom={
                              DB_LeftSidebar_MenuItem_MenuContainerAA_Hover_paddingBottom
                            }
                            DB_LeftSidebar_MenuItem_MenuContainerAA_Hover_ShadowHorizontal={
                              DB_LeftSidebar_MenuItem_MenuContainerAA_Hover_ShadowHorizontal
                            }
                            DB_LeftSidebar_MenuItem_MenuContainerAA_Hover_ShadowVertical={
                              DB_LeftSidebar_MenuItem_MenuContainerAA_Hover_ShadowVertical
                            }
                            DB_LeftSidebar_MenuItem_MenuContainerAA_Hover_ShadowBlur={
                              DB_LeftSidebar_MenuItem_MenuContainerAA_Hover_ShadowBlur
                            }
                            DB_LeftSidebar_MenuItem_MenuContainerAA_Hover_ShadowColor={
                              DB_LeftSidebar_MenuItem_MenuContainerAA_Hover_ShadowColor
                            }
                            DB_LeftSidebar_MenuItem_HauptmenuTopAA_FontWeight={
                              DB_LeftSidebar_MenuItem_HauptmenuTopAA_FontWeight
                            }
                            DB_LeftSidebar_MenuItem_HauptmenuTopAA_FontColor={
                              DB_LeftSidebar_MenuItem_HauptmenuTopAA_FontColor
                            }
                            DB_LeftSidebar_MenuItem_HauptmenuTopAA_FontSize={
                              DB_LeftSidebar_MenuItem_HauptmenuTopAA_FontSize
                            }
                            DB_LeftSidebar_MenuItem_HauptmenuTopAA_BackgroundColor={
                              DB_LeftSidebar_MenuItem_HauptmenuTopAA_BackgroundColor
                            }
                            DB_LeftSidebar_MenuItem_HauptmenuTopAA_TextAlign={
                              DB_LeftSidebar_MenuItem_HauptmenuTopAA_TextAlign
                            }
                            DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderTop={
                              DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderTop
                            }
                            DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderStyleTop={
                              DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderStyleTop
                            }
                            DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderColorTop={
                              DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderColorTop
                            }
                            DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderBottom={
                              DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderBottom
                            }
                            DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderBottom={
                              DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderBottom
                            }
                            DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderStyleBottom={
                              DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderStyleBottom
                            }
                            DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderColorBottom={
                              DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderColorBottom
                            }
                            DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderLeft={
                              DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderLeft
                            }
                            DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderStyleLeft={
                              DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderStyleLeft
                            }
                            DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderColorLeft={
                              DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderColorLeft
                            }
                            DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderRight={
                              DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderRight
                            }
                            DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderStyleRight={
                              DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderStyleRight
                            }
                            DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderColorRight={
                              DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderColorRight
                            }
                            DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderTopRightRadius={
                              DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderTopRightRadius
                            }
                            DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderTopLeftRadius={
                              DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderTopLeftRadius
                            }
                            DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderBottomRightRadius={
                              DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderBottomRightRadius
                            }
                            DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderBottomLeftRadius={
                              DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderBottomLeftRadius
                            }
                            DB_LeftSidebar_MenuItem_HauptmenuTopAA_marginLeftRight={
                              DB_LeftSidebar_MenuItem_HauptmenuTopAA_marginLeftRight
                            }
                            DB_LeftSidebar_MenuItem_HauptmenuTopAA_marginTopBottom={
                              DB_LeftSidebar_MenuItem_HauptmenuTopAA_marginTopBottom
                            }
                            DB_LeftSidebar_MenuItem_HauptmenuTopAA_paddingLeftRight={
                              DB_LeftSidebar_MenuItem_HauptmenuTopAA_paddingLeftRight
                            }
                            DB_LeftSidebar_MenuItem_HauptmenuTopAA_paddingTopBottom={
                              DB_LeftSidebar_MenuItem_HauptmenuTopAA_paddingTopBottom
                            }
                            DB_LeftSidebar_MenuItem_HauptmenuTopAA_letterSpacing={
                              DB_LeftSidebar_MenuItem_HauptmenuTopAA_letterSpacing
                            }
                            DB_LeftSidebar_MenuItem_HauptmenuTopAA_ShadowHorizontal={
                              DB_LeftSidebar_MenuItem_HauptmenuTopAA_ShadowHorizontal
                            }
                            DB_LeftSidebar_MenuItem_HauptmenuTopAA_ShadowVertical={
                              DB_LeftSidebar_MenuItem_HauptmenuTopAA_ShadowVertical
                            }
                            DB_LeftSidebar_MenuItem_HauptmenuTopAA_ShadowBlur={
                              DB_LeftSidebar_MenuItem_HauptmenuTopAA_ShadowBlur
                            }
                            DB_LeftSidebar_MenuItem_HauptmenuTopAA_ShadowColor={
                              DB_LeftSidebar_MenuItem_HauptmenuTopAA_ShadowColor
                            }
                            DB_LeftSidebar_MenuItem_HauptmenuTopAA_TextDecoration={
                              DB_LeftSidebar_MenuItem_HauptmenuTopAA_TextDecoration
                            }
                            DB_LeftSidebar_MenuItem_HauptmenuTopAA_TextDecorationStyle={
                              DB_LeftSidebar_MenuItem_HauptmenuTopAA_TextDecorationStyle
                            }
                            DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_FontWeight={
                              DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_FontWeight
                            }
                            DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_FontColor={
                              DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_FontColor
                            }
                            DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_FontSize={
                              DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_FontSize
                            }
                            DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BackgroundColor={
                              DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BackgroundColor
                            }
                            DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_TextAlign={
                              DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_TextAlign
                            }
                            DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderTop={
                              DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderTop
                            }
                            DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderColorTop={
                              DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderColorTop
                            }
                            DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderColorBottom={
                              DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderColorBottom
                            }
                            DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderStyleBottom={
                              DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderStyleBottom
                            }
                            DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderLeft={
                              DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderLeft
                            }
                            DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderStyleLeft={
                              DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderStyleLeft
                            }
                            DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderColorLeft={
                              DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderColorLeft
                            }
                            DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderColorRight={
                              DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderColorRight
                            }
                            DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderRight={
                              DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderRight
                            }
                            DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderStyleRight={
                              DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderStyleRight
                            }
                            DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderTopRightRadius={
                              DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderTopRightRadius
                            }
                            DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderTopLeftRadius={
                              DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderTopLeftRadius
                            }
                            DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderBottomRightRadius={
                              DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderBottomRightRadius
                            }
                            DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderBottomLeftRadius={
                              DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderBottomLeftRadius
                            }
                            DB_LeftSidebar_MenuItem_HauptmenuTopAA_marginLeftRight_Hover={
                              DB_LeftSidebar_MenuItem_HauptmenuTopAA_marginLeftRight_Hover
                            }
                            DB_LeftSidebar_MenuItem_HauptmenuTopAA_marginTopBottom_Hover={
                              DB_LeftSidebar_MenuItem_HauptmenuTopAA_marginTopBottom_Hover
                            }
                            DB_LeftSidebar_MenuItem_HauptmenuTopAA_paddingLeftRight_Hover={
                              DB_LeftSidebar_MenuItem_HauptmenuTopAA_paddingLeftRight_Hover
                            }
                            DB_LeftSidebar_MenuItem_HauptmenuTopAA_paddingTopBottom_Hover={
                              DB_LeftSidebar_MenuItem_HauptmenuTopAA_paddingTopBottom_Hover
                            }
                            DB_LeftSidebar_MenuItem_HauptmenuTopAA_letterSpacing_Hover={
                              DB_LeftSidebar_MenuItem_HauptmenuTopAA_letterSpacing_Hover
                            }
                            DB_LeftSidebar_MenuItem_HauptmenuTopAA_ShadowHorizontal_Hover={
                              DB_LeftSidebar_MenuItem_HauptmenuTopAA_ShadowHorizontal_Hover
                            }
                            DB_LeftSidebar_MenuItem_HauptmenuTopAA_ShadowVertical_Hover={
                              DB_LeftSidebar_MenuItem_HauptmenuTopAA_ShadowVertical_Hover
                            }
                            DB_LeftSidebar_MenuItem_HauptmenuTopAA_ShadowBlur_Hover={
                              DB_LeftSidebar_MenuItem_HauptmenuTopAA_ShadowBlur_Hover
                            }
                            DB_LeftSidebar_MenuItem_HauptmenuTopAA_ShadowColor_Hover={
                              DB_LeftSidebar_MenuItem_HauptmenuTopAA_ShadowColor_Hover
                            }
                            DB_LeftSidebar_MenuItem_HauptmenuTopAA_TextDecoration_Hover={
                              DB_LeftSidebar_MenuItem_HauptmenuTopAA_TextDecoration_Hover
                            }
                            DB_LeftSidebar_MenuItem_HauptmenuTopAA_TextDecorationStyle_Hover={
                              DB_LeftSidebar_MenuItem_HauptmenuTopAA_TextDecorationStyle_Hover
                            }
                          />
                        </Navbar>
                      )}
                      {TopBarIsVisibleBottom && !isMobile && (
                        <>
                          <hr
                            style={{
                              marginTop: "20px",
                              marginBottom: "5px",
                              width: "95%",
                              marginLeft: "auto",
                              marginRight: "auto",
                              color: "rgba(0,0,0,0.5)",
                              border: "1px solid white",
                              borderRadius: "5px",
                            }}
                          />
                          <Navbar
                            expand="lg"
                            className="p-0"
                            style={{
                              width: "95%",
                              marginLeft: "auto",
                              marginRight: "auto",
                            }}
                          >
                            <TopBarStaticBottom
                              meldenModalHandler={meldenModalHandler}
                              faqDetailItem={faqDetailItem}
                              TopBarIsVisibleBottom={TopBarIsVisibleBottom}
                              aboutUsShowHandler={aboutUsShowHandler}
                              faqShowHandler={faqShowHandler}
                              logoActive={logoActive}
                              countImagePlusTwo={countImagePlusTwo}
                              shopName={shopName}
                              resetFilterHandler={resetFilterHandler}
                              searchValue={searchValue}
                              searchHandler={searchHandler}
                              searchPressEnter={searchPressEnter}
                              isAuthState={isAuthState}
                              signOutMessage={signOutMessage}
                              productsUserId={productsUserId}
                              showTopProductsHandler={showTopProductsHandler}
                              toggleSignIn={toggleSignIn}
                              /* handleShow_showConfig_Main={handleShow_showConfig_Main} */
                              userOwnsThisSite={userOwnsThisSite}
                              DB_fontFace_FontFamilyName={
                                DB_fontFace_FontFamilyName
                              }
                              SideBarLeft_HauptmenuTopStatic_Farbe_Groesse_Stil_Trigger={
                                SideBarLeft_HauptmenuTopStatic_Farbe_Groesse_Stil_Trigger
                              }
                              SideBarLeft_HauptmenuTopStatic_Farbe_Groesse_Stil_Hover_Trigger={
                                SideBarLeft_HauptmenuTopStatic_Farbe_Groesse_Stil_Hover_Trigger
                              }
                              SideBarLeft_HauptmenuTopStatic_HintergrundFarbe_Radius_Stil_Trigger={
                                SideBarLeft_HauptmenuTopStatic_HintergrundFarbe_Radius_Stil_Trigger
                              }
                              SideBarLeft_HauptmenuTopStatic_HintergrundFarbe_Radius_Stil_Hover_Trigger={
                                SideBarLeft_HauptmenuTopStatic_HintergrundFarbe_Radius_Stil_Hover_Trigger
                              }
                              SideBarLeft_HauptmenuTopStatic_Farbe_Groesse_Stil_OnMouseOverAndOut={
                                SideBarLeft_HauptmenuTopStatic_Farbe_Groesse_Stil_OnMouseOverAndOut
                              }
                              SideBarLeft_HauptmenuTopStatic_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut={
                                SideBarLeft_HauptmenuTopStatic_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
                              }
                              SideBarLeft_HauptmenuTopStatic_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut={
                                SideBarLeft_HauptmenuTopStatic_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut
                              }
                              SideBarLeft_HauptmenuTopStatic_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut={
                                SideBarLeft_HauptmenuTopStatic_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut
                              }
                              DB_fontFace_hauptMenueStatic_FontFamilyName={
                                DB_fontFace_hauptMenueStatic_FontFamilyName
                              }
                              DB_fontFace_hauptMenueStatic_FontFamilySrc={
                                DB_fontFace_hauptMenueStatic_FontFamilySrc
                              }
                              DB_LeftSidebar_MenuItem_HauptmenuTop_FontWeight={
                                DB_LeftSidebar_MenuItem_HauptmenuTop_FontWeight
                              }
                              DB_LeftSidebar_MenuItem_HauptmenuTop_FontColor={
                                DB_LeftSidebar_MenuItem_HauptmenuTop_FontColor
                              }
                              DB_LeftSidebar_MenuItem_HauptmenuTop_FontSize={
                                DB_LeftSidebar_MenuItem_HauptmenuTop_FontSize
                              }
                              DB_LeftSidebar_MenuItem_HauptmenuTop_BackgroundColor={
                                DB_LeftSidebar_MenuItem_HauptmenuTop_BackgroundColor
                              }
                              DB_LeftSidebar_MenuItem_HauptmenuTop_TextAlign={
                                DB_LeftSidebar_MenuItem_HauptmenuTop_TextAlign
                              }
                              DB_LeftSidebar_MenuItem_HauptmenuTop_BorderTop={
                                DB_LeftSidebar_MenuItem_HauptmenuTop_BorderTop
                              }
                              DB_LeftSidebar_MenuItem_HauptmenuTop_BorderStyleTop={
                                DB_LeftSidebar_MenuItem_HauptmenuTop_BorderStyleTop
                              }
                              DB_LeftSidebar_MenuItem_HauptmenuTop_BorderColorTop={
                                DB_LeftSidebar_MenuItem_HauptmenuTop_BorderColorTop
                              }
                              DB_LeftSidebar_MenuItem_HauptmenuTop_BorderBottom={
                                DB_LeftSidebar_MenuItem_HauptmenuTop_BorderBottom
                              }
                              DB_LeftSidebar_MenuItem_HauptmenuTop_BorderStyleBottom={
                                DB_LeftSidebar_MenuItem_HauptmenuTop_BorderStyleBottom
                              }
                              DB_LeftSidebar_MenuItem_HauptmenuTop_BorderColorBottom={
                                DB_LeftSidebar_MenuItem_HauptmenuTop_BorderColorBottom
                              }
                              DB_LeftSidebar_MenuItem_HauptmenuTop_BorderLeft={
                                DB_LeftSidebar_MenuItem_HauptmenuTop_BorderLeft
                              }
                              DB_LeftSidebar_MenuItem_HauptmenuTop_BorderStyleLeft={
                                DB_LeftSidebar_MenuItem_HauptmenuTop_BorderStyleLeft
                              }
                              DB_LeftSidebar_MenuItem_HauptmenuTop_BorderColorLeft={
                                DB_LeftSidebar_MenuItem_HauptmenuTop_BorderColorLeft
                              }
                              DB_LeftSidebar_MenuItem_HauptmenuTop_BorderRight={
                                DB_LeftSidebar_MenuItem_HauptmenuTop_BorderRight
                              }
                              DB_LeftSidebar_MenuItem_HauptmenuTop_BorderStyleRight={
                                DB_LeftSidebar_MenuItem_HauptmenuTop_BorderStyleRight
                              }
                              DB_LeftSidebar_MenuItem_HauptmenuTop_BorderColorRight={
                                DB_LeftSidebar_MenuItem_HauptmenuTop_BorderColorRight
                              }
                              DB_LeftSidebar_MenuItem_HauptmenuTop_BorderTopRightRadius={
                                DB_LeftSidebar_MenuItem_HauptmenuTop_BorderTopRightRadius
                              }
                              DB_LeftSidebar_MenuItem_HauptmenuTop_BorderTopLeftRadius={
                                DB_LeftSidebar_MenuItem_HauptmenuTop_BorderTopLeftRadius
                              }
                              DB_LeftSidebar_MenuItem_HauptmenuTop_BorderBottomRightRadius={
                                DB_LeftSidebar_MenuItem_HauptmenuTop_BorderBottomRightRadius
                              }
                              DB_LeftSidebar_MenuItem_HauptmenuTop_BorderBottomLeftRadius={
                                DB_LeftSidebar_MenuItem_HauptmenuTop_BorderBottomLeftRadius
                              }
                              DB_LeftSidebar_MenuItem_HauptmenuTop_marginLeftRight={
                                DB_LeftSidebar_MenuItem_HauptmenuTop_marginLeftRight
                              }
                              DB_LeftSidebar_MenuItem_HauptmenuTop_marginTopBottom={
                                DB_LeftSidebar_MenuItem_HauptmenuTop_marginTopBottom
                              }
                              DB_LeftSidebar_MenuItem_HauptmenuTop_paddingLeftRight={
                                DB_LeftSidebar_MenuItem_HauptmenuTop_paddingLeftRight
                              }
                              DB_LeftSidebar_MenuItem_HauptmenuTop_paddingTopBottom={
                                DB_LeftSidebar_MenuItem_HauptmenuTop_paddingTopBottom
                              }
                              DB_LeftSidebar_MenuItem_HauptmenuTop_letterSpacing={
                                DB_LeftSidebar_MenuItem_HauptmenuTop_letterSpacing
                              }
                              DB_LeftSidebar_MenuItem_HauptmenuTop_ShadowHorizontal={
                                DB_LeftSidebar_MenuItem_HauptmenuTop_ShadowHorizontal
                              }
                              DB_LeftSidebar_MenuItem_HauptmenuTop_ShadowVertical={
                                DB_LeftSidebar_MenuItem_HauptmenuTop_ShadowVertical
                              }
                              DB_LeftSidebar_MenuItem_HauptmenuTop_ShadowBlur={
                                DB_LeftSidebar_MenuItem_HauptmenuTop_ShadowBlur
                              }
                              DB_LeftSidebar_MenuItem_HauptmenuTop_ShadowColor={
                                DB_LeftSidebar_MenuItem_HauptmenuTop_ShadowColor
                              }
                              DB_LeftSidebar_MenuItem_HauptmenuTop_TextDecoration={
                                DB_LeftSidebar_MenuItem_HauptmenuTop_TextDecoration
                              }
                              DB_LeftSidebar_MenuItem_HauptmenuTop_TextDecorationStyle={
                                DB_LeftSidebar_MenuItem_HauptmenuTop_TextDecorationStyle
                              }
                              DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_FontWeight={
                                DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_FontWeight
                              }
                              DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_FontColor={
                                DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_FontColor
                              }
                              DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_FontSize={
                                DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_FontSize
                              }
                              DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BackgroundColor={
                                DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BackgroundColor
                              }
                              DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_TextAlign={
                                DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_TextAlign
                              }
                              DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderTop={
                                DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderTop
                              }
                              DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderStyleTop={
                                DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderStyleTop
                              }
                              DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderColorTop={
                                DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderColorTop
                              }
                              DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderBottom={
                                DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderBottom
                              }
                              DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderStyleBottom={
                                DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderStyleBottom
                              }
                              DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderColorBottom={
                                DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderColorBottom
                              }
                              DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderLeft={
                                DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderLeft
                              }
                              DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderStyleLeft={
                                DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderStyleLeft
                              }
                              DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderColorLeft={
                                DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderColorLeft
                              }
                              DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderRight={
                                DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderRight
                              }
                              DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderStyleRight={
                                DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderStyleRight
                              }
                              DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderColorRight={
                                DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderColorRight
                              }
                              DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderTopRightRadius={
                                DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderTopRightRadius
                              }
                              DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderTopLeftRadius={
                                DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderTopLeftRadius
                              }
                              DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderBottomRightRadius={
                                DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderBottomRightRadius
                              }
                              DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderBottomLeftRadius={
                                DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderBottomLeftRadius
                              }
                              DB_LeftSidebar_MenuItem_HauptmenuTop_marginLeftRight_Hover={
                                DB_LeftSidebar_MenuItem_HauptmenuTop_marginLeftRight_Hover
                              }
                              DB_LeftSidebar_MenuItem_HauptmenuTop_marginTopBottom_Hover={
                                DB_LeftSidebar_MenuItem_HauptmenuTop_marginTopBottom_Hover
                              }
                              DB_LeftSidebar_MenuItem_HauptmenuTop_paddingLeftRight_Hover={
                                DB_LeftSidebar_MenuItem_HauptmenuTop_paddingLeftRight_Hover
                              }
                              DB_LeftSidebar_MenuItem_HauptmenuTop_paddingTopBottom_Hover={
                                DB_LeftSidebar_MenuItem_HauptmenuTop_paddingTopBottom_Hover
                              }
                              DB_LeftSidebar_MenuItem_HauptmenuTop_letterSpacing_Hover={
                                DB_LeftSidebar_MenuItem_HauptmenuTop_letterSpacing_Hover
                              }
                              DB_LeftSidebar_MenuItem_HauptmenuTop_ShadowHorizontal_Hover={
                                DB_LeftSidebar_MenuItem_HauptmenuTop_ShadowHorizontal_Hover
                              }
                              DB_LeftSidebar_MenuItem_HauptmenuTop_ShadowVertical_Hover={
                                DB_LeftSidebar_MenuItem_HauptmenuTop_ShadowVertical_Hover
                              }
                              DB_LeftSidebar_MenuItem_HauptmenuTop_ShadowBlur_Hover={
                                DB_LeftSidebar_MenuItem_HauptmenuTop_ShadowBlur_Hover
                              }
                              DB_LeftSidebar_MenuItem_HauptmenuTop_ShadowColor_Hover={
                                DB_LeftSidebar_MenuItem_HauptmenuTop_ShadowColor_Hover
                              }
                              DB_LeftSidebar_MenuItem_HauptmenuTop_TextDecoration_Hover={
                                DB_LeftSidebar_MenuItem_HauptmenuTop_TextDecoration_Hover
                              }
                              DB_LeftSidebar_MenuItem_HauptmenuTop_TextDecorationStyle_Hover={
                                DB_LeftSidebar_MenuItem_HauptmenuTop_TextDecorationStyle_Hover
                              }
                              DB_LeftSidebar_MenuItem_MenuContainer_Logo_DDpaddingLeft={
                                DB_LeftSidebar_MenuItem_MenuContainer_Logo_DDpaddingLeft
                              }
                              DB_LeftSidebar_MenuItem_MenuContainer_Logo_DDpaddingRight={
                                DB_LeftSidebar_MenuItem_MenuContainer_Logo_DDpaddingRight
                              }
                              DB_LeftSidebar_MenuItem_MenuContainer_Logo_DDpaddingTop={
                                DB_LeftSidebar_MenuItem_MenuContainer_Logo_DDpaddingTop
                              }
                              DB_LeftSidebar_MenuItem_MenuContainer_Logo_DDpaddingBottom={
                                DB_LeftSidebar_MenuItem_MenuContainer_Logo_DDpaddingBottom
                              }
                              DB_LeftSidebar_MenuItem_MenuContainer_Logo_DDShadowHorizontal={
                                DB_LeftSidebar_MenuItem_MenuContainer_Logo_DDShadowHorizontal
                              }
                              DB_LeftSidebar_MenuItem_MenuContainer_Logo_DDShadowVertical={
                                DB_LeftSidebar_MenuItem_MenuContainer_Logo_DDShadowVertical
                              }
                              DB_LeftSidebar_MenuItem_MenuContainer_Logo_DDHeight={
                                DB_LeftSidebar_MenuItem_MenuContainer_Logo_DDHeight
                              }
                              DB_LeftSidebar_MenuItem_MenuContainer_Logo_DDShadowBlur={
                                DB_LeftSidebar_MenuItem_MenuContainer_Logo_DDShadowBlur
                              }
                              DB_LeftSidebar_MenuItem_MenuContainer_Logo_DDShadowColor={
                                DB_LeftSidebar_MenuItem_MenuContainer_Logo_DDShadowColor
                              }
                              DB_LeftSidebar_MenuContainer_Logo_DDBackgroundColor={
                                DB_LeftSidebar_MenuContainer_Logo_DDBackgroundColor
                              }
                              SideBarLeft_ContainerTopXYZ_Farbe_Groesse_Stil_OnMouseOverAndOut={
                                SideBarLeft_ContainerTopXYZ_Farbe_Groesse_Stil_OnMouseOverAndOut
                              }
                              SideBarLeft_ContainerTopXYZ_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut={
                                SideBarLeft_ContainerTopXYZ_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
                              }
                              SideBarLeft_ContainerTopXYZ_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut={
                                SideBarLeft_ContainerTopXYZ_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut
                              }
                              SideBarLeft_ContainerTopXYZ_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut={
                                SideBarLeft_ContainerTopXYZ_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut
                              }
                              DB_LeftSidebar_MenuContainerXYZ_BackgroundColor={
                                DB_LeftSidebar_MenuContainerXYZ_BackgroundColor
                              }
                              DB_LeftSidebar_MenuContainerXYZ_PaddingTopBottom={
                                DB_LeftSidebar_MenuContainerXYZ_PaddingTopBottom
                              }
                              DB_LeftSidebar_MenuContainerXYZ_PaddingLeftRight={
                                DB_LeftSidebar_MenuContainerXYZ_PaddingLeftRight
                              }
                              DB_LeftSidebar_MenuContainerXYZ_BorderTop={
                                DB_LeftSidebar_MenuContainerXYZ_BorderTop
                              }
                              DB_LeftSidebar_MenuContainerXYZ_BorderStyleTop={
                                DB_LeftSidebar_MenuContainerXYZ_BorderStyleTop
                              }
                              DB_LeftSidebar_MenuContainerXYZ_BorderColorTop={
                                DB_LeftSidebar_MenuContainerXYZ_BorderColorTop
                              }
                              DB_LeftSidebar_MenuContainerXYZ_BorderBottom={
                                DB_LeftSidebar_MenuContainerXYZ_BorderBottom
                              }
                              DB_LeftSidebar_MenuContainerXYZ_BorderStyleBottom={
                                DB_LeftSidebar_MenuContainerXYZ_BorderStyleBottom
                              }
                              DB_LeftSidebar_MenuContainerXYZ_BorderColorBottom={
                                DB_LeftSidebar_MenuContainerXYZ_BorderColorBottom
                              }
                              DB_LeftSidebar_MenuContainerXYZ_BorderLeft={
                                DB_LeftSidebar_MenuContainerXYZ_BorderLeft
                              }
                              DB_LeftSidebar_MenuContainerXYZ_BorderStyleLeft={
                                DB_LeftSidebar_MenuContainerXYZ_BorderStyleLeft
                              }
                              DB_LeftSidebar_MenuContainerXYZ_BorderColorLeft={
                                DB_LeftSidebar_MenuContainerXYZ_BorderColorLeft
                              }
                              DB_LeftSidebar_MenuContainerXYZ_BorderRight={
                                DB_LeftSidebar_MenuContainerXYZ_BorderRight
                              }
                              DB_LeftSidebar_MenuContainerXYZ_BorderStyleRight={
                                DB_LeftSidebar_MenuContainerXYZ_BorderStyleRight
                              }
                              DB_LeftSidebar_MenuContainerXYZ_BorderColorRight={
                                DB_LeftSidebar_MenuContainerXYZ_BorderColorRight
                              }
                              DB_LeftSidebar_MenuItem_MenuContainerXYZ_BorderTopRightRadius={
                                DB_LeftSidebar_MenuItem_MenuContainerXYZ_BorderTopRightRadius
                              }
                              DB_LeftSidebar_MenuItem_MenuContainerXYZ_BorderTopLeftRadius={
                                DB_LeftSidebar_MenuItem_MenuContainerXYZ_BorderTopLeftRadius
                              }
                              DB_LeftSidebar_MenuItem_MenuContainerXYZ_BorderBottomRightRadius={
                                DB_LeftSidebar_MenuItem_MenuContainerXYZ_BorderBottomRightRadius
                              }
                              DB_LeftSidebar_MenuItem_MenuContainerXYZ_BorderBottomLeftRadius={
                                DB_LeftSidebar_MenuItem_MenuContainerXYZ_BorderBottomLeftRadius
                              }
                              DB_LeftSidebar_MenuItem_MenuContainerXYZ_paddingLeft={
                                DB_LeftSidebar_MenuItem_MenuContainerXYZ_paddingLeft
                              }
                              DB_LeftSidebar_MenuItem_MenuContainerXYZ_paddingRight={
                                DB_LeftSidebar_MenuItem_MenuContainerXYZ_paddingRight
                              }
                              DB_LeftSidebar_MenuItem_MenuContainerXYZ_paddingTop={
                                DB_LeftSidebar_MenuItem_MenuContainerXYZ_paddingTop
                              }
                              DB_LeftSidebar_MenuItem_MenuContainerXYZ_paddingBottom={
                                DB_LeftSidebar_MenuItem_MenuContainerXYZ_paddingBottom
                              }
                              DB_LeftSidebar_MenuItem_MenuContainerXYZ_ShadowHorizontal={
                                DB_LeftSidebar_MenuItem_MenuContainerXYZ_ShadowHorizontal
                              }
                              DB_LeftSidebar_MenuItem_MenuContainerXYZ_ShadowVertical={
                                DB_LeftSidebar_MenuItem_MenuContainerXYZ_ShadowVertical
                              }
                              DB_LeftSidebar_MenuItem_MenuContainerXYZ_ShadowBlur={
                                DB_LeftSidebar_MenuItem_MenuContainerXYZ_ShadowBlur
                              }
                              DB_LeftSidebar_MenuItem_MenuContainerXYZ_ShadowColor={
                                DB_LeftSidebar_MenuItem_MenuContainerXYZ_ShadowColor
                              }
                              DB_LeftSidebar_MenuContainerXYZ_Hover_BackgroundColor={
                                DB_LeftSidebar_MenuContainerXYZ_Hover_BackgroundColor
                              }
                              DB_LeftSidebar_MenuContainerXYZ_Hover_PaddingTopBottom={
                                DB_LeftSidebar_MenuContainerXYZ_Hover_PaddingTopBottom
                              }
                              DB_LeftSidebar_MenuContainerXYZ_Hover_PaddingLeftRight={
                                DB_LeftSidebar_MenuContainerXYZ_Hover_PaddingLeftRight
                              }
                              DB_LeftSidebar_MenuContainerXYZ_Hover_BorderTop={
                                DB_LeftSidebar_MenuContainerXYZ_Hover_BorderTop
                              }
                              DB_LeftSidebar_MenuContainerXYZ_Hover_BorderStyleTop={
                                DB_LeftSidebar_MenuContainerXYZ_Hover_BorderStyleTop
                              }
                              DB_LeftSidebar_MenuContainerXYZ_Hover_BorderColorTop={
                                DB_LeftSidebar_MenuContainerXYZ_Hover_BorderColorTop
                              }
                              DB_LeftSidebar_MenuContainerXYZ_Hover_BorderBottom={
                                DB_LeftSidebar_MenuContainerXYZ_Hover_BorderBottom
                              }
                              DB_LeftSidebar_MenuContainerXYZ_Hover_BorderStyleBottom={
                                DB_LeftSidebar_MenuContainerXYZ_Hover_BorderStyleBottom
                              }
                              DB_LeftSidebar_MenuContainerXYZ_Hover_BorderColorBottom={
                                DB_LeftSidebar_MenuContainerXYZ_Hover_BorderColorBottom
                              }
                              DB_LeftSidebar_MenuContainerXYZ_Hover_BorderLeft={
                                DB_LeftSidebar_MenuContainerXYZ_Hover_BorderLeft
                              }
                              DB_LeftSidebar_MenuContainerXYZ_Hover_BorderStyleLeft={
                                DB_LeftSidebar_MenuContainerXYZ_Hover_BorderStyleLeft
                              }
                              DB_LeftSidebar_MenuContainerXYZ_Hover_BorderColorLeft={
                                DB_LeftSidebar_MenuContainerXYZ_Hover_BorderColorLeft
                              }
                              DB_LeftSidebar_MenuContainerXYZ_Hover_BorderRight={
                                DB_LeftSidebar_MenuContainerXYZ_Hover_BorderRight
                              }
                              DB_LeftSidebar_MenuContainerXYZ_Hover_BorderStyleRight={
                                DB_LeftSidebar_MenuContainerXYZ_Hover_BorderStyleRight
                              }
                              DB_LeftSidebar_MenuContainerXYZ_Hover_BorderColorRight={
                                DB_LeftSidebar_MenuContainerXYZ_Hover_BorderColorRight
                              }
                              DB_LeftSidebar_MenuItem_MenuContainerXYZ_Hover_BorderTopRightRadius={
                                DB_LeftSidebar_MenuItem_MenuContainerXYZ_Hover_BorderTopRightRadius
                              }
                              DB_LeftSidebar_MenuItem_MenuContainerXYZ_Hover_BorderTopLeftRadius={
                                DB_LeftSidebar_MenuItem_MenuContainerXYZ_Hover_BorderTopLeftRadius
                              }
                              DB_LeftSidebar_MenuItem_MenuContainerXYZ_Hover_BorderBottomRightRadius={
                                DB_LeftSidebar_MenuItem_MenuContainerXYZ_Hover_BorderBottomRightRadius
                              }
                              DB_LeftSidebar_MenuItem_MenuContainerXYZ_Hover_BorderBottomLeftRadius={
                                DB_LeftSidebar_MenuItem_MenuContainerXYZ_Hover_BorderBottomLeftRadius
                              }
                              DB_LeftSidebar_MenuItem_MenuContainerXYZ_Hover_paddingLeft={
                                DB_LeftSidebar_MenuItem_MenuContainerXYZ_Hover_paddingLeft
                              }
                              DB_LeftSidebar_MenuItem_MenuContainerXYZ_Hover_paddingRight={
                                DB_LeftSidebar_MenuItem_MenuContainerXYZ_Hover_paddingRight
                              }
                              DB_LeftSidebar_MenuItem_MenuContainerXYZ_Hover_paddingTop={
                                DB_LeftSidebar_MenuItem_MenuContainerXYZ_Hover_paddingTop
                              }
                              DB_LeftSidebar_MenuItem_MenuContainerXYZ_Hover_paddingBottom={
                                DB_LeftSidebar_MenuItem_MenuContainerXYZ_Hover_paddingBottom
                              }
                              DB_LeftSidebar_MenuItem_MenuContainerXYZ_Hover_ShadowHorizontal={
                                DB_LeftSidebar_MenuItem_MenuContainerXYZ_Hover_ShadowHorizontal
                              }
                              DB_LeftSidebar_MenuItem_MenuContainerXYZ_Hover_ShadowVertical={
                                DB_LeftSidebar_MenuItem_MenuContainerXYZ_Hover_ShadowVertical
                              }
                              DB_LeftSidebar_MenuItem_MenuContainerXYZ_Hover_ShadowBlur={
                                DB_LeftSidebar_MenuItem_MenuContainerXYZ_Hover_ShadowBlur
                              }
                              DB_LeftSidebar_MenuItem_MenuContainerXYZ_Hover_ShadowColor={
                                DB_LeftSidebar_MenuItem_MenuContainerXYZ_Hover_ShadowColor
                              }
                            />
                          </Navbar>
                        </>
                      )}
                    </>
                  ) : productDetailShow && !aboutUsShow && !faqShow ? (
                    <BodyProductDetail
                      logoActive={logoActive}
                      updateDetailProduct={updateDetailProduct}
                      language={language}
                      traceHandler={traceHandler}
                      userOwnsThisSite={userOwnsThisSite}
                      hideProductDetailsHandler={hideProductDetailsHandler}
                      productDetailItem={productDetailItem}
                      images={images}
                      shopName={shopName}
                      setIsAuthState={setIsAuthState}
                      DB_Waehrung_Global={DB_Waehrung_Global}
                      DB_fontFace_FontFamilyName={DB_fontFace_FontFamilyName}
                      DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_FontFamilyName={
                        DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_FontFamilyName
                      }
                      DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_FontFamilySrc={
                        DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_FontFamilySrc
                      }
                      setSideBarLeft_ContainerSocial_Farbe_Groesse_Stil_OnMouseOverAndOut_Handler={
                        setSideBarLeft_ContainerSocial_Farbe_Groesse_Stil_OnMouseOverAndOut_Handler
                      }
                      setSideBarLeft_ContainerSocial_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut_Handler={
                        setSideBarLeft_ContainerSocial_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut_Handler
                      }
                      setSideBarLeft_AlleSocial_Farbe_Groesse_Stil_OnMouseOverAndOut_Handler={
                        setSideBarLeft_AlleSocial_Farbe_Groesse_Stil_OnMouseOverAndOut_Handler
                      }
                      setSideBarLeft_AlleSocial_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut_Handler={
                        setSideBarLeft_AlleSocial_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut_Handler
                      }
                      setSideBarLeft_DetailItem_Farbe_Groesse_Stil_OnMouseOverAndOut_Handler={
                        setSideBarLeft_DetailItem_Farbe_Groesse_Stil_OnMouseOverAndOut_Handler
                      }
                      setSideBarLeft_DetailItem_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut_Handler={
                        setSideBarLeft_DetailItem_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut_Handler
                      }
                      SideBarLeft_ContainerDetail_Farbe_Groesse_Stil_OnMouseOverAndOut={
                        SideBarLeft_ContainerDetail_Farbe_Groesse_Stil_OnMouseOverAndOut
                      }
                      SideBarLeft_ContainerDetail_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut={
                        SideBarLeft_ContainerDetail_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut
                      }
                      SideBarLeft_AlleDetail_Farbe_Groesse_Stil_OnMouseOverAndOut={
                        SideBarLeft_AlleDetail_Farbe_Groesse_Stil_OnMouseOverAndOut
                      }
                      SideBarLeft_AlleDetail_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut={
                        SideBarLeft_AlleDetail_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut
                      }
                      SideBarLeft_DetailItem_Farbe_Groesse_Stil_OnMouseOverAndOut={
                        SideBarLeft_DetailItem_Farbe_Groesse_Stil_OnMouseOverAndOut
                      }
                      SideBarLeft_DetailItem_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut={
                        SideBarLeft_DetailItem_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut
                      }
                      SideBarLeft_DetailSuper_Farbe_Groesse_Stil_OnMouseOverAndOut={
                        SideBarLeft_DetailSuper_Farbe_Groesse_Stil_OnMouseOverAndOut
                      }
                      SideBarLeft_DetailSuper_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut={
                        SideBarLeft_DetailSuper_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut
                      }
                      SideBarLeft_TitelKontaktFormularTitel_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut={
                        SideBarLeft_TitelKontaktFormularTitel_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut
                      }
                      SideBarLeft_TitelKontaktFormularTitel_Farbe_Groesse_Stil_OnMouseOverAndOut={
                        SideBarLeft_TitelKontaktFormularTitel_Farbe_Groesse_Stil_OnMouseOverAndOut
                      }
                      SideBarLeft_FormInputDetail_Farbe_Groesse_Stil_OnMouseOverAndOut={
                        SideBarLeft_FormInputDetail_Farbe_Groesse_Stil_OnMouseOverAndOut
                      }
                      DB_LeftSidebar_MenuItem_TitelSuperTitel_FontWeight={
                        DB_LeftSidebar_MenuItem_TitelSuperTitel_FontWeight
                      }
                      DB_LeftSidebar_MenuItem_TitelSuperTitel_FontColor={
                        DB_LeftSidebar_MenuItem_TitelSuperTitel_FontColor
                      }
                      DB_LeftSidebar_MenuItem_TitelSuperTitel_FontSize={
                        DB_LeftSidebar_MenuItem_TitelSuperTitel_FontSize
                      }
                      DB_LeftSidebar_MenuItem_TitelSuperTitel_BackgroundColor={
                        DB_LeftSidebar_MenuItem_TitelSuperTitel_BackgroundColor
                      }
                      DB_LeftSidebar_MenuItem_TitelSuperTitel_TextAlign={
                        DB_LeftSidebar_MenuItem_TitelSuperTitel_TextAlign
                      }
                      DB_fontFace_chooseFontFamilySuperTitel_FontFamilyName={
                        DB_fontFace_chooseFontFamilySuperTitel_FontFamilyName
                      }
                      DB_fontFace_chooseFontFamilySuperTitel_FontFamilySrc={
                        DB_fontFace_chooseFontFamilySuperTitel_FontFamilySrc
                      }
                      DB_LeftSidebar_MenuItem_TitelSuperTitel_BorderTop={
                        DB_LeftSidebar_MenuItem_TitelSuperTitel_BorderTop
                      }
                      DB_LeftSidebar_MenuItem_TitelSuperTitel_BorderStyleTop={
                        DB_LeftSidebar_MenuItem_TitelSuperTitel_BorderStyleTop
                      }
                      DB_LeftSidebar_MenuItem_TitelSuperTitel_BorderColorTop={
                        DB_LeftSidebar_MenuItem_TitelSuperTitel_BorderColorTop
                      }
                      DB_LeftSidebar_MenuItem_TitelSuperTitel_BorderBottom={
                        DB_LeftSidebar_MenuItem_TitelSuperTitel_BorderBottom
                      }
                      DB_LeftSidebar_MenuItem_TitelSuperTitel_BorderStyleBottom={
                        DB_LeftSidebar_MenuItem_TitelSuperTitel_BorderStyleBottom
                      }
                      DB_LeftSidebar_MenuItem_TitelSuperTitel_BorderColorBottom={
                        DB_LeftSidebar_MenuItem_TitelSuperTitel_BorderColorBottom
                      }
                      DB_LeftSidebar_MenuItem_TitelSuperTitel_BorderLeft={
                        DB_LeftSidebar_MenuItem_TitelSuperTitel_BorderLeft
                      }
                      DB_LeftSidebar_MenuItem_TitelSuperTitel_BorderStyleLeft={
                        DB_LeftSidebar_MenuItem_TitelSuperTitel_BorderStyleLeft
                      }
                      DB_LeftSidebar_MenuItem_TitelSuperTitel_BorderColorLeft={
                        DB_LeftSidebar_MenuItem_TitelSuperTitel_BorderColorLeft
                      }
                      DB_LeftSidebar_MenuItem_TitelSuperTitel_BorderRight={
                        DB_LeftSidebar_MenuItem_TitelSuperTitel_BorderRight
                      }
                      DB_LeftSidebar_MenuItem_TitelSuperTitel_BorderStyleRight={
                        DB_LeftSidebar_MenuItem_TitelSuperTitel_BorderStyleRight
                      }
                      DB_LeftSidebar_MenuItem_TitelSuperTitel_BorderColorRight={
                        DB_LeftSidebar_MenuItem_TitelSuperTitel_BorderColorRight
                      }
                      DB_LeftSidebar_MenuItem_TitelSuperTitel_BorderTopRightRadius={
                        DB_LeftSidebar_MenuItem_TitelSuperTitel_BorderTopRightRadius
                      }
                      DB_LeftSidebar_MenuItem_TitelSuperTitel_BorderTopLeftRadius={
                        DB_LeftSidebar_MenuItem_TitelSuperTitel_BorderTopLeftRadius
                      }
                      DB_LeftSidebar_MenuItem_TitelSuperTitel_BorderBottomRightRadius={
                        DB_LeftSidebar_MenuItem_TitelSuperTitel_BorderBottomRightRadius
                      }
                      DB_LeftSidebar_MenuItem_TitelSuperTitel_BorderBottomLeftRadius={
                        DB_LeftSidebar_MenuItem_TitelSuperTitel_BorderBottomLeftRadius
                      }
                      DB_LeftSidebar_MenuItem_TitelSuperTitel_marginLeftRight={
                        DB_LeftSidebar_MenuItem_TitelSuperTitel_marginLeftRight
                      }
                      DB_LeftSidebar_MenuItem_TitelSuperTitel_marginTopBottom={
                        DB_LeftSidebar_MenuItem_TitelSuperTitel_marginTopBottom
                      }
                      DB_LeftSidebar_MenuItem_TitelSuperTitel_paddingLeftRight={
                        DB_LeftSidebar_MenuItem_TitelSuperTitel_paddingLeftRight
                      }
                      DB_LeftSidebar_MenuItem_TitelSuperTitel_paddingTopBottom={
                        DB_LeftSidebar_MenuItem_TitelSuperTitel_paddingTopBottom
                      }
                      DB_LeftSidebar_MenuItem_TitelSuperTitel_letterSpacing={
                        DB_LeftSidebar_MenuItem_TitelSuperTitel_letterSpacing
                      }
                      DB_LeftSidebar_MenuItem_TitelSuperTitel_ShadowHorizontal={
                        DB_LeftSidebar_MenuItem_TitelSuperTitel_ShadowHorizontal
                      }
                      DB_LeftSidebar_MenuItem_TitelSuperTitel_ShadowVertical={
                        DB_LeftSidebar_MenuItem_TitelSuperTitel_ShadowVertical
                      }
                      DB_LeftSidebar_MenuItem_TitelSuperTitel_ShadowBlur={
                        DB_LeftSidebar_MenuItem_TitelSuperTitel_ShadowBlur
                      }
                      DB_LeftSidebar_MenuItem_TitelSuperTitel_ShadowColor={
                        DB_LeftSidebar_MenuItem_TitelSuperTitel_ShadowColor
                      }
                      DB_LeftSidebar_MenuItem_TitelSuperTitel_TextDecoration={
                        DB_LeftSidebar_MenuItem_TitelSuperTitel_TextDecoration
                      }
                      DB_LeftSidebar_MenuItem_TitelSuperTitel_TextDecorationStyle={
                        DB_LeftSidebar_MenuItem_TitelSuperTitel_TextDecorationStyle
                      }
                      DB_LeftSidebar_MenuItem_TitelFormInputDetail_FontWeight={
                        DB_LeftSidebar_MenuItem_TitelFormInputDetail_FontWeight
                      }
                      DB_LeftSidebar_MenuItem_TitelFormInputDetail_FontColor={
                        DB_LeftSidebar_MenuItem_TitelFormInputDetail_FontColor
                      }
                      DB_LeftSidebar_MenuItem_TitelFormInputDetail_FontSize={
                        DB_LeftSidebar_MenuItem_TitelFormInputDetail_FontSize
                      }
                      DB_LeftSidebar_MenuItem_TitelFormInputDetail_BackgroundColor={
                        DB_LeftSidebar_MenuItem_TitelFormInputDetail_BackgroundColor
                      }
                      DB_LeftSidebar_MenuItem_TitelFormInputDetail_TextAlign={
                        DB_LeftSidebar_MenuItem_TitelFormInputDetail_TextAlign
                      }
                      DB_fontFace_chooseFontFormLabelsFontFamily_FontFamilyName={
                        DB_fontFace_chooseFontFormLabelsFontFamily_FontFamilyName
                      }
                      DB_LeftSidebar_MenuItem_TitelFormInputDetail_BorderTop={
                        DB_LeftSidebar_MenuItem_TitelFormInputDetail_BorderTop
                      }
                      DB_LeftSidebar_MenuItem_TitelFormInputDetail_BorderStyleTop={
                        DB_LeftSidebar_MenuItem_TitelFormInputDetail_BorderStyleTop
                      }
                      DB_LeftSidebar_MenuItem_TitelFormInputDetail_BorderColorTop={
                        DB_LeftSidebar_MenuItem_TitelFormInputDetail_BorderColorTop
                      }
                      DB_LeftSidebar_MenuItem_TitelFormInputDetail_BorderBottom={
                        DB_LeftSidebar_MenuItem_TitelFormInputDetail_BorderBottom
                      }
                      DB_LeftSidebar_MenuItem_TitelFormInputDetail_BorderStyleBottom={
                        DB_LeftSidebar_MenuItem_TitelFormInputDetail_BorderStyleBottom
                      }
                      DB_LeftSidebar_MenuItem_TitelFormInputDetail_BorderColorBottom={
                        DB_LeftSidebar_MenuItem_TitelFormInputDetail_BorderColorBottom
                      }
                      DB_LeftSidebar_MenuItem_TitelFormInputDetail_BorderLeft={
                        DB_LeftSidebar_MenuItem_TitelFormInputDetail_BorderLeft
                      }
                      DB_LeftSidebar_MenuItem_TitelFormInputDetail_BorderStyleLeft={
                        DB_LeftSidebar_MenuItem_TitelFormInputDetail_BorderStyleLeft
                      }
                      DB_LeftSidebar_MenuItem_TitelFormInputDetail_BorderColorLeft={
                        DB_LeftSidebar_MenuItem_TitelFormInputDetail_BorderColorLeft
                      }
                      DB_LeftSidebar_MenuItem_TitelFormInputDetail_BorderRight={
                        DB_LeftSidebar_MenuItem_TitelFormInputDetail_BorderRight
                      }
                      DB_LeftSidebar_MenuItem_TitelFormInputDetail_BorderStyleRight={
                        DB_LeftSidebar_MenuItem_TitelFormInputDetail_BorderStyleRight
                      }
                      DB_LeftSidebar_MenuItem_TitelFormInputDetail_BorderColorRight={
                        DB_LeftSidebar_MenuItem_TitelFormInputDetail_BorderColorRight
                      }
                      DB_LeftSidebar_MenuItem_TitelFormInputDetail_BorderTopRightRadius={
                        DB_LeftSidebar_MenuItem_TitelFormInputDetail_BorderTopRightRadius
                      }
                      DB_LeftSidebar_MenuItem_TitelFormInputDetail_BorderTopLeftRadius={
                        DB_LeftSidebar_MenuItem_TitelFormInputDetail_BorderTopLeftRadius
                      }
                      DB_LeftSidebar_MenuItem_TitelFormInputDetail_BorderBottomRightRadius={
                        DB_LeftSidebar_MenuItem_TitelFormInputDetail_BorderBottomRightRadius
                      }
                      DB_LeftSidebar_MenuItem_TitelFormInputDetail_BorderBottomLeftRadius={
                        DB_LeftSidebar_MenuItem_TitelFormInputDetail_BorderBottomLeftRadius
                      }
                      DB_LeftSidebar_MenuItem_TitelFormInputDetail_marginLeftRight={
                        DB_LeftSidebar_MenuItem_TitelFormInputDetail_marginLeftRight
                      }
                      DB_LeftSidebar_MenuItem_TitelFormInputDetail_marginTopBottom={
                        DB_LeftSidebar_MenuItem_TitelFormInputDetail_marginTopBottom
                      }
                      DB_LeftSidebar_MenuItem_TitelFormInputDetail_paddingLeftRight={
                        DB_LeftSidebar_MenuItem_TitelFormInputDetail_paddingLeftRight
                      }
                      DB_LeftSidebar_MenuItem_TitelFormInputDetail_paddingTopBottom={
                        DB_LeftSidebar_MenuItem_TitelFormInputDetail_paddingTopBottom
                      }
                      DB_LeftSidebar_MenuItem_TitelFormInputDetail_letterSpacing={
                        DB_LeftSidebar_MenuItem_TitelFormInputDetail_letterSpacing
                      }
                      DB_LeftSidebar_MenuItem_TitelFormInputDetail_ShadowHorizontal={
                        DB_LeftSidebar_MenuItem_TitelFormInputDetail_ShadowHorizontal
                      }
                      DB_LeftSidebar_MenuItem_TitelFormInputDetail_ShadowVertical={
                        DB_LeftSidebar_MenuItem_TitelFormInputDetail_ShadowVertical
                      }
                      DB_LeftSidebar_MenuItem_TitelFormInputDetail_ShadowBlur={
                        DB_LeftSidebar_MenuItem_TitelFormInputDetail_ShadowBlur
                      }
                      DB_LeftSidebar_MenuItem_TitelFormInputDetail_ShadowColor={
                        DB_LeftSidebar_MenuItem_TitelFormInputDetail_ShadowColor
                      }
                      DB_LeftSidebar_MenuItem_TitelFormInputDetail_TextDecoration={
                        DB_LeftSidebar_MenuItem_TitelFormInputDetail_TextDecoration
                      }
                      DB_LeftSidebar_MenuItem_TitelFormInputDetail_TextDecorationStyle={
                        DB_LeftSidebar_MenuItem_TitelFormInputDetail_TextDecorationStyle
                      }
                      DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_FontWeight={
                        DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_FontWeight
                      }
                      DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_FontColor={
                        DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_FontColor
                      }
                      DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_FontSize={
                        DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_FontSize
                      }
                      DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_BackgroundColor={
                        DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_BackgroundColor
                      }
                      DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_TextAlign={
                        DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_TextAlign
                      }
                      DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_BorderTop={
                        DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_BorderTop
                      }
                      DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_BorderStyleTop={
                        DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_BorderStyleTop
                      }
                      DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_BorderColorTop={
                        DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_BorderColorTop
                      }
                      DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_BorderBottom={
                        DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_BorderBottom
                      }
                      DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_BorderStyleBottom={
                        DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_BorderStyleBottom
                      }
                      DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_BorderColorBottom={
                        DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_BorderColorBottom
                      }
                      DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_BorderLeft={
                        DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_BorderLeft
                      }
                      DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_BorderStyleLeft={
                        DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_BorderStyleLeft
                      }
                      DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_BorderColorLeft={
                        DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_BorderColorLeft
                      }
                      DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_BorderRight={
                        DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_BorderRight
                      }
                      DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_BorderStyleRight={
                        DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_BorderStyleRight
                      }
                      DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_BorderColorRight={
                        DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_BorderColorRight
                      }
                      DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_BorderTopRightRadius={
                        DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_BorderTopRightRadius
                      }
                      DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_BorderTopLeftRadius={
                        DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_BorderTopLeftRadius
                      }
                      DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_BorderBottomRightRadius={
                        DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_BorderBottomRightRadius
                      }
                      DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_BorderBottomLeftRadius={
                        DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_BorderBottomLeftRadius
                      }
                      DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_marginLeftRight={
                        DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_marginLeftRight
                      }
                      DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_marginTopBottom={
                        DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_marginTopBottom
                      }
                      DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_paddingLeftRight={
                        DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_paddingLeftRight
                      }
                      DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_paddingTopBottom={
                        DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_paddingTopBottom
                      }
                      DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_letterSpacing={
                        DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_letterSpacing
                      }
                      DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_ShadowHorizontal={
                        DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_ShadowHorizontal
                      }
                      DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_ShadowVertical={
                        DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_ShadowVertical
                      }
                      DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_ShadowBlur={
                        DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_ShadowBlur
                      }
                      DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_ShadowColor={
                        DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_ShadowColor
                      }
                      DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_TextDecoration={
                        DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_TextDecoration
                      }
                      DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_TextDecorationStyle={
                        DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_TextDecorationStyle
                      }
                      DB_LeftSidebar_MenuItem_TitelKontaktFormular_FontWeight={
                        DB_LeftSidebar_MenuItem_TitelKontaktFormular_FontWeight
                      }
                      DB_LeftSidebar_MenuItem_TitelKontaktFormular_FontColor={
                        DB_LeftSidebar_MenuItem_TitelKontaktFormular_FontColor
                      }
                      DB_LeftSidebar_MenuItem_TitelKontaktFormular_FontSize={
                        DB_LeftSidebar_MenuItem_TitelKontaktFormular_FontSize
                      }
                      DB_LeftSidebar_MenuItem_TitelKontaktFormular_BackgroundColor={
                        DB_LeftSidebar_MenuItem_TitelKontaktFormular_BackgroundColor
                      }
                      DB_LeftSidebar_MenuItem_TitelKontaktFormular_TextAlign={
                        DB_LeftSidebar_MenuItem_TitelKontaktFormular_TextAlign
                      }
                      DB_fontFace_chooseFontKontaktFormularFontFamily_FontFamilyName={
                        DB_fontFace_chooseFontKontaktFormularFontFamily_FontFamilyName
                      }
                      DB_LeftSidebar_MenuItem_TitelKontaktFormular_BorderTop={
                        DB_LeftSidebar_MenuItem_TitelKontaktFormular_BorderTop
                      }
                      DB_LeftSidebar_MenuItem_TitelKontaktFormular_BorderStyleTop={
                        DB_LeftSidebar_MenuItem_TitelKontaktFormular_BorderStyleTop
                      }
                      DB_LeftSidebar_MenuItem_TitelKontaktFormular_BorderColorTop={
                        DB_LeftSidebar_MenuItem_TitelKontaktFormular_BorderColorTop
                      }
                      DB_LeftSidebar_MenuItem_TitelKontaktFormular_BorderBottom={
                        DB_LeftSidebar_MenuItem_TitelKontaktFormular_BorderBottom
                      }
                      DB_LeftSidebar_MenuItem_TitelKontaktFormular_BorderStyleBottom={
                        DB_LeftSidebar_MenuItem_TitelKontaktFormular_BorderStyleBottom
                      }
                      DB_LeftSidebar_MenuItem_TitelKontaktFormular_BorderColorBottom={
                        DB_LeftSidebar_MenuItem_TitelKontaktFormular_BorderColorBottom
                      }
                      DB_LeftSidebar_MenuItem_TitelKontaktFormular_BorderLeft={
                        DB_LeftSidebar_MenuItem_TitelKontaktFormular_BorderLeft
                      }
                      DB_LeftSidebar_MenuItem_TitelKontaktFormular_BorderStyleLeft={
                        DB_LeftSidebar_MenuItem_TitelKontaktFormular_BorderStyleLeft
                      }
                      DB_LeftSidebar_MenuItem_TitelKontaktFormular_BorderColorLeft={
                        DB_LeftSidebar_MenuItem_TitelKontaktFormular_BorderColorLeft
                      }
                      DB_LeftSidebar_MenuItem_TitelKontaktFormular_BorderRight={
                        DB_LeftSidebar_MenuItem_TitelKontaktFormular_BorderRight
                      }
                      DB_LeftSidebar_MenuItem_TitelKontaktFormular_BorderStyleRight={
                        DB_LeftSidebar_MenuItem_TitelKontaktFormular_BorderStyleRight
                      }
                      DB_LeftSidebar_MenuItem_TitelKontaktFormular_BorderColorRight={
                        DB_LeftSidebar_MenuItem_TitelKontaktFormular_BorderColorRight
                      }
                      DB_LeftSidebar_MenuItem_TitelKontaktFormular_BorderTopRightRadius={
                        DB_LeftSidebar_MenuItem_TitelKontaktFormular_BorderTopRightRadius
                      }
                      DB_LeftSidebar_MenuItem_TitelKontaktFormular_BorderTopLeftRadius={
                        DB_LeftSidebar_MenuItem_TitelKontaktFormular_BorderTopLeftRadius
                      }
                      DB_LeftSidebar_MenuItem_TitelKontaktFormular_BorderBottomRightRadius={
                        DB_LeftSidebar_MenuItem_TitelKontaktFormular_BorderBottomRightRadius
                      }
                      DB_LeftSidebar_MenuItem_TitelKontaktFormular_BorderBottomLeftRadius={
                        DB_LeftSidebar_MenuItem_TitelKontaktFormular_BorderBottomLeftRadius
                      }
                      DB_LeftSidebar_MenuItem_TitelKontaktFormular_marginLeftRight={
                        DB_LeftSidebar_MenuItem_TitelKontaktFormular_marginLeftRight
                      }
                      DB_LeftSidebar_MenuItem_TitelKontaktFormular_marginTopBottom={
                        DB_LeftSidebar_MenuItem_TitelKontaktFormular_marginTopBottom
                      }
                      DB_LeftSidebar_MenuItem_TitelKontaktFormular_paddingLeftRight={
                        DB_LeftSidebar_MenuItem_TitelKontaktFormular_paddingLeftRight
                      }
                      DB_LeftSidebar_MenuItem_TitelKontaktFormular_paddingTopBottom={
                        DB_LeftSidebar_MenuItem_TitelKontaktFormular_paddingTopBottom
                      }
                      DB_LeftSidebar_MenuItem_TitelKontaktFormular_letterSpacing={
                        DB_LeftSidebar_MenuItem_TitelKontaktFormular_letterSpacing
                      }
                      DB_LeftSidebar_MenuItem_TitelKontaktFormular_ShadowHorizontal={
                        DB_LeftSidebar_MenuItem_TitelKontaktFormular_ShadowHorizontal
                      }
                      DB_LeftSidebar_MenuItem_TitelKontaktFormular_ShadowVertical={
                        DB_LeftSidebar_MenuItem_TitelKontaktFormular_ShadowVertical
                      }
                      DB_LeftSidebar_MenuItem_TitelKontaktFormular_ShadowBlur={
                        DB_LeftSidebar_MenuItem_TitelKontaktFormular_ShadowBlur
                      }
                      DB_LeftSidebar_MenuItem_TitelKontaktFormular_ShadowColor={
                        DB_LeftSidebar_MenuItem_TitelKontaktFormular_ShadowColor
                      }
                      DB_LeftSidebar_MenuItem_TitelKontaktFormular_TextDecoration={
                        DB_LeftSidebar_MenuItem_TitelKontaktFormular_TextDecoration
                      }
                      DB_LeftSidebar_MenuItem_TitelKontaktFormular_TextDecorationStyle={
                        DB_LeftSidebar_MenuItem_TitelKontaktFormular_TextDecorationStyle
                      }
                      DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_FontWeight={
                        DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_FontWeight
                      }
                      DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_FontColor={
                        DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_FontColor
                      }
                      DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_FontSize={
                        DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_FontSize
                      }
                      DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BackgroundColor={
                        DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BackgroundColor
                      }
                      DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_TextAlign={
                        DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_TextAlign
                      }
                      DB_fontFace_chooseFontBeschreibungFontFamily_FontFamilyName={
                        DB_fontFace_chooseFontBeschreibungFontFamily_FontFamilyName
                      }
                      DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderTop={
                        DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderTop
                      }
                      DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderStyleTop={
                        DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderStyleTop
                      }
                      DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderColorTop={
                        DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderColorTop
                      }
                      DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderBottom={
                        DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderBottom
                      }
                      DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderStyleBottom={
                        DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderStyleBottom
                      }
                      DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderColorBottom={
                        DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderColorBottom
                      }
                      DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderLeft={
                        DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderLeft
                      }
                      DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderStyleLeft={
                        DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderStyleLeft
                      }
                      DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderColorLeft={
                        DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderColorLeft
                      }
                      DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderRight={
                        DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderRight
                      }
                      DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderStyleRight={
                        DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderStyleRight
                      }
                      DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderColorRight={
                        DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderColorRight
                      }
                      DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderTopRightRadius={
                        DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderTopRightRadius
                      }
                      DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderTopLeftRadius={
                        DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderTopLeftRadius
                      }
                      DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderBottomRightRadius={
                        DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderBottomRightRadius
                      }
                      DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderBottomLeftRadius={
                        DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderBottomLeftRadius
                      }
                      DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_marginLeftRight={
                        DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_marginLeftRight
                      }
                      DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_marginTopBottom={
                        DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_marginTopBottom
                      }
                      DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_paddingLeftRight={
                        DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_paddingLeftRight
                      }
                      DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_paddingTopBottom={
                        DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_paddingTopBottom
                      }
                      DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_letterSpacing={
                        DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_letterSpacing
                      }
                      DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_ShadowHorizontal={
                        DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_ShadowHorizontal
                      }
                      DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_ShadowVertical={
                        DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_ShadowVertical
                      }
                      DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_ShadowBlur={
                        DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_ShadowBlur
                      }
                      DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_ShadowColor={
                        DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_ShadowColor
                      }
                      DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_TextDecoration={
                        DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_TextDecoration
                      }
                      DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_TextDecorationStyle={
                        DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_TextDecorationStyle
                      }
                      DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_FontWeight={
                        DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_FontWeight
                      }
                      DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_FontColor={
                        DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_FontColor
                      }
                      DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_FontSize={
                        DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_FontSize
                      }
                      DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BackgroundColor={
                        DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BackgroundColor
                      }
                      DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_TextAlign={
                        DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_TextAlign
                      }
                      DB_fontFace_chooseFontHaupttitelFontFamily_FontFamilyName={
                        DB_fontFace_chooseFontHaupttitelFontFamily_FontFamilyName
                      }
                      DB_fontFace_chooseFontHaupttitelFontFamily_FontFamilySrc={
                        DB_fontFace_chooseFontHaupttitelFontFamily_FontFamilySrc
                      }
                      DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderTop={
                        DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderTop
                      }
                      DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderStyleTop={
                        DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderStyleTop
                      }
                      DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderColorTop={
                        DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderColorTop
                      }
                      DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderBottom={
                        DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderBottom
                      }
                      DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderStyleBottom={
                        DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderStyleBottom
                      }
                      DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderColorBottom={
                        DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderColorBottom
                      }
                      DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderLeft={
                        DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderLeft
                      }
                      DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderStyleLeft={
                        DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderStyleLeft
                      }
                      DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderColorLeft={
                        DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderColorLeft
                      }
                      DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderRight={
                        DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderRight
                      }
                      DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderStyleRight={
                        DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderStyleRight
                      }
                      DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderColorRight={
                        DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderColorRight
                      }
                      DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderTopRightRadius={
                        DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderTopRightRadius
                      }
                      DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderTopLeftRadius={
                        DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderTopLeftRadius
                      }
                      DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderBottomRightRadius={
                        DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderBottomRightRadius
                      }
                      DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderBottomLeftRadius={
                        DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderBottomLeftRadius
                      }
                      DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_marginLeftRight={
                        DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_marginLeftRight
                      }
                      DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_marginTopBottom={
                        DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_marginTopBottom
                      }
                      DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_paddingLeftRight={
                        DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_paddingLeftRight
                      }
                      DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_paddingTopBottom={
                        DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_paddingTopBottom
                      }
                      DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_letterSpacing={
                        DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_letterSpacing
                      }
                      DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_ShadowHorizontal={
                        DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_ShadowHorizontal
                      }
                      DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_ShadowVertical={
                        DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_ShadowVertical
                      }
                      DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_ShadowBlur={
                        DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_ShadowBlur
                      }
                      DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_ShadowColor={
                        DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_ShadowColor
                      }
                      DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_TextDecoration={
                        DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_TextDecoration
                      }
                      DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_TextDecorationStyle={
                        DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_TextDecorationStyle
                      }
                      DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_FontColorNotEncoded={
                        DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_FontColorNotEncoded
                      }
                    />
                  ) : aboutUsShow && !faqShow ? (
                    <AboutUs
                      traceHandler={traceHandler}
                      aboutUsDetailItem={aboutUsDetailItem}
                      images={aboutUsImages}
                      setSideBarLeft_ContainerSocial_Farbe_Groesse_Stil_OnMouseOverAndOut_Handler={
                        setSideBarLeft_ContainerSocial_Farbe_Groesse_Stil_OnMouseOverAndOut_Handler
                      }
                      setSideBarLeft_ContainerSocial_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut_Handler={
                        setSideBarLeft_ContainerSocial_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut_Handler
                      }
                      setSideBarLeft_AlleSocial_Farbe_Groesse_Stil_OnMouseOverAndOut_Handler={
                        setSideBarLeft_AlleSocial_Farbe_Groesse_Stil_OnMouseOverAndOut_Handler
                      }
                      setSideBarLeft_AlleSocial_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut_Handler={
                        setSideBarLeft_AlleSocial_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut_Handler
                      }
                      setSideBarLeft_DetailItem_Farbe_Groesse_Stil_OnMouseOverAndOut_Handler={
                        setSideBarLeft_DetailItem_Farbe_Groesse_Stil_OnMouseOverAndOut_Handler
                      }
                      setSideBarLeft_DetailItem_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut_Handler={
                        setSideBarLeft_DetailItem_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut_Handler
                      }
                      SideBarLeft_ContainerDetail_Farbe_Groesse_Stil_OnMouseOverAndOut={
                        SideBarLeft_ContainerDetail_Farbe_Groesse_Stil_OnMouseOverAndOut
                      }
                      SideBarLeft_ContainerDetail_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut={
                        SideBarLeft_ContainerDetail_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut
                      }
                      SideBarLeft_DetailItem_Farbe_Groesse_Stil_OnMouseOverAndOut={
                        SideBarLeft_DetailItem_Farbe_Groesse_Stil_OnMouseOverAndOut
                      }
                      SideBarLeft_DetailItem_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut={
                        SideBarLeft_DetailItem_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut
                      }
                      DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_FontWeight={
                        DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_FontWeight
                      }
                      DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_FontColor={
                        DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_FontColor
                      }
                      DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_FontSize={
                        DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_FontSize
                      }
                      DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BackgroundColor={
                        DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BackgroundColor
                      }
                      DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_TextAlign={
                        DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_TextAlign
                      }
                      DB_fontFace_chooseFontBeschreibungFontFamily_FontFamilyName={
                        DB_fontFace_chooseFontBeschreibungFontFamily_FontFamilyName
                      }
                      DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderTop={
                        DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderTop
                      }
                      DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderStyleTop={
                        DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderStyleTop
                      }
                      DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderColorTop={
                        DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderColorTop
                      }
                      DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderBottom={
                        DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderBottom
                      }
                      DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderStyleBottom={
                        DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderStyleBottom
                      }
                      DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderColorBottom={
                        DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderColorBottom
                      }
                      DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderLeft={
                        DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderLeft
                      }
                      DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderStyleLeft={
                        DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderStyleLeft
                      }
                      DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderColorLeft={
                        DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderColorLeft
                      }
                      DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderRight={
                        DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderRight
                      }
                      DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderStyleRight={
                        DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderStyleRight
                      }
                      DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderColorRight={
                        DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderColorRight
                      }
                      DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderTopRightRadius={
                        DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderTopRightRadius
                      }
                      DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderTopLeftRadius={
                        DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderTopLeftRadius
                      }
                      DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderBottomRightRadius={
                        DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderBottomRightRadius
                      }
                      DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderBottomLeftRadius={
                        DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderBottomLeftRadius
                      }
                      DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_marginLeftRight={
                        DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_marginLeftRight
                      }
                      DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_marginTopBottom={
                        DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_marginTopBottom
                      }
                      DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_paddingLeftRight={
                        DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_paddingLeftRight
                      }
                      DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_paddingTopBottom={
                        DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_paddingTopBottom
                      }
                      DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_letterSpacing={
                        DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_letterSpacing
                      }
                      DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_ShadowHorizontal={
                        DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_ShadowHorizontal
                      }
                      DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_ShadowVertical={
                        DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_ShadowVertical
                      }
                      DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_ShadowBlur={
                        DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_ShadowBlur
                      }
                      DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_ShadowColor={
                        DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_ShadowColor
                      }
                      DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_TextDecoration={
                        DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_TextDecoration
                      }
                      DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_TextDecorationStyle={
                        DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_TextDecorationStyle
                      }
                      DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_FontWeight={
                        DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_FontWeight
                      }
                      DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_FontColor={
                        DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_FontColor
                      }
                      DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_FontSize={
                        DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_FontSize
                      }
                      DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BackgroundColor={
                        DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BackgroundColor
                      }
                      DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_TextAlign={
                        DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_TextAlign
                      }
                      DB_fontFace_chooseFontHaupttitelFontFamily_FontFamilyName={
                        DB_fontFace_chooseFontHaupttitelFontFamily_FontFamilyName
                      }
                      DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderTop={
                        DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderTop
                      }
                      DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderStyleTop={
                        DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderStyleTop
                      }
                      DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderColorTop={
                        DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderColorTop
                      }
                      DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderBottom={
                        DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderBottom
                      }
                      DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderStyleBottom={
                        DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderStyleBottom
                      }
                      DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderColorBottom={
                        DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderColorBottom
                      }
                      DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderLeft={
                        DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderLeft
                      }
                      DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderStyleLeft={
                        DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderStyleLeft
                      }
                      DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderColorLeft={
                        DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderColorLeft
                      }
                      DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderRight={
                        DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderRight
                      }
                      DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderStyleRight={
                        DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderStyleRight
                      }
                      DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderColorRight={
                        DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderColorRight
                      }
                      DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderTopRightRadius={
                        DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderTopRightRadius
                      }
                      DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderTopLeftRadius={
                        DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderTopLeftRadius
                      }
                      DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderBottomRightRadius={
                        DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderBottomRightRadius
                      }
                      DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderBottomLeftRadius={
                        DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderBottomLeftRadius
                      }
                      DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_marginLeftRight={
                        DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_marginLeftRight
                      }
                      DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_marginTopBottom={
                        DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_marginTopBottom
                      }
                      DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_paddingLeftRight={
                        DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_paddingLeftRight
                      }
                      DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_paddingTopBottom={
                        DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_paddingTopBottom
                      }
                      DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_letterSpacing={
                        DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_letterSpacing
                      }
                      DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_ShadowHorizontal={
                        DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_ShadowHorizontal
                      }
                      DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_ShadowVertical={
                        DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_ShadowVertical
                      }
                      DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_ShadowBlur={
                        DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_ShadowBlur
                      }
                      DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_ShadowColor={
                        DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_ShadowColor
                      }
                      DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_TextDecoration={
                        DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_TextDecoration
                      }
                      DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_TextDecorationStyle={
                        DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_TextDecorationStyle
                      }
                    />
                  ) : (
                    faqShow && (
                      <FAQ
                        userOwnsThisSite={userOwnsThisSite}
                        categoryLeftSideHandler={(e) => {
                          categoryLeftSideHandler(e);
                        }}
                        hideProductDetailsHandler={hideProductDetailsHandler}
                        faqDetailItem={faqDetailItem}
                        images={aboutUsImages}
                        DB_fontFace_FontFamilyName={DB_fontFace_FontFamilyName}
                        setSideBarLeft_ContainerSocial_Farbe_Groesse_Stil_OnMouseOverAndOut_Handler={
                          setSideBarLeft_ContainerSocial_Farbe_Groesse_Stil_OnMouseOverAndOut_Handler
                        }
                        setSideBarLeft_ContainerSocial_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut_Handler={
                          setSideBarLeft_ContainerSocial_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut_Handler
                        }
                        setSideBarLeft_AlleSocial_Farbe_Groesse_Stil_OnMouseOverAndOut_Handler={
                          setSideBarLeft_AlleSocial_Farbe_Groesse_Stil_OnMouseOverAndOut_Handler
                        }
                        setSideBarLeft_AlleSocial_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut_Handler={
                          setSideBarLeft_AlleSocial_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut_Handler
                        }
                        setSideBarLeft_DetailItem_Farbe_Groesse_Stil_OnMouseOverAndOut_Handler={
                          setSideBarLeft_DetailItem_Farbe_Groesse_Stil_OnMouseOverAndOut_Handler
                        }
                        setSideBarLeft_DetailItem_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut_Handler={
                          setSideBarLeft_DetailItem_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut_Handler
                        }
                        SideBarLeft_AlleDetail_Farbe_Groesse_Stil_Trigger={
                          SideBarLeft_AlleDetail_Farbe_Groesse_Stil_Trigger
                        }
                        SideBarLeft_AlleDetail_HintergrundFarbe_Radius_Stil_Trigger={
                          SideBarLeft_AlleDetail_HintergrundFarbe_Radius_Stil_Trigger
                        }
                        SideBarLeft_DetailItem_Farbe_Groesse_Stil_Trigger={
                          SideBarLeft_DetailItem_Farbe_Groesse_Stil_Trigger
                        }
                        SideBarLeft_DetailItem_HintergrundFarbe_Radius_Stil_Trigger={
                          SideBarLeft_DetailItem_HintergrundFarbe_Radius_Stil_Trigger
                        }
                        SideBarLeft_ContainerDetail_Farbe_Groesse_Stil_OnMouseOverAndOut={
                          SideBarLeft_ContainerDetail_Farbe_Groesse_Stil_OnMouseOverAndOut
                        }
                        SideBarLeft_ContainerDetail_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut={
                          SideBarLeft_ContainerDetail_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut
                        }
                        SideBarLeft_DetailItem_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut={
                          SideBarLeft_DetailItem_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut
                        }
                        DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_FontWeight={
                          DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_FontWeight
                        }
                        DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_FontColor={
                          DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_FontColor
                        }
                        DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_FontSize={
                          DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_FontSize
                        }
                        DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BackgroundColor={
                          DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BackgroundColor
                        }
                        DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_TextAlign={
                          DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_TextAlign
                        }
                        DB_fontFace_chooseFontBeschreibungFontFamily_FontFamilyName={
                          DB_fontFace_chooseFontBeschreibungFontFamily_FontFamilyName
                        }
                        DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderTop={
                          DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderTop
                        }
                        DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderStyleTop={
                          DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderStyleTop
                        }
                        DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderColorTop={
                          DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderColorTop
                        }
                        DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderBottom={
                          DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderBottom
                        }
                        DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderStyleBottom={
                          DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderStyleBottom
                        }
                        DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderColorBottom={
                          DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderColorBottom
                        }
                        DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderLeft={
                          DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderLeft
                        }
                        DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderStyleLeft={
                          DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderStyleLeft
                        }
                        DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderColorLeft={
                          DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderColorLeft
                        }
                        DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderRight={
                          DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderRight
                        }
                        DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderStyleRight={
                          DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderStyleRight
                        }
                        DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderColorRight={
                          DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderColorRight
                        }
                        DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderTopRightRadius={
                          DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderTopRightRadius
                        }
                        DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderTopLeftRadius={
                          DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderTopLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderBottomRightRadius={
                          DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderBottomRightRadius
                        }
                        DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderBottomLeftRadius={
                          DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderBottomLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_marginLeftRight={
                          DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_marginLeftRight
                        }
                        DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_marginTopBottom={
                          DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_marginTopBottom
                        }
                        DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_paddingLeftRight={
                          DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_paddingLeftRight
                        }
                        DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_paddingTopBottom={
                          DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_paddingTopBottom
                        }
                        DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_letterSpacing={
                          DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_letterSpacing
                        }
                        DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_ShadowHorizontal={
                          DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_ShadowHorizontal
                        }
                        DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_ShadowVertical={
                          DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_ShadowVertical
                        }
                        DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_ShadowBlur={
                          DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_ShadowBlur
                        }
                        DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_ShadowColor={
                          DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_ShadowColor
                        }
                        DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_TextDecoration={
                          DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_TextDecoration
                        }
                        DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_TextDecorationStyle={
                          DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_TextDecorationStyle
                        }
                        DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_FontWeight={
                          DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_FontWeight
                        }
                        DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_FontColor={
                          DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_FontColor
                        }
                        DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_FontSize={
                          DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_FontSize
                        }
                        DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BackgroundColor={
                          DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BackgroundColor
                        }
                        DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_TextAlign={
                          DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_TextAlign
                        }
                        DB_fontFace_chooseFontHaupttitelFontFamily_FontFamilyName={
                          DB_fontFace_chooseFontHaupttitelFontFamily_FontFamilyName
                        }
                        DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderTop={
                          DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderTop
                        }
                        DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderStyleTop={
                          DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderStyleTop
                        }
                        DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderColorTop={
                          DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderColorTop
                        }
                        DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderBottom={
                          DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderBottom
                        }
                        DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderStyleBottom={
                          DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderStyleBottom
                        }
                        DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderColorBottom={
                          DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderColorBottom
                        }
                        DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderLeft={
                          DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderLeft
                        }
                        DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderStyleLeft={
                          DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderStyleLeft
                        }
                        DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderColorLeft={
                          DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderColorLeft
                        }
                        DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderRight={
                          DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderRight
                        }
                        DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderStyleRight={
                          DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderStyleRight
                        }
                        DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderColorRight={
                          DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderColorRight
                        }
                        DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderTopRightRadius={
                          DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderTopRightRadius
                        }
                        DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderTopLeftRadius={
                          DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderTopLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderBottomRightRadius={
                          DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderBottomRightRadius
                        }
                        DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderBottomLeftRadius={
                          DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderBottomLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_marginLeftRight={
                          DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_marginLeftRight
                        }
                        DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_marginTopBottom={
                          DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_marginTopBottom
                        }
                        DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_paddingLeftRight={
                          DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_paddingLeftRight
                        }
                        DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_paddingTopBottom={
                          DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_paddingTopBottom
                        }
                        DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_letterSpacing={
                          DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_letterSpacing
                        }
                        DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_ShadowHorizontal={
                          DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_ShadowHorizontal
                        }
                        DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_ShadowVertical={
                          DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_ShadowVertical
                        }
                        DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_ShadowBlur={
                          DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_ShadowBlur
                        }
                        DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_ShadowColor={
                          DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_ShadowColor
                        }
                        DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_TextDecoration={
                          DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_TextDecoration
                        }
                        DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_TextDecorationStyle={
                          DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_TextDecorationStyle
                        }
                      />
                    )
                  )}
                </Row>
              </div>
            </Col>
            {!specialRightCategoriesVisible &&
            !rightFiltersVisible &&
            !ProductFilterIsVisibleRight &&
            !rightSocialsVisible &&
            !TopBarIsVisibleRight &&
            !rightMainCategoriesVisible ? (
              ""
            ) : (
              <Col
                lg={
                  specialRightCategoriesVisible ||
                  rightFiltersVisible ||
                  ProductFilterIsVisibleRight ||
                  rightSocialsVisible ||
                  TopBarIsVisibleRight ||
                  rightMainCategoriesVisible
                    ? 2
                    : !specialRightCategoriesVisible &&
                      !rightFiltersVisible &&
                      !ProductFilterIsVisibleRight &&
                      !rightSocialsVisible &&
                      !TopBarIsVisibleRight &&
                      !rightMainCategoriesVisible
                    ? 0
                    : 12
                }
                className={`
              ${
                DB_OrderOfBodyElements === "012"
                  ? "order-lg-2 d-lg-block d-none mt-3 ps-0"
                  : DB_OrderOfBodyElements === "021"
                  ? "order-lg-1 d-lg-block d-none mt-3 pe-0"
                  : DB_OrderOfBodyElements === "102"
                  ? "order-lg-2 d-lg-block d-none mt-3"
                  : DB_OrderOfBodyElements === "120"
                  ? "order-lg-1 d-lg-block d-none mt-3 ps-0 pe-0"
                  : DB_OrderOfBodyElements === "201"
                  ? "order-lg-0 d-lg-block d-none mt-3 pe-0"
                  : DB_OrderOfBodyElements === "210"
                  ? "order-lg-0 d-lg-block d-none mt-3 pe-0"
                  : "order-lg-2 d-lg-block d-none mt-3 ps-0"
              }`}
              >
                {rightMainCategoriesVisible && !isMobile && (
                  <Navbar expand="lg">
                    <HauptMenueTopSpecial
                      ultraCategoriesNew={ultraCategoriesNew}
                      categoryLeftSideHandler={(e) => {
                        categoryLeftSideHandler(e);
                      }}
                      DB_fontFace_FontFamilyName={DB_fontFace_FontFamilyName}
                      SideBarLeft_HauptmenuTopAA_Farbe_Groesse_Stil_OnMouseOverAndOut={
                        SideBarLeft_HauptmenuTop_Farbe_Groesse_Stil_OnMouseOverAndOut
                      }
                      SideBarLeft_HauptmenuTopAA_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut={
                        SideBarLeft_HauptmenuTop_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
                      }
                      SideBarLeft_HauptmenuTopAA_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut={
                        SideBarLeft_HauptmenuTop_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut
                      }
                      SideBarLeft_HauptmenuTopAA_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut={
                        SideBarLeft_HauptmenuTop_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut
                      }
                      SideBarLeft_ContainerTopAA_Farbe_Groesse_Stil_OnMouseOverAndOut={
                        SideBarLeft_ContainerTop_Farbe_Groesse_Stil_OnMouseOverAndOut
                      }
                      SideBarLeft_ContainerTopAA_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut={
                        SideBarLeft_ContainerTop_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
                      }
                      SideBarLeft_ContainerTopAA_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut={
                        SideBarLeft_ContainerTop_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut
                      }
                      SideBarLeft_ContainerTopAA_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut={
                        SideBarLeft_ContainerTop_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut
                      }
                      DB_fontFace_hauptMenueTopAA_FontFamilyName={
                        DB_fontFace_hauptMenueTopAA_FontFamilyName
                      }
                      DB_fontFace_hauptMenueTopAA_FontFamilySrc={
                        DB_fontFace_hauptMenueTopAA_FontFamilySrc
                      }
                      DB_LeftSidebar_MenuContainerAA_BackgroundColor={
                        DB_LeftSidebar_MenuContainerAA_BackgroundColor
                      }
                      DB_LeftSidebar_MenuContainerAA_PaddingTopBottom={
                        DB_LeftSidebar_MenuContainerAA_PaddingTopBottom
                      }
                      DB_LeftSidebar_MenuContainerAA_PaddingLeftRight={
                        DB_LeftSidebar_MenuContainerAA_PaddingLeftRight
                      }
                      DB_LeftSidebar_MenuContainerAA_BorderTop={
                        DB_LeftSidebar_MenuContainerAA_BorderTop
                      }
                      DB_LeftSidebar_MenuContainerAA_BorderStyleTop={
                        DB_LeftSidebar_MenuContainerAA_BorderStyleTop
                      }
                      DB_LeftSidebar_MenuContainerAA_BorderColorTop={
                        DB_LeftSidebar_MenuContainerAA_BorderColorTop
                      }
                      DB_LeftSidebar_MenuContainerAA_BorderBottom={
                        DB_LeftSidebar_MenuContainerAA_BorderBottom
                      }
                      DB_LeftSidebar_MenuContainerAA_BorderStyleBottom={
                        DB_LeftSidebar_MenuContainerAA_BorderStyleBottom
                      }
                      DB_LeftSidebar_MenuContainerAA_BorderColorBottom={
                        DB_LeftSidebar_MenuContainerAA_BorderColorBottom
                      }
                      DB_LeftSidebar_MenuContainerAA_BorderLeft={
                        DB_LeftSidebar_MenuContainerAA_BorderLeft
                      }
                      DB_LeftSidebar_MenuContainerAA_BorderStyleLeft={
                        DB_LeftSidebar_MenuContainerAA_BorderStyleLeft
                      }
                      DB_LeftSidebar_MenuContainerAA_BorderColorLeft={
                        DB_LeftSidebar_MenuContainerAA_BorderColorLeft
                      }
                      DB_LeftSidebar_MenuContainerAA_BorderRight={
                        DB_LeftSidebar_MenuContainerAA_BorderRight
                      }
                      DB_LeftSidebar_MenuContainerAA_BorderStyleRight={
                        DB_LeftSidebar_MenuContainerAA_BorderStyleRight
                      }
                      DB_LeftSidebar_MenuContainerAA_BorderColorRight={
                        DB_LeftSidebar_MenuContainerAA_BorderColorRight
                      }
                      DB_LeftSidebar_MenuItem_MenuContainerAA_BorderTopRightRadius={
                        DB_LeftSidebar_MenuItem_MenuContainerAA_BorderTopRightRadius
                      }
                      DB_LeftSidebar_MenuItem_MenuContainerAA_BorderTopLeftRadius={
                        DB_LeftSidebar_MenuItem_MenuContainerAA_BorderTopLeftRadius
                      }
                      DB_LeftSidebar_MenuItem_MenuContainerAA_BorderBottomRightRadius={
                        DB_LeftSidebar_MenuItem_MenuContainerAA_BorderBottomRightRadius
                      }
                      DB_LeftSidebar_MenuItem_MenuContainerAA_BorderBottomLeftRadius={
                        DB_LeftSidebar_MenuItem_MenuContainerAA_BorderBottomLeftRadius
                      }
                      DB_LeftSidebar_MenuItem_MenuContainerAA_paddingLeft={
                        DB_LeftSidebar_MenuItem_MenuContainerAA_paddingLeft
                      }
                      DB_LeftSidebar_MenuItem_MenuContainerAA_paddingRight={
                        DB_LeftSidebar_MenuItem_MenuContainerAA_paddingRight
                      }
                      DB_LeftSidebar_MenuItem_MenuContainerAA_paddingTop={
                        DB_LeftSidebar_MenuItem_MenuContainerAA_paddingTop
                      }
                      DB_LeftSidebar_MenuItem_MenuContainerAA_paddingBottom={
                        DB_LeftSidebar_MenuItem_MenuContainerAA_paddingBottom
                      }
                      DB_LeftSidebar_MenuItem_MenuContainerAA_ShadowHorizontal={
                        DB_LeftSidebar_MenuItem_MenuContainerAA_ShadowHorizontal
                      }
                      DB_LeftSidebar_MenuItem_MenuContainerAA_ShadowVertical={
                        DB_LeftSidebar_MenuItem_MenuContainerAA_ShadowVertical
                      }
                      DB_LeftSidebar_MenuItem_MenuContainerAA_ShadowBlur={
                        DB_LeftSidebar_MenuItem_MenuContainerAA_ShadowBlur
                      }
                      DB_LeftSidebar_MenuItem_MenuContainerAA_ShadowColor={
                        DB_LeftSidebar_MenuItem_MenuContainerAA_ShadowColor
                      }
                      DB_LeftSidebar_MenuContainerAA_Hover_BackgroundColor={
                        DB_LeftSidebar_MenuContainerAA_Hover_BackgroundColor
                      }
                      DB_LeftSidebar_MenuContainerAA_Hover_PaddingTopBottom={
                        DB_LeftSidebar_MenuContainerAA_Hover_PaddingTopBottom
                      }
                      DB_LeftSidebar_MenuContainerAA_Hover_PaddingLeftRight={
                        DB_LeftSidebar_MenuContainerAA_Hover_PaddingLeftRight
                      }
                      DB_LeftSidebar_MenuContainerAA_Hover_BorderTop={
                        DB_LeftSidebar_MenuContainerAA_Hover_BorderTop
                      }
                      DB_LeftSidebar_MenuContainerAA_Hover_BorderStyleTop={
                        DB_LeftSidebar_MenuContainerAA_Hover_BorderStyleTop
                      }
                      DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderStyleTop={
                        DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderStyleTop
                      }
                      DB_LeftSidebar_MenuContainerAA_Hover_BorderColorTop={
                        DB_LeftSidebar_MenuContainerAA_Hover_BorderColorTop
                      }
                      DB_LeftSidebar_MenuContainerAA_Hover_BorderBottom={
                        DB_LeftSidebar_MenuContainerAA_Hover_BorderBottom
                      }
                      DB_LeftSidebar_MenuContainerAA_Hover_BorderStyleBottom={
                        DB_LeftSidebar_MenuContainerAA_Hover_BorderStyleBottom
                      }
                      DB_LeftSidebar_MenuContainerAA_Hover_BorderColorBottom={
                        DB_LeftSidebar_MenuContainerAA_Hover_BorderColorBottom
                      }
                      DB_LeftSidebar_MenuContainerAA_Hover_BorderLeft={
                        DB_LeftSidebar_MenuContainerAA_Hover_BorderLeft
                      }
                      DB_LeftSidebar_MenuContainerAA_Hover_BorderStyleLeft={
                        DB_LeftSidebar_MenuContainerAA_Hover_BorderStyleLeft
                      }
                      DB_LeftSidebar_MenuContainerAA_Hover_BorderColorLeft={
                        DB_LeftSidebar_MenuContainerAA_Hover_BorderColorLeft
                      }
                      DB_LeftSidebar_MenuContainerAA_Hover_BorderRight={
                        DB_LeftSidebar_MenuContainerAA_Hover_BorderRight
                      }
                      DB_LeftSidebar_MenuContainerAA_Hover_BorderStyleRight={
                        DB_LeftSidebar_MenuContainerAA_Hover_BorderStyleRight
                      }
                      DB_LeftSidebar_MenuContainerAA_Hover_BorderColorRight={
                        DB_LeftSidebar_MenuContainerAA_Hover_BorderColorRight
                      }
                      DB_LeftSidebar_MenuItem_MenuContainerAA_Hover_BorderTopRightRadius={
                        DB_LeftSidebar_MenuItem_MenuContainerAA_Hover_BorderTopRightRadius
                      }
                      DB_LeftSidebar_MenuItem_MenuContainerAA_Hover_BorderTopLeftRadius={
                        DB_LeftSidebar_MenuItem_MenuContainerAA_Hover_BorderTopLeftRadius
                      }
                      DB_LeftSidebar_MenuItem_MenuContainerAA_Hover_BorderBottomRightRadius={
                        DB_LeftSidebar_MenuItem_MenuContainerAA_Hover_BorderBottomRightRadius
                      }
                      DB_LeftSidebar_MenuItem_MenuContainerAA_Hover_BorderBottomLeftRadius={
                        DB_LeftSidebar_MenuItem_MenuContainerAA_Hover_BorderBottomLeftRadius
                      }
                      DB_LeftSidebar_MenuItem_MenuContainerAA_Hover_paddingLeft={
                        DB_LeftSidebar_MenuItem_MenuContainerAA_Hover_paddingLeft
                      }
                      DB_LeftSidebar_MenuItem_MenuContainerAA_Hover_paddingRight={
                        DB_LeftSidebar_MenuItem_MenuContainerAA_Hover_paddingRight
                      }
                      DB_LeftSidebar_MenuItem_MenuContainerAA_Hover_paddingTop={
                        DB_LeftSidebar_MenuItem_MenuContainerAA_Hover_paddingTop
                      }
                      DB_LeftSidebar_MenuItem_MenuContainerAA_Hover_paddingBottom={
                        DB_LeftSidebar_MenuItem_MenuContainerAA_Hover_paddingBottom
                      }
                      DB_LeftSidebar_MenuItem_MenuContainerAA_Hover_ShadowHorizontal={
                        DB_LeftSidebar_MenuItem_MenuContainerAA_Hover_ShadowHorizontal
                      }
                      DB_LeftSidebar_MenuItem_MenuContainerAA_Hover_ShadowVertical={
                        DB_LeftSidebar_MenuItem_MenuContainerAA_Hover_ShadowVertical
                      }
                      DB_LeftSidebar_MenuItem_MenuContainerAA_Hover_ShadowBlur={
                        DB_LeftSidebar_MenuItem_MenuContainerAA_Hover_ShadowBlur
                      }
                      DB_LeftSidebar_MenuItem_MenuContainerAA_Hover_ShadowColor={
                        DB_LeftSidebar_MenuItem_MenuContainerAA_Hover_ShadowColor
                      }
                      DB_LeftSidebar_MenuItem_HauptmenuTopAA_FontWeight={
                        DB_LeftSidebar_MenuItem_HauptmenuTopAA_FontWeight
                      }
                      DB_LeftSidebar_MenuItem_HauptmenuTopAA_FontColor={
                        DB_LeftSidebar_MenuItem_HauptmenuTopAA_FontColor
                      }
                      DB_LeftSidebar_MenuItem_HauptmenuTopAA_FontSize={
                        DB_LeftSidebar_MenuItem_HauptmenuTopAA_FontSize
                      }
                      DB_LeftSidebar_MenuItem_HauptmenuTopAA_BackgroundColor={
                        DB_LeftSidebar_MenuItem_HauptmenuTopAA_BackgroundColor
                      }
                      DB_LeftSidebar_MenuItem_HauptmenuTopAA_TextAlign={
                        DB_LeftSidebar_MenuItem_HauptmenuTopAA_TextAlign
                      }
                      DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderTop={
                        DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderTop
                      }
                      DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderStyleTop={
                        DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderStyleTop
                      }
                      DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderColorTop={
                        DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderColorTop
                      }
                      DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderBottom={
                        DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderBottom
                      }
                      DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderBottom={
                        DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderBottom
                      }
                      DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderStyleBottom={
                        DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderStyleBottom
                      }
                      DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderColorBottom={
                        DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderColorBottom
                      }
                      DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderLeft={
                        DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderLeft
                      }
                      DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderStyleLeft={
                        DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderStyleLeft
                      }
                      DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderColorLeft={
                        DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderColorLeft
                      }
                      DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderRight={
                        DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderRight
                      }
                      DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderStyleRight={
                        DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderStyleRight
                      }
                      DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderColorRight={
                        DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderColorRight
                      }
                      DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderTopRightRadius={
                        DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderTopRightRadius
                      }
                      DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderTopLeftRadius={
                        DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderTopLeftRadius
                      }
                      DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderBottomRightRadius={
                        DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderBottomRightRadius
                      }
                      DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderBottomLeftRadius={
                        DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderBottomLeftRadius
                      }
                      DB_LeftSidebar_MenuItem_HauptmenuTopAA_marginLeftRight={
                        DB_LeftSidebar_MenuItem_HauptmenuTopAA_marginLeftRight
                      }
                      DB_LeftSidebar_MenuItem_HauptmenuTopAA_marginTopBottom={
                        DB_LeftSidebar_MenuItem_HauptmenuTopAA_marginTopBottom
                      }
                      DB_LeftSidebar_MenuItem_HauptmenuTopAA_paddingLeftRight={
                        DB_LeftSidebar_MenuItem_HauptmenuTopAA_paddingLeftRight
                      }
                      DB_LeftSidebar_MenuItem_HauptmenuTopAA_paddingTopBottom={
                        DB_LeftSidebar_MenuItem_HauptmenuTopAA_paddingTopBottom
                      }
                      DB_LeftSidebar_MenuItem_HauptmenuTopAA_letterSpacing={
                        DB_LeftSidebar_MenuItem_HauptmenuTopAA_letterSpacing
                      }
                      DB_LeftSidebar_MenuItem_HauptmenuTopAA_ShadowHorizontal={
                        DB_LeftSidebar_MenuItem_HauptmenuTopAA_ShadowHorizontal
                      }
                      DB_LeftSidebar_MenuItem_HauptmenuTopAA_ShadowVertical={
                        DB_LeftSidebar_MenuItem_HauptmenuTopAA_ShadowVertical
                      }
                      DB_LeftSidebar_MenuItem_HauptmenuTopAA_ShadowBlur={
                        DB_LeftSidebar_MenuItem_HauptmenuTopAA_ShadowBlur
                      }
                      DB_LeftSidebar_MenuItem_HauptmenuTopAA_ShadowColor={
                        DB_LeftSidebar_MenuItem_HauptmenuTopAA_ShadowColor
                      }
                      DB_LeftSidebar_MenuItem_HauptmenuTopAA_TextDecoration={
                        DB_LeftSidebar_MenuItem_HauptmenuTopAA_TextDecoration
                      }
                      DB_LeftSidebar_MenuItem_HauptmenuTopAA_TextDecorationStyle={
                        DB_LeftSidebar_MenuItem_HauptmenuTopAA_TextDecorationStyle
                      }
                      DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_FontWeight={
                        DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_FontWeight
                      }
                      DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_FontColor={
                        DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_FontColor
                      }
                      DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_FontSize={
                        DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_FontSize
                      }
                      DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BackgroundColor={
                        DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BackgroundColor
                      }
                      DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_TextAlign={
                        DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_TextAlign
                      }
                      DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderTop={
                        DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderTop
                      }
                      DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderColorTop={
                        DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderColorTop
                      }
                      DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderColorBottom={
                        DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderColorBottom
                      }
                      DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderStyleBottom={
                        DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderStyleBottom
                      }
                      DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderLeft={
                        DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderLeft
                      }
                      DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderStyleLeft={
                        DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderStyleLeft
                      }
                      DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderColorLeft={
                        DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderColorLeft
                      }
                      DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderColorRight={
                        DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderColorRight
                      }
                      DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderRight={
                        DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderRight
                      }
                      DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderStyleRight={
                        DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderStyleRight
                      }
                      DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderTopRightRadius={
                        DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderTopRightRadius
                      }
                      DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderTopLeftRadius={
                        DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderTopLeftRadius
                      }
                      DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderBottomRightRadius={
                        DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderBottomRightRadius
                      }
                      DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderBottomLeftRadius={
                        DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderBottomLeftRadius
                      }
                      DB_LeftSidebar_MenuItem_HauptmenuTopAA_marginLeftRight_Hover={
                        DB_LeftSidebar_MenuItem_HauptmenuTopAA_marginLeftRight_Hover
                      }
                      DB_LeftSidebar_MenuItem_HauptmenuTopAA_marginTopBottom_Hover={
                        DB_LeftSidebar_MenuItem_HauptmenuTopAA_marginTopBottom_Hover
                      }
                      DB_LeftSidebar_MenuItem_HauptmenuTopAA_paddingLeftRight_Hover={
                        DB_LeftSidebar_MenuItem_HauptmenuTopAA_paddingLeftRight_Hover
                      }
                      DB_LeftSidebar_MenuItem_HauptmenuTopAA_paddingTopBottom_Hover={
                        DB_LeftSidebar_MenuItem_HauptmenuTopAA_paddingTopBottom_Hover
                      }
                      DB_LeftSidebar_MenuItem_HauptmenuTopAA_letterSpacing_Hover={
                        DB_LeftSidebar_MenuItem_HauptmenuTopAA_letterSpacing_Hover
                      }
                      DB_LeftSidebar_MenuItem_HauptmenuTopAA_ShadowHorizontal_Hover={
                        DB_LeftSidebar_MenuItem_HauptmenuTopAA_ShadowHorizontal_Hover
                      }
                      DB_LeftSidebar_MenuItem_HauptmenuTopAA_ShadowVertical_Hover={
                        DB_LeftSidebar_MenuItem_HauptmenuTopAA_ShadowVertical_Hover
                      }
                      DB_LeftSidebar_MenuItem_HauptmenuTopAA_ShadowBlur_Hover={
                        DB_LeftSidebar_MenuItem_HauptmenuTopAA_ShadowBlur_Hover
                      }
                      DB_LeftSidebar_MenuItem_HauptmenuTopAA_ShadowColor_Hover={
                        DB_LeftSidebar_MenuItem_HauptmenuTopAA_ShadowColor_Hover
                      }
                      DB_LeftSidebar_MenuItem_HauptmenuTopAA_TextDecoration_Hover={
                        DB_LeftSidebar_MenuItem_HauptmenuTopAA_TextDecoration_Hover
                      }
                      DB_LeftSidebar_MenuItem_HauptmenuTopAA_TextDecorationStyle_Hover={
                        DB_LeftSidebar_MenuItem_HauptmenuTopAA_TextDecorationStyle_Hover
                      }
                    />
                  </Navbar>
                )}
                {ProductFilterIsVisibleRight && (
                  <ProductFilterTopBarSpecialRight
                    paginationStart={paginationStart}
                    paginationEnd={paginationEnd}
                    allProductsStatePagination={allProductsStatePagination}
                    productBefore={productBefore}
                    productNext={productNext}
                    columnNumber={columnNumber}
                    pageAnzahlCounter={pageAnzahlCounter}
                    DB_fontFace_FontFamilyName={DB_fontFace_FontFamilyName}
                    columnNumberChange={columnNumberChange}
                    changePreisAlphabeticUswHandler={
                      changePreisAlphabeticUswHandler
                    }
                    SideBarLeft_SortierungTop_Farbe_Groesse_Stil_OnMouseOverAndOut={
                      SideBarLeft_SortierungTop_Farbe_Groesse_Stil_OnMouseOverAndOut
                    }
                    SideBarLeft_SortierungTop_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut={
                      SideBarLeft_SortierungTop_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
                    }
                    SideBarLeft_SortierungTop_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut={
                      SideBarLeft_SortierungTop_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut
                    }
                    SideBarLeft_SortierungTop_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut={
                      SideBarLeft_SortierungTop_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut
                    }
                    DB_fontFace_FilterTopBar_FontFamilyName={
                      DB_fontFace_FilterTopBar_FontFamilyName
                    }
                    DB_fontFace_FilterTopBar_FontFamilySrc={
                      DB_fontFace_FilterTopBar_FontFamilySrc
                    }
                    DB_LeftSidebar_MenuItem_SortierungTop_BackgroundColor={
                      DB_LeftSidebar_MenuItem_SortierungTop_BackgroundColor
                    }
                    DB_LeftSidebar_MenuItem_SortierungTop_FontColor={
                      DB_LeftSidebar_MenuItem_SortierungTop_FontColor
                    }
                    DB_LeftSidebar_MenuItem_SortierungTop_BorderTop={
                      DB_LeftSidebar_MenuItem_SortierungTop_BorderTop
                    }
                    DB_LeftSidebar_MenuItem_SortierungTop_BorderStyleTop={
                      DB_LeftSidebar_MenuItem_SortierungTop_BorderStyleTop
                    }
                    DB_LeftSidebar_MenuItem_SortierungTop_BorderColorTop={
                      DB_LeftSidebar_MenuItem_SortierungTop_BorderColorTop
                    }
                    DB_LeftSidebar_MenuItem_SortierungTop_BorderBottom={
                      DB_LeftSidebar_MenuItem_SortierungTop_BorderBottom
                    }
                    DB_LeftSidebar_MenuItem_SortierungTop_BorderStyleBottom={
                      DB_LeftSidebar_MenuItem_SortierungTop_BorderStyleBottom
                    }
                    DB_LeftSidebar_MenuItem_SortierungTop_BorderColorBottom={
                      DB_LeftSidebar_MenuItem_SortierungTop_BorderColorBottom
                    }
                    DB_LeftSidebar_MenuItem_SortierungTop_BorderLeft={
                      DB_LeftSidebar_MenuItem_SortierungTop_BorderLeft
                    }
                    DB_LeftSidebar_MenuItem_SortierungTop_BorderStyleLeft={
                      DB_LeftSidebar_MenuItem_SortierungTop_BorderStyleLeft
                    }
                    DB_LeftSidebar_MenuItem_SortierungTop_BorderColorLeft={
                      DB_LeftSidebar_MenuItem_SortierungTop_BorderColorLeft
                    }
                    DB_LeftSidebar_MenuItem_SortierungTop_BorderRight={
                      DB_LeftSidebar_MenuItem_SortierungTop_BorderRight
                    }
                    DB_LeftSidebar_MenuItem_SortierungTop_BorderStyleRight={
                      DB_LeftSidebar_MenuItem_SortierungTop_BorderStyleRight
                    }
                    DB_LeftSidebar_MenuItem_SortierungTop_BorderColorRight={
                      DB_LeftSidebar_MenuItem_SortierungTop_BorderColorRight
                    }
                    DB_LeftSidebar_MenuItem_SortierungTop_BorderTopRightRadius={
                      DB_LeftSidebar_MenuItem_SortierungTop_BorderTopRightRadius
                    }
                    DB_LeftSidebar_MenuItem_SortierungTop_BorderTopLeftRadius={
                      DB_LeftSidebar_MenuItem_SortierungTop_BorderTopLeftRadius
                    }
                    DB_LeftSidebar_MenuItem_SortierungTop_BorderBottomRightRadius={
                      DB_LeftSidebar_MenuItem_SortierungTop_BorderBottomRightRadius
                    }
                    DB_LeftSidebar_MenuItem_SortierungTop_BorderBottomLeftRadius={
                      DB_LeftSidebar_MenuItem_SortierungTop_BorderBottomLeftRadius
                    }
                    DB_LeftSidebar_MenuItem_SortierungTop_ShadowHorizontal={
                      DB_LeftSidebar_MenuItem_SortierungTop_ShadowHorizontal
                    }
                    DB_LeftSidebar_MenuItem_SortierungTop_ShadowVertical={
                      DB_LeftSidebar_MenuItem_SortierungTop_ShadowVertical
                    }
                    DB_LeftSidebar_MenuItem_SortierungTop_ShadowBlur={
                      DB_LeftSidebar_MenuItem_SortierungTop_ShadowBlur
                    }
                    DB_LeftSidebar_MenuItem_SortierungTop_ShadowColor={
                      DB_LeftSidebar_MenuItem_SortierungTop_ShadowColor
                    }
                    DB_LeftSidebar_MenuItem_SortierungTop_Hover_BorderTop={
                      DB_LeftSidebar_MenuItem_SortierungTop_Hover_BorderTop
                    }
                    DB_LeftSidebar_MenuItem_SortierungTop_Hover_BorderStyleTop={
                      DB_LeftSidebar_MenuItem_SortierungTop_Hover_BorderStyleTop
                    }
                    DB_LeftSidebar_MenuItem_SortierungTop_Hover_BorderColorTop={
                      DB_LeftSidebar_MenuItem_SortierungTop_Hover_BorderColorTop
                    }
                    DB_LeftSidebar_MenuItem_SortierungTop_Hover_BorderBottom={
                      DB_LeftSidebar_MenuItem_SortierungTop_Hover_BorderBottom
                    }
                    DB_LeftSidebar_MenuItem_SortierungTop_Hover_BorderStyleBottom={
                      DB_LeftSidebar_MenuItem_SortierungTop_Hover_BorderStyleBottom
                    }
                    DB_LeftSidebar_MenuItem_SortierungTop_Hover_BorderColorBottom={
                      DB_LeftSidebar_MenuItem_SortierungTop_Hover_BorderColorBottom
                    }
                    DB_LeftSidebar_MenuItem_SortierungTop_Hover_BorderLeft={
                      DB_LeftSidebar_MenuItem_SortierungTop_Hover_BorderLeft
                    }
                    DB_LeftSidebar_MenuItem_SortierungTop_Hover_BorderStyleLeft={
                      DB_LeftSidebar_MenuItem_SortierungTop_Hover_BorderStyleLeft
                    }
                    DB_LeftSidebar_MenuItem_SortierungTop_Hover_BorderColorLeft={
                      DB_LeftSidebar_MenuItem_SortierungTop_Hover_BorderColorLeft
                    }
                    DB_LeftSidebar_MenuItem_SortierungTop_Hover_BorderRight={
                      DB_LeftSidebar_MenuItem_SortierungTop_Hover_BorderRight
                    }
                    DB_LeftSidebar_MenuItem_SortierungTop_Hover_BorderStyleRight={
                      DB_LeftSidebar_MenuItem_SortierungTop_Hover_BorderStyleRight
                    }
                    DB_LeftSidebar_MenuItem_SortierungTop_Hover_BorderColorRight={
                      DB_LeftSidebar_MenuItem_SortierungTop_Hover_BorderColorRight
                    }
                    DB_LeftSidebar_MenuItem_SortierungTop_Hover_BorderTopRightRadius={
                      DB_LeftSidebar_MenuItem_SortierungTop_Hover_BorderTopRightRadius
                    }
                    DB_LeftSidebar_MenuItem_SortierungTop_Hover_BorderTopLeftRadius={
                      DB_LeftSidebar_MenuItem_SortierungTop_Hover_BorderTopLeftRadius
                    }
                    DB_LeftSidebar_MenuItem_SortierungTop_Hover_BorderBottomRightRadius={
                      DB_LeftSidebar_MenuItem_SortierungTop_Hover_BorderBottomRightRadius
                    }
                    DB_LeftSidebar_MenuItem_SortierungTop_Hover_BorderBottomLeftRadius={
                      DB_LeftSidebar_MenuItem_SortierungTop_Hover_BorderBottomLeftRadius
                    }
                    DB_LeftSidebar_MenuItem_SortierungTop_Hover_ShadowHorizontal={
                      DB_LeftSidebar_MenuItem_SortierungTop_Hover_ShadowHorizontal
                    }
                    DB_LeftSidebar_MenuItem_SortierungTop_Hover_ShadowVertical={
                      DB_LeftSidebar_MenuItem_SortierungTop_Hover_ShadowVertical
                    }
                    DB_LeftSidebar_MenuItem_SortierungTop_Hover_ShadowBlur={
                      DB_LeftSidebar_MenuItem_SortierungTop_Hover_ShadowBlur
                    }
                    DB_LeftSidebar_MenuItem_SortierungTop_Hover_ShadowColor={
                      DB_LeftSidebar_MenuItem_SortierungTop_Hover_ShadowColor
                    }
                    DB_LeftSidebar_MenuItem_SortierungTop_Hover_FontColor={
                      DB_LeftSidebar_MenuItem_SortierungTop_Hover_FontColor
                    }
                    DB_LeftSidebar_MenuItem_SortierungTop_Hover_BackgroundColor={
                      DB_LeftSidebar_MenuItem_SortierungTop_Hover_BackgroundColor
                    }
                  />
                )}
                {specialRightCategoriesVisible && (
                  <SideBarLeftKategories
                    trace={traceCounter}
                    resetFilterHandler={resetFilterHandler}
                    ultraCategoriesNew={ultraCategoriesNew}
                    categoryLeftSideHandler={categoryLeftSideHandler}
                    DB_fontFace_FontFamilyName={DB_fontFace_FontFamilyName}
                    userOwnsThisSite={userOwnsThisSite}
                    SideBarLeft_Container_Farbe_Groesse_Stil_OnMouseOverAndOut={
                      SideBarLeft_Container_Farbe_Groesse_Stil_OnMouseOverAndOut
                    }
                    SideBarLeft_Container_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut={
                      SideBarLeft_Container_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
                    }
                    SideBarLeft_Container_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut={
                      SideBarLeft_Container_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut
                    }
                    SideBarLeft_Container_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut={
                      SideBarLeft_Container_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut
                    }
                    SideBarLeft_AlleProdukte_Farbe_Groesse_Stil_OnMouseOverAndOut={
                      SideBarLeft_AlleProdukte_Farbe_Groesse_Stil_OnMouseOverAndOut
                    }
                    SideBarLeft_AlleProdukte_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut={
                      SideBarLeft_AlleProdukte_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
                    }
                    SideBarLeft_AlleProdukte_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut={
                      SideBarLeft_AlleProdukte_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut
                    }
                    SideBarLeft_AlleProdukte_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut={
                      SideBarLeft_AlleProdukte_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut
                    }
                    SideBarLeft_Hauptkategorie_Farbe_Groesse_Stil_OnMouseOverAndOut={
                      SideBarLeft_Hauptkategorie_Farbe_Groesse_Stil_OnMouseOverAndOut
                    }
                    SideBarLeft_Hauptkategorie_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut={
                      SideBarLeft_Hauptkategorie_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
                    }
                    SideBarLeft_Hauptkategorie_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut={
                      SideBarLeft_Hauptkategorie_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut
                    }
                    SideBarLeft_Hauptkategorie_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut={
                      SideBarLeft_Hauptkategorie_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut
                    }
                    SideBarLeft_Unterkategorie_Farbe_Groesse_Stil_OnMouseOverAndOut={
                      SideBarLeft_Unterkategorie_Farbe_Groesse_Stil_OnMouseOverAndOut
                    }
                    SideBarLeft_Unterkategorie_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut={
                      SideBarLeft_Unterkategorie_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
                    }
                    SideBarLeft_Unterkategorie_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut={
                      SideBarLeft_Unterkategorie_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut
                    }
                    SideBarLeft_Unterkategorie_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut={
                      SideBarLeft_Unterkategorie_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut
                    }
                    SideBarLeft_UnterUnterkategorie_Farbe_Groesse_Stil_OnMouseOverAndOut={
                      SideBarLeft_UnterUnterkategorie_Farbe_Groesse_Stil_OnMouseOverAndOut
                    }
                    SideBarLeft_UnterUnterkategorie_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut={
                      SideBarLeft_UnterUnterkategorie_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
                    }
                    SideBarLeft_UnterUnterkategorie_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut={
                      SideBarLeft_UnterUnterkategorie_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut
                    }
                    SideBarLeft_UnterUnterkategorie_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut={
                      SideBarLeft_UnterUnterkategorie_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut
                    }
                    DB_fontFace_alleProdukteAuswahlBB_FontFamilyName={
                      DB_fontFace_alleProdukteAuswahlBB_FontFamilyName
                    }
                    DB_fontFace_alleProdukteAuswahlBB_FontFamilySrc={
                      DB_fontFace_alleProdukteAuswahlBB_FontFamilySrc
                    }
                    DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_FontWeight={
                      DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_FontWeight
                    }
                    DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_FontColor={
                      DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_FontColor
                    }
                    DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_FontSize={
                      DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_FontSize
                    }
                    DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_BackgroundColor={
                      DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_BackgroundColor
                    }
                    DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_BorderTop={
                      DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_BorderTop
                    }
                    DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_BorderStyleTop={
                      DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_BorderStyleTop
                    }
                    DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_BorderColorTop={
                      DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_BorderColorTop
                    }
                    DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_BorderBottom={
                      DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_BorderBottom
                    }
                    DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_BorderStyleBottom={
                      DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_BorderStyleBottom
                    }
                    DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_BorderColorBottom={
                      DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_BorderColorBottom
                    }
                    DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_BorderLeft={
                      DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_BorderLeft
                    }
                    DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_BorderStyleLeft={
                      DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_BorderStyleLeft
                    }
                    DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_BorderColorLeft={
                      DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_BorderColorLeft
                    }
                    DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_BorderRight={
                      DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_BorderRight
                    }
                    DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_BorderStyleRight={
                      DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_BorderStyleRight
                    }
                    DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_BorderColorRight={
                      DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_BorderColorRight
                    }
                    DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_BorderTopRightRadius={
                      DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_BorderTopRightRadius
                    }
                    DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_BorderTopLeftRadius={
                      DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_BorderTopLeftRadius
                    }
                    DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_BorderBottomRightRadius={
                      DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_BorderBottomRightRadius
                    }
                    DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_BorderBottomLeftRadius={
                      DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_BorderBottomLeftRadius
                    }
                    DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_marginLeftRight={
                      DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_marginLeftRight
                    }
                    DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_marginTopBottom={
                      DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_marginTopBottom
                    }
                    DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_paddingLeftRight={
                      DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_paddingLeftRight
                    }
                    DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_paddingTopBottom={
                      DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_paddingTopBottom
                    }
                    DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_letterSpacing={
                      DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_letterSpacing
                    }
                    DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_ShadowHorizontal={
                      DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_ShadowHorizontal
                    }
                    DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_ShadowVertical={
                      DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_ShadowVertical
                    }
                    DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_ShadowBlur={
                      DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_ShadowBlur
                    }
                    DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_ShadowColor={
                      DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_ShadowColor
                    }
                    DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_TextDecoration={
                      DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_TextDecoration
                    }
                    DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_TextDecorationStyle={
                      DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_TextDecorationStyle
                    }
                    DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_TextAlign={
                      DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_TextAlign
                    }
                    DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_FontWeight={
                      DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_FontWeight
                    }
                    DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_FontColor={
                      DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_FontColor
                    }
                    DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_FontSize={
                      DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_FontSize
                    }
                    DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_BackgroundColor={
                      DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_BackgroundColor
                    }
                    DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_TextAlign={
                      DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_TextAlign
                    }
                    DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_BorderTop={
                      DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_BorderTop
                    }
                    DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_BorderStyleTop={
                      DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_BorderStyleTop
                    }
                    DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_BorderColorTop={
                      DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_BorderColorTop
                    }
                    DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_BorderBottom={
                      DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_BorderBottom
                    }
                    DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_BorderStyleBottom={
                      DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_BorderStyleBottom
                    }
                    DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_BorderColorBottom={
                      DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_BorderColorBottom
                    }
                    DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_BorderLeft={
                      DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_BorderLeft
                    }
                    DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_BorderStyleLeft={
                      DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_BorderStyleLeft
                    }
                    DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_BorderColorLeft={
                      DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_BorderColorLeft
                    }
                    DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_BorderRight={
                      DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_BorderRight
                    }
                    DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_BorderStyleRight={
                      DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_BorderStyleRight
                    }
                    DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_BorderColorRight={
                      DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_BorderColorRight
                    }
                    DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_BorderTopRightRadius={
                      DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_BorderTopRightRadius
                    }
                    DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_BorderTopLeftRadius={
                      DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_BorderTopLeftRadius
                    }
                    DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_BorderBottomRightRadius={
                      DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_BorderBottomRightRadius
                    }
                    DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_BorderBottomLeftRadius={
                      DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_Hover_BorderBottomLeftRadius
                    }
                    DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_marginLeftRight_Hover={
                      DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_marginLeftRight_Hover
                    }
                    DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_marginTopBottom_Hover={
                      DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_marginTopBottom_Hover
                    }
                    DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_paddingLeftRight_Hover={
                      DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_paddingLeftRight_Hover
                    }
                    DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_paddingTopBottom_Hover={
                      DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_paddingTopBottom_Hover
                    }
                    DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_letterSpacing_Hover={
                      DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_letterSpacing_Hover
                    }
                    DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_ShadowHorizontal_Hover={
                      DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_ShadowHorizontal_Hover
                    }
                    DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_ShadowVertical_Hover={
                      DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_ShadowVertical_Hover
                    }
                    DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_ShadowBlur_Hover={
                      DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_ShadowBlur_Hover
                    }
                    DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_ShadowColor_Hover={
                      DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_ShadowColor_Hover
                    }
                    DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_TextDecoration_Hover={
                      DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_TextDecoration_Hover
                    }
                    DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_TextDecorationStyle_Hover={
                      DB_LeftSidebar_MenuItem_TitelUltraKatZuruecksetzenBB_TextDecorationStyle_Hover
                    }
                    DB_fontFace_hauptkategorieTitel_FontFamilyName={
                      DB_fontFace_hauptkategorieTitel_FontFamilyName
                    }
                    DB_LeftSidebar_MenuItem_Hauptkategorie_FontFace={
                      DB_LeftSidebar_MenuItem_Hauptkategorie_FontFace
                    }
                    DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_FontFace={
                      DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_FontFace
                    }
                    DB_LeftSidebar_MenuItem_Hauptkategorie_FontWeight={
                      DB_LeftSidebar_MenuItem_Hauptkategorie_FontWeight
                    }
                    DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_FontWeight={
                      DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_FontWeight
                    }
                    DB_LeftSidebar_MenuItem_Hauptkategorie_FontColor={
                      DB_LeftSidebar_MenuItem_Hauptkategorie_FontColor
                    }
                    DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_FontColor={
                      DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_FontColor
                    }
                    DB_LeftSidebar_MenuItem_Hauptkategorie_FontSize={
                      DB_LeftSidebar_MenuItem_Hauptkategorie_FontSize
                    }
                    DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_FontSize={
                      DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_FontSize
                    }
                    SideBarLeft_Hauptkategorie_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut={
                      SideBarLeft_Hauptkategorie_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut
                    }
                    DB_LeftSidebar_MenuItem_Hauptkategorie_BackgroundColor={
                      DB_LeftSidebar_MenuItem_Hauptkategorie_BackgroundColor
                    }
                    DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_BackgroundColor={
                      DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_BackgroundColor
                    }
                    DB_LeftSidebar_MenuItem_Hauptkategorie_TextAlign={
                      DB_LeftSidebar_MenuItem_Hauptkategorie_TextAlign
                    }
                    DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_TextAlign={
                      DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_TextAlign
                    }
                    SideBarLeft_Hauptkategorie_Farbe_Groesse_Stil_OnMouseOverAndOut={
                      SideBarLeft_Hauptkategorie_Farbe_Groesse_Stil_OnMouseOverAndOut
                    }
                    SideBarLeft_Hauptkategorie_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut={
                      SideBarLeft_Hauptkategorie_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
                    }
                    DB_LeftSidebar_MenuItem_Hauptkategorie_BorderTop={
                      DB_LeftSidebar_MenuItem_Hauptkategorie_BorderTop
                    }
                    DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_BorderTop={
                      DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_BorderTop
                    }
                    DB_LeftSidebar_MenuItem_Hauptkategorie_BorderStyleTop={
                      DB_LeftSidebar_MenuItem_Hauptkategorie_BorderStyleTop
                    }
                    DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_BorderStyleTop={
                      DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_BorderStyleTop
                    }
                    DB_LeftSidebar_MenuItem_Hauptkategorie_BorderColorTop={
                      DB_LeftSidebar_MenuItem_Hauptkategorie_BorderColorTop
                    }
                    DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_BorderColorTop={
                      DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_BorderColorTop
                    }
                    DB_LeftSidebar_MenuItem_Hauptkategorie_BorderBottom={
                      DB_LeftSidebar_MenuItem_Hauptkategorie_BorderBottom
                    }
                    DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_BorderBottom={
                      DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_BorderBottom
                    }
                    DB_LeftSidebar_MenuItem_Hauptkategorie_BorderStyleBottom={
                      DB_LeftSidebar_MenuItem_Hauptkategorie_BorderStyleBottom
                    }
                    DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_BorderStyleBottom={
                      DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_BorderStyleBottom
                    }
                    DB_LeftSidebar_MenuItem_Hauptkategorie_BorderColorBottom={
                      DB_LeftSidebar_MenuItem_Hauptkategorie_BorderColorBottom
                    }
                    DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_BorderColorBottom={
                      DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_BorderColorBottom
                    }
                    DB_LeftSidebar_MenuItem_Hauptkategorie_BorderLeft={
                      DB_LeftSidebar_MenuItem_Hauptkategorie_BorderLeft
                    }
                    DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_BorderLeft={
                      DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_BorderLeft
                    }
                    DB_LeftSidebar_MenuItem_Hauptkategorie_BorderStyleLeft={
                      DB_LeftSidebar_MenuItem_Hauptkategorie_BorderStyleLeft
                    }
                    DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_BorderStyleLeft={
                      DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_BorderStyleLeft
                    }
                    DB_LeftSidebar_MenuItem_Hauptkategorie_BorderColorLeft={
                      DB_LeftSidebar_MenuItem_Hauptkategorie_BorderColorLeft
                    }
                    DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_BorderColorLeft={
                      DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_BorderColorLeft
                    }
                    DB_LeftSidebar_MenuItem_Hauptkategorie_BorderRight={
                      DB_LeftSidebar_MenuItem_Hauptkategorie_BorderRight
                    }
                    DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_BorderRight={
                      DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_BorderRight
                    }
                    DB_LeftSidebar_MenuItem_Hauptkategorie_BorderStyleRight={
                      DB_LeftSidebar_MenuItem_Hauptkategorie_BorderStyleRight
                    }
                    DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_BorderStyleRight={
                      DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_BorderStyleRight
                    }
                    DB_LeftSidebar_MenuItem_Hauptkategorie_BorderColorRight={
                      DB_LeftSidebar_MenuItem_Hauptkategorie_BorderColorRight
                    }
                    DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_BorderColorRight={
                      DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_BorderColorRight
                    }
                    DB_LeftSidebar_MenuItem_Hauptkategorie_BorderTopRightRadius={
                      DB_LeftSidebar_MenuItem_Hauptkategorie_BorderTopRightRadius
                    }
                    DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_BorderTopRightRadius={
                      DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_BorderTopRightRadius
                    }
                    DB_LeftSidebar_MenuItem_Hauptkategorie_BorderTopLeftRadius={
                      DB_LeftSidebar_MenuItem_Hauptkategorie_BorderTopLeftRadius
                    }
                    DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_BorderTopLeftRadius={
                      DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_BorderTopLeftRadius
                    }
                    DB_LeftSidebar_MenuItem_Hauptkategorie_BorderBottomRightRadius={
                      DB_LeftSidebar_MenuItem_Hauptkategorie_BorderBottomRightRadius
                    }
                    DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_BorderBottomRightRadius={
                      DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_BorderBottomRightRadius
                    }
                    DB_LeftSidebar_MenuItem_Hauptkategorie_BorderBottomLeftRadius={
                      DB_LeftSidebar_MenuItem_Hauptkategorie_BorderBottomLeftRadius
                    }
                    DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_BorderBottomLeftRadius={
                      DB_LeftSidebar_MenuItem_Hauptkategorie_Hover_BorderBottomLeftRadius
                    }
                    DB_fontFace_hauptkategorieTitel_FontFamilyName={
                      DB_fontFace_hauptkategorieTitel_FontFamilyName
                    }
                    DB_fontFace_hauptkategorieTitel_FontFamilySrc={
                      DB_fontFace_hauptkategorieTitel_FontFamilySrc
                    }
                    DB_LeftSidebar_MenuItem_Hauptkategorie_marginTopBottom={
                      DB_LeftSidebar_MenuItem_Hauptkategorie_marginTopBottom
                    }
                    DB_LeftSidebar_MenuItem_Hauptkategorie_marginLeftRight={
                      DB_LeftSidebar_MenuItem_Hauptkategorie_marginLeftRight
                    }
                    DB_LeftSidebar_MenuItem_Hauptkategorie_paddingLeftRight={
                      DB_LeftSidebar_MenuItem_Hauptkategorie_paddingLeftRight
                    }
                    DB_LeftSidebar_MenuItem_Hauptkategorie_paddingTopBottom={
                      DB_LeftSidebar_MenuItem_Hauptkategorie_paddingTopBottom
                    }
                    DB_LeftSidebar_MenuItem_Hauptkategorie_letterSpacing={
                      DB_LeftSidebar_MenuItem_Hauptkategorie_letterSpacing
                    }
                    DB_LeftSidebar_MenuItem_Hauptkategorie_ShadowHorizontal={
                      DB_LeftSidebar_MenuItem_Hauptkategorie_ShadowHorizontal
                    }
                    DB_LeftSidebar_MenuItem_Hauptkategorie_ShadowVertical={
                      DB_LeftSidebar_MenuItem_Hauptkategorie_ShadowVertical
                    }
                    DB_LeftSidebar_MenuItem_Hauptkategorie_ShadowBlur={
                      DB_LeftSidebar_MenuItem_Hauptkategorie_ShadowBlur
                    }
                    DB_LeftSidebar_MenuItem_Hauptkategorie_ShadowColor={
                      DB_LeftSidebar_MenuItem_Hauptkategorie_ShadowColor
                    }
                    DB_LeftSidebar_MenuItem_Hauptkategorie_TextDecoration={
                      DB_LeftSidebar_MenuItem_Hauptkategorie_TextDecoration
                    }
                    DB_LeftSidebar_MenuItem_Hauptkategorie_TextDecorationStyle={
                      DB_LeftSidebar_MenuItem_Hauptkategorie_TextDecorationStyle
                    }
                    DB_LeftSidebar_MenuItem_Hauptkategorie_marginTopBottom_Hover={
                      DB_LeftSidebar_MenuItem_Hauptkategorie_marginTopBottom_Hover
                    }
                    DB_LeftSidebar_MenuItem_Hauptkategorie_marginLeftRight_Hover={
                      DB_LeftSidebar_MenuItem_Hauptkategorie_marginLeftRight_Hover
                    }
                    DB_LeftSidebar_MenuItem_Hauptkategorie_paddingLeftRight_Hover={
                      DB_LeftSidebar_MenuItem_Hauptkategorie_paddingLeftRight_Hover
                    }
                    DB_LeftSidebar_MenuItem_Hauptkategorie_paddingTopBottom_Hover={
                      DB_LeftSidebar_MenuItem_Hauptkategorie_paddingTopBottom_Hover
                    }
                    DB_LeftSidebar_MenuItem_Hauptkategorie_letterSpacing_Hover={
                      DB_LeftSidebar_MenuItem_Hauptkategorie_letterSpacing_Hover
                    }
                    DB_LeftSidebar_MenuItem_Hauptkategorie_ShadowHorizontal_Hover={
                      DB_LeftSidebar_MenuItem_Hauptkategorie_ShadowHorizontal_Hover
                    }
                    DB_LeftSidebar_MenuItem_Hauptkategorie_ShadowVertical_Hover={
                      DB_LeftSidebar_MenuItem_Hauptkategorie_ShadowVertical_Hover
                    }
                    DB_LeftSidebar_MenuItem_Hauptkategorie_ShadowBlur_Hover={
                      DB_LeftSidebar_MenuItem_Hauptkategorie_ShadowBlur_Hover
                    }
                    DB_LeftSidebar_MenuItem_Hauptkategorie_ShadowColor_Hover={
                      DB_LeftSidebar_MenuItem_Hauptkategorie_ShadowColor_Hover
                    }
                    DB_LeftSidebar_MenuItem_Hauptkategorie_TextDecoration_Hover={
                      DB_LeftSidebar_MenuItem_Hauptkategorie_TextDecoration_Hover
                    }
                    DB_LeftSidebar_MenuItem_Hauptkategorie_TextDecorationStyle_Hover={
                      DB_LeftSidebar_MenuItem_Hauptkategorie_TextDecorationStyle_Hover
                    }
                    DB_fontFace_unterkategorieTitel_FontFamilyName={
                      DB_fontFace_unterkategorieTitel_FontFamilyName
                    }
                    DB_fontFace_unterkategorieTitel_FontFamilySrc={
                      DB_fontFace_unterkategorieTitel_FontFamilySrc
                    }
                    DB_LeftSidebar_MenuItem_Unterkategorie_FontFace={
                      DB_LeftSidebar_MenuItem_Unterkategorie_FontFace
                    }
                    DB_LeftSidebar_MenuItem_Unterkategorie_Hover_FontFace={
                      DB_LeftSidebar_MenuItem_Unterkategorie_Hover_FontFace
                    }
                    DB_LeftSidebar_MenuItem_Unterkategorie_FontWeight={
                      DB_LeftSidebar_MenuItem_Unterkategorie_FontWeight
                    }
                    DB_LeftSidebar_MenuItem_Unterkategorie_Hover_FontWeight={
                      DB_LeftSidebar_MenuItem_Unterkategorie_Hover_FontWeight
                    }
                    DB_LeftSidebar_MenuItem_Unterkategorie_FontColor={
                      DB_LeftSidebar_MenuItem_Unterkategorie_FontColor
                    }
                    DB_LeftSidebar_MenuItem_Unterkategorie_Hover_FontColor={
                      DB_LeftSidebar_MenuItem_Unterkategorie_Hover_FontColor
                    }
                    DB_LeftSidebar_MenuItem_Unterkategorie_FontSize={
                      DB_LeftSidebar_MenuItem_Unterkategorie_FontSize
                    }
                    DB_LeftSidebar_MenuItem_Unterkategorie_Hover_FontSize={
                      DB_LeftSidebar_MenuItem_Unterkategorie_Hover_FontSize
                    }
                    DB_LeftSidebar_MenuItem_Unterkategorie_BackgroundColor={
                      DB_LeftSidebar_MenuItem_Unterkategorie_BackgroundColor
                    }
                    DB_LeftSidebar_MenuItem_Unterkategorie_Hover_BackgroundColor={
                      DB_LeftSidebar_MenuItem_Unterkategorie_Hover_BackgroundColor
                    }
                    DB_LeftSidebar_MenuItem_Unterkategorie_TextAlign={
                      DB_LeftSidebar_MenuItem_Unterkategorie_TextAlign
                    }
                    DB_LeftSidebar_MenuItem_Unterkategorie_Hover_TextAlign={
                      DB_LeftSidebar_MenuItem_Unterkategorie_Hover_TextAlign
                    }
                    DB_LeftSidebar_MenuItem_Unterkategorie_BorderTop={
                      DB_LeftSidebar_MenuItem_Unterkategorie_BorderTop
                    }
                    DB_LeftSidebar_MenuItem_Unterkategorie_Hover_BorderTop={
                      DB_LeftSidebar_MenuItem_Unterkategorie_Hover_BorderTop
                    }
                    DB_LeftSidebar_MenuItem_Unterkategorie_BorderStyleTop={
                      DB_LeftSidebar_MenuItem_Unterkategorie_BorderStyleTop
                    }
                    DB_LeftSidebar_MenuItem_Unterkategorie_Hover_BorderStyleTop={
                      DB_LeftSidebar_MenuItem_Unterkategorie_Hover_BorderStyleTop
                    }
                    DB_LeftSidebar_MenuItem_Unterkategorie_BorderColorTop={
                      DB_LeftSidebar_MenuItem_Unterkategorie_BorderColorTop
                    }
                    DB_LeftSidebar_MenuItem_Unterkategorie_Hover_BorderColorTop={
                      DB_LeftSidebar_MenuItem_Unterkategorie_Hover_BorderColorTop
                    }
                    DB_LeftSidebar_MenuItem_Unterkategorie_BorderBottom={
                      DB_LeftSidebar_MenuItem_Unterkategorie_BorderBottom
                    }
                    DB_LeftSidebar_MenuItem_Unterkategorie_Hover_BorderBottom={
                      DB_LeftSidebar_MenuItem_Unterkategorie_Hover_BorderBottom
                    }
                    DB_LeftSidebar_MenuItem_Unterkategorie_BorderStyleBottom={
                      DB_LeftSidebar_MenuItem_Unterkategorie_BorderStyleBottom
                    }
                    DB_LeftSidebar_MenuItem_Unterkategorie_Hover_BorderStyleBottom={
                      DB_LeftSidebar_MenuItem_Unterkategorie_Hover_BorderStyleBottom
                    }
                    DB_LeftSidebar_MenuItem_Unterkategorie_BorderColorBottom={
                      DB_LeftSidebar_MenuItem_Unterkategorie_BorderColorBottom
                    }
                    DB_LeftSidebar_MenuItem_Unterkategorie_Hover_BorderColorBottom={
                      DB_LeftSidebar_MenuItem_Unterkategorie_Hover_BorderColorBottom
                    }
                    DB_LeftSidebar_MenuItem_Unterkategorie_BorderLeft={
                      DB_LeftSidebar_MenuItem_Unterkategorie_BorderLeft
                    }
                    DB_LeftSidebar_MenuItem_Unterkategorie_Hover_BorderLeft={
                      DB_LeftSidebar_MenuItem_Unterkategorie_Hover_BorderLeft
                    }
                    DB_LeftSidebar_MenuItem_Unterkategorie_BorderStyleLeft={
                      DB_LeftSidebar_MenuItem_Unterkategorie_BorderStyleLeft
                    }
                    DB_LeftSidebar_MenuItem_Unterkategorie_Hover_BorderStyleLeft={
                      DB_LeftSidebar_MenuItem_Unterkategorie_Hover_BorderStyleLeft
                    }
                    DB_LeftSidebar_MenuItem_Unterkategorie_BorderColorLeft={
                      DB_LeftSidebar_MenuItem_Unterkategorie_BorderColorLeft
                    }
                    DB_LeftSidebar_MenuItem_Unterkategorie_Hover_BorderColorLeft={
                      DB_LeftSidebar_MenuItem_Unterkategorie_Hover_BorderColorLeft
                    }
                    DB_LeftSidebar_MenuItem_Unterkategorie_BorderRight={
                      DB_LeftSidebar_MenuItem_Unterkategorie_BorderRight
                    }
                    DB_LeftSidebar_MenuItem_Unterkategorie_Hover_BorderRight={
                      DB_LeftSidebar_MenuItem_Unterkategorie_Hover_BorderRight
                    }
                    DB_LeftSidebar_MenuItem_Unterkategorie_BorderStyleRight={
                      DB_LeftSidebar_MenuItem_Unterkategorie_BorderStyleRight
                    }
                    DB_LeftSidebar_MenuItem_Unterkategorie_Hover_BorderStyleRight={
                      DB_LeftSidebar_MenuItem_Unterkategorie_Hover_BorderStyleRight
                    }
                    DB_LeftSidebar_MenuItem_Unterkategorie_BorderColorRight={
                      DB_LeftSidebar_MenuItem_Unterkategorie_BorderColorRight
                    }
                    DB_LeftSidebar_MenuItem_Unterkategorie_Hover_BorderColorRight={
                      DB_LeftSidebar_MenuItem_Unterkategorie_Hover_BorderColorRight
                    }
                    DB_LeftSidebar_MenuItem_Unterkategorie_BorderTopRightRadius={
                      DB_LeftSidebar_MenuItem_Unterkategorie_BorderTopRightRadius
                    }
                    DB_LeftSidebar_MenuItem_Unterkategorie_Hover_BorderTopRightRadius={
                      DB_LeftSidebar_MenuItem_Unterkategorie_Hover_BorderTopRightRadius
                    }
                    DB_LeftSidebar_MenuItem_Unterkategorie_BorderTopLeftRadius={
                      DB_LeftSidebar_MenuItem_Unterkategorie_BorderTopLeftRadius
                    }
                    DB_LeftSidebar_MenuItem_Unterkategorie_Hover_BorderTopLeftRadius={
                      DB_LeftSidebar_MenuItem_Unterkategorie_Hover_BorderTopLeftRadius
                    }
                    DB_LeftSidebar_MenuItem_Unterkategorie_BorderBottomRightRadius={
                      DB_LeftSidebar_MenuItem_Unterkategorie_BorderBottomRightRadius
                    }
                    DB_LeftSidebar_MenuItem_Unterkategorie_Hover_BorderBottomRightRadius={
                      DB_LeftSidebar_MenuItem_Unterkategorie_Hover_BorderBottomRightRadius
                    }
                    DB_LeftSidebar_MenuItem_Unterkategorie_BorderBottomLeftRadius={
                      DB_LeftSidebar_MenuItem_Unterkategorie_BorderBottomLeftRadius
                    }
                    DB_LeftSidebar_MenuItem_Unterkategorie_Hover_BorderBottomLeftRadius={
                      DB_LeftSidebar_MenuItem_Unterkategorie_Hover_BorderBottomLeftRadius
                    }
                    DB_LeftSidebar_MenuItem_Unterkategorie_marginTopBottom={
                      DB_LeftSidebar_MenuItem_Unterkategorie_marginTopBottom
                    }
                    DB_LeftSidebar_MenuItem_Unterkategorie_marginLeftRight={
                      DB_LeftSidebar_MenuItem_Unterkategorie_marginLeftRight
                    }
                    DB_LeftSidebar_MenuItem_Unterkategorie_paddingLeftRight={
                      DB_LeftSidebar_MenuItem_Unterkategorie_paddingLeftRight
                    }
                    DB_LeftSidebar_MenuItem_Unterkategorie_paddingTopBottom={
                      DB_LeftSidebar_MenuItem_Unterkategorie_paddingTopBottom
                    }
                    DB_LeftSidebar_MenuItem_Unterkategorie_letterSpacing={
                      DB_LeftSidebar_MenuItem_Unterkategorie_letterSpacing
                    }
                    DB_LeftSidebar_MenuItem_Unterkategorie_ShadowHorizontal={
                      DB_LeftSidebar_MenuItem_Unterkategorie_ShadowHorizontal
                    }
                    DB_LeftSidebar_MenuItem_Unterkategorie_ShadowVertical={
                      DB_LeftSidebar_MenuItem_Unterkategorie_ShadowVertical
                    }
                    DB_LeftSidebar_MenuItem_Unterkategorie_ShadowBlur={
                      DB_LeftSidebar_MenuItem_Unterkategorie_ShadowBlur
                    }
                    DB_LeftSidebar_MenuItem_Unterkategorie_ShadowColor={
                      DB_LeftSidebar_MenuItem_Unterkategorie_ShadowColor
                    }
                    DB_LeftSidebar_MenuItem_Unterkategorie_TextDecoration={
                      DB_LeftSidebar_MenuItem_Unterkategorie_TextDecoration
                    }
                    DB_LeftSidebar_MenuItem_Unterkategorie_TextDecorationStyle={
                      DB_LeftSidebar_MenuItem_Unterkategorie_TextDecorationStyle
                    }
                    DB_LeftSidebar_MenuItem_Unterkategorie_marginTopBottom_Hover={
                      DB_LeftSidebar_MenuItem_Unterkategorie_marginTopBottom_Hover
                    }
                    DB_LeftSidebar_MenuItem_Unterkategorie_marginLeftRight_Hover={
                      DB_LeftSidebar_MenuItem_Unterkategorie_marginLeftRight_Hover
                    }
                    DB_LeftSidebar_MenuItem_Unterkategorie_paddingLeftRight_Hover={
                      DB_LeftSidebar_MenuItem_Unterkategorie_paddingLeftRight_Hover
                    }
                    DB_LeftSidebar_MenuItem_Unterkategorie_paddingTopBottom_Hover={
                      DB_LeftSidebar_MenuItem_Unterkategorie_paddingTopBottom_Hover
                    }
                    DB_LeftSidebar_MenuItem_Unterkategorie_letterSpacing_Hover={
                      DB_LeftSidebar_MenuItem_Unterkategorie_letterSpacing_Hover
                    }
                    DB_LeftSidebar_MenuItem_Unterkategorie_ShadowHorizontal_Hover={
                      DB_LeftSidebar_MenuItem_Unterkategorie_ShadowHorizontal_Hover
                    }
                    DB_LeftSidebar_MenuItem_Unterkategorie_ShadowVertical_Hover={
                      DB_LeftSidebar_MenuItem_Unterkategorie_ShadowVertical_Hover
                    }
                    DB_LeftSidebar_MenuItem_Unterkategorie_ShadowBlur_Hover={
                      DB_LeftSidebar_MenuItem_Unterkategorie_ShadowBlur_Hover
                    }
                    DB_LeftSidebar_MenuItem_Unterkategorie_ShadowColor_Hover={
                      DB_LeftSidebar_MenuItem_Unterkategorie_ShadowColor_Hover
                    }
                    DB_LeftSidebar_MenuItem_Unterkategorie_TextDecoration_Hover={
                      DB_LeftSidebar_MenuItem_Unterkategorie_TextDecoration_Hover
                    }
                    DB_LeftSidebar_MenuItem_Unterkategorie_TextDecorationStyle_Hover={
                      DB_LeftSidebar_MenuItem_Unterkategorie_TextDecorationStyle_Hover
                    }
                    SideBarLeft_Unterkategorie_Farbe_Groesse_Stil_Trigger={
                      SideBarLeft_Unterkategorie_Farbe_Groesse_Stil_Trigger
                    }
                    SideBarLeft_Unterkategorie_Farbe_Groesse_Stil_Hover_Trigger={
                      SideBarLeft_Unterkategorie_Farbe_Groesse_Stil_Hover_Trigger
                    }
                    SideBarLeft_Unterkategorie_HintergrundFarbe_Radius_Stil_Trigger={
                      SideBarLeft_Unterkategorie_HintergrundFarbe_Radius_Stil_Trigger
                    }
                    SideBarLeft_Unterkategorie_HintergrundFarbe_Radius_Stil_Hover_Trigger={
                      SideBarLeft_Unterkategorie_HintergrundFarbe_Radius_Stil_Hover_Trigger
                    }
                    SideBarLeft_Unterkategorie_Farbe_Groesse_Stil_OnMouseOverAndOut={
                      SideBarLeft_Unterkategorie_Farbe_Groesse_Stil_OnMouseOverAndOut
                    }
                    SideBarLeft_Unterkategorie_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut={
                      SideBarLeft_Unterkategorie_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
                    }
                    SideBarLeft_Unterkategorie_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut={
                      SideBarLeft_Unterkategorie_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut
                    }
                    SideBarLeft_Unterkategorie_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut={
                      SideBarLeft_Unterkategorie_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut
                    }
                    DB_fontFace_unterunterkategorieTitel_FontFamilyName={
                      DB_fontFace_unterunterkategorieTitel_FontFamilyName
                    }
                    DB_fontFace_unterunterkategorieTitel_FontFamilySrc={
                      DB_fontFace_unterunterkategorieTitel_FontFamilySrc
                    }
                    DB_LeftSidebar_MenuItem_UnterUnterkategorie_FontFace={
                      DB_LeftSidebar_MenuItem_UnterUnterkategorie_FontFace
                    }
                    DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_FontFace={
                      DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_FontFace
                    }
                    DB_LeftSidebar_MenuItem_UnterUnterkategorie_FontWeight={
                      DB_LeftSidebar_MenuItem_UnterUnterkategorie_FontWeight
                    }
                    DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_FontWeight={
                      DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_FontWeight
                    }
                    DB_LeftSidebar_MenuItem_UnterUnterkategorie_FontColor={
                      DB_LeftSidebar_MenuItem_UnterUnterkategorie_FontColor
                    }
                    DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_FontColor={
                      DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_FontColor
                    }
                    DB_LeftSidebar_MenuItem_UnterUnterkategorie_FontSize={
                      DB_LeftSidebar_MenuItem_UnterUnterkategorie_FontSize
                    }
                    DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_FontSize={
                      DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_FontSize
                    }
                    DB_LeftSidebar_MenuItem_UnterUnterkategorie_BackgroundColor={
                      DB_LeftSidebar_MenuItem_UnterUnterkategorie_BackgroundColor
                    }
                    DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_BackgroundColor={
                      DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_BackgroundColor
                    }
                    DB_LeftSidebar_MenuItem_UnterUnterkategorie_TextAlign={
                      DB_LeftSidebar_MenuItem_UnterUnterkategorie_TextAlign
                    }
                    DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_TextAlign={
                      DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_TextAlign
                    }
                    DB_LeftSidebar_MenuItem_UnterUnterkategorie_BorderTop={
                      DB_LeftSidebar_MenuItem_UnterUnterkategorie_BorderTop
                    }
                    DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_BorderTop={
                      DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_BorderTop
                    }
                    DB_LeftSidebar_MenuItem_UnterUnterkategorie_BorderStyleTop={
                      DB_LeftSidebar_MenuItem_UnterUnterkategorie_BorderStyleTop
                    }
                    DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_BorderStyleTop={
                      DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_BorderStyleTop
                    }
                    DB_LeftSidebar_MenuItem_UnterUnterkategorie_BorderColorTop={
                      DB_LeftSidebar_MenuItem_UnterUnterkategorie_BorderColorTop
                    }
                    DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_BorderColorTop={
                      DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_BorderColorTop
                    }
                    DB_LeftSidebar_MenuItem_UnterUnterkategorie_BorderBottom={
                      DB_LeftSidebar_MenuItem_UnterUnterkategorie_BorderBottom
                    }
                    DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_BorderBottom={
                      DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_BorderBottom
                    }
                    DB_LeftSidebar_MenuItem_UnterUnterkategorie_BorderStyleBottom={
                      DB_LeftSidebar_MenuItem_UnterUnterkategorie_BorderStyleBottom
                    }
                    DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_BorderStyleBottom={
                      DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_BorderStyleBottom
                    }
                    DB_LeftSidebar_MenuItem_UnterUnterkategorie_BorderColorBottom={
                      DB_LeftSidebar_MenuItem_UnterUnterkategorie_BorderColorBottom
                    }
                    DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_BorderColorBottom={
                      DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_BorderColorBottom
                    }
                    DB_LeftSidebar_MenuItem_UnterUnterkategorie_BorderLeft={
                      DB_LeftSidebar_MenuItem_UnterUnterkategorie_BorderLeft
                    }
                    DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_BorderLeft={
                      DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_BorderLeft
                    }
                    DB_LeftSidebar_MenuItem_UnterUnterkategorie_BorderStyleLeft={
                      DB_LeftSidebar_MenuItem_UnterUnterkategorie_BorderStyleLeft
                    }
                    DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_BorderStyleLeft={
                      DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_BorderStyleLeft
                    }
                    DB_LeftSidebar_MenuItem_UnterUnterkategorie_BorderColorLeft={
                      DB_LeftSidebar_MenuItem_UnterUnterkategorie_BorderColorLeft
                    }
                    DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_BorderColorLeft={
                      DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_BorderColorLeft
                    }
                    DB_LeftSidebar_MenuItem_UnterUnterkategorie_BorderRight={
                      DB_LeftSidebar_MenuItem_UnterUnterkategorie_BorderRight
                    }
                    DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_BorderRight={
                      DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_BorderRight
                    }
                    DB_LeftSidebar_MenuItem_UnterUnterkategorie_BorderStyleRight={
                      DB_LeftSidebar_MenuItem_UnterUnterkategorie_BorderStyleRight
                    }
                    DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_BorderStyleRight={
                      DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_BorderStyleRight
                    }
                    DB_LeftSidebar_MenuItem_UnterUnterkategorie_BorderColorRight={
                      DB_LeftSidebar_MenuItem_UnterUnterkategorie_BorderColorRight
                    }
                    DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_BorderColorRight={
                      DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_BorderColorRight
                    }
                    DB_LeftSidebar_MenuItem_UnterUnterkategorie_BorderTopRightRadius={
                      DB_LeftSidebar_MenuItem_UnterUnterkategorie_BorderTopRightRadius
                    }
                    DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_BorderTopRightRadius={
                      DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_BorderTopRightRadius
                    }
                    DB_LeftSidebar_MenuItem_UnterUnterkategorie_BorderTopLeftRadius={
                      DB_LeftSidebar_MenuItem_UnterUnterkategorie_BorderTopLeftRadius
                    }
                    DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_BorderTopLeftRadius={
                      DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_BorderTopLeftRadius
                    }
                    DB_LeftSidebar_MenuItem_UnterUnterkategorie_BorderBottomRightRadius={
                      DB_LeftSidebar_MenuItem_UnterUnterkategorie_BorderBottomRightRadius
                    }
                    DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_BorderBottomRightRadius={
                      DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_BorderBottomRightRadius
                    }
                    DB_LeftSidebar_MenuItem_UnterUnterkategorie_BorderBottomLeftRadius={
                      DB_LeftSidebar_MenuItem_UnterUnterkategorie_BorderBottomLeftRadius
                    }
                    DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_BorderBottomLeftRadius={
                      DB_LeftSidebar_MenuItem_UnterUnterkategorie_Hover_BorderBottomLeftRadius
                    }
                    DB_LeftSidebar_MenuItem_UnterUnterkategorie_marginTopBottom={
                      DB_LeftSidebar_MenuItem_UnterUnterkategorie_marginTopBottom
                    }
                    DB_LeftSidebar_MenuItem_UnterUnterkategorie_marginLeftRight={
                      DB_LeftSidebar_MenuItem_UnterUnterkategorie_marginLeftRight
                    }
                    DB_LeftSidebar_MenuItem_UnterUnterkategorie_paddingLeftRight={
                      DB_LeftSidebar_MenuItem_UnterUnterkategorie_paddingLeftRight
                    }
                    DB_LeftSidebar_MenuItem_UnterUnterkategorie_paddingTopBottom={
                      DB_LeftSidebar_MenuItem_UnterUnterkategorie_paddingTopBottom
                    }
                    DB_LeftSidebar_MenuItem_UnterUnterkategorie_letterSpacing={
                      DB_LeftSidebar_MenuItem_UnterUnterkategorie_letterSpacing
                    }
                    DB_LeftSidebar_MenuItem_UnterUnterkategorie_ShadowHorizontal={
                      DB_LeftSidebar_MenuItem_UnterUnterkategorie_ShadowHorizontal
                    }
                    DB_LeftSidebar_MenuItem_UnterUnterkategorie_ShadowVertical={
                      DB_LeftSidebar_MenuItem_UnterUnterkategorie_ShadowVertical
                    }
                    DB_LeftSidebar_MenuItem_UnterUnterkategorie_ShadowBlur={
                      DB_LeftSidebar_MenuItem_UnterUnterkategorie_ShadowBlur
                    }
                    DB_LeftSidebar_MenuItem_UnterUnterkategorie_ShadowColor={
                      DB_LeftSidebar_MenuItem_UnterUnterkategorie_ShadowColor
                    }
                    DB_LeftSidebar_MenuItem_UnterUnterkategorie_TextDecoration={
                      DB_LeftSidebar_MenuItem_UnterUnterkategorie_TextDecoration
                    }
                    DB_LeftSidebar_MenuItem_UnterUnterkategorie_TextDecorationStyle={
                      DB_LeftSidebar_MenuItem_UnterUnterkategorie_TextDecorationStyle
                    }
                    DB_LeftSidebar_MenuItem_UnterUnterkategorie_marginTopBottom_Hover={
                      DB_LeftSidebar_MenuItem_UnterUnterkategorie_marginTopBottom_Hover
                    }
                    DB_LeftSidebar_MenuItem_UnterUnterkategorie_marginLeftRight_Hover={
                      DB_LeftSidebar_MenuItem_UnterUnterkategorie_marginLeftRight_Hover
                    }
                    DB_LeftSidebar_MenuItem_UnterUnterkategorie_paddingLeftRight_Hover={
                      DB_LeftSidebar_MenuItem_UnterUnterkategorie_paddingLeftRight_Hover
                    }
                    DB_LeftSidebar_MenuItem_UnterUnterkategorie_paddingTopBottom_Hover={
                      DB_LeftSidebar_MenuItem_UnterUnterkategorie_paddingTopBottom_Hover
                    }
                    DB_LeftSidebar_MenuItem_UnterUnterkategorie_letterSpacing_Hover={
                      DB_LeftSidebar_MenuItem_UnterUnterkategorie_letterSpacing_Hover
                    }
                    DB_LeftSidebar_MenuItem_UnterUnterkategorie_ShadowHorizontal_Hover={
                      DB_LeftSidebar_MenuItem_UnterUnterkategorie_ShadowHorizontal_Hover
                    }
                    DB_LeftSidebar_MenuItem_UnterUnterkategorie_ShadowVertical_Hover={
                      DB_LeftSidebar_MenuItem_UnterUnterkategorie_ShadowVertical_Hover
                    }
                    DB_LeftSidebar_MenuItem_UnterUnterkategorie_ShadowBlur_Hover={
                      DB_LeftSidebar_MenuItem_UnterUnterkategorie_ShadowBlur_Hover
                    }
                    DB_LeftSidebar_MenuItem_UnterUnterkategorie_ShadowColor_Hover={
                      DB_LeftSidebar_MenuItem_UnterUnterkategorie_ShadowColor_Hover
                    }
                    DB_LeftSidebar_MenuItem_UnterUnterkategorie_TextDecoration_Hover={
                      DB_LeftSidebar_MenuItem_UnterUnterkategorie_TextDecoration_Hover
                    }
                    DB_LeftSidebar_MenuItem_UnterUnterkategorie_TextDecorationStyle_Hover={
                      DB_LeftSidebar_MenuItem_UnterUnterkategorie_TextDecorationStyle_Hover
                    }
                    DB_LeftSidebar_MenuContainer_CC__BackgroundColor={
                      DB_LeftSidebar_MenuContainer_CC__BackgroundColor
                    }
                    DB_LeftSidebar_MenuContainer_CC__PaddingTopBottom={
                      DB_LeftSidebar_MenuContainer_CC__PaddingTopBottom
                    }
                    DB_LeftSidebar_MenuContainer_CC__PaddingLeftRight={
                      DB_LeftSidebar_MenuContainer_CC__PaddingLeftRight
                    }
                    DB_LeftSidebar_MenuContainer_CC__BorderTop={
                      DB_LeftSidebar_MenuContainer_CC__BorderTop
                    }
                    DB_LeftSidebar_MenuContainer_CC__BorderStyleTop={
                      DB_LeftSidebar_MenuContainer_CC__BorderStyleTop
                    }
                    DB_LeftSidebar_MenuContainer_CC__BorderColorTop={
                      DB_LeftSidebar_MenuContainer_CC__BorderColorTop
                    }
                    DB_LeftSidebar_MenuContainer_CC__BorderBottom={
                      DB_LeftSidebar_MenuContainer_CC__BorderBottom
                    }
                    DB_LeftSidebar_MenuContainer_CC__BorderStyleBottom={
                      DB_LeftSidebar_MenuContainer_CC__BorderStyleBottom
                    }
                    DB_LeftSidebar_MenuContainer_CC__BorderColorBottom={
                      DB_LeftSidebar_MenuContainer_CC__BorderColorBottom
                    }
                    DB_LeftSidebar_MenuContainer_CC__BorderLeft={
                      DB_LeftSidebar_MenuContainer_CC__BorderLeft
                    }
                    DB_LeftSidebar_MenuContainer_CC__BorderStyleLeft={
                      DB_LeftSidebar_MenuContainer_CC__BorderStyleLeft
                    }
                    DB_LeftSidebar_MenuContainer_CC__BorderColorLeft={
                      DB_LeftSidebar_MenuContainer_CC__BorderColorLeft
                    }
                    DB_LeftSidebar_MenuContainer_CC__BorderRight={
                      DB_LeftSidebar_MenuContainer_CC__BorderRight
                    }
                    DB_LeftSidebar_MenuContainer_CC__BorderStyleRight={
                      DB_LeftSidebar_MenuContainer_CC__BorderStyleRight
                    }
                    DB_LeftSidebar_MenuContainer_CC__BorderColorRight={
                      DB_LeftSidebar_MenuContainer_CC__BorderColorRight
                    }
                    DB_LeftSidebar_MenuItem_MenuContainer_CC__BorderTopRightRadius={
                      DB_LeftSidebar_MenuItem_MenuContainer_CC__BorderTopRightRadius
                    }
                    DB_LeftSidebar_MenuItem_MenuContainer_CC__BorderTopLeftRadius={
                      DB_LeftSidebar_MenuItem_MenuContainer_CC__BorderTopLeftRadius
                    }
                    DB_LeftSidebar_MenuItem_MenuContainer_CC__BorderBottomRightRadius={
                      DB_LeftSidebar_MenuItem_MenuContainer_CC__BorderBottomRightRadius
                    }
                    DB_LeftSidebar_MenuItem_MenuContainer_CC__BorderBottomLeftRadius={
                      DB_LeftSidebar_MenuItem_MenuContainer_CC__BorderBottomLeftRadius
                    }
                    DB_LeftSidebar_MenuItem_MenuContainer_CC__paddingLeft={
                      DB_LeftSidebar_MenuItem_MenuContainer_CC__paddingLeft
                    }
                    DB_LeftSidebar_MenuItem_MenuContainer_CC__paddingRight={
                      DB_LeftSidebar_MenuItem_MenuContainer_CC__paddingRight
                    }
                    DB_LeftSidebar_MenuItem_MenuContainer_CC__paddingTop={
                      DB_LeftSidebar_MenuItem_MenuContainer_CC__paddingTop
                    }
                    DB_LeftSidebar_MenuItem_MenuContainer_CC__paddingBottom={
                      DB_LeftSidebar_MenuItem_MenuContainer_CC__paddingBottom
                    }
                    DB_LeftSidebar_MenuItem_MenuContainer_CC__ShadowHorizontal={
                      DB_LeftSidebar_MenuItem_MenuContainer_CC__ShadowHorizontal
                    }
                    DB_LeftSidebar_MenuItem_MenuContainer_CC__ShadowVertical={
                      DB_LeftSidebar_MenuItem_MenuContainer_CC__ShadowVertical
                    }
                    DB_LeftSidebar_MenuItem_MenuContainer_CC__ShadowBlur={
                      DB_LeftSidebar_MenuItem_MenuContainer_CC__ShadowBlur
                    }
                    DB_LeftSidebar_MenuItem_MenuContainer_CC__ShadowColor={
                      DB_LeftSidebar_MenuItem_MenuContainer_CC__ShadowColor
                    }
                    DB_LeftSidebar_MenuContainer_CC__Hover_BackgroundColor={
                      DB_LeftSidebar_MenuContainer_CC__Hover_BackgroundColor
                    }
                    DB_LeftSidebar_MenuContainer_CC__Hover_PaddingTopBottom={
                      DB_LeftSidebar_MenuContainer_CC__Hover_PaddingTopBottom
                    }
                    DB_LeftSidebar_MenuContainer_CC__Hover_PaddingLeftRight={
                      DB_LeftSidebar_MenuContainer_CC__Hover_PaddingLeftRight
                    }
                    DB_LeftSidebar_MenuContainer_CC__Hover_BorderTop={
                      DB_LeftSidebar_MenuContainer_CC__Hover_BorderTop
                    }
                    DB_LeftSidebar_MenuContainer_CC__Hover_BorderStyleTop={
                      DB_LeftSidebar_MenuContainer_CC__Hover_BorderStyleTop
                    }
                    DB_LeftSidebar_MenuContainer_CC__Hover_BorderColorTop={
                      DB_LeftSidebar_MenuContainer_CC__Hover_BorderColorTop
                    }
                    DB_LeftSidebar_MenuContainer_CC__Hover_BorderBottom={
                      DB_LeftSidebar_MenuContainer_CC__Hover_BorderBottom
                    }
                    DB_LeftSidebar_MenuContainer_CC__Hover_BorderStyleBottom={
                      DB_LeftSidebar_MenuContainer_CC__Hover_BorderStyleBottom
                    }
                    DB_LeftSidebar_MenuContainer_CC__Hover_BorderColorBottom={
                      DB_LeftSidebar_MenuContainer_CC__Hover_BorderColorBottom
                    }
                    DB_LeftSidebar_MenuContainer_CC__Hover_BorderLeft={
                      DB_LeftSidebar_MenuContainer_CC__Hover_BorderLeft
                    }
                    DB_LeftSidebar_MenuContainer_CC__Hover_BorderStyleLeft={
                      DB_LeftSidebar_MenuContainer_CC__Hover_BorderStyleLeft
                    }
                    DB_LeftSidebar_MenuContainer_CC__Hover_BorderColorLeft={
                      DB_LeftSidebar_MenuContainer_CC__Hover_BorderColorLeft
                    }
                    DB_LeftSidebar_MenuContainer_CC__Hover_BorderRight={
                      DB_LeftSidebar_MenuContainer_CC__Hover_BorderRight
                    }
                    DB_LeftSidebar_MenuContainer_CC__Hover_BorderStyleRight={
                      DB_LeftSidebar_MenuContainer_CC__Hover_BorderStyleRight
                    }
                    DB_LeftSidebar_MenuContainer_CC__Hover_BorderColorRight={
                      DB_LeftSidebar_MenuContainer_CC__Hover_BorderColorRight
                    }
                    DB_LeftSidebar_MenuItem_MenuContainer_CC__Hover_BorderTopRightRadius={
                      DB_LeftSidebar_MenuItem_MenuContainer_CC__Hover_BorderTopRightRadius
                    }
                    DB_LeftSidebar_MenuItem_MenuContainer_CC__Hover_BorderTopLeftRadius={
                      DB_LeftSidebar_MenuItem_MenuContainer_CC__Hover_BorderTopLeftRadius
                    }
                    DB_LeftSidebar_MenuItem_MenuContainer_CC__Hover_BorderBottomRightRadius={
                      DB_LeftSidebar_MenuItem_MenuContainer_CC__Hover_BorderBottomRightRadius
                    }
                    DB_LeftSidebar_MenuItem_MenuContainer_CC__Hover_BorderBottomLeftRadius={
                      DB_LeftSidebar_MenuItem_MenuContainer_CC__Hover_BorderBottomLeftRadius
                    }
                    DB_LeftSidebar_MenuItem_MenuContainer_CC__Hover_paddingLeft={
                      DB_LeftSidebar_MenuItem_MenuContainer_CC__Hover_paddingLeft
                    }
                    DB_LeftSidebar_MenuItem_MenuContainer_CC__Hover_paddingRight={
                      DB_LeftSidebar_MenuItem_MenuContainer_CC__Hover_paddingRight
                    }
                    DB_LeftSidebar_MenuItem_MenuContainer_CC__Hover_paddingTop={
                      DB_LeftSidebar_MenuItem_MenuContainer_CC__Hover_paddingTop
                    }
                    DB_LeftSidebar_MenuItem_MenuContainer_CC__Hover_paddingBottom={
                      DB_LeftSidebar_MenuItem_MenuContainer_CC__Hover_paddingBottom
                    }
                    DB_LeftSidebar_MenuItem_MenuContainer_CC__Hover_ShadowHorizontal={
                      DB_LeftSidebar_MenuItem_MenuContainer_CC__Hover_ShadowHorizontal
                    }
                    DB_LeftSidebar_MenuItem_MenuContainer_CC__Hover_ShadowVertical={
                      DB_LeftSidebar_MenuItem_MenuContainer_CC__Hover_ShadowVertical
                    }
                    DB_LeftSidebar_MenuItem_MenuContainer_CC__Hover_ShadowBlur={
                      DB_LeftSidebar_MenuItem_MenuContainer_CC__Hover_ShadowBlur
                    }
                    DB_LeftSidebar_MenuItem_MenuContainer_CC__Hover_ShadowColor={
                      DB_LeftSidebar_MenuItem_MenuContainer_CC__Hover_ShadowColor
                    }
                  />
                )}

                {rightFiltersVisible && (
                  <SideBarRightFiltersMain
                    resetFilterHandler={resetFilterHandler}
                    ultraFiltersState={ultraFiltersState}
                    filterProduktsHandler={filterProduktsHandler}
                    isCheck={isCheck}
                    DB_fontFace_FontFamilyName={DB_fontFace_FontFamilyName}
                    SideBarLeft_ContainerFilter_Farbe_Groesse_Stil_OnMouseOverAndOut={
                      SideBarLeft_ContainerFilter_Farbe_Groesse_Stil_OnMouseOverAndOut
                    }
                    SideBarLeft_ContainerFilter_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut={
                      SideBarLeft_ContainerFilter_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
                    }
                    SideBarLeft_ContainerFilter_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut={
                      SideBarLeft_ContainerFilter_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut
                    }
                    SideBarLeft_ContainerFilter_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut={
                      SideBarLeft_ContainerFilter_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut
                    }
                    SideBarLeft_AlleFilter_Farbe_Groesse_Stil_OnMouseOverAndOut={
                      SideBarLeft_AlleFilter_Farbe_Groesse_Stil_OnMouseOverAndOut
                    }
                    SideBarLeft_AlleFilter_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut={
                      SideBarLeft_AlleFilter_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
                    }
                    SideBarLeft_AlleFilter_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut={
                      SideBarLeft_AlleFilter_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut
                    }
                    SideBarLeft_AlleFilter_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut={
                      SideBarLeft_AlleFilter_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut
                    }
                    SideBarLeft_HauptFiltertitel_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut={
                      SideBarLeft_HauptFiltertitel_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
                    }
                    SideBarLeft_HauptFiltertitel_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut={
                      SideBarLeft_HauptFiltertitel_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut
                    }
                    SideBarLeft_HauptFiltertitel_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut={
                      SideBarLeft_HauptFiltertitel_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut
                    }
                    SideBarLeft_FilterItem_Farbe_Groesse_Stil_OnMouseOverAndOut={
                      SideBarLeft_FilterItem_Farbe_Groesse_Stil_OnMouseOverAndOut
                    }
                    SideBarLeft_FilterItem_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut={
                      SideBarLeft_FilterItem_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
                    }
                    SideBarLeft_FilterItem_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut={
                      SideBarLeft_FilterItem_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut
                    }
                    SideBarLeft_FilterItem_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut={
                      SideBarLeft_FilterItem_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut
                    }
                    DB_fontFace_AlleFilterButtonFontFamily_FontFamilyName={
                      DB_fontFace_AlleFilterButtonFontFamily_FontFamilyName
                    }
                    DB_fontFace_AlleFilterButtonFontFamily_FontFamilySrc={
                      DB_fontFace_AlleFilterButtonFontFamily_FontFamilySrc
                    }
                    DB_LeftSidebar_MenuItem_AlleFilterButtonFF_FontWeight={
                      DB_LeftSidebar_MenuItem_AlleFilterButtonFF_FontWeight
                    }
                    DB_LeftSidebar_MenuItem_AlleFilterButtonFF_FontColor={
                      DB_LeftSidebar_MenuItem_AlleFilterButtonFF_FontColor
                    }
                    DB_LeftSidebar_MenuItem_AlleFilterButtonFF_FontSize={
                      DB_LeftSidebar_MenuItem_AlleFilterButtonFF_FontSize
                    }
                    DB_LeftSidebar_MenuItem_AlleFilterButtonFF_BackgroundColor={
                      DB_LeftSidebar_MenuItem_AlleFilterButtonFF_BackgroundColor
                    }
                    DB_LeftSidebar_MenuItem_AlleFilterButtonFF_TextAlign={
                      DB_LeftSidebar_MenuItem_AlleFilterButtonFF_TextAlign
                    }
                    DB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderTop={
                      DB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderTop
                    }
                    DB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderStyleTop={
                      DB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderStyleTop
                    }
                    DB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderColorTop={
                      DB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderColorTop
                    }
                    DB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderBottom={
                      DB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderBottom
                    }
                    DB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderStyleBottom={
                      DB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderStyleBottom
                    }
                    DB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderColorBottom={
                      DB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderColorBottom
                    }
                    DB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderLeft={
                      DB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderLeft
                    }
                    DB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderStyleLeft={
                      DB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderStyleLeft
                    }
                    DB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderColorLeft={
                      DB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderColorLeft
                    }
                    DB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderRight={
                      DB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderRight
                    }
                    DB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderStyleRight={
                      DB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderStyleRight
                    }
                    DB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderColorRight={
                      DB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderColorRight
                    }
                    DB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderTopRightRadius={
                      DB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderTopRightRadius
                    }
                    DB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderTopLeftRadius={
                      DB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderTopLeftRadius
                    }
                    DB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderBottomRightRadius={
                      DB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderBottomRightRadius
                    }
                    DB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderBottomLeftRadius={
                      DB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderBottomLeftRadius
                    }
                    DB_LeftSidebar_MenuItem_AlleFilterButtonFF_marginLeftRight={
                      DB_LeftSidebar_MenuItem_AlleFilterButtonFF_marginLeftRight
                    }
                    DB_LeftSidebar_MenuItem_AlleFilterButtonFF_marginTopBottom={
                      DB_LeftSidebar_MenuItem_AlleFilterButtonFF_marginTopBottom
                    }
                    DB_LeftSidebar_MenuItem_AlleFilterButtonFF_paddingLeftRight={
                      DB_LeftSidebar_MenuItem_AlleFilterButtonFF_paddingLeftRight
                    }
                    DB_LeftSidebar_MenuItem_AlleFilterButtonFF_paddingTopBottom={
                      DB_LeftSidebar_MenuItem_AlleFilterButtonFF_paddingTopBottom
                    }
                    DB_LeftSidebar_MenuItem_AlleFilterButtonFF_letterSpacing={
                      DB_LeftSidebar_MenuItem_AlleFilterButtonFF_letterSpacing
                    }
                    DB_LeftSidebar_MenuItem_AlleFilterButtonFF_ShadowHorizontal={
                      DB_LeftSidebar_MenuItem_AlleFilterButtonFF_ShadowHorizontal
                    }
                    DB_LeftSidebar_MenuItem_AlleFilterButtonFF_ShadowVertical={
                      DB_LeftSidebar_MenuItem_AlleFilterButtonFF_ShadowVertical
                    }
                    DB_LeftSidebar_MenuItem_AlleFilterButtonFF_ShadowBlur={
                      DB_LeftSidebar_MenuItem_AlleFilterButtonFF_ShadowBlur
                    }
                    DB_LeftSidebar_MenuItem_AlleFilterButtonFF_ShadowColor={
                      DB_LeftSidebar_MenuItem_AlleFilterButtonFF_ShadowColor
                    }
                    DB_LeftSidebar_MenuItem_AlleFilterButtonFF_TextDecoration={
                      DB_LeftSidebar_MenuItem_AlleFilterButtonFF_TextDecoration
                    }
                    DB_LeftSidebar_MenuItem_AlleFilterButtonFF_TextDecorationStyle={
                      DB_LeftSidebar_MenuItem_AlleFilterButtonFF_TextDecorationStyle
                    }
                    DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_FontWeight={
                      DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_FontWeight
                    }
                    DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_FontColor={
                      DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_FontColor
                    }
                    DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_FontSize={
                      DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_FontSize
                    }
                    DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BackgroundColor={
                      DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BackgroundColor
                    }
                    DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_TextAlign={
                      DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_TextAlign
                    }
                    DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderTop={
                      DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderTop
                    }
                    DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderStyleTop={
                      DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderStyleTop
                    }
                    DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderColorTop={
                      DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderColorTop
                    }
                    DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderBottom={
                      DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderBottom
                    }
                    DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderStyleBottom={
                      DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderStyleBottom
                    }
                    DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderColorBottom={
                      DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderColorBottom
                    }
                    DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderLeft={
                      DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderLeft
                    }
                    DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderStyleLeft={
                      DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderStyleLeft
                    }
                    DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderColorLeft={
                      DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderColorLeft
                    }
                    DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderRight={
                      DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderRight
                    }
                    DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderStyleRight={
                      DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderStyleRight
                    }
                    DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderColorRight={
                      DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderColorRight
                    }
                    DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderTopRightRadius={
                      DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderTopRightRadius
                    }
                    DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderTopLeftRadius={
                      DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderTopLeftRadius
                    }
                    DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderBottomRightRadius={
                      DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderBottomRightRadius
                    }
                    DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderBottomLeftRadius={
                      DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderBottomLeftRadius
                    }
                    DB_LeftSidebar_MenuItem_AlleFilterButtonFF_marginLeftRight_Hover={
                      DB_LeftSidebar_MenuItem_AlleFilterButtonFF_marginLeftRight_Hover
                    }
                    DB_LeftSidebar_MenuItem_AlleFilterButtonFF_marginTopBottom_Hover={
                      DB_LeftSidebar_MenuItem_AlleFilterButtonFF_marginTopBottom_Hover
                    }
                    DB_LeftSidebar_MenuItem_AlleFilterButtonFF_paddingLeftRight_Hover={
                      DB_LeftSidebar_MenuItem_AlleFilterButtonFF_paddingLeftRight_Hover
                    }
                    DB_LeftSidebar_MenuItem_AlleFilterButtonFF_paddingTopBottom_Hover={
                      DB_LeftSidebar_MenuItem_AlleFilterButtonFF_paddingTopBottom_Hover
                    }
                    DB_LeftSidebar_MenuItem_AlleFilterButtonFF_letterSpacing_Hover={
                      DB_LeftSidebar_MenuItem_AlleFilterButtonFF_letterSpacing_Hover
                    }
                    DB_LeftSidebar_MenuItem_AlleFilterButtonFF_ShadowHorizontal_Hover={
                      DB_LeftSidebar_MenuItem_AlleFilterButtonFF_ShadowHorizontal_Hover
                    }
                    DB_LeftSidebar_MenuItem_AlleFilterButtonFF_ShadowVertical_Hover={
                      DB_LeftSidebar_MenuItem_AlleFilterButtonFF_ShadowVertical_Hover
                    }
                    DB_LeftSidebar_MenuItem_AlleFilterButtonFF_ShadowBlur_Hover={
                      DB_LeftSidebar_MenuItem_AlleFilterButtonFF_ShadowBlur_Hover
                    }
                    DB_LeftSidebar_MenuItem_AlleFilterButtonFF_ShadowColor_Hover={
                      DB_LeftSidebar_MenuItem_AlleFilterButtonFF_ShadowColor_Hover
                    }
                    DB_LeftSidebar_MenuItem_AlleFilterButtonFF_TextDecoration_Hover={
                      DB_LeftSidebar_MenuItem_AlleFilterButtonFF_TextDecoration_Hover
                    }
                    DB_LeftSidebar_MenuItem_AlleFilterButtonFF_TextDecorationStyle_Hover={
                      DB_LeftSidebar_MenuItem_AlleFilterButtonFF_TextDecorationStyle_Hover
                    }
                    DB_fontFace_FilterHauptTitel_FontFamilyName={
                      DB_fontFace_FilterHauptTitel_FontFamilyName
                    }
                    DB_fontFace_FilterHauptTitel_FontFamilySrc={
                      DB_fontFace_FilterHauptTitel_FontFamilySrc
                    }
                    DB_LeftSidebar_MenuItem_FilterHauptTitel_FontFace={
                      DB_LeftSidebar_MenuItem_FilterHauptTitel_FontFace
                    }
                    DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_FontFace={
                      DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_FontFace
                    }
                    DB_LeftSidebar_MenuItem_FilterHauptTitel_FontWeight={
                      DB_LeftSidebar_MenuItem_FilterHauptTitel_FontWeight
                    }
                    DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_FontWeight={
                      DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_FontWeight
                    }
                    DB_LeftSidebar_MenuItem_FilterHauptTitel_FontColor={
                      DB_LeftSidebar_MenuItem_FilterHauptTitel_FontColor
                    }
                    DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_FontColor={
                      DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_FontColor
                    }
                    DB_LeftSidebar_MenuItem_FilterHauptTitel_FontSize={
                      DB_LeftSidebar_MenuItem_FilterHauptTitel_FontSize
                    }
                    DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_FontSize={
                      DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_FontSize
                    }
                    SideBarLeft_HauptFiltertitel_Farbe_Groesse_Stil_OnMouseOverAndOut={
                      SideBarLeft_HauptFiltertitel_Farbe_Groesse_Stil_OnMouseOverAndOut
                    }
                    DB_LeftSidebar_MenuItem_FilterHauptTitel_BackgroundColor={
                      DB_LeftSidebar_MenuItem_FilterHauptTitel_BackgroundColor
                    }
                    DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BackgroundColor={
                      DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BackgroundColor
                    }
                    DB_LeftSidebar_MenuItem_FilterHauptTitel_TextAlign={
                      DB_LeftSidebar_MenuItem_FilterHauptTitel_TextAlign
                    }
                    DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_TextAlign={
                      DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_TextAlign
                    }
                    DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderTop={
                      DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderTop
                    }
                    DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderTop={
                      DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderTop
                    }
                    DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderStyleTop={
                      DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderStyleTop
                    }
                    DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderStyleTop={
                      DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderStyleTop
                    }
                    DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderColorTop={
                      DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderColorTop
                    }
                    DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderColorTop={
                      DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderColorTop
                    }
                    DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderBottom={
                      DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderBottom
                    }
                    DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderBottom={
                      DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderBottom
                    }
                    DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderStyleBottom={
                      DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderStyleBottom
                    }
                    DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderStyleBottom={
                      DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderStyleBottom
                    }
                    DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderColorBottom={
                      DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderColorBottom
                    }
                    DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderColorBottom={
                      DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderColorBottom
                    }
                    DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderLeft={
                      DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderLeft
                    }
                    DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderLeft={
                      DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderLeft
                    }
                    DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderStyleLeft={
                      DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderStyleLeft
                    }
                    DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderStyleLeft={
                      DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderStyleLeft
                    }
                    DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderColorLeft={
                      DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderColorLeft
                    }
                    DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderColorLeft={
                      DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderColorLeft
                    }
                    DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderRight={
                      DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderRight
                    }
                    DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderRight={
                      DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderRight
                    }
                    DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderStyleRight={
                      DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderStyleRight
                    }
                    DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderStyleRight={
                      DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderStyleRight
                    }
                    DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderColorRight={
                      DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderColorRight
                    }
                    DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderColorRight={
                      DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderColorRight
                    }
                    DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderTopRightRadius={
                      DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderTopRightRadius
                    }
                    DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderTopRightRadius={
                      DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderTopRightRadius
                    }
                    DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderTopLeftRadius={
                      DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderTopLeftRadius
                    }
                    DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderTopLeftRadius={
                      DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderTopLeftRadius
                    }
                    DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderBottomRightRadius={
                      DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderBottomRightRadius
                    }
                    DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderBottomRightRadius={
                      DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderBottomRightRadius
                    }
                    DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderBottomLeftRadius={
                      DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderBottomLeftRadius
                    }
                    DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderBottomLeftRadius={
                      DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderBottomLeftRadius
                    }
                    /* handleShow_showConfig_LeftSidebar_MenuMainUltraFilterHauptTitel={
                  handleShow_showConfig_LeftSidebar_MenuMainUltraFilterHauptTitel
                }
                handleShow_showConfig_LeftSidebar_MenuMainUltraFilterHauptTitel_BorderAndColor={
                  handleShow_showConfig_LeftSidebar_MenuMainUltraFilterHauptTitel_BorderAndColor
                }
                handleShow_showConfig_LeftSidebar_MenuMainUltraFilterHauptTitel_Hover={
                  handleShow_showConfig_LeftSidebar_MenuMainUltraFilterHauptTitel_Hover
                }
                handleShow_showConfig_LeftSidebar_MenuMainUltraFilterHauptTitel_Hover_BorderAndColor={
                  handleShow_showConfig_LeftSidebar_MenuMainUltraFilterHauptTitel_Hover_BorderAndColor
                } */
                    DB_LeftSidebar_MenuItem_FilterHauptTitel_marginTopBottom={
                      DB_LeftSidebar_MenuItem_FilterHauptTitel_marginTopBottom
                    }
                    DB_LeftSidebar_MenuItem_FilterHauptTitel_marginLeftRight={
                      DB_LeftSidebar_MenuItem_FilterHauptTitel_marginLeftRight
                    }
                    DB_LeftSidebar_MenuItem_FilterHauptTitel_paddingLeftRight={
                      DB_LeftSidebar_MenuItem_FilterHauptTitel_paddingLeftRight
                    }
                    DB_LeftSidebar_MenuItem_FilterHauptTitel_paddingTopBottom={
                      DB_LeftSidebar_MenuItem_FilterHauptTitel_paddingTopBottom
                    }
                    DB_LeftSidebar_MenuItem_FilterHauptTitel_letterSpacing={
                      DB_LeftSidebar_MenuItem_FilterHauptTitel_letterSpacing
                    }
                    DB_LeftSidebar_MenuItem_FilterHauptTitel_ShadowHorizontal={
                      DB_LeftSidebar_MenuItem_FilterHauptTitel_ShadowHorizontal
                    }
                    DB_LeftSidebar_MenuItem_FilterHauptTitel_ShadowVertical={
                      DB_LeftSidebar_MenuItem_FilterHauptTitel_ShadowVertical
                    }
                    DB_LeftSidebar_MenuItem_FilterHauptTitel_ShadowBlur={
                      DB_LeftSidebar_MenuItem_FilterHauptTitel_ShadowBlur
                    }
                    DB_LeftSidebar_MenuItem_FilterHauptTitel_ShadowColor={
                      DB_LeftSidebar_MenuItem_FilterHauptTitel_ShadowColor
                    }
                    DB_LeftSidebar_MenuItem_FilterHauptTitel_TextDecoration={
                      DB_LeftSidebar_MenuItem_FilterHauptTitel_TextDecoration
                    }
                    DB_LeftSidebar_MenuItem_FilterHauptTitel_TextDecorationStyle={
                      DB_LeftSidebar_MenuItem_FilterHauptTitel_TextDecorationStyle
                    }
                    DB_LeftSidebar_MenuItem_FilterHauptTitel_marginTopBottom_Hover={
                      DB_LeftSidebar_MenuItem_FilterHauptTitel_marginTopBottom_Hover
                    }
                    DB_LeftSidebar_MenuItem_FilterHauptTitel_marginLeftRight_Hover={
                      DB_LeftSidebar_MenuItem_FilterHauptTitel_marginLeftRight_Hover
                    }
                    DB_LeftSidebar_MenuItem_FilterHauptTitel_paddingLeftRight_Hover={
                      DB_LeftSidebar_MenuItem_FilterHauptTitel_paddingLeftRight_Hover
                    }
                    DB_LeftSidebar_MenuItem_FilterHauptTitel_paddingTopBottom_Hover={
                      DB_LeftSidebar_MenuItem_FilterHauptTitel_paddingTopBottom_Hover
                    }
                    DB_LeftSidebar_MenuItem_FilterHauptTitel_letterSpacing_Hover={
                      DB_LeftSidebar_MenuItem_FilterHauptTitel_letterSpacing_Hover
                    }
                    DB_LeftSidebar_MenuItem_FilterHauptTitel_ShadowHorizontal_Hover={
                      DB_LeftSidebar_MenuItem_FilterHauptTitel_ShadowHorizontal_Hover
                    }
                    DB_LeftSidebar_MenuItem_FilterHauptTitel_ShadowVertical_Hover={
                      DB_LeftSidebar_MenuItem_FilterHauptTitel_ShadowVertical_Hover
                    }
                    DB_LeftSidebar_MenuItem_FilterHauptTitel_ShadowBlur_Hover={
                      DB_LeftSidebar_MenuItem_FilterHauptTitel_ShadowBlur_Hover
                    }
                    DB_LeftSidebar_MenuItem_FilterHauptTitel_ShadowColor_Hover={
                      DB_LeftSidebar_MenuItem_FilterHauptTitel_ShadowColor_Hover
                    }
                    DB_LeftSidebar_MenuItem_FilterHauptTitel_TextDecoration_Hover={
                      DB_LeftSidebar_MenuItem_FilterHauptTitel_TextDecoration_Hover
                    }
                    DB_LeftSidebar_MenuItem_FilterHauptTitel_TextDecorationStyle_Hover={
                      DB_LeftSidebar_MenuItem_FilterHauptTitel_TextDecorationStyle_Hover
                    }
                    DB_LeftSidebar_MenuContainerFilterGG_BackgroundColor={
                      DB_LeftSidebar_MenuContainerFilterGG_BackgroundColor
                    }
                    DB_LeftSidebar_MenuContainerFilterGG_BorderTop={
                      DB_LeftSidebar_MenuContainerFilterGG_BorderTop
                    }
                    DB_LeftSidebar_MenuContainerFilterGG_BorderStyleTop={
                      DB_LeftSidebar_MenuContainerFilterGG_BorderStyleTop
                    }
                    DB_LeftSidebar_MenuContainerFilterGG_BorderColorTop={
                      DB_LeftSidebar_MenuContainerFilterGG_BorderColorTop
                    }
                    DB_LeftSidebar_MenuContainerFilterGG_BorderBottom={
                      DB_LeftSidebar_MenuContainerFilterGG_BorderBottom
                    }
                    DB_LeftSidebar_MenuContainerFilterGG_BorderStyleBottom={
                      DB_LeftSidebar_MenuContainerFilterGG_BorderStyleBottom
                    }
                    DB_LeftSidebar_MenuContainerFilterGG_BorderColorBottom={
                      DB_LeftSidebar_MenuContainerFilterGG_BorderColorBottom
                    }
                    DB_LeftSidebar_MenuContainerFilterGG_BorderLeft={
                      DB_LeftSidebar_MenuContainerFilterGG_BorderLeft
                    }
                    DB_LeftSidebar_MenuContainerFilterGG_BorderStyleLeft={
                      DB_LeftSidebar_MenuContainerFilterGG_BorderStyleLeft
                    }
                    DB_LeftSidebar_MenuContainerFilterGG_BorderColorLeft={
                      DB_LeftSidebar_MenuContainerFilterGG_BorderColorLeft
                    }
                    DB_LeftSidebar_MenuContainerFilterGG_BorderRight={
                      DB_LeftSidebar_MenuContainerFilterGG_BorderRight
                    }
                    DB_LeftSidebar_MenuContainerFilterGG_BorderStyleRight={
                      DB_LeftSidebar_MenuContainerFilterGG_BorderStyleRight
                    }
                    DB_LeftSidebar_MenuContainerFilterGG_BorderColorRight={
                      DB_LeftSidebar_MenuContainerFilterGG_BorderColorRight
                    }
                    DB_LeftSidebar_MenuItem_MenuContainerFilterGG_BorderTopRightRadius={
                      DB_LeftSidebar_MenuItem_MenuContainerFilterGG_BorderTopRightRadius
                    }
                    DB_LeftSidebar_MenuItem_MenuContainerFilterGG_BorderTopLeftRadius={
                      DB_LeftSidebar_MenuItem_MenuContainerFilterGG_BorderTopLeftRadius
                    }
                    DB_LeftSidebar_MenuItem_MenuContainerFilterGG_BorderBottomRightRadius={
                      DB_LeftSidebar_MenuItem_MenuContainerFilterGG_BorderBottomRightRadius
                    }
                    DB_LeftSidebar_MenuItem_MenuContainerFilterGG_BorderBottomLeftRadius={
                      DB_LeftSidebar_MenuItem_MenuContainerFilterGG_BorderBottomLeftRadius
                    }
                    DB_LeftSidebar_MenuItem_MenuContainerFilterGG_paddingLeft={
                      DB_LeftSidebar_MenuItem_MenuContainerFilterGG_paddingLeft
                    }
                    DB_LeftSidebar_MenuItem_MenuContainerFilterGG_paddingRight={
                      DB_LeftSidebar_MenuItem_MenuContainerFilterGG_paddingRight
                    }
                    DB_LeftSidebar_MenuItem_MenuContainerFilterGG_paddingTop={
                      DB_LeftSidebar_MenuItem_MenuContainerFilterGG_paddingTop
                    }
                    DB_LeftSidebar_MenuItem_MenuContainerFilterGG_paddingBottom={
                      DB_LeftSidebar_MenuItem_MenuContainerFilterGG_paddingBottom
                    }
                    DB_LeftSidebar_MenuItem_MenuContainerFilterGG_ShadowHorizontal={
                      DB_LeftSidebar_MenuItem_MenuContainerFilterGG_ShadowHorizontal
                    }
                    DB_LeftSidebar_MenuItem_MenuContainerFilterGG_ShadowVertical={
                      DB_LeftSidebar_MenuItem_MenuContainerFilterGG_ShadowVertical
                    }
                    DB_LeftSidebar_MenuItem_MenuContainerFilterGG_ShadowBlur={
                      DB_LeftSidebar_MenuItem_MenuContainerFilterGG_ShadowBlur
                    }
                    DB_LeftSidebar_MenuItem_MenuContainerFilterGG_ShadowColor={
                      DB_LeftSidebar_MenuItem_MenuContainerFilterGG_ShadowColor
                    }
                    DB_LeftSidebar_MenuContainerFilterGG_Hover_BackgroundColor={
                      DB_LeftSidebar_MenuContainerFilterGG_Hover_BackgroundColor
                    }
                    DB_LeftSidebar_MenuContainerFilterGG_Hover_BorderTop={
                      DB_LeftSidebar_MenuContainerFilterGG_Hover_BorderTop
                    }
                    DB_LeftSidebar_MenuContainerFilterGG_Hover_BorderStyleTop={
                      DB_LeftSidebar_MenuContainerFilterGG_Hover_BorderStyleTop
                    }
                    DB_LeftSidebar_MenuContainerFilterGG_Hover_BorderColorTop={
                      DB_LeftSidebar_MenuContainerFilterGG_Hover_BorderColorTop
                    }
                    DB_LeftSidebar_MenuContainerFilterGG_Hover_BorderBottom={
                      DB_LeftSidebar_MenuContainerFilterGG_Hover_BorderBottom
                    }
                    DB_LeftSidebar_MenuContainerFilterGG_Hover_BorderStyleBottom={
                      DB_LeftSidebar_MenuContainerFilterGG_Hover_BorderStyleBottom
                    }
                    DB_LeftSidebar_MenuContainerFilterGG_Hover_BorderColorBottom={
                      DB_LeftSidebar_MenuContainerFilterGG_Hover_BorderColorBottom
                    }
                    DB_LeftSidebar_MenuContainerFilterGG_Hover_BorderLeft={
                      DB_LeftSidebar_MenuContainerFilterGG_Hover_BorderLeft
                    }
                    DB_LeftSidebar_MenuContainerFilterGG_Hover_BorderStyleLeft={
                      DB_LeftSidebar_MenuContainerFilterGG_Hover_BorderStyleLeft
                    }
                    DB_LeftSidebar_MenuContainerFilterGG_Hover_BorderColorLeft={
                      DB_LeftSidebar_MenuContainerFilterGG_Hover_BorderColorLeft
                    }
                    DB_LeftSidebar_MenuContainerFilterGG_Hover_BorderRight={
                      DB_LeftSidebar_MenuContainerFilterGG_Hover_BorderRight
                    }
                    DB_LeftSidebar_MenuContainerFilterGG_Hover_BorderStyleRight={
                      DB_LeftSidebar_MenuContainerFilterGG_Hover_BorderStyleRight
                    }
                    DB_LeftSidebar_MenuContainerFilterGG_Hover_BorderColorRight={
                      DB_LeftSidebar_MenuContainerFilterGG_Hover_BorderColorRight
                    }
                    DB_LeftSidebar_MenuItem_MenuContainerFilterGG_Hover_BorderTopRightRadius={
                      DB_LeftSidebar_MenuItem_MenuContainerFilterGG_Hover_BorderTopRightRadius
                    }
                    DB_LeftSidebar_MenuItem_MenuContainerFilterGG_Hover_BorderTopLeftRadius={
                      DB_LeftSidebar_MenuItem_MenuContainerFilterGG_Hover_BorderTopLeftRadius
                    }
                    DB_LeftSidebar_MenuItem_MenuContainerFilterGG_Hover_BorderBottomRightRadius={
                      DB_LeftSidebar_MenuItem_MenuContainerFilterGG_Hover_BorderBottomRightRadius
                    }
                    DB_LeftSidebar_MenuItem_MenuContainerFilterGG_Hover_BorderBottomLeftRadius={
                      DB_LeftSidebar_MenuItem_MenuContainerFilterGG_Hover_BorderBottomLeftRadius
                    }
                    DB_LeftSidebar_MenuItem_MenuContainerFilterGG_Hover_paddingLeft={
                      DB_LeftSidebar_MenuItem_MenuContainerFilterGG_Hover_paddingLeft
                    }
                    DB_LeftSidebar_MenuItem_MenuContainerFilterGG_Hover_paddingRight={
                      DB_LeftSidebar_MenuItem_MenuContainerFilterGG_Hover_paddingRight
                    }
                    DB_LeftSidebar_MenuItem_MenuContainerFilterGG_Hover_paddingTop={
                      DB_LeftSidebar_MenuItem_MenuContainerFilterGG_Hover_paddingTop
                    }
                    DB_LeftSidebar_MenuItem_MenuContainerFilterGG_Hover_paddingBottom={
                      DB_LeftSidebar_MenuItem_MenuContainerFilterGG_Hover_paddingBottom
                    }
                    DB_LeftSidebar_MenuItem_MenuContainerFilterGG_Hover_ShadowHorizontal={
                      DB_LeftSidebar_MenuItem_MenuContainerFilterGG_Hover_ShadowHorizontal
                    }
                    DB_LeftSidebar_MenuItem_MenuContainerFilterGG_Hover_ShadowVertical={
                      DB_LeftSidebar_MenuItem_MenuContainerFilterGG_Hover_ShadowVertical
                    }
                    DB_LeftSidebar_MenuItem_MenuContainerFilterGG_Hover_ShadowBlur={
                      DB_LeftSidebar_MenuItem_MenuContainerFilterGG_Hover_ShadowBlur
                    }
                    DB_LeftSidebar_MenuItem_MenuContainerFilterGG_Hover_ShadowColor={
                      DB_LeftSidebar_MenuItem_MenuContainerFilterGG_Hover_ShadowColor
                    }
                    DB_fontFace_FilterItemFontFamily_FontFamilyName={
                      DB_fontFace_FilterItemFontFamily_FontFamilyName
                    }
                    DB_fontFace_FilterItemFontFamily_FontFamilySrc={
                      DB_fontFace_FilterItemFontFamily_FontFamilySrc
                    }
                    DB_LeftSidebar_MenuItem_FilterItem_FontWeight={
                      DB_LeftSidebar_MenuItem_FilterItem_FontWeight
                    }
                    DB_LeftSidebar_MenuItem_FilterItem_Hover_FontWeight={
                      DB_LeftSidebar_MenuItem_FilterItem_Hover_FontWeight
                    }
                    DB_LeftSidebar_MenuItem_FilterItem_FontColor={
                      DB_LeftSidebar_MenuItem_FilterItem_FontColor
                    }
                    DB_LeftSidebar_MenuItem_FilterItem_Hover_FontColor={
                      DB_LeftSidebar_MenuItem_FilterItem_Hover_FontColor
                    }
                    DB_LeftSidebar_MenuItem_FilterItem_FontSize={
                      DB_LeftSidebar_MenuItem_FilterItem_FontSize
                    }
                    DB_LeftSidebar_MenuItem_FilterItem_Hover_FontSize={
                      DB_LeftSidebar_MenuItem_FilterItem_Hover_FontSize
                    }
                    DB_LeftSidebar_MenuItem_FilterItem_BackgroundColor={
                      DB_LeftSidebar_MenuItem_FilterItem_BackgroundColor
                    }
                    DB_LeftSidebar_MenuItem_FilterItem_Hover_BackgroundColor={
                      DB_LeftSidebar_MenuItem_FilterItem_Hover_BackgroundColor
                    }
                    DB_LeftSidebar_MenuItem_FilterItem_TextAlign={
                      DB_LeftSidebar_MenuItem_FilterItem_TextAlign
                    }
                    DB_LeftSidebar_MenuItem_FilterItem_Hover_TextAlign={
                      DB_LeftSidebar_MenuItem_FilterItem_Hover_TextAlign
                    }
                    DB_LeftSidebar_MenuItem_FilterItem_BorderTop={
                      DB_LeftSidebar_MenuItem_FilterItem_BorderTop
                    }
                    DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderTop={
                      DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderTop
                    }
                    DB_LeftSidebar_MenuItem_FilterItem_BorderStyleTop={
                      DB_LeftSidebar_MenuItem_FilterItem_BorderStyleTop
                    }
                    DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderStyleTop={
                      DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderStyleTop
                    }
                    DB_LeftSidebar_MenuItem_FilterItem_BorderColorTop={
                      DB_LeftSidebar_MenuItem_FilterItem_BorderColorTop
                    }
                    DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderColorTop={
                      DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderColorTop
                    }
                    DB_LeftSidebar_MenuItem_FilterItem_BorderBottom={
                      DB_LeftSidebar_MenuItem_FilterItem_BorderBottom
                    }
                    DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderBottom={
                      DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderBottom
                    }
                    DB_LeftSidebar_MenuItem_FilterItem_BorderStyleBottom={
                      DB_LeftSidebar_MenuItem_FilterItem_BorderStyleBottom
                    }
                    DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderStyleBottom={
                      DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderStyleBottom
                    }
                    DB_LeftSidebar_MenuItem_FilterItem_BorderColorBottom={
                      DB_LeftSidebar_MenuItem_FilterItem_BorderColorBottom
                    }
                    DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderColorBottom={
                      DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderColorBottom
                    }
                    DB_LeftSidebar_MenuItem_FilterItem_BorderLeft={
                      DB_LeftSidebar_MenuItem_FilterItem_BorderLeft
                    }
                    DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderLeft={
                      DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderLeft
                    }
                    DB_LeftSidebar_MenuItem_FilterItem_BorderStyleLeft={
                      DB_LeftSidebar_MenuItem_FilterItem_BorderStyleLeft
                    }
                    DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderStyleLeft={
                      DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderStyleLeft
                    }
                    DB_LeftSidebar_MenuItem_FilterItem_BorderColorLeft={
                      DB_LeftSidebar_MenuItem_FilterItem_BorderColorLeft
                    }
                    DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderColorLeft={
                      DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderColorLeft
                    }
                    DB_LeftSidebar_MenuItem_FilterItem_BorderRight={
                      DB_LeftSidebar_MenuItem_FilterItem_BorderRight
                    }
                    DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderRight={
                      DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderRight
                    }
                    DB_LeftSidebar_MenuItem_FilterItem_BorderStyleRight={
                      DB_LeftSidebar_MenuItem_FilterItem_BorderStyleRight
                    }
                    DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderStyleRight={
                      DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderStyleRight
                    }
                    DB_LeftSidebar_MenuItem_FilterItem_BorderColorRight={
                      DB_LeftSidebar_MenuItem_FilterItem_BorderColorRight
                    }
                    DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderColorRight={
                      DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderColorRight
                    }
                    DB_LeftSidebar_MenuItem_FilterItem_BorderTopRightRadius={
                      DB_LeftSidebar_MenuItem_FilterItem_BorderTopRightRadius
                    }
                    DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderTopRightRadius={
                      DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderTopRightRadius
                    }
                    DB_LeftSidebar_MenuItem_FilterItem_BorderTopLeftRadius={
                      DB_LeftSidebar_MenuItem_FilterItem_BorderTopLeftRadius
                    }
                    DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderTopLeftRadius={
                      DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderTopLeftRadius
                    }
                    DB_LeftSidebar_MenuItem_FilterItem_BorderBottomRightRadius={
                      DB_LeftSidebar_MenuItem_FilterItem_BorderBottomRightRadius
                    }
                    DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderBottomRightRadius={
                      DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderBottomRightRadius
                    }
                    DB_LeftSidebar_MenuItem_FilterItem_BorderBottomLeftRadius={
                      DB_LeftSidebar_MenuItem_FilterItem_BorderBottomLeftRadius
                    }
                    DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderBottomLeftRadius={
                      DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderBottomLeftRadius
                    }
                    DB_LeftSidebar_MenuItem_FilterItem_marginTopBottom={
                      DB_LeftSidebar_MenuItem_FilterItem_marginTopBottom
                    }
                    DB_LeftSidebar_MenuItem_FilterItem_marginLeftRight={
                      DB_LeftSidebar_MenuItem_FilterItem_marginLeftRight
                    }
                    DB_LeftSidebar_MenuItem_FilterItem_paddingLeftRight={
                      DB_LeftSidebar_MenuItem_FilterItem_paddingLeftRight
                    }
                    DB_LeftSidebar_MenuItem_FilterItem_paddingTopBottom={
                      DB_LeftSidebar_MenuItem_FilterItem_paddingTopBottom
                    }
                    DB_LeftSidebar_MenuItem_FilterItem_letterSpacing={
                      DB_LeftSidebar_MenuItem_FilterItem_letterSpacing
                    }
                    DB_LeftSidebar_MenuItem_FilterItem_ShadowHorizontal={
                      DB_LeftSidebar_MenuItem_FilterItem_ShadowHorizontal
                    }
                    DB_LeftSidebar_MenuItem_FilterItem_ShadowVertical={
                      DB_LeftSidebar_MenuItem_FilterItem_ShadowVertical
                    }
                    DB_LeftSidebar_MenuItem_FilterItem_ShadowBlur={
                      DB_LeftSidebar_MenuItem_FilterItem_ShadowBlur
                    }
                    DB_LeftSidebar_MenuItem_FilterItem_ShadowColor={
                      DB_LeftSidebar_MenuItem_FilterItem_ShadowColor
                    }
                    DB_LeftSidebar_MenuItem_FilterItem_TextDecoration={
                      DB_LeftSidebar_MenuItem_FilterItem_TextDecoration
                    }
                    DB_LeftSidebar_MenuItem_FilterItem_TextDecorationStyle={
                      DB_LeftSidebar_MenuItem_FilterItem_TextDecorationStyle
                    }
                    DB_LeftSidebar_MenuItem_FilterItem_marginTopBottom_Hover={
                      DB_LeftSidebar_MenuItem_FilterItem_marginTopBottom_Hover
                    }
                    DB_LeftSidebar_MenuItem_FilterItem_marginLeftRight_Hover={
                      DB_LeftSidebar_MenuItem_FilterItem_marginLeftRight_Hover
                    }
                    DB_LeftSidebar_MenuItem_FilterItem_paddingLeftRight_Hover={
                      DB_LeftSidebar_MenuItem_FilterItem_paddingLeftRight_Hover
                    }
                    DB_LeftSidebar_MenuItem_FilterItem_paddingTopBottom_Hover={
                      DB_LeftSidebar_MenuItem_FilterItem_paddingTopBottom_Hover
                    }
                    DB_LeftSidebar_MenuItem_FilterItem_letterSpacing_Hover={
                      DB_LeftSidebar_MenuItem_FilterItem_letterSpacing_Hover
                    }
                    DB_LeftSidebar_MenuItem_FilterItem_ShadowHorizontal_Hover={
                      DB_LeftSidebar_MenuItem_FilterItem_ShadowHorizontal_Hover
                    }
                    DB_LeftSidebar_MenuItem_FilterItem_ShadowVertical_Hover={
                      DB_LeftSidebar_MenuItem_FilterItem_ShadowVertical_Hover
                    }
                    DB_LeftSidebar_MenuItem_FilterItem_ShadowBlur_Hover={
                      DB_LeftSidebar_MenuItem_FilterItem_ShadowBlur_Hover
                    }
                    DB_LeftSidebar_MenuItem_FilterItem_ShadowColor_Hover={
                      DB_LeftSidebar_MenuItem_FilterItem_ShadowColor_Hover
                    }
                    DB_LeftSidebar_MenuItem_FilterItem_TextDecoration_Hover={
                      DB_LeftSidebar_MenuItem_FilterItem_TextDecoration_Hover
                    }
                    DB_LeftSidebar_MenuItem_FilterItem_TextDecorationStyle_Hover={
                      DB_LeftSidebar_MenuItem_FilterItem_TextDecorationStyle_Hover
                    }
                  />
                )}
                {TopBarIsVisibleRight && !isMobile && (
                  <>
                    <Navbar
                      expand="lg"
                      className="p-0"
                      style={{
                        marginLeft: "auto",
                        marginRight: "auto",
                      }}
                    >
                      <TopBarStaticSpecialLeftRight
                        meldenModalHandler={meldenModalHandler}
                        faqDetailItem={faqDetailItem}
                        aboutUsShowHandler={aboutUsShowHandler}
                        faqShowHandler={faqShowHandler}
                        logoActive={logoActive}
                        countImagePlusTwo={countImagePlusTwo}
                        shopName={shopName}
                        resetFilterHandler={resetFilterHandler}
                        searchValue={searchValue}
                        searchHandler={searchHandler}
                        searchPressEnter={searchPressEnter}
                        isAuthState={isAuthState}
                        signOutMessage={signOutMessage}
                        productsUserId={productsUserId}
                        showTopProductsHandler={showTopProductsHandler}
                        toggleSignIn={toggleSignIn}
                        /* handleShow_showConfig_Main={handleShow_showConfig_Main} */
                        userOwnsThisSite={userOwnsThisSite}
                        DB_fontFace_FontFamilyName={DB_fontFace_FontFamilyName}
                        SideBarLeft_HauptmenuTopStatic_Farbe_Groesse_Stil_Trigger={
                          SideBarLeft_HauptmenuTopStatic_Farbe_Groesse_Stil_Trigger
                        }
                        SideBarLeft_HauptmenuTopStatic_Farbe_Groesse_Stil_Hover_Trigger={
                          SideBarLeft_HauptmenuTopStatic_Farbe_Groesse_Stil_Hover_Trigger
                        }
                        SideBarLeft_HauptmenuTopStatic_HintergrundFarbe_Radius_Stil_Trigger={
                          SideBarLeft_HauptmenuTopStatic_HintergrundFarbe_Radius_Stil_Trigger
                        }
                        SideBarLeft_HauptmenuTopStatic_HintergrundFarbe_Radius_Stil_Hover_Trigger={
                          SideBarLeft_HauptmenuTopStatic_HintergrundFarbe_Radius_Stil_Hover_Trigger
                        }
                        SideBarLeft_HauptmenuTopStatic_Farbe_Groesse_Stil_OnMouseOverAndOut={
                          SideBarLeft_HauptmenuTopStatic_Farbe_Groesse_Stil_OnMouseOverAndOut
                        }
                        SideBarLeft_HauptmenuTopStatic_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut={
                          SideBarLeft_HauptmenuTopStatic_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
                        }
                        SideBarLeft_HauptmenuTopStatic_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut={
                          SideBarLeft_HauptmenuTopStatic_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut
                        }
                        SideBarLeft_HauptmenuTopStatic_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut={
                          SideBarLeft_HauptmenuTopStatic_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut
                        }
                        DB_fontFace_hauptMenueStatic_FontFamilyName={
                          DB_fontFace_hauptMenueStatic_FontFamilyName
                        }
                        DB_fontFace_hauptMenueStatic_FontFamilySrc={
                          DB_fontFace_hauptMenueStatic_FontFamilySrc
                        }
                        DB_LeftSidebar_MenuItem_HauptmenuTop_FontWeight={
                          DB_LeftSidebar_MenuItem_HauptmenuTop_FontWeight
                        }
                        DB_LeftSidebar_MenuItem_HauptmenuTop_FontColor={
                          DB_LeftSidebar_MenuItem_HauptmenuTop_FontColor
                        }
                        DB_LeftSidebar_MenuItem_HauptmenuTop_FontSize={
                          DB_LeftSidebar_MenuItem_HauptmenuTop_FontSize
                        }
                        DB_LeftSidebar_MenuItem_HauptmenuTop_BackgroundColor={
                          DB_LeftSidebar_MenuItem_HauptmenuTop_BackgroundColor
                        }
                        DB_LeftSidebar_MenuItem_HauptmenuTop_TextAlign={
                          DB_LeftSidebar_MenuItem_HauptmenuTop_TextAlign
                        }
                        DB_LeftSidebar_MenuItem_HauptmenuTop_BorderTop={
                          DB_LeftSidebar_MenuItem_HauptmenuTop_BorderTop
                        }
                        DB_LeftSidebar_MenuItem_HauptmenuTop_BorderStyleTop={
                          DB_LeftSidebar_MenuItem_HauptmenuTop_BorderStyleTop
                        }
                        DB_LeftSidebar_MenuItem_HauptmenuTop_BorderColorTop={
                          DB_LeftSidebar_MenuItem_HauptmenuTop_BorderColorTop
                        }
                        DB_LeftSidebar_MenuItem_HauptmenuTop_BorderBottom={
                          DB_LeftSidebar_MenuItem_HauptmenuTop_BorderBottom
                        }
                        DB_LeftSidebar_MenuItem_HauptmenuTop_BorderStyleBottom={
                          DB_LeftSidebar_MenuItem_HauptmenuTop_BorderStyleBottom
                        }
                        DB_LeftSidebar_MenuItem_HauptmenuTop_BorderColorBottom={
                          DB_LeftSidebar_MenuItem_HauptmenuTop_BorderColorBottom
                        }
                        DB_LeftSidebar_MenuItem_HauptmenuTop_BorderLeft={
                          DB_LeftSidebar_MenuItem_HauptmenuTop_BorderLeft
                        }
                        DB_LeftSidebar_MenuItem_HauptmenuTop_BorderStyleLeft={
                          DB_LeftSidebar_MenuItem_HauptmenuTop_BorderStyleLeft
                        }
                        DB_LeftSidebar_MenuItem_HauptmenuTop_BorderColorLeft={
                          DB_LeftSidebar_MenuItem_HauptmenuTop_BorderColorLeft
                        }
                        DB_LeftSidebar_MenuItem_HauptmenuTop_BorderRight={
                          DB_LeftSidebar_MenuItem_HauptmenuTop_BorderRight
                        }
                        DB_LeftSidebar_MenuItem_HauptmenuTop_BorderStyleRight={
                          DB_LeftSidebar_MenuItem_HauptmenuTop_BorderStyleRight
                        }
                        DB_LeftSidebar_MenuItem_HauptmenuTop_BorderColorRight={
                          DB_LeftSidebar_MenuItem_HauptmenuTop_BorderColorRight
                        }
                        DB_LeftSidebar_MenuItem_HauptmenuTop_BorderTopRightRadius={
                          DB_LeftSidebar_MenuItem_HauptmenuTop_BorderTopRightRadius
                        }
                        DB_LeftSidebar_MenuItem_HauptmenuTop_BorderTopLeftRadius={
                          DB_LeftSidebar_MenuItem_HauptmenuTop_BorderTopLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_HauptmenuTop_BorderBottomRightRadius={
                          DB_LeftSidebar_MenuItem_HauptmenuTop_BorderBottomRightRadius
                        }
                        DB_LeftSidebar_MenuItem_HauptmenuTop_BorderBottomLeftRadius={
                          DB_LeftSidebar_MenuItem_HauptmenuTop_BorderBottomLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_HauptmenuTop_marginLeftRight={
                          DB_LeftSidebar_MenuItem_HauptmenuTop_marginLeftRight
                        }
                        DB_LeftSidebar_MenuItem_HauptmenuTop_marginTopBottom={
                          DB_LeftSidebar_MenuItem_HauptmenuTop_marginTopBottom
                        }
                        DB_LeftSidebar_MenuItem_HauptmenuTop_paddingLeftRight={
                          DB_LeftSidebar_MenuItem_HauptmenuTop_paddingLeftRight
                        }
                        DB_LeftSidebar_MenuItem_HauptmenuTop_paddingTopBottom={
                          DB_LeftSidebar_MenuItem_HauptmenuTop_paddingTopBottom
                        }
                        DB_LeftSidebar_MenuItem_HauptmenuTop_letterSpacing={
                          DB_LeftSidebar_MenuItem_HauptmenuTop_letterSpacing
                        }
                        DB_LeftSidebar_MenuItem_HauptmenuTop_ShadowHorizontal={
                          DB_LeftSidebar_MenuItem_HauptmenuTop_ShadowHorizontal
                        }
                        DB_LeftSidebar_MenuItem_HauptmenuTop_ShadowVertical={
                          DB_LeftSidebar_MenuItem_HauptmenuTop_ShadowVertical
                        }
                        DB_LeftSidebar_MenuItem_HauptmenuTop_ShadowBlur={
                          DB_LeftSidebar_MenuItem_HauptmenuTop_ShadowBlur
                        }
                        DB_LeftSidebar_MenuItem_HauptmenuTop_ShadowColor={
                          DB_LeftSidebar_MenuItem_HauptmenuTop_ShadowColor
                        }
                        DB_LeftSidebar_MenuItem_HauptmenuTop_TextDecoration={
                          DB_LeftSidebar_MenuItem_HauptmenuTop_TextDecoration
                        }
                        DB_LeftSidebar_MenuItem_HauptmenuTop_TextDecorationStyle={
                          DB_LeftSidebar_MenuItem_HauptmenuTop_TextDecorationStyle
                        }
                        DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_FontWeight={
                          DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_FontWeight
                        }
                        DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_FontColor={
                          DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_FontColor
                        }
                        DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_FontSize={
                          DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_FontSize
                        }
                        DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BackgroundColor={
                          DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BackgroundColor
                        }
                        DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_TextAlign={
                          DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_TextAlign
                        }
                        DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderTop={
                          DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderTop
                        }
                        DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderStyleTop={
                          DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderStyleTop
                        }
                        DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderColorTop={
                          DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderColorTop
                        }
                        DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderBottom={
                          DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderBottom
                        }
                        DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderStyleBottom={
                          DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderStyleBottom
                        }
                        DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderColorBottom={
                          DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderColorBottom
                        }
                        DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderLeft={
                          DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderLeft
                        }
                        DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderStyleLeft={
                          DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderStyleLeft
                        }
                        DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderColorLeft={
                          DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderColorLeft
                        }
                        DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderRight={
                          DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderRight
                        }
                        DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderStyleRight={
                          DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderStyleRight
                        }
                        DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderColorRight={
                          DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderColorRight
                        }
                        DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderTopRightRadius={
                          DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderTopRightRadius
                        }
                        DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderTopLeftRadius={
                          DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderTopLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderBottomRightRadius={
                          DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderBottomRightRadius
                        }
                        DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderBottomLeftRadius={
                          DB_LeftSidebar_MenuItem_HauptmenuTop_Hover_BorderBottomLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_HauptmenuTop_marginLeftRight_Hover={
                          DB_LeftSidebar_MenuItem_HauptmenuTop_marginLeftRight_Hover
                        }
                        DB_LeftSidebar_MenuItem_HauptmenuTop_marginTopBottom_Hover={
                          DB_LeftSidebar_MenuItem_HauptmenuTop_marginTopBottom_Hover
                        }
                        DB_LeftSidebar_MenuItem_HauptmenuTop_paddingLeftRight_Hover={
                          DB_LeftSidebar_MenuItem_HauptmenuTop_paddingLeftRight_Hover
                        }
                        DB_LeftSidebar_MenuItem_HauptmenuTop_paddingTopBottom_Hover={
                          DB_LeftSidebar_MenuItem_HauptmenuTop_paddingTopBottom_Hover
                        }
                        DB_LeftSidebar_MenuItem_HauptmenuTop_letterSpacing_Hover={
                          DB_LeftSidebar_MenuItem_HauptmenuTop_letterSpacing_Hover
                        }
                        DB_LeftSidebar_MenuItem_HauptmenuTop_ShadowHorizontal_Hover={
                          DB_LeftSidebar_MenuItem_HauptmenuTop_ShadowHorizontal_Hover
                        }
                        DB_LeftSidebar_MenuItem_HauptmenuTop_ShadowVertical_Hover={
                          DB_LeftSidebar_MenuItem_HauptmenuTop_ShadowVertical_Hover
                        }
                        DB_LeftSidebar_MenuItem_HauptmenuTop_ShadowBlur_Hover={
                          DB_LeftSidebar_MenuItem_HauptmenuTop_ShadowBlur_Hover
                        }
                        DB_LeftSidebar_MenuItem_HauptmenuTop_ShadowColor_Hover={
                          DB_LeftSidebar_MenuItem_HauptmenuTop_ShadowColor_Hover
                        }
                        DB_LeftSidebar_MenuItem_HauptmenuTop_TextDecoration_Hover={
                          DB_LeftSidebar_MenuItem_HauptmenuTop_TextDecoration_Hover
                        }
                        DB_LeftSidebar_MenuItem_HauptmenuTop_TextDecorationStyle_Hover={
                          DB_LeftSidebar_MenuItem_HauptmenuTop_TextDecorationStyle_Hover
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Logo_DDpaddingLeft={
                          DB_LeftSidebar_MenuItem_MenuContainer_Logo_DDpaddingLeft
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Logo_DDpaddingRight={
                          DB_LeftSidebar_MenuItem_MenuContainer_Logo_DDpaddingRight
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Logo_DDpaddingTop={
                          DB_LeftSidebar_MenuItem_MenuContainer_Logo_DDpaddingTop
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Logo_DDpaddingBottom={
                          DB_LeftSidebar_MenuItem_MenuContainer_Logo_DDpaddingBottom
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Logo_DDShadowHorizontal={
                          DB_LeftSidebar_MenuItem_MenuContainer_Logo_DDShadowHorizontal
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Logo_DDShadowVertical={
                          DB_LeftSidebar_MenuItem_MenuContainer_Logo_DDShadowVertical
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Logo_DDHeight={
                          DB_LeftSidebar_MenuItem_MenuContainer_Logo_DDHeight
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Logo_DDShadowBlur={
                          DB_LeftSidebar_MenuItem_MenuContainer_Logo_DDShadowBlur
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Logo_DDShadowColor={
                          DB_LeftSidebar_MenuItem_MenuContainer_Logo_DDShadowColor
                        }
                        DB_LeftSidebar_MenuContainer_Logo_DDBackgroundColor={
                          DB_LeftSidebar_MenuContainer_Logo_DDBackgroundColor
                        }
                        SideBarLeft_ContainerTopXYZ_Farbe_Groesse_Stil_OnMouseOverAndOut={
                          SideBarLeft_ContainerTopXYZ_Farbe_Groesse_Stil_OnMouseOverAndOut
                        }
                        SideBarLeft_ContainerTopXYZ_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut={
                          SideBarLeft_ContainerTopXYZ_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
                        }
                        SideBarLeft_ContainerTopXYZ_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut={
                          SideBarLeft_ContainerTopXYZ_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut
                        }
                        SideBarLeft_ContainerTopXYZ_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut={
                          SideBarLeft_ContainerTopXYZ_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut
                        }
                        DB_LeftSidebar_MenuContainerXYZ_BackgroundColor={
                          DB_LeftSidebar_MenuContainerXYZ_BackgroundColor
                        }
                        DB_LeftSidebar_MenuContainerXYZ_PaddingTopBottom={
                          DB_LeftSidebar_MenuContainerXYZ_PaddingTopBottom
                        }
                        DB_LeftSidebar_MenuContainerXYZ_PaddingLeftRight={
                          DB_LeftSidebar_MenuContainerXYZ_PaddingLeftRight
                        }
                        DB_LeftSidebar_MenuContainerXYZ_BorderTop={
                          DB_LeftSidebar_MenuContainerXYZ_BorderTop
                        }
                        DB_LeftSidebar_MenuContainerXYZ_BorderStyleTop={
                          DB_LeftSidebar_MenuContainerXYZ_BorderStyleTop
                        }
                        DB_LeftSidebar_MenuContainerXYZ_BorderColorTop={
                          DB_LeftSidebar_MenuContainerXYZ_BorderColorTop
                        }
                        DB_LeftSidebar_MenuContainerXYZ_BorderBottom={
                          DB_LeftSidebar_MenuContainerXYZ_BorderBottom
                        }
                        DB_LeftSidebar_MenuContainerXYZ_BorderStyleBottom={
                          DB_LeftSidebar_MenuContainerXYZ_BorderStyleBottom
                        }
                        DB_LeftSidebar_MenuContainerXYZ_BorderColorBottom={
                          DB_LeftSidebar_MenuContainerXYZ_BorderColorBottom
                        }
                        DB_LeftSidebar_MenuContainerXYZ_BorderLeft={
                          DB_LeftSidebar_MenuContainerXYZ_BorderLeft
                        }
                        DB_LeftSidebar_MenuContainerXYZ_BorderStyleLeft={
                          DB_LeftSidebar_MenuContainerXYZ_BorderStyleLeft
                        }
                        DB_LeftSidebar_MenuContainerXYZ_BorderColorLeft={
                          DB_LeftSidebar_MenuContainerXYZ_BorderColorLeft
                        }
                        DB_LeftSidebar_MenuContainerXYZ_BorderRight={
                          DB_LeftSidebar_MenuContainerXYZ_BorderRight
                        }
                        DB_LeftSidebar_MenuContainerXYZ_BorderStyleRight={
                          DB_LeftSidebar_MenuContainerXYZ_BorderStyleRight
                        }
                        DB_LeftSidebar_MenuContainerXYZ_BorderColorRight={
                          DB_LeftSidebar_MenuContainerXYZ_BorderColorRight
                        }
                        DB_LeftSidebar_MenuItem_MenuContainerXYZ_BorderTopRightRadius={
                          DB_LeftSidebar_MenuItem_MenuContainerXYZ_BorderTopRightRadius
                        }
                        DB_LeftSidebar_MenuItem_MenuContainerXYZ_BorderTopLeftRadius={
                          DB_LeftSidebar_MenuItem_MenuContainerXYZ_BorderTopLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_MenuContainerXYZ_BorderBottomRightRadius={
                          DB_LeftSidebar_MenuItem_MenuContainerXYZ_BorderBottomRightRadius
                        }
                        DB_LeftSidebar_MenuItem_MenuContainerXYZ_BorderBottomLeftRadius={
                          DB_LeftSidebar_MenuItem_MenuContainerXYZ_BorderBottomLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_MenuContainerXYZ_paddingLeft={
                          DB_LeftSidebar_MenuItem_MenuContainerXYZ_paddingLeft
                        }
                        DB_LeftSidebar_MenuItem_MenuContainerXYZ_paddingRight={
                          DB_LeftSidebar_MenuItem_MenuContainerXYZ_paddingRight
                        }
                        DB_LeftSidebar_MenuItem_MenuContainerXYZ_paddingTop={
                          DB_LeftSidebar_MenuItem_MenuContainerXYZ_paddingTop
                        }
                        DB_LeftSidebar_MenuItem_MenuContainerXYZ_paddingBottom={
                          DB_LeftSidebar_MenuItem_MenuContainerXYZ_paddingBottom
                        }
                        DB_LeftSidebar_MenuItem_MenuContainerXYZ_ShadowHorizontal={
                          DB_LeftSidebar_MenuItem_MenuContainerXYZ_ShadowHorizontal
                        }
                        DB_LeftSidebar_MenuItem_MenuContainerXYZ_ShadowVertical={
                          DB_LeftSidebar_MenuItem_MenuContainerXYZ_ShadowVertical
                        }
                        DB_LeftSidebar_MenuItem_MenuContainerXYZ_ShadowBlur={
                          DB_LeftSidebar_MenuItem_MenuContainerXYZ_ShadowBlur
                        }
                        DB_LeftSidebar_MenuItem_MenuContainerXYZ_ShadowColor={
                          DB_LeftSidebar_MenuItem_MenuContainerXYZ_ShadowColor
                        }
                        DB_LeftSidebar_MenuContainerXYZ_Hover_BackgroundColor={
                          DB_LeftSidebar_MenuContainerXYZ_Hover_BackgroundColor
                        }
                        DB_LeftSidebar_MenuContainerXYZ_Hover_PaddingTopBottom={
                          DB_LeftSidebar_MenuContainerXYZ_Hover_PaddingTopBottom
                        }
                        DB_LeftSidebar_MenuContainerXYZ_Hover_PaddingLeftRight={
                          DB_LeftSidebar_MenuContainerXYZ_Hover_PaddingLeftRight
                        }
                        DB_LeftSidebar_MenuContainerXYZ_Hover_BorderTop={
                          DB_LeftSidebar_MenuContainerXYZ_Hover_BorderTop
                        }
                        DB_LeftSidebar_MenuContainerXYZ_Hover_BorderStyleTop={
                          DB_LeftSidebar_MenuContainerXYZ_Hover_BorderStyleTop
                        }
                        DB_LeftSidebar_MenuContainerXYZ_Hover_BorderColorTop={
                          DB_LeftSidebar_MenuContainerXYZ_Hover_BorderColorTop
                        }
                        DB_LeftSidebar_MenuContainerXYZ_Hover_BorderBottom={
                          DB_LeftSidebar_MenuContainerXYZ_Hover_BorderBottom
                        }
                        DB_LeftSidebar_MenuContainerXYZ_Hover_BorderStyleBottom={
                          DB_LeftSidebar_MenuContainerXYZ_Hover_BorderStyleBottom
                        }
                        DB_LeftSidebar_MenuContainerXYZ_Hover_BorderColorBottom={
                          DB_LeftSidebar_MenuContainerXYZ_Hover_BorderColorBottom
                        }
                        DB_LeftSidebar_MenuContainerXYZ_Hover_BorderLeft={
                          DB_LeftSidebar_MenuContainerXYZ_Hover_BorderLeft
                        }
                        DB_LeftSidebar_MenuContainerXYZ_Hover_BorderStyleLeft={
                          DB_LeftSidebar_MenuContainerXYZ_Hover_BorderStyleLeft
                        }
                        DB_LeftSidebar_MenuContainerXYZ_Hover_BorderColorLeft={
                          DB_LeftSidebar_MenuContainerXYZ_Hover_BorderColorLeft
                        }
                        DB_LeftSidebar_MenuContainerXYZ_Hover_BorderRight={
                          DB_LeftSidebar_MenuContainerXYZ_Hover_BorderRight
                        }
                        DB_LeftSidebar_MenuContainerXYZ_Hover_BorderStyleRight={
                          DB_LeftSidebar_MenuContainerXYZ_Hover_BorderStyleRight
                        }
                        DB_LeftSidebar_MenuContainerXYZ_Hover_BorderColorRight={
                          DB_LeftSidebar_MenuContainerXYZ_Hover_BorderColorRight
                        }
                        DB_LeftSidebar_MenuItem_MenuContainerXYZ_Hover_BorderTopRightRadius={
                          DB_LeftSidebar_MenuItem_MenuContainerXYZ_Hover_BorderTopRightRadius
                        }
                        DB_LeftSidebar_MenuItem_MenuContainerXYZ_Hover_BorderTopLeftRadius={
                          DB_LeftSidebar_MenuItem_MenuContainerXYZ_Hover_BorderTopLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_MenuContainerXYZ_Hover_BorderBottomRightRadius={
                          DB_LeftSidebar_MenuItem_MenuContainerXYZ_Hover_BorderBottomRightRadius
                        }
                        DB_LeftSidebar_MenuItem_MenuContainerXYZ_Hover_BorderBottomLeftRadius={
                          DB_LeftSidebar_MenuItem_MenuContainerXYZ_Hover_BorderBottomLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_MenuContainerXYZ_Hover_paddingLeft={
                          DB_LeftSidebar_MenuItem_MenuContainerXYZ_Hover_paddingLeft
                        }
                        DB_LeftSidebar_MenuItem_MenuContainerXYZ_Hover_paddingRight={
                          DB_LeftSidebar_MenuItem_MenuContainerXYZ_Hover_paddingRight
                        }
                        DB_LeftSidebar_MenuItem_MenuContainerXYZ_Hover_paddingTop={
                          DB_LeftSidebar_MenuItem_MenuContainerXYZ_Hover_paddingTop
                        }
                        DB_LeftSidebar_MenuItem_MenuContainerXYZ_Hover_paddingBottom={
                          DB_LeftSidebar_MenuItem_MenuContainerXYZ_Hover_paddingBottom
                        }
                        DB_LeftSidebar_MenuItem_MenuContainerXYZ_Hover_ShadowHorizontal={
                          DB_LeftSidebar_MenuItem_MenuContainerXYZ_Hover_ShadowHorizontal
                        }
                        DB_LeftSidebar_MenuItem_MenuContainerXYZ_Hover_ShadowVertical={
                          DB_LeftSidebar_MenuItem_MenuContainerXYZ_Hover_ShadowVertical
                        }
                        DB_LeftSidebar_MenuItem_MenuContainerXYZ_Hover_ShadowBlur={
                          DB_LeftSidebar_MenuItem_MenuContainerXYZ_Hover_ShadowBlur
                        }
                        DB_LeftSidebar_MenuItem_MenuContainerXYZ_Hover_ShadowColor={
                          DB_LeftSidebar_MenuItem_MenuContainerXYZ_Hover_ShadowColor
                        }
                      />
                    </Navbar>
                  </>
                )}

                {rightSocialsVisible && (
                  <>
                    {(shopBoxes.boxOneTitel || shopBoxes.boxOneContent) && (
                      <SideBarRightMessageToBuyer
                        boxTitel={shopBoxes.boxOneTitel}
                        boxContent={shopBoxes.boxOneContent}
                        SideBarLeft_ContainerSocial_Farbe_Groesse_Stil_OnMouseOverAndOut={
                          SideBarLeft_ContainerSocial_Farbe_Groesse_Stil_OnMouseOverAndOut
                        }
                        SideBarLeft_ContainerSocial_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut={
                          SideBarLeft_ContainerSocial_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
                        }
                        SideBarLeft_ContainerSocial_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut={
                          SideBarLeft_ContainerSocial_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut
                        }
                        SideBarLeft_ContainerSocial_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut={
                          SideBarLeft_ContainerSocial_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut
                        }
                        SideBarLeft_AlleSocial_Farbe_Groesse_Stil_OnMouseOverAndOut={
                          SideBarLeft_AlleSocial_Farbe_Groesse_Stil_OnMouseOverAndOut
                        }
                        SideBarLeft_AlleSocial_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut={
                          SideBarLeft_AlleSocial_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
                        }
                        SideBarLeft_AlleSocial_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut={
                          SideBarLeft_AlleSocial_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut
                        }
                        SideBarLeft_AlleSocial_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut={
                          SideBarLeft_AlleSocial_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut
                        }
                        SideBarLeft_SocialItem_Farbe_Groesse_Stil_OnMouseOverAndOut={
                          SideBarLeft_SocialItem_Farbe_Groesse_Stil_OnMouseOverAndOut
                        }
                        SideBarLeft_SocialItem_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut={
                          SideBarLeft_SocialItem_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
                        }
                        SideBarLeft_SocialItem_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut={
                          SideBarLeft_SocialItem_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut
                        }
                        SideBarLeft_SocialItem_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut={
                          SideBarLeft_SocialItem_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut
                        }
                        SideBarLeft_ContainerSocial_Farbe_Groesse_Stil_Trigger={
                          SideBarLeft_ContainerSocial_Farbe_Groesse_Stil_Trigger
                        }
                        SideBarLeft_ContainerSocial_Farbe_Groesse_Stil_Hover_Trigger={
                          SideBarLeft_ContainerSocial_Farbe_Groesse_Stil_Hover_Trigger
                        }
                        SideBarLeft_ContainerSocial_HintergrundFarbe_Radius_Stil_Trigger={
                          SideBarLeft_ContainerSocial_HintergrundFarbe_Radius_Stil_Trigger
                        }
                        SideBarLeft_ContainerSocial_HintergrundFarbe_Radius_Stil_Hover_Trigger={
                          SideBarLeft_ContainerSocial_HintergrundFarbe_Radius_Stil_Hover_Trigger
                        }
                        SideBarLeft_AlleSocial_Farbe_Groesse_Stil_Trigger={
                          SideBarLeft_AlleSocial_Farbe_Groesse_Stil_Trigger
                        }
                        SideBarLeft_AlleSocial_Farbe_Groesse_Stil_Hover_Trigger={
                          SideBarLeft_AlleSocial_Farbe_Groesse_Stil_Hover_Trigger
                        }
                        SideBarLeft_AlleSocial_HintergrundFarbe_Radius_Stil_Trigger={
                          SideBarLeft_AlleSocial_HintergrundFarbe_Radius_Stil_Trigger
                        }
                        SideBarLeft_AlleSocial_HintergrundFarbe_Radius_Stil_Hover_Trigger={
                          SideBarLeft_AlleSocial_HintergrundFarbe_Radius_Stil_Hover_Trigger
                        }
                        SideBarLeft_SocialItem_Farbe_Groesse_Stil_Trigger={
                          SideBarLeft_SocialItem_Farbe_Groesse_Stil_Trigger
                        }
                        SideBarLeft_SocialItem_Farbe_Groesse_Stil_Hover_Trigger={
                          SideBarLeft_SocialItem_Farbe_Groesse_Stil_Hover_Trigger
                        }
                        SideBarLeft_SocialItem_HintergrundFarbe_Radius_Stil_Trigger={
                          SideBarLeft_SocialItem_HintergrundFarbe_Radius_Stil_Trigger
                        }
                        SideBarLeft_SocialItem_HintergrundFarbe_Radius_Stil_Hover_Trigger={
                          SideBarLeft_SocialItem_HintergrundFarbe_Radius_Stil_Hover_Trigger
                        }
                        DB_fontFace_FontFamilyName={DB_fontFace_FontFamilyName}
                        DB_LeftSidebar_MenuContainer_Social_DDBackgroundColor={
                          DB_LeftSidebar_MenuContainer_Social_DDBackgroundColor
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDBorderTop={
                          DB_LeftSidebar_MenuContainer_Social_DDBorderTop
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDBorderStyleTop={
                          DB_LeftSidebar_MenuContainer_Social_DDBorderStyleTop
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDBorderColorTop={
                          DB_LeftSidebar_MenuContainer_Social_DDBorderColorTop
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDBorderBottom={
                          DB_LeftSidebar_MenuContainer_Social_DDBorderBottom
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDBorderStyleBottom={
                          DB_LeftSidebar_MenuContainer_Social_DDBorderStyleBottom
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDBorderColorBottom={
                          DB_LeftSidebar_MenuContainer_Social_DDBorderColorBottom
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDBorderLeft={
                          DB_LeftSidebar_MenuContainer_Social_DDBorderLeft
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDBorderStyleLeft={
                          DB_LeftSidebar_MenuContainer_Social_DDBorderStyleLeft
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDBorderColorLeft={
                          DB_LeftSidebar_MenuContainer_Social_DDBorderColorLeft
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDBorderRight={
                          DB_LeftSidebar_MenuContainer_Social_DDBorderRight
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDBorderStyleRight={
                          DB_LeftSidebar_MenuContainer_Social_DDBorderStyleRight
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDBorderColorRight={
                          DB_LeftSidebar_MenuContainer_Social_DDBorderColorRight
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDBorderTopRightRadius={
                          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDBorderTopRightRadius
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDBorderTopLeftRadius={
                          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDBorderTopLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDBorderBottomRightRadius={
                          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDBorderBottomRightRadius
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDBorderBottomLeftRadius={
                          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDBorderBottomLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDpaddingLeft={
                          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDpaddingLeft
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDpaddingRight={
                          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDpaddingRight
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDpaddingTop={
                          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDpaddingTop
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDpaddingBottom={
                          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDpaddingBottom
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDShadowHorizontal={
                          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDShadowHorizontal
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDShadowVertical={
                          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDShadowVertical
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDShadowBlur={
                          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDShadowBlur
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDShadowColor={
                          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDShadowColor
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDHover_BackgroundColor={
                          DB_LeftSidebar_MenuContainer_Social_DDHover_BackgroundColor
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDHover_BorderTop={
                          DB_LeftSidebar_MenuContainer_Social_DDHover_BorderTop
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDHover_BorderStyleTop={
                          DB_LeftSidebar_MenuContainer_Social_DDHover_BorderStyleTop
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDHover_BorderColorTop={
                          DB_LeftSidebar_MenuContainer_Social_DDHover_BorderColorTop
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDHover_BorderBottom={
                          DB_LeftSidebar_MenuContainer_Social_DDHover_BorderBottom
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDHover_BorderStyleBottom={
                          DB_LeftSidebar_MenuContainer_Social_DDHover_BorderStyleBottom
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDHover_BorderColorBottom={
                          DB_LeftSidebar_MenuContainer_Social_DDHover_BorderColorBottom
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDHover_BorderLeft={
                          DB_LeftSidebar_MenuContainer_Social_DDHover_BorderLeft
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDHover_BorderStyleLeft={
                          DB_LeftSidebar_MenuContainer_Social_DDHover_BorderStyleLeft
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDHover_BorderColorLeft={
                          DB_LeftSidebar_MenuContainer_Social_DDHover_BorderColorLeft
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDHover_BorderRight={
                          DB_LeftSidebar_MenuContainer_Social_DDHover_BorderRight
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDHover_BorderStyleRight={
                          DB_LeftSidebar_MenuContainer_Social_DDHover_BorderStyleRight
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDHover_BorderColorRight={
                          DB_LeftSidebar_MenuContainer_Social_DDHover_BorderColorRight
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_BorderTopRightRadius={
                          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_BorderTopRightRadius
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_BorderTopLeftRadius={
                          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_BorderTopLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_BorderBottomRightRadius={
                          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_BorderBottomRightRadius
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_BorderBottomLeftRadius={
                          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_BorderBottomLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_paddingLeft={
                          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_paddingLeft
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_paddingRight={
                          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_paddingRight
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_paddingTop={
                          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_paddingTop
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_paddingBottom={
                          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_paddingBottom
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_ShadowHorizontal={
                          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_ShadowHorizontal
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_ShadowVertical={
                          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_ShadowVertical
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_ShadowBlur={
                          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_ShadowBlur
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_ShadowColor={
                          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_ShadowColor
                        }
                        DB_fontFace_SocialTitelFontFamily_FontFamilyName={
                          DB_fontFace_SocialTitelFontFamily_FontFamilyName
                        }
                        DB_fontFace_SocialTitelFontFamily_FontFamilySrc={
                          DB_fontFace_SocialTitelFontFamily_FontFamilySrc
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_FontWeight={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_FontWeight
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_FontColor={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_FontColor
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_FontSize={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_FontSize
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BackgroundColor={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BackgroundColor
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_TextAlign={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_TextAlign
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderTop={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderTop
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderStyleTop={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderStyleTop
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderColorTop={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderColorTop
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderBottom={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderBottom
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderStyleBottom={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderStyleBottom
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderColorBottom={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderColorBottom
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderLeft={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderLeft
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderStyleLeft={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderStyleLeft
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderColorLeft={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderColorLeft
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderRight={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderRight
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderStyleRight={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderStyleRight
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderColorRight={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderColorRight
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderTopRightRadius={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderTopRightRadius
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderTopLeftRadius={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderTopLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderBottomRightRadius={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderBottomRightRadius
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderBottomLeftRadius={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderBottomLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_marginLeftRight={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_marginLeftRight
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_marginTopBottom={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_marginTopBottom
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_paddingLeftRight={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_paddingLeftRight
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_paddingTopBottom={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_paddingTopBottom
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_letterSpacing={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_letterSpacing
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowHorizontal={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowHorizontal
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowVertical={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowVertical
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowBlur={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowBlur
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowColor={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowColor
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_TextDecoration={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_TextDecoration
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_TextDecorationStyle={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_TextDecorationStyle
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_FontWeight={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_FontWeight
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_FontColor={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_FontColor
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_FontSize={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_FontSize
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BackgroundColor={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BackgroundColor
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_TextAlign={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_TextAlign
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderTop={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderTop
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderStyleTop={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderStyleTop
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderColorTop={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderColorTop
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderBottom={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderBottom
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderStyleBottom={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderStyleBottom
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderColorBottom={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderColorBottom
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderLeft={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderLeft
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderStyleLeft={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderStyleLeft
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderColorLeft={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderColorLeft
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderRight={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderRight
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderStyleRight={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderStyleRight
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderColorRight={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderColorRight
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderTopRightRadius={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderTopRightRadius
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderTopLeftRadius={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderTopLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderBottomRightRadius={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderBottomRightRadius
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderBottomLeftRadius={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderBottomLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_marginLeftRight_Hover={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_marginLeftRight_Hover
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_marginTopBottom_Hover={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_marginTopBottom_Hover
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_paddingLeftRight_Hover={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_paddingLeftRight_Hover
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_paddingTopBottom_Hover={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_paddingTopBottom_Hover
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_letterSpacing_Hover={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_letterSpacing_Hover
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowHorizontal_Hover={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowHorizontal_Hover
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowVertical_Hover={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowVertical_Hover
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowBlur_Hover={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowBlur_Hover
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowColor_Hover={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowColor_Hover
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_TextDecoration_Hover={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_TextDecoration_Hover
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_TextDecorationStyle_Hover={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_TextDecorationStyle_Hover
                        }
                        DB_fontFace_SocialTextFontFamily_FontFamilyName={
                          DB_fontFace_SocialTextFontFamily_FontFamilyName
                        }
                        DB_fontFace_SocialTextFontFamily_FontFamilySrc={
                          DB_fontFace_SocialTextFontFamily_FontFamilySrc
                        }
                        DB_LeftSidebar_MenuItem_SocialText_FontWeight={
                          DB_LeftSidebar_MenuItem_SocialText_FontWeight
                        }
                        DB_LeftSidebar_MenuItem_SocialText_FontColor={
                          DB_LeftSidebar_MenuItem_SocialText_FontColor
                        }
                        DB_LeftSidebar_MenuItem_SocialText_FontSize={
                          DB_LeftSidebar_MenuItem_SocialText_FontSize
                        }
                        DB_LeftSidebar_MenuItem_SocialText_BackgroundColor={
                          DB_LeftSidebar_MenuItem_SocialText_BackgroundColor
                        }
                        DB_LeftSidebar_MenuItem_SocialText_BorderTop={
                          DB_LeftSidebar_MenuItem_SocialText_BorderTop
                        }
                        DB_LeftSidebar_MenuItem_SocialText_BorderStyleTop={
                          DB_LeftSidebar_MenuItem_SocialText_BorderStyleTop
                        }
                        DB_LeftSidebar_MenuItem_SocialText_BorderColorTop={
                          DB_LeftSidebar_MenuItem_SocialText_BorderColorTop
                        }
                        DB_LeftSidebar_MenuItem_SocialText_BorderBottom={
                          DB_LeftSidebar_MenuItem_SocialText_BorderBottom
                        }
                        DB_LeftSidebar_MenuItem_SocialText_BorderStyleBottom={
                          DB_LeftSidebar_MenuItem_SocialText_BorderStyleBottom
                        }
                        DB_LeftSidebar_MenuItem_SocialText_BorderColorBottom={
                          DB_LeftSidebar_MenuItem_SocialText_BorderColorBottom
                        }
                        DB_LeftSidebar_MenuItem_SocialText_BorderLeft={
                          DB_LeftSidebar_MenuItem_SocialText_BorderLeft
                        }
                        DB_LeftSidebar_MenuItem_SocialText_BorderStyleLeft={
                          DB_LeftSidebar_MenuItem_SocialText_BorderStyleLeft
                        }
                        DB_LeftSidebar_MenuItem_SocialText_BorderColorLeft={
                          DB_LeftSidebar_MenuItem_SocialText_BorderColorLeft
                        }
                        DB_LeftSidebar_MenuItem_SocialText_BorderRight={
                          DB_LeftSidebar_MenuItem_SocialText_BorderRight
                        }
                        DB_LeftSidebar_MenuItem_SocialText_BorderStyleRight={
                          DB_LeftSidebar_MenuItem_SocialText_BorderStyleRight
                        }
                        DB_LeftSidebar_MenuItem_SocialText_BorderColorRight={
                          DB_LeftSidebar_MenuItem_SocialText_BorderColorRight
                        }
                        DB_LeftSidebar_MenuItem_SocialText_BorderTopRightRadius={
                          DB_LeftSidebar_MenuItem_SocialText_BorderTopRightRadius
                        }
                        DB_LeftSidebar_MenuItem_SocialText_BorderTopLeftRadius={
                          DB_LeftSidebar_MenuItem_SocialText_BorderTopLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_SocialText_BorderBottomRightRadius={
                          DB_LeftSidebar_MenuItem_SocialText_BorderBottomRightRadius
                        }
                        DB_LeftSidebar_MenuItem_SocialText_BorderBottomLeftRadius={
                          DB_LeftSidebar_MenuItem_SocialText_BorderBottomLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_SocialText_marginLeftRight={
                          DB_LeftSidebar_MenuItem_SocialText_marginLeftRight
                        }
                        DB_LeftSidebar_MenuItem_SocialText_marginTopBottom={
                          DB_LeftSidebar_MenuItem_SocialText_marginTopBottom
                        }
                        DB_LeftSidebar_MenuItem_SocialText_paddingLeftRight={
                          DB_LeftSidebar_MenuItem_SocialText_paddingLeftRight
                        }
                        DB_LeftSidebar_MenuItem_SocialText_paddingTopBottom={
                          DB_LeftSidebar_MenuItem_SocialText_paddingTopBottom
                        }
                        DB_LeftSidebar_MenuItem_SocialText_letterSpacing={
                          DB_LeftSidebar_MenuItem_SocialText_letterSpacing
                        }
                        DB_LeftSidebar_MenuItem_SocialText_ShadowHorizontal={
                          DB_LeftSidebar_MenuItem_SocialText_ShadowHorizontal
                        }
                        DB_LeftSidebar_MenuItem_SocialText_ShadowVertical={
                          DB_LeftSidebar_MenuItem_SocialText_ShadowVertical
                        }
                        DB_LeftSidebar_MenuItem_SocialText_ShadowBlur={
                          DB_LeftSidebar_MenuItem_SocialText_ShadowBlur
                        }
                        DB_LeftSidebar_MenuItem_SocialText_ShadowColor={
                          DB_LeftSidebar_MenuItem_SocialText_ShadowColor
                        }
                        DB_LeftSidebar_MenuItem_SocialText_TextAlign={
                          DB_LeftSidebar_MenuItem_SocialText_TextAlign
                        }
                        DB_LeftSidebar_MenuItem_SocialText_TextDecoration={
                          DB_LeftSidebar_MenuItem_SocialText_TextDecoration
                        }
                        DB_LeftSidebar_MenuItem_SocialText_TextDecorationStyle={
                          DB_LeftSidebar_MenuItem_SocialText_TextDecorationStyle
                        }
                        DB_LeftSidebar_MenuItem_SocialText_Hover_FontWeight={
                          DB_LeftSidebar_MenuItem_SocialText_Hover_FontWeight
                        }
                        DB_LeftSidebar_MenuItem_SocialText_Hover_FontColor={
                          DB_LeftSidebar_MenuItem_SocialText_Hover_FontColor
                        }
                        DB_LeftSidebar_MenuItem_SocialText_Hover_FontSize={
                          DB_LeftSidebar_MenuItem_SocialText_Hover_FontSize
                        }
                        DB_LeftSidebar_MenuItem_SocialText_Hover_BackgroundColor={
                          DB_LeftSidebar_MenuItem_SocialText_Hover_BackgroundColor
                        }
                        DB_LeftSidebar_MenuItem_SocialText_Hover_TextAlign={
                          DB_LeftSidebar_MenuItem_SocialText_Hover_TextAlign
                        }
                        DB_LeftSidebar_MenuItem_SocialText_Hover_BorderTop={
                          DB_LeftSidebar_MenuItem_SocialText_Hover_BorderTop
                        }
                        DB_LeftSidebar_MenuItem_SocialText_Hover_BorderStyleTop={
                          DB_LeftSidebar_MenuItem_SocialText_Hover_BorderStyleTop
                        }
                        DB_LeftSidebar_MenuItem_SocialText_Hover_BorderColorTop={
                          DB_LeftSidebar_MenuItem_SocialText_Hover_BorderColorTop
                        }
                        DB_LeftSidebar_MenuItem_SocialText_Hover_BorderBottom={
                          DB_LeftSidebar_MenuItem_SocialText_Hover_BorderBottom
                        }
                        DB_LeftSidebar_MenuItem_SocialText_Hover_BorderStyleBottom={
                          DB_LeftSidebar_MenuItem_SocialText_Hover_BorderStyleBottom
                        }
                        DB_LeftSidebar_MenuItem_SocialText_Hover_BorderColorBottom={
                          DB_LeftSidebar_MenuItem_SocialText_Hover_BorderColorBottom
                        }
                        DB_LeftSidebar_MenuItem_SocialText_Hover_BorderLeft={
                          DB_LeftSidebar_MenuItem_SocialText_Hover_BorderLeft
                        }
                        DB_LeftSidebar_MenuItem_SocialText_Hover_BorderStyleLeft={
                          DB_LeftSidebar_MenuItem_SocialText_Hover_BorderStyleLeft
                        }
                        DB_LeftSidebar_MenuItem_SocialText_Hover_BorderColorLeft={
                          DB_LeftSidebar_MenuItem_SocialText_Hover_BorderColorLeft
                        }
                        DB_LeftSidebar_MenuItem_SocialText_Hover_BorderRight={
                          DB_LeftSidebar_MenuItem_SocialText_Hover_BorderRight
                        }
                        DB_LeftSidebar_MenuItem_SocialText_Hover_BorderStyleRight={
                          DB_LeftSidebar_MenuItem_SocialText_Hover_BorderStyleRight
                        }
                        DB_LeftSidebar_MenuItem_SocialText_Hover_BorderColorRight={
                          DB_LeftSidebar_MenuItem_SocialText_Hover_BorderColorRight
                        }
                        DB_LeftSidebar_MenuItem_SocialText_Hover_BorderTopRightRadius={
                          DB_LeftSidebar_MenuItem_SocialText_Hover_BorderTopRightRadius
                        }
                        DB_LeftSidebar_MenuItem_SocialText_Hover_BorderTopLeftRadius={
                          DB_LeftSidebar_MenuItem_SocialText_Hover_BorderTopLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_SocialText_Hover_BorderBottomRightRadius={
                          DB_LeftSidebar_MenuItem_SocialText_Hover_BorderBottomRightRadius
                        }
                        DB_LeftSidebar_MenuItem_SocialText_Hover_BorderBottomLeftRadius={
                          DB_LeftSidebar_MenuItem_SocialText_Hover_BorderBottomLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_SocialText_marginLeftRight_Hover={
                          DB_LeftSidebar_MenuItem_SocialText_marginLeftRight_Hover
                        }
                        DB_LeftSidebar_MenuItem_SocialText_marginTopBottom_Hover={
                          DB_LeftSidebar_MenuItem_SocialText_marginTopBottom_Hover
                        }
                        DB_LeftSidebar_MenuItem_SocialText_paddingLeftRight_Hover={
                          DB_LeftSidebar_MenuItem_SocialText_paddingLeftRight_Hover
                        }
                        DB_LeftSidebar_MenuItem_SocialText_paddingTopBottom_Hover={
                          DB_LeftSidebar_MenuItem_SocialText_paddingTopBottom_Hover
                        }
                        DB_LeftSidebar_MenuItem_SocialText_letterSpacing_Hover={
                          DB_LeftSidebar_MenuItem_SocialText_letterSpacing_Hover
                        }
                        DB_LeftSidebar_MenuItem_SocialText_ShadowHorizontal_Hover={
                          DB_LeftSidebar_MenuItem_SocialText_ShadowHorizontal_Hover
                        }
                        DB_LeftSidebar_MenuItem_SocialText_ShadowVertical_Hover={
                          DB_LeftSidebar_MenuItem_SocialText_ShadowVertical_Hover
                        }
                        DB_LeftSidebar_MenuItem_SocialText_ShadowBlur_Hover={
                          DB_LeftSidebar_MenuItem_SocialText_ShadowBlur_Hover
                        }
                        DB_LeftSidebar_MenuItem_SocialText_ShadowColor_Hover={
                          DB_LeftSidebar_MenuItem_SocialText_ShadowColor_Hover
                        }
                        DB_LeftSidebar_MenuItem_SocialText_TextDecoration_Hover={
                          DB_LeftSidebar_MenuItem_SocialText_TextDecoration_Hover
                        }
                        DB_LeftSidebar_MenuItem_SocialText_TextDecorationStyle_Hover={
                          DB_LeftSidebar_MenuItem_SocialText_TextDecorationStyle_Hover
                        }
                      />
                    )}
                    {(shopBoxes.boxTwoTitel || shopBoxes.boxTwoContent) && (
                      <SideBarRightMessageToBuyer
                        boxTitel={shopBoxes.boxTwoTitel}
                        boxContent={shopBoxes.boxTwoContent}
                        SideBarLeft_ContainerSocial_Farbe_Groesse_Stil_OnMouseOverAndOut={
                          SideBarLeft_ContainerSocial_Farbe_Groesse_Stil_OnMouseOverAndOut
                        }
                        SideBarLeft_ContainerSocial_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut={
                          SideBarLeft_ContainerSocial_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
                        }
                        SideBarLeft_ContainerSocial_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut={
                          SideBarLeft_ContainerSocial_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut
                        }
                        SideBarLeft_ContainerSocial_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut={
                          SideBarLeft_ContainerSocial_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut
                        }
                        SideBarLeft_AlleSocial_Farbe_Groesse_Stil_OnMouseOverAndOut={
                          SideBarLeft_AlleSocial_Farbe_Groesse_Stil_OnMouseOverAndOut
                        }
                        SideBarLeft_AlleSocial_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut={
                          SideBarLeft_AlleSocial_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
                        }
                        SideBarLeft_AlleSocial_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut={
                          SideBarLeft_AlleSocial_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut
                        }
                        SideBarLeft_AlleSocial_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut={
                          SideBarLeft_AlleSocial_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut
                        }
                        SideBarLeft_SocialItem_Farbe_Groesse_Stil_OnMouseOverAndOut={
                          SideBarLeft_SocialItem_Farbe_Groesse_Stil_OnMouseOverAndOut
                        }
                        SideBarLeft_SocialItem_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut={
                          SideBarLeft_SocialItem_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
                        }
                        SideBarLeft_SocialItem_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut={
                          SideBarLeft_SocialItem_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut
                        }
                        SideBarLeft_SocialItem_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut={
                          SideBarLeft_SocialItem_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut
                        }
                        SideBarLeft_ContainerSocial_Farbe_Groesse_Stil_Trigger={
                          SideBarLeft_ContainerSocial_Farbe_Groesse_Stil_Trigger
                        }
                        SideBarLeft_ContainerSocial_Farbe_Groesse_Stil_Hover_Trigger={
                          SideBarLeft_ContainerSocial_Farbe_Groesse_Stil_Hover_Trigger
                        }
                        SideBarLeft_ContainerSocial_HintergrundFarbe_Radius_Stil_Trigger={
                          SideBarLeft_ContainerSocial_HintergrundFarbe_Radius_Stil_Trigger
                        }
                        SideBarLeft_ContainerSocial_HintergrundFarbe_Radius_Stil_Hover_Trigger={
                          SideBarLeft_ContainerSocial_HintergrundFarbe_Radius_Stil_Hover_Trigger
                        }
                        SideBarLeft_AlleSocial_Farbe_Groesse_Stil_Trigger={
                          SideBarLeft_AlleSocial_Farbe_Groesse_Stil_Trigger
                        }
                        SideBarLeft_AlleSocial_Farbe_Groesse_Stil_Hover_Trigger={
                          SideBarLeft_AlleSocial_Farbe_Groesse_Stil_Hover_Trigger
                        }
                        SideBarLeft_AlleSocial_HintergrundFarbe_Radius_Stil_Trigger={
                          SideBarLeft_AlleSocial_HintergrundFarbe_Radius_Stil_Trigger
                        }
                        SideBarLeft_AlleSocial_HintergrundFarbe_Radius_Stil_Hover_Trigger={
                          SideBarLeft_AlleSocial_HintergrundFarbe_Radius_Stil_Hover_Trigger
                        }
                        SideBarLeft_SocialItem_Farbe_Groesse_Stil_Trigger={
                          SideBarLeft_SocialItem_Farbe_Groesse_Stil_Trigger
                        }
                        SideBarLeft_SocialItem_Farbe_Groesse_Stil_Hover_Trigger={
                          SideBarLeft_SocialItem_Farbe_Groesse_Stil_Hover_Trigger
                        }
                        SideBarLeft_SocialItem_HintergrundFarbe_Radius_Stil_Trigger={
                          SideBarLeft_SocialItem_HintergrundFarbe_Radius_Stil_Trigger
                        }
                        SideBarLeft_SocialItem_HintergrundFarbe_Radius_Stil_Hover_Trigger={
                          SideBarLeft_SocialItem_HintergrundFarbe_Radius_Stil_Hover_Trigger
                        }
                        DB_fontFace_FontFamilyName={DB_fontFace_FontFamilyName}
                        DB_LeftSidebar_MenuContainer_Social_DDBackgroundColor={
                          DB_LeftSidebar_MenuContainer_Social_DDBackgroundColor
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDBorderTop={
                          DB_LeftSidebar_MenuContainer_Social_DDBorderTop
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDBorderStyleTop={
                          DB_LeftSidebar_MenuContainer_Social_DDBorderStyleTop
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDBorderColorTop={
                          DB_LeftSidebar_MenuContainer_Social_DDBorderColorTop
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDBorderBottom={
                          DB_LeftSidebar_MenuContainer_Social_DDBorderBottom
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDBorderStyleBottom={
                          DB_LeftSidebar_MenuContainer_Social_DDBorderStyleBottom
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDBorderColorBottom={
                          DB_LeftSidebar_MenuContainer_Social_DDBorderColorBottom
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDBorderLeft={
                          DB_LeftSidebar_MenuContainer_Social_DDBorderLeft
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDBorderStyleLeft={
                          DB_LeftSidebar_MenuContainer_Social_DDBorderStyleLeft
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDBorderColorLeft={
                          DB_LeftSidebar_MenuContainer_Social_DDBorderColorLeft
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDBorderRight={
                          DB_LeftSidebar_MenuContainer_Social_DDBorderRight
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDBorderStyleRight={
                          DB_LeftSidebar_MenuContainer_Social_DDBorderStyleRight
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDBorderColorRight={
                          DB_LeftSidebar_MenuContainer_Social_DDBorderColorRight
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDBorderTopRightRadius={
                          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDBorderTopRightRadius
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDBorderTopLeftRadius={
                          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDBorderTopLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDBorderBottomRightRadius={
                          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDBorderBottomRightRadius
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDBorderBottomLeftRadius={
                          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDBorderBottomLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDpaddingLeft={
                          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDpaddingLeft
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDpaddingRight={
                          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDpaddingRight
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDpaddingTop={
                          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDpaddingTop
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDpaddingBottom={
                          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDpaddingBottom
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDShadowHorizontal={
                          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDShadowHorizontal
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDShadowVertical={
                          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDShadowVertical
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDShadowBlur={
                          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDShadowBlur
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDShadowColor={
                          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDShadowColor
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDHover_BackgroundColor={
                          DB_LeftSidebar_MenuContainer_Social_DDHover_BackgroundColor
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDHover_BorderTop={
                          DB_LeftSidebar_MenuContainer_Social_DDHover_BorderTop
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDHover_BorderStyleTop={
                          DB_LeftSidebar_MenuContainer_Social_DDHover_BorderStyleTop
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDHover_BorderColorTop={
                          DB_LeftSidebar_MenuContainer_Social_DDHover_BorderColorTop
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDHover_BorderBottom={
                          DB_LeftSidebar_MenuContainer_Social_DDHover_BorderBottom
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDHover_BorderStyleBottom={
                          DB_LeftSidebar_MenuContainer_Social_DDHover_BorderStyleBottom
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDHover_BorderColorBottom={
                          DB_LeftSidebar_MenuContainer_Social_DDHover_BorderColorBottom
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDHover_BorderLeft={
                          DB_LeftSidebar_MenuContainer_Social_DDHover_BorderLeft
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDHover_BorderStyleLeft={
                          DB_LeftSidebar_MenuContainer_Social_DDHover_BorderStyleLeft
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDHover_BorderColorLeft={
                          DB_LeftSidebar_MenuContainer_Social_DDHover_BorderColorLeft
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDHover_BorderRight={
                          DB_LeftSidebar_MenuContainer_Social_DDHover_BorderRight
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDHover_BorderStyleRight={
                          DB_LeftSidebar_MenuContainer_Social_DDHover_BorderStyleRight
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDHover_BorderColorRight={
                          DB_LeftSidebar_MenuContainer_Social_DDHover_BorderColorRight
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_BorderTopRightRadius={
                          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_BorderTopRightRadius
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_BorderTopLeftRadius={
                          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_BorderTopLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_BorderBottomRightRadius={
                          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_BorderBottomRightRadius
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_BorderBottomLeftRadius={
                          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_BorderBottomLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_paddingLeft={
                          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_paddingLeft
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_paddingRight={
                          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_paddingRight
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_paddingTop={
                          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_paddingTop
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_paddingBottom={
                          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_paddingBottom
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_ShadowHorizontal={
                          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_ShadowHorizontal
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_ShadowVertical={
                          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_ShadowVertical
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_ShadowBlur={
                          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_ShadowBlur
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_ShadowColor={
                          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_ShadowColor
                        }
                        DB_fontFace_SocialTitelFontFamily_FontFamilyName={
                          DB_fontFace_SocialTitelFontFamily_FontFamilyName
                        }
                        DB_fontFace_SocialTitelFontFamily_FontFamilySrc={
                          DB_fontFace_SocialTitelFontFamily_FontFamilySrc
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_FontWeight={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_FontWeight
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_FontColor={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_FontColor
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_FontSize={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_FontSize
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BackgroundColor={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BackgroundColor
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_TextAlign={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_TextAlign
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderTop={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderTop
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderStyleTop={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderStyleTop
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderColorTop={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderColorTop
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderBottom={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderBottom
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderStyleBottom={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderStyleBottom
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderColorBottom={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderColorBottom
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderLeft={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderLeft
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderStyleLeft={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderStyleLeft
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderColorLeft={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderColorLeft
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderRight={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderRight
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderStyleRight={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderStyleRight
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderColorRight={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderColorRight
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderTopRightRadius={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderTopRightRadius
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderTopLeftRadius={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderTopLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderBottomRightRadius={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderBottomRightRadius
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderBottomLeftRadius={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderBottomLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_marginLeftRight={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_marginLeftRight
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_marginTopBottom={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_marginTopBottom
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_paddingLeftRight={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_paddingLeftRight
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_paddingTopBottom={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_paddingTopBottom
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_letterSpacing={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_letterSpacing
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowHorizontal={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowHorizontal
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowVertical={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowVertical
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowBlur={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowBlur
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowColor={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowColor
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_TextDecoration={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_TextDecoration
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_TextDecorationStyle={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_TextDecorationStyle
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_FontWeight={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_FontWeight
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_FontColor={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_FontColor
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_FontSize={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_FontSize
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BackgroundColor={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BackgroundColor
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_TextAlign={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_TextAlign
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderTop={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderTop
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderStyleTop={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderStyleTop
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderColorTop={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderColorTop
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderBottom={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderBottom
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderStyleBottom={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderStyleBottom
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderColorBottom={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderColorBottom
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderLeft={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderLeft
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderStyleLeft={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderStyleLeft
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderColorLeft={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderColorLeft
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderRight={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderRight
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderStyleRight={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderStyleRight
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderColorRight={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderColorRight
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderTopRightRadius={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderTopRightRadius
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderTopLeftRadius={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderTopLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderBottomRightRadius={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderBottomRightRadius
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderBottomLeftRadius={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderBottomLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_marginLeftRight_Hover={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_marginLeftRight_Hover
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_marginTopBottom_Hover={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_marginTopBottom_Hover
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_paddingLeftRight_Hover={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_paddingLeftRight_Hover
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_paddingTopBottom_Hover={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_paddingTopBottom_Hover
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_letterSpacing_Hover={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_letterSpacing_Hover
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowHorizontal_Hover={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowHorizontal_Hover
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowVertical_Hover={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowVertical_Hover
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowBlur_Hover={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowBlur_Hover
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowColor_Hover={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowColor_Hover
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_TextDecoration_Hover={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_TextDecoration_Hover
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_TextDecorationStyle_Hover={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_TextDecorationStyle_Hover
                        }
                        DB_fontFace_SocialTextFontFamily_FontFamilyName={
                          DB_fontFace_SocialTextFontFamily_FontFamilyName
                        }
                        DB_fontFace_SocialTextFontFamily_FontFamilySrc={
                          DB_fontFace_SocialTextFontFamily_FontFamilySrc
                        }
                        DB_LeftSidebar_MenuItem_SocialText_FontWeight={
                          DB_LeftSidebar_MenuItem_SocialText_FontWeight
                        }
                        DB_LeftSidebar_MenuItem_SocialText_FontColor={
                          DB_LeftSidebar_MenuItem_SocialText_FontColor
                        }
                        DB_LeftSidebar_MenuItem_SocialText_FontSize={
                          DB_LeftSidebar_MenuItem_SocialText_FontSize
                        }
                        DB_LeftSidebar_MenuItem_SocialText_BackgroundColor={
                          DB_LeftSidebar_MenuItem_SocialText_BackgroundColor
                        }
                        DB_LeftSidebar_MenuItem_SocialText_BorderTop={
                          DB_LeftSidebar_MenuItem_SocialText_BorderTop
                        }
                        DB_LeftSidebar_MenuItem_SocialText_BorderStyleTop={
                          DB_LeftSidebar_MenuItem_SocialText_BorderStyleTop
                        }
                        DB_LeftSidebar_MenuItem_SocialText_BorderColorTop={
                          DB_LeftSidebar_MenuItem_SocialText_BorderColorTop
                        }
                        DB_LeftSidebar_MenuItem_SocialText_BorderBottom={
                          DB_LeftSidebar_MenuItem_SocialText_BorderBottom
                        }
                        DB_LeftSidebar_MenuItem_SocialText_BorderStyleBottom={
                          DB_LeftSidebar_MenuItem_SocialText_BorderStyleBottom
                        }
                        DB_LeftSidebar_MenuItem_SocialText_BorderColorBottom={
                          DB_LeftSidebar_MenuItem_SocialText_BorderColorBottom
                        }
                        DB_LeftSidebar_MenuItem_SocialText_BorderLeft={
                          DB_LeftSidebar_MenuItem_SocialText_BorderLeft
                        }
                        DB_LeftSidebar_MenuItem_SocialText_BorderStyleLeft={
                          DB_LeftSidebar_MenuItem_SocialText_BorderStyleLeft
                        }
                        DB_LeftSidebar_MenuItem_SocialText_BorderColorLeft={
                          DB_LeftSidebar_MenuItem_SocialText_BorderColorLeft
                        }
                        DB_LeftSidebar_MenuItem_SocialText_BorderRight={
                          DB_LeftSidebar_MenuItem_SocialText_BorderRight
                        }
                        DB_LeftSidebar_MenuItem_SocialText_BorderStyleRight={
                          DB_LeftSidebar_MenuItem_SocialText_BorderStyleRight
                        }
                        DB_LeftSidebar_MenuItem_SocialText_BorderColorRight={
                          DB_LeftSidebar_MenuItem_SocialText_BorderColorRight
                        }
                        DB_LeftSidebar_MenuItem_SocialText_BorderTopRightRadius={
                          DB_LeftSidebar_MenuItem_SocialText_BorderTopRightRadius
                        }
                        DB_LeftSidebar_MenuItem_SocialText_BorderTopLeftRadius={
                          DB_LeftSidebar_MenuItem_SocialText_BorderTopLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_SocialText_BorderBottomRightRadius={
                          DB_LeftSidebar_MenuItem_SocialText_BorderBottomRightRadius
                        }
                        DB_LeftSidebar_MenuItem_SocialText_BorderBottomLeftRadius={
                          DB_LeftSidebar_MenuItem_SocialText_BorderBottomLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_SocialText_marginLeftRight={
                          DB_LeftSidebar_MenuItem_SocialText_marginLeftRight
                        }
                        DB_LeftSidebar_MenuItem_SocialText_marginTopBottom={
                          DB_LeftSidebar_MenuItem_SocialText_marginTopBottom
                        }
                        DB_LeftSidebar_MenuItem_SocialText_paddingLeftRight={
                          DB_LeftSidebar_MenuItem_SocialText_paddingLeftRight
                        }
                        DB_LeftSidebar_MenuItem_SocialText_paddingTopBottom={
                          DB_LeftSidebar_MenuItem_SocialText_paddingTopBottom
                        }
                        DB_LeftSidebar_MenuItem_SocialText_letterSpacing={
                          DB_LeftSidebar_MenuItem_SocialText_letterSpacing
                        }
                        DB_LeftSidebar_MenuItem_SocialText_ShadowHorizontal={
                          DB_LeftSidebar_MenuItem_SocialText_ShadowHorizontal
                        }
                        DB_LeftSidebar_MenuItem_SocialText_ShadowVertical={
                          DB_LeftSidebar_MenuItem_SocialText_ShadowVertical
                        }
                        DB_LeftSidebar_MenuItem_SocialText_ShadowBlur={
                          DB_LeftSidebar_MenuItem_SocialText_ShadowBlur
                        }
                        DB_LeftSidebar_MenuItem_SocialText_ShadowColor={
                          DB_LeftSidebar_MenuItem_SocialText_ShadowColor
                        }
                        DB_LeftSidebar_MenuItem_SocialText_TextAlign={
                          DB_LeftSidebar_MenuItem_SocialText_TextAlign
                        }
                        DB_LeftSidebar_MenuItem_SocialText_TextDecoration={
                          DB_LeftSidebar_MenuItem_SocialText_TextDecoration
                        }
                        DB_LeftSidebar_MenuItem_SocialText_TextDecorationStyle={
                          DB_LeftSidebar_MenuItem_SocialText_TextDecorationStyle
                        }
                        DB_LeftSidebar_MenuItem_SocialText_Hover_FontWeight={
                          DB_LeftSidebar_MenuItem_SocialText_Hover_FontWeight
                        }
                        DB_LeftSidebar_MenuItem_SocialText_Hover_FontColor={
                          DB_LeftSidebar_MenuItem_SocialText_Hover_FontColor
                        }
                        DB_LeftSidebar_MenuItem_SocialText_Hover_FontSize={
                          DB_LeftSidebar_MenuItem_SocialText_Hover_FontSize
                        }
                        DB_LeftSidebar_MenuItem_SocialText_Hover_BackgroundColor={
                          DB_LeftSidebar_MenuItem_SocialText_Hover_BackgroundColor
                        }
                        DB_LeftSidebar_MenuItem_SocialText_Hover_TextAlign={
                          DB_LeftSidebar_MenuItem_SocialText_Hover_TextAlign
                        }
                        DB_LeftSidebar_MenuItem_SocialText_Hover_BorderTop={
                          DB_LeftSidebar_MenuItem_SocialText_Hover_BorderTop
                        }
                        DB_LeftSidebar_MenuItem_SocialText_Hover_BorderStyleTop={
                          DB_LeftSidebar_MenuItem_SocialText_Hover_BorderStyleTop
                        }
                        DB_LeftSidebar_MenuItem_SocialText_Hover_BorderColorTop={
                          DB_LeftSidebar_MenuItem_SocialText_Hover_BorderColorTop
                        }
                        DB_LeftSidebar_MenuItem_SocialText_Hover_BorderBottom={
                          DB_LeftSidebar_MenuItem_SocialText_Hover_BorderBottom
                        }
                        DB_LeftSidebar_MenuItem_SocialText_Hover_BorderStyleBottom={
                          DB_LeftSidebar_MenuItem_SocialText_Hover_BorderStyleBottom
                        }
                        DB_LeftSidebar_MenuItem_SocialText_Hover_BorderColorBottom={
                          DB_LeftSidebar_MenuItem_SocialText_Hover_BorderColorBottom
                        }
                        DB_LeftSidebar_MenuItem_SocialText_Hover_BorderLeft={
                          DB_LeftSidebar_MenuItem_SocialText_Hover_BorderLeft
                        }
                        DB_LeftSidebar_MenuItem_SocialText_Hover_BorderStyleLeft={
                          DB_LeftSidebar_MenuItem_SocialText_Hover_BorderStyleLeft
                        }
                        DB_LeftSidebar_MenuItem_SocialText_Hover_BorderColorLeft={
                          DB_LeftSidebar_MenuItem_SocialText_Hover_BorderColorLeft
                        }
                        DB_LeftSidebar_MenuItem_SocialText_Hover_BorderRight={
                          DB_LeftSidebar_MenuItem_SocialText_Hover_BorderRight
                        }
                        DB_LeftSidebar_MenuItem_SocialText_Hover_BorderStyleRight={
                          DB_LeftSidebar_MenuItem_SocialText_Hover_BorderStyleRight
                        }
                        DB_LeftSidebar_MenuItem_SocialText_Hover_BorderColorRight={
                          DB_LeftSidebar_MenuItem_SocialText_Hover_BorderColorRight
                        }
                        DB_LeftSidebar_MenuItem_SocialText_Hover_BorderTopRightRadius={
                          DB_LeftSidebar_MenuItem_SocialText_Hover_BorderTopRightRadius
                        }
                        DB_LeftSidebar_MenuItem_SocialText_Hover_BorderTopLeftRadius={
                          DB_LeftSidebar_MenuItem_SocialText_Hover_BorderTopLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_SocialText_Hover_BorderBottomRightRadius={
                          DB_LeftSidebar_MenuItem_SocialText_Hover_BorderBottomRightRadius
                        }
                        DB_LeftSidebar_MenuItem_SocialText_Hover_BorderBottomLeftRadius={
                          DB_LeftSidebar_MenuItem_SocialText_Hover_BorderBottomLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_SocialText_marginLeftRight_Hover={
                          DB_LeftSidebar_MenuItem_SocialText_marginLeftRight_Hover
                        }
                        DB_LeftSidebar_MenuItem_SocialText_marginTopBottom_Hover={
                          DB_LeftSidebar_MenuItem_SocialText_marginTopBottom_Hover
                        }
                        DB_LeftSidebar_MenuItem_SocialText_paddingLeftRight_Hover={
                          DB_LeftSidebar_MenuItem_SocialText_paddingLeftRight_Hover
                        }
                        DB_LeftSidebar_MenuItem_SocialText_paddingTopBottom_Hover={
                          DB_LeftSidebar_MenuItem_SocialText_paddingTopBottom_Hover
                        }
                        DB_LeftSidebar_MenuItem_SocialText_letterSpacing_Hover={
                          DB_LeftSidebar_MenuItem_SocialText_letterSpacing_Hover
                        }
                        DB_LeftSidebar_MenuItem_SocialText_ShadowHorizontal_Hover={
                          DB_LeftSidebar_MenuItem_SocialText_ShadowHorizontal_Hover
                        }
                        DB_LeftSidebar_MenuItem_SocialText_ShadowVertical_Hover={
                          DB_LeftSidebar_MenuItem_SocialText_ShadowVertical_Hover
                        }
                        DB_LeftSidebar_MenuItem_SocialText_ShadowBlur_Hover={
                          DB_LeftSidebar_MenuItem_SocialText_ShadowBlur_Hover
                        }
                        DB_LeftSidebar_MenuItem_SocialText_ShadowColor_Hover={
                          DB_LeftSidebar_MenuItem_SocialText_ShadowColor_Hover
                        }
                        DB_LeftSidebar_MenuItem_SocialText_TextDecoration_Hover={
                          DB_LeftSidebar_MenuItem_SocialText_TextDecoration_Hover
                        }
                        DB_LeftSidebar_MenuItem_SocialText_TextDecorationStyle_Hover={
                          DB_LeftSidebar_MenuItem_SocialText_TextDecorationStyle_Hover
                        }
                      />
                    )}
                    {(shopBoxes.boxThreeTitel || shopBoxes.boxThreeContent) && (
                      <SideBarRightMessageToBuyer
                        boxTitel={shopBoxes.boxThreeTitel}
                        boxContent={shopBoxes.boxThreeContent}
                        SideBarLeft_ContainerSocial_Farbe_Groesse_Stil_OnMouseOverAndOut={
                          SideBarLeft_ContainerSocial_Farbe_Groesse_Stil_OnMouseOverAndOut
                        }
                        SideBarLeft_ContainerSocial_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut={
                          SideBarLeft_ContainerSocial_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
                        }
                        SideBarLeft_ContainerSocial_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut={
                          SideBarLeft_ContainerSocial_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut
                        }
                        SideBarLeft_ContainerSocial_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut={
                          SideBarLeft_ContainerSocial_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut
                        }
                        SideBarLeft_AlleSocial_Farbe_Groesse_Stil_OnMouseOverAndOut={
                          SideBarLeft_AlleSocial_Farbe_Groesse_Stil_OnMouseOverAndOut
                        }
                        SideBarLeft_AlleSocial_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut={
                          SideBarLeft_AlleSocial_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
                        }
                        SideBarLeft_AlleSocial_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut={
                          SideBarLeft_AlleSocial_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut
                        }
                        SideBarLeft_AlleSocial_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut={
                          SideBarLeft_AlleSocial_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut
                        }
                        SideBarLeft_SocialItem_Farbe_Groesse_Stil_OnMouseOverAndOut={
                          SideBarLeft_SocialItem_Farbe_Groesse_Stil_OnMouseOverAndOut
                        }
                        SideBarLeft_SocialItem_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut={
                          SideBarLeft_SocialItem_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
                        }
                        SideBarLeft_SocialItem_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut={
                          SideBarLeft_SocialItem_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut
                        }
                        SideBarLeft_SocialItem_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut={
                          SideBarLeft_SocialItem_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut
                        }
                        SideBarLeft_ContainerSocial_Farbe_Groesse_Stil_Trigger={
                          SideBarLeft_ContainerSocial_Farbe_Groesse_Stil_Trigger
                        }
                        SideBarLeft_ContainerSocial_Farbe_Groesse_Stil_Hover_Trigger={
                          SideBarLeft_ContainerSocial_Farbe_Groesse_Stil_Hover_Trigger
                        }
                        SideBarLeft_ContainerSocial_HintergrundFarbe_Radius_Stil_Trigger={
                          SideBarLeft_ContainerSocial_HintergrundFarbe_Radius_Stil_Trigger
                        }
                        SideBarLeft_ContainerSocial_HintergrundFarbe_Radius_Stil_Hover_Trigger={
                          SideBarLeft_ContainerSocial_HintergrundFarbe_Radius_Stil_Hover_Trigger
                        }
                        SideBarLeft_AlleSocial_Farbe_Groesse_Stil_Trigger={
                          SideBarLeft_AlleSocial_Farbe_Groesse_Stil_Trigger
                        }
                        SideBarLeft_AlleSocial_Farbe_Groesse_Stil_Hover_Trigger={
                          SideBarLeft_AlleSocial_Farbe_Groesse_Stil_Hover_Trigger
                        }
                        SideBarLeft_AlleSocial_HintergrundFarbe_Radius_Stil_Trigger={
                          SideBarLeft_AlleSocial_HintergrundFarbe_Radius_Stil_Trigger
                        }
                        SideBarLeft_AlleSocial_HintergrundFarbe_Radius_Stil_Hover_Trigger={
                          SideBarLeft_AlleSocial_HintergrundFarbe_Radius_Stil_Hover_Trigger
                        }
                        SideBarLeft_SocialItem_Farbe_Groesse_Stil_Trigger={
                          SideBarLeft_SocialItem_Farbe_Groesse_Stil_Trigger
                        }
                        SideBarLeft_SocialItem_Farbe_Groesse_Stil_Hover_Trigger={
                          SideBarLeft_SocialItem_Farbe_Groesse_Stil_Hover_Trigger
                        }
                        SideBarLeft_SocialItem_HintergrundFarbe_Radius_Stil_Trigger={
                          SideBarLeft_SocialItem_HintergrundFarbe_Radius_Stil_Trigger
                        }
                        SideBarLeft_SocialItem_HintergrundFarbe_Radius_Stil_Hover_Trigger={
                          SideBarLeft_SocialItem_HintergrundFarbe_Radius_Stil_Hover_Trigger
                        }
                        DB_fontFace_FontFamilyName={DB_fontFace_FontFamilyName}
                        DB_LeftSidebar_MenuContainer_Social_DDBackgroundColor={
                          DB_LeftSidebar_MenuContainer_Social_DDBackgroundColor
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDBorderTop={
                          DB_LeftSidebar_MenuContainer_Social_DDBorderTop
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDBorderStyleTop={
                          DB_LeftSidebar_MenuContainer_Social_DDBorderStyleTop
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDBorderColorTop={
                          DB_LeftSidebar_MenuContainer_Social_DDBorderColorTop
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDBorderBottom={
                          DB_LeftSidebar_MenuContainer_Social_DDBorderBottom
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDBorderStyleBottom={
                          DB_LeftSidebar_MenuContainer_Social_DDBorderStyleBottom
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDBorderColorBottom={
                          DB_LeftSidebar_MenuContainer_Social_DDBorderColorBottom
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDBorderLeft={
                          DB_LeftSidebar_MenuContainer_Social_DDBorderLeft
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDBorderStyleLeft={
                          DB_LeftSidebar_MenuContainer_Social_DDBorderStyleLeft
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDBorderColorLeft={
                          DB_LeftSidebar_MenuContainer_Social_DDBorderColorLeft
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDBorderRight={
                          DB_LeftSidebar_MenuContainer_Social_DDBorderRight
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDBorderStyleRight={
                          DB_LeftSidebar_MenuContainer_Social_DDBorderStyleRight
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDBorderColorRight={
                          DB_LeftSidebar_MenuContainer_Social_DDBorderColorRight
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDBorderTopRightRadius={
                          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDBorderTopRightRadius
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDBorderTopLeftRadius={
                          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDBorderTopLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDBorderBottomRightRadius={
                          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDBorderBottomRightRadius
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDBorderBottomLeftRadius={
                          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDBorderBottomLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDpaddingLeft={
                          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDpaddingLeft
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDpaddingRight={
                          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDpaddingRight
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDpaddingTop={
                          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDpaddingTop
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDpaddingBottom={
                          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDpaddingBottom
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDShadowHorizontal={
                          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDShadowHorizontal
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDShadowVertical={
                          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDShadowVertical
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDShadowBlur={
                          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDShadowBlur
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDShadowColor={
                          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDShadowColor
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDHover_BackgroundColor={
                          DB_LeftSidebar_MenuContainer_Social_DDHover_BackgroundColor
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDHover_BorderTop={
                          DB_LeftSidebar_MenuContainer_Social_DDHover_BorderTop
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDHover_BorderStyleTop={
                          DB_LeftSidebar_MenuContainer_Social_DDHover_BorderStyleTop
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDHover_BorderColorTop={
                          DB_LeftSidebar_MenuContainer_Social_DDHover_BorderColorTop
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDHover_BorderBottom={
                          DB_LeftSidebar_MenuContainer_Social_DDHover_BorderBottom
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDHover_BorderStyleBottom={
                          DB_LeftSidebar_MenuContainer_Social_DDHover_BorderStyleBottom
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDHover_BorderColorBottom={
                          DB_LeftSidebar_MenuContainer_Social_DDHover_BorderColorBottom
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDHover_BorderLeft={
                          DB_LeftSidebar_MenuContainer_Social_DDHover_BorderLeft
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDHover_BorderStyleLeft={
                          DB_LeftSidebar_MenuContainer_Social_DDHover_BorderStyleLeft
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDHover_BorderColorLeft={
                          DB_LeftSidebar_MenuContainer_Social_DDHover_BorderColorLeft
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDHover_BorderRight={
                          DB_LeftSidebar_MenuContainer_Social_DDHover_BorderRight
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDHover_BorderStyleRight={
                          DB_LeftSidebar_MenuContainer_Social_DDHover_BorderStyleRight
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDHover_BorderColorRight={
                          DB_LeftSidebar_MenuContainer_Social_DDHover_BorderColorRight
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_BorderTopRightRadius={
                          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_BorderTopRightRadius
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_BorderTopLeftRadius={
                          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_BorderTopLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_BorderBottomRightRadius={
                          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_BorderBottomRightRadius
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_BorderBottomLeftRadius={
                          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_BorderBottomLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_paddingLeft={
                          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_paddingLeft
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_paddingRight={
                          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_paddingRight
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_paddingTop={
                          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_paddingTop
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_paddingBottom={
                          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_paddingBottom
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_ShadowHorizontal={
                          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_ShadowHorizontal
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_ShadowVertical={
                          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_ShadowVertical
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_ShadowBlur={
                          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_ShadowBlur
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_ShadowColor={
                          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_ShadowColor
                        }
                        DB_fontFace_SocialTitelFontFamily_FontFamilyName={
                          DB_fontFace_SocialTitelFontFamily_FontFamilyName
                        }
                        DB_fontFace_SocialTitelFontFamily_FontFamilySrc={
                          DB_fontFace_SocialTitelFontFamily_FontFamilySrc
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_FontWeight={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_FontWeight
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_FontColor={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_FontColor
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_FontSize={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_FontSize
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BackgroundColor={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BackgroundColor
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_TextAlign={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_TextAlign
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderTop={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderTop
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderStyleTop={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderStyleTop
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderColorTop={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderColorTop
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderBottom={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderBottom
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderStyleBottom={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderStyleBottom
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderColorBottom={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderColorBottom
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderLeft={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderLeft
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderStyleLeft={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderStyleLeft
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderColorLeft={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderColorLeft
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderRight={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderRight
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderStyleRight={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderStyleRight
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderColorRight={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderColorRight
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderTopRightRadius={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderTopRightRadius
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderTopLeftRadius={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderTopLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderBottomRightRadius={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderBottomRightRadius
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderBottomLeftRadius={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderBottomLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_marginLeftRight={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_marginLeftRight
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_marginTopBottom={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_marginTopBottom
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_paddingLeftRight={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_paddingLeftRight
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_paddingTopBottom={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_paddingTopBottom
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_letterSpacing={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_letterSpacing
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowHorizontal={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowHorizontal
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowVertical={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowVertical
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowBlur={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowBlur
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowColor={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowColor
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_TextDecoration={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_TextDecoration
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_TextDecorationStyle={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_TextDecorationStyle
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_FontWeight={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_FontWeight
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_FontColor={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_FontColor
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_FontSize={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_FontSize
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BackgroundColor={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BackgroundColor
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_TextAlign={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_TextAlign
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderTop={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderTop
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderStyleTop={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderStyleTop
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderColorTop={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderColorTop
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderBottom={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderBottom
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderStyleBottom={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderStyleBottom
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderColorBottom={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderColorBottom
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderLeft={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderLeft
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderStyleLeft={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderStyleLeft
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderColorLeft={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderColorLeft
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderRight={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderRight
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderStyleRight={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderStyleRight
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderColorRight={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderColorRight
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderTopRightRadius={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderTopRightRadius
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderTopLeftRadius={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderTopLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderBottomRightRadius={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderBottomRightRadius
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderBottomLeftRadius={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderBottomLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_marginLeftRight_Hover={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_marginLeftRight_Hover
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_marginTopBottom_Hover={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_marginTopBottom_Hover
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_paddingLeftRight_Hover={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_paddingLeftRight_Hover
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_paddingTopBottom_Hover={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_paddingTopBottom_Hover
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_letterSpacing_Hover={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_letterSpacing_Hover
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowHorizontal_Hover={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowHorizontal_Hover
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowVertical_Hover={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowVertical_Hover
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowBlur_Hover={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowBlur_Hover
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowColor_Hover={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowColor_Hover
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_TextDecoration_Hover={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_TextDecoration_Hover
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_TextDecorationStyle_Hover={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_TextDecorationStyle_Hover
                        }
                        DB_fontFace_SocialTextFontFamily_FontFamilyName={
                          DB_fontFace_SocialTextFontFamily_FontFamilyName
                        }
                        DB_fontFace_SocialTextFontFamily_FontFamilySrc={
                          DB_fontFace_SocialTextFontFamily_FontFamilySrc
                        }
                        DB_LeftSidebar_MenuItem_SocialText_FontWeight={
                          DB_LeftSidebar_MenuItem_SocialText_FontWeight
                        }
                        DB_LeftSidebar_MenuItem_SocialText_FontColor={
                          DB_LeftSidebar_MenuItem_SocialText_FontColor
                        }
                        DB_LeftSidebar_MenuItem_SocialText_FontSize={
                          DB_LeftSidebar_MenuItem_SocialText_FontSize
                        }
                        DB_LeftSidebar_MenuItem_SocialText_BackgroundColor={
                          DB_LeftSidebar_MenuItem_SocialText_BackgroundColor
                        }
                        DB_LeftSidebar_MenuItem_SocialText_BorderTop={
                          DB_LeftSidebar_MenuItem_SocialText_BorderTop
                        }
                        DB_LeftSidebar_MenuItem_SocialText_BorderStyleTop={
                          DB_LeftSidebar_MenuItem_SocialText_BorderStyleTop
                        }
                        DB_LeftSidebar_MenuItem_SocialText_BorderColorTop={
                          DB_LeftSidebar_MenuItem_SocialText_BorderColorTop
                        }
                        DB_LeftSidebar_MenuItem_SocialText_BorderBottom={
                          DB_LeftSidebar_MenuItem_SocialText_BorderBottom
                        }
                        DB_LeftSidebar_MenuItem_SocialText_BorderStyleBottom={
                          DB_LeftSidebar_MenuItem_SocialText_BorderStyleBottom
                        }
                        DB_LeftSidebar_MenuItem_SocialText_BorderColorBottom={
                          DB_LeftSidebar_MenuItem_SocialText_BorderColorBottom
                        }
                        DB_LeftSidebar_MenuItem_SocialText_BorderLeft={
                          DB_LeftSidebar_MenuItem_SocialText_BorderLeft
                        }
                        DB_LeftSidebar_MenuItem_SocialText_BorderStyleLeft={
                          DB_LeftSidebar_MenuItem_SocialText_BorderStyleLeft
                        }
                        DB_LeftSidebar_MenuItem_SocialText_BorderColorLeft={
                          DB_LeftSidebar_MenuItem_SocialText_BorderColorLeft
                        }
                        DB_LeftSidebar_MenuItem_SocialText_BorderRight={
                          DB_LeftSidebar_MenuItem_SocialText_BorderRight
                        }
                        DB_LeftSidebar_MenuItem_SocialText_BorderStyleRight={
                          DB_LeftSidebar_MenuItem_SocialText_BorderStyleRight
                        }
                        DB_LeftSidebar_MenuItem_SocialText_BorderColorRight={
                          DB_LeftSidebar_MenuItem_SocialText_BorderColorRight
                        }
                        DB_LeftSidebar_MenuItem_SocialText_BorderTopRightRadius={
                          DB_LeftSidebar_MenuItem_SocialText_BorderTopRightRadius
                        }
                        DB_LeftSidebar_MenuItem_SocialText_BorderTopLeftRadius={
                          DB_LeftSidebar_MenuItem_SocialText_BorderTopLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_SocialText_BorderBottomRightRadius={
                          DB_LeftSidebar_MenuItem_SocialText_BorderBottomRightRadius
                        }
                        DB_LeftSidebar_MenuItem_SocialText_BorderBottomLeftRadius={
                          DB_LeftSidebar_MenuItem_SocialText_BorderBottomLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_SocialText_marginLeftRight={
                          DB_LeftSidebar_MenuItem_SocialText_marginLeftRight
                        }
                        DB_LeftSidebar_MenuItem_SocialText_marginTopBottom={
                          DB_LeftSidebar_MenuItem_SocialText_marginTopBottom
                        }
                        DB_LeftSidebar_MenuItem_SocialText_paddingLeftRight={
                          DB_LeftSidebar_MenuItem_SocialText_paddingLeftRight
                        }
                        DB_LeftSidebar_MenuItem_SocialText_paddingTopBottom={
                          DB_LeftSidebar_MenuItem_SocialText_paddingTopBottom
                        }
                        DB_LeftSidebar_MenuItem_SocialText_letterSpacing={
                          DB_LeftSidebar_MenuItem_SocialText_letterSpacing
                        }
                        DB_LeftSidebar_MenuItem_SocialText_ShadowHorizontal={
                          DB_LeftSidebar_MenuItem_SocialText_ShadowHorizontal
                        }
                        DB_LeftSidebar_MenuItem_SocialText_ShadowVertical={
                          DB_LeftSidebar_MenuItem_SocialText_ShadowVertical
                        }
                        DB_LeftSidebar_MenuItem_SocialText_ShadowBlur={
                          DB_LeftSidebar_MenuItem_SocialText_ShadowBlur
                        }
                        DB_LeftSidebar_MenuItem_SocialText_ShadowColor={
                          DB_LeftSidebar_MenuItem_SocialText_ShadowColor
                        }
                        DB_LeftSidebar_MenuItem_SocialText_TextAlign={
                          DB_LeftSidebar_MenuItem_SocialText_TextAlign
                        }
                        DB_LeftSidebar_MenuItem_SocialText_TextDecoration={
                          DB_LeftSidebar_MenuItem_SocialText_TextDecoration
                        }
                        DB_LeftSidebar_MenuItem_SocialText_TextDecorationStyle={
                          DB_LeftSidebar_MenuItem_SocialText_TextDecorationStyle
                        }
                        DB_LeftSidebar_MenuItem_SocialText_Hover_FontWeight={
                          DB_LeftSidebar_MenuItem_SocialText_Hover_FontWeight
                        }
                        DB_LeftSidebar_MenuItem_SocialText_Hover_FontColor={
                          DB_LeftSidebar_MenuItem_SocialText_Hover_FontColor
                        }
                        DB_LeftSidebar_MenuItem_SocialText_Hover_FontSize={
                          DB_LeftSidebar_MenuItem_SocialText_Hover_FontSize
                        }
                        DB_LeftSidebar_MenuItem_SocialText_Hover_BackgroundColor={
                          DB_LeftSidebar_MenuItem_SocialText_Hover_BackgroundColor
                        }
                        DB_LeftSidebar_MenuItem_SocialText_Hover_TextAlign={
                          DB_LeftSidebar_MenuItem_SocialText_Hover_TextAlign
                        }
                        DB_LeftSidebar_MenuItem_SocialText_Hover_BorderTop={
                          DB_LeftSidebar_MenuItem_SocialText_Hover_BorderTop
                        }
                        DB_LeftSidebar_MenuItem_SocialText_Hover_BorderStyleTop={
                          DB_LeftSidebar_MenuItem_SocialText_Hover_BorderStyleTop
                        }
                        DB_LeftSidebar_MenuItem_SocialText_Hover_BorderColorTop={
                          DB_LeftSidebar_MenuItem_SocialText_Hover_BorderColorTop
                        }
                        DB_LeftSidebar_MenuItem_SocialText_Hover_BorderBottom={
                          DB_LeftSidebar_MenuItem_SocialText_Hover_BorderBottom
                        }
                        DB_LeftSidebar_MenuItem_SocialText_Hover_BorderStyleBottom={
                          DB_LeftSidebar_MenuItem_SocialText_Hover_BorderStyleBottom
                        }
                        DB_LeftSidebar_MenuItem_SocialText_Hover_BorderColorBottom={
                          DB_LeftSidebar_MenuItem_SocialText_Hover_BorderColorBottom
                        }
                        DB_LeftSidebar_MenuItem_SocialText_Hover_BorderLeft={
                          DB_LeftSidebar_MenuItem_SocialText_Hover_BorderLeft
                        }
                        DB_LeftSidebar_MenuItem_SocialText_Hover_BorderStyleLeft={
                          DB_LeftSidebar_MenuItem_SocialText_Hover_BorderStyleLeft
                        }
                        DB_LeftSidebar_MenuItem_SocialText_Hover_BorderColorLeft={
                          DB_LeftSidebar_MenuItem_SocialText_Hover_BorderColorLeft
                        }
                        DB_LeftSidebar_MenuItem_SocialText_Hover_BorderRight={
                          DB_LeftSidebar_MenuItem_SocialText_Hover_BorderRight
                        }
                        DB_LeftSidebar_MenuItem_SocialText_Hover_BorderStyleRight={
                          DB_LeftSidebar_MenuItem_SocialText_Hover_BorderStyleRight
                        }
                        DB_LeftSidebar_MenuItem_SocialText_Hover_BorderColorRight={
                          DB_LeftSidebar_MenuItem_SocialText_Hover_BorderColorRight
                        }
                        DB_LeftSidebar_MenuItem_SocialText_Hover_BorderTopRightRadius={
                          DB_LeftSidebar_MenuItem_SocialText_Hover_BorderTopRightRadius
                        }
                        DB_LeftSidebar_MenuItem_SocialText_Hover_BorderTopLeftRadius={
                          DB_LeftSidebar_MenuItem_SocialText_Hover_BorderTopLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_SocialText_Hover_BorderBottomRightRadius={
                          DB_LeftSidebar_MenuItem_SocialText_Hover_BorderBottomRightRadius
                        }
                        DB_LeftSidebar_MenuItem_SocialText_Hover_BorderBottomLeftRadius={
                          DB_LeftSidebar_MenuItem_SocialText_Hover_BorderBottomLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_SocialText_marginLeftRight_Hover={
                          DB_LeftSidebar_MenuItem_SocialText_marginLeftRight_Hover
                        }
                        DB_LeftSidebar_MenuItem_SocialText_marginTopBottom_Hover={
                          DB_LeftSidebar_MenuItem_SocialText_marginTopBottom_Hover
                        }
                        DB_LeftSidebar_MenuItem_SocialText_paddingLeftRight_Hover={
                          DB_LeftSidebar_MenuItem_SocialText_paddingLeftRight_Hover
                        }
                        DB_LeftSidebar_MenuItem_SocialText_paddingTopBottom_Hover={
                          DB_LeftSidebar_MenuItem_SocialText_paddingTopBottom_Hover
                        }
                        DB_LeftSidebar_MenuItem_SocialText_letterSpacing_Hover={
                          DB_LeftSidebar_MenuItem_SocialText_letterSpacing_Hover
                        }
                        DB_LeftSidebar_MenuItem_SocialText_ShadowHorizontal_Hover={
                          DB_LeftSidebar_MenuItem_SocialText_ShadowHorizontal_Hover
                        }
                        DB_LeftSidebar_MenuItem_SocialText_ShadowVertical_Hover={
                          DB_LeftSidebar_MenuItem_SocialText_ShadowVertical_Hover
                        }
                        DB_LeftSidebar_MenuItem_SocialText_ShadowBlur_Hover={
                          DB_LeftSidebar_MenuItem_SocialText_ShadowBlur_Hover
                        }
                        DB_LeftSidebar_MenuItem_SocialText_ShadowColor_Hover={
                          DB_LeftSidebar_MenuItem_SocialText_ShadowColor_Hover
                        }
                        DB_LeftSidebar_MenuItem_SocialText_TextDecoration_Hover={
                          DB_LeftSidebar_MenuItem_SocialText_TextDecoration_Hover
                        }
                        DB_LeftSidebar_MenuItem_SocialText_TextDecorationStyle_Hover={
                          DB_LeftSidebar_MenuItem_SocialText_TextDecorationStyle_Hover
                        }
                      />
                    )}
                    {(shopBoxes.boxFourTitel || shopBoxes.boxFourContent) && (
                      <SideBarRightMessageToBuyer
                        boxTitel={shopBoxes.boxFourTitel}
                        boxContent={shopBoxes.boxFourContent}
                        SideBarLeft_ContainerSocial_Farbe_Groesse_Stil_OnMouseOverAndOut={
                          SideBarLeft_ContainerSocial_Farbe_Groesse_Stil_OnMouseOverAndOut
                        }
                        SideBarLeft_ContainerSocial_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut={
                          SideBarLeft_ContainerSocial_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
                        }
                        SideBarLeft_ContainerSocial_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut={
                          SideBarLeft_ContainerSocial_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut
                        }
                        SideBarLeft_ContainerSocial_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut={
                          SideBarLeft_ContainerSocial_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut
                        }
                        SideBarLeft_AlleSocial_Farbe_Groesse_Stil_OnMouseOverAndOut={
                          SideBarLeft_AlleSocial_Farbe_Groesse_Stil_OnMouseOverAndOut
                        }
                        SideBarLeft_AlleSocial_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut={
                          SideBarLeft_AlleSocial_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
                        }
                        SideBarLeft_AlleSocial_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut={
                          SideBarLeft_AlleSocial_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut
                        }
                        SideBarLeft_AlleSocial_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut={
                          SideBarLeft_AlleSocial_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut
                        }
                        SideBarLeft_SocialItem_Farbe_Groesse_Stil_OnMouseOverAndOut={
                          SideBarLeft_SocialItem_Farbe_Groesse_Stil_OnMouseOverAndOut
                        }
                        SideBarLeft_SocialItem_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut={
                          SideBarLeft_SocialItem_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
                        }
                        SideBarLeft_SocialItem_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut={
                          SideBarLeft_SocialItem_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut
                        }
                        SideBarLeft_SocialItem_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut={
                          SideBarLeft_SocialItem_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut
                        }
                        SideBarLeft_ContainerSocial_Farbe_Groesse_Stil_Trigger={
                          SideBarLeft_ContainerSocial_Farbe_Groesse_Stil_Trigger
                        }
                        SideBarLeft_ContainerSocial_Farbe_Groesse_Stil_Hover_Trigger={
                          SideBarLeft_ContainerSocial_Farbe_Groesse_Stil_Hover_Trigger
                        }
                        SideBarLeft_ContainerSocial_HintergrundFarbe_Radius_Stil_Trigger={
                          SideBarLeft_ContainerSocial_HintergrundFarbe_Radius_Stil_Trigger
                        }
                        SideBarLeft_ContainerSocial_HintergrundFarbe_Radius_Stil_Hover_Trigger={
                          SideBarLeft_ContainerSocial_HintergrundFarbe_Radius_Stil_Hover_Trigger
                        }
                        SideBarLeft_AlleSocial_Farbe_Groesse_Stil_Trigger={
                          SideBarLeft_AlleSocial_Farbe_Groesse_Stil_Trigger
                        }
                        SideBarLeft_AlleSocial_Farbe_Groesse_Stil_Hover_Trigger={
                          SideBarLeft_AlleSocial_Farbe_Groesse_Stil_Hover_Trigger
                        }
                        SideBarLeft_AlleSocial_HintergrundFarbe_Radius_Stil_Trigger={
                          SideBarLeft_AlleSocial_HintergrundFarbe_Radius_Stil_Trigger
                        }
                        SideBarLeft_AlleSocial_HintergrundFarbe_Radius_Stil_Hover_Trigger={
                          SideBarLeft_AlleSocial_HintergrundFarbe_Radius_Stil_Hover_Trigger
                        }
                        SideBarLeft_SocialItem_Farbe_Groesse_Stil_Trigger={
                          SideBarLeft_SocialItem_Farbe_Groesse_Stil_Trigger
                        }
                        SideBarLeft_SocialItem_Farbe_Groesse_Stil_Hover_Trigger={
                          SideBarLeft_SocialItem_Farbe_Groesse_Stil_Hover_Trigger
                        }
                        SideBarLeft_SocialItem_HintergrundFarbe_Radius_Stil_Trigger={
                          SideBarLeft_SocialItem_HintergrundFarbe_Radius_Stil_Trigger
                        }
                        SideBarLeft_SocialItem_HintergrundFarbe_Radius_Stil_Hover_Trigger={
                          SideBarLeft_SocialItem_HintergrundFarbe_Radius_Stil_Hover_Trigger
                        }
                        DB_fontFace_FontFamilyName={DB_fontFace_FontFamilyName}
                        DB_LeftSidebar_MenuContainer_Social_DDBackgroundColor={
                          DB_LeftSidebar_MenuContainer_Social_DDBackgroundColor
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDBorderTop={
                          DB_LeftSidebar_MenuContainer_Social_DDBorderTop
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDBorderStyleTop={
                          DB_LeftSidebar_MenuContainer_Social_DDBorderStyleTop
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDBorderColorTop={
                          DB_LeftSidebar_MenuContainer_Social_DDBorderColorTop
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDBorderBottom={
                          DB_LeftSidebar_MenuContainer_Social_DDBorderBottom
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDBorderStyleBottom={
                          DB_LeftSidebar_MenuContainer_Social_DDBorderStyleBottom
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDBorderColorBottom={
                          DB_LeftSidebar_MenuContainer_Social_DDBorderColorBottom
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDBorderLeft={
                          DB_LeftSidebar_MenuContainer_Social_DDBorderLeft
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDBorderStyleLeft={
                          DB_LeftSidebar_MenuContainer_Social_DDBorderStyleLeft
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDBorderColorLeft={
                          DB_LeftSidebar_MenuContainer_Social_DDBorderColorLeft
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDBorderRight={
                          DB_LeftSidebar_MenuContainer_Social_DDBorderRight
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDBorderStyleRight={
                          DB_LeftSidebar_MenuContainer_Social_DDBorderStyleRight
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDBorderColorRight={
                          DB_LeftSidebar_MenuContainer_Social_DDBorderColorRight
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDBorderTopRightRadius={
                          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDBorderTopRightRadius
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDBorderTopLeftRadius={
                          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDBorderTopLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDBorderBottomRightRadius={
                          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDBorderBottomRightRadius
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDBorderBottomLeftRadius={
                          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDBorderBottomLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDpaddingLeft={
                          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDpaddingLeft
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDpaddingRight={
                          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDpaddingRight
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDpaddingTop={
                          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDpaddingTop
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDpaddingBottom={
                          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDpaddingBottom
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDShadowHorizontal={
                          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDShadowHorizontal
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDShadowVertical={
                          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDShadowVertical
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDShadowBlur={
                          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDShadowBlur
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDShadowColor={
                          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDShadowColor
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDHover_BackgroundColor={
                          DB_LeftSidebar_MenuContainer_Social_DDHover_BackgroundColor
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDHover_BorderTop={
                          DB_LeftSidebar_MenuContainer_Social_DDHover_BorderTop
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDHover_BorderStyleTop={
                          DB_LeftSidebar_MenuContainer_Social_DDHover_BorderStyleTop
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDHover_BorderColorTop={
                          DB_LeftSidebar_MenuContainer_Social_DDHover_BorderColorTop
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDHover_BorderBottom={
                          DB_LeftSidebar_MenuContainer_Social_DDHover_BorderBottom
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDHover_BorderStyleBottom={
                          DB_LeftSidebar_MenuContainer_Social_DDHover_BorderStyleBottom
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDHover_BorderColorBottom={
                          DB_LeftSidebar_MenuContainer_Social_DDHover_BorderColorBottom
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDHover_BorderLeft={
                          DB_LeftSidebar_MenuContainer_Social_DDHover_BorderLeft
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDHover_BorderStyleLeft={
                          DB_LeftSidebar_MenuContainer_Social_DDHover_BorderStyleLeft
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDHover_BorderColorLeft={
                          DB_LeftSidebar_MenuContainer_Social_DDHover_BorderColorLeft
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDHover_BorderRight={
                          DB_LeftSidebar_MenuContainer_Social_DDHover_BorderRight
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDHover_BorderStyleRight={
                          DB_LeftSidebar_MenuContainer_Social_DDHover_BorderStyleRight
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDHover_BorderColorRight={
                          DB_LeftSidebar_MenuContainer_Social_DDHover_BorderColorRight
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_BorderTopRightRadius={
                          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_BorderTopRightRadius
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_BorderTopLeftRadius={
                          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_BorderTopLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_BorderBottomRightRadius={
                          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_BorderBottomRightRadius
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_BorderBottomLeftRadius={
                          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_BorderBottomLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_paddingLeft={
                          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_paddingLeft
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_paddingRight={
                          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_paddingRight
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_paddingTop={
                          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_paddingTop
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_paddingBottom={
                          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_paddingBottom
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_ShadowHorizontal={
                          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_ShadowHorizontal
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_ShadowVertical={
                          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_ShadowVertical
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_ShadowBlur={
                          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_ShadowBlur
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_ShadowColor={
                          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_ShadowColor
                        }
                        DB_fontFace_SocialTitelFontFamily_FontFamilyName={
                          DB_fontFace_SocialTitelFontFamily_FontFamilyName
                        }
                        DB_fontFace_SocialTitelFontFamily_FontFamilySrc={
                          DB_fontFace_SocialTitelFontFamily_FontFamilySrc
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_FontWeight={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_FontWeight
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_FontColor={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_FontColor
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_FontSize={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_FontSize
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BackgroundColor={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BackgroundColor
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_TextAlign={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_TextAlign
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderTop={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderTop
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderStyleTop={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderStyleTop
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderColorTop={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderColorTop
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderBottom={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderBottom
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderStyleBottom={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderStyleBottom
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderColorBottom={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderColorBottom
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderLeft={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderLeft
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderStyleLeft={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderStyleLeft
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderColorLeft={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderColorLeft
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderRight={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderRight
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderStyleRight={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderStyleRight
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderColorRight={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderColorRight
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderTopRightRadius={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderTopRightRadius
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderTopLeftRadius={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderTopLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderBottomRightRadius={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderBottomRightRadius
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderBottomLeftRadius={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderBottomLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_marginLeftRight={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_marginLeftRight
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_marginTopBottom={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_marginTopBottom
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_paddingLeftRight={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_paddingLeftRight
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_paddingTopBottom={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_paddingTopBottom
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_letterSpacing={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_letterSpacing
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowHorizontal={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowHorizontal
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowVertical={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowVertical
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowBlur={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowBlur
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowColor={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowColor
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_TextDecoration={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_TextDecoration
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_TextDecorationStyle={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_TextDecorationStyle
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_FontWeight={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_FontWeight
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_FontColor={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_FontColor
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_FontSize={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_FontSize
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BackgroundColor={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BackgroundColor
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_TextAlign={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_TextAlign
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderTop={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderTop
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderStyleTop={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderStyleTop
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderColorTop={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderColorTop
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderBottom={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderBottom
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderStyleBottom={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderStyleBottom
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderColorBottom={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderColorBottom
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderLeft={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderLeft
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderStyleLeft={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderStyleLeft
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderColorLeft={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderColorLeft
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderRight={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderRight
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderStyleRight={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderStyleRight
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderColorRight={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderColorRight
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderTopRightRadius={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderTopRightRadius
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderTopLeftRadius={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderTopLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderBottomRightRadius={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderBottomRightRadius
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderBottomLeftRadius={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderBottomLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_marginLeftRight_Hover={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_marginLeftRight_Hover
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_marginTopBottom_Hover={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_marginTopBottom_Hover
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_paddingLeftRight_Hover={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_paddingLeftRight_Hover
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_paddingTopBottom_Hover={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_paddingTopBottom_Hover
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_letterSpacing_Hover={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_letterSpacing_Hover
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowHorizontal_Hover={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowHorizontal_Hover
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowVertical_Hover={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowVertical_Hover
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowBlur_Hover={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowBlur_Hover
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowColor_Hover={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowColor_Hover
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_TextDecoration_Hover={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_TextDecoration_Hover
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_TextDecorationStyle_Hover={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_TextDecorationStyle_Hover
                        }
                        DB_fontFace_SocialTextFontFamily_FontFamilyName={
                          DB_fontFace_SocialTextFontFamily_FontFamilyName
                        }
                        DB_fontFace_SocialTextFontFamily_FontFamilySrc={
                          DB_fontFace_SocialTextFontFamily_FontFamilySrc
                        }
                        DB_LeftSidebar_MenuItem_SocialText_FontWeight={
                          DB_LeftSidebar_MenuItem_SocialText_FontWeight
                        }
                        DB_LeftSidebar_MenuItem_SocialText_FontColor={
                          DB_LeftSidebar_MenuItem_SocialText_FontColor
                        }
                        DB_LeftSidebar_MenuItem_SocialText_FontSize={
                          DB_LeftSidebar_MenuItem_SocialText_FontSize
                        }
                        DB_LeftSidebar_MenuItem_SocialText_BackgroundColor={
                          DB_LeftSidebar_MenuItem_SocialText_BackgroundColor
                        }
                        DB_LeftSidebar_MenuItem_SocialText_BorderTop={
                          DB_LeftSidebar_MenuItem_SocialText_BorderTop
                        }
                        DB_LeftSidebar_MenuItem_SocialText_BorderStyleTop={
                          DB_LeftSidebar_MenuItem_SocialText_BorderStyleTop
                        }
                        DB_LeftSidebar_MenuItem_SocialText_BorderColorTop={
                          DB_LeftSidebar_MenuItem_SocialText_BorderColorTop
                        }
                        DB_LeftSidebar_MenuItem_SocialText_BorderBottom={
                          DB_LeftSidebar_MenuItem_SocialText_BorderBottom
                        }
                        DB_LeftSidebar_MenuItem_SocialText_BorderStyleBottom={
                          DB_LeftSidebar_MenuItem_SocialText_BorderStyleBottom
                        }
                        DB_LeftSidebar_MenuItem_SocialText_BorderColorBottom={
                          DB_LeftSidebar_MenuItem_SocialText_BorderColorBottom
                        }
                        DB_LeftSidebar_MenuItem_SocialText_BorderLeft={
                          DB_LeftSidebar_MenuItem_SocialText_BorderLeft
                        }
                        DB_LeftSidebar_MenuItem_SocialText_BorderStyleLeft={
                          DB_LeftSidebar_MenuItem_SocialText_BorderStyleLeft
                        }
                        DB_LeftSidebar_MenuItem_SocialText_BorderColorLeft={
                          DB_LeftSidebar_MenuItem_SocialText_BorderColorLeft
                        }
                        DB_LeftSidebar_MenuItem_SocialText_BorderRight={
                          DB_LeftSidebar_MenuItem_SocialText_BorderRight
                        }
                        DB_LeftSidebar_MenuItem_SocialText_BorderStyleRight={
                          DB_LeftSidebar_MenuItem_SocialText_BorderStyleRight
                        }
                        DB_LeftSidebar_MenuItem_SocialText_BorderColorRight={
                          DB_LeftSidebar_MenuItem_SocialText_BorderColorRight
                        }
                        DB_LeftSidebar_MenuItem_SocialText_BorderTopRightRadius={
                          DB_LeftSidebar_MenuItem_SocialText_BorderTopRightRadius
                        }
                        DB_LeftSidebar_MenuItem_SocialText_BorderTopLeftRadius={
                          DB_LeftSidebar_MenuItem_SocialText_BorderTopLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_SocialText_BorderBottomRightRadius={
                          DB_LeftSidebar_MenuItem_SocialText_BorderBottomRightRadius
                        }
                        DB_LeftSidebar_MenuItem_SocialText_BorderBottomLeftRadius={
                          DB_LeftSidebar_MenuItem_SocialText_BorderBottomLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_SocialText_marginLeftRight={
                          DB_LeftSidebar_MenuItem_SocialText_marginLeftRight
                        }
                        DB_LeftSidebar_MenuItem_SocialText_marginTopBottom={
                          DB_LeftSidebar_MenuItem_SocialText_marginTopBottom
                        }
                        DB_LeftSidebar_MenuItem_SocialText_paddingLeftRight={
                          DB_LeftSidebar_MenuItem_SocialText_paddingLeftRight
                        }
                        DB_LeftSidebar_MenuItem_SocialText_paddingTopBottom={
                          DB_LeftSidebar_MenuItem_SocialText_paddingTopBottom
                        }
                        DB_LeftSidebar_MenuItem_SocialText_letterSpacing={
                          DB_LeftSidebar_MenuItem_SocialText_letterSpacing
                        }
                        DB_LeftSidebar_MenuItem_SocialText_ShadowHorizontal={
                          DB_LeftSidebar_MenuItem_SocialText_ShadowHorizontal
                        }
                        DB_LeftSidebar_MenuItem_SocialText_ShadowVertical={
                          DB_LeftSidebar_MenuItem_SocialText_ShadowVertical
                        }
                        DB_LeftSidebar_MenuItem_SocialText_ShadowBlur={
                          DB_LeftSidebar_MenuItem_SocialText_ShadowBlur
                        }
                        DB_LeftSidebar_MenuItem_SocialText_ShadowColor={
                          DB_LeftSidebar_MenuItem_SocialText_ShadowColor
                        }
                        DB_LeftSidebar_MenuItem_SocialText_TextAlign={
                          DB_LeftSidebar_MenuItem_SocialText_TextAlign
                        }
                        DB_LeftSidebar_MenuItem_SocialText_TextDecoration={
                          DB_LeftSidebar_MenuItem_SocialText_TextDecoration
                        }
                        DB_LeftSidebar_MenuItem_SocialText_TextDecorationStyle={
                          DB_LeftSidebar_MenuItem_SocialText_TextDecorationStyle
                        }
                        DB_LeftSidebar_MenuItem_SocialText_Hover_FontWeight={
                          DB_LeftSidebar_MenuItem_SocialText_Hover_FontWeight
                        }
                        DB_LeftSidebar_MenuItem_SocialText_Hover_FontColor={
                          DB_LeftSidebar_MenuItem_SocialText_Hover_FontColor
                        }
                        DB_LeftSidebar_MenuItem_SocialText_Hover_FontSize={
                          DB_LeftSidebar_MenuItem_SocialText_Hover_FontSize
                        }
                        DB_LeftSidebar_MenuItem_SocialText_Hover_BackgroundColor={
                          DB_LeftSidebar_MenuItem_SocialText_Hover_BackgroundColor
                        }
                        DB_LeftSidebar_MenuItem_SocialText_Hover_TextAlign={
                          DB_LeftSidebar_MenuItem_SocialText_Hover_TextAlign
                        }
                        DB_LeftSidebar_MenuItem_SocialText_Hover_BorderTop={
                          DB_LeftSidebar_MenuItem_SocialText_Hover_BorderTop
                        }
                        DB_LeftSidebar_MenuItem_SocialText_Hover_BorderStyleTop={
                          DB_LeftSidebar_MenuItem_SocialText_Hover_BorderStyleTop
                        }
                        DB_LeftSidebar_MenuItem_SocialText_Hover_BorderColorTop={
                          DB_LeftSidebar_MenuItem_SocialText_Hover_BorderColorTop
                        }
                        DB_LeftSidebar_MenuItem_SocialText_Hover_BorderBottom={
                          DB_LeftSidebar_MenuItem_SocialText_Hover_BorderBottom
                        }
                        DB_LeftSidebar_MenuItem_SocialText_Hover_BorderStyleBottom={
                          DB_LeftSidebar_MenuItem_SocialText_Hover_BorderStyleBottom
                        }
                        DB_LeftSidebar_MenuItem_SocialText_Hover_BorderColorBottom={
                          DB_LeftSidebar_MenuItem_SocialText_Hover_BorderColorBottom
                        }
                        DB_LeftSidebar_MenuItem_SocialText_Hover_BorderLeft={
                          DB_LeftSidebar_MenuItem_SocialText_Hover_BorderLeft
                        }
                        DB_LeftSidebar_MenuItem_SocialText_Hover_BorderStyleLeft={
                          DB_LeftSidebar_MenuItem_SocialText_Hover_BorderStyleLeft
                        }
                        DB_LeftSidebar_MenuItem_SocialText_Hover_BorderColorLeft={
                          DB_LeftSidebar_MenuItem_SocialText_Hover_BorderColorLeft
                        }
                        DB_LeftSidebar_MenuItem_SocialText_Hover_BorderRight={
                          DB_LeftSidebar_MenuItem_SocialText_Hover_BorderRight
                        }
                        DB_LeftSidebar_MenuItem_SocialText_Hover_BorderStyleRight={
                          DB_LeftSidebar_MenuItem_SocialText_Hover_BorderStyleRight
                        }
                        DB_LeftSidebar_MenuItem_SocialText_Hover_BorderColorRight={
                          DB_LeftSidebar_MenuItem_SocialText_Hover_BorderColorRight
                        }
                        DB_LeftSidebar_MenuItem_SocialText_Hover_BorderTopRightRadius={
                          DB_LeftSidebar_MenuItem_SocialText_Hover_BorderTopRightRadius
                        }
                        DB_LeftSidebar_MenuItem_SocialText_Hover_BorderTopLeftRadius={
                          DB_LeftSidebar_MenuItem_SocialText_Hover_BorderTopLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_SocialText_Hover_BorderBottomRightRadius={
                          DB_LeftSidebar_MenuItem_SocialText_Hover_BorderBottomRightRadius
                        }
                        DB_LeftSidebar_MenuItem_SocialText_Hover_BorderBottomLeftRadius={
                          DB_LeftSidebar_MenuItem_SocialText_Hover_BorderBottomLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_SocialText_marginLeftRight_Hover={
                          DB_LeftSidebar_MenuItem_SocialText_marginLeftRight_Hover
                        }
                        DB_LeftSidebar_MenuItem_SocialText_marginTopBottom_Hover={
                          DB_LeftSidebar_MenuItem_SocialText_marginTopBottom_Hover
                        }
                        DB_LeftSidebar_MenuItem_SocialText_paddingLeftRight_Hover={
                          DB_LeftSidebar_MenuItem_SocialText_paddingLeftRight_Hover
                        }
                        DB_LeftSidebar_MenuItem_SocialText_paddingTopBottom_Hover={
                          DB_LeftSidebar_MenuItem_SocialText_paddingTopBottom_Hover
                        }
                        DB_LeftSidebar_MenuItem_SocialText_letterSpacing_Hover={
                          DB_LeftSidebar_MenuItem_SocialText_letterSpacing_Hover
                        }
                        DB_LeftSidebar_MenuItem_SocialText_ShadowHorizontal_Hover={
                          DB_LeftSidebar_MenuItem_SocialText_ShadowHorizontal_Hover
                        }
                        DB_LeftSidebar_MenuItem_SocialText_ShadowVertical_Hover={
                          DB_LeftSidebar_MenuItem_SocialText_ShadowVertical_Hover
                        }
                        DB_LeftSidebar_MenuItem_SocialText_ShadowBlur_Hover={
                          DB_LeftSidebar_MenuItem_SocialText_ShadowBlur_Hover
                        }
                        DB_LeftSidebar_MenuItem_SocialText_ShadowColor_Hover={
                          DB_LeftSidebar_MenuItem_SocialText_ShadowColor_Hover
                        }
                        DB_LeftSidebar_MenuItem_SocialText_TextDecoration_Hover={
                          DB_LeftSidebar_MenuItem_SocialText_TextDecoration_Hover
                        }
                        DB_LeftSidebar_MenuItem_SocialText_TextDecorationStyle_Hover={
                          DB_LeftSidebar_MenuItem_SocialText_TextDecorationStyle_Hover
                        }
                      />
                    )}
                    <SideBarRightSocialIcons
                      SideBarLeft_ContainerSocial_Farbe_Groesse_Stil_OnMouseOverAndOut={
                        SideBarLeft_ContainerSocial_Farbe_Groesse_Stil_OnMouseOverAndOut
                      }
                      SideBarLeft_ContainerSocial_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut={
                        SideBarLeft_ContainerSocial_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
                      }
                      SideBarLeft_ContainerSocial_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut={
                        SideBarLeft_ContainerSocial_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut
                      }
                      SideBarLeft_ContainerSocial_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut={
                        SideBarLeft_ContainerSocial_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut
                      }
                      SideBarLeft_AlleSocial_Farbe_Groesse_Stil_OnMouseOverAndOut={
                        SideBarLeft_AlleSocial_Farbe_Groesse_Stil_OnMouseOverAndOut
                      }
                      SideBarLeft_AlleSocial_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut={
                        SideBarLeft_AlleSocial_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
                      }
                      SideBarLeft_AlleSocial_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut={
                        SideBarLeft_AlleSocial_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut
                      }
                      SideBarLeft_AlleSocial_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut={
                        SideBarLeft_AlleSocial_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut
                      }
                      SideBarLeft_SocialItem_Farbe_Groesse_Stil_OnMouseOverAndOut={
                        SideBarLeft_SocialItem_Farbe_Groesse_Stil_OnMouseOverAndOut
                      }
                      SideBarLeft_SocialItem_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut={
                        SideBarLeft_SocialItem_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
                      }
                      SideBarLeft_SocialItem_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut={
                        SideBarLeft_SocialItem_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut
                      }
                      SideBarLeft_SocialItem_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut={
                        SideBarLeft_SocialItem_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut
                      }
                      SideBarLeft_ContainerSocial_Farbe_Groesse_Stil_Trigger={
                        SideBarLeft_ContainerSocial_Farbe_Groesse_Stil_Trigger
                      }
                      SideBarLeft_ContainerSocial_Farbe_Groesse_Stil_Hover_Trigger={
                        SideBarLeft_ContainerSocial_Farbe_Groesse_Stil_Hover_Trigger
                      }
                      SideBarLeft_ContainerSocial_HintergrundFarbe_Radius_Stil_Trigger={
                        SideBarLeft_ContainerSocial_HintergrundFarbe_Radius_Stil_Trigger
                      }
                      SideBarLeft_ContainerSocial_HintergrundFarbe_Radius_Stil_Hover_Trigger={
                        SideBarLeft_ContainerSocial_HintergrundFarbe_Radius_Stil_Hover_Trigger
                      }
                      SideBarLeft_AlleSocial_Farbe_Groesse_Stil_Trigger={
                        SideBarLeft_AlleSocial_Farbe_Groesse_Stil_Trigger
                      }
                      SideBarLeft_AlleSocial_Farbe_Groesse_Stil_Hover_Trigger={
                        SideBarLeft_AlleSocial_Farbe_Groesse_Stil_Hover_Trigger
                      }
                      SideBarLeft_AlleSocial_HintergrundFarbe_Radius_Stil_Trigger={
                        SideBarLeft_AlleSocial_HintergrundFarbe_Radius_Stil_Trigger
                      }
                      SideBarLeft_AlleSocial_HintergrundFarbe_Radius_Stil_Hover_Trigger={
                        SideBarLeft_AlleSocial_HintergrundFarbe_Radius_Stil_Hover_Trigger
                      }
                      SideBarLeft_SocialItem_Farbe_Groesse_Stil_Trigger={
                        SideBarLeft_SocialItem_Farbe_Groesse_Stil_Trigger
                      }
                      SideBarLeft_SocialItem_Farbe_Groesse_Stil_Hover_Trigger={
                        SideBarLeft_SocialItem_Farbe_Groesse_Stil_Hover_Trigger
                      }
                      SideBarLeft_SocialItem_HintergrundFarbe_Radius_Stil_Trigger={
                        SideBarLeft_SocialItem_HintergrundFarbe_Radius_Stil_Trigger
                      }
                      SideBarLeft_SocialItem_HintergrundFarbe_Radius_Stil_Hover_Trigger={
                        SideBarLeft_SocialItem_HintergrundFarbe_Radius_Stil_Hover_Trigger
                      }
                      DB_fontFace_FontFamilyName={DB_fontFace_FontFamilyName}
                      DB_LeftSidebar_MenuContainer_Social_DDBackgroundColor={
                        DB_LeftSidebar_MenuContainer_Social_DDBackgroundColor
                      }
                      DB_LeftSidebar_MenuContainer_Social_DDBorderTop={
                        DB_LeftSidebar_MenuContainer_Social_DDBorderTop
                      }
                      DB_LeftSidebar_MenuContainer_Social_DDBorderStyleTop={
                        DB_LeftSidebar_MenuContainer_Social_DDBorderStyleTop
                      }
                      DB_LeftSidebar_MenuContainer_Social_DDBorderColorTop={
                        DB_LeftSidebar_MenuContainer_Social_DDBorderColorTop
                      }
                      DB_LeftSidebar_MenuContainer_Social_DDBorderBottom={
                        DB_LeftSidebar_MenuContainer_Social_DDBorderBottom
                      }
                      DB_LeftSidebar_MenuContainer_Social_DDBorderStyleBottom={
                        DB_LeftSidebar_MenuContainer_Social_DDBorderStyleBottom
                      }
                      DB_LeftSidebar_MenuContainer_Social_DDBorderColorBottom={
                        DB_LeftSidebar_MenuContainer_Social_DDBorderColorBottom
                      }
                      DB_LeftSidebar_MenuContainer_Social_DDBorderLeft={
                        DB_LeftSidebar_MenuContainer_Social_DDBorderLeft
                      }
                      DB_LeftSidebar_MenuContainer_Social_DDBorderStyleLeft={
                        DB_LeftSidebar_MenuContainer_Social_DDBorderStyleLeft
                      }
                      DB_LeftSidebar_MenuContainer_Social_DDBorderColorLeft={
                        DB_LeftSidebar_MenuContainer_Social_DDBorderColorLeft
                      }
                      DB_LeftSidebar_MenuContainer_Social_DDBorderRight={
                        DB_LeftSidebar_MenuContainer_Social_DDBorderRight
                      }
                      DB_LeftSidebar_MenuContainer_Social_DDBorderStyleRight={
                        DB_LeftSidebar_MenuContainer_Social_DDBorderStyleRight
                      }
                      DB_LeftSidebar_MenuContainer_Social_DDBorderColorRight={
                        DB_LeftSidebar_MenuContainer_Social_DDBorderColorRight
                      }
                      DB_LeftSidebar_MenuItem_MenuContainer_Social_DDBorderTopRightRadius={
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDBorderTopRightRadius
                      }
                      DB_LeftSidebar_MenuItem_MenuContainer_Social_DDBorderTopLeftRadius={
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDBorderTopLeftRadius
                      }
                      DB_LeftSidebar_MenuItem_MenuContainer_Social_DDBorderBottomRightRadius={
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDBorderBottomRightRadius
                      }
                      DB_LeftSidebar_MenuItem_MenuContainer_Social_DDBorderBottomLeftRadius={
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDBorderBottomLeftRadius
                      }
                      DB_LeftSidebar_MenuItem_MenuContainer_Social_DDpaddingLeft={
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDpaddingLeft
                      }
                      DB_LeftSidebar_MenuItem_MenuContainer_Social_DDpaddingRight={
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDpaddingRight
                      }
                      DB_LeftSidebar_MenuItem_MenuContainer_Social_DDpaddingTop={
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDpaddingTop
                      }
                      DB_LeftSidebar_MenuItem_MenuContainer_Social_DDpaddingBottom={
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDpaddingBottom
                      }
                      DB_LeftSidebar_MenuItem_MenuContainer_Social_DDShadowHorizontal={
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDShadowHorizontal
                      }
                      DB_LeftSidebar_MenuItem_MenuContainer_Social_DDShadowVertical={
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDShadowVertical
                      }
                      DB_LeftSidebar_MenuItem_MenuContainer_Social_DDShadowBlur={
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDShadowBlur
                      }
                      DB_LeftSidebar_MenuItem_MenuContainer_Social_DDShadowColor={
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDShadowColor
                      }
                      DB_LeftSidebar_MenuContainer_Social_DDHover_BackgroundColor={
                        DB_LeftSidebar_MenuContainer_Social_DDHover_BackgroundColor
                      }
                      DB_LeftSidebar_MenuContainer_Social_DDHover_BorderTop={
                        DB_LeftSidebar_MenuContainer_Social_DDHover_BorderTop
                      }
                      DB_LeftSidebar_MenuContainer_Social_DDHover_BorderStyleTop={
                        DB_LeftSidebar_MenuContainer_Social_DDHover_BorderStyleTop
                      }
                      DB_LeftSidebar_MenuContainer_Social_DDHover_BorderColorTop={
                        DB_LeftSidebar_MenuContainer_Social_DDHover_BorderColorTop
                      }
                      DB_LeftSidebar_MenuContainer_Social_DDHover_BorderBottom={
                        DB_LeftSidebar_MenuContainer_Social_DDHover_BorderBottom
                      }
                      DB_LeftSidebar_MenuContainer_Social_DDHover_BorderStyleBottom={
                        DB_LeftSidebar_MenuContainer_Social_DDHover_BorderStyleBottom
                      }
                      DB_LeftSidebar_MenuContainer_Social_DDHover_BorderColorBottom={
                        DB_LeftSidebar_MenuContainer_Social_DDHover_BorderColorBottom
                      }
                      DB_LeftSidebar_MenuContainer_Social_DDHover_BorderLeft={
                        DB_LeftSidebar_MenuContainer_Social_DDHover_BorderLeft
                      }
                      DB_LeftSidebar_MenuContainer_Social_DDHover_BorderStyleLeft={
                        DB_LeftSidebar_MenuContainer_Social_DDHover_BorderStyleLeft
                      }
                      DB_LeftSidebar_MenuContainer_Social_DDHover_BorderColorLeft={
                        DB_LeftSidebar_MenuContainer_Social_DDHover_BorderColorLeft
                      }
                      DB_LeftSidebar_MenuContainer_Social_DDHover_BorderRight={
                        DB_LeftSidebar_MenuContainer_Social_DDHover_BorderRight
                      }
                      DB_LeftSidebar_MenuContainer_Social_DDHover_BorderStyleRight={
                        DB_LeftSidebar_MenuContainer_Social_DDHover_BorderStyleRight
                      }
                      DB_LeftSidebar_MenuContainer_Social_DDHover_BorderColorRight={
                        DB_LeftSidebar_MenuContainer_Social_DDHover_BorderColorRight
                      }
                      DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_BorderTopRightRadius={
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_BorderTopRightRadius
                      }
                      DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_BorderTopLeftRadius={
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_BorderTopLeftRadius
                      }
                      DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_BorderBottomRightRadius={
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_BorderBottomRightRadius
                      }
                      DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_BorderBottomLeftRadius={
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_BorderBottomLeftRadius
                      }
                      DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_paddingLeft={
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_paddingLeft
                      }
                      DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_paddingRight={
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_paddingRight
                      }
                      DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_paddingTop={
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_paddingTop
                      }
                      DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_paddingBottom={
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_paddingBottom
                      }
                      DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_ShadowHorizontal={
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_ShadowHorizontal
                      }
                      DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_ShadowVertical={
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_ShadowVertical
                      }
                      DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_ShadowBlur={
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_ShadowBlur
                      }
                      DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_ShadowColor={
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_ShadowColor
                      }
                      DB_fontFace_SocialTitelFontFamily_FontFamilyName={
                        DB_fontFace_SocialTitelFontFamily_FontFamilyName
                      }
                      DB_fontFace_SocialTitelFontFamily_FontFamilySrc={
                        DB_fontFace_SocialTitelFontFamily_FontFamilySrc
                      }
                      DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_FontWeight={
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_FontWeight
                      }
                      DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_FontColor={
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_FontColor
                      }
                      DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_FontSize={
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_FontSize
                      }
                      DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BackgroundColor={
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BackgroundColor
                      }
                      DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_TextAlign={
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_TextAlign
                      }
                      DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderTop={
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderTop
                      }
                      DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderStyleTop={
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderStyleTop
                      }
                      DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderColorTop={
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderColorTop
                      }
                      DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderBottom={
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderBottom
                      }
                      DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderStyleBottom={
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderStyleBottom
                      }
                      DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderColorBottom={
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderColorBottom
                      }
                      DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderLeft={
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderLeft
                      }
                      DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderStyleLeft={
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderStyleLeft
                      }
                      DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderColorLeft={
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderColorLeft
                      }
                      DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderRight={
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderRight
                      }
                      DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderStyleRight={
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderStyleRight
                      }
                      DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderColorRight={
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderColorRight
                      }
                      DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderTopRightRadius={
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderTopRightRadius
                      }
                      DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderTopLeftRadius={
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderTopLeftRadius
                      }
                      DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderBottomRightRadius={
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderBottomRightRadius
                      }
                      DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderBottomLeftRadius={
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderBottomLeftRadius
                      }
                      DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_marginLeftRight={
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_marginLeftRight
                      }
                      DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_marginTopBottom={
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_marginTopBottom
                      }
                      DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_paddingLeftRight={
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_paddingLeftRight
                      }
                      DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_paddingTopBottom={
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_paddingTopBottom
                      }
                      DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_letterSpacing={
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_letterSpacing
                      }
                      DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowHorizontal={
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowHorizontal
                      }
                      DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowVertical={
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowVertical
                      }
                      DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowBlur={
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowBlur
                      }
                      DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowColor={
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowColor
                      }
                      DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_TextDecoration={
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_TextDecoration
                      }
                      DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_TextDecorationStyle={
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_TextDecorationStyle
                      }
                      DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_FontWeight={
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_FontWeight
                      }
                      DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_FontColor={
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_FontColor
                      }
                      DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_FontSize={
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_FontSize
                      }
                      DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BackgroundColor={
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BackgroundColor
                      }
                      DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_TextAlign={
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_TextAlign
                      }
                      DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderTop={
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderTop
                      }
                      DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderStyleTop={
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderStyleTop
                      }
                      DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderColorTop={
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderColorTop
                      }
                      DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderBottom={
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderBottom
                      }
                      DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderStyleBottom={
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderStyleBottom
                      }
                      DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderColorBottom={
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderColorBottom
                      }
                      DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderLeft={
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderLeft
                      }
                      DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderStyleLeft={
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderStyleLeft
                      }
                      DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderColorLeft={
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderColorLeft
                      }
                      DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderRight={
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderRight
                      }
                      DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderStyleRight={
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderStyleRight
                      }
                      DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderColorRight={
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderColorRight
                      }
                      DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderTopRightRadius={
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderTopRightRadius
                      }
                      DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderTopLeftRadius={
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderTopLeftRadius
                      }
                      DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderBottomRightRadius={
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderBottomRightRadius
                      }
                      DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderBottomLeftRadius={
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderBottomLeftRadius
                      }
                      DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_marginLeftRight_Hover={
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_marginLeftRight_Hover
                      }
                      DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_marginTopBottom_Hover={
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_marginTopBottom_Hover
                      }
                      DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_paddingLeftRight_Hover={
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_paddingLeftRight_Hover
                      }
                      DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_paddingTopBottom_Hover={
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_paddingTopBottom_Hover
                      }
                      DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_letterSpacing_Hover={
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_letterSpacing_Hover
                      }
                      DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowHorizontal_Hover={
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowHorizontal_Hover
                      }
                      DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowVertical_Hover={
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowVertical_Hover
                      }
                      DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowBlur_Hover={
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowBlur_Hover
                      }
                      DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowColor_Hover={
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowColor_Hover
                      }
                      DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_TextDecoration_Hover={
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_TextDecoration_Hover
                      }
                      DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_TextDecorationStyle_Hover={
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_TextDecorationStyle_Hover
                      }
                      DB_fontFace_SocialTextFontFamily_FontFamilyName={
                        DB_fontFace_SocialTextFontFamily_FontFamilyName
                      }
                      DB_fontFace_SocialTextFontFamily_FontFamilySrc={
                        DB_fontFace_SocialTextFontFamily_FontFamilySrc
                      }
                      DB_LeftSidebar_MenuItem_SocialText_FontWeight={
                        DB_LeftSidebar_MenuItem_SocialText_FontWeight
                      }
                      DB_LeftSidebar_MenuItem_SocialText_FontColor={
                        DB_LeftSidebar_MenuItem_SocialText_FontColor
                      }
                      DB_LeftSidebar_MenuItem_SocialText_FontSize={
                        DB_LeftSidebar_MenuItem_SocialText_FontSize
                      }
                      DB_LeftSidebar_MenuItem_SocialText_BackgroundColor={
                        DB_LeftSidebar_MenuItem_SocialText_BackgroundColor
                      }
                      DB_LeftSidebar_MenuItem_SocialText_BorderTop={
                        DB_LeftSidebar_MenuItem_SocialText_BorderTop
                      }
                      DB_LeftSidebar_MenuItem_SocialText_BorderStyleTop={
                        DB_LeftSidebar_MenuItem_SocialText_BorderStyleTop
                      }
                      DB_LeftSidebar_MenuItem_SocialText_BorderColorTop={
                        DB_LeftSidebar_MenuItem_SocialText_BorderColorTop
                      }
                      DB_LeftSidebar_MenuItem_SocialText_BorderBottom={
                        DB_LeftSidebar_MenuItem_SocialText_BorderBottom
                      }
                      DB_LeftSidebar_MenuItem_SocialText_BorderStyleBottom={
                        DB_LeftSidebar_MenuItem_SocialText_BorderStyleBottom
                      }
                      DB_LeftSidebar_MenuItem_SocialText_BorderColorBottom={
                        DB_LeftSidebar_MenuItem_SocialText_BorderColorBottom
                      }
                      DB_LeftSidebar_MenuItem_SocialText_BorderLeft={
                        DB_LeftSidebar_MenuItem_SocialText_BorderLeft
                      }
                      DB_LeftSidebar_MenuItem_SocialText_BorderStyleLeft={
                        DB_LeftSidebar_MenuItem_SocialText_BorderStyleLeft
                      }
                      DB_LeftSidebar_MenuItem_SocialText_BorderColorLeft={
                        DB_LeftSidebar_MenuItem_SocialText_BorderColorLeft
                      }
                      DB_LeftSidebar_MenuItem_SocialText_BorderRight={
                        DB_LeftSidebar_MenuItem_SocialText_BorderRight
                      }
                      DB_LeftSidebar_MenuItem_SocialText_BorderStyleRight={
                        DB_LeftSidebar_MenuItem_SocialText_BorderStyleRight
                      }
                      DB_LeftSidebar_MenuItem_SocialText_BorderColorRight={
                        DB_LeftSidebar_MenuItem_SocialText_BorderColorRight
                      }
                      DB_LeftSidebar_MenuItem_SocialText_BorderTopRightRadius={
                        DB_LeftSidebar_MenuItem_SocialText_BorderTopRightRadius
                      }
                      DB_LeftSidebar_MenuItem_SocialText_BorderTopLeftRadius={
                        DB_LeftSidebar_MenuItem_SocialText_BorderTopLeftRadius
                      }
                      DB_LeftSidebar_MenuItem_SocialText_BorderBottomRightRadius={
                        DB_LeftSidebar_MenuItem_SocialText_BorderBottomRightRadius
                      }
                      DB_LeftSidebar_MenuItem_SocialText_BorderBottomLeftRadius={
                        DB_LeftSidebar_MenuItem_SocialText_BorderBottomLeftRadius
                      }
                      DB_LeftSidebar_MenuItem_SocialText_marginLeftRight={
                        DB_LeftSidebar_MenuItem_SocialText_marginLeftRight
                      }
                      DB_LeftSidebar_MenuItem_SocialText_marginTopBottom={
                        DB_LeftSidebar_MenuItem_SocialText_marginTopBottom
                      }
                      DB_LeftSidebar_MenuItem_SocialText_paddingLeftRight={
                        DB_LeftSidebar_MenuItem_SocialText_paddingLeftRight
                      }
                      DB_LeftSidebar_MenuItem_SocialText_paddingTopBottom={
                        DB_LeftSidebar_MenuItem_SocialText_paddingTopBottom
                      }
                      DB_LeftSidebar_MenuItem_SocialText_letterSpacing={
                        DB_LeftSidebar_MenuItem_SocialText_letterSpacing
                      }
                      DB_LeftSidebar_MenuItem_SocialText_ShadowHorizontal={
                        DB_LeftSidebar_MenuItem_SocialText_ShadowHorizontal
                      }
                      DB_LeftSidebar_MenuItem_SocialText_ShadowVertical={
                        DB_LeftSidebar_MenuItem_SocialText_ShadowVertical
                      }
                      DB_LeftSidebar_MenuItem_SocialText_ShadowBlur={
                        DB_LeftSidebar_MenuItem_SocialText_ShadowBlur
                      }
                      DB_LeftSidebar_MenuItem_SocialText_ShadowColor={
                        DB_LeftSidebar_MenuItem_SocialText_ShadowColor
                      }
                      DB_LeftSidebar_MenuItem_SocialText_TextAlign={
                        DB_LeftSidebar_MenuItem_SocialText_TextAlign
                      }
                      DB_LeftSidebar_MenuItem_SocialText_TextDecoration={
                        DB_LeftSidebar_MenuItem_SocialText_TextDecoration
                      }
                      DB_LeftSidebar_MenuItem_SocialText_TextDecorationStyle={
                        DB_LeftSidebar_MenuItem_SocialText_TextDecorationStyle
                      }
                      DB_LeftSidebar_MenuItem_SocialText_Hover_FontWeight={
                        DB_LeftSidebar_MenuItem_SocialText_Hover_FontWeight
                      }
                      DB_LeftSidebar_MenuItem_SocialText_Hover_FontColor={
                        DB_LeftSidebar_MenuItem_SocialText_Hover_FontColor
                      }
                      DB_LeftSidebar_MenuItem_SocialText_Hover_FontSize={
                        DB_LeftSidebar_MenuItem_SocialText_Hover_FontSize
                      }
                      DB_LeftSidebar_MenuItem_SocialText_Hover_BackgroundColor={
                        DB_LeftSidebar_MenuItem_SocialText_Hover_BackgroundColor
                      }
                      DB_LeftSidebar_MenuItem_SocialText_Hover_TextAlign={
                        DB_LeftSidebar_MenuItem_SocialText_Hover_TextAlign
                      }
                      DB_LeftSidebar_MenuItem_SocialText_Hover_BorderTop={
                        DB_LeftSidebar_MenuItem_SocialText_Hover_BorderTop
                      }
                      DB_LeftSidebar_MenuItem_SocialText_Hover_BorderStyleTop={
                        DB_LeftSidebar_MenuItem_SocialText_Hover_BorderStyleTop
                      }
                      DB_LeftSidebar_MenuItem_SocialText_Hover_BorderColorTop={
                        DB_LeftSidebar_MenuItem_SocialText_Hover_BorderColorTop
                      }
                      DB_LeftSidebar_MenuItem_SocialText_Hover_BorderBottom={
                        DB_LeftSidebar_MenuItem_SocialText_Hover_BorderBottom
                      }
                      DB_LeftSidebar_MenuItem_SocialText_Hover_BorderStyleBottom={
                        DB_LeftSidebar_MenuItem_SocialText_Hover_BorderStyleBottom
                      }
                      DB_LeftSidebar_MenuItem_SocialText_Hover_BorderColorBottom={
                        DB_LeftSidebar_MenuItem_SocialText_Hover_BorderColorBottom
                      }
                      DB_LeftSidebar_MenuItem_SocialText_Hover_BorderLeft={
                        DB_LeftSidebar_MenuItem_SocialText_Hover_BorderLeft
                      }
                      DB_LeftSidebar_MenuItem_SocialText_Hover_BorderStyleLeft={
                        DB_LeftSidebar_MenuItem_SocialText_Hover_BorderStyleLeft
                      }
                      DB_LeftSidebar_MenuItem_SocialText_Hover_BorderColorLeft={
                        DB_LeftSidebar_MenuItem_SocialText_Hover_BorderColorLeft
                      }
                      DB_LeftSidebar_MenuItem_SocialText_Hover_BorderRight={
                        DB_LeftSidebar_MenuItem_SocialText_Hover_BorderRight
                      }
                      DB_LeftSidebar_MenuItem_SocialText_Hover_BorderStyleRight={
                        DB_LeftSidebar_MenuItem_SocialText_Hover_BorderStyleRight
                      }
                      DB_LeftSidebar_MenuItem_SocialText_Hover_BorderColorRight={
                        DB_LeftSidebar_MenuItem_SocialText_Hover_BorderColorRight
                      }
                      DB_LeftSidebar_MenuItem_SocialText_Hover_BorderTopRightRadius={
                        DB_LeftSidebar_MenuItem_SocialText_Hover_BorderTopRightRadius
                      }
                      DB_LeftSidebar_MenuItem_SocialText_Hover_BorderTopLeftRadius={
                        DB_LeftSidebar_MenuItem_SocialText_Hover_BorderTopLeftRadius
                      }
                      DB_LeftSidebar_MenuItem_SocialText_Hover_BorderBottomRightRadius={
                        DB_LeftSidebar_MenuItem_SocialText_Hover_BorderBottomRightRadius
                      }
                      DB_LeftSidebar_MenuItem_SocialText_Hover_BorderBottomLeftRadius={
                        DB_LeftSidebar_MenuItem_SocialText_Hover_BorderBottomLeftRadius
                      }
                      DB_LeftSidebar_MenuItem_SocialText_marginLeftRight_Hover={
                        DB_LeftSidebar_MenuItem_SocialText_marginLeftRight_Hover
                      }
                      DB_LeftSidebar_MenuItem_SocialText_marginTopBottom_Hover={
                        DB_LeftSidebar_MenuItem_SocialText_marginTopBottom_Hover
                      }
                      DB_LeftSidebar_MenuItem_SocialText_paddingLeftRight_Hover={
                        DB_LeftSidebar_MenuItem_SocialText_paddingLeftRight_Hover
                      }
                      DB_LeftSidebar_MenuItem_SocialText_paddingTopBottom_Hover={
                        DB_LeftSidebar_MenuItem_SocialText_paddingTopBottom_Hover
                      }
                      DB_LeftSidebar_MenuItem_SocialText_letterSpacing_Hover={
                        DB_LeftSidebar_MenuItem_SocialText_letterSpacing_Hover
                      }
                      DB_LeftSidebar_MenuItem_SocialText_ShadowHorizontal_Hover={
                        DB_LeftSidebar_MenuItem_SocialText_ShadowHorizontal_Hover
                      }
                      DB_LeftSidebar_MenuItem_SocialText_ShadowVertical_Hover={
                        DB_LeftSidebar_MenuItem_SocialText_ShadowVertical_Hover
                      }
                      DB_LeftSidebar_MenuItem_SocialText_ShadowBlur_Hover={
                        DB_LeftSidebar_MenuItem_SocialText_ShadowBlur_Hover
                      }
                      DB_LeftSidebar_MenuItem_SocialText_ShadowColor_Hover={
                        DB_LeftSidebar_MenuItem_SocialText_ShadowColor_Hover
                      }
                      DB_LeftSidebar_MenuItem_SocialText_TextDecoration_Hover={
                        DB_LeftSidebar_MenuItem_SocialText_TextDecoration_Hover
                      }
                      DB_LeftSidebar_MenuItem_SocialText_TextDecorationStyle_Hover={
                        DB_LeftSidebar_MenuItem_SocialText_TextDecorationStyle_Hover
                      }
                    />

                    {/*  <SideBarRightCookieMessage 
                    SideBarLeft_ContainerSocial_Farbe_Groesse_Stil_OnMouseOverAndOut={
                          SideBarLeft_ContainerSocial_Farbe_Groesse_Stil_OnMouseOverAndOut
                        }
                        SideBarLeft_ContainerSocial_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut={
                          SideBarLeft_ContainerSocial_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
                        }
                        SideBarLeft_ContainerSocial_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut={
                          SideBarLeft_ContainerSocial_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut
                        }
                        SideBarLeft_ContainerSocial_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut={
                          SideBarLeft_ContainerSocial_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut
                        }
                        SideBarLeft_AlleSocial_Farbe_Groesse_Stil_OnMouseOverAndOut={
                          SideBarLeft_AlleSocial_Farbe_Groesse_Stil_OnMouseOverAndOut
                        }
                        SideBarLeft_AlleSocial_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut={
                          SideBarLeft_AlleSocial_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
                        }
                        SideBarLeft_AlleSocial_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut={
                          SideBarLeft_AlleSocial_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut
                        }
                        SideBarLeft_AlleSocial_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut={
                          SideBarLeft_AlleSocial_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut
                        }
                        SideBarLeft_SocialItem_Farbe_Groesse_Stil_OnMouseOverAndOut={
                          SideBarLeft_SocialItem_Farbe_Groesse_Stil_OnMouseOverAndOut
                        }
                        SideBarLeft_SocialItem_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut={
                          SideBarLeft_SocialItem_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
                        }
                        SideBarLeft_SocialItem_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut={
                          SideBarLeft_SocialItem_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut
                        }
                        SideBarLeft_SocialItem_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut={
                          SideBarLeft_SocialItem_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut
                        }
                        SideBarLeft_ContainerSocial_Farbe_Groesse_Stil_Trigger={
                          SideBarLeft_ContainerSocial_Farbe_Groesse_Stil_Trigger
                        }
                        SideBarLeft_ContainerSocial_Farbe_Groesse_Stil_Hover_Trigger={
                          SideBarLeft_ContainerSocial_Farbe_Groesse_Stil_Hover_Trigger
                        }
                        SideBarLeft_ContainerSocial_HintergrundFarbe_Radius_Stil_Trigger={
                          SideBarLeft_ContainerSocial_HintergrundFarbe_Radius_Stil_Trigger
                        }
                        SideBarLeft_ContainerSocial_HintergrundFarbe_Radius_Stil_Hover_Trigger={
                          SideBarLeft_ContainerSocial_HintergrundFarbe_Radius_Stil_Hover_Trigger
                        }
                        SideBarLeft_AlleSocial_Farbe_Groesse_Stil_Trigger={
                          SideBarLeft_AlleSocial_Farbe_Groesse_Stil_Trigger
                        }
                        SideBarLeft_AlleSocial_Farbe_Groesse_Stil_Hover_Trigger={
                          SideBarLeft_AlleSocial_Farbe_Groesse_Stil_Hover_Trigger
                        }
                        SideBarLeft_AlleSocial_HintergrundFarbe_Radius_Stil_Trigger={
                          SideBarLeft_AlleSocial_HintergrundFarbe_Radius_Stil_Trigger
                        }
                        SideBarLeft_AlleSocial_HintergrundFarbe_Radius_Stil_Hover_Trigger={
                          SideBarLeft_AlleSocial_HintergrundFarbe_Radius_Stil_Hover_Trigger
                        }
                        SideBarLeft_SocialItem_Farbe_Groesse_Stil_Trigger={
                          SideBarLeft_SocialItem_Farbe_Groesse_Stil_Trigger
                        }
                        SideBarLeft_SocialItem_Farbe_Groesse_Stil_Hover_Trigger={
                          SideBarLeft_SocialItem_Farbe_Groesse_Stil_Hover_Trigger
                        }
                        SideBarLeft_SocialItem_HintergrundFarbe_Radius_Stil_Trigger={
                          SideBarLeft_SocialItem_HintergrundFarbe_Radius_Stil_Trigger
                        }
                        SideBarLeft_SocialItem_HintergrundFarbe_Radius_Stil_Hover_Trigger={
                          SideBarLeft_SocialItem_HintergrundFarbe_Radius_Stil_Hover_Trigger
                        }
                        DB_fontFace_FontFamilyName={DB_fontFace_FontFamilyName}
                        DB_LeftSidebar_MenuContainer_Social_DDBackgroundColor={
                          DB_LeftSidebar_MenuContainer_Social_DDBackgroundColor
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDBorderTop={
                          DB_LeftSidebar_MenuContainer_Social_DDBorderTop
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDBorderStyleTop={
                          DB_LeftSidebar_MenuContainer_Social_DDBorderStyleTop
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDBorderColorTop={
                          DB_LeftSidebar_MenuContainer_Social_DDBorderColorTop
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDBorderBottom={
                          DB_LeftSidebar_MenuContainer_Social_DDBorderBottom
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDBorderStyleBottom={
                          DB_LeftSidebar_MenuContainer_Social_DDBorderStyleBottom
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDBorderColorBottom={
                          DB_LeftSidebar_MenuContainer_Social_DDBorderColorBottom
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDBorderLeft={
                          DB_LeftSidebar_MenuContainer_Social_DDBorderLeft
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDBorderStyleLeft={
                          DB_LeftSidebar_MenuContainer_Social_DDBorderStyleLeft
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDBorderColorLeft={
                          DB_LeftSidebar_MenuContainer_Social_DDBorderColorLeft
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDBorderRight={
                          DB_LeftSidebar_MenuContainer_Social_DDBorderRight
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDBorderStyleRight={
                          DB_LeftSidebar_MenuContainer_Social_DDBorderStyleRight
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDBorderColorRight={
                          DB_LeftSidebar_MenuContainer_Social_DDBorderColorRight
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDBorderTopRightRadius={
                          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDBorderTopRightRadius
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDBorderTopLeftRadius={
                          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDBorderTopLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDBorderBottomRightRadius={
                          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDBorderBottomRightRadius
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDBorderBottomLeftRadius={
                          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDBorderBottomLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDpaddingLeft={
                          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDpaddingLeft
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDpaddingRight={
                          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDpaddingRight
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDpaddingTop={
                          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDpaddingTop
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDpaddingBottom={
                          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDpaddingBottom
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDShadowHorizontal={
                          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDShadowHorizontal
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDShadowVertical={
                          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDShadowVertical
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDShadowBlur={
                          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDShadowBlur
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDShadowColor={
                          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDShadowColor
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDHover_BackgroundColor={
                          DB_LeftSidebar_MenuContainer_Social_DDHover_BackgroundColor
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDHover_BorderTop={
                          DB_LeftSidebar_MenuContainer_Social_DDHover_BorderTop
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDHover_BorderStyleTop={
                          DB_LeftSidebar_MenuContainer_Social_DDHover_BorderStyleTop
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDHover_BorderColorTop={
                          DB_LeftSidebar_MenuContainer_Social_DDHover_BorderColorTop
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDHover_BorderBottom={
                          DB_LeftSidebar_MenuContainer_Social_DDHover_BorderBottom
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDHover_BorderStyleBottom={
                          DB_LeftSidebar_MenuContainer_Social_DDHover_BorderStyleBottom
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDHover_BorderColorBottom={
                          DB_LeftSidebar_MenuContainer_Social_DDHover_BorderColorBottom
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDHover_BorderLeft={
                          DB_LeftSidebar_MenuContainer_Social_DDHover_BorderLeft
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDHover_BorderStyleLeft={
                          DB_LeftSidebar_MenuContainer_Social_DDHover_BorderStyleLeft
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDHover_BorderColorLeft={
                          DB_LeftSidebar_MenuContainer_Social_DDHover_BorderColorLeft
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDHover_BorderRight={
                          DB_LeftSidebar_MenuContainer_Social_DDHover_BorderRight
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDHover_BorderStyleRight={
                          DB_LeftSidebar_MenuContainer_Social_DDHover_BorderStyleRight
                        }
                        DB_LeftSidebar_MenuContainer_Social_DDHover_BorderColorRight={
                          DB_LeftSidebar_MenuContainer_Social_DDHover_BorderColorRight
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_BorderTopRightRadius={
                          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_BorderTopRightRadius
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_BorderTopLeftRadius={
                          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_BorderTopLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_BorderBottomRightRadius={
                          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_BorderBottomRightRadius
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_BorderBottomLeftRadius={
                          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_BorderBottomLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_paddingLeft={
                          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_paddingLeft
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_paddingRight={
                          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_paddingRight
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_paddingTop={
                          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_paddingTop
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_paddingBottom={
                          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_paddingBottom
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_ShadowHorizontal={
                          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_ShadowHorizontal
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_ShadowVertical={
                          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_ShadowVertical
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_ShadowBlur={
                          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_ShadowBlur
                        }
                        DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_ShadowColor={
                          DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_ShadowColor
                        }
                        DB_fontFace_SocialTitelFontFamily_FontFamilyName={
                          DB_fontFace_SocialTitelFontFamily_FontFamilyName
                        }
                        DB_fontFace_SocialTitelFontFamily_FontFamilySrc={
                          DB_fontFace_SocialTitelFontFamily_FontFamilySrc
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_FontWeight={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_FontWeight
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_FontColor={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_FontColor
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_FontSize={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_FontSize
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BackgroundColor={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BackgroundColor
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_TextAlign={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_TextAlign
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderTop={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderTop
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderStyleTop={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderStyleTop
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderColorTop={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderColorTop
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderBottom={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderBottom
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderStyleBottom={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderStyleBottom
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderColorBottom={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderColorBottom
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderLeft={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderLeft
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderStyleLeft={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderStyleLeft
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderColorLeft={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderColorLeft
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderRight={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderRight
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderStyleRight={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderStyleRight
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderColorRight={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderColorRight
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderTopRightRadius={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderTopRightRadius
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderTopLeftRadius={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderTopLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderBottomRightRadius={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderBottomRightRadius
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderBottomLeftRadius={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderBottomLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_marginLeftRight={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_marginLeftRight
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_marginTopBottom={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_marginTopBottom
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_paddingLeftRight={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_paddingLeftRight
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_paddingTopBottom={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_paddingTopBottom
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_letterSpacing={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_letterSpacing
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowHorizontal={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowHorizontal
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowVertical={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowVertical
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowBlur={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowBlur
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowColor={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowColor
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_TextDecoration={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_TextDecoration
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_TextDecorationStyle={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_TextDecorationStyle
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_FontWeight={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_FontWeight
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_FontColor={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_FontColor
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_FontSize={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_FontSize
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BackgroundColor={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BackgroundColor
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_TextAlign={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_TextAlign
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderTop={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderTop
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderStyleTop={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderStyleTop
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderColorTop={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderColorTop
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderBottom={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderBottom
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderStyleBottom={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderStyleBottom
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderColorBottom={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderColorBottom
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderLeft={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderLeft
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderStyleLeft={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderStyleLeft
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderColorLeft={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderColorLeft
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderRight={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderRight
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderStyleRight={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderStyleRight
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderColorRight={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderColorRight
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderTopRightRadius={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderTopRightRadius
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderTopLeftRadius={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderTopLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderBottomRightRadius={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderBottomRightRadius
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderBottomLeftRadius={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderBottomLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_marginLeftRight_Hover={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_marginLeftRight_Hover
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_marginTopBottom_Hover={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_marginTopBottom_Hover
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_paddingLeftRight_Hover={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_paddingLeftRight_Hover
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_paddingTopBottom_Hover={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_paddingTopBottom_Hover
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_letterSpacing_Hover={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_letterSpacing_Hover
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowHorizontal_Hover={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowHorizontal_Hover
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowVertical_Hover={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowVertical_Hover
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowBlur_Hover={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowBlur_Hover
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowColor_Hover={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowColor_Hover
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_TextDecoration_Hover={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_TextDecoration_Hover
                        }
                        DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_TextDecorationStyle_Hover={
                          DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_TextDecorationStyle_Hover
                        }
                        DB_fontFace_SocialTextFontFamily_FontFamilyName={
                          DB_fontFace_SocialTextFontFamily_FontFamilyName
                        }
                        DB_fontFace_SocialTextFontFamily_FontFamilySrc={
                          DB_fontFace_SocialTextFontFamily_FontFamilySrc
                        }
                        DB_LeftSidebar_MenuItem_SocialText_FontWeight={
                          DB_LeftSidebar_MenuItem_SocialText_FontWeight
                        }
                        DB_LeftSidebar_MenuItem_SocialText_FontColor={
                          DB_LeftSidebar_MenuItem_SocialText_FontColor
                        }
                        DB_LeftSidebar_MenuItem_SocialText_FontSize={
                          DB_LeftSidebar_MenuItem_SocialText_FontSize
                        }
                        DB_LeftSidebar_MenuItem_SocialText_BackgroundColor={
                          DB_LeftSidebar_MenuItem_SocialText_BackgroundColor
                        }
                        DB_LeftSidebar_MenuItem_SocialText_BorderTop={
                          DB_LeftSidebar_MenuItem_SocialText_BorderTop
                        }
                        DB_LeftSidebar_MenuItem_SocialText_BorderStyleTop={
                          DB_LeftSidebar_MenuItem_SocialText_BorderStyleTop
                        }
                        DB_LeftSidebar_MenuItem_SocialText_BorderColorTop={
                          DB_LeftSidebar_MenuItem_SocialText_BorderColorTop
                        }
                        DB_LeftSidebar_MenuItem_SocialText_BorderBottom={
                          DB_LeftSidebar_MenuItem_SocialText_BorderBottom
                        }
                        DB_LeftSidebar_MenuItem_SocialText_BorderStyleBottom={
                          DB_LeftSidebar_MenuItem_SocialText_BorderStyleBottom
                        }
                        DB_LeftSidebar_MenuItem_SocialText_BorderColorBottom={
                          DB_LeftSidebar_MenuItem_SocialText_BorderColorBottom
                        }
                        DB_LeftSidebar_MenuItem_SocialText_BorderLeft={
                          DB_LeftSidebar_MenuItem_SocialText_BorderLeft
                        }
                        DB_LeftSidebar_MenuItem_SocialText_BorderStyleLeft={
                          DB_LeftSidebar_MenuItem_SocialText_BorderStyleLeft
                        }
                        DB_LeftSidebar_MenuItem_SocialText_BorderColorLeft={
                          DB_LeftSidebar_MenuItem_SocialText_BorderColorLeft
                        }
                        DB_LeftSidebar_MenuItem_SocialText_BorderRight={
                          DB_LeftSidebar_MenuItem_SocialText_BorderRight
                        }
                        DB_LeftSidebar_MenuItem_SocialText_BorderStyleRight={
                          DB_LeftSidebar_MenuItem_SocialText_BorderStyleRight
                        }
                        DB_LeftSidebar_MenuItem_SocialText_BorderColorRight={
                          DB_LeftSidebar_MenuItem_SocialText_BorderColorRight
                        }
                        DB_LeftSidebar_MenuItem_SocialText_BorderTopRightRadius={
                          DB_LeftSidebar_MenuItem_SocialText_BorderTopRightRadius
                        }
                        DB_LeftSidebar_MenuItem_SocialText_BorderTopLeftRadius={
                          DB_LeftSidebar_MenuItem_SocialText_BorderTopLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_SocialText_BorderBottomRightRadius={
                          DB_LeftSidebar_MenuItem_SocialText_BorderBottomRightRadius
                        }
                        DB_LeftSidebar_MenuItem_SocialText_BorderBottomLeftRadius={
                          DB_LeftSidebar_MenuItem_SocialText_BorderBottomLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_SocialText_marginLeftRight={
                          DB_LeftSidebar_MenuItem_SocialText_marginLeftRight
                        }
                        DB_LeftSidebar_MenuItem_SocialText_marginTopBottom={
                          DB_LeftSidebar_MenuItem_SocialText_marginTopBottom
                        }
                        DB_LeftSidebar_MenuItem_SocialText_paddingLeftRight={
                          DB_LeftSidebar_MenuItem_SocialText_paddingLeftRight
                        }
                        DB_LeftSidebar_MenuItem_SocialText_paddingTopBottom={
                          DB_LeftSidebar_MenuItem_SocialText_paddingTopBottom
                        }
                        DB_LeftSidebar_MenuItem_SocialText_letterSpacing={
                          DB_LeftSidebar_MenuItem_SocialText_letterSpacing
                        }
                        DB_LeftSidebar_MenuItem_SocialText_ShadowHorizontal={
                          DB_LeftSidebar_MenuItem_SocialText_ShadowHorizontal
                        }
                        DB_LeftSidebar_MenuItem_SocialText_ShadowVertical={
                          DB_LeftSidebar_MenuItem_SocialText_ShadowVertical
                        }
                        DB_LeftSidebar_MenuItem_SocialText_ShadowBlur={
                          DB_LeftSidebar_MenuItem_SocialText_ShadowBlur
                        }
                        DB_LeftSidebar_MenuItem_SocialText_ShadowColor={
                          DB_LeftSidebar_MenuItem_SocialText_ShadowColor
                        }
                        DB_LeftSidebar_MenuItem_SocialText_TextAlign={
                          DB_LeftSidebar_MenuItem_SocialText_TextAlign
                        }
                        DB_LeftSidebar_MenuItem_SocialText_TextDecoration={
                          DB_LeftSidebar_MenuItem_SocialText_TextDecoration
                        }
                        DB_LeftSidebar_MenuItem_SocialText_TextDecorationStyle={
                          DB_LeftSidebar_MenuItem_SocialText_TextDecorationStyle
                        }
                        DB_LeftSidebar_MenuItem_SocialText_Hover_FontWeight={
                          DB_LeftSidebar_MenuItem_SocialText_Hover_FontWeight
                        }
                        DB_LeftSidebar_MenuItem_SocialText_Hover_FontColor={
                          DB_LeftSidebar_MenuItem_SocialText_Hover_FontColor
                        }
                        DB_LeftSidebar_MenuItem_SocialText_Hover_FontSize={
                          DB_LeftSidebar_MenuItem_SocialText_Hover_FontSize
                        }
                        DB_LeftSidebar_MenuItem_SocialText_Hover_BackgroundColor={
                          DB_LeftSidebar_MenuItem_SocialText_Hover_BackgroundColor
                        }
                        DB_LeftSidebar_MenuItem_SocialText_Hover_TextAlign={
                          DB_LeftSidebar_MenuItem_SocialText_Hover_TextAlign
                        }
                        DB_LeftSidebar_MenuItem_SocialText_Hover_BorderTop={
                          DB_LeftSidebar_MenuItem_SocialText_Hover_BorderTop
                        }
                        DB_LeftSidebar_MenuItem_SocialText_Hover_BorderStyleTop={
                          DB_LeftSidebar_MenuItem_SocialText_Hover_BorderStyleTop
                        }
                        DB_LeftSidebar_MenuItem_SocialText_Hover_BorderColorTop={
                          DB_LeftSidebar_MenuItem_SocialText_Hover_BorderColorTop
                        }
                        DB_LeftSidebar_MenuItem_SocialText_Hover_BorderBottom={
                          DB_LeftSidebar_MenuItem_SocialText_Hover_BorderBottom
                        }
                        DB_LeftSidebar_MenuItem_SocialText_Hover_BorderStyleBottom={
                          DB_LeftSidebar_MenuItem_SocialText_Hover_BorderStyleBottom
                        }
                        DB_LeftSidebar_MenuItem_SocialText_Hover_BorderColorBottom={
                          DB_LeftSidebar_MenuItem_SocialText_Hover_BorderColorBottom
                        }
                        DB_LeftSidebar_MenuItem_SocialText_Hover_BorderLeft={
                          DB_LeftSidebar_MenuItem_SocialText_Hover_BorderLeft
                        }
                        DB_LeftSidebar_MenuItem_SocialText_Hover_BorderStyleLeft={
                          DB_LeftSidebar_MenuItem_SocialText_Hover_BorderStyleLeft
                        }
                        DB_LeftSidebar_MenuItem_SocialText_Hover_BorderColorLeft={
                          DB_LeftSidebar_MenuItem_SocialText_Hover_BorderColorLeft
                        }
                        DB_LeftSidebar_MenuItem_SocialText_Hover_BorderRight={
                          DB_LeftSidebar_MenuItem_SocialText_Hover_BorderRight
                        }
                        DB_LeftSidebar_MenuItem_SocialText_Hover_BorderStyleRight={
                          DB_LeftSidebar_MenuItem_SocialText_Hover_BorderStyleRight
                        }
                        DB_LeftSidebar_MenuItem_SocialText_Hover_BorderColorRight={
                          DB_LeftSidebar_MenuItem_SocialText_Hover_BorderColorRight
                        }
                        DB_LeftSidebar_MenuItem_SocialText_Hover_BorderTopRightRadius={
                          DB_LeftSidebar_MenuItem_SocialText_Hover_BorderTopRightRadius
                        }
                        DB_LeftSidebar_MenuItem_SocialText_Hover_BorderTopLeftRadius={
                          DB_LeftSidebar_MenuItem_SocialText_Hover_BorderTopLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_SocialText_Hover_BorderBottomRightRadius={
                          DB_LeftSidebar_MenuItem_SocialText_Hover_BorderBottomRightRadius
                        }
                        DB_LeftSidebar_MenuItem_SocialText_Hover_BorderBottomLeftRadius={
                          DB_LeftSidebar_MenuItem_SocialText_Hover_BorderBottomLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_SocialText_marginLeftRight_Hover={
                          DB_LeftSidebar_MenuItem_SocialText_marginLeftRight_Hover
                        }
                        DB_LeftSidebar_MenuItem_SocialText_marginTopBottom_Hover={
                          DB_LeftSidebar_MenuItem_SocialText_marginTopBottom_Hover
                        }
                        DB_LeftSidebar_MenuItem_SocialText_paddingLeftRight_Hover={
                          DB_LeftSidebar_MenuItem_SocialText_paddingLeftRight_Hover
                        }
                        DB_LeftSidebar_MenuItem_SocialText_paddingTopBottom_Hover={
                          DB_LeftSidebar_MenuItem_SocialText_paddingTopBottom_Hover
                        }
                        DB_LeftSidebar_MenuItem_SocialText_letterSpacing_Hover={
                          DB_LeftSidebar_MenuItem_SocialText_letterSpacing_Hover
                        }
                        DB_LeftSidebar_MenuItem_SocialText_ShadowHorizontal_Hover={
                          DB_LeftSidebar_MenuItem_SocialText_ShadowHorizontal_Hover
                        }
                        DB_LeftSidebar_MenuItem_SocialText_ShadowVertical_Hover={
                          DB_LeftSidebar_MenuItem_SocialText_ShadowVertical_Hover
                        }
                        DB_LeftSidebar_MenuItem_SocialText_ShadowBlur_Hover={
                          DB_LeftSidebar_MenuItem_SocialText_ShadowBlur_Hover
                        }
                        DB_LeftSidebar_MenuItem_SocialText_ShadowColor_Hover={
                          DB_LeftSidebar_MenuItem_SocialText_ShadowColor_Hover
                        }
                        DB_LeftSidebar_MenuItem_SocialText_TextDecoration_Hover={
                          DB_LeftSidebar_MenuItem_SocialText_TextDecoration_Hover
                        }
                        DB_LeftSidebar_MenuItem_SocialText_TextDecorationStyle_Hover={
                          DB_LeftSidebar_MenuItem_SocialText_TextDecorationStyle_Hover
                        } /> */}
                  </>
                )}
              </Col>
            )}
          </Row>
        </Container>
      </section>
      <Footer
        faqDetailItem={faqDetailItem}
        meldenModalHandler={meldenModalHandler}
        aboutUsDetailItem={aboutUsDetailItem}
        aboutUsShowHandler={aboutUsShowHandler}
        faqShowHandler={faqShowHandler}
        shopName={shopName}
        countImagePlusTwo={countImagePlusTwo}
        logoActive={logoActive}
        toggleSignIn={toggleSignIn}
        isAuthState={isAuthState}
        signOutMessage={signOutMessage}
      />
      <Offcanvas
        show={show}
        onHide={handleClose}
        placement="bottom"
        className="offCanvasBottomOverlay "
      >
        {/*  <Offcanvas.Header closeButton>
          <Offcanvas.Title>Offcanvas</Offcanvas.Title>
        </Offcanvas.Header> */}
        <Offcanvas.Body>
          <SideBarRightFiltersOffCanvas
            resetFilterHandler={resetFilterHandler}
            ultraFiltersState={ultraFiltersState}
            filterProduktsHandler={filterProduktsHandler}
            isCheck={isCheck}
            DB_fontFace_AlleFilterButtonFontFamily_FontFamilyName={
              DB_fontFace_AlleFilterButtonFontFamily_FontFamilyName
            }
            DB_fontFace_AlleFilterButtonFontFamily_FontFamilySrc={
              DB_fontFace_AlleFilterButtonFontFamily_FontFamilySrc
            }
            DB_LeftSidebar_MenuItem_AlleFilterButtonFF_FontWeight={
              DB_LeftSidebar_MenuItem_AlleFilterButtonFF_FontWeight
            }
            DB_LeftSidebar_MenuItem_AlleFilterButtonFF_FontColor={
              DB_LeftSidebar_MenuItem_AlleFilterButtonFF_FontColor
            }
            DB_LeftSidebar_MenuItem_AlleFilterButtonFF_FontSize={
              DB_LeftSidebar_MenuItem_AlleFilterButtonFF_FontSize
            }
            DB_LeftSidebar_MenuItem_AlleFilterButtonFF_BackgroundColor={
              DB_LeftSidebar_MenuItem_AlleFilterButtonFF_BackgroundColor
            }
            DB_LeftSidebar_MenuItem_AlleFilterButtonFF_TextAlign={
              DB_LeftSidebar_MenuItem_AlleFilterButtonFF_TextAlign
            }
            DB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderTop={
              DB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderTop
            }
            DB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderStyleTop={
              DB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderStyleTop
            }
            DB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderColorTop={
              DB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderColorTop
            }
            DB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderBottom={
              DB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderBottom
            }
            DB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderStyleBottom={
              DB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderStyleBottom
            }
            DB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderColorBottom={
              DB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderColorBottom
            }
            DB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderLeft={
              DB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderLeft
            }
            DB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderStyleLeft={
              DB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderStyleLeft
            }
            DB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderColorLeft={
              DB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderColorLeft
            }
            DB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderRight={
              DB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderRight
            }
            DB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderStyleRight={
              DB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderStyleRight
            }
            DB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderColorRight={
              DB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderColorRight
            }
            DB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderTopRightRadius={
              DB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderTopRightRadius
            }
            DB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderTopLeftRadius={
              DB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderTopLeftRadius
            }
            DB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderBottomRightRadius={
              DB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderBottomRightRadius
            }
            DB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderBottomLeftRadius={
              DB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderBottomLeftRadius
            }
            DB_LeftSidebar_MenuItem_AlleFilterButtonFF_marginLeftRight={
              DB_LeftSidebar_MenuItem_AlleFilterButtonFF_marginLeftRight
            }
            DB_LeftSidebar_MenuItem_AlleFilterButtonFF_marginTopBottom={
              DB_LeftSidebar_MenuItem_AlleFilterButtonFF_marginTopBottom
            }
            DB_LeftSidebar_MenuItem_AlleFilterButtonFF_paddingLeftRight={
              DB_LeftSidebar_MenuItem_AlleFilterButtonFF_paddingLeftRight
            }
            DB_LeftSidebar_MenuItem_AlleFilterButtonFF_paddingTopBottom={
              DB_LeftSidebar_MenuItem_AlleFilterButtonFF_paddingTopBottom
            }
            DB_LeftSidebar_MenuItem_AlleFilterButtonFF_letterSpacing={
              DB_LeftSidebar_MenuItem_AlleFilterButtonFF_letterSpacing
            }
            DB_LeftSidebar_MenuItem_AlleFilterButtonFF_ShadowHorizontal={
              DB_LeftSidebar_MenuItem_AlleFilterButtonFF_ShadowHorizontal
            }
            DB_LeftSidebar_MenuItem_AlleFilterButtonFF_ShadowVertical={
              DB_LeftSidebar_MenuItem_AlleFilterButtonFF_ShadowVertical
            }
            DB_LeftSidebar_MenuItem_AlleFilterButtonFF_ShadowBlur={
              DB_LeftSidebar_MenuItem_AlleFilterButtonFF_ShadowBlur
            }
            DB_LeftSidebar_MenuItem_AlleFilterButtonFF_ShadowColor={
              DB_LeftSidebar_MenuItem_AlleFilterButtonFF_ShadowColor
            }
            DB_LeftSidebar_MenuItem_AlleFilterButtonFF_TextDecoration={
              DB_LeftSidebar_MenuItem_AlleFilterButtonFF_TextDecoration
            }
            DB_LeftSidebar_MenuItem_AlleFilterButtonFF_TextDecorationStyle={
              DB_LeftSidebar_MenuItem_AlleFilterButtonFF_TextDecorationStyle
            }
            DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_FontWeight={
              DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_FontWeight
            }
            DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_FontColor={
              DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_FontColor
            }
            DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_FontSize={
              DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_FontSize
            }
            DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BackgroundColor={
              DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BackgroundColor
            }
            DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_TextAlign={
              DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_TextAlign
            }
            DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderTop={
              DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderTop
            }
            DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderStyleTop={
              DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderStyleTop
            }
            DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderColorTop={
              DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderColorTop
            }
            DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderBottom={
              DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderBottom
            }
            DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderStyleBottom={
              DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderStyleBottom
            }
            DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderColorBottom={
              DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderColorBottom
            }
            DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderLeft={
              DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderLeft
            }
            DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderStyleLeft={
              DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderStyleLeft
            }
            DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderColorLeft={
              DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderColorLeft
            }
            DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderRight={
              DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderRight
            }
            DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderStyleRight={
              DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderStyleRight
            }
            DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderColorRight={
              DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderColorRight
            }
            DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderTopRightRadius={
              DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderTopRightRadius
            }
            DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderTopLeftRadius={
              DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderTopLeftRadius
            }
            DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderBottomRightRadius={
              DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderBottomRightRadius
            }
            DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderBottomLeftRadius={
              DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderBottomLeftRadius
            }
            DB_LeftSidebar_MenuItem_AlleFilterButtonFF_marginLeftRight_Hover={
              DB_LeftSidebar_MenuItem_AlleFilterButtonFF_marginLeftRight_Hover
            }
            DB_LeftSidebar_MenuItem_AlleFilterButtonFF_marginTopBottom_Hover={
              DB_LeftSidebar_MenuItem_AlleFilterButtonFF_marginTopBottom_Hover
            }
            DB_LeftSidebar_MenuItem_AlleFilterButtonFF_paddingLeftRight_Hover={
              DB_LeftSidebar_MenuItem_AlleFilterButtonFF_paddingLeftRight_Hover
            }
            DB_LeftSidebar_MenuItem_AlleFilterButtonFF_paddingTopBottom_Hover={
              DB_LeftSidebar_MenuItem_AlleFilterButtonFF_paddingTopBottom_Hover
            }
            DB_LeftSidebar_MenuItem_AlleFilterButtonFF_letterSpacing_Hover={
              DB_LeftSidebar_MenuItem_AlleFilterButtonFF_letterSpacing_Hover
            }
            DB_LeftSidebar_MenuItem_AlleFilterButtonFF_ShadowHorizontal_Hover={
              DB_LeftSidebar_MenuItem_AlleFilterButtonFF_ShadowHorizontal_Hover
            }
            DB_LeftSidebar_MenuItem_AlleFilterButtonFF_ShadowVertical_Hover={
              DB_LeftSidebar_MenuItem_AlleFilterButtonFF_ShadowVertical_Hover
            }
            DB_LeftSidebar_MenuItem_AlleFilterButtonFF_ShadowBlur_Hover={
              DB_LeftSidebar_MenuItem_AlleFilterButtonFF_ShadowBlur_Hover
            }
            DB_LeftSidebar_MenuItem_AlleFilterButtonFF_ShadowColor_Hover={
              DB_LeftSidebar_MenuItem_AlleFilterButtonFF_ShadowColor_Hover
            }
            DB_LeftSidebar_MenuItem_AlleFilterButtonFF_TextDecoration_Hover={
              DB_LeftSidebar_MenuItem_AlleFilterButtonFF_TextDecoration_Hover
            }
            DB_LeftSidebar_MenuItem_AlleFilterButtonFF_TextDecorationStyle_Hover={
              DB_LeftSidebar_MenuItem_AlleFilterButtonFF_TextDecorationStyle_Hover
            }
            DB_fontFace_FilterHauptTitel_FontFamilyName={
              DB_fontFace_FilterHauptTitel_FontFamilyName
            }
            DB_fontFace_FilterHauptTitel_FontFamilySrc={
              DB_fontFace_FilterHauptTitel_FontFamilySrc
            }
            DB_LeftSidebar_MenuItem_FilterHauptTitel_FontFace={
              DB_LeftSidebar_MenuItem_FilterHauptTitel_FontFace
            }
            DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_FontFace={
              DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_FontFace
            }
            DB_LeftSidebar_MenuItem_FilterHauptTitel_FontWeight={
              DB_LeftSidebar_MenuItem_FilterHauptTitel_FontWeight
            }
            DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_FontWeight={
              DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_FontWeight
            }
            DB_LeftSidebar_MenuItem_FilterHauptTitel_FontColor={
              DB_LeftSidebar_MenuItem_FilterHauptTitel_FontColor
            }
            DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_FontColor={
              DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_FontColor
            }
            DB_LeftSidebar_MenuItem_FilterHauptTitel_FontSize={
              DB_LeftSidebar_MenuItem_FilterHauptTitel_FontSize
            }
            DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_FontSize={
              DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_FontSize
            }
            SideBarLeft_HauptFiltertitel_Farbe_Groesse_Stil_OnMouseOverAndOut={
              SideBarLeft_HauptFiltertitel_Farbe_Groesse_Stil_OnMouseOverAndOut
            }
            SideBarLeft_HauptFiltertitel_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut={
              SideBarLeft_HauptFiltertitel_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut
            }
            SideBarLeft_HauptFiltertitel_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut={
              SideBarLeft_HauptFiltertitel_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut
            }
            DB_LeftSidebar_MenuItem_FilterHauptTitel_BackgroundColor={
              DB_LeftSidebar_MenuItem_FilterHauptTitel_BackgroundColor
            }
            DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BackgroundColor={
              DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BackgroundColor
            }
            DB_LeftSidebar_MenuItem_FilterHauptTitel_TextAlign={
              DB_LeftSidebar_MenuItem_FilterHauptTitel_TextAlign
            }
            DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_TextAlign={
              DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_TextAlign
            }
            DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderTop={
              DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderTop
            }
            DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderTop={
              DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderTop
            }
            DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderStyleTop={
              DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderStyleTop
            }
            DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderStyleTop={
              DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderStyleTop
            }
            DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderColorTop={
              DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderColorTop
            }
            DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderColorTop={
              DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderColorTop
            }
            DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderBottom={
              DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderBottom
            }
            DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderBottom={
              DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderBottom
            }
            DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderStyleBottom={
              DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderStyleBottom
            }
            DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderStyleBottom={
              DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderStyleBottom
            }
            DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderColorBottom={
              DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderColorBottom
            }
            DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderColorBottom={
              DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderColorBottom
            }
            DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderLeft={
              DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderLeft
            }
            DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderLeft={
              DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderLeft
            }
            DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderStyleLeft={
              DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderStyleLeft
            }
            DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderStyleLeft={
              DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderStyleLeft
            }
            DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderColorLeft={
              DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderColorLeft
            }
            DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderColorLeft={
              DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderColorLeft
            }
            DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderRight={
              DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderRight
            }
            DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderRight={
              DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderRight
            }
            DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderStyleRight={
              DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderStyleRight
            }
            DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderStyleRight={
              DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderStyleRight
            }
            DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderColorRight={
              DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderColorRight
            }
            DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderColorRight={
              DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderColorRight
            }
            DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderTopRightRadius={
              DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderTopRightRadius
            }
            DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderTopRightRadius={
              DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderTopRightRadius
            }
            DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderTopLeftRadius={
              DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderTopLeftRadius
            }
            DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderTopLeftRadius={
              DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderTopLeftRadius
            }
            DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderBottomRightRadius={
              DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderBottomRightRadius
            }
            DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderBottomRightRadius={
              DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderBottomRightRadius
            }
            DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderBottomLeftRadius={
              DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderBottomLeftRadius
            }
            DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderBottomLeftRadius={
              DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderBottomLeftRadius
            }
            /* handleShow_showConfig_LeftSidebar_MenuMainUltraFilterHauptTitel={
              handleShow_showConfig_LeftSidebar_MenuMainUltraFilterHauptTitel
            }
            handleShow_showConfig_LeftSidebar_MenuMainUltraFilterHauptTitel_BorderAndColor={
              handleShow_showConfig_LeftSidebar_MenuMainUltraFilterHauptTitel_BorderAndColor
            }
            handleShow_showConfig_LeftSidebar_MenuMainUltraFilterHauptTitel_Hover={
              handleShow_showConfig_LeftSidebar_MenuMainUltraFilterHauptTitel_Hover
            }
            handleShow_showConfig_LeftSidebar_MenuMainUltraFilterHauptTitel_Hover_BorderAndColor={
              handleShow_showConfig_LeftSidebar_MenuMainUltraFilterHauptTitel_Hover_BorderAndColor
            } */
            DB_LeftSidebar_MenuItem_FilterHauptTitel_marginTopBottom={
              DB_LeftSidebar_MenuItem_FilterHauptTitel_marginTopBottom
            }
            DB_LeftSidebar_MenuItem_FilterHauptTitel_marginLeftRight={
              DB_LeftSidebar_MenuItem_FilterHauptTitel_marginLeftRight
            }
            DB_LeftSidebar_MenuItem_FilterHauptTitel_paddingLeftRight={
              DB_LeftSidebar_MenuItem_FilterHauptTitel_paddingLeftRight
            }
            DB_LeftSidebar_MenuItem_FilterHauptTitel_paddingTopBottom={
              DB_LeftSidebar_MenuItem_FilterHauptTitel_paddingTopBottom
            }
            DB_LeftSidebar_MenuItem_FilterHauptTitel_letterSpacing={
              DB_LeftSidebar_MenuItem_FilterHauptTitel_letterSpacing
            }
            DB_LeftSidebar_MenuItem_FilterHauptTitel_ShadowHorizontal={
              DB_LeftSidebar_MenuItem_FilterHauptTitel_ShadowHorizontal
            }
            DB_LeftSidebar_MenuItem_FilterHauptTitel_ShadowVertical={
              DB_LeftSidebar_MenuItem_FilterHauptTitel_ShadowVertical
            }
            DB_LeftSidebar_MenuItem_FilterHauptTitel_ShadowBlur={
              DB_LeftSidebar_MenuItem_FilterHauptTitel_ShadowBlur
            }
            DB_LeftSidebar_MenuItem_FilterHauptTitel_ShadowColor={
              DB_LeftSidebar_MenuItem_FilterHauptTitel_ShadowColor
            }
            DB_LeftSidebar_MenuItem_FilterHauptTitel_TextDecoration={
              DB_LeftSidebar_MenuItem_FilterHauptTitel_TextDecoration
            }
            DB_LeftSidebar_MenuItem_FilterHauptTitel_TextDecorationStyle={
              DB_LeftSidebar_MenuItem_FilterHauptTitel_TextDecorationStyle
            }
            DB_LeftSidebar_MenuItem_FilterHauptTitel_marginTopBottom_Hover={
              DB_LeftSidebar_MenuItem_FilterHauptTitel_marginTopBottom_Hover
            }
            DB_LeftSidebar_MenuItem_FilterHauptTitel_marginLeftRight_Hover={
              DB_LeftSidebar_MenuItem_FilterHauptTitel_marginLeftRight_Hover
            }
            DB_LeftSidebar_MenuItem_FilterHauptTitel_paddingLeftRight_Hover={
              DB_LeftSidebar_MenuItem_FilterHauptTitel_paddingLeftRight_Hover
            }
            DB_LeftSidebar_MenuItem_FilterHauptTitel_paddingTopBottom_Hover={
              DB_LeftSidebar_MenuItem_FilterHauptTitel_paddingTopBottom_Hover
            }
            DB_LeftSidebar_MenuItem_FilterHauptTitel_letterSpacing_Hover={
              DB_LeftSidebar_MenuItem_FilterHauptTitel_letterSpacing_Hover
            }
            DB_LeftSidebar_MenuItem_FilterHauptTitel_ShadowHorizontal_Hover={
              DB_LeftSidebar_MenuItem_FilterHauptTitel_ShadowHorizontal_Hover
            }
            DB_LeftSidebar_MenuItem_FilterHauptTitel_ShadowVertical_Hover={
              DB_LeftSidebar_MenuItem_FilterHauptTitel_ShadowVertical_Hover
            }
            DB_LeftSidebar_MenuItem_FilterHauptTitel_ShadowBlur_Hover={
              DB_LeftSidebar_MenuItem_FilterHauptTitel_ShadowBlur_Hover
            }
            DB_LeftSidebar_MenuItem_FilterHauptTitel_ShadowColor_Hover={
              DB_LeftSidebar_MenuItem_FilterHauptTitel_ShadowColor_Hover
            }
            DB_LeftSidebar_MenuItem_FilterHauptTitel_TextDecoration_Hover={
              DB_LeftSidebar_MenuItem_FilterHauptTitel_TextDecoration_Hover
            }
            DB_LeftSidebar_MenuItem_FilterHauptTitel_TextDecorationStyle_Hover={
              DB_LeftSidebar_MenuItem_FilterHauptTitel_TextDecorationStyle_Hover
            }
            DB_LeftSidebar_MenuContainerFilterGG_BackgroundColor={
              DB_LeftSidebar_MenuContainerFilterGG_BackgroundColor
            }
            DB_LeftSidebar_MenuContainerFilterGG_BorderTop={
              DB_LeftSidebar_MenuContainerFilterGG_BorderTop
            }
            DB_LeftSidebar_MenuContainerFilterGG_BorderStyleTop={
              DB_LeftSidebar_MenuContainerFilterGG_BorderStyleTop
            }
            DB_LeftSidebar_MenuContainerFilterGG_BorderColorTop={
              DB_LeftSidebar_MenuContainerFilterGG_BorderColorTop
            }
            DB_LeftSidebar_MenuContainerFilterGG_BorderBottom={
              DB_LeftSidebar_MenuContainerFilterGG_BorderBottom
            }
            DB_LeftSidebar_MenuContainerFilterGG_BorderStyleBottom={
              DB_LeftSidebar_MenuContainerFilterGG_BorderStyleBottom
            }
            DB_LeftSidebar_MenuContainerFilterGG_BorderColorBottom={
              DB_LeftSidebar_MenuContainerFilterGG_BorderColorBottom
            }
            DB_LeftSidebar_MenuContainerFilterGG_BorderLeft={
              DB_LeftSidebar_MenuContainerFilterGG_BorderLeft
            }
            DB_LeftSidebar_MenuContainerFilterGG_BorderStyleLeft={
              DB_LeftSidebar_MenuContainerFilterGG_BorderStyleLeft
            }
            DB_LeftSidebar_MenuContainerFilterGG_BorderColorLeft={
              DB_LeftSidebar_MenuContainerFilterGG_BorderColorLeft
            }
            DB_LeftSidebar_MenuContainerFilterGG_BorderRight={
              DB_LeftSidebar_MenuContainerFilterGG_BorderRight
            }
            DB_LeftSidebar_MenuContainerFilterGG_BorderStyleRight={
              DB_LeftSidebar_MenuContainerFilterGG_BorderStyleRight
            }
            DB_LeftSidebar_MenuContainerFilterGG_BorderColorRight={
              DB_LeftSidebar_MenuContainerFilterGG_BorderColorRight
            }
            DB_LeftSidebar_MenuItem_MenuContainerFilterGG_BorderTopRightRadius={
              DB_LeftSidebar_MenuItem_MenuContainerFilterGG_BorderTopRightRadius
            }
            DB_LeftSidebar_MenuItem_MenuContainerFilterGG_BorderTopLeftRadius={
              DB_LeftSidebar_MenuItem_MenuContainerFilterGG_BorderTopLeftRadius
            }
            DB_LeftSidebar_MenuItem_MenuContainerFilterGG_BorderBottomRightRadius={
              DB_LeftSidebar_MenuItem_MenuContainerFilterGG_BorderBottomRightRadius
            }
            DB_LeftSidebar_MenuItem_MenuContainerFilterGG_BorderBottomLeftRadius={
              DB_LeftSidebar_MenuItem_MenuContainerFilterGG_BorderBottomLeftRadius
            }
            DB_LeftSidebar_MenuItem_MenuContainerFilterGG_paddingLeft={
              DB_LeftSidebar_MenuItem_MenuContainerFilterGG_paddingLeft
            }
            DB_LeftSidebar_MenuItem_MenuContainerFilterGG_paddingRight={
              DB_LeftSidebar_MenuItem_MenuContainerFilterGG_paddingRight
            }
            DB_LeftSidebar_MenuItem_MenuContainerFilterGG_paddingTop={
              DB_LeftSidebar_MenuItem_MenuContainerFilterGG_paddingTop
            }
            DB_LeftSidebar_MenuItem_MenuContainerFilterGG_paddingBottom={
              DB_LeftSidebar_MenuItem_MenuContainerFilterGG_paddingBottom
            }
            DB_LeftSidebar_MenuItem_MenuContainerFilterGG_ShadowHorizontal={
              DB_LeftSidebar_MenuItem_MenuContainerFilterGG_ShadowHorizontal
            }
            DB_LeftSidebar_MenuItem_MenuContainerFilterGG_ShadowVertical={
              DB_LeftSidebar_MenuItem_MenuContainerFilterGG_ShadowVertical
            }
            DB_LeftSidebar_MenuItem_MenuContainerFilterGG_ShadowBlur={
              DB_LeftSidebar_MenuItem_MenuContainerFilterGG_ShadowBlur
            }
            DB_LeftSidebar_MenuItem_MenuContainerFilterGG_ShadowColor={
              DB_LeftSidebar_MenuItem_MenuContainerFilterGG_ShadowColor
            }
            DB_LeftSidebar_MenuContainerFilterGG_Hover_BackgroundColor={
              DB_LeftSidebar_MenuContainerFilterGG_Hover_BackgroundColor
            }
            DB_LeftSidebar_MenuContainerFilterGG_Hover_BorderTop={
              DB_LeftSidebar_MenuContainerFilterGG_Hover_BorderTop
            }
            DB_LeftSidebar_MenuContainerFilterGG_Hover_BorderStyleTop={
              DB_LeftSidebar_MenuContainerFilterGG_Hover_BorderStyleTop
            }
            DB_LeftSidebar_MenuContainerFilterGG_Hover_BorderColorTop={
              DB_LeftSidebar_MenuContainerFilterGG_Hover_BorderColorTop
            }
            DB_LeftSidebar_MenuContainerFilterGG_Hover_BorderBottom={
              DB_LeftSidebar_MenuContainerFilterGG_Hover_BorderBottom
            }
            DB_LeftSidebar_MenuContainerFilterGG_Hover_BorderStyleBottom={
              DB_LeftSidebar_MenuContainerFilterGG_Hover_BorderStyleBottom
            }
            DB_LeftSidebar_MenuContainerFilterGG_Hover_BorderColorBottom={
              DB_LeftSidebar_MenuContainerFilterGG_Hover_BorderColorBottom
            }
            DB_LeftSidebar_MenuContainerFilterGG_Hover_BorderLeft={
              DB_LeftSidebar_MenuContainerFilterGG_Hover_BorderLeft
            }
            DB_LeftSidebar_MenuContainerFilterGG_Hover_BorderStyleLeft={
              DB_LeftSidebar_MenuContainerFilterGG_Hover_BorderStyleLeft
            }
            DB_LeftSidebar_MenuContainerFilterGG_Hover_BorderColorLeft={
              DB_LeftSidebar_MenuContainerFilterGG_Hover_BorderColorLeft
            }
            DB_LeftSidebar_MenuContainerFilterGG_Hover_BorderRight={
              DB_LeftSidebar_MenuContainerFilterGG_Hover_BorderRight
            }
            DB_LeftSidebar_MenuContainerFilterGG_Hover_BorderStyleRight={
              DB_LeftSidebar_MenuContainerFilterGG_Hover_BorderStyleRight
            }
            DB_LeftSidebar_MenuContainerFilterGG_Hover_BorderColorRight={
              DB_LeftSidebar_MenuContainerFilterGG_Hover_BorderColorRight
            }
            DB_LeftSidebar_MenuItem_MenuContainerFilterGG_Hover_BorderTopRightRadius={
              DB_LeftSidebar_MenuItem_MenuContainerFilterGG_Hover_BorderTopRightRadius
            }
            DB_LeftSidebar_MenuItem_MenuContainerFilterGG_Hover_BorderTopLeftRadius={
              DB_LeftSidebar_MenuItem_MenuContainerFilterGG_Hover_BorderTopLeftRadius
            }
            DB_LeftSidebar_MenuItem_MenuContainerFilterGG_Hover_BorderBottomRightRadius={
              DB_LeftSidebar_MenuItem_MenuContainerFilterGG_Hover_BorderBottomRightRadius
            }
            DB_LeftSidebar_MenuItem_MenuContainerFilterGG_Hover_BorderBottomLeftRadius={
              DB_LeftSidebar_MenuItem_MenuContainerFilterGG_Hover_BorderBottomLeftRadius
            }
            DB_LeftSidebar_MenuItem_MenuContainerFilterGG_Hover_paddingLeft={
              DB_LeftSidebar_MenuItem_MenuContainerFilterGG_Hover_paddingLeft
            }
            DB_LeftSidebar_MenuItem_MenuContainerFilterGG_Hover_paddingRight={
              DB_LeftSidebar_MenuItem_MenuContainerFilterGG_Hover_paddingRight
            }
            DB_LeftSidebar_MenuItem_MenuContainerFilterGG_Hover_paddingTop={
              DB_LeftSidebar_MenuItem_MenuContainerFilterGG_Hover_paddingTop
            }
            DB_LeftSidebar_MenuItem_MenuContainerFilterGG_Hover_paddingBottom={
              DB_LeftSidebar_MenuItem_MenuContainerFilterGG_Hover_paddingBottom
            }
            DB_LeftSidebar_MenuItem_MenuContainerFilterGG_Hover_ShadowHorizontal={
              DB_LeftSidebar_MenuItem_MenuContainerFilterGG_Hover_ShadowHorizontal
            }
            DB_LeftSidebar_MenuItem_MenuContainerFilterGG_Hover_ShadowVertical={
              DB_LeftSidebar_MenuItem_MenuContainerFilterGG_Hover_ShadowVertical
            }
            DB_LeftSidebar_MenuItem_MenuContainerFilterGG_Hover_ShadowBlur={
              DB_LeftSidebar_MenuItem_MenuContainerFilterGG_Hover_ShadowBlur
            }
            DB_LeftSidebar_MenuItem_MenuContainerFilterGG_Hover_ShadowColor={
              DB_LeftSidebar_MenuItem_MenuContainerFilterGG_Hover_ShadowColor
            }
            DB_fontFace_FilterItemFontFamily_FontFamilyName={
              DB_fontFace_FilterItemFontFamily_FontFamilyName
            }
            DB_fontFace_FilterItemFontFamily_FontFamilySrc={
              DB_fontFace_FilterItemFontFamily_FontFamilySrc
            }
            DB_LeftSidebar_MenuItem_FilterItem_FontWeight={
              DB_LeftSidebar_MenuItem_FilterItem_FontWeight
            }
            DB_LeftSidebar_MenuItem_FilterItem_Hover_FontWeight={
              DB_LeftSidebar_MenuItem_FilterItem_Hover_FontWeight
            }
            DB_LeftSidebar_MenuItem_FilterItem_FontColor={
              DB_LeftSidebar_MenuItem_FilterItem_FontColor
            }
            DB_LeftSidebar_MenuItem_FilterItem_Hover_FontColor={
              DB_LeftSidebar_MenuItem_FilterItem_Hover_FontColor
            }
            DB_LeftSidebar_MenuItem_FilterItem_FontSize={
              DB_LeftSidebar_MenuItem_FilterItem_FontSize
            }
            DB_LeftSidebar_MenuItem_FilterItem_Hover_FontSize={
              DB_LeftSidebar_MenuItem_FilterItem_Hover_FontSize
            }
            SideBarLeft_FilterItem_Farbe_Groesse_Stil_OnMouseOverAndOut={
              SideBarLeft_FilterItem_Farbe_Groesse_Stil_OnMouseOverAndOut
            }
            SideBarLeft_FilterItem_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut={
              SideBarLeft_FilterItem_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
            }
            SideBarLeft_FilterItem_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut={
              SideBarLeft_FilterItem_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut
            }
            SideBarLeft_FilterItem_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut={
              SideBarLeft_FilterItem_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut
            }
            DB_LeftSidebar_MenuItem_FilterItem_BackgroundColor={
              DB_LeftSidebar_MenuItem_FilterItem_BackgroundColor
            }
            DB_LeftSidebar_MenuItem_FilterItem_Hover_BackgroundColor={
              DB_LeftSidebar_MenuItem_FilterItem_Hover_BackgroundColor
            }
            DB_LeftSidebar_MenuItem_FilterItem_TextAlign={
              DB_LeftSidebar_MenuItem_FilterItem_TextAlign
            }
            DB_LeftSidebar_MenuItem_FilterItem_Hover_TextAlign={
              DB_LeftSidebar_MenuItem_FilterItem_Hover_TextAlign
            }
            DB_LeftSidebar_MenuItem_FilterItem_BorderTop={
              DB_LeftSidebar_MenuItem_FilterItem_BorderTop
            }
            DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderTop={
              DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderTop
            }
            DB_LeftSidebar_MenuItem_FilterItem_BorderStyleTop={
              DB_LeftSidebar_MenuItem_FilterItem_BorderStyleTop
            }
            DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderStyleTop={
              DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderStyleTop
            }
            DB_LeftSidebar_MenuItem_FilterItem_BorderColorTop={
              DB_LeftSidebar_MenuItem_FilterItem_BorderColorTop
            }
            DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderColorTop={
              DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderColorTop
            }
            DB_LeftSidebar_MenuItem_FilterItem_BorderBottom={
              DB_LeftSidebar_MenuItem_FilterItem_BorderBottom
            }
            DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderBottom={
              DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderBottom
            }
            DB_LeftSidebar_MenuItem_FilterItem_BorderStyleBottom={
              DB_LeftSidebar_MenuItem_FilterItem_BorderStyleBottom
            }
            DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderStyleBottom={
              DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderStyleBottom
            }
            DB_LeftSidebar_MenuItem_FilterItem_BorderColorBottom={
              DB_LeftSidebar_MenuItem_FilterItem_BorderColorBottom
            }
            DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderColorBottom={
              DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderColorBottom
            }
            DB_LeftSidebar_MenuItem_FilterItem_BorderLeft={
              DB_LeftSidebar_MenuItem_FilterItem_BorderLeft
            }
            DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderLeft={
              DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderLeft
            }
            DB_LeftSidebar_MenuItem_FilterItem_BorderStyleLeft={
              DB_LeftSidebar_MenuItem_FilterItem_BorderStyleLeft
            }
            DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderStyleLeft={
              DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderStyleLeft
            }
            DB_LeftSidebar_MenuItem_FilterItem_BorderColorLeft={
              DB_LeftSidebar_MenuItem_FilterItem_BorderColorLeft
            }
            DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderColorLeft={
              DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderColorLeft
            }
            DB_LeftSidebar_MenuItem_FilterItem_BorderRight={
              DB_LeftSidebar_MenuItem_FilterItem_BorderRight
            }
            DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderRight={
              DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderRight
            }
            DB_LeftSidebar_MenuItem_FilterItem_BorderStyleRight={
              DB_LeftSidebar_MenuItem_FilterItem_BorderStyleRight
            }
            DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderStyleRight={
              DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderStyleRight
            }
            DB_LeftSidebar_MenuItem_FilterItem_BorderColorRight={
              DB_LeftSidebar_MenuItem_FilterItem_BorderColorRight
            }
            DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderColorRight={
              DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderColorRight
            }
            DB_LeftSidebar_MenuItem_FilterItem_BorderTopRightRadius={
              DB_LeftSidebar_MenuItem_FilterItem_BorderTopRightRadius
            }
            DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderTopRightRadius={
              DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderTopRightRadius
            }
            DB_LeftSidebar_MenuItem_FilterItem_BorderTopLeftRadius={
              DB_LeftSidebar_MenuItem_FilterItem_BorderTopLeftRadius
            }
            DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderTopLeftRadius={
              DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderTopLeftRadius
            }
            DB_LeftSidebar_MenuItem_FilterItem_BorderBottomRightRadius={
              DB_LeftSidebar_MenuItem_FilterItem_BorderBottomRightRadius
            }
            DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderBottomRightRadius={
              DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderBottomRightRadius
            }
            DB_LeftSidebar_MenuItem_FilterItem_BorderBottomLeftRadius={
              DB_LeftSidebar_MenuItem_FilterItem_BorderBottomLeftRadius
            }
            DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderBottomLeftRadius={
              DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderBottomLeftRadius
            }
            DB_LeftSidebar_MenuItem_FilterItem_marginTopBottom={
              DB_LeftSidebar_MenuItem_FilterItem_marginTopBottom
            }
            DB_LeftSidebar_MenuItem_FilterItem_marginLeftRight={
              DB_LeftSidebar_MenuItem_FilterItem_marginLeftRight
            }
            DB_LeftSidebar_MenuItem_FilterItem_paddingLeftRight={
              DB_LeftSidebar_MenuItem_FilterItem_paddingLeftRight
            }
            DB_LeftSidebar_MenuItem_FilterItem_paddingTopBottom={
              DB_LeftSidebar_MenuItem_FilterItem_paddingTopBottom
            }
            DB_LeftSidebar_MenuItem_FilterItem_letterSpacing={
              DB_LeftSidebar_MenuItem_FilterItem_letterSpacing
            }
            DB_LeftSidebar_MenuItem_FilterItem_ShadowHorizontal={
              DB_LeftSidebar_MenuItem_FilterItem_ShadowHorizontal
            }
            DB_LeftSidebar_MenuItem_FilterItem_ShadowVertical={
              DB_LeftSidebar_MenuItem_FilterItem_ShadowVertical
            }
            DB_LeftSidebar_MenuItem_FilterItem_ShadowBlur={
              DB_LeftSidebar_MenuItem_FilterItem_ShadowBlur
            }
            DB_LeftSidebar_MenuItem_FilterItem_ShadowColor={
              DB_LeftSidebar_MenuItem_FilterItem_ShadowColor
            }
            DB_LeftSidebar_MenuItem_FilterItem_TextDecoration={
              DB_LeftSidebar_MenuItem_FilterItem_TextDecoration
            }
            DB_LeftSidebar_MenuItem_FilterItem_TextDecorationStyle={
              DB_LeftSidebar_MenuItem_FilterItem_TextDecorationStyle
            }
            DB_LeftSidebar_MenuItem_FilterItem_marginTopBottom_Hover={
              DB_LeftSidebar_MenuItem_FilterItem_marginTopBottom_Hover
            }
            DB_LeftSidebar_MenuItem_FilterItem_marginLeftRight_Hover={
              DB_LeftSidebar_MenuItem_FilterItem_marginLeftRight_Hover
            }
            DB_LeftSidebar_MenuItem_FilterItem_paddingLeftRight_Hover={
              DB_LeftSidebar_MenuItem_FilterItem_paddingLeftRight_Hover
            }
            DB_LeftSidebar_MenuItem_FilterItem_paddingTopBottom_Hover={
              DB_LeftSidebar_MenuItem_FilterItem_paddingTopBottom_Hover
            }
            DB_LeftSidebar_MenuItem_FilterItem_letterSpacing_Hover={
              DB_LeftSidebar_MenuItem_FilterItem_letterSpacing_Hover
            }
            DB_LeftSidebar_MenuItem_FilterItem_ShadowHorizontal_Hover={
              DB_LeftSidebar_MenuItem_FilterItem_ShadowHorizontal_Hover
            }
            DB_LeftSidebar_MenuItem_FilterItem_ShadowVertical_Hover={
              DB_LeftSidebar_MenuItem_FilterItem_ShadowVertical_Hover
            }
            DB_LeftSidebar_MenuItem_FilterItem_ShadowBlur_Hover={
              DB_LeftSidebar_MenuItem_FilterItem_ShadowBlur_Hover
            }
            DB_LeftSidebar_MenuItem_FilterItem_ShadowColor_Hover={
              DB_LeftSidebar_MenuItem_FilterItem_ShadowColor_Hover
            }
            DB_LeftSidebar_MenuItem_FilterItem_TextDecoration_Hover={
              DB_LeftSidebar_MenuItem_FilterItem_TextDecoration_Hover
            }
            DB_LeftSidebar_MenuItem_FilterItem_TextDecorationStyle_Hover={
              DB_LeftSidebar_MenuItem_FilterItem_TextDecorationStyle_Hover
            }
            backgroundColorActive={backgroundColorActive}
            DB_BackgroundImageForAllBody={DB_BackgroundImageForAllBody}
            DB_BackgroundColorOverlayForAllBody={
              DB_BackgroundColorOverlayForAllBody
            }
          />
        </Offcanvas.Body>
      </Offcanvas>
      <Modal
        show={signInModal}
        backdrop={true}
        className={
          doLoginOn || doForgotPassword ? "loggingModal" : "registerModal"
        }
      >
        <Modal.Header>
          <div className="loginRegisterHeader">
            <Navbar.Brand style={{ margin: "0px", padding: "0px" }}>
              {DB_TopBarLogoImageSrc && logoActive ? (
                <img height="65" src={DB_TopBarLogoImageSrc} alt="Logo" />
              ) : (
                <img
                  id="theLogoSpin"
                  src="/images/logo180_PS2.gif"
                  alt="SuperMegaShop.com Logo"
                  height="65"
                />
              )}
            </Navbar.Brand>
          </div>
        </Modal.Header>

        {doLoginOn ? (
          <Modal.Body className="p-0 loginRegister loginModal">
            <div className="loginRegisterIcons">
              <div
                onClick={anmeldenAktiv}
                className={doLoginOn ? "loginNavIconActive" : "loginNavIcon"}
              >
                <span className="singleIcon">🤙</span>
                <span className="singleIconText">{t("Anmelden")}</span>
              </div>
              <div
                onClick={registrierenAktiv}
                className={doRegisterOn ? "loginNavIconActive" : "loginNavIcon"}
              >
                <span className="singleIcon">👋</span>
                <span className="singleIconText">{t("Registrieren")}</span>
              </div>
              <div
                onClick={passwortVergessenAktiv}
                className={
                  doForgotPassword ? "loginNavIconActive" : "loginNavIcon"
                }
              >
                <span className="singleIcon">❓</span>
                <span className="singleIconText">{t("Hilfe")}</span>
              </div>
            </div>
            <Login
              registrierenAktiv={registrierenAktiv}
              passwortVergessenAktiv={passwortVergessenAktiv}
              anmeldungErfolgreich={anmeldungErfolgreich}
            />
          </Modal.Body>
        ) : (
          ""
        )}
        {doRegisterOn ? (
          <Modal.Body className="p-0 loginRegister loginModal">
            <div className="loginRegisterIcons">
              <div
                onClick={anmeldenAktiv}
                className={doLoginOn ? "loginNavIconActive" : "loginNavIcon"}
              >
                <span className="singleIcon">🤙</span>
                <span className="singleIconText">{t("Anmelden")}</span>
              </div>
              <div
                onClick={registrierenAktiv}
                className={doRegisterOn ? "loginNavIconActive" : "loginNavIcon"}
              >
                <span className="singleIcon">👋</span>
                <span className="singleIconText">{t("Registrieren")}</span>
              </div>
              <div
                onClick={passwortVergessenAktiv}
                className={
                  doForgotPassword ? "loginNavIconActive" : "loginNavIcon"
                }
              >
                <span className="singleIcon">❓</span>
                <span className="singleIconText">{t("Hilfe")}</span>
              </div>
            </div>
            <Register
              DB_TopBarLogoImageSrc={DB_TopBarLogoImageSrc}
              anmeldenAktiv={anmeldenAktiv}
              passwortVergessenAktiv={passwortVergessenAktiv}
            />
          </Modal.Body>
        ) : (
          ""
        )}

        {doForgotPassword ? (
          <Modal.Body className="p-0 loginRegister loginModal">
            <div className="loginRegisterIcons">
              <div
                onClick={anmeldenAktiv}
                className={doLoginOn ? "loginNavIconActive" : "loginNavIcon"}
              >
                <span className="singleIcon">🤙</span>
                <span className="singleIconText">{t("Anmelden")}</span>
              </div>
              <div
                onClick={registrierenAktiv}
                className={doRegisterOn ? "loginNavIconActive" : "loginNavIcon"}
              >
                <span className="singleIcon">👋</span>
                <span className="singleIconText">{t("Registrieren")}</span>
              </div>
              <div
                onClick={passwortVergessenAktiv}
                className={
                  doForgotPassword ? "loginNavIconActive" : "loginNavIcon"
                }
              >
                <span className="singleIcon">❓</span>
                <span className="singleIconText">{t("Hilfe")}</span>
              </div>
            </div>
            <ForgetPassword DB_TopBarLogoImageSrc={DB_TopBarLogoImageSrc} />
          </Modal.Body>
        ) : (
          ""
        )}
        <Button className="buttonModalClose" onClick={toggleSignIn}>
          {t("Schliessen")}
        </Button>
      </Modal>
      <ToastContainer />

      <Modal
        show={benutzerMeldenModal}
        backdrop={true}
        className={"loggingModal"}
      >
        <Modal.Header>
          <div className="loginRegisterHeader">
            {DB_TopBarLogoImageSrc && logoActive && !TopBarIsVisibleBottom ? (
              <Navbar.Brand style={{ margin: "0px", padding: "0px" }}>
                <img
                  onClick={resetFilterHandler}
                  src={DB_TopBarLogoImageSrc}
                  alt="Logo"
                />
              </Navbar.Brand>
            ) : (
              <Navbar.Brand
                style={{ margin: "0px", padding: "0px" }}
              ></Navbar.Brand>
            )}
          </div>
        </Modal.Header>
        <Modal.Body className="p-0 loginRegister loginModal">
          <form className="ps-4 pe-4" action="#">
            <div className="mb-3 text-center">
              <h1
                style={{ color: "white", marginTop: "25px", fontSize: "24px" }}
              >
                {t("BenutzerMelden")}
              </h1>
              <div
                className="form-label"
                style={{
                  color: "white",
                  marginTop: "15px",
                  marginBottom: "10px",
                  fontSize: "16px",
                  marginLeft: "30px",
                  marginRight: "30px",
                }}
              >
                {t("RegelverstossMelden")}
              </div>
              <div style={{ marginTop: "20px" }}>
                <Select
                  className="react-select landUniIconContainer"
                  classNamePrefix="react-select"
                  onChange={(e) => doMeldenReasonHandler(e)}
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 3,
                    colors: {
                      ...theme.colors,
                      color: "white",
                      neutral80: "white",
                      text: "white",
                      primary25: "#635bff",
                      primary: "#635bff",
                      font: "white",
                    },
                  })}
                  options={[
                    { value: "beleidigung", label: `${t("Beleidigung")}` },
                    { value: "produkte", label: `${t("IllegaleProdukte")}` },
                    { value: "bilder", label: `${t("AnstoessigeBilder")}` },
                    { value: "rassismus", label: `${t("Rassismus")}` },
                    { value: "sexismus", label: `${t("Sexismus")}` },
                    { value: "politik", label: `${t("Politik")}` },
                    { value: "religion", label: `${t("Religion")}` },
                  ]}
                ></Select>
              </div>
              <div
                className="form-label"
                style={{
                  color: "white",
                  marginTop: "15px",
                  marginBottom: "10px",
                  fontSize: "16px",
                }}
              >
                {t("BeschreibungRegelverstoss")}
              </div>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Control
                  style={{ color: "white", height: "100px" }}
                  placeholder={t("Meldungsbeschreibung")}
                  as="textarea"
                  rows={3}
                  onChange={(e) => {
                    onChangeMeldebeschreibung(e);
                  }}
                />
              </Form.Group>
              <div
                style={{
                  color: "white",
                  marginTop: "25px",
                  marginBottom: "20px",
                  fontSize: "16px",
                  marginLeft: "30px",
                  marginRight: "30px",
                }}
              >
                {t("WeiterMassnahmen")}
              </div>
            </div>

            <div className="mb-1 text-center">
              <div className="mb-3 text-center">
                <Button
                  variant="danger"
                  onClick={() => regelverstossMeldenSubmit()}
                >
                  {t("BenutzerMelden")}
                </Button>
              </div>
            </div>
          </form>
        </Modal.Body>

        <Button
          className="buttonModalClose"
          onClick={() => meldenModalHandler(false)}
        >
          {t("Schliessen")}
        </Button>
      </Modal>
    </>
  );
}

export default MyShop;
