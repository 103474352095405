import { createUseStyles } from "react-jss";

const ProductCardBreadCrumbsThree = ({
  categoryLeftSideHandler,
  productKategorie,
  productUnterkategorie,
  productUnterUnterkategorie,
  DB_BodyProductCard_Breadcrumbs_FontFamily,
  DB_fontFace_chooseFontBreadcrumbs_FontFamilyName,
  DB_fontFace_chooseFontBreadcrumbs_FontFamilySrc,
  DB_LeftSidebar_MenuItem_BreadCrumbs_FontWeight,
  DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_FontWeight,
  DB_LeftSidebar_MenuItem_BreadCrumbs_FontColor,
  DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_FontColor,
  DB_LeftSidebar_MenuItem_BreadCrumbs_FontSize,
  DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_FontSize,
  SideBarLeft_BreadCrumbs_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut,
  SideBarLeft_BreadCrumbs_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut,
  DB_LeftSidebar_MenuItem_BreadCrumbs_BackgroundColor,
  DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BackgroundColor,
  DB_LeftSidebar_MenuItem_BreadCrumbs_TextAlign,
  DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_TextAlign,
  SideBarLeft_BreadCrumbs_Farbe_Groesse_Stil_OnMouseOverAndOut,
  SideBarLeft_BreadCrumbs_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut,
  DB_LeftSidebar_MenuItem_BreadCrumbs_BorderTop,
  DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderTop,
  DB_LeftSidebar_MenuItem_BreadCrumbs_BorderStyleTop,
  DB_LeftSidebar_MenuItem_BreadCrumbs_BorderColorTop,
  DB_LeftSidebar_MenuItem_BreadCrumbs_BorderBottom,
  DB_LeftSidebar_MenuItem_BreadCrumbs_BorderStyleBottom,
  DB_LeftSidebar_MenuItem_BreadCrumbs_BorderColorBottom,
  DB_LeftSidebar_MenuItem_BreadCrumbs_BorderLeft,
  DB_LeftSidebar_MenuItem_BreadCrumbs_BorderStyleLeft,
  DB_LeftSidebar_MenuItem_BreadCrumbs_BorderColorLeft,
  DB_LeftSidebar_MenuItem_BreadCrumbs_BorderRight,
  DB_LeftSidebar_MenuItem_BreadCrumbs_BorderStyleRight,
  DB_LeftSidebar_MenuItem_BreadCrumbs_BorderColorRight,

  DB_LeftSidebar_MenuItem_BreadCrumbs_BorderTopRightRadius,
  DB_LeftSidebar_MenuItem_BreadCrumbs_BorderTopLeftRadius,
  DB_LeftSidebar_MenuItem_BreadCrumbs_BorderBottomRightRadius,
  DB_LeftSidebar_MenuItem_BreadCrumbs_BorderBottomLeftRadius,

  DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderTopRightRadius,
  DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderTopLeftRadius,
  DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderBottomRightRadius,
  DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderBottomLeftRadius,

  DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderStyleTop,
  DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderColorTop,
  DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderBottom,
  DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderStyleBottom,

  DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderColorBottom,
  DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderLeft,
  DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderStyleLeft,
  DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderColorLeft,

  DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderRight,
  DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderStyleRight,
  DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderColorRight,

  DB_LeftSidebar_MenuItem_BreadCrumbs_marginTopBottom,
  DB_LeftSidebar_MenuItem_BreadCrumbs_marginLeftRight,
  DB_LeftSidebar_MenuItem_BreadCrumbs_paddingLeftRight,
  DB_LeftSidebar_MenuItem_BreadCrumbs_paddingTopBottom,

  DB_LeftSidebar_MenuItem_BreadCrumbs_letterSpacing,
  DB_LeftSidebar_MenuItem_BreadCrumbs_ShadowHorizontal,
  DB_LeftSidebar_MenuItem_BreadCrumbs_ShadowVertical,
  DB_LeftSidebar_MenuItem_BreadCrumbs_ShadowBlur,
  DB_LeftSidebar_MenuItem_BreadCrumbs_ShadowColor,
  DB_LeftSidebar_MenuItem_BreadCrumbs_TextDecoration,
  DB_LeftSidebar_MenuItem_BreadCrumbs_TextDecorationStyle,

  DB_LeftSidebar_MenuItem_BreadCrumbs_marginTopBottom_Hover,
  DB_LeftSidebar_MenuItem_BreadCrumbs_marginLeftRight_Hover,
  DB_LeftSidebar_MenuItem_BreadCrumbs_paddingLeftRight_Hover,
  DB_LeftSidebar_MenuItem_BreadCrumbs_paddingTopBottom_Hover,

  DB_LeftSidebar_MenuItem_BreadCrumbs_letterSpacing_Hover,
  DB_LeftSidebar_MenuItem_BreadCrumbs_ShadowHorizontal_Hover,
  DB_LeftSidebar_MenuItem_BreadCrumbs_ShadowVertical_Hover,
  DB_LeftSidebar_MenuItem_BreadCrumbs_ShadowBlur_Hover,
  DB_LeftSidebar_MenuItem_BreadCrumbs_ShadowColor_Hover,
  DB_LeftSidebar_MenuItem_BreadCrumbs_TextDecoration_Hover,
  DB_LeftSidebar_MenuItem_BreadCrumbs_TextDecorationStyle_Hover,
}) => {
  const useStyles = createUseStyles({
    produktBreadCrumbsContainer: {
      textAlign: `${DB_LeftSidebar_MenuItem_BreadCrumbs_TextAlign}`,
    },
    produktBreadCrumbs: {
      display: "inline-block",
      fontFamily: `${DB_fontFace_chooseFontBreadcrumbs_FontFamilyName}`,
      fontWeight: `${DB_LeftSidebar_MenuItem_BreadCrumbs_FontWeight}`,
      color: `${DB_LeftSidebar_MenuItem_BreadCrumbs_FontColor}`,
      fontSize: `${DB_LeftSidebar_MenuItem_BreadCrumbs_FontSize}px`,
      backgroundColor: `${
        SideBarLeft_BreadCrumbs_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut
          ? "rgba(255,0,0,0.5)"
          : SideBarLeft_BreadCrumbs_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut
          ? "rgba(255,0,0,0.3)"
          : DB_LeftSidebar_MenuItem_BreadCrumbs_BackgroundColor
      }`,

      borderTop: `${
        SideBarLeft_BreadCrumbs_Farbe_Groesse_Stil_OnMouseOverAndOut
          ? "3"
          : SideBarLeft_BreadCrumbs_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
          ? "3"
          : DB_LeftSidebar_MenuItem_BreadCrumbs_BorderTop
      }px
        ${
          SideBarLeft_BreadCrumbs_Farbe_Groesse_Stil_OnMouseOverAndOut
            ? "solid"
            : SideBarLeft_BreadCrumbs_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
            ? "dotted"
            : DB_LeftSidebar_MenuItem_BreadCrumbs_BorderStyleTop
        }
        ${
          SideBarLeft_BreadCrumbs_Farbe_Groesse_Stil_OnMouseOverAndOut
            ? "red"
            : SideBarLeft_BreadCrumbs_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
            ? "red"
            : DB_LeftSidebar_MenuItem_BreadCrumbs_BorderColorTop
        }`,
      borderBottom: `${
        SideBarLeft_BreadCrumbs_Farbe_Groesse_Stil_OnMouseOverAndOut
          ? "3"
          : SideBarLeft_BreadCrumbs_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
          ? "3"
          : DB_LeftSidebar_MenuItem_BreadCrumbs_BorderBottom
      }px
        ${
          SideBarLeft_BreadCrumbs_Farbe_Groesse_Stil_OnMouseOverAndOut
            ? "solid"
            : SideBarLeft_BreadCrumbs_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
            ? "dotted"
            : DB_LeftSidebar_MenuItem_BreadCrumbs_BorderStyleBottom
        }
        ${
          SideBarLeft_BreadCrumbs_Farbe_Groesse_Stil_OnMouseOverAndOut
            ? "red"
            : SideBarLeft_BreadCrumbs_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
            ? "red"
            : DB_LeftSidebar_MenuItem_BreadCrumbs_BorderColorBottom
        }`,
      borderLeft: `${
        SideBarLeft_BreadCrumbs_Farbe_Groesse_Stil_OnMouseOverAndOut
          ? "3"
          : SideBarLeft_BreadCrumbs_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
          ? "3"
          : DB_LeftSidebar_MenuItem_BreadCrumbs_BorderLeft
      }px
        ${
          SideBarLeft_BreadCrumbs_Farbe_Groesse_Stil_OnMouseOverAndOut
            ? "solid"
            : SideBarLeft_BreadCrumbs_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
            ? "dotted"
            : DB_LeftSidebar_MenuItem_BreadCrumbs_BorderStyleLeft
        }
        ${
          SideBarLeft_BreadCrumbs_Farbe_Groesse_Stil_OnMouseOverAndOut
            ? "red"
            : SideBarLeft_BreadCrumbs_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
            ? "red"
            : DB_LeftSidebar_MenuItem_BreadCrumbs_BorderColorLeft
        }`,
      borderRight: `${
        SideBarLeft_BreadCrumbs_Farbe_Groesse_Stil_OnMouseOverAndOut
          ? "3"
          : SideBarLeft_BreadCrumbs_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
          ? "3"
          : DB_LeftSidebar_MenuItem_BreadCrumbs_BorderRight
      }px
        ${
          SideBarLeft_BreadCrumbs_Farbe_Groesse_Stil_OnMouseOverAndOut
            ? "solid"
            : SideBarLeft_BreadCrumbs_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
            ? "dotted"
            : DB_LeftSidebar_MenuItem_BreadCrumbs_BorderStyleRight
        }
        ${
          SideBarLeft_BreadCrumbs_Farbe_Groesse_Stil_OnMouseOverAndOut
            ? "red"
            : SideBarLeft_BreadCrumbs_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
            ? "red"
            : DB_LeftSidebar_MenuItem_BreadCrumbs_BorderColorRight
        }`,
      borderTopRightRadius: `${DB_LeftSidebar_MenuItem_BreadCrumbs_BorderTopRightRadius}px`,
      borderTopLeftRadius: `${DB_LeftSidebar_MenuItem_BreadCrumbs_BorderTopLeftRadius}px`,
      borderBottomRightRadius: `${DB_LeftSidebar_MenuItem_BreadCrumbs_BorderBottomRightRadius}px`,
      borderBottomLeftRadius: `${DB_LeftSidebar_MenuItem_BreadCrumbs_BorderBottomLeftRadius}px`,
      marginLeft: `${
        DB_LeftSidebar_MenuItem_BreadCrumbs_marginLeftRight < 0
          ? Math.abs(DB_LeftSidebar_MenuItem_BreadCrumbs_marginLeftRight)
          : 0
      }px`,
      marginRight:
        `${DB_LeftSidebar_MenuItem_BreadCrumbs_marginLeftRight}` > 0
          ? Math.abs(`${DB_LeftSidebar_MenuItem_BreadCrumbs_marginLeftRight}`)
          : `${0}px`,
      marginTop:
        `${DB_LeftSidebar_MenuItem_BreadCrumbs_marginTopBottom}` > 0
          ? Math.abs(`${DB_LeftSidebar_MenuItem_BreadCrumbs_marginTopBottom}`)
          : `${0}px`,
      marginBottom:
        `${DB_LeftSidebar_MenuItem_BreadCrumbs_marginTopBottom}` < 0
          ? Math.abs(`${DB_LeftSidebar_MenuItem_BreadCrumbs_marginTopBottom}`)
          : `${0}px`,
      paddingLeft: `${DB_LeftSidebar_MenuItem_BreadCrumbs_paddingLeftRight}px`,
      paddingRight: `${DB_LeftSidebar_MenuItem_BreadCrumbs_paddingLeftRight}px`,
      paddingTop: `${DB_LeftSidebar_MenuItem_BreadCrumbs_paddingTopBottom}px`,
      paddingBottom: `${DB_LeftSidebar_MenuItem_BreadCrumbs_paddingTopBottom}px`,
      letterSpacing: `${DB_LeftSidebar_MenuItem_BreadCrumbs_letterSpacing}px`,
      textShadow: `${DB_LeftSidebar_MenuItem_BreadCrumbs_ShadowHorizontal}px
        ${DB_LeftSidebar_MenuItem_BreadCrumbs_ShadowVertical}px
        ${DB_LeftSidebar_MenuItem_BreadCrumbs_ShadowBlur}px
        ${DB_LeftSidebar_MenuItem_BreadCrumbs_ShadowColor}`,
      textDecoration: `${DB_LeftSidebar_MenuItem_BreadCrumbs_TextDecoration}
        ${DB_LeftSidebar_MenuItem_BreadCrumbs_TextDecorationStyle}`,
      "&:hover": {
        fontWeight: `${DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_FontWeight}`,
        color: `${DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_FontColor}`,
        fontSize: `${DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_FontSize}px`,
        backgroundColor: `${DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BackgroundColor}`,
        textAlign: `${DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_TextAlign}`,
        borderTop: `${DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderTop}px
        ${DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderStyleTop}
        ${DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderColorTop}`,
        borderBottom: `${DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderBottom}px
        ${DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderStyleBottom}
        ${DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderColorBottom}`,
        borderLeft: `${DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderLeft}px
        ${DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderStyleLeft}
        ${DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderColorLeft}`,
        borderRight: `${DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderRight}px
        ${DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderStyleRight}
        ${DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderColorRight}`,
        borderTopRightRadius: `${DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderTopRightRadius}px`,
        borderTopLeftRadius: `${DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderTopLeftRadius}px`,
        borderBottomRightRadius: `${DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderBottomRightRadius}px`,
        borderBottomLeftRadius: `${DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderBottomLeftRadius}px`,
        marginLeft:
          `${DB_LeftSidebar_MenuItem_BreadCrumbs_marginLeftRight_Hover}` < 0
            ? Math.abs(
                `${DB_LeftSidebar_MenuItem_BreadCrumbs_marginLeftRight_Hover}`
              )
            : `${0}` + `px`,
        marginRight:
          `${DB_LeftSidebar_MenuItem_BreadCrumbs_marginLeftRight_Hover}` > 0
            ? Math.abs(
                `${DB_LeftSidebar_MenuItem_BreadCrumbs_marginLeftRight_Hover}`
              )
            : `${0}` + `px`,
        marginTop:
          `${DB_LeftSidebar_MenuItem_BreadCrumbs_marginTopBottom_Hover}` > 0
            ? Math.abs(
                `${DB_LeftSidebar_MenuItem_BreadCrumbs_marginTopBottom_Hover}`
              )
            : `${0}` + `px`,
        marginBottom:
          `${DB_LeftSidebar_MenuItem_BreadCrumbs_marginTopBottom_Hover}` < 0
            ? Math.abs(
                `${DB_LeftSidebar_MenuItem_BreadCrumbs_marginTopBottom_Hover}`
              )
            : `${0}` + `px`,
        paddingLeft: `${DB_LeftSidebar_MenuItem_BreadCrumbs_paddingLeftRight_Hover}px`,
        paddingRight: `${DB_LeftSidebar_MenuItem_BreadCrumbs_paddingLeftRight_Hover}px`,
        paddingTop: `${DB_LeftSidebar_MenuItem_BreadCrumbs_paddingTopBottom_Hover}px`,
        paddingBottom: `${DB_LeftSidebar_MenuItem_BreadCrumbs_paddingTopBottom_Hover}px`,
        letterSpacing: `${DB_LeftSidebar_MenuItem_BreadCrumbs_letterSpacing_Hover}px`,
        textShadow: `${DB_LeftSidebar_MenuItem_BreadCrumbs_ShadowHorizontal_Hover}px
        ${DB_LeftSidebar_MenuItem_BreadCrumbs_ShadowVertical_Hover}px
        ${DB_LeftSidebar_MenuItem_BreadCrumbs_ShadowBlur_Hover}px
        ${DB_LeftSidebar_MenuItem_BreadCrumbs_ShadowColor_Hover}`,
        textDecoration: `${DB_LeftSidebar_MenuItem_BreadCrumbs_TextDecoration_Hover}
        ${DB_LeftSidebar_MenuItem_BreadCrumbs_TextDecorationStyle_Hover}`,
      },
    },
  });

  const classes = useStyles();
  return (
    <div className={classes.produktBreadCrumbsContainer}>
      {/* <div
          className={classes.produktBreadCrumbs}
          style={{ cursor: "pointer" }}
          onClick={() =>
            categoryLeftSideHandler(
              `${productKategorie}`,
              `${productUnterkategorie}`
            )
          }
        >
          {productUnterkategorie}
        </div> */}
      {productUnterUnterkategorie ? (
        <div
          className={classes.produktBreadCrumbs}
          style={{ cursor: "pointer" }}
          onClick={() =>
            categoryLeftSideHandler(
              `${productKategorie}`,
              `${productUnterkategorie}`,
              `${productUnterUnterkategorie}`
            )
          }
        >
          {productUnterUnterkategorie}
        </div>
      ) : !productUnterUnterkategorie && productUnterkategorie ? (
        <div
          className={classes.produktBreadCrumbs}
          style={{ cursor: "pointer" }}
          onClick={() =>
            categoryLeftSideHandler(
              `${productKategorie}`,
              `${productUnterkategorie}`
            )
          }
        >
          {productUnterkategorie}
        </div>
      ) : productKategorie &&
        !productUnterUnterkategorie &&
        !productUnterkategorie ? (
        <div
          className={classes.produktBreadCrumbs}
          style={{ cursor: "pointer" }}
          onClick={() => categoryLeftSideHandler(`${productKategorie}`)}
        >
          {productKategorie}
        </div>
      ) : (
        <div></div>
      )}
    </div>
  );
};
export default ProductCardBreadCrumbsThree;
