import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";

const BodyProductDetailFormular = ({
  stateNachricht,
  contactFormHandlerNachricht,
  anfrageAnVerkaeuferSendenHandler,
  DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_FontFamilyName,
  DB_fontFace_chooseFontFormLabelsFontFamily_FontFamilyName,
  DB_LeftSidebar_MenuItem_TitelFormInputDetail_FontWeight,
  DB_LeftSidebar_MenuItem_TitelFormInputDetail_FontColor,
  DB_LeftSidebar_MenuItem_TitelFormInputDetail_FontSize,
  DB_LeftSidebar_MenuItem_TitelFormInputDetail_BackgroundColor,
  DB_LeftSidebar_MenuItem_TitelFormInputDetail_TextAlign,
  DB_LeftSidebar_MenuItem_TitelFormInputDetail_BorderTop,
  DB_LeftSidebar_MenuItem_TitelFormInputDetail_BorderStyleTop,
  DB_LeftSidebar_MenuItem_TitelFormInputDetail_BorderColorTop,
  DB_LeftSidebar_MenuItem_TitelFormInputDetail_BorderBottom,
  DB_LeftSidebar_MenuItem_TitelFormInputDetail_BorderStyleBottom,
  DB_LeftSidebar_MenuItem_TitelFormInputDetail_BorderColorBottom,
  DB_LeftSidebar_MenuItem_TitelFormInputDetail_BorderLeft,
  DB_LeftSidebar_MenuItem_TitelFormInputDetail_BorderStyleLeft,
  DB_LeftSidebar_MenuItem_TitelFormInputDetail_BorderColorLeft,
  DB_LeftSidebar_MenuItem_TitelFormInputDetail_BorderRight,
  DB_LeftSidebar_MenuItem_TitelFormInputDetail_BorderStyleRight,
  DB_LeftSidebar_MenuItem_TitelFormInputDetail_BorderColorRight,
  DB_LeftSidebar_MenuItem_TitelFormInputDetail_BorderTopRightRadius,
  DB_LeftSidebar_MenuItem_TitelFormInputDetail_BorderTopLeftRadius,
  DB_LeftSidebar_MenuItem_TitelFormInputDetail_BorderBottomRightRadius,
  DB_LeftSidebar_MenuItem_TitelFormInputDetail_BorderBottomLeftRadius,
  DB_LeftSidebar_MenuItem_TitelFormInputDetail_marginLeftRight,
  DB_LeftSidebar_MenuItem_TitelFormInputDetail_marginTopBottom,
  DB_LeftSidebar_MenuItem_TitelFormInputDetail_paddingLeftRight,
  DB_LeftSidebar_MenuItem_TitelFormInputDetail_paddingTopBottom,
  DB_LeftSidebar_MenuItem_TitelFormInputDetail_letterSpacing,
  DB_LeftSidebar_MenuItem_TitelFormInputDetail_ShadowHorizontal,
  DB_LeftSidebar_MenuItem_TitelFormInputDetail_ShadowVertical,
  DB_LeftSidebar_MenuItem_TitelFormInputDetail_ShadowBlur,
  DB_LeftSidebar_MenuItem_TitelFormInputDetail_ShadowColor,
  DB_LeftSidebar_MenuItem_TitelFormInputDetail_TextDecoration,
  DB_LeftSidebar_MenuItem_TitelFormInputDetail_TextDecorationStyle,
  SideBarLeft_TitelFormInputDetail_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut,
  SideBarLeft_TitelFormInputDetail_Farbe_Groesse_Stil_OnMouseOverAndOut,

  DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_FontWeight,
  DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_FontColor,
  DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_FontSize,
  DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_BackgroundColor,
  DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_TextAlign,
  DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_BorderTop,
  DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_BorderStyleTop,
  DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_BorderColorTop,
  DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_BorderBottom,
  DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_BorderStyleBottom,
  DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_BorderColorBottom,
  DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_BorderLeft,
  DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_BorderStyleLeft,
  DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_BorderColorLeft,
  DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_BorderRight,
  DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_BorderStyleRight,
  DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_BorderColorRight,
  DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_BorderTopRightRadius,
  DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_BorderTopLeftRadius,
  DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_BorderBottomRightRadius,
  DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_BorderBottomLeftRadius,
  DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_marginLeftRight,
  DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_marginTopBottom,
  DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_paddingLeftRight,
  DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_paddingTopBottom,
  DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_letterSpacing,
  DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_ShadowHorizontal,
  DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_ShadowVertical,
  DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_ShadowBlur,
  DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_ShadowColor,
  DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_TextDecoration,
  DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_TextDecorationStyle,
  DB_LeftSidebar_MenuItem_TitelKontaktFormular_FontWeight,
  DB_LeftSidebar_MenuItem_TitelKontaktFormular_FontColor,
  DB_LeftSidebar_MenuItem_TitelKontaktFormular_FontSize,
  DB_LeftSidebar_MenuItem_TitelKontaktFormular_BackgroundColor,
  DB_LeftSidebar_MenuItem_TitelKontaktFormular_TextAlign,
  DB_LeftSidebar_MenuItem_TitelKontaktFormular_BorderTop,
  DB_LeftSidebar_MenuItem_TitelKontaktFormular_BorderStyleTop,
  DB_LeftSidebar_MenuItem_TitelKontaktFormular_BorderColorTop,
  DB_LeftSidebar_MenuItem_TitelKontaktFormular_BorderBottom,
  DB_LeftSidebar_MenuItem_TitelKontaktFormular_BorderStyleBottom,
  DB_LeftSidebar_MenuItem_TitelKontaktFormular_BorderColorBottom,
  DB_LeftSidebar_MenuItem_TitelKontaktFormular_BorderLeft,
  DB_LeftSidebar_MenuItem_TitelKontaktFormular_BorderStyleLeft,
  DB_LeftSidebar_MenuItem_TitelKontaktFormular_BorderColorLeft,
  DB_LeftSidebar_MenuItem_TitelKontaktFormular_BorderRight,
  DB_LeftSidebar_MenuItem_TitelKontaktFormular_BorderStyleRight,
  DB_LeftSidebar_MenuItem_TitelKontaktFormular_BorderColorRight,
  DB_LeftSidebar_MenuItem_TitelKontaktFormular_BorderTopRightRadius,
  DB_LeftSidebar_MenuItem_TitelKontaktFormular_BorderTopLeftRadius,
  DB_LeftSidebar_MenuItem_TitelKontaktFormular_BorderBottomRightRadius,
  DB_LeftSidebar_MenuItem_TitelKontaktFormular_BorderBottomLeftRadius,
  DB_LeftSidebar_MenuItem_TitelKontaktFormular_marginLeftRight,
  DB_LeftSidebar_MenuItem_TitelKontaktFormular_marginTopBottom,
  DB_LeftSidebar_MenuItem_TitelKontaktFormular_paddingLeftRight,
  DB_LeftSidebar_MenuItem_TitelKontaktFormular_paddingTopBottom,
  DB_LeftSidebar_MenuItem_TitelKontaktFormular_letterSpacing,
  DB_LeftSidebar_MenuItem_TitelKontaktFormular_ShadowHorizontal,
  DB_LeftSidebar_MenuItem_TitelKontaktFormular_ShadowVertical,
  DB_LeftSidebar_MenuItem_TitelKontaktFormular_ShadowBlur,
  DB_LeftSidebar_MenuItem_TitelKontaktFormular_ShadowColor,
  DB_LeftSidebar_MenuItem_TitelKontaktFormular_TextDecoration,
  DB_LeftSidebar_MenuItem_TitelKontaktFormular_TextDecorationStyle,
  SideBarLeft_TitelKontaktFormularTitel_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut,
  SideBarLeft_TitelKontaktFormularTitel_Farbe_Groesse_Stil_OnMouseOverAndOut,
  SideBarLeft_AlleDetail_Farbe_Groesse_Stil_OnMouseOverAndOut,
  SideBarLeft_AlleDetail_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut,
  DB_fontFace_chooseFontKontaktFormularFontFamily_FontFamilyName,
}) => {
  const { t } = useTranslation();

  const useStyles = createUseStyles({
    inputFormContactForm: {
      fontFamily: `${DB_fontFace_chooseFontFormLabelsFontFamily_FontFamilyName}`,
      fontWeight: `${DB_LeftSidebar_MenuItem_TitelFormInputDetail_FontWeight}`,
      color: `${DB_LeftSidebar_MenuItem_TitelFormInputDetail_FontColor}`,
      fontSize: `${DB_LeftSidebar_MenuItem_TitelFormInputDetail_FontSize}px`,
      backgroundColor: `${
        SideBarLeft_TitelFormInputDetail_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut
          ? "rgba(255,0,0,0.5)"
          : DB_LeftSidebar_MenuItem_TitelFormInputDetail_BackgroundColor
      }`,
      textAlign: `${DB_LeftSidebar_MenuItem_TitelFormInputDetail_TextAlign}`,
      borderTop: `${
        SideBarLeft_TitelFormInputDetail_Farbe_Groesse_Stil_OnMouseOverAndOut
          ? "3"
          : DB_LeftSidebar_MenuItem_TitelFormInputDetail_BorderTop
      }px
            ${
              SideBarLeft_TitelFormInputDetail_Farbe_Groesse_Stil_OnMouseOverAndOut
                ? "solid"
                : DB_LeftSidebar_MenuItem_TitelFormInputDetail_BorderStyleTop
            }
            ${
              SideBarLeft_TitelFormInputDetail_Farbe_Groesse_Stil_OnMouseOverAndOut
                ? "red"
                : DB_LeftSidebar_MenuItem_TitelFormInputDetail_BorderColorTop
            }`,
      borderBottom: `${
        SideBarLeft_TitelFormInputDetail_Farbe_Groesse_Stil_OnMouseOverAndOut
          ? "3"
          : DB_LeftSidebar_MenuItem_TitelFormInputDetail_BorderBottom
      }px
            ${
              SideBarLeft_TitelFormInputDetail_Farbe_Groesse_Stil_OnMouseOverAndOut
                ? "solid"
                : DB_LeftSidebar_MenuItem_TitelFormInputDetail_BorderStyleBottom
            }
            ${
              SideBarLeft_TitelFormInputDetail_Farbe_Groesse_Stil_OnMouseOverAndOut
                ? "red"
                : DB_LeftSidebar_MenuItem_TitelFormInputDetail_BorderColorBottom
            }`,
      borderLeft: `${
        SideBarLeft_TitelFormInputDetail_Farbe_Groesse_Stil_OnMouseOverAndOut
          ? "3"
          : DB_LeftSidebar_MenuItem_TitelFormInputDetail_BorderLeft
      }px
            ${
              SideBarLeft_TitelFormInputDetail_Farbe_Groesse_Stil_OnMouseOverAndOut
                ? "solid"
                : DB_LeftSidebar_MenuItem_TitelFormInputDetail_BorderStyleLeft
            }
            ${
              SideBarLeft_TitelFormInputDetail_Farbe_Groesse_Stil_OnMouseOverAndOut
                ? "red"
                : DB_LeftSidebar_MenuItem_TitelFormInputDetail_BorderColorLeft
            }`,
      borderRight: `${
        SideBarLeft_TitelFormInputDetail_Farbe_Groesse_Stil_OnMouseOverAndOut
          ? "3"
          : DB_LeftSidebar_MenuItem_TitelFormInputDetail_BorderRight
      }px
            ${
              SideBarLeft_TitelFormInputDetail_Farbe_Groesse_Stil_OnMouseOverAndOut
                ? "solid"
                : DB_LeftSidebar_MenuItem_TitelFormInputDetail_BorderStyleRight
            }
            ${
              SideBarLeft_TitelFormInputDetail_Farbe_Groesse_Stil_OnMouseOverAndOut
                ? "red"
                : DB_LeftSidebar_MenuItem_TitelFormInputDetail_BorderColorRight
            }`,
      borderTopRightRadius: `${DB_LeftSidebar_MenuItem_TitelFormInputDetail_BorderTopRightRadius}px`,
      borderTopLeftRadius: `${DB_LeftSidebar_MenuItem_TitelFormInputDetail_BorderTopLeftRadius}px`,
      borderBottomRightRadius: `${DB_LeftSidebar_MenuItem_TitelFormInputDetail_BorderBottomRightRadius}px`,
      borderBottomLeftRadius: `${DB_LeftSidebar_MenuItem_TitelFormInputDetail_BorderBottomLeftRadius}px`,
      marginLeft: `${DB_LeftSidebar_MenuItem_TitelFormInputDetail_marginLeftRight}px`,
      marginRight: `${DB_LeftSidebar_MenuItem_TitelFormInputDetail_marginLeftRight}px`,
      marginTop: `${DB_LeftSidebar_MenuItem_TitelFormInputDetail_marginTopBottom}px`,
      marginBottom: `${DB_LeftSidebar_MenuItem_TitelFormInputDetail_marginTopBottom}px`,
      paddingLeft: `${DB_LeftSidebar_MenuItem_TitelFormInputDetail_paddingLeftRight}px`,
      paddingRight: `${DB_LeftSidebar_MenuItem_TitelFormInputDetail_paddingLeftRight}px`,
      paddingTop: `${DB_LeftSidebar_MenuItem_TitelFormInputDetail_paddingTopBottom}px`,
      paddingBottom: `${DB_LeftSidebar_MenuItem_TitelFormInputDetail_paddingTopBottom}px`,
      letterSpacing: `${DB_LeftSidebar_MenuItem_TitelFormInputDetail_letterSpacing}px`,
      textShadow: `${DB_LeftSidebar_MenuItem_TitelFormInputDetail_ShadowHorizontal}px
            ${DB_LeftSidebar_MenuItem_TitelFormInputDetail_ShadowVertical}px
            ${DB_LeftSidebar_MenuItem_TitelFormInputDetail_ShadowBlur}px
            ${DB_LeftSidebar_MenuItem_TitelFormInputDetail_ShadowColor}`,
      textDecoration: `${DB_LeftSidebar_MenuItem_TitelFormInputDetail_TextDecoration}
            ${DB_LeftSidebar_MenuItem_TitelFormInputDetail_TextDecorationStyle}`,

      "&:autofill": {
        backgroundColor: `${DB_LeftSidebar_MenuItem_TitelFormInputDetail_BackgroundColor}`,
        color: `${DB_LeftSidebar_MenuItem_TitelFormInputDetail_FontColor}`,
      },

      "&:focus": {
        backgroundColor: `${DB_LeftSidebar_MenuItem_TitelFormInputDetail_BackgroundColor}`,
        color: `${DB_LeftSidebar_MenuItem_TitelFormInputDetail_FontColor}`,
      },
    },

    productDetailItemHeaderKontaktFormularTitel: {
      fontFamily: `${DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_FontFamilyName}`,
      fontWeight: `${DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_FontWeight}`,
      color: `${DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_FontColor}`,
      fontSize: `${DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_FontSize}px`,
      backgroundColor: `${
        SideBarLeft_TitelKontaktFormularTitel_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut
          ? "rgba(255,0,0,0.5)"
          : DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_BackgroundColor
      }`,
      textAlign: `${DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_TextAlign}`,
      borderTop: `${
        SideBarLeft_TitelKontaktFormularTitel_Farbe_Groesse_Stil_OnMouseOverAndOut
          ? "3"
          : DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_BorderTop
      }px
      ${
        SideBarLeft_TitelKontaktFormularTitel_Farbe_Groesse_Stil_OnMouseOverAndOut
          ? "solid"
          : DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_BorderStyleTop
      }
      ${
        SideBarLeft_TitelKontaktFormularTitel_Farbe_Groesse_Stil_OnMouseOverAndOut
          ? "red"
          : DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_BorderColorTop
      }`,
      borderBottom: `${
        SideBarLeft_TitelKontaktFormularTitel_Farbe_Groesse_Stil_OnMouseOverAndOut
          ? "3"
          : DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_BorderBottom
      }px
      ${
        SideBarLeft_TitelKontaktFormularTitel_Farbe_Groesse_Stil_OnMouseOverAndOut
          ? "solid"
          : DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_BorderStyleBottom
      }
      ${
        SideBarLeft_TitelKontaktFormularTitel_Farbe_Groesse_Stil_OnMouseOverAndOut
          ? "red"
          : DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_BorderColorBottom
      }`,
      borderLeft: `${
        SideBarLeft_TitelKontaktFormularTitel_Farbe_Groesse_Stil_OnMouseOverAndOut
          ? "3"
          : DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_BorderLeft
      }px
      ${
        SideBarLeft_TitelKontaktFormularTitel_Farbe_Groesse_Stil_OnMouseOverAndOut
          ? "solid"
          : DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_BorderStyleLeft
      }
      ${
        SideBarLeft_TitelKontaktFormularTitel_Farbe_Groesse_Stil_OnMouseOverAndOut
          ? "red"
          : DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_BorderColorLeft
      }`,
      borderRight: `${
        SideBarLeft_TitelKontaktFormularTitel_Farbe_Groesse_Stil_OnMouseOverAndOut
          ? "3"
          : DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_BorderRight
      }px
      ${
        SideBarLeft_TitelKontaktFormularTitel_Farbe_Groesse_Stil_OnMouseOverAndOut
          ? "solid"
          : DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_BorderStyleRight
      }
      ${
        SideBarLeft_TitelKontaktFormularTitel_Farbe_Groesse_Stil_OnMouseOverAndOut
          ? "red"
          : DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_BorderColorRight
      }`,
      borderTopRightRadius: `${DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_BorderTopRightRadius}px`,
      borderTopLeftRadius: `${DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_BorderTopLeftRadius}px`,
      borderBottomRightRadius: `${DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_BorderBottomRightRadius}px`,
      borderBottomLeftRadius: `${DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_BorderBottomLeftRadius}px`,
      marginLeft: `${DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_marginLeftRight}px`,
      marginRight: `${DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_marginLeftRight}px`,
      marginTop: `${DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_marginTopBottom}px`,
      marginBottom: `${DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_marginTopBottom}px`,
      paddingLeft: `${DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_paddingLeftRight}px`,
      paddingRight: `${DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_paddingLeftRight}px`,
      paddingTop: `${DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_paddingTopBottom}px`,
      paddingBottom: `${DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_paddingTopBottom}px`,
      letterSpacing: `${DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_letterSpacing}px`,
      textShadow: `${DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_ShadowHorizontal}px
      ${DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_ShadowVertical}px
      ${DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_ShadowBlur}px
      ${DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_ShadowColor}`,
      textDecoration: `${DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_TextDecoration}
      ${DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_TextDecorationStyle}`,
      cursor: "pointer",
    },

    KontaktFormularContainer: {
      borderTop: `${
        SideBarLeft_AlleDetail_Farbe_Groesse_Stil_OnMouseOverAndOut
          ? "3"
          : DB_LeftSidebar_MenuItem_TitelKontaktFormular_BorderTop
      }px
        ${
          SideBarLeft_AlleDetail_Farbe_Groesse_Stil_OnMouseOverAndOut
            ? "solid"
            : DB_LeftSidebar_MenuItem_TitelKontaktFormular_BorderStyleTop
        }
        ${
          SideBarLeft_AlleDetail_Farbe_Groesse_Stil_OnMouseOverAndOut
            ? "red"
            : DB_LeftSidebar_MenuItem_TitelKontaktFormular_BorderColorTop
        }`,
      borderBottom: `${
        SideBarLeft_AlleDetail_Farbe_Groesse_Stil_OnMouseOverAndOut
          ? "3"
          : DB_LeftSidebar_MenuItem_TitelKontaktFormular_BorderBottom
      }px
        ${
          SideBarLeft_AlleDetail_Farbe_Groesse_Stil_OnMouseOverAndOut
            ? "solid"
            : DB_LeftSidebar_MenuItem_TitelKontaktFormular_BorderStyleBottom
        }
        ${
          SideBarLeft_AlleDetail_Farbe_Groesse_Stil_OnMouseOverAndOut
            ? "red"
            : DB_LeftSidebar_MenuItem_TitelKontaktFormular_BorderColorBottom
        }`,
      borderLeft: `${
        SideBarLeft_AlleDetail_Farbe_Groesse_Stil_OnMouseOverAndOut
          ? "3"
          : DB_LeftSidebar_MenuItem_TitelKontaktFormular_BorderLeft
      }px
        ${
          SideBarLeft_AlleDetail_Farbe_Groesse_Stil_OnMouseOverAndOut
            ? "solid"
            : DB_LeftSidebar_MenuItem_TitelKontaktFormular_BorderStyleLeft
        }
        ${
          SideBarLeft_AlleDetail_Farbe_Groesse_Stil_OnMouseOverAndOut
            ? "red"
            : DB_LeftSidebar_MenuItem_TitelKontaktFormular_BorderColorLeft
        }`,
      borderRight: `${
        SideBarLeft_AlleDetail_Farbe_Groesse_Stil_OnMouseOverAndOut
          ? "3"
          : DB_LeftSidebar_MenuItem_TitelKontaktFormular_BorderRight
      }px
        ${
          SideBarLeft_AlleDetail_Farbe_Groesse_Stil_OnMouseOverAndOut
            ? "solid"
            : DB_LeftSidebar_MenuItem_TitelKontaktFormular_BorderStyleRight
        }
        ${
          SideBarLeft_AlleDetail_Farbe_Groesse_Stil_OnMouseOverAndOut
            ? "red"
            : DB_LeftSidebar_MenuItem_TitelKontaktFormular_BorderColorRight
        }`,
      borderTopRightRadius: `${DB_LeftSidebar_MenuItem_TitelKontaktFormular_BorderTopRightRadius}px`,
      borderTopLeftRadius: `${DB_LeftSidebar_MenuItem_TitelKontaktFormular_BorderTopLeftRadius}px`,
      borderBottomRightRadius: `${DB_LeftSidebar_MenuItem_TitelKontaktFormular_BorderBottomRightRadius}px`,
      borderBottomLeftRadius: `${DB_LeftSidebar_MenuItem_TitelKontaktFormular_BorderBottomLeftRadius}px`,
      marginLeft: `${DB_LeftSidebar_MenuItem_TitelKontaktFormular_marginLeftRight}px`,
      marginRight: `${DB_LeftSidebar_MenuItem_TitelKontaktFormular_marginLeftRight}px`,
      marginTop: `${DB_LeftSidebar_MenuItem_TitelKontaktFormular_marginTopBottom}px`,
      marginBottom: `${DB_LeftSidebar_MenuItem_TitelKontaktFormular_marginTopBottom}px`,
      paddingLeft: `${DB_LeftSidebar_MenuItem_TitelKontaktFormular_paddingLeftRight}px`,
      paddingRight: `${DB_LeftSidebar_MenuItem_TitelKontaktFormular_paddingLeftRight}px`,
      paddingTop: `${DB_LeftSidebar_MenuItem_TitelKontaktFormular_paddingTopBottom}px`,
      paddingBottom: `${DB_LeftSidebar_MenuItem_TitelKontaktFormular_paddingTopBottom}px`,

      backgroundColor: `${
        SideBarLeft_AlleDetail_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut
          ? "rgba(255,0,0,0.5)"
          : DB_LeftSidebar_MenuItem_TitelKontaktFormular_BackgroundColor
      }`,
    },

    KontaktFormularContainerLabel: {
      fontFamily: `${DB_fontFace_chooseFontKontaktFormularFontFamily_FontFamilyName}`,
      fontWeight: `${DB_LeftSidebar_MenuItem_TitelKontaktFormular_FontWeight}`,
      color: `${DB_LeftSidebar_MenuItem_TitelKontaktFormular_FontColor}`,
      fontSize: `${DB_LeftSidebar_MenuItem_TitelKontaktFormular_FontSize}px`,
      textAlign: `${DB_LeftSidebar_MenuItem_TitelKontaktFormular_TextAlign}`,
      letterSpacing: `${DB_LeftSidebar_MenuItem_TitelKontaktFormular_letterSpacing}px`,
      textShadow: `${DB_LeftSidebar_MenuItem_TitelKontaktFormular_ShadowHorizontal}px
          ${DB_LeftSidebar_MenuItem_TitelKontaktFormular_ShadowVertical}px
          ${DB_LeftSidebar_MenuItem_TitelKontaktFormular_ShadowBlur}px
          ${DB_LeftSidebar_MenuItem_TitelKontaktFormular_ShadowColor}`,
      textDecoration: `${DB_LeftSidebar_MenuItem_TitelKontaktFormular_TextDecoration}
          ${DB_LeftSidebar_MenuItem_TitelKontaktFormular_TextDecorationStyle}`,
    },
  });

  const classes = useStyles();

  return (
    <div className="row m-0 p-0">
      <div className={`col-lg-12 col-12 mt-0 mb-0 pt-0 pb-0 `}>
        <div className={`hello ${classes.KontaktFormularContainerLabel}`}>
          {t("NachrichtAnDenVerkaeufer")}:
        </div>
        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
          <Form.Control
            onChange={(e) => contactFormHandlerNachricht(e)}
            value={stateNachricht}
            as="textarea"
            className={classes.inputFormContactForm}
            rows={3}
          />
        </Form.Group>
      </div>
      <div
        onClick={() => anfrageAnVerkaeuferSendenHandler()}
        className={`col-lg-12 col-12 text-center ${classes.productDetailItemHeaderKontaktFormularTitel}`}
      >
        {t("ProduktanfrageAbschicken")}
      </div>
    </div>
  );
};

export default BodyProductDetailFormular;
