import React, { useState } from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { toast, Zoom } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";

import RegisterEmailPW from "./RegisterEmailPW";
import RegisterDetails from "./RegisterDetails";
import RegisterInfos from "./RegisterInfos";
import RegisterDone from "./RegisterDone";

import LockIcon from "@mui/icons-material/Lock";
import HomeIcon from "@mui/icons-material/Home";
import PersonIcon from "@mui/icons-material/Person";
import CloudDoneIcon from "@mui/icons-material/CloudDone";

const Register = ({ DB_TopBarLogoImageSrc }) => {
  const { t } = useTranslation();

  const [doEmailPW, setDoEmailPW] = useState(true);
  const [doDetails, setDoDetails] = useState(false);
  const [doInfo, setDoInfo] = useState(false);
  const [doDone, setDoDone] = useState(false);

  const [regEmail, setRegEmail] = useState("");
  const [regPasswort, setRegPasswort] = useState("");
  const [regPasswortRepeat, setRegPasswortRepeat] = useState("");
  const [regVorname, setRegVorname] = useState("");
  const [regNachname, setRegNachname] = useState("");
  const [regFirma, setRegFirma] = useState("");
  const [regHomepage, setRegHomepage] = useState("");
  const [regAdresse, setRegAdresse] = useState("");
  const [regPLZ, setRegPLZ] = useState("");
  const [regOrt, setRegOrt] = useState("");
  const [regLand, setRegLand] = useState("");
  const [eMailValid, setEMailValid] = useState(true);
  const [passwortValid, setPasswortValid] = useState(true);
  const [passwortRepValid, setPasswortRepValid] = useState(true);
  const [passwortNotDuplicated, setPasswortNotDuplicated] = useState(true);
  const [vornameValid, setVornameValid] = useState(true);
  const [nachnameValid, setNachnameValid] = useState(true);
  const [firmaValid, setFirmaValid] = useState(true);
  const [homepageValid, setHomepageValid] = useState(true);
  const [adresseValid, setAdresseValid] = useState(true);
  const [plzValid, setPlzValid] = useState(true);
  const [ortValid, setOrtValid] = useState(true);
  const [landValid, setLandValid] = useState(true);
  const [confirmAGB, setConfirmAGB] = useState(false);
  const [confirmAGBTest, setConfirmAGBTest] = useState(false);

  const AGBsConfirmHandler = (e) => {
    setConfirmAGB(e.target.checked);
  };

  let re = /\S+@\S+\.\S+/;
  const eMailPWconfirm = () => {
    if (!confirmAGB) {
      setConfirmAGBTest(true);
    } else if (confirmAGB) {
      setConfirmAGBTest(false);
    }
    if (!re.test(regEmail)) {
      setEMailValid(false);
    } else if (re.test(regEmail)) {
      setEMailValid(true);
    }

    if (regPasswort.trim() == "") {
      setPasswortValid(false);
    } else if (regPasswort.trim() != "") {
      setPasswortValid(true);
    }
    if (regPasswortRepeat.trim() == "") {
      setPasswortRepValid(false);
    } else if (regPasswort != regPasswortRepeat && regPasswortRepeat != "") {
      setPasswortRepValid(true);
      setPasswortNotDuplicated(false);
    } else if (regPasswortRepeat.trim() != "") {
      setPasswortRepValid(true);
      setPasswortNotDuplicated(true);
    }

    if (
      eMailValid &&
      passwortValid &&
      passwortRepValid &&
      passwortNotDuplicated &&
      re.test(regEmail) &&
      regPasswort.trim() != "" &&
      regPasswortRepeat.trim() != "" &&
      regPasswort == regPasswortRepeat &&
      confirmAGB
    ) {
      doDetailsHandler();
    }
  };

  const detailsConfirm = () => {
    if (regVorname.trim() == "") {
      setVornameValid(false);
    }
    if (regNachname.trim() == "") {
      setNachnameValid(false);
    }
    if (regVorname.trim() != "") {
      setVornameValid(true);
    }
    if (regNachname.trim() != "") {
      setNachnameValid(true);
    }

    if (
      vornameValid &&
      nachnameValid &&
      regVorname.trim() != "" &&
      regNachname.trim() != ""
    ) {
      doInfoHandler();
    }
  };

  const adresseConfirm = () => {
    if (regAdresse.trim() == "") {
      setAdresseValid(false);
    }
    if (regPLZ.trim() == "") {
      setPlzValid(false);
    }
    if (regOrt.trim() == "") {
      setOrtValid(false);
    }
    if (regLand.trim() == "") {
      setLandValid(false);
    }

    if (regAdresse.trim() != "") {
      setAdresseValid(true);
    }
    if (regPLZ.trim() != "") {
      setPlzValid(true);
    }
    if (regOrt.trim() != "") {
      setOrtValid(true);
    }
    if (regLand.trim() != "") {
      setLandValid(true);
    }

    if (
      regAdresse &&
      regPLZ &&
      regOrt.trim() != "" &&
      regLand.trim() != "" &&
      vornameValid &&
      nachnameValid &&
      regVorname.trim() != "" &&
      regNachname.trim() != "" &&
      eMailValid &&
      passwortValid &&
      passwortRepValid &&
      passwortNotDuplicated &&
      re.test(regEmail) &&
      regPasswort.trim() != "" &&
      regPasswortRepeat.trim() != "" &&
      regPasswort == regPasswortRepeat
    ) {
      doDoneHandler();

      /*   toast.success(`${t("RegistrierungErfolgreichToast")}`, {
        toastId: "custom-id-yes",
        position: "bottom-center",
        autoClose: 30000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        transition: Zoom,
      }); */
    } else {
      if (
        !re.test(regEmail) ||
        regPasswort.trim() == "" ||
        regPasswortRepeat.trim() == "" ||
        regPasswort != regPasswortRepeat
      ) {
        doEmailPWHandler();
      } else if (regVorname.trim() == "" || regNachname.trim() == "") {
        doDetailsHandler();
      } else if (
        regAdresse.trim() == "" ||
        regPLZ.trim() == "" ||
        regOrt.trim() == "" ||
        regLand.trim() == ""
      ) {
        doInfoHandler();
      } else {
        doEmailPWHandler();
      }
    }
  };

  const doRegEmailHandler = (e) => {
    setRegEmail(e.target.value);
  };
  const doRegPasswortHandler = (e) => {
    setRegPasswort(e.target.value);
  };

  const doRegPasswortRepeatHandler = (e) => {
    setRegPasswortRepeat(e.target.value);
    if (regPasswort == e.target.value && e.target.value != "") {
      setPasswortNotDuplicated(true);
    }
  };

  const doRegVornameHandler = (e) => {
    setRegVorname(e.target.value);
  };

  const doRegNachnameHandler = (e) => {
    setRegNachname(e.target.value);
  };

  const doRegFirmaHandler = (e) => {
    setRegFirma(e.target.value);
  };

  const doRegHomepageHandler = (e) => {
    setRegHomepage(e.target.value);
  };

  const doRegAdresseHandler = (e) => {
    setRegAdresse(e.target.value);
  };

  const doRegPLZHandler = (e) => {
    setRegPLZ(e.target.value);
  };

  const doRegOrtHandler = (e) => {
    setRegOrt(e.target.value);
  };

  const doRegLandHandler = (e) => {
    setRegLand(e.label);
  };

  const doEmailPWHandler = () => {
    setDoEmailPW(true);
    setDoDetails(false);
    setDoInfo(false);
    setDoDone(false);
  };

  const doDetailsHandler = () => {
    setDoEmailPW(false);
    setDoDetails(true);
    setDoInfo(false);
    setDoDone(false);
  };

  const doInfoHandler = () => {
    setDoEmailPW(false);
    setDoDetails(false);
    setDoInfo(true);
    setDoDone(false);
  };

  const doDoneHandler = () => {
    const activeUrl = window.location.origin;
    axios({
      method: "POST",
      url: `${process.env.REACT_APP_PUBLIC_SERVER_URL}/auth/signup`,
      data: {
        email: regEmail,
        password: regPasswort,
        vorname: regVorname,
        nachname: regNachname,
        firma: regFirma,
        homepage: regHomepage,
        adresse: regAdresse,
        plz: regPLZ,
        ort: regOrt,
        land: regLand,
        url: activeUrl,
        logo: DB_TopBarLogoImageSrc,
      },
    })
      .then((response) => {
        if (response.status === 200) {
          setDoEmailPW(false);
          setDoDetails(false);
          setDoInfo(false);
          setDoDone(true);
          toast.success(`${t("RegistrierungErfolgreichToast")}`, {
            toastId: "custom-id-yes",
            position: "top-center",
            autoClose: 3600000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            transition: Zoom,
          });
        } else if (response.status == "201") {
          toast.error(`${t("EmailVergeben")}`, {
            toastId: "custom-id-yes",
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            transition: Zoom,
          });
        } else if (response.status == "202") {
          toast.error(`${t("EmailZuKlein")}`, {
            toastId: "custom-id-yes",
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            transition: Zoom,
          });
        } else if (response.status == "203") {
          toast.error(`${t("EmailLeer")}`, {
            toastId: "custom-id-yes",
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            transition: Zoom,
          });
        } else if (response.status == "204") {
          toast.error(`${t("KeineVerbindungZumServer")}`, {
            toastId: "custom-id-yes",
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            transition: Zoom,
          });
        } else {
          toast.error(`${t("KeineVerbindungZumServer")}`, {
            toastId: "custom-id-yes",
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            transition: Zoom,
          });
        }
      })
      .catch((error) => {
        toast.error(`${t("KeineVerbindungZumServer")}`, {
          toastId: "custom-id-yes",
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          transition: Zoom,
        });
      });
  };

  return (
    <>
      <div className="regloginRegisterIcons">
        <div
          /*   onClick={doEmailPWHandler} */
          className={
            doEmailPW
              ? "registerLoginNavIconActive"
              : doDetails || doInfo || doDone
              ? "registerLoginNavIconCompleted"
              : "registerLoginNavIcon"
          }
        >
          <span
            className={
              doEmailPW
                ? "singleIconRegActive"
                : doDetails || doInfo || doDone
                ? "singleIconRegCompleted"
                : "singleIconReg"
            }
          >
            <LockIcon style={{ height: "21px", marginBottom: "5px" }} />
          </span>
          <span
            className={
              doEmailPW
                ? "singleIconTextRegActive"
                : doDetails || doInfo || doDone
                ? "singleIconTextRegCompleted"
                : "singleIconTextReg"
            }
          >
            {t("Benutzer")}
          </span>
        </div>
        {doDetails || doInfo || doDone ? (
          <div className="nextRegArrowCompleted">→</div>
        ) : (
          <div className="nextRegArrow">→</div>
        )}

        <div
          /*   onClick={doEmailPWHandler} */
          className={
            doDetails
              ? "registerLoginNavIconActive"
              : doInfo || doDone
              ? "registerLoginNavIconCompleted"
              : "registerLoginNavIcon"
          }
        >
          <span
            className={
              doDetails
                ? "singleIconRegActive"
                : doInfo || doDone
                ? "singleIconRegCompleted"
                : "singleIconReg"
            }
          >
            <PersonIcon style={{ height: "21px", marginBottom: "5px" }} />
          </span>
          <span
            className={
              doDetails
                ? "singleIconTextRegActive"
                : doInfo || doDone
                ? "singleIconTextRegCompleted"
                : "singleIconTextReg"
            }
          >
            {t("Details")}
          </span>
        </div>

        {doInfo || doDone ? (
          <div className="nextRegArrowCompleted">→</div>
        ) : (
          <div className="nextRegArrow">→</div>
        )}

        <div
          /*   onClick={doEmailPWHandler} */
          className={
            doInfo
              ? "registerLoginNavIconActive"
              : doDone
              ? "registerLoginNavIconCompleted"
              : "registerLoginNavIcon"
          }
        >
          <span
            className={
              doInfo
                ? "singleIconRegActive"
                : doDone
                ? "singleIconRegCompleted"
                : "singleIconReg"
            }
          >
            <HomeIcon style={{ height: "21px", marginBottom: "5px" }} />
          </span>
          <span
            className={
              doInfo
                ? "singleIconTextRegActive"
                : doDone
                ? "singleIconTextRegCompleted"
                : "singleIconTextReg"
            }
          >
            {t("Adresse")}
          </span>
        </div>
        {doDone ? (
          <div className="nextRegArrowCompleted">→</div>
        ) : (
          <div className="nextRegArrow">→</div>
        )}

        <div
          /*   onClick={doEmailPWHandler} */
          className={
            doDone ? "registerLoginNavIconCompleted" : "registerLoginNavIcon"
          }
        >
          <span className={doDone ? "singleIconRegCompleted" : "singleIconReg"}>
            <CloudDoneIcon style={{ height: "21px", marginBottom: "5px" }} />
          </span>
          <span
            className={
              doDone ? "singleIconTextRegCompleted" : "singleIconTextReg"
            }
          >
            {t("Fertig")}
          </span>
        </div>
      </div>

      {doEmailPW && (
        <RegisterEmailPW
          AGBsConfirmHandler={AGBsConfirmHandler}
          confirmAGB={confirmAGB}
          confirmAGBTest={confirmAGBTest}
          regEmail={regEmail}
          doRegEmailHandler={doRegEmailHandler}
          regPasswort={regPasswort}
          doRegPasswortHandler={doRegPasswortHandler}
          regPasswortRepeat={regPasswortRepeat}
          doRegPasswortRepeatHandler={doRegPasswortRepeatHandler}
          eMailPWconfirm={eMailPWconfirm}
          eMailValid={eMailValid}
          passwortValid={passwortValid}
          passwortRepValid={passwortRepValid}
          passwortNotDuplicated={passwortNotDuplicated}
        />
      )}
      {doDetails && (
        <RegisterDetails
          regVorname={regVorname}
          doRegVornameHandler={doRegVornameHandler}
          regNachname={regNachname}
          doRegNachnameHandler={doRegNachnameHandler}
          regFirma={regFirma}
          doRegFirmaHandler={doRegFirmaHandler}
          regHomepage={regHomepage}
          doRegHomepageHandler={doRegHomepageHandler}
          doEmailPWHandler={doEmailPWHandler}
          vornameValid={vornameValid}
          nachnameValid={nachnameValid}
          firmaValid={firmaValid}
          homepageValid={homepageValid}
          detailsConfirm={detailsConfirm}
        />
      )}
      {doInfo && (
        <RegisterInfos
          regAdresse={regAdresse}
          doRegAdresseHandler={doRegAdresseHandler}
          regPLZ={regPLZ}
          doRegPLZHandler={doRegPLZHandler}
          regOrt={regOrt}
          doRegOrtHandler={doRegOrtHandler}
          regLand={regLand}
          doRegLandHandler={doRegLandHandler}
          adresseValid={adresseValid}
          plzValid={plzValid}
          ortValid={ortValid}
          landValid={landValid}
          adresseConfirm={adresseConfirm}
          doDetailsHandler={doDetailsHandler}
        />
      )}
      {doDone && <RegisterDone />}
    </>
  );
};

export default Register;
