import { initReactI18next } from "react-i18next";
import i18n from "i18next";
import translationEN from "./locales/en/translation.json";
import translationDE from "./locales/de/translation.json";

//translations
const resources = {
  de: {
    translation: translationDE,
  },
};
i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "de",
    fallbackLng: "de", // use en if detected lng is not available
    debug: true,

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
