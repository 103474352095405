import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";

const BodyProductDetailKontaktFormular = ({
  DB_fontFace_chooseFontHaupttitelFontFamily_FontFamilyName,
  SideBarLeft_ContainerDetail_Farbe_Groesse_Stil_OnMouseOverAndOut,
  SideBarLeft_ContainerDetail_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut,
  DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_FontWeight,
  DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_FontColor,
  DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_FontSize,
  DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BackgroundColor,
  DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_TextAlign,
  DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderTop,
  DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderStyleTop,
  DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderColorTop,
  DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderBottom,
  DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderStyleBottom,
  DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderColorBottom,
  DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderLeft,
  DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderStyleLeft,
  DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderColorLeft,
  DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderRight,
  DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderStyleRight,
  DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderColorRight,
  DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderTopRightRadius,
  DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderTopLeftRadius,
  DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderBottomRightRadius,
  DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderBottomLeftRadius,
  DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_marginLeftRight,
  DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_marginTopBottom,
  DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_paddingLeftRight,
  DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_paddingTopBottom,
  DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_letterSpacing,
  DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_ShadowHorizontal,
  DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_ShadowVertical,
  DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_ShadowBlur,
  DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_ShadowColor,
  DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_TextDecoration,
  DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_TextDecorationStyle,
}) => {
  const { t, i18n } = useTranslation();

  const useStyles = createUseStyles({
    productDetailItemHeaderTitel: {
      fontFamily: `${DB_fontFace_chooseFontHaupttitelFontFamily_FontFamilyName}`,
      fontWeight: `${DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_FontWeight}`,
      color: `${DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_FontColor}`,
      fontSize: `${DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_FontSize}px`,
      backgroundColor: `${
        SideBarLeft_ContainerDetail_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut
          ? "rgba(255,0,0,0.5)"
          : DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BackgroundColor
      }`,
      textAlign: `${DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_TextAlign}`,
      borderTop: `${
        SideBarLeft_ContainerDetail_Farbe_Groesse_Stil_OnMouseOverAndOut
          ? "3"
          : DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderTop
      }px
        ${
          SideBarLeft_ContainerDetail_Farbe_Groesse_Stil_OnMouseOverAndOut
            ? "solid"
            : DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderStyleTop
        }
        ${
          SideBarLeft_ContainerDetail_Farbe_Groesse_Stil_OnMouseOverAndOut
            ? "red"
            : DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderColorTop
        }`,
      borderBottom: `${
        SideBarLeft_ContainerDetail_Farbe_Groesse_Stil_OnMouseOverAndOut
          ? "3"
          : DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderBottom
      }px
        ${
          SideBarLeft_ContainerDetail_Farbe_Groesse_Stil_OnMouseOverAndOut
            ? "solid"
            : DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderStyleBottom
        }
        ${
          SideBarLeft_ContainerDetail_Farbe_Groesse_Stil_OnMouseOverAndOut
            ? "red"
            : DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderColorBottom
        }`,
      borderLeft: `${
        SideBarLeft_ContainerDetail_Farbe_Groesse_Stil_OnMouseOverAndOut
          ? "3"
          : DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderLeft
      }px
        ${
          SideBarLeft_ContainerDetail_Farbe_Groesse_Stil_OnMouseOverAndOut
            ? "solid"
            : DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderStyleLeft
        }
        ${
          SideBarLeft_ContainerDetail_Farbe_Groesse_Stil_OnMouseOverAndOut
            ? "red"
            : DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderColorLeft
        }`,
      borderRight: `${
        SideBarLeft_ContainerDetail_Farbe_Groesse_Stil_OnMouseOverAndOut
          ? "3"
          : DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderRight
      }px
        ${
          SideBarLeft_ContainerDetail_Farbe_Groesse_Stil_OnMouseOverAndOut
            ? "solid"
            : DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderStyleRight
        }
        ${
          SideBarLeft_ContainerDetail_Farbe_Groesse_Stil_OnMouseOverAndOut
            ? "red"
            : DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderColorRight
        }`,
      borderTopRightRadius: `${DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderTopRightRadius}px`,
      borderTopLeftRadius: `${DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderTopLeftRadius}px`,
      borderBottomRightRadius: `${DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderBottomRightRadius}px`,
      borderBottomLeftRadius: `${DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderBottomLeftRadius}px`,
      marginLeft: `${DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_marginLeftRight}px`,
      marginRight: `${DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_marginLeftRight}px`,
      marginTop: `${DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_marginTopBottom}px`,
      marginBottom: `${DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_marginTopBottom}px`,
      paddingLeft: `${DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_paddingLeftRight}px`,
      paddingRight: `${DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_paddingLeftRight}px`,
      paddingTop: `${DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_paddingTopBottom}px`,
      paddingBottom: `${DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_paddingTopBottom}px`,
      letterSpacing: `${DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_letterSpacing}px`,
      textShadow: `${DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_ShadowHorizontal}px
        ${DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_ShadowVertical}px
        ${DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_ShadowBlur}px
        ${DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_ShadowColor}`,
      textDecoration: `${DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_TextDecoration}
        ${DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_TextDecorationStyle}`,
      cursor: "pointer",
    },
  });

  const classes = useStyles();

  return (
    <div className={`${classes.productDetailItemHeaderTitel} mt-3`}>
      {t("Kontaktaufnahme")}:
    </div>
  );
};

export default BodyProductDetailKontaktFormular;
