import { createUseStyles } from "react-jss";

const TopBarStaticContainer = (props) => {
  const useStyles = createUseStyles({
    staticMenueTopContainer: {
      backgroundColor: `${
        props.SideBarLeft_ContainerTopXYZ_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut
          ? "rgba(255,0,0,0.5)"
          : props.SideBarLeft_ContainerTopXYZ_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut
          ? "rgba(255,0,0,0.3)"
          : props.DB_LeftSidebar_MenuContainerXYZ_BackgroundColor
      }`,
      textAlign: `right`,
      paddingTop: `${props.DB_LeftSidebar_MenuContainerXYZ_PaddingTopBottom}px`,
      paddingBottom: `${props.DB_LeftSidebar_MenuContainerXYZ_PaddingTopBottom}px`,
      paddingLeft: `${props.DB_LeftSidebar_MenuContainerXYZ_PaddingLeftRight}px`,
      paddingRight: `${props.DB_LeftSidebar_MenuContainerXYZ_PaddingLeftRight}px`,
      borderTop: `${
        props.SideBarLeft_ContainerTopXYZ_Farbe_Groesse_Stil_OnMouseOverAndOut
          ? "3"
          : props.SideBarLeft_ContainerTopXYZ_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
          ? "3"
          : props.DB_LeftSidebar_MenuContainerXYZ_BorderTop
      }px
          ${
            props.SideBarLeft_ContainerTopXYZ_Farbe_Groesse_Stil_OnMouseOverAndOut
              ? "solid"
              : props.SideBarLeft_ContainerTopXYZ_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
              ? "dotted"
              : props.DB_LeftSidebar_MenuContainerXYZ_BorderStyleTop
          }
          ${
            props.SideBarLeft_ContainerTopXYZ_Farbe_Groesse_Stil_OnMouseOverAndOut
              ? "red"
              : props.SideBarLeft_ContainerTopXYZ_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
              ? "red"
              : props.DB_LeftSidebar_MenuContainerXYZ_BorderColorTop
          }`,
      borderBottom: `${
        props.SideBarLeft_ContainerTopXYZ_Farbe_Groesse_Stil_OnMouseOverAndOut
          ? "3"
          : props.SideBarLeft_ContainerTopXYZ_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
          ? "3"
          : props.DB_LeftSidebar_MenuContainerXYZ_BorderBottom
      }px
          ${
            props.SideBarLeft_ContainerTopXYZ_Farbe_Groesse_Stil_OnMouseOverAndOut
              ? "solid"
              : props.SideBarLeft_ContainerTopXYZ_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
              ? "dotted"
              : props.DB_LeftSidebar_MenuContainerXYZ_BorderStyleBottom
          }
          ${
            props.SideBarLeft_ContainerTopXYZ_Farbe_Groesse_Stil_OnMouseOverAndOut
              ? "red"
              : props.SideBarLeft_ContainerTopXYZ_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
              ? "red"
              : props.DB_LeftSidebar_MenuContainerXYZ_BorderColorBottom
          }`,
      borderLeft: `${
        props.SideBarLeft_ContainerTopXYZ_Farbe_Groesse_Stil_OnMouseOverAndOut
          ? "3"
          : props.SideBarLeft_ContainerTopXYZ_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
          ? "3"
          : props.DB_LeftSidebar_MenuContainerXYZ_BorderLeft
      }px
          ${
            props.SideBarLeft_ContainerTopXYZ_Farbe_Groesse_Stil_OnMouseOverAndOut
              ? "solid"
              : props.SideBarLeft_ContainerTopXYZ_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
              ? "dotted"
              : props.DB_LeftSidebar_MenuContainerXYZ_BorderStyleLeft
          }
          ${
            props.SideBarLeft_ContainerTopXYZ_Farbe_Groesse_Stil_OnMouseOverAndOut
              ? "red"
              : props.SideBarLeft_ContainerTopXYZ_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
              ? "red"
              : props.DB_LeftSidebar_MenuContainerXYZ_BorderColorLeft
          }`,
      borderRight: `${
        props.SideBarLeft_ContainerTopXYZ_Farbe_Groesse_Stil_OnMouseOverAndOut
          ? "3"
          : props.SideBarLeft_ContainerTopXYZ_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
          ? "3"
          : props.DB_LeftSidebar_MenuContainerXYZ_BorderRight
      }px
          ${
            props.SideBarLeft_ContainerTopXYZ_Farbe_Groesse_Stil_OnMouseOverAndOut
              ? "solid"
              : props.SideBarLeft_ContainerTopXYZ_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
              ? "dotted"
              : props.DB_LeftSidebar_MenuContainerXYZ_BorderStyleRight
          }
          ${
            props.SideBarLeft_ContainerTopXYZ_Farbe_Groesse_Stil_OnMouseOverAndOut
              ? "red"
              : props.SideBarLeft_ContainerTopXYZ_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
              ? "red"
              : props.DB_LeftSidebar_MenuContainerXYZ_BorderColorRight
          }`,
      borderTopRightRadius: `${props.DB_LeftSidebar_MenuItem_MenuContainerXYZ_BorderTopRightRadius}px`,
      borderTopLeftRadius: `${props.DB_LeftSidebar_MenuItem_MenuContainerXYZ_BorderTopLeftRadius}px`,
      borderBottomRightRadius: `${props.DB_LeftSidebar_MenuItem_MenuContainerXYZ_BorderBottomRightRadius}px`,
      borderBottomLeftRadius: `${props.DB_LeftSidebar_MenuItem_MenuContainerXYZ_BorderBottomLeftRadius}px`,
      paddingLeft: `${props.DB_LeftSidebar_MenuItem_MenuContainerXYZ_paddingLeft}px`,
      paddingRight: `${props.DB_LeftSidebar_MenuItem_MenuContainerXYZ_paddingRight}px`,
      paddingTop: `${props.DB_LeftSidebar_MenuItem_MenuContainerXYZ_paddingTop}px`,
      paddingBottom: `${props.DB_LeftSidebar_MenuItem_MenuContainerXYZ_paddingBottom}px`,
      boxShadow: `${props.DB_LeftSidebar_MenuItem_MenuContainerXYZ_ShadowHorizontal}px
          ${props.DB_LeftSidebar_MenuItem_MenuContainerXYZ_ShadowVertical}px
          ${props.DB_LeftSidebar_MenuItem_MenuContainerXYZ_ShadowBlur}px
          ${props.DB_LeftSidebar_MenuItem_MenuContainerXYZ_ShadowColor}`,
      display: `block`,
      "&:hover": {
        backgroundColor: `${props.DB_LeftSidebar_MenuContainerXYZ_Hover_BackgroundColor}`,
        textAlign: `left`,
        paddingTop: `${props.DB_LeftSidebar_MenuContainerXYZ_Hover_PaddingTopBottom}px`,
        paddingBottom: `${props.DB_LeftSidebar_MenuContainerXYZ_Hover_PaddingTopBottom}px`,
        paddingLeft: `${props.DB_LeftSidebar_MenuContainerXYZ_Hover_PaddingLeftRight}px`,
        paddingRight: `${props.DB_LeftSidebar_MenuContainerXYZ_Hover_PaddingLeftRight}px`,
        borderTop: `${props.DB_LeftSidebar_MenuContainerXYZ_Hover_BorderTop}px
          ${props.DB_LeftSidebar_MenuContainerXYZ_Hover_BorderStyleTop}
          ${props.DB_LeftSidebar_MenuContainerXYZ_Hover_BorderColorTop}`,
        borderBottom: `${props.DB_LeftSidebar_MenuContainerXYZ_Hover_BorderBottom}px
          ${props.DB_LeftSidebar_MenuContainerXYZ_Hover_BorderStyleBottom}
          ${props.DB_LeftSidebar_MenuContainerXYZ_Hover_BorderColorBottom}`,
        borderLeft: `${props.DB_LeftSidebar_MenuContainerXYZ_Hover_BorderLeft}px
          ${props.DB_LeftSidebar_MenuContainerXYZ_Hover_BorderStyleLeft}
          ${props.DB_LeftSidebar_MenuContainerXYZ_Hover_BorderColorLeft}`,
        borderRight: `${props.DB_LeftSidebar_MenuContainerXYZ_Hover_BorderRight}px
          ${props.DB_LeftSidebar_MenuContainerXYZ_Hover_BorderStyleRight}
          ${props.DB_LeftSidebar_MenuContainerXYZ_Hover_BorderColorRight}`,
        borderTopRightRadius: `${props.DB_LeftSidebar_MenuItem_MenuContainerXYZ_Hover_BorderTopRightRadius}px`,
        borderTopLeftRadius: `${props.DB_LeftSidebar_MenuItem_MenuContainerXYZ_Hover_BorderTopLeftRadius}px`,
        borderBottomRightRadius: `${props.DB_LeftSidebar_MenuItem_MenuContainerXYZ_Hover_BorderBottomRightRadius}px`,
        borderBottomLeftRadius: `${props.DB_LeftSidebar_MenuItem_MenuContainerXYZ_Hover_BorderBottomLeftRadius}px`,
        paddingLeft: `${props.DB_LeftSidebar_MenuItem_MenuContainerXYZ_Hover_paddingLeft}px`,
        paddingRight: `${props.DB_LeftSidebar_MenuItem_MenuContainerXYZ_Hover_paddingRight}px`,
        paddingTop: `${props.DB_LeftSidebar_MenuItem_MenuContainerXYZ_Hover_paddingTop}px`,
        paddingBottom: `${props.DB_LeftSidebar_MenuItem_MenuContainerXYZ_Hover_paddingBottom}px`,
        boxShadow: `${props.DB_LeftSidebar_MenuItem_MenuContainerXYZ_Hover_ShadowHorizontal}px
          ${props.DB_LeftSidebar_MenuItem_MenuContainerXYZ_Hover_ShadowVertical}px
          ${props.DB_LeftSidebar_MenuItem_MenuContainerXYZ_Hover_ShadowBlur}px
          ${props.DB_LeftSidebar_MenuItem_MenuContainerXYZ_Hover_ShadowColor}`,
      },
    },
  });

  const classes = useStyles();

  return (
    <div
      className={`justify-content-end navbar-nav ${classes.staticMenueTopContainer}`}
    >
      {props.children}
    </div>
  );
};

export default TopBarStaticContainer;
