import React, { useEffect } from "react";
import "/node_modules/react-image-gallery/styles/css/image-gallery.css";
import { createUseStyles } from "react-jss";

const MarqueeBoxImage = ({
  product,
  index,
  showProductDetailsHandler,
  DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderTop,
  DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderStyleTop,
  DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderColorTop,
  DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderBottom,
  DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderStyleBottom,
  DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderColorBottom,
  DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderLeft,
  DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderStyleLeft,
  DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderColorLeft,
  DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderRight,
  DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderStyleRight,
  DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderColorRight,
  DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderTopRightRadius,
  DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderTopLeftRadius,
  DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderBottomRightRadius,
  DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderBottomLeftRadius,

  DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderTop,
  DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderColorTop,
  DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderStyleTop,
  DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderColorBottom,
  DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderStyleBottom,
  DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderLeft,
  DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderColorLeft,
  DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderStyleLeft,
  DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderRight,
  DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderStyleRight,
  DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderColorRight,
  DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderTopRightRadius,
  DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderTopLeftRadius,
  DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderBottomRightRadius,
  DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderBottomLeftRadius,
  DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderBottom,
}) => {
  const useStyles = createUseStyles({
    marqueeItem: {
      borderTop: `${DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderTop}px
    ${DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderStyleTop}
    ${DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderColorTop}`,
      borderBottom: `${DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderBottom}px
    ${DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderStyleBottom}
    ${DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderColorBottom}`,
      borderLeft: `${DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderLeft}px
    ${DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderStyleLeft}
    ${DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderColorLeft}`,
      borderRight: `${DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderRight}px
    ${DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderStyleRight}
    ${DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderColorRight}`,
      borderTopRightRadius: `${DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderTopRightRadius}px`,
      borderTopLeftRadius: `${DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderTopLeftRadius}px`,
      borderBottomRightRadius: `${DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderBottomRightRadius}px`,
      borderBottomLeftRadius: `${DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderBottomLeftRadius}px`,
      "&:hover": {
        borderTop: `${DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderTop}px
      ${DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderStyleTop}
      ${DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderColorTop}`,
        borderBottom: `${DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderBottom}px
      ${DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderStyleBottom}
      ${DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderColorBottom}`,
        borderLeft: `${DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderLeft}px
      ${DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderStyleLeft}
      ${DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderColorLeft}`,
        borderRight: `${DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderRight}px
      ${DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderStyleRight}
      ${DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderColorRight}`,
        borderTopRightRadius: `${DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderTopRightRadius}px`,
        borderTopLeftRadius: `${DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderTopLeftRadius}px`,
        borderBottomRightRadius: `${DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderBottomRightRadius}px`,
        borderBottomLeftRadius: `${DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderBottomLeftRadius}px`,
      },
    },
  });

  const classes = useStyles();

  return (
    <div key={`marquee-${index}`}>
      {product.mainImage && (
        <div
          onClick={(productIdentifier) =>
            showProductDetailsHandler(product.titel)
          }
          style={{
            cursor: "pointer",
            textDecoration: "none",
            height: "90px",
          }}
        >
          <img
            className={classes.marqueeItem}
            height="90px"
            alt={"marquee" + product.titel}
            src={`${process.env.REACT_APP_PUBLIC_SERVER_URL}/${product.mainImage}`}
            position="relative"
            style={{ marginLeft: "90px" }}
          />
        </div>
      )}
    </div>
  );
};

export default MarqueeBoxImage;
