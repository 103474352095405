import { useState } from "react";
import "/node_modules/react-image-gallery/styles/css/image-gallery.css";
import { getCookie } from "../helpers/helpers";
import { createUseStyles } from "react-jss";

const FAQ = ({
  faqDetailItem,
  DB_fontFace_FontFamilyName,
  DB_fontFace_chooseFontHaupttitelFontFamily_FontFamilyName,
  DB_fontFace_chooseFontBeschreibungFontFamily_FontFamilyName,
  SideBarLeft_ContainerDetail_Farbe_Groesse_Stil_OnMouseOverAndOut,
  SideBarLeft_ContainerDetail_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut,
  SideBarLeft_DetailItem_Farbe_Groesse_Stil_OnMouseOverAndOut,
  SideBarLeft_DetailItem_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut,
  DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_FontWeight,
  DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_FontColor,
  DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_FontSize,
  DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BackgroundColor,
  DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_TextAlign,
  DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderTop,
  DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderStyleTop,
  DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderColorTop,
  DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderBottom,
  DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderStyleBottom,
  DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderColorBottom,
  DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderLeft,
  DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderStyleLeft,
  DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderColorLeft,
  DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderRight,
  DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderStyleRight,
  DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderColorRight,
  DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderTopRightRadius,
  DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderTopLeftRadius,
  DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderBottomRightRadius,
  DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderBottomLeftRadius,
  DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_marginLeftRight,
  DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_marginTopBottom,
  DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_paddingLeftRight,
  DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_paddingTopBottom,
  DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_letterSpacing,
  DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_ShadowHorizontal,
  DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_ShadowVertical,
  DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_ShadowBlur,
  DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_ShadowColor,
  DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_TextDecoration,
  DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_TextDecorationStyle,
  DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_FontWeight,
  DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_FontColor,
  DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_FontSize,
  DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BackgroundColor,
  DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_TextAlign,
  DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderTop,
  DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderStyleTop,
  DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderColorTop,
  DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderBottom,
  DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderStyleBottom,
  DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderColorBottom,
  DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderLeft,
  DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderStyleLeft,
  DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderColorLeft,
  DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderRight,
  DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderStyleRight,
  DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderColorRight,
  DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderTopRightRadius,
  DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderTopLeftRadius,
  DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderBottomRightRadius,
  DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderBottomLeftRadius,
  DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_marginLeftRight,
  DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_marginTopBottom,
  DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_paddingLeftRight,
  DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_paddingTopBottom,
  DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_letterSpacing,
  DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_ShadowHorizontal,
  DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_ShadowVertical,
  DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_ShadowBlur,
  DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_ShadowColor,
  DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_TextDecoration,
  DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_TextDecorationStyle,
}) => {
  const DB_ProductDetails_Header_Untertitel_FontSize = 16;
  const DB_ProductDetails_Header_Untertitel_FontColor = "rgba(255,255,255,1)";
  const DB_ProductDetails_Header_Untertitel_BackgroundColor = "rgba(0,0,0,0.3)";
  const DB_ProductDetails_BorderRadius = 15;
  const DB_ProductDetails_Border_Width = 1;
  const DB_ProductDetails_Border_Style = "solid";
  const DB_ProductDetails_Border_Color = "rgba(0,0,0,0.3)";
  const DB_ProductDetails_Produkbeschreibung_Header_BackgroundColor =
    "rgba(0,0,0,0.7)";
  const DB_ProductDetails_Produkbeschreibung_Header_FontColor =
    "rgba(255,255,255,1)";
  const DB_ProductDetails_Produkbeschreibung_Header_Border_Width = 1;
  const DB_ProductDetails_Produkbeschreibung_Header_Border_Color =
    "rgba(0,0,0,1)";
  const DB_ProductDetails_Produkbeschreibung_Header_Border_Style = "solid";

  const useStyles = createUseStyles({
    productDetailItemHeaderTitel: {
      fontFamily: `${DB_fontFace_chooseFontHaupttitelFontFamily_FontFamilyName}`,
      fontWeight: `${DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_FontWeight}`,
      color: `${DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_FontColor}`,
      fontSize: `${DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_FontSize}px`,
      backgroundColor: `${
        SideBarLeft_ContainerDetail_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut
          ? "rgba(255,0,0,0.5)"
          : DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BackgroundColor
      }`,
      textAlign: `${DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_TextAlign}`,
      borderTop: `${
        SideBarLeft_ContainerDetail_Farbe_Groesse_Stil_OnMouseOverAndOut
          ? "3"
          : DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderTop
      }px
        ${
          SideBarLeft_ContainerDetail_Farbe_Groesse_Stil_OnMouseOverAndOut
            ? "solid"
            : DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderStyleTop
        }
        ${
          SideBarLeft_ContainerDetail_Farbe_Groesse_Stil_OnMouseOverAndOut
            ? "red"
            : DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderColorTop
        }`,
      borderBottom: `${
        SideBarLeft_ContainerDetail_Farbe_Groesse_Stil_OnMouseOverAndOut
          ? "3"
          : DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderBottom
      }px
        ${
          SideBarLeft_ContainerDetail_Farbe_Groesse_Stil_OnMouseOverAndOut
            ? "solid"
            : DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderStyleBottom
        }
        ${
          SideBarLeft_ContainerDetail_Farbe_Groesse_Stil_OnMouseOverAndOut
            ? "red"
            : DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderColorBottom
        }`,
      borderBottom: `${
        SideBarLeft_ContainerDetail_Farbe_Groesse_Stil_OnMouseOverAndOut
          ? "3"
          : DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderBottom
      }px
      ${
        SideBarLeft_ContainerDetail_Farbe_Groesse_Stil_OnMouseOverAndOut
          ? "solid"
          : DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderStyleBottom
      }
      ${
        SideBarLeft_ContainerDetail_Farbe_Groesse_Stil_OnMouseOverAndOut
          ? "red"
          : DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderColorBottom
      }`,
      borderLeft: `${
        SideBarLeft_ContainerDetail_Farbe_Groesse_Stil_OnMouseOverAndOut
          ? "3"
          : DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderLeft
      }px
        ${
          SideBarLeft_ContainerDetail_Farbe_Groesse_Stil_OnMouseOverAndOut
            ? "solid"
            : DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderStyleLeft
        }
        ${
          SideBarLeft_ContainerDetail_Farbe_Groesse_Stil_OnMouseOverAndOut
            ? "red"
            : DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderColorLeft
        }`,
      borderRight: `${
        SideBarLeft_ContainerDetail_Farbe_Groesse_Stil_OnMouseOverAndOut
          ? "3"
          : DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderRight
      }px
        ${
          SideBarLeft_ContainerDetail_Farbe_Groesse_Stil_OnMouseOverAndOut
            ? "solid"
            : DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderStyleRight
        }
        ${
          SideBarLeft_ContainerDetail_Farbe_Groesse_Stil_OnMouseOverAndOut
            ? "red"
            : DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderColorRight
        }`,
      borderTopRightRadius: `${DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderTopRightRadius}px`,
      borderTopLeftRadius: `${DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderTopLeftRadius}px`,
      borderBottomRightRadius: `${DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderBottomRightRadius}px`,
      borderBottomLeftRadius: `${DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderBottomLeftRadius}px`,
      marginLeft: `${DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_marginLeftRight}px`,
      marginRight: `${DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_marginLeftRight}px`,
      marginTop: `${DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_marginTopBottom}px`,
      marginBottom: `${DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_marginTopBottom}px`,
      paddingLeft: `${DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_paddingLeftRight}px`,
      paddingRight: `${DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_paddingLeftRight}px`,
      paddingTop: `${DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_paddingTopBottom}px`,
      paddingBottom: `${DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_paddingTopBottom}px`,
      letterSpacing: `${DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_letterSpacing}px`,
      textShadow: `${DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_ShadowHorizontal}px
        ${DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_ShadowVertical}px
        ${DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_ShadowBlur}px
        ${DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_ShadowColor}`,
      textDecoration: `${DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_TextDecoration}
        ${DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_TextDecorationStyle}`,
      cursor: "pointer",
    },

    productDetailItemHeaderUntertitel: {
      fontFamily: `${DB_fontFace_FontFamilyName}`,
      fontSize: `${DB_ProductDetails_Header_Untertitel_FontSize}px`,
      color: `${DB_ProductDetails_Header_Untertitel_FontColor}`,
      borderRight: `${DB_ProductDetails_Border_Width}px
        ${DB_ProductDetails_Border_Style} ${DB_ProductDetails_Border_Color}`,
      borderLeft: `${DB_ProductDetails_Border_Width}px
        ${DB_ProductDetails_Border_Style} ${DB_ProductDetails_Border_Color}`,
      backgroundColor: `${DB_ProductDetails_Header_Untertitel_BackgroundColor}`,
      paddingLeft: "16px",
      paddingRight: "16px",
      paddingBottom: "5px",
      paddingTop: "5px",
      cursor: "pointer",
    },

    produktBeschreibungHeader: {
      fontFamily: `${DB_fontFace_FontFamilyName}`,
      border: `${DB_ProductDetails_Produkbeschreibung_Header_Border_Width}px
        ${DB_ProductDetails_Produkbeschreibung_Header_Border_Style}
        ${DB_ProductDetails_Produkbeschreibung_Header_Border_Color}`,
      backgroundColor: `${DB_ProductDetails_Produkbeschreibung_Header_BackgroundColor}`,
      borderTopLeftRadius: `${DB_ProductDetails_BorderRadius}px`,
      borderTopRightradius: `${DB_ProductDetails_BorderRadius}px`,
      padding: "10px",
      paddingTop: "7px",
      paddingBottom: "7px",
      color: `${DB_ProductDetails_Produkbeschreibung_Header_FontColor}`,
      fontWeight: "bold",
    },

    produktBeschreibung: {
      fontFamily: `${DB_fontFace_chooseFontBeschreibungFontFamily_FontFamilyName}`,
      fontWeight: `${DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_FontWeight}`,
      color: `${DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_FontColor}`,
      fontSize: `${DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_FontSize}px`,
      backgroundColor: `${
        SideBarLeft_DetailItem_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut
          ? "rgba(255,0,0,0.5)"
          : DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BackgroundColor
      }`,
      textAlign: `${DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_TextAlign}`,
      borderTop: `${
        SideBarLeft_DetailItem_Farbe_Groesse_Stil_OnMouseOverAndOut
          ? "3"
          : DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderTop
      }px
        ${
          SideBarLeft_DetailItem_Farbe_Groesse_Stil_OnMouseOverAndOut
            ? "solid"
            : DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderStyleTop
        }
        ${
          SideBarLeft_DetailItem_Farbe_Groesse_Stil_OnMouseOverAndOut
            ? "red"
            : DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderColorTop
        }`,
      borderBottom: `${
        SideBarLeft_DetailItem_Farbe_Groesse_Stil_OnMouseOverAndOut
          ? "3"
          : DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderBottom
      }px
        ${
          SideBarLeft_DetailItem_Farbe_Groesse_Stil_OnMouseOverAndOut
            ? "solid"
            : DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderStyleBottom
        }
        ${
          SideBarLeft_DetailItem_Farbe_Groesse_Stil_OnMouseOverAndOut
            ? "red"
            : DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderColorBottom
        }`,
      borderLeft: `${
        SideBarLeft_DetailItem_Farbe_Groesse_Stil_OnMouseOverAndOut
          ? "3"
          : DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderLeft
      }px
        ${
          SideBarLeft_DetailItem_Farbe_Groesse_Stil_OnMouseOverAndOut
            ? "solid"
            : DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderStyleLeft
        }
        ${
          SideBarLeft_DetailItem_Farbe_Groesse_Stil_OnMouseOverAndOut
            ? "red"
            : DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderColorLeft
        }`,
      borderRight: `${
        SideBarLeft_DetailItem_Farbe_Groesse_Stil_OnMouseOverAndOut
          ? "3"
          : DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderRight
      }px
        ${
          SideBarLeft_DetailItem_Farbe_Groesse_Stil_OnMouseOverAndOut
            ? "solid"
            : DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderStyleRight
        }
        ${
          SideBarLeft_DetailItem_Farbe_Groesse_Stil_OnMouseOverAndOut
            ? "red"
            : DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderColorRight
        }`,
      borderTopRightRadius: `${DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderTopRightRadius}px`,
      borderTopLeftRadius: `${DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderTopLeftRadius}px`,
      borderBottomRightRadius: `${DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderBottomRightRadius}px`,
      borderBottomLeftRadius: `${DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderBottomLeftRadius}px`,
      marginLeft: `${DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_marginLeftRight}px`,
      marginRight: `${DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_marginLeftRight}px`,
      marginTop: `${DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_marginTopBottom}px`,
      marginBottom: "20px",
      paddingLeft: `${DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_paddingLeftRight}px`,
      paddingRight: `${DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_paddingLeftRight}px`,
      paddingTop: `${DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_paddingTopBottom}px`,
      paddingBottom: `${DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_paddingTopBottom}px`,
      letterSpacing: `${DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_letterSpacing}px`,
      textShadow: `${DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_ShadowHorizontal}px
        ${DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_ShadowVertical}px
        ${DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_ShadowBlur}px
        ${DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_ShadowColor}`,
      textDecoration: `${DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_TextDecoration}
        ${DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_TextDecorationStyle}`,
    },
  });

  const classes = useStyles();

  return (
    <div className={"container"}>
      {faqDetailItem &&
        (faqDetailItem.faqOneTitel || faqDetailItem.faqOneContent) && (
          <div className="row">
            <div className={"col-lg-12 mt-3 col-12"}>
              {faqDetailItem && faqDetailItem.faqOneTitel && (
                <div className={classes.productDetailItemHeaderTitel}>
                  {faqDetailItem.faqOneTitel}
                </div>
              )}{" "}
              {faqDetailItem && faqDetailItem.faqOneContent && (
                <div className={classes.produktBeschreibung}>
                  <div>{faqDetailItem.faqOneContent}</div>
                </div>
              )}
            </div>
          </div>
        )}
      {faqDetailItem &&
        (faqDetailItem.faqTwoTitel || faqDetailItem.faqTwoContent) && (
          <div className="row">
            <div className={"col-lg-12 mt-3 col-12"}>
              {faqDetailItem && faqDetailItem.faqTwoTitel && (
                <div className={classes.productDetailItemHeaderTitel}>
                  {faqDetailItem.faqTwoTitel}
                </div>
              )}{" "}
              {faqDetailItem && faqDetailItem.faqTwoContent && (
                <div className={classes.produktBeschreibung}>
                  <div>{faqDetailItem.faqTwoContent}</div>
                </div>
              )}
            </div>
          </div>
        )}{" "}
      {faqDetailItem &&
        (faqDetailItem.faqThreeTitel || faqDetailItem.faqThreeContent) && (
          <div className="row">
            <div className={"col-lg-12 mt-3 col-12"}>
              {faqDetailItem && faqDetailItem.faqThreeTitel && (
                <div className={classes.productDetailItemHeaderTitel}>
                  {faqDetailItem.faqThreeTitel}
                </div>
              )}{" "}
              {faqDetailItem && faqDetailItem.faqThreeContent && (
                <div className={classes.produktBeschreibung}>
                  <div>{faqDetailItem.faqThreeContent}</div>
                </div>
              )}
            </div>
          </div>
        )}{" "}
      {faqDetailItem &&
        (faqDetailItem.faqFourTitel || faqDetailItem.faqFourContent) && (
          <div className="row">
            <div className={"col-lg-12 mt-3 col-12"}>
              {faqDetailItem && faqDetailItem.faqFourTitel && (
                <div className={classes.productDetailItemHeaderTitel}>
                  {faqDetailItem.faqFourTitel}
                </div>
              )}{" "}
              {faqDetailItem && faqDetailItem.faqFourContent && (
                <div className={classes.produktBeschreibung}>
                  <div>{faqDetailItem.faqFourContent}</div>
                </div>
              )}
            </div>
          </div>
        )}{" "}
      {faqDetailItem &&
        (faqDetailItem.faqFiveTitel || faqDetailItem.faqFiveContent) && (
          <div className="row">
            <div className={"col-lg-12 mt-3 col-12"}>
              {faqDetailItem && faqDetailItem.faqFiveTitel && (
                <div className={classes.productDetailItemHeaderTitel}>
                  {faqDetailItem.faqFiveTitel}
                </div>
              )}{" "}
              {faqDetailItem && faqDetailItem.faqFiveContent && (
                <div className={classes.produktBeschreibung}>
                  <div>{faqDetailItem.faqFiveContent}</div>
                </div>
              )}
            </div>
          </div>
        )}
      {faqDetailItem &&
        (faqDetailItem.faqSixTitel || faqDetailItem.faqSixContent) && (
          <div className="row">
            <div className={"col-lg-12 mt-3 col-12"}>
              {faqDetailItem && faqDetailItem.faqSixTitel && (
                <div className={classes.productDetailItemHeaderTitel}>
                  {faqDetailItem.faqSixTitel}
                </div>
              )}{" "}
              {faqDetailItem && faqDetailItem.faqSixContent && (
                <div className={classes.produktBeschreibung}>
                  <div>{faqDetailItem.faqSixContent}</div>
                </div>
              )}
            </div>
          </div>
        )}
      {faqDetailItem &&
        (faqDetailItem.faqSevenTitel || faqDetailItem.faqSevenContent) && (
          <div className="row">
            <div className={"col-lg-12 mt-3 col-12"}>
              {faqDetailItem && faqDetailItem.faqSevenTitel && (
                <div className={classes.productDetailItemHeaderTitel}>
                  {faqDetailItem.faqSevenTitel}
                </div>
              )}{" "}
              {faqDetailItem && faqDetailItem.faqSevenContent && (
                <div className={classes.produktBeschreibung}>
                  <div>{faqDetailItem.faqSevenContent}</div>
                </div>
              )}
            </div>
          </div>
        )}{" "}
      {faqDetailItem &&
        (faqDetailItem.faqEightTitel || faqDetailItem.faqEightContent) && (
          <div className="row">
            <div className={"col-lg-12 mt-3 col-12"}>
              {faqDetailItem && faqDetailItem.faqEightTitel && (
                <div className={classes.productDetailItemHeaderTitel}>
                  {faqDetailItem.faqEightTitel}
                </div>
              )}{" "}
              {faqDetailItem && faqDetailItem.faqEightContent && (
                <div className={classes.produktBeschreibung}>
                  <div>{faqDetailItem.faqEightContent}</div>
                </div>
              )}
            </div>
          </div>
        )}{" "}
      {faqDetailItem &&
        (faqDetailItem.faqNineTitel || faqDetailItem.faqNineContent) && (
          <div className="row">
            <div className={"col-lg-12 mt-3 col-12"}>
              {faqDetailItem && faqDetailItem.faqNineTitel && (
                <div className={classes.productDetailItemHeaderTitel}>
                  {faqDetailItem.faqNineTitel}
                </div>
              )}{" "}
              {faqDetailItem && faqDetailItem.faqNineContent && (
                <div className={classes.produktBeschreibung}>
                  <div>{faqDetailItem.faqNineContent}</div>
                </div>
              )}
            </div>
          </div>
        )}{" "}
      {faqDetailItem &&
        (faqDetailItem.faqTenTitel || faqDetailItem.faqTenContent) && (
          <div className="row">
            <div className={"col-lg-12 mt-3 col-12"}>
              {faqDetailItem && faqDetailItem.faqTenTitel && (
                <div className={classes.productDetailItemHeaderTitel}>
                  {faqDetailItem.faqTenTitel}
                </div>
              )}
              {faqDetailItem && faqDetailItem.faqTenContent && (
                <div className={classes.produktBeschreibung}>
                  <div>{faqDetailItem.faqTenContent}</div>
                </div>
              )}
            </div>
          </div>
        )}{" "}
      {faqDetailItem &&
        (faqDetailItem.faqElevenTitel || faqDetailItem.faqElevenContent) && (
          <div className="row">
            <div className={"col-lg-12 mt-3 col-12"}>
              {faqDetailItem && faqDetailItem.faqElevenTitel && (
                <div className={classes.productDetailItemHeaderTitel}>
                  {faqDetailItem.faqElevenTitel}
                </div>
              )}{" "}
              {faqDetailItem && faqDetailItem.faqElevenContent && (
                <div className={classes.produktBeschreibung}>
                  <div>{faqDetailItem.faqElevenContent}</div>
                </div>
              )}
            </div>
          </div>
        )}{" "}
      {faqDetailItem &&
        (faqDetailItem.faqTwelfeTitel || faqDetailItem.faqTwelfeContent) && (
          <div className="row">
            <div className={"col-lg-12 mt-3 col-12"}>
              {faqDetailItem && faqDetailItem.faqTwelfeTitel && (
                <div className={classes.productDetailItemHeaderTitel}>
                  {faqDetailItem.faqTwelfeTitel}
                </div>
              )}
              {faqDetailItem && faqDetailItem.faqTwelfeContent && (
                <div className={classes.produktBeschreibung}>
                  <div>{faqDetailItem.faqTwelfeContent}</div>
                </div>
              )}
            </div>
          </div>
        )}
    </div>
  );
};

export default FAQ;
