import { createUseStyles } from "react-jss";

const SideBarRightSocialIconsText = ({
  CustomSocialMessage,
  SideBarLeft_SocialItem_Farbe_Groesse_Stil_OnMouseOverAndOut,
  SideBarLeft_SocialItem_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut,
  SideBarLeft_SocialItem_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut,
  SideBarLeft_SocialItem_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut,

  DB_fontFace_SocialTextFontFamily_FontFamilyName,
  DB_fontFace_SocialTextFontFamily_FontFamilySrc,
  DB_LeftSidebar_MenuItem_SocialText_FontWeight,
  DB_LeftSidebar_MenuItem_SocialText_FontColor,
  DB_LeftSidebar_MenuItem_SocialText_FontSize,
  DB_LeftSidebar_MenuItem_SocialText_BackgroundColor,
  DB_LeftSidebar_MenuItem_SocialText_BorderTop,
  DB_LeftSidebar_MenuItem_SocialText_BorderStyleTop,
  DB_LeftSidebar_MenuItem_SocialText_BorderColorTop,
  DB_LeftSidebar_MenuItem_SocialText_BorderBottom,
  DB_LeftSidebar_MenuItem_SocialText_BorderStyleBottom,
  DB_LeftSidebar_MenuItem_SocialText_BorderColorBottom,
  DB_LeftSidebar_MenuItem_SocialText_BorderLeft,
  DB_LeftSidebar_MenuItem_SocialText_BorderStyleLeft,
  DB_LeftSidebar_MenuItem_SocialText_BorderColorLeft,
  DB_LeftSidebar_MenuItem_SocialText_BorderRight,
  DB_LeftSidebar_MenuItem_SocialText_BorderStyleRight,
  DB_LeftSidebar_MenuItem_SocialText_BorderColorRight,
  DB_LeftSidebar_MenuItem_SocialText_BorderTopRightRadius,
  DB_LeftSidebar_MenuItem_SocialText_BorderTopLeftRadius,
  DB_LeftSidebar_MenuItem_SocialText_BorderBottomRightRadius,
  DB_LeftSidebar_MenuItem_SocialText_BorderBottomLeftRadius,
  DB_LeftSidebar_MenuItem_SocialText_marginLeftRight,
  DB_LeftSidebar_MenuItem_SocialText_marginTopBottom,
  DB_LeftSidebar_MenuItem_SocialText_paddingLeftRight,
  DB_LeftSidebar_MenuItem_SocialText_paddingTopBottom,
  DB_LeftSidebar_MenuItem_SocialText_letterSpacing,
  DB_LeftSidebar_MenuItem_SocialText_ShadowHorizontal,
  DB_LeftSidebar_MenuItem_SocialText_ShadowVertical,
  DB_LeftSidebar_MenuItem_SocialText_ShadowBlur,
  DB_LeftSidebar_MenuItem_SocialText_ShadowColor,
  DB_LeftSidebar_MenuItem_SocialText_TextAlign,
  DB_LeftSidebar_MenuItem_SocialText_TextDecoration,
  DB_LeftSidebar_MenuItem_SocialText_TextDecorationStyle,

  DB_LeftSidebar_MenuItem_SocialText_Hover_FontWeight,
  DB_LeftSidebar_MenuItem_SocialText_Hover_FontColor,
  DB_LeftSidebar_MenuItem_SocialText_Hover_FontSize,
  DB_LeftSidebar_MenuItem_SocialText_Hover_BackgroundColor,
  DB_LeftSidebar_MenuItem_SocialText_Hover_TextAlign,
  DB_LeftSidebar_MenuItem_SocialText_Hover_BorderTop,
  DB_LeftSidebar_MenuItem_SocialText_Hover_BorderStyleTop,
  DB_LeftSidebar_MenuItem_SocialText_Hover_BorderColorTop,
  DB_LeftSidebar_MenuItem_SocialText_Hover_BorderBottom,
  DB_LeftSidebar_MenuItem_SocialText_Hover_BorderStyleBottom,
  DB_LeftSidebar_MenuItem_SocialText_Hover_BorderColorBottom,
  DB_LeftSidebar_MenuItem_SocialText_Hover_BorderLeft,
  DB_LeftSidebar_MenuItem_SocialText_Hover_BorderStyleLeft,
  DB_LeftSidebar_MenuItem_SocialText_Hover_BorderColorLeft,
  DB_LeftSidebar_MenuItem_SocialText_Hover_BorderRight,
  DB_LeftSidebar_MenuItem_SocialText_Hover_BorderStyleRight,
  DB_LeftSidebar_MenuItem_SocialText_Hover_BorderColorRight,
  DB_LeftSidebar_MenuItem_SocialText_Hover_BorderTopRightRadius,
  DB_LeftSidebar_MenuItem_SocialText_Hover_BorderTopLeftRadius,
  DB_LeftSidebar_MenuItem_SocialText_Hover_BorderBottomRightRadius,
  DB_LeftSidebar_MenuItem_SocialText_Hover_BorderBottomLeftRadius,
  DB_LeftSidebar_MenuItem_SocialText_marginLeftRight_Hover,
  DB_LeftSidebar_MenuItem_SocialText_marginTopBottom_Hover,
  DB_LeftSidebar_MenuItem_SocialText_paddingLeftRight_Hover,
  DB_LeftSidebar_MenuItem_SocialText_paddingTopBottom_Hover,
  DB_LeftSidebar_MenuItem_SocialText_letterSpacing_Hover,
  DB_LeftSidebar_MenuItem_SocialText_ShadowHorizontal_Hover,
  DB_LeftSidebar_MenuItem_SocialText_ShadowVertical_Hover,
  DB_LeftSidebar_MenuItem_SocialText_ShadowBlur_Hover,
  DB_LeftSidebar_MenuItem_SocialText_ShadowColor_Hover,
  DB_LeftSidebar_MenuItem_SocialText_TextDecoration_Hover,
  DB_LeftSidebar_MenuItem_SocialText_TextDecorationStyle_Hover,
}) => {
  const useStyles = createUseStyles({
    rightSideTextMessageToBuyer: {
      fontFamily: `${DB_fontFace_SocialTextFontFamily_FontFamilyName}`,
      fontWeight: `${DB_LeftSidebar_MenuItem_SocialText_FontWeight}`,
      color: `${DB_LeftSidebar_MenuItem_SocialText_FontColor}`,
      fill: `${DB_LeftSidebar_MenuItem_SocialText_FontColor}`,
      fontSize: `${DB_LeftSidebar_MenuItem_SocialText_FontSize}px`,
      backgroundColor: `${
        SideBarLeft_SocialItem_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut
          ? "rgba(255,0,0,0.5)"
          : SideBarLeft_SocialItem_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut
          ? "rgba(255,0,0,0.3)"
          : DB_LeftSidebar_MenuItem_SocialText_BackgroundColor
      }`,
      textAlign: `${DB_LeftSidebar_MenuItem_SocialText_TextAlign}`,
      borderTop: `${
        SideBarLeft_SocialItem_Farbe_Groesse_Stil_OnMouseOverAndOut
          ? "3"
          : SideBarLeft_SocialItem_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
          ? "3"
          : DB_LeftSidebar_MenuItem_SocialText_BorderTop
      }px
            ${
              SideBarLeft_SocialItem_Farbe_Groesse_Stil_OnMouseOverAndOut
                ? "solid"
                : SideBarLeft_SocialItem_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
                ? "dotted"
                : DB_LeftSidebar_MenuItem_SocialText_BorderStyleTop
            }
            ${
              SideBarLeft_SocialItem_Farbe_Groesse_Stil_OnMouseOverAndOut
                ? "red"
                : SideBarLeft_SocialItem_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
                ? "red"
                : DB_LeftSidebar_MenuItem_SocialText_BorderColorTop
            }`,
      borderBottom: `${
        SideBarLeft_SocialItem_Farbe_Groesse_Stil_OnMouseOverAndOut
          ? "3"
          : SideBarLeft_SocialItem_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
          ? "3"
          : DB_LeftSidebar_MenuItem_SocialText_BorderBottom
      }px
              ${
                SideBarLeft_SocialItem_Farbe_Groesse_Stil_OnMouseOverAndOut
                  ? "solid"
                  : SideBarLeft_SocialItem_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
                  ? "dotted"
                  : DB_LeftSidebar_MenuItem_SocialText_BorderStyleBottom
              }
              ${
                SideBarLeft_SocialItem_Farbe_Groesse_Stil_OnMouseOverAndOut
                  ? "red"
                  : SideBarLeft_SocialItem_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
                  ? "red"
                  : DB_LeftSidebar_MenuItem_SocialText_BorderColorBottom
              }`,
      borderLeft: `${
        SideBarLeft_SocialItem_Farbe_Groesse_Stil_OnMouseOverAndOut
          ? "3"
          : SideBarLeft_SocialItem_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
          ? "3"
          : DB_LeftSidebar_MenuItem_SocialText_BorderLeft
      }px
              ${
                SideBarLeft_SocialItem_Farbe_Groesse_Stil_OnMouseOverAndOut
                  ? "solid"
                  : SideBarLeft_SocialItem_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
                  ? "dotted"
                  : DB_LeftSidebar_MenuItem_SocialText_BorderStyleLeft
              }
              ${
                SideBarLeft_SocialItem_Farbe_Groesse_Stil_OnMouseOverAndOut
                  ? "red"
                  : SideBarLeft_SocialItem_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
                  ? "red"
                  : DB_LeftSidebar_MenuItem_SocialText_BorderColorLeft
              }`,
      borderRight: `${
        SideBarLeft_SocialItem_Farbe_Groesse_Stil_OnMouseOverAndOut
          ? "3"
          : SideBarLeft_SocialItem_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
          ? "3"
          : DB_LeftSidebar_MenuItem_SocialText_BorderRight
      }px
              ${
                SideBarLeft_SocialItem_Farbe_Groesse_Stil_OnMouseOverAndOut
                  ? "solid"
                  : SideBarLeft_SocialItem_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
                  ? "dotted"
                  : DB_LeftSidebar_MenuItem_SocialText_BorderStyleRight
              }
              ${
                SideBarLeft_SocialItem_Farbe_Groesse_Stil_OnMouseOverAndOut
                  ? "red"
                  : SideBarLeft_SocialItem_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
                  ? "red"
                  : DB_LeftSidebar_MenuItem_SocialText_BorderColorRight
              }`,
      borderTopRightRadius: `${DB_LeftSidebar_MenuItem_SocialText_BorderTopRightRadius}px`,
      borderTopLeftRadius: `${DB_LeftSidebar_MenuItem_SocialText_BorderTopLeftRadius}px`,
      borderBottomRightRadius: `${DB_LeftSidebar_MenuItem_SocialText_BorderBottomRightRadius}px`,
      borderBottomLeftRadius: `${DB_LeftSidebar_MenuItem_SocialText_BorderBottomLeftRadius}px`,
      marginLeft: `${DB_LeftSidebar_MenuItem_SocialText_marginLeftRight}px`,
      marginRight: `${DB_LeftSidebar_MenuItem_SocialText_marginLeftRight}px`,
      marginTop: `${DB_LeftSidebar_MenuItem_SocialText_marginTopBottom}px`,
      marginBottom: `${DB_LeftSidebar_MenuItem_SocialText_marginTopBottom}px`,
      paddingLeft: `${DB_LeftSidebar_MenuItem_SocialText_paddingLeftRight}px`,
      paddingRight: `${DB_LeftSidebar_MenuItem_SocialText_paddingLeftRight}px`,
      paddingTop: `${DB_LeftSidebar_MenuItem_SocialText_paddingTopBottom}px`,
      paddingBottom: `${DB_LeftSidebar_MenuItem_SocialText_paddingTopBottom}px`,
      letterSpacing: `${DB_LeftSidebar_MenuItem_SocialText_letterSpacing}px`,
      textShadow: `${DB_LeftSidebar_MenuItem_SocialText_ShadowHorizontal}px
            ${DB_LeftSidebar_MenuItem_SocialText_ShadowVertical}px
            ${DB_LeftSidebar_MenuItem_SocialText_ShadowBlur}px
            ${DB_LeftSidebar_MenuItem_SocialText_ShadowColor}`,
      textDecoration: `${DB_LeftSidebar_MenuItem_SocialText_TextDecoration}
            ${DB_LeftSidebar_MenuItem_SocialText_TextDecorationStyle}`,

      "&:hover": {
        fontWeight: `${DB_LeftSidebar_MenuItem_SocialText_Hover_FontWeight}`,
        color: `${DB_LeftSidebar_MenuItem_SocialText_Hover_FontColor}`,
        fill: `${DB_LeftSidebar_MenuItem_SocialText_Hover_FontColor}`,
        fontSize: `${DB_LeftSidebar_MenuItem_SocialText_Hover_FontSize}px`,
        backgroundColor: `${DB_LeftSidebar_MenuItem_SocialText_Hover_BackgroundColor}`,
        textAlign: `${DB_LeftSidebar_MenuItem_SocialText_Hover_TextAlign}`,
        borderTop: `${DB_LeftSidebar_MenuItem_SocialText_Hover_BorderTop}px
            ${DB_LeftSidebar_MenuItem_SocialText_Hover_BorderStyleTop}
            ${DB_LeftSidebar_MenuItem_SocialText_Hover_BorderColorTop}`,
        borderBottom: `${DB_LeftSidebar_MenuItem_SocialText_Hover_BorderBottom}px
            ${DB_LeftSidebar_MenuItem_SocialText_Hover_BorderStyleBottom}
            ${DB_LeftSidebar_MenuItem_SocialText_Hover_BorderColorBottom}`,
        borderLeft: `${DB_LeftSidebar_MenuItem_SocialText_Hover_BorderLeft}px
            ${DB_LeftSidebar_MenuItem_SocialText_Hover_BorderStyleLeft}
            ${DB_LeftSidebar_MenuItem_SocialText_Hover_BorderColorLeft}`,
        borderRight: `${DB_LeftSidebar_MenuItem_SocialText_Hover_BorderRight}px
            ${DB_LeftSidebar_MenuItem_SocialText_Hover_BorderStyleRight}
            ${DB_LeftSidebar_MenuItem_SocialText_Hover_BorderColorRight}`,
        borderTopRightRadius: `${DB_LeftSidebar_MenuItem_SocialText_Hover_BorderTopRightRadius}px`,
        borderTopLeftRadius: `${DB_LeftSidebar_MenuItem_SocialText_Hover_BorderTopLeftRadius}px`,
        borderBottomRightRadius: `${DB_LeftSidebar_MenuItem_SocialText_Hover_BorderBottomRightRadius}px`,
        borderBottomLeftRadius: `${DB_LeftSidebar_MenuItem_SocialText_Hover_BorderBottomLeftRadius}px`,
        marginLeft: `${DB_LeftSidebar_MenuItem_SocialText_marginLeftRight_Hover}px`,
        marginRight: `${DB_LeftSidebar_MenuItem_SocialText_marginLeftRight_Hover}px`,
        marginTop: `${DB_LeftSidebar_MenuItem_SocialText_marginTopBottom_Hover}px`,
        marginBottom: `${DB_LeftSidebar_MenuItem_SocialText_marginTopBottom_Hover}px`,
        paddingLeft: `${DB_LeftSidebar_MenuItem_SocialText_paddingLeftRight_Hover}px`,
        paddingRight: `${DB_LeftSidebar_MenuItem_SocialText_paddingLeftRight_Hover}px`,
        paddingTop: `${DB_LeftSidebar_MenuItem_SocialText_paddingTopBottom_Hover}px`,
        paddingBottom: `${DB_LeftSidebar_MenuItem_SocialText_paddingTopBottom_Hover}px`,
        letterSpacing: `${DB_LeftSidebar_MenuItem_SocialText_letterSpacing_Hover}px`,
        textShadow: `${DB_LeftSidebar_MenuItem_SocialText_ShadowHorizontal_Hover}px
            ${DB_LeftSidebar_MenuItem_SocialText_ShadowVertical_Hover}px
            ${DB_LeftSidebar_MenuItem_SocialText_ShadowBlur_Hover}px
            ${DB_LeftSidebar_MenuItem_SocialText_ShadowColor_Hover}`,
        textDecoration: `${DB_LeftSidebar_MenuItem_SocialText_TextDecoration_Hover}
            ${DB_LeftSidebar_MenuItem_SocialText_TextDecorationStyle_Hover}`,
      },
    },
  });
  const classes = useStyles();
  return (
    <div className={classes.rightSideTextMessageToBuyer}>
      {CustomSocialMessage}
    </div>
  );
};

export default SideBarRightSocialIconsText;
