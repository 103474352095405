import React from "react";
import ProductCardPreisWaehrung from "./ProductCardPreisWaehrung";
import ProductCardBreadCrumbs from "./ProductCardBreadCrumbs";
import ProductCardBreadCrumbsFour from "./ProductCardBreadCrumbsFour";
import ProductCardBreadCrumbsThree from "./ProductCardBreadCrumbsThree";
import ProductCardContainerTwelfe from "./ProductCardContainerTwelfe";
import { createUseStyles } from "react-jss";

const BodyAllProductCards = ({
  allProductsState,
  categoryLeftSideHandler,
  showProductDetailsHandler,
  columnNumber,
  DB_Waehrung_Global,
  DB_fontFace_FontFamilyName,
  DB_fontFace_chooseFontBreadcrumbs_FontFamilyName,
  DB_fontFace_chooseFontBreadcrumbs_FontFamilySrc,
  DB_fontFace_chooseFontWaehrungEuro_FontFamilyName,
  DB_fontFace_chooseFontWaehrungEuro_FontFamilySrc,
  DB_fontFace_chooseFontWaehrung_FontFamilyName,
  DB_fontFace_chooseFontWaehrung_FontFamilySrc,
  DB_fontFace_chooseFontFamilyProduct_FontFamilyName,
  DB_LeftSidebar_MenuItem_HauptTitel_FontWeight,
  DB_LeftSidebar_MenuItem_HauptTitel_Hover_FontWeight,
  DB_LeftSidebar_MenuItem_HauptTitel_FontColor,
  DB_LeftSidebar_MenuItem_HauptTitel_Hover_FontColor,
  DB_LeftSidebar_MenuItem_HauptTitel_FontSize,
  DB_LeftSidebar_MenuItem_HauptTitel_Hover_FontSize,
  SideBarLeft_HauptTitel_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut,
  SideBarLeft_HauptTitel_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut,
  DB_LeftSidebar_MenuItem_HauptTitel_BackgroundColor,
  DB_LeftSidebar_MenuItem_HauptTitel_Hover_BackgroundColor,
  DB_LeftSidebar_MenuItem_HauptTitel_TextAlign,
  DB_LeftSidebar_MenuItem_HauptTitel_Hover_TextAlign,
  SideBarLeft_HauptTitel_Farbe_Groesse_Stil_OnMouseOverAndOut,
  SideBarLeft_HauptTitel_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut,
  DB_LeftSidebar_MenuItem_HauptTitel_BorderTop,
  DB_LeftSidebar_MenuItem_HauptTitel_Hover_BorderTop,
  DB_LeftSidebar_MenuItem_HauptTitel_BorderStyleTop,
  DB_LeftSidebar_MenuItem_HauptTitel_BorderColorTop,
  DB_LeftSidebar_MenuItem_HauptTitel_BorderBottom,
  DB_LeftSidebar_MenuItem_HauptTitel_BorderStyleBottom,
  DB_LeftSidebar_MenuItem_HauptTitel_BorderColorBottom,
  DB_LeftSidebar_MenuItem_HauptTitel_BorderLeft,
  DB_LeftSidebar_MenuItem_HauptTitel_BorderStyleLeft,
  DB_LeftSidebar_MenuItem_HauptTitel_BorderColorLeft,
  DB_LeftSidebar_MenuItem_HauptTitel_BorderRight,
  DB_LeftSidebar_MenuItem_HauptTitel_BorderStyleRight,
  DB_LeftSidebar_MenuItem_HauptTitel_BorderColorRight,

  DB_LeftSidebar_MenuItem_HauptTitel_BorderTopRightRadius,
  DB_LeftSidebar_MenuItem_HauptTitel_BorderTopLeftRadius,
  DB_LeftSidebar_MenuItem_HauptTitel_BorderBottomRightRadius,
  DB_LeftSidebar_MenuItem_HauptTitel_BorderBottomLeftRadius,

  DB_LeftSidebar_MenuItem_HauptTitel_Hover_BorderTopRightRadius,
  DB_LeftSidebar_MenuItem_HauptTitel_Hover_BorderTopLeftRadius,
  DB_LeftSidebar_MenuItem_HauptTitel_Hover_BorderBottomRightRadius,
  DB_LeftSidebar_MenuItem_HauptTitel_Hover_BorderBottomLeftRadius,

  DB_LeftSidebar_MenuItem_HauptTitel_Hover_BorderStyleTop,
  DB_LeftSidebar_MenuItem_HauptTitel_Hover_BorderColorTop,
  DB_LeftSidebar_MenuItem_HauptTitel_Hover_BorderBottom,
  DB_LeftSidebar_MenuItem_HauptTitel_Hover_BorderStyleBottom,

  DB_LeftSidebar_MenuItem_HauptTitel_Hover_BorderColorBottom,
  DB_LeftSidebar_MenuItem_HauptTitel_Hover_BorderLeft,
  DB_LeftSidebar_MenuItem_HauptTitel_Hover_BorderStyleLeft,
  DB_LeftSidebar_MenuItem_HauptTitel_Hover_BorderColorLeft,

  DB_LeftSidebar_MenuItem_HauptTitel_Hover_BorderRight,
  DB_LeftSidebar_MenuItem_HauptTitel_Hover_BorderStyleRight,
  DB_LeftSidebar_MenuItem_HauptTitel_Hover_BorderColorRight,

  DB_LeftSidebar_MenuItem_HauptTitel_marginTopBottom,
  DB_LeftSidebar_MenuItem_HauptTitel_marginLeftRight,
  DB_LeftSidebar_MenuItem_HauptTitel_paddingLeftRight,
  DB_LeftSidebar_MenuItem_HauptTitel_paddingTopBottom,

  DB_LeftSidebar_MenuItem_HauptTitel_letterSpacing,
  DB_LeftSidebar_MenuItem_HauptTitel_ShadowHorizontal,
  DB_LeftSidebar_MenuItem_HauptTitel_ShadowVertical,
  DB_LeftSidebar_MenuItem_HauptTitel_ShadowBlur,
  DB_LeftSidebar_MenuItem_HauptTitel_ShadowColor,
  DB_LeftSidebar_MenuItem_HauptTitel_TextDecoration,
  DB_LeftSidebar_MenuItem_HauptTitel_TextDecorationStyle,

  DB_LeftSidebar_MenuItem_HauptTitel_marginTopBottom_Hover,
  DB_LeftSidebar_MenuItem_HauptTitel_marginLeftRight_Hover,
  DB_LeftSidebar_MenuItem_HauptTitel_paddingLeftRight_Hover,
  DB_LeftSidebar_MenuItem_HauptTitel_paddingTopBottom_Hover,

  DB_LeftSidebar_MenuItem_HauptTitel_letterSpacing_Hover,
  DB_LeftSidebar_MenuItem_HauptTitel_ShadowHorizontal_Hover,
  DB_LeftSidebar_MenuItem_HauptTitel_ShadowVertical_Hover,
  DB_LeftSidebar_MenuItem_HauptTitel_ShadowBlur_Hover,
  DB_LeftSidebar_MenuItem_HauptTitel_ShadowColor_Hover,
  DB_LeftSidebar_MenuItem_HauptTitel_TextDecoration_Hover,
  DB_LeftSidebar_MenuItem_HauptTitel_TextDecorationStyle_Hover,

  DB_LeftSidebar_MenuItem_UnterTitel_Hover_FontFace,
  DB_LeftSidebar_MenuItem_UnterTitel_FontWeight,
  DB_LeftSidebar_MenuItem_UnterTitel_Hover_FontWeight,
  DB_LeftSidebar_MenuItem_UnterTitel_FontColor,
  DB_LeftSidebar_MenuItem_UnterTitel_Hover_FontColor,
  DB_LeftSidebar_MenuItem_UnterTitel_FontSize,
  DB_LeftSidebar_MenuItem_UnterTitel_Hover_FontSize,
  SideBarLeft_UnterTitel_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut,
  SideBarLeft_UnterTitel_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut,
  DB_LeftSidebar_MenuItem_UnterTitel_BackgroundColor,
  DB_LeftSidebar_MenuItem_UnterTitel_Hover_BackgroundColor,
  DB_LeftSidebar_MenuItem_UnterTitel_TextAlign,
  DB_LeftSidebar_MenuItem_UnterTitel_Hover_TextAlign,
  SideBarLeft_UnterTitel_Farbe_Groesse_Stil_OnMouseOverAndOut,
  SideBarLeft_UnterTitel_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut,
  DB_LeftSidebar_MenuItem_UnterTitel_BorderTop,
  DB_LeftSidebar_MenuItem_UnterTitel_Hover_BorderTop,
  DB_LeftSidebar_MenuItem_UnterTitel_BorderStyleTop,
  DB_LeftSidebar_MenuItem_UnterTitel_BorderColorTop,
  DB_LeftSidebar_MenuItem_UnterTitel_BorderBottom,
  DB_LeftSidebar_MenuItem_UnterTitel_BorderStyleBottom,
  DB_LeftSidebar_MenuItem_UnterTitel_BorderColorBottom,
  DB_LeftSidebar_MenuItem_UnterTitel_BorderLeft,
  DB_LeftSidebar_MenuItem_UnterTitel_BorderStyleLeft,
  DB_LeftSidebar_MenuItem_UnterTitel_BorderColorLeft,
  DB_LeftSidebar_MenuItem_UnterTitel_BorderRight,
  DB_LeftSidebar_MenuItem_UnterTitel_BorderStyleRight,
  DB_LeftSidebar_MenuItem_UnterTitel_BorderColorRight,

  DB_LeftSidebar_MenuItem_UnterTitel_BorderTopRightRadius,
  DB_LeftSidebar_MenuItem_UnterTitel_BorderTopLeftRadius,
  DB_LeftSidebar_MenuItem_UnterTitel_BorderBottomRightRadius,
  DB_LeftSidebar_MenuItem_UnterTitel_BorderBottomLeftRadius,

  DB_LeftSidebar_MenuItem_UnterTitel_Hover_BorderTopRightRadius,
  DB_LeftSidebar_MenuItem_UnterTitel_Hover_BorderTopLeftRadius,
  DB_LeftSidebar_MenuItem_UnterTitel_Hover_BorderBottomRightRadius,
  DB_LeftSidebar_MenuItem_UnterTitel_Hover_BorderBottomLeftRadius,

  DB_LeftSidebar_MenuItem_UnterTitel_Hover_BorderStyleTop,
  DB_LeftSidebar_MenuItem_UnterTitel_Hover_BorderColorTop,
  DB_LeftSidebar_MenuItem_UnterTitel_Hover_BorderBottom,
  DB_LeftSidebar_MenuItem_UnterTitel_Hover_BorderStyleBottom,

  DB_LeftSidebar_MenuItem_UnterTitel_Hover_BorderColorBottom,
  DB_LeftSidebar_MenuItem_UnterTitel_Hover_BorderLeft,
  DB_LeftSidebar_MenuItem_UnterTitel_Hover_BorderStyleLeft,
  DB_LeftSidebar_MenuItem_UnterTitel_Hover_BorderColorLeft,

  DB_LeftSidebar_MenuItem_UnterTitel_Hover_BorderRight,
  DB_LeftSidebar_MenuItem_UnterTitel_Hover_BorderStyleRight,
  DB_LeftSidebar_MenuItem_UnterTitel_Hover_BorderColorRight,

  DB_LeftSidebar_MenuItem_UnterTitel_marginTopBottom,
  DB_LeftSidebar_MenuItem_UnterTitel_marginLeftRight,
  DB_LeftSidebar_MenuItem_UnterTitel_paddingLeftRight,
  DB_LeftSidebar_MenuItem_UnterTitel_paddingTopBottom,

  DB_LeftSidebar_MenuItem_UnterTitel_letterSpacing,
  DB_LeftSidebar_MenuItem_UnterTitel_ShadowHorizontal,
  DB_LeftSidebar_MenuItem_UnterTitel_ShadowVertical,
  DB_LeftSidebar_MenuItem_UnterTitel_ShadowBlur,
  DB_LeftSidebar_MenuItem_UnterTitel_ShadowColor,
  DB_LeftSidebar_MenuItem_UnterTitel_TextDecoration,
  DB_LeftSidebar_MenuItem_UnterTitel_TextDecorationStyle,

  DB_LeftSidebar_MenuItem_UnterTitel_marginTopBottom_Hover,
  DB_LeftSidebar_MenuItem_UnterTitel_marginLeftRight_Hover,
  DB_LeftSidebar_MenuItem_UnterTitel_paddingLeftRight_Hover,
  DB_LeftSidebar_MenuItem_UnterTitel_paddingTopBottom_Hover,

  DB_LeftSidebar_MenuItem_UnterTitel_letterSpacing_Hover,
  DB_LeftSidebar_MenuItem_UnterTitel_ShadowHorizontal_Hover,
  DB_LeftSidebar_MenuItem_UnterTitel_ShadowVertical_Hover,
  DB_LeftSidebar_MenuItem_UnterTitel_ShadowBlur_Hover,
  DB_LeftSidebar_MenuItem_UnterTitel_ShadowColor_Hover,
  DB_LeftSidebar_MenuItem_UnterTitel_TextDecoration_Hover,
  DB_LeftSidebar_MenuItem_UnterTitel_TextDecorationStyle_Hover,
  DB_LeftSidebar_MenuItem_ProduktPreis_FontWeight,
  DB_LeftSidebar_MenuItem_ProduktPreis_Hover_FontWeight,
  DB_LeftSidebar_MenuItem_ProduktPreis_FontColor,
  DB_LeftSidebar_MenuItem_ProduktPreis_Hover_FontColor,
  DB_LeftSidebar_MenuItem_ProduktPreis_FontSize,
  DB_LeftSidebar_MenuItem_ProduktPreis_Hover_FontSize,
  SideBarLeft_ProduktPreis_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut,
  SideBarLeft_ProduktPreis_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut,
  DB_LeftSidebar_MenuItem_ProduktPreis_BackgroundColor,
  DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BackgroundColor,
  DB_LeftSidebar_MenuItem_ProduktPreis_TextAlign,
  DB_LeftSidebar_MenuItem_ProduktPreis_Hover_TextAlign,
  SideBarLeft_ProduktPreis_Farbe_Groesse_Stil_OnMouseOverAndOut,
  SideBarLeft_ProduktPreis_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut,
  DB_LeftSidebar_MenuItem_ProduktPreis_BorderTop,
  DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderTop,
  DB_LeftSidebar_MenuItem_ProduktPreis_BorderStyleTop,
  DB_LeftSidebar_MenuItem_ProduktPreis_BorderColorTop,
  DB_LeftSidebar_MenuItem_ProduktPreis_BorderBottom,
  DB_LeftSidebar_MenuItem_ProduktPreis_BorderStyleBottom,
  DB_LeftSidebar_MenuItem_ProduktPreis_BorderColorBottom,
  DB_LeftSidebar_MenuItem_ProduktPreis_BorderLeft,
  DB_LeftSidebar_MenuItem_ProduktPreis_BorderStyleLeft,
  DB_LeftSidebar_MenuItem_ProduktPreis_BorderColorLeft,
  DB_LeftSidebar_MenuItem_ProduktPreis_BorderRight,
  DB_LeftSidebar_MenuItem_ProduktPreis_BorderStyleRight,
  DB_LeftSidebar_MenuItem_ProduktPreis_BorderColorRight,

  DB_LeftSidebar_MenuItem_ProduktPreis_BorderTopRightRadius,
  DB_LeftSidebar_MenuItem_ProduktPreis_BorderTopLeftRadius,
  DB_LeftSidebar_MenuItem_ProduktPreis_BorderBottomRightRadius,
  DB_LeftSidebar_MenuItem_ProduktPreis_BorderBottomLeftRadius,

  DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderTopRightRadius,
  DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderTopLeftRadius,
  DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderBottomRightRadius,
  DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderBottomLeftRadius,

  DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderStyleTop,
  DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderColorTop,
  DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderBottom,
  DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderStyleBottom,

  DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderColorBottom,
  DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderLeft,
  DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderStyleLeft,
  DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderColorLeft,

  DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderRight,
  DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderStyleRight,
  DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderColorRight,

  DB_LeftSidebar_MenuItem_ProduktPreis_marginTopBottom,
  DB_LeftSidebar_MenuItem_ProduktPreis_marginLeftRight,
  DB_LeftSidebar_MenuItem_ProduktPreis_paddingLeftRight,
  DB_LeftSidebar_MenuItem_ProduktPreis_paddingTopBottom,

  DB_LeftSidebar_MenuItem_ProduktPreis_letterSpacing,
  DB_LeftSidebar_MenuItem_ProduktPreis_ShadowHorizontal,
  DB_LeftSidebar_MenuItem_ProduktPreis_ShadowVertical,
  DB_LeftSidebar_MenuItem_ProduktPreis_ShadowBlur,
  DB_LeftSidebar_MenuItem_ProduktPreis_ShadowColor,
  DB_LeftSidebar_MenuItem_ProduktPreis_TextDecoration,
  DB_LeftSidebar_MenuItem_ProduktPreis_TextDecorationStyle,

  DB_LeftSidebar_MenuItem_ProduktPreis_marginTopBottom_Hover,
  DB_LeftSidebar_MenuItem_ProduktPreis_marginLeftRight_Hover,
  DB_LeftSidebar_MenuItem_ProduktPreis_paddingLeftRight_Hover,
  DB_LeftSidebar_MenuItem_ProduktPreis_paddingTopBottom_Hover,

  DB_LeftSidebar_MenuItem_ProduktPreis_letterSpacing_Hover,
  DB_LeftSidebar_MenuItem_ProduktPreis_ShadowHorizontal_Hover,
  DB_LeftSidebar_MenuItem_ProduktPreis_ShadowVertical_Hover,
  DB_LeftSidebar_MenuItem_ProduktPreis_ShadowBlur_Hover,
  DB_LeftSidebar_MenuItem_ProduktPreis_ShadowColor_Hover,
  DB_LeftSidebar_MenuItem_ProduktPreis_TextDecoration_Hover,
  DB_LeftSidebar_MenuItem_ProduktPreis_TextDecorationStyle_Hover,
  DB_LeftSidebar_MenuItem_ProduktWaehrung_FontWeight,
  DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_FontWeight,
  DB_LeftSidebar_MenuItem_ProduktWaehrung_FontColor,
  DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_FontColor,
  DB_LeftSidebar_MenuItem_ProduktWaehrung_FontSize,
  DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_FontSize,
  SideBarLeft_ProduktWaehrung_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut,
  SideBarLeft_ProduktWaehrung_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut,
  DB_LeftSidebar_MenuItem_ProduktWaehrung_BackgroundColor,
  DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BackgroundColor,
  DB_LeftSidebar_MenuItem_ProduktWaehrung_TextAlign,
  DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_TextAlign,
  SideBarLeft_ProduktWaehrung_Farbe_Groesse_Stil_OnMouseOverAndOut,
  SideBarLeft_ProduktWaehrung_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut,
  DB_LeftSidebar_MenuItem_ProduktWaehrung_BorderTop,
  DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderTop,
  DB_LeftSidebar_MenuItem_ProduktWaehrung_BorderStyleTop,
  DB_LeftSidebar_MenuItem_ProduktWaehrung_BorderColorTop,
  DB_LeftSidebar_MenuItem_ProduktWaehrung_BorderBottom,
  DB_LeftSidebar_MenuItem_ProduktWaehrung_BorderStyleBottom,
  DB_LeftSidebar_MenuItem_ProduktWaehrung_BorderColorBottom,
  DB_LeftSidebar_MenuItem_ProduktWaehrung_BorderLeft,
  DB_LeftSidebar_MenuItem_ProduktWaehrung_BorderStyleLeft,
  DB_LeftSidebar_MenuItem_ProduktWaehrung_BorderColorLeft,
  DB_LeftSidebar_MenuItem_ProduktWaehrung_BorderRight,
  DB_LeftSidebar_MenuItem_ProduktWaehrung_BorderStyleRight,
  DB_LeftSidebar_MenuItem_ProduktWaehrung_BorderColorRight,

  DB_LeftSidebar_MenuItem_ProduktWaehrung_BorderTopRightRadius,
  DB_LeftSidebar_MenuItem_ProduktWaehrung_BorderTopLeftRadius,
  DB_LeftSidebar_MenuItem_ProduktWaehrung_BorderBottomRightRadius,
  DB_LeftSidebar_MenuItem_ProduktWaehrung_BorderBottomLeftRadius,

  DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderTopRightRadius,
  DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderTopLeftRadius,
  DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderBottomRightRadius,
  DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderBottomLeftRadius,

  DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderStyleTop,
  DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderColorTop,
  DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderBottom,
  DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderStyleBottom,

  DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderColorBottom,
  DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderLeft,
  DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderStyleLeft,
  DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderColorLeft,

  DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderRight,
  DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderStyleRight,
  DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderColorRight,

  DB_LeftSidebar_MenuItem_ProduktWaehrung_marginTopBottom,
  DB_LeftSidebar_MenuItem_ProduktWaehrung_marginLeftRight,
  DB_LeftSidebar_MenuItem_ProduktWaehrung_paddingLeftRight,
  DB_LeftSidebar_MenuItem_ProduktWaehrung_paddingTopBottom,

  DB_LeftSidebar_MenuItem_ProduktWaehrung_letterSpacing,
  DB_LeftSidebar_MenuItem_ProduktWaehrung_ShadowHorizontal,
  DB_LeftSidebar_MenuItem_ProduktWaehrung_ShadowVertical,
  DB_LeftSidebar_MenuItem_ProduktWaehrung_ShadowBlur,
  DB_LeftSidebar_MenuItem_ProduktWaehrung_ShadowColor,
  DB_LeftSidebar_MenuItem_ProduktWaehrung_TextDecoration,
  DB_LeftSidebar_MenuItem_ProduktWaehrung_TextDecorationStyle,

  DB_LeftSidebar_MenuItem_ProduktWaehrung_marginTopBottom_Hover,
  DB_LeftSidebar_MenuItem_ProduktWaehrung_marginLeftRight_Hover,
  DB_LeftSidebar_MenuItem_ProduktWaehrung_paddingLeftRight_Hover,
  DB_LeftSidebar_MenuItem_ProduktWaehrung_paddingTopBottom_Hover,

  DB_LeftSidebar_MenuItem_ProduktWaehrung_letterSpacing_Hover,
  DB_LeftSidebar_MenuItem_ProduktWaehrung_ShadowHorizontal_Hover,
  DB_LeftSidebar_MenuItem_ProduktWaehrung_ShadowVertical_Hover,
  DB_LeftSidebar_MenuItem_ProduktWaehrung_ShadowBlur_Hover,
  DB_LeftSidebar_MenuItem_ProduktWaehrung_ShadowColor_Hover,
  DB_LeftSidebar_MenuItem_ProduktWaehrung_TextDecoration_Hover,
  DB_LeftSidebar_MenuItem_ProduktWaehrung_TextDecorationStyle_Hover,

  DB_LeftSidebar_MenuItem_BreadCrumbs_FontWeight,
  DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_FontWeight,
  DB_LeftSidebar_MenuItem_BreadCrumbs_FontColor,
  DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_FontColor,
  DB_LeftSidebar_MenuItem_BreadCrumbs_FontSize,
  DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_FontSize,

  SideBarLeft_BreadCrumbs_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut,
  SideBarLeft_BreadCrumbs_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut,
  DB_LeftSidebar_MenuItem_BreadCrumbs_BackgroundColor,
  DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BackgroundColor,
  DB_LeftSidebar_MenuItem_BreadCrumbs_TextAlign,
  DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_TextAlign,
  SideBarLeft_BreadCrumbs_Farbe_Groesse_Stil_OnMouseOverAndOut,
  SideBarLeft_BreadCrumbs_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut,
  DB_LeftSidebar_MenuItem_BreadCrumbs_BorderTop,
  DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderTop,
  DB_LeftSidebar_MenuItem_BreadCrumbs_BorderStyleTop,
  DB_LeftSidebar_MenuItem_BreadCrumbs_BorderColorTop,
  DB_LeftSidebar_MenuItem_BreadCrumbs_BorderBottom,
  DB_LeftSidebar_MenuItem_BreadCrumbs_BorderStyleBottom,
  DB_LeftSidebar_MenuItem_BreadCrumbs_BorderColorBottom,
  DB_LeftSidebar_MenuItem_BreadCrumbs_BorderLeft,
  DB_LeftSidebar_MenuItem_BreadCrumbs_BorderStyleLeft,
  DB_LeftSidebar_MenuItem_BreadCrumbs_BorderColorLeft,
  DB_LeftSidebar_MenuItem_BreadCrumbs_BorderRight,
  DB_LeftSidebar_MenuItem_BreadCrumbs_BorderStyleRight,
  DB_LeftSidebar_MenuItem_BreadCrumbs_BorderColorRight,

  DB_LeftSidebar_MenuItem_BreadCrumbs_BorderTopRightRadius,
  DB_LeftSidebar_MenuItem_BreadCrumbs_BorderTopLeftRadius,
  DB_LeftSidebar_MenuItem_BreadCrumbs_BorderBottomRightRadius,
  DB_LeftSidebar_MenuItem_BreadCrumbs_BorderBottomLeftRadius,

  DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderTopRightRadius,
  DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderTopLeftRadius,
  DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderBottomRightRadius,
  DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderBottomLeftRadius,

  DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderStyleTop,
  DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderColorTop,
  DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderBottom,
  DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderStyleBottom,

  DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderColorBottom,
  DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderLeft,
  DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderStyleLeft,
  DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderColorLeft,

  DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderRight,
  DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderStyleRight,
  DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderColorRight,

  DB_LeftSidebar_MenuItem_BreadCrumbs_marginTopBottom,
  DB_LeftSidebar_MenuItem_BreadCrumbs_marginLeftRight,
  DB_LeftSidebar_MenuItem_BreadCrumbs_paddingLeftRight,
  DB_LeftSidebar_MenuItem_BreadCrumbs_paddingTopBottom,

  DB_LeftSidebar_MenuItem_BreadCrumbs_letterSpacing,
  DB_LeftSidebar_MenuItem_BreadCrumbs_ShadowHorizontal,
  DB_LeftSidebar_MenuItem_BreadCrumbs_ShadowVertical,
  DB_LeftSidebar_MenuItem_BreadCrumbs_ShadowBlur,
  DB_LeftSidebar_MenuItem_BreadCrumbs_ShadowColor,
  DB_LeftSidebar_MenuItem_BreadCrumbs_TextDecoration,
  DB_LeftSidebar_MenuItem_BreadCrumbs_TextDecorationStyle,

  DB_LeftSidebar_MenuItem_BreadCrumbs_marginTopBottom_Hover,
  DB_LeftSidebar_MenuItem_BreadCrumbs_marginLeftRight_Hover,
  DB_LeftSidebar_MenuItem_BreadCrumbs_paddingLeftRight_Hover,
  DB_LeftSidebar_MenuItem_BreadCrumbs_paddingTopBottom_Hover,

  DB_LeftSidebar_MenuItem_BreadCrumbs_letterSpacing_Hover,
  DB_LeftSidebar_MenuItem_BreadCrumbs_ShadowHorizontal_Hover,
  DB_LeftSidebar_MenuItem_BreadCrumbs_ShadowVertical_Hover,
  DB_LeftSidebar_MenuItem_BreadCrumbs_ShadowBlur_Hover,
  DB_LeftSidebar_MenuItem_BreadCrumbs_ShadowColor_Hover,
  DB_LeftSidebar_MenuItem_BreadCrumbs_TextDecoration_Hover,
  DB_LeftSidebar_MenuItem_BreadCrumbs_TextDecorationStyle_Hover,

  SideBarLeft_ProduktContainer_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut,
  SideBarLeft_ProduktContainer_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut,
  DB_LeftSidebar_MenuProduktContainer_BackgroundColor,
  DB_LeftSidebar_MenuProduktContainer_PaddingTopBottom,
  DB_LeftSidebar_MenuProduktContainer_PaddingLeftRight,
  SideBarLeft_ProduktContainer_Farbe_Groesse_Stil_OnMouseOverAndOut,
  SideBarLeft_ProduktContainer_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut,
  DB_LeftSidebar_MenuProduktContainer_BorderTop,
  DB_LeftSidebar_MenuProduktContainer_BorderStyleTop,
  DB_LeftSidebar_MenuProduktContainer_BorderColorTop,
  DB_LeftSidebar_MenuProduktContainer_BorderBottom,
  DB_LeftSidebar_MenuProduktContainer_BorderStyleBottom,
  DB_LeftSidebar_MenuProduktContainer_BorderColorBottom,
  DB_LeftSidebar_MenuProduktContainer_BorderLeft,
  DB_LeftSidebar_MenuProduktContainer_BorderStyleLeft,
  DB_LeftSidebar_MenuProduktContainer_BorderColorLeft,
  DB_LeftSidebar_MenuProduktContainer_BorderRight,
  DB_LeftSidebar_MenuProduktContainer_BorderStyleRight,
  DB_LeftSidebar_MenuProduktContainer_BorderColorRight,
  DB_LeftSidebar_MenuItem_MenuProduktContainer_BorderTopRightRadius,
  DB_LeftSidebar_MenuItem_MenuProduktContainer_BorderTopLeftRadius,
  DB_LeftSidebar_MenuItem_MenuProduktContainer_BorderBottomRightRadius,
  DB_LeftSidebar_MenuItem_MenuProduktContainer_BorderBottomLeftRadius,
  DB_LeftSidebar_MenuItem_MenuProduktContainer_paddingLeft,
  DB_LeftSidebar_MenuItem_MenuProduktContainer_paddingRight,
  DB_LeftSidebar_MenuItem_MenuProduktContainer_paddingTop,
  DB_LeftSidebar_MenuItem_MenuProduktContainer_paddingBottom,
  DB_LeftSidebar_MenuItem_MenuProduktContainer_ShadowHorizontal,
  DB_LeftSidebar_MenuItem_MenuProduktContainer_ShadowBlur,
  DB_LeftSidebar_MenuItem_MenuProduktContainer_ShadowColor,
  DB_LeftSidebar_MenuProduktContainer_Hover_BackgroundColor,
  DB_LeftSidebar_MenuProduktContainer_Hover_PaddingTopBottom,
  DB_LeftSidebar_MenuProduktContainer_Hover_PaddingLeftRight,
  DB_LeftSidebar_MenuProduktContainer_Hover_BorderTop,
  DB_LeftSidebar_MenuProduktContainer_Hover_BorderStyleTop,
  DB_LeftSidebar_MenuProduktContainer_Hover_BorderColorTop,
  DB_LeftSidebar_MenuProduktContainer_Hover_BorderBottom,
  DB_LeftSidebar_MenuProduktContainer_Hover_BorderStyleBottom,
  DB_LeftSidebar_MenuProduktContainer_Hover_BorderColorBottom,
  DB_LeftSidebar_MenuProduktContainer_Hover_BorderLeft,
  DB_LeftSidebar_MenuProduktContainer_Hover_BorderStyleLeft,
  DB_LeftSidebar_MenuProduktContainer_Hover_BorderColorLeft,
  DB_LeftSidebar_MenuProduktContainer_Hover_BorderRight,
  DB_LeftSidebar_MenuProduktContainer_Hover_BorderStyleRight,
  DB_LeftSidebar_MenuProduktContainer_Hover_BorderColorRight,
  DB_LeftSidebar_MenuItem_MenuProduktContainer_Hover_BorderTopRightRadius,
  DB_LeftSidebar_MenuItem_MenuProduktContainer_Hover_BorderTopLeftRadius,
  DB_LeftSidebar_MenuItem_MenuProduktContainer_Hover_BorderBottomRightRadius,
  DB_LeftSidebar_MenuItem_MenuProduktContainer_Hover_BorderBottomLeftRadius,
  DB_LeftSidebar_MenuItem_MenuProduktContainer_Hover_paddingLeft,
  DB_LeftSidebar_MenuItem_MenuProduktContainer_Hover_paddingRight,
  DB_LeftSidebar_MenuItem_MenuProduktContainer_Hover_paddingTop,
  DB_LeftSidebar_MenuItem_MenuProduktContainer_Hover_paddingBottom,
  DB_LeftSidebar_MenuItem_MenuProduktContainer_Hover_ShadowVertical,
  DB_LeftSidebar_MenuItem_MenuProduktContainer_ShadowVertical,
  DB_LeftSidebar_MenuItem_MenuProduktContainer_Hover_ShadowHorizontal,
  DB_LeftSidebar_MenuItem_MenuProduktContainer_Hover_ShadowBlur,
  DB_LeftSidebar_MenuItem_MenuProduktContainer_Hover_ShadowColor,
  DB_LeftSidebar_MenuProduktImageContainer_BackgroundColor,
  DB_LeftSidebar_MenuProduktImageContainer_Hover_BackgroundColor,
}) => {
  const DB_BodyProductCard_BorderRadius = 15;
  const DB_BodyProductCard_Breadcrumbs_FontFamily = DB_fontFace_FontFamilyName;
  const DB_BodyProductCard_Waehrung = DB_Waehrung_Global;

  const DB_BodyProductCard_BorderTop_Width = 7;
  const DB_BodyProductCard_BorderTop_Style = "solid";
  const DB_BodyProductCard_BorderTop_Color = "rgba(255,0,0,0.7)";

  const DB_BodyProductCard_BorderBottom_Width = 7;
  const DB_BodyProductCard_BorderBottom_Style = "solid";
  const DB_BodyProductCard_BorderBottom_Color = "rgba(0,255,0,0.7)";

  const DB_BodyProductCard_BorderLeft_Width = 7;
  const DB_BodyProductCard_BorderLeft_Style = "solid";
  const DB_BodyProductCard_BorderLeft_Color = "rgba(0,0,255,0.7)";

  const DB_BodyProductCard_BorderRight_Width = 7;
  const DB_BodyProductCard_BorderRight_Style = "solid";
  const DB_BodyProductCard_BorderRight_Color = "rgba(0,0,0,0.7)";

  const useStyles = createUseStyles({
    produktTitel: {
      overflow: "hidden",
      textOverflow: "ellipsis",
      lineClamp: "1",
      fontFamily: `${DB_fontFace_chooseFontFamilyProduct_FontFamilyName}`,
      fontWeight: `${DB_LeftSidebar_MenuItem_HauptTitel_FontWeight}`,
      color: `${DB_LeftSidebar_MenuItem_HauptTitel_FontColor}`,
      fontSize: `${DB_LeftSidebar_MenuItem_HauptTitel_FontSize}px`,
      backgroundColor: `${
        SideBarLeft_HauptTitel_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut
          ? "rgba(255,0,0,0.5)"
          : SideBarLeft_HauptTitel_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut
          ? "rgba(255,0,0,0.3)"
          : DB_LeftSidebar_MenuItem_HauptTitel_BackgroundColor
      }`,
      textAlign: `${DB_LeftSidebar_MenuItem_HauptTitel_TextAlign}`,
      borderTop: `${
        SideBarLeft_HauptTitel_Farbe_Groesse_Stil_OnMouseOverAndOut
          ? "3"
          : SideBarLeft_HauptTitel_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
          ? "3"
          : DB_LeftSidebar_MenuItem_HauptTitel_BorderTop
      }px
              ${
                SideBarLeft_HauptTitel_Farbe_Groesse_Stil_OnMouseOverAndOut
                  ? "solid"
                  : SideBarLeft_HauptTitel_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
                  ? "dotted"
                  : DB_LeftSidebar_MenuItem_HauptTitel_BorderStyleTop
              }
              ${
                SideBarLeft_HauptTitel_Farbe_Groesse_Stil_OnMouseOverAndOut
                  ? "red"
                  : SideBarLeft_HauptTitel_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
                  ? "red"
                  : DB_LeftSidebar_MenuItem_HauptTitel_BorderColorTop
              }`,
      borderBottom: `${
        SideBarLeft_HauptTitel_Farbe_Groesse_Stil_OnMouseOverAndOut
          ? "3"
          : SideBarLeft_HauptTitel_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
          ? "3"
          : DB_LeftSidebar_MenuItem_HauptTitel_BorderBottom
      }px
              ${
                SideBarLeft_HauptTitel_Farbe_Groesse_Stil_OnMouseOverAndOut
                  ? "solid"
                  : SideBarLeft_HauptTitel_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
                  ? "dotted"
                  : DB_LeftSidebar_MenuItem_HauptTitel_BorderStyleBottom
              }
              ${
                SideBarLeft_HauptTitel_Farbe_Groesse_Stil_OnMouseOverAndOut
                  ? "red"
                  : SideBarLeft_HauptTitel_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
                  ? "red"
                  : DB_LeftSidebar_MenuItem_HauptTitel_BorderColorBottom
              }`,
      borderLeft: `${
        SideBarLeft_HauptTitel_Farbe_Groesse_Stil_OnMouseOverAndOut
          ? "3"
          : SideBarLeft_HauptTitel_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
          ? "3"
          : DB_LeftSidebar_MenuItem_HauptTitel_BorderLeft
      }px
              ${
                SideBarLeft_HauptTitel_Farbe_Groesse_Stil_OnMouseOverAndOut
                  ? "solid"
                  : SideBarLeft_HauptTitel_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
                  ? "dotted"
                  : DB_LeftSidebar_MenuItem_HauptTitel_BorderStyleLeft
              }
              ${
                SideBarLeft_HauptTitel_Farbe_Groesse_Stil_OnMouseOverAndOut
                  ? "red"
                  : SideBarLeft_HauptTitel_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
                  ? "red"
                  : DB_LeftSidebar_MenuItem_HauptTitel_BorderColorLeft
              }`,
      borderRight: `${
        SideBarLeft_HauptTitel_Farbe_Groesse_Stil_OnMouseOverAndOut
          ? "3"
          : SideBarLeft_HauptTitel_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
          ? "3"
          : DB_LeftSidebar_MenuItem_HauptTitel_BorderRight
      }px
              ${
                SideBarLeft_HauptTitel_Farbe_Groesse_Stil_OnMouseOverAndOut
                  ? "solid"
                  : SideBarLeft_HauptTitel_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
                  ? "dotted"
                  : DB_LeftSidebar_MenuItem_HauptTitel_BorderStyleRight
              }
              ${
                SideBarLeft_HauptTitel_Farbe_Groesse_Stil_OnMouseOverAndOut
                  ? "red"
                  : SideBarLeft_HauptTitel_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
                  ? "red"
                  : DB_LeftSidebar_MenuItem_HauptTitel_BorderColorRight
              }`,
      borderTopRightRadius: `${DB_LeftSidebar_MenuItem_HauptTitel_BorderTopRightRadius}px`,
      borderTopLeftRadius: `${DB_LeftSidebar_MenuItem_HauptTitel_BorderTopLeftRadius}px`,
      borderBottomRightRadius: `${DB_LeftSidebar_MenuItem_HauptTitel_BorderBottomRightRadius}px`,
      borderBottomLeftRadius: `${DB_LeftSidebar_MenuItem_HauptTitel_BorderBottomLeftRadius}px`,
      marginLeft: `${
        DB_LeftSidebar_MenuItem_HauptTitel_marginLeftRight < 0
          ? Math.abs(DB_LeftSidebar_MenuItem_HauptTitel_marginLeftRight)
          : 0
      }px`,
      marginRight: `${
        DB_LeftSidebar_MenuItem_HauptTitel_marginLeftRight > 0
          ? Math.abs(DB_LeftSidebar_MenuItem_HauptTitel_marginLeftRight)
          : 0
      }px`,
      marginTop: `${
        DB_LeftSidebar_MenuItem_HauptTitel_marginTopBottom > 0
          ? Math.abs(DB_LeftSidebar_MenuItem_HauptTitel_marginTopBottom)
          : 0
      }px`,
      marginBottom: `${
        DB_LeftSidebar_MenuItem_HauptTitel_marginTopBottom < 0
          ? Math.abs(DB_LeftSidebar_MenuItem_HauptTitel_marginTopBottom)
          : 0
      }px`,
      paddingLeft: `${
        DB_LeftSidebar_MenuItem_HauptTitel_paddingLeftRight < 0
          ? Math.abs(DB_LeftSidebar_MenuItem_HauptTitel_paddingLeftRight)
          : 0
      }px`,
      paddingRight: `${
        DB_LeftSidebar_MenuItem_HauptTitel_paddingLeftRight > 0
          ? Math.abs(DB_LeftSidebar_MenuItem_HauptTitel_paddingLeftRight)
          : 0
      }px`,
      paddingTop: `${
        DB_LeftSidebar_MenuItem_HauptTitel_paddingTopBottom > 0
          ? Math.abs(DB_LeftSidebar_MenuItem_HauptTitel_paddingTopBottom)
          : 0
      }px`,
      paddingBottom: `${
        DB_LeftSidebar_MenuItem_HauptTitel_paddingTopBottom < 0
          ? Math.abs(DB_LeftSidebar_MenuItem_HauptTitel_paddingTopBottom)
          : 0
      }px`,
      letterSpacing: `${DB_LeftSidebar_MenuItem_HauptTitel_letterSpacing}px`,
      textShadow: `${DB_LeftSidebar_MenuItem_HauptTitel_ShadowHorizontal}px
              ${DB_LeftSidebar_MenuItem_HauptTitel_ShadowVertical}px
              ${DB_LeftSidebar_MenuItem_HauptTitel_ShadowBlur}px
              ${DB_LeftSidebar_MenuItem_HauptTitel_ShadowColor}`,
      textDecoration: `${DB_LeftSidebar_MenuItem_HauptTitel_TextDecoration}
              ${DB_LeftSidebar_MenuItem_HauptTitel_TextDecorationStyle}`,

      "&:hover": {
        fontFamily: `${DB_fontFace_chooseFontFamilyProduct_FontFamilyName}`,
        fontWeight: `${DB_LeftSidebar_MenuItem_HauptTitel_Hover_FontWeight}`,
        color: `${DB_LeftSidebar_MenuItem_HauptTitel_Hover_FontColor}`,
        fontSize: `${DB_LeftSidebar_MenuItem_HauptTitel_Hover_FontSize}px`,
        backgroundColor: `${DB_LeftSidebar_MenuItem_HauptTitel_Hover_BackgroundColor}`,
        textAlign: `${DB_LeftSidebar_MenuItem_HauptTitel_Hover_TextAlign}`,
        borderTop: `${DB_LeftSidebar_MenuItem_HauptTitel_Hover_BorderTop}px
                  ${DB_LeftSidebar_MenuItem_HauptTitel_Hover_BorderStyleTop}
                  ${DB_LeftSidebar_MenuItem_HauptTitel_Hover_BorderColorTop}`,
        borderBottom: `${DB_LeftSidebar_MenuItem_HauptTitel_Hover_BorderBottom}px
                  ${DB_LeftSidebar_MenuItem_HauptTitel_Hover_BorderStyleBottom}
                  ${DB_LeftSidebar_MenuItem_HauptTitel_Hover_BorderColorBottom}`,
        borderLeft: `${DB_LeftSidebar_MenuItem_HauptTitel_Hover_BorderLeft}px
                  ${DB_LeftSidebar_MenuItem_HauptTitel_Hover_BorderStyleLeft}
                  ${DB_LeftSidebar_MenuItem_HauptTitel_Hover_BorderColorLeft}`,
        borderRight: `${DB_LeftSidebar_MenuItem_HauptTitel_Hover_BorderRight}px
                  ${DB_LeftSidebar_MenuItem_HauptTitel_Hover_BorderStyleRight}
                  ${DB_LeftSidebar_MenuItem_HauptTitel_Hover_BorderColorRight}`,
        borderTopRightRadius: `${DB_LeftSidebar_MenuItem_HauptTitel_Hover_BorderTopRightRadius}px`,
        borderTopLeftRadius: `${DB_LeftSidebar_MenuItem_HauptTitel_Hover_BorderTopLeftRadius}px`,
        borderBottomRightRadius: `${DB_LeftSidebar_MenuItem_HauptTitel_Hover_BorderBottomRightRadius}px`,
        borderBottomLeftRadius: `${DB_LeftSidebar_MenuItem_HauptTitel_Hover_BorderBottomLeftRadius}px`,

        marginLeft: `${
          DB_LeftSidebar_MenuItem_HauptTitel_marginLeftRight_Hover < 0
            ? Math.abs(DB_LeftSidebar_MenuItem_HauptTitel_marginLeftRight_Hover)
            : 0
        }px`,
        marginRight: `${
          DB_LeftSidebar_MenuItem_HauptTitel_marginLeftRight_Hover > 0
            ? Math.abs(DB_LeftSidebar_MenuItem_HauptTitel_marginLeftRight_Hover)
            : 0
        }px`,
        marginTop: `${
          DB_LeftSidebar_MenuItem_HauptTitel_marginTopBottom_Hover > 0
            ? Math.abs(DB_LeftSidebar_MenuItem_HauptTitel_marginTopBottom_Hover)
            : 0
        }px`,
        marginBottom: `${
          DB_LeftSidebar_MenuItem_HauptTitel_marginTopBottom_Hover < 0
            ? Math.abs(DB_LeftSidebar_MenuItem_HauptTitel_marginTopBottom_Hover)
            : 0
        }px`,
        paddingLeft: `${
          DB_LeftSidebar_MenuItem_HauptTitel_paddingLeftRight_Hover < 0
            ? Math.abs(
                DB_LeftSidebar_MenuItem_HauptTitel_paddingLeftRight_Hover
              )
            : 0
        }px`,
        paddingRight: `${
          DB_LeftSidebar_MenuItem_HauptTitel_paddingLeftRight_Hover > 0
            ? Math.abs(
                DB_LeftSidebar_MenuItem_HauptTitel_paddingLeftRight_Hover
              )
            : 0
        }px`,
        paddingTop: `${
          DB_LeftSidebar_MenuItem_HauptTitel_paddingTopBottom_Hover > 0
            ? Math.abs(
                DB_LeftSidebar_MenuItem_HauptTitel_paddingTopBottom_Hover
              )
            : 0
        }px`,
        paddingBottom: `${
          DB_LeftSidebar_MenuItem_HauptTitel_paddingTopBottom_Hover < 0
            ? Math.abs(
                DB_LeftSidebar_MenuItem_HauptTitel_paddingTopBottom_Hover
              )
            : 0
        }px`,
        letterSpacing: `${DB_LeftSidebar_MenuItem_HauptTitel_letterSpacing_Hover}px`,
        textShadow: `${DB_LeftSidebar_MenuItem_HauptTitel_ShadowHorizontal_Hover}px
                  ${DB_LeftSidebar_MenuItem_HauptTitel_ShadowVertical_Hover}px
                  ${DB_LeftSidebar_MenuItem_HauptTitel_ShadowBlur_Hover}px
                  ${DB_LeftSidebar_MenuItem_HauptTitel_ShadowColor_Hover}`,
        textDecoration: `${DB_LeftSidebar_MenuItem_HauptTitel_TextDecoration_Hover}
                  ${DB_LeftSidebar_MenuItem_HauptTitel_TextDecorationStyle_Hover}`,
      },
    },
    produktUntertitel: {
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      width: "100%",
      fontFamily: `DB_fontFace_chooseFontFamilyProductUntertitel_FontFamilyName}`,
      fontWeight: `DB_LeftSidebar_MenuItem_UnterTitel_FontWeight}`,
      color: `DB_LeftSidebar_MenuItem_UnterTitel_FontColor}`,
      fontSize: `DB_LeftSidebar_MenuItem_UnterTitel_FontSize}px`,
      backgroundColor: `
        SideBarLeft_UnterTitel_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut
          ? "rgba(255,0,0,0.5)"
          : SideBarLeft_UnterTitel_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut
          ? "rgba(255,0,0,0.3)"
          : DB_LeftSidebar_MenuItem_UnterTitel_BackgroundColor
      }`,
      textAlign: `DB_LeftSidebar_MenuItem_UnterTitel_TextAlign}`,
      borderTop: `
        SideBarLeft_UnterTitel_Farbe_Groesse_Stil_OnMouseOverAndOut
          ? "3"
          : SideBarLeft_UnterTitel_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
          ? "3"
          : DB_LeftSidebar_MenuItem_UnterTitel_BorderTop
      }px
        ${
          SideBarLeft_UnterTitel_Farbe_Groesse_Stil_OnMouseOverAndOut
            ? "solid"
            : SideBarLeft_UnterTitel_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
            ? "dotted"
            : DB_LeftSidebar_MenuItem_UnterTitel_BorderStyleTop
        }
        ${
          SideBarLeft_UnterTitel_Farbe_Groesse_Stil_OnMouseOverAndOut
            ? "red"
            : SideBarLeft_UnterTitel_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
            ? "red"
            : DB_LeftSidebar_MenuItem_UnterTitel_BorderColorTop
        }`,
      borderBottom: `
        SideBarLeft_UnterTitel_Farbe_Groesse_Stil_OnMouseOverAndOut
          ? "3"
          : SideBarLeft_UnterTitel_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
          ? "3"
          : DB_LeftSidebar_MenuItem_UnterTitel_BorderBottom
      }px
        ${
          SideBarLeft_UnterTitel_Farbe_Groesse_Stil_OnMouseOverAndOut
            ? "solid"
            : SideBarLeft_UnterTitel_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
            ? "dotted"
            : DB_LeftSidebar_MenuItem_UnterTitel_BorderStyleBottom
        }
        ${
          SideBarLeft_UnterTitel_Farbe_Groesse_Stil_OnMouseOverAndOut
            ? "red"
            : SideBarLeft_UnterTitel_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
            ? "red"
            : DB_LeftSidebar_MenuItem_UnterTitel_BorderColorBottom
        }`,
      borderLeft: `
        SideBarLeft_UnterTitel_Farbe_Groesse_Stil_OnMouseOverAndOut
          ? "3"
          : SideBarLeft_UnterTitel_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
          ? "3"
          : DB_LeftSidebar_MenuItem_UnterTitel_BorderLeft
      }px
        ${
          SideBarLeft_UnterTitel_Farbe_Groesse_Stil_OnMouseOverAndOut
            ? "solid"
            : SideBarLeft_UnterTitel_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
            ? "dotted"
            : DB_LeftSidebar_MenuItem_UnterTitel_BorderStyleLeft
        }
        ${
          SideBarLeft_UnterTitel_Farbe_Groesse_Stil_OnMouseOverAndOut
            ? "red"
            : SideBarLeft_UnterTitel_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
            ? "red"
            : DB_LeftSidebar_MenuItem_UnterTitel_BorderColorLeft
        }`,
      borderRight: `
        SideBarLeft_UnterTitel_Farbe_Groesse_Stil_OnMouseOverAndOut
          ? "3"
          : SideBarLeft_UnterTitel_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
          ? "3"
          : DB_LeftSidebar_MenuItem_UnterTitel_BorderRight
      }px
        ${
          SideBarLeft_UnterTitel_Farbe_Groesse_Stil_OnMouseOverAndOut
            ? "solid"
            : SideBarLeft_UnterTitel_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
            ? "dotted"
            : DB_LeftSidebar_MenuItem_UnterTitel_BorderStyleRight
        }
        ${
          SideBarLeft_UnterTitel_Farbe_Groesse_Stil_OnMouseOverAndOut
            ? "red"
            : SideBarLeft_UnterTitel_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
            ? "red"
            : DB_LeftSidebar_MenuItem_UnterTitel_BorderColorRight
        }`,
      borderTopRightRadius: `DB_LeftSidebar_MenuItem_UnterTitel_BorderTopRightRadius}px`,
      borderTopLeftRadius: `DB_LeftSidebar_MenuItem_UnterTitel_BorderTopLeftRadius}px`,
      borderBottomRightRadius: `DB_LeftSidebar_MenuItem_UnterTitel_BorderBottomRightRadius}px`,
      borderBottomLeftRadius: `DB_LeftSidebar_MenuItem_UnterTitel_BorderBottomLeftRadius}px`,
      marginLeft: `
        DB_LeftSidebar_MenuItem_UnterTitel_marginLeftRight < 0
          ? Math.abs(DB_LeftSidebar_MenuItem_UnterTitel_marginLeftRight)
          : 0
      }px`,
      marginRight: `
        DB_LeftSidebar_MenuItem_UnterTitel_marginLeftRight > 0
          ? Math.abs(DB_LeftSidebar_MenuItem_UnterTitel_marginLeftRight)
          : 0
      }px`,
      marginTop: `
        DB_LeftSidebar_MenuItem_UnterTitel_marginTopBottom > 0
          ? Math.abs(DB_LeftSidebar_MenuItem_UnterTitel_marginTopBottom)
          : 0
      }px`,
      marginBottom: "0px",

      paddingLeft: `
        DB_LeftSidebar_MenuItem_UnterTitel_paddingLeftRight < 0
          ? Math.abs(DB_LeftSidebar_MenuItem_UnterTitel_paddingLeftRight)
          : 0
      }px`,
      paddingRight: `
        DB_LeftSidebar_MenuItem_UnterTitel_paddingLeftRight > 0
          ? Math.abs(DB_LeftSidebar_MenuItem_UnterTitel_paddingLeftRight)
          : 0
      }px`,
      paddingTop: `
        DB_LeftSidebar_MenuItem_UnterTitel_paddingTopBottom > 0
          ? Math.abs(DB_LeftSidebar_MenuItem_UnterTitel_paddingTopBottom)
          : 0
      }px`,
      paddingBottom: `
        DB_LeftSidebar_MenuItem_UnterTitel_paddingTopBottom < 0
          ? Math.abs(DB_LeftSidebar_MenuItem_UnterTitel_paddingTopBottom)
          : 0
      }px`,
      letterSpacing: `DB_LeftSidebar_MenuItem_UnterTitel_letterSpacing}px`,
      textShadow: `DB_LeftSidebar_MenuItem_UnterTitel_ShadowHorizontal}px
        ${DB_LeftSidebar_MenuItem_UnterTitel_ShadowVertical}px
        ${DB_LeftSidebar_MenuItem_UnterTitel_ShadowBlur}px
        ${DB_LeftSidebar_MenuItem_UnterTitel_ShadowColor}`,
      textDecoration: `DB_LeftSidebar_MenuItem_UnterTitel_TextDecoration}
        ${DB_LeftSidebar_MenuItem_UnterTitel_TextDecorationStyle}`,
      "&:hover": {
        fontFamily: `${DB_LeftSidebar_MenuItem_UnterTitel_Hover_FontFace}`,
        fontWeight: `${DB_LeftSidebar_MenuItem_UnterTitel_Hover_FontWeight}`,
        color: `${DB_LeftSidebar_MenuItem_UnterTitel_Hover_FontColor}`,
        fontSize: `${DB_LeftSidebar_MenuItem_UnterTitel_Hover_FontSize}px`,
        backgroundColor: `${DB_LeftSidebar_MenuItem_UnterTitel_Hover_BackgroundColor}`,
        textAlign: `${DB_LeftSidebar_MenuItem_UnterTitel_Hover_TextAlign}`,
        borderTop: `${DB_LeftSidebar_MenuItem_UnterTitel_Hover_BorderTop}px
            ${DB_LeftSidebar_MenuItem_UnterTitel_Hover_BorderStyleTop}
            ${DB_LeftSidebar_MenuItem_UnterTitel_Hover_BorderColorTop}`,
        borderBottom: `${DB_LeftSidebar_MenuItem_UnterTitel_Hover_BorderBottom}px
            ${DB_LeftSidebar_MenuItem_UnterTitel_Hover_BorderStyleBottom}
            ${DB_LeftSidebar_MenuItem_UnterTitel_Hover_BorderColorBottom}`,
        borderLeft: `${DB_LeftSidebar_MenuItem_UnterTitel_Hover_BorderLeft}px
            ${DB_LeftSidebar_MenuItem_UnterTitel_Hover_BorderStyleLeft}
            ${DB_LeftSidebar_MenuItem_UnterTitel_Hover_BorderColorLeft}`,
        borderRight: `${DB_LeftSidebar_MenuItem_UnterTitel_Hover_BorderRight}px
            ${DB_LeftSidebar_MenuItem_UnterTitel_Hover_BorderStyleRight}
            ${DB_LeftSidebar_MenuItem_UnterTitel_Hover_BorderColorRight}`,
        borderTopRightRadius: `${DB_LeftSidebar_MenuItem_UnterTitel_Hover_BorderTopRightRadius}px`,
        borderTopLeftRadius: `${DB_LeftSidebar_MenuItem_UnterTitel_Hover_BorderTopLeftRadius}px`,
        borderBottomRightRadius: `${DB_LeftSidebar_MenuItem_UnterTitel_Hover_BorderBottomRightRadius}px`,
        borderBottomLeftRadius: `${DB_LeftSidebar_MenuItem_UnterTitel_Hover_BorderBottomLeftRadius}px`,

        marginLeft: `${
          DB_LeftSidebar_MenuItem_UnterTitel_marginLeftRight_Hover < 0
            ? Math.abs(DB_LeftSidebar_MenuItem_UnterTitel_marginLeftRight_Hover)
            : 0
        }px`,
        marginRight: `${
          DB_LeftSidebar_MenuItem_UnterTitel_marginLeftRight_Hover > 0
            ? Math.abs(DB_LeftSidebar_MenuItem_UnterTitel_marginLeftRight_Hover)
            : 0
        }px`,
        marginTop: `${
          DB_LeftSidebar_MenuItem_UnterTitel_marginTopBottom_Hover > 0
            ? Math.abs(DB_LeftSidebar_MenuItem_UnterTitel_marginTopBottom_Hover)
            : 0
        }px`,
        marginBottom: `${
          DB_LeftSidebar_MenuItem_UnterTitel_marginTopBottom_Hover < 0
            ? Math.abs(DB_LeftSidebar_MenuItem_UnterTitel_marginTopBottom_Hover)
            : 0
        }px`,

        paddingLeft: `${
          DB_LeftSidebar_MenuItem_UnterTitel_paddingLeftRight_Hover < 0
            ? Math.abs(
                DB_LeftSidebar_MenuItem_UnterTitel_paddingLeftRight_Hover
              )
            : 0
        }px`,
        paddingRight: `${
          DB_LeftSidebar_MenuItem_UnterTitel_paddingLeftRight_Hover > 0
            ? Math.abs(
                DB_LeftSidebar_MenuItem_UnterTitel_paddingLeftRight_Hover
              )
            : 0
        }px`,
        paddingTop: `${
          DB_LeftSidebar_MenuItem_UnterTitel_paddingTopBottom_Hover > 0
            ? Math.abs(
                DB_LeftSidebar_MenuItem_UnterTitel_paddingTopBottom_Hover
              )
            : 0
        }px`,
        paddingBottom: `${
          DB_LeftSidebar_MenuItem_UnterTitel_paddingTopBottom_Hover < 0
            ? Math.abs(
                DB_LeftSidebar_MenuItem_UnterTitel_paddingTopBottom_Hover
              )
            : 0
        }px`,
        letterSpacing: `${DB_LeftSidebar_MenuItem_UnterTitel_letterSpacing_Hover}px`,
        textShadow: `${DB_LeftSidebar_MenuItem_UnterTitel_ShadowHorizontal_Hover}px
            ${DB_LeftSidebar_MenuItem_UnterTitel_ShadowVertical_Hover}px
            ${DB_LeftSidebar_MenuItem_UnterTitel_ShadowBlur_Hover}px
            ${DB_LeftSidebar_MenuItem_UnterTitel_ShadowColor_Hover}`,
        textDecoration: `${DB_LeftSidebar_MenuItem_UnterTitel_TextDecoration_Hover}
          ${DB_LeftSidebar_MenuItem_UnterTitel_TextDecorationStyle_Hover}`,
      },
    },
    imageHeight: {
      height: "196px",
      backgroundColor: `${DB_LeftSidebar_MenuProduktImageContainer_BackgroundColor}`,
      borderBottomLeftRadius: "0px",
      borderBottomRightRadius: "0px",
      borderTopRightRadius: `${DB_LeftSidebar_MenuItem_MenuProduktContainer_BorderTopRightRadius}px`,
      borderTopLeftRadius: ` ${DB_LeftSidebar_MenuItem_MenuProduktContainer_BorderTopLeftRadius}px`,
      "&:hover": {
        backgroundColor: `${DB_LeftSidebar_MenuProduktImageContainer_Hover_BackgroundColor}`,
      },
    },

    imageHeightTwo: {
      height: "302px",
      backgroundColor: `${DB_LeftSidebar_MenuProduktImageContainer_BackgroundColor}`,
      borderBottomLeftRadius: "0px",
      borderBottomRightRadius: "0px",
      borderTopRightRadius: `${DB_LeftSidebar_MenuItem_MenuProduktContainer_BorderTopRightRadius}px`,
      borderTopLeftRadius: `${DB_LeftSidebar_MenuItem_MenuProduktContainer_BorderTopLeftRadius}px`,

      "&:hover": {
        backgroundColor: `${DB_LeftSidebar_MenuProduktImageContainer_Hover_BackgroundColor}`,
      },
    },

    imageHeightFour: {
      height: "142px",
      backgroundColor: `${DB_LeftSidebar_MenuProduktImageContainer_BackgroundColor}`,
      borderBottomLeftRadius: "0px",
      borderBottomRightRadius: "0px",
      borderTopRightRadius: `${DB_LeftSidebar_MenuItem_MenuProduktContainer_BorderTopRightRadius}px`,
      borderTopLeftRadius: `${DB_LeftSidebar_MenuItem_MenuProduktContainer_BorderTopLeftRadius}px`,

      "&:hover": {
        backgroundColor: `${DB_LeftSidebar_MenuProduktImageContainer_Hover_BackgroundColor}`,
      },
    },
    imageHeightSingle: {
      width: "130px",
      height: "100%",
      backgroundColor: `${DB_LeftSidebar_MenuProduktImageContainer_BackgroundColor}`,
      borderTopLeftRadius: `${DB_LeftSidebar_MenuItem_MenuProduktContainer_BorderTopLeftRadius}px`,
      borderBottomLeftRadius: `${DB_LeftSidebar_MenuItem_MenuProduktContainer_BorderBottomLeftRadius}px`,

      "&:hover": {
        backgroundColor: `${DB_LeftSidebar_MenuProduktImageContainer_Hover_BackgroundColor}`,
      },
    },
    productImagesTwoThreeFour: {
      borderTopRightRadius: `${DB_LeftSidebar_MenuItem_MenuProduktContainer_BorderTopRightRadius}px`,
      borderTopLeftRadius: `${DB_LeftSidebar_MenuItem_MenuProduktContainer_BorderTopLeftRadius}px`,
      "&:hover": {
        borderTopRightRadius: `${DB_LeftSidebar_MenuItem_MenuProduktContainer_Hover_BorderTopRightRadius}px`,
        borderTopLeftRadius: `${DB_LeftSidebar_MenuItem_MenuProduktContainer_Hover_BorderTopLeftRadius}px`,
      },
    },
    productImagesOne: {
      borderBottomLeftRadius: `${DB_LeftSidebar_MenuItem_MenuProduktContainer_BorderBottomLeftRadius}px`,
      borderTopLeftRadius: `${DB_LeftSidebar_MenuItem_MenuProduktContainer_BorderTopLeftRadius}px`,
      "&:hover": {
        borderBottomLeftRadius: `${DB_LeftSidebar_MenuItem_MenuProduktContainer_Hover_BorderBottomLeftRadius}px`,
        borderTopLeftRadius: `${DB_LeftSidebar_MenuItem_MenuProduktContainer_Hover_BorderTopLeftRadius}px`,
      },
    },
  });

  const classes = useStyles();

  return allProductsState.map((product, index) => {
    return (
      <React.Fragment key={product.id + "ReactFragment"}>
        <React.Fragment key={"productID" + product.id}>
          {(() => {
            if (columnNumber === "12") {
              return (
                <div
                  className={"col-lg-" + columnNumber + " mt-3 col-12"}
                  key={"12" + "-" + product.id}
                >
                  <ProductCardContainerTwelfe
                    DB_LeftSidebar_MenuProduktImageContainer_Hover_BackgroundColor={
                      DB_LeftSidebar_MenuProduktImageContainer_Hover_BackgroundColor
                    }
                    DB_BodyProductCard_BorderRadius={
                      DB_BodyProductCard_BorderRadius
                    }
                    DB_BodyProductCard_BorderTop_Width={
                      DB_BodyProductCard_BorderTop_Width
                    }
                    DB_BodyProductCard_BorderTop_Style={
                      DB_BodyProductCard_BorderTop_Style
                    }
                    DB_BodyProductCard_BorderTop_Color={
                      DB_BodyProductCard_BorderTop_Color
                    }
                    DB_BodyProductCard_BorderBottom_Width={
                      DB_BodyProductCard_BorderBottom_Width
                    }
                    DB_BodyProductCard_BorderBottom_Style={
                      DB_BodyProductCard_BorderBottom_Style
                    }
                    DB_BodyProductCard_BorderBottom_Color={
                      DB_BodyProductCard_BorderBottom_Color
                    }
                    DB_BodyProductCard_BorderLeft_Width={
                      DB_BodyProductCard_BorderLeft_Width
                    }
                    DB_BodyProductCard_BorderLeft_Style={
                      DB_BodyProductCard_BorderLeft_Style
                    }
                    DB_BodyProductCard_BorderLeft_Color={
                      DB_BodyProductCard_BorderLeft_Color
                    }
                    DB_BodyProductCard_BorderRight_Width={
                      DB_BodyProductCard_BorderRight_Width
                    }
                    DB_BodyProductCard_BorderRight_Style={
                      DB_BodyProductCard_BorderRight_Style
                    }
                    DB_BodyProductCard_BorderRight_Color={
                      DB_BodyProductCard_BorderRight_Color
                    }
                    SideBarLeft_ProduktContainer_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut={
                      SideBarLeft_ProduktContainer_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut
                    }
                    SideBarLeft_ProduktContainer_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut={
                      SideBarLeft_ProduktContainer_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut
                    }
                    DB_LeftSidebar_MenuProduktContainer_BackgroundColor={
                      DB_LeftSidebar_MenuProduktContainer_BackgroundColor
                    }
                    DB_LeftSidebar_MenuProduktContainer_PaddingTopBottom={
                      DB_LeftSidebar_MenuProduktContainer_PaddingTopBottom
                    }
                    DB_LeftSidebar_MenuProduktContainer_PaddingLeftRight={
                      DB_LeftSidebar_MenuProduktContainer_PaddingLeftRight
                    }
                    SideBarLeft_ProduktContainer_Farbe_Groesse_Stil_OnMouseOverAndOut={
                      SideBarLeft_ProduktContainer_Farbe_Groesse_Stil_OnMouseOverAndOut
                    }
                    SideBarLeft_ProduktContainer_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut={
                      SideBarLeft_ProduktContainer_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
                    }
                    DB_LeftSidebar_MenuProduktContainer_BorderTop={
                      DB_LeftSidebar_MenuProduktContainer_BorderTop
                    }
                    DB_LeftSidebar_MenuProduktContainer_BorderStyleTop={
                      DB_LeftSidebar_MenuProduktContainer_BorderStyleTop
                    }
                    DB_LeftSidebar_MenuProduktContainer_BorderColorTop={
                      DB_LeftSidebar_MenuProduktContainer_BorderColorTop
                    }
                    DB_LeftSidebar_MenuProduktContainer_BorderBottom={
                      DB_LeftSidebar_MenuProduktContainer_BorderBottom
                    }
                    DB_LeftSidebar_MenuProduktContainer_BorderStyleBottom={
                      DB_LeftSidebar_MenuProduktContainer_BorderStyleBottom
                    }
                    DB_LeftSidebar_MenuProduktContainer_BorderColorBottom={
                      DB_LeftSidebar_MenuProduktContainer_BorderColorBottom
                    }
                    DB_LeftSidebar_MenuProduktContainer_BorderLeft={
                      DB_LeftSidebar_MenuProduktContainer_BorderLeft
                    }
                    DB_LeftSidebar_MenuProduktContainer_BorderStyleLeft={
                      DB_LeftSidebar_MenuProduktContainer_BorderStyleLeft
                    }
                    DB_LeftSidebar_MenuProduktContainer_BorderColorLeft={
                      DB_LeftSidebar_MenuProduktContainer_BorderColorLeft
                    }
                    DB_LeftSidebar_MenuProduktContainer_BorderRight={
                      DB_LeftSidebar_MenuProduktContainer_BorderRight
                    }
                    DB_LeftSidebar_MenuProduktContainer_BorderStyleRight={
                      DB_LeftSidebar_MenuProduktContainer_BorderStyleRight
                    }
                    DB_LeftSidebar_MenuProduktContainer_BorderColorRight={
                      DB_LeftSidebar_MenuProduktContainer_BorderColorRight
                    }
                    DB_LeftSidebar_MenuItem_MenuProduktContainer_BorderTopRightRadius={
                      DB_LeftSidebar_MenuItem_MenuProduktContainer_BorderTopRightRadius
                    }
                    DB_LeftSidebar_MenuItem_MenuProduktContainer_BorderTopLeftRadius={
                      DB_LeftSidebar_MenuItem_MenuProduktContainer_BorderTopLeftRadius
                    }
                    DB_LeftSidebar_MenuItem_MenuProduktContainer_BorderBottomRightRadius={
                      DB_LeftSidebar_MenuItem_MenuProduktContainer_BorderBottomRightRadius
                    }
                    DB_LeftSidebar_MenuItem_MenuProduktContainer_BorderBottomLeftRadius={
                      DB_LeftSidebar_MenuItem_MenuProduktContainer_BorderBottomLeftRadius
                    }
                    DB_LeftSidebar_MenuItem_MenuProduktContainer_paddingLeft={
                      DB_LeftSidebar_MenuItem_MenuProduktContainer_paddingLeft
                    }
                    DB_LeftSidebar_MenuItem_MenuProduktContainer_paddingRight={
                      DB_LeftSidebar_MenuItem_MenuProduktContainer_paddingRight
                    }
                    DB_LeftSidebar_MenuItem_MenuProduktContainer_paddingTop={
                      DB_LeftSidebar_MenuItem_MenuProduktContainer_paddingTop
                    }
                    DB_LeftSidebar_MenuItem_MenuProduktContainer_paddingBottom={
                      DB_LeftSidebar_MenuItem_MenuProduktContainer_paddingBottom
                    }
                    DB_LeftSidebar_MenuItem_MenuProduktContainer_ShadowHorizontal={
                      DB_LeftSidebar_MenuItem_MenuProduktContainer_ShadowHorizontal
                    }
                    DB_LeftSidebar_MenuItem_MenuProduktContainer_ShadowBlur={
                      DB_LeftSidebar_MenuItem_MenuProduktContainer_ShadowBlur
                    }
                    DB_LeftSidebar_MenuItem_MenuProduktContainer_ShadowColor={
                      DB_LeftSidebar_MenuItem_MenuProduktContainer_ShadowColor
                    }
                    DB_LeftSidebar_MenuProduktContainer_Hover_BackgroundColor={
                      DB_LeftSidebar_MenuProduktContainer_Hover_BackgroundColor
                    }
                    DB_LeftSidebar_MenuProduktContainer_Hover_PaddingTopBottom={
                      DB_LeftSidebar_MenuProduktContainer_Hover_PaddingTopBottom
                    }
                    DB_LeftSidebar_MenuProduktContainer_Hover_PaddingLeftRight={
                      DB_LeftSidebar_MenuProduktContainer_Hover_PaddingLeftRight
                    }
                    DB_LeftSidebar_MenuProduktContainer_Hover_BorderTop={
                      DB_LeftSidebar_MenuProduktContainer_Hover_BorderTop
                    }
                    DB_LeftSidebar_MenuProduktContainer_Hover_BorderStyleTop={
                      DB_LeftSidebar_MenuProduktContainer_Hover_BorderStyleTop
                    }
                    DB_LeftSidebar_MenuProduktContainer_Hover_BorderColorTop={
                      DB_LeftSidebar_MenuProduktContainer_Hover_BorderColorTop
                    }
                    DB_LeftSidebar_MenuProduktContainer_Hover_BorderBottom={
                      DB_LeftSidebar_MenuProduktContainer_Hover_BorderBottom
                    }
                    DB_LeftSidebar_MenuProduktContainer_Hover_BorderStyleBottom={
                      DB_LeftSidebar_MenuProduktContainer_Hover_BorderStyleBottom
                    }
                    DB_LeftSidebar_MenuProduktContainer_Hover_BorderColorBottom={
                      DB_LeftSidebar_MenuProduktContainer_Hover_BorderColorBottom
                    }
                    DB_LeftSidebar_MenuProduktContainer_Hover_BorderLeft={
                      DB_LeftSidebar_MenuProduktContainer_Hover_BorderLeft
                    }
                    DB_LeftSidebar_MenuProduktContainer_Hover_BorderStyleLeft={
                      DB_LeftSidebar_MenuProduktContainer_Hover_BorderStyleLeft
                    }
                    DB_LeftSidebar_MenuProduktContainer_Hover_BorderColorLeft={
                      DB_LeftSidebar_MenuProduktContainer_Hover_BorderColorLeft
                    }
                    DB_LeftSidebar_MenuProduktContainer_Hover_BorderRight={
                      DB_LeftSidebar_MenuProduktContainer_Hover_BorderRight
                    }
                    DB_LeftSidebar_MenuProduktContainer_Hover_BorderStyleRight={
                      DB_LeftSidebar_MenuProduktContainer_Hover_BorderStyleRight
                    }
                    DB_LeftSidebar_MenuProduktContainer_Hover_BorderColorRight={
                      DB_LeftSidebar_MenuProduktContainer_Hover_BorderColorRight
                    }
                    DB_LeftSidebar_MenuItem_MenuProduktContainer_Hover_BorderTopRightRadius={
                      DB_LeftSidebar_MenuItem_MenuProduktContainer_Hover_BorderTopRightRadius
                    }
                    DB_LeftSidebar_MenuItem_MenuProduktContainer_Hover_BorderTopLeftRadius={
                      DB_LeftSidebar_MenuItem_MenuProduktContainer_Hover_BorderTopLeftRadius
                    }
                    DB_LeftSidebar_MenuItem_MenuProduktContainer_Hover_BorderBottomRightRadius={
                      DB_LeftSidebar_MenuItem_MenuProduktContainer_Hover_BorderBottomRightRadius
                    }
                    DB_LeftSidebar_MenuItem_MenuProduktContainer_Hover_BorderBottomLeftRadius={
                      DB_LeftSidebar_MenuItem_MenuProduktContainer_Hover_BorderBottomLeftRadius
                    }
                    DB_LeftSidebar_MenuItem_MenuProduktContainer_Hover_paddingLeft={
                      DB_LeftSidebar_MenuItem_MenuProduktContainer_Hover_paddingLeft
                    }
                    DB_LeftSidebar_MenuItem_MenuProduktContainer_Hover_paddingRight={
                      DB_LeftSidebar_MenuItem_MenuProduktContainer_Hover_paddingRight
                    }
                    DB_LeftSidebar_MenuItem_MenuProduktContainer_Hover_paddingTop={
                      DB_LeftSidebar_MenuItem_MenuProduktContainer_Hover_paddingTop
                    }
                    DB_LeftSidebar_MenuItem_MenuProduktContainer_Hover_paddingBottom={
                      DB_LeftSidebar_MenuItem_MenuProduktContainer_Hover_paddingBottom
                    }
                    DB_LeftSidebar_MenuItem_MenuProduktContainer_ShadowVertical={
                      DB_LeftSidebar_MenuItem_MenuProduktContainer_ShadowVertical
                    }
                    DB_LeftSidebar_MenuItem_MenuProduktContainer_Hover_ShadowHorizontal={
                      DB_LeftSidebar_MenuItem_MenuProduktContainer_Hover_ShadowHorizontal
                    }
                    DB_LeftSidebar_MenuItem_MenuProduktContainer_Hover_ShadowBlur={
                      DB_LeftSidebar_MenuItem_MenuProduktContainer_Hover_ShadowBlur
                    }
                    DB_LeftSidebar_MenuItem_MenuProduktContainer_Hover_ShadowColor={
                      DB_LeftSidebar_MenuItem_MenuProduktContainer_Hover_ShadowColor
                    }
                    DB_LeftSidebar_MenuItem_MenuProduktContainer_Hover_ShadowVertical={
                      DB_LeftSidebar_MenuItem_MenuProduktContainer_Hover_ShadowVertical
                    }
                  >
                    <div
                      style={{
                        display: "inline-block",
                        position: "relative",
                        float: "left",
                      }}
                      className={classes.imageHeightSingle}
                    >
                      {product.mainImage && (
                        <div
                          /* href={`?s=${product.titel}&t=${trace}`} */
                          style={{
                            cursor: "pointer",
                            textDecoration: "none",
                            objectFit: "contain",
                          }}
                          className={classes.productImagesOne}
                        >
                          <img
                            onClick={(productIdentifier) =>
                              showProductDetailsHandler(product.titel)
                            }
                            alt={product.titel}
                            src={`${process.env.REACT_APP_PUBLIC_SERVER_URL}/${product.mainImage}`}
                            className={classes.productImagesOne}
                            style={{
                              objectFit: "contain",
                              cursor: "pointer",
                              width: "100%",
                              height: "100%",
                              position: "absolute",
                            }}
                          />
                        </div>
                      )}
                    </div>
                    <div
                      className="productDescription"
                      style={{
                        display: "inline-block",
                        width: "calc(100% - 200px)",
                        borderBottomLeftRadius: "0px",
                        borderTopRightRadius: `${DB_LeftSidebar_MenuItem_MenuProduktContainer_BorderTopRightRadius}px`,
                        borderBottomRightRadius: `${DB_LeftSidebar_MenuItem_MenuProduktContainer_BorderBottomRightRadius}px`,
                      }}
                    >
                      <div
                        /* href={`?s=${product.titel}&t=${trace}`} */
                        style={{ cursor: "pointer", textDecoration: "none" }}
                        className={`card-title ${classes.produktTitel}`}
                      >
                        <div
                          onClick={(productIdentifier) =>
                            showProductDetailsHandler(product.titel)
                          }
                        >
                          {product.titel}
                        </div>
                      </div>
                      {product.untertitel ? (
                        <div
                          /* href={`?s=${product.titel}&t=${trace}`} */
                          style={{
                            cursor: "pointer",
                            textDecoration: "none",
                          }}
                        >
                          <div
                            className={`card-text ${classes.produktUntertitel}`}
                            onClick={(productIdentifier) =>
                              showProductDetailsHandler(product.titel)
                            }
                          >
                            {product.untertitel}
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}
                      <ProductCardPreisWaehrung
                        productTitle={product.titel}
                        showProductDetailsHandler={showProductDetailsHandler}
                        productFixpreis={product.fixpreis}
                        productAktionspreis={product.startpreis}
                        DB_BodyProductCard_Waehrung={
                          DB_BodyProductCard_Waehrung
                        }
                        DB_fontFace_chooseFontWaehrung_FontFamilyName={
                          DB_fontFace_chooseFontWaehrung_FontFamilyName
                        }
                        DB_fontFace_chooseFontWaehrung_FontFamilySrc={
                          DB_fontFace_chooseFontWaehrung_FontFamilySrc
                        }
                        DB_fontFace_chooseFontWaehrungEuro_FontFamilyName={
                          DB_fontFace_chooseFontWaehrungEuro_FontFamilyName
                        }
                        DB_fontFace_chooseFontWaehrungEuro_FontFamilySrc={
                          DB_fontFace_chooseFontWaehrungEuro_FontFamilySrc
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_FontWeight={
                          DB_LeftSidebar_MenuItem_ProduktPreis_FontWeight
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_Hover_FontWeight={
                          DB_LeftSidebar_MenuItem_ProduktPreis_Hover_FontWeight
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_FontColor={
                          DB_LeftSidebar_MenuItem_ProduktPreis_FontColor
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_Hover_FontColor={
                          DB_LeftSidebar_MenuItem_ProduktPreis_Hover_FontColor
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_FontSize={
                          DB_LeftSidebar_MenuItem_ProduktPreis_FontSize
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_Hover_FontSize={
                          DB_LeftSidebar_MenuItem_ProduktPreis_Hover_FontSize
                        }
                        SideBarLeft_ProduktPreis_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut={
                          SideBarLeft_ProduktPreis_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut
                        }
                        SideBarLeft_ProduktPreis_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut={
                          SideBarLeft_ProduktPreis_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_BackgroundColor={
                          DB_LeftSidebar_MenuItem_ProduktPreis_BackgroundColor
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BackgroundColor={
                          DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BackgroundColor
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_TextAlign={
                          DB_LeftSidebar_MenuItem_ProduktPreis_TextAlign
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_Hover_TextAlign={
                          DB_LeftSidebar_MenuItem_ProduktPreis_Hover_TextAlign
                        }
                        SideBarLeft_ProduktPreis_Farbe_Groesse_Stil_OnMouseOverAndOut={
                          SideBarLeft_ProduktPreis_Farbe_Groesse_Stil_OnMouseOverAndOut
                        }
                        SideBarLeft_ProduktPreis_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut={
                          SideBarLeft_ProduktPreis_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_BorderTop={
                          DB_LeftSidebar_MenuItem_ProduktPreis_BorderTop
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderTop={
                          DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderTop
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_BorderStyleTop={
                          DB_LeftSidebar_MenuItem_ProduktPreis_BorderStyleTop
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_BorderColorTop={
                          DB_LeftSidebar_MenuItem_ProduktPreis_BorderColorTop
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_BorderBottom={
                          DB_LeftSidebar_MenuItem_ProduktPreis_BorderBottom
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_BorderStyleBottom={
                          DB_LeftSidebar_MenuItem_ProduktPreis_BorderStyleBottom
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_BorderColorBottom={
                          DB_LeftSidebar_MenuItem_ProduktPreis_BorderColorBottom
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_BorderLeft={
                          DB_LeftSidebar_MenuItem_ProduktPreis_BorderLeft
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_BorderStyleLeft={
                          DB_LeftSidebar_MenuItem_ProduktPreis_BorderStyleLeft
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_BorderColorLeft={
                          DB_LeftSidebar_MenuItem_ProduktPreis_BorderColorLeft
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_BorderRight={
                          DB_LeftSidebar_MenuItem_ProduktPreis_BorderRight
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_BorderStyleRight={
                          DB_LeftSidebar_MenuItem_ProduktPreis_BorderStyleRight
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_BorderColorRight={
                          DB_LeftSidebar_MenuItem_ProduktPreis_BorderColorRight
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_BorderTopRightRadius={
                          DB_LeftSidebar_MenuItem_ProduktPreis_BorderTopRightRadius
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_BorderTopLeftRadius={
                          DB_LeftSidebar_MenuItem_ProduktPreis_BorderTopLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_BorderBottomRightRadius={
                          DB_LeftSidebar_MenuItem_ProduktPreis_BorderBottomRightRadius
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_BorderBottomLeftRadius={
                          DB_LeftSidebar_MenuItem_ProduktPreis_BorderBottomLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderTopRightRadius={
                          DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderTopRightRadius
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderTopLeftRadius={
                          DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderTopLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderBottomRightRadius={
                          DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderBottomRightRadius
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderBottomLeftRadius={
                          DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderBottomLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderStyleTop={
                          DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderStyleTop
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderColorTop={
                          DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderColorTop
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderBottom={
                          DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderBottom
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderStyleBottom={
                          DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderStyleBottom
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderColorBottom={
                          DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderColorBottom
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderLeft={
                          DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderLeft
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderStyleLeft={
                          DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderStyleLeft
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderColorLeft={
                          DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderColorLeft
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderRight={
                          DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderRight
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderStyleRight={
                          DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderStyleRight
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderColorRight={
                          DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderColorRight
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_marginTopBottom={
                          DB_LeftSidebar_MenuItem_ProduktPreis_marginTopBottom
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_marginLeftRight={
                          DB_LeftSidebar_MenuItem_ProduktPreis_marginLeftRight
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_paddingLeftRight={
                          DB_LeftSidebar_MenuItem_ProduktPreis_paddingLeftRight
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_paddingTopBottom={
                          DB_LeftSidebar_MenuItem_ProduktPreis_paddingTopBottom
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_letterSpacing={
                          DB_LeftSidebar_MenuItem_ProduktPreis_letterSpacing
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_ShadowHorizontal={
                          DB_LeftSidebar_MenuItem_ProduktPreis_ShadowHorizontal
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_ShadowVertical={
                          DB_LeftSidebar_MenuItem_ProduktPreis_ShadowVertical
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_ShadowBlur={
                          DB_LeftSidebar_MenuItem_ProduktPreis_ShadowBlur
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_ShadowColor={
                          DB_LeftSidebar_MenuItem_ProduktPreis_ShadowColor
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_TextDecoration={
                          DB_LeftSidebar_MenuItem_ProduktPreis_TextDecoration
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_TextDecorationStyle={
                          DB_LeftSidebar_MenuItem_ProduktPreis_TextDecorationStyle
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_marginTopBottom_Hover={
                          DB_LeftSidebar_MenuItem_ProduktPreis_marginTopBottom_Hover
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_marginLeftRight_Hover={
                          DB_LeftSidebar_MenuItem_ProduktPreis_marginLeftRight_Hover
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_paddingLeftRight_Hover={
                          DB_LeftSidebar_MenuItem_ProduktPreis_paddingLeftRight_Hover
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_paddingTopBottom_Hover={
                          DB_LeftSidebar_MenuItem_ProduktPreis_paddingTopBottom_Hover
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_letterSpacing_Hover={
                          DB_LeftSidebar_MenuItem_ProduktPreis_letterSpacing_Hover
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_ShadowHorizontal_Hover={
                          DB_LeftSidebar_MenuItem_ProduktPreis_ShadowHorizontal_Hover
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_ShadowVertical_Hover={
                          DB_LeftSidebar_MenuItem_ProduktPreis_ShadowVertical_Hover
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_ShadowBlur_Hover={
                          DB_LeftSidebar_MenuItem_ProduktPreis_ShadowBlur_Hover
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_ShadowColor_Hover={
                          DB_LeftSidebar_MenuItem_ProduktPreis_ShadowColor_Hover
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_TextDecoration_Hover={
                          DB_LeftSidebar_MenuItem_ProduktPreis_TextDecoration_Hover
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_TextDecorationStyle_Hover={
                          DB_LeftSidebar_MenuItem_ProduktPreis_TextDecorationStyle_Hover
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_FontWeight={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_FontWeight
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_FontWeight={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_FontWeight
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_FontColor={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_FontColor
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_FontColor={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_FontColor
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_FontSize={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_FontSize
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_FontSize={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_FontSize
                        }
                        SideBarLeft_ProduktWaehrung_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut={
                          SideBarLeft_ProduktWaehrung_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut
                        }
                        SideBarLeft_ProduktWaehrung_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut={
                          SideBarLeft_ProduktWaehrung_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_BackgroundColor={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_BackgroundColor
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BackgroundColor={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BackgroundColor
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_TextAlign={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_TextAlign
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_TextAlign={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_TextAlign
                        }
                        SideBarLeft_ProduktWaehrung_Farbe_Groesse_Stil_OnMouseOverAndOut={
                          SideBarLeft_ProduktWaehrung_Farbe_Groesse_Stil_OnMouseOverAndOut
                        }
                        SideBarLeft_ProduktWaehrung_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut={
                          SideBarLeft_ProduktWaehrung_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_BorderTop={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_BorderTop
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderTop={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderTop
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_BorderStyleTop={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_BorderStyleTop
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_BorderColorTop={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_BorderColorTop
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_BorderBottom={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_BorderBottom
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_BorderStyleBottom={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_BorderStyleBottom
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_BorderColorBottom={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_BorderColorBottom
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_BorderLeft={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_BorderLeft
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_BorderStyleLeft={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_BorderStyleLeft
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_BorderColorLeft={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_BorderColorLeft
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_BorderRight={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_BorderRight
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_BorderStyleRight={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_BorderStyleRight
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_BorderColorRight={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_BorderColorRight
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_BorderTopRightRadius={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_BorderTopRightRadius
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_BorderTopLeftRadius={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_BorderTopLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_BorderBottomRightRadius={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_BorderBottomRightRadius
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_BorderBottomLeftRadius={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_BorderBottomLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderTopRightRadius={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderTopRightRadius
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderTopLeftRadius={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderTopLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderBottomRightRadius={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderBottomRightRadius
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderBottomLeftRadius={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderBottomLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderStyleTop={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderStyleTop
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderColorTop={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderColorTop
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderBottom={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderBottom
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderStyleBottom={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderStyleBottom
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderColorBottom={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderColorBottom
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderLeft={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderLeft
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderStyleLeft={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderStyleLeft
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderColorLeft={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderColorLeft
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderRight={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderRight
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderStyleRight={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderStyleRight
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderColorRight={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderColorRight
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_marginTopBottom={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_marginTopBottom
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_marginLeftRight={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_marginLeftRight
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_paddingLeftRight={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_paddingLeftRight
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_paddingTopBottom={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_paddingTopBottom
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_letterSpacing={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_letterSpacing
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_ShadowHorizontal={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_ShadowHorizontal
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_ShadowVertical={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_ShadowVertical
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_ShadowBlur={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_ShadowBlur
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_ShadowColor={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_ShadowColor
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_TextDecoration={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_TextDecoration
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_TextDecorationStyle={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_TextDecorationStyle
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_marginTopBottom_Hover={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_marginTopBottom_Hover
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_marginLeftRight_Hover={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_marginLeftRight_Hover
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_paddingLeftRight_Hover={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_paddingLeftRight_Hover
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_paddingTopBottom_Hover={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_paddingTopBottom_Hover
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_letterSpacing_Hover={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_letterSpacing_Hover
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_ShadowHorizontal_Hover={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_ShadowHorizontal_Hover
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_ShadowVertical_Hover={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_ShadowVertical_Hover
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_ShadowBlur_Hover={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_ShadowBlur_Hover
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_ShadowColor_Hover={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_ShadowColor_Hover
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_TextDecoration_Hover={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_TextDecoration_Hover
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_TextDecorationStyle_Hover={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_TextDecorationStyle_Hover
                        }
                      />
                    </div>
                  </ProductCardContainerTwelfe>
                </div>
              );
            } else if (columnNumber === "6") {
              return (
                <div
                  className={"col-lg-" + columnNumber + " mt-3 col-12"}
                  key={"6" + "-" + product.id}
                >
                  <ProductCardContainerTwelfe
                    DB_LeftSidebar_MenuProduktImageContainer_Hover_BackgroundColor={
                      DB_LeftSidebar_MenuProduktImageContainer_Hover_BackgroundColor
                    }
                    DB_BodyProductCard_BorderRadius={
                      DB_BodyProductCard_BorderRadius
                    }
                    DB_BodyProductCard_BorderTop_Width={
                      DB_BodyProductCard_BorderTop_Width
                    }
                    DB_BodyProductCard_BorderTop_Style={
                      DB_BodyProductCard_BorderTop_Style
                    }
                    DB_BodyProductCard_BorderTop_Color={
                      DB_BodyProductCard_BorderTop_Color
                    }
                    DB_BodyProductCard_BorderBottom_Width={
                      DB_BodyProductCard_BorderBottom_Width
                    }
                    DB_BodyProductCard_BorderBottom_Style={
                      DB_BodyProductCard_BorderBottom_Style
                    }
                    DB_BodyProductCard_BorderBottom_Color={
                      DB_BodyProductCard_BorderBottom_Color
                    }
                    DB_BodyProductCard_BorderLeft_Width={
                      DB_BodyProductCard_BorderLeft_Width
                    }
                    DB_BodyProductCard_BorderLeft_Style={
                      DB_BodyProductCard_BorderLeft_Style
                    }
                    DB_BodyProductCard_BorderLeft_Color={
                      DB_BodyProductCard_BorderLeft_Color
                    }
                    DB_BodyProductCard_BorderRight_Width={
                      DB_BodyProductCard_BorderRight_Width
                    }
                    DB_BodyProductCard_BorderRight_Style={
                      DB_BodyProductCard_BorderRight_Style
                    }
                    DB_BodyProductCard_BorderRight_Color={
                      DB_BodyProductCard_BorderRight_Color
                    }
                    SideBarLeft_ProduktContainer_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut={
                      SideBarLeft_ProduktContainer_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut
                    }
                    SideBarLeft_ProduktContainer_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut={
                      SideBarLeft_ProduktContainer_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut
                    }
                    DB_LeftSidebar_MenuProduktContainer_BackgroundColor={
                      DB_LeftSidebar_MenuProduktContainer_BackgroundColor
                    }
                    DB_LeftSidebar_MenuProduktContainer_PaddingTopBottom={
                      DB_LeftSidebar_MenuProduktContainer_PaddingTopBottom
                    }
                    DB_LeftSidebar_MenuProduktContainer_PaddingLeftRight={
                      DB_LeftSidebar_MenuProduktContainer_PaddingLeftRight
                    }
                    SideBarLeft_ProduktContainer_Farbe_Groesse_Stil_OnMouseOverAndOut={
                      SideBarLeft_ProduktContainer_Farbe_Groesse_Stil_OnMouseOverAndOut
                    }
                    SideBarLeft_ProduktContainer_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut={
                      SideBarLeft_ProduktContainer_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
                    }
                    DB_LeftSidebar_MenuProduktContainer_BorderTop={
                      DB_LeftSidebar_MenuProduktContainer_BorderTop
                    }
                    DB_LeftSidebar_MenuProduktContainer_BorderStyleTop={
                      DB_LeftSidebar_MenuProduktContainer_BorderStyleTop
                    }
                    DB_LeftSidebar_MenuProduktContainer_BorderColorTop={
                      DB_LeftSidebar_MenuProduktContainer_BorderColorTop
                    }
                    DB_LeftSidebar_MenuProduktContainer_BorderBottom={
                      DB_LeftSidebar_MenuProduktContainer_BorderBottom
                    }
                    DB_LeftSidebar_MenuProduktContainer_BorderStyleBottom={
                      DB_LeftSidebar_MenuProduktContainer_BorderStyleBottom
                    }
                    DB_LeftSidebar_MenuProduktContainer_BorderColorBottom={
                      DB_LeftSidebar_MenuProduktContainer_BorderColorBottom
                    }
                    DB_LeftSidebar_MenuProduktContainer_BorderLeft={
                      DB_LeftSidebar_MenuProduktContainer_BorderLeft
                    }
                    DB_LeftSidebar_MenuProduktContainer_BorderStyleLeft={
                      DB_LeftSidebar_MenuProduktContainer_BorderStyleLeft
                    }
                    DB_LeftSidebar_MenuProduktContainer_BorderColorLeft={
                      DB_LeftSidebar_MenuProduktContainer_BorderColorLeft
                    }
                    DB_LeftSidebar_MenuProduktContainer_BorderRight={
                      DB_LeftSidebar_MenuProduktContainer_BorderRight
                    }
                    DB_LeftSidebar_MenuProduktContainer_BorderStyleRight={
                      DB_LeftSidebar_MenuProduktContainer_BorderStyleRight
                    }
                    DB_LeftSidebar_MenuProduktContainer_BorderColorRight={
                      DB_LeftSidebar_MenuProduktContainer_BorderColorRight
                    }
                    DB_LeftSidebar_MenuItem_MenuProduktContainer_BorderTopRightRadius={
                      DB_LeftSidebar_MenuItem_MenuProduktContainer_BorderTopRightRadius
                    }
                    DB_LeftSidebar_MenuItem_MenuProduktContainer_BorderTopLeftRadius={
                      DB_LeftSidebar_MenuItem_MenuProduktContainer_BorderTopLeftRadius
                    }
                    DB_LeftSidebar_MenuItem_MenuProduktContainer_BorderBottomRightRadius={
                      DB_LeftSidebar_MenuItem_MenuProduktContainer_BorderBottomRightRadius
                    }
                    DB_LeftSidebar_MenuItem_MenuProduktContainer_BorderBottomLeftRadius={
                      DB_LeftSidebar_MenuItem_MenuProduktContainer_BorderBottomLeftRadius
                    }
                    DB_LeftSidebar_MenuItem_MenuProduktContainer_paddingLeft={
                      DB_LeftSidebar_MenuItem_MenuProduktContainer_paddingLeft
                    }
                    DB_LeftSidebar_MenuItem_MenuProduktContainer_paddingRight={
                      DB_LeftSidebar_MenuItem_MenuProduktContainer_paddingRight
                    }
                    DB_LeftSidebar_MenuItem_MenuProduktContainer_paddingTop={
                      DB_LeftSidebar_MenuItem_MenuProduktContainer_paddingTop
                    }
                    DB_LeftSidebar_MenuItem_MenuProduktContainer_paddingBottom={
                      DB_LeftSidebar_MenuItem_MenuProduktContainer_paddingBottom
                    }
                    DB_LeftSidebar_MenuItem_MenuProduktContainer_ShadowHorizontal={
                      DB_LeftSidebar_MenuItem_MenuProduktContainer_ShadowHorizontal
                    }
                    DB_LeftSidebar_MenuItem_MenuProduktContainer_ShadowBlur={
                      DB_LeftSidebar_MenuItem_MenuProduktContainer_ShadowBlur
                    }
                    DB_LeftSidebar_MenuItem_MenuProduktContainer_ShadowColor={
                      DB_LeftSidebar_MenuItem_MenuProduktContainer_ShadowColor
                    }
                    DB_LeftSidebar_MenuProduktContainer_Hover_BackgroundColor={
                      DB_LeftSidebar_MenuProduktContainer_Hover_BackgroundColor
                    }
                    DB_LeftSidebar_MenuProduktContainer_Hover_PaddingTopBottom={
                      DB_LeftSidebar_MenuProduktContainer_Hover_PaddingTopBottom
                    }
                    DB_LeftSidebar_MenuProduktContainer_Hover_PaddingLeftRight={
                      DB_LeftSidebar_MenuProduktContainer_Hover_PaddingLeftRight
                    }
                    DB_LeftSidebar_MenuProduktContainer_Hover_BorderTop={
                      DB_LeftSidebar_MenuProduktContainer_Hover_BorderTop
                    }
                    DB_LeftSidebar_MenuProduktContainer_Hover_BorderStyleTop={
                      DB_LeftSidebar_MenuProduktContainer_Hover_BorderStyleTop
                    }
                    DB_LeftSidebar_MenuProduktContainer_Hover_BorderColorTop={
                      DB_LeftSidebar_MenuProduktContainer_Hover_BorderColorTop
                    }
                    DB_LeftSidebar_MenuProduktContainer_Hover_BorderBottom={
                      DB_LeftSidebar_MenuProduktContainer_Hover_BorderBottom
                    }
                    DB_LeftSidebar_MenuProduktContainer_Hover_BorderStyleBottom={
                      DB_LeftSidebar_MenuProduktContainer_Hover_BorderStyleBottom
                    }
                    DB_LeftSidebar_MenuProduktContainer_Hover_BorderColorBottom={
                      DB_LeftSidebar_MenuProduktContainer_Hover_BorderColorBottom
                    }
                    DB_LeftSidebar_MenuProduktContainer_Hover_BorderLeft={
                      DB_LeftSidebar_MenuProduktContainer_Hover_BorderLeft
                    }
                    DB_LeftSidebar_MenuProduktContainer_Hover_BorderStyleLeft={
                      DB_LeftSidebar_MenuProduktContainer_Hover_BorderStyleLeft
                    }
                    DB_LeftSidebar_MenuProduktContainer_Hover_BorderColorLeft={
                      DB_LeftSidebar_MenuProduktContainer_Hover_BorderColorLeft
                    }
                    DB_LeftSidebar_MenuProduktContainer_Hover_BorderRight={
                      DB_LeftSidebar_MenuProduktContainer_Hover_BorderRight
                    }
                    DB_LeftSidebar_MenuProduktContainer_Hover_BorderStyleRight={
                      DB_LeftSidebar_MenuProduktContainer_Hover_BorderStyleRight
                    }
                    DB_LeftSidebar_MenuProduktContainer_Hover_BorderColorRight={
                      DB_LeftSidebar_MenuProduktContainer_Hover_BorderColorRight
                    }
                    DB_LeftSidebar_MenuItem_MenuProduktContainer_Hover_BorderTopRightRadius={
                      DB_LeftSidebar_MenuItem_MenuProduktContainer_Hover_BorderTopRightRadius
                    }
                    DB_LeftSidebar_MenuItem_MenuProduktContainer_Hover_BorderTopLeftRadius={
                      DB_LeftSidebar_MenuItem_MenuProduktContainer_Hover_BorderTopLeftRadius
                    }
                    DB_LeftSidebar_MenuItem_MenuProduktContainer_Hover_BorderBottomRightRadius={
                      DB_LeftSidebar_MenuItem_MenuProduktContainer_Hover_BorderBottomRightRadius
                    }
                    DB_LeftSidebar_MenuItem_MenuProduktContainer_Hover_BorderBottomLeftRadius={
                      DB_LeftSidebar_MenuItem_MenuProduktContainer_Hover_BorderBottomLeftRadius
                    }
                    DB_LeftSidebar_MenuItem_MenuProduktContainer_Hover_paddingLeft={
                      DB_LeftSidebar_MenuItem_MenuProduktContainer_Hover_paddingLeft
                    }
                    DB_LeftSidebar_MenuItem_MenuProduktContainer_Hover_paddingRight={
                      DB_LeftSidebar_MenuItem_MenuProduktContainer_Hover_paddingRight
                    }
                    DB_LeftSidebar_MenuItem_MenuProduktContainer_Hover_paddingTop={
                      DB_LeftSidebar_MenuItem_MenuProduktContainer_Hover_paddingTop
                    }
                    DB_LeftSidebar_MenuItem_MenuProduktContainer_Hover_paddingBottom={
                      DB_LeftSidebar_MenuItem_MenuProduktContainer_Hover_paddingBottom
                    }
                    DB_LeftSidebar_MenuItem_MenuProduktContainer_ShadowVertical={
                      DB_LeftSidebar_MenuItem_MenuProduktContainer_ShadowVertical
                    }
                    DB_LeftSidebar_MenuItem_MenuProduktContainer_Hover_ShadowHorizontal={
                      DB_LeftSidebar_MenuItem_MenuProduktContainer_Hover_ShadowHorizontal
                    }
                    DB_LeftSidebar_MenuItem_MenuProduktContainer_Hover_ShadowBlur={
                      DB_LeftSidebar_MenuItem_MenuProduktContainer_Hover_ShadowBlur
                    }
                    DB_LeftSidebar_MenuItem_MenuProduktContainer_Hover_ShadowColor={
                      DB_LeftSidebar_MenuItem_MenuProduktContainer_Hover_ShadowColor
                    }
                    DB_LeftSidebar_MenuItem_MenuProduktContainer_Hover_ShadowVertical={
                      DB_LeftSidebar_MenuItem_MenuProduktContainer_Hover_ShadowVertical
                    }
                  >
                    <div
                      className={classes.imageHeightTwo}
                      style={{
                        position: "relative",
                        borderBottomRightRadius: `${DB_LeftSidebar_MenuItem_MenuProduktContainer_BorderBottomRightRadius}px`,
                        borderTopRightRadius: `${DB_LeftSidebar_MenuItem_MenuProduktContainer_BorderTopRightRadius}px`,
                        borderBottomLeftRadius: `${DB_LeftSidebar_MenuItem_MenuProduktContainer_BorderBottomLeftRadius}px`,
                        borderTopLeftRadius: `${DB_LeftSidebar_MenuItem_MenuProduktContainer_BorderTopLeftRadius}px`,
                      }}
                    >
                      {product.mainImage && (
                        <div
                          /* href={`?s=${product.titel}&t=${trace}`} */
                          style={{
                            cursor: "pointer",
                            textDecoration: "none",
                            objectFit: "contain",
                          }}
                          className={classes.productImagesTwoThreeFour}
                        >
                          <img
                            onClick={(productIdentifier) =>
                              showProductDetailsHandler(product.titel)
                            }
                            alt={product.titel}
                            src={`${process.env.REACT_APP_PUBLIC_SERVER_URL}/${product.mainImage}`}
                            style={{
                              objectFit: "contain",
                              cursor: "pointer",
                              width: "100%",
                              height: "100%",
                              position: "absolute",
                            }}
                            className={classes.productImagesTwoThreeFour}
                          />
                        </div>
                      )}
                    </div>

                    <div className="productDescription">
                      <ProductCardBreadCrumbs
                        DB_BodyProductCard_Breadcrumbs_FontFamily={
                          DB_BodyProductCard_Breadcrumbs_FontFamily
                        }
                        DB_fontFace_chooseFontBreadcrumbs_FontFamilyName={
                          DB_fontFace_chooseFontBreadcrumbs_FontFamilyName
                        }
                        DB_fontFace_chooseFontBreadcrumbs_FontFamilySrc={
                          DB_fontFace_chooseFontBreadcrumbs_FontFamilySrc
                        }
                        categoryLeftSideHandler={categoryLeftSideHandler}
                        productKategorie={product.kategorie}
                        productUnterkategorie={product.unterkategorie}
                        productUnterUnterkategorie={product.unterunterkategorie}
                        DB_LeftSidebar_MenuItem_BreadCrumbs_FontWeight={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_FontWeight
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_FontWeight={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_FontWeight
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_FontColor={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_FontColor
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_FontColor={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_FontColor
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_FontSize={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_FontSize
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_FontSize={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_FontSize
                        }
                        SideBarLeft_BreadCrumbs_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut={
                          SideBarLeft_BreadCrumbs_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut
                        }
                        SideBarLeft_BreadCrumbs_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut={
                          SideBarLeft_BreadCrumbs_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_BackgroundColor={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_BackgroundColor
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BackgroundColor={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BackgroundColor
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_TextAlign={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_TextAlign
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_TextAlign={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_TextAlign
                        }
                        SideBarLeft_BreadCrumbs_Farbe_Groesse_Stil_OnMouseOverAndOut={
                          SideBarLeft_BreadCrumbs_Farbe_Groesse_Stil_OnMouseOverAndOut
                        }
                        SideBarLeft_BreadCrumbs_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut={
                          SideBarLeft_BreadCrumbs_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_BorderTop={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_BorderTop
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderTop={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderTop
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_BorderStyleTop={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_BorderStyleTop
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_BorderColorTop={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_BorderColorTop
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_BorderBottom={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_BorderBottom
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_BorderStyleBottom={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_BorderStyleBottom
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_BorderColorBottom={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_BorderColorBottom
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_BorderLeft={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_BorderLeft
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_BorderStyleLeft={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_BorderStyleLeft
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_BorderColorLeft={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_BorderColorLeft
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_BorderRight={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_BorderRight
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_BorderStyleRight={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_BorderStyleRight
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_BorderColorRight={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_BorderColorRight
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_BorderTopRightRadius={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_BorderTopRightRadius
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_BorderTopLeftRadius={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_BorderTopLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_BorderBottomRightRadius={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_BorderBottomRightRadius
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_BorderBottomLeftRadius={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_BorderBottomLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderTopRightRadius={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderTopRightRadius
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderTopLeftRadius={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderTopLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderBottomRightRadius={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderBottomRightRadius
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderBottomLeftRadius={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderBottomLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderStyleTop={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderStyleTop
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderColorTop={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderColorTop
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderBottom={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderBottom
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderStyleBottom={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderStyleBottom
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderColorBottom={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderColorBottom
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderLeft={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderLeft
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderStyleLeft={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderStyleLeft
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderColorLeft={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderColorLeft
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderRight={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderRight
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderStyleRight={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderStyleRight
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderColorRight={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderColorRight
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_marginTopBottom={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_marginTopBottom
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_marginLeftRight={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_marginLeftRight
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_paddingLeftRight={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_paddingLeftRight
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_paddingTopBottom={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_paddingTopBottom
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_letterSpacing={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_letterSpacing
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_ShadowHorizontal={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_ShadowHorizontal
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_ShadowVertical={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_ShadowVertical
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_ShadowBlur={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_ShadowBlur
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_ShadowColor={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_ShadowColor
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_TextDecoration={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_TextDecoration
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_TextDecorationStyle={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_TextDecorationStyle
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_marginTopBottom_Hover={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_marginTopBottom_Hover
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_marginLeftRight_Hover={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_marginLeftRight_Hover
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_paddingLeftRight_Hover={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_paddingLeftRight_Hover
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_paddingTopBottom_Hover={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_paddingTopBottom_Hover
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_letterSpacing_Hover={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_letterSpacing_Hover
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_ShadowHorizontal_Hover={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_ShadowHorizontal_Hover
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_ShadowVertical_Hover={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_ShadowVertical_Hover
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_ShadowBlur_Hover={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_ShadowBlur_Hover
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_ShadowColor_Hover={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_ShadowColor_Hover
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_TextDecoration_Hover={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_TextDecoration_Hover
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_TextDecorationStyle_Hover={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_TextDecorationStyle_Hover
                        }
                      />
                      {product.titel ? (
                        <div
                          /* href={`?s=${product.titel}&t=${trace}`} */
                          style={{
                            cursor: "pointer",
                            textDecoration: "none",
                          }}
                          className={`card-title ${classes.produktTitel}`}
                        >
                          <div
                            onClick={(productIdentifier) =>
                              showProductDetailsHandler(product.titel)
                            }
                          >
                            {product.titel}
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}
                      {product.untertitel ? (
                        <div
                          /* href={`?s=${product.titel}&t=${trace}`} */
                          style={{
                            cursor: "pointer",
                            textDecoration: "none",
                          }}
                        >
                          <div
                            className={`card-text ${classes.produktUntertitel}`}
                            onClick={(productIdentifier) =>
                              showProductDetailsHandler(product.titel)
                            }
                          >
                            {product.untertitel}
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}
                      <ProductCardPreisWaehrung
                        productTitle={product.titel}
                        showProductDetailsHandler={showProductDetailsHandler}
                        productFixpreis={product.fixpreis}
                        productAktionspreis={product.startpreis}
                        DB_BodyProductCard_Waehrung={
                          DB_BodyProductCard_Waehrung
                        }
                        DB_fontFace_chooseFontWaehrung_FontFamilyName={
                          DB_fontFace_chooseFontWaehrung_FontFamilyName
                        }
                        DB_fontFace_chooseFontWaehrung_FontFamilySrc={
                          DB_fontFace_chooseFontWaehrung_FontFamilySrc
                        }
                        DB_fontFace_chooseFontWaehrungEuro_FontFamilyName={
                          DB_fontFace_chooseFontWaehrungEuro_FontFamilyName
                        }
                        DB_fontFace_chooseFontWaehrungEuro_FontFamilySrc={
                          DB_fontFace_chooseFontWaehrungEuro_FontFamilySrc
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_FontWeight={
                          DB_LeftSidebar_MenuItem_ProduktPreis_FontWeight
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_Hover_FontWeight={
                          DB_LeftSidebar_MenuItem_ProduktPreis_Hover_FontWeight
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_FontColor={
                          DB_LeftSidebar_MenuItem_ProduktPreis_FontColor
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_Hover_FontColor={
                          DB_LeftSidebar_MenuItem_ProduktPreis_Hover_FontColor
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_FontSize={
                          DB_LeftSidebar_MenuItem_ProduktPreis_FontSize
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_Hover_FontSize={
                          DB_LeftSidebar_MenuItem_ProduktPreis_Hover_FontSize
                        }
                        SideBarLeft_ProduktPreis_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut={
                          SideBarLeft_ProduktPreis_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut
                        }
                        SideBarLeft_ProduktPreis_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut={
                          SideBarLeft_ProduktPreis_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_BackgroundColor={
                          DB_LeftSidebar_MenuItem_ProduktPreis_BackgroundColor
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BackgroundColor={
                          DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BackgroundColor
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_TextAlign={
                          DB_LeftSidebar_MenuItem_ProduktPreis_TextAlign
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_Hover_TextAlign={
                          DB_LeftSidebar_MenuItem_ProduktPreis_Hover_TextAlign
                        }
                        SideBarLeft_ProduktPreis_Farbe_Groesse_Stil_OnMouseOverAndOut={
                          SideBarLeft_ProduktPreis_Farbe_Groesse_Stil_OnMouseOverAndOut
                        }
                        SideBarLeft_ProduktPreis_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut={
                          SideBarLeft_ProduktPreis_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_BorderTop={
                          DB_LeftSidebar_MenuItem_ProduktPreis_BorderTop
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderTop={
                          DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderTop
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_BorderStyleTop={
                          DB_LeftSidebar_MenuItem_ProduktPreis_BorderStyleTop
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_BorderColorTop={
                          DB_LeftSidebar_MenuItem_ProduktPreis_BorderColorTop
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_BorderBottom={
                          DB_LeftSidebar_MenuItem_ProduktPreis_BorderBottom
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_BorderStyleBottom={
                          DB_LeftSidebar_MenuItem_ProduktPreis_BorderStyleBottom
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_BorderColorBottom={
                          DB_LeftSidebar_MenuItem_ProduktPreis_BorderColorBottom
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_BorderLeft={
                          DB_LeftSidebar_MenuItem_ProduktPreis_BorderLeft
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_BorderStyleLeft={
                          DB_LeftSidebar_MenuItem_ProduktPreis_BorderStyleLeft
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_BorderColorLeft={
                          DB_LeftSidebar_MenuItem_ProduktPreis_BorderColorLeft
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_BorderRight={
                          DB_LeftSidebar_MenuItem_ProduktPreis_BorderRight
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_BorderStyleRight={
                          DB_LeftSidebar_MenuItem_ProduktPreis_BorderStyleRight
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_BorderColorRight={
                          DB_LeftSidebar_MenuItem_ProduktPreis_BorderColorRight
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_BorderTopRightRadius={
                          DB_LeftSidebar_MenuItem_ProduktPreis_BorderTopRightRadius
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_BorderTopLeftRadius={
                          DB_LeftSidebar_MenuItem_ProduktPreis_BorderTopLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_BorderBottomRightRadius={
                          DB_LeftSidebar_MenuItem_ProduktPreis_BorderBottomRightRadius
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_BorderBottomLeftRadius={
                          DB_LeftSidebar_MenuItem_ProduktPreis_BorderBottomLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderTopRightRadius={
                          DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderTopRightRadius
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderTopLeftRadius={
                          DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderTopLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderBottomRightRadius={
                          DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderBottomRightRadius
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderBottomLeftRadius={
                          DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderBottomLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderStyleTop={
                          DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderStyleTop
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderColorTop={
                          DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderColorTop
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderBottom={
                          DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderBottom
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderStyleBottom={
                          DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderStyleBottom
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderColorBottom={
                          DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderColorBottom
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderLeft={
                          DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderLeft
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderStyleLeft={
                          DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderStyleLeft
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderColorLeft={
                          DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderColorLeft
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderRight={
                          DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderRight
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderStyleRight={
                          DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderStyleRight
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderColorRight={
                          DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderColorRight
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_marginTopBottom={
                          DB_LeftSidebar_MenuItem_ProduktPreis_marginTopBottom
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_marginLeftRight={
                          DB_LeftSidebar_MenuItem_ProduktPreis_marginLeftRight
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_paddingLeftRight={
                          DB_LeftSidebar_MenuItem_ProduktPreis_paddingLeftRight
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_paddingTopBottom={
                          DB_LeftSidebar_MenuItem_ProduktPreis_paddingTopBottom
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_letterSpacing={
                          DB_LeftSidebar_MenuItem_ProduktPreis_letterSpacing
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_ShadowHorizontal={
                          DB_LeftSidebar_MenuItem_ProduktPreis_ShadowHorizontal
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_ShadowVertical={
                          DB_LeftSidebar_MenuItem_ProduktPreis_ShadowVertical
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_ShadowBlur={
                          DB_LeftSidebar_MenuItem_ProduktPreis_ShadowBlur
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_ShadowColor={
                          DB_LeftSidebar_MenuItem_ProduktPreis_ShadowColor
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_TextDecoration={
                          DB_LeftSidebar_MenuItem_ProduktPreis_TextDecoration
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_TextDecorationStyle={
                          DB_LeftSidebar_MenuItem_ProduktPreis_TextDecorationStyle
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_marginTopBottom_Hover={
                          DB_LeftSidebar_MenuItem_ProduktPreis_marginTopBottom_Hover
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_marginLeftRight_Hover={
                          DB_LeftSidebar_MenuItem_ProduktPreis_marginLeftRight_Hover
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_paddingLeftRight_Hover={
                          DB_LeftSidebar_MenuItem_ProduktPreis_paddingLeftRight_Hover
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_paddingTopBottom_Hover={
                          DB_LeftSidebar_MenuItem_ProduktPreis_paddingTopBottom_Hover
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_letterSpacing_Hover={
                          DB_LeftSidebar_MenuItem_ProduktPreis_letterSpacing_Hover
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_ShadowHorizontal_Hover={
                          DB_LeftSidebar_MenuItem_ProduktPreis_ShadowHorizontal_Hover
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_ShadowVertical_Hover={
                          DB_LeftSidebar_MenuItem_ProduktPreis_ShadowVertical_Hover
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_ShadowBlur_Hover={
                          DB_LeftSidebar_MenuItem_ProduktPreis_ShadowBlur_Hover
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_ShadowColor_Hover={
                          DB_LeftSidebar_MenuItem_ProduktPreis_ShadowColor_Hover
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_TextDecoration_Hover={
                          DB_LeftSidebar_MenuItem_ProduktPreis_TextDecoration_Hover
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_TextDecorationStyle_Hover={
                          DB_LeftSidebar_MenuItem_ProduktPreis_TextDecorationStyle_Hover
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_FontWeight={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_FontWeight
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_FontWeight={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_FontWeight
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_FontColor={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_FontColor
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_FontColor={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_FontColor
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_FontSize={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_FontSize
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_FontSize={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_FontSize
                        }
                        SideBarLeft_ProduktWaehrung_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut={
                          SideBarLeft_ProduktWaehrung_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut
                        }
                        SideBarLeft_ProduktWaehrung_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut={
                          SideBarLeft_ProduktWaehrung_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_BackgroundColor={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_BackgroundColor
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BackgroundColor={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BackgroundColor
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_TextAlign={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_TextAlign
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_TextAlign={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_TextAlign
                        }
                        SideBarLeft_ProduktWaehrung_Farbe_Groesse_Stil_OnMouseOverAndOut={
                          SideBarLeft_ProduktWaehrung_Farbe_Groesse_Stil_OnMouseOverAndOut
                        }
                        SideBarLeft_ProduktWaehrung_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut={
                          SideBarLeft_ProduktWaehrung_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_BorderTop={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_BorderTop
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderTop={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderTop
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_BorderStyleTop={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_BorderStyleTop
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_BorderColorTop={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_BorderColorTop
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_BorderBottom={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_BorderBottom
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_BorderStyleBottom={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_BorderStyleBottom
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_BorderColorBottom={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_BorderColorBottom
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_BorderLeft={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_BorderLeft
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_BorderStyleLeft={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_BorderStyleLeft
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_BorderColorLeft={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_BorderColorLeft
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_BorderRight={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_BorderRight
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_BorderStyleRight={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_BorderStyleRight
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_BorderColorRight={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_BorderColorRight
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_BorderTopRightRadius={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_BorderTopRightRadius
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_BorderTopLeftRadius={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_BorderTopLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_BorderBottomRightRadius={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_BorderBottomRightRadius
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_BorderBottomLeftRadius={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_BorderBottomLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderTopRightRadius={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderTopRightRadius
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderTopLeftRadius={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderTopLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderBottomRightRadius={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderBottomRightRadius
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderBottomLeftRadius={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderBottomLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderStyleTop={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderStyleTop
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderColorTop={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderColorTop
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderBottom={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderBottom
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderStyleBottom={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderStyleBottom
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderColorBottom={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderColorBottom
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderLeft={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderLeft
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderStyleLeft={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderStyleLeft
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderColorLeft={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderColorLeft
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderRight={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderRight
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderStyleRight={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderStyleRight
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderColorRight={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderColorRight
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_marginTopBottom={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_marginTopBottom
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_marginLeftRight={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_marginLeftRight
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_paddingLeftRight={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_paddingLeftRight
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_paddingTopBottom={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_paddingTopBottom
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_letterSpacing={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_letterSpacing
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_ShadowHorizontal={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_ShadowHorizontal
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_ShadowVertical={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_ShadowVertical
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_ShadowBlur={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_ShadowBlur
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_ShadowColor={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_ShadowColor
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_TextDecoration={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_TextDecoration
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_TextDecorationStyle={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_TextDecorationStyle
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_marginTopBottom_Hover={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_marginTopBottom_Hover
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_marginLeftRight_Hover={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_marginLeftRight_Hover
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_paddingLeftRight_Hover={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_paddingLeftRight_Hover
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_paddingTopBottom_Hover={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_paddingTopBottom_Hover
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_letterSpacing_Hover={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_letterSpacing_Hover
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_ShadowHorizontal_Hover={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_ShadowHorizontal_Hover
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_ShadowVertical_Hover={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_ShadowVertical_Hover
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_ShadowBlur_Hover={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_ShadowBlur_Hover
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_ShadowColor_Hover={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_ShadowColor_Hover
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_TextDecoration_Hover={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_TextDecoration_Hover
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_TextDecorationStyle_Hover={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_TextDecorationStyle_Hover
                        }
                      />
                    </div>
                  </ProductCardContainerTwelfe>
                </div>
              );
            } else if (columnNumber === "3") {
              return (
                <div
                  className={"col-lg-" + columnNumber + " mt-3 col-12"}
                  key={"3" + "-" + product.id}
                >
                  <ProductCardContainerTwelfe
                    DB_LeftSidebar_MenuProduktImageContainer_Hover_BackgroundColor={
                      DB_LeftSidebar_MenuProduktImageContainer_Hover_BackgroundColor
                    }
                    DB_BodyProductCard_BorderRadius={
                      DB_BodyProductCard_BorderRadius
                    }
                    DB_BodyProductCard_BorderTop_Width={
                      DB_BodyProductCard_BorderTop_Width
                    }
                    DB_BodyProductCard_BorderTop_Style={
                      DB_BodyProductCard_BorderTop_Style
                    }
                    DB_BodyProductCard_BorderTop_Color={
                      DB_BodyProductCard_BorderTop_Color
                    }
                    DB_BodyProductCard_BorderBottom_Width={
                      DB_BodyProductCard_BorderBottom_Width
                    }
                    DB_BodyProductCard_BorderBottom_Style={
                      DB_BodyProductCard_BorderBottom_Style
                    }
                    DB_BodyProductCard_BorderBottom_Color={
                      DB_BodyProductCard_BorderBottom_Color
                    }
                    DB_BodyProductCard_BorderLeft_Width={
                      DB_BodyProductCard_BorderLeft_Width
                    }
                    DB_BodyProductCard_BorderLeft_Style={
                      DB_BodyProductCard_BorderLeft_Style
                    }
                    DB_BodyProductCard_BorderLeft_Color={
                      DB_BodyProductCard_BorderLeft_Color
                    }
                    DB_BodyProductCard_BorderRight_Width={
                      DB_BodyProductCard_BorderRight_Width
                    }
                    DB_BodyProductCard_BorderRight_Style={
                      DB_BodyProductCard_BorderRight_Style
                    }
                    DB_BodyProductCard_BorderRight_Color={
                      DB_BodyProductCard_BorderRight_Color
                    }
                    SideBarLeft_ProduktContainer_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut={
                      SideBarLeft_ProduktContainer_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut
                    }
                    SideBarLeft_ProduktContainer_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut={
                      SideBarLeft_ProduktContainer_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut
                    }
                    DB_LeftSidebar_MenuProduktContainer_BackgroundColor={
                      DB_LeftSidebar_MenuProduktContainer_BackgroundColor
                    }
                    DB_LeftSidebar_MenuProduktContainer_PaddingTopBottom={
                      DB_LeftSidebar_MenuProduktContainer_PaddingTopBottom
                    }
                    DB_LeftSidebar_MenuProduktContainer_PaddingLeftRight={
                      DB_LeftSidebar_MenuProduktContainer_PaddingLeftRight
                    }
                    SideBarLeft_ProduktContainer_Farbe_Groesse_Stil_OnMouseOverAndOut={
                      SideBarLeft_ProduktContainer_Farbe_Groesse_Stil_OnMouseOverAndOut
                    }
                    SideBarLeft_ProduktContainer_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut={
                      SideBarLeft_ProduktContainer_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
                    }
                    DB_LeftSidebar_MenuProduktContainer_BorderTop={
                      DB_LeftSidebar_MenuProduktContainer_BorderTop
                    }
                    DB_LeftSidebar_MenuProduktContainer_BorderStyleTop={
                      DB_LeftSidebar_MenuProduktContainer_BorderStyleTop
                    }
                    DB_LeftSidebar_MenuProduktContainer_BorderColorTop={
                      DB_LeftSidebar_MenuProduktContainer_BorderColorTop
                    }
                    DB_LeftSidebar_MenuProduktContainer_BorderBottom={
                      DB_LeftSidebar_MenuProduktContainer_BorderBottom
                    }
                    DB_LeftSidebar_MenuProduktContainer_BorderStyleBottom={
                      DB_LeftSidebar_MenuProduktContainer_BorderStyleBottom
                    }
                    DB_LeftSidebar_MenuProduktContainer_BorderColorBottom={
                      DB_LeftSidebar_MenuProduktContainer_BorderColorBottom
                    }
                    DB_LeftSidebar_MenuProduktContainer_BorderLeft={
                      DB_LeftSidebar_MenuProduktContainer_BorderLeft
                    }
                    DB_LeftSidebar_MenuProduktContainer_BorderStyleLeft={
                      DB_LeftSidebar_MenuProduktContainer_BorderStyleLeft
                    }
                    DB_LeftSidebar_MenuProduktContainer_BorderColorLeft={
                      DB_LeftSidebar_MenuProduktContainer_BorderColorLeft
                    }
                    DB_LeftSidebar_MenuProduktContainer_BorderRight={
                      DB_LeftSidebar_MenuProduktContainer_BorderRight
                    }
                    DB_LeftSidebar_MenuProduktContainer_BorderStyleRight={
                      DB_LeftSidebar_MenuProduktContainer_BorderStyleRight
                    }
                    DB_LeftSidebar_MenuProduktContainer_BorderColorRight={
                      DB_LeftSidebar_MenuProduktContainer_BorderColorRight
                    }
                    DB_LeftSidebar_MenuItem_MenuProduktContainer_BorderTopRightRadius={
                      DB_LeftSidebar_MenuItem_MenuProduktContainer_BorderTopRightRadius
                    }
                    DB_LeftSidebar_MenuItem_MenuProduktContainer_BorderTopLeftRadius={
                      DB_LeftSidebar_MenuItem_MenuProduktContainer_BorderTopLeftRadius
                    }
                    DB_LeftSidebar_MenuItem_MenuProduktContainer_BorderBottomRightRadius={
                      DB_LeftSidebar_MenuItem_MenuProduktContainer_BorderBottomRightRadius
                    }
                    DB_LeftSidebar_MenuItem_MenuProduktContainer_BorderBottomLeftRadius={
                      DB_LeftSidebar_MenuItem_MenuProduktContainer_BorderBottomLeftRadius
                    }
                    DB_LeftSidebar_MenuItem_MenuProduktContainer_paddingLeft={
                      DB_LeftSidebar_MenuItem_MenuProduktContainer_paddingLeft
                    }
                    DB_LeftSidebar_MenuItem_MenuProduktContainer_paddingRight={
                      DB_LeftSidebar_MenuItem_MenuProduktContainer_paddingRight
                    }
                    DB_LeftSidebar_MenuItem_MenuProduktContainer_paddingTop={
                      DB_LeftSidebar_MenuItem_MenuProduktContainer_paddingTop
                    }
                    DB_LeftSidebar_MenuItem_MenuProduktContainer_paddingBottom={
                      DB_LeftSidebar_MenuItem_MenuProduktContainer_paddingBottom
                    }
                    DB_LeftSidebar_MenuItem_MenuProduktContainer_ShadowHorizontal={
                      DB_LeftSidebar_MenuItem_MenuProduktContainer_ShadowHorizontal
                    }
                    DB_LeftSidebar_MenuItem_MenuProduktContainer_ShadowBlur={
                      DB_LeftSidebar_MenuItem_MenuProduktContainer_ShadowBlur
                    }
                    DB_LeftSidebar_MenuItem_MenuProduktContainer_ShadowColor={
                      DB_LeftSidebar_MenuItem_MenuProduktContainer_ShadowColor
                    }
                    DB_LeftSidebar_MenuProduktContainer_Hover_BackgroundColor={
                      DB_LeftSidebar_MenuProduktContainer_Hover_BackgroundColor
                    }
                    DB_LeftSidebar_MenuProduktContainer_Hover_PaddingTopBottom={
                      DB_LeftSidebar_MenuProduktContainer_Hover_PaddingTopBottom
                    }
                    DB_LeftSidebar_MenuProduktContainer_Hover_PaddingLeftRight={
                      DB_LeftSidebar_MenuProduktContainer_Hover_PaddingLeftRight
                    }
                    DB_LeftSidebar_MenuProduktContainer_Hover_BorderTop={
                      DB_LeftSidebar_MenuProduktContainer_Hover_BorderTop
                    }
                    DB_LeftSidebar_MenuProduktContainer_Hover_BorderStyleTop={
                      DB_LeftSidebar_MenuProduktContainer_Hover_BorderStyleTop
                    }
                    DB_LeftSidebar_MenuProduktContainer_Hover_BorderColorTop={
                      DB_LeftSidebar_MenuProduktContainer_Hover_BorderColorTop
                    }
                    DB_LeftSidebar_MenuProduktContainer_Hover_BorderBottom={
                      DB_LeftSidebar_MenuProduktContainer_Hover_BorderBottom
                    }
                    DB_LeftSidebar_MenuProduktContainer_Hover_BorderStyleBottom={
                      DB_LeftSidebar_MenuProduktContainer_Hover_BorderStyleBottom
                    }
                    DB_LeftSidebar_MenuProduktContainer_Hover_BorderColorBottom={
                      DB_LeftSidebar_MenuProduktContainer_Hover_BorderColorBottom
                    }
                    DB_LeftSidebar_MenuProduktContainer_Hover_BorderLeft={
                      DB_LeftSidebar_MenuProduktContainer_Hover_BorderLeft
                    }
                    DB_LeftSidebar_MenuProduktContainer_Hover_BorderStyleLeft={
                      DB_LeftSidebar_MenuProduktContainer_Hover_BorderStyleLeft
                    }
                    DB_LeftSidebar_MenuProduktContainer_Hover_BorderColorLeft={
                      DB_LeftSidebar_MenuProduktContainer_Hover_BorderColorLeft
                    }
                    DB_LeftSidebar_MenuProduktContainer_Hover_BorderRight={
                      DB_LeftSidebar_MenuProduktContainer_Hover_BorderRight
                    }
                    DB_LeftSidebar_MenuProduktContainer_Hover_BorderStyleRight={
                      DB_LeftSidebar_MenuProduktContainer_Hover_BorderStyleRight
                    }
                    DB_LeftSidebar_MenuProduktContainer_Hover_BorderColorRight={
                      DB_LeftSidebar_MenuProduktContainer_Hover_BorderColorRight
                    }
                    DB_LeftSidebar_MenuItem_MenuProduktContainer_Hover_BorderTopRightRadius={
                      DB_LeftSidebar_MenuItem_MenuProduktContainer_Hover_BorderTopRightRadius
                    }
                    DB_LeftSidebar_MenuItem_MenuProduktContainer_Hover_BorderTopLeftRadius={
                      DB_LeftSidebar_MenuItem_MenuProduktContainer_Hover_BorderTopLeftRadius
                    }
                    DB_LeftSidebar_MenuItem_MenuProduktContainer_Hover_BorderBottomRightRadius={
                      DB_LeftSidebar_MenuItem_MenuProduktContainer_Hover_BorderBottomRightRadius
                    }
                    DB_LeftSidebar_MenuItem_MenuProduktContainer_Hover_BorderBottomLeftRadius={
                      DB_LeftSidebar_MenuItem_MenuProduktContainer_Hover_BorderBottomLeftRadius
                    }
                    DB_LeftSidebar_MenuItem_MenuProduktContainer_Hover_paddingLeft={
                      DB_LeftSidebar_MenuItem_MenuProduktContainer_Hover_paddingLeft
                    }
                    DB_LeftSidebar_MenuItem_MenuProduktContainer_Hover_paddingRight={
                      DB_LeftSidebar_MenuItem_MenuProduktContainer_Hover_paddingRight
                    }
                    DB_LeftSidebar_MenuItem_MenuProduktContainer_Hover_paddingTop={
                      DB_LeftSidebar_MenuItem_MenuProduktContainer_Hover_paddingTop
                    }
                    DB_LeftSidebar_MenuItem_MenuProduktContainer_Hover_paddingBottom={
                      DB_LeftSidebar_MenuItem_MenuProduktContainer_Hover_paddingBottom
                    }
                    DB_LeftSidebar_MenuItem_MenuProduktContainer_ShadowVertical={
                      DB_LeftSidebar_MenuItem_MenuProduktContainer_ShadowVertical
                    }
                    DB_LeftSidebar_MenuItem_MenuProduktContainer_Hover_ShadowHorizontal={
                      DB_LeftSidebar_MenuItem_MenuProduktContainer_Hover_ShadowHorizontal
                    }
                    DB_LeftSidebar_MenuItem_MenuProduktContainer_Hover_ShadowBlur={
                      DB_LeftSidebar_MenuItem_MenuProduktContainer_Hover_ShadowBlur
                    }
                    DB_LeftSidebar_MenuItem_MenuProduktContainer_Hover_ShadowColor={
                      DB_LeftSidebar_MenuItem_MenuProduktContainer_Hover_ShadowColor
                    }
                    DB_LeftSidebar_MenuItem_MenuProduktContainer_Hover_ShadowVertical={
                      DB_LeftSidebar_MenuItem_MenuProduktContainer_Hover_ShadowVertical
                    }
                  >
                    <div
                      className={classes.imageHeightFour}
                      style={{
                        position: "relative",
                        borderBottomRightRadius: `${DB_LeftSidebar_MenuItem_MenuProduktContainer_BorderBottomRightRadius}px`,
                        borderTopRightRadius: `${DB_LeftSidebar_MenuItem_MenuProduktContainer_BorderTopRightRadius}px`,
                        borderBottomLeftRadius: `${DB_LeftSidebar_MenuItem_MenuProduktContainer_BorderBottomLeftRadius}px`,
                        borderTopLeftRadius: `${DB_LeftSidebar_MenuItem_MenuProduktContainer_BorderTopLeftRadius}px`,
                      }}
                    >
                      {product.mainImage && (
                        <div
                          style={{
                            objectFit: "contain",
                            cursor: "pointer",
                          }}
                          className={classes.productImagesTwoThreeFour}
                        >
                          <img
                            onClick={(productIdentifier) =>
                              showProductDetailsHandler(product.titel)
                            }
                            alt={product.titel}
                            src={`${process.env.REACT_APP_PUBLIC_SERVER_URL}/${product.mainImage}`}
                            style={{
                              objectFit: "contain",
                              cursor: "pointer",
                              width: "100%",
                              height: "100%",
                              position: "absolute",
                            }}
                            className={classes.productImagesTwoThreeFour}
                          />
                        </div>
                      )}
                    </div>
                    <div
                      style={{
                        borderBottomRightRadius: `${DB_LeftSidebar_MenuItem_MenuProduktContainer_BorderBottomRightRadius}px`,
                        borderBottomLeftRadius: `${DB_LeftSidebar_MenuItem_MenuProduktContainer_BorderBottomLeftRadius}px`,
                      }}
                      className="productDescription"
                    >
                      <ProductCardBreadCrumbsFour
                        DB_BodyProductCard_Breadcrumbs_FontFamily={
                          DB_BodyProductCard_Breadcrumbs_FontFamily
                        }
                        DB_fontFace_chooseFontBreadcrumbs_FontFamilyName={
                          DB_fontFace_chooseFontBreadcrumbs_FontFamilyName
                        }
                        DB_fontFace_chooseFontBreadcrumbs_FontFamilySrc={
                          DB_fontFace_chooseFontBreadcrumbs_FontFamilySrc
                        }
                        categoryLeftSideHandler={categoryLeftSideHandler}
                        productKategorie={product.kategorie}
                        productUnterkategorie={product.unterkategorie}
                        productUnterUnterkategorie={product.unterunterkategorie}
                        DB_LeftSidebar_MenuItem_BreadCrumbs_FontWeight={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_FontWeight
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_FontWeight={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_FontWeight
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_FontColor={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_FontColor
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_FontColor={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_FontColor
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_FontSize={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_FontSize
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_FontSize={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_FontSize
                        }
                        SideBarLeft_BreadCrumbs_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut={
                          SideBarLeft_BreadCrumbs_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut
                        }
                        SideBarLeft_BreadCrumbs_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut={
                          SideBarLeft_BreadCrumbs_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_BackgroundColor={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_BackgroundColor
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BackgroundColor={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BackgroundColor
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_TextAlign={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_TextAlign
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_TextAlign={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_TextAlign
                        }
                        SideBarLeft_BreadCrumbs_Farbe_Groesse_Stil_OnMouseOverAndOut={
                          SideBarLeft_BreadCrumbs_Farbe_Groesse_Stil_OnMouseOverAndOut
                        }
                        SideBarLeft_BreadCrumbs_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut={
                          SideBarLeft_BreadCrumbs_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_BorderTop={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_BorderTop
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderTop={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderTop
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_BorderStyleTop={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_BorderStyleTop
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_BorderColorTop={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_BorderColorTop
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_BorderBottom={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_BorderBottom
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_BorderStyleBottom={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_BorderStyleBottom
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_BorderColorBottom={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_BorderColorBottom
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_BorderLeft={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_BorderLeft
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_BorderStyleLeft={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_BorderStyleLeft
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_BorderColorLeft={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_BorderColorLeft
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_BorderRight={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_BorderRight
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_BorderStyleRight={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_BorderStyleRight
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_BorderColorRight={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_BorderColorRight
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_BorderTopRightRadius={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_BorderTopRightRadius
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_BorderTopLeftRadius={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_BorderTopLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_BorderBottomRightRadius={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_BorderBottomRightRadius
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_BorderBottomLeftRadius={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_BorderBottomLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderTopRightRadius={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderTopRightRadius
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderTopLeftRadius={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderTopLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderBottomRightRadius={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderBottomRightRadius
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderBottomLeftRadius={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderBottomLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderStyleTop={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderStyleTop
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderColorTop={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderColorTop
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderBottom={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderBottom
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderStyleBottom={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderStyleBottom
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderColorBottom={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderColorBottom
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderLeft={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderLeft
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderStyleLeft={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderStyleLeft
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderColorLeft={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderColorLeft
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderRight={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderRight
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderStyleRight={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderStyleRight
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderColorRight={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderColorRight
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_marginTopBottom={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_marginTopBottom
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_marginLeftRight={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_marginLeftRight
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_paddingLeftRight={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_paddingLeftRight
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_paddingTopBottom={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_paddingTopBottom
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_letterSpacing={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_letterSpacing
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_ShadowHorizontal={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_ShadowHorizontal
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_ShadowVertical={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_ShadowVertical
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_ShadowBlur={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_ShadowBlur
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_ShadowColor={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_ShadowColor
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_TextDecoration={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_TextDecoration
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_TextDecorationStyle={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_TextDecorationStyle
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_marginTopBottom_Hover={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_marginTopBottom_Hover
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_marginLeftRight_Hover={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_marginLeftRight_Hover
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_paddingLeftRight_Hover={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_paddingLeftRight_Hover
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_paddingTopBottom_Hover={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_paddingTopBottom_Hover
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_letterSpacing_Hover={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_letterSpacing_Hover
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_ShadowHorizontal_Hover={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_ShadowHorizontal_Hover
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_ShadowVertical_Hover={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_ShadowVertical_Hover
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_ShadowBlur_Hover={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_ShadowBlur_Hover
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_ShadowColor_Hover={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_ShadowColor_Hover
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_TextDecoration_Hover={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_TextDecoration_Hover
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_TextDecorationStyle_Hover={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_TextDecorationStyle_Hover
                        }
                      />
                      <div
                        /* href={`?s=${product.titel}&t=${trace}`} */
                        style={{ cursor: "pointer", textDecoration: "none" }}
                        className={`card-title ${classes.produktTitel}`}
                      >
                        <div
                          style={{ cursor: "pointer" }}
                          onClick={(productIdentifier) =>
                            showProductDetailsHandler(product.titel)
                          }
                        >
                          {product.titel}
                        </div>
                      </div>
                      {product.untertitel ? (
                        <div
                          /* href={`?s=${product.titel}&t=${trace}`} */
                          style={{
                            cursor: "pointer",
                            textDecoration: "none",
                          }}
                        >
                          <div
                            className={`card-text ${classes.produktUntertitel}`}
                            onClick={(productIdentifier) =>
                              showProductDetailsHandler(product.titel)
                            }
                          >
                            {product.untertitel}
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}

                      <ProductCardPreisWaehrung
                        productTitle={product.titel}
                        showProductDetailsHandler={showProductDetailsHandler}
                        productFixpreis={product.fixpreis}
                        productAktionspreis={product.startpreis}
                        DB_fontFace_chooseFontWaehrung_FontFamilyName={
                          DB_fontFace_chooseFontWaehrung_FontFamilyName
                        }
                        DB_fontFace_chooseFontWaehrung_FontFamilySrc={
                          DB_fontFace_chooseFontWaehrung_FontFamilySrc
                        }
                        DB_fontFace_chooseFontWaehrungEuro_FontFamilyName={
                          DB_fontFace_chooseFontWaehrungEuro_FontFamilyName
                        }
                        DB_fontFace_chooseFontWaehrungEuro_FontFamilySrc={
                          DB_fontFace_chooseFontWaehrungEuro_FontFamilySrc
                        }
                        DB_BodyProductCard_Waehrung={
                          DB_BodyProductCard_Waehrung
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_FontWeight={
                          DB_LeftSidebar_MenuItem_ProduktPreis_FontWeight
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_Hover_FontWeight={
                          DB_LeftSidebar_MenuItem_ProduktPreis_Hover_FontWeight
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_FontColor={
                          DB_LeftSidebar_MenuItem_ProduktPreis_FontColor
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_Hover_FontColor={
                          DB_LeftSidebar_MenuItem_ProduktPreis_Hover_FontColor
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_FontSize={
                          DB_LeftSidebar_MenuItem_ProduktPreis_FontSize
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_Hover_FontSize={
                          DB_LeftSidebar_MenuItem_ProduktPreis_Hover_FontSize
                        }
                        SideBarLeft_ProduktPreis_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut={
                          SideBarLeft_ProduktPreis_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut
                        }
                        SideBarLeft_ProduktPreis_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut={
                          SideBarLeft_ProduktPreis_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_BackgroundColor={
                          DB_LeftSidebar_MenuItem_ProduktPreis_BackgroundColor
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BackgroundColor={
                          DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BackgroundColor
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_TextAlign={
                          DB_LeftSidebar_MenuItem_ProduktPreis_TextAlign
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_Hover_TextAlign={
                          DB_LeftSidebar_MenuItem_ProduktPreis_Hover_TextAlign
                        }
                        SideBarLeft_ProduktPreis_Farbe_Groesse_Stil_OnMouseOverAndOut={
                          SideBarLeft_ProduktPreis_Farbe_Groesse_Stil_OnMouseOverAndOut
                        }
                        SideBarLeft_ProduktPreis_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut={
                          SideBarLeft_ProduktPreis_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_BorderTop={
                          DB_LeftSidebar_MenuItem_ProduktPreis_BorderTop
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderTop={
                          DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderTop
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_BorderStyleTop={
                          DB_LeftSidebar_MenuItem_ProduktPreis_BorderStyleTop
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_BorderColorTop={
                          DB_LeftSidebar_MenuItem_ProduktPreis_BorderColorTop
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_BorderBottom={
                          DB_LeftSidebar_MenuItem_ProduktPreis_BorderBottom
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_BorderStyleBottom={
                          DB_LeftSidebar_MenuItem_ProduktPreis_BorderStyleBottom
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_BorderColorBottom={
                          DB_LeftSidebar_MenuItem_ProduktPreis_BorderColorBottom
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_BorderLeft={
                          DB_LeftSidebar_MenuItem_ProduktPreis_BorderLeft
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_BorderStyleLeft={
                          DB_LeftSidebar_MenuItem_ProduktPreis_BorderStyleLeft
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_BorderColorLeft={
                          DB_LeftSidebar_MenuItem_ProduktPreis_BorderColorLeft
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_BorderRight={
                          DB_LeftSidebar_MenuItem_ProduktPreis_BorderRight
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_BorderStyleRight={
                          DB_LeftSidebar_MenuItem_ProduktPreis_BorderStyleRight
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_BorderColorRight={
                          DB_LeftSidebar_MenuItem_ProduktPreis_BorderColorRight
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_BorderTopRightRadius={
                          DB_LeftSidebar_MenuItem_ProduktPreis_BorderTopRightRadius
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_BorderTopLeftRadius={
                          DB_LeftSidebar_MenuItem_ProduktPreis_BorderTopLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_BorderBottomRightRadius={
                          DB_LeftSidebar_MenuItem_ProduktPreis_BorderBottomRightRadius
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_BorderBottomLeftRadius={
                          DB_LeftSidebar_MenuItem_ProduktPreis_BorderBottomLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderTopRightRadius={
                          DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderTopRightRadius
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderTopLeftRadius={
                          DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderTopLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderBottomRightRadius={
                          DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderBottomRightRadius
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderBottomLeftRadius={
                          DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderBottomLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderStyleTop={
                          DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderStyleTop
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderColorTop={
                          DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderColorTop
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderBottom={
                          DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderBottom
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderStyleBottom={
                          DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderStyleBottom
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderColorBottom={
                          DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderColorBottom
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderLeft={
                          DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderLeft
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderStyleLeft={
                          DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderStyleLeft
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderColorLeft={
                          DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderColorLeft
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderRight={
                          DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderRight
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderStyleRight={
                          DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderStyleRight
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderColorRight={
                          DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderColorRight
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_marginTopBottom={
                          DB_LeftSidebar_MenuItem_ProduktPreis_marginTopBottom
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_marginLeftRight={
                          DB_LeftSidebar_MenuItem_ProduktPreis_marginLeftRight
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_paddingLeftRight={
                          DB_LeftSidebar_MenuItem_ProduktPreis_paddingLeftRight
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_paddingTopBottom={
                          DB_LeftSidebar_MenuItem_ProduktPreis_paddingTopBottom
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_letterSpacing={
                          DB_LeftSidebar_MenuItem_ProduktPreis_letterSpacing
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_ShadowHorizontal={
                          DB_LeftSidebar_MenuItem_ProduktPreis_ShadowHorizontal
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_ShadowVertical={
                          DB_LeftSidebar_MenuItem_ProduktPreis_ShadowVertical
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_ShadowBlur={
                          DB_LeftSidebar_MenuItem_ProduktPreis_ShadowBlur
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_ShadowColor={
                          DB_LeftSidebar_MenuItem_ProduktPreis_ShadowColor
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_TextDecoration={
                          DB_LeftSidebar_MenuItem_ProduktPreis_TextDecoration
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_TextDecorationStyle={
                          DB_LeftSidebar_MenuItem_ProduktPreis_TextDecorationStyle
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_marginTopBottom_Hover={
                          DB_LeftSidebar_MenuItem_ProduktPreis_marginTopBottom_Hover
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_marginLeftRight_Hover={
                          DB_LeftSidebar_MenuItem_ProduktPreis_marginLeftRight_Hover
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_paddingLeftRight_Hover={
                          DB_LeftSidebar_MenuItem_ProduktPreis_paddingLeftRight_Hover
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_paddingTopBottom_Hover={
                          DB_LeftSidebar_MenuItem_ProduktPreis_paddingTopBottom_Hover
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_letterSpacing_Hover={
                          DB_LeftSidebar_MenuItem_ProduktPreis_letterSpacing_Hover
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_ShadowHorizontal_Hover={
                          DB_LeftSidebar_MenuItem_ProduktPreis_ShadowHorizontal_Hover
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_ShadowVertical_Hover={
                          DB_LeftSidebar_MenuItem_ProduktPreis_ShadowVertical_Hover
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_ShadowBlur_Hover={
                          DB_LeftSidebar_MenuItem_ProduktPreis_ShadowBlur_Hover
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_ShadowColor_Hover={
                          DB_LeftSidebar_MenuItem_ProduktPreis_ShadowColor_Hover
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_TextDecoration_Hover={
                          DB_LeftSidebar_MenuItem_ProduktPreis_TextDecoration_Hover
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_TextDecorationStyle_Hover={
                          DB_LeftSidebar_MenuItem_ProduktPreis_TextDecorationStyle_Hover
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_FontWeight={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_FontWeight
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_FontWeight={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_FontWeight
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_FontColor={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_FontColor
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_FontColor={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_FontColor
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_FontSize={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_FontSize
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_FontSize={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_FontSize
                        }
                        SideBarLeft_ProduktWaehrung_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut={
                          SideBarLeft_ProduktWaehrung_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut
                        }
                        SideBarLeft_ProduktWaehrung_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut={
                          SideBarLeft_ProduktWaehrung_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_BackgroundColor={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_BackgroundColor
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BackgroundColor={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BackgroundColor
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_TextAlign={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_TextAlign
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_TextAlign={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_TextAlign
                        }
                        SideBarLeft_ProduktWaehrung_Farbe_Groesse_Stil_OnMouseOverAndOut={
                          SideBarLeft_ProduktWaehrung_Farbe_Groesse_Stil_OnMouseOverAndOut
                        }
                        SideBarLeft_ProduktWaehrung_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut={
                          SideBarLeft_ProduktWaehrung_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_BorderTop={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_BorderTop
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderTop={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderTop
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_BorderStyleTop={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_BorderStyleTop
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_BorderColorTop={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_BorderColorTop
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_BorderBottom={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_BorderBottom
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_BorderStyleBottom={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_BorderStyleBottom
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_BorderColorBottom={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_BorderColorBottom
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_BorderLeft={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_BorderLeft
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_BorderStyleLeft={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_BorderStyleLeft
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_BorderColorLeft={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_BorderColorLeft
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_BorderRight={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_BorderRight
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_BorderStyleRight={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_BorderStyleRight
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_BorderColorRight={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_BorderColorRight
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_BorderTopRightRadius={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_BorderTopRightRadius
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_BorderTopLeftRadius={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_BorderTopLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_BorderBottomRightRadius={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_BorderBottomRightRadius
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_BorderBottomLeftRadius={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_BorderBottomLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderTopRightRadius={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderTopRightRadius
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderTopLeftRadius={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderTopLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderBottomRightRadius={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderBottomRightRadius
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderBottomLeftRadius={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderBottomLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderStyleTop={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderStyleTop
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderColorTop={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderColorTop
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderBottom={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderBottom
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderStyleBottom={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderStyleBottom
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderColorBottom={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderColorBottom
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderLeft={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderLeft
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderStyleLeft={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderStyleLeft
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderColorLeft={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderColorLeft
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderRight={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderRight
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderStyleRight={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderStyleRight
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderColorRight={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderColorRight
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_marginTopBottom={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_marginTopBottom
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_marginLeftRight={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_marginLeftRight
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_paddingLeftRight={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_paddingLeftRight
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_paddingTopBottom={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_paddingTopBottom
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_letterSpacing={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_letterSpacing
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_ShadowHorizontal={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_ShadowHorizontal
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_ShadowVertical={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_ShadowVertical
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_ShadowBlur={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_ShadowBlur
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_ShadowColor={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_ShadowColor
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_TextDecoration={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_TextDecoration
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_TextDecorationStyle={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_TextDecorationStyle
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_marginTopBottom_Hover={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_marginTopBottom_Hover
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_marginLeftRight_Hover={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_marginLeftRight_Hover
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_paddingLeftRight_Hover={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_paddingLeftRight_Hover
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_paddingTopBottom_Hover={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_paddingTopBottom_Hover
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_letterSpacing_Hover={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_letterSpacing_Hover
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_ShadowHorizontal_Hover={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_ShadowHorizontal_Hover
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_ShadowVertical_Hover={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_ShadowVertical_Hover
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_ShadowBlur_Hover={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_ShadowBlur_Hover
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_ShadowColor_Hover={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_ShadowColor_Hover
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_TextDecoration_Hover={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_TextDecoration_Hover
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_TextDecorationStyle_Hover={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_TextDecorationStyle_Hover
                        }
                      />
                    </div>
                  </ProductCardContainerTwelfe>
                </div>
              );
            } else {
              return (
                <div
                  className={"col-lg-" + columnNumber + " mt-3 col-12"}
                  key={"x" + "-" + product.id}
                >
                  <ProductCardContainerTwelfe
                    DB_LeftSidebar_MenuProduktImageContainer_Hover_BackgroundColor={
                      DB_LeftSidebar_MenuProduktImageContainer_Hover_BackgroundColor
                    }
                    DB_BodyProductCard_BorderRadius={
                      DB_BodyProductCard_BorderRadius
                    }
                    DB_BodyProductCard_BorderTop_Width={
                      DB_BodyProductCard_BorderTop_Width
                    }
                    DB_BodyProductCard_BorderTop_Style={
                      DB_BodyProductCard_BorderTop_Style
                    }
                    DB_BodyProductCard_BorderTop_Color={
                      DB_BodyProductCard_BorderTop_Color
                    }
                    DB_BodyProductCard_BorderBottom_Width={
                      DB_BodyProductCard_BorderBottom_Width
                    }
                    DB_BodyProductCard_BorderBottom_Style={
                      DB_BodyProductCard_BorderBottom_Style
                    }
                    DB_BodyProductCard_BorderBottom_Color={
                      DB_BodyProductCard_BorderBottom_Color
                    }
                    DB_BodyProductCard_BorderLeft_Width={
                      DB_BodyProductCard_BorderLeft_Width
                    }
                    DB_BodyProductCard_BorderLeft_Style={
                      DB_BodyProductCard_BorderLeft_Style
                    }
                    DB_BodyProductCard_BorderLeft_Color={
                      DB_BodyProductCard_BorderLeft_Color
                    }
                    DB_BodyProductCard_BorderRight_Width={
                      DB_BodyProductCard_BorderRight_Width
                    }
                    DB_BodyProductCard_BorderRight_Style={
                      DB_BodyProductCard_BorderRight_Style
                    }
                    DB_BodyProductCard_BorderRight_Color={
                      DB_BodyProductCard_BorderRight_Color
                    }
                    SideBarLeft_ProduktContainer_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut={
                      SideBarLeft_ProduktContainer_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut
                    }
                    SideBarLeft_ProduktContainer_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut={
                      SideBarLeft_ProduktContainer_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut
                    }
                    DB_LeftSidebar_MenuProduktContainer_BackgroundColor={
                      DB_LeftSidebar_MenuProduktContainer_BackgroundColor
                    }
                    DB_LeftSidebar_MenuProduktContainer_PaddingTopBottom={
                      DB_LeftSidebar_MenuProduktContainer_PaddingTopBottom
                    }
                    DB_LeftSidebar_MenuProduktContainer_PaddingLeftRight={
                      DB_LeftSidebar_MenuProduktContainer_PaddingLeftRight
                    }
                    SideBarLeft_ProduktContainer_Farbe_Groesse_Stil_OnMouseOverAndOut={
                      SideBarLeft_ProduktContainer_Farbe_Groesse_Stil_OnMouseOverAndOut
                    }
                    SideBarLeft_ProduktContainer_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut={
                      SideBarLeft_ProduktContainer_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
                    }
                    DB_LeftSidebar_MenuProduktContainer_BorderTop={
                      DB_LeftSidebar_MenuProduktContainer_BorderTop
                    }
                    DB_LeftSidebar_MenuProduktContainer_BorderStyleTop={
                      DB_LeftSidebar_MenuProduktContainer_BorderStyleTop
                    }
                    DB_LeftSidebar_MenuProduktContainer_BorderColorTop={
                      DB_LeftSidebar_MenuProduktContainer_BorderColorTop
                    }
                    DB_LeftSidebar_MenuProduktContainer_BorderBottom={
                      DB_LeftSidebar_MenuProduktContainer_BorderBottom
                    }
                    DB_LeftSidebar_MenuProduktContainer_BorderStyleBottom={
                      DB_LeftSidebar_MenuProduktContainer_BorderStyleBottom
                    }
                    DB_LeftSidebar_MenuProduktContainer_BorderColorBottom={
                      DB_LeftSidebar_MenuProduktContainer_BorderColorBottom
                    }
                    DB_LeftSidebar_MenuProduktContainer_BorderLeft={
                      DB_LeftSidebar_MenuProduktContainer_BorderLeft
                    }
                    DB_LeftSidebar_MenuProduktContainer_BorderStyleLeft={
                      DB_LeftSidebar_MenuProduktContainer_BorderStyleLeft
                    }
                    DB_LeftSidebar_MenuProduktContainer_BorderColorLeft={
                      DB_LeftSidebar_MenuProduktContainer_BorderColorLeft
                    }
                    DB_LeftSidebar_MenuProduktContainer_BorderRight={
                      DB_LeftSidebar_MenuProduktContainer_BorderRight
                    }
                    DB_LeftSidebar_MenuProduktContainer_BorderStyleRight={
                      DB_LeftSidebar_MenuProduktContainer_BorderStyleRight
                    }
                    DB_LeftSidebar_MenuProduktContainer_BorderColorRight={
                      DB_LeftSidebar_MenuProduktContainer_BorderColorRight
                    }
                    DB_LeftSidebar_MenuItem_MenuProduktContainer_BorderTopRightRadius={
                      DB_LeftSidebar_MenuItem_MenuProduktContainer_BorderTopRightRadius
                    }
                    DB_LeftSidebar_MenuItem_MenuProduktContainer_BorderTopLeftRadius={
                      DB_LeftSidebar_MenuItem_MenuProduktContainer_BorderTopLeftRadius
                    }
                    DB_LeftSidebar_MenuItem_MenuProduktContainer_BorderBottomRightRadius={
                      DB_LeftSidebar_MenuItem_MenuProduktContainer_BorderBottomRightRadius
                    }
                    DB_LeftSidebar_MenuItem_MenuProduktContainer_BorderBottomLeftRadius={
                      DB_LeftSidebar_MenuItem_MenuProduktContainer_BorderBottomLeftRadius
                    }
                    DB_LeftSidebar_MenuItem_MenuProduktContainer_paddingLeft={
                      DB_LeftSidebar_MenuItem_MenuProduktContainer_paddingLeft
                    }
                    DB_LeftSidebar_MenuItem_MenuProduktContainer_paddingRight={
                      DB_LeftSidebar_MenuItem_MenuProduktContainer_paddingRight
                    }
                    DB_LeftSidebar_MenuItem_MenuProduktContainer_paddingTop={
                      DB_LeftSidebar_MenuItem_MenuProduktContainer_paddingTop
                    }
                    DB_LeftSidebar_MenuItem_MenuProduktContainer_paddingBottom={
                      DB_LeftSidebar_MenuItem_MenuProduktContainer_paddingBottom
                    }
                    DB_LeftSidebar_MenuItem_MenuProduktContainer_ShadowHorizontal={
                      DB_LeftSidebar_MenuItem_MenuProduktContainer_ShadowHorizontal
                    }
                    DB_LeftSidebar_MenuItem_MenuProduktContainer_ShadowBlur={
                      DB_LeftSidebar_MenuItem_MenuProduktContainer_ShadowBlur
                    }
                    DB_LeftSidebar_MenuItem_MenuProduktContainer_ShadowColor={
                      DB_LeftSidebar_MenuItem_MenuProduktContainer_ShadowColor
                    }
                    DB_LeftSidebar_MenuProduktContainer_Hover_BackgroundColor={
                      DB_LeftSidebar_MenuProduktContainer_Hover_BackgroundColor
                    }
                    DB_LeftSidebar_MenuProduktContainer_Hover_PaddingTopBottom={
                      DB_LeftSidebar_MenuProduktContainer_Hover_PaddingTopBottom
                    }
                    DB_LeftSidebar_MenuProduktContainer_Hover_PaddingLeftRight={
                      DB_LeftSidebar_MenuProduktContainer_Hover_PaddingLeftRight
                    }
                    DB_LeftSidebar_MenuProduktContainer_Hover_BorderTop={
                      DB_LeftSidebar_MenuProduktContainer_Hover_BorderTop
                    }
                    DB_LeftSidebar_MenuProduktContainer_Hover_BorderStyleTop={
                      DB_LeftSidebar_MenuProduktContainer_Hover_BorderStyleTop
                    }
                    DB_LeftSidebar_MenuProduktContainer_Hover_BorderColorTop={
                      DB_LeftSidebar_MenuProduktContainer_Hover_BorderColorTop
                    }
                    DB_LeftSidebar_MenuProduktContainer_Hover_BorderBottom={
                      DB_LeftSidebar_MenuProduktContainer_Hover_BorderBottom
                    }
                    DB_LeftSidebar_MenuProduktContainer_Hover_BorderStyleBottom={
                      DB_LeftSidebar_MenuProduktContainer_Hover_BorderStyleBottom
                    }
                    DB_LeftSidebar_MenuProduktContainer_Hover_BorderColorBottom={
                      DB_LeftSidebar_MenuProduktContainer_Hover_BorderColorBottom
                    }
                    DB_LeftSidebar_MenuProduktContainer_Hover_BorderLeft={
                      DB_LeftSidebar_MenuProduktContainer_Hover_BorderLeft
                    }
                    DB_LeftSidebar_MenuProduktContainer_Hover_BorderStyleLeft={
                      DB_LeftSidebar_MenuProduktContainer_Hover_BorderStyleLeft
                    }
                    DB_LeftSidebar_MenuProduktContainer_Hover_BorderColorLeft={
                      DB_LeftSidebar_MenuProduktContainer_Hover_BorderColorLeft
                    }
                    DB_LeftSidebar_MenuProduktContainer_Hover_BorderRight={
                      DB_LeftSidebar_MenuProduktContainer_Hover_BorderRight
                    }
                    DB_LeftSidebar_MenuProduktContainer_Hover_BorderStyleRight={
                      DB_LeftSidebar_MenuProduktContainer_Hover_BorderStyleRight
                    }
                    DB_LeftSidebar_MenuProduktContainer_Hover_BorderColorRight={
                      DB_LeftSidebar_MenuProduktContainer_Hover_BorderColorRight
                    }
                    DB_LeftSidebar_MenuItem_MenuProduktContainer_Hover_BorderTopRightRadius={
                      DB_LeftSidebar_MenuItem_MenuProduktContainer_Hover_BorderTopRightRadius
                    }
                    DB_LeftSidebar_MenuItem_MenuProduktContainer_Hover_BorderTopLeftRadius={
                      DB_LeftSidebar_MenuItem_MenuProduktContainer_Hover_BorderTopLeftRadius
                    }
                    DB_LeftSidebar_MenuItem_MenuProduktContainer_Hover_BorderBottomRightRadius={
                      DB_LeftSidebar_MenuItem_MenuProduktContainer_Hover_BorderBottomRightRadius
                    }
                    DB_LeftSidebar_MenuItem_MenuProduktContainer_Hover_BorderBottomLeftRadius={
                      DB_LeftSidebar_MenuItem_MenuProduktContainer_Hover_BorderBottomLeftRadius
                    }
                    DB_LeftSidebar_MenuItem_MenuProduktContainer_Hover_paddingLeft={
                      DB_LeftSidebar_MenuItem_MenuProduktContainer_Hover_paddingLeft
                    }
                    DB_LeftSidebar_MenuItem_MenuProduktContainer_Hover_paddingRight={
                      DB_LeftSidebar_MenuItem_MenuProduktContainer_Hover_paddingRight
                    }
                    DB_LeftSidebar_MenuItem_MenuProduktContainer_Hover_paddingTop={
                      DB_LeftSidebar_MenuItem_MenuProduktContainer_Hover_paddingTop
                    }
                    DB_LeftSidebar_MenuItem_MenuProduktContainer_Hover_paddingBottom={
                      DB_LeftSidebar_MenuItem_MenuProduktContainer_Hover_paddingBottom
                    }
                    DB_LeftSidebar_MenuItem_MenuProduktContainer_ShadowVertical={
                      DB_LeftSidebar_MenuItem_MenuProduktContainer_ShadowVertical
                    }
                    DB_LeftSidebar_MenuItem_MenuProduktContainer_Hover_ShadowHorizontal={
                      DB_LeftSidebar_MenuItem_MenuProduktContainer_Hover_ShadowHorizontal
                    }
                    DB_LeftSidebar_MenuItem_MenuProduktContainer_Hover_ShadowBlur={
                      DB_LeftSidebar_MenuItem_MenuProduktContainer_Hover_ShadowBlur
                    }
                    DB_LeftSidebar_MenuItem_MenuProduktContainer_Hover_ShadowColor={
                      DB_LeftSidebar_MenuItem_MenuProduktContainer_Hover_ShadowColor
                    }
                    DB_LeftSidebar_MenuItem_MenuProduktContainer_Hover_ShadowVertical={
                      DB_LeftSidebar_MenuItem_MenuProduktContainer_Hover_ShadowVertical
                    }
                  >
                    <div
                      className={classes.imageHeight}
                      style={{
                        position: "relative",
                        borderBottomRightRadius: `${DB_LeftSidebar_MenuItem_MenuProduktContainer_BorderBottomRightRadius}px`,
                        borderTopRightRadius: `${DB_LeftSidebar_MenuItem_MenuProduktContainer_BorderTopRightRadius}px`,
                        borderBottomLeftRadius: `${DB_LeftSidebar_MenuItem_MenuProduktContainer_BorderBottomLeftRadius}px`,
                        borderTopLeftRadius: `${DB_LeftSidebar_MenuItem_MenuProduktContainer_BorderTopLeftRadius}px`,
                      }}
                    >
                      {product.mainImage && (
                        <div
                          /* href={`?s=${product.titel}&t=${trace}`} */
                          style={{
                            cursor: "pointer",
                            textDecoration: "none",
                            objectFit: "contain",
                          }}
                          className={classes.productImagesTwoThreeFour}
                        >
                          <img
                            onClick={(productIdentifier) =>
                              showProductDetailsHandler(product.titel)
                            }
                            alt={product.titel}
                            src={`${process.env.REACT_APP_PUBLIC_SERVER_URL}/${product.mainImage}`}
                            style={{
                              objectFit: "contain",
                              cursor: "pointer",
                              width: "100%",
                              height: "100%",
                              position: "absolute",
                            }}
                            className={classes.productImagesTwoThreeFour}
                          />
                        </div>
                      )}
                    </div>
                    <div
                      style={{
                        borderBottomRightRadius: `${DB_LeftSidebar_MenuItem_MenuProduktContainer_BorderBottomRightRadius}px`,
                        borderBottomLeftRadius: `${DB_LeftSidebar_MenuItem_MenuProduktContainer_BorderBottomLeftRadius}px`,
                      }}
                      className="productDescription"
                    >
                      <ProductCardBreadCrumbsThree
                        DB_BodyProductCard_Breadcrumbs_FontFamily={
                          DB_BodyProductCard_Breadcrumbs_FontFamily
                        }
                        DB_fontFace_chooseFontBreadcrumbs_FontFamilyName={
                          DB_fontFace_chooseFontBreadcrumbs_FontFamilyName
                        }
                        DB_fontFace_chooseFontBreadcrumbs_FontFamilySrc={
                          DB_fontFace_chooseFontBreadcrumbs_FontFamilySrc
                        }
                        categoryLeftSideHandler={categoryLeftSideHandler}
                        productKategorie={product.kategorie}
                        productUnterkategorie={product.unterkategorie}
                        productUnterUnterkategorie={product.unterunterkategorie}
                        DB_LeftSidebar_MenuItem_BreadCrumbs_FontWeight={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_FontWeight
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_FontWeight={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_FontWeight
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_FontColor={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_FontColor
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_FontColor={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_FontColor
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_FontSize={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_FontSize
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_FontSize={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_FontSize
                        }
                        SideBarLeft_BreadCrumbs_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut={
                          SideBarLeft_BreadCrumbs_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut
                        }
                        SideBarLeft_BreadCrumbs_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut={
                          SideBarLeft_BreadCrumbs_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_BackgroundColor={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_BackgroundColor
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BackgroundColor={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BackgroundColor
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_TextAlign={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_TextAlign
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_TextAlign={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_TextAlign
                        }
                        SideBarLeft_BreadCrumbs_Farbe_Groesse_Stil_OnMouseOverAndOut={
                          SideBarLeft_BreadCrumbs_Farbe_Groesse_Stil_OnMouseOverAndOut
                        }
                        SideBarLeft_BreadCrumbs_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut={
                          SideBarLeft_BreadCrumbs_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_BorderTop={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_BorderTop
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderTop={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderTop
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_BorderStyleTop={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_BorderStyleTop
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_BorderColorTop={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_BorderColorTop
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_BorderBottom={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_BorderBottom
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_BorderStyleBottom={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_BorderStyleBottom
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_BorderColorBottom={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_BorderColorBottom
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_BorderLeft={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_BorderLeft
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_BorderStyleLeft={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_BorderStyleLeft
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_BorderColorLeft={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_BorderColorLeft
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_BorderRight={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_BorderRight
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_BorderStyleRight={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_BorderStyleRight
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_BorderColorRight={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_BorderColorRight
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_BorderTopRightRadius={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_BorderTopRightRadius
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_BorderTopLeftRadius={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_BorderTopLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_BorderBottomRightRadius={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_BorderBottomRightRadius
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_BorderBottomLeftRadius={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_BorderBottomLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderTopRightRadius={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderTopRightRadius
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderTopLeftRadius={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderTopLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderBottomRightRadius={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderBottomRightRadius
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderBottomLeftRadius={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderBottomLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderStyleTop={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderStyleTop
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderColorTop={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderColorTop
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderBottom={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderBottom
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderStyleBottom={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderStyleBottom
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderColorBottom={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderColorBottom
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderLeft={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderLeft
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderStyleLeft={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderStyleLeft
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderColorLeft={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderColorLeft
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderRight={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderRight
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderStyleRight={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderStyleRight
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderColorRight={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_Hover_BorderColorRight
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_marginTopBottom={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_marginTopBottom
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_marginLeftRight={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_marginLeftRight
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_paddingLeftRight={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_paddingLeftRight
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_paddingTopBottom={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_paddingTopBottom
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_letterSpacing={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_letterSpacing
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_ShadowHorizontal={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_ShadowHorizontal
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_ShadowVertical={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_ShadowVertical
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_ShadowBlur={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_ShadowBlur
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_ShadowColor={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_ShadowColor
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_TextDecoration={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_TextDecoration
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_TextDecorationStyle={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_TextDecorationStyle
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_marginTopBottom_Hover={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_marginTopBottom_Hover
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_marginLeftRight_Hover={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_marginLeftRight_Hover
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_paddingLeftRight_Hover={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_paddingLeftRight_Hover
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_paddingTopBottom_Hover={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_paddingTopBottom_Hover
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_letterSpacing_Hover={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_letterSpacing_Hover
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_ShadowHorizontal_Hover={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_ShadowHorizontal_Hover
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_ShadowVertical_Hover={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_ShadowVertical_Hover
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_ShadowBlur_Hover={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_ShadowBlur_Hover
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_ShadowColor_Hover={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_ShadowColor_Hover
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_TextDecoration_Hover={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_TextDecoration_Hover
                        }
                        DB_LeftSidebar_MenuItem_BreadCrumbs_TextDecorationStyle_Hover={
                          DB_LeftSidebar_MenuItem_BreadCrumbs_TextDecorationStyle_Hover
                        }
                      />
                      <div
                        /* href={`?s=${product.titel}&t=${trace}`} */
                        style={{
                          cursor: "pointer",
                          textDecoration: "none",
                        }}
                        className={`card-title ${classes.produktTitel}`}
                      >
                        <div
                          style={{ cursor: "pointer" }}
                          onClick={(productIdentifier) =>
                            showProductDetailsHandler(product.titel)
                          }
                        >
                          {product.titel}
                        </div>
                      </div>
                      {product.untertitel ? (
                        <div
                          /* href={`?s=${product.titel}&t=${trace}`} */
                          style={{
                            cursor: "pointer",
                            textDecoration: "none",
                          }}
                        >
                          <div
                            onClick={(productIdentifier) =>
                              showProductDetailsHandler(product.titel)
                            }
                            className={`card-text ${classes.produktUntertitel}`}
                          >
                            {product.untertitel}
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}

                      <ProductCardPreisWaehrung
                        productTitle={product.titel}
                        showProductDetailsHandler={showProductDetailsHandler}
                        productFixpreis={product.fixpreis}
                        productAktionspreis={product.startpreis}
                        DB_fontFace_chooseFontWaehrung_FontFamilyName={
                          DB_fontFace_chooseFontWaehrung_FontFamilyName
                        }
                        DB_fontFace_chooseFontWaehrung_FontFamilySrc={
                          DB_fontFace_chooseFontWaehrung_FontFamilySrc
                        }
                        DB_fontFace_chooseFontWaehrungEuro_FontFamilyName={
                          DB_fontFace_chooseFontWaehrungEuro_FontFamilyName
                        }
                        DB_fontFace_chooseFontWaehrungEuro_FontFamilySrc={
                          DB_fontFace_chooseFontWaehrungEuro_FontFamilySrc
                        }
                        DB_BodyProductCard_Waehrung={
                          DB_BodyProductCard_Waehrung
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_FontWeight={
                          DB_LeftSidebar_MenuItem_ProduktPreis_FontWeight
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_Hover_FontWeight={
                          DB_LeftSidebar_MenuItem_ProduktPreis_Hover_FontWeight
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_FontColor={
                          DB_LeftSidebar_MenuItem_ProduktPreis_FontColor
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_Hover_FontColor={
                          DB_LeftSidebar_MenuItem_ProduktPreis_Hover_FontColor
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_FontSize={
                          DB_LeftSidebar_MenuItem_ProduktPreis_FontSize
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_Hover_FontSize={
                          DB_LeftSidebar_MenuItem_ProduktPreis_Hover_FontSize
                        }
                        SideBarLeft_ProduktPreis_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut={
                          SideBarLeft_ProduktPreis_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut
                        }
                        SideBarLeft_ProduktPreis_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut={
                          SideBarLeft_ProduktPreis_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_BackgroundColor={
                          DB_LeftSidebar_MenuItem_ProduktPreis_BackgroundColor
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BackgroundColor={
                          DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BackgroundColor
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_TextAlign={
                          DB_LeftSidebar_MenuItem_ProduktPreis_TextAlign
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_Hover_TextAlign={
                          DB_LeftSidebar_MenuItem_ProduktPreis_Hover_TextAlign
                        }
                        SideBarLeft_ProduktPreis_Farbe_Groesse_Stil_OnMouseOverAndOut={
                          SideBarLeft_ProduktPreis_Farbe_Groesse_Stil_OnMouseOverAndOut
                        }
                        SideBarLeft_ProduktPreis_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut={
                          SideBarLeft_ProduktPreis_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_BorderTop={
                          DB_LeftSidebar_MenuItem_ProduktPreis_BorderTop
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderTop={
                          DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderTop
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_BorderStyleTop={
                          DB_LeftSidebar_MenuItem_ProduktPreis_BorderStyleTop
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_BorderColorTop={
                          DB_LeftSidebar_MenuItem_ProduktPreis_BorderColorTop
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_BorderBottom={
                          DB_LeftSidebar_MenuItem_ProduktPreis_BorderBottom
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_BorderStyleBottom={
                          DB_LeftSidebar_MenuItem_ProduktPreis_BorderStyleBottom
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_BorderColorBottom={
                          DB_LeftSidebar_MenuItem_ProduktPreis_BorderColorBottom
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_BorderLeft={
                          DB_LeftSidebar_MenuItem_ProduktPreis_BorderLeft
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_BorderStyleLeft={
                          DB_LeftSidebar_MenuItem_ProduktPreis_BorderStyleLeft
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_BorderColorLeft={
                          DB_LeftSidebar_MenuItem_ProduktPreis_BorderColorLeft
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_BorderRight={
                          DB_LeftSidebar_MenuItem_ProduktPreis_BorderRight
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_BorderStyleRight={
                          DB_LeftSidebar_MenuItem_ProduktPreis_BorderStyleRight
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_BorderColorRight={
                          DB_LeftSidebar_MenuItem_ProduktPreis_BorderColorRight
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_BorderTopRightRadius={
                          DB_LeftSidebar_MenuItem_ProduktPreis_BorderTopRightRadius
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_BorderTopLeftRadius={
                          DB_LeftSidebar_MenuItem_ProduktPreis_BorderTopLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_BorderBottomRightRadius={
                          DB_LeftSidebar_MenuItem_ProduktPreis_BorderBottomRightRadius
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_BorderBottomLeftRadius={
                          DB_LeftSidebar_MenuItem_ProduktPreis_BorderBottomLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderTopRightRadius={
                          DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderTopRightRadius
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderTopLeftRadius={
                          DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderTopLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderBottomRightRadius={
                          DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderBottomRightRadius
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderBottomLeftRadius={
                          DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderBottomLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderStyleTop={
                          DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderStyleTop
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderColorTop={
                          DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderColorTop
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderBottom={
                          DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderBottom
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderStyleBottom={
                          DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderStyleBottom
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderColorBottom={
                          DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderColorBottom
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderLeft={
                          DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderLeft
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderStyleLeft={
                          DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderStyleLeft
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderColorLeft={
                          DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderColorLeft
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderRight={
                          DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderRight
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderStyleRight={
                          DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderStyleRight
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderColorRight={
                          DB_LeftSidebar_MenuItem_ProduktPreis_Hover_BorderColorRight
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_marginTopBottom={
                          DB_LeftSidebar_MenuItem_ProduktPreis_marginTopBottom
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_marginLeftRight={
                          DB_LeftSidebar_MenuItem_ProduktPreis_marginLeftRight
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_paddingLeftRight={
                          DB_LeftSidebar_MenuItem_ProduktPreis_paddingLeftRight
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_paddingTopBottom={
                          DB_LeftSidebar_MenuItem_ProduktPreis_paddingTopBottom
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_letterSpacing={
                          DB_LeftSidebar_MenuItem_ProduktPreis_letterSpacing
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_ShadowHorizontal={
                          DB_LeftSidebar_MenuItem_ProduktPreis_ShadowHorizontal
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_ShadowVertical={
                          DB_LeftSidebar_MenuItem_ProduktPreis_ShadowVertical
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_ShadowBlur={
                          DB_LeftSidebar_MenuItem_ProduktPreis_ShadowBlur
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_ShadowColor={
                          DB_LeftSidebar_MenuItem_ProduktPreis_ShadowColor
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_TextDecoration={
                          DB_LeftSidebar_MenuItem_ProduktPreis_TextDecoration
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_TextDecorationStyle={
                          DB_LeftSidebar_MenuItem_ProduktPreis_TextDecorationStyle
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_marginTopBottom_Hover={
                          DB_LeftSidebar_MenuItem_ProduktPreis_marginTopBottom_Hover
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_marginLeftRight_Hover={
                          DB_LeftSidebar_MenuItem_ProduktPreis_marginLeftRight_Hover
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_paddingLeftRight_Hover={
                          DB_LeftSidebar_MenuItem_ProduktPreis_paddingLeftRight_Hover
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_paddingTopBottom_Hover={
                          DB_LeftSidebar_MenuItem_ProduktPreis_paddingTopBottom_Hover
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_letterSpacing_Hover={
                          DB_LeftSidebar_MenuItem_ProduktPreis_letterSpacing_Hover
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_ShadowHorizontal_Hover={
                          DB_LeftSidebar_MenuItem_ProduktPreis_ShadowHorizontal_Hover
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_ShadowVertical_Hover={
                          DB_LeftSidebar_MenuItem_ProduktPreis_ShadowVertical_Hover
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_ShadowBlur_Hover={
                          DB_LeftSidebar_MenuItem_ProduktPreis_ShadowBlur_Hover
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_ShadowColor_Hover={
                          DB_LeftSidebar_MenuItem_ProduktPreis_ShadowColor_Hover
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_TextDecoration_Hover={
                          DB_LeftSidebar_MenuItem_ProduktPreis_TextDecoration_Hover
                        }
                        DB_LeftSidebar_MenuItem_ProduktPreis_TextDecorationStyle_Hover={
                          DB_LeftSidebar_MenuItem_ProduktPreis_TextDecorationStyle_Hover
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_FontWeight={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_FontWeight
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_FontWeight={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_FontWeight
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_FontColor={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_FontColor
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_FontColor={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_FontColor
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_FontSize={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_FontSize
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_FontSize={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_FontSize
                        }
                        SideBarLeft_ProduktWaehrung_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut={
                          SideBarLeft_ProduktWaehrung_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut
                        }
                        SideBarLeft_ProduktWaehrung_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut={
                          SideBarLeft_ProduktWaehrung_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_BackgroundColor={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_BackgroundColor
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BackgroundColor={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BackgroundColor
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_TextAlign={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_TextAlign
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_TextAlign={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_TextAlign
                        }
                        SideBarLeft_ProduktWaehrung_Farbe_Groesse_Stil_OnMouseOverAndOut={
                          SideBarLeft_ProduktWaehrung_Farbe_Groesse_Stil_OnMouseOverAndOut
                        }
                        SideBarLeft_ProduktWaehrung_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut={
                          SideBarLeft_ProduktWaehrung_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_BorderTop={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_BorderTop
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderTop={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderTop
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_BorderStyleTop={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_BorderStyleTop
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_BorderColorTop={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_BorderColorTop
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_BorderBottom={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_BorderBottom
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_BorderStyleBottom={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_BorderStyleBottom
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_BorderColorBottom={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_BorderColorBottom
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_BorderLeft={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_BorderLeft
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_BorderStyleLeft={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_BorderStyleLeft
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_BorderColorLeft={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_BorderColorLeft
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_BorderRight={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_BorderRight
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_BorderStyleRight={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_BorderStyleRight
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_BorderColorRight={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_BorderColorRight
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_BorderTopRightRadius={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_BorderTopRightRadius
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_BorderTopLeftRadius={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_BorderTopLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_BorderBottomRightRadius={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_BorderBottomRightRadius
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_BorderBottomLeftRadius={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_BorderBottomLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderTopRightRadius={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderTopRightRadius
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderTopLeftRadius={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderTopLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderBottomRightRadius={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderBottomRightRadius
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderBottomLeftRadius={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderBottomLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderStyleTop={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderStyleTop
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderColorTop={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderColorTop
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderBottom={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderBottom
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderStyleBottom={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderStyleBottom
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderColorBottom={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderColorBottom
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderLeft={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderLeft
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderStyleLeft={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderStyleLeft
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderColorLeft={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderColorLeft
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderRight={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderRight
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderStyleRight={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderStyleRight
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderColorRight={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_Hover_BorderColorRight
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_marginTopBottom={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_marginTopBottom
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_marginLeftRight={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_marginLeftRight
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_paddingLeftRight={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_paddingLeftRight
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_paddingTopBottom={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_paddingTopBottom
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_letterSpacing={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_letterSpacing
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_ShadowHorizontal={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_ShadowHorizontal
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_ShadowVertical={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_ShadowVertical
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_ShadowBlur={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_ShadowBlur
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_ShadowColor={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_ShadowColor
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_TextDecoration={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_TextDecoration
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_TextDecorationStyle={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_TextDecorationStyle
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_marginTopBottom_Hover={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_marginTopBottom_Hover
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_marginLeftRight_Hover={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_marginLeftRight_Hover
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_paddingLeftRight_Hover={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_paddingLeftRight_Hover
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_paddingTopBottom_Hover={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_paddingTopBottom_Hover
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_letterSpacing_Hover={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_letterSpacing_Hover
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_ShadowHorizontal_Hover={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_ShadowHorizontal_Hover
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_ShadowVertical_Hover={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_ShadowVertical_Hover
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_ShadowBlur_Hover={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_ShadowBlur_Hover
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_ShadowColor_Hover={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_ShadowColor_Hover
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_TextDecoration_Hover={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_TextDecoration_Hover
                        }
                        DB_LeftSidebar_MenuItem_ProduktWaehrung_TextDecorationStyle_Hover={
                          DB_LeftSidebar_MenuItem_ProduktWaehrung_TextDecorationStyle_Hover
                        }
                      />
                    </div>
                  </ProductCardContainerTwelfe>
                </div>
              );
            }
          })()}
        </React.Fragment>
      </React.Fragment>
    );
  });
};

export default BodyAllProductCards;
