// @flow
import React from "react";
import { Button, InputGroup, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const RegisterDetails = ({
  regVorname,
  doRegVornameHandler,
  regNachname,
  doRegNachnameHandler,
  regFirma,
  doRegFirmaHandler,
  regHomepage,
  doRegHomepageHandler,
  doEmailPWHandler,
  vornameValid,
  nachnameValid,
  firmaValid,
  homepageValid,
  detailsConfirm,
}) => {
  const { t } = useTranslation();

  return (
    <>
      {/* {(userLoggedIn || user) && <Navigate to={redirectUrl} replace />} */}

      <form
        style={{ paddingLeft: "45px", paddingRight: "45px", marginTop: "10px" }}
        action="#"
      >
        <div className="mb-1 mt-3 text-center">
          <label
            style={{ color: "white", fontSize: "16px" }}
            htmlFor="firstname"
            className="form-label"
          >
            {t("Vorname")}
          </label>
        </div>
        <InputGroup>
          <InputGroup.Text
            className={
              vornameValid
                ? "emailUniIconContainer regInputValid"
                : "emailUniIconContainer regInputInvalid"
            }
          >
            <span id="emailUniIcon">🙋</span>
          </InputGroup.Text>
          <Form.Control
            aria-label="firstname"
            aria-describedby="emailUniIcon"
            onChange={doRegVornameHandler}
            value={regVorname}
            className={
              vornameValid
                ? "regInputValid form-control inputEmailPw"
                : "regInputInvalid form-control inputEmailPw"
            }
            type="text"
            id="firstname"
            placeholder={t("Vorname")}
          />
        </InputGroup>
        <div className="mb-4" style={{ paddingTop: "2px", paddingLeft: "2px" }}>
          {!vornameValid && (
            <div
              style={{ fontSize: "13px", color: "#eb0b3d", fontWeight: "bold" }}
            >
              {t("VornameMeldung")}
            </div>
          )}
        </div>
        <div className="mb-1 text-center">
          <label
            style={{ color: "white", fontSize: "16px" }}
            htmlFor="lastname"
            className="form-label"
          >
            {t("Nachname")}
          </label>
        </div>

        <InputGroup>
          <InputGroup.Text
            className={
              nachnameValid
                ? "emailUniIconContainer regInputValid"
                : "emailUniIconContainer regInputInvalid"
            }
          >
            <span id="emailUniIcon">🤵</span>
          </InputGroup.Text>
          <Form.Control
            aria-label="lastname"
            aria-describedby="emailUniIcon"
            onChange={doRegNachnameHandler}
            value={regNachname}
            className={
              nachnameValid
                ? "regInputValid form-control inputEmailPw"
                : "regInputInvalid form-control inputEmailPw"
            }
            type="text"
            id="lastname"
            placeholder={t("Nachname")}
          />
        </InputGroup>
        <div className="mb-4" style={{ paddingTop: "2px", paddingLeft: "2px" }}>
          {!nachnameValid && (
            <div
              style={{ fontSize: "13px", color: "#eb0b3d", fontWeight: "bold" }}
            >
              {t("NachnameMeldung")}
            </div>
          )}
        </div>

        <div className="mb-1 text-center">
          <label
            style={{ color: "white", fontSize: "16px" }}
            htmlFor="company"
            className="form-label"
          >
            {t("Firma")}
          </label>
        </div>

        <InputGroup className="mb-4">
          <InputGroup.Text className="emailUniIconContainer">
            <span id="emailUniIcon">📇</span>
          </InputGroup.Text>
          <Form.Control
            aria-label="company"
            aria-describedby="emailUniIcon"
            onChange={doRegFirmaHandler}
            value={regFirma}
            className="form-control inputEmailPw"
            type="text"
            id="company"
            placeholder={t("Firma")}
          />
        </InputGroup>

        <div className="mb-1 text-center">
          <label
            style={{ color: "white", fontSize: "16px" }}
            htmlFor="website"
            className="form-label"
          >
            {t("Homepage")}
          </label>
        </div>
        <InputGroup className="mb-4">
          <InputGroup.Text className="emailUniIconContainer">
            <span id="emailUniIcon">🌐</span>
          </InputGroup.Text>
          <Form.Control
            aria-label="website"
            aria-describedby="emailUniIcon"
            onChange={doRegHomepageHandler}
            value={regHomepage}
            className="form-control inputEmailPw"
            type="text"
            id="website"
            placeholder={t("Homepage")}
          />
        </InputGroup>

        <div className="mt-5 d-flex justify-content-between">
          <div className="mb-4" style={{ display: "inline-block" }}>
            <Button onClick={doEmailPWHandler} variant="secondary">
              {t("Zurueck")}
            </Button>
          </div>
          <div className="mb-4" style={{ display: "inline-block" }}>
            <Button variant="primary" onClick={detailsConfirm}>
              {t("Weiter")}
            </Button>
          </div>
        </div>
      </form>
    </>
  );
};

export default RegisterDetails;
