import { useState, useEffect } from "react";
import ImageGallery from "react-image-gallery";
import "/node_modules/react-image-gallery/styles/css/image-gallery.css";
import { createUseStyles } from "react-jss";

const AboutUs = ({
  traceHandler,
  aboutUsDetailItem,
  images,
  DB_fontFace_chooseFontHaupttitelFontFamily_FontFamilyName,
  DB_fontFace_chooseFontBeschreibungFontFamily_FontFamilyName,
  SideBarLeft_ContainerDetail_Farbe_Groesse_Stil_OnMouseOverAndOut,
  SideBarLeft_ContainerDetail_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut,
  SideBarLeft_DetailItem_Farbe_Groesse_Stil_OnMouseOverAndOut,
  SideBarLeft_DetailItem_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut,

  DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_FontWeight,
  DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_FontColor,
  DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_FontSize,
  DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BackgroundColor,
  DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_TextAlign,
  DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderTop,
  DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderStyleTop,
  DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderColorTop,
  DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderBottom,
  DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderStyleBottom,
  DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderColorBottom,
  DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderLeft,
  DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderStyleLeft,
  DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderColorLeft,
  DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderRight,
  DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderStyleRight,
  DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderColorRight,
  DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderTopRightRadius,
  DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderTopLeftRadius,
  DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderBottomRightRadius,
  DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderBottomLeftRadius,
  DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_marginLeftRight,
  DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_marginTopBottom,
  DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_paddingLeftRight,
  DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_paddingTopBottom,
  DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_letterSpacing,
  DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_ShadowHorizontal,
  DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_ShadowVertical,
  DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_ShadowBlur,
  DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_ShadowColor,
  DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_TextDecoration,
  DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_TextDecorationStyle,

  DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_FontWeight,
  DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_FontColor,
  DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_FontSize,
  DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BackgroundColor,
  DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_TextAlign,
  DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderTop,
  DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderStyleTop,
  DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderColorTop,
  DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderBottom,
  DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderStyleBottom,
  DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderColorBottom,
  DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderLeft,
  DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderStyleLeft,
  DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderColorLeft,
  DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderRight,
  DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderStyleRight,
  DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderColorRight,
  DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderTopRightRadius,
  DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderTopLeftRadius,
  DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderBottomRightRadius,
  DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderBottomLeftRadius,
  DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_marginLeftRight,
  DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_marginTopBottom,
  DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_paddingLeftRight,
  DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_paddingTopBottom,
  DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_letterSpacing,
  DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_ShadowHorizontal,
  DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_ShadowVertical,
  DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_ShadowBlur,
  DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_ShadowColor,
  DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_TextDecoration,
  DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_TextDecorationStyle,
}) => {
  const [showBullets] = useState(false);
  const [infinite] = useState(false);
  const [showThumbnails] = useState(false);
  const [showFullscreenButton] = useState(true);
  const [showGalleryFullscreenButton] = useState(false);
  const [showPlayButton] = useState(false);
  const [showGalleryPlayButton] = useState(false);
  const [showNav] = useState(true);
  const [isRTL] = useState(false);
  const [slideDuration] = useState(800);
  const [slideInterval] = useState(3000);
  const [slideOnThumbnailOver] = useState(false);
  const [thumbnailPosition] = useState("bottom");
  const [useWindowKeyDown] = useState(true);

  useEffect(() => {
    traceHandler();
  }, []);

  const DB_ProductDetails_BorderRadius = 15;

  const useStyles = createUseStyles({
    productDetailItemHeaderTitel: {
      fontFamily: `${DB_fontFace_chooseFontHaupttitelFontFamily_FontFamilyName}`,
      fontWeight: `${DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_FontWeight}`,
      color: `${DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_FontColor}`,
      fontSize: `${DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_FontSize}px`,
      backgroundColor: `${
        SideBarLeft_ContainerDetail_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut
          ? "rgba(255,0,0,0.5)"
          : DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BackgroundColor
      }`,
      textAlign: `${DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_TextAlign}`,
      borderTop: `${
        SideBarLeft_ContainerDetail_Farbe_Groesse_Stil_OnMouseOverAndOut
          ? "3"
          : DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderTop
      }px
        ${
          SideBarLeft_ContainerDetail_Farbe_Groesse_Stil_OnMouseOverAndOut
            ? "solid"
            : DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderStyleTop
        }
        ${
          SideBarLeft_ContainerDetail_Farbe_Groesse_Stil_OnMouseOverAndOut
            ? "red"
            : DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderColorTop
        }`,
      borderBottom: `${
        SideBarLeft_ContainerDetail_Farbe_Groesse_Stil_OnMouseOverAndOut
          ? "3"
          : DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderBottom
      }px
        ${
          SideBarLeft_ContainerDetail_Farbe_Groesse_Stil_OnMouseOverAndOut
            ? "solid"
            : DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderStyleBottom
        }
        ${
          SideBarLeft_ContainerDetail_Farbe_Groesse_Stil_OnMouseOverAndOut
            ? "red"
            : DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderColorBottom
        }`,
      borderLeft: `${
        SideBarLeft_ContainerDetail_Farbe_Groesse_Stil_OnMouseOverAndOut
          ? "3"
          : DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderLeft
      }px
        ${
          SideBarLeft_ContainerDetail_Farbe_Groesse_Stil_OnMouseOverAndOut
            ? "solid"
            : DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderStyleLeft
        }
        ${
          SideBarLeft_ContainerDetail_Farbe_Groesse_Stil_OnMouseOverAndOut
            ? "red"
            : DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderColorLeft
        }`,
      borderRight: `${
        SideBarLeft_ContainerDetail_Farbe_Groesse_Stil_OnMouseOverAndOut
          ? "3"
          : DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderRight
      }px
        ${
          SideBarLeft_ContainerDetail_Farbe_Groesse_Stil_OnMouseOverAndOut
            ? "solid"
            : DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderStyleRight
        }
        ${
          SideBarLeft_ContainerDetail_Farbe_Groesse_Stil_OnMouseOverAndOut
            ? "red"
            : DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderColorRight
        }`,
      borderTopRightRadius: `${DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderTopRightRadius}px`,
      borderTopLeftRadius: `${DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderTopLeftRadius}px`,
      borderBottomRightRadius: `${DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderBottomRightRadius}px`,
      borderBottomLeftRadius: `${DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderBottomLeftRadius}px`,
      marginLeft: `${DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_marginLeftRight}px`,
      marginRight: `${DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_marginLeftRight}px`,
      marginTop: `${DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_marginTopBottom}px`,
      marginBottom: `${DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_marginTopBottom}px`,
      paddingLeft: `${DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_paddingLeftRight}px`,
      paddingRight: `${DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_paddingLeftRight}px`,
      paddingTop: `${DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_paddingTopBottom}px`,
      paddingBottom: `${DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_paddingTopBottom}px`,
      letterSpacing: `${DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_letterSpacing}px`,
      textShadow: `${DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_ShadowHorizontal}px
        ${DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_ShadowVertical}px
        ${DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_ShadowBlur}px
        ${DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_ShadowColor}`,
      textDecoration: `${DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_TextDecoration}
        ${DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_TextDecorationStyle}`,
      cursor: "pointer",
    },
    produktBeschreibung: {
      fontFamily: `${DB_fontFace_chooseFontBeschreibungFontFamily_FontFamilyName}`,
      fontWeight: `${DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_FontWeight}`,
      color: `${DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_FontColor}`,
      fontSize: `${DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_FontSize}px`,
      backgroundColor: `${
        SideBarLeft_DetailItem_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut
          ? "rgba(255,0,0,0.5)"
          : DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BackgroundColor
      }`,
      textAlign: `${DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_TextAlign}`,
      borderTop: `${
        SideBarLeft_DetailItem_Farbe_Groesse_Stil_OnMouseOverAndOut
          ? "3"
          : DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderTop
      }px
        ${
          SideBarLeft_DetailItem_Farbe_Groesse_Stil_OnMouseOverAndOut
            ? "solid"
            : DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderStyleTop
        }
        ${
          SideBarLeft_DetailItem_Farbe_Groesse_Stil_OnMouseOverAndOut
            ? "red"
            : DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderColorTop
        }`,
      borderBottom: `${
        SideBarLeft_DetailItem_Farbe_Groesse_Stil_OnMouseOverAndOut
          ? "3"
          : DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderBottom
      }px
        ${
          SideBarLeft_DetailItem_Farbe_Groesse_Stil_OnMouseOverAndOut
            ? "solid"
            : DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderStyleBottom
        }
        ${
          SideBarLeft_DetailItem_Farbe_Groesse_Stil_OnMouseOverAndOut
            ? "red"
            : DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderColorBottom
        }`,
      borderLeft: `${
        SideBarLeft_DetailItem_Farbe_Groesse_Stil_OnMouseOverAndOut
          ? "3"
          : DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderLeft
      }px
        ${
          SideBarLeft_DetailItem_Farbe_Groesse_Stil_OnMouseOverAndOut
            ? "solid"
            : DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderStyleLeft
        }
        ${
          SideBarLeft_DetailItem_Farbe_Groesse_Stil_OnMouseOverAndOut
            ? "red"
            : DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderColorLeft
        }`,
      borderRight: `${
        SideBarLeft_DetailItem_Farbe_Groesse_Stil_OnMouseOverAndOut
          ? "3"
          : DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderRight
      }px
        ${
          SideBarLeft_DetailItem_Farbe_Groesse_Stil_OnMouseOverAndOut
            ? "solid"
            : DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderStyleRight
        }
        ${
          SideBarLeft_DetailItem_Farbe_Groesse_Stil_OnMouseOverAndOut
            ? "red"
            : DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderColorRight
        }`,
      borderTopRightRadius: `${DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderTopRightRadius}px`,
      borderTopLeftRadius: `${DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderTopLeftRadius}px`,
      borderBottomRightRadius: `${DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderBottomRightRadius}px`,
      borderBottomLeftRadius: `${DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderBottomLeftRadius}px`,
      marginLeft: `${DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_marginLeftRight}px`,
      marginRight: `${DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_marginLeftRight}px`,
      marginTop: `${DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_marginTopBottom}px`,
      marginBottom: "20px",
      paddingLeft: `${DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_paddingLeftRight}px`,
      paddingRight: `${DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_paddingLeftRight}px`,
      paddingTop: `${DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_paddingTopBottom}px`,
      paddingBottom: `${DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_paddingTopBottom}px`,
      letterSpacing: `${DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_letterSpacing}px`,
      textShadow: `${DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_ShadowHorizontal}px
        ${DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_ShadowVertical}px
        ${DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_ShadowBlur}px
        ${DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_ShadowColor}`,
      textDecoration: `${DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_TextDecoration}
        ${DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_TextDecorationStyle}`,
    },
  });

  const classes = useStyles();
  return (
    <div className={"container"}>
      <div className="row">
        <div className={"col-lg-6 mt-3 col-12"}>
          <div
            className="border-0 m-0 p-0"
            style={{
              borderRadius: `${DB_ProductDetails_BorderRadius}px`,
              padding: "20px",
              boxShadow:
                "rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px",
            }}
          >
            {/*   <div onClick={changeProductDetailsHandler}> */}
            <ImageGallery
              items={images}
              /* ref={(i) => (this._imageGallery = i)} */
              /* onClick={hideProductDetailsHandler} */
              /* onImageLoad={this._onImageLoad} */
              /* onSlide={this._onSlide.bind(this)}
              onPause={this._onPause.bind(this)}
              onScreenChange={this._onScreenChange.bind(this)} */
              /* onPlay={this._onPlay.bind(this)} */
              infinite={infinite}
              showBullets={showBullets}
              showFullscreenButton={
                showFullscreenButton && showGalleryFullscreenButton
              }
              showPlayButton={showPlayButton && showGalleryPlayButton}
              showThumbnails={showThumbnails}
              /* showIndex={showIndex} */
              showNav={showNav}
              isRTL={isRTL}
              thumbnailPosition={thumbnailPosition}
              slideDuration={parseInt(slideDuration)}
              slideInterval={parseInt(slideInterval)}
              slideOnThumbnailOver={slideOnThumbnailOver}
              useWindowKeyDown={useWindowKeyDown}
            />
            {/*  </div> */}
          </div>
        </div>
        <div className={"col-lg-6 mt-3 col-12"}>
          <div className={classes.productDetailItemHeaderTitel}>Impressum</div>
          <div className={classes.produktBeschreibung}>
            {aboutUsDetailItem.details.firma && (
              <div>
                <strong className="me-2">Firma:</strong>{" "}
                {aboutUsDetailItem.details.firma}
              </div>
            )}

            {aboutUsDetailItem.details.vorname &&
            aboutUsDetailItem.details.nachname ? (
              <div>
                <strong className="me-2">Name:</strong>{" "}
                {aboutUsDetailItem.details.vorname}{" "}
                {aboutUsDetailItem.details.nachname}
              </div>
            ) : (
              <div>
                <strong className="me-2">Name:</strong>{" "}
                {aboutUsDetailItem.privateName}
              </div>
            )}
            {aboutUsDetailItem.details.email ? (
              <div>
                <strong className="me-2">E-Mail:</strong>{" "}
                <a href={`mailto:${aboutUsDetailItem.details.email}`}>
                  {aboutUsDetailItem.details.email}
                </a>
              </div>
            ) : (
              <div>
                <strong className="me-2">E-Mail:</strong>{" "}
                <a href={`mailto:${aboutUsDetailItem.details.email}`}>
                  {aboutUsDetailItem.privateEmail}
                </a>
              </div>
            )}

            {aboutUsDetailItem.details.adresse ? (
              <div>
                <strong className="me-2">Adresse:</strong>{" "}
                {aboutUsDetailItem.details.adresse}
              </div>
            ) : (
              <div>
                <strong className="me-2">Adresse:</strong>{" "}
                {aboutUsDetailItem.privateAdress}
              </div>
            )}

            {aboutUsDetailItem.details.plz && aboutUsDetailItem.details.ort ? (
              <div>
                <strong className="me-2">PLZ / Ort:</strong>{" "}
                {aboutUsDetailItem.details.plz} {aboutUsDetailItem.details.ort}
              </div>
            ) : (
              <div>
                <strong className="me-2">PLZ / Ort:</strong>{" "}
                {aboutUsDetailItem.privatePLZ} {aboutUsDetailItem.privateOrt}
              </div>
            )}
            {aboutUsDetailItem.details.land ? (
              <div>
                <strong className="me-2">Land:</strong>{" "}
                {aboutUsDetailItem.details.land}
              </div>
            ) : (
              <div>
                <strong className="me-2">Land:</strong>{" "}
                {aboutUsDetailItem.privateLand}
              </div>
            )}
          </div>

          {(aboutUsDetailItem.details.lieferungLaender ||
            aboutUsDetailItem.details.sprachen) && (
            <>
              <div className={classes.productDetailItemHeaderTitel}>
                Lieferung / Sprachen
              </div>
              <div className={classes.produktBeschreibung}>
                {aboutUsDetailItem.details.lieferungLaender && (
                  <div>
                    <strong className="me-2">Lieferung nach:</strong>{" "}
                    {aboutUsDetailItem.details.lieferungLaender}
                  </div>
                )}
                <br />

                {aboutUsDetailItem.details.sprachen && (
                  <div>
                    <strong className="me-2">Sprachen:</strong>{" "}
                    {aboutUsDetailItem.details.sprachen}
                  </div>
                )}
              </div>
            </>
          )}
        </div>
      </div>

      <div className="row">
        <div className={"col-lg-12 mt-3 col-12"}>
          {aboutUsDetailItem.details.beschreibung && (
            <>
              <div className={classes.productDetailItemHeaderTitel}>
                Shop Beschreibung
              </div>
              <div className={classes.produktBeschreibung}>
                {aboutUsDetailItem.details.beschreibung && (
                  <div>
                    <strong className="me-2">Beschreibung:</strong>{" "}
                    {aboutUsDetailItem.details.beschreibung}
                  </div>
                )}
              </div>
            </>
          )}
        </div>
        <div className={"col-lg-12 mt-3 col-12"}>
          {" "}
          {(aboutUsDetailItem.details.facebook ||
            aboutUsDetailItem.details.instagram ||
            aboutUsDetailItem.details.twitter ||
            aboutUsDetailItem.details.youtube) && (
            <>
              <div className={classes.productDetailItemHeaderTitel}>
                Social Media
              </div>
              <div className={classes.produktBeschreibung}>
                {aboutUsDetailItem.details.homepage && (
                  <div>
                    <strong className="me-2">Homepage:</strong>{" "}
                    {aboutUsDetailItem.details.homepage}
                  </div>
                )}
                {aboutUsDetailItem.details.facebook && (
                  <div>
                    <strong className="me-2">Facebook:</strong>{" "}
                    {aboutUsDetailItem.details.facebook}
                  </div>
                )}

                {aboutUsDetailItem.details.instagram && (
                  <div>
                    <strong className="me-2">Instagram:</strong>{" "}
                    {aboutUsDetailItem.details.instagram}
                  </div>
                )}

                {aboutUsDetailItem.details.twitter && (
                  <div>
                    <strong className="me-2">Twitter:</strong>{" "}
                    {aboutUsDetailItem.details.twitter}
                  </div>
                )}

                {aboutUsDetailItem.details.youtube && (
                  <div>
                    <strong className="me-2">youtube:</strong>{" "}
                    {aboutUsDetailItem.details.youtube}
                  </div>
                )}
              </div>
            </>
          )}
          {aboutUsDetailItem.privateJoinOn && (
            <>
              <div className={classes.productDetailItemHeaderTitel}>
                Weitere Infos
              </div>
              <div className={classes.produktBeschreibung}>
                {aboutUsDetailItem.details.bankverbindung && (
                  <div>
                    <strong className="me-2">Bankverbindung:</strong>{" "}
                    {aboutUsDetailItem.details.bankverbindung}
                  </div>
                )}

                {aboutUsDetailItem.privateJoinOn && (
                  <div>
                    <strong className="me-2">Mitglied seit:</strong>{" "}
                    {aboutUsDetailItem.privateJoinOn.split("T")[0]}
                  </div>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
