import { useState, useEffect } from "react";
import axios from "axios";
import { Container, Button, Form, Modal, Row, Col } from "react-bootstrap";
import { toast, Zoom } from "react-toastify";
import ImageGallery from "react-image-gallery";
import "/node_modules/react-image-gallery/styles/css/image-gallery.css";
import Login from "./Login";
import Register from "./Register";
import ForgetPassword from "./ForgetPassword";
import { isAuth, getCookie } from "../helpers/helpers";
import BodyProductMainTitle from "./BodyProductMainTitle";
import BodyProductDetailKontaktFormular from "./BodyProductDetailKontaktFormular";
import BodyProductDetailFormular from "./BodyProductDetailFormular";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import { useSearchParams } from "react-router-dom";

const BodyProductDetail = ({
  logoActive,
  updateDetailProduct,
  product,
  language,
  traceHandler,
  userOwnsThisSite,
  hideProductDetailsHandler,
  productDetailItem,
  images,
  shopName,
  setIsAuthState,
  DB_Waehrung_Global,
  showProductDetailsHandler,
  DB_fontFace_FontFamilyName,
  DB_fontFace_chooseFontFamilySuperTitel_FontFamilyName,
  DB_fontFace_chooseFontFamilySuperTitel_FontFamilySrc,
  DB_fontFace_chooseFontHaupttitelFontFamily_FontFamilyName,
  DB_fontFace_chooseFontHaupttitelFontFamily_FontFamilySrc,
  DB_fontFace_chooseFontBeschreibungFontFamily_FontFamilyName,
  DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_FontFamilyName,
  DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_FontFamilySrc,
  DB_fontFace_chooseFontFormLabelsFontFamily_FontFamilyName,
  DB_fontFace_chooseFontKontaktFormularFontFamily_FontFamilyName,
  SideBarLeft_ContainerDetail_Farbe_Groesse_Stil_OnMouseOverAndOut,
  SideBarLeft_ContainerDetail_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut,
  SideBarLeft_AlleDetail_Farbe_Groesse_Stil_OnMouseOverAndOut,
  SideBarLeft_AlleDetail_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut,
  SideBarLeft_DetailItem_Farbe_Groesse_Stil_OnMouseOverAndOut,
  SideBarLeft_DetailItem_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut,
  SideBarLeft_DetailSuper_Farbe_Groesse_Stil_OnMouseOverAndOut,
  SideBarLeft_DetailSuper_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut,
  SideBarLeft_TitelKontaktFormularTitel_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut,
  SideBarLeft_TitelKontaktFormularTitel_Farbe_Groesse_Stil_OnMouseOverAndOut,
  SideBarLeft_FormInputDetail_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut,
  SideBarLeft_FormInputDetail_Farbe_Groesse_Stil_OnMouseOverAndOut,

  DB_LeftSidebar_MenuItem_TitelSuperTitel_FontWeight,
  DB_LeftSidebar_MenuItem_TitelSuperTitel_FontColor,
  DB_LeftSidebar_MenuItem_TitelSuperTitel_FontSize,
  DB_LeftSidebar_MenuItem_TitelSuperTitel_BackgroundColor,
  DB_LeftSidebar_MenuItem_TitelSuperTitel_TextAlign,
  DB_LeftSidebar_MenuItem_TitelSuperTitel_BorderTop,
  DB_LeftSidebar_MenuItem_TitelSuperTitel_BorderStyleTop,
  DB_LeftSidebar_MenuItem_TitelSuperTitel_BorderColorTop,
  DB_LeftSidebar_MenuItem_TitelSuperTitel_BorderBottom,
  DB_LeftSidebar_MenuItem_TitelSuperTitel_BorderStyleBottom,
  DB_LeftSidebar_MenuItem_TitelSuperTitel_BorderColorBottom,
  DB_LeftSidebar_MenuItem_TitelSuperTitel_BorderLeft,
  DB_LeftSidebar_MenuItem_TitelSuperTitel_BorderStyleLeft,
  DB_LeftSidebar_MenuItem_TitelSuperTitel_BorderColorLeft,
  DB_LeftSidebar_MenuItem_TitelSuperTitel_BorderRight,
  DB_LeftSidebar_MenuItem_TitelSuperTitel_BorderStyleRight,
  DB_LeftSidebar_MenuItem_TitelSuperTitel_BorderColorRight,
  DB_LeftSidebar_MenuItem_TitelSuperTitel_BorderTopRightRadius,
  DB_LeftSidebar_MenuItem_TitelSuperTitel_BorderTopLeftRadius,
  DB_LeftSidebar_MenuItem_TitelSuperTitel_BorderBottomRightRadius,
  DB_LeftSidebar_MenuItem_TitelSuperTitel_BorderBottomLeftRadius,
  DB_LeftSidebar_MenuItem_TitelSuperTitel_marginLeftRight,
  DB_LeftSidebar_MenuItem_TitelSuperTitel_marginTopBottom,
  DB_LeftSidebar_MenuItem_TitelSuperTitel_paddingLeftRight,
  DB_LeftSidebar_MenuItem_TitelSuperTitel_paddingTopBottom,
  DB_LeftSidebar_MenuItem_TitelSuperTitel_letterSpacing,
  DB_LeftSidebar_MenuItem_TitelSuperTitel_ShadowHorizontal,
  DB_LeftSidebar_MenuItem_TitelSuperTitel_ShadowVertical,
  DB_LeftSidebar_MenuItem_TitelSuperTitel_ShadowBlur,
  DB_LeftSidebar_MenuItem_TitelSuperTitel_ShadowColor,
  DB_LeftSidebar_MenuItem_TitelSuperTitel_TextDecoration,
  DB_LeftSidebar_MenuItem_TitelSuperTitel_TextDecorationStyle,

  DB_LeftSidebar_MenuItem_TitelFormInputDetail_FontWeight,
  DB_LeftSidebar_MenuItem_TitelFormInputDetail_FontColor,
  DB_LeftSidebar_MenuItem_TitelFormInputDetail_FontSize,
  DB_LeftSidebar_MenuItem_TitelFormInputDetail_BackgroundColor,
  DB_LeftSidebar_MenuItem_TitelFormInputDetail_TextAlign,
  DB_LeftSidebar_MenuItem_TitelFormInputDetail_BorderTop,
  DB_LeftSidebar_MenuItem_TitelFormInputDetail_BorderStyleTop,
  DB_LeftSidebar_MenuItem_TitelFormInputDetail_BorderColorTop,
  DB_LeftSidebar_MenuItem_TitelFormInputDetail_BorderBottom,
  DB_LeftSidebar_MenuItem_TitelFormInputDetail_BorderStyleBottom,
  DB_LeftSidebar_MenuItem_TitelFormInputDetail_BorderColorBottom,
  DB_LeftSidebar_MenuItem_TitelFormInputDetail_BorderLeft,
  DB_LeftSidebar_MenuItem_TitelFormInputDetail_BorderStyleLeft,
  DB_LeftSidebar_MenuItem_TitelFormInputDetail_BorderColorLeft,
  DB_LeftSidebar_MenuItem_TitelFormInputDetail_BorderRight,
  DB_LeftSidebar_MenuItem_TitelFormInputDetail_BorderStyleRight,
  DB_LeftSidebar_MenuItem_TitelFormInputDetail_BorderColorRight,
  DB_LeftSidebar_MenuItem_TitelFormInputDetail_BorderTopRightRadius,
  DB_LeftSidebar_MenuItem_TitelFormInputDetail_BorderTopLeftRadius,
  DB_LeftSidebar_MenuItem_TitelFormInputDetail_BorderBottomRightRadius,
  DB_LeftSidebar_MenuItem_TitelFormInputDetail_BorderBottomLeftRadius,
  DB_LeftSidebar_MenuItem_TitelFormInputDetail_marginLeftRight,
  DB_LeftSidebar_MenuItem_TitelFormInputDetail_marginTopBottom,
  DB_LeftSidebar_MenuItem_TitelFormInputDetail_paddingLeftRight,
  DB_LeftSidebar_MenuItem_TitelFormInputDetail_paddingTopBottom,
  DB_LeftSidebar_MenuItem_TitelFormInputDetail_letterSpacing,
  DB_LeftSidebar_MenuItem_TitelFormInputDetail_ShadowHorizontal,
  DB_LeftSidebar_MenuItem_TitelFormInputDetail_ShadowVertical,
  DB_LeftSidebar_MenuItem_TitelFormInputDetail_ShadowBlur,
  DB_LeftSidebar_MenuItem_TitelFormInputDetail_ShadowColor,
  DB_LeftSidebar_MenuItem_TitelFormInputDetail_TextDecoration,
  DB_LeftSidebar_MenuItem_TitelFormInputDetail_TextDecorationStyle,
  DB_LeftSidebar_MenuItem_TitelFormInputDetail_FontColorNotEncoded,

  DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_FontWeight,
  DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_FontColor,
  DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_FontSize,
  DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_BackgroundColor,
  DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_TextAlign,
  DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_BorderTop,
  DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_BorderStyleTop,
  DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_BorderColorTop,
  DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_BorderBottom,
  DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_BorderStyleBottom,
  DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_BorderColorBottom,
  DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_BorderLeft,
  DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_BorderStyleLeft,
  DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_BorderColorLeft,
  DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_BorderRight,
  DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_BorderStyleRight,
  DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_BorderColorRight,
  DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_BorderTopRightRadius,
  DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_BorderTopLeftRadius,
  DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_BorderBottomRightRadius,
  DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_BorderBottomLeftRadius,
  DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_marginLeftRight,
  DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_marginTopBottom,
  DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_paddingLeftRight,
  DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_paddingTopBottom,
  DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_letterSpacing,
  DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_ShadowHorizontal,
  DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_ShadowVertical,
  DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_ShadowBlur,
  DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_ShadowColor,
  DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_TextDecoration,
  DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_TextDecorationStyle,
  DB_LeftSidebar_MenuItem_TitelKontaktFormular_FontWeight,
  DB_LeftSidebar_MenuItem_TitelKontaktFormular_FontColor,
  DB_LeftSidebar_MenuItem_TitelKontaktFormular_FontSize,
  DB_LeftSidebar_MenuItem_TitelKontaktFormular_BackgroundColor,
  DB_LeftSidebar_MenuItem_TitelKontaktFormular_TextAlign,
  DB_LeftSidebar_MenuItem_TitelKontaktFormular_BorderTop,
  DB_LeftSidebar_MenuItem_TitelKontaktFormular_BorderStyleTop,
  DB_LeftSidebar_MenuItem_TitelKontaktFormular_BorderColorTop,
  DB_LeftSidebar_MenuItem_TitelKontaktFormular_BorderBottom,
  DB_LeftSidebar_MenuItem_TitelKontaktFormular_BorderStyleBottom,
  DB_LeftSidebar_MenuItem_TitelKontaktFormular_BorderColorBottom,
  DB_LeftSidebar_MenuItem_TitelKontaktFormular_BorderLeft,
  DB_LeftSidebar_MenuItem_TitelKontaktFormular_BorderStyleLeft,
  DB_LeftSidebar_MenuItem_TitelKontaktFormular_BorderColorLeft,
  DB_LeftSidebar_MenuItem_TitelKontaktFormular_BorderRight,
  DB_LeftSidebar_MenuItem_TitelKontaktFormular_BorderStyleRight,
  DB_LeftSidebar_MenuItem_TitelKontaktFormular_BorderColorRight,
  DB_LeftSidebar_MenuItem_TitelKontaktFormular_BorderTopRightRadius,
  DB_LeftSidebar_MenuItem_TitelKontaktFormular_BorderTopLeftRadius,
  DB_LeftSidebar_MenuItem_TitelKontaktFormular_BorderBottomRightRadius,
  DB_LeftSidebar_MenuItem_TitelKontaktFormular_BorderBottomLeftRadius,
  DB_LeftSidebar_MenuItem_TitelKontaktFormular_marginLeftRight,
  DB_LeftSidebar_MenuItem_TitelKontaktFormular_marginTopBottom,
  DB_LeftSidebar_MenuItem_TitelKontaktFormular_paddingLeftRight,
  DB_LeftSidebar_MenuItem_TitelKontaktFormular_paddingTopBottom,
  DB_LeftSidebar_MenuItem_TitelKontaktFormular_letterSpacing,
  DB_LeftSidebar_MenuItem_TitelKontaktFormular_ShadowHorizontal,
  DB_LeftSidebar_MenuItem_TitelKontaktFormular_ShadowVertical,
  DB_LeftSidebar_MenuItem_TitelKontaktFormular_ShadowBlur,
  DB_LeftSidebar_MenuItem_TitelKontaktFormular_ShadowColor,
  DB_LeftSidebar_MenuItem_TitelKontaktFormular_TextDecoration,
  DB_LeftSidebar_MenuItem_TitelKontaktFormular_TextDecorationStyle,

  DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_FontWeight,
  DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_FontColor,
  DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_FontSize,
  DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BackgroundColor,
  DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_TextAlign,
  DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderTop,
  DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderStyleTop,
  DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderColorTop,
  DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderBottom,
  DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderStyleBottom,
  DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderColorBottom,
  DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderLeft,
  DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderStyleLeft,
  DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderColorLeft,
  DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderRight,
  DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderStyleRight,
  DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderColorRight,
  DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderTopRightRadius,
  DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderTopLeftRadius,
  DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderBottomRightRadius,
  DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderBottomLeftRadius,
  DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_marginLeftRight,
  DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_marginTopBottom,
  DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_paddingLeftRight,
  DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_paddingTopBottom,
  DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_letterSpacing,
  DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_ShadowHorizontal,
  DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_ShadowVertical,
  DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_ShadowBlur,
  DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_ShadowColor,
  DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_TextDecoration,
  DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_TextDecorationStyle,

  DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_FontWeight,
  DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_FontColor,
  DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_FontSize,
  DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BackgroundColor,
  DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_TextAlign,
  DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderTop,
  DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderStyleTop,
  DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderColorTop,
  DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderBottom,
  DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderStyleBottom,
  DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderColorBottom,
  DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderLeft,
  DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderStyleLeft,
  DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderColorLeft,
  DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderRight,
  DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderStyleRight,
  DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderColorRight,
  DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderTopRightRadius,
  DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderTopLeftRadius,
  DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderBottomRightRadius,
  DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderBottomLeftRadius,
  DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_marginLeftRight,
  DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_marginTopBottom,
  DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_paddingLeftRight,
  DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_paddingTopBottom,
  DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_letterSpacing,
  DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_ShadowHorizontal,
  DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_ShadowVertical,
  DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_ShadowBlur,
  DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_ShadowColor,
  DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_TextDecoration,
  DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_TextDecorationStyle,
}) => {
  const [showBullets] = useState(true);
  const [infinite] = useState(true);
  const [showThumbnails] = useState(true);
  const [showFullscreenButton] = useState(true);
  const [showGalleryFullscreenButton] = useState(true);
  const [showPlayButton] = useState(true);
  const [showGalleryPlayButton] = useState(true);
  const [showNav] = useState(true);
  const [isRTL] = useState(false);
  const [slideDuration] = useState(800);
  const [slideInterval] = useState(3000);
  const [slideOnThumbnailOver] = useState(false);
  const [thumbnailPosition] = useState("bottom");
  const [useWindowKeyDown] = useState(true);

  const [stateNachricht, setStateNachricht] = useState("");

  const [signInModal, setSignInModal] = useState(false);
  const [doLoginOn, setDoLoginOn] = useState(true);
  const [doRegisterOn, setDoRegisterOn] = useState(false);
  const [doForgotPassword, setDoForgotPassword] = useState(false);

  const [openLinkModal, setOpenLinkModal] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const { t } = useTranslation();

  /*   useEffect(() => {
    setSearchParams({ s: productDetailItem.titel });
  }, [updateDetailProduct]); */

  const token = getCookie("token");

  const anmeldungErfolgreich = () => {
    setSignInModal(!signInModal);
    setIsAuthState(true);
  };

  const registrierenAktiv = () => {
    setDoRegisterOn(true);
    setDoForgotPassword(false);
    setDoLoginOn(false);
  };

  const anmeldenAktiv = () => {
    setDoLoginOn(true);
    setDoForgotPassword(false);
    setDoRegisterOn(false);
  };

  const passwortVergessenAktiv = () => {
    setDoForgotPassword(true);
    setDoLoginOn(false);
    setDoRegisterOn(false);
  };

  const toggleSignIn = () => {
    setSignInModal(!signInModal);
  };

  const toggleOpenLink = () => {
    setOpenLinkModal(!openLinkModal);
  };

  const contactFormHandlerNachricht = (event) => {
    setStateNachricht(event.target.value);
  };

  const anfrageAnVerkaeuferSendenHandler = () => {
    let orderDetailsForSendingSeller = {
      productDetailOfOneItem: productDetailItem.productIdentifier,
      stateNachricht,
    };

    if (!isAuth()) {
      toggleSignIn();
    } else {
      if (stateNachricht === "") {
        toast.error(`${t("AlleFelderAusfuellen")}`, {
          toastId: "custom-id-yes",
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          transition: Zoom,
        });
      } else {
        axios({
          method: "post",
          url: `${process.env.REACT_APP_PUBLIC_SERVER_URL}/shop/send-product-order-to-seller`,
          data: {
            shopName: shopName,
            orderDetailsForSendingSeller,
            currency: DB_Waehrung,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
          .then((response) => {
            if (response.status === 200) {
              toast.success(`${t("BestellungErfolgreich")}`, {
                toastId: "custom-id-yes",
                position: "bottom-center",
                autoClose: 10000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
                transition: Zoom,
              });
              setStateNachricht("");
            } else if (response.status === 201) {
              toast.info(`${t("MaximumThreeOrders")}`, {
                toastId: "custom-id-yes",
                position: "bottom-center",
                autoClose: 10000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
                transition: Zoom,
              });
            } else {
              toast.error(`${t("BestellungFehlgeschlagen")}`, {
                toastId: "custom-id-yes",
                position: "top-center",
                autoClose: 10000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
                transition: Zoom,
              });
            }
          })
          .catch((error) => {
            console.log("error", error);
          });
      }
    }
  };

  const DB_Waehrung = DB_Waehrung_Global;
  const DB_ProductDetails_Preis_FontSize = 25;
  const DB_ProductDetails_Waehrung_FontSize = 15;
  const DB_ProductDetails_Header_Untertitel_FontSize = 16;
  const DB_ProductDetails_Header_Untertitel_FontColor = "rgba(255,255,255,1)";
  const DB_ProductDetails_Header_Untertitel_BackgroundColor = "rgba(0,0,0,0.3)";
  const DB_ProductDetails_BorderRadius = 15;
  const DB_ProductDetails_Border_Width = 1;
  const DB_ProductDetails_Border_Style = "solid";
  const DB_ProductDetails_Border_Color = "rgba(0,0,0,0.3)";
  const DB_ProductDetails_Produkbeschreibung_Header_BackgroundColor =
    "rgba(0,0,0,0.7)";
  const DB_ProductDetails_Produkbeschreibung_Header_FontColor =
    "rgba(255,255,255,1)";
  const DB_ProductDetails_Produkbeschreibung_Body_BackgroundColor =
    "rgba(0,0,0,0.3)";
  const DB_ProductDetails_Produkbeschreibung_Body_FontColor =
    "rgba(255,255,255,1)";
  const DB_ProductDetails_Produkbeschreibung_ContactForm_Button_BackgroundColor =
    "rgba(0,0,0,0.7)";
  const DB_ProductDetails_Produkbeschreibung_ContactForm_Button_FontColor =
    "rgba(255,255,255,1)";
  const DB_ProductDetails_Produkbeschreibung_Body_Border_Width = 1;
  const DB_ProductDetails_Produkbeschreibung_Body_Border_Color =
    "rgba(0,0,0,1)";
  const DB_ProductDetails_Produkbeschreibung_Body_Border_Style = "solid";
  const DB_ProductDetails_Produkbeschreibung_Header_Border_Width = 1;
  const DB_ProductDetails_Produkbeschreibung_Header_Border_Color =
    "rgba(0,0,0,1)";
  const DB_ProductDetails_Produkbeschreibung_Header_Border_Style = "solid";

  const useStyles = createUseStyles({
    productDetailItemHeaderTitel: {
      fontFamily: `${DB_fontFace_chooseFontHaupttitelFontFamily_FontFamilyName}`,
      fontWeight: `${DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_FontWeight}`,
      color: `${DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_FontColor}`,
      fontSize: `${DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_FontSize}px`,
      backgroundColor: `${
        SideBarLeft_ContainerDetail_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut
          ? "rgba(255,0,0,0.5)"
          : DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BackgroundColor
      }`,
      textAlign: `${DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_TextAlign}`,
      borderTop: `${
        SideBarLeft_ContainerDetail_Farbe_Groesse_Stil_OnMouseOverAndOut
          ? "3"
          : DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderTop
      }px
        ${
          SideBarLeft_ContainerDetail_Farbe_Groesse_Stil_OnMouseOverAndOut
            ? "solid"
            : DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderStyleTop
        }
        ${
          SideBarLeft_ContainerDetail_Farbe_Groesse_Stil_OnMouseOverAndOut
            ? "red"
            : DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderColorTop
        }`,
      borderBottom: `${
        SideBarLeft_ContainerDetail_Farbe_Groesse_Stil_OnMouseOverAndOut
          ? "3"
          : DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderBottom
      }px
        ${
          SideBarLeft_ContainerDetail_Farbe_Groesse_Stil_OnMouseOverAndOut
            ? "solid"
            : DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderStyleBottom
        }
        ${
          SideBarLeft_ContainerDetail_Farbe_Groesse_Stil_OnMouseOverAndOut
            ? "red"
            : DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderColorBottom
        }`,
      borderLeft: `${
        SideBarLeft_ContainerDetail_Farbe_Groesse_Stil_OnMouseOverAndOut
          ? "3"
          : DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderLeft
      }px
        ${
          SideBarLeft_ContainerDetail_Farbe_Groesse_Stil_OnMouseOverAndOut
            ? "solid"
            : DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderStyleLeft
        }
        ${
          SideBarLeft_ContainerDetail_Farbe_Groesse_Stil_OnMouseOverAndOut
            ? "red"
            : DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderColorLeft
        }`,
      borderRight: `${
        SideBarLeft_ContainerDetail_Farbe_Groesse_Stil_OnMouseOverAndOut
          ? "3"
          : DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderRight
      }px
        ${
          SideBarLeft_ContainerDetail_Farbe_Groesse_Stil_OnMouseOverAndOut
            ? "solid"
            : DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderStyleRight
        }
        ${
          SideBarLeft_ContainerDetail_Farbe_Groesse_Stil_OnMouseOverAndOut
            ? "red"
            : DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderColorRight
        }`,
      borderTopRightRadius: `${DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderTopRightRadius}px`,
      borderTopLeftRadius: `${DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderTopLeftRadius}px`,
      borderBottomRightRadius: `${DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderBottomRightRadius}px`,
      borderBottomLeftRadius: `${DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderBottomLeftRadius}px`,
      marginLeft: `${DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_marginLeftRight}px`,
      marginRight: `${DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_marginLeftRight}px`,
      marginTop: `${DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_marginTopBottom}px`,
      marginBottom: `${DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_marginTopBottom}px`,
      paddingLeft: `${DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_paddingLeftRight}px`,
      paddingRight: `${DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_paddingLeftRight}px`,
      paddingTop: `${DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_paddingTopBottom}px`,
      paddingBottom: `${DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_paddingTopBottom}px`,
      letterSpacing: `${DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_letterSpacing}px`,
      textShadow: `${DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_ShadowHorizontal}px
        ${DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_ShadowVertical}px
        ${DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_ShadowBlur}px
        ${DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_ShadowColor}`,
      textDecoration: `${DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_TextDecoration}
        ${DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_TextDecorationStyle}`,
      cursor: "pointer",
    },

    productDetailItemHeaderUntertitel: {
      fontFamily: `${DB_fontFace_FontFamilyName}`,
      fontSize: `${DB_ProductDetails_Header_Untertitel_FontSize}px`,
      color: `${DB_ProductDetails_Header_Untertitel_FontColor}`,
      borderRight: `${DB_ProductDetails_Border_Width}px
        ${DB_ProductDetails_Border_Style} ${DB_ProductDetails_Border_Color}`,
      borderLeft: `${DB_ProductDetails_Border_Style} ${DB_ProductDetails_Border_Color}`,
      backgroundColor: `${DB_ProductDetails_Header_Untertitel_BackgroundColor}`,
      paddingLeft: "16px",
      paddingRight: "16px",
      paddingBottom: "5px",
      paddingTop: "5px",
      cursor: "pointer",
    },

    produktBeschreibungHeader: {
      fontFamily: `${DB_fontFace_FontFamilyName}`,
      border: `${DB_ProductDetails_Produkbeschreibung_Header_Border_Width}px
        ${DB_ProductDetails_Produkbeschreibung_Header_Border_Style}
        ${DB_ProductDetails_Produkbeschreibung_Header_Border_Color}`,
      backgroundColor: `${DB_ProductDetails_Produkbeschreibung_Header_BackgroundColor}`,
      borderTopLeftRadius: `${DB_ProductDetails_BorderRadius}px`,
      borderTopRightRadius: `${DB_ProductDetails_BorderRadius}px`,
      padding: "10px",
      paddingTop: "7px",
      paddingBottom: "7px",
      color: `${DB_ProductDetails_Produkbeschreibung_Header_FontColor}`,
      fontWeight: "bold",
    },

    produktBeschreibung: {
      fontFamily: `${DB_fontFace_chooseFontBeschreibungFontFamily_FontFamilyName}`,
      fontWeight: `${DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_FontWeight} `,
      color: `${DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_FontColor} `,
      fontSize: `${DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_FontSize}px `,
      backgroundColor: `${
        SideBarLeft_DetailItem_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut
          ? "rgba(255,0,0,0.5)"
          : DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BackgroundColor
      } `,
      textAlign: `${DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_TextAlign} `,
      borderTop: `${
        SideBarLeft_DetailItem_Farbe_Groesse_Stil_OnMouseOverAndOut
          ? "3"
          : DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderTop
      }px
        ${
          SideBarLeft_DetailItem_Farbe_Groesse_Stil_OnMouseOverAndOut
            ? "solid"
            : DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderStyleTop
        }
        ${
          SideBarLeft_DetailItem_Farbe_Groesse_Stil_OnMouseOverAndOut
            ? "red"
            : DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderColorTop
        } `,
      borderBottom: `${
        SideBarLeft_DetailItem_Farbe_Groesse_Stil_OnMouseOverAndOut
          ? "3"
          : DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderBottom
      }px
        ${
          SideBarLeft_DetailItem_Farbe_Groesse_Stil_OnMouseOverAndOut
            ? "solid"
            : DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderStyleBottom
        }
        ${
          SideBarLeft_DetailItem_Farbe_Groesse_Stil_OnMouseOverAndOut
            ? "red"
            : DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderColorBottom
        } `,
      borderLeft: `${
        SideBarLeft_DetailItem_Farbe_Groesse_Stil_OnMouseOverAndOut
          ? "3"
          : DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderLeft
      }px
        ${
          SideBarLeft_DetailItem_Farbe_Groesse_Stil_OnMouseOverAndOut
            ? "solid"
            : DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderStyleLeft
        }
        ${
          SideBarLeft_DetailItem_Farbe_Groesse_Stil_OnMouseOverAndOut
            ? "red"
            : DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderColorLeft
        } `,
      borderRight: `${
        SideBarLeft_DetailItem_Farbe_Groesse_Stil_OnMouseOverAndOut
          ? "3"
          : DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderRight
      }px
        ${
          SideBarLeft_DetailItem_Farbe_Groesse_Stil_OnMouseOverAndOut
            ? "solid"
            : DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderStyleRight
        }
        ${
          SideBarLeft_DetailItem_Farbe_Groesse_Stil_OnMouseOverAndOut
            ? "red"
            : DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderColorRight
        } `,
      borderTopRightRadius: `${DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderTopRightRadius}px`,
      borderTopLeftRadius: `${DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderTopLeftRadius}px `,
      borderBottomRightRadius: `${DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderBottomRightRadius}px `,
      borderBottomLeftRadius: `${DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BorderBottomLeftRadius}px `,
      marginLeft: `${DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_marginLeftRight}px `,
      marginRight: `${DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_marginLeftRight}px `,
      marginTop: `${DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_marginTopBottom}px `,
      marginBottom: "20px",
      paddingLeft: `${DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_paddingLeftRight}px `,
      paddingRight: `${DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_paddingLeftRight}px `,
      paddingTop: `${DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_paddingTopBottom}px `,
      paddingBottom: `${DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_paddingTopBottom}px `,
      letterSpacing: `${DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_letterSpacing}px `,
      textShadow: `${DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_ShadowHorizontal}px
        ${DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_ShadowVertical}px
        ${DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_ShadowBlur}px
        ${DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_ShadowColor} `,
      textDecoration: `${DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_TextDecoration}
        ${DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_TextDecorationStyle} `,
    },

    KontaktFormularContainer: {
      borderTop: `${
        SideBarLeft_AlleDetail_Farbe_Groesse_Stil_OnMouseOverAndOut
          ? "3"
          : DB_LeftSidebar_MenuItem_TitelKontaktFormular_BorderTop
      }px
        ${
          SideBarLeft_AlleDetail_Farbe_Groesse_Stil_OnMouseOverAndOut
            ? "solid"
            : DB_LeftSidebar_MenuItem_TitelKontaktFormular_BorderStyleTop
        }
        ${
          SideBarLeft_AlleDetail_Farbe_Groesse_Stil_OnMouseOverAndOut
            ? "red"
            : DB_LeftSidebar_MenuItem_TitelKontaktFormular_BorderColorTop
        }`,
      borderBottom: `${
        SideBarLeft_AlleDetail_Farbe_Groesse_Stil_OnMouseOverAndOut
          ? "3"
          : DB_LeftSidebar_MenuItem_TitelKontaktFormular_BorderBottom
      }px
        ${
          SideBarLeft_AlleDetail_Farbe_Groesse_Stil_OnMouseOverAndOut
            ? "solid"
            : DB_LeftSidebar_MenuItem_TitelKontaktFormular_BorderStyleBottom
        }
        ${
          SideBarLeft_AlleDetail_Farbe_Groesse_Stil_OnMouseOverAndOut
            ? "red"
            : DB_LeftSidebar_MenuItem_TitelKontaktFormular_BorderColorBottom
        }`,
      borderLeft: `${
        SideBarLeft_AlleDetail_Farbe_Groesse_Stil_OnMouseOverAndOut
          ? "3"
          : DB_LeftSidebar_MenuItem_TitelKontaktFormular_BorderLeft
      }px
        ${
          SideBarLeft_AlleDetail_Farbe_Groesse_Stil_OnMouseOverAndOut
            ? "solid"
            : DB_LeftSidebar_MenuItem_TitelKontaktFormular_BorderStyleLeft
        }
        ${
          SideBarLeft_AlleDetail_Farbe_Groesse_Stil_OnMouseOverAndOut
            ? "red"
            : DB_LeftSidebar_MenuItem_TitelKontaktFormular_BorderColorLeft
        }`,
      borderRight: `${
        SideBarLeft_AlleDetail_Farbe_Groesse_Stil_OnMouseOverAndOut
          ? "3"
          : DB_LeftSidebar_MenuItem_TitelKontaktFormular_BorderRight
      }px
        ${
          SideBarLeft_AlleDetail_Farbe_Groesse_Stil_OnMouseOverAndOut
            ? "solid"
            : DB_LeftSidebar_MenuItem_TitelKontaktFormular_BorderStyleRight
        }
        ${
          SideBarLeft_AlleDetail_Farbe_Groesse_Stil_OnMouseOverAndOut
            ? "red"
            : DB_LeftSidebar_MenuItem_TitelKontaktFormular_BorderColorRight
        }`,
      borderTopRightRadius: `${DB_LeftSidebar_MenuItem_TitelKontaktFormular_BorderTopRightRadius}px`,
      borderTopLeftRadius: `${DB_LeftSidebar_MenuItem_TitelKontaktFormular_BorderTopLeftRadius}px`,
      borderBottomRightRadius: `${DB_LeftSidebar_MenuItem_TitelKontaktFormular_BorderBottomRightRadius}px`,
      borderBottomLeftRadius: `${DB_LeftSidebar_MenuItem_TitelKontaktFormular_BorderBottomLeftRadius}px`,
      marginLeft: `${DB_LeftSidebar_MenuItem_TitelKontaktFormular_marginLeftRight}px`,
      marginRight: `${DB_LeftSidebar_MenuItem_TitelKontaktFormular_marginLeftRight}px`,
      marginTop: `${DB_LeftSidebar_MenuItem_TitelKontaktFormular_marginTopBottom}px`,
      marginBottom: `${DB_LeftSidebar_MenuItem_TitelKontaktFormular_marginTopBottom}px`,
      paddingLeft: `${DB_LeftSidebar_MenuItem_TitelKontaktFormular_paddingLeftRight}px`,
      paddingRight: `${DB_LeftSidebar_MenuItem_TitelKontaktFormular_paddingLeftRight}px`,
      paddingTop: `${DB_LeftSidebar_MenuItem_TitelKontaktFormular_paddingTopBottom}px`,
      paddingBottom: `${DB_LeftSidebar_MenuItem_TitelKontaktFormular_paddingTopBottom}px`,

      backgroundColor: `${
        SideBarLeft_AlleDetail_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut
          ? "rgba(255,0,0,0.5)"
          : DB_LeftSidebar_MenuItem_TitelKontaktFormular_BackgroundColor
      }`,

      KontaktFormularContainerLabel: {
        fontFamily: `${DB_fontFace_chooseFontKontaktFormularFontFamily_FontFamilyName}`,
        fontWeight: `${DB_LeftSidebar_MenuItem_TitelKontaktFormular_FontWeight}`,
        color: `${DB_LeftSidebar_MenuItem_TitelKontaktFormular_FontColor}`,
        fontSize: `${DB_LeftSidebar_MenuItem_TitelKontaktFormular_FontSize}px`,
        textAlign: `${DB_LeftSidebar_MenuItem_TitelKontaktFormular_TextAlign}`,
        letterSpacing: `${DB_LeftSidebar_MenuItem_TitelKontaktFormular_letterSpacing}px`,
        textShadow: `${DB_LeftSidebar_MenuItem_TitelKontaktFormular_ShadowHorizontal}px
          ${DB_LeftSidebar_MenuItem_TitelKontaktFormular_ShadowVertical}px
          ${DB_LeftSidebar_MenuItem_TitelKontaktFormular_ShadowBlur}px
          ${DB_LeftSidebar_MenuItem_TitelKontaktFormular_ShadowColor}`,
        textDecoration: `${DB_LeftSidebar_MenuItem_TitelKontaktFormular_TextDecoration}
          ${DB_LeftSidebar_MenuItem_TitelKontaktFormular_TextDecorationStyle}`,
      },

      produktBeschreibungContactFormLeft: {
        backgroundColor: `${DB_ProductDetails_Produkbeschreibung_Body_BackgroundColor}`,
        color: `${DB_ProductDetails_Produkbeschreibung_Body_FontColor}`,
        borderLeft: `${DB_ProductDetails_Produkbeschreibung_Body_Border_Width}px
          ${DB_ProductDetails_Produkbeschreibung_Body_Border_Style}
          ${DB_ProductDetails_Produkbeschreibung_Body_Border_Color}`,
        fontFamily: `${DB_fontFace_FontFamilyName}`,
        borderLeft: "1px solid rgba(0, 0, 0, 0.7)",
        backgroundColor: "rgba(0, 0, 0, 0.3)",
        color: "white",
        padding: "13px",
        paddingBottom: "7px",
        marginTop: "0px",
        marginBottom: "0px",
      },

      produktBeschreibungContactFormRight: {
        backgroundColor: `${DB_ProductDetails_Produkbeschreibung_Body_BackgroundColor}`,
        color: `${DB_ProductDetails_Produkbeschreibung_Body_FontColor}`,
        borderRight: `${DB_ProductDetails_Produkbeschreibung_Body_Border_Width}px
          ${DB_ProductDetails_Produkbeschreibung_Body_Border_Style}
          ${DB_ProductDetails_Produkbeschreibung_Body_Border_Color}`,
        fontFamily: `${DB_fontFace_FontFamilyName}`,
        borderRight: "1px solid rgba(0, 0, 0, 0.7)",
        backgroundColor: "rgba(0, 0, 0, 0.3)",
        color: "white",
        padding: "13px",
        paddingBottom: "7px",
        marginTop: "0px",
        marginBottom: "0px",
      },

      produktBeschreibungContactFormNachricht: {
        backgroundColor: `${DB_ProductDetails_Produkbeschreibung_Body_BackgroundColor}`,
        color: `${DB_ProductDetails_Produkbeschreibung_Body_FontColor}`,
        borderLeft: `${DB_ProductDetails_Produkbeschreibung_Body_Border_Width}px
          ${DB_ProductDetails_Produkbeschreibung_Body_Border_Style}
          ${DB_ProductDetails_Produkbeschreibung_Body_Border_Color}`,
        borderRight: `${DB_ProductDetails_Produkbeschreibung_Body_Border_Width}px
          ${DB_ProductDetails_Produkbeschreibung_Body_Border_Style}
          ${DB_ProductDetails_Produkbeschreibung_Body_Border_Color}`,
        fontFamily: `${DB_fontFace_FontFamilyName}`,
        borderRight: "1px solid rgba(0, 0, 0, 0.7)",
        borderLeft: "1px solid rgba(0, 0, 0, 0.7)",
        borderBottom: "1px solid rgba(0, 0, 0, 0.7)",
        backgroundColor: "rgba(0, 0, 0, 0.3)",
        color: "white",
        padding: "13px",
        marginTop: "0px",
      },

      produktBeschreibungContactFormBottom: {
        backgroundColor: `${DB_ProductDetails_Produkbeschreibung_ContactForm_Button_BackgroundColor}`,
        color: `${DB_ProductDetails_Produkbeschreibung_ContactForm_Button_FontColor}`,
        borderLeft: `${DB_ProductDetails_Produkbeschreibung_Body_Border_Width}px
          ${DB_ProductDetails_Produkbeschreibung_Body_Border_Style}
          ${DB_ProductDetails_Produkbeschreibung_Body_Border_Color}`,
        borderRight: `${DB_ProductDetails_Produkbeschreibung_Body_Border_Width}px
          ${DB_ProductDetails_Produkbeschreibung_Body_Border_Style}
          ${DB_ProductDetails_Produkbeschreibung_Body_Border_Color}`,
        borderBottom: `${DB_ProductDetails_Produkbeschreibung_Body_Border_Width}px
          ${DB_ProductDetails_Produkbeschreibung_Body_Border_Style}
          ${DB_ProductDetails_Produkbeschreibung_Body_Border_Color}`,
        borderTop: `${DB_ProductDetails_Produkbeschreibung_Body_Border_Width}px
          ${DB_ProductDetails_Produkbeschreibung_Body_Border_Style}
          ${DB_ProductDetails_Produkbeschreibung_Body_Border_Color}`,
        fontFamily: `${DB_fontFace_FontFamilyName}`,
        borderRight: "1px solid rgba(0, 0, 0, 0.7)",
        borderBottom: "1px solid rgba(0, 0, 0, 0.7)",
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        borderBottomLeftRadius: `${DB_ProductDetails_BorderRadius}px`,
        borderBottomRightRadius: `${DB_ProductDetails_BorderRadius}px`,
        padding: "8px",
        marginTop: "0px",
        cursor: "pointer",

        "&:hover": {
          fontFamily: `${DB_fontFace_FontFamilyName}`,
          borderRight: "1px solid rgba(0, 0, 0, 0.7)",
          borderBottom: "1px solid rgba(0, 0, 0, 0.7)",
          borderBottomLeftRadius: `${DB_ProductDetails_BorderRadius}px`,
          borderBottomRightRadius: `${DB_ProductDetails_BorderRadius}px`,
          color: "white",
          fontWeight: "bold",
          marginTop: "0px",
        },
        produktPreis: {
          fontSize: `${DB_ProductDetails_Preis_FontSize}px`,
        },

        produktPreisWaehrung: {
          fontSize: `${DB_ProductDetails_Waehrung_FontSize}px`,
        },

        imageGalleryBGColor: {
          backgroundColor: `${
            SideBarLeft_DetailItem_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut
              ? "rgba(255,0,0,0.5)"
              : DB_LeftSidebar_MenuItem_TitelDetailBeschreibung_BackgroundColor
          }`,
        },

        verkaufsplattform: {
          cursor: "pointer",
          "&:hover": {
            textDecoration: "underline",
          },
        },
      },
    },
  });

  const classes = useStyles();

  const [DB_TopBarLogoImageSrc, setDB_TopBarLogoImageSrc] = useState("");
  useEffect(() => {
    axios({
      method: "post",
      url: `${process.env.REACT_APP_PUBLIC_SERVER_URL}/shop/get-design-logo-image`,
      data: {
        shopName,
      },
    })
      .then((uploadedResult) => {
        if (uploadedResult.status === 201) {
        } else {
          setDB_TopBarLogoImageSrc(
            `${process.env.REACT_APP_PUBLIC_SERVER_URL}/` +
              uploadedResult.data.logo_image +
              "?" +
              new Date().getMilliseconds()
          );
        }
      })
      .catch((err) => {
        toast.error(
          "Keine Verbindung zum Server - Logo konnte nicht vom Server heruntergeladen werden",
          {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            transition: Zoom,
          }
        );
      });
  }, []);

  return (
    <>
      <div className={"col-lg-12 mt-3 col-12"}>
        <div className="text-center">
          {userOwnsThisSite && (
            <a
              href={`${process.env.REACT_APP_PUBLIC_CLIENT_URL}/add-product?title=${productDetailItem.titel}`}
              target="_blank"
              rel="noopener"
            >
              <Button
                variant="warning"
                style={{ marginBottom: "10px", width: "100%" }}
              >
                {t("DiesenArtikelInDerProduktverwaltungAnpassen")}
              </Button>
            </a>
          )}
          <BodyProductMainTitle
            hideProductDetailsHandler={hideProductDetailsHandler}
            productDetailItemTitel={
              productDetailItem && productDetailItem.titel
            }
            DB_fontFace_chooseFontFamilySuperTitel_FontFamilyName={
              DB_fontFace_chooseFontFamilySuperTitel_FontFamilyName
            }
            DB_fontFace_chooseFontFamilySuperTitel_FontFamilySrc={
              DB_fontFace_chooseFontFamilySuperTitel_FontFamilySrc
            }
            DB_LeftSidebar_MenuItem_TitelSuperTitel_FontWeight={
              DB_LeftSidebar_MenuItem_TitelSuperTitel_FontWeight
            }
            DB_LeftSidebar_MenuItem_TitelSuperTitel_FontColor={
              DB_LeftSidebar_MenuItem_TitelSuperTitel_FontColor
            }
            DB_LeftSidebar_MenuItem_TitelSuperTitel_FontSize={
              DB_LeftSidebar_MenuItem_TitelSuperTitel_FontSize
            }
            DB_LeftSidebar_MenuItem_TitelSuperTitel_BackgroundColor={
              DB_LeftSidebar_MenuItem_TitelSuperTitel_BackgroundColor
            }
            DB_LeftSidebar_MenuItem_TitelSuperTitel_TextAlign={
              DB_LeftSidebar_MenuItem_TitelSuperTitel_TextAlign
            }
            DB_LeftSidebar_MenuItem_TitelSuperTitel_BorderTop={
              DB_LeftSidebar_MenuItem_TitelSuperTitel_BorderTop
            }
            DB_LeftSidebar_MenuItem_TitelSuperTitel_BorderStyleTop={
              DB_LeftSidebar_MenuItem_TitelSuperTitel_BorderStyleTop
            }
            DB_LeftSidebar_MenuItem_TitelSuperTitel_BorderColorTop={
              DB_LeftSidebar_MenuItem_TitelSuperTitel_BorderColorTop
            }
            DB_LeftSidebar_MenuItem_TitelSuperTitel_BorderBottom={
              DB_LeftSidebar_MenuItem_TitelSuperTitel_BorderBottom
            }
            DB_LeftSidebar_MenuItem_TitelSuperTitel_BorderStyleBottom={
              DB_LeftSidebar_MenuItem_TitelSuperTitel_BorderStyleBottom
            }
            DB_LeftSidebar_MenuItem_TitelSuperTitel_BorderColorBottom={
              DB_LeftSidebar_MenuItem_TitelSuperTitel_BorderColorBottom
            }
            DB_LeftSidebar_MenuItem_TitelSuperTitel_BorderLeft={
              DB_LeftSidebar_MenuItem_TitelSuperTitel_BorderLeft
            }
            DB_LeftSidebar_MenuItem_TitelSuperTitel_BorderStyleLeft={
              DB_LeftSidebar_MenuItem_TitelSuperTitel_BorderStyleLeft
            }
            DB_LeftSidebar_MenuItem_TitelSuperTitel_BorderColorLeft={
              DB_LeftSidebar_MenuItem_TitelSuperTitel_BorderColorLeft
            }
            DB_LeftSidebar_MenuItem_TitelSuperTitel_BorderRight={
              DB_LeftSidebar_MenuItem_TitelSuperTitel_BorderRight
            }
            DB_LeftSidebar_MenuItem_TitelSuperTitel_BorderStyleRight={
              DB_LeftSidebar_MenuItem_TitelSuperTitel_BorderStyleRight
            }
            DB_LeftSidebar_MenuItem_TitelSuperTitel_BorderColorRight={
              DB_LeftSidebar_MenuItem_TitelSuperTitel_BorderColorRight
            }
            DB_LeftSidebar_MenuItem_TitelSuperTitel_BorderTopRightRadius={
              DB_LeftSidebar_MenuItem_TitelSuperTitel_BorderTopRightRadius
            }
            DB_LeftSidebar_MenuItem_TitelSuperTitel_BorderTopLeftRadius={
              DB_LeftSidebar_MenuItem_TitelSuperTitel_BorderTopLeftRadius
            }
            DB_LeftSidebar_MenuItem_TitelSuperTitel_BorderBottomRightRadius={
              DB_LeftSidebar_MenuItem_TitelSuperTitel_BorderBottomRightRadius
            }
            DB_LeftSidebar_MenuItem_TitelSuperTitel_BorderBottomLeftRadius={
              DB_LeftSidebar_MenuItem_TitelSuperTitel_BorderBottomLeftRadius
            }
            DB_LeftSidebar_MenuItem_TitelSuperTitel_marginLeftRight={
              DB_LeftSidebar_MenuItem_TitelSuperTitel_marginLeftRight
            }
            DB_LeftSidebar_MenuItem_TitelSuperTitel_marginTopBottom={
              DB_LeftSidebar_MenuItem_TitelSuperTitel_marginTopBottom
            }
            DB_LeftSidebar_MenuItem_TitelSuperTitel_paddingLeftRight={
              DB_LeftSidebar_MenuItem_TitelSuperTitel_paddingLeftRight
            }
            DB_LeftSidebar_MenuItem_TitelSuperTitel_paddingTopBottom={
              DB_LeftSidebar_MenuItem_TitelSuperTitel_paddingTopBottom
            }
            DB_LeftSidebar_MenuItem_TitelSuperTitel_letterSpacing={
              DB_LeftSidebar_MenuItem_TitelSuperTitel_letterSpacing
            }
            DB_LeftSidebar_MenuItem_TitelSuperTitel_ShadowHorizontal={
              DB_LeftSidebar_MenuItem_TitelSuperTitel_ShadowHorizontal
            }
            DB_LeftSidebar_MenuItem_TitelSuperTitel_ShadowVertical={
              DB_LeftSidebar_MenuItem_TitelSuperTitel_ShadowVertical
            }
            DB_LeftSidebar_MenuItem_TitelSuperTitel_ShadowBlur={
              DB_LeftSidebar_MenuItem_TitelSuperTitel_ShadowBlur
            }
            DB_LeftSidebar_MenuItem_TitelSuperTitel_ShadowColor={
              DB_LeftSidebar_MenuItem_TitelSuperTitel_ShadowColor
            }
            DB_LeftSidebar_MenuItem_TitelSuperTitel_TextDecoration={
              DB_LeftSidebar_MenuItem_TitelSuperTitel_TextDecoration
            }
            DB_LeftSidebar_MenuItem_TitelSuperTitel_TextDecorationStyle={
              DB_LeftSidebar_MenuItem_TitelSuperTitel_TextDecorationStyle
            }
            SideBarLeft_DetailSuper_Farbe_Groesse_Stil_OnMouseOverAndOut={
              SideBarLeft_DetailSuper_Farbe_Groesse_Stil_OnMouseOverAndOut
            }
            SideBarLeft_DetailSuper_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut={
              SideBarLeft_DetailSuper_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut
            }
          />
          {/* <div
            className="productDetailItemHeaderUntertitel"
            onClick={hideProductDetailsHandler}
          >
            {productDetailItem.untertitel}
          </div> */}
        </div>
        <div
          className="border-0 m-0 p-0"
          style={{
            borderRadius: `${DB_ProductDetails_BorderRadius}px`,
            padding: "20px",
            boxShadow:
              "rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px",
          }}
        >
          {/*   <div onClick={changeProductDetailsHandler}> */}
          <ImageGallery
            items={images}
            /* ref={(i) => (this._imageGallery = i)} */
            /* onClick={hideProductDetailsHandler} */
            /* onImageLoad={this._onImageLoad} */
            /* onSlide={this._onSlide.bind(this)}
              onPause={this._onPause.bind(this)}
              onScreenChange={this._onScreenChange.bind(this)} */
            /* onPlay={this._onPlay.bind(this)} */
            infinite={infinite}
            showBullets={showBullets}
            showFullscreenButton={
              showFullscreenButton && showGalleryFullscreenButton
            }
            showPlayButton={showPlayButton && showGalleryPlayButton}
            showThumbnails={showThumbnails}
            /* showIndex={showIndex} */
            showNav={showNav}
            isRTL={isRTL}
            thumbnailPosition={thumbnailPosition}
            slideDuration={parseInt(slideDuration)}
            slideInterval={parseInt(slideInterval)}
            slideOnThumbnailOver={slideOnThumbnailOver}
            useWindowKeyDown={useWindowKeyDown}
          />
          {/*  </div> */}
        </div>
        {/* <Container className="mx-0 px-0">
          <div className="row">
            <div className={"col-lg-12 mt-3 col-12"}>
              <span className="produktKategorieProductDetailSmall">
                <span
                  className="produktKategorieDetailProductSmallItem"
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    categoryLeftSideHandler(`${productDetailItem.kategorie}`)
                  }
                >
                  {productDetailItem.kategorie}
                </span>{" "}
                <span className="produktKategorieSmallItem">
                  {productDetailItem.unterkategorie && <span> {">"} </span>}
                </span>
                <span
                  className="produktKategorieDetailProductSmallItem"
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    categoryLeftSideHandler(
                      `${productDetailItem.kategorie}`,
                      `${productDetailItem.unterkategorie}`
                    )
                  }
                >
                  {productDetailItem.unterkategorie}
                </span>{" "}
                <span className="produktKategorieSmallItem">
                  {productDetailItem.unterunterkategorie && (
                    <span> {">"} </span>
                  )}
                </span>
                <span
                  className="produktKategorieDetailProductSmallItem"
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    categoryLeftSideHandler(
                      `${productDetailItem.kategorie}`,
                      `${productDetailItem.unterkategorie}`,
                      `${productDetailItem.unterunterkategorie}`
                    )
                  }
                >
                  {productDetailItem.unterunterkategorie}
                </span>
              </span>
            </div>
          </div>
        </Container> */}
        {productDetailItem.beschreibung && (
          <Container>
            <br />
            <div className="row">
              <div className="col-lg-12 mt-3 px-0 col-12">
                <div className={classes.productDetailItemHeaderTitel}>
                  {t("Beschreibung")}
                </div>
              </div>
              <div className="col-lg-12 mt-0 px-0 col-12">
                <div className={classes.produktBeschreibung}>
                  {productDetailItem.beschreibung}
                </div>
              </div>
            </div>
          </Container>
        )}

        <div className="row">
          <div className={"col-lg-6 mt-3 col-12"}>
            {(productDetailItem.zustand ||
              productDetailItem.garantie ||
              productDetailItem.marke ||
              productDetailItem.material ||
              productDetailItem.farbe ||
              productDetailItem.groesse) && (
              <div>
                <div className={classes.productDetailItemHeaderTitel}>
                  {t("Eigenschaften")}
                </div>
                <div className={classes.produktBeschreibung}>
                  {productDetailItem.zustand && (
                    <div>
                      <strong className="me-2">{t("Zustand")}:</strong>{" "}
                      {productDetailItem.zustand}
                    </div>
                  )}
                  {productDetailItem.garantie && (
                    <div>
                      <strong className="me-2">{t("Garantie")}:</strong>{" "}
                      {productDetailItem.garantie}
                    </div>
                  )}
                  {productDetailItem.marke && (
                    <div>
                      <strong className="me-2">{t("Marke")}:</strong>{" "}
                      {productDetailItem.marke}
                    </div>
                  )}
                  {productDetailItem.material && (
                    <div>
                      <strong className="me-2">{t("Material")}:</strong>{" "}
                      {productDetailItem.material}
                    </div>
                  )}
                  {productDetailItem.farbe && (
                    <div>
                      <strong className="me-2">{t("Farbe")}:</strong>{" "}
                      {productDetailItem.farbe}
                    </div>
                  )}
                  {productDetailItem.groesse && (
                    <div>
                      <strong className="me-2">{t("Groesse")}:</strong>{" "}
                      {productDetailItem.groesse}
                    </div>
                  )}
                </div>
              </div>
            )}
            {(productDetailItem.laenge ||
              productDetailItem.breite ||
              productDetailItem.hoehe ||
              productDetailItem.gewicht) && (
              <div>
                <div className={`${classes.productDetailItemHeaderTitel} mt-3`}>
                  {t("Dimensionen")}
                </div>
                <div className={classes.produktBeschreibung}>
                  {productDetailItem.laenge && (
                    <div>
                      <strong className="me-2">{t("Laenge")}:</strong>{" "}
                      {productDetailItem.laenge}
                    </div>
                  )}
                  {productDetailItem.breite && (
                    <div>
                      <strong className="me-2">{t("Breite")}:</strong>{" "}
                      {productDetailItem.breite}
                    </div>
                  )}
                  {productDetailItem.hoehe && (
                    <div>
                      <strong className="me-2">{t("Hoehe")}:</strong>{" "}
                      {productDetailItem.hoehe}
                    </div>
                  )}
                  {productDetailItem.gewicht && (
                    <div>
                      <strong className="me-2">{t("Gewicht")}:</strong>{" "}
                      {productDetailItem.gewicht}
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>

          <div className={"col-lg-6 mt-3 col-12"}>
            {(productDetailItem.lieferungstyp ||
              productDetailItem.lieferkosten ||
              productDetailItem.bezahlung ||
              productDetailItem.zahlungsfrist) && (
              <div>
                <div className={classes.productDetailItemHeaderTitel}>
                  {t("Lieferbedingungen")}
                </div>
                <div className={classes.produktBeschreibung}>
                  {productDetailItem.lieferungstyp && (
                    <div>
                      <strong className="me-2">{t("Lieferungstyp")}:</strong>{" "}
                      {productDetailItem.lieferungstyp}
                    </div>
                  )}

                  {productDetailItem.lieferkosten && (
                    <div>
                      <strong className="me-2">{t("Lieferkosten")}:</strong>{" "}
                      {productDetailItem.lieferkosten} {DB_Waehrung}
                    </div>
                  )}
                  {productDetailItem.bezahlung && (
                    <div>
                      <strong className="me-2">{t("Bezahlung")}:</strong>{" "}
                      {productDetailItem.bezahlung}
                    </div>
                  )}
                  {productDetailItem.zahlungsfrist && (
                    <div>
                      <strong className="me-2">{t("Zahlungsfrist")}:</strong>{" "}
                      {productDetailItem.zahlungsfrist} {t("Tage")}
                    </div>
                  )}
                </div>
              </div>
            )}
            {productDetailItem.verkaufsplattform && (
              <div>
                <div className={`${classes.productDetailItemHeaderTitel} mt-3`}>
                  {t("Verkaufsplattform")}
                </div>
                <div className={classes.produktBeschreibung}>
                  {productDetailItem.verkaufsplattform &&
                    productDetailItem.auktionsweblink && (
                      <div onClick={toggleOpenLink}>
                        <strong className="me-2">
                          {t("Verkaufsplattform")}:
                        </strong>
                        <span className="verkaufsplattform">
                          {productDetailItem.verkaufsplattform}
                        </span>
                      </div>
                    )}
                  {productDetailItem.artikelnummer && (
                    <div>
                      <strong className="me-2">{t("Artikelnummer")}:</strong>{" "}
                      {productDetailItem.artikelnummer}
                    </div>
                  )}

                  {productDetailItem.startdatum && (
                    <div>
                      <strong className="me-2">{t("Startdatum")}:</strong>{" "}
                      {new Date(
                        productDetailItem.startdatum
                      ).toLocaleDateString(language ? language : "en")}
                    </div>
                  )}
                  {productDetailItem.enddatum && (
                    <div>
                      <strong className="me-2">{t("Enddatum")}:</strong>{" "}
                      {new Date(productDetailItem.enddatum).toLocaleDateString(
                        language ? language : "en"
                      )}
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>

          {productDetailItem.fixpreis && (
            <div className={"col-lg-3 mb-0"}>
              <div className={classes.productDetailItemHeaderTitel}>
                {t("Preis")}
              </div>
              <div className={classes.produktBeschreibung}>
                <div
                  className="produktPreis"
                  onClick={(productIdentifier) =>
                    showProductDetailsHandler(product.titel)
                  }
                >
                  {productDetailItem.fixpreis} {DB_Waehrung}
                </div>
              </div>
            </div>
          )}
        </div>

        <div className="row">
          <div className={"col-lg-12 col-12"}>
            <BodyProductDetailKontaktFormular
              DB_fontFace_chooseFontHaupttitelFontFamily_FontFamilyName={
                DB_fontFace_chooseFontHaupttitelFontFamily_FontFamilyName
              }
              SideBarLeft_ContainerDetail_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut={
                SideBarLeft_ContainerDetail_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut
              }
              SideBarLeft_ContainerDetail_Farbe_Groesse_Stil_OnMouseOverAndOut={
                SideBarLeft_ContainerDetail_Farbe_Groesse_Stil_OnMouseOverAndOut
              }
              DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_FontWeight={
                DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_FontWeight
              }
              DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_FontColor={
                DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_FontColor
              }
              DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_FontSize={
                DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_FontSize
              }
              DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BackgroundColor={
                DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BackgroundColor
              }
              DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_TextAlign={
                DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_TextAlign
              }
              DB_fontFace_chooseFontHaupttitelFontFamily_FontFamilySrc={
                DB_fontFace_chooseFontHaupttitelFontFamily_FontFamilySrc
              }
              DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderTop={
                DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderTop
              }
              DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderStyleTop={
                DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderStyleTop
              }
              DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderColorTop={
                DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderColorTop
              }
              DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderBottom={
                DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderBottom
              }
              DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderStyleBottom={
                DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderStyleBottom
              }
              DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderColorBottom={
                DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderColorBottom
              }
              DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderLeft={
                DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderLeft
              }
              DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderStyleLeft={
                DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderStyleLeft
              }
              DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderColorLeft={
                DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderColorLeft
              }
              DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderRight={
                DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderRight
              }
              DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderStyleRight={
                DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderStyleRight
              }
              DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderColorRight={
                DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderColorRight
              }
              DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderTopRightRadius={
                DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderTopRightRadius
              }
              DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderTopLeftRadius={
                DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderTopLeftRadius
              }
              DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderBottomRightRadius={
                DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderBottomRightRadius
              }
              DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderBottomLeftRadius={
                DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_BorderBottomLeftRadius
              }
              DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_marginLeftRight={
                DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_marginLeftRight
              }
              DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_marginTopBottom={
                DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_marginTopBottom
              }
              DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_paddingLeftRight={
                DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_paddingLeftRight
              }
              DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_paddingTopBottom={
                DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_paddingTopBottom
              }
              DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_letterSpacing={
                DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_letterSpacing
              }
              DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_ShadowHorizontal={
                DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_ShadowHorizontal
              }
              DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_ShadowVertical={
                DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_ShadowVertical
              }
              DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_ShadowBlur={
                DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_ShadowBlur
              }
              DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_ShadowColor={
                DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_ShadowColor
              }
              DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_TextDecoration={
                DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_TextDecoration
              }
              DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_TextDecorationStyle={
                DB_LeftSidebar_MenuItem_TitelDetailHauptTitel_TextDecorationStyle
              }
            />
            <div className={`row  ${classes.KontaktFormularContainer}`}>
              <BodyProductDetailFormular
                stateNachricht={stateNachricht}
                contactFormHandlerNachricht={contactFormHandlerNachricht}
                anfrageAnVerkaeuferSendenHandler={
                  anfrageAnVerkaeuferSendenHandler
                }
                DB_LeftSidebar_MenuItem_TitelKontaktFormular_FontWeight={
                  DB_LeftSidebar_MenuItem_TitelKontaktFormular_FontWeight
                }
                DB_LeftSidebar_MenuItem_TitelKontaktFormular_FontSize={
                  DB_LeftSidebar_MenuItem_TitelKontaktFormular_FontSize
                }
                DB_LeftSidebar_MenuItem_TitelKontaktFormular_TextAlign={
                  DB_LeftSidebar_MenuItem_TitelKontaktFormular_TextAlign
                }
                DB_LeftSidebar_MenuItem_TitelKontaktFormular_FontColor={
                  DB_LeftSidebar_MenuItem_TitelKontaktFormular_FontColor
                }
                DB_LeftSidebar_MenuItem_TitelFormInputDetail_FontWeight={
                  DB_LeftSidebar_MenuItem_TitelFormInputDetail_FontWeight
                }
                DB_LeftSidebar_MenuItem_TitelFormInputDetail_FontColor={
                  DB_LeftSidebar_MenuItem_TitelFormInputDetail_FontColor
                }
                DB_LeftSidebar_MenuItem_TitelFormInputDetail_FontSize={
                  DB_LeftSidebar_MenuItem_TitelFormInputDetail_FontSize
                }
                DB_LeftSidebar_MenuItem_TitelFormInputDetail_BackgroundColor={
                  DB_LeftSidebar_MenuItem_TitelFormInputDetail_BackgroundColor
                }
                DB_LeftSidebar_MenuItem_TitelFormInputDetail_TextAlign={
                  DB_LeftSidebar_MenuItem_TitelFormInputDetail_TextAlign
                }
                DB_fontFace_chooseFontFormLabelsFontFamily_FontFamilyName={
                  DB_fontFace_chooseFontFormLabelsFontFamily_FontFamilyName
                }
                DB_LeftSidebar_MenuItem_TitelFormInputDetail_BorderTop={
                  DB_LeftSidebar_MenuItem_TitelFormInputDetail_BorderTop
                }
                DB_LeftSidebar_MenuItem_TitelFormInputDetail_BorderStyleTop={
                  DB_LeftSidebar_MenuItem_TitelFormInputDetail_BorderStyleTop
                }
                DB_LeftSidebar_MenuItem_TitelFormInputDetail_BorderColorTop={
                  DB_LeftSidebar_MenuItem_TitelFormInputDetail_BorderColorTop
                }
                DB_LeftSidebar_MenuItem_TitelFormInputDetail_BorderBottom={
                  DB_LeftSidebar_MenuItem_TitelFormInputDetail_BorderBottom
                }
                DB_LeftSidebar_MenuItem_TitelFormInputDetail_BorderStyleBottom={
                  DB_LeftSidebar_MenuItem_TitelFormInputDetail_BorderStyleBottom
                }
                DB_LeftSidebar_MenuItem_TitelFormInputDetail_BorderColorBottom={
                  DB_LeftSidebar_MenuItem_TitelFormInputDetail_BorderColorBottom
                }
                DB_LeftSidebar_MenuItem_TitelFormInputDetail_BorderLeft={
                  DB_LeftSidebar_MenuItem_TitelFormInputDetail_BorderLeft
                }
                DB_LeftSidebar_MenuItem_TitelFormInputDetail_BorderStyleLeft={
                  DB_LeftSidebar_MenuItem_TitelFormInputDetail_BorderStyleLeft
                }
                DB_LeftSidebar_MenuItem_TitelFormInputDetail_BorderColorLeft={
                  DB_LeftSidebar_MenuItem_TitelFormInputDetail_BorderColorLeft
                }
                DB_LeftSidebar_MenuItem_TitelFormInputDetail_BorderRight={
                  DB_LeftSidebar_MenuItem_TitelFormInputDetail_BorderRight
                }
                DB_LeftSidebar_MenuItem_TitelFormInputDetail_BorderStyleRight={
                  DB_LeftSidebar_MenuItem_TitelFormInputDetail_BorderStyleRight
                }
                DB_LeftSidebar_MenuItem_TitelFormInputDetail_BorderColorRight={
                  DB_LeftSidebar_MenuItem_TitelFormInputDetail_BorderColorRight
                }
                DB_LeftSidebar_MenuItem_TitelFormInputDetail_BorderTopRightRadius={
                  DB_LeftSidebar_MenuItem_TitelFormInputDetail_BorderTopRightRadius
                }
                DB_LeftSidebar_MenuItem_TitelFormInputDetail_BorderTopLeftRadius={
                  DB_LeftSidebar_MenuItem_TitelFormInputDetail_BorderTopLeftRadius
                }
                DB_LeftSidebar_MenuItem_TitelFormInputDetail_BorderBottomRightRadius={
                  DB_LeftSidebar_MenuItem_TitelFormInputDetail_BorderBottomRightRadius
                }
                DB_LeftSidebar_MenuItem_TitelFormInputDetail_BorderBottomLeftRadius={
                  DB_LeftSidebar_MenuItem_TitelFormInputDetail_BorderBottomLeftRadius
                }
                DB_LeftSidebar_MenuItem_TitelFormInputDetail_marginLeftRight={
                  DB_LeftSidebar_MenuItem_TitelFormInputDetail_marginLeftRight
                }
                DB_LeftSidebar_MenuItem_TitelFormInputDetail_marginTopBottom={
                  DB_LeftSidebar_MenuItem_TitelFormInputDetail_marginTopBottom
                }
                DB_LeftSidebar_MenuItem_TitelFormInputDetail_paddingLeftRight={
                  DB_LeftSidebar_MenuItem_TitelFormInputDetail_paddingLeftRight
                }
                DB_LeftSidebar_MenuItem_TitelFormInputDetail_paddingTopBottom={
                  DB_LeftSidebar_MenuItem_TitelFormInputDetail_paddingTopBottom
                }
                DB_LeftSidebar_MenuItem_TitelFormInputDetail_letterSpacing={
                  DB_LeftSidebar_MenuItem_TitelFormInputDetail_letterSpacing
                }
                DB_LeftSidebar_MenuItem_TitelFormInputDetail_ShadowHorizontal={
                  DB_LeftSidebar_MenuItem_TitelFormInputDetail_ShadowHorizontal
                }
                DB_LeftSidebar_MenuItem_TitelFormInputDetail_ShadowVertical={
                  DB_LeftSidebar_MenuItem_TitelFormInputDetail_ShadowVertical
                }
                DB_LeftSidebar_MenuItem_TitelFormInputDetail_ShadowBlur={
                  DB_LeftSidebar_MenuItem_TitelFormInputDetail_ShadowBlur
                }
                DB_LeftSidebar_MenuItem_TitelFormInputDetail_ShadowColor={
                  DB_LeftSidebar_MenuItem_TitelFormInputDetail_ShadowColor
                }
                DB_LeftSidebar_MenuItem_TitelFormInputDetail_TextDecoration={
                  DB_LeftSidebar_MenuItem_TitelFormInputDetail_TextDecoration
                }
                DB_LeftSidebar_MenuItem_TitelFormInputDetail_TextDecorationStyle={
                  DB_LeftSidebar_MenuItem_TitelFormInputDetail_TextDecorationStyle
                }
                DB_LeftSidebar_MenuItem_TitelFormInputDetail_FontColorNotEncoded={
                  DB_LeftSidebar_MenuItem_TitelFormInputDetail_FontColorNotEncoded
                }
                SideBarLeft_TitelFormInputDetail_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut={
                  SideBarLeft_FormInputDetail_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut
                }
                SideBarLeft_TitelFormInputDetail_Farbe_Groesse_Stil_OnMouseOverAndOut={
                  SideBarLeft_FormInputDetail_Farbe_Groesse_Stil_OnMouseOverAndOut
                }
                DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_FontFamilyName={
                  DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_FontFamilyName
                }
                DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_FontFamilySrc={
                  DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_FontFamilySrc
                }
                DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_FontWeight={
                  DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_FontWeight
                }
                DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_FontColor={
                  DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_FontColor
                }
                DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_FontSize={
                  DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_FontSize
                }
                DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_BackgroundColor={
                  DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_BackgroundColor
                }
                DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_TextAlign={
                  DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_TextAlign
                }
                DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_BorderTop={
                  DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_BorderTop
                }
                DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_BorderStyleTop={
                  DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_BorderStyleTop
                }
                DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_BorderColorTop={
                  DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_BorderColorTop
                }
                DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_BorderBottom={
                  DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_BorderBottom
                }
                DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_BorderStyleBottom={
                  DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_BorderStyleBottom
                }
                DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_BorderColorBottom={
                  DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_BorderColorBottom
                }
                DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_BorderLeft={
                  DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_BorderLeft
                }
                DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_BorderStyleLeft={
                  DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_BorderStyleLeft
                }
                DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_BorderColorLeft={
                  DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_BorderColorLeft
                }
                DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_BorderRight={
                  DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_BorderRight
                }
                DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_BorderStyleRight={
                  DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_BorderStyleRight
                }
                DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_BorderColorRight={
                  DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_BorderColorRight
                }
                DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_BorderTopRightRadius={
                  DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_BorderTopRightRadius
                }
                DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_BorderTopLeftRadius={
                  DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_BorderTopLeftRadius
                }
                DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_BorderBottomRightRadius={
                  DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_BorderBottomRightRadius
                }
                DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_BorderBottomLeftRadius={
                  DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_BorderBottomLeftRadius
                }
                DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_marginLeftRight={
                  DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_marginLeftRight
                }
                DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_marginTopBottom={
                  DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_marginTopBottom
                }
                DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_paddingLeftRight={
                  DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_paddingLeftRight
                }
                DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_paddingTopBottom={
                  DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_paddingTopBottom
                }
                DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_letterSpacing={
                  DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_letterSpacing
                }
                DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_ShadowHorizontal={
                  DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_ShadowHorizontal
                }
                DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_ShadowVertical={
                  DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_ShadowVertical
                }
                DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_ShadowBlur={
                  DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_ShadowBlur
                }
                DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_ShadowColor={
                  DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_ShadowColor
                }
                DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_TextDecoration={
                  DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_TextDecoration
                }
                DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_TextDecorationStyle={
                  DB_LeftSidebar_MenuItem_TitelKontaktFormularTitel_TextDecorationStyle
                }
                SideBarLeft_TitelKontaktFormularTitel_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut={
                  SideBarLeft_TitelKontaktFormularTitel_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut
                }
                SideBarLeft_TitelKontaktFormularTitel_Farbe_Groesse_Stil_OnMouseOverAndOut={
                  SideBarLeft_TitelKontaktFormularTitel_Farbe_Groesse_Stil_OnMouseOverAndOut
                }
                SideBarLeft_AlleDetail_Farbe_Groesse_Stil_OnMouseOverAndOut={
                  SideBarLeft_AlleDetail_Farbe_Groesse_Stil_OnMouseOverAndOut
                }
                SideBarLeft_AlleDetail_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut={
                  SideBarLeft_AlleDetail_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut
                }
                DB_fontFace_chooseFontKontaktFormularFontFamily_FontFamilyName={
                  DB_fontFace_chooseFontKontaktFormularFontFamily_FontFamilyName
                }
                DB_LeftSidebar_MenuItem_TitelKontaktFormular_BorderTop={
                  DB_LeftSidebar_MenuItem_TitelKontaktFormular_BorderTop
                }
                DB_LeftSidebar_MenuItem_TitelKontaktFormular_BorderStyleTop={
                  DB_LeftSidebar_MenuItem_TitelKontaktFormular_BorderStyleTop
                }
                DB_LeftSidebar_MenuItem_TitelKontaktFormular_BorderColorTop={
                  DB_LeftSidebar_MenuItem_TitelKontaktFormular_BorderColorTop
                }
                DB_LeftSidebar_MenuItem_TitelKontaktFormular_BorderBottom={
                  DB_LeftSidebar_MenuItem_TitelKontaktFormular_BorderBottom
                }
                DB_LeftSidebar_MenuItem_TitelKontaktFormular_BorderStyleBottom={
                  DB_LeftSidebar_MenuItem_TitelKontaktFormular_BorderStyleBottom
                }
                DB_LeftSidebar_MenuItem_TitelKontaktFormular_BorderColorBottom={
                  DB_LeftSidebar_MenuItem_TitelKontaktFormular_BorderColorBottom
                }
                DB_LeftSidebar_MenuItem_TitelKontaktFormular_BorderLeft={
                  DB_LeftSidebar_MenuItem_TitelKontaktFormular_BorderLeft
                }
                DB_LeftSidebar_MenuItem_TitelKontaktFormular_BorderStyleLeft={
                  DB_LeftSidebar_MenuItem_TitelKontaktFormular_BorderStyleLeft
                }
                DB_LeftSidebar_MenuItem_TitelKontaktFormular_BorderColorLeft={
                  DB_LeftSidebar_MenuItem_TitelKontaktFormular_BorderColorLeft
                }
                DB_LeftSidebar_MenuItem_TitelKontaktFormular_BorderRight={
                  DB_LeftSidebar_MenuItem_TitelKontaktFormular_BorderRight
                }
                DB_LeftSidebar_MenuItem_TitelKontaktFormular_BorderStyleRight={
                  DB_LeftSidebar_MenuItem_TitelKontaktFormular_BorderStyleRight
                }
                DB_LeftSidebar_MenuItem_TitelKontaktFormular_BorderColorRight={
                  DB_LeftSidebar_MenuItem_TitelKontaktFormular_BorderColorRight
                }
                DB_LeftSidebar_MenuItem_TitelKontaktFormular_BorderTopRightRadius={
                  DB_LeftSidebar_MenuItem_TitelKontaktFormular_BorderTopRightRadius
                }
                DB_LeftSidebar_MenuItem_TitelKontaktFormular_BorderTopLeftRadius={
                  DB_LeftSidebar_MenuItem_TitelKontaktFormular_BorderTopLeftRadius
                }
                DB_LeftSidebar_MenuItem_TitelKontaktFormular_BorderBottomRightRadius={
                  DB_LeftSidebar_MenuItem_TitelKontaktFormular_BorderBottomRightRadius
                }
                DB_LeftSidebar_MenuItem_TitelKontaktFormular_BorderBottomLeftRadius={
                  DB_LeftSidebar_MenuItem_TitelKontaktFormular_BorderBottomLeftRadius
                }
                DB_LeftSidebar_MenuItem_TitelKontaktFormular_marginLeftRight={
                  DB_LeftSidebar_MenuItem_TitelKontaktFormular_marginLeftRight
                }
                DB_LeftSidebar_MenuItem_TitelKontaktFormular_marginTopBottom={
                  DB_LeftSidebar_MenuItem_TitelKontaktFormular_marginTopBottom
                }
                DB_LeftSidebar_MenuItem_TitelKontaktFormular_paddingLeftRight={
                  DB_LeftSidebar_MenuItem_TitelKontaktFormular_paddingLeftRight
                }
                DB_LeftSidebar_MenuItem_TitelKontaktFormular_paddingTopBottom={
                  DB_LeftSidebar_MenuItem_TitelKontaktFormular_paddingTopBottom
                }
                DB_LeftSidebar_MenuItem_TitelKontaktFormular_letterSpacing={
                  DB_LeftSidebar_MenuItem_TitelKontaktFormular_letterSpacing
                }
                DB_LeftSidebar_MenuItem_TitelKontaktFormular_ShadowHorizontal={
                  DB_LeftSidebar_MenuItem_TitelKontaktFormular_ShadowHorizontal
                }
                DB_LeftSidebar_MenuItem_TitelKontaktFormular_ShadowVertical={
                  DB_LeftSidebar_MenuItem_TitelKontaktFormular_ShadowVertical
                }
                DB_LeftSidebar_MenuItem_TitelKontaktFormular_ShadowBlur={
                  DB_LeftSidebar_MenuItem_TitelKontaktFormular_ShadowBlur
                }
                DB_LeftSidebar_MenuItem_TitelKontaktFormular_ShadowColor={
                  DB_LeftSidebar_MenuItem_TitelKontaktFormular_ShadowColor
                }
                DB_LeftSidebar_MenuItem_TitelKontaktFormular_TextDecoration={
                  DB_LeftSidebar_MenuItem_TitelKontaktFormular_TextDecoration
                }
                DB_LeftSidebar_MenuItem_TitelKontaktFormular_TextDecorationStyle={
                  DB_LeftSidebar_MenuItem_TitelKontaktFormular_TextDecorationStyle
                }
              />
            </div>
          </div>
        </div>
      </div>

      <Modal show={openLinkModal} backdrop={true}>
        <Modal.Header>
          <div className="loginRegisterHeader">
            <span>
              {DB_TopBarLogoImageSrc && logoActive ? (
                <img
                  src={DB_TopBarLogoImageSrc}
                  alt="SuperMegaShop.com Logo"
                  height="65"
                />
              ) : (
                ""
              )}
            </span>
          </div>
        </Modal.Header>

        <Modal.Body
          className="p-0  text-white pb-4 pt-2 px-4"
          style={{ overflow: "auto" }}
        >
          <h6>Der Link führt zu folgender Seite:</h6>
          <br />
          <h6
            style={{
              width: "350px",
              wordWrap: "break-word",
              display: "inline-block",
            }}
          >
            {productDetailItem.auktionsweblink}
          </h6>
          <br />
          <br />
          <h6>Möchten Sie diesen Link wirklich öffnen?</h6>
        </Modal.Body>
        <Row className="px-3 pb-4 pt-2">
          <Col>
            <Button variant="secondary" onClick={toggleOpenLink}>
              {t("Schliessen")}
            </Button>

            <Button style={{ float: "right" }}>
              <a
                target="_blank"
                rel="noopener"
                href={`${productDetailItem.auktionsweblink}`}
              >
                Link öffnen
              </a>
            </Button>
          </Col>
        </Row>
      </Modal>

      <Modal
        show={signInModal}
        backdrop={true}
        className={
          doLoginOn || doForgotPassword ? "loggingModal" : "registerModal"
        }
      >
        <Modal.Header>
          <div className="loginRegisterHeader">
            <span>
              {DB_TopBarLogoImageSrc && logoActive ? (
                <img
                  src={DB_TopBarLogoImageSrc}
                  alt="SuperMegaShop.com Logo"
                  height="65"
                />
              ) : (
                ""
              )}
            </span>
          </div>
        </Modal.Header>

        {doLoginOn ? (
          <Modal.Body className="p-0 loginRegister loginModal">
            <div className="loginRegisterIcons">
              <div
                onClick={anmeldenAktiv}
                className={doLoginOn ? "loginNavIconActive" : "loginNavIcon"}
              >
                <span className="singleIcon">🤙</span>
                <span className="singleIconText">{t("Anmelden")}</span>
              </div>
              <div
                onClick={registrierenAktiv}
                className={doRegisterOn ? "loginNavIconActive" : "loginNavIcon"}
              >
                <span className="singleIcon">👋</span>
                <span className="singleIconText">{t("Registrieren")}</span>
              </div>
              <div
                onClick={passwortVergessenAktiv}
                className={
                  doForgotPassword ? "loginNavIconActive" : "loginNavIcon"
                }
              >
                <span className="singleIcon">❓</span>
                <span className="singleIconText">{t("Hilfe")}</span>
              </div>
            </div>
            <Login
              registrierenAktiv={registrierenAktiv}
              passwortVergessenAktiv={passwortVergessenAktiv}
              anmeldungErfolgreich={anmeldungErfolgreich}
            />
          </Modal.Body>
        ) : (
          ""
        )}
        {doRegisterOn ? (
          <Modal.Body className="p-0 loginRegister loginModal">
            <div className="loginRegisterIcons">
              <div
                onClick={anmeldenAktiv}
                className={doLoginOn ? "loginNavIconActive" : "loginNavIcon"}
              >
                <span className="singleIcon">🤙</span>
                <span className="singleIconText">{t("Anmelden")}</span>
              </div>
              <div
                onClick={registrierenAktiv}
                className={doRegisterOn ? "loginNavIconActive" : "loginNavIcon"}
              >
                <span className="singleIcon">👋</span>
                <span className="singleIconText">{t("Registrieren")}</span>
              </div>
              <div
                onClick={passwortVergessenAktiv}
                className={
                  doForgotPassword ? "loginNavIconActive" : "loginNavIcon"
                }
              >
                <span className="singleIcon">❓</span>
                <span className="singleIconText">{t("Hilfe")}</span>
              </div>
            </div>
            <Register
              DB_TopBarLogoImageSrc={DB_TopBarLogoImageSrc}
              anmeldenAktiv={anmeldenAktiv}
              passwortVergessenAktiv={passwortVergessenAktiv}
            />
          </Modal.Body>
        ) : (
          ""
        )}

        {doForgotPassword ? (
          <Modal.Body className="p-0 loginRegister loginModal">
            <div className="loginRegisterIcons">
              <div
                onClick={anmeldenAktiv}
                className={doLoginOn ? "loginNavIconActive" : "loginNavIcon"}
              >
                <span className="singleIcon">🤙</span>
                <span className="singleIconText">{t("Anmelden")}</span>
              </div>
              <div
                onClick={registrierenAktiv}
                className={doRegisterOn ? "loginNavIconActive" : "loginNavIcon"}
              >
                <span className="singleIcon">👋</span>
                <span className="singleIconText">{t("Registrieren")}</span>
              </div>
              <div
                onClick={passwortVergessenAktiv}
                className={
                  doForgotPassword ? "loginNavIconActive" : "loginNavIcon"
                }
              >
                <span className="singleIcon">❓</span>
                <span className="singleIconText">{t("Hilfe")}</span>
              </div>
            </div>
            <ForgetPassword DB_TopBarLogoImageSrc={DB_TopBarLogoImageSrc} />
          </Modal.Body>
        ) : (
          ""
        )}
        <Button className="buttonModalClose" onClick={toggleSignIn}>
          {t("Schliessen")}
        </Button>
      </Modal>
    </>
  );
};

export default BodyProductDetail;
