import cookie from "js-cookie";

// set in cookie
export const setCookie = (key, value) => {
  cookie.set(key, value, {
    expires: 30, // 30days
  });
};

// remove from cookie
export const removeCookie = (key) => {
  cookie.remove(key, {
    expires: 1, // 1day
  });
};

// get from cookie such as stroed token
// will be useful when we need to make request to server with token
export const getCookie = (key) => {
  return cookie.get(key);
};

// set in localstorage
export const setLocalStorage = (key, value) => {
  localStorage.setItem(key, JSON.stringify(value));
};

// remove from localstorage
export const removeLocalStorage = (key) => {
  localStorage.removeItem(key);
};

// authenticate user by passing data to cookie and localstorage during signin
export const authenticate = (response) => {
  // response we get after successful user sign in

  setCookie("token", response.data.token);
  setLocalStorage("user", response.data.user);
};

// access user info from localstorage
export const isAuth = () => {
  const cookieChecked = getCookie("token");
  if (cookieChecked) {
    if (localStorage.getItem("user")) {
      return JSON.parse(localStorage.getItem("user"));
    } else {
      return false;
    }
  }
};

// access user info from localstorage
export const getIdOfUser = () => {
  const cookieChecked = getCookie("token");
  if (cookieChecked) {
    if (localStorage.getItem("user")) {
      const tempUser = JSON.parse(localStorage.getItem("user"));
      return tempUser.id;
    } else {
      return false;
    }
  }
};

export const signout = (next) => {
  removeCookie("token");
  removeLocalStorage("user");
};

export const updateUser = (response, next) => {
  let auth = JSON.parse(localStorage.getItem("user"));
  auth = response.data;
  localStorage.setItem("user", JSON.stringify(auth));

  next();
};
