import SideBarRightSocialIconsText from "./SideBarRightSocialIconsText";
import SideBarRightSocialIconsTitel from "./SideBarRightSocialIconsTitel";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";

const SideBarRightSocialIcons = ({
  SideBarLeft_ContainerSocial_Farbe_Groesse_Stil_OnMouseOverAndOut,
  SideBarLeft_ContainerSocial_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut,
  SideBarLeft_ContainerSocial_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut,
  SideBarLeft_ContainerSocial_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut,

  SideBarLeft_AlleSocial_Farbe_Groesse_Stil_OnMouseOverAndOut,
  SideBarLeft_AlleSocial_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut,
  SideBarLeft_AlleSocial_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut,
  SideBarLeft_AlleSocial_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut,

  SideBarLeft_SocialItem_Farbe_Groesse_Stil_OnMouseOverAndOut,
  SideBarLeft_SocialItem_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut,
  SideBarLeft_SocialItem_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut,
  SideBarLeft_SocialItem_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut,

  DB_LeftSidebar_MenuContainer_Social_DDBackgroundColor,
  DB_LeftSidebar_MenuContainer_Social_DDBorderTop,
  DB_LeftSidebar_MenuContainer_Social_DDBorderStyleTop,
  DB_LeftSidebar_MenuContainer_Social_DDBorderColorTop,
  DB_LeftSidebar_MenuContainer_Social_DDBorderBottom,
  DB_LeftSidebar_MenuContainer_Social_DDBorderStyleBottom,
  DB_LeftSidebar_MenuContainer_Social_DDBorderColorBottom,
  DB_LeftSidebar_MenuContainer_Social_DDBorderLeft,
  DB_LeftSidebar_MenuContainer_Social_DDBorderStyleLeft,
  DB_LeftSidebar_MenuContainer_Social_DDBorderColorLeft,
  DB_LeftSidebar_MenuContainer_Social_DDBorderRight,
  DB_LeftSidebar_MenuContainer_Social_DDBorderStyleRight,
  DB_LeftSidebar_MenuContainer_Social_DDBorderColorRight,
  DB_LeftSidebar_MenuItem_MenuContainer_Social_DDBorderTopRightRadius,
  DB_LeftSidebar_MenuItem_MenuContainer_Social_DDBorderTopLeftRadius,
  DB_LeftSidebar_MenuItem_MenuContainer_Social_DDBorderBottomRightRadius,
  DB_LeftSidebar_MenuItem_MenuContainer_Social_DDBorderBottomLeftRadius,
  DB_LeftSidebar_MenuItem_MenuContainer_Social_DDpaddingLeft,
  DB_LeftSidebar_MenuItem_MenuContainer_Social_DDpaddingRight,
  DB_LeftSidebar_MenuItem_MenuContainer_Social_DDpaddingTop,
  DB_LeftSidebar_MenuItem_MenuContainer_Social_DDpaddingBottom,
  DB_LeftSidebar_MenuItem_MenuContainer_Social_DDShadowHorizontal,
  DB_LeftSidebar_MenuItem_MenuContainer_Social_DDShadowVertical,
  DB_LeftSidebar_MenuItem_MenuContainer_Social_DDShadowBlur,
  DB_LeftSidebar_MenuItem_MenuContainer_Social_DDShadowColor,

  DB_LeftSidebar_MenuContainer_Social_DDHover_BackgroundColor,
  DB_LeftSidebar_MenuContainer_Social_DDHover_BorderTop,
  DB_LeftSidebar_MenuContainer_Social_DDHover_BorderStyleTop,
  DB_LeftSidebar_MenuContainer_Social_DDHover_BorderColorTop,
  DB_LeftSidebar_MenuContainer_Social_DDHover_BorderBottom,
  DB_LeftSidebar_MenuContainer_Social_DDHover_BorderStyleBottom,
  DB_LeftSidebar_MenuContainer_Social_DDHover_BorderColorBottom,
  DB_LeftSidebar_MenuContainer_Social_DDHover_BorderLeft,
  DB_LeftSidebar_MenuContainer_Social_DDHover_BorderStyleLeft,
  DB_LeftSidebar_MenuContainer_Social_DDHover_BorderColorLeft,
  DB_LeftSidebar_MenuContainer_Social_DDHover_BorderRight,
  DB_LeftSidebar_MenuContainer_Social_DDHover_BorderStyleRight,
  DB_LeftSidebar_MenuContainer_Social_DDHover_BorderColorRight,
  DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_BorderTopRightRadius,
  DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_BorderTopLeftRadius,
  DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_BorderBottomRightRadius,
  DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_BorderBottomLeftRadius,
  DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_paddingLeft,
  DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_paddingRight,
  DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_paddingTop,
  DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_paddingBottom,
  DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_ShadowHorizontal,
  DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_ShadowVertical,
  DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_ShadowBlur,
  DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_ShadowColor,

  DB_fontFace_SocialTitelFontFamily_FontFamilyName,
  DB_fontFace_SocialTitelFontFamily_FontFamilySrc,
  DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_FontWeight,
  DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_FontColor,
  DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_FontSize,
  DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BackgroundColor,
  DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_TextAlign,
  DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderTop,
  DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderStyleTop,
  DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderColorTop,
  DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderBottom,
  DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderStyleBottom,
  DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderColorBottom,
  DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderLeft,
  DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderStyleLeft,
  DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderColorLeft,
  DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderRight,
  DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderStyleRight,
  DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderColorRight,

  DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderTopRightRadius,
  DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderTopLeftRadius,
  DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderBottomRightRadius,
  DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderBottomLeftRadius,
  DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_marginLeftRight,
  DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_marginTopBottom,
  DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_paddingLeftRight,
  DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_paddingTopBottom,
  DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_letterSpacing,
  DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowHorizontal,
  DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowVertical,
  DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowBlur,
  DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowColor,
  DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_TextDecoration,
  DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_TextDecorationStyle,

  DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_FontWeight,
  DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_FontColor,
  DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_FontSize,
  DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BackgroundColor,
  DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_TextAlign,
  DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderTop,
  DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderStyleTop,
  DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderColorTop,
  DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderBottom,
  DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderStyleBottom,
  DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderColorBottom,
  DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderLeft,
  DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderStyleLeft,
  DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderColorLeft,
  DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderRight,
  DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderStyleRight,
  DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderColorRight,
  DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderTopRightRadius,
  DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderTopLeftRadius,
  DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderBottomRightRadius,
  DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderBottomLeftRadius,
  DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_marginLeftRight_Hover,
  DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_marginTopBottom_Hover,
  DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_paddingLeftRight_Hover,
  DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_paddingTopBottom_Hover,
  DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_letterSpacing_Hover,
  DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowHorizontal_Hover,
  DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowVertical_Hover,
  DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowBlur_Hover,
  DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowColor_Hover,
  DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_TextDecoration_Hover,
  DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_TextDecorationStyle_Hover,

  DB_fontFace_SocialTextFontFamily_FontFamilyName,
  DB_fontFace_SocialTextFontFamily_FontFamilySrc,
  DB_LeftSidebar_MenuItem_SocialText_FontWeight,
  DB_LeftSidebar_MenuItem_SocialText_FontColor,
  DB_LeftSidebar_MenuItem_SocialText_FontSize,
  DB_LeftSidebar_MenuItem_SocialText_BackgroundColor,
  DB_LeftSidebar_MenuItem_SocialText_BorderTop,
  DB_LeftSidebar_MenuItem_SocialText_BorderStyleTop,
  DB_LeftSidebar_MenuItem_SocialText_BorderColorTop,
  DB_LeftSidebar_MenuItem_SocialText_BorderBottom,
  DB_LeftSidebar_MenuItem_SocialText_BorderStyleBottom,
  DB_LeftSidebar_MenuItem_SocialText_BorderColorBottom,
  DB_LeftSidebar_MenuItem_SocialText_BorderLeft,
  DB_LeftSidebar_MenuItem_SocialText_BorderStyleLeft,
  DB_LeftSidebar_MenuItem_SocialText_BorderColorLeft,
  DB_LeftSidebar_MenuItem_SocialText_BorderRight,
  DB_LeftSidebar_MenuItem_SocialText_BorderStyleRight,
  DB_LeftSidebar_MenuItem_SocialText_BorderColorRight,
  DB_LeftSidebar_MenuItem_SocialText_BorderTopRightRadius,
  DB_LeftSidebar_MenuItem_SocialText_BorderTopLeftRadius,
  DB_LeftSidebar_MenuItem_SocialText_BorderBottomRightRadius,
  DB_LeftSidebar_MenuItem_SocialText_BorderBottomLeftRadius,
  DB_LeftSidebar_MenuItem_SocialText_marginLeftRight,
  DB_LeftSidebar_MenuItem_SocialText_marginTopBottom,
  DB_LeftSidebar_MenuItem_SocialText_paddingLeftRight,
  DB_LeftSidebar_MenuItem_SocialText_paddingTopBottom,
  DB_LeftSidebar_MenuItem_SocialText_letterSpacing,
  DB_LeftSidebar_MenuItem_SocialText_ShadowHorizontal,
  DB_LeftSidebar_MenuItem_SocialText_ShadowVertical,
  DB_LeftSidebar_MenuItem_SocialText_ShadowBlur,
  DB_LeftSidebar_MenuItem_SocialText_ShadowColor,
  DB_LeftSidebar_MenuItem_SocialText_TextAlign,
  DB_LeftSidebar_MenuItem_SocialText_TextDecoration,
  DB_LeftSidebar_MenuItem_SocialText_TextDecorationStyle,

  DB_LeftSidebar_MenuItem_SocialText_Hover_FontWeight,
  DB_LeftSidebar_MenuItem_SocialText_Hover_FontColor,
  DB_LeftSidebar_MenuItem_SocialText_Hover_FontSize,
  DB_LeftSidebar_MenuItem_SocialText_Hover_BackgroundColor,
  DB_LeftSidebar_MenuItem_SocialText_Hover_TextAlign,
  DB_LeftSidebar_MenuItem_SocialText_Hover_BorderTop,
  DB_LeftSidebar_MenuItem_SocialText_Hover_BorderStyleTop,
  DB_LeftSidebar_MenuItem_SocialText_Hover_BorderColorTop,
  DB_LeftSidebar_MenuItem_SocialText_Hover_BorderBottom,
  DB_LeftSidebar_MenuItem_SocialText_Hover_BorderStyleBottom,
  DB_LeftSidebar_MenuItem_SocialText_Hover_BorderColorBottom,
  DB_LeftSidebar_MenuItem_SocialText_Hover_BorderLeft,
  DB_LeftSidebar_MenuItem_SocialText_Hover_BorderStyleLeft,
  DB_LeftSidebar_MenuItem_SocialText_Hover_BorderColorLeft,
  DB_LeftSidebar_MenuItem_SocialText_Hover_BorderRight,
  DB_LeftSidebar_MenuItem_SocialText_Hover_BorderStyleRight,
  DB_LeftSidebar_MenuItem_SocialText_Hover_BorderColorRight,
  DB_LeftSidebar_MenuItem_SocialText_Hover_BorderTopRightRadius,
  DB_LeftSidebar_MenuItem_SocialText_Hover_BorderTopLeftRadius,
  DB_LeftSidebar_MenuItem_SocialText_Hover_BorderBottomRightRadius,
  DB_LeftSidebar_MenuItem_SocialText_Hover_BorderBottomLeftRadius,
  DB_LeftSidebar_MenuItem_SocialText_marginLeftRight_Hover,
  DB_LeftSidebar_MenuItem_SocialText_marginTopBottom_Hover,
  DB_LeftSidebar_MenuItem_SocialText_paddingLeftRight_Hover,
  DB_LeftSidebar_MenuItem_SocialText_paddingTopBottom_Hover,
  DB_LeftSidebar_MenuItem_SocialText_letterSpacing_Hover,
  DB_LeftSidebar_MenuItem_SocialText_ShadowHorizontal_Hover,
  DB_LeftSidebar_MenuItem_SocialText_ShadowVertical_Hover,
  DB_LeftSidebar_MenuItem_SocialText_ShadowBlur_Hover,
  DB_LeftSidebar_MenuItem_SocialText_ShadowColor_Hover,
  DB_LeftSidebar_MenuItem_SocialText_TextDecoration_Hover,
  DB_LeftSidebar_MenuItem_SocialText_TextDecorationStyle_Hover,
}) => {
  const { t, i18n } = useTranslation();

  const useStyles = createUseStyles({
    rightSideSocialIcons: {
      backgroundColor: `${
        SideBarLeft_ContainerSocial_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut
          ? "rgba(255,0,0,0.5)"
          : SideBarLeft_ContainerSocial_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut
          ? "rgba(255,0,0,0.3)"
          : DB_LeftSidebar_MenuContainer_Social_DDBackgroundColor
      }`,

      borderTop: `${
        SideBarLeft_ContainerSocial_Farbe_Groesse_Stil_OnMouseOverAndOut
          ? "3"
          : SideBarLeft_ContainerSocial_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
          ? "3"
          : DB_LeftSidebar_MenuContainer_Social_DDBorderTop
      }px
          ${
            SideBarLeft_ContainerSocial_Farbe_Groesse_Stil_OnMouseOverAndOut
              ? "solid"
              : SideBarLeft_ContainerSocial_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
              ? "dotted"
              : DB_LeftSidebar_MenuContainer_Social_DDBorderStyleTop
          }
          ${
            SideBarLeft_ContainerSocial_Farbe_Groesse_Stil_OnMouseOverAndOut
              ? "red"
              : SideBarLeft_ContainerSocial_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
              ? "red"
              : DB_LeftSidebar_MenuContainer_Social_DDBorderColorTop
          }`,
      borderBottom: `${
        SideBarLeft_ContainerSocial_Farbe_Groesse_Stil_OnMouseOverAndOut
          ? "3"
          : SideBarLeft_ContainerSocial_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
          ? "3"
          : DB_LeftSidebar_MenuContainer_Social_DDBorderBottom
      }px
          ${
            SideBarLeft_ContainerSocial_Farbe_Groesse_Stil_OnMouseOverAndOut
              ? "solid"
              : SideBarLeft_ContainerSocial_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
              ? "dotted"
              : DB_LeftSidebar_MenuContainer_Social_DDBorderStyleBottom
          }
          ${
            SideBarLeft_ContainerSocial_Farbe_Groesse_Stil_OnMouseOverAndOut
              ? "red"
              : SideBarLeft_ContainerSocial_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
              ? "red"
              : DB_LeftSidebar_MenuContainer_Social_DDBorderColorBottom
          }`,
      borderLeft: `${
        SideBarLeft_ContainerSocial_Farbe_Groesse_Stil_OnMouseOverAndOut
          ? "3"
          : SideBarLeft_ContainerSocial_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
          ? "3"
          : DB_LeftSidebar_MenuContainer_Social_DDBorderLeft
      }px
          ${
            SideBarLeft_ContainerSocial_Farbe_Groesse_Stil_OnMouseOverAndOut
              ? "solid"
              : SideBarLeft_ContainerSocial_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
              ? "dotted"
              : DB_LeftSidebar_MenuContainer_Social_DDBorderStyleLeft
          }
          ${
            SideBarLeft_ContainerSocial_Farbe_Groesse_Stil_OnMouseOverAndOut
              ? "red"
              : SideBarLeft_ContainerSocial_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
              ? "red"
              : DB_LeftSidebar_MenuContainer_Social_DDBorderColorLeft
          }`,
      borderRight: `${
        SideBarLeft_ContainerSocial_Farbe_Groesse_Stil_OnMouseOverAndOut
          ? "3"
          : SideBarLeft_ContainerSocial_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
          ? "3"
          : DB_LeftSidebar_MenuContainer_Social_DDBorderRight
      }px
          ${
            SideBarLeft_ContainerSocial_Farbe_Groesse_Stil_OnMouseOverAndOut
              ? "solid"
              : SideBarLeft_ContainerSocial_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
              ? "dotted"
              : DB_LeftSidebar_MenuContainer_Social_DDBorderStyleRight
          }
          ${
            SideBarLeft_ContainerSocial_Farbe_Groesse_Stil_OnMouseOverAndOut
              ? "red"
              : SideBarLeft_ContainerSocial_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
              ? "red"
              : DB_LeftSidebar_MenuContainer_Social_DDBorderColorRight
          }`,
      borderTopRightRadius: `${DB_LeftSidebar_MenuItem_MenuContainer_Social_DDBorderTopRightRadius}px`,
      borderTopLeftRadius: `${DB_LeftSidebar_MenuItem_MenuContainer_Social_DDBorderTopLeftRadius}px`,
      borderBottomRightRadius: `${DB_LeftSidebar_MenuItem_MenuContainer_Social_DDBorderBottomRightRadius}px`,
      borderBottomLeftRadius: `${DB_LeftSidebar_MenuItem_MenuContainer_Social_DDBorderBottomLeftRadius}px`,

      paddingLeft: `${DB_LeftSidebar_MenuItem_MenuContainer_Social_DDpaddingLeft}px`,
      paddingRight: `${DB_LeftSidebar_MenuItem_MenuContainer_Social_DDpaddingRight}px`,
      paddingTop: `${DB_LeftSidebar_MenuItem_MenuContainer_Social_DDpaddingTop}px`,
      paddingBottom: `${DB_LeftSidebar_MenuItem_MenuContainer_Social_DDpaddingBottom}px`,
      boxShadow: `${DB_LeftSidebar_MenuItem_MenuContainer_Social_DDShadowHorizontal}px
          ${DB_LeftSidebar_MenuItem_MenuContainer_Social_DDShadowVertical}px
          ${DB_LeftSidebar_MenuItem_MenuContainer_Social_DDShadowBlur}px
          ${DB_LeftSidebar_MenuItem_MenuContainer_Social_DDShadowColor}`,

      "&:hover": {
        backgroundColor: `${DB_LeftSidebar_MenuContainer_Social_DDHover_BackgroundColor}`,

        borderTop: `${DB_LeftSidebar_MenuContainer_Social_DDHover_BorderTop}px
          ${DB_LeftSidebar_MenuContainer_Social_DDHover_BorderStyleTop}
          ${DB_LeftSidebar_MenuContainer_Social_DDHover_BorderColorTop}`,
        borderBottom: `${DB_LeftSidebar_MenuContainer_Social_DDHover_BorderBottom}px
          ${DB_LeftSidebar_MenuContainer_Social_DDHover_BorderStyleBottom}
          ${DB_LeftSidebar_MenuContainer_Social_DDHover_BorderColorBottom}`,
        borderLeft: `${DB_LeftSidebar_MenuContainer_Social_DDHover_BorderLeft}px
          ${DB_LeftSidebar_MenuContainer_Social_DDHover_BorderStyleLeft}
          ${DB_LeftSidebar_MenuContainer_Social_DDHover_BorderColorLeft}`,
        borderRight: `${DB_LeftSidebar_MenuContainer_Social_DDHover_BorderRight}px
          ${DB_LeftSidebar_MenuContainer_Social_DDHover_BorderStyleRight}
          ${DB_LeftSidebar_MenuContainer_Social_DDHover_BorderColorRight}`,

        borderTopRightRadius: `${DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_BorderTopRightRadius}px`,
        borderTopLeftRadius: `${DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_BorderTopLeftRadius}px`,
        borderBottomRightRradius: `${DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_BorderBottomRightRadius}px`,
        borderBottomLeftRadius: `${DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_BorderBottomLeftRadius}px`,
        paddingLeft: `${DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_paddingLeft}px`,
        paddingRight: `${DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_paddingRight}px`,
        paddingTop: `${DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_paddingTop}px`,
        paddingBottom: `${DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_paddingBottom}px`,
        boxShadow: `${DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_ShadowHorizontal}px
          ${DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_ShadowVertical}px
          ${DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_ShadowBlur}px
          ${DB_LeftSidebar_MenuItem_MenuContainer_Social_DDHover_ShadowColor}`,
      },
    },
  });

  const classes = useStyles();

  return (
    <>
      <div
        className={`position-relative mt-3 ${classes.rightSideSocialIcons}`}
        data-bs-spy="scroll"
        data-bs-target="#navbar-example3"
        data-bs-offset="0"
        tabIndex="0"
      >
        <div className="category">
          <SideBarRightSocialIconsTitel
            CustomSocialTitel={"Cookies"}
            SideBarLeft_AlleSocial_Farbe_Groesse_Stil_OnMouseOverAndOut={
              SideBarLeft_AlleSocial_Farbe_Groesse_Stil_OnMouseOverAndOut
            }
            SideBarLeft_AlleSocial_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut={
              SideBarLeft_AlleSocial_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
            }
            SideBarLeft_AlleSocial_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut={
              SideBarLeft_AlleSocial_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut
            }
            SideBarLeft_AlleSocial_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut={
              SideBarLeft_AlleSocial_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut
            }
            DB_fontFace_SocialTitelFontFamily_FontFamilyName={
              DB_fontFace_SocialTitelFontFamily_FontFamilyName
            }
            DB_fontFace_SocialTitelFontFamily_FontFamilySrc={
              DB_fontFace_SocialTitelFontFamily_FontFamilySrc
            }
            DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_FontWeight={
              DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_FontWeight
            }
            DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_FontColor={
              DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_FontColor
            }
            DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_FontSize={
              DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_FontSize
            }
            DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BackgroundColor={
              DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BackgroundColor
            }
            DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_TextAlign={
              DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_TextAlign
            }
            DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderTop={
              DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderTop
            }
            DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderStyleTop={
              DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderStyleTop
            }
            DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderColorTop={
              DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderColorTop
            }
            DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderBottom={
              DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderBottom
            }
            DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderStyleBottom={
              DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderStyleBottom
            }
            DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderColorBottom={
              DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderColorBottom
            }
            DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderLeft={
              DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderLeft
            }
            DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderStyleLeft={
              DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderStyleLeft
            }
            DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderColorLeft={
              DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderColorLeft
            }
            DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderRight={
              DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderRight
            }
            DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderStyleRight={
              DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderStyleRight
            }
            DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderColorRight={
              DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderColorRight
            }
            DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderTopRightRadius={
              DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderTopRightRadius
            }
            DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderTopLeftRadius={
              DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderTopLeftRadius
            }
            DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderBottomRightRadius={
              DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderBottomRightRadius
            }
            DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderBottomLeftRadius={
              DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_BorderBottomLeftRadius
            }
            DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_marginLeftRight={
              DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_marginLeftRight
            }
            DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_marginTopBottom={
              DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_marginTopBottom
            }
            DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_paddingLeftRight={
              DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_paddingLeftRight
            }
            DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_paddingTopBottom={
              DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_paddingTopBottom
            }
            DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_letterSpacing={
              DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_letterSpacing
            }
            DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowHorizontal={
              DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowHorizontal
            }
            DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowVertical={
              DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowVertical
            }
            DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowBlur={
              DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowBlur
            }
            DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowColor={
              DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowColor
            }
            DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_TextDecoration={
              DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_TextDecoration
            }
            DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_TextDecorationStyle={
              DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_TextDecorationStyle
            }
            DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_FontWeight={
              DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_FontWeight
            }
            DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_FontColor={
              DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_FontColor
            }
            DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_FontSize={
              DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_FontSize
            }
            DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BackgroundColor={
              DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BackgroundColor
            }
            DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_TextAlign={
              DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_TextAlign
            }
            DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderTop={
              DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderTop
            }
            DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderStyleTop={
              DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderStyleTop
            }
            DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderColorTop={
              DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderColorTop
            }
            DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderBottom={
              DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderBottom
            }
            DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderStyleBottom={
              DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderStyleBottom
            }
            DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderColorBottom={
              DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderColorBottom
            }
            DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderLeft={
              DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderLeft
            }
            DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderStyleLeft={
              DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderStyleLeft
            }
            DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderColorLeft={
              DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderColorLeft
            }
            DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderRight={
              DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderRight
            }
            DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderStyleRight={
              DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderStyleRight
            }
            DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderColorRight={
              DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderColorRight
            }
            DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderTopRightRadius={
              DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderTopRightRadius
            }
            DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderTopLeftRadius={
              DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderTopLeftRadius
            }
            DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderBottomRightRadius={
              DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderBottomRightRadius
            }
            DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderBottomLeftRadius={
              DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_Hover_BorderBottomLeftRadius
            }
            DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_marginLeftRight_Hover={
              DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_marginLeftRight_Hover
            }
            DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_marginTopBottom_Hover={
              DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_marginTopBottom_Hover
            }
            DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_paddingLeftRight_Hover={
              DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_paddingLeftRight_Hover
            }
            DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_paddingTopBottom_Hover={
              DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_paddingTopBottom_Hover
            }
            DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_letterSpacing_Hover={
              DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_letterSpacing_Hover
            }
            DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowHorizontal_Hover={
              DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowHorizontal_Hover
            }
            DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowVertical_Hover={
              DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowVertical_Hover
            }
            DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowBlur_Hover={
              DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowBlur_Hover
            }
            DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowColor_Hover={
              DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_ShadowColor_Hover
            }
            DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_TextDecoration_Hover={
              DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_TextDecoration_Hover
            }
            DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_TextDecorationStyle_Hover={
              DB_LeftSidebar_MenuItem_TitelSocialTitle_EE_TextDecorationStyle_Hover
            }
          />

          <SideBarRightSocialIconsText
            CustomSocialMessage={t("WirBenutzenCookies")}
            SideBarLeft_SocialItem_Farbe_Groesse_Stil_OnMouseOverAndOut={
              SideBarLeft_SocialItem_Farbe_Groesse_Stil_OnMouseOverAndOut
            }
            SideBarLeft_SocialItem_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut={
              SideBarLeft_SocialItem_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
            }
            SideBarLeft_SocialItem_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut={
              SideBarLeft_SocialItem_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut
            }
            SideBarLeft_SocialItem_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut={
              SideBarLeft_SocialItem_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut
            }
            DB_fontFace_SocialTextFontFamily_FontFamilyName={
              DB_fontFace_SocialTextFontFamily_FontFamilyName
            }
            DB_fontFace_SocialTextFontFamily_FontFamilySrc={
              DB_fontFace_SocialTextFontFamily_FontFamilySrc
            }
            DB_LeftSidebar_MenuItem_SocialText_FontWeight={
              DB_LeftSidebar_MenuItem_SocialText_FontWeight
            }
            DB_LeftSidebar_MenuItem_SocialText_FontColor={
              DB_LeftSidebar_MenuItem_SocialText_FontColor
            }
            DB_LeftSidebar_MenuItem_SocialText_FontSize={
              DB_LeftSidebar_MenuItem_SocialText_FontSize
            }
            DB_LeftSidebar_MenuItem_SocialText_BackgroundColor={
              DB_LeftSidebar_MenuItem_SocialText_BackgroundColor
            }
            DB_LeftSidebar_MenuItem_SocialText_BorderTop={
              DB_LeftSidebar_MenuItem_SocialText_BorderTop
            }
            DB_LeftSidebar_MenuItem_SocialText_BorderStyleTop={
              DB_LeftSidebar_MenuItem_SocialText_BorderStyleTop
            }
            DB_LeftSidebar_MenuItem_SocialText_BorderColorTop={
              DB_LeftSidebar_MenuItem_SocialText_BorderColorTop
            }
            DB_LeftSidebar_MenuItem_SocialText_BorderBottom={
              DB_LeftSidebar_MenuItem_SocialText_BorderBottom
            }
            DB_LeftSidebar_MenuItem_SocialText_BorderStyleBottom={
              DB_LeftSidebar_MenuItem_SocialText_BorderStyleBottom
            }
            DB_LeftSidebar_MenuItem_SocialText_BorderColorBottom={
              DB_LeftSidebar_MenuItem_SocialText_BorderColorBottom
            }
            DB_LeftSidebar_MenuItem_SocialText_BorderLeft={
              DB_LeftSidebar_MenuItem_SocialText_BorderLeft
            }
            DB_LeftSidebar_MenuItem_SocialText_BorderStyleLeft={
              DB_LeftSidebar_MenuItem_SocialText_BorderStyleLeft
            }
            DB_LeftSidebar_MenuItem_SocialText_BorderColorLeft={
              DB_LeftSidebar_MenuItem_SocialText_BorderColorLeft
            }
            DB_LeftSidebar_MenuItem_SocialText_BorderRight={
              DB_LeftSidebar_MenuItem_SocialText_BorderRight
            }
            DB_LeftSidebar_MenuItem_SocialText_BorderStyleRight={
              DB_LeftSidebar_MenuItem_SocialText_BorderStyleRight
            }
            DB_LeftSidebar_MenuItem_SocialText_BorderColorRight={
              DB_LeftSidebar_MenuItem_SocialText_BorderColorRight
            }
            DB_LeftSidebar_MenuItem_SocialText_BorderTopRightRadius={
              DB_LeftSidebar_MenuItem_SocialText_BorderTopRightRadius
            }
            DB_LeftSidebar_MenuItem_SocialText_BorderTopLeftRadius={
              DB_LeftSidebar_MenuItem_SocialText_BorderTopLeftRadius
            }
            DB_LeftSidebar_MenuItem_SocialText_BorderBottomRightRadius={
              DB_LeftSidebar_MenuItem_SocialText_BorderBottomRightRadius
            }
            DB_LeftSidebar_MenuItem_SocialText_BorderBottomLeftRadius={
              DB_LeftSidebar_MenuItem_SocialText_BorderBottomLeftRadius
            }
            DB_LeftSidebar_MenuItem_SocialText_marginLeftRight={
              DB_LeftSidebar_MenuItem_SocialText_marginLeftRight
            }
            DB_LeftSidebar_MenuItem_SocialText_marginTopBottom={
              DB_LeftSidebar_MenuItem_SocialText_marginTopBottom
            }
            DB_LeftSidebar_MenuItem_SocialText_paddingLeftRight={
              DB_LeftSidebar_MenuItem_SocialText_paddingLeftRight
            }
            DB_LeftSidebar_MenuItem_SocialText_paddingTopBottom={
              DB_LeftSidebar_MenuItem_SocialText_paddingTopBottom
            }
            DB_LeftSidebar_MenuItem_SocialText_letterSpacing={
              DB_LeftSidebar_MenuItem_SocialText_letterSpacing
            }
            DB_LeftSidebar_MenuItem_SocialText_ShadowHorizontal={
              DB_LeftSidebar_MenuItem_SocialText_ShadowHorizontal
            }
            DB_LeftSidebar_MenuItem_SocialText_ShadowVertical={
              DB_LeftSidebar_MenuItem_SocialText_ShadowVertical
            }
            DB_LeftSidebar_MenuItem_SocialText_ShadowBlur={
              DB_LeftSidebar_MenuItem_SocialText_ShadowBlur
            }
            DB_LeftSidebar_MenuItem_SocialText_ShadowColor={
              DB_LeftSidebar_MenuItem_SocialText_ShadowColor
            }
            DB_LeftSidebar_MenuItem_SocialText_TextAlign={
              DB_LeftSidebar_MenuItem_SocialText_TextAlign
            }
            DB_LeftSidebar_MenuItem_SocialText_TextDecoration={
              DB_LeftSidebar_MenuItem_SocialText_TextDecoration
            }
            DB_LeftSidebar_MenuItem_SocialText_TextDecorationStyle={
              DB_LeftSidebar_MenuItem_SocialText_TextDecorationStyle
            }
            DB_LeftSidebar_MenuItem_SocialText_Hover_FontWeight={
              DB_LeftSidebar_MenuItem_SocialText_Hover_FontWeight
            }
            DB_LeftSidebar_MenuItem_SocialText_Hover_FontColor={
              DB_LeftSidebar_MenuItem_SocialText_Hover_FontColor
            }
            DB_LeftSidebar_MenuItem_SocialText_Hover_FontSize={
              DB_LeftSidebar_MenuItem_SocialText_Hover_FontSize
            }
            DB_LeftSidebar_MenuItem_SocialText_Hover_BackgroundColor={
              DB_LeftSidebar_MenuItem_SocialText_Hover_BackgroundColor
            }
            DB_LeftSidebar_MenuItem_SocialText_Hover_TextAlign={
              DB_LeftSidebar_MenuItem_SocialText_Hover_TextAlign
            }
            DB_LeftSidebar_MenuItem_SocialText_Hover_BorderTop={
              DB_LeftSidebar_MenuItem_SocialText_Hover_BorderTop
            }
            DB_LeftSidebar_MenuItem_SocialText_Hover_BorderStyleTop={
              DB_LeftSidebar_MenuItem_SocialText_Hover_BorderStyleTop
            }
            DB_LeftSidebar_MenuItem_SocialText_Hover_BorderColorTop={
              DB_LeftSidebar_MenuItem_SocialText_Hover_BorderColorTop
            }
            DB_LeftSidebar_MenuItem_SocialText_Hover_BorderBottom={
              DB_LeftSidebar_MenuItem_SocialText_Hover_BorderBottom
            }
            DB_LeftSidebar_MenuItem_SocialText_Hover_BorderStyleBottom={
              DB_LeftSidebar_MenuItem_SocialText_Hover_BorderStyleBottom
            }
            DB_LeftSidebar_MenuItem_SocialText_Hover_BorderColorBottom={
              DB_LeftSidebar_MenuItem_SocialText_Hover_BorderColorBottom
            }
            DB_LeftSidebar_MenuItem_SocialText_Hover_BorderLeft={
              DB_LeftSidebar_MenuItem_SocialText_Hover_BorderLeft
            }
            DB_LeftSidebar_MenuItem_SocialText_Hover_BorderStyleLeft={
              DB_LeftSidebar_MenuItem_SocialText_Hover_BorderStyleLeft
            }
            DB_LeftSidebar_MenuItem_SocialText_Hover_BorderColorLeft={
              DB_LeftSidebar_MenuItem_SocialText_Hover_BorderColorLeft
            }
            DB_LeftSidebar_MenuItem_SocialText_Hover_BorderRight={
              DB_LeftSidebar_MenuItem_SocialText_Hover_BorderRight
            }
            DB_LeftSidebar_MenuItem_SocialText_Hover_BorderStyleRight={
              DB_LeftSidebar_MenuItem_SocialText_Hover_BorderStyleRight
            }
            DB_LeftSidebar_MenuItem_SocialText_Hover_BorderColorRight={
              DB_LeftSidebar_MenuItem_SocialText_Hover_BorderColorRight
            }
            DB_LeftSidebar_MenuItem_SocialText_Hover_BorderTopRightRadius={
              DB_LeftSidebar_MenuItem_SocialText_Hover_BorderTopRightRadius
            }
            DB_LeftSidebar_MenuItem_SocialText_Hover_BorderTopLeftRadius={
              DB_LeftSidebar_MenuItem_SocialText_Hover_BorderTopLeftRadius
            }
            DB_LeftSidebar_MenuItem_SocialText_Hover_BorderBottomRightRadius={
              DB_LeftSidebar_MenuItem_SocialText_Hover_BorderBottomRightRadius
            }
            DB_LeftSidebar_MenuItem_SocialText_Hover_BorderBottomLeftRadius={
              DB_LeftSidebar_MenuItem_SocialText_Hover_BorderBottomLeftRadius
            }
            DB_LeftSidebar_MenuItem_SocialText_marginLeftRight_Hover={
              DB_LeftSidebar_MenuItem_SocialText_marginLeftRight_Hover
            }
            DB_LeftSidebar_MenuItem_SocialText_marginTopBottom_Hover={
              DB_LeftSidebar_MenuItem_SocialText_marginTopBottom_Hover
            }
            DB_LeftSidebar_MenuItem_SocialText_paddingLeftRight_Hover={
              DB_LeftSidebar_MenuItem_SocialText_paddingLeftRight_Hover
            }
            DB_LeftSidebar_MenuItem_SocialText_paddingTopBottom_Hover={
              DB_LeftSidebar_MenuItem_SocialText_paddingTopBottom_Hover
            }
            DB_LeftSidebar_MenuItem_SocialText_letterSpacing_Hover={
              DB_LeftSidebar_MenuItem_SocialText_letterSpacing_Hover
            }
            DB_LeftSidebar_MenuItem_SocialText_ShadowHorizontal_Hover={
              DB_LeftSidebar_MenuItem_SocialText_ShadowHorizontal_Hover
            }
            DB_LeftSidebar_MenuItem_SocialText_ShadowVertical_Hover={
              DB_LeftSidebar_MenuItem_SocialText_ShadowVertical_Hover
            }
            DB_LeftSidebar_MenuItem_SocialText_ShadowBlur_Hover={
              DB_LeftSidebar_MenuItem_SocialText_ShadowBlur_Hover
            }
            DB_LeftSidebar_MenuItem_SocialText_ShadowColor_Hover={
              DB_LeftSidebar_MenuItem_SocialText_ShadowColor_Hover
            }
            DB_LeftSidebar_MenuItem_SocialText_TextDecoration_Hover={
              DB_LeftSidebar_MenuItem_SocialText_TextDecoration_Hover
            }
            DB_LeftSidebar_MenuItem_SocialText_TextDecorationStyle_Hover={
              DB_LeftSidebar_MenuItem_SocialText_TextDecorationStyle_Hover
            }
          />
        </div>
      </div>
    </>
  );
};

export default SideBarRightSocialIcons;
