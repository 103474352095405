// @flow
import React, { useState } from "react";
import { Button, InputGroup, Form } from "react-bootstrap";
import axios from "axios";
import { toast, Zoom } from "react-toastify";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import "react-toastify/dist/ReactToastify.min.css";

const ForgetPassword = (props) => {
  const [values, setValues] = useState({
    email: "",
    buttonText: "Request password reset link",
  });
  const [searchParams, setSearchParams] = useSearchParams();
  const { email, buttonText } = values;
  const { t } = useTranslation();
  const handleChange = (name) => (event) => {
    setValues({ ...values, [name]: event.target.value });
  };

  const clickSubmit = (event) => {
    event.preventDefault();
    const activeUrl = window.location.origin;
    setValues({ ...values, buttonText: "Submitting" });

    axios({
      method: "PUT",
      url: `${process.env.REACT_APP_PUBLIC_SERVER_URL}/auth/forgot-password`,
      data: { email, url: activeUrl, logo: props.DB_TopBarLogoImageSrc },
    })
      .then((response) => {
        if (response.status === 200) {
          toast.success(response.data.data, {
            toastId: "custom-id-yes",
            position: "top-center",
            autoClose: 14000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            transition: Zoom,
          });
        } else if (response.status === 201) {
          toast.error(response.data.data, {
            toastId: "custom-id-yes",
            position: "top-center",
            autoClose: 7000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            transition: Zoom,
          });
        } else {
          toast.error("Error: Unbekannter Fehler", {
            position: "top-center",
            autoClose: 7000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            transition: Zoom,
          });
        }
      })
      .catch((error) => {
        toast.error("Keine Verbindung zum Server - Index Profile", {
          toastId: "custom-id-yes",
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          transition: Zoom,
        });
      });
  };

  return (
    <>
      <div className="text-center" style={{ height: "40px" }}></div>
      <form className="ps-5 pe-5" action="#">
        <div className="mb-3 text-center">
          <label
            htmlFor="emailaddress"
            className="form-label"
            style={{ color: "white", fontSize: "16px" }}
          >
            {t("Email")}
            <br />
            <small style={{ color: "lightgray", marginBottom: "10px" }}>
              {t("FuerPasswortZuruecksetzung")}
            </small>
          </label>

          <InputGroup className="mb-3">
            <InputGroup.Text className="emailUniIconContainer">
              <span id="emailUniIcon">✉️</span>
            </InputGroup.Text>
            <Form.Control
              aria-label="email"
              aria-describedby="emailUniIcon"
              onChange={handleChange("email")}
              value={email}
              className="form-control inputEmailPw"
              type="email"
              id="emailaddress"
              placeholder={t("Email")}
            />
          </InputGroup>
        </div>

        <div className="mb-1 text-center mt-4">
          <div className="mb-3 text-center">
            <Button onClick={clickSubmit} variant="primary" type="submit">
              {t("PasswortZuruecksetzen")}
            </Button>
          </div>
        </div>
      </form>
    </>
  );
};

export default ForgetPassword;
