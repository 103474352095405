import SideBarRightAlleFilter from "./SideBarRightAllFilter";
import SideBarRightMainFilter from "./SideBarRightMainFilter";
import SideBarRightFilterItem from "./SideBarRightFilterItem";
import { createUseStyles } from "react-jss";

const SideBarRightFiltersOffCanvas = ({
  resetFilterHandler,
  ultraFiltersState,
  filterProduktsHandler,
  isCheck,

  SideBarLeft_ContainerFilter_Farbe_Groesse_Stil_OnMouseOverAndOut,
  SideBarLeft_ContainerFilter_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut,
  SideBarLeft_ContainerFilter_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut,
  SideBarLeft_ContainerFilter_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut,

  SideBarLeft_AlleFilter_Farbe_Groesse_Stil_OnMouseOverAndOut,
  SideBarLeft_AlleFilter_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut,
  SideBarLeft_AlleFilter_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut,
  SideBarLeft_AlleFilter_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut,
  SideBarLeft_AlleFilter_Farbe_Groesse_Stil_Trigger,
  SideBarLeft_AlleFilter_Farbe_Groesse_Stil_Hover_Trigger,
  SideBarLeft_AlleFilter_HintergrundFarbe_Radius_Stil_Trigger,
  SideBarLeft_AlleFilter_HintergrundFarbe_Radius_Stil_Hover_Trigger,

  DB_fontFace_AlleFilterButtonFontFamily_FontFamilyName,
  DB_fontFace_AlleFilterButtonFontFamily_FontFamilySrc,
  DB_LeftSidebar_MenuItem_AlleFilterButtonFF_FontWeight,
  DB_LeftSidebar_MenuItem_AlleFilterButtonFF_FontColor,
  DB_LeftSidebar_MenuItem_AlleFilterButtonFF_FontSize,
  DB_LeftSidebar_MenuItem_AlleFilterButtonFF_BackgroundColor,
  DB_LeftSidebar_MenuItem_AlleFilterButtonFF_TextAlign,
  DB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderTop,
  DB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderStyleTop,
  DB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderColorTop,
  DB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderBottom,
  DB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderStyleBottom,
  DB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderColorBottom,
  DB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderLeft,
  DB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderStyleLeft,
  DB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderColorLeft,
  DB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderRight,
  DB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderStyleRight,
  DB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderColorRight,
  DB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderTopRightRadius,
  DB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderTopLeftRadius,
  DB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderBottomRightRadius,
  DB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderBottomLeftRadius,
  DB_LeftSidebar_MenuItem_AlleFilterButtonFF_marginLeftRight,
  DB_LeftSidebar_MenuItem_AlleFilterButtonFF_marginTopBottom,
  DB_LeftSidebar_MenuItem_AlleFilterButtonFF_paddingLeftRight,
  DB_LeftSidebar_MenuItem_AlleFilterButtonFF_paddingTopBottom,
  DB_LeftSidebar_MenuItem_AlleFilterButtonFF_letterSpacing,
  DB_LeftSidebar_MenuItem_AlleFilterButtonFF_ShadowHorizontal,
  DB_LeftSidebar_MenuItem_AlleFilterButtonFF_ShadowVertical,
  DB_LeftSidebar_MenuItem_AlleFilterButtonFF_ShadowBlur,
  DB_LeftSidebar_MenuItem_AlleFilterButtonFF_ShadowColor,
  DB_LeftSidebar_MenuItem_AlleFilterButtonFF_TextDecoration,
  DB_LeftSidebar_MenuItem_AlleFilterButtonFF_TextDecorationStyle,

  DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_FontWeight,
  DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_FontColor,
  DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_FontSize,
  DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BackgroundColor,
  DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_TextAlign,
  DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderTop,
  DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderStyleTop,
  DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderColorTop,
  DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderBottom,
  DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderStyleBottom,
  DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderColorBottom,
  DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderLeft,
  DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderStyleLeft,
  DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderColorLeft,
  DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderRight,
  DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderStyleRight,
  DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderColorRight,
  DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderTopRightRadius,
  DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderTopLeftRadius,
  DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderBottomRightRadius,
  DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderBottomLeftRadius,
  DB_LeftSidebar_MenuItem_AlleFilterButtonFF_marginLeftRight_Hover,
  DB_LeftSidebar_MenuItem_AlleFilterButtonFF_marginTopBottom_Hover,
  DB_LeftSidebar_MenuItem_AlleFilterButtonFF_paddingLeftRight_Hover,
  DB_LeftSidebar_MenuItem_AlleFilterButtonFF_paddingTopBottom_Hover,
  DB_LeftSidebar_MenuItem_AlleFilterButtonFF_letterSpacing_Hover,
  DB_LeftSidebar_MenuItem_AlleFilterButtonFF_ShadowHorizontal_Hover,
  DB_LeftSidebar_MenuItem_AlleFilterButtonFF_ShadowVertical_Hover,
  DB_LeftSidebar_MenuItem_AlleFilterButtonFF_ShadowBlur_Hover,
  DB_LeftSidebar_MenuItem_AlleFilterButtonFF_ShadowColor_Hover,
  DB_LeftSidebar_MenuItem_AlleFilterButtonFF_TextDecoration_Hover,
  DB_LeftSidebar_MenuItem_AlleFilterButtonFF_TextDecorationStyle_Hover,

  DB_fontFace_FilterHauptTitel_FontFamilyName,
  DB_fontFace_FilterHauptTitel_FontFamilySrc,

  SideBarLeft_HauptFiltertitel_Farbe_Groesse_Stil_OnMouseOverAndOut,
  SideBarLeft_HauptFiltertitel_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut,
  SideBarLeft_HauptFiltertitel_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut,
  SideBarLeft_HauptFiltertitel_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut,

  DB_LeftSidebar_MenuItem_FilterHauptTitel_FontFace,
  DB_LeftSidebar_MenuItem_FilterHauptTitel_FontWeight,
  DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_FontWeight,
  DB_LeftSidebar_MenuItem_FilterHauptTitel_FontColor,
  DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_FontColor,
  DB_LeftSidebar_MenuItem_FilterHauptTitel_FontSize,
  DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_FontSize,

  DB_LeftSidebar_MenuItem_FilterHauptTitel_BackgroundColor,
  DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BackgroundColor,
  DB_LeftSidebar_MenuItem_FilterHauptTitel_TextAlign,
  DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_TextAlign,

  DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderTop,
  DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderTop,
  DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderStyleTop,
  DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderColorTop,
  DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderBottom,
  DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderStyleBottom,
  DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderColorBottom,
  DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderLeft,
  DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderStyleLeft,
  DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderColorLeft,
  DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderRight,
  DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderStyleRight,
  DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderColorRight,

  DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderTopRightRadius,
  DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderTopLeftRadius,
  DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderBottomRightRadius,
  DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderBottomLeftRadius,

  DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderTopRightRadius,
  DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderTopLeftRadius,
  DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderBottomRightRadius,
  DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderBottomLeftRadius,

  DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderStyleTop,
  DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderColorTop,
  DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderBottom,
  DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderStyleBottom,

  DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderColorBottom,
  DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderLeft,
  DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderStyleLeft,
  DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderColorLeft,

  DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderRight,
  DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderStyleRight,
  DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderColorRight,

  DB_LeftSidebar_MenuItem_FilterHauptTitel_marginTopBottom,
  DB_LeftSidebar_MenuItem_FilterHauptTitel_marginLeftRight,
  DB_LeftSidebar_MenuItem_FilterHauptTitel_paddingLeftRight,
  DB_LeftSidebar_MenuItem_FilterHauptTitel_paddingTopBottom,

  DB_LeftSidebar_MenuItem_FilterHauptTitel_letterSpacing,
  DB_LeftSidebar_MenuItem_FilterHauptTitel_ShadowHorizontal,
  DB_LeftSidebar_MenuItem_FilterHauptTitel_ShadowVertical,
  DB_LeftSidebar_MenuItem_FilterHauptTitel_ShadowBlur,
  DB_LeftSidebar_MenuItem_FilterHauptTitel_ShadowColor,
  DB_LeftSidebar_MenuItem_FilterHauptTitel_TextDecoration,
  DB_LeftSidebar_MenuItem_FilterHauptTitel_TextDecorationStyle,

  DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_FontFace,
  DB_LeftSidebar_MenuItem_FilterHauptTitel_marginTopBottom_Hover,
  DB_LeftSidebar_MenuItem_FilterHauptTitel_marginLeftRight_Hover,
  DB_LeftSidebar_MenuItem_FilterHauptTitel_paddingLeftRight_Hover,
  DB_LeftSidebar_MenuItem_FilterHauptTitel_paddingTopBottom_Hover,

  DB_LeftSidebar_MenuItem_FilterHauptTitel_letterSpacing_Hover,
  DB_LeftSidebar_MenuItem_FilterHauptTitel_ShadowHorizontal_Hover,
  DB_LeftSidebar_MenuItem_FilterHauptTitel_ShadowVertical_Hover,
  DB_LeftSidebar_MenuItem_FilterHauptTitel_ShadowBlur_Hover,
  DB_LeftSidebar_MenuItem_FilterHauptTitel_ShadowColor_Hover,
  DB_LeftSidebar_MenuItem_FilterHauptTitel_TextDecoration_Hover,
  DB_LeftSidebar_MenuItem_FilterHauptTitel_TextDecorationStyle_Hover,

  DB_LeftSidebar_MenuContainerFilterGG_BackgroundColor,
  DB_LeftSidebar_MenuContainerFilterGG_BorderTop,
  DB_LeftSidebar_MenuContainerFilterGG_BorderStyleTop,
  DB_LeftSidebar_MenuContainerFilterGG_BorderColorTop,
  DB_LeftSidebar_MenuContainerFilterGG_BorderBottom,
  DB_LeftSidebar_MenuContainerFilterGG_BorderStyleBottom,
  DB_LeftSidebar_MenuContainerFilterGG_BorderColorBottom,
  DB_LeftSidebar_MenuContainerFilterGG_BorderLeft,
  DB_LeftSidebar_MenuContainerFilterGG_BorderStyleLeft,
  DB_LeftSidebar_MenuContainerFilterGG_BorderColorLeft,
  DB_LeftSidebar_MenuContainerFilterGG_BorderRight,
  DB_LeftSidebar_MenuContainerFilterGG_BorderStyleRight,
  DB_LeftSidebar_MenuContainerFilterGG_BorderColorRight,
  DB_LeftSidebar_MenuItem_MenuContainerFilterGG_BorderTopRightRadius,
  DB_LeftSidebar_MenuItem_MenuContainerFilterGG_BorderTopLeftRadius,
  DB_LeftSidebar_MenuItem_MenuContainerFilterGG_BorderBottomRightRadius,
  DB_LeftSidebar_MenuItem_MenuContainerFilterGG_BorderBottomLeftRadius,
  DB_LeftSidebar_MenuItem_MenuContainerFilterGG_paddingLeft,
  DB_LeftSidebar_MenuItem_MenuContainerFilterGG_paddingRight,
  DB_LeftSidebar_MenuItem_MenuContainerFilterGG_paddingTop,
  DB_LeftSidebar_MenuItem_MenuContainerFilterGG_paddingBottom,
  DB_LeftSidebar_MenuItem_MenuContainerFilterGG_ShadowHorizontal,
  DB_LeftSidebar_MenuItem_MenuContainerFilterGG_ShadowVertical,
  DB_LeftSidebar_MenuItem_MenuContainerFilterGG_ShadowBlur,
  DB_LeftSidebar_MenuItem_MenuContainerFilterGG_ShadowColor,

  DB_LeftSidebar_MenuContainerFilterGG_Hover_BackgroundColor,
  DB_LeftSidebar_MenuContainerFilterGG_Hover_BorderTop,
  DB_LeftSidebar_MenuContainerFilterGG_Hover_BorderStyleTop,
  DB_LeftSidebar_MenuContainerFilterGG_Hover_BorderColorTop,
  DB_LeftSidebar_MenuContainerFilterGG_Hover_BorderBottom,
  DB_LeftSidebar_MenuContainerFilterGG_Hover_BorderStyleBottom,
  DB_LeftSidebar_MenuContainerFilterGG_Hover_BorderColorBottom,
  DB_LeftSidebar_MenuContainerFilterGG_Hover_BorderLeft,
  DB_LeftSidebar_MenuContainerFilterGG_Hover_BorderStyleLeft,
  DB_LeftSidebar_MenuContainerFilterGG_Hover_BorderColorLeft,
  DB_LeftSidebar_MenuContainerFilterGG_Hover_BorderRight,
  DB_LeftSidebar_MenuContainerFilterGG_Hover_BorderStyleRight,
  DB_LeftSidebar_MenuContainerFilterGG_Hover_BorderColorRight,
  DB_LeftSidebar_MenuItem_MenuContainerFilterGG_Hover_BorderTopRightRadius,
  DB_LeftSidebar_MenuItem_MenuContainerFilterGG_Hover_BorderTopLeftRadius,
  DB_LeftSidebar_MenuItem_MenuContainerFilterGG_Hover_BorderBottomRightRadius,
  DB_LeftSidebar_MenuItem_MenuContainerFilterGG_Hover_BorderBottomLeftRadius,
  DB_LeftSidebar_MenuItem_MenuContainerFilterGG_Hover_paddingLeft,
  DB_LeftSidebar_MenuItem_MenuContainerFilterGG_Hover_paddingRight,
  DB_LeftSidebar_MenuItem_MenuContainerFilterGG_Hover_paddingTop,
  DB_LeftSidebar_MenuItem_MenuContainerFilterGG_Hover_paddingBottom,
  DB_LeftSidebar_MenuItem_MenuContainerFilterGG_Hover_ShadowHorizontal,
  DB_LeftSidebar_MenuItem_MenuContainerFilterGG_Hover_ShadowVertical,
  DB_LeftSidebar_MenuItem_MenuContainerFilterGG_Hover_ShadowBlur,
  DB_LeftSidebar_MenuItem_MenuContainerFilterGG_Hover_ShadowColor,

  DB_fontFace_FilterItemFontFamily_FontFamilyName,
  DB_fontFace_FilterItemFontFamily_FontFamilySrc,
  DB_LeftSidebar_MenuItem_FilterItem_Hover_FontFace,
  DB_LeftSidebar_MenuItem_FilterItem_FontWeight,
  DB_LeftSidebar_MenuItem_FilterItem_Hover_FontWeight,
  DB_LeftSidebar_MenuItem_FilterItem_FontColor,
  DB_LeftSidebar_MenuItem_FilterItem_Hover_FontColor,
  DB_LeftSidebar_MenuItem_FilterItem_FontSize,
  DB_LeftSidebar_MenuItem_FilterItem_Hover_FontSize,
  SideBarLeft_FilterItem_Farbe_Groesse_Stil_OnMouseOverAndOut,
  SideBarLeft_FilterItem_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut,
  SideBarLeft_FilterItem_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut,
  SideBarLeft_FilterItem_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut,
  DB_LeftSidebar_MenuItem_FilterItem_BackgroundColor,
  DB_LeftSidebar_MenuItem_FilterItem_Hover_BackgroundColor,
  DB_LeftSidebar_MenuItem_FilterItem_TextAlign,
  DB_LeftSidebar_MenuItem_FilterItem_Hover_TextAlign,
  DB_LeftSidebar_MenuItem_FilterItem_BorderTop,
  DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderTop,
  DB_LeftSidebar_MenuItem_FilterItem_BorderStyleTop,
  DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderStyleTop,
  DB_LeftSidebar_MenuItem_FilterItem_BorderColorTop,
  DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderColorTop,
  DB_LeftSidebar_MenuItem_FilterItem_BorderBottom,
  DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderBottom,
  DB_LeftSidebar_MenuItem_FilterItem_BorderStyleBottom,
  DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderStyleBottom,
  DB_LeftSidebar_MenuItem_FilterItem_BorderColorBottom,
  DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderColorBottom,
  DB_LeftSidebar_MenuItem_FilterItem_BorderLeft,
  DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderLeft,
  DB_LeftSidebar_MenuItem_FilterItem_BorderStyleLeft,
  DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderStyleLeft,
  DB_LeftSidebar_MenuItem_FilterItem_BorderColorLeft,
  DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderColorLeft,
  DB_LeftSidebar_MenuItem_FilterItem_BorderRight,
  DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderRight,
  DB_LeftSidebar_MenuItem_FilterItem_BorderStyleRight,
  DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderStyleRight,
  DB_LeftSidebar_MenuItem_FilterItem_BorderColorRight,
  DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderColorRight,
  DB_LeftSidebar_MenuItem_FilterItem_BorderTopRightRadius,
  DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderTopRightRadius,
  DB_LeftSidebar_MenuItem_FilterItem_BorderTopLeftRadius,
  DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderTopLeftRadius,
  DB_LeftSidebar_MenuItem_FilterItem_BorderBottomRightRadius,
  DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderBottomRightRadius,
  DB_LeftSidebar_MenuItem_FilterItem_BorderBottomLeftRadius,
  DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderBottomLeftRadius,
  handleShow_showConfig_LeftSidebar_MenuFilterItem,
  handleShow_showConfig_LeftSidebar_MenuFilterItem_BorderAndColor,
  handleShow_showConfig_LeftSidebar_MenuFilterItem_Hover,
  handleShow_showConfig_LeftSidebar_MenuFilterItem_Hover_BorderAndColor,
  DB_LeftSidebar_MenuItem_FilterItem_marginTopBottom,
  DB_LeftSidebar_MenuItem_FilterItem_marginLeftRight,
  DB_LeftSidebar_MenuItem_FilterItem_paddingLeftRight,
  DB_LeftSidebar_MenuItem_FilterItem_paddingTopBottom,
  DB_LeftSidebar_MenuItem_FilterItem_letterSpacing,
  DB_LeftSidebar_MenuItem_FilterItem_ShadowHorizontal,
  DB_LeftSidebar_MenuItem_FilterItem_ShadowVertical,
  DB_LeftSidebar_MenuItem_FilterItem_ShadowBlur,
  DB_LeftSidebar_MenuItem_FilterItem_ShadowColor,
  DB_LeftSidebar_MenuItem_FilterItem_TextDecoration,
  DB_LeftSidebar_MenuItem_FilterItem_TextDecorationStyle,
  DB_LeftSidebar_MenuItem_FilterItem_marginTopBottom_Hover,
  DB_LeftSidebar_MenuItem_FilterItem_marginLeftRight_Hover,
  DB_LeftSidebar_MenuItem_FilterItem_paddingLeftRight_Hover,
  DB_LeftSidebar_MenuItem_FilterItem_paddingTopBottom_Hover,
  DB_LeftSidebar_MenuItem_FilterItem_letterSpacing_Hover,
  DB_LeftSidebar_MenuItem_FilterItem_ShadowHorizontal_Hover,
  DB_LeftSidebar_MenuItem_FilterItem_ShadowVertical_Hover,
  DB_LeftSidebar_MenuItem_FilterItem_ShadowBlur_Hover,
  DB_LeftSidebar_MenuItem_FilterItem_ShadowColor_Hover,
  DB_LeftSidebar_MenuItem_FilterItem_TextDecoration_Hover,
  DB_LeftSidebar_MenuItem_FilterItem_TextDecorationStyle_Hover,

  backgroundColorActive,
  DB_BackgroundImageForAllBody,
  DB_BackgroundColorOverlayForAllBody,
}) => {
  let zustandTemp = [];
  let garantieTemp = [];
  let markeTemp = [];
  let materialTemp = [];
  let farbeTemp = [];
  let groesseTemp = [];

  const DB_fontFace_FontFamilyName = "Lato";
  const DB_BodyProductCard_BorderRadius = 15;
  // DB_RightSidebar
  const DB_RightSidebar_FilterItem_FontFamily = "Lato";
  const DB_RightSidebar_FilterItem_FontColor = "rgba(255,255,255,1)";
  const DB_RightSidebar_FilterItem_FontWeight = 400;
  const DB_RightSidebar_FilterItem_FontSize = 14;
  const DB_RightSidebar_FilterItemTitel_BorderRadius = 30;

  const DB_RightSidebar_FilterTitel_FontColor = "rgba(255,255,255,1)";
  const DB_RightSidebar_FilterTitel_FontFamily = "Lato";
  const DB_RightSidebar_FilterTitel_FontSize = 15;
  const DB_RightSidebar_FilterTitel_TextAlign = "center";
  const DB_RightSidebar_FilterTitel_BackgroundColor = "rgba(255,255,255,0.1)";
  const DB_RightSidebar_FilterTitel_BorderRadius = 5;
  const DB_RightSidebar_FilterTitel_FontWeight = 600;

  let farbeTempOffCanvas = [];

  const useStyles = createUseStyles({
    filterRechtSeiteContainer: {
      borderTop: `${
        SideBarLeft_ContainerFilter_Farbe_Groesse_Stil_OnMouseOverAndOut
          ? "3"
          : SideBarLeft_ContainerFilter_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
          ? "3"
          : DB_LeftSidebar_MenuContainerFilterGG_BorderTop
      }px
        ${
          SideBarLeft_ContainerFilter_Farbe_Groesse_Stil_OnMouseOverAndOut
            ? "solid"
            : SideBarLeft_ContainerFilter_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
            ? "dotted"
            : DB_LeftSidebar_MenuContainerFilterGG_BorderStyleTop
        }
        ${
          SideBarLeft_ContainerFilter_Farbe_Groesse_Stil_OnMouseOverAndOut
            ? "red"
            : SideBarLeft_ContainerFilter_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
            ? "red"
            : DB_LeftSidebar_MenuContainerFilterGG_BorderColorTop
        }`,
      borderBottom: `${
        SideBarLeft_ContainerFilter_Farbe_Groesse_Stil_OnMouseOverAndOut
          ? "3"
          : SideBarLeft_ContainerFilter_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
          ? "3"
          : DB_LeftSidebar_MenuContainerFilterGG_BorderBottom
      }px
        ${
          SideBarLeft_ContainerFilter_Farbe_Groesse_Stil_OnMouseOverAndOut
            ? "solid"
            : SideBarLeft_ContainerFilter_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
            ? "dotted"
            : DB_LeftSidebar_MenuContainerFilterGG_BorderStyleBottom
        }
        ${
          SideBarLeft_ContainerFilter_Farbe_Groesse_Stil_OnMouseOverAndOut
            ? "red"
            : SideBarLeft_ContainerFilter_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
            ? "red"
            : DB_LeftSidebar_MenuContainerFilterGG_BorderColorBottom
        }`,
      borderLeft: `${
        SideBarLeft_ContainerFilter_Farbe_Groesse_Stil_OnMouseOverAndOut
          ? "3"
          : SideBarLeft_ContainerFilter_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
          ? "3"
          : DB_LeftSidebar_MenuContainerFilterGG_BorderLeft
      }px
        ${
          SideBarLeft_ContainerFilter_Farbe_Groesse_Stil_OnMouseOverAndOut
            ? "solid"
            : SideBarLeft_ContainerFilter_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
            ? "dotted"
            : DB_LeftSidebar_MenuContainerFilterGG_BorderStyleLeft
        }
        ${
          SideBarLeft_ContainerFilter_Farbe_Groesse_Stil_OnMouseOverAndOut
            ? "red"
            : SideBarLeft_ContainerFilter_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
            ? "red"
            : DB_LeftSidebar_MenuContainerFilterGG_BorderColorLeft
        }`,
      borderRight: `${
        SideBarLeft_ContainerFilter_Farbe_Groesse_Stil_OnMouseOverAndOut
          ? "3"
          : SideBarLeft_ContainerFilter_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
          ? "3"
          : DB_LeftSidebar_MenuContainerFilterGG_BorderRight
      }px
        ${
          SideBarLeft_ContainerFilter_Farbe_Groesse_Stil_OnMouseOverAndOut
            ? "solid"
            : SideBarLeft_ContainerFilter_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
            ? "dotted"
            : DB_LeftSidebar_MenuContainerFilterGG_BorderStyleRight
        }
        ${
          SideBarLeft_ContainerFilter_Farbe_Groesse_Stil_OnMouseOverAndOut
            ? "red"
            : SideBarLeft_ContainerFilter_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
            ? "red"
            : DB_LeftSidebar_MenuContainerFilterGG_BorderColorRight
        }`,
      borderTopRightRadius: `${DB_LeftSidebar_MenuItem_MenuContainerFilterGG_BorderTopRightRadius}px`,
      borderTopLeftRadius: `${DB_LeftSidebar_MenuItem_MenuContainerFilterGG_BorderTopLeftRadius}px`,
      borderBottomRightRadius: `${DB_LeftSidebar_MenuItem_MenuContainerFilterGG_BorderBottomRightRadius}px`,
      borderBottomLeftRadius: `${DB_LeftSidebar_MenuItem_MenuContainerFilterGG_BorderBottomLeftRadius}px`,

      paddingLeft: `${DB_LeftSidebar_MenuItem_MenuContainerFilterGG_paddingLeft}px`,
      paddingRight: `${DB_LeftSidebar_MenuItem_MenuContainerFilterGG_paddingRight}px`,
      paddingTop: `${DB_LeftSidebar_MenuItem_MenuContainerFilterGG_paddingTop}px`,
      paddingBottom: `${DB_LeftSidebar_MenuItem_MenuContainerFilterGG_paddingBottom}px`,
      boxShadow: `${DB_LeftSidebar_MenuItem_MenuContainerFilterGG_ShadowHorizontal}px
        ${DB_LeftSidebar_MenuItem_MenuContainerFilterGG_ShadowVertical}px
        ${DB_LeftSidebar_MenuItem_MenuContainerFilterGG_ShadowBlur}px
        ${DB_LeftSidebar_MenuItem_MenuContainerFilterGG_ShadowColor}`,
    },
    filterItem: {
      fontFamily: `${DB_fontFace_FilterItemFontFamily_FontFamilyName}`,
      fontWeight: `${DB_LeftSidebar_MenuItem_FilterItem_FontWeight}`,
      color: `${DB_LeftSidebar_MenuItem_FilterItem_FontColor}`,
      fontSize: `${DB_LeftSidebar_MenuItem_FilterItem_FontSize}px`,
      backgroundColor: `${
        SideBarLeft_FilterItem_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut
          ? "rgba(255,0,0,0.5)"
          : SideBarLeft_FilterItem_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut
          ? "rgba(255,0,0,0.3)"
          : DB_LeftSidebar_MenuItem_FilterItem_BackgroundColor
      }`,
      textAlign: `${DB_LeftSidebar_MenuItem_FilterItem_TextAlign}`,
      borderTop: `${
        SideBarLeft_FilterItem_Farbe_Groesse_Stil_OnMouseOverAndOut
          ? "3"
          : SideBarLeft_FilterItem_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
          ? "3"
          : DB_LeftSidebar_MenuItem_FilterItem_BorderTop
      }px
        ${
          SideBarLeft_FilterItem_Farbe_Groesse_Stil_OnMouseOverAndOut
            ? "solid"
            : SideBarLeft_FilterItem_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
            ? "dotted"
            : DB_LeftSidebar_MenuItem_FilterItem_BorderStyleTop
        }
        ${
          SideBarLeft_FilterItem_Farbe_Groesse_Stil_OnMouseOverAndOut
            ? "red"
            : SideBarLeft_FilterItem_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
            ? "red"
            : DB_LeftSidebar_MenuItem_FilterItem_BorderColorTop
        }`,
      borderBottom: `${
        SideBarLeft_FilterItem_Farbe_Groesse_Stil_OnMouseOverAndOut
          ? "3"
          : SideBarLeft_FilterItem_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
          ? "3"
          : DB_LeftSidebar_MenuItem_FilterItem_BorderBottom
      }px
        ${
          SideBarLeft_FilterItem_Farbe_Groesse_Stil_OnMouseOverAndOut
            ? "solid"
            : SideBarLeft_FilterItem_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
            ? "dotted"
            : DB_LeftSidebar_MenuItem_FilterItem_BorderStyleBottom
        }
        ${
          SideBarLeft_FilterItem_Farbe_Groesse_Stil_OnMouseOverAndOut
            ? "red"
            : SideBarLeft_FilterItem_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
            ? "red"
            : DB_LeftSidebar_MenuItem_FilterItem_BorderColorBottom
        }`,
      borderLeft: `${
        SideBarLeft_FilterItem_Farbe_Groesse_Stil_OnMouseOverAndOut
          ? "3"
          : SideBarLeft_FilterItem_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
          ? "3"
          : DB_LeftSidebar_MenuItem_FilterItem_BorderLeft
      }px
        ${
          SideBarLeft_FilterItem_Farbe_Groesse_Stil_OnMouseOverAndOut
            ? "solid"
            : SideBarLeft_FilterItem_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
            ? "dotted"
            : DB_LeftSidebar_MenuItem_FilterItem_BorderStyleLeft
        }
        ${
          SideBarLeft_FilterItem_Farbe_Groesse_Stil_OnMouseOverAndOut
            ? "red"
            : SideBarLeft_FilterItem_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
            ? "red"
            : DB_LeftSidebar_MenuItem_FilterItem_BorderColorLeft
        }`,
      borderRight: `${
        SideBarLeft_FilterItem_Farbe_Groesse_Stil_OnMouseOverAndOut
          ? "3"
          : SideBarLeft_FilterItem_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
          ? "3"
          : DB_LeftSidebar_MenuItem_FilterItem_BorderRight
      }px
        ${
          SideBarLeft_FilterItem_Farbe_Groesse_Stil_OnMouseOverAndOut
            ? "solid"
            : SideBarLeft_FilterItem_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
            ? "dotted"
            : DB_LeftSidebar_MenuItem_FilterItem_BorderStyleRight
        }
        ${
          SideBarLeft_FilterItem_Farbe_Groesse_Stil_OnMouseOverAndOut
            ? "red"
            : SideBarLeft_FilterItem_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
            ? "red"
            : DB_LeftSidebar_MenuItem_FilterItem_BorderColorRight
        }`,
      borderTopRightRadius: `${DB_LeftSidebar_MenuItem_FilterItem_BorderTopRightRadius}px`,
      borderTopLeftRadius: `${DB_LeftSidebar_MenuItem_FilterItem_BorderTopLeftRadius}px`,
      borderBottomRightRadius: `${DB_LeftSidebar_MenuItem_FilterItem_BorderBottomRightRadius}px`,
      borderBottomLeftRadius: `${DB_LeftSidebar_MenuItem_FilterItem_BorderBottomLeftRadius}px`,
      marginLeft: `${DB_LeftSidebar_MenuItem_FilterItem_marginLeftRight}px`,
      marginRight: `${DB_LeftSidebar_MenuItem_FilterItem_marginLeftRight}px`,
      marginTop: `${DB_LeftSidebar_MenuItem_FilterItem_marginTopBottom}px`,
      marginBottom: `${DB_LeftSidebar_MenuItem_FilterItem_marginTopBottom}px`,
      paddingLeft: `${DB_LeftSidebar_MenuItem_FilterItem_paddingLeftRight}px`,
      paddingRight: `${DB_LeftSidebar_MenuItem_FilterItem_paddingLeftRight}px`,
      paddingTop: `${DB_LeftSidebar_MenuItem_FilterItem_paddingTopBottom}px`,
      paddingBottom: `${DB_LeftSidebar_MenuItem_FilterItem_paddingTopBottom}px`,
      letterSpacing: `${DB_LeftSidebar_MenuItem_FilterItem_letterSpacing}px`,
      textShadow: `${DB_LeftSidebar_MenuItem_FilterItem_ShadowHorizontal}px
        ${DB_LeftSidebar_MenuItem_FilterItem_ShadowVertical}px
        ${DB_LeftSidebar_MenuItem_FilterItem_ShadowBlur}px
        ${DB_LeftSidebar_MenuItem_FilterItem_ShadowColor}`,
      textDecoration: `${DB_LeftSidebar_MenuItem_FilterItem_TextDecoration}
        ${DB_LeftSidebar_MenuItem_FilterItem_TextDecorationStyle}`,
    },
  });
  const classes = useStyles();

  return (
    <>
      <div
        /* style={{
          backgroundColor: "rgba(0,0,0,0.3)",
          textAlign: "left",
          padding: "10px",
          borderRadius: "20px",
          border: "1px solid rgba(0,0,0,0.3)",
          boxShadow:
            "rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px",
        }} */
        data-bs-spy="scroll"
        data-bs-target="#navbar-example3"
        className={`position-relative ${classes.filterRechtSeiteContainer}`}
        data-bs-offset="0"
        tabIndex="0"
      >
        <div className="category">
          <div>
            <SideBarRightAlleFilter
              DB_fontFace_FontFamilyName={DB_fontFace_FontFamilyName}
              resetFilterHandler={resetFilterHandler}
              SideBarLeft_AlleFilter_Farbe_Groesse_Stil_OnMouseOverAndOut={
                SideBarLeft_AlleFilter_Farbe_Groesse_Stil_OnMouseOverAndOut
              }
              SideBarLeft_AlleFilter_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut={
                SideBarLeft_AlleFilter_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
              }
              SideBarLeft_AlleFilter_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut={
                SideBarLeft_AlleFilter_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut
              }
              SideBarLeft_AlleFilter_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut={
                SideBarLeft_AlleFilter_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut
              }
              SideBarLeft_AlleFilter_Farbe_Groesse_Stil_Trigger={
                SideBarLeft_AlleFilter_Farbe_Groesse_Stil_Trigger
              }
              SideBarLeft_AlleFilter_Farbe_Groesse_Stil_Hover_Trigger={
                SideBarLeft_AlleFilter_Farbe_Groesse_Stil_Hover_Trigger
              }
              SideBarLeft_AlleFilter_HintergrundFarbe_Radius_Stil_Trigger={
                SideBarLeft_AlleFilter_HintergrundFarbe_Radius_Stil_Trigger
              }
              SideBarLeft_AlleFilter_HintergrundFarbe_Radius_Stil_Hover_Trigger={
                SideBarLeft_AlleFilter_HintergrundFarbe_Radius_Stil_Hover_Trigger
              }
              DB_fontFace_AlleFilterButtonFontFamily_FontFamilyName={
                DB_fontFace_AlleFilterButtonFontFamily_FontFamilyName
              }
              DB_fontFace_AlleFilterButtonFontFamily_FontFamilySrc={
                DB_fontFace_AlleFilterButtonFontFamily_FontFamilySrc
              }
              DB_LeftSidebar_MenuItem_AlleFilterButtonFF_FontWeight={
                DB_LeftSidebar_MenuItem_AlleFilterButtonFF_FontWeight
              }
              DB_LeftSidebar_MenuItem_AlleFilterButtonFF_FontColor={
                DB_LeftSidebar_MenuItem_AlleFilterButtonFF_FontColor
              }
              DB_LeftSidebar_MenuItem_AlleFilterButtonFF_FontSize={
                DB_LeftSidebar_MenuItem_AlleFilterButtonFF_FontSize
              }
              DB_LeftSidebar_MenuItem_AlleFilterButtonFF_BackgroundColor={
                DB_LeftSidebar_MenuItem_AlleFilterButtonFF_BackgroundColor
              }
              DB_LeftSidebar_MenuItem_AlleFilterButtonFF_TextAlign={
                DB_LeftSidebar_MenuItem_AlleFilterButtonFF_TextAlign
              }
              DB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderTop={
                DB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderTop
              }
              DB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderStyleTop={
                DB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderStyleTop
              }
              DB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderColorTop={
                DB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderColorTop
              }
              DB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderBottom={
                DB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderBottom
              }
              DB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderStyleBottom={
                DB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderStyleBottom
              }
              DB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderColorBottom={
                DB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderColorBottom
              }
              DB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderLeft={
                DB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderLeft
              }
              DB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderStyleLeft={
                DB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderStyleLeft
              }
              DB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderColorLeft={
                DB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderColorLeft
              }
              DB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderRight={
                DB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderRight
              }
              DB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderStyleRight={
                DB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderStyleRight
              }
              DB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderColorRight={
                DB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderColorRight
              }
              DB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderTopRightRadius={
                DB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderTopRightRadius
              }
              DB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderTopLeftRadius={
                DB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderTopLeftRadius
              }
              DB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderBottomRightRadius={
                DB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderBottomRightRadius
              }
              DB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderBottomLeftRadius={
                DB_LeftSidebar_MenuItem_AlleFilterButtonFF_BorderBottomLeftRadius
              }
              DB_LeftSidebar_MenuItem_AlleFilterButtonFF_marginLeftRight={
                DB_LeftSidebar_MenuItem_AlleFilterButtonFF_marginLeftRight
              }
              DB_LeftSidebar_MenuItem_AlleFilterButtonFF_marginTopBottom={
                DB_LeftSidebar_MenuItem_AlleFilterButtonFF_marginTopBottom
              }
              DB_LeftSidebar_MenuItem_AlleFilterButtonFF_paddingLeftRight={
                DB_LeftSidebar_MenuItem_AlleFilterButtonFF_paddingLeftRight
              }
              DB_LeftSidebar_MenuItem_AlleFilterButtonFF_paddingTopBottom={
                DB_LeftSidebar_MenuItem_AlleFilterButtonFF_paddingTopBottom
              }
              DB_LeftSidebar_MenuItem_AlleFilterButtonFF_letterSpacing={
                DB_LeftSidebar_MenuItem_AlleFilterButtonFF_letterSpacing
              }
              DB_LeftSidebar_MenuItem_AlleFilterButtonFF_ShadowHorizontal={
                DB_LeftSidebar_MenuItem_AlleFilterButtonFF_ShadowHorizontal
              }
              DB_LeftSidebar_MenuItem_AlleFilterButtonFF_ShadowVertical={
                DB_LeftSidebar_MenuItem_AlleFilterButtonFF_ShadowVertical
              }
              DB_LeftSidebar_MenuItem_AlleFilterButtonFF_ShadowBlur={
                DB_LeftSidebar_MenuItem_AlleFilterButtonFF_ShadowBlur
              }
              DB_LeftSidebar_MenuItem_AlleFilterButtonFF_ShadowColor={
                DB_LeftSidebar_MenuItem_AlleFilterButtonFF_ShadowColor
              }
              DB_LeftSidebar_MenuItem_AlleFilterButtonFF_TextDecoration={
                DB_LeftSidebar_MenuItem_AlleFilterButtonFF_TextDecoration
              }
              DB_LeftSidebar_MenuItem_AlleFilterButtonFF_TextDecorationStyle={
                DB_LeftSidebar_MenuItem_AlleFilterButtonFF_TextDecorationStyle
              }
              DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_FontWeight={
                DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_FontWeight
              }
              DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_FontColor={
                DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_FontColor
              }
              DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_FontSize={
                DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_FontSize
              }
              DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BackgroundColor={
                DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BackgroundColor
              }
              DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_TextAlign={
                DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_TextAlign
              }
              DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderTop={
                DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderTop
              }
              DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderStyleTop={
                DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderStyleTop
              }
              DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderColorTop={
                DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderColorTop
              }
              DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderBottom={
                DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderBottom
              }
              DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderStyleBottom={
                DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderStyleBottom
              }
              DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderColorBottom={
                DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderColorBottom
              }
              DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderLeft={
                DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderLeft
              }
              DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderStyleLeft={
                DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderStyleLeft
              }
              DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderColorLeft={
                DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderColorLeft
              }
              DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderRight={
                DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderRight
              }
              DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderStyleRight={
                DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderStyleRight
              }
              DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderColorRight={
                DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderColorRight
              }
              DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderTopRightRadius={
                DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderTopRightRadius
              }
              DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderTopLeftRadius={
                DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderTopLeftRadius
              }
              DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderBottomRightRadius={
                DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderBottomRightRadius
              }
              DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderBottomLeftRadius={
                DB_LeftSidebar_MenuItem_AlleFilterButtonFF_Hover_BorderBottomLeftRadius
              }
              DB_LeftSidebar_MenuItem_AlleFilterButtonFF_marginLeftRight_Hover={
                DB_LeftSidebar_MenuItem_AlleFilterButtonFF_marginLeftRight_Hover
              }
              DB_LeftSidebar_MenuItem_AlleFilterButtonFF_marginTopBottom_Hover={
                DB_LeftSidebar_MenuItem_AlleFilterButtonFF_marginTopBottom_Hover
              }
              DB_LeftSidebar_MenuItem_AlleFilterButtonFF_paddingLeftRight_Hover={
                DB_LeftSidebar_MenuItem_AlleFilterButtonFF_paddingLeftRight_Hover
              }
              DB_LeftSidebar_MenuItem_AlleFilterButtonFF_paddingTopBottom_Hover={
                DB_LeftSidebar_MenuItem_AlleFilterButtonFF_paddingTopBottom_Hover
              }
              DB_LeftSidebar_MenuItem_AlleFilterButtonFF_letterSpacing_Hover={
                DB_LeftSidebar_MenuItem_AlleFilterButtonFF_letterSpacing_Hover
              }
              DB_LeftSidebar_MenuItem_AlleFilterButtonFF_ShadowHorizontal_Hover={
                DB_LeftSidebar_MenuItem_AlleFilterButtonFF_ShadowHorizontal_Hover
              }
              DB_LeftSidebar_MenuItem_AlleFilterButtonFF_ShadowVertical_Hover={
                DB_LeftSidebar_MenuItem_AlleFilterButtonFF_ShadowVertical_Hover
              }
              DB_LeftSidebar_MenuItem_AlleFilterButtonFF_ShadowBlur_Hover={
                DB_LeftSidebar_MenuItem_AlleFilterButtonFF_ShadowBlur_Hover
              }
              DB_LeftSidebar_MenuItem_AlleFilterButtonFF_ShadowColor_Hover={
                DB_LeftSidebar_MenuItem_AlleFilterButtonFF_ShadowColor_Hover
              }
              DB_LeftSidebar_MenuItem_AlleFilterButtonFF_TextDecoration_Hover={
                DB_LeftSidebar_MenuItem_AlleFilterButtonFF_TextDecoration_Hover
              }
              DB_LeftSidebar_MenuItem_AlleFilterButtonFF_TextDecorationStyle_Hover={
                DB_LeftSidebar_MenuItem_AlleFilterButtonFF_TextDecorationStyle_Hover
              }
            />

            {ultraFiltersState.map((filter, index) => {
              if (filter.zustand) {
                zustandTemp.push(filter.zustand);
                if (zustandTemp.length === 1) {
                  return (
                    <div key={"zustand" + "_" + filter.zustand}>
                      <SideBarRightMainFilter
                        filterBezeichnung="Zustand"
                        DB_fontFace_FilterHauptTitel_FontFamilyName={
                          DB_fontFace_FilterHauptTitel_FontFamilyName
                        }
                        DB_fontFace_FilterHauptTitel_FontFamilySrc={
                          DB_fontFace_FilterHauptTitel_FontFamilySrc
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_FontFace={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_FontFace
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_FontFace={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_FontFace
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_FontWeight={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_FontWeight
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_FontWeight={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_FontWeight
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_FontColor={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_FontColor
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_FontColor={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_FontColor
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_FontSize={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_FontSize
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_FontSize={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_FontSize
                        }
                        SideBarLeft_HauptFiltertitel_Farbe_Groesse_Stil_OnMouseOverAndOut={
                          SideBarLeft_HauptFiltertitel_Farbe_Groesse_Stil_OnMouseOverAndOut
                        }
                        SideBarLeft_HauptFiltertitel_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut={
                          SideBarLeft_HauptFiltertitel_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
                        }
                        SideBarLeft_HauptFiltertitel_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut={
                          SideBarLeft_HauptFiltertitel_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut
                        }
                        SideBarLeft_HauptFiltertitel_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut={
                          SideBarLeft_HauptFiltertitel_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_BackgroundColor={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_BackgroundColor
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BackgroundColor={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BackgroundColor
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_TextAlign={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_TextAlign
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_TextAlign={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_TextAlign
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderTop={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderTop
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderTop={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderTop
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderStyleTop={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderStyleTop
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderStyleTop={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderStyleTop
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderColorTop={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderColorTop
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderColorTop={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderColorTop
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderBottom={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderBottom
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderBottom={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderBottom
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderStyleBottom={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderStyleBottom
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderStyleBottom={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderStyleBottom
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderColorBottom={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderColorBottom
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderColorBottom={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderColorBottom
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderLeft={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderLeft
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderLeft={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderLeft
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderStyleLeft={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderStyleLeft
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderStyleLeft={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderStyleLeft
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderColorLeft={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderColorLeft
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderColorLeft={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderColorLeft
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderRight={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderRight
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderRight={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderRight
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderStyleRight={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderStyleRight
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderStyleRight={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderStyleRight
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderColorRight={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderColorRight
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderColorRight={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderColorRight
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderTopRightRadius={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderTopRightRadius
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderTopRightRadius={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderTopRightRadius
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderTopLeftRadius={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderTopLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderTopLeftRadius={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderTopLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderBottomRightRadius={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderBottomRightRadius
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderBottomRightRadius={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderBottomRightRadius
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderBottomLeftRadius={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderBottomLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderBottomLeftRadius={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderBottomLeftRadius
                        }
                        /* handleShow_showConfig_LeftSidebar_MenuMainUltraFilterHauptTitel={
                          handleShow_showConfig_LeftSidebar_MenuMainUltraFilterHauptTitel
                        }
                        handleShow_showConfig_LeftSidebar_MenuMainUltraFilterHauptTitel_BorderAndColor={
                          handleShow_showConfig_LeftSidebar_MenuMainUltraFilterHauptTitel_BorderAndColor
                        }
                        handleShow_showConfig_LeftSidebar_MenuMainUltraFilterHauptTitel_Hover={
                          handleShow_showConfig_LeftSidebar_MenuMainUltraFilterHauptTitel_Hover
                        }
                        handleShow_showConfig_LeftSidebar_MenuMainUltraFilterHauptTitel_Hover_BorderAndColor={
                          handleShow_showConfig_LeftSidebar_MenuMainUltraFilterHauptTitel_Hover_BorderAndColor
                        } */
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_marginTopBottom={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_marginTopBottom
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_marginLeftRight={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_marginLeftRight
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_paddingLeftRight={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_paddingLeftRight
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_paddingTopBottom={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_paddingTopBottom
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_letterSpacing={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_letterSpacing
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_ShadowHorizontal={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_ShadowHorizontal
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_ShadowVertical={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_ShadowVertical
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_ShadowBlur={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_ShadowBlur
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_ShadowColor={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_ShadowColor
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_TextDecoration={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_TextDecoration
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_TextDecorationStyle={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_TextDecorationStyle
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_marginTopBottom_Hover={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_marginTopBottom_Hover
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_marginLeftRight_Hover={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_marginLeftRight_Hover
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_paddingLeftRight_Hover={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_paddingLeftRight_Hover
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_paddingTopBottom_Hover={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_paddingTopBottom_Hover
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_letterSpacing_Hover={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_letterSpacing_Hover
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_ShadowHorizontal_Hover={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_ShadowHorizontal_Hover
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_ShadowVertical_Hover={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_ShadowVertical_Hover
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_ShadowBlur_Hover={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_ShadowBlur_Hover
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_ShadowColor_Hover={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_ShadowColor_Hover
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_TextDecoration_Hover={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_TextDecoration_Hover
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_TextDecorationStyle_Hover={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_TextDecorationStyle_Hover
                        }
                      />

                      <SideBarRightFilterItem
                        filterItemBezeichnung="zustand"
                        filterZustand={filter.zustand}
                        filterProduktsHandler={filterProduktsHandler}
                        isCheck={isCheck}
                        DB_fontFace_FilterItemFontFamily_FontFamilyName={
                          DB_fontFace_FilterItemFontFamily_FontFamilyName
                        }
                        DB_fontFace_FilterItemFontFamily_FontFamilySrc={
                          DB_fontFace_FilterItemFontFamily_FontFamilySrc
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_Hover_FontFace={
                          DB_LeftSidebar_MenuItem_FilterItem_Hover_FontFace
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_FontWeight={
                          DB_LeftSidebar_MenuItem_FilterItem_FontWeight
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_Hover_FontWeight={
                          DB_LeftSidebar_MenuItem_FilterItem_Hover_FontWeight
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_FontColor={
                          DB_LeftSidebar_MenuItem_FilterItem_FontColor
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_Hover_FontColor={
                          DB_LeftSidebar_MenuItem_FilterItem_Hover_FontColor
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_FontSize={
                          DB_LeftSidebar_MenuItem_FilterItem_FontSize
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_Hover_FontSize={
                          DB_LeftSidebar_MenuItem_FilterItem_Hover_FontSize
                        }
                        SideBarLeft_FilterItem_Farbe_Groesse_Stil_OnMouseOverAndOut={
                          SideBarLeft_FilterItem_Farbe_Groesse_Stil_OnMouseOverAndOut
                        }
                        SideBarLeft_FilterItem_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut={
                          SideBarLeft_FilterItem_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
                        }
                        SideBarLeft_FilterItem_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut={
                          SideBarLeft_FilterItem_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut
                        }
                        SideBarLeft_FilterItem_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut={
                          SideBarLeft_FilterItem_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_BackgroundColor={
                          DB_LeftSidebar_MenuItem_FilterItem_BackgroundColor
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_Hover_BackgroundColor={
                          DB_LeftSidebar_MenuItem_FilterItem_Hover_BackgroundColor
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_TextAlign={
                          DB_LeftSidebar_MenuItem_FilterItem_TextAlign
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_Hover_TextAlign={
                          DB_LeftSidebar_MenuItem_FilterItem_Hover_TextAlign
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_BorderTop={
                          DB_LeftSidebar_MenuItem_FilterItem_BorderTop
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderTop={
                          DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderTop
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_BorderStyleTop={
                          DB_LeftSidebar_MenuItem_FilterItem_BorderStyleTop
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderStyleTop={
                          DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderStyleTop
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_BorderColorTop={
                          DB_LeftSidebar_MenuItem_FilterItem_BorderColorTop
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderColorTop={
                          DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderColorTop
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_BorderBottom={
                          DB_LeftSidebar_MenuItem_FilterItem_BorderBottom
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderBottom={
                          DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderBottom
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_BorderStyleBottom={
                          DB_LeftSidebar_MenuItem_FilterItem_BorderStyleBottom
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderStyleBottom={
                          DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderStyleBottom
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_BorderColorBottom={
                          DB_LeftSidebar_MenuItem_FilterItem_BorderColorBottom
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderColorBottom={
                          DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderColorBottom
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_BorderLeft={
                          DB_LeftSidebar_MenuItem_FilterItem_BorderLeft
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderLeft={
                          DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderLeft
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_BorderStyleLeft={
                          DB_LeftSidebar_MenuItem_FilterItem_BorderStyleLeft
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderStyleLeft={
                          DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderStyleLeft
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_BorderColorLeft={
                          DB_LeftSidebar_MenuItem_FilterItem_BorderColorLeft
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderColorLeft={
                          DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderColorLeft
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_BorderRight={
                          DB_LeftSidebar_MenuItem_FilterItem_BorderRight
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderRight={
                          DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderRight
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_BorderStyleRight={
                          DB_LeftSidebar_MenuItem_FilterItem_BorderStyleRight
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderStyleRight={
                          DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderStyleRight
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_BorderColorRight={
                          DB_LeftSidebar_MenuItem_FilterItem_BorderColorRight
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderColorRight={
                          DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderColorRight
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_BorderTopRightRadius={
                          DB_LeftSidebar_MenuItem_FilterItem_BorderTopRightRadius
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderTopRightRadius={
                          DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderTopRightRadius
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_BorderTopLeftRadius={
                          DB_LeftSidebar_MenuItem_FilterItem_BorderTopLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderTopLeftRadius={
                          DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderTopLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_BorderBottomRightRadius={
                          DB_LeftSidebar_MenuItem_FilterItem_BorderBottomRightRadius
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderBottomRightRadius={
                          DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderBottomRightRadius
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_BorderBottomLeftRadius={
                          DB_LeftSidebar_MenuItem_FilterItem_BorderBottomLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderBottomLeftRadius={
                          DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderBottomLeftRadius
                        }
                        handleShow_showConfig_LeftSidebar_MenuFilterItem={
                          handleShow_showConfig_LeftSidebar_MenuFilterItem
                        }
                        handleShow_showConfig_LeftSidebar_MenuFilterItem_BorderAndColor={
                          handleShow_showConfig_LeftSidebar_MenuFilterItem_BorderAndColor
                        }
                        handleShow_showConfig_LeftSidebar_MenuFilterItem_Hover={
                          handleShow_showConfig_LeftSidebar_MenuFilterItem_Hover
                        }
                        handleShow_showConfig_LeftSidebar_MenuFilterItem_Hover_BorderAndColor={
                          handleShow_showConfig_LeftSidebar_MenuFilterItem_Hover_BorderAndColor
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_marginTopBottom={
                          DB_LeftSidebar_MenuItem_FilterItem_marginTopBottom
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_marginLeftRight={
                          DB_LeftSidebar_MenuItem_FilterItem_marginLeftRight
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_paddingLeftRight={
                          DB_LeftSidebar_MenuItem_FilterItem_paddingLeftRight
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_paddingTopBottom={
                          DB_LeftSidebar_MenuItem_FilterItem_paddingTopBottom
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_letterSpacing={
                          DB_LeftSidebar_MenuItem_FilterItem_letterSpacing
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_ShadowHorizontal={
                          DB_LeftSidebar_MenuItem_FilterItem_ShadowHorizontal
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_ShadowVertical={
                          DB_LeftSidebar_MenuItem_FilterItem_ShadowVertical
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_ShadowBlur={
                          DB_LeftSidebar_MenuItem_FilterItem_ShadowBlur
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_ShadowColor={
                          DB_LeftSidebar_MenuItem_FilterItem_ShadowColor
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_TextDecoration={
                          DB_LeftSidebar_MenuItem_FilterItem_TextDecoration
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_TextDecorationStyle={
                          DB_LeftSidebar_MenuItem_FilterItem_TextDecorationStyle
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_marginTopBottom_Hover={
                          DB_LeftSidebar_MenuItem_FilterItem_marginTopBottom_Hover
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_marginLeftRight_Hover={
                          DB_LeftSidebar_MenuItem_FilterItem_marginLeftRight_Hover
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_paddingLeftRight_Hover={
                          DB_LeftSidebar_MenuItem_FilterItem_paddingLeftRight_Hover
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_paddingTopBottom_Hover={
                          DB_LeftSidebar_MenuItem_FilterItem_paddingTopBottom_Hover
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_letterSpacing_Hover={
                          DB_LeftSidebar_MenuItem_FilterItem_letterSpacing_Hover
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_ShadowHorizontal_Hover={
                          DB_LeftSidebar_MenuItem_FilterItem_ShadowHorizontal_Hover
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_ShadowVertical_Hover={
                          DB_LeftSidebar_MenuItem_FilterItem_ShadowVertical_Hover
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_ShadowBlur_Hover={
                          DB_LeftSidebar_MenuItem_FilterItem_ShadowBlur_Hover
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_ShadowColor_Hover={
                          DB_LeftSidebar_MenuItem_FilterItem_ShadowColor_Hover
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_TextDecoration_Hover={
                          DB_LeftSidebar_MenuItem_FilterItem_TextDecoration_Hover
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_TextDecorationStyle_Hover={
                          DB_LeftSidebar_MenuItem_FilterItem_TextDecorationStyle_Hover
                        }
                      />
                    </div>
                  );
                } else {
                  return (
                    <div key={"zustand" + "_" + filter.zustand + Math.random()}>
                      <div style={{ height: "0px" }}></div>
                      <div className={`form-check ${classes.filterItem}`}>
                        <input
                          className="form-check-input zustand"
                          type="checkbox"
                          value={filter.zustand}
                          id={filter.zustand}
                          onChange={filterProduktsHandler}
                          checked={isCheck.find((element) => {
                            if (element.zustand == filter.zustand) {
                              return true;
                            } else {
                              return false;
                            }
                          })}
                        />
                        <label
                          className="form-check-label menuItem"
                          htmlFor={filter.zustand}
                        >
                          {filter.zustand}
                        </label>
                      </div>
                    </div>
                  );
                }
              } /* else if (filter.garantie) {
                garantieTemp.push(filter.garantie);
                if (!garantieTemp.length === 1) {
                  return (
                    <div key={"garantie" + "_" + filter.garantie}>
                      <div className="menuItem_filterTitel"
                    style={{
                      fontWeight: `${DB_RightSidebar_FilterTitel_FontWeight}`,
                      color: `${DB_RightSidebar_FilterTitel_FontColor}`,
                      fontFamily: `${DB_RightSidebar_FilterTitel_FontFamily}`,
                      fontSize: `${DB_RightSidebar_FilterTitel_FontSize}px`,
                      textAlign: `${DB_RightSidebar_FilterTitel_TextAlign}`,
                      backgroundColor: `${DB_RightSidebar_FilterTitel_BackgroundColor}`,
                      borderRadius: `${DB_RightSidebar_FilterTitel_BorderRadius}px`,
                    }}>
                        Garantie
                      </div>
                      <div className="form-check">
                        <input
                          className="form-check-input garantie"
                          type="checkbox"
                          value={filter.garantie}
                          id={filter.garantie}
                          onChange={filterProduktsHandler}
                          defaultChecked={
                            isCheck.indexOf({
                              garantie: filter.garantie,
                            })
                              ? false
                              : true
                          }
                        />
                        <label
                          style={{
                            fontFamily: `${DB_RightSidebar_FilterItem_FontFamily}`,
                            color: `${DB_RightSidebar_FilterItem_FontColor}`,
                            fontWeight: `${DB_RightSidebar_FilterItem_FontWeight}`,
                            fontSize: `${DB_RightSidebar_FilterItem_FontSize}`,
                          }}
                          className="form-check-label menuItem"
                          htmlFor={filter.garantie}
                        >
                          {filter.garantie}
                        </label>
                      </div>
                    </div>
                  );
                } else {
                  return (
                    <div key={"garantie" + "_" + filter.garantie}>
                      <div style={{ height: "0px" }}></div>
                      <div className="form-check">
                        <input
                          className="form-check-input garantie"
                          type="checkbox"
                          value={filter.garantie}
                          id={filter.garantie}
                          onChange={filterProduktsHandler}
                          defaultChecked={
                            isCheck.indexOf({
                              garantie: filter.garantie,
                            })
                              ? false
                              : true
                          }
                        />
                        <label
                          style={{
                            fontFamily: `${DB_RightSidebar_FilterItem_FontFamily}`,
                            color: `${DB_RightSidebar_FilterItem_FontColor}`,
                            fontWeight: `${DB_RightSidebar_FilterItem_FontWeight}`,
                            fontSize: `${DB_RightSidebar_FilterItem_FontSize}`,
                          }}
                          className="form-check-label menuItem"
                          htmlFor={filter.garantie}
                        >
                          {filter.garantie}
                        </label>
                      </div>
                    </div>
                  );
                }
              } */ else if (filter.marke) {
                markeTemp.push(filter.marke);
                if (markeTemp.length === 1) {
                  return (
                    <div key={"marke" + "_" + filter.marke}>
                      <SideBarRightMainFilter
                        filterBezeichnung="Marke"
                        DB_fontFace_FontFamilyName={DB_fontFace_FontFamilyName}
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_FontFace={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_FontFace
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_FontFace={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_FontFace
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_FontWeight={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_FontWeight
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_FontWeight={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_FontWeight
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_FontColor={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_FontColor
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_FontColor={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_FontColor
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_FontSize={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_FontSize
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_FontSize={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_FontSize
                        }
                        SideBarLeft_HauptFiltertitel_Farbe_Groesse_Stil_OnMouseOverAndOut={
                          SideBarLeft_HauptFiltertitel_Farbe_Groesse_Stil_OnMouseOverAndOut
                        }
                        SideBarLeft_HauptFiltertitel_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut={
                          SideBarLeft_HauptFiltertitel_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
                        }
                        SideBarLeft_HauptFiltertitel_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut={
                          SideBarLeft_HauptFiltertitel_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut
                        }
                        SideBarLeft_HauptFiltertitel_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut={
                          SideBarLeft_HauptFiltertitel_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_BackgroundColor={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_BackgroundColor
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BackgroundColor={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BackgroundColor
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_TextAlign={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_TextAlign
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_TextAlign={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_TextAlign
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderTop={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderTop
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderTop={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderTop
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderStyleTop={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderStyleTop
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderStyleTop={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderStyleTop
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderColorTop={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderColorTop
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderColorTop={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderColorTop
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderBottom={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderBottom
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderBottom={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderBottom
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderStyleBottom={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderStyleBottom
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderStyleBottom={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderStyleBottom
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderColorBottom={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderColorBottom
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderColorBottom={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderColorBottom
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderLeft={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderLeft
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderLeft={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderLeft
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderStyleLeft={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderStyleLeft
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderStyleLeft={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderStyleLeft
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderColorLeft={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderColorLeft
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderColorLeft={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderColorLeft
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderRight={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderRight
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderRight={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderRight
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderStyleRight={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderStyleRight
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderStyleRight={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderStyleRight
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderColorRight={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderColorRight
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderColorRight={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderColorRight
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderTopRightRadius={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderTopRightRadius
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderTopRightRadius={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderTopRightRadius
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderTopLeftRadius={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderTopLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderTopLeftRadius={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderTopLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderBottomRightRadius={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderBottomRightRadius
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderBottomRightRadius={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderBottomRightRadius
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderBottomLeftRadius={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderBottomLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderBottomLeftRadius={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderBottomLeftRadius
                        }
                        /* handleShow_showConfig_LeftSidebar_MenuMainUltraFilterHauptTitel={
                          handleShow_showConfig_LeftSidebar_MenuMainUltraFilterHauptTitel
                        }
                        handleShow_showConfig_LeftSidebar_MenuMainUltraFilterHauptTitel_BorderAndColor={
                          handleShow_showConfig_LeftSidebar_MenuMainUltraFilterHauptTitel_BorderAndColor
                        }
                        handleShow_showConfig_LeftSidebar_MenuMainUltraFilterHauptTitel_Hover={
                          handleShow_showConfig_LeftSidebar_MenuMainUltraFilterHauptTitel_Hover
                        }
                        handleShow_showConfig_LeftSidebar_MenuMainUltraFilterHauptTitel_Hover_BorderAndColor={
                          handleShow_showConfig_LeftSidebar_MenuMainUltraFilterHauptTitel_Hover_BorderAndColor
                        } */
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_marginTopBottom={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_marginTopBottom
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_marginLeftRight={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_marginLeftRight
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_paddingLeftRight={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_paddingLeftRight
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_paddingTopBottom={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_paddingTopBottom
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_letterSpacing={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_letterSpacing
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_ShadowHorizontal={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_ShadowHorizontal
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_ShadowVertical={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_ShadowVertical
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_ShadowBlur={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_ShadowBlur
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_ShadowColor={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_ShadowColor
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_TextDecoration={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_TextDecoration
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_TextDecorationStyle={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_TextDecorationStyle
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_marginTopBottom_Hover={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_marginTopBottom_Hover
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_marginLeftRight_Hover={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_marginLeftRight_Hover
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_paddingLeftRight_Hover={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_paddingLeftRight_Hover
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_paddingTopBottom_Hover={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_paddingTopBottom_Hover
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_letterSpacing_Hover={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_letterSpacing_Hover
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_ShadowHorizontal_Hover={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_ShadowHorizontal_Hover
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_ShadowVertical_Hover={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_ShadowVertical_Hover
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_ShadowBlur_Hover={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_ShadowBlur_Hover
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_ShadowColor_Hover={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_ShadowColor_Hover
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_TextDecoration_Hover={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_TextDecoration_Hover
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_TextDecorationStyle_Hover={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_TextDecorationStyle_Hover
                        }
                      />

                      <SideBarRightFilterItem
                        filterItemBezeichnung="marke"
                        filterZustand={filter.marke}
                        filterProduktsHandler={filterProduktsHandler}
                        isCheck={isCheck}
                        DB_fontFace_FilterItemFontFamily_FontFamilyName={
                          DB_fontFace_FilterItemFontFamily_FontFamilyName
                        }
                        DB_fontFace_FilterItemFontFamily_FontFamilySrc={
                          DB_fontFace_FilterItemFontFamily_FontFamilySrc
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_Hover_FontFace={
                          DB_LeftSidebar_MenuItem_FilterItem_Hover_FontFace
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_FontWeight={
                          DB_LeftSidebar_MenuItem_FilterItem_FontWeight
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_Hover_FontWeight={
                          DB_LeftSidebar_MenuItem_FilterItem_Hover_FontWeight
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_FontColor={
                          DB_LeftSidebar_MenuItem_FilterItem_FontColor
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_Hover_FontColor={
                          DB_LeftSidebar_MenuItem_FilterItem_Hover_FontColor
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_FontSize={
                          DB_LeftSidebar_MenuItem_FilterItem_FontSize
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_Hover_FontSize={
                          DB_LeftSidebar_MenuItem_FilterItem_Hover_FontSize
                        }
                        SideBarLeft_FilterItem_Farbe_Groesse_Stil_OnMouseOverAndOut={
                          SideBarLeft_FilterItem_Farbe_Groesse_Stil_OnMouseOverAndOut
                        }
                        SideBarLeft_FilterItem_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut={
                          SideBarLeft_FilterItem_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
                        }
                        SideBarLeft_FilterItem_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut={
                          SideBarLeft_FilterItem_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut
                        }
                        SideBarLeft_FilterItem_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut={
                          SideBarLeft_FilterItem_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_BackgroundColor={
                          DB_LeftSidebar_MenuItem_FilterItem_BackgroundColor
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_Hover_BackgroundColor={
                          DB_LeftSidebar_MenuItem_FilterItem_Hover_BackgroundColor
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_TextAlign={
                          DB_LeftSidebar_MenuItem_FilterItem_TextAlign
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_Hover_TextAlign={
                          DB_LeftSidebar_MenuItem_FilterItem_Hover_TextAlign
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_BorderTop={
                          DB_LeftSidebar_MenuItem_FilterItem_BorderTop
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderTop={
                          DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderTop
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_BorderStyleTop={
                          DB_LeftSidebar_MenuItem_FilterItem_BorderStyleTop
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderStyleTop={
                          DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderStyleTop
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_BorderColorTop={
                          DB_LeftSidebar_MenuItem_FilterItem_BorderColorTop
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderColorTop={
                          DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderColorTop
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_BorderBottom={
                          DB_LeftSidebar_MenuItem_FilterItem_BorderBottom
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderBottom={
                          DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderBottom
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_BorderStyleBottom={
                          DB_LeftSidebar_MenuItem_FilterItem_BorderStyleBottom
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderStyleBottom={
                          DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderStyleBottom
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_BorderColorBottom={
                          DB_LeftSidebar_MenuItem_FilterItem_BorderColorBottom
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderColorBottom={
                          DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderColorBottom
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_BorderLeft={
                          DB_LeftSidebar_MenuItem_FilterItem_BorderLeft
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderLeft={
                          DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderLeft
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_BorderStyleLeft={
                          DB_LeftSidebar_MenuItem_FilterItem_BorderStyleLeft
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderStyleLeft={
                          DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderStyleLeft
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_BorderColorLeft={
                          DB_LeftSidebar_MenuItem_FilterItem_BorderColorLeft
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderColorLeft={
                          DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderColorLeft
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_BorderRight={
                          DB_LeftSidebar_MenuItem_FilterItem_BorderRight
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderRight={
                          DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderRight
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_BorderStyleRight={
                          DB_LeftSidebar_MenuItem_FilterItem_BorderStyleRight
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderStyleRight={
                          DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderStyleRight
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_BorderColorRight={
                          DB_LeftSidebar_MenuItem_FilterItem_BorderColorRight
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderColorRight={
                          DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderColorRight
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_BorderTopRightRadius={
                          DB_LeftSidebar_MenuItem_FilterItem_BorderTopRightRadius
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderTopRightRadius={
                          DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderTopRightRadius
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_BorderTopLeftRadius={
                          DB_LeftSidebar_MenuItem_FilterItem_BorderTopLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderTopLeftRadius={
                          DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderTopLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_BorderBottomRightRadius={
                          DB_LeftSidebar_MenuItem_FilterItem_BorderBottomRightRadius
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderBottomRightRadius={
                          DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderBottomRightRadius
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_BorderBottomLeftRadius={
                          DB_LeftSidebar_MenuItem_FilterItem_BorderBottomLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderBottomLeftRadius={
                          DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderBottomLeftRadius
                        }
                        handleShow_showConfig_LeftSidebar_MenuFilterItem={
                          handleShow_showConfig_LeftSidebar_MenuFilterItem
                        }
                        handleShow_showConfig_LeftSidebar_MenuFilterItem_BorderAndColor={
                          handleShow_showConfig_LeftSidebar_MenuFilterItem_BorderAndColor
                        }
                        handleShow_showConfig_LeftSidebar_MenuFilterItem_Hover={
                          handleShow_showConfig_LeftSidebar_MenuFilterItem_Hover
                        }
                        handleShow_showConfig_LeftSidebar_MenuFilterItem_Hover_BorderAndColor={
                          handleShow_showConfig_LeftSidebar_MenuFilterItem_Hover_BorderAndColor
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_marginTopBottom={
                          DB_LeftSidebar_MenuItem_FilterItem_marginTopBottom
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_marginLeftRight={
                          DB_LeftSidebar_MenuItem_FilterItem_marginLeftRight
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_paddingLeftRight={
                          DB_LeftSidebar_MenuItem_FilterItem_paddingLeftRight
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_paddingTopBottom={
                          DB_LeftSidebar_MenuItem_FilterItem_paddingTopBottom
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_letterSpacing={
                          DB_LeftSidebar_MenuItem_FilterItem_letterSpacing
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_ShadowHorizontal={
                          DB_LeftSidebar_MenuItem_FilterItem_ShadowHorizontal
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_ShadowVertical={
                          DB_LeftSidebar_MenuItem_FilterItem_ShadowVertical
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_ShadowBlur={
                          DB_LeftSidebar_MenuItem_FilterItem_ShadowBlur
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_ShadowColor={
                          DB_LeftSidebar_MenuItem_FilterItem_ShadowColor
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_TextDecoration={
                          DB_LeftSidebar_MenuItem_FilterItem_TextDecoration
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_TextDecorationStyle={
                          DB_LeftSidebar_MenuItem_FilterItem_TextDecorationStyle
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_marginTopBottom_Hover={
                          DB_LeftSidebar_MenuItem_FilterItem_marginTopBottom_Hover
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_marginLeftRight_Hover={
                          DB_LeftSidebar_MenuItem_FilterItem_marginLeftRight_Hover
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_paddingLeftRight_Hover={
                          DB_LeftSidebar_MenuItem_FilterItem_paddingLeftRight_Hover
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_paddingTopBottom_Hover={
                          DB_LeftSidebar_MenuItem_FilterItem_paddingTopBottom_Hover
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_letterSpacing_Hover={
                          DB_LeftSidebar_MenuItem_FilterItem_letterSpacing_Hover
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_ShadowHorizontal_Hover={
                          DB_LeftSidebar_MenuItem_FilterItem_ShadowHorizontal_Hover
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_ShadowVertical_Hover={
                          DB_LeftSidebar_MenuItem_FilterItem_ShadowVertical_Hover
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_ShadowBlur_Hover={
                          DB_LeftSidebar_MenuItem_FilterItem_ShadowBlur_Hover
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_ShadowColor_Hover={
                          DB_LeftSidebar_MenuItem_FilterItem_ShadowColor_Hover
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_TextDecoration_Hover={
                          DB_LeftSidebar_MenuItem_FilterItem_TextDecoration_Hover
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_TextDecorationStyle_Hover={
                          DB_LeftSidebar_MenuItem_FilterItem_TextDecorationStyle_Hover
                        }
                      />
                    </div>
                  );
                } else {
                  return (
                    <div key={"marke" + "_" + filter.marke}>
                      <div style={{ height: "0px" }}></div>
                      <div className={`form-check ${classes.filterItem}`}>
                        <input
                          className="form-check-input marke"
                          type="checkbox"
                          value={filter.marke}
                          id={filter.marke}
                          onChange={filterProduktsHandler}
                          checked={isCheck.find((element) => {
                            if (element.marke == filter.marke) {
                              return true;
                            } else {
                              return false;
                            }
                          })}
                        />
                        <label
                          className="form-check-label menuItem"
                          htmlFor={filter.marke}
                        >
                          {filter.marke}
                        </label>
                      </div>
                    </div>
                  );
                }
              } else if (filter.material) {
                materialTemp.push(filter.material);
                if (materialTemp.length === 1) {
                  return (
                    <div key={"material" + "_" + filter.material}>
                      <SideBarRightMainFilter
                        filterBezeichnung="Material"
                        DB_fontFace_FontFamilyName={DB_fontFace_FontFamilyName}
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_FontFace={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_FontFace
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_FontFace={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_FontFace
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_FontWeight={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_FontWeight
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_FontWeight={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_FontWeight
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_FontColor={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_FontColor
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_FontColor={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_FontColor
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_FontSize={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_FontSize
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_FontSize={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_FontSize
                        }
                        SideBarLeft_HauptFiltertitel_Farbe_Groesse_Stil_OnMouseOverAndOut={
                          SideBarLeft_HauptFiltertitel_Farbe_Groesse_Stil_OnMouseOverAndOut
                        }
                        SideBarLeft_HauptFiltertitel_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut={
                          SideBarLeft_HauptFiltertitel_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
                        }
                        SideBarLeft_HauptFiltertitel_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut={
                          SideBarLeft_HauptFiltertitel_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut
                        }
                        SideBarLeft_HauptFiltertitel_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut={
                          SideBarLeft_HauptFiltertitel_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_BackgroundColor={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_BackgroundColor
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BackgroundColor={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BackgroundColor
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_TextAlign={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_TextAlign
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_TextAlign={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_TextAlign
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderTop={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderTop
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderTop={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderTop
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderStyleTop={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderStyleTop
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderStyleTop={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderStyleTop
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderColorTop={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderColorTop
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderColorTop={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderColorTop
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderBottom={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderBottom
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderBottom={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderBottom
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderStyleBottom={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderStyleBottom
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderStyleBottom={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderStyleBottom
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderColorBottom={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderColorBottom
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderColorBottom={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderColorBottom
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderLeft={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderLeft
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderLeft={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderLeft
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderStyleLeft={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderStyleLeft
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderStyleLeft={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderStyleLeft
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderColorLeft={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderColorLeft
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderColorLeft={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderColorLeft
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderRight={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderRight
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderRight={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderRight
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderStyleRight={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderStyleRight
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderStyleRight={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderStyleRight
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderColorRight={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderColorRight
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderColorRight={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderColorRight
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderTopRightRadius={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderTopRightRadius
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderTopRightRadius={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderTopRightRadius
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderTopLeftRadius={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderTopLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderTopLeftRadius={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderTopLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderBottomRightRadius={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderBottomRightRadius
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderBottomRightRadius={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderBottomRightRadius
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderBottomLeftRadius={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderBottomLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderBottomLeftRadius={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderBottomLeftRadius
                        }
                        /* handleShow_showConfig_LeftSidebar_MenuMainUltraFilterHauptTitel={
                          handleShow_showConfig_LeftSidebar_MenuMainUltraFilterHauptTitel
                        }
                        handleShow_showConfig_LeftSidebar_MenuMainUltraFilterHauptTitel_BorderAndColor={
                          handleShow_showConfig_LeftSidebar_MenuMainUltraFilterHauptTitel_BorderAndColor
                        }
                        handleShow_showConfig_LeftSidebar_MenuMainUltraFilterHauptTitel_Hover={
                          handleShow_showConfig_LeftSidebar_MenuMainUltraFilterHauptTitel_Hover
                        }
                        handleShow_showConfig_LeftSidebar_MenuMainUltraFilterHauptTitel_Hover_BorderAndColor={
                          handleShow_showConfig_LeftSidebar_MenuMainUltraFilterHauptTitel_Hover_BorderAndColor
                        } */
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_marginTopBottom={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_marginTopBottom
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_marginLeftRight={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_marginLeftRight
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_paddingLeftRight={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_paddingLeftRight
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_paddingTopBottom={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_paddingTopBottom
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_letterSpacing={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_letterSpacing
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_ShadowHorizontal={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_ShadowHorizontal
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_ShadowVertical={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_ShadowVertical
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_ShadowBlur={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_ShadowBlur
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_ShadowColor={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_ShadowColor
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_TextDecoration={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_TextDecoration
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_TextDecorationStyle={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_TextDecorationStyle
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_marginTopBottom_Hover={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_marginTopBottom_Hover
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_marginLeftRight_Hover={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_marginLeftRight_Hover
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_paddingLeftRight_Hover={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_paddingLeftRight_Hover
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_paddingTopBottom_Hover={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_paddingTopBottom_Hover
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_letterSpacing_Hover={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_letterSpacing_Hover
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_ShadowHorizontal_Hover={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_ShadowHorizontal_Hover
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_ShadowVertical_Hover={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_ShadowVertical_Hover
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_ShadowBlur_Hover={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_ShadowBlur_Hover
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_ShadowColor_Hover={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_ShadowColor_Hover
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_TextDecoration_Hover={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_TextDecoration_Hover
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_TextDecorationStyle_Hover={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_TextDecorationStyle_Hover
                        }
                      />

                      <SideBarRightFilterItem
                        filterItemBezeichnung="material"
                        filterZustand={filter.material}
                        filterProduktsHandler={filterProduktsHandler}
                        isCheck={isCheck}
                        DB_fontFace_FilterItemFontFamily_FontFamilyName={
                          DB_fontFace_FilterItemFontFamily_FontFamilyName
                        }
                        DB_fontFace_FilterItemFontFamily_FontFamilySrc={
                          DB_fontFace_FilterItemFontFamily_FontFamilySrc
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_Hover_FontFace={
                          DB_LeftSidebar_MenuItem_FilterItem_Hover_FontFace
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_FontWeight={
                          DB_LeftSidebar_MenuItem_FilterItem_FontWeight
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_Hover_FontWeight={
                          DB_LeftSidebar_MenuItem_FilterItem_Hover_FontWeight
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_FontColor={
                          DB_LeftSidebar_MenuItem_FilterItem_FontColor
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_Hover_FontColor={
                          DB_LeftSidebar_MenuItem_FilterItem_Hover_FontColor
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_FontSize={
                          DB_LeftSidebar_MenuItem_FilterItem_FontSize
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_Hover_FontSize={
                          DB_LeftSidebar_MenuItem_FilterItem_Hover_FontSize
                        }
                        SideBarLeft_FilterItem_Farbe_Groesse_Stil_OnMouseOverAndOut={
                          SideBarLeft_FilterItem_Farbe_Groesse_Stil_OnMouseOverAndOut
                        }
                        SideBarLeft_FilterItem_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut={
                          SideBarLeft_FilterItem_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
                        }
                        SideBarLeft_FilterItem_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut={
                          SideBarLeft_FilterItem_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut
                        }
                        SideBarLeft_FilterItem_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut={
                          SideBarLeft_FilterItem_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_BackgroundColor={
                          DB_LeftSidebar_MenuItem_FilterItem_BackgroundColor
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_Hover_BackgroundColor={
                          DB_LeftSidebar_MenuItem_FilterItem_Hover_BackgroundColor
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_TextAlign={
                          DB_LeftSidebar_MenuItem_FilterItem_TextAlign
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_Hover_TextAlign={
                          DB_LeftSidebar_MenuItem_FilterItem_Hover_TextAlign
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_BorderTop={
                          DB_LeftSidebar_MenuItem_FilterItem_BorderTop
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderTop={
                          DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderTop
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_BorderStyleTop={
                          DB_LeftSidebar_MenuItem_FilterItem_BorderStyleTop
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderStyleTop={
                          DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderStyleTop
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_BorderColorTop={
                          DB_LeftSidebar_MenuItem_FilterItem_BorderColorTop
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderColorTop={
                          DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderColorTop
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_BorderBottom={
                          DB_LeftSidebar_MenuItem_FilterItem_BorderBottom
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderBottom={
                          DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderBottom
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_BorderStyleBottom={
                          DB_LeftSidebar_MenuItem_FilterItem_BorderStyleBottom
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderStyleBottom={
                          DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderStyleBottom
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_BorderColorBottom={
                          DB_LeftSidebar_MenuItem_FilterItem_BorderColorBottom
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderColorBottom={
                          DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderColorBottom
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_BorderLeft={
                          DB_LeftSidebar_MenuItem_FilterItem_BorderLeft
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderLeft={
                          DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderLeft
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_BorderStyleLeft={
                          DB_LeftSidebar_MenuItem_FilterItem_BorderStyleLeft
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderStyleLeft={
                          DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderStyleLeft
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_BorderColorLeft={
                          DB_LeftSidebar_MenuItem_FilterItem_BorderColorLeft
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderColorLeft={
                          DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderColorLeft
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_BorderRight={
                          DB_LeftSidebar_MenuItem_FilterItem_BorderRight
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderRight={
                          DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderRight
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_BorderStyleRight={
                          DB_LeftSidebar_MenuItem_FilterItem_BorderStyleRight
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderStyleRight={
                          DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderStyleRight
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_BorderColorRight={
                          DB_LeftSidebar_MenuItem_FilterItem_BorderColorRight
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderColorRight={
                          DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderColorRight
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_BorderTopRightRadius={
                          DB_LeftSidebar_MenuItem_FilterItem_BorderTopRightRadius
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderTopRightRadius={
                          DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderTopRightRadius
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_BorderTopLeftRadius={
                          DB_LeftSidebar_MenuItem_FilterItem_BorderTopLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderTopLeftRadius={
                          DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderTopLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_BorderBottomRightRadius={
                          DB_LeftSidebar_MenuItem_FilterItem_BorderBottomRightRadius
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderBottomRightRadius={
                          DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderBottomRightRadius
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_BorderBottomLeftRadius={
                          DB_LeftSidebar_MenuItem_FilterItem_BorderBottomLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderBottomLeftRadius={
                          DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderBottomLeftRadius
                        }
                        handleShow_showConfig_LeftSidebar_MenuFilterItem={
                          handleShow_showConfig_LeftSidebar_MenuFilterItem
                        }
                        handleShow_showConfig_LeftSidebar_MenuFilterItem_BorderAndColor={
                          handleShow_showConfig_LeftSidebar_MenuFilterItem_BorderAndColor
                        }
                        handleShow_showConfig_LeftSidebar_MenuFilterItem_Hover={
                          handleShow_showConfig_LeftSidebar_MenuFilterItem_Hover
                        }
                        handleShow_showConfig_LeftSidebar_MenuFilterItem_Hover_BorderAndColor={
                          handleShow_showConfig_LeftSidebar_MenuFilterItem_Hover_BorderAndColor
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_marginTopBottom={
                          DB_LeftSidebar_MenuItem_FilterItem_marginTopBottom
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_marginLeftRight={
                          DB_LeftSidebar_MenuItem_FilterItem_marginLeftRight
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_paddingLeftRight={
                          DB_LeftSidebar_MenuItem_FilterItem_paddingLeftRight
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_paddingTopBottom={
                          DB_LeftSidebar_MenuItem_FilterItem_paddingTopBottom
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_letterSpacing={
                          DB_LeftSidebar_MenuItem_FilterItem_letterSpacing
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_ShadowHorizontal={
                          DB_LeftSidebar_MenuItem_FilterItem_ShadowHorizontal
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_ShadowVertical={
                          DB_LeftSidebar_MenuItem_FilterItem_ShadowVertical
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_ShadowBlur={
                          DB_LeftSidebar_MenuItem_FilterItem_ShadowBlur
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_ShadowColor={
                          DB_LeftSidebar_MenuItem_FilterItem_ShadowColor
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_TextDecoration={
                          DB_LeftSidebar_MenuItem_FilterItem_TextDecoration
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_TextDecorationStyle={
                          DB_LeftSidebar_MenuItem_FilterItem_TextDecorationStyle
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_marginTopBottom_Hover={
                          DB_LeftSidebar_MenuItem_FilterItem_marginTopBottom_Hover
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_marginLeftRight_Hover={
                          DB_LeftSidebar_MenuItem_FilterItem_marginLeftRight_Hover
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_paddingLeftRight_Hover={
                          DB_LeftSidebar_MenuItem_FilterItem_paddingLeftRight_Hover
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_paddingTopBottom_Hover={
                          DB_LeftSidebar_MenuItem_FilterItem_paddingTopBottom_Hover
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_letterSpacing_Hover={
                          DB_LeftSidebar_MenuItem_FilterItem_letterSpacing_Hover
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_ShadowHorizontal_Hover={
                          DB_LeftSidebar_MenuItem_FilterItem_ShadowHorizontal_Hover
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_ShadowVertical_Hover={
                          DB_LeftSidebar_MenuItem_FilterItem_ShadowVertical_Hover
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_ShadowBlur_Hover={
                          DB_LeftSidebar_MenuItem_FilterItem_ShadowBlur_Hover
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_ShadowColor_Hover={
                          DB_LeftSidebar_MenuItem_FilterItem_ShadowColor_Hover
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_TextDecoration_Hover={
                          DB_LeftSidebar_MenuItem_FilterItem_TextDecoration_Hover
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_TextDecorationStyle_Hover={
                          DB_LeftSidebar_MenuItem_FilterItem_TextDecorationStyle_Hover
                        }
                      />
                    </div>
                  );
                } else {
                  return (
                    <div key={"material" + "_" + filter.material}>
                      <div style={{ height: "0px" }}></div>
                      <div className={`form-check ${classes.filterItem}`}>
                        <input
                          className="form-check-input material"
                          type="checkbox"
                          value={filter.material}
                          id={filter.material}
                          onChange={filterProduktsHandler}
                          defaultChecked={
                            isCheck.indexOf({
                              material: filter.material,
                            })
                              ? false
                              : true
                          }
                        />
                        <label
                          className="form-check-label menuItem"
                          htmlFor={filter.material}
                        >
                          {filter.material}
                        </label>
                      </div>
                    </div>
                  );
                }
              } else if (filter.farbe) {
                farbeTemp.push(filter.farbe);
                if (farbeTemp.length === 1) {
                  return (
                    <div key={"farbe" + "_" + filter.farbe}>
                      <SideBarRightMainFilter
                        filterBezeichnung="Farbe"
                        DB_fontFace_FontFamilyName={DB_fontFace_FontFamilyName}
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_FontFace={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_FontFace
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_FontFace={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_FontFace
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_FontWeight={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_FontWeight
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_FontWeight={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_FontWeight
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_FontColor={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_FontColor
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_FontColor={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_FontColor
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_FontSize={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_FontSize
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_FontSize={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_FontSize
                        }
                        SideBarLeft_HauptFiltertitel_Farbe_Groesse_Stil_OnMouseOverAndOut={
                          SideBarLeft_HauptFiltertitel_Farbe_Groesse_Stil_OnMouseOverAndOut
                        }
                        SideBarLeft_HauptFiltertitel_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut={
                          SideBarLeft_HauptFiltertitel_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
                        }
                        SideBarLeft_HauptFiltertitel_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut={
                          SideBarLeft_HauptFiltertitel_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut
                        }
                        SideBarLeft_HauptFiltertitel_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut={
                          SideBarLeft_HauptFiltertitel_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_BackgroundColor={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_BackgroundColor
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BackgroundColor={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BackgroundColor
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_TextAlign={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_TextAlign
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_TextAlign={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_TextAlign
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderTop={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderTop
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderTop={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderTop
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderStyleTop={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderStyleTop
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderStyleTop={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderStyleTop
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderColorTop={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderColorTop
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderColorTop={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderColorTop
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderBottom={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderBottom
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderBottom={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderBottom
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderStyleBottom={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderStyleBottom
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderStyleBottom={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderStyleBottom
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderColorBottom={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderColorBottom
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderColorBottom={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderColorBottom
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderLeft={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderLeft
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderLeft={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderLeft
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderStyleLeft={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderStyleLeft
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderStyleLeft={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderStyleLeft
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderColorLeft={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderColorLeft
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderColorLeft={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderColorLeft
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderRight={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderRight
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderRight={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderRight
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderStyleRight={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderStyleRight
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderStyleRight={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderStyleRight
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderColorRight={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderColorRight
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderColorRight={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderColorRight
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderTopRightRadius={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderTopRightRadius
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderTopRightRadius={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderTopRightRadius
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderTopLeftRadius={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderTopLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderTopLeftRadius={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderTopLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderBottomRightRadius={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderBottomRightRadius
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderBottomRightRadius={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderBottomRightRadius
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderBottomLeftRadius={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderBottomLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderBottomLeftRadius={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderBottomLeftRadius
                        }
                        /* handleShow_showConfig_LeftSidebar_MenuMainUltraFilterHauptTitel={
                          handleShow_showConfig_LeftSidebar_MenuMainUltraFilterHauptTitel
                        }
                        handleShow_showConfig_LeftSidebar_MenuMainUltraFilterHauptTitel_BorderAndColor={
                          handleShow_showConfig_LeftSidebar_MenuMainUltraFilterHauptTitel_BorderAndColor
                        }
                        handleShow_showConfig_LeftSidebar_MenuMainUltraFilterHauptTitel_Hover={
                          handleShow_showConfig_LeftSidebar_MenuMainUltraFilterHauptTitel_Hover
                        }
                        handleShow_showConfig_LeftSidebar_MenuMainUltraFilterHauptTitel_Hover_BorderAndColor={
                          handleShow_showConfig_LeftSidebar_MenuMainUltraFilterHauptTitel_Hover_BorderAndColor
                        } */
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_marginTopBottom={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_marginTopBottom
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_marginLeftRight={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_marginLeftRight
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_paddingLeftRight={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_paddingLeftRight
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_paddingTopBottom={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_paddingTopBottom
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_letterSpacing={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_letterSpacing
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_ShadowHorizontal={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_ShadowHorizontal
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_ShadowVertical={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_ShadowVertical
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_ShadowBlur={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_ShadowBlur
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_ShadowColor={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_ShadowColor
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_TextDecoration={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_TextDecoration
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_TextDecorationStyle={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_TextDecorationStyle
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_marginTopBottom_Hover={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_marginTopBottom_Hover
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_marginLeftRight_Hover={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_marginLeftRight_Hover
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_paddingLeftRight_Hover={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_paddingLeftRight_Hover
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_paddingTopBottom_Hover={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_paddingTopBottom_Hover
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_letterSpacing_Hover={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_letterSpacing_Hover
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_ShadowHorizontal_Hover={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_ShadowHorizontal_Hover
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_ShadowVertical_Hover={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_ShadowVertical_Hover
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_ShadowBlur_Hover={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_ShadowBlur_Hover
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_ShadowColor_Hover={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_ShadowColor_Hover
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_TextDecoration_Hover={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_TextDecoration_Hover
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_TextDecorationStyle_Hover={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_TextDecorationStyle_Hover
                        }
                      />

                      <SideBarRightFilterItem
                        filterItemBezeichnung="farbe"
                        filterZustand={filter.farbe}
                        filterProduktsHandler={filterProduktsHandler}
                        isCheck={isCheck}
                        DB_fontFace_FilterItemFontFamily_FontFamilyName={
                          DB_fontFace_FilterItemFontFamily_FontFamilyName
                        }
                        DB_fontFace_FilterItemFontFamily_FontFamilySrc={
                          DB_fontFace_FilterItemFontFamily_FontFamilySrc
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_Hover_FontFace={
                          DB_LeftSidebar_MenuItem_FilterItem_Hover_FontFace
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_FontWeight={
                          DB_LeftSidebar_MenuItem_FilterItem_FontWeight
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_Hover_FontWeight={
                          DB_LeftSidebar_MenuItem_FilterItem_Hover_FontWeight
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_FontColor={
                          DB_LeftSidebar_MenuItem_FilterItem_FontColor
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_Hover_FontColor={
                          DB_LeftSidebar_MenuItem_FilterItem_Hover_FontColor
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_FontSize={
                          DB_LeftSidebar_MenuItem_FilterItem_FontSize
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_Hover_FontSize={
                          DB_LeftSidebar_MenuItem_FilterItem_Hover_FontSize
                        }
                        SideBarLeft_FilterItem_Farbe_Groesse_Stil_OnMouseOverAndOut={
                          SideBarLeft_FilterItem_Farbe_Groesse_Stil_OnMouseOverAndOut
                        }
                        SideBarLeft_FilterItem_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut={
                          SideBarLeft_FilterItem_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
                        }
                        SideBarLeft_FilterItem_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut={
                          SideBarLeft_FilterItem_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut
                        }
                        SideBarLeft_FilterItem_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut={
                          SideBarLeft_FilterItem_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_BackgroundColor={
                          DB_LeftSidebar_MenuItem_FilterItem_BackgroundColor
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_Hover_BackgroundColor={
                          DB_LeftSidebar_MenuItem_FilterItem_Hover_BackgroundColor
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_TextAlign={
                          DB_LeftSidebar_MenuItem_FilterItem_TextAlign
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_Hover_TextAlign={
                          DB_LeftSidebar_MenuItem_FilterItem_Hover_TextAlign
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_BorderTop={
                          DB_LeftSidebar_MenuItem_FilterItem_BorderTop
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderTop={
                          DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderTop
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_BorderStyleTop={
                          DB_LeftSidebar_MenuItem_FilterItem_BorderStyleTop
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderStyleTop={
                          DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderStyleTop
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_BorderColorTop={
                          DB_LeftSidebar_MenuItem_FilterItem_BorderColorTop
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderColorTop={
                          DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderColorTop
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_BorderBottom={
                          DB_LeftSidebar_MenuItem_FilterItem_BorderBottom
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderBottom={
                          DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderBottom
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_BorderStyleBottom={
                          DB_LeftSidebar_MenuItem_FilterItem_BorderStyleBottom
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderStyleBottom={
                          DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderStyleBottom
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_BorderColorBottom={
                          DB_LeftSidebar_MenuItem_FilterItem_BorderColorBottom
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderColorBottom={
                          DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderColorBottom
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_BorderLeft={
                          DB_LeftSidebar_MenuItem_FilterItem_BorderLeft
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderLeft={
                          DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderLeft
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_BorderStyleLeft={
                          DB_LeftSidebar_MenuItem_FilterItem_BorderStyleLeft
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderStyleLeft={
                          DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderStyleLeft
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_BorderColorLeft={
                          DB_LeftSidebar_MenuItem_FilterItem_BorderColorLeft
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderColorLeft={
                          DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderColorLeft
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_BorderRight={
                          DB_LeftSidebar_MenuItem_FilterItem_BorderRight
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderRight={
                          DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderRight
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_BorderStyleRight={
                          DB_LeftSidebar_MenuItem_FilterItem_BorderStyleRight
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderStyleRight={
                          DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderStyleRight
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_BorderColorRight={
                          DB_LeftSidebar_MenuItem_FilterItem_BorderColorRight
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderColorRight={
                          DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderColorRight
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_BorderTopRightRadius={
                          DB_LeftSidebar_MenuItem_FilterItem_BorderTopRightRadius
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderTopRightRadius={
                          DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderTopRightRadius
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_BorderTopLeftRadius={
                          DB_LeftSidebar_MenuItem_FilterItem_BorderTopLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderTopLeftRadius={
                          DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderTopLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_BorderBottomRightRadius={
                          DB_LeftSidebar_MenuItem_FilterItem_BorderBottomRightRadius
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderBottomRightRadius={
                          DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderBottomRightRadius
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_BorderBottomLeftRadius={
                          DB_LeftSidebar_MenuItem_FilterItem_BorderBottomLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderBottomLeftRadius={
                          DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderBottomLeftRadius
                        }
                        handleShow_showConfig_LeftSidebar_MenuFilterItem={
                          handleShow_showConfig_LeftSidebar_MenuFilterItem
                        }
                        handleShow_showConfig_LeftSidebar_MenuFilterItem_BorderAndColor={
                          handleShow_showConfig_LeftSidebar_MenuFilterItem_BorderAndColor
                        }
                        handleShow_showConfig_LeftSidebar_MenuFilterItem_Hover={
                          handleShow_showConfig_LeftSidebar_MenuFilterItem_Hover
                        }
                        handleShow_showConfig_LeftSidebar_MenuFilterItem_Hover_BorderAndColor={
                          handleShow_showConfig_LeftSidebar_MenuFilterItem_Hover_BorderAndColor
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_marginTopBottom={
                          DB_LeftSidebar_MenuItem_FilterItem_marginTopBottom
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_marginLeftRight={
                          DB_LeftSidebar_MenuItem_FilterItem_marginLeftRight
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_paddingLeftRight={
                          DB_LeftSidebar_MenuItem_FilterItem_paddingLeftRight
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_paddingTopBottom={
                          DB_LeftSidebar_MenuItem_FilterItem_paddingTopBottom
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_letterSpacing={
                          DB_LeftSidebar_MenuItem_FilterItem_letterSpacing
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_ShadowHorizontal={
                          DB_LeftSidebar_MenuItem_FilterItem_ShadowHorizontal
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_ShadowVertical={
                          DB_LeftSidebar_MenuItem_FilterItem_ShadowVertical
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_ShadowBlur={
                          DB_LeftSidebar_MenuItem_FilterItem_ShadowBlur
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_ShadowColor={
                          DB_LeftSidebar_MenuItem_FilterItem_ShadowColor
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_TextDecoration={
                          DB_LeftSidebar_MenuItem_FilterItem_TextDecoration
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_TextDecorationStyle={
                          DB_LeftSidebar_MenuItem_FilterItem_TextDecorationStyle
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_marginTopBottom_Hover={
                          DB_LeftSidebar_MenuItem_FilterItem_marginTopBottom_Hover
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_marginLeftRight_Hover={
                          DB_LeftSidebar_MenuItem_FilterItem_marginLeftRight_Hover
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_paddingLeftRight_Hover={
                          DB_LeftSidebar_MenuItem_FilterItem_paddingLeftRight_Hover
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_paddingTopBottom_Hover={
                          DB_LeftSidebar_MenuItem_FilterItem_paddingTopBottom_Hover
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_letterSpacing_Hover={
                          DB_LeftSidebar_MenuItem_FilterItem_letterSpacing_Hover
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_ShadowHorizontal_Hover={
                          DB_LeftSidebar_MenuItem_FilterItem_ShadowHorizontal_Hover
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_ShadowVertical_Hover={
                          DB_LeftSidebar_MenuItem_FilterItem_ShadowVertical_Hover
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_ShadowBlur_Hover={
                          DB_LeftSidebar_MenuItem_FilterItem_ShadowBlur_Hover
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_ShadowColor_Hover={
                          DB_LeftSidebar_MenuItem_FilterItem_ShadowColor_Hover
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_TextDecoration_Hover={
                          DB_LeftSidebar_MenuItem_FilterItem_TextDecoration_Hover
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_TextDecorationStyle_Hover={
                          DB_LeftSidebar_MenuItem_FilterItem_TextDecorationStyle_Hover
                        }
                      />
                    </div>
                  );
                } else {
                  return (
                    <div key={"farbe" + "_" + filter.farbe}>
                      <div style={{ height: "0px" }}></div>
                      <div className={`form-check ${classes.filterItem}`}>
                        <input
                          className="form-check-input farbe"
                          type="checkbox"
                          value={filter.farbe}
                          id={filter.farbe}
                          onChange={filterProduktsHandler}
                          defaultChecked={
                            isCheck.indexOf({
                              farbe: filter.farbe,
                            })
                              ? false
                              : true
                          }
                        />
                        <label
                          className="form-check-label menuItem"
                          htmlFor={filter.farbe}
                        >
                          {filter.farbe}
                        </label>
                      </div>
                    </div>
                  );
                }
              } else if (filter.groesse) {
                groesseTemp.push(filter.groesse);
                if (groesseTemp.length === 1) {
                  return (
                    <div key={"groesse" + "_" + filter.groesse}>
                      <SideBarRightMainFilter
                        filterBezeichnung="Grösse"
                        DB_fontFace_FontFamilyName={DB_fontFace_FontFamilyName}
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_FontFace={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_FontFace
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_FontFace={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_FontFace
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_FontWeight={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_FontWeight
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_FontWeight={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_FontWeight
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_FontColor={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_FontColor
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_FontColor={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_FontColor
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_FontSize={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_FontSize
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_FontSize={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_FontSize
                        }
                        SideBarLeft_HauptFiltertitel_Farbe_Groesse_Stil_OnMouseOverAndOut={
                          SideBarLeft_HauptFiltertitel_Farbe_Groesse_Stil_OnMouseOverAndOut
                        }
                        SideBarLeft_HauptFiltertitel_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut={
                          SideBarLeft_HauptFiltertitel_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
                        }
                        SideBarLeft_HauptFiltertitel_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut={
                          SideBarLeft_HauptFiltertitel_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut
                        }
                        SideBarLeft_HauptFiltertitel_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut={
                          SideBarLeft_HauptFiltertitel_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_BackgroundColor={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_BackgroundColor
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BackgroundColor={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BackgroundColor
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_TextAlign={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_TextAlign
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_TextAlign={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_TextAlign
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderTop={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderTop
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderTop={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderTop
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderStyleTop={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderStyleTop
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderStyleTop={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderStyleTop
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderColorTop={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderColorTop
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderColorTop={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderColorTop
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderBottom={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderBottom
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderBottom={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderBottom
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderStyleBottom={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderStyleBottom
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderStyleBottom={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderStyleBottom
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderColorBottom={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderColorBottom
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderColorBottom={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderColorBottom
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderLeft={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderLeft
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderLeft={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderLeft
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderStyleLeft={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderStyleLeft
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderStyleLeft={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderStyleLeft
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderColorLeft={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderColorLeft
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderColorLeft={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderColorLeft
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderRight={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderRight
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderRight={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderRight
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderStyleRight={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderStyleRight
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderStyleRight={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderStyleRight
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderColorRight={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderColorRight
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderColorRight={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderColorRight
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderTopRightRadius={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderTopRightRadius
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderTopRightRadius={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderTopRightRadius
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderTopLeftRadius={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderTopLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderTopLeftRadius={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderTopLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderBottomRightRadius={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderBottomRightRadius
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderBottomRightRadius={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderBottomRightRadius
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderBottomLeftRadius={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_BorderBottomLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderBottomLeftRadius={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_Hover_BorderBottomLeftRadius
                        }
                        /* handleShow_showConfig_LeftSidebar_MenuMainUltraFilterHauptTitel={
                          handleShow_showConfig_LeftSidebar_MenuMainUltraFilterHauptTitel
                        }
                        handleShow_showConfig_LeftSidebar_MenuMainUltraFilterHauptTitel_BorderAndColor={
                          handleShow_showConfig_LeftSidebar_MenuMainUltraFilterHauptTitel_BorderAndColor
                        }
                        handleShow_showConfig_LeftSidebar_MenuMainUltraFilterHauptTitel_Hover={
                          handleShow_showConfig_LeftSidebar_MenuMainUltraFilterHauptTitel_Hover
                        }
                        handleShow_showConfig_LeftSidebar_MenuMainUltraFilterHauptTitel_Hover_BorderAndColor={
                          handleShow_showConfig_LeftSidebar_MenuMainUltraFilterHauptTitel_Hover_BorderAndColor
                        } */
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_marginTopBottom={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_marginTopBottom
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_marginLeftRight={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_marginLeftRight
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_paddingLeftRight={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_paddingLeftRight
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_paddingTopBottom={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_paddingTopBottom
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_letterSpacing={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_letterSpacing
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_ShadowHorizontal={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_ShadowHorizontal
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_ShadowVertical={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_ShadowVertical
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_ShadowBlur={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_ShadowBlur
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_ShadowColor={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_ShadowColor
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_TextDecoration={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_TextDecoration
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_TextDecorationStyle={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_TextDecorationStyle
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_marginTopBottom_Hover={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_marginTopBottom_Hover
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_marginLeftRight_Hover={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_marginLeftRight_Hover
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_paddingLeftRight_Hover={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_paddingLeftRight_Hover
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_paddingTopBottom_Hover={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_paddingTopBottom_Hover
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_letterSpacing_Hover={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_letterSpacing_Hover
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_ShadowHorizontal_Hover={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_ShadowHorizontal_Hover
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_ShadowVertical_Hover={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_ShadowVertical_Hover
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_ShadowBlur_Hover={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_ShadowBlur_Hover
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_ShadowColor_Hover={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_ShadowColor_Hover
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_TextDecoration_Hover={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_TextDecoration_Hover
                        }
                        DB_LeftSidebar_MenuItem_FilterHauptTitel_TextDecorationStyle_Hover={
                          DB_LeftSidebar_MenuItem_FilterHauptTitel_TextDecorationStyle_Hover
                        }
                      />

                      <SideBarRightFilterItem
                        filterItemBezeichnung="groesse"
                        filterZustand={filter.groesse}
                        filterProduktsHandler={filterProduktsHandler}
                        isCheck={isCheck}
                        DB_fontFace_FilterItemFontFamily_FontFamilyName={
                          DB_fontFace_FilterItemFontFamily_FontFamilyName
                        }
                        DB_fontFace_FilterItemFontFamily_FontFamilySrc={
                          DB_fontFace_FilterItemFontFamily_FontFamilySrc
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_Hover_FontFace={
                          DB_LeftSidebar_MenuItem_FilterItem_Hover_FontFace
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_FontWeight={
                          DB_LeftSidebar_MenuItem_FilterItem_FontWeight
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_Hover_FontWeight={
                          DB_LeftSidebar_MenuItem_FilterItem_Hover_FontWeight
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_FontColor={
                          DB_LeftSidebar_MenuItem_FilterItem_FontColor
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_Hover_FontColor={
                          DB_LeftSidebar_MenuItem_FilterItem_Hover_FontColor
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_FontSize={
                          DB_LeftSidebar_MenuItem_FilterItem_FontSize
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_Hover_FontSize={
                          DB_LeftSidebar_MenuItem_FilterItem_Hover_FontSize
                        }
                        SideBarLeft_FilterItem_Farbe_Groesse_Stil_OnMouseOverAndOut={
                          SideBarLeft_FilterItem_Farbe_Groesse_Stil_OnMouseOverAndOut
                        }
                        SideBarLeft_FilterItem_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut={
                          SideBarLeft_FilterItem_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
                        }
                        SideBarLeft_FilterItem_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut={
                          SideBarLeft_FilterItem_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut
                        }
                        SideBarLeft_FilterItem_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut={
                          SideBarLeft_FilterItem_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_BackgroundColor={
                          DB_LeftSidebar_MenuItem_FilterItem_BackgroundColor
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_Hover_BackgroundColor={
                          DB_LeftSidebar_MenuItem_FilterItem_Hover_BackgroundColor
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_TextAlign={
                          DB_LeftSidebar_MenuItem_FilterItem_TextAlign
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_Hover_TextAlign={
                          DB_LeftSidebar_MenuItem_FilterItem_Hover_TextAlign
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_BorderTop={
                          DB_LeftSidebar_MenuItem_FilterItem_BorderTop
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderTop={
                          DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderTop
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_BorderStyleTop={
                          DB_LeftSidebar_MenuItem_FilterItem_BorderStyleTop
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderStyleTop={
                          DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderStyleTop
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_BorderColorTop={
                          DB_LeftSidebar_MenuItem_FilterItem_BorderColorTop
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderColorTop={
                          DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderColorTop
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_BorderBottom={
                          DB_LeftSidebar_MenuItem_FilterItem_BorderBottom
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderBottom={
                          DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderBottom
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_BorderStyleBottom={
                          DB_LeftSidebar_MenuItem_FilterItem_BorderStyleBottom
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderStyleBottom={
                          DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderStyleBottom
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_BorderColorBottom={
                          DB_LeftSidebar_MenuItem_FilterItem_BorderColorBottom
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderColorBottom={
                          DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderColorBottom
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_BorderLeft={
                          DB_LeftSidebar_MenuItem_FilterItem_BorderLeft
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderLeft={
                          DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderLeft
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_BorderStyleLeft={
                          DB_LeftSidebar_MenuItem_FilterItem_BorderStyleLeft
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderStyleLeft={
                          DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderStyleLeft
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_BorderColorLeft={
                          DB_LeftSidebar_MenuItem_FilterItem_BorderColorLeft
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderColorLeft={
                          DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderColorLeft
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_BorderRight={
                          DB_LeftSidebar_MenuItem_FilterItem_BorderRight
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderRight={
                          DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderRight
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_BorderStyleRight={
                          DB_LeftSidebar_MenuItem_FilterItem_BorderStyleRight
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderStyleRight={
                          DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderStyleRight
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_BorderColorRight={
                          DB_LeftSidebar_MenuItem_FilterItem_BorderColorRight
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderColorRight={
                          DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderColorRight
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_BorderTopRightRadius={
                          DB_LeftSidebar_MenuItem_FilterItem_BorderTopRightRadius
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderTopRightRadius={
                          DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderTopRightRadius
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_BorderTopLeftRadius={
                          DB_LeftSidebar_MenuItem_FilterItem_BorderTopLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderTopLeftRadius={
                          DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderTopLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_BorderBottomRightRadius={
                          DB_LeftSidebar_MenuItem_FilterItem_BorderBottomRightRadius
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderBottomRightRadius={
                          DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderBottomRightRadius
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_BorderBottomLeftRadius={
                          DB_LeftSidebar_MenuItem_FilterItem_BorderBottomLeftRadius
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderBottomLeftRadius={
                          DB_LeftSidebar_MenuItem_FilterItem_Hover_BorderBottomLeftRadius
                        }
                        handleShow_showConfig_LeftSidebar_MenuFilterItem={
                          handleShow_showConfig_LeftSidebar_MenuFilterItem
                        }
                        handleShow_showConfig_LeftSidebar_MenuFilterItem_BorderAndColor={
                          handleShow_showConfig_LeftSidebar_MenuFilterItem_BorderAndColor
                        }
                        handleShow_showConfig_LeftSidebar_MenuFilterItem_Hover={
                          handleShow_showConfig_LeftSidebar_MenuFilterItem_Hover
                        }
                        handleShow_showConfig_LeftSidebar_MenuFilterItem_Hover_BorderAndColor={
                          handleShow_showConfig_LeftSidebar_MenuFilterItem_Hover_BorderAndColor
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_marginTopBottom={
                          DB_LeftSidebar_MenuItem_FilterItem_marginTopBottom
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_marginLeftRight={
                          DB_LeftSidebar_MenuItem_FilterItem_marginLeftRight
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_paddingLeftRight={
                          DB_LeftSidebar_MenuItem_FilterItem_paddingLeftRight
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_paddingTopBottom={
                          DB_LeftSidebar_MenuItem_FilterItem_paddingTopBottom
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_letterSpacing={
                          DB_LeftSidebar_MenuItem_FilterItem_letterSpacing
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_ShadowHorizontal={
                          DB_LeftSidebar_MenuItem_FilterItem_ShadowHorizontal
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_ShadowVertical={
                          DB_LeftSidebar_MenuItem_FilterItem_ShadowVertical
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_ShadowBlur={
                          DB_LeftSidebar_MenuItem_FilterItem_ShadowBlur
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_ShadowColor={
                          DB_LeftSidebar_MenuItem_FilterItem_ShadowColor
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_TextDecoration={
                          DB_LeftSidebar_MenuItem_FilterItem_TextDecoration
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_TextDecorationStyle={
                          DB_LeftSidebar_MenuItem_FilterItem_TextDecorationStyle
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_marginTopBottom_Hover={
                          DB_LeftSidebar_MenuItem_FilterItem_marginTopBottom_Hover
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_marginLeftRight_Hover={
                          DB_LeftSidebar_MenuItem_FilterItem_marginLeftRight_Hover
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_paddingLeftRight_Hover={
                          DB_LeftSidebar_MenuItem_FilterItem_paddingLeftRight_Hover
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_paddingTopBottom_Hover={
                          DB_LeftSidebar_MenuItem_FilterItem_paddingTopBottom_Hover
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_letterSpacing_Hover={
                          DB_LeftSidebar_MenuItem_FilterItem_letterSpacing_Hover
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_ShadowHorizontal_Hover={
                          DB_LeftSidebar_MenuItem_FilterItem_ShadowHorizontal_Hover
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_ShadowVertical_Hover={
                          DB_LeftSidebar_MenuItem_FilterItem_ShadowVertical_Hover
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_ShadowBlur_Hover={
                          DB_LeftSidebar_MenuItem_FilterItem_ShadowBlur_Hover
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_ShadowColor_Hover={
                          DB_LeftSidebar_MenuItem_FilterItem_ShadowColor_Hover
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_TextDecoration_Hover={
                          DB_LeftSidebar_MenuItem_FilterItem_TextDecoration_Hover
                        }
                        DB_LeftSidebar_MenuItem_FilterItem_TextDecorationStyle_Hover={
                          DB_LeftSidebar_MenuItem_FilterItem_TextDecorationStyle_Hover
                        }
                      />
                    </div>
                  );
                } else {
                  return (
                    <div key={"groesse" + "_" + filter.groesse}>
                      <div style={{ height: "0px" }}></div>
                      <div className={`form-check ${classes.filterItem}`}>
                        <input
                          className="form-check-input groesse"
                          type="checkbox"
                          value={filter.groesse}
                          id={filter.groesse}
                          onChange={filterProduktsHandler}
                          defaultChecked={
                            isCheck.indexOf({
                              groesse: filter.groesse,
                            })
                              ? false
                              : true
                          }
                        />
                        <label
                          className="form-check-label menuItem"
                          htmlFor={filter.groesse}
                        >
                          {filter.groesse}
                        </label>
                      </div>
                    </div>
                  );
                }
              }
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default SideBarRightFiltersOffCanvas;
