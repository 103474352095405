import React from "react";

import { useTranslation } from "react-i18next";

const RegisterDone = (props) => {
  const { t } = useTranslation();

  return (
    <>
      <div className="pyro">
        <div className="before"></div>
        <div className="after"></div>
      </div>
      <h3 className="animate-charcter">{t("Willkommen")}</h3>
    </>
  );
};

export default RegisterDone;
