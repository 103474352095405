import React from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import "/node_modules/react-image-gallery/styles/css/image-gallery.css";
import { createUseStyles } from "react-jss";

const HauptMenueTopSpecial = ({
  ultraCategoriesNew,
  categoryLeftSideHandler,
  SideBarLeft_ContainerTopAA_Farbe_Groesse_Stil_OnMouseOverAndOut,
  SideBarLeft_ContainerTopAA_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut,
  SideBarLeft_ContainerTopAA_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut,
  SideBarLeft_ContainerTopAA_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut,
  SideBarLeft_HauptmenuTopAA_Farbe_Groesse_Stil_OnMouseOverAndOut,
  SideBarLeft_HauptmenuTopAA_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut,
  SideBarLeft_HauptmenuTopAA_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut,
  SideBarLeft_HauptmenuTopAA_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut,
  DB_fontFace_hauptMenueTopAA_FontFamilyName,
  DB_fontFace_hauptMenueTopAA_FontFamilySrc,
  DB_LeftSidebar_MenuContainerAA_BackgroundColor,
  DB_LeftSidebar_MenuContainerAA_PaddingTopBottom,
  DB_LeftSidebar_MenuContainerAA_PaddingLeftRight,
  DB_LeftSidebar_MenuContainerAA_BorderTop,
  DB_LeftSidebar_MenuContainerAA_BorderStyleTop,
  DB_LeftSidebar_MenuContainerAA_BorderColorTop,
  DB_LeftSidebar_MenuContainerAA_BorderBottom,
  DB_LeftSidebar_MenuContainerAA_BorderStyleBottom,
  DB_LeftSidebar_MenuContainerAA_BorderColorBottom,
  DB_LeftSidebar_MenuContainerAA_BorderLeft,
  DB_LeftSidebar_MenuContainerAA_BorderStyleLeft,
  DB_LeftSidebar_MenuContainerAA_BorderColorLeft,
  DB_LeftSidebar_MenuContainerAA_BorderRight,
  DB_LeftSidebar_MenuContainerAA_BorderStyleRight,
  DB_LeftSidebar_MenuContainerAA_BorderColorRight,
  DB_LeftSidebar_MenuItem_MenuContainerAA_BorderTopRightRadius,
  DB_LeftSidebar_MenuItem_MenuContainerAA_BorderTopLeftRadius,
  DB_LeftSidebar_MenuItem_MenuContainerAA_BorderBottomRightRadius,
  DB_LeftSidebar_MenuItem_MenuContainerAA_BorderBottomLeftRadius,
  DB_LeftSidebar_MenuItem_MenuContainerAA_paddingLeft,
  DB_LeftSidebar_MenuItem_MenuContainerAA_paddingRight,
  DB_LeftSidebar_MenuItem_MenuContainerAA_paddingTop,
  DB_LeftSidebar_MenuItem_MenuContainerAA_paddingBottom,
  DB_LeftSidebar_MenuItem_MenuContainerAA_ShadowHorizontal,
  DB_LeftSidebar_MenuItem_MenuContainerAA_ShadowVertical,
  DB_LeftSidebar_MenuItem_MenuContainerAA_ShadowBlur,
  DB_LeftSidebar_MenuItem_MenuContainerAA_ShadowColor,

  DB_LeftSidebar_MenuContainerAA_Hover_BackgroundColor,
  DB_LeftSidebar_MenuContainerAA_Hover_PaddingTopBottom,
  DB_LeftSidebar_MenuContainerAA_Hover_PaddingLeftRight,
  DB_LeftSidebar_MenuContainerAA_Hover_BorderTop,
  DB_LeftSidebar_MenuContainerAA_Hover_BorderStyleTop,
  DB_LeftSidebar_MenuContainerAA_Hover_BorderColorTop,
  DB_LeftSidebar_MenuContainerAA_Hover_BorderBottom,
  DB_LeftSidebar_MenuContainerAA_Hover_BorderStyleBottom,
  DB_LeftSidebar_MenuContainerAA_Hover_BorderColorBottom,
  DB_LeftSidebar_MenuContainerAA_Hover_BorderLeft,
  DB_LeftSidebar_MenuContainerAA_Hover_BorderStyleLeft,
  DB_LeftSidebar_MenuContainerAA_Hover_BorderColorLeft,
  DB_LeftSidebar_MenuContainerAA_Hover_BorderRight,
  DB_LeftSidebar_MenuContainerAA_Hover_BorderStyleRight,
  DB_LeftSidebar_MenuContainerAA_Hover_BorderColorRight,
  DB_LeftSidebar_MenuItem_MenuContainerAA_Hover_BorderTopRightRadius,
  DB_LeftSidebar_MenuItem_MenuContainerAA_Hover_BorderTopLeftRadius,
  DB_LeftSidebar_MenuItem_MenuContainerAA_Hover_BorderBottomRightRadius,
  DB_LeftSidebar_MenuItem_MenuContainerAA_Hover_BorderBottomLeftRadius,
  DB_LeftSidebar_MenuItem_MenuContainerAA_Hover_paddingLeft,
  DB_LeftSidebar_MenuItem_MenuContainerAA_Hover_paddingRight,
  DB_LeftSidebar_MenuItem_MenuContainerAA_Hover_paddingTop,
  DB_LeftSidebar_MenuItem_MenuContainerAA_Hover_paddingBottom,
  DB_LeftSidebar_MenuItem_MenuContainerAA_Hover_ShadowHorizontal,
  DB_LeftSidebar_MenuItem_MenuContainerAA_Hover_ShadowVertical,
  DB_LeftSidebar_MenuItem_MenuContainerAA_Hover_ShadowBlur,
  DB_LeftSidebar_MenuItem_MenuContainerAA_Hover_ShadowColor,

  DB_LeftSidebar_MenuItem_HauptmenuTopAA_FontWeight,
  DB_LeftSidebar_MenuItem_HauptmenuTopAA_FontColor,
  DB_LeftSidebar_MenuItem_HauptmenuTopAA_FontSize,
  DB_LeftSidebar_MenuItem_HauptmenuTopAA_BackgroundColor,
  DB_LeftSidebar_MenuItem_HauptmenuTopAA_TextAlign,
  DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderTop,
  DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderStyleTop,
  DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderColorTop,
  DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderBottom,
  DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderStyleBottom,
  DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderColorBottom,
  DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderLeft,
  DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderStyleLeft,
  DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderColorLeft,
  DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderRight,
  DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderStyleRight,
  DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderColorRight,
  DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderTopRightRadius,
  DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderTopLeftRadius,
  DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderBottomRightRadius,
  DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderBottomLeftRadius,
  DB_LeftSidebar_MenuItem_HauptmenuTopAA_marginLeftRight,
  DB_LeftSidebar_MenuItem_HauptmenuTopAA_marginTopBottom,
  DB_LeftSidebar_MenuItem_HauptmenuTopAA_paddingLeftRight,
  DB_LeftSidebar_MenuItem_HauptmenuTopAA_paddingTopBottom,
  DB_LeftSidebar_MenuItem_HauptmenuTopAA_letterSpacing,
  DB_LeftSidebar_MenuItem_HauptmenuTopAA_ShadowHorizontal,
  DB_LeftSidebar_MenuItem_HauptmenuTopAA_ShadowVertical,
  DB_LeftSidebar_MenuItem_HauptmenuTopAA_ShadowBlur,
  DB_LeftSidebar_MenuItem_HauptmenuTopAA_ShadowColor,
  DB_LeftSidebar_MenuItem_HauptmenuTopAA_TextDecoration,
  DB_LeftSidebar_MenuItem_HauptmenuTopAA_TextDecorationStyle,

  DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_FontWeight,
  DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_FontColor,
  DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_FontSize,
  DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BackgroundColor,
  DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_TextAlign,
  DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderTop,
  DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderColorTop,
  DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderStyleTop,
  DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderColorBottom,
  DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderStyleBottom,
  DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderLeft,
  DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderColorLeft,
  DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderStyleLeft,
  DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderRight,
  DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderStyleRight,
  DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderColorRight,
  DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderTopRightRadius,
  DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderTopLeftRadius,
  DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderBottomRightRadius,
  DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderBottomLeftRadius,
  DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderBottom,
  DB_LeftSidebar_MenuItem_HauptmenuTopAA_marginLeftRight_Hover,
  DB_LeftSidebar_MenuItem_HauptmenuTopAA_marginTopBottom_Hover,
  DB_LeftSidebar_MenuItem_HauptmenuTopAA_paddingLeftRight_Hover,
  DB_LeftSidebar_MenuItem_HauptmenuTopAA_paddingTopBottom_Hover,
  DB_LeftSidebar_MenuItem_HauptmenuTopAA_letterSpacing_Hover,
  DB_LeftSidebar_MenuItem_HauptmenuTopAA_ShadowHorizontal_Hover,
  DB_LeftSidebar_MenuItem_HauptmenuTopAA_ShadowVertical_Hover,
  DB_LeftSidebar_MenuItem_HauptmenuTopAA_ShadowBlur_Hover,
  DB_LeftSidebar_MenuItem_HauptmenuTopAA_ShadowColor_Hover,
  DB_LeftSidebar_MenuItem_HauptmenuTopAA_TextDecoration_Hover,
  DB_LeftSidebar_MenuItem_HauptmenuTopAA_TextDecorationStyle_Hover,
}) => {
  let mainHeaderKategorieTemp = [];

  const useStyles = createUseStyles({
    hauptMenueTopSpecialContainer: {
      display: "block",
      backgroundColor: `${
        SideBarLeft_ContainerTopAA_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut
          ? "rgba(255,0,0,0.5)"
          : SideBarLeft_ContainerTopAA_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut
          ? "rgba(255,0,0,0.3)"
          : DB_LeftSidebar_MenuContainerAA_BackgroundColor
      }`,
      paddingTop: `${DB_LeftSidebar_MenuContainerAA_PaddingTopBottom}px`,
      paddingBottom: `${DB_LeftSidebar_MenuContainerAA_PaddingTopBottom}px`,
      paddingLeft: `${DB_LeftSidebar_MenuContainerAA_PaddingLeftRight}px`,
      paddingRight: `${DB_LeftSidebar_MenuContainerAA_PaddingLeftRight}px`,

      borderTop: `${
        SideBarLeft_ContainerTopAA_Farbe_Groesse_Stil_OnMouseOverAndOut
          ? "3"
          : SideBarLeft_ContainerTopAA_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
          ? "3"
          : DB_LeftSidebar_MenuContainerAA_BorderTop
      }px
          ${
            SideBarLeft_ContainerTopAA_Farbe_Groesse_Stil_OnMouseOverAndOut
              ? "solid"
              : SideBarLeft_ContainerTopAA_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
              ? "dotted"
              : DB_LeftSidebar_MenuContainerAA_BorderStyleTop
          }
          ${
            SideBarLeft_ContainerTopAA_Farbe_Groesse_Stil_OnMouseOverAndOut
              ? "red"
              : SideBarLeft_ContainerTopAA_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
              ? "red"
              : DB_LeftSidebar_MenuContainerAA_BorderColorTop
          }`,
      borderBottom: `${
        SideBarLeft_ContainerTopAA_Farbe_Groesse_Stil_OnMouseOverAndOut
          ? "3"
          : SideBarLeft_ContainerTopAA_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
          ? "3"
          : DB_LeftSidebar_MenuContainerAA_BorderBottom
      }px
          ${
            SideBarLeft_ContainerTopAA_Farbe_Groesse_Stil_OnMouseOverAndOut
              ? "solid"
              : SideBarLeft_ContainerTopAA_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
              ? "dotted"
              : DB_LeftSidebar_MenuContainerAA_BorderStyleBottom
          }
          ${
            SideBarLeft_ContainerTopAA_Farbe_Groesse_Stil_OnMouseOverAndOut
              ? "red"
              : SideBarLeft_ContainerTopAA_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
              ? "red"
              : DB_LeftSidebar_MenuContainerAA_BorderColorBottom
          }`,
      borderLeft: `${
        SideBarLeft_ContainerTopAA_Farbe_Groesse_Stil_OnMouseOverAndOut
          ? "3"
          : SideBarLeft_ContainerTopAA_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
          ? "3"
          : DB_LeftSidebar_MenuContainerAA_BorderLeft
      }px
          ${
            SideBarLeft_ContainerTopAA_Farbe_Groesse_Stil_OnMouseOverAndOut
              ? "solid"
              : SideBarLeft_ContainerTopAA_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
              ? "dotted"
              : DB_LeftSidebar_MenuContainerAA_BorderStyleLeft
          }
          ${
            SideBarLeft_ContainerTopAA_Farbe_Groesse_Stil_OnMouseOverAndOut
              ? "red"
              : SideBarLeft_ContainerTopAA_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
              ? "red"
              : DB_LeftSidebar_MenuContainerAA_BorderColorLeft
          }`,
      borderRight: `${
        SideBarLeft_ContainerTopAA_Farbe_Groesse_Stil_OnMouseOverAndOut
          ? "3"
          : SideBarLeft_ContainerTopAA_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
          ? "3"
          : DB_LeftSidebar_MenuContainerAA_BorderRight
      }px
          ${
            SideBarLeft_ContainerTopAA_Farbe_Groesse_Stil_OnMouseOverAndOut
              ? "solid"
              : SideBarLeft_ContainerTopAA_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
              ? "dotted"
              : DB_LeftSidebar_MenuContainerAA_BorderStyleRight
          }
          ${
            SideBarLeft_ContainerTopAA_Farbe_Groesse_Stil_OnMouseOverAndOut
              ? "red"
              : SideBarLeft_ContainerTopAA_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
              ? "red"
              : DB_LeftSidebar_MenuContainerAA_BorderColorRight
          }`,
      borderTopRightRadius: `${DB_LeftSidebar_MenuItem_MenuContainerAA_BorderTopRightRadius}px`,
      borderTopLeftRadius: `${DB_LeftSidebar_MenuItem_MenuContainerAA_BorderTopLeftRadius}px`,
      borderBottomRightRadius: `${DB_LeftSidebar_MenuItem_MenuContainerAA_BorderBottomRightRadius}px`,
      borderBottomLeftRadius: `${DB_LeftSidebar_MenuItem_MenuContainerAA_BorderBottomLeftRadius}px`,

      paddingLeft: `${DB_LeftSidebar_MenuItem_MenuContainerAA_paddingLeft}px`,
      paddingRight: `${DB_LeftSidebar_MenuItem_MenuContainerAA_paddingRight}px`,
      paddingTop: `${DB_LeftSidebar_MenuItem_MenuContainerAA_paddingTop}px`,
      paddingBottom: `${DB_LeftSidebar_MenuItem_MenuContainerAA_paddingBottom}px`,
      boxShadow: `${DB_LeftSidebar_MenuItem_MenuContainerAA_ShadowHorizontal}px
          ${DB_LeftSidebar_MenuItem_MenuContainerAA_ShadowVertical}px
          ${DB_LeftSidebar_MenuItem_MenuContainerAA_ShadowBlur}px
          ${DB_LeftSidebar_MenuItem_MenuContainerAA_ShadowColor}`,

      marginBottom: "13px",
      "&:hover": {
        backgroundColor: `${DB_LeftSidebar_MenuContainerAA_Hover_BackgroundColor}`,
        paddingTop: `${DB_LeftSidebar_MenuContainerAA_Hover_PaddingTopBottom}px`,
        paddingBottom: `${DB_LeftSidebar_MenuContainerAA_Hover_PaddingTopBottom}px`,
        paddingLeft: `${DB_LeftSidebar_MenuContainerAA_Hover_PaddingLeftRight}px`,
        paddingRight: `${DB_LeftSidebar_MenuContainerAA_Hover_PaddingLeftRight}px`,
        borderTop: `${DB_LeftSidebar_MenuContainerAA_Hover_BorderTop}px
          ${DB_LeftSidebar_MenuContainerAA_Hover_BorderStyleTop}
          ${DB_LeftSidebar_MenuContainerAA_Hover_BorderColorTop}`,
        borderBottom: `${DB_LeftSidebar_MenuContainerAA_Hover_BorderBottom}px
          ${DB_LeftSidebar_MenuContainerAA_Hover_BorderStyleBottom}
          ${DB_LeftSidebar_MenuContainerAA_Hover_BorderColorBottom}`,
        borderLeft: `${DB_LeftSidebar_MenuContainerAA_Hover_BorderLeft}px
          ${DB_LeftSidebar_MenuContainerAA_Hover_BorderStyleLeft}
          ${DB_LeftSidebar_MenuContainerAA_Hover_BorderColorLeft}`,
        borderRight: `${DB_LeftSidebar_MenuContainerAA_Hover_BorderRight}px
          ${DB_LeftSidebar_MenuContainerAA_Hover_BorderStyleRight}
          ${DB_LeftSidebar_MenuContainerAA_Hover_BorderColorRight}`,

        borderTopRightRadius: `${DB_LeftSidebar_MenuItem_MenuContainerAA_Hover_BorderTopRightRadius}px`,
        borderTopLeftRadius: `${DB_LeftSidebar_MenuItem_MenuContainerAA_Hover_BorderTopLeftRadius}px`,
        borderBottomRightRadius: `${DB_LeftSidebar_MenuItem_MenuContainerAA_Hover_BorderBottomRightRadius}px`,
        borderBottomLeftRadius: `${DB_LeftSidebar_MenuItem_MenuContainerAA_Hover_BorderBottomLeftRadius}px`,
        paddingLeft: `${DB_LeftSidebar_MenuItem_MenuContainerAA_Hover_paddingLeft}px`,
        paddingRight: `${DB_LeftSidebar_MenuItem_MenuContainerAA_Hover_paddingRight}px`,
        paddingTop: `${DB_LeftSidebar_MenuItem_MenuContainerAA_Hover_paddingTop}px`,
        paddingBottom: `${DB_LeftSidebar_MenuItem_MenuContainerAA_Hover_paddingBottom}px`,
        boxShadow: `${DB_LeftSidebar_MenuItem_MenuContainerAA_Hover_ShadowHorizontal}px
          ${DB_LeftSidebar_MenuItem_MenuContainerAA_Hover_ShadowVertical}px
          ${DB_LeftSidebar_MenuItem_MenuContainerAA_Hover_ShadowBlur}px
          ${DB_LeftSidebar_MenuItem_MenuContainerAA_Hover_ShadowColor}`,
      },
    },
    topNavigationNavItemSpecial: {
      width: "100%",
      display: `block`,
      fontFamily: `${DB_fontFace_hauptMenueTopAA_FontFamilyName}`,
      fontWeight: `${DB_LeftSidebar_MenuItem_HauptmenuTopAA_FontWeight}`,
      color: `${DB_LeftSidebar_MenuItem_HauptmenuTopAA_FontColor}`,
      fontSize: `${DB_LeftSidebar_MenuItem_HauptmenuTopAA_FontSize}px`,
      backgroundColor: `${
        SideBarLeft_HauptmenuTopAA_HintergrundFarbe_Radius_Stil_OnMouseOverAndOut
          ? "rgba(255,0,0,0.5)"
          : SideBarLeft_HauptmenuTopAA_HintergrundFarbe_Radius_Stil_Hover_OnMouseOverAndOut
          ? "rgba(255,0,0,0.3)"
          : DB_LeftSidebar_MenuItem_HauptmenuTopAA_BackgroundColor
      }`,
      textAlign: `${DB_LeftSidebar_MenuItem_HauptmenuTopAA_TextAlign}`,
      borderTop: `${
        SideBarLeft_HauptmenuTopAA_Farbe_Groesse_Stil_OnMouseOverAndOut
          ? "3"
          : SideBarLeft_HauptmenuTopAA_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
          ? "3"
          : DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderTop
      }px
          ${
            SideBarLeft_HauptmenuTopAA_Farbe_Groesse_Stil_OnMouseOverAndOut
              ? "solid"
              : SideBarLeft_HauptmenuTopAA_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
              ? "dotted"
              : DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderStyleTop
          }
          ${
            SideBarLeft_HauptmenuTopAA_Farbe_Groesse_Stil_OnMouseOverAndOut
              ? "red"
              : SideBarLeft_HauptmenuTopAA_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
              ? "red"
              : DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderColorTop
          }`,
      borderBottom: `${
        SideBarLeft_HauptmenuTopAA_Farbe_Groesse_Stil_OnMouseOverAndOut
          ? "3"
          : SideBarLeft_HauptmenuTopAA_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
          ? "3"
          : DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderBottom
      }px
          ${
            SideBarLeft_HauptmenuTopAA_Farbe_Groesse_Stil_OnMouseOverAndOut
              ? "solid"
              : SideBarLeft_HauptmenuTopAA_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
              ? "dotted"
              : DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderStyleBottom
          }
          ${
            SideBarLeft_HauptmenuTopAA_Farbe_Groesse_Stil_OnMouseOverAndOut
              ? "red"
              : SideBarLeft_HauptmenuTopAA_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
              ? "red"
              : DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderColorBottom
          }`,
      borderLeft: `${
        SideBarLeft_HauptmenuTopAA_Farbe_Groesse_Stil_OnMouseOverAndOut
          ? "3"
          : SideBarLeft_HauptmenuTopAA_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
          ? "3"
          : DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderLeft
      }px
          ${
            SideBarLeft_HauptmenuTopAA_Farbe_Groesse_Stil_OnMouseOverAndOut
              ? "solid"
              : SideBarLeft_HauptmenuTopAA_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
              ? "dotted"
              : DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderStyleLeft
          }
          ${
            SideBarLeft_HauptmenuTopAA_Farbe_Groesse_Stil_OnMouseOverAndOut
              ? "red"
              : SideBarLeft_HauptmenuTopAA_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
              ? "red"
              : DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderColorLeft
          }`,
      borderRight: `${
        SideBarLeft_HauptmenuTopAA_Farbe_Groesse_Stil_OnMouseOverAndOut
          ? "3"
          : SideBarLeft_HauptmenuTopAA_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
          ? "3"
          : DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderRight
      }px
          ${
            SideBarLeft_HauptmenuTopAA_Farbe_Groesse_Stil_OnMouseOverAndOut
              ? "solid"
              : SideBarLeft_HauptmenuTopAA_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
              ? "dotted"
              : DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderStyleRight
          }
          ${
            SideBarLeft_HauptmenuTopAA_Farbe_Groesse_Stil_OnMouseOverAndOut
              ? "red"
              : SideBarLeft_HauptmenuTopAA_Farbe_Groesse_Stil_Hover_OnMouseOverAndOut
              ? "red"
              : DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderColorRight
          }`,
      borderTopRightRadius: `${DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderTopRightRadius}px`,
      borderTopLeftRadius: `${DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderTopLeftRadius}px`,
      borderBottomRightRadius: `${DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderBottomRightRadius}px`,
      borderBottomLeftRadius: `${DB_LeftSidebar_MenuItem_HauptmenuTopAA_BorderBottomLeftRadius}px`,
      marginLeft: `${DB_LeftSidebar_MenuItem_HauptmenuTopAA_marginLeftRight}px`,
      marginRight: `${DB_LeftSidebar_MenuItem_HauptmenuTopAA_marginLeftRight}px`,
      marginTop: `${DB_LeftSidebar_MenuItem_HauptmenuTopAA_marginTopBottom}px`,
      marginBottom: `${DB_LeftSidebar_MenuItem_HauptmenuTopAA_marginTopBottom}px`,
      paddingLeft: `${DB_LeftSidebar_MenuItem_HauptmenuTopAA_paddingLeftRight}px`,
      paddingRight: `${DB_LeftSidebar_MenuItem_HauptmenuTopAA_paddingLeftRight}px`,
      paddingTop: `${DB_LeftSidebar_MenuItem_HauptmenuTopAA_paddingTopBottom}px`,
      paddingBottom: `${DB_LeftSidebar_MenuItem_HauptmenuTopAA_paddingTopBottom}px`,
      letterSpacing: `${DB_LeftSidebar_MenuItem_HauptmenuTopAA_letterSpacing}px`,
      textShadow: `${DB_LeftSidebar_MenuItem_HauptmenuTopAA_ShadowHorizontal}px
          ${DB_LeftSidebar_MenuItem_HauptmenuTopAA_ShadowVertical}px
          ${DB_LeftSidebar_MenuItem_HauptmenuTopAA_ShadowBlur}px
          ${DB_LeftSidebar_MenuItem_HauptmenuTopAA_ShadowColor}`,
      textDecoration: `${DB_LeftSidebar_MenuItem_HauptmenuTopAA_TextDecoration}
          ${DB_LeftSidebar_MenuItem_HauptmenuTopAA_TextDecorationStyle}`,

      "&:hover": {
        fontWeight: `${DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_FontWeight}`,
        color: `${DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_FontColor}`,
        fontSize: `${DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_FontSize}px`,
        backgroundColor: `${DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BackgroundColor}`,
        textAlign: `${DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_TextAlign}`,
        borderTop: `${DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderTop}px
          ${DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderStyleTop}
          ${DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderColorTop}`,
        borderBottom: `${DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderBottom}px
          ${DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderStyleBottom}
          ${DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderColorBottom}`,
        borderLeft: `${DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderLeft}px
          ${DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderStyleLeft}
          ${DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderColorLeft}`,
        borderRight: `${DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderRight}px
          ${DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderStyleRight}
          ${DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderColorRight}`,
        borderTopRightRadius: `${DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderTopRightRadius}px`,
        borderTopLeftRadius: `${DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderTopLeftRadius}px`,
        borderBottomRightRadius: `${DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderBottomRightRadius}px`,
        borderBottomLeftRadius: `${DB_LeftSidebar_MenuItem_HauptmenuTopAA_Hover_BorderBottomLeftRadius}px`,

        marginLeft: `${DB_LeftSidebar_MenuItem_HauptmenuTopAA_marginLeftRight_Hover}px`,
        marginRight: `${DB_LeftSidebar_MenuItem_HauptmenuTopAA_marginLeftRight_Hover}px`,
        marginTop: `${DB_LeftSidebar_MenuItem_HauptmenuTopAA_marginTopBottom_Hover}px`,
        marginBottom: `${DB_LeftSidebar_MenuItem_HauptmenuTopAA_marginTopBottom_Hover}px`,
        paddingLeft: `${DB_LeftSidebar_MenuItem_HauptmenuTopAA_paddingLeftRight_Hover}px`,
        paddingRight: `${DB_LeftSidebar_MenuItem_HauptmenuTopAA_paddingLeftRight_Hover}px`,
        paddingTop: `${DB_LeftSidebar_MenuItem_HauptmenuTopAA_paddingTopBottom_Hover}px`,
        paddingBottom: `${DB_LeftSidebar_MenuItem_HauptmenuTopAA_paddingTopBottom_Hover}px`,
        letterSpacing: `${DB_LeftSidebar_MenuItem_HauptmenuTopAA_letterSpacing_Hover}px`,
        textShadow: `${DB_LeftSidebar_MenuItem_HauptmenuTopAA_ShadowHorizontal_Hover}px
          ${DB_LeftSidebar_MenuItem_HauptmenuTopAA_ShadowVertical_Hover}px
          ${DB_LeftSidebar_MenuItem_HauptmenuTopAA_ShadowBlur_Hover}px
          ${DB_LeftSidebar_MenuItem_HauptmenuTopAA_ShadowColor_Hover}`,
        textDecoration: `${DB_LeftSidebar_MenuItem_HauptmenuTopAA_TextDecoration_Hover}
          ${DB_LeftSidebar_MenuItem_HauptmenuTopAA_TextDecorationStyle_Hover}`,
      },
    },
  });
  const classes = useStyles();

  return (
    <Container className={classes.hauptMenueTopSpecialContainer}>
      <Nav style={{ flexDirection: "column" }}>
        {ultraCategoriesNew.map((category, index) => {
          if (category.kategorie) {
            if (category.kategorie && !category.subkategorie) {
              mainHeaderKategorieTemp = category.kategorie;
              return (
                !undefined && (
                  <Nav.Item
                    key={"categoryTop" + category.kategorie}
                    onClick={() =>
                      categoryLeftSideHandler(`${category.kategorie}`)
                    }
                  >
                    <Nav.Link
                      className={`mb-1 ${classes.topNavigationNavItemSpecial}`}
                    >
                      {category.kategorie}
                    </Nav.Link>
                  </Nav.Item>
                )
              );
            }
          }
        })}
      </Nav>
    </Container>
  );
};

export default HauptMenueTopSpecial;
